import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
//@ts-ignore
import * as cornerstone from 'cornerstone-core';
//@ts-ignore
import * as cornerstoneTools from 'cornerstone-tools';
// import { RedacMappingComponent } from 'projects/transaction/src/app/transaction-view/@ui-controls/redac-mapping/redac-mapping.component';
import { Subject } from 'rxjs';
import { FieldConfig } from '../modal/field.interface';
@Component({
  selector: 'app-dicom-integration',
  templateUrl: './dicom-integration.component.html',
  styleUrls: ['./dicom-integration.component.scss'],
})
export class DicomIntegrationComponent implements OnInit {
  // @Input() src:any;
  config = {
    fileUrl: '',
    tools: [
      {
        name: 'DragProbe',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Eraser',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'StackScrollMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Active,
      },
      {
        name: 'Rotate',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Pan',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'ZoomMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Length',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Angle',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'FreehandRoi',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Wwwc',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
    ],
    classList: '',
  };
  enableTools: boolean = false;
  viewerProvider: IViewerProvider = {
    element: null,
    cornerstoneTools: cornerstoneTools,
    cornerstone: cornerstone,
    playClip: {
      play(frameRate: number) {},
      stop() {},
    },
  };
  tenantId: any;
  currentCu: any;
  ontology: any;
  fileSave: any;
  private unsubscribe$ = new Subject<any>();
  field: FieldConfig;
  redactedResult: any;
  constructor(
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tenantId = localStorage.getItem('envClientId')
      ? localStorage.getItem('envClientId')
      : sessionStorage.getItem('TenantId');
  }

  ngOnInit(): void {
    /*istanbul ignore else */
    if (this.data) {
      /*istanbul ignore next */
      this.loadDICOMImages(this.data?.file?.contentUrl);
    }
  }
  /**
   * Load selected DICOM images
   *
   * @param files list of selected dicom files
   */
  loadDICOMImages(event: any) {
    this.config.fileUrl = event;
    this.enableTools = true;
  }

  // exportStateToJson(): void {
  //   this.download(
  //     JSON.stringify(this.viewerProvider?.cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()),
  //     'toolsState.json',
  //     'application/json'
  //   )
  // }

  // saveAs(): void {
  //   this.viewerProvider?.cornerstoneTools.SaveAs(this.viewerProvider?.element, 'screenshot.png');
  // }

  activateTool(name: string): void {
    /*istanbul ignore next*/
    const foundTool = this.config.tools?.find((tool) => tool.name === name);
    /*istanbul ignore else */
    if (foundTool && this.viewerProvider) {
      /*istanbul ignore next */
      this.viewerProvider?.cornerstoneTools['setToolActive'](name, foundTool.options);
    }
  }

  isActive(tool: any): boolean {
    /*istanbul ignore next*/
    return this.viewerProvider?.cornerstoneTools?.isToolActiveForElement(this.viewerProvider?.element, tool.name);
  }

  // private download(content: any, fileName: string, contentType: string) {
  //   const a = document.createElement("a");
  //   const file = new Blob([content], {type: contentType});
  //   a.href = URL.createObjectURL(file);
  //   a.download = fileName;
  //   a.click();
  // }

  initializeProvider(event: any) {
    this.viewerProvider = event;
  }

  stop() {
    /*istanbul ignore next */
    this.viewerProvider?.playClip?.stop();
  }
  play(frame: any) {
    /*istanbul ignore next */
    this.viewerProvider?.playClip?.play(frame);
  }

}

interface IViewerProvider {
  element: any;
  cornerstoneTools: any;
  cornerstone: any;
  playClip?: {
    play(frameRate: number): void;
    stop(): void;
  };
}
enum ToolModeEnum {
  Active = 'Active',
  Passive = 'Passive',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}
/**
 * Active tools will render and respond to user input. Active tools are able to create new annotations and/or measurements.
 * Passive tools will render and passively respond to user input. Their data can be manipulated, but not created.
 * Enabled tools will render, but will not respond to input. The "enabled" tool state is essentially a "read-only" state.
 * The default state for a tool. Tools that are disabled cannot be interacted with and are not rendered on the enabled element.
 */
/**
 * // Set the tool font and font size
const fontFamily =
  'Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';

cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);

// Set the tool width
cornerstoneTools.toolStyle.setToolWidth(2);

// Set color for inactive tools
cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');

// Set color for active tools
cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');

// and so on...
 */
