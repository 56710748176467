import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { CredentialsService } from '@common-services';
// import { Logger } from '@core';
import { DialogService } from 'primeng/dynamicdialog';
import { FeatureFlagDialogComponent } from '../components/feature-flag-confirmation/featureflag-confiramation-dialog.component';
// import { Observable } from 'rxjs';


// const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class FeatureflagAuthenticationGuard implements CanActivate {
  labels: any;
  constructor(public dialogService: DialogService, public credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    /* istanbul ignore next */
    return new Promise((resolve) => {
      if (route.data.featureToggle && this.credentialsService.featureFlagData && this.credentialsService.featureFlagData[route.data.featureToggle[0]]) {
        resolve(true);
      } else {
        const ref = this.dialogService.open(FeatureFlagDialogComponent, {
          header: 'Feature Flag',
          width: '50%',
        });
        ref.onClose.subscribe((res: any) => {
          resolve(false);
        });
      }
    });
  }
}
