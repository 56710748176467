import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';
import { BetData, DeviceType, PageData } from '../modal/mcc';
import { AlertService } from '../toaster/alert.service';
import { TenantCustomEndpointService } from './tenant-custom-endpoint.service';
import { UiCustomizeEndpointService } from './ui-customize-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class UiCustomizeFacadeService {
  private ngUnsubscribe = new Subject();
  selectedShelf: any = [{}];
  selctedShelfRef: any[] = [];
  index: number;

  mapBooksDataSubject = new Subject();
  mapBooksDataObservable = this.mapBooksDataSubject.asObservable();

  gsisListSubject = new Subject();
  gsisList$ = this.gsisListSubject.asObservable();

  mapGsisDataSubject = new Subject();
  mapGsisDataObservable = this.mapGsisDataSubject.asObservable();

  pageLayoutMode = new BehaviorSubject(false);
  pageLauoutModeObservable = this.pageLayoutMode.asObservable();

  layoutId = new BehaviorSubject('');
  layoutIdObservable = this.layoutId.asObservable();

  layoutData = new Subject();
  layoutDataObservable = this.layoutData.asObservable();

  savedHeaderSubject = new BehaviorSubject({});
  savedHeader$ = this.savedHeaderSubject.asObservable();

  headerDataSubject = new BehaviorSubject({});
  headerData$ = this.headerDataSubject.asObservable();

  getDashboards = new Subject();
  getDashboards$ = this.getDashboards.asObservable();

  public collections = new Subject();
  collections$ = this.collections.asObservable();

  customTemplateSubject = new Subject();
  customTemplateObservable$ = this.customTemplateSubject.asObservable();

  public supersetDashboardsList = new Subject();
  supersetDashboardsList$ = this.supersetDashboardsList.asObservable();

  quickLinkData = new BehaviorSubject({});
  quickLinkData$ = this.quickLinkData.asObservable();

  public footerId = new BehaviorSubject('');
  footerId$ = this.footerId.asObservable();

  constructor(
    private uiCustomizeEndpoint: UiCustomizeEndpointService,
    private tenantCustomEndpointService: TenantCustomEndpointService,
    private loader: LoaderService,
    private alertService: AlertService
  ) {}

  getMapBooksData(pageno: number, rightHolderId: string, text?: string) {
    this.loader.show();
    if (text) {
      this.uiCustomizeEndpoint
        .getMapBookData(pageno, rightHolderId, text)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          this.mapBooksDataSubject.next(res);
        });
    } else {
      this.uiCustomizeEndpoint
        .getMapBookData(pageno, rightHolderId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          this.mapBooksDataSubject.next(res);
        });
    }
  }

  getBookDataWithGsis(bookId: any, rightHolderId: any) {
    return this.uiCustomizeEndpoint.getGSIsByBookId(bookId, rightHolderId);
  }

  getMapGSIsData(pageno: number, rightHolderId: string, text?: string, limit: number = 5) {
    this.uiCustomizeEndpoint
      .getMapGSIsData(pageno, rightHolderId, text, limit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.mapGsisDataSubject.next(res);
      });
  }

  fileUpload(file: any, isReportProgress: boolean) {
    if (file) {
      return this.uiCustomizeEndpoint.uploadFile(file, isReportProgress);
    }
  }
  getAllMediaLinks(limit: number, pageNumber: number) {
    return this.uiCustomizeEndpoint.getAllMediaLinks(limit, pageNumber);
  }

  postPageLayout(data: PageData, isCopy: boolean) {
    // this.loader.show();
    // if (data) {
    //   this.uiCustomizeEndpoint
    //     .postPageLayout({
    //       div: data,
    //     })
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe((res: any) => {
    //       this.loader.hide();
    //       this.alertService.showToaster(res.message, 'Saved', 'success');
    //       /*istanbul ignore next*/
    //       this.layoutId.next(res?.result?.id);
    //     });
    // } else {
    //   this.alertService.showToaster('Give all the data', 'Info', 'info');
    //   this.loader.hide();
    // }
    return this.uiCustomizeEndpoint.postPageLayout(data, isCopy);
  }
  addDummyShelf(betData: any): BetData {
    let newBetData: BetData = {
      betId: 'dummy',
      betName: 'dummy',
      betType: 'dummy',
      betMasterId: NaN,
      displayName: 'dummy',
    };
    let betDataForReference: BetData = betData?.[0];
    if (betDataForReference?.description && betDataForReference?.description != undefined) {
      newBetData.description = betDataForReference.description;
    }
    return newBetData;
  }

  getMetaData(id: any, deviceType: DeviceType = 'DESKTOP') {
    return this.uiCustomizeEndpoint.getMetaData(id, deviceType);
  }

  getPageData(id: any) {
    return this.uiCustomizeEndpoint.getPageData(id);
  }

  deletePage(id: any, deviceType: DeviceType) {
    return this.uiCustomizeEndpoint.deletePage(id, deviceType);
  }

  getAllPages(id: any, searchText: string) {
    return this.uiCustomizeEndpoint.getAllPages(id, searchText);
  }
  getCollectionById(name: string) {
    return this.uiCustomizeEndpoint.getCollectionById(name);
  }

  getAllPagesByROle(id: any, searchText: string) {
    return this.uiCustomizeEndpoint.getAllPagesByROle(id, searchText);
  }

  getAllPaginatedPagesByRole(id: any, searchText: string, pageno: number, deviceType: DeviceType) {
    return this.uiCustomizeEndpoint.getAllPaginatedPagesByRole(pageno, id, searchText, deviceType);
  }

  // Create new sitemeta data
  postSiteMetaData(data: any) {
    return this.uiCustomizeEndpoint.postSiteMetaData(data);
  }

  postRoleToLayout(data: any) {
    this.loader.show();
    if (data) {
      this.uiCustomizeEndpoint
        .postRoleWithLayout(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, 'Layout added to role', 'success');
          this.pageLayoutMode.next(true);
        });
    }
  }

  getLibraryLayoutById(layoutId: string) {
    this.uiCustomizeEndpoint
      .getLibraryTemplateDataById(layoutId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutData.next(res);
      });
  }

  getAllLinks(limit: number) {
    return this.uiCustomizeEndpoint.getAllLinks(limit);
  }

  getMapEntitiesData(roleId: string, pageSize: number, pagenum: number, searchText?: string) {
    /*istanbul ignore next*/
    if (searchText) {
      return this.uiCustomizeEndpoint.getMapEntitiesData(roleId, pageSize, pagenum, searchText);
    } else {
      return this.uiCustomizeEndpoint.getMapEntitiesData(roleId, pageSize, pagenum);
    }
  }

  getGsiIdFromMasterId(masterId: number, version?: string) {
    return this.uiCustomizeEndpoint.getGsiIdFromMasterId(masterId, version);
  }

  deleteMyresourceImage(payLoad: any) {
    return this.uiCustomizeEndpoint.deleteMyresourceImage(payLoad);
  }

  saveCustomizeHeader(payload: any, type: string) {
    /* istanbul ignore next */
    if (type === 'create') {
      this.loader.show();
      // Delete existing header
      /* istanbul ignore next */
      this.tenantCustomEndpointService
        .deleteHeaderFromRole(payload.roleId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            /* istanbul ignore next */
            if (res?.status === 200) {
              // Save header data
              this.saveHeaderData(payload);
            }
          },
          (err: any) => {
            this.loader.hide();
            /* istanbul ignore next */
            this.alertService.showToaster(err?.message, '', 'error');
          }
        );
    } else if (type === 'update') {
      this.saveHeaderData(payload);
    }
  }

  // Save header data
  saveHeaderData(payload: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.uiCustomizeEndpoint
      .postCustomizeHeader(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(res?.message, '', 'success');
          /* istanbul ignore next */
          if (res.result) res.result.roleName = payload?.roleName;
          this.savedHeaderSubject.next(res);
          /* istanbul ignore next */
          this.detectSiteMetaData(res?.result);
        },
        (err: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }

  // Detect any existing sitemeta data
  detectSiteMetaData(headerData: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.uiCustomizeEndpoint
      .getMetaData(headerData?.roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(res?.message, '', 'success');
          let data: any = {};
          // Sitemeta data is already exists
          /* istanbul ignore next */
          if (res?.result) {
            // Update sitemeta data for role
            data = res.result;
            this.removeUnnecessaryVarsFromHeader(data);
            /* istanbul ignore next */
            data.headerId = headerData?.id;
          }
          // Need to create sitemeta data
          else {
            // Create new sitemeta data for role
            /* istanbul ignore next */
            data = {
              roleId: headerData?.roleId,
              roleName: headerData?.roleName,
              headerId: headerData?.id,
            };
          }
          // Create new sitemeta data
          this.postSiteMetaData(data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
              (res: any) => {
                if (res) {
                  this.alertService.showToaster('Save header data into sitemeta data', '', 'success');
                }
              },
              (err: any) => {
                this.loader.hide();
                /* istanbul ignore next */
                this.alertService.showToaster(err?.message, '', 'error');
              }
            );
        },
        (err: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }

  // Detect any existing sitemeta data
  deleteDefaultPageFromSiteMetaData(roleData: any) {
    this.loader.show();
    this.uiCustomizeEndpoint
      .getMetaData(roleData?.roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(res?.message, '', 'success');
          let data: any = {};
          // Sitemeta data is already exists
          /* istanbul ignore next */
          if (res?.result) {
            // Update sitemeta data for role
            data = res.result;
            this.removeUnnecessaryVarsFromHeader(data);
            /* istanbul ignore next */
            if (data?.defaultPageId) {
              delete data.defaultPageId;
            }
            // Update sitemeta data
            this.postSiteMetaData(data)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(
                (res: any) => {
                  if (res) {
                    this.alertService.showToaster('Save header data into sitemeta data', '', 'success');
                  }
                },
                /* istanbul ignore next */
                (err: any) => {
                  this.loader.hide();
                  /* istanbul ignore next */
                  this.alertService.showToaster(err?.message, '', 'error');
                }
              );
          }
        },
        /* istanbul ignore next */
        (err: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }

  removeUnnecessaryVarsFromHeader(header: any) {
    /* istanbul ignore next */
    if (header?.createdAt) {
      delete header.createdAt;
    }
    /* istanbul ignore next */
    if (header?.modifiedAt) {
      delete header.modifiedAt;
    }
    /* istanbul ignore next */
    if (header?.tenant) {
      delete header.tenant;
    }
  }

  getHeaderById(headerId: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.tenantCustomEndpointService
      .getHeaderById(headerId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res?.status === 200 && res?.result) {
            let data = res.result;
            this.removeUnnecessaryVarsFromHeader(data);
            this.headerDataSubject.next(data);
          }
        },
        (err: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }
  saveCollectionMCC(data: any) {
    return this.uiCustomizeEndpoint.saveCollectionMCC(data);
  }
  reMoveItem(data: any, name: string) {
    return this.uiCustomizeEndpoint.removeItemd(data, name);
  }
  deleteCollection(name: any) {
    return this.uiCustomizeEndpoint.deleteCollection(name);
  }
  getAllCollections(pageNo: number, pageSize: number, searchCrietria: string) {
    return this.uiCustomizeEndpoint.getAllCollections(pageNo, pageSize, searchCrietria);
  }

  getAllDashboards(collectionName: string) {
    this.loader.show();
    this.uiCustomizeEndpoint
      .getAllDashboards(collectionName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getDashboards.next(res);
      });
  }

  getDashboardCollections(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    this.loader.show();
    this.uiCustomizeEndpoint
      .getDashboardCollections(pageNumber, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.collections.next(res);
      });
  }

  getAllDashboardWidgets(id: any) {
    return this.uiCustomizeEndpoint.getAllDashboardWidgets(id);
  }

  getAllCustomTemplates(templateType: string) {
    return this.uiCustomizeEndpoint.getAllCustomTemplates(templateType);
  }
  saveFooter(data: any, deviceType: DeviceType = 'DESKTOP', isCopy: boolean) {
    return this.uiCustomizeEndpoint.saveFooter(data, deviceType, isCopy);
  }
  fetchFooterByRoleId(id: string, deviceType: DeviceType = 'DESKTOP') {
    return this.uiCustomizeEndpoint.fetchFooterByRoleId(id, deviceType);
  }
  fetchFooter(id: string, deviceType: DeviceType = 'DESKTOP') {
    return this.uiCustomizeEndpoint.fetchFooter(id, deviceType);
  }

  getAggrigateValues(data: any, isTxnType: any) {
    return this.uiCustomizeEndpoint.getAggrigateValues(data, isTxnType);
  }
  getAttributes(data: any, isTxnType: any) {
    return this.uiCustomizeEndpoint.getAttributes(data, isTxnType);
  }
  getMCCDashboard() {
    this.loader.show();
    this.uiCustomizeEndpoint
      .getMCCDashboard()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res?.status == 500) {
          this.alertService.showToaster(res.message, '', 'error');
        } else if (res?.status == 200) {
          this.supersetDashboardsList.next(res);
          this.alertService.showToaster(res.message, '', 'success');
        }
      });
  }

  detectScreenSize(): string {
    let screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 'desktop';
    } else if (screenWidth >= 768) {
      return 'tab';
    } else {
      return 'mobile';
    }
  }
  saveQuickLink(data: any) {
    return this.uiCustomizeEndpoint.saveQuickLink(data);
  }
  getLanguageData(data: any, language: string) {
    return this.uiCustomizeEndpoint.getLanguageData(data, language);
  }
  fetchQuickLink(id: any) {
    this.uiCustomizeEndpoint
      .fetchQuickLink(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.quickLinkData.next(res);
      });
  }
  deleteQuickLinks(roleId: any, deviceType: string) {
    return this.uiCustomizeEndpoint.deleteQuickLinks(roleId, deviceType);
  }

  getMapFiltersData(entityCardId: string, pageSize: number, pagenum: number, searchText?: string) {
    /*istanbul ignore next*/
    if (searchText) {
      return this.uiCustomizeEndpoint.getMapFiltersData(entityCardId, pageSize, pagenum, searchText);
    } else {
      return this.uiCustomizeEndpoint.getMapFiltersData(entityCardId, pageSize, pagenum);
    }
  }

  saveOrUpdateFilter(filterName: any, filterId: any, data: any) {
    return this.uiCustomizeEndpoint.saveOrUpdateFilter(filterName, filterId, data);
  }

  deleteFilter(entityCardId: any, filterId: any) {
    return this.uiCustomizeEndpoint.deleteFilter(entityCardId, filterId);
  }

  getFilter(entityCardId: any, filterId: any) {
    return this.uiCustomizeEndpoint.getFilter(entityCardId, filterId);
  }
  validateGsi(id: number) {
    return this.uiCustomizeEndpoint.validateGsi(id);
  }
  getGsiTrxData(masterId: number, id: number) {
    return this.uiCustomizeEndpoint.getGsiTrxData(masterId, id);
  }
  getGSIDetailsById(id: number) {
    return this.uiCustomizeEndpoint.getGSIDetailsById(id);
  }
}

