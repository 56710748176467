import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const REGEX = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
@Component({
  selector: 'node-attribute-duration',
  templateUrl: './attribute-duration.component.html',
  styleUrls: ['./attribute-duration.component.scss'],
})
export class AttributeDurationComponent implements OnInit {
  labels: any;
  ngUnsubscribe = new Subject();
  durationValue: any = {
    days: null,
    hours: null,
    minutes: null,
  };
  @Input() defaultValue: any;
  @Output() durationChange = new EventEmitter<string>();

  constructor(private translator: TranslatorService) {}

  ngOnInit(): void {
    this.detectLanguageChange();
    this.parseDurationFormat();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  parseDurationFormat() {
    /*istanbull ignore else*/
    if (this.defaultValue != '') {
      const [, , , , day, hour, min] = this.defaultValue?.match(REGEX) || [];
      this.durationValue.days = parseInt(day) || null;
      this.durationValue.hours = parseInt(hour) || null;
      this.durationValue.minutes = parseInt(min) || null;
    }
  }

  createDurationFormat(days: any, hours: any, minutes: any) {
    let durationFormat = '';

    if (!days && !hours) {
      if (minutes) durationFormat = `PT${minutes}M`;
      else durationFormat = `P`;
    } else if (!days) {
      if (minutes) durationFormat = `PT${hours}H${minutes}M`;
      else durationFormat = `PT${hours}H`;
    } else if (!hours) {
      if (minutes) durationFormat = `P${days}DT${minutes}M`;
      else durationFormat = `P${days}D`;
    } else {
      if (minutes) durationFormat = `P${days}DT${hours}H${minutes}M`;
      else durationFormat = `P${days}DT${hours}H`;
    }

    return durationFormat;
  }

  onDurationChange() {
    let durationFormat = '';

    /* istanbul ignore next */
    if (
      this.durationValue?.days !== null ||
      this.durationValue?.hours !== null ||
      this.durationValue?.minutes !== null
    ) {
      /* istanbul ignore next */
      let days: any = this.durationValue?.days;
      /* istanbul ignore next */
      let hours: any = this.durationValue?.hours;
      /* istanbul ignore next */
      let minutes: any = this.durationValue?.minutes;
      durationFormat = this.createDurationFormat(days, hours, minutes);
    }

    this.durationChange.emit(durationFormat);
  }

  onKeyPress(event: any) {
    return event.charCode >= 48 && event.charCode != 101 && event.charCode != 69;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
