<div class="p-tree-folder-upload" #mainTree>
  <p-tree
    [value]="nodes"
    [loading]="loading"
    selectionMode="checkbox"
    class="w-full md:w-30rem"
    (selectionChange)="handleFolderSelection($event)"
    [(selection)]="selectedFiles"
    (onNodeExpand)="getNodes($event)"
  >
    <ng-template let-node pTemplate="default">
      <div class="file-name nh-column-gap-16 d-flex justify-content-between">
        <p>{{ node.label }}</p>
        <div class="d-flex  nh-column-gap-12" >
          <button class="upload-button" *ngIf="!node?.data?.isFolder" (click)="showDeletePopUp(node); $event?.stopPropagation()">
            delete
          </button>
          <button class="upload-button" *ngIf="node?.data?.isFolder" (click)="openSideBar(node); $event?.stopPropagation()">
            upload
          </button>
        </div>
       
      </div>
    </ng-template>
  </p-tree>
</div>
<ng-template #statusPopUp>
  <div mat-dialog-content class="folderbody">
    <h3 class="fheading nh-mb-24">Uploading</h3>
    <p class="folder-count nh-mb-8">
      {{ totalNumberOfFiles - numberOfFiles }} / {{ totalNumberOfFiles }} Files being uploaded…
    </p>
    <div class="progress-cont d-flex" *ngIf="!(progress == 0)">
      <div class="progress" [style.width]="progress + '%'"></div>
      <p class="progress-text">{{ progress }}%</p>
      <!-- Added class for progress percentage -->
    </div>
    <!-- <div class="position-relative fuploading">
      <div class="fu-uploading" [ngStyle]="{
      'animation-delay': '12ms'
      }"
      ></div>
    </div>
    <p class="time-left nh-mt-10">Time Left : 12 Sec</p> -->
  </div>
  <div mat-dialog-actions class="folderaction">
    <button class="fcancel cursor-pointer" (click)="closePopUp()">Cancel</button>
  </div>
</ng-template>
<ng-template #deletePopUp>
  <div mat-dialog-content class="folderbody">
    <h3 class="fheading nh-mb-24">Are you sure you want to delete the file?</h3>
  </div>
  <div mat-dialog-actions class="folderaction d-flex nh-column-gap-16">
    <button class="fcancel cursor-pointer" (click)="deleteFolder(selectedDeleteNode)">Yes</button>
    <button class="fcancel cursor-pointer" (click)="closePopUp()">No</button>
  </div>
</ng-template>
<p-sidebar appendTo="body" styleClass="p-sidebar-folderupload" [(visible)]="isUpload" position="right">
  <div class="folderupload" *ngIf="isUpload">
    <!-- <mat-icon (click)="closeSideBar()">close</mat-icon> -->
    <h3 class="heading">Upload Documents</h3>
    <p class="label-name nh-pb-8">Select Action</p>
    <p-dropdown
      [options]="uploadOptions"
      [(ngModel)]="selectedUploadOption"
      placeholder="Select"
      class="select-action"
    ></p-dropdown>
    <p class="label-name nh-pb-8 nh-mt-24">Upload</p>
    <app-file-upload
      [fileUploadPath]="uploadPath?.data?.fullPath"
      [isFolderUpload]="true"
      [isFolder]="selectedUploadOption == 'Folder' ? true : false"
      (fileUploadPayload)="recievePayLoad($event)"
    >
    </app-file-upload>
  </div>
</p-sidebar>
