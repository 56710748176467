import { Injectable, OnDestroy } from '@angular/core';
import { RolesEndpointService } from './roles-endpoint.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService } from './../loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class RolesFacadeService implements OnDestroy {
  rolesData: any = [];
  rolesDataSubject = new BehaviorSubject(this.rolesData);
  rolesData$ = this.rolesDataSubject.asObservable();
  singleRoleSubject = new BehaviorSubject('');
  singleRoleData$ = this.singleRoleSubject.asObservable();

  viewToUpdateRoles = new BehaviorSubject('');
  viewToUpdateRoles$ = this.viewToUpdateRoles.asObservable();

  selectedBookDetials = new BehaviorSubject('');
  selectedBookDetials$ = this.selectedBookDetials.asObservable();
  rolesPaginateSubject = new Subject();
  rolesPaginateSubject$ = this.rolesPaginateSubject.asObservable();

  rolesMccPaginateSubject = new Subject();
  rolesMccPaginateSubject$ = this.rolesMccPaginateSubject.asObservable();

  mccPageCustomizationBooksSubject = new Subject();
  mccPageCustomizationBooksSubject$ = this.mccPageCustomizationBooksSubject.asObservable();

  rolesMccConfigSubject = new Subject();
  rolesMccConfigSubject$ = this.rolesMccConfigSubject.asObservable();

  private assigendBooksSubject = new Subject();
  assigendBooks$ = this.assigendBooksSubject.asObservable();
  rolesAllPaginateSubject = new Subject();
  rolesAllPaginate$ = this.rolesAllPaginateSubject.asObservable();
  rolesByBookIdSubject = new Subject();
  rolesByBookId$ = this.rolesByBookIdSubject.asObservable();
  singleRoleByIdSubject = new BehaviorSubject('');
  singleRoleById$ = this.singleRoleByIdSubject.asObservable();
  rolesInfoLayerSubject = new BehaviorSubject('');
  rolesInfoLayer$ = this.rolesInfoLayerSubject.asObservable();
  detailsByTargetRoleIdSubject = new Subject();
  detailsByTargetRoleId$ = this.detailsByTargetRoleIdSubject.asObservable();
  entitiesForDelegationSubject = new Subject();
  entitiesForDelegation$ = this.entitiesForDelegationSubject.asObservable();
  attributesForDelegationSubject = new Subject();
  attributesForDelegation$ = this.attributesForDelegationSubject.asObservable();
  unAssignUserToRoleSubject = new Subject();
  unAssignUserToRole$ = this.unAssignUserToRoleSubject.asObservable();
  unAssignGroupToRoleSubject = new Subject();
  unAssignGroupToRole$ = this.unAssignGroupToRoleSubject.asObservable();
  assignUserToRoleSubject = new Subject();
  assignUserToRole$ = this.assignUserToRoleSubject.asObservable();
  assignGroupToRoleSubject = new Subject();
  assignGroupToRole$ = this.assignGroupToRoleSubject.asObservable();
  rolesCountSubject = new Subject();
  rolesCount$ = this.rolesCountSubject.asObservable();
  isBookSubject = new Subject();
  isBookSubject$ = this.isBookSubject.asObservable();

  deleteRoleSubject = new Subject();
  rdeleteRole$ = this.deleteRoleSubject.asObservable();

  private ngUnsubscribe = new Subject();
  books: any = [];

  booksDataSubject = new BehaviorSubject(this.books);
  booksData$ = this.booksDataSubject.asObservable();

  private rolesDelegationListSubject = new Subject();
  rolesDelegationList$ = this.rolesDelegationListSubject.asObservable();

  private deleteDelegationSubject = new Subject();
  deleteDelegation$ = this.deleteDelegationSubject.asObservable();

  private delegationByIdSubject = new Subject();
  delegationById$ = this.delegationByIdSubject.asObservable();

  unassignLdapGroupOfRole = new Subject();
  unassignLdapGroupOfRole$ = this.unassignLdapGroupOfRole.asObservable();

  assignLdapGroupOfRole = new Subject();
  assignLdapGroupOfRole$ = this.assignLdapGroupOfRole.asObservable();

  getAllUnassignedLdapGroupsofRole = new Subject();
  getAllUnassignedLdapGroupsofRole$ = this.getAllUnassignedLdapGroupsofRole.asObservable();

  getAllAssignedLdapGroupsofRole = new Subject();
  getAllAssignedLdapGroupsofRole$ = this.getAllAssignedLdapGroupsofRole.asObservable();

  localRoles = new Subject();
  localRoles$ = this.localRoles.asObservable();

  deletedLocalRole = new Subject();
  deletedLocalRole$ = this.deletedLocalRole.asObservable();

  booksList = new Subject();
  booksList$ = this.booksList.asObservable();

  assignedOwners = new Subject();
  assignedOwners$ = this.assignedOwners.asObservable();

  assigneOwnerResponse = new Subject();
  assigneOwnerResponse$ = this.assigneOwnerResponse.asObservable();

  globalRolesTree = new Subject();
  globalRolesTree$ = this.globalRolesTree.asObservable();

  localRolesTree = new Subject();
  localRolesTree$ = this.localRolesTree.asObservable();

  saveIRDRResponse = new Subject();
  saveIRDRResponse$ = this.saveIRDRResponse.asObservable();

  roleBookList = new Subject();
  roleBookList$ = this.roleBookList.asObservable();

  bookTreeData = new Subject();
  bookTreeData$ = this.bookTreeData.asObservable();

  allOwnersData = new Subject();
  allOwnersData$ = this.allOwnersData.asObservable();

  bookRolesTree = new Subject();
  bookRolesTree$ = this.bookRolesTree.asObservable();

  viewLocalRoleData = new Subject();
  viewLocalRoleData$ = this.viewLocalRoleData.asObservable();

  isAdminSubject = new BehaviorSubject(false);
  isAdmin$ = this.isAdminSubject.asObservable();

  isDashboardSubject = new BehaviorSubject(false);
  isDashboard$ = this.isDashboardSubject.asObservable();

  isImportSubject = new BehaviorSubject(false);
  isImport$ = this.isImportSubject.asObservable();

  mergeOrMapRolesSubject = new BehaviorSubject('');
  mergeOrMapRolesObs$ = this.mergeOrMapRolesSubject.asObservable();

  promoteMccSuccess = new Subject();
  promoteMccSuccess$ = this.promoteMccSuccess.asObservable();

  closeDialogImport = new Subject();
  closeDialogImport$ = this.closeDialogImport.asObservable();

  constructor(
    private alertService: AlertService,
    private router: Router,
    private loader: LoaderService,
    private rolesEndPointService: RolesEndpointService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  upadteSlectedBook(book: any) {
    this.selectedBookDetials.next(book);
  }

  getIsBookSubject(data: any) {
    this.isBookSubject.next(data);
  }

  updateSingleRoleData(roleName: string) {
    this.singleRoleSubject.next(roleName);
  }

  navigateToUpdate(roleData: any) {
    this.viewToUpdateRoles.next(roleData);
  }

  loadAllRoles() {
    this.loader.show();
    this.rolesEndPointService
      .getAllRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.rolesDataSubject.next(res);
      });
  }
  getLoadAllRoles(data: any) {
    this.rolesDataSubject.next(data);
  }
  loadPaginateRoles(
    pageNumber: number,
    pageSize: number,
    searchString: string,
    isActive?: boolean,
    viewGuestRole?: boolean
  ) {
    this.loader.show();
    this.rolesEndPointService
      .getPaginateRoles(pageNumber, pageSize, searchString, isActive, viewGuestRole)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesPaginateSubject.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  mccPageCustomizationBooks(
    pageNumber: number,
    pageSize: number,
    searchString: string,
    roleid: number,
    isActive?: boolean
  ) {
    this.loader.show();
    this.rolesEndPointService
      .getMCCPageCustomizationBooks(pageNumber, pageSize, searchString, roleid, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.mccPageCustomizationBooksSubject.next(res);
        } else {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }
  // MCC Roles

  loadMccPaginateRoles(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    this.loader.show();
    this.rolesEndPointService
      .getMccPaginateRoles(pageNumber, pageSize, searchString, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesMccPaginateSubject.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  mccConfigRoles(configData: any) {
    this.loader.show();
    this.rolesEndPointService
      .getMccConfigRoles(configData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesMccConfigSubject.next(res);
        this.alertService.showToaster(res.message, '', 'success');
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  getLoadPaginateRoles(data: any) {
    this.rolesPaginateSubject.next(data);
  }
  loadAllPaginateRoles(pageNumber: number, pageSize: number, searchString: string) {
    this.rolesEndPointService
      .getPaginateRoles(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.rolesAllPaginateSubject.next(res);
      });
  }

  getLoadAllPginateRoles(data: any) {
    this.rolesAllPaginateSubject.next(data);
  }
  loadRoleBooks(roleId: any) {
    //load books by role id
    this.loader.show();
    this.rolesEndPointService
      .getBooksforRoleb2c(roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assigendBooksSubject.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }
  loadRoleForBook(bookId: any, pageIndex: number, pageSize: number, searchString: string, isActive?: boolean) {
    //load books by book id
    this.loader.show();
    this.rolesEndPointService
      .getRolesforBook(bookId, pageIndex, pageSize, searchString, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: any) => {
        this.loader.hide();
        this.rolesPaginateSubject.next(r);
        if (!r) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  loadBookRoles(bookId: any) {
    //load books by book id
    this.loader.show();
    this.rolesEndPointService
      .getRolesAttachedToBook(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.loader.hide();
        this.rolesPaginateSubject.next(response);
        if (!response) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }
  createRoleb2c(postData: any, bookList: any) {
    // create new roles
    this.loader.show();
    this.rolesEndPointService
      .createRoleb2c(postData, bookList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.loadAllRoles();
      });
  }

  createRole(postData: any) {
    this.loader.show();
    this.rolesEndPointService
      .createRole(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: any) => {
        this.loader.hide();
        this.alertService.showToaster(r.message, '', 'success');
        this.loadAllRoles();
      });
  }
  updateRole(updateData: any, value?: string) {
    this.loader.show();
    this.rolesEndPointService
      .updateRole(updateData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (value == 'goToMapGroups') {
          this.router.navigateByUrl(`admin/mapgroups`);
          this.alertService.showToaster(res.message, '', 'success');
        }
        // else if (value == 'goToDelegation') {
        //   this.router.navigateByUrl(`admin/delegation`);
        //   this.alertService.showToaster(res.message, '', 'success');
        // }
        else {
          this.alertService.showToaster(res.message, '', 'success');
        }
      });
  }

  deleteRole(roleId: number) {
    //delete b2c role
    this.loader.show();
    this.rolesEndPointService
      .deleteRole(roleId, true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.status, '', 'success');
          this.deleteRoleSubject.next(res);
        },
        (err: any) => {
          this.loader.hide();
          this.alertService.showToaster(err.error, '', 'error');
        }
      );
  }
  /**
   * @description delete role functionality
   * @param roleId role id to be deleted
   * @param wishToContinue boolean true or false
   * @returns
   */
  deleteRolewishToContinue(roleId: number, wishToContinue: boolean) {
    return this.rolesEndPointService.deleteRole(roleId, wishToContinue);
  }
  deleteRoleb2c(roleId: number) {
    this.loader.show();
    this.rolesEndPointService
      .deleteRoleb2c(roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((respon: any) => {
        this.loader.hide();
        this.alertService.showToaster(respon.status, '', 'success');
        this.deleteRoleSubject.next(respon);
      });
  }

  importRoleFromCSV(postData: any) {
    this.loader.show();
    this.rolesEndPointService
      .importRoleFromCSV(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.status, '', 'success');
        this.loader.hide();
      });
  }

  loadRolesCount() {
    this.loader.show();
    this.rolesEndPointService
      .getRolesCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.rolesCountSubject.next(res);
      });
  }

  unAssignUserToRole(roleId: number, userId: number) {
    this.loader.show();
    this.rolesEndPointService
      .unAssignUserToRole(roleId, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unAssignUserToRoleSubject.next(res);
      });
  }

  unAssignGroupToRole(roleId: number, groupId: number) {
    this.loader.show();
    this.rolesEndPointService
      .unAssignGroupToRole(roleId, groupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unAssignGroupToRoleSubject.next(res);
      });
  }

  assignUserToRole(roleId: any, postData: any) {
    this.loader.show();
    this.rolesEndPointService
      .assignUserToRole(roleId, postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignUserToRoleSubject.next(res);
      });
  }

  assignGroupToRole(roleId: any, postData: any) {
    this.loader.show();
    this.rolesEndPointService
      .assignGroupToRole(roleId, postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignGroupToRoleSubject.next(res);
      });
  }

  // manage roles by bookId
  getAllRolesByBookId(pageNumber: number, pageSize: number, searchString: string, bookid: number) {
    this.loader.show();
    this.rolesEndPointService
      .getAllRolesByBookId(pageNumber, pageSize, searchString, bookid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesByBookIdSubject.next(res);
      });
  }

  getAllRolesBySolutionId(pageNumber: number, pageSize: number, searchString: string, solutionid: number) {
    this.loader.show();
    this.rolesEndPointService
      .getAllRolesBySolutionId(pageNumber, pageSize, searchString, solutionid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesByBookIdSubject.next(res);
      });
  }

  saveSingleRoleData(role: any) {
    this.singleRoleByIdSubject.next(role);
  }

  getInformationLayer(roleId: number) {
    this.loader.show();
    this.rolesEndPointService
      .getInformationLayer(roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesInfoLayerSubject.next(res);
      });
  }

  getDetailsByTargetRoleId(fromRoleId: number, toRoleId: number) {
    this.loader.show();
    this.rolesEndPointService
      .getDetailsByTargetRoleId(fromRoleId, toRoleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.detailsByTargetRoleIdSubject.next(res);
      });
  }

  getEntitiesForDelegation(bookId: number, pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.rolesEndPointService
      .getEntitiesForDelegation(bookId, pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entitiesForDelegationSubject.next(res);
      });
  }

  getAttributesForDelegation(entityName: string, pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.rolesEndPointService
      .getAttributesForDelegation(entityName, pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.attributesForDelegationSubject.next(res);
      });
  }

  createDelegation(postData: any) {
    this.loader.show();
    this.rolesEndPointService
      .createDelegation(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resp: any) => {
        this.loader.hide();
        this.alertService.showToaster(resp.message, '', 'success');
        this.loadAllRoles();
      });
  }

  loadRoleDelegations(pageNumber: number, pageSize: number, searchString: string, roleId: any) {
    this.loader.show();
    this.rolesEndPointService
      .loadRoleDelegations(pageNumber, pageSize, searchString, roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.rolesDelegationListSubject.next(res);
      });
  }

  deleteDelegation(delegationId: any) {
    this.loader.show();
    this.rolesEndPointService
      .deleteDelegation(delegationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          this.deleteDelegationSubject.next(res);
        }
      });
  }

  getDelegationById(delegationId: any) {
    this.loader.show();
    this.rolesEndPointService
      .getDelegationById(delegationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          this.delegationByIdSubject.next(res);
        }
      });
  }

  getAllUnassignedLDAPGroupsForRoles(pageNumber: number, pageSize: number, searchString: string, roleId: any) {
    this.loader.show();
    this.rolesEndPointService
      .getAllUnassignedLDAPGroupsForRoles(pageNumber, pageSize, searchString, roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          this.getAllUnassignedLdapGroupsofRole.next(res);
        }
      });
  }

  getAllAssignedLDAPGroupsForRoles(pageNumber: number, pageSize: number, searchString: string, roleId: any) {
    this.loader.show();
    this.rolesEndPointService
      .getAllAssignedLDAPGroupsForRoles(pageNumber, pageSize, searchString, roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          this.getAllAssignedLdapGroupsofRole.next(res);
        }
      });
  }

  unassignLdapGroupForRoles(roleId: number, groupId: number) {
    this.loader.show();
    this.rolesEndPointService
      .unassignLdapGroupForRoles(roleId, groupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unassignLdapGroupOfRole.next(res);
      });
  }
  assignLdapGroupsForRoles(roleId: any, postData: any) {
    this.loader.show();
    this.rolesEndPointService
      .assignGroupToRole(roleId, postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignLdapGroupOfRole.next(res);
      });
  }

  /**
   * Gets local roles
   * @param pageNumber
   * @param pageSize
   * @param searchString
   * @param bookId
   */
  getLocalRoles(pageNumber: number, pageSize: number, searchString: string, isActive: boolean) {
    return this.rolesEndPointService.getPaginateRoles(pageNumber, pageSize, searchString, isActive);
  }
  //getting b2c roles
  getAllb2cRoles(pageNumber: number, pageSize: number, searchString: string, isActive: boolean) {
    return this.rolesEndPointService.getAllb2cRoles(pageNumber, pageSize, searchString, isActive);
  }

  loadAllb2cRoles(pageNumber: number, pageSize: number, searchString: string, isActive: boolean) {
    //loading b2c roles into obserable
    this.loader.show();
    this.rolesEndPointService
      .getAllb2cRoles(pageNumber, pageSize, searchString, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((re: any) => {
        this.loader.hide();
        this.rolesPaginateSubject.next(re);
        if (!re) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  /**
   * Deletes local roles
   * @param role
   */
  deleteLocalRoles(role: any) {
    this.loader.show();
    this.rolesEndPointService
      .deleteBookRole(role)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.status, '', 'success');
        this.deletedLocalRole.next(res);
      });
  }

  /**
   * Gets all book tree pageable
   * @param pageNumber
   * @param pageSize
   * @param searchText
   */
  getAllBookTreePageable(pageNumber: number, pageSize: number, searchText: string) {
    this.loader.show();
    this.rolesEndPointService
      .getAllBookTreePageable(pageNumber, pageSize, searchText)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.booksList.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  /**
   * Gets book tree by id
   * @param bookId
   */
  getBookTreeById(bookId: number, roleId?: number, searchCriteria?: string) {
    this.loader.show();
    this.rolesEndPointService
      .getBookTreeById(bookId, roleId, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.bookTreeData.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  /**
   * Saves book role
   * @param payload
   */
  saveBookRole(payload: any) {
    this.loader.show();
    this.rolesEndPointService
      .saveBookRole(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((a: any) => {
        this.loader.hide();
        this.alertService.showToaster(a.message, '', 'success');
        this.loadAllRoles();
      });
  }

  /**
   * Gets assigned owners pageable
   * @param pageNumber
   * @param pageSize
   * @param searchCriteria
   * @param payload
   */
  getAssignedOwnersPageable(pageNumber: number, pageSize: number, searchCriteria: string, payload: any) {
    this.loader.show();
    this.rolesEndPointService
      .getAssignedOwnersPageable(pageNumber, pageSize, searchCriteria, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignedOwners.next(res);
      });
  }

  /**
   * Assigns book owners
   * @param payload
   */
  assignBookOwners(payload: any) {
    this.loader.show();
    this.rolesEndPointService
      .assignBookOwners(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assigneOwnerResponse.next(res);
        if (res.status === 200) {
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }

  /**
   * Gets all roles hierarchy
   */
  getAllRolesHierarchy() {
    this.loader.show();
    this.rolesEndPointService
      .getAllRolesHierarchy()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.globalRolesTree.next(res);
      });
  }

  /**
   * Gets book role tree
   * @param bookId
   */
  getBookRoleTree(bookId: any) {
    this.loader.show();
    this.rolesEndPointService
      .getBookRoleTree(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.localRolesTree.next(res);
      });
  }

  /**
   * Gets book role tree
   * @param bookId
   */
  LoadAssignedBooks(bookId: any) {
    //load aaigned books
    this.loader.show();
    this.rolesEndPointService
      .getBooksforRole(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.localRolesTree.next(res);
      });
  }

  /**
   * Saves irdr
   * @param payload
   */
  saveIRDR(payload: any) {
    this.loader.show();
    this.rolesEndPointService
      .saveIRDR(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.saveIRDRResponse.next(res);
        if (res.status === 200) {
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }

  /**
   * Gets irdrfor book or solution
   * @param bookId
   * @param gsiId
   */
  getIRDRForBookOrSolution(bookId: any, gsiId: any) {
    this.loader.show();
    this.rolesEndPointService
      .getIRDRForBookOrSolution(bookId, gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.bookRolesTree.next(res);
      });
  }

  /**
   * Gets irdrfor role pageable
   * @param pageNumber
   * @param pageSize
   * @param roleId
   * @param searchText
   */
  getIRDRForRolePageable(pageNumber: number, pageSize: number, roleId: any, searchText: string) {
    this.loader.show();
    this.rolesEndPointService
      .getIRDRForRolePageable(pageNumber, pageSize, roleId, searchText)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.roleBookList.next(res);
      });
  }

  /**
   * Gets all owners
   * @param pageNumber
   * @param pageSize
   * @param searchText
   * @param payload
   */
  getAllOwners(pageNumber: number, pageSize: number, searchText: string, payload: any) {
    this.loader.show();
    this.rolesEndPointService
      .getAllOwners(pageNumber, pageSize, searchText, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allOwnersData.next(res);
      });
  }

  /**
   * Sets local role details
   * @param data
   */
  setLocalRoleDetails(data: any) {
    this.viewLocalRoleData.next(data);
  }

  /**
   * Gets book tree with IRDR's
   * @param pageNumber
   * @param pageSize
   * @param roleId
   * @param searchText
   * @returns
   */
  getBookTreePageable(pageNumber: number, pageSize: number, roleId: any, searchText: string) {
    return this.rolesEndPointService.getBookTreePageable(pageNumber, pageSize, roleId, searchText);
  }

  /**
   * Loads books
   * @param pageNumber
   * @param pageSize
   * @param searchString
   */
  loadPaginateBooksbySearch(pageNumber: number, pageSize: number, searchString: string) {
    //geting all books
    //this.loader.show();
    this.rolesEndPointService
      .getPaginateBooks(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.booksDataSubject.next(res);
      });
  }

  setIsAdmin(data: boolean) {
    this.isAdminSubject.next(data);
  }
  setIsDashboard(data: boolean) {
    this.isDashboardSubject.next(data);
  }
  setIsImport(data: boolean) {
    this.isImportSubject.next(data);
  }

  getPublicRoles(tenant: any) {
    return this.rolesEndPointService.getPublicRoles(tenant);
  }
  deletCustomRole(siteLayoutId: any) {
    return this.rolesEndPointService.deleteCustomRole(siteLayoutId);
  }
  loadUnassigneSubtenant(id: any) {
    return this.rolesEndPointService.loadUnassigneSubtenant(id);
  }
  onAssigningSubTenant(payload: any) {
    return this.rolesEndPointService.onAssigningSubTenant(payload);
  }
  mappingRoles(roleid: any, subtenantName: any) {
    return this.rolesEndPointService.mappingRoles(roleid, subtenantName);
  }
  mapRoleToParent(payload: any) {
    return this.rolesEndPointService.mapRoleToParent(payload);
  }

  getAdminsList() {
    return this.rolesEndPointService.getAdminsList();
  }

  saveEntityCardConfigureChatbot(TenantName: any, payload: any) {
    return this.rolesEndPointService.postEntityCardConfigureChatbot(TenantName, payload);
  }

  mergeOrMapRoles(action: string) {
    this.mergeOrMapRolesSubject.next(action);
  }
  promoteMcc(payload: any, type: string, role: string) {
    this.rolesEndPointService
      .promoteMCC(payload, type, role)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          this.promoteMccSuccess.next(res);
        },
        (err: any) => {
          this.alertService.showToaster(err?.error?.message, '', 'error');
        }
      );
  }

  closeDialog(val: boolean) {
    this.closeDialogImport.next(val);
  }
  getTnCbyClientIdAndRole(clientId: string, roleName: string) {
    return this.rolesEndPointService.getTnCbyClientIdAndRole(clientId, roleName);
  }
}
