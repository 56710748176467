<p-accordion>
  <p-accordionTab header="Select Folder " class="Select-folder">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">Folder</span>
      </div>
      <input
        type="text"
        [(ngModel)]="selectedFolders"
        class="form-control"
        aria-label="Amount (to the nearest dollar)"
        ngDefaultControl
      />
      <div class="input-group-append" (click)="createFolder()">
        <span class="input-group-text">
          <mat-icon>
            create_new_folder
          </mat-icon>
        </span>
      </div>
    </div>
    <p-tree #ptree [value]="folders" [selectionMode]="'single'" (onNodeSelect)="nodeSelect($event)"></p-tree>
  </p-accordionTab>
  <p-accordionTab header="Upload Files " [formGroup]="group">
    <h3 class="upload-file">Upload files to the Entity</h3>
    <app-file-upload-transaction
      [formControlName]="field?.attribute?.name"
      id="{{ field?.attribute?.name }}"
      [fileUploadPath]="folderpath"
      (fileUploadPayload)="recieveFileUploadPayload($event)"
      [field]="field"
      [isFolder]="true"
    ></app-file-upload-transaction>
  </p-accordionTab>
  <p-accordionTab header="Access Type">
    <div class="Acess-type">
      <div class="acess-rights">
        <span class="heading">Set Access Rights</span>
        <div class="select-acess">
          <span>Set access</span>
          <span class="select">
            <select name="accessType1" [(ngModel)]="accessType" (change)="changeAccess($event, 'access')">
              <option value="">Select</option>
              <option value="USER">USER</option>
              <option value="ROLE">ROLE</option>
            </select>
          </span>
        </div>
      </div>
      <div class="search">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Search</mat-label>
          <input
            (keyup)="changeAccess($event)"
            [(ngModel)]="searchString"
            type="text"
            placeholder="Pick one"
            aria-label="Number"
            matInput
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            name="selectedPermission"
            [(ngModel)]="selectedPermission"
            (optionSelected)="selectPermission($event)"
            #auto="matAutocomplete"
            ngDefaultControl
          >
            <mat-option *ngFor="let permission of permissionList" [value]="permission?.name">
              {{ permission?.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <button type="submit" class="searchButton">
          <span class="material-icons">search</span>
        </button>
      </div>
      <mat-chip-list #chipList aria-label="Fruit selection">
        <mat-chip
          *ngFor="let permission of selectedPermissions; let i = index"
          [removable]="true"
          (removed)="removePermission(i)"
        >
          {{ permission.name }}
          <button matChipRemove><mat-icon>cancel</mat-icon></button>
        </mat-chip>
      </mat-chip-list>
      <div class="select-type">
        <span class="type-heading">Access Type</span>
        <div class="select-radio">
          <mat-checkbox name="isWrite" [(ngModel)]="isWrite">
            Write
          </mat-checkbox>
          <mat-checkbox name="isRead" [(ngModel)]="isRead">
            Read
          </mat-checkbox>
          <span>
            <button class="Done-btn" (click)="submit()">
              Done
            </button>
          </span>
        </div>
      </div>
      <div class="global-acess">
        <div class="circle"></div>
        <div class="global-acess-type">
          <span class="global-acess-select">Anyone in the organization</span>
          <span class="text">Anyone in the organization can</span>
          <span class="radio-type">
            <mat-checkbox name="isWriteAll" [(ngModel)]="isWriteAll">
              Write
            </mat-checkbox>
            <mat-checkbox name="isReadAll" [(ngModel)]="isReadAll">
              Read
            </mat-checkbox>
          </span>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
