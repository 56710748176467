import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '@common-services';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  time: any;
  timeDetails: any;
  constructor() {}
  hours: any;
  subscription: Subscription;
  subject = new Subject();
  observable = this.subject.asObservable();

  seconds: any;
  minutes: any;
  timerColour: any;

  increaseTimer() {
    this.timeDetails.setTime(this.timeDetails?.getTime() + 1000);
    if (this.timeDetails?.getHours() < 10)
      this.hours = '0' + this.timeDetails?.getHours();
    else this.hours = this.timeDetails?.getHours();

    if (this.timeDetails?.getMinutes() < 10)
      this.minutes = '0' + this.timeDetails?.getMinutes();
    else this.minutes = this.timeDetails?.getMinutes();

    if (this.timeDetails?.getSeconds() < 10)
      this.seconds = '0' + this.timeDetails?.getSeconds();
    else this.seconds = this.timeDetails?.getSeconds();

    this.time = this.hours + ':' + this.minutes + ':' + this.seconds;
    this.timerColorChange();

    this.field.value = this.time;
    this.group.controls[this.field?.attribute?.name].setValue(
      this.field?.value
    );
    this.group.controls[this.field?.attribute?.name].markAsDirty();

    return this.observable;
  }

  ngOnInit(): void {
    /* istanbul ignore else */
    if (!this.field?.value) {
      this.field.value = '00:00:00';
    }

    this.timeDetails = new Date();

    let timeArray = this.field?.value.split(':');

    this.timeDetails?.setHours(timeArray[0]);

    this.timeDetails?.setMinutes(timeArray[1]);

    this.timeDetails?.setSeconds(timeArray[2]);
    this.time = this.field?.value;

    this.subscription = timer(0, 1000)
      .pipe(switchMap(() => this.increaseTimer()))
      .subscribe((result) => {});
    this.timerColorChange();
    setInterval(() => {
      this.timerColorChange();
    }, 1000);
  }
  timerColorChange(): void {
    let timerConfiguration = this.field?.configuration?.timerCustomConfiguration;
    if(timerConfiguration?.length > 0) {
      for (let i = 0; i < timerConfiguration?.length; i++) {
        let config = timerConfiguration?.[i];
        let currentTime = (this.hours * 60 + this.minutes) * 60 + this.seconds;
        if (config.operator == '>' && currentTime > config.value - 1) {
          this.timerColour = config.colour;
          break;
        } else if (config.operator == '<' && currentTime < config.value - 1) {
          this.timerColour = config.colour;
          break;
        } else if (config.operator == '<=' && currentTime <= config.value - 1) {
          this.timerColour = config?.colour;
          break;
        } else if (config.operator == '>=' && currentTime >= config.value - 1) {
          this.timerColour = config?.colour;
          break;
        }else if (config.operator == '=' && currentTime == config.value - 1) {
          this.timerColour = config?.colour;
          break;
        } else {
          this.timerColour = '#ffffff';
        }
      };
    }
  }
}
