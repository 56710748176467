import { NgModule } from '@angular/core';
import { ChipSingleComponent } from './chip-single.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChipModule } from 'primeng/chip';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [ChipSingleComponent],
  imports: [
    ControlsMaterialModule,
  ],
  exports: [ChipSingleComponent],
})
export class ChipSingleModule {
  static entry = {
    chipsingleselection: ChipSingleComponent,
  };
}
