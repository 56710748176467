import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { DeviceState } from './detect-device';
import { LoaderService } from '../loader/loader.service';
import {
  getLocalStorageValue,
  getTenantName,
} from '../../generic-folder/generic-functions';
import Web3 from 'web3';
declare global {
  interface Window {
    ethereum?: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionEndpointService extends CduiBaseService {
  metaMaskAddress: string;
  metaMaskTransactionHash: string;
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private deviceState: DeviceState,
    private loader: LoaderService
  ) {
    super(injector);
  }
  getNextChangeUnitByGSIId(postData: Object) {
    const headerDict = {
      'Login-Source': this.deviceState.getDeviceType(),
      'Operating-System': this.deviceState.getDeviceOs(),
      'Login-Type': 'login',
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http
      .post(this.api?.transactionUrl, postData, requestOptions)
      .pipe(pluck('result'));
  }

  getListChangeUnitByGSIId(
    postData: Object,
    gsiDraft: boolean,
    isDebug: boolean,
    isSubtransaction?: any
  ) {
    const headerDict = {
      'Login-Source': this.deviceState.getDeviceType(),
      'Operating-System': this.deviceState.getDeviceOs(),
      'Login-Type': 'login',
    };
    if (isDebug) {
      headerDict['Debug-Mode'] = 'true';
    }
    /* istanbul ignore next */
    if (localStorage.getItem('gsi-masterId')) {
      /* istanbul ignore next */
      headerDict['gsi-masterId'] = localStorage.getItem('gsi-masterId');
    }
    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    if (gsiDraft) {
      // Save draft data of change-unit
      return this.http.post(
        this.environment.dsdOrchUrl + '/execute/execute/txn-draft',
        postData,
        requestOptions
      );
    } else if (isSubtransaction) {
      // Fetch change unit data
      return this.http.post(
        this.environment.dsdOrchUrl +
          `/execute/change-unit/subTransactionalCU?triggerContextualID=${isSubtransaction?.triggerContextualID}`,
        postData,
        requestOptions
      );
    } else {
      // Fetch change unit data
      return this.http.post(
        this.environment.dsdOrchUrl + '/execute/change-unit',
        postData,
        requestOptions
      );
    }
  }

  navigateToCu(postData: any, isFromSubmit?: boolean) {
    return this.http.post(
      this.environment.dsdOrchUrl + '/execute/navigation/change-unit',
      postData
    );
  }

  saveAsDraft(postData: any) {
    return this.http.post(
      this.environment.dsdOrchUrl + '/execute/execute/txn-draft',
      postData
    );
  }

  // conditional potentiality
  attrConditionalPotentiality(postData: Object) {
    return this.http
      .post(
        this.environment.dsdOrchUrl + '/execute/refresh/dynamic-attributes',
        postData
      )
      .pipe(pluck('result'));
  }

  // multi entity support
  addMultipleEntities(postData: Object, entDetails: any) {
    return this.http.post(
      this.api.addMultiEntities + `${entDetails.id}&index=${entDetails.slot}`,
      postData
    );
  }

  getResumeChangeUnitByGSIId(postData: Object) {
    return this.http.post(this.api?.transactionresumeUrl, postData).pipe();
  }
  getPaginatedRecords(postData: Object, pageNumber: number, pageSize: number) {
    return this.http.post(
      this.api.getPaginatedRecords +
        `?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      postData
    );
  }
  reAssign(txnid: any, userid: any, assignComment: any, payload?: any) {
    return this.http.post(
      this.api?.reAssign +
        'transactionId=' +
        txnid +
        '&userId=' +
        userid +
        '&comment=' +
        assignComment,
      payload
    );
  }
  escalateTxn(txnid: any, escalateComment?: any, payload?: any) {
    return this.http.post(
      this.api?.escalateTxn + 'comment=' + escalateComment,
      payload
    );
  }
  cancelAutoSubmit(txnid: any, payload?: any) {
    return this.http.post(
      this.api?.cancelAutoSubmitTxn + 'transactionId=' + txnid,
      payload
    );
  }
  getCreatedTxn(
    pagenum: any,
    pageSize: any,
    searchText: any,
    originatingBookId?: any
  ) {
    originatingBookId = originatingBookId ? originatingBookId : '';
    return this.http.get(
      this.api?.getCreatedTxn +
        'page=' +
        pagenum +
        '&size=' +
        pageSize +
        '&containerCuName=' +
        searchText +
        '&originatingBookId=' +
        originatingBookId
    );
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  downloadFile(downloadUrl: string, fileToBeDownloaded?: string) {
    let fileName = downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1);
    let credentials;
    /* istanbul ignore next */
    if (this.IsJsonString(localStorage.getItem('credentials'))) {
      credentials = JSON.parse(localStorage.getItem('credentials'));
    }
    const options: {
      headers?: HttpHeaders;
      responseType: 'blob';
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ` + credentials?.token,
      }),
      responseType: 'blob',
    };
    let blob: any;
    let link = document.createElement('a');

    this.http.get(`${downloadUrl}`, options).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        blob = res;
        link.download = fileToBeDownloaded;
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        link.click();
        URL.revokeObjectURL(link.href);
      }
    });
  }
  getAssignedTxn(
    pagenum: any,
    pageSize: any,
    searchText: any,
    originatingBookId?: any
  ) {
    originatingBookId = originatingBookId ? originatingBookId : '';
    return this.http.get(
      this.api?.getAssignedTxn +
        'page=' +
        pagenum +
        '&size=' +
        pageSize +
        '&containerCuName=' +
        searchText +
        '&originatingBookId=' +
        originatingBookId
    );
  }
  getEscalatedTxn(
    pagenum: any,
    pageSize: any,
    searchText: any,
    originatingBookId?: any
  ) {
    originatingBookId = originatingBookId ? originatingBookId : '';
    return this.http.get(
      this.api?.getEscalatedTxn +
        'page=' +
        pagenum +
        '&size=' +
        pageSize +
        '&containerCuName=' +
        searchText +
        '&originatingBookId=' +
        originatingBookId
    );
  }
  getPendingTransactions(pagenum: any, pageSize: any, searchText: any) {
    return this.http.get(
      this.api?.pendingTransactionUrl +
        'containerCUName=' +
        searchText +
        '' +
        '&pageNo=' +
        pagenum +
        '&pageSize=' +
        pageSize
    );
  }

  getTransactionHistoryCuList(transactionId: string, gsiId: string) {
    return this.http.get(
      this.api?.getTransactionHistoryCuList + transactionId + '/' + gsiId
    );
  }

  getCuTransactionHistory(transactionId: string, cuContextualId: string) {
    return this.http.get(
      this.api?.getCuTransactionHistory + transactionId + '/' + cuContextualId
    );
  }

  getTransactionHistoryEmbeddedCuList(
    transactionId: string,
    gsiId: string,
    triggerCuId: any
  ) {
    return this.http.get(
      this.api?.getTransactionHistoryCuList +
        transactionId +
        '/' +
        gsiId +
        '/' +
        triggerCuId
    );
  }

  getTransactionHistoryNestedCuList(transactionId: string, gsiId: string) {
    return this.http.get(
      this.api?.getTransactionHistoryCuList + transactionId + '/' + gsiId
    );
  }

  getTransactionFullHistoryEmbeddedCuList(
    transactionId: string,
    gsiId: string
  ) {
    return this.http.get(
      this.api?.getTransactionFullHistoryEmbeddedCuList +
        transactionId +
        '/' +
        gsiId
    );
  }

  ///get reserved actions ///
  getReservedActionsList(body: any) {
    return this.http.get(
      this.api?.getReservedActionsList +
        'gsiId=' +
        body.gsiId +
        '&transactionId=' +
        body.transID +
        '&triggerCUId=' +
        body.triggerCuId
    );
  }
  ///advanced search ///
  advancedSearchTxn(postData: any) {
    return this.http.post(this.api?.advancedSearchTxn, postData);
  }
  getFilteredTransactions(body: any) {
    return this.http.post(this.api?.filteredTransactions, body);
  }
  // getAllTransactions(pagenum: any, pageSize: any, searchText: any) {
  //   return this.http.post(
  //     this.api?.getAllTransactions + 'limit=' + pageSize + '&pageNumber=' + pagenum + '&searchText=' + searchText,
  //     ''
  //   );
  // }
  txnStatusChange(status: any, txnId: any, payload?: any) {
    if (
      status == 'SUSPENDED' ||
      status == 'ABORTED' ||
      status == 'RESUMED' ||
      status == 'WITHDRAWN'
    ) {
      return this.http.put(
        this.api?.txnStatusChange + 'triggerStateType=' + status,
        payload
      );
    } else {
      return this.http.post(
        this.api?.txnStatusall + '/' + status + '/' + txnId,
        payload
      );
    }
  }

  /**
   * to get ads based on gsi id and user information
   * @param gsiData
   */
  getTransactionAds(gsiData: any) {
    return this.http.post(`${this.api?.getAds}`, gsiData);
  }

  /**
   * impression call when ad displayed on UI
   * @param impressionData
   */
  makeImpression(impressionData: any) {
    return this.http
      .post(`${this.api?.adImpression}`, impressionData)
      .pipe(pluck('response'));
  }

  /**
   * Click this.api call when user clicks on specific ad
   * @param clickedData
   */
  adClick(clickedData: any) {
    return this.http
      .post(`${this.api?.adClick}`, clickedData)
      .pipe(pluck('response'));
  }
  //this.api call to get reserved actions hostory
  getReservedActionsHistory(transactionId: string, cuContextualId: string) {
    return this.http.get(
      this.api?.getReservedActionsHistory +
        '/' +
        transactionId +
        '/' +
        cuContextualId
    );
  }
  /**
   * to get location details based on IP
   */
  getLocation() {
    return this.http.get(`${this.api?.geolocationurl}`);
  }

  //Sub-transactional entity
  getSubtransactionalEntity(postData: Object) {
    const headers = { Accept: 'application/json' };
    return this.http
      .post(`${this.api?.getSubtransactionaEntity}`, postData, { headers })
      .pipe(pluck('result'));
  }
  getPreviousCUData(queryParamsData: any, isDebug: boolean) {
    const {
      txnId,
      triggerCUId,
      rootGSIId,
      previousCUContextualId,
    } = queryParamsData;
    let body = new URLSearchParams();
    body.set('rootGSIId', rootGSIId);
    body.set('previousCUContextualId', previousCUContextualId);
    body.set('bookId', '1');

    // ${triggerCUId}?`+ body.toString()
    const url =
      this.api?.previousCuData + txnId + `/${triggerCUId}?` + body.toString();
    if (isDebug) {
      const headerDict = {
        'Debug-Mode': 'true',
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.get(url, requestOptions);
    }

    return this.http.get(url);
  }

  getGmapLocation(latitude: any, longitude: any) {
    return this.http.get(
      `${this.api?.googleGeoLocation}${latitude},${longitude}`
    );
  }

  // Pushback transaction API - FE757
  postTransPushbackData(data: any) {
    return this.http.post(this.api?.pushbackChangeUnit, data);
  }

  debugData(postData: any) {
    const headerDict = {
      'Debug-Mode': 'true',
    };
    const payLoad = postData;
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.api?.debug, payLoad, requestOptions);
  }

  debugValue(postData: any) {
    return this.http.get(
      this.api?.debugValue +
        postData.transactionId +
        '?cuId=' +
        postData.cuId +
        '&cuContextualId=' +
        postData.cuContextualId
    );
  }
  /**
   * Dashboard for scheduled jobs
   */
  /*gets all scheduled jobs list */
  getJobdetails(pagenum: any, pageSize: any, searchText: any) {
    return this.http.get(
      this.api?.getScheduledJobs +
        'pageNumber=' +
        pagenum +
        '&pageSize=' +
        pageSize +
        '&searchText=' +
        searchText
    );
  }

  /*gets list of all triggers based on gsiname and gsiId */
  getJobtriggers(gsiId: number, gsiName: any, pagenum: any, pageSize: any) {
    return this.http.get(
      this.api?.getJobTriggers +
        'gsiId=' +
        gsiId +
        '&gsiName=' +
        gsiName +
        '&pageNumber=' +
        pagenum +
        '&pageSize=' +
        pageSize
    );
  }

  /*gets list of all paginated triggers */
  getAlltriggers(pagenum: any, pageSize: any) {
    return this.http.get(
      this.api?.getAllTriggers +
        'pageNumber=' +
        pagenum +
        '&pageSize=' +
        pageSize
    );
  }
  /*Scheduled GSI executions */
  gsiExecutions(pagenum: any, pageSize: any, data: any) {
    return this.http.post(
      this.api?.gsiExecutions +
        'pageNumber=' +
        pagenum +
        '&pageSize=' +
        pageSize,
      data
    );
  }

  /*unschedules a jobs */
  getUnscheduleJobs(data: Object) {
    return this.http.post(this.api?.getUnscheduleJobs, data);
  }

  /*unschedules a trigger */
  getUnscheduleTriggers(data: Object) {
    return this.http.post(this.api?.getUnscheduleTriggers, data);
  }

  /*pauses trigger */
  pauseTrigger(data: Object) {
    return this.http.post(this.api?.pauseTrigger, data);
  }
  /*resume trigger */
  resumeTrigger(data: Object) {
    return this.http.post(this.api?.resumeTrigger, data);
  }

  /*pauses job */
  pauseJob(data: Object) {
    return this.http.post(this.api?.pauseJob, data);
  }
  /* resumes job */
  resumeJob(data: Object) {
    return this.http.post(this.api?.resumeJob, data);
  }

  // Save pushback comment data
  postTransPushbackCommentData(transId: any, data: any) {
    return this.http.post(
      this.api?.transPushbackComment + `?txnId=${transId}`,
      data
    );
  }
  // publish to blockchain service
  publishtoBlockChain(payload: any) {
    return this.http.post(this.api.publishtoBlockChain, payload);
  }
  getTransactionOwner(id: any) {
    return this.http.get(this.api?.transactionOwner + `${id}`);
  }
  sendLocationdata(data: any) {
    return this.http.post(this.api?.sendLocationData, data);
  }
  getLocationData(transId: any, contextualId: any) {
    return this.http.get(
      this.api?.getLocationData + `${transId}/${contextualId}`
    );
  }
  facetSearch(data: any, paginationDetails: any) {
    if (paginationDetails && paginationDetails.pageSize) {
      return this.http.post(
        this.api?.facetSearch +
          `?pageNumber=${paginationDetails.pageNumber}&pageSize=${paginationDetails.pageSize}`,
        data
      );
    } else {
      return this.http.post(this.api?.facetSearch, data);
    }
  }
  notificationApproved(data: any, btn: any) {
    return this.http.post(this.api?.notificationbtn + `${btn}/${data}`, null);
  }
  campaignLogout(): any {
    let envClientId = localStorage.getItem('clientId');
    let tenantName = getTenantName();
    /* istanbul ignore next */
    if (getLocalStorageValue('credentials')) {
      const token = JSON.parse(getLocalStorageValue('credentials'));
      const refresh_token: any = token.refresh_token;
      const logoutPayload = {
        tenantName: tenantName,
        clientId: envClientId,
        refreshToken: refresh_token,
      };
      this.loader.show();
      return this.http.post(this.api?.logOut, logoutPayload);
    }
  }
  updateSignUpGSIExecutedSuccessfully(assign: boolean) {
    let userId = JSON.parse(localStorage.getItem('ActiveUserInfo')).id;
    return this.http.put(
      this.api?.updateSignUpGSIExecutedSuccessfully +
        `${assign}&userId=${userId}`,
      {}
    );
  }
  // dynamicFormUploadFiles(files: any) {
  //   return this.http.post(this.api?.singleFileUpload, files);
  // }
  dicomFiletoJpg(payload: any) {
    // return this.http.post(`http://10.16.4.128:5302/v2_dpe/dcm_image`, payload);
    return this.http.post(this.api?.dicomFiletoJpg, payload);
  }
  assignedData(
    status: any,
    containercuname: any,
    pageNumber: any,
    pagesize: any
  ) {
    return this.http.get(
      this.api?.assignedData +
        `${status}&page=${pageNumber}&size=${pagesize}&containerCuName=${containercuname}&originatingBookId=`
    );
  }
  getcustomizedDialogWarning(payload: any) {
    return this.http.post(this.api?.customizedWarningDialog, payload);
  }
  cancelTransaction(payload: any) {
    return this.http.post(this.api?.cancelTransaction, payload);
  }

  updateTableData(payload: any) {
    return this.http.post(this.api?.updateTableDataURL, payload);
  }
  /* istanbul ignore next */
  //for Calender slot execution
  executeCalnderSlot(payload: any) {
    return this.http.post(this.api?.executeSlot, payload);
  }

  getTopBannerAds(req:any){
    return this.http.post(this.api?.getTopBannerAds,req);
  }

  async connectToMetamask() {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        /* istanbul ignore next */
        const web3 = new Web3(window.ethereum);
        /* istanbul ignore next */
        const accounts = await web3.eth.getAccounts();
        /* istanbul ignore next */
        const chainId = await web3.eth.getChainId();
        /* istanbul ignore next */
        const address = accounts[0];
        /* istanbul ignore next */
        return { address, chainId };
      } catch (error) {
        throw new Error('User rejected connection request');
      }
    } else {
      throw new Error('metamask_not_detected');
    }
  }

  async sendTransaction(sender, receiver, amount) {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const web3 = new Web3(window.ethereum);
        const transactionHash = await this.sendEthTransaction(
          web3,
          sender,
          receiver,
          amount
        );
        return { transactionHash };
      } catch (error) {
        throw new Error('User rejected connection request');
      }
    } else {
      throw new Error('metamask_not_detected');
    }
  }

  async sendEthTransaction(web3, sender, receiver, amount) {
    this.loader.show();
    try {
      let transactionHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: sender,
            to: web3.utils.toChecksumAddress(receiver),
            value: parseFloat(web3.utils.toWei(amount,"ether")).toString(16),
            gasLimit: '0x5028',
            maxPriorityFeePerGas: '0x3b9aca00',
            maxFeePerGas: '0x2540be400',
          },
        ],
      });
      return transactionHash;
    } catch (error) {
      throw new Error('Transaction failed');
    }
  }

  getEntityCardByConfigurationName(configName: string){
    return this.http.get(this.api.getEntityCardByConfigurationName + configName )
  }
}
