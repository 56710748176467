<section class="tab-header">
  <div class="row no-gutters align-items-center">
    <div class="col node-search-wrap">
      <div class="node-select" id="selectObjectVersion">
        <mat-form-field appearance="standard">
          <select matNativeControl [(ngModel)]="filterType" (change)="loadData($event)">
            <option value="all" id="getAllObjects">{{ labels?.ALL }}</option>
            <option value="published" id="getPublishedObjects">{{ labels?.PUBLISHED }}</option>
            <option value="drafts" id="getDraftsObjects">{{ labels?.DRAFTS }}</option>
          </select>
        </mat-form-field>
      </div>
      <div class="node-search-right">
        <div class="node-create right-item">
          <button class="create-new-btn" (click)="addNewEntity()" id="createNew_Object"></button>
        </div>
        <!-- <div class="slide-toggle right-item">
          <mat-slide-toggle id="slide_toggleBtn"></mat-slide-toggle>
        </div> -->
        <!-- <div class="node-compare right-item">
          <button class="compare-btn-icon">
            <span>Compare</span>
          </button>
        </div> -->
        <div class="node-search-block right-item">
          <button
            (click)="displayNodesearch = !displayNodesearch; searchText = ''"
            class="search-btn-icon search-btn-large"
            id="search_Object"
          ></button>
        </div>
      </div>
      <div
        class="node-search-field"
        [ngClass]="{ showNodesearch: displayNodesearch, 'node-search-field-for-node': !isHybrid }"
      >
        <mat-form-field appearance="legacy">
          <button
            mat-icon-button
            matSuffix
            (click)="displayNodesearch = !displayNodesearch; searchText = ''; getAllEntities()"
          >
            <mat-icon matSuffix>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchText" placeholder="Search" (keyup)="getAllEntities()" />
        </mat-form-field>
      </div>
    </div>
  </div>
</section>
<section class="tab-body">
  <ul class="common-list">
    <li *ngFor="let entity of entityList?.data">
      <mat-accordion>
        <mat-expansion-panel hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header (click)="expandEntity(entity)">
            <div class="d-flex item align-items-center">
              <div class="details">
                <p class="sub">{{ entity.status }} [{{ entity.version }}]</p>
                <p class="title"
                  matTooltip="{{ entity.name }}"
                  aria-label="Button that displays a tooltip when focused or hovered over">
                  {{ entity.name }}
                </p>
              </div>
              <a
                *ngIf="this.checkedAttributeList?.length > 0"
                class="add-icon"
                (click)="addAttrToEntity(entity)"
                id="IfSelectedCheckbox"
                ><span></span
              ></a>

              <a
                *ngIf="this.checkedAttributeList.length === 0"
                class="add-icon"
                (click)="addEntity(entity); $event.stopPropagation()"
                id="addObject_{{ entity?.name?.replaceAll(' ', '_') }}"
                ><span></span
              ></a>
              <a
                class="copy-icon"
                (click)="cloneEntity(entity); $event.stopPropagation()"
                id="copyObject_{{ entity?.name?.replaceAll(' ', '_') }}"
                ><span></span
              ></a>
              <a
                class="more-icon"
                (click)="expandEntity(entity)"
                id="moreObjectDetails_{{ entity?.name?.replaceAll(' ', '_') }}"
                ><span></span
              ></a>
            </div>
          </mat-expansion-panel-header>
          <div *ngIf="entity?.displayList">
            <div class="mat-elevation-z0" *ngFor="let attr of entity.displayList">
              <div class="d-flex" *ngIf="!attr.generalEntity">
                {{ attr.name }}
                <mat-checkbox
                  class="example-margin"
                  [color]="'primary'"
                  (change)="Checkboxchanged($event.checked, attr)"
                >
                </mat-checkbox>
                <!--- <a
                  class="create-new-btn"
                  (click)="addAttrToEntity(entity, attr)"
                  id="addAttrToEntity_{{ attr?.name?.replaceAll(' ', '_') }}"
                ></a> --->
              </div>
              <div class="d-flex" *ngIf="attr.generalEntity">
                <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</section>
<app-clone-modal *ngIf="openModal" [data]="selectedEntity" [title]="title" [type]="type" [isPublished]="isPublished">
</app-clone-modal>
<div class="tab-footer">
  <app-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [totalRecords]="entityList.totalResults"
    (onPageChange)="onPageChange($event)"
  >
  </app-paginator>
</div>
