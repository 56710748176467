import { Component, Input, OnInit } from '@angular/core';
import { EntityWidget } from '@common-services';
@Component({
  selector: 'app-canvasjs-display',
  templateUrl: './canvasjs-display.component.html',
  styleUrls: ['./canvasjs-display.component.scss'],
})
export class CanvasjsDisplayComponent implements OnInit {
  label: any;
  constructor() {}
  @Input() data: any;
  @Input() entityWidget: EntityWidget;
  @Input() entityName: string;
  @Input() chartType: string;
  @Input() control: any;
  @Input() transaction: boolean = false;
  @Input() displayName: string;
  slot: any;
  controlSlot: any;
  @Input() set _canavasChanged(value: number) {
    this.renderChart();
  }
  @Input() categories: any = [];
  height: any;
  width: any;
  fusionChartsDataSource: any;
  ngOnInit(): void {
    /*istanbul ignore next*/
    if (this.transaction) {
      this.controlSlot = this.control?.value?.value?.slot;
      this.slot = parseInt(this.entityName.split('$')[1]);
      this.entityName = this.entityName.split('$')[0];
      if (this.displayName != '') {
        this.entityName = this.displayName;
      }
      if (this.control?.attrClass) {
        this.setChartSize(); 
      }
    }
    this.renderChart();
  }

  setChartSize() {
    const sizeClass = this.control?.attrClass;
    if (sizeClass === 'small') {
      this.height = 200;
      this.width = 200;
    } else if (sizeClass === 'medium') {
      this.height = 300;
      this.width = 300;
    } else if (sizeClass === 'large') { 
      this.height = 450;
      this.width = 450;
    } else {
      this.height = 300; 
      this.width = 300;  
    }
  }

  renderChart(): void {
    // var chart = new CanvasJS.Chart('canvasjsrender', {
    //   theme: 'light2', //light1
    //   title: {
    //     text: this.entityName,
    //   },
    //   data: [
    //     {
    //       type: this.chartType,
    //       dataPoints: [...this.data],
    //     },
    //   ],
    // });
    // chart.render();
    /* istanbul ignore next */
    if (this.chartType === 'stackedbar2d' || this.chartType === 'stackedcolumn2d' || this.chartType === 'msline') {
      this.stackedCharts();
    } else if (this.chartType === 'treemap') {
      let duplicateData: any = JSON.parse(JSON.stringify(this.data));
      let svalue: number[] = [];
      let sum = 0;
      let min: number;
      let max: number;
      duplicateData.forEach((data: any, index: any) => {
        if (data.svalue || data.svalue == 0) {
          svalue.push(data.svalue);
        }
        sum = sum + data.value;
      });
      if (svalue.length > 0) {
        min = Math.min(...svalue);
        max = Math.max(...svalue);
      }
      duplicateData.forEach((data: any, index: any) => {
        if (data.svalue === undefined) {
          data.svalue = Math.floor((data.value / sum) * 100);
          if (index % 2 == 0) {
            data.svalue = 0 - data.svalue;
          }
        } else if (data.value && data.svalue === null) {
          data.svalue = min || min == 0 ? min : '-50';
        }
      });
      this.fusionChartsDataSource = {
        chart: {
          theme: 'fusion',
          caption: '',
          showvalues: '1',
        },
        data: [{ label: this.entityName, data: duplicateData }],
        colorrange: {
          mapbypercent: 2,
          gradient: 2,
          minvalue: min ? min : '-50',
          code: '472731',
          color: [
            {
              code: '0e757a',
              maxvalue: max && min ? (max + min) / 2 : '0',
            },
            {
              code: 'f18a85',
              maxvalue: max ? max : '50',
            },
          ],
        },
      };
    } else if (this.chartType === 'scatter') {
      let duplicateData: any = JSON.parse(JSON.stringify(this.data));
      this.categories = [{ category: JSON.parse(JSON.stringify(duplicateData)) }];
      this.categories[0].category.forEach((category: any) => {
        category['label'] = category.x.toString();
        delete category.y;
      });
      duplicateData = { seriesname: '', data: JSON.parse(JSON.stringify(duplicateData)) };
      this.fusionChartsDataSource = {
        chart: {
          theme: 'fusion',
          caption: this.entityName,
          showvalues: '1',
        },
        dataset: [duplicateData],
        categories: this.categories,
      };
    } else if (this.chartType === 'heatmap') {
      const colorArray = {
        gradient: '1',
        minvalue: '0',
        startlabel: 'min',
        endlabel: 'max',
      };
      const dataset = [{ data: this.data }];
      this.fusionChartsDataSource = {
        chart: {
          valueFontColor: '#ffffff',
          theme: 'fusion',
          caption: this.entityName,
          showvalues: '1',
          plottooltext: '$label: <b>$dataValue</b>',
        },
        dataset: dataset,
        colorrange: colorArray,
      };
    } else {
      this.fusionChartsDataSource = {
        chart: {
          theme: 'fusion',
          caption: this.entityName,
          showvalues: '1',
        },
        data: this.data,
      };
    }
  }

  stackedCharts() {
    let label: any = [];
    let dummyData: any = [];
    this.data.forEach((key: any) => {
      label.push({ label: key.label });
      dummyData.push({ value: key.value });
    });
    const data = [
      {
        seriesname: '',
        data: dummyData,
      },
    ];
    const categories = [
      {
        category: label,
      },
    ];
    this.fusionChartsDataSource = {
      chart: {
        theme: 'fusion',
        caption: this.entityName,
        showvalues: '1',
      },
      categories: categories,
      dataset: data,
    };
  }
}
