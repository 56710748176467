import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class DahsboardEndpointService extends CduiBaseService {
  // url = 'http://10.15.1.12:6036/special-features/analytics';
  url = this.api?.specialFeatureAnalytics;
  constructor(private http: HttpClient, private injector: Injector) {
    super(injector);
  }

  getTopTransactedSolutions(tenantId: any, topNnum: number, selectedEnv: any) {
    return this.http.get(
      this.api?.getTopTransactedSolutions + `tenantId=${tenantId}&topN=${topNnum}&env=${selectedEnv}`
    );
  }

  getTopEditedSolutions(tenantId: any, topNnum: number, selectedEnv: any) {
    return this.http.get(this.api?.getTopEditedSolutions + `tenantId=${tenantId}&topN=${topNnum}&env=${selectedEnv}`);
  }

  getTransactionsCount(lastNdays: number, tenantId: any, selectedEnv: any, startDate?: any, endDate?: any) {
    if (startDate && endDate) {
      return this.http.get(
        this.api?.getTransactionsCount +
          `lastNdays=${lastNdays}&tenantId=${tenantId}&env=${selectedEnv}&startDate=${startDate}&endDate=${endDate}`
      );
    } else {
      return this.http.get(
        this.api?.getTransactionsCount + `lastNdays=${lastNdays}&tenantId=${tenantId}&env=${selectedEnv}`
      );
    }
  }

  getSolutionsCount(lastNdays: number, tenantId: any, selectedEnv: any, startDate?: any, endDate?: any) {
    if (startDate && endDate) {
      return this.http.get(
        this.api?.getSolutionsCount +
          `lastNdays=${lastNdays}&tenantId=${tenantId}&env=${selectedEnv}&startDate=${startDate}&endDate=${endDate}`
      );
    } else {
      return this.http.get(
        this.api?.getSolutionsCount + `lastNdays=${lastNdays}&tenantId=${tenantId}&env=${selectedEnv}`
      );
    }
  }

  loadUsersCount(selectedEnv: any) {
    if (selectedEnv) {
      return this.http.get(this.api?.loadUsersCount + `?env=${selectedEnv}`);
    } else {
      return this.http.get(this.api?.loadUsersCount);
    }
  }

  //dashboard services
  saveData(data: any) {
    return this.http.post(this.api?.saveDataset, data);
  }

  getGroupList(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    return this.http.get(
      this.api?.groupList + `pageNumber=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchCriteria}`
    );
  }

  getwidgets(groupName: any) {
    return this.http.get(this.api?.getWidgets + groupName);
  }

  getAttributes(data: any, isTxnType: any) {
    if (isTxnType) {
      return this.http.post(this.api?.getAttributes, data);
    } else {
      return this.http.post(this.api?.getGEAttributes, data);
    }
  }

  getxandyValues(data: any, isTxnType: any) {
    if (isTxnType) {
      return this.http.post(this.api?.getxandyValues, data);
    } else {
      return this.http.post(this.api?.getGExandyValues, data);
    }
  }

  saveWidget(data: any, specialFeature?: any) {
    if (specialFeature) {
      return this.http.post(this.api?.specialFeatureAnalytics, data);
    }
    return this.http.post(this.api?.saveWidget, data);
  }

  deleteWidget(id: any) {
    return this.http.delete(this.api?.deleteWidget + id);
  }

  saveCollection(data: any) {
    return this.http.post(this.api?.saveCollection, data);
  }

  getCollectionDetails(collectionName: any) {
    return this.http.get(this.api?.getCollectionDetails + collectionName);
  }

  getdashboardDetailsOnCollection(collectionName: any) {
    return this.http.get(this.api?.getdashboardDetailsOnCollection + collectionName);
  }

  getDashboardsByName(dashboardName: any) {
    return this.http.get(this.api?.getDashboardsByName + dashboardName);
  }

  getAttributeValues(data: any, name: any, isTxnType: any) {
    if (isTxnType) {
      return this.http.post(this.api?.getAttributeValues, data);
    } else {
      return this.http.post(this.api?.getGEAttributeValues + name, data);
    }
  }

  getAggrigateValues(data: any, isTxnType: any) {
    if (isTxnType) {
      return this.http.post(this.api?.getAggrigateValues, data);
    } else {
      return this.http.post(this.api?.getGEAggrigateValues, data);
    }
  }

  getcuExecutionData(data: any) {
    return this.http.post(this.api?.getcuExecutionData, data);
  }

  getCollections(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    return this.http.get(
      this.api?.getCollections +
        'pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize +
        '&searchCriteria=' +
        searchCriteria
    );
  }

  getDatasetDetails(datasetName: any) {
    return this.http.get(this.api?.getDatasetDetails + datasetName);
  }

  getDashboardDetails(dashboradId: any) {
    return this.http.get(this.api?.getDashboardDetails + dashboradId);
  }

  publishDashboard(data: any, name: any) {
    return this.http.post(this.api?.publishDashboard + name, data);
  }

  getWidgetDetails(widgetId: any) {
    return this.http.get(this.api?.getWidgetDetails + widgetId);
  }

  getAllDashboardWidgets(dashbordId: any) {
    return this.http.get(this.api?.getAllDashboardWidgets + dashbordId);
  }

  getAllDashboards(pageNumber: any, pageSize: any, searchCriteria?: any) {
    if (searchCriteria) {
      return this.http.get(
        this.api?.getAllDashboards +
          'pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize +
          '&searchCriteria=' +
          searchCriteria
      );
    } else {
      return this.http.get(this.api?.getAllDashboards + 'pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    }
  }
  getAllAdDashboards(endDate: any, granularity: string, startDate: any) {
    return this.http.get(
      this.api?.getAdDashboard + '?endDate=' + endDate + '&granularity=' + granularity + '&startDate=' + startDate
    );
  }

  getAllDatasets(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    return this.http.get(
      this.api?.getAllDatasets +
        '?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize +
        '&searchCriteria=' +
        searchCriteria
    );
  }

  deleteDatasets(id: any) {
    return this.http.delete(this.api?.deleteDatasets + id);
  }

  deleteDashboard(id: any) {
    return this.http.delete(this.api?.deleteDashboard + id);
  }

  getMCCDashboardByTenantId() {
    return this.http.get(`${this.api?.getDashboardsByTenantID}`);
  }

  getSupersetDashboardByUserId() {
    return this.http.get(`${this.api?.getDashboardsByUserID}`);
  }

  getTenentByName(tenentName: string) {
    return this.http.get(this.api?.getTenentByName + `${tenentName}`);
  }

  getMeteringData(clientId: any) {
    return this.http.get(`${this.api?.meteringUrl}/${clientId}`);
  }

  getBillingData(clientId: string) {
    return this.http.get(this.api?.getBillingData + `${clientId}`);
  }

  featureUsageBill(payload: any) {
    return this.http.post(this.api?.featureUsageBill + '', payload);
  }
  getBillingByYear(tenantName: string, year: number, clientId: string, subscriptionid?: any) {
    if (subscriptionid) {
      return this.http.get(
        this.api?.getBillingData +
          `billBasicInfoInYear?tenantName=${tenantName}&tenantSubscriptionId=${subscriptionid}&year=${year}`
      );
    }
    return this.http.get(this.api?.getBillingData + `billBasicInfoInYear?tenantName=${clientId}&year=${year}`);
  }

  /**
   * Exports billing pdf
   * @param tenantName
   * @param isDayWise
   * @returns
   */
  exportBillingPdf(clientId: string, isDayWise: boolean) {
    const options: {
      headers?: HttpHeaders;
      responseType: 'blob';
    } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf',
      }),
      responseType: 'blob',
    };
    return this.http.get(this.api?.exportBillingPdf + `${clientId}/exportPdf?dayWiseBill=${isDayWise}`, options);
  }
  downloadBillHistoryPdf(clientId: string, billId: any) {
    const options: {
      headers?: HttpHeaders;
      responseType: 'blob';
    } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        Accept: 'application/pdf',
      }),
      responseType: 'blob',
    };
    return this.http.get(this.api?.exportBillingPdf + `${clientId}/exportPdf?billId=${billId}`, options);
  }

  /**
   * Gets users count by tenant name
   * @param tenantName
   * @returns
   */
  getUsersCountByTenantName(tenantName: string) {
    return this.http.get(this.api?.getUsersCountByTenantName + `tenantName=${tenantName}`);
  }

  /**
   * Gets entity count
   * @param lastNdays
   * @param tenantId
   * @returns
   */
  getEntityCount(lastNdays: number, tenantId: any, startDate?: any, endDate?: any, environment?: any) {
    if (startDate && endDate) {
      return this.http.get(
        this.api?.getEntityCount +
          `lastNdays=${lastNdays}&tenantId=${tenantId}&env=${environment}&startDate=${startDate}&endDate=${endDate}`
      );
    } else {
      return this.http.get(this.api?.getEntityCount + `lastNdays=${lastNdays}&tenantId=${tenantId}&env=${environment}`);
    }
  }
  getAllAdvertiserDashboards(advertiserId: string, campaignId: any, endDate: any, granularity: string, startDate: any) {
    if (campaignId !== '') {
      return this.http.get(
        this.api?.getAdvertiserDashboards +
          '?advertiserId=' +
          advertiserId +
          '&campaignId=' +
          campaignId +
          '&endDate=' +
          endDate +
          '&granularity=' +
          granularity +
          '&startDate=' +
          startDate
      );
    } else {
      return this.http.get(
        this.api?.getAdvertiserDashboards +
          '?advertiserId=' +
          advertiserId +
          '&endDate=' +
          endDate +
          '&granularity=' +
          granularity +
          '&startDate=' +
          startDate
      );
    }
  }

  getSolutions(pagenum: number, searchCriteria: string, isPublished: boolean, isTXNRights: boolean) {
    const pageSize = 5;
    const ontology = '';
    const publisherId = '';
    const type = isTXNRights ? 'TXN_INFO_RIGHTS' : 'SOLUTIONS_INFO_RIGHTS';
    return this.http.get(
      `${this.api?.getAllGsis}?isPublished=${isPublished}&limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrName=${publisherId}&query=${searchCriteria}&userRights=${type}`
    );
  }
  getSpecialFeatures() {
    return this.http.get(`${this.api?.tfCoreUrl}/specialFeatureTypes`);
  }
  fetchCU(gsiId: string) {
    return this.http.get(`${this.api?.saveGsi}/${gsiId}`);
  }
  submitSpecialFeature(body: any) {
    return this.http.post(`${this.url}/metrics`, body);
  }
  saveSpecialFeature(body: any) {
    return this.http.post(this.url, body);
  }
  fetchSaveFeatures(dashboardId: string) {
    return this.http.get(`${this.url}/view/data/${dashboardId}`);
  }
  deleteSpecialFeature(lookupId: any) {
    return this.http.delete(`${this.url}/${lookupId}`);
  }
  updateSpecialFeature(body?: any, lookupId?: any) {
    return this.http.put(`${this.url}`, body);
  }
  getAllPublisherDashboards(publisherId: string, endDate: any, granularity: string, solutionName: any, startDate: any) {
    if (solutionName === '') {
      return this.http.get(
        this.api?.getPublisherDashboards +
          '?endDate=' +
          endDate +
          '&granularity=' +
          granularity +
          '&publisherId=' +
          publisherId +
          '&startDate=' +
          startDate
      );
    } else {
      return this.http.get(
        this.api?.getPublisherDashboards +
          '?endDate=' +
          endDate +
          '&granularity=' +
          granularity +
          '&publisherId=' +
          publisherId +
          '&solutionName=' +
          solutionName +
          '&startDate=' +
          startDate
      );
    }
  }

  getallblocklistedRoles() {
    return this.http.get(this.api?.getAllRoles);
  }

  getGsiName() {
    return this.http.get(this.api?.getGsiConfigurationURL);
  }

  addConfiguration(postData: any) {
    return this.http.post(this.api?.saveGsiConfigurationURL, postData);
  }

  getAllGsiInTenant(pageNo: any, pageSize: any, searchString: any) {
    return this.http.get(
      this.api?.getAllGsiInTenant + '?searchCriteria=' + searchString + '&pageNo=' + pageNo + '&pageSize=' + pageSize
    );
  }

  updateConfiguration(postData: any) {
    return this.http.put(this.api?.updategsiConfigurationURL, postData);
  }
  subscribedPlans(tenantName: any) {
    return this.http.get(this.api?.getSubscriptionList + `${tenantName}`);
  }
  getAllPlan(tenantName: any) {
    return this.http.get(this.api?.getSubscriptionList + `${tenantName}`);
  }
  getBills(tenantName: any, tenantSubscriptionId: any, year: any) {
    return this.http.get(
      this.api?.getBillingData +
        'billBasicInfoInYear?' +
        `tenantName=${tenantName}` +
        `&tenantSubscriptionId=${tenantSubscriptionId}` +
        `&year=${year}`
    );
  }
  ActivateNow(tenantName: any, subscriptionId: any) {
    return this.http.post(
      this.api?.activateNow + `${tenantName}/activateNow?tenantSubscriptionId=${subscriptionId}`,
      {}
    );
  }
  FetchPlans() {
    return this.http.get(this.api?.getAllPlans);
  }
  getPlans(pageSize: number, pageNo: number, query: string) {
    return this.http.get(this.api?.getPlans + `?pageSize=${pageSize}&pageNo=${pageNo}&keyword=${query}&isAsc=true`);
  }
  FetchFeatures(subscriptionPlanName: any) {
    return this.http.get(this.api?.ViewFeature + subscriptionPlanName);
  }
  StripeRedirectBuyPlan(payload: any) {
    return this.http.post(this.api?.StripeRedirectBuyPlan, payload);
  }
  StripeRedirectBuyAddOn(payload: any, tenantName: string) {
    return this.http.post(this.api?.StripeRedirectBuyAddOn + tenantName, payload);
  }

  getAllCampaignList(advertiserId: number) {
    return this.http.get(this.api?.getAllCampaignList + `?advertiserId=${advertiserId}`);
  }
  getAllSolutionList(endDate: any, startDate: any, publisherId: any) {
    return this.http.get(
      this.api?.getAllSolutionList + `?endDate=${endDate}&publisherId=${publisherId}&startDate=${startDate}`
    );
  }
  exportCSV(id: any) {
    return this.http.get(this.api?.download + 'csv/' + id);
  }
  exportExcel(id: any) {
    return this.http.get(this.api?.download + 'xlsx/' + id);
  }
  subscribeWidget(widget: any) {
    return this.http.post(this.api?.subscribedsh, widget);
  }
  getDashboardVariables(advertiserId: string, tenant: any, endDate: any, startDate: any, granularity: string) {
    return this.http.get(
      this.api?.getAdAdvertiser +
        `?tenant=${tenant}&advertiserId=${advertiserId}&endDate=${endDate}&granularity=${granularity}&startDate=${startDate}`
    );
  }
  getAgencyDashboardVariables(tenant: string, endDate: any, startDate: any, granularity: string) {
    return this.http.get(
      this.api?.getAdAdvertiser +
        `?tenant=${tenant}&endDate=${endDate}&granularity=${granularity}&startDate=${startDate}`
    );
  }

  getAdvertiserTopPerformingDetails(tenant: string, endDate: any, startDate: any, advertiserId?: any) {
    if (advertiserId) {
      return this.http.get(
        this.api?.getTopPerformingDetails +
          `?tenant=${tenant}&advertiserId=${advertiserId}&endDate=${endDate}&startDate=${startDate}`
      );
    }
    return this.http.get(
      this.api?.getTopPerformingDetails + `?tenant=${tenant}&endDate=${endDate}&startDate=${startDate}`
    );
  }

  getGsiDetails(tenant: any) {
    return this.http.get(this.api?.getGsiAdDashboard + `/${tenant}`);
  }

  getSpendDetails(tenant: any, endDate: any, startDate: any, advertiserId?: any) {
    if (advertiserId) {
      return this.http.get(this.api?.getspend + `/${tenant}/${advertiserId}?endDate=${endDate}&startDate=${startDate}`);
    }
    return this.http.get(this.api?.getspend + `/${tenant}?endDate=${endDate}&startDate=${startDate}`);
  }

  getBudgetDetails(tenant: any, endDate: any, startDate: any, advertiserId?: any) {
    if (advertiserId) {
      return this.http.get(
        this.api?.getBudget + `/${tenant}/${advertiserId}?endDate=${endDate}&startDate=${startDate}`
      );
    }
    return this.http.get(this.api?.getBudget + `/${tenant}?endDate=${endDate}&startDate=${startDate}`);
  }
  getAdsDetails(tenent: any, startDate: any, endDate: any) {
    return this.http.get(
      this.api?.getAdsAdDashboard + `?advertiserId=${tenent}&endDate=${endDate}&startDate=${startDate}`
    );
  }
  // getAllCampaigns(advertiserId: string) {
  //   return this.http.get(this.api?.getAllCampigns + `/${advertiserId}`);
  // }
  getAllCampaignsDetails(advertiserId: string, endDate: any, startDate: any, tenant?: any) {
    return this.http.get(
      this.api?.getAllCampaignsDetails + `${tenant}/${advertiserId}?endDate=${endDate}&startDate=${startDate}`
    );
  }
  getAdvertiserStreamAnalytics(
    advertiserId: string,
    tenantName: string,
    endDate: any,
    startDate: any,
    campaignId: any,
    granularity: string
  ) {
    return this.http.get(
      this.api?.getAdvertiserStreamAnalytics +
        `?tenant=${tenantName}advertiserId=${advertiserId}&campaignId=${campaignId}&endDate=${endDate}&granularity=${granularity}&startDate=${startDate}`
    );
  }
  updateStatus(payload: any) {
    return this.http.put(this.api?.updateStatusOfCampaign, payload);
  }
}
