<div
  id="physicalLayerBlock"
  [ngClass]="{
    'layout-five': layoutSetting === 't-5',
    'default-card-views': cuLayoutTemplate.template == 't-1'
  }"
>
  <!-- <h3 class="title-trigger">
    <span class="sprite physical-ico"></span>
    {{ labels?.Physical_Layer}}
    <span class="estimated-time-cls" *ngIf="estimatedTime || isNumber()"
      >Estimated : {{ estimatedTimeInMin | formatTime }} Min</span
    >
  </h3> -->
  <ul *ngIf="physicalEntities?.participatingItems?.length">
    <li
      *ngFor="let entity of physicalEntities?.participatingItems; let i = index"
      (click)="openEntity(i)"
      id="entityexpand_{{ entity?.item?.DATA?.name }}"
      class="tag-name d-flex align-items-center"
    >
      <div class="phy-ent" *ngIf="currentEntityIndex > i">
        <span class="sprite entity-ico"></span> {{ entity?.displayName }}
      </div>
    </li>
  </ul>

  <dynamic-form
    class="dynamic-form"
    [isTransactionActive]="isTransactionActive"
    [entityList]="entitiesList"
    [gsi]="gsi"
    [tempInfoEntList]="tempInfoEntList"
    [showSubmit]="true"
    [isReadOnly]="isReadOnly"
    [layoutConfigurations]="layoutConfigurations"
    (submitEmit)="submitFormData($event)"
    [layoutInfo]="layoutSetting"
    (formValueChange)="formValueChange($event)"
    [isInfo]="false"
    [currentCu]="currentCu"
    [selectedCard]="selectedCard"
    (dataEmitForTable)="entityDataForTable($event)"
    (addMultiEntities)="addMultiEntities($event)"
    [indexChange]="indexChange"
  >
  </dynamic-form>
</div>
