import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { AlertService, GsiButton } from '@common-services';
import { EntityBoardEndpointService } from '@common-services';

@Component({
  template: '',
})
export class DesignExpandedBase implements OnInit, OnDestroy, OnChanges {
  isDynamicExpandCardSelected: boolean = false;
  @Input() set _isDynamicExpandCardSelected(value: boolean) {
    this.isDynamicExpandCardSelected = value;
    if (value) {
      this.renderDynamicCard();
    }
  }
  dropActionBts: any = {};
  @Input() style: any;
  @Input() expandType: any = 0;
  @Input() set _expandType(value: any) {
    this.expandType = value;
    if (value?.template) {
      this.renderDynamicCard();
    }
  }
  @Input() previewType: 'wireFrame' | 'Preview';
  @Input() selectGsiListExpand: any;
  droppedExpandColors: any;
  @Input() selectedDirectiveId: any;
  @Input() selectedColor: any = '';
  @Input() fontSize: any;
  @Input() isItalic: any;
  @Input() alignment: any;
  @Input() selectedFont: string;
  @Input() isBold: any;
  @Input() boxShadowData: string = '';
  @Input() isDesign: boolean = false;
  cardColorExpand: any;
  btnColorsExpand: any = [];
  expandHyperlinkMap = new Map();
  expandHyperlinkArray: any = [];
  expandDropedHyperlinkattr: any = [];
  @Input() expandHyperLinkAttributes: any = [];
  expandDragedHyperlink: boolean = false;
  droppedStylesExpand: any = [];
  expandGsiMapping: { [key: number]: GsiButton } = {};
  @Input() expandGsiList: GsiButton[] = [];
  @Input() CART_BUTTON: GsiButton;
  @Output() expandGsiListemit = new EventEmitter();
  @Output() dialogEmit = new EventEmitter();
  @Output() CART_BUTTON_EMIT = new EventEmitter();
  labels: any;
  hoverColorsFinalExpand: any = [];
  expandHoverBackground: any;
  @Input() set colorConditionSetter(value: any) {
    this.conditionColors = value;
  }
  conditionColors: any;
  @Input() set changeObj(data: any) {
    this.sendExpandData();
  }

  gsiAttrMapExpand = {};
  @Input() dropExpandAttributesForDeletion: any = [];

  @Input() isGrammer: any = false;
  @Input() dragAttrType: any;
  @Input() nslAttributes: any;
  @Input() templateType: any;
  @Input() hyperlinkMap: any;
  @Output() removeDropHyperlink = new EventEmitter();
  @Output() droppedAttr = new EventEmitter();
  @Output() onDeletion = new EventEmitter();
  @Output() droppedActionButton = new EventEmitter();
  @Input() tabSelected: any;
  droppedAttrExpand: any = {};
  @Output() droppedAttrExpandEmit = new EventEmitter();
  @Output() emitConditionColor = new EventEmitter();
  @Input() hoverColorsExpand: any;
  @ViewChild('dropEntityData') dropEntityData: ElementRef;
  contentUrl: string;




  constructor(private alertService: AlertService, protected entityBoardService: EntityBoardEndpointService , public element?: ElementRef) {}


  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.sendExpandData();
  }

  renderDynamicCard() {
    let placeholder = '.dynamic-template-placeholder';

    this.element.nativeElement.querySelector(placeholder).innerHTML = '';
    /* istanbul ignore next */
    this.element.nativeElement.querySelector(placeholder).innerHTML = this.expandType.template;
    /* istanbul ignore next */
    this.element.nativeElement.querySelectorAll(placeholder + ' li').forEach((e: any,index: number) => {
      if(e.className.includes('card-logo')) {
        e.addEventListener('drop', (event: any) => this.dropAttribute(event, 'image',index));
      } else if(e.className.includes('card-video')) {
        e.addEventListener('drop', (event: any) => this.dropAttribute(event, 'video',index));
      } else {
        e.addEventListener('drop', (event: any) => this.dropAttribute(event, 'any',index));
      }
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
    /* istanbul ignore next */
    this.element.nativeElement.querySelectorAll(placeholder + ' button').forEach((e: any) => {
      e.addEventListener('drop', (event: any) => this.dropActionBtn(event));
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
  }
  /*istanbul ignore next*/
  onDeletingAttr(element: any, index?: number) {
    // this.onDeletion.emit(ev);
    let attrName: any;
    if (element?.innerHTML) {
      attrName = element.innerHTML;
      element.innerHTML = 'Attribute';
    } else attrName = element;
    this.deleteAttributeextension(attrName);
  }

  deleteAttributeextension(attrName: any) {
    /* istanbul ignore next */
    const foundIndex = this.dropExpandAttributesForDeletion.findIndex((x: any) => x.name === attrName);
    this.gsiAttrMapExpand[attrName] = '';
    /* istanbul ignore next */
    if (foundIndex != -1) {
      this.nslAttributes.push(this.dropExpandAttributesForDeletion[foundIndex]);
      const index = this.dropExpandAttributesForDeletion.findIndex((x: any) => x.name === attrName);
      if (index != -1) {
        this.dropExpandAttributesForDeletion[index] = { name: 'Attribute' };
      }
      this.dropExpandAttributesForDeletion.splice(foundIndex, 1);
      this.expandGsiList[foundIndex].isSelected = false;
      this.onDeletion.emit(this.nslAttributes);
    }
  }

  protected cardBackgroundUpdate = (): string => {
    let cardStyle: string[] = this.dropEntityData?.nativeElement
      ?.getElementsByClassName('entity-template-type')?.[0]
      ?.attributes?.style?.value.split(';');
    return cardStyle?.find((style: string) => style.includes('background'))?.split(':')[1];
  };

  sendExpandData(): void {
    if (this.dropEntityData) {
      this.droppedExpandColors = [];
      this.droppedStylesExpand = [];
      let cardBackground: string = this.cardBackgroundUpdate();
      this.cardColorExpand = cardBackground;
      let list = [...this.dropEntityData?.nativeElement?.getElementsByTagName('li')];
      list = list.filter(
        (el) =>
          el.className !== 'list-group-second' && el.className !== 'list-group-third' && el.className !== 'linebar'
      );
      list.forEach((x: any) => {
        let attributeStyle: string[] = [];
        let styleObj: any = {};
        if (x?.attributes?.style?.value) attributeStyle = x.attributes.style.value?.split(';');
        attributeStyle.forEach((style: any) => {
          let arr: string[] = style.split(':');
          styleObj[arr[0].trim()] = arr[1]?.trim();
        });
        let color: string = styleObj?.color ? styleObj.color : '';
        color = color ? color : '';
        this.droppedStylesExpand.push(styleObj);
        this.droppedExpandColors.push(color);
      });

      let bts = [...this.dropEntityData.nativeElement?.getElementsByTagName('button')];
      this.btnColorsExpand = [];
      bts.forEach((element: any) => {
        let buttonStyle: string[] = [];
        if (element?.attributes?.style?.value) buttonStyle = element.attributes.style.value?.split(';');
        let bbgColor = buttonStyle.find((str: string) => {
          return str.includes('background');
        });
        let btnColor: string = bbgColor ? bbgColor?.split(':')[1] : 'blue';

        this.btnColorsExpand.push(btnColor);
      });
      list = list.map((x: any) => {
        return x.innerText;
      });
      // list = list.filter(x=>x.trim()!== 'Attribute');
      list.forEach((element: any, index: number) => {
        /* istanbul ignore else */
        if (element.trim() !== 'Attribute') this.droppedAttrExpand[(index + 1).toString()] = element.trim();
        else if (element.trim() === 'Attribute') {
          if (this.droppedAttrExpand[index + 1]) {
            delete this.droppedAttrExpand[index + 1];
          }
        }
      });

      /* istanbul ignore else */
      if (this.isGrammer && this.droppedAttrExpand) {
        this.droppedAttrExpandEmit.emit({ droppedAttrExpand: this.droppedAttrExpand, event: false });
      }
    }
    this.expandHyperlinkMap.forEach((value: any, key: any) => {
      this.expandHyperlinkArray.push({
        attrName: key,
        link: value,
      });
    });
  }

  dropAttribute(ev: any, attrType?: any, index?: number) {
    if (
      this.dragAttrType != 'button' &&
      ((this.dragAttrType && attrType == 'image' && this.dragAttrType == 'image') ||
        (this.dragAttrType && attrType == 'any' && this.dragAttrType !== 'image'))
    ) {
      this.dropAttributeInTemplates(ev, index);
    } else if (
      this.dragAttrType != 'button' &&
      (attrType == this.dragAttrType || (attrType == 'label' && this.dragAttrType == 'metric'))
    ) {
      /* istanbul ignore next */
      this.dropAttributeInTemplates(ev);
    } else if (this.dragAttrType == 'hyperlink' || this.dragAttrType == 'button') {
      this.dropAttributeInTemplates(ev);
    } else {
      this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
    }
    ev.stopPropagation();
  }

  dropAttributeInTemplates(ev: any, index?: number) {
    this.dropAttributeInTemplatesExpand(ev, index);
    this.droppedAttr.emit(ev);
  }

  dropAttributeInTemplatesExpand(ev: any, attrIndex: number) {
    /* istanbul ignore next */
    if (this.dragAttrType == 'hyperlink') {
      this.dropExpandHyperlinkAttr(ev);
      this.dragAttrType = undefined;
    } else if (ev?.target?.innerHTML.trim() == 'Attribute' && this.dragAttrType != 'button') {
      ev.preventDefault();
      const data = ev.dataTransfer.getData('text');
      const foundIndex = this.nslAttributes.findIndex((x: any) => x.name === data);
      if (foundIndex != -1) {
        this.dropExpandAttributesForDeletion.push(this.nslAttributes[foundIndex]);
        this.nslAttributes.splice(foundIndex, 1);
      }
    }
    if (this.dragAttrType == 'button' && ev?.target?.innerHTML != 'Attribute') {
      this.dropGsiInAttributeExpand(ev);
    }
  }
  dropGsiInAttributeExpand(ev: any) {
    const gsiKeys = ev?.target?.innerText;
    const data = ev?.dataTransfer?.getData('text');
    const gsiMapIndex = parseInt(data.split('-')[1]);
    this.gsiAttrMapExpand[ev?.target?.innerText] = this.expandGsiList[gsiMapIndex];
    this.gsiAttrMapExpand[ev?.target?.innerText].mapped = true;
    this.expandGsiList[gsiMapIndex].isSelected = true;
  }
  removeGsiinAttributeExpand(ev?: any) {
    let attrName: any;
    if (ev?.innerHTML) {
      attrName = ev?.innerHTML?.trim();
      ev.innerHTML = 'Attribute';
    } else attrName = ev;
    this.gsiAttrMapExpand[attrName] = '';
    const foundIndex = this.dropExpandAttributesForDeletion?.findIndex((x: any) => x.name === attrName);
    if (foundIndex != -1) {
      this.expandGsiList[foundIndex].isSelected = false;
    }
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  dropActionBtn(event: any) {
    /* istanbul ignore next */
    if (this.dragAttrType === 'button' || this.dragAttrType === 'cart') {
      if (this.checkGSIMappingCondition()) {
        this.alertGSIMappedToCart();
        return;
      }
      let buttonId = parseInt(event?.target?.id?.split('-')[1]);
      if (!this.expandGsiMapping?.[buttonId]) {
        event.preventDefault();
        if (this.dragAttrType == 'button') {
          const data = event?.dataTransfer?.getData('text');
          const gsiIndex = parseInt(data?.split('-')[1]);
          this.makeGsiButtonFromId(buttonId, gsiIndex);
        } else {
          this.makeCartButtonFrom(buttonId);
        }
      } else {
        if (this.expandGsiMapping[buttonId].entityType == 'GSI' && this.dragAttrType == 'cart')
          this.alertGSIMappedToCart();
        this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      }
    }
  }

  /*istanbul ignore next*/
  checkGSIMappingCondition(): boolean {
    let keysLength = Object.keys(this.expandGsiMapping).length;
    if (keysLength > 0 && this.dragAttrType == 'cart') {
      return true;
    } else if (
      this.dragAttrType == 'button' &&
      (this.CART_BUTTON.isSelected['child'].isSelected || this.CART_BUTTON.isSelected['expand'].isSelected)
    ) {
      return true;
    }
    return false;
  }

  /*istanbul ignore next*/
  alertGSIMappedToCart() {
    this.dialogEmit.emit();
  }

  /*istanbul ignore next*/
  makeCartButtonFrom(buttonId: number) {
    this.CART_BUTTON.isSelected['expand'].isSelected = true;
    this.CART_BUTTON.isSelected['expand'].position = buttonId;
    this.expandGsiMapping[buttonId] = this.CART_BUTTON;
    this.dropActionBts['action-' + buttonId] = this.CART_BUTTON;
    this.CART_BUTTON_EMIT.emit(this.CART_BUTTON);
  }

  /*istanbul ignore next*/
  makeGsiButtonFromId(buttonId: number, gsiId: number) {
    this.expandGsiMapping[buttonId] = this.expandGsiList[gsiId];
    this.expandGsiList[gsiId].isSelected = true;
    this.expandGsiList[gsiId].position = buttonId;
    this.dropActionBts['action-' + buttonId] = this.expandGsiList[gsiId];
    this.expandGsiListemit.emit(this.expandGsiList);
  }

  /*istanbul ignore next*/
  removeGsiFromCard(index: number) {
    if (this.expandGsiMapping[index].entityType === 'GSI') {
      this.expandGsiMapping[index].isSelected = false;
      delete this.expandGsiMapping[index];
      delete this.dropActionBts['action-' + index];
    } else {
      this.expandGsiMapping[index].isSelected['expand'].isSelected = false;
      this.CART_BUTTON.isSelected['expand'].isSelected = false;
      delete this.expandGsiMapping[index];
      delete this.dropActionBts['action-' + index];
    }
  }

  recieveEmittedDirectiveId(event: any) {
    this.selectedDirectiveId = event;
  }
  recieveConditionColor(event: any) {
    this.emitConditionColor.emit(event);
  }
  recieveExpandHoverColor(event: any) {
    this.expandHoverBackground = event;
  }
  ngOnDestroy() {
    this.sendExpandData();
    if (Object.keys(this.droppedAttrExpand).length > 0 || Object.keys(this.dropActionBts).length > 0) {
      if (this.isGrammer && this.droppedAttrExpand) {
        this.droppedAttrExpandEmit.emit({ droppedAttrExpand: this.droppedAttrExpand, event: true });
      }
      this.entityBoardService.sendDataToEntityCard({
        attrData: this.droppedAttrExpand,
        gsiData: this.dropActionBts,
        dropExpandColors: this.droppedExpandColors,
        expandBackground: this.cardColorExpand,
        expandBtnColors: this.btnColorsExpand,
        droppedStylesExpand: this.droppedStylesExpand,
        gsiAttrMapExpand: this.gsiAttrMapExpand,
        expandHyperlinkArray: this.expandHyperlinkArray,
        expandHoverBackground: this.expandHoverBackground,
      });
    }
  }
  removeDropHyperlink1(event: any) {
    this.removeDropHyperlink.emit(event);
  }
  dropExpandHyperlinkAttr(ev: any) {
    const data = ev.dataTransfer.getData('text');
    //single attribute must drop
    if (ev?.target?.innerHTML?.trim() != this.labels?.Attribute && !this.expandHyperlinkMap.has(ev.target.innerHTML)) {
      this.expandHyperlinkMap.set(ev?.target?.innerText.trim(), data);
      this.expandDragedHyperlink = true;
      const index = this.expandHyperLinkAttributes.findIndex((attr: any) => attr.name == data);
      this.expandDropedHyperlinkattr.push(this.expandHyperLinkAttributes[index]);
      this.expandHyperLinkAttributes.splice(index, 1);
    } else {
      this.alertService.showToaster(
        'Dragged attribute type is not matched to the dropped attribute expand.....',
        '',
        'Error'
      );
    }
  }
  removeExpandDropHyperlink(element: any) {
    let attrName: any;
    if (element?.innerHTML) {
      attrName = element.innerHTML;
    } else attrName = element;
    const hyperlink_name = this.expandHyperlinkMap.get(attrName);

    if (hyperlink_name) {
      const index = this.expandDropedHyperlinkattr.findIndex((attr: any) => attr.name == hyperlink_name);
      this.expandHyperLinkAttributes.push(this.expandDropedHyperlinkattr[index]);
      this.expandDropedHyperlinkattr.splice(index, 1);
      this.expandHyperlinkMap.delete(attrName);
    }
  }
}
