import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '@common-services';
// Sass import required to style widgets
import '@webex/widget-space/src/momentum.scss';
import { DynamicScriptLoaderService } from '../services/dynamic-script-loader.service';

declare let webex: any;

@Component({
  selector: 'app-webex-widget',
  template: ` <div id="my-space-widget" style="width:100%; height:500px; overflow:auto;"></div> `,
  styles: [],
})
export class WebexWidgetComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(private dynamicScriptLoaderService: DynamicScriptLoaderService) { }
  ngOnInit() {
    this.loadScripts(() => {
      this.loadWidget();
    });
  }

  loadWidget() {
    /* istanbul ignore else */
    if (document.getElementById('my-space-widget') != null) {
      var widgetEl = document.getElementById('my-space-widget');
      var fieldValue = this.field;
      /* istanbul ignore next */
      var firstSplit = fieldValue?.value.split('&');
      // Init a new widget
      /* istanbul ignore next */
      webex?.widget(widgetEl)?.spaceWidget({
        accessToken: `Bearer ${firstSplit[1]}`,
        destinationType: 'email',
        destinationId: firstSplit[0],
      });
    }
  }

  loadScripts(cb: any) {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoaderService
      .load('widget')
      .then((data) => {
        // Script Loaded Successfully
        const webexWidgetLink = document.createElement('link');
        webexWidgetLink.rel = 'stylesheet';
        webexWidgetLink.href = './assets/widget/widget-space.main.css';
        // this.addingCDNURL(this.widGetSpaceMainCSS);
        document.head.prepend(webexWidgetLink); // injecting script
        cb(true);
      })
      .catch((error) => console.log(error));
  }
}
