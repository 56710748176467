import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '@common-services';

@Component({
  selector: 'app-auto-identifier',
  template: ``,
  styles: [],
})
export class AutoIdentifierComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
