import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { GsiList } from '../modal/gsi-list';

export interface StoreState {
  gsiList: GsiList[];
}

@Injectable({
  providedIn: 'root',
})
export class GsiStoreService extends ObservableStore<StoreState> {
  constructor() {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  getStateData() {
    return this.getState();
  }
  setStateData(data: any) {
    this.setState({ gsiList: data }, GsiStoreActions.SetGsis);
  }
}

export enum GsiStoreActions {
  SetGsis = 'SET_Entities',
}
