import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { EventsEndpointService, TransactionFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-post-exceptions-view',
  templateUrl: './post-exceptions-view.component.html',
  styleUrls: ['./post-exceptions-view.component.scss'],
})
export class PostExceptionsViewComponent implements OnInit {
  postExceptions: any;
  resumeFlag: boolean = false;
  continueFlag: boolean = false;
  abortFlag: boolean = false;
  retryFlag: boolean = false;
  targetCUId: any = null;
  targetCUContextualId: any = null;
  label: any;
  helpText: any;
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public eventsEndpoint: EventsEndpointService,
    private transactionFacade: TransactionFacadeService,
    private router: Router
  ) {
    this.postExceptions = this.data.postExceptions;
    Object.keys(this.postExceptions).forEach((key: any) => {
      if (key == 'RESUME') {
        this.resumeFlag = true;

        this.label = this.postExceptions['RESUME'].label;
        this.helpText = this.postExceptions['RESUME'].helpText;
      } else if (key == 'RETRY') {
        this.retryFlag = true;
        this.label = this.postExceptions['RETRY'].label;
        this.helpText = this.postExceptions['RETRY'].helpText;
      }
      if (key == 'CONTINUE') {
        this.continueFlag = true;
        this.label = this.postExceptions['CONTINUE'].label;
        this.helpText = this.postExceptions['CONTINUE'].helpText;
      }
      if (key == 'ABORT') {
        this.abortFlag = true;
        this.label = this.postExceptions['ABORT'].label;
        this.helpText = this.postExceptions['ABORT'].helpText;
      }
    });
  }

  sendPostExceptionsData(flag: String) {
    /*istanbul ignore else */
    if (flag == 'RESUME') {
      this.targetCUId = this.postExceptions['RESUME'].triggerCUId;
      this.targetCUContextualId = this.postExceptions['RESUME'].triggerCUContextualId;
    } else if (flag == 'CONTINUE') {
      this.targetCUId = this.postExceptions['CONTINUE'].triggerCUId;
      this.targetCUContextualId = this.postExceptions['CONTINUE'].triggerCUContextualId;
    } else if (flag == 'RETRY') {
      this.targetCUId = this.postExceptions['RETRY'].triggerCUId;
      this.targetCUContextualId = this.postExceptions['RETRY'].triggerCUContextualId;
    } else if (flag == 'ABORT') {
      this.targetCUContextualId = null;
      this.targetCUId = null;
    }
    let data = {
      transID: this.data.baseTransId,
      exceptionAction: flag,
      targetCUId: this.targetCUId,
      targetCUContextualId: this.targetCUContextualId,
      gsiId: this.data.gsiId,
    };
    this.eventsEndpoint
      .sendPostExceptionsData(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore else */
        if (res?.result?.postExceptionActions) {
          this.transactionFacade.listChangeUnitSubject.next(res);
        } else if (res.message == 'Transaction aborted') {
          this.dialog.closeAll();
          this.router.navigateByUrl('/cdui/mylibrary-dashboard');
        } else {
          this.transactionFacade.listChangeUnitSubject.next(res);
          this.dialog.closeAll();
        }
      });
  }

  close() {
    this.dialog.closeAll();
  }

  ngOnInit(): void {}
}
