<div class="solutions-wrap">
  <div class="solutions-list" *ngIf="showBooks">
    <h3>{{ labels?.Book }}</h3>

    <ul *ngFor="let book of bookConflicts; let i = index">
      <li (click)="toggleRenameButton('book', i)" *ngIf="book.conflicting === true && book.conflictAction !== 'SKIP'">
        <mat-icon (click)="toggleEdit('book', i)" [hidden]="book.conflictAction === 'MERGE'">edit</mat-icon>

        <mat-checkbox
          class="cstm-check"
          [disabled]="book.conflictAction !== 'NEW'"
          (change)="changeCheckboxSelect($event, i, 'book')"
        >
          <span *ngIf="!bookRenameFalg[i] && book.conflictAction === 'NEW'">{{ book?.sourceValue }}</span>
          <span *ngIf="!bookRenameFalg[i] && book.conflictAction !== 'NEW'">{{ book?.targetValue }}</span>
        </mat-checkbox>

        <span *ngIf="!bookRenameFalg[i] && !book?.isConflictResolved" class="conflict-icon">
          <mat-icon> priority_high </mat-icon>Please change the name of the {{ selectedType }}
        </span>

        <span *ngIf="!bookRenameFalg[i] && book?.isConflictResolved" class="conflict-icon">
          {{ book?.message }}
        </span>

        <span *ngIf="bookRenameFalg[i]">
          <input [(ngModel)]="newName" />
          <button (click)="save(i, 'book')" class="btn-rename">{{ labels?.Rename }}</button>
        </span>
      </li>
    </ul>

    <ul *ngFor="let book of bookConflicts; let i = index">
      <li *ngIf="book.conflicting === false || book.conflictAction === 'SKIP'">
        <span>{{ book?.sourceValue }}</span>
        <span *ngIf="book.conflictAction === 'SKIP'" class="previous-font"> (Previously installed)</span>
      </li>
    </ul>
  </div>

  <div class="solutions-list" *ngIf="showGsis">
    <h3>{{ labels?.GSI }}</h3>

    <ul *ngFor="let gsi of gsiConflicts; let i = index">
      <li
        (click)="toggleRenameButton('gsi', i)"
        *ngIf="gsi.conflicting === true && gsi.conflictAction !== 'SKIP'"
        class="list-design"
      >
        <mat-icon (click)="toggleEdit('gsi', i)" class="edit-icon" [hidden]="gsi?.conflictAction === 'MERGE'"
          >edit</mat-icon
        >

        <!-- <mat-checkbox
          class="cstm-check"
          [disabled]="gsi.conflictAction !== 'NEW'"
          (change)="changeCheckboxSelect($event, i, 'gsi')"
        > -->
          <span *ngIf="!gsiRenameFalg[i] && gsi.conflictAction === 'NEW'">{{ gsi?.sourceValue }}</span>
          <span *ngIf="!gsiRenameFalg[i] && gsi.conflictAction !== 'NEW'">{{ gsi?.targetValue }}</span>
        <!-- </mat-checkbox> -->

        <span *ngIf="!gsiRenameFalg[i] && !gsi?.isConflictResolved" class="conflict-icon">
          <mat-icon> priority_high </mat-icon>Please change the name of the {{ selectedType }}
        </span>

        <span *ngIf="!gsiRenameFalg[i] && gsi?.isConflictResolved" class="conflict-icon">
          {{ gsi?.message }}
        </span>

        <span *ngIf="gsiRenameFalg[i]">
          <input [(ngModel)]="newName" />
          <button (click)="save(i, 'gsi')" class="btn-rename">{{ labels?.Rename }}</button>
        </span>
      </li>
    </ul>

    <ul *ngFor="let gsi of gsiConflicts; let i = index">
      <li *ngIf="gsi.conflicting === false || gsi.conflictAction === 'SKIP'">
        <span>{{ gsi?.sourceValue }}</span>
        <span *ngIf="gsi.conflictAction === 'SKIP'" class="previous-font"> (Previously installed)</span>
      </li>
    </ul>
  </div>

  <div class="solutions-list" *ngIf="showCus">
    <h3>{{ labels?.Change_Unit }}</h3>

    <ul *ngFor="let cu of cuConflicts; let i = index">
      <li (click)="toggleRenameButton('cu', i)" *ngIf="cu.conflicting === true && cu.conflictAction !== 'SKIP'">
        <mat-icon (click)="toggleEdit('cu', i)" [hidden]="cu.conflictAction === 'MERGE'">edit</mat-icon>

        <!-- <mat-checkbox
          class="cstm-check"
          [disabled]="cu.conflictAction !== 'NEW'"
          (change)="changeCheckboxSelect($event, i, 'cu')"
        > -->
          <span *ngIf="!cuRenameFalg[i] && cu.conflictAction === 'NEW'">{{ cu?.sourceValue }}</span>
          <span *ngIf="!cuRenameFalg[i] && cu.conflictAction !== 'NEW'">{{ cu?.targetValue }}</span>
        <!-- </mat-checkbox> -->

        <span *ngIf="!cuRenameFalg[i] && !cu?.isConflictResolved" class="conflict-icon">
          <mat-icon> priority_high </mat-icon>Please change the name of the {{ selectedType }}
        </span>

        <span *ngIf="!cuRenameFalg[i] && cu?.isConflictResolved" class="conflict-icon">
          {{ cu?.message }}
        </span>

        <span *ngIf="cuRenameFalg[i]">
          <input [(ngModel)]="newName" />
          <button (click)="save(i, 'cu')" class="btn-rename">{{ labels?.Rename }}</button>
        </span>
      </li>
    </ul>

    <ul *ngFor="let cu of cuConflicts; let i = index">
      <li *ngIf="cu.conflicting === false || cu.conflictAction === 'SKIP'">
        <span>{{ cu?.sourceValue }}</span>
        <span *ngIf="cu.conflictAction === 'SKIP'" class="previous-font"> (Previously installed)</span>
      </li>
    </ul>
  </div>

  <div class="solutions-list" *ngIf="showGes">
    <h3>{{ labels?.Entity }}</h3>

    <ul *ngFor="let ge of geConflicts; let i = index">
      <li (click)="toggleRenameButton('ge', i)" *ngIf="ge.conflicting === true && ge.conflictAction !== 'SKIP'">
        <mat-icon (click)="toggleEdit('ge', i)" [hidden]="ge.conflictAction === 'MERGE'">edit</mat-icon>

        <!-- <mat-checkbox
          class="cstm-check"
          [disabled]="ge.conflictAction !== 'NEW'"
          [hidden]="geRenameFalg[i]"
          (change)="changeCheckboxSelect($event, i, 'ge')"
        > -->
          <span *ngIf="!geRenameFalg[i] && ge.conflictAction === 'NEW'">{{ ge?.sourceValue }}</span>
          <span *ngIf="!geRenameFalg[i] && ge.conflictAction !== 'NEW'">{{ ge?.targetValue }}</span>
        <!-- </mat-checkbox> -->

        <span *ngIf="!geRenameFalg[i] && !ge?.isConflictResolved" class="conflict-icon">
          <mat-icon> priority_high </mat-icon>Please change the name of the {{ selectedType }}
        </span>

        <span *ngIf="!geRenameFalg[i] && ge?.isConflictResolved" class="conflict-icon">
          {{ ge.message }}
        </span>

        <span *ngIf="geRenameFalg[i]">
          <input [(ngModel)]="newName" />
          <button (click)="save(i, 'ge')" class="btn-rename">{{ labels?.Rename }}</button>
        </span>
      </li>
    </ul>

    <ul *ngFor="let ge of geConflicts; let i = index">
      <li *ngIf="ge.conflicting === false || ge.conflictAction === 'SKIP'">
        <span>{{ ge?.sourceValue }}</span>
        <span *ngIf="ge.conflictAction === 'SKIP'" class="previous-font"> (Previously installed)</span>
      </li>
    </ul>
  </div>
</div>

<div class="configure-btns btns-custom">
  <button
    class="btn btn-next btn-active"
    [disabled]="checkConflicts()"
    (click)="selectedType !== 'ge' ? betTabchange() : moveToNextTab()"
  >
    {{ labels?.Next }}
  </button>
  <button class="btn btn-cancel" (click)="cancel()">{{ labels?.Cancel }}</button>
</div>
