
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertService, Attribute, AttributeEndpointService, Entity, Librarytypes, NodeGsiFacadeService, SuggestionFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';

@Component({
  selector: 'app-new-entity-modal',
  templateUrl: './new-entity-modal.component.html',
  styleUrls: ['./new-entity-modal.component.scss'],
})
export class NewEntityModalComponent implements OnInit {
  entity: any = { ...new Entity() };
  selectedEntity: any = {};
  labels: any;
  private ngUnsubscribe = new Subject();
  todo: any;
  searchText: string = '';
  attributeTypes: any;
  sourceVal: string;
  openconfig: any;
  cu: any;
  layerName: any;
  isRightSidebar: boolean = false;
  flagadd: boolean = false;
  flagEditPanel: boolean = true;
  openconfigure = false;
  openentityconfigure = false;
  configurationEntityData: any;
  tempGsiObj: any = {};
  dimension: any;
  entityIndex: any;

  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private entityFacadeService: NodeEntityFacadeService,
    private attributeEndpointService: AttributeEndpointService,
    private translator: TranslatorService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private suggestionFacadeService: SuggestionFacadeService
  ) {
    this.getAttributeTypes();
    this.detectSavedEntity();
    this.detectIndEntityData();
  }

  ngOnInit(): void {
    this.gsiFacade.openLibrary('entity');
  }

  addAttribute() {
    this.flagEditPanel = true;
    /* istanbul ignore next*/
    if (!this.flagadd) {
      this.entityFacadeService.saveMultipleEntities([this.entity]);
      this.flagadd = true;
    } else {
      /* istanbul ignore next*/
      this.entity.nslAttributes.push({ ...new Attribute(), openUiPanel: false });
    }
  }

  detectSavedEntity() {
    /* istanbul ignore next*/
    this.entityFacadeService.multientityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res?.result) {
        this.entity = res.result[0];
        /* istanbul ignore next*/
        if (!this.entity.nslAttributes.length)
          this.entity.nslAttributes.push({ ...new Attribute(), openUiPanel: false });
      }
    });
  }

  deleteAttribute(index: number) {
    this.entity.nslAttributes.splice(index, 1);
  }

  closePanel() {
    this.gsiFacade.changeNSLComponent(Librarytypes.NSLGsi);
  }
  selectAttrType(attrType: any, attr: any) {
    this.searchText = '';
    attr.attributeType.type = attrType.value.dataType;
    this.getAttributeTypes();
  }

  getAttributeTypes() {
    this.attributeEndpointService.getAllAttributeTypes(this.searchText, 1, 62).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res && res?.data) {
        this.attributeTypes = res.data;
      }
    });
  }

  /* For Default Values */
  addDefaultValuetoAttibute(attribute: any, value: any, isSingleDefault?: boolean) {
    if (isSingleDefault) {
      attribute['defaultValue'] = value;
    } else {
      attribute.attributeType.extendedProperties.sourceValues.push({
        TYPE: 'OptionValue',
        DATA: { actualValue: value, displayValue: value },
      });
    }
    this.sourceVal = '';
  }

  removeSourceValue(attribute: any, index: any) {
    attribute.attributeType.extendedProperties.sourceValues.splice(index, 1);
  }

  addconfig(attribute: any, attrIndex: number) {
    /* istanbul ignore next*/
    if (this.flagEditPanel == true) {
      this.openconfigure = true;
    }
    /* istanbul ignore next*/
    this.configurationEntityData = {
      masterEntityConfig: true,
      id: 1,
      label: 'AT',
      /* istanbul ignore next*/
      data: {
        dsdId: attribute?.id ? attribute.id : 0,
        color: 'red',
        name: attribute.name,
        dimension: { ...this.dimension },
        attr: attribute,
        entity: { ...this.entity },
        entityIndex: 0,
        attrIndex: attrIndex,
        currentcu: {},
      },
    };
  }

  addentconfig() {
    this.openentityconfigure = true;
    /* istanbul ignore next*/
    this.configurationEntityData = {
      id: 1,
      label: 'EN',
      /* istanbul ignore next*/
      data: {
        entityData: this.entity,
        dsdId: this.entity?.id ? this.entity.id : 0,
        color: '#ffc400',
        name: this.entity.name,
        dimension: { ...this.dimension },
        displayName: this.entity?.displayName,
        entityIndex: 0,
        attributeList: this.entity?.nslAttributes,
        currentcu: {},
      },
    };
  }

  closeAttribute(event: any) {
    this.openconfigure = false;
  }

  closeEntity(event: any) {
    this.openentityconfigure = false;
  }

  saveEntity() {
    let entityToSave: any = this.entity;
    this.entityFacadeService.saveEntityForIndEnt(entityToSave, false, this.cu);
    this.closePanel();
  }

  openEntityLibrary() {
    this.gsiFacade.openLibraryEntity(Librarytypes.Entity);
  }

  detectIndEntityData() {
    /* istanbul ignore next*/
    this.entityFacadeService.entityAttributes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.flagEditPanel = false;
      let firstEmptyAttribute = this.entity.nslAttributes.findIndex((attr: any) => attr.name == '');
      /* istanbul ignore next*/
      if (firstEmptyAttribute !== -1) {
        response['generalEntity'] = { ...response };
        response['attributeType'] = { properties: { referencingType: response.generalEntity.name }, type: 'entity' };
        // response.nslAttributes = undefined;
        this.entity.nslAttributes[firstEmptyAttribute] = response;
        this.entityFacadeService.saveMultipleEntities([this.entity]);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
