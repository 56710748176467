import { NgModule } from '@angular/core';
import { YearComponent } from './year.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [YearComponent],
  imports: [ControlsMaterialModule],

  exports: [YearComponent],
})
export class YearModule {
  static entry = {
    year: YearComponent,
  };
}
