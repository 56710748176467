import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigModel, endPoints } from './app-config.service.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration!: AppConfigModel;
  setTenantName = new BehaviorSubject('');
  setTenantName$ = this.setTenantName.asObservable();

  constructor(private httpClient: HttpClient) {}

  setConfig(): Observable<AppConfigModel> {
    return this.httpClient.get<AppConfigModel>('../../assets/app-config.json').pipe(
      map((config: any): any => {
        this.configuration = config;
        return config;
      })
    );
  }

  readConfig(): AppConfigModel {
    if (!this.configuration) {
      this.configuration = {} as AppConfigModel;
      this.configuration.serverIp = '';
      this.configuration.dsdOrchUrl = '/dsd-orch';
      this.configuration.cdmServerAPI = '/cdm';
    }
    return this.configuration;
  }

  getTenantConfiguration(tenantName: any) {
    this.setTenantName.next(tenantName);
  }

  getUrls(environment: AppConfigModel) {
    return endPoints(environment);
  }
}
