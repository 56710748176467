import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Librarytypes,
  NodeGsiFacadeService,
  TranslatorService,
  pagination
} from '@common-services';
import { NodeChangeUnitFacadeService } from '../../../node-services/node-change-unit-facade.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-node-system-changeunits',
  templateUrl: './node-system-changeunits.component.html',
  styleUrls: ['./node-system-changeunits.component.scss'],
})
export class NodeSystemChangeunitsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Output() betCountEmitter = new EventEmitter();
  pageIndex: number = 0;
  pageSize: number = 15;
  searchText: string = '';
  isPublished: any = '';
  changeUnitList: any = {
    totalResults: [],
    data: [],
  };
  displayNodesearch: any;
  filterType: any = 'all';
  openModal: boolean = false;
  title: string;
  type: string;
  selectedCu: any;
  libraryData: any;
  labels: any;
  isSeparateCu: boolean = false;
  @Input() nestedNumber: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private changeunitFacade: NodeChangeUnitFacadeService
  ) {
    this.getChangeUnits();
    this.detectChangeUnitList();
    this.getLibraryData();
    this.detectLanguageChange();
    this.getIndividualCu();
  }

  ngOnInit(): void {}
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  getChangeUnits() {
    /* istanbul ignore next */
    this.changeunitFacade.getAllChangeUnits(this.pageIndex, this.pageSize, this.searchText, this.isPublished);
  }
  addChangeunit(cu: any) {
    if (this.isSeparateCu) {
      this.changeunitFacade.setIndividualCudata('');
      this.changeunitFacade.editPublishedBET(cu.id, 'change-unit');
    } else {
      this.changeunitFacade.getCuDetails(cu.dsdId, this.nestedNumber);
    }
  }

  loadData(event: any) {
    if (this.filterType == 'published') {
      this.isPublished = true;
    } else if (this.filterType == 'drafts') {
      this.isPublished = false;
    } else {
      this.isPublished = '';
    }
    this.getChangeUnits();
  }
  detectChangeUnitList() {
    this.changeunitFacade.changeUnitList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.changeUnitList = res.result;
      this.betCountEmitter.next(res?.result?.totalResults)
    });
  }
  addNewCU() {
    this.changeunitFacade.addNewCU(true);
  }

  cloneChangeUnit(cu: any) {
    this.openModal = true;
    this.title = 'Rename the Cloned ChangeUnit';
    this.type = 'change-unit';
    this.selectedCu = cu;
  }

  onPageChange(pageData: pagination) {
    this.pageSize = pageData.pageSize;
    this.pageIndex = pageData.pageIndex;
    this.getChangeUnits();
  }
  expandCU(cu: any) {
    this.gsiFacade.viewBetDetails(cu.id, Librarytypes.ChangeUnit);
  }

  getLibraryData() {
    this.gsiFacade.viewLibraryData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.libraryData = res;
        const foundIndex = this.changeUnitList.data.findIndex((gsi: any) => gsi.id == res.id);
        /* istanbul ignore else */
        if (foundIndex != -1) {
          this.changeUnitList.data[foundIndex].displayList = res.layers;
        }
      }
    });
  }
  getIndividualCu() {
    this.changeunitFacade.nodeSeparateCu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res && res == 'IndCu') {
        this.isSeparateCu = true;
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
