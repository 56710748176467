import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AlertService, GsiEndpointService } from '@common-services';
import { DraggableAttribute, IStyle, TranslatorService } from '@common-services';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'lib-cart-template',
  templateUrl: './cart-template.component.html',
  styleUrls: ['./cart-template.component.scss']
})
export class CartTemplateComponent implements OnInit {
  @Input() tabSelected: 'child' | 'expand' | 'cart';
  @Input() cartTemplateType: any;
  @Input() cartAttributesPositionMap: { [key: number]: DraggableAttribute } = {};
  @Input() style: IStyle = {
    changedElementStyle: 'selectedColor',
    changedElementValue: '',
  };
  @Input() attributeDirectiveId: string;
  @Output() enableDroppedAttribute = new EventEmitter();
  @Output() allowDropEvent = new EventEmitter();
  @Output() onDeletingCartAttrEvt = new EventEmitter();
  @Output() recieveEmittedDirectiveIdEvt = new EventEmitter();
  @ViewChildren('cartAttributeStyles') cartAttributeStyles: QueryList<ElementRef>;
  @Output() cartAttributeStylesEmit = new EventEmitter();
  @Input() cartAttrStyleArray: any = [];
  labels: any;
  cartConfig: any;
  backGroundColor: string = '';
  cartBoxShadow: string = '';
  cartBorderColor: string = '';
  cartBorderRadius: number;
  cartBorderWidth: number;
  cartTemplateNumber: number;
  templateType: number = -1;
  quantityIncrementButton: string = '';
  quantityDecrementButton: string = '';
  ngUnsubscribe = new Subject();
  @Input() cartAttrStyleArr: any = [];

  constructor(private gsiEndpointService: GsiEndpointService,protected translator: TranslatorService,private alertService: AlertService) {
    this.detectLanguageChange();
  }
  ngOnInit(): void {
    this.getCartConfiguration();
  }
  enableDroppedAttributeOnTemplate(event: any, type: string) {
    this.enableDroppedAttribute.emit({ event: event, type: type });
  }
  allowDrop(event: any) {
    this.allowDropEvent.emit(event);
  }
  onDeletingCartAttr(event: any) {
    this.onDeletingCartAttrEvt.emit(event);
  }
  recieveEmittedDirectiveId(event: any) {
    this.recieveEmittedDirectiveIdEvt.emit(event);
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }
  ngOnChanges() {
    this.cartAttributeStylesEmit.emit(this.cartAttributeStyles);
  }
  getCartConfiguration(): void {
    this.gsiEndpointService.getCartConfiguration().subscribe(
      (response: any) => {
        this.cartConfig = response?.result;
        this.templateType = parseInt(this.cartConfig?.templateType);
        this.cartTemplateNumber = parseInt(this.cartConfig?.templateType);
        this.cartBoxShadow = this.cartConfig?.styles?.boxShadow;
        this.cartBorderRadius = parseInt(this.cartConfig?.styles?.borderRadius);
        this.cartBorderWidth = parseInt(this.cartConfig?.styles?.borderWidth);
        this.cartBorderColor = this.cartConfig?.styles?.borderColor;
        this.quantityDecrementButton = this.cartConfig?.styles?.decrementButton;
        this.quantityIncrementButton = this.cartConfig?.styles?.incrementButton;
        this.backGroundColor = this.cartConfig.background;
      },
      (error: any) => {
        this.alertService.showToaster(error.message, error.name, 'error');
      }
    );
  }
}
