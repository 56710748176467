import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertService, GsiFacadeService, TranslatorService
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-node-independent-entity-edit',
  templateUrl: './node-independent-entity-edit.component.html',
  styleUrls: ['./node-independent-entity-edit.component.scss'],
})
export class NodeIndependentEntityEditComponent implements OnInit, OnDestroy {
  @Input() configurationData: any;
  @Input() tempGsiObj: any;
  @Output() closeentity: EventEmitter<any> = new EventEmitter<any>();

  ngUnsubscribe = new Subject();
  close: boolean = false;
  step: number = 0;
  selectedChangedriverOptCu: any;
  cardformGroup: FormGroup;
  optChangeDrivers: any = ['OPTIONAL', 'NEGATIVE', 'ALTERNATIVE', 'NONE'];
  entityConfig: any;
  labels: any;
  isConditionalPotentiality: any;
  isGlobalEntity: boolean = false;
  isAuditable: boolean = false;
  setAsMasterData: boolean = true;
  uniqueKey: any = [];
  attributesList: any;
  roles: any = [];
  selectedindex: any;
  TxnRights: any = [];
  DesignRights: any = [];
  TableColorPickr: boolean;
  options: any[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

  constructor(
    private entityFacadeService: NodeEntityFacadeService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private gsiFacadeService: GsiFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService
  ) {
    this.cardformGroup = formBuilder.group({
      minimumselection: [0],
      maximumselection: [0],
      minimumselectionAll: [false],
    });
    this.detectLanguageChange();
    this.detectTableTemplateData();
    this.detectFetchedEntity();
  }

  ngOnInit() {
    /* ------------- For Entity Configuration - START ---------------- */
    this.setEntityConfig();
    this.checkConfigEnt();
    /* istanbul ignore next */
    if (this.configurationData.data?.entityData?.configuration) {
      this.entityConfig.type = JSON.parse(this.configurationData.data?.entityData?.configuration).type;
      if (this.entityConfig.type == 'table') {
        this.entityFacadeService.tableUIConfiguration(true, this.configurationData);
      } else {
        this.entityConfig = JSON.parse(this.configurationData.data?.entityData?.configuration);
      }
      /* istanbul ignore next */
      if (this.entityConfig?.cardSettings && Object.keys(this.entityConfig?.cardSettings).length !== 0) {
        this.cardformGroup.setValue(this.entityConfig.cardSettings);
      }
    }
    /* ------------- For Entity Configuration - END ---------------- */
    this.configurationData?.data?.entityData?.designTimeRights?.forEach((role: any) => {
      this.roles?.push({
        name: role.name ? role.name : role.rightHolderName,
        id: role.rightHolderId,
        rightHolderId: role.rightHolderId,
      });
    });

    this.DesignRights = this.configurationData?.data?.entityData?.designTimeRights;
    this.TxnRights = this.configurationData?.data?.entityData?.txnTimeRights;
    this.getDesignRightsIndex(this.DesignRights);
    this.getTxnRightsIndex(this.TxnRights);
  }

  // Get entity configuration
  checkConfigEnt() {
    /* istanbul ignore next */
    let entityConfig = this.configurationData.data?.entityData?.entityProperties;
    /* istanbul ignore next */
    if (entityConfig) {
      this.isConditionalPotentiality = true;
    } else {
      this.isConditionalPotentiality = false;
    }
  }

  closeEntity() {
    this.close = true;
    this.closeentity.emit(this.close);
    this.changeunitFacade.closeActionpanel();
  }

  saveconf() {
    /* istanbul ignore next */
    if (this.entityConfig?.type === 'card') {
      if (this.entityConfig.cardSelection == 'single') {
        this.entityConfig.hideSelectAll = true;
        this.entityConfig.cardSettings = {
          maximumselection: 1,
          minimumselection: 1,
          minimumselectionAll: false,
        };
      } else if (this.entityConfig.cardSelection == 'multiple') {
        /* istanbul ignore next */
        this.entityConfig.cardSettings = this.cardformGroup?.value;
      } else {
        this.entityConfig.cardSettings = {};
      }
      this.configurationData['configuration'] = JSON.stringify(this.entityConfig);
    } else {
      this.configurationData['configuration'] = JSON.stringify(this.entityConfig);
    }
    /* istanbul ignore next */
    if (this.isConditionalPotentiality) {
      if (this.configurationData?.data?.entityData?.entityProperties) {
        this.configurationData.data.entityData.entityProperties = {};
      }
      const entityProperties = {
        isConditionalPotentialityEntity: this.isConditionalPotentiality,
      };
      this.configurationData.data.entityData.entityProperties = entityProperties;
    } else {
      /* istanbul ignore next */
      if (this.configurationData?.data?.entityData?.entityProperties) {
        delete this.configurationData.data.entityData.entityProperties;
      }
    }
    if (this.configurationData?.data?.entityData) {
      this.configurationData.data.entityData.isMasterData = this.setAsMasterData;
    }
    if (this.configurationData?.data?.entityData) {
      this.configurationData.data.entityData.isAuditableData = this.isAuditable;
      if (this.configurationData?.data?.entityData['entityProperties']) {
        this.configurationData.data.entityData['entityProperties'].isTraceAudit = 'true';
      } else {
        this.configurationData.data.entityData['entityProperties'] = { isTraceAudit: 'true' };
      }
    }
    /* istanbul ignore next */
    this.entityFacadeService.saveEntity(
      this.configurationData?.data?.entityData,
      false,
      this.configurationData?.data?.cu,
      [],
      this.configurationData.data?.layerType,
      false,
      this.configurationData.data?.layerType,
      this.configurationData?.configuration
    );
    /* istanbul ignore next */
    this.closeEntity();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  setEntityConfig() {
    this.entityConfig = {
      setting: {},
      export: [
        {
          label: 'Pdf',
          isChecked: false,
        },
        {
          label: 'CSV',
          isChecked: false,
        },
        {
          label: 'Excel',
          isChecked: false,
        },
      ],
      colouring: {},
      tableDescription: {},
      pagination: {
        rows: 10,
        placementOfPagination: '',
      },
      display: '',
      newTableConfiguration: true,
      type: '',
      hideSelectAll: false,
      cardSelection: '',
      cardSettings: {},
    };
  }

  entityConfigChange() {
    /*istanbul ignore next*/
    if (this.entityConfig.type === '') {
      this.setEntityConfig();
    } else if (this.entityConfig.type === 'table') {
      this.entityFacadeService.tableUIConfiguration(true, this.configurationData);
    }
  }

  /* saving tuples data for card view entity */
  saveCardViewDetails(event: any) {
    this.entityConfig.setting = event;
    /*istanbul ignore next*/
    this.entityConfig.cardSettings = this.cardformGroup?.value;
    this.saveconf();
  }

  // Open global entity dialog
  openGlobalEntityConfirmation(templateRef?: TemplateRef<any>) {
    this.dialog.open(templateRef, { width: '700px' });
  }

  // Close global entity dialog
  closeGlobalEntityDialog(value: boolean) {
    this.isGlobalEntity = value;
    if (value) {
      if (this.configurationData.data.entityData) {
        if (this.configurationData.data.entityData['entityProperties']) {
          this.configurationData.data.entityData['entityProperties'].isGlobal = 'true';
        } else {
          this.configurationData.data.entityData['entityProperties'] = { isGlobal: 'true' };
        }
      }
    }
    this.dialog.closeAll();
  }

  detectTableTemplateData() {
    this.entityFacadeService.updateEntityTblConfig$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.entityConfig = res;
      this.saveconf();
    });
  }

  openDialog(templateRef: TemplateRef<any>) {
    /*istanbul ignore next*/
    this.attributesList = this.configurationData?.data?.entityData?.nslAttributes;
    /*istanbul ignore next*/
    this.attributesList = this.attributesList?.map((attr: any, i: number) => {
      attr = { ...attr, visible: true };
      return attr;
    });
    this.dialog.closeAll();
    this.dialog.open(templateRef);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  selectPrimaryAttribute(event: any, i: number) {
    let attrObj = this.attributesList[i];
    /*istanbul ignore else*/
    if (attrObj.visible) delete attrObj.visible;
    /*istanbul ignore next*/
    if (event?.checked) {
      this.uniqueKey.push(attrObj);
      this.attributesList[i].visible = false;
    } else {
      this.uniqueKey = this.uniqueKey?.filter((attr: any) => attr.id !== attrObj.id);
      this.attributesList[i].visible = true;
    }
  }

  saveUniqueKey() {
    if (this.uniqueKey.length) {
      /*istanbul ignore next*/
      if (!this.configurationData?.data?.entityData?.uniqueConstraints)
        this.configurationData.data.entityData = {
          ...this.configurationData?.data?.entityData,
          uniqueConstraints: [this.uniqueKey],
        };
      else this.configurationData?.data?.entityData?.uniqueConstraints?.push(this.uniqueKey);
      this.uniqueKey = [];
      /*istanbul ignore next*/
      this.attributesList = this.configurationData?.data?.entityData?.uniqueConstraints;
    } else {
      this.alertService.showToaster('Select Attributes to create Primary key', '', 'warning');
    }
    this.closeDialog();
  }

  deleteUniqueKey(iKey: number) {
    this.configurationData.data.entityData.uniqueConstraints.splice(iKey, 1);
  }

  fetEntityByEntityId() {
    let id = this.configurationData?.data?.entityData?.id;
    if (id) {
      this.entityFacadeService?.fetchEntityByEntityId(id);
    }
  }
  detectFetchedEntity() {
    this.entityFacadeService.entityDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        if (res?.isMasterData) {
          this.setAsMasterData = res?.isMasterData;
        }
      }
    });
  }

  buttonEnter(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }

  buttonLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }

  menuenter() {
    this.gsiFacadeService.menuenter();
  }

  menuLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }

  showRights(i: any) {
    this.selectedindex = i;
  }

  removeAgent(i: any) {
    this.roles.splice(i, 1);
    /*istanbul ignore next*/
    this.configurationData?.data?.entityData?.designTimeRights.splice(i, 1);
    /*istanbul ignore next*/
    this.configurationData?.data?.entityData?.txnTimeRights.splice(i, 1);
  }

  getDesignRightsIndex(role: any) {
    if (this.DesignRights && this.DesignRights.length > 0 && role) {
      /*istanbul ignore next*/
      return this.DesignRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.rightHolderId === role.id
      );
    }
    return -1;
  }

  getTxnRightsIndex(role: any) {
    if (this.TxnRights && this.TxnRights.length > 0 && role) {
      /*istanbul ignore next*/
      return this.TxnRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.rightHolderId === role.id
      );
    }
    return -1;
  }

  autoCheckRights(rights: any) {
    /*istanbul ignore next*/
    if (rights && (rights.decisionRight || rights.executionRight)) {
      rights.informationRight = true;
    }
  }

  getUniqueConstraints(uniqueConstraints: any) {
    if (uniqueConstraints && uniqueConstraints?.length) {
      this.configurationData.data.entity.uniqueConstraints = uniqueConstraints;
    } else {
      this.configurationData.data.entity.uniqueConstraints = [];
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
