<section class="tab-header">
  <div class="row no-gutters align-items-center">
    <div class="col node-search-wrap">
      <div class="node-search-right">
        <div class="node-search-block right-item">
          <button
            (click)="displayNodesearch = !displayNodesearch"
            class="search-btn-icon search-btn-large"
            id="search_Agent"
          ></button>
        </div>
      </div>

      <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
        <mat-form-field appearance="legacy">
          <button
            mat-icon-button
            matSuffix
            (click)="displayNodesearch = !displayNodesearch; searchText = ''; getAllAgents()"
          >
            <mat-icon matSuffix>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchText" placeholder="Search" (keyup)="searchInput.next()" />
        </mat-form-field>
      </div>
    </div>
  </div>
</section>
<section class="tab-body">
  <ul class="common-list">
    <li *ngFor="let agent of agentsList?.rolesList">
      <div class="d-flex item align-items-center active">
        <div class="details">
          <p class="title" matTooltip="{{ agent.name }}">
            {{ agent.name }}
          </p>
        </div>
        <a class="add-icon" (click)="addAgent(agent)" id="addAgent_{{ agent?.name?.replaceAll(' ', '_') }}"
          ><span></span
        ></a>
        <a class="copy-icon" id="copyAgent_{{ agent?.name?.replaceAll(' ', '_') }}"><span></span></a>
        <a class="more-icon" id="moreAgentDetails_{{ agent?.name?.replaceAll(' ', '_') }}" (click)="moreAgent(agent)"
          ><span></span
        ></a>
      </div>
      <div>
        <ul class="agents-sublist li-top-0" *ngIf="openAgentsPanel && agent?.id == currentAgentId">
          <li
            *ngFor="let user of userListByRoles"
            id="mainElements_{{ user?.name | stringReplace }}"
            class="li-top-0 d-flex justify-content-between user-dropdown-padding"
          >
            <p id="users_{{ user?.name | stringReplace }}">
              {{ user?.name }}
            </p>
            <span (click)="addAgent(user)">
              <img
                [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
                alt=""
                class="img-h25 hover-img hoverplusimg"
                id="iconClickingAgents_{{ user?.name | stringReplace }}"
              />
            </span>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</section>
<div class="tab-footer">
  <app-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [totalRecords]="agentsList?.totalRoles"
    (onPageChange)="onPageChange($event)"
  >
  </app-paginator>
</div>
