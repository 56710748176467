import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  OnChanges,
  Optional,
  Inject,
  HostListener,
  TemplateRef,
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OCRFacadeService } from '../ocr/ocr-facade.service';
import { A2aFacadeService } from '../a2a/a2a-facade.service';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';

@Component({
  selector: 'app-image-map',
  templateUrl: './image-map.component.html',
  styleUrls: ['./image-map.component.scss'],
})
export class ImageMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() fileType: string;
  @Input()
  src: string;

  @Input()
  coordinates: ImageMapCoordinate[];

  @Input()
  canEdit: boolean;

  @Input()
  component: any;
  @Input()
  canMap: boolean;

  @Input()
  ontology: string;

  @Output() finishMapping = new EventEmitter<boolean>();

  @Input() originalDocumentSize: any;
  @Input() isTemplate: any;
  @ViewChild('imagemapping') imagecoordinates: ElementRef;
  /* istanbul ignore */
  @Output('onClick')
  /* istanbul ignore */
  onClick: EventEmitter<ImageMapCoordinate> = new EventEmitter();
  domHeight: any;
  domWidth: any;
  expandRightPanel: boolean = false;
  startWidth = 15;
  startHeight = 40;
  refreshPage: boolean = true;
  private ngUnsubscribe = new Subject();
  pageNumber = 1;
  totalPages: number;
  showPaginationFlag: boolean;
  coordinatesByPAgeNumber: any = [];
  newCoordinates: any = [];
  startMapping: boolean = false;
  startBoundary: boolean = false;
  newMappedCordinate: any;
  startingCoordinate: any;
  firstClick: any;
  selectedCoordinate: any;
  plusCursor: boolean = false;
  confirmDeleteData: string;
  startX: number;
  startY: number;
  imagePosition: any = {};
  endX: number;
  endY: number;
  scrollPosition: number = 0;
  fieldDetails: any;
  attributeDetails: any;
  cdecId: any;
  newCoordinatesByPageNumber: any;
  expanded: any;
  currentCu: any;
  labels: any;
  dragClickStarted: boolean;
  constructor(
    private ocrFacadeService: OCRFacadeService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private a2aFacade: A2aFacadeService,
    private translator: TranslatorService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    /* istanbul ignore next */
    if (data) {
      this.src = data.src;
      this.originalDocumentSize = data.originalDocumentSize;
      this.coordinates = data.coordinates;
      this.fileType = data.fileType;
      this.canEdit = data.canEdit;
      this.coordinatesByPAgeNumber = data.coordinates;
      this.attributeDetails = data.attribute_details;
      this.cdecId = data.cdecId;
      this.expanded = data.expanded;
      this.currentCu = data.currentCu;
      this.isTemplate = data.isTemplate;
    }
    this.detectRightPanelExpandedFlag();
    this.detectLanguageChange();
  }

  ngOnInit() {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnChanges() {
    if (this.canMap) {
      this.enableMapping();
    }
    this.coordinatesByPAgeNumber = [];
    if (this.fileType == 'pdf') {
      for (let ele of this.coordinates) {
        /* istanbul ignore else */
        if (ele.page_no == this.pageNumber) {
          this.coordinatesByPAgeNumber.push(ele);
        }
      }
    } else {
      this.coordinatesByPAgeNumber = this.coordinates;
    }
  }

  detectRightPanelExpandedFlag() {
    this.ocrFacadeService.rightPanelExpanded$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.call === true) {
        this.refreshPage = true;
        this.expandRightPanel = res?.expandPanel;
      } /* istanbul ignore else */ else if (res?.call === false) {
        this.refreshPage = false;
      }
    });
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this.coordinatesByPAgeNumber = [];
    this.newCoordinatesByPageNumber = [];
    for (let ele of this.coordinates) {
      /* istanbul ignore else */
      if (ele.page_no == this.pageNumber) {
        this.coordinatesByPAgeNumber.push(ele);
      }
    }
    for (let ele of this.newCoordinates) {
      /* istanbul ignore else */
      if (ele.page_no == this.pageNumber) {
        this.newCoordinatesByPageNumber.push(ele);
      }
    }
  }

  changePage(event: any) {
    /* istanbul ignore next */
    this.pageNumber = event?.pageIndex + 1;
    this.coordinatesByPAgeNumber = [];
    this.newCoordinatesByPageNumber = [];
    for (let ele of this.coordinates) {
      /* istanbul ignore else */
      if (ele.page_no == this.pageNumber) {
        this.coordinatesByPAgeNumber.push(ele);
      }
    }
    for (let ele of this.newCoordinates) {
      /* istanbul ignore else */
      if (ele.page_no == this.pageNumber) {
        this.newCoordinatesByPageNumber.push(ele);
      }
    }
  }

  pageChange(pageData: any) {
    this.pageNumber = pageData;
  }

  pageRendered(e: any) {
    /* istanbul ignore next */
    this.domHeight = e?.source?.height;
    /* istanbul ignore next */
    this.domWidth = e?.source?.width;
  }

  /**
   * take actual height of the image
   * take dom height and width of the image in div
   *  domheight / actualheight * actualpixel
   *  x cal should be done on --- width
   *  y  cal should be done on ---- height
   */

  getCoordinateStyle(coordinate: ImageMapCoordinate, type?: any): object {
    /* istanbul ignore else */
    if (this.fileType === 'image' && this.imagecoordinates) {
      /* istanbul ignore next */
      this.domHeight = this.imagecoordinates?.nativeElement?.height;
      /* istanbul ignore next */
      this.domWidth = this.imagecoordinates?.nativeElement?.width;
    }
    /* istanbul ignore next */
    const x = (this.domWidth / this.originalDocumentSize?.width) * coordinate?.x;
    /* istanbul ignore next */
    const y = (this.domHeight / this.originalDocumentSize?.height) * coordinate?.y - this.scrollPosition;
    /* istanbul ignore next */
    const height = (this.domHeight / this.originalDocumentSize?.height) * coordinate?.height;
    /* istanbul ignore next */
    const width = (this.domWidth / this.originalDocumentSize?.width) * coordinate?.width;

    /* istanbul ignore next */
    let ele = document.getElementById(`${type}_${coordinate?.height}_${coordinate?.width}`);
    let zindex;
    /* istanbul ignore next */
    if (width && height && ele) {
      zindex = Math.ceil(9 + Math.ceil(window?.innerHeight * window?.innerWidth) / Math.ceil(width * height));
      ele.style.zIndex = `${zindex}`;
    }

    return {
      height: `${height}px`,
      width: `${width}px`,
      left: `${x}px`,
      top: `${y}px`,
    };
  }

  viewManualMapping(coordinate: ImageMapCoordinate): object {
    /* istanbul ignore next */
    return {
      height: `${coordinate?.height}px`,
      width: `${coordinate?.width}px`,
      left: `${coordinate?.x}px`,
      top: `${coordinate?.y}px`,
    };
  }

  onAreaClick(coordinate: any) {
    this.onClick.emit(coordinate);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onAreaEdit(coordinate: ImageMapCoordinate): ImageMapCoordinate {
    return coordinate;
  }
  getActualX(event: any) {
    let val = 10.5892;
    /*istanbul ignore next*/
    var path = event?.path || (event?.composedPath && event?.composedPath());
    /*istanbul ignore next*/
    let windowX = (event?.view?.innerWidth - path[0]?.clientWidth) / 2;
    // let windowX=(event.view.innerWidth/val) + 24;
    /*istanbul ignore next*/
    let actualX = event?.clientX - windowX;
    return actualX;
  }
  getActualY(event: any) {
    let val = 24;
    /*istanbul ignore next*/
    let actualY = event?.clientY - val;
    return actualY;
  }
  getDomWidth(event: any) {
    this.domWidth = event?.path[0]?.clientWidth;
    this.domHeight = event?.path[0]?.clientHeight;
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    /*istanbul ignore next*/
    this.scrollPosition = (event.target as Element)?.scrollTop;
    /*istanbul ignore next*/
    if (!this.scrollPosition) this.scrollPosition = 0;
  }
  @HostListener('window:pointerdown', ['$event'])
  startDrag(event: MouseEvent) {
    this.dragClickStarted = true;
    this.startX = this.getActualX(event);
    this.startY = this.getActualY(event);
  }
  @HostListener('window:pointermove', ['$event'])
  moveDrag(e: MouseEvent) {
    if (this.dragClickStarted) {
      this.createManualBoxCoordinates(e);
    }
  }

  resetBox() {
    this.newCoordinatesByPageNumber = [];
  }

  createManualBoxCoordinates(event: MouseEvent) {
    this.endX = event.clientX;
    this.endY = event.clientY;
    /*istanbul ignore next*/
    let data = {
      height: Math.round(this.getActualY(event) - this.startY),
      page_no: this.imagecoordinates ? 0 : this.pageNumber,
      width: Math.round(this.getActualX(event) - this.startX),
      ymax: Math.round(this.getActualY(event)) + this.scrollPosition,
      x: Math.round(this.startX),
      xmax: Math.round(this.getActualX(event)),
      y: Math.round(this.startY) + this.scrollPosition,
    }; // 74 added becoz of button done
    let checkFlag: boolean = data?.height > 2 && data?.width > 2;
    let ratioW = this.originalDocumentSize?.width / this.domWidth;
    let ratioH = this.originalDocumentSize?.height / this.domHeight;
    data.xmax = ratioW * data?.xmax;
    data.x = ratioW * data?.x;
    data.ymax = ratioH * data?.ymax;
    data.y = ratioH * data?.y;
    data.height = ratioH * data?.height;
    data.width = ratioW * data?.width;
    /*istanbul ignore next*/
    if (checkFlag) {
      if (this.newCoordinates.length == 0) {
        this.newCoordinates.push(data);
        if (this.fileType == 'image') {
          this.newCoordinatesByPageNumber = this.newCoordinates;
        } else if (this.fileType == 'pdf') {
          /* istanbul ignore else */
          this.newCoordinatesByPageNumber = [];
          for (let ele of this.newCoordinates) {
            /* istanbul ignore else */
            if (ele.page_no == this.pageNumber) {
              this.newCoordinatesByPageNumber.push(ele);
            }
          }
        }
      } else {
        this.newCoordinates.pop();
        this.newCoordinates.push(data);
        if (this.fileType == 'image') {
          this.newCoordinatesByPageNumber = this.newCoordinates;
        } else if (this.fileType == 'pdf') {
          /* istanbul ignore else */
          this.newCoordinatesByPageNumber = [];
          for (let ele of this.newCoordinates) {
            /* istanbul ignore else */
            if (ele.page_no == this.pageNumber) {
              this.newCoordinatesByPageNumber.push(ele);
            }
          }
        }
      }
    }
  }
  @HostListener('window:pointerup', ['$event'])
  stopDrag(event: MouseEvent) {
    this.dragClickStarted = false;
    this.createManualBoxCoordinates(event);
  }

  @HostListener('document:click', ['$event'])
  onClickEvent(event: MouseEvent) {
    if (this.startMapping && this.firstClick == undefined) {
      this.firstClick = {};
      this.firstClick.x = event.clientX - this.startWidth;
      this.firstClick.y = event.clientY + this.startHeight;
      this.plusCursor = true;
    } else if (
      this.startMapping &&
      this.firstClick != undefined &&
      this.startingCoordinate == undefined &&
      this.selectedCoordinate == undefined
    ) {
      /* istanbul ignore next */
      if (
        event.clientX - this.firstClick.x > this.domWidth ||
        event.clientX - this.firstClick.x < 0 ||
        event.clientY - this.firstClick.y > this.domHeight ||
        event.clientY - this.firstClick.y < 0
      ) {
        this.alertService.showToaster('click on Image Only', '', 'error');
        return;
      }
      this.startingCoordinate = {};
      /* istanbul ignore next */
      this.startingCoordinate.x = event?.clientX - this.firstClick?.x;
      /* istanbul ignore next */
      this.startingCoordinate.y = event?.clientY - this.firstClick?.y;
      this.startingCoordinate.width = 0;
      this.startingCoordinate.height = 0;
    } else if (this.startMapping && this.firstClick != undefined && this.startingCoordinate) {
      this.selectedCoordinate = this.startingCoordinate;
      this.startingCoordinate = undefined;
      this.plusCursor = false;
    } else if (this.selectedCoordinate != undefined) {
      this.firstClick = undefined;
      this.startMapping = false;
      this.selectedCoordinate = undefined;
      this.finishMapping.emit(false);
    }
  }

  @HostListener('mousemove', ['$event'])
  onMoveEvent(event: MouseEvent) {
    /* istanbul ignore next */
    if (this.startMapping && this.startingCoordinate) {
      let width = event.clientX - this.firstClick?.x - this.startingCoordinate?.x;
      let height = event.clientY - this.firstClick?.y - this.startingCoordinate?.y;
      this.startingCoordinate.width = width;
      this.startingCoordinate.height = height;
    }
  }

  closeImage(templateRef: TemplateRef<any>) {
    this.dialog.closeAll();
    if (this.isTemplate && this.src) {
      this.confirmDeleteData =
        this.labels?.If_you_change_the_current_Document +
        ', ' +
        this.labels?.all_saved_mappings_will_be_deleted +
        '. ' +
        this.labels?.Do_you_want_to_continue +
        '?';
    } else {
      this.confirmDeleteData = this.labels?.Are_you_sure_you_want_to_delete_this_document + '?';
    }
    this.dialog.open(templateRef, { width: '600px' });
  }

  onCancelClick() {
    this.dialog.closeAll();
  }
  onDeleteClick() {
    this.ocrFacadeService.deleteOcr(true);
  }
  enableMapping() {
    this.startMapping = true;
    this.selectedCoordinate = undefined;
  }

  manualMappedAttribute() {
    /* istanbul ignore next */
    this.selectedCoordinate.x = this.selectedCoordinate?.x * (this.originalDocumentSize?.width / this.domWidth);
    /* istanbul ignore next */
    this.selectedCoordinate.y = this.selectedCoordinate?.y * (this.originalDocumentSize?.height / this.domHeight);
    /* istanbul ignore next */
    this.selectedCoordinate.width = this.selectedCoordinate?.width * (this.originalDocumentSize?.width / this.domWidth);
    /* istanbul ignore next */
    this.selectedCoordinate.height =
      this.selectedCoordinate?.height * (this.originalDocumentSize?.height / this.domHeight);
    /* istanbul ignore next */
    let payload = {
      file_path: this.src,
      userCoords: [
        parseInt(this.selectedCoordinate?.x),
        parseInt(this.selectedCoordinate?.y),
        parseInt(this.selectedCoordinate?.x + this.selectedCoordinate?.width),
        parseInt(this.selectedCoordinate?.y + this.selectedCoordinate?.height),
      ],
      domain: this.ontology,
      ref_file_dimenstions: this.originalDocumentSize,
    };
    this.ocrFacadeService.manualMap(payload);
  }
  getHeight() {
    return {
      height: `${this.domHeight}px`,
    };
  }
  onSubmit() {
    let currCoordinates: any = {};
    currCoordinates.x_min = Math.round(this.newCoordinates[0]?.x);
    currCoordinates.y_min = Math.round(this.newCoordinates[0]?.y);
    currCoordinates.x_max = Math.round(this.newCoordinates[0]?.xmax);
    currCoordinates.y_max = Math.round(this.newCoordinates[0]?.ymax);
    let payLoad = {
      cdecId: this.cdecId,
      file_path: this.src,
      page_no: this.pageNumber,
      coordinates: currCoordinates,

      file_dimensions: {
        height: this.originalDocumentSize?.height,
        width: this.originalDocumentSize?.width,
      },
      cu_json: { currentCU: this.currentCu },
      attribute_data: {
        attribute_name: this.attributeDetails?.attribute_displayName,
        attribute_id: this.attributeDetails?.attributeId,
        attribute_type: this.attributeDetails?.attribute_type,
        layer: this.attributeDetails?.isInfo ? 'information' : 'physical',
        enitity_id: this.attributeDetails?.entityId,
      },
    };
    this.a2aFacade.manualTransactionData(payLoad);
  }
}

export class ImageMapCoordinate {
  x = 0;
  y = 0;
  width = 100;
  height = 100;
  name?: string;
  page_no?: number;
  flag?: boolean;
  constructor(init?: Partial<ImageMapCoordinate>) {
    Object.assign(this, init);
  }
}
