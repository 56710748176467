import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Librarytypes,
  NodeGsiFacadeService,
  OCRFacadeService
} from '@common-services';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-ocr-popup',
  templateUrl: './node-ocr-popup.component.html',
  styleUrls: ['./node-ocr-popup.component.scss'],
})
export class NodeOcrPopupComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Input() initComponentData: any;
  leftPanel: boolean = false;
  rightPanel: boolean = false;
  uploadPanel: boolean = true;
  ocrData: any;
  changeUnit: any;
  currentClickedAttribute: any;
  tempGSIData: any;
  @Output() closeOCRPanel = new EventEmitter();
  entity: any;
  fetchedCU: any;
  constructor(
    private gsiFacadeService: NodeGsiFacadeService,
    private entityFacadeService: NodeEntityFacadeService,
    private changeunitFacadeService: NodeChangeUnitFacadeService,
    private ocrFacadeService: OCRFacadeService,
    public dialog: MatDialog
  ) {
    this.detectSavedGSI();
    this.detectcloseOCRModal();
    this.detectExistingOCR();
    this.detectSaveMappedFileDetails();
    this.detectFetchCu();
  }

  detectFetchCu() {
    this.changeunitFacadeService.fetchedCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cu: any) => {
      if (cu) {
        this.fetchedCU = cu?.cudata;
      }
    });
  }

  detectExistingOCR() {
    this.ocrFacadeService.cuDetailsForOCR$
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((changeUnit: any) => {
        if (changeUnit && changeUnit?.uiProperties?.cdecId) {
          const cdecId = changeUnit?.uiProperties?.cdecId;
          // this.isEdit = true;
          /* istanbul ignore next */
          if (cdecId) {
            const postData = {
              file: '',
              gsi_ID: '',
              cu_ID: '',
              cdecId: cdecId,
              trans_call: false,
            };
            this.ocrFacadeService.extractMappingInTransactionView(postData);
          }
        }
      });
  }

  detectSaveMappedFileDetails() {
    this.ocrFacadeService.fileMappedDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        res.isRecalledOCR = true;
        this.ocrData = res;
        this.uploadPanel = false;
        this.leftPanel = true;
        this.rightPanel = true;
      }
    });
  }

  changeLayout(arr: any) {
    if (arr == 'left') {
      this.uploadPanel = false;
      this.rightPanel = false;
      this.leftPanel = true;
    } else if (arr == 'right') {
      this.uploadPanel = false;
      this.rightPanel = true;
      this.leftPanel = false;
    } else if (arr == 'upload') {
      this.uploadPanel = true;
      this.rightPanel = false;
      this.leftPanel = false;
    }
  }
  ngOnInit(): void {
    this.initComponent();
  }

  initComponent() {
    this.tempGSIData = this.initComponentData.gsiData;
    this.changeUnit = this.initComponentData.changeUnit;
    this.entity = this.initComponentData.entity;
    if (this.fetchedCU?.uiProperties?.cdecId) {
      this.ocrFacadeService.updateCUDetailsForOCR(this.fetchedCU);
    }
  }

  openPDF(event: any) {
    this.ocrData = event;
    this.uploadPanel = false;
    this.leftPanel = true;
    this.rightPanel = true;
  }
  handleEmittedAttributes(event: any) {
    this.currentClickedAttribute = event;
  }
  closeComponent() {
    this.gsiFacadeService.saveGsi(this.tempGSIData);
    this.closeOCRPanel.emit(true);
  }
  /* istanbul ignore next */
  detectSavedGSI() {
    this.gsiFacadeService.multigsiDraftResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.gsiFacadeService.changeNSLComponent(Librarytypes.NSLGsi);
    });
  }
  detectcloseOCRModal() {
    this.ocrFacadeService.ocrData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.uploadPanel = true;
        this.rightPanel = false;
        this.leftPanel = false;
        // this.gsiFacadeService.closeFile(true);
        //   this.gsiFacadeService.saveGsi(this.tempGSIData);
        // this.closeOCRPanel.emit(true);
        //  this.dialog.closeAll();
      }
    });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

