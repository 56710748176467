import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityBoardEndpointService } from './entity-board-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class EntityBoardFacadeService {
  [x: string]: any;
  entity: any = {};
  role: any;
  gsiList: any;
  ngSubscribe = new Subject();
  cardConfiguration: any;

  public cardIds = new Subject();
  cardIds$ = this.cardIds.asObservable();

  private entityCardDetails: Subject<any> = new Subject<any>();
  public entityCardDetails$: Observable<any> = this.entityCardDetails.asObservable();

  private backToRoleGsiEntity = new Subject();
  backToRoleGsiEntity$ = this.backToRoleGsiEntity.asObservable();

  private getAllGsis = new Subject();
  getAllGsis$ = this.getAllGsis.asObservable();

  private getAllUnrelatedGsi = new Subject();
  getAllUnrelatedGsi$ = this.getAllUnrelatedGsi.asObservable();

  constructor(private entityBoardEndpointService: EntityBoardEndpointService) {}

  setBacktoRoleGsiEntity(type: string) {
    this.backToRoleGsiEntity.next(type);
  }

  getAllGsisPaginated(pageno: number, searchCriteria: string) {
    this.entityBoardEndpointService.getAllGsisPaginated(pageno, searchCriteria).subscribe((res: any) => {
      this.getAllGsis.next(res);
    });
  }

  saveCartConfig(requestBody: any) {
    return this.entityBoardEndpointService.saveCartConfig(requestBody);
  }

  getCartConfiguration(): Observable<any> {
    return this.entityBoardEndpointService.getCartConfiguration();
  }
  /**
   * This function is responsible for getting all published GSIs data from endpoint service
   * @param {number} pageSize
   * @param {number} pageNumber
   * @param {string} searchgsi -string to search
   */
  getAllUnrelatedGsis(pageSize: number, pageNumber: number, searchgsi: string) {
    this.entityBoardEndpointService.getAllUnrelatedGsis(pageSize, pageNumber, searchgsi).subscribe((res: any) => {
      this.getAllUnrelatedGsi.next(res);
    });
  }
  // getCardIds(data: any) {
  //   this.entityBoardEndpointService
  //     .getCardIds(data)
  //     .pipe(takeUntil(this.ngSubscribe))
  //     .subscribe((res: any) => {
  //       this.cardIds.next(res?.result[data]);
  //     });
  // }

  getEntityCardDetails(id: any) {
    if (id) {
      let result = this.entityBoardEndpointService.getEntityCardDetails(id);
      return result;
    }
  }
}
