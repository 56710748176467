<div class="overview-wrap">
  <div class="row">
    <div class="col-4">
      <div class="overview-bg">
        <div class="overviewbook">
          <div class="overviewbook-details">
            <figure>
              <img
                [src]="'/assets/img/configure-solution/overview-book.svg' | CDNUrlRewritePipe"
                alt=""
                [hidden] = "betType === 'gsiImport'"
              />
            </figure>
            <h3>{{ bookDetails?.name }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-8">
      <h2 *ngIf="betType === 'gsiImport'">{{ this.fileName }}</h2>

      <div class="overview-info" *ngIf="betType !== 'gsiImport'">
        <h2>{{ labels?.Overview }}</h2>
        <ul class="overview-labels">
          <li class="clr-orange" *ngIf="bookDetails?.price">Price: {{ bookDetails?.price }}</li>
          <li class="clr-green" *ngIf="bookDetails?.price?.length === 0">{{ labels?.Free }}</li>
          <li class="clr-blue" *ngIf="bookDetails?.noOfDownloads">{{ bookDetails?.noOfDownloads }}</li>
          <li class="clr-red" *ngIf="bookDetails?.solutionType">{{ bookDetails?.solutionType }}</li>
        </ul>
        <p *ngIf="bookFinalize?.shortDescription">
          {{ bookFinalize?.shortDescription }}
        </p>
        <p *ngIf="!bookFinalize?.shortDescription">
          {{ bookDetails?.marketplaceId?.description }}
        </p>
        <div class="developedby">
          <h6>{{ labels?.Developed_By }}</h6>
          <h3>
            <strong>{{ bookDetails?.author?.name }}</strong>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="configuration-details">
    <h4>{{ labels?.Configuration_Details }}</h4>
    <div class="details-wrap">
      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.roleConflictCount !== 0 && isMaped === true"
      >
        <div class="info-title pad-12">
          <h6>Roles Maped</h6>
          <span class="text-700">{{ NoOfRolesConflictedIndividually?.mapped }}</span>
        </div>
        <div *ngFor="let roles of bookFinalize?.conflictsAndResolutions?.roleConflicts">
          <div class="details-list pad-12" *ngIf="roles.conflictAction === 'MAP'">
            <div>
              <h6>{{ roles.sourceValue }}</h6>
              <span class="text-400">{{ roles.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.roleConflictCount !== 0 && isMerged === true"
      >
        <div class="info-title pad-12">
          <h6>Roles Merged</h6>
          <span class="text-700">{{ NoOfRolesConflictedIndividually?.merged }}</span>
        </div>
        <div *ngFor="let roles of bookFinalize?.conflictsAndResolutions?.roleConflicts">
          <div class="details-list pad-12" *ngIf="roles.conflictAction === 'MERGE'">
            <div>
              <h6>{{ roles.sourceValue }}</h6>
              <span class="text-400">{{ roles.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.roleConflictCount !== 0 && isRenamed === true"
      >
        <div class="info-title pad-12">
          <h6>Roles Renamed</h6>
          <span class="text-700">{{ NoOfRolesConflictedIndividually?.renamed }}</span>
        </div>
        <div *ngFor="let roles of bookFinalize?.conflictsAndResolutions?.roleConflicts">
          <div class="details-list pad-12" *ngIf="roles.conflictAction === 'RENAME'">
            <div>
              <h6>{{ roles.sourceValue }}</h6>
              <span class="text-400">{{ roles.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.bookConflictCount !== 0 && isBookRenamed === true"
      >
        <div class="info-title pad-12">
          <h6>{{ labels?.Solutions }} {{ labels?.Renamed }}</h6>
          <span class="text-700">{{ bookFinalize?.conflictsAndResolutions?.bookConflictCount }}</span>
        </div>
        <div *ngFor="let bookConflict of solutionChanges?.bookConflicts">
          <div class="details-list pad-12" *ngIf="bookConflict?.conflicting === true">
            <div>
              <h6>{{ bookConflict?.sourceValue }}</h6>
              <span class="text-400">{{ bookConflict?.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.gsiConflictCount !== 0 && isGsiRenamed === true"
      >
        <div class="info-title pad-12">
          <h6>{{ labels?.Solutions }} {{ labels?.Renamed }}</h6>
          <span class="text-700">{{ bookFinalize?.conflictsAndResolutions?.gsiConflictCount }}</span>
        </div>
        <div *ngFor="let gsiConflict of solutionChanges?.gsiConflicts">
          <div class="details-list pad-12" *ngIf="gsiConflict?.conflicting === true">
            <div>
              <h6>{{ gsiConflict?.sourceValue }}</h6>
              <span class="text-400">{{ gsiConflict?.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.cuConflictCount !== 0 && isCuRenamed === true"
      >
        <div class="info-title pad-12">
          <h6>{{ labels?.Change_Units }} {{ labels?.Renamed }}</h6>
          <span class="text-700">{{ bookFinalize?.conflictsAndResolutions?.cuConflictCount }}</span>
        </div>
        <div *ngFor="let cuConflict of solutionChanges?.cuConflicts">
          <div class="details-list pad-12" *ngIf="cuConflict?.conflicting === true">
            <div>
              <h6>{{ cuConflict?.sourceValue }}</h6>
              <span class="text-400">{{ cuConflict?.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.geConflictCount !== 0 && isEntRenamed === true"
      >
        <div class="info-title pad-12">
          <h6>{{ labels?.Entities }} {{ labels?.Renamed }}</h6>
          <span class="text-700">{{ bookFinalize?.conflictsAndResolutions?.geConflictCount }}</span>
        </div>
        <div *ngFor="let geConflict of solutionChanges?.geConflicts">
          <div class="details-list pad-12" *ngIf="geConflict?.conflicting === true">
            <div>
              <h6>{{ geConflict?.sourceValue }}</h6>
              <span class="text-400">{{ geConflict?.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="details-info"
        *ngIf="bookFinalize?.conflictsAndResolutions?.connectionConflictCount !== 0 && isConnRenamed === true"
      >
        <div class="info-title pad-12">
          <h6>{{ labels?.Connections }} {{ labels?.Renamed }}</h6>
          <span class="text-700">{{ bookFinalize?.conflictsAndResolutions?.connectionConflictCount }}</span>
        </div>
        <div *ngFor="let connConflict of bookFinalize?.conflictsAndResolutions?.connectionConflicts">
          <div class="details-list pad-12" *ngIf="connConflict?.conflicting === true">
            <div>
              <h6>{{ connConflict?.sourceValue }}</h6>
              <span class="text-400">{{ connConflict?.targetValue }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="details-info" *ngIf="bookFinalize.conflictsAndResolutions.attributeConflictCount !== 0">
        <div class="info-title pad-12">
          <h6>Entities Renamed</h6>
          <span class="text-700">{{bookFinalize.conflictsAndResolutions.attributeConflictCount}}</span>
        </div>
        <div class="details-list pad-12" *ngFor="let attributeConflict of solutionChanges.attributeConflicts">
          <div *ngIf="attributeConflict.conflicting === true">
            <h6>{{attributeConflict.sourceValue}}</h6>
            <span class="text-400">{{attributeConflict.targetValue}}</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <div class="configure-btns text-right">
    <button class="btn btn-next btn-active" (click)="importFromBetStore()">{{ labels?.Import }}</button>
    <button class="btn btn-cancel" (click)="cancel()">{{ labels?.Cancel }}</button>
  </div>
  <div class="err-msg-wrap">
    <app-shared-error-handler
      *ngIf="configurationType == 'Errors'"
      [errorData]="errorData"
      (closeErrorHandler)="closeRightBar($event)"
    ></app-shared-error-handler>
  </div>
</div>
