import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef, MatDialog  } from '@angular/material/dialog';
import {
  ApplicationPipesModule,
  AttSymbolPipe,
  CommonServicesModule,
  DatetimeTypeWidgetsModule,
  SharedErrorHandlerModule,
} from '@common-services';
import { ColorPickerModule } from 'ngx-color-picker';
import { DateTimePickerModule } from 'ngx-datetime-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TooltipModule } from 'primeng/tooltip';
import { ArEntityComponent } from './components/ar-entity/ar-entity.component';
import { AttributeDurationComponent } from './components/attribute-duration/attribute-duration.component';
import { CardTemplateConfigurationComponent } from './components/card-template-configuration/card-template-configuration.component';
import { CloneModalComponent } from './components/clone-modal/clone-modal.component';
import { CommonDcdComponent } from './components/common-dcd/common-dcd.component';
import { ConditionsComponent } from './components/conditions/conditions.component';
import { ConnectorsComponent } from './components/connectors/connectors.component';
import { DcdConditionsComponent } from './components/dcd-conditions/dcd-conditions.component';
import { NodeSystemAgentComponent } from './components/my-collections/node-system-agent/node-system-agent.component';
import { NodeSystemBookComponent } from './components/my-collections/node-system-book/node-system-book.component';
import { NodeSystemChangeunitsComponent } from './components/my-collections/node-system-changeunits/node-system-changeunits.component';
import { NodeSystemEntitiesComponent } from './components/my-collections/node-system-entities/node-system-entities.component';
import { NodeSystemSolutionsComponent } from './components/my-collections/node-system-solutions/node-system-solutions.component';
import { NestedConnectorsComponent } from './components/nested-connectors/nested-connectors.component';
import { NewEntityModalComponent } from './components/new-entity-modal/new-entity-modal.component';
import { NodeAttributeEditComponent } from './components/node-attribute-edit/node-attribute-edit.component';
import { NodeBetEditPopupComponent } from './components/node-bet-edit-popup/node-bet-edit-popup.component';
import { NodeBookEditComponent } from './components/node-book-edit/node-book-edit.component';
import { NodeCanvasSuggestionComponent } from './components/node-canvas-suggestion/node-canvas-suggestion.component';
import { NodeCompareComponent } from './components/node-compare/node-compare.component';
import { NodeCuEditComponent } from './components/node-cu-edit/node-cu-edit.component';
import { NodeEntityDcdComponent } from './components/node-entity-dcd/node-entity-dcd.component';
import { NodeEntityEditComponent } from './components/node-entity-edit/node-entity-edit.component';
import { NodeExpandEntityComponent } from './components/node-expand-entity/node-expand-entity.component';
import { NodeExpandLayersComponent } from './components/node-expand-layers/node-expand-layers.component';
import { NodeGsiEditComponent } from './components/node-gsi-edit/node-gsi-edit.component';
import { NodeIndependentAttributeEditComponent } from './components/node-independent-attribute-edit/node-independent-attribute-edit.component';
import { NodeIndependentEntityEditComponent } from './components/node-independent-entity-edit/node-independent-entity-edit.component';
import { NodeMenuPanelComponent } from './components/node-menu-panel/node-menu-panel.component';
import { NodeMenuTabComponent } from './components/node-menu-tab/node-menu-tab.component';
import { NodeMultiusePopupComponent } from './components/node-multiuse-popup/node-multiuse-popup.component';
import { NodeNestedAttributeModalComponent } from './components/node-nested-attribute-modal/node-nested-attribute-modal.component';
import { NodeNestedCuComponent } from './components/node-nested-cu/node-nested-cu.component';
import { NodeOcrLeftComponent } from './components/node-ocr-left/node-ocr-left.component';
import { NodeOcrPopupComponent } from './components/node-ocr-popup/node-ocr-popup.component';
import { NodeOcrRightComponent } from './components/node-ocr-right/node-ocr-right.component';
import { NodeOcrUploadComponent } from './components/node-ocr-upload/node-ocr-upload.component';
import { NodeParallelComponent } from './components/node-parallel/node-parallel.component';
import { NodeRecommendationsListComponent } from './components/node-recommendations-list/node-recommendations-list.component';
import { NodeReservedActionEntityComponent } from './components/node-reserved-action-entity/node-reserved-action-entity.component';
import { NodeSeparateCuComponent } from './components/node-separate-cu/node-separate-cu.component';
import { NodeSeparateCuDisplayTagsComponent } from './components/node-separateCu-display-tags/node-separateCu-display-tags.component';
import { NodeSpecialFeatureQueryConditionComponent } from './components/node-special-feature-query-condition/node-special-feature-query-condition.component';
import { NodeSpecialFeaturesComponent } from './components/node-special-features/node-special-features.component';
import { NodeTriggerAttributeEditComponent } from './components/node-trigger-attribute-edit/node-trigger-attribute-edit.component';
import { NslMeasuresComponent } from './components/nsl-measures/nsl-measures.component';
import { NslRelatedGsiComponent } from './components/nsl-related-gsi/nsl-related-gsi.component';
import { NslReviewScreenComponent } from './components/nsl-review-screen/nsl-review-screen.component';
import { NslSpecialFeaturesComponent } from './components/nsl-special-features/nsl-special-features.component';
import { ShadeOfPotentialityComponent } from './components/shade-of-potentiality/shade-of-potentiality.component';
import { ShadesOfPotentialityConnectionsComponent } from './components/shades-of-potentiality-connections/shades-of-potentiality-connections.component';
import { TblConfigurationComponent } from './components/tbl-configuration/tbl-configuration.component';
import { WidgetConfigurationComponent } from './components/widget-configuration/widget-configuration.component';
import { EntityMapperComponent } from './entity-mapper/entity-mapper.component';
import { MaterialModule } from './material/material.module';
import { CalendarModule } from 'primeng/calendar';
import { EntityToEntityDCDComponent } from './components/entity-to-entity-dcd/entity-to-entity-dcd.component';
import { NslReviewApprovalScreenComponent } from './components/nsl-review-approval-screen/nsl-review-approval-screen.component';
import { HelperTextComponent } from './components/helper-text/helper-text.component';
import { ConnectorMappingComponentComponent } from './components/connector-mapping-component/connector-mapping-component.component';
import { MappingDisplayComponent } from './components/mapping-display/mapping-display.component';
import { NodeConfirmDeleteComponent } from './components/node-confirm-delete/node-confirm-delete.component';
import { RcuDcdComponent } from './components/rcu-dcd/rcu-dcd.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
// import { EntityCardsModule } from 'entity-cards';
import { TableConfigurtionComponent } from './components/table-configurtion/table-configurtion.component';
import { VerticalTableDropComponent } from './components/vertical-table-drop/vertical-table-drop.component';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { NodeLibComponent } from './node-lib.component';
// import { EntityCardsModule } from 'projects/entity-cards/src/lib/entity-cards.module';
import { EntityCardsModule } from 'entity-cards';
import { FailedRecordsHandlingComponent } from './components/failed-records-handling/failed-records-handling.component';
import { ReviewStatusComponentNodelib } from './components/review-status/review-status.component';
import { MatTableModule } from '@angular/material/table';
import { NodeGsiFormComponent } from './components/node-gsi-form/node-gsi-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor, RetryInterceptor, ErrorHandlerInterceptor, CacheInterceptor, ApiPrefixInterceptor} from '@application-guard';

const sharedComponents: any = [
  NodeMenuPanelComponent,
  NodeMenuTabComponent,
  NodeSystemBookComponent,
  NodeSystemSolutionsComponent,
  NodeSystemChangeunitsComponent,
  NodeSystemEntitiesComponent,
  NodeSystemAgentComponent,
  NodeExpandEntityComponent,
  CloneModalComponent,
  NodeCanvasSuggestionComponent,
  NodeOcrUploadComponent,
  NodeOcrRightComponent,
  NodeOcrLeftComponent,
  NodeOcrPopupComponent,
  NslReviewScreenComponent,
  NodeNestedAttributeModalComponent,
  NodeParallelComponent,
  ShadeOfPotentialityComponent,
  NslSpecialFeaturesComponent,
  NodeSpecialFeaturesComponent,
  NodeSpecialFeatureQueryConditionComponent,
  NodeMultiusePopupComponent,
  NodeIndependentAttributeEditComponent,
  NslReviewApprovalScreenComponent,
  ReviewStatusComponentNodelib,
  NodeNestedCuComponent,
  CardTemplateConfigurationComponent,
  ConnectorsComponent,
  NestedConnectorsComponent,
  NodeCuEditComponent,
  NodeGsiEditComponent,
  NodeBookEditComponent,
  NodeEntityEditComponent,
  NodeAttributeEditComponent,
  NodeIndependentEntityEditComponent,
  NodeReservedActionEntityComponent,
  NslMeasuresComponent,
  ArEntityComponent,
  NodeTriggerAttributeEditComponent,
  NodeCompareComponent,
  AttributeDurationComponent,
  NodeEntityDcdComponent,
  NodeExpandLayersComponent,
  NodeBetEditPopupComponent,
  NodeConfirmDeleteComponent,
  EntityMapperComponent,
  ConditionsComponent,
  NodeSeparateCuComponent,
  NodeSeparateCuDisplayTagsComponent,
  TblConfigurationComponent,
  NewEntityModalComponent,
  NodeRecommendationsListComponent,
  WidgetConfigurationComponent,
  CommonDcdComponent,
  NslRelatedGsiComponent,
  NodeRecommendationsListComponent,
  ShadesOfPotentialityConnectionsComponent,
  EntityToEntityDCDComponent,
  HelperTextComponent,
  ConnectorMappingComponentComponent,
  MappingDisplayComponent,
  DcdConditionsComponent,
  RcuDcdComponent,
  TableConfigurtionComponent,
  VerticalTableDropComponent,
  NodeLibComponent,
  FailedRecordsHandlingComponent,
  NodeGsiFormComponent,
];
@NgModule({
  declarations: [...sharedComponents],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ApplicationPipesModule,
    TooltipModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    //  NewSolutionModule,
    // SharedErrorHandlerModule,
    ColorPickerModule,
    NgxMatNativeDateModule,
    DateTimePickerModule,
    InfiniteScrollModule,
    SharedErrorHandlerModule,
    CommonServicesModule,
    CalendarModule,
    NgxGraphModule,
    // EntityCardsModule,
    EntityCardsModule,
    SharedModule,
    TableModule,
    DropdownModule,
    DatetimeTypeWidgetsModule,
    MatDialogModule,
    MatTableModule,
  ],
  exports: [...sharedComponents, ColorPickerModule, CalendarModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AttSymbolPipe,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
})
export class NodeLibModule {}
