import { Component, ComponentFactoryResolver, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DropBase } from '../drop-base/drop-base';
import { RolesFacadeService, AlertService, EntityBoardEndpointService, LoaderService, EntityEndpointService, EntityBoardFacadeService, TranslatorService } from '@common-services';

@Component({
  selector: 'app-base-drop',
  templateUrl: './base-drop.component.html',
  styleUrls: ['./base-drop.component.scss'],
})
export class BaseDropComponent extends DropBase implements OnInit {
  constructor(
    router: Router,
    rolesFacadeService: RolesFacadeService,
    alertService: AlertService,
    entityBoardService: EntityBoardEndpointService,
    dialog: MatDialog,
    loader: LoaderService,
    el: ElementRef,
    entityEndpointService: EntityEndpointService,
    entityBoardFacade: EntityBoardFacadeService,
    translator: TranslatorService,
    resolver: ComponentFactoryResolver
  ) {
    super(
      router,
      rolesFacadeService,
      alertService,
      entityBoardService,
      dialog,
      loader,
      el,
      entityEndpointService,
      entityBoardFacade,
      translator,
      resolver
    );
  }
}
