import { NgModule } from '@angular/core';
import { ChipMultipleComponent } from './chip-multiple.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [ChipMultipleComponent],
  imports: [ControlsMaterialModule],
  exports: [ChipMultipleComponent],
})
export class ChipMultipleModule {
  static entry = {
    chipmultipleselection: ChipMultipleComponent,
  };
}
