import { Pipe, PipeTransform } from '@angular/core';
import { createConditionalArray } from '@common-services';

@Pipe({
  name: 'conditionDisplay',
  pure: true,
})
export class ConditionDisplayPipe implements PipeTransform {

  transform(condition: any) {
    if (condition && condition.length !== 0) {
      const conditionDisplayAttr = createConditionalArray(condition, [], true)
        .filter((ele: any) => ele !== undefined)
        .map(
          (value: any) => `${value?.sourceAttribute} ${value?.operator} ${value?.targetAttribute} ${value?.predicate}`
        )
        .join(' ');

      return conditionDisplayAttr ? `( ${conditionDisplayAttr} )` : '';
    }
    return condition;
  }

}