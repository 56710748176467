import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { EventsFacadeService, FieldConfig, getUiControlCustomization, SttEndpointService, TransactionFacadeService, validateDependentExpression } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-textarea',
  styleUrls: ['./textarea.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <div class="demo-full-width customlabeltip" [formGroup]="group">
      <div class="{{ parentClass }}" appearance="standard">
        <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
          <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
            <label
              class="main-label nh-mb-0"
              [pTooltip]="toolTip"
              id="{{ field.attribute.name }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              [style.color]="field.color"
              [style.font-size.px]="field.fontSize"
              *ngIf="
                (field?.sentence == null || field?.sentence == '') &&
                !field?.hideLabels &&
                !field?.configuration?.hideLabel &&
                !field?.hideLabelMultiAttr
              "
              ><span class="main-label-inner"
                ><span class="main-label-text text-truncate">{{ field?.label }} :</span>
                <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
              ></label
            >
            <p
              class="nh-ml-6 d-flex main-label-helperIcon"
              *ngIf="field?.configuration?.showHelperTextIcon"
              [ngStyle]="{
                color: field?.configuration?.helperTextColor,
                'font-size': field?.configuration?.helperTextFont
              }"
            >
              <mat-icon
                class="material-icons-outlined helperText-infoIcon"
                [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
                matTooltip="{{ field?.configuration?.helpertext }}"
                matTooltipPosition="above"
                *ngIf="!field?.hideLabels"
                >info_outline</mat-icon
              >
            </p>
          </div>
          <div
            class="d-flex align-items-center nh-column-gap-5 pb-2 multivalue-btns"
            *ngIf="
              field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
              (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
            "
          >
            <mat-icon
              matTooltip="Sub-Transactional CU"
              matSuffix
              class="d-flex align-items-center justify-content-center"
              *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
              ><span class="sub-cu" (click)="openSubPopUp()"
                ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
              /></span>
            </mat-icon>
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              id="txtAreaAdd_{{ field.attribute.name }}"
              (click)="addAttribute.next()"
              >add</mat-icon
            >
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              id="txtAreaAdd_{{ field.attribute.name }}"
              (click)="removeAttribute.next()"
              >remove</mat-icon
            >
          </div>
        </div>

        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>

        <div
          class="sub-right-class text-area-paret"
          *ngIf="
            (!foundObject?.event || foundObject?.event == '' || foundObject?.event == 'Option 7') &&
            !field?.attribute?.isTableConfig
          "
        >
          <span *ngIf="foundObject?.event == 'Option 7' && showPlaceholder" class="label-flex-row-placeholder d-none"
            >Type Here</span
          >
          <textarea
            class="text-multiline"
            [formControlName]="field.attribute.name"
            [readonly]="field.readonly"
            [required]="field?.isRequired && !field?.isInfo"
            (change)="onChange($event)"
            [matTooltip]="field?.configuration?.hideTooltip ? '' : field.value"
            [matTooltipPosition]="'above'"
            (keyup)="onValChange($event)"
            [value]="SpeechToText"
            id="textArea_{{ field.attribute.name }}"
            (focus)="onFocus($event)"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="4"
            ngDefaultControl
            matTooltip="{{ this.field.value }}"
            [ngClass]="{
              activeclick: isSelected,
              valueCheck: isValue,
              inValidClass:
                !field?.isInfo &&
                (group?.controls)[field?.attribute?.name]?.invalid &&
                (group?.controls)[field?.attribute?.name]?.touched
            }"
          ></textarea>
          <mat-icon class="mic-icon" *ngIf="field?.configuration?.configureMic" (click)="recordVoice()">mic</mat-icon>
        </div>

        <div
          *ngIf="foundObject?.event && foundObject?.event !== 'Option 7' && !field?.attribute?.isTableConfig"
          class="{{ appliedClass }}"
        >
          <mat-form-field>
            <textarea
              matInput
              [formControlName]="field.attribute.name"
              [matTooltip]="field?.configuration?.hideTooltip ? '' : field.value"
              [matTooltipPosition]="'above'"
              [readonly]="field.readonly"
              [required]="field?.isRequired && !field?.isInfo"
              (change)="onChange($event)"
              id="textArea_{{ field.attribute.name }}"
              (focus)="onFocus($event)"
              cdkAutosizeMinRows="4"
              ngDefaultControl
              [ngClass]="{ activeclick: isSelected, valueCheck: isValue }"
            ></textarea>

            <div *ngIf="foundObject?.event == 'Option 3'" class="word-count">
              <span>{{ field?.value?.length }}/</span><span>100</span>
            </div>
          </mat-form-field>
          <!-- <div class="table-multiline-wrapper" *ngIf="field?.attribute?.isTableConfig">
          <div *ngIf="!field?.configuration?.readonly">
            <p-overlayPanel #op styleClass="ui-control-dropdown">
              <textarea
                *ngIf="!foundObject?.event || foundObject?.event == ''"
                class="mutliline-input-table"
                [formControlName]="field?.attribute?.name"
                [readonly]="field?.readonly"
                [required]="field?.isRequired && !field?.isInfo"
                (change)="onChange($event)"
                [(ngModel)]="field.value"
                id="textArea_{{ field.attribute.name }}"
                (focus)="onFocus($event)"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                ngDefaultControl
                [ngClass]="{ activeclick: isSelected, valueCheck: isValue }"
              ></textarea>
              <mat-form-field *ngIf="foundObject?.event && foundObject?.event !== ''" class="{{ appliedClass }}">
                <textarea
                  matInput
                  class="mutliline-input-table"
                  [formControlName]="field.attribute.name"
                  [readonly]="field.readonly"
                  [required]="field?.isRequired && !field?.isInfo"
                  (change)="onChange($event)"
                  [(ngModel)]="field.value"
                  id="textArea_{{ field.attribute.name }}"
                  (focus)="onFocus($event)"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  ngDefaultControl
                  [ngClass]="{ activeclick: isSelected, valueCheck: isValue }"
                ></textarea>
                <div *ngIf="foundObject?.event == 'Option 3'" class="word-count">
                  <span>{{ field?.value?.length }}/</span><span>100</span>
                </div>
              </mat-form-field>
            </p-overlayPanel>
            <div class="custom-btn-table" (click)="op.toggle($event)" label="Your Text Here">
              <span class="label-text">Your Text Here</span>
            </div>
          </div>
        </div> -->
          <!-- <mat-icon
          matTooltip="Sub-Transactional CU"
          matSuffix
          class="d-flex align-items-center justify-content-center"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          ><span class="sub-cu" (click)="openSubPopUp()"
            ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
          /></span>
        </mat-icon>
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="txtAreaAdd_{{ field.attribute.name }}"
          (click)="addAttribute.next()"
          >add</mat-icon
        >
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="txtAreaAdd_{{ field.attribute.name }}"
          (click)="removeAttribute.next()"
          >remove</mat-icon
        >-->
        </div>
        <!-- <ng-container ngProjectAs="mat-error">
        <mat-error
          class="mat-error-mess"
          *ngIf="
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
          "
          >{{ field?.displayName ? field?.displayName : field?.name }} is invalid</mat-error
        >
      </ng-container> -->
      <!--textarea text in table-->
      <div *ngIf="field?.attribute?.isTableConfig && !field?.configuration?.readonly">
        <p-overlayPanel #op styleClass="ui-control-dropdown">
          <textarea
            class="textarea-table"
            [formControlName]="field.attribute.name"
            [readonly]="field.readonly"
            [required]="field?.isRequired && !field?.isInfo"
            (change)="onChange($event)"
            id="textArea_{{ field.attribute.name }}"
            (focus)="onFocus($event)"
            [ngClass]="{ activeclick: isSelected, valueCheck: isValue }"
            ngDefaultControl
          ></textarea>
        </p-overlayPanel>
        <div
          class="custom-btn-table"
          [ngClass]="{
            activeclick: isSelected,
            valueCheck: isValue,
            inValidClass:
              !field?.isInfo &&
              (group?.controls)[field?.attribute?.name]?.invalid &&
              (group?.controls)[field?.attribute?.name]?.touched
          }"
          (click)="op.toggle($event)"
          label="Your Text Here"
        >
          <span class="label-text" [pTooltip]="this.group.controls[field?.attribute?.name]?.value" tooltipPosition="right" >{{ this.group.controls[field?.attribute?.name]?.value }}</span>
        </div>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error class="error-message" *ngIf="showTextLength && getInfoWarnMessage(field, validation)">
          Required {{ group?.controls[field?.attribute?.name]?.value?.length }} / {{ validation.value }}
        </mat-error>
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </div>
  `,
  styles: [],
})
export class TextareaComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  toolTip: any;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  showTextLength: boolean = false;
  cuData: any;
  isSelected: boolean = false;
  isValue: boolean = false;
  foundObject: any;
  appliedClass: string = '';
  parentClass: string = 'cstm-textarea';
  showPlaceholder: boolean = false;
  validationList: any[] = [
    'greaterThan',
    'greaterThanOrEqual',
    'smallerThan',
    'smallerThanOrEqual',
    'equalTo',
    'notequalTo',
    'valuesSelect',
  ];

  private unsubscribe: Subject<any> = new Subject<any>();
  isRecordingEnabled: boolean = false;
  audio: string = 'general';
  SpeechToText: string;
   currentFocusedInputId:string
  ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacadeService: TransactionFacadeService,
    public sttService: SttEndpointService
  ) {
    this.detectCurrentCu();
    this.detectSelectedLabel();
    this.detectSttFinalOutput();
    this.detectSttInterimOutput();
  }

  ngOnInit(): void {
    if (this.field?.isRequired && !this.field?.isInfo) {
      this.toolTip = this.field.label + ' * ';
    } else {
      this.toolTip = this.field.label;
    }
    if (this.field?.configuration?.showTextLength) {
      this.showTextLength = true;
    }
    this.valueChecking();
    if (this.field?.value && this.field?.isOptional) {
      this.setOptionalValidators();
    }
    let textAreaOption = getUiControlCustomization('TextArea');
    if (textAreaOption) {
      this.foundObject = { event: textAreaOption };
      if (textAreaOption == 'Option 7') {
        this.parentClass = 'textarea-parent-class-styles';
        if (!this.field.value) this.showPlaceholder = true;
      } else {
        let op: number;
        switch (textAreaOption) {
          case 'Option 1':
            op = 1;
            break;
          case 'Option 2':
            op = 2;
            break;
          case 'Option 3':
            op = 4;
            break;
          case 'Option 4':
            op = 6;
            break;
          case 'Option 5':
            op = 8;
            break;
          case 'Option 6':
            op = 9;
            break;
        }
        this.appliedClass = `form-input-var form-input-var-opt${op} form-multi-var-opt${op}`;
      }
    }
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }
  }

  valueChecking() {
    if (this.cuData && this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review') {
      this.cuData?.layers?.[0]?.entityList?.[2]?.nslAttributes?.forEach((attr: any) => {
        let attrDisplayName = JSON.parse(JSON.stringify(attr?.displayName));
        attrDisplayName = attrDisplayName.toLowerCase();
        attrDisplayName = attrDisplayName.replace(' ', '');
        attrDisplayName = attrDisplayName.trim();
        let fieldDisplayName = JSON.parse(JSON.stringify(this.field?.displayName));
        fieldDisplayName = fieldDisplayName.toLowerCase();
        fieldDisplayName = fieldDisplayName.replace(' ', '');
        fieldDisplayName = fieldDisplayName.trim();
        if (attrDisplayName == fieldDisplayName && this.field?.value != attr?.values?.[0]) {
          this.isValue = true;
        }
      });
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange(event: any) {
    if (!this.field.isHidden) {
      if (this.field?.isOptional) {
        this.setOptionalValidators();
      }
      const data = {
        attributeId: this.field.attribute['id'],
        isTableConfig: this.field.attribute['isTableConfig'],
        attrName: this.field.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field.entityName,
        entityId: this.field?.entityId,
        isInfo: this.field?.isInfo,
        slotNumber: this.field?.slotNumber,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }

  onValChange(event: any) {
    if (!event?.target?.value) this.showPlaceholder = true;
    else this.showPlaceholder = false;
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    let text = this.group?.get(String(this.field.attribute.name)).value;
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    if (validation.name == 'notempty' && text.length > 0 && !text.replace(/\s/g, '').length) {
      this.group?.get(String(this.field.attribute.name)).setErrors({ incorrect: true });
    }

    return this.group?.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute?.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    } else {
      if (this.validationList?.includes(validation?.name)) {
        return validateDependentExpression(
          validation,
          this.eventsService,
          this.field,
          this.group,
          this.transactionFacadeService
        );
      }
      return this.group.controls[field?.attribute?.name]?.value?.toString()?.match(validation?.requiredPattern)
        ? false
        : true;
    }
  }

  /**
   * Determines whether input field is focused
   * @param event Contains the input value
   */
  onFocus(event: any) {
    if (!this.field.isHidden) {
      if (!event?.target?.value) this.showPlaceholder = true;
      else this.showPlaceholder = false;
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_FOCUS',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        attribute_type: this.field?.type,
        attribute_displayName: this.field?.attribute?.displayName,
        entityMasterId: this.field?.entityMasterId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
      /* istanbul ignore next */
      if (this.cuData?.isReserved && this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review') {
        this.isSelected = true;
        this.updateDataForPDf(data, this.cuData);
      }
    }
  }

  updateDataForPDf(data: any, cuData: any) {
    /* istanbul ignore next */
    const res = {
      currentCU: cuData,
      selected_atrribute: {
        enity_name: data?.entityName?.split('$')[0],
        attribute_name: data?.attrName?.split('$')[0],
        entity_master_id: data?.entityMasterId,
        attribute_id: data?.attributeId,
        entity_index: data?.entityName?.split('$')[1] - 1,
        value_index: 0,
        attribute_index: 0,
        // attribute_index: data?.attrName?.split('$')[1],
      },
    };
    this.eventsService.updateSelctedAttrLabel({ isSelected: false, attrName: data?.attrName });
    this.eventsService.getPdfTextCord(res);
  }
  detectSttFinalOutput() {
    this.sttService.finalResultObservable.pipe(takeUntil(this.ngUnsubscribe), delay(1000)).subscribe((res: string) => {
      if (res && this.currentFocusedInputId==this.field?.attribute?.id ) {
        this.SpeechToText = res;
        //this.speechInput.nativeElement.value = this.SpeechToText;
        this.group.controls[this.field.attribute.name].setValue(
          res
        );
        this.stopVoiceToText();
      }
    });
  }
  detectSttInterimOutput() {
    this.sttService.interimResultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      if (res && this.currentFocusedInputId==this.field?.attribute?.id) this.SpeechToText = res;
    });
  }
  detectCurrentCu() {
    this.transactionFacadeService.currentCuDetails$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result?.currentCU) {
        this.cuData = res?.result?.currentCU;
      }
    });
  }
  recordVoice() {
    this.currentFocusedInputId=this.field?.attribute?.id;
    if (!this.isRecordingEnabled) {
      this.isRecordingEnabled = true;
      this.startVoiceToText(this.currentFocusedInputId);
    } else {
      this.isRecordingEnabled = false;
      this.stopVoiceToText();
    }
  }
  startVoiceToText(audio?: string) {
    if (audio !== undefined) {
      this.audio = audio;
    }
    this.sttService.start();
  }
  stopVoiceToText() {
    this.sttService.stop();
  }

  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    }
  }
  /* istanbul ignore next */
  detectSelectedLabel() {
    this.eventsService.updateLabelColor$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.isSelected = false;
      /* istanbul ignore next */
      if (
        this.field?.attribute['name'] == res?.attrName &&
        this.cuData?.isReserved &&
        this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review'
      ) {
        this.isSelected = res?.isSelected;
      }
    });
  }
}
