import { EmailDefaultTemplateComponent } from "../login-templates/email-default-template/email-default-template.component";
import { MobileDefaultTemplateComponent } from "../login-templates/mobile-default-template/mobile-default-template.component";
import { TemplateEightComponent } from "../login-templates/template-eight/template-eight.component";
import { TemplateElevenComponent } from "../login-templates/template-eleven/template-eleven.component";
import { TemplateFifteenComponent } from "../login-templates/template-fifteen/template-fifteen.component";
import { TemplateFiveComponent } from "../login-templates/template-five/template-five.component";
import { TemplateFourComponent } from "../login-templates/template-four/template-four.component";
import { TemplateFourteenComponent } from "../login-templates/template-fourteen/template-fourteen.component";
import { TemplateNineComponent } from "../login-templates/template-nine/template-nine.component";
import { TemplateOneComponent } from "../login-templates/template-one/template-one.component";
import { TemplateSevenComponent } from "../login-templates/template-seven/template-seven.component";
import { TemplateSeventeenComponent } from "../login-templates/template-seventeen/template-seventeen.component";
import { TemplateSixComponent } from "../login-templates/template-six/template-six.component";
import { TemplateSixteenComponent } from "../login-templates/template-sixteen/template-sixteen.component";
import { TemplateTenComponent } from "../login-templates/template-ten/template-ten.component";
import { TemplateThirteenComponent } from "../login-templates/template-thirteen/template-thirteen.component";
import { TemplateThreeComponent } from "../login-templates/template-three/template-three.component";
import { TemplateTwelveComponent } from "../login-templates/template-twelve/template-twelve.component";
import { TemplateTwoComponent } from "../login-templates/template-two/template-two.component";
import { SignupTemplateEightComponent } from "../signup-templates/signup-template-eight/signup-template-eight.component";
import { SignupTemplateElevenComponent } from "../signup-templates/signup-template-eleven/signup-template-eleven.component";
import { SignupTemplateFiveComponent } from "../signup-templates/signup-template-five/signup-template-five.component";
import { SignupTemplateFourComponent } from "../signup-templates/signup-template-four/signup-template-four.component";
import { SignupTemplateFourteenComponent } from "../signup-templates/signup-template-fourteen/signup-template-fourteen.component";
import { SignupTemplateNineComponent } from "../signup-templates/signup-template-nine/signup-template-nine.component";
import { SignupTemplateOneComponent } from "../signup-templates/signup-template-one/signup-template-one.component";
import { SignupTemplateSevenComponent } from "../signup-templates/signup-template-seven/signup-template-seven.component";
import { SignupTemplateSeventeenComponent } from "../signup-templates/signup-template-seventeen/signup-template-seventeen.component";
import { SignupTemplateSixComponent } from "../signup-templates/signup-template-six/signup-template-six.component";
import { SignupTemplateSixteenComponent } from "../signup-templates/signup-template-sixteen/signup-template-sixteen.component";
import { SignupTemplateTenComponent } from "../signup-templates/signup-template-ten/signup-template-ten.component";
import { SignupTemplateThirteenComponent } from "../signup-templates/signup-template-thirteen/signup-template-thirteen.component";
import { SignupTemplateThreeComponent } from "../signup-templates/signup-template-three/signup-template-three.component";
import { SignupTemplateTwelveComponent } from "../signup-templates/signup-template-twelve/signup-template-twelve.component";
import { SignupTemplateTwoComponent } from "../signup-templates/signup-template-two/signup-template-two.component";


export const loginMapper = {
  templateOne: TemplateOneComponent,
  templateTwo: TemplateTwoComponent,
  templateThree: TemplateThreeComponent,
  templateFour: TemplateFourComponent,
  templateFive: TemplateFiveComponent,
  templateSix: TemplateSixComponent,
  templateSeven: TemplateSevenComponent,
  templateEight: TemplateEightComponent,
  templateNine: TemplateNineComponent,
  templateTen: TemplateTenComponent,
  templateThirteen: TemplateThirteenComponent,
  templateFourteen: TemplateFourteenComponent,
  templateFifteen: TemplateFifteenComponent,
  templateSixteen: TemplateSixteenComponent,
  templateSeventeen: TemplateThirteenComponent,
  templateEighteen: TemplateFourteenComponent,
  templateNinteen: TemplateFourComponent,
  templateEleven: TemplateElevenComponent,
  templateTwelve: TemplateTwelveComponent,
  templateTwenty: TemplateThreeComponent,
  templateTwentytwo: TemplateFourComponent,
  MobileDefaultTemplate: MobileDefaultTemplateComponent,
  EmailDefaultTemplate: EmailDefaultTemplateComponent,
  templateTwentyThree: TemplateSeventeenComponent,
  templateNinteenWilthNoSignUp : TemplateSixteenComponent
};

export const signupMapper = {
  templateOne: SignupTemplateOneComponent,
  templateTwo: SignupTemplateTwoComponent,
  templateThree: SignupTemplateThreeComponent,
  templateFour: SignupTemplateFourComponent,
  templateFive: SignupTemplateFiveComponent,
  templateSix: SignupTemplateSixComponent,
  templateSeven: SignupTemplateSevenComponent,
  templateEight: SignupTemplateEightComponent,
  templateNine: SignupTemplateNineComponent,
  templateTen: SignupTemplateTenComponent,
  templateEleven: SignupTemplateElevenComponent,
  templateTwelve: SignupTemplateTwelveComponent,
  templateThirteen: SignupTemplateThirteenComponent,
  templateFourteen: SignupTemplateFourteenComponent,
  templateFifteen: SignupTemplateFourteenComponent,
  templateSixteen: SignupTemplateSixteenComponent,
  templateSeventeen: SignupTemplateThirteenComponent,
  templateEighteen: SignupTemplateFourteenComponent,
  templateNinteen: SignupTemplateFourComponent,
  templateTwenty: SignupTemplateThreeComponent,
  templateTwentytwo: SignupTemplateFourComponent,
  templateTwentyThree: SignupTemplateSeventeenComponent,
};
