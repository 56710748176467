import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DateOnlyComponent } from './dateonly.component';
@NgModule({
  declarations: [DateOnlyComponent],
  imports: [ControlsMaterialModule],

  exports: [DateOnlyComponent],
})
export class DateOnlyModule {
  static entry = {
    dateonly: DateOnlyComponent,
  };
}
