<div class="nested-atr-wrap">
  <div class="top-head">
    <div class="save-wrap">
      <div class="save-blk">
        <h5>OCR</h5>
        <mat-icon matSuffix (click)="closeComponent()">close</mat-icon>
      </div>
    </div>
  </div>
</div>
<app-node-ocr-upload
  *ngIf="uploadPanel"
  [ocrData]="ocrData"
  [initComponentData]="initComponentData"
  (emitOpenPDF)="openPDF($event)"
></app-node-ocr-upload>
<div class="d-flex">
  <app-node-ocr-left
    class="left-pane"
    *ngIf="leftPanel"
    (emitCoordinates)="handleEmittedAttributes($event)"
    [ocrData]="ocrData"
  ></app-node-ocr-left>
  <app-node-ocr-right
    class="right-pane"
    *ngIf="rightPanel"
    [ocrData]="ocrData"
    [initComponentData]="initComponentData"
    [currentClickedAttribute]="currentClickedAttribute"
  ></app-node-ocr-right>
</div>
