import { NgModule } from '@angular/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrcodeScannerComponent } from './qrcode-scanner.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [QrcodeScannerComponent],
  imports: [ZXingScannerModule, ControlsMaterialModule],
  exports: [QrcodeScannerComponent],
})
export class QrcodeScannerModule { }
