import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Injector, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, EventsFacadeService, TranslatorService, TransactionFacadeService, EventsEndpointService } from '@common-services';
import { ConfirmationService } from 'primeng/api';
import { DynamicUiTableComponent } from '../dynamic-ui-table/dynamic-ui-table.component';

@Component({
  selector: 'app-vertical-dynamic-ui-table',
  templateUrl: './vertical-dynamic-ui-table.component.html',
  styleUrls: ['./vertical-dynamic-ui-table.component.scss'],
  providers: [DatePipe, ConfirmationService],
})
export class VerticalDynamicUiTableComponent extends DynamicUiTableComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  pageIndex = 1;
  listRundownCols: any[] = [];
  maxCountOfOptions = 1;

  constructor(
    dialog: MatDialog,
    translator: TranslatorService,
    alertService: AlertService,
    confirmationService: ConfirmationService,
    eventService: EventsFacadeService,
    transactionFacade: TransactionFacadeService,
    renderer: Renderer2,
    public eventsEndPoint: EventsEndpointService

  ) {
    super(dialog, translator, alertService, confirmationService, eventService, transactionFacade, renderer,eventsEndPoint);
    this.detectTableScrollEvent();
    this.setTableColourCustomisation();
  }

  ngOnInit(): void {}

  setTableColourCustomisation() {
    document.documentElement.style.setProperty('--odd-row-hover-color', this.cofiguration?.oddRow?.colorHover);
    document.documentElement.style.setProperty('--even-row-hover-color', this.cofiguration?.evenRow?.colorHover);
    document.documentElement.style.setProperty('--even-row-font-color', this.cofiguration?.evenRow?.fontHover);
    document.documentElement.style.setProperty('--odd-row-font-color', this.cofiguration?.oddRow?.fontHover);
  }

  onPageChange(pageData: any) {
    this.pageIndex = pageData;
  }

  ngOnChanges(simple: SimpleChanges) {
    super.ngOnChanges(simple);
    this.maxCountOfOptions = this.getOptionValueCount(this.control);
    this.setTableColourCustomisation();
    if (this.cofiguration?.isListRunDown && this.listValues?.length > 0 && this.cols?.length > 0) {
      // create list of columns for list rundown
      let attrName = this.cols[0]?.header;
      this.listRundownCols = this.listValues[0][attrName]?.attr_options;
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  getOptionValueCount(control: any) {
    let maxOptionsCount: Map<string, any> = new Map<string, any>();
    maxOptionsCount.set(control?.key, 1);
    for (let [key, value] of Object.entries(control?.value?.value)) {
      /* istanbul ignore else */
      if (value?.constructor?.name === 'Array') {
        if (maxOptionsCount.get(control?.key) < value[0]?.attr_options?.length) {
          maxOptionsCount.set(control?.key, value[0]?.attr_options?.length);
        }
      }
    }
    return maxOptionsCount.get(control?.key);
  }

  hidecols(col){
    if(this.tenantName.includes('defi') && (col?.displayValue?.toLowerCase() == "status" || col?.displayValue?.toLowerCase().includes("pending changes")) && JSON.parse(this.role)?.roleName !== "dealer manager"){
      return true;
    }
    return false;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
