<div class="mainDatetime">
  <div *ngIf="type === 'date' || type === 'newdate' || type === 'newdatetime' || type === 'newtime' || type === 'datetime' || type === 'time' || type === 'timer' || type === 'dateonly' || type === 'timeonly'">
    <label>{{ label }}</label> 
    <div class="datetime-picker-wrapper">
      <p-calendar
        class="ui-primeng-calendar"
        [ngClass]="{
          'p-date': type === 'newdate' || type === 'date' || type === 'dateonly',
          'p-datetime': type === 'newdatetime' || type === 'datetime',
          'p-time': type === 'newtime' || type === 'time' || type === 'timer' || type === 'timeonly'
        }"
        [placeholder]="primePlaceholder"
        [(ngModel)]="dateTypeValue"
        (onSelect)="emitValue($event)"
        (onBlur)="onBlur($event)"
        [dateFormat]="primeDateFormatType"
        [showIcon]="true"
        [showTime]="type === 'newdatetime' || type === 'newtime' || type === 'datetime' || type === 'time' || type === 'timer' || type === 'timeonly'"
        [showSeconds]="type === 'newdatetime' || type === 'newtime' || type === 'datetime' || type === 'time' || type === 'timer' || type === 'timeonly'"
        [timeOnly]="type === 'newtime' || type === 'time' || type === 'timer' || type === 'timeonly'"
        [hourFormat]="timeFormatType == '24-hr' ? 24 : 12"
        [yearRange]="yearRange"
        [yearNavigator]="type !== 'newtime' && type !== 'time' && type !== 'timer' && type !== 'timeonly'"
        [monthNavigator]="type !== 'newtime' && type !== 'time' && type !== 'timer' && type !== 'timeonly'"
        [hideOnDateTimeSelect]="type !== 'newdatetime' && type !== 'datetime'"
        [disabledDays]="disabledDays"
        appendTo="body"
        #primecalendar
      >
        <ng-template pTemplate="footer" *ngIf="type === 'newdatetime' || type === 'newtime' || type === 'datetime' || type === 'time' || type === 'timer'">
          <div class="datetime-calendar-footer">
            <button type="button" class="p-calendar-footer-now" (click)="nowClicked(); primecalendar.hideOverlay()">
              {{ labels?.Now }}
            </button>
            <button
              type="button"
              class="p-calendar-footer-done"
              (click)="dateTypeValue ? null : nowClicked(); primecalendar.hideOverlay()"
            ></button>
          </div>
        </ng-template>
      </p-calendar>
      <svg
        *ngIf="type === 'newdatetime' || type === 'datetime'"
        class="datetime-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="Group_104425" data-name="Group 104425" transform="translate(-679 -222)">
          <rect
            id="Rectangle_38766"
            data-name="Rectangle 38766"
            width="24"
            height="24"
            transform="translate(679 222)"
            fill="#333"
            opacity="0"
          />
          <g id="Group_98139" data-name="Group 98139" transform="translate(680.5 222.732)">
            <path
              id="Path_101798"
              data-name="Path 101798"
              d="M23.489,3.777h15.75a3.146,3.146,0,0,1,3.125,3.171v16a3.146,3.146,0,0,1-3.121,3.167H23.489a3.146,3.146,0,0,1-3.125-3.171v-16a3.146,3.146,0,0,1,3.121-3.167Zm0,21.335H39.239a2.147,2.147,0,0,0,2.125-2.163v-16a2.147,2.147,0,0,0-2.129-2.167H23.491A2.147,2.147,0,0,0,21.364,6.94v16a2.147,2.147,0,0,0,2.129,2.167Z"
              transform="translate(-20.864 -2.844)"
              fill="#333"
            />
            <path
              id="Line_5451"
              data-name="Line 5451"
              d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
              transform="translate(15.75)"
              fill="#333"
            />
            <path
              id="Line_5452"
              data-name="Line 5452"
              d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
              transform="translate(5.25)"
              fill="#333"
            />
            <path
              id="Line_5453"
              data-name="Line 5453"
              d="M20.25.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H20.25a.5.5,0,0,1,.5.5A.5.5,0,0,1,20.25.5Z"
              transform="translate(0.375 7.504)"
              fill="#333"
            />
          </g>
          <g id="Group_104258" data-name="Group 104258" transform="translate(-0.059 -1.361)">
            <path
              id="Ellipse_15242"
              data-name="Ellipse 15242"
              d="M4.681-.5A5.181,5.181,0,1,1-.5,4.681,5.187,5.187,0,0,1,4.681-.5Zm0,9.361A4.181,4.181,0,1,0,.5,4.681,4.185,4.185,0,0,0,4.681,8.861Z"
              transform="translate(686.377 234)"
              fill="#333"
            />
            <path
              id="Path_105702"
              data-name="Path 105702"
              d="M31.142,12.9H28.786a.5.5,0,0,1-.5-.5V8.9a.5.5,0,0,1,1,0v3h1.855a.5.5,0,0,1,0,1Z"
              transform="translate(661.942 227.181)"
              fill="#333"
            />
          </g>
        </g>
      </svg>
      <svg
        *ngIf="type === 'newdate' || type === 'date' || type === 'dateonly'"
        class="date-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="Group_104425" data-name="Group 104425" transform="translate(-679 -222)">
          <rect
            id="Rectangle_38766"
            data-name="Rectangle 38766"
            width="24"
            height="24"
            transform="translate(679 222)"
            fill="#333"
            opacity="0"
          />
          <g id="Group_98139" data-name="Group 98139" transform="translate(680.5 222.732)">
            <path
              id="Path_101798"
              data-name="Path 101798"
              d="M23.489,3.777h15.75a3.146,3.146,0,0,1,3.125,3.171v16a3.146,3.146,0,0,1-3.121,3.167H23.489a3.146,3.146,0,0,1-3.125-3.171v-16a3.146,3.146,0,0,1,3.121-3.167Zm0,21.335H39.239a2.147,2.147,0,0,0,2.125-2.163v-16a2.147,2.147,0,0,0-2.129-2.167H23.491A2.147,2.147,0,0,0,21.364,6.94v16a2.147,2.147,0,0,0,2.129,2.167Z"
              transform="translate(-20.864 -2.844)"
              fill="#333"
            />
            <path
              id="Line_5451"
              data-name="Line 5451"
              d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
              transform="translate(15.75)"
              fill="#333"
            />
            <path
              id="Line_5452"
              data-name="Line 5452"
              d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
              transform="translate(5.25)"
              fill="#333"
            />
            <path
              id="Line_5453"
              data-name="Line 5453"
              d="M20.25.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H20.25a.5.5,0,0,1,.5.5A.5.5,0,0,1,20.25.5Z"
              transform="translate(0.375 7.504)"
              fill="#333"
            />
          </g>
        </g>
      </svg>
      <svg
        *ngIf="type === 'newtime' || type === 'time' || type === 'timer' || type === 'timeonly'"
        class="actual-clock-icon"
        id="time"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect id="Rectangle_38765" data-name="Rectangle 38765" width="24" height="24" fill="#fff" opacity="0" />
        <g id="Group_98140" data-name="Group 98140">
          <path
            id="Path_101795"
            data-name="Path 101795"
            d="M14.023,16.946H7.988a.5.5,0,0,1-.5-.5V7.478a.5.5,0,0,1,1,0v8.469h5.535a.5.5,0,1,1,0,1Z"
            transform="translate(4.012 -3.761)"
            fill="#999"
          />
          <path
            id="Ellipse_14847"
            data-name="Ellipse 14847"
            d="M12,1A11,11,0,0,0,4.222,19.778,11,11,0,0,0,19.778,4.222,10.928,10.928,0,0,0,12,1m0-1A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            fill="#999"
          />
        </g>
      </svg>
    </div>
  </div>

  <!-- For Year Month -->
  <div *ngIf="type === 'yearMonth'">
    <mat-form-field appearance="standard">
      <label class="y_month">{{ label }}</label>
      <p>{{ dateTypeValue | date: yearMonthFormatType }}</p>
      <input
        matInput
        name="defaultValueForYearMonth"
        id="defaultValueForYearMonth"
        placeholder="Month and Year"
        [matDatepicker]="picker"
        [(ngModel)]="dateTypeValue"
        style="display: none;"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, picker)"
      >
      </mat-datepicker>
    </mat-form-field>
  </div>

  <!-- For Year Month -->
  <div *ngIf="type === 'year'">
    <mat-form-field appearance="standard">
      <label>{{ label }}</label>
      <p>{{ dateTypeValue }}</p>
      <input
        matInput
        name="defaultValueForYear"
        id="defaultValueForYear"
        placeholder="Select Year"
        [matDatepicker]="picker"
        style="display: none;"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearForYear($event, picker)">
      </mat-datepicker>
    </mat-form-field>
  </div>

  <!-- For DateTime -->
  <!-- <div *ngIf="type === 'datetime'">
    <label>{{ label }}</label>
    <ngx-datetime-picker
      [(selectedDateTime)]="dateTypeValue"
      class="ui-custom-calendar ui-custom-datetime"
      [placeholder]="dateFormatType + ' ' + timeFormatType"
      [disableInput]="true"
      (selectedDateTimeChange)="emitValue($event)"
      [use24HourClock]="timeFormatType === '24-hr'"
    ></ngx-datetime-picker>
  </div> -->

  <!-- For Time -->
  <!-- <div *ngIf="type === 'time'" class="ui-design">
    <ngx-time-picker
      class="ui-custom-calendar ui-custom-time"
      [placeholder]="timeFormatType"
      [(selectedTime)]="dateTypeValue"
      [disableInput]="true"
      (selectedTimeChange)="emitValue($event)"
      [use24HourClock]="timeFormatType === '24-hr'"
    ></ngx-time-picker>
  </div> -->

  <!-- For Date Range -->
  <div *ngIf="type === 'dateRange'">
    <label>{{ label }}</label>
    <p-calendar
      name="defaultValueForDateRange"
      id="defaultValueForDateRange"
      placeholder="Choose two dates"
      [(ngModel)]="dateTypeValue"
      (ngModelChange)="emitValue()"
      [showIcon]="true"
      appendTo="body"
      selectionMode="range"
      [readonlyInput]="true"
      inputId="range"
      [monthNavigator]="true"
      [yearNavigator]="true"
      yearRange="1900:2050"
      dateFormat="dd-mm-yy"
    >
    </p-calendar>
  </div>
</div>
