<section [ngClass]="templateCSS[loginDesign?.templateType]">
    <div class="shelf-inner">
      <div class="logo d-none">
        <figure class="logo-bg">
          <img src="../../../../../assets/images/mcc/logo-bg.png" alt="" />
        </figure>
        <figure class="logo-img">
          <img src="../../../../../assets/images/mcc/logo.png" alt="" />
        </figure>
      </div>
      <div class="container">
        <div class="left">
          <article class="box">
            <figure class="box-img">
              <img
                [src]="loginDesign?.asset?.desktop"
                alt=""
                onerror="'../../../../../assets/images/mcc/login-img.png'"
              />
            </figure>
            <h3 class="box-title">
              <span>{{ loginDesign?.caption?.heading }}</span>
            </h3>
            <p class="box-para">
              <span>{{ loginDesign?.caption?.text }}</span>
            </p>
          </article>
        </div>
        <div class="right">
          <div class="login-card">
            <h2 class="title">
              <span [ngStyle]="{ color: loginDesign?.headingText?.fontColor }">{{ headingText }}</span>
            </h2>
            <div class="form-section">
              <div class="signup-form">
                <mat-tab-group class="signup-tab-list">
                  <mat-tab
                    *ngIf="signupOptions == 'SignupViaEmail' || signupOptions == 'SignupViaEmailAndMobile' || onboarding"
                    label="Signup using Email"
                  >
                    <form #signupForm="ngForm" name="form" class="pt-3">
                      <div class="form-group">
                        <div class="ui-custom-select">
                          <div *ngIf="showPublicRoles">
                            <mat-form-field appearance="fill" floatLabel="never">
                              <mat-select
                                panelClass="ui-custom-select-options"
                                matNativeControl
                                [(ngModel)]="selectedRole"
                                name="selectedRole"
                                id="selectedRole"
                                [required]="isRoleMandatory && showPublicRoles"
                              >
                                <mat-option *ngFor="let role of rolesList" [value]="role">
                                  {{ role?.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                      <div class="form-group" *ngIf="customLabel && customIdGeneration != 'Auto'">
                        <mat-form-field>
                          <input
                            matInput
                            id="customLabel"
                            name="customLabel"
                            type="text"
                            placeholder="{{ customLabel }}"
                            [(ngModel)]="signupPayload.customID"
                            [required]="customLabel"
                            [readonly]="referralCode"
                          />
                        </mat-form-field>
                      </div>

                      <div class="form-row m-0">
                        <div class="form-row m-0 cstm-attributes">
                          <div class="form-group col-md-6 pl-0"
                          *ngFor="let attribute of attributeList1; let i = index">
                          <mat-form-field
                          appearance="standard"
                          class="custom-input-mat custom-input-icon">
                          <input
                            id="CustomAttributes{{ i }}"
                            name="customAttributes{{ i }}"
                            placeholder="{{ attribute }}"
                            [(ngModel)]="attributeArray[i]"
                            matInput
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </mat-form-field>
                          </div>
                        </div>
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="firstName"
                              type="text"
                              placeholder="First Name"
                              [(ngModel)]="signupPayload.firstName"
                              name="firstName"
                              required
                            />
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="lastName"
                              type="text"
                              placeholder="Last Name"
                              [(ngModel)]="signupPayload.lastName"
                              name="lastName"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-12 pl-0 pr-0">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="userName"
                              type="text"
                              placeholder="Email"
                              [(ngModel)]="signupPayload.email"
                              name="email"
                              (keyup)="ValidateEmail()"
                              required
                              placeholder="Enter Your Email ID"
                            />

                            <span class="form-icon login-email-icon"></span>
                          </mat-form-field>
                          <small
                            class="text-danger"
                            [ngClass]="{ 'myclass-2': emailValidator == false }"
                            *ngIf="!emailValidator"
                          >
                            Enter a Valid Email
                          </small>

                          <div></div>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <div class="ui-custom-select">
                            <mat-form-field appearance="fill" floatLabel="never">
                              <mat-label>{{ labels?.Select_Country }}</mat-label>
                              <mat-select
                                panelClass="ui-custom-select-options"
                                [(ngModel)]="_countryName"
                                (selectionChange)="ValidateMobileNumberMobile($event)"
                                [ngModelOptions]="{ standalone: true }"
                                [disabled]="defaultCountryCodeEditable"
                              >
                              <mat-option *ngFor="let country of countryCodes" [value]="country[1].countryName"
                              >{{ country[1].countryName }} : {{ country[1].countryCode }}</mat-option
                            >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="mobile"
                              type="text"
                              placeholder="Contact Number"
                              onkeydown="return((event.keyCode >=48 && event.keyCode <=57)||event.keyCode ==8)"
                              [(ngModel)]="signupPayload.mobileNumber"
                              (keyup)="ValidateMobileNumberEmail()"
                              (keydown.space)="$event.preventDefault()"
                              name="mobileNumber"
                              [required]="faEnabled || mobileNumberMandatoryWhileEmailSignUp"
                            />
                            <span class="form-icon phone-icon"></span>
                          </mat-form-field>
                          <small
                            [ngClass]="{ 'myclass-2': mobileNumberValidatorEmail == false }"
                            *ngIf="!mobileNumberValidatorEmail"
                          >
                          Mobile Number Must be {{ mobileNumberData?.[1]?.mobileNumberLength }} digits
                          </small>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="password"
                              type="password"
                              placeholder="Password"
                              [(ngModel)]="signupPayload.password"
                              required
                              name="password"
                              [type]="showPassword ? 'text' : 'password'"
                            />
                            <div class="toggle-eye-icon">
                              <img
                                src="../../../../../assets/images/mcc-login-screen/Hide Password.svg"
                                alt=""
                                (click)="toggleShowPassword()"
                                *ngIf="!showPassword"
                              />
                              <img
                                src="../../../../../assets/images/mcc-login-screen/Show Icon.svg"
                                alt=""
                                (click)="toggleShowPassword()"
                                *ngIf="showPassword"
                              />
                            </div>
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0 passwordpolicy">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="confirmPassword"
                              type="password"
                              placeholder="Confirm Password"
                              [(ngModel)]="confirmPassword"
                              (keyup)="ValidateConfirmPassword()"
                              required
                              name="confirmPassword"
                            />
                            <div class="cstm-tooltip" href="">
                              <span class="info-icon">
                                <mat-icon matSuffix>info</mat-icon>
                              </span>
                              <div class="pasword-policy">
                                <span class="policyinfo" *ngIf="password_info">
                                  Password Policy(minimum requirements):<br />
                                  Digits: {{ password_info?.digits }}<br />
                                  forceExpiredPasswordChange:{{ password_info?.forceExpiredPasswordChange }}days<br />
                                  Length: {{ password_info?.length }}<br />
                                  notUsername: {{ password_info?.notUsername }}<br />
                                  specialChars: {{ password_info?.specialChars }}<br />
                                  upperCase: {{ password_info?.upperCase }}<br />
                                </span>
                                <span class="policyinfo" *ngIf="!password_info">
                                  Password Policy(No minimum requirements):<br />
                                </span>
                              </div>
                            </div>
                          </mat-form-field>
                          <div>
                            <small
                              class="text-danger"
                              [ngClass]="{ 'myclass-2': passwordCheck == false }"
                              *ngIf="!passwordCheck"
                            >
                              Password not matched
                            </small>
                            <small
                              class="text-danger"
                              [ngClass]="{ 'myclass-1': passwordCheck == true }"
                              *ngIf="passwordCheck && confirmPassword.length"
                            >
                              Password matched
                            </small>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="displayTermsField">
                        <input type="checkbox" name="checkbox" [(ngModel)]="termsAgreed" />
                        <span (click)="openDialog(termsAndConditions)"
                          >I Agree to all the <a class="terms">{{ labels?.terms_and_conditions }}</a>
                        </span>
                      </div>

                      <div class="login-ctac">
                        <button
                          type="submit"
                          class="login-btn"
                          id="signup"
                          (click)="signup()"
                          [disabled]="!signupForm.valid || !termsAgreed"
                        >
                          Signup
                        </button>
                        <!-- <p class="text">Or</p>
                        <div>
                          <p class="blue-text">Sign In via Mobile</p>
                        </div> -->
                      </div>
                    </form>
                  </mat-tab>
                  <mat-tab
                    *ngIf="
                      !onboarding &&
                      (signupOptions == 'SignupViaMobile' || signupOptions == 'SignupViaEmailAndMobile') &&
                      !faEnabled
                    "
                    label="Signup using Mobile"
                  >
                    <div class="form" action="">
                      <div class="form-group">
                        <div class="ui-custom-select">
                          <div *ngIf="showPublicRoles">
                            <mat-form-field appearance="fill" floatLabel="never">
                              <mat-select
                                panelClass="ui-custom-select-options"
                                matNativeControl
                                [(ngModel)]="selectedRole"
                                name="selectedRole"
                                id="selectedRole"
                                [required]="isRoleMandatory && showPublicRoles"
                              >
                                <mat-option *ngFor="let role of rolesList" [value]="role">
                                  {{ role?.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-12 pl-0 pr-0">
                          <div class="ui-custom-select">
                            <mat-form-field appearance="fill" floatLabel="never">
                              <mat-label>{{ labels?.Select_Country }}</mat-label>
                              <mat-select
                                panelClass="ui-custom-select-options"
                                [(ngModel)]="_countryName"
                                [ngModelOptions]="{ standalone: true }"
                                (selectionChange)="ValidateMobileNumberMobile($event)"
                                [disabled]="defaultCountryCodeEditable"
                              >
                              <mat-option *ngFor="let country of countryCodes" [value]="country[1].countryName"
                              >{{ country[1].countryName }} : {{ country[1].countryCode }}</mat-option
                            >
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="form-group col-md-12 pr-0 pl-0">
                          <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                            <input
                              matInput
                              id="mobile"
                              type="text"
                              placeholder="Contact Number"
                              onkeydown="return((event.keyCode >=48 && event.keyCode <=57)||event.keyCode ==8)"
                              [(ngModel)]="signupPayload.mobileNumber"
                              (keyup)="ValidateMobileNumberEmail()"
                              (keydown.space)="$event.preventDefault()"
                              name="mobileNumber"
                              [required]="faEnabled || mobileNumberMandatoryWhileEmailSignUp"
                            />
                            <span class="form-icon phone-icon"></span>
                          </mat-form-field>
                          <small
                            [ngClass]="{ 'myclass-2': mobileNumberValidatorEmail == false }"
                            *ngIf="!mobileNumberValidatorEmail"
                          >
                          Mobile Number Must be {{ mobileNumberData?.[1]?.mobileNumberLength }} digits
                          </small>
                        </div>
                      </div>
                      <div *ngIf="displayTermsField">
                        <input type="checkbox" name="checkbox" [(ngModel)]="termsAgreed" />
                        <span (click)="openDialog(termsAndConditions)"
                          >I Agree to all the <a class="terms">{{ labels?.terms_and_conditions }}</a>
                        </span>
                      </div>

                      <div class="submit-button-wrapper">
                        <button
                          class="btn-custom"
                          id="sendOtpButton"
                          (click)="sendOtp()"
                          [disabled]="(timeLeft != 0 && activateOtp) || !termsAgreed"
                        >
                          {{ activateOtp ? 'Resend OTP' : 'Send OTP' }}
                        </button>
                        <p *ngIf="timeLeft != 0 && activateOtp">Re-send OTP in {{ timeLeft }} seconds</p>
                        <!-- <p class="text">Or</p>
                        <div>
                          <p class="blue-text">Sign In via Email</p>
                        </div> -->
                      </div>

                      <div class="form-group" *ngIf="activateOtp">
                        <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                          <input
                            matInput
                            id="otp"
                            type="text"
                            placeholder="OTP"
                            [(ngModel)]="signupOtp"
                            (keypress)="acceptInteger($event)"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </mat-form-field>
                      </div>

                      <div class="submit-button-wrapper" *ngIf="activateOtp">
                        <button class="btn-custom" id="verifyOtp" [disabled]="!termsAgreed" (click)="verifyOtp()">
                          Verify and Signup
                        </button>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <div class="signup-others">
                  <!-- <a (click)="forgotPassword()" href="" class="forgot-pwd">Forgot password?</a> -->
                  <!-- <p>
                    or signup via
                    <span>
                      <img [src]="'../../../assets/img/onboard/google-icon.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                    <span>
                      <img [src]="'../../../assets/img/onboard/twitter-cion.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                    <span>
                      <img [src]="'../../../assets/img/onboard/fb-icon.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                  </p> -->
                  <h4>Already have an account? <a (click)="signin()">Login</a></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ng-template #termsAndConditions let-data>
    <iframe [src]="data | safeResourceUrl" class="iFrame"></iframe>
    <div class="terms-dialog">
      <button class="agree" (click)="closeDialog('Agreed')">Agree</button>
      <button class="cancl" (click)="closeDialog('cancel')">Cancel</button>
    </div>
  </ng-template>
