import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonOntologyComponent } from './common-ontology.component';



import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { MaterialModule } from '../materail/material.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AlertService } from '../toaster/alert.service';

@NgModule({
  declarations: [CommonOntologyComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, ApplicationPipesModule,ToastrModule.forRoot(),],
  exports: [CommonOntologyComponent],
  providers:[
    AlertService,
    ToastrService,
  ]
})
export class CommonOntologyModule {}
