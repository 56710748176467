import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NslDicomOperationComponent } from './nsl-dicom-operation/nsl-dicom-operation.component';



@NgModule({
  declarations: [
    NslDicomOperationComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [NslDicomOperationComponent],
})
export class NslDicomOperationModule { }
