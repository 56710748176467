export const basicQuestions = {
  coder: {
    selectYourInterest: 'Select your fields of interest.',
    selectPlatforms: 'Which Platforms have you used before?',
    selectTools: 'Which Tools have you used before?',
    selectYourSolution: 'What solutions are you looking to build?',
  },
  student: {
    selectYourInterest: 'What are your interest areas?',
    selectYourWish: 'What would you wish to become?',
    selectIssues: 'What are the Problems you wish to solve in the world',
    selectYourSolution: 'What solutions are you looking to build?',
  },
  generalCitizen: {
    selectYourInterest: 'Passionate about something? Tell us.',
    selectSolutions: 'Looking for solutions? Choose One.',
    selectYourSolution: 'What solutions are you looking to build?',
  },
};

export const basicQnsList = {
  coder: {
    interestAreas: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/gaming.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/gaming-hover.svg',
        label: 'Gaming',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/stockmarket.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/stockmarket-hover.svg',
        label: 'Stock Market',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/crypto.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/crypto-hover.svg',
        label: 'Crypto Currency',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/finance.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/finance-hover.svg',
        label: 'Finance',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/sports.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/sports-hover.svg',
        label: 'Sports',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/security.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/security-hover.svg',
        label: 'Security',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/graphics.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/graphics-hover.svg',
        label: 'Graphics/ visualization',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/education.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/education-hover.svg',
        label: 'Education',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/developer/interest/ai.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/developer/interest/ai-hover.svg',
        label: 'AI/ ML',
        isActive: false,
      },
    ],
    platforms: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/outsystem.svg',
        label: 'Outsystems',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/kissflow.svg',
        label: 'Kissflow',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/appian.svg',
        label: 'Appian',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/zoho.svg',
        label: 'Zoho Creator',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/google.svg',
        label: 'Google App Builder',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/topcoder.svg',
        label: 'Topcoder',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/coderbyte.svg',
        label: 'Coderbyte',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/wordpress.svg',
        label: 'Wordpress',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/developer/platform/others.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    tools: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/azure.svg',
        label: 'Azure',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/embold.svg',
        label: 'Embold',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/github.svg',
        label: 'Linx',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/nodejs.svg',
        label: 'Atom',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/github.svg',
        label: 'GitHub',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/nodejs.svg',
        label: 'Node.js',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/codeenvy.svg',
        label: 'Codenvy',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/angular.svg',
        label: 'AngularJS',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/developer/tools/others.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    interestedSolutions: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/balloons/social-app.svg',
        name: 'socialapp',
        label: 'Social App',
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/balloons/elearning.svg',
        name: 'elearning',
        label: 'ELearning',
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/balloons/ecommerce.svg',
        name: 'ecommerce',
        label: 'Ecommerce',
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/balloons/community-welfare.svg',
        name: 'communitywelfare',
        label: 'Community Welfare',
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/balloons/budget-mangement.svg',
        name: 'budgetmangement',
        label: 'Budget Mangement',
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/balloons/quiz-app.svg',
        name: 'quizapp',
        label: 'Quiz App',
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/balloons/health-care.svg',
        name: 'healthcare',
        label: 'Health Care',
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/balloons/delivery-service.svg',
        name: 'deliveryservice',
        label: 'Delivery Service',
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/balloons/others.svg',
        name: 'others',
        label: 'Others',
      },
    ],
  },

  student: {
    interestAreas: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/science.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/science-hover.svg',
        label: 'Science',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/space.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/space-hover.svg',
        label: 'Space',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/ev.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/ev-hover.svg',
        label: 'EV',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/robotics.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/robotics-hover.svg',
        label: 'Robotics',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/healthcare.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/healthcare-hover.svg',
        label: 'Healthcare',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/cars.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/cars-hover.svg',
        label: 'Cars',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/sports.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/sports-hover.svg',
        label: 'Sports',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/music.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/music-hover.svg',
        label: 'Music',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/theater.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/theater-hover.svg',
        label: 'Theatre',
        isActive: false,
      },
      {
        questionId: 10,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/art.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/art-hover.svg',
        label: 'Art',
        isActive: false,
      },
      {
        questionId: 11,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/food.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/food-hover.svg',
        label: 'Food',
        isActive: false,
      },
      {
        questionId: 12,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/others.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/others-hover.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    wish: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/scientist.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/scientist-hover.svg',
        label: 'Scientist',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/journalist.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/journalist-hover.svg',
        label: 'Journalist',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/designer.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/designer-hover.svg',
        label: 'Designer',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/pilot.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/pilot-hover.svg',
        label: 'Pilot',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/doctor.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/doctor-hover.svg',
        label: 'Doctor',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/astronaut.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/astronaut-hover.svg',
        label: 'Astronaut',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/architect.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/architect-hover.svg',
        label: 'Architect',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/engineer.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/engineer-hover.svg',
        label: 'Engineer',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/lawyer.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/lawyer-hover.svg',
        label: 'Lawyer',
        isActive: false,
      },
      {
        questionId: 10,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/musician.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/musician-hover.svg',
        label: 'Musician',
        isActive: false,
      },
      {
        questionId: 11,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/chef.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/chef-hover.svg',
        label: 'Chef',
        isActive: false,
      },
      {
        questionId: 12,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/others.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/others.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    issues: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/sanitation.svg',
        name: 'sanitation',
        label: 'Sanitation',
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/litaracy.svg',
        name: 'litaracy',
        label: 'Literacy',
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/ewebsite.svg',
        name: 'ewebsite',
        label: 'E-Waste',
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/water.svg',
        name: 'water',
        label: 'Water',
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/deforest.svg',
        name: 'cybersec',
        label: 'Cybersecurity',
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/poverty.svg',
        name: 'poverty',
        label: 'Poverty',
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/pollution.svg',
        name: 'pollution',
        label: 'Pollution',
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/hunger.svg',
        name: 'hunger',
        label: 'Hunger',
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/education.svg',
        name: 'education',
        label: 'Education',
      },
    ],
    interestedSolutions: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/balloons/social-app.svg',
        name: 'socialapp',
        label: 'Social App',
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/balloons/elearning.svg',
        name: 'elearning',
        label: 'ELearning',
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/balloons/ecommerce.svg',
        name: 'ecommerce',
        label: 'Ecommerce',
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/balloons/community-welfare.svg',
        name: 'communitywelfare',
        label: 'Community Welfare',
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/balloons/budget-mangement.svg',
        name: 'budgetmangement',
        label: 'Budget Mangement',
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/balloons/quiz-app.svg',
        name: 'quizapp',
        label: 'Quiz App',
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/balloons/health-care.svg',
        name: 'healthcare',
        label: 'Health Care',
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/balloons/delivery-service.svg',
        name: 'deliveryservice',
        label: 'Delivery Service',
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/balloons/others.svg',
        name: 'others',
        label: 'Others',
      },
    ],
  },

  generalCitizen: {
    profiles: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/teacher.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/teacher-hover.svg',
        label: 'Teacher',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/designer.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/designer-hover.svg',
        label: 'Designer',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/executive.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/executive-hover.svg',
        label: 'Executive',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/consultant.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/consultant-hover.svg',
        label: 'Consultant',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/accountant.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/accountant-hover.svg',
        label: 'Accountant',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/coder.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/coder-hover.svg',
        label: 'Coder',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/doctor.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/doctor-hover.svg',
        label: 'Doctor',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/supermom.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/supermom-hover.svg',
        label: 'Supermom',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/architect.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/architect-hover.svg',
        label: 'Architect',
        isActive: false,
      },
      {
        questionId: 10,
        imageUrl: '../../assets/images/carnival/carnival/student/wish/engineer.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/wish/engineer-hover.svg',
        label: 'Engineer',
        isActive: false,
      },
      {
        questionId: 11,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/busines.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/busines-hover.svg',
        label: 'Business person',
        isActive: false,
      },
      // {
      //   questionId: 12,
      //   imageUrl: '../../assets/images/carnival/engineer.svg',
      //   label: 'Engineer',
      //   isActive: false,
      // },
      // {
      //   questionId: 13,
      //   imageUrl: '../../assets/images/carnival/doctor.svg',
      //   label: 'Dentist',
      //   isActive: false,
      // },
      // {
      //   questionId: 14,
      //   imageUrl: '../../assets/images/carnival/lawyer.svg',
      //   label: 'Lawyer',
      //   isActive: false,
      // },
      // {
      //   questionId: 15,
      //   imageUrl: '../../assets/images/carnival/architect.svg',
      //   label: 'Coder',
      //   isActive: false,
      // },
      {
        questionId: 16,
        imageUrl: '../../assets/images/carnival/carnival/general/engage/others.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/engage/others-hover.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    interestAreas: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/science.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/science-hover.svg',
        label: 'Science',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/space.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/space-hover.svg',
        label: 'Space',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/ev.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/ev-hover.svg',
        label: 'Technology',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/general/interest/healthcare.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/interest/healthcare-hover.svg',
        label: 'Healthcare',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/general/interest/design.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/interest/design-hover.svg',
        label: 'Design',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/general/interest/realestate.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/interest/realestate-hover.svg',
        label: 'Real Estate',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/sports.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/sports-hover.svg',
        label: 'Sports',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/music.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/music-hover.svg',
        label: 'Music',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/theater.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/theater-hover.svg',
        label: 'Blogging',
        isActive: false,
      },
      {
        questionId: 10,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/art.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/art-hover.svg',
        label: 'Art',
        isActive: false,
      },
      {
        questionId: 11,
        imageUrl: '../../assets/images/carnival/carnival/student/interest/food.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/student/interest/food-hover.svg',
        label: 'Food',
        isActive: false,
      },
      {
        questionId: 12,
        imageUrl: '../../assets/images/carnival/carnival/general/interest/others.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/interest/others-hover.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    solutions: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/budget.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/budget-hover.svg',
        label: 'Budget Management',
        isActive: false,
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/grocery-hover.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/grocery-hover.svg',
        label: 'Grocery Marketplace',
        isActive: false,
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/financial.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/financial-hover.svg',
        label: 'Financial Advice',
        isActive: false,
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/reselling.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/reselling-hover.svg',
        label: 'Reselling Marketplace',
        isActive: false,
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/furnishing.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/furnishing-hover.svg',
        label: 'Home and Furnishing',
        isActive: false,
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/community.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/community-hover.svg',
        label: 'Community welfare',
        isActive: false,
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/tution.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/tution-hover.svg',
        label: 'Tuition and Coaching',
        isActive: false,
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/carrer.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/carrer-hover.svg',
        label: 'Career Advice',
        isActive: false,
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/books.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/books-hover.svg',
        label: 'Books and Libraries',
        isActive: false,
      },
      {
        questionId: 10,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/restaurent.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/restaurent-hover.svg',
        label: 'Restaurants and Hotels',
        isActive: false,
      },
      {
        questionId: 11,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/healthcare.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/healthcare-hover.svg',
        label: 'Healthcare',
        isActive: false,
      },
      {
        questionId: 12,
        imageUrl: '../../assets/images/carnival/carnival/general/solutions/others.svg',
        imagehoverUrl: '../../assets/images/carnival/carnival/general/solutions/others-hover.svg',
        label: 'Others',
        isActive: false,
      },
    ],
    interestedSolutions: [
      {
        questionId: 1,
        imageUrl: '../../assets/images/carnival/balloons/social-app.svg',
        name: 'socialapp',
        label: 'Social App',
      },
      {
        questionId: 2,
        imageUrl: '../../assets/images/carnival/balloons/elearning.svg',
        name: 'elearning',
        label: 'ELearning',
      },
      {
        questionId: 3,
        imageUrl: '../../assets/images/carnival/balloons/ecommerce.svg',
        name: 'ecommerce',
        label: 'Ecommerce',
      },
      {
        questionId: 4,
        imageUrl: '../../assets/images/carnival/balloons/community-welfare.svg',
        name: 'communitywelfare',
        label: 'Community Welfare',
      },
      {
        questionId: 5,
        imageUrl: '../../assets/images/carnival/balloons/budget-mangement.svg',
        name: 'budgetmangement',
        label: 'Budget Mangement',
      },
      {
        questionId: 6,
        imageUrl: '../../assets/images/carnival/balloons/quiz-app.svg',
        name: 'quizapp',
        label: 'Quiz App',
      },
      {
        questionId: 7,
        imageUrl: '../../assets/images/carnival/balloons/health-care.svg',
        name: 'healthcare',
        label: 'Health Care',
      },
      {
        questionId: 8,
        imageUrl: '../../assets/images/carnival/balloons/delivery-service.svg',
        name: 'deliveryservice',
        label: 'Delivery Service',
      },
      {
        questionId: 9,
        imageUrl: '../../assets/images/carnival/balloons/others.svg',
        name: 'others',
        label: 'Others',
      },
    ],
  },
};
