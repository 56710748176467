import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { displayUSAMobileFormat, AlertService, EventsFacadeService, FieldConfig, TransactionFacadeService, dependentAttributeValue, getAttributeValue, setAttributeValue, getUiControlCustomization, returnMobileNumber, SttEndpointService, displayMexicoMobileFormat, validateDependentExpression, AttributeStoreService, StoreState} from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { delay, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-input',
  styleUrls: ['./input.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <div [formGroup]="group" class="form-input-field">
      <!-- custom-input-mat + custom-input-mat-legacy-->
      <mat-form-field
        appearance="standard"
        class="{{ appliedClass }}"
        [ngClass]="{
          'custom-input-icon':
            (field.type == 'mobilenumber' && !foundMobileObject?.event) ||
            field.type == 'password' ||
            field.type == 'text'
        }"
      >
        <div class="d-flex justify-content-between nh-column-gap-10 label-flex-row" *ngIf="!field?.hideLabels">
          <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
            <label
              id="{{ field?.attribute?.name }}"
              pTooltip="{{ field?.label }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              class="main-label nh-mb-0"
              [style.color]="field.color"
              [style.font-size.px]="field.fontSize"
              *ngIf="
                (field?.sentence == null || field?.sentence === '') &&
                !field?.hideLabels &&
                !field?.configuration?.hideLabel &&
                !field?.hideLabelMultiAttr
              "
              ><span class="main-label-inner"
                ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
                <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
              ></label
            >
            <p
              class="nh-ml-6 d-flex main-label-helperIcon"
              *ngIf="field?.configuration?.showHelperTextIcon"
              [ngStyle]="{
                color: field?.configuration?.helperTextColor,
                'font-size': field?.configuration?.helperTextFont
              }"
            >
              <mat-icon
                class="material-icons-outlined helperText-infoIcon"
                [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
                matTooltip="{{ field?.configuration?.helpertext }}"
                matTooltipPosition="above"
                *ngIf="!field?.hideLabels"
                >info_outline</mat-icon
              >
            </p>
          </div>
          <div
            class="d-flex align-items-center nh-column-gap-5 pb-2 multivalue-btns"
            *ngIf="
              field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
              (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
            "
          >
            <mat-icon
              matTooltip="Sub-Transactional CU"
              matSuffix
              class="d-flex align-items-center justify-content-center"
              *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            >
              <span class="sub-cu" (click)="openSubPopUp()">
                <img class="d-block" src="../assets/img/transaction/trigger-att.svg" />
              </span>
            </mat-icon>
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              (click)="addAttribute.next()"
              >add</mat-icon
            >
            <mat-icon
            *ngIf="
            field.isMulti &&
            (field?.isOptional || field?.attrInstanceId != 0) &&
            !field.configuration?.hideMultiValueIcon &&
            !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              (click)="removeAttribute.next()"
              >remove</mat-icon
            >
          </div>
        </div>
        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <div
          class="d-flex position-relative mobilenumber-wrapper label-flex-row-input"
          [ngClass]="{ 'mobile-number': field.inputType == 'mobilenumber' }"
        >
          <span *ngIf="field.inputType == 'mobilenumber'" class="mobile-format">
            {{ field.configuration?.mobileFormat ? field.configuration?.mobileFormat?.actualValue : '+91' }}
          </span>
          <!-- this label-flex-row-placeholder only for multi style 10 -->
          <span
            *ngIf="field.inputType == 'text' && foundTextObject?.event == 'Option 9' && showPlaceholder"
            class="label-flex-row-placeholder d-none"
            >Input your text</span
          >
          <input
            *ngIf="field.type != 'number' && field.type != 'email' && field.type != 'textavatar' && !isInMemoryRcuSearch"
            matInput
            appPhoneMask
            [mobileNumberFormat]="field.configuration?.mobileFormat?.displayValue"
            [value]="field.value"
            [matTooltip]="field?.configuration?.hideTooltip ? '' : field.value"
            [matTooltipPosition]="'above'"
            [formControlName]="field?.attribute?.name"
            (keypress)="onlyNumberKey($event, field.inputType)"
            (keyup)="onlyNumberKey($event, field.inputType); updateMobileNumber($event, field.inputType)"
            [type]="field.type"
            [readonly]="field.readonly"
            [required]="field.isRequired && !field?.isInfo"
            [autocomplete]="autoOff"
            (change)="onChange($event); onlyNumberKey($event, field.inputType)"
            (paste)="onlyNumberKey($event, field.inputType)"
            (drop)="onlyNumberKey($event, field.inputType)"
            (focus)="onFocus($event)"
            id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
            [ngClass]="{
              activeclick: isSelected,
              encryptTextStyle: isEncryptedText,
              valueCheck: isValue,
              inValidClass:
                !field?.isInfo &&
                (group?.controls)[field?.attribute?.name]?.invalid &&
                (group?.controls)[field?.attribute?.name]?.touched
            }"
          />

          <mat-icon
            *ngIf="field.type === 'text' && field.configuration?.configureMic"
            class="form-icon mic-icon"
            [ngClass]="{ recording: isRecording }"
            (click)="recordVoice()"
          >
            mic
          </mat-icon>
          <div
            *ngIf="field.inputType == 'Password' && group?.get(this.field?.attribute?.name)?.value"
            (mousedown)="onClickPasswordIcon()"
            (mouseup)="onClickPasswordIcon()"
            (mouseleave)="hidePassWord()"
          >
            <mat-icon matSuffix class="passwordIcon">{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </div>
          <em
            class="nh-icon nh-icon-mobile actual-mobile-icon"
            *ngIf="field.inputType == 'mobilenumber' && !foundMobileObject?.event"
          ></em>
          <div
            *ngIf="
              field?.inputType == 'mobilenumber' &&
              foundMobileObject?.type === 'PhoneNumber' &&
              foundMobileObject?.event
            "
            class="icon"
          >
            <i class="bi bi-phone"></i>
          </div>
        </div>
        <div
          [ngClass]="
            (!foundNumberObject?.event || foundNumberObject?.event == '') && field?.configuration?.enablenumberIncOrDec
              ? 'number-input-plus-minus'
              : (!foundNumberObject?.event || foundNumberObject?.event == '') &&
                !field?.configuration?.enablenumberIncOrDec
              ? 'number-input'
              : ''
          "
          class="{{ appliedDivNumberClass }}"
          *ngIf="field?.type == 'number'"
        >
          <button
            type="button"
            *ngIf="
              field?.configuration?.enablenumberIncOrDec &&
              (!foundNumberObject?.event || foundNumberObject?.event == '')
            "
            [disabled]="field?.readonly"
            (click)="triggerOnchange($event, 'minus')"
            class="minus"
            [ngClass]="{ activeclick: isSelected }"
          ></button>
          <button
            type="button"
            *ngIf="
              field?.configuration?.enablenumberIncOrDec && foundNumberObject?.event && foundNumberObject?.event !== ''
            "
            [disabled]="field?.readonly"
            (click)="triggerOnchange($event, 'minus')"
            class="minus"
          ></button>
          <input
            matInput
            class="{{ appliedNumberInputClass }}"
            name="quantity"
            [value]="field.value"
            [formControlName]="field?.attribute?.name"
            (keypress)="onlyNumberKey($event, field.inputType)"
            (keyup)="onlyNumberKey($event, field.inputType)"
            [readonly]="field.readonly"
            [required]="field.isRequired && !field?.isInfo"
            [autocomplete]="autoOff"
            (change)="onChange($event); onlyNumberKey($event, field.inputType)"
            (drop)="onlyNumberKey($event, field.inputType)"
            (focus)="onFocus($event)"
            id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
            type="number"
            [ngClass]="{
              activeclick: isSelected,
              valueCheck: isValue,
              inValidClass:
                !field?.isInfo &&
                (group?.controls)[field?.attribute?.name]?.invalid &&
                (group?.controls)[field?.attribute?.name]?.touched
            }"
            (paste)="onPaste($event)"
            (wheel)="$event.preventDefault()"
          />
          <button
            type="button"
            *ngIf="
              field?.configuration?.enablenumberIncOrDec &&
              (!foundNumberObject?.event || foundNumberObject?.event == '')
            "
            [disabled]="field?.readonly"
            (click)="triggerOnchange($event, 'plus')"
            class="plus"
            [ngClass]="{ activeclick: isSelected }"
          ></button>
          <button
            type="button"
            *ngIf="
              field?.configuration?.enablenumberIncOrDec && foundNumberObject?.event && foundNumberObject?.event !== ''
            "
            [disabled]="field?.readonly"
            (click)="triggerOnchange($event, 'plus')"
            class="plus"
          ></button>
        </div>
        <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon" *ngIf="field.type == 'email'">
          <div class="email-wrapper">
            <input
              matInput
              [value]="field.value"
              [formControlName]="field?.attribute?.name"
              (keypress)="onlyNumberKey($event, field.inputType)"
              [matTooltip]="field?.configuration?.hideTooltip ? '' : field.value"
              [matTooltipPosition]="'above'"
              (keyup)="onlyNumberKey($event, field.inputType)"
              [type]="field.type"
              [readonly]="field.readonly"
              [required]="field.isRequired && !field?.isInfo"
              [autocomplete]="autoOff"
              (change)="onChange($event); onlyNumberKey($event, field.inputType)"
              (paste)="onlyNumberKey($event, field.inputType)"
              (drop)="onlyNumberKey($event, field.inputType)"
              (focus)="onFocus($event)"
              id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
              [ngClass]="{
                inValidClass:
                  !field?.isInfo &&
                  (group?.controls)[field?.attribute?.name]?.invalid &&
                  (group?.controls)[field?.attribute?.name]?.touched
              }"
            />

            <em class="nh-icon nh-icon-message actual-email-icon"></em>
          </div>
        </mat-form-field>
        <mat-form-field appearance="standard" *ngIf="field.type == 'textavatar'">
          <input
            class="avater-image"
            matInput
            [value]="field.value"
            [formControlName]="field?.attribute?.name"
            readonly="true"
            [required]="field.isRequired && !field?.isInfo"
            [autocomplete]="autoOff"
            id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
            [ngClass]="{ activeclick: isSelected, valueCheck: isValue }"
          />
        </mat-form-field>
        <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon" *ngIf="field.type == 'text' && isInMemoryRcuSearch">
          <div class="email-wrapper">
            <input
              matInput
              [value]="field.value"
              [formControlName]="field?.attribute?.name"
              [matTooltip]="field?.configuration?.hideTooltip ? '' : field.value"
              [matTooltipPosition]="'above'"
              [type]="field.type"
              [readonly]="field.readonly"
              [required]="field.isRequired && !field?.isInfo"
              [autocomplete]="autoOff"
              (focus)="onFocus($event)"
              id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
              [ngClass]="{
                inValidClass:
                  !field?.isInfo &&
                  (group?.controls)[field?.attribute?.name]?.invalid &&
                  (group?.controls)[field?.attribute?.name]?.touched
              }"
            />

            <mat-icon
            *ngIf="field?.type === 'text' && isInMemoryRcuSearch"
            class="form-icon mic-icon"
            (click)="onChange($event)"
          >
          search
          </mat-icon>
          </div>
        </mat-form-field>

        <!-- <mat-icon
          matTooltip="Sub-Transactional CU"
          matSuffix
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          ><span class="sub-cu" (click)="openSubPopUp()"></span>
        </mat-icon>
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.attribute['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          (click)="addAttribute.next()"
          >add</mat-icon
        >
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.attribute['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          (click)="removeAttribute.next()"
          >remove</mat-icon
        > -->
      </mat-form-field>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <!-- <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            !field?.isInfo &&
            field?.validations?.length == 0 &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
          "
          >{{ field?.displayName ? field?.displayName : field?.name }} is invalid</mat-error
        >
      </ng-container> -->
      <ng-container *ngFor="let validation of field?.validations" ngProjectAs="mat-error">
        <mat-error
        [ngClass]="{ 'position-absolute': field.inputType == 'mobilenumber' }"
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
      <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            slotValidation?.type &&
            (slotValidation?.type === 'ERROR' || slotValidation?.type === 'BLOCK_WARN') &&
            getErrorMessage(field, slotValidation)
          "
          >{{ slotValidation?.message }}</mat-error
        >
      </ng-container>
    </div>
  `,
  styles: [],
})
export class InputComponent implements OnInit {
  private unsubscribe: Subject<any> = new Subject<any>();
  isSelected: boolean = false;
  isInMemoryRcuSearch: boolean = false;
  field: FieldConfig;
  group: FormGroup;
  autoOff = 'on';
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  tenant: string;
  showPassword: boolean = false;
  pastedText: string;
  showDelay: 1000;
  hideDelay: 1000;
  touchendHideDelay: 1000;
  isEncryptedText: boolean = false;
  cuData: any;
  isValue: boolean = false;
  validationList: any[] = [
    'greaterThan',
    'greaterThanOrEqual',
    'smallerThan',
    'smallerThanOrEqual',
    'equalTo',
    'notequalTo',
    'valuesSelect',
    'min',
    'max',
    'positiveorzero',
    'positive'
  ];
  foundTextObject: any;
  foundNumberObject: any;
  foundMobileObject: any;
  appliedClass: string = 'custom-input-mat';
  appliedTextClass: string = 'custom-input-mat';
  appliedMobileClass: string = 'custom-input-mat';
  appliedNumberClass: string = 'custom-input-mat';
  appliedDivNumberClass: string = '';
  appliedNumberInputClass: string = 'input-quantity';
  showPlaceholder: boolean = false;
  isRecordingEnabled: boolean = false;
  audio: string = 'general';
  ngUnsubscribe: Subject<any> = new Subject();
  slotValidation:any;
  isRecording: boolean = false;
  negativeFlag: boolean = false;

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private alertService: AlertService,
    private transactionFacadeService: TransactionFacadeService,
    public sttService: SttEndpointService,
    private attributeStoreService: AttributeStoreService
  ) {
    // this.fieldChecking();
    this.detectCurrentCu();
    this.detectSelectedLabel();
    this.detectSttFinalOutput();
    this.detectSttInterimOutput();
  }

  fieldChecking(): void {
    /* istanbul ignore next */
    this.tenant = localStorage.getItem('TenantName');
    if (this.field?.type) {
      this.isEncryptedText = this.field.type === 'encrypttext';
      this.field.type = this.field.type === 'encrypttext' ? 'text' : this.field.type;
    }
    if (this.field?.type == 'textavatar') {
      this.getFirstLetters();
    }
    /* istanbul ignore next */
    this.autoOff = this.field?.type == 'password' ? 'new-password' : this.field?.readonly ? 'off' : 'on';
    this.createForm();
  }

  handlePastedText(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;

    // if (this.field.inputType === 'email') {
    //   if (isNaN(Number(inputValue)) == false) {
    //     event.preventDefault();
    //   }
    // } else
     if (this.field.inputType === 'number' || this.field.inputType === 'mobilenumber') {
      if (isNaN(Number(inputValue)) == true) {
        event.preventDefault();
      }
    }
    this.field.value = inputValue;
  }

  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    this.pastedText = clipboardData.getData('text');
    this.handlePastedText(event);
  }

  @HostListener('keyup', ['$event'])handleKeyUp(event: KeyboardEvent) {
    this.handlePastedText(event);
  }
  

  ngOnInit(): void {
    /* istanbul ignore next */
    this.isInMemoryRcuSearch = this.field?.entityName?.split('$')?.[0] == 'NSL_Text_Search' && this.field?.name == 'search_word';
    this.tenant = localStorage.getItem('TenantName');

    /* istanbul ignore next */
    if (
      this.group?.controls?.[this.field?.attribute?.name]?.value &&
      this.group?.controls?.[this.field.attribute.name]?.value != ''
    ) {
      if (this.field?.inputType === 'mobilenumber') 
        this.group?.controls[this.field?.attribute?.name]?.setValue(this.removeSpecialCharacters(this.group?.controls[this.field.attribute.name].value));
      this.field.value = this.group?.controls[this.field.attribute.name].value;
    }
    /* istanbul ignore next */
    this.autoOff = this.field?.type == 'password' ? 'new-password' : this.field?.readonly ? 'off' : 'on';
    this.createForm();
    this.fieldChecking();
    this.valueChecking();
    let textOption = getUiControlCustomization('Text');
    /* istanbul ignore else */
    if (textOption) {
      this.foundTextObject = { type: 'text', event: textOption };
      this.appliedTextClass = 'form-input-var form-input-var-opt';
      switch (textOption) {
        case 'Option 1':
          this.appliedTextClass += '1';
          break;
        case 'Option 2':
          this.appliedTextClass += '2';
          break;
        case 'Option 3':
          this.appliedTextClass += '4';
          break;
        case 'Option 4':
          this.appliedTextClass += '5';
          break;
        case 'Option 5':
          this.appliedTextClass += '6';
          break;
        case 'Option 6':
          this.appliedTextClass += '7';
          break;
        case 'Option 7':
          this.appliedTextClass += '9';
          break;
        case 'Option 9':
          if (!this.field.value) this.showPlaceholder = true;
          this.appliedTextClass += '10';
          break;
      }
    }
    let numberOption = getUiControlCustomization('Number');
    /* istanbul ignore else */
    if (numberOption) {
      this.foundNumberObject = { type: 'Number', event: numberOption };
      this.appliedNumberInputClass = 'quantity';
      this.appliedNumberClass = '';
      /* istanbul ignore else */
      if (numberOption == 'Option 3') {
        this.appliedDivNumberClass = 'form-number-input number-input-arrow';
      } else {
        this.appliedDivNumberClass = 'form-number-input number-input-arrow form-number-input-var-opt';
        let Option = numberOption.slice(-1);
        this.appliedDivNumberClass += Option;
      }
    }
    let mobileOption = getUiControlCustomization('PhoneNumber');
    /* istanbul ignore else */
    if (mobileOption) {
      this.foundMobileObject = { type: 'PhoneNumber', event: mobileOption };
      this.appliedMobileClass =
        mobileOption == 'Option 1'
          ? 'form-input-var form-input-var-opt1 placeholder-icon for-phone-number'
          : 'mobile-parent-class-styles form-input-var-opt10';
    }
    /* istanbul ignore else */
    if (this.field?.inputType == 'mobilenumber') this.appliedClass = this.appliedMobileClass;
    else if (this.field?.inputType == 'number') this.appliedClass = this.appliedNumberClass;
    else if (this.field?.inputType == 'text') this.appliedClass = this.appliedTextClass;
    /* istanbul ignore next */
    if (
      this.field.inputType === 'mobilenumber' &&
      this.field.configuration?.mobileFormat?.displayValue == 'USA' &&
      this.field.value
    ) {
      this.field.value = returnMobileNumber(this.field.value, 'USA');
      this.group.controls[this.field.attribute?.name].setValue(displayUSAMobileFormat(this.field.value));
    }
    if (this.field?.value && this.field?.isOptional) {
      this.setOptionalValidators();
    }
    if (
      this.field.inputType === 'mobilenumber' &&
      this.field.configuration?.mobileFormat?.displayValue == 'MEXICO' &&
      this.field.value
    ) {
      this.field.value = returnMobileNumber(this.field.value, 'MEXICO');
      this.group.controls[this.field.attribute?.name].setValue(displayMexicoMobileFormat(this.field.value));
    }
    if (this.field?.value && this.field?.isOptional) {
      this.setOptionalValidators();
    }
     /*istanbul ignore next*/
     this.attributeStoreService?.stateChanged?.subscribe((state: StoreState) => {
      if(state?.attributeList
        && this.field?.attribute?.name?.includes('Booking Count')
        && this.field?.entityName?.includes('NSL_SlotBooking')){
        this.group?.controls[this.field?.attribute?.name]?.setValue("");
        this.field.value = "";
      }
  });
  }
  removeSpecialCharacters(input: string) {
    return input.replace(/[^a-zA-Z0-9\s]/g, '');
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  valueChecking() {
    if (this.cuData && this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review') {
      this.cuData?.layers?.[0]?.entityList?.[2]?.nslAttributes?.forEach((attr: any) => {
        let attrDisplayName = JSON.parse(JSON.stringify(attr?.displayName));
        attrDisplayName = attrDisplayName.toLowerCase();
        attrDisplayName = attrDisplayName.replace(' ', '');
        attrDisplayName = attrDisplayName.trim();
        let fieldDisplayName = JSON.parse(JSON.stringify(this.field?.displayName));
        fieldDisplayName = fieldDisplayName.toLowerCase();
        fieldDisplayName = fieldDisplayName.replace(' ', '');
        fieldDisplayName = fieldDisplayName.trim();
        if (
          attrDisplayName == fieldDisplayName &&
          this.field?.value.toLowerCase().trim() != attr?.values?.[0]?.toLowerCase().trim()
        ) {
          this.isValue = true;
        }
      });
    }
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    if (validation?.name == 'notempty') this.validateNotEmptyValidator(validation);
    return this.group?.get(String(this.field?.attribute?.name))?.status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (
      this.group?.controls[field?.attribute.name]?.value != null ||
      this.group?.controls[field?.attribute.name]?.value != undefined
    ) {
      if (validation?.name == 'min') {
        return parseInt(this.group?.controls[field?.attribute?.name].value) < parseInt(validation?.value);
      } else if (validation?.name == 'max') {
        return parseInt(this.group.controls[field?.attribute?.name].value) > parseInt(validation?.value);
      } else {
        if (this.validationList?.includes(validation?.name)) {
          return validateDependentExpression(
            validation,
            this.eventsService,
            this.field,
            this.group,
            this.transactionFacadeService
          );
        }
        return this.group.controls[field?.attribute?.name]?.value?.toString()?.match(validation?.requiredPattern)
          ? false
          : true;
      }
    }
  }

  createForm() {
    /* istanbul ignore next */
    if (this.field && this.field.inputType === 'email') {
      let control = new FormControl();
      this.group.addControl(this.field?.attribute?.name, control);
      /* istanbul ignore next */
      if (this.field?.validations?.length == 0) {
        this.group.controls[this.field?.attribute?.name].setValidators([
          Validators.pattern('^[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\\.[a-zA-Z]{2,4}$'),
        ]);
      }
    } else if (this.field && this.field.inputType === 'number') {
      /* istanbul ignore next */
      if (this.field.value && this.field.value != '') {
        this.field.value = typeof this.field.value === 'number' ? this.field.value : this.field.value.split('.')[0];
        if (this.field?.type && this.field?.type === 'number') {
          if (this.field?.value && typeof this.field.value === 'string') {
            this.field.value = parseInt(this.field.value);
          }
        }
        this.group?.controls[this.field?.attribute?.name]?.setValue(this.field.value);
      }
    }
    /* istanbul ignore else */
    if (this.field?.validations?.length > 0) {
      const validList: any = [];
      /* istanbul ignore next */
      this.field?.validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      /* istanbul ignore next */
      this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
    }
  }

  triggerOnchange(event: any, type: string) {
    this.group.controls[this.field.attribute.name].markAsDirty();
    type == 'plus'
      ? this.group.controls[this.field.attribute.name].setValue(
          (this.group?.controls)[this.field.attribute.name].value === ''
            ? 0
            : (this.group?.controls)[this.field.attribute.name].value + 1
        )
      : this.group.controls[this.field.attribute.name].setValue(
          (this.group?.controls)[this.field.attribute.name].value - 1
        );
    this.onChange(event);
  }

  onlyNumberKey(event: any, fieldType: string) {
       /*istanbul ignore if*/
       /*istanbul ignore if*/
       if (this.field?.entityName?.includes('NSL_SlotBooking') && this.field?.attribute?.name?.includes('Booking Count')) {
        this.slotValidation = {
          name: 'NSL_SlotBooking',
          value: this.group?.controls[this.field?.attribute?.name]?.value,
          dependentExpression: 'NSL_SlotBooking.Slots',
          type: '',
          message: '',
        };
        let value = validateDependentExpression(
          this.slotValidation,
          this.eventsService,
          this.field,
          this.group,
          this.transactionFacadeService
        );
        let selectedChipValue =this.attributeStoreService.getStateData();
        if(value){
        let bookingCount = value[(value?.findIndex((v:any)=> v?.actualValue == selectedChipValue?.attributeList))]?.count
        this.slotValidation.name = 'smallerThanOrEqual';
        this.slotValidation.value = bookingCount;
        this.slotValidation.type = 'ERROR';
        this.slotValidation.message = 'Maximum allowed count is ' + bookingCount;
        }
      }
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }
    /* istanbul ignore next */
    if (this.field?.type == 'text') {
      this.showPlaceholder = event?.target?.value == '' ? true : false;
    }
    if (fieldType === 'number') {
      if (event.charCode == 45 && !this.negativeFlag) {
        this.negativeFlag = true;
        return true;
      }
    if(this.group?.controls[this.field?.attribute?.name]?.value==null){
      this.negativeFlag = false;
    }
    if(this.group?.controls[this.field?.attribute?.name]?.value==null){
      this.negativeFlag = false;
    }
    return event.charCode == 8 || event.charCode == 0
        ? null
        : event.charCode > 46 && event.charCode <= 57;
    } else if (fieldType === 'mobilenumber' && event?.type !== 'paste') {
      return event.charCode == 8 || event.charCode == 0
        ? null
        : event.charCode == 45 || event.charCode == 43 || (event.charCode > 46 && event.charCode <= 57);
    }
    setTimeout(() => {
      this.changeText(event, this.field.inputType);
    }, 10);
  }
  onPaste(event: any) {
    if (event.type === 'paste') {
      const pastedText = event.clipboardData.getData('text/plain');
      if (!/^\d+$/.test(pastedText)) {
        // Prevent pasting if it contains non-digit characters
        event.preventDefault();
        return;
      }
    }
  }

  updateMobileNumber(event: any, fieldType: string) {
    if (fieldType === 'mobilenumber' && this.field?.configuration?.mobileFormat?.displayValue == 'USA') {
      this.field.value = returnMobileNumber(event?.target?.value, 'USA');
      this.group.controls[this.field.attribute?.name].setValue(displayUSAMobileFormat(this.field.value));
    }
  }

  changeText(event: any, fieldType: string) {
    if (fieldType == 'text') {
      event.target.value = event.target.value.replace(/ +/g, ' ');
    }
    if (fieldType == 'text' && this.field?.attributeType?.properties?.uppercase === 'true') {
      event.target.value = event.target.value.toUpperCase();
    }
    if (fieldType == 'text' && this.field?.attributeType?.properties?.lowercase === 'true') {
      event.target.value = event.target.value.toLowerCase();
    }

    if (fieldType === 'text' && this.field?.attributeType?.properties?.alphanumeric === 'true') {
      event.target.value = event.target.value.replace(/[^\w\s]/g, '').replaceAll('_', '');
    }
  }

  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange(event: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      // this.validateDependentExpression();
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore next */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }

  /**
   * Determines whether input field is focused
   * @param event Contains the input value
   */
  onFocus(event: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_FOCUS',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        entityMasterId: this.field?.entityMasterId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        attribute_type: this.field?.type,
        attribute_displayName: this.field?.attribute?.displayName,
        attrTxnRecordId: this.field?.attrTxnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* istanbul ignore else */
      this.eventsService.setEvent(data);

      /* istanbul ignore next */
      if (this.cuData?.isReserved && this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review') {
        this.isSelected = true;
        this.updateDataForPDf(data, this.cuData);
      }

      /* conditional potentiality check  */
      /* if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } */
    }
  }

  setOptionalValidators() {
    if (this.group?.value?.value && this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    } else if (!this.group?.value?.value && this.field?.optionalValidation) {
      this.field.validations = null;
      this.group?.controls[this.field?.attribute?.name]?.setValidators(null);
      this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
    }
  }

  updateDataForPDf(data: any, cuData: any) {
    /* istanbul ignore next */
    const res = {
      currentCU: cuData,
      selected_atrribute: {
        enity_name: data?.entityName?.split('$')[0],
        attribute_name: data?.attrName?.split('$')[0],
        entity_master_id: data?.entityMasterId,
        attribute_id: data?.attributeId,
        entity_index: data?.entityName?.split('$')[1] - 1,
        value_index: 0,
        attribute_index: data.attrName.split('$')[1],
        // attribute_index: data?.attrName?.split('$')[1],
      },
    };
    this.eventsService.updateSelctedAttrLabel({ isSelected: false, attrName: data?.attrName });
    this.eventsService.getPdfTextCord(res);
  }

  /* istanbul ignore next */
  detectCurrentCu() {
    this.transactionFacadeService.currentCuDetails$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result?.currentCU) {
        this.cuData = res?.result?.currentCU;
      }
    });
  }
  onClickPasswordIcon() {
    if (this.field.type === 'password') {
      this.field.type = 'text';
      this.showPassword = true;
    } else {
      this.field.type = 'password';
      this.showPassword = false;
    }
  }
  hidePassWord() {
    this.field.type = 'password';
    this.showPassword = false;
  }
  currentFocusedInputId:string
  recordVoice() {
    this.isRecording = !this.isRecording;
    this.currentFocusedInputId=this.field?.attribute?.id;

    if (!this.isRecordingEnabled) {
      this.isRecordingEnabled = true;
      this.startVoiceToText(this.currentFocusedInputId);
    } else {
      this.isRecordingEnabled = false;
      this.stopVoiceToText();
    }
  }
  startVoiceToText(audio?: string) {
    if (audio !== undefined) {
      this.audio = audio;
    }
    this.sttService.start();
  }
  stopVoiceToText() {
    this.sttService.stop();
  }
  setErrorDependentAttribute(validator: any) {
    /* istanbul ignore next*/
    if (validator?.type !== 'INFO') {
      /* istanbul ignore next*/
      this.group?.controls[this.field?.attribute?.name]?.setErrors({ [validator?.name || 'incorrect']: true });
      /* istanbul ignore next*/
      this.group?.setErrors({ invalid: true });
    }
  }
  detectSttFinalOutput() {
    this.sttService.finalResultObservable.pipe(
      takeUntil(this.ngUnsubscribe),
      delay(1000)
    ).subscribe((res: string) => {
      if (res && this.currentFocusedInputId==this.field?.attribute?.id) {
          this.group?.controls[this.field?.attribute?.name]?.setValue(res);
      }
      this.stopVoiceToText();
    });
  }
  detectSttInterimOutput() {
    this.sttService.interimResultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      if (res) {
        this.group?.controls[this.field?.attribute?.name]?.setValue(res);
      }
    });
  }
  validateNotEmptyValidator(validator: any) {
    let flag = false;
    if (this.group?.controls[this.field?.attribute?.name]?.value?.toString()) return (flag = false);
    this.setErrorDependentAttribute(validator);
    // this.field.isRequired = false;
    this.group?.status === 'INVALID'
      ? this.transactionFacadeService.disableSubmitButtonFlag.next(true)
      : this.transactionFacadeService.disableSubmitButtonFlag.next(false);
    return flag;
  }
  getFirstLetters() {
    const firstLetters = this.field.value
      .toUpperCase()
      .split(' ')
      .map((word: any) => word[0])
      .join('');
    if (firstLetters[0] && firstLetters[1]) {
      this.field.value = firstLetters[0] + firstLetters[1];
    } else {
      this.field.value = firstLetters[0];
    }
    this.field.value = this.field.value || '';
    this.group.controls[this.field?.attribute?.name].setValue(this.field.value);
  }
  /* istanbul ignore next */
  detectSelectedLabel() {
    this.eventsService.updateLabelColor$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.isSelected = false;
      /* istanbul ignore next */
      if (
        this.field?.attribute['name'] == res?.attrName &&
        this.cuData?.isReserved &&
        this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review'
      ) {
        this.isSelected = res?.isSelected;
      }
    });
  }
}
