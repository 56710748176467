<ng-template #actionButton let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <span class="btn-close">
      <i
        class="bi bi-x"
        *ngIf="kanbanGsiConfiguration?.[data?.position]"
        (click)="removeGsiFromCard(data?.position)"
      ></i>
      <button
        *ngIf="!isGrammer"
        #gsiAction
        attributeDesignConfig
        [isEdit]="isEdit"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [_style]="style"
        [tabSelected]="tabSelected"
        [isDesign]="isDesign"
        [isButton]="true"
        [_selectedDirectiveId]="selectedDirectiveId"
        (drop)="dropActionBtn($event)"
        (dragover)="allowDrop($event)"
        class="btn template-btn action-button btnaction"
        pTooltip="Action Button"
        [id]="'action-' + data?.position"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [ngClass]="data?.class"
      >
        {{kanbanGsiConfiguration?.[data?.position] ? kanbanGsiConfiguration?.[data?.position].name : 'Action Button'}}
      </button>
    </span>
  </ng-container>

  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="data?.isSponser">
      <div
        [ngClass]="data?.class"
        *ngIf="gsiMapping[data?.position]?.displayName"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
      ></div>
    </ng-container>
    <ng-container *ngIf="!data?.isSponser">
      <button
        [ngStyle]="{ background: btnColors?.[data?.position] }"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name && quantity[txnDataIndex] == 0"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </button>
    </ng-container>

    <div
      class="quantity-section"
      *ngIf="gsiMapping?.[data?.position]?.name && quantity[txnDataIndex] > 0 && gsiMapping?.[data?.position]?.entityType == 'CART'  "
    >
      <div class="quantity-input">
        <button class="quantity-btn">
          <i class="bi bi-dash" (click)="changeQuantity('decreaseQuantity', txnDataIndex)"></i>
        </button>
        <span class="quantity">{{ quantity[txnDataIndex] }}</span>
        <button class="quantity-btn">
          <i class="bi bi-plus" (click)="changeQuantity('increaseQuantity', txnDataIndex)"></i>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
<ul class="map-attribute">
  <li
    [id]="attributeItem?.innerHTML"
    (drop)="dropAttribute($event, 'any', 0)"
    attributeDesignConfig
    [cardDisplay]="cardDisplay"
    [position]="0"
    [isEdit]="isEdit"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [isDesign]="isDesign"
    [_isCondition]="conditionColors"
    [_selectedDirectiveId]="selectedDirectiveId"
    [_style]="style"
    (emitSelectedConditionElement)="recieveConditionColor($event)"
    [tabSelected]="tabSelected"
    (dragover)="allowDrop($event)"
  >
    <!-- <div class="attribute-unlink"> -->
    <div #attributeItem class="attribute" id="attr-0" [ngClass]="{'d-none': kanbanPositionMap?.[0]}">
      <ng-container>{{kanbanPositionMap?.[0] ?kanbanPositionMap?.[0]?.name : "Attribute" }}</ng-container>
    </div>
    <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
      ><img
        class="cursor-pointer"
        (click)="onDeletingAttr(attributeItem)"
        [src]="'../../../../../assets/img/addmeasures/delete.svg'"
        alt=""
    /></span>
    <span class="delete-attr" *ngIf="childPositionMap?.[0]?.addOnChild?.value"
      >addon<img
        class="cursor-pointer"
        (click)="onDeletingAddOn(attributeItem)"
        [src]="'../../../../../assets/img/addmeasures/delete.svg'"
        alt=""
    /></span>
  </li>
</ul>

<div *ngIf="kanbanPositionMap?.[0]" class="kanban-configuration d-flex justify-content-between">
  <div class="right-container flex-grow-1">
    <div class="kanban-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'kanban-btn', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'kanban-btn', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
    <div cdkDropListOrientation="horizontal" class="row no-gutters" cdkDropList (cdkDropListDropped)="onDrop($event)">
      <div class="col" *ngFor="let status of kanbanStatus; let lastItem = last" cdkDrag>
        <div  *ngIf="!status.isHidden"  [class.nh-mr-12]="!lastItem">

          <div class="stage">
            <div class="stage-header">
              <p>{{ status.DATA.displayValue }}</p>
              <div class="icons">
                <em class="nh-icon nh-icon-drag" cdkDragHandle></em>
                <em class="nh-icon nh-icon-plus" (click)="openKanbanStatusConfigModal()"></em>
                <em class="nh-icon nh-icon-trash01" (click)="hideKanbanStatus(status)"></em>
              </div>
            </div>
            <div class="stage-body"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #kanbanStatusConfigTemplate let-data>
  <div class="modal-header align-items-center">
    <div>
      <p class="nh-fs-20 nh-fw-600">Attribute:<span>{{kanbanPositionMap?.[0]?.displayName}}</span></p>
    </div>
    <img (click)="closeDialog()" src="../../../../../../assets/img/close.svg" alt="" class="img-sm" />
  </div>
  <div class="modal-body">
    <p class="nh-mb-24 nh-fs-16 nh-fw-600">Select Value</p>
    <div
      class="d-flex align-items-center justify-content-between pl-24 pr-24 pl-24 pt-16 pb-12 nh-mb-10"
      *ngFor="let kanbanStatus of data;let i=index"
    >
      <div class="d-flex">
        <p class="nh-mr-24 nh-fs-16 nh-fw-600">{{i}}.</p>
        <p class="nh-fs-16 nh-fw-600">{{ kanbanStatus.DATA.displayValue }}</p>
      </div>
      <div><mat-checkbox (change)="hideKanbanStatus(kanbanStatus)" [checked]="!kanbanStatus.isHidden"></mat-checkbox></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary btn-done" (click)="closeDialog()">Done</div>
  </div>
</ng-template>
