<div id="image-container">
  <div class="close-btn-redact">
    <button mat-dialog-close id="close"><mat-icon>close</mat-icon></button>
  </div>
  <div [ngSwitch]="fileType">
    <ng-container *ngSwitchCase="'image'">
      <div class="popup-scroll" #scrollElement (scroll)="onScroll($event)">
        <img
          [src]="src"
          class="popimg abc"
          (contextmenu)="onRightClick($event)"
          #imagemapping
          alt=""
          draggable="false"
        />
        <div *ngIf="!preview">
          <button (click)="onSubmit()" class="pointer">Done</button>
          <br />
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'pdf'">
      <div class="popup-scroll" (scroll)="onScroll($event)">
        <pdf-viewer
          [show-all]="false"
          [ngClass]="'ocr-pdf'"
          [src]="src"
          [render-text]="true"
          [page]="pageNumber"
          [fit-to-page]="true"
          [original-size]="false"
          style="display: block;"
          id="pdfMapping"
          (contextmenu)="onRightClick($event)"
          (pageChange)="pageChange($event)"
          (page-rendered)="pageRendered($event)"
          (after-load-complete)="afterLoadComplete($event)"
        ></pdf-viewer>
        <div class="pagination-style">
          <app-paginator
            class="d-flex"
            [pageIndex]="pageNumber - 1"
            [pageSize]="1"
            [totalRecords]="totalPages"
            (onPageChange)="changePage($event)"
            *ngIf="fileType === 'pdf' && totalPages > 1"
          >
          </app-paginator>
        </div>
      </div>
      <div *ngIf="!preview" class="pointer">
        <button (click)="onSubmit()" class="pointer">Done</button>
        <br />
      </div>
    </ng-container>
  </div>
  <div class="coordinates-container position-static" *ngIf="!preview">
    <ng-container>
      <ng-container *ngFor="let c of coordinatesByPAgeNumber; let i = index">
        <div class="area" [ngStyle]="getCoordinateStyle(c)" (click)="undoBox(i)"></div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="type == 'move'">
      <ng-container *ngFor="let c of newcoordinatesByPAgeNumber; let i = index">
        <div class="area" [ngStyle]="getCoordinateStyle(c)" (click)="undoBox(i)"></div>
      </ng-container>
    </ng-container>
  </div>
</div>
