import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'events',
})
export class EventsPipe implements PipeTransform {
  //this pipe searches name of attribute using contexual id
  transform(id: any, layers?: any): string {
    let output = '';
    layers?.forEach((layer: any) => {
      layer?.participatingItems?.forEach((item: any) => {
        item?.nslAttributes?.forEach((attr: any) => {
          if (id == attr?.id) {
            output = attr?.name;
            return;
          }
        });
      });
    });
    return output;
  }
}
