import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {
  AlertService,
  AugumentedRealityEndpointService,
  ChangeUnitFacadeService,
  CrossDomainMessagingService,
  EventsFacadeService,
  FieldConfig,
  GsiFacadeService,
  LayoutService,
  LayoutSolutionFacadeService,
  LoaderService,
  OCRFacadeService,
  SharedFacadeService,
  TransactionEndpointService,
  TransactionFacadeService,
  TranslatorService,
  eventsListMapper,
  getFileNameFromImgUrl,
  setPropertyInDivLayout,
  IsJsonString,
  generateImpressionCallObject,
  generateClickadObject,
  getDTFormat,
} from '@common-services';
import { Guid } from 'guid-typescript';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectFade,
  EffectFlip,
  FreeMode,
  Grid,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper';
import { pSBC } from '../constants/darken-color.const';
declare const gtag: Function;
//declare const gtag: Function;
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  EffectCoverflow,
  EffectFlip,
  FreeMode,
  Grid,
  EffectFade,
]);

export interface DialogData {
  gsi: Object;
  currentCU: Object;
  mode: string;
  txnData: Object;
  isReadOnly: Boolean;
}

@Component({
  selector: 'app-transaction-view',
  templateUrl: './transaction-view.component.html',
  styleUrls: ['./transaction-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransactionViewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  templateType: string;
  @Input() transactionCU: any;
  @Input() gsi: any;
  @Input() selectedCu: any;
  @Input() mode: string;
  @Input() txnData: any;
  @Input() solutionInfo: any;
  @Output() inividualGsiData: any = new EventEmitter();
  @Output() setInividualGsiData: any = new EventEmitter();
  @Input() isReadOnly: any;
  @Input() isDebugMode: boolean;
  @Input() isCarnival: boolean;
  @Input() isOnboarding: boolean;
  @Input() layoutConfigurations: any;
  @Input() selectedCard: any;
  @Input() isTransactionActive: boolean;
  @Input() gsiDesign: any;
  @Input() gsiPopup: boolean = false;
  optionsMap: Map<any, any>;

  redac: boolean;
  userInfo: any;
  infoDisplayNone = false;

  // @Input() entitiesList: any;
  book: any;
  showForm: boolean;
  currentEntity: any;
  currentCuIndex: number = 1;
  canTriggerNextGSi: boolean = false;
  // showNext: boolean = false;
  routeEntity: any;
  nestedEntity: any;
  physicalFields: any = {};
  infoFields: any = {};
  hideSubmitButton = false;

  unsubscribe$: Subject<any> = new Subject<any>();
  bookDesignData: any = {};
  styleContent: any = {
    primaryColor: 'default',
    'background-color': '#ffffff',
    fontFamily: '"Inter", sans-serif;',
  };
  @ViewChild('thankumodal') thankumodal: TemplateRef<any>;
  @ViewChild('ArDialog') ArDialog: TemplateRef<any>;
  @ViewChild('cuMatTab', { static: false }) cuMatTab: any;
  @ViewChild('metaMaskAlert', { static: true }) metaMaskAlert: TemplateRef<any>;

  timerToRedirect: any = 45;
  timerSubscription: Subscription;
  tick = 1000;
  bgStyleContent: any = {};
  similarColors: any[];
  cuBtnValidations: boolean = false;
  entitySelectionMap: Map<any, any>;
  lastCompletedSolutionIndex: number = -1;
  similarColorsForPrimary = [
    { color: '#666666', parentColor: 'default' },
    { color: '#009870', parentColor: 'default' },
    { color: '#009870', parentColor: 'default' },
    { color: '#151D46', parentColor: 'default' },
    { color: '#6965C3', parentColor: 'blue' },
    { color: '#150F85', parentColor: 'blue' },
    { color: '#150F85', parentColor: 'blue' },
    { color: '#150F85', parentColor: 'blue' },
    { color: '#29583F', parentColor: 'green' },
    { color: '#519872', parentColor: 'green' },
    { color: '#519872', parentColor: 'green' },
    { color: '#519872', parentColor: 'green' },
    { color: '#FDFACE', parentColor: 'yellow' },
    { color: '#F5C911', parentColor: 'yellow' },
    { color: '#F5C911', parentColor: 'yellow' },
    { color: '#F5C911', parentColor: 'yellow' },
    { color: '#835858', parentColor: 'red' },
    { color: '#463333', parentColor: 'red' },
    { color: '#463333', parentColor: 'red' },
    { color: '#463333', parentColor: 'red' },
  ];
  currentCuId: any;
  physicalEntities: any;
  tempentList: any;
  tempInfoEntList: any;
  informationEntities: any;
  currentEntityIndex = 0;
  currentIndexOfCarnivalCu: number = 0;
  @Output() getNextCUEmitter = new EventEmitter();
  @Output() formValueChange = new EventEmitter();
  @Output() tempEntityListEmit = new EventEmitter();
  @Output() tempInfoEntityListEmit = new EventEmitter();
  tempEntityList: any = [];
  cuStartingPosition: any = 0;
  cuEndingPosition: any = 4;
  displayCuList: any[] = [];
  flagLinkLoop: any[] = [];
  activeCUIndex: number = 0;
  recordVideoDisable: any;
  recordAudioDisable: any;
  defiGsi: boolean = false;
  currentDefiGsi: any;
  minimumSelectFlag: boolean = true;

  dataTypeMapper: any = [
    { mapType: 'string', control: { type: 'input', inputType: 'text' } },
    { mapType: 'string', control: { type: 'webex' } },
    { mapType: 'string', control: { type: 'dynamicID' } },
    { mapType: 'table', control: { type: 'table' } },
    { mapType: 'number', control: { type: 'input', inputType: 'number' } },
    {
      mapType: 'currency',
      control: { type: 'input', inputType: 'number', units: '&#8377;' },
    },
    { mapType: 'date', control: { type: 'date' } },
    { mapType: 'datetime', control: { type: 'datetime' } },
    { mapType: 'currentdatetime', control: { type: 'currentdatetime' } },
    { mapType: 'time', control: { type: 'time' } },
    { mapType: 'email', control: { type: 'input', inputType: 'email' } },
    { mapType: 'boolean', control: { type: 'boolean' } },
    {
      mapType: 'dropdown',
      control: {
        type: 'dropdown',
        options: [
          { name: 'test', value: 'test' },
          { name: 'test2', value: 'test2' },
        ],
      },
    },
    {
      mapType: 'checkbox',
      control: {
        type: 'checkbox',
        options: [
          { name: 'test', value: 'test' },
          { name: 'test2', value: 'test2' },
        ],
      },
    },
    {
      mapType: 'radioButton',
      control: {
        type: 'radioButton',
        options: [
          { name: 'test', value: 'test' },
          { name: 'test2', value: 'test2' },
        ],
      },
    },
    {
      mapType: 'image',
      control: {
        type: 'image',
        options: [
          {
            value:
              'https://nsltf-s3-bucket.s3.ap-south-1.amazonaws.com/tf_images/images/home/1.png',
          },
          {
            value:
              'https://nsltf-s3-bucket.s3.ap-south-1.amazonaws.com/tf_images/images/home/2-new.png',
          },
          {
            value:
              'https://nsltf-s3-bucket.s3.ap-south-1.amazonaws.com/tf_images/images/home/4-new.png',
          },
          {
            value:
              'https://nsltf-s3-bucket.s3.ap-south-1.amazonaws.com/tf_images/images/home/8-new.png',
          },
        ],
      },
    },

    { mapType: 'map', control: { type: 'map' } },
    { mapType: 'signature', control: { type: 'signature' } },
    { mapType: 'video', control: { type: 'video' } },
    { mapType: 'audio', control: { type: 'audio' } },
    { mapType: 'file', control: { type: 'file' } },
    {
      mapType: 'list',
      control: {
        type: 'list',
        options: [
          { name: 'item1', value: 'item1' },
          { name: 'item2', value: 'item2' },
        ],
      },
    },
    { mapType: 'editor', control: { type: 'editor' } },
    { mapType: 'dateRange', control: { type: 'dateRange' } },
  ];
  layerItem: any = {};
  layersData: any[] = [];
  UIcontrols: any = [];
  uiControlsLoaded: boolean;
  formData: any;
  labels: any;
  infoEntities: any;

  layoutType: any;
  infoCu: boolean;
  fontUrl: string;
  ocrModalShow = false;
  transactionViewOCR = false;
  transactionMappings: any = [];
  solutionLayouts: any = [];
  tempAds: any = [];
  openGsiId: number;
  gsiDiscardModal: boolean = false;
  isProceedDiscard: boolean = false;
  selectedCuReadOnly: any;
  activeGrid: string = 'three';
  leftSection: boolean = true;
  isLmstemplate: boolean = true;
  embeddedGSIid: any;
  currentTab = 'description';
  gsiInfoCont: boolean;
  cuLayoutTemplate: any = {
    asserts: { color: '', opacity: 0, url: '' },
    actionLabel: '',
    template: 'default-layout',
    background: { color: '#C3DEFF', opacity: 1.0, url: '' },
    formColor: '#FFFFFF',
    outline: { color: '', size: 1, corner: 2.0 },
    dropShadow: { color: '', xAxis: 2.5, yAxis: 7.5, opacity: 1.0, blur: 5.0 },
    backgroundBlur: { blur: 0.0, brightness: 1, opacity: 1.0 },
    font: {
      cuFont: {
        fontSize: 15,
        color: '#000000',
      },
      physicalLayerFont: {
        fontSize: 15,
        color: '#000000',
      },
      informationLayerFont: {
        fontSize: 15,
        color: '#000000',
      },
      gsiTabs: {
        color: '#000000',
        fontSize: 12,
      },
      attribute: {
        color: '#000000',
        fontSize: 16,
      },
    },
  };
  tempcuLayoutTemplate = { ...this.cuLayoutTemplate };
  attributeColor: any = '#000000';
  attributeSize: any = 16;
  layoutList: any[] = [
    'layoutTemp-01',
    'layoutTemp-02',
    'layoutTemp-03',
    'layoutTemp-04',
    'layoutTemp-05',
    'layoutTemp-06',
    'layoutTemp-07',
    'layoutTemp-08',
    'layoutTemp-09',
    'layoutTemp-10',
    'layoutTemp-11',
    'layoutTemp-12',
    'layoutTemp-13',
    'layoutTemp-14',
    'layoutTemp-15',
    'layoutTemp-16',
    'layoutTemp-17',
    'layoutTemp-18',
    'layoutTemp-19',
    'layoutTemp-20',
  ];
  tenantName: string;
  isFacetSearchCu: boolean = false;
  isInmemoryRCU: boolean = false;
  GsicurrentPageNo: number = 0;
  nextGsiPage: number;
  numberOfLayers: any = '';
  scrollPosition: any;
  ocrSmallModal: boolean;
  adsBottom: any;
  firstTime: boolean = true;
  previousData: any;
  swiper_config: SwiperOptions = {
    loop: false,
    autoHeight: true,
    allowTouchMove: true,
    spaceBetween: 1,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    slidesPerView: 'auto',
    pagination: { el: '.trans-view-swiper .swiper-pager', clickable: true },
    navigation: {
      nextEl: '.trans-view-swiper .swiper-next',
      prevEl: '.trans-view-swiper .swiper-prev',
    },
    roundLengths: true,
    slidesOffsetAfter: 0,
    centeredSlides: false,
  };
  fieldDetails: any;
  submitDisableInteger: any;
  submitDisableIntegerDuration: any;
  disableDuration: any;
  disableSubmitButtonFlag: any = false;
  arEntitiesData: any;
  physicalBtn: boolean = false;
  hybridBtn: boolean = false;
  arBtnEntity: boolean = false;
  timeIntervalofBottomAds: any;
  timeIntervalofAds: any;
  currentLanguageCode: any = 'EN';
  flagAutoSubmit: boolean = true;
  isHideGsi: boolean;
  isHideCU: boolean;
  bookSize: any;
  bookColor: any;
  changeLayout(id: any) {
    this.templateType = id;
    /* istanbul ignore else */
    if (this.templateType == 't-9') {
      this.physicalEntities = this.tempentList;
      this.informationEntities = this.tempInfoEntList;
      this.isLmstemplate = false;
    }
    /* istanbul ignore next */
    if (this.templateType === 't-10') {
      // document.documentElement.style.setProperty('--chatLeft', '30px');
      this.renderer.addClass(document.body, 'chartBootLeft');
    } else {
      this.isLmstemplate = true;
    }
  }
  infoLayer = false;
  infoLayerEvent() {
    this.infoLayer = !this.infoLayer;
  }

  infoSelectedCu() {
    this.infoCu = !this.infoCu;
  }

  cancelDialog() {
    this.gsiPopup = false;
    this.transactionFacadeService.openPopup.next('close');
    this.transactionFacadeService.resumePreviousTrans(null);
  }

  callgoBack() {
    this.dialog.closeAll();
    this.transactionFacadeService.isMccPopup = false;
    if (!this.defiGsi) {
      this.router.navigate(['/mylibrary-dashboard']);
    }
    localStorage.removeItem('GsicurrentPageNo');
    this.GsicurrentPageNo = 0;
  }

  goBack() {
    if (this.transactionFacadeService.isLogoutConfigGsi) {
      this.transactionFacadeService.logoutApplication();
      return;
    }
    sessionStorage.removeItem('entityTabIndexDefi');
    let campaignUrl = localStorage.getItem('campaignUrl');
    if (campaignUrl && campaignUrl != 'undefined') {
      this.transactionFacadeService.campaignGsiLogout();
    } else if (this.txnData?.currentCU?.triggerCUDialogList?.length > 0) {
      let data = {
        ...this.txnData,
        triggerCuId: this.txnData.currentCU.id,
        triggerCUDialogAction: 'BACK',
      };
      this.transactionFacadeService
        .getcustomizedDialogWarning(data)
        .subscribe((res: any) => {
          if (res) {
            if (res && res?.result?.conditionEvaluationResult) {
              if (window.confirm(res?.result?.message)) {
                this.callgoBack();
              }
            } else {
              this.callgoBack();
            }
          }
        });
    } else {
      this.transactionFacadeService.preventAlert.next(true);
      this.callgoBack();
    }
    this.transactionFacadeService.openPopup.next('close');
    if (!this.defiGsi) {
      this.transactionFacadeService.resumePreviousTrans();
    }
    // else
    // {
    //   let gsiObj : any;
    //   gsiObj = {
    //     bookId : this.solutionInfo?.bookId,
    //     gsiId :  this.currentDefiGsi?.id,
    //     gsiMasterId : this.currentDefiGsi?.masterId,
    //   };
    //   this.transactionFacadeService.resumeTransactionSubject.next(gsiObj);
    // }
    this.loader.hide();
  }

  screenHeight: number;
  screenWidth: number;
  isMobile = false;
  isIpadPatroit = false;
  isIpadLandscape = false;

  layouts: any = [
    {
      template: 't-5',
      icon: 'penta-ico',
    },
    {
      template: 't-3',
      icon: 'single-ico',
    },
    {
      template: 't-1',
      icon: 'double-ico',
    },
    {
      template: 't-2',
      icon: 'triple-ico',
    },
    {
      template: 't-4',
      icon: 'quadruple-ico',
    },
    {
      template: 't-6',
      icon: 'hexa-ico',
    },
    {
      template: 't-9',
      icon: 'hexa-ico',
    },
    {
      template: 't-10',
      icon: 'penta-ico',
    },
  ];
  ads: any;
  currentAdImage: number;
  interval: any;
  allGsiOverview$: any;
  publishedGsiList: any;
  totalPublished = 0;
  draftGsiList$: any;
  totalGsis: any;
  totalDrafts = 0;
  searchInput = new Subject();
  searchText = '';
  searchString: string = '';
  entityListDefi: any;
  showEntityList: any[];
  uniqueItems: Set<any> = new Set();
  pageSize = 100;
  totalPages: any = [];
  currentPageNo: number = 0;
  pendingGsiData: any = [];
  tempInfoEntityList: any;
  carnivalGsiList: any = [];
  responsiveOptions: any;
  buttonAction: any;
  mandatoryPhysicalAttributes: number = 0;
  disableSubmitButton: any;
  isSelection: boolean = false;
  details: any = {
    invalid: '',
  };
  isPhysicalLayerVideo: boolean;
  leftActiveWindow: any = false;
  dropdownPageNo: any = 1;
  dropdownpagesize: any = 100;
  autoOpenCamera = true;
  imageCaptureDisable: any;
  disableFormEwa: any;
  tabList: any;
  toggleSideBar: boolean = false;
  toggleGrid: boolean = false;
  gsiName: string = '';

  // ------------- For Pushback - Start ------------------ //
  comment: any = '';
  triggerPushback: boolean = false;
  isPushbackEnabled: boolean = false;
  isPushbackCommentEnabled: boolean = false;
  CUsListForPushback: any = new Array();
  selectedCuIndexForPushback: number = -1;
  callTriggerPushback: number = 0;
  commentsOnCU: any = [];
  chatHistoryPanel: boolean = false;
  isCommentPanelVisible: boolean = false;

  openCommentPanel() {
    this.chatHistoryPanel = true;
    document.body.classList.add('cuPushback-chathistory-body');
  }

  closeCommentPanel() {
    this.chatHistoryPanel = false;
    document.body.classList.remove('cuPushback-chathistory-body');
  }

  backBtnClick(event: any, commentPanel: any, cuListPanel?: any) {
    /* istanbul ignore next */
    if (this.CUsListForPushback?.length === 1) {
      this.selectedCuIndexForPushback = 0;

      // For comment
      /* istanbul ignore next */
      if (this.isPushbackCommentEnabled) {
        commentPanel.toggle(event);
      } else {
        this.triggerPushbackFunc();
      }
    } else if (this.CUsListForPushback?.length > 1) {
      /* istanbul ignore next */
      if (
        commentPanel?.el?.nativeElement?.id === 'topBackBtn' ||
        commentPanel?.el?.nativeElement?.id === 'bottomBackBtn'
      )
        cuListPanel.toggle(event);
    }
  }

  selectPushbackCU(
    event: any,
    commentPanel: any,
    cuListPanel: any,
    pushbackCUIndex: any
  ) {
    this.selectedCuIndexForPushback = pushbackCUIndex;
    // For comment
    /* istanbul ignore next */
    if (this.isPushbackCommentEnabled) {
      /* istanbul ignore next */
      commentPanel?.toggle(event);
    } else {
      /* istanbul ignore next */
      cuListPanel?.hide(true);
      this.triggerPushbackFunc();
    }
  }

  triggerPushbackFunc(event?: any, commentPanel?: any) {
    this.callTriggerPushback = Math.floor(Math.random() * 100) + 1;
    /* istanbul ignore next */
    if (commentPanel) {
      commentPanel.hide(event);
    }
  }

  onCommentPanelHide(cuListDropDownPanel: any) {
    this.selectedCuIndexForPushback = -1;
    cuListDropDownPanel.hide(true);
  }

  // ------------- For Pushback - End ------------------ //

  private cd: ChangeDetectorRef;
  private layoutFacade: LayoutSolutionFacadeService;
  private eventsService: EventsFacadeService;
  private translator: TranslatorService;
  private ocrFacadeService: OCRFacadeService;
  private changeUnitFacadeService: ChangeUnitFacadeService;
  private layoutService: LayoutService;
  public transactionFacadeService: TransactionFacadeService;
  private dialog: MatDialog;
  private gsiFacade: GsiFacadeService;
  private router: Router;
  private sharedFacadeService: SharedFacadeService;
  private renderer: Renderer2;
  private alertService: AlertService;
  private elRef: ElementRef;
  private viewportScroller: ViewportScroller;
  private arService: AugumentedRealityEndpointService;
  private transactionEndpointService: TransactionEndpointService;

  public loader: LoaderService;
  private crossDomainMessagingService: CrossDomainMessagingService;
  constructor(baseInjector: Injector) {
    this.cd = baseInjector.get(ChangeDetectorRef);
    this.layoutFacade = baseInjector.get(LayoutSolutionFacadeService);
    this.eventsService = baseInjector.get(EventsFacadeService);
    this.translator = baseInjector.get(TranslatorService);
    this.ocrFacadeService = baseInjector.get(OCRFacadeService);
    this.changeUnitFacadeService = baseInjector.get(ChangeUnitFacadeService);
    this.layoutService = baseInjector.get(LayoutService);
    this.transactionFacadeService = baseInjector.get(TransactionFacadeService);
    this.dialog = baseInjector.get(MatDialog);
    this.gsiFacade = baseInjector.get(GsiFacadeService);
    this.router = baseInjector.get(Router);
    this.sharedFacadeService = baseInjector.get(SharedFacadeService);
    this.renderer = baseInjector.get(Renderer2);
    this.alertService = baseInjector.get(AlertService);
    this.elRef = baseInjector.get(ElementRef);
    this.viewportScroller = baseInjector.get(ViewportScroller);
    this.arService = baseInjector.get(AugumentedRealityEndpointService);
    this.transactionEndpointService = baseInjector.get(
      TransactionEndpointService
    );
    this.loader = baseInjector.get(LoaderService);
    this.crossDomainMessagingService = baseInjector.get(
      CrossDomainMessagingService
    );
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        numVisible: 2,
        numScroll: 1,
      },
    ];
    // this.detectSolutionStyle();
    this.detectChangeUnitControls();
    this.detectLanguageChange();
    this.getScreenSize();
    this.renderer.removeClass(document.body, 'chartBootLeft');
    // this.changeTheme();
    this.detectEventTypeAHead();
    this.getActionLabel();
    this.detectIsPhysicalLayerVideo();
    this.detectButtonDisableFlag();
    this.detectImageCaptureDisable();
    this.saveSubtransactionalCu();
    this.detectDisableFormEwa();
    this.disableSubmitButtonInteger();
    this.submitButtonDisable();
    this.disableSubmitButtonTimeOnly();
    this.detectSubmitButtonHide();
    this.detectSubmitButtonDisabling();
    this.detectRecordVideoDisable();
    this.detectRecordAudioDisable();
    this.detectMiniumselectionDisable();
    let self = this;
    /* istanbul ignore next */
    document?.addEventListener('visibilitychange', function () {
      /* istanbul ignore next */
      if (document.hidden) {
        self.leftActiveWindow = true;
        //do whatever you want
        // clearInterval(self.interval);
      } else {
        self.leftActiveWindow = false;
        self.startTimeInterval();
        setTimeout(() => {
          self.startTimeIntervalBottomAds();
        }, 500);
      }
      // else {
      //   //do whatever you want
      //
      //   self.startTimeInterval();
      // }
    });
    this.detectSearchInput();
    // this.getSolutions('');
    this.getGSIList();
    this.detectPendingGsiData();
    this.detectArClose();
    this.detectArEntities();
    this.entityDataForTabandAccordion();
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: any) => {
        // As UT is failing commented
        // gtag('event', 'page_view', {
        //   page_path: data?.urlAfterRedirects,
        //   page_name: 'transaction-view',
        // });
      });
    this.getActionLabelPushback();
    this.setLanguage();
  }

  @HostListener('window:resize', ['$event'])
  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.labels = res;
        /* istanbul ignore next */
        this.transactionFacadeService.MessageData = res;
      });
  }
  setLanguage() {
    this.currentLanguageCode = localStorage.getItem('locale');
  }
  // Detect Pending GSI details
  detectPendingGsiData() {
    this.transactionFacadeService.pendingGsiArray$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res && this.isTransactionActive) {
          /* istanbul ignore next */
          let index = res.findIndex(
            (x: any) => x?.gsiId === this.gsi?.changeUnits?.id
          );
          /* istanbul ignore next */
          if (index >= 0) {
            /* istanbul ignore next */
            if (!res?.[index].gsiName) {
              /* istanbul ignore next */
              res[index].gsiName = this.gsi?.name;
              this.transactionFacadeService.updatePendingGsiArray(res);
            } else {
              this.pendingGsiData = res;
            }
          } else if (Array.isArray(res)) {
            this.pendingGsiData = res;
          }
        }
      });
  }

  detectIsPhysicalLayerVideo() {
    /* istanbul ignore next */

    this.eventsService.isPhysicalLayerVideo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.isPhysicalLayerVideo = res;
      });
  }

  detectSubmitButtonHide() {
    this.eventsService.hideSubmitButton$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.currentCuId === this.currentCuId) {
          this.hideSubmitButton = res.hideSubmitButton;
        }
      });
  }

  //For disabling submit button
  detectButtonDisableFlag() {
    this.transactionFacadeService.disbaleSubmitButton$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.disableSubmitButton = res;
      });
  }
  disableSubmitButtonInteger() {
    /* istanbul ignore next */
    this.transactionFacadeService.disbaleSubmitButtonInteger$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.submitDisableInteger = res;
        if (this.details?.invalid) {
          this.details.invalid = false;
        }
      });
  }
  disableSubmitButtonTimeOnly() {
    /* istanbul ignore next */
    this.transactionFacadeService.disbaleSubmitButtonTimeOnly$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (this.details?.invalid) {
          this.details.invalid = false;
        }
      });
  }
  //For disabling submit button
  submitButtonDisable() {
    this.transactionFacadeService.disableSubmitButtonFlag$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.disableSubmitButtonFlag = res;
      });
  }

  //for disabling submit button for Entity Without Attribute
  detectDisableFormEwa() {
    this.transactionFacadeService.disableFormEwa$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.disableFormEwa = res;
      });
  }
  //For disabling submit button
  detectImageCaptureDisable() {
    this.transactionFacadeService.imageCaptureDisable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.imageCaptureDisable = res;
      });
  }
  detectSubmitButtonDisabling() {
    /* istanbul ignore next */
    this.transactionFacadeService.submitButtonDisabled$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        const { isSelected, submitOnSelection } = res;
        /* istanbul ignore next */
        this.isSelection = isSelected;
        /* istanbul ignore next */
        if (!isSelected && submitOnSelection && this.isTransactionActive) {
          let event = new Event('submit');
          this.onSubmit(event);
        }
      });
  }

  changePage(control: string, isdefaultCU?: boolean) {
    if (isdefaultCU) {
      if (control == 'previous' && this.cuStartingPosition > 0) {
        this.cuStartingPosition = --this.cuStartingPosition;
        this.cuEndingPosition = --this.cuEndingPosition;
      }
      if (
        control == 'next' &&
        this.cuEndingPosition < this.gsi?.changeUnits?.solutionLogic?.length
      ) {
        this.cuStartingPosition = ++this.cuStartingPosition;
        this.cuEndingPosition = ++this.cuEndingPosition;
      }
    } else {
      /* istanbul ignore else */
      if (control == 'previous') {
        /* istanbul ignore else */
        if (this.GsicurrentPageNo >= 0) {
          this.GsicurrentPageNo = --this.GsicurrentPageNo;
          // localStorage.setItem('GsicurrentPageNo',JSON.stringify(this.GsicurrentPageNo))
          /* istanbul ignore next */
          this.gsiFacade.getBookGsis(
            this.solutionInfo.bookId,
            this.pageSize,
            this.GsicurrentPageNo,
            this.searchString,
            this.book?.isFromLibraryBook
          );
        }
      }
      /* istanbul ignore else */
      if (control == 'next') {
        this.GsicurrentPageNo = ++this.GsicurrentPageNo;
        localStorage.setItem(
          'GsicurrentPageNo',
          JSON.stringify(this.GsicurrentPageNo)
        );
        /* istanbul ignore next */
        this.gsiFacade.getBookGsis(
          this.solutionInfo.bookId,
          this.pageSize,
          this.GsicurrentPageNo,
          this.searchString,
          this.book?.isFromLibraryBook
        );
      }
    }
  }

  getBookInfo() {
    /* istanbul ignore next */
    this.GsicurrentPageNo = JSON.parse(
      localStorage.getItem('GsicurrentPageNo')
    );
    this.GsicurrentPageNo =
      this.GsicurrentPageNo == null ? 0 : this.GsicurrentPageNo;
    if (this.solutionInfo?.bookId) {
      this.gsiFacade.getBookGsis(
        this.solutionInfo.bookId,
        this.pageSize,
        this.GsicurrentPageNo,
        this.searchString
      );
    }
  }

  getCarnivalBookInfo() {
    this.sharedFacadeService.getBookDetails();
  }

  getGSIList() {
    /* istanbul ignore next */
    if (sessionStorage.bookData && sessionStorage.bookData !== '') {
      this.book = JSON.parse(sessionStorage.bookData);
    }
    this.gsiFacade.gsi$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((gsiResponse: any) => {
        if (this.isTransactionActive) {
          /* istanbul ignore next */
          this.totalGsis = gsiResponse?.total;
          this.nextGsiPage = Math.ceil(this.totalGsis / 10);
          // this.totalGsis > 5 ?
          this.publishedGsiList = gsiResponse?.content;
          this.publishedGsiList?.forEach((element: any) => {
            if (element?.displayName?.length > 10) {
              element.class = 'truncate';
            }
          });
        }
      });
  }

  returnCuIcon(cu: any) {
    let cuId;
    /* istanbul ignore next */
    if (cu?.DATA?.contextualId) {
      /* istanbul ignore next */
      cuId = cu?.DATA?.contextualId?.[0]?.split('_')[1];
    }
    cuId = parseInt(cuId);
    /* istanbul ignore next */
    let cuIconLink = this.gsi?.changeUnits?.design?.cuIcon?.[cuId]?.link?.any;
    return cuIconLink;
  }

  hideprogressBar = false;
  showProgressImg = false;
  showSpan = false;
  forhealthCare: boolean = false;
  forefundzz: boolean = false;
  itemstobeIterated: any[] = [];
  ngOnInit() {
    this.flagLinkLoop = [];
    this.displayCuList = [];
    this.entitySelectionMap = new Map<any, any>();
    this.optionsMap = new Map<any, any>();
    if (
      this.gsi?.changeUnits?.solutionLogic &&
      this.gsi?.changeUnits?.solutionLogic?.length > 1
    ) {
      /*istanbul ignore next*/
      this.findNextTriggerSetCU(this.gsi?.changeUnits?.solutionLogic[0]);
      this.gsi.changeUnits.solutionLogic = this.displayCuList;
      this.displayCuList = [];
      this.flagLinkLoop = [];

      // this.changeUnits?.sort((a: any, b: any) => a?.DATA?.index - b?.DATA?.index);
    }
    this.userInfo = localStorage.getItem('userInfo');
    this.userInfo = JSON.parse(this.userInfo);
    this.flagLinkLoop = [];
    this.displayCuList = [];
    if (
      this.gsi?.changeUnits?.solutionLogic &&
      this.gsi?.changeUnits?.solutionLogic?.length > 1
    ) {
      /*istanbul ignore next*/
      this.findNextTriggerSetCU(this.gsi?.changeUnits?.solutionLogic[0]);
      this.gsi.changeUnits.solutionLogic = this.displayCuList;
      // this.tabList = this.displayCuList;
      this.displayCuList = [];
      this.flagLinkLoop = [];

      // this.changeUnits?.sort((a: any, b: any) => a?.DATA?.index - b?.DATA?.index);
    }
    /* istanbul ignore next */
    if (this.gsi) {
      this.changeTheme();
      this.updateCUIcons();
      /* istanbul ignore next */
      this.getSolutions(this.gsi?.name);
    }
    // if (this.txnData) {
    //   const depDropdownPayload = JSON.parse(JSON.stringify(this.txnData));
    //   this.transactionFacadeService.depDropdownPayloadOnLoad = {
    //     ...depDropdownPayload,
    //     type: 'ON_LOAD',
    //   };
    // }
    this.currentEntityIndex = 0;
    this.layerItem = {
      txnSlotItems: [],
      type: 'physical',
    };
    this.tenantName = localStorage.getItem('TenantName');
    if (
      this.tenantName?.includes('efundzz') ||
      this.tenantName == 'healthcaremx' ||
      this.tenantName == 'healthcaremxstage'
    ) {
      this.hideprogressBar = false;
      this.showProgressImg = true;
    }
    if (this.tenantName == 'callhealth') {
      this.showSpan = true;
    }
    if (this.tenantName?.includes('efundzz')) {
      this.forefundzz = true;
    }
    if (
      (this.tenantName == 'healthcaremx' ||
        this.tenantName == 'healthcaremxstage') &&
      this.cuLayoutTemplate?.template?.includes('progressbar')
    ) {
      this.forhealthCare = true;
    }

    /* istanbul ignore next */
    if (sessionStorage?.bookData && sessionStorage?.bookData !== '') {
      this.book = JSON.parse(sessionStorage.bookData);
    }
    this.getAttributeEvents();
    this.detectAds();
    this.detectBottomAds();
    this.getTriggerCondition();
    this.getBookInfo();
    if (this.isCarnival) {
      this.getCarnivalBookInfo();
      this.gsi['showCu'] = true;
    }
    if (this.isOnboarding) {
      /* istanbul ignore next */
      const bookName =
        this.tenantName == 'b2b' ? 'Admin training' : 'Start your training';
      this.sharedFacadeService.getBookDetails(bookName);
      this.gsi['showCu'] = true;
    }
    /* istanbul ignore next */
    this.detectGSIDesign(this.gsiDesign);
    if (this.isReadOnly) {
      this.setEntitiesByLayer();
    }
    this.changeTheme();
    this.detectCarnivalGsiList();
    /* istanbul ignore next */
    if (this.selectedCu?.uiProperties?.ar_id) {
      this.fetchArEntities(this.selectedCu);
    }
    this.checkForArCu(this.selectedCu);
    this.getHumanCUs();

    // this.templateType = 't-9';
    // this.autoSubmit();
  }

  getHumanCUs() {
    /* istanbul ignore next */
    this.itemstobeIterated = this.gsi?.changeUnits?.solutionLogic?.filter(
      /* istanbul ignore next */
      (item: any) =>
        item.DATA?.agents?.[0]?.agentType === 'human' &&
        item?.DATA?.cuType !== 'GSI'
    );
    this.getActiveIndex();
  }

  getActiveIndex() {
    this.activeCUIndex = 0;
    if (this.selectedCu?.contextualId && this.itemstobeIterated?.length) {
      const gsiSplit = this.selectedCu.contextualId.split('.');
      const cuSplit = gsiSplit?.length > 1 ? gsiSplit[1]?.split('_') : null;
      const referenceCUId = cuSplit && cuSplit?.length > 1 ? cuSplit[1] : null;
      if (
        this.itemstobeIterated?.filter(
          (item) => item.DATA.referencedChangeUnit == referenceCUId
        )?.length > 1
      ) {
        this.activeCUIndex = this.itemstobeIterated?.findIndex(
          (item) =>
            item.DATA.referencedChangeUnit == referenceCUId &&
            item.DATA.id == this.selectedCu.id
        );
      } else {
        this.activeCUIndex = this.itemstobeIterated?.findIndex(
          (item) => item.DATA.referencedChangeUnit == referenceCUId
        );
      }
    }
  }

  toggleSideBarMenu() {
    this.defaultCollapse = !this.defaultCollapse;
    /* istanbul ignore next */
    if (this.cuMatTab) {
      /* istanbul ignore next */
      setTimeout(() => {
        /* istanbul ignore next */
        window.dispatchEvent(new Event('resize'));
      }, 250);
    }
  }
  /* istanbul ignore next */
  toggleColumn() {
    this.toggleGrid = !this.toggleGrid;
    /* istanbul ignore next */
    if (this.cuMatTab) {
      /* istanbul ignore next */
      setTimeout(() => {
        /* istanbul ignore next */
        window.dispatchEvent(new Event('resize'));
      }, 250);
    }
    this.transactionFacadeService.getPdfResize(this.toggleGrid);
  }

  updateCUIcons() {
    /* istanbul ignore next */
    this.gsi?.changeUnits?.solutionLogic?.forEach((changeUnit: any) => {
      /* istanbul ignore next */
      if (this.gsi?.changeUnits?.design?.cuIcon?.[changeUnit?.DATA?.masterId]) {
        /* istanbul ignore next */
        changeUnit.icon = this.gsi?.changeUnits?.design?.cuIcon?.[
          changeUnit?.DATA?.masterId
        ]?.link?.any;
      } else if (this.gsi?.changeUnits?.design?.cuIcon?.default) {
        /* istanbul ignore next */
        changeUnit.icon = this.gsi?.changeUnits?.design?.cuIcon?.default?.link?.any;
      }
    });
  }

  /* istanbul ignore next */
  detectAds() {
    // this.ads = this.resAdsres;
    this.transactionFacadeService.transactionAds$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ads: any) => {
        /* istanbul ignore next */
        if (ads) {
          this.ads = ads;
          let impressionId: any = Guid.create();
          this.ads = { ...this.ads, impressionId: impressionId };
          this.makeImpression(this.ads);
          this.startTimeInterval();
        }
      });
  }
  /* istanbul ignore next */
  detectBottomAds() {
    this.transactionFacadeService.transactionBottomAds$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ads: any) => {
        /* istanbul ignore next */
        if (ads) {
          this.adsBottom = ads;
          let impressionId: any = Guid.create();
          this.adsBottom = { ...this.adsBottom, impressionId: impressionId };
          this.adsBottom.impressionId = impressionId;
          this.makeImpression(this.adsBottom);
          this.startTimeIntervalBottomAds();
        }
      });
  }
  /* istanbul ignore next */
  getAds() {
    if (this.ads?.gsiData) {
      this.assignCorrelationValue();
      let correlatorValue: any = Guid.create();
      /* istanbul ignore next */
      this.ads.gsiData.dateTime = getDTFormat();
      /* istanbul ignore next */
      this.ads.gsiData.correlatorValue = correlatorValue?.value;
      let gsiDataObj = JSON.parse(JSON.stringify(this.ads?.gsiData));
      gsiDataObj.adSlotType = 'Display';
      clearTimeout(this.timeIntervalofAds);
      this.transactionFacadeService.getTransactionAds(gsiDataObj);
    }
  }
  /* istanbul ignore next */
  getBottomAds() {
    if (this.adsBottom?.gsiData) {
      this.assignCorrelationValue();
      let correlatorValue: any = Guid.create();
      /* istanbul ignore next */
      this.adsBottom.gsiData.dateTime = getDTFormat();
      /* istanbul ignore next */
      this.adsBottom.gsiData.correlatorValue = correlatorValue?.value;
      let gsiDataObjText = JSON.parse(JSON.stringify(this.adsBottom?.gsiData));
      gsiDataObjText.adSlotType = 'Text';
      clearTimeout(this.timeIntervalofBottomAds);
      this.transactionFacadeService.getTransactionBottomAds(gsiDataObjText);
    }
  }
  /* istanbul ignore next */
  assignCorrelationValue() {
    if (
      this.ads?.gsiData?.geoLocation &&
      (!this.ads?.gsiData?.geoLocation?.state ||
        !this.ads?.gsiData?.geoLocation?.country)
    ) {
      delete this.ads.gsiData.geoLocation;
      delete this.adsBottom.gsiData.geoLocation;
    }
  }

  /**
   * Ad interval for every 10 seconds
   */
  /* istanbul ignore next */
  startTimeIntervalBottomAds() {
    // let self = this;
    if (
      this.adsBottom?.duration != undefined ||
      this.adsBottom?.duration != null
    ) {
      /* istanbul ignore next */
      clearTimeout(this.timeIntervalofBottomAds);
      this.timeIntervalofBottomAds = setTimeout(() => {
        if (this.adsBottom && !this.leftActiveWindow) {
          this.getBottomAds();
        }
      }, this.adsBottom?.duration * 1000);
    }
  }
  /* istanbul ignore next */
  startTimeInterval() {
    // let self = this;
    if (this.ads?.duration != undefined || this.ads?.duration != null) {
      /* istanbul ignore next */
      clearTimeout(this.timeIntervalofAds);
      this.timeIntervalofAds = setTimeout(() => {
        if (this.ads && !this.leftActiveWindow) {
          this.getAds();
        }
        /* istanbul ignore next */
        // if (self.tempAds?.length === 0) {
        //   self.tempAds = [...self.ads];
        // }
        // /* istanbul ignore next */
        // if (self?.tempAds?.[self.currentAdImage]) {
        //   /* istanbul ignore next */
        //   if (self?.tempAds?.[this.currentAdImage]?.adData?.length) {
        //     /* istanbul ignore next */
        //     self?.tempAds?.[self?.currentAdImage]?.adData?.forEach((ads: any) => {
        //       let impressionId: any = Guid.create();
        //       ads.impressionId = impressionId;
        //     });
        //     /* istanbul ignore next */
        //     self.makeImpression(self?.tempAds?.[self.currentAdImage]);
        //   }
        //   self.tempAds.splice(self.currentAdImage, 1);
        // }
      }, this.ads?.duration * 1000);
    }
  }

  /**
   * to make impression API call when ad shown on UI
   * @param impression
   */
  makeImpression(ad: any, isTopBannerAd?: boolean) {
    // let impressionData: any = [];
    /* istanbul ignore next */
    let adObj = generateImpressionCallObject(ad, {
      userInfo: this.userInfo,
      gsiName: this.gsi?.name,
      localStorage: localStorage,
      transId: this.transactionCU?.transId,
    });
    Object.keys(adObj).forEach(
      (key: any) => adObj[key] === undefined && delete adObj[key]
    );
    // impressionData.push(adObj);
    if (adObj?.solutionName != undefined)
      this.transactionFacadeService.makeImpression(adObj);
  }

  /**
   * to make click API call when clicked on specific Ad
   * @param clickedAd
   */
  adClicked(clickedAd: any, subImg?: any) {
    // let clickData: any = [];
    // let correlatorValue: any = Guid.create();
    let uniqueClickId: any = Guid.create();
    let adObj = generateClickadObject(clickedAd, {
      uniqueClickId: uniqueClickId,
      userInfo: this.userInfo,
      gsiName: this.gsi?.name,
    });
    // clickData.push(adObj);
    this.transactionFacadeService.adClick(adObj);
    // window.location.href= clickedAd.destinationUrl;
    /* istanbul ignore next */
    if (subImg) {
      window.open(subImg?.destinationUrl, '_blank');
    } else {
      window.open(clickedAd?.destinationUrl, '_blank');
    }
  }

  // Elastic Search - FE757
  // Storing GSI data
  gsiData = new Subject<any>();
  gsiData$ = this.gsiData.asObservable();

  detectGSI(gsi: any) {
    this.gsiData.next(gsi); // Store it for for ELastic search // FE757
    /* istanbul ignore next */
    this.openGsiId = gsi?.id;
    this.changeTheme();
    this.getHumanCUs();
  }

  scrollbehindassetforhealthcare: boolean = false;
  submitButtonStyle: any;
  cancelButtonStyle: any;
  saveButtonStyle: any;
  backButtonStyle: any;
  showdefaultSubmitButtons: boolean = true;
  showdefaultCancelButtons: boolean = true;
  showdefaultSaveButtons: boolean = true;
  showdefaultBackButtons: boolean = true;
  saveToolTip: any = 'Save';
  cancelToolTip: any = 'Cancel';
  submitToolTip: any = 'Submit';
  backToolTip: any = 'Back';
  showPanel: boolean = true;
  defaultCollapse: boolean = false;
  detectGSIDesign(gsiDesign: any) {
    /* istanbul ignore else */
    if (gsiDesign && this.selectedCu) {
      //  for(let tab of this.tabList){
      //     if(tab?.Data?.id !== this.selectedCu?.id){
      //      let val =  this.tabList.shift();
      //      this.tabList.push(val);
      //     }
      //     if(tab?.Data?.id == this.selectedCu?.id){
      //       break ;
      //      }
      //   }
      /* istanbul ignore next */
      let id = this.selectedCu?.masterId;
      this.isHideCU = false;
      this.isHideGsi = false;
      /* istanbul ignore next */
      if (gsiDesign?.cuDesignLayout?.[id]?.[0]) {
        this.cuLayoutTemplate = gsiDesign.cuDesignLayout[id][0];
        this.isHideGsi = this.cuLayoutTemplate?.isHideGsi;
        this.isHideCU = this.cuLayoutTemplate?.isHideCU;
        this.tenantName = localStorage.getItem('TenantName');
        if (
          this.tenantName == 'healthcaremx' ||
          this.tenantName == 'healcaremxstage'
        ) {
          this.scrollbehindassetforhealthcare = true;
        }

        this.attributeColor = this.cuLayoutTemplate?.font?.attribute?.color
          ? this.cuLayoutTemplate?.font?.attribute?.color
          : '#000000';
        this.attributeSize = this.cuLayoutTemplate?.font?.attribute?.fontSize
          ? this.cuLayoutTemplate?.font?.attribute?.fontSize
          : 16;
        this.bookColor = this.cuLayoutTemplate?.font?.book?.color
          ? this.cuLayoutTemplate?.font?.book?.color
          : '#000000';
        this.bookSize = this.cuLayoutTemplate?.font?.book?.fontSize
          ? this.cuLayoutTemplate?.font?.book?.fontSize
          : 12;
        //this.getDefaultLayoutConfig();
        this.getAttributeSizes();
        /* istanbul ignore next */
        if (this.cuLayoutTemplate.template == 'default-layout') {
          this.numberOfLayers = this.selectedCu?.layers;
        }

        sessionStorage.setItem('template', this.cuLayoutTemplate?.template);
        // if (this.cuLayoutTemplate.template == 'transaction-book-menu') {
        //   this.cuLayoutTemplate.template = this.templateType = 't-13';
        // }
        /* istanbul ignore next */
        if (this.cuLayoutTemplate.CUListConfig) {
          this.showPanel = JSON.parse(
            this.cuLayoutTemplate.CUListConfig
          )?.showPanel;
          this.defaultCollapse =
            JSON.parse(this.cuLayoutTemplate.CUListConfig)?.defaultCollapse ||
            false;
        }
        if (this.cuLayoutTemplate?.actionLabel) {
          this.showdefaultSubmitButtons = this.showdefaultCancelButtons = this.showdefaultSaveButtons = this.showdefaultBackButtons = false;
          this.buttonAction = JSON.parse(this.cuLayoutTemplate?.actionLabel);
          this.submitButtonStyle = { Submit: this.buttonAction?.Submit };
          this.cancelButtonStyle = { Cancel: this.buttonAction?.Cancel };
          this.saveButtonStyle = { Save: this.buttonAction?.Save };
          this.backButtonStyle = { Back: this.buttonAction?.Back };
          this.saveToolTip = this.saveButtonStyle?.Save?.actionName;
          this.cancelToolTip = this.cancelButtonStyle?.Cancel?.actionName;
          this.submitToolTip = this.submitButtonStyle?.Submit?.actionName;
          this.backToolTip = this.backButtonStyle?.Back?.actionName;
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.format?.length == 0) {
            this.showdefaultSubmitButtons = true;
            // this.submitButtonStyle.Submit.format = 'icon';
          }
          /* istanbul ignore next */
          if (this.cancelButtonStyle?.Cancel?.format?.length == 0) {
            this.showdefaultCancelButtons = true;
            // this.cancelButtonStyle.Cancel.format = 'icon';
          }
          /* istanbul ignore next */
          if (this.saveButtonStyle?.Save?.format?.length == 0) {
            this.showdefaultSaveButtons = true;
            // this.saveButtonStyle.Save.format = 'icon';
          }
          /* istanbul ignore next */
          if (this.backButtonStyle?.Back?.format?.length == 0) {
            this.showdefaultBackButtons = true;
            // this.saveButtonStyle.Save.format = 'icon';
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.format == 'button') {
            this.submitButtonStyle.Submit.svgUrl = '';
          }
          /* istanbul ignore next */
          if (this.cancelButtonStyle?.Cancel?.format == 'button') {
            this.cancelButtonStyle.Cancel.svgUrl = '';
          }
          /* istanbul ignore next */
          if (this.saveButtonStyle?.Save?.format == 'button') {
            this.saveButtonStyle.Save.svgUrl = '';
          }
          /* istanbul ignore next */
          if (this.backButtonStyle?.Back?.format == 'button') {
            this.backButtonStyle.Back.svgUrl = '';
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.format == 'icon') {
            this.submitButtonStyle.Submit.style = '';
          }
          /* istanbul ignore next */
          if (this.cancelButtonStyle?.Cancel?.format == 'icon') {
            this.cancelButtonStyle.Cancel.style = '';
          }
          /* istanbul ignore next */
          if (this.saveButtonStyle?.Save?.format == 'icon') {
            this.saveButtonStyle.Save.style = '';
          }
          /* istanbul ignore next */
          if (this.backButtonStyle?.Back?.format == 'icon') {
            this.backButtonStyle.Back.style = '';
          }

          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.defaultButton) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitDefaultButtonBg',
              this.submitButtonStyle?.Submit?.defaultButton
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.defaultText) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitDefaultTextColor',
              this.submitButtonStyle?.Submit?.defaultText
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.hoverButton) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitHoverButtoBg',
              this.submitButtonStyle?.Submit?.hoverButton
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.hoverText) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitHoverTextColor',
              this.submitButtonStyle?.Submit?.hoverText
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.svgUrl) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitDefaultButtoImg',
              "url('" + this.submitButtonStyle?.Submit?.svgUrl + "')"
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.hoverSvgUrl) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitHoverButtoImg',
              "url('" + this.submitButtonStyle?.Submit?.hoverSvgUrl + "')"
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.borderColor) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitBorderColor',
              this.submitButtonStyle?.Submit?.borderColor
            );
          }
          /* istanbul ignore next */
          if (this.submitButtonStyle?.Submit?.borderColorHover) {
            /* istanbul ignore next */
            this.elRef?.nativeElement?.style?.setProperty(
              '--submitBorderColorHover',
              this.submitButtonStyle?.Submit?.borderColorHover
            );
          }
          if (this.cancelButtonStyle?.Cancel?.defaultButton) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--cancelDefaultButtonBg',
              this.cancelButtonStyle?.Cancel?.defaultButton
            );
          }
          if (this.cancelButtonStyle?.Cancel?.defaultText) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--cancelDefaultTextColor',
              this.cancelButtonStyle?.Cancel?.defaultText
            );
          }
          if (this.cancelButtonStyle?.Cancel?.hoverButton) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--cancelHoverButtoBg',
              this.cancelButtonStyle?.Cancel?.hoverButton
            );
          }
          if (this.cancelButtonStyle?.Cancel?.hoverText) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--cancelHoverTextColor',
              this.cancelButtonStyle?.Cancel?.hoverText
            );
          }
          if (this.cancelButtonStyle?.Cancel?.svgUrl) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--cancelDefaultButtoImg',
              "url('" + this.cancelButtonStyle?.Cancel?.svgUrl + "')"
            );
          }
          if (this.cancelButtonStyle?.Cancel?.hoverSvgUrl) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--cancelHoverButtoImg',
              "url('" + this.cancelButtonStyle?.Cancel?.hoverSvgUrl + "')"
            );
          }

          if (this.cancelButtonStyle?.Cancel?.borderColor) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--CancelBorderColor',
              this.cancelButtonStyle?.Cancel?.borderColor
            );
          }

          if (this.cancelButtonStyle?.Cancel?.borderColorHover) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--CancelBorderColorHover',
              this.cancelButtonStyle?.Cancel?.borderColorHover
            );
          }

          if (this.saveButtonStyle?.Save?.defaultButton) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--saveDefaultButtonBg',
              this.saveButtonStyle?.Save?.defaultButton
            );
          }
          if (this.saveButtonStyle?.Save?.defaultText) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--saveDefaultTextColor',
              this.saveButtonStyle?.Save?.defaultText
            );
          }
          if (this.saveButtonStyle?.Save?.hoverButton) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--saveHoverButtoBg',
              this.saveButtonStyle?.Save?.hoverButton
            );
          }
          if (this.saveButtonStyle?.Save?.hoverText) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--saveHoverTextColor',
              this.saveButtonStyle?.Save?.hoverText
            );
          }
          if (this.saveButtonStyle?.Save?.svgUrl) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--saveDefaultButtoImg',
              "url('" + this.saveButtonStyle?.Save?.svgUrl + "')"
            );
          }
          if (this.saveButtonStyle?.Save?.hoverSvgUrl) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--saveHoverButtoImg',
              "url('" + this.saveButtonStyle?.Save?.hoverSvgUrl + "')"
            );
          }

          if (this.saveButtonStyle?.Save?.borderColor) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--SaveBorderColor',
              this.saveButtonStyle?.Save?.borderColor
            );
          }
          if (this.saveButtonStyle?.Save?.borderColorHover) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--SaveBorderColorHover',
              this.saveButtonStyle?.Save?.borderColorHover
            );
          }

          if (this.backButtonStyle?.Back?.defaultButton) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--backDefaultButtonBg',
              this.backButtonStyle?.Back?.defaultButton
            );
          }
          if (this.backButtonStyle?.Back?.defaultText) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--backDefaultTextColor',
              this.backButtonStyle?.Back?.defaultText
            );
          }
          if (this.backButtonStyle?.Back?.hoverButton) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--backHoverButtoBg',
              this.backButtonStyle?.Back?.hoverButton
            );
          }
          if (this.backButtonStyle?.Back?.hoverText) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--backHoverTextColor',
              this.backButtonStyle?.Back?.hoverText
            );
          }
          if (this.backButtonStyle?.Back?.svgUrl) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--backDefaultButtoImg',
              "url('" + this.backButtonStyle?.Back?.svgUrl + "')"
            );
          }
          if (this.backButtonStyle?.Back?.hoverSvgUrl) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--backHoverButtoImg',
              "url('" + this.backButtonStyle?.Back?.hoverSvgUrl + "')"
            );
          }

          if (this.backButtonStyle?.Back?.borderColor) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--BackBorderColor',
              this.backButtonStyle?.Back?.borderColor
            );
          }
          if (this.backButtonStyle?.Back?.borderColorHover) {
            this.elRef?.nativeElement?.style?.setProperty(
              '--BackBorderColorHover',
              this.backButtonStyle?.Back?.borderColorHover
            );
          }
        }

        if (
          this.cuLayoutTemplate.template == 'layout-asset-6-phy' ||
          this.cuLayoutTemplate.template == 'asset-layout-ast-6-phy'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-ast-6-phy';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-asset-6-info' ||
          this.cuLayoutTemplate.template == 'asset-layout-ast-6-info'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-ast-6-info';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-asset-6-phy-info' ||
          this.cuLayoutTemplate.template == 'asset-layout-ast-6-phyinfo'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-ast-6-phyinfo';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-asset-6-info-phy' ||
          this.cuLayoutTemplate.template == 'asset-layout-ast-6-infophy'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-ast-6-infophy';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-6-asset-phy' ||
          this.cuLayoutTemplate.template == 'asset-layout-6-ast-phy'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-6-ast-phy';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-6-asset-info' ||
          this.cuLayoutTemplate.template == 'asset-layout-6-ast-info'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-6-ast-info';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-6-asset-phy-info' ||
          this.cuLayoutTemplate.template == 'asset-layout-6-ast-phyinfo'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-ast-6-phy';
        }
        if (
          this.cuLayoutTemplate.template == 'layout-6-asset-info-phy' ||
          this.cuLayoutTemplate.template == 'asset-layout-6-ast-infophy'
        ) {
          this.cuLayoutTemplate.template = 'asset-layout-6-ast-infophy';
        }
      } else {
        // this.cuLayoutTemplate = { ...this.tempcuLayoutTemplate };
        /* istanbul ignore next */
        if (
          this.layoutConfigurations?.templateName ===
          'cuLayouts-cupopup-12-default-phy-info'
        ) {
          this.cuLayoutTemplate.font.cuFont.fontSize = 14;
          this.cuLayoutTemplate.font.physicalLayerFont.fontSize = 20;
          this.cuLayoutTemplate.font.informationLayerFont.fontSize = 20;
          this.cuLayoutTemplate.background.color = 'rgba(107,126,145,0.9)';
          this.cuLayoutTemplate.outline.corner = 0;
          this.cuLayoutTemplate.template = this.layoutConfigurations?.templateName;
        }
      }
      /* istanbul ignore next */
      this.elRef?.nativeElement?.style?.setProperty(
        '--submitDefaultButtonBg',
        this.submitButtonStyle?.Submit?.defaultButton || '#ffffff'
      );

      this.elRef?.nativeElement?.style?.setProperty(
        '--submitDefaultTextColor',
        this.submitButtonStyle?.Submit?.defaultText || '#000000'
      );

      this.elRef?.nativeElement?.style?.setProperty(
        '--submitHoverButtoBg',
        this.submitButtonStyle?.Submit?.hoverButton || '#ff657d'
      );
      this.elRef?.nativeElement?.style?.setProperty(
        '--submitHoverTextColor',
        this.submitButtonStyle?.Submit?.hoverText || '#ffffff'
      );

      if (this.submitButtonStyle?.Submit?.svgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--submitDefaultButtoImg',
          "url('" + this.submitButtonStyle?.Submit?.svgUrl + "')"
        );
      }
      if (this.submitButtonStyle?.Submit?.hoverSvgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--submitHoverButtoImg',
          "url('" + this.submitButtonStyle?.Submit?.hoverSvgUrl + "')"
        );
      }
      this.elRef?.nativeElement?.style?.setProperty(
        '--cancelDefaultButtonBg',
        this.cancelButtonStyle?.Cancel?.defaultButton || '#ffffff'
      );

      if (this.cancelButtonStyle?.Cancel?.defaultText) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--cancelDefaultTextColor',
          this.cancelButtonStyle?.Cancel?.defaultText
        );
      }
      //if (this.cancelButtonStyle?.Cancel?.hoverButton) {
      this.elRef?.nativeElement?.style?.setProperty(
        '--cancelHoverButtoBg',
        this.cancelButtonStyle?.Cancel?.hoverButton || '#ffffff'
      );
      //}
      if (this.cancelButtonStyle?.Cancel?.hoverText) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--cancelHoverTextColor',
          this.cancelButtonStyle?.Cancel?.hoverText
        );
      }
      if (this.cancelButtonStyle?.Cancel?.svgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--cancelDefaultButtoImg',
          "url('" + this.cancelButtonStyle?.Cancel?.svgUrl + "')"
        );
      }
      if (this.cancelButtonStyle?.Cancel?.hoverSvgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--cancelHoverButtoImg',
          "url('" + this.cancelButtonStyle?.Cancel?.hoverSvgUrl + "')"
        );
      }
      this.elRef?.nativeElement?.style?.setProperty(
        '--saveDefaultButtonBg',
        this.saveButtonStyle?.Save?.defaultButton || '#ffffff'
      );

      if (this.saveButtonStyle?.Save?.defaultText) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--saveDefaultTextColor',
          this.saveButtonStyle?.Save?.defaultText
        );
      }
      //if (this.saveButtonStyle?.Save?.hoverButton) {
      this.elRef?.nativeElement?.style?.setProperty(
        '--saveHoverButtoBg',
        this.saveButtonStyle?.Save?.hoverButton || '#ffffff'
      );
      //}
      if (this.saveButtonStyle?.Save?.hoverText) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--saveHoverTextColor',
          this.saveButtonStyle?.Save?.hoverText
        );
      }
      if (this.saveButtonStyle?.Save?.svgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--saveDefaultButtoImg',
          "url('" + this.saveButtonStyle?.Save?.svgUrl + "')"
        );
      }
      if (this.saveButtonStyle?.Save?.hoverSvgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--saveHoverButtoImg',
          "url('" + this.saveButtonStyle?.Save?.hoverSvgUrl + "')"
        );
      }
      /* istanbul ignore next */
      this.elRef?.nativeElement?.style?.setProperty(
        '--backDefaultButtonBg',
        this.backButtonStyle?.Back?.defaultButton || '#ffffff'
      );

      this.elRef?.nativeElement?.style?.setProperty(
        '--backDefaultTextColor',
        this.backButtonStyle?.Back?.defaultText || '#000000'
      );

      this.elRef?.nativeElement?.style?.setProperty(
        '--backHoverButtoBg',
        this.backButtonStyle?.Back?.hoverButton || '#ff657d'
      );
      this.elRef?.nativeElement?.style?.setProperty(
        '--backHoverTextColor',
        this.backButtonStyle?.Back?.hoverText || '#ffffff'
      );

      if (this.backButtonStyle?.Back?.svgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--backDefaultButtoImg',
          "url('" + this.backButtonStyle?.Back?.svgUrl + "')"
        );
      }
      if (this.backButtonStyle?.Back?.hoverSvgUrl) {
        this.elRef?.nativeElement?.style?.setProperty(
          '--backHoverButtoImg',
          "url('" + this.backButtonStyle?.Back?.hoverSvgUrl + "')"
        );
      }
      // update selected Cu Icon
      /* istanbul ignore next */
      if (gsiDesign?.cuIcon?.[this.selectedCu?.masterId]) {
        this.selectedCu['cuIcon'] = gsiDesign.cuIcon[this.selectedCu?.masterId];
      }
      // for physical layers icons
      /* istanbul ignore next */
      this.selectedCu?.layers?.[0]?.entityList?.forEach((entity: any) => {
        /* istanbul ignore next */
        if (gsiDesign?.entityIcon?.[entity?.masterId]) {
          entity.design = {
            ...entity.design,
            entityIcon: gsiDesign?.entityIcon[entity?.masterId],
          };
        }
        /* istanbul ignore next */
        if (this.cuLayoutTemplate.font) {
          entity.design = {
            ...entity.design,
            LayerFont: this.cuLayoutTemplate.font.physicalLayerFont,
          };
        }
      });
      // for Infomation layers icons
      /* istanbul ignore next */
      this.selectedCu?.layers?.[1]?.entityList?.forEach((entity: any) => {
        /* istanbul ignore next */
        if (gsiDesign?.entityIcon?.[entity?.masterId]) {
          entity.design = {
            ...entity.design,
            entityIcon: gsiDesign.entityIcon[entity?.masterId],
          };
        }
        /* istanbul ignore next */
        if (this.cuLayoutTemplate.font) {
          entity.design = {
            ...entity.design,
            LayerFont: this.cuLayoutTemplate?.font?.informationLayerFont,
          };
        }
      });
      this.setStyles();
    } else if (gsiDesign?.gsiLayout) {
      // this.templateType = gsiDesign?.gsiLayout?.[0];
      if (
        gsiDesign?.gsiLayout?.[0] == 't-1' ||
        gsiDesign?.gsiLayout?.[0] == 't-10'
      ) {
        this.cuLayoutTemplate.template = 'default-layout';
        if (
          !gsiDesign?.backGround?.link?.any ||
          gsiDesign?.backGround?.link?.any ==
            'https://solutions.nslhub.com/dsd-files-store/content/developer/static/assets/background/bg-1.png'
        ) {
          // this.cuLayoutTemplate.background.color = '#C3DEFF';
        }

        this.cuLayoutTemplate.formColor = '#FFFFFF';
        this.numberOfLayers = this.selectedCu?.layers;
        // this.publishedGsiList = this.gsi?.changeUnits?.solutionLogic;
      }
    }

    this.setInfoLayerDetails();
  }
  phyEntity: any[] = [];
  infoEntity: any[] = [];

  entityDataForTabandAccordion() {
    this.selectedCu?.layers?.forEach((layer: any) => {
      if (layer.type === 'physical') {
        this.phyEntity = layer?.entityList;
      } else if (layer.type === 'information') {
        this.infoEntity = layer?.entityList;
      }
    });
  }

  setInfoLayerDetails() {
    this.numberOfLayers = this.selectedCu?.layers;
    let infoLayer = null;
    if (Array.isArray(this.numberOfLayers)) {
      infoLayer = this.numberOfLayers?.find(
        (layer: any) => layer?.type === 'information'
      );
    }
    if (infoLayer?.entityList) {
      this.infoDisplayNone = !infoLayer.entityList.some(
        (info: any) => !info.isHidden && info.isInPotentiality
      );
    } else {
      this.infoDisplayNone = true;
    }
  }

  setStyles() {
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.outline?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--border-color',
        this.cuLayoutTemplate?.outline?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.outline?.size >= 0) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--border-size',
        this.cuLayoutTemplate?.outline?.size + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.outline?.corner >= 0) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--border-radius-size',
        this.cuLayoutTemplate?.outline?.corner + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.dropShadow?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--dropShadow-color',
        this.cuLayoutTemplate?.dropShadow?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.dropShadow?.xAxis) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--dropShadow-xAxis',
        this.cuLayoutTemplate?.dropShadow?.xAxis + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.dropShadow?.yAxis) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--dropShadow-yAxis',
        this.cuLayoutTemplate?.dropShadow?.yAxis + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.dropShadow?.opacity) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--dropShadow-size',
        this.cuLayoutTemplate?.dropShadow?.opacity / 5 + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.dropShadow?.blur) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--dropShadow-blur',
        this.cuLayoutTemplate?.dropShadow?.blur / 10 + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.background?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--background-color-bg',
        this.cuLayoutTemplate?.background?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.entitySpacing) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--ds-entity-row-gap',
        this.cuLayoutTemplate?.entitySpacing + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.attributeSpacing) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--ds-attribute-row-gap',
        this.cuLayoutTemplate?.attributeSpacing + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.backgroundBlur?.blur) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--backgroundColor-blur',
        this.cuLayoutTemplate?.backgroundBlur?.blur / 10 + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.backgroundBlur?.brightness) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--backgroundColor-brightness',
        this.cuLayoutTemplate?.backgroundBlur?.brightness / 100
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.backgroundBlur?.opacity) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--backgroundColor-opacity',
        this.cuLayoutTemplate?.backgroundBlur?.opacity / 100
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.cuFont?.fontSize) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-cuFont-fontSize',
        this.cuLayoutTemplate?.font?.cuFont?.fontSize + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.bookFont?.fontSize) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-book-fontSize',
        this.cuLayoutTemplate?.font?.bookFont?.fontSize + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.bookFont?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-book-color',
        this.cuLayoutTemplate?.font?.bookFont?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.cuFont?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-cuFont-color',
        this.cuLayoutTemplate?.font?.cuFont?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.background?.opacity) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--background-opacity',
        this.cuLayoutTemplate?.background?.opacity / 100
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.formColor) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--formColor-bg',
        this.cuLayoutTemplate?.formColor
      );
      setPropertyInDivLayout(
        this.elRef,
        '--formColor-bg-darker',
        pSBC(-0.1, this.cuLayoutTemplate?.formColor)
      );
      setPropertyInDivLayout(
        this.elRef,
        '--formColor-bg-darkest',
        pSBC(-0.4, this.cuLayoutTemplate?.formColor)
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.gsiTabs?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-gsiFont-color',
        this.cuLayoutTemplate?.font?.gsiTabs?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.attribute?.color) {
      /* istanbul ignore next */
      this.attributeColor = this.cuLayoutTemplate?.font?.attribute?.color;
      setPropertyInDivLayout(
        this.elRef,
        '--attributeFont-color',
        this.attributeColor
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.background?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--background-color',
        this.cuLayoutTemplate?.background?.color
      );
    }
    if (this.cuLayoutTemplate?.font?.attribute?.fontSize) {
      /* istanbul ignore next */
      this.attributeSize = this.cuLayoutTemplate?.font?.attribute?.fontSize;
      setPropertyInDivLayout(
        this.elRef,
        '--attributeFont-size',
        this.attributeSize + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.gsiTabs?.fontSize) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-gsiFont-size',
        this.cuLayoutTemplate?.font?.gsiTabs?.fontSize + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.informationLayerFont?.fontSize) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-infoLayerFont-size',
        this.cuLayoutTemplate?.font?.informationLayerFont?.fontSize + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.informationLayerFont?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-infoLayerFont-color',
        this.cuLayoutTemplate?.font?.informationLayerFont?.color
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.physicalLayerFont?.fontSize) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-phyLayerFont-size',
        this.cuLayoutTemplate?.font?.physicalLayerFont?.fontSize + 'px'
      );
    }
    /* istanbul ignore next */
    if (this.cuLayoutTemplate?.font?.physicalLayerFont?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--font-phyLayerFont-color',
        this.cuLayoutTemplate?.font?.physicalLayerFont?.color
      );
    }
  }

  /* istanbul ignore next */
  getScreenSize(event?: any) {
    /* istanbul ignore next */
    {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      this.isMobile = this.screenWidth < 767;
      this.isIpadPatroit = this.screenWidth < 991;
      this.isIpadLandscape = this.screenWidth < 1199;
    }
  }

  detectSolutionStyle() {
    this.layoutFacade.layoutBookDesign$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bookDesignData: any) => {
        /* istanbul ignore next */
        if (bookDesignData && bookDesignData.tuples?.length > 0) {
          bookDesignData.tuples?.forEach((prop: any) => {
            this.styleContent[prop.key] = prop.value;
            /* istanbul ignore else */
            if (prop.key === 'layout') {
              this.templateType = prop.value;
            }
          });
          this.fontUrl =
            'assets/fonts/' +
            this.styleContent.fontFamily
              ?.replace(/_/g, '')
              .toLocaleLowerCase() +
            '-font.css';
        }
        this.selectedPrimaryColor(this.styleContent.primaryColor);
      });
  }

  getAttributeEvents() {
    this.eventsService
      .getEvent()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (this.isTransactionActive) {
          setTimeout(() => {
            this.checkForEvents(data);
          }, 500);
        }
      });
  }

  entityDataForTable(event: any) {
    //
    this.tempEntityList = event;
    this.tempEntityListEmit.emit(event);
  }

  infoEntityDataForTable(event: any) {
    this.tempInfoEntityList = event;
    this.tempInfoEntityListEmit.emit(event);
  }

  deepColneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  formatTableData(
    multiEntityObj: any,
    prop: any,
    entityCountObj: any,
    finalArray: any,
    tableEntities: any
  ) {
    let rowObj = {};
    let multiEntArry: any = [];
    /* istanbul ignore else */
    if (multiEntityObj) {
      rowObj = JSON.parse(JSON.stringify(multiEntityObj));
    }
    /* istanbul ignore next */
    multiEntityObj?.nslAttributes.sort((a: any, b: any) => {
      return a.attr_order_num - b.attr_order_num;
    });
    /* istanbul ignore next */
    const attrCount =
      multiEntityObj?.nslAttributes?.length / entityCountObj?.[prop];
    /* istanbul ignore next */
    multiEntityObj?.nslAttributes?.forEach((x: any, index: any) => {
      multiEntArry.push(x);
      if ((index + 1) % attrCount === 0) {
        const entIndex = (index + 1) / attrCount;
        /* istanbul ignore next */
        if (tableEntities?.[entIndex - 1]?.recordId) {
          rowObj['recordId'] = tableEntities[entIndex - 1].recordId;
        } else {
          delete rowObj['recordId'];
        }
        if (tableEntities?.[entIndex - 1]?.txnRecordId) {
          rowObj['txnRecordId'] = tableEntities[entIndex - 1].txnRecordId;
        } else {
          delete rowObj['txnRecordId'];
        }
        rowObj['nslAttributes'] = multiEntArry;
        finalArray.push(rowObj);
        rowObj = {};
        rowObj = JSON.parse(JSON.stringify(multiEntityObj));
        multiEntArry = [];
      }
    });
  }

  private checkEntitieswithCardandTable(
    attributesArray: any,
    layerType: string
  ) {
    /* istanbul ignore next */
    if (layerType === 'triggerCES') return;
    /* istanbul ignore next */
    const entityCountObj =
      layerType === 'physical'
        ? this.find_duplicate_in_array(this.tempEntityList)
        : this.find_duplicate_in_array(this.tempInfoEntityList);
    for (let prop in entityCountObj) {
      /* istanbul ignore next */
      Object.keys(entityCountObj[prop])?.forEach((key) => {
        if (entityCountObj[prop][key] >= 2) {
          let entIndex;
          let multiEntityObj: any;
          for (let i = 0; i < attributesArray?.length; i++) {
            /* istanbul ignore next */
            const ent = attributesArray?.[i];
            /* istanbul ignore next */
            if (ent?.name?.split('$')?.[0] == key && ent?.slot == prop) {
              multiEntityObj = this.deepColneObject(ent);
              /* istanbul ignore next */
              let tableEntities = this.tempEntityList?.filter(
                (x: any) =>
                  x.name === multiEntityObj?.name?.split('$')?.[0] &&
                  x?.slot === multiEntityObj?.slot
              );
              attributesArray.splice(i, 1);
              /* istanbul ignore next */
              this.formatTableData(
                multiEntityObj,
                key,
                entityCountObj?.[prop],
                attributesArray,
                tableEntities
              );
              break;
            } else {
              for (let j = 0; j < ent?.nslAttributes?.length; j++) {
                /* istanbul ignore next */
                if (
                  ent.nslAttributes?.[j]?.type == 'entity' &&
                  ent.nslAttributes?.[j]?.name?.split('$')[0] == key &&
                  ent.nslAttributes[j].ent_index == prop
                ) {
                  /* istanbul ignore next */
                  multiEntityObj = this.deepColneObject(ent.nslAttributes?.[j]);
                  let tableEntities: any;
                  /* istanbul ignore next */
                  this.tempEntityList?.forEach((list: any) => {
                    tableEntities = list?.nslAttributes?.filter(
                      (value: any) =>
                        ent?.nslAttributes?.[j]?.type == 'entity' &&
                        ent?.nslAttributes?.[j]?.name?.split('$')?.[0] ==
                          value?.name
                    );
                  });
                  ent.nslAttributes.splice(j, 1);
                  /* istanbul ignore next */
                  this.formatTableData(
                    multiEntityObj,
                    key,
                    entityCountObj?.[prop],
                    ent.nslAttributes,
                    tableEntities
                  );
                  break;
                } else if (ent.nslAttributes?.[j]?.type == 'entity') {
                  const tempEntityArray: any = [];
                  tempEntityArray.push(ent.nslAttributes[j]);
                  this.checkEntitieswithCardandTable(
                    tempEntityArray,
                    layerType
                  );
                }
              }
            }
          }
        }
      });
    }
  }
  //for unit test to access the method
  checkEntitieswithCardandTablePublic(attributesArray: any, layerType: string) {
    this.checkEntitieswithCardandTable(attributesArray, layerType);
  }

  private find_duplicate_in_array(array: any) {
    const count: any = [];
    /* istanbul ignore next */
    array?.forEach((item: any) => {
      if (
        (item?.configuration && item?.configuration?.type == 'table') ||
        (item?.configuration?.type == 'verticalTable' &&
          item?.configuration?.configurationData?.advanceTableFeatures
            ?.multiRowsVerticalTable) ||
        item?.configuration?.type == 'card' ||
        item?.configuration?.type == 'cardWithTemplate' ||
        item?.configuration?.type == 'widgets' ||
        item?.configuration?.type == 'tableWithLabels'
      ) {
        /* istanbul ignore next */
        if (
          count?.[item?.ent_index] &&
          count?.[item?.ent_index][item?.name.split('$')[0]]
        ) {
          count[item.ent_index][item.name.split('$')[0]] += 1;
          return;
        }
        count[item.ent_index] = {
          ...count[item.ent_index],
          [item.name.split('$')[0]]: 1,
        };
      } else if (item?.type === 'entity') {
        /* istanbul ignore next */
        item?.nslAttributes?.forEach((attr: any) => {
          if (
            (attr?.type == 'entity' &&
              attr?.configuration &&
              attr?.configuration?.type == 'table') ||
            (attr?.configuration?.type == 'verticalTable' &&
              attr?.configuration?.configurationData?.advanceTableFeatures
                ?.multiRowsVerticalTable) ||
            attr?.configuration?.type == 'card' ||
            attr?.configuration?.type == 'cardWithTemplate' ||
            attr?.configuration?.type == 'widgets' ||
            attr?.configuration?.type == 'tableWithLabels'
          ) {
            /* istanbul ignore next */
            if (
              count?.[attr?.ent_index] &&
              count?.[attr?.ent_index][attr.name.split('$')[0]]
            ) {
              count[attr.ent_index][attr.name.split('$')[0]] += 1;
              return;
            }
            count[attr.ent_index] = {
              ...count[attr.ent_index],
              [attr.name.split('$')[0]]: 1,
            };
          } else if (attr?.type == 'entity') {
            let countObj = this.find_duplicate_in_array(attr.nslAttributes);
            countObj.forEach((x: any) => {
              count[attr.ent_index] = x;
            });
          }
        });
      }
    });
    return count;
  }

  getAttributeSizes() {
    [
      ...(this.physicalEntities || []),
      ...(this.informationEntities || []),
    ]?.forEach((item: any, index: any) => {
      const layerName = this.physicalEntities?.length > index ? 'PL' : 'IL';
      const physicalLen = this.physicalEntities?.length
        ? this.physicalEntities?.length
        : 0;
      const path = layerName + '_' + (item?.slot - 1) + '_' + item.masterId;
      const cuDesign = this.cuLayoutTemplate?.entityDesign;
      if (cuDesign?.[path]) {
        if (cuDesign?.[path]?.isHideEntity) {
          item.configuration = {
            ...item.configuration,
            hideEntityLabel: cuDesign?.[path]?.isHideEntity,
          };
        }
        item.isTabView = cuDesign?.[path]?.isTabView || true;
        item.configuration = {
          ...item.configuration,
          entityClass:
            cuDesign?.[path]?.size === 'LARGE'
              ? 'col-lg-12'
              : cuDesign?.[path]?.size === 'SMALL'
              ? 'col-lg-6'
              : null,
        };
        item?.nslAttributes?.forEach((attr: any) => {
          if (cuDesign?.[path]?.attributeConfig?.[attr.name]) {
            this.getNestedAttributes(
              attr,
              cuDesign?.[path]?.attributeConfig?.[attr.name]
            );
          }
        });
      }
      item.nslAttributes?.forEach((attr: any) => {
        this.setUIControlSizes(attr);
      });
    });
  }

  getNestedAttributes(attr: any, existingData: any) {
    if (attr?.nslAttributes?.length > 0) {
      if (existingData?.isHideEntity) {
        attr.configuration = {
          ...attr.configuration,
          hideEntityLabel: existingData?.isHideEntity,
        };
      }
      attr.isTabView = existingData?.isTabView ?? true;
      attr.configuration = {
        ...attr.configuration,
        entityClass:
          existingData?.size === 'LARGE'
            ? 'col-12'
            : existingData?.size === 'SMALL'
            ? 'col-6'
            : null,
      };
      attr.nslAttributes.forEach((innerAttr: any) => {
        if (existingData?.attributeConfig?.[innerAttr.name]) {
          this.getNestedAttributes(
            innerAttr,
            existingData?.attributeConfig?.[innerAttr.name]
          );
        }
      });
    } else {
      attr.attrClass =
        this.isIpadPatroit || existingData?.attrSize === 'LARGE'
          ? 'col-12'
          : existingData?.attrSize === 'MEDIUM'
          ? 'col-6'
          : existingData?.attrSize === 'SMALL'
          ? 'col-4'
          : null;
    }
  }

  setUIControlSizes(attr: any) {
    if (!attr.attrClass) {
      attr.attrClass = '';
    }
    attr.attrClass +=
      this.cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE'
        ? ' size-extra-large-uicontrols'
        : this.cuLayoutTemplate?.uiControlSize === 'MEDIUM'
        ? ' size-medium-uicontrols'
        : this.cuLayoutTemplate?.uiControlSize === 'SMALL'
        ? ' size-small-uicontrols'
        : '';
  }

  getLayerType(layerType: string) {
    if (layerType == 'physical') {
      return 'PL';
    } else if (layerType == 'information') {
      return 'IL';
    } else if (layerType == 'triggerCES') {
      return 'triggerCES';
    }
  }

  /**
   * Checks for event in the eventsList of the GSI and if exists make an event API call
   * @param data event data
   */

  checkForEvents(data: any) {
    //current scroll position
    /* istanbul ignore next */
    this.triggerSlotConfiguration(data);
    this.scrollPosition = this.viewportScroller.getScrollPosition();
    /* istanbul ignore next */
    if (data?.eventType === 'ON_SELECT' && this.txnData?.currentCU) {
      /* istanbul ignore next */
      if (
        !this.selectedCu ||
        this.selectedCu?.id !== this.txnData?.currentCU?.id
      ) {
        this.selectedCu = JSON.parse(JSON.stringify(this.txnData?.currentCU));
      }
    }
    /* istanbul ignore next */
    if (
      this.selectedCu &&
      this.selectedCu?.eventCUList &&
      this.selectedCu?.eventCUList?.length > 0
    ) {
      /*istanbul ignore next*/
      let index = this.selectedCu.eventCUList.findIndex((event: any) => {
        /*istanbul ignore next*/
        if (event?.sourceChangeDrivers?.length > 0) {
          /*istanbul ignore next*/
          let i = event?.sourceChangeDrivers?.findIndex(
            (id: any) => id?.changeDriverId == data?.attributeId
          );
          /*istanbul ignore next*/
          if (i !== -1) {
            /*istanbul ignore next*/
            return event.eventType === data.eventType;
          }
        } else if (event?.slotContextualId) {
          let flag = false;
          /*istanbul ignore next*/
          this.selectedCu?.layers?.forEach((layer: any) => {
            /*istanbul ignore next*/
            if (
              event?.slotContextualId?.split('.')?.[0] ===
              this.getLayerType(layer?.type)
            ) {
              /*istanbul ignore next*/
              layer?.entityList?.forEach((entity: any) => {
                /*istanbul ignore next*/
                if (
                  Number(
                    event?.slotContextualId?.split('.')?.[1]?.split('SL')?.[1]
                  ) === entity?.slot &&
                  entity?.slot === data.slotNumber
                ) {
                  flag = true;
                }
              });
            }
          });
          /*istanbul ignore next*/
          return (
            event.changeDriverId === data.attributeId &&
            event.eventType === data.eventType &&
            flag
          );
        } else {
          /*istanbul ignore next*/
          return (
            event.changeDriverId === data.attributeId &&
            event.eventType === data.eventType
          );
        }
      });
      if (
        index !== -1 &&
        this.selectedCu?.eventCUList[index]?.sourceChangeDrivers?.length > 0
      ) {
        let count: number = 0;
        this.selectedCu?.eventCUList[index]?.sourceChangeDrivers?.forEach(
          (changeDriver: any) => {
            this.selectedCu?.layers?.forEach((layer: any) => {
              if (
                changeDriver?.slotContextualId?.split('.')?.[0] ===
                this.getLayerType(layer?.type)
              ) {
                layer?.entityList?.forEach((entity: any) => {
                  if (
                    Number(
                      changeDriver?.slotContextualId
                        ?.split('.')?.[1]
                        ?.split('SL')?.[1]
                    ) === entity?.slot
                  ) {
                    entity?.nslAttributes?.forEach((attr: any) => {
                      if (
                        attr.id === changeDriver?.changeDriverId &&
                        (attr?.values[0] !== '' || attr?.isOptional)
                      ) {
                        count += 1;
                      }
                    });
                  }
                });
              }
            });
          }
        );
        if (
          count !==
          this.selectedCu?.eventCUList[index]?.sourceChangeDrivers?.length
        )
          index = -1;
      }

      /* istanbul ignore next */
      if (index !== -1) {
        let transactionData = JSON.parse(JSON.stringify(this.txnData));
        /**remove nested entities for onfocus/onchange/onload */
        /* istanbul ignore next */
        transactionData?.currentCU?.layers?.forEach((layer: any) => {
          /* istanbul ignore next */
          this.checkEntitieswithCardandTable(layer.entityList, layer.type);
        });
        let triggerEntIndex: number = -1;
        let event = transactionData?.currentCU?.eventCUList[index];
        triggerEntIndex = Number(
          event?.slotContextualId?.split('.')?.[1]?.split('SL')?.[1]
        );
        let originalData = JSON.parse(JSON.stringify(transactionData));
        if (
          !event?.slotContextualId ||
          (triggerEntIndex != -1 && triggerEntIndex == data?.slotNumber)
        ) {
          // let originalData = JSON.parse(JSON.stringify(transactionData));
          setTimeout(() => {
            /* istanbul ignore next */
            const gsiId = this.selectedCu?.eventCUList?.[index]?.gsiId;
            const eventCuId = this.selectedCu?.eventCUList?.[index]?.id;
            transactionData.gsiId = gsiId;
            transactionData.eventCuId = eventCuId;
            /* istanbul ignore next */
            if (data?.eventType === 'ON_SELECT') {
              transactionData.gsiId = data?.gsiId;
            }
            /* istanbul ignore next */
            transactionData?.currentCU?.layers?.forEach((layer: any) => {
              layer.entityList = this.renameEntity(
                layer.entityList,
                data,
                layer.type,
                true
              );
            });
            let masterTxnData = JSON.parse(JSON.stringify(transactionData));

            /* istanbul ignore next */
            transactionData?.currentCU?.layers?.forEach((layer: any) => {
              /* istanbul ignore next */
              if (layer.type === 'physical') {
                if (data.onTop) {
                  layer.entityList = this.setSelectedRows(
                    layer.entityList,
                    data
                  );
                } else {
                  layer.entityList = this.removeEntity(layer.entityList, data);
                }
              }
              this.resetEntityorder(layer.entityList);
            });

            /* istanbul ignore next */
            transactionData?.currentCU?.layers?.forEach((layer: any) => {
              layer.entityList = this.renameEntity(
                layer.entityList,
                data,
                layer.type,
                false
              );
            });
            if (transactionData?.currentCU?.eventCUList?.length > 0) {
              // this.setEventsCuList(transactionData);
            }
            if (this.selectedCu?.reservedCUType == 'NSL_EQL_IN_Memory') {
              if (
                this.transactionFacadeService.selectedTableRecordList?.length >
                0
              ) {
                /* istanbul ignore next */
                this.transactionFacadeService.selectedTableRecordList?.forEach(
                  (obj: any) => {
                    /* istanbul ignore next */
                    let tableRows: any = [];
                    let temptableEntityList = transactionData.currentCU.layers[0].entityList.filter(
                      (ent: any) => ent.slot == 3
                    );
                    /* istanbul ignore else */
                    temptableEntityList?.forEach((ele: any, index: any) => {
                      obj.selectedTransactions.forEach((o3: any) => {
                        let result = ele.nslAttributes.filter((o1: any) =>
                          o3.nslAttributes.some(
                            (o2: any) => o1.attr_order_num === o2.attr_order_num
                          )
                        );
                        if (result.length > 0) {
                          tableRows.push(ele);
                        }
                      });
                    });
                    if (
                      this.transactionFacadeService
                        .selectedTableAtrributeRecords?.[1]?.length > 0
                    ) {
                      this.transactionFacadeService.selectedTableAtrributeRecords[1] = this.transactionFacadeService.selectedTableAtrributeRecords[1].concat(
                        tableRows
                      );
                    } else {
                      this.transactionFacadeService.selectedTableAtrributeRecords[1] = tableRows;
                    }
                  }
                );
              }
            }

            //Triggered in case of normal GSI event
            /* istanbul ignore next */
            if (this.selectedCu?.eventCUList?.[index]?.isGsi) {
              /* istanbul ignore next */
              this.eventsService.changeUnitEvents(
                transactionData,
                this.selectedCu?.id,
                data,
                masterTxnData.currentCU?.layers,
                originalData.currentCU?.layers
              );
            } else if (
              this.selectedCu?.eventCUList?.[index]?.eventExpressions?.length >
              0
            ) {
              this.eventsService.getEventExpressionDetails(
                transactionData,
                this.selectedCu?.eventCUList?.[index]?.eventType,
                this.selectedCu?.eventCUList?.[index]?.changeDriverId,
                this.selectedCu?.id,
                data
              );
            } else {
              // Triggered in case of dependent dropdown attributes
              /*istanbul ignore next*/
              let attrType = this.currentEventAttributeType(data?.attributeId);
              if (attrType == 'typeahead') {
                let attrValue = this.getAttrValue(data?.attributeId);
                this.eventsService.getDependentDropdownDetails(
                  transactionData,
                  this.selectedCu?.eventCUList?.[index]?.eventType,
                  this.selectedCu?.eventCUList?.[index]?.changeDriverId,
                  data,
                  this.dropdownPageNo,
                  500,
                  attrValue
                );
              } else {
                this.eventsService.getDependentDropdownDetails(
                  transactionData,
                  this.selectedCu?.eventCUList?.[index]?.eventType,
                  this.selectedCu?.eventCUList?.[index]?.changeDriverId,
                  data,
                  this.dropdownPageNo,
                  this.dropdownpagesize
                );
              }
              this.setAttributesGotValueWithOnChange(data);
              this.transactionFacadeService.depDropdownPayloadOnChange = {
                ...transactionData,
                type: 'ON_CHANGE',
              };
              this.transactionFacadeService.cuData = transactionData;
            }
            this.routeEntity = undefined;
            this.nestedEntity = undefined;
          }, 200);
        }
      }
    }
  }

  setAttributesGotValueWithOnChange(data: any) {
    if (this.selectedCu?.eventCUList?.length > 0) {
      this.selectedCu?.eventCUList?.forEach((ele: any) => {
        if (
          ele?.changeDriverId == data?.attributeId &&
          ele?.eventType == 'ON_CHANGE '
        ) {
          ele.referenceEntityInfo?.attributeReferences?.forEach(
            (refAtt: any) => {
              let targetAtributeId = refAtt?.targetAttributeId?.split('.');
              targetAtributeId = targetAtributeId[
                targetAtributeId?.length - 1
              ].slice(2);
              this.transactionFacadeService.isOnChangeEventTriggered[
                targetAtributeId
              ] = true;
            }
          );
        }
      });
    }
  }
  findNextTriggerSetCU(triggerCu: any): void {
    /*istanbul ignore next*/
    let cuFoundInTarget = this.displayCuList.findIndex(
      (x: any) =>
        x?.DATA?.name === triggerCu?.DATA?.name &&
        x?.DATA?.index === triggerCu?.DATA?.index
    );
    /*istanbul ignore else*/
    if (cuFoundInTarget === -1) {
      this.displayCuList.push(triggerCu);
    }
    // loop end if the cu doesnot have nextTriggerSet
    /*istanbul ignore next*/
    triggerCu?.DATA?.nextTriggerSet?.forEach((cu: any) => {
      let index = this.flagLinkLoop?.findIndex((x: any) => x === cu?.nextCUId);
      /*istanbul ignore else*/
      if (index === -1) {
        this.flagLinkLoop.push(cu.nextCUId);
        this.findActualCu(cu);
      }
    });
  }

  findActualCu(ChangeUnit: any): void {
    /*istanbul ignore next*/
    let cu = this.gsi?.changeUnits?.solutionLogic.find((cu: any) => {
      return cu?.DATA?.id === ChangeUnit.nextCUId;
    });
    /*istanbul ignore next*/
    let cuFoundInTarget = this.displayCuList?.findIndex(
      (x: any) =>
        x?.DATA?.name === cu?.DATA?.name && x?.DATA?.index == cu?.DATA?.index
    );
    /*istanbul ignore else*/
    if (cuFoundInTarget === -1) {
      this.displayCuList.push(cu);
    }
    this.findNextTriggerSetCU(cu);
  }
  getAttrValue(attributeId: any) {
    let attrValue = '';
    const [physicalLayer] = this.selectedCu?.layers?.filter(
      (layer: any) => layer?.type === 'physical'
    );
    let physicalEntities = physicalLayer?.entityList;

    physicalEntities?.forEach((entity: any) => {
      entity.nslAttributes?.forEach((attr: any) => {
        /*istanbul ignore else*/
        if (attr.id == attributeId) attrValue = attr.values.toString();
      });
    });

    return attrValue;
  }
  currentEventAttributeType(attributeId: any): string {
    let attrType = '';
    const physicalLayer = this.selectedCu?.layers?.find(
      (layer: any) => layer?.type === 'physical'
    );
    let physicalEntities = physicalLayer?.entityList || [];

    physicalEntities?.forEach((entity: any) => {
      entity?.nslAttributes?.forEach((attr: any) => {
        if (attr.id == attributeId && attr.uiElement == 'typeahead') {
          attrType = 'typeahead';
        }
      });
    });

    return attrType;
  }

  /* trigger an event when there is a condition to an Attribute*/
  getTriggerCondition() {
    this.eventsService
      .getTriggerEvent()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (this.isTransactionActive) {
          this.loader.show();
          setTimeout(() => {
            this.scrollPosition = this.viewportScroller.getScrollPosition();
            this.checkForTriggerConditionEvent(data);
          }, 500);
        }
      });
  }

  checkForTriggerConditionEvent(data: any) {
    //current scroll position
    let transactionData = JSON.parse(JSON.stringify(this.txnData));
    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      this.checkEntitieswithCardandTable(layer.entityList, layer.type);
    });
    let originalData = JSON.parse(JSON.stringify(transactionData));

    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      layer.entityList = this.renameEntity(
        layer.entityList,
        data,
        layer.type,
        true
      );
    });

    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      if (layer.type === 'physical') {
        layer.entityList = this.removeEntity(layer.entityList, data);
      }
      this.resetEntityorder(layer.entityList);
    });
    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      layer.entityList = this.renameEntity(
        layer.entityList,
        data,
        layer.type,
        false
      );
    });
    if (transactionData?.currentCU?.eventCUList?.length > 0) {
      // this.setEventsCuList(transactionData);
    }

    setTimeout(() => {
      this.transactionFacadeService.setAttributeDataForEvents({
        attributeData: data,
        txnData: transactionData,
      });
      this.transactionFacadeService.attrConditionalPotentiality(
        transactionData,
        data,
        originalData.currentCU?.layers
      );
      this.routeEntity = undefined;
      this.nestedEntity = undefined;
    }, 200);
  }

  //reset the order for entities
  private resetEntityorder(entityList: any) {
    entityList.sort((a: any, b: any) => {
      return a.slot - b.slot;
    });
  }
  //for unit test to access the method
  resetEntityorderPublic(entityList: any) {
    this.resetEntityorder(entityList);
  }

  /**Rename the entity name */
  public renameEntity(
    data: any,
    entityDetails: any,
    layerName: any,
    isCheck: boolean,
    triggerMultiEntity?: boolean
  ) {
    /* istanbul ignore next */
    if (entityDetails?.isTableConfig) {
      let tableEntities: any = [];
      tableEntities = data?.filter(
        (ent: any) => ent.name == entityDetails?.entityName
      );
      /* if(tableEntities.length == 0){
        data.forEach((list: any) => {
          tableEntities = list.nslAttributes.filter(
            (value: any) => value.type == 'entity' && value.name == entityDetails.entityName
          );
        });
      } */
      /* istanbul ignore next */
      let attrCount = tableEntities
        ? tableEntities?.[0]?.nslAttributes?.length
        : 0;
      /* istanbul ignore next */
      let entIndex = attrCount
        ? Math.trunc(
            parseInt(entityDetails?.attrName?.split('$')?.[1]) / attrCount
          )
        : 0;
      /* istanbul ignore next */
      if (
        tableEntities &&
        tableEntities?.length > 0 &&
        isCheck &&
        tableEntities?.[entIndex] &&
        !triggerMultiEntity
      ) {
        this.routeEntity = JSON.parse(
          JSON.stringify(tableEntities?.[entIndex])
        );
        this.routeEntity.name = this.routeEntity?.name?.split('$')?.[0];
      }
      /* istanbul ignore next */
      data?.forEach((element: any) => {
        element.name = element?.name?.split('$')?.[0];
        /* istanbul ignore next */
        let tableEntities = element.nslAttributes?.filter(
          (value: any) => value.type == 'entity'
        );
        if (element.nslAttributes && element.nslAttributes?.length > 0) {
          this.renameNestedentity(
            tableEntities,
            entityDetails,
            layerName,
            isCheck
          );
          element.nslAttributes = element.nslAttributes
            ?.map((el1: any) => {
              if (el1?.values && el1?.values?.[0] === '') {
                el1.values = [];
              }
              if (!el1?.isInPotentiality) {
                el1 = null;
              }
              return el1;
            })
            ?.filter((el1: any) => el1 !== null);
          if (!element.isInPotentiality) {
            element.nslAttributes = null;
          }
        }
      });
    } else {
      /* istanbul ignore next */
      data?.forEach((element: any) => {
        if (element.name == entityDetails?.entityName && !triggerMultiEntity) {
          this.routeEntity = JSON.parse(JSON.stringify(element));
          /* istanbul ignore next */
          this.routeEntity.name = this.routeEntity?.name?.split('$')?.[0];
        }
        /* istanbul ignore next */
        element.name = element?.name?.split('$')?.[0];
        /* istanbul ignore next */
        if (element?.nslAttributes && element?.nslAttributes?.length > 0) {
          element.nslAttributes = element.nslAttributes
            ?.map((el1: any) => {
              if (el1?.values && el1?.values?.[0] === '') {
                el1.values = [];
              }
              if (el1.type == 'entity') {
                this.renameNestedentity(el1, entityDetails, layerName, isCheck);
              }
              if (!el1?.isInPotentiality) {
                el1 = null;
              }
              return el1;
            })
            ?.filter((el1: any) => el1 !== null);
          if (!element.isInPotentiality) {
            element.nslAttributes = null;
          }
        }
      });
    }
    return data;
  }

  renamingNestedEntity(
    el1: any,
    entityDetails: any,
    layerName: any,
    isCheck: any
  ) {
    this.renameNestedentity(el1, entityDetails, layerName, isCheck);
  }

  /**Rename the nested entity name */
  private renameNestedentity(
    element: any,
    entityDetails: any,
    layerName: any,
    isCheck: any
  ) {
    /* istanbul ignore next */
    if (entityDetails?.isTableConfig && layerName === 'physical') {
      let tableEntities: any = element?.filter(
        (ele: any) => ele.name === entityDetails?.entityName
      );
      /* istanbul ignore next */
      let attrCount = tableEntities
        ? tableEntities?.[0]?.nslAttributes?.length
        : 0;
      /* istanbul ignore next */
      let entIndex = attrCount
        ? Math.trunc(
            parseInt(entityDetails?.attrName?.split('$')?.[1]) / attrCount
          )
        : 0;
      /* istanbul ignore next */
      if (tableEntities && tableEntities?.length > 0 && isCheck) {
        /* istanbul ignore next */
        if (entityDetails?.ent_index) {
          if (
            tableEntities?.[entIndex] &&
            tableEntities?.[entIndex].ent_index == entityDetails?.ent_index
          ) {
            this.nestedEntity = JSON.parse(
              JSON.stringify(tableEntities?.[entIndex])
            );
            /* istanbul ignore next */
            tableEntities[entIndex].name = tableEntities[entIndex].name.split(
              '$'
            )?.[0];
          }
        } else if (tableEntities?.[entIndex]) {
          this.nestedEntity = JSON.parse(
            JSON.stringify(tableEntities?.[entIndex])
          );
          /* istanbul ignore next */
          tableEntities[entIndex].name = tableEntities[entIndex].name.split(
            '$'
          )?.[0];
        }
        if (this.nestedEntity) {
          this.nestedEntity.name = this.nestedEntity?.name?.split('$')?.[0];
        }
      }
      element?.forEach((ele: any) => {
        /* istanbul ignore next */
        ele.name = ele?.name?.split('$')?.[0];
        if (ele.nslAttributes && ele.nslAttributes?.length > 0) {
          ele.nslAttributes = ele?.nslAttributes
            ?.map((el1: any) => {
              if (el1?.values && el1?.values?.[0] === '') {
                el1.values = [];
              }
              if (!el1?.isInPotentiality) {
                el1 = null;
              }
              return el1;
            })
            ?.filter((el1: any) => el1 !== null);
          if (!element.isInPotentiality) {
            element.nslAttributes = null;
          }
        }
      });
    } else {
      /* istanbul ignore next */
      if (element?.name == entityDetails?.entityName) {
        /* istanbul ignore next */
        this.nestedEntity = JSON.parse(JSON.stringify(element));
        /* istanbul ignore next */
        this.nestedEntity.name = this.nestedEntity?.name?.split('$')?.[0];
      }
      /* istanbul ignore next */
      if (element?.name) {
        /* istanbul ignore next */
        element.name = element?.name?.split('$')?.[0];
      }
      /* istanbul ignore next */
      if (element?.nslAttributes) {
        element.nslAttributes = element?.nslAttributes
          ?.map((el1: any) => {
            if (el1?.values && el1?.values?.[0] === '') {
              el1.values = [];
            }
            if (el1.type == 'entity') {
              this.renamingNestedEntity(el1, entityDetails, layerName, isCheck);
            }
            if (!el1?.isInPotentiality) {
              el1 = null;
            }
            return el1;
          })
          ?.filter((el1: any) => el1 !== null);
      }
      if (!element?.isInPotentiality && element?.nslAttributes) {
        element.nslAttributes = null;
      }
    }
  }

  removeEntityPublic(
    data: any,
    entityDetails: any,
    nestedMultiEntity?: boolean
  ) {
    this.removeEntity(data, entityDetails, nestedMultiEntity);
  }
  /**Remove the entities from the txn data if is-mutlti is true*/
  private removeEntity(
    data: any,
    entityDetails: any,
    nestedMultiEntity?: boolean
  ) {
    if (nestedMultiEntity) {
      /* istanbul ignore next */
      let multiEntities = data?.filter((entity: any) => {
        return (
          entity.name == entityDetails.entityName &&
          entity.txnRecordId == entityDetails.txnRecordId
        );
      });
      let nonMultiEntities = data?.filter((entity: any) => {
        /* istanbul ignore next */
        return (
          entity.name != entityDetails.entityName &&
          entity.txnRecordId != entityDetails.txnRecordId
        );
      });
      /* istanbul ignore next */
      if (multiEntities?.length > 0) {
        data = [];
        data = [...multiEntities, ...nonMultiEntities];
      }
    } else if (this.routeEntity) {
      data = data?.filter((ent: any) => {
        /* istanbul ignore next */
        return ent.slot !== this.routeEntity?.slot;
      });
      data.push(this.routeEntity);
    } else {
      /* istanbul ignore next */
      data?.forEach((element: any) => {
        let nestedEntities = element.nslAttributes?.filter((ent: any) => {
          return ent.type == 'entity';
        });

        let nestedEventEntities = nestedEntities?.filter((el: any) => {
          /* istanbul ignore next */
          return (
            el.name == this.nestedEntity?.name &&
            el.slot == this.nestedEntity?.slot
          );
        });
        let nestedWithoutEventEntities = nestedEntities?.filter((el: any) => {
          /* istanbul ignore next */
          return (
            el.name !== this.nestedEntity?.name ||
            el.slot !== this.nestedEntity?.slot
          );
        });

        /* istanbul ignore next */
        if (nestedEventEntities?.length > 0) {
          /* istanbul ignore next */
          nestedEventEntities = this.nestedEntity
            ? [this.nestedEntity]
            : undefined;
          /* istanbul ignore next */
          element.nslAttributes = element?.nslAttributes?.filter((ent: any) => {
            return ent.type != 'entity';
          });
          /* istanbul ignore next */
          if (nestedEventEntities) {
            element.nslAttributes = [
              ...element.nslAttributes,
              ...nestedEventEntities,
            ];
          }
          /* istanbul ignore next */
          if (nestedWithoutEventEntities) {
            element.nslAttributes = [
              ...element.nslAttributes,
              ...nestedWithoutEventEntities,
            ];
          }
        } else {
          /* istanbul ignore next */
          element.nslAttributes?.forEach((el1: any) => {
            if (el1.type == 'entity') {
              this.removeNestedentity(el1, entityDetails);
            }
          });
        }
      });
    }
    return data;
  }

  setSelectedRows(data: any, entityDetails: any) {
    let selectedRows = this.transactionFacadeService.selectedTransactions;
    selectedRows = selectedRows.filter(
      (row: any) => row.entityName === entityDetails?.entityName
    );
    let eventEntities = data?.filter((el: any) => {
      /* istanbul ignore next */
      return (
        el.name == entityDetails?.entityName.split('$')?.[0] &&
        el.slot == entityDetails?.slotNumber
      );
    });
    let withoutEventEntities = data?.filter((el: any) => {
      /* istanbul ignore next */
      return (
        el.name !== entityDetails?.entityName.split('$')?.[0] ||
        el.slot !== entityDetails?.slotNumber
      );
    });

    /* istanbul ignore next */
    if (
      eventEntities?.length > 0 &&
      eventEntities.length !== selectedRows[0]?.selectedRowTxnRecordIds?.length
    ) {
      eventEntities = eventEntities.filter((ent: any) => {
        return (
          selectedRows[0]?.selectedRowTxnRecordIds.indexOf(ent.txnRecordId) !==
          -1
        );
      });
    }
    data = [...eventEntities, ...withoutEventEntities];
    return data;
  }

  removingNestedentity(a: any, b: any) {
    this.removeNestedentity(a, b);
  }
  /**Remove the nested entities from the txn-data if is-mutlti is true*/
  removeNestedentity(element: any, entityDetails: any) {
    let nestedEntities = element.nslAttributes?.filter((ent: any) => {
      return ent.type == 'entity';
    });

    let nestedEventEntities = nestedEntities?.filter((el: any) => {
      return el.name == this.nestedEntity?.name;
    });

    let nestedWithoutEventEntities = nestedEntities.filter((el: any) => {
      /* istanbul ignore next */
      return (
        el.name !== this.nestedEntity?.name ||
        el.slot !== this.nestedEntity?.slot
      );
    });

    /* istanbul ignore next */
    if (nestedEventEntities?.length > 0) {
      nestedEventEntities = [this.nestedEntity];
      element.nslAttributes = element.nslAttributes?.filter((ent: any) => {
        return ent.type != 'entity';
      });

      if (nestedWithoutEventEntities) {
        element.nslAttributes = [
          ...element.nslAttributes,
          ...nestedWithoutEventEntities,
        ];
      }
      if (nestedEventEntities) {
        element.nslAttributes = [
          ...element.nslAttributes,
          ...nestedEventEntities,
        ];
      }
    } else {
      /* istanbul ignore next */
      element.nslAttributes?.forEach((el1: any) => {
        if (el1.type == 'entity') {
          this.removingNestedentity(el1, entityDetails);
        }
      });
    }
  }

  ngOnDestroy() {
    localStorage.setItem('GsicurrentPageNo', JSON.stringify(0));
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.transactionFacadeService.saveSubtransaction('');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  selectedPrimaryColor(value: any) {
    this.similarColors = [];
    // this.similarColors = [...this.similarColorsForPrimary.filter((x) => x.parentColor === value)];
    this.similarColors = [
      ...this.similarColorsForPrimary?.filter((x) => x.parentColor === value),
    ];
    //this.changeTheme();
  }

  changeTheme() {
    /* istanbul ignore next */
    document?.documentElement?.style?.setProperty(
      '--h2-color',
      this.gsi?.changeUnits?.design?.color?.primary
    );
    /* istanbul ignore next */
    document?.documentElement?.style?.setProperty(
      '--h3-color',
      this.gsi?.changeUnits?.design?.color?.font
    );
    /* istanbul ignore next */
    this.styleContent.fontFamily = this.gsi?.changeUnits?.design?.font?.family;
    /* istanbul ignore next */
    if (this.gsi?.changeUnits?.design?.font?.family)
      document?.documentElement?.style?.setProperty(
        '--font-family',
        this.gsi?.changeUnits?.design?.font?.family
      );
    else {
      try {
        if (IsJsonString(localStorage.getItem('selectedFont'))) {
          const sFont = JSON.parse(localStorage.getItem('selectedFont'));
        if (sFont?.fontName) {
          this.styleContent.fontFamily = sFont?.fontName;
          document.documentElement.style.setProperty('--global-font-family', sFont?.fontName);
          document.documentElement.style.setProperty('--font-family', sFont?.fontName);
        } else if (sFont) {
          let styles = `
            @font-face {
              font-family: 'custom-font';
              src: url(${sFont}) format('truetype');
            }
            `;
            this.styleContent.fontFamily = undefined;
          const node = this.renderer.createElement('style');
          node.innerHTML = styles;
          this.renderer.appendChild(document.body, node);
          document.documentElement.style.setProperty('--global-font-family', 'custom-font');
          document.documentElement.style.setProperty('--font-family', 'custom-font');
        }
      }
     } catch (err) {
        console.error(err);
      }
    }
    /* istanbul ignore next */
    this.styleContent.color = this.gsi?.changeUnits?.design?.color?.primary;
    if (this.templateType === 't-9') {
      this.styleContent['background-color'] = 'transparent';
    }
    /* istanbul ignore next */
    this.templateType = this.templateType
      ? this.templateType
      : this.gsi?.changeUnits?.design?.gsiLayout
      ? this.gsi?.changeUnits?.design?.gsiLayout?.[0]
      : '';

    if (this.isCarnival || this.isOnboarding) {
      this.templateType = 't-11';
      this.renderer.addClass(document.body, 'chartBootLeft');
      this.transactionFacadeService.updateCarnivalScreen(true);
    }
    // grls tenant
    /* istanbul ignore next */
    if (
      localStorage.getItem('TenantName') === 'grls' ||
      localStorage.getItem('TenantName') === 'grlstest' ||
      localStorage.getItem('TenantName') === 'grlsguyton' ||
      localStorage.getItem('TenantName') === 'techlevmlplt'
    ) {
      this.templateType = 't-13';
    }

    this.solutionLayouts = [];

    /* istanbul ignore next */
    if (
      this.gsi &&
      typeof this.gsi?.changeUnits?.design?.gsiLayout !== 'string'
    ) {
      /* istanbul ignore next */
      this.gsi?.changeUnits?.design?.gsiLayout?.forEach((layout: any) => {
        /* istanbul ignore next */
        const foundLayout = this.layouts?.find(
          (x: any) => x?.template === layout
        );
        /* istanbul ignore next */
        if (foundLayout) {
          this.solutionLayouts.push(foundLayout);
        }
      });
    }
    /* istanbul ignore next */
    if (this.gsi?.changeUnits?.design?.backGround?.link) {
      /* istanbul ignore next */
      this.bgStyleContent.backgroundImage =
        this.gsi?.changeUnits?.design?.backGround?.link?.any?.length > 1
          ? `url(${this.gsi?.changeUnits?.design?.backGround?.link?.any})`
          : '';
      /* istanbul ignore next */
      this.bgStyleContent.opacity =
        this.gsi?.changeUnits?.design?.backGround?.opacity / 100;
    } else {
      /* istanbul ignore next */
      this.bgStyleContent.backgroundColor = this.gsi?.changeUnits?.design?.color?.base;
    }
    /* istanbul ignore next */
    this.bgStyleContent.height = this.screenHeight;
  }

  ngOnChanges(changes: SimpleChanges) {
    /*istanbul ignore next */
    this.currentEntityIndex = 0;
    this.infoFields = {};
    this.isSelection = false;
    this.infoFields.fields = [];
    this.showForm = false;
    this.entityDataForTabandAccordion();
    /* istanbul ignore next */
    if (this.selectedCu && this.selectedCu?.layers) {
      /* istanbul ignore next */
      this.currentCuId = this.selectedCu?.id;
      this.isFacetSearchCu =
        this.selectedCu?.reservedCUType == 'NSL_Template_FacetedSearch' ||
        this.selectedCu?.isFaceted
          ? true
          : false;
      this.isInmemoryRCU =
        this.selectedCu?.reservedCUType == 'NSL_EQL_IN_Memory';
      this.currentCuIndex = this.selectedCu?.index;
      /* istanbul ignore else */
      if (this.currentCuIndex == this.gsi?.changeUnits?.solutionLogic?.length) {
        this.canTriggerNextGSi = true;
      }
    }
    this.embeddedGSIid = sessionStorage.innerGSIid;
    this.entityListDefi = [...this.selectedCu?.layers[0]?.entityList];
    this.tenantName = localStorage.getItem('TenantName');
    /* istanbul ignore next */
    if (this.tenantName?.includes('defi') || this.tenantName?.includes('cpd')) {
      this.entityListDefi = this.renameEntity(
        this.entityListDefi,
        undefined,
        undefined,
        false
      );
    }
    this.showEntityList = [];
    /* istanbul ignore next */
    for (let item of this.entityListDefi) {
      /* istanbul ignore next */
      if (this.uniqueItems?.has(item?.slot) == false) {
        this.uniqueItems?.add(item?.slot);
        this.showEntityList.push(item);
      }
    }
    this.uniqueItems?.clear();
    console.log(this.showEntityList);

    /* istanbul ignore next */
    if (this.scrollPosition) {
      setTimeout(() => {
        this.viewportScroller.scrollToPosition(this.scrollPosition);
      }, 400);
    }
    /* istanbul ignore next */
    if (changes?.solutionInfo) {
      this.isSelection = false;
    }

    /* istanbul ignore next */

    if (changes?.gsiDesign || changes?.selectedCu) {
      this.detectGSIDesign(this.gsiDesign);
    }
    // if (
    //   changes?.selectedCu?.currentValue?.id !=
    //   changes?.selectedCu?.previousValue?.id
    // ) {
    //   this.hideSubmitButton = false;
    // }
    let firstChangeCu =
      changes?.selectedCu &&
      changes?.txnData &&
      changes?.transactionCU &&
      this.cuLayoutTemplate?.template?.includes('cupopup')
        ? changes?.selectedCu?.firstChange ||
          changes?.txnData?.firstChange ||
          changes?.transactionCU?.firstChange
        : true;
    if (
      (changes?.transactionCU &&
        changes?.transactionCU?.currentValue?.triggerState != 'COMPLETED') ||
      (changes?.selectedCu && this.isTransactionActive)
    ) {
      this.hideSubmitButton = false;
      this.setEntitiesByLayer();
      this.setInfoLayerDetails();
      this.getActiveIndex();
      this.autoSubmit();
      let depDropdownPayload = JSON.parse(JSON.stringify(this.txnData));
      if (depDropdownPayload?.currentCU?.layers?.length > 0) {
        this.renameToRemoveDollar(
          depDropdownPayload?.currentCU?.layers[0]?.entityList
        );
      }
      this.transactionFacadeService.depDropdownPayloadOnLoad = {
        ...depDropdownPayload,
        type: 'ON_LOAD',
      };
    }
    
    /* istanbul ignore next */
    if (this.scrollPosition) {
      //
      setTimeout(() => {
        this.viewportScroller.scrollToPosition(this.scrollPosition);
      }, 200);
    }
    if (changes?.gsi) {
      this.detectGSI(this.gsi);
    }
    /* istanbul ignore next */
    if (
      !changes.txnData?.previousValue ||
      changes.txnData?.currentValue.currentCU?.id !==
        changes.txnData?.previousValue.currentCU?.id
    )
      // this.autoSubmit();
      this.cd.detectChanges();
  }

  renameToRemoveDollar(data: any) {
    data.forEach((element: any) => {
      for (let [key, val] of Object.entries(element)) {
        if (typeof val === 'string' && val.includes('$')) {
          element[key] = val.split('$')[0];
        }
      }
    });
  }

  // detect solution details
  detectCarnivalGsiList() {
    this.sharedFacadeService.carnivalSolutionDetails$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.content) {
          /* istanbul ignore next */
          this.carnivalGsiList = res?.content;
        }
      });
  }

  //For disabling submit button
  detectRecordVideoDisable() {
    /* istanbul ignore next */
    this.transactionFacadeService.recordVideoDisable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.recordVideoDisable = res;
      });
  }
  //For disabling submit button
  detectRecordAudioDisable() {
    this.transactionFacadeService.recordAudioDisable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.recordAudioDisable = res;
      });
  }

  detectMiniumselectionDisable() {
    /* istanbul ignore next */
    this.transactionFacadeService.minSelection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.optionsMap.set(res?.name, res?.flag);
          let minFlag: any;
          minFlag = true;
          this.optionsMap?.forEach((value, key) => {
            minFlag = minFlag && value;
          });
          this.minimumSelectFlag = minFlag;
        }
      });
  }

  setEntitiesByLayer() {
    /* istanbul ignore next */
    if (
      this.selectedCu &&
      this.selectedCu?.layers &&
      this.selectedCu?.layers?.length > 0
    ) {
      /* istanbul ignore next */
      const [physicalLayer] = this.selectedCu?.layers?.filter(
        (layer: any) => layer?.type === 'physical'
      );
      /* istanbul ignore next */
      this.physicalEntities = physicalLayer?.entityList;
      /* istanbul ignore next */
      this.physicalEntities?.forEach((entity: any) => {
        entity.nslAttributes?.forEach((attr: any) => {
          attr['design'] = {
            color: this.attributeColor,
            fontSize: this.attributeSize,
          };
          /* istanbul ignore next */
          if (this.gsiDesign?.entityIcon?.[attr?.masterId]) {
            attr['design'] = {
              ...attr['design'],
              entityIcon: this.gsiDesign?.entityIcon?.[attr?.masterId],
            };
          }
        });
      });
      /* istanbul ignore next */
      if (physicalLayer) {
        this.tempentList = JSON.parse(
          JSON.stringify(physicalLayer?.entityList)
        );
      }
      /* istanbul ignore next */
      const [infoLayer] = this.selectedCu?.layers?.filter(
        (layer: any) => layer?.type === 'information'
      );
      /* istanbul ignore next */
      this.informationEntities = infoLayer?.entityList;
      /* istanbul ignore next */
      this.informationEntities?.forEach((entity: any) => {
        entity.nslAttributes?.forEach((attr: any) => {
          attr['design'] = {
            color: this.attributeColor,
            fontSize: this.attributeSize,
          };
        });
      });
      /* istanbul ignore next */
      if (infoLayer) {
        this.tempInfoEntList = JSON.parse(
          JSON.stringify(infoLayer?.entityList)
        );
      }
    }
    this.gsiName = this.gsi?.displayName || this.gsi?.name || '';
    this.getAttributeSizes();
  }

  detectChangeUnitControls() {
    this.layoutFacade.changeUnitUIControls$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.UIcontrols = res && res?.entityList ? res?.entityList : [];
        this.setFields();
      });
  }

  setFields() {
    this.getNextPhysicalEntity();
    this.infoEntities = this.selectedCu?.layers?.find(
      (x: any) => x.type === 'information'
    );
    /* istanbul ignore next */
    if (this.infoEntities) {
      this.infoFields.fields = [];
      /* istanbul ignore next */
      this.infoEntities?.entityList?.forEach((entity: any) => {
        this.infoFields.name = entity.name;
        entity.nslAttributes?.forEach((attribute: any) => {
          let field: FieldConfig = this.getFieldFormat(
            attribute,
            'information'
          );
          /* istanbul ignore next */
          field.readonly =
            attribute?.values?.length > 0 || entity?.item?.DATA?.isReserved
              ? true
              : false;
          this.infoFields.fields.push(field);
        });
      });
    }
    setTimeout(() => {
      this.showForm = true;
    }, 500);
  }

  getFieldFormat(attribute: any, layer: any) {
    let field = {} as FieldConfig;
    field.name = attribute.name;
    field.attribute = attribute;
    this.getFieldMapper(field, attribute);
    /* istanbul ignore next */
    if (attribute && attribute.name) {
      field.label = attribute.name?.replace(/_/g, ' ');
    }
    /* istanbul ignore next */
    if (attribute && attribute.options && attribute.options?.length > 0) {
      if (
        field.type === 'dropdown' ||
        field.type === 'radioButton' ||
        field.type === 'checkbox' ||
        field.type === 'list' ||
        field.type === 'multiselectdropdown' ||
        field.type === 'image' ||
        field.type === 'audio' ||
        field.type === 'video' ||
        field.type === 'hyperlink'
      ) {
        field.options = attribute.options;
      }
    }
    /* istanbul ignore next */
    if (
      attribute.attributeType?.properties?.currencyType ||
      attribute.attributeType?.properties?.unit
    ) {
      field.units = attribute.attributeType.properties.currencyType
        ? attribute.attributeType.properties.currencyType
        : attribute.attributeType?.properties?.unit;
    }
    /* istanbul ignore next */
    if (attribute && attribute.values && attribute.values?.length > 0) {
      /* istanbul ignore next */
      if (layer === 'information') {
        field.value = [];
        /* istanbul ignore next */
        attribute.values?.forEach((attr: any) => {
          /* istanbul ignore next */
          if (this.isUrl(attr)) {
            field.type = 'hyperlink';
          }
          field.value.push(attr);
        });
      }
      /* istanbul ignore next */
      if (
        attribute.attributeType &&
        attribute.attributeType.type === 'boolean'
      ) {
        /* istanbul ignore next */
        field.value = attribute?.values?.[0] === 'true' ? true : false;
      }
    }
    return field;
  }

  getFieldMapper(field: any, attribute: any) {
    /* istanbul ignore next */
    if (attribute?.attributeType && attribute?.attributeType?.type) {
      /* istanbul ignore next */
      const foundUIControlEntity = this.UIcontrols?.find(
        (x: any) => x.entityId === this.currentEntity?.item?.DATA?.id
      );
      if (foundUIControlEntity) {
        const foundAttribute = foundUIControlEntity.attributeList?.find(
          (x: any) => x.attributeId === attribute?.id
        );
        /* istanbul ignore next */
        if (foundAttribute && foundAttribute.tuples?.length > 0) {
          const foundUIControl = foundAttribute.tuples?.find(
            (x: any) => x.key === 'uiControl'
          );
          if (foundUIControl) {
            if (
              foundUIControl.value === 'number' ||
              foundUIControl.value === 'text' ||
              foundUIControl.value === 'email'
            ) {
              field.inputType = foundUIControl.value;
              field.type = 'input';
            } else {
              field.type = foundUIControl.value;
              if (field.mapType === 'file') {
                if (this.book && this.gsi && this.selectedCu) {
                  field.fileUploadPath = `${this.book?.id},${this.gsi?.id},${this.selectedCu?.id}`;
                }
              }
            }
          } else {
            this.setOldUIControls(field, attribute);
          }
        } else {
          this.setOldUIControls(field, attribute);
        }
      } else {
        this.setOldUIControls(field, attribute);
      }
    }
  }

  setOldUIControls(field: any, attribute: any) {
    /* istanbul ignore next */
    const foundField = this.dataTypeMapper?.find(
      (x: any) => x.mapType === attribute?.attributeType?.type
    );
    /* istanbul ignore next */
    if (foundField) {
      /* istanbul ignore next */
      field.type = foundField?.control?.type;
      /* istanbul ignore next */
      if (foundField?.control?.inputType) {
        field.inputType = foundField.control.inputType;
      }
      /* istanbul ignore next */
      if (foundField.mapType === 'file') {
        /* istanbul ignore next */
        if (this.book && this.gsi && this.selectedCu) {
          /* istanbul ignore next */
          field.fileUploadPath = `${this.book?.id},${this.gsi?.id},${this.selectedCu?.id}`;
        }
      }
    }
    /* istanbul ignore next */
    if (foundField?.control?.options) {
      field.options = foundField.control.options;
    }
    /* istanbul ignore next */
    if (foundField?.control?.units) {
      field.units = foundField.control.units;
    }
  }

  saveUIPreview() {
    /* istanbul ignore next */
    const CUDetails: any = {
      cuId: Number(this.selectedCu?.id),
      cuType: 'CHANGEUNIT',
      entityList: [],
      tuples: [
        {
          key: 'templateType',
          value: this.templateType,
        },
      ],
    };
    this.layoutFacade.storeChangeUnitDetails(CUDetails);
  }

  getNextPhysicalEntity() {
    this.physicalFields = {};
    this.physicalFields.fields = [];
    this.physicalEntities = this.selectedCu?.layers?.find(
      (x: any) => x.type === 'physical'
    );
    /* istanbul ignore next */
    if (this.physicalEntities) {
      /* istanbul ignore next */
      this.currentEntity = this.physicalEntities?.entityList?.[
        this.currentEntityIndex
      ];
      this.physicalFields.name = this.currentEntity.name;
      /* istanbul ignore next */
      this.currentEntity.nslAttributes?.forEach((attribute: any) => {
        let field: FieldConfig = this.getFieldFormat(attribute, 'physical');
        this.physicalFields.fields.push(field);
      });
    }
  }

  infoLayerformValueChanges(formData: any) {
    this.getNextEntity(formData, formData.isFormValueChange);
  }
  /**
   * Updated the txnData of the Cu whenever the value in the form changes.
   * @param formData Trnsaction form data
   */
  formValueChanges(formData: any) {
    this.getNextEntity(formData, formData.isFormValueChange);
  }

  dynamicformValueChange(formData: any) {
    this.tenantName = localStorage.getItem('TenantName');
    if (
      (this.tenantName.includes('defi') || this.tenantName.includes('cpd')) &&
      formData?.layer == 'physical'
    ) {
      this.cuBtnValidations = formData?.cuBtnValidations;
      for (let cu of this.itemstobeIterated) {
        if (this.selectedCu?.id == cu?.DATA?.id) {
          cu['cuBtnValidations'] = this.cuBtnValidations;
          break;
        } else {
          cu['cuBtnValidations'] = true;
        }
      }
      this.entitySelectionMap = formData?.entitySelectionMap;
    }
    /* istanbul ignore next */
    const ind = this.selectedCu?.layers?.findIndex((layer: any) => {
      return layer.type === formData.layer;
    });
    /* istanbul ignore else */
    if (ind != -1) {
      /* istanbul ignore else */
      if (this.selectedCu && this.selectedCu.layers?.[ind]?.entityList) {
        /* istanbul ignore else */
        this.selectedCu.layers[ind].entityList = formData?.data;
      }
    }
    this.formValueChange.emit(formData);
  }

  getNextEntity(formData: any, isFormChange?: boolean) {
    this.layerItem = formData.layerData;
    const layerIndex = this.layersData.findIndex(
      (layer: any) => layer.type === this.layerItem.type
    );
    if (layerIndex !== -1) {
      this.layersData.splice(layerIndex, 1, this.layerItem);
    } else {
      this.layersData.push(this.layerItem);
    }
    this.getNextCUEmitter.emit({
      layerData: this.layersData,
      isFormChange: isFormChange,
    });
    /* istanbul ignore next */
    if (!isFormChange) {
      this.layerItem = {
        txnSlotItems: [],
        type: 'physical',
      };
      this.layersData = this.layersData.map((layer: any) => {
        let obj = layer;
        obj['txnSlotItems'] = [];
        return obj;
      });
    }
  }

  getFormData(data: any) {
    if (this.isTransactionActive) {
      this.ocrModalShow = false;
      this.transactionFacadeService.dynamicFormSubmit.next();
      /* istanbul ignore next */
      let len = this.gsi?.changeUnits?.solutionLogic?.length;
      /* istanbul ignore next */
      if (
        (this.isCarnival || this.isOnboarding) &&
        data?.data?.[0]?.id ==
          this.selectedCu?.layers?.[0]?.entityList?.[0]?.id &&
        this.selectedCu?.id ==
          this.gsi?.changeUnits?.solutionLogic?.[len - 1]?.DATA?.id
      ) {
        /* istanbul ignore else */
        if (this.currentIndexOfCarnivalCu < this.publishedGsiList?.length - 1) {
          this.lastCompletedSolutionIndex = this.currentIndexOfCarnivalCu;
          this.getNextCUEmitter.emit(data);
          /* istanbul ignore next */
          this.inividualGsiData.emit(
            this.publishedGsiList?.[this.currentIndexOfCarnivalCu + 1]
          );
          return;
        } else if (
          this.currentIndexOfCarnivalCu == this.publishedGsiList?.length - 1 &&
          this.isCarnival
        ) {
          this.dialog.closeAll();
          this.dialog.open(this.thankumodal, {
            disableClose: true,
            panelClass: 'thankumodal-bg',
          });
          this.setTimer(data);
        } else if (
          this.currentIndexOfCarnivalCu == this.publishedGsiList?.length - 1 &&
          this.isOnboarding
        ) {
          this.getNextCUEmitter.emit(data);
          this.router.navigateByUrl('/onboard/training');
        }
      }
      this.getNextCUEmitter.emit(data);
    }
  }

  getActionLabel() {
    this.transactionFacadeService.updateTransactionIdData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result?.currentCU?.actionLabel) {
          this.buttonAction = res?.result?.currentCU?.actionLabel;
        } else {
          this.buttonAction = this.labels.Submit;
        }
      });
  }

  autoSubmit() {
    /* istanbul ignore next */
    if (
      this.txnData?.currentCU?.uiProperties?.isAutoSubmit === 'true' &&
      Number(this.txnData?.currentCU?.uiProperties?.interval) &&
      this.flagAutoSubmit
    ) {
      this.hideSubmitButton = true;
      this.flagAutoSubmit = false;
      setTimeout(() => {
        let pseudoClickEvent = new Event('click');
        this.flagAutoSubmit = true;
        this.onSubmit(pseudoClickEvent);
      }, this.txnData?.currentCU?.uiProperties?.interval * 1000);
    }
  }

  triggerOnSubmit(event: any) {
    this.transactionFacadeService.setCardData(null);
    this.transactionFacadeService.onSubmitting(event);
    this.isPhysicalLayerVideo = false;
    this.scrollPosition = [0, 0];
  }

  onSubmit(event: any) {
    if (
      this.physicalEntities &&
      this.physicalEntities[0].name === 'NSL_CryptoWallet_Input'
    ) {
      if (this.physicalEntities[0].nslAttributes[0].values[0] === 'MetaMask') {
        this.transactionEndpointService
          .connectToMetamask()
          .then((json) => json)
          .then((res: any) => {
            this.transactionEndpointService.metaMaskAddress = res.address;
            this.triggerOnSubmit(event);
          })
          .catch((error) => {
            /* istanbul ignore next */
            if (error.message === 'metamask_not_detected') {
              /* istanbul ignore next */
              this.dialog.open(this.metaMaskAlert, { width: '400px' });
            } else {
              /* istanbul ignore next */
              this.alertService.showToaster(error.message, '', 'error');
            }
          });
      }
    } else if (
      this.physicalEntities &&
      this.physicalEntities[0].name === 'NSL_ETHCryptoPayment_Input'
    ) {
      const nslAttributes = this.selectedCu.layers.find(
        (layer) => layer.type === 'physical'
      ).entityList[0].nslAttributes;
      const sender = nslAttributes.filter(
        (attr) => attr.name === 'Sender Wallet Address'
      )[0].values[0];
      const receiver = nslAttributes.filter(
        (attr) => attr.name === 'Receiver Wallet Address'
      )[0].values[0];
      const amount = nslAttributes.filter((attr) => attr.name === 'Amount')[0]
        .values[0];
      if (sender && receiver && amount) {
        this.transactionEndpointService
          .sendTransaction(sender, receiver, amount)
          .then((json) => json)
          .then((res: any) => {
            this.transactionEndpointService.metaMaskTransactionHash =
              res.transactionHash;
            this.loader.hide();
            this.triggerOnSubmit(event);
          })
          .catch((error) => {
            this.loader.hide();
            /* istanbul ignore next */
            this.alertService.showToaster(error.message, '', 'error');
          });
      }
    } else {
      if (
        !this.recordVideoDisable &&
        !this.recordAudioDisable &&
        !this.isSelection &&
        this.minimumSelectFlag &&
        this.isTransactionActive
      ) {
        if (this.txnData?.currentCU?.triggerCUDialogList?.length > 0) {
          let data = {
            ...this.txnData,
            triggerCuId: this.txnData.currentCU.id,
            triggerCUDialogAction: 'ON_SUBMIT',
          };
          this.transactionFacadeService
            .getcustomizedDialogWarning(data)
            .subscribe((res: any) => {
              if (res && res?.result?.conditionEvaluationResult) {
                if (window.confirm(res?.result?.message)) {
                  this.triggerOnSubmit(event);
                }
              } else {
                this.triggerOnSubmit(event);
              }
            });
        } else {
          this.triggerOnSubmit(event);
        }
      }
    }
    sessionStorage.removeItem('entityTabIndexDefi');
  }

  private setTimer(data: any) {
    const estimatedTime = '45';
    this.timerToRedirect = Number(estimatedTime.trim());
    this.timerSubscription = timer(0, this.tick).subscribe(() => {
      {
        --this.timerToRedirect;
        /* istanbul ignore next */
        if (this.timerToRedirect == 0) {
          this.dialog.closeAll();
          this.getNextCUEmitter.emit(data);
          this.transactionFacadeService.isMccPopup = false;
          this.router.navigateByUrl('/mylibrary-dashboard');
        }
      }
    });
  }

  getDateTimeFormat(date: Date, time: boolean) {
    /* istanbul ignore next */
    const fullDate =
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1))?.slice(-2) +
      '-' +
      ('0' + date.getDate())?.slice(-2);
    let fullTime = 'T00:00:00.000';
    /* istanbul ignore next */
    if (time) {
      /* istanbul ignore next */
      fullTime =
        'T' +
        ('0' + date.getHours())?.slice(-2) +
        ':' +
        ('0' + date.getMinutes())?.slice(-2) +
        ':' +
        ('0' + date.getSeconds())?.slice(-2) +
        '.' +
        ('00' + date.getMilliseconds())?.slice(-3);
    }
    return fullDate + fullTime;
  }

  isUrl(input: any) {
    try {
      new URL(input);
    } catch (_) {
      return false;
    }
    return true;
  }
  openOCRModal(event?: any) {
    this.ocrModalShow = !this.ocrModalShow;
    /* istanbul ignore next */
    if (this.ocrModalShow) {
      this.transactionViewOCR = true;
    }
  }

  openDialog(templateRef: TemplateRef<any>) {
    /* istanbul ignore next */
    if (!this.isReadOnly) {
      this.dialog.closeAll();
      this.dialog.open(templateRef, { width: '520px' });
    }
  }

  /**
   *
   * @param event
   * file change detect
   * convert file to formdata
   * save the file in cloud
   */

  fileChange(event: any) {
    let fileList: any = event.target.files;
    /* istanbul ignore next */
    if (fileList?.length > 0) {
      const formData = new FormData();
      /* istanbul ignore next */
      formData?.append('file', fileList?.[0]);
      this.ocrFacadeService.fileUpload(formData, this.selectedCu);
      this.dialog.closeAll();
      this.ocrSmallModal = true;
    }
  }

  // onload featch solutions

  getSolutions(searchText: string): void {
    this.gsiFacade
      .getAllGsisForTransactionVIew(1, 24, searchText, true, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        if (!this.solutionInfo?.bookId) {
          this.publishedGsiList = data?.data;
          setTimeout(() => {
            // this.focusGsi();
          }, 200);
        }
      });
  }
  expand: boolean = true;

  openGsi(gsiInfo?: any, expand?: any) {
    if (expand !== undefined) {
      this.expand = expand;
    }
    let indexOfNewGSI = this.carnivalGsiList.indexOf(gsiInfo);
    if (!this.isCarnival && !this.isOnboarding) {
      this.gsiDiscardModal = false;
      this.inividualGsiData.emit(gsiInfo);
      this.isProceedDiscard = false;
    } else {
      /* istanbul ignore next */
      if (this.carnivalGsiList?.[indexOfNewGSI]?.executionStatus == 'true') {
        this.gsiDiscardModal = false;
        this.inividualGsiData.emit(gsiInfo);
        this.isProceedDiscard = false;
        /* istanbul ignore next */
        if (this.gsi.changeUnits.id == gsiInfo.id) {
          this.gsi.showCu = !this.gsi.showCu;
        }
      } else {
        this.alertService.showToaster(
          'Execute Current Gsi first',
          '',
          'warning'
        );
      }
    }
  }

  proceedDiscard() {
    this.isProceedDiscard = true;
    this.openGsi();
  }

  detectSearchInput() {
    this.searchInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.getSolutions(this.searchText);
      });
  }
  selectedPreviousCU(selectedCU: any) {
    // this.selectedCUEmit.emit();
    // this.transactionFacadeService.changeTransactionViewType({ isReadOnly: true });

    const data = { cuData: selectedCU, txnData: this.txnData };
    sessionStorage.removeItem('entityTabIndexDefi');
    if (this.gsi?.changeUnits?.isNavigableCu) {
      let navigateCuId = data.cuData?.DATA?.contextualId[0];
      /* istanbul ignore next */
      if (navigateCuId) {
        this.txnData.navigateToCu = navigateCuId;
      }
      this.transactionFacadeService.navigateToCu(this.txnData, false);
    } else {
      this.openReadOnlyPopup(data);
    }
  }

  getContextualId(gsiData: any, currentCu: any) {
    const {
      changeUnits: { solutionLogic },
    } = gsiData;
    const {
      DATA: { id },
    } = currentCu;

    const matchedData = solutionLogic?.filter((obj: any) => {
      /* istanbul ignore next */
      if (obj?.DATA?.id === id) {
        return obj;
      }
    });
    /* istanbul ignore next */
    if (matchedData?.length > 0) {
      /* istanbul ignore next */
      return matchedData?.[0];
    }
  }

  openReadOnlyPopup(data: any) {
    const { cuData, txnData } = data;
    let matchedCUData = this.getContextualId(this.gsi, cuData);

    /* istanbul ignore next */
    const requestBody = {
      rootGSIId: txnData.gsiId,
      triggerCUId: cuData?.DATA?.id,
      txnId: txnData.transId,
      previousCUContextualId: matchedCUData?.DATA?.contextualId?.[0],
    };

    this.transactionFacadeService
      .getPreviousCUData(requestBody, this.isDebugMode)
      .subscribe((currentCu: any) => {
        const {
          result: { currentCU },
        } = currentCu;
        this.selectedCuReadOnly = currentCU;

        if (this.isDebugMode) {
          this.transactionFacadeService.debugCu(this.selectedCuReadOnly);
        } else {
          const dialogRef = this.dialog.open(ReadOnlyTransactionDialog, {
            width: '1500px',
            height: '500px',
            disableClose: false,
            data: {
              gsi: this.gsi,
              currentCU: currentCU,
              mode: 'transaction',
              txnData: {},
              isReadOnly: true,
            },
          });
        }
      });
  }

  isDataSame(previousAttr: any, currentAttr: any) {
    for (let i = 0; i < previousAttr?.length; i++) {
      if (previousAttr[i].attrValue != currentAttr[i].attrValue) return false;
    }
    return true;
  }
  // Send request and fetch options for attr
  detectEventTypeAHead() {
    this.eventsService.typeAHeadEvent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        let data: any = {
          gsiId: res.gsiId,
          entityName: res.entityName,
          attrName: res.attrName,
          attrValue: res.attrValue,
          contextAttrInfo: [],
        };
        /* istanbul ignore next */
        res?.contextAttrInfo?.forEach((attr: any) => {
          if (attr != null) {
            /* istanbul ignore next */
            let targetAttr = this.txnData?.currentCU?.layers?.[0]?.entityList?.[0]?.nslAttributes?.find(
              (attribute: any) => {
                return attr?.attrName === attribute?.name;
              }
            );
            /* istanbul ignore next */
            if (targetAttr) {
              /* istanbul ignore next */
              data?.contextAttrInfo?.push({
                attrName: targetAttr?.name,
                attrValue: targetAttr?.values?.[0]
                  ? targetAttr?.values?.[0]
                  : '',
              });
            }
          }
        });
        /* istanbul ignore next */
        if (this.firstTime) {
          this.firstTime = false;
        } else {
          let isDataSame =
            this.isDataSame(
              this.eventsService?.previousAttrData?.contextAttrInfo,
              data?.contextAttrInfo
            ) &&
            this.eventsService?.previousAttrData?.attrName == data?.attrName;
          if (!isDataSame) {
            this.eventsService.updateTypeAheadPageNumber(0);
            this.eventsService.typeAheadOptionsData.next([]);
          } else if (
            isDataSame &&
            this.eventsService.typeAheadEventType == 'click'
          ) {
            return;
          }
        }
        this.eventsService.previousAttrData = data;
        this.eventsService.postContextualSearchData(data);
      });
  }
  saveSubtransactionalCu() {
    this.transactionFacadeService.saveSubtransactionalData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && this.isTransactionActive) {
          this.transactionFacadeService.getListChangeUnitByGSIId(
            this.txnData,
            false,
            false,
            res
          );
        }
      });
  }

  toggleTransaction() {
    this.leftSection = !this.leftSection;
  }

  changeGrid(grid: string) {
    this.activeGrid = grid;
    this.sharedFacadeService.setGridLayout(grid);
  }

  status(id: string, gsi: any) {
    let index = this.carnivalGsiList.indexOf(gsi);
    /* istanbul ignore next */
    if (this.carnivalGsiList?.[index]?.executionStatus == 'true') {
      return 'Completed';
    } else if (id == this.gsi.changeUnits.id) {
      this.currentIndexOfCarnivalCu = index;
      return 'Progress';
    } else {
      return 'To Do';
    }
  }
  showSolutionName(name: string) {
    /* istanbul ignore else */
    if (this.isCarnival) {
      /* istanbul ignore else */
      if (name == 'Elementary Dry Run') {
        return 'Elementary';
      }
      /* istanbul ignore else */
      if (name == 'Basic Dry Run') {
        return 'Basic';
      }
    }
    return name;
  }

  openInfoPopup(gsiObj: any, event: any) {
    event.stopPropagation();
    /* istanbul ignore next */
    if (!gsiObj?.gsiInfoCont) {
      gsiObj['gsiInfoCont'] = true;
    }
  }

  closeInfoPopup(gsiObj: any) {
    /* istanbul ignore next */
    if (gsiObj?.gsiInfoCont) {
      gsiObj.gsiInfoCont = false;
    }
  }

  bookCover(gsi: any) {
    let index = this.carnivalGsiList?.indexOf(gsi);
    /* istanbul ignore next */
    if (index == 0) {
      return 'elementary';
    } else if (index == 1) {
      return 'basic';
    } else if (index == 2) {
      return 'proficient1';
    } else if (index == 3) {
      return 'proficient';
    }
  }

  goback() {
    this.transactionFacadeService.isMccPopup = false;
    this.router.navigateByUrl('/mylibrary-dashboard');
    this.GsicurrentPageNo = 0;
    localStorage.removeItem('GsicurrentPageNo');
    this.transactionFacadeService.resumeTransaction('');
  }

  goToGsi() {
    let campaignUrl = localStorage.getItem('campaignUrl');
    let flag = Boolean(localStorage.getItem('mccToTransactions'));
    let menuLevel = localStorage.getItem('myLibraryPage');
    if (campaignUrl && campaignUrl != 'undefined') {
      this.transactionFacadeService.campaignGsiLogout();
    } else {
      if (flag) {
        this.goback();
      } else {
        switch (menuLevel) {
          case 'Books':
            this.router.navigateByUrl(`transaction/my-collection`);
            break;
          case 'Solution':
            this.goback();
            break;
          case 'Entities':
            this.goBack();
            break;
          default:
            this.router.navigateByUrl(`transaction/my-collection`);
            break;
        }
      }
    }
  }

  focusGsi() {
    /* istanbul ignore next */
    setTimeout(() => {
      /* istanbul ignore next */
      const errorField = this.renderer?.selectRootElement(`.gsiFocus`);
      /* istanbul ignore next */
      errorField?.scrollIntoView();
    }, 300);
  }
  checkIndex(index: number) {
    if (index < this.currentCuIndex) {
      return true;
    }
    return false;
  }

  getFileNameFromImgUrl(imgUrl: any) {
    return getFileNameFromImgUrl(imgUrl);
  }

  switchToGsi(gsiObj: any) {
    this.gsi = gsiObj;
    this.transactionFacadeService.gsiObj.next(this.gsi);
    this.getHumanCUs();
    this.setInividualGsiData.emit(gsiObj);
  }
  openAdditionDialog() {
    let layerObject = {};
    /* istanbul ignore next */
    this.selectedCu?.layers?.forEach((layer: any) => {
      layerObject[layer?.type] = true;
    });
    this.transactionFacadeService.updateLayersEntity(layerObject);
  }
  openArCamera(type: any) {
    // this.dialog.open(this.ArDialog)
    this.arService.setArData({
      ar_id: this.selectedCu?.uiProperties?.ar_id,
      master_gsi_id: this.gsi?.changeUnits?.masterId,
      gsi: this.gsi?.changeUnits,
      transId: this.gsi?.changeUnits?.transId,
      master_cu_id: this.selectedCu?.masterId,
      autoOpenCamera: this.autoOpenCamera,
      type: type,
    });
    let arData = {
      ar_id: this.selectedCu?.uiProperties?.ar_id,
      master_gsi_id: this.gsi?.changeUnits?.masterId,
      gsi: this.gsi?.changeUnits,
      transId: this.gsi?.changeUnits?.transId,
      master_cu_id: this.selectedCu?.masterId,
      autoOpenCamera: this.autoOpenCamera,
      type: type,
    };
    this.crossDomainMessagingService.setData(arData, 'arData');
    this.dialog.closeAll();
    /*istanbul ignore next*/
    if (type == 'PE') {
      // this.router.navigateByUrl(`AR/${this.selectedCu?.id}`);
      this.router.navigateByUrl(`AR/arCameraView/${this.selectedCu?.id}`);
    } else if (type == 'AE' || type == 'HE') {
      /*istanbul ignore next*/
      this.router.navigateByUrl(`AR/filesView/${this.selectedCu?.id}`);
    }
  }
  fetchArEntities(cu: any) {
    let formData = new FormData();
    formData.append('ar_id', cu?.uiProperties?.ar_id);
    formData.append('gsi_id', this.gsi?.changeUnits?.masterId);
    formData.append('cu_id', cu?.masterId);
    // let formData = {formData : data}
    this.arService.fetchArEntities(formData);
  }

  detectArEntities() {
    this.arService.arEntityData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res?.result) {
          this.arEntitiesData = res.result;
          /*istanbul ignore else*/
          if (res?.result?.Physical_Entities?.length > 0) {
            this.physicalBtn = true;
            res?.result?.Physical_Entities?.forEach((ent: any) => {
              /*istanbul ignore else*/
              if (ent.AR_attributes?.length > 0) {
                this.hybridBtn = true;
              }
            });
          }
          /*istanbul ignore else*/
          if (res?.result?.AR_Entities?.length > 0) {
            this.arBtnEntity = true;
            this.alertService.showToaster(
              'Download the NSLHUB Mobile Application for Better Augmented Reality experience  ',
              '',
              'info'
            );
          }
        }
      });
  }
  detectArClose() {
    this.transactionFacadeService.closeLoopOfAr$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          this.autoOpenCamera = res?.openCamera;
        }
      });
  }
  checkForArCu(selectedCu: any) {
    /*istanbul ignore next*/
    this.gsi?.changeUnits?.solutionLogic?.forEach((changeUnit: any) => {
      if (
        changeUnit?.DATA?.id == selectedCu?.id &&
        selectedCu?.uiProperties?.ar_id &&
        this.autoOpenCamera
      ) {
        this.dialog?.closeAll();
        this.dialog?.open(this.ArDialog);
      }
    });
  }
  ngAfterViewInit() {
    this.checkForArCu(this.selectedCu);
  }
  testrouting() {
    this.router.navigateByUrl('/transaction/redac');
  }
  saveAsDraft() {
    let transactionData = JSON.parse(JSON.stringify(this.txnData));
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      this.checkEntitieswithCardandTable(layer.entityList, layer.type);
      this.resetEntityorder(layer.entityList);
    });
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      layer.entityList = this.renameEntity(
        layer.entityList,
        { isTableConfig: false },
        layer.type,
        false
      );
    });
    if (transactionData?.currentCU?.eventCUList?.length > 0) {
      // this.setEventsCuList(transactionData);
    }
    this.transactionFacadeService.saveAsDraft(transactionData);
    this.transactionFacadeService.saveAsDraftreturnData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          this.transactionFacadeService.isMccPopup = false;
          this.router.navigateByUrl('mylibrary-dashboard');
        }
      });
    this.transactionFacadeService.openPopup.next('close');
  }

  addMultipleEntities(event: any) {
    /* istanbul ignore next */
    let multiEnt =
      event?.data?.isParentMultiEntity && event?.data?.isMulti ? true : false;
    let transactionData = JSON.parse(JSON.stringify(this.txnData));
    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      this.checkEntitieswithCardandTable(layer.entityList, layer.type);
      this.eventsService.entityAttributesOrder(layer.entityList);
    });
    let originalData = JSON.parse(JSON.stringify(transactionData));
    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      layer.entityList = this.renameEntity(
        layer.entityList,
        event.data,
        layer.type,
        false,
        true
      );
    });
    if (transactionData?.currentCU?.eventCUList?.length > 0) {
      // this.setEventsCuList(transactionData);
    }
    /* istanbul ignore next */
    transactionData?.currentCU?.layers?.forEach((layer: any) => {
      if (multiEnt) {
        layer.entityList = this.removeEntity(
          layer.entityList,
          event.data,
          multiEnt
        );
      }
      this.resetEntityorder(layer.entityList);
    });
    /* istanbul ignore next */
    this.transactionFacadeService.addMultipleEntities(
      transactionData,
      event,
      multiEnt,
      originalData.currentCU?.layers
    );
  }

  getActionLabelPushback() {
    this.transactionFacadeService.listChangeUnit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result?.currentCU?.actionLabel) {
          this.buttonAction = res?.result?.currentCU?.actionLabel;
        } else {
          this.buttonAction = this.labels.SUBMIT;
        }
      });
  }

  setEventsCuList(transactionData: any) {
    for (var i = 0; i < transactionData.currentCU.eventCUList?.length; i++) {
      transactionData.currentCU.eventCUList[i] = eventsListMapper(
        transactionData.currentCU.eventCUList[i]
      );
    }
  }

  selectedGsiDefi(gsi: any) {
    this.defiGsi = true;
    this.currentDefiGsi = gsi;
    this.goBack();
    this.transactionFacadeService.getChangeUnitByGsiId(
      gsi?.id,
      undefined,
      this.solutionInfo?.bookId
    );
  }

  activeGsiIndex: number = 0;
  getGsiName(
    gsiObj: any,
    currentCUName?: string,
    currentIndex?: number
  ): string {
    const name = gsiObj?.displayName || gsiObj?.name || '';
    if (currentIndex) {
      if ((gsiObj?.displayName || gsiObj?.name) === currentCUName) {
        this.activeGsiIndex = currentIndex;
      }
    }
    if (
      this.layoutConfigurations?.conditionalTooltip?.maxLength &&
      name?.length > this.layoutConfigurations?.conditionalTooltip?.maxLength
    ) {
      return (
        name.substring(
          0,
          this.layoutConfigurations?.conditionalTooltip?.maxLength - 3
        ) + '...'
      );
    }
    return name;
  }
  //for slot configuration execution
  triggerSlotConfiguration(data: any) {
    if (
      data?.entityName?.includes('NSL_SlotBooking') &&
      data?.type == 'dateonly'
    ) {
      this.eventsService.triggerSlotExecution.next(false);
      let transactionData = JSON.parse(JSON.stringify(this.txnData));
      transactionData?.currentCU?.layers?.forEach((layer: any) => {
        /* istanbul ignore next */
        this.checkEntitieswithCardandTable(layer.entityList, layer.type);
        this.resetEntityorder(layer.entityList);
      });
      transactionData?.currentCU?.layers?.forEach((layer: any) => {
        layer.entityList = this.renameEntity(
          layer.entityList,
          { isTableConfig: false },
          layer.type,
          false
        );
      });
      transactionData?.currentCU?.layers?.forEach((layer: any) => {
        layer.entityList = this.renameEntity(
          layer.entityList,
          data,
          layer.type,
          true
        );
      });
      let masterTxnData = JSON.parse(JSON.stringify(transactionData));

      /* istanbul ignore next */
      transactionData?.currentCU?.layers?.forEach((layer: any) => {
        /* istanbul ignore next */
        if (layer.type === 'physical') {
          layer.entityList = this.removeEntity(layer.entityList, data);
        }
        this.resetEntityorder(layer.entityList);
      });

      /* istanbul ignore next */
      transactionData?.currentCU?.layers?.forEach((layer: any) => {
        layer.entityList = this.renameEntity(
          layer.entityList,
          data,
          layer.type,
          false
        );
      });
      /* istanbul ignore next */
      masterTxnData?.currentCU?.layers?.[0]?.entityList[
        data?.ent_index - 1
      ]?.nslAttributes?.forEach((attribute: any) => {
        if (attribute?.id == data?.attributeId)
          attribute.values[0] = data?.dateChanged;
        if (attribute?.name == 'Slots') {
          attribute.options = null;
          delete attribute?.attributeType?.extendedProperties;
          if (attribute?.values) attribute.values = [];
        }
      });
      this.transactionFacadeService.executeCalnderSlot(masterTxnData);
    }
  }
}
//read-only view previous cu popup starts here
@Component({
  selector: 'read-only-transaction-dailog',
  template: `
    <div class="transparent">
      <div class="pull-right close-btn-cstm">
        <!-- <mat-icon class="close" (click)="close()">highlight_off</mat-icon> -->
      </div>
      <ng-template #readOnlyTransaction> </ng-template>
    </div>
  `,
})
export class ReadOnlyTransactionDialog implements OnInit {
  @ViewChild('readOnlyTransaction', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    public dialogRef: MatDialogRef<ReadOnlyTransactionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  componentRef: any;

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      TransactionViewComponent
    );
    const { gsi, currentCU, mode, txnData, isReadOnly } = this.data;
    /* istanbul ignore next */
    if (factory) {
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.selectedCu = currentCU;
      this.componentRef.instance.gsi = gsi;
      this.componentRef.instance.mode = mode;
      this.componentRef.instance.txnData = txnData;
      this.componentRef.instance.isReadOnly = isReadOnly;
    }
  }
  close() {
    this.dialogRef.close({ event: 'close' });
  }
}
