import { Attribute } from './attribute';
import { Rights } from './rights';

export interface Entity {
  id?: number;
  // dsdId?: number;
  isMultiValue: boolean;
  botQuestion?: string;
  name: string;
  masterId: number;
  displayName: string;
  nslAttributes: Attribute[];
  ontology: [];
  keywords: [];
  author: {};
  description?: string;
  tfId: number;
  status: any;
  dsdStatus: any;
  version: any;
  configuration: string;
  isNameUpdated?: boolean;
  isMasterData?: boolean;
  entityIndex: number;
  source: string;
  layerType?: string;
  // contextualId?: string[];
  designTimeRights?: Rights[];
  entityType?: any;
  // entityClassification: string;
  // entityType: string;
  // guid?: string;
  // ownerId?: number;
  // reserved?: boolean;
  // space?: string;
  // subEntities: any[];
  // symbolicId: string;
  // time: Date;
  txnTimeRights: Rights[];
  roles?: any[];
  uniqueConstraints?: any;
  entityProperties?: any;
  
}
export class Entity {
  dsdId?: any;
  isAuditableData?: boolean;
  constructor() {
    this.isMultiValue = false;
    this.description = '';
    this.nslAttributes = [];
    this.name = '';
    this.roles = [];
    this.uniqueConstraints = [];
    this.entityProperties = {
      isTraceAudit: false,
    };
  }
}
