<div>
  <ul *ngFor="let entity of layerData; let entityIndex = index" class="mt-20 dropdown-layer">
    <li class="cstm-attr-plus li-top-0 entity-listing">
      <mat-accordion class="w-100">
        <mat-expansion-panel [(expanded)]="entity.expand">
          <mat-expansion-panel-header>
            <mat-panel-title class="active-p">
              <div class="entity-section">
                <img src="../../../../../assets/images/hybrid-canvas-icon/entity_icon.svg" alt="">
                <p [matTooltipPosition]="'above'" matTooltipClass="tool-tip-custom" matTooltip="{{ entity?.name }}"
                  id="expand_{{ cuName | stringReplace }}.{{ layerLabel.name | stringReplace }}.{{entity?.name | stringReplace }}_{{ entityIndex }}"
                  class="ent-widt">
                  {{ entity?.name }}
                  <span class="w-auto px-4" (click)="addAttributetoDcd(entityIndex, entity?.name, entity?.tfId, null, null, entity)"
                    *ngIf="currentCUType !== 'memberShip' && !onlyAttribute">
                    <!-- <img id="plus_{{ cuName | stringReplace }}.{{ layerLabel.name | stringReplace }}.{{
                                  entity?.name | stringReplace
                                }}" [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe" alt=""
                      class="img-h25 hover-img hoverplusimg" /> -->
                  </span>
                </p>
              </div>
             

              <span class="w-auto plus-icon" (click)="addAttributetoDcd(entityIndex, entity?.name, entity?.tfId, null, null, entity)" *ngIf="currentCUType !== 'memberShip' && !onlyAttribute">
                <!-- <img id="plus_{{ cuName | stringReplace }}.{{ layerLabel.name | stringReplace }}.{{ entity?.name | stringReplace }}" [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe" alt="" class="img-h25 hover-img hoverplusimg"/> -->
                <img id="plus_{{ cuName | stringReplace }}.{{ layerLabel.name | stringReplace }}.{{ entity?.name | stringReplace }}" src="../../../../../assets/images/hybrid-canvas-icon/plus.svg" alt="" class="img-h25 hover-img hoverplusimg">
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
         
          <ul class="attribute-list attribute-list-li" id="attributeList" *ngIf="entity?.expand && currentCUType != 'entityMemberShip'">
            <app-entity-dcd
              [entityIndex]="entityIndex"
              [entity]="entity"
              [attr]="attr"
              [attrName]="entity?.name + '.' + attr?.name"
              [attrId]="entity?.tfId + '.' + attr.tfId"
              [cuName]="cuName"
              [layerName]="layerLabel"
              *ngFor="let attr of entity?.nslAttributes; let attrIndex = index"
              (attributeEmit)="addAttributeToDCD($event)"
              (entityEmit)="addEntityToDCD($event)"
            ></app-entity-dcd>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</div>


