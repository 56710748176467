import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { pluck, takeUntil } from 'rxjs/operators';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Entity } from '../modal/entity';
import { Librarytypes } from '../enums/library-types.enums';
import { Subject } from 'rxjs';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class EntityEndpointService extends CduiBaseService {

  public isEditAttributeFlag = new Subject();
  isEditAttributeFlag$ = this.isEditAttributeFlag.asObservable();
  public entityUploadFileData = new Subject();
  entityUploadFileData$ = this.entityUploadFileData.asObservable();
  public getEntityName = new Subject();
  getEntityName$ = this.getEntityName.asObservable();
  private ngUnsubscribe = new Subject();

  constructor(private http: HttpClient, private injector: Injector,private alertService: AlertService,) {
    super(injector);
  }

  saveGEEntity(body: Entity, isIRDRUpdate?: boolean) {
    /* istanbul ignore next */
    isIRDRUpdate = isIRDRUpdate ? isIRDRUpdate : false;
    return this.http.post(
      this.environment.dsdOrchUrl + `/dsd-bets-store/tenant/entity?isOnlyIrdrUpdate=${isIRDRUpdate}`,
      body
    );
  }

  saveEntity(body: Entity) {
    return this.http.post(
      this.environment.dsdOrchUrl +
        '/dsd-bets-store/tenant/entity' +
        `?disableWarnings=${localStorage?.getItem('skipWarnings') === 'true'}`,
      body
    );
  }

  getValidations() {
    /* istanbul ignore next */
    return this.http.get(this.api?.attrTypes).pipe(pluck('result'));
  }
  getAllEntities(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean, layerType?: any) {
    const ontology = '';
    const publisherId = '';
    /* istanbul ignore next */
    if (layerType === Librarytypes.ReservedActions) {
      /* istanbul ignore next */
      return this.http
        .get(this.api?.reservedEntities + `?query=${searchCriteria}&page=${pagenum}&limit=${pageSize}`)
        .pipe(pluck('result'));
    } else {
      /* istanbul ignore next */
      return this.http
        .get(
          this.api?.getAllEntities +
            `?query=${searchCriteria}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${pagenum}&limit=${pageSize}&isPublished=${isPublished}`
        )
        .pipe(pluck('result'));
    }
  }

  getExactEntityByName(searchCriteria: string, layerType?: any) {
    const ontology = '';
    const publisherId = '';
    /* istanbul ignore next */
    if (layerType === Librarytypes.ReservedActions) {
      /* istanbul ignore next */
      return this.http.get(this.api?.reservedEntities).pipe(pluck('result'));
    } else {
      /* istanbul ignore next */
      return this.http.get(this.api?.getExactEntityByName + `${searchCriteria}`).pipe(pluck('result'));
    }
  }

  getAllEntitiesCount(pagenum: number, pageSize: number, searchCriteria: string) {
    /* istanbul ignore next */
    return this.http
      .get(this.api?.entityCount + '?pageNo=' + pagenum + '&pageSize=' + pageSize + '&searchCriteria=' + searchCriteria)
      .pipe(pluck('result', 'total'));
  }
  save(EntityName: any, entityId: number, clientId: number, data1: any) {
    return this.http.post(
      this.api?.save + `?EntityName=${EntityName}&entityId=${entityId}&tenantId=${clientId}`,
      data1
    );
  }
  updateById(EntityName: any, entityId: number, clientId: number, data1: any) {
    return this.http.put(
      this.api?.updateById + `?EntityName=${EntityName}&entityId=${entityId}&tenantId=${clientId}`,
      data1
    );
  }
  userCustomAttributesConfig(clientId: any) {
    return this.http.get(this.api?.userCustomAttributes+`${clientId}`);
  }
  fetchById(entityId: any, tenantId: any) {
    return this.http.get(this.api?.fetchById + `?entityId=${entityId}&tenantId=${tenantId}`);
  }
  deleteById(entityId: any, tenantId: any) {
    return this.http.delete(this.api?.deleteById + `?entityId=${entityId}&tenantId=${tenantId}`);
  }
  getClientIdFromStorage(): string | null {
    return localStorage.getItem('environmentName');
  }
  userCustomAttributes(clientId: any) {
    return this.http.get(this.api?.customAttributes + `${clientId}`);
  }
  loadAttributesByEntId(id: any, isReserved?: boolean, reservedActions?: any) {
    /* istanbul ignore next */
    if (reservedActions) {
      /* istanbul ignore next */
      return this.http.get<any>(this.api?.getAttributeByEntityIdForReservedActions + `${id}`);
    } else {
      /* istanbul ignore next */
      isReserved = isReserved ? isReserved : false;
      /* istanbul ignore next */
      return this.http.get<any>(this.api?.getAttributeByEntityId + `/${id}?isReserved=${isReserved}`);
    }
  }

  getCloneDetails(id: number, isPublished: boolean, type: any, data: any) {
    /* istanbul ignore next */
    if (type === 'gsi') {
      /* istanbul ignore next */
      return this.http.post(this.api?.betsStoreTenant + `${type}/clone/${id}`, { name: data.name });
    } else {
      /* istanbul ignore next */
      return this.http.post(this.api?.betsStoreTenant + `${type}/clone/${id}?isPublished=${isPublished}`, data);
    }
  }
  //Elastic search - FE757
  // ------------------------------ START -------------------------

  // Fetch contextual search data for typeahead attribute while creating soln based on Gsi Id
  getContextualSearchData(gsiId: number) {
    /* istanbul ignore next */
    return this.http.get<any>(this.api?.saveTypeAheadSearchConfiguration + `?gsiId=${gsiId}`);
  }

  // Save contextual search data for typeahead attribute while creating soln
  postContextualSearchData(data: any) {
    /* istanbul ignore next */
    return this.http.post<any>(this.api?.saveTypeAheadSearchConfiguration + '/save', data);
  }

  validateEntityName(tenantName: any, entityName: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.validateEntityName + entityName + '?tenantId=' + tenantName);
  }

  // ------------------------------ END -------------------------
  getTriggerCuAttrUiElement(data: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.triggerCuAttrUiElement, data);
  }

  getAttributeComparisonData(data: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.attributesComparison, data);
  }
  searchEntityBasedCriteria(payload: any, pagenumber: number, pagesize: number) {
    /* istanbul ignore next */
    return this.http.post(this.api?.searchEntityBasedCriteria + `pageNo=${pagenumber}&pageSize=${pagesize}`, payload);
  }
  getEntityIndexData(pageNo: number, pageSize: number, entitySearchText: any) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.entityIndex + `get/tablesInfo?pageNo=${pageNo}&pageSize=${pageSize}&searchable=${entitySearchText}`
    );
  }

  getIndexData(entityName: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.entityIndex + `get/indexInfo?entityName=${entityName}`);
  }

  getAttributesOfEntity(tenantId: any, entityName: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.entityAttributes + `${entityName}?tenantId=${tenantId}`);
  }

  deleteEntityIndex(entityName: string, indexList: string[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: indexList,
    };
    /* istanbul ignore next */
    return this.http.delete(this.api?.entityIndex + `delete?entityName=${entityName}`, options);
  }

  createEntityIndex(createIndexPayload: any, entityName: string) {
    /* istanbul ignore next */
    return this.http.post(this.api?.entityIndex + `create?entityName=${entityName}`, createIndexPayload);
  }

  getUiControlsForAttribute(attributeNamesArray: string[]) {
    /* istanbul ignore next */
    return this.http.post(this.api?.attributeSuggestion, { attributes: attributeNamesArray, activate_auto_id: true });
  }
  downloadGeData(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.downloadGeData, payload);
  }

  //For entity upgrade in other BETs
  getAllCuWithArchivedEntity(entityId: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAllCuWithArchivedEntity + `${entityId}`);
  }

  getAllGsiWithArchivedEntity(cuId: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAllGsiWithArchivedEntity + `${cuId}`);
  }

  getAllEligibleBetsWithGsi(gsiId: any, isReserved: boolean) {
    /* istanbul ignore next */
    if (!isReserved) return this.http.get(this.api?.getAllEligibleBetsWithGsi + `${gsiId}` + `?scanForNonRsrvBet=true`);
    else return this.http.get(this.api?.getAllEligibleBetsWithGsi + `${gsiId}`);
  }

  upgradeGsi(gsi: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.upgradeGsi, gsi);
  }

  saveUpgradedGsi(gsi: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.saveUpgradedGsi, gsi);
  }

  getCurrencyJson() {
    return this.http.get('../../../../../assets/json/currency');
  }

  editAttributeEnable(event: any){
    this.isEditAttributeFlag.next(event);
  }
  sendDeepClonedData(data: any) {
    return this.http.post(this.api?.deepClone, data);
  }
  uploadEntity(file: any) {
    const formData = new FormData();
    formData.append('data_file', file);
    this.http
      .post(this.api?.excelEntityParser, formData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.entityUploadFileData.next(res);
          this.alertService.showToaster(res?.message, '', 'success');
        },
        /* istanbul ignore next */
        (err: any) => {
          this.alertService.showToaster('file upload failed', '', 'error');
        }
      );
  }
  downloadEntTemplate() {
    this.http
      .get(this.api?.entityTemplate, { responseType: 'blob' })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          const blob = new Blob([res], { type: 'xlsx' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = 'EntityParserTemplate.xlsx';
          link.click();
          window.URL.revokeObjectURL(url);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
}
