import { Component, HostListener, Injector, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JSEncrypt } from 'jsencrypt';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { AnimationOptions } from 'ngx-lottie';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from '../../generic-folder/logger.service';
import { CduiBaseComponent } from '../cdui-base/cdui-base.component';
import { AuthenticationService } from '../cdui-base/authentication.service';
import { UsersFacadeService } from '../users/users-facade.service';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';
import { LoaderService } from '../loader/loader.service';
import { Credentials, CredentialsService } from '../cdui-base/credentials.service';
import { TenantCustomUiFacadeService } from '../tenant-cus/tenant-custom-ui-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { ManageConfigurationFacadeService } from '../manage-configuration/manage-configuration-facade.service';
import { OnboardingFacadeService } from '../onboarding/onboarding-facade.service';
import { checkTokenExistsorNot, findFont, validateProviderImageExistOrNot } from '../../generic-folder/generic-functions';
import { SsoLoginService } from './services/sso-login.service';
import { SelectRolefromPublicUserRoleComponent } from './select-rolefrom-public-user-role/select-rolefrom-public-user-role.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedFacadeService, TransactionFacadeService } from '@common-services';

const log = new Logger('Login');
//declare const gtag: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends CduiBaseComponent implements OnInit, OnDestroy {
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  showPassword: boolean = false;
  // tenantList: any;
  activeUser: any;
  labels: any;
  username_or_password_incorrect: any;
  tenantType: any;
  ngUnsubscribe = new Subject();
  @Input() tenantName: string;
  @Input() tenantExists: boolean;
  @Input() customLoginFlag: boolean = true;
  urlTenantName: any;
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  otpLogin: boolean = false;
  countDownTimer: any = [];
  display: any;
  capturedMobileNumber: any;
  mobileNumberFieldEntered: boolean = false;
  twofactorverification: boolean = false;
  mobileNumber: any;
  email: any;
  mobileNbr: any;
  OTPNbr: any;
  otpCapture: any;
  resendOTP: boolean = false;
  tenantId: any;
  @Input() envClientId: any;
  @Input() envName: any;
  @Input() environmentType: any;
  otpTimer: any;
  otp: any;
  otpValidval: boolean = false;
  redirectUrl: string;
  headerDesignData: any = {
    logo: { any: '../../../assets/images/nh-favicon.png' },
    header: { backgroundColor: { color: '#FFF' }, font: { family: '' } },
  };
  isSingletonLogin: boolean = false;
  carnivalCategory: any;
  profileTypeExist: boolean = false;
  $encrypt: any;
  primaryLoginMethod: string = 'SigninViaEmail';
  envConfigData: any;
  @Input() set setIdentityProvidersList(list: any) {
    this.identityProvidersList = list;
  }
  identityProvidersList: any = [];
  @Input() isCustomLoginTenant: boolean = false;
  @Input() isLmsTenant: boolean = false;
  lottieOptions: AnimationOptions = {
    path: '../../../assets/json/login_earth.json',
  };
  allowSelfSignup: boolean = false;
  customLoginTenants = ['vpnhsl', 'ngdev', 'nggroupuat'];
  lmsTenants = ['ngdev', 'nggroupuat'];
  signupProperties2fa: any;
  context: any;
  timeLeft: number;
  interval: NodeJS.Timeout;
  clientId: string;
  otpSubmitButtonVisibility: boolean = false;
  @Input() mode: string = 'transaction';

  @Input() enableLoginViaMobile: any = false;
  countryCodes: any;
  selectedCountryCode = '+91';
  countrycode: any;
  epocTime: any;
  captcha: string = '';
  otpTimeOutDuration: any;
  isOtpGeneratedOnce = false;
  isRedirectedtoLoginPage: boolean = false;
  currentScreen: string = 'desktop';
  enableLoginViaOtp: boolean = false;
  captchaURL: any;
  isCaptchaValid: boolean = false;
  isReloaded: boolean = false;
  isCaptchaEnabled: boolean = false;
  displayTermsField: boolean = false;
  termsAndConditions: any;
  enableLoginViaEmail: boolean = true;

  // forgot password
  @Input() forgotPasswordScreen = false;
  forgotPasswordUserName: string = '';
  realmname: string;
  env: string;
  isEzit: boolean = false;

  constructor(
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userFacade: UsersFacadeService,
    private alertService: AlertService,
    public translator: TranslatorService,
    private loader: LoaderService,
    public  keycloak: KeycloakService,
    private credentialsService: CredentialsService,
    private tenantCustomUiFacadeService: TenantCustomUiFacadeService,
    private gsiFacade: GsiFacadeService,
    private manageConfigurationFacade: ManageConfigurationFacadeService,
    private ssoService: SsoLoginService,
    private onboardingFacadeService: OnboardingFacadeService,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public transactionFacadeService: TransactionFacadeService,
    public sharedfacade: SharedFacadeService,
    ) {
    super(injector);
    this.transactionFacadeService = injector.get(TransactionFacadeService);
    this.getEnvConfigInfo();
    this.clientId = localStorage.getItem('envClientId');
    this.tenantName = localStorage.getItem('TenantName');
    this.envName = localStorage.getItem('environmentName');
    localStorage.removeItem('selectedRole');
    this.setKeycloakConfg();
    this.redirectUrl = this.route.snapshot.queryParams['redirect']
      ? this.getRedirectedUrl()
      : '/mylibrary-dashboard';
    this.createForm();
    this.detectLanguageChange();
    this.detectCarnivalProfileInfo();
    this.detectActiveUserInfo();
    this.adminHeaderSettings(); //for tenant specific logo in login header
    this.detectTenantUi(); //for tenant specific logo in login header
    this.detectCredsFromParent();
    this.detectHomeContentStatus();
    this.getCountryCodes();
    this.getPublicRoles();
    this.detectGsiMappings();
    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        // gtag('event', 'page_view', {
        //   page_path: data?.urlAfterRedirects,
        //   page_name: 'login-page',
        // });
      });
    this.currentScreen = this.tenantCustomUiFacadeService.detectScreenSize();
    this.generateCaptcha();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentScreen = this.tenantCustomUiFacadeService.detectScreenSize();
  }

  ngOnInit() {
    this.addingCDNURL();

    /* istanbul ignore next */
    this.redirectUrl = this.route.snapshot.queryParams['redirect']
      ? this.getRedirectedUrl()
      : '/mylibrary-dashboard';
    if (!this.labels && checkTokenExistsorNot()) {
      this.translator.getStaticLabels(localStorage.getItem('locale'));
    } else {
      this.translator.getStaticJsonFromLocale();
    }

    /* istanbul ignore next */
    if (this.route?.snapshot?.queryParams?.['otpLogin'] === 'true') {
      console.log(this.route.snapshot);
      this.otpLoginClick();
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.username_or_password_incorrect = this.labels['Username_or_password_incorrect.'];
    });
  }

  detectCredsFromParent() {
    this.ssoService.detectSSOObeservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      if (data) {
        let res = localStorage.getItem('lowKey');
        let data = JSON.parse(atob(res));
        this.loginForm.patchValue({
          username: data?.userName,
          password: data?.password,
        });
        this.login();
      }
    });
  }

  //This function gets list of identity providers i.e google , facebook etc.
  async getIdentityProvidersList() {
    this.authenticationService.identityProvidersList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      const providersList = res?.result;
      /* istanbul ignore next */
      if (providersList?.length > 0) {
        this.identityProvidersList = providersList.map((x: any) => {
          return {
            ...x,
            isImageExist: validateProviderImageExistOrNot(x?.alias),
            displayName: x?.displayName ? x?.displayName : x?.alias,
          };
        });
      }
    });
  }

  addingCDNURL() {
    let staticPath = '../../../assets/json/login_earth.json';
    /*istanbul ignore next */
    if (this.environment.cdnUrl) {
      let staticUrl = staticPath.split('assets');
      this.lottieOptions = {
        path: this.environment.cdnUrl + '/assets' + staticUrl[1],
      };
    } else {
      this.lottieOptions = {
        path: staticPath,
      };
    }
  }

  //This function will bypass keycloak login using silent sso reridection and open up respective login pages
  socialLogin(hint: string, redirectUrl?: string) {
    if (this.mode === 'transaction') {
      /* istanbul ignore next */
      if (this.keycloak.getKeycloakInstance()?.realm) {
        sessionStorage.setItem('TenantId', this.keycloak.getKeycloakInstance()?.realm);
      }
      /* istanbul ignore next */
      if (this.keycloak?.getKeycloakInstance()?.authenticated) {
        const data: Credentials = {
          //@ts-ignore the response from keycloak is not in sync with the package version
          username: this.keycloak.getKeycloakInstance().tokenParsed.preferred_username,
          token: this.keycloak.getKeycloakInstance().token,
          client_id: this.clientId,
          expires_in: 600,
          last_loginTime: new Date(),
        };
        this.userFacade.getLocationTracking();
        this.userFacade.getUserProfile();
        // this.translator.getStaticLabels('EN');
        this.credentialsService.setCredentials(data, true);
        localStorage.setItem('ActiveUserFlag', 'user');
        this.userFacade.updateActiveUserFlag('user');
        this.userFacade.loadActiveUsers('login');
        this.adminHeaderSettings();
        this.userFacade.checkToken(true);
        localStorage.setItem('isSocialLogin', 'true');
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
        }
      } else {
        /* istanbul ignore next */
        this.keycloak?.login({ idpHint: hint });
      }
      /* istanbul ignore next */
      this.keycloak
        .getKeycloakInstance()
        .loadUserProfile()
        .then((data: any) => {})
        .catch(() => {
          console.error('Failed');
        });
      /* istanbul ignore next */
      this.keycloak.getKeycloakInstance().onAuthSuccess = function () {
        alert('authenticated');
      };
    }
  }

  /** Function to route User to forgot Password Page when he clicks on Forgot Password Link.  */
  forgotClick(defaultTemplate?: boolean) {
    this.forgotPasswordScreen = true;
    this.realmname = localStorage.getItem('TenantName');
    this.env = localStorage.getItem('environmentName');
    /* istanbul ignore next */
    if (this.customLoginTenants?.includes(this.realmname)) {
      this.isCustomLoginTenant = true;
    }
    /* istanbul ignore next */
    if (this.lmsTenants?.includes(this.realmname)) {
      this.isLmsTenant = true;
    }
    // if (this.mode === 'transaction') {
    //   this.router.navigate(['/forgotPass'], { replaceUrl: true });
    // }
    if (this.mode === 'transaction' && this.customLoginFlag === false) {
      if (!defaultTemplate) {
        this.router.navigate(['/forgotPass'], { replaceUrl: true });
      } else {
        this.router.navigate(['/login/forgotPass'], { replaceUrl: true });
      }
    }
  }

  fpclickSubmit() {
    let obj: any = {};
    obj.realm = this.realmname;
    obj.email = this.forgotPasswordUserName;
    obj.environmentName = this.env;
    this.loader.show();
    this.authenticationService
      .forgotPassword(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
        }
      });
  }

  fpcancelClick() {
    this.forgotPasswordScreen = false;
  }

  selfonboardSignUp() {
    if (this.mode === 'transaction') {
      this.router.navigate(['/selfOnboardSignUp'], { replaceUrl: true });
    }
  }

  // Login OTP Feature's Function Calls Starts Here //
  /** Function for Loggin VIA OTP Starts */

  otpLoginClick() {
    this.otpLogin = true;
    this.otpCapture = '';
    this.mobileNumber = '';
    this.mobileNumberFieldEntered = false;
  }

  /** Function for Loggin VIA OTP Ends */

  /** Function for Countdown Timer Starts */
  startTimer(duration: any) {
    let timer = duration,
      minutes,
      seconds,
      count = 0;
    /* istanbul ignore next */
    this.otpTimer = setInterval(() => {
      /*istanbul ignore else*/
      if (count >= 60) this.resendOTP = true;

      minutes = Math.trunc(timer / 60);
      seconds = timer % 60;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.display = minutes + ':' + seconds;
      count = count + 1;
      if (--timer < 0) {
        timer = 0;
        this.resendOTP = true;
        clearInterval(this.otpTimer);
      }
    }, 1000);
  }

  /** Function for Countdown Timer Ends */

  /** Hide and show method to showcase the Input field once Generate OTP method is called Starts */
  mobileNumberEntered(mobileNumber: any) {
    this.capturedMobileNumber = mobileNumber;
  }

  /** Hide and show method to showcase the Input field once Generate OTP method is called Starts */

  /** Function to send API for OTP Generation Starts */
  /* istanbul ignore next */
  generateOTP() {
    if (this.mobileNumber?.length == 0) {
      this.alertService.showToaster('Please provide mobile number', '', 'info');
      return;
    }
    if (this.mode === 'transaction') {
      this.isOtpGeneratedOnce = true;
      if (this.selectedCountryCode?.length > 0) {
        this.countrycode = this.selectedCountryCode.replace('+', '%2B');
      }
      this.loader.show();
      this.mobileNbr = this.mobileNumber;
      let payload = {
        countryCode: this.countrycode,
        mobileNumber: this.mobileNbr,
        tenantName: this.clientId,
      }

      this.authenticationService
      .generateOtp(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res) {
            if (res.status == '200') {
              this.mobileNumberFieldEntered = true;
              this.otpValidval = true;
              const fiveMinutes = this.otpTimeOutDuration ? Number(this.otpTimeOutDuration) * 60 : 180;
              clearInterval(this.otpTimer);
              this.startTimer(fiveMinutes);
              document.getElementById('sentOTPbtn').innerHTML = 'Resend OTP';
              this.otpSubmitButtonVisibility = true;
              this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
            } else {
              this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
            }
          }
        });
    }
  }

  /** Function to send API for OTP Generation Starts */

  /** Function to Validate OTP Starts Here  */

  validateOTP() {
    if (this.mode === 'transaction') {
      /* istanbul ignore next */
      this.redirectUrl = this.route.snapshot.queryParams['redirect']
        ? this.getRedirectedUrl()
        : '/cdui/mylibrary-dashboard';
      this.mobileNbr = this.mobileNumber;
      this.OTPNbr = this.otpCapture;
      let guestId: any = '';
      let country = '';
      /*istanbul ignore else*/
      if (this.selectedCountryCode.length > 0) {
        country = this.selectedCountryCode.replace('+', '%2B');
      }
      if (localStorage.getItem('isGuestUser') == 'true') {
        guestId = JSON.parse(localStorage.getItem('ActiveUserInfo')).id;
      }
      let payload = {
        countryCode: country,
        mobileNumber: this.mobileNbr,
        tenantName: this.clientId,
        otpNumber: this.OTPNbr,
        guestUserId: guestId,
      }
      /* istanbul ignore next */
      this.authenticationService
      .validateOTP(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          if (res.result) {
            const data: Credentials = {
              username: '',
              token: res.result.access_token,
              client_id: this.clientId,
              refresh_token: res.result.refresh_token,
              expires_in: res.result.expires_in,
              last_loginTime: new Date(),
            };
            this.tenantCustomUiFacadeService.savedHeaderTemplates.next(null);
            this.credentialsService.setCredentials(data, true);
            this.userFacade.getLocationTracking();
            this.userFacade.getUserProfile();
            // this.translator.getStaticLabels('EN');
            this.userFacade.loadActiveUsers('login');
            sessionStorage.setItem('TenantId', this.tenantName);
            localStorage.setItem('ActiveUserFlag', 'user');
            this.userFacade.updateActiveUserFlag('user');
            this.adminHeaderSettings();
            this.userFacade.checkToken(true);
            localStorage.setItem('isGuestUser', 'false');

            this.alertService.showToaster(res.message, '', 'success');
          } else {
            this.otpCapture = '';
            this.alertService.showToaster(res.message, '', 'error');
          }
        });
    }
  }

  /** Function to Validate OTP Ends Here  */

  /** Function Capture OTP Starts */
  captureOTP(data: any) {
    this.OTPNbr = data;
  }
  /** Function Capture OTP Ends */

  /** Function for Resending OTP Starts */
  resendOTPtoMobile() {
    this.generateOTP();
  }
  /** Function for Resending OTP Ends */
  // Login OTP Feature's Function Calls Starts Here //

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // detect Carnival Profile Details
  detectCarnivalProfileInfo() {
    this.userFacade.userProfileInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res?.profileType) {
        this.profileTypeExist = true;
        /* istanbul ignore next */
        this.carnivalCategory = res?.profileType;
      }
    });
  }

  detectActiveUserInfo() {
    this.userFacade.activeUserInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res && this.envName) {
        let userInfo: any = res;
        this.loader.show();
        /* istanbul ignore next */
        this.authenticationService
          .getEvnByName(this.envName)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((tenantDetails: any) => {
            this.loader.hide();
            /* istanbul ignore next */
            this.tenantType = tenantDetails?.tenantInfoModel?.tenantType;
            /* istanbul ignore next */
            this.tenantId = tenantDetails.tenantInfoModel?.id;
            /* istanbul ignore next */
            if (this.tenantId) {
              localStorage.setItem('TenantId', this.tenantId);
            }
            localStorage.setItem('envType', tenantDetails.type);
            /*istanbul ignore else */
            if (userInfo?.planNFeatureMapping?.planName === 'Advertiser') {
              this.redirectUrl = '/admin/Advertiser-ad-dashboard';
            }
            /* istanbul ignore else */
            if (userInfo?.groups && userInfo?.groups.length > 0) {
              for (let user of userInfo.groups) {
                const x = user;
                if (x.name == 'Environment_Admin') {
                  sessionStorage.setItem('groupType', 'Environment_Admin');
                  localStorage.setItem('ActiveUserFlag', 'Environment_Admin');
                  this.userFacade.updateActiveUserFlag('Environment_Admin');
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  if (this.tenantType == 'saas') {
                    localStorage.setItem('TenantType', 'saas');
                  } else {
                    localStorage.removeItem('TenantType');
                  }
                  break;
                } else if (x.name == 'Tenant_Admin') {
                  sessionStorage.setItem('groupType', 'Tenant_Admin');
                  localStorage.setItem('ActiveUserFlag', 'Tenant_Admin');
                  this.userFacade.updateActiveUserFlag('Tenant_Admin');
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  if (this.tenantType == 'saas') {
                    localStorage.setItem('TenantType', 'saas');
                  } else {
                    localStorage.removeItem('TenantType');
                  }
                  break;
                } else if (x.name == 'Platform_Admin') {
                  sessionStorage.setItem('groupType', 'Platform_Admin');
                  localStorage.setItem('ActiveUserFlag', x.name);
                  this.userFacade.updateActiveUserFlag(x.name);
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  break;
                } else if (x.name == 'selfonboard_Admin') {
                  sessionStorage.setItem('groupType', 'selfonboard_Admin');
                  localStorage.setItem('ActiveUserFlag', x.name);
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  break;
                } else if (x.name == 'FeatureFlag_Admin') {
                  sessionStorage.setItem('groupType', 'FeatureFlag_Admin');
                  localStorage.setItem('ActiveUserFlag', x.name);
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  break;
                } 
                else if (x.name == 'TnC_Admin') {
                  localStorage.setItem('ActiveUserFlag', x.name);
                  this.router.navigate(['/admin'], { replaceUrl: true });
                  break;
                }else {
                  if ((x.groupType == 'SUB_ADMIN')) {
                    sessionStorage.setItem('groupType', `SUB_ADMIN:${x.name}`);
                    this.detectsubadminBookList(userInfo, x.name);
                  }
                  break;
                }
              }
            } else {
              this.defaultRedirectAfterlogin(userInfo);
            }
          });
      }
    });
  }
  /**
   * sub-0a
   * @param userInfo
   * @param groupName
   * fetchsubadmin books list if there is no book then act as normal user
   */
   detectsubadminBookList(userInfo: any, groupName: string) {
    this.userFacade
      .detectsubadminBookList(groupName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.result.adminBookList.length > 0) {
            sessionStorage.setItem('bookList', JSON.stringify(res.result.adminBookList));
            for (let book of res.result.adminBookList) {
              if (book.adminGroups[0] == 'Environment_Admin') {
                localStorage.setItem('ActiveUserFlag', 'Environment_Admin');
                this.userFacade.updateActiveUserFlag('Environment_Admin');
                break;
              }
            }
            for (let book of res.result.adminBookList) {
              if (book.adminGroups[0] == 'Tenant_Admin') {
                localStorage.setItem('ActiveUserFlag', 'Tenant_Admin');
                this.userFacade.updateActiveUserFlag('Tenant_Admin');
                break;
              }
            }
            this.sharedfacade.changeGroup(localStorage.getItem('ActiveUserFlag'));
            this.router.navigate(['/admin'], { replaceUrl: true });
          } else {
            this.defaultRedirectAfterlogin(userInfo);
          }
        },
        (error: any) => {
          this.defaultRedirectAfterlogin(userInfo);
          // this.alertService.showToaster()
        }
      );
  }

  detectHomeContentStatus() {
    this.tenantCustomUiFacadeService.enableHomeInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.isRedirectedtoLoginPage = true;
      }
    });
  }

  //redirection after login
  defaultRedirectAfterlogin(userInfo: any) {
    localStorage.setItem('ActiveUserFlag', 'user');
    this.userFacade.updateActiveUserFlag('user');
    /* istanbul ignore next */
    this.carnivalCategory = this.carnivalCategory ? this.carnivalCategory : userInfo?.profileType;
    // check if roleselection required-
    // check if intersectionRole length greater than 0 return true, else return false
    let rolescheck = this.roleselectionRequired(userInfo);
    //default behaviour signupgsiexecutionis mandatory and executed succefully no need to select role get gsi executed
    if (rolescheck) {
      // this function check if there is only 1 role then direct go to navigation else open dialog
      this.openDialog();
    } else {
      /* istanbul ignore next */
      if (this.carnivalCategory) {
        this.validateCarnivalStatus();
      } else {
        let isGuest: boolean = localStorage.getItem('isGuestUser') === 'true' ? true : false;
        /* istanbul ignore next */
        // if (userInfo?.roles?.find((m: any) => m?.name === 'Guest' || m?.roleName === 'Guest')) {
        //   this.redirectUrl = '/cdui/mylibrary-dashboard';
        // }
        /* istanbul ignore next */
        if (
          this.redirectUrl &&
          (isGuest || this.credentialsService.isAuthenticated()) &&
          !this.isRedirectedtoLoginPage
        ) {
          // this.router.navigateByUrl(this.redirectUrl, { replaceUrl: true });
          this.userFacade.validateMCCDetails(this.redirectUrl);
        }
        /* istanbul ignore next */
        if (this.tenantType == 'saas' || this.tenantType == 'subtenant') {
          localStorage.setItem('TenantType', this.tenantType);
        } else {
          localStorage.removeItem('TenantType');
        }
      }
    }
  }

  validateCarnivalStatus() {
    /* istanbul ignore next */
    if (this.carnivalCategory !== '') {
      const featureName = `carnival.${this.carnivalCategory}`;
      this.canivalRouteDecision(featureName);
      localStorage.setItem('carnivalPersona', this.carnivalCategory);

      // update profile api with persona
      /* istanbul ignore next */
      if (!this.profileTypeExist) {
        const personaDetails = { profileType: this.carnivalCategory };
        this.userFacade.addUserProfile(personaDetails, true);
      }
    }
  }

  canivalRouteDecision(featureName: any) {
    this.userFacade.getMatchingFeatureflag(featureName).subscribe((res: any) => {
      /* istanbul ignore next */
      const isfeatureFlag = res?.result;
      if (isfeatureFlag) {
        const carnivalFeatureDetails = JSON.stringify(res);
        localStorage.setItem('carnivalEventFlag', carnivalFeatureDetails);

        this.router.navigate([`/carnival/category/${this.carnivalCategory}/ref_pg/1`]);
      } else {
        this.userFacade.validateMCCDetails('/mylibrary-dashboard');
      }
    });
  }

  login() {
    localStorage.removeItem('customPageId');
    if (this.mode === 'transaction') {
      this.redirectUrl = this.route.snapshot.queryParams['redirect']
        ? this.getRedirectedUrl()
        : '/mylibrary-dashboard';
      this.tenantType = '';
      this.isLoading = true;
      let guestId: any = '';
      let userName = JSON.parse(localStorage.getItem('ActiveUserInfo'))?.guestUserName;
      if (localStorage.getItem('isGuestUser') == 'true' && userName && userName.includes('nslguest')) {
        guestId = JSON.parse(localStorage.getItem('ActiveUserInfo'))?.id;
      }

      /* istanbul ignore next */
      const login$ = this.authenticationService?.login(
        {
          userName: this.loginForm.value.username.trim(),
          encryptedPassword: this.encrypt(this.loginForm.value.password),
          tenantName: localStorage.getItem('TenantName'),
          clientId: localStorage.getItem('envClientId'),
          guestUserId: guestId,
        },
        this.loginForm.value.remember
      );
      /* istanbul ignore next */
      login$
        .pipe(
          finalize(() => {
            this.loginForm.markAsPristine();
            this.isLoading = false;
          }),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (response: any) => {
            if (response?.result && this.signupProperties2fa?.sessionType != '2fa') {
              if (
                response?.status == 200 &&
                response?.message == 'Please update your password to activate your account'
              ) {
                this.openPasswordPopup(response);
                this.alertService.showToaster(response?.message, '', 'info');
                return;
              }
              this.tenantCustomUiFacadeService.savedHeaderTemplates.next(null);
              this.isRedirectedtoLoginPage = false;
              sessionStorage.setItem('resetSelectedRoleOnLogin', 'true');
              this.userFacade.getLocationTracking();
              this.userFacade.getUserProfile();
              // this.translator.getStaticLabels('EN');
              this.userFacade.loadActiveUsers('login');
              sessionStorage.setItem('TenantId', this.tenantName);
              this.adminHeaderSettings();
              this.userFacade.checkToken(true);
              localStorage.setItem('isGuestUser', 'false');
              this.detectLogoutConfiguration();
            } else if (this.signupProperties2fa?.sessionType == '2fa') {
              this.twofactorverification = true;
              this.context = JSON.parse(localStorage.getItem('loginContext'));
              this.alertService.showToaster(
                'Otp has been successfully sent to Mobile ending with',
                response?.result?.lastDigits,
                'success'
              );
              this.otpTimerFor2fa();
              this.detectLogoutConfiguration();
            } else {
              this.alertService.showToaster(response?.message, '', 'error');
            }
          },
          (error) => {
            log.debug(`Login error: ${error}`);
            this.error = error;
          }
        );
    }
  }
  detectLogoutConfiguration() {
    localStorage.setItem('isLogoutConfigurationEnabled', 'true');
  }


  openPasswordPopup(response: any) {
    this.dialog.closeAll();
    const dialogRef = this.dialog?.open(ResetPasswordComponent, {
      data: { response: response },
      panelClass: 'reset-password-panel-class',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      /*istanbul ignore else*/
      if (result?.status == 'pass') {
        this.loginForm.value.username = result.userName;
        this.loginForm.value.password = result.newPassword;
        localStorage.setItem('TenantName', result.tenantName);
        localStorage.setItem('envClientId', result.clientId);

        this.userFacade.updateActiveUserInfo(null);
        /*istanbul ignore else*/
        if (localStorage.userRoles) {
          localStorage.removeItem('userRoles');
        }
        localStorage.removeItem('selectedRole');
        this.router.navigate(['/login']);
        this.login();
      }
    });
  }

  encrypt(password: string) {
    const publicKey =
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLU0whc9gpsHOER0wCrE/j13OASD1h/fL9lFXgrDT1HynMhxwIhCJPja7ww06wA3B0uZ8kxEfCavohvbmjMph3CoPRXWynWuvenDGB2ek1nbCg2aHJvmb3Zg1Coea0gmGkBorVWtqkwmLpuX/2Jq+W+UoWSMfePuyPC3kY4VwFpQIDAQAB';
    this.$encrypt = new JSEncrypt();
    const text = `${password}`;
    /* istanbul ignore else */
    if (text.length < 117) {
      this.$encrypt.setPublicKey(publicKey);
      return this.$encrypt.encrypt(text);
    }
  }

  /**
   * Accepts mobile number
   * @param event
   */
  acceptMobileNumber(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    /* istanbul ignore next */
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    document.getElementById('sentOTPbtn').innerHTML = 'Send OTP';
    this.mobileNumberFieldEntered = false;
  }

  /**
   * Checks for numbers
   * @param event
   * @returns
   */
  checkForNumbers(event: any) {
    this.OTPNbr = this.otpCapture;
    if (
      event.keyCode !== 69 &&
      event.keyCode !== 187 &&
      event.keyCode !== 189 &&
      event.keyCode !== 107 &&
      event.keyCode !== 189 &&
      event.keyCode !== 109 &&
      event.keyCode !== 173 &&
      event.keyCode !== 190
    ) {
      return true;
    } else {
      return false;
    }
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      // tenant: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
      captcha: new FormControl(),
    });
  }

  /**
   * Cancels forgot password. Navigates back to login page
   */
  cancelForgotPassword() {
    this.otpLogin = false;
  }
  // including for tenant specific header (DYUI-2146)
  adminHeaderSettings() {
    this.tenantName = localStorage.getItem('TenantName');
    /* istanbul ignore else */
    let envClientID = localStorage.getItem('envClientId');

    if (this.tenantName && checkTokenExistsorNot()) {
      this.tenantCustomUiFacadeService.fetchTenantData(envClientID ? envClientID : this.tenantName);
    }
  }
  // including for tenant specific header (DYUI-2146)
  detectTenantUi() {
    this.tenantCustomUiFacadeService.savedTemplate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data?: any) => {
      /* istanbul ignore else */
      if (this.headerDesignData && data) {
        /* istanbul ignore next */
        this.headerDesignData.logo.desktop = data?.result?.header?.logo?.desktop;
        const selectedFont = findFont(data?.result?.font);
        localStorage.setItem('selectedFont', JSON.stringify(selectedFont));
        /* istanbul ignore next */
        if (selectedFont?.fontName) {
          document.documentElement.style.setProperty('--global-font-family', selectedFont?.fontName);
        }
      }
    });
  }

  // for validation integer only accepted
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  /**
   * Sets b2b2c login state based upon the redirect url
   */
  setB2B2CLoginState() {
    this.route.queryParams.subscribe((params) => {
      /* istanbul ignore else */
      if (params && params.redirect) {
        const idpHint = this.getParameterByName('idpHint', params.redirect);
        /* istanbul ignore else */
        if (idpHint) {
          this.socialLogin(idpHint, params.redirect);
        }
      }
    });
  }

  /**
   * Gets parameter by name. Used to extract params from a redirect url
   * @param name param that needs to be searched
   * @param url redirect url from which param needs to be searched
   * @returns  matched param value
   */
  getParameterByName(name: string, url: string) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(url);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }

  forgotCustomerid() {
    if (this.mode === 'transaction') {
      this.router.navigateByUrl('login/forgotCustomerId');
    }
  }

  /* istanbul ignore next */
  cancelClick() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  /** Function to make API call with required Body Obj to forgot password API when we click on Submit btn */
  clickSubmit() {
    if (this.mode === 'transaction') {
      let guestUserId: any = '';
      let userName = JSON.parse(localStorage.getItem('ActiveUserInfo'))?.guestUserName;
      if (localStorage.getItem('isGuestUser') == 'true' && userName && userName.includes('nslguest')) {
        guestUserId = JSON.parse(localStorage.getItem('ActiveUserInfo'))?.id;
      }
      this.loader.show();
      // this.context = JSON.parse(localStorage.getItem('loginContext'));
      this.authenticationService
        .validate2fa(this.otp, this.context?.userName, this.context?.tenantName, guestUserId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /*istanbul ignore else */
          if (res?.result) {
            const data: Credentials = {
              username: this.context?.userName,
              token: res.result?.access_token,
              client_id: this.context?.clientId,
              refresh_token: res.result?.refresh_token,
              expires_in: res.result?.expires_in,
              last_loginTime: new Date(),
              tenant: this.context?.tenantName,
            };
            this.credentialsService.setCredentials(data, true);
            // this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
            this.userFacade.getLocationTracking();
            this.userFacade.getUserProfile();
            // this.translator.getStaticLabels('EN');
            this.userFacade.loadActiveUsers('login');
            sessionStorage.setItem('TenantId', this.context?.tenantName);
            this.adminHeaderSettings();
            this.userFacade.checkToken(true);
            localStorage.setItem('isGuestUser', 'false');
          } else {
            this.alertService.showToaster(res?.message, '', 'error');
          }
        });
    }
  }

  resendOtp() {
    if (this.mode === 'transaction') {
      this.loader.show();
      this.context = JSON.parse(localStorage.getItem('loginContext'));
      this.otpTimerFor2fa();
      let payload = {
        tenantName: this.context?.tenantName,
        userName: this.context?.userName,
      }
      this.authenticationService
      .resend2fa(payload)        
      .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /*istanbul ignore else */
          if (res) {
            this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
          }
        });
    }
  }

  otpTimerFor2fa() {
    this.timeLeft = 30;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  getCountryCodes() {
    this.onboardingFacadeService
      .getCountryCodes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.countryCodes = res.result;
      });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  getRedirectedUrl() {
    const url = this.route.snapshot.queryParams['redirect'];
    /* istanbul ignore next */
    return url;
  }

  preventPasting(event: any) {
    event.preventDefault();
  }

  generateCaptcha() {
    this.isReloaded = true;
    this.epocTime = Date.now();
    let url = this.authenticationService.generateCaptcha(this.epocTime, this.clientId);
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        this.captchaURL = URL.createObjectURL(blob);
        this.isReloaded = false;
      });
  }

  verifyCaptcha() {
    this.authenticationService
      .verifyCaptcha(this.loginForm.value.captcha, this.epocTime, this.clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.status == 200) {
          this.isCaptchaValid = true;
          this.login();
        }
        /*istanbul ignore else */
        if (res && res.status != 200) {
          this.loginForm.get('captcha').patchValue('');
          this.generateCaptcha();
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }
  publicRoleList: any = [];
  roleListActiveUser: any = [];
  roleSelectionList: any = [];
  getPublicRoles() {
    let clientId = localStorage.getItem('envClientId');
    this.userFacade
      .getPublicRolesLogin(clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.publicRoleList = res;
        }
      });
  }
  roleSelectionMandatoryOnLogin: boolean = false;
  roleselectionRequired(userInfo: any) {
    let enableLoginGSIConfiguration = sessionStorage.getItem('enableLoginGSIConfiguration'); // from envConfigInfo
    let signUpGsiExecutionMandatory = sessionStorage.getItem('signUpGsiExecutionMandatory'); // from envConfigInfo
    /*istanbul ignore next */
    let signUpGSIExecutedSuccessfully = userInfo?.signUpGSIExecutedSuccessfully
      ? userInfo.signUpGSIExecutedSuccessfully
      : false; // from userInfo
    /*istanbul ignore next */
    this.roleSelectionMandatoryOnLogin =
    this.envConfigData?.rolesSelectionMandatoryOnLogin
    /*istanbul ignore next */
    if (enableLoginGSIConfiguration && this.router?.url?.toLowerCase().includes('login')) {
      //ROLE SELECTION this function return true if there is any role
      return this.getRoleSelection();
    } else if (signUpGsiExecutionMandatory && !signUpGSIExecutedSuccessfully && this.router?.url?.toLowerCase().includes('signup')) {
      //role selection  signupgsiexecution is mandatory  but its not executed succefully once
      //ROLE SELECTION this function return true if there is any role
      return this.getRoleSelection();
    } else {
      return false;
    }
  }
  getRoleSelection() {
    // this.getPublicRoleList = this.getPublicRoles();
    this.roleListActiveUser = JSON.parse(localStorage.getItem('ActiveUserInfo')).roles;
    this.hasingRoleList();
    if (this.roleSelectionList.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  openDialog() {
    if (this.roleSelectionList.length == 1 && this.roleSelectionMandatoryOnLogin) {
      this.selectedRoleforGSIExecution(this.roleSelectionList[0]);
    } else {
      /*istanbul ignore next*/
      const dialogRef = this.dialog.open(SelectRolefromPublicUserRoleComponent, {
        disableClose: true,
        width: '100%',
        panelClass: 'SelectRolefromPublicUserRoleComponent',
        data: {
          roleSelectionList: this.roleSelectionList,
          roleSelectionMandatoryOnLogin: this.roleSelectionMandatoryOnLogin,
        },
      });
      // result can be null if user did not selected any role : roleSelectionMandatoryOnLogin = false
      // result can be role object then this function then go for gsiexecution
      /*istanbul ignore next*/
      dialogRef.afterClosed().subscribe((result: any) => {
        /* istanbul ignore next */
        if (result) {
          this.selectedRoleforGSIExecution(result);
        }
      });
    }
  }

  hasingRoleList() {
    let obj = {};
    this.roleSelectionList = [];
    this.roleListActiveUser.forEach((roleuser: any) => {
      return (obj[roleuser.id] = roleuser);
    });
    this.publicRoleList.forEach((publicrole: any) => {
      if (obj?.hasOwnProperty(publicrole.id)) {
        this.roleSelectionList.push(publicrole);
      }
    });
  }
  selectedRoleforGSIExecution(role: any) {
    /* istanbul ignore next */
    if (role?.name) {
      this.onboardingFacadeService.getGsiMappingsByRoleName(role?.name, 'login');
      /* istanbul ignore next */
      this.dialog?.closeAll();
    } else {
      this.alertService.showToaster('No role selected', '', 'info');
      this.userFacade.validateMCCDetails('/mylibrary-dashboard');
      /* istanbul ignore next */
      this.dialog?.closeAll();
    }
  }
  detectGsiMappings() {
    this.onboardingFacadeService.gsiListByRoleName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result) {
        this.transactionFacadeService.setLogoutConfigGsiFlag(false);
        localStorage.setItem('isDisableHeader', 'true');
        this.userFacade.disableSideMenu();
        const solutionData = btoa(JSON.stringify({ bookId: res?.result?.bookId, gsiId: res?.result?.gsiId }));
        /* istanbul ignore next */
        localStorage.setItem('gsi-masterId', res?.result?.gsiMasterId);
        this.router.navigate(['transaction/view/' + solutionData]);
      } else {
        this.userFacade.validateMCCDetails('/mylibrary-dashboard');
      }
    });
  }
  checkCaptchEnabled() {
    if (this.isCaptchaEnabled) this.verifyCaptcha();
    else this.login();
  }
  openTncDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, { data: this.termsAndConditions, width: '500px', height: '500px' });
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  ngAfterViewInit() {
    setTimeout(()=>{
      this.checkKeycloakAuthentication();
    },1000);
  }

  checkKeycloakAuthentication() {
    /* istanbul ignore next */
    if (this.keycloak?.getKeycloakInstance()?.realm) {
      sessionStorage.setItem('TenantId', this.keycloak.getKeycloakInstance()?.realm);
    }
    /* istanbul ignore next */
    console.log('Cookies', this.keycloak?.getKeycloakInstance()?.authenticated);
    if (this.keycloak?.getKeycloakInstance()?.authenticated) {
      const data: Credentials = {
        username: this.keycloak.getKeycloakInstance()?.profile?.username,
        token: this.keycloak.getKeycloakInstance().token,
        client_id: this.clientId,
        //refresh_token: this.keycloak.getKeycloakInstance().refreshToken,
        expires_in: 600,
        last_loginTime: new Date(),
      };
      this.credentialsService.setCredentials(data, true);
      this.isRedirectedtoLoginPage = false;
      sessionStorage.setItem('resetSelectedRoleOnLogin', 'true');
      this.userFacade.getLocationTracking();
      this.userFacade.getUserProfile();
      this.translator.getStaticLabels('EN');
      localStorage.setItem('ActiveUserFlag', 'user');
      this.userFacade.updateActiveUserFlag('user');
      this.userFacade.loadActiveUsers('login');
      this.userFacade.checkToken(true);
      localStorage.setItem('isSocialLogin', 'true');
      localStorage.setItem('isGuestUser', 'false');
    }
  }
  

  getEnvConfigInfo(){
      this.envConfigData = JSON.parse(localStorage.getItem('envConfigInfo'));
      if (this.envConfigData) {
        this.otpTimeOutDuration = this.envConfigData?.otpPolicy?.otpTimeout;
        this.signupProperties2fa = this.envConfigData?.otpPolicy;
        if (this.signupProperties2fa?.sessionType == '2fa') {
          localStorage.setItem('2fa', 'true');
        }
        localStorage.setItem('enableOtpViaMobile', this.envConfigData?.otpConfigurationEnabled);
        if (this.envConfigData?.canUsersSelfSignUp) {
          this.allowSelfSignup = true;
        }
        /* istanbul ignore else */
        if (this.envConfigData?.otpConfigurationEnabled) {
          this.enableLoginViaMobile = this.envConfigData?.otpConfigurationEnabled;
          this.enableLoginViaOtp = this.enableLoginViaMobile == 'true';
          /*istanbul ignore else */
          if (this.envConfigData?.loginOptions) {
            let loginOptions = this.envConfigData.loginOptions;
            this.enableLoginViaEmail = loginOptions.includes('Email');
            this.enableLoginViaOtp = loginOptions.includes('Mobile');
          }
          /*istanbul ignore else */
          if (this.envConfigData?.primaryLoginMethod) {
            this.primaryLoginMethod = this.envConfigData.primaryLoginMethod;
            this.otpLogin = this.primaryLoginMethod?.includes('Mobile');
          }
        }
        /* istanbul ignore next */
        else {
          this.otpLogin = false;
          this.enableLoginViaEmail = true;
          this.primaryLoginMethod = 'SignInViaEmail';
        }
        /*istanbul ignore next */
        if (this.envConfigData?.canCreateSubTenants) {
          sessionStorage.setItem('canCreateSubTenants', 'true');
        } else {
          sessionStorage.setItem('canCreateSubTenants', 'false');
        }
        /*istanbul ignore next */
        if (this.envConfigData?.enableLoginGSIConfiguration) {
          sessionStorage.setItem('enableLoginGSIConfiguration', this.envConfigData.enableLoginGSIConfiguration);
        }
        /*istanbul ignore next */
        if (this.envConfigData?.signUpGsiExecutionMandatory) {
          sessionStorage.setItem('signUpGsiExecutionMandatory', this.envConfigData.signUpGsiExecutionMandatory);
        }
        /*istanbul ignore next */
        if (this.envConfigData?.captchaPolicy?.isCaptchaEnabled == 'true') {
          this.isCaptchaEnabled = true;
        }
        if (this.envConfigData?.termsAndConditions) {
          this.displayTermsField = true;
          this.termsAndConditions = this.sanitizer.bypassSecurityTrustResourceUrl(this.envConfigData.termsAndConditions);
        }
      }
  }

  setKeycloakConfg()
  {
    this.configService.setConfig().pipe(takeUntil(this.ngUnsubscribe)).subscribe((appConfigData:any)=>{
      const keyCloakOptions: any = {
        config: {
          url: `${appConfigData.loginAuth}`,
        },
        loadUserProfileAtStartUp: true,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          flow: 'implicit',
          enableLogging: true,
          silentCheckSsoRedirectUri: window?.location?.origin + '/assets/silent-check-sso.html',
        },
        bearerExcludedUrls: [],
      };
      keyCloakOptions.config.realm = localStorage.getItem('TenantName');
      keyCloakOptions.config.clientId = this.clientId;
      this.keycloak.init(keyCloakOptions);
    })
  }
}
