import { NgModule } from '@angular/core';
import { HtmlComponent } from './html.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [HtmlComponent],
  imports: [ControlsMaterialModule],
  exports: [HtmlComponent],
})
export class HtmlModule {
  static entry = {
    html: HtmlComponent,
    payment: HtmlComponent,
  };
}
