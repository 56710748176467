<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeAttribute()">
        close
      </button>
      <label class="status">Status: Published (Version {{ changeUnit?.version }})</label>
    </div>
    <div class="heading-area">
      <h2>Trigger CU Level Edit</h2>
      <!-- <div *ngIf="flagsave">
        <button class="save" (click)="saveEntitywithAttr()">Save</button>
      </div> -->
      <div>
        <button class="save" (click)="saveAttr()">Save</button>
      </div>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Attribute Details
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field class="mb-4 mr-3 w-100">
            <mat-label>{{ labels?.Change_Driver_Type }}</mat-label>
            <mat-select [(ngModel)]="selectedChangedriverOptAttr">
              <mat-option
                *ngFor="let opt of optChangeDrivers; let i = index"
                [value]="opt"
                (click)="onChangeOptionAttr(opt, i)"
              >
                {{ opt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mb-4 mr-3 w-100" *ngIf="selectedChangedriverOptAttr == 'ALTERNATIVE'">
            <mat-label>{{ labels?.Alternative_Attributes }}</mat-label>
            <mat-select [(ngModel)]="SelectedalternativeAttr">
              <mat-option
                *ngFor="let attr of altAttr; let i = index"
                [value]="attr.name"
                (click)="onChangeOptionAttr(attr.name, i)"
              >
                {{ attr.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="configurationData?.data?.attr?.name">
            <mat-label>Attribute Name</mat-label>
            <input matInput placeholder="Attribute name" [(ngModel)]="configurationData.data.attr.name" readonly />
          </mat-form-field>

          <mat-form-field *ngIf="configurationData?.data?.attr?.displayName">
            <mat-label>Display name</mat-label>
            <input matInput placeholder="Attribute name" [(ngModel)]="configurationData.data.attr.displayName" />
          </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Constant Values
            </mat-panel-title>
          </mat-expansion-panel-header>

          <form [formGroup]="attrDetails">
            <!-- Not for scheduler and location UI controls -->
            <ng-container
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'scheduler' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'location'
              "
            >
              <!-- For adding options in checkbox, dropdown and radioButton UI controls -->
              <!-- <div
                *ngIf="
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'checkbox' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'dropdown' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'radioButton' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'typeahead' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'list' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'multiselectdropdown' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipmultipleselection' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipsingleselection'
                "
              >
                <input
                  matInput
                  class="add-value w-100"
                  placeholder="Add Option"
                  id="inputData"
                  type="text"
                  autocomplete="off"
                  formControlName="defaultOption"
                />
                <button class="validate" id="addInputData" (click)="addDefaultValuetoAttibute()">
                  {{ labels.Add }}
                </button>
                <div *ngIf="sourceValues">
                  <div class="d-flex added" *ngFor="let attrvalue of sourceValues; let i = index">
                    {{ attrvalue.DATA?.displayValue }}
                    <span class="pointer"
                      ><img
                        (click)="removeScourceValue(i)"
                        id="attrremoveimg"
                        class="ml-2"
                        [src]="'assets/img/nsl-gram/icon-delete.svg' | CDNUrlRewritePipe"
                        alt=""
                    /></span>
                  </div>
                </div>
              </div> -->
              <ng-container
                [ngTemplateOutlet]="defaultValueBlock"
                [ngTemplateOutletContext]="{
                  attrDetails: configurationData?.data?.attr
                }"
              >
              </ng-container>

              <!-- For Location UI control -->
              <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location'">
                <mat-form-field>
                  <mat-label>{{ labels?.Location_Type }}</mat-label>
                  <mat-select formControlName="locationType">
                    <mat-option *ngFor="let option of locationArray" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- For Multi value -->
              <div class="multi-file-upload mb-4">
                <div class="multi-value">
                  <mat-label class="label-txt mr-2">Multi Value</mat-label>
                  <mat-radio-group fxLayout="column" formControlName="isMultiValue">
                    <mat-radio-button
                      [checked]="multiDefaultValue"
                      name="isMultiValue"
                      id="attribute_multiYes"
                      [value]="true"
                      [disabled]="true"
                    >
                      Yes
                    </mat-radio-button>
                    <mat-radio-button
                      [checked]="!multiDefaultValue"
                      name="isMultiValue"
                      id="attribute_multiNo"
                      [value]="false"
                      [disabled]="true"
                    >
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div
                  *ngIf="
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'file' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'image' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'audio' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'video'
                  "
                >
                  {{ attrDetails.value?.attributeType }}
                  <app-file-upload
                    [fileUploadFromEntity]="true"
                    [editFileUrl]="editFileUrl"
                    (fileUploadResponse)="fileUploadUrl($event)"
                    (deletedFileResponse)="deletedFileResponse($event)"
                    [isMulti]="multiDefaultValue"
                    [fileType]="configurationData.data.attr?.attributeType?.uiElement?.uiElement"
                  >
                  </app-file-upload>
                </div>
              </div>
              <!--for translation-->
              <div class="multi-value translation-type">
                <mat-form-field appearance="fill">
                  <mat-label class="label-txt">Translation Type </mat-label>
                  <mat-select [(ngModel)]="translationType" [ngModelOptions]="{ standalone: true }" name="food">
                    <mat-option
                      *ngFor="let type of translationTypeArray"
                      [value]="type"
                      id="attribute_translationType_{{ type }}"
                      (click)="ChangeTranslationType(type)"
                    >
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                class="expected-time"
                *ngIf="
                  configurationData.data?.attr?.attributeType?.uiElement?.uiElement == 'currency' ||
                  configurationData.data?.attr?.attributeType?.uiElement?.uiElement == 'metric' ||
                  unitType
                "
              >
                <label>{{ labels.Type }}</label>
                <input
                  matInput
                  [placeholder]="
                    configurationData.data?.attr?.attributeType?.uiElement?.uiElement == 'currency'
                      ? labels.Currency_Type
                      : labels.Metric_Type
                  "
                  id="defaultValue"
                  type="text"
                  autocomplete="off"
                  formControlName="unitType"
                />
              </div>
            </ng-container>
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!configurationData.data.entity?.isReserved">
          <mat-expansion-panel-header>
            <mat-panel-title>
              UI Controls
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="no-label w-75">
            <mat-label>Select Ui Control</mat-label>
            <mat-form-field appearance="outline" *ngIf="configurationData.data.attr?.attributeType">
              <mat-select
                class="uic-select"
                panelClass="attribute-icons select-with-search"
                (selectionChange)="selectAttrType($event, configurationData.data.attr)"
                [(ngModel)]="configurationData.data.attr.attributeType.uiElement.uiElement"
                placeholder="Select Attribute Type"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-form-field class="d-block" appearance="outline">
                  <input
                    matInput
                    placeholder="Search"
                    [(ngModel)]="searchText"
                    (keyup)="getUiControlDataType()"
                    (keydown)="$event.stopPropagation()"
                  />
                </mat-form-field>
                <mat-option [value]="attrType.uiElement" *ngFor="let attrType of attributeTypes"
                  >{{ attrType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- For autoIdentifier UI control start -->
          <div *ngIf="configurationData.data.attr?.attributeType?.uiElement?.dataType === 'autoIdentifier'">
            <form [formGroup]="autoIdForm">
              <div class="formatWrap auto-formfields">
                <div class="labelwraps">
                  <mat-label (click)="addField('format')">{{ labels?.Format }}</mat-label>
                  <mat-label (click)="addField('date')">{{ labels?.date }}</mat-label>
                  <mat-label (click)="addField('separator')">{{ labels?.Separator }}</mat-label>
                  <mat-label (click)="addField('increment')">{{ labels?.increment }}</mat-label>
                  <mat-label (click)="addField('attribute')">{{ labels?.attribute }}</mat-label>
                </div>
                <div
                  cdkDropList
                  [cdkDropListData]="(autoIdForm?.get('formatFields'))['controls']"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)"
                >
                  <div
                    formArrayName="formatFields"
                    *ngFor="let field of (autoIdForm?.get('formatFields'))['controls']; let i = index"
                    class="example-box"
                    cdkDrag
                  >
                    <div [formGroupName]="i">
                      <div *ngIf="field.value.type === 'format'" class="autofield-flex">
                        <mat-form-field floatLabel="never">
                          <mat-label>{{ labels?.Format }}</mat-label>
                          <input
                            matInput
                            placeholder="Format"
                            formControlName="format"
                            type="text"
                            autocomplete="off"
                          />
                        </mat-form-field>
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                      <div *ngIf="field.value.type === 'date'" class="autofield-flex">
                        <mat-form-field>
                          <mat-label>{{ labels?.Choose_a_date_format }}</mat-label>
                          <mat-select formControlName="dateFormat" (selectionChange)="checkYearFormat()">
                            <mat-option *ngFor="let date of dateFormats" [value]="date">
                              {{ date }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                      <div *ngIf="field.value.type === 'attribute'">
                        <span *ngIf="field?.controls?.entAttributeName?.value">
                          {{ field.controls.entAttributeName.value }}
                          <span class="pointer"
                            ><img
                              (click)="removeAttributeSelection(field)"
                              [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                          /></span>
                        </span>
                        <div class="attributeflex-items">
                          <ul class="attribute-list attribute-list-li" *ngIf="!field.controls.entAttributeName.value">
                            <app-auto-id-nested-entity
                              [attr]="attr"
                              [entity]="configurationData.data.entity"
                              [attrName]="configurationData.data.entity.name + '.' + attr.name"
                              *ngFor="let attr of entityAttributeSelection; let attrIndex = index"
                              (attributeEmit)="selectedAttribute($event, field)"
                            ></app-auto-id-nested-entity>
                          </ul>
                          <div class="autofield-flex">
                            <mat-form-field class="">
                              <mat-select formControlName="entAttributeString">
                                <mat-option *ngFor="let string of attributeNameOptions" [value]="string.value">
                                  {{ string.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="field.value.entAttributeString !== ''">
                              <input
                                matInput
                                placeholder="Value"
                                formControlName="entAttributeStringValue"
                                type="text"
                                autocomplete="off"
                              />
                            </mat-form-field>
                            <mat-icon cdkDragHandle>reorder</mat-icon>
                            <mat-icon (click)="removeField(i)">clear</mat-icon>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="field.value.type === 'separator' && separatingOperatorSelected">
                        {{ selectorSeparated }}
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                      <div *ngIf="field.value.type === 'increment'">
                        INCREMENT_ID
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="autoFormFields">
                  <mat-form-field *ngIf="getFinancialMonth">
                    <mat-label>{{ labels?.Financial_Month }}</mat-label>
                    <mat-select formControlName="financialMonth">
                      <mat-option *ngFor="let month of Months" [value]="month.value">
                        {{ month.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="isIncrementValue">
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.Max_Value }}</mat-label>
                      <input
                        matInput
                        placeholder="Placeholder"
                        id="maxValue"
                        type="number"
                        autocomplete="off"
                        formControlName="maxValue"
                      />
                    </mat-form-field>
                    <mat-form-field floatLabel="never">
                      <mat-label> {{ labels?.Count_Start_Parameter }}</mat-label>
                      <input
                        matInput
                        placeholder="Placeholder"
                        id="maxValue"
                        type="number"
                        autocomplete="off"
                        formControlName="countStartParameter"
                      />
                    </mat-form-field>
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.ID_Leading_Zeros }}</mat-label>
                      <input
                        matInput
                        placeholder="Placeholder"
                        id="maxValue"
                        type="number"
                        autocomplete="off"
                        formControlName="incIdLeadingZeroes"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>{{ labels?.Count_Reset_Parameter }}</mat-label>
                      <mat-select formControlName="countReset">
                        <mat-option *ngFor="let countReset of countResetParams" [value]="countReset">
                          {{ countReset }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="autoform-operators">
                  <div class="formatWrapSeparators operators-list">
                    <div
                      *ngFor="let separator of separators"
                      class="operator-circle"
                      (click)="selectSeparator(separator)"
                    >
                      <span id="selectDcdOperator">{{ separator }}</span>
                    </div>
                  </div>
                  <div *ngIf="separatingOperatorSelected" class="seletedseparator">
                    {{ labels?.Separator }} : {{ selectorSeparated }}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- For autoIdentifier UI control end -->

          <!-- form for scheduler ui-control -->
          <div *ngIf="configurationData.data.attr?.attributeType?.uiElement?.dataType === 'scheduler'">
            <form class="schedul-wrap" [formGroup]="schedulerDetails">
              <!-- For Interval -->
              <mat-form-field>
                <mat-label>{{ labels?.interval }}</mat-label>
                <mat-select
                  formControlName="interval"
                  id="schedularIntervalSelect"
                  (selectionChange)="onSelectionChange($event)"
                >
                  <mat-option *ngFor="let option of intervalOptions" [value]="option" id="schedular_{{ option }}">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- For Start Date and Time -->
              <div class="mr-25">
                <mat-form-field>
                  <label [ngClass]="{ labelMargin: schedulerDetails.value.startDate }">
                    {{ labels.start_date_and_time }}
                  </label>
                  <input matInput [ngxMatDatetimePicker]="picker" formControlName="startDate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="false"
                    [disableMinute]="false"
                    [hideTime]="false"
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>

              <!-- For End Date and Time -->
              <div class="mr-25">
                <mat-form-field>
                  <label [ngClass]="{ labelMargin: schedulerDetails.value.endDate }">
                    {{ labels.end_date_and_time }}
                  </label>
                  <input matInput [ngxMatDatetimePicker]="picker1" formControlName="endDate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker1
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="false"
                    [disableMinute]="false"
                    [hideTime]="false"
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>

              <!-- For Time -->
              <mat-form-field [hidden]="!isTime">
                <label [ngClass]="{ labelMargin: timeFormat }">
                  {{ labels.Select_Time }}
                </label>
                <p class="timedisplay">{{ timeFormat | date: 'HH:mm' }}</p>
                <input
                  style="display: none;"
                  matInput
                  [ngxMatDatetimePicker]="picker2"
                  [(ngModel)]="timeFormat"
                  formControlName="time"
                  required
                />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker2
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [stepSecond]="1"
                  [touchUi]="false"
                  [color]="'primary'"
                  [enableMeridian]="false"
                  [disableMinute]="false"
                  [hideTime]="false"
                  (dateInput)="updateNewTimeFormat($event)"
                  (dateChange)="updateNewTimeFormat($event)"
                  panelClass="hide-calendar  d-none"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>

              <!-- For Day -->
              <mat-form-field [hidden]="!isDay">
                <mat-label>{{ labels?.Day }}</mat-label>
                <div>
                  <input
                    matInput
                    placeholder="{{ labels?.Enter_Value }}"
                    id="formatDay"
                    type="text"
                    autocomplete="off"
                    formControlName="day"
                  />
                </div>
              </mat-form-field>

              <!-- For Increment Value -->
              <mat-form-field>
                <mat-label>{{ labels.Increment_Value }}</mat-label>
                <input
                  matInput
                  placeholder="{{ labels?.Enter_Value }}"
                  id="formatSchedular"
                  type="number"
                  autocomplete="off"
                  formControlName="incrementValue"
                />
              </mat-form-field>
              <div class="orline">or</div>

              <!-- For Recurrence Expression -->
              <mat-form-field class="w-100">
                <mat-label>{{ labels?.recurrenceExpression }}</mat-label>
                <input
                  matInput
                  placeholder="{{ labels?.Enter_Value }}"
                  id="formatRecurrenceExpression"
                  type="text"
                  autocomplete="off"
                  formControlName="recurrenceExpression"
                />
              </mat-form-field>
            </form>
          </div>
          <!-- scheduler ends here -->
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!configurationData.data.entity?.isReserved">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Advanced settings
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormVideo' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'payment' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'currentdatetime' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'hyperlink' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'audio' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'image' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'currentdate' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'encrypttext' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormProgress' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'signature' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'password' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormProgress'
              "
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Validation
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- <div class="form-group">
                <label>Select to Display</label>
                <div class="d-flex">
                  <mat-checkbox>Mandatory</mat-checkbox>
                </div>
              </div> -->
              <div class="level-dropdown no-label">
                <mat-form-field appearance="float" appearance="outline">
                  <mat-label>Select Level</mat-label>
                  <mat-select>
                    <mat-option
                      *ngFor="let level of levelList"
                      [value]="level"
                      (click)="CompareProperties(level, 'ATTRIBUTE_CONSTRAINTS')"
                    >
                      {{ level }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="compare">
                <button class="compareButton" (click)="compareValuePopup('ATTRIBUTE CONSTRAINTS')">
                  Compare Attribute Constraints
                </button>
              </div>
              <div class="TriggerCuValidators" *ngIf="betLevel">
                <div class="no-label w-75">
                  <mat-form-field appearance="outline">
                    <mat-label>Select Validators</mat-label>
                    <mat-select class="uic-select" id="validatorsSelectDropdown" [(ngModel)]="selectedUiValidator">
                      <mat-option
                        *ngFor="let validators of UiValidatorDropdown?.constraints; let i = index"
                        [value]="validators.name"
                        [class.disabled]="validators.disabled"
                        id="validatorsSelected_{{ validators.name }}"
                        (click)="validatorsChange(validators, i)"
                      >
                        {{ validators.displayName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <ng-container *ngIf="selectedValidatorsList && selectedValidatorsList?.length">
                    <div
                      *ngFor="let selected of selectedValidatorsList; let j = index"
                      class="selected-validate expected-time"
                    >
                      <div class="labelbtn">
                        <label class="mb-2">{{ selected.displayName }}</label>
                        <button mat-stroked-button class="cstm-matbtn" (click)="removeValidator(selected, j)">
                          Remove
                        </button>
                      </div>
                      <div *ngFor="let value of selected?.properties" class="selected-validate-input">
                        <div *ngIf="value?.name && value?.name != 'type'">
                          <input
                            matInput
                            class="cstm-input-text"
                            placeholder="{{ value?.message ? value?.message : value?.name }}"
                            id="{{ value.name }}"
                            type="text"
                            [(ngModel)]="value.message"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <mat-form-field class="pt-0" id="constraint_type_{{ j }}">
                        <mat-label>{{ labels?.constraint_type }}</mat-label>
                        <mat-select [(ngModel)]="selected.selectType" (change)="typeChange(selected.selectType, j)">
                          <mat-option id="info" value="INFO">{{ labels?.Info }}</mat-option>
                          <mat-option id="blockwarn" value="NONBLOCK_WARN"> {{ labels?.Non_Block_Warn }} </mat-option>
                          <mat-option id="nonblockwarn" value="BLOCK_WARN"> {{ labels?.Block_Warn }} </mat-option>
                          <mat-option id="error" value="ERROR">{{ labels?.Error }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="BasicCuLevel" *ngIf="!betLevel">
                <div class="no-label w-75">
                  <ng-container *ngIf="lowerBetProperties?.constraints && lowerBetProperties?.constraints?.length">
                    <div
                      *ngFor="let selected of lowerBetProperties?.constraints; let j = index"
                      class="selected-validate expected-time"
                    >
                      <div class="labelbtn">
                        <label class="mb-2">{{ selected.displayName }}</label>
                      </div>
                      <div *ngFor="let value of selected?.properties" class="selected-validate-input">
                        <div *ngIf="value?.name && value?.name != 'type'">
                          <input
                            matInput
                            class="cstm-input-text"
                            placeholder="{{ value?.message ? value?.message : value?.name }}"
                            id="{{ value.name }}"
                            type="text"
                            [(ngModel)]="value.message"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <mat-label>{{ labels?.constraint_type }}</mat-label>
                      <mat-select>
                        <mat-option id="info" value="INFO">{{ labels?.Info }}</mat-option>
                        <mat-option id="blockwarn" value="NONBLOCK_WARN"> {{ labels?.Non_Block_Warn }} </mat-option>
                        <mat-option id="nonblockwarn" value="BLOCK_WARN"> {{ labels?.Block_Warn }} </mat-option>
                        <mat-option id="error" value="ERROR">{{ labels?.Error }}</mat-option>
                      </mat-select>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Metadata
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field class="description-textarea">
                <textarea matInput rows="5" placeholder="Description Text"></textarea>
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
        <mat-expansion-panel
          *ngIf="
            (changeUnit?.reservedCUType === 'search' ||
              changeUnit?.reservedCUType === 'NSL_Template_PaginatedSearch' ||
              changeUnit?.reservedCUType?.indexOf('NSL_Report:') !== -1 ||
              changeUnit?.reservedCUType === 'aggregatefunction' ||
              changeUnit?.reservedCUType === 'statisticalfunction' ||
              changeUnit?.reservedCUType === 'selection') &&
            configurationData?.data?.entity?.layerType === 'physical' &&
            changeUnit?.isReserved
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Membership Criteria
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="level-dropdown no-label">
            <mat-form-field class="float" appearance="outline">
              <mat-label>Select Level</mat-label>
              <mat-select>
                <mat-option
                  *ngFor="let level of levelList"
                  [value]="level"
                  (click)="CompareProperties(level, 'MEMBERSHIP_CRITERIA')"
                >
                  {{ level }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <button class="compareButton" (click)="compareValuePopup('MEMBERSHIP CRITERIA')">
              Compare Membership Criteria
            </button>
          </div>
          <div class="triggerCuMembershipCriteria" *ngIf="betLevelMembership">
            <div>
              <span class="addmem">{{ labels?.Add_Membership_Criteria }}</span>
              <mat-radio-group
                name="membershipRadio_{{ configurationData.data.attr.name }}"
                [(ngModel)]="memberShipFlagsArr"
                (change)="addMembership(configurationData.data.attr)"
                fxLayout="column"
              >
                <mat-radio-button
                  name="membershipRadio_{{ configurationData.data.attr.name }}"
                  id="membershipRadio_{{ configurationData.data.attr.name }}"
                  [value]="true"
                >
                  {{ labels?.Yes }}
                </mat-radio-button>
                <mat-radio-button
                  name="membershipRadio1_{{ configurationData.data.attr.name }}"
                  id="membershipRadio1_{{ configurationData.data.attr.name }}"
                  [value]="false"
                >
                  {{ labels?.No }}
                </mat-radio-button>
              </mat-radio-group>
              <div *ngIf="memberShipFlagsArr">
                <div class="exptext">{{ labels?.Expression }}</div>
                <span *ngIf="configurationData.data.attr.memberShipName" class="m-l-20">
                  <p class="display-flex" class="w-auto d-flex">
                    ({{ configurationData.data.attr.memberShipName }})
                    <a href="javascript:;" id="hoveredDeleteIcons" (click)="memberShipFlagsChange()">
                      <figure id="removeCriteriaIcon_{{ configurationData.data.attr?.name | stringReplace }}">
                        <img
                          [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                          class="condition-icon"
                        />
                      </figure>
                    </a>
                  </p>
                </span>
                <div
                  class="d-flex align-items-baseline expression-form"
                  id="expressionDiv_{{ configurationData.data.attr.name }}"
                >
                  <span class="mr-2" *ngIf="currentOp">{{ currentOp }}</span>
                  <span class="mr-2" *ngIf="memberShipAttr">{{ memberShipAttr | dcd }}</span>
                  <mat-form-field floatLabel="never">
                    <mat-label>{{ labels?.Enter_Value }}</mat-label>
                    <input
                      matInput
                      placeholder="Enter Value"
                      type="text"
                      autocomplete="off"
                      [(ngModel)]="configurationData.data.attr.membershipInput"
                      autofocus
                    />
                  </mat-form-field>
                </div>
                <a
                  href="javascript:;"
                  (click)="updateAttrMemberShip(configurationData.data.attr)"
                  class="d-inline-block mb-3"
                >
                  <img [src]="'assets/img/icon-green-plus.svg' | CDNUrlRewritePipe" class="condition-icon" alt="" />
                  {{ labels?.Add }}
                </a>
              </div>

              <ul class="operators expression-form-operators">
                <li class="w-auto" (click)="currentOp = op" *ngFor="let op of memberShipCriteriaOperators">
                  {{ op }}
                </li>
              </ul>
            </div>
            <div class="no-label">
              <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                <mat-expansion-panel *ngIf="physicalLayer?.length > 0" id="accordianExpansionPhysical_cuRecursive">
                  <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                    <mat-panel-title class="active-bold">
                      <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-node-expand-layers
                    [layerData]="physicalLayer"
                    [tempGsiObj]="tempGsiObj"
                    [cuName]="configurationData.data.name"
                    [configurationData]="configurationData"
                    [layerLabel]="labelForPhyscialLayer"
                    [changeUnit]="changeUnit"
                    [isMembership]="true"
                    [setPrimaryKeySearch]="changeUnit"
                  >
                  </app-node-expand-layers>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                <mat-expansion-panel *ngIf="informationLayer?.length > 0" id="expansionPanel_GSIRecursive">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="active-bold">
                      <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-node-expand-layers
                    [layerData]="informationLayer"
                    [tempGsiObj]="tempGsiObj"
                    [cuName]="configurationData.data.name"
                    [configurationData]="configurationData"
                    [layerLabel]="labelForInformationLayer"
                    [changeUnit]="changeUnit"
                    [isMembership]="true"
                    [setPrimaryKeySearch]="changeUnit"
                  >
                  </app-node-expand-layers>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                <mat-expansion-panel *ngIf="triggerCESLayer?.length > 0" id="expansionPanel_GSIRecursive">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="active-bold">
                      <span id="infoLayr_GSIRecursive">{{ labels?.triggerCES_Layer }}</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-node-expand-layers
                    [layerData]="triggerCESLayer"
                    [tempGsiObj]="tempGsiObj"
                    [cuName]="configurationData.data.name"
                    [configurationData]="configurationData"
                    [layerLabel]="labelForTriggerCESLayer"
                    [changeUnit]="changeUnit"
                    [isMembership]="true"
                    [setPrimaryKeySearch]="changeUnit"
                  >
                  </app-node-expand-layers>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <div class="BasicCuLevel" *ngIf="!betLevelMembership">
            <div>
              <div>
                <div class="exptext">{{ labels?.Expression }}</div>
                <span
                  *ngIf="lowerBetProperties?.memberShipCriteria && lowerBetProperties?.memberShipCriteria != ''"
                  class="m-l-20"
                >
                  <p class="display-flex" class="w-auto d-flex">
                    {{ lowerBetProperties?.memberShipCriteria }}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <!-- </mat-accordion> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- </div> -->

        <ng-template #defaultValueBlock let-attrDetails="attrDetails">
          <!-- Block for slider type UI control -->
          <div
            class="slider-target-div"
            *ngIf="
              attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
            "
          >
            <label class="slider-target-label">Target Value</label>
            <input
              matInput
              placeholder=""
              [(ngModel)]="targetValue"
              class="target-slider border-Bottom-slider"
              (keyup)="onlyNumberKey($event)"
            />
          </div>
          <div class="slider-target-div" *ngIf="attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider'">
            <label class="slider-target-label">Step Value</label>
            <input
              matInput
              placeholder=""
              [(ngModel)]="sliderStepValue"
              class="target-slider border-Bottom-slider"
              (keyup)="onlyNumberKey($event)"
            />
          </div>
          <div class="level-dropdown no-label">
            <mat-form-field appearance="float" appearance="outline">
              <mat-label>Select Level</mat-label>
              <mat-select>
                <mat-option
                  *ngFor="let level of levelList"
                  [value]="level"
                  (click)="CompareProperties(level, 'DEFAULT_VALUE')"
                >
                  {{ level }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="compare">
            <button class="compareButton" (click)="compareValuePopup('DEFAULT VALUE')">
              Compare Default Value
            </button>
          </div>
          <!-- For default value of UI controls expect file type UI controls-->
          <div
            class="expected-time"
            *ngIf="!excludeUiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement) && betLevelDefault"
          >
            <div>
              <!-- Enter default value for UI control -->
              <div
                *ngIf="uiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement); else defaultInputBlock"
              >
                <!-- For Boolean  -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'boolean'">
                  {{ labels?.Default_Value }}:
                  <mat-slide-toggle [(ngModel)]="booleanDefaultValue" (ngModelChange)="booleanValue()">
                  </mat-slide-toggle>
                </div>

                <!-- For Date -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'date'" class="hideunderline">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <input
                      matInput
                      name="defaultValueForDate"
                      id="defaultValueForDate"
                      placeholder="Choose a date"
                      [matDatepicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- For Year Month -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'yearMonth'">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <p>{{ dateTypeDefaultValue | date: yearMonthFormatType }}</p>
                    <input
                      matInput
                      name="defaultValueForYearMonth"
                      id="defaultValueForYearMonth"
                      placeholder="Month and Year"
                      [matDatepicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      style="display: none;"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker
                      #picker
                      startView="multi-year"
                      (yearSelected)="chosenYearHandler($event)"
                      (monthSelected)="chosenMonthHandler($event, picker)"
                    >
                    </mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- For Year Month -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'year'">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <p>{{ defaultValue }}</p>
                    <input
                      matInput
                      name="defaultValueForYear"
                      id="defaultValueForYear"
                      placeholder="Select Year"
                      [matDatepicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      style="display: none;"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearForYear($event, picker)">
                    </mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- For DateTime -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'datetime'" class="hideunderline">
                  <mat-form-field appearance="standard" *ngIf="timeFormatType === '24-hr'">
                    <label>{{ labels.Default_Value }}</label>
                    <input
                      matInput
                      name="defaultValueForDatetime"
                      id="defaultValueForDatetime"
                      placeholder="Choose date and time"
                      [ngxMatDatetimePicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      #picker
                      [showSpinners]="true"
                      [showSeconds]="false"
                      [stepHour]="1"
                      [stepMinute]="1"
                      [stepSecond]="1"
                      [touchUi]="false"
                      [color]="'primary'"
                      [enableMeridian]="false"
                      [disableMinute]="false"
                      [hideTime]="false"
                    >
                    </ngx-mat-datetime-picker>
                  </mat-form-field>

                  <mat-form-field appearance="standard" *ngIf="timeFormatType !== '24-hr'">
                    <label>{{ labels.Default_Value }}</label>
                    <input
                      matInput
                      name="defaultValueForDatetime"
                      id="defaultValueForDatetime"
                      placeholder="Choose date and time"
                      [ngxMatDatetimePicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      #picker
                      [showSpinners]="true"
                      [showSeconds]="false"
                      [stepHour]="1"
                      [stepMinute]="1"
                      [stepSecond]="1"
                      [touchUi]="false"
                      [color]="'primary'"
                      [enableMeridian]="true"
                      [disableMinute]="false"
                      [hideTime]="false"
                    >
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                </div>

                <!-- For Time -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'time'" class="hideunderline">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <br />
                    <ul class="inner-cal" style="display: inline-flex;">
                      <li *ngIf="timeFormatType === '24-hr'">
                        <input
                          matInput
                          name="defaultValueForTime"
                          id="defaultValueForTime"
                          placeholder="Choose time"
                          [ngxMatDatetimePicker]="picker"
                          [(ngModel)]="dateTypeDefaultValue"
                          (ngModelChange)="formatDate()"
                          readonly
                          style="display: none;"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker
                          #picker
                          [showSpinners]="true"
                          [showSeconds]="false"
                          [stepHour]="1"
                          [stepMinute]="1"
                          [stepSecond]="1"
                          [touchUi]="false"
                          [color]="'primary'"
                          [enableMeridian]="false"
                          [disableMinute]="false"
                          [hideTime]="false"
                          panelClass="hide-calendar  d-none"
                        >
                        </ngx-mat-datetime-picker>
                      </li>
                      <li *ngIf="timeFormatType !== '24-hr'">
                        <input
                          matInput
                          name="defaultValueForTime"
                          id="defaultValueForTime"
                          placeholder="Choose time"
                          [ngxMatDatetimePicker]="picker"
                          [(ngModel)]="dateTypeDefaultValue"
                          (ngModelChange)="formatDate()"
                          readonly
                          style="display: none;"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker
                          #picker
                          [showSpinners]="true"
                          [showSeconds]="false"
                          [stepHour]="1"
                          [stepMinute]="1"
                          [stepSecond]="1"
                          [touchUi]="false"
                          [color]="'primary'"
                          [enableMeridian]="true"
                          [disableMinute]="false"
                          [hideTime]="false"
                          panelClass="hide-calendar  d-none"
                        >
                        </ngx-mat-datetime-picker>
                      </li>
                      <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType === '24-hr'">
                        <p class="timedisplay">{{ dateTypeDefaultValue | date: 'HH:mm' }}</p>
                      </li>
                      <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType !== '24-hr'">
                        <p class="timedisplay">{{ dateTypeDefaultValue | date: 'hh:mm a' }}</p>
                      </li>
                    </ul>
                  </mat-form-field>
                </div>

                <!-- For Date Range -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'dateRange'" class="hideunderline">
                  <label>{{ labels.Default_Value }}</label>
                  <p-calendar
                    name="defaultValueForDateRange"
                    id="defaultValueForDateRange"
                    placeholder="Choose two dates"
                    [(ngModel)]="dateTypeDefaultValue"
                    (ngModelChange)="formatDate()"
                    [showIcon]="true"
                    appendTo="body"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="range"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1900:2050"
                    dateFormat="dd-mm-yy"
                  >
                  </p-calendar>
                </div>

                <!-- For Duration -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'duration'" class="duration">
                  <label>{{ labels.Default_Value }}</label>
                  <table>
                    <tr>
                      <td>
                        <input
                          type="number"
                          id="duration_days"
                          placeholder="00"
                          [(ngModel)]="durationDefaultValue.days"
                          class="time-input"
                        />
                        Days :
                      </td>
                      <td>
                        <input
                          type="number"
                          id="duration_hours"
                          placeholder="00"
                          min="0"
                          max="23"
                          [(ngModel)]="durationDefaultValue.hours"
                          (ngModelChange)="checkDuration()"
                          class="time-input"
                        />
                        {{ labels?.Hours }} :
                      </td>
                      <td>
                        <input
                          type="number"
                          id="duration_minutes"
                          placeholder="00"
                          min="0"
                          max="59"
                          [(ngModel)]="durationDefaultValue.minutes"
                          (ngModelChange)="checkDuration()"
                          class="time-input"
                        />
                        {{ labels?.Minutes }}
                      </td>
                    </tr>
                  </table>
                </div>

                <!-- Rich Text -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'editor'" class="text-editor">
                  <mat-label>{{ labels.Default_Value }}</mat-label>
                  <angular-editor
                    [(ngModel)]="defaultValue"
                    id="angularEditor"
                    [config]="EditorConfig"
                  ></angular-editor>
                </div>

                <div
                  *ngIf="
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'boolean' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'basicSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'inputSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'rangeSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'stepSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'verticalSlider'
                  "
                >
                  <!-- Add button for adding default values -->
                  <button
                    *ngIf="!(!multiDefaultValue && allDefaultValues?.length > 0)"
                    mat-stroked-button
                    type="button"
                    class="addDefaultValBtn"
                    id="Default_Value"
                    (click)="addDefaultValue()"
                  >
                    {{ labels.Add }}
                  </button>

                  <!-- Show all default values of UI control -->
                  <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
                    <p
                      *ngIf="
                        !dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) &&
                        attrDetails?.attributeType?.uiElement?.uiElement !== 'duration'
                      "
                    >
                      {{ attrvalue }}
                    </p>
                    <p
                      *ngIf="
                        dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) ||
                        attrDetails?.attributeType?.uiElement?.uiElement === 'duration'
                      "
                    >
                      {{ displayDateDefaultValue(attrvalue, attrDetails?.attributeType?.uiElement?.uiElement) }}
                    </p>
                    <span class="pointer"
                      ><img
                        (click)="removeDefaultValue(i)"
                        id="defaultvalueremoveimg"
                        src="assets/img/delete-icon.svg"
                        alt=""
                    /></span>
                  </div>
                </div>

                <!--Slider default value-->
                <div
                  *ngIf="
                    attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
                  "
                >
                  <label>{{ labels.Default_Value }}</label>
                  <input
                    matInput
                    class="add-value-default"
                    name="defaultValue"
                    placeholder=""
                    id="defaultValue_slider"
                    type="number"
                    autocomplete="off"
                    [(ngModel)]="defaultValue"
                  />

                  <!-- Add button for adding default values -->
                  <button
                    *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
                    mat-stroked-button
                    type="button"
                    class="addDefaultValBtn"
                    id="Slider_Default_Value"
                    (click)="sliderDefaultValue()"
                  >
                    {{ labels.Add }}
                  </button>
                  <!-- Show all default values of UI control -->
                  <div *ngIf="allDefaultValues?.length > 0">
                    <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
                      {{ attrvalue }}
                      <span class="pointer"
                        ><img
                          (click)="removeDefaultValue(i)"
                          id="sliderdefaultvalueremoveimg"
                          [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #defaultInputBlock>
                <label>{{ labels.Default_Value }}</label>
                <input
                  matInput
                  class="add-value-default"
                  name="defaultValue"
                  placeholder="Default Value"
                  id="defaultValue"
                  type="text"
                  autocomplete="off"
                  [(ngModel)]="defaultValue"
                  (keyup.enter)="addDefaultValue()"
                />

                <!-- Add button for adding default values -->
                <button
                  *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
                  mat-stroked-button
                  type="button"
                  class="addDefaultValBtn"
                  id="Default_Value"
                  (click)="addDefaultValue()"
                >
                  {{ labels.Add }}
                </button>

                <!-- Show all default values of UI control -->
                <div>
                  <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
                    {{ attrvalue }}
                    <span class="pointer"
                      ><img
                        (click)="removeDefaultValue(i)"
                        id="defaultvalueremoveimg"
                        src="assets/img/delete-icon.svg"
                        alt=""
                    /></span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div
            class="expected-time"
            *ngIf="!excludeUiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement) && !betLevelDefault"
          >
            <div class="BasicCuLevel">
              <!-- Enter default value for UI control -->
              <div
                *ngIf="uiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement); else defaultInputBlock"
              >
                <!-- For Boolean  -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'boolean'">
                  {{ labels?.Default_Value }}:
                  <mat-slide-toggle [(ngModel)]="booleanDefaultValue" (ngModelChange)="booleanValue()">
                  </mat-slide-toggle>
                </div>

                <!-- For Date -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'date'" class="hideunderline">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <input
                      matInput
                      name="defaultValueForDate"
                      id="defaultValueForDate"
                      placeholder="Choose a date"
                      [matDatepicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- For Year Month -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'yearMonth'">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <p>{{ dateTypeDefaultValue | date: yearMonthFormatType }}</p>
                    <input
                      matInput
                      name="defaultValueForYearMonth"
                      id="defaultValueForYearMonth"
                      placeholder="Month and Year"
                      [matDatepicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      style="display: none;"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker
                      #picker
                      startView="multi-year"
                      (yearSelected)="chosenYearHandler($event)"
                      (monthSelected)="chosenMonthHandler($event, picker)"
                    >
                    </mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- For Year Month -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'year'">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <p>{{ defaultValue }}</p>
                    <input
                      matInput
                      name="defaultValueForYear"
                      id="defaultValueForYear"
                      placeholder="Select Year"
                      [matDatepicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      style="display: none;"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearForYear($event, picker)">
                    </mat-datepicker>
                  </mat-form-field>
                </div>

                <!-- For DateTime -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'datetime'" class="hideunderline">
                  <mat-form-field appearance="standard" *ngIf="timeFormatType === '24-hr'">
                    <label>{{ labels.Default_Value }}</label>
                    <input
                      matInput
                      name="defaultValueForDatetime"
                      id="defaultValueForDatetime"
                      placeholder="Choose date and time"
                      [ngxMatDatetimePicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      #picker
                      [showSpinners]="true"
                      [showSeconds]="false"
                      [stepHour]="1"
                      [stepMinute]="1"
                      [stepSecond]="1"
                      [touchUi]="false"
                      [color]="'primary'"
                      [enableMeridian]="false"
                      [disableMinute]="false"
                      [hideTime]="false"
                    >
                    </ngx-mat-datetime-picker>
                  </mat-form-field>

                  <mat-form-field appearance="standard" *ngIf="timeFormatType !== '24-hr'">
                    <label>{{ labels.Default_Value }}</label>
                    <input
                      matInput
                      name="defaultValueForDatetime"
                      id="defaultValueForDatetime"
                      placeholder="Choose date and time"
                      [ngxMatDatetimePicker]="picker"
                      [(ngModel)]="dateTypeDefaultValue"
                      (ngModelChange)="formatDate()"
                      readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker
                      #picker
                      [showSpinners]="true"
                      [showSeconds]="false"
                      [stepHour]="1"
                      [stepMinute]="1"
                      [stepSecond]="1"
                      [touchUi]="false"
                      [color]="'primary'"
                      [enableMeridian]="true"
                      [disableMinute]="false"
                      [hideTime]="false"
                    >
                    </ngx-mat-datetime-picker>
                  </mat-form-field>
                </div>

                <!-- For Time -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'time'" class="hideunderline">
                  <mat-form-field appearance="standard">
                    <label>{{ labels.Default_Value }}</label>
                    <br />
                    <ul class="inner-cal" style="display: inline-flex;">
                      <li *ngIf="timeFormatType === '24-hr'">
                        <input
                          matInput
                          name="defaultValueForTime"
                          id="defaultValueForTime"
                          placeholder="Choose time"
                          [ngxMatDatetimePicker]="picker"
                          [(ngModel)]="dateTypeDefaultValue"
                          (ngModelChange)="formatDate()"
                          readonly
                          style="display: none;"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker
                          #picker
                          [showSpinners]="true"
                          [showSeconds]="false"
                          [stepHour]="1"
                          [stepMinute]="1"
                          [stepSecond]="1"
                          [touchUi]="false"
                          [color]="'primary'"
                          [enableMeridian]="false"
                          [disableMinute]="false"
                          [hideTime]="false"
                          panelClass="hide-calendar  d-none"
                        >
                        </ngx-mat-datetime-picker>
                      </li>
                      <li *ngIf="timeFormatType !== '24-hr'">
                        <input
                          matInput
                          name="defaultValueForTime"
                          id="defaultValueForTime"
                          placeholder="Choose time"
                          [ngxMatDatetimePicker]="picker"
                          [(ngModel)]="dateTypeDefaultValue"
                          (ngModelChange)="formatDate()"
                          readonly
                          style="display: none;"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker
                          #picker
                          [showSpinners]="true"
                          [showSeconds]="false"
                          [stepHour]="1"
                          [stepMinute]="1"
                          [stepSecond]="1"
                          [touchUi]="false"
                          [color]="'primary'"
                          [enableMeridian]="true"
                          [disableMinute]="false"
                          [hideTime]="false"
                          panelClass="hide-calendar  d-none"
                        >
                        </ngx-mat-datetime-picker>
                      </li>
                      <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType === '24-hr'">
                        <p class="timedisplay">{{ dateTypeDefaultValue | date: 'HH:mm' }}</p>
                      </li>
                      <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType !== '24-hr'">
                        <p class="timedisplay">{{ dateTypeDefaultValue | date: 'hh:mm a' }}</p>
                      </li>
                    </ul>
                  </mat-form-field>
                </div>

                <!-- For Date Range -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'dateRange'" class="hideunderline">
                  <label>{{ labels.Default_Value }}</label>
                  <p-calendar
                    name="defaultValueForDateRange"
                    id="defaultValueForDateRange"
                    placeholder="Choose two dates"
                    [(ngModel)]="dateTypeDefaultValue"
                    (ngModelChange)="formatDate()"
                    [showIcon]="true"
                    appendTo="body"
                    selectionMode="range"
                    [readonlyInput]="true"
                    inputId="range"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    yearRange="1900:2050"
                    dateFormat="dd-mm-yy"
                  >
                  </p-calendar>
                </div>

                <!-- For Duration -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'duration'" class="duration">
                  <label>{{ labels.Default_Value }}</label>
                  <table>
                    <tr>
                      <td>
                        <input
                          type="number"
                          id="duration_days"
                          placeholder="00"
                          [(ngModel)]="durationDefaultValue.days"
                          class="time-input"
                        />
                        Days :
                      </td>
                      <td>
                        <input
                          type="number"
                          id="duration_hours"
                          placeholder="00"
                          min="0"
                          max="23"
                          [(ngModel)]="durationDefaultValue.hours"
                          (ngModelChange)="checkDuration()"
                          class="time-input"
                        />
                        {{ labels?.Hours }} :
                      </td>
                      <td>
                        <input
                          type="number"
                          id="duration_minutes"
                          placeholder="00"
                          min="0"
                          max="59"
                          [(ngModel)]="durationDefaultValue.minutes"
                          (ngModelChange)="checkDuration()"
                          class="time-input"
                        />
                        {{ labels?.Minutes }}
                      </td>
                    </tr>
                  </table>
                </div>

                <!-- Rich Text -->
                <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'editor'" class="text-editor">
                  <mat-label>{{ labels.Default_Value }}</mat-label>
                  <angular-editor
                    [(ngModel)]="defaultValue"
                    id="angularEditor"
                    [config]="EditorConfig"
                  ></angular-editor>
                </div>

                <div
                  *ngIf="
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'boolean' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'basicSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'inputSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'rangeSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'stepSlider' &&
                    attrDetails?.attributeType?.uiElement?.uiElement !== 'verticalSlider'
                  "
                >
                  <!-- Show all default values of UI control -->
                  <div class="d-flex added" *ngFor="let defVal of lowerBetProperties?.defaultValue">
                    <p
                      *ngIf="
                        !dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) &&
                        attrDetails?.attributeType?.uiElement?.uiElement !== 'duration'
                      "
                    >
                      {{ defVal }}
                    </p>
                    <p
                      *ngIf="
                        dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) ||
                        attrDetails?.attributeType?.uiElement?.uiElement === 'duration'
                      "
                    >
                      {{ displayDateDefaultValue(defVal, attrDetails?.attributeType?.uiElement?.uiElement) }}
                    </p>
                  </div>
                </div>

                <!--Slider default value-->
                <div
                  *ngIf="
                    attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
                    attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
                  "
                >
                  <label>{{ labels.Default_Value }}</label>
                  <input
                    matInput
                    class="add-value-default"
                    name="defaultValue"
                    placeholder=""
                    id="defaultValue_slider"
                    type="number"
                    autocomplete="off"
                    [(ngModel)]="defaultValue"
                  />
                  <!-- Show all default values of UI control -->
                  <div>
                    <div class="d-flex added" *ngFor="let defVal of lowerBetProperties?.defaultValue">
                      {{ defVal }}
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #defaultInputBlock>
                <label>{{ labels.Default_Value }}</label>
                <input
                  matInput
                  class="add-value-default"
                  name="defaultValue"
                  placeholder="Default Value"
                  id="defaultValue"
                  type="text"
                  autocomplete="off"
                  [(ngModel)]="defaultValue"
                  (keyup.enter)="addDefaultValue()"
                />
                <!-- Show all default values of UI control -->
                <div class="d-flex added" *ngFor="let defVal of lowerBetProperties?.defaultValue">
                  {{ defVal }}
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </mat-accordion>
    </div>
  </div>
</div>
