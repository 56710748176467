<ng-template #listAttribute let-data="data">
  <li class="{{ data.class }}" [innerHTML]="data.value">
    <!-- {{attributeUIElementMap?.get(data.displayName)}} -->
    <div class="text-left" *ngIf="data?.knowMore">
      <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
        Know More
      </button>
    </div>
  </li>
</ng-template>
<ng-template #actionButton let-data="data">
  <span class="btn-close" *ngIf="data?.cardDisplay == 'design'">
    <i class="bi bi-x" *ngIf="childGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
    <button
      *ngIf="!isGrammer"
      #gsiAction
      attributeDesignConfig
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [_style]="style"
      [tabSelected]="tabSelected"
      [isDesign]="isDesign"
      [isButton]="true"
      [_selectedDirectiveId]="selectedDirectiveId"
      (drop)="dropActionBtn($event)"
      (dragover)="allowDrop($event)"
      class="btn template-btn action-button btnaction"
      pTooltip="Action Button"
      [id]="'action-' + data?.position"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      [(styleGsi)]="styleGsi[data?.position]"
      [ngClass]="data?.class"
      [cardDisplay]="cardDisplay"
      [(styleGsi)]="styleGsi[data?.position]"
    >
      {{childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'Action Button'}}
    </button>
  </span>
  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="data?.isLiTag">
      <li
        [ngStyle]="{ background: btnColors?.[data?.position] }"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        pTooltip=" {{ gsiMapping[data?.index]?.displayName }}"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </li>
    </ng-container>
    <ng-container *ngIf="!data?.isLiTag">
      <button
        [ngStyle]="{ background: btnHoveredBoolean[data?.position] ? btnHoverColors[data?.position] :btnColors?.[data?.position] }"
        (mouseover)="btnHoveredBoolean[data?.position] = true"
        (mouseout)="btnHoveredBoolean[data?.position] = false"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        pTooltip=" {{ gsiMapping[data?.index]?.displayName }}"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <li
      *ngIf="!data.isImage"
      [id]="attributeItem?.innerHTML"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      [_style]="style"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      [tabSelected]="tabSelected"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="styleAttribute[data?.position]"
    >
      <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
      /></span>
    </li>
    <li
      *ngIf="data?.isImage"
      #attributeItem
      id="attr-{{ data?.position }}"
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      [ngClass]="data?.class"
    >
      {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
      <span *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'">
        <img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
        />
      </span>
    </li>
  </ng-container>

  <ng-container *ngIf="data.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.isImage">
      <li
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue"
        pTooltip="{{ (card?.recordData)?.[data?.position]?.value }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleDisplay"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.displayName }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        *ngIf="data?.isSingleValueWOC"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <ng-container *ngIf="data?.isReadMore">
        <app-read-more
          [text]="(card?.recordData)?.[data?.position]?.value"
          [ngClass]="data?.class"
          [height]="data?.height"
        >
        </app-read-more>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data?.isImage">
      <img
        [ngClass]="data?.class"
        *ngIf="(card?.recordData)[data.position]?.contentUrl"
        [src]="(card?.recordData)[data.position]?.contentUrl | CDNUrlRewritePipe"
        alt="general-entity"
      />
    </ng-container>
  </ng-container>
</ng-template>
<div class="ecommerce-child-preview-cards">
  <div class="entitytemplates">
    <div class="Attribute-4" *ngIf="templateType == '54'">
      <div
        class="e-commerce-preview-card-1 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <ul class="preview-body">
          <h1 class="card-title"></h1>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>

          <p class="card-subtitle"></p>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
          <p class="card-subtitle-small"></p>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <div class="Attribute-6" *ngIf="templateType == '55'">
      <div
        class="e-commerce-preview-card-2 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <h6 class="card-main">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </h6>
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-caption">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-mrp">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 5,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-4" *ngIf="templateType == '56'">
      <div
        class="e-commerce-preview-card-3 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '57'">
      <div
        class="e-commerce-preview-card-4 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title text-ellipsis">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
          </p>
          <p class="card-subtitle-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '58'">
      <div
        class="e-commerce-preview-card-5 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-4" *ngIf="templateType == '59'">
      <div
        class="e-commerce-preview-card-6 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small col-6">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-4" *ngIf="templateType == '60'">
      <div
        class="e-commerce-preview-card-7 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small col-6">{{ (card?.recordData)[3]?.value }}</p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '61'">
      <div
        class="e-commerce-preview-card-8 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <p class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-caption">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small col-6">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </ul>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '62'">
      <div
        class="e-commerce-preview-card-9 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <span class="card-subtitle1">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
            <span class="card-subtitle2">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
          </p>

          <div class="card-subtitle card-inline row">
            <div class="card-subtitle-small col-6">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </div>
            <div class="btn-cta col-6">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '63'">
      <div
        class="e-commerce-preview-card-10 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-caption">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <span class="card-subtitle1">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
            <span class="card-subtitle2">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-4" *ngIf="templateType == '64'">
      <div
        class="e-commerce-preview-card-11 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
      </div>
    </div>
    <div class="Attribute-6" *ngIf="templateType == '65'">
      <div
        class="e-commerce-preview-card-12 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="badge">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-caption">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-mrp">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 5,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-4" *ngIf="templateType == '66'">
      <div
        class="e-commerce-preview-card-13 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small"></p>
          <p class="card-subtitle-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '67'">
      <div
        class="e-commerce-preview-card-14 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title text-ellipsis">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
          </p>
          <p class="card-subtitle-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '68'">
      <div
        class="e-commerce-preview-card-15 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <div class="preview-body">
            <h1 class="card-title">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </h1>
            <p class="card-subtitle">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </p>
            <p class="card-subtitle-small">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </p>
            <p class="card-subtitle">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </p>
          </div>
          <div class="btn-cta">
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="Attribute-4" *ngIf="templateType == '69'">
      <div
        class="e-commerce-preview-card-16 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'e-commerce-imgsize',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small col-6">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-4" *ngIf="templateType == '70'">
      <div
        class="e-commerce-preview-card-17 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small col-6">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '71'">
      <div
        class="e-commerce-preview-card-18 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </div>
          <p class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-caption">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle-small col-6">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </ul>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '72'">
      <div
        class="e-commerce-preview-card-19 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                isImage: true,
                class: 'e-commerce-imgsize',
                cardDisplay: cardDisplay
              }
            }"
          ></ng-template>
        </div>
        <div class="preview-body">
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-subtitle">
            <span class="card-subtitle1">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
            <span class="card-subtitle2">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
          </p>
          <span class="card-subtitle">
            <span class="card-subtitle-small">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template></div
          ></span>
        </div>
      </div>
    </div>
    <div class="Attribute-5" *ngIf="templateType == '73'">
      <div
        class="e-commerce-preview-card-20 e-commerce-preview"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="preview-body">
          <div class="card-logo">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </div>
          <h1 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </h1>
          <p class="card-caption">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
          <p class="card-subtitle">
            <span class="card-subtitle1">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
            <span class="card-subtitle2"> {{ (card?.recordData)[4]?.value }}</span>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </p>
        </div>
        <div class="btn-cta">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 1, class: 'btn cta-btn text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
