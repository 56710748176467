<div class="demo-full-width" [formGroup]="group">
  <div class="d-flex justify-content-between nh-column-gap-10">
    <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
      <label
        class="main-label nh-mb-0"
        pTooltip="{{ field?.label }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
      >
        <span class="main-label-inner"
          ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
          <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
        >
      </label>
      <p
        class="nh-ml-6 d-flex main-label-helperIcon"
        *ngIf="field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        <mat-icon
          class="material-icons-outlined helperText-infoIcon"
          [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
          matTooltip="{{ field?.configuration?.helpertext }}"
          matTooltipPosition="above"
          *ngIf="!field?.hideLabels"
          >info_outline</mat-icon
        >
      </p>
    </div>
    <div
      class="d-flex align-items-center nh-column-gap-5 pb-2"
      *ngIf="
        field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
        (field.isMulti && !field.configuration?.hideMultiValueIcon)
      "
    >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="addAttribute.next()"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="removeAttribute.next()"
        >remove</mat-icon
      >
    </div>
  </div>
  <p
    class="top-text"
    *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
    [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
  >
    {{ field?.configuration?.helpertext }}
  </p>

  <div class="d-flex justify-content-between nh-column-gap-10">
      <mat-form-field appearance="standard" class="custom-input-mat" *ngIf="field.type == 'char'">
      <input
        matInput
        placeholder="Enter {{ field.type }} datatype"
        [(ngModel)]="field.value"
        [formControlName]="field?.attribute?.name"
        (keypress)="getCharInput($event, field.inputType)"
        (paste)="handlePaste($event)"
        [type]="field.type"
        [readonly]="field.readonly"
        [required]="field?.isRequired && !field?.isInfo"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
    /></mat-form-field>

  <mat-form-field
    appearance="standard"
    class="custom-input-mat"
    *ngIf="field.type == 'short' || field.type == 'integer' || field.type == 'byte'"
  >
  <input
        matInput
        placeholder="Enter {{ field.type }} datatype"
        [formControlName]="field?.attribute?.name"
        (keyup)="getNumberType($event)"
        onlyNumber
        type="text"
        [readonly]="field.readonly"
        [required]="field?.isRequired && !field?.isInfo"
        (change)="getNumberType($event)"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
    />
  </mat-form-field>
  <mat-form-field appearance="standard" class="custom-input-mat" *ngIf="field.type == 'float'">
    <input
      matInput
      placeholder="Enter {{ field.type }} datatype"
      [(ngModel)]="field.value"
      [formControlName]="field?.attribute?.name"
      type="number"
      [readonly]="field.readonly"
      [required]="field?.isRequired && !field?.isInfo"
      (input)="getNumberType($event)"
      id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
      [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }"
  /></mat-form-field>
  <p
  class="bottom-text"
  *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
  [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
>
  {{ field?.configuration?.helpertext }}
</p>

  <mat-form-field appearance="standard" class="custom-input-mat custom-input" *ngIf="field.type == 'period'">
    <input
      placeholder="Select Year"
      matInput
      type="number"
      [value]="year"
      [(ngModel)]="year"
      [matAutocomplete]="autoYear"
      (keyup)="period($event.target.value, 'year')"
      (change)="period($event.target.value, 'year')"
    />
    <mat-autocomplete #autoYear="matAutocomplete">
      <mat-option *ngFor="let minute of yearData" [value]="minute" (click)="period(minute, 'year')">{{
        minute
      }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="standard" class="custom-input-mat" *ngIf="field.type == 'period'">
    <input
      placeholder="Select Month"
      matInput
      [value]="month"
      [(ngModel)]="month"
      type="number"
      [matAutocomplete]="autoMonth"
      (keyup)="period($event.target.value, 'month')"
      (change)="period($event.target.value, 'month')"
    />
    <mat-autocomplete #autoMonth="matAutocomplete">
      <mat-option *ngFor="let minute of monthData" [value]="minute" (click)="period(minute, 'month')">{{
        minute
      }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="standard" class="custom-input-mat" *ngIf="field.type == 'period'">
    <input
      placeholder="Select Week"
      matInput
      [value]="week"
      [(ngModel)]="week"
      type="number"
      [matAutocomplete]="autoWeek"
      (keyup)="period($event.target.value, 'week')"
      (change)="period($event.target.value, 'week')"
    />
    <mat-autocomplete #autoWeek="matAutocomplete">
      <mat-option *ngFor="let minute of weekData" [value]="minute" (click)="period(minute, 'week')">{{
        minute
      }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="standard" class="custom-input-mat" *ngIf="field.type == 'period'">
    <input
      placeholder="Select Days"
      matInput
      type="number"
      [value]="day"
      [(ngModel)]="day"
      [matAutocomplete]="autoDays"
      (keyup)="period($event.target.value, 'day')"
      (change)="period($event.target.value, 'day')"
    />
    <mat-autocomplete #autoDays="matAutocomplete">
      <mat-option *ngFor="let minute of dayData" [value]="minute" (click)="period(minute, 'day')">{{
        minute
      }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

</div>

<ng-container *ngFor="let validation of field?.validations" ngProjectAs="mat-error">
  <mat-error
    *ngIf="
      validation.type &&
      (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
      getErrorMessage(field, validation)
    "
    >{{ validation.message }}</mat-error
  >
  <mat-error
    [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
    *ngIf="
      validation.type &&
      validation.type !== 'ERROR' &&
      validation.type !== 'BLOCK_WARN' &&
      getInfoWarnMessage(field, validation)
    "
    >{{ validation.message }}</mat-error
  >
</ng-container>

</div>
