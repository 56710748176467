import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';

import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CarouselModule } from 'primeng/carousel';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { MatTabsModule } from '@angular/material/tabs';
import { SwiperModule } from 'swiper/angular';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { WebcamModule } from 'ngx-webcam';
import { MatListModule } from '@angular/material/list';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DateTimePickerModule } from 'ngx-datetime-picker';
import { CalendarModule as PCalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { MatTableModule } from '@angular/material/table';
import { CdkTableModule } from '@angular/cdk/table';
import { TreeModule } from 'primeng/tree';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { NgxPaginationModule } from 'ngx-pagination';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { ApplicationPipesModule } from '@common-services';
import { MatSliderModule } from '@angular/material/slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PaginatorModule } from 'primeng/paginator';
@NgModule({
  declarations: [],
  imports: [    CommonModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    CarouselModule,
    TooltipModule,
    AccordionModule,
    MatTabsModule,
    SwiperModule,
    MatSelectModule,
    MatStepperModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    WebcamModule,
    MatListModule,
    SelectButtonModule,
    PCalendarModule,
    DateTimePickerModule,
    ChipModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    AngularEditorModule,
    MatChipsModule,
    MatCheckboxModule,
    PdfViewerModule,
    PaginatorModule,
    MatProgressBarModule,
    DragDropModule,
    ApplicationPipesModule,
    NgScormPlayerModule,
    MatTableModule,
    CdkTableModule,
    TreeModule,
    AgmCoreModule,
    AgmDirectionModule,
    NgxPaginationModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    MatButtonModule,
    MatSliderModule,
    ImageCropperModule
],
  exports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    CarouselModule,
    TooltipModule,
    AccordionModule,
    MatTabsModule,
    SwiperModule,
    MatSelectModule,
    MatStepperModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    InfiniteScrollModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    WebcamModule,
    MatListModule,
    SelectButtonModule,
    PCalendarModule,
    DateTimePickerModule,
    ChipModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    AngularEditorModule,
    MatChipsModule,
    MatCheckboxModule,
    PdfViewerModule,
    PaginatorModule,
    MatProgressBarModule,
    DragDropModule,
    ApplicationPipesModule,
    NgScormPlayerModule,
    MatTableModule,
    CdkTableModule,
    TreeModule,
    AgmCoreModule,
    AgmDirectionModule,
    NgxPaginationModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    MatButtonModule,
    MatSliderModule,
    ImageCropperModule,
    ClipboardModule
  ]
})
export class TransactionMaterialModule { }
