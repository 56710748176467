import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChangeUnit, Librarytypes, TranslatorService, NodeGsiFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-node-parallel',
  templateUrl: './node-parallel.component.html',
  styleUrls: ['./node-parallel.component.scss'],
})
export class NodeParallelComponent implements OnInit, OnDestroy {
  @Input() graphComponent: boolean;
  @Input() gsiData: any;
  @Input() node: any;

  private ngUnsubscribe = new Subject();

  @Output() emitChangeCompoent: EventEmitter<any> = new EventEmitter<any>();
  labels: any;

  palCUList: any = [{ ...new ChangeUnit(), isParallel: true }];
  palCU: any;
  currentCU: any;
  cuListInGSI: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private nodeChangeUnitFacadeService: NodeChangeUnitFacadeService
  ) {
    this.detectLanguageChange();
    this.detectEmbeddedGsiData();
    this.detectParallelCUFromLibrary();
  }

  ngOnInit(): void {
    this.currentCU = this.node.data;
    this.cuListInGSI = this.gsiData.solutionLogic;
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  selectEmbeddedGsiFromLibrary() {
    this.gsiFacade.openLibrary(Librarytypes.GSIEmbedded);
  }
  detectEmbeddedGsiData() {
    this.gsiFacade.cuDetailsByGsiId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.palCU = {
          ...res,
          cuType: 'GSI',
          isParallel: true,
        };
        if (this.palCUList[0]?.name == '') {
          this.palCUList[0] = { ...this.palCUList[0], ...res };
        } else {
          this.palCUList.push(this.palCU);
        }
      }
    });
  }
  detectParallelCUFromLibrary() {
    this.nodeChangeUnitFacadeService.cuDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.palCU = {
          ...res,
          cuType: 'BASIC',
          isParallel: true,
        };
        if (this.palCUList[0]?.name == '') {
          this.palCUList[0] = {
            ...this.palCUList[0],
            ...res,
            cuType: 'BASIC',
            isParallel: true,
          };
        } else {
          this.palCUList.push(this.palCU);
        }
      }
    });
  }
  remove(cu: any, index: any) {
    this.palCUList.slice(index, 1);
  }
  GotoPrevious() {
    this.emitChangeCompoent.emit(Librarytypes.AddNodeActionMenu);
  }
  saveParallel() {
    this.palCUList.forEach((cu: any) => {
      cu.referencedChangeUnit = cu.id;
      cu.tfReferencedChangeUnit = cu.tfId;
      cu.id = undefined;
      cu.tfId = undefined;
      let maxindex = this.cuListInGSI.length + 1;
      let body = { isParallel: true, nextCUName: cu.name, index: maxindex };
      cu['index'] = maxindex;
      this.cuListInGSI.push(cu);
      let emptyIndex = this.currentCU.nextTriggerSet.findIndex((cu: any) => cu.nextCUName === '');
      if (emptyIndex !== -1) {
        this.currentCU.nextTriggerSet[emptyIndex] = body;
      } else {
        this.currentCU.nextTriggerSet.push(body);
      }
    });
    let findCurrentCU = this.cuListInGSI.findIndex((cu: any) => cu.name == this.currentCU.name);
    this.cuListInGSI[findCurrentCU] = this.currentCU;
    this.gsiData.solutionLogic = this.cuListInGSI;
    this.saveGsi();
  }
  saveGsi() {
    this.gsiFacade.saveGsi(this.gsiData);
  }

  removeChangeUnit() {
    this.palCUList.pop();
  }
  addChangeUnit() {
    this.palCUList.push({ ...new ChangeUnit(), isParallel: true });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
