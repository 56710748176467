<div class="{{ selectedTheme }}">
  <div class="transactionSmartComponent">
    <!-- <app-transaction-view
      [gsi]="ctxGSI"
      [selectedCu]="selectedCu"
      [mode]="'transaction'"
      [txnData]="txnData"
      [entitiesList]="physicalEntities"
      (getNextCUEmitter)="getNextCU($event)"
    >
    </app-transaction-view> -->
    <!-- {{ gsiData }} -->
    <div
      *ngIf="environmentType == 'development'"
      class="nh-p-5 nh-display-block nh-text-right -versionHistory"
    >
      Version: {{ gsiData?.changeUnits?.version }}
    </div>
    <ng-container
      *ngTemplateOutlet="
        transactionView;
        context: {
          isTransactionActive: !openPopUpDialog,
          layoutConfig: layoutConfigurations,
          ctxTransactionCU: !openPopUpDialog
            ? transactionCU
            : previousTransactionCU,
          ctxGSI: !openPopUpDialog ? gsiData : _gsiData,
          ctxCurrentCu: !openPopUpDialog ? currentCu : _previousCUList,
          ctxSolutionData: !openPopUpDialog
            ? solutionData
            : previousSolutionData || solutionData
        }
      "
    ></ng-container>
    <div
      [ngClass]="{
        'debug-wrap': toggle == true,
        'debug-toggle': toggle == false
      }"
      *ngIf="isDebug"
    >
      <div class="debug-top">
        <div class="debug-info">
          <h3>Debug Info</h3>
        </div>
        <div class="debug-pathway">
          <div class="pathway-btns">
            <button class="btn btn-exit" (click)="exit()">
              Exit Debug Mode
            </button>
          </div>
        </div>
      </div>
      <div class="debug-mid">
        <div class="debug-cu">
          <div class="library-expansion-list" id="libraryExpansionList">
            <ul class="culistul-li">
              <li
                *ngFor="let cu of gsiData?.changeUnits?.solutionLogic"
                (click)="intialSelection(cu)"
              >
                {{ cu?.DATA?.name }}
              </li>
            </ul>
            <div>
              <ng-container>
                <ng-template
                  *ngTemplateOutlet="
                    changeUnit;
                    context: { cus: selectedCuDetails }
                  "
                >
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="debug-data w-100">
          <div class="row">
            <div class="col-3">
              <div class="data-info">
                <h5>data</h5>
                <ul>
                  <li>
                    <span>Value</span>
                    <span *ngFor="let v of value">{{ v }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-4">
              <div class="data-info">
                <h5>source info</h5>
                <ul>
                  <li>
                    <span>Type</span>
                    <span>{{ sourcemap?.sourceType }}</span>
                  </li>
                  <li>
                    <span>Actual Source</span>
                    <span>{{ sourcemap?.actualSource }}</span>
                  </li>
                  <li>
                    <span>Possible Sources</span>
                    <span>
                      <li *ngFor="let source of sourcemap?.possibleSources">
                        {{ source }}
                      </li></span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-4">
              <div class="data-info">
                <h5>target info</h5>
                <ul>
                  <li>
                    <span>Possible Targets</span>
                    <span>
                      <li *ngFor="let target of targetMap">
                        {{ target }}
                      </li></span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="debug-arrow">
        <img
          (click)="toggleDebugMode()"
          [src]="
            '../../../../assets/img/debugpanel/debug-arrowup.svg'
              | CDNUrlRewritePipe
          "
          alt=""
        />
      </div>
    </div>
  </div>

  <ng-template #changeUnit let-cu="cus">
    <mat-accordion>
      <mat-expansion-panel class="nested-cu-expansion">
        <!-- <ng-container>
          <ng-template *ngTemplateOutlet="changeUnit; context: { cus: cu }"> </ng-template>
        </ng-container> -->
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span
              (click)="selectedCU(cu)"
              [matTooltipPosition]="'above'"
              matTooltipClass="tool-tip-custom"
              matTooltip="{{ cu?.name }}"
              id="cuDetails_{{ cu?.name | stringReplace }}"
              >{{ cu?.DATA?.name }}</span
            >
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="cu?.DATA?.solutionLogic">
          <ng-container *ngFor="let change of cu?.DATA?.solutionLogic">
            <ng-template
              *ngTemplateOutlet="changeUnit; context: { cus: change }"
            >
            </ng-template>
          </ng-container>
        </div>

        <mat-accordion
          *ngIf="
            !cu?.DATA?.solutionLogic &&
            currentChangeUnit?.DATA?.id == cu?.DATA?.id
          "
          class="playerdata header-0"
          id="accordianPhysical"
        >
          <mat-expansion-panel
            *ngIf="cuphysicalLayer?.length > 0"
            id="accordianExpansionPhysical"
          >
            <mat-expansion-panel-header id="physical_Layer">
              <mat-panel-title class="active-bold">
                <span
                  id="dcd_{{ cu?.name | stringReplace }}.physical_layer"
                  (click)="layerSelected('physical')"
                  >Physical_Layer</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngIf="currentLayer == 'physical'">
              <ng-template
                *ngTemplateOutlet="layer; context: { layer: cuphysicalLayer }"
              >
              </ng-template>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion
          *ngIf="
            !cu?.DATA?.solutionLogic &&
            currentChangeUnit?.DATA?.id == cu?.DATA?.id
          "
          class="ilayerdata header-0"
          id="accordian"
        >
          <mat-expansion-panel
            *ngIf="cuinformationLayer?.length > 0"
            id="expansionPanel"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                class="active-bold"
                (click)="layerSelected('information')"
              >
                <span
                  id="dcd_{{ cu?.name | stringReplace }}.information_layer"
                  (click)="layerSelected('information')"
                >
                  Information_Layer
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngIf="currentLayer == 'information'">
              <ng-template
                *ngTemplateOutlet="
                  layer;
                  context: { layer: cuinformationLayer }
                "
              >
              </ng-template>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion
          *ngIf="
            !cu?.DATA?.solutionLogic &&
            currentChangeUnit?.DATA?.id == cu?.DATA?.id
          "
          class="ilayerdata header-0"
          id="accordian"
        >
          <mat-expansion-panel
            *ngIf="cutriggerCESLayer?.length > 0"
            id="expansionPanel"
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                class="active-bold"
                (click)="layerSelected('triggerCES')"
              >
                <span
                  id="dcd_{{ cu?.name | stringReplace }}.information_layer"
                  (click)="layerSelected('triggerCES')"
                >
                  TriggerCES
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngIf="currentLayer == 'triggerCES'">
              <ng-template
                *ngTemplateOutlet="layer; context: { layer: cutriggerCESLayer }"
              >
              </ng-template>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-expansion-panel>
    </mat-accordion>
  </ng-template>
  <ng-template #layer let-layerData="layer">
    <div>
      <ul
        *ngFor="let entity of layerData; let entityIndex = index"
        class="mt-20"
      >
        <li class="cstm-attr-plus li-top-0">
          <mat-accordion class="w-100">
            <mat-expansion-panel [expanded]="selectedEntity?.id == entity?.id">
              <mat-expansion-panel-header
                aria-expanded="selectedEntity?.id ==entity?.id"
              >
                <mat-panel-title
                  class="active-p"
                  (click)="showAttr(entity, entityIndex, $event)"
                >
                  <p
                    [matTooltipPosition]="'above'"
                    matTooltipClass="tool-tip-custom"
                    matTooltip="{{ entity?.name }}"
                    class="ent-widt"
                  >
                    {{ entity?.name }}
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <ng-container
                *ngFor="
                  let attr of entity?.nslAttributes;
                  let attrIndex = index
                "
              >
                <ng-template
                  *ngTemplateOutlet="
                    nested;
                    context: { attrs: attr, key: layerKey }
                  "
                >
                </ng-template>
              </ng-container>
            </mat-expansion-panel>
          </mat-accordion>
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template #nested let-attr="attrs" let-keys="key">
    <ul>
      <li class="li-top-0">
        <div class="li-flex att-li">
          <ng-template #attrName>
            <p
              [matTooltipPosition]="'above'"
              matTooltipClass="tool-tip-custom"
              matTooltip="{{ attr?.name }}"
              class="attlist-widt"
            >
              <span
                class="att-dcd-name"
                (click)="setData(keys + '.' + attr?.name, attr)"
                >{{ attr?.name }}</span
              >
            </p>
          </ng-template>
          <mat-accordion
            class="w-100"
            *ngIf="attr?.nslAttributes; else attrName"
          >
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title
                  class="active-p"
                  (click)="setData(keys + '.' + attr?.name, attr)"
                >
                  <p
                    [matTooltipPosition]="'above'"
                    matTooltipClass="tool-tip-custom"
                    matTooltip="{{ attr?.name }}"
                    class="ent-widt"
                  >
                    {{ attr?.name | dcd }}
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul class="attribute-list attribute-list-li">
                <ng-container *ngFor="let subEntity of attr?.nslAttributes">
                  <ng-template
                    *ngTemplateOutlet="
                      nested;
                      context: {
                        attrs: subEntity,
                        key: keys + '.' + attr?.name
                      }
                    "
                  >
                  </ng-template>
                </ng-container>
              </ul>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </li>
    </ul>
  </ng-template>

  <div
    *ngIf="
      transactionInProgress || transactionFacadeService?.cuData?.isCancellable
    "
  >
    <div class="modal d-block" tabindex="-1">
      <div class="modal-dialog dialog-in-middle">
        <div class="modal-content text-center" style="background: #fee0e0;">
          <div class="modal-header">
            <h5 class="modal-title">Alert</h5>
          </div>
          <div class="modal-body font-weight-bold">
            <p>This transaction might take time. Would you like to wait ?</p>
          </div>
          <div class="my-3 text-center">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="waitUntilTransaction()"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-danger"
              (click)="goToLibrary()"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-danger"
              *ngIf="transactionFacadeService?.cuData?.isCancellable"
              (click)="cancelTransaction()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #myDialog>
  <ng-container
    *ngTemplateOutlet="
      transactionView;
      context: {
        isTransactionActive: openPopUpDialog,
        layoutConfig: popupLayoutConfigurations,
        ctxTransactionCU: openPopUpDialog
          ? transactionCU
          : previousTransactionCU,
        ctxGSI: openPopUpDialog ? gsiData : _gsiData,
        ctxCurrentCu: openPopUpDialog ? currentCu : _previousCUList,
        ctxSolutionData: openPopUpDialog ? solutionData : previousSolutionData
      }
    "
  ></ng-container>
  <!-- <div mat-dialog-actions>
      <i
        mat-dialog-close
        class="bi bi-x-lg"
        style="position: absolute; top: 5px; right: 30px; cursor: pointer;"
        (click)="dialogClose()"
      ></i>
    </div> -->
</ng-template>
<ng-template
  #transactionView
  let-isTransactionActive="isTransactionActive"
  let-layoutConfig="layoutConfig"
  let-ctxTransactionCU="ctxTransactionCU"
  let-ctxSolutionData="ctxSolutionData"
  let-ctxGSI="ctxGSI"
  let-ctxCurrentCu="ctxCurrentCu"
>
  <ng-container [ngSwitch]="layoutConfig?.specialComponent">
    <ng-template [ngSwitchCase]="'basic'" *ngIf="isActiveFlag">
      <app-basic-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-basic-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'opencu'" *ngIf="isActiveFlag">
      <app-opencu-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-opencu-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'asset'" *ngIf="isActiveFlag">
      <app-asset-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-asset-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'cuhorizontal'" *ngIf="isActiveFlag">
      <app-cuhorizontal-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-cuhorizontal-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'extended'" *ngIf="isActiveFlag">
      <app-extended-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-extended-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'assethorizontal'" *ngIf="isActiveFlag">
      <app-assethorizontal-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-assethorizontal-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'luxury'" *ngIf="isActiveFlag">
      <app-luxury-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-luxury-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'regular'" *ngIf="isActiveFlag">
      <app-regular-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-regular-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'enterprise'" *ngIf="isActiveFlag">
      <app-enterprise-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-enterprise-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'enterprise3'" *ngIf="isActiveFlag">
      <app-enterprise3-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-enterprise3-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'tabaccord'" *ngIf="isActiveFlag">
      <app-tabaccord-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-tabaccord-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'miscellaneous'">
      <app-miscellaneous-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-miscellaneous-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'mixed'" *ngIf="isActiveFlag">
      <app-mixed-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-mixed-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'carnival'" *ngIf="isActiveFlag">
      <app-carnival-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-carnival-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'progressbar'" *ngIf="isActiveFlag">
      <app-progressbar-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-progressbar-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'cudashboard'" *ngIf="isActiveFlag">
      <app-cudashboard-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-cudashboard-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'cupopup'">
      <app-cupopup-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
        [gsiPopup]="gsiPopup"
      >
      </app-cupopup-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'etcdashboard'" *ngIf="isActiveFlag">
      <app-etc-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-etc-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'questionandanswer'" *ngIf="isActiveFlag">
      <app-questionandanswer-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-questionandanswer-transaction-view>
    </ng-template>
    <ng-template [ngSwitchCase]="'studioverse'" *ngIf="isActiveFlag">
      <app-studioverse-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-studioverse-transaction-view>
    </ng-template>
    <ng-template ngSwitchDefault *ngIf="isActiveFlag">
      <app-transaction-view
        *ngIf="ctxGSI && ctxCurrentCu"
        [transactionCU]="ctxTransactionCU"
        [gsi]="ctxGSI"
        [selectedCu]="ctxCurrentCu"
        [mode]="'transaction'"
        [txnData]="eventsData"
        [isDebugMode]="isDebug"
        [solutionInfo]="ctxSolutionData"
        (getNextCUEmitter)="submitFormData($event)"
        (formValueChange)="formValueChange($event)"
        (setInividualGsiData)="settingGsi($event)"
        (inividualGsiData)="gsiInformation($event)"
        (tempEntityListEmit)="entityDataForTable($event)"
        (tempInfoEntityListEmit)="infoEntityDataForTable($event)"
        [isCarnival]="isCarnival"
        [isOnboarding]="isOnboarding"
        [layoutConfigurations]="layoutConfig"
        [selectedCard]="selectedCard"
        [isTransactionActive]="isTransactionActive"
        [gsiDesign]="entityDesign"
      >
      </app-transaction-view>
    </ng-template>
  </ng-container>
</ng-template>
