import { Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AuthenticationService, CredentialsService, GsiFacadeService, LoaderService, LoginComponent, LoginDesign, ManageConfigurationFacadeService, OnboardingFacadeService, SharedFacadeService, SsoLoginService, TenantCustomUiFacadeService, TransactionFacadeService, TranslatorService, UsersFacadeService, setPropertyInDivLayout } from '@common-services';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-template-seventeen',
  templateUrl: './template-seventeen.component.html',
  styleUrls: ['./template-seventeen.component.scss'],
})
export class TemplateSeventeenComponent extends LoginComponent implements OnInit {
  @Input() loginDesign: LoginDesign;
  showPassword: boolean = false;
  emailValidator: boolean = false;
  emailValidationNecessary: boolean = false;
  constructor(
    injector: Injector,
    router: Router,
    route: ActivatedRoute,
    formBuilder: FormBuilder,
    authenticationService: AuthenticationService,
    userFacade: UsersFacadeService,
    alertService: AlertService,
    translator: TranslatorService,
    loader: LoaderService,
    keycloak: KeycloakService,
    credentialsService: CredentialsService,
    tenantCustomUiFacadeService: TenantCustomUiFacadeService,
    gsiFacade: GsiFacadeService,
    manageConfigurationFacade: ManageConfigurationFacadeService,
    ssoService: SsoLoginService,
    onboardingFacadeService: OnboardingFacadeService,
    private elRef: ElementRef,
    dialog: MatDialog,
    domsanitizer: DomSanitizer,
    public transactionFacadeService: TransactionFacadeService,
    sharedfacade: SharedFacadeService
  ) {
    super(
      injector,
      router,
      route,
      formBuilder,
      authenticationService,
      userFacade,
      alertService,
      translator,
      loader,
      keycloak,
      credentialsService,
      tenantCustomUiFacadeService,
      gsiFacade,
      manageConfigurationFacade,
      ssoService,
      onboardingFacadeService,
      dialog,
      domsanitizer,
      transactionFacadeService,
      sharedfacade
    );
  }

  //For toggle-eye-icon in password details to show and hide password
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  ngOnInit(): void {
    setPropertyInDivLayout(this.elRef, '--main-background-color', this.loginDesign?.backGround?.color);
    setPropertyInDivLayout(this.elRef, '--chip-color', this.loginDesign?.chip?.color);
    if (this.loginDesign?.headingText?.font?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--heading-font', this.loginDesign?.headingText?.font);
    }
    if (this.loginDesign?.caption?.font?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-font', this.loginDesign?.caption?.font);
    }
    if (this.loginDesign?.actionButton?.fontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--action-font-color', this.loginDesign?.actionButton?.fontColor);
    }
    if (this.loginDesign?.actionButton?.buttonColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--action-button-color', this.loginDesign?.actionButton?.buttonColor);
    }
    if (this.loginDesign?.caption?.headingFontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-heading-color', this.loginDesign?.caption?.headingFontColor);
    }
    if (this.loginDesign?.caption?.textFontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-description-color', this.loginDesign?.caption?.textFontColor);
    }
    if(this.tenantName=='studioverse'){
      this.emailValidationNecessary=true;
    }
    else{
      this.emailValidator=true;
    }
  }

  ValidateEmail() {
    let email = this.loginForm.value['username'];
    let pattern = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9]+\.){1,2}[a-zA-Z]{2,7}$/;
    /*istanbul ignore else */
    if (pattern.test(email)) {
      this.emailValidator = true;
    } else {
      this.emailValidator = false;
    }
  }
}
