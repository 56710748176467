<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closePanel()">
        {{ labels?.close }}
      </button>
      <div class="status-blk">
        Status Draft (Ver 1.1)
      </div>
    </div>
    <div class="heading-area">
      <h2>{{ labels?.Connector }}</h2>
      <button class="save" (click)="saveSOP()">{{ labels?.Save }}</button>
    </div>
  </div>
  <div class="accrdn-wrap">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ labels?.List_of_Connectors }} {{ listOfSopDCDs?.length }} </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="dcd-created-list" *ngFor="let completedDcd of listOfSopDCDs; let i = index">
          <ul>
            <p *ngIf="completedDcd?.sourceContextualName">
              <span>{{ completedDcd.sourceContextualName | dcd }}</span>
            </p>
            <li>
              <figure class="w-auto text-center">
                <img class="w-75" [src]="'/assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" />
              </figure>
            </li>
            <li>
              <!-- <div class="hovertext">
                <span>
                  {{ completedDcd.targetContextualName | dcdLabel: false }}
                </span>
              </div> -->
              <!-- <span *ngIf="completedDcd?.targetContextualName">{{
                completedDcd.targetContextualName | dcdLabel: true
              }}</span> -->
              <p *ngIf="completedDcd?.targetContextualName">
                <span>{{ completedDcd.targetContextualName | dcd }}</span>
              </p>
            </li>
            <figure class="figure" id="deleteDcd" (click)="deleteDcd(i)">
              <img [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
            </figure>
          </ul>
        </div>

        <div class="dcs-lists d-none" *ngFor="let completedDcd of listOfSopDCDs; let i = index">
          <ol>
            <li>
              <ul>
                <li>
                  <div class="d-flex">
                    <div *ngIf="completedDcd.sourceArray?.length > 0">
                      <p *ngFor="let display of completedDcd.sourceArray; let last = last">
                        <span id="source">{{ display.name | dcd }}<span *ngIf="!last">,</span></span>
                      </p>
                    </div>
                    <figure>
                      <img [src]="'/assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" alt="" />
                    </figure>
                    <p *ngIf="completedDcd?.targetContextualName">
                      <span>{{ completedDcd.targetContextualName | dcd }}</span>
                    </p>
                  </div>
                  <div class="del-dcd d-none">
                    <!-- <button id="deleteDcd"  (click)="deleteDcd(i)">-->
                    <figure id="deleteDcd" (click)="deleteDcd(i)">
                      <img [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                    </figure>
                    <!-- <span>Delete</span> -->
                    <!-- </button>-->
                  </div>
                </li>
              </ul>
            </li>
            <li>
              <ul class="dcd-lables">
                <!-- <li>{{ completedDcd.targetContextualName | dcdLabel: true }}</li>
                <li>{{ completedDcd.targetContextualName | dcdLabel: false }}</li> -->

                <!-- <li>
                  <span class="withouthover">
                    {{ completedDcd.targetContextualName | dcdLabel: true }}
                  </span>
                  <span class="withhover">
                    {{ completedDcd.targetContextualName | dcdLabel: false }}
                  </span>
                </li> -->
              </ul>
              <!-- <div class="diflex">
                <figure><img [src]="'/assets/img/attribute-red-icon.svg'| CDNUrlRewritePipe" /></figure>
                <p>
                  <span id="target_{{ completedDcd.targetContextualName | stringReplace }}_Item{{ i + 1 }}">{{
                    completedDcd.targetContextualName | dcd
                  }}</span>
                </p>
              </div> -->
            </li>
          </ol>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ labels?.Create_Connection }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <mat-label>Source</mat-label>
          <mat-form-field appearance="fill">
            <mat-label> Select layer</mat-label>
            <mat-select [(ngModel)]="selectedLayer" (selectionChange)="mapLabel(selectedLayer, currentGsi)">
              <mat-option *ngFor="let layer of changeUnit?.layers" [value]="layer"> {{ layer?.type }}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-node-expand-layers
            *ngIf="selectedLayer"
            [SOPSource]="true"
            [layerData]="selectedLayer?.participatingItems"
            [tempGsiObj]="tempGsiObj"
            [cuName]="changeUnit?.name"
            [configurationData]="configurationData"
            [layerLabel]="layerLabel"
            [changeUnit]="changeUnit"
          >
          </app-node-expand-layers>
        </div>
        <div>
          <mat-label>Target</mat-label>
          <mat-form-field>
            <mat-label>Select CU</mat-label>
            <mat-select [(ngModel)]="selectedWishCu">
              <mat-option *ngFor="let wishCu of wishGsiFinal?.solutionLogic" [value]="wishCu">{{
                wishCu?.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Select Layer</mat-label>
            <mat-select [(ngModel)]="selectedWishLayer" (selectionChange)="mapLabel(selectedWishLayer, wishGsiFinal)">
              <mat-option *ngFor="let wishLayer of selectedWishCu?.layers" [value]="wishLayer">{{
                wishLayer?.type
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <app-node-expand-layers
            *ngIf="selectedWishLayer"
            [SOPTarget]="true"
            [layerData]="selectedWishLayer?.participatingItems"
            [tempGsiObj]="tempGsiObj"
            [cuName]="selectedWishCu?.name"
            [configurationData]="selectedWishCu"
            [layerLabel]="layerLabel"
            [changeUnit]="selectedWishCu"
          >
          </app-node-expand-layers>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!-- <mat-form-field appearance="standard">
  <mat-label>{{ labels?.Select_the_Environment }}</mat-label>
  <mat-select
    [(ngModel)]="selectedEnv"
    multiple
    id="selectEnv"
    (selectionChange)="retirveSelectedEnv($event)"
    name="envname"
  >
    <mat-option *ngFor="let env of envDetails" id="{{ env.type }} " [value]="env.type">
      {{ env.type }}
    </mat-option>
  </mat-select>
</mat-form-field> -->
