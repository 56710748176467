export class EntityWidget {
  image: string;
  noOfAttributesRequired: number;
  name: string;
  axises: AxisType[];
  axisCountFixed: boolean;
  chartType: string;
  data: any;
  minAttributes: number;
  maxAttributes: number;
  disabled?: boolean;
  categories: any;
}
export interface AxisType {
  name: string;
  type: string;
  isOptional: boolean;
}
let path: string = '../../../../assets/img/general-entity/icons-dashboard/';
export const entityWidgets: EntityWidget[] = [
  {
    image: `${path}Area.svg`,
    noOfAttributesRequired: 2,
    name: 'Area',
    axises: [
      { name: 'value', type: 'number', isOptional: false },
      { name: 'label', type: 'string', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'area2d',
    data: [
      { value: 10, label: '12' },
      { value: 15, label: '20' },
      { value: 25, label: '40' },
      { value: 30, label: '67' },
      { value: 28, label: '90' },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Bar.svg`,
    noOfAttributesRequired: 2,
    name: 'Bar',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'bar2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Column.svg`,
    noOfAttributesRequired: 2,
    name: 'Column',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'column2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Pareto.svg`,
    noOfAttributesRequired: 2,
    name: 'Pareto',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'pareto2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}PieChart.svg`,
    noOfAttributesRequired: 2,
    name: 'Pie Chart',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'pie2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Pyramid.svg`,
    noOfAttributesRequired: 2,
    name: 'Pyramid',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'pyramid',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Scatter.svg`,
    noOfAttributesRequired: 2,
    name: 'Scatter',
    axises: [
      { name: 'y', type: 'number', isOptional: false },
      { name: 'x', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'scatter',
    data: [
      { y: 2, x: 10 },
      { y: 2, x: 15 },
      { y: 2, x: 25 },
      { y: 4, x: 30 },
      { y: 6, x: 28 },
    ],
    categories: [{ x: 10 }, { x: 15 }, { x: 25 }, { x: 30 }, { x: 28 }],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Spine.svg`,
    noOfAttributesRequired: 2,
    name: 'spline',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'spline',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}StackedBar.svg`,
    noOfAttributesRequired: 2,
    name: 'Stacked Bar',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: false,
    chartType: 'stackedbar2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [
      {
        category: [
          {
            label: 'apple',
          },
          {
            label: 'orange',
          },
          {
            label: 'banana',
          },
          {
            label: 'mango',
          },
          {
            label: 'grape',
          },
        ],
      },
    ],
    minAttributes: 2,
    maxAttributes: -1,
  },
  {
    image: `${path}StackedColumn.svg`,
    noOfAttributesRequired: 2,
    name: 'Stacked Column',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: false,
    chartType: 'stackedcolumn2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [
      {
        category: [
          {
            label: 'apple',
          },
          {
            label: 'orange',
          },
          {
            label: 'banana',
          },
          {
            label: 'mango',
          },
          {
            label: 'grape',
          },
        ],
      },
    ],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Timeline.svg`,
    noOfAttributesRequired: 2,
    name: 'Time Line',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'line',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Tree Map.svg`,
    noOfAttributesRequired: 2,
    name: 'Tree Map',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
      { name: 'svalue', type: 'number', isOptional: true },
    ],
    axisCountFixed: true,
    chartType: 'treemap',
    data: [
      {
        label: 'USA',
        value: 400,
        data: [
          {
            label: 'North',
            value: 100,
            svalue: 2,
            data: [
              {
                label: 'North City1',
                value: 50,
                svalue: 1,
              },
              {
                label: 'North City2',
                value: 50,
                svalue: 1,
              },
            ],
          },
          {
            label: 'South',
            svalue: 2,
            value: 100,
            data: [
              {
                label: 'South City1',
                svalue: 1,
                value: 50,
              },
              {
                label: 'South City2',
                svalue: 1,
                value: 50,
              },
            ],
          },
          {
            label: 'East',
            svalue: 2,
            value: 100,
            data: [
              {
                label: 'East City1',
                svalue: 1,
                value: 50,
              },
              {
                label: 'East City2',
                svalue: 1,
                value: 50,
              },
            ],
          },
          {
            label: 'West',
            value: 100,
            svalue: 2,
            data: [
              {
                label: 'West City1',
                svalue: 1,
                value: 50,
              },
              {
                label: 'West City2',
                svalue: 1,
                value: 50,
              },
            ],
          },
        ],
      },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Timeline.svg`,
    noOfAttributesRequired: 2,
    name: 'Multi Series Line',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: false,
    chartType: 'msline',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [
      {
        category: [
          {
            label: 'apple',
          },
          {
            label: 'orange',
          },
          {
            label: 'banana',
          },
          {
            label: 'mango',
          },
          {
            label: 'grape',
          },
        ],
      },
    ],
    minAttributes: 2,
    maxAttributes: -1,
  },
  {
    image: `${path}Column.svg`,
    noOfAttributesRequired: 2,
    name: 'Histogram',
    axises: [
      { name: 'label', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'column2d',
    data: [
      { label: 'apple', value: 10 },
      { label: 'orange', value: 15 },
      { label: 'banana', value: 25 },
      { label: 'mango', value: 30 },
      { label: 'grape', value: 28 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
  {
    image: `${path}Tree Map.svg`,
    noOfAttributesRequired: 2,
    name: 'Heat Map',
    axises: [
      { name: 'rowid', type: 'string', isOptional: false },
      { name: 'columnid', type: 'string', isOptional: false },
      { name: 'value', type: 'number', isOptional: false },
    ],
    axisCountFixed: true,
    chartType: 'heatmap',
    data: [
      { columnid: '10', rowid: '10', value: 30 },
      { columnid: '15', rowid: '15', value: 30 },
      { columnid: '20', rowid: '25', value: 30 },
      { columnid: '25', rowid: '30', value: 30 },
      { columnid: '30', rowid: '28', value: 30 },
    ],
    categories: [],
    minAttributes: 2,
    maxAttributes: 3,
  },
];

export interface SetItem {
  equals(other: SetItem): boolean;
}
export class EntityValue {
  name: string;
  value: EntityWidget;
  /**
   *
   */
  constructor(name: string, value: EntityWidget) {
    this.name = name;
    this.value = value;
  }

  // public equals(other:EntityValue):boolean{
  //   return this.name === other.name;
  // }
}

// export class EntitySet<T extends EntityValue> extends Set<T>{
//   add(value:T):this{

//     if(!this.has(value)){
//       super.add(value);
//     }
//     return this;
//   }
//   has(value:T):boolean{
// 		let flag = false;
// 		this.forEach(item => {
// 			// if(item.)
// 			if (value.equals(item)) {
// 				flag = true;
// 			}
// 		});
// 		return flag;
// 	}

// }
