import { Injectable, OnDestroy } from '@angular/core';
import { NewLayoutSolutionEndpointService } from './layout-solution-endpoint.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { LoaderService } from './../loader/loader.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class NewLayoutSolutionFacadeService implements OnDestroy {
  ngUnsubscribe = new Subject();

  booksListSubject = new Subject();
  booksList$ = this.booksListSubject.asObservable();

  designSystemData = new BehaviorSubject('');
  designSystemData$ = this.designSystemData.asObservable();

  layoutBookDesign = new Subject();
  layoutBookDesign$ = this.layoutBookDesign.asObservable();
  getIndustryList = new Subject();
  getIndustryList$ = this.getIndustryList.asObservable();

  getBookCoversList = new Subject();
  getBookCoversList$ = this.getBookCoversList.asObservable();

  getCardCoversList = new Subject();
  getCardCoversList$ = this.getCardCoversList.asObservable();

  public getGsiIconsList = new Subject();
  getGsiIconsList$ = this.getGsiIconsList.asObservable();

  public getEntityIconsList = new Subject();
  getEntityIconsList$ = this.getEntityIconsList.asObservable();

  public getCuIconsList = new Subject();
  getCuIconsList$ = this.getCuIconsList.asObservable();

  templateDesign = new Subject();
  templateDesign$ = this.templateDesign.asObservable();

  layoutChangeUnitDesign = new Subject();
  layoutChangeUnitDesign$ = this.layoutChangeUnitDesign.asObservable();

  changeUnitUIControls = new Subject();
  changeUnitUIControls$ = this.changeUnitUIControls.asObservable();

  layoutBookSearch = new Subject();
  layoutBookSearch$ = this.layoutBookSearch.asObservable();

  bookSearchText = new Subject();
  bookSearchText$ = this.bookSearchText.asObservable();

  updateUser = new Subject();
  updateUser$ = this.updateUser.asObservable();

  savedTemplate = new Subject();
  savedTemplate$ = this.savedTemplate.asObservable();

  savedDesign = new Subject();
  savedDesign$ = this.savedDesign.asObservable();

  bookDetails = new BehaviorSubject('');
  bookDetails$ = this.bookDetails.asObservable();

  cardDetails = new BehaviorSubject('');
  cardDetails$ = this.cardDetails.asObservable();

  gsiDetails = new BehaviorSubject('');
  gsiDetails$ = this.gsiDetails.asObservable();

  cuDetails = new BehaviorSubject('');
  cuDetails$ = this.cuDetails.asObservable();

  entityDetails = new Subject();
  entityDetails$ = this.entityDetails.asObservable();

  storeIdsOfGsi = new BehaviorSubject('');
  storeIdsOfGsi$ = this.storeIdsOfGsi.asObservable();

  storeIdsOfCus = new BehaviorSubject('');
  storeIdsOfCus$ = this.storeIdsOfCus.asObservable();

  selectedGsiDetails = new BehaviorSubject('');
  selectedGsiDetails$ = this.selectedGsiDetails.asObservable();

  selectedCuDetails = new BehaviorSubject('');
  selectedCuDetails$ = this.selectedCuDetails.asObservable();

  selectedEntityDetails = new BehaviorSubject('');
  selectedEntityDetails$ = this.selectedEntityDetails.asObservable();

  selectedBetType = new BehaviorSubject('');
  selectedBetType$ = this.selectedBetType.asObservable();

  pageNumberForBookCover = new BehaviorSubject('');
  pageNumberForBookCover$ = this.pageNumberForBookCover.asObservable();

  selectedLayoutType = new Subject();
  selectedLayoutType$ = this.selectedLayoutType.asObservable();

  selectedGsiDetailsForLayout = new BehaviorSubject('');
  selectedGsiDetailsForLayout$ = this.selectedGsiDetailsForLayout.asObservable();

  selectedCuDetailsForLayout = new BehaviorSubject('');
  selectedCuDetailsForLayout$ = this.selectedCuDetailsForLayout.asObservable();

  CustomLayout = new BehaviorSubject('');
  CustomLayout$ = this.CustomLayout.asObservable();

  bookDetailsCustomLayout = new BehaviorSubject('');
  bookDetailsCustomLayout$ = this.bookDetailsCustomLayout.asObservable();

  customTab = new Subject();
  customTab$ = this.customTab.asObservable();

  bookFont = new BehaviorSubject('');
  bookFont$ = this.bookFont.asObservable();

  currentCuName = new BehaviorSubject('');
  currentCuName$ = this.currentCuName.asObservable();
  entityInChangeUnit = new Subject();
  entityInChangeUnit$ = this.entityInChangeUnit.asObservable();

  dataToCustomLayout = new Subject();
  dataToCustomLayout$ = this.dataToCustomLayout.asObservable();

  headerCustomization = new BehaviorSubject('');
  headerCustomization$ = this.headerCustomization.asObservable();

  selectedAssertUrl = new BehaviorSubject('');
  selectedAssertUrl$ = this.selectedAssertUrl.asObservable();

  entityDesign = new BehaviorSubject('');
  entityDesign$ = this.entityDesign.asObservable();

  checkedTemplateDatatoCustomLayoutSubject = new BehaviorSubject('');
  checkedTemplateDatatoCustomLayoutSubject$ = this.checkedTemplateDatatoCustomLayoutSubject.asObservable();
  iconUrlSubject = new Subject();
  iconUrlSubject$ = this.iconUrlSubject.asObservable();

  denyAttrSizeChange = new Subject();
  denyAttrSizeChange$ = this.denyAttrSizeChange.asObservable();

  platformType = new Subject();
  platformType$ = this.platformType.asObservable();

  deleteDesign = new Subject();
  deleteDesign$ = this.deleteDesign.asObservable();

  constructor(
    private layoutEndPoint: NewLayoutSolutionEndpointService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {}

  // getAllBooks(pageNo: number, pageSize: number, searchText: string) {
  //   return this.layoutEndPoint.getAllBooks(pageNo, pageSize, searchText);
  // }
  getBookDetails(bookId: any, platform?: string) {
    this.loader.show();
    return this.layoutEndPoint.getBookDetails(bookId, platform).subscribe((res: any) => {
      this.bookDetails.next(res); // return response of book details api
      this.loader.hide();
    });
  }
  getGsisInBook(bookId: any, platform?: string) {
    this.loader.show();
    this.layoutEndPoint
      .getGsisInBook(bookId, platform)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.gsiDetails.next(res);
        this.loader.hide();
      });
  }
  getCusInBook(bookId: any, gsiIds: any, platform?: string, item?: any, loadPreview = false) {
    this.loader.show();
    this.layoutEndPoint
      .getCusInBook(bookId, gsiIds, platform)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.cuDetails.next({ ...res, item, loadPreview });
        this.loader.hide();
      });
  }
  getEntitiesInBook(bookId: any, cuIds: any, platform?: string) {
    this.loader.show();
    this.layoutEndPoint
      .getEntitiesInBook(bookId, cuIds, platform)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.entityDetails.next(res);
        this.loader.hide();
      });
  }
  getColors(limit: number, bgPage: number) {
    return this.layoutEndPoint.getColors(limit, bgPage);
  }

  getWallpapers(bgsLimit: number, bgPage: number) {
    return this.layoutEndPoint.getWallpapers(bgsLimit, bgPage);
  }

  getAllBooksWithDesignData(pageNo: number, pageSize: number, searchText: string, approvalStatus?: string) {
    // this.loader.show();
    sessionStorage.removeItem('bookData');
    if (approvalStatus) {
      this.layoutEndPoint
        .getAllBooksWithDesignData(pageNo, pageSize, searchText, approvalStatus)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          /*istanbul ignore else*/
          if (res) {
            this.booksListSubject.next(res);
            // this.loader.hide();
          }
        });
    } else {
      this.layoutEndPoint
        .getAllBooksWithDesignData(pageNo, pageSize, searchText)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          /*istanbul ignore else*/
          if (res) {
            this.booksListSubject.next(res);
            // this.loader.hide();
          }
        });
    }
  }

  fetachDesignSystemData(data: any) {
    this.designSystemData.next(data);
  }
  getIndustries() {
    this.layoutEndPoint
      .getIndustries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getIndustryList.next(res);
      });
  }
  storeGsiIds(ids: any) {
    this.storeIdsOfGsi.next(ids);
  }

  storeCuIds(ids: any) {
    this.storeIdsOfCus.next(ids);
  }

  ////////////Fetching Indusry List////////////
  getBookCovers(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getBookCovers(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getBookCoversList.next(res);
      });
  }
  getCardCovers(ontologyId: any, limit: any, page: any, layout: any) {
    this.layoutEndPoint
      .getCardCovers(ontologyId, limit, page, layout)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getCardCoversList.next(res);
      });
  }
  getGsiIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getGsiIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getGsiIconsList.next(res);
      });
  }

  getEntityIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getEntityIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getEntityIconsList.next(res);
      });
  }
  getCuIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getCuIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getCuIconsList.next(res);
      });
  }

  getAllChapters() {
    return this.layoutEndPoint.getAllChapters();
  }

  storeBookDetails(bookDetails: any) {
    this.layoutEndPoint
      .storeBookData(bookDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  saveDesign(bookDetails: any, flag?: any) {
    this.loader.show();
    this.layoutEndPoint
      .saveDesign(bookDetails, flag)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster('Design Got Saved Successfully', '', 'success');
        this.loader.hide();
        this.savedTemplate.next(res.templateId);
        this.savedDesign.next(res);
      });
  }

  saveCardDesign(cardDetails: any) {
    this.loader.show();
    this.layoutEndPoint
      .saveCardDesign(cardDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster('Design Got Saved Successfully', '', 'success');
        this.loader.hide();
        this.savedTemplate.next(res.templateId);
      });
  }

  updatedTemplateId(templateId: any) {
    this.savedTemplate.next(templateId);
  }

  fetchBookDetails(bookId: any) {
    this.layoutEndPoint
      .fetchBookData(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutBookDesign.next(res);
      });
  }

  getListOfBooks(searchText: string) {
    this.layoutBookSearch.next(searchText);
  }

  clearBookSearchText(searchText: string) {
    this.bookSearchText.next(searchText);
  }

  storeChangeUnitDetails(bookDetails: any) {
    this.layoutEndPoint
      .storeChangeUnitData(bookDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  fetchChangeUnitDetails(bookId: any) {
    this.layoutEndPoint
      .fetchBookData(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutChangeUnitDesign.next(res);
      });
  }

  fetchUiControls(cuId: any) {
    this.layoutEndPoint
      .fetchBookData(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.changeUnitUIControls.next(res);
      });
  }

  fetchLayoutDesign(bookId: any) {
    this.layoutEndPoint
      .getAllLayoutDesigns(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.templateDesign.next(res);
      });
  }

  updateUserData(userId: any, body: any) {
    this.layoutEndPoint
      .updateUserData(userId, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.updateUser.next(res);
      });
  }

  updateSelectedCuForLayout(data: any) {
    this.selectedCuDetailsForLayout.next(data);
  }

  updatecuDetails(data: any) {
    this.cuDetails.next(data);
  }

  updateSelectedGsiForLayout(data: any) {
    this.selectedGsiDetailsForLayout.next(data);
  }

  updateSelectedCu(data: any) {
    this.selectedCuDetails.next(data);
  }

  updateSelectedGsi(data: any) {
    this.selectedGsiDetails.next(data);
  }

  updateSelectedEntity(data: any) {
    this.selectedEntityDetails.next(data);
  }

  updateSelectedBetType(data: any) {
    this.selectedBetType.next(data);
  }

  updateSelectedLayoutType(data: any) {
    this.selectedLayoutType.next(data);
  }

  updatePageNumberForBookCover(data: any) {
    this.pageNumberForBookCover.next(data);
  }

  updateCustomLayout(data: any) {
    this.CustomLayout.next(data);
  }

  updateBookDetailsCustomLayout(data: any) {
    this.bookDetailsCustomLayout.next(data);
  }

  updateCustomTab(data: any) {
    this.customTab.next(data);
  }

  updateCurrentCuName(data: any) {
    this.currentCuName.next(data);
  }
  updateBookFont(data: any) {
    this.bookFont.next(data);
  }
  getEntitiesinCU(cuId: any) {
    this.layoutEndPoint
      .getEntitiesinCU(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.entityInChangeUnit.next(res);
      });
  }

  updateHeaderCustomization(data: any) {
    this.headerCustomization.next(data);
  }

  updateSelectedAssertUrl(data: any) {
    this.selectedAssertUrl.next(data);
  }

  updateEntityDesign(data: any) {
    this.entityDesign.next(data);
  }

  sendDatatoCustomLayoutomponent(data: any) {
    this.dataToCustomLayout.next(data);
  }

  uploadImage(data: any) {
    /* istanbul ignore next */
    if (data) {
      this.layoutEndPoint
        .uploadData(data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.alertService.showToaster(res.message, '', 'success');
          this.iconUrlSubject.next(res);
        });
    }
  }

  setDenyAttrSizeChange() {
    this.denyAttrSizeChange.next(true);
  }

  resetDesign(platform: any, betLevel: any, masterId: any) {
    if (betLevel == 'BOOK') {
      this.layoutEndPoint
        .resetDesign(platform, betLevel, masterId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.deleteDesign.next(res);
        });
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
