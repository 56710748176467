<ng-container>
  <div class="form-group mmi-maps-container">
    <p *ngIf="displayLocationstatus" class="warning">
      {{ labels?.Location_is_disabled_in_Browser_Please_enable_and_reload_the_page_to_Apply_Settings }}
    </p>
    <!-- <p class="main-label">{{ field.displayName }} :</p> -->
    <div class="d-flex justify-content-between nh-column-gap-10">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="
            (field.sentence == null || field?.sentence === '') && !field?.hideLabels && !field?.configuration?.hideLabel
          "
          ><span class="main-label-inner">
            <span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          >
        </label>
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
    </div>
    <p
      class="top-text"
      *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <mat-form-field
      appearance="standard"
      class="custom-input-mat custom-input-icon"
      [ngClass]="
        field?.configuration?.locationType == 'Current Location' && field?.configuration?.readCurrentLocation
          ? 'disable-Input'
          : ''
      "
    >
      <div class="mmi-maps-wrapper">
        <input
          matInput
          placeholder="search for location"
          [matAutocomplete]="auto1"
          spellcheck="off"
          type="text"
          #search
          name="searchlocation"
          [ngModel]="searchControl"
          id="searchControl"
          (focus)="onFocus($event)"
          (ngModelChange)="searchLocations($event)"
          [ngClass]="{
            inValidClass:
              !field?.isInfo &&
              (group?.controls)[field?.attribute?.name]?.invalid &&
              (group?.controls)[field?.attribute?.name]?.touched
          }"
        />
        <mat-autocomplete
          #auto1="matAutocomplete"
          [displayWith]="getOptionText"
          (optionSelected)="locationSelected($event)"
          name="location"
        >
          <mat-option *ngFor="let location of locations" [value]="location">
            {{ location.placeAddress }}
          </mat-option>
        </mat-autocomplete>

        <span class="form-icon map-icon"></span>
        <svg
          #iconsvg
          class="actual-map-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          #google_map_icon
        >
          <g id="Group_104223" data-name="Group 104223" transform="translate(-1113 -781)">
            <g id="Group_100656" data-name="Group 100656" transform="translate(1113 781)">
              <g
                id="Rectangle_37086"
                data-name="Rectangle 37086"
                fill="none"
                stroke="#999"
                stroke-width="1"
                opacity="0"
              >
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
              </g>
              <g id="Group_100655" data-name="Group 100655" transform="translate(4.171 1.193)">
                <path
                  id="Path_103714"
                  data-name="Path 103714"
                  d="M10.072,22.623,3.18,12.565a8.349,8.349,0,1,1,13.784,0ZM10.072.5A7.352,7.352,0,0,0,4.005,12l6.067,8.854L16.139,12A7.352,7.352,0,0,0,10.072.5Z"
                  transform="translate(-2.223)"
                  fill="#999"
                />
                <path
                  id="Path_103715"
                  data-name="Path 103715"
                  d="M8.809,2.85A3.566,3.566,0,1,1,5.243,6.416,3.57,3.57,0,0,1,8.809,2.85Zm0,6.132A2.566,2.566,0,1,0,6.243,6.416,2.569,2.569,0,0,0,8.809,8.982Z"
                  transform="translate(-1.087 1.201)"
                  fill="#999"
                />
              </g>
            </g>
          </g>
        </svg>
        <div id="map" class="nh-mt-16" style="width: 100%; height: 60vh; background-color: white;"></div>
      </div>
    </mat-form-field>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
      <mat-error
        *ngIf="
          validation.type &&
          (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
          getErrorMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
      <mat-error
        [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
        *ngIf="
          validation.type &&
          validation.type !== 'ERROR' &&
          validation.type !== 'BLOCK_WARN' &&
          getInfoWarnMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
    </ng-container>
  </div>
</ng-container>
<!-- <ng-container *ngIf="showMarkers">
  <div class="form-group">
    <label
      class="main-label"
      *ngIf="
        (field.sentence == null || field?.sentence === '') && !field?.hideLabels && !field?.configuration?.hideLabel
      "
      >{{ field?.label }} : <span *ngIf="field?.isRequired" class="mat-form-field-required-marker">*</span></label
    >
    <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
      <input
        matInput
        placeholder="search for location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
        [formControl]="searchControl"
        id="searchControl"
      />
      <span class="form-icon map-icon"></span>
    </mat-form-field>
  </div>
</ng-container> -->
