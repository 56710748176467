import { Injectable, OnDestroy } from '@angular/core';
import { LayoutSolutionEndpointService } from './layout-solution-endpoint.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { AlertService } from '../toaster/alert.service';
import { LoaderService } from './../loader/loader.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { TranslatorService } from '@common-services';

@Injectable({
  providedIn: 'root',
})
export class LayoutSolutionFacadeService implements OnDestroy {
  ngUnsubscribe = new Subject();

  booksListSubject = new Subject();
  booksList$ = this.booksListSubject.asObservable();

  clearBooks = new Subject();
  clearBooks$ = this.clearBooks.asObservable();

  closeFilter = new Subject();
  closeFilter$ = this.closeFilter.asObservable();

  designSystemData = new Subject();
  designSystemData$ = this.designSystemData.asObservable();

  layoutBookDesign = new Subject();
  layoutBookDesign$ = this.layoutBookDesign.asObservable();
  getIndustryList = new Subject();
  getIndustryList$ = this.getIndustryList.asObservable();

  getBookCoversList = new Subject();
  getBookCoversList$ = this.getBookCoversList.asObservable();

  public getGsiIconsList = new Subject();
  getGsiIconsList$ = this.getGsiIconsList.asObservable();

  public getEntityIconsList = new Subject();
  getEntityIconsList$ = this.getEntityIconsList.asObservable();

  public getCuIconsList = new Subject();
  getCuIconsList$ = this.getCuIconsList.asObservable();

  templateDesign = new Subject();
  templateDesign$ = this.templateDesign.asObservable();

  layoutChangeUnitDesign = new Subject();
  layoutChangeUnitDesign$ = this.layoutChangeUnitDesign.asObservable();

  changeUnitUIControls = new Subject();
  changeUnitUIControls$ = this.changeUnitUIControls.asObservable();

  layoutBookSearch = new Subject();
  layoutBookSearch$ = this.layoutBookSearch.asObservable();

  bookSearchText = new Subject();
  bookSearchText$ = this.bookSearchText.asObservable();

  updateUser = new Subject();
  updateUser$ = this.updateUser.asObservable();

  savedTemplate = new Subject();
  savedTemplate$ = this.savedTemplate.asObservable();

  bookDetails = new Subject();
  bookDetails$ = this.bookDetails.asObservable();

  gsiDetails = new Subject();
  gsiDetails$ = this.gsiDetails.asObservable();

  cuDetails = new Subject();
  cuDetails$ = this.cuDetails.asObservable();

  entityDetails = new Subject();
  entityDetails$ = this.entityDetails.asObservable();

  storeIdsOfGsi = new BehaviorSubject('');
  storeIdsOfGsi$ = this.storeIdsOfGsi.asObservable();

  storeIdsOfCus = new BehaviorSubject('');
  storeIdsOfCus$ = this.storeIdsOfCus.asObservable();

  getLayoutConfig = new Subject();
  getLayoutConfig$ = this.getLayoutConfig.asObservable();

  allSiteMetadata = new Subject();
  allSiteMetadata$ = this.allSiteMetadata.asObservable();

  getRoleData = new Subject();
  getRoleData$ = this.getRoleData.asObservable();

  getSelectedThemeData = new Subject();
  getSelectedThemeData$ = this.getSelectedThemeData.asObservable();

  postRoleData = new Subject();
  postRoleData$ = this.postRoleData.asObservable();

  getSiteLayoutData = new Subject();
  getSiteLayoutData$ = this.getSiteLayoutData.asObservable();

  LayoutGsisList = new Subject();
  LayoutGsisList$ = this.LayoutGsisList.asObservable();
  weekWiseList = new BehaviorSubject(false);
  weekWiseList$ = this.weekWiseList.asObservable();

  getDivsData = new Subject();
  getDivsData$ = this.getDivsData.asObservable();

  getRoleSiteMetaData = new Subject();
  getRoleSiteMetaData$ = this.getRoleSiteMetaData.asObservable();

  postRoleSiteMetaData = new Subject();
  postRoleSiteMetaData$ = this.postRoleSiteMetaData.asObservable();

  searchedBookList = new Subject();
  searchedBookList$ = this.searchedBookList.asObservable();
  labels: any;

  isTemplateAPIDataDone: boolean = false;
  isUserAPIDataDone: boolean = false;

  templateAndUserAPIDone = new Subject();
  templateAndUserAPIDone$ = this.templateAndUserAPIDone.asObservable();

  constructor(
    private layoutEndPoint: LayoutSolutionEndpointService,
    private alertService: AlertService,
    private loader: LoaderService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  // getAllBooks(pageNo: number, pageSize: number, searchText: string) {
  //   return this.layoutEndPoint.getAllBooks(pageNo, pageSize, searchText);
  // }
  getBookDetails(bookId: any) {
    this.loader.show();
    return this.layoutEndPoint.getBookDetails(bookId).subscribe(
      (res: any) => {
        this.bookDetails.next(res); // return response of book details api
        this.loader.hide();
      },
      (error: Response) => {
        this.bookDetails.next('false'); //when book details api fails return false
      }
    );
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  getGsisInBook(bookId: any) {
    this.loader.show();
    this.layoutEndPoint
      .getGsisInBook(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.gsiDetails.next(res);
        this.loader.hide();
      });
  }

  onEditOrTransactClearBooks(str: string) {
    this.clearBooks.next(str);
  }
  getCusInBook(bookId: any, gsiIds: any) {
    this.loader.show();
    this.layoutEndPoint
      .getCusInBook(bookId, gsiIds)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.cuDetails.next(res);
        this.loader.hide();
      });
  }
  getEntitiesInBook(bookId: any, cuIds: any) {
    this.loader.show();
    this.layoutEndPoint
      .getEntitiesInBook(bookId, cuIds)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.entityDetails.next(res);
        this.loader.hide();
      });
  }
  getColors(limit: number, bgPage: number) {
    return this.layoutEndPoint.getColors(limit, bgPage);
  }

  getWallpapers(bgsLimit: number, bgPage: number) {
    return this.layoutEndPoint.getWallpapers(bgsLimit, bgPage);
  }

  getAllBooksWithDesignData(
    pageNo: number,
    pageSize: number,
    searchText: string,
    userRights?: string,
    approvalStatus?: string,
    selectedWeek?: number,
    isLibraryAdmin?: boolean
  ) {
    // this.loader.show();
    /*istanbul ignore else*/
    if (!isLibraryAdmin) sessionStorage.removeItem('bookData');
    if (approvalStatus) {
      if (selectedWeek) {
        const reqTime = moment()
          .utc()
          .subtract(7 * selectedWeek, 'days')
          .format();
        let epochTime = new Date(reqTime).getTime();

        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, approvalStatus, epochTime)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              /*istanbul ignore next */
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      } else {
        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, approvalStatus)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              /*istanbul ignore else*/
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      }
    } else {
      if (selectedWeek) {
        const reqTime = moment()
          .utc()
          .subtract(7 * selectedWeek, 'days')
          .format();
        let epochTime = new Date(reqTime).getTime();

        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, undefined, epochTime)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /* istanbul ignore next */
            if (res) {
              /* istanbul ignore next */
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      } else {
        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, undefined)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      }
    }
  }

  fetachDesignSystemData(data: any) {
    this.designSystemData.next(data);
  }
  getIndustries() {
    this.layoutEndPoint
      .getIndustries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getIndustryList.next(res);
      });
  }
  storeGsiIds(ids: any) {
    this.storeIdsOfGsi.next(ids);
  }

  storeCuIds(ids: any) {
    this.storeIdsOfCus.next(ids);
  }

  ////////////Fetching Indusry List////////////
  getBookCovers(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getBookCovers(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getBookCoversList.next(res);
      });
  }
  getGsiIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getGsiIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getGsiIconsList.next(res);
      });
  }

  getEntityIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getEntityIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getEntityIconsList.next(res);
      });
  }
  getCuIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getCuIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getCuIconsList.next(res);
      });
  }

  getAllChapters() {
    return this.layoutEndPoint.getAllChapters();
  }

  storeBookDetails(bookDetails: any) {
    this.layoutEndPoint.storeBookData(bookDetails);
  }

  saveDesign(bookDetails: any) {
    this.loader.show();
    this.layoutEndPoint
      .saveDesign(bookDetails)
      .pipe(
        catchError((error) => {
          this.bookDetails.next('false'); // when deign api fails return false
          this.alertService.showToaster(error.error.message, '', 'error');
          return throwError(error);
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster('Design Got Saved Successfully', '', 'success');
        this.loader.hide();
        this.savedTemplate.next(res.templateId);
      });
  }

  updatedTemplateId(templateId: any) {
    this.savedTemplate.next(templateId);
  }

  fetchBookDetails(bookId: any) {
    this.layoutEndPoint
      .fetchBookData(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutBookDesign.next(res);
      });
  }

  getListOfBooks(searchText: string) {
    this.layoutBookSearch.next(searchText);
  }

  clearBookSearchText(searchText: string) {
    this.bookSearchText.next(searchText);
  }

  closeFilterToggle(closefiltertoggle: boolean) {
    this.closeFilter.next(closefiltertoggle);
  }

  storeChangeUnitDetails(bookDetails: any) {
    this.layoutEndPoint.storeChangeUnitData(bookDetails);
  }

  fetchChangeUnitDetails(bookId: any) {
    this.layoutEndPoint
      .fetchBookData(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutChangeUnitDesign.next(res);
      });
  }

  fetchUiControls(cuId: any) {
    this.layoutEndPoint
      .fetchBookData(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.changeUnitUIControls.next(res);
      });
  }

  fetchLayoutDesign(bookId: any) {
    this.layoutEndPoint
      .getAllLayoutDesigns(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.templateDesign.next(res);
      });
  }

  updateUserData(userId: any, body: any) {
    this.layoutEndPoint
      .updateUserData(userId, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.updateUser.next(res);
      });
  }

  weekWiseListUpdate(x: boolean) {
    this.weekWiseList.next(x);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getLibraryTemplateData() {
    /* istanbul ignore next */
    this.layoutEndPoint
      .getLibraryTemplateData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.getDivsData.next(res);
        },
        (err: any) => {
          this.isTemplateAPIDataDone = true;
          this.updateTemplateAndUserAPIDone(true);
        }
      );
  }

  getMetaDataByUser() {
    /* istanbul ignore next */
    this.layoutEndPoint
      .getMetaDataByUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          this.getLayoutConfig.next(res);
        },
        (err: any) => {
          this.getLayoutConfig.next('');
          this.isUserAPIDataDone = true;
          this.updateTemplateAndUserAPIDone(true);
        }
      );
  }

  updateTemplateAndUserAPIDone(value: boolean) {
    this.templateAndUserAPIDone.next(true);
  }

  getAllSiteMetadata(pageNumber: number, pageSize: number, roleName: string) {
    /* istanbul ignore next */
    this.layoutEndPoint
      .getAllSiteMetadata(pageNumber, pageSize, roleName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.allSiteMetadata.next(res);
      });
  }

  getMetaDataByRoleIdTheme(roleId: any) {
    this.layoutEndPoint
      .getMetaDataByRoleIdTheme(roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.getRoleData.next(res);
        } else {
          this.getRoleData.next({});
        }
      });
  }

  getSelectedTheme(roleId: any) {
    this.layoutEndPoint
      .getMetaDataByRoleIdTheme(roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.getSelectedThemeData.next(res);
      });
  }

  postMetaDataByRoleIdTheme(themeData: any) {
    this.layoutEndPoint
      .postMetaDataByRoleIdTheme(themeData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.postRoleData.next(res);
      });
  }

  getLibraryLayoutById(layoutId: string) {
    this.layoutEndPoint
      .getLibraryTemplateDataById(layoutId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.getSiteLayoutData.next(res);
      });
  }

  getBookDataWithGsis(bookId: any) {
    this.layoutEndPoint
      .getGSIsByBookId(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        res.bookId = bookId;
        this.LayoutGsisList.next(res);
      });
  }

  getMetaDataByRoleId(roleId: any) {
    /* istanbul ignore next */
    this.layoutEndPoint
      .getMetaDataByRoleId(roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result) {
          this.getRoleSiteMetaData.next(res.result);
        } else {
          this.getRoleSiteMetaData.next({});
        }
      });
  }

  postMetaDataByRoleId(siteMetaData: any) {
    /* istanbul ignore next */
    this.layoutEndPoint
      .postMetaDataByRoleId(siteMetaData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.postRoleSiteMetaData.next(res);
      });
  }

  getAllBooksForSearch(
    pageNo: number,
    pageSize: number,
    searchText: string,
    userRights?: string,
    approvalStatus?: string,
    selectedWeek?: number
  ) {
    this.layoutEndPoint
      .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, undefined)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore else*/
        if (res) {
          this.searchedBookList.next(res.result);
          // this.loader.hide();
        }
      });
  }
}
