import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, ElementRef, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, LoaderService, RolesFacadeService, EntityEndpointService, EntityBoardEndpointService, EntityBoardFacadeService, TranslatorService} from '@common-services';
// import { TableDropComponent } from 'projects/entity-cards/src/public-api';
import { TableDropComponent } from 'entity-cards';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';

@Component({
  selector: 'app-vertical-table-drop',
  templateUrl: './vertical-table-drop.component.html',
  styleUrls: ['./vertical-table-drop.component.scss'],
})
export class VerticalTableDropComponent extends TableDropComponent  {
  public entityFacade: NodeEntityFacadeService ;
      constructor(
        router: Router,
        public route: ActivatedRoute,
        rolesFacadeService: RolesFacadeService,
        alertService: AlertService,
        entityBoardService: EntityBoardEndpointService,
        public dialog: MatDialog,
        // livechatFacade: LivechatFacadeService,
        loader: LoaderService,
        el: ElementRef,
        entityEndpointService: EntityEndpointService,
        entityBoardFacade: EntityBoardFacadeService,
        translator: TranslatorService,
        injector: Injector,
        resolver: ComponentFactoryResolver,

      ) {
        super(
          router,
          rolesFacadeService,
          alertService,
          entityBoardService,
          dialog,
          loader,
          el,
          entityEndpointService,
          entityBoardFacade,
          translator,
          resolver,

        );
        if(!this.entityFacade){
          this.entityFacade = injector.get( NodeEntityFacadeService);
        }
      }

       ngOnInit(): void {
       super.ngOnInit();
  }
  saveVerticalTableData(type: any) {
    this.verticalTableConfig = this.getStyleConfig();
    this.getAdditionalVerticalStyleConfig(this.verticalTableConfig);
    let entityConfiguration = this.entityData?.data?.entityData?.configuration
      ? JSON.parse(this.entityData.data.entityData.configuration)
      : {};
    entityConfiguration['configurationData'] = this.verticalTableConfig;
    entityConfiguration['type'] = type;
    this.entityFacade.verticalTableEntitySaveDetailsMethod(false, entityConfiguration);
    this.entityFacade.verticalTableUIConfiguration(false);
  }
}
