import { Injectable } from '@angular/core';

export enum MobileOS {
  Android = 'android',
  iOS = 'ios',
  Unknown = 'unknown',
  WindowsPhone = 'Windows Phone',
}
export enum DesktopOS {
  Linux = 'linux',
  MacOS = 'mac_os',
  Unix = 'unix',
  Unknown = 'unknown',
  Windows = 'windows',
}
@Injectable({
  providedIn: 'root',
})
export class DeviceState {
  constructor() {}

  userAgent = navigator.userAgent || navigator.vendor || (window as any).opera || undefined;

  isMobileDevice() {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regexs.some((b) => this.userAgent.match(b));
  }

  isTabletDevice() {
    const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return regex.test(this.userAgent.toLowerCase());
  }

  isDesktopDevice() {
    return !this.isMobileDevice() && !this.isTabletDevice();
  }

  getMobileOS() {
    if (/windows phone/i.test(this.userAgent)) {
      return MobileOS.WindowsPhone;
    } else if (/android/i.test(this.userAgent)) {
      return MobileOS.Android;
    } else if (/iPad|iPhone|iPod/.test(this.userAgent) && !(window as any).MSStream) {
      return MobileOS.iOS;
    } else {
      return MobileOS.Unknown;
    }
  }

  getDesktopOS() {
    if (this.userAgent.indexOf('Win') !== -1) {
      return DesktopOS.Windows;
    } else if (this.userAgent.indexOf('Mac') !== -1) {
      return DesktopOS.MacOS;
    } else if (this.userAgent.indexOf('X11') !== -1) {
      return DesktopOS.Unix;
    } else if (this.userAgent.indexOf('Linux') !== -1) {
      return DesktopOS.Linux;
    } else {
      return DesktopOS.Unknown;
    }
  }

  getDeviceType() {
    if (this.isMobileDevice()) {
      return 'Mobile';
    } else if (this.isTabletDevice()) {
      return 'Tablet';
    } else if (this.isDesktopDevice()) {
      return 'Desktop';
    } else {
      return 'Unknown';
    }
  }

  getDeviceOs() {
    if (this.isMobileDevice() || this.isTabletDevice()) {
      return this.getMobileOS();
    } else if (this.isDesktopDevice()) {
      return this.getDesktopOS();
    } else {
      return 'Unknown';
    }
  }
}
