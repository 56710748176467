<div class="relatedgsi-wrap">
  <div class="relatedgsi-title">
    <h4 class="relatedgsi-h4">{{ relatedGsiData.name }}</h4>
    <div class="relatedgsi-close" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="relatedgsi-expansion" *ngFor="let gsi of relatedGsiData.relatedGSIs">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="expansion-header">
              <div class="relatedgsi-name">
                <a class="d-flex">
                  wish
                  <mat-icon>expand_more</mat-icon>
                </a>
                <h3 class="relatedgsi-h3">{{ gsi.gsiName }}</h3>
              </div>
              <div class="relatedgsi-count">
                <div class="span-count">
                  <span
                    ><img [src]="'/assets/img/gsirelated/output.PNG' | CDNUrlRewritePipe" alt=""
                  /></span>
                  <span>{{ gsi.totalInputConnectors }} Input,{{ gsi.totalOutputConnectors }} Output</span>
                </div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-expansion-panel *ngFor="let CU of gsi.relatedCUs">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="expansion-header">
                <div class="relatedgsi-name">
                  <a class="d-flex">
                    CU
                    <mat-icon>expand_more</mat-icon>
                  </a>
                  <h3 class="relatedgsi-h3">{{ CU.cuName }}</h3>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="relatedgsi-sublist" *ngIf="CU?.inBoundDCDs?.length > 0">
            <div class="source">
              <button class="btn btn-source">source</button>
            </div>
            <div class="sublist-info">
              <div class="relatedgsi-sublistitems" *ngFor="let source of CU.inBoundDCDs">
                <ul>
                  <li>{{ source.sourceContextualName ? source.sourceContextualName : (source.formula | dcd) }}</li>
                </ul>
                <div class="gsi-gap">
                  <figure>
                    <img [src]="'/assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" alt="" />
                  </figure>
                </div>
                <ul>
                  <li>{{ source.targetContextualName | dcd }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="relatedgsi-sublist" *ngIf="CU?.outBoundDCDs?.length > 0">
            <div class="source">
              <button class="btn btn-source">Target</button>
            </div>
            <div class="sublist-info">
              <div class="relatedgsi-sublistitems" *ngFor="let target of CU.outBoundDCDs">
                <ul>
                  <li>{{ target.sourceContextualName ? target.sourceContextualName : (target.formula | dcd) }}</li>
                </ul>
                <div class="gsi-gap">
                  <figure>
                    <img [src]="'/assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" alt="" />
                  </figure>
                </div>
                <ul>
                  <li>{{ target.targetContextualName | dcd }}</li>
                </ul>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
