<!-- <div class="preview-wrap">
  <div class="preview-head">
    <div class="head-ttle">
      <h4>OCR FILE</h4>
    </div>
    <div class="head-btn">
      <button mat-stroked-button>Scan</button>
    </div>
  </div>
  <div class="main-preview">
    <div class="preview-area">
      Preview
    </div>
  </div>
</div> -->
<ng-container *ngIf="image && originalDocumentSize && coordinates">
  <app-image-map
    [src]="image"
    [originalDocumentSize]="originalDocumentSize"
    [coordinates]="coordinates"
    (onClick)="selectedField($event)"
    [fileType]="fileType"
    canEdit="true"
  ></app-image-map>
</ng-container>
