import { Component, Injector } from '@angular/core';
import { ConfigService } from './app-config.service';
import { AppConfigModel } from './app-config.service.model';

@Component({
  selector: 'app-root',
  template: '',
})
export class CduiBaseComponent {
  configService: ConfigService;
  constructor(baseInjector: Injector) {
    this.configService = baseInjector.get(ConfigService);
  }
  get api() {
    if (!this.environment.dsdOrchUrl) {
      this.environment.serverIp = '';
      this.environment.dsdOrchUrl = '/dsd-orch';
      this.environment.cdmServerAPI = '/cdm';
    }
    return this.configService.getUrls(this.environment);
  }
  get environment(): AppConfigModel {
    return this.configService.readConfig();
  }
}
