import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  helperData: Object;
}

@Component({
  selector: 'app-helper-text',
  templateUrl: './helper-text.component.html',
  styleUrls: ['./helper-text.component.scss'],
})
export class HelperTextComponent implements OnInit {
  helperTextString: string = '';
  helperTextPostion: string = '';
  helperTextColor: string = '';
  helperTextFont: string = '';
  helperTextIconColor: string = '';
  showHelperTextIcon: boolean;
  helperData: any = {};

  constructor(
    public Dialog: MatDialog,
    public dialogRef: MatDialogRef<HelperTextComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData
  ) {}

  ngOnInit(): void {
    this.helperData = this.dialog.helperData;
    this.helperTextString = this.helperData?.helperText;
    this.helperTextPostion = this.helperData?.helperTextPostion;
    this.helperTextColor = this.helperData?.helperTextColor;
    this.helperTextFont = this.helperData?.helperTextFont;
    this.showHelperTextIcon = this.helperData?.showHelperTextIcon;
    this.helperTextIconColor = this.helperData?.helperTextIconColor;
  }

  /*istanbul ignore next */
  closeDialog(): void {
    this.dialogRef.close();
  }

  selectedColor(type: string) {
    this.helperTextColor = type;
  }

  selectedPosition(type: string) {
    if (this.showHelperTextIcon) {
      this.helperTextPostion = '';
    }
    this.helperTextPostion = type;
  }

  selectedFont(type: string) {
    this.helperTextFont = type;
  }

  iconSelectedColor(type: string) {
    this.helperTextIconColor = type;
  }

  /*istanbul ignore next */
  saveHelperText() {
    this.dialogRef.close({
      helperData: {
        helperText: this.helperTextString,
        helperTextPostion: this.helperTextPostion,
        helperTextFont: this.helperTextFont,
        helperTextColor: this.helperTextColor,
        helperTextIconColor: this.helperTextIconColor,
        showHelperTextIcon: this.showHelperTextIcon,
      },
    });
  }
}
