import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DateRangeComponent } from './date-range.component';

@NgModule({
  declarations: [DateRangeComponent],
  imports: [
    ControlsMaterialModule,
  ],

  exports: [DateRangeComponent],
})
export class DateRangeModule {
  static entry = {
    daterange: DateRangeComponent,
  };
}
