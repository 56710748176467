<!--**********************************************************************************insurance********************************************************-->
<ng-template #templateHeader>
  <div class="entity-card-header">
    <div
      class="btn-corner"
      *ngIf="listValues.length > 1 && !settings?.customizeCardconfigData?.onSelectGsiConfig?.onSelectGsi"
      (click)="deleteCard($event)"
    >
      <!-- <div class="delete-images"><i class="bi bi-trash3"></i></div> -->
    </div>

    <div
      class="btn-corner"
      *ngIf="listValues.length > 1"
      (click)="onSelectDelete(settings?.customizeCardconfigData?.onSelectGsiConfig?.selectedOnSelectGsi)"
    >
      <div class="delete-images" *ngIf="settings?.customizeCardconfigData?.onSelectGsiConfig?.onSelectGsi">
        <i class="bi bi-trash3"></i>
      </div>
    </div>

    <div class="btn-corner" [ngClass]="dCard.isChecked ? 'showbtncheck' : ''">
      <div class="uikit-radio" *ngIf="settings?.selectionType == 'radiobutton'">
        <label class="radio">
          <input
            type="radio"
            [(checked)]="dCard.isChecked"
            [(value)]="dCard.isChecked"
            (change)="selectedCards($event, 'radiobutton')"
          />
          <div class="box"></div>
        </label>
      </div>
      <div class="uikit-radio option3" *ngIf="settings?.selectionType == 'checkbox'">
        <label class="radio">
          <input
            [(ngModel)]="dCard.isChecked"
            type="checkbox"
            (change)="selectedCards($event, settings?.selectionType)"
          />
          <div class="box" (click)="selectedCards($event, settings?.selectionType)"></div>
        </label>
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="!showExpand">
  <div class="insurance-gecards insurance-gecards-bg insurance-child-wireframe" (click)="openExpand($event)">
    <div class="entitytemplates">
      <div *ngIf="templateType == 34">
        <div
          class="insurance-child-card-1 space-round1 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 34"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-logo">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 35">
        <div
          class="insurance-child-card-2 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 35"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[0]"
                dynamicField
                [formData]="attributes[0][attributes[0].attributeName]"
                [group]="attributes[0][attributes[0].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-img">
              <img [src]="contentUrl[3] | CDNUrlRewritePipe" />
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 36">
        <div
          class="insurance-child-card-3 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 36"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <!-- <li class="card-caption">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
            </li> -->
            <li class="card-icon">
              <img [src]="contentUrl[3] | CDNUrlRewritePipe" />
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="templateType == 37">
        <div class="insurance-child-card-4" *ngIf="templateType == 37">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="templateType == 38">
        <div
          class="insurance-child-card-5 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 38"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 39">
        <div
          class="insurance-child-card-6 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 39"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 40">
        <div
          class="insurance-child-card-7 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 40"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>

            <li class="card-subtitle-gray">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <hr />
            <li class="card-subtitle-list">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <hr />
            <li class="card-subtitle-list">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 41">
        <div
          class="insurance-child-card-8 space-round1 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 41"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>

            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <hr />
            <li class="card-subtitle-gray">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <hr />
            <li class="card-subtitle-gray">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 42">
        <div
          class="insurance-child-card-9 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 42"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <!-- <li class="card-caption">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
            </li> -->
            <li class="card-icon">
              <img [src]="contentUrl[3] | CDNUrlRewritePipe" />
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="templateType == 43">
        <div
          class="insurance-child-card-10 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 43"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="templateType == 44">
        <div
          class="insurance-child-card-11 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 44"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul class="section1">
              <li class="card-img">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-caption">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 45">
        <div
          class="insurance-child-card-12 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 45"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul class="section1">
              <li class="card-img">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 46">
        <div
          class="insurance-child-card-13 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 46"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 47">
        <div
          class="insurance-child-card-14 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 47"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-clr card-subtitle-clr1">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-clr card-subtitle-clr2">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 48">
        <div
          class="insurance-child-card-15 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 48"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[0]"
                dynamicField
                [formData]="attributes[0][attributes[0].attributeName]"
                [group]="attributes[0][attributes[0].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-img">
              <img [src]="contentUrl[3] | CDNUrlRewritePipe" />
            </li>
            <li class="card-subtitle-clr">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="templateType == 49">
        <div
          class="insurance-child-card-16 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 49"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 50">
        <div
          class="insurance-child-card-17 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 50"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>

            <li class="card-subtitle-gray">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <hr />
            <li class="card-subtitle-list">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 51">
        <div
          class="insurance-child-card-18 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 51"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul class="section1">
              <li class="card-img">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 52">
        <div
          class="insurance-child-card-19 space-round2 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 52"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul class="section1">
              <li class="card-img">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-subtitle-gray">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <hr />
              <li class="card-subtitle-list">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <hr />
              <li class="card-subtitle-list">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
              Show more
            </div>
          </div>
        </div>
        <div (click)="openExpand($event)" *ngIf="templateType == 41">
          <div
            class="insurance-child-card-8 space-round1 insurance-child"
            [ngClass]="{ 'card-checked': dCard.isChecked }"
            *ngIf="templateType == 41"
          >
            <ul>
              <li class="card-img">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
              </li>

              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <hr />
              <li class="card-subtitle-gray">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <hr />
              <li class="card-subtitle-gray">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div *ngIf="templateType == 53">
        <div
          class="insurance-child-card-20 space-round3 insurance-child"
          [ngClass]="{ 'card-checked': dCard.isChecked }"
          *ngIf="templateType == 53"
        >
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul>
            <li class="card-img">
              <img [src]="contentUrl[1] | CDNUrlRewritePipe" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-clr card-subtitle-clr1">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-clr card-subtitle-clr2">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-dynamic-ui-card-insurance-expand-templates
  *ngIf="showExpand && currentIndex == expandIndex"
  [attributes]="attributes_expand"
  (closeExpandEmitter)="closeExpand($event)"
  [expandType]="expandType"
  [eventCUList]="eventCUList"
></app-dynamic-ui-card-insurance-expand-templates>
