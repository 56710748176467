import { NgModule } from '@angular/core';
import { CaptureImageComponent } from './capture-image.component';
import { ClickOutside } from './capture-image-clickOutside.directive';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [CaptureImageComponent, ClickOutside],
  imports: [
    ControlsMaterialModule
  ],
  exports: [CaptureImageComponent],
})
export class CaptureImageModule {
  static entry = {
    capturingimage: CaptureImageComponent,
  };
}
