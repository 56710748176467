import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { MultiselectdropdownComponent } from './multiselectdropdown.component';

@NgModule({
  declarations: [MultiselectdropdownComponent],
  imports: [ControlsMaterialModule],
  exports: [MultiselectdropdownComponent],
})
export class MultiselectedDropDownModule {
  static entry = {
    multiselectdropdown: MultiselectdropdownComponent,
  };
}
