import { NgModule } from '@angular/core';
import { BooleanComponent } from './boolean.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [BooleanComponent],
  imports: [ControlsMaterialModule],
  exports: [BooleanComponent],
})
export class BooleanModule {
  static entry = {
    boolean: BooleanComponent,
  };
}
