import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AugumentedRealityEndpointService extends CduiBaseService {
  arEntityData = new BehaviorSubject({});
  arEntityData$ = this.arEntityData.asObservable();
  ngUnsubscribe = new Subject();

  openArWindow = new BehaviorSubject({});
  openArWindow$ = this.openArWindow.asObservable();

  closeArWindow = new BehaviorSubject({});
  closeArWindow$ = this.closeArWindow.asObservable();

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  fetchArEntities(data: any) {
    this.http
      .post(this.api?.detectArEntity, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next */
        if (res) {
          this.arEntityData.next(res);
        }
      });
  }

  setArEntityData(data: any) {
    this.arEntityData.next(data);
  }
  setArData(data: any) {
    this.openArWindow.next(data);
  }
  closeArPanel(data: any) {
    this.closeArWindow.next(data);
  }
}
