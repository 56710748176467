<div class="zoominout">
  <div mat-dialog-title class="toolbar">
    <span *ngIf="canDownload" class="nh-icon nh-icon-download01" (click)="downloadFile()"></span>
    <span class="nh-icon nh-icon-rotate_left" (click)="onRotate('left')"></span>
    <span class="nh-icon nh-icon-rotate_right" (click)="onRotate('right')"></span>
    <button
      [disabled]="buttonClickCounts == maxClicks"
      (click)="zoomInOut('in')"
      [ngClass]="{ disableZoom: buttonClickCounts == maxClicks }"
    >
      <span class="nh-icon nh-icon-zoom-in"></span>
    </button>
    <button
      [disabled]="buttonClickCounts == -1 * maxClicks"
      (click)="zoomInOut('out')"
      [ngClass]="{ disableZoom: buttonClickCounts == -1 * maxClicks || zoomImagePercentage < 10 }"
    >
      <span class="nh-icon nh-icon-zoom-out"></span>
    </button>
    <span class="nh-icon nh-icon-x-lg cancel" (click)="closeImageZoomScreen()"></span>
  </div>
  <div class="img-view" [ngStyle]="{ rotate: rotate + 'deg' }" mat-dialog-content>
    <img
      class="zoomClass"
      [ngStyle]="{ width: zoomImagePercentage + '%', 'max-width': zoomMaxWidth + '%' }"
      id="imgZoom"
      (contextmenu)="onRightClick($event)"
      [src]="src.contentUrl"
    />
  </div>
</div>
