<div class="top-tabs" (clickOutside)="closePanel()">
  <div class="tab-one">
    <ul class="tab-list row no-gutters align-items-center justify-content-between" *ngIf="!isParagraph">
      <li class="col-auto">
        <button class="tab-btn" [ngClass]="{ active: tabID === 'SG' }" (click)="changeTab('SG')" id="NSL_Library">
          {{ labels?.NSL_Library }}
        </button>
      </li>
      <li class="col-auto">
        <button class="tab-btn" [ngClass]="{ active: tabID === 'MC' }" (click)="changeTab('MC')" id="My_Collection">
          {{ labels?.My_Collection }}
        </button>
      </li>
      <li class="col-auto">
        <button class="tab-btn" [ngClass]="{ active: tabID === 'KT' }" (click)="changeTab('KT')" id="NSL_Kit">
          {{ labels?.NSL_Kit }}
        </button>
      </li>
      <li class="col-auto" (click)="toggleTabModal()" [ngClass]="{ tabActive: tabbingModal }">
        <button class="tab-toggle-btn" id="show_Tabs">{{ labels?.Show_Tabs }}</button>
      </li>
    </ul>
    <div class="tab-container" *ngIf="tabbingModal">
      <div
        class="tab-content def-tab-content-style"
        id="suggestion"
        [ngClass]="{
          'tab-active': tabID === 'SG'
        }"
      >
        <div class="close-area pt-4 mb-0 pl-3" *ngIf="isParagraph">
          <div class="nh-canvas-right-panel">
            <h5>{{ labels?.NSL_Library }}</h5>
            <button class="material-icons" (click)="closePanel()">
              {{ labels?.close }}
            </button>
          </div>
        </div>
        <section class="tab-header">
          <div class="row no-gutters align-items-center">
            <div class="col node-search-wrap ">
              <div class="node-search-right">
                <div class="node-search-block right-item">
                  <button
                    (click)="displayNodesearch = !displayNodesearch"
                    class="search-btn-icon search-btn-large"
                  ></button>
                </div>
                <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
                  <mat-form-field appearance="legacy">
                    <button
                      mat-icon-button
                      matSuffix
                      (click)="displayNodesearch = !displayNodesearch; dsdSuggestion = ''"
                    >
                      <mat-icon matSuffix>close</mat-icon>
                    </button>
                    <input matInput [(ngModel)]="dsdSuggestion" (keyup)="searchDsdDetails()" />
                  </mat-form-field>
                </div>
                <!-- <div class="node-compare right-item">
                  <button class="compare-btn-icon">
                    <span>Compare</span>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </section>
        <section class="tab-body">
          <!-- DLD Suggestions Component -->
          <app-node-canvas-suggestion></app-node-canvas-suggestion>
        </section>
      </div>
      <div
        class="tab-content"
        id="collection"
        [ngClass]="{
          'tab-active': tabID === 'MC'
        }"
      >
        <div class="close-area pt-4 mb-0 pl-3" *ngIf="isParagraph">
          <div class="nh-canvas-right-panel">
            <h5>My Collection</h5>
            <button class="material-icons" (click)="closePanel()">
              {{ labels?.close }}
            </button>
          </div>
        </div>
        <mat-tab-group class="tab-two" [(selectedIndex)]="selectedTabIndex" #myCollection>
          <mat-tab label="{{ labels?.Book }}">
            <p class="bet-count">Total: {{ bookCount }}</p>
            <app-node-system-book (betCountEmitter)="bookCount= countFormatter($event)"></app-node-system-book>
          </mat-tab>
          <mat-tab label="{{ labels?.Agent }}">
            <p class="bet-count">Total: {{ agentCount }}</p>
            <app-node-system-agent (selectAgent)="openAgentLibrary($event)" (betCountEmitter)="agentCount= countFormatter($event)"></app-node-system-agent>
          </mat-tab>
          <mat-tab label="{{ labels?.Solution }}">
            <p class="bet-count">Total: {{ solutionCount }}</p>
            <app-node-system-solutions [libraryType]="libraryType" (betCountEmitter)="solutionCount= countFormatter($event)"></app-node-system-solutions>
          </mat-tab>
          <mat-tab label="{{ labels?.Intent }}">
            <p class="bet-count">Total: {{ ChangeUnitCount}}</p>
            <app-node-system-changeunits [nestedNumber]="nestedNumber" (betCountEmitter)="ChangeUnitCount= countFormatter($event)"></app-node-system-changeunits>
          </mat-tab>
          <mat-tab label="{{ labels?.Object }}">
            <p class="bet-count">Total: {{ entityCount }}</p>
            <app-node-system-entities [nodeSection]="nodeSection" (betCountEmitter)="entityCount= countFormatter($event)"></app-node-system-entities>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div
        class="tab-content"
        id="nsl-kit"
        [ngClass]="{
          'tab-active': tabID === 'KT'
        }"
      >
        <div class="close-area pt-4 mb-0 pl-3" *ngIf="isParagraph">
          <div class="nh-canvas-right-panel">
            <h5>NSL KIT</h5> 
            <button class="material-icons" (click)="closePanel()">
              {{ labels?.close }}
            </button>
          </div>
        </div>
        <mat-tab-group class="tab-two">
          <mat-tab label="{{ labels?.Change_Unit }}">
            <section class="tab-header">
              <div class="row no-gutters align-items-center">
                <div class="col node-search-wrap nslkitsearch 1">
                  <div class="node-search-right">
                    <!-- <div class="node-create right-item">
                      <button class="create-new-btn"></button>
                    </div> -->

                    <!-- <div class="slide-toggle right-item">
                      <mat-slide-toggle></mat-slide-toggle>
                    </div> -->

                    <!-- <div class="node-compare right-item">
                      <button class="compare-btn-icon">
                        <span>{{ labels?.Compare }}</span>
                      </button>
                    </div> -->
                    <div class="node-search-block right-item">
                      <button
                        (click)="displayNodesearch = !displayNodesearch"
                        class="search-btn-icon search-btn-large"></button>
                    </div>
                  </div>
                  <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
                    <mat-form-field appearance="legacy">
                      <button
                        mat-icon-button
                        matSuffix
                        (click)="displayNodesearch = !displayNodesearch; searchText = ''"
                      >
                        <mat-icon matSuffix>close</mat-icon>
                      </button>
                      <input matInput [(ngModel)]="searchText" (keyup)="searchInput.next()" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </section>

            <!-- <section class="tab-body">
              <ul class="common-list">
                <li *ngFor="let cu of reservedCuList">
                  <div class="d-flex item align-items-center">
                    <div class="details">
                      <p
                        class="title"
                        pTooltip="{{ cu.name }}"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                      >
                        {{ cu.name }}
                      </p>
                    </div>
                    <a class="add-icon" (click)="addChangeunit(cu)"><span></span></a>
                    <a class="copy-icon"><span></span></a>
                    <a class="more-icon"><span></span></a>
                  </div>
                </li>
              </ul>
            </section> -->
            
            
            <section class="tab-body nslkit-tab-body">
              <ul class="common-list">
                <li *ngFor="let cu of reservedCuGrammarList; let k = index">
                  <mat-accordian>
                    <mat-expansion-panel
                      hideToggle
                      class="mat-elevation-z0"
                      [ngClass]="{ toggleDesdciption: toolTipIndex == k }"
                    >
                      <mat-expansion-panel-header>
                        <div class="d-flex item align-items-center">
                          <div class="details">
                            <p class="type" pTooltip="{{ cu.nsl_display_name }}" tooltipPosition="bottom">
                              {{ cu.nsl_display_name }}
                            </p>
                            <p class="display-cuname" pTooltip="{{ cu.reserved_cu_name }}" tooltipPosition="bottom">
                              {{ cu.reserved_cu_name }}
                            </p>
                          </div>
                          <div class="rcud-list-symbol">
                            <span (click)="toolTipIndex = k">d</span>
                            <div [ngStyle]="{ display: toolTipIndex == k ? 'block' : 'none' }" class="rcud-desc">
                              <mat-icon (click)="closeDescription()">clear</mat-icon>
                              <p>{{ cu['Function description'] }}</p>
                            </div>
                          </div>
                          <a
                            class="add-icon"
                            (click)="addReservedCUFromLibrabry(cu.nsl_method_name, cu.nsl_display_name)"
                            ><span></span
                          ></a>
                          <a
                            class="more-icon"
                            (click)="addReservedCUFromLibrabry(cu.nsl_method_name, cu.nsl_display_name, true)"
                            ><span></span
                          ></a>
                        </div>
                      </mat-expansion-panel-header>
                      <!-- <mat-expansion-panel-header>
                          <div class="d-flex item level-three align-items-center">
                            <div class="details">
                              <p class="title">{{ cu.displayName }}</p>
                            </div>
                            <a class="more-icon" id="moreCuDetails_{{ cu?.displayName?.replaceAll(' ', '_') }}"
                              ><span></span
                            ></a>
                          </div>
                        </mat-expansion-panel-header> -->
                      <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of cu.layers">
                        <mat-expansion-panel-header>
                          <div class="d-flex item level-three align-items-center">
                            <div class="details">
                              <!-- <p class="sub"></p> -->
                              <p class="title">{{ layer.type }}</p>
                            </div>
                            <a class="more-icon" id="moreLayersDetails_{{ layer?.type }}"><span></span></a>
                          </div>
                        </mat-expansion-panel-header>
                        <mat-expansion-panel
                          hideToggle
                          class="mat-elevation-z0"
                          *ngFor="let ent of layer.participatingItems"
                        >
                          <mat-expansion-panel-header>
                            <div class="d-flex item level-four align-items-center">
                              <div class="details">
                                <!-- <p class="sub"></p> -->
                                <p class="title">{{ ent.item.DATA.name }}</p>
                              </div>
                              <a
                                class="more-icon"
                                id="moreEntityDetails_{{ ent?.item?.DATA?.name?.replaceAll(' ', '_') }}"
                                ><span></span
                              ></a>
                            </div>
                          </mat-expansion-panel-header>
                          <div class="mat-elevation-z0" *ngFor="let attr of ent.item?.DATA?.nslAttributes">
                            <div class="d-flex item level-five align-items-center">
                              <div class="d-flex" *ngIf="!attr?.generalEntity">
                                {{ attr.name }}
                              </div>
                              <div class="d-flex" *ngIf="attr.generalEntity">
                                <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
                              </div>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-expansion-panel>

                      <!-- <div *ngIf="cu?.entityList && cu?.entityList?.length > 0" class="entity-list">
                        <span *ngFor="let ent of cu?.entityList">
                          <p>
                            {{ ent.entity_name }}
                          </p>
                        </span>
                      </div> -->
                    </mat-expansion-panel>
                  </mat-accordian>
                </li>
              </ul>
            </section>

            <div class="tab-footer">
              <app-paginator
                [pageIndex]="pageIndex"
                [pageSize]="pageSize"
                [totalRecords]="cuTotalRecords"
                (onPageChange)="onPageChange($event, 'cu')"
              >
              </app-paginator>
            </div>

          </mat-tab>


          <mat-tab label="{{ labels?.Entity }}">
            <section class="tab-body tab-body-entity">
              <section class="tab-header ">
                <div class="row no-gutters align-items-center">
                  <div class="col node-search-wrap nslkitsearch">
                    <div class="node-search-right">
                      <!-- <div class="node-create right-item">
                        <button class="create-new-btn"></button>
                      </div> -->

                      <!-- <div class="slide-toggle right-item">
                        <mat-slide-toggle></mat-slide-toggle>
                      </div> -->

                      <!-- <div class="node-compare right-item">
                        <button class="compare-btn-icon">
                          <span>{{ labels?.Compare }}</span>
                        </button>
                      </div> -->
                      <div class="node-search-block right-item">
                        <button
                          (click)="displayNodesearch = !displayNodesearch"
                          class="search-btn-icon search-btn-large"
                        ></button>
                      </div>
                    </div>
                    <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
                      <mat-form-field appearance="legacy">
                        <button
                          mat-icon-button
                          matSuffix
                          (click)="displayNodesearch = !displayNodesearch; srchText = ''; getReservedEntitiesList()"
                        >
                          <mat-icon matSuffix>close</mat-icon>
                        </button>
                        <input matInput [(ngModel)]="srchText" (keyup)="getReservedEntitiesList()" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row no-gutters align-items-center">
                  <div class="col node-search-wrap">
                    <ul class="common-list">
                      <li *ngFor="let entity of reservedEntityList">
                        <!-- <div class="d-flex item align-items-center">
                          <div class="details">
                            <p
                              class="title"
                              pTooltip="{{ entity.name }}"
                              tooltipPosition="top"
                              tooltipStyleClass="transcustomtolltipbook"
                            >
                              {{ entity.name }}
                            </p>
                          </div>
                          <a class="add-icon" (click)="addReservedEntity(entity)"><span></span></a>
                          <a class="copy-icon"><span></span></a>
                          <a class="more-icon" (click)="expandEntity(entity)"><span></span></a>
                        </div> -->
                        <mat-accordion>
                          <mat-expansion-panel hideToggle class="mat-elevation-z0">
                            <mat-expansion-panel-header (click)="expandEntity(entity)">
                              <div class="d-flex item align-items-center">
                                <div class="details">
                                  <p class="title" matTooltip="{{ entity.name }}">
                                    {{ entity.name }}
                                  </p>
                                </div>
                                <a
                                  *ngIf="checkedAttributeList?.length > 0"
                                  class="add-icon"
                                  (click)="addReservedAttribute(entity)"
                                  id="IfSelectedCheckbox"
                                  ><span></span
                                ></a>
                                <a
                                  *ngIf="checkedAttributeList?.length == 0"
                                  class="add-icon"
                                  (click)="addReservedEntity(entity); $event.stopPropagation()"
                                >
                                  <span></span
                                ></a>
                                <!-- <a class="copy-icon"><span></span></a> -->
                                <a class="more-icon" (click)="expandEntity(entity)"><span></span></a>
                              </div>
                            </mat-expansion-panel-header>
                            <div *ngIf="entity?.displayList">
                              <div class="mat-elevation-z0" *ngFor="let attr of entity?.displayList">
                                <div class="d-flex item align-items-center" *ngIf="!attr?.generalEntity">
                                  <p>{{ attr?.name }}</p>
                                  <mat-checkbox
                                    class="example-margin"
                                    [color]="'primary'"
                                    (change)="Checkboxchanged($event.checked, attr)"
                                  >
                                  </mat-checkbox>
                                  <!-- <a class="add-icon" (click)="addReservedAttribute(entity, ind)"><span></span></a> -->
                                  <!-- <a class="create-new-btn" (click)="addAttrToEntity(entity, attr)"></a> -->
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </section>
            <div class="tab-footer">
              <app-paginator
                [pageIndex]="pgIndex"
                [pageSize]="pgSize"
                [totalRecords]="totalRecords"
                (onPageChange)="onPageChange($event, 'entity')"
              >
              </app-paginator>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
