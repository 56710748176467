import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalSearchTypeaheadEndpointService } from './global-search-typeahead-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchTypeaheadFacadeService {
  ngUnsubscribe = new Subject();

  private autocompleteData = new Subject();
  autocompleteData$ = this.autocompleteData.asObservable();
  private searchData = new Subject();
  searchData$ = this.searchData.asObservable();
  private googleData = new Subject();
  googleData$ = this.googleData.asObservable();
  private googleImageData = new Subject();
  googleImageData$ = this.googleImageData.asObservable();
  private searchPaginatedData = new Subject();
  searchPaginatedData$ = this.searchPaginatedData.asObservable();

  constructor(private globalSearchEndpointService: GlobalSearchTypeaheadEndpointService) {}

  /*Gets Autocomplete global result */
  getAutoCompleteSearch(searchText: string, betType: string) {
    this.globalSearchEndpointService
      .getAutoCompleteSearch(searchText, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.autocompleteData.next(res);
      });
  }
  /*Gets paginated Global search result */
  getPaginatedGlobalSearch(searchText: string, betType: string, pageNo: number, pageSize: number) {
    this.globalSearchEndpointService
      .getPaginatedGlobalSearch(searchText, betType, pageNo, pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.searchPaginatedData.next(res);
      });
  }
  /*Gets Global search result */
  getGlobalSearch(searchText: string, betType: string) {
    this.globalSearchEndpointService
      .getGlobalSearch(searchText, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.searchData.next(res);
      });
  }
  /*Gets Google web search result */
  googleApi(searchText: string) {
    this.globalSearchEndpointService
      .googleApi(searchText)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.googleData.next(res);
      });
  }
  /*Gets google image search result */
  googleImageApi(searchText: string) {
    this.globalSearchEndpointService
      .googleImageApi(searchText)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.googleImageData.next(res);
      });
  }
}
