declare var require: any;
let transform = require('node-json-transform').transform;

export function dldGSIListMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      Book: 'Book',
      GSIList: 'GSIList',
    },
    operate: [
      {
        run: function (val: any[]) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return transform(x, nestedMap);
          });
        },
        on: 'GSIList',
      },
    ],
  };

  const nestedMap = {
    item: {
      dldId: 'dldId',
      displayName: 'displayName',
      name: 'name',
      confidence: 'confidence',
    },
  };

  const result = transform(data, map);

  return result;
}
