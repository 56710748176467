import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { AudioComponent } from './audio.component';

@NgModule({
  declarations: [AudioComponent],
  imports: [ControlsMaterialModule],
  exports: [AudioComponent],
})
export class AudioModule {
  static entry = {
    audio: AudioComponent,
  };
}
