import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService, EventsFacadeService, FieldConfig } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-audio',
  styleUrls: ['./audio.component.scss'],
  template: `
    <div class="demo-full-width">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field?.label }}<span class="main-label-colon">:</span></span
              ><span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              class="material-icons-outlined helperText-infoIcon"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        >
          <mat-icon
            class="d-flex align-items-center justify-content-center"
            matTooltip="Sub-Transactional CU"
            matSuffix
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        {{ field?.configuration?.helpertext }}
      </p>

      <ng-container *ngIf="audioUrls?.length > 0; else datanotfound">
        <ul>
          <li *ngFor="let audio of audioUrls; let i = index">
            <audio class="nh-wp-100" controls>
              <source [src]="audio" />
            </audio>
            <ng-container
              *ngFor="let validation of field?.validations"
              ngProjectAs="mat-error"
            >
              <mat-error
                *ngIf="
                  validation.type &&
                  (validation.type === 'ERROR' ||
                    validation.type === 'BLOCK_WARN') &&
                  !checkValidFile(validation, i)
                "
                >{{ validation.message }}</mat-error
              >
              <mat-error
                [ngClass]="
                  validation.type == 'INFO' ? 'infocolor' : 'warncolor'
                "
                *ngIf="
                  validation.type &&
                  validation.type !== 'ERROR' &&
                  validation.type !== 'BLOCK_WARN' &&
                  !checkValidFile(validation, i)
                "
                >{{ validation.message }}</mat-error
              >
            </ng-container>
          </li>
        </ul>
      </ng-container>
      <ng-template #datanotfound>
        <p class="notFound">{{ labels?.No_Audio_Found }}</p>
      </ng-template>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </div>
  `,
  styles: [],
})
export class AudioComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  labels: any;
  private ngUnsubscribe = new Subject();
  audioUrls: any[];
  files: any[];
  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.audioUrls = [];
    this.files = [];
    /*istanbul ignore next*/
    if (this.field?.value?.length > 0) {
      /*istanbul ignore next*/
      this.field?.value?.forEach((obj: any, ind: any) => {
        if (this.IsJsonString(obj)) {
          let jsonObj: any = JSON.parse(obj);
          /*istanbul ignore else*/
          if (jsonObj.contentUrl) {
            this.audioUrls[ind] = jsonObj.contentUrl;
            this.files[ind] = jsonObj;
          }
        } else {
          this.audioUrls[ind] = obj;
          this.files[ind] = obj;
        }
      });
    }
  }
  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  checkValidFile(obj: any, i: any) {
    switch (obj?.name) {
      case 'filenamelengthtype':
        /* istanbul ignore next */
        return this.files?.[i]?.name?.length <= obj?.maxLength;
      case 'required':
        /* istanbul ignore next */
        return this.files?.[i]?.size;
      case 'maxfilesizetype':
        let size = 1;
        /* istanbul ignore next */
        if (obj?.unit == 'KB') {
          size = 1024;
        } else if (obj?.unit == 'MB') {
          size = 1024 * 1024;
        }
        /* istanbul ignore next */
        return this.files?.[i]?.size <= obj?.value * size;
      case 'minfilesizetype':
        let minSize = 1;
        /* istanbul ignore next */
        if (obj?.unit == 'KB') {
          minSize = 1024;
        } else if (obj?.unit == 'MB') {
          minSize = 1024 * 1024;
        }
        /* istanbul ignore next */
        return this.files?.[i]?.size >= obj?.value * minSize;
      case 'assertextensiontype':
        /* istanbul ignore next */
        let fileName = this.files?.[i]?.name;
        /* istanbul ignore next */
        let ext = fileName?.substring(fileName.lastIndexOf('.') + 1);
        /* istanbul ignore next */
        return obj?.extension?.toLowerCase().includes(ext);
      case 'assertnotextensiontype':
        /* istanbul ignore next */
        let fileName1 = this.files?.[i]?.name;
        /* istanbul ignore next */
        let ext1 = fileName1?.substring(fileName1.lastIndexOf('.') + 1);
        /* istanbul ignore next */
        return !obj?.extension?.toLowerCase().includes(ext1);
      case 'filenamepatterntype':
        /* istanbul ignore next */
        return this.files?.[i]?.name?.match(obj?.requiredPattern);
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
