<div class="con-main-wrapper conditions-wrapper" id="conditions_root_div">
  <div class="con-header" id="conditions_header">
    <div class="con-header-left" id="conditions_header_left">
      <h2>
        {{ labels?.Reserve_Change_Unit_funtions }}
      </h2>
    </div>
    <div class="con-header-right" id="conditions_header_right">
      <button class="btn save" id="conditions_btn_save" (click)="saveRcuData()" >{{ labels?.Save }}</button>
      <button class="btn-close" id="conditions_btn_close" (click)="closeRcuComp()" >
        <i class="bi bi-x-square"></i>
      </button>
    </div>
  </div>
</div>
<div *ngIf="nodes?.length == 0">
  <button class="btn save reserve-btn" id="add_reserve_funtion_btn" (click)="openMenu();paramType='RCU'" >{{ labels?.Add_Reserve_Function }}</button>
</div>
<ngx-graph
  *ngIf="nodes.length > 0"
  class="chart-container graph-position"
  id="chart-container"
  [view]="[800, 500]"
  [layoutSettings]="layoutSettings"
  [links]="links"
  [nodes]="nodes"
  [curve]="curve"
  [layout]="layout"
  [enableZoom]="true"
  [autoZoom]="true"
  [update$]="update$"
  [zoomToFit$]="zoomToFit$"
  >
  <ng-template #nodeTemplate let-node>
    <svg:g class="node" xmlns="http://www.w3.org/2000/xhtml"
   
    >
      <svg:foreignObject class="node-label-outer visible">
        <xhtml:div
          class="node-tooltip"
          id="node-tooltip"
          pTooltip="{{ node?.data?.displayName }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          xmlns="http://www.w3.org/1999/xhtml"
        >
          {{ node?.data?.displayName }}
        </xhtml:div>
      </svg:foreignObject>
    </svg:g>
    <svg:rect
      id="node-circle"
      class="node-circle"
      [attr.width]="node.data.dimension.width"
      [attr.height]="node.data.dimension.height"
      [attr.stroke]="node.data.color"
      [attr.fill]="node.data.color"
      ry="50"
    />
    <svg:text
      class="node-text"
      [ngClass]="node.label == 'Const' ? 'constant-text-rect' : 'node-text'"
      id="node-text"
      pTooltip="{{ node.label }}"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      alignment-baseline="central"
      [attr.x]="7"
      [attr.y]="16"
    >
      {{ node.label }}
    </svg:text>
    <svg:foreignObject id="add-node-outer" class="visible add-node-outer" *ngIf="node.label == 'RCU'">
      <xhtml:button class="add-node-cu" (click)="openMenu(node)" xmlns="http://www.w3.org/1999/xhtml"
        >+
      </xhtml:button>
    </svg:foreignObject>
    <svg:foreignObject id="edit-node-outer" class="visible add-node-outer"
    >
        <xhtml:button class=" hover-btn edit-node-cu show-btn-edit" (click)="showPanel(node)" xmlns="http://www.w3.org/1999/xhtml">
          <em class="nh-icon nh-icon-edit02"></em>
        </xhtml:button>
      </svg:foreignObject>

    <svg:foreignObject id="delete-node-outer" class="visible add-node-outer"
    >
      <xhtml:button class=" hover-btn delete-node-cu show-btn-delete" (click)="deleteNode(node)" xmlns="http://www.w3.org/1999/xhtml">
        <em  class="nh-icon nh-icon-delete02"></em>
      </xhtml:button>
    </svg:foreignObject>
    <svg:foreignObject id="info-node-outer" class="visible add-node-outer" *ngIf="node.label == 'RCU'"
    >
      <xhtml:button class=" hover-btn info-node-cu show-btn-info" (click)="infoRCU(node)" xmlns="http://www.w3.org/1999/xhtml">
        <em  class="nh-icon nh-icon-info-circle"></em>
      </xhtml:button>
    </svg:foreignObject>
    <svg:g class="node" xmlns="http://www.w3.org/2000/xhtml">
      <svg:foreignObject class="node-label-outer visible">
        <xhtml:div
        ><xhtml:ng-container
        *ngIf="node.data.show"
        [ngTemplateOutlet]="node"
        [ngTemplateOutletContext]="{
          entity: node
        }"
      >
      </xhtml:ng-container>
        </xhtml:div>
      </svg:foreignObject>
    </svg:g>
    
  </ng-template>
</ngx-graph>

<ng-template #attributeSourceDDBlock let-entity="entity">
  <!-- Attribute Selection Starts -->
  <div class="form-group" *ngIf="entity.nslAttributes.length > 0">
    <label class="field-label">{{ labels?.Attributes }}</label>
    <select
      class="custom-select"
      aria-label=""
      name="select_attribute"
      (change)="changeAttribute(entity, $event.target.value)"
    >
      <option value="-1" selected>{{ labels?.Select_Attribute }}</option>
      <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option>
    </select>
  </div>

  <div *ngIf="entity?.nslAttributes?.length > 0">
    <div *ngFor="let attr of entity.nslAttributes">
      <ng-container
        *ngIf="attr.generalEntity && attr?.isSelected"
        [ngTemplateOutlet]="attributeSourceDDBlock"
        [ngTemplateOutletContext]="{
          entity: attr.generalEntity
        }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template  #actionMenu let-entity="nodeInfo">
  <button class="btn-close close-dialog" style="right: 25px;" *ngIf="paramType" id="conditions_btn_close"  (click)="clearPopData()">
    <i class="bi bi-arrow-left-short" ></i>
  </button>
  <button class="btn-close close-dialog" id="conditions_btn_close" (click)="closeRefComp()" >
    <i class="bi bi-x"></i>
  </button>
  <div class="mat-select-param" *ngIf="!paramType">
    <label class="field-label">{{ labels?.Select_Parameter }}</label>
    <select
      class="custom-select margin-b-15"
      aria-label=""
      name="param_select"
      (change)="changeLayer(sourceDcdValue.layerType)"
      [(ngModel)]="paramType" 
    >
        <option [value]="'Attr'">{{ labels?.Attribute }}</option>
        <option [value]="'RCU'">{{ labels?.Reserve_CU_Fucntion }}</option>
        <option [value]="'Const'">{{ labels?.Constant_Value }}</option>
    </select>
  </div>
  <div *ngIf="paramType=='Attr'">
    <div>
      <label class="field-label">{{ labels?.Layer }}</label>
      <select
        class="custom-select margin-b-15"
        aria-label=""
        name="layer_select"
        (change)="changeLayer(sourceDcdValue.layerType)"
        [(ngModel)]="sourceDcdValue.layerType"
      >
        <option value="" selected>{{ labels?.Select_Layer }}</option>
        <option *ngFor="let layer of sourceDcdValue.selectedChangeUnit.layers; let layerIndex = index" [value]="layer.type">
          {{ labels?.[layer?.type] ? labels?.[layer?.type]  :  layer?.type}}
        </option>
      </select>
     <div
       class="form-group"
       *ngIf="
         sourceDcdValue?.layerType && sourceDcdValue.participatingItems?.length > 0
       "
     >
       <mat-label class="field-label">{{ labels?.Object }}</mat-label>
       <select
         class="custom-select"
         aria-label=""
         name="select_object"
         (change)="changeEntity(sourceDcdValue.entityIndex)"
         [(ngModel)]="sourceDcdValue.entityIndex"
       >
         <option value="-1" selected>{{ labels?.Select_Object }}</option>
   
         <option
           *ngFor="let entity of sourceDcdValue.participatingItems; let entityIndex = index"
           [value]="entityIndex"
           >{{ entity.name }}</option
         >
       </select>
     </div>
     <ng-container
       *ngIf="sourceDcdValue?.nslAttributes?.length > 0"
       [ngTemplateOutlet]="attributeSourceDDBlock"
       [ngTemplateOutletContext]="{
         entity: sourceDcdValue
       }"
     >
     </ng-container>
    </div>
  </div>
  <div *ngIf="paramType=='RCU'">
    <div class="rcu-name-container">
      <label class="field-label">{{ labels?.RCU_Name }}</label>
       <select
        class="custom-select"
        aria-label=""
        name="layer_select"
        (change)="rcuChange(reservedCUName)"
        [(ngModel)]="reservedCUName">
        <option *ngFor="let rcu of DataTypeRcuList; let rcuIndex = index" [value]="rcu">
          {{ rcu }}
        </option>
       </select>
      </div>
      <div class="function-name-container">
        <label class="field-label" *ngIf="reservedCUName">{{ labels?.Function_Name }}</label>
        <select
          class="custom-select"
          aria-label=""
          name="layer_select"
          (change)="rcuLayerChange(reservedCUName, functionName)"
          [(ngModel)]="functionName"
          *ngIf="reservedCUName"
        >
          <option
            *ngFor="let rcuLayer of functionsNamesOfSelectedRcu; let rcuLayerIndex = index"
            [value]="rcuLayer"
          >
            {{ rcuLayer }}
          </option>
        </select>
      </div>
      <div class="function-Info" *ngIf="reservedCUName && functionName && rcuInfo?.info">
        <textarea class="function-Info-name" [(ngModel)]="rcuInfo.info" readonly> </textarea>
      </div>
  </div>
  <div *ngIf="paramType=='Const'">
    <mat-form-field class="full-width">
      <mat-label>{{ labels?.Constant_Value }}</mat-label>
      <input matInput class="constant-value-box" placeholder="Type Constant Value" [(ngModel)]="constValue">
    </mat-form-field>
  </div>
  <div class="rcu-save-btn" *ngIf="paramType">
    <button class="btn save" id="conditions_btn_save" [disabled]="(!enableSaveBtn)" (click)="generateNodes()" >{{ labels?.Save }}</button>
  </div>

</ng-template>
<ng-template #infoTemplate>
  <button class="btn-close close-dialog" id="conditions_btn_close" (click)="closeRefComp()" >
    <i class="bi bi-x"></i>
  </button>
  <div class="popup-container" style="max-width: 400px;">
    <div class="popup-info margin-b-15">
      <h5>{{ rcuInfo?.info }}</h5>
      <h6 class="margin-b-15">{{ labels?.Arguments }} :</h6>
      <ul style ="margin: 5px">
        <li *ngFor="let arg of rcuInfo?.args">
          <strong class="arg-name">{{ arg?.name }}:</strong> {{ arg?.info }} 
          <div><span>Type: {{ arg?.dataType }}</span></div>
          <!-- <div><span>Mandatory: {{ arg?.isMandatory }}</span></div> -->
        </li>
      </ul>
    </div>
  </div>
</ng-template>