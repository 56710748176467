import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SolutionVersioningFacadeService, TranslatorService } from '@common-services';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


export interface DialogData {
  data: any;
}

@Component({
  selector: 'app-review-status',
  templateUrl: './review-status.component.html',
  styleUrls: ['./review-status.component.scss'],
})
export class ReviewStatusComponentNodelib implements OnInit {
  ELEMENT_DATA: any = this.data;

  private ngUnsubscribe = new Subject();
  pendingReviewExists: boolean = true;
  finishType: string;
  reviewData: any;
  OwnerdataSource: any[] = [];
  displayedColumns: string[] = ['name', 'status', 'acted_on', 'comments'];
  dataSource = this.ELEMENT_DATA;
  headerColumns: string[] = ['owner', 'submitDate', 'solutionName', 'description'];
  ownerId: any;
  noComments: "Didn't comment";
  labels: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private versioningFacade: SolutionVersioningFacadeService,
    private translator: TranslatorService
  ) {
    this.detectFinish();
    this.pendingReviewInformation();
    this.detectfinishNotification();
    this.detectLanguageChange();
    this.getOwnerData();
  }

  ngOnInit(): void {}
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  // for getting solutions owner data//
  getOwnerData() {
    let robj = { betOwner: 'string', submittedTime: 'string', betName: 'string', betDescription: 'string' };

    robj.betDescription = this.ELEMENT_DATA.betDescription;
    robj.betName = this.ELEMENT_DATA.betName;
    robj.betOwner = this.ELEMENT_DATA.betOwner;
    if (this.ELEMENT_DATA && this.ELEMENT_DATA.submittedTime) {
      let arr = this.ELEMENT_DATA?.submittedTime.split(',');
      robj.submittedTime = arr[0] + arr[1];
      this.OwnerdataSource.push(robj);
    }
  }

  // for detectFinish //
  detectFinish() {
    this.versioningFacade.finish$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.reviewData = res;
        this.finishType = res.finishType;
        const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));

        this.ownerId = activeUser.id === res.ownerId ? true : false;
      }
    });
  }
  //for detectfinishNotification//
  detectfinishNotification() {
    this.versioningFacade.finishNotification$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((resp: any) => {
      if (resp) {
        this.reviewData = resp;
        this.finishType = resp.finishType;
        const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));

        this.ownerId = activeUser.id === resp.ownerId ? true : false;
      }
    });
  }

  //for getting pending reviewinfo//
  pendingReviewInformation() {
    this.versioningFacade.pendingReviewExists$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.pendingReviewExists = res;
      }
    });
  }

  //cancelling review//
  cancelReview() {
    this.versioningFacade.cancelReview(this.reviewData, this.finishType);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.versioningFacade.getFinishDataForNotification('');
    this.ngUnsubscribe.complete();
  }
  closeDialog(){
    this.versioningFacade.closeReviewStatus();
  }
}
