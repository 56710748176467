import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsEndpointService, EventsFacadeService, FieldConfig } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-label',
  template: `
    <form class="demo-full-width">
      <div *ngIf="!field?.configuration?.clickableConfig?.enableClickableCustomization">
        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>

        <button
          mat-button
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          (click)="checkEvent()"
          [disabled]="minutes > 1 || seconds > 1"
        >
          {{ field.label }}
          <span *ngIf="minutes > 1 || seconds > 1"
            >( <span *ngIf="minutes > 0">{{ minutes }}:</span>{{ seconds }} )</span
          >
        </button>
        <p
          class="bottom-text"
          *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <span
          title="Sub-Transactional CU"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          class="sub-cu"
          (click)="openSubPopUp()"
        >
        </span>
      </div>

      <!-- config clickable -->
      <div *ngIf="field?.configuration?.clickableConfig?.enableClickableCustomization == true">
        <div *ngIf="field?.configuration?.clickableConfig?.selectedClickableType == 'button'">
          <button
            mat-button
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            (click)="checkEvent()"
            [disabled]="minutes > 1 || seconds > 1"
          >
            {{ field.value }}
            <span *ngIf="minutes > 1 || seconds > 1"
              >( <span *ngIf="minutes > 0">{{ minutes }}:</span>{{ seconds }} )</span
            >
          </button>
        </div>
        <div (click)="checkEvent()" *ngIf="field?.configuration?.clickableConfig?.selectedClickableType == 'icon'">
          <img [src]="field.configuration.clickableConfig?.clickableIcon?.contentUrl" />
        </div>

        <div (click)="checkEvent()" *ngIf="field?.configuration?.clickableConfig?.selectedClickableType == 'text'">
          <label>{{ field.configuration.clickableConfig?.clickableIcon?.clickableText }} </label>
        </div>

        <div (click)="checkEvent()" *ngIf="field?.configuration?.clickableConfig?.selectedClickableType == 'link'">
          <a>{{ field.configuration.clickableConfig.clickableLink }}</a>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class ClickableComponent implements OnInit {
  field: FieldConfig;
  chipDataChanged: Subject<any> = new Subject();
  group: FormGroup;
  timer: any;
  seconds: number;
  minutes: number;
  clickableConfig: any;
  isOnSelectEvent: boolean;
  clickableOnSelect: Subject<any> = new Subject();
  event:any;

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService,public eventsEndPoint: EventsEndpointService) {
    // this.checkOnSelectEvent();

  }

  ngOnInit(): void {
    // this.checkOnSelectEvent();
    this.clickableConfig = this.field.configuration?.clickableConfig;
    /* istanbul ignore next */
    if (!this.clickableConfig) {
    this.field.value = typeof this.field.value === 'number' ? this.field.value : Number(this.field.value);
    }
    this.timer = setInterval(() => {
      this.timeBetweenDates();
    }, 1000);
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    if (this.field?.configuration?.clickableConfig?.selectedClickableType == 'icon') {
      if (this.field.value) {
        let config = JSON.parse(this.field.value);
        if(config?.contentUrl)
        this.field.configuration.clickableConfig.clickableIcon = config;
      }
    }
    else if (this.field?.configuration?.clickableConfig?.selectedClickableType == 'link') {
      if (this.field.value) {
        this.field.configuration.clickableConfig.clickableLink = this.field.value;
      }
    }
    else if (this.field?.configuration?.clickableConfig?.selectedClickableType == 'text') {
      if (this.field.value) {
        this.field.configuration.clickableConfig.clickableText = this.field.value;
      }
    }

  }
  // checkOnSelectEvent(){
  //   this.eventsEndPoint.emitEventClickable$.subscribe((res: any) => {
  //     let id = this.field?.attribute?.id + '_' + this.field?.slotNumber;
  //     if (id == res.id) {
  //       this.isOnSelectEvent = true;
  //       this.event = res.event;
  //     }
  //   });
  // }

  /* istanbul ignore next */
  timeBetweenDates() {
    let now = new Date();
    let difference = this.field.value - now.getTime();
    if (difference <= 0) {
      // Timer done
      clearInterval(this.timer);
    } else {
      this.seconds = Math.floor(difference / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.seconds %= 60;
      this.minutes %= 60;
    }
  }

  /* istanbul ignore next */
  onClick() {
    if (!this.field.isHidden) {
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
      /* conditional potentiality check  */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
    }
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  /*this is trigger events @author: Aravind */
  checkEvent() {
    if (this.clickableConfig?.enableClickableCustomization) {
      this.onSelect();
    } else this.onClick();
  }

  /*this is to trigger onselect event */
  onSelect() {
    this.clickableOnSelect.next({ id: this.field?.attribute['id'], slot: this.field.slotNumber, name:this.field?.attribute?.['name'], event: this.field?.eventsOnSelects?.[0] });
  }


}
