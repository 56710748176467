import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { DLDRecommendations } from '../modal/dld-recommendations';

@Injectable({providedIn: 'root'})
export class DLDRecommendationsStore extends ObservableStore<DLDRecommendations> {
  constructor() {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  /* istanbul ignore next */
  getStateData() {
    let state = this.getState();
    if (state && Object.keys(state).length > 0) {
      return state;
    }
  }

  getGSI(state: any, data: any, action: string) {
    this.setState({ ...state, gsiList: data, previousAction: action }, action);
  }

  getRole(state: any, data: any, action: string) {
    this.setState({ ...state, roleList: data, previousAction: action }, action);
  }

  getChangeUnit(state: any, data: any, action: string) {
    this.setState({ ...state, changeUnitList: data, previousAction: action }, action);
  }
  getEnity(state: any, data: any, action: string) {
    this.setState({ ...state, entityList: data, previousAction: action }, action);
  }

  getAttribute(state: any, data: any, action: string) {
    this.setState({ ...state, attributeList: data, previousAction: action }, action);
  }

  getDCD(state: any, data: any, action: string) {
    this.setState({ ...state, DCDList: data, previousAction: action }, action);
  }

  getNestedCU(state: any, data: any, action: string) {
    this.setState({ ...state, nestedCUList: data, previousAction: action }, action);
  }
  getExceptionCU(state: any, data: any, action: string) {
    this.setState({ ...state, exceptionCUList: data, previousAction: action }, action);
  }

  setStateData(data: any, action: string) {
    const currentStateData = this.getStateData();
    switch (action) {
      //   case 'GET_Book':
      //     return this.getBook(currentStateData, data, action);

      case DLDStoreActions.ReadGSI:
        return this.getGSI(currentStateData, data, action);

      case DLDStoreActions.ReadRole:
        return this.getRole(currentStateData, data, action);

      case DLDStoreActions.ReadChangeUnit:
        return this.getChangeUnit(currentStateData, data, action);

      case DLDStoreActions.ReadEntity:
        return this.getEnity(currentStateData, data, action);

      case DLDStoreActions.ReadAttribute:
        return this.getAttribute(currentStateData, data, action);

      case DLDStoreActions.ReadDCD:
        return this.getDCD(currentStateData, data, action);

      case DLDStoreActions.ReadNestedCU:
        return this.getNestedCU(currentStateData, data, action);
      case DLDStoreActions.ReadExceptionCu:
        return this.getExceptionCU(currentStateData, data, action);
    }
  }
}
export enum DLDStoreActions {
  ReadBook = 'GET_Book',
  ReadRole = 'GET_Role',
  ReadGSI = 'GET_GSI',
  ReadChangeUnit = 'GET_ChangeUnit',
  ReadEntity = 'GET_Entity',
  ReadAttribute = 'GET_Attribute',
  ReadDCD = 'GET_DCD',
  ReadNestedCU = 'GET_NestedCU',
  ReadExceptionCu = 'GET_ExceptionCU',
}
