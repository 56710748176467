import { Component, OnInit, forwardRef, Input, OnDestroy,TemplateRef, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject, Subject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { AlertService, EventsFacadeService, FileUploadPreviewComponent, TranslatorService } from '@common-services';
import { BrowserQRCodeReader } from '@zxing/browser';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QrcodeScannerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QrcodeScannerComponent),
      multi: true,
    },
  ],
})
export class QrcodeScannerComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() field: any;
  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_39,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION,
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.EAN_8,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
  ];

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string = '';
  camClicked: boolean;
  torchEnabled = false;
  cameraPermission: boolean = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  chipDataChanged: Subject<any> = new Subject();
  tryHarder = false;
  deviceSelected: string;
  codeReader = new BrowserQRCodeReader();
  files: any = [];
  labels: any;
  unSubscribe = new Subject();
  isRemoveAll: boolean = false;
  @ViewChild('fileInput') fileInput: any;
  constructor(
    private alertService: AlertService,
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private dialogService: MatDialog,
    public dialog: MatDialog,
    ) {
      this.getPermission('camera');
  }

  ngOnInit(): void {
    this.detectLanguageChange();
    /* istanbul ignore next*/
    if (this.field?.configuration?.submitCU) {
      /* istanbul ignore next*/
      this.eventsService.hideSubmitButton.next(!!this.field?.configuration?.submitCU);
    }
  }

  /**
   * Detects language change
   */
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  /**
   * Decodes img code
   * @param imgData
   */
  decodeImgCode(imgData: string) {
    if (imgData.includes('data:image')) {
      const controls = this.codeReader.decodeFromImageUrl(imgData).then((decodedData: any) => {
        /* istanbul ignore next*/
        if (decodedData) {
          this.qrResultString = decodedData.text;
          this.alertService.showToaster('Code scanned succsesfully!', '', 'success');
          this.propagateChange(decodedData.text);
          /* istanbul ignore next*/
          if (this.field?.configuration?.submitCU) {
            /* istanbul ignore next*/
            const data = {
              attributeId: this.field?.attribute['id'],
              isTableConfig: this.field?.attribute['isTableConfig'],
              attrName: this.field?.attribute['name'],
              eventType: 'ON_CHANGE',
              entityName: this.field?.entityName,
              entityId: this.field?.entityId,
              slotNumber: this.field?.slotNumber,
              isInfo: this.field?.isInfo,
              isMulti: this.field?.isMultiEntity,
              txnRecordId: this.field?.txnRecordId,
            };
            if (this.field?.isOnSelectSubmit) {
              this.chipDataChanged.next();
            } else {
              this.eventsService.onSubmitEvent(data);
            }
          }
        } else {
          this.alertService.showToaster('Invalid QR Code', '', 'error');
        }
      });
    } else {
      this.alertService.showToaster('Please upload valid image .jpeg, .png, etc', '', 'error');
    }
  }

  /**
   * on file drop handler
   */
  /* istanbul ignore next*/
  onFileDropped(files: any) {
    this.files = files;
    this.getBase64(files[0]).then((data: any) => {
      this.decodeImgCode(data);
    });
  }

  /**
   * handle file from browsing
   */
  /* istanbul ignore next*/
  fileBrowseHandler(files: any) {
    this.files = files;
    this.getBase64(files[0]).then((data: any) => {
      this.decodeImgCode(data);
    });
  }

  retry() {
    this.files = [];
    this.fileInput.nativeElement.value = null;
    this.fileInput?.nativeElement?.click();
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files = [];
  }

  /**
   * Gets base64 from image file url
   * @param file
   * @returns
   */
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * Determines whether cameras found on
   * @param devices
   */
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    /*istanbul ignore else */
    if (this.hasDevices) {
      this.deviceCurrent = this.availableDevices[0];
    }
  }

  /**
   * Determines whether code result on
   * @param resultString
   */
  onCodeResult(resultString: string) {
    /*istanbul ignore else */
    if (resultString) {
        this.qrResultString = resultString;
        this.alertService.showToaster('Code scanned succsesfully!', '', 'success');
        this.propagateChange(this.qrResultString);
        /* istanbul ignore next*/
        if (this.field?.configuration?.submitCU) {
          /* istanbul ignore next*/
          const data = {
            attributeId: this.field?.attribute['id'],
            isTableConfig: this.field?.attribute['isTableConfig'],
            attrName: this.field?.attribute['name'],
            eventType: 'ON_CHANGE',
            entityName: this.field?.entityName,
            entityId: this.field?.entityId,
            slotNumber: this.field?.slotNumber,
            isInfo: this.field?.isInfo,
            isMulti: this.field?.isMultiEntity,
            txnRecordId: this.field?.txnRecordId,
          };
          if (this.field?.isOnSelectSubmit) {
            this.chipDataChanged.next();
          } else {
            this.eventsService.onSubmitEvent(data);
          }
        }
    }
  }

  /**
   * Determines whether has permission on
   * @param hasPermission
   */
  onHasPermission(hasPermission: boolean) {
    this.hasPermission = hasPermission;
  }

  /**
   * Determines whether torch compatible on
   * @param isCompatible
   */
  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  /**
   * Toggles torch
   */
  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  /**
   * Toggles try harder
   */
  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  /**
   * Determines whether device change on
   * @param device
   * @returns
   */
  onDeviceChange(device: MediaDeviceInfo) {
    /* istanbul ignore next */
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  /**
   * Writes value
   * @param value
   */
  writeValue(value: any) {}

  /**
   * Propagate change of qrcode scanner component
   */
  propagateChange = (_: any) => {};

  /**
   * Registers on change
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  camClickToScan(templateRef: TemplateRef<any>): void {
    this.camClicked = true;
    this.dialog.open(templateRef, { panelClass: 'filter-dialog-pop-up' });
  }
  /**
   * Registers on touched
   */
  registerOnTouched() {}

  /**
   * Validates qrcode scanner component
   * @param { value }
   * @returns
   */
  validate({ value }: FormControl) {
    let inNotValid;
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      inNotValid = this.qrResultString || (this.files && this.files.length > 0) ? false : true;
    } else {
      inNotValid = false;
    }
    return !inNotValid ? null : { valid: false };
  }

  /**
   * @author Ajit Dhayal
   * @return void, will call onchange event for valid Qrcode
   */
  triggerOnChangeEvent() {
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
      ent_index: this.field?.ent_index,
    };
    if (this.field?.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent(data);
    } else {
      this.eventsService.setEvent(data);
    }
  }

  async getPermission(permission) {
    try {
      const result = await navigator.permissions.query({ name: permission });
      this.cameraPermission = result.state === 'granted' ? true : false;
    } catch (error) {
      this.cameraPermission = false;
    }
  }
  
  ngOnDestroy() {}
}
