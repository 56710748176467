<ng-template #listAttribute let-data="data" let-name="name">
  <li class="list-attribute">
    <strong class="title passcolor"> {{ name }} </strong>
    <span>{{ data }}</span>
  </li>
</ng-template>
<ng-template #listSingleAttribute let-data="data">
  <li class="title w-100">
    {{ data }}
  </li>
</ng-template>
<ng-template #actionButton let-data="data">
  <ng-container *ngIf="data.cardDisplay == 'design'">
    <span class="btn-close">
      <i class="bi bi-x" *ngIf="expandGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
      <button
        *ngIf="!isGrammer"
        #gsiAction
        attributeDesignConfig
        [isEdit]="isEdit"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [_style]="style"
        [tabSelected]="tabSelected"
        [isDesign]="isDesign"
        [isButton]="true"
        [_selectedDirectiveId]="selectedDirectiveId"
        (drop)="dropActionBtn($event)"
        (dragover)="allowDrop($event)"
        class="btn template-btn action-button btnaction text-truncate"
        [id]="'action-' + data?.position"
        [styleGsi]="expandStyleGsi?.[data?.position]"
        [ngClass]="data?.class"
        [cardDisplay]="cardDisplay"
      >
        {{expandGsiMapping?.[data?.position] ? expandGsiMapping?.[data?.position].name : 'Action Button'}}
      </button>
    </span>
  </ng-container>
  <ng-container *ngIf="data.cardDisplay == 'view'">
    <button
      [ngStyle]="{ background: btnHoveredBoolean?.[data?.position] ? btnHoverColors?.[data?.position] :btnColors?.[data?.position] }"
      (mouseover)="btnHoveredBoolean[data?.position] = true"
      (mouseout)="btnHoveredBoolean[data?.position] = false"
      #gsiAction
      [ngClass]="data?.class"
      pTooltip="{{ gsiExpanddata?.['action-'+ data?.position ]?.displayName }}"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      id="action-{{ data?.position }}"
      (click)="gotoTxnPage(gsiExpanddata?.['action-'+ data?.position ],expandedCardDetail?.[0])"
      *ngIf="gsiExpanddata?.['action-'+ data?.position]"
    >
      {{ gsiExpanddata?.['action-'+ data?.position ]?.displayName }}
    </button>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="cardDisplay == 'design'">
    <li
      *ngIf="!data?.isImage"
      [id]="attributeItem?.innerHTML?.trim()"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [_style]="style"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="expandStyleAttribute[data?.position]"
      [tabSelected]="tabSelected"
    >
      <div [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }" class="attribute-unlink">
        <div #attributeItem id="attr-{{ data?.position - 1 }}">
          {{expandPositionMap?.[data?.position -1 ] ?expandPositionMap?.[data?.position -1]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{expandPositionMap?.[data?.position] ?expandPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
          alt=""
      /></span> -->
    </li>
    <li
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      id="attr-{{ data?.position - 1 }}"
      *ngIf="data?.isImage"
      [ngClass]="data.class"
      class="h-100"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position - 1 }}">
          {{expandPositionMap?.[data?.position -1] ?expandPositionMap?.[data?.position -1]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <div [ngClass]="{'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute'}"
        class="image-unlink h-100"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{expandPositionMap?.[data?.position] ?expandPositionMap?.[data?.position]?.name : "Attribute" }}</div
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
          alt=""
      /></span> -->
    </li>
  </ng-container>
  <ng-container *ngIf="cardDisplay == 'view'">
    <ng-container *ngIf="data?.isImage">
      <img
        [ngClass]="data?.class"
        *ngIf="contentURL?.[data?.position]"
        [src]="contentURL?.[data?.position] | CDNUrlRewritePipe"
        alt=" "
      />
    </ng-container>
    <ng-container *ngIf="!data?.isImage">
      <li
      CardDesignConfig
      [isAttributeLevel]="true"
      [isEntityLevel]="false"
      [singleRecord]="singleRecord[data?.position +'']"
      [attributeColors]="[expandColors?.[data?.position]]"
      [conditionArray]="conditionArrayExpand"
      [attrHoveredColors]="[attrhoveredColors?.[data?.position]]"
      [_otherStyle]="[otherStyles?.[data?.position]]"
      *ngIf="data?.isSingleDisplay  && expandDetails?.[data?.position +'']"
    >
      {{displayNameMap.has(expandDetails?.[data?.position +'']) ? displayNameMap.get(expandDetails?.[data?.position +'']) : expandDetails?.[data?.position +'']}}
    </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="singleRecord[data?.position +'']"
        [attributeColors]="[expandColors?.[data?.position]]"
        [conditionArray]="conditionArrayExpand"
        [attrHoveredColors]="[attrhoveredColors?.[data?.position]]"
        [_otherStyle]="[otherStyles?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue && expandedCardDetail?.[0]?.[expandDetails?.[data?.position +'']]"
        pTooltip="{{ expandedCardDetail?.[0]?.[expandDetails?.[data?.position +'']] }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{expandedCardDetail?.[0]?.[expandDetails?.[data?.position +'']]}}
      </li>
      <li
        *ngIf="data?.isSingleValueWOC && expandedCardDetail?.[0]?.[expandDetails?.[data?.position +'']]"
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="singleRecord[data?.position +'']"
        [attributeColors]="[expandColors?.[data?.position]]"
        [conditionArray]="conditionArrayExpand"
        [attrHoveredColors]="[attrhoveredColors?.[data?.position]]"
        [_otherStyle]="[otherStyles?.[data?.position]]"
      >
        {{expandedCardDetail?.[0]?.[expandDetails?.[data?.position +'']]}}
      </li>
     
    </ng-container>
  </ng-container>
</ng-template>
<ng-container #dynamicExpandCard>
  <div
    class="dynamic-template-placeholder"
    #dropEntityData
    [ngStyle]="{ display: tabSelected === 'child' || !isDynamicExpandCardSelected ? 'none' : '' }"
  ></div>
</ng-container>
<div class="expand-type-temp-previews">
  <div class="top-header">
    <mat-icon (click)="closeCard()">close</mat-icon>
  </div>
  <div class="expand-entity-template-t2 expand-type-3" *ngIf="expandType == 1">
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-5">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true,
                      class: 'nh-p-8'
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-7">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-small'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-medium'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle-small'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    cardDisplay: cardDisplay,
                    class: 'btn cta-btn btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    class: 'btn cta-btn btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    class: 'btn cta-btn btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t1 expand-type-2" *ngIf="expandType == 4">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-4">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-8">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-title'
                }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-title-small'
                }
              }"
            ></ng-template>
          </ul>
          <div class="row">
            <div class="col-md-6">
              <!-- <ul class="title-sup">
                  <li id="" class="card-subtitle">
                    {{expandedCardDetail?.[0]?.[expandDetails?.['4']]}}
                  </li>
                  <li id="" class="card-subtitle-sup">
                    {{expandedCardDetail?.[0]?.[expandDetails?.['5']]}}
                  </li>
                </ul> -->
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 4,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 5,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 6,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 7,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
              </ul>
              <ul class="listgroup">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 8,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 9,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 10,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 11,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 12,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 13,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 14,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="listgroup">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 15,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 16,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 17,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 18,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-title-small'
                    }
                  }"
                ></ng-template>
              </ul>
              <ul class="listgroup">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 19,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 20,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 21,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 22,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 23,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 24,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 25,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-title'
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
          </div>

          <div class="btn-cta">
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  class: 'btn cta-btn-border btn-tooltip'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  class: 'btn cta-btn-border btn-tooltip'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  class: 'btn cta-btn btn-tooltip'
                }
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="expand-entity-template-t3 expand-type-4" *ngIf="expandType == 12">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-6">
          <ul class="card-img">
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <ul class="card-img-grid">
                <li id="" class="card-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
                <li id="" class="card-logo mb-0">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 3,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="card-img-grid">
                <li id="" class="card-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 4,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
                <li id="" class="card-logo mb-0">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 5,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-small'
                  }
                }"
              ></ng-template>

              <li id="" class="linebar"></li>
            </ul>
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title-small'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title-small'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="list-group pt-0">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <li id="" class="linebar"></li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title-small'
                  }
                }"
              ></ng-template>
            </ul>
            <!-- <ul class="list-group pad-125">
                <li id="" class="list-group-title-small">
                  {{expandedCardDetail?.[0]?.[expandDetails?.['14']]}}
                </li>
              </ul> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data-box">
            <ul class="data-box-title">
              <!-- <li id="" class="card-title">
                  {{expandedCardDetail?.[0]?.[expandDetails?.['15']]}}
                </li> -->

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="data-box-bold">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-bold'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-semi'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-semi'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-semi'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-semi'
                  }
                }"
              ></ng-template>
              <li id="" class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 21,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-semi'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="btn-cta">
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  class: 'btn cta-btn-border btn-tooltip'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  class: 'btn cta-btn-border btn-tooltip'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  class: 'btn cta-btn btn-tooltip'
                }
              }"
            ></ng-template>
          </div>
          <!-- <div class="btn-cta">
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  class: 'btn btn-tooltip cta-btn-border'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  class: 'btn btn-tooltip cta-btn'
                }
              }"
            ></ng-template>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t4 expand-type-5" *ngIf="expandType == 5">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-6">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="card-data pl-50">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-list">
              <div class="btn-cta">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      cardDisplay: cardDisplay,
                      class: 'btn btn-tooltip cta-btn-border'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      class: 'btn btn-tooltip cta-btn'
                    }
                  }"
                ></ng-template>
              </div>
              <ul class="list-group mb-0">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 13,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'list-group-title mb-0'
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row list-group-mid">
        <div class="col-md-6">
          <div class="card-data clr-black">
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data">
            <ul class="list-group">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title w-100'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
      </div>
      <div class="linebar"></div>
      <div class="row list-group-last">
        <div class="col-md-6">
          <div class="card-data">
            <ul class="list-group mb-0">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 21,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 22,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 23,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 24,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data">
            <ul class="list-group mb-0">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 25,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 26,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 27,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 28,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 29,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 30,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'list-group-title'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t5 expand-type-6" *ngIf="expandType == 3">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-7">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-5">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-small'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-small'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-medium'
                  }
                }"
              ></ng-template>
            </ul>
            <div class="list-group">
              <ul class="list-group-item">
                <li id="" class="list-group-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 6,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
                <li id="" class="list-group-second">
                  <ul>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 7,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 8,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-subtitle'
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
                <li id="" class="list-group-third">
                  <ul>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 9,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 10,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
              <ul class="list-group-item">
                <li id="" class="list-group-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 11,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
                <li id="" class="list-group-second">
                  <ul>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 12,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 13,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'llist-group-subtitle'
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
                <li id="" class="list-group-third">
                  <ul>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 14,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 15,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
              <ul class="list-group-item">
                <li id="" class="list-group-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 16,
                          cardDisplay: cardDisplay,
                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
                <li id="" class="list-group-second">
                  <ul>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 17,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 18,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-subtitle'
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
                <li id="" class="list-group-third">
                  <ul>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 19,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 20,
                          cardDisplay: cardDisplay,
                          isSingleValue: true,
                          class: 'list-group-title'
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 21,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-subtitle'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 22,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-subtitle'
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 23,
                      cardDisplay: cardDisplay,
                      isSingleValue: true,
                      class: 'card-subtitle'
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-12 list-data">
          <ul class="col-md-6 list-data-items">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 24,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 25,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 26,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 27,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 28,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 29,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
          </ul>
          <ul class="col-md-6 list-data-items">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 30,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 31,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 32,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 33,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 34,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 35,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 36,
                  cardDisplay: cardDisplay,
                  isSingleValue: true,
                  class: 'card-subtitle'
                }
              }"
            ></ng-template>
          </ul>
        </div>
        <div class="col-md-5 btn-list">
          <div class="btn-cta">
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  class: 'btn btn-tooltip cta-btn-border'
                }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  class: 'btn btn-tooltip cta-btn'
                }
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t6 expand-type-7 attributes-15" *ngIf="expandType == 2">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-5">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true,
                      class: 'nh-p-8'
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <!-- need to add    [ngClass]="{'border-class': this.attributeUIElementMap?.get(expandDetails?.['2']) == 'hyperlink'}" (click)="hyperLinkCheck($event,expandDetails?.['2'])" -->
        <div class="col-md-7">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    class: 'card-title',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="cardtitlesmall">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    class: 'card-title-small',
                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    class: 'card-title-small',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    class: 'card-title-medium',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <ul class="list-group">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 6,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 7,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 8,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 9,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 10,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </div>
              <div class="col-md-6 col-sm-12">
                <ul class="list-group">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 11,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 12,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 13,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 14,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 15,
                        cardDisplay: cardDisplay,
                        class: 'list-group-title',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </div>
            </div>

            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    cardDisplay: cardDisplay,
                    class: 'btn cta-btn-border btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    class: 'btn cta-btn btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    class: 'btn cta-btn btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t7 expand-type-3 attributes-8" *ngIf="expandType == 6">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-5">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-7">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    class: 'card-title',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    class: 'card-title-small',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    class: 'card-title-medium',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle-small',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="mt-32">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="mt-32">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-list">
              <div class="btn-cta">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      cardDisplay: cardDisplay,
                      class: 'btn btn-tooltip cta-btn-border'
                    }
                  }"
                ></ng-template>

                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      class: 'btn btn-tooltip cta-btn'
                    }
                  }"
                ></ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t8 expand-type-3 attributes-8" *ngIf="expandType == 7">
    <div class="top-header">
      <mat-icon (click)="closeCard()">close</mat-icon>
    </div>
    <div
      class="card"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-small'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-medium'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle-small'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="mt-32">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    cardDisplay: cardDisplay,
                    class: 'card-subtitle mb-8',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="mt-32">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle mt-0 mb-24'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-title-small'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle mb-24'
                  }
                }"
              ></ng-template>
              <div class="hl mb-24"></div>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle-small'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="mt-32">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle mb-8'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="mt-32">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    cardDisplay: cardDisplay,
                    isSingleValue: true,
                    class: 'card-subtitle'
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="btn-list">
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    cardDisplay: cardDisplay,
                    class: 'btn btn-tooltip cta-btn-border'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    class: 'btn btn-tooltip cta-btn'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    class: 'btn btn-tooltip cta-btn'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t9" *ngIf="expandType == 8">
    <div
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="top-header">
        <mat-icon (click)="closeCard()">close</mat-icon>
      </div>
      <section class="row expand-head">
        <div class="col-6">
          <div class="row primary-list">
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 1,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 1,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 3,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 3,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 4,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 4,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 5,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 5,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 6,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 6,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 7,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 7,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 8,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 8,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 9,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 9,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
          </div>
          <ul class="note">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 10,
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
        <div class="col-6">
          <div class="secondary-list">
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 11,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 11,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 12,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 12,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 13,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 13,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 14,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 14,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 15,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 15,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 16,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 16,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
          </div>
          <!-- <div class="more-cta">
          <button
  [ngStyle]="{ background: btnColors[1] }" #gsiAction (drop)="dropActionBtn($event)" (dragover)="allowDrop($event)">
            Attribute
          </button>
        </div> -->
        </div>
      </section>
      <section class="expand-view">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 17,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'title'
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 18,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'desc desc-xl'
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 19,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'title'
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 20,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'desc'
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 22,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'title'
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 22,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'desc desc-md'
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 23,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'title'
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 24,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'desc'
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 25,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'title'
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 26,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'desc desc-lg'
              }
            }"
          ></ng-template>
        </ul>
      </section>
      <div class="tertiary-list">
        <div class="row">
          <div class="col-3">
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 27,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 27,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 28,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 28,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul>
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 29,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 29,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul class="d-flex flex-wrap btn-list">
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                class: 'btn btn-primary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 4,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 5,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 6,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 7,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 8,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 9,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div class="expand-entity-template-t10" *ngIf="expandType == 9">
    <div
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="top-header">
        <mat-icon (click)="closeCard()">close</mat-icon>
      </div>
      <section class="row expand-head">
        <div class="col-6">
          <div class="row primary-list">
            <ng-container *ngFor="let i of noOfValue(9)">
              <div class="col-4">
                <ul>
                  <li>
                    <strong>
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: {
                            position: i + 1,
                            cardDisplay: cardDisplay,
                            isSingleDisplay: true
                          }
                        }"
                      ></ng-template
                    ></strong>
                    <span
                      ><ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: {
                            position: i + 1,
                            cardDisplay: cardDisplay,
                            isSingleValueWOC: true
                          }
                        }"
                      ></ng-template
                    ></span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
          <ul class="note">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 10,
                  cardDisplay: cardDisplay,

                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
        <div class="col-6">
          <div class="secondary-list">
            <ul *ngFor="let i of noOfValue(5)">
              <li>
                <strong>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 11,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></strong>
                <span
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 11,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
          </div>
          <!-- <div class="more-cta">
          <button
  [ngStyle]="{ background: btnColors[1] }" #gsiAction (drop)="dropActionBtn($event)" (dragover)="allowDrop($event)">
            Attribute
          </button>
        </div> -->
        </div>
      </section>
      <div class="tertiary-list">
        <div class="row">
          <ng-container *ngFor="let i of noOfValue(2)">
            <div class="col-4">
              <ul>
                <li>
                  <strong>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: i + 17,
                          cardDisplay: cardDisplay,
                          isSingleDisplay: true
                        }
                      }"
                    ></ng-template
                  ></strong>
                  <span
                    ><ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: i + 17,
                          cardDisplay: cardDisplay,
                          isSingleValueWOC: true
                        }
                      }"
                    ></ng-template
                  ></span>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
      <ul class="d-flex flex-wrap btn-list">
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                class: 'btn btn-primary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 4,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 5,
                cardDisplay: cardDisplay,
                class: 'btn btn-primary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 6,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 7,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 8,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: {
                position: 9,
                cardDisplay: cardDisplay,
                class: 'btn btn-secondary'
              }
            }"
          ></ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="expandType == 195">
    <div
      class="entity-board-banner-13-expand"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="banner-text">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    class: 'title',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    class: 'desc',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    class: 'ratings',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    cardDisplay: cardDisplay,
                    class: 'sub-desc',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    cardDisplay: cardDisplay,
                    class: 'sub-desc',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-auto">
            <ul>
              <li class="image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 6,
                      cardDisplay: cardDisplay,

                      isImage: true
                    }
                  }"
                ></ng-template>
              </li>
            </ul>
          </div>
        </div>
        <ul class="expand-view">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 7,
                cardDisplay: cardDisplay,
                class: 'dd',
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 8,
                cardDisplay: cardDisplay,
                class: 'dt',
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 9,
                cardDisplay: cardDisplay,
                class: 'dd',
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 10,
                cardDisplay: cardDisplay,
                class: 'dt',
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 11,
                cardDisplay: cardDisplay,
                class: 'dd',
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="expandType == 20">
    <div
      class="entity-template-expand-view entity-template-type"
      CardDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [id]="'Template-' + expandType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [(cardStyle)]="expandCardStyle"
      [expandDetails]="expandDetails"
      [expandedCardDetail]="expandedCardDetail"
      [cardHoveredColor]="cardHoveredColor"
      [ngStyle]="{ 'background-color': expandBackground }"
      [cardColor]="expandBackground"
      [conditionArray]="conditionArrayExpand"
      [card]="card"
    >
      <section class="expand-title">
        <div class="row">
          <ul class="col-xl-6 d-flex align-items-center template-btns">
            <li class="title w-100">
              {{ entityName }}
            </li>
          </ul>
          <ul class="col-xl-6 d-flex justify-content-end template-btns">
            <div class="col-auto pr-0">
              <div class="d-flex flex-wrap btn-list">
                <ul class="file-download">
                  <div class="button-1">
                    <img src="../../../../../assets/img/general-entity/File-download.svg" alt="" />
                  </div>
                </ul>
                <ul class="expand-btn">
                  <div class="button-1" (click)="closeCard()">
                    <img src="../../../../../assets/img/general-entity/view-less.svg" alt="" />
                  </div>
                </ul>
                <ul>
                  <li>
                    <ng-template
                      [ngTemplateOutlet]="actionButton"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 0,
                          cardDisplay: cardDisplay,
                          class: 'btn btn-primary'
                        }
                      }"
                    ></ng-template>
                  </li>
                </ul>
                <ul>
                  <li>
                    <ng-template
                      [ngTemplateOutlet]="actionButton"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 1,
                          cardDisplay: cardDisplay,
                          class: 'btn btn-primary'
                        }
                      }"
                    ></ng-template>
                  </li>
                </ul>
                <ul class="pr-0">
                  <li>
                    <ng-template
                      [ngTemplateOutlet]="actionButton"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          cardDisplay: cardDisplay,
                          class: 'btn btn-primary'
                        }
                      }"
                    ></ng-template>
                  </li>
                </ul>
              </div>
            </div>
          </ul>
        </div>
      </section>
      <section class="expand-header">
        <div class="row">
          <ng-container *ngFor="let i of noOfValue(3)">
            <ul class="col-xl-3">
              <li class="list-attribute">
                <strong class="title passcolor">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 1,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template>
                </strong>
                <span>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 1,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </li>
            </ul>
          </ng-container>
        </div>
      </section>

      <table class="expand-table">
        <thead>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <th>
                <ul>
                  <li class="list-attribute">
                    <strong class="title passcolor">
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: {
                            position: i + 5,
                            cardDisplay: cardDisplay,
                            isSingleDisplay: true
                          }
                        }"
                      ></ng-template>
                    </strong>
                    <span
                      ><ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: {
                            position: i + 5,
                            cardDisplay: cardDisplay,
                            isSingleValueWOC: true
                          }
                        }"
                      ></ng-template
                    ></span>
                  </li>
                </ul>
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 9,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 13,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 17,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 21,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 25,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 29,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 33,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 37,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let i of noOfValue(3)">
              <td>
                <ul>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 41,
                        cardDisplay: cardDisplay,
                        class: 'title w-100',
                        isSingleValue: true
                      }
                    }"
                  ></ng-template>
                </ul>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="hrms-template-322" *ngIf="expandType == 329">
    <div class="hrms-card-container">
      <div class="top-header">
        <mat-icon (click)="closeCard()">close</mat-icon>
      </div>
      <div class="action-btns">
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              cardDisplay: cardDisplay,
              class: 'action-btn cursor-pointer'
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: {
              position: 1,
              cardDisplay: cardDisplay,
              class: 'action-btn cursor-pointer'
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: {
              position: 2,
              cardDisplay: cardDisplay,
              class: 'action-btn cursor-pointer'
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: {
              position: 3,
              cardDisplay: cardDisplay,
              class: 'action-btn cursor-pointer'
            }
          }"
        ></ng-template>
      </div>
      <div
        class="hrms-text-section"
        CardDesignConfig
        [cardDisplay]="cardDisplay"
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
      >
        <div class="text-section-main-style">
          <span class="text-section-heading"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-4-col">
            <ng-container *ngFor="let i of noOfValue(12)">
              <div class="text-section-content">
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 2,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 2,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['14']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 14,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-4-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? (!expandedCardDetail?.[0]?.[expandDetails?.['15']] && !expandedCardDetail?.[0]?.[expandDetails?.['16']] && !expandedCardDetail?.[0]?.[expandDetails?.['17']] && !expandedCardDetail?.[0]?.[expandDetails?.['18']] && !expandedCardDetail?.[0]?.[expandDetails?.['19']] && !expandedCardDetail?.[0]?.[expandDetails?.['20']] && !expandedCardDetail?.[0]?.[expandDetails?.['21']] && !expandedCardDetail?.[0]?.[expandDetails?.['22']] && !expandedCardDetail?.[0]?.[expandDetails?.['23']] && !expandedCardDetail?.[0]?.[expandDetails?.['24']] && !expandedCardDetail?.[0]?.[expandDetails?.['25']] && !expandedCardDetail?.[0]?.[expandDetails?.['26']]) : false}">
            <ng-container *ngFor="let i of noOfValue(12)">
              <div class="text-section-content"
              [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.[ i + 15]] : false}" >
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 15,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 15,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />
          <span class="text-section-heading"
            [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['27']]: false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 27,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-3-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['28']]  && !expandedCardDetail?.[0]?.[expandDetails?.['29']] && !expandedCardDetail?.[0]?.[expandDetails?.['30']] : false }">
            <ng-container *ngFor="let i of noOfValue(3)">
              <div class="text-section-content"
              [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.[ i + 28]] : false}" >
              >
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 28,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 28,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['31']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 31,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-3-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['32']] && !expandedCardDetail?.[0]?.[expandDetails?.['33']] && !expandedCardDetail?.[0]?.[expandDetails?.['34']]: false}">
            <ng-container *ngFor="let i of noOfValue(3)">
              <div class="text-section-content"
              [ngClass]="{'d-none': !expandedCardDetail?.[0]?.[expandDetails?.[i+32]]}" >
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 32,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 32,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['35']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 35,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-3-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['36']] && !expandedCardDetail?.[0]?.[expandDetails?.['37']] && !expandedCardDetail?.[0]?.[expandDetails?.['38']]: false}"
          >
            <ng-container *ngFor="let i of noOfValue(3)">
              <div class="text-section-content" [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.[i+36]] : false}">
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 36,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 36,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['39']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 39,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-3-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['40']] && !expandedCardDetail?.[0]?.[expandDetails?.['41']] && !expandedCardDetail?.[0]?.[expandDetails?.['42']]: false}">
            <ng-container *ngFor="let i of noOfValue(3)">
              <div class="text-section-content"  [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.[i+40]] : false}">
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 40,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 40,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['43']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 43,
                  cardDisplay: cardDisplay,

                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-3-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ?!expandedCardDetail?.[0]?.[expandDetails?.['44']] && !expandedCardDetail?.[0]?.[expandDetails?.['45']] && !expandedCardDetail?.[0]?.[expandDetails?.['46']]: false}">
            <ng-container *ngFor="let i of noOfValue(3)">
              <div class="text-section-content"          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.[i+44]] : false}"
              >
                <span class="text-section-content-class"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 44,
                        cardDisplay: cardDisplay,
                        isSingleDisplay: true
                      }
                    }"
                  ></ng-template
                ></span>
                <span class="text-section-content-attr"
                  ><ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: i + 44,
                        cardDisplay: cardDisplay,
                        isSingleValueWOC: true
                      }
                    }"
                  ></ng-template
                ></span>
              </div>
            </ng-container>
          </ul>
          <hr />

          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['47']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 47,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-1-col"   [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['48']] : false}">
            <div class="text-section-content" >
              <span class="text-section-content-class"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 48,
                      cardDisplay: cardDisplay,
                      isSingleDisplay: true
                    }
                  }"
                ></ng-template
              ></span>
              <span class="text-section-content-attr"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 48,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template
              ></span>
            </div>
          </ul>

          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['49']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 49,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-1-col"  [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['50']] : false}">
            <div class="text-section-content"  >
              <span class="text-section-content-class"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 50,
                      cardDisplay: cardDisplay,
                      isSingleDisplay: true
                    }
                  }"
                ></ng-template
              ></span>
              <span class="text-section-content-attr"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 50,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template
              ></span>
            </div>
          </ul>
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['51']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 51,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-1-col"  [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['52']] : false}">
            <div class="text-section-content">
              <span class="text-section-content-class"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 52,
                      cardDisplay: cardDisplay,
                      isSingleDisplay: true
                    }
                  }"
                ></ng-template
              ></span>
              <span class="text-section-content-attr"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 52,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template
              ></span>
            </div>
          </ul>
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['53']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 53,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-1-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['54']] : false}">
            <div class="text-section-content">
              <span class="text-section-content-class"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 54,
                      cardDisplay: cardDisplay,
                      isSingleDisplay: true
                    }
                  }"
                ></ng-template
              ></span>
              <span class="text-section-content-attr"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 54,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template
              ></span>
            </div>
          </ul>
          <span class="text-section-heading"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['55']] : false}"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 55,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <ul class="ul-1-col"
          [ngClass]="{'d-none': cardDisplay == 'view' ? !expandedCardDetail?.[0]?.[expandDetails?.['56']] : false}">
            <div class="text-section-content">
              <span class="text-section-content-class"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 56,
                      cardDisplay: cardDisplay,
                      isSingleDisplay: true
                    }
                  }"
                ></ng-template
              ></span>
              <span class="text-section-content-attr"
                ><ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 56,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template
              ></span>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="stringer-expand-container position-relative" *ngIf="expandType == 340"
    CardDesignConfig
    [cardDisplay]="cardDisplay"
    [isEdit]="isEdit"
    [id]="'Template-' + expandType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [(cardStyle)]="expandCardStyle"
    [expandDetails]="expandDetails"
    [expandedCardDetail]="expandedCardDetail"
    [cardHoveredColor]="cardHoveredColor"
    [ngStyle]="{ 'background-color': expandBackground }"
    [cardColor]="expandBackground"
    [conditionArray]="conditionArrayExpand"
    [card]="card">
  <ul class="header-section">
    <div class="icon-header-section">
        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
            data: {
            position: 0,
            cardDisplay: cardDisplay,
            isSingleValue: true,
            class: 'only-value'
            }
        }"
        ></ng-template>
        <div class="icon-section">
            <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
                data: {
                position: 1,
                cardDisplay: cardDisplay,
                class: 'gsi-button'
                }
            }"
            ></ng-template>
            <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
                data: {
                position: 2,
                cardDisplay: cardDisplay,
                class: 'gsi-button'
                }
            }"
            ></ng-template>
            <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
                data: {
                position: 3,
                cardDisplay: cardDisplay,
                class: 'gsi-button'
                }
            }"
            ></ng-template>
            <div class="top-btn">
              <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                  data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  class: 'gsi-button'
                  }
              }"
              ></ng-template>
              <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                  data: {
                  position: 5,
                  cardDisplay: cardDisplay,
                  class: 'gsi-button'
                  }
              }"
              ></ng-template>
            </div>
        </div>
    </div>
    <ng-template
    [ngTemplateOutlet]="attributeTemplate"
    [ngTemplateOutletContext]="{
        data: {
        position: 1,
        cardDisplay: cardDisplay,
        isSingleValue: true,
        class: 'only-value main-heading'
        }
    }"
    ></ng-template>
    <p class="only-value description-heading">Description</p>
    <ng-template
    [ngTemplateOutlet]="attributeTemplate"
    [ngTemplateOutletContext]="{
        data: {
        position: 2,
        cardDisplay: cardDisplay,
        isSingleValue: true,
        class: 'only-value description-content'
        }
    }"
    ></ng-template>
  </ul>
  <div class="line-through"></div>
  <ul class="main-section">
    <p class="only-value description-heading">Objective Details</p>
    <div class="content-section">
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 3,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 4,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 5,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 6,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 7,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 8,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 9,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 10,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 11,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
    </div>
    <div class="line-through"></div>
    <li class="only-value description-heading">Success Criteria</li>
    <div class="content-section content-section-alt">
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 12,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 13,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 14,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 15,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 16,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 17,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
    </div>
    <div class="line-through"></div>
    <div class="description-alt-heading">
        <p class="only-value description-heading">Recalibration History</p>
        <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
                data: {
                position: 6,
                cardDisplay: cardDisplay,
                class: 'gsi-button'
                }
            }"
        ></ng-template>
    </div>
    <div class="content-section">
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 18,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
        <div class="attribute-value">
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 19,
                cardDisplay: cardDisplay,
                isSingleDisplay: true,
                isSingleValue: true
                }
            }"
            ></ng-template>
        </div>
    </div>
    <!-- <div class="line-through"></div>
    <p class="only-value description-heading">Attachment</p>
    <div class="content-section content-section-alt-2">
        <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'only-value'
                }
            }"
        ></ng-template>
        <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'only-value'
                }
            }"
        ></ng-template>
        <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
                data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'only-value'
                }
            }"
        ></ng-template>
    </div> -->
    <div class="line-through mt-6"></div>
    <div class="final-btn-section">
        <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
            data: {
            position: 7,
            cardDisplay: cardDisplay,
            class: 'gsi-button'
            }
        }"
        ></ng-template>
        <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
            data: {
            position: 8,
            cardDisplay: cardDisplay,
            class: 'gsi-button'
            }
        }"
        ></ng-template>
    </div>
  </ul>
  </div>
</div>
