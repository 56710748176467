<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeAttribute()">
        {{ labels?.close }}
      </button>
      <label class="status">Status: Draft ( Ver 1.1 )</label>
    </div>
    <div class="heading-area">
      <h2>{{ labels?.Independent_Attribute }}</h2>
      <div *ngIf="flagsave">
        <button class="save" (click)="saveEntitywithAttr()">{{ labels?.Save }}</button>
      </div>
      <div *ngIf="!flagsave">
        <button class="save" (click)="saveAttr()">{{ labels?.Save }}</button>
      </div>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Attribute_Details }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="searchable">
            <p>
              {{ labels?.Global_Search }}
            </p>
            <mat-slide-toggle
              [(ngModel)]="configurationData.data.attr?.nslAttributeProperties.isTraceable"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation'">
            <label> {{ labels?.Destination }} : </label>
            <input
              id="nodeIndependentattredit_mmiDestinationLat"
              type="number"
              matInput
              placeholder="Latitude"
              [(ngModel)]="mmiDestinationLat"
            />
            <input
              id="nodeIndependentattredit_mmiDestinationLon"
              type="number"
              matInput
              placeholder="Longitude"
              [(ngModel)]="mmiDestinationLon"
            />
            <button
              *ngIf="!mmiDestination"
              class="validate"
              id="nodeIndependentattredit_MMIDestinationValue"
              (click)="addMMIDestination()"
            >
              {{ labels?.Add }}
            </button>
            <div class="mmi-destination" *ngIf="mmiDestination">
              <p>{{ mmiDestination }}</p>
              <div class="edit-deleticons">
                <span class="pointer"
                  ><img
                    (click)="removeMMIDestination()"
                    id="mmi_defaultvalueremoveimg"
                    [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                    alt=""
                /></span>
                <span>
                  <img
                    (click)="editMMIDestination()"
                    class="edit-icon"
                    id="mmi_editiconimg"
                    [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe"
                    alt="Edit"
                  />
                </span>
              </div>
            </div>
          </div>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'file'"
            class="file_configurations"
          >
            <div class="isReadOnly">
              <p>{{ labels?.Hide_preview }}</p>
              <mat-slide-toggle
                id="nodeIndattrEdit_hidePreview"
                [(ngModel)]="hidePreview"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
            <div class="isReadOnly">
              <p>{{ labels?.Hide_open_in_new_Tab }}</p>
              <mat-slide-toggle
                id="nodeIndattrEdit_hideOpenInNewTab"
                [(ngModel)]="hideOpenInNewTab"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
            <div class="isReadOnly">
              <p>{{ labels?.Hide_Deletion }}</p>
              <mat-slide-toggle
                id="nodeIndattrEdit_hideDeletion"
                [(ngModel)]="hideDeletion"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
          </div>
          <div
            *ngIf="
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'image' &&
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'previewdocument' &&
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'file' &&
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'audio' &&
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'video' &&
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'label'
            "
            class="isReadOnly"
          >
            <p>
              {{ labels?.Show_as_Label }}
            </p>
            <mat-slide-toggle id="slide_toggleBtn_isReadOnly" [(ngModel)]="isReadOnly" value="true"></mat-slide-toggle>
          </div>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'hyperlink'"
            class="isReadOnly"
          >
            <p>
              {{ labels?.Show_Copy_Icon }}
            </p>
            <mat-slide-toggle id="slide_toggleBtn_copyLink" [(ngModel)]="copyLink" value="true"></mat-slide-toggle>
          </div>
          <div
            class="rename-input-wrap"
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'hyperlink'"
          >
            <mat-form-field>
              <input
                matInput
                [placeholder]="labels?.Display_Label"
                type="text"
                autocomplete="off"
                [(ngModel)]="displayText"
                id="display"
              />
            </mat-form-field>
            <div class="show-as-label">
              <p>
                {{ labels?.Attribute_Mapping ? labels?.Attribute_Mapping : 'Attribute Mapping' }}
              </p>
              <mat-slide-toggle
                id="Attribute_Mapping"
                [(ngModel)]="attributeMapping"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
            <div *ngIf="attributeMapping">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  [placeholder]="labels?.Attribute_Path"
                  autocomplete="off"
                  [(ngModel)]="attrPath"
                  id="attrPath"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            class="show-as-label isReadOnly"
            *ngIf="
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipsingleselection' ||
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipmultipleselection' ||
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'textarea' ||
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'label' ||
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'multiselectdropdown'
            "
          >
            <p>
              {{ labels?.Hide_Tooltip }}
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_hideTooltip"
              [(ngModel)]="hideTooltip"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div
            *ngIf="
              attrDetails?.value?.attributeUiElement === 'dropdown' ||
              attrDetails?.value?.attributeUiElement === 'multiselectdropdown'
            "
            class="show-as-label"
          >
            <p>
              {{ labels?.Hide_Search_Bar }}
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_hideSearchBar"
              [(ngModel)]="hideSearchBar"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'radioButton'"
            class="isReadOnly"
          >
            <p>
              {{ labels?.Show_Horizontal_Options }}
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_showHorizontalOptions"
              [(ngModel)]="showHorizontalOptions"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'percentage'"
            class="percentageDefaultType"
          >
            <div class="d-flex justify-content-between align-items-center">
              <mat-label class="label-txt"> {{ labels?.Set_default_type }} </mat-label>
              <mat-radio-group [(ngModel)]="percentageType" [ngModelOptions]="{ standalone: true }">
                <mat-radio-button
                  name="defaultPercentageType"
                  id="nodeIndpEdit_defaultPercentageType"
                  value="percentage"
                >
                  Percentage</mat-radio-button
                >
                <mat-radio-button name="defaultDecimalType" id="nodeIndpEdit_defaultDecimalType" value="decimal">
                  Decimal</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>
          <mat-form-field class="mb-4 mr-3 w-100">
            <mat-label>{{ labels?.Change_Driver_Type }}</mat-label>
            <mat-select multiple [(ngModel)]="selectedChangedriverOptAttr">
              <mat-option
                *ngFor="let opt of optChangeDrivers; let i = index"
                [value]="opt"
                (click)="onChangeOptionAttr(opt, i)"
              >
                {{ opt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mb-4 mr-3 w-100" *ngIf="selectedChangedriverOptAttr?.includes('ALTERNATIVE')">
            <mat-label>{{ labels?.Alternative_Attributes }}</mat-label>
            <mat-select [(ngModel)]="SelectedalternativeAttr">
              <mat-option
                *ngFor="let attr of altAttr; let i = index"
                [value]="attr.name"
                (click)="onChangeOptionAttr(attr.name, i)"
              >
                {{ attr.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="configurationData?.data?.attr?.name">
            <mat-label>{{ labels?.Attribute_Name }}</mat-label>
            <input matInput placeholder="Attribute name" [(ngModel)]="configurationData.data.attr.name" readonly />
          </mat-form-field>

          <mat-form-field *ngIf="configurationData?.data?.attr">
            <mat-label>{{ labels?.Display_name }}</mat-label>
            <input
              matInput
              placeholder="Attribute Display name"
              [(ngModel)]="configurationData.data.attr.displayName"
            />
          </mat-form-field>
          <div
            class="show-as-label nh-mb-20"
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'date'"
          >
            <p>Show as Tabs</p>
            <mat-slide-toggle id="slide_toggleBtn_tabs" [(ngModel)]="showDataAsTab" value="true"></mat-slide-toggle>
          </div>
          <div *ngIf="showDataAsTab" class="nh-mb-20">
            <div class="d-flex justify-content-between align-items-center">
              <p>Max.No.of Tabs</p>
              <div class="input-group justify-content-between border nh-radius-6 nh-border-80 mb-3 nh-w-100">
                <div class="input-group-prepend">
                  <button
                    [disabled]="dateTabConfig.maxNumberOfTabs < 2"
                    class="btn btn-outline-secondary bi bi-dash nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'minus', 'maxNumberOfTabs')"
                  ></button>
                </div>
                <input
                  type="tel"
                  [(ngModel)]="dateTabConfig.maxNumberOfTabs"
                  class="form-control nh-border-0"
                  placeholder=""
                  min="1"
                  value="true"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary bi bi-plus nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'plus', 'maxNumberOfTabs')"
                  ></button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column nh-mb-16">
              <label class="nh-mb-4">Select Design Style</label>
              <p-dropdown
                optionLabel="displayValue"
                optionValue="actualValue"
                [(ngModel)]="dateTabConfig.style"
                [options]="designStyleList"
                class="prime-ng-dropdown"
                id="designStyle"
                name="designStyle"
                appendTo="body"
              ></p-dropdown>
            </div>
            <div class="nh-mb-16">
              <mat-checkbox class="checkbox-sm" id="hideBlanks" value="true" [(ngModel)]="dateTabConfig.hideBlanks"
                >Hide Blanks</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox class="checkbox-sm" id="showPreDate" value="true" [(ngModel)]="dateTabConfig.showPredates"
                >Show Pre-date</mat-checkbox
              >
            </div>
            <div class="d-flex justify-content-between align-items-center" *ngIf="dateTabConfig.showPredates">
              <p>Max.No.of Pre-date Tabs</p>
              <div class="input-group justify-content-between border nh-radius-6 nh-border-80 mb-3 nh-w-100">
                <div class="input-group-prepend">
                  <button
                    [disabled]="dateTabConfig.maxNumberOfPreDates < 1"
                    class="btn btn-outline-secondary bi bi-dash nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'minus', 'maxNumberOfPreDates')"
                  ></button>
                </div>
                <input
                  type="tel"
                  [(ngModel)]="dateTabConfig.maxNumberOfPreDates"
                  class="form-control nh-border-0"
                  placeholder=""
                  min="0"
                  value="true"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary bi bi-plus nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'plus', 'maxNumberOfPreDates')"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="constantvalues">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Constant_Values }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- <div class="no-label w-75">
            <mat-label>Select Attributes</mat-label>
            <mat-form-field appearance="outline">
              <mat-select value="Sum">
                <mat-option value="Sum">Basic</mat-option>
                <mat-option value="Average">Average </mat-option>
                <mat-option value="Not Equal">Not Equal </mat-option>
                <mat-option value="Multiply">Multiply</mat-option>
                <mat-option value="Equals">Equals </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
          <form [formGroup]="attrDetails">
            <!-- Not for scheduler and location UI controls -->
            <ng-container
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'scheduler' &&
                (configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'location' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation')
              "
            >
              <!-- For adding options in checkbox, dropdown and radioButton UI controls -->
              <div
                *ngIf="
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'checkbox' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'dropdown' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'radioButton' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'typeahead' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'list' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'multiselectdropdown' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipmultipleselection' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipsingleselection'
                "
              >
                <input
                  matInput
                  class="add-value w-100"
                  placeholder="Add Option"
                  id="inputData"
                  type="text"
                  autocomplete="off"
                  formControlName="defaultOption"
                />
                <button class="validate" id="addInputData" (click)="addDefaultValuetoAttibute()">
                  {{ labels.Add }}
                </button>

                <div *ngIf="sourceValues">
                  <div class="d-flex added" *ngFor="let attrvalue of sourceValues; let i = index">
                    {{ attrvalue.DATA?.displayValue }}
                    <span class="pointer"
                      ><img
                        (click)="removeScourceValue(i)"
                        id="attrremoveimg"
                        class="ml-2"
                        [src]="'assets/img/nsl-gram/icon-delete.svg' | CDNUrlRewritePipe"
                        alt=""
                    /></span>
                  </div>
                </div>
              </div>

              <!-- <div
                class="expected-time mb-3"
                *ngIf="
                  configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'file' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'image' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'audio' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'video' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'Password' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'checkbox' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'dropdown' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'radioButton' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'location'
                "
              >
                <mat-label>DefaultValue</mat-label>
                <input
                  matInput
                  placeholder="Default Value"
                  name="defaultValue"
                  id="defaultValue"
                  type="text"
                  name="defaultValue"
                  autocomplete="off"
                  formControlName="defaultValue"
                />
              </div> -->

              <!-- For default value of UI controls expect file type UI controls-->
              <ng-container
                [ngTemplateOutlet]="defaultValueBlock"
                [ngTemplateOutletContext]="{
                  attrDetails: configurationData?.data?.attr
                }"
              >
              </ng-container>

              <!-- For Location UI control -->
              <!-- <div *ngIf="attrDetails?.value?.attributeUiElement === 'location'">
                <mat-form-field>
                  <mat-label>{{ labels?.Location_Type }}</mat-label>
                  <mat-select formControlName="locationType">
                    <mat-option *ngFor="let option of locationArray" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->

              <!-- For Constant Change Driver -->
              <!-- <div class="multi-value mb-4">
                <mat-label class="label-txt mr-2">
                  Constant Change Driver
                </mat-label>
                <mat-radio-group fxLayout="column" formControlName="constantChangeDriver">
                  <mat-radio-button name="isconstantChangeDriver" id="constantChangeDriverYes" [value]="true">
                    {{ labels?.Yes }}
                  </mat-radio-button>
                  <mat-radio-button name="isconstantChangeDriver" id="constantChangeDriverNo" [value]="false">
                    {{ labels?.No }}
                  </mat-radio-button>
                </mat-radio-group>
                <input
                  *ngIf="attrDetails.value.constantChangeDriver"
                  matInput
                  placeholder="Enter Constant Change Driver Value"
                  name="constantChangeDriverValue"
                  type="text"
                  name="constantChangeDriverValue"
                  autocomplete="off"
                  formControlName="constantChangeDriverValue"
                />
              </div> -->

              <!-- For Multi value -->
              <div class="multi-file-upload mb-4">
                <div
                  class="multi-value"
                  *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'chipmultipleselection'"
                >
                  <div class="nh-mb-16">
                    <mat-label class="label-txt mr-2">{{ labels?.Multi_Value }}</mat-label>
                    <mat-radio-group fxLayout="column" formControlName="isMultiValue">
                      <mat-radio-button
                        [checked]="multiDefaultValue"
                        name="isMultiValue"
                        id="attribute_multiYes"
                        [value]="true"
                        (click)="checkOfMultiDefaultValues(true)"
                      >
                        {{ labels?.Yes }}
                      </mat-radio-button>
                      <mat-radio-button
                        [checked]="!multiDefaultValue"
                        name="isMultiValue"
                        id="attribute_multiNo"
                        [value]="false"
                        (click)="checkOfMultiDefaultValues(false)"
                      >
                        {{ labels?.No }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div class="nh-mb-16">
                    <mat-label class="label-txt">{{ labels?.Download }} </mat-label>
                    <mat-radio-group fxLayout="column">
                      <mat-radio-button
                        name="canDownload"
                        id="attribute_downloadYes"
                        [checked]="canDownload == true ? true : false"
                        [value]="true"
                        (click)="downloadFile(true)"
                        >{{ labels?.Enable }}</mat-radio-button
                      >
                      <mat-radio-button
                        name="canDownload"
                        id="attribute_downloadNo"
                        [checked]="canDownload == false ? true : false"
                        [value]="false"
                        (click)="downloadFile(false)"
                        >{{ labels?.Disable }}</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>

                  <div
                    *ngIf="
                      attrDetails?.value?.attributeUiElement == 'currency' ||
                      attrDetails?.value?.attributeUiElement == 'metric'
                    "
                    class="nh-mb-16"
                  >
                    <mat-checkbox [(checked)]="attrConfiguration.strikeOff" (change)="configurationKeys($event)">
                      {{ labels?.strike_off }}
                    </mat-checkbox>
                  </div>

                  <div *ngIf="attrDetails?.value?.attributeUiElement == 'html'" class="nh-mb-16">
                    <mat-slide-toggle
                      id="slide_toggleBtn_newTab"
                      [(ngModel)]="showPopup"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="htmlToggle(showPopup)"
                      >{{ labels?.show_in_transaction }}</mat-slide-toggle
                    >
                  </div>

                  <div *ngIf="attrDetails.value.isMultiValue" class="nh-mb-16">
                    <div class="nh-mb-16">
                      <mat-label class="label-txt"> {{ labels?.Hide_Label_for_Multi_Attribute }} </mat-label>
                      <mat-radio-group fxLayout="column" formControlName="hideLabelMultiAttr">
                        <mat-radio-button name="hideLabelMultiAttr" id="hide-label-yes" [value]="true">{{
                          labels?.Yes
                        }}</mat-radio-button>
                        <mat-radio-button name="hideLabelMultiAttr" id="hide-label-no" [value]="false">{{
                          labels?.No
                        }}</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div class="nh-mb-16">
                      <mat-label class="label-txt"> {{ labels?.Hide_Multi_Value }}</mat-label>
                      <mat-radio-group fxLayout="column" formControlName="hideMultiValueIcon">
                        <mat-radio-button name="hideMultiValueIcon" id="hide-multi-value-yes" [value]="true">{{
                          labels?.Yes
                        }}</mat-radio-button>
                        <mat-radio-button name="hideMultiValueIcon" id="hide-multi-value-no" [value]="false">{{
                          labels?.No
                        }}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>

                <div class="multi-value translation-type nh-mb-16">
                  <mat-form-field appearance="fill">
                    <mat-label class="label-txt">{{ labels?.Translation_Type }} </mat-label>
                    <mat-select [(ngModel)]="translationType" [ngModelOptions]="{ standalone: true }" name="food">
                      <mat-option
                        *ngFor="let type of translationTypeArray"
                        [value]="type"
                        id="attribute_translationType_{{ type }}"
                        (click)="ChangeTranslationType(type)"
                      >
                        {{ type }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                    class="time_limit_picker"
                    *ngIf="
                      (configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'videoRecording' ||
                      configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'audioRecording')
                    "
                  >
                    <span class="time_limit_span">Time Limit</span>
                    <div class="time_limit_inputs">
                      <div class="time_limit_input_item">
                        <span>Hours:</span>
                        <input
                          class="time_limit_Hours"
                          matInput
                          placeholder="Hours"
                          id="Hours_picker"
                          [(ngModel)]="timeLimit.hours"
                          type="number"
                          autocomplete="off"
                          (keyup)="setLimit($event, 'hours')"
                          min="0"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </div>
                      <div class="time_limit_input_item">
                        <span>Minutes:</span>
                        <input
                          matInput
                          class="time_limit_Minutes"
                          placeholder="Minutes"
                          id="Minutes_picker"
                          [(ngModel)]="timeLimit.minutes"
                          type="number"
                          autocomplete="off"
                          (keyup)="setLimit($event, 'minutes')"
                          min="0"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </div>
                      <div class="time_limit_input_item">
                        <span>Seconds:</span>
                        <input
                          matInput
                          class="time_limit_Seconds"
                          placeholder="Seconds"
                          id="Seconds_picker"
                          [(ngModel)]="timeLimit.seconds"
                          type="number"
                          autocomplete="off"
                          (keyup)="setLimit($event, 'seconds')"
                          min="0"
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </div>
                    </div>
                  </div>

                <div
                  *ngIf="
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'file' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'image' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'previewdocument' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'audio' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'video' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'imageavatar'
                  "
                >
                  <div class="cal-height" id="mat_panel_toggle_url">
                    <span id="enable_URL" *ngIf="!fileUrlDefaultValueFlag">{{ labels.Enable_URL }}</span>
                    <span id="disable_URL" *ngIf="fileUrlDefaultValueFlag">{{ labels.Disable_URL }}</span>
                    <mat-slide-toggle
                      id="enable_disable_toggle"
                      [checked]="fileUrlDefaultValueFlag"
                      (toggleChange)="toggleDefaultValueURL()"
                      value="true"
                    ></mat-slide-toggle>
                  </div>
                  <div *ngIf="!fileUrlDefaultValueFlag">
                    {{ attrDetails.value.attributeType }}
                    <app-file-upload
                      [fileUploadFromEntity]="true"
                      [editFileUrl]="editFileUrl"
                      (fileUploadResponse)="fileUploadUrl($event)"
                      (deletedFileResponse)="deletedFileResponse($event)"
                      [isMulti]="multiDefaultValue"
                      [fileType]="configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'imageavatar' ? 'image' : configurationData?.data?.attr?.attributeType?.uiElement?.uiElement"
                    >
                    </app-file-upload>
                  </div>
                  <div *ngIf="fileUrlDefaultValueFlag" class="file-url-container">
                    <ng-container [ngTemplateOutlet]="fileUrlContainer"> </ng-container>
                  </div>
                </div>
                <div *ngIf="attrDetails?.value?.attributeUiElement == 'streamingVideo'">
                  <ng-container [ngTemplateOutlet]="fileUrlContainer"> </ng-container>
                </div>
              </div>
              <div
                *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'image'"
                class="image-control-selection"
              >
                <span class="image-ui">{{ labels?.Type_of_Image_UI_Control }}</span>
                <div class="radio-selection">
                  <mat-radio-group
                    class="radio-type"
                    aria-label="Select an option"
                    [(ngModel)]="imgAsChip"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <mat-radio-button [value]="false">{{ labels?.Simple_Image }}</mat-radio-button>
                    <mat-radio-button [value]="true">{{ labels?.Image_as_Chip }}</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngIf="imgAsChip" class="appearance-selction">
                  <span class="image-ui">{{ labels?.Appearance }}</span>
                  <div class="select-size">
                    <div
                      #boxSmall
                      class="box"
                      [ngClass]="imgSize?.includes('small') ? 'box-active' : ''"
                      (click)="imgSize = 'small1'"
                    >
                      <span class="size">{{ labels?.Small }}</span>
                      <span class="format">file format: svg, png, jpeg (max: 500 kb)</span>
                      <div>
                        <mat-radio-group
                          aria-label="Select an option"
                          class="type"
                          [(ngModel)]="imgSize"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-radio-button [value]="'small1'">1:1</mat-radio-button>
                          <mat-radio-button [value]="'small2'">16:9</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                    <div
                      #boxMedium
                      class="box"
                      [ngClass]="imgSize?.includes('medium') ? 'box-active' : ''"
                      (click)="imgSize = 'medium1'"
                    >
                      <span class="size">{{ labels?.Medium }}</span>
                      <span class="format">file format: svg, png, jpeg (max: 500 kb)</span>
                      <div>
                        <mat-radio-group
                          aria-label="Select an option"
                          class="type"
                          [(ngModel)]="imgSize"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <mat-radio-button [value]="'medium1'">1:1</mat-radio-button>
                          <mat-radio-button [value]="'medium2'">16:9</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="preview" *ngIf="imgAsChip">
                  <p class="content">{{ labels?.Preview }}</p>
                  <span class="image">
                    <img
                      *ngIf="imgSize == 'small1'"
                      [src]="'../../../../../assets/img/Upload-images/smallimage64-11.svg'"
                      alt=""
                    />
                    <img
                      *ngIf="imgSize == 'medium1'"
                      [src]="'../../../../../assets/img/Upload-images/image112-11.svg'"
                      alt=""
                    />
                    <img
                      *ngIf="imgSize == 'small2'"
                      [src]="'../../../../../assets/img/Upload-images/smallimage102-11.svg'"
                      alt=""
                    />
                    <img
                      *ngIf="imgSize == 'medium2'"
                      [src]="'../../../../../assets/img/Upload-images/image-169.svg'"
                      alt=""
                    />
                  </span>
                  <p class="content">Size: 64 x 64</p>
                </div>
                <!-- <div class="upload-image">
                  <span class="image-ui">Upload Images</span>
                  <div class="upload">
                    <div class="details">
                      <div class="upload-btn-wrapper">
                        <button class="btn">Upload</button>
                        <input type="file" name="myfile" />
                      </div>
                      <span class="upload-description">Upload in 1:1, 16:9 or 4:3 ratio</span>
                      <span class="upload-description">file format: svg, png, jpeg (max: 500 kb)</span>
                    </div>
                    <div class="upload-preview">
                      preview
                    </div>
                  </div>
                </div>
                <div class="addbtn">
                  <button class="btn">Add</button>
                  <div class="added-images">
                    <span class="text">Added Images</span>
                    <span class="images">
                      <div class="image">
                        <figure>
                          <img [src]="'../../../../../assets/img/Upload-images/image-169.svg'" alt="" />
                        </figure>
                        <span class="image-text">example</span>
                        <span class="cancel-btn"><i class="bi bi-x-circle"></i></span>
                      </div>
                    </span>
                  </div>
                  <div class="multiselect">
                    <section class="example-section">
                      <mat-checkbox class="example-margin">Available for Multi Select</mat-checkbox>
                    </section>
                  </div>
                </div> -->
              </div>
              <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'currency'">
                <mat-form-field appearance="standard">
                  <mat-label>Currency</mat-label>
                  <mat-select
                    class="test"
                    formControlName="defaultOption"
                    multiple
                    (selectionChange)="addDefaultValuetoAttibute()"
                    panelClass="currency-popup-wrapper"
                  >
                    <div class="search-field">
                      <input
                        placeholder="search currency"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="searchCurrency"
                      />
                    </div>
                    <mat-option *ngFor="let option of filteredOptions | filter: searchCurrency" [value]="option"
                      >{{ option?.symbol_native }} : {{ option?.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div class="currency-options" *ngIf="currencyOptions?.length > 0">
                  <div class="currency-option" *ngFor="let currency of currencyOptions; let i = index">
                    <p class="text-truncate">{{ currency?.symbol_native }} : {{ currency?.name }}</p>
                    <div>
                      <span class="pointer"
                        ><img
                          (click)="deleteCurrencyOption(i)"
                          id="defaultvalueremoveimg"
                          [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                      <button
                        [value]="currency.name"
                        class="btn btn-info"
                        [ngStyle]="{ border: def_ault === i ? '2px solid skyblue' : '1px solid #ccc' }"
                        (click)="makeDefaultCurrency(i)"
                      >
                        Make Default
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="default-currency-wrapper"
                *ngIf="
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'currency' && isDefaultCurrency
                "
              >
                <label>Default Currency Type</label>
                <p class="text-truncate">{{ attrDetails?.value?.unitType }}</p>
              </div>
              <div
                class="expected-time"
                *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'metric' || unitType"
              >
                <label>{{ labels.Type }}</label>
                <input
                  matInput
                  [placeholder]="
                    configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'currency'
                      ? labels.Currency_Type
                      : labels.Metric_Type
                  "
                  id="defaultValue"
                  type="text"
                  autocomplete="off"
                  formControlName="unitType"
                />
                <div class="metric-dimension">
                  <input
                    matInput
                    placeholder="Enter Dimension"
                    id="nodeIndependentattredit_metricDimension"
                    type="text"
                    autocomplete="off"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="attrConfiguration.metricDimension"
                  />
                  <ng-container *ngIf="attrConfiguration?.metricDimension">
                    <mat-radio-group
                      [(ngModel)]="attrConfiguration.metricDimensionPosition"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <mat-radio-button id="nodeIndependentattredit_metricDimension_subscript" value="subscript">
                        {{ labels?.subscript }}
                      </mat-radio-button>
                      <mat-radio-button id="nodeIndependentattredit_metricDimension_superscript" value="superscript">
                        {{ labels?.superscript }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </ng-container>
                  <div class="searchable">
                    <p>
                      {{ labels?.Show_label_as_suffix }}
                    </p>
                    <mat-slide-toggle
                      id="nodeIndependentattredit_suffixLabel"
                      [(ngModel)]="attrConfiguration.metricLabelSuffix"
                      [ngModelOptions]="{ standalone: true }"
                      value="true"
                    ></mat-slide-toggle>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!configurationData.data.entity?.isReserved">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.UI_Controls }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="no-label w-75">
            <mat-label>{{ labels?.Select_Ui_Control }}</mat-label>
            <mat-form-field appearance="outline">
              <mat-select
                class="uic-select arrowRborder"
                panelClass="attribute-icons select-with-search"
                (selectionChange)="selectAttrType($event, configurationData.data.attr)"
                [(ngModel)]="configurationData.data.attr.attributeType.uiElement.name"
                placeholder="Select Attribute Type"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-form-field class="d-block" appearance="outline">
                  <input
                    matInput
                    placeholder="Search"
                    [(ngModel)]="searchText"
                    (keyup)="getUiControlDataType()"
                    (keydown)="$event.stopPropagation()"
                  />
                </mat-form-field>
                <mat-option [value]="attrType?.name" *ngFor="let attrType of attributeTypes"
                  >{{ attrType?.displayName ? attrType?.displayName : attrType?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="schedul-wrap" *ngIf="attrDetails?.value?.attributeUiElement === 'location'">
            <mat-form-field>
              <mat-label>{{ labels?.Location_Type }}</mat-label>
              <mat-select [(ngModel)]="attrDetails.value.locationType">
                <mat-option *ngFor="let option of locationArray" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="
              attrDetails?.value?.attributeUiElement === 'location' &&
              attrDetails?.value?.locationType === 'Current Location'
            "
            class="isReadOnly"
          >
            <p>
              Disable Location Change
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_readCurrentLocation"
              [(ngModel)]="readCurrentLocation"
              [ngModelOptions]="{ standalone: true }"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div class="rating_color" *ngIf="attrDetails?.value?.attributeUiElement === 'rating'" id="rating_color">
            <label id="label_rating_color">{{ labels?.Choose_Star_Colour|| "Choose Star Colour" }}</label>

            <input
              id="input_rating_color"
              class="rating_color_input"
              [style.background]="ratingColor"
              [(colorPicker)]="ratingColor"
              (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
              (colorPickerClose)="onEventLog('colorPickerClose', $event)"
              (cpInputChange)="onEventLog('cpInputChange', $event)"
              (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
              (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
            />
          </div>
          <!-- For autoIdentifier UI control start -->
          <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.dataType === 'autoIdentifier'">
            <form [formGroup]="autoIdForm">
              <div class="formatWrap auto-formfields">
                <div class="labelwraps">
                  <mat-label (click)="addField('format')">{{ labels?.Format }}</mat-label>
                  <mat-label (click)="addField('date')">{{ labels?.date }}</mat-label>
                  <mat-label (click)="addField('separator')">{{ labels?.Separator }}</mat-label>
                  <mat-label (click)="addField('increment')">{{ labels?.increment }}</mat-label>
                  <mat-label (click)="addField('attribute')">{{ labels?.attribute }}</mat-label>
                </div>
                <div
                  cdkDropList
                  [cdkDropListData]="(autoIdForm?.get('formatFields'))['controls']"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)"
                >
                  <div
                    formArrayName="formatFields"
                    *ngFor="let field of (autoIdForm?.get('formatFields'))['controls']; let i = index"
                    class="example-box"
                    cdkDrag
                  >
                    <div [formGroupName]="i">
                      <div *ngIf="field.value.type === 'format'" class="autofield-flex">
                        <mat-form-field floatLabel="never">
                          <mat-label>{{ labels?.Format }}</mat-label>
                          <input
                            matInput
                            placeholder="Format"
                            formControlName="format"
                            type="text"
                            autocomplete="off"
                          />
                        </mat-form-field>
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                      <div *ngIf="field.value.type === 'date'" class="autofield-flex">
                        <mat-form-field>
                          <mat-label>{{ labels?.Choose_a_date_format }}</mat-label>
                          <mat-select formControlName="dateFormat" (selectionChange)="checkYearFormat()">
                            <mat-option *ngFor="let date of dateFormats" [value]="date">
                              {{ date }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                      <div *ngIf="field.value.type === 'attribute'">
                        <span *ngIf="field?.controls?.entAttributeName?.value">
                          {{ field.controls.entAttributeName.value }}
                          <span class="pointer"
                            ><img
                              (click)="removeAttributeSelection(field)"
                              [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                          /></span>
                        </span>
                        <div class="attributeflex-items">
                          <ul class="attribute-list attribute-list-li" *ngIf="!field.controls.entAttributeName.value">
                            <app-auto-id-nested-entity
                              [attr]="attr"
                              [entity]="configurationData.data.entity"
                              [attrName]="configurationData.data.entity.name + '.' + attr.name"
                              *ngFor="let attr of entityAttributeSelection; let attrIndex = index"
                              (attributeEmit)="selectedAttribute($event, field)"
                            ></app-auto-id-nested-entity>
                          </ul>
                          <div class="autofield-flex">
                            <mat-form-field class="">
                              <mat-select formControlName="entAttributeString">
                                <mat-option *ngFor="let string of attributeNameOptions" [value]="string.value">
                                  {{ string.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="field.value.entAttributeString !== ''">
                              <input
                                matInput
                                placeholder="Value"
                                formControlName="entAttributeStringValue"
                                type="text"
                                autocomplete="off"
                              />
                            </mat-form-field>
                            <mat-icon cdkDragHandle>reorder</mat-icon>
                            <mat-icon (click)="removeField(i)">clear</mat-icon>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="field.value.type === 'separator' && separatingOperatorSelected">
                        {{ selectorSeparated }}
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                      <div *ngIf="field.value.type === 'increment'">
                        {{ labels?.INCREMENT_ID }}
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="autoFormFields">
                  <mat-form-field *ngIf="getFinancialMonth">
                    <mat-label>{{ labels?.Financial_Month }}</mat-label>
                    <mat-select formControlName="financialMonth">
                      <mat-option *ngFor="let month of Months" [value]="month.value">
                        {{ month.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="isIncrementValue">
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.Max_Value }}</mat-label>
                      <input
                        matInput
                        placeholder="Placeholder"
                        id="maxValue"
                        type="number"
                        autocomplete="off"
                        formControlName="maxValue"
                      />
                    </mat-form-field>
                    <mat-form-field floatLabel="never">
                      <mat-label> {{ labels?.Count_Start_Parameter }}</mat-label>
                      <input
                        matInput
                        placeholder="Placeholder"
                        id="maxValue"
                        type="number"
                        autocomplete="off"
                        formControlName="countStartParameter"
                      />
                    </mat-form-field>
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.ID_Leading_Zeros }}</mat-label>
                      <input
                        matInput
                        placeholder="Placeholder"
                        id="maxValue"
                        type="number"
                        autocomplete="off"
                        formControlName="incIdLeadingZeroes"
                      />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>{{ labels?.Count_Reset_Parameter }}</mat-label>
                      <mat-select formControlName="countReset">
                        <mat-option *ngFor="let countReset of countResetParams" [value]="countReset">
                          {{ countReset }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="autoform-operators">
                  <div class="formatWrapSeparators operators-list">
                    <div
                      *ngFor="let separator of separators"
                      class="operator-circle"
                      (click)="selectSeparator(separator)"
                    >
                      <span id="selectDcdOperator">{{ separator }}</span>
                    </div>
                  </div>
                  <div *ngIf="separatingOperatorSelected" class="seletedseparator">
                    {{ labels?.Separator }} : {{ selectorSeparated }}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- For autoIdentifier UI control end -->

          <!-- form for scheduler ui-control -->
          <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.dataType === 'scheduler'">
            <form class="schedul-wrap" [formGroup]="schedulerDetails">
              <!-- For Interval -->
              <mat-form-field>
                <mat-label>{{ labels?.interval }}</mat-label>
                <mat-select
                  formControlName="interval"
                  id="schedularIntervalSelect"
                  (selectionChange)="onSelectionChange($event)"
                >
                  <mat-option *ngFor="let option of intervalOptions" [value]="option" id="schedular_{{ option }}">
                    {{ option }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- For Start Date and Time -->
              <div class="mr-25">
                <mat-form-field>
                  <label [ngClass]="{ labelMargin: schedulerDetails.value.startDate }">
                    {{ labels.start_date_and_time }}
                  </label>
                  <input matInput [ngxMatDatetimePicker]="picker" formControlName="startDate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="false"
                    [disableMinute]="false"
                    [hideTime]="false"
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>

              <!-- For End Date and Time -->
              <div class="mr-25">
                <mat-form-field>
                  <label [ngClass]="{ labelMargin: schedulerDetails.value.endDate }">
                    {{ labels.end_date_and_time }}
                  </label>
                  <input matInput [ngxMatDatetimePicker]="picker1" formControlName="endDate" readonly />
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker1
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="false"
                    [disableMinute]="false"
                    [hideTime]="false"
                  >
                  </ngx-mat-datetime-picker>
                </mat-form-field>
              </div>

              <!-- For Time -->
              <mat-form-field [hidden]="!isTime">
                <label [ngClass]="{ labelMargin: timeFormat }">
                  {{ labels.Select_Time }}
                </label>
                <p class="timedisplay">{{ timeFormat | date: 'HH:mm' }}</p>
                <input
                  style="display: none;"
                  matInput
                  [ngxMatDatetimePicker]="picker2"
                  [(ngModel)]="timeFormat"
                  formControlName="time"
                  required
                />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker2
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [stepSecond]="1"
                  [touchUi]="false"
                  [color]="'primary'"
                  [enableMeridian]="false"
                  [disableMinute]="false"
                  [hideTime]="false"
                  (dateInput)="updateNewTimeFormat($event)"
                  (dateChange)="updateNewTimeFormat($event)"
                  panelClass="hide-calendar  d-none"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>

              <!-- For Day -->
              <mat-form-field [hidden]="!isDay">
                <mat-label>{{ labels?.Day }}</mat-label>
                <div>
                  <input
                    matInput
                    placeholder="{{ labels?.Enter_Value }}"
                    id="formatDay"
                    type="text"
                    autocomplete="off"
                    formControlName="day"
                  />
                </div>
              </mat-form-field>

              <!-- For Increment Value -->
              <mat-form-field>
                <mat-label>{{ labels.Increment_Value }}</mat-label>
                <input
                  matInput
                  placeholder="{{ labels?.Enter_Value }}"
                  id="formatSchedular"
                  type="number"
                  autocomplete="off"
                  formControlName="incrementValue"
                />
              </mat-form-field>
              <div class="orline">{{ labels?.or }}</div>

              <!-- For Recurrence Expression -->
              <mat-form-field class="w-100">
                <mat-label>{{ labels?.recurrenceExpression }}</mat-label>
                <input
                  matInput
                  placeholder="{{ labels?.Enter_Value }}"
                  id="formatRecurrenceExpression"
                  type="text"
                  autocomplete="off"
                  formControlName="recurrenceExpression"
                />
              </mat-form-field>
            </form>
          </div>
          <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'labelwithimage'">
            <app-file-upload
              [fileUploadFromEntity]="true"
              [editFileUrl]="editFileUrl"
              (fileUploadResponse)="fileUploadUrl2($event)"
              (deletedFileResponse)="deletedFileResponse($event)"
              [isMulti]="multiDefaultValue"
              [fileType]="'image'"
              [isDesignTime]="true"            >
            </app-file-upload>
          </div>
          <div
            *ngIf="
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'chipmultipleselection' ||
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'chipsingleselection'
            "
          >
            <p>{{ labels?.chip_with_image }} {{ chipImageUrl?.length }}/{{ sourceValues?.length }}</p>
            <div class="independent-attr-upload-wrapper">
              <div class="multi-upload">
                <p>{{ labels?.upload }}</p>
                <button
                  class="upload-add"
                  id="multipleImageUpload"
                  (click)="uploadMultipleFiles($event)"
                  [disabled]="disableAddButton"
                >
                  {{ labels?.add }}
                </button>
              </div>

              <app-file-upload
                class="file-upload"
                [fileUploadFromEntity]="true"
                [editFileUrl]="editChipImageUrl"
                (fileUploadResponse)="chipImageUpload($event)"
                (deletedFileResponse)="deletedFileResponse($event)"
                [isMulti]="true"
                [isDesignTime]="addButtonClicked"
                [fileType]="'image'"
              >
              </app-file-upload>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!configurationData.data.entity?.isReserved">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormVideo' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'payment' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'currentdatetime' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'audio' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'currentdate' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormProgress' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'signature' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'password' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormProgress' &&
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'imageavatar' &&
                configurationData?.data.attr?.attributeType?.uiElement?.uiElement != 'labelwithimage' &&
                configurationData?.data.attr?.attributeType?.uiElement?.uiElement != 'chipsingleselection'
              "
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Validation }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- <div class="form-group">
                <label>Select to Display</label>
                <div class="d-flex">
                  <mat-checkbox>Mandatory</mat-checkbox>
                </div>
              </div> -->
              <div class="no-label w-75">
                <mat-form-field appearance="outline">
                  <mat-label>{{ labels?.Select_Validators }}</mat-label>
                  <mat-select class="uic-select" id="validatorsSelectDropdown" [(ngModel)]="selectedUiValidator">
                    <mat-option
                      *ngFor="let validators of UiValidatorDropdown?.constraints; let i = index"
                      [value]="validators.name"
                      [class.disabled]="validators.disabled"
                      id="validatorsSelected_{{ validators.name }}"
                      (click)="validatorsChange(validators, i)"
                    >
                      {{ validators.displayName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngIf="selectedValidatorsList && selectedValidatorsList?.length">
                  <div
                    *ngFor="let selected of selectedValidatorsList; let j = index"
                    class="selected-validate expected-time"
                  >
                    <div class="labelbtn">
                      <label class="mb-2">{{ selected.displayName }}</label>
                      <button mat-stroked-button class="cstm-matbtn" (click)="removeValidator(selected, j)">
                        {{ labels?.Remove }}
                      </button>
                    </div>
                    <div *ngFor="let value of selected?.properties" class="selected-validate-input">
                      <div *ngIf="value?.name && value?.name != 'type'">
                        <input
                          matInput
                          class="cstm-input-text"
                          placeholder="{{ value?.message ? value?.message : value?.name }}"
                          id="{{ value.name }}"
                          type="text"
                          [(ngModel)]="value.message"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <mat-form-field class="pt-0" id="constraint_type_{{ j }}">
                      <mat-label>{{ labels?.constraint_type }}</mat-label>
                      <mat-select [(ngModel)]="selected.selectType" (change)="typeChange(selected.selectType, j)">
                        <mat-option id="info" value="INFO">{{ labels?.Info }}</mat-option>
                        <mat-option id="blockwarn" value="NONBLOCK_WARN"> {{ labels?.Non_Block_Warn }} </mat-option>
                        <mat-option id="nonblockwarn" value="BLOCK_WARN"> {{ labels?.Block_Warn }} </mat-option>
                        <mat-option id="error" value="ERROR">{{ labels?.Error }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Metadata }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field class="description-textarea">
                <textarea matInput rows="5" placeholder="Description Text"></textarea>
              </mat-form-field>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Helper_Text }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <textarea placeholder="Enter Helper Text" [(ngModel)]="helperTextString" [maxlength]="250" rows="4" cols="35">
          </textarea>

          <label class="nopadding clrlbl"> Characters Remaining: {{ helperTextString.length }}/250</label>
          <div class="Position">
            <span class="heading">{{ labels?.Position }}</span>
            <div
              class="boxes"
              [ngClass]="helperTextString == '' || attrConfiguration.showHelperTextIcon ? 'events-none' : ''"
            >
              <div
                class="box"
                (click)="selectedPosition('bottom')"
                [ngClass]="helperTextPostion == 'bottom' && !attrConfiguration.showHelperTextIcon ? 'box-active' : ''"
              >
                <span class="text-content top-text">&nbsp;</span>
                <span class="text-box"></span>
                <span class="text-content bottom-text">{{ labels?.Bottom_Helper_Text }}</span>
              </div>
              <div
                class="box"
                (click)="selectedPosition('top')"
                [ngClass]="helperTextPostion == 'top' && !attrConfiguration.showHelperTextIcon ? 'box-active' : ''"
              >
                <span class="text-content top-text">{{ labels?.Top_Helper_Text }}</span>
                <span class="text-box"></span>
                <span class="text-content bottom-text">&nbsp;</span>
              </div>
            </div>
          </div>
          <div
            class="Choose"
            [ngClass]="helperTextString == '' || attrConfiguration.showHelperTextIcon ? 'events-none' : ''"
          >
            <h5 class="heading">{{ labels?.Font_Colour }}</h5>
            <div class="color-boxes">
              <div
                class="color-box color1"
                (click)="selectedColor('#000000')"
                [ngClass]="{
                  active: helperTextColor == '#000000' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color2"
                (click)="selectedColor('#323232')"
                [ngClass]="{
                  active: helperTextColor == '#323232' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color3"
                (click)="selectedColor('#666666')"
                [ngClass]="{
                  active: helperTextColor == '#666666' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color4"
                (click)="selectedColor('#999999')"
                [ngClass]="{
                  active: helperTextColor == '#999999' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color5"
                (click)="selectedColor('#CB1FCB')"
                [ngClass]="{
                  active: helperTextColor == '#CB1FCB' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color6"
                (click)="selectedColor('#9900FF')"
                [ngClass]="{
                  active: helperTextColor == '#9900FF' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color7"
                (click)="selectedColor('#741B47')"
                [ngClass]="{
                  active: helperTextColor == '#741B47' && !attrConfiguration.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color8"
                (click)="selectedColor('#5B0F00')"
                [ngClass]="{
                  active: helperTextColor == '#5B0F00' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color9"
                (click)="selectedColor('#4A86E8')"
                [ngClass]="{
                  active: helperTextColor == '#4A86E8' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color10"
                (click)="selectedColor('#0000FF')"
                [ngClass]="{
                  active: helperTextColor == '#0000FF' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color11"
                (click)="selectedColor('#1C4587')"
                [ngClass]="{
                  active: helperTextColor == '#1C4587' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color12"
                (click)="selectedColor('#073763')"
                [ngClass]="{
                  active: helperTextColor == '#073763' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color13"
                (click)="selectedColor('#274E13')"
                [ngClass]="{
                  active: helperTextColor == '#274E13' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
              <div
                class="color-box color14"
                (click)="selectedColor('#FFFFFF')"
                [ngClass]="{
                  active: helperTextColor == '#FFFFFF' && !attrConfiguration?.showHelperTextIcon
                }"
              ></div>
            </div>
            <h5 class="heading">Font Size</h5>
            <div class="font-boxes">
              <div
                class="font-box"
                (click)="selectedFont('10px')"
                [ngClass]="{
                  active: helperTextFont == '10px' && !attrConfiguration?.showHelperTextIcon
                }"
              >
                10 px
              </div>
              <div
                class="font-box"
                (click)="selectedFont('12px')"
                [ngClass]="{
                  active: helperTextFont == '12px' && !attrConfiguration?.showHelperTextIcon
                }"
              >
                12 px
              </div>
              <div
                class="font-box"
                (click)="selectedFont('14px')"
                [ngClass]="{
                  active: helperTextFont == '14px' && !attrConfiguration?.showHelperTextIcon
                }"
              >
                14 px
              </div>
            </div>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="attrConfiguration.showHelperTextIcon" value="true">{{
              labels?.Show_HelperText_Icon
            }}</mat-slide-toggle>
          </div>
          <div class="Choose">
            <h5 class="heading">{{ labels?.Icon_Colour }}</h5>
            <div
              class="color-boxes"
              [ngClass]="helperTextString == '' || !attrConfiguration.showHelperTextIcon ? 'events-none' : ''"
            >
              <div
                class="color-box color1"
                (click)="iconSelectedColor('#000000')"
                [ngClass]="{ active: helperTextIconColor == '#000000' }"
              ></div>
              <div
                class="color-box color2"
                (click)="iconSelectedColor('#323232')"
                [ngClass]="{ active: helperTextIconColor == '#323232' }"
              ></div>
              <div
                class="color-box color3"
                (click)="iconSelectedColor('#666666')"
                [ngClass]="{ active: helperTextIconColor == '#666666' }"
              ></div>
              <div
                class="color-box color4"
                (click)="iconSelectedColor('#999999')"
                [ngClass]="{ active: helperTextIconColor == '#999999' }"
              ></div>
              <div
                class="color-box color5"
                (click)="iconSelectedColor('#CB1FCB')"
                [ngClass]="{ active: helperTextIconColor == '#CB1FCB' }"
              ></div>
              <div
                class="color-box color6"
                (click)="iconSelectedColor('#9900FF')"
                [ngClass]="{ active: helperTextIconColor == '#9900FF' }"
              ></div>
              <div
                class="color-box color7"
                (click)="iconSelectedColor('#741B47')"
                [ngClass]="{ active: helperTextIconColor == '#741B47' }"
              ></div>
              <div
                class="color-box color8"
                (click)="iconSelectedColor('#5B0F00')"
                [ngClass]="{ active: helperTextIconColor == '#5B0F00' }"
              ></div>
              <div
                class="color-box color9"
                (click)="iconSelectedColor('#4A86E8')"
                [ngClass]="{ active: helperTextIconColor == '#4A86E8' }"
              ></div>
              <div
                class="color-box color10"
                (click)="iconSelectedColor('#0000FF')"
                [ngClass]="{ active: helperTextIconColor == '#0000FF' }"
              ></div>
              <div
                class="color-box color11"
                (click)="iconSelectedColor('#1C4587')"
                [ngClass]="{ active: helperTextIconColor == '#1C4587' }"
              ></div>
              <div
                class="color-box color12"
                (click)="iconSelectedColor('#073763')"
                [ngClass]="{ active: helperTextIconColor == '#073763' }"
              ></div>
              <div
                class="color-box color13"
                (click)="iconSelectedColor('#274E13')"
                [ngClass]="{ active: helperTextIconColor == '#274E13' }"
              ></div>
              <div
                class="color-box color14"
                (click)="iconSelectedColor('#FFFFFF')"
                [ngClass]="{ active: helperTextIconColor == '#FFFFFF' }"
              ></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>

<ng-template #defaultValueBlock let-attrDetails="attrDetails">
  <!-- Block for slider type UI control -->
  <div
    class="slider-target-div"
    *ngIf="
      attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
    "
  >
    <label class="slider-target-label">{{ labels?.Target_Value }}</label>
    <input
      matInput
      placeholder=""
      [(ngModel)]="targetValue"
      class="target-slider border-Bottom-slider"
      (keyup)="onlyNumberKey($event)"
    />
  </div>
  <div class="slider-target-div" *ngIf="attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider'">
    <label class="slider-target-label">{{ labels?.Step_Value }}</label>
    <input
      matInput
      placeholder=""
      [(ngModel)]="sliderStepValue"
      class="target-slider border-Bottom-slider"
      (keyup)="onlyNumberKey($event)"
    />
  </div>
  <!-- For default value of UI controls expect file type UI controls-->
  <div class="expected-time" *ngIf="!excludeUiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement)">
    <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement !== 'clickable'">
      <!-- Enter default value for UI control -->
      <div *ngIf="uiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement); else defaultInputBlock">
        <!-- For Boolean  -->
        <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'boolean'">
          {{ labels?.Default_Value }}:
          <mat-slide-toggle [(ngModel)]="booleanDefaultValue" (ngModelChange)="booleanValue()"> </mat-slide-toggle>
        </div>

        <!-- For Date, Year Month, Year, DateTime, Time, and Date Range -->
        <div class="nh-mb-10" *ngIf="dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement)">
          <app-datetime-type-widgets
            [type]="attrDetails?.attributeType?.uiElement?.uiElement"
            [label]="labels.Default_Value"
            [dateTypeValue]="dateTypeDefaultValue"
            [dateFormatType]="dateFormatType"
            [timeFormatType]="timeFormatType"
            [yearMonthFormatType]="yearMonthFormatType"
            [customDateFormat]="customDateFormat"
            (outputValue)="updateValue($event, null, 'default')"
          ></app-datetime-type-widgets>
        </div>

        <!-- For Duration -->
        <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'duration'" class="duration">
          <label>{{ labels.Default_Value }}</label>
          <table>
            <tr>
              <td>
                <input
                  type="number"
                  id="duration_days"
                  placeholder="00"
                  [(ngModel)]="durationDefaultValue.days"
                  class="time-input"
                />
                {{ labels?.Days }} :
              </td>
              <td>
                <input
                  type="number"
                  id="duration_hours"
                  placeholder="00"
                  min="0"
                  max="23"
                  [(ngModel)]="durationDefaultValue.hours"
                  (ngModelChange)="checkDuration()"
                  class="time-input"
                />
                {{ labels?.Hours }} :
              </td>
              <td>
                <input
                  type="number"
                  id="duration_minutes"
                  placeholder="00"
                  min="0"
                  max="59"
                  [(ngModel)]="durationDefaultValue.minutes"
                  (ngModelChange)="checkDuration()"
                  class="time-input"
                />
                {{ labels?.Minutes }}
              </td>
            </tr>
          </table>
        </div>

        <!-- Rich Text -->
        <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'editor'" class="text-editor">
          <mat-label>{{ labels.Default_Value }}</mat-label>
          <angular-editor [(ngModel)]="defaultValue" id="angularEditor" [config]="EditorConfig"></angular-editor>
        </div>

        <!-- MMI Location -->
        <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'MMILocation'" class="MMILocation">
          <label>{{ labels?.Default_Value }}</label>
          <input
            type="number"
            id="nodeIndependentattredit_mmiDefaultlat"
            matInput
            placeholder="Latitude"
            [(ngModel)]="mmiDefaultLat"
          />
          <input
            type="number"
            id="nodeIndependentattredit_mmiDefaultlon"
            matInput
            placeholder="Longitude"
            [(ngModel)]="mmiDefaultLon"
          />
        </div>

        <div
          *ngIf="
            attrDetails?.attributeType?.uiElement?.uiElement !== 'boolean' &&
            attrDetails?.attributeType?.uiElement?.uiElement !== 'basicSlider' &&
            attrDetails?.attributeType?.uiElement?.uiElement !== 'inputSlider' &&
            attrDetails?.attributeType?.uiElement?.uiElement !== 'rangeSlider' &&
            attrDetails?.attributeType?.uiElement?.uiElement !== 'stepSlider' &&
            attrDetails?.attributeType?.uiElement?.uiElement !== 'verticalSlider'
          "
        >
          <!-- Add button for adding default values -->
          <button
            *ngIf="!(!multiDefaultValue && allDefaultValues?.length > 0 && !isEditable)"
            mat-stroked-button
            type="button"
            class="addDefaultValBtn"
            id="Default_Value"
            (click)="addDefaultValue()"
          >
            {{ labels.Add }}
          </button>

          <!-- Show all default values of UI control -->
          <div class="d-flex added nh-mb-16 default-values" *ngFor="let attrvalue of allDefaultValues; let i = index">
            <p
              *ngIf="
                !dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) &&
                attrDetails?.attributeType?.uiElement?.uiElement !== 'duration'
              "
            >
              {{ attrvalue }}
            </p>
            <p 
                *ngIf="
                  !customDateFormat &&
                  (attrDetails?.attributeType?.uiElement?.uiElement == 'date'
                  || attrDetails?.attributeType?.uiElement?.uiElement == 'datetime'
                  || attrDetails?.attributeType?.uiElement?.uiElement == 'dateonly'
                  || attrDetails?.attributeType?.uiElement?.uiElement == 'newdate'
                  || attrDetails?.attributeType?.uiElement?.uiElement == 'newdatetime')
                "
              >
                <p-calendar
                  [(ngModel)]="displayDateDFValue"
                  [dateFormat]="dateFormatType"
                  [showTime]="true"
                  [hourFormat]="timeFormatType == '24-hr' ? 24 : 12"
                  [ngModelOptions]="{ standalone: true }"
                  class="p-cal-show-value"
                ></p-calendar>
              </p>
              <p
                *ngIf="
                  (dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement)
                  && !customDateFormat 
                  && attrDetails?.attributeType?.uiElement?.uiElement != 'date'
                  && attrDetails?.attributeType?.uiElement?.uiElement != 'datetime'
                  && attrDetails?.attributeType?.uiElement?.uiElement != 'dateonly'
                  && attrDetails?.attributeType?.uiElement?.uiElement != 'newdate'
                  && attrDetails?.attributeType?.uiElement?.uiElement != 'newdatetime'
                  ) ||
                  attrDetails?.attributeType?.uiElement?.uiElement === 'duration'
                "
              >
                {{ displayDateDefaultValue(attrvalue, attrDetails?.attributeType?.uiElement?.uiElement) }}
              </p>
              <p *ngIf="dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) && customDateFormat">
                <p-calendar
                  [minDate]="minDateCustomFormatDate"
                  [dateFormat]="customDateFormat"
                  [ngModelOptions]="{ standalone: true }"
                  class="p-cal-show-value"
                ></p-calendar>
              </p>
            <div>
              <span class="pointer"
                ><img
                  (click)="removeDefaultValue(i)"
                  id="defaultvalueremoveimg"
                  class="defaultvalueremoveimg"
                  src="assets/img/delete-icon.svg"
                  alt=""
              /></span>
              <span>
                <img
                  (click)="editDefaultValue(i)"
                  class="edit-icon"
                  [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe"
                  alt="Edit"
                />
              </span>
            </div>
          </div>
        </div>

        <!--Slider default value-->
        <div
          *ngIf="
            attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
            attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
            attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
            attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
            attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
          "
        >
          <label>{{ labels.Default_Value }}</label>
          <input
            matInput
            class="add-value-default"
            name="defaultValue"
            placeholder=""
            id="defaultValue_slider"
            type="number"
            autocomplete="off"
            [(ngModel)]="defaultValue"
          />

          <!-- Add button for adding default values -->
          <button
            *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
            mat-stroked-button
            type="button"
            class="addDefaultValBtn"
            id="Slider_Default_Value"
            (click)="sliderDefaultValue()"
          >
            {{ labels.Add }}
          </button>
          <!-- Show all default values of UI control -->
          <div *ngIf="allDefaultValues?.length > 0">
            <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
              {{ attrvalue }}
              <span class="pointer"
                ><img
                  (click)="removeDefaultValue(i)"
                  id="sliderdefaultvalueremoveimg"
                  [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
              <span>
                <img
                  (click)="editDefaultValue(i)"
                  class="edit-icon"
                  [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe"
                  alt="Edit"
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <ng-template #defaultInputBlock>
        <label>{{ labels.Default_Value }}</label>
        <input
          matInput
          class="add-value-default"
          name="defaultValue"
          placeholder="Default Value"
          id="defaultValue"
          type="text"
          autocomplete="off"
          [(ngModel)]="defaultValue"
          (keyup.enter)="addDefaultValue()"
        />

        <!-- Add button for adding default values -->
        <button
          *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
          mat-stroked-button
          type="button"
          class="addDefaultValBtn"
          id="Default_Value"
          (click)="addDefaultValue()"
        >
          {{ labels.Add }}
        </button>

        <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement == 'text'">
          <div>
            <mat-checkbox [(ngModel)]="uppercase" [disabled]="lowercase">{{ labels?.UppercaseOnly }}</mat-checkbox>
          </div>
          <div>
            <mat-checkbox [(ngModel)]="lowercase" [disabled]="uppercase">{{ labels?.LowercaseOnly }}</mat-checkbox>
          </div>
          <div>
            <mat-checkbox [(ngModel)]="alphanumeric">{{ labels?.alphanumeric }}</mat-checkbox>
          </div>
        </div>

        <!-- Show all default values of UI control -->
        <div>
          <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
            {{ attrvalue }}
            <span class="pointer"
              ><img (click)="removeDefaultValue(i)" id="defaultvalueremoveimg" src="assets/img/delete-icon.svg" alt=""
            /></span>
            <span>
              <img
                (click)="editDefaultValue(i)"
                class="edit-icon indAttr-editIcon"
                [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe"
                alt="Edit"
              />
            </span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Block for File type UI control -->
  <!-- <div
    class="custom-radio-btns multi-file-upload"
    *ngIf="
      attrDetails?.attributeType?.uiElement?.uiElement == 'file' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'image' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'audio' ||
      attrDetails?.attributeType?.uiElement?.uiElement == 'video'
    "
  >
    <label style="margin-right: 60%;"> Upload {{ attrDetails.value.attributeUiElement }} </label>
    <app-file-upload
      [fileUploadFromEntity]="true"
      [editFileUrl]="editFileUrl"
      [isMulti]="multiDefaultValue"
      [fileType]="attrDetails.value.attributeUiElement"
      (deletedFileResponse)="deletedFileResponse($event)"
      (fileUploadResponse)="fileUploadUrl($event)"
    >
    </app-file-upload>
  </div> -->

  <div class="nh-mb-16">
    <!-- For date type UI controls -->
    <div
      *ngIf="
        attrDetails?.attributeType?.uiElement?.uiElement === 'date' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'datetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newdate' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newdatetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'currentdate' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'currentdatetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'dateRange' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'dateonly'
      "
    >
      <mat-form-field appearance="fill" [ngClass]="{ disabled: customDateFormat }">
        <mat-label class="label-txt">{{ labels?.Select_Date_Format }}</mat-label>
        <mat-select id="dateFormatTypeDropdown" [(value)]="dateFormatType">
          <mat-option
            *ngFor="let dateFormat of dateFormatsForShow; let i = index"
            [value]="dateFormat"
            id="dateFormat"
            (click)="selectedDateFormat(i)"
          >
            {{ dateFormat }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- For time type UI controls -->
    <div
      *ngIf="
        attrDetails?.attributeType?.uiElement?.uiElement === 'time' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newtime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'currentdatetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'datetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newdatetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'timeonly'
      "
    >
      <mat-form-field appearance="fill" [ngClass]="{ disabled: customDateFormat }">
        <mat-label class="label-txt">{{ labels?.Select_Time_Format }}</mat-label>
        <mat-select id="timeFormatTypeDropdown" [(value)]="timeFormatType">
          <mat-option
            *ngFor="let timeFormat of timeFormatsForShow; let i = index"
            [value]="timeFormat"
            id="timeFormat"
            (click)="selectedTimeFormat(i)"
          >
            {{ timeFormat }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- For yearMonth UI control -->
    <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'yearMonth'">
      <mat-form-field appearance="fill">
        <mat-label class="label-txt">{{ labels?.Select_Date_Format }}</mat-label>
        <mat-select id="yearMonthFormatTypeDropdown" [(value)]="yearMonthFormatType">
          <mat-option
            *ngFor="let yearMonthFormat of yearMonthFormatsForShow; let i = index"
            [value]="yearMonthFormat"
            id="yearMonthFormat"
            (click)="selectedYearMonthFormat(i)"
          >
            {{ yearMonthFormat }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Custom Format for Date and Time related UI controls-->
    <div
      *ngIf="
        attrDetails?.attributeType?.uiElement?.uiElement === 'date' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'datetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newdate' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newdatetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'currentdate' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'currentdatetime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'dateRange' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'dateonly' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'time' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'newtime' ||
        attrDetails?.attributeType?.uiElement?.uiElement === 'timeonly'
      "
    >
     <mat-form-field appearance="fill" id="custom-format" class="custom-format" [ngClass]="{ disabled: !entityOpen }">
        <mat-label class="label-txt">Custom Format</mat-label>
        <input
          matInput
          class="time_limit_Seconds"
          placeholder="{{ getPlaceholder(attrDetails?.attributeType?.uiElement?.uiElement) }}"
          id="custom-format-input"
          [(ngModel)]="customDateFormat"
          (ngModelChange)="changeFormat()"
          type="text"
          autocomplete="off"
          [ngModelOptions]="{ standalone: true }"
        />
      </mat-form-field>

      <span *ngIf="customDateFormat"> 
        <span>Ex: </span>
        <p-calendar
          [(ngModel)]="currentDateForCustomDateFormatEx"
          [dateFormat]="customDateFormat"
          [ngModelOptions]="{ standalone: true }"
          class="p-cal-show-value"
        ></p-calendar> 
      </span>
    </div>
  </div>
</ng-template>
<ng-template #fileUrlContainer let-entity="entity">
  <input
    matInput
    [(ngModel)]="UrlDefaultValue"
    type="text"
    class="fileUrl-input"
    id="stream_video_code_input"
    placeholder="Default Value"
  />

  <button
    *ngIf="!(!multiDefaultValue && fileUrl.length > 0)"
    class="validate"
    id="Default_Value"
    (click)="setUrlDefaultValue()"
  >
    {{ labels.Add }}
  </button>
  <div class="added editdefaultvalue" *ngFor="let attrvalue of fileUrl; let attrValueIndex = index">
    <span class="link_url_file">{{ attrvalue }}</span>
    <div class="edit-deleticons">
      <span class="pointer"
        ><img
          (click)="removefileUrlValue(attrValueIndex)"
          id="defaultvalueremoveimg"
          [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
          alt=""
      /></span>
    </div>
  </div>
</ng-template>
