<button class="filter-btn" *ngIf="openFilter == false" (click)="openFilter = !openFilter">
  <img src="../../assets/img/mylibrary/filter.svg" />
  {{ labels?.Filter }}
</button>

<!-- <mat-menu #menu="matMenu">
  <section class="shelf-outer entity-1">
  <button mat-button [matMenuTriggerFor]="menu" *ngIf="!hideAllOptions">Filter</button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of filterOptions">
      <button mat-menu-item (click)="changeTemplate(item)">{{ item?.name }}</button>
    </ng-container>
  </mat-menu> -->
<div class="filterwrap-entity" *ngIf="filterData && openFilter">
  <div>
    <div class="filter-header">
      <button class="filter-btn">
        <img src="../../assets/images/mcc/filter.svg" />
        {{ labels?.Filter }}
      </button>

      <button class="close-icon" (click)="openFilter = false">
        <img src="../../assets/images/mcc/remove.svg" />
      </button>
    </div>
    <div class="filter-content">
      <div class="input-section d-none">
        <input class="input-field" placeholder="Search" />
        <figure class="input-img">
          <img src="../../../../../assets/images/mcc/search-icon.svg" alt="" />
        </figure>
      </div>
      <!-- <mat-icon class="close-icon" *ngIf="!filterData.isMccLayout && filterData.tempIsMCC" (click)="closeFilter()"
        >close</mat-icon> -->

      <div class="entity-tabs">
        <!-- <h3
          class="entity-title btn-tooltip"
          pTooltip="{{ (filterData.cardDetails?.childView?.attributeList)[0]?.name }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
        >
          {{ (filterData.cardDetails?.childView?.attributeList)[0]?.name }}
        </h3> -->
        <div class="entity-filter-accordion">
          <p-accordion [multiple]="true">
            <p-accordionTab [header]="labels?.Filter" *ngIf="filterData.cardDetails?.filterBy.length > 0">
              <div
                class="group-accordion-list"
                *ngFor="let group of filterData.groupFilterDataBy | keyvalue; let i = index"
              >
                <p-accordion [multiple]="true" class="group-accordion">
                  <p-accordionTab header="{{ group.key | split: 'slot:-' + i }}">
                    <div *ngFor="let attr of group.value; let i = index">
                      <h5 class="filter-title">
                        {{ attr?.attributeDisplayName }}
                      </h5>
                      <ul class="sortulentity sort-common">
                        <li>
                          <!-- <mat-checkbox class="attr-val-chk">{{ value }}</mat-checkbox> -->
                          <mat-selection-list
                            id="sort_{{ i }}"
                            [(ngModel)]="filterData.filterValues[attr?.attributeName]"
                            (selectionChange)="onSelectionChange($event, attr.filtervalues)"
                          >
                            <mat-list-option
                              checkboxPosition="before"
                              *ngFor="let option of attr.filtervalues"
                              [value]="option.name"
                              id="sort_{{ option.name }}"
                            >
                              {{ option.displayValue }} ({{ option.count }})
                            </mat-list-option>
                          </mat-selection-list>
                        </li>
                      </ul>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </p-accordionTab>
            <p-accordionTab [header]="labels?.Sort" *ngIf="filterData.cardDetails?.sortBy.length > 0">
              <div class="attr-list">
                <ul class="sortulentity sort-section">
                  <li>
                    <mat-radio-group aria-label="Select sort option" [(ngModel)]="filterData.sortOption">
                      <mat-radio-button [value]="1">Ascending</mat-radio-button>
                      <mat-radio-button [value]="2">Descending</mat-radio-button>
                    </mat-radio-group>
                  </li>
                </ul>

                <div class="attr-list-sort sort-common">
                  <mat-radio-group aria-label="Select sort option" [(ngModel)]="filterData.sortOptionAttr">
                    <mat-radio-button
                      [value]="attr?.attributeName"
                      *ngFor="let attr of filterData.cardDetails?.sortBy; let i = index"
                      >{{ attr?.attributeDisplayName }}</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab [header]="labels?.Range + ' ' + labels?.Filter" *ngIf="filterData.rangeData.length > 0">
              <div class="attr-list" *ngFor="let attr of filterData.rangeData; let i = index">
                <h5 class="filter-title">
                  {{ attr?.attributeDisplayName }}
                </h5>
                <ul class="range-list">
                  <li>
                    <p-slider [(ngModel)]="attr.rangeArray" [range]="true"></p-slider>
                    <div class="range-input">
                      <span class="input-box">{{
                        parseIntDuplicate(attr.minimumValue) +
                          ((attr.maximumValue - attr.minimumValue) * (attr?.rangeArray)[0]) / 100 | numberSuffix: 2
                      }}</span>
                      <span class="input-box">{{
                        parseIntDuplicate(attr.minimumValue) +
                          ((attr.maximumValue - attr.minimumValue) * (attr?.rangeArray)[1]) / 100 | numberSuffix: 2
                      }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </div>

    <div class="filter-footer">
      <div
        class="bottom-icon d-flex justify-content-center align-items-center"
        *ngIf="
          filterData.cardDetails?.sortBy.length > 0 ||
          filterData.cardDetails?.filterBy.length > 0 ||
          filterData.rangeData.length > 0
        "
      >
        <div class="position-relative d-flex flex-column justify-content-center align-items-center">
          <button
            class="cursor-pointer d-flex justify-content-center align-items-center rounded-circle"
            (click)="resetFilterData()"
          >
            <em class="bi bi-arrow-clockwise"></em>
          </button>
          <span class>Reset</span>
        </div>
        <div class="position-relative d-flex flex-column justify-content-center align-items-center">
          <button
            class="cursor-pointer d-flex justify-content-center align-items-center rounded-circle"
            (click)="applyFilterAndSort(false)"
          >
            <em class="bi bi-check2"></em>
          </button>
          <span>Apply</span>
        </div>
      </div>
    </div>
  </div>
</div>
