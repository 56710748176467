import {
  Directive,
  HostListener,
  ElementRef,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

import { IStyle, ICardStyle } from '@common-services';

@Directive({
  selector: '[CardDesignConfig]',
})
export class CardDesignConfigDirective implements OnChanges {
  @Input() isAttributeLevel: boolean = false;
  @Input() isEntityLevel: boolean = true;
  @Input() cardDisplay: string;
  @Input() attrHoveredColors: any;
  @Input() cardHoveredColor: string;
  @Input() cardColor: string;
  @Input() cardShadow: string;
  @Input() cardBorderSize: number;
  @Input() cardBorderRadius: number;
  @Input() cardBorderColor: string;
  @Input() attributeColors: string[];
  @Input() btnColors: string[];
  @Input() conditionArray: any = [];
  @Input() card: any;
  @Input() singleRecord: any;
  // Expand card details
  @Input() expandDetails: any;
  @Input() expandedCardDetail: any;
  @Input() cardStyle: ICardStyle;
  otherStyles: any = {};
  @Input() set isEdit(value: boolean) {
    if (value) {
      this.editStyle();
    }
  }
  @Input() set _otherStyle(value: any) {
    this.otherStyles = value;
  }
  cardHovered: boolean = false;
  private leafNodes: HTMLElement[] = [];
  private buttonLeafNodes: HTMLElement[] = [];

  appendColors(): void {
    let l: number = this.htmlElement.children.length;
    /*istanbul ignore else*/
    if (l != 0) {
      for (let i = 0; i < l; i++) {
        let childElement: HTMLElement = this.htmlElement.children[i] as HTMLElement;
        if (childElement?.classList.value.includes('template-btns')) {
          this.appendLeafNodes(childElement, true);
        } else {
          this.appendLeafNodes(childElement, false);
        }
      }
      this.changeColor();
      // this.changeButtonColors();
    }
  }
  changeHoverColor(leafNode: HTMLElement, label: string) {
    if (this.isAttributeLevel) {
      if (label == 'over' && this.attrHoveredColors?.[0]?.length > 0) {
        leafNode.style.color = this.attrHoveredColors[0];
      } else if(this.conditionArray.length == 0) {
         leafNode.style.color = this.attributeColors[0];
      }
    } else {
      if (this.expandedCardDetail) {
        let index: number;
        Object.entries(this.expandDetails).forEach(([key, value]) => {
          if (
            leafNode.innerText.trim() == value ||
            leafNode.innerText.trim() == this.expandedCardDetail?.[0]?.[String(value)]
          ) {
            index = parseInt(key);
          }
        });
        if (label == 'over') {
          leafNode.style.color = this.attrHoveredColors[index];
        } else {
          leafNode.style.color = this.attributeColors?.[index];
        }
      } else {
        const index = this.card.recordData.findIndex(
          (attr: any) => attr.attributeName == leafNode.innerText || attr.value == leafNode.innerText
        );
        if (index != -1) {
          if (label == 'over') {
            leafNode.style.color = this.attrHoveredColors?.[index];
          } else {
            leafNode.style.color = this.attributeColors?.[index];
          }
        }
      }
    }
  }

  changeColor(): void {
    let index = 0;
    /*istanbul ignore next*/
    this.leafNodes.forEach((leafNode: HTMLElement) => {
      leafNode.style.color = this.attributeColors[index];
      leafNode.addEventListener('mouseover', () => {
        this.changeHoverColor(leafNode, 'over');
      });
      leafNode.addEventListener('mouseout', () => {
        this.changeHoverColor(leafNode, 'out');
      });
      this.applyOtherStyles(leafNode, index);
      this.conditionArray?.forEach((condition: any) => {
        this.applyColorToCondition(condition, leafNode, index, this.singleRecord);
      });
      if (leafNode?.className?.includes('passcolor')) {
        index--;
      }
      index++;
    });
  }

  applyOtherStyles(leafNode: HTMLElement, index: number) {
    let styleKeys: string[] = Object.keys(this.otherStyles?.[index] ? this.otherStyles[index] : {});
    styleKeys.forEach((key: string) => {
      switch (key) {
        case 'font-size':
          leafNode.style.fontSize = this.otherStyles[index][key];
          break;
        case 'font-style':
          leafNode.style.fontStyle = this.otherStyles[index][key];
          break;
        case 'font-weight':
          leafNode.style.fontWeight = this.otherStyles[index][key];
          break;
        case 'font-family':
          leafNode.style.fontFamily = this.otherStyles[index][key];
          break;
        case 'text-align':
          leafNode.style.textAlign = this.otherStyles[index][key];

        default:
          break;
      }
    });
  }
  applyColorToCondition(condition: any, leafNode: HTMLElement, index: number, singleRecord?: any): void {
    if (this.isAttributeLevel && singleRecord) {
      if (singleRecord?.attributeName === condition?.attribute || singleRecord?.name === condition?.attribute  ) {
        this.applyParticularCondition(singleRecord, condition, leafNode);
      }
    } else {
      this.card?.recordData?.forEach((record: any) => {
        if (record?.attributeName === condition?.attribute) {
          this.applyParticularCondition(record, condition, leafNode);
        }
      });
    }
  }
  applyParticularCondition(record: any, condition: any, leafNode: any): void {
    record.value = typeof condition?.value === 'number' ? parseInt(record?.value) : record?.value;
    if (condition?.condition === '==') {
      if (condition.value == record.value) {
        leafNode.style.color = condition.color;
      }
    } else if (condition?.condition === '!=') {
      if (condition.value != record.value) {
        leafNode.style.color = condition.color;
      }
    } else if (condition?.condition === '<=') {
      if (condition.value >= record.value) {
        leafNode.style.color = condition.color;
      }
    } else if (condition?.condition === '>=') {
      if (condition.value <= record.value) {
        leafNode.style.color = condition.color;
      }
    } else if (condition?.condition === '<') {
      if (condition.value > record.value) {
        leafNode.style.color = condition.color;
      }
    } else if (condition?.condition === '>') {
      if (condition.value < record.value) {
        leafNode.style.color = condition.color;
      }
    }
  }
  appendLeafNodes(node: HTMLElement, isButton: boolean): void {
    if (node?.children?.length != 0) {
      let length = node.children.length;
      for (let i = 0; i < length; i++) {
        let childElement: HTMLElement = node.children[i] as HTMLElement;
        if (childElement.classList.contains('template-btns')) this.appendLeafNodes(childElement, true);
        else this.appendLeafNodes(childElement, isButton);
      }
    } else {
      /*istanbul ignore next*/
      if (node) {
        if (!isButton) this.leafNodes.push(node);
        else this.buttonLeafNodes.push(node);
      }
      return;
    }
  }

  htmlElement: HTMLElement;
  isSelcted: boolean = false;
  isHovered: boolean = false;
  backgroundColor: string = '';
  hoverBackgroundColor: string;
  @Output() emitSelectedElement: EventEmitter<number> = new EventEmitter<number>();
  @Output() emitHoverColor: EventEmitter<string> = new EventEmitter<string>();
  @Input() style: IStyle;

  @Input() set _selectedDirectiveId(value: number) {
    if (value !== -1) {
      if (!this.style?.isBorder && this.cardDisplay == 'design') {
        this.htmlElement.style.border = '1px solid rgba(217, 217, 217, 0.5)';
      }
      this.isSelcted = false;
    } else {
      this.isSelcted = true;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.cardDisplay == 'design') {
      if (changes?.style) {
        if (this.style.changedElementStyle == 'selectedColor') {
          if (this.isSelcted) {
            this.htmlElement.style.backgroundColor = this.style.changedElementValue;
            this.cardStyle.color = this.style.changedElementValue;
          }
        } else if (this.style.changedElementStyle == 'hoverBackgroundColor') {
          if (this.isSelcted) {
            this.hoverBackgroundColor = this.style.changedElementValue;
            this.cardStyle.hoverColor = this.style.changedElementValue;
            //  this.emitHoverColor.emit(this.style.changedElementValue);
          }
        } else if (this.style.changedElementStyle == 'borderSize') {
          if (this.isSelcted) {
            this.htmlElement.style.borderWidth = this.style.changedElementValue + 'px';
            this.cardStyle.borderWidth = this.style.changedElementValue;
          }
        } else if (this.style.changedElementStyle == 'borderRadius') {
          if (this.isSelcted) {
            this.htmlElement.style.borderRadius = this.style.changedElementValue + 'px';
            this.cardStyle.borderRadius = this.style.changedElementValue;
          }
        } else if (this.style.changedElementStyle == 'selectedBorderColor') {
          if (this.isSelcted) {
            this.htmlElement.style.borderColor = this.style.changedElementValue;
            this.cardStyle.borderColor = this.style.changedElementValue;
          }
        }
      }
      if (changes?.cardStyle) {
        this.editStyle();
      }
    } else {
      if (changes?.attributeColors?.currentValue?.length > 0) {
        if (this.isEntityLevel && !this.isAttributeLevel) {
          this.appendColors();
        } else if (this.isAttributeLevel) {
          this.appendLeafNodes(this.htmlElement, false);
          this.changeColor();
        }
      }
      /*istanbul ignore next*/
      if (this.cardShadow) {
        this.htmlElement.style.boxShadow = this.cardShadow ? this.cardShadow : '';
      }
      /*istanbul ignore next*/
      if (this.cardBorderSize) {
        this.htmlElement.style.borderWidth = this.cardBorderSize ? this.cardBorderSize + 'px' : '';
      }
      /*istanbul ignore next*/
      if (this.cardBorderRadius) {
        this.htmlElement.style.borderRadius = this.cardBorderRadius ? this.cardBorderRadius + 'px' : '';
      }
      /*istanbul ignore next*/
      if (this.cardBorderColor) {
        this.htmlElement.style.borderColor = this.cardBorderColor ? this.cardBorderColor : '';
      }
    }
  }
  constructor(private element: ElementRef) {
    this.htmlElement = element.nativeElement;
  }

  /**
   * @param {string}value - string value
   * '_changeShadowStyle' assigns box-shadow style to the selected template in entity board
   */
  @Input() set _changeShadowStyle(value: string) {
    if (this.isSelcted) {
      this.htmlElement.style.boxShadow = value;
      this.cardStyle.boxShadow = value;
    }
  }
  editStyle() {
    this.htmlElement.style.background = this.cardStyle?.color;
    this.htmlElement.style.borderWidth = this.cardStyle?.borderWidth ? this.cardStyle?.borderWidth + 'px' : '';
    this.htmlElement.style.borderRadius = this.cardStyle?.borderRadius ? this.cardStyle?.borderRadius + 'px' : '';
    this.htmlElement.style.borderColor = this.cardStyle?.borderColor ? this.cardStyle?.borderColor : '';
    this.htmlElement.style.boxShadow = this.cardStyle?.boxShadow;
  }

  @HostListener('click', ['$event'])
  onCLick(event: MouseEvent): void {
    if (this.cardDisplay == 'design') {
      if (!this.style?.isBorder) {
        this.htmlElement.style.border = '2px solid black';
      }
      this.emitSelectedElement.emit(-1);
    }
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: any) {
    if (this.cardDisplay == 'design') {
      this.isHovered = true;
      if (this.cardStyle?.hoverColor) this.htmlElement.style.backgroundColor = this.cardStyle?.hoverColor;
    } else {
      if (this.isEntityLevel) {
        this.htmlElement.style.backgroundColor =
          this.cardHoveredColor?.length > 0 ? this.cardHoveredColor : this.cardColor;
        event.stopPropagation();
      }
    }
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: any) {
    if (this.cardDisplay == 'design') {
      this.isHovered = false;
      if (this.cardStyle?.color) {
        this.htmlElement.style.backgroundColor = this.cardStyle?.color ? this.cardStyle?.color  : '#ffffff';
      }
    } else {
      if (this.isEntityLevel) {
        this.htmlElement.style.backgroundColor = this.cardColor ? this.cardColor : '#ffffff';
        event.stopPropagation();
      }
    }
  }
}
