import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig, displayValue } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-label',
  styleUrls: ['./label-with-image.component.scss'],
  template: `
    <form class="errorLables main-lable cstm-margin" [formGroup]="group">
    <div class="d-flex justify-content-between nh-column-gap-10">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          ><span class="main-label-inner"
          ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
        </span></label
        >
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
          [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        >
      <span
        title="Sub-Transactional CU"
        *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        class="sub-cu"
        (click)="openSubPopUp()"
      >
      </span>
      </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div class="label-image-card">
        <mat-selection-list
          [formControlName]="field.attribute?.name"
          id="{{ field?.attribute?.name }}"
          (selectionChange)="onSelectionChange($event)"
          [required]="field.isRequired"
        >
          <mat-list-option [matTooltipPosition]="'above'"
            [matTooltip]="field?.configuration?.hideTooltip ? '' : value"
            *ngFor="let value of field.value; let i = index" [value]="value">
            <figure><img [src]="finalUrls[i]" alt="labelwithimage" /></figure>
            <span>
              {{ displayValue(value) }}
            </span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </form>
  `,
  styles: [
    '.errorLables{font-size: 18px;} .cstm-margin{margin-bottom:10px;} .cstm-margin span {white-space: normal !important}',
  ],
})
export class LabelWithImageComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  imgUrls: any;
  imgUrlArray: any = [];
  finalUrls: string[] = [];
  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void {
    if (this.field?.configuration?.imgUrlWithLabel) {
      this.imgUrls = JSON.parse(this.field?.configuration?.imgUrlWithLabel);
      this.imgUrls?.forEach((url: any) => {
        this.finalUrls?.push(url?.contentUrl);
      });
    }
  }
  onSelectionChange(event: any) {
    const data = {
      attributeId: this.field.attribute['id'],
      isTableConfig: this.field.attribute['isTableConfig'],
      attrName: this.field.attribute['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    /* conditional potentiality check  */
    /* istanbul ignore else */
    if (this.field.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent(data);
    } else {
      this.eventsService.setEvent(data);
    }
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  displayValue(label: any) {
    return displayValue(label);
  }
}
