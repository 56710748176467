import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  AfterViewChecked,
  Renderer2,
  ViewChildren,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AlertService, ChatBotIcons, ChatBotStoreActions, ManageConfigurationFacadeService, NewSolutionFacadeService, SttEndpointService, TranslatorService, checkTokenExistsorNot, decodeJwtoken, setPropertyInDivLayout } from '@common-services';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, map, takeUntil } from 'rxjs/operators';
import Speech from 'speak-tts'; // es6
import { IFeedback } from './livechat/livechat-endpoint.service';
import { LivechatFacadeService } from './livechat/livechat-facade.service';
import { ChatBotMediaDialog, MediaTypes } from './chatbot-media-dialog/chatbot-media-dialog.component';
// import { typewriterEffect } from '../../../../common-services/src/generic-folder/generic-functions.ts';

@Component({
  selector: 'app-livechat-smart',
  templateUrl: './livechat-smart.component.html',
  styleUrls: ['./livechat-smart.component.scss'],
})
export class LivechatSmartComponent implements OnInit, OnChanges {
  isShown: boolean = false;
  labels: any;
  ngUnsubscribe: Subject<any> = new Subject();
  @Input() iconType: string;
  chatBotIcons = ChatBotIcons;
  messageList: any[] = [];
  question: string;
  userCurrentQuestion: any;
  token: any;
  jwtToken: string;
  userInfo: any;
  roomId: any;
  updatedChatData: any;
  openPrompt: boolean = false;
  registered: boolean = false;
  mediaTypes = MediaTypes;
  speech = new Speech();
  isAudio: boolean = false;
  tenantSpecificFlag: boolean = false;
  size: number = 10;
  @ViewChild('videoPlayerMini') videoPlayerMini: ElementRef;
  @ViewChild('target') target: ElementRef;
  @ViewChild('scroll') scrollElement: ElementRef;
  @ViewChild('picker') picker: any;
  @ViewChild('scroll') scrollPad: ElementRef;

  feedback: IFeedback = {
    token: '',
    roomId: '',
    rating: 0,
    comments: '',
    jwtToken: '',
  };
  toggleFeedback: boolean = false;
  comments: string = '';
  unreadMessages: number = 0;
  isRecordingEnabled: boolean = false;
  moreTopicsButton: boolean = false;
  notListedButton: boolean = false;
  emojiFeedbackFlag: boolean = false;
  emojiePickerFlag: boolean = false;
  botName: string = 'Kia';
  botIconUrl: string = '../../../../../assets/img/chat-kia-circle.svg';
  botResponseIconUrl: string = '../../../../../assets/img/chat-kia-circle.svg';
  // fontColour: string = '#00000';
  // primaryColour: string = '#C1BDBD';
  // secondaryColour: string = '#FFFFFF';
  setWelcome: boolean = true;
  isOnboarding: any;
  isCallBackEnabled: boolean = false;
  useEmojis: boolean = false;
  headerColour: string;
  headerTextColour: string;
  iconPrimaryColour: string;
  iconSecondaryColour: string;
  iconHoverColour: string;
  systemResponseColour: string;
  userResponseColour: string;
  systemResponseTextColour: string;
  userResponseTextColour: string;
  chatBackgroundColour: string;
  previousChatData: any[] = [];
  showIcons: boolean = false;
  agentFileName: string;
  fileName: string = '';
  minDate: Date;
  optionsCount: number = 5;
  options = ['Live transfer to the Agent ', 'Raise a ticket', 'Call Back'];
  isDCEOInterface:boolean = false;

  // DateTimeFormat: string = 'DD/MM/YYYY hh:mm';

  date: moment.Moment;
  disabled = false;
  showSpinners = true;
  showSeconds = false;
  touchUi = false;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  instant = 0;

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required]),
  });
  public dateControl = new FormControl();
  dateForm: FormGroup;

  count = 0;
  mleak: any;
  messageFor5min: string = `Since we have not received any inputs from you, we will be closing this chat. You are welcome to open a new chat with us, if you need any more details`;
  messageFor2min: string = `Are you still looking for any more information ? This chat will be closed shortly if no inputs are received`;
  messageFro1min: string = `We are waiting for your inputs. Please respond`;
  messageObject = {
    60: this.messageFro1min,
    120: this.messageFor2min,
    300: this.messageFor5min,
  };
  timeoutTocloseBot: number = 15;
  // en, te, hi;
  selectedLanguage = 'en';
  displayLanguageSettings: boolean = false;
  langulageOptions: any = [];
  hideTranscriptConditions = [
    'connected',
    'Welcome message',
    'Closed by visitor',
    'promptTranscript',
    'closing idle chats',
  ];
  isOption: boolean = false;
  tableValue: any = [];
  displaytable: any;
  @ViewChildren('botreply') botreply: any;
  @ViewChildren('chathistorybotreply') chathistorybotreply: any;
  src: any;
  enable_autosuggestion: string = 'yes';
  questionInput = new Subject();
  description: boolean = false;
  disableInput: boolean = true;
  isWelcomeMessage: boolean = false;
  option: string;
  // isDceo: boolean = false;
  static isDceo = false;
  get dceoFlag() {
    return LivechatSmartComponent.isDceo;
  }
  envType: string;
  isDCEOEnabled: boolean = false;
  dceoQueryType: string = 'general';
  isConfigurationFetched: boolean = false;
  isNumberOrDecimal: string ;
  selectedRole: any;
  isHiddenTranscript: boolean = false;
  chatbotroles: any[] = [];
  enableChatBotForRole: boolean = true;
  feedbackEnabled: boolean = true;
  dragEnabled: boolean = true;
  constructor(
    private translator: TranslatorService,
    public livechatFacade: LivechatFacadeService,
    public dialog: MatDialog,
    public alertservice: AlertService,
    private manageconfigurationservice: ManageConfigurationFacadeService,
    private router: Router,
    public elRef: ElementRef,
    private newSolutionFacade: NewSolutionFacadeService,
    private _sanitizer: DomSanitizer,
    private dialogService: MatDialog,
    public renderer: Renderer2,
    public sttService: SttEndpointService,
    public cdr: ChangeDetectorRef
  ) {
    this.detectLastChatData();
    this.detectBlobFileForRecording();
    // this.recordingFacadeService.voiceToTextInit();
    this.dateForm = new FormGroup({
      dateControl: this.dateControl,
    });
    this.minDate = new Date();
    this.detectInputAutoSuggestion();
    this.detectAutoSuggestions();
    this.detectSttFinalOutput();
    this.detectSttInterimOutput();
    this.getSelectedRoleData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setDepartment();
  }
  ngOnInit(): void {
    this.detectLanguageChange();
    this.getCredentials();
    this.updatedChatData = {
      messagesList: [],
    };
    /*istanbul ignore next*/
    this.isOnboarding = localStorage.getItem('onBoarding') == 'true' ? true : false;
    if (localStorage.getItem('selectedRole')) {
      this.selectedRole = JSON.parse(localStorage.getItem('selectedRole'));
    }
  }

  getCredentials() {
    const credentials = JSON.parse(localStorage.getItem('credentials'));
    /*istanbul ignore next*/
    this.jwtToken = credentials?.token;
    let data = decodeJwtoken(this.jwtToken);

    /*istanbul ignore next*/
    const userInfo = {
      name: data?.data?.name ? data?.data?.name : data?.data?.email,
      email: data?.data?.email,
    };
    this.userInfo = userInfo;
    this.envType = localStorage.getItem('environmentType');
  }

  detectLastChatData() {
    this.livechatFacade.updatedChatData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      console.log(res);
      let descriptionIndex: number;
      /* istanbul ignore else */
      if (res) {
        res?.messagesList?.forEach((data: any, ind: number) => {
          /* istanbul ignore else */
          if (data?.answer?.ans?.text && ind === 0) {
            this.disableInput = false;
            /* istanbul ignore else */
            if (!this.isWelcomeMessage) {
              this.isWelcomeMessage = true;
              if (!LivechatSmartComponent.isDceo) this.startTimer();
            }
          }
          /* istanbul ignore else */
          if (
            data?.answer?.media?.entity_card?.length > 0 &&
            this.IsJsonString(data?.answer?.media?.entity_card?.[0])
          ) {
            data.answer.media.entity_card[0] = JSON.parse(data?.answer?.media?.entity_card?.[0]);
            let entityId = data?.answer?.media?.entity_card?.[0]?.entity_id;
            /* istanbul ignore else */
            if (entityId) {
              this.newSolutionFacade.getCardIds([entityId]);
              this.getAllCardIds(entityId, 'updatedChatData');
            }
          }
          /* istanbul ignore else */
          this.isCallBackEnabled = data?.answer?.isCalendar ? data?.answer?.isCalendar : false;
          /* istanbul ignore else */
          if (data?.answer?.media?.file?.length > 0) {
            this.agentFileName = data?.answer?.media?.file[0].substring(
              data?.answer?.media?.file[0].lastIndexOf('/') + 1
            );
          }
          /* istanbul ignore else */
          if (data?.answer?.button && data?.answer?.button?.length > 0) {
            data?.answer?.button.forEach((i: any) => {
              /* istanbul ignore else */
              if (this.options.indexOf(i.displayText) !== -1) {
                this.isOption = true;
              }
            });
          }
          if (data?.answer?.isInternalDescription) {
            this.description = data?.answer?.isInternalDescription;
            descriptionIndex = ind;
          } else {
            this.disableInput = ind == descriptionIndex + 1 ? true : false;
          }
          this.isNumberOrDecimal = (data?.answer?.response_type == 'decimal' || data?.answer?.response_type == 'number') ? data?.answer?.response_type : '-1';
        });
        // setTimeout(() =>{
        this.updatedChatData = res;
        setTimeout(() => {
          this.updatedChatData.messagesList?.forEach((data: any, index: number) => {
            /*istanbul ignore else*/
            if (data?.answer?.read == false) {
              this.unreadMessages++;
            }
            if (data?.answer?.ans?.text && index == this.updatedChatData?.messagesList?.length - 1) {
              // this.typeWritter('ans' + index, data?.answer?.ans?.text);
            }
            /*istanbul ignore else*/
            if (data?.answer?.media?.html_table?.length > 0 && data?.answer?.media?.html_table[0] != null) {
              this.disableInput = true;
              this.displaytable = this._sanitizer.bypassSecurityTrustHtml(data?.answer?.media?.html_table[0]);
              let div_index: number;
              if (index % 2 === 1) {
                div_index = (index - 1) / 2;
              } else {
                div_index = index / 2;
              }
              const currentdiv = this.botreply._results[div_index]?.nativeElement;
              /*istanbul ignore else*/
              if (document.getElementsByClassName('html-table-parent' + index).length === 0) {
                this.renderer.addClass(currentdiv, 'html-table-parent' + index);
              }
              let ind = Object.values(document.getElementsByClassName('html-table-parent' + index)).length - 1;
              let parentEle = document.getElementsByClassName('html-table-parent' + index)[ind];
              let ele = document.createElement('div');
              let dynamicClass = `html-table${index}`;
              ele.setAttribute('class', dynamicClass);
              ele.innerHTML = this.displaytable;
              /*istanbul ignore else*/
              if (document.getElementsByClassName('html-table' + index)?.length === 0) {
                parentEle?.appendChild(ele);
                /*istanbul ignore else*/
                if (document.getElementsByClassName('html-table' + index)[0]?.innerHTML) {
                  document.getElementsByClassName('html-table' + index)[0].innerHTML = document
                    .getElementsByClassName('html-table' + index)?.[0]
                    ?.innerHTML?.split('binding: ')?.[1]
                    ?.split('(see https://g.co/ng/security#xss)')?.[0]
                    .trim();
                }
              }
            }
            this.disableInput = false;
          });
          this.cdr.detectChanges();
          this.detectScrolling();
        }, 100);
        this.textToSpeech();
        if (this.updatedChatData?.messagesList?.[this.updatedChatData?.messagesList?.length - 1]?.answer) {
          this.enable_autosuggestion = this.updatedChatData?.messagesList[
            this.updatedChatData?.messagesList?.length - 1
          ]?.answer?.enable_autosuggestion;
        } else {
          this.enable_autosuggestion = 'yes';
        }
        // },timeDelay)
      }
      this.cdr.detectChanges();
      this.detectScrolling();
    });
  }

  detectScrolling() {
    this.scrollPad.nativeElement.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'end',
    });
    this.scrollPad.nativeElement.scrollTop = this.scrollPad.nativeElement.scrollHeight;
  }

  getAllCardIds(entityId: any, chatType: string) {
    this.newSolutionFacade.cardIds$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        /* istanbul ignore next */
        if (res?.length > 0) {
          /* istanbul ignore next */
          let cardId = res[res.length - 1];
          /* istanbul ignore next */
          if (chatType === 'updatedChatData') {
            /* istanbul ignore next */
            this.updatedChatData?.messagesList?.forEach((data: any) => {
              /* istanbul ignore next */
              if (
                data?.answer?.media?.entity_card?.length > 0 &&
                data?.answer?.media?.entity_card?.[0]?.entity_id === entityId
              ) {
                data.answer.media.entity_card[0].cardId = cardId;
              }
            });
          } else if (chatType === 'previousChatData') {
            /* istanbul ignore next */
            this.previousChatData?.forEach((data: any) => {
              /* istanbul ignore next */
              if (data?.media?.entity_card?.length > 0 && data?.media?.entity_card?.[0]?.entity_id === entityId) {
                data.media.entity_card[0].cardId = cardId;
              }
            });
          }
        }
      }
    });
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  backFromCardEntity(event: any) {
    console.log(event);
  }

  connect(flag?: string) {
    this.roomId = Guid.create();
    this.token = Guid.create();
    /*istanbul ignore next*/
    if (this.tenantSpecificFlag === true) {
      this.livechatFacade.connect(
        this.token?.value,
        this.roomId,
        this.userInfo,
        this.jwtToken,
        'TenantSpecific',
        LivechatSmartComponent.isDceo ? this.dceoQueryType : undefined
      );
    } else {
      this.livechatFacade.connect(
        this.token?.value,
        this.roomId,
        this.userInfo,
        this.jwtToken,
        LivechatSmartComponent.isDceo ? this.dceoQueryType : undefined
      );
    }
    this.setRoomAndTokenIds();
    this.getLanguages();
    this.registered = true;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  guestUserChat: boolean = false;
  toggleShow() {
    this.getCredentials();
    let isGuestUser = localStorage.getItem('isGuestUser');
    if (isGuestUser == 'true') {
      this.guestUserChat = true;
    } else if (this.isRedirectFromGuestUser()) {
      this.updatedChatData.messagesList = [];
      this.registered = false;
      this.livechatFacade.resetStore();
    }
    let tenant = localStorage.getItem('TenantName');
    if (tenant === 'srsrstf') {
      this.router.navigateByUrl('/chatbot-canvas/dceo');
    } else if(this.isDCEOInterface !== false){
        this.router.navigateByUrl('/chatbot-canvas/dceo');
      } else {
        this.isShown = !this.isShown;
        this.livechatFacade.changeIsShown(this.isShown);
        /*istanbul ignore else*/
        if (this.isShown === true) {
          this.livechatFacade.resumeChatbotState();
          this.unreadMessages = 0;
          /*istanbul ignore next*/
          this.updatedChatData?.messagesList?.forEach((data: any) => {
            if (data?.answer) {
              data.answer.read = true;
            } else if (data?.question) {
              data.question.read = true;
            }
          });
        }
        /*istanbul ignore next*/
        this.updatedChatData?.messagesList?.forEach((data: any) => {
          if (data?.answer) {
            data.answer.read = true;
          } else if (data?.question) {
            data.question.read = true;
          }
        });
        /*istanbul ignore next*/
        if (this.updatedChatData?.messagesList?.length === 0 && this.registered === false && this.isShown) {
          this.connect();
        }
      }
  
  }

  sendQuestions(el?: HTMLElement) {
    /*istanbul ignore next*/
    this.question = this.question?.trim();
    /*istanbul ignore else*/
    if (this.question) {
      this.count = 0;
      this.stopTimer();
      this.startTimer();
      /*istanbul ignore next*/
      if (el !== undefined) {
        el.scrollIntoView();
      }
      if (this.question === 'Go Back to Main Menu') {
        // window.scrollTo(0, 0);
      }
      this.userCurrentQuestion = this.question;
      this.livechatFacade.send(this.token?.value, this.roomId, this.question, this.selectedLanguage);
      this.question = '';
    }
    if (this.description) {
      this.disableInput = true;
      this.description = false;
      this.alertservice.showToaster('ticket generation is in progress', '', 'success');
    }
    this.sugestions = [];
  }

  CalendarClock() {
    /*istanbul ignore next*/
    //this.dateForm.controls[dateControl].value;
    let selectedDate = this.dateForm.controls['dateControl'].value;
    const dateFormat = 'DD-MM-YYYY HH:mm';
    /*istanbul ignore else*/
    if (moment(selectedDate, dateFormat, true).isValid()) {
      let dateTime = moment(selectedDate).format('DD-MM-YYYY HH:mm');
      this.question = 'send call at ' + dateTime;
      this.sendQuestions();
      this.isCallBackEnabled = false;
    }
  }

  endChat() {
    /*istanbul ignore next*/
    if (this.updatedChatData?.messagesList?.length === 0) {
      this.toggleShow();
      this.count = 0;
      this.stopTimer();
      return;
    }
    this.openPrompt = !this.isHiddenTranscript;
    if (!this.openPrompt) {
      this.sendTranscript(false);
    }
    const tmp = {
      currentToken: '',
      previousToken: this.token,
      currentRoomId: '',
      previousRoomId: this.roomId,
    };
    localStorage.setItem('botIds', JSON.stringify(tmp));
    // localStorage.setItem('botIds', '');
    this.previousChatData = [];
    this.langulageOptions = [];
    this.sugestions = [];
    this.disableInput = true;
    this.isWelcomeMessage = false;
    this.count = 0;
    this.registered = false;
    this.stopTimer();
    /*istanbul ignore next*/
    this.livechatFacade.disconnect(this.token?.value, this.roomId);
  }

  sendTranscript(flag: boolean, el?: HTMLElement) {
    /*istanbul ignore next*/
    if (el !== undefined) {
      el.scrollIntoView();
    }
    /*istanbul ignore else*/
    if (flag) {
      /*istanbul ignore next*/
      this.livechatFacade.transcript(this.token?.value, this.roomId, this.userInfo);
    }
    this.livechatFacade.resetStore();
    this.toggleFeedback = true;
    this.openPrompt = false;
    if(!this.feedbackEnabled) this.sendFeedback(false);
  }

  /*istanbul ignore next*/
  enableAudio() {
    this.isAudio = !this.isAudio;
    if (this.isAudio === false) {
      this.speech.cancel();
    }
  }

  textToSpeech() {
    /*istanbul ignore else*/
    if (this.isAudio) {
      /*istanbul ignore next*/
      const lastElement = this.updatedChatData?.messagesList[this.updatedChatData?.messagesList?.length - 1];
      let messageToSpeak;

      /*istanbul ignore next*/
      if (lastElement?.answer) {
        if (lastElement?.answer?.response_type === 'text') {
          if (lastElement?.answer?.ans?.text.includes('</a>')) {
            let msg1 = lastElement?.answer?.ans?.text.split('</a>')[0];
            let msg2 = lastElement?.answer?.ans?.text.split('</a>')[1];
            let firstIndex = msg1.indexOf('<');
            let secondIndex = msg1.indexOf('>');
            messageToSpeak = msg1.substring(0, firstIndex) + msg1.substring(secondIndex + 1) + msg2;
          } else {
            messageToSpeak = lastElement?.answer?.ans?.text;
          }
        } /*istanbul ignore else*/ else if (lastElement?.answer?.response_type === 'suggest') {
          messageToSpeak = 'We got Suggestions for you ';
        }
      }

      /*istanbul ignore else*/
      if (messageToSpeak) {
        this.speech.speak({
          text: messageToSpeak,
          queue: false,
        });
      }
    }
  }

  /*istanbul ignore next*/
  openMediaDialog(typeOfMedia: string, sourceUrl: string) {
    if (typeOfMedia === this.mediaTypes.Video) {
      this.videoPlayerMini.nativeElement.pause();
    }
    this.speech.cancel();

    const dialogRef = this.dialog.open(ChatBotMediaDialog, {
      width: '1000px',
      height: '650px',
      data: { typeOfMedia: typeOfMedia, src: sourceUrl },
    });

    dialogRef.afterClosed().subscribe((result: any) => {});
  }

  sendButtonAction(button: IButton) {
    if (button.displayText.includes('Rephrase')) {
      return;
    }
    this.question = button.value;
    this.sendQuestions();
  }

  /*istanbul ignore next*/
  makeCall(data: any) {
    // https://meet-carnivals.nslhub.com/sample?nslhubUserName=Sairam P
    let str = this.userInfo?.name;
    str = window.btoa(str);
    data.url += '?nslhubUserName=base64_' + str;
    window.open(data?.url);
  }

  ratingAtSessionLevel(rating: number) {
    this.feedback.rating = rating;
  }

  sendFeedback(flag: boolean) {
    if (flag === true) {
      this.feedback.comments = this.comments;
      /*istanbul ignore next*/
      this.feedback.token = this.token?.value;
      /*istanbul ignore next*/
      this.feedback.roomId = this.roomId?.value;
      this.feedback.jwtToken = this.jwtToken;
      this.livechatFacade.feedBack(this.feedback);
    } else {
      this.comments = '';
      this.feedback.comments = this.comments;
      /*istanbul ignore next*/
      this.feedback.token = this.token?.value;
      /*istanbul ignore next*/
      this.feedback.roomId = this.roomId?.value;
      this.feedback.jwtToken = this.jwtToken;
      this.livechatFacade.feedBack(this.feedback);
    }
    this.comments = '';

    this.toggleFeedback = false;
    this.toggleShow();
  }

  /*istanbul ignore next*/
  // async toggleRecording() {
  //   if (this.isRecordingEnabled === false) {
  //     this.recordingFacadeService.startRecording();
  //     this.isRecordingEnabled = true;
  //   } else if (this.isRecordingEnabled === true) {
  //     this.recordingFacadeService.stopRecording();
  //     this.isRecordingEnabled = false;
  //   }
  // }

  /*istanbul ignore next*/
  detectBlobFileForRecording() {
    // this.recordingFacadeService.getRecordedBlob().subscribe((blob: any) => {
    //   if (blob) {
    //     let blobFileUrl = URL.createObjectURL(blob);
    //     let reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     reader.onloadend = () => {
    //       var base64Data = reader.result;
    //     };
    //   }
    // });
  }

  addEmoji(event: any) {
    /*istanbul ignore next*/
    this.question = `${this.question}${event?.emoji?.native}`;
    this.emojiePickerFlag = false;
  }

  fileUploaded: boolean = false;
  fileUploadFailed: string = '-1';
  fileUpload(files: FileList) {
    let newFormData = new FormData();
    newFormData.append('file', files[0]);
    this.fileUploaded = true;
    this.livechatFacade.fileUpload(newFormData, this.roomId?.value).subscribe(
      (res: any) => {
        /*istanbul ignore next*/
        let filePath = res?.url;
        filePath = `${filePath}`;
        let arr: string[] = filePath.split('.');
        let fileType: string = arr[arr.length - 1];
        let arr1: string[] = filePath.split('/');
        let fileName: string = arr1[arr1.length - 1];
        fileName = `${fileName}`;
        this.fileName = fileName;
        let data = {
          filePath: filePath,
          fileType: fileType,
          fileName: fileName,
          read: true, 
        };
        this.fileUploadFailed = 'no';
        this.livechatFacade.setChatBotState(data, ChatBotStoreActions.UpdateFile);
        this.sendQuestions();
      },
      /*istanbul ignore next*/
      (err: any) => {
        if (err && err.status === 200) {
          let filePath: string = err?.error?.text;
          filePath = `${filePath}`;
          //filePath = `${this.api.paasLivechatUrl}${filePath}`;
          let arr: string[] = filePath.split('.');
          this.fileUploadFailed = 'yes';
          let fileType: string = arr[arr.length - 1];
          let arr1: string[] = filePath.split('/');
          let fileName: string = arr1[arr1.length - 1];
          fileName = `${fileName}`;
          let data = {
            filePath: filePath,
            fileType: fileType,
            fileName: fileName,
            read: true,
          };
          this.livechatFacade.setChatBotState(data, ChatBotStoreActions.UpdateFile);
        } else {
          this.fileUploadFailed = 'yes';
          throw new Error(err);
        }
      }
    );
  }

  filedownload(fileUrl: string) {
    let data = {
      url: fileUrl,
    };
    this.fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    this.livechatFacade
      .fileDownload(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          // let dataType = res.type;
          let binaryData = [];
          binaryData.push(res.body);
          let downloadLink = document?.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', this.fileName);
          document.body.appendChild(downloadLink);
          setTimeout(() => {
            downloadLink.click();
          }, 1000);
        }
      });
  }

  record() {
    if (!this.isRecordingEnabled) {
      this.isRecordingEnabled = true;
      this.startVoiceToText();
    } else {
      this.isRecordingEnabled = false;
      this.stopVoiceToText();
    }
  }

  startVoiceToText(dceoQueryType?: string) {
    if (dceoQueryType) this.dceoQueryType = dceoQueryType;
    this.sttService.start();
  }

  stopVoiceToText() {
    this.sttService.stop();
    // this.recordingFacadeService.stopVoiceToText();
    // this.question = this.recordingFacadeService?.tempWords;
    // this.recordingFacadeService.tempWords = '';
    // this.sendQuestions();
  }

  detectSttFinalOutput() {
    this.sttService.finalResultObservable.pipe(takeUntil(this.ngUnsubscribe), delay(1000)).subscribe((res: string) => {
      if (res && !LivechatSmartComponent.isDceo) {
        this.question = res;
        this.stopVoiceToText();
        this.sendQuestions();
      }
    });
  }

  detectSttInterimOutput() {
    this.sttService.interimResultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: string) => {
      if (res && !LivechatSmartComponent.isDceo) this.question = res;
    });
  }

  ngOnDestroy() {
    this.livechatFacade.disconnect(this.token?.value, this.roomId);
  }

  clickedTopics(action: string) {
    if (action === 'more topics') {
      this.question = action;
      this.sendQuestions();
      this.moreTopicsButton = true;
    } else if (action === 'not listed') {
      this.question = 'My topic/issue is not listed here';
      this.sendQuestions();
      this.notListedButton = true;
    }
  }

  chatBotEnabled: boolean;
  isCaptureFeedbackEnabled: boolean;
  fetchDetails() {
    this.manageconfigurationservice
      .fetchBotDetails(this.tenantSpecificFlag)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result) {
          if (res?.result?.chatbotStatus == 'YES') {
            this.chatBotEnabled = true;
          } else {
            this.chatBotEnabled = false;
          }
          if (res?.result?.isCaptureFeedbackEnabled == 'YES') {
            this.isCaptureFeedbackEnabled = true;
          } else {
            this.isCaptureFeedbackEnabled = false;
          }
          if (res?.result?.botName) {
            this.botName = res?.result?.botName;
          }
          if (res?.result?.botIconUrl) {
            this.botIconUrl = res?.result?.botIconUrl;
          }
          if (res?.result?.botResponseIconUrl) {
            this.botResponseIconUrl = res?.result?.botResponseIconUrl;
          }
          if (res?.result?.useEmojis) {
            this.useEmojis = res?.result?.useEmojis;
          }
          if (res?.result?.headerColour) {
            this.headerColour = res?.result?.headerColour;
            setPropertyInDivLayout(this.elRef, '--header-color', this.headerColour);
          }
          if (res?.result?.headerTextColour) {
            this.headerTextColour = res?.result?.headerTextColour;
            setPropertyInDivLayout(this.elRef, '--headerText-color', this.headerTextColour);
          }
          if (res?.result?.iconPrimaryColour) {
            this.iconPrimaryColour = res?.result?.iconPrimaryColour;
            setPropertyInDivLayout(this.elRef, '--iconPrimary-color', this.iconPrimaryColour);
          }
          if (res?.result?.iconSecondaryColour) {
            this.iconSecondaryColour = res?.result?.iconSecondaryColour;
            setPropertyInDivLayout(this.elRef, '--iconSecondary-color', this.iconSecondaryColour);
          }
          if (res?.result?.iconHoverColour) {
            this.iconHoverColour = res?.result?.iconHoverColour;
            setPropertyInDivLayout(this.elRef, '--iconHover-color', this.iconHoverColour);
          }
          if (res?.result?.systemResponseColour) {
            this.systemResponseColour = res?.result?.systemResponseColour;
            setPropertyInDivLayout(this.elRef, '--systemResponse-color', this.systemResponseColour);
          }
          if (res?.result?.userResponseColour) {
            this.userResponseColour = res?.result?.userResponseColour;
            setPropertyInDivLayout(this.elRef, '--userResponse-color', this.userResponseColour);
          }
          if (res?.result?.systemResponseTextColour) {
            this.systemResponseTextColour = res?.result?.systemResponseTextColour;
            setPropertyInDivLayout(this.elRef, '--systemResponseText-color', this.systemResponseTextColour);
          }
          if (res?.result?.userResponseTextColour) {
            this.userResponseTextColour = res?.result?.userResponseTextColour;
            setPropertyInDivLayout(this.elRef, '--userResponseText-color', this.userResponseTextColour);
          }
          if (res?.result?.chatBackgroundColour) {
            this.chatBackgroundColour = res?.result?.chatBackgroundColour;
            setPropertyInDivLayout(this.elRef, '--chatBackground-color', this.chatBackgroundColour);
          }

          if (res?.result?.welcomeMessage && this.setWelcome) {
            //this.livechatFacade.addWelcomeMessage(res?.result?.welcomeMessage, this.roomId?.value);
            this.setWelcome = false;
          }
          if (res?.result?.fontSize) {
            if (res?.result?.fontSize == 'medium') {
              this.size += 1;
            } else if (res?.result?.fontSize == 'large') {
              this.size += 2;
            }
          }
          if (res?.result?.isDCEOEnabled) {
            this.isDCEOEnabled = res?.result?.isDCEOEnabled;
          } else {
            this.isDCEOEnabled = false;
          }
          if (res?.result?.chatbotRole) {
            this.chatbotroles = JSON.parse(res?.result?.chatbotRole);
            if(this.chatbotroles?.length > 0) {
              let idx = this.chatbotroles?.findIndex((role: any) => role?.id == (this.selectedRole?.roleId ? this.selectedRole?.roleId : this.selectedRole?.id));
              if (this.selectedRole?.roleName != "NslAnonymousGuest") {  // check for guest user
                this.enableChatBotForRole = idx == -1 ? false : true;
              } else this.enableChatBotForRole = true;
            }else {
              this.enableChatBotForRole = true;
            }
          } else {
            this.enableChatBotForRole = true;
          }
          if (res?.result?.isHiddenTranscript) {
            this.isHiddenTranscript = true;
          }
          if(res?.result?.isDCEOInterface){
            this.isDCEOInterface = res?.result?.isDCEOInterface != null ?  res?.result?.isDCEOInterface : true ;
          }
          this.feedbackEnabled = res?.result?.isFeedbackEnabled != null ? res?.result?.isFeedbackEnabled : true;
          this.dragEnabled = res?.result?.isDragEnabled != null ? res?.result?.isDragEnabled : true;
        } else {
          this.chatBotEnabled = false;
        }
      });
  }

  getSelectedRoleData() {
    this.livechatFacade.selectedRoleData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.selectedRole = res;
        if (this.chatbotroles?.length > 0) {
          let idx = this.chatbotroles?.findIndex((role: any) => role?.id == (this.selectedRole?.roleId ? this.selectedRole?.roleId : this.selectedRole?.id));
          this.enableChatBotForRole = idx == -1 ? false : true;
        }
      }
    });
  }

  hideIcon : boolean = false;
  setDepartment() {
    /* istanbul ignore next */
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: any) => {
        console.log(event);
        event?.url?.includes('dceo') ? this.hideIcon = true : this.hideIcon = false;
        let data = {
          token: this.jwtToken,
        };
        /* istanbul ignore next */
        if (this.envType.toLocaleLowerCase() === 'development') {
          if (
            event?.url?.includes('transaction') ||
            event?.url?.includes('custom-mylibrary') ||
            event?.url?.includes('mylibrary-dashboard') ||
            event?.url?.includes('dceo')
          ) {
            /* istanbul ignore next */
            this.livechatFacade.setDepartment(data, this.roomId?.value);
            this.tenantSpecificFlag = true;
          } else {
            this.tenantSpecificFlag = false;
          }
        } else {
          this.livechatFacade.setDepartment(data, this.roomId?.value);
          this.tenantSpecificFlag = true;
        }
        if (checkTokenExistsorNot() && !this.isConfigurationFetched) {
          this.isConfigurationFetched = true;
          this.fetchDetails();
        }
      });
  }

  chatBotTimer() {
    const timeKeys: any = Object.keys(this.messageObject).map((m) => Number(m));
    /* istanbul ignore next */
    this.mleak = setInterval(() => {
      if (timeKeys.includes(this.count)) {
        this.setNoresponseBotquestion(this.count);
      }

      if (this.count > timeKeys[timeKeys.length - 1] + this.timeoutTocloseBot) {
        this.count = 0;
        this.livechatFacade.disconnect(this.token?.value, this.roomId);
        this.sendTranscript(false);
        this.sendFeedback(false);
        this.stopTimer();
      }
      // console.log('Count form 0 to ' + timeKeys[timeKeys.length-1] + ' - ' + this.count);
      if (!this.disableInput) {
        this.count++;
      }
    }, 1000);
  }

  startTimer() {
    if (!LivechatSmartComponent.isDceo) this.chatBotTimer();
  }
  stopTimer() {
    if (this.mleak) {
      clearInterval(this.mleak);
      this.mleak = null;
    }
  }

  setNoresponseBotquestion(id: number) {
    /* istanbul ignore next */
    let msg: any = {
      question: null,
      ans: {
        text: this.messageObject[id],
        data: null,
      },
      document: null,
      score: null,
      topic: null,
      alternate: null,
      media: {
        image: [],
        video: [],
        audio: [],
      },
      response_type: 'text',
      trace: null,
      probe_mode: false,
      ans_format: null,
      timestamp: null,
      tenant_info: null,
      roomId: this.roomId?.value,
      user: this.updatedChatData?.messagesList?.answer?.user,
      button: null,
      url: null,
      isFaqOrAtq: false,
    };

    this.livechatFacade.setChatBotState(msg, ChatBotStoreActions.UpdateAnswer);
  }

  getTranscriptData(roomId: string, tokenId: string) {
    this.previousChatData = [];
    this.livechatFacade
      .getTranscriptData(roomId, tokenId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.previousChatData = JSON.parse(JSON.stringify(res));
        if (this.isDCEOEnabled) {
          this.previousChatData = [];
        }
        /* istanbul ignore next */
        this.previousChatData?.forEach((data: any) => {
          /* istanbul ignore next */
          if (data?.media?.entity_card?.length > 0 && this.IsJsonString(data?.media?.entity_card?.[0])) {
            /* istanbul ignore next */
            data.media.entity_card[0] = JSON.parse(data?.media?.entity_card?.[0]);
            /* istanbul ignore next */
            let entityId = data?.media?.entity_card?.[0]?.entity_id;
            /* istanbul ignore next */
            if (entityId) {
              /* istanbul ignore next */
              this.newSolutionFacade.getCardIds([entityId]);
              this.getAllCardIds(entityId, 'previousChatData');
            }
          }
          if (data?.media?.file?.length > 0) {
            this.agentFileName = data?.media?.file[0].substring(data?.media?.file[0].lastIndexOf('/') + 1);
          }
        });
      });
  }

  setRoomAndTokenIds() {
    const botids = localStorage.getItem('botIds');
    /* istanbul ignore else */
    if (!botids) {
      const tmp = {
        currentToken: this.token,
        previousToken: this.token,
        currentRoomId: this.roomId,
        previousRoomId: this.roomId,
      };
      localStorage.setItem('botIds', JSON.stringify(tmp));
    } else {
      const parsedBotids = JSON.parse(botids);
      /* istanbul ignore else */
      if (parsedBotids.currentToken === '' && parsedBotids.currentRoomId === '') {
        const tmp = {
          currentToken: this.token,
          previousToken: parsedBotids?.previousToken,
          currentRoomId: this.roomId,
          previousRoomId: parsedBotids?.previousRoomId,
        };
        localStorage.setItem('botIds', JSON.stringify(tmp));
      } else {
        const tmp = {
          currentToken: this.token,
          previousToken: parsedBotids?.currentToken,
          currentRoomId: this.roomId,
          previousRoomId: parsedBotids?.currentRoomId,
        };
        localStorage.setItem('botIds', JSON.stringify(tmp));
        if (!this.isRedirectFromGuestUser()) {
          this.getTranscriptData(tmp?.previousRoomId?.value, tmp?.previousToken?.value);
        } else {
          this.guestUserChat = false;
        }
      }
    }
  }

  getLanguages() {
    this.livechatFacade
      .getLanguages(this.tenantSpecificFlag)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.langulageOptions = [];
          Object.entries(res?.language).forEach((data: any) => {
            this.langulageOptions.push({ name: data[0], value: data[1] });
          });
          this.setLanguage('en');
        }
      });
  }

  setLanguage(value: string) {
    this.displayLanguageSettings = false;
    this.selectedLanguage = value;
    this.livechatFacade.addLanguageForBot(this.roomId.value, this.selectedLanguage);
    if (this.langulageOptions?.length > 0) {
      let language = this.langulageOptions.find((m: any) => m.value == this.selectedLanguage)?.name;
      if (language) {
        let message = 'Successfully switched to ' + language;
        this.alertservice.showToaster(message, '', 'success');
      }
    }
  }

  getHistory() {
    const botids = localStorage.getItem('botIds');
    if (botids) {
      const parsedBotids = JSON.parse(botids);
      /* istanbul ignore next */
      this.getTranscriptData(parsedBotids?.previousRoomId?.value, parsedBotids?.previousToken?.value);
    }
  }

  detectInputAutoSuggestion() {
    this.questionInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: any) => {
        /* istanbul ignore else */
        if (this.enable_autosuggestion === 'yes') {
          this.livechatFacade.getAutoSuggestions(this.question, this.selectedLanguage);
        }
      });
  }

  sugestions: string[] = [];

  detectAutoSuggestions() {
    this.livechatFacade.suggestionsObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.sugestions = res?.suggestions;
    });
  }

  checkLanguageOptions() {
    if (this.displayLanguageSettings) {
      this.displayLanguageSettings = false;
    }
  }

  isRedirectFromGuestUser() {
    let isGuestUser = localStorage.getItem('isGuestUser');
    return isGuestUser == 'false' && this.guestUserChat;
  }

  // typeWritter(id: string, ans: string) {
  //   let el = document.getElementById(id);
  //   typewriterEffect(ans + ' ', el);
  // }
}

export interface IButton {
  displayText: string;
  value: string;
}


