<div class="viewroles-wrap">
  <div class="mapping-details w-100">
    <div class="rolemap-tables">
      <div class="table-responsive">
        <table class="table" aria-describedby="">
          <thead *ngIf="conflicted === false">
            <tr>
              <th class="d-flex justify-content-between" id="">
                <div>{{ labels?.View_Roles_with_Conflict }}</div>
              </th>
              <th>
                <div class="selectall">
                  <mat-checkbox
                    class="cstm-check"
                    [disabled]="!checkConflicts()"
                    (change)="changeAllRolesSelection($event)"
                    [(ngModel)]="selectAll"
                    >Select all</mat-checkbox
                  >
                </div>
              </th>
              <!-- <th class="text-right" id="">
                <button mat-stroked-button class="cstm-btn" (click)="mapExistConRole()">
                  {{ labels?.Map_to_Existing_Roles }}
                </button>
                <button mat-stroked-button class="cstm-btn" (click)="mergeExistRole()">
                  {{ labels?.Merge_with_Existing_Role }}
                </button>
                <button mat-stroked-button class="cstm-btn" (click)="renameRole()">{{ labels?.Rename }}</button>
              </th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let role of rolesConflicted; let i = index">
              <tr *ngIf="role?.conflicting" class="row-adjust">
                <td class="edit-icon" *ngIf="role?.conflicting">
                  <mat-icon (click)="renameRole(i)">edit</mat-icon>
                </td>
                <td class="content-style">
                  <span
                    *ngIf="
                      role?.isConflictResolved === false &&
                      role?.conflicting &&
                      !(role.selectedNamerole && !role.selectedrole)
                    "
                    class="value-flex"
                  >
                    <mat-checkbox
                      class="cstm-check"
                      (change)="changeRole($event, role, i)"
                      [(ngModel)]="allRolesFlag[i].checked"
                      >{{ role?.sourceValue }}
                    </mat-checkbox>
                  </span>
                  <span
                    *ngIf="
                      (role.conflictAction === 'RENAME' ||
                        role.conflictAction === 'MERGE' ||
                        role.conflictAction === 'MAP') &&
                      role.isResolvedcon &&
                      !role.selectedNamerole
                    "
                    class="value-flex"
                  >
                    <mat-checkbox
                      [disabled]="role.isResolvedcon"
                      class="cstm-check"
                      (change)="changeRole($event, role, i)"
                      >{{ role?.targetValue }}</mat-checkbox
                    >
                  </span>
                  <span class="value-flex" *ngIf="role.selectedNamerole && !role.selectedrole">
                    <input type="text" placeholder="CH Admin" [(ngModel)]="renameroles[i]" />
                    <button class="btn-rename" (click)="saveName(i)">Rename</button>
                  </span>
                  <span
                    *ngIf="
                      !role.isResolvedcon &&
                      role?.targetValue === '' &&
                      role.conflictAction !== 'MERGE' &&
                      !role.selectedNamerole &&
                      !role.selectedrole
                    "
                    class="warning-icon"
                  >
                    <mat-icon>
                      warning_amber
                    </mat-icon>
                    {{ labels?.Resolve_Conflict }}
                  </span>
                  <span
                    *ngIf="
                      role.isResolvedcon &&
                      role.isConflictResolved === true &&
                      !(role.selectedNamerole && !role.selectedrole)
                    "
                    class="warning-icon"
                  >
                    {{ role?.message }}
                  </span>
                  <ngx-select-dropdown
                    class="form-control cstm-selt"
                    *ngIf="role.selectedrole && !role.selectedNamerole && !role.isConflictResolved"
                    [(ngModel)]="role.userConroles"
                    [multiple]="false"
                    [config]="config"
                    [options]="userRolesList"
                    (change)="rolesConData(i)"
                    (searchChange)="rolesconList($event)"
                    (close)="rolesconList('')"
                  ></ngx-select-dropdown>
                </td>

                <!-- <td>
                  <ngx-select-dropdown
                    class="form-control cstm-selt"
                    *ngIf="role.selectedrole && !role.selectedNamerole"
                    [(ngModel)]="role.userConroles"
                    [multiple]="false"
                    [config]="config"
                    [options]="userRolesList"
                    (change)="rolesConData(i)"
                    (searchChange)="rolesconList($event)"
                  ></ngx-select-dropdown>
                 
                </td> -->
              </tr>
            </ng-container>

            <div *ngFor="let role of rolesConflicted; let i = index">
              <tr *ngIf="role?.targetValue === '' && role.conflictAction === 'MERGE'">
                <td class="conflict-active">
                  <span>
                    <mat-checkbox class="cstm-check" disabled>{{ role?.sourceValue }}</mat-checkbox>
                    (Previously Installed)
                  </span>
                </td>
              </tr>
            </div>
          </tbody>
        </table>

        <div class="configure-btns btns-custom">
          <button class="btn btn-next btn-active" (click)="moveToNextTab()" [disabled]="checkConflicts()">
            {{ labels?.Next }}
          </button>
          <button class="btn btn-cancel" (click)="cancel()">{{ labels?.CANCEL }}</button>
        </div>
      </div>
      <!-- <div *ngIf="conflicted === true">{{ conflictedMessage }}</div> -->
    </div>
  </div>
</div>
