import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

type observableType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
@Injectable({
  providedIn: 'root',
})
export class EnvValidationsGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): observableType {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    const tenantName = localStorage.getItem('TenantName');
    if (activeUserFlag == 'Environment_Admin' || tenantName === 'b2c') {
      return true;
    } else {
      this.router.navigateByUrl('/admin');
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdminValidationsGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): observableType {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    if (activeUserFlag == 'Tenant_Admin') {
      return true;
    } else {
      this.router.navigateByUrl('/admin');
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): observableType {
    const environmentType = localStorage.getItem('environmentType');
    const envType = localStorage.getItem('envType');
    const env = environmentType ? environmentType : envType;
    if (env == 'development') {
      return true;
    } else {
      this.router.navigateByUrl('/admin');
    }
  }
}
