<div
  class="cuLayouts__topAds nh-display-flex nh-justify-center nh-mb-15"
  *ngIf="topBannerData != undefined && topBannerData != null"
>
  <div class="cuLayouts__topAds__inner nh-bg-50 nh-position-relative">
    <span class="nh-position-absolute">Ad</span>
    <img
      [title]="topBannerData?.destinationUrl"
      class="cursor-pointer img-fluid"
      [src]="topBannerData?.s3Location"
      id="top-ads-logo-img"
      alt="topBannerData?.destinationUrl"
      (click)="adClicked(topBannerData)"
    />
  </div>
</div>
