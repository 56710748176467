<ng-template #alertDialog>
  <div mat-dialog-content>You can’t map GSI and add to cart functionality at a same time.</div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ labels?.Close }}</button>
  </div>
</ng-template>
<ng-template #actionButton let-data="data">
  <span class="btn-close">
    <i
      class="bi bi-x"
      *ngIf="childGsiMapping?.[data?.position]"
      (click)="removeGsiFromCard(data?.position, childGsiMapping)"
    ></i>
    <button
      *ngIf="!isGrammer"
      #gsiAction
      (drop)="dropActionBtn($event, childGsiMapping)"
      (dragover)="allowDrop($event)"
      class="btn template-btn action-button btnaction text-truncate"
      pTooltip="Action Button"
      [id]="'action-' + data?.position"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      (dblclick)="dblClickButtonInCard($event)"
      [ngClass]="data?.class"
    >
      {{ childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position]?.name : 'Action Button'}}
    </button>
  </span>
</ng-template>
<div class="nhrow_col nhrow_middle"  >
  <div class="entity-config-info">
    <div class="entity-expand-wrap">
      <div class="entity-expand">
        <lib-cart-template
        [tabSelected]="tabSelected"
        [cartTemplateType]="cartTemplateType"
        [cartAttributesPositionMap]="cartAttributesPositionMap"
        (enableDroppedAttribute)="enableDroppedAttributeOnCartTemplate($event)"
        (allowDropEvent)="allowDrop($event)"
        [attributeDirectiveId]="attributeDirectiveId"
        [style]="style"
        (onDeletingCartAttrEvt)="onDeletingCartAttr($event)"
        (cartAttributeStylesEmit)="receiveCartAttributeStyles($event)"
        [(cartAttrStyleArray)]="cartAttrStyleArray"
        [(cartAttrStyleArr)]="cartAttrStyleArr"
      ></lib-cart-template>
        <ng-container *ngIf="!renderDynamiTemplate">
          <div class="entitycards-scroll">
          
            <h3 class="nh-fs-18 nh-fw-700 nh-text-90 nh-mb-8" *ngIf="!isSelectTemplate && tabSelected == 'child'">
              Default Card Template
            </h3>
            <div
              #dropEntityData
              class="entity-expands-info"
              *ngIf="!isSelectTemplate && tabSelected !== 'expand'"
              [ngStyle]="{ width: '33.3%' }"
              [ngClass]="{ 'd-none': tabSelected == 'cart' }"
              id="default"
            >
              <ul>
                <li
                  (drop)="dropAttribute($event, 'any')"
                  (dragover)="allowDrop($event)"
                  class="done"
                  *ngFor="let attr of attri; let i = index"
                >
                  <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem.innerHTML !== 'Attribute' }">
                    <div id="attr-{{ i }}" #attributeItem>
                      {{childAttributePositionMap?.[i] ?childAttributePositionMap?.[i]?.name : "Attribute" }}
                    </div>
                    <em
                      class="nh-icon nh-icon-trash01 cursor-pointer"
                      (click)="onDeletingAttribute(i, true)"
                      *ngIf="childAttributePositionMap?.[i]"
                    ></em>
                  </div>
                  <!-- <span
                    class="main-attr"
                    id="attr-{{ i }}"
                    #attributeItem
                    >{{childAttributePositionMap?.[i] ?childAttributePositionMap?.[i]?.name : "Attribute" }}</span
                  >
                  <span
                    ><img
                      *ngIf="childAttributePositionMap?.[i]"
                      class="cursor-pointer"
                      [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                      alt=""
                      (click)="onDeletingAttribute(i, true)"
                  /></span> -->
                </li>
              </ul>
              <ng-container *ngIf="!isGrammer">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  *ngFor="let btn of gsiActionButton; let i = index"
                  [ngTemplateOutletContext]="{ data: { position: i, class: 'w-100' } }"
                ></ng-template>
              </ng-container>
            </div>
            <div
              class="entitytemplates row"
              *ngIf="isSelectTemplate && templateType == '1'"
              [ngClass]="{ 'd-none': tabSelected !== 'child' }"
            >
              <div class="col-4" [ngStyle]="{ width: '33.3%' }">
                <div class="entity-template-t1 entity-template-type" #dropEntityData>
                  <div class="tl-left">
                    <ul>
                      <li id="attr_1" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_2" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_3" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_4" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                    </ul>
                  </div>
                  <div class="tl-right">
                    <ul>
                      <li
                        class="img-li"
                        id="attr_5"
                        (drop)="dropAttribute($event, 'image')"
                        (dragover)="allowDrop($event)"
                      >
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_6" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                    </ul>
                    <ng-template
                      [ngTemplateOutlet]="actionButton"
                      [ngTemplateOutletContext]="{ data: { position: 0 } }"
                    ></ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="entitytemplates row"
              *ngIf="isSelectTemplate && templateType == '2'"
              [ngClass]="{ 'd-none': tabSelected !== 'child' }"
            >
              <div class="col-4" [ngStyle]="{ width: '33.3%' }">
                <div class="entity-template-t2 entity-template-type" #dropEntityData>
                  <div class="tl-right">
                    <ul>
                      <li
                        class="img-li"
                        id="attr_7"
                        (drop)="dropAttribute($event, 'image')"
                        (dragover)="allowDrop($event)"
                      >
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_8" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                    </ul>
                    <ng-template
                      [ngTemplateOutlet]="actionButton"
                      [ngTemplateOutletContext]="{ data: { position: 0 } }"
                    ></ng-template>
                  </div>
                  <div class="tl-left">
                    <ul>
                      <li id="attr_9" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_10" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_11" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                      <li id="attr_12" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                        {{ labels?.Attribute }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="entitytemplates row"
              *ngIf="isSelectTemplate && templateType == '4'"
              [ngClass]="{ 'd-none': tabSelected !== 'child' }"
            >
              <div class="col-4" [ngStyle]="{ width: '33.3%' }">
                <div class="entity-template-t2 entity-template-type d-block" #dropEntityData>
                  <ul>
                    <li id="attr_13" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                      {{ labels?.Attribute }}
                    </li>
                    <li id="attr_14" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                      {{ labels?.Attribute }}
                    </li>
                    <li id="attr_15" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                      {{ labels?.Attribute }}
                    </li>
                    <li id="attr_16" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                      {{ labels?.Attribute }}
                    </li>
                    <li id="attr_17" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                      {{ labels?.Attribute }}
                    </li>
                  </ul>
                  <div class="row" *ngIf="!isGrammer">
                    <div class="col-6">
                      <ng-template
                        [ngTemplateOutlet]="actionButton"
                        [ngTemplateOutletContext]="{ data: { position: 0 } }"
                      ></ng-template>
                    </div>
                    <div class="col-6">
                      <ng-template
                        [ngTemplateOutlet]="actionButton"
                        [ngTemplateOutletContext]="{ data: { position: 0 } }"
                      ></ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div #childTemplateContainer></div>
            <div #expandTemplateContainer></div>
            <!-- <div
              class="entitytemplates row"
              *ngIf="isSelectTemplate && expandTemplateType == '1'"
              [ngClass]="{ 'd-none': tabSelected !== 'child' }"
            >
              <div class="col-12">
                <div class="entity-template-t2 entity-template-type" #dropEntityData>
                  <div class="col-4">
                    <ul class="h-100">
                      <li
                        class="h-100"
                        id="attr_18"
                        (drop)="dropAttribute($event, 'image')"
                        (dragover)="allowDrop($event)"
                      >
                        {{ labels?.Attribute }}
                      </li>
                    </ul>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-6">
                        <ul>
                          <li id="attr_19" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                            {{ labels?.Attribute }}
                          </li>
                          <li id="attr_20" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                            {{ labels?.Attribute }}
                          </li>
                          <li id="attr_21" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                            {{ labels?.Attribute }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-6">
                        <ul>
                          <li id="attr_22" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                            {{ labels?.Attribute }}
                          </li>
                          <li id="attr_23" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
                            {{ labels?.Attribute }}
                          </li>
                        </ul>
                        <div class="header-section">
                          <ul>
                            <li
                              id="attr_24"
                              (drop)="dropAttribute($event, 'any')"
                              (dragover)="allowDrop($event)"
                              class="mb-0"
                            >
                              {{ labels?.Attribute }}
                            </li>
                          </ul>
                          <ng-template
                            [ngTemplateOutlet]="actionButton"
                            [ngTemplateOutletContext]="{ data: { position: 0 } }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
