import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  toasterMessage: Subject<Toast> = new Subject<Toast>();
  toasterMessage$: Observable<Toast> = this.toasterMessage.asObservable();

  constructor() {}

  showToaster(message: string, title: string, typeOfAlert: ToastType, time: number = 5000) {
    const alertType = typeOfAlert?.toLowerCase();
    if (message && message !== 'undefined') {
      this.toasterMessage.next({ message: message, title: title, typeOfAlert: alertType, time: time });
    }
  }

  closeToaster() {
    this.toasterMessage.next(null);
  }
}
export type ToastType = 'success' | 'error' | 'warning' | 'info' | any;
export interface Toast {
  message?: string;
  title?: string;
  typeOfAlert?: ToastType | any;
  time?: number;
}
