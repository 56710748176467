import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService, LoaderService, TransactionFacadeService, UploadFacadeService, FieldConfig, EventsFacadeService } from '@common-services';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-capture-image',
  styleUrls: ['./capture-image.component.scss'],
  template: ` <div class="webcam-info" id="webcam_info">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
        <label
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          class="main-label nh-mb-0"
          id="webcam_info_label"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel"
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          >
        </label>
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <div>
        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <ng-container *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])">
          <div class="nh-display-flex nh-column-gap-5 nh-align-center nh-mb-10">
            <!-- <button
              class="btn actionbtn shadow-none"
              id="webcam_open_camera"
              *ngIf="!showWebcam"
              (click)="openCamera()"
            >
              <mat-icon>camera_alt</mat-icon>
            </button> -->

            <figure class="cam-icon" id="webcam_open_camera" *ngIf="!showWebcam && !webcamImage" (click)="openCamera()">
              <img src="../../../assets/images/ui-controls/icon-capture-img.svg" />
            </figure>
          </div>

          <div class="video-recording-panel" *ngIf="showWebcam">
            <div class="video-panel-close" (click)="showWebcam = false">
              <figure><img src="../../../assets/images/ui-controls/panel-close.svg" /></figure>
            </div>

            <div class="recordplayer">
              <div *ngIf="!webcamImage && showWebcam" id="webcam_camera_image">
                <webcam
                  class="webcame-div"
                  id="webcam_open_image"
                  [trigger]="triggerObservable"
                  [captureImageData]="true"
                  (imageCapture)="handleImage($event)"
                  *ngIf="showWebcam"
                  [videoOptions]="videoOptions"
                  (initError)="handleInitError($event)"
                  [mirrorImage]="isMirrorImageFlagEnabled ? 'never' : 'always'"
                ></webcam>
              </div>
            </div>

            <div class="panel-footer">
              <figure class="cam-icon" id="webcam_capture" *ngIf="showWebcam" (click)="triggerSnapshot()">
                <img src="../../../assets/images/ui-controls/icon-capture-img.svg" />
              </figure>
              <div class="dropdown">
                <div id="three_dot_toggle_icon" class="toggle cursor-pointer" (click)="toggleDropdown()">
                  <!-- <button class="btn actionbtn toggle_btn shadow-none" id="expand_more">
                              <mat-icon>more_vert</mat-icon>
                            </button> -->
                  <figure class="cam-icon" id="expand_more">
                    <img src="../../../assets/images/ui-controls/mirror-img.svg" />
                  </figure>
                </div>
                <ul class="dropdown-content" *ngIf="isOpen" (clickOutside)="toggleDropdown()">
                  <li>
                    <mat-slide-toggle
                      class="dropdown-option-mat-slide"
                      [checked]="isMirrorImageFlagEnabled"
                      (change)="editImage($event)"
                      id="slide_toggle_dropdown"
                      >Mirror Image:</mat-slide-toggle
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div *ngIf="flippedImage" id="webcam_image" class="captured-image-panel">
            <!-- <button class="btn actionbtn shadow-none" >
              <mat-icon>highlight_off</mat-icon>
            </button> -->
            <figure class="w-auto delete-captured-img" id="webcam_clear" *ngIf="webcamImage" (click)="clearImage()">
              <img src="../../../assets/images/ui-controls/icon-trash.svg" />
            </figure>
            <img [src]="flippedImage" #imagemapping class="captured-img w-100" alt="" />
          </div>
        </ng-container>
        <!--Capture Image here text in table-->
        <div *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']">
          <p-overlayPanel #op4 styleClass="ui-control-dropdown">
            <ng-template pTemplate="content">
              <div class="capture-img-table">
                <div *ngIf="!webcamImage && showWebcam" id="webcam_camera_image">
                  <webcam
                    class="webcame-div"
                    id="webcam_open_image"
                    [trigger]="triggerObservable"
                    [captureImageData]="true"
                    (imageCapture)="handleImage($event)"
                    *ngIf="showWebcam"
                    [videoOptions]="videoOptions"
                    (initError)="handleInitError($event)"
                    [mirrorImage]="isMirrorImageFlagEnabled ? 'never' : 'always'"
                  ></webcam>
                </div>
                <div *ngIf="flippedImage" id="webcam_image">
                  <img [src]="flippedImage" #imagemapping class="captured-img w-100" alt="" />
                </div>
                <figure *ngIf="!((!webcamImage && showWebcam) || flippedImage)">
                  <img src="../../../assets/images/ui-controls/img-capture.png" />
                </figure>
                <!-- <figure>
                  <img src="../../../assets/images/ui-controls/icon-camera-filled.svg" />
                </figure> -->
                <div class="d-flex">
                  <button
                    class="btn actionbtn shadow-none"
                    id="webcam_open_camera"
                    *ngIf="!showWebcam"
                    (click)="openCamera()"
                  >
                    <mat-icon>camera_alt</mat-icon>
                  </button>
                  <button
                    class="btn actionbtn shadow-none"
                    id="webcam_capture"
                    *ngIf="showWebcam"
                    (click)="triggerSnapshot()"
                  >
                    <mat-icon>camera</mat-icon>
                  </button>
                  <button
                    class="btn actionbtn shadow-none"
                    id="webcam_clear"
                    *ngIf="webcamImage"
                    (click)="clearImage()"
                  >
                    <mat-icon>highlight_off</mat-icon>
                  </button>
                  <div class="dropdown">
                    <div id="three_dot_toggle_icon" class="toggle cursor-pointer" (click)="toggleDropdown()">
                      <button class="btn actionbtn toggle_btn shadow-none" id="expand_more">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </div>
                    <ul class="dropdown-content" *ngIf="isOpen" (clickOutside)="toggleDropdown()">
                      <li>
                        <mat-slide-toggle
                          class="dropdown-option-mat-slide"
                          [checked]="isMirrorImageFlagEnabled"
                          (change)="editImage($event)"
                          id="slide_toggle_dropdown"
                          >Mirror Image:</mat-slide-toggle
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-overlayPanel>
          <!-- <p-overlayPanel #ophover styleClass="ui-control-dropdown-hover" [style]="marginStyle">
            <img *ngIf="flippedImage" [src]="flippedImage" #imagemapping class="captured-img w-100" alt="" />
          </p-overlayPanel> -->
          <figure (click)="op4.toggle($event)" class="thumbnail-icon">
            <img *ngIf="!flippedImage" src="../../../assets/images/ui-controls/icon-camera.svg" />
            <img *ngIf="flippedImage" [src]="flippedImage" #imagemapping class="captured-img w-100" alt="" />
          </figure>
        </div>
        <p
          class="bottom-text"
          *ngIf="
            field?.configuration?.helperTextPosition == 'bottom' &&
            !field?.configuration?.showHelperTextIcon &&
            field.configuration?.showTooltip
          "
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
          matTooltip="{{ field?.configuration?.helpertext }}"
          matTooltipPosition="below"
        >
          {{ field?.configuration?.helpertext }}
        </p>
      </div>
    </div>
  </div>`,
})
export class CaptureImageComponent implements OnInit, OnDestroy {
  positionLeft = '-52px';
  marginStyle = { 'margin-left': this.positionLeft };

  errors: WebcamInitError[] = [];
  trigger: Subject<void> = new Subject<void>();
  nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  ngUnsubscribe = new Subject();
  webcamImage: WebcamImage;
  capturedFlag: boolean = false;
  field: FieldConfig;
  group: FormGroup;
  videoOptions: MediaTrackConstraints = {
    facingMode: 'environment',
    width: { ideal: 1024 },
    height: { ideal: 576 },
  };
  showWebcam = false;
  flippedImage: any = null;
  constructor(
    private alertService: AlertService,
    private loader: LoaderService,
    private uploadFacadeService: UploadFacadeService,
    private transacationFacadeService: TransactionFacadeService,
    private eventsService: EventsFacadeService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) {}
  isOpen = false;
  isMirrorImageFlagEnabled: boolean = false;

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      this.transacationFacadeService.disableSubmitButtonImageCapture(true);
    }
  }
  /*istanbul ignore next*/
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  /* istanbul ignore next */
  triggerSnapshot(): void {
    this.trigger.next();
    this.showWebcam = false;
    this.capturedFlag = true;
  }
  handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
    /*istanbul ignore else*/
    if (this.errors.length > 0) {
      this.errors.forEach((error: any) => {
        this.alertService.showToaster(error, '', 'info');
      });
    }
  }
  editImage(event: any) {
    this.isMirrorImageFlagEnabled = event?.checked;
  }

  /**
   *
   * @param src - image url
   * @author Rahul Kumar
   * @description this will flip the image horizontally i.e mirror image
   */
  /* istanbul ignore next */
  flip(src: any) {
    const img = new Image();
    img.onload = () => {
      var c = document.createElement('canvas');
      c.width = img.width;
      c.height = img.height;
      var ctx = c.getContext('2d');
      ctx.scale(-1, 1);
      ctx.drawImage(img, -img.width, 0);
      img.onload = undefined;
      const flippedImage = c.toDataURL();
      this.flippedImage = flippedImage;
      this.saveImageFiles(flippedImage);
    };
    img.src = src;
  }
  /**
   *
   * @param webcamImage
   * @author Rahul Kumar
   * @description used to convert the image into binary format.
   */
  saveImageFiles(webcamImage: any) {
    const arr = webcamImage?.split(','); //this.webcamImage.imageAsDataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    //Convert Each Character to binary
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], 'test.jpg');
    let formData = new FormData();

    // let formData = new FormData();
    // let file = new File([data], 'test');
    formData.append('file', file);
    // formData.append('image', file);
    this.saveFiles(formData, 1);
  }
  // /* istanbul ignore next */
  handleImage(webcamImage: any): void {
    this.webcamImage = webcamImage;
    if (!this.isMirrorImageFlagEnabled) {
      this.flip(webcamImage?.imageAsDataUrl);
    } else {
      this.flippedImage = webcamImage?.imageAsDataUrl;
      this.saveImageFiles(webcamImage?.imageAsDataUrl);
    }
  }
  saveFiles(formDataFAQ: any, fileLength?: any) {
    this.uploadFacadeService
      .dynamicFormUploadFiles(formDataFAQ, fileLength)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (data) {
            if (Array.isArray(this.field.value)) {
              /* istanbul ignore next */
              this.field.value[0] = JSON?.stringify(data);
            } else {
              /* istanbul ignore next */
              this.field.value = JSON?.stringify(data);
            }
            this.imageClick();
            this.group.controls[this.field.attribute.name].setValue(JSON?.stringify(data));
            this.transacationFacadeService.disableSubmitButtonImageCapture(false);
          }
        },
        /* istanbul ignore next */
        (err: any) => {
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }
  openCamera() {
    this.showWebcam = true;
  }
  clearImage() {
    this.webcamImage = null;
    this.showWebcam = true;
    this.flippedImage = null;
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      this.transacationFacadeService.disableSubmitButtonImageCapture(true);
    }
  }
  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  /* istanbul ignore next */
  imageClick() {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore next */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
