import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';
import { ManageConfigurationEndpointService } from './manage-configuration-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class ManageConfigurationFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  private ConfigurableCPEntities = new Subject();
  ConfigurableCPEntities$ = this.ConfigurableCPEntities.asObservable();

  private showUserConfigurationSource = new BehaviorSubject<boolean>(false);
  showUserConfiguration$ = this.showUserConfigurationSource.asObservable();
  
  setShowUserConfiguration(value: boolean) {
    this.showUserConfigurationSource.next(value);
  }
  private ConfigurableEntityData = new Subject();
  ConfigurableEntityData$ = this.ConfigurableEntityData.asObservable();

  private ConfigurableEntityTableData = new Subject();
  ConfigurableEntityTableData$ = this.ConfigurableEntityTableData.asObservable();

  public getSignupProperties = new BehaviorSubject('');
  getSignupProperties$ = this.getSignupProperties.asObservable();

  private gsiDetails = new Subject();
  gsiDetails$ = this.gsiDetails.asObservable();
  api: any;

  /*istanbul ignore next */
  get getsignupProperties() {
    return this.getSignupProperties;
  }

  constructor(
    private manageConfigurationEndpointService: ManageConfigurationEndpointService,
    private loader: LoaderService,
    private alertService: AlertService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  getConfigurableCPEntities(pageSize?: any, name?: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .getConfigurableCPEntities(pageSize, name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else  */
        if (res) {
          this.loader.hide();
          this.ConfigurableCPEntities.next(res);
        }
      });
  }

  getConfigurableEntityData(generalEntityID: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .getConfigurableEntityData(generalEntityID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.ConfigurableEntityData.next(res);
      });
  }

  saveConfigurableEntityData(postData: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .saveConfigurableEntityData(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
        this.loader.hide();
      });
  }

  saveLoginWithOtp(otpConfigurationForm: any) {
    return this.manageConfigurationEndpointService.saveLoginWithOtp(otpConfigurationForm.enableOtp,otpConfigurationForm.otpLimit);
  }
  saveHotConf(ttlConfiguration: number) {
    return this.manageConfigurationEndpointService.saveHotConf(ttlConfiguration);
  }
  fetchHotConf() {
    return this.manageConfigurationEndpointService.fetchHotConf();
  }
  tenantBotBasicSave(payload: any) {
    return this.manageConfigurationEndpointService.tenantBotBasicSave(payload);
  }
  fetchBotDetails(isTenantSpecificBot: boolean) {
    return this.manageConfigurationEndpointService.fetchBotDetails(isTenantSpecificBot);
  }
  faqSave(formData: any, language: any, fileType: string) {
    return this.manageConfigurationEndpointService.faqSave(formData, language, fileType);
  }
  fetchSignupConfig() {
    return this.manageConfigurationEndpointService.fetchSignupConfig();
  }
  saveCreateguestUserFlag(flag: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .saveCreateguestUserFlag(flag)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          /* istanbul ignore next */
          if (res?.result) {
            localStorage.setItem('canCreateGuestUsers', res.result?.canCreateGuestUsers?.toString());
          }
          this.alertService.showToaster(res.message, '', res.status == 'OK' ? 'success' : 'error');
          this.loader.hide();
        }
      });
  }
  getGuestuserConfiguration(tenantName: any) {
    return this.manageConfigurationEndpointService.getGuestuserConfiguration(tenantName);
  }
  saveSignupConfig(payload: any) {
    return this.manageConfigurationEndpointService.saveSignupConfig(payload);
  }
  deleteSignupConfig() {
    return this.manageConfigurationEndpointService.deleteSignupConfig();
  }
  // atqSave(formData:any){
  //   return this.manageConfigurationEndpointService.atqSave(formData);
  // }

  fetchSignupProperties(clientId?: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .fetchSignupProperties(clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else  */
        if (res) {
          this.loader.hide();
          
          this.getSignupProperties.next(res);
        }
      });
  }
  fetchGsiMappings(purpose: any, pageNo: number, pageSize: number, roleName:string) {
    return this.manageConfigurationEndpointService.fetchGsiMappings(purpose, pageNo, pageSize, roleName);
  }
  fetchPublicGsiMappings(purpose: any) {
    return this.manageConfigurationEndpointService.fetchPublicGsiMappings(purpose);
  }
  deleteGsiMapping(role: any, purpose: any) {
    return this.manageConfigurationEndpointService.deleteGsiMapping(role, purpose);
  }

  botIconUpload(file: any) {
    return this.manageConfigurationEndpointService.botIconUpload(file);
  }
  customFileForSignUpCustomization(formdata: any) {
    return this.manageConfigurationEndpointService.customFileForSignUpCustomization(formdata);
  }

  saveMsgConfigDoc(formData: any) {
    return this.manageConfigurationEndpointService.saveMsgConfigDoc(formData);
  }
  faqUploadHistory(page_number: number, page_size: number) {
    return this.manageConfigurationEndpointService.faqUploadHistory(page_number, page_size);
  }
  getEntitiesForText2SQL(body: any) {
    return this.manageConfigurationEndpointService.getEntitiesForText2SQL(body);
  }
  getSchema(tenantName: string, entity: string) {
    return this.manageConfigurationEndpointService.getSchema(tenantName, entity);
  }
  DummyData(body: any) {
    return this.manageConfigurationEndpointService.DummyData(body);
  }

  unTrainUploadHistory(page_number: number, page_size: number) {
    return this.manageConfigurationEndpointService.unTrainUploadHistory(page_number, page_size);
  }

  faqFileDownload(fileName: string) {
    return this.manageConfigurationEndpointService.faqFileDownload(fileName);
  }
  unTrainFileDownload(fileName: string) {
    return this.manageConfigurationEndpointService.unTrainFileDownload(fileName);
  }
  loadCPEntityDataById(id: any) {
    return this.manageConfigurationEndpointService.loadCPEntityDataById(id);
  }

  getLanguages() {
    return this.manageConfigurationEndpointService.getLanguages();
  }

  getCampaignUrl(payload: any) {
    return this.manageConfigurationEndpointService.getCampaignUrl(payload);
  }

  saveLoginConfig(payload: any) {
    return this.manageConfigurationEndpointService.saveLoginConfig(payload);
  }

  getLoginConfig() {
    return this.manageConfigurationEndpointService.getLoginConfig();
  }

  updateLoginConfig(payload: any) {
    return this.manageConfigurationEndpointService.updateLoginConfig(payload);
  }
  deleteLoginConfig() {
    return this.manageConfigurationEndpointService.deleteLoginConfig();
  }
  fetchLoginConfiguration(clientId: any) {
    return this.manageConfigurationEndpointService.fetchLoginConfiguration(clientId);
  }
  saveLoginConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.saveLoginConfiguration(payload);
  }
  updateLoginConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.updateLoginConfiguration(payload);
  }
  deleteLoginConfiguration() {
    return this.manageConfigurationEndpointService.deleteLoginConfiguration();
  }
  fetchLogoutConfiguration(clientId: any) {
    return this.manageConfigurationEndpointService.fetchLogoutConfiguration(clientId);
  }
  saveLogoutConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.saveLogoutConfiguration(payload);
  }
  updateLogoutConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.updateLogoutConfiguration(payload);
  }
  deleteLogoutConfiguration() {
    return this.manageConfigurationEndpointService.deleteLogoutConfiguration();
  }

  fetchMachineCuTxnHistoryConfiguration() {
    return this.manageConfigurationEndpointService.fetchMachineCuTxnHistoryConfiguration();
  }
  saveMachineCuTxnHistoryConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.saveMachineCuTxnHistoryConfiguration(payload);
  }
  updateMachineCuTxnHistoryConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.updateMachineCuTxnHistoryConfiguration(payload);
  }
  deleteMachineCuTxnHistoryConfiguration() {
    return this.manageConfigurationEndpointService.deleteMachineCuTxnHistoryConfiguration();
  }
  saveFaceRecogConfg(isFaceRecognitionEnabled: boolean) {
    return this.manageConfigurationEndpointService.saveFaceRecogConfg(isFaceRecognitionEnabled);
  }
  getFaceRecogConfg(clientId: any) {
    return this.manageConfigurationEndpointService.getFaceRecogConfg(clientId);
  }
  deleteFaceRecogConfg() {
    return this.manageConfigurationEndpointService.deleteFaceRecogConfg();
  }
  updateFaceRecogConfg(isFaceRecognitionEnabled: boolean) {
    return this.manageConfigurationEndpointService.updateFaceRecogConfg(isFaceRecognitionEnabled);
  }
  getuserConfiguration() {
    return this.manageConfigurationEndpointService.getUserConfiguration();
  }
  saveuserConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.saveuserConfiguration(payload);
  }
  updateUserConfiguration(payload: any) {
    return this.manageConfigurationEndpointService.updateUserConfiguration(payload);
  }
  deleteUserConfiguration() {
    return this.manageConfigurationEndpointService.deleteUserConfiguration();
  } customFileForUsersCustomization(formdata: any, subject: any) {
    return this.manageConfigurationEndpointService.customFileForUsersCustomization(formdata, subject);
  }
  getGSIConfiguration(tenantName: string, gsiId: string) {
    return this.manageConfigurationEndpointService.getGSIConfiguration(tenantName, gsiId);
  }
  saveGSIConfiguration(tenantName: string, payload: any) {
    return this.manageConfigurationEndpointService.saveGSIConfiguration(tenantName, payload);
  }
}
