import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeUnit,
  TranslatorService,
  EventsFacadeService,
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-info-layer',
  templateUrl: './info-layer.component.html',
  styleUrls: ['./info-layer.component.scss'],
})
export class InfoLayerComponent implements OnInit, OnDestroy {
  @Output() infoLayerformValueChanges = new EventEmitter();
  @Output() entityDataEmitForTable = new EventEmitter();
  @Output() addMultipleEntities = new EventEmitter();
  @Input() entitiesList: any;
  @Input() infoEntities: any;
  @Input() UIcontrols: any;
  @Input() gsi: any;
  @Input() currentCu: ChangeUnit;
  @Input() isReadOnly: any;
  @Input() cuLayoutTemplate: any;
  @Input() layoutConfigurations: any;
  @Input() isTransactionActive: boolean;
  @Input() gsiDesign: any;

  @Output() emitFormData = new EventEmitter();
  @Input() indexChange?: number;

  currentEntityIndex: number = 0;
  labels: any;
  layerData: any = {
    type: 'information',
    txnSlotItems: [],
  };

  private ngUnsubscribe = new Subject();

  constructor(
    private translator: TranslatorService,
    private eventsService: EventsFacadeService
  ) {
    this.detectLanguageChange();
    this.emitFormValueonEventTrigger();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    /* istanbul ignore next */
    this.entitiesList?.forEach((entity: any) => {
      entity?.nslAttributes?.forEach((attribute: any) => {
        if (this.currentCu.attributeMapping) {
          Object?.keys(this.currentCu?.attributeMapping)?.forEach(
            (key: string) => {
              this.currentCu?.attributeMapping[key]?.forEach(
                (MappedAttr: any) => {
                  if (
                    MappedAttr ===
                      'IL.SL00' +
                        entity?.slot +
                        '.' +
                        entity?.name +
                        '.' +
                        attribute?.name ||
                    MappedAttr ===
                      'IL.SL00' +
                        entity?.slot +
                        '.EN' +
                        entity?.id +
                        '.AT' +
                        attribute?.id
                  ) {
                    attribute.isInPotentiality = false;
                  }
                }
              );
            }
          );
        }
      });
    });
  }

  emitFormValueonEventTrigger() {
    this.eventsService.eventTrigger$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        if (!data?.isInfo) {
          const attributeName = data?.attrName?.split('$')[0];
          const entityName = data?.entityName?.split('$')[0];
          let isAttributeMapping = false;
          let resetAttributeMappings = false;
          if (attributeName && this.currentCu?.attributeMapping) {
            this.entitiesList?.forEach((entity: any) => {
              entity.nslAttributes?.forEach((attribute: any) => {
                Object?.keys(this.currentCu.attributeMapping)?.forEach(
                  (key: string) => {
                    this.currentCu.attributeMapping[key]?.forEach(
                      (MappedAttr: any) => {
                        isAttributeMapping = true;
                        if (
                          MappedAttr ===
                            'IL.SL00' +
                              entity.slot +
                              '.' +
                              entity.name +
                              '.' +
                              attribute.name ||
                          MappedAttr ===
                            'IL.SL00' +
                              entity.slot +
                              '.EN' +
                              entity.id +
                              '.AT' +
                              attribute.id
                        ) {
                          attribute.isInPotentiality = false;
                        }
                      }
                    );
                  }
                );
              });
            });
          }
          /* istanbul ignore next */
          if (
            data &&
            (data?.eventType === 'ON_FOCUS' || data?.eventType === 'ON_CHANGE')
          ) {
            if (this.currentCu?.attributeMapping) {
              this.entitiesList?.forEach((ent: any) => {
                ent?.nslAttributes.forEach((attribute: any) => {
                  Object?.entries(this.currentCu?.attributeMapping)?.forEach(
                    ([key, value]: any) => {
                      if (key?.includes(data.attributeId || data.attrName)) {
                        resetAttributeMappings = true;
                      }
                    }
                  );
                });
              });
            }
            this.entitiesList?.forEach((entity: any) => {
              entity?.nslAttributes?.forEach((attribute: any) => {
                if (resetAttributeMappings) {
                  attribute.isInPotentiality = false;
                } else if (this.currentCu?.attributeMapping) {
                  attribute.isInPotentiality = true;
                  Object?.keys(this.currentCu?.attributeMapping)?.forEach(
                    (key: string) => {
                      this.currentCu?.attributeMapping[key]?.forEach(
                        (MappedAttr: any) => {
                          if (
                            MappedAttr ===
                              'IL.SL00' +
                                entity?.slot +
                                '.' +
                                entity?.name +
                                '.' +
                                attribute?.name ||
                            MappedAttr ===
                              'IL.SL00' +
                                entity?.slot +
                                '.EN' +
                                entity?.id +
                                '.AT' +
                                attribute?.id
                          ) {
                            attribute.isInPotentiality = false;
                          }
                        }
                      );
                    }
                  );
                }
                if (this.currentCu?.attributeMapping) {
                  let findIdFormat = this.currentCu?.attributeMapping[
                    'PL.SL00' +
                      data.slotNumber +
                      '.EN' +
                      data.entityId +
                      '.AT' +
                      data.attributeId
                  ]?.find(
                    (MappedAttr: any) =>
                      MappedAttr ===
                      'IL.SL00' +
                        entity.slot +
                        '.EN' +
                        entity.id +
                        '.AT' +
                        attribute.id
                  );
                  let findNameFormat = this.currentCu?.attributeMapping[
                    'PL.SL00' +
                      data.slotNumber +
                      '.' +
                      entityName +
                      '.' +
                      attributeName
                  ]?.find(
                    (MappedAttr: any) =>
                      MappedAttr ===
                      'IL.SL00' +
                        entity.slot +
                        '.' +
                        entity.name +
                        '.' +
                        attribute.name
                  );
                  if (findIdFormat || findNameFormat) {
                    attribute.isInPotentiality = true;
                  }
                }
              });
            });
          }
          /*istanbul ignore next*/
          if (isAttributeMapping) {
            this.entitiesList?.forEach((entity: any) => {
              let isAtleastOneAttributevisible = entity.nslAttributes.find(
                (attr: any) => attr.isInPotentiality
              );
              entity.isHidden = isAtleastOneAttributevisible ? false : true;
            });
            this.entitiesList = JSON.parse(JSON.stringify(this.entitiesList));
          }
        }
      });
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }

  entityDataForTable(event: any) {
    this.entityDataEmitForTable.emit(event);
  }

  submitFormData(data: any) {
    if (
      data.isOnlyInformational == -1 ||
      this.layoutConfigurations?.hidePhyLayer
    ) {
      this.emitFormData.emit({
        data: data.data,
        layer: 'information',
        details: data.details,
      });
    }
  }

  formValueChange(formValue: any) {
    /* istanbul ignore next */
    this.entitiesList?.forEach((entity: any) => {
      entity?.nslAttributes?.forEach((attribute: any) => {
        formValue?.data?.forEach((entity1: any) => {
          let index = entity1?.nslAttributes?.findIndex(
            (t: any) => t.id === attribute.id
          );
          if (index !== -1) {
            attribute.values = entity1?.nslAttributes[index]?.values;
          }
        });
      });
    });
    this.infoLayerformValueChanges.emit({
      data: formValue?.data,
      layer: 'information',
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  addMultiEntities(entity: any) {
    this.addMultipleEntities.emit(entity);
  }
}
