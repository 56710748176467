import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService } from '../translator/translator.service';

@Component({
  selector: 'app-hide-record-additional-info',
  templateUrl: './hide-record-additional-info.component.html',
  styleUrls: ['./hide-record-additional-info.component.scss'],
})
export class HideRecordAdditionalInfoComponent implements OnInit {
  @Input() disableSTEs: boolean = false;
  @Input() canvas: string = '';
  @Output() hideRecordAdditionalInfo: any = new EventEmitter();
  labels: any;
  private ngUnsubscribe = new Subject();
  constructor(private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  onhideRecordAdditionalInfoChange() {
    this.hideRecordAdditionalInfo.emit(this.disableSTEs);
  }
}
