import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SolutionDictionaryFacadeService } from '../../../solution-dictionary/solution-dictionary-facade.service';
import { TranslatorService } from '../../../translator/translator.service';
import { RolesFacadeService } from '../../../roles/roles-facade.service';

@Component({
  selector: 'app-dashboard-conflicts',
  templateUrl: './dashboard-conflicts.component.html',
  styleUrls: ['./dashboard-conflicts.component.scss'],
})
export class DashboardConflictsComponent implements OnInit {
  @Input() dashboardsConflicted: any;
  @Input() saasapplication: boolean;
  @Output() changedDashboards: EventEmitter<any> = new EventEmitter();

  private unsubscribe$: Subject<any> = new Subject<any>();

  conflicted: boolean = false;
  isResolvedcon: boolean[] = [];
  selectedNameDashboard: boolean[] = [];
  indexdata: any[] = [];
  message: any;
  labels: any;
  betType: any;

  constructor(
    private router: Router,
    private translator: TranslatorService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private dialogRef: MatDialog,
    private rolesFacade: RolesFacadeService
  ) {
    this.detectLanguageChange();
    this.getBetType();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    var conflictindex = this.dashboardsConflicted?.findIndex(
      (x: any) => x.conflicting === true && x.isConflictResolved === false
    );

    /* istanbul ignore next */
    if (conflictindex === -1) {
      this.conflicted = true;
    }
  }

  moveToNextTab() {
    // this.betConfiguration.changeTabIndex(7);
    this.solutionDictionaryFacade.setChangeTabIndex(7);
    this.changedDashboards.emit(this.dashboardsConflicted);
  }

  cancel() {
    if (!this.saasapplication) {
      if (this.betType === 'gsiImport') {
        this.rolesFacade.closeDialog(true);
        this.router.navigateByUrl('/admin/importsolution');
      } else {
        this.router.navigateByUrl('/solution-dictionary');
      }
    } else {
      this.solutionDictionaryFacade.saasapplicationBetSubect.next(null);
      this.router.navigateByUrl('admin/manage-saasapplications/addSaasApplication');
    }
    this.dialogRef.closeAll();
    this.solutionDictionaryFacade.closepopupDataService();
  }

  changeDashboard(eve: any, data: any, index: any) {
    /* istanbul ignore next */
    if (eve.checked) {
      this.indexdata.push(index);
    } else if (!eve.checked) {
      this.indexdata.splice(index, 1);
      data.selectedNameDashboard = false;
    }
  }

  renameDashboard() {
    /* istanbul ignore next */
    this.indexdata?.forEach((element: any) => {
      this.dashboardsConflicted[element].selectedNameDashboard = true;
    });
  }

  saveName() {
    for (var i = 0; i < this.indexdata.length; i++) {
      var data = this.indexdata[i];
      this.dashboardsConflicted[data].conflictAction = 'RENAME';
      this.dashboardsConflicted[data].isConflictResolved = true;
      this.dashboardsConflicted[data].conflicting = true;
      this.dashboardsConflicted[data].isResolvedcon = true;
      this.dashboardsConflicted[data].selectedNameDashboard = false;
      this.message = 'Renamed from' + ' ' + this.dashboardsConflicted[data].sourceValue;
      this.dashboardsConflicted[data].message = this.message;
      this.indexdata.splice(i, 1);
    }
  }

  checkConflicts() {
    let flag: boolean = true;
    /* istanbul ignore next */
    this.dashboardsConflicted?.forEach((dashboard: any) => {
      /* istanbul ignore next */
      if (dashboard?.isConflictResolved === false && dashboard?.conflicting === true) {
        flag = flag && dashboard.conflicting;
      }
    });
    return !flag;
  }

  getBetType() {
    this.solutionDictionaryFacade.betType$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.betType = res;
      }
    });
  }
}
