import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener,
  Renderer2,
  ElementRef,
  Injector,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { TranslatorService, AlertService, EventsFacadeService, TransactionFacadeService, displayValue, getCurrentEventDataArray, getEventData, paginationDetails, displayMexicoMobileFormat, EventsEndpointService } from '@common-services';
import { SortEvent, ConfirmationService } from 'primeng/api';
import * as XLSX from 'xlsx';
import { Table } from 'primeng/table/table';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { displayUSAMobileFormat } from '@common-services';
import { isParseNumber } from '@common-services';
import { DialogComponent } from 'ui-controls';
import * as moment from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-dynamic-ui-table',
  templateUrl: './dynamic-ui-table.component.html',
  styleUrls: ['./dynamic-ui-table.component.scss'],
  providers: [DatePipe, ConfirmationService],
})
export class DynamicUiTableComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() control: any;
  @Input() multiEntities: any;
  @Input() eventCUList: any;
  @Input() rowNumberChanged: any;
  @Input() selectedRowTxnRecordIds: any;
  @Input() tempEntityList: any;
  @Input() facetedData: any;
  @Input() isFacetSearchCu: boolean;
  @Input() filterData: any;
  @Input() selectedEditRow: any = -1;

  listValues: any[];
  allValues: any[];
  filters = ['Starts With', 'Contains', 'Not Contains', 'Ends With', 'Equals', 'Not Equals'];
  cofiguration: any;
  cols: any[];
  nestedCols: any[];
  togglecols: any[];
  // nestedRows:boolean[];
  finalContentUrl: any;
  tableColouring: any;
  searchText: '';
  defaultTableColouring = {
    alternateBackground: false,
    cellHoverColor: '#FAFAFA',
    selectAlternatePattern: '#FFFFFF',
    tableBackground: '#FFFFFF',
    tableBorder: '#CCCCCC',
    tableHeader: '#F2F2F2',
    paginationPlacement: 'right',
  };
  exportOptions: any = [];
  attrOrder: any = [];
  nestedAttrOrder: any = [];
  selectedNestedRow: number = -1;
  tableConfiguration: any;
  freezeColumnName: any;
  freezeSelectionColumn: any;
  freezeserialum: any;
  newEnhancements: any;
  initialPageSize: any;
  pagination: any;
  tableDescription: any;
  tableSettings: any;
  selectedTableArray: any = [];
  isselectAllTable: boolean = false;
  popupclicked: boolean = false;
  isSearchClicked: boolean = false;
  exportColumns: any[];
  _selectedColumns: any[];
  closeInputField: boolean = false;
  searchByValue: '';
  title: any = 'Table UI Control';
  entityName: any = '';
  tableSelection: string;
  tenantName: string;
  maxCountOfOptions: any;
  gsiConfigurations: any = [];
  tableWidth: any;
  sortOption: any = '';
  sortOptionAttr: any = '';
  @Output() dateChangedEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedTransactionsEmit: EventEmitter<any> = new EventEmitter<any>(); // sending output to parent component
  @Output() removeEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitWaypointstoUpdateForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateTableData: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedEditableRow: EventEmitter<any> = new EventEmitter<any>();
  unSubscribe = new Subject();
  unsubscribe$ = new Subject();
  labels: any;
  csvData: any = [];
  csvColumns: any[];
  urls: any[];
  pdfData: any = [];
  @Input() selectedTransactionArray: any = [];
  @Input() isInfo: any;
  @Input() wayPointsData: any = [];
  selectedIndexes: any = [];
  slot: any;
  isMulti: any;
  jsondataforuploadFile: any;
  filteredValue: any;
  isFullScreen: boolean;
  paginationDetails: paginationDetails;
  customPagination = false;
  hideLabels: boolean = false;
  hideSelectAllButton: boolean = false;
  @ViewChild('vsTable') vsTable: Table;
  currentEventData: any = [];
  combineEventsData: any = [];
  tableFirstRowIndex: number = 0;
  newTabClicked: boolean;
  displayBasic2: Boolean = false;
  splitScreenclicked: boolean;
  splitViewData: any = [];
  rowsdataInputType: any;
  fileName: string;
  selectedIndex: any;
  viewOptionsInTable: boolean = false;
  singleRowUpload: boolean = false;
  evenRowHovered: number;
  oddRowHovered: number;
  columnHovered: number;
  fontHovered: boolean = false;
  fontHoveredTable: boolean = false;
  rowHovered: number;
  columnHighlight: any;
  numberOfButtons: any;
  isallowColumnSearch: boolean = false;
  searchQuery = new Subject();
  private ngUnsubscribe = new Subject();
  searchValue: string = '';
  entityListData: any = [];
  showPaginator: boolean = true;
  singlePageTable: boolean = false;
  extraWidthPercentSNo: string;
  extraWidthPercentSelectButton: string;
  rowConditionCheckedArray: any = [];
  rowConditionCheckedforRow: boolean = false;
  sideMenu_swiper_config: SwiperOptions = {
    loop: false,
    autoHeight: true,
    allowTouchMove: true,
    spaceBetween: 1,
    autoplay: false,
    slidesPerView: 'auto',
    pagination: { el: '.filter-swiper .swiper-pager', clickable: true },
    navigation: {
      nextEl: '.filter-swiper .swiper-next',
      prevEl: '.filter-swiper .swiper-prev',
    },
    roundLengths: true,
    slidesOffsetAfter: 0,
    centeredSlides: false,
  };
  rowSelection=['p-checkbox-icon','p-radiobutton-box','p-checkbox-box','table-w-fixed','ui-control-label'];
  parse: Function = JSON.parse;
  originalCols: any = [];
  role :any;

  constructor(
    public dialog: MatDialog,
    private translator: TranslatorService,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    private eventService: EventsFacadeService,
    private transactionFacade: TransactionFacadeService,
    private renderer: Renderer2,
    public eventsEndPoint: EventsEndpointService,

    // public injector: Injector,
  ) {
    // this.eventsEndPoint = injector.get(EventsEndpointService);
    this.detectLanguageChange();
    this.detectTableScrollEvent();
    this.filterSearch();
    this.detectDateChangedFromTableView();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectTableScrollEvent() {
    /* istanbul ignore next */
    this.eventService.tableScrollEvent.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let ind = this.eventService.tableScrollPosition.findIndex((ent: any) => ent?.name == this.entityName);
      if (ind == -1) {
        this.eventService.tableScrollPosition.push({
          name: this.entityName,
          scrollPosition: this.vsTable.containerViewChild.nativeElement.getElementsByClassName('p-datatable-wrapper')[0]
            .scrollLeft,
        });
      } else {
        this.eventService.tableScrollPosition[
          ind
        ].scrollPosition = this.vsTable.containerViewChild.nativeElement.getElementsByClassName(
          'p-datatable-wrapper'
        )[0].scrollLeft;
      }
    });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.cols?.filter((col) => val.includes(col));
  }

  ngOnChanges(simple: SimpleChanges) {
    /* istanbul ignore next*/
    this.entityListData = this.tempEntityList?.map((elem: any) => elem);
    this.slot = this.control?.value?.value?.slot;
    if (this.entityListData?.length > 0) {
      this.entityListData = this.entityListData?.filter((ent: any) => {
        return ent?.name == this.control?.key?.split('$')[0] && ent.slot == this.slot;
      });
    }
    this.tenantName = localStorage.getItem('TenantName');
    this.role = localStorage.getItem('selectedRole');
    console.log("role from table", this.role);
    if (
      simple?.selectedTransactionArray &&
      simple?.control?.currentValue?.key !== simple?.control?.previousValue?.key
    ) {
      this.selectedTransactionArray = [];
    }
    if (Object.keys(this.transactionFacade.paginationDetails).length) {
      this.customPagination = true;
      this.paginationDetails = this.transactionFacade.paginationDetails;
    } else {
      this.customPagination = false;
      this.paginationDetails = {} as paginationDetails;
    }

    /* istanbul ignore next*/
    if (this.control && !(Object.keys(simple)?.length == 1 && simple?.rowNumberChanged)) {
      this.prepareTableStructureData(this.control);
    }

    /* istanbul ignore next*/
    if (localStorage.getItem('expandTable')) {
      this.isFullScreen = localStorage.getItem('expandTable') == 'true' ? true : false;
      if (this.isFullScreen) {
        this.renderer.addClass(document.body, 'expand-table');
      }
    }

    if (
      simple?.wayPointsData?.currentValue.length > 0 &&
      simple?.wayPointsData?.currentValue?.length !== simple?.wayPointsData?.previousValue?.length
    ) {
      this.prepareWaypointsJSONObj();
    }
    this.isSearchClicked = false;
    if (this.selectedEditRow != -1) {
      // this.dialog.closeAll();
      this.editRow(this.selectedEditRow, true);
    }
  }

  /* Preapre the table structure data from the form */
  prepareTableStructureData(control: any) {
    /* istanbul ignore next*/

    if (
      (control.value.value.configurationType && control.value.value.configurationType == 'table') ||
      control.value.value.configurationType == 'verticalTable' ||
      control.value.value.configurationType == 'tableWithLabels'
    ) {
      /* istanbul ignore next */
      this.title = control.value.value.displayName ? control.value.value.displayName : control.key?.split('$')[0];
      this.entityName = control.key;
      this.slot = control.value.value.slot;
      this.isMulti = control.value.value.isMulti;
      this.cols = [];
      this.nestedCols = [];
      this.togglecols = [];
      this.listValues = [];
      let obj = {};
      let advancedFeatures: any = {};
      this.hideSelectAllButton = JSON.parse(control.value.value.configuration).hideSelectAll;
      this.cofiguration =
        control.value.value.configurationType == 'table' || control.value.value.configurationType == 'tableWithLabels'
          ? JSON.parse(control.value.value.configuration).setting
          : JSON.parse(control.value.value.configuration).configurationData;
      Object.keys(this.cofiguration).forEach((key) => {
        /*istanbul ignore else */
        if (key == 'allowColumnSearch') {
          this.isallowColumnSearch = true;
        }
      });
      this.cofiguration.tableComponentScale = this.cofiguration?.tableComponentScale || 'medium';
      this.singlePageTable = !!(this.cofiguration?.singlePageTable);
      this.tableConfiguration = JSON.parse(control.value.value.configuration);
      this.attrOrder =
        control.value.value.configurationType == 'table' || control.value.value.configurationType == 'tableWithLabels'
          ? JSON.parse(control.value.value.configuration).droppedAtt
          : JSON.parse(control.value.value.configuration).configurationData.attr;
      this.nestedAttrOrder =
        control.value.value.configurationType == 'table' || control.value.value.configurationType == 'tableWithLabels'
          ? this.tableConfiguration?.nestedRowDropAttr
          : this.tableConfiguration?.configurationData.nestedRowDropAttr;
      this.tableSettings = JSON.parse(control.value.value.configuration).tableSettings;
      this.tableSelection = JSON.parse(control.value.value.configuration).tableSelection;
      this.newEnhancements = JSON.parse(control.value.value.configuration).newTableConfiguration;
      this.gsiConfigurations = this.tableConfiguration?.actionButton;
      advancedFeatures = JSON.parse(control.value.value.configuration).advancedFeatures;
      if (advancedFeatures) {
        this.cofiguration.allowColumnFilter = this.cofiguration.allowColumnFilter || advancedFeatures.allowColumnFilter;
        this.cofiguration.allowColumnSearch = this.cofiguration.allowColumnSearch || advancedFeatures.allowColumnSearch;
        this.cofiguration.allowColumnsort = this.cofiguration.allowColumnsort || advancedFeatures.allowColumnsort;
        this.cofiguration.allowDownload = this.cofiguration.allowDownload || advancedFeatures.allowDownload;
        this.cofiguration.allowUpload = this.cofiguration.allowUpload || advancedFeatures.allowUpload;
        this.cofiguration.toggleColumn = this.cofiguration.toggleColumn || advancedFeatures.toggleColumn;
        this.cofiguration.filter = this.cofiguration.filter || advancedFeatures.filter;
        this.cofiguration.search = this.cofiguration.search || advancedFeatures.search;
        this.cofiguration.sort = this.cofiguration.sort || advancedFeatures.sort;
        this.cofiguration.facet = advancedFeatures.facet;
      }
      if (this.newEnhancements) {
        this.exportOptions = JSON.parse(control.value.value.configuration).export;
        this.pagination = JSON.parse(control.value.value.configuration).pagination;
        this.initialPageSize = JSON.parse(control.value.value.configuration).pagination?.rows;
        this.tableDescription = JSON.parse(control.value.value.configuration).tableDescription;
        this.setTableColours(control);
      } else {
        this.setDefaultValues();
      }
      if (this.tableSettings?.submitOnSelection) {
        this.eventService.hideSubmitButton.next({ currentCuId: this.eventService.currentCuId, hideSubmitButton: true });
      }
      this.hideLabels = this.cofiguration?.hideLabels;
      this.freezeColumnName = this.cofiguration?.freezeColumns;
      this.freezeserialum = this.cofiguration?.freezeSeNum;
      this.freezeSelectionColumn = this.cofiguration?.freezeSelection;
      for (let [key, value] of Object.entries(control.value.value)) {
        /* istanbul ignore next*/
        if (value?.constructor?.name === 'Array') {
          /* istanbul ignore next */
          this.cols.push({
            field: key,
            header: key?.split('$')[0],
            form: control.value.controls[key].controls[0],
            displayValue: value[0].attr_displayName,
            isOptional: control.value.controls[key].controls[0].value?.isOptional,
            attr_id: value[0]?.attr_id,
            searchText: '',
            hidden: false,
            attrConfiguration1: JSON.parse(value[0]?.attr_configuration),
          });
          obj[key.split('$')[0]] = value[0];
          if (value[0].attr_configuration && typeof value[0].attr_configuration === 'string') {
            let attr_config = JSON.parse(value[0].attr_configuration);
            this.viewOptionsInTable = attr_config?.viewOptionsInTable;
          }
        }
      }

      this.cols = this.cols?.filter((v, i, a) => a.findIndex((t) => t.header === v.header) === i);
      let listArray: any = [];
      for (let [key, value] of Object.entries(control.value.value)) {
        /* istanbul ignore next*/
        if (value?.constructor?.name === 'Array') {
          listArray.push({
            element: value[0],
            form: control.value.controls[key].controls[0],
            txnRecordId: control?.value?.value?.txnRecordId,
          });
        }
      }
      let rowObj = {};
      /* istanbul ignore next */
      listArray?.forEach((x: any, index: any) => {
        x.element['selectedRow'] = false;
        x.element['txnRecordId'] = x?.txnRecordId;
        x.element['containsOnSelectEvent'] = this.checkEventGSI(x.element);
        rowObj[x.element.label.split('$')[0]] = x.element;
        rowObj[x.element.label.split('$')[0] + 'form'] = x.form;

        /* istanbul ignore next*/
        if ((index + 1) % this.cols?.length === 0) {
          this.listValues.push(rowObj);
          rowObj = {};
        }
      });
    }
    if (this.tableConfiguration?.conditionArray && this.tableConfiguration?.conditionArray?.length > 0)
    this.checkForAttributeCondition();
    this.allValues = this.listValues;
    if (control.value.value.configurationType == 'verticalTable') {
      this.maxCountOfOptions = this.getOptionValueCount(control);
    }
    this.cols.forEach((col: any) => {
      this.listValues.forEach((rowObj: any) => {
        col.hidden = !rowObj[col.header].isInPotentiality || rowObj[col.header].isHidden || col.hidden;
      });
    });

    if (this.pagination && this.rowNumberChanged?.rows) {
      this.pagination.rows = this.rowNumberChanged.rows;
    } else if (this.pagination?.rows) {
      this.pagination.rows = parseInt(this.pagination.rows);
    }

    /* istanbul ignore next */
    if (this.customPagination && this.transactionFacade.selectedTableRecords[this.paginationDetails.pageNumber]) {
      let selectedData = this.transactionFacade.selectedTableRecords[this.paginationDetails.pageNumber];
      this.selectedTransactionArray = this.listValues?.filter((objFromA: any) => {
        return selectedData?.find((objFromB: any) => {
          let excludeArray: any = [];
          _.forOwn(objFromA, (value, key) => {
            if (key.includes('form')) {
              excludeArray.push(key);
            }
          });
          let objA = _.omit(objFromA, excludeArray);
          let objB = _.omit(objFromB, excludeArray);
          Object.values(objA).forEach((value: any) => {
            if (value.entity_txnRecordId) delete value.entity_txnRecordId;
            if (value?.txnRecordId) delete value.txnRecordId;
          });
          Object.values(objB).forEach((value: any) => {
            if (value.entity_txnRecordId) delete value.entity_txnRecordId;
            if (value?.txnRecordId) delete value.txnRecordId;
          });
          return _.isEqual(objA, objB);
        });
      });
      for (let i = 0; i < this.selectedTransactionArray.length; i++) {
        this.selectedIndexes.push(i);
      }
      const selectedRows = this.preparedObjectsFromTableRows();
      this.selectedTransactionsEmit.emit({
        entityName: this.entityName,
        selectedTransactions: selectedRows,
        selectedIndexes: this.selectedIndexes,
        selectedRowTxnRecordIds: this.selectedRowTxnRecordIds,
        slot: this.slot,
      });
    }

    if (this.selectedRowTxnRecordIds?.length > 0 && !this.customPagination) {
      if (this.selectedIndexes?.length > 0) this.selectedIndexes = [];
      this.selectedRowTxnRecordIds?.forEach((val: any) => {
        if (val) {
          let ind = this.entityListData?.findIndex((x: any) => x?.txnRecordId === val);
          if (ind !== -1) {
            this.selectedIndexes.push(ind);
          }
        }
      });
      this.selectedTransactionArray = this.listValues.filter((value: any, index: number) => {
        return this.selectedIndexes.includes(index);
      });
    }

    /* istanbul ignore next */
    if (
      this.tableSettings &&
      Object.keys(this.tableSettings)?.length > 0 &&
      !this.isInfo &&
      !this.control?.value?.value?.isOptional
    ) {
      /* istanbul ignore next*/
      if (this.tableSettings.minimumselectionAll) {
        this.selectedTransactionArray = this.listValues;
        /* istanbul ignore next */
        this.selectedIndexes = this.selectedTransactionArray?.length;
        /* istanbul ignore else */
        if (this.customPagination) {
          this.transactionFacade.selectedTableRecords[
            this.paginationDetails.pageNumber
          ] = this.selectedTransactionArray;
          /* istanbul ignore next */
          if (this.paginationDetails.pageNumber == 0) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber + 1
            ] = this.selectedTransactionArray;
          } else if (this.paginationDetails.pageNumber == 1) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber - 1
            ] = this.selectedTransactionArray;
          }
        }
      }
    }
    // this.cols = this.cols.filter((col) => col?.form?.controls?.['isHidden']?.value === false);
    if (this.nestedAttrOrder?.length > 0) {
      this.nestedCols =
        control.value.value.configurationType == 'table'
          ? this.nestedAttrOrder.map((column: any) => this.cols.find((header: any) => header?.displayValue === column))
          : [];
    }
    this.originalCols = this.cols;
    if (this.attrOrder?.length > 0) {
      let ind = this.attrOrder.findLastIndex((attribute: any) => attribute === 'Action');
      if(ind != -1){
        this.attrOrder.splice(ind,1);
        this.tableConfiguration.widthArray.splice(ind,1);
      }
      if(this.attrOrder?.length > 0){
        this.cols =
        control.value.value.configurationType == 'verticalTable'
          ? this.attrOrder.map((column: any) => this.cols[column])
          : this.attrOrder.map((column: any) => this.cols.find((header: any) => header.header === column));
      this.cols = this.cols.filter((col: any) => col);
      if (control.value.value.configurationType == 'table' && (this.rowConditionCheckedArray && this.rowConditionCheckedArray?.length > 0)){
        this.rowConditionCheckedArray.forEach((row:any,index:number) => {
          row = this.attrOrder.map((column: any) => row.find((obj: any) => obj.attrHeader == column));
          this.rowConditionCheckedArray[index] = row;
        });
      }
      }
    }
    this._selectedColumns = this.cols;
    this.setTableWidths(this.tableConfiguration.widthArray);
    this.togglecols = this.cols?.filter((column: any) => column?.form?.value?.isHidden == false);
    this.togglecols.forEach((col: any) => {
      col['label'] = col?.displayValue ? col?.displayValue : col?.header;
    });
    this.exportColumns = this.cols?.map((col) => ({ title: col?.header, dataKey: col?.field }));
    this.exportColumns.unshift({ title: 'SrNo', dataKey: 'srNo' });
    this.checkEventGSICombine(this.selectedColumns);
    this.checkMinMaxSelection();
  }
  @ViewChild('buttonContainer', { static: false }) buttonContainer: ElementRef | undefined;

  setTableWidths(widthArray: any) {
    this.tableWidth = 0;
    if (
      !this.cofiguration?.hideSelectButton &&
      (this.tableSelection !== 'single' || !this.cofiguration?.allowSelection)
    ) {
      this.tableWidth = this.tableWidth + 35;

    }
    if (!this.cofiguration?.hideSerialNumber) {
      this.tableWidth = this.tableWidth + 50;

    }
    if (this.tableConfiguration?.nestedRow) {
      this.tableWidth = this.tableWidth + 50;
    }
    if (widthArray && widthArray.length > 0) {
      this.cols?.forEach((attr: any, index: number) => {
        let width;
        if (
          attr?.containsOnSelectEvent &&
          this.tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
          this.tableConfiguration?.gsiConfigurations?.hideAttrName
        ) {
          width = '7';
        } else if (!attr.hidden) {
          width = widthArray?.[index] ? widthArray?.[index]?.split('p')[0] - 16 : '170';
        } else {
          width = '0';
        }
        this.tableWidth = this.tableWidth + Number(width);
      });
      this.cols?.forEach((attr: any, index: number) => {
        let width;
        if (
          attr?.containsOnSelectEvent &&
          this.tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
          this.tableConfiguration?.gsiConfigurations?.hideAttrName
        ) {
          width = '7px';
        } else {
          width = widthArray?.[index] ? widthArray?.[index] : '186px';
        }
        let colWidth =  Number(width.split('p')[0]) > 16 ? Number(width.split('p')[0]) - 16 :  Number(width.split('p')[0]);
        let colPer = (colWidth * 100) / this.tableWidth;
        widthArray[index] = !attr.hidden ? colPer.toString() + '%' : '0%';
        // widthArray[index] = colWidth.toString() + 'px';
      });
    } else {
      widthArray = [];
      let count=0
      this.cols?.forEach((attr:any)=>{
        if(!attr?.hidden){
          count=count+1;
        }
      })
      this.cols?.forEach((attr: any, index: number) => {
        if (!attr?.hidden) {
          let colPer = 100 / count;
          widthArray[index] = colPer.toString() + '%';
          // widthArray[index] = '160px';
          this.tableWidth = this.tableWidth + 160;
        }
      });
    }
    this.extraWidthPercentSelectButton = this.cofiguration?.hideSelectButton ? '' : ((35 / this.tableWidth) * 100).toString() + '%';
    this.extraWidthPercentSNo = this.cofiguration?.hideSerialNumber ? '' : ((50 / this.tableWidth) * 100).toString() + '%';
    this.tableWidth = this.tableWidth.toString() + 'px';
    this.tableConfiguration.widthArray = widthArray;
  }

  ngAfterViewInit(): void {
    /* istanbul ignore next */
    const found = this.eventService.tableScrollPosition.find((ent: any) => ent?.name == this.entityName);
    setTimeout(() => {
      /* istanbul ignore next */
      if (this.eventService.tableScrollPosition && found?.name == this.entityName) {
        this.vsTable.containerViewChild.nativeElement.getElementsByClassName('p-datatable-wrapper')[0].scrollLeft =
          found.scrollPosition;
      }
    }, 500);

    /* istanbul ignore next*/
    if (this.buttonContainer) {
      const buttons = this.buttonContainer?.nativeElement?.querySelectorAll('button');
      this.numberOfButtons = buttons?.length;
    }
  }

  getOptionValueCount(control: any) {
    let maxOptionsCount: Map<string, any> = new Map<string, any>();
    maxOptionsCount.set(control.key, 1);
    for (let [key, value] of Object.entries(control.value.value)) {
      /* istanbul ignore else */
      if (value?.constructor?.name === 'Array') {
        if (maxOptionsCount.get(control.key) < value[0]?.attr_options?.length) {
          maxOptionsCount.set(control.key, value[0]?.attr_options?.length);
        }
      }
    }
    return maxOptionsCount.get(control.key);
  }

  saveSearchFilters() {
    this.filterData.layers = this.filterData?.layers?.filter((layer: any) => {
      return layer?.type == 'information';
    });

    // this.filterData.layers[0].entityList = [];
    this.filterData.layers[0].entityList[0] = this.facetedData;
    this.transactionFacade.facetSearch(this.filterData, this.paginationDetails);
  }

  onFacetFilterChange(selectedOption: MatCheckboxChange, attrIdx: number, valueClear?: any) {
    if (attrIdx >= 0) {
      if (selectedOption.checked) {
        if (!this.facetedData.nslAttributes[attrIdx].values.includes(selectedOption.source.value))
          this.facetedData.nslAttributes[attrIdx].values.push(selectedOption.source.value);
      } else {
        if (this.facetedData.nslAttributes[attrIdx].values.includes(selectedOption?.source?.value || valueClear))
          this.facetedData.nslAttributes[attrIdx].values.pop(selectedOption?.source?.value || valueClear);
      }
    }
    this.saveSearchFilters();
  }

  clearAllFeatures() {
    this.facetedData?.nslAttributes?.forEach((attr: any) => {
      attr.values = [];
    });
    this.saveSearchFilters();
  }

  applyFilterAndSort() {
    let sortedData = {
      data: this.listValues,
      mode: 'single',
      field: this.sortOptionAttr,
      order: this.sortOption,
    };
    this.customSort(sortedData);
  }

  checkMinMaxSelection(change = false) {
    /* istanbul ignore next */
    if (
      this.tableSettings &&
      Object.keys(this.tableSettings)?.length > 0 &&
      !this.isInfo &&
      (!this.control?.value?.value?.isOptional || this.tableSettings?.submitOnSelection)
    ) {
      /* istanbul ignore next */
      if (
        this.selectedTransactionArray?.length >= this.tableSettings.minimumselection &&
        this.selectedTransactionArray?.length <= this.tableSettings.maximumselection
      ) {
        this.selectionEvent.emit({
          isSelected: false,
          submitOnSelection: this.tableSelection === 'single' && this.tableSettings?.submitOnSelection && change,
        });
      } else if (
        this.tableSettings.minimumselectionAll &&
        this.selectedTransactionArray?.length !== this.listValues?.length
      ) {
        if (
          localStorage.environmentName !== 'flatworldgistaging' &&
          this.tenantName !== 'healthcaremx' &&
          this.tenantName !== 'telceldev' &&
          this.tenantName !== 'callhealth' &&
          this.tenantName !== 'sdiwms'
        ) {
          /* istanbul ignore next */
          this.alertService.showToaster('You can select all table rows', '', 'info');
        }
        this.selectionEvent.emit({
          isSelected: true,
        });
      } else if (
        this.tableSettings.minimumselectionAll &&
        this.selectedTransactionArray?.length == this.listValues?.length
      ) {
        this.selectionEvent.emit({
          isSelected: false,
          submitOnSelection: this.tableSelection === 'single' && this.tableSettings?.submitOnSelection && change,
        });
      } else if (
        (this.tableSettings.minimumselection === null || this.tableSettings.minimumselection === undefined) &&
        (this.tableSettings.maximumselection === null || this.tableSettings.maximumselection === undefined) &&
        (this.tableSettings.minimumselectionAll === null ||
          this.tableSettings.minimumselectionAll === false ||
          this.tableSettings.minimumselectionAll === undefined)
      ) {
        this.selectionEvent.emit({
          isSelected: false,
          submitOnSelection: this.tableSelection === 'single' && this.tableSettings?.submitOnSelection && change,
        });
      } else {
        if (
          localStorage.environmentName !== 'flatworldgistaging' &&
          this.tenantName !== 'healthcaremx' &&
          this.tenantName !== 'telceldev' &&
          this.tenantName !== 'callhealth' &&
          this.tenantName !== 'sdiwms' &&
          !this.tableSettings?.submitOnSelection
        ) {
          /* istanbul ignore next */
          this.alertService.showToaster(
            'You can select minimum ' +
              this.tableSettings.minimumselection +
              ' and maximum ' +
              this.tableSettings.maximumselection,
            '',
            'info'
          );
        }
        this.selectionEvent.emit({
          isSelected: true,
        });
      }
    }
  }

  /** File upload function */
  onFileChange(ev: any) {
    this.singleRowUpload = false;
    let workBook: any = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    ev.target.value = '';
    /* istanbul ignore next */
    let fileType = file?.name?.substring(file?.name?.lastIndexOf('.') + 1);
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
      });
      jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {
          blankrows: false,
          raw: false,
          rawNumbers: true,
          header: 0,
          defval: '',
        });
        return initial;
      }, {});
      // /* istanbul ignore next */
      // if (fileType == 'csv') {
      //   this.jsondataforuploadFile = JSON.parse(JSON.stringify(jsonData)).Sheet1;
      // } else {
      //   this.jsondataforuploadFile = JSON.parse(JSON.stringify(jsonData)).data
      //     ? JSON.parse(JSON.stringify(jsonData)).data
      //     : JSON.parse(JSON.stringify(jsonData)).Sheet1;
      // }
      let tempArray: any[] = [];
      /* istanbul ignore next */
      for (const [key, val] of Object.entries(JSON.parse(JSON.stringify(jsonData)))) {
        tempArray = [...tempArray, ...(<[]>val)];
      }
      this.jsondataforuploadFile = tempArray;
      this.mapFileUploadDataToTable();
    };
    reader.readAsBinaryString(file);
  }

  /** mapping the upload file data to table */
  mapFileUploadDataToTable() {
    let attributes: any = [];
    /* istanbul ignore next */
    if (this.jsondataforuploadFile && this.jsondataforuploadFile?.length > 0 && this.jsondataforuploadFile[0]) {
      /* istanbul ignore next */
      if (this.jsondataforuploadFile[0].SrNo) {
        this.jsondataforuploadFile.forEach((ele: any) => {
          delete ele.SrNo;
        });
      }
      if (this.jsondataforuploadFile?.length == 1) {
        this.singleRowUpload = true;
      }
      for (let [key, _value] of Object.entries(this.jsondataforuploadFile[0])) {
        /* istanbul ignore next */
        attributes.push(this.originalCols?.findIndex((obj: any) => obj.displayValue == key));
      }
      const misMatch = attributes.some((el: any) => el == -1);
      /* istanbul ignore next */
      if (
        this.listValues &&
        Object.keys(this.listValues[0])?.length / 2 == Object.keys(this.jsondataforuploadFile[0])?.length &&
        !misMatch
      ) {
        if (this.isMulti) {
          /* istanbul ignore next */
          if (this.listValues?.length >= this.jsondataforuploadFile?.length) {
            this.attributeValuesMapping();
          } else {
            let tempFileData = [...this.jsondataforuploadFile];
            this.jsondataforuploadFile = [];
            /* istanbul ignore next */
            for (let i = 0; i < this.listValues?.length; i++) {
              /* istanbul ignore next */
              this.jsondataforuploadFile?.push(tempFileData[i]);
            }
            for (let value of this.listValues) {
              tempFileData.shift();
            }
            this.attributeValuesMapping();
            setTimeout(() => {
              this.fileUploadEvent.emit({ fileData: tempFileData });
            }, 100);
          }
        } else if (this.listValues.length >= this.jsondataforuploadFile?.length) {
          this.attributeValuesMapping();
        } else {
          /* istanbul ignore next */
          this.confirmationService.confirm({
            message:
              'No. of records in the table are less than the records of the uploaded file . Click on Yes to update only the table records or click No.',
            accept: () => {
              this.attributeValuesMapping();
            },
          });
        }
      } else {
        this.alertService.showToaster('Uploaded file is mismatched to the attributes', '', 'Error');
      }
    }
  }
  /** Mapping attribute values into the table */
  attributeValuesMapping() {
    /* istanbul ignore next */
    this.listValues?.forEach((obj: any, index: any) => {
      if (index < this.jsondataforuploadFile?.length) {
        for (let [_key, value] of Object.entries(obj)) {
          /* istanbul ignore next*/
          if (value.constructor?.name !== 'Object') {
            let formC: any = value;
            if (
              formC.value.inputType == 'date' ||
              formC.value.inputType == 'currentdate' ||
              formC.value.inputType == 'datetime'
            ) {
              /* istanbul ignore next */
              if (this.jsondataforuploadFile[index][formC.value.attr_displayName]) {
                if (formC.value.inputType == 'date') {
                  this.jsondataforuploadFile[index][formC.value.attr_displayName] = this.jsondataforuploadFile[index][
                    formC.value.attr_displayName
                  ].replace(/-/g, '/');
                }
                let uploadedDate = this.jsondataforuploadFile[index][formC.value.attr_displayName];
                let dateFormat = formC.value.attributeType?.properties?.format;
                /*istanbul ignore else */
                if (dateFormat == 'dd/MM/yyyy') {
                  const originalMoment = moment(uploadedDate, 'DD/MM/YYYY');
                  uploadedDate = originalMoment.format('MM/DD/YYYY');
                }
                const dd = new Date(uploadedDate);
                formC.get(formC.value.label).setValue(dd);
              }
            } else if (formC.value.inputType == 'currentdatetime') {
              const dd = new Date();
              formC.get(formC.value.label).setValue(dd);
            } else if (
              formC.value.inputType == 'multiselectdropdown' ||
              formC.value.inputType == 'checkbox' ||
              formC.value.inputType == 'chipsingleselection'
            ) {
              let arr: any = [];
              let temp = this.jsondataforuploadFile[index][formC.value.attr_displayName];
              arr = temp.split(',');
              arr.forEach((str: any) => {
                const ind = arr.indexOf(str);
                arr[ind] = str.trim();
              });
              formC.get(formC.value.label).setValue(arr);
            } else if (
              formC.value.inputType == 'mobilenumber' &&
              formC.value.attr_configuration &&
              JSON.parse(formC.value.attr_configuration)?.mobileFormat?.displayValue == 'USA'
            ) {
              formC
                .get(formC.value.label)
                .setValue(displayUSAMobileFormat(this.jsondataforuploadFile[index][formC.value.attr_displayName]));
            } else if (
              formC.value.inputType == 'mobilenumber' &&
              formC.value.attr_configuration && JSON.parse(formC.value.attr_configuration)?.mobileFormat?.displayValue == 'MEXICO'
            ) {
              formC
                .get(formC.value.label)
                .setValue(displayMexicoMobileFormat(this.jsondataforuploadFile[index][formC.value.attr_displayName]));
            }
             else {
              /* istanbul ignore next */
              formC.get(formC.value.label).setValue(this.jsondataforuploadFile[index][formC.value.attr_displayName]);
            }
          }
        }
      } else {
        this.listValues.splice(index);
      }
    });
    if (this.singleRowUpload) {
      this.tableUploaded.emit({ emitTableData: true, singleRowUpload: this.singleRowUpload });
    } else {
      this.tableUploaded.emit(true);
    }
  }


  /* sort functionality for table ui control*/
  customSort(event: SortEvent) {
    let updateData: any = [];
    for (let [key, value] of Object.entries(this.control.value.value)) {
      /* istanbul ignore next*/
      if (value?.constructor?.name === 'Array') {
        updateData.push(value[0]);
      }
    }
    /* istanbul ignore next */
    event.data?.forEach((el: any) => {
      for (let [key, value] of Object.entries(el)) {
        /* istanbul ignore next*/
        if (value.constructor?.name === 'Object') {
          const obj = updateData?.find((a: any) => {
            return a.label == value['label'];
          });
          /* istanbul ignore next*/
          if (obj) {
            value[value['label']] = obj[obj['label']];
          }
        }
      }
    });
    /* istanbul ignore next */
    const sortField = event.field?.split('$')[0];

    event?.data?.sort((data1: any, data2: any) => {
      let value1 = data1[sortField][data1[sortField]?.label];
      let value2 = data2[sortField][data2[sortField]?.label];
      let result = null;

      /* istanbul ignore next */
      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (!isNaN(value1) && !isNaN(value2)) {
        if (parseInt(value1) < parseInt(value2)) {
          result = -1;
        } else {
          result = parseInt(value1) > parseInt(value2) ? 1 : 0;
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);
      else if (Array.isArray(value1) && Array.isArray(value2)) result = value1[0][0].localeCompare(value2[0][0]);
      return event.order * result;
    });
  }

  filterSearch() {
    this.searchQuery
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.filterFunction(res[1], res[0], 'Contains', '');
        }
      });
  }

  clearSearchTexts(column?: any) {
    this.cols.forEach((col: any) => {
      if (col !== column) {
        col.searchText = '';
      }
    });
  }
  //Filter Function for table ui control
  filterFunction(field: any, value: any, filtersUsed: string, ele: any) {
    this.listValues = this.allValues;
    this.isSearchClicked = true;
    this.rowNumberChanged.first = 0;
    this.clearSearchTexts(field);
    let updateData: any = [];
    for (let [, value] of Object.entries(this.control.value.value)) {
      /* istanbul ignore next*/
      if (value?.constructor?.name === 'Array') {
        updateData.push(value[0]);
      }
    }
    /* istanbul ignore next */
    this.listValues?.forEach((el: any) => {
      for (let [, value] of Object.entries(el)) {
        /* istanbul ignore next*/
        if (value?.constructor?.name === 'Object') {
          const obj = updateData?.find((a: any) => {
            return a.label == value['label'];
          });
          /* istanbul ignore next*/
          if (obj) {
            value[value['label']] = obj[obj['label']];
          }
        }
      }
    });
    /* istanbul ignore next */
    const filterField = field.header?.split('$')[0];
    /* istanbul ignore next*/
    if (filtersUsed !== 'Clear' && value) {
      this.listValues = this.listValues?.filter((data: any) => {
        /* istanbul ignore next*/
        if ((data[filterField]?.inputType == 'label' || JSON.parse(data[filterField]?.attr_configuration)?.isReadOnly) && Array.isArray(data[filterField][data[filterField]?.label])) {
          this.filteredValue = data[filterField][data[filterField]?.label][0][0]?.toLocaleLowerCase();
        } else if (typeof data?.[filterField]?.[data?.[filterField]?.label] == 'number') {
          /* istanbul ignore next*/
          data[filterField][data[filterField].label] = data[filterField][data[filterField].label]?.toString();
          this.filteredValue = data[filterField][data[filterField]?.label]?.toLocaleLowerCase();
        } else {
          /* istanbul ignore else*/
          this.filteredValue = Array.isArray(data?.[filterField]?.[data?.[filterField]?.label]) ? data?.[filterField]?.[data?.[filterField]?.label].flat()[0] : data?.[filterField]?.[data?.[filterField]?.label]?.toLocaleLowerCase();
        }
        value = value?.toLocaleLowerCase();
        this.filteredValue = this.filteredValue?.toLocaleLowerCase();
        var lengthOfData = this.filteredValue?.length;
        switch (filtersUsed) {
          case 'Starts With': {
            return this.filteredValue.substr(0, value.length) === value;
          }
          case 'Contains': {
            return this.filteredValue.includes(value);
          }
          case 'Not Contains': {
            return !this.filteredValue.includes(value);
          }
          case 'Ends With': {
            return this.filteredValue.substr(lengthOfData - value.length) === value;
          }
          case 'Equals': {
            return this.filteredValue === value;
          }
          case 'Not Equals': {
            return this.filteredValue !== value;
          }
        }
      });
    } else {
      this.listValues = this.allValues;
      ele = '';
      this.searchText = '';
      this.clearSearchTexts();
    }
    if (this.listValues.length === 0) {
      this.alertService.showToaster('No Data Available', '', 'info');
      this.showPaginator = false;
    } else if (this.listValues.length < this.paginationDetails?.pageSize) {
      this.showPaginator = false;
    } else {
      this.showPaginator = true;
    }
  }

  /* Export csv file functionality*/
  exportCSVFile(pt: any) {
    this.csvData = this.formatDataForExportFiles();
    /* istanbul ignore next */
    let tableHeaders = this.originalCols?.filter((column: any) => column.form.value.isHidden == false);
    this.csvColumns = tableHeaders?.map((col) => ({ field: col.displayValue, header: col.displayValue }));
    /* istanbul ignore next */
    this.csvColumns.unshift({ field: 'SrNo', header: 'SrNo' });
    setTimeout(() => {
      pt.exportCSV();
    }, 1000);
  }

  /* Export pdf(Portrait) functionality*/
  exportPdf() {
    import('jspdf').then(({ jsPDF }) => {
      import('jspdf-autotable').then((autoTable) => {
        const doc = new jsPDF('p');
        this.formatDataForExportFiles();
        let tableHeaders = this.cols?.filter((column: any) => column.form.value.isHidden == false);
        tableHeaders = tableHeaders?.map((col) => ({ title: col.displayValue, dataKey: col.field }));
        if (this.cofiguration?.hideSerialNumber) {
          this.pdfData = this.pdfData?.filter((row: any) => row.splice(0, 1));
        } else {
          tableHeaders.unshift({ title: 'SrNo', dataKey: 'SrNo' });
        }
        (doc as any).autoTable(tableHeaders, this.pdfData);
        const filename = this.title + new Date() + '.pdf';
        doc.save(filename);
      });
    });
  }

  /* Export pdf(Landscape) functionality*/
  exportLandScapePdf() {
    import('jspdf').then(({ jsPDF }) => {
      import('jspdf-autotable').then((autoTable) => {
        const doc = new jsPDF('l');
        this.formatDataForExportFiles();
        let tableHeaders = this.cols?.filter((column: any) => column.form.value.isHidden == false);
        tableHeaders = tableHeaders?.map((col) => ({ title: col.displayValue, dataKey: col.field }));
        if (this.cofiguration?.hideSerialNumber) {
          this.pdfData = this.pdfData?.filter((row: any) => row.splice(0, 1));
        } else {
          tableHeaders.unshift({ title: 'SrNo', dataKey: 'SrNo' });
        }
        (doc as any).autoTable(tableHeaders, this.pdfData);
        const filename = this.title + new Date() + '.pdf';
        doc.save(filename);
      });
    });
  }

  /* format the data for csv, pdf, xslx */
  formatDataForExportFiles() {
    this.pdfData = [];
    let updateData: any = [];
    let fileData: any = [];
    for (let [, value] of Object.entries(this.control.value.value)) {
      /* istanbul ignore next*/
      if (value?.constructor?.name === 'Array' && !value?.[0]?.isHidden) {
        updateData.push(value[0]);
      }
    }

    /* istanbul ignore next */
    this.listValues?.forEach((el: any, index: any) => {
      let rowObj = { SrNo: ++index };
      for (let [, value] of Object.entries(el)) {
        /* istanbul ignore next*/
        if (value.constructor?.name === 'Object' && Object.keys(value).includes('isHidden') && !value['isHidden']) {
          const obj = updateData?.find((a: any) => {
            return a.label == value['label'];
          });
          /* istanbul ignore next*/
          if (obj) {
            if (
              obj.inputType == 'date' ||
              obj.inputType == 'datetime' ||
              obj.inputType == 'currentdatetime' ||
              obj.inputType == 'currentdate'
            ) {
              /* istanbul ignore next*/
              if (obj[obj['label']] !== '' && obj[obj['label']] !== null) {
                let dateVal = obj[obj['label']];
                if (obj.inputType === 'date' || obj.inputType === 'currentdate') {
                  dateVal = formatDate(new Date(dateVal), 'MM/dd/yyyy', 'en-US');
                } else if (obj.inputType === 'datetime' || obj.inputType === 'currentdatetime') {
                  dateVal = formatDate(new Date(dateVal), 'MM/dd/yyyy HH:mm:ss', 'en-US');
                }
                rowObj[value['attr_displayName']] = dateVal;
              } else {
                rowObj[value['attr_displayName']] = obj[obj['label']];
              }
            } else if (
              obj.inputType == 'number' ||
              obj.inputType == 'decimal' ||
              obj.inputType == 'currency' ||
              obj.inputType == 'metric'
            ) {
              if (obj[obj['label']] !== '') rowObj[value['attr_displayName']] = Number(obj[obj['label']]);
              else rowObj[value['attr_displayName']] = obj[obj['label']];
            } else if (obj.inputType == 'label' || JSON.parse(obj?.attr_configuration)?.isReadOnly) {
              if (Array.isArray(obj[obj['label']])) obj[obj['label']] = obj[obj['label']].flat();
              rowObj[value['attr_displayName']] = Array.isArray(obj[obj['label']])
                ? displayValue(obj[obj['label']][0])
                : displayValue(obj[obj['label']]);
            } else if (obj.inputType == 'checkbox' || obj.inputType == 'chipsingleselection') {
              rowObj[value['attr_displayName']] = String(obj[obj['label']]);
            } else if (obj?.inputType == 'multiselectdropdown' && Array.isArray(obj[obj['label']])) {
              rowObj[value['attr_displayName']] = [''];
              obj[obj['label']]?.forEach((val: any, i: number) => {
                if (val) rowObj[value['attr_displayName']] += String(val);
                if (i < obj[obj['label']].length - 1) rowObj[value['attr_displayName']] += ',';
              });
            } else {
              rowObj[value['attr_displayName']] = obj[obj['label']];
            }
          }
        }
      }
      fileData.push(rowObj);
      this.pdfData.push(Object.values(rowObj));
    });
    return fileData;
  }

  /* Export excel functionality*/
  exportExcel() {
    let sheetData = this.formatDataForExportFiles();
    let headerData: any = this.getColsHeaders();
    import('xlsx').then((xlsx) => {
      const wb = xlsx.utils.book_new();
      const worksheet = xlsx.utils.json_to_sheet(sheetData, { header: headerData });
      xlsx.utils.book_append_sheet(wb, worksheet, 'Sheet1');
      const filename = this.title + new Date().toDateString();
      const excelBuffer: any = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, filename);
    });
  }

  getColsHeaders() {
    let headerArray: any = [];
    headerArray.push('SrNo');
    this.originalCols.forEach((col: any) => {
      if(!col?.hidden){
        headerArray.push(col?.displayValue);
      }
    });
    return headerArray;
  }

  /* Save functionality for xlsx*/
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    import('file-saver').then((fileSaver) => {
      (fileSaver as any).default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }
  /* Send the selected transactions/table to dynamic-form submit*/
  selectedTransactions(event: any, index: any, direct = false) {
    if (
      this.rowSelection.some((element:any) => event?.target?.getAttribute('class')?.includes(element)) || direct
    ) {
      if (this.tableSelection == 'single') {
        let temp = direct ? this.listValues[index] : this.selectedTransactionArray;
        this.selectedTransactionArray = [];
        this.selectedTransactionArray.push(temp);
      } else if (this.tableSelection == 'multiple' && direct) {
        this.selectedTransactionArray.push(this.listValues[index]);
      }
      const selectedRows = this.preparedObjectsFromTableRows();
      if ((event?.currentTarget?.children[0]?.children[0]?.children[0]?.checked || direct) && index != -1) {
        if (this.tableSelection == 'single') {
          this.selectedRowTxnRecordIds = [];
          this.selectedIndexes = [];
        }
        this.selectedIndexes.push(index);
        this.selectedRowTxnRecordIds?.push(this.entityListData?.[index]?.txnRecordId);
        /* istanbul ignore else */
        if (this.customPagination) {
          this.transactionFacade.selectedTableRecords[
            this.paginationDetails.pageNumber
          ] = this.selectedTransactionArray;
          /* istanbul ignore next */
          if (this.paginationDetails.pageNumber == 0) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber + 1
            ] = this.selectedTransactionArray;
          } else if (this.paginationDetails.pageNumber == 1) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber - 1
            ] = this.selectedTransactionArray;
          }
        }
        this.selectedTransactionsEmit.emit({
          entityName: this.entityName,
          selectedTransactions: selectedRows,
          selectedIndexes: this.selectedIndexes,
          selectedRowTxnRecordIds: this.selectedRowTxnRecordIds,
          slot: this.slot,
        });
      } else if (index != -1) {
        this.selectedIndexes = this.selectedIndexes?.filter((ind: any) => ind != index);
        this.selectedRowTxnRecordIds = this.selectedRowTxnRecordIds?.filter(
          (i: any) => i != this.entityListData?.[index]?.txnRecordId
        );
        /* istanbul ignore else */
        if (this.customPagination) {
          this.transactionFacade.selectedTableRecords[
            this.paginationDetails.pageNumber
          ] = this.selectedTransactionArray;
          /* istanbul ignore next */
          if (this.paginationDetails.pageNumber == 0) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber + 1
            ] = this.selectedTransactionArray;
          } else if (this.paginationDetails.pageNumber == 1) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber - 1
            ] = this.selectedTransactionArray;
          }
        }
        this.selectedTransactionsEmit.emit({
          entityName: this.entityName,
          selectedTransactions: selectedRows,
          selectedIndexes: this.selectedIndexes,
          selectedRowTxnRecordIds: this.selectedRowTxnRecordIds,
          slot: this.slot,
        });
      } else {
        this.selectedIndexes = [];
        if (event?.currentTarget?.children?.[0]?.children?.[0]?.children?.[0]?.checked) {
          for (let i = 0; i < this.selectedTransactionArray.length; i++) {
            this.selectedIndexes.push(i);
            this.selectedRowTxnRecordIds?.push(this.entityListData?.[i]?.txnRecordId);
          }
        } else {
          this.selectedRowTxnRecordIds = [];
        }
        /* istanbul ignore else */
        if (this.customPagination) {
          this.transactionFacade.selectedTableRecords[
            this.paginationDetails.pageNumber
          ] = this.selectedTransactionArray;
          /* istanbul ignore next */
          if (this.paginationDetails.pageNumber == 0) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber + 1
            ] = this.selectedTransactionArray;
          } else if (this.paginationDetails.pageNumber == 1) {
            this.transactionFacade.selectedTableRecords[
              this.paginationDetails.pageNumber - 1
            ] = this.selectedTransactionArray;
          }
        }
        this.selectedTransactionsEmit.emit({
          entityName: this.entityName,
          selectedTransactions: selectedRows,
          selectedIndexes: this.selectedIndexes,
          selectedRowTxnRecordIds: this.selectedRowTxnRecordIds,
          slot: this.slot,
        });
      }
      this.checkMinMaxSelection(true);
    }
  }

  deleteEntityControl(rowIndex: any, rowData: any) {
    this.selectedTransactionArray = this.selectedTransactionArray?.filter((obj: any) => {
      return rowData[Object.keys(rowData)[0]].label != obj[Object.keys(obj)[0]].label;
    });
    if (
      this.selectedRowTxnRecordIds?.length > 0 &&
      this.selectedRowTxnRecordIds?.includes(this.entityListData?.[rowIndex]?.txnRecordId)
    ) {
      this.selectedRowTxnRecordIds = this.selectedRowTxnRecordIds?.filter(
        (rowId: any) => rowId !== this.entityListData?.[rowIndex]?.txnRecordId
      );
      this.selectedIndexes = this.selectedIndexes?.filter((ent: any) => ent !== rowIndex);
      this.selectedIndexes.forEach((value: any, index: any) => {
        if (value > rowIndex) {
          this.selectedIndexes[index]--;
        }
      });
    }
    this.removeEntity.emit({
      entityName: this.entityName,
      index: rowIndex,
      slot: this.slot,
      selectedRows: this.selectedTransactionArray,
      rowData: rowData,
    });
    this.selectedTransactionArray.length = 0;
    this.checkMinMaxSelection();
  }
  paginate(event: any) {
    /* istanbul ignore else*/
    if (event?.first >= 0) {
      this.tableFirstRowIndex = event?.first;
    }
    this.pagination.rows = event.rows;
    this.rowNumber.emit({ rows: event.rows, first: event?.first });
    if (this.control && !this.isSearchClicked) {
      this.prepareTableStructureData(this.control);
    }
  }

  preparedObjectsFromTableRows() {
    let entities: any = [];
    this.selectedTransactionArray.forEach((obj: any) => {
      let entity: any = { nslAttributes: [] };
      for (let [key1, value] of Object.entries(obj)) {
        /* istanbul ignore next */
        if (value?.constructor?.name === 'Object') {
          entity.nslAttributes.push(this.prepareObgectfromArry(value, key1));
        }
      }
      entities.push(entity);
    });
    return entities;
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  prepareObgectfromArry(values: any, key: any) {
    let obj = {};
    /* istanbul ignore next */
    obj['id'] = values?.attr_id;
    /* istanbul ignore next */
    obj['attr_order_num'] = values?.attr_order_num;
    /* istanbul ignore next */
    obj['type'] = values?.attrtype;
    /* istanbul ignore next */
    obj['label'] = values?.labelType;
    /* istanbul ignore next */
    obj['name'] = values?.label?.split('$')[0];
    /* istanbul ignore next */
    obj['displayName'] = values?.attr_displayName;
    /* istanbul ignore next */
    obj['options'] = values?.attr_options;
    /* istanbul ignore next */
    obj['isMulti'] = values?.isMulti;
    /* istanbul ignore next */
    obj['constraints'] = values?.constraints;
    /* istanbul ignore next */
    obj['uiElement'] = values?.inputType;
    /* istanbul ignore next */
    obj['isInPotentiality'] = values?.isInPotentiality;
    /* istanbul ignore next */
    obj['isHidden'] = values?.isHidden; // FE757 - For Hidden Attribute - Attribute level
    /* istanbul ignore next */
    obj['isDisabled'] = values?.isDisabled;
    /* istanbul ignore next */
    obj['triggerConditionalPotentiality'] = values?.triggerConditionalPotentiality;
    /* istanbul ignore next */
    obj['isOptional'] = values?.isOptional;
    /* istanbul ignore next */
    obj['isNegative'] = values?.isNegative;
    /* istanbul ignore next */
    if (values?.recordId && values?.recordId != '') {
      obj['recordId'] = values?.recordId;
    }
    /* istanbul ignore next */
    if (values?.alternativeChangeDriver && values?.alternativeChangeDriver != '') {
      obj['alternativeChangeDriver'] = values?.alternativeChangeDriver;
    }
    /* istanbul ignore next */
    if (values?.isOptional && values?.isOptional != '') {
      obj['isOptional'] = values?.isOptional;
    }
    /* istanbul ignore next */
    if (values?.isNegative && values?.isNegative != '') {
      obj['isNegative'] = values?.isNegative;
    }
    /* istanbul ignore next */
    if (values?.isHidden && values?.isHidden != '') {
      obj['isHidden'] = values?.isHidden;
    }

    obj['values'] = <any>[];
    //values.forEach((element: any) => {
    /* istanbul ignore next */
    if (values.inputType == 'file') {
      /* istanbul ignore next */
      if (values[values.label]?.length > 0) {
        /* istanbul ignore next */
        if (typeof values[values.label] == 'string') {
          obj['values'].push(values[values.label]);
        } else {
          for (let value of values[values.label]) {
            /* istanbul ignore next */
            if (typeof value == 'object') {
              obj['values'].push(JSON.stringify(value));
            } else {
              obj['values'].push(value);
            }
          }
        }
      } else {
        obj['values'] = [];
      }
    } else if (values.inputType == 'dateRange') {
      let value;
      if (values[values.label]) {
        value = this.IsJsonString(values[values.label]) ? values[values.label] : JSON.stringify(values[values.label]);
      } else {
        value = '';
      }
      obj['values'].push(value);
    } else if (values.inputType == 'checkbox' || values.inputType == 'multiselectdropdown') {
      if (values[values.label] == '') {
        obj['values'] = [];
      } else {
        obj['values'] = Array.isArray(values[values.label][0]) ? values[values.label][0] : values[values.label];
      }
    } else if (values.inputType == 'image' || values.inputType == 'video' || values.inputType == 'audio') {
      obj['values'] = values[values.label][0];
    } else if (values.inputType == 'location' || values.inputType == 'signature') {
      obj['values'] = typeof values[values.label] === 'object' ? [JSON.stringify(values[values.label])] : [];
    } else {
      const value = values[values.label];
      obj['values'].push(value);
    }
    values.value = values[values.label];
    //});
    return obj;
  }

  selectRows(event: any) {}

  zoomTbl() {
    this.renderer.addClass(document.body, 'expand-table');
    this.isFullScreen = true;
    localStorage.setItem('expandTable', JSON.stringify(this.isFullScreen));
  }

  minimizeTbl() {
    this.renderer.removeClass(document.body, 'expand-table');
    this.isFullScreen = false;
    localStorage.setItem('expandTable', JSON.stringify(this.isFullScreen));
  }
  openpopup(rowData: any, attribute: any) {
    this.popupclicked = true;
    let displyUrls = [];
    displyUrls.push(JSON.parse(attribute?.value)?.contentUrl);
    this.dialog.open(DialogComponent, {
      width: '56%',
      data: displyUrls,
      panelClass: 'splitscreenpop',
    });
  }
  newtab(rowData: any, attribute: any, attr?: any) {
    this.newTabClicked = true;
    if (attribute?.value) {
      let displayDataForNewTab = JSON.parse(attribute?.value)?.contentUrl;
      window.open(displayDataForNewTab, '_blank');
    } else {
      this.finalContentUrl = this.control?.value?.controls[rowData[attr]?.label]?.value[0]?.value[0]?.contentUrl;
      window.open(this.finalContentUrl, '_blank');
    }
  }
  splitScreen(rowData: any, attribute: any, attr?: any) {
    this.splitScreenclicked = true;
    this.displayBasic2 = true;
    let contentURLArray = [];
    this.rowsdataInputType = attribute?.inputType;
    if (attribute?.value) {
      contentURLArray?.push(JSON.parse(attribute?.value)?.contentUrl);
      this.splitViewData = contentURLArray;
    } else {
      this.splitViewData = [];
      this.splitViewData.push(this.control?.value?.controls[rowData[attr]?.label]?.value[0]?.value[0]?.contentUrl);
    }
    this.renderer.addClass(document.body, 'expand-table');
  }
  setTableColours(control: any) {
    this.tableColouring = JSON.parse(control.value.value.configuration).colouring;
    this.tableColouring.tableHeader = this.tableColouring?.tableHeader
      ? this.tableColouring?.tableHeader
      : this.defaultTableColouring.tableHeader;
    this.tableColouring.tableBorder = this.tableConfiguration?.gridLines?.outLine?.selected
      ? this.tableConfiguration?.gridLines?.outLine?.borderColor
      : this.defaultTableColouring.tableBorder;
    this.pagination.placementOfPagination = this.pagination?.placementOfPagination
      ? this.pagination?.placementOfPagination
      : this.defaultTableColouring.paginationPlacement;
    /* istanbul ignore next*/
    if (this.tableColouring) {
      document.documentElement.style.setProperty('--backgroundcell-color', this.tableColouring.tableBackground);
      document.documentElement.style.setProperty('--cellHoverColor-color', this.tableColouring.cellHoverColor);
      document.documentElement.style.setProperty('--combination-border-color', this.tableColouring.tableBorder);
      /* istanbul ignore next*/
      if (this.tableColouring.alternateBackground) {
        document.documentElement.style.setProperty(
          '--selectAlternatePattern-color',
          this.tableColouring.selectAlternatePattern
        );
      } else {
        document.documentElement.style.setProperty(
          '--selectAlternatePattern-color',
          this.defaultTableColouring.selectAlternatePattern
        );
      }
    }
    /* istanbul ignore next */
    if (this.pagination?.placementOfPagination) {
      document.documentElement.style.setProperty('--pagination-placement', this.pagination.placementOfPagination);
    }
    document.documentElement.style.setProperty('--header-color', this.tableColouring.tableHeader);
    document.documentElement.style.setProperty('--table-border-color', this.tableColouring.tableBorder);
  }

  setDefaultValues() {
    this.tableColouring = this.defaultTableColouring;
    document.documentElement.style.setProperty('--backgroundcell-color', this.tableColouring.tableBackground);
    document.documentElement.style.setProperty('--cellHoverColor-color', this.tableColouring.cellHoverColor);
    document.documentElement.style.setProperty('--header-color', this.tableColouring.tableHeader);
    document.documentElement.style.setProperty('--table-border-color', this.tableColouring.tableBorder);
    document.documentElement.style.setProperty('--pagination-placement', this.tableColouring.placementOfPagination);
    document.documentElement.style.setProperty(
      '--selectAlternatePattern-color',
      this.tableColouring.selectAlternatePattern
    );
    this.initialPageSize = 10;
    this.pagination = {
      rows: 10,
      placementOfPagination: 'center',
    };
    this.exportOptions = [
      {
        label: 'Pdf',
        isChecked: true,
      },
      {
        label: 'CSV',
        isChecked: true,
      },
      {
        label: 'Excel',
        isChecked: true,
      },
    ];
    this.cofiguration.searchFields = this.cofiguration.searchFields?.length > 0 ? this.cofiguration.searchFields : [];
    this.cofiguration.sortFields = this.cofiguration.sortFields?.length > 0 ? this.cofiguration.sortFields : [];
    this.cofiguration.filterFields = this.cofiguration.filterFields?.length > 0 ? this.cofiguration.filterFields : [];
    this.cofiguration = this.cofiguration ? this.cofiguration : {};
    this.cofiguration.allowExpand = true;
  }

  onPageChange(pageData: any) {
    /*istanbul ignore else*/
    if (this.customPagination) {
      this.transactionFacade.previousPageNumber = this.paginationDetails.pageNumber;
      this.transactionFacade.presentPageNumber = pageData.page + 1;
    }
    this.paginationDetails.pageNumber = pageData.page+1;
    this.pageChanged.emit(this.paginationDetails);
  }

  // Check event data based on attribute id
  checkEventGSI(attr: any) {
    /*istanbul ignore next*/
    this.currentEventData = getCurrentEventDataArray(this.eventCUList, attr);
    if (this.currentEventData.length > 0) {
      // if (JSON.parse(attr.attr_configuration)?.clickableConfig?.enableClickableCustomization) {
      //   let res = {
      //     id: attr?.attr_id + '_' + attr?.slotNumber,
      //     event: this.currentEventData[0],
      //   };
      //   this.eventsEndPoint.emitEventClickable.next(res);
      // }
      return true;
    }
    return false;
  }

  checkEventGSICombine(attribute: any) {
    this.combineEventsData = [];
    attribute.forEach((attr: any) => {
      let eventsArray = getCurrentEventDataArray(this.eventCUList, attr.form.value);
      if (eventsArray.length > 0) {
        this.combineEventsData.push(eventsArray);
        this.combineEventsData = this.combineEventsData.flat();
      }
    });
  }

  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any, position = 'inline') {
    if (position === 'onTop') {
      if (this.selectedTransactionArray.length == 0) {
        this.alertService.showToaster('Select atleast one table row', '', 'info');
        return false;
      } else {
        let data: any;
        data = attrData.find((attr: any) => attr.form.value.attr_id == currEvent.changeDriverId);
        attrData = data.form.value;
      }
    }
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['onTop'] = position == 'onTop';
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacade.dataForCheckForEvents = data;
    this.transactionFacade.setGsiDraftData(currEvent);
  }

  clickOnRow(event: any, rowIndex: any) {
    if (this.tableSelection === 'single' && this.cofiguration?.allowSelection) {
      this.selectedTransactions(event, rowIndex, true);
    }
  }

  onPage(event: any) {
    /* istanbul ignore next */
  }

  handleOnPanelShow() {
    this.renderer.addClass(document.body, 'columnSelectClass');
  }

  handleOnPanelHide() {
    this.renderer.removeClass(document.body, 'columnSelectClass');
  }

  downloadTemplate() {
    /* istanbul ignore else */
      this.transactionFacade.downloadFile(this.cofiguration?.inputTemplate ? this.cofiguration?.inputTemplate : this.tableConfiguration?.advanceTableFeatures?.tableTemplate);
  }
  searchClicked(event: any, index: number) {
    this.selectedIndex = index;
    this.isSearchClicked = true;
    this.searchValue = '';
    event.stopPropagation();
  }
  closeInput() {
    this.closeInputField = true;
    this.isSearchClicked = false;
    this.selectedIndex = -1;
    this.listValues = this.allValues;
    this.searchValue = '';
    this.showPaginator = true;
  }
  innerSearch(event: any) {
    event.stopPropagation();
  }

  setHoverColors(rowIndex: number, colIndex?: number) {
    this.evenRowHovered = rowIndex;
    this.oddRowHovered = rowIndex;
    this.columnHovered = colIndex;
  }

  setOutHoverColors(rowIndex: number, colIndex?: number) {
    this.oddRowHovered = -1;
    this.evenRowHovered = -1;
    this.columnHovered = -1;
  }

  filterContainsWith(event: any, field: any, value2: any) {
    if (value2 == '') {
      this.listValues = this.allValues;
    } else {
      let updateData: any = [];
      for (let [, value] of Object.entries(this.control.value.value)) {
        /* istanbul ignore next*/
        if (value?.constructor?.name === 'Array') {
          updateData.push(value[0]);
        }
      }
      /* istanbul ignore next */
      this.listValues?.forEach((el: any) => {
        for (let [, value] of Object.entries(el)) {
          /* istanbul ignore next*/
          if (value?.constructor?.name === 'Object') {
            const obj = updateData?.find((a: any) => {
              return a.label == value['label'];
            });
            /* istanbul ignore next*/
            if (obj) {
              value[value['label']] = obj[obj['label']];
            }
          }
        }
      });
      const filterField = field.header?.split('$')[0];
      this.listValues = this.listValues?.filter((data: any) => {
        /* istanbul ignore next*/
        if (
          (data[filterField]?.inputType === 'label' || JSON.parse(data[filterField]?.attr_configuration)?.isReadOnly) &&
          Array.isArray(data?.[filterField][data?.[filterField]?.label])
        ) {
          data[filterField][data[filterField].label] = data?.[filterField][data[filterField]?.label].flat();
        }
        let value1 = Array.isArray(data?.[filterField][data[filterField]?.label])
          ? data?.[filterField][data[filterField]?.label]?.[0]
          : data?.[filterField][data[filterField]?.label];
        /* istanbul ignore next*/
        if (!isParseNumber(value1) && !isParseNumber(value2)) {
          value1 = value1.toLocaleLowerCase();
          value2 = value2.toLocaleLowerCase();
          return value1.includes(value2);
        } else {
          return Number(value1) === Number(value2);
        }
      });
    }
  }

  dateChnagedEvent(event: any) {
    this.dateChangedEmit.emit(event);
  }

  detectDateChangedFromTableView() {
    // this.eventService.tableEachRow$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
    //   this.dateChangedEmit.emit(res);
    // });
  }

  highlightColumn(col: any) {
    if (this.columnHighlight == col) {
      this.columnHighlight = {};
    } else {
      this.columnHighlight = col;
    }
  }

  prepareWaypointsJSONObj() {
    if (this.entityName.split('$')[0] === 'Details of Optimization') {
      this.jsondataforuploadFile = [];
      this.wayPointsData.forEach((waypoint: any, index: number) => {
        this.jsondataforuploadFile.push({ Alphabet: String.fromCharCode(65 + index), Address: waypoint });
      });
      this.mapFileUploadDataToTable();
      this.emitWaypointstoUpdateForm.emit(true);
    }
  }

  updateTable() {
    this.updateTableData.emit(true);
  }
  editRow(index: any, formReload?: boolean) {
    if (!formReload) {
      this.selectedEditableRow.emit({
        selectedEditableRow: index,
      });
    }

    let data: any = {
      totalData: this.listValues,
      index: index,
    };
    // const dialogRef = this.dialog.open(TableViewComponent, {
    //   panelClass: 'table-view',
    //   data: data,
    // });

    // dialogRef
    //   .afterClosed()
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((result) => {
    //     /*istanbul ignore else*/
    //     this.selectedEditableRow.emit({
    //       selectedEditableRow: -1,
    //     });
    //     // this.selectedEditRow = null;
    //   });
  }

  updateFlagsForGridTable() {
    this.tableConfiguration.gridLines = {
      outLine: { selected: true },
      rows: { selected: true },
      column: { selected: true },
    };
  }

  checkConditon(value: string, condition: string, selectedConditonValue: string, type: string): boolean {
    const updatedValue = type === 'number' ? parseInt(value) : value;
    switch (condition) {
      case '==': {
        return updatedValue == selectedConditonValue;
      }
      case '!=': {
        return updatedValue != selectedConditonValue;
      }
      case '<=': {
        return updatedValue <= selectedConditonValue;
      }
      case '>=': {
        return updatedValue >= selectedConditonValue;
      }
      case '<': {
        return updatedValue < selectedConditonValue;
      }
      case '>': {
        return updatedValue > selectedConditonValue;
      }
    }
  }

  conditionalAttributeColor: string;
  checkForAttributeCondition() {
    this.rowConditionCheckedArray = [];
    let i = 0;
    this.listValues.forEach((rowData: any) => {
      this.rowConditionCheckedArray.push([]);
      for (let [key, value] of Object.entries(rowData)) {
        if (value?.constructor?.name == 'Object') {
          let val: any = value;
          this.rowConditionCheckedforRow = false;
          this.conditionalAttributeColor = '';
          for (let i = 0; i < this.tableConfiguration?.conditionArray?.length; i++) {
            let attrCondition = this.tableConfiguration?.conditionArray[i];
            if (attrCondition?.conditionAttribute == key) {
              if (
                this.checkConditon(
                  Array.isArray(val?.value) ? val?.value?.[0] : val?.value,
                  attrCondition?.condition,
                  attrCondition?.selectedConditonValue,
                  val?.attributeType?.type
                )
              ) {
                this.rowConditionCheckedforRow = true;
                this.conditionalAttributeColor = attrCondition?.fillColor;
              }
            }
          }
          this.rowConditionCheckedArray[i].push({
            checked: this.rowConditionCheckedforRow,
            fillColor: this.conditionalAttributeColor,
            attrId: val.attr_id,
            attrHeader:val.label.split('$')[0] ,
          });
        }
      }
      i++;
      // for (let i = 0; i < this.tableConfiguration?.conditionArray?.length; i++) {
      //   let attrCondition = this.tableConfiguration?.conditionArray[i];
      //   for (let [key, value] of Object.entries(rowData)) {
      //     if (attrCondition?.conditionAttribute == key) {
      //       let val: any = value;
      //       if (
      //         this.checkConditon(
      //           Array.isArray(val?.value) ? val?.value?.[0] : val?.value,
      //           attrCondition?.condition,
      //           attrCondition?.selectedConditonValue,
      //           val?.attributeType?.type
      //         )
      //       ) {
      //         this.rowConditionCheckedforRow = true;
      //         this.conditionalAttributeColor = attrCondition?.fillColor;
      //         this.rowConditionCheckedArray.push({
      //           checked: this.rowConditionCheckedforRow,
      //           fillColor: this.conditionalAttributeColor,
      //         });
      //         return;
      //       }
      //     }
      //   }
      //   if (this.rowConditionCheckedforRow) return;
      // }
      // if (!this.rowConditionCheckedforRow)
      //   this.rowConditionCheckedArray.push({
      //     checked: this.rowConditionCheckedforRow,
      //     fillColor: this.conditionalAttributeColor,
      //   });
    });
  }

  getClickableOnSelectData(res: any) {
    Object.keys(this.control?.value?.controls).forEach((each: any) => {
      let arr = each.split('$');
      if (arr.length > 1) {
        if (
          this.control?.value?.controls[each]?.value?.[0]?.attr_id == res.id &&
          res.slot == this.control?.value?.controls[each]?.value?.[0]?.slotNumber &&
          each == res.name
        ) {
          this.saveEventGSI(res.event, this.control?.value?.controls[each].value[0]);
        }
      }
    });
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
