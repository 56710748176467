<link
  *ngIf="fontUrl"
  [href]="fontUrl | safeResourceUrl"
  rel="stylesheet"
  type="text/css"
/>
<div
  class="cuLayouts layoutStudioverse {{ cuLayoutTemplate.template }} {{
    tenantName
  }}"
  id="layoutStudioverse_{{ cuLayoutTemplate.template }}"
  *ngIf="
    cuLayoutTemplate.template !== 't-1' &&
    templateType !== 't-13' &&
    !isCarnival &&
    !isOnboarding &&
    cuLayoutTemplate.template !== 't-13'
  "
  [ngClass]="{
    cuButtonFixed: buttonAction?.positionFixed,
    cuLayouts_extraLarge: cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE',
    cuLayouts_medium: cuLayoutTemplate?.uiControlSize === 'MEDIUM',
    cuLayouts_small: cuLayoutTemplate?.uiControlSize === 'SMALL'
  }"
>
  <div
    class="cuLayouts_colorbg nh-z-n1 position-fixed nh-start-0 nh-top-0 nh-hp-100 nh-wp-100"
    id="background_{{
      getFileNameFromImgUrl(
        bgStyleContent.backgroundImage
          ? bgStyleContent.backgroundImage
          : cuLayoutTemplate?.background?.color
      )
    }}"
    [ngStyle]="{
      'background-image': bgStyleContent.backgroundImage
        ? bgStyleContent.backgroundImage
        : 'none',
      'background-color': cuLayoutTemplate?.background?.color
        ? cuLayoutTemplate.background.color
        : 'transparent',
      opacity: bgStyleContent.backgroundImage
        ? (bgStyleContent.opacity * 100) / 100
        : 1
    }"
  ></div>
  <div
    class="cuLayouts_imagebg nh-z-n1 position-fixed nh-start-0 nh-top-0 nh-hp-100 nh-wp-100"
    id="background_{{
      getFileNameFromImgUrl(cuLayoutTemplate?.background?.url)
    }}"
    *ngIf="templateType !== 't-13' && !isCarnival && !isOnboarding"
  >
    <img
      *ngIf="cuLayoutTemplate?.background?.url"
      [src]="cuLayoutTemplate?.background?.url"
      alt="image"
      class="nh-hp-100 nh-wp-100"
    />
  </div>
  <div class="layoutStudioverse_content">
    <!--Ads top start-->
   <app-advertisement-panel 
      [transactionCU]="transactionCU"> 
    </app-advertisement-panel> 
    <!--Ads top end-->
    <div
      class="nhCard_header"
      *ngIf="layoutConfigurations?.gsiNameVisible && !isHideGsi"
    >
      <div class="layoutStudioverse_container mx-auto">
        <div class="nhCard_header_inner nh-overflow-hidden">
          <h5
            class="text-truncate nh-fontFamily nh-gsiColor nh-fw-700"
            [ngClass]="{
              'nh-fs-14': isIpadLandscape,
              'nh-gsi-fontsize': !isIpadLandscape
            }"
          >
            {{ gsiName }}
          </h5>
        </div>
      </div>
    </div>
    <div
      class="layoutStudioverse_container mx-auto"
      [ngClass]="{
        'layoutStudioverse_container--full': layoutConfigurations?.containerFull
      }"
    >
      <div class="nhCard">
        <div
          class="nhCard_header"
          *ngIf="layoutConfigurations?.gsiNameVisible01"
        >
          <div
            class="d-flex align-items-center nhCard_header_inner nh-overflow-hidden"
          >
            <button
              role="button"
              class="nh-unsetAll cursor-pointer nh-lh-n"
              (click)="goBack()"
            >
              <em
                class="nh-icon nh-icon-chevron-left nh-gsiColor"
                [ngClass]="{
                  'nh-fs-14': isIpadLandscape,
                  'nh-gsi-fontsize': !isIpadLandscape
                }"
              ></em>
            </button>
            <h5
              class="text-truncate nh-fontFamily nh-gsiColor nh-fw-700"
              [ngClass]="{
                'nh-fs-14': isIpadLandscape,
                'nh-gsi-fontsize': !isIpadLandscape
              }"
              *ngIf="!isHideGsi"
            >
              {{ gsiName }}
            </h5>
          </div>
        </div>
        <div class="nhCard_body">
          <div class="row nhCard_row">
            <div
              *ngIf="!layoutConfigurations?.hidePhyLayer"
              class="nhCard_row_col nhCard_row_left {{
                layoutConfigurations?.classLeft
              }}"
              [ngClass]="{
                'nhCard_row_col-empty': infoDisplayNone
              }"
            >
              <div class="nhCard_row_left_inner">
                <app-physical-layer
                  [isTransactionActive]="isTransactionActive"
                  [layoutConfigurations]="layoutConfigurations"
                  class="app-physical-layer"
                  [tempInfoEntList]="tempInfoEntList"
                  [estimatedTime]="selectedCu && selectedCu.estimatedTime"
                  [entitiesList]="physicalEntities"
                  [layoutSetting]="templateType"
                  [isReadOnly]="isReadOnly"
                  (emitFormData)="getFormData($event)"
                  (formValueChanges)="dynamicformValueChange($event)"
                  [gsi]="gsi"
                  [currentCu]="selectedCu"
                  (entityDataEmitForTable)="entityDataForTable($event)"
                  [cuLayoutTemplate]="cuLayoutTemplate"
                  (addMultipleEntities)="addMultipleEntities($event)"
                  [selectedCard]="selectedCard"
                >
                </app-physical-layer>
              </div>
            </div>
            <div
              *ngIf="!layoutConfigurations?.hideInfoLayer"
              class="nhCard_row_col nhCard_row_right {{
                layoutConfigurations?.classRight
              }}"
              [ngClass]="{
                'nhCard_row_col-empty': infoDisplayNone
              }"
            >
              <div
                class="nhCard_row_right_inner"
                *ngIf="
                  numberOfLayers &&
                  (numberOfLayers[0]?.type == 'information' ||
                    numberOfLayers[1]?.type == 'information' ||
                    numberOfLayers[2]?.type == 'information')
                "
                [ngStyle]="{ display: isFacetSearchCu ? 'none' : '' }"
              >
                <app-info-layer
                  [isTransactionActive]="isTransactionActive"
                  (emitFormData)="getFormData($event)"
                  [layoutConfigurations]="layoutConfigurations"
                  class="app-info-layer"
                  [entitiesList]="informationEntities"
                  [isReadOnly]="isReadOnly"
                  (infoLayerformValueChanges)="dynamicformValueChange($event)"
                  [gsi]="gsi"
                  [currentCu]="selectedCu"
                  (entityDataEmitForTable)="infoEntityDataForTable($event)"
                  [cuLayoutTemplate]="cuLayoutTemplate"
                >
                </app-info-layer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="nhCard_footer"
        [ngClass]="
          buttonAction?.positionFixed
            ? 'nh-position-fixed'
            : 'nh-position-static'
        "
        [attr.culayoutActionPosition]="
          buttonAction?.positionFixed ? 'fixed' : 'static'
        "
      >
        <div
          class="cuButton_inner"
          [ngClass]="{
            'justify-content-end': buttonAction?.buttonAlignment === 'right',
            'justify-content-center': buttonAction?.buttonAlignment === 'center'
          }"
        >
          <div class="cuButtons" *ngIf="!saveButtonStyle?.Save?.disableButton">
            <button
              (click)="saveAsDraft()"
              [disabled]="isReadOnly"
              class="cuButtons-btn cuButtons-btn-save"
              [ngClass]="{
                'cuButtons-btn-rounded':
                  saveButtonStyle?.Save?.style == 'rounded',
                'cuButtons-btn-corner':
                  saveButtonStyle?.Save?.style == 'rounded corner',
                'cuButtons-btn-solid': saveButtonStyle?.Save?.style == 'solid',
                'cuButtons-btn-save-icon': !saveButtonStyle?.Save?.svgUrl,
                'cuButtons-btn-save-noicon':
                  saveButtonStyle?.Save?.format == 'icon'
              }"
            >
              <span
                *ngIf="
                  saveButtonStyle?.Save?.format == 'button' &&
                  saveButtonStyle?.Save?.style
                "
              >
                {{ labels?.[saveButtonStyle?.Save?.actionName] ? labels?.[saveButtonStyle?.Save?.actionName]: saveButtonStyle?.Save?.actionName }}
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  !saveButtonStyle?.Save?.svgUrl &&
                  saveButtonStyle?.Save?.format == 'icon'
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/save.svg"
                  alt="Save"
                />
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  showdefaultSaveButtons ||
                  (saveButtonStyle?.Save?.format == 'button' &&
                    !saveButtonStyle?.Save?.style) ||
                  (saveButtonStyle?.Save?.format == 'icon' &&
                    !saveButtonStyle?.Save?.svgUrl)
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/save.svg"
                  alt="Save"
                />
              </span>
            </button>
            <span
              *ngIf="
                saveButtonStyle?.Save?.format !== 'button' ||
                showdefaultSaveButtons ||
                (saveButtonStyle?.Save?.format == 'button' &&
                  !saveButtonStyle?.Save?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[saveToolTip] ? labels?.[saveToolTip]: saveToolTip }}
            </span>
          </div>
          <div
            class="cuButtons"
            *ngIf="
              !details?.showNext && !cancelButtonStyle?.Cancel?.disableButton
            "
          >
            <button
              (click)="goBack()"
              [disabled]="isReadOnly"
              class="cuButtons-btn cuButtons-btn-cancel"
              [ngClass]="{
                'cuButtons-btn-rounded':
                  cancelButtonStyle?.Cancel?.style == 'rounded',
                'cuButtons-btn-corner':
                  cancelButtonStyle?.Cancel?.style == 'rounded corner',
                'cuButtons-btn-solid':
                  cancelButtonStyle?.Cancel?.style == 'solid',
                'cuButtons-btn-cancel-icon': !cancelButtonStyle?.Cancel?.svgUrl,
                'cuButtons-btn-cancel-noicon':
                  cancelButtonStyle?.Cancel?.format == 'icon'
              }"
            >
              <span
                *ngIf="
                  cancelButtonStyle?.Cancel?.format == 'button' &&
                  cancelButtonStyle?.Cancel?.style
                "
              >
                {{ labels?.[cancelButtonStyle?.Cancel?.actionName] ? labels?.[cancelButtonStyle?.Cancel?.actionName]: cancelButtonStyle?.Cancel?.actionName }}
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  !cancelButtonStyle?.Cancel?.svgUrl &&
                  cancelButtonStyle?.Cancel?.format == 'icon'
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/cancel.svg"
                  alt=" "
                />
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  showdefaultCancelButtons ||
                  (cancelButtonStyle?.Cancel?.format == 'button' &&
                    !cancelButtonStyle?.Cancel?.style) ||
                  (cancelButtonStyle?.Cancel?.format == 'icon' &&
                    !cancelButtonStyle?.Cancel?.svgUrl)
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/cancel.svg"
                  alt=" "
                />
              </span>
            </button>
            <span
              *ngIf="
                cancelButtonStyle?.Cancel?.format !== 'button' ||
                showdefaultCancelButtons ||
                (cancelButtonStyle?.Cancel?.format == 'button' &&
                  !cancelButtonStyle?.Cancel?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[cancelToolTip] ? labels?.[cancelToolTip]: cancelToolTip }}
            </span>
          </div>
          <div class="cuButtons" *ngIf="!hideSubmitButton">
            <button
              (click)="onSubmit($event)"
              id="submitTrans"
              class="cuButtons-btn cuButtons-btn-submit"
              [ngClass]="{
                'cuButtons-btn-rounded':
                  submitButtonStyle?.Submit?.style == 'rounded',
                'cuButtons-btn-corner':
                  submitButtonStyle?.Submit?.style == 'rounded corner',
                'cuButtons-btn-solid':
                  submitButtonStyle?.Submit?.style == 'solid',
                'cuButtons-btn-submit-icon': !submitButtonStyle?.Submit?.svgUrl,
                'cuButtons-btn-submit-noicon':
                  submitButtonStyle?.Submit?.format == 'icon'
              }"
            >
              <span *ngIf="details?.showNext">{{ labels?.Next }}</span>
              <span
                *ngIf="
                  submitButtonStyle?.Submit?.format == 'button' &&
                  submitButtonStyle?.Submit?.style
                "
              >
                {{ labels?.[submitButtonStyle?.Submit?.actionName] ? labels?.[submitButtonStyle?.Submit?.actionName]: submitButtonStyle?.Submit?.actionName }}
              </span>
              <span
                *ngIf="
                  !details?.showNext &&
                  !submitButtonStyle?.Submit?.svgUrl &&
                  submitButtonStyle?.Submit?.format == 'icon'
                "
                class="cuButtons-imgs"
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/right-arrow-submit.svg"
                  alt=" "
              /></span>
              <span
                *ngIf="
                  showdefaultSubmitButtons ||
                  (submitButtonStyle?.Submit?.format == 'button' &&
                    !submitButtonStyle?.Submit?.style) ||
                  (submitButtonStyle?.Submit?.format == 'icon' &&
                    !submitButtonStyle?.Submit?.svgUrl)
                "
                class="cuButtons-imgs"
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/right-arrow-submit.svg"
                  alt=" "
              /></span>
            </button>
            <span
              *ngIf="
                submitButtonStyle?.Submit?.format !== 'button' ||
                showdefaultSubmitButtons ||
                (submitButtonStyle?.Submit?.format == 'button' &&
                  !submitButtonStyle?.Submit?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[submitToolTip] ? labels?.[submitToolTip]: submitToolTip }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
