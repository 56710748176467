import { compositeExtractionDeMapper } from '../composite-extration/compositeExtraction-demapper';
import { entityDeMapper } from '../entity/entity-demapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function changeUnitDeMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'id',
      id: 'id',
      name: 'name',
      agents: 'agents',
      cuType: 'cuType',
      layers: 'layers',
      gsiList: 'gsiList',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      dsdStatus: 'dsdStatus',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      displayName: 'displayName',
      description: 'description',
      actionLabel: 'actionLabel',
      ontology: 'ontology',
      keywords: 'keywords',
      attachments: 'attachments',
      nextTriggerSet: 'nextTriggerSet',
      dsdReferencedChangeUnit: 'referencedChangeUnit',
      color: 'color',
      secondaryColor: 'secondaryColor',
      activeCuIndex: 'activeCuIndex',
      index: 'index',
      masterId: 'masterId',
      cuSystemProperties: 'cuSystemProperties',
      constraint: 'constraint',
      constraintName: 'constraintName',
      author: 'author',
      ownerId: 'ownerId',
      version: 'version',
      slotItemProperties: 'slotItemProperties',
      source: 'source',
      specialFeatureProperties: 'specialFeatureProperties',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      reservedCUType: 'reservedCUType',
      isNameUpdated: 'isNameUpdated',
      uiProperties: 'uiProperties',
      isFinal: 'isFinal', //  Usedfor final cu save
      oldVersionId: 'oldVersionId',
      estimatedTime: 'estimatedTime',
      querySentence: 'sentenceTags',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      dsdMetadataId: 'dsdMetadataId',
      isParallel: 'isParallel',
      attributeMapping: 'attributeMapping',
      eventCUList: 'eventCUList',
      measures: 'measures',
      disableSTEs: 'disableSTEs',
      txnDataSaveMode: 'txnDataSaveMode',
      transactionEndpoint: 'transactionEndpoint',
      isTemplate: 'isTemplate',
      triggerCUDialogList: 'triggerCUDialogList',
      exceptions: 'exceptions',
      isFaceted: 'isFaceted',
    },
    operate: [
      {
        run: function (val: any) {
          /*istanbul ignore next*/

          return val ? val : null;
        },
        on: 'dsdId',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/

          if (val !== undefined && typeof val === 'string') {
            return Number(val);
          } else {
            return val;
          }
        },
        on: 'id',
      },
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'layers',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraint',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraintName',
      },
      {
        run: (val: any) => {
          if (val !== undefined && Object.keys(val).length) {
            return val;
          } else {
            return {};
          }
        },
        on: 'uiProperties',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/
          if (val !== undefined) {
            val = compositeExtractionDeMapper(val);
            // val = removeDataType(val);
            return JSON.stringify(val);
          } else {
            return val;
          }
        },
        on: 'querySentence',
      },
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((y: any) => {
              return {
                nextCUName: y.nextCUName,
                index: y.index,
                dsdNextCUId: y?.nextCUId,
                isParallel: y?.isParallel,
              };
            });
          } else {
            return [];
          }
        },
        on: 'nextTriggerSet',
      },
    ],
  };

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      botMessage: 'botMessage',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return {
              changeDriverMetaData: x?.changeDriverMetaData, /// optional changes drivers data ///
              id: x?.participatingItemId,
              isMultiValue: x?.isMultiValue,
              isPlaceholder: x?.isPlaceholder,
              hideMulti: x?.hideMulti,
              conditionalPotentiality: x?.conditionalPotentiality,
              conditionalPotentialityName: x?.conditionalPotentialityName,
              item: {
                DATA: entityDeMapper(x),
                TYPE: 'GeneralEntity',
              },
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };

  const result = transform(data, map);

  return result;
}
