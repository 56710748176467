<section class="tab-header">
  <div class="row no-gutters align-items-center">
    <div class="col">
      <button class="create-new-btn"></button>
    </div>
    <div class="col-auto ml-auto">
      <div class="slide-toggle">
        <mat-slide-toggle></mat-slide-toggle>
      </div>
    </div>
    <div class="col-auto ml-3">
      <button class="search-btn-icon search-btn-large"></button>
    </div>
    <div class="col-auto ml-3">
      <button class="compare-btn-icon">
        <span>{{ labels?.Compare }}</span>
      </button>
    </div>
  </div>
</section>
<section class="tab-body">
  <ul class="common-list">
    <li>
      <div class="d-flex item align-items-center active">
        <div class="details">
          <p class="sub">{{ labels?.Draft }}</p>
          <p class="title">{{ labels?.Change_Unit }}</p>
        </div>
        <a class="add-icon"><span></span></a>
        <a class="copy-icon"><span></span></a>
        <a class="more-icon"><span></span></a>
      </div>
      <ul class="common-list">
          <li>
            <div class="d-flex item align-items-center">
              <div class="details">
                <p class="sub">Draft</p>
                <p class="title">Entity</p>
              </div>
              <a class="more-icon"><span></span></a>
            </div>
          </li>
          <li>
            <div class="d-flex item align-items-center">
              <div class="details">
                <p class="sub">Draft</p>
                <p class="title">Attribute</p>
              </div>
              <a class="more-icon"><span></span></a>
            </div>
          </li>
        </ul>
    </li>
    <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
      <li>
        <div class="d-flex item align-items-center">
          <div class="details">
            <p class="sub">Draft</p>
            <p class="title">Change Unit</p>
          </div>
          <a class="add-icon"><span></span></a>
          <a class="copy-icon"><span></span></a>
          <a class="more-icon"><span></span></a>
        </div>
      </li>
  </ul>
</section>
<div class="tab-footer">
  <ul class="pager d-flex justify-content-center">
    <li>
      <a href="javascript:void(0);" class="prev">{{ labels?.Previous }}</a>
    </li>
    <li>{{ labels?.Page }}</li>
    <li><a href="javascript:void(0);">1</a></li>
    <li><a href="javascript:void(0);" class="active">2</a></li>
    <li><a href="javascript:void(0);">3</a></li>
    <li><a href="javascript:void(0);">4</a></li>
    <li><a href="javascript:void(0);">5</a></li>
    <li>
      <a href="javascript:void(0);" class="next">{{ labels?.Next }}</a>
    </li>
  </ul>
</div>
