import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { ProgressbarComponent } from './progressbar.component';
@NgModule({
  declarations: [ProgressbarComponent],
  imports: [ControlsMaterialModule],
  exports: [ProgressbarComponent],
})
export class ProgressbarModule {
  static entry = {
    progressbar: ProgressbarComponent,
  };
}
