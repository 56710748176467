<ng-template #listAttribute let-data="data">
  <li class="{{ data.class }}" [innerHTML]="data.value">
    <!-- {{attributeUIElementMap?.get(data.displayName)}} -->
    <div class="text-left" *ngIf="data?.knowMore">
      <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
        Know More
      </button>
    </div>
  </li>
</ng-template>
<ng-template #actionButton let-data="data">
  <span class="btn-close" *ngIf="data?.cardDisplay == 'design'">
    <i class="bi bi-x" *ngIf="childGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
    <button
      *ngIf="!isGrammer"
      #gsiAction
      attributeDesignConfig
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [_style]="style"
      [tabSelected]="tabSelected"
      [isDesign]="isDesign"
      [isButton]="true"
      [_selectedDirectiveId]="selectedDirectiveId"
      (drop)="dropActionBtn($event)"
      (dragover)="allowDrop($event)"
      class="btn template-btn action-button btnaction text-truncate"
      pTooltip="Action Button"
      [id]="'action-' + data?.position"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      [(styleGsi)]="styleGsi[data?.position]"
      [ngClass]="data?.class"
      [cardDisplay]="cardDisplay"
      [(styleGsi)]="styleGsi[data?.position]"
    >
      {{childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'Action Button'}}
    </button>
  </span>
  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="data?.isLiTag">
      <button
        [ngStyle]="{ background: btnHoveredBoolean[data?.position] ? btnHoverColors[data?.position] :btnColors?.[data?.position] }"
        (mouseover)="btnHoveredBoolean[data?.position] = true"
        (mouseout)="btnHoveredBoolean[data?.position] = false"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        pTooltip=" {{ gsiMapping?.[data?.index]?.displayName }}"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
        class="text-truncate"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </button>
    </ng-container>
    <ng-container *ngIf="!data?.isLiTag">
      <button
        [ngStyle]="{ background: btnColors?.[data?.position] }"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        pTooltip=" {{ gsiMapping?.[data?.index]?.displayName }}"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
        class="text-truncate"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <li
      *ngIf="!data.isImage"
      [id]="attributeItem?.innerHTML"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      [_style]="style"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      [tabSelected]="tabSelected"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="styleAttribute[data?.position]"
    >
      <div [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }" class="attribute-unlink">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          width="24"
          height="24"
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
      /></span> -->
    </li>
    <li
      *ngIf="data?.isImage"
      id="attr-{{ data?.position }}"
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      [ngClass]="data?.class"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf="data.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.isImage">
      <li
        [ngClass]="data?.class"
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        *ngIf="data?.isSingleValue"
        pTooltip="{{ (card?.recordData)?.[data?.position]?.value }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleDisplay"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.displayName }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        *ngIf="data?.isSingleValueWOC"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <ng-container *ngIf="data?.isReadMore">
        <app-read-more
          [text]="(card?.recordData)?.[data?.position]?.value"
          [ngClass]="data?.class"
          [height]="data?.height"
        >
        </app-read-more>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data?.isImage">
      <img
        [ngClass]="data?.class"
        *ngIf="(card?.recordData)[data.position]?.contentUrl"
        [src]="(card?.recordData)[data.position]?.contentUrl | CDNUrlRewritePipe"
        alt="general-entity"
      />
    </ng-container>
  </ng-container>
</ng-template>
<div class="features-preview-cards">
  <div class="entitytemplates row no-gutters">
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '225'">
      <div
        class="feature-2 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <li class="w-50">
            <ul class="content-text">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'content-heading lineclamp',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'content-body',
                    cardDisplay: cardDisplay,
                    isReadMore: true,
                    height: 268
                  }
                }"
              ></ng-template>
            </ul>
          </li>
          <li class="image w-50">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '226'" (click)="stopProgationBigdiv($event)">
      <div
        class="feature-3 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content-body">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'content-heading text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, class: 'content-text', cardDisplay: cardDisplay, isReadMore: true, height: 268 }
            }"
          ></ng-template>
        </ul>
        <div class="text-center">
          <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
            Know More
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '227'" (click)="stopProgationBigdiv($event)">
      <div
        class="feature-4 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <li class="content-img">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
          <ul class="content-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, class: 'heading lineclamp', cardDisplay: cardDisplay, isSingleValue: true }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,

                  class: 'text',
                  cardDisplay: cardDisplay,
                  isReadMore: true,
                  height: 334
                }
              }"
            ></ng-template>
          </ul>
        </ul>
        <div class="text-left">
          <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
            Know More
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '149'">
      <div
        class="feature-5 feature-template entity-template-type"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <div class="w-66">
            <ul class="content-text">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'h2_heading lineclamp', cardDisplay: cardDisplay, isSingleValue: true }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, height: 305, class: 'text', cardDisplay: cardDisplay, isReadMore: true }
                }"
              ></ng-template>
            </ul>
          </div>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '150'">
      <div
        class="feature-6 feature-template entity-template-type"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <div class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </div>
          <div class="text-content">
            <div class="text lineclamp">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '151'" (click)="stopProgationBigdiv($event)">
      <div
        class="feature-7 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, height: 305, class: 'text', cardDisplay: cardDisplay, isReadMore: true }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '152'">
      <div
        class="feature-8 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
          <div class="content-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text' }
              }"
            ></ng-template>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '153'">
      <div
        class="feature-9 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="content-heading">
            <li class="image">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isImage: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </li>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
              }"
            ></ng-template>
          </div>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, height: 287, class: 'text', cardDisplay: cardDisplay, isReadMore: true }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '154'">
      <div
        class="feature-10 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
            }"
          ></ng-template>
          <div class="content-section">
            <div class="section-item" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
              <li class="image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </div>
            <div class="section-item">
              <li class="image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 4, isSingleValue: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '155'">
      <div
        class="feature-11 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content-body">
          <div class="card-left">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isSingleValue: true,
                  cardDisplay: cardDisplay,
                  class: 'content-heading text-truncate'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'content-text lineclamp' }
              }"
            ></ng-template>
          </div>
          <div class="card-right">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  isSingleValue: true,
                  cardDisplay: cardDisplay,
                  class: 'content-heading text-truncate'
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'content-text lineclamp' }
              }"
            ></ng-template>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '156'">
      <div
        class="feature-12 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="top-content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <div class="content-text lineclamp">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </div>
            <li class="image">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isImage: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </li>
          </div>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                isReadMore: true,
                cardDisplay: cardDisplay,
                class: 'bottom-content-text',
                height: 214
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '157'">
      <div
        class="feature-13 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="top-content">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'text lineclamp' }
              }"
            ></ng-template>
            <li class="image">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isImage: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </li>
          </div>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                isReadMore: true,
                cardDisplay: cardDisplay,
                class: 'bottom-content-text',
                height: 311
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '179'">
      <div class="feature-35 feature-template entity-template-type">
        <ul
          class="boxes"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="box">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
                }"
              ></ng-template>
              <div class="box-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>

                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
              </div>
              <div class="box-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 4, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
              </div>
            </ul>
          </div>
          <div class="box">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 5, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
                }"
              ></ng-template>
              <div class="box-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 6, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 7, isSingleValue: true, cardDisplay: cardDisplay, class: 'text' }
                  }"
                ></ng-template>
              </div>
              <div class="box-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 8, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 9, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
              </div>
            </ul>
          </div>
          <div class="box">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 10, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
                }"
              ></ng-template>
              <div class="box-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 11, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 12, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
              </div>
              <div class="box-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 13, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 14, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                  }"
                ></ng-template>
              </div>
            </ul>
          </div>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '160'" (click)="stopProgationBigdiv($event)">
      <div
        class="feature-16 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
          <div class="content-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isReadMore: true, cardDisplay: cardDisplay, class: 'text', height: 287 }
              }"
            ></ng-template>
          </div>
        </ul>
        <div class="text-left">
          <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
            Know More
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '165'" (click)="stopProgationBigdiv($event)">
      <div
        class="feature-21 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="content-heading">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
              }"
            ></ng-template>
          </div>
          <div class="content-body">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text lineclamp' }
              }"
            ></ng-template>
          </div>
        </ul>
        <div class="text-center">
          <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
            Know More
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '166'">
      <div
        class="feature-22 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <li class="content-img">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
          <div class="right-content">
            <ul class="content-text">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text lineclamp' }
                }"
              ></ng-template>
            </ul>
          </div>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '178'">
      <div class="feature-34 feature-template entity-template-type">
        <ul
          class="content"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <!-- <li class="list-heading">
              {{ (card?.recordData)?.[0]?.value }}
            </li> -->
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'list-heading' }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'list-sub-heading' }
            }"
          ></ng-template>
          <div class="content-text">
            <ng-container *ngFor="let a of noOfValue(59)">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: a + 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'list-item col-2' }
                }"
              ></ng-template>
            </ng-container>
          </div>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '171'">
      <div
        class="feature-27 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="left-content">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
              }"
            ></ng-template>
          </div>
          <div class="right-content">
            <!-- need to add ngif condition for ngtemplate -->
            <ng-container *ngFor="let a of noOfValue(14)">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: a + 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'sub-heading' }
                }"
              ></ng-template>
            </ng-container>
            <!-- <li class="sub-heading" *ngIf="(card?.recordData)?.[1]?.value">
                {{ (card?.recordData)?.[1]?.value }}
              </li> -->
          </div>
        </ul>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '173'" (click)="stopProgationBigdiv($event)">
      <div
        class="feature-29 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="content-heading">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading lineclamp' }
              }"
            ></ng-template>
          </div>
        </ul>
        <div class="text-center">
          <button class="btn btn-cta" (click)="stopProgationSmalldiv($event)" *ngIf="knowMore">
            Know More
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '161'">
      <div
        class="feature-17 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'content-heading lineclamp' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isReadMore: true, cardDisplay: cardDisplay, class: 'content-body', height: 301 }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>

    <!-- Ravikant code start  -->

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '158'">
      <div
        class="feature-14 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content">
          <div class="row" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <div class="col-lg-4 content_left">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'content_image img-fluid' }
                }"
              ></ng-template>
            </div>
            <div class="col-lg-8 content_right">
              <h2 class="content_heading lineclamp">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h2>
              <div class="content_desc lineclamp">
                <p>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '159'">
      <div
        class="feature-15 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content">
          <h1 class="content_title text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h1>
          <div class="media" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  isImage: true,
                  cardDisplay: cardDisplay,
                  class: 'content_image rounded-circle img-fluid'
                }
              }"
            ></ng-template>
            <div class="media-body">
              <h2 class="content_heading text-truncate">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h2>
              <div class="content_desc lineclamp">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '167'">
      <div
        class="feature-23 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content mx-auto">
          <div class="row" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <div class="col-lg-7 content_left">
              <h2 class="content_heading lineclamp">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h2>
              <div class="content_desc lineclamp">
                <p>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </p>
              </div>
            </div>
            <div class="col-lg-5 content_right">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isImage: true, cardDisplay: cardDisplay, class: 'content_image img-fluid' }
                }"
              ></ng-template>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '168'">
      <div
        class="feature-24 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content mx-auto">
          <div class="media" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'content_image rounded-circle' }
              }"
            ></ng-template>
            <div class="media-body">
              <h5 class="content_heading">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h5>
              <p class="content_desc">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '169'">
      <div
        class="feature-25 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content mx-auto">
          <h2 class="content_heading">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h2>
          <div class="row">
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 5, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 7, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 8, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 9, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 10, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 11, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 12, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 13, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 14, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 15, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 16, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 17, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 18, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="media">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 19, isImage: true, class: 'content_image', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
                <div class="media-body">
                  <p class="content_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 20, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '170'">
      <div
        class="feature-26 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content mx-auto">
          <div class="row" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <div class="col-lg-6 content_left">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isImage: true, class: 'content_image img-fluid', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
            <div class="col-lg-6 content_right">
              <h2 class="content_heading">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h2>
              <div class="content_listing">
                <div class="media">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 2, isImage: true, class: 'content_icon', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                  <div class="media-body">
                    <p>
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </p>
                  </div>
                </div>
                <div class="media">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 4, isImage: true, class: 'content_icon', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                  <div class="media-body">
                    <p>
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </p>
                  </div>
                </div>
                <div class="media">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 6, isImage: true, class: 'content_icon', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                  <div class="media-body">
                    <p>
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 7, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </p>
                  </div>
                </div>
                <div class="media">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 8, isImage: true, class: 'content_icon', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                  <div class="media-body">
                    <p>
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 9, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </p>
                  </div>
                </div>
                <div class="media">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 10, isImage: true, class: 'content_icon', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                  <div class="media-body">
                    <p>
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 11, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '180'">
      <div
        class="feature-36 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content">
          <h2 class="content_heading text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h2>
          <div class="row">
            <div class="col-lg-3">
              <div class="box">
                <p
                  class="box_heading text-truncate"
                  pTooltip="{{ (card?.recordData)?.[1]?.value }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                >
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </p>
                <h3 class="box_desc lineclamp">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </h3>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="box">
                <p
                  class="box_heading text-truncate"
                  pTooltip="{{ (card?.recordData)?.[3]?.value }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                >
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </p>
                <h3 class="box_desc">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </h3>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="box">
                <p
                  class="box_heading text-truncate"
                  pTooltip="{{ (card?.recordData)?.[5]?.value }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                >
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </p>
                <h3 class="box_desc">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </h3>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="box">
                <p
                  class="box_heading text-truncate"
                  pTooltip="{{ (card?.recordData)?.[7]?.value }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                >
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 7, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </p>
                <h3 class="box_desc">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 8, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </h3>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '172'">
      <div
        class="feature-28 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content mx-auto">
          <h1 class="content_heading text-center">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h1>
          <div class="row">
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 3, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 5, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 7, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 8, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 9, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 10, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 11, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 12, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 13, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 14, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 15, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 16, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 17, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 18, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <div class="content_box_icon">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 19, isImage: true, class: 'image', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="content_box_cnt">
                  <h3 class="content_box_title">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 20, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '174'">
      <div
        class="feature-30 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="content mx-auto">
          <h1 class="heading text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h1>
        </div>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '175'">
      <div
        class="feature-31 feature-template entity-template-type"
      >
        <div class="content mx-auto"
        [ngStyle]="{ 'background-color': cardColor, 'flex-row-reverse': entityCardFlip }"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle">
          <div class="content-left">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </div>
          <div class="content-right">
            <h2 class="content-heading">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </h2>
            <div class="content-desc">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
            <div class="content-action d-flex template-btns">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 1, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '176'">
      <div
        class="feature-32 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="content mx-auto" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <div class="content-left">
            <h2 class="content-heading">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </h2>
            <div class="content-desc">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
            <div class="content-action d-flex template-btns">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 1, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
          </div>
          <div class="content-right">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '177'">
      <div
        class="feature-33 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="content mx-auto">
          <h2 class="content_heading text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h2>
          <div class="row">
            <div class="col-lg-6">
              <div class="content_box">
                <figure>
                  <div class="content_box_image mx-auto">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 1, isImage: true, cardDisplay: cardDisplay, class: 'img_fluid' }
                      }"
                    ></ng-template>
                  </div>
                  <figcaption class="content_box_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content_box">
                <figure>
                  <div class="content_box_image mx-auto">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 3, isImage: true, cardDisplay: cardDisplay, class: 'img_fluid' }
                      }"
                    ></ng-template>
                  </div>
                  <figcaption class="content_box_desc">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Ravikant code End -->
    <div class="col-md-12 Attribute-4" *ngIf="templateType == '162'">
      <div
        class="feature-18 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <li class="image-bg">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'e-commerce-imgsize' }
              }"
            ></ng-template>
          </li>
          <div class="img-content">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  isSingleValue: true,
                  cardDisplay: cardDisplay,
                  class: 'img-content-title lineclamp'
                }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  isSingleValue: true,
                  cardDisplay: cardDisplay,
                  class: 'img-content-detail lineclamp'
                }
              }"
            ></ng-template>
          </div>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '163'">
      <div
        class="feature-19 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <div class="overlayBody">
            <li class="image-bg">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'e-commerce-imgsize' }
                }"
              ></ng-template>
            </li>
            <div class="img-content-overlay">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'img-content-title lineclamp'
                  }
                }"
              ></ng-template>
            </div>
          </div>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, isReadMore: true, cardDisplay: cardDisplay, class: 'f-19Content', height: 231 }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '164'">
      <div
        class="feature-20 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'f-20Title lineclamp' }
            }"
          ></ng-template>

          <li class="f-20Image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isImage: true, cardDisplay: cardDisplay, class: 'e-commerce-imgsize' }
              }"
            ></ng-template>
          </li>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                isReadMore: true,
                cardDisplay: cardDisplay,
                class: 'f-20Content lineclamp',
                height: 202
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '224'">
      <div
        class="feature-1 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <li class="f-1Image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'e-commerce-imgsize' }
              }"
            ></ng-template>
          </li>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'f-1Title text-truncate' }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, isReadMore: true, cardDisplay: cardDisplay, class: 'f-1Content', height: 230 }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '233'">
      <div
        class="feature-37 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'eading text-truncate' }
            }"
          ></ng-template>

          <div class="cards-wrapper">
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-heading lineclamp' }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 4, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 5, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-heading lineclamp' }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 6, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 7, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 8, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-heading lineclamp' }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 9, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 10, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 12, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 13, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 15, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 16, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 18, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 19, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 21, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 22, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 23,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 24, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 25, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 26,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 27, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 28, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 29,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 30, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 31, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 32,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 33, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 34, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 35,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 36, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 37, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 38,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 39, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 40, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 41,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 42, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 43, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 44,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 45, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 46, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 47,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 48, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 49, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 50,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 51, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
            <div class="card">
              <li class="cards-image">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 52, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 53,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'cards-heading lineclamp'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 54, isSingleValue: true, cardDisplay: cardDisplay, class: 'cards-text lineclamp' }
                }"
              ></ng-template>
            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '234'">
      <div
        class="feature-38 feature-template entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ul class="content">
          <li class="left-content-image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
          <div class="right-content">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
              }"
            ></ng-template>
            <div class="right-content-bottom">
              <div class="wrapper-class">
                <div class="wrapper-top">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
                    }"
                  ></ng-template>
                  <li class="text text-truncate">
                    <span
                      *ngIf="(card?.recordData)?.[4]?.value"
                      class="passcolor text"
                      >{{ (card?.recordData)?.[4]?.attributeName}}</span
                    ><span class="sub-text text">{{ (card?.recordData)?.[4]?.value
                    }}</span>
                  </li>
                </div>
                <div class="wrapper-bottom">
                  <li class="text text-truncate">
                    <span
                      *ngIf="(card?.recordData)?.[5]?.value"
                      class="passcolor text"
                      >{{ (card?.recordData)?.[5]?.attributeName}}</span
                    ><span class="sub-text text">{{ (card?.recordData)?.[5]?.value
                    }}</span>
                  </li>
                  <li class="text text-truncate">
                    <span
                      *ngIf="(card?.recordData)?.[6]?.value"
                      class="passcolor text"
                      >{{ (card?.recordData)?.[6]?.attributeName}}</span
                    ><span class="sub-text text">{{ (card?.recordData)?.[6]?.value
                    }}</span>
                  </li>
                </div>
              </div>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'view-more-button text-truncate', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <!-- multiple attribute view start-->

    <div class="col-md-12 Attribute-4 swiper-single-card" *ngIf="templateType == '223'">
      <div
        class="mulAttrView-temp-223 mulAttrView-temp entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="childView mx-auto">
          <div class="row nh-mb-24">
            <div class="col col-left">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    isImage: true,
                    cardDisplay: cardDisplay,
                    class: 'childView_logo nh-radius-8 nh-mb-24'
                  }
                }"
              ></ng-template>
              <p class="childView_desc nh-mb-32 nh-fs-18">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </p>
              <h4 class="subtitle nh-fs-20 nh-fw-600">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h4>
            </div>
            <div class="col col-right">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 3, isImage: true, cardDisplay: cardDisplay, class: 'childView_image nh-radius-8' }
                }"
              ></ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col col-left">
              <div class="row-action template-btns">
                <div class="row-action-col d-inline-block">
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: { position: 0, class: 'btn nh-bg-58 nh-radius-50 text-truncate', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
                <div class="row-action-col d-inline-block">
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, class: 'btn nh-bg-58 nh-radius-50 text-truncate', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </div>
              </div>
            </div>
            <div class="col col-right">
              <h4 class="nh-fs-40 nh-fw-700 text-right nh-text-900">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h4>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4 swiper-single-card" *ngIf="templateType == '220'">
      <div
        class="mulAttrView-temp-220 mulAttrView-temp entity-template-type"
        [ngClass]="{ 'design-template-220': cardDisplay == 'design' }"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="profile mx-auto nh-radius-12 nh-text-900 nh-word-wrap">
          <div class="profile_top">
            <div class="row">
              <div class="col-lg-5 profile_top_left row-col d-flex">
                <!-- <img class="profile_image nh-radius-12" src="../../../../assets/images//slider/banner-01.PNG"
                    alt="general-entity" />                 -->

                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'profile_image nh-radius-12' }
                  }"
                ></ng-template>
                <h2 class="profile_name flex-fill nh-text-200 nh-fs-24 nh-fw-600">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </h2>
              </div>
              <div class="col-lg-7 profile_top_right row-col">
                <div class="row">
                  <div class="col col-label">
                    <!-- <label class="profile_label nh-fs-14 nh-mb-8">Label</label> -->
                    <div class="profile_badge">
                      <span class="profile_badge_item nh-text-250 nh-bg-65"
                        ><ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template
                      ></span>
                    </div>
                  </div>
                  <div class="col col-insurance">
                    <!-- <label class="profile_label nh-fs-14 nh-mb-8">Insurance</label> -->
                    <div class="nh-fs-16">
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </div>
                  </div>
                  <div class="col col-payment">
                    <div class="nh-bg-56">
                      <label class="profile_label nh-fs-14 nh-mb-8 text-center nh-lh-n d-block nh-text-82"
                        ><ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template
                      ></label>
                      <h4 class="col-payment_heading nh-fs-24 text-center nh-fw-600 nh-lh-n">
                        <ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template>
                      </h4>
                    </div>
                  </div>
                  <div class="col col-payment">
                    <div class="nh-bg-59">
                      <label class="profile_label nh-fs-14 nh-mb-8 text-center nh-lh-n d-block nh-text-82"
                        ><ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template
                      ></label>
                      <h4 class="col-payment_heading nh-fs-24 text-center nh-fw-600 nh-lh-n">
                        <ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 7, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile_info">
            <div class="row">
              <div class="col-lg-5 profile_info_left">
                <div class="profile_cta template-btns">
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 2,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 3,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 4,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 5,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                </div>
              </div>
              <div class="col-lg-7 profile_info_right">
                <div class="profile_userInfo">
                  <div class="row">
                    <div class="col-lg-2 row-col-first">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 7, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 8, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 row-col-second">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 9, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 10, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-third">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 11, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 12, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-forth">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 13, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 14, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2 row-col-first">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 15, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 16, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 row-col-second">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 17, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 18, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-third">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 19, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 20, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-forth">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82"
                          ><ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 21, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template
                        ></label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          <ng-template
                            [ngTemplateOutlet]="attributeTemplate"
                            [ngTemplateOutletContext]="{
                              data: { position: 22, isSingleValueWOC: true, cardDisplay: cardDisplay }
                            }"
                          ></ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4 swiper-single-card" *ngIf="templateType == '221'">
      <div
        class="mulAttrView-temp-221 mulAttrView-temp entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="profile profile--large mx-auto nh-radius-12 nh-text-900">
          <div class="profile_top">
            <div class="row">
              <div class="col-lg-5 profile_top_left row-col d-flex">
                <!-- <img class="profile_image nh-radius-12" src="../../../../assets/images//slider/banner-01.PNG"
                    alt="general-entity" />                 -->
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'profile_image nh-radius-12' }
                  }"
                ></ng-template>
                <h2 class="profile_name flex-fill nh-text-200 nh-fs-24 nh-fw-600">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </h2>
              </div>
              <div class="col-lg-7 profile_top_right row-col">
                <div class="row">
                  <div class="col col-label">
                    <!-- <label class="profile_label nh-fs-14 nh-mb-8">Label</label> -->
                    <div class="profile_badge">
                      <span class="profile_badge_item nh-text-250 nh-bg-65"
                        ><ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template
                      ></span>
                    </div>
                  </div>
                  <div class="col col-insurance">
                    <!-- <label class="profile_label nh-fs-14 nh-mb-8">Insurance</label> -->
                    <div class="nh-fs-16">
                      <ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                        }"
                      ></ng-template>
                    </div>
                  </div>
                  <div class="col col-payment">
                    <div class="nh-bg-56">
                      <label class="profile_label nh-fs-14 nh-mb-8 text-center nh-lh-n d-block nh-text-82"
                        ><ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template
                      ></label>
                      <h4 class="col-payment_heading nh-fs-24 text-center nh-fw-600 nh-lh-n">
                        <ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template>
                      </h4>
                    </div>
                  </div>
                  <div class="col col-payment">
                    <div class="nh-bg-59">
                      <label class="profile_label nh-fs-14 nh-mb-8 text-center nh-lh-n d-block nh-text-82"
                        ><ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template
                      ></label>
                      <h4 class="col-payment_heading nh-fs-24 text-center nh-fw-600 nh-lh-n">
                        <ng-template
                          [ngTemplateOutlet]="attributeTemplate"
                          [ngTemplateOutletContext]="{
                            data: { position: 7, isSingleValueWOC: true, cardDisplay: cardDisplay }
                          }"
                        ></ng-template>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="profile_info">
            <div class="row">
              <div class="col-lg-5 profile_info_left">
                <div class="profile_cta template-btns">
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 2,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 3,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 4,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 5,
                        class: 'btn nh-radius-6 overflow-hidden text-truncate',
                        cardDisplay: cardDisplay
                      }
                    }"
                  ></ng-template>
                </div>
              </div>
              <div class="col-lg-7 profile_info_right">
                <div class="profile_userInfo">
                  <div class="row">
                    <div class="col-lg-2 row-col-first">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[6]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[7]?.displayName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 row-col-second">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[8]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[9]?.displayName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-third">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[10]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[11]?.displayName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-forth">
                      <div class="form-group">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[12]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[13]?.displayName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-2 row-col-first">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[14]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[15]?.displayName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 row-col-second">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[16]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[17]?.displayName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-third">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[18]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[19]?.displayName }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 row-col-forth">
                      <div class="form-group mb-0">
                        <label class="profile_label nh-fs-14 nh-mb-8 nh-lh-n nh-text-82">{{
                          gsiMapping?.[20]?.name
                        }}</label>
                        <div class="profile_userInfo_text text-truncate nh-fs-16 nh-lh-n">
                          {{ gsiMapping?.[21]?.displayName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4 swiper-single-card" *ngIf="templateType == '222'">
      <div
        class="mulAttrView-temp-222 mulAttrView-temp entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="childView nh-radius-16 nh-p-32 mx-auto">
          <div class="row">
            <div class="col col-left">
              <div class="row">
                <div class="col-lg-6">
                  <h5
                    class="nh-mb-8 nh-text-201 nh-fw-600 nh-fs-16 nh-lh-n text-truncate"
                    pTooltip="{{ (card?.recordData)?.[0]?.value }}"
                    tooltipPosition="top"
                  >
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h5>
                  <h1
                    class="nh-mb-40 nh-text-900 nh-fw-700 nh-fs-48 text-truncate"
                    pTooltip="{{ (card?.recordData)?.[1]?.value }}"
                    tooltipPosition="top"
                  >
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h1>
                </div>
                <div class="col-lg-6">
                  <h5
                    class="nh-mb-8 nh-text-201 nh-fw-600 nh-fs-16 nh-lh-n text-truncate"
                    pTooltip="{{ (card?.recordData)?.[2]?.value }}"
                    tooltipPosition="top"
                  >
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h5>
                  <h1
                    class="nh-mb-40 nh-text-900 nh-fw-700 nh-fs-48 text-truncate"
                    pTooltip="{{ (card?.recordData)?.[3]?.value }}"
                    tooltipPosition="top"
                  >
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h1>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <h4
                    class="nh-fw-600 nh-fs-24 nh-lh-n text-truncate"
                    pTooltip="{{ (card?.recordData)?.[4]?.value }}"
                    tooltipPosition="top"
                  >
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                      }"
                    ></ng-template>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col col-right">
              <h4
                class="nh-mb-20 nh-fw-600 nh-fs-16 nh-lh-n text-truncate"
                pTooltip="{{ (card?.recordData)?.[5]?.value }}"
                tooltipPosition="top"
              >
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h4>
              <h1
                class="nh-text-201 nh-fw-700 nh-fs-48 text-truncate"
                pTooltip="{{ (card?.recordData)?.[6]?.value }}"
                tooltipPosition="top"
              >
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h1>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn btn-orange nh-radius-4 nh-h-40 text-truncate template-btns',
                    cardDisplay: cardDisplay
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '203'">
      <div class="mulAttrView-temp-203 mulAttrView-temp entity-template-type">
        <section
          class="childView mx-auto nh-p-16 nh-radius-8"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="row">
            <div class="col-lg-5 row-col">
              <h2 class="childView_title nh-mb-28 nh-fw-600 nh-fs-20 nh-lh-n">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h2>
              <h6 class="nh-fs-14 nh-lh-n">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h6>
            </div>
            <div class="col-lg-7 row-col">
              <h1 class="nh-fw-600 nh-text-400 nh-fs-48 nh-lh-n text-truncate">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h1>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '204'">
      <div class="mulAttrView-temp-204 mulAttrView-temp entity-template-type">
        <section
          class="childView mx-auto nh-text-900"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="nh-mb-12">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'childView_icon' }
              }"
            ></ng-template>
          </div>
          <h1
            class="nh-fw-600 nh-text-400 nh-fs-36 nh-mb-8 nh-lh-n text-truncate"
            pTooltip="{{ (card?.recordData)?.[1]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
          >
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h1>
          <p class="nh-fs-14 nh-lh-n">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </p>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '205'">
      <div class="mulAttrView-temp-205 mulAttrView-temp entity-template-type">
        <section
          class="childView mx-auto nh-text-900"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="row" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
            <div class="col-lg-7 row-left">
              <h1
                class="childView_title nh-text-398 nh-lh-n nh-mb-16 text-truncate"
                pTooltip="{{ (card?.recordData)?.[0]?.value }}"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
              >
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h1>
              <h5 class="nh-fs-24 nh-fw-600 nh-lh-n">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </h5>
            </div>
            <div class="col-lg-5 row-right">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    isImage: true,
                    cardDisplay: cardDisplay,
                    class: 'childView_icon nh-mb-24 img-fluid'
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn btn-block nh-bg-207 nh-text-50 nh-radius-4 nh-h-40 text-truncate template-btns',
                    cardDisplay: cardDisplay
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="Attribute-4" *ngIf="templateType == '206'">
      <div
        class="mulAttrView-temp-206 mulAttrView-temp entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="childView mx-auto nh-text-900 text-center">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'childView_icon mx-auto nh-mb-16' }
            }"
          ></ng-template>
          <p class="nh-text-398 nh-lh-n nh-mb-10 nh-fs-16 text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </p>
          <h1 class="childView_number nh-fw-700 text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h1>
          <!-- need to write -->
          <div
            class="cta-btn"
            pTooltip=" {{ gsiMapping?.[0]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
          >
            {{ gsiMapping?.[0]?.displayName }}
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '209'">
      <div class="mulAttrView-temp-209 mulAttrView-temp entity-template-type">
        <section
          class="childView mx-auto nh-text-900"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="childView_inner">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'childView_icon mx-auto nh-mb-16' }
              }"
            ></ng-template>
            <h1 class="childView_title nh-mb-8 nh-fw-700 text-truncate">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </h1>
            <p class="nh-lh-n nh-fs-14 text-truncate">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </p>
          </div>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '196'">
      <div
        class="mulAttrView-temp-196 mulAttrView-temp entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <section class="childView mx-auto nh-text-900">
          <h1 class="childView_title nh-mb-16 nh-text-199 nh-fw-600 text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </h1>
          <p class="nh-lh-n nh-fs-22 nh-fw-600 text-truncate">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </p>
        </section>
      </div>
    </div>

    <div class="col-md-12 Attribute-4" *ngIf="templateType == '219'">
      <div
        class="mulAttrView-temp-6 mulAttrView-temp entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="childCard">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'dayCount' }
            }"
          ></ng-template>
          <div class="childCardBody">
            <div class="row">
              <div class="col-md-8 childCardContent">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'CardTitle' }
                  }"
                ></ng-template>
                <div class="row priceBody">
                  <div class="col-md-7">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'priceTitle' }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'priceContent' }
                      }"
                    ></ng-template>
                  </div>
                  <div class="col-md-5 leftBorder">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 4, isSingleValue: true, cardDisplay: cardDisplay, class: 'priceTitle' }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 5, isSingleValue: true, cardDisplay: cardDisplay, class: 'priceContent' }
                      }"
                    ></ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-4 btnPlaced template-btns">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, class: 'btn btn-cta', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- multiple attribute view end-->
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '210'">
    <div class="attr3_8 entity-template-type">
      <ul
        class="content"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="content-icon">
          <li class="display-icon">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'icon' }
              }"
            ></ng-template>
          </li>
        </div>
        <div class="content-text">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
            }"
          ></ng-template>
        </div>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '211'">
    <div class="attr3_9 entity-template-type">
      <ul
        class="content"
        [ngClass]="{ 'flex-row-reverse': entityCardFlip }"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <li class="display-icon">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'icon' }
            }"
          ></ng-template>
        </li>
        <div class="content-text">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text' }
            }"
          ></ng-template>
        </div>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '216'">
    <div class="attr-4_5 entity-template-type">
      <ul
        class="content"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        [ngClass]="{ 'flex-row-reverse': entityCardFlip }"
      >
        <div class="text">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-bg-color' }
            }"
          ></ng-template>

          <div class="card-info">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'left-info text-bg-color' }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'right-info text-bg-color' }
              }"
            ></ng-template>
          </div>
        </div>

        <li class="display-icon">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 3, isImage: true, cardDisplay: cardDisplay, class: 'icon' }
            }"
          ></ng-template>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '217'">
    <div class="attr-4_6 entity-template-type">
      <ul
        class="content"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="top-info">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-bg-color' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                isSingleValue: true,
                cardDisplay: cardDisplay,
                class: 'info-sub-heading text-bg-color'
              }
            }"
          ></ng-template>
        </div>
        <div class="bottom-info">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-bg-color' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                isSingleValue: true,
                cardDisplay: cardDisplay,
                class: 'info-sub-heading text-bg-color'
              }
            }"
          ></ng-template>
        </div>
      </ul>
    </div>
  </div>
  <div class="col-md-12 Attribute-4" *ngIf="templateType == '212'">
    <div
      class="attr-4_1 feature-template entity-template-type"
      [ngStyle]="{ 'background-color': cardColor }"
      CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [id]="'Template-' + templateType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <ul class="content">
        <li class="image">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </li>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-item-1' }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-item-2' }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-item-3' }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '215'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesChidCard-3"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardTitle' }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardDigit' }
          }"
        ></ng-template>
        <div class="row">
          <div class="col-3">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'digitNotes text-truncate' }
              }"
            ></ng-template>
          </div>
          <div class="col-9">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardNotes' }
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '214'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesChidCard-2"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="iconBody">
          <li class="cardIcon">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'iconBody' }
              }"
            ></ng-template>
          </li>
        </div>
        <div class="row">
          <div class="col-12">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardTitle' }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardDigit' }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardNotes' }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'attributeAction-btn template-btns',
                  cardDisplay: cardDisplay,
                  isLiTag: true
                }
              }"
            ></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '213'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesChidCard-4"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="row" [ngClass]="{ 'flex-row-reverse': entityCardFlip }">
          <div class="col-md-10">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardTitle' }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardDigit' }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardNotes' }
              }"
            ></ng-template>
          </div>
          <div class="col-md-2">
            <div class="iconBody">
              <li class="cardIcon">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, isImage: true, cardDisplay: cardDisplay, class: 'iconBody' }
                  }"
                ></ng-template>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '207'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesTotalCard-3"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="row">
          <div class="col-md-8">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardTitle' }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardDigit' }
              }"
            ></ng-template>
          </div>
          <div class="col-md-4 iconContainer">
            <div class="iconBody">
              <li class="cardIcon">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isImage: true, cardDisplay: cardDisplay, class: 'iconBody' }
                  }"
                ></ng-template>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '208'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesChidCard-5"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardTitle' }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'cardDigit' }
          }"
        ></ng-template>
        <div class="iconBody">
          <li class="cardIcon">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 2, isImage: true, cardDisplay: cardDisplay, class: 'iconBody' }
              }"
            ></ng-template>
          </li>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '202'">
    <div
      class="attr-2_7 feature-template entity-template-type"
      [ngStyle]="{ 'background-color': cardColor }"
      CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [id]="'Template-' + templateType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <ul class="content">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-item-1 text-truncate' }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-item-2 text-truncate' }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: { position: 0, class: 'btn btn-cta template-btns text-truncate', cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '197'">
    <div
      class="attr-2_2 entity-template-type"
      [ngStyle]="{ 'background-color': cardColor }"
      CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [id]="'Template-' + templateType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <ul class="content">
        <div class="top-content">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
            }"
          ></ng-template>
        </div>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'bottom-content text-truncate' }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '198'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesChidCard-198"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'dollerTitle text-truncate' }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'subTitle text-truncate' }
          }"
        ></ng-template>
      </div>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '200'">
    <div
      class="attr-2_5 entity-template-type"
      [ngStyle]="{ 'background-color': cardColor }"
      CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [id]="'Template-' + templateType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <ul class="content">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'heading text-truncate' }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text text-truncate' }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
  <div class="col-md-12 Attribute-4" *ngIf="templateType == '201'">
    <div
      class="attr-2_6 feature-template entity-template-type"
      [ngStyle]="{ 'background-color': cardColor }"
      CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [id]="'Template-' + templateType"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <ul class="content">
        <li class="image">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </li>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-item lineclamp' }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>

  <div class="col-md-12 Attribute-4" *ngIf="templateType == '199'">
    <div class="mulAttrView-temp-6 mulAttrView-temp entity-template-type">
      <div
        class="attributesChidCard-199"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isSingleValue: true, cardDisplay: cardDisplay, class: 'AmtTitle lineclamp' }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'subTitle text-truncate' }
          }"
        ></ng-template>
        <div class="expandActionBody template-btns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'expandAction-btn active text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row swiper-single-card" *ngIf="templateType == '1224'">
    <div class="col-12 p-0">
      <div
        class="dynamic-card-block-1 entity-template-type dynamic-card-block"
        [ngStyle]="{ 'background-color': cardColor }"
      >
        <div class="block-1">
          <ul
            class="blocks"
            CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
            [isEdit]="isEdit"
            [id]="'Template-' + templateType"
            [_changeShadowStyle]="boxShadowData"
            [style]="style"
            [_selectedDirectiveId]="selectedDirectiveId"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [cardBorderSize]="cardBorderSize"
            [cardBorderRadius]="cardBorderRadius"
            [cardBorderColor]="cardBorderColor"
            [cardShadow]="cardShadow"
            [cardColor]="cardColor"
            [card]="card"
            [cardDisplay]="cardDisplay"
            [(cardStyle)]="cardStyle"
          >
            <ul
              class="block"
              [ngClass]="{'d-none': cardDisplay == 'view' ? !(card?.recordData)?.[1]?.value && !(card?.recordData)?.[0]?.value : false} "
            >
              <div class="btn-cta template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 0, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      isSingleValue: true,
                      cardDisplay: cardDisplay,
                      class: 'number text-truncate passcolor'
                    }
                  }"
                ></ng-template>
              </ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    isSingleDisplay: true,
                    cardDisplay: cardDisplay,
                    class: 'case'
                  }
                }"
              ></ng-template>
              <div class="btn-cta1 template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
            </ul>
            <ul
              class="block"
              [ngClass]="{'d-none': cardDisplay == 'view' ? !(card?.recordData)?.[2]?.value && !(card?.recordData)?.[3]?.value : false} "
            >
              <div class="btn-cta template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 2, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 3,
                      isSingleValue: true,
                      cardDisplay: cardDisplay,
                      class: 'number passcolor'
                    }
                  }"
                ></ng-template>
              </ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    isSingleDisplay: true,
                    cardDisplay: cardDisplay,
                    class: 'case'
                  }
                }"
              ></ng-template>
              <div class="btn-cta1 template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
            </ul>
            <ul
              class="block"
              [ngClass]="{'d-none': cardDisplay == 'view' ? !(card?.recordData)?.[4]?.value && !(card?.recordData)?.[5]?.value: false} "
            >
              <div class="btn-cta template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 4, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 5,
                      isSingleValue: true,
                      cardDisplay: cardDisplay,
                      class: 'number passcolor'
                    }
                  }"
                ></ng-template>
              </ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    isSingleDisplay: true,
                    cardDisplay: cardDisplay,
                    class: 'case'
                  }
                }"
              ></ng-template>
              <div class="btn-cta1 template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
            </ul>
            <ul
              class="block"
              [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[6]?.value && !(card?.recordData)?.[7]?.value : false }"
            >
              <div class="btn-cta template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 6, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 7,
                      isSingleValue: true,
                      cardDisplay: cardDisplay,
                      class: 'number passcolor'
                    }
                  }"
                ></ng-template>
              </ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    isSingleDisplay: true,
                    cardDisplay: cardDisplay,
                    class: 'case'
                  }
                }"
              ></ng-template>
              <div class="btn-cta1 template-btns passcolor">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 3, class: 'btn cta-btn gsi-button text-truncate', cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </div>
            </ul>
            <!-- <ul class="block">
              <div class="btn-cta">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[4],card)"
                  *ngIf="gsiMapping?.[4]?.name"
                    pTooltip=" {{ gsiMapping?.[4]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[4]?.displayName }}
                </button>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <img class="" [src]="(card?.recordData)?.[8]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li class="number">{{ (card?.recordData)?.[9]?.value }}</li>
              </ul>
              <li class="case">{{ (card?.recordData)?.[9]?.displayName  }}</li>
              <div class="btn-cta1">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[4],card)"
                  *ngIf="gsiMapping?.[4]?.name"
                    pTooltip=" {{ gsiMapping?.[4]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[4]?.displayName }}
                </button>
              </div>
            </ul>
            <ul class="block">
              <div class="btn-cta">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[5],card)"
                  *ngIf="gsiMapping?.[5]?.name"
                    pTooltip=" {{ gsiMapping?.[5]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[5]?.displayName }}
                </button>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <img class="" [src]="(card?.recordData)?.[10]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li class="number">{{ (card?.recordData)?.[11]?.value }}</li>
              </ul>
              <li class="case">{{ (card?.recordData)?.[11]?.displayName  }}</li>
              <div class="btn-cta1">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[5],card)"
                  *ngIf="gsiMapping?.[5]?.name"
                    pTooltip=" {{ gsiMapping?.[5]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[5]?.displayName }}
                </button>
              </div>
            </ul>
            <ul class="block">
              <div class="btn-cta">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[6],card)"
                  *ngIf="gsiMapping?.[6]?.name"
                    pTooltip=" {{ gsiMapping?.[6]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[6]?.displayName }}
                </button>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <img class="" [src]="(card?.recordData)?.[12]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li class="number">{{ (card?.recordData)?.[13]?.value }}</li>
              </ul>
              <li class="case">{{ (card?.recordData)?.[13]?.displayName  }}</li>
              <div class="btn-cta1">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[6],card)"
                  *ngIf="gsiMapping?.[6]?.name"
                    pTooltip=" {{ gsiMapping?.[6]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[6]?.displayName }}
                </button>
              </div>
            </ul>
            <ul class="block">
              <div class="btn-cta">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[7],card)"
                  *ngIf="gsiMapping?.[7]?.name"
                    pTooltip=" {{ gsiMapping?.[7]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[7]?.displayName }}
                </button>
              </div>
              <ul class="content">
                <li class="card-logo">
                  <img class="" [src]="(card?.recordData)?.[14]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li class="number">{{ (card?.recordData)?.[15]?.value }}</li>
              </ul>
              <li class="case">{{ (card?.recordData)?.[15]?.displayName  }}</li>
              <div class="btn-cta1">
                <button
                  class="btn cta-btn gsi-button text-truncate"
                  (click)="gotoTxnPage(gsiMapping?.[7],card)"
                  *ngIf="gsiMapping?.[7]?.name"
                    pTooltip=" {{ gsiMapping?.[7]?.displayName }}"
                    tooltipPosition="top"
                    tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ gsiMapping?.[7]?.displayName }}
                </button>
              </div>
            </ul> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row swiper-single-card" *ngIf="templateType == '2224'">
    <div
      class="dynamic-card-block-2 entity-template-type dynamic-card-block"
      [ngStyle]="{ 'background-color': cardColor }"
    >
      <div class="block-2">
        <ul
          class="blocks"
          CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <ul
            class="block"
            [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[1]?.value && !(card?.recordData)?.[0]?.value : false }"
          >
            <ul class="image">
              <li class="img">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                }"
              ></ng-template>
            </ul>
            <ul class="cases">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'number text-truncate passcolor'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </ul>
          </ul>
          <ul
            class="block"
            [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[2]?.value && !(card?.recordData)?.[3]?.value: false} "
          >
            <ul class="image">
              <li class="img">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 1, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                }"
              ></ng-template>
            </ul>
            <ul class="cases">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'number text-truncate passcolor'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 3, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </ul>
          </ul>
          <ul
            class="block"
            [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[4]?.value && !(card?.recordData)?.[5]?.value: false} "
          >
            <ul class="image">
              <li class="img">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 4, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 2, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                }"
              ></ng-template>
            </ul>
            <ul class="cases">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'number text-truncate passcolor'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 5, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </ul>
          </ul>

          <ul
            class="block"
            [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[6]?.value && !(card?.recordData)?.[7]?.value : false} "
          >
            <ul class="image">
              <li class="img">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: { position: 6, isImage: true, cardDisplay: cardDisplay }
                  }"
                ></ng-template>
              </li>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: { position: 3, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                }"
              ></ng-template>
            </ul>
            <ul class="cases">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    isSingleValue: true,
                    cardDisplay: cardDisplay,
                    class: 'number text-truncate passcolor'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 7, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                }"
              ></ng-template>
            </ul>
          </ul>
          <!-- <ul class="block">
              <ul class="image">
                <li class="img">
                  <img class="" [src]="(card?.recordData)?.[12]?.contentUrl | CDNUrlRewritePipe" alt="image" />
                </li>
                <li class="arrow"></li>
              </ul>
              <ul class="cases">
                <li class="number text-truncate">{{ (card?.recordData)?.[13]?.value }}</li>
                <li class="text">{{ (card?.recordData)?.[14]?.value }}</li>
              </ul>
            </ul>
            <ul class="block">
              <ul class="image">
                <li class="img">
                  <img class="" [src]="(card?.recordData)?.[15]?.contentUrl | CDNUrlRewritePipe" alt="image" />
                </li>
                <li class="arrow"></li>
              </ul>
              <ul class="cases">
                <li class="number text-truncate">{{ (card?.recordData)?.[16]?.value }}</li>
                <li class="text">{{ (card?.recordData)?.[17]?.value }}</li>
              </ul>
            </ul>
            <ul class="block">
              <ul class="image">
                <li class="img">
                  <img class="" [src]="(card?.recordData)?.[18]?.contentUrl | CDNUrlRewritePipe" alt="image" />
                </li>
                <li class="arrow"></li>
              </ul>
              <ul class="cases">
                <li class="number text-truncate">{{ (card?.recordData)?.[19]?.value }}</li>
                <li class="text">{{ (card?.recordData)?.[20]?.value }}</li>
              </ul>
            </ul>
            <ul class="block">
              <ul class="image">
                <li class="img">
                  <img class="" [src]="(card?.recordData)?.[21]?.contentUrl | CDNUrlRewritePipe" alt="image" />
                </li>
                <li class="arrow"></li>
              </ul>
              <ul class="cases">
                <li class="number text-truncate">{{ (card?.recordData)?.[22]?.value }}</li>
                <li class="text">{{ (card?.recordData)?.[23]?.value }}</li>
              </ul>
            </ul> -->
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row swiper-single-card" *ngIf="templateType == '3224'">
    <div class="col-12 p-0">
      <div
        class="dynamic-card-block-3 entity-template-type dynamic-card-block"
        [ngStyle]="{ 'background-color': cardColor }"
      >
        <div class="block-3">
          <ul
            class="blocks"
            CardDesignConfig
          [cardHoveredColor]="cardHoveredColor"
            [isEdit]="isEdit"
            [id]="'Template-' + templateType"
            [_changeShadowStyle]="boxShadowData"
            [style]="style"
            [_selectedDirectiveId]="selectedDirectiveId"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [cardBorderSize]="cardBorderSize"
            [cardBorderRadius]="cardBorderRadius"
            [cardBorderColor]="cardBorderColor"
            [cardShadow]="cardShadow"
            [cardColor]="cardColor"
            [card]="card"
            [cardDisplay]="cardDisplay"
            [(cardStyle)]="cardStyle"
          >
            <ul
              class="block"
              [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[1]?.value && !(card?.recordData)?.[0]?.value: false} "
            >
              <ul class="arrow-icon passcolor template-btns">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 0, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                  }"
                ></ng-template>
              </ul>
              <ul class="content">
                <li class="image">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 0, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <li>
                  <ul class="cases">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'number passcolor' }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 1, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
            </ul>
            <ul
              class="block"
              [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[2]?.value && !(card?.recordData)?.[3]?.value: false} "
            >
              <ul class="arrow-icon passcolor template-btns">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 1, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                  }"
                ></ng-template>
              </ul>
              <ul class="content">
                <li class="image">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 2, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <li>
                  <ul class="cases">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'number passcolor' }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 3, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
            </ul>
            <ul
              class="block"
              [ngClass]="{'d-none':cardDisplay == 'view' ? !(card?.recordData)?.[4]?.value && !(card?.recordData)?.[5]?.value: false} "
            >
              <ul class="arrow-icon passcolor template-btns">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: { position: 2, class: 'arrow', cardDisplay: cardDisplay, isLiTag: true }
                  }"
                ></ng-template>
              </ul>
              <ul class="content">
                <li class="image">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 4, isImage: true, cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <li>
                  <ul class="cases">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 5, isSingleValue: true, cardDisplay: cardDisplay, class: 'number passcolor' }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: { position: 5, isSingleDisplay: true, cardDisplay: cardDisplay, class: 'text' }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
            </ul>
            <!-- <ul class="block">
              <li class="arrow"></li>
              <ul class="content">
                <li class="image">
                  <img class="" [src]="(card?.recordData)?.[9]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li>
                  <ul class="cases">
                    <li class="number">{{ (card?.recordData)?.[10]?.value }}</li>
                    <li class="text">{{ (card?.recordData)?.[11]?.value }}</li>
                  </ul>
                </li>
              </ul>
            </ul>
            <ul class="block">
              <li class="arrow"></li>
              <ul class="content">
                <li class="image">
                  <img class="" [src]="(card?.recordData)?.[12]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li>
                  <ul class="cases">
                    <li class="number">{{ (card?.recordData)?.[13]?.value }}</li>
                    <li class="text">{{ (card?.recordData)?.[14]?.value }}</li>
                  </ul>
                </li>
              </ul>
            </ul>
            <ul class="block">
              <li class="arrow"></li>
              <ul class="content">
                <li class="image">
                  <img class="" [src]="(card?.recordData)?.[15]?.contentUrl | CDNUrlRewritePipe" alt="img" />
                </li>
                <li>
                  <ul class="cases">
                    <li class="number">{{ (card?.recordData)?.[16]?.value }}</li>
                    <li class="text">{{ (card?.recordData)?.[17]?.value }}</li>
                  </ul>
                </li>
              </ul>
            </ul> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <mat-slide-toggle class="nh-mb-16" *ngIf="templateType == 334" [(ngModel)]="isHovered"
        >hovered State</mat-slide-toggle
      >

      <div class="studioverse_cu_card dynamic-card-block" *ngIf="templateType == 334">
        <div class="nhentitycard nh-position-relative" [ngClass]="{ hovered: isHovered }">
          <div class="nhentitycard_top nh-position-relative nh-mb-12">
            <ul class="nhentitycard_image nh-display-flex nh-flex-wrap">
              <li class="card-logo nh-wp-50" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                Attribute
              </li>
              <li class="card-logo nh-wp-50" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                Attribute
              </li>
              <li class="card-logo nh-wp-50" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                Attribute
              </li>
              <li class="card-logo nh-wp-50" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                Attribute
              </li>
            </ul>
            <ul class="nhentitycard_tiles nh-position-absolute nh-display-flex nh-align-center">
              <em class="nh-icon nh-icon-play-circle nh-fs-16 nh-mr-8"></em>
              <li
                class="only-value nh-fs-12 nh-mr-3"
                (drop)="dropAttribute($event, 'any')"
                (dragover)="allowDrop($event)"
              >
                Attribute
              </li>
              <span class="statictext nh-fs-12">Titles</span>
            </ul>
            <div class="learnmore nh-position-absolute nh-hp-100 nh-wp-100">
              <ul class="nh-position-absolute">
                <li
                  class="only-value nh-display-inline-block cursor-pointer nh-radius-18"
                  (drop)="dropAttribute($event, 'any')"
                  (dragover)="allowDrop($event)"
                >
                  Attribute
                </li>
              </ul>
            </div>
          </div>
          <ul class="nhentitycard_title nh-position-relative nh-mb-8">
            <li class="only-value" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
              Attribute
            </li>
          </ul>
          <ul class="nhentitycard_tags nh-display-flex nh-column-gap-4 nh-position-relative">
            <li class="only-value nh-radius-24" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
              Attribute
            </li>
            <li class="only-value nh-radius-24" (drop)="dropAttribute($event, 'any')" (dragover)="allowDrop($event)">
              Attribute
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="entitytemplates type-templates" *ngIf="templateType == '328'">
      <div
        class="entity-comment-section dynamic-card-block"
        #dropEntityData
        (emitHoverColor)="recieveHoverColor($event)"
        [id]="'Template-' + templateType"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      >
        <ul class="comment-inner">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { class: 'comment-img' } }"
          ></ng-template>

          <div class="content-section">
            <div class="division-section">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{ data: { class: 'c-name' } }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{ data: { class: 'c-date' } }"
                ></ng-template>
              </ul>
            </div>
            <div class="c-comment-dis">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{ data: { class: 'c-comments' } }"
                ></ng-template>
              </ul>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <div class="entitytemplates type-templates row" *ngIf="templateType == '308'">
    <div class="col-12">
      <div class="tourism-wireframe-308 tourism-card"
       CardDesignConfig
        [cardHoveredColor]="cardHoveredColor" 
        [isEdit]="isEdit"
        [id]="'Template-' + templateType" 
        [_changeShadowStyle]="boxShadowData" 
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId" 
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize" 
        [cardBorderRadius]="cardBorderRadius" 
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow" 
        [cardColor]="cardColor" 
        [card]="card" 
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle">
        <div class="tourism-card">
          <div class="tourism-card-row">
            <div class="tourism-banner-img">
              <ul>
                <li class="tourism-img">
                  <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                    data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'tourism-img' }
                  }"></ng-template>
                </li>
              </ul>
            </div>
            <div class="tourism-col1">
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                                          data: { position: 1, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-h3 tourism-txt-truncate' }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                                          data: { position: 2, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-h6 tourism-txt-truncate' }}">
                </ng-template>
              </ul>
  
              <div class="custom-flex">
                <div class="flex-left">
                  <ul>
                    <span>Class</span>
                    <ng-template [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                                          data: { position: 3, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-p tourism-txt-truncate' }}">
                    </ng-template>
                    <span>Class</span>
                    <ng-template [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                                          data: { position: 4, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-p tourism-txt-truncate' }}">
                    </ng-template>
                  </ul>
                </div>
                <div class="flex-right">
                  <ul>
                    <span>Class</span>
                    <ng-template [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                                          data: { position: 5, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-p tourism-txt-truncate' }}">
                    </ng-template>
                    <span>Class</span>
                    <ng-template [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                                          data: { position: 6, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-p tourism-txt-truncate' }}">
                    </ng-template>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tourism-col-2">
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                                        data: { position: 7, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                                      data: { position: 8, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
  
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                                    data: { position: 9, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                                  data: { position: 10, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                                data: { position: 11, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                              data: { position: 12, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                            data: { position: 13, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                          data: { position: 14, isSingleValue: true, cardDisplay: cardDisplay }}">
                </ng-template>
              </ul>
            </div>
            <div class="tourism-col-3">
              <div class="ratings">
                <div class="rating-left">
                  <ul>
                    <ng-template [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                                          data: { position: 15, isSingleValue: true, cardDisplay: cardDisplay, class: 'tourism-rating text-truncate' }}">
                    </ng-template>
                  </ul>
                </div>
                <div class="rating-right">
                  <ul>
                    <ng-template [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                                            data: { position: 16, isSingleValue: true, cardDisplay: cardDisplay, class: 'text-truncate' }}">
                    </ng-template>
                  </ul>
                </div>
              </div>
              <ul>
                <span class="start-text">Class</span>
                <ng-template [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                                          data: { position: 17, isSingleValue: true, cardDisplay: cardDisplay, class: 'price-text attribute-value' }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                                          data: { position: 18, isSingleValue: true, cardDisplay: cardDisplay, class: 'end-text attribute-value' }}">
                </ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                                          data: { position: 19, isSingleValue: true, cardDisplay: cardDisplay, class: 'note-text attribute-value' }}">
                </ng-template>
  
                <!-- <li class="tourism-txt-truncate only-value">
                          value
                        </li>
                        <li class="tourism-txt-truncate only-value">
                          value
                        </li>
                        <li class="tourism-txt-truncate only-value">
                          value
                        </li>
                        <li class="tourism-txt-truncate only-value">
                          value
                        </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="entitytemplates type-templates row" *ngIf="templateType == '304'">
  <div class="col-12">
    <div class="entity-001" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">

      <article class="entity-card-inner">
        <ul class="cards-img">
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'tmwf-img',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"></ng-template>
        </ul>

        <div class="cards-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 1,
                class: 'tmwf-title',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>
        </div>
      </article>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates" *ngIf="templateType == '314'">
  <div class="info-card-details" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
    [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle">
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
        data: {
          position: 0,
          class: 'info',
          cardDisplay: cardDisplay,
          isSingleValue: true
        }
      }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 1,
                class: 'info',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 2,
                class: 'info',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 3,
                class: 'info',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 4,
                class: 'info',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 5,
                class: 'info',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 6,
                class: 'info',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
    </ul>
    <ul class="deatails">
      <span class="name">
        Class
      </span>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 7,
            class: 'info',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
    </ul>
  </div>
</div>
<div class="entitytemplates type-templates row" *ngIf="templateType == '303'">
  <div class="col-12">
    <div class="tourism-wireframe-303" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
      <div class="tourism-wireframe-left">
        <div class="tmwf-left-side">
          <div class="tourism-wireframe-left-top">
            <ul class="tmwf-main-img">
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'tmwf-img',
                    isImage: true,
                    cardDisplay: cardDisplay                     
                  }
                }"></ng-template>
            </ul>
            <div class="tmwf-details">
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      class: 'tmwf-title',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"></ng-template>
              </ul>
              <div class="tmwf-more-details">
                <div>
                  <span>class</span>
                  <ul>
                    <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          class: '',
                          cardDisplay: cardDisplay,
                          isSingleValue: true
                        }
                      }"></ng-template>
                  </ul>
                </div>

                <div>
                  <span>class</span>
                  <ul>
                    <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                        data: {
                          position: 3,
                          class: '',
                          cardDisplay: cardDisplay,
                          isSingleValue: true
                        }
              }"></ng-template>
                  </ul>
                </div>
                <div>
                  <span>class</span>
                  <ul>
                    <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                        data: {
                          position: 4,
                          class: '',
                          cardDisplay: cardDisplay,
                          isSingleValue: true
                        }
                      }"></ng-template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tourism-wireframe-left-bottom">
            <div class="tourism-wireframe-left-bottom-sec1">
              <div class="twlb-btm-field">
                <span>Class</span>
                <ul>
                  <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                      data: {
                        position: 5,
                        class: '',
                        cardDisplay: cardDisplay,
                        isSingleValue: true
                      }
                    }"></ng-template>
                </ul>
              </div>

              <div class="twlb-btm-field">
                <span>Class</span>
                <ul>
                  <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                      data: {
                        position: 6,
                        class: '',
                        cardDisplay: cardDisplay,
                        isSingleValue: true
                      }
                    }"></ng-template>
                </ul>
              </div>
              <div class="twlb-btm-field">
                <span>Class</span>
                <ul>
                  <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 7,
                  class: '',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
                </ul>
              </div>
            </div>
            <div class="tourism-wireframe-left-bottom-sec2">
              <div class="twlb-btm-field">
                <span>Class</span>
                <ul>
                  <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                      data: {
                        position: 8,
                        class: '',
                        cardDisplay: cardDisplay,
                        isSingleValue: true
                      }
                    }"></ng-template>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="tmwf-wireframe-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 9,
                  class: '',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 10,
                class: '',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 11,
              class: '',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 12,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
        data: {
          position: 13,
          class: '',
          cardDisplay: cardDisplay,
          isSingleValue: true
        }
      }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
      data: {
        position: 14,
        class: '',
        cardDisplay: cardDisplay,
        isSingleValue: true
      }
    }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
    data: {
      position: 15,
      class: '',
      cardDisplay: cardDisplay,
      isSingleValue: true
    }
  }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
  data: {
    position: 16,
    class: '',
    cardDisplay: cardDisplay,
    isSingleValue: true
  }
  }"></ng-template>
          </ul>
        </div>
      </div>
      <div class="tourism-wireframe-right">
        <div class="price-flex">
          <div class="price-left">
            <div class="tmwf-price-list">
              <span>Class</span>
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                    data: {
                      position: 17,
                      class: 'tmwf-price-img',
                      isImage: true,
                      cardDisplay: cardDisplay
                    }
                  }"></ng-template>

                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                    data: {
                      position: 18,
                      class: 'tmwf-price-title',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"></ng-template>
              </ul>
            </div>
          </div>
          <div class="price-right">
            <div class="tmwf-price-list tmwf-price-list-2">
              <span>Class</span>
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                    data: {
                      position: 19,
                      class: 'tmwf-price-img',
                      isImage: true,
                      cardDisplay: cardDisplay
                    }
                  }"></ng-template>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                    data: {
                      position: 20,
                      class: 'tmwf-price-title',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"></ng-template>
              </ul>
            </div>
          </div>
        </div>

        <div class="tmwf-price-list tmwf-price-list-3">
          <span>Class</span>
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 21,
                  class: 'tmwf-price-img',
                  isImage: true,
                  cardDisplay: cardDisplay
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 22,
                  class: 'tmwf-price-title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="divider"></div>
        <div class="tmwf-btprice-list">
          <div class="btprice-left"><span class="tmwf-info-price">Class</span></div>
          <div class="btprice-right">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 23,
                    class: '',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="btprice-left"><span class="tmwf-info-price">Class</span></div>
          <div class="btprice-right">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 24,
                    class: '',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="btprice-left"><span class="tmwf-info-price tmwf-info-title">Class</span></div>
          <div class="btprice-right">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 25,
                    class: 'tmwf-info-title',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="entitytemplates type-templates row" *ngIf="templateType == '307'">
  <div class="col-12">
    <div class="entity-004" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
      <article class="card-top">
        <ul class="cards-img">
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'tmwf-img',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"></ng-template>
        </ul>
        <div class="cards-inner">
          <ul class="cards-list">
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 1,
                class: 'card-heading',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 2,
                class: 'card-subheading',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>

          <div class="card-inner-list">
            <div class="list-inner">
              <span>Class</span>
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 3,
                class: 'list-date',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
            <div class="list-inner">
              <span>Class</span>
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 4,
                class: 'list-date',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
            <div class="list-inner">
              <span>Class:</span>
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 5,
                class: 'card-add',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
          </div>

          <div class="card-iner-subtext">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 6,
                class: 'small-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
            </ul>
          </div>
        </div>
        <div class="card-inner-left">
          <div class="inner-top">
            <div class="inner-left-sp">
              <ul>
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 7,
                class: 'tmwf-img',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }">
                  <img src="../../../../../assets/default-images/user.png" alt="" />
                </ng-template>
              </ul>

              <ul class="cards-list">
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 8,
                class: 'heading-location',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
            <div class="inner-left-sp">
              <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 9,
                class: 'tmwf-img',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }">
                <img src="../../../../../assets/default-images/location.png" alt="" />
              </ng-template>
              </ul>
              <ul class="cards-list">
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 10,
                class: 'heading-home',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
          </div>

          <div class="inner-bottom">
            <div class="inner-left-sp">
              <span></span>
              <ul class="cards-list">
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 11,
                class: 'card-normal-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
            <div class="inner-left-sp">
              <span></span>

              <ul class="cards-list">
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 12,
                class: 'card-normal-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
            <div class="inner-left-sp">
              <span></span>

              <ul class="cards-list">
                <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 13,
                class: 'card-normal-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-inner-rate">
          <div class="inner-rate">
            <span class="price-heading">Class:</span>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 14,
                class: 'price-main',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
            </ul>
          </div>
          <div class="inner-rate">
            <span class="price-heading">Class:</span>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 15,
                class: 'price-offer',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
            </ul>
          </div>
        </div>

        <div class="card-mobile d-none">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 16,
                class: 'small-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>
        </div>
      </article>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates row" *ngIf="templateType == '305'">
  <div class="col-12">
    <div class="entity-002" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">

      <article class="entity-card-inner">
        <ul class="cards-img">
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'tmwf-img',
                isImage: true,
                cardDisplay: cardDisplay                     
              }
            }"></ng-template>
        </ul>

        <div class="cards-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'tmwf-title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
      </article>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates row" *ngIf="templateType == '306'">
  <div class="col-12">
    <div class="entity-003" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
      <article class="entity-card-inner">
        <div class="card-top">
          <ul class="cards-img">
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'tmwf-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>

          </ul>
          <ul class="cards-list">
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'card-heading text-truncate',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'card-subheading text-truncate',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  class: 'card-sub text-truncate',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="card-bottom">
          <div class="card-price">
            <div class="price-heading">Class</div>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'price-rate text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="card-price">
            <div class="price-headings">Class</div>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'price-rates text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
        </div>
      </article>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates row" *ngIf="templateType == '309'">
  <div class="col-12">
    <div class="parac-sq-wrfm-card" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
      <ul class="parac-sq-wrfm-card-img">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'parac-sq-wrfm-img',
            isImage: true,
            cardDisplay: cardDisplay                     
          }
        }"></ng-template>

      </ul>
      <ul class="parac-sq-wrfm-card-body">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'parac-sq-wrfm-title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'parac-sq-wrfm-info text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        <ul class="parac-sq-wrfm-ofr-price">
          <span class="class text-truncate">Class</span>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 3,
              class: 'member text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
        </ul>
        <li class="parac-sq-wrfm-gsi-link">
          <a href="javascript:;">
            Know More
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates template-310 row" *ngIf="templateType == '310'">
  <div class="col-12">
    <div class="parac-wrfm-card" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
      <ul class="parac-wrfm-card-img">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'parac-wrfm-img',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"></ng-template>
      </ul>
      <ul class="parac-wrfm-card-body">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 1,
                class: 'parac-wrfm-title text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'parac-wrfm-sub-title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: 'parac-wrfm-info text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        <ul class="parac-wrfm-price">
          <span class="text-truncate">Class</span>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 4,
              class: 'text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
        </ul>
        <ul class="parac-wrfm-ofr-price">
          <span class="text-truncate">Class</span>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 5,
              class: 'text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
        </ul>
        <ul class="parac-wrfm-quantity">
          <span class="text-truncate">Class</span>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 6,
              class: 'text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
        </ul>
        <li class="parac-wrfm-gsi-link">
          <a href="javascript:;">
            Know More
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates template-311 row" *ngIf="templateType == '311'">
  <div class="col-12">
    <div class="doctor-card-template" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
      [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
      <div class="doctor-details">
        <ul class="image">
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 0,
              class: 'logo',
              isImage: true,
              cardDisplay: cardDisplay                     
            }
          }"></ng-template>
        </ul>
        <ul class="details">
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 1,
              class: 'name',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 2,
              class: 'description text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 3,
              class: 'qualification text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
        </ul>
      </div>
      <div class="doctor-template-body">
        <div class="lang-loc">
          <ul class="language">
            <span class="class text-truncate">Class</span>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 4,
                class: 'member text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>
          <ul class="location">
            <span class="class text-truncate">Class</span>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 5,
                class: 'member text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>
        </div>
        <div class="fee-details">
          <ul class="fee">
            <span class="class text-truncate">Class</span>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 6,
                class: 'member text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>
          <ul class="price">
            <span class="class text-truncate">Class</span>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 7,
                class: 'member text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </ul>
        </div>
        <div class="gsi-link">
          <a href="javascript:;">
            Know More
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates" *ngIf="templateType == '312'">
  <div class="patient-card" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
    [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle">
    <div class="patient-card-top">
      <div class="patient-card-img">
        <ul>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'patient-card-img',
                isImage: true,
                cardDisplay: cardDisplay                     
              }
            }"></ng-template>
        </ul>
      </div>
      <div class="patient-card-details">
        <div class="patient-card-heading">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'patient-card-title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
          <div class="online card-logo">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'online-img',
                    isImage: true,
                    cardDisplay: cardDisplay                     
                  }
                }"></ng-template>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'online-txt',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
        </div>
        <div class="patient-card-content">
          <div class="patient-info-list">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'patient-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="patient-info-list">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'patient-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="patient-info-list">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'patient-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="patient-info-list">
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'patient-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="patient-bottom-content">
      <div class="p-content-sec">
        <div class="p-content-heading">Class</div>
        <ul>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 8,
                class: '',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
        </ul>
      </div>
      <div class="p-content-sec">
        <div class="p-content-heading">Class</div>
        <ul>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 9,
                class: '',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates" *ngIf="templateType == '313'">
  <div class="hdfc-card" CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
    [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle">
    <!-- <div class="patient-card-v2 dynamic-card-block">
      <div class="patient-card-top">
        <div class="patient-card-img">
          <ul>
            <li
              class="patient-card-img card-logo"
              (drop)="dropAttribute($event, 'image')"
              (dragover)="allowDrop($event)"
            >
              Attribute
            </li>
          </ul>
        </div>
        <div class="patient-card-details">
          <div class="patient-card-heading">
            <ul>
              <li
                class="only-value patient-card-title"
                (drop)="dropAttribute($event, 'any')"
                appConfigureAttribute
                (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                [isDesign]="isDesign"
                [_isCondition]="conditionColors"
                [_selectedDirectiveId]="selectedDirectiveId"
                (emitSelectedConditionElement)="recieveConditionColor($event)"
                (dragover)="allowDrop($event)"
              >
                Attribute
              </li>
              <li class="online card-logo">
                <ul>
                  <li class="online-img" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                    Attribute
                  </li>
                  <li
                    class="attribute-value"
                    (drop)="dropAttribute($event, 'any')"
                    appConfigureAttribute
                    (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                    [isDesign]="isDesign"
                    [_isCondition]="conditionColors"
                    [_selectedDirectiveId]="selectedDirectiveId"
                    (emitSelectedConditionElement)="recieveConditionColor($event)"
                    (dragover)="allowDrop($event)"
                  >
                    Attribute
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="patient-card-content">
            <ul>
              <li class="patient-info-list">
                <ul>
                  <li class="online-img" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                    Attribute
                  </li>
                  <li
                    class="patient-value Attribute-value text-truncate"
                    (drop)="dropAttribute($event, 'any')"
                    appConfigureAttribute
                    (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                    [isDesign]="isDesign"
                    [_isCondition]="conditionColors"
                    [_selectedDirectiveId]="selectedDirectiveId"
                    (emitSelectedConditionElement)="recieveConditionColor($event)"
                    (dragover)="allowDrop($event)"
                  >
                    Attribute
                  </li>
                </ul>
              </li>
              <li class="patient-info-list">
                <ul>
                  <li class="online-img" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                    Attribute
                  </li>
                  <li
                    class="patient-value Attribute-value text-truncate"
                    (drop)="dropAttribute($event, 'any')"
                    appConfigureAttribute
                    (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                    [isDesign]="isDesign"
                    [_isCondition]="conditionColors"
                    [_selectedDirectiveId]="selectedDirectiveId"
                    (emitSelectedConditionElement)="recieveConditionColor($event)"
                    (dragover)="allowDrop($event)"
                  >
                    Attribute
                  </li>
                </ul>
              </li>
              <li class="patient-info-list">
                <ul>
                  <li class="online-img" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                    Attribute
                  </li>
                  <li
                    class="patient-value Attribute-value text-truncate"
                    (drop)="dropAttribute($event, 'any')"
                    appConfigureAttribute
                    (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                    [isDesign]="isDesign"
                    [_isCondition]="conditionColors"
                    [_selectedDirectiveId]="selectedDirectiveId"
                    (emitSelectedConditionElement)="recieveConditionColor($event)"
                    (dragover)="allowDrop($event)"
                  >
                    Attribute
                  </li>
                </ul>
              </li>
              <li class="patient-info-list">
                <ul>
                  <li class="online-img" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                    Attribute
                  </li>
                  <li
                    class="patient-value Attribute-value text-truncate"
                    (drop)="dropAttribute($event, 'any')"
                    appConfigureAttribute
                    (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                    [isDesign]="isDesign"
                    [_isCondition]="conditionColors"
                    [_selectedDirectiveId]="selectedDirectiveId"
                    (emitSelectedConditionElement)="recieveConditionColor($event)"
                    (dragover)="allowDrop($event)"
                  >
                    Attribute
                  </li>
                </ul>
              </li>
              <li class="patient-info-list">
                <ul>
                  <li class="online-img" (drop)="dropAttribute($event, 'image')" (dragover)="allowDrop($event)">
                    Attribute
                  </li>
                  <li
                    class="patient-value Attribute-value text-truncate"
                    (drop)="dropAttribute($event, 'any')"
                    appConfigureAttribute
                    (emitSelectedElement)="recieveEmittedDirectiveId($event)"

                    [isDesign]="isDesign"
                    [_isCondition]="conditionColors"
                    [_selectedDirectiveId]="selectedDirectiveId"
                    (emitSelectedConditionElement)="recieveConditionColor($event)"
                    (dragover)="allowDrop($event)"
                  >
                    Attribute
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="patient-bottom-content">
        <ul>
          <li
            (drop)="dropAttribute($event, 'any')"
            appConfigureAttribute
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"

            [isDesign]="isDesign"
            [_isCondition]="conditionColors"
            [_selectedDirectiveId]="selectedDirectiveId"
            (emitSelectedConditionElement)="recieveConditionColor($event)"
            (dragover)="allowDrop($event)"
          >
            <span>Attribute</span>
            <span>Attribute</span>
          </li>
          <li
            (drop)="dropAttribute($event, 'any')"
            appConfigureAttribute
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"

            [isDesign]="isDesign"
            [_isCondition]="conditionColors"
            [_selectedDirectiveId]="selectedDirectiveId"
            (emitSelectedConditionElement)="recieveConditionColor($event)"
            (dragover)="allowDrop($event)"
          >
            <span>Attribute</span>
            <span>Attribute</span>
          </li>
          <li
            (drop)="dropAttribute($event, 'any')"
            appConfigureAttribute
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"

            [isDesign]="isDesign"
            [_isCondition]="conditionColors"
            [_selectedDirectiveId]="selectedDirectiveId"
            (emitSelectedConditionElement)="recieveConditionColor($event)"
            (dragover)="allowDrop($event)"
          >
            <span>Attribute</span>
            <span>Attribute</span>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="hdfc-card-header">
      <div class="hdfc-card-logo">
        <ul>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: '',
                isImage: true,
                cardDisplay: cardDisplay                     
              }
            }"></ng-template>
        </ul>
      </div>
      <div class="hdfc-card-header-right">
        <div class="hdfc-card-heading">
          <ul class="hdfc-card-heading-lists">
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'hdfc-card-h1',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'hdfc-card-h2',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
          <ul class="online-section">
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  class: 'online-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  class: 'online-txt',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="hdfc-card-gsiBtn">
          <span class="gsiBtn-info">Class</span>
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 5,
                  class: 'btn',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
      </div>
    </div>
    <div class="hdfc-card-body">
      <div class="hdfc-card-listing">
        <div class="hdfc-card-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 6,
                  class: 'hdfc-card-list-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 7,
                  class: 'hdfc-card-listText',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="hdfc-card-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 8,
                  class: 'hdfc-card-list-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 9,
                  class: 'hdfc-card-listText',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="hdfc-card-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 10,
                  class: 'hdfc-card-list-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 11,
                  class: 'hdfc-card-listText',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="hdfc-card-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 12,
                  class: 'hdfc-card-list-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 13,
                  class: 'hdfc-card-listText',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
        <div class="hdfc-card-list">
          <ul>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 14,
                  class: 'hdfc-card-list-img',
                  isImage: true,
                  cardDisplay: cardDisplay                     
                }
              }"></ng-template>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 15,
                  class: 'hdfc-card-listText',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </ul>
        </div>
      </div>
      <div class="hdfc-card-bottom">
        <div class="hdfc-card-ft-listing">
          <div class="hdfc-card-ft-list">
            <span>Class</span>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: '',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="hdfc-card-ft-list">
            <span>Class</span>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: '',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
          <div class="hdfc-card-ft-list">
            <span>Class</span>
            <ul>
              <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: '',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="templateType == '316'">
  <div class="entitytemplates symptoms-cards type-templates" CardDesignConfig [cardHoveredColor]="cardHoveredColor"
    [isEdit]="isEdit" [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle">
    <ul>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
        data: {
          position: 0,
          class: 'image text-truncate',
          isImage: true,
          cardDisplay: cardDisplay                     
        }
      }"></ng-template>
      <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
        data: {
          position: 1,
          class: 'text text-truncate',
          cardDisplay: cardDisplay,
          isSingleValue: true
        }
      }"></ng-template>
    </ul>
  </div>
</div>
</div>
<div class="col-12 mt-3 ml-3" *ngIf="templateType == '324'" CardDesignConfig [cardHoveredColor]="cardHoveredColor"
  [isEdit]="isEdit" [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
  <div class="amst-card amst-wireframe amst-template-324">
    <div class="amst-card-head">
      <ul class="amst-title-icon text-truncate">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'content-heading',
                isImage: true,
                cardDisplay: cardDisplay                    
              }
            }"></ng-template>
      </ul>
      <div class="amst-card-title">
        Entity Name
      </div>
    </div>
    <div class="amst-card-body">
      <ul>
        <div class="amst-label-cont">
          <div class="amst-label-title text-truncate">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'amst-label text-truncate',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title text-truncate">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'amst-label text-truncate',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title text-truncate">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  class: 'amst-label',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  class: 'amst-label',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
        </div>
      </ul>
    </div>
  </div>
</div>
<div class="col-12 mt-3 ml-3" *ngIf="templateType == '323'" CardDesignConfig [cardHoveredColor]="cardHoveredColor"
  [isEdit]="isEdit" [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
  <div class="amst-card amst-gmap-card amst-extra-card amst-wireframe">
    <div class="amst-card-head">
      <ul class="amst-title-icon">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 0,
                class: 'content-heading',
                isImage: true,
                cardDisplay: cardDisplay
              }
            }"></ng-template>
      </ul>
      <div class="amst-card-title">
        Entity Name
      </div>
    </div>
    <div class="amst-card-body">
      <ul>
        <div class="amst-label-cont amst-label-full amst-bold-title">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'content-heading',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <ul class="amst-details">
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 2,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 3,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 4,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
        </ul>
        <ul class="amst-details">
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 5,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 6,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 7,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
        </ul>
        <ul class="amst-details">
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 8,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 9,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 10,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
        </ul>
        <ul class="amst-details">
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 11,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 12,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
          </div>
        </ul>
      </ul>
    </div>
  </div>
</div>
<div class="col-12 mt-3 ml-3" *ngIf="templateType == '322'" CardDesignConfig [cardHoveredColor]="cardHoveredColor"
  [isEdit]="isEdit" [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
  <div class="amst-card amst-gmap-card amst-wireframe">
    <div class="amst-card-head">
      <ul class="amst-title-icon">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: '',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"></ng-template>
      </ul>
      <div class="amst-card-title">
        Entity Name
      </div>
    </div>
    <div class="amst-card-body">
      <ul>
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'amst-label-cont amst-label-gmap',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        <div class="amst-label-cont amst-label-full">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'amst-label',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
        </div>
        <ul class="amst-details">
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 3,
                class: 'amst-label',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </div>
          <div class="amst-label-cont">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  class: 'amst-label',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"></ng-template>
          </div>
          <div class="amst-label-cont amst-bold-title">
            <div class="amst-label-title">Class</div>
            <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
              data: {
                position: 5,
                class: 'amst-label amst-danger-label',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"></ng-template>
          </div>
        </ul>
      </ul>
    </div>
  </div>
</div>
<div class="col-12 mt-3 ml-3"  *ngIf="templateType == '325'" [ngStyle]="{ width: tabSelected == 'expand' ? '100%' : '33.3%' }" CardDesignConfig
  [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit" [id]="'Template-' + templateType"
  [_changeShadowStyle]="boxShadowData" [style]="style" [_selectedDirectiveId]="selectedDirectiveId"
  (emitSelectedElement)="recieveEmittedDirectiveId($event)" [cardBorderSize]="cardBorderSize"
  [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor" [cardShadow]="cardShadow"
  [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
  <div class="amst-card amst-gmap-card amst-extraV2-card amst-wireframe">
    <div class="amst-card-head">
      <ul class="amst-title-icon">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'content-heading',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"></ng-template>
      </ul>
      <div class="amst-card-title">
        Entity Name
      </div>
    </div>
    <div class="amst-card-body">
      <ul>
        <div class="amst-label-cont amst-label-full amst-bold-title">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'content-heading',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'content-heading',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 4,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 5,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 6,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 7,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 8,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 9,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <!-- <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 10,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 11,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 12,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div> -->
      </ul>
    </div>
  </div>
</div>

<div class="col-12 mt-3 ml-3"  *ngIf="templateType == '326'" [ngStyle]="{ width: tabSelected == 'expand' ? '100%' : '33.3%' }" CardDesignConfig
  [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit" [id]="'Template-' + templateType"
  [_changeShadowStyle]="boxShadowData" [style]="style" [_selectedDirectiveId]="selectedDirectiveId"
  (emitSelectedElement)="recieveEmittedDirectiveId($event)" [cardBorderSize]="cardBorderSize"
  [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor" [cardShadow]="cardShadow"
  [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
  <div class="amst-card amst-gmap-card amst-extraV3-card amst-wireframe">
    <div class="amst-card-head">
      <ul class="amst-title-icon">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'content-heading',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"></ng-template>
      </ul>
      <div class="amst-card-title">
        Entity Name
      </div>
    </div>
    <div class="amst-card-body">
      <ul>
        <div class="amst-label-cont amst-label-full amst-bold-title">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'content-heading',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <ul class="amst-details">
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
            data: {
              position: 2,
              class: 'amst-label',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont amst-redlabel-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 4,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        </ul>
        <ul class="amst-details">
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 5,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 6,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 7,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        </ul>
        <ul class="amst-details">
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 8,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 9,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 10,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        </ul>
        <ul class="amst-details">
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 11,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 12,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        </ul>
      </ul>
    </div>
  </div>
</div>

<div class="col-12 mt-3 ml-3" *ngIf="templateType == '327'" [ngStyle]="{ width: tabSelected == 'expand' ? '100%' : '33.3%' }"
   CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
  [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
  <div class="amst-card amst-culumn2-card amst-wireframe">
    <div class="amst-card-head">
      <ul class="amst-title-icon">
        <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'content-heading',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"></ng-template>
      </ul>
      <div class="amst-card-title">
        Entity Name
      </div>
    </div>
    <div class="amst-card-body">
      <ul>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
        <div class="amst-label-cont">
          <div class="amst-label-title">Class</div>
          <ng-template [ngTemplateOutlet]="attributeTemplate" [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'amst-label',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"></ng-template>
        </div>
      </ul>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates" *ngIf="templateType == '328'">
  <div
    class="entity-comment-section"
    CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
  [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
    <ul class="comment-inner">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'comment-img',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>

      <div class="content-section">
        <div class="division-section">
          <ul>
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                class: 'c-name',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                class: 'c-date',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          </ul>
        </div>
        <div class="c-comment-dis">
          <ul>
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                class: 'c-comments',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</div>

<div *ngIf="templateType == '321'"  class="entitytemplates type-templates">
  <div class="cart-view-wrfm-card"
    CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
  [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
      <div class="cart-view-wrfm-logo">
        <ul>
          <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: '',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
        </ul>
      </div>
      <div class="cart-view-wrfm-right-side">
        <div class="cart-view-wrfm-cont-left">
          <ul>
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                class: '',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                class: 'cart-view-wrfm-middle-list',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 3,
              class: '',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
          </ul>
        </div>
        <div class="cart-view-wrfm-cont-right">
          <ul>
            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 4,
                class: '',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          </ul>
        </div>
      </div>
  </div>
</div>

<div *ngIf="templateType == '318'">
  <div
    class="entitytemplates book-now-318 type-templates"
    CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
  [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
    <ul>
      <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 0,
          class: 'image',
          isImage: true,
          cardDisplay: cardDisplay
        }
      }"
    ></ng-template>
      <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 1,
          class: 'text text-truncate',
          cardDisplay: cardDisplay,
          isSingleValue: true
        }
      }"
    ></ng-template>
    </ul>
  </div>
</div>
<div *ngIf="templateType == '319'">
  <div
    class="entitytemplates type-templates product-info-card-319"
    CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
  [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
    <ul>
      <div class="image-wrapper">
        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'image',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'percentage-off text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      </div>
      <div class="product-details-wrapper">
        <div class="product-details">
          <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
          <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: 'quantity text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
        </div>
        <div class="product-details">
          <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 4,
            class: 'product-finalprice text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
          <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 5,
            class: 'product-actualprice text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
        </div>
        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 6,
            class: 'text text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      </div>
    </ul>
  </div>
</div>
<div *ngIf="templateType == '320'">
  <div
    class="entitytemplates order-summery type-templates"
    CardDesignConfig [cardHoveredColor]="cardHoveredColor" [isEdit]="isEdit"
  [id]="'Template-' + templateType" [_changeShadowStyle]="boxShadowData" [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId" (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize" [cardBorderRadius]="cardBorderRadius" [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow" [cardColor]="cardColor" [card]="card" [cardDisplay]="cardDisplay" [(cardStyle)]="cardStyle">
    <ul>
      <span>Class</span>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
     <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 4,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 5,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
     <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 6,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
     <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 7,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul>
      <span>Class</span>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 8,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="d-flex flex-column">
  <mat-slide-toggle class="nh-mb-16" *ngIf="templateType == 334" [(ngModel)]="isHovered"
    >hovered State</mat-slide-toggle
  >

  <div class="studioverse_cu_card dynamic-card-block" *ngIf="templateType == 334">
    <div class="nhentitycard nh-position-relative" [ngClass]="{ hovered: isHovered }">
      <div class="nhentitycard_top nh-position-relative nh-mb-12">
        <ul class="nhentitycard_image nh-display-flex nh-flex-wrap">

                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 0, isImage: true, cardDisplay: cardDisplay ,class: 'card-logo nh-wp-50' }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isImage: true, cardDisplay: cardDisplay ,class: 'card-logo nh-wp-50' }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 2, isImage: true, cardDisplay: cardDisplay ,class: 'card-logo nh-wp-50' }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 3, isImage: true, cardDisplay: cardDisplay ,class: 'card-logo nh-wp-50' }
                    }"
                  ></ng-template>
        </ul>
        <ul class="nhentitycard_tiles nh-position-absolute nh-display-flex nh-align-center">
          <em class="nh-icon nh-icon-play-circle nh-fs-16 nh-mr-8"></em>
          <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 4,isSingleValue:true, cardDisplay: cardDisplay ,class: 'only-value nh-fs-12 nh-mr-3' }
                    }"
                  ></ng-template>

          <span class="statictext nh-fs-12">Titles</span>
        </ul>
        <div class="learnmore nh-position-absolute nh-hp-100 nh-wp-100">
          <ul class="nh-position-absolute">

            <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 5,isSingleValue:true, cardDisplay: cardDisplay ,class: 'only-value nh-display-inline-block cursor-pointer nh-radius-18' }
            }"
          ></ng-template>
          </ul>
        </div>
      </div>
      <ul class="nhentitycard_title nh-position-relative nh-mb-8">

        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 6,isSingleValue:true, cardDisplay: cardDisplay ,class: 'nhentitycard_title nh-position-relative nh-mb-8' }
        }"
      ></ng-template>
      </ul>
      <ul class="nhentitycard_tags nh-display-flex nh-column-gap-4 nh-position-relative">

        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 7, isSingleValue:true, cardDisplay: cardDisplay , class: 'only-value nh-radius-24' }
        }"
      ></ng-template>

        <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 8, isSingleValue:true, cardDisplay: cardDisplay , class: 'only-value nh-radius-24' }
        }"
      ></ng-template>
      </ul>
    </div>
  </div>
</div>
