import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: number, countryCode: string): string {
    const stringValue = value?.toString();
    const digits = stringValue?.replace(/\D/g, ''); // Remove non-digit characters
    if (!digits) {
      return '';
    }
    // Remove non-digit characters
    if (countryCode == '+1') {
      let length = digits?.length;
      if (length == 0) {
        return '';
      } else if (length > 0 && length <= 3) {
        return `(${digits.slice(0, length)})`;
      } else if (length > 3 && length <= 6) {
        return `(${digits.slice(0, 3)}) ${digits.slice(3, length)}`;
      } else if (length > 6) {
        return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, length)}`;
      }
    } else {
      return digits;
    }
  }
}
