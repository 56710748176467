import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BetConfiguration } from './bet-configuration.component';
import { ConnectionComponent } from './connection/connection.component';
import { DashboardConflictsComponent } from './dashboard/dashboard-conflicts/dashboard-conflicts.component';
import { DatasetConflictsComponent } from './dataset/dataset-conflicts/dataset-conflicts.component';
import { BookOverview } from './overview/book-overview.component';
import { ConflictRoles } from './roles/conflict-roles.component';
import { ViewRoles } from './roles/view-roles.component';
import { ConfigureSolutions } from './solutions/configure-solutions.component';
import { MaterialModule } from '../materail/material.module';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { BetConfigurationRoutingModule } from './bet-configuration-routing.module';
import { SharedErrorHandlerModule } from '../shared-error-handler/shared-error-handler.module';
// import { NodeCreateSuggestionComponent } from './node-system-grammer/components/node-create-suggestion/node-create-suggestion.component';

@NgModule({
  declarations: [
    BetConfiguration,
    ConnectionComponent,
    DashboardConflictsComponent,
    DatasetConflictsComponent,
    BookOverview,
    ConflictRoles,
    ViewRoles,
    ConfigureSolutions,
  ],
  imports: [
    CommonModule,
    ApplicationPipesModule,
    FormsModule,
    MaterialModule,
    SelectDropDownModule,
    BetConfigurationRoutingModule,
    SharedErrorHandlerModule
  ],
  exports: [
    BetConfiguration,
    ConnectionComponent,
    DashboardConflictsComponent,
    DatasetConflictsComponent,
    BookOverview,
    ConflictRoles,
    ViewRoles,
    ConfigureSolutions,
  ],
})
export class BetConfigurationModule {}
