import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AlertService,
  GsiFacadeService, Librarytypes,
  NodeGsiFacadeService,
  TranslatorService, getObjectByValue
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-node-gsi-edit',
  templateUrl: './node-gsi-edit.component.html',
  styleUrls: ['./node-gsi-edit.component.scss'],
})
export class NodeGsiEditComponent implements OnInit, OnDestroy {
  @Input() tempGsiObj: any;
  @Input() configurationData: any;
  @Output() emitChangeCompoent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closegsi: EventEmitter<any> = new EventEmitter<any>();
  close: boolean = false;
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  labelForInformationLayer = { name: 'information Layer', label: 'information Layer' };
  roles: any = [];
  selectedindex: any;
  step: number = 0;
  recursiveGSI: boolean = false;
  operators: any = ['<', '>', '==', '!=', '<=', '>='];
  recursiveOperator: any;
  constraintName: any;
  constraint: any;
  conditionValue: any;
  physicalLayer: any = [];
  informationLayer: any = [];
  cuPhysicalLayer: any = [];
  cuInformationLayer: any = [];
  labels: any;
  recursiveCUList: any = [];
  private ngUnsubscribe = new Subject();
  disableSTEs: boolean = false;
  ontologyList: any;
  orgOntologyList: any;
  lastChild: any;
  metaInfo: any = { ontology: [], description: '', author: {}, keywords: '' };
  constructor(
    private gsiFacadeService: GsiFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private alertService: AlertService
  ) {
    this.getDCDStream();
    this.detectLanguageChange();
    this.detectMasterOntologies();
  }

  ngOnInit(): void {
    /* istanbul ignore next */

    this.tempGsiObj?.designTimeRights?.forEach((role: any) => {
      this.roles.push({
        name: role.name ? role.name : role.rightHolderName,
        id: role.rightHolderId,
        rightHolderId: role.rightHolderId,
      });
    });
    this.configurationData?.data?.designTimeRights?.forEach((role: any) => {
      this.roles.push({
        name: role.name ? role.name : role.rightHolderName,
        id: role.rightHolderId,
        rightHolderId: role.rightHolderId,
      });
    });
    this.getDesignRightsIndex(this.tempGsiObj.designTimeRights);
    this.getTxnRightsIndex(this.tempGsiObj.txnTimeRights);
    this.getLayersData();
    /* istanbul ignore next */
    if (this.tempGsiObj?.constraintName) {
      this.recursiveGSI = true;
    }
    this.disableSTEs = true;
    /* istanbul ignore next */
    this.tempGsiObj?.solutionLogic?.forEach((changeUnit: any) => {
      /* istanbul ignore next */
      if (changeUnit?.disableSTEs) {
        this.disableSTEs = false;
      }
    });
    this.fetchOntologies();
  }
  ngOnChanges() {
    setTimeout(() => {
      this.detectMetaInfo();
    }, 1500);
  }
  getDesignRightsIndex(role: any) {
    /* istanbul ignore next */
    if (this.tempGsiObj?.designTimeRights && this.tempGsiObj?.designTimeRights.length > 0 && role) {
      return this.tempGsiObj.designTimeRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.id === role.id
      );
    }
    return -1;
  }
  getTxnRightsIndex(role: any) {
    /* istanbul ignore next */
    if (this.tempGsiObj?.txnTimeRights && this.tempGsiObj?.txnTimeRights.length > 0 && role) {
      return this.tempGsiObj.txnTimeRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.id === role.id
      );
    }
    return -1;
  }
  removeAgent(i: any) {
    this.roles.splice(i, 1);
    this.tempGsiObj.designTimeRights.splice(i, 1);
    this.tempGsiObj.txnTimeRights.splice(i, 1);
  }
  showRights(i: any) {
    this.selectedindex = i;
  }
  GotoPrevious() {
    this.emitChangeCompoent.emit(Librarytypes.AddNodeActionMenu);
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  closeGsi() {
    this.close = true;
    this.closegsi.emit(this.close);
  }
  saveGsi() {
     /* istanbul ignore next */
    this.tempGsiObj.author = this.metaInfo?.author;
     /* istanbul ignore next */
    this.tempGsiObj.description = this.metaInfo?.description;
     /* istanbul ignore next */
    this.tempGsiObj.keywords = this.metaInfo?.keywords instanceof Array ? this.metaInfo?.keywords : this.metaInfo?.keywords?.split(',');
    this.gsiFacade.setCloseActionMenu(true);
    this.GotoPrevious();
    this.gsiFacade.updateGsi(this.tempGsiObj);
  }
  setStep(index: number) {
    this.step = index;
  }
  /* istanbul ignore next */
  menuenter() {
    this.gsiFacadeService.menuenter();
  }
  /* istanbul ignore next */
  menuLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }
  /* istanbul ignore next */
  buttonEnter(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }
  /* istanbul ignore next */
  buttonLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }
  removeConstraint() {
    this.constraintName = '';
    this.constraint = '';
    this.recursiveOperator = '';
    this.conditionValue = '';
    /* istanbul ignore else*/
    if (this.tempGsiObj?.constraintName !== '') {
      this.tempGsiObj.constraintName = '';
      this.tempGsiObj.constraint = '';
    }
  }
  // forming the expression for recursive cu type
  addCondition() {
    /* istanbul ignore next*/
    if (this.recursiveGSI) {
      this.constraintName = this.constraintName ? this.constraintName : '';
      this.constraint = this.constraint ? this.constraint : '';
      /* istanbul ignore else*/
      if (this.conditionValue !== '' && isNaN(this.conditionValue)) {
        this.conditionValue = `'${this.conditionValue}'`;
      }
      this.tempGsiObj.constraintName = this.constraintName + this.recursiveOperator + this.conditionValue;
      /* istanbul ignore else*/
      if (this.constraint !== this.tempGsiObj.constraint) {
        this.tempGsiObj.constraint =
          this.constraint && this.recursiveOperator && this.conditionValue
            ? this.constraint + this.recursiveOperator + this.conditionValue
            : this.constraint + this.conditionValue;
      }
      /* istanbul ignore next*/
      if (this.tempGsiObj?.constraint === '' && this.tempGsiObj?.constraintName !== '') {
        this.tempGsiObj.constraint = this.tempGsiObj?.constraintName;
      }
    }
    this.constraintName = undefined;
    this.conditionValue = undefined;
    this.recursiveOperator = undefined;
  }
  getLayersData() {
    /* istanbul ignore else*/
    this.recursiveCUList = [];
    /* istanbul ignore next*/
    this.tempGsiObj?.solutionLogic?.forEach((changeUnit: any) => {
      if (changeUnit?.nextTriggerSet?.length === 0) {
        this.recursiveCUList.push(changeUnit);
      }
    });
    if (this.recursiveCUList.length > 0) {
      this.getCULayerData(this.recursiveCUList);
    }
    this.physicalLayer = [];
    this.informationLayer = [];
    // this.triggerCESLayer = [];
    /* istanbul ignore else*/

    /* istanbul ignore next*/
    if (this.tempGsiObj?.layers?.length > 0) {
      this.tempGsiObj?.layers?.forEach((layer: any) => {
        /* istanbul ignore else*/
        if (layer?.participatingItems?.length > 0) {
          if (layer?.type == 'information') {
            layer?.participatingItems?.forEach((participatingItem: any) => {
              let entityIndex;
              /* istanbul ignore else*/
              if (participatingItem && participatingItem?.name) {
                entityIndex = this.informationLayer.findIndex(
                  (ele: any) => ele.item.DATA.name === participatingItem.name
                );
              }
              /* istanbul ignore else*/
              if (entityIndex === -1) {
                this.informationLayer.push(participatingItem);
              }
            });
          } else if (layer?.type == 'physical') {
            layer?.participatingItems?.forEach((participatingItem: any) => {
              let entityIndex;
              /* istanbul ignore else*/
              if (participatingItem && participatingItem.name) {
                entityIndex = this.physicalLayer.findIndex((ele: any) => ele.name === participatingItem.name);
              }
              /* istanbul ignore else*/
              if (entityIndex === -1) {
                this.physicalLayer.push(participatingItem);
              }
            });
          }
          // else if (layer?.type == 'triggerCES') {
          /* istanbul ignore else*/
          // layer?.participatingItems?.forEach((participatingItem: any) => {
          //   this.triggerCESLayer.push(participatingItem);
          // });
          // }
        }
      });
    }
  }
  getCULayerData(cuList: any) {
    this.cuPhysicalLayer = [];
    this.cuInformationLayer = [];
    cuList.forEach((cu: any) => {
      /* istanbul ignore next*/
      if (cu?.layers?.length > 0) {
        cu?.layers?.forEach((layer: any) => {
          /* istanbul ignore else*/
          if (layer?.participatingItems?.length > 0) {
            if (layer?.type == 'information') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /* istanbul ignore else*/
                if (participatingItem && participatingItem?.name) {
                  entityIndex = this.cuInformationLayer.findIndex((ele: any) => ele?.name === participatingItem.name);
                }
                /* istanbul ignore else*/
                if (entityIndex === -1) {
                  participatingItem.cuName = cu.name;
                  this.cuInformationLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'physical') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /* istanbul ignore else*/
                if (participatingItem && participatingItem.name) {
                  entityIndex = this.cuPhysicalLayer.findIndex((ele: any) => ele.name === participatingItem.name);
                }
                /* istanbul ignore else*/
                if (entityIndex === -1) {
                  participatingItem.cuName = cu.name;
                  this.cuPhysicalLayer.push(participatingItem);
                }
              });
            }
          }
        });
      }
    });
  }

  getDCDStream() {
    this.gsiFacade.recursiveExpression$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res && res.formulaId && res?.cuType === 'recursive') {
        /* istanbul ignore else*/
        if (this.recursiveGSI) {
          this.constraintName = res.formulaId;
          this.constraint = res.formulaId;
        }
      }
    });
  }
  autoCheckRights(rights: any) {
    /* istanbul ignore next*/
    if ((rights && rights?.decisionRight) || rights?.executionRight) {
      rights.informationRight = true;
    }
  }
  disableSTEGSI() {
    /* istanbul ignore next */
    this.tempGsiObj?.solutionLogic?.forEach((changeUnit: any) => {
      changeUnit.disableSTEs = false;
    });
  }

  detectMasterOntologies() {
    this.gsiFacade.populateOntologies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.ontologyList = [res];

        this.orgOntologyList = [res];
      }
    });
  }
  onChangeOntology(item: any, index: number) {
    /* istanbul ignore next */
    if (item) {
      this.lastChild = item;
      this.metaInfo = {
        ...this.metaInfo,

        ontology: [{ id: this.lastChild?.id }],
      };
    } else {
      this.lastChild = null;
      this.metaInfo = { ...this.metaInfo, ontology: [] };
    }
    this.ontologyList.splice(index + 1, this.ontologyList.length);
    this.tempGsiObj.ontology = this.metaInfo?.ontology;
    this.tempGsiObj.author = this.metaInfo?.author;
    this.tempGsiObj.description = this.metaInfo?.description;
    this.tempGsiObj.keywords =
      this.metaInfo?.keywords instanceof Array ? this.metaInfo?.keywords : this.metaInfo?.keywords?.split(',');
  }
  onClickActionIcons() {
    if (!this.lastChild) {
      this.alertService.showToaster('Please select an ontology', '', 'info');
    } else if (this.lastChild?.children?.length > 0) {
      /* istanbul ignore next */
      this.ontologyList = [...this.ontologyList, this.lastChild];
      this.lastChild = {};
    } else {
      this.alertService.showToaster('Subcategory not found', '', 'info');
    }
  }
  detectMetaInfo() {
    /* istanbul ignore next */
    let ontData = this.tempGsiObj?.ontology?.filter((x: any) => x.level != 1);
    let data: any = [];
     /* istanbul ignore next */
    this.metaInfo.description = this.tempGsiObj?.description;
    /* istanbul ignore next */
    if (this.tempGsiObj?.keywords?.length > 0) {
      let str = '';
       /* istanbul ignore next */
      for(let key of this.tempGsiObj?.keywords)
      {
         /* istanbul ignore next */
        if(key != '')
        {
          str += key;
          str += ',';
        }
      }
      str = str.replace(/,\s*$/, "");
      this.metaInfo.keywords = str == ',' ? '' : str;
    }
    /*istanbul ignore else */
    if (ontData?.length > 0) {
      for (let i = 0; i < ontData.length; i++) {
        let x = ontData[i];
        x = {
          id: getObjectByValue('id', x?.id, this.ontologyList[i]?.children),
          children: this.ontologyList[i]?.children,
        };
        data.splice(i, 1);
        data.push(x);
        /*istanbul ignore next */
        if (x?.children?.length > 0 && x?.id?.children.length > 0 && i + 1 != ontData.length) {
          data.push(x?.id);
        }
        this.lastChild = x?.id;
        this.ontologyList = data;
      }
    } else {
      this.ontologyList = this.orgOntologyList;
    }
  }
  onClearClick(item: any) {
    item.searchText = '';
  }
  fetchOntologies() {
    this.gsiFacade.fetchOntologies();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

