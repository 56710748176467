<div class="nsl-special-features">
  <div class="special-features-top">
    <div class="topaction-btns">
      <button class="btn btn-done" (click)="saveSpecialFeaturePayload()">{{labels?.Done}}</button>
      <button class="btn-close" (click)="onCloseDialog()">{{labels?.Close}}</button>
    </div>
    <div class="sf-title">
      <h3>{{labels?.Special_Features}}</h3>
      <h4>{{ currentCuData?.displayName }}</h4>
    </div>
  </div>
  <div class="special-features-tab">
    <div class="tabgroup">
      <div class="tabgroup-btns">
        <button
        [disabled]="isnode == 'ishyb'"
          *ngFor="let sf of allSpecialFeatures; let i = index"
          (click)="selectSfTab(sf)"
          class="btn feature-btn"
          [ngClass]="{ active: sf == selectedSf }"
          pTooltip="{{ sf?.name }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
        >
          {{ sf?.name }}
          <span class="spl-ftrs-info-icon bi bi-info-circle" pTooltip="{{ sf.info }}"></span>
        </button>
      </div>
      <div class="tabgroup-content">
        <div>
          <div class="tab-content">
            <div class="tab-content-list">
              <div class="">
                <ul>
                  <li class="position-relative" id="selectedCu">
                    <mat-form-field appearance="standard">
                      <mat-label>{{labels?.Select_CU}}</mat-label>
                      <mat-select [(ngModel)]="currentCuData" (selectionChange)="updateData($event)">
                        <mat-option *ngFor="let cu of tempGsiObj?.solutionLogic" [value]="cu">{{
                          cu?.displayName
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </li>
                  <li class="position-relative"  id="selectedEntity">
                    <mat-form-field appearance="standard">
                      <mat-label>{{labels?.Select_Entity}}</mat-label>
                      <mat-select [(ngModel)]="selectedEntity" [disabled]="isnode == 'ishyb'">
                        <mat-option [value]="">{{labels?.Create_New}}</mat-option>
                        <mat-option *ngFor="let entity of selectedLayer?.participatingItems" [value]="entity">{{
                          entity.displayName
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </li>
                  <li *ngIf="!selectedEntity?.id && reserveEntity != 'reserveEntity'">
                    <div class="form-group">
                      <label>{{labels?.Create_New_Entity}}</label>
                      <input [(ngModel)]="createNewEntity" type="text" placeholder="Enter entity name" />
                    </div>
                    <div class="entity-pop d-none">
                      <div class="enity-pop-info">
                        <p class="text-right"><button class="material-icons btn-close">close</button></p>
                        <h4>{{labels?.Given_Entity_already_exists}}</h4>
                        <div class="text-center">
                          <button class="entitybtn btn">{{labels?.Use_Same_Entity}}</button>
                          <button class="entitybtn btn">{{labels?.Rename}}</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li *ngIf="reserveEntity != 'reserveEntity' || selectedAttribute?.id" class="position-relative"  id="selectedAttribute">
                    <mat-form-field appearance="standard">
                      <mat-label>{{labels?.Select_Attribute}}</mat-label>
                      <mat-select [(ngModel)]="selectedAttribute" [disabled]="isnode == 'ishyb'">
                        <mat-option [value]="">{{labels?.Create_New}}</mat-option>
                        <mat-option *ngFor="let attribute of selectedEntity?.nslAttributes" [value]="attribute">{{
                          attribute.name
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </li>
                  <li *ngIf="!selectedAttribute?.id && reserveEntity != 'reserveEntity'">
                    <div class="form-group">
                      <label>{{labels?.Create_Attribute}}</label>
                      <input [(ngModel)]="createNewAttribute" type="text" placeholder="Enter attribute name" />
                    </div>
                  </li>
                </ul>
                <button *ngIf="reserveEntity != 'reserveEntity'" class="btn btn-validate" [disabled]="isnode == 'ishyb'" (click)="createEntity()">
                  {{labels?.Validate}}
                </button>
              </div>
              <div class="sf-fileds-two">
                <div class="config-sf">
                  <h3 class="config-title">
                    {{labels?.Configure_Special_Features}}
                  </h3>
                  <div class="d-block">
                    <!-- *ngIf="openSfForm"  -->
                    <app-node-special-features
                      *ngIf="renderSfForm"
                      #sfInNode
                      [sourceData]="entryVantageData"
                      [selectedEventType]="selectedEventType"
                      [selectedSf]="selectedSf"
                      [gsiData]="tempGsiObj"
                      [participatingLayer]="selectedLayer?.type"
                      [entity]="selectedEntity"
                      [attribute]="selectedAttribute"
                      [slotIndex]="slotIndex"
                      [cuIndex]="currentCuData?.index"
                      [initialPayLoad]="existingConfig"
                      (payLoadEmitter)="findSpecialFeaturePayload($event)"
                      (sendReserveEnt)="addReserveEnt($event)"
                    >
                    </app-node-special-features>
                    <div class="addmeasures">
                      <div>
                        <button (click)="triggerSfNodeSubmit()" class="btn btncreate">{{labels?.Create}}</button>
                      </div>
                    </div>
                    <button class="btn btn-validate" [disabled]="isnode == 'ishyb'" (click)="clickedAddMeasures()">{{labels?.Add_Measure}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="special-features-tablist">
      <div class="tablist-data">
        <h3>{{labels?.Configured_Special_Feature}}</h3>
        <div class="text-box">
          <div class="text line-clamp">
            <p *ngIf="postFactSentence">{{ postFactSentence }}</p>
          </div>
        </div>
      </div>
      <div class="tablist-data">
        <h3>{{labels?.Configured_Measures}}</h3>
        <div class="text-box">
          <div class="text">
            {{labels?.No_Text}}
          </div>
        </div>
        <div *ngFor="let measure of measuresSf[0]?.rating; let i = index">
          <p>
            {{ measure?.measureDataEntity?.name }}{{ measure?.condition }} is
            {{ (measure.measureDataEntity?.nslAttributes)[0].defaultValue }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
