declare var require: any;
let transform = require('node-json-transform').transform;

export function gsiListMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      content: 'data',
      total: 'totalResults',
    },
    operate: [
      {
        run: function (val: any[]) {
          /*istanbul ignore next*/
          return val?.map((x: any) => {
            return {
              gsiId: x.id, // used for export solutions //
              dsdId: x.dsdId,
              id: x.dsdId,
              name: x.name,
              status: x.status,
              displayName: x.displayName,
              design: x.design,
              description: x.description,
              masterId: x?.masterId,
            };
          });
        },
        on: 'content',
      },
    ],
  };
  return transform(data, map);
}
