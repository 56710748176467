<div class="custom-dashboard-table d-flex flex-column">
  <table>
    <thead>
      <tr>
        <td colspan="3">
          <div class="border nh-p-16 nh-radius-8 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Ambulance Details</p>
          </div>
        </td>
        <ng-container *ngFor="let event of ambulanceDetails[0].events">
          <td [attr.colspan]="event.seriesOfEventsHappening.length">
            <div
              class="border nh-p-16 nh-radius-8 nh-mr-10 nh-mb-10"
              [ngClass]="{
                'bg-light-yellow': event.eventName === 'Off Road',
                'bg-light-red': event.eventName === 'Workshop',
                'bg-light-purple': event.eventName === 'Back Redeployment'
              }"
            >
              {{ event.eventName }}
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td>
          <div class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Registration Number</p>
          </div>
        </td>
        <td>
          <div class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Off Road Date and Time</p>
          </div>
        </td>
        <td>
          <div class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Off Road Duration</p>
          </div>
        </td>
        <ng-container *ngFor="let event of ambulanceDetails[0].events">
          <td *ngFor="let seriesEvent of event.seriesOfEventsHappening">
            <div
              class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10"
              [ngClass]="{
                'bg-light-yellow': event.eventName === 'Off Road',
                'bg-light-red': event.eventName === 'Workshop',
                'bg-light-purple': event.eventName === 'Back Redeployment'
              }"
            >
              {{ seriesEvent.name }}
            </div>
          </td>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of cardsData">
        <tr class="h-60">
          <ng-container *ngFor="let val of data.recordData; let i = index">
            <td *ngIf="i >= 4" [ngClass]="{ 'bg-light-blue': i < 6 }">
              <div *ngIf="i < 7" class="d-flex align-items-center justify-content-center bg-light-blue w-100px nh-h-60">
                <p>{{ val.value }}</p>
              </div>
              <div
                *ngIf="i >= 7"
                (click)="navigateToTransaction(data)"
                class="d-flex align-items-center flex-column justify-content-center w-100px nh-h-60 border"
              >
                <p
                  class="dot"
                  *ngIf="val?.value?.toLowerCase() != 'high priority'"
                  [ngClass]="{
                    'dot-red': val?.value?.toLowerCase() === 'active',
                    'dot-yellow': val?.value?.toLowerCase() === 'auto closed with comments',
                    'dot-blue': val?.value?.toLowerCase() === 'auto closed',
                    'dot-purple': val?.value?.toLowerCase() === 'temporary closed',
                    'dot-green': val?.value?.toLowerCase() === 'permanently closed'
                  }"
                ></p>
                <div *ngIf="val?.value?.toLowerCase() === 'high priority'" class="dot-circle-1">
                  <div class="dot-circle-2">
                    <div class="dot-circle-red"></div>
                  </div>
                </div>
                <p class="text-danger" *ngIf="val?.value?.toLowerCase() === 'high priority'">High Priority</p>
              </div>
            </td>
          </ng-container>
        </tr>
        <tr class="h-12">
          <td></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="column-alert-wrapper nh-mt-30">
    <div class="d-flex">
      <div class="alert-box bg-red"></div>
      <p>HIGH PRIORITY</p>
    </div>
    <div class="d-flex">
      <div class="alert-box bg-red"></div>
      <p>ACTIVE</p>
    </div>
    <div class="d-flex">
      <div class="alert-box bg-blue"></div>
      <p>AUTO-CLOSED</p>
    </div>
    <div class="d-flex">
      <div class="alert-box bg-yellow"></div>
      <p>AUTOCLOSED WITH COMMENTS</p>
    </div>
    <div class="d-flex">
      <div class="alert-box bg-purple"></div>
      <p>TEMPORARY CLOSED</p>
    </div>
    <div class="d-flex">
      <div class="alert-box bg-green"></div>
      <p>PERMANENTLY CLOSED</p>
    </div>
  </div>
</div>
