import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslatorService, NodeGsiFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';

@Component({
  selector: 'app-shades-of-potentiality-connections',
  templateUrl: './shades-of-potentiality-connections.component.html',
  styleUrls: ['./shades-of-potentiality-connections.component.scss'],
})
export class ShadesOfPotentialityConnectionsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  gsiData: any = {};
  @Output() closeSOP: EventEmitter<any> = new EventEmitter<any>();
  // @Output() sopDCDConnections: EventEmitter<any> = new EventEmitter<any>();

  @Input() currentGsi: any;
  @Input() wishGsi: any;
  @Input() configurationData: any;
  @Input() changeUnit: any;
  temp: any;
  close: boolean = false;
  wishGsiFinal: any;
  sourceEntity: any;
  currentSelectedEntity: any;
  currentSelectedAttribute: any;
  @Input() listOfSopDCDs: any = [];
  labels: any;
  selectedLayer: any;
  layerLabels: any = [
    { type: 'physical', label: { name: 'physical Layer', label: 'physical Layer' } },
    { type: 'information', label: { name: 'information Layer', label: 'information Layer' } },
    { type: 'triggerCES', label: { name: 'triggerCES Layer', label: 'triggerCES Layer' } },
  ];
  layerLabel: any;
  dcd: any = { sourceArray: [], formulaArray: [] };
  selectedWishCu: any;
  selectedWishLayer: any;
  tempGsiObj: any;
  expandLayer: boolean = true;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private changeUnitFacade: NodeChangeUnitFacadeService
  ) {
    this.detectCudetails();
    this.detectLanguageChange();
    this.getDCDStream();
  }

  ngOnInit(): void {
    if (this.wishGsi?.id != undefined) {
      /* istanbul ignore next */
      this.getCuDetailsForSOP(this.wishGsi?.id);
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  closePanel() {
    this.close = true;
    this.closeSOP.emit(this.close);
  }

  saveSOP() {
    let obj: any = {
      DCD: this.listOfSopDCDs,
      wishGsi: this.wishGsiFinal,
      currentCU: this.configurationData,
    };
    this.gsiFacade.sopConnectionData.next(obj);
    this.closePanel();
  }

  selectedEntityData(entity: any) {
    this.currentSelectedEntity = entity;
  }

  selectedAttributeData(attribute: any) {
    this.currentSelectedAttribute = attribute;
  }

  getCuDetailsForSOP(id: any) {
    this.gsiFacade.getCuDetailsForSOP(id);
  }

  detectCudetails() {
    this.gsiFacade.cuDetailsByGsiIdforSOP$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.wishGsiFinal = res;
      }
    });
  }

  deleteDcd(dcdIndex: number) {
    /* istanbul ignore next*/
    if (dcdIndex !== undefined && this.listOfSopDCDs && this.listOfSopDCDs.length > 0) {
      this.listOfSopDCDs.splice(dcdIndex, 1);
    }
  }

  sopDCDs(event: any) {
    //this.sopDCDConnections.emit(event);
    this.listOfSopDCDs = [];
    this.listOfSopDCDs = event;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  mapLabel(layer: any, gsi: any) {
    this.layerLabel = this.layerLabels?.find((label: any) => label.type == layer?.type).label;
    this.tempGsiObj = { appDetails: gsi };
    this.expandLayer = true;
  }

  getDCDStream() {
    this.changeUnitFacade.dcd$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res && res.formulaId) {
        this.expandLayer = false;
        if (res?.cuType === 'SOPSource') {
          this.dcd.sourceArray.push({ name: res?.formulaId });
          this.dcd.formula = res?.formulaId;
          this.dcd.formulaArray.push(res?.formulaId);
          this.dcd.sourceContextualId = res?.contextualIdWithId;
          this.dcd.sourceContextualName = res?.contextualId;
        } else if (res?.cuType === 'SOPTarget') {
          this.dcd.targetContextualId = res?.contextualIdWithId;
          this.dcd.targetContextualName = res?.contextualId;
          this.listOfSopDCDs.push(this.dcd);
        }
      }
    });
  }
}
