<div class="header">
  <div class="close-icon">
    <figure class="save-events" id="eventsPlusClick">
      <img class="cursor-pointer" src="../../../../../assets/img/tick.svg" alt="" (click)="saveHelperText()" />
    </figure>
    <figure class="cancel-img" id="eventsCloseClick">
      <img class="cursor-pointer" src="../../../../../assets/img/icon-cancel.svg" alt="" (click)="closeDialog()" />
    </figure>
  </div>

  <textarea placeholder="Enter Helper Text" [(ngModel)]="helperTextString" [maxlength]="32" rows="4" cols="35">
  </textarea>

  <label class="nopadding clrlbl"> Characters Remaining: {{ helperTextString?.length }}/32</label>
  <div class="Position">
    <span class="heading">Position</span>
    <div class="boxes">
      <div class="box" (click)="selectedPosition('bottom')" [ngClass]="{ 'box-active': helperTextPostion == 'bottom' }">
        <span class="text-box"></span>
        <span class="text-content bottom-text">Bottom Helper Text</span>
      </div>
      <div class="box" (click)="selectedPosition('top')" [ngClass]="{ 'box-active': helperTextPostion == 'top' }">
        <span class="text-content top-text">Top Helper Text</span>
        <span class="text-box"></span>
      </div>
    </div>
  </div>
  <div class="Choose" [ngClass]="helperTextString == '' || showHelperTextIcon ? 'events-none' : ''">
    <h5 class="heading">Font Colour</h5>
    <div class="color-boxes">
      <div
        class="color-box color1"
        (click)="selectedColor('#000000')"
        [ngClass]="{ active: helperTextColor == '#000000' }"
      ></div>
      <div
        class="color-box color2"
        (click)="selectedColor('#323232')"
        [ngClass]="{ active: helperTextColor == '#323232' }"
      ></div>
      <div
        class="color-box color3"
        (click)="selectedColor('#666666')"
        [ngClass]="{ active: helperTextColor == '#666666' }"
      ></div>
      <div
        class="color-box color4"
        (click)="selectedColor('#999999')"
        [ngClass]="{ active: helperTextColor == '#999999' }"
      ></div>
      <div
        class="color-box color5"
        (click)="selectedColor('#CB1FCB')"
        [ngClass]="{ active: helperTextColor == '#CB1FCB' }"
      ></div>
      <div
        class="color-box color6"
        (click)="selectedColor('#9900FF')"
        [ngClass]="{ active: helperTextColor == '#9900FF' }"
      ></div>
      <div
        class="color-box color7"
        (click)="selectedColor('#741B47')"
        [ngClass]="{ active: helperTextColor == '#741B47' }"
      ></div>
      <div
        class="color-box color8"
        (click)="selectedColor('#5B0F00')"
        [ngClass]="{ active: helperTextColor == '#5B0F00' }"
      ></div>
      <div
        class="color-box color9"
        (click)="selectedColor('#4A86E8')"
        [ngClass]="{ active: helperTextColor == '#4A86E8' }"
      ></div>
      <div
        class="color-box color10"
        (click)="selectedColor('#0000FF')"
        [ngClass]="{ active: helperTextColor == '#0000FF' }"
      ></div>
      <div
        class="color-box color11"
        (click)="selectedColor('#1C4587')"
        [ngClass]="{ active: helperTextColor == '#1C4587' }"
      ></div>
      <div
        class="color-box color12"
        (click)="selectedColor('#073763')"
        [ngClass]="{ active: helperTextColor == '#073763' }"
      ></div>
      <div
        class="color-box color13"
        (click)="selectedColor('#274E13')"
        [ngClass]="{ active: helperTextColor == '#274E13' }"
      ></div>
      <div
        class="color-box color14"
        (click)="selectedColor('#FFFFFF')"
        [ngClass]="{ active: helperTextColor == '#FFFFFF' }"
      ></div>
    </div>
    <h5 class="heading">Font Size</h5>
    <div class="font-boxes">
      <div class="font-box" (click)="selectedFont('10px')" [ngClass]="{ active: helperTextFont == '10px' }">
        10 px
      </div>
      <div class="font-box" (click)="selectedFont('12px')" [ngClass]="{ active: helperTextFont == '12px' }">
        12 px
      </div>
      <div class="font-box" (click)="selectedFont('14px')" [ngClass]="{ active: helperTextFont == '14px' }">
        14 px
      </div>
    </div>
  </div>
  <div>
    <mat-slide-toggle [(ngModel)]="showHelperTextIcon" value="true">Show HelperText Icon</mat-slide-toggle>
  </div>
  <div class="Choose">
    <h5 class="heading">Icon Colour</h5>
    <div class="color-boxes" [ngClass]="helperTextString == '' || !showHelperTextIcon ? 'events-none' : ''">
      <div
        class="color-box color1"
        (click)="iconSelectedColor('#000000')"
        [ngClass]="{ active: helperTextIconColor == '#000000' }"
      ></div>
      <div
        class="color-box color2"
        (click)="iconSelectedColor('#323232')"
        [ngClass]="{ active: helperTextIconColor == '#323232' }"
      ></div>
      <div
        class="color-box color3"
        (click)="iconSelectedColor('#666666')"
        [ngClass]="{ active: helperTextIconColor == '#666666' }"
      ></div>
      <div
        class="color-box color4"
        (click)="iconSelectedColor('#999999')"
        [ngClass]="{ active: helperTextIconColor == '#999999' }"
      ></div>
      <div
        class="color-box color5"
        (click)="iconSelectedColor('#CB1FCB')"
        [ngClass]="{ active: helperTextIconColor == '#CB1FCB' }"
      ></div>
      <div
        class="color-box color6"
        (click)="iconSelectedColor('#9900FF')"
        [ngClass]="{ active: helperTextIconColor == '#9900FF' }"
      ></div>
      <div
        class="color-box color7"
        (click)="iconSelectedColor('#741B47')"
        [ngClass]="{ active: helperTextIconColor == '#741B47' }"
      ></div>
      <div
        class="color-box color8"
        (click)="iconSelectedColor('#5B0F00')"
        [ngClass]="{ active: helperTextIconColor == '#5B0F00' }"
      ></div>
      <div
        class="color-box color9"
        (click)="iconSelectedColor('#4A86E8')"
        [ngClass]="{ active: helperTextIconColor == '#4A86E8' }"
      ></div>
      <div
        class="color-box color10"
        (click)="iconSelectedColor('#0000FF')"
        [ngClass]="{ active: helperTextIconColor == '#0000FF' }"
      ></div>
      <div
        class="color-box color11"
        (click)="iconSelectedColor('#1C4587')"
        [ngClass]="{ active: helperTextIconColor == '#1C4587' }"
      ></div>
      <div
        class="color-box color12"
        (click)="iconSelectedColor('#073763')"
        [ngClass]="{ active: helperTextIconColor == '#073763' }"
      ></div>
      <div
        class="color-box color13"
        (click)="iconSelectedColor('#274E13')"
        [ngClass]="{ active: helperTextIconColor == '#274E13' }"
      ></div>
      <div
        class="color-box color14"
        (click)="iconSelectedColor('#FFFFFF')"
        [ngClass]="{ active: helperTextIconColor == '#FFFFFF' }"
      ></div>
    </div>
  </div>
</div>
