<div class="viewroles-wrap">
  <div class="mapping-details w-100">
    <div class="rolemap-tables">
      <h3>{{ labels?.Connection }}</h3>
      <!-- if conflict is present -->
      <div class="table-responsive" *ngIf="conflicted === true">
        <table class="table" aria-describedby="">
          <!-- Heading and Rename button -->
          <!-- <thead>
            <tr>
              <th class="d-flex justify-content-between" id="">
                <div>View connection with conflict</div>
              </th>
              <th class="text-right" id="">
                <button mat-stroked-button class="cstm-btn" (click)="renameConnection()">
                  {{ labels?.Rename }}
                </button>
              </th>
            </tr>
          </thead> -->

          <tbody>
            <!-- List of all connection -->
            <tr *ngFor="let connection of connectionsConflicted; let i = index">
              <td class="conflict-active">
                <span
                  *ngIf="
                    connection?.isConflictResolved === false &&
                    connection?.conflicting === true &&
                    !connection.selectedNameconnection
                  "
                >
                  <!-- <mat-checkbox class="cstm-check" (change)="changeConnection($event, connection, i)"> -->
                  {{ connection?.sourceValue }}
                  <!-- </mat-checkbox> -->
                </span>
                <span *ngIf="connection.selectedNameconnection && !connection.isResolvedcon">
                  <input [(ngModel)]="connection.targetValue" />
                  <button (click)="saveName(i)" class="btn-rename">{{ labels?.Rename }}</button>
                </span>
                <span *ngIf="connection?.isConflictResolved === true && connection?.conflicting === true">
                  <mat-checkbox
                    class="cstm-check"
                    [disabled]="connection.isResolvedcon"
                    (change)="changeConnection($event, connection, i)"
                    *ngIf="connection.conflictAction && connection.isResolvedcon"
                  >
                    {{ connection?.targetValue }}
                  </mat-checkbox>
                </span>
                <!-- <span *ngIf="!connection.isResolvedcon && connection?.targetValue === ''">
                  <mat-icon>
                    warning_amber
                  </mat-icon>
                  {{ labels?.Resolve_Conflict }}
                </span> -->
                <span
                  *ngIf="!connection.isResolvedcon && connection?.targetValue === ''"
                  class="conflict-icon"
                  (click)="renameConnection(i)"
                >
                  <mat-icon> priority_high </mat-icon>Please change the name of the Connection
                </span>
              </td>

              <td>
                <span *ngIf="connection?.isConflictResolved && connection.isResolvedcon">
                  {{ connection.message }}
                </span>
                <!-- <span *ngIf="connection.selectedNameconnection">
                  <input type="text" [(ngModel)]="connection.targetValue" (keydown.enter)="saveName(i)" />
                </span> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- if conflict is not present -->
      <div *ngIf="conflicted === false">
        No Conflicted Connections
      </div>

      <!-- Next and Cancel button -->
      <div class="configure-btns btns-custom">
        <button class="btn btn-next btn-active" (click)="moveToNextTab()" [disabled]="checkConflicts()">
          {{ labels?.Next }}
        </button>
        <button class="btn btn-cancel" (click)="cancel()">
          {{ labels?.CANCEL }}
        </button>
      </div>
    </div>
  </div>
</div>
