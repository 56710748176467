import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JSEncrypt } from 'jsencrypt';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../cdui-base/authentication.service';
import { LoaderService } from '../../loader/loader.service';
import { AlertService } from '../../toaster/alert.service';
import { TranslatorService } from '../../translator/translator.service';
import { UsersFacadeService } from '../../users/users-facade.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  PasswordDetails: FormGroup;
  loginApiResponse: any;
  pwdError: boolean = false;
  labels: any;
  expiryTime: any;
  $encrypt: any;
  tenantName: any = '';
  password_info: any;
  ngUnsubscribe = new Subject();

  constructor(
    public translator: TranslatorService,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private loader: LoaderService,
    private userFacade: UsersFacadeService
  ) {}

  ngOnInit(): void {
    this.loginApiResponse = this.data.response.result;
    this.tenantName = this.loginApiResponse.login.tenantName;
    this.expiryTime = this.loginApiResponse.expiresInSec;
    this.detectLanguageChange();
    this.CreatePasswordForm();
    this.passwordPolicy();
  }

  detectLanguageChange() {
    this.translator.languageLables$.subscribe((res: any) => {
      this.labels = res;
    });
  }

  CreatePasswordForm() {
    this.PasswordDetails = new FormGroup({
      newPassword: new FormControl(''),
      newPasswordCnf: new FormControl(''),
    });
  }

  checkPasswordMatch() {
    /*istanbul ignore next */
    if (
      (this.PasswordDetails.value.newPassword != '' && this.PasswordDetails.value.newPasswordCnf == '') ||
      (this.PasswordDetails.value.newPassword == '' && this.PasswordDetails.value.newPasswordCnf != '') ||
      this.PasswordDetails.value.newPassword !== this.PasswordDetails.value.newPasswordCnf
    ) {
      this.pwdError = true;
    } else {
      this.pwdError = false;
    }
  }

  passwordReseting() {
    delete this.loginApiResponse.login.guestUserId;
    this.loginApiResponse.login.encryptedPassword = this.encrypt(this.PasswordDetails.value.newPassword);
    this.loader.show();
    this.authenticationService
      .updateTemperoryPassword(this.loginApiResponse.login, this.loginApiResponse.sessionId)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res && res.status == 200) {
          this.dialogRef.close({
            userName: this.loginApiResponse.login.userName,
            newPassword: this.PasswordDetails.value.newPassword,
            status: 'pass',
            clientId: this.loginApiResponse.login.clientId,
            tenantName: this.loginApiResponse.login.tenantName,
          });
        } else {
          this.alertService.showToaster(res.message, '', 'error');
          this.dialogRef.close({
            userName: this.loginApiResponse.login.userName,
            newPassword: this.PasswordDetails.value.newPassword,
            status: 'fail',
            clientId: this.loginApiResponse.login.clientId,
            tenantName: this.loginApiResponse.login.tenantName,
          });
        }
      });
  }

  closePopup() {
    this.dialogRef.close({
      userName: this.loginApiResponse.login.userName,
      newPassword: this.PasswordDetails.value.newPassword,
      status: 'fail',
      clientId: this.loginApiResponse.login.clientId,
      tenantName: this.loginApiResponse.login.tenantName,
    });
  }

  encrypt(password: string) {
    const publicKey =
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLU0whc9gpsHOER0wCrE/j13OASD1h/fL9lFXgrDT1HynMhxwIhCJPja7ww06wA3B0uZ8kxEfCavohvbmjMph3CoPRXWynWuvenDGB2ek1nbCg2aHJvmb3Zg1Coea0gmGkBorVWtqkwmLpuX/2Jq+W+UoWSMfePuyPC3kY4VwFpQIDAQAB';
    this.$encrypt = new JSEncrypt();
    const text = `${password}`.trim();
    /* istanbul ignore else */
    if (text.length < 117) {
      this.$encrypt.setPublicKey(publicKey);
      return this.$encrypt.encrypt(text);
    }
  }

  passwordPolicy() {
    this.userFacade.getPasswordPolicyByAdmin(this.tenantName);
    this.userFacade.tenantPasswordPolicy$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.password_info = res.result;
    });
  }
}
