import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { LabelComponent } from './label.component';

@NgModule({
  declarations: [LabelComponent],
  imports: [ControlsMaterialModule],
  exports: [LabelComponent],
})
export class LabelModule {
  static entry = {
    label: LabelComponent,
    correlationcoefficient: LabelComponent,
    cuclock: LabelComponent,
    distance: LabelComponent,
    probability: LabelComponent,
    quantification: LabelComponent,
    similaritycoefficient: LabelComponent,
    variability: LabelComponent,
    weightedaverage: LabelComponent,
    ratioanalysis: LabelComponent,
    simplelinearregression: LabelComponent,
    multilinearregression: LabelComponent,
    quantile: LabelComponent,
    meansquarederror: LabelComponent,
    quartile: LabelComponent,
    movingaverage: LabelComponent,
    percentile: LabelComponent,
  };
}
