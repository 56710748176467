<div (click)="close()" class="close">
  <mat-icon>close</mat-icon>
</div>
<div class="postexception">
  <div class="info">{{ data.promptDescription }}</div>
  <div class="actions">
    <div class="action" (click)="sendPostExceptionsData('RESUME')">
      <button *ngIf="resumeFlag">Resume</button>
    </div>
    <div class="action" (click)="sendPostExceptionsData('CONTINUE')">
      <button *ngIf="continueFlag">Continue</button>
    </div>
    <div class="action" (click)="sendPostExceptionsData('ABORT')">
      <button *ngIf="abortFlag">Abort</button>
    </div>
    <div class="action" (click)="sendPostExceptionsData('RETRY')">
      <button *ngIf="retryFlag">Retry</button>
    </div>
  </div>
</div>
