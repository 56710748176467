<div *ngIf="!isCompare">
  <div class="review-container">
    <div class="review-close">
      <button mat-icon-button aria-label="close dialog" mat-dialog-close (click)="changeStatus()">
        <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font"
          >close</mat-icon
        >
      </button>
    </div>
    <div class="review-header">
      <h1>{{ accordianResponse?.name }}</h1>
      <!-- <div class="review-header-arrows">
        <mat-icon id="arrow-left">keyboard_arrow_left</mat-icon>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div> -->
    </div>
    <div class="review-main">
      <div class="review-main-sec sec1">
        <div class="review-wishname">
          <mat-icon>library_books</mat-icon>
          <h3>{{ accordianResponse?.name }}</h3>
        </div>
        <p id="username">By {{ author?.name }}</p>
        <hr />
        <div class="review-comment">
          <h3>Comments</h3>
          <input type="text" name="" id="" placeholder="Write a comment" [(ngModel)]="textRequestNotes" />
        </div>
      </div>
      <div class="review-main-sec sec2 sec-head">
        <h3>Select Agent</h3>
        <hr />
        <mat-form-field appearance="fill" class="fillnone" id="selectReviewrs">
          <mat-label> {{ labels?.Select_Reviewer }} </mat-label>
          <mat-select multiple class="reviewer-multi" id="selectReviewrsSelection" [(ngModel)]="selectedReviewerList">
            <!-- <div *ngFor="let reviewer of reviewersList">
              <mat-option id="selectedReviewrs_{{ reviewer.name }}" [value]="reviewer.email">{{
                reviewer.name
              }}</mat-option>
            </div> -->
            <div
              class="reviewer-options"
              infinite-scroll
              [infiniteScrollDistance]="1"
              [infiniteScrollThrottle]="250"
              [scrollWindow]="false"
              (scrolled)="onScrollReviewers()"
            >
              <mat-form-field class="search-reviewer">
                <span matPrefix> </span>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="inputReviewer"
                  [placeholder]="labels?.Search_Reviewer"
                  (input)="inputReviewerObs.next(inputReviewer)"
                />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
              <mat-option
                *ngFor="let reviewer of reviewersList"
                id="selectedReviewrs_{{ reviewer?.name }}"
                [value]="reviewer?.email"
                >{{ reviewer?.name }}</mat-option
              >
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="review-main-sec sec-head" *ngIf="this.selectedReviewerList.length">
        <h3>Select Action</h3>
        <hr />
        <!-- <div class="review-action">
          <mat-icon>compare_arrows</mat-icon>
          <p>Compare</p>
        </div> -->
        <div class="review-action" *ngIf="ownerId && !pendingReviewExists">
          <mat-icon>send</mat-icon>
          <button mat-button class="btn ml-30" id="versionbtn-send" (click)="onClickTopActionBtns('send')">
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="Compare-Bet" *ngIf="isCompare">
  <div class="review-wrapp">
    <div class="diff-one">
      <div class="cu-form-container">
        <figure class="gsi-icon">
          <img [src]="'assets/img/gsi-dot-icon.svg' | CDNUrlRewritePipe" alt="" />
        </figure>
        <mat-label
          [ngClass]="{
            updatedCls: changeunit?.name?.status === 'CHANGED',
            unchangedCls: changeunit?.name?.status === 'UNCHANGED',
            deletedCls: changeunit?.name?.status === 'DELETED',
            addedCls: changeunit?.name?.status === 'ADDED'
          }"
          >{{ (changeunit?.name)[oldOrNew] }}</mat-label
        >
      </div>

      <mat-vertical-stepper
        [linear]="isLinear"
        #stepper
        class="w-100 custom-stepper"
        (selectionChange)="onChangeUnitStepChange($event)"
      >
        <mat-step [aria-labelledby]="!stepDisabled ? 'agentStep' : null" label="Agents">
          <div class="common-card agents-card">
            <div id="change-unit-agents">
              <div class="sub-heading">
                <div class="sub-heading-inner">
                  <figure class="shadow">
                    <img [src]="'../../../../../assets/img/icon-agent-step-normal.svg' | CDNUrlRewritePipe" alt="" />
                  </figure>
                  <div class="custom-radio-btns">
                    <div
                      [ngClass]="{
                        updatedCls: changeunit?.agents?.status === 'CHANGED',
                        unchangedCls: changeunit?.agents?.status === 'UNCHANGED',
                        deletedCls: changeunit?.agents?.status === 'DELETED',
                        addedCls: changeunit?.agents?.status === 'ADDED'
                      }"
                    >
                      <mat-radio-group name="agentsList" fxLayout="column" [value]="(changeunit?.agents)[oldOrNew]">
                        <mat-radio-button [disabled]="true" id="selectMemberHuman" name="agent" value="human"
                          >{{ labels?.Human }}
                        </mat-radio-button>
                        <mat-radio-button [disabled]="true" id="selectMemberMachine" name="agent" value="machine">{{
                          labels?.Machine
                        }}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="change-assign-rights-roles"
                class="assign-rights"
                *ngFor="let role of changeunit?.designTimeRights?.diff; let i = index"
              >
                <ul>
                  <li>
                    <div class="actions-item">
                      <button style="z-index: 1050;">
                        <span
                          [ngClass]="{
                            updatedCls: role?.rightHolderName?.status === 'CHANGED',
                            unchangedCls: role?.rightHolderName?.name?.status === 'UNCHANGED',
                            deletedCls: role?.rightHolderName?.status === 'DELETED',
                            addedCls: role?.rightHolderName?.status === 'ADDED'
                          }"
                          >{{ (role?.rightHolderName)[oldOrNew] }}</span
                        >
                      </button>
                    </div>
                    <div class="read-right-actions" *ngIf="(role?.rightHolderName)[oldOrNew]">
                      <div>
                        <label>{{ labels?.Solution_Design }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [disabled]="true"
                              [checked]="(role?.informationRight)[oldOrNew]"
                              id="readCUChecked_0"
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              [disabled]="true"
                              [checked]="(role?.decisionRight)[oldOrNew]"
                              id="writeCUChecked_0"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [disabled]="true"
                              [checked]="((changeunit?.txnTimeRights?.diff)[i]?.informationRight)[oldOrNew]"
                              id="readCUChecked_1"
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              [disabled]="true"
                              id="writeCUChecked_1"
                              [checked]="((changeunit?.txnTimeRights?.diff)[i]?.decisionRight)[oldOrNew]"
                              >{{ labels?.Write }}</mat-checkbox
                            >
                            <mat-checkbox
                              [disabled]="true"
                              id="executeCUChecked_1"
                              [checked]="((changeunit?.txnTimeRights?.diff)[i]?.executionRight)[oldOrNew]"
                              >{{ labels?.Execute }}</mat-checkbox
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-step>

        <mat-step
          *ngFor="let layerData of changeunit?.layers.diff"
          [aria-labelledby]="!stepDisabled ? 'physicalStep' : null"
          label="{{ layerData.type[oldOrNew] }}"
        >
          <div class="common-card physical-card">
            <ul>
              <li *ngFor="let entityData of layerData.participatingItems.diff">
                <div class="sub-heading">
                  <div class="sub-heading-inner">
                    <div class="actions-item" (click)="entityData.item.showAttributes = true">
                      <button style="z-index: 1050;">
                        <figure *ngIf="(entityData?.item?.name)[oldOrNew]" class="shadow">
                          <img [src]="'../../../../../assets/img/entity-red-icon.svg' | CDNUrlRewritePipe" alt="" />
                        </figure>
                        <h2
                          [ngClass]="{
                            updatedCls: entityData?.item?.name?.status === 'CHANGED',
                            unchangedCls: entityData?.item?.name?.status === 'UNCHANGED',
                            deletedCls: entityData?.item?.name?.status === 'DELETED',
                            addedCls: entityData?.item?.name?.status === 'ADDED'
                          }"
                        >
                          {{ (entityData?.item?.name)[oldOrNew] }}​​
                        </h2>
                      </button>
                    </div>
                  </div>
                </div>
                <ul class="sub-physical-content select-event-parent" *ngIf="entityData?.item?.showAttributes">
                  <li *ngFor="let attr of entityData?.item?.nslAttributes?.diff; let attributeIndex = index">
                    <div class="sub-heading">
                      <div
                        class="sub-heading-inner super-sub-headinner"
                        id="attrMainDiv_{{ attr?.name | stringReplace }}"
                      >
                        <div id="attrLabel_{{ (attr?.name)[oldOrNew] | stringReplace }}" class="actions-item">
                          <button id="currentAttributeId_{{ attr?.name | stringReplace }}">
                            <figure *ngIf="attr.name[oldOrNew]" class="shadow">
                              <img
                                [src]="'../../../../../assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe"
                                alt=""
                              />
                            </figure>

                            <h2
                              [ngClass]="{
                                updatedCls: attr?.name?.status === 'CHANGED',
                                unchangedCls: attr?.name?.status === 'UNCHANGED',
                                deletedCls: attr?.name?.status === 'DELETED',
                                addedCls: attr?.name?.status === 'ADDED'
                              }"
                              class="w-auto"
                              (click)="showValues()"
                            >
                              {{ (attr?.name)[oldOrNew] }} - {{attr?.attributeType?.type?.[oldOrNew]}}
                            </h2>
                          </button>
                          <br />
                          <h6 style="padding-left: 50px;" *ngIf="showDefaultValues && (attr?.defaultValue)[oldOrNew]">
                            Default Values
                          </h6>
                          <br />
                          <ul style="padding-left: 60px;" *ngIf="showDefaultValues && (attr?.defaultValue)[oldOrNew]">
                            <li>{{ (attr?.defaultValue)[oldOrNew] }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>
