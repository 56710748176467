import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memberShipCriteriaOperatorsFilter',
})
export class MemberShipCriteriaOperatorsFilterPipe implements PipeTransform {
  /**
   *
   * @param data
   * @author Ajit Dhayal
   * @returns updated operators list by discarding '=' operator
   */
  transform(data: any): any {
    if (!data || data?.length === 0) {
      return [];
    }
    return data?.filter((item: any) => item !== '=');
  }
}
