export enum HeaderCustomization {
  Regular_Basic = 'Regular_Basic',
  Regular_Curved = 'Regular_Curved',
  Regular_CurvedBlue = 'Regular_CurvedBlue',
  Regular_Menu = 'Regular_Menu',
  Regular_Doubled = 'Regular_Doubled',
  Regular_Shapped = 'Regular_Shapped',
  Regular_BrandedLogoBlue = 'Regular_BrandedLogoBlue',
  Extended_Basic = 'Extended_Basic',
  Extended_BasicBlue = 'Extended_BasicBlue',
  Extended_Curved = 'Extended_Curved',
  Extended_CurvedBlue = 'Extended_CurvedBlue',
  Extended_MenuLeft = 'Extended_MenuLeft',
  Extended_MenuLeftBlue = 'Extended_MenuLeftBlue',
  Extended_MenuRight = 'Extended_MenuRight',
  Extended_BrandedLogoBlue = 'Extended_BrandedLogoBlue',
  Extended_Black = 'Extended_Black',
  Extended_LogoCentricMenuLeft = 'Extended_LogoCentricMenuLeft',
  Extended_LogoCentricMenuLeftBlue = 'Extended_LogoCentricMenuLeftBlue',
  Extended_Transparent = 'Extended_Transparent',
  Extended_Doubled = 'Extended_Doubled',
  Extended_LongLogoDoubled = 'Extended_LongLogoDoubled',
  Extended_HeightedLongLogoDoubled = 'Extended_HeightedLongLogoDoubled',
  Extended_TransparentBrandedLogoMenuRight = 'Extended_TransparentBrandedLogoMenuRight',
  Extended_SubBottomPanel = 'Extended_SubBottomPanel',
  Extended_TripleLayerPanel = 'Extended_TripleLayerPanel',
  Extended_ERP_Template = 'Extended_ERP_Template',
  Extended_Defy_CTA_Buttons_Template = 'Extended_Defy_CTA_Buttons_Template',
  Extended_Top_Multiple_CTA_Buttons = 'Extended_Top_Multiple_CTA_Buttons',
  Extended_Bottom_Multiple_CTA_Buttons = 'Extended_Bottom_Multiple_CTA_Buttons',
  Extended_2_CTA_Buttons_ListPanel = 'Extended_2_CTA_Buttons_ListPanel',
  Extended_2_CTA_Buttons_bottom_dropdownPanel = 'Extended_2_CTA_Buttons_bottom_dropdownPanel',
  Extended_2_CTA_Buttons_top_dropdownPanel = 'Extended_2_CTA_Buttons_top_dropdownPanel',
  Extended_2_CTA_Buttons_SmallPanel = 'Extended_2_CTA_Buttons_SmallPanel',
  Extended_2_CTA_Buttons_LargePanel = 'Extended_2_CTA_Buttons_LargePanel',
  Extended_3_CTA_Buttons_SidePanel = 'Extended_3_CTA_Buttons_SidePanel',
  Extended_Single_CTA_Button_BottomPanel = 'Extended_Single_CTA_Button_BottomPanel',
  Extended_Single_CTA_Button_CenteredPanel = 'Extended_Single_CTA_Button_CenteredPanel',
  Extended_5_CTA_Buttons_SmallPanel = 'Extended_5_CTA_Buttons_SmallPanel',
}
