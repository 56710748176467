/**
 * @Author : Sunil Goyal
 */
import { Component, Inject, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Edge, Layout, Node, ClusterNode, DagreNodesOnlyLayout } from '@swimlane/ngx-graph';
import { curveStep } from 'd3-shape';
import * as shape from 'd3-shape';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { emptyObject, generateFormulaId, Entity, TranslatorService } from '@common-services';

export interface DialogData {
  entityList:any,
  transformationInfo:any,
}
@Component({
  selector: 'app-rcu-dcd',
  templateUrl: './rcu-dcd.component.html',
  styleUrls: ['./rcu-dcd.component.scss']
})
export class RcuDcdComponent implements OnInit {
  @ViewChild('actionMenu') menuTemplate: any;
  @ViewChild('infoTemplate') infoTemplate: any;
  private unsubscribe$: Subject<any> = new Subject<any>();
  zoomToFit$: Subject<boolean> = new Subject();
  public layout: Layout = new DagreNodesOnlyLayout();
  update$: Subject<any> = new Subject();
  dimension = {
    width: '32',
    height: '32',
  };
  public layoutSettings = {
    orientation: 'TB',
  };
  public nodes: Node[] = [];
  public nodeInfo: Node = {} as Node;
  public links: Edge[] = [];
  shape: any = curveStep;
  public curve: any = shape.curveBundle.beta(1);;
  labels: any;
  sourceDcdValue: any;
  RCUsFlag: boolean = false;
  ngUnsubscribe: any = new Subject();
  functionName: any = '';
  rcuInfo: any;
  functionsNamesOfSelectedRcu: any = [];
  DataTypeRcuList: any = [];
  reservedCUName: any = '';
  actionPopUp: MatDialogRef<any, any>;
  hoveredNode: any;
  currentHoveredNode: any;
  paramType="";
  constValue:any;
  enableSaveBtn = false;
  constructor(
    private changeUnitFacade: NodeChangeUnitFacadeService,
    public dialogRef: MatDialogRef<RcuDcdComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogRefData: DialogData,
    public dialog: MatDialog,
    private translator: TranslatorService
    ) { 
      this.detectLanguageChange();
      this.detectNonTemplateRCU();
      this.detectRCUFunctionName();
      this.detectRCUFunctionData();
  }
  onMouseEnter(node: any): void {
    this.currentHoveredNode = node;
  }

  onMouseleave() {
    this.currentHoveredNode = null;
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  ngOnInit(): void {
    this.sourceDcdValue = this.dialogRefData.entityList;
    /* istanbul ignore next */
    if(this.dialogRefData?.transformationInfo?.transformationInfo && 
      !emptyObject(this.dialogRefData?.transformationInfo?.transformationInfo)){
      const { nodes, links }= this.reverseResult(this.dialogRefData.transformationInfo);
      if(nodes == undefined && links == undefined){
        this.nodes = [];
        this.links = [];
      }
      this.nodes = nodes;
      this.links = links;
      this.updateGraph();
      this.fitGraph();
    }
    this.fetchNonTemplateRCU();
  }
  /**
   * It will opne Action menu
   * @param node: node of rcu data
   */
  openMenu(node?:any){
    /* istanbul ignore next */
    if(this.actionPopUp){
      this.actionPopUp?.close();
    }
    /* istanbul ignore next */
    this.nodeInfo = node ? JSON.parse(JSON.stringify(node)) : undefined;
    this.openActionMenu(this.menuTemplate);
  }
   /**
   * It generates the nodes and links to display graph
   */
  generateNodes(){
    const MIN_ID = 101;
    const MAX_ID = 999;
  
    const newNode: any = {
      id: `${this.randomXToY(MIN_ID, MAX_ID)}`,
      label: this.paramType,
      data: {
        dimension: { ...this.dimension },
        color: 'red',
        hover:false,
        show: false,
        isEdited: false,
        displayName: this.paramType=="RCU" ? this.sourceDcdValue.selectedAttribute.name : this.functionName,
      },
    };
    let changeDriverContextualName:any;
    let dynamicData: any;
    /* istanbul ignore next */
    switch(this.paramType){
      case 'RCU':
        dynamicData = {
          transformationInfo: {
            reservedCUName: this.reservedCUName,
            functionName: this.functionName,
          },
          displayName: this.functionName,
          rcuInfo:this.rcuInfo,
        };
        break;
      case 'Attr':
        changeDriverContextualName = generateFormulaId({
          layerType: this.sourceDcdValue.layerType,
          attributeName:this.sourceDcdValue.selectedAttribute.name,
          entityIndex :this.sourceDcdValue.entityIndex,
          entityName:this.sourceDcdValue.selectedEntity.name
        })
        dynamicData = {
          sourceAttribute: {
            changeDriverContextualName: changeDriverContextualName,
            changeDriverContextualId: changeDriverContextualName,
          },
          displayName: this.sourceDcdValue.selectedAttribute.name,
        };
        break;
      case 'Const':
        dynamicData = {
          constantChangeDriver: this.constValue,
          displayName: this.constValue,
        };
        break;
      default:
        break;
    }
    /* istanbul ignore next */
    if (this.nodeInfo?.data?.isEdited) {
      this.nodeInfo.data = { ...this.nodeInfo.data, ...dynamicData };
      const nodeIndex = this.nodes?.findIndex((node: any) => node?.id === this.nodeInfo.id);
      if (nodeIndex !== -1) {
        this.nodes[nodeIndex] = this.nodeInfo;
      }
    } else {
      newNode.data = { ...newNode.data, ...dynamicData };
      /* istanbul ignore next */
      if (this.nodeInfo) {
        this.links.push({
          source: this.nodeInfo?.id,
          target: newNode?.id,
        });
      }
      this.nodes.push(newNode);
    }
   
    /* istanbul ignore next */
    this.nodes?.forEach((node:any)=>{
      node.data.isEdited = false;
      node.data.show = false;
    })
    this.clearPopData();
    this.updateGraph();
    this.fitGraph();
    /* istanbul ignore next */
    this.actionPopUp?.close();
  }
   /**
   * It delete the current node from nodes list
   */
  deleteNode(nodeToDelete: any) {
    /* istanbul ignore next */
    this.nodes = this.nodes?.filter((node: any) => node?.id !== nodeToDelete?.id);
    /* istanbul ignore next */
    this.links = this.links?.filter((link: any) => link?.source !== nodeToDelete?.id && link?.target !== nodeToDelete?.id);
    this.updateGraph();
    this.fitGraph();
  }
  /**
   * It opens the information popup for Reserve function data
   */
  infoRCU(node:any){
    /* istanbul ignore next */
    this.rcuInfo =node?.data?.rcuInfo;
    this.openActionMenu(this.infoTemplate);
  }
  
  /**
 * It generates the random number
 */
  randomXToY(minVal:any,maxVal:any){
    var randVal = minVal+(Math.random()*(maxVal-minVal));
    return Math.round(randVal);
  }
  /**
 * these two update and fitgraph is used to update the data after change in nodes or links
 */
  updateGraph() {
    this.update$.next(true);
  }
  fitGraph() {
    this.zoomToFit$.next(true);
  }
   /**
 * It closes the active action pop up and clear the data
 */
  closeRefComp(){
    this.clearPopData();
    this.actionPopUp.close();
  }
     /**
 * It clears the data
 */
  clearPopData(){
    this.functionName = "";
    this.reservedCUName = "";
    this.paramType="";
    // this.nodeInfo = undefined;
    this.enableSaveBtn = false;
    this.constValue = undefined;
  }
/**
 * bewlow are api calls and observables for reserve cu, fucntion and funciton data for rcu dcd
 */
  rcuChange(reservedCUName: any) {
    this.fetchRCUFunctionName(reservedCUName);
  }
  rcuLayerChange(reservedCUName: any, functionName: any) {
    this.fetchRCUFunctionData(reservedCUName, functionName);
  }
  fetchNonTemplateRCU() {
    this.changeUnitFacade.getNonTemplateRCU();
  }
  fetchRCUFunctionName(reservedCUName: any) {
    this.changeUnitFacade.getRCUFunctionName(reservedCUName);
  }
  fetchRCUFunctionData(reservedCUName: any, functionName: any) {
    this.functionName = functionName;
    this.enableSaveBtn=true;
    this.changeUnitFacade.getRCUFunctionData(reservedCUName, functionName);
  }
  
  detectNonTemplateRCU() {
    this.changeUnitFacade.nonTemplateRCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.DataTypeRcuList = res?.result;
      }
    });
  }
  detectRCUFunctionName() {
    this.changeUnitFacade.rCUFunctionName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.functionName = '';
        this.rcuInfo = {};
        this.functionsNamesOfSelectedRcu = res?.result?.functionNames;
      }
    });
  }
  detectRCUFunctionData() {
    this.changeUnitFacade.rCUFunctionData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.rcuInfo = res?.result;
      }
    });
  }
  /**
 * It opens ng template pop up for actions
 */
  openActionMenu(templateRef: TemplateRef<any>) {
    const className ='node-popup';
    this.actionPopUp = this.dialog.open(templateRef, {
      width: 'auto',
      height: 'auto',
      hasBackdrop: false,
      panelClass: className,
      data: {
        node: this.nodeInfo,
      },
    });
  }
   /**
   * It closes all the popups in component
   */
  closeRcuComp(){
    this.actionPopUp?.close();
    this.dialogRef?.close();
  }
    /**
   * It saves RCU data in required format
   */
  saveRcuData(){
    this.dialogRef?.close(this.buildResult(this.nodes,this.links,this.nodes[0].id));
  }
  /**
   * It changes the layer for selection of entity
   */
  changeLayer(layerType: string) {
    if(this.paramType == 'Const'){
      this.enableSaveBtn = true;
    }
    /*istanbul ignore next*/
    this.sourceDcdValue.selectedChangeUnit?.layers?.forEach((layer: any) => {
      /*istanbul ignore next*/
      if (layerType === layer?.type) {
        this.sourceDcdValue.participatingItems = layer?.participatingItems;
      }
    });
  }
   /**
   * It changes the entity for selection of attribute
   */
  changeEntity(index: number): void {
    /*istanbul ignore else*/
    if (index >= 0) {
      /* istanbul ignore next */
      let entity: Entity = this.sourceDcdValue?.participatingItems[index]; //selects entity from the participatingItems
      this.sourceDcdValue.selectedAttribute = [];
      this.sourceDcdValue.selectedEntity = entity;
      this.sourceDcdValue.nslAttributes = [];
      /* istanbul ignore next*/
      this.sourceDcdValue.nslAttributes = entity?.nslAttributes;
    }
  }
   /**
   * It selects atribute as parameter for rcu function
   */
  changeAttribute(entity: any, attrIndex: number) {
    this.enableSaveBtn = true;
    this.setFalseAll(entity, attrIndex);
    /*istanbul ignore else*/
    if (!entity.nslAttributes[attrIndex].generalEntity) {
      this.sourceDcdValue.selectedAttribute = entity.nslAttributes[attrIndex];
    }
  }
  setFalseAll(entity: any, attrIndex: any) {
    entity.nslAttributes.forEach((ele: any, index: any) => {
      ele.isSelected = false;
      /*istanbul ignore next*/
      if (ele?.id === entity?.nslAttributes?.[attrIndex]?.id) {
        ele.isSelected = true;
      }
    });
  }
  /**
   * It shows edit panel when user edits existing data
   */
  showPanel(node:any){
    node.data.show = !node.data.show;
    node.data.isEdited = true;
    /*istanbul ignore next*/
    if(node?.data?.show && node?.data?.transformationInfo){
      this.reservedCUName = node.data.transformationInfo.reservedCUName;
      this.functionName = node.data.transformationInfo.functionName;
      this.paramType="RCU"
      this.openMenu(node);
    }else if(node?.data?.show && node?.data?.sourceAttribute){
      this.paramType="Attr";
      this.openMenu(node);
    }else if(node?.data?.show && node?.data?.constantChangeDriver){
      this.constValue = node?.data?.constantChangeDriver;
      this.paramType="Const";
      this.enableSaveBtn=true;
      this.openMenu(node);
    }
  }
  /**
   * It generates the req payload from nodes and links 
   * as payload is nested tree strucure, below method is recursive
   */
  buildResult(nodes: any, links: any, nodeId: any) {
    const currentNode = nodes.find((node:any) => node.id === nodeId);
    if (!currentNode) {
      return {
        transformationInfo: {
          reservedCUName: '',
          functionName: '',
          sourceChangeDriver: [],
        },
      };
    }
    const result: any = {};
    /*istanbul ignore else*/
    if(currentNode?.data?.transformationInfo){
      result.transformationInfo= {
        reservedCUName: currentNode.data.transformationInfo?.reservedCUName || '',
        functionName: currentNode.data.transformationInfo?.functionName || '',
        sourceChangeDriver: [],
      }
     }
    /*istanbul ignore else*/
    if (currentNode?.data?.sourceAttribute) {
      result.changeDriverContextualName = currentNode.data.sourceAttribute.changeDriverContextualName;
      result.changeDriverContextualId = currentNode.data.sourceAttribute.changeDriverContextualId;
    }
    /*istanbul ignore next*/
    if (currentNode?.data?.constantChangeDriver) {
      result.constantChangeDriver = currentNode?.data?.constantChangeDriver;
    }
    /*istanbul ignore next*/
    const outgoingLinks = links?.filter((link:any) => link?.source === nodeId);
    for (const link of outgoingLinks) {
      const childResult = this.buildResult(nodes, links, link.target);
      /*istanbul ignore else*/
      if(result.transformationInfo){
        result.transformationInfo.sourceChangeDriver.push(childResult);
      }
    }
    return result;
  }
  /**
   * It generates the nodes and links from transfromationInfo
   */
  reverseResult(result: any, parentId?: any): { nodes: any[]; links: any[] } {
    const nodes: any[] = [];
    const links: any[] = [];
    const currentNodeId = `${Math.floor(Math.random() * 10000)}`; // Adjust the random ID generation as needed
    const node: any = {
      id: currentNodeId,
      label: this.getLabel(result), // Adjust the label based on the result structure
      data: {
        dimension: {}, // Add appropriate default or extracted dimension data
        color: 'red',
        hover: false,
        show: false,
        isEdited: false,
        displayName: '',
      },
    };
    if (result?.transformationInfo) {
      node.data.transformationInfo = {
        reservedCUName: result.transformationInfo.reservedCUName,
        functionName: result.transformationInfo.functionName,
      };
      node.data.displayName = result.transformationInfo.functionName;
    }
    if (result?.changeDriverContextualName && result?.changeDriverContextualId) {
      node.data.sourceAttribute = {
        changeDriverContextualName: result.changeDriverContextualName,
        changeDriverContextualId: result.changeDriverContextualId,
      };
      const nameArray = result.changeDriverContextualName.split('.');
      const name = nameArray[nameArray.length-1];
      node.data.displayName = name;
    }
    if(result?.constantChangeDriver){
      node.data.constantChangeDriver = result?.constantChangeDriver;
      node.data.displayName =result?.constantChangeDriver;
    }
    nodes.push(node);
    if (parentId) {
      links.push({
        source: parentId,
        target: currentNodeId,
      });
    }
    for (const childResult of result.transformationInfo?.sourceChangeDriver || []) {
      const { nodes: childNodes, links: childLinks } = this.reverseResult(childResult, currentNodeId);
      nodes.push(...childNodes);
      links.push(...childLinks);
    }
    return { nodes, links };
  }
  /**
   * Generates the result for display label
   * @param result 
   * @returns string
   */
  getLabel(result:any){
    return result.transformationInfo ? 'RCU' : result.constantChangeDriver? 'Const' : 'Att'
  }
}
