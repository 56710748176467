<ul>
  <li class="li-top-0">
    <div class="li-flex att-li">
      <ng-template #attrName>
        <p
          [matTooltipPosition]="'above'"
          matTooltipClass="tool-tip-custom"
          matTooltip="{{ attr?.name }}"
          class="attlist-widt"
          id="dcd_label_{{ nestedAttrName | stringReplace }}"
        >
          <span class="att-dcd-name">{{ attr?.name | dcd }}</span>

          <span (click)="addAttributetoDcd()" class="hoverplusimg-icon">
            <img
              [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
              alt=""
              id="dcd_plus_{{ nestedAttrName | stringReplace }}"
              class="img-h25 hover-img hoverplusimg"
            />
          </span>
        </p>
      </ng-template>
      <mat-accordion class="w-100" *ngIf="attr?.generalEntity; else attrName">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="active-p">
              <p
                [matTooltipPosition]="'above'"
                matTooltipClass="tool-tip-custom"
                matTooltip="{{ attr?.name }}"
                id="dcd_label_{{ nestedAttrName | stringReplace }}"
                class="ent-widt"
              >
                {{ attr?.name | dcd }}
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="attribute-list attribute-list-li">
            <app-auto-id-nested-entity
              *ngFor="let subEntity of attr?.generalEntity?.nslAttributes"
              [attr]="subEntity"
              [attrName]="nestedAttrName + '.' + subEntity.name"
              [entity]="entity"
              (attributeEmit)="nestedAttributeEmit($event)"
            >
            </app-auto-id-nested-entity>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </li>
</ul>
