import { NgModule } from '@angular/core';
import { TypeaheadComponent } from './typeahead.component';
import { ControlsMaterialModule } from '../material.module';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  declarations: [TypeaheadComponent],
  imports: [ControlsMaterialModule, TooltipModule],
  exports: [TypeaheadComponent],
})
export class TypeaheadModule {
  static entry = {
    typeahead: TypeaheadComponent,
  };
}
