<ng-template #actionButton let-data="data">
  <span class="btn-close">
    <i class="bi bi-x" *ngIf="childGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
    <button
      #gsiAction
      appConfigureAttribute
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isButton]="true"
      [_htmlValue]="cartTitle"
      [index]="data?.position"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitInnerHtml)="changeNameOftheGsi($event)"
      (drop)="dropActionBtn($event)"
      (dragover)="allowDrop($event)"
      [class]="data?.class"
      [pTooltip]="childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'Button'"
      [id]="'action-' + data?.position"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      [_style]="style"
      [_changeShadowStyle]="boxShadowData"
    >
      {{childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'CTA'}}
    </button>
  </span>
</ng-template>
<div class="cart-drop-wrapper" *ngIf="templateSelectionScreen">
  <div class="cartdrop-header-wrapper">
    <div class="cart-drop-header d-flex justify-content-between align-items-center">
      <h3>Choose Template</h3>
      <button (click)="proceedToMapping()">
        Proceed
      </button>
    </div>
  </div>
  <div class="preview-template-wrapper">
    <div
      class="preview-template-1"
      (click)="templateSelection('1')"
      [ngClass]="{ 'preview-template-active': templateNumber === '1' }"
    >
      <img src="../../../../../assets/img/general-entity/templates/Add-to-cart/Addtocart-1.png" />
    </div>
  
    <div
      class="preview-template-3"
      (click)="templateSelection('3')"
      [ngClass]="{ 'preview-template-active': templateNumber === '3' }"
    >
      <img src="../../../../../assets/img/general-entity/templates/Add-to-cart/Addtocart-3.png" />
    </div>
    <div
      class="preview-template-4"
      (click)="templateSelection('4')"
      [ngClass]="{ 'preview-template-active': templateNumber === '4' }"
    >
      <img src="../../../../../assets/img/general-entity/templates/Add-to-cart/Addtocart-4.png" />
    </div>
    <div
      class="preview-template-5"
      (click)="templateSelection('5')"
      [ngClass]="{ 'preview-template-active': templateNumber === '5' }"
    >
      <img src="../../../../../assets/img/general-entity/templates/Add-to-cart/Addtocart-2.png" />
    </div>
    
  </div>
</div>
<div class="entityTable-configuration" *ngIf="!templateSelectionScreen">
  <div class="nhCard">
    <div class="nhCard_header">
      <div class="nhCard_header_inner d-flex align-items-center justify-content-between">
        <div class="nhCard_header_left d-flex align-items-center">
          <button class="nh-unsetAll cursor-pointer nhCard_header_back">
            <em class="nh-icon nh-icon-chevron-left" (click)="navigateToCartSelection()"></em>
          </button>
          <h4 class="nh-fs-18">Configure Cart</h4>
        </div>
        <div class="nhCard_header_right d-flex align-items-center">
          <ul class="nhCard_header_right_list">
            <li class="eth-close" id="cancel_1" (click)="navigateToLibrary()"><i class="bi bi-x-lg"></i></li>
            <li class="eth-check" id="next_1" (click)="saveCartConfig()">
              <i class="bi bi-check2"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nhCard_maincontent">
      <div class="nhrow">
        <!------left--------->
        <div class="nhrow_col nhrow_left">
          <div class="et-config-content-left">
            <mat-tab-group mat-align-tabs="start" class="form-tabs form-tabs-opt1">
              <mat-tab label="GSI">
                <mat-accordion>
                  <span class="search-filter">
                    <input type="text" placeholder="Search..." [(ngModel)]="search" (keyup)="searchPaginatedGsi()" />
                    <em class="nh-icon nh-icon-search-line"></em>
                  </span>
                  <ng-container *ngFor="let gsi of gsiList; let i = index">
                    <mat-expansion-panel
                      *ngIf="!gsi.isSelected"
                      draggable="true"
                      (dragstart)="drag($event, gsi, false)"
                      [id]="gsi.name + '-' + i"
                      pTooltip="{{ gsi.name }}"
                      tooltipPosition="right"
                      class="disabled-panel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p class="text text-truncate">{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
                  </ng-container>
                  <app-paginator
                    [pageIndex]="pageno - 1"
                    [pageSize]="10"
                    [totalRecords]="totalResults"
                    (onPageChange)="onPageChange($event)"
                  >
                  </app-paginator>
                </mat-accordion>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <!------------------->

        <!------middle------->
        <div class="nhrow_col nhrow_middle">
          <div class="entity-config-info">
            <div class="entity-expand-wrap">
              <div class="entity-expand">
                <ng-container>
                  <div class="entitycards-scroll">
                    <div class="cart-configure">
                      <!-- cart -- 1 -->
                      <ng-container *ngIf="templateNumber === '1'">
                        <div
                          class="cart-card"
                          #cardStyles
                          CardColorConfig
                          [style]="style"
                          [_selectedDirectiveId]="selectedDirectiveId"
                          [_changeShadowStyle]="boxShadowData"
                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                        >
                          <div class="total-item">
                            {{ labels?.Total_Item_Added }}
                          </div>
                          <div class="cart-main-content">
                            <div class="cart-image" id="position-0"></div>
                            <div class="cart-item-titles">
                              <div class="cart-item-name" id="position-1"></div>
                              <div class="cart-item-props">
                                <div class="cart-item-prop" id="position-2"></div>
                                <div class="cart-item-prop" id="position-3"></div>
                                <div class="cart-item-prop" id="position-4"></div>
                              </div>
                            </div>
                            <div class="cart-item-button">
                              <div
                                class="btn btn-outline-secondary bi bi-dash"
                                #quantityButton1
                                appConfigureAttribute
                                [isButton]="true"
                                [_style]="style"
                                [_selectedDirectiveId]="selectedDirectiveId"
                                [_changeShadowStyle]="boxShadowData"
                                (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                              ></div>
                              <input type="number" min="0" oninput="validity.valid||(value='');" disabled />
                              <div
                                class="btn btn-outline-secondary bi bi-plus"
                                #quantityButton2
                                appConfigureAttribute
                                [isButton]="true"
                                [_style]="style"
                                [_selectedDirectiveId]="selectedDirectiveId"
                                [_changeShadowStyle]="boxShadowData"
                                (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                              ></div>
                            </div>
                          </div>
                          <div class="cart-buttons" disabled>
                            <ng-template
                              [ngTemplateOutlet]="actionButton"
                              [ngTemplateOutletContext]="{ data: { position: 0, class: 'btn btn-dark text-truncate' } }"
                            ></ng-template>
                            <ng-template
                              [ngTemplateOutlet]="actionButton"
                              [ngTemplateOutletContext]="{ data: { position: 1, class: 'btn btn-dark text-truncate' } }"
                            ></ng-template>
                            <ng-template
                              [ngTemplateOutlet]="actionButton"
                              [ngTemplateOutletContext]="{ data: { position: 2, class: 'btn btn-dark text-truncate' } }"
                            ></ng-template>
                            <ng-template
                              [ngTemplateOutlet]="actionButton"
                              [ngTemplateOutletContext]="{ data: { position: 3, class: 'btn btn-dark text-truncate' } }"
                            ></ng-template>
                            <ng-template
                              [ngTemplateOutlet]="actionButton"
                              [ngTemplateOutletContext]="{ data: { position: 4, class: 'btn btn-dark text-truncate' } }"
                            ></ng-template>
                          </div>
                        </div>
                      </ng-container>

                      <!-- cart 3 -->
                      <ng-container *ngIf="templateNumber === '3'">
                        <div
                          class="cart-tmplt-v3-wrfm"
                          #cardStyles
                          CardColorConfig
                          [style]="style"
                          [_selectedDirectiveId]="selectedDirectiveId"
                          [_changeShadowStyle]="boxShadowData"
                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                        >
                          <!-- <div class="cart-tmplt-v3-wrfm-heading">
                            <ul>
                              <li>
                                Shipping Address
                              </li>
                              <li>
                                Text CTA
                              </li>
                            </ul>
                          </div> -->

                          <!-- <div class="cart-tmplt-v3-wrfm-header">
                            <ul>
                              <li class="cart-tmplt-v3-wrfm-logo">
                                <img src="" alt="" />
                              </li>
                              <li class="cart-tmplt-v3-wrfm-title">
                                Member
                              </li>
                              <li class="cart-tmplt-v3-wrfm-btn">
                                Text CTA
                              </li>
                              <li class="cart-tmplt-v3-wrfm-para">
                                Member
                              </li>
                            </ul>
                          </div> -->

                          <div class="cart-tmplt-v3-wrfm-body">
                            <div class="cart-tmplt-v3-wrfm-imgs">
                              <ul>
                                <li class="cart-tmplt-v3-wrfm-logo" id="v3-logo">
                                  <img src="" alt="" />
                                </li>
                              </ul>
                            </div>
                            <div class="cart-tmplt-v3-wrfm-right">
                              <div class="cart-tmplt-v3-wrfm-content">
                                <ul>
                                  <li class="cart-tmplt-v3-wrfm-list cart-tmplt-full" id="v3-member1"></li>
                                  <li class="cart-tmplt-v3-wrfm-list" id="v3-member2"></li>
                                  <li class="cart-tmplt-v3-wrfm-list cart-tmplt-bold-text" id="v3-member3"></li>
                                </ul>
                              </div>
                              <div class="cart-tmplt-v3-wrfm-controls">
                                <ul>
                                  <li id="v3-button">
                                    <div class="input-group mb-3">
                                      <div class="input-group-prepend">
                                        <button
                                          class="btn btn-outline-secondary bi bi-dash"
                                          type="button"
                                          #quantityButton1
                                          appConfigureAttribute
                                          [_style]="style"
                                          [isButton]="true"
                                          [_selectedDirectiveId]="selectedDirectiveId"
                                          [_changeShadowStyle]="boxShadowData"
                                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                                        ></button>
                                      </div>
                                      <input type="number" class="form-control" placeholder="" value="" disabled />
                                      <div class="input-group-append">
                                        <button
                                          class="btn btn-outline-secondary bi bi-plus"
                                          type="button"
                                          #quantityButton2
                                          appConfigureAttribute
                                          [isButton]="true"
                                          [_style]="style"
                                          [_selectedDirectiveId]="selectedDirectiveId"
                                          [_changeShadowStyle]="boxShadowData"
                                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                                        ></button>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="cart-tmplt-v3-wrfm-bottom cart-tmplt-v3Action">
                            <ul>
                              <li class="cart-tmplt-v3-wrfm-list cart-tmplt-full-3" id="v3-class"></li>
                              <li class="cart-tmplt-v3-wrfm-list-3" id="v3-member4"></li>
                            </ul>
                            <ul>
                              <li class="" id="v3-action-buttons">
                                <ng-template
                                  [ngTemplateOutlet]="actionButton"
                                  [ngTemplateOutletContext]="{
                                    data: {
                                      position: 0,
                                      class: 'btn text-truncate cart-tmplt-v3-wrfm-list cart-tmplt-bold-btn-3'
                                    }
                                  }"
                                ></ng-template>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-container>

                      <!-- cart 4 -->
                      <ng-container *ngIf="templateNumber === '4'">
                        <div
                          class="cart-tmplt-v4-wrfm"
                          #cardStyles
                          CardColorConfig
                          [style]="style"
                          [_selectedDirectiveId]="selectedDirectiveId"
                          [_changeShadowStyle]="boxShadowData"
                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                        >
                          <h3 class="totalItem">{{ labels?.Total_Item_Added}}</h3>
                          <div class="cart-tmplt-v4-wrfm-mainBidy">
                            <div class="cart-tmplt-v4-itemAdd">
                              <div class="cart-tmplt-v4-body">
                                <span class="close-cart"> </span>
                                <div class="cart-tmplt-v4-img">
                                  <ul>
                                    <li class="cart-tmplt-v4-logo" id="v4-logo">
                                      <img src="" alt="" />
                                    </li>
                                  </ul>
                                </div>
                                <div class="cart-tmplt-v4-content-body">
                                  <div class="cart-tmplt-v4-content">
                                    <ul>
                                      <li class="cart-title" id="v4-cart-title"></li>
                                    </ul>
                                    <div class="cart-tmplt-v4-content-control">
                                      <div class="membercontent">
                                        <ul>
                                          <li class="memberTitle" id="v4-memberTitle1"></li>
                                          <li class="memberTitle" id="v4-memberTitle2"></li>
                                        </ul>
                                      </div>
                                      <div class="cart-tmplt-v4-wrfm-controls">
                                        <ul>
                                          <li id="v4-button-controls">
                                            <div class="input-group">
                                              <div class="input-group-prepend">
                                                <button
                                                  class="btn btn-outline-secondary bi bi-dash"
                                                  type="button"
                                                  #quantityButton1
                                                  appConfigureAttribute
                                                  [isButton]="true"
                                                  [_style]="style"
                                                  [_selectedDirectiveId]="selectedDirectiveId"
                                                  [_changeShadowStyle]="boxShadowData"
                                                  (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                                                ></button>
                                              </div>
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder=""
                                                aria-label=" button addon"
                                                aria-describedby="button-addon1"
                                                disabled
                                              />
                                              <div class="input-group-append">
                                                <button
                                                  class="btn btn-outline-secondary bi bi-plus"
                                                  type="button"
                                                  #quantityButton2
                                                  appConfigureAttribute
                                                  [isButton]="true"
                                                  [_style]="style"
                                                  [_selectedDirectiveId]="selectedDirectiveId"
                                                  [_changeShadowStyle]="boxShadowData"
                                                  (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                                                ></button>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="memberBold">
                                        <ul>
                                          <li class="memberTitlebold" id="v4-memberTitlebold1"></li>
                                          <li class="memberTitlebold" id="v4-memberTitlebold2"></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="cart-tmplt-v4-ShipAdd">
                              <div class="cart-tmplt-v4-ShipAdd-body">
                                <h4 class="shipTitle">Shipping Address</h4>
                                <div class="shipAction">
                                  <ul>
                                    <li class="NameTitle">Class</li>
                                    <li class="actionCTA">CTA</li>
                                  </ul>
                                  <ul>
                                    <li class="fullAddress">Member</li>
                                  </ul>
                                </div>
                                <div class="shipAction">
                                  <ul>
                                    <li class="NameTitle">Class</li>
                                    <li class="actionCTA">CTA</li>
                                  </ul>
                                  <ul>
                                    <li class="fullAddress">Member</li>
                                  </ul>
                                </div>
                                <div class="shipAction">
                                  <ul>
                                    <li class="NameTitle">Class</li>
                                    <li class="actionCTA">CTA</li>
                                  </ul>
                                  <ul>
                                    <li class="fullAddress">Member</li>
                                  </ul>
                                </div>
                              </div>
                            </div> -->
                          </div>
                          <div class="cart-tmplt-v3-wrfm-bottom cart-tmplt-v4Action">
                            <ul>
                              <li class="cart-tmplt-v3-wrfm-list cart-tmplt-full-4" id="v4-class"></li>
                              <li class="cart-tmplt-v3-wrfm-list-4" id="v4-Member-list-4"></li>
                            </ul>
                            <ul>
                              <li class="" id="v4-actionButtons">
                                <ng-template
                                  [ngTemplateOutlet]="actionButton"
                                  [ngTemplateOutletContext]="{
                                    data: {
                                      position: 0,
                                      class: 'btn text-truncate cart-tmplt-v3-wrfm-list cart-tmplt-bold-btn-4'
                                    }
                                  }"
                                ></ng-template>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </ng-container>

                      <!-- cart 5 -->
                      <ng-container *ngIf="templateNumber === '5'">
                        <div
                          class="cart-tmplt-v5"
                          #cardStyles
                          CardColorConfig
                          [style]="style"
                          [_selectedDirectiveId]="selectedDirectiveId"
                          [_changeShadowStyle]="boxShadowData"
                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                        >
                          <!-- <div class="cart-tmplt-v5-header">
                            <ul>
                              <li class="cart-tmplt-v5-logo">
                                <img src="" alt="" />
                              </li>
                              <li class="cart-tmplt-v5-title">
                                Deliver to Govind Jarapala, Door no 305,Gachi…
                              </li>
                              <li class="cart-tmplt-v5-btn">
                                Edit Address
                              </li>
                            </ul>
                          </div> -->

                          <div class="cart-tmplt-v5-body">
                            <div class="cart-tmplt-v5-imgs">
                              <ul>
                                <li class="cart-tmplt-v5-logo" id="v5-img-logo">
                                  <img src="" alt="" />
                                </li>
                              </ul>
                            </div>
                            <div class="cart-tmplt-v5-right">
                              <div class="cart-tmplt-v5-content">
                                <ul>
                                  <li class="cart-tmplt-v5-list cart-tmplt-full" id="v5-attribute1">
                                    Nestle Milkmaid Condensed Milk
                                  </li>
                                  <li class="cart-tmplt-v5-list cart-tmplt-bold-text" id="v5-attribute2">
                                    ₹125
                                  </li>
                                  <li class="cart-tmplt-v5-list txt-striped" id="v5-attribute3">
                                    ₹143
                                  </li>
                                </ul>
                              </div>
                              <div class="cart-tmplt-v5-controls">
                                <ul>
                                  <li id="v5-buttons">
                                    <div class="input-group mb-3">
                                      <div class="input-group-prepend">
                                        <button
                                          class="btn btn-outline-secondary bi bi-dash"
                                          type="button"
                                          #quantityButton1
                                          appConfigureAttribute
                                          [isButton]="true"
                                          [_style]="style"
                                          [_selectedDirectiveId]="selectedDirectiveId"
                                          [_changeShadowStyle]="boxShadowData"
                                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                                        ></button>
                                      </div>
                                      <input type="number" class="form-control" placeholder="" value="01" />
                                      <div class="input-group-append">
                                        <button
                                          class="btn btn-outline-secondary bi bi-plus"
                                          type="button"
                                          #quantityButton2
                                          appConfigureAttribute
                                          [isButton]="true"
                                          [_style]="style"
                                          [_selectedDirectiveId]="selectedDirectiveId"
                                          [_changeShadowStyle]="boxShadowData"
                                          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                                        ></button>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="cart-tmplt-v5-bottom cart-tmplt-v5Action">
                            <div class="cart-tmplt-v5-content-bottom">
                              <ul>
                                <li class="cart-tmplt-v5-list cart-tmplt-total-price" id="v5-total-amount">
                                  Total Amount
                                </li>
                                <li class="cart-tmplt-v5-list cart-tmplt-price-display" id="v5-amount">
                                  ₹ 375.00
                                </li>
                              </ul>
                            </div>
                            <div>
                              <ul>
                                <li class="" id="v5-actionButtons">
                                  <ng-template
                                    [ngTemplateOutlet]="actionButton"
                                    [ngTemplateOutletContext]="{
                                      data: {
                                        position: 0,
                                        class: 'btn text-truncate cart-tmplt-v4-wrfm-list cart-tmplt-bold-btn-5'
                                      }
                                    }"
                                  ></ng-template>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!------------------->

        <!-----right--------->
        <div class="nhrow_col nhrow_right">
          <div class="et-config-content-right">
            <mat-tab-group class="form-tabs form-tabs-opt1">
              <mat-tab label="Design">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Color
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="color-picker1">
                      <div class="appearance-sec">
                        <div class="color-customisation">
                          <ul>
                            <li (click)="toggleColorPicker()">
                              <p>Fill Color</p>
                              <p class="box-color">
                                <span class="color-fill" [ngStyle]="{ 'background-color': selectedColor }"></span>
                                <i class="bi bi-eyedropper"></i>
                              </p>
                            </li>
                            <span
                              *ngIf="colorPickerToggler"
                              [cpPositionRelativeToArrow]="true"
                              [cpDialogDisplay]="'inline'"
                              (colorPickerChange)="changeColor($event)"
                              [(colorPicker)]="colorModel"
                              [cpOKButton]="true"
                              (colorPickerSelect)="closeColorPicker($event)"
                              [cpToggle]="true"
                              [cpOKButtonText]="'Done'"
                            >
                            </span>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Text
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="test">
                      <p class="title text-color">Font Family</p>
                      <div class="entity-custom-select">
                        <mat-form-field appearance="fill" floatLabel="never">
                          <mat-select
                            (ngModelChange)="changeFont($event)"
                            [(ngModel)]="_fontIndex"
                            placeholder="select font"
                            panelClass="entity-custom-select-options"
                          >
                            <mat-option *ngFor="let font of fonts; let i = index" [value]="i">{{
                              font.name
                            }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <p class="title text-color">Font Style</p>
                      <div class="entity-custom-select"></div>
                      <div class="font-style">
                        <ul>
                          <li
                            [ngStyle]="{
                              border: isBold ? '1px solid black' : '1px solid rgba(217, 217, 217, 0.5)'
                            }"
                            (click)="changeBold()"
                          >
                            <span>B</span>
                          </li>
                          <li
                            [ngStyle]="{
                              border: isItalic ? '1px solid black' : '1px solid rgba(217, 217, 217, 0.5)'
                            }"
                            (click)="changeItalic()"
                          >
                            <span>i</span>
                          </li>
                          <!-- <li (click)="checkIsUnderLine()">
                                            <span>U</span>
                                          </li> -->
                        </ul>
                      </div>
                      <div class="alignment">
                        <p class="title text-color">Alignment</p>
                        <ul>
                          <li
                            *ngFor="let align of alignmentOptions"
                            (click)="checkAlign(align)"
                            [ngClass]="{ active: alignment == align }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                      <div class="font-section">
                        <p class="title text-color">Font Size</p>
                        <div class="font-input">
                          <button class="font-btn">
                            <i class="bi bi-dash" (click)="decreaseFontSize()"></i>
                          </button>
                          <span>{{ fontSize }}</span>
                          <button class="font-btn">
                            <i class="bi bi-plus" (click)="increaseFontSize()"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Border
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="appearance-sec">
                      <div>
                        <div class="color-customisation">
                          <ul>
                            <li class="nh-mb-12 nh-mt-8">
                              <mat-slide-toggle
                                class="er-filter-toggle er-filter-toggle-a"
                                id="border-radius-toggle"
                                [(ngModel)]="borderRadiusToggle"
                              >
                                <span>
                                  <p class="nh-fs-14">Border</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div *ngIf="borderRadiusToggle === true">
                        <div class="color-customisation">
                          <ul>
                            <li>
                              <p>Border Color</p>
                              <p class="box-color">
                                <span class="color-fill" [ngStyle]="{ 'background-color': selectedBorderColor }"></span>
                                <i class="bi bi-eyedropper cursor-pointer" (click)="toggleBorderColorPicker()"></i>
                              </p>
                            </li>
                            <span
                              class="color-fill"
                              *ngIf="borderColorPickerToggler"
                              [cpPositionRelativeToArrow]="true"
                              [cpDialogDisplay]="'inline'"
                              (colorPickerChange)="changeBorderColor($event)"
                              [(colorPicker)]="borderColorModel"
                              [cpOKButton]="true"
                              (colorPickerSelect)="closeBorderColorPicker($event)"
                              [cpToggle]="true"
                              [cpOKButtonText]="'Done'"
                            ></span>
                          </ul>
                        </div>
                        <div class="pagination-sec d-flex nh-column-gap-16 nh-mb-16 nh-mt-16">
                          <div class="font-section w-100">
                            <p class="title text-color">Border Size</p>
                            <div class="font-input">
                              <button class="font-btn">
                                <i class="bi bi-dash" (click)="decreaseBorderProperty('borderSize')"></i>
                              </button>
                              <span>{{ borderSize }}</span>
                              <button class="font-btn">
                                <i class="bi bi-plus" (click)="increaseBorderProperty('borderSize')"></i>
                              </button>
                            </div>
                          </div>
                          <div class="font-section w-100">
                            <p class="title text-color">Border Radius</p>
                            <div class="font-input">
                              <button class="font-btn">
                                <i class="bi bi-dash" (click)="decreaseBorderProperty('borderRadius')"></i>
                              </button>
                              <span>{{ borderRadius }}</span>
                              <button class="font-btn">
                                <i class="bi bi-plus" (click)="increaseBorderProperty('borderRadius')"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ul>
                          <li class="nh-mb-12">
                            <mat-slide-toggle
                              class="er-filter-toggle er-filter-toggle-a"
                              [(ngModel)]="boxShadowToggle"
                              (change)="boxShadowToggle && openTemplatesDialog()"
                            >
                              <span>
                                <p class="nh-fs-14">
                                  Apply Drop Shadow
                                </p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <!------------------->
      </div>
    </div>
  </div>
</div>
