<div>
  <mat-dialog-content class="alert-dialog py-3">
    <div class="users-table">
      <label>{{ labels?.Alert }}</label>
    </div>
  </mat-dialog-content>
  <div class="mat-dialg-center">
    <p>
      {{ labels?.Configuring_Master }} <span *ngIf="betType == 'ent'">{{ labels?.Entity }}</span>
      <span *ngIf="betType == 'att'">Attribute </span>Will result in impact entity data wherever used. Would you like to
      proceed ?
    </p>
  </div>
  <mat-dialog-actions class="justify-content-end">
    <button mat-button (click)="upgrade()">{{ labels?.Yes }}</button>
    <button mat-button mat-dialog-close>{{ labels?.No }}</button>
  </mat-dialog-actions>
</div>
