import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { QrcodeScannerModule } from '../qrcode-scanner/qrcode-scanner.module';
import { QrScannerControlComponent } from './qr-scanner-control.component';

@NgModule({
  declarations: [QrScannerControlComponent],
  imports: [ControlsMaterialModule, QrcodeScannerModule],
  exports: [QrScannerControlComponent],
})
export class QrScannerControlModule {
  static entry = {
    qrscanner: QrScannerControlComponent,
  };
}
