import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SignatureConfig } from '../lib/modal/digital-signature';
import { GmapsData } from '../lib/modal/google-maps';



export const defaultEditorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '150',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  //placeholder: 'Enter text here...',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '4',
  fonts: [
    { class: 'arial', name: 'Arial' },
    { class: 'times-new-roman', name: 'Times New Roman' },
    { class: 'calibri', name: 'Calibri' },
    { class: 'comic-sans-ms', name: 'Comic Sans MS' },
  ],
  uploadUrl: '',
  uploadWithCredentials: false,
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [[], ['insertImage', 'insertVideo']],
};

export const defaultMapsConfig: GmapsData = {
  lat: 51.673858,
  lng: 7.815982,
  zoomControl: false,
  disableDefaultUI: false,
  zoom: 12,
  markers: [],
};

export const defaultDigitalSignatureOptions: SignatureConfig = {
  minWidth: 5,
  canvasWidth: 320,
  canvasHeight: 200,
};
