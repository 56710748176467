<div class="entityTable-configuration" id="table-config" *ngIf="!canvasDesignPage">
    <div class="nhCard">
      <div class="nhCard_header">
        <div class="nhCard_header_inner d-flex align-items-center justify-content-between">
          <div class="nhCard_header_left d-flex align-items-center">
            <button class="nh-unsetAll cursor-pointer nhCard_header_back" (click)="saveVerticalTableData('null')">
              <em class="nh-icon nh-icon-chevron-left"></em>
            </button>
            <h4 class="nh-fs-18">Configure Table</h4>
          </div>
          <div class="nhCard_header_right d-flex align-items-center">
            <ul class="nhCard_header_right_list">
              <li class="eth-check">
                <i class="bi bi-check2" id="done_1" (click)="saveVerticalTableData('verticalTable')"></i>
              </li>
              <li class="eth-close"><i class="bi bi-x-lg" id="cancel_1" (click)="saveVerticalTableData('null')"></i></li>
            </ul>
          </div>
        </div>
      </div>
  
      <div class="nhCard_maincontent">
        <div class="nhrow">
          <div class="nhrow_col nhrow_left" *ngIf="isCardConfig || !isAdvancedFeature">
            <div class="et-config-content-left">
              <!-- <i class="bi bi-grip-vertical"></i> -->
              <mat-tab-group class="form-tabs form-tabs-opt1">
                <mat-tab label="Attributes">
                  <div class="attributes-list">
                    <ul>
                      <li
                        *ngFor="let attr of nslAttributes"
                        draggable="true"
                        (dragstart)="drag($event, attr, true)"
                        [id]="attr?.name"
                      >
                        <i class="bi bi-grip-vertical"></i>
                        <p>{{ attr?.name }}</p>
                      </li>
                    </ul>
                  </div>
                </mat-tab>
                <mat-tab label="GSI">
                  <mat-accordion>
                    <mat-expansion-panel
                      *ngFor="let gsi of selectGsiList"
                      draggable="true"
                      (dragstart)="drag($event, gsi, false)"
                      [id]="gsi.name"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex align-items-center justify-content-between">
                        <mat-slide-toggle class="er-filter-toggle er-filter-toggle-a label-color">
                          <span>
                            Enable Popup
                          </span>
                        </mat-slide-toggle>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
  
          <div class="nhrow_col nhrow_middle" [ngClass]="{ nhrow_middle_isAdvancedFeature: isAdvancedFeature }">
            <div class="entity-config-info" >
              <div class="entity-config-info-header">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div class="entity-expand-wrap">
                <div class="entity-expand" >
                  <div
                    id="headerDescription"
                    class="table-description"
                    [ngClass]="headerDescriptionBackgroundSelected ? 'table-header-selected' : ''"
                    
                    (click)="setHeaderDescriptionBackgroundSelected()"
                    (mouseover)="headerHovered = true"
                    (mouseout)="headerHovered = false"
                    [ngStyle]="{
                      'background-color': headerHovered ? headerDescriptionHoverColor : headerDescriptionBackground,
                      color: headerHovered ? headerDescriptionFontHoverColor : headerDescriptionFontColor
                    }"
                  >
                    {{ headerDescription }}
                  </div>
  
                  <p-table
                    *ngIf="verticalTableData"
                    #dropTableData
                    class="entity-table-design vertical-table"
                    [value]="verticalTableData"
                    [resizableColumns]="true"
                    styleClass="p-datatable-gridlines"
                    [tableStyle]="{ 'min-width': tableWidth }"
                    [scrollable]="true"
                    scrollHeight="100%"
                    scrollWidth="100%"
                    [tableStyle]="{
                      'border-color': gridLines?.outLine?.selected ? gridLines?.outLine?.borderColor : 'none',
                      'border-width': gridLines?.outLine?.selected ? gridLines?.outLine?.borderThickness + 'px' : '0px',
                      'border-radius': gridLines?.outLine?.selected ? gridLines?.outLine?.borderRadius + 'px' : '0px'
                    }"
                    [ngClass]="{
                      'table-outline': gridLines?.outLine?.selected == true
                    }"
                  >
                    <ng-template pTemplate="header">
                      <tr
                        [ngClass]="{
                          'row-outline': gridLines?.rows?.selected === true
                        }"
                      >
                        <ng-container>
                          <th
                            class="btn-tooltip d-flex"
                            pTooltip="Serial Numbers"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                            [ngStyle]="{
                              color:
                                hovered || (-2 == columnHovered && advanceTableFeatures.columnHighlights)
                                  ? headerFontHoverColor
                                  : headerFontColor,
                              'font-family': headerFontFamily?.font,
                              'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                              'font-weight': headerBold ? 'bold' : 'normal',
                              'font-style': headerItalic ? 'italic' : 'normal',
                              'font-size': headerFontSize + 'px',
                              'justify-content': headerAlignment?.viewClass,
                              'background-color':
                                hovered || advanceTableFeatures?.columnHighlights ? headerHoverColor : headerBackground
                            }"
                            [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                            *ngIf="advanceTableFeatures?.serialNumbers == true"
                            pResizableColumn
                            (mouseover)="hovered = true; setHover(-2)"
                            (mouseout)="hovered = false; setOutHover(0)"
                          >
                            S.No.
                          </th>
                          <th
                            class="btn-tooltip d-flex"
                            pTooltip="{{ row.attributeHeading }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                            [ngStyle]="{ 'background-color': hovered ? headerHoverColor : headerBackground }"
                            [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                            *ngFor="let row of verticalTableData?.[0];let k = index"
                            class="table-row"
                            (click)="setHeaderBackgroundSelected($event, k)"
                            (mouseover)="hovered = true"
                            (mouseout)="hovered = false"
                            pResizableColumn
                            [ngClass]="{
                              'column-outline': gridLines?.column?.selected === true
                            }"
                          >
                            <span
                              class="entity-table-header-span main-attr text-truncate"
                              [ngStyle]="{
                                color:
                                  hovered || (-2 == columnHovered && advanceTableFeatures.columnHighlights)
                                    ? headerFontHoverColor
                                    : headerFontColor,
                                'font-family': headerFontFamily?.font,
                                'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                                'font-weight': headerBold ? 'bold' : 'normal',
                                'font-style': headerItalic ? 'italic' : 'normal',
                                'font-size': headerFontSize + 'px',
                                'justify-content': headerAlignment?.viewClass
                              }"
                              #attributeItem
                              >{{ row?.attributeValue }}
                            </span>
                          </th>
                        </ng-container>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" class="ui-resizable-column" let-cols let-i="rowIndex">
                      <tr
                        [ngClass]="{
                          'row-outline': gridLines?.rows?.selected === true
                        }"
                        [ngStyle]="{height: rowSize + 'px'}"
                      >
                        <td
                          class="table-row"
                          (click)="setRowBackGroundSelected(i)"
                          *ngIf="advanceTableFeatures.serialNumbers == true"
                          [ngClass]="{
                            'column-outline': gridLines?.column?.selected === true
                          }"
                          [ngStyle]="{
                            'background-color':
                              i % 2 == 0
                                ? evenHovered == i || advanceTableFeatures.columnHighlights
                                  ? evenRowHoverColor
                                  : evenRowColor
                                : oddHovered == i || advanceTableFeatures.columnHighlights
                                ? oddRowHoverColor
                                : oddRowColor,
                            color:
                              i % 2 == 0
                                ? evenHovered == i || (-2 == columnHovered && advanceTableFeatures.columnHighlights)
                                  ? evenRowFontHoverColor
                                  : evenRowFontColor
                                : oddHovered == i || (-2 == columnHovered && advanceTableFeatures.columnHighlights)
                                ? oddRowFontHoverColor
                                : oddRowFontColor,
                            'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                            'text-decoration-line':
                              i % 2 == 0
                                ? evenRowUnderLine
                                  ? 'underline'
                                  : 'none'
                                : oddRowUnderLine
                                ? 'underline'
                                : 'none',
                            'font-weight':
                              i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                            'font-style':
                              i % 2 == 0 ? (evenRowItalic ? 'italic' : 'normal') : oddRowItalic ? 'italic' : 'normal',
                            'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                            'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                          }"
                          [ngClass]="{
                            'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                            'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                          }"
                          (mouseover)="setHover(i, -2)"
                          (mouseout)="setOutHover(i)"
                        >
                          {{ i + 1 }}
                        </td>
                        <td
                          *ngFor="let col of cols; let j = index"
                          class="table-row"
                          (drop)="prevDrag($event,j)"
                          (dragover)="allowDrop($event)"
                          (click)="setRowBackGroundSelected(i)"
                          [ngStyle]="{
                            'background-color':
                              i % 2 == 0
                                ? evenHovered == i || (j == columnHovered && advanceTableFeatures?.columnHighlights)
                                  ? evenRowHoverColor
                                  : evenRowColor
                                : oddHovered == i || (j == columnHovered && advanceTableFeatures?.columnHighlights)
                                ? oddRowHoverColor
                                : oddRowColor,
                            'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                          }"
                          [ngClass]="{
                            'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                            'table-oddRow-selected': i % 2 == 1 && oddRowSelected,
                            'column-outline': gridLines?.column?.selected === true
                          }"
                          (mouseover)="setHover(i, j)"
                          (mouseout)="setOutHover(i)"

                        >
                          <div
                            class="table-value btn-tooltip"
                            pTooltip="{{ col.attributeValue }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                            [ngStyle]="{
                              color:
                                i % 2 == 0
                                  ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                    ? evenRowFontHoverColor
                                    : evenRowFontColor
                                  : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                  ? oddRowFontHoverColor
                                  : oddRowFontColor,
                              'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                              'text-decoration-line':
                                i % 2 == 0
                                  ? evenRowUnderLine
                                    ? 'underline'
                                    : 'none'
                                  : oddRowUnderLine
                                  ? 'underline'
                                  : 'none',
                              'font-weight':
                                i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                              'font-style':
                                i % 2 == 0 ? (evenRowItalic ? 'italic' : 'normal') : oddRowItalic ? 'italic' : 'normal',
                              'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                              'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                            }"
                          >
                            <mat-radio-button *ngIf="j != 0 && prevDragType === 'radioButton'">
                              <p
                                *ngIf="advanceTableFeatures?.showLabels"
                                [ngStyle]="{
                                  color:
                                    i % 2 == 0
                                      ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                        ? evenRowFontHoverColor
                                        : evenRowFontColor
                                      : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                      ? oddRowFontHoverColor
                                      : oddRowFontColor,
                                  'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                                  'text-decoration-line':
                                    i % 2 == 0
                                      ? evenRowUnderLine
                                        ? 'underline'
                                        : 'none'
                                      : oddRowUnderLine
                                      ? 'underline'
                                      : 'none',
                                  'font-weight':
                                    i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                                  'font-style':
                                    i % 2 == 0
                                      ? evenRowItalic
                                        ? 'italic'
                                        : 'normal'
                                      : oddRowItalic
                                      ? 'italic'
                                      : 'normal',
                                  'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                                  'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                                }"
                              >
                                {{ col?.attributeValue }}
                              </p></mat-radio-button
                            >
                            <mat-checkbox *ngIf="j != 0 && prevDragType === 'checkbox'">
                              <p
                                *ngIf="advanceTableFeatures?.showLabels"
                                [ngStyle]="{
                                  color:
                                    i % 2 == 0
                                      ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                        ? evenRowFontHoverColor
                                        : evenRowFontColor
                                      : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                      ? oddRowFontHoverColor
                                      : oddRowFontColor,
                                  'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                                  'text-decoration-line':
                                    i % 2 == 0
                                      ? evenRowUnderLine
                                        ? 'underline'
                                        : 'none'
                                      : oddRowUnderLine
                                      ? 'underline'
                                      : 'none',
                                  'font-weight':
                                    i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                                  'font-style':
                                    i % 2 == 0
                                      ? evenRowItalic
                                        ? 'italic'
                                        : 'normal'
                                      : oddRowItalic
                                      ? 'italic'
                                      : 'normal',
                                  'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                                  'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                                }"
                              >
                                {{ col?.attributeValue }}
                              </p></mat-checkbox
                            >
                            <span
                              #attributeItem
                              [ngClass]="{ 'd-none': j !== 0 || col?.attributeHeading !== 'AttributeName' }"
                              [ngStyle]="{
                                'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                              }"
                            >
                              Attribute
                            </span>
                            <span
                              #attributeItemWithValue
                              [ngClass]="{ 'd-none': j !== 0 || col?.attributeHeading === 'AttributeName' }"
                              [ngStyle]="{
                                'justify-content': i % 2 == 0 ? evenRowAlignment?.viewClass : oddRowAlignment?.viewClass
                              }"
                            >
                              {{ col?.attributeHeading }}
                            </span>
                          </div>
                          <span class="delete-attr" *ngIf="j == 0 && attributeItem.innerHTML !== 'Attribute'"
                            ><img
                              class="cursor-pointer"
                              (click)="
                              prevDelete(
                                  col?.attributeHeading !== 'AttributeName' ? attributeItemWithValue : attributeItem,
                                  i
                                );
                                $event.stopPropagation()
                              "
                              [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                              alt=""
                          /></span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <div
                    id="footerDescription"
                    class="table-description"
                    *ngIf="footerDescription && toggleFooterChecked"
                    [ngClass]="footerDescriptionBackgroundSelected ? 'table-header-selected' : ''"
                    (click)="setFooterDescriptionBackgroundSelected()"
                    (mouseover)="footerHovered = true"
                    (mouseout)="footerHovered = false"
                    [ngStyle]="{
                      'background-color': footerHovered ? footerDescriptionHoverColor : footerDescriptionBackground,
                      color: footerHovered ? footerDescriptionFontHoverColor : footerDescriptionFontColor
                    }"
                  >
                    {{ footerDescription }}
                  </div>
                  <div class="table-pagination" *ngIf="showPagination">
                    <app-entity-grid-card-paginator [length]="5" [pageSize]="10" [paginationJustify]="paginatedAlignment">
                    </app-entity-grid-card-paginator>
                  </div>
                </div>
              </div>
            </div>
  
            <app-drop-advanced-features
              *ngIf="isAdvancedFeature"
              [entityData]="entityData"
              [selectedEntityData]="selectedEntityData"
              [selectedRoleId]="selectedRoleId"
              [combinationArray]="combinationArray"
              [dropAdvancedFeature]="dropAdvancedFeature"
              [childGsiList]="childGsiList"
              [urlBindingGsi]="urlBindingGsi"
              [urlBindingAttribute]="urlBindingAttribute"
              (combinationArrayEmit)="combinationArrayEmit($event)"
              (hiddenRuleSetEmit)="hiddenRuleSetEmit($event)"
              (selectedEntityDataEmit)="selectedEntityDataEmit($event)"
              (urlBindingEmit)="urlBindingEmit($event)"
              (advancedFeaturesEmit)="advancedFeaturesEmit($event)"
            ></app-drop-advanced-features>
          </div>
  
          <div class="nhrow_col nhrow_right" *ngIf="isCardConfig">
            <div class="et-config-content-right">
              <mat-tab-group class="form-tabs form-tabs-opt1">
                <mat-tab label="Design">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Color
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="color-customisation">
                        <ul>
                          <li>
                            <p>Fill Color</p>
                            <p class="box-color">
                              <span class="color-fill"></span>
                              <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('background')"></i>
                            </p>
                          </li>
                          <li>
                            <p>Border Color</p>
                            <p class="box-color">
                              <span class="color-fill"></span>
                              <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('border')"></i>
                            </p>
                          </li>
                          <li>
                            <p>Text Color</p>
                            <p class="box-color">
                              <span class="color-fill"></span>
                              <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('font')"></i>
                            </p>
                          </li>
                          <li>
                            <p>Text Hover Color</p>
                            <p class="box-color">
                              <span class="color-fill"></span>
                              <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('textHover')"></i>
                            </p>
                          </li>
                          <li>
                            <p>Hover Color</p>
                            <p class="box-color">
                              <span class="color-fill"></span>
                              <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('backgroundHover')"></i>
                            </p>
                          </li>
                        </ul>
                        <span
                          *ngIf="colorPickerToggler"
                          [cpPositionRelativeToArrow]="true"
                          [cpDialogDisplay]="'inline'"
                          (colorPickerChange)="changeColor($event)"
                          [(colorPicker)]="colorModel"
                          [cpOKButton]="true"
                          (colorPickerSelect)="closeColorPicker($event)"
                          [cpToggle]="true"
                          [cpOKButtonText]="'Done'"
                        >
                        </span>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Gridlines
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="grid-customisation">
                        <ul>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="gridLines.outLine.selected"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <img src="../../../../../assets/img/general-entity/Advance-features/outline.svg" />
                                <p>OutLine</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="gridLines.rows.selected"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <img src="../../../../../assets/img/general-entity/Advance-features/rows.svg" />
                                <p>Rows</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="gridLines.column.selected"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <img src="../../../../../assets/img/general-entity/Advance-features/column.svg" />
                                <p>Columns</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
  
                          <li class="row-sizes">
                            <p-selectButton
                              [options]="sizes"
                              [(ngModel)]="gridLines.selectedStyle"
                              optionLabel="name"
                            ></p-selectButton>
                          </li>
                        </ul>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Gsi Button Configuration
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="gsi-configuration">
                        <div class="gsi-sec">
                          <ul>
                            <li>
                              <button
                                class="btn-acc btn-gsi"
                                (click)="
                                  gsiConfigurations.gsiPosition = 'onTop'; isMore = false; inTableGSIButtons = false
                                "
                                [ngClass]="{ active: gsiConfigurations.gsiPosition == 'onTop' }"
                              >
                                <em class="nh-icon nh-icon-gsi-button-ontop"></em>
                              </button>
                              <p>On Top</p>
                            </li>
                            <li>
                              <button
                                class="btn-acc btn-gsi"
                                (click)="
                                  gsiConfigurations.gsiPosition = 'inline'; isMore = false; inTableGSIButtons = true
                                "
                                [ngClass]="{ active: gsiConfigurations.gsiPosition == 'inline' }"
                              >
                                <em class="nh-icon nh-icon-gsi-button-inline"></em>
                              </button>
                              <p>Inline</p>
                            </li>
                            <li>
                              <button
                                class="btn-acc btn-gsi"
                                (click)="
                                  gsiConfigurations.gsiPosition = 'combine'; isMore = true; inTableGSIButtons = false
                                "
                                [ngClass]="{ active: gsiConfigurations.gsiPosition == 'combine' }"
                              >
                                <em class="nh-icon nh-icon-gsi-button-combine"></em>
                              </button>
                              <p>Combine</p>
                            </li>
                            <li>
                              <button
                                class="btn-acc btn-gsi"
                                (click)="
                                  gsiConfigurations.gsiPosition = 'combineInline'; isMore = true; inTableGSIButtons = true
                                "
                                [ngClass]="{ active: gsiConfigurations.gsiPosition == 'combineInline' }"
                              >
                                <em class="nh-icon nh-icon-gsi-button-combine-inline"></em>
                              </button>
                              <p>Combine Inline</p>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="input-section"
                          *ngIf="
                            gsiConfigurations.gsiPosition == 'combineInline' || gsiConfigurations.gsiPosition == 'inline'
                          "
                        >
                          <p class="title text-color">Column Title</p>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Actions"
                            [(ngModel)]="gsiConfigurations.gsiInlineTitle"
                          />
                        </div>
                        <div
                          class="input-section"
                          *ngIf="
                            gsiConfigurations.gsiPosition == 'combine' || gsiConfigurations.gsiPosition == 'combineInline'
                          "
                        >
                          <p class="title text-color">Combination Name</p>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Actions"
                            [(ngModel)]="gsiConfigurations.combineName"
                          />
                        </div>
                        <div class="add-icon" *ngIf="isSelectGsi">
                          <p class="nh-mt-16">configure {{ gsiForConfigure }} :</p>
                          <mat-slide-toggle
                            [(ngModel)]="gsiNameWithIcon"
                            [disabled]="gsiOnlyIcon"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a nh-mt-16 nh-mb-16"
                          >
                            <span>
                              <p class="title">Icon with GSI Name</p>
                            </span>
                          </mat-slide-toggle>
                          <mat-slide-toggle
                            [(ngModel)]="gsiOnlyIcon"
                            [disabled]="gsiNameWithIcon"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p class="title">Only Icon</p>
                            </span>
                          </mat-slide-toggle>
  
                          <div class="icon-section">
                            <ul class="nh-mt-24">
                              <li>
                                <button class="btn btn-icon" (click)="selectGsiIcon = 'cart'; imgSrc = ''">
                                  <img
                                    src="../../../../../assets/img/general-entity/Advance-features/cart.svg"
                                    alt="cart"
                                  />
                                </button>
                              </li>
                              <li>
                                <button class="btn btn-icon" (click)="selectGsiIcon = 'heart'; imgSrc = ''">
                                  <img
                                    src="../../../../../assets/img/general-entity/Advance-features/heart.svg"
                                    alt="heart"
                                  />
                                </button>
                              </li>
                              <li>
                                <button class="btn btn-icon" (click)="selectGsiIcon = 'bookmark'; imgSrc = ''">
                                  <img
                                    src="../../../../../assets/img/general-entity/Advance-features/bookmark.svg"
                                    alt="bookmark"
                                  />
                                </button>
                              </li>
                              <li>
                                <button class="btn btn-icon" (click)="selectGsiIcon = 'payment'; imgSrc = ''">
                                  <img
                                    src="../../../../../assets/img/general-entity/Advance-features/payment.svg"
                                    alt="payment"
                                  />
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div class="upload-section">
                            <ul>
                              <li class="items">
                                <article class="box">
                                  <div class="box-left">
                                    <p class="box-title text-color">Default Icon upload</p>
                                    <small class="text-color">file format: png, svg</small>
                                    <div class="btn-upload" (change)="uploadIcon($event)">
                                      <input type="file" placeholder="Attachment" />
                                      <button class="btn-acc btn-dark" type="button">
                                        Upload
                                      </button>
                                    </div>
                                  </div>
                                  <div class="box-right">
                                    <div class="close-icon">
                                      <em class="nh-icon nh-icon-x-circle"></em>
                                    </div>
                                    <div class="box-icon">
                                      <img
                                        [src]="imgSrc.length > 0 ? imgSrc : defaultGsiImages[selectGsiIcon]"
                                        alt="calender"
                                      />
                                    </div>
                                  </div>
                                </article>
                              </li>
                            </ul>
                          </div>
                          <button class="btn-acc btn-dark nh-mt-20 ml-auto" (click)="saveGsiConfigurations()">
                            Save
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Text
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="test">
                        <p class="title text-color">Font Family</p>
                        <div class="entity-custom-select">
                          <mat-form-field appearance="fill" floatLabel="never">
                            <mat-select
                              (ngModelChange)="selectedeFontChange($event)"
                              [(ngModel)]="selectedFont"
                              placeholder="Inter"
                              panelClass="entity-custom-select-options"
                            >
                              <mat-option *ngFor="let font of fonts" [value]="font">{{ font.name }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <p class="title text-color">Font Style</p>
                        <div class="entity-custom-select"></div>
                        <div class="font-style">
                          <ul>
                            <li (click)="checkIsbold()">
                              <span>B</span>
                            </li>
                            <li (click)="checkIsItalic()">
                              <span>i</span>
                            </li>
                            <li (click)="checkIsUnderLine()">
                              <span>U</span>
                            </li>
                          </ul>
                        </div>
                        <div class="alignment">
                          <p class="title text-color">Alignment</p>
                          <ul>
                            <li
                              *ngFor="let align of alignmentOptions"
                              (click)="checkAlign(align)"
                              [ngClass]="{ active: alignment == align }"
                            >
                              <em [class]="align.class"></em>
                            </li>
                          </ul>
                        </div>
                        <div class="font-section">
                          <p class="title text-color">Font Size</p>
                          <div class="font-input">
                            <button class="font-btn">
                              <i class="bi bi-dash" (click)="decreaseFontSize()"></i>
                            </button>
                            <span>{{ fontSize }}</span>
                            <button class="font-btn">
                              <i class="bi bi-plus" (click)="increaseFontSize()"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Header Freeze
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="header-freeze">
                        <div class="header-btn">
                          <ul>
                            <li>
                              <button
                                (click)="tableFreezePosition = 'top'"
                                class="btn-acc btn-gsi"
                                [ngClass]="{ active: tableFreezePosition == 'top' }"
                              >
                                <em class="nh-icon nh-icon-freeze-top"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                (click)="tableFreezePosition = 'left'"
                                class="btn-acc btn-gsi"
                                [ngClass]="{ active: tableFreezePosition == 'left' }"
                              >
                                <em class="nh-icon nh-icon-freeze-left"></em>
                              </button>
                            </li>
                            <li>
                              <button
                                (click)="tableFreezePosition = 'topLeft'"
                                class="btn-acc btn-gsi"
                                [ngClass]="{ active: tableFreezePosition == 'topLeft' }"
                              >
                                <em class="nh-icon nh-icon-freeze-top-left"></em>
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div
                          class="entity-custom-select nh-mt-16"
                          *ngIf="tableFreezePosition == 'left' || tableFreezePosition == 'topLeft'"
                        >
                          <mat-form-field appearance="fill" floatLabel="never">
                            <mat-select
                              [(ngModel)]="freezeAttribute"
                              placeholder="Select Attributes"
                              panelClass="entity-custom-select-options entity-multi-select"
                              multiple
                            >
                              <mat-option *ngFor="let attr of dropAttributes" [value]="attr.name">{{
                                attr.name
                              }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Pagination
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="pagination-sec">
                        <ul>
                          <li class="nh-mb-12">
                            <mat-slide-toggle
                              [(ngModel)]="showPagination"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <p>Enable</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                        </ul>
                        <div class="font-section">
                          <p class="title text-color">Rows Per Page</p>
                          <div class="font-input">
                            <button class="btn-acc font-btn">
                              <i (click)="decreasePaginateSize()" class="bi bi-dash"></i>
                            </button>
                            <span>{{ paginationRowSize }}</span>
                            <button class="btn-acc font-btn">
                              <i (click)="increasePaginateSize()" class="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div class="alignment">
                          <p class="title text-color">Alignment</p>
                          <ul>
                            <li
                              *ngFor="let align of alignmentOptions"
                              (click)="checkPaginatedAlign(align)"
                              [ngClass]="{ active: paginatedAlignment == align }"
                            >
                              <em [class]="align.class"></em>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Entity Description
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="entity-description">
                        <ul>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="toggleHeaderChecked"
                              class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                            >
                              <span>
                                Table Header Description
                              </span>
                            </mat-slide-toggle>
                            <textarea
                              id="desc"
                              name="desc"
                              maxlength="100"
                              class="text-field"
                              [(ngModel)]="headerTempDescription"
                              *ngIf="toggleHeaderChecked"
                            >
                              {{ headerTempDescription }}
                              </textarea
                            >
  
                            <div class="count-sec" *ngIf="toggleHeaderChecked">
                              <button class="btn-acc btn-dark" (click)="saveHeaderDescription(headerTempDescription)">
                                Save
                              </button>
                            </div>
                          </li>
                          <li class="nh-mb-12">
                            <mat-slide-toggle
                              [(ngModel)]="toggleFooterChecked"
                              class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                            >
                              <span>
                                Table Footer Description
                              </span>
                            </mat-slide-toggle>
                            <textarea
                              id="desc"
                              name="desc"
                              maxlength="100"
                              class="text-field"
                              [(ngModel)]="footerTempDescription"
                              *ngIf="toggleFooterChecked"
                            >
                              {{ footerTempDescription }}
                              </textarea
                            >
  
                            <div class="count-sec" *ngIf="toggleFooterChecked">
                              <button class="btn-acc btn-dark" (click)="saveFooterDescription(footerTempDescription)">
                                Save
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Component Scale
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="header-freeze">
                        <div class="header-btn">
                          <ul>
                            <li>
                              <button
                                (click)="changeRowSize('large')"
                                class="btn-acc btn-gsi"
                                [ngClass]="{ active: tableComponentScale == 'large' }"
                              >
                                <em class="nh-icon nh-icon-freeze-top"></em>
                              </button>
                              <span>Large</span>
                            </li>
                            <li>
                              <button
                                (click)="changeRowSize('medium')"
                                class="btn-acc btn-gsi"
                                [ngClass]="{ active: tableComponentScale == 'medium' }"
                              >
                                <em class="nh-icon nh-icon-freeze-left"></em>
                              </button>
                              <span>Medium</span>
                            </li>
                            <li>
                              <button
                                (click)="changeRowSize('small')"
                                class="btn-acc btn-gsi"
                                [ngClass]="{ active: tableComponentScale == 'small' }"
                              >
                                <em class="nh-icon nh-icon-freeze-top-left"></em>
                              </button>
                              <span>Small</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Advance Features
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="advance-features">
                        <ul>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="advanceTableFeatures.multipleSelection"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <p>Multiple Selection</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="advanceTableFeatures.columnHighlights"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <p>Column Highlights</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="advanceTableFeatures.attributeWrap"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <p>Attribute Wrap</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                          <li>
                            <mat-slide-toggle
                              [(ngModel)]="advanceTableFeatures.serialNumbers"
                              disableRipple
                              class="er-filter-toggle er-filter-toggle-a"
                            >
                              <span>
                                <p>Serial Numbers</p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                          <ng-container>
                            <li>
                              <mat-slide-toggle
                                [ngModel]="isVericalListRundown"
                                (change)="openDialog(myDialog)"
                                class="er-filter-toggle er-filter-toggle-a"
                              >
                                <span>
                                  <p>{{ isVericalListRundown ? 'verticalList' : 'ListRunDown' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                            <li>
                              <mat-slide-toggle
                                [(ngModel)]="advanceTableFeatures.allowExpand"
                                disableRipple
                                class="er-filter-toggle er-filter-toggle-a"
                              >
                                <span>
                                  <p>allowExpand</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                            <li>
                              <mat-slide-toggle
                                [(ngModel)]="advanceTableFeatures.hideDeleteIcon"
                                disableRipple
                                class="er-filter-toggle er-filter-toggle-a"
                              >
                                <span>
                                  <p>hide Attribute DeleteIcon</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                            <li>
                              <mat-slide-toggle
                                [(ngModel)]="advanceTableFeatures.showLabels"
                                disableRipple
                                class="er-filter-toggle er-filter-toggle-a"
                              >
                                <span>
                                  <p>showLables</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                            <li>
                              <mat-slide-toggle
                                [(ngModel)]="advanceTableFeatures.multiRowsVerticalTable"
                                disableRipple
                                class="er-filter-toggle er-filter-toggle-a"
                              >
                                <span>
                                  <p>{{ labels?.MultiValue_as_Extension || 'Multivalue as Extension' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-tab>
                <mat-tab label="Condition">
                  <div class="condition-wrapper">
                    <div
                      class="add-condition d-flex justify-content-center align-items-center cursor-pointer"
                      (click)="addCondition()"
                    >
                      <span><i class="bi bi-plus-lg"></i></span>
                      <span class="text">{{ labels?.Add_Condition }}</span>
                    </div>
                    <div class="condition-box d-flex">
                      <ng-container *ngFor="let condition of conditionArray; let index = index">
                        <div class="d-flex nh-fs-14 nh-column-gap-10">
                          <span>{{ condition.conditionAttribute }}</span>
                          <span>{{ condition.condition }}</span>
                          <span [ngStyle]="{ color: condition.textColor, 'background-color': condition.fillColor }"
                            >{{ condition.selectedConditonValue }}
                          </span>
                          <i class="bi bi-trash3 nh-fs-14 ml-auto" (click)="deleteSelectedCondition(index, 'child')"></i>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="condition-tab" *ngIf="addConditionVariable">
                    <div class="entity-custom-select">
                      <mat-form-field appearance="fill" floatLabel="never">
                        <mat-select
                          [(ngModel)]="conditionAttribute"
                          placeholder="Select Attributes"
                          panelClass="entity-custom-select-options entity-multi-select"
                          multiple
                        >
                          <mat-option *ngFor="let attr of dropAttributes" [value]="attr.name">{{ attr.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="operator-list">
                      <ul (click)="addConditionOperator()">
                        <li>
                          <button
                            class="btn-acc btn-icon"
                            [ngClass]="{ active: selectedCondition === '==' }"
                            (click)="setSelectedCondition('==')"
                          >
                            <span>==</span>
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn-acc btn-icon"
                            [ngClass]="{ active: selectedCondition === '!=' }"
                            (click)="setSelectedCondition('!=')"
                          >
                            <span>!=</span>
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn-acc btn-icon"
                            (click)="setSelectedCondition('>')"
                            [ngClass]="{ active: selectedCondition === '>' }"
                          >
                            <span>></span>
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn-acc btn-icon"
                            (click)="setSelectedCondition('\<')"
                            [ngClass]="{ active: selectedCondition === '\<' }"
                          >
                            <span><</span>
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn-acc btn-icon"
                            (click)="setSelectedCondition('>=')"
                            [ngClass]="{ active: selectedCondition === '>=' }"
                          >
                            <span>>=</span>
                          </button>
                        </li>
                        <li>
                          <button
                            class="btn-acc btn-icon"
                            (click)="setSelectedCondition('\<=')"
                            [ngClass]="{ active: selectedCondition === '\<=' }"
                          >
                            <span><=</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="input-section">
                      <p class="title text-color">Value</p>
                      <input type="text" class="form-control" placeholder="" [(ngModel)]="selectedConditonValue" />
                    </div>
                    <div class="color-customisation">
                      <ul>
                        <li
                          [cpPosition]="'bottom'"
                          [cpDialogDisplay]="'inline'"
                          [cpPositionRelativeToArrow]="true"
                          [(colorPicker)]="conditionColor"
                          [cpOKButton]="true"
                          [cpOKButtonText]="'Done'"
                        >
                          <p>Fill Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ 'background-color': conditionColor }"></span
                            ><i class="bi bi-eyedropper"></i>
                          </p>
                        </li>
                        <li
                          [cpPosition]="'bottom'"
                          [cpDialogDisplay]="'inline'"
                          [cpPositionRelativeToArrow]="true"
                          [(colorPicker)]="selectedConditionTextColor"
                          [cpOKButton]="true"
                          [cpOKButtonText]="'Done'"
                        >
                          <p>Text Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ 'background-color': selectedConditionTextColor }"></span
                            ><i class="bi bi-eyedropper"></i>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <button class="btn-acc btn-dark create-btn" (click)="createCondition()">
                      Create
                    </button>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="entity-right entity-right-config d-none" *ngIf="!isGrammer">
              <div class="entity-editfields">
                <div class="entitytab-one entitytab-fields">
                  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                    <mat-tab label="Configure">
                      <div class="editfields-form" *ngIf="isSlectedRole">
                        <label>ROLES</label>
                        <div class="editfields-info">
                          <h4
                            pTooltip="{{ selectedRole }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                          >
                            {{ selectedRole }}
                          </h4>
                          <button class="btn btnedit">
                            <img
                              [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                              (click)="backtoRolePage()"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="editfields-form" *ngIf="isSlectedEntity">
                        <label>ENTITY</label>
                        <div class="editfields-info">
                          <h4
                            pTooltip="{{ selectedEntity }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                          >
                            {{ selectedEntity }}
                          </h4>
                          <button class="btn btnedit">
                            <img
                              [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                              (click)="backtoEntityPage()"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="editfields-form" *ngIf="isSlectedGsi">
                        <label>Solution</label>
                        <div class="editfields-info">
                          <h4
                            pTooltip="{{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }} {{
                              selectedGsi[2] ? ',' + selectedGsi[2] : ''
                            }}"
                            tooltipPosition="top"
                            tooltipStyleClass="transcustomtolltipbook"
                          >
                            {{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}
                            {{ selectedGsi[2] ? ',' + selectedGsi[2] : '' }}
                          </h4>
                          <button class="btn btnedit">
                            <img
                              [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                              (click)="backtoGsiPage()"
                            />
                          </button>
                        </div>
                      </div>
                      <div class="condition">
                        <span><i class="bi bi-plus-lg"></i></span>
                        <span class="text">Add More CTA Button</span>
                      </div>
                    </mat-tab>
                    <mat-tab label="Design" *ngIf="!isAdvancedFeature">
                      <div class="entitytab-two entitytab-fields">
                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight>
                          <mat-tab label="Color">
                            <div class="color-picker1">
                              <div class="choose-color" *ngIf="tabSelected === 'child' || !applySameToExpand">
                                <div
                                  class="color-picker-me btn btn-primary"
                                  *ngIf="!colorPickerToggler"
                                  (click)="toggleColorPicker()"
                                >
                                  Choose Color
                                </div>
                                <span
                                  *ngIf="colorPickerToggler"
                                  [cpPositionRelativeToArrow]="true"
                                  [cpDialogDisplay]="'inline'"
                                  (colorPickerChange)="changeColor($event)"
                                  [(colorPicker)]="colorModel"
                                  [cpOKButton]="true"
                                  (colorPickerSelect)="closeColorPicker($event)"
                                  [cpToggle]="true"
                                  [cpOKButtonText]="'Done'"
                                >
                                </span>
                              </div>
                            </div>
                          </mat-tab>
                        </mat-tab-group>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- <div class="entity-left" *ngIf="isCardConfig">
      <div class="entity-editfields">
        <div class="entity-tabs">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Entity">
              <div class="entity-tabs-info">
                <ul *ngIf="tabSelected !== 'expand'">
                  <li
                    *ngFor="let attr of nslAttributes"
                    draggable="true"
                    (dragstart)="drag($event, attr, true)"
                    [id]="attr.name"
                  >
                    <span>{{ attr.name }}</span>
                    <span
                      ><img [src]="'../../../../../assets/img/general-entity/pin.svg' | CDNUrlRewritePipe" alt=""
                    /></span>
                  </li>
                </ul>
                <ul *ngIf="tabSelected == 'expand'">
                  <li
                    *ngFor="let attr of nslAttributesExpand"
                    draggable="true"
                    (dragstart)="drag($event, attr, true)"
                    [id]="attr.name"
                  >
                    <span>{{ attr.name }}</span>
                    <span
                      ><img [src]="'../../../../../assets/img/general-entity/pin.svg' | CDNUrlRewritePipe" alt=""
                    /></span>
                  </li>
                </ul>
              </div>
            </mat-tab>
            <mat-tab label="GSI" *ngIf="!isGrammer">
              <div class="entity-tabs-info">
                <ul *ngIf="tabSelected !== 'expand'">
                  <li
                    *ngFor="let gsi of selectGsiList"
                    draggable="true"
                    (dragstart)="drag($event, gsi, false)"
                    [id]="gsi.name"
                  >
                    <span>{{ gsi.name }}</span>
                    <span
                      ><img [src]="'../../../../../assets/img/general-entity/pin.svg' | CDNUrlRewritePipe" alt=""
                    /></span>
                  </li>
                </ul>
                <ul *ngIf="tabSelected == 'expand'">
                  <li
                    *ngFor="let gsi of selectGsiListExpand"
                    draggable="true"
                    (dragstart)="drag($event, gsi, false)"
                    [id]="gsi.name"
                  >
                    <span>{{ gsi.name }}</span>
                    <span
                      ><img [src]="'../../../../../assets/img/general-entity/pin.svg' | CDNUrlRewritePipe" alt=""
                    /></span>
                  </li>
                </ul>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div> -->
  </div>
  
  <ng-template #myDialog>
    <h2 matDialogTitle>Vertical List Rundown Toggle</h2>
    <mat-dialog-content class="border-bottom border-top py-3">
      <div class="row">
        Are you sure you want change Table Entire list will get Reset
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="this.dialog.closeAll()" matDialogClose>{{ labels?.Cancel }}</button>
      <button mat-button color="primary" (click)="resetVerticalTable()">Change</button>
    </mat-dialog-actions>
  </ng-template>
  <ng-template #borderStyle let-data="data">
    border-color
    <span
      [cpPositionRelativeToArrow]="true"
      [cpDialogDisplay]="'inline'"
      (colorPickerChange)="choseBorderColor($event, data.label)"
      borderThickness
      [(colorPicker)]="colorModel"
      [cpOKButton]="true"
      (colorPickerSelect)="closeColorPicker($event)"
      [cpToggle]="true"
      [cpOKButtonText]="'Done'"
    >
    </span>
    <div class="font-section">
      <p class="title text-color">Border Thickness</p>
      <div class="font-input">
        <button class="btn-acc font-btn">
          <i (click)="decreaseBorderThickness(data.label)" class="bi bi-dash"></i>
        </button>
        <span>{{ gridLines[data.label]?.borderThickness }}</span>
        <button class="btn-acc font-btn">
          <i (click)="increaseBorderThickness(data.label)" class="bi bi-plus"></i>
        </button>
      </div>
    </div>
    <div class="font-section">
      <p class="title text-color">Border Radius</p>
      <div class="font-input">
        <button class="btn-acc font-btn">
          <i (click)="decreaseBorderRadius(data.label)" class="bi bi-dash"></i>
        </button>
        <span>{{ gridLines[data.label]?.borderRadius }}</span>
        <button class="btn-acc font-btn">
          <i (click)="increaseBorderRadius(data.label)" class="bi bi-plus"></i>
        </button>
      </div>
      </div>
      </ng-template>