<div class="uprof-changepassword-container">
  <h3>{{ labels?.Reset_Password }}</h3>
  <p>Password reset session will expire in {{ expiryTime }} seconds</p>
  <form [formGroup]="PasswordDetails" class="uprof-form">
    <div class="uprof-formfield">
      <mat-label>{{ labels?.New_Password }}</mat-label>
      <div class="uprof-input">
        <input
          class="uprof-inputfield"
          matInput
          type="password"
          name="newPassword"
          id="newPassword"
          formControlName="newPassword"
        />
      </div>
    </div>
    <div class="uprof-formfield passwordpolicy">
      <mat-label>{{ labels?.Confirm_New_Password }}</mat-label>
      <div class="uprof-input position-relative">
        <input
          class="uprof-inputfield uprof-inputfield-confirm"
          matInput
          type="password"
          name="newPasswordCnf"
          id="newPasswordCnf"
          formControlName="newPasswordCnf"
          (keyup)="checkPasswordMatch()"
        />
        <div class="cstm-tooltip" href="">
          <div class="pasword-policy">
            <span class="info-icon">
              <mat-icon matSuffix>info</mat-icon>
            </span>
            <span class="policyinfo" *ngIf="password_info">
              Password Policy(minimum requirements):<br />
              Digits: {{ password_info?.digits }}<br />
              forceExpiredPasswordChange:{{ password_info?.forceExpiredPasswordChange }}days<br />
              Length: {{ password_info?.length }}<br />
              notUsername: {{ password_info?.notUsername }}<br />
              specialChars: {{ password_info?.specialChars }}<br />
              upperCase: {{ password_info?.upperCase }}<br />
            </span>
            <span class="policyinfo" *ngIf="!password_info"> Password Policy(No minimum requirements):<br /> </span>
          </div>
        </div>
      </div>
      <mat-error *ngIf="pwdError"> {{ labels?.Password_Doesn_t_Match }}</mat-error>
    </div>
  </form>
</div>
<div class="uprof-btn">
  <button
    [disabled]="pwdError || PasswordDetails.value.newPassword == '' || PasswordDetails.value.newPasswordCnf == ''"
    class="btn"
    mat-button
    name="passwordReseting"
    id="passwordReseting"
    (click)="passwordReseting()"
  >
    {{ labels?.Save }}
  </button>
  <button class="btn" mat-button (click)="closePopup()">{{ labels?.Cancel }}</button>
</div>
