import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  A2aFacadeService,
  AlertService,
  ChangeUnit,
  ChangeUnitFacadeService,
  ComponentCanDeactivate,
  EntityFacadeService,
  EntityStoreService,
  EventsFacadeService,
  Gsi,
  GsiFacadeService,
  LayoutService,
  LayoutSolutionFacadeService,
  LibraryFacadeService,
  LoaderService,
  NewSolutionFacadeService,
  NodeGsiFacadeService,
  OCRFacadeService,
  TransactionFacadeService,
  paginationDetails,
} from '@common-services';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DemoPopUpComponent } from '../demo-pop-up/demo-pop-up.component';
import { TransactionEndpointService ,AttributeStoreService} from '@common-services';
import { PostExceptionsViewComponent } from '../post-exceptions-view/post-exceptions-view.component';
declare var require: any;
declare var OpenPay: any;
const publicIp = require('public-ip');
//declare const gtag: Function;

@Component({
  selector: 'app-transaction-smart',
  templateUrl: './transaction-smart.component.html',
  styleUrls: ['./transaction-smart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransactionSmartComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate {
  gsi: any;
  selectedCu: ChangeUnit;
  _previousCUList: ChangeUnit;
  showTransaction: boolean;
  currentScreen: String = 'myCollection';
  unsubscribe$: Subject<any> = new Subject<any>();
  ignoreWarning: boolean = false;
  gsiData: any;
  _gsiData: any;
  nextCURequestPost: any = {
    gsiId: 2000,
  };
  templateType: string;
  txnData: any = {};
  changeUnitData: any;
  redirectData: any;
  redirected: boolean;
  currentEntity: number;
  currentAttribute: number;
  layerItem: any = {};
  transactionResume: boolean = false;
  isActiveFlag: boolean = true;
  transactionEventResume: boolean = false;
  physicalEntities: any;
  currentCu: any;
  originalEntitylist: any;
  listData: any;
  eventsData: any;
  previousChangeUnit: any;
  currentCULayers: any;
  solutionData: any;
  previousSolutionData: any;
  targetEntity: any;
  isCarnival: boolean;
  isOnboarding: boolean;
  showAds: boolean = false;
  isReadOnlyMode: boolean;
  isMachineCUPending: boolean;
  transactionCU: any;
  previousTransactionCU: any;
  isDebug: boolean = false;
  debugInfo: any;
  showTree: boolean = false;
  showCuList: boolean = true;
  isResume: boolean = false;
  selectedCuDetails: any;
  informationLayer: any = [];
  cuPhysicalLayer: any = [];
  selectedEntityList: any = [];
  showEntityList: boolean = false;
  attributesList: any = [];
  selectedEntity: any;
  selectedAttribute: any;
  showAttributeList: boolean = false;
  layerShortCut: any;
  layerKey: any;
  layerAttr: any;
  targetEnt: any = [];

  txnCulayer: any;
  targetInfoMap: any;
  sourceInfoMap: any;
  targetMap: any;
  sourcemap: any;
  currentLayer: string;
  showLayer: boolean;
  cuinformationLayer: any[];
  cuphysicalLayer: any[];
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  labelForInformationLayer = {
    name: 'information Layer',
    label: 'information Layer',
  };
  isAccordianOpen: any[] = [false];
  currentChangeUnit: any;
  index: any;
  txnNSL: any;
  value: any;
  slot: any;
  debugValue: any;
  toggle: boolean = false;
  tempCurrentCu: any;
  tempEntityList: any;
  tempInfoEntityList: any;
  previousCUList: any[] = [];
  isReloaded: boolean = false;
  currentCuExecuting: any;
  cutriggerCESLayer: any[];
  transactionInProgress: boolean;
  eventGsiData: any;
  solnData: any;
  @Input() solEncodedData: any;
  dropdownPageNo: any = 1;
  dropdownpagesize: any = 100;
  entityDesign: any;
  attrTableFlag: boolean;
  locationInterval: NodeJS.Timeout | number | null = null;
  valueChanged: any;
  selectedTheme: any;
  layoutConfigurations: any;
  popupLayoutConfigurations: any;
  defaultLayoutConfigurations: any;
  allLayoutConfigurations: any;
  selectedCard: any;
  popupClosed: boolean = false;
  previousGsidata: any;
  currentState = 'normal';
  timeIntervalofResume: any;
  openPopUpDialog = false;
  gsiPopup = false;
  ngUnsubscribe = new Subject();
  @ViewChild('myDialog') myDialog: TemplateRef<any>;
  urlBindingEntity: any;
  urlBindingAttrValue: any;
  urlBindingGsiId: any;
  urlBindingMasterId: any;
  availableDates: any = [];
  postExceptionsData: any = '';
  environmentType: string = '';
  /* istanbul ignore next */
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm alert before navigating away
    return this.ignoreWarning ? true : false;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload', ['$event'])
  /* istanbul ignore next */
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue =
        'WARNING: Any changes made will be lost. Press Cancel to go back and save these changes, or OK to lose these changes.';
    }
  }
  gsiDataObj: any;
  public myEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() ignoreWarningEmiter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private nodeGsiFacadeService: NodeGsiFacadeService,
    private libraryFacadeService: LibraryFacadeService,
    public transactionFacadeService: TransactionFacadeService,
    private transactionEndPointService: TransactionEndpointService,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private layoutFacade: LayoutSolutionFacadeService,
    private router: Router,
    public route: ActivatedRoute,
    private eventsService: EventsFacadeService,
    private ocrFacadeService: OCRFacadeService,
    public dialog: MatDialog,
    private loader: LoaderService,
    private a2aFacade: A2aFacadeService,
    public layoutService: LayoutService,
    private render: Renderer2,
    public entityfacade: EntityFacadeService,
    public newSolutionFacade: NewSolutionFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private datePipe: DatePipe,
    private entityStoreService: EntityStoreService,
    private alertService: AlertService,
    private attributeStoreService: AttributeStoreService
  ) {
    this.loader.show();
    this.currentScreen = 'myCollection';
    this.detectPopUpDialog();
    this.detectGSIPopupChanges();
    this.detectTransactionViewType();

    // this.detectChangeOfTab();
    this.detectResumeChangeUnit();
    this.detectLink();
    // this.detectTemplateType();
    this.detectNextChangeUnit();
    this.detectOCRMappings();
    this.getEventsResponse();
    this.getTriggerCondition();
    this.preventAlert();
    // new service integration for list integration
    this.detectListChangeUnit();
    this.getDependentDropdownResponce();
    // this.getLocationDetails();
    this.detectPendingTransaction();
    this.detectNextListChangeUnit();
    this.detectLocationDetails();
    this.detectDebugInfo();
    this.stopResumeTransaction();
    this.getDebugValue();
    this.detectSolutionData();
    this.detectEvents();
    this.detectCurrentCu();
    this.getSubmiTransaction();
    this.facetSearchFilter();
    this.getMultiVlaueEntities();
    this.getDebugCu();
    this.getEmbeddedGSIInfo();
    this.detectSelectedCard();
    this.getMetaData();
    this.detectExit();
    this.getAllCardIds();
    this.detectCancelledTransaction();
  }

  @ViewChild('readOnlyPopUp', { static: true }) readOnlyPopUp: TemplateRef<any>;

  getSolutionDataFromRoute(event: any) {
    if (event?.url?.includes('transaction/view')) {
      /* istanbul ignore next */
      let splitedUrl = event?.url?.split('/');
      /* istanbul ignore next */
      this.solnData = atob(
        decodeURIComponent(splitedUrl[splitedUrl?.length - 1])
      );
      /* istanbul ignore next */
      if (this.solnData) {
        if (this.solutionData?.gsiId !== JSON.parse(this.solnData)?.gsiId) {
          this.detectActiveRoute(this.solnData);
        }
      }
    } else if (
      event?.url?.includes('custom-mylibrary') &&
      !!this.solEncodedData
    ) {
      this.dialog.closeAll();
    }
  }
  ngOnInit(): void {
    this.getAllLayoutsConfig();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: NavigationStart) => {
        /* istanbul ignore next */
        this.getSolutionDataFromRoute(event);
      });

      /* this if block will detect entity name and attribute value in url   'browse/ent/val'  and use it to fetch  entity  and all cards for that entity */
    if (this.route?.snapshot?.params?.entityName) {
      let entityName = this.route?.snapshot?.params?.entityName;
      entityName = decodeURIComponent(entityName);
      this.urlBindingAttrValue = this.route?.snapshot?.params?.attributeValue;
      // let tenantName = localStorage.getItem('TenantName');
      let tenantName = localStorage.getItem('envClientId')?localStorage.getItem('envClientId'): localStorage.getItem('TenantName');
      // this.loader.show();
      this.entityfacade
        .fetchEntityByName(tenantName, entityName)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          if (res) {
            this.urlBindingEntity = res?.result;
            this.newSolutionFacade.getCardIds([this.urlBindingEntity.id]);
          }
        });
    } else if (this.route?.snapshot?.params?.configurationName) {
      // let configName = decodeURIComponent(this.route?.snapshot?.params?.configutaionName);
      this.urlBindingAttrValue = this.route?.snapshot?.params?.attributeValue;
      this.getEntityCardByConfigurationName(this.route?.snapshot?.params?.configurationName)
    }
    /* istanbul ignore next */
    if (this.route.snapshot.params?.solutionData) {
      this.solnData = atob(this.route.snapshot.params?.solutionData);
    }
    if (this.solEncodedData) {
      this.detectMccPoupGsi();
      this.solnData = atob(this.solEncodedData);
    }
    /* istanbul ignore next */
    if (
      !this.solutionData ||
      JSON.stringify(this.solutionData) !== this.solnData
    ) {
      this.detectActiveRoute(
        this.solnData,
        JSON.parse(this.solnData)?.randNum ? false : true
      );
    }
    /* istanbul ignore else */
    if (sessionStorage.getItem('uicontrolsMap')) {
      let arr = Object?.entries(
        JSON.parse(sessionStorage.getItem('uicontrolsMap'))
      )?.map(([type, event]) => ({
        type,
        event,
      }));
      if (arr?.length > 0) {
        this.selectedTheme = '';
      }
    }
    this.environmentType = localStorage.getItem('environmentType');
    if (sessionStorage.getItem('themeId') == 'NslTheme1') this.selectedTheme = '';
    else if (sessionStorage.getItem('themeId') == 'NslTheme2') this.selectedTheme = 'legacy';
    else if (sessionStorage.getItem('themeId') == 'NslTheme3') this.selectedTheme = 'filled';
    else if (sessionStorage.getItem('themeId') == 'NslTheme4') this.selectedTheme = 'glassmorphism';
    else if (sessionStorage.getItem('themeId') == 'NslTheme5') this.selectedTheme = 'neumorphism';
    else if (sessionStorage.getItem('themeId') == 'NslTheme6') this.selectedTheme = 'retro';
    else this.selectedTheme = '';

    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: any) => {
        if (!this.solutionData) {
          this.getSolutionDataFromRoute(data);
        }
        // gtag('event', 'page_view', {
        //   page_path: data?.urlAfterRedirects,
        //   page_name: 'transaction-view',
        // });
      });
  }

  /* istanbul ignore next */
  routerEvent() {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data: any) => {
        // gtag('event', 'page_view', {
        //   page_path: data?.urlAfterRedirects,
        //   page_name: 'transaction-smart',
        // });
      });
  }

  detectMccPoupGsi() {
    this.transactionFacadeService.isMccPopup = true;
    this.transactionFacadeService.mccPopup$.pipe(takeUntil(this.unsubscribe$)).subscribe((mcc) => {
      if (mcc) {
        this.solEncodedData = mcc;
        this.solnData = atob(this.solEncodedData);
        if (
          !this.solutionData ||
          JSON.stringify(this.solutionData) !== this.solnData
        ) {
          this.detectActiveRoute(this.solnData);
        }
      }
    });
  }

  detectActiveRoute(solutionData: any, flag: boolean = true) {
    /* istanbul ignore next */
    let activeUserInfo = localStorage.ActiveUserInfo
      ? JSON.parse(localStorage.ActiveUserInfo)
      : null;
    let tenantName = localStorage.getItem('TenantName');
    /* istanbul ignore next */
    if (tenantName != 'b2c' && activeUserInfo?.planNFeatureMapping) {
      this.showAds =
        activeUserInfo?.planNFeatureMapping?.planName === 'AdBasedPlan'
          ? true
          : false;
    } else if (tenantName === 'b2c' || tenantName === 'rubihub') {
      this.showAds = true;
    }
    /* istanbul ignore next */
    this.previousSolutionData = this.solutionData
      ? JSON.parse(JSON.stringify(this.solutionData))
      : null;
    this.solutionData = solutionData ? JSON.parse(solutionData) : null;
    /* istanbul ignore next */
    let currentCuExecuting =
      localStorage.currentCuData &&
      !this.route.snapshot.params?.txnId &&
      !this.route.snapshot.params?.attributeValue &&
      JSON.parse(localStorage.currentCuData)?.gsiId === this.solutionData?.gsiId
        ? JSON.parse(localStorage.currentCuData)
        : null;
    /* istanbul ignore next */
    if (currentCuExecuting) {
      this.isReloaded = true;
      this.currentCuExecuting = currentCuExecuting;
    }
    /* istanbul ignore next */
    this.isCarnival = this.solutionData?.isCarnival;
    /* istanbul ignore next */
    this.isOnboarding = this.solutionData?.isOnboarding;
    /* istanbul ignore next */
    if (!this.solutionData?.bookId || this.solutionData?.bookId === 'null') {
      delete this.solutionData.bookId;
    }
    this.loader.hide();
    /* istanbul ignore next */
    /* istanbul ignore next */
    if (this.solutionData?.isDebug) {
      /* istanbul ignore next */
      this.isDebug = this.solutionData?.isDebug;
      this.transactionFacadeService.setDebug(this.isDebug);
      this.render.addClass(document.body, 'debugSpace');
    } else {
      this.transactionFacadeService.setDebug(false);
    }
    /* istanbul ignore next */
    this.transactionResume = this.solutionData?.isResumeTxn ? true : false;
    this.transactionEventResume = this.solutionData?.isResumeEventTxn
      ? true
      : false;
    /* istanbul ignore next */
    if (this.transactionEventResume) {
      this.isResume = false;
    }
    /* istanbul ignore next */
    if (this.solutionData && this.solutionData?.txnId) {
      /* istanbul ignore next */
      this.nextCURequestPost.transId = this.solutionData?.txnId;
    }
    /* istanbul ignore next */
    if (this.solutionData && this.solutionData?.triggerCuId) {
      /* istanbul ignore next */
      this.nextCURequestPost.triggerCuId = this.solutionData?.triggerCuId;
    }
    // this.route.snapshot.params
    /* istanbul ignore next */
    if (this.route.snapshot.params?.txnId) {
      this.redirected = true;
      this.nextCURequestPost.transId = this.route.snapshot.params?.txnId;
      // this.nextCURequestPost.txnData =
      //   sessionStorage.previousTxnData && sessionStorage.previousTxnData !== ''
      //     ? JSON.parse(sessionStorage.previousTxnData)
      //     : {};
      const rediData: any = [];
      this.redirectData = '';
      Object.keys(this.route.snapshot.queryParams)?.forEach((value) => {
        rediData.push(value + '=' + this.route.snapshot.queryParams[value]);
      });
      this.redirectData = rediData.join('&');
      this.currentEntity = Number(this.route.snapshot.params.entity);
      this.currentAttribute = Number(this.route.snapshot.params.attribute);
      if (this.currentAttribute && this.currentEntity) {
        this.getCUDetailsByid();
      } else if (flag) {
        this.getSelectedGSI();
      }
    } else if (flag) {
      this.getSelectedGSI();
    }
  }

  detectTransactionViewType() {
    this.transactionFacadeService.transactionViewType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          const { isReadOnly } = res;

          this.isReadOnlyMode = isReadOnly;
        }
      });
  }

  detectSolutionData() {
    this.transactionFacadeService.transactionSmartSolutionData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore else*/
        if (res) {
          this.previousSolutionData = this.solutionData
            ? { ...this.solutionData }
            : null;
          this.solutionData = res;
          this.isReloaded = false;
        }
      });
  }

  facetSearchFilter() {
    this.transactionFacadeService.facetSearchResult$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.res) {
          let ind = res.res.result.currentCU.layers.findIndex(
            (layer: any) => layer.type === 'information'
          );
          if (ind != -1) {
            this.currentCu.layers[ind] = res.res.result.currentCU.layers[ind];
          }
          let index = res.res.result.currentCU.layers.findIndex(
            (layer: any) => layer.type === 'physical'
          );
          this.findtargetphyentity(res.res.result.currentCU.layers[index]);
          this.currentCu.layers[index].entityList = this.currentCu.layers[
            index
          ].entityList.filter((ent: any) => ent.slot != 1);
          this.currentCu.layers[index].entityList.push(this.targetEnt);
          this.currentCu.layers[index].entityList = this.currentCu.layers[
            index
          ].entityList.flat();
          this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
          this.getDefaultLayoutConfig();
        }
        this.targetEnt = [];
      });
  }

  findtargetphyentity(data: any) {
    data?.entityList?.forEach((ent: any) => {
      if (ent.slot == 1) {
        this.targetEnt.push(ent);
      }
    });
  }

  detectLink() {
    this.transactionFacadeService.redirectWithoutErrorPopup$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.ignoreWarning = res;
          this.ignoreWarningEmiter.emit(this.ignoreWarning);
        }
      });
  }

  detectDebugInfo() {
    /*istanbul ignore next*/
    this.transactionFacadeService.debug$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.debugInfo = res.result;
          this.txnCulayer = res?.result?.txnCULayer;
          this.targetInfoMap =
            res?.result?.debugInfo?.changeDriverTargetInfoMap;
          this.sourceInfoMap =
            res?.result?.debugInfo?.changeDriverSourceInfoMap;
        }
      });
  }
  //commented since not been used //
  /*getLocationDetails() {
    this.transactionFacadeService.locationDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      let locationDetails = {
        ip: data.query,
        city: data.city,
        country: data.country,
        state: data.regionName,
      };
      this.getAds();
    });
  }*/

  getCUDetailsByid() {
    let triggerCUId;
    let gsiId;
    // let triggerCUId = sessionStorage.innerGSIid !== '' && this.solutionData.gsiId != sessionStorage.innerGSIid ? sessionStorage.innerGSIid : sessionStorage.currentCuID;
    /* istanbul ignore next */
    if (
      sessionStorage.innerGSIid !== '' &&
      this.solutionData?.gsiId != sessionStorage.innerGSIid
    ) {
      triggerCUId = sessionStorage.currentCuID;
      gsiId = sessionStorage.innerGSIid;
    } else {
      triggerCUId = sessionStorage.currentCuID;
      gsiId = this.solutionData?.gsiId;
    }
    this.changeUnitFacadeService.getCuDetailsByGSIId(
      gsiId,
      Number(triggerCUId)
    );
    this.changeUnitFacadeService.resumedChangeUnit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cuData: any) => {
        /* istanbul ignore next */
        if (cuData && cuData.result) {
          this.previousChangeUnit = cuData.result;
          /* istanbul ignore next */
          if (sessionStorage.layersData && sessionStorage.layersData !== '') {
            this.previousChangeUnit.layers = JSON.parse(
              sessionStorage.layersData
            );
          }
          this.getSelectedGSI();
        }
      });
  }

  /** Get the target entity from nested entities*/
  public findTargetEntity(res: any, type: any) {
    const infoIndex = this.currentCu.layers.findIndex(
      (x: any) => x.type === type
    );
    /* istanbul ignore next */
    this.currentCu.layers[infoIndex].entityList?.every(
      (ent: any, index: any) => {
        if (ent.name == res.entityName.split('$')[0]) {
          this.targetEntity = ent;
          return false;
        } else {
          this.getNestedEntityData(ent, res);
        }
        return true;
      }
    );
  }

  detectLocationDetails() {
    this.transactionFacadeService.googleMapSubject$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        /* istanbul ignore next */
        if (res?.map?.results?.myArrayList) {
          /* istanbul ignore next */
          for (
            let i = 0;
            i <
            res?.map.results?.myArrayList?.[0]?.map?.address_components
              ?.myArrayList?.length;
            i++
          ) {
            /* istanbul ignore next */
            let locationObject =
              res?.map.results?.myArrayList?.[0]?.map?.address_components
                ?.myArrayList?.[i]?.map;
            /* istanbul ignore next */
            /* istanbul ignore next */
            if (locationObject?.types?.myArrayList?.includes('country')) {
              this.gsiDataObj.geoLocation = {
                ...this.gsiDataObj.geoLocation,
                country: locationObject.long_name,
              };
            }
            /* istanbul ignore next */
            /* istanbul ignore next */
            if (
              locationObject?.types?.myArrayList?.includes(
                'administrative_area_level_1'
              )
            ) {
              this.gsiDataObj.geoLocation = {
                ...this.gsiDataObj.geoLocation,
                state: locationObject.long_name,
              };
            }
            /* istanbul ignore next */
            /* istanbul ignore next */
            if (
              locationObject?.types?.myArrayList?.includes(
                'administrative_area_level_2'
              )
            ) {
              this.gsiDataObj.geoLocation = {
                ...this.gsiDataObj.geoLocation,
                city: locationObject.long_name,
              };
            }
          }
          /* istanbul ignore next */
          this.callAdsMethod();
        } else {
          /* istanbul ignore next */
          this.callAdsMethod();
        }
      });
  }

  /** Get the target entity from nested entities*/
  getNestedEntityData(ent: any, entityDetails: any) {
    ent.nslAttributes?.every((val: any, ind: any) => {
      /* istanbul ignore next */
      if (val.type == 'entity') {
        /* istanbul ignore next */
        if (val.name == entityDetails.entityName) {
          this.targetEntity = val;
          return false;
        } else {
          this.getNestedEntityData(val, entityDetails);
        }
      }
      return true;
    });
  }

  entityDataForTable(event: any) {
    this.tempEntityList = event;
  }

  infoEntityDataForTable(event: any) {
    this.tempInfoEntityList = event;
  }

  deepColneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  formatTableData(
    multiEntityObj: any,
    prop: any,
    entityCountObj: any,
    finalArray: any,
    tableEntities?: any
  ) {
    let rowObj = {};
    let multiEntArry: any = [];
    /* istanbul ignore next */
    if (multiEntityObj) {
      rowObj = JSON.parse(JSON.stringify(multiEntityObj));
    }
    /* istanbul ignore next */
    const attrCount =
      multiEntityObj?.nslAttributes?.length / entityCountObj[prop];
    /* istanbul ignore next */
    multiEntityObj?.nslAttributes?.forEach((x: any, index: any) => {
      multiEntArry.push(x);
      if ((index + 1) % attrCount === 0) {
        const entIndex = (index + 1) / attrCount;
        if (tableEntities[entIndex - 1]?.recordId) {
          rowObj['recordId'] = tableEntities[entIndex - 1].recordId;
        } else {
          delete rowObj['recordId'];
        }
        if (tableEntities[entIndex - 1]?.txnRecordId) {
          rowObj['txnRecordId'] = tableEntities[entIndex - 1].txnRecordId;
        } else {
          delete rowObj['txnRecordId'];
        }
        rowObj['nslAttributes'] = multiEntArry;
        finalArray.push(rowObj);
        rowObj = {};
        rowObj = JSON.parse(JSON.stringify(multiEntityObj));
        multiEntArry = [];
      }
    });
  }
  checkEntitieswithCardandTable(attributesArray: any, type: string) {
    /* istanbul ignore next */
    let index = this.originalEntitylist?.layers?.findIndex(
      (layer: any) => layer?.type == type
    );
    /* istanbul ignore next */
    const entityCountObj =
      type === 'physical'
        ? this.find_duplicate_in_array(this.tempEntityList)
        : this.find_duplicate_in_array(this.tempInfoEntityList);
    /* istanbul ignore next */
    for (let prop in entityCountObj) {
      /* istanbul ignore next */
      Object.keys(entityCountObj[prop])?.forEach((key) => {
        if (entityCountObj[prop][key] >= 2) {
          let entIndex;
          let multiEntityObj: any;

          for (let i = 0; i < attributesArray?.length; i++) {
            const ent = attributesArray[i];
            /* istanbul ignore next */
            if (ent?.name?.split('$')?.[0] == key && ent?.slot == prop) {
              multiEntityObj = this.deepColneObject(ent);
              /* istanbul ignore next */
              let tableEntities = this.originalEntitylist?.layers?.[
                index
              ]?.entityList?.filter(
                (x: any) => x?.name === multiEntityObj?.name?.split('$')?.[0] && x?.slot === multiEntityObj?.slot
              );
              attributesArray.splice(i, 1);
              let len = this.tempEntityList?.length - tableEntities?.length;
              if (len > 0) {
                tableEntities = [];
                this.tempEntityList.forEach((x: any, index: any) => {
                  if (x.name == multiEntityObj?.name?.split('$')?.[0]) {
                    tableEntities.push(x);
                  }
                });
              }
              /* istanbul ignore next */
              this.formatTableData(
                multiEntityObj,
                key,
                entityCountObj[prop],
                attributesArray,
                tableEntities
              );
              break;
            } else {
              for (let j = 0; j < ent.nslAttributes?.length; j++) {
                /* istanbul ignore next */
                if (
                  ent?.nslAttributes?.[j]?.type == 'entity' &&
                  ent?.nslAttributes?.[j]?.name?.split('$')?.[0] == key &&
                  ent.nslAttributes[j].ent_index == prop
                ) {
                  multiEntityObj = this.deepColneObject(ent.nslAttributes[j]);
                  let tableEntities: any;
                  let tempEntities: any;
                  /* istanbul ignore next */
                  this.originalEntitylist.layers[index].entityList.forEach(
                    (list: any) => {
                      tableEntities = list.nslAttributes.filter(
                        (value: any) =>
                          ent?.nslAttributes?.[j]?.type == 'entity' &&
                          ent?.nslAttributes?.[j]?.name?.split('$')?.[0] ==
                            value?.slot
                      );
                    }
                  );
                  /* istanbul ignore next */
                  this.tempEntityList?.forEach((list: any) => {
                    tempEntities = list?.nslAttributes?.filter(
                      (value: any) =>
                        ent?.nslAttributes?.[j]?.type == 'entity' &&
                        ent?.nslAttributes?.[j]?.name?.split('$')?.[0] ==
                          value?.slot
                    );
                  });
                  ent.nslAttributes.splice(j, 1);
                  let len = tempEntities?.length - tableEntities?.length;
                  if (len > 0) {
                    tempEntities.forEach((x: any, index: any) => {
                      if (index >= len) {
                        tableEntities.push(x);
                      }
                    });
                  }
                  /* istanbul ignore next */
                  this.formatTableData(
                    multiEntityObj,
                    key,
                    entityCountObj[prop],
                    ent.nslAttributes,
                    tableEntities
                  );
                  break;
                }
              }
            }
          }
        }
      });
    }
  }

  find_duplicate_in_array(array: any) {
    const count: any = [];
    /* istanbul ignore next */
    array?.forEach((item: any) => {
      /* istanbul ignore next */
      if (
        (item?.configuration && item?.configuration?.type == 'table') ||
        (item?.configuration?.type == 'verticalTable' && item?.configuration?.configurationData?.advanceTableFeatures?.multiRowsVerticalTable) ||
        item?.configuration?.type == 'card' ||
        item?.configuration?.type == 'cardWithTemplate' ||
        item?.configuration?.type == 'tableWithLabels'
      ) {
        /* istanbul ignore next */
        if (count?.[item?.ent_index] && count?.[item?.ent_index][item?.name.split('$')?.[0]]) {
          /* istanbul ignore next */
          count[item?.ent_index][item?.name?.split('$')?.[0]] += 1;
          return;
        }
        count[item.ent_index] = { ...count[item.ent_index], [item.name.split('$')?.[0]]: 1 };
      } else if (item?.type == 'entity') {
        /* istanbul ignore next */
        item?.nslAttributes?.forEach((attr: any) => {
          if (
            (attr?.type == 'entity' && attr?.configuration && attr?.configuration?.type == 'table') ||
            (attr?.configuration?.type == 'verticalTable' && attr?.configuration?.configurationData?.advanceTableFeatures?.multiRowsVerticalTable) ||
            attr?.configuration?.type == 'card' ||
            attr?.configuration?.type == 'cardWithTemplate' ||
            attr?.configuration?.type == 'tableWithLabels'
          ) {
            if (count[attr.ent_index] && count?.[attr?.ent_index][attr.name.split('$')?.[0]]) {
              count[attr.ent_index][attr.name.split('$')?.[0]] += 1;
              return;
            }
            count[attr.ent_index] = { ...count[attr.ent_index], [attr.name.split('$')?.[0]]: 1 };
          } else if (attr?.type == 'entity') {
            let countObj = this.find_duplicate_in_array(attr.nslAttributes);
            countObj.forEach((x: any) => {
              count[attr.ent_index] = x;
            });
          }
        });
      }
    });
    return count;
  }

  getEventsResponse() {
    this.eventsService.cuEventsResponse$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        let data = res.res;
        /* istanbul ignore next */
        data.result.currentCU.layers?.forEach((layer: any) => {
          layer.entityList = this.renameEntity(layer.entityList, true);
        });
        this.currentCu = data.result.currentCU;
        this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
        this.getDefaultLayoutConfig();
      });
  }

  /** setting nested entity data for events attributes */
  /* istanbul ignore next */
  public setNestedEntityData(
    sourceEntity: any,
    targetEntity: any,
    entityDetails: any,
    type?: any
  ) {
    let count: any = this.getTableRowUIcontrolLenth(entityDetails?.entityName);
    /* istanbul ignore next */
    let attributeIndex: any = parseInt(entityDetails?.attrName?.split('$')[1]);
    /* istanbul ignore next */
    if (sourceEntity?.name == targetEntity?.name?.split('$')?.[0]) {
      /* istanbul ignore next */
      sourceEntity?.nslAttributes?.forEach((val: any, ind: any) => {
        /* istanbul ignore next */
        if (entityDetails?.isTableConfig) {
          targetEntity?.nslAttributes?.every((attr: any, index: any) => {
            if (attributeIndex < count) {
              if (attr.id == val.id && val?.values?.length > 0) {
                attr.values = val?.values;
                /* istanbul ignore next */
                if (val?.recordId) {
                  attr['recordId'] = val.recordId;
                }
                return false;
              }
            } else if (
              index < (Math.floor(attributeIndex / count) + 1) * count &&
              index > Math.floor(attributeIndex / count) * count - 1
            ) {
              if (attr.id == val.id && val?.values?.length > 0) {
                attr.values = val?.values;
                /* istanbul ignore next */
                if (val?.recordId) {
                  attr['recordId'] = val.recordId;
                }
                return false;
              }
            }
            return true;
          });
        } else {
          /* istanbul ignore next */
          const foundAttributeIndex = targetEntity?.nslAttributes?.findIndex(
            (targetAttr: any) => targetAttr.id == val.id
          );
          /* istanbul ignore next */
          if (foundAttributeIndex != -1 && val?.values?.length > 0) {
            targetEntity.nslAttributes[foundAttributeIndex].values = val.values;
            /* istanbul ignore next */
            if (val.recordId) {
              targetEntity.nslAttributes[foundAttributeIndex]['recordId'] =
                val.recordId;
            }
          }
        }
      });
    } else {
      /* istanbul ignore next */
      sourceEntity?.nslAttributes?.forEach((val: any, ind: any) => {
        /* istanbul ignore next */
        if (val.type == 'entity') {
          this.setNestedEntityData(val, targetEntity, entityDetails, type);
        }
      });
    }
    setTimeout(() => {
      this.currentCu.layers.forEach((layer: any) => {
        this.checkEntitieswithCardandTable(layer.entityList, layer.type);
        this.entityAttributesOrder(layer.entityList);
      });
      this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
      this.getDefaultLayoutConfig();
    }, 300);
  }

  /**set the original order */
  entityAttributesOrder(changedFormValues: any) {
    changedFormValues?.sort((x: any, y: any) => {
      return x.attr_order_num - y.attr_order_num;
    });
    /* istanbul ignore next */
    changedFormValues?.forEach((ent: any) => {
      ent?.nslAttributes?.sort((x: any, y: any) => {
        return x.attr_order_num - y.attr_order_num;
      });
      /* istanbul ignore next */
      ent?.nslAttributes?.forEach((attr: any) => {
        if (attr.type == 'entity') {
          this.nestedEntityAttributesOrder(attr);
        }
      });
    });
  }

  /**set the original order  for nested*/
  nestedEntityAttributesOrder(ent: any) {
    ent?.nslAttributes.sort((x: any, y: any) => {
      return x.attr_order_num - y.attr_order_num;
    });
    /* istanbul ignore next */
    ent?.nslAttributes?.forEach((attribute: any) => {
      if (attribute.type == 'entity') {
        this.nestedEntityAttributesOrder(attribute);
      }
    });
  }

  getTableRowUIcontrolLenth(entityName: any) {
    /* istanbul ignore next */
    this.tempCurrentCu = this.originalEntitylist
      ? JSON.parse(JSON.stringify(this.originalEntitylist))
      : {};
    let count = 0;
    /* istanbul ignore next */
    const infoIndex = this.tempCurrentCu?.layers?.findIndex(
      (x: any) => x.type === 'physical'
    );
    /* istanbul ignore next */
    if (infoIndex !== -1) {
      this.tempCurrentCu?.layers[infoIndex]?.entityList?.every((ent: any) => {
        /*  istanbul ignore next  */
        if (ent?.name === entityName?.split('$')?.[0]) {
          /*  istanbul ignore next */
          count = ent?.nslAttributes?.length;
          return false;
        } else {
          /*  istanbul ignore next  */
          ent.nslAttributes.every((attr: any) => {
            if (
              attr?.type == 'entity' &&
              attr?.name === entityName?.split('$')?.[0]
            ) {
              /*  istanbul ignore next */
              count = attr?.nslAttributes?.length;
              return false;
            }
            return true;
          });
          if (count > 0) {
            return false;
          }
        }
        return true;
      });
    }
    return count;
  }

  /**
   * Gets dependent dropdown responce
   */
  getDependentDropdownResponce() {
    this.eventsService.dependentDropdownResponse$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        if (this.isReadOnlyMode !== true) {
          /* istanbul ignore next */
          this.setCurrentCuDataOnDependencyChange(
            data?.res?.result,
            data?.entityDetails,
            data?.expressionEvent
          );
        }
      });
  }

  getDebugValue() {
    this.transactionFacadeService.debugValues$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        /*istanbul ignore next*/
        if (data) {
          this.debugValue = data?.result?.currentCU;
          this.cuDetails(this.debugValue);
        }
      });
  }

  /**
   * Sets current cu data on dependency change
   * @param responseData
   */

  findEntityIndex(
    targetEntityName: any,
    infoIndex: any,
    entityDetails: any,
    targetEntityId: any
  ): number {
    let entityIndex: any;
    if (targetEntityName !== entityDetails?.entityName?.split('$')?.[0]) {
      entityIndex = this.currentCu?.layers[infoIndex]?.entityList?.findIndex(
        (ele: any) =>
          ele.id === Number(targetEntityId) &&
          ele.name?.split('$')?.[0] == targetEntityName
      );
    } else {
      entityIndex = this.currentCu?.layers[infoIndex]?.entityList?.findIndex(
        (ele: any) =>
          ele.id === Number(targetEntityId) &&
          ele.name == entityDetails?.entityName
      );
    }
    return entityIndex;
  }

  setCurrentCuDataOnDependencyChange(
    responseData: any,
    entityDetails: any,
    isExpressionEvent?: boolean
  ) {
    /* istanbul ignore next */
    let count: any = this.getTableRowUIcontrolLenth(entityDetails?.entityName);
    /* istanbul ignore next */
    let attributeIndex: any = parseInt(
      entityDetails?.attrName?.split('$')?.[1]
    );
    /* istanbul ignore next */
    if (responseData && responseData?.length > 0) {
      responseData?.forEach((attribute: any) => {
        /* istanbul ignore next */
        if (attribute?.referenceValueType == 'VALUE' && !attribute?.values) {
          attribute['values'] = [];
        } else if (attribute?.referenceValueType != 'VALUE' && !attribute?.options) {
          attribute['options'] = [];
        }
        let targetLayer,
          targetArrLength,
          targetEntityId: any,
          targetAtributeId: any,
          targetEntityName: any,
          targetArr: any,
          targetEnt: any;
        if (isExpressionEvent) {
          targetArr = attribute?.targetContextualId
            ?.split(/[EN.AT]/)
            .filter((el: any) => el);
          targetEnt = attribute?.targetContextualName
            ?.split('.')
            .filter((el: any) => el);
        } else {
          targetArr = attribute?.targetAttributeId
            ?.split(/[EN.AT]/)
            .filter((el: any) => el);
          targetEnt = attribute?.targetAttributeName
            ?.split('.')
            .filter((el: any) => el);
        }
        targetArrLength = targetArr?.length;
        targetLayer = targetArr?.[0];
        targetEntityId = targetArr?.[targetArrLength - 2];
        targetAtributeId = targetArr?.[targetArrLength - 1];
        targetEntityName = targetEnt?.[targetEnt?.length - 2];
        /*istanbul ignore next */
        if (targetLayer && targetAtributeId) {
          let physicalIndex = this.currentCu?.layers?.findIndex(
            (x: any) => x.type === 'physical'
          );
          let phyEntityIndex = this.findEntityIndex(
            targetEntityName,
            physicalIndex,
            entityDetails,
            targetEntityId
          );
          let informationIndex = this.currentCu?.layers?.findIndex(
            (x: any) => x.type == 'information'
          );
          let infoEntityIndex = this.findEntityIndex(
            targetEntityName,
            informationIndex,
            entityDetails,
            targetEntityId
          );
          if (phyEntityIndex == -1) {
            if (infoEntityIndex && infoEntityIndex !== -1) {
              physicalIndex = informationIndex;
              phyEntityIndex = infoEntityIndex;
            }
          }
          if (phyEntityIndex !== -1) {
            this.currentCu?.layers[physicalIndex]?.entityList[
              phyEntityIndex
            ]?.nslAttributes?.every((attr: any, ind: any) => {
              /* istanbul ignore next */
              if (
                entityDetails.isTableConfig &&
                targetEntityName === entityDetails?.entityName?.split('$')?.[0]
              ) {
                if (attributeIndex < count) {
                  if (
                    targetAtributeId == attr?.id &&
                    attribute?.referenceValueType !== 'OPTIONS'
                  ) {
                    this.currentCu.layers[physicalIndex].entityList[
                      phyEntityIndex
                    ].nslAttributes[ind].values = attribute?.values;
                    return false;
                  } else if (
                    targetAtributeId == attr?.id &&
                    attribute?.referenceValueType === 'OPTIONS'
                  ) {
                    this.currentCu.layers[physicalIndex].entityList[
                      phyEntityIndex
                    ].nslAttributes[ind].options = attribute?.options;
                    return false;
                  }
                } else if (
                  ind < (Math.floor(attributeIndex / count) + 1) * count &&
                  ind > Math.floor(attributeIndex / count) * count - 1
                ) {
                  if (
                    targetAtributeId == attr?.id &&
                    attribute?.referenceValueType !== 'OPTIONS'
                  ) {
                    this.currentCu.layers[physicalIndex].entityList[
                      phyEntityIndex
                    ].nslAttributes[ind].values = attribute?.values;
                    return false;
                  } else if (
                    targetAtributeId == attr?.id &&
                    attribute?.referenceValueType === 'OPTIONS'
                  ) {
                    this.currentCu.layers[physicalIndex].entityList[
                      phyEntityIndex
                    ].nslAttributes[ind].options = attribute?.options;
                    // this.currentCu.layers[infoIndex].entityList[entityIndex].nslAttributes[ind].values = [];
                    return false;
                  }
                }
              } else {
                if (
                  targetAtributeId == attr?.id &&
                  attribute?.referenceValueType !== 'OPTIONS'
                ) {
                  this.currentCu.layers[physicalIndex].entityList[
                    phyEntityIndex
                  ].nslAttributes[ind].values = attribute?.values;
                  if (
                    this.currentCu?.layers[physicalIndex]?.entityList[
                      phyEntityIndex
                    ]?.nslAttributes[ind]?.triggerConditionalPotentiality
                  ) {
                    this.setDataDetails(
                      this.currentCu.layers[physicalIndex].entityList[
                        phyEntityIndex
                      ],
                      ind
                    );
                  }
                } else if (
                  targetAtributeId == attr?.id &&
                  attribute?.referenceValueType === 'OPTIONS'
                ) {
                  this.currentCu.layers[physicalIndex].entityList[
                    phyEntityIndex
                  ].nslAttributes[ind].options = attribute?.options;
                  // this.currentCu.layers[infoIndex].entityList[entityIndex].nslAttributes[ind].values = [];
                }
              }
              return true;
            });
          } else {
            this.currentCu?.layers[physicalIndex]?.entityList?.forEach(
              (ele: any) => {
                this.setNestedEntityDataFordependentDropdown(
                  ele,
                  entityDetails,
                  attribute,
                  targetEntityId,
                  targetAtributeId,
                  attributeIndex,
                  count,
                  targetEntityName
                );
              }
            );
            if (infoEntityIndex && informationIndex == -1) {
              this.currentCu?.layers[informationIndex]?.entityList?.forEach(
                (ele: any) => {
                  this.setNestedEntityDataFordependentDropdown(
                    ele,
                    entityDetails,
                    attribute,
                    targetEntityId,
                    targetAtributeId,
                    attributeIndex,
                    count,
                    targetEntityName
                  );
                }
              );
            }
          }
        }
      });
      this.currentCu?.layers?.forEach((layer: any) => {
        this.checkEntitieswithCardandTable(layer?.entityList, layer?.type);
        this.entityAttributesOrder(layer?.entityList);
      });
      this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
      this.getDefaultLayoutConfig();
      /* istanbul ignore next */
      if (this.valueChanged) {
        this.eventsService.setTriggerEvent(this.valueChanged);
      }
      this.valueChanged = null;
    }
  }

  /** setting nested entity data for dependency dropdown */
  public setNestedEntityDataFordependentDropdown(
    ele: any,
    entityDetails: any,
    attribute: any,
    targetEntityId: any,
    targetAtributeId: any,
    attributeIndex: any,
    count: any,
    targetEntityName: any
  ) {
    /* istanbul ignore next */
    ele?.nslAttributes?.forEach((val: any, ind: any) => {
      if (val && val.type == 'entity') {
        const entityFound =
          targetEntityName == entityDetails?.entityName?.split('$')?.[0]
            ? val?.name == entityDetails?.entityName
            : val?.name?.split('$')?.[0] == targetEntityName;
        if (val.id === Number(targetEntityId) && entityFound) {
          val?.nslAttributes?.every((attr: any, ind: any) => {
            if (
              entityDetails.isTableConfig &&
              targetEntityName === entityDetails?.entityName.split('$')?.[0]
            ) {
              if (attributeIndex < count) {
                if (
                  targetAtributeId == attr.id &&
                  attribute.referenceValueType !== 'OPTIONS'
                ) {
                  val.nslAttributes[ind].values = attribute.values;
                  return false;
                } else if (
                  targetAtributeId == attr.id &&
                  attribute.referenceValueType === 'OPTIONS'
                ) {
                  val.nslAttributes[ind].options = attribute.options;
                  return false;
                }
              } else if (
                ind < (Math.floor(attributeIndex / count) + 1) * count &&
                ind > Math.floor(attributeIndex / count) * count - 1
              ) {
                if (
                  targetAtributeId == attr.id &&
                  attribute.referenceValueType !== 'OPTIONS'
                ) {
                  val.nslAttributes[ind].values = attribute.values;
                  return false;
                } else if (
                  targetAtributeId == attr.id &&
                  attribute.referenceValueType === 'OPTIONS' &&
                  attr.ent_index === entityDetails?.ent_index
                ) {
                  val.nslAttributes[ind].options = attribute.options;
                  return false;
                }
              }
            } else {
              if (
                targetAtributeId == attr.id &&
                attribute.referenceValueType !== 'OPTIONS'
              ) {
                attr.values = attribute.values;
                if (val.nslAttributes[ind].triggerConditionalPotentiality) {
                  this.setDataDetails(val, ind);
                }
              } else if (
                targetAtributeId == attr.id &&
                attribute.referenceValueType === 'OPTIONS'
              ) {
                if (
                  entityDetails.ent_index &&
                  attr.ent_index === entityDetails.ent_index
                ) {
                  attr.options = attribute.options;
                } else if (!entityDetails.ent_index) {
                  attr.options = attribute.options;
                }
              }
            }
            return true;
          });
        } else {
          this.setNestedEntityDataFordependentDropdown(
            val,
            entityDetails,
            attribute,
            targetEntityId,
            targetAtributeId,
            attributeIndex,
            count,
            targetEntityName
          );
        }
      }
    });
  }

  setDataDetails(entity: any, index: any) {
    const data = {
      attributeId: entity.nslAttributes[index].id,
      isTableConfig:
        entity?.configuration?.type == 'table' ||
        entity?.configuration?.type == 'card'
          ? true
          : false,
      attrName: entity.nslAttributes[index].name,
      eventType: 'ON_CHANGE',
      entityName: entity.name,
      entityId: entity?.id,
      isMulti: entity?.isMulti,
    };
    this.valueChanged = data;
  }

  detectNextListChangeUnit() {
    this.changeUnitFacadeService.changeUnitListByGSI$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((changeUnitList: Gsi) => {
        this._gsiData = this.gsiData
          ? JSON.parse(JSON.stringify(this.gsiData))
          : null;
        this.gsiData = {
          ...this.gsiData,
          changeUnits: changeUnitList,
          name: changeUnitList.name,
          displayName: changeUnitList.displayName,
        };
        /* istanbul ignore next */
        const data = {
          ...this.nextCURequestPost,
          gsiId: this.solutionData?.gsiId,
        };
        /* istanbul ignore next */
        const foundGSI = changeUnitList.solutionLogic?.find(
          (x: any) =>
            x.DATA?.cuType === 'GSI' &&
            x.DATA?.referencedChangeUnit === this.solutionData?.gsiId
        );
        let triggerCuId = Number(sessionStorage.currentCuID);
        /* istanbul ignore next */
        if (foundGSI) {
          /* istanbul ignore next */
          const foundCU = foundGSI?.DATA?.solutionLogic?.find(
            (x: any) =>
              x.DATA?.referencedChangeUnit ===
              Number(sessionStorage.currentRefCuID)
          );
          /* istanbul ignore next */
          if (foundCU) {
            /* istanbul ignore next */
            triggerCuId = foundCU.DATA?.id;
            /* istanbul ignore next */
            this.previousChangeUnit.index = foundCU.DATA?.index;
          }
        }
        // this.transactionFacadeService.getIpAddress();
        /* istanbul ignore next */
        if (this.showAds) {
          // this.transactionFacadeService.geolocation();
          this.getAds();
        }
        /* istanbul ignore next */
        if (this.redirected && this.previousChangeUnit) {
          // this.previousChangeUnit.id = triggerCuId;
          /* istanbul ignore next */
          this.previousChangeUnit.contextualId = sessionStorage?.contextualId;
          /* istanbul ignore next */
          this.previousChangeUnit.layers?.forEach((layer: any) => {
            const foundEntity = layer?.entityList?.find(
              (x: any) => x.id === this.currentEntity
            );
            /* istanbul ignore next */
            if (foundEntity) {
              let foundAttribute = foundEntity.nslAttributes?.find(
                (x: any) => x.id === this.currentAttribute
              );
              /* istanbul ignore next */
              if (foundAttribute) {
                foundAttribute.values = [this.redirectData];
              }
            }
          });
          data.currentCU = this.previousChangeUnit;
        }
        /* istanbul ignore next */
        this.nextCURequestPost = data ? JSON.parse(JSON.stringify(data)) : null;
        /* istanbul ignore next */
        this.nextCURequestPost.gsiMasterId = changeUnitList?.masterId;
        this.nextCURequestPost.gsiId = changeUnitList?.id;
        this.getNextChangeUnit();
      });
  }

  getTriggerCondition() {
    this.transactionFacadeService.ConditionalPotentialityAttr$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: any) => {
      const eventData = this.transactionFacadeService.getAttributeDataForEvents();
      /* istanbul ignore next */
      if (eventData) {
        /* istanbul ignore next */
        this.eventsService.setEvent(data?.entityDetails);
      }
      /* istanbul ignore next */
      if (this.isReadOnlyMode !== true) {
        /* istanbul ignore next */
        if (data) {
          this.updateCurrentCuData(data.res, data.entityDetails);
        }
      }
    });
  }

  /**
   * Checks for on change events
   */
  checkForOnChangeEvents(eventData: any, data: any) {
    const { attributeData, txnData } = eventData;
    /* istanbul ignore next  */
    if (
      data?.currentCU &&
      data?.currentCU?.eventCUList &&
      data?.currentCU?.eventCUList?.length > 0
    ) {
      /* istanbul ignore next */
      let index = data?.currentCU?.eventCUList?.findIndex(
        (event: any) =>
          event?.changeDriverId === attributeData?.attributeId &&
          event?.eventType === attributeData?.eventType &&
          event?.eventType === 'ON_CHANGE'
      );
      /* istanbul ignore next */
      if (index !== -1) {
        const gsiId = data?.currentCU?.eventCUList?.[index]?.gsiId;
        const eventCuId = data?.currentCU?.eventCUList?.[index]?.id;
        data.gsiId = gsiId;
        data.eventCuId = eventCuId;
        if (data?.currentCU?.eventCUList[index].isGsi) {
          this.eventsService.changeUnitEvents(
            data,
            data?.currentCU?.id,
            attributeData,
            data?.currentCU?.layers
          );
        } else {
          this.eventsService.getDependentDropdownDetails(
            data,
            data?.currentCU?.eventCUList[index].eventType,
            data?.currentCU?.eventCUList[index].changeDriverId,
            attributeData,
            this.dropdownPageNo,
            this.dropdownpagesize
          );
          this.transactionFacadeService.depDropdownPayloadOnChange = {
            ...data,
            type: 'ON_CHANGE',
          };
        }
      }
    }
  }

  public updateCurrentCuData(data: any, entityDetails: any) {
    /* istanbul ignore next */
    const informationLayerData = data?.currentCU?.layers?.find((x: any) => {
      return x.type === 'information';
    });
    /* istanbul ignore next */
    const physicalLayerData = data?.currentCU?.layers?.find((x: any) => {
      return x.type === 'physical';
    });
    /* istanbul ignore next */
    this.currentCu?.layers?.forEach((layer: any, index: any) => {
      /* */
      if (layer.type == 'physical' && physicalLayerData) {
        //Added conditions for the events
        this.currentCu.layers[index] = physicalLayerData;
        //this.findTargetEntity(entityDetails);
        //this.checkForConditionalAttributes(physicalLayerData, this.targetEntity, entityDetails);
      }
      /* */
      if (layer.type == 'information' && informationLayerData) {
        //Added conditions for the events
        this.currentCu.layers[index] = informationLayerData;
        //this.findTargetEntity(entityDetails);
        //this.checkForConditionalAttributes(informationLayerData, this.targetEntity, entityDetails);
      }
    });
    /* istanbul ignore next */
    this.currentCu = this.currentCu
      ? JSON.parse(JSON.stringify(this.currentCu))
      : null;
    this.getDefaultLayoutConfig();
    this.eventsService.clearOnLoadEvents();
  }
  /* conditional potentiality at transaction level ends here*/

  /**
   * Checks for conditional attributes
   * @param physicalLayerData layer data from the conditional API call
   * @param layer current cu layer data
   */
  public checkForConditionalAttributes(
    sourceData: any,
    targetEntity: any,
    entityDetails: any
  ) {
    /* istanbul ignore next */
    sourceData.entityList?.forEach((entity: any) => {
      //let foundEntity = targetData.entityList?.find((ele: any) => ele.id === entity.id && ele.name === entity.name);
      if (entity?.name == targetEntity?.name?.split('$')?.[0]) {
        this.setNestedConditonalAttributes(entity, targetEntity, entityDetails);
      } else {
        this.checkNestedConditionalEntity(entity, targetEntity, entityDetails);
      }
    });
  }

  /** Set the events res data to existing entities from event res nested entities*/
  public checkNestedConditionalEntity(
    sourceData: any,
    targetEntity: any,
    entityDetails: any
  ) {
    /*  */
    /* istanbul ignore next */
    sourceData?.nslAttributes?.forEach((entity: any, ind: any) => {
      /* istanbul ignore next */
      if (entity.type == 'entity') {
        if (entity?.name == targetEntity?.name?.split('$')?.[0]) {
          this.setNestedConditonalAttributes(
            entity,
            targetEntity,
            entityDetails
          );
        } else {
          this.checkNestedConditionalEntity(
            entity,
            targetEntity,
            entityDetails
          );
        }
      }
    });
  }

  /** setting nested entity data for conditional attributes */
  public setNestedConditonalAttributes(
    sourceEntity: any,
    targetEntity: any,
    entityDetails: any
  ) {
    //let count:any = this.getTableRowUIcontrolLenth(entityDetails.entityName);
    //let attributeIndex:any = parseInt(entityDetails?.attrName.split('$')[1]);
    let attrIndex: any = 0;
    /* istanbul ignore next */
    sourceEntity.nslAttributes?.forEach((val: any, ind: any) => {
      /* if(entityDetails.isTableConfig){
        if(ind >= attributeIndex && ind <= (attributeIndex+count)) {
          const foundAttr = targetEntity.nslAttributes[ind].id == val.id ? targetEntity.nslAttributes[ind] : null;//targetEntity.nslAttributes?.find((targetAttr: any) => targetAttr.id == val.id);
          if (foundAttr) {
            foundAttr.values = val.values;
            foundAttr.isInPotentiality = val.isInPotentiality;
            if (val.recordId) {
              foundAttr['recordId'] = val.recordId;
            }
          } else {
            const index = targetEntity.nslAttributes[ind].id == entityDetails.attributeId ? ind : -1;
            if (index != -1) {
              ++attrIndex;
              targetEntity.nslAttributes.splice(index + attrIndex, 0, val); //.push(val);
            } else {
              targetEntity.nslAttributes.push(val);
            }
          }
        } */
      //}else {
      /* istanbul ignore next */
      const foundAttr = targetEntity.nslAttributes?.find(
        (targetAttr: any) => targetAttr.id == val.id
      );
      /*  */
      if (foundAttr) {
        foundAttr.values = val.values;
        foundAttr.isInPotentiality = val.isInPotentiality;
        /* istanbul ignore next */
        if (val.recordId) {
          foundAttr['recordId'] = val.recordId;
        }
      } else {
        const index = targetEntity.nslAttributes.findIndex(
          (targetAttr: any) => targetAttr.id == entityDetails.attributeId
        );
        /* istanbul ignore next */
        if (index != -1) {
          ++attrIndex;
          targetEntity.nslAttributes.splice(index + attrIndex, 0, val); //.push(val);
        } else {
          targetEntity.nslAttributes.push(val);
        }
      }
      //}
    });
  }

  setSelectedCuLayersValues(
    selectedCuLayer: any,
    layer: any,
    isForEvents?: boolean
  ) {
    /* istanbul ignore next */
    selectedCuLayer?.participatingItems?.forEach(
      (entity: any, index: number) => {
        /* istanbul ignore next */
        if (entity?.item && entity?.item?.DATA) {
          entity.item.DATA?.nslAttributes?.forEach((attr: any) => {
            /* istanbul ignore next */
            const foundAttr = layer?.txnSlotItems?.[
              index
            ]?.item?.DATA?.transEntityRecords?.[0]?.txnNslAttribute?.find(
              (y: any) => y?.nslAttributeID === attr?.id
            );
            /* istanbul ignore next */
            const entityTxnId =
              layer?.txnSlotItems?.[index]?.item?.DATA?.transEntityRecords?.[0]
                ?.id;
            /* istanbul ignore next */
            if (entityTxnId) {
              entity.item.DATA.txnId = entityTxnId;
            }
            /* istanbul ignore next */
            if (foundAttr) {
              this.recursiveAttrMethod(
                attr,
                entity,
                layer,
                isForEvents,
                foundAttr
              );
            }
          });
          //Commented as because of this data was getting appened if there are same attributes.
          // else {
          //   this.insertEntityAttributeValues(entity, layer, isForEvents);
          // }
        }
      }
    );
  }

  recursiveAttrMethod(
    attr: any,
    entity: any,
    layer: any,
    isForEvents: boolean,
    foundAttr: any
  ) {
    /* istanbul ignore next */
    if (attr?.attributeType?.type !== 'entity') {
      this.assignattributeValues(attr, entity, layer, isForEvents, foundAttr);
    } else {
      /* istanbul ignore next */
      attr?.generalEntity?.nslAttributes?.forEach((subAttr: any) => {
        /* istanbul ignore next */
        const foundSubAttr = foundAttr?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.find(
          (y: any) => y?.nslAttributeID === subAttr?.id
        );
        this.recursiveAttrMethod(
          subAttr,
          entity,
          layer,
          isForEvents,
          foundSubAttr
        );
      });
    }
  }

  assignattributeValues(
    attr: any,
    entity: any,
    layer: any,
    isForEvents: boolean,
    foundAttr: any
  ) {
    /* istanbul ignore next */
    if (
      attr.attributeType?.properties &&
      attr.attributeType?.properties?.sourceValues &&
      attr.attributeType?.properties?.sourceValues.trim() !== ''
    ) {
      /*  */
      const attributeValues = JSON.parse(
        attr.attributeType?.properties?.sourceValues
      );
      /* istanbul ignore next */
      if (attributeValues && attributeValues?.length > 0) {
        attr.options = attributeValues;
      }
      // removed else condition as values are not assign in multiple options scenario.
      /* istanbul ignore next */
      if (foundAttr) {
        this.addValuesToTheFoundAttr(isForEvents, attr, foundAttr);
      }
      /* istanbul ignore next */
    } else if (foundAttr) {
      /*  */
      this.addValuesToTheFoundAttr(isForEvents, attr, foundAttr);
    }
  }

  addValuesToTheFoundAttr(isForEvents: boolean, attr: any, foundAttr: any) {
    /* istanbul ignore next */
    if (foundAttr?.id) {
      attr.txnId = foundAttr.id;
    }
    /* istanbul ignore next */
    if (!isForEvents) {
      /* istanbul ignore next */
      if (
        foundAttr?.values?.length > 0 &&
        this.checkForContentUrl(foundAttr?.values?.[0])
      ) {
        /* istanbul ignore next */
        attr.values = [JSON.parse(foundAttr?.values?.[0])?.contentUrl];
      } else {
        attr.values = foundAttr.values;
      }
    } else {
      /* istanbul ignore next */
      if (foundAttr && foundAttr?.values?.length > 0) {
        attr.values = foundAttr.values;
      }
    }
  }

  checkForContentUrl(value: any) {
    if (value && typeof value === 'string') {
      let index = value.indexOf('contentUrl');
      /* istanbul ignore next */
      return index === -1 ? false : true;
    } else {
      return false;
    }
  }

  insertEntityAttributeValues(entity: any, layer: any, isFromEvents?: boolean) {
    /* istanbul ignore next */
    entity?.item?.DATA?.nslAttributes?.forEach((attr: any) => {
      /* istanbul ignore next */
      const foundAttr = layer?.txnSlotItems?.[0]?.item?.DATA?.transEntityRecords?.[0]?.txnNslAttribute?.find(
        (y: any) =>
          y.name?.toLocaleLowerCase() === attr.name?.toLocaleLowerCase()
      );
      /* istanbul ignore next */
      if (foundAttr) {
        if (!isFromEvents) {
          attr.values = foundAttr.values;
        } else {
          /* istanbul ignore next */
          if (foundAttr && foundAttr.values?.length > 0) {
            attr.values = foundAttr.values;
          }
        }
      }
    });
  }
  // updatePreviousCus(){
  //   this.transactionFacadeService.listChangeUnit$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any)=>{
  //   this.transId = res.transId;
  //   this.gsiId = res.gsiId;
  //   this.contextualId = res.currentCU.contextualId;
  //   this.triggerCuId = res.currentCU.id;
  //   this.transactionFacadeService.getPreviousCus(this.transId,this.triggerCuId,this.gsiId,this.contextualId);
  //   })
  // }
  //not been called any where //
  // detectTemplateType() {
  //   this.layoutFacade.layoutChangeUnitDesign$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
  //     this.templateType = this.templateType ? this.templateType : 't-1';
  //    /*  */
  //     if (res && res.tuples && res.tuples[0] && res.tuples[0].key === 'templateType') {
  //       this.templateType = res.tuples[0].value;
  //     }
  //   });
  // }

  detectResumeChangeUnit() {
    this.transactionFacadeService.resumeTransactionData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (this.isReadOnlyMode !== true) {
          /* istanbul ignore next */
          if (res) {
            this.transactionResume = true;
            this.nextCURequestPost = res;
            this.isResume = true;
            // this.dialog.closeAll();
          }
        }
      });
  }

  // Reset resume transaction data
  stopResumeTransaction() {
    this.transactionFacadeService.stopResumeTransaction$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* instanbul ignore else */
        if (res) {
          this.transactionResume = false;
          this.nextCURequestPost = null;
        }
      });
  }

  detectPendingTransaction() {
    this.transactionFacadeService.waitForTransaction$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.transactionResume = true;
          this.isMachineCUPending = true;
          this.nextCURequestPost = res;
          this.getNextChangeUnit();
        }
      });
  }
  // not been used //
  // detectChangeOfTab() {
  //   this.transactionFacadeService.currentTab$.pipe(takeUntil(this.unsubscribe$)).subscribe((currentTab: String) => {
  //     this.currentScreen = currentTab;
  //    /*  */
  //     if (this.currentScreen === 'transactionView') {
  //       this.getSelectedGSI();
  //     }
  //   });
  // }
  // maping values from ocr to Dynamic form

  checkifTableOCRAttribute(attr: any, res: any) {
    let index = -1;

    /* istanbul ignore next */
    res?.mappings?.forEach((attCdec: any, i: number) => {
      attCdec?.solution_attribute?.forEach((value: any) => {
        if (value.id === attr.id && attCdec?.isTable) index = i;
      });
    });
    /* istanbul ignore next */
    return index > -1;
  }

  fillData(res: any) {
    let l = 0;
    while (l < res?.currentCU?.layers.length) {
      /* istanbul ignore next */
      let originalEntityList = res?.currentCU?.layers?.[l]?.entityList
        ? JSON.parse(JSON.stringify(res?.currentCU?.layers?.[l]?.entityList))
        : null;
      let k = 0;
      /* istanbul ignore next */
      while (k < originalEntityList.length) {
        if (
          res?.currentCU?.layers[l]?.entityList[k]?.configuration?.type ===
          'table'
        ) {
          let maxNumberOfValue = 0;
          let demoFlag = false;
          for (let ele of res?.currentCU?.layers[l]?.entityList[k]
            ?.nslAttributes) {
            /* istanbul ignore next */
            if (maxNumberOfValue < ele?.values?.length) {
              maxNumberOfValue = ele?.values?.length;
            }
          }
          for (let j = 1; j < maxNumberOfValue; j++) {
            let demoEntity = res?.currentCU?.layers[l]?.entityList[k];
            for (let ele of demoEntity?.nslAttributes) {
              /* istanbul ignore next */
              if (ele?.values?.length > j) {
                ele.values[1] = ele?.values[j];
              }
              //else {
              //   // ele.values[1] = '';
              // }
            }
            res?.currentCU?.layers[l]?.entityList?.push(
              JSON.parse(JSON.stringify(demoEntity))
            );
            demoFlag = true;
          }
          if (demoFlag) {
            res?.currentCU?.layers[l]?.entityList?.splice(k, 1);
            originalEntityList?.splice(k, 1);
            k--;
            demoFlag = false;
          }
        } else if (res?.currentCU?.layers[l]?.entityList[k]?.isMulti == true) {
          let maxNumberOfValue = 0;
          for (let ele of res?.currentCU?.layers[l]?.entityList[k]
            ?.nslAttributes) {
            if (this.checkifTableOCRAttribute(ele, res)) {
              this.attrTableFlag = true;
            }
            /* istanbul ignore next */
            if (maxNumberOfValue < ele?.values?.length) {
              maxNumberOfValue = ele?.values?.length;
            }
          }
          if (this.attrTableFlag) {
            for (let j = 1; j < maxNumberOfValue; j++) {
              let demoEntity = res?.currentCU?.layers[l]?.entityList[k];
              for (let ele of demoEntity?.nslAttributes) {
                /* istanbul ignore next */
                if (ele?.values?.length > j) {
                  ele.values[1] = ele?.values[j];
                } else {
                  ele.values[1] = '';
                }
              }
              res?.currentCU?.layers[l]?.entityList?.push(
                JSON.parse(JSON.stringify(demoEntity))
              );
            }
            res?.currentCU?.layers[l]?.entityList?.splice(k, 1);
            originalEntityList?.splice(k, 1);
            k--;
          }
        }
        this.attrTableFlag = false;
        k++;
      }
      k = 0;
      /* istanbul ignore next */
      originalEntityList = res?.currentCU?.layers?.[l]?.entityList
        ? JSON.parse(JSON.stringify(res?.currentCU?.layers?.[l]?.entityList))
        : null;
      /* istanbul ignore next */
      while (k < originalEntityList?.length) {
        for (let ele of res?.currentCU?.layers[l]?.entityList[k]
          ?.nslAttributes) {
          /* istanbul ignore next */
          if (ele.nslAttributes?.length > 0) {
            this.nestedEntityValueCheck(ele);
          } else {
            /* istanbul ignore next */
            if (!ele?.isMulti) {
              /* istanbul ignore next */
              if (ele.values?.length > 1) {
                ele?.values?.splice(0, 1);
              }
              /* istanbul ignore next */
              if (ele.values?.length > 1) {
                ele.values.splice(1, ele.values?.length - 1);
              }
            }
          }
        }
        this.attrTableFlag = false;
        k++;
      }
      l++;
    }
    /*  */
    this.currentCu = res?.currentCU
      ? JSON.parse(JSON.stringify(res?.currentCU))
      : null;
    this.getDefaultLayoutConfig();
  }
  detectOCRMappings() {
    this.ocrFacadeService.transactionfileMappedDetails$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.fillData(res);
        }
      });
  }

  nestedEntityValueCheck(nestedentity: any) {
    /* istanbul ignore next */
    for (let ele of nestedentity?.nslAttributes) {
      if (ele.nslAttributes?.length > 0) {
        this.nestedEntityValueCheck(ele);
      } else {
        /* istanbul ignore next */
        if (!ele?.isMulti) {
          if (ele.values?.length > 1) {
            ele?.values?.splice(0, 1);
          }
          /* istanbul ignore next */
          if (ele.values?.length > 1) {
            ele.values.splice(1, ele.values?.length - 1);
          }
        }
      }
    }
  }
  pendingTransaction(status: number) {
    if (status === 202) {
      this.transactionInProgress = true;
      clearTimeout(this.timeIntervalofResume);
      this.timeIntervalofResume = setTimeout(() => {
        this.waitUntilTransaction();
      }, 8000);
    } else {
      this.transactionInProgress = false;
    }
  }

  /**
   * wait until machine CU completes the transaction
   */
  waitUntilTransaction() {
    const transObj: any = {};
    transObj.transId = this.txnData.transId;
    transObj.gsiId = this.txnData.gsiId;
    this.transactionInProgress = false;
    clearTimeout(this.timeIntervalofResume);
    this.transactionFacadeService.triggerWaitForTransaction(transObj);
  }
  cancelTransaction() {
    let contextualId = this.txnData?.gsiContextualId
      ? this.txnData?.gsiContextualId
      : this.transactionFacadeService?.cuData?.currentCU?.gsiContextualId;
    let payload = {
      gsiContextualId: contextualId ? contextualId : null,
      transactionId: this.txnData.transId,
      isCancel: true,
    };
    this.transactionFacadeService.cancelTransaction(payload);
    this.ignoreWarning = true;
    this.goToLibrary();
  }
  goToLibrary() {
    this.transactionInProgress = false;
    this.transactionFacadeService.isMccPopup = false;
    this.router.navigate(['/mylibrary-dashboard']);
  }

  getDefaultLayoutConfig() {
    this.eventsService.currentCuId = this.currentCu?.id;
    /* istanbul ignore next */
    if (
      this.entityDesign &&
      this.currentCu?.masterId &&
      this.allLayoutConfigurations
    ) {
      /* istanbul ignore next */
      if (
        !this.entityDesign?.cuDesignLayout?.[this.currentCu.masterId]?.[0] &&
        this.layoutConfigurations &&
        !this.openPopUpDialog &&
        this.previousCUList &&
        !this.entityDesign?.cuDesignLayout?.[
          this.previousCUList[this.previousCUList.length - 1]?.currentCU
            ?.masterId
        ]?.[0].template.includes('popup')
      ) {
        return;
      }
      const cuLayoutTemplate = this.entityDesign?.cuDesignLayout?.[
        this.currentCu.masterId
      ]?.[0] ||
        this.gsiData?.changeUnits?.design?.cuDesignLayout?.[
          this.currentCu.masterId
        ]?.[0] || {
          template: this.openPopUpDialog
            ? 'cuLayouts-cupopup-12-default-phy-info'
            : 'default-layout',
        };
      const tempCuPopupdesign = this.popupLayoutConfigurations
        ? JSON.parse(JSON.stringify(this.popupLayoutConfigurations))
        : null;
      let tempCudesign = this.layoutConfigurations
        ? JSON.parse(JSON.stringify(this.layoutConfigurations))
        : null;
      if (this.openPopUpDialog) {
        this.popupLayoutConfigurations = null;
      }
      // else {
      //   this.layoutConfigurations = null;
      // }
      if (
        this.defaultLayoutConfigurations &&
        cuLayoutTemplate?.template === 'default-layout'
      ) {
        this.layoutConfigurations = {
          ...this.defaultLayoutConfigurations,
          templateName: 'default-layout',
        };
      } else {
        this.allLayoutConfigurations?.forEach((layouts: any) => {
          layouts?.list?.forEach((lst: any) => {
            const template = lst.multiLayout.find(
              (tmpl: any) => tmpl.layout === cuLayoutTemplate?.template
            );
            if (template) {
              if (
                template.isPopUpTemplate &&
                !this.openPopUpDialog &&
                !this.solEncodedData
              ) {
                this.openPopUpDialog = true;
                 tempCudesign = null;
              }
              if (this.openPopUpDialog) {
                this.popupLayoutConfigurations = {
                  ...template,
                  templateName: cuLayoutTemplate.template,
                };
              }
              else{

                this.layoutConfigurations = {
                  ...template,
                  templateName: cuLayoutTemplate.template,
                };
              }

              if (
                !this.defaultLayoutConfigurations &&
                cuLayoutTemplate?.template === 'default-layout'
              ) {
                this.defaultLayoutConfigurations = {
                  ...template,
                  templateName: cuLayoutTemplate.template,
                };
              }
            }
          });
        });
      }
      this.popupLayoutConfigurations =
        this.popupLayoutConfigurations || tempCuPopupdesign;
      this.layoutConfigurations = this.layoutConfigurations || tempCudesign;
      this.isActiveFlag = true;
    }
  }

  gsiPopupStatus = false;
  detectPopUpDialog() {
    this.transactionFacadeService.openPopup$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isPopUp: any) => {
        if (isPopUp === 'close') {
          this.openPopUpDialog = false;
          this.isActiveFlag = false;
          /* istanbul ignore else */
          if (!this.solEncodedData) {
            this.dialog.closeAll();
          }
        } else if (isPopUp === 'closeAll') {
          this.openPopUpDialog = false;
          this.isActiveFlag = false;
          this.dialog.closeAll();
        }
        if (isPopUp === 'gsiPopupOpen') {
          this.gsiPopupStatus = true;
        } else if (isPopUp === 'gsiPopupClose') {
          this.gsiPopupStatus = false;
          this.openPopUpDialog = false;
        }
      });
  }
  detectGSIPopupChanges() {
    this.transactionFacadeService.gsiPopup$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.gsiPopup = res;
        this.gsiPopupStatus = res ;
      });
  }
  /* istanbul ignore next */
  getAllLayoutsConfig(): any {
    /* istanbul ignore next */
    if (!this.allLayoutConfigurations) {
      this.layoutService.getProductsSmall().subscribe((result: any[]) => {
        this.allLayoutConfigurations = result;
        this.getDefaultLayoutConfig();
      });
    }
  }

  /* istanbul ignore next */
  originalTransactionData: any;
  detectListChangeUnit() {
    /* istanbul ignore next */
    this.changeUnitFacadeService.GsiDesign$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((design: any) => {
      this.entityDesign = design;
      // if (!this.isResume) {
        this.getDefaultLayoutConfig();
      // }
    });
    this.transactionFacadeService.listChangeUnit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        if (response?.result?.postExceptionActions) {
          this.postExceptionsData = {
            postExceptions: response.result.postExceptionActions,
            transId: response?.result?.transId,
            gsiId: response?.result?.gsiId,
            baseTransId: response.result?.baseTransId,
            promptMessage: response.result?.promptMessage,
            promptDescription: response.result?.promptDescription,
          };
        }
        /*istanbul ignore next*/
        if (this.gsiPopupStatus) {
          this.openPopUpDialog = true;
        } else {
          this.openPopUpDialog = false;
          this.gsiPopup = false ;
        }
        /* istanbul ignore next */
        if (this.isReadOnlyMode !== true) {
          this.transactionFacadeService.getCurrentCu(response);
          this.detectSolCurrentCu(response);
          /* istanbul ignore next */
          if (response) {
            if (
              response?.result?.currentCU?.id === 2031994054269 &&
              localStorage.getItem('TenantName') === 'digitalmind'
            ) {
              this.openDemoPopUp();
            }
            /* istanbul ignore next */
            if (response?.status === 202) {
              // this.currentCu = response?.result?.currentCU;
              // this.originalEntitylist = this.currentCu ? JSON.parse(JSON.stringify(this.currentCu)) : null;
              this.transactionFacadeService.cuData = JSON.parse(
                JSON.stringify(response?.result)
              );
              this.txnData.transId = response?.result?.transId;
              this.txnData.gsiId = response?.result?.gsiId;
              if (this.isMachineCUPending) {
                this.loader.show();
                setTimeout(() => {
                  this.loader.hide();
                  this.isMachineCUPending = false;
                  this.pendingTransaction(response?.status);
                }, 10000);
              } else {
                this.eventsData = Object.assign({}, this.txnData);
                this.pendingTransaction(response?.status);
              }
            } else {
              const tempPrevCU = this.transactionCU
                ? JSON.parse(JSON.stringify(this.transactionCU))
                : null;
              this.transactionCU = response?.result;
              if (response?.status === 200) {
                if(response.result.currentCU.reservedCUType === "externalSolution:openpay_card_save") {
                  response.result.currentCU.layers.forEach(layer => {
                    if(layer.type === 'physical') {
                        layer.entityList.forEach(entityList => {
                            if (entityList.name === "NSL_Openpay_Card") {
                                entityList.nslAttributes.forEach(attribute => {
                                  if(attribute.name === 'Device Session Id') {
                                            OpenPay.setId('mz5g9tvckacrlrfhv926');
                                            OpenPay.setApiKey('pk_3bc583331eca4b2ca2c52f9c2edfc02e');
                                            attribute['values'] = [OpenPay.deviceData.setup()]
                                    }
                                })
                            }
                        })
                    }
                })
                }
                this.transactionFacadeService.openPendingTransactionModal({
                  txnData: this.txnData,
                  status: response?.status,
                });
              }
              response = response?.result;
              /* istanbul ignore next */
              if (response) {
                this.transactionFacadeService.cuData = JSON.parse(
                  JSON.stringify(response)
                );
                this.isLocationCu(response?.currentCU, response?.transId);
                if (
                  (!response?.currentCU) ||
                  (response?.triggerState &&
                  (response?.triggerState == 'COMPLETED' ||
                    response?.triggerState == 'SCHEDULED'))
                ) {
                  this.eventsService.updateScormScore(0);
                  sessionStorage.removeItem('innerGSIid');
                  this.transactionFacadeService.listChangeUnitSubject.next(
                    null
                  );
                  this.transactionFacadeService.isTransactionCompleted$
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((res: any) => {
                      if (res) {
                        this.ignoreWarning = res;
                        this.ignoreWarningEmiter.emit(this.ignoreWarning);
                      }
                    });
                  if (this.transactionFacadeService.isLogoutConfigGsi) {
                    this.transactionFacadeService.logoutApplication();
                  }
                  if (this.solutionData?.bookId) {
                    /* Start
                    BUG-10119: Blank screen is displayed with loading
                    Author: Sairam
                    Date: 22-Oct-2021
                  */
                    this.transactionFacadeService.isMccPopup = false;
                    /* istanbul ignore next */
                    if (
                      (!sessionStorage.bookData ||
                        sessionStorage.bookData === '') &&
                      !(this.isCarnival || this.isOnboarding)
                    ) {
                      this.router.navigate(['/mylibrary-dashboard']);
                    } else if (!(this.isCarnival || this.isOnboarding)) {
                      this.router.navigate(['/transaction/my-collection']);
                    }
                    /* End of BUG-10119 Resolution*/
                  } else {
                    if (response?.isAnyPendingGsi !== true) {
                      this.transactionFacadeService.isMccPopup = false;
                      this.router.navigate(['/mylibrary-dashboard']);
                    }
                  }
                } else {
                  this.listData = JSON.parse(JSON.stringify(response));
                  if (!this.listData?.enableComments)
                    this.listData.enableComments = false;
                  if (!this.listData?.enablePushback)
                    this.listData.enablePushback = false;
                  if (!this.listData?.currentCU?.isFaceted)
                    this.listData.currentCU.isFaceted = false;
                  if (!this.listData?.currentCU?.isReserved)
                    this.listData.currentCU.isReserved = false;
                  if (!this.listData?.currentCU?.subtransactionalCU)
                    this.listData.currentCU.subtransactionalCU = false;
                  this.eventsData = Object.assign({}, this.listData);
                  console.log(this.entityDesign);
                  /* istanbul ignore next */
                  response?.currentCU?.layers?.forEach((layer: any) => {
                    let data = layer.entityList.find((res: any) => {
                      return this.entityDesign.entityIcon[res.masterId];
                    });
                    if (data?.design?.entityIcon?.link) {
                      data.design.entityIcon.link = this.entityDesign.entityIcon[
                        data.masterId
                      ]?.link;
                    }
                  });
                  const _tempCuList = this.currentCu
                    ? JSON.parse(JSON.stringify(this.currentCu))
                    : null;
                  this.currentCu = this.listData?.currentCU;
                  if (this.currentCu) {
                    let isMonthSelected = this.attributeStoreService.getStateData();
                    if(isMonthSelected?.attributeList?.toString() == 'isMonthSelected') this.availableDates= [];
                    this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
                    /*istanbul ignore next*/
                    this.currentCu?.layers?.forEach((layer: any) => {
                      if (layer?.type == 'physical') {
                        layer?.entityList?.forEach((entity: any) => {
                          if (entity?.name.includes('NSL_SlotBooking')) {
                            entity?.nslAttributes?.forEach((attr: any) => {
                              if (attr?.attributeType?.type == 'dateOnly') {
                                  attr?.values.forEach((data: any,index:number) => {
                                    if(this.availableDates?.length == 0 && index == 0){
                                      this.availableDates.push(this.datePipe?.transform(new Date(data), 'MM/dd/yyyy'));
                                      attr.values[index] = "";
                                    }
                                    else if (attr?.values != '')
                                    this.availableDates.push(this.datePipe.transform(new Date(data), 'MM/dd/yyyy'));
                                });
                                attr.values = attr?.values?.slice(0, 1);
                              }
                            });
                          }
                        });
                      }
                    });
                    this.entityStoreService.setStateData(this.availableDates);
                  }
                  this.getDefaultLayoutConfig();
                  // if (
                  //     this.layoutConfigurations &&
                  //     !this.layoutConfigurations.templateName.includes('popup') &&
                  //     (this.previousSolutionData && this.previousSolutionData?.gsiId !== this.solutionData?.gsiId )
                  //     &&
                  //     !this.gsiPopup
                  //   ) {
                  //       this.openPopUpDialog = false;
                  //     }
        /* istanbul ignore next */
                  if (this.openPopUpDialog && !this._previousCUList && (this.currentCu?.masterId !== _tempCuList?.masterId && this.gsiData?.changeUnits?.masterId !== this._gsiData?.changeUnits?.masterId)) {
                    this._previousCUList = _tempCuList;
                  } else if (!this.openPopUpDialog && this._previousCUList) {
                    this._previousCUList = null;
                  }
                  if (this.openPopUpDialog && !this.previousTransactionCU) {
                    this.previousTransactionCU = tempPrevCU;
                  }
                  if (this.gsiPopup) {
                    this.openPopUpDialog = true;
                  }
                  if (!this.solEncodedData) {
                    if (this.openPopUpDialog) {
                      this.openDialog(this.myDialog);
                    } else {
                      this.dialog.closeAll();
                    }
                  }
                  this.originalEntitylist = this.currentCu
                    ? JSON.parse(JSON.stringify(this.currentCu))
                    : null;
                  /* istanbul ignore next */
                  if (this.currentCu && this.currentCu?.contextualId) {
                    sessionStorage.innerGSIid = response?.gsiId;
                    sessionStorage.layersData = this.currentCu?.layers
                      ? JSON.stringify(this.currentCu?.layers)
                      : null;
                    this.txnData.cuContextualId = this.currentCu?.contextualId;
                    let contextArr = this.currentCu?.contextualId.split('.');
                    let contextCU = contextArr?.find(
                      (x: any) => x.indexOf('CU') !== -1
                    );
                    /* istanbul ignore next */
                    if (contextCU) {
                      let contextCUArr = contextCU.split('_');
                      let refereceCU = contextCUArr?.find(
                        (x: any) => x.indexOf('CU') == -1
                      );
                      /* istanbul ignore next */
                      if (refereceCU) {
                        sessionStorage.currentRefCuID = refereceCU;
                      }
                    }
                    sessionStorage.currentCuID = this.currentCu?.id;
                    sessionStorage.contextualId = this.currentCu?.contextualId;
                  }
                  this.eventsService.selectedCuData = response;
                  /* istanbul ignore next */
                  this.eventsService.gsiName = this.gsiData?.name;
                  this.showTxnRollBackMsg(response);
                }
              }
              this.eventsService.clearOnLoadEvents();
            }
          } else {
            /* istanbul ignore next */
            this.eventsData = Object.assign({}, this.listData);
            this.currentCu = this.listData?.currentCU;
            this.currentCu = this.currentCu
              ? JSON.parse(JSON.stringify(this.currentCu))
              : null;
            this.getDefaultLayoutConfig();
          }
        }
        if (this.postExceptionsData != '') this.openPostExceptions();
      });
  }
  showTxnRollBackMsg(response: any) {
    if (response?.reason === 'ROLLBACK_INITIATION') {
      this.alertService.showToaster(
        'Transaction failed and rolled back. Please fill in the details and try again',
        '',
        'error'
      );
    }
  }
  detectSolCurrentCu(res: any) {
    /* istanbul ignore next */
    if (res) {
      let idArray = res?.result?.currentCU?.contextualId?.split('_');
      if (Array.isArray(idArray) && idArray[idArray.length - 1])
        this.a2aFacade.changeUnitById(idArray[idArray.length - 1]);
    }
  }
  getMetaData() {
    this.a2aFacade.cudetailsMetaData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          const tenantId = localStorage.getItem('envClientId')
            ? localStorage.getItem('envClientId')
            : sessionStorage.getItem('TenantId');
          this.a2aFacade.getOntologyInfo(res?.dsdMetadataId, tenantId);
        }
      });
  }

  detectNextChangeUnit() {
    this.transactionFacadeService.nextChangeUnit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        /* istanbul ignore next */
        if (this.isReadOnlyMode !== true) {
          /*  */
          if (
            response &&
            response.nextTriggerCU &&
            response.nextTriggerCU.DATA
          ) {
            /* istanbul ignore next */
            if (this.solutionData?.gsiId !== response?.gsiId) {
              const foundGSI = this.gsiData?.changeUnits?.solutionLogic?.find(
                (x: any) => x.DATA?.id === response?.gsiId
              );
              /* istanbul ignore next */
              if (foundGSI) {
                this.solutionData.gsiId = foundGSI?.DATA?.referencedChangeUnit;
              }
            }
            this.eventsService.selectedCuData = response;
            this.eventsService.gsiName = this.gsiData?.name;
            this.selectedCu = response?.nextTriggerCU.DATA;
            this.getDefaultLayoutConfig();
            this.txnData.gsiId = response?.gsiId;
            this.txnData.transactionId = response.transId;
            /* istanbul ignore next */
            if (this.selectedCu && this.selectedCu?.contextualId) {
              this.txnData.cuContextualId = this.selectedCu?.contextualId;
              sessionStorage.currentRefCuID = this.selectedCu?.referencedChangeUnit;
              sessionStorage.currentCuID = this.selectedCu?.id;
              sessionStorage.contextualId = this.selectedCu?.contextualId;
            }
            /* istanbul ignore next */
            if (this.selectedCu && this.selectedCu.id) {
              this.layoutFacade.fetchChangeUnitDetails(this.selectedCu.id);
            }
            /* istanbul ignore next */
            if (response.txnData) {
              this.findLayerAndAssignAttributeData(response.txnData);
            }
            /*  */
            this.selectedCu?.layers?.forEach((layer: any) => {
              /*  */
              layer?.participatingItems?.forEach((entity: any) => {
                /*  */
                entity?.item?.DATA?.nslAttributes?.forEach((attr: any) => {
                  /* istanbul ignore next */
                  if (
                    attr.attributeType?.properties &&
                    attr.attributeType?.properties?.sourceValues &&
                    attr.attributeType?.properties?.sourceValues.trim() !== ''
                  ) {
                    const attributeValues = attr.attributeType?.properties
                      ?.sourceValues
                      ? JSON.parse(attr.attributeType?.properties?.sourceValues)
                      : null;
                    /* istanbul ignore next */
                    if (attributeValues?.length > 0) {
                      attr.options = attributeValues;
                    }
                  }
                });
              });
            });
            this.redirected = false;
          } else if (response && response.transId) {
            /* */
            this.transactionFacadeService.isMccPopup = false;
            this.router.navigate(['/mylibrary-dashboard']);
          }
        }
      });
  }

  /* findLayerAndAssignAttributeData(data: any) {
    data.txnCULayer?.forEach((layer: any) => {
      const foundLayer = this.selectedCu.layers?.find((x: any) => x.type === layer.type);
      if (foundLayer) {
        this.setSelectedCuLayersValues(foundLayer, layer);
      }
    });
  } */

  /* getSelectedGSI() {
    // this.transactionFacadeService.gsiData$.pipe(takeUntil(this.unsubscribe$)).subscribe((gsi) => {
    if (this.solutionData.gsiId != undefined && this.solutionData.gsiId !== '') {
      this.getChangeUnitByGsiId(this.solutionData.gsiId);
    }
    // });
  } */
  /*  getChangeUnitByGsiId(gsiId: number) {
    this.transactionFacadeService.getChangeUnitByGsiId(
      gsiId,
      this.nextCURequestPost.triggerCuId,
      this.solutionData.bookId
    );
    this.changeUnitFacadeService.changeUnitListByGSI$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((changeUnitList: Gsi) => {
        this.gsiData = {
          ...this.gsiData,
          changeUnits: changeUnitList,
          name: changeUnitList.name,
          displayName: changeUnitList.displayName,
        };
        const data = { ...this.nextCURequestPost, gsiId: this.solutionData.gsiId };
        const foundGSI = changeUnitList.solutionLogic?.find(
          (x: any) => x.DATA?.cuType === 'GSI' && x.DATA?.referencedChangeUnit === this.solutionData.gsiId
        );
        let triggerCuId = Number(sessionStorage.currentCuID);
        if (foundGSI) {
          const foundCU = foundGSI?.DATA?.solutionLogic?.find(
            (x: any) => x.DATA?.referencedChangeUnit === Number(sessionStorage.currentRefCuID)
          );
          if (foundCU) {
            triggerCuId = foundCU.DATA?.id;
            this.previousChangeUnit.index = foundCU.DATA?.index;
          }
        }
        // this.transactionFacadeService.getIpAddress();
        if (this.showAds) {
          // this.transactionFacadeService.geolocation();
          this.getAds();
        }
        if (this.redirected && this.previousChangeUnit) {
          // this.previousChangeUnit.id = triggerCuId;
          this.previousChangeUnit.contextualId = sessionStorage.contextualId;
          this.previousChangeUnit.layers?.forEach((layer: any) => {
            const foundEntity = layer?.entityList?.find((x: any) => x.id === this.currentEntity);
            if (foundEntity) {
              let foundAttribute = foundEntity.nslAttributes?.find((x: any) => x.id === this.currentAttribute);
              if (foundAttribute) {
                foundAttribute.values = [this.redirectData];
              }
            }
          });
          data.currentCU = this.previousChangeUnit;
        }
        this.nextCURequestPost = JSON.parse(JSON.stringify(data));
        this.getNextChangeUnit();
      });
  } */

  findLayerAndAssignAttributeData(data: any) {
    /* istanbul ignore next */
    data.txnCULayer?.forEach((layer: any) => {
      /*  */
      const foundLayer = this.selectedCu.layers?.find(
        (x) => x.type === layer.type
      );
      /* istanbul ignore next */
      if (foundLayer) {
        this.setSelectedCuLayersValues(foundLayer, layer);
      }
    });
  }

  getSelectedGSI() {
    // this.transactionFacadeService.gsiData$.pipe(takeUntil(this.unsubscribe$)).subscribe((gsi) => {
    /* istanbul ignore next */
    if (
      this.solutionData &&
      this.solutionData?.gsiId != undefined &&
      this.solutionData?.gsiId !== ''
    ) {
      /* istanbul ignore next */
      if (this.solutionData.gsiId == this.solutionData.triggerCuId) {
        this.getTriggerCU(this.solutionData);
      } else {
        this.getChangeUnitByGsiId(this.solutionData?.gsiId);
      }
    }
    // });
  }
  async getChangeUnitByGsiId(gsiId: number) {
    /* istanbul ignore next */
    await this.transactionFacadeService.getChangeUnitByGsiId(
      gsiId,
      this.nextCURequestPost?.triggerCuId,
      this.solutionData?.bookId,
      this.isDebug
    );
  }

  isMobileTablet() {
    var check = false;
    /* istanbul ignore next */
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }
  isMobile() {
    var check = false;
    /* istanbul ignore next */
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check || navigator?.['userAgentData']?.mobile;
  }

  getDateTimeFormat() {
    let dateTime = new Date();
    return (
      dateTime.getFullYear() +
      '-' +
      ('0' + (dateTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateTime.getDate()).slice(-2) +
      'T' +
      dateTime.getHours() +
      ':' +
      dateTime.getMinutes() +
      ':' +
      dateTime.getSeconds() +
      '.' +
      dateTime.getMilliseconds() +
      'Z'
    );
  }

  /**
   * get Ads based on GSI id ans user details.
   */
  async getAds() {
    let clientIPAddress = await publicIp.v4();
    /* istanbul ignore next */
    let activeUserInfo = localStorage.ActiveUserInfo
      ? JSON.parse(localStorage.ActiveUserInfo)
      : null;
    /* istanbul ignore next */
    let correlatorValue: any = Guid.create();
    /* istanbul ignore next */
    this.gsiDataObj = {
      ontology: this.gsiData?.changeUnits?.design?.gsiIcon[
        this.gsiData?.changeUnits?.id
      ]?.ontology?.id,
      gsiId: this.gsiData?.changeUnits?.id,
      platformType: this.isMobileTablet()
        ? this.isMobile()
          ? 'Mobile'
          : 'Tablet'
        : 'Desktop',
      dateTime: this.getDateTimeFormat(),
      domainUrl: 'cduidev.dev3.nslhub.com',
      ipAddress: clientIPAddress,
      userId: activeUserInfo?.id,
      correlatorValue: correlatorValue?.value,
    };
    /*  */
    /* istanbul ignore next */
    // this.callAdsMethod();
    /*  */
    /* istanbul ignore next */
    navigator?.geolocation?.getCurrentPosition(
      //if success, this is called using the position as the data variable
      (position: any) => {
        /*  */
        this.gsiDataObj.geoLocation = {
          latitude: position?.coords?.latitude,
          longitude: position?.coords?.longitude,
        };
        if (
          this.gsiDataObj?.geoLocation?.latitude &&
          this.gsiDataObj?.geoLocation?.longitude
        ) {
          this.transactionFacadeService.getLocation(
            this.gsiDataObj.geoLocation.latitude,
            this.gsiDataObj.geoLocation.longitude
          );
        } else {
          this.callAdsMethod();
        }
        // this.transactionFacadeService.getTransactionAds(this.gsiDataObj);
      },
      //if failure, this is called with error variable message printed
      /*  */
      (error) => {
        this.callAdsMethod();
      }
    );
  }
  /* istanbul ignore next */
  callAdsMethod() {
    /* istanbul ignore next */
    if (
      this.gsiDataObj?.geoLocation &&
      (!this.gsiDataObj?.geoLocation?.state ||
        !this.gsiDataObj?.geoLocation?.country)
    ) {
      delete this.gsiDataObj.geoLocation;
    }
    let gsiDataObj = JSON.parse(JSON.stringify(this.gsiDataObj));
    gsiDataObj.adSlotType = 'Display';
    this.transactionFacadeService.getTransactionAds(gsiDataObj);
    let gsiDataObjText = JSON.parse(JSON.stringify(this.gsiDataObj));
    gsiDataObjText.adSlotType = 'Text';
    let correlatorValue: any = Guid.create();
    gsiDataObjText.correlatorValue = correlatorValue?.value;
    gsiDataObjText.dateTime = this.getDateTimeFormat();
    this.transactionFacadeService.getTransactionBottomAds(gsiDataObjText);
    this.getTransactionTopAds(gsiDataObjText);
  }
  getTransactionTopAds(gsiData: any) {
    this.transactionFacadeService.getTopBannerAds({
      ...gsiData,
      adSize: '728 X 90',
    });
  }
  /// method not been used ////
  // getPosition(position: any) {
  //   return position;
  // }
  getNextChangeUnit() {
    /*  */
    /* istanbul ignore next */
    this.nextCURequestPost.bookId = this.solutionData?.bookId;
    /* istanbul ignore next */
    if (this.solutionData?.recordId && this.solutionData?.entityName) {
      this.nextCURequestPost.recordId = this.solutionData.recordId;
      this.nextCURequestPost.entityName = this.solutionData.entityName;
    } else if (
      this.solutionData?.recordIdsList?.length > 0 &&
      this.solutionData?.entities?.length > 0
    ) {
      this.nextCURequestPost.recordIdsList = this.solutionData.recordIdsList;
      this.nextCURequestPost.dcdsList = this.solutionData.dcdsList;
      this.nextCURequestPost.entities = this.solutionData.entities;
      if (this.solutionData?.quantitiesList?.length > 0) {
        this.nextCURequestPost.quantitiesList = this.solutionData.quantitiesList;
      }
    } else {
      if (this.nextCURequestPost?.quantitiesList)
        delete this.nextCURequestPost.quantitiesList;
      if (this.nextCURequestPost?.entities)
        delete this.nextCURequestPost.entities;
      if (this.nextCURequestPost?.recordIdsList)
        delete this.nextCURequestPost.recordIdsList;
      if (this.nextCURequestPost?.dcdsList)
        delete this.nextCURequestPost.dcdsList;
      if (this.nextCURequestPost?.recordId)
        delete this.nextCURequestPost.recordId;
      if (this.nextCURequestPost?.entityName)
        delete this.nextCURequestPost.entityName;
    }
    /* istanbul ignore next */
    this.nextCURequestPost.gsiContextualId = this.solutionData?.contextualId;
    /* istanbul ignore next */
    if (
      this.redirected &&
      sessionStorage?.innerGSIid &&
      sessionStorage?.currentCuID &&
      this.nextCURequestPost?.currentCU
    ) {
      this.nextCURequestPost.gsiId = Number(sessionStorage.innerGSIid);
      this.nextCURequestPost.currentCU.id = Number(sessionStorage.currentCuID);
    }

    if (this.transactionResume) {
      this.transactionFacadeService.getResumeChangeUnitByGSIId(
        this.nextCURequestPost
      );
      this.transactionFacadeService.resumeTransaction('');
      this.transactionResume = false;
      this.isReloaded = false;
    } else if (this.transactionEventResume) {
      /* istanbul ignore next */
      const tempPrevCU = this.transactionCU
        ? JSON.parse(JSON.stringify(this.transactionCU))
        : null;
      this.transactionCU = this.nextCURequestPost;
      this.transactionFacadeService.cuData = JSON.parse(
        JSON.stringify(this.nextCURequestPost)
      );
      /* istanbul ignore next */
      if (this.nextCURequestPost) {
        this.listData = JSON.parse(JSON.stringify(this.nextCURequestPost));
        this.eventsData = Object.assign({}, this.listData);
        /* istanbul ignore next */
        const _tempCuList = this.currentCu
          ? JSON.parse(JSON.stringify(this.currentCu))
          : null;
        /* istanbul ignore next */
        this.currentCu = this.listData?.currentCU;
        /* istanbul ignore next */
        this.currentCu = this.currentCu
          ? JSON.parse(JSON.stringify(this.currentCu))
          : null;
        this.getDefaultLayoutConfig();
        /* istanbul ignore next */
        if (this.openPopUpDialog && !this._previousCUList) {
          this._previousCUList = _tempCuList;
        } else if (!this.openPopUpDialog && this._previousCUList) {
          this._previousCUList = null;
        }
        /* istanbul ignore next */
        if (this.openPopUpDialog && !this.previousTransactionCU) {
          this.previousTransactionCU = tempPrevCU;
        }
        /* istanbul ignore next */
        if (!this.solEncodedData) {
          /* istanbul ignore next */
          if (this.openPopUpDialog) {
            this.openDialog(this.myDialog);
          } else {
            this.dialog.closeAll();
          }
        }
        /* istanbul ignore next */
        if (this.currentCu && this.currentCu?.contextualId) {
          /* istanbul ignore next */
          sessionStorage.innerGSIid = this.nextCURequestPost?.gsiId;
          /* istanbul ignore next */
          sessionStorage.layersData = this.currentCu?.layers
            ? JSON.stringify(this.currentCu?.layers)
            : null;
          /* istanbul ignore next */
          this.txnData.cuContextualId = this.currentCu?.contextualId;
          /* istanbul ignore next */
          let contextArr = this.currentCu?.contextualId.split('.');
          /* istanbul ignore next */
          let contextCU = contextArr?.find((x: any) => x.indexOf('CU') !== -1);
          /* istanbul ignore next */
          if (contextCU) {
            /* istanbul ignore next */
            let contextCUArr = contextCU.split('_');
            /* istanbul ignore next */
            let refereceCU = contextCUArr?.find(
              (x: any) => x.indexOf('CU') == -1
            );
            /* istanbul ignore next */
            if (refereceCU) {
              sessionStorage.currentRefCuID = refereceCU;
            }
          }
          /* istanbul ignore next */
          sessionStorage.currentCuID = this.currentCu?.id;
          /* istanbul ignore next */
          sessionStorage.contextualId = this.currentCu?.contextualId;
        }
      }
      this.transactionFacadeService.resumeTransaction('');
      this.transactionEventResume = false;
    } else if (this.isReloaded) {
      this.transactionFacadeService.getResumeChangeUnitByGSIId(
        this.currentCuExecuting
      );
      this.transactionFacadeService.resumeTransaction('');
      this.isReloaded = false;
    } else {
      /* istanbul ignore next */
      if (this.popupClosed) {
        this.nextCURequestPost.bookId = undefined;
        this.nextCURequestPost.gsiContextualId = undefined;
        /* istanbul ignore next */
        this.nextCURequestPost.gsiId = this.previousGsidata?.gsiId;
        /* istanbul ignore next */
        this.nextCURequestPost.gsiMasterId = this.previousGsidata?.gsiId;
        this.transactionFacadeService.getListChangeUnitByGSIId(
          this.nextCURequestPost,
          false,
          this.isDebug,
          '',
          undefined,
          this.layoutConfigurations
        );
      } else {
        this.transactionFacadeService.getListChangeUnitByGSIId(
          this.nextCURequestPost,
          false,
          this.isDebug,
          '',
          undefined,
          this.layoutConfigurations
        );
      }
    }
  }

  detectCurrentCu() {
    this.a2aFacade.manualTransact$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.fillData(res);
          this.ocrFacadeService.manualTransact.next(res);
          this.dialog.closeAll();
          this.transactionFacadeService.openPopup.next('close');
        }
      });
  }
  ngOnDestroy() {
    this.loader.hide();
    //this.transactionFacadeService.openPendingTransactionModal
    this.render.removeClass(document.body, 'debugSpace');
    this.transactionFacadeService.paginationDetails = {} as paginationDetails;
    localStorage.removeItem('currentCuData');
    localStorage.removeItem('expandTable');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.transactionFacadeService.setCardData(null);
    clearInterval(this.locationInterval);
  }

  getNeextCU(txnData: any) {
    /*  */
    /* istanbul ignore next */
    this.nextCURequestPost.gsiId = this?.txnData?.gsiId;
    /*  */
    /* istanbul ignore next */
    this.nextCURequestPost.transID = this?.txnData?.transactionId;

    /*  */
    /* istanbul ignore next */
    this.nextCURequestPost.triggerCuId = this?.selectedCu?.id;
    /* istanbul ignore next */
    this.nextCURequestPost.txnData.cuContextualId = this?.selectedCu?.contextualId;
    this.txnData.txnCULayer = [];
    /*  */
    /* istanbul ignore next */
    txnData?.layerData?.forEach((layer: any) => {
      /* istanbul ignore next */
      if (layer?.txnSlotItems?.length > 0) {
        delete layer.guid;
        delete layer.id;
        this.txnData.txnCULayer.push(layer);
      }
    });
    this.nextCURequestPost.txnData = this.txnData;
    this.nextCURequestPost.txnData.triggerEndTime = null;
    const tempTxnData = JSON.parse(JSON.stringify(this.txnData));
    tempTxnData.txnCULayer = [];
    sessionStorage.previousTxnData = JSON.stringify(tempTxnData);
    /*  */
    /* istanbul ignore next */
    if (!txnData?.isFormChange) {
      this.getNextChangeUnit();
    } else {
      this.findLayerAndAssignAttributeData(this.txnData);
    }
  }

  getScormValueCalculate(previousScore: any, currentScore: any) {
    /*  */
    /* istanbul ignore next */
    currentScore =
      currentScore == '' || currentScore === undefined
        ? 0
        : parseInt(currentScore);
    /*  */
    /* istanbul ignore next */
    previousScore =
      previousScore == '' || previousScore === undefined
        ? 0
        : parseInt(previousScore);
    let currentScoreConvertedValue: any;
    if (currentScore === 100) {
      currentScoreConvertedValue = 10;
    } else {
      currentScoreConvertedValue = 0;
    }
    return previousScore + currentScoreConvertedValue;
  }

  renameEntity(data: any, isFormValueChange: boolean) {
    /* istanbul ignore next */
    data?.forEach((element: any) => {
      /* istanbul ignore next */
      element.name = element?.name?.split('$')?.[0];
      /* istanbul ignore next */
      if (element.nslAttributes && element.nslAttributes.length > 0) {
        element.nslAttributes = element?.nslAttributes
          ?.map((el1: any) => {
            /* istanbul ignore next */
            if (el1.type == 'entity') {
              this.renameNestedentity(el1, isFormValueChange);
            }
            this.roundingDecimals(el1);
            // sending empty array if there is no value //
            /* istanbul ignore next */
            if (el1?.values?.length === 1 && el1?.values?.[0] === '') {
              el1.values = [];
            }
            this.roundingDecimals(el1);
            /* istanbul ignore next */
            if (
              el1?.options &&
              el1?.options.length > 0 &&
              !isFormValueChange &&
              !this.eventGsiData
            ) {
              el1.options = null;
            }
            if (!el1?.isInPotentiality && !isFormValueChange) {
              el1 = null;
            }
            return el1;
          })
          ?.filter((el1: any) => el1 !== null);
        if (!element.isInPotentiality && !isFormValueChange) {
          element.nslAttributes = null;
        }
        if (element?.isOptional && !isFormValueChange) {
          if (
            (element?.configuration?.type == 'card' &&
              Object.keys(element?.configuration?.cardSettings)?.length > 0) ||
            (element?.configuration?.type == 'table' &&
              Object.keys(element?.configuration?.tableSettings)?.length > 0)
          ) {
            let records =
              element?.configuration?.type == 'card'
                ? this.transactionFacadeService?.selectedCardsRecordList
                : this.transactionFacadeService?.selectedTableRecordList;
            if (records.length > 0) {
              let index = records.findIndex((list: any) => {
                return (
                  list?.entityName?.split('$')?.[0] ===
                  element?.name?.split('$')?.[0]
                );
              });
              if (
                index == -1 ||
                (index !== -1 &&
                  (records[index]?.selectedCards
                    ? records[index].selectedCards.length == 0
                    : records[index]?.selectedIndexes.length == 0))
              ) {
                element.nslAttributes = null;
              }
            } else {
              element.nslAttributes = null;
            }
          }
        }
      }
    });

    return data;
  }

  renameNestedentity(element: any, isFormValueChange: boolean) {
    /* istanbul ignore next */
    element.name = element?.name?.split('$')?.[0];
    /* istanbul ignore next */
    if (element.nslAttributes && element.nslAttributes.length > 0) {
      element.nslAttributes = element.nslAttributes
        ?.map((el1: any) => {
          /* istanbul ignore next */
          if (el1.type == 'entity') {
            this.renameNestedentity(el1, isFormValueChange);
          }
          // sending empty array if there is no value //
          /* istanbul ignore next */
          if (el1?.values?.length === 1 && el1?.values?.[0] === '') {
            el1.values = [];
          }
          this.roundingDecimals(el1);
          /* istanbul ignore next */
          if (el1?.options && el1?.options.length > 0 && !isFormValueChange) {
            el1.options = null;
          }
          if (!el1?.isInPotentiality && !isFormValueChange) {
            el1 = null;
          }
          return el1;
        })
        ?.filter((el1: any) => el1 !== null);
      if (!element.isInPotentiality && !isFormValueChange) {
        element.nslAttributes = null;
      }
      if (element?.isOptional && !isFormValueChange) {
        if (
          (element?.configuration?.type == 'card' &&
            Object.keys(element?.configuration?.cardSettings)?.length > 0) ||
          (element?.configuration?.type == 'table' &&
            Object.keys(element?.configuration?.tableSettings)?.length > 0)
        ) {
          let records =
            element?.configuration?.type == 'card'
              ? this.transactionFacadeService?.selectedCardsRecordList
              : this.transactionFacadeService?.selectedTableRecordList;
          if (records.length > 0) {
            let index = records.findIndex((list: any) => {
              return (
                list?.entityName?.split('$')?.[0] ===
                element?.name?.split('$')?.[0]
              );
            });
            if (
              index == -1 ||
              (index !== -1 &&
                (records[index]?.selectedCards
                  ? records[index].selectedCards.length == 0
                  : records[index]?.selectedIndexes.length == 0))
            ) {
              element.nslAttributes = null;
            }
          } else {
            element.nslAttributes = null;
          }
        }
      }
    }
  }

  roundingDecimals(el1: any) {
    /* rounding the decimal values */
    if (
      el1.attributeType?.properties?.roundingMode &&
      el1.attributeType?.properties?.format
    ) {
      const roundVal = el1.values[0];
      /* istanbul ignore next */
      if (roundVal != null && roundVal != undefined && roundVal != '') {
        let format = el1.attributeType?.properties?.format;
        el1.values = [];
        if (el1?.attributeType?.properties?.roundingMode == 'ROUND') {
          el1.values.push(
            Math.round(
              parseFloat(roundVal.toString().replace(/,/g, '')) *
                Math.pow(10, format)
            ) / Math.pow(10, format)
          );
        }
        if (el1?.attributeType?.properties?.roundingMode == 'ROUND-UP') {
          el1.values.push(
            Math.ceil(
              parseFloat(roundVal.toString().replace(/,/g, '')) *
                Math.pow(10, format)
            ) / Math.pow(10, format)
          );
        }
        if (el1?.attributeType?.properties?.roundingMode == 'ROUND-DOWN') {
          el1.values.push(
            Math.floor(
              parseFloat(roundVal.toString().replace(/,/g, '')) *
                Math.pow(10, format)
            ) / Math.pow(10, format)
          );
        }
      }
    }
  }

  submitFormData(data: any) {
    this.listData.currentCU = JSON.parse(JSON.stringify(this.currentCu));
    /* istanbul ignore next */
    this.eventsData = JSON.parse(JSON.stringify(this.listData));
    const ind = this.listData.currentCU?.layers?.findIndex((layer: any) => {
      return layer.type === data.layer;
    });
    /* istanbul ignore next */
    if (ind != -1) {
      this.listData.currentCU.layers[ind].entityList = data.data;
    }
    this.transactionFacadeService.selectedCUForEvent = JSON.parse(
      JSON.stringify(this.listData)
    );
    /* istanbul ignore next */
    if (this.listData.currentCU?.tCUsentenceName) {
      this.listData.currentCU.tCUsentenceName = '';
    }
    /* istanbul ignore next */
    this.listData.currentCU.layers?.forEach((layer: any) => {
      layer.entityList = this.renameEntity(layer.entityList, false);
    });
    if (
      this.listData?.currentCU?.reservedCUType == 'NSL_Template_FacetedSearch'
    ) {
      const index = this.listData.currentCU.layers.findIndex((key: any) => {
        return key.type === 'triggerCES';
      });
      if (this.listData.currentCU.layers[index]) {
        this.transactionFacadeService.selectedTransactions?.forEach(
          (key: any, i: any) => {
            if (key?.configuration && key?.type == 'entity') {
              this.listData.currentCU.layers[index].entityList.push({
                ...key,
                slot: 1,
              });
            }
          }
        );
        this.listData.currentCU.layers[index].entityList.splice(0, 1);
        this.transactionFacadeService.selectedTransactions = [];
      }
    }
    if (
      this.listData?.currentCU?.reservedCUType == 'NSL_Template_CDEC_Review'
    ) {
      this.eventsService.setFeedBack(this.listData?.currentCU);
    }
    if (
      this.listData.currentCU?.reservedCUType === 'NSL_Crypto_Wallet_Address'
    ) {
      const index = this.listData.currentCU.layers.findIndex((key: any) => {
        return key.type === 'triggerCES';
      });
      if (this.listData.currentCU.layers[index]) {
        this.listData.currentCU.layers[index].entityList.forEach(
          (entity: any) => {
            if (entity.name === 'NSL_CryptoWallet_Output') {
              entity.nslAttributes.forEach((attr: any) => {
                if (attr.name === 'Wallet Account Address') {
                  attr.values = [
                    this.transactionEndPointService.metaMaskAddress,
                  ];
                }
              });
            }
          }
        );
      }
    }

    if (this.listData.currentCU?.reservedCUType === 'NSL_ETHCryptoPayment') {
      const index = this.listData.currentCU.layers.findIndex((key: any) => {
        return key.type === 'triggerCES';
      });
      if (this.listData.currentCU.layers[index]) {
        this.listData.currentCU.layers[index].entityList.forEach(
          (entity: any) => {
            if (entity.name === 'NSL_ETHCryptoPayment_Output') {
              entity.nslAttributes.forEach((attr: any) => {
                if (attr.name === 'Transaction Hash') {
                  attr.values = [
                    this.transactionEndPointService.metaMaskTransactionHash,
                  ];
                }
              });
            }
          }
        );
      }
    }

    /* istanbul ignore next */
    if (
      this.transactionFacadeService.selectedTableAtrributeRecords.length > 0
    ) {
      const index = this.listData.currentCU.layers.findIndex((key: any) => {
        return key.type === 'triggerCES';
      });
      if (
        this.listData.currentCU.layers[index] &&
        Object.keys(data.details).length == 0
      ) {
        this.transactionFacadeService.selectedTableAtrributeRecords?.forEach(
          (key: any, i: any) => {
            if (i > 0) {
              key?.forEach((val: any) => {
                if (
                  val?.configuration &&
                  (val?.displayName
                    ? val.displayName != 'NSL_GERecordsSize'
                    : val?.name != 'NSL_GERecordsSize')
                ) {
                  //val.slot = 3;
                  if (
                    this.listData.currentCU?.reservedCUType ==
                    'NSL_Template_PaginatedEQLSearchResult'
                  ) {
                    this.listData.currentCU.layers[index].entityList.push({
                      ...val,
                      slot: 2,
                    });
                  } else if (this.listData.currentCU?.reservedCUType == 'NSL_EQL_IN_Memory') {
                    this.listData.currentCU.layers[index].entityList.push({ ...val, slot: 1 });
                  } else {
                    this.listData.currentCU.layers[index].entityList.push({
                      ...val,
                      slot: 3,
                    });
                  }
                }
              });
            }
          }
        );
        if (
          this.listData.currentCU?.reservedCUType ==
          'NSL_Template_PaginatedEQLSearchResult'
        ) {
          this.listData.currentCU.layers[index].entityList.splice(1, 1);
        } else if (this.listData.currentCU?.reservedCUType == 'NSL_EQL_IN_Memory') {
          this.listData.currentCU.layers[index].entityList.splice(0, 1);
        } else {
          this.listData.currentCU.layers[index].entityList.splice(2, 1);
        }
        this.transactionFacadeService.selectedTableAtrributeRecords = [];
        this.transactionFacadeService.selectedTableRecords = [];
      }
    }

    /**
     * Scorm player Adding the values based fetch value and default score and updating in the value
     */
    this.eventsService.scormStatus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          /*  */
          /* istanbul ignore next */
          const data = this.listData?.currentCU?.layers;
          /*  */
          /* istanbul ignore next */
          for (var i = 0; i < this.listData?.currentCU?.layers?.length; i++) {
            for (var j = 0; j < data[i].entityList?.length; j++) {
              for (
                var k = 0;
                k < data[i].entityList[j].nslAttributes?.length;
                k++
              ) {
                if (
                  data[i].entityList[j].nslAttributes[k].uiElement ===
                  'scormProgress'
                ) {
                  /* istanbul ignore next */
                  data[i].entityList[j].nslAttributes[k].values = [
                    this.getScormValueCalculate(
                      data?.[i]?.entityList?.[j]?.nslAttributes?.[k]
                        ?.values?.[0],
                      res?.runtimeData?.['cmi.score.raw']
                    ),
                  ];
                }
              }
            }
          }
          /* istanbul ignore next */
          this.listData.currentCU.layers = data;
        }
      });

    this.ocrFacadeService.resetOCRData(true);
    /*  */

    /* istanbul ignore next */
    if (this.listData?.currentCU?.id === undefined && !this.isOnboarding) {
      this.transactionFacadeService.isMccPopup = false;
      this.router.navigate(['/mylibrary-dashboard']);
    } else {
      // this.updatePreviousCus();
      if (this.isDebug) {
        this.listData.currentCU.layers?.forEach((layer: any) => {
          layer.entityList = this.renameEntity(layer.entityList, false);
        });
      }
      if(this.listData?.currentCU && this.listData?.currentCU?.contextualId && !this.listData?.gsiId){
        this.listData['gsiId'] = this.listData?.currentCU?.contextualId.match(/GS(\d+)/)[1];
      }
      this.transactionFacadeService.getListChangeUnitByGSIId(
        this.listData,
        true,
        this.isDebug,
        '',
        data.details,
        this.layoutConfigurations
      );
      this.previousCUList.push(this.listData);

      this.transactionFacadeService.savePrevCUs(this.previousCUList);
      // this.transactionFacadeService.getPreviousCus(this.nextCURequestPost.transId,this.nextCURequestPost.gsiId,this.nextCURequestPost.gsiId,this.nextCURequestPost.currentCU?.contextualId);
    }
    this.transactionFacadeService.setCardData(null);
  }

  formValueChange(data: any) {
    /*  */
    /* istanbul ignore next */
    const ind = this.listData?.currentCU?.layers?.findIndex((layer: any) => {
      return layer?.type === data?.layer;
    });
    /* istanbul ignore next */
    if (ind != -1) {
      /* istanbul ignore next */
      if (this.listData && this.listData?.currentCU) {
        /* istanbul ignore next */
        if (data && data?.layer == 'information') {
          /* istanbul ignore next */
          data.data = this.renameEntity(data?.data, true);
        }
        /* istanbul ignore next */
        this.listData.currentCU.layers[ind].entityList = data?.data;
        /* istanbul ignore next */
        //this.currentCu = this.listData?.currentCU;
      }
    }
  }

  gsiInformation(event: any) {
    /* istanbul ignore next */
    this.solutionData.gsiId = event?.id;
    this.getSelectedGSI();
  }
  selectedCU(cu: any) {
    this.currentChangeUnit = cu;
    /* istanbul ignore next */
    this.transactionFacadeService.debugData({
      transactionId: this.transactionCU.transId,
      cuId: cu?.DATA?.id,
      cuContextualId: cu?.DATA?.contextualId?.[0],
    });
    /* istanbul ignore next */
    this.transactionFacadeService.debugValue({
      transactionId: this.transactionCU?.transId,
      cuId: cu?.DATA?.id,
      cuContextualId: cu?.DATA?.contextualId?.[0],
    });
    if (
      cu?.DATA?.cuType === 'GSI' &&
      !this.currentChangeUnit?.DATA?.solutionLogic
    ) {
      this.changeUnitFacadeService.fetchEmbGsiDetails(cu.DATA.id);
    }
  }

  layerSelected(layer: string) {
    this.currentLayer = layer;
    /* istanbul ignore next */
    if (layer == 'physical') {
      this.layerShortCut = 'PL.SL';
    } else if (layer == 'information') {
      /*istanbul ignore next*/
      this.layerShortCut = 'IL.SL';
    } else if (layer == 'triggerCES') {
      /*istanbul ignore else */
      /*istanbul ignore next*/
      this.layerShortCut = 'triggerCES.SL';
    }
    // this.index=this.txnCulayer.findIndex((x:any)=>x.type == layer)
  }
  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  showAttr(ent: any, index: number, event: any) {
    event.stopPropagation();
    /*istanbul ignore next*/
    this.selectedEntity = ent;
    /*istanbul ignore next*/
    this.attributesList = ent?.nslAttributes;
    this.slot = index;
    let indexPadding = this.getIndexPadding(index + 1);
    /*istanbul ignore next*/
    this.layerKey = this.layerShortCut + indexPadding + '.' + ent?.name;
    this.setData(this.layerKey);
  }

  selectedAttr(attr: any) {
    /*istanbul ignore next*/
    this.layerAttr = this.layerKey + '.' + attr?.name;
    this.selectedAttribute = attr;
    this.setData(this.layerAttr);
  }

  setData(layerCode: any, attr?: any) {
    if (this.sourceInfoMap && this.sourceInfoMap?.hasOwnProperty(layerCode)) {
      this.sourcemap = this.sourceInfoMap[layerCode];
    } else {
      this.sourcemap = [];
    }
    if (this.targetInfoMap && this.targetInfoMap?.hasOwnProperty(layerCode)) {
      this.targetMap = this.targetInfoMap[layerCode];
    } else {
      this.targetMap = [];
    }
    this.value = attr?.values;
  }

  intialSelection(cu: any) {
    this.selectedCuDetails = cu;
  }

  cuDetails(cu: any) {
    this.cuinformationLayer = [];
    this.cuphysicalLayer = [];
    this.cutriggerCESLayer = [];
    /*istanbul ignore next*/
    if (cu.layers && cu.layers?.length > 0) {
      /* istanbul ignore next */

      cu.layers?.forEach((layer: any) => {
        /* istanbul ignore next */
        if (layer && layer?.entityList && layer?.entityList?.length > 0) {
          if (layer.type == 'information') {
            this.cuinformationLayer = [];
            this.informationLayer = [];

            /* istanbul ignore next */
            layer.entityList?.forEach((participatingItem: any) => {
              this.cuinformationLayer.push(participatingItem);
            });
          } else if (layer.type == 'physical') {
            this.cuphysicalLayer = [];
            /* istanbul ignore next */
            layer.entityList?.forEach((participatingItem: any) => {
              this.cuphysicalLayer.push(participatingItem);
            });
          } else if (layer.type == 'triggerCES') {
            this.cutriggerCESLayer = [];
            /* istanbul ignore next */
            layer.entityList?.forEach((participatingItem: any) => {
              this.cutriggerCESLayer.push(participatingItem);
            });
          }
        }
      });
      // getActiveCULayer
      /* istanbul ignore next */
    }
  }
  toggleDebugMode() {
    this.toggle = !this.toggle;
  }
  exit() {
    if (this.solutionData?.nodeGrammar == 'grammar') {
      this.router.navigateByUrl(`/node/canvas`);
      this.nodeGsiFacadeService.getCuDetails(this.solutionData?.gsiId);
    } else if (this.solutionData?.nodeGrammar == 'node') {
      localStorage.setItem('Node', 'node');
      this.router.navigateByUrl(`/node/system`);
      this.nodeGsiFacadeService.getCuDetails(this.solutionData?.gsiId);
    } else if (this.solutionData?.nodeGrammar == 'paragraph') {
      this.router.navigateByUrl(`/paragraph`);
      this.nodeGsiFacadeService.getCuDetails(this.solutionData?.gsiId);
    } else {
      /*istanbul ignore next*/
      this.router.navigateByUrl(`/newsolution`);
      /*istanbul ignore next*/
      this.libraryFacadeService.updateGSIdetails({
        id: this.solutionData?.gsiId,
      });
    }
  }
  async getTriggerCU(gsi: any) {
    /* istanbul ignore next */
    await this.transactionFacadeService.getTriggerCu(gsi.gsiId);
  }
  settingGsi(event: any) {
    this.nextCURequestPost.gsiId = event?.id;
    this.nextCURequestPost.gsiMasterId = event?.id;
    if (this.nextCURequestPost['currentCU']) {
      delete this.nextCURequestPost.currentCU;
    }
    if (this.nextCURequestPost['transId']) {
      delete this.nextCURequestPost.transId;
    }
    if (this.nextCURequestPost['triggerCuId']) {
      delete this.nextCURequestPost.triggerCuId;
    }
    if (this.nextCURequestPost['gsiContextualId']) {
      delete this.nextCURequestPost.gsiContextualId;
    }
    this.transactionFacadeService.getListChangeUnitByGSIId(
      this.nextCURequestPost,
      false,
      this.isDebug,
      undefined,
      this.solutionData?.page,
      this.layoutConfigurations
    );
  }

  preventAlert() {
    this.transactionFacadeService.preventAlert$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: boolean) => {
        this.ignoreWarning = res;
        this.ignoreWarningEmiter.emit(this.ignoreWarning);
      });
  }

  detectEvents() {
    /* istanbul ignore next */
    this.transactionFacadeService.eventGsiData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.eventGsiData = res;
        }
      });
  }

  openDemoPopUp() {
    this.dialog.open(DemoPopUpComponent, {
      maxWidth: '100vw',
      width: '100%',
      height: '100%',
    });
  }

  isLocationCu(cu: any, transId: any) {
    if (cu && cu?.name == 'NSL_LocationTracking_Reserved_CU') {
      if (transId) {
        let currUserInfo = JSON.parse(localStorage.getItem('ActiveUserInfo'));
        this.transactionFacadeService
          .getTransactionOwner(transId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (currUserInfo?.id == res?.result?.ownerId) {
              this.saveLocationData(cu, transId);
              this.transactionFacadeService.locationData.next({ owner: true });
            } else {
              this.transactionFacadeService.locationData.next({
                cu: cu,
                transId: transId,
                owner: false,
              });
            }
          });
      }
    }
  }
  saveLocationData(cu: any, transId: any) {
    this.prepareLocationData(cu, transId);
    this.locationInterval = setInterval(() => {
      this.prepareLocationData(cu, transId);
    }, 5000);
  }
  prepareLocationData(cu: any, transId: any) {
    let data: any;
    let location: string = '';
    /* istanbul ignore next */
    navigator?.geolocation?.getCurrentPosition((position: any) => {
      console.log(position, 'position');
      location = JSON.stringify({
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
      });
      data = {
        layerType: 'physical',
        numberOfSlots: 1,
        slotNumber: 0,
        txnBaseEntity: {
          cuContextualId: cu?.contextualId,
          transactionId: transId,
        },
        txnSlotItem: {
          item: {
            TYPE: 'TxnGeneralEntity',
            DATA: {
              name: 'NSL_LocationTrackEntity',
              generalEntityID: cu?.layers[0]?.entityList[0]?.id,
              transEntityRecords: [
                {
                  txnNslAttribute: [
                    {
                      name: 'Location',
                      nslAttributeID:
                        cu?.layers[0]?.entityList[0]?.nslAttributes[0]?.id,
                      values: [location],
                    },
                  ],
                },
              ],
              isLastBatch: false,
            },
          },
        },
      };
      this.transactionFacadeService
        .sendLocationData(data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          console.log(res);
        });
    });
  }

  getSubmiTransaction() {
    this.transactionFacadeService.submitForlocation$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          clearInterval(this.locationInterval);
        }
      });
  }

  getMultiVlaueEntities() {
    this.transactionFacadeService.addMultiEntities$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.currentCu = res.currentCU;
          this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
        }
      });
  }

  openDialog(templateRef: TemplateRef<any>) {
    /* istanbul ignore next */
    this.dialog.closeAll();
    this.dialog.open(templateRef, {
      width: '100%',
      panelClass: 'cuLayouts-popup',
      backdropClass: 'cuLayouts-popup-backdrop',
      disableClose: true,
    });
  }

  dialogClose() {
    this.previousGsidata = this.transactionFacadeService.pendingGsiData?.pop();
    this.popupClosed = true;
    this.transactionFacadeService.openPopup.next('close');
    this.getChangeUnitByGsiId(this.previousGsidata?.gsiId);
  }

  getDebugCu() {
    this.transactionFacadeService.debugPreviousCu$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.currentCu = res;
          this.currentCu = JSON.parse(JSON.stringify(this.currentCu));
        }
      });
  }
  getEmbeddedGSIInfo() {
    this.changeUnitFacadeService.embededGsiDetails$.subscribe((res: any) => {
      if (res?.solutionLogic) {
        this.currentChangeUnit.DATA.solutionLogic = res.solutionLogic;
      }
    });
  }
  /* istanbul ignore next */
  detectSelectedCard() {
    this.transactionFacadeService.cardsData$.subscribe((data: any) => {
      if (data) {
        this.selectedCard = data;
      }
    });
  }
  detectExit() {
    /* istanbul ignore next */
    this.transactionFacadeService.exitDebug$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.exit();
        } else {
          this.moveToLibrary(true);
        }
      });
  }
  detectCancelledTransaction() {
    this.transactionFacadeService.cancelledTransactionData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          console.log(res);
        }
      });
  }

  moveToLibrary(event: any) {
    this.myEventEmitter.emit(event);
  }

  /* this function will get all card id  present in that entity, and we use last cardid  */
  getAllCardIds() {
    this.newSolutionFacade.cardIds$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.data) {
          /* istanbul ignore next */
          if (res?.data?.length > 0) {
            /* istanbul ignore next */
            let cardId = res?.data?.[res?.data?.length - 1];
            this.detectEntityCardData(cardId);
          }
        }
      });
  }

  /*this function will get card configuration of particular ent card based on cardId */
  detectEntityCardData(cardId: any) {
    this.gsiFacadeService
      .getEntityCardDetails(cardId)
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res.result) {
          this.entityCardsFacetApiCall(res,cardId);
        }
      });
  }

  entityCardsFacetApiCall(res: any,cardId: any){
    this.urlBindingGsiId =
    res?.result?.filterDesign?.urlBindingData?.gsiId;
  this.urlBindingMasterId =
    res?.result?.filterDesign?.urlBindingData?.masterId;
  let req: any = {
    groupBy: [
      {
        fieldName:
        (this.urlBindingEntity?.name ? this.urlBindingEntity?.name :res?.result?.primaryEntity?.name ) +
          '.' +
          res?.result?.filterDesign?.urlBindingData?.attributeName,
      },
    ],
    searchQuery: {
      searchQueryDepth: 0,
      operationType: 'AND',
      queries: [
        {
          searchQueryDepth: 1,
          fieldName:
            (this.urlBindingEntity?.name ? this.urlBindingEntity?.name :res?.result?.primaryEntity?.name )+
            '.' +
            res?.result?.filterDesign?.urlBindingData?.attributeName,
          searchOperatorType: 'IN',
          values: [this.urlBindingAttrValue],
        },
      ],
    },
    functions: [],
    entityCardID: cardId,
    searchQueryValue: '',
    entitiesList: [(this.urlBindingEntity?.name ? this.urlBindingEntity?.name :res?.result?.primaryEntity?.name )],
    entityIdsList: [(this.urlBindingEntity?.id ? this.urlBindingEntity?.id :res?.result?.primaryEntity?.id )],
    action: 'SEARCH',
    attributesList: [],
    // this.urlBindingEntity.name + '.' + res.result.filterDesign.urlBindingData.attributeName
  };
  this.detectEntityData(req, 0, 1);
  }

  /*this function will fetch records for particular card of entity.  and we take record id and proceed to transaction*/
  detectEntityData(req: any, pageNum: any, pageSize: any) {
    this.gsiFacadeService
      .getGsiIdFromMasterId(this.urlBindingMasterId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const gsiId = result?.result?.id
          ? result.result?.id
          : this.urlBindingMasterId;
        this.gsiFacadeService
          .getEntityDataDetails(req, pageNum, pageSize, 'v1')
          ?.pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res?.result?.content[0]?.data[0]?.id) {
              let recordId: any = res?.result?.content[0]?.data[0]?.id;
              let solData: any = {
                gsiId: gsiId,
                masterId: this.urlBindingMasterId,
                recordId: recordId,
                entityName: (this.urlBindingEntity?.name ? this.urlBindingEntity?.name :res?.result?.content[0]?.data[0]?.name ),
              };
              solData = JSON.stringify(solData);
              this.detectActiveRoute(solData);
            }
          });
      });
  }

  openPostExceptions() {
    this.ignoreWarning = true;
    this.ignoreWarningEmiter.emit(this.ignoreWarning);
    if (this.postExceptionsData != '') {
      this.dialog.open(PostExceptionsViewComponent, {
        panelClass: 'post-exceptions',
        data: this.postExceptionsData,
      });
    }
  }

  getEntityCardByConfigurationName(configName: string){
    this.transactionEndPointService.getEntityCardByConfigurationName(configName).subscribe((res: any)=>{
      if(res?.result) this.entityCardsFacetApiCall(res,res?.result?.id);
    })
  }
}
