import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetime',
  /* istanbul ignore next */
  pure: true,
})
export class DatetimePipe implements PipeTransform {
  transform(item: any) {
    if (item) {
      let date = this.convertUTCDateToLocalDate(new Date(item));
      item = date.toLocaleString();
      return item;
    }
  }
  convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
}
