import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  GsiFacadeService,
  Librarytypes,
  NodeGsiFacadeService,
  TranslatorService,
  pagination
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-node-system-book',
  templateUrl: './node-system-book.component.html',
  styleUrls: ['./node-system-book.component.scss'],
})
export class NodeSystemBookComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();
  @Output() betCountEmitter = new EventEmitter();
  pageIndex: number = 0;
  pageSize: number = 15;
  searchText: string = '';
  bookList: any = {
    total: [],
    content: [],
  };
  displayNodesearch: any;
  labels: any;
  private ngUnsubscribe = new Subject();
  pageIndexBook: number = 0;
  pageSizeBook: number = 100;
  activeBookIndex: any;
  activeBook: any;
  alertModal: boolean = true;
  cloningGsiData: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private gsiFacadeService: GsiFacadeService,
    public dialog: MatDialog
  ) {
    this.getAllBooks();
    this.detectBooksList();
    this.detectLanguageChange();
    this.detectBooksGSI();
    this.getLibraryData();
  }

  ngOnInit(): void {}
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getAllBooks() {
    /* istanbul ignore next */
    this.gsiFacade.getAllBooks(this.pageIndex, this.pageSize, this.searchText);
  }
  detectBooksList() {
    this.gsiFacade.bookList$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.bookList = res.result;
      this.betCountEmitter.next(res?.result?.total);
    });
  }
  onPageChange(pageData: pagination) {
    this.pageSize = pageData.pageSize;
    this.pageIndex = pageData.pageIndex;
    this.getAllBooks();
  }

  bookAddFromLibrary(book: any) {
    this.gsiFacade.addBookFromLibrary(book?.id);
  }

  fetchBookGsis(book: any, ind: any) {
    this.activeBookIndex = ind;
    this.activeBook = book;
    this.gsiFacadeService.getBookGsis(book?.id, this.pageSizeBook, this.pageIndexBook, '');
  }

  detectBooksGSI() {
    this.gsiFacadeService.BookGsisSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res && res?.content) {
        this.activeBook['gsiList'] = res?.content;
      }
    });
  }

  importGsiData(gsi: any, ClonedtemplateRef: TemplateRef<any>) {
    this.alertModal = false;
    this.cloningGsiData = gsi;
    this.dialog.open(ClonedtemplateRef, { width: '520px' });
  }

  cloningGsi() {
    let gsi = this.cloningGsiData;
    this.gsiFacade.getCuDetails(gsi.dsdId);
    this.dialog.closeAll();
  }

  expandGsi(gsi: any, currentInd: number, book: any) {
    book['activeGsiIndex'] = currentInd;
    this.gsiFacade.viewBetDetails(gsi.id, Librarytypes.GSI);
  }

  getLibraryData() {
    this.gsiFacade.viewLibraryData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.activeBook['gsiData'] = res;
      }
    });
  }
  
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
