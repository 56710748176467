<div class="middle-content solution-pane">
  <div class="container-fluid">
    <div class="row justify-content-center row-0">
      <div class="dsd-main-panel">
        <section class="page-header">
          <div class="row align-items-end">
            <div class="col textRightAr">
              <h2 class="page-title" *ngIf="bookDetails?.marketplaceId.type === 'GSI'">
                <span class="configur-span">{{ bookDetails?.name }}</span>
                {{ labels?.configure_solution }}
              </h2>
              <h2 class="page-title" *ngIf="tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5">
                <span class="configur-span">{{ bookDetails?.name }}</span>
                Configure Book
                <span *ngIf="tabIndex === 3"> > Solution</span>
                <span *ngIf="tabIndex === 4"> > CU</span>
                <span *ngIf="tabIndex === 5"> > Entity</span>
              </h2>
              <h2 class="page-title" *ngIf="tabIndex === 0">
                Select roles
              </h2>
              <h2 class="page-title" *ngIf="tabIndex === 1">
                Roles Resolution
              </h2>
              <h2 class="page-title" *ngIf="tabIndex === 6">
                Dashboard
              </h2>
              <h2 class="page-title" *ngIf="tabIndex === 7">
                Dataset
              </h2>
              <h2 class="page-title" *ngIf="tabIndex === 8">
                Connection
              </h2>
            </div>
            <div class="col">
              <div class="bet-btns">
                <button
                  class="btn btnimport"
                  (click)="mergeOrMapRoles('merge')"
                  *ngIf="tabIndex === 0 && validateBook?.conflictsAndResolutions?.roleConflictCount"
                >
                  {{ labels?.Merge_with_Existing_Role }}
                </button>
                <button
                  class="btn btnimport"
                  (click)="mergeOrMapRoles('merge')"
                  *ngIf="tabIndex === 2 && validateBook?.conflictsAndResolutions?.bookConflictCount"
                >
                  Merge with Existing Book
                </button>
                <!-- <button class="btn btnimport" (click)="mergeOrMapRoles('merge')" *ngIf="tabIndex === 3">
                  Merge with Existing GSI
                </button>
                <button class="btn btnimport" (click)="mergeOrMapRoles('merge')" *ngIf="tabIndex === 4">
                  Merge with Existing ChangeUnit
                </button>
                <button class="btn btnimport" (click)="mergeOrMapRoles('merge')" *ngIf="tabIndex === 5">
                  Merge with Existing Entity
                </button> -->
                <button
                  class="btn btnimport"
                  (click)="mergeOrMapRoles('map')"
                  *ngIf="tabIndex === 0 && validateBook?.conflictsAndResolutions?.roleConflictCount"
                >
                  {{ labels?.Map_to_Existing_Roles }}
                </button>
              </div>
            </div>
          </div>
        </section>

        <section class="configure-solution-mapping">
          <div class="configure-timeline">
            <mat-tab-group [(selectedIndex)]="tabIndex" (selectedTabChange)="betToggleSelection($event)">
              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 0
                            ? 'number-large number-selected'
                            : completedTab[0]
                            ? 'number-large number-done'
                            : 'number-large'
                        "
                        >1</span
                      >
                    </div>
                    Role ({{ rolesLength }})
                  </div>
                </ng-template>
                <div
                  *ngIf="
                    userRolesList?.rolesList && validateBook?.conflictsAndResolutions?.roleConflicts && tabIndex === 0
                  "
                  class="configure-timeline-content"
                >
                  <app-conflict-roles
                    [(rolesConflicted)]="validateBook.conflictsAndResolutions.roleConflicts"
                    [(userRolesList)]="userRolesList.rolesList"
                    (changedroles)="changedallroles($event)"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [saasapplication]="saasapplication"
                  ></app-conflict-roles>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="m-44 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 1
                            ? 'number-small number-selected'
                            : completedTab[1]
                            ? 'number-small number-done'
                            : 'number-small'
                        "
                        >1.1</span
                      >
                    </div>
                    Roles resolution
                  </div>
                </ng-template>
                <div
                  *ngIf="validateBook?.conflictsAndResolutions?.roleConflicts && tabIndex === 1"
                  class="configure-timeline-content"
                >
                  <app-view-roles
                    [(messages)]="messageRoles"
                    [(rolesConflicted)]="validateBook.conflictsAndResolutions.roleConflicts"
                    [(rolesCount)]="validateBook.conflictsAndResolutions.roleCount"
                    (noOfRolesEmiter)="noOfRolesConflict($event)"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [saasapplication]="saasapplication"
                  ></app-view-roles>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 2
                            ? 'number-large number-selected'
                            : completedTab[2]
                            ? 'number-large number-done'
                            : 'number-large'
                        "
                        >2</span
                      >
                    </div>
                    Book
                  </div>
                </ng-template>
                <div *ngIf="validateBook && validateBook.conflictsAndResolutions && tabIndex === 2">
                  <app-configure-solutions
                    [(bookConflicts)]="validateBook.conflictsAndResolutions.bookConflicts"
                    [(gsiConflicts)]="validateBook.conflictsAndResolutions.gsiConflicts"
                    [(cuConflicts)]="validateBook.conflictsAndResolutions.cuConflicts"
                    [(geConflicts)]="validateBook.conflictsAndResolutions.geConflicts"
                    [(showBooks)]="solutionLevelVisibility.book"
                    [(showGsis)]="solutionLevelVisibility.gsi"
                    [(showCus)]="solutionLevelVisibility.cu"
                    [(showGes)]="solutionLevelVisibility.ge"
                    [(solutionConflictParameters)]="solutionConflictParameters"
                    (changes)="configureSolutionChanges($event)"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [selectedType]="currentBetTab"
                    [saasapplication]="saasapplication"
                  ></app-configure-solutions>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="m-13 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 3
                            ? 'number-small number-selected'
                            : completedTab[3]
                            ? 'number-small number-done'
                            : 'number-small'
                        "
                        >2.1</span
                      >
                    </div>
                    Solution
                  </div>
                </ng-template>

                <div *ngIf="validateBook && validateBook.conflictsAndResolutions && tabIndex === 3">
                  <app-configure-solutions
                    [(bookConflicts)]="validateBook.conflictsAndResolutions.bookConflicts"
                    [(gsiConflicts)]="validateBook.conflictsAndResolutions.gsiConflicts"
                    [(cuConflicts)]="validateBook.conflictsAndResolutions.cuConflicts"
                    [(geConflicts)]="validateBook.conflictsAndResolutions.geConflicts"
                    [(showBooks)]="solutionLevelVisibility.book"
                    [(showGsis)]="solutionLevelVisibility.gsi"
                    [(showCus)]="solutionLevelVisibility.cu"
                    [(showGes)]="solutionLevelVisibility.ge"
                    [(solutionConflictParameters)]="solutionConflictParameters"
                    (changes)="configureSolutionChanges($event)"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [selectedType]="currentBetTab"
                    [saasapplication]="saasapplication"
                  ></app-configure-solutions>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="m-13 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 4
                            ? 'number-small number-selected'
                            : completedTab[4]
                            ? 'number-small number-done'
                            : 'number-small'
                        "
                        >2.2</span
                      >
                    </div>
                    CU
                  </div>
                </ng-template>

                <div *ngIf="validateBook && validateBook.conflictsAndResolutions">
                  <app-configure-solutions
                    [(bookConflicts)]="validateBook.conflictsAndResolutions.bookConflicts"
                    [(gsiConflicts)]="validateBook.conflictsAndResolutions.gsiConflicts"
                    [(cuConflicts)]="validateBook.conflictsAndResolutions.cuConflicts"
                    [(geConflicts)]="validateBook.conflictsAndResolutions.geConflicts"
                    [(showBooks)]="solutionLevelVisibility.book"
                    [(showGsis)]="solutionLevelVisibility.gsi"
                    [(showCus)]="solutionLevelVisibility.cu"
                    [(showGes)]="solutionLevelVisibility.ge"
                    [(solutionConflictParameters)]="solutionConflictParameters"
                    (changes)="configureSolutionChanges($event)"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [selectedType]="currentBetTab"
                    [saasapplication]="saasapplication"
                  ></app-configure-solutions>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="m-13 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 5
                            ? 'number-small number-selected'
                            : completedTab[5]
                            ? 'number-small number-done'
                            : 'number-small'
                        "
                        >2.3</span
                      >
                    </div>
                    Entity
                  </div>
                </ng-template>

                <div *ngIf="validateBook && validateBook.conflictsAndResolutions">
                  <app-configure-solutions
                    [(bookConflicts)]="validateBook.conflictsAndResolutions.bookConflicts"
                    [(gsiConflicts)]="validateBook.conflictsAndResolutions.gsiConflicts"
                    [(cuConflicts)]="validateBook.conflictsAndResolutions.cuConflicts"
                    [(geConflicts)]="validateBook.conflictsAndResolutions.geConflicts"
                    [(showBooks)]="solutionLevelVisibility.book"
                    [(showGsis)]="solutionLevelVisibility.gsi"
                    [(showCus)]="solutionLevelVisibility.cu"
                    [(showGes)]="solutionLevelVisibility.ge"
                    [(solutionConflictParameters)]="solutionConflictParameters"
                    (changes)="configureSolutionChanges($event)"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [selectedType]="currentBetTab"
                    [saasapplication]="saasapplication"
                  ></app-configure-solutions>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="mb-113 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 6
                            ? 'number-large number-selected'
                            : completedTab[6]
                            ? 'number-large number-done'
                            : 'number-large'
                        "
                        >3</span
                      >
                    </div>
                    Dashboard
                  </div>
                </ng-template>

                <div *ngIf="validateBook?.conflictsAndResolutions">
                  <app-dashboard-conflicts
                    [(dashboardsConflicted)]="validateBook.conflictsAndResolutions.dashBoardConflicts"
                    (changedDashboards)="changedallDashboards($event)"
                    [saasapplication]="saasapplication"
                  ></app-dashboard-conflicts>
                </div>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="mb-113 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 7
                            ? 'number-large number-selected'
                            : completedTab[7]
                            ? 'number-large number-done'
                            : 'number-large'
                        "
                        >4</span
                      >
                    </div>
                    Dataset
                  </div>
                </ng-template>

                <div *ngIf="validateBook?.conflictsAndResolutions">
                  <app-dataset-conflicts
                    [(datasetsConflicted)]="validateBook.conflictsAndResolutions.dataSetConflicts"
                    (changedDatasets)="changedallDatasets($event)"
                    [saasapplication]="saasapplication"
                  ></app-dataset-conflicts>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="mb-113 number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 8
                            ? 'number-large number-selected'
                            : completedTab[8]
                            ? 'number-large number-done'
                            : 'number-large'
                        "
                        >5</span
                      >
                    </div>
                    Connections
                  </div>
                </ng-template>

                <div *ngIf="validateBook?.conflictsAndResolutions">
                  <app-connection
                    [(connectionsConflicted)]="validateBook.conflictsAndResolutions.connectionConflicts"
                    (changedConnections)="changedallConnections($event)"
                    [saasapplication]="saasapplication"
                  >
                  </app-connection>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div class="number-flex">
                    <div class="count-area">
                      <span
                        [ngClass]="
                          tabIndex === 9
                            ? 'number-large number-selected'
                            : completedTab[9]
                            ? 'number-large number-done'
                            : 'number-large'
                        "
                        >6</span
                      >
                    </div>
                    Overview
                  </div>
                </ng-template>
                <div class="book-overview">
                  <app-bet-book-overview
                    *ngIf="tabIndex === 9"
                    [(bookFinalize)]="bookFinalize"
                    [(solutionChanges)]="solutionChanges"
                    [(bookDetails)]="bookDetails"
                    [(NoOfRolesConflictedIndividually)]="NoOfRolesConflictedIndividually"
                    [isDsdRhs]="bookDetails?.isDsdRhs"
                    [betType]="selectedBetType"
                    [saasapplication]="saasapplication"
                    [targetTenant]="targetTenant"
                  >
                  </app-bet-book-overview>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
