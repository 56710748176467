import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { RecordAudioComponent } from './record-audio.component';

@NgModule({
  declarations: [RecordAudioComponent],
  imports: [ControlsMaterialModule,],
  exports: [RecordAudioComponent],
})
export class RecordAudioModule {
  static entry = {
    audiorecording: RecordAudioComponent,
  };
}
