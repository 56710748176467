import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { getLocalStorageValue } from '@common-services';
import { CredentialsService } from '@common-services';

@Injectable({providedIn: 'root'})
export class CarnivalGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLogin = route?.data?.login;
    if (isLogin) {
      if (this.credentialsService.isAuthenticated()) {
        const carnivalPersona = localStorage.getItem('carnivalPersona');
        if (carnivalPersona) {
          this.router.navigate([`/carnival/category/${carnivalPersona}/ref_pg/1`]);
        } else {
          this.router.navigateByUrl('/mylibrary-dashboard');
        }
        return false;
      } else {
        return true;
      }
    }
    let carnivalEventFlag = getLocalStorageValue('carnivalEventFlag');
    if (carnivalEventFlag) {
      const getLocalStorageValues = JSON.parse(carnivalEventFlag);

      const { result } = getLocalStorageValues;
      if (result === true) {
        return true;
      } else {
        return false;
      }
    }
  }
}
