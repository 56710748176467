<div>
  <div [formGroup]="group">
    <div class="d-flex justify-content-between nh-column-gap-10">
      <div class="d-flex nh-mw-0">
        <label
          pTooltip="{{ field.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          class="main-label"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
        >
          <span class="main-label-inner">
            <span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          >
        </label>
      </div>
      <div
      class="d-flex align-items-center nh-column-gap-5 pb-2"
      *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
    >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="addAttribute.next()"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="removeAttribute.next()"
        >remove</mat-icon
      >
    </div>
    </div>
    <div class="currentTime-wrapper">
      <input
        class="current-time-input"
        [matTooltip]="field?.configuration?.hideTooltip ? '' : field.value"
        [matTooltipPosition]="'above'"
        type="text"
        [(value)]="field.value"
      />
      <em class="nh-icon nh-icon-time currentTime-icon"></em>
    </div>
  </div>
</div>
