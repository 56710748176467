import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NodeGsiFacadeService, SolutionVersioningFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Gsi } from '../../models/gsi';
import { ChangeUnit } from '../../models/change-unit';
import { ReviewStatusComponentNodelib } from '../review-status/review-status.component';

@Component({
  selector: 'app-nsl-review-approval-screen',
  templateUrl: './nsl-review-approval-screen.component.html',
  styleUrls: ['./nsl-review-approval-screen.component.scss'],
})

export class NslReviewApprovalScreenComponent implements OnInit, OnDestroy {
  solutionData: any = {};
  unlinkList: any = [];
  gsiSuggestions: any;
  gsiList: any = [{ ...new Gsi() }];
  changeUnitListAny: ChangeUnit[] = [];
  selectedLabel: string = '';
  appDetails = { ...new Gsi(), isEditable: false };
  showAlternateImplementation = false;
  activeUserName: any;
  disableApprove: boolean = true;
  labels: any;
  ownerId: any;
  primaryId: any;
  primaryDsdId: any;
  primaryName: any;
  finishType: string;
  textReviewNotes: any;
  private ngUnsubscribe = new Subject();
  reviewData: any;
  accordianResponse: any;
  author: any = [];
  id: any;
  submitterId: any = [];
  displayName: any;
  sumbitClick: boolean = true;
  pageNo: number = 0;
  pageSize: number = 6;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<NslReviewApprovalScreenComponent>,
    private gsiFacade: NodeGsiFacadeService,
    private versioningFacade: SolutionVersioningFacadeService,
    private translator: TranslatorService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    /* istanbul ignore else */
    if (data) {
      this.id = data?.primaryId;
      this.primaryDsdId = data?.primaryDsdId;
      this.primaryName = data?.primaryName;
      this.finishType = data?.finishType;
      this.displayName = data?.displayName;
    }
    this.detectfinishNotification();
    this.detectLanguageChange();
    this.detectSelectedSubmitterId();
    this.showReviewStatusData();
  }

  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectSelectedSubmitterId() {
    this.versioningFacade.selectSubmitterId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.submitterId = [res];
      }
    });
  }

  detectfinishNotification() {
    this.versioningFacade.finishNotification$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.reviewData = res;
        this.finishType = res?.finishType;
        const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
        this.accordianResponse = res;
        this.primaryId = res?.tfId;
        this.primaryDsdId = res?.id;
        this.primaryName = res?.name;
        /* istanbul ignore next */
        this.ownerId = activeUser?.id === res?.ownerId ? true : false;
        /* istanbul ignore next */
        this.activeUserName = activeUser?.email;
        this.loadReviewersInfo();
        this.detectReviewInfo(this.reviewData);
      }
    });
  }

  loadReviewersInfo() {
    if (this.primaryId && this.ownerId) {
      this.versioningFacade.loadReviewers(this.primaryId, this.finishType, this.pageNo, this.pageSize);
    }
  }

  detectReviewInfo(data: any) {
    this.versioningFacade.detectReviewInfo(data);
  }

  showReviewStatus() {
    this.versioningFacade.showReviewStatus(this.reviewData);
  }

  showReviewStatusData() {
    this.versioningFacade.reviewStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        const dialogRef = this.dialog.open(ReviewStatusComponentNodelib, {
          width: '70%',
          data: res,
        });

        dialogRef.afterClosed().subscribe((result) => {});
      }
    });
  }

  onApproveReject(type: any) {
    const data = { id: this.id, dsdId: this.data?.primaryDsdId, name: this.data?.primaryName };
    const comment = this.textReviewNotes || 'no comments';
    const finishType = this.data?.finishType === 'cu' ? 'change-unit' : this.data?.finishType.toLowerCase();
    this.versioningFacade.approveOrRejectBET(data, type, finishType, comment);
    this.sendReviewNotifications();
  }

  sendReviewNotifications() {
    let targetType;
    if (this.data?.finishType === 'gsi') {
      targetType = 'Gsi';
    } else {
      targetType = this.data?.finishType === 'cu' ? 'Cu' : 'Entity';
    }
    const actionableContent = {
      target_type: targetType,
      target_id: this.data?.primaryId,
      dsdId: this.data?.primaryDsdId,
      target_name: this.data?.primaryName,
      context_cu: '',
      context_gsi: '',
      approvals: true,
    };

    const body = {
      title: `Review ${this.finishType}`,
      targetUserId: this.submitterId,
      content: this.textReviewNotes,
      actionableContent: actionableContent,
      notificationChannels: ['PUSH'],
    };
    this.versioningFacade.sendNotifications(body);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
