import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-rolefrom-public-user-role',
  templateUrl: './select-rolefrom-public-user-role.component.html',
  styleUrls: ['./select-rolefrom-public-user-role.component.scss'],
})
export class SelectRolefromPublicUserRoleComponent implements OnInit, OnDestroy {
  roleSelectedForGSIExecution: any;
  private ngUnsubscribe = new Subject();

  constructor(
    public dialogRef: MatDialogRef<SelectRolefromPublicUserRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  submit() {
    this.dialogRef.close(this.roleSelectedForGSIExecution);
  }
  skip() {
    this.dialogRef.close({});
  }
  ngOnInit(): void {}
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  // public objectComparisonFunction = function( option, value ) : boolean {
  //   return option.id === value.id;
  // }
}
