import { Component, EventEmitter, Injector, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { getDTFormat, TransactionFacadeService } from '@common-services';
import { generateImpressionCallObject } from '@common-services';
import { generateClickadObject } from '@common-services';

@Component({
  selector: 'app-advertisement-panel',
  templateUrl: './advertisement-panel.component.html',
  styleUrls: ['./advertisement-panel.component.scss'],
})
export class AdvertisementPanelComponent implements OnInit {
  gsiDataObj: any;
  private ngUnsubscribe = new Subject();
  topBannerData: any;
  timeIntervalofTopAds: any;
  userInfo:any;
  gsi:any;
  @Input() transactionCU: any;
  transactionID:any;

  constructor(
    public transactionFacadeService: TransactionFacadeService,
  ) {
    let self = this;
    self.startTimeIntervalTopAds();
    this.getTopBannerAd();
  }
  getTopBannerAd() {
    this.transactionFacadeService.transactionTopAds$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res?.adId) {
        this.topBannerData = { ...res, gsiDataObj: this.gsiDataObj };
        let impressionId: any = Guid.create();
        this.topBannerData = { ...this.topBannerData, impressionId: impressionId?.value };
        this.makeImpression(this.topBannerData);
        this.startTimeIntervalTopAds();
      }
    });
  }
  startTimeIntervalTopAds() {
    if (this.topBannerData?.duration != undefined || this.topBannerData?.duration != null) {
      /* istanbul ignore next */
      clearTimeout(this.timeIntervalofTopAds);
      this.timeIntervalofTopAds = setTimeout(() => {
        if (this.topBannerData) {
          this.setTopBannerData();
        }
      }, this.topBannerData?.duration * 1000);
    }
  }
  setTopBannerData() {
    if (this.topBannerData.gsiData) {
      let correlatorValue: any = Guid.create();
      /* istanbul ignore next */
      this.topBannerData.gsiData.dateTime = getDTFormat();
      /* istanbul ignore next */
      this.topBannerData.gsiData.correlatorValue = correlatorValue?.value;
      clearTimeout(this.timeIntervalofTopAds);
      this.transactionFacadeService.getTopBannerAds(this.topBannerData.gsiData);
    }
  }
  ngOnInit(): void {
    this.userInfo = localStorage.getItem('userInfo');
    this.userInfo = JSON.parse(this.userInfo);   
  }
  getGsiObj(){
    this.transactionFacadeService.gsiObj$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
      if(res){
        this.gsi = res;
      }
    });
  }
  /**
   * to make impression API call when ad shown on UI
   * @param impression
   */
  makeImpression(ad: any) {
    let adObj = generateImpressionCallObject(
      ad,
      {
        userInfo:this.userInfo,
        gsiName:this.gsi?.name,
        localStorage:localStorage,
        transId:this.transactionCU?.transId
      }
    );
    Object.keys(adObj).forEach((key: any) => adObj[key] === undefined && delete adObj[key]);
    this.transactionFacadeService.makeImpression(adObj);
  }

   /**
 * to make click API call when clicked on specific Ad
 * @param clickedAd
 */
  adClicked(clickedAd: any, subImg?: any) {
    // let clickData: any = [];
    // let correlatorValue: any = Guid.create();
    let uniqueClickId: any = Guid.create();
    let adObj = generateClickadObject(
      clickedAd,
      {
        uniqueClickId:uniqueClickId,
        userInfo:this.userInfo,
        gsiName:this.gsi?.name
      }
    );
    // clickData.push(adObj);
    this.transactionFacadeService.adClick(adObj);
    // window.location.href= clickedAd.destinationUrl;
    /* istanbul ignore next */
    if (subImg) {
      window.open(subImg?.destinationUrl, '_blank');
    } else {
      window.open(clickedAd?.destinationUrl, '_blank');
    }
  }
}
