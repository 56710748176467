<!-- <div class="rating-box">
    <div class="rating" [ngStyle]="{'width':'100%' }" style="width:55%;" (click)="onClick($event)"></div>
</div> -->
<div class="d-flex align-items-center nh-mb-8 main-label-wrapper">
  <label
    class="main-label nh-mb-0"
    pTooltip="{{ field?.label }}"
    tooltipPosition="top"
    tooltipStyleClass="transcustomtolltipbook"
    [style.color]="field.color"
    [style.font-size.px]="field.fontSize"
    *ngIf="
      (field?.sentence == null || field?.sentence === '') &&
      !field?.hideLabels &&
      !field?.configuration?.hideLabel &&
      !field?.hideLabelMultiAttr
    "
    ><span class="main-label-inner">
      <span class="main-label-text text-truncate"
        >{{ field.label }} <span class="main-label-colon">:</span></span
      >
      <span
        *ngIf="field?.isRequired && !field?.isInfo"
        class="mat-form-field-required-marker"
        >*</span
      ></span
    ></label
  >
  <p
    class="nh-ml-6 d-flex main-label-helperIcon"
    *ngIf="field?.configuration?.showHelperTextIcon"
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    <mat-icon
    [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
      class="material-icons-outlined helperText-infoIcon"
      matTooltip="{{ field?.configuration?.helpertext }}"
      matTooltipPosition="above"
      *ngIf="!field?.hideLabels"
      >info_outline</mat-icon
    >
  </p>
</div>
<p
  class="top-text"
  *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
  [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
>
  {{ field?.configuration?.helpertext }}
</p>
<div class="main-star-div" [formGroup]="group" [ngClass]="{
  inValidClass:
    !field?.isInfo &&
    (group?.controls)[field?.attribute?.name]?.invalid &&
    (group?.controls)[field?.attribute?.name]?.touched
}">
  <input
    style="display: none;"
    matInput
    [value]="field.value"
    [formControlName]="field?.attribute?.name"
    [type]="field.type"
    [readonly]="field.readonly"
    [required]="field.isRequired"
    id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
  />
  <div *ngFor="let star of stars; let i = index">
    <div class="rating-box-selection" (click)="onClick(i)">
      <div class="rating-selection" [ngStyle]="{ width: star.width, color: field?.configuration?.color }"></div>
    </div>
  </div>
  <p
    class="bottom-text"
    *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
    [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
</div>
