<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <li
      *ngIf="!data.isImage"
      [id]="attributeItem?.innerHTML"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      [_style]="style"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      [tabSelected]="tabSelected"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="styleAttribute[data?.position]"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
    </li>
    <li
      *ngIf="data?.isImage"
      id="attr-{{ data?.position }}"
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      [ngClass]="data?.class"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
    </li>
  </ng-container>
  <ng-container *ngIf="data.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.isImage">
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue"
        pTooltip="{{ (card?.recordData)?.[data?.position]?.value }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleDisplay"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.displayName }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        *ngIf="data?.isSingleValueWOC"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <ng-container *ngIf="data?.isReadMore">
        <app-read-more
          [text]="(card?.recordData)?.[data?.position]?.value"
          [ngClass]="data?.class"
          [height]="data?.height"
        >
        </app-read-more>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data?.isImage">
      <img
        [ngClass]="data?.class"
        *ngIf="(card?.recordData)[data.position]?.contentUrl"
        [src]="(card?.recordData)[data.position]?.contentUrl | CDNUrlRewritePipe"
        alt="general-entity"
      />
    </ng-container>
  </ng-container>
</ng-template>
<div class="features-preview-cards">
  <div class="entitytemplates">
    <!-- <div class="col-md-12 {{ (card?.recordData)?.[1]?.value }}-4" *ngIf="templateType == '183'"> -->
    <div class="entity-board-banner entity-board-banner-1-padding" *ngIf="templateType == '183'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-1"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <li class="title">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'title-highlight',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '184'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-2"
      >
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '185'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-3"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div class="entity-board-banner entity-board-banner-4-padding" *ngIf="templateType == '186'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-4"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '187'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-5"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '188'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-6"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '189'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-7"
      >
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '190'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-8"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '191'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-9"
      >
        <div class="graphic"></div>
        <div class="container">
          <div class="row no-gutters content">
            <div class="col-auto left-content-image">
              <ul>
                <li class="image">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
              </ul>
            </div>
            <div class="col left-content">
              <ul class="banner-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      class: 'title',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 2,
                      class: 'desc',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '192'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-10"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>
    <div *ngIf="templateType == '193'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-11"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'desc',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>

    <div *ngIf="templateType == '194'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-12"
      >
        <ul>
          <li class="image">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </li>
        </ul>
        <div class="container">
          <ul class="banner-text">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'title',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </section>
    </div>

    <div *ngIf="templateType == '195'">
      <section
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
        class="entity-board-banner entity-board-banner-13"
      >
        <div class="container">
          <div class="row">
            <div class="col leftside-content">
              <ul class="banner-text">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      class: 'title',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      class: 'desc',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 2,
                      class: 'rating',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 3,
                      class: 'sub-desc',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 4,
                      class: 'title',
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
            <div class="col-auto rightside-content">
              <ul>
                <li class="image">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 5, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div *ngIf="templateType == '228'">
      <div class="faq-viewTemplate faq-template">
        <div
          class="nh-faq-accordian nh-faq-accordian-one faq-card-maxwidth"
          [ngClass]="{ 'nh-faq-accordian-open': accordion }"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="nh-faq-accordian-header">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'nh-faq-accordian-header-text',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
            <button
              class="nh-faq-accordian-header-toggle text-center"
              (click)="accordion = !accordion; $event?.stopPropagtion()"
            >
              <em class="nh-icon nh-icon-chevron-down passcolor"></em>
            </button>
          </div>
          <div class="nh-faq-accordian-content" [ngStyle]="{ color: attributeColors?.[1] }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'nh-faq-accordian-content-inner',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </div>
        </div>
        <!-- <p-accordion class="faq-viewTemplate_one faq-card-maxwidth">
            <p-accordionTab header="{{(card?.recordData)?.[0]?.value}}">
              <span [ngStyle]="{ color: attributeColors?.[1] }">{{ (card?.recordData)?.[1]?.value }}</span>
            </p-accordionTab>
          </p-accordion> -->
      </div>
    </div>

    <div *ngIf="templateType == '230'">
      <div class="faq-viewTemplate faq-template">
        <div
          class="nh-faq-accordian nh-faq-accordian-three faq-card-maxwidth"
          [ngClass]="{ 'nh-faq-accordian-open': accordion }"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="nh-faq-accordian-header">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'nh-faq-accordian-three_image nh-radius-16 position-absolute',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
            <div class="nh-faq-accordian-header-text">
              <h1 class="title">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </h1>
              <h5 class="subtitle">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 2,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </h5>
            </div>
            <button
              class="nh-faq-accordian-header-toggle text-center"
              (click)="accordion = !accordion; $event?.stopPropagtion()"
            >
              <em class="nh-icon nh-icon-chevron-down passcolor"></em>
            </button>
          </div>
          <div class="nh-faq-accordian-content" [ngStyle]="{ color: attributeColors?.[3] }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  class: 'nh-faq-accordian-content-inner',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </div>
        </div>
        <!-- <p-accordion class="faq-viewTemplate_three faq-card-maxwidth">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <img
                  class="faq-viewTemplate_three_image nh-radius-16 position-absolute"
                  [src]="(card?.recordData)?.[0]?.contentUrl | CDNUrlRewritePipe"
                  alt="general-entity"
                />
                <h1 class="title">{{ (card?.recordData)?.[1]?.value }}</h1>
                <h5 class="subtitle">{{ (card?.recordData)?.[2]?.value }}</h5>
              </ng-template>
              <span [ngStyle]="{ color: attributeColors?.[3] }">{{ (card?.recordData)?.[3]?.value }}</span>
            </p-accordionTab>
          </p-accordion> -->
      </div>
    </div>

    <div *ngIf="templateType == '232'">
      <div class="faq-viewTemplate faq-template">
        <div
          class="nh-faq-accordian nh-faq-accordian-five faq-card-maxwidth"
          [ngClass]="{ 'nh-faq-accordian-open': accordion }"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="nh-faq-accordian-header">
            <div class="nh-faq-accordian-header-text">
              <h1 class="title">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </h1>
              <h5 class="subtitle">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </h5>
            </div>
            <button
              class="nh-faq-accordian-header-toggle text-center"
              (click)="accordion = !accordion; $event?.stopPropagtion()"
            >
              <em class="nh-icon nh-icon-chevron-down passcolor"></em>
            </button>
          </div>
          <div class="nh-faq-accordian-content" [ngStyle]="{ color: attributeColors?.[3] }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'nh-faq-accordian-content-inner',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </div>
        </div>
        <!-- <p-accordion class="faq-viewTemplate_five faq-card-maxwidth">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <h1 class="title">{{ (card?.recordData)?.[0]?.value }}</h1>
                <h5 class="subtitle">{{ (card?.recordData)?.[1]?.value }}</h5>
              </ng-template>
              <span [ngStyle]="{ color: attributeColors?.[3] }">{{ (card?.recordData)?.[2]?.value }}</span>
            </p-accordionTab>
          </p-accordion> -->
      </div>
    </div>

    <div *ngIf="templateType == '229'">
      <div class="faq-viewTemplate faq-template">
        <div
          class="nh-faq-accordian nh-faq-accordian-two faq-card-maxwidth"
          [ngClass]="{ 'nh-faq-accordian-open': accordion }"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="nh-faq-accordian-header">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'nh-faq-accordian-two_image rounded-circle position-absolute',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
            <div class="nh-faq-accordian-header-text">
              <h1 class="title">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </h1>
            </div>
            <button
              class="nh-faq-accordian-header-toggle text-center"
              (click)="accordion = !accordion; $event?.stopPropagtion()"
            >
              <em class="nh-icon nh-icon-chevron-down passcolor"></em>
            </button>
          </div>
          <div class="nh-faq-accordian-content" [ngStyle]="{ color: attributeColors?.[2] }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'nh-faq-accordian-content-inner',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </div>
        </div>
        <!-- <p-accordion class="faq-viewTemplate_two faq-card-maxwidth">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <img
                  class="faq-viewTemplate_two_image rounded-circle position-absolute"
                  alt="general-entity"
                  [src]="(card?.recordData)?.[0]?.contentUrl | CDNUrlRewritePipe"
                  alt="general-entity"
                />
                <h1 class="title">{{ (card?.recordData)?.[1]?.value }}</h1>
              </ng-template>
              <span [ngStyle]="{ color: attributeColors?.[2] }">{{ (card?.recordData)?.[2]?.value }}</span>
            </p-accordionTab>
          </p-accordion> -->
      </div>
    </div>

    <div *ngIf="templateType == '231'">
      <div class="faq-viewTemplate faq-template">
        <div
          class="nh-faq-accordian nh-faq-accordian-four faq-card-maxwidth"
          [ngClass]="{ 'nh-faq-accordian-open': accordion }"
          [ngStyle]="{ 'background-color': cardColor }"
          CardDesignConfig
          [isEdit]="isEdit"
          [id]="'Template-' + templateType"
          [_changeShadowStyle]="boxShadowData"
          [style]="style"
          [_selectedDirectiveId]="selectedDirectiveId"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [cardBorderSize]="cardBorderSize"
          [cardBorderRadius]="cardBorderRadius"
          [cardBorderColor]="cardBorderColor"
          [cardShadow]="cardShadow"
          [cardColor]="cardColor"
          [card]="card"
          [cardDisplay]="cardDisplay"
          [(cardStyle)]="cardStyle"
        >
          <div class="nh-faq-accordian-header">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  isImage: true,
                  class: 'nh-faq-accordian-four_image position-absolute',
                  cardDisplay: cardDisplay
                }
              }"
            ></ng-template>
            <div class="nh-faq-accordian-header-text">
              <h1 class="title">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </h1>
            </div>
            <button
              class="nh-faq-accordian-header-toggle text-center"
              (click)="accordion = !accordion; $event?.stopPropagtion()"
            >
              <em class="nh-icon nh-icon-chevron-down passcolor"></em>
            </button>
          </div>
          <div class="nh-faq-accordian-content" [ngStyle]="{ color: attributeColors?.[2] }">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  class: 'nh-faq-accordian-content-inner',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </div>
        </div>
        <!-- <p-accordion class="faq-viewTemplate_four faq-card-maxwidth">
            <p-accordionTab>
              <ng-template pTemplate="header">
                <img
                  class="faq-viewTemplate_four_image position-absolute"
                  alt="general-entity"
                  [src]="(card?.recordData)?.[0]?.contentUrl | CDNUrlRewritePipe"
                  alt="general-entity"
                />
                <h1 class="title">{{ (card?.recordData)?.[1]?.value }}</h1>
              </ng-template>
              <span [ngStyle]="{ color: attributeColors?.[2] }">{{ (card?.recordData)?.[2]?.value }}</span>
            </p-accordionTab>
          </p-accordion> -->
      </div>
    </div>
    <div class="s-dashboard-1" *ngIf="templateType == '300'">
      <div
        class="sd-1 entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="sd-1-template">
          <ul class="content">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'class',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'member',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
          <ul>
            <li class="image">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="templateType == '301'">
      <div
        class="table-calender entity-template-type"
        [ngStyle]="{ 'background-color': cardColor }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <div class="s-dashboard-1-table">
          <h2 class="entity-name">Entity Name</h2>
          <div class="table">
            <ul class="table-header color1">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">ACTION</li>
            </ul>
            <ul class="table-row">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row color2">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row color2">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row color2">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row color2">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
            <ul class="table-row color2">
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column">Attribute</li>
              <li class="column btns">
                <button class="respond-btn text-truncate">Action Button</button>
                <button class="respond-btn text-truncate">Action Button</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="templateType == '302'">
      <div class="table-calender entity-template-type">
        <div class="s-dashboard-1-right">
          <div
            class="right-entity"
            [ngStyle]="{ 'background-color': cardColor }"
            CardDesignConfig
            [isEdit]="isEdit"
            [id]="'Template-' + templateType"
            [_changeShadowStyle]="boxShadowData"
            [style]="style"
            [_selectedDirectiveId]="selectedDirectiveId"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [cardBorderSize]="cardBorderSize"
            [cardBorderRadius]="cardBorderRadius"
            [cardBorderColor]="cardBorderColor"
            [cardShadow]="cardShadow"
            [cardColor]="cardColor"
            [card]="card"
            [cardDisplay]="cardDisplay"
            [(cardStyle)]="cardStyle"
          >
            <div class="entity-name">
              <h3 class="name text-truncate">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      cardDisplay: cardDisplay,
                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </h3>
            </div>
            <div class="entities">
              <ul class="entity">
                <li class="image text-truncate">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 1, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <div>
                  <ul class="content">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          cardDisplay: cardDisplay,
                          class: 'heading lineclamp',
                          isSingleValue: true
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 3,
                          cardDisplay: cardDisplay,
                          class: 'hours text-truncate',
                          isSingleValue: true
                        }
                      }"
                    ></ng-template>
                  </ul>
                </div>
              </ul>
              <ul class="entity">
                <li class="image text-truncate">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 4, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <li>
                  <ul class="content">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 5,
                          cardDisplay: cardDisplay,
                          class: 'heading lineclamp',
                          isSingleValue: true
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 6,
                          cardDisplay: cardDisplay,
                          class: 'hours text-truncate',
                          isSingleValue: true
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
              <ul class="entity">
                <li class="image text-truncate">
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: { position: 7, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
                    }"
                  ></ng-template>
                </li>
                <li>
                  <ul class="content">
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 8,
                          cardDisplay: cardDisplay,
                          class: 'heading lineclamp',
                          isSingleValue: true
                        }
                      }"
                    ></ng-template>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 9,
                          cardDisplay: cardDisplay,
                          class: 'hours text-truncate',
                          isSingleValue: true
                        }
                      }"
                    ></ng-template>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="templateType == '329'">
      <div
        class="hrms-template"
        [ngStyle]="{ 'background-color': cardColor }"
        [ngClass]="{ active: cardSelected }"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + templateType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              class: 'hrms-title text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
        <div class="hrms-data">
          <ul>
            <li>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 1,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 2,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
            </li>
            <li>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 3,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 4,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
            </li>
            <li>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 5,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 6,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
            </li>
            <li>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 7,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
              <span>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 8,
                      cardDisplay: cardDisplay,
                      isSingleValueWOC: true
                    }
                  }"
                ></ng-template>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
