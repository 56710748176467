import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { debounceTime, takeUntil, startWith, map } from 'rxjs/operators';
import { ChangeUnitFacadeService } from '../change-unit/change-unit-facade.service';
import { EntityFacadeService } from '../entity/entity-facade.service';
import { EventsFacadeService } from '../events/events-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { LibraryFacadeService } from '../library/library-facade.service';
import { pagination } from '../modal/pagination';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';
import { solutionLogicDeMapper } from '../mappers/solution-logic/solutionLogic-demapper';
import { validateRights } from '../../generic-folder/generic-functions';

export interface DialogData {
  eventCUList: any[];
  tempGsiObj: Object;
  changeUnit: Object;
  currentAttr: any;
  sourceContextualId: string;
  entityIndex?: any;
  groupedEvent?: any;
}
export interface EmbededGSIData {
  gsiName: string;
  index: number;
}

interface onSelectCustomization {
  id: String;
  gsiName: String;
  displayType: String;
  displayText: String;
  imageUrl: String;
  eventType: String;
}

@Component({
  selector: 'app-change-unit-event',
  templateUrl: './change-unit-event.component.html',
  styleUrls: ['./change-unit-event.component.scss'],
})
export class ChangeUnitEventComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  private ngUnsubscribe = new Subject();
  individualEventObj: any = {
    dsdChangeDriverId: null,
    isGsi: false,
    eventType: '',
    isEnableActions: false,
    isEnableRefEntity: 'selectGsi',
    slotContextualId: '',
    slotContextualName: '',
    isPopupEvent: false,
  };
  eventsList: any = [this.individualEventObj];
  targetAttributeObj: any = {
    targetAttributeId: '',
    targetAttributeName: '',
    refDisplayAttributeIds: [],
    refDisplayAttributeNames: [],
    refDisplayAttributeMap: {},
    refActualAttributeId: '',
    refActualAttributeName: '',
    referenceValueType: '',
    isEnableTargetAttribute: true,
  };

  operators: any = ['<', '>', '=', '!=', '<=', '>=', 'INCLUDES'];
  operatorsForExpressionEvents: any = ['+', '-', '*', '/', '%'];
  searchEntityInput = new Subject();
  searchCuInput = new Subject();
  searchGSIInput = new Subject();
  entitySearchText: string = '';
  cuSearchText: string = '';
  isConditionEnabled: boolean;
  isPublished: any = '';
  entityPageNo: number = 0;
  entityPageSize: number = 10;
  cuPageNo: number = 0;
  cuPageSize: number = 10;
  currentCuEventsList: any[];
  currentConditionObj: any = {};
  currentAttr: any;
  labels: any;
  contextAttrSearchType: string = '';
  currentEvent: any = {};
  actionItems: any = [];
  selectedEntities: any = [];
  totalRefEntity: any = 15;
  pageSize: number = 15;
  pageIndex: number = 0;
  eventsDropdownSelectedData: {
    event: any;
    eventIndex: number;
    fullEvent: any;
    evt: any;
  };
  currentEventIndex: number;
  selectedEvnt: any;
  selectedEventGSI: any;
  searchCriteria = '';
  gsiEventList: any = [];
  physicalLayer: any = [];
  informationLayer: any = [];
  triggerCESLayer: any = [];
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  labelForInformationLayer = {
    name: 'information Layer',
    label: 'information Layer',
  };
  labelForTriggerCESLayer = {
    name: 'triggerCES Layer',
    label: 'triggerCES Layer',
  };
  contextualIdWithNestedConditions: EmbededGSIData[] = [];
  conditionphysicalLayer: any;
  currentConditionEventIndex: number;
  conditioninformationLayer: any;
  cuPhysicalLayerData: any;
  cuInformationLayerData: any;
  selectedOperator: any = '';
  changeUnit: any;
  tempGsiObj: any;
  gsiSourceCuList: any;
  gsiSourceEntity: any;
  selectedCu: any;
  myControl = new FormControl();
  showCuList: boolean = false;
  filteredOptions: Observable<string[]>;
  attributeMappings: any = [];
  currentMapping: any = {
    currentLayer: String,
  };
  mapIndex: any;
  refActualAttribute: boolean = false;
  refDisplayAttribute: boolean = false;
  sourceInformationLayer: any = [];
  sourceTriggerCESLayer: any = [];
  refActualInfo: boolean = false;
  refActualTrigger: boolean = false;
  refDisplayInfo: boolean = false;
  refDisplayTrigger: boolean = false;
  expressionArray: any[] = [];
  expressionIdArray: any[] = [];
  targetContextualNameExpEvent: any;
  targetContextualIdExpEvent: any;
  isPopupOpen: boolean = false;
  // expressionError: string;
  // targetPanel: boolean = false;
  sourceContextualId: string = '';
  onselectFlag: boolean = false;
  currentEntityIndex: number = -1;
  groupedEvent: boolean;
  allCustomizations: onSelectCustomization[] = [];
  constructor(
    private libraryFacadeService: LibraryFacadeService,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private gsiFacade: GsiFacadeService,
    private alertService: AlertService,
    private translator: TranslatorService,
    private entityFacade: EntityFacadeService,
    private eventsFacadeService: EventsFacadeService,
    public Dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeUnitEventComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData
  ) {
    this.detectLanguageChange();
    this.detectAllEventGsi();
    this.detectEntityResponseForEvents();
    this.detectSingleAttributeDetailsWithEnt();
    this.detecEntitiesBYSearchInput();
    this.detectGsiBYSearchInput();
  }

  ngOnInit(): void {
    this.changeUnit = this.dialog?.changeUnit;
    this.tempGsiObj = this.dialog?.tempGsiObj;
    this.currentEntityIndex = this.dialog?.entityIndex;
    this.sourceContextualId = this.dialog?.sourceContextualId;
    this.groupedEvent = this.dialog?.groupedEvent;
    this.detectSelectedChangeUnit(this.dialog?.changeUnit);
    this.getEventAttr(this.dialog?.currentAttr);
    this.setCurrentAttributeEvents();
    this.saveAllCustomisations();
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  saveEvents() {
    this.eventsList?.forEach((event: any) => {
      this.createRefDisplayAttrMap(event);
      let finalAttributeMapping: any = [];
      if (event?.isEnableRefEntity == 'expressionEvent') {
        delete event['eventExpressions'][0].expArray;
        delete event['eventExpressions'][0].lastChar;
        delete event['eventExpressions'][0].expressionError;
        delete event['eventExpressions'][0].sourcePanel;
        delete event['eventExpressions'][0].targetPanel;
      } else {
        delete event['eventExpressions'];
      }
      // event['isPopupEvent'] = this.isPopupOpen;
      event?.attributeMappings?.forEach((mappings: any) => {
        let data = {
          targetAttributeId: mappings?.targetAttributeId,
          targetAttributeName: mappings?.targetAttributeId,
          refDisplayAttributeId: mappings?.refDisplayAttributeId,
          refActualAttributeId: mappings?.refActualAttributeId,
          refDisplayAttributeName: mappings?.refDisplayAttributeId,
          refActualAttributeName: mappings?.refActualAttributeId,
          referenceValueType: 'OPTIONS',
        };
        finalAttributeMapping.push(data);
      });
      /* istanbul ignore else */
      if (finalAttributeMapping?.length > 0) {
        event.attributeMappings = finalAttributeMapping;
      }
    });
    if (
      this.currentCuEventsList &&
      this.currentCuEventsList?.length > 0 &&
      !this.groupedEvent
    ) {
      this.currentCuEventsList = [
        ...this.currentCuEventsList?.filter((ele: any) => {
          let slot = true;
          /* istanbul ignore next */
          if (ele?.slotContextualId)
            slot =
              this.currentEntityIndex + 1 ==
              Number(ele?.slotContextualId?.split('.')?.[1]?.split('SL')?.[1]);
          /* istanbul ignore else */
          if (slot) {
            return ele.dsdChangeDriverId !== this.currentAttr?.dsdId;
          } else {
            return !slot;
          }
        }),
        ...this.eventsList,
      ];
    } else {
      this.currentCuEventsList = [...this.eventsList];
    }

    // to remove corrupt events
    /* istanbul ignore else */
    if (!this.groupedEvent) {
      // to remove corrupt events
      let error = false;
      this.currentCuEventsList?.forEach((e: any, ind: any) => {
        /* istanbul ignore else */
        if (!e?.dsdChangeDriverId || !e?.eventType) {
          delete this.currentCuEventsList[ind];
          error = true;
        }
      });
      this.currentCuEventsList = this.currentCuEventsList?.filter(
        (event: any) => event != null || event != undefined
      );
      /* istanbul ignore else */
      if (error) {
        this.alertService?.showToaster('Corrupted Event found', '', 'info');
      }
    }
    this.changeUnitFacadeService.updateAttributeEvents(
      this.currentCuEventsList
    );
    if (
      this.currentAttr?.configuration &&
      typeof this.currentAttr?.configuration == 'string'
    ) {
      let attrConfig = JSON.parse(this.currentAttr.configuration);
      attrConfig['allCustomizations'] = this.allCustomizations;
      this.currentAttr.configuration = JSON.stringify(attrConfig);
    }
    this.dialogRef.close({
      changeUnit: this.changeUnit,
      tempGsiObj: this.tempGsiObj,
      eventCUList: this.currentCuEventsList,
    });
  }
  /**
   * Detecs entities by search input
   */
  detecEntitiesBYSearchInput() {
    /* istanbul ignore next */
    this.searchEntityInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        this.entityPageNo = 0;
        this.entityPageSize = 15;
        this.getEntities(true);
      });
  }
  detectGsiBYSearchInput() {
    /* istanbul ignore next */
    this.searchGSIInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        this.fetchEventGsi();
      });
  }
  detectLanguageChange() {
    /* istanbul ignore next */
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.labels = res;
        this.actionItems = [
          { name: this.labels?.Select_GSI, value: 'selectGsi' },
          { name: this.labels?.Reference_Entity, value: 'refEnt' },
          { name: this.labels?.Expression_Event, value: 'expressionEvent' },
        ];
      });
  }
  addEvent() {
    this.entitySearchText = '';
    this.searchText = '';
    this.individualEventObj.eventType = '';
    this.individualEventObj.eventExpressions = [
      {
        expArray: [],
        lastChar: '',
        expression: '',
        expressionName: '',
        targetContextualId: '',
        targetContextualName: '',
        expressionError: '',
        targetPanel: false,
        sourcePanel: false,
      },
    ];
    this.getEntities();
    this.eventsList.push(Object.assign({}, this.individualEventObj));
    this.selectedEventGSI = this.individualEventObj;
    this.isConditionEnabled = false;
    this.addSingleCustomization(this.eventsList[this.eventsList?.length - 1]);
  }
  /**
   * Gets events attr when navigationg from the solution canvas to library panel
   */
  editEntityAttrData: any = {};
  sourceValues: any = [];
  entity: any;
  currentAttrId: any;
  attrIndex: any;
  stored: string;
  getEventAttr(data: any) {
    /* istanbul ignore next */
    if (data) {
      this.libraryFacadeService.getAllEntities(
        this.pageIndex,
        this.pageSize,
        '',
        true
      );

      this.currentAttr = data;
      /* istanbul ignore next */
      if (data?.entity) {
        /* istanbul ignore next */
        let attrData =
          data?.entity?.nslAttributes[data?.attrIndex]?.attributeType;
        /* istanbul ignore next */
        this.editEntityAttrData = {
          ...data?.entity?.nslAttributes[data?.attrIndex],
        };
        /* istanbul ignore next */
        if (
          attrData?.extendedProperties &&
          attrData?.extendedProperties?.sourceValues
        ) {
          /* istanbul ignore next */
          this.sourceValues = attrData?.extendedProperties?.sourceValues;
        }
        this.entity = data.entity;
        this.currentAttrId = data.id;
        // let uiElementName = data?.uiElement.replace(' ', '_');
        /* istanbul ignore next */
      }
      /* istanbul ignore next */
      this.attrIndex = data?.attrIndex;
      delete data.entity;
      delete this.currentAttr.entity;
    }
  }
  addSingleCustomization(evt: any) {
    let customization: onSelectCustomization = {
      id: this.currentAttr.id,
      displayType: 'TEXT',
      gsiName: 'Sample GSI',
      displayText: '',
      imageUrl: '',
      eventType: evt.eventType,
    };
    this.allCustomizations.push(customization);
  }

  saveAllCustomisations() {
    if (
      this.currentAttr?.configuration &&
      typeof this.currentAttr?.configuration == 'string'
    ) {
      if (
        Object.keys(JSON.parse(this.currentAttr?.configuration))?.indexOf(
          'allCustomizations'
        ) === -1
      ) {
        this.eventsList?.forEach((evt: any) => {
          this.addSingleCustomization(evt);
        });
      } else {
        this.allCustomizations = JSON.parse(
          this.currentAttr?.configuration
        )?.allCustomizations;
      }
    }
  }

  imageChipFileUpload(event: any, evtIndex: any) {
    this.allCustomizations[evtIndex].imageUrl = event.contentUrl;
  }

  /**
   * Gets entities for the reference entity search
   */
  getEntities(isPublished?: boolean) {
    const isEntityPublished = isPublished ? isPublished : this.isPublished;
    this.libraryFacadeService.getAllEntities(
      this.entityPageNo,
      this.entityPageSize,
      this.entitySearchText,
      isEntityPublished
    );
  }
  /**
   * Clears events data
   */
  clearEventsData() {
    /* istanbul ignore next */
    this.individualEventObj = {
      dsdChangeDriverId: null,
      isGsi: false,
      eventType: '',
      isEnableActions: false,
      isEnableRefEntity: 'selectGsi',
      eventExpressions: [
        {
          expArray: [],
          lastChar: '',
          expression: '',
          expressionName: '',
          targetContextualId: '',
          targetContextualName: '',
        },
      ],
    };
    /* istanbul ignore next */
    this.entitySearchText = '';
    this.searchText = '';
    /* istanbul ignore next */
    this.eventsList = [this.individualEventObj];
    this.currentCuEventsList = [];
    this.currentConditionObj = {};
  }
  /**
   * Sets current attribute events
   */
  setCurrentAttributeEvents() {
    this.clearEventsData();
    this.currentCuEventsList = this.dialog.eventCUList;
    this.individualEventObj.slotContextualId = this.sourceContextualId;
    this.individualEventObj.slotContextualName = this.sourceContextualId;
    /* istanbul ignore next */
    if (this.currentCuEventsList && this.currentCuEventsList?.length > 0) {
      this.currentCuEventsList = this.currentCuEventsList?.map((ele: any) => {
        ele['cuId'] = this.currentAttr?.cuId;
        return ele;
      });
      this.eventsList = this.currentCuEventsList.filter((data: any) => {
        let slot = true;
        if (data?.slotContextualId)
          slot =
            this.currentEntityIndex + 1 ==
            Number(data?.slotContextualId?.split('.')?.[1]?.split('SL')?.[1]);
        if (!this.groupedEvent)
          return (
            slot &&
            data.dsdChangeDriverId == this.currentAttr?.dsdId &&
            !data?.sourceChangeDrivers
          );
        else return data?.eventType;
      });
      /* istanbul ignore next */
      if (this.eventsList && this.eventsList?.length > 0) {
        this.eventsList = this.eventsList?.map((ele: any, i: number) => {
          // this.isPopupOpen = ele['isPopupEvent'];
          if (ele?.referenceEntityInfo) {
            ele['isEnableActions'] = true;
            ele['isEnableRefEntity'] = 'refEnt';
            /* istanbul ignore next */
            if (
              ele?.referenceEntityInfo &&
              ele?.referenceEntityInfo?.membershipCriterias?.length > 0
            ) {
              ele['showCondition'] = true;
              ele['isEnableConditionSelection'] = true;
              ele?.referenceEntityInfo?.membershipCriterias?.forEach(
                (condition: any) => {
                  condition[
                    'displayCondition'
                  ] = `${condition?.referenceAttributeContextualName}${condition?.membershipContextualName}`;
                }
              );
              ele.referenceEntityInfo.membershipCriterias = ele?.referenceEntityInfo?.membershipCriterias?.filter(
                (memberShip: any) => memberShip != null
              );
              ele.currentConditionIndex =
                ele?.referenceEntityInfo?.membershipCriterias.length;
            }
            //ele['currentConditionIndex'] = i;
            /* istanbul ignore next */
            if (
              ele?.referenceEntityInfo &&
              ele?.referenceEntityInfo?.attributeReferences?.length > 0
            ) {
              /* istanbul ignore next */
              ele.referenceEntityInfo.attributeReferences?.forEach(
                (attrRef: any) => {
                  attrRef['isEnableTargetAttribute'] = false;
                  attrRef['entityDetails'] = {};
                  let [
                    targetLayer,
                    _targetLabel,
                    targetEntityEntity,
                    targetAttributeName,
                  ] = attrRef?.targetAttributeName?.split('.');
                  attrRef.entityDetails['entity'] = {
                    name: targetEntityEntity,
                  };

                  attrRef['currentLayer'] =
                    targetLayer === 'PL'
                      ? this.labels?.Physical_Layer
                      : this.labels?.Information_Layer;
                  attrRef.entityDetails['attribute'] = {
                    name: targetAttributeName,
                  };

                  //Set Ref Entity Data

                  this.entityFacade
                    .loadAttributesByEntIdForDependentDropdown(
                      ele.referenceEntityInfo.dsdReferenceEntityId
                    )
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((res: any) => {
                      /* istanbul ignore next */
                      if (res && res.result) {
                        this.setDependentDropDownDataInEditMode(
                          res.result,
                          ele
                        );
                        /* istanbul ignore next */
                      }
                    });
                }
              );
            }
            if (
              ele?.referenceEntityInfo?.contextSearchRequest?.attrSearchType
            ) {
              this.contextAttrSearchType =
                ele.referenceEntityInfo.contextSearchRequest?.attrSearchType;
            }
          } else if (ele.isGsi) {
            ele['isEnableActions'] = true;
            ele['isEnableRefEntity'] = 'selectGsi';
            /* istanbul ignore next */
          } else if (!ele?.referenceEntityInfo && !ele?.isGsi) {
            ele['isEnableActions'] = true;
            ele['isEnableRefEntity'] = 'expressionEvent';
            this.eventsList[i].eventExpressions[0]['expArray'] = ele[
              'eventExpressions'
            ][0].expressionName.split(/([+\-*\/%])/);
            this.eventsList[i].eventExpressions[0]['expressioError'] = ele[
              'eventExpressions'
            ][0].expressionError = '';
            this.eventsList[i].eventExpressions[0]['sourcePanel'] = ele[
              'eventExpressions'
            ][0].sourcePanel = false;
            this.eventsList[i].eventExpressions[0]['targetPanel'] = ele[
              'eventExpressions'
            ][0].targetPanel = false;
            this.eventsList[i].eventExpressions[0]['lastChar'] = ele[
              'eventExpressions'
            ][0].lastChar = 'operand';
            // this.expressionArray = ele['eventExpressions'][0].expressionName.split(/([+\-*%])/);
            // this.expressionIdArray = ele['eventExpressions'][0].expression.split(/([+\-*%])/);
            // this.targetContextualNameExpEvent = ele['eventExpressions'][0].targetContextualName;
            // this.targetContextualIdExpEvent = ele['eventExpressions'][0].targetContextualId;
          }
          ele.slotContextualId = this.sourceContextualId;
          ele.slotContextualName = this.sourceContextualId;
          return ele;
        });
      } else if (this.groupedEvent) {
        this.eventsList = this.currentCuEventsList;
      } else {
        this.eventsList = [this.individualEventObj];
      }
    } else {
      this.eventsList = [this.individualEventObj];
    }
  }
  /**
   * Determines whether select ref entity on reference dropdown click
   * @param event
   */

  onSelectRefEntity(event: any) {
    /* istanbul ignore next */
    this.entityFacade
      .loadAttributesByEntIdForDependentDropdown(
        event?.referenceEntityInfo?.dsdReferenceEntityId
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res.result) {
          this.setDependentDropDownDataInEditMode(res.result, event);
        }
      });
    this.currentEvent = event;
  }
  /**
   * Sets dependent drop down data in edit mode
   * @param res
   * @param ele
   */
  setDependentDropDownDataInEditMode(res: any, ele: any) {
    /* istanbul ignore next */
    if (this.eventsList && this.eventsList?.length > 0) {
      const index = this.eventsList.findIndex((elements: any) => {
        /* istanbul ignore next */
        if (elements?.referenceEntityInfo?.referenceEntityId == res?.id) {
          return true;
        } else {
          return false;
        }
      });
      /* istanbul ignore next */
      if (index !== -1) {
        ele.refEntity = res;
        /* istanbul ignore next */
        if (
          ele?.referenceEntityInfo &&
          ele?.referenceEntityInfo.attributeReferences?.length > 0
        ) {
          /* istanbul ignore next */
          ele.referenceEntityInfo.attributeReferences?.forEach(
            (attrRef: any) => {
              // const [, refActualAttr] = attrRef.refActualAttributeName.split('.');
              // const [, refDisplayAttr] = attrRef.refDisplayAttributeName.split('.');
              // [attrRef['displayAttrId']] = res.nslAttributes.filter(
              //   (attribute: any) => attribute.name === refDisplayAttr
              // );
              if (Object.keys(attrRef?.refDisplayAttributeMap)?.length > 0) {
                attrRef['refDisplayAttributeNames'] = Object.keys(
                  attrRef?.refDisplayAttributeMap
                );
              } else {
                attrRef['refDisplayAttributeNames'] = [];
              }
              if (Object.values(attrRef?.refDisplayAttributeMap)?.length > 0) {
                attrRef['refDisplayAttributeIds'] = Object.values(
                  attrRef?.refDisplayAttributeMap
                );
              } else {
                attrRef['refDisplayAttributeIds'] = [];
              }
              // [attrRef['actualAttId']] = res.nslAttributes.filter((attribute: any) => attribute.name === refActualAttr);
            }
          );
        }
      } else {
        this.currentEvent.refEntity = res;
      }
    } else {
      this.currentEvent.refEntity = res;
    }
  }
  /**
   * Determines whether clear entity click on
   */
  /* istanbul ignore next */
  onClearEntityClick() {
    this.entitySearchText = '';
    this.entityPageNo = 0;
    this.entityPageSize = 15;
    this.getEntities(true);
  }
  /**
   * Detects entity list response for events
   */
  detectEntityResponseForEvents() {
    this.entityFacade.entities$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.selectedEntities = res?.content;
        this.totalRefEntity = res?.total;
      });
  }
  /**
   * Determines whether entity selection on
   * @param entityData
   * @param eventData
   */
  onEntitySelection(entityData: any, eventData: any) {
    eventData.referenceEntityInfo.referenceEntityId = entityData.tfId;
  }
  /**
   * Adds target attribute
   * @param event
   */
  addTargetAttribute(event: any) {
    this.isConditionEnabled = false;
    this.targetAttributeObj.refDisplayAttributeIds = [];
    this.targetAttributeObj.refDisplayAttributeNames = [];
    event['referenceEntityInfo']['attributeReferences'].push(
      Object.assign({}, this.targetAttributeObj)
    );
    this.libraryFacadeService.currentCuTyeChange('eventCondition');
  }
  /**
   * Adds conditions for the events
   * @param event
   */
  addConditions(event: any) {
    event['showCondition'] = true;
    event['isEnableConditionSelection'] = true;
    /* istanbul ignore next */
    if (!event['referenceEntityInfo']) {
      event['referenceEntityInfo'] = {};
    }
    event['referenceEntityInfo']['membershipCriterias'] = [];
    event['currentConditionIndex'] = 0;
    /* istanbul ignore next */
    if (this.currentEvent && !this.currentEvent.refEntity) {
      this.currentEvent = Object.assign({}, event);
    }
    this.setNestedLayerData();
  }

  /**
   * Adds filters for the events
   * @param event
   */
  addFilters(event: any) {
    if (!event.refEntity) {
      this.alertService.showToaster(
        'select reference entity to add filters',
        '',
        'error'
      );
      return;
    }
    if (!event['referenceEntityInfo']) {
      event['referenceEntityInfo'] = {};
    }

    if (!event['referenceEntityInfo']['dataFilterDto']?.sortList) {
      event['referenceEntityInfo']['dataFilterDto'] = { sortList: [] };
    }

    if (event.referenceEntityInfo.dataFilterDto.sortList.length == 0)
      event.referenceEntityInfo.dataFilterDto.sortList.push({
        fieldName: '',
        order: '',
      });
    else this.alertService.showToaster('you can only add 1 filter', '', 'info');
  }

  resetFilter(sortList: any[], ind: number) {
    sortList?.splice(ind, 1);
    sortList?.push({ fieldName: '', order: '' });
  }

  deleteFilter(sortList: any[], ind: number) {
    sortList?.splice(ind, 1);
  }
  /**
   * Adds Context Search Type
   * @param event
   */
  addAttrSearchType(event: any) {
    if (!event['referenceEntityInfo']) {
      event['referenceEntityInfo'] = {};
    }
    event['referenceEntityInfo']['membershipCriterias'] = [];
    if (
      !(
        event.referenceEntityInfo.contextSearchRequest &&
        event.referenceEntityInfo.contextSearchRequest.attrSearchType
      )
    ) {
      event['referenceEntityInfo']['contextSearchRequest'] = {
        attrSearchType: '',
      };
    } else {
      this.contextAttrSearchType =
        event.referenceEntityInfo.contextSearchRequest.attrSearchType;
    }
    /* istanbul ignore next */
    if (this.currentEvent && !this.currentEvent.refEntity) {
      this.currentEvent = Object.assign({}, event);
    }
    this.setNestedLayerData();
  }

  /**
   * Search Type
   * @param event
   */
  setSearchType(event: any) {
    event.referenceEntityInfo.contextSearchRequest.attrSearchType = this.contextAttrSearchType;
  }
  setNestedLayerData() {
    this.currentEvent.isRefCondition = true;
    this.conditionphysicalLayer = [
      {
        ...this.currentEvent.refEntity,
      },
    ];
  }
  //pagination for reference entity for events
  /* istanbul ignore next */
  onEntityPageChange(pageData: pagination) {
    this.pageSize = pageData?.pageSize;
    this.pageIndex = pageData?.pageIndex;
    this.libraryFacadeService.getAllEntities(
      this.pageIndex,
      this.pageSize,
      this.entitySearchText,
      true
    );
  }
  /**
   * Determines whether delete event click on
   * @param eventData
   * @param eventIndex
   */
  onDeleteEventClick(eventData: any, eventIndex: number) {
    this.eventsList.splice(eventIndex, 1);
    /* istanbul ignore next */
    this.allCustomizations.splice(eventIndex, 1);
    if (eventIndex == this.selectedEventGSI?.eventIndex) {
      this.selectedEventGSI = null;
    }
  }

  /**
   * Determines whether event click on events dropdown
   * @param event tyepe of event
   * @param eventIndex event Index in the dropdown
   * @param fullEvent eventData
   * @param evt
   * @param actualEventIndex Event Index
   */

  onEventClick(
    event: any,
    eventIndex: number,
    fullEvent: any,
    evt: any,
    actualEventIndex: number
  ) {
    /* istanbul ignore next */
    this.libraryFacadeService.changeOfLibraryData(null);
    evt.isEnableActions = true;
    this.eventsDropdownSelectedData = { event, eventIndex, fullEvent, evt };
    this.selectedEventGSI = evt;
    this.selectedEventGSI.eventIndex = actualEventIndex;
    this.eventsList[actualEventIndex].eventExpressions[0].expressionError = '';
    this.eventsList[actualEventIndex] = this.setEventsObj(evt);
    this.currentEventIndex = actualEventIndex;
    /* istanbul ignore else */
    if (event === 'ON_SELECT') {
      this.onselectFlag = true;
    } else {
      this.onselectFlag = false;
    }
    this.fetchEventGsi();
    /* istanbul ignore else */
    if (event == 'ON_SELECT') {
      this.onselectFlag = true;
      /* istanbul ignore next */
      if (
        this.eventsList[this.currentEventIndex].isEnableRefEntity ==
        'expressionEvent'
      ) {
        this.eventsList[this.currentEventIndex].isEnableRefEntity = 'selectGsi';
      }
    } else {
      this.onselectFlag = false;
    }

    if(this.allCustomizations?.length > this.currentEventIndex) {
      this.allCustomizations[this.currentEventIndex].eventType =  event;
    }

    // this.libraryFacadeService.changeOfCurrentLibraryType(Librarytypes.EventGsi);
  }
  /**
   * Sets events obj
   * @param eventData
   * @returns
   */
  setEventsObj(eventData: any) {
    const { fullEvent } = this.eventsDropdownSelectedData;
    this.selectedEvnt = fullEvent;
    const obj = {
      dsdChangeDriverId: this.currentAttr.dsdId,
      dsdGsiId: '',
      cuId: this.currentAttr.cuId,
      changeDriverId: this.currentAttr.tfId,
    };
    eventData = { ...eventData, ...obj };
    return eventData;
  }
  /**
   * Determines whether action change on and enable options for GSI select/ dependent dropdown select
   * @param event whether its gsi select or attribute selction
   * @param action Event Object
   */
  showLibraryMainLibrary: boolean = false;
  disableEntityRef: boolean = false;
  searchText = '';

  onActionChange(event: any, action: any, eventIndex: number) {
    this.currentEventIndex = eventIndex;
    action.isEnableRefEntity = event.value;
    this.selectedEventGSI = action;
    /* istanbul ignore next */
    if (action.isEnableRefEntity == 'selectGsi') {
      action.isGsi = true;
      this.eventsFacadeService.disableContextualSearchConfiguration(false);
      this.fetchEventGsi();
      // this.libraryFacadeService.changeOfCurrentLibraryType(Librarytypes.EventGsi);
    } else if (action.isEnableRefEntity == 'refEnt') {
      /* istanbul ignore next */
      this.showLibraryMainLibrary = false;
      this.eventsFacadeService.disableContextualSearchConfiguration(true);
      action.isGsi = false;
      action['referenceEntityInfo'] = {
        dsdReferenceEntityId: null,
        referenceEntityId: null,
        attributeReferences: [],
        membershipCriterias: [],
        dataFilterDto: {},
        contextSearchRequest: {},
      };
    } else if (action.isEnableRefEntity == 'expressionEvent') {
      if (action?.referenceEntityInfo) delete action.referenceEntityInfo;
    }
  }
  fetchEventGsi() {
    /* istanbul ignore next */
    this.pageIndex = this.pageIndex === undefined ? 0 : this.pageIndex;
    /* istanbul ignore next */
    this.pageSize = this.pageSize === undefined ? 10 : this.pageSize;
    /* istanbul ignore next */
    this.searchText = this.searchText === undefined ? '' : this.searchText;
    /* istanbul ignore next */
    this.isPublished = !this.isPublished ? '' : this.isPublished;
    this.gsiFacade.getEventAllGsis(
      this.pageIndex,
      this.pageSize,
      this.searchText,
      true,
      false
    );
  }
  detectAllEventGsi() {
    this.gsiFacade.eventAllgsi$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.gsiEventList = res.content;
        }
      });
  }
  selectedGsiEvent(gsiData: any) {
    /* istanbul ignore else */
    if (this.currentEventIndex > -1) {
      /* istanbul ignore next */
      this.eventsList[this.currentEventIndex]['dsdGsiId'] = gsiData?.dsdId;
      /* istanbul ignore next */
      this.eventsList[this.currentEventIndex]['gsiId'] = gsiData?.gsiId;
      /* istanbul ignore next */
      this.eventsList[this.currentEventIndex]['gsiName'] = gsiData?.name;
      /* istanbul ignore next */
      this.eventsList[this.currentEventIndex]['gsiDisplayName'] =
        gsiData?.displayName;
      this.eventsList[this.currentEventIndex]['isGsi'] = true;
      this.eventsList[this.currentEventIndex]['gsiData'] = gsiData;
      this.gsiFacade
        .getGSIDetailsById(
          gsiData?.gsiId ? gsiData?.gsiId : gsiData?.gsiData?.id
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          /* istanbul ignore else */
          if (res?.result) {
            /* istanbul ignore else */
            if (res.result.solutionLogic)
              this.gsiSourceCuList = res.result.solutionLogic;
            this.eventsList[this.currentEventIndex].attributeMappings = [];
            this.sourceInformationLayer = [];
            this.sourceTriggerCESLayer = [];
            this.searchCu();
            // this.gsiSourceEntity = this.gsiSourceCu.layers.find((x:any)=>{x.type == 'physical'})
          }
        });
      if (this.allCustomizations?.length >= this.currentEventIndex + 1) {
        this.allCustomizations[
          this.currentEventIndex
        ].displayText = gsiData?.displayName
          ? gsiData.displayName
          : gsiData?.name;
      }
    }
  }
  /**
   * Determines whether delete attr click on
   * @param evt
   * @param attrInd
   */
  onDeleteAttrClick(evt: any, attrInd: any) {
    evt.referenceEntityInfo.attributeReferences.splice(attrInd, 1);
  }
  /**
   * Determines whether click layer exp panel for event on
   * @param layer
   * @param targetAttr
   * @param eventIndex
   * @param attributeIndex
   */
  currentAttributeIndex: number;
  onClickLayerExpPanelForEvent(
    layer: any,
    targetAttr: any,
    eventIndex: number,
    attributeIndex: number
  ) {
    this.isConditionEnabled = false;
    this.currentEventIndex = eventIndex;
    this.currentAttributeIndex = attributeIndex;
    /* istanbul ignore else */
    if (layer == 'physical') {
      /* istanbul ignore next */
      targetAttr.currentLayer = this.labels?.Physical_Layer;
    } else if (layer == 'information') {
      /* istanbul ignore else */
      /* istanbul ignore next */
      targetAttr.currentLayer = this.labels?.Information_Layer;
    }
  }

  onClickLayerExpPanelGsi(
    layer: any,
    currentMapping: any,
    mapIndex?: any,
    AttributeType?: any,
    ismulti?: any,
    eventIndex?: any
  ) {
    this.currentEventIndex = eventIndex;
    this.mapIndex = mapIndex;
    if (ismulti == 'target') {
      this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].targetPanel = !this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].targetPanel;
      this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].sourcePanel = false;
    } else if (ismulti == 'source') {
      /* istanbul ignore next */
      this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].sourcePanel = !this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].sourcePanel;
      this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].targetPanel = false;
    }
    if (layer == 'physical') {
      currentMapping.currentLayer = this.labels?.Physical_Layer;
    } else if (layer == 'information') {
      /* istanbul ignore next */
      currentMapping.currentLayer = this.labels?.Information_Layer;
    }

    if (AttributeType == 'refDisplayAttributeName') {
      currentMapping.refDisplayAttribute = true;
      currentMapping.refActualAttribute = false;
    } else if (AttributeType == 'refActualAttributeName') {
      currentMapping.refActualAttribute = true;
      currentMapping.refDisplayAttribute = false;
    } else {
      currentMapping.refActualAttribute = false;
      currentMapping.refDisplayAttribute = false;
    }
    this.currentMapping = currentMapping;
  }
  clearEmbededGSIArray(event: any) {
    /* istanbul ignore next */
    if (event === true) {
      this.contextualIdWithNestedConditions = [];
    }
  }
  /**
   * Determines whether display entity change on
   * @param changeEvent
   * @param item
   * @param targetAttr
   */
  onDisplayEntityChange(changeEvent: any, item: any, targetAttr: any) {
    targetAttr['refDisplayAttributeIds'].push(
      `${item.refEntity.name}.${changeEvent.attribute.name}`
    );
    targetAttr['refDisplayAttributeNames'].push(
      `${item.refEntity.name}.${changeEvent.attribute.name}`
    );
  }
  /**
   * Adds formula to event
   * @param operator
   * @param event
   */
  addFormulaToEvent(operator: any, event: any) {
    this.currentEvent.isRefCondition = false;
    this.currentConditionObj['operator'] = operator;
    this.eventsList[this.currentConditionEventIndex].attributeValue += operator;
    this.conditionphysicalLayer = this.cuPhysicalLayerData;
    this.conditioninformationLayer = this.cuInformationLayerData;
    this.selectedOperator = operator;
  }

  /**
   * Determines whether actual entity change on
   * @param changeEvent
   * @param entity
   * @param targetAttr
   */
  onActualEntityChange(changeEvent: any, entity: any, targetAttr: any) {
    targetAttr[
      'refActualAttributeId'
    ] = `${entity.refEntity.name}.${changeEvent.attribute.name}`;
    targetAttr[
      'refActualAttributeName'
    ] = `${entity.refEntity.name}.${changeEvent.attribute.name}`;
  }
  detectSelectedChangeUnit(res: any) {
    if (res) {
      this.physicalLayer = [];
      this.informationLayer = [];
      this.triggerCESLayer = [];
      /* istanbul ignore next */
      if (res?.layers?.length > 0) {
        res.layers?.forEach((layer: any) => {
          /* istanbul ignore next */
          if (layer?.participatingItems?.length > 0) {
            if (layer?.type == 'information') {
              /* istanbul ignore next */
              layer.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /* istanbul ignore next */
                if (participatingItem && participatingItem?.name) {
                  entityIndex = this.informationLayer.findIndex(
                    (ele: any) => ele.name === participatingItem.name
                  );
                }
                /* istanbul ignore next */
                if (entityIndex === -1) {
                  this.informationLayer.push(participatingItem);
                  this.cuInformationLayerData = [...this.informationLayer];
                }
              });
            } else if (layer.type == 'physical') {
              /* istanbul ignore next */
              layer.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /* istanbul ignore next */
                if (participatingItem && participatingItem.name) {
                  entityIndex = this.physicalLayer.findIndex(
                    (ele: any) =>
                      ele?.participatingItemId ===
                      participatingItem?.participatingItemId
                  );
                }
                /* istanbul ignore next */
                if (entityIndex === -1) {
                  this.physicalLayer.push(participatingItem);
                  this.cuPhysicalLayerData = [...this.physicalLayer];
                }
              });
            } /* istanbul ignore next */ else if (layer.type == 'triggerCES') {
              /* istanbul ignore next */
              layer.participatingItems?.forEach((participatingItem: any) => {
                this.triggerCESLayer.push(participatingItem);
              });
            }
          }
        });
      }
    }
  }
  /**
   * Detects the entity and attribute data when the target and source attributes are selected for conditional and reference attributes
   */
  sourceFormulaAttr: string = '';
  freeTextInTargetAttribute: boolean = false;
  targetAttributeValue: string = '';

  detectSingleAttributeDetailsWithEnt() {
    this.libraryFacadeService.singleAttributeDetailsWithEnt$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (this.isConditionEnabled) {
          let obj = {};
          if (!this.currentConditionObj?.sourceAttr) {
            this.eventsList[this.currentConditionEventIndex].attributeValue =
              res.attribute.name;
            this.currentConditionObj.sourceAttr = res.attribute;
            this.currentConditionObj.sourceentity = res.entity;
            obj = {
              referenceAttributeId: `${res.entity.name}.${res.attribute.name}`,
              referenceAttributeContextualName: `${res.entity.name}.${res.attribute.name}`,
            };
            this.sourceFormulaAttr = res.attribute.name;
          } else {
            obj = {
              targetAttr: res.attribute,
              membershipContextualId: `${this.currentConditionObj.operator}${res.contextualAttrName}`,
              membershipContextualName: `${this.currentConditionObj.operator}${res.contextualAttrName}`,
              displayCondition: `${this.currentConditionObj.sourceAttr.name}${this.currentConditionObj.operator}${res.attribute.name}`,
            };
            this.eventsList[this.currentConditionEventIndex].attributeValue +=
              res.attribute.name;
            this.freeTextInTargetAttribute = false;
            this.targetAttributeValue = res.attribute.name;
          }
          this.currentConditionObj = { ...this.currentConditionObj, ...obj };
        } else if (
          this.eventsList[this.currentEventIndex]?.isGsi &&
          !this.currentMapping?.refActualAttribute &&
          !this.currentMapping?.refDisplayAttribute
        ) {
          let obj = {
            entityDetails: res,
            isEnableTargetAttribute: false,
            targetAttributeId: res?.contextualAttrName,
            targetAttributeName: res?.contextualAttrName,
          };
          this.currentMapping = { ...obj };
          this.eventsList[this.currentEventIndex].attributeMappings[
            this.mapIndex
          ] = {
            ...this.eventsList[this.currentEventIndex].attributeMappings[
              this.mapIndex
            ],
            ...obj,
          };
        } else if (this.currentMapping?.refActualAttribute) {
          if (res?.contextualAttrName.split('.')[0] === 'IL')
            this.currentMapping.refActualInfo = true;
          else this.currentMapping.refActualTrigger = true;
          this.currentMapping.refActualAttributeId = res.contextualAttrName;
          this.eventsList[this.currentEventIndex].attributeMappings[
            this.mapIndex
          ] = {
            ...this.currentMapping,
            ...this.eventsList[this.currentEventIndex].attributeMappings[
              this.mapIndex
            ],
          };
        } else if (this.currentMapping?.refDisplayAttribute) {
          if (res.contextualAttrName.split('.')[0] === 'IL')
            this.currentMapping.refDisplayInfo = true;
          else this.currentMapping.refDisplayTrigger = true;
          this.currentMapping.refDisplayAttributeId = res.contextualAttrName;
          this.eventsList[this.currentEventIndex].attributeMappings[
            this.mapIndex
          ] = {
            ...this.currentMapping,
            ...this.eventsList[this.currentEventIndex].attributeMappings[
              this.mapIndex
            ],
          };
        } else if (
          this.eventsList[this.currentEventIndex].isEnableRefEntity ==
          'expressionEvent'
        ) {
          if (
            this.eventsList[this.currentEventIndex].eventExpressions[0]
              .sourcePanel
          ) {
            if (
              this.eventsList[this.currentEventIndex].eventExpressions[0]
                .lastChar == 'operand'
            ) {
              this.eventsList[
                this.currentEventIndex
              ].eventExpressions[0].expressionError =
                "can't add attribute after another attribute, add operator";
              return;
            }
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].expressionError = '';
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].lastChar = 'operand';
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].expArray.push(res.contextualAttrName);
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].expression = this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].expArray.join('');
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].expressionName = this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].expArray.join('');
            //   this.expressionArray.push(res.contextualAttrName);
            //   this.expressionIdArray.push(res.contextualAttrId);
          } else {
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].targetContextualName = res.contextualAttrName;
            this.eventsList[
              this.currentEventIndex
            ].eventExpressions[0].targetContextualId = res.contextualAttrName;
            // this.targetContextualIdExpEvent = res.contextualAttrId;
            // this.targetContextualNameExpEvent = res.contextualAttrName;
          }
        } else {
          const obj: any = {
            entityDetails: res,
            isEnableTargetAttribute: false,
            targetAttributeId: res.contextualAttrName,
            targetAttributeName: res.contextualAttrName,
          };

          if (
            this.currentEventIndex !== -1 &&
            this.currentAttributeIndex !== -1 &&
            this.eventsList[this.currentEventIndex]?.referenceEntityInfo
          ) {
            this.eventsList[
              this.currentEventIndex
            ].referenceEntityInfo.attributeReferences[
              this.currentAttributeIndex
            ] = {
              ...this.eventsList[this.currentEventIndex].referenceEntityInfo
                .attributeReferences[this.currentAttributeIndex],
              ...obj,
            };
          }
        }
      });
  }
  /**
   * Determines whether click layer exp panel for event condition on
   * @param layer
   * @param eventIndex
   * @param event
   */
  onClickLayerExpPanelForEventCondition(
    layer: any,
    eventIndex: number,
    event: any
  ) {
    this.currentConditionEventIndex = eventIndex;
    this.isConditionEnabled = true;
    // this.currentConditionObj = {};
    /* istanbul ignore else */
    if (layer == 'physical') {
      /* istanbul ignore next */
      this.currentConditionObj[
        'currentConditionLayer'
      ] = this.labels?.Physical_Layer;
    } else if (layer == 'information') {
      /* istanbul ignore next */
      this.currentConditionObj[
        'currentConditionLayer'
      ] = this.labels?.Information_Layer;
    }
  }
  /**
   * Removes membership critira
   * @param index
   * @param eventIndex
   */
  removeMembershipCritira(index: number, eventIndex: number, evt: any) {
    /* istanbul ignore next */
    this.eventsList[eventIndex]?.referenceEntityInfo.membershipCriterias.splice(
      index,
      1
    );
    this.currentConditionObj = {};
    this.eventsList[this.currentConditionEventIndex].currentConditionIndex += 1;
    /* istanbul ignore next */
    if (this.currentEvent && !this.currentEvent?.refEntity) {
      this.currentEvent = Object.assign({}, evt);
    }
    /* istanbul ignore next */
    this.currentEvent['showCondition'] = true;
    this.currentEvent['isEnableConditionSelection'] = true;
    this.setNestedLayerData();
    // this.addConditions(this.currentEvent);
  }
  /**
   * Changes of attribute value
   */
  changeOfAttributeValue(eventIndex?: any) {
    let obj = {};
    /* istanbul ignore next */
    if (this.freeTextInTargetAttribute) {
      obj = {
        membershipContextualId: `${this.currentConditionObj?.operator}${this.targetAttributeValue}`,
        membershipContextualName: `${this.currentConditionObj?.operator}${this.targetAttributeValue}`,
        displayCondition: `${this.currentConditionObj?.sourceAttr.name}${this.currentConditionObj?.operator}${this.targetAttributeValue}`,
      };
      this.eventsList[eventIndex].attributeValue += this.targetAttributeValue;
      this.currentConditionObj = { ...this.currentConditionObj, ...obj };
    }
    /* istanbul ignore next */
    if (this.sourceFormulaAttr !== '' && this.selectedOperator !== '') {
      this.addConditionToEvent(eventIndex);
    }
  }
  addConditionToEvent(eventIndex?: any) {
    this.eventsList[eventIndex].referenceEntityInfo.membershipCriterias[
      this.eventsList[eventIndex].currentConditionIndex
    ] = this.currentConditionObj;
    this.eventsList[eventIndex].attributeValue = '';
    this.eventsList[eventIndex].currentConditionIndex += 1;
    this.setNestedLayerData();
    this.resetConditionForm();
  }
  toggleExpressionsOperators = false;
  resetConditionForm() {
    this.targetAttributeValue = '';
    this.sourceFormulaAttr = '';
    this.selectedOperator = '';
    this.toggleExpressionsOperators = false;
    this.freeTextInTargetAttribute = false;
    this.currentConditionObj = {};
  }

  /**
   * Determines whether free text change on dependent dropdown membership criteria target attribute
   * @param targetAttributeValue
   */
  onFreeTextChange(targetAttributeValue: any) {
    this.targetAttributeValue = targetAttributeValue;
    this.freeTextInTargetAttribute = this.targetAttributeValue ? true : false;
  }

  addGsiTargetAttribute(event: any) {
    this.eventsList[this.currentEventIndex].attributeMappings.push({
      referenceValueType: 'OPTIONS',
    });
  }

  /* istanbul ignore next */
  searchCu() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterAgent(value))
    );
  }

  filterAgent(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.gsiSourceCuList.filter((cu: any) =>
      cu?.name.toLowerCase().includes(filterValue)
    );
  }

  selectedChangeUnitFromSearch(value: any, eventIndex: any) {
    this.gsiSourceCuList.forEach((cu: any) => {
      /* istanbul ignore else */
      if (cu?.name == value) {
        this.eventsList[eventIndex].selectedCu = cu;
        cu?.layers?.forEach((layer: any) => {
          if (layer.type == 'information') {
            /* istanbul ignore next */
            layer.participatingItems?.forEach((participatingItem: any) => {
              /* istanbul ignore next */
              if (participatingItem && participatingItem?.item?.DATA?.name) {
                this.sourceInformationLayer.push(participatingItem?.item?.DATA);
              }
            });
          } else if (layer.type == 'triggerCES') {
            /* istanbul ignore else */
            /* istanbul ignore next */
            layer.participatingItems?.forEach((participatingItem: any) => {
              /* istanbul ignore next */
              if (participatingItem && participatingItem?.item?.DATA?.name) {
                this.sourceTriggerCESLayer.push(participatingItem?.item?.DATA);
              }
            });
          }
        });
      }
    });
    this.myControl.setValue('');
  }

  deleteAttributeMapping(event: any, index: any, currentEventIndex: any) {
    this.eventsList[currentEventIndex].attributeMappings.splice(index, 1);
  }

  addOperatorToExpression(operator: string, eventIndex: any) {
    this.currentEventIndex = eventIndex;

    /*istanbul ignore next*/
    if (
      this.eventsList[this.currentEventIndex]?.eventExpressions[0]?.expArray
        ?.length == 0
    ) {
      this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].expressionError =
        'first character should be an attribute';
      return;
    }
    /*istanbul ignore else*/
    if (
      this.eventsList[this.currentEventIndex]?.eventExpressions[0]?.lastChar ==
      'operator'
    ) {
      this.eventsList[
        this.currentEventIndex
      ].eventExpressions[0].expressionError =
        "can't add operator after operator, add attribute";
      return;
    }
    this.eventsList[
      this.currentEventIndex
    ].eventExpressions[0].expressionError = '';
    this.eventsList[this.currentEventIndex].eventExpressions[0].lastChar =
      'operator';
    this.eventsList[this.currentEventIndex].eventExpressions[0].expArray.push(
      operator
    );
  }

  deleteLastCharFromExpression(index: any) {
    let expSize = this.eventsList[this.currentEventIndex].eventExpressions[0]
      .expArray.length;
    this.eventsList[index].eventExpressions[0].expArray.splice(expSize - 1, 1);
    this.eventsList[index].eventExpressions[0].lastChar =
      this.eventsList[index].eventExpressions[0].lastChar == 'operator'
        ? 'operand'
        : 'operator';
  }

  resetExpression(index: any) {
    this.currentEventIndex = index;
    this.eventsList[this.currentEventIndex].eventExpressions[0].expArray = [];
    this.eventsList[this.currentEventIndex].eventExpressions[0].lastChar = '';
    this.eventsList[
      this.currentEventIndex
    ].eventExpressions[0].expressionError = '';
  }
  deleteTargetForExpEvents(evt: any, index: any) {
    this.currentEventIndex = index;
    this.eventsList[index].eventExpressions[0].targetContextualName = '';
    this.eventsList[index].eventExpressions[0].targetContextualId = '';
  }

  createRefDisplayAttrMap(event: any) {
    event?.referenceEntityInfo?.attributeReferences?.forEach((ref: any) => {
      ref.refDisplayAttributeMap = {};
      ref?.refDisplayAttributeNames?.forEach((key: any, index: any) => {
        ref.refDisplayAttributeMap[key] = ref?.refDisplayAttributeIds[index];
      });
      delete ref.refDisplayAttributeNames;
      delete ref.refDisplayAttributeIds;
    });
  }

  removeValue(index: any, attrInd: any, evtInd: any) {
    this.eventsList[evtInd].referenceEntityInfo.attributeReferences[
      attrInd
    ].refDisplayAttributeNames.splice(index, 1);
    this.eventsList[evtInd].referenceEntityInfo.attributeReferences[
      attrInd
    ].refDisplayAttributeIds.splice(index, 1);
  }

  validateEvents() {
    this.tempGsiObj = validateRights(this.tempGsiObj, 'cuList');
    let cuObj = JSON.parse(
      JSON.stringify(this.tempGsiObj.cuList[this.tempGsiObj.activeCuIndex])
    );
    cuObj = solutionLogicDeMapper(cuObj);
    cuObj.eventCUList = this.eventsList;
    const payload: any = {
      'path params': 'EVENTCU',
      'Request Body': cuObj,
    };
    this.gsiFacade.validateVantagePoints(payload);
  }

  ngOnDestroy(): void {
    this.currentConditionObj = {};
    this.eventsList = [];
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
