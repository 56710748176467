export const templateExpandMap: {[index: number]: number} = {
  // 2-6 Attributes new templates
  5: 1,
  6: 4,
  10: 1,
  28: 1,
  8: 2,
  9: 3,
  7: 5,
  12: 2,
  14: 12,
  11: 4,
  13: 5,
  15: 3,
  16: 1,
  18: 2,
  20: 12,
  17: 4,
  19: 5,
  21: 3,
  22: 1,
  24: 2,
  26: 12,
  23: 4,
  25: 5,
  27: 3,
  121: 12,
  122: 3,
  30: 2,
  32: 2,
  29: 4,
  33: 3,
  31: 5,
  // 2-6 Attribues new templates
  130: 6,
  132: 6,
  134: 6,
  136: 6,
  138: 6,
  131: 7,
  133: 7,
  135: 7,
  137: 7,
  139: 7,
  242: 20,
  // e-commerce-templates
  54: 94,
  55: 95,
  56: 96,
  57: 97,
  58: 98,
  59: 99,
  60: 100,
  61: 101,
  62: 102,
  63: 103,
  64: 104,
  65: 105,
  66: 106,
  67: 107,
  68: 108,
  69: 109,
  70: 110,
  71: 111,
  72: 112,
  73: 113,
  // insurance templates
  49: 74,
  50: 75,
  34: 76,
  45: 77,
  36: 78,
  37: 79,
  51: 80,
  47: 82,
  35: 83,
  38: 81,
  39: 84,
  40: 85,
  41: 86,
  42: 87,
  43: 88,
  44: 89,
  52: 90,
  46: 91,
  53: 92,
  48: 93,
  // 19 Attributes
  181: 8,
  182: 9,
  //features-templates
  // 226: 147,
  // 151: 151,
  // 227: 148,
  // 160: 160,
  // 165: 165,
  // 173: 173,
  //banners-templates
  195: 195,
  196: 196,
  197: 197,
  198: 198,
  199: 199,
  200: 200,
  201: 201,
  202: 202,
  203: 203,
  204: 204,
  205: 205,
  206: 206,
  207: 207,
  208: 208,
  209: 209,
  210: 210,
  211: 211,
  212: 212,
  213: 213,
  214: 214,
  215: 215,
  216: 216,
  217: 217,
  218: 218,
  219: 219,
  220: 220,
  221: 221,
  222: 222,
  223: 223,
};
/*
  no-image-childTemplate
  6 --->10 Attributes
  7 --->20 Attributes
  1 --->10Attributes
  2 --->15Attributes
  12 --->20Attributes
  4 --->25Attributes
  5 --->30Attributes
  3--->35Attributes
 */
