import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService, isMissingAttr, LoaderService,entityDeMapper,entityMapper, Entity, betMapper, SuggestionFacadeService, AlertService, emptyObject, isReservedCU} from '@common-services';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NodeEntityFacadeService extends CduiBaseService {
  public multientityResponse = new Subject();
  multientityResponse$ = this.multientityResponse.asObservable();

  private nodeSeparateCu = new BehaviorSubject('');
  nodeSeparateCu$ = this.nodeSeparateCu.asObservable();

  indCuEntityData = new Subject();
  indCuEntityData$ = this.indCuEntityData.asObservable();

  private grammerMultientityResponse = new Subject();
  grammerMultientityResponse$ = this.grammerMultientityResponse.asObservable();

  saveentityResponse = new Subject();
  saveentityResponse$ = this.saveentityResponse.asObservable();

  nestedEntityResponse = new Subject();
  nestedEntityResponse$ = this.nestedEntityResponse.asObservable();

  saveGsientityResponse = new Subject();
  saveGsientityResponse$ = this.saveGsientityResponse.asObservable();

  private saveentityResponseForIndCu = new Subject();
  saveentityResponseForIndCu$ = this.saveentityResponseForIndCu.asObservable();

  private saveentityResponseForIndEnt = new Subject();
  saveentityResponseForIndEnt$ = this.saveentityResponseForIndEnt.asObservable();

  private finishEntitySubject = new Subject();
  finishEntitySubject$ = this.finishEntitySubject.asObservable();

  public entityAttributes = new Subject();
  entityAttributes$ = this.entityAttributes.asObservable();

  public entityAttributesNested = new Subject();
  entityAttributesNested$ = this.entityAttributesNested.asObservable();

  public entityAttributesGsi = new Subject();
  entityAttributesGsi$ = this.entityAttributesGsi.asObservable();

  entityCCD = new Subject();
  entityCCD$ = this.entityCCD.asObservable();

  public entityList = new Subject();
  entityList$ = this.entityList.asObservable();

  private entity = new Subject();
  entity$ = this.entity.asObservable();

  public sendSourceEntityEql = new Subject();
  sendSourceEntityEql$ = this.sendSourceEntityEql.asObservable();

  public sendSearchEntityEql = new Subject();
  sendSearchEntityEql$ = this.sendSearchEntityEql.asObservable();

  checkForOtherEntitiesSubject = new Subject();
  checkForOtherEntities$ = this.checkForOtherEntitiesSubject.asObservable();
  public updateEntityResponse = new Subject();
  updateEntityResponse$ = this.updateEntityResponse.asObservable();

  entityData = new Subject();
  entityData$ = this.entityData.asObservable();

  private storeEntityData = new Subject();
  storeEntityData$ = this.storeEntityData.asObservable();

  private addNewEntSubject = new Subject();
  addNewEntSubject$ = this.addNewEntSubject.asObservable();

  saveentityResponseinGSI = new Subject();
  saveentityResponseinGSI$ = this.saveentityResponseinGSI.asObservable();

  sendEntityResponseToSf = new BehaviorSubject('');
  sendEntityResponseToSf$ = this.sendEntityResponseToSf.asObservable();

  public multientityResponseInGsi = new Subject();
  multientityResponseInGsi$ = this.multientityResponseInGsi.asObservable();
  private ngUnsubscribe = new Subject();

  public eventEntityList = new Subject();
  eventEntityList$ = this.eventEntityList.asObservable();

  entityPublishResponse = new Subject();
  entityPublishResponse$ = this.entityPublishResponse.asObservable();
  private specialFeatureSubject: BehaviorSubject<any> = new BehaviorSubject({ isSpecialFeatureAvailable: false });
  specialFeature$ = this.specialFeatureSubject.asObservable();

  private eqlOperatorSubject: BehaviorSubject<any> = new BehaviorSubject<any>({ isOperatorsAvailable: false });
  eqlOperator$ = this.eqlOperatorSubject.asObservable();

  attributeWithEntity = new Subject();
  attributeWithEntity$ = this.attributeWithEntity.asObservable();

  sendAttrDetails = new BehaviorSubject('');
  sendAttrDetails$ = this.sendAttrDetails.asObservable();

  existingEntity = new Subject();
  existingEntity$ = this.existingEntity.asObservable();

  existingEntityComposite = new Subject();
  existingEntityComposite$ = this.existingEntityComposite.asObservable();

  private infoLayerOpen = new Subject<boolean>();
  infoLayerOpen$ = this.infoLayerOpen.asObservable();

  private triggerCESLayerOpen = new Subject<boolean>();
  trggerCESLayerOpen$ = this.triggerCESLayerOpen.asObservable();

  extractedDeleteData = new Subject();
  extractedDeleteData$ = this.extractedDeleteData.asObservable();

  nestedEntityDeleteObservable = new Subject();
  nestedEntityDeleteObservable$ = this.nestedEntityDeleteObservable.asObservable();

  extractedData = new Subject();
  extractedData$ = this.extractedData.asObservable();

  nestedEntityObservable = new Subject();
  nestedEntityObservable$ = this.nestedEntityObservable.asObservable();

  private entitydetails = new Subject<Entity>();
  entitydetails$ = this.entitydetails.asObservable();

  // for fetching Entity
  entityDetails = new Subject<Entity>();
  entityDetails$ = this.entityDetails.asObservable();

  tableEntityDetails = new Subject();
  tableEntityDetails$ = this.tableEntityDetails.asObservable();

  verticalTableEntityDetails = new Subject();
  verticalTableEntityDetails$ = this.verticalTableEntityDetails.asObservable();

  tableEntityConfiguration = new Subject();
  tableEntityConfiguration$ = this.tableEntityConfiguration.asObservable();

  cardEntityDetails = new Subject();
  cardEntityDetails$ = this.cardEntityDetails.asObservable();

  widgetUIDetails = new Subject();
  widgetUIDetails$ = this.widgetUIDetails.asObservable();

  public widgetConfiguraiton = new Subject();
  widgetConfiguration$ = this.widgetConfiguraiton.asObservable();

  cardConfigData = new Subject();
  cardConfigData$ = this.cardConfigData.asObservable();

  verticalTableConfigData = new Subject();
  verticalTableConfigData$ = this.verticalTableConfigData.asObservable();

  entityTableConfigData = new Subject();
  entityTableConfigData$ = this.entityTableConfigData.asObservable();

  verticalTableEntitySaveDetails = new Subject();
  verticalTableEntitySaveDetails$ = this.verticalTableEntitySaveDetails.asObservable();

  tableConfigurationSaveDetails = new Subject();
  tableConfigurationSaveDetails$ = this.tableConfigurationSaveDetails.asObservable();

  slotAttribute = new Subject();
  slotAttribute$ = this.slotAttribute.asObservable();

  saveIndentityResponse = new Subject();
  saveIndentityResponse$ = this.saveIndentityResponse.asObservable();

  saveEntityInNested = new Subject();
  saveEntityInNested$ = this.saveEntityInNested.asObservable();

  separateEntityResponse = new Subject();
  separateEntityResponse$ = this.separateEntityResponse.asObservable();

  private newIndEntity = new Subject();
  newIndEntity$ = this.newIndEntity.asObservable();

  private newconfig = new Subject();
  newconfig$ = this.newconfig.asObservable();
  sfGrammarSave = new BehaviorSubject('');
  sfGrammarSave$ = this.sfGrammarSave.asObservable();

  updatedEntityResponse = new Subject();
  updatedEntityResponse$ = this.updatedEntityResponse.asObservable();

  editPublishedEntity = new Subject();
  editPublishedEntity$ = this.editPublishedEntity.asObservable();

  targetEntityResponse = new Subject();
  targetEntityResponse$ = this.targetEntityResponse.asObservable();

  updatedTargetEntityResponse = new Subject();
  updatedTargetEntityResponse$ = this.updatedTargetEntityResponse.asObservable();

  editAttributeEntity = new Subject();
  editAttributeEntity$ = this.editAttributeEntity.asObservable();

  updatedSlotEntity = new Subject();
  updatedSlotEntity$ = this.updatedSlotEntity.asObservable();

  public updateEntityTblConfig = new Subject();
  updateEntityTblConfig$ = this.updateEntityTblConfig.asObservable();

  entitySaveError = new Subject();
  entitySaveError$ = this.entitySaveError.asObservable();

  public cloneResponse = new Subject();
  cloneResponse$ = this.cloneResponse.asObservable();

  triggerCuAttrUiElement = new BehaviorSubject('');
  triggerCuAttrUiElement$ = this.triggerCuAttrUiElement.asObservable();

  AttributeComparisonData = new Subject();
  AttributeComparisonData$ = this.AttributeComparisonData.asObservable();

  independentEntity = new Subject();
  independentEntity$ = this.independentEntity.asObservable();

  nestedEntity = new Subject();
  nestedEntity$ = this.nestedEntity.asObservable();

  attributeUiControls = new Subject();
  attributeUiControls$ = this.attributeUiControls.asObservable();

  slotEntity = new Subject();
  slotEntity$ = this.slotEntity.asObservable();

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private alertService: AlertService,
    private loader: LoaderService,
    // private suggestionFacadeService: SuggestionFacadeService
  ) {
    super(injector);
  }

  sendAttributeEntity(entity: any, attribute: any) {
    this.attributeWithEntity.next({ entity: entity, attribute: attribute });
  }

  sendSourceEntityToEql(sourceEntity: any, targetEntity: any) {
    let res = { sourceEntity: sourceEntity, targetEntity: targetEntity };
    this.sendSourceEntityEql.next(res);
  }
  sendSearchEntityToEql(searchEntity: any) {
    let res = { searchEntity: searchEntity };
    this.sendSearchEntityEql.next(res);
  }
  setAttributeEntity(data: any) {
    this.attributeWithEntity.next(data);
  }
  setIndividualCudata(data: any) {
    this.nodeSeparateCu.next(data);
  }
  sendAttribute(res: any) {
    this.attributeWithEntity.next(res);
  }
  setEntityCCD(status: any) {
    this.entityCCD.next(status);
  }
  storeEntity(res: any) {
    this.storeEntityData.next(res);
  }
  addNewEntity(individualEntity: boolean) {
    this.addNewEntSubject.next(individualEntity);
  }
  getMultientityResponse(data: any) {
    this.multientityResponse.next(data);
  }
  getAttrDetails(data: any) {
    this.sendAttrDetails.next(data);
  }

  tableUIConfiguration(flag: boolean, data: any) {
    this.tableEntityDetails.next({ flag: flag, configurationData: data });
  }

  verticalTableUIConfiguration(flag: boolean, data?: any) {
    this.verticalTableEntityDetails.next({ flag: flag, configurationData: data });
  }
  verticalTableEntitySaveDetailsMethod(flag: boolean, data: any) {
    this.verticalTableEntitySaveDetails.next({ flag: true, configurationData: data });
  }

  tableConfgSaveDetailsMethod(flag: boolean, data: any) {
    this.tableConfigurationSaveDetails.next({ flag: flag, configurationData: data });
  }
  entityTableUIConfiguration(flag: boolean, data?: any) {
    this.tableEntityConfiguration.next({ flag: flag, configurationData: data });
  }

  widgetUIConfiguration(flag: boolean, data: any) {
    this.widgetUIDetails.next({ flag: flag, configurationData: data });
  }
  saveWidgetData(data: any) {
    this.widgetConfiguraiton.next(data);
  }

  cardUIConfiguration(flag: boolean, data: any) {
    this.cardEntityDetails.next({ flag: flag, configurationData: data });
  }

  cardConfigurationData(flag: boolean, data: any) {
    this.cardConfigData.next({ flag: flag, configurationData: data });
  }

  extractNestedEntity(reqBody: any, deleteNested?: any) {
    /* istanbul ignore next */
    this.http.post(this.api?.nestedEntity, reqBody).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        if (deleteNested) {
          this.extractedDeleteData.next(res);
        } else this.extractedData.next(res);
      }
    });
  }

  saveMultipleEntities(
    entity: any,
    changeUnit?: any,
    nslGrammer = false,
    layer?: string,
    lastEntity = true,
    nestedSave = false,
    nestedNumber = -1,
    isSeparateCU?: boolean
  ) {
    //this.loader.show();
    let entityWithoutId = entity.filter((ent: any) => {
      return ent.id == '' || ent.id == undefined;
    });
    let entityWithId = entity.filter((ent: any) => {
      return ent.id != undefined && ent.id != '';
    });

    let reqBody: any = entityDeMapper(entityWithoutId);
    if (entityWithoutId?.length > 0 && isMissingAttr(reqBody)) {
      this.http.post(this.api?.multipleEntitySave, reqBody).subscribe(
        (res: any) => {
          if (res?.result) {
            this.alertService.showToaster(res.message, '', 'success');

            if (changeUnit) {
              res.designTimeRights = changeUnit.designTimeRights;
              res.txnTimeRights = changeUnit.txnTimeRights;
              res.changeUnitIndex = changeUnit.index;
              res.masterId = changeUnit?.masterId;
              res.name = changeUnit.name;
              res.description = changeUnit.description;
              res.sentenceTags = changeUnit.sentenceTags;

              res.isReserved = changeUnit.isReserved;
              res.layer = layer;
              if (!nestedSave && changeUnit?.agents[0].agentType == 'machine') {
                res.agent = 'machine';
              }
            }
            this.loader.hide();

            if (res?.result?.length > 0) {
              for (var i = 0; i < res.result?.length; i++) {
                res.result[i] = entityMapper(res.result[i]);
              }
            }
            res.lastEntity = lastEntity;
            if (nestedNumber >= 0) {
              res.nestedNumber = nestedNumber;
            }
            if (entityWithId?.length > 0) {
              res?.result.push(...entityWithId);
            }
            if (nslGrammer) {
              this.grammerMultientityResponse.next(res);
            } else if (nestedSave) {
              this.saveEntityInNested.next(res);
            } else if (isSeparateCU) {
              this.separateEntityResponse.next(res);
            } else {
              this.multientityResponse.next(res);
            }
          }
        },
        (err: any) => {
          this.entitySaveError.next({ error: true });
        }
      );
    } else {
      let res = {
        result: entityWithId,
      };
      if (nslGrammer) {
        this.grammerMultientityResponse.next(res);
      } else if (nestedSave) {
        this.saveEntityInNested.next(res);
      } else if (isSeparateCU) {
        this.separateEntityResponse.next(res);
      } else {
        this.multientityResponse.next(res);
      }
    }
  }
  saveMultipleEntitiesInGSI(entity: any) {
    //this.loader.show()sub;
    let reqBody: any = entityDeMapper(entity);
    /*istanbul ignore next*/
    this.http.post(this.api?.multipleEntitySave, reqBody).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res?.result) {
        this.alertService.showToaster(res.message, '', 'success');
      }
      this.loader.hide();
      /*istanbul ignore next*/
      if (res?.result?.length > 0) {
        for (var i = 0; i < res.result?.length; i++) {
          res.result[i] = entityMapper(res.result[i]);
        }
      }
      this.multientityResponseInGsi.next(res);
    });
  }

  getGrammerMultientityResponse(data: any) {
    this.grammerMultientityResponse.next(data);
  }
  loadAttributesByEntIdNested(id: any) {
    let isReserved = false;
    this.http
      .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=${isReserved}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.entityAttributesNested.next(entityMapper(res.result));
      });
  }
  loadAttributesByEntId(
    id: any,
    attribute?: any,
    entityLevel?: any,
    isReserved?: any,
    reservedActions?: any,
    addEntitytToGsi?: any,
    slotEntity?: any,
    entityIndex?: any,
    indCu?: string,
    editAttribute?: boolean,
    isNested?: boolean,
    isPrimaryKeySearch?: boolean
  ) {
    /*istanbul ignore next*/
    isReserved = isReserved ? isReserved : false;
    isNested = isNested ? isNested : false;
    /*istanbul ignore next*/
    return this.http
      .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=${isReserved}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = entityMapper(res?.result);
        if (isPrimaryKeySearch) {
          if (data?.uniqueConstraints?.length > 0) {
            data.nslAttributes = data?.uniqueConstraints[0];
          } else {
            this.alertService.showToaster('Add Entity with Unique Constraint Attributes', 'Info', 'error', 5000);
            return;
          }
        }
        /*istanbul ignore next*/
        if (((res?.result?.name?.includes('NSL_')||isReservedCU(res?.result)) || res?.result?.name?.includes('NSL ')) && !isNested) {
          if (attribute) {
            this.attributeWithEntity.next({ entity: data, attribute: attribute });
          } else {
            this.entityAttributes.next(data);
          }
        } else if (location.pathname.includes('nsl-eql')) {
          this.entityAttributes.next(data);
        } else {
          /*istanbul ignore next*/
          if (res?.result?.status == 'PUBLISHED') {
            /*istanbul ignore next*/
            if (attribute && typeof attribute === 'object') {
              attribute?.forEach((attr: any) => {
                attr.tfId = attr?.dsdId;
              });
              //attribute.tfId = attribute?.dsdId;
              //this.editPublishedBET(id, 'entity', attribute);
              /*istanbul ignore next*/
              if (attribute) {
                this.attributeWithEntity.next({ entity: data, attribute: attribute });
              } else {
                this.entityAttributes.next(data);
              }
              //this.attributeWithEntity.next({ entity: data, attribute: attribute });
            } else {
              if (addEntitytToGsi) {
                this.entityAttributesGsi.next(data);
              } else if (slotEntity) {
                this.slotAttribute.next(data);
              } else if (indCu) {
                this.indCuEntityData.next(data);
              } else if (editAttribute) {
                this.editAttributeEntity.next(data);
              } else if (isNested) {
                this.nestedEntity.next(data);
              } else {
                this.entityAttributes.next(data);
              }
            }
            //this.editPublishedBET(id, 'entity', attribute);
          } else if (
            /*istanbul ignore next*/
            res?.result?.status == 'READY' ||
            res?.result?.status == 'APPROVED' ||
            res?.result?.status == 'REJECTED'
          ) {
            this.cancelReview('entity', res?.result);
          } else {
            /*istanbul ignore next*/
            if (attribute && typeof attribute === 'object') {
              attribute?.forEach((attr: any) => {
                attr.tfId = attr?.dsdId;
              });
              // attribute.tfId = attribute?.dsdId;
              this.attributeWithEntity.next({ entity: data, attribute: attribute });
            } else {
              if (addEntitytToGsi) {
                this.entityAttributesGsi.next(data);
              } else if (slotEntity) {
                this.slotAttribute.next(data);
              } else if (indCu) {
                this.indCuEntityData.next(data);
              } else if (editAttribute) {
                this.editAttributeEntity.next(data);
              } else {
                this.entityAttributes.next(data);
              }
            }
          }
        }
      });
  }
  getEntityAtribute(data: any) {
    this.entityAttributes.next(data);
  }
  editPublishedBET(id: any, betType: any, attribute?: any, editFetchData?: boolean) {
    /*istanbul ignore next*/
    this.http
      .post(this.api?.editPublishedBET + `${betType}/${id}`, {})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = entityMapper(res?.result);
        if (attribute) {
          this.attributeWithEntity.next({ entity: data, attribute: attribute });
        } else if (editFetchData) {
          this.editPublishedEntity.next(data);
        } else {
          this.entityAttributes.next(data);
        }
      });
  }

  cancelReview(betType: any, data: any) {
    /*istanbul ignore next*/
    this.http
      .post(this.api?.cancelReview + `${betType}/` + `${data.id}` + '?cancelReview=true', data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = entityMapper(res?.result);
        this.entityAttributes.next(data);
      });
  }
  /*istanbul ignore next*/
  loadEntityDataById(id: any, layer = 'physical', updateCU = true) {
    return this.http
      .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=false`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = entityMapper(res?.result);
        data.layer = layer;
        data.updateCU = updateCU;
        this.entityData.next(data);
      });
  }

  /*istanbul ignore next*/
  loadGSIEntityDataById(id: any, layer = 'physical', updateCU = true) {
    return this.http
      .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=false`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = entityMapper(res?.result);
        data.layer = layer;
        data.updateCU = updateCU;
        this.entityData.next(data);
      });
  }

  getSaveentityResponse(data: any) {
    this.saveentityResponse.next(data);
  }

  getSaveEntityResponseForIndCu(data: any): any {
    this.saveentityResponseForIndCu.next(data);
  }

  getSaveentityResponseForIndEnt(data: any) {
    this.saveentityResponseForIndEnt.next(data);
  }

  saveEntityGsi(
    body: Entity,
    isDld?: any,
    changeUnit?: any,
    entityAttributes?: any,
    layer?: string,
    layerChanged?: boolean,
    oldLayer?: string
  ) {
    /*istanbul ignore next*/
    if (body?.tfId) {
      //  const entInfo = entityDeMapper(body);
      let reqBody: any = entityDeMapper(body);
      this.http.post(this.api?.getAttributeByEntityId, reqBody).subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        res.result = entityMapper(res.result);
        res = this.returnUpdatedEntityResponse(res, changeUnit, res.nslAttributes, layer, layerChanged);
        this.saveentityResponseinGSI.next(res);
      });
      // return this.http.post(environment.tfCoreUrl + '/Entity', body);
    }
  }
  // save Entity after Importing from DLD to TF or Created Entity to TF
  /*istanbul ignore next*/
  saveNestedEntity(body: any, nestedTags?: any, changeUnit?: any, deleteNested?: any) {
    if (nestedTags) {
      body.nestedTags = nestedTags;
      body.changeUnit = {
        name: changeUnit.name,
      };
    }
    if (deleteNested) {
      this.nestedEntityDeleteObservable.next(body);
    } else {
      this.nestedEntityObservable.next(body);
    }
  }
  saveEntityForSF(body: any) {
    let mappedBody = entityDeMapper(body);
    /* istanbul ignore next */
    this.http.post(this.api?.getAttributeByEntityId, mappedBody).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
      res.result = entityMapper(res.result);
      this.sendEntityResponseToSf.next(res);
    });
  }
  clearEntityData() {
    this.sendEntityResponseToSf.next('');
  }
  deleteNestedEntity(entity: any) {
    let entityInfo = entityDeMapper(entity);
    entityInfo.dsdId = entityInfo.masterId;
    entityInfo.id = entityInfo.dsdId;
    this.http.post(this.api?.getAttributeByEntityId, entityInfo).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
    });
  }
  saveMainEntity(entity: any) {
    let entityInfo = entityDeMapper(entity);
    this.http.post(this.api?.getAttributeByEntityId, entityInfo).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
      this.nestedEntityResponse.next(res);
    });
  }
  saveEntity(
    body: any,
    isDld?: any,
    changeUnit?: any,
    entityAttributes?: any,
    layer?: string,
    layerChanged?: boolean,
    oldLayer?: string,
    configuration?: any,
    conditionData?: any
  ) {
    /* istanbul ignore next */
    let isMulti = body?.isMultiValue;
    /* istanbul ignore next */
    let isNested = changeUnit?.isNested;
    let changeDriverMetaData = body?.changeDriverMetaData;
    /* istanbul ignore next */
    if (body?.isMultiValue) {
      delete body.isMultiValue;
    }
    /* istanbul ignore next */
    if (isDld) {
      let entityInfo: any = entityDeMapper(body);
      /* istanbul ignore next */
      this.http.post(this.api?.getAttributeByEntityId, entityInfo).subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        const entity = entityMapper(res.result);
        this.loadAttributesByEntId(entity.id);
      });
    } else if (body?.tfId) {
      body.configuration = configuration;
      let reqBody: any = entityDeMapper(body);
      this.http.post(this.api?.getAttributeByEntityId, reqBody).subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        res.result = entityMapper(res.result);
        res = this.returnUpdatedEntityResponse(res, changeUnit, res?.nslAttributes, layer, layerChanged);
        if (oldLayer) {
          res.oldLayer = oldLayer;
          res.layer = layer;
        }
        if (configuration) {
          res.result.configuration = configuration;
        }
        if (isMulti) {
          res.result.isMultiValue = isMulti;
        }
        if (changeDriverMetaData) {
          res.result.changeDriverMetaData = changeDriverMetaData;
        }
        if (changeUnit) {
          let layerIndex = changeUnit.layers.findIndex((q: any) => {
            return q.type == layer;
          });
          let entityIndex = -1;
          if (layerIndex != -1) {
            entityIndex = changeUnit.layers?.[layerIndex]?.participatingItems?.findIndex((q: any) => {
              return q.id == res.result?.id;
            });
          }
          let nslAttributes = res?.result?.nslAttributes.filter((x: any) => {
            let k = changeUnit?.layers[layerIndex]?.participatingItems[entityIndex]?.nslAttributes.findIndex(
              (y: any) => {
                return y.id == x.id;
              }
            );
            if (k != -1) {
              return x;
            }
          });
          res.result.nslAttributes = nslAttributes;
        }
        /* istanbul ignore next */
        if (this.updateCorrectEntityData(changeUnit, layer, res.result).length > 0 && !body?.isSameEntity) {
          res.result.nslAttributes = this.updateCorrectEntityData(changeUnit, layer, res?.result);
        }
        if (body?.nestedNumber >= 0 || isNested) {
          res.nestedNumber = body?.nestedNumber ? body?.nestedNumber : changeUnit?.nestedNumber;
          this.updatedEntityResponse.next(res);
        } else {
          if (conditionData) {
            res.conditionData = conditionData;
          }
          this.saveentityResponse.next(res);
        }
      });
      //  const entInfo = entityDeMapper(body);
      // return this.http.post(environment.tfCoreUrl + '/Entity', body);
    } else {
      if (body?.entityType != 'enum' || emptyObject(body?.entityType)) {
        delete body.entityType;
      }
      let reqBody: any = entityDeMapper(body);
      /* istanbul ignore next */
      this.http.post(this.api?.getAttributeByEntityId, reqBody).subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        res.result = entityMapper(res.result);
        res = this.returnUpdatedEntityResponse(res, changeUnit, entityAttributes, layer);
        /* istanbul ignore else */
        if (configuration) {
          res.result.configuration = configuration;
        }
        /* istanbul ignore else */
        if (isMulti) {
          res.result.isMultiValue = isMulti;
        }
        /* istanbul ignore next */
        if (this.updateCorrectEntityData(changeUnit, layer, res.result).length > 0 && !body?.isSameEntity) {
          res.result.nslAttributes = this.updateCorrectEntityData(changeUnit, layer, res?.result);
        }
        /* istanbul ignore next */
        if (body?.nestedNumber >= 0 || isNested) {
          /* istanbul ignore next */
          res.nestedNumber = body?.nestedNumber ? body?.nestedNumber : changeUnit?.nestedNumber;
          this.updatedEntityResponse.next(res);
        } else {
          this.saveentityResponse.next(res);
        }
      });
    }
  }
  updateCorrectEntityData(changeUnit: any, layer: any, entity: any) {
    /*istanbul ignore next*/
    let layerIndex = changeUnit?.layers?.findIndex((q: any) => {
      return q.type == layer;
    });
    /*istanbul ignore next*/
    let cuEntity = changeUnit?.layers[layerIndex]?.participatingItems?.find((ent: any) => ent?.id == entity?.id);
    /*istanbul ignore next*/
    let nslAttributes = cuEntity?.nslAttributes;
    let entityObjAttributes: any = [];
    /*istanbul ignore next*/
    nslAttributes?.forEach((attr: any) => {
      if (attr) {
        let attributeType = attr?.attributeType;
        let foundAttr = entity?.nslAttributes.find((x: any) => x?.name === attr?.name);
        /* istanbul ignore next */
        if (foundAttr) {
          if (foundAttr?.attributeType) foundAttr.attributeType = attributeType;
          entityObjAttributes.push(foundAttr);
        }
      }
    });
    return entityObjAttributes;
  }
  saveGsiEntity(body: any, layer?: string, id?: any) {
    /*istanbul ignore next*/
    let isMulti = body?.isMultiValue ? body?.isMultiValue : false;
    /*istanbul ignore next*/
    let changeDriverMetaData = body?.changeDriverMetaData;
    /* istanbul ignore next */
    if (body?.isMultiValue) {
      delete body.isMultiValue;
    }
    /* istanbul ignore next */
    if (body?.tfId) {
      body = entityDeMapper(body);
    }
    /*istanbul ignore next */
    this.http.post(this.api?.getAttributeByEntityId, body).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
      res.result = entityMapper(res.result);
      res.result.isMultiValue = isMulti;
      res.layer = layer;
      /* istanbul ignore next */
      if (id) {
        res.id = id;
      } else {
        res.id = res?.result?.id;
      }
      /*istanbul ignore next*/
      if (changeDriverMetaData) {
        res.result.changeDriverMetaData = changeDriverMetaData;
      }
      this.saveGsientityResponse.next(res);
    });
  }
  setGsiEntity(res: any) {
    this.saveGsientityResponse.next(res);
  }
  getSaveentityResponseForConfigure(
    body: any,
    isDld?: any,
    changeUnit?: any,
    entityAttributes?: any,
    layer?: string,
    layerChanged?: boolean,
    oldLayer?: string,
    configuration?: any,
    isNestedEntityData?: any
  ) {
    /* istanbul ignore next */
    let isSeperateCU = changeUnit?.isSeperateCU;
    /* istanbul ignore next */
    let isMulti = body?.isMultiValue;
    /* istanbul ignore next */
    let changeDriverMetaData = body?.changeDriverMetaData;
    let res = this.returnUpdatedEntityResponse(body, changeUnit, body.nslAttributes, layer, layerChanged);
    if (oldLayer) {
      res.oldLayer = oldLayer;
      res.layer = layer;
    }
    res.result = JSON.parse(JSON.stringify(res));
    if (configuration) {
      res.result.configuration = configuration;
    }
    if (isMulti) {
      res.result.isMultiValue = isMulti;
    }
    if (changeDriverMetaData) {
      res.result.changeDriverMetaData = changeDriverMetaData;
    }
    res.isNestedEntityData = isNestedEntityData?.nestedEntity ? isNestedEntityData : undefined;
    /* istanbul ignore next */
    if (isSeperateCU) {
      res.layerChanged = layerChanged;
      this.saveIndentityResponse.next(res);
    } else {
      res.isEntConfig = true;
      res.entityNumber = isNestedEntityData?.entityIndex;
      this.saveentityResponse.next(res);
    }
  }
  /*istanbul ignore next*/
  saveEntityForIndCu(body: Entity, changeUnit?: any, entityAttributes?: any) {
    /* istanbul ignore next */
    if (body?.tfId) {
      //  const entInfo = entityDeMapper(body);
      let reqBody: any = entityDeMapper(body);
      this.http.post(this.api?.getAttributeByEntityId, reqBody).subscribe(
        (res: any) => {
          this.alertService.showToaster(res.message, '', 'success');
          res.result = entityMapper(res.result);
          res = this.returnUpdatedEntityResponse(res, changeUnit, entityAttributes);
          this.saveentityResponseForIndCu.next(res);
        },
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
      // return this.http.post(environment.tfCoreUrl + '/Entity', body);
    } else {
      this.http.post(this.api?.getAttributeByEntityId, body).subscribe(
        (res: any) => {
          this.alertService.showToaster(res.message, '', 'success');
          res.result = entityMapper(res.result);
          res = this.returnUpdatedEntityResponse(res, changeUnit, entityAttributes);
          this.saveentityResponseForIndCu.next(res);
        },
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
    }
  }
  /*istanbul ignore next*/
  saveEntityForIndEnt(body: any, changeUnit?: any, entityAttributes?: any) {
    /* istanbul ignore next */
    let changeDriverMetaData = body?.changeDriverMetaData;
    if (body?.tfId) {
      //  const entInfo = entityDeMapper(body);
      let reqBody: any = entityDeMapper(body);
      /*istanbul ignore next*/
      this.http.post(this.api?.getAttributeByEntityId, reqBody).subscribe(
        (res: any) => {
          this.alertService.showToaster(res.message, '', 'success');
          res.result = entityMapper(res.result);
          res = this.returnUpdatedEntityResponse(res, changeUnit, entityAttributes);
          if (changeDriverMetaData) {
            res.result.changeDriverMetaData = changeDriverMetaData;
          }
          this.saveentityResponseForIndEnt.next(res);
        },
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
      // return this.http.post(environment.tfCoreUrl + '/Entity', body);
    } else {
      /* istanbul ignore next */
      this.http.post(this.api?.getAttributeByEntityId, body).subscribe(
        (res: any) => {
          this.alertService.showToaster(res.message, '', 'success');
          res.result = entityMapper(res.result);
          res = this.returnUpdatedEntityResponse(res, changeUnit, entityAttributes);
          if (changeDriverMetaData) {
            res.result.changeDriverMetaData = changeDriverMetaData;
          }
          this.saveentityResponseForIndEnt.next(res);
        },
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
    }
  }

  returnUpdatedEntityResponse(
    res: any,
    changeUnit?: any,
    entityAttributes?: any,
    layer?: string,
    layerChanged?: boolean
  ) {
    /*istanbul ignore next*/
    if (changeUnit?.index) {
      /* istanbul ignore next */
      res.changeUnitIndex = changeUnit?.index;
      /* istanbul ignore next */
      res.sentenceTags = changeUnit?.sentenceTags;
      /* istanbul ignore next */
      res.description = changeUnit?.description;
      res.layer = layer;
      res.layerChanged = layerChanged;
    }
    /*istanbul ignore else*/
    if (entityAttributes) {
      res.entityAttributes = entityAttributes;
    }
    return res;
  }
  /*istanbul ignore next*/
  updateEntity(body: any, isDld?: boolean, isOCR?: boolean) {
    //this.loader.show()sub;
    const entInfo = entityDeMapper(body);
    /*istanbul ignore next*/
    this.http.post(this.api?.getAttributeByEntityId, entInfo).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.loader.hide();
        const updateInfo = entityMapper(res.result);
        this.updateEntityResponse.next(updateInfo);
      }
    });
  }
  getUpdateEntity(data: any) {
    this.updateEntityResponse.next(data);
  }
  getCloneDetails(id: number, isPublished: boolean, type: any, data: any) {
    //this.loader.show()sub;
    /*istanbul ignore next*/
    this.http
      .post(this.api?.betsStoreTenant + `${type}/clone/${id}?isPublished=${isPublished}`, data)
      .subscribe((res: any) => {
        this.alertService.showToaster(res['message'], '', 'success');
        this.loader.hide();
        /*istanbul ignore next*/
        if (type === 'change-unit') {
          let returnedValue = betMapper(type, res);
          this.cloneResponse.next(returnedValue);
        } else {
          const entRes = entityMapper(res?.result);
          this.entityAttributes.next(entRes);
        }
      });
  }
  getAllEntities(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean, layerType?: any) {
    const ontology = '';
    const publisherId = '';
    /*istanbul ignore next*/
    this.http
      .get(
        this.api?.getAllEntities +
          `?query=${searchCriteria}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${
            pagenum + 1
          }&limit=${pageSize}&isPublished=${isPublished}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.entityList.next(res);
      });
  }
  getallEntity(data: any) {
    this.entityList.next(data);
  }

  getEntitiesForEvents(pagenum: number, pageSize: number, searchCriteria: string) {
    const ontology = '';
    const publisherId = '';
    /*istanbul ignore next*/
    this.http
      .get(
        this.api?.getAllEntities +
          `?query=${searchCriteria}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${pagenum}&limit=${pageSize}&isPublished=`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.eventEntityList.next(res);
      });
  }

  getEntityDeatils(id: any, isReserved?: boolean) {
    /*istanbul ignore next*/
    return this.http.get<any>(this.api?.getAttributeByEntityId + `/${id}?isReserved=${isReserved}`);
  }

  checkForOtherEntities(status: boolean) {
    this.checkForOtherEntitiesSubject.next(status);
  }
  //create request body for saving DLD Entity to TF
  /*istanbul ignore next*/
  saveDldEntity(dldEntity?: any) {
    let reqBody: any = {
      name: dldEntity.name,
      isMultiValue: false,
      entityClassification: '',
      nslAttributes: dldEntity?.nslAttributes ? dldEntity.nslAttributes : [],
    };
    this.saveEntity(reqBody, dldEntity.isDld);
  }
  addAttribute(attribute?: any) {}
  /*istanbul ignore next*/
  finishEntity(entity: any) {
    const ent = entityDeMapper(entity);
    this.http
      .post(this.api?.versionFinishEntity, ent)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore else*/
        if (res) {
          this.alertService.showToaster(res.message, '', 'success');
          this.finishEntitySubject.next(entityMapper(res.result));
        }
      });
  }
  publishEntity(entity: any) {
    /*istanbul ignore next*/
    this.http
      .post(`${this.api?.skipVersionPublishEntity}`, entityDeMapper(entity))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore else*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          this.entityPublishResponse.next(entityMapper(res.result));
        }
      });
  }

  getFinishEntity(data: any) {
    this.finishEntitySubject.next(data);
  }
  fetchSpecialFeatures() {
    /*istanbul ignore next*/
    this.http.get(`${this.api?.tfCoreUrl}/specialFeatureTypes`).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        let spf = {
          isSpecialFeatureAvailable: true,
          ...res,
        };
        this.specialFeatureSubject.next({ ...this.specialFeatureSubject.value, ...spf });
      }
    });
  }
  detectSpecialFeatures(data: any) {
    this.specialFeatureSubject.next(data);
  }
  getEqlOperators() {
    /*istanbul ignore next*/
    this.http.get(this.api?.getEqlOperator).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        let operators: any = {
          operators: res.result,
          isOperatorsAvailable: true,
        };
        this.eqlOperatorSubject.next({
          ...this.eqlOperatorSubject.value,
          ...operators,
        });
      }
    });
  }
  checkExistingEntity(entityName: any, compositeExtraction?: boolean) {
    /* istanbul ignore next */
    this.http
      .post(`${this.api?.getEntityIdByName}`, {
        CU_list: [
          {
            name: '',
            agent: [],
            entities: [{ name: entityName }],
          },
        ],
      })
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res?.CU_list?.length > 0) {
          res.CU_list.forEach((item: any) => {
            item.entities.forEach((entity: any) => {
              if (entity.name == entityName) {
                /*istanbul ignore next*/
                if (entity?.dsdId) {
                  /*istanbul ignore next*/
                  this.http
                    .get(`${this.api?.getAttributeByEntityId}/${entity.dsdId}?isReserved=${false}`)
                    .subscribe((res: any) => {
                      /*istanbul ignore next*/
                      if (compositeExtraction) {
                        if (res && res?.result) {
                          this.existingEntityComposite.next(res.result);
                        } else {
                          this.existingEntityComposite.next({ entityExists: false, entityName });
                        }
                      } else {
                        if (res && res?.result) {
                          this.existingEntity.next(res.result);
                        } else {
                          this.existingEntity.next({ entityExists: false, entityName });
                        }
                      }
                    });
                } else {
                  if (compositeExtraction) {
                    this.existingEntityComposite.next({ entityExists: false, entityName });
                  } else {
                    this.existingEntity.next({ entityExists: false, entityName });
                  }
                }
              }
            });
          });
        }
      });
  }
  openInformationLayer(value: boolean) {
    this.infoLayerOpen.next(value);
  }

  openTriggerCesLayer(value: boolean) {
    this.triggerCESLayerOpen.next(value);
  }
  getEntityDeatilsbyId(id: string) {
    /*istanbul ignore next*/
    return this.http
      .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=${false}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data: Entity = entityMapper(res?.result);
        this.entitydetails.next(data);
      });
  }

  saveSfGrammar(trigger: any) {
    this.sfGrammarSave.next(trigger);
  }
  // checkExistingEntity(entity: any){
  //   const name = entity.name;
  //   this.http.get(`${this.api?.tfCoreUrl}/Entity/${name}`).subscribe((res: any) => {
  //     if(res && res?.result){
  //       this.existingEntity.next(res.result);
  //     } else {
  //       this.existingEntity.next({entityExists: true})
  //     }
  //   })
  // }

  /*istanbul ignore next*/
  getEntityDetailsforReservedActions(id: any) {
    return this.http.get<any>(this.api?.getAttributeByEntityIdForReservedActions + `${id}`);
  }
  addNewIndEntity(data: boolean) {
    this.newIndEntity.next(data);
  }

  addnewconfig(data: boolean) {
    this.newconfig.next(data);
  }
  saveTargetEntity(targetEntity: any) {
    let reqBody: any = entityDeMapper(targetEntity);
    this.http.post(this.api?.multipleEntitySave, reqBody).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res?.result) {
        this.alertService.showToaster(res.message, '', 'success');
      }
      this.loader.hide();
      /*istanbul ignore next*/
      if (res?.result?.length > 0) {
        for (var i = 0; i < res.result?.length; i++) {
          res.result[i] = entityMapper(res.result[i]);
        }
      }
      this.targetEntityResponse.next(res);
    });
  }

  updateTargetEntity(targetEntity: any) {
    let reqBody: any = entityDeMapper(targetEntity);
    this.http.post(this.api?.getAttributeByEntityId, reqBody).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res?.result) {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        const updateInfo = entityMapper(res.result);
        this.updatedTargetEntityResponse.next(updateInfo);
      }
    });
  }

  sendupdateEntityTblConfig(data: any) {
    this.updateEntityTblConfig.next(data);
  }
  getTriggerCuAttrUiElement(data: any) {
    this.http.post(this.api?.triggerCuAttrUiElement, data).subscribe((res: any) => {
      if (res) {
        this.triggerCuAttrUiElement.next(res);
      }
    });
  }
  getAttributeComparisonData(data: any) {
    this.http.post(this.api?.attributesComparison, data).subscribe((res) => {
      if (res) {
        this.AttributeComparisonData.next(res);
      }
    });
  }
  uiControlsFetch(data: any) {
    this.http.post(this.api?.attributeSuggestion, data).subscribe((res: any) => {
      if (res && res?.length > 0) {
        this.attributeUiControls.next(res);
      }
    });
  }
  fetchEntityByEntityId(id: any) {
    if (id) {
      this.http
        .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=${false}`)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          /*istanbul ignore next*/
          const data: Entity = entityMapper(res?.result);
          this.entityDetails.next(data);
        });
    }
  }
  
  verticalTableConfigurationData(flag: boolean, data: any) {
    this.verticalTableConfigData.next({ flag: flag, configurationData: data });
  }

  fetchEntityForReservedCU(id: any, isPrimaryKeySearch?: boolean) {
    this.http
      .get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=${false}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const data = entityMapper(res?.result);
        if (isPrimaryKeySearch) {
          /*istanbul ignore next*/
          if (data?.uniqueConstraints?.length > 0) {
            data.nslAttributes = data?.uniqueConstraints[0];
          } else {
            this.alertService.showToaster('Add Entity with Unique Constraint Attributes', 'Info', 'error', 5000);
            return;
          }
        }
        this.slotEntity.next(data);
      });
  }
}
