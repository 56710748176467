import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertService, DraggableAttribute, EntityBoardEndpointService, GsiButton, ICardStyle } from '@common-services';


@Component({
  selector: 'app-expand-templates-base',
  templateUrl: './expand-templates-base.component.html',
  styleUrls: ['./expand-templates-base.component.scss'],
})
export class ExpandTemplatesBaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() displayNameMap: Map<string,string>;
  @Input() txnDataIndex: any;
  @Output() emitExecuteCard = new EventEmitter();
  expandType: any;
  @Input() set _expandType(value: any) {
    if (value?.template) {
      this.renderDynamicCard();
    }
    this.expandType = value?.template ? value : Number(value);
  }
  @Input() attributeUIElementMap: Map<string, string>;
  @Input() expandCardDetails: any;
  @Input() gsiExpanddata: any;
  @Input() expandColors: any;
  @Input() expandBackground: any;
  @Input() conditionArrayExpand: any;
  @Input() btnColors: any;
  @Input() otherStyles: any;
  @Output() closeCardEmitter: EventEmitter<boolean> = new EventEmitter();
  expandDetails: any;
  contentURL: any = [];
  expandedCardDetail: any[] = [];
  @Input() entityName: string;
  card: { recordData: any[] };
  @Input() expandBoxShadow: any;
  @Input() attrhoveredColors: any;
  @Input() cardHoveredColor: any;
  @Input() expandCardStyle: any;
  @Input() cardDisplay: any;
  @Input() expandStyleAttribute: any;
  @Input() isEdit: boolean = false;
  // @Input() mappedExpandGsi :any

  dropActionBts: any = {};
  @Input() style: any;
  // @Input() expandType: number | string = 0;
  @Input() previewType: 'wireFrame' | 'Preview';
  @Input() selectGsiListExpand: any;
  droppedExpandColors: any;
  @Input() selectedDirectiveId: any;
  @Input() boxShadowData: string = '';
  @Input() isDesign: boolean = false;
  cardColorExpand: any;
  btnColorsExpand: any = [];
  droppedStylesExpand: any = [];
  expandGsiMapping: { [key: number]: GsiButton } = {};
  @Input() expandGsiList: GsiButton[] = [];
  @Input() CART_BUTTON: GsiButton;
  @Output() expandGsiListemit = new EventEmitter();
  @Output() dialogEmit = new EventEmitter();
  @Output() CART_BUTTON_EMIT = new EventEmitter();
  hoverColorsFinalExpand: any = [];
  expandHoverBackground: any;
  @Input() set colorConditionSetter(value: any) {
    this.conditionColors = value;
  }
  conditionColors: any;
  @Input() set changeObj(data: any) {
    // this.sendExpandData();
  }

  dropExpandAttributesForDeletion: any = [];

  @Input() isGrammer: any = false;
  @Input() dragAttrType: any;

  @Input() templateType: any;
  @Output() droppedAttr = new EventEmitter();
  @Output() onDeletion = new EventEmitter();
  @Output() droppedActionButton = new EventEmitter();
  @Input() tabSelected: any;
  droppedAttrExpand: any = {};
  @Output() droppedAttrExpandEmit = new EventEmitter();
  @Output() emitConditionColor = new EventEmitter();
  @Input() hoverColorsExpand: any;
  @ViewChild('dropEntityData') dropEntityData: ElementRef;
  @Input() expandPositionMap: { [key: number]: DraggableAttribute } = {};
  @Input() expandStyleGsi: ICardStyle[];
  @Input() set mappedExpandGsi(value: any) {
    if (value) {
      value.forEach((attr: any, index: number) => {
        let tempGsi = this.expandGsiList.findIndex((gsi: any) => gsi.name == attr);
        if (tempGsi != -1) {
          this.makeGsiButtonFromId(index, tempGsi);
        }
      });
    }
  }
  @Input() btnHoverColors: any = [];
  btnHoveredBoolean: boolean[] = [];
  isDynamicExpandCardSelected: boolean = false;
  @Input() set _isDynamicExpandCardSelected(value: boolean) {
    this.isDynamicExpandCardSelected = value;
    if (value) {
      this.renderDynamicCard();
    }
  }
  constructor(private alertService: AlertService, private entityBoardService: EntityBoardEndpointService,public element?: ElementRef) {}

  ngOnInit(): void {
    if (this.cardDisplay == 'view') {
      this.btnColors?.forEach((color: any, index: number) => {
        this.btnHoveredBoolean[index] = false;
      });
    }
  }

  ngOnChanges(): void {}
  /*istanbul ignore next*/
  renderDynamicCard() {
    let placeholder = '.dynamic-template-placeholder';

    this.element.nativeElement.querySelector(placeholder).innerHTML = '';
    /* istanbul ignore next */
    this.element.nativeElement.querySelector(placeholder).innerHTML = this.expandType.template;
    /* istanbul ignore next */
    this.element.nativeElement.querySelectorAll(placeholder + ' li').forEach((e: any) => {
      e.addEventListener('drop', (event: any) => this.dropAttribute(event, 'any'));
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
    /* istanbul ignore next */
    this.element.nativeElement.querySelectorAll(placeholder + ' .card-logo').forEach((e: any) => {
      e.addEventListener('drop', (event: any) => this.dropAttribute(event, 'image'));
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
    this.element.nativeElement.querySelectorAll(placeholder + ' .card-video').forEach((e: any) => {
      e.addEventListener('drop', (event: any) => this.dropAttribute(event, 'video'));
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
    /* istanbul ignore next */
    this.element.nativeElement.querySelectorAll(placeholder + ' button').forEach((e: any) => {
      e.addEventListener('drop', (event: any) => this.dropActionBtn(event));
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
  }
  onDeletingAttr(element: any) {
    this.onDeletion.emit(element);
  }

  dropAttribute(ev: any, attrType?: any) {
    if (
      this.dragAttrType != 'button' &&
      ((this.dragAttrType && attrType == 'image' && this.dragAttrType == 'image') ||
        (this.dragAttrType && attrType == 'any' && this.dragAttrType !== 'image'))
    ) {
      this.dropAttributeInTemplates(ev);
    } else if (
      this.dragAttrType != 'button' &&
      (attrType == this.dragAttrType || (attrType == 'label' && this.dragAttrType == 'metric'))
    ) {
      this.dropAttributeInTemplates(ev);
    } else {
      this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
    }
    // this.dragAttrType = undefined;
  }

  dropAttributeInTemplates(ev: any) {
    // this.dropAttributeInTemplatesExpand(ev);
    this.droppedAttr.emit(ev);
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  dropActionBtn(event: any) {
    /* istanbul ignore next */
    if (this.dragAttrType === 'button' || this.dragAttrType === 'cart') {
      if (this.checkGSIMappingCondition()) {
        this.alertGSIMappedToCart();
        return;
      }
      let buttonId = parseInt(event?.target?.id?.split('-')[1]);
      if (!this.expandGsiMapping?.[buttonId]) {
        event.preventDefault();
        if (this.dragAttrType == 'button') {
          const data = event?.dataTransfer?.getData('text');
          const gsiIndex = parseInt(data?.split('-')[1]);
          this.makeGsiButtonFromId(buttonId, gsiIndex);
        } else {
          this.makeCartButtonFrom(buttonId);
        }
      } else {
        if (this.expandGsiMapping[buttonId].entityType == 'GSI' && this.dragAttrType == 'cart')
          this.alertGSIMappedToCart();
        this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      }
    }
  }

  /*istanbul ignore next*/
  checkGSIMappingCondition(): boolean {
    let keysLength = Object.keys(this.expandGsiMapping).length;
    if (keysLength > 0 && this.dragAttrType == 'cart') {
      return true;
    } else if (
      this.dragAttrType == 'button' &&
      (this.CART_BUTTON.isSelected['child'].isSelected || this.CART_BUTTON.isSelected['expand'].isSelected)
    ) {
      return true;
    }
    return false;
  }

  /*istanbul ignore next*/
  alertGSIMappedToCart() {
    this.dialogEmit.emit();
  }

  /*istanbul ignore next*/
  makeCartButtonFrom(buttonId: number) {
    this.CART_BUTTON.isSelected['expand'].isSelected = true;
    this.CART_BUTTON.isSelected['expand'].position = buttonId;
    this.expandGsiMapping[buttonId] = this.CART_BUTTON;
    this.dropActionBts['action-' + buttonId] = this.CART_BUTTON;
    this.CART_BUTTON_EMIT.emit(this.CART_BUTTON);
  }

  /*istanbul ignore next*/
  makeGsiButtonFromId(buttonId: number, gsiId: number) {
    this.expandGsiMapping[buttonId] = this.expandGsiList[gsiId];
    this.expandGsiList[gsiId].isSelected = true;
    this.expandGsiList[gsiId].position = buttonId;
    this.dropActionBts['action-' + buttonId] = this.expandGsiList[gsiId];
    this.expandGsiListemit.emit(this.expandGsiList);
  }

  /*istanbul ignore next*/
  removeGsiFromCard(index: number) {
    if (this.expandGsiMapping[index].entityType === 'GSI') {
      this.expandGsiMapping[index].isSelected = false;
      delete this.expandGsiMapping[index];
      delete this.dropActionBts['action-' + index];
    } else {
      this.expandGsiMapping[index].isSelected['expand'].isSelected = false;
      delete this.expandGsiMapping[index];
      delete this.dropActionBts['action-' + index];
    }
  }

  recieveEmittedDirectiveId(event: any) {
    this.selectedDirectiveId = event;
  }
  recieveConditionColor(event: any) {
    this.emitConditionColor.emit(event);
  }
  recieveExpandHoverColor(event: any) {
    this.expandHoverBackground = event;
  }
  ngOnDestroy() {
    // this.sendExpandData();
    if (Object.keys(this.droppedAttrExpand).length > 0 || Object.keys(this.dropActionBts).length > 0) {
      if (this.isGrammer && this.droppedAttrExpand) {
        this.droppedAttrExpandEmit.emit({ droppedAttrExpand: this.droppedAttrExpand, event: true });
      }
      this.entityBoardService.sendDataToEntityCard({
        attrData: this.droppedAttrExpand,
        gsiData: this.dropActionBts,
        dropExpandColors: this.droppedExpandColors,
        expandBackground: this.cardColorExpand,
        expandBtnColors: this.btnColorsExpand,
        droppedStylesExpand: this.droppedStylesExpand,
        expandHoverBackground: this.expandHoverBackground,
      });
    }
  }
  noOfValue(len: number) {
    return Array.from({ length: len }, (v, i) => i);
  }
  singleRecord: any = {};

  @Input() set _recordId(data: string) {
    // this.expandCardDetails = [];
    this.expandedCardDetail = this.expandCardDetails?.filter((card: any) => card.recordId == data);
    /* istanbul ignore else*/
    Object.keys(this.expandDetails).forEach((key: any) => {
      if (this.expandedCardDetail?.[0]?.[this.expandDetails?.[key]]) {
        /*istanbul ignore next*/
        this.singleRecord[key] = {'attributeName' : this.expandDetails?.[key] ,'value':this.expandedCardDetail?.[0]?.[this.expandDetails?.[key]] }
        if (this.isJsonString(this.expandedCardDetail?.[0]?.[this.expandDetails?.[key]])) {
          let image = JSON.parse(this.expandedCardDetail?.[0]?.[this.expandDetails?.[key]]);
          this.contentURL[key] = image?.contentUrl;
        }
      }
    });
    this.card = { recordData: [] };
    this.expandedCardDetail.forEach((card: any) => {
      Object.keys(card).forEach((key) => {
        this.card.recordData.push({ attributeName: key, value: card[key] });
      });
    });
  }

  expandMapSet: Set<string>;

  @Input() set expandMap(data: any) {
    if(data){
      this.expandMapSet = new Set(Object.keys(data));
      this.expandDetails = data;
    }
  }

  closeCard(): void {
    this.closeCardEmitter.emit(true);
  }
  gotoTxnPage(gsi: any, card?: any) {
    this.emitExecuteCard.emit({ gsi: gsi, card: card, txnDataIndex: this.txnDataIndex });
  }
  isJsonString(jsonString: string): boolean {
    try {
      var json = JSON.parse(jsonString);
      /* istanbul ignore next */
      if (json && typeof json === 'object') {
        return true;
      }
    } catch (e) {}

    return false;
  }
  /* istanbul ignore next */
  hyperLinkCheck(event: any, value: any) {
    if (this.attributeUIElementMap?.get(value) == 'hyperlink') {
      event.stopPropagation();
      window.open(this.expandedCardDetail?.[0]?.[value], '_blank');
    }
  }
}
