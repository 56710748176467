import { NgModule } from '@angular/core';
import { TimeOnlyComponent } from './timeonly.component';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { ControlsMaterialModule } from '../material.module';
@NgModule({
  declarations: [TimeOnlyComponent],
  imports: [ControlsMaterialModule, NgxMatDatetimePickerModule],

  exports: [TimeOnlyComponent],
})
export class TimeOnlyModule {
  static entry = {
    timeonly: TimeOnlyComponent,
  };
}
