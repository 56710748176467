import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AlertService, EventsFacadeService, FieldConfig, getUiControlCustomization } from '@common-services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  private ngUnsubscribe = new Subject();
  val1: number;
  val2: number = 50;
  val3: number;
  val4: number;

  rangeValues: number[] = [20, 80];
  maxValue: any = 100;
  stepValue: any;
  appliedClass: string = '';
  appliedBackgroundClass: string = '';

  constructor(private alertService: AlertService, private eventsService: EventsFacadeService) {}

  ngOnInit(): void {
    /* istanbul ignore next */
    if (Array.isArray(this.field.value)) {
      this.field.value[0] = this.field.value[0] ? this.field.value[0] : 0;
    } else {
      this.field.value = this.field.value ? this.field.value : 0;
    }
    /* istanbul ignore next */
    if (this.field?.attribute?.name) {
      this.group.controls[this.field.attribute.name].setValue(this.field.value);
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.targetValue) {
      this.maxValue = Number(this.field?.configuration?.targetValue);
    }
    /* istanbul ignore next */
    if (this.field.type == 'stepslider' && this.field?.configuration?.sliderStepValue) {
      this.stepValue = Number(this.field?.configuration?.sliderStepValue);
    }
    if (this.field?.validations?.length > 0) {
      const validList: any = [];
      /* istanbul ignore next */
      this.field?.validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      /* istanbul ignore next */
      this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
    }
    let sliderOption = getUiControlCustomization('Slider');
    if (sliderOption) {
      let op = sliderOption.slice(-1);
      this.appliedClass = `form-range-slider form-range-slider-opt${op} form-range-slider-thumbnail`;
      if (sliderOption == 'Option 4') this.appliedBackgroundClass = 'mat-slider-box';
      else if (sliderOption == 'Option 7') this.appliedBackgroundClass = 'mat-slider-box2';
    }
  }
  updateValue(event: any) {
    /* istanbul ignore next */
    if (event.target.value > this.maxValue) {
      this.alertService.showToaster(`Max value should not be greater than ${this.maxValue}`, '', 'info');
    } else {
      this.group.controls[this.field.attribute.name].setValue(event.target.value);
    }
  }
  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    return this.group?.get(String(this.field?.attribute?.name))?.status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (
      this.group?.controls[field?.attribute.name]?.value != null ||
      this.group?.controls[field?.attribute.name]?.value != undefined
    ) {
      if (validation?.name == 'min') {
        return parseInt(this.group?.controls[field?.attribute?.name].value) < parseInt(validation?.value);
      } else if (validation?.name == 'max') {
        return parseInt(this.group.controls[field?.attribute?.name].value) > parseInt(validation?.value);
      } else {
        return this.group.controls[field?.attribute?.name]?.value?.toString()?.match(validation?.requiredPattern)
          ? false
          : true;
      }
    }
  }

  onlyNumberKey(event: any) {
    /* istanbul ignore next */
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode == 45 || (event.charCode > 46 && event.charCode <= 57);
  }

   /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  /*istanbul ignore next*/
  onChange(event: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      // this.validateDependentExpression();
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore next */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
