import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, GsiFacadeService, NodeGsiFacadeService, SolutionVersioningFacadeService, TranslatorService, changeUnitDeMapper } from '@common-services';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-nsl-review-screen',
  templateUrl: './nsl-review-screen.component.html',
  styleUrls: ['./nsl-review-screen.component.scss'],
})
export class NslReviewScreenComponent implements OnInit {
  @Input() gsiDetails: any;
  inputReviewer = '';
  inputReviewerObs = new Subject();
  pageNo: number = 0;
  pageSize: number = 12;
  selectedReviewerList: any = [];
  reviewersList: any = [];
  private ngUnsubscribe = new Subject();
  showGsiDetails: boolean = false;
  showDetails: boolean = false;
  accordianResponse: any;
  primaryId: any;
  finishType: string;
  ownerId: any;
  pendingReviewExists: boolean = false;
  reviewerList: any;
  activeUserName: any;
  temp: number = 0;
  disableApprove: boolean = true;
  primaryDsdId: any;
  primaryName: any;
  textRequestNotes: any;
  reviewData: any;
  labels: any;
  author: any = [];
  diffData: any;
  showDefaultValues: boolean = false;
  stepDisabled: boolean = false;
  reservedStep: boolean;
  openDCDPage: boolean;
  openMapSourcePage: boolean;
  openAddQueryPage: boolean;
  currentLayerIndex: any;
  isLinear: boolean = false;
  @Input() changeunit: any;
  @Input() oldOrNew: any;
  @Input() isCompare: boolean = false;

  ngSubscribe = new Subject();

  constructor(
    private versioningFacade: SolutionVersioningFacadeService,
    private alertService: AlertService,
    private router: Router,
    private dialog: MatDialog,
    private gsiFacadeService: GsiFacadeService,
    private translator: TranslatorService,
    private gsiFacade: NodeGsiFacadeService
  ) {
    this.detectfinishNsl();
    this.detectLanguageChange();
    this.pendingReviewInformation();
    this.detectReviewersList();
    this.searchRiviewers();
  }

  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectfinishNsl() {
    this.versioningFacade.finishNsl$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.reviewData = res;
      this.accordianResponse = res;
      this.author = res?.author;
      this.finishType = res?.finishType;
      this.primaryId = res?.tfId;
      const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
      this.ownerId = activeUser?.id === res?.ownerId || activeUser?.id == res?.author?.id ? true : false;
      this.loadReviewersInfo();
      this.activeUserName = activeUser?.email;
      this.primaryDsdId = res?.id;
      this.primaryName = res?.name;
    });
  }

  detectReviewersList() {
    this.versioningFacade.reviewersList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        let displayReviewerList: any = [];
        let newReviwersList = res.result.content;
        newReviwersList.forEach((reviewer: any) => {
          let index = this.reviewersList.findIndex((currentReviewer: any) => currentReviewer.name == reviewer.name);
          /*istanbul ignore else*/
          if (index == -1) {
            displayReviewerList.push(reviewer);
          }
        });
        /*istanbul ignore next*/
        this.reviewersList = displayReviewerList?.concat(this.reviewersList);
      }
    });
  }

  pendingReviewInformation() {
    this.versioningFacade.pendingReviewExists$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.reviewerList = res;
        this.pendingReviewExists = res;
        this.isDesigner(this.reviewerList);
      } else {
        this.ownerId = true;
      }
    });
  }

  isDesigner(reviewerList: any) {
    let flag: boolean;
    let isAReviewer: boolean;
    let reviewer = reviewerList?.reviewers?.findIndex((rev: any) => rev.name === this.activeUserName);
    if (reviewer == -1) {
      isAReviewer = false;
    } else {
      isAReviewer = true;
    }
    if (isAReviewer) {
      flag = false;
    } else {
      /* istanbul ignore else */
      if (this.temp != 1) {
        this.alertService.showToaster('BET is under review', 'Review for current BET is under process', 'error');
        this.temp = 1;
      }
      flag = true;
    }
    this.disableApprove = flag;
  }

  isDesignerClicked() {
    /* istanbul ignore else */
    if (this.disableApprove) {
      this.router.navigateByUrl(`/mylibrary`);
    }
  }

  onClickTopActionBtns(label: any) {
    switch (label) {
      // case 'compare':
      //   // this.showDetails = false;
      //   this.showCompare = !this.showCompare;
      //   /* istanbul ignore next */
      //   if (this.showCompare) {
      //     this.showDetails = false;
      //   }
      //   this.versioningFacade.getScreenType(this.finishType);
      //   break;
      case 'send':
        if (this.selectedReviewerList.length > 0) {
          let targetType;
          if (this.finishType === 'gsi') {
            targetType = 'Gsi';
          } else {
            targetType = this.finishType === 'cu' ? 'Cu' : 'Entity';
          }
          const actionableContent = {
            target_type: targetType,
            target_id: this.primaryId,
            dsdId: this.primaryDsdId,
            target_name: this.primaryName,
            context_cu: '',
            context_gsi: '',
            approvals: true,
          };
          const body = {
            title: `Review ${this.finishType}`,
            targetUserId: this.selectedReviewerList,
            content: this.textRequestNotes,
            actionableContent: actionableContent,
            notificationChannels: ['PUSH'],
          };
          /* istanbul ignore next */
          if (!body.content) {
            /* istanbul ignore next */
            let betType = this.finishType.toUpperCase();
            body.content = `${this.reviewData?.author?.name} wants review of ${betType} - ${this.reviewData.displayName}`;
          }
          this.versioningFacade.sendNotifications(body, true);
          this.dialog.closeAll();
        } else {
          this.alertService.showToaster('Please select reviewer', '', 'error');
        }
        break;
      default:
        break;
    }
  }
  loadEntityDiff() {
    /*istanbul ignore next */
    this.versioningFacade.getVersionsDiff$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.diffData = JSON.parse(res);
      }
    });
  }

  changeStatus() {
    /*istanbul ignore else */
    if (this.reviewData && this.finishType == 'gsi') {
      this.gsiFacade.cancelReview('gsi', this.reviewData);
    } else if (this.reviewData && this.finishType == 'cu') {
      this.reviewData = changeUnitDeMapper(this.reviewData);
      this.gsiFacade.cancelReview('change-unit', this.reviewData);
    }
  }
  showValues() {
    this.showDefaultValues = !this.showDefaultValues;
  }
  onChangeUnitStepChange(event: any) {
    this.reservedStep = false;
    this.openDCDPage = false;
    /*istanbul ignore next*/
    const currentTabLabel = event.selectedStep.ariaLabelledby?.toLocaleLowerCase();
    if (currentTabLabel === 'addquerystep') {
      this.openMapSourcePage = false;
    }
    if (currentTabLabel === 'mapsourcestep') {
      this.openAddQueryPage = false;
    }

    switch (currentTabLabel) {
      case 'physicalstep':
        this.tabChanged({ index: 0 });
        break;
      case 'informationstep':
        this.tabChanged({ index: 1 });
        break;
      case 'triggercesstep':
        this.tabChanged({ index: 2 });
        break;
      case 'reservedactions':
        this.tabChanged({ index: 3 });
        break;
      case 'reservedactionsstep':
        this.reservedStep = true;
        break;
    }
  }
  tabChanged(tabChangeEvent: any) {
    this.currentLayerIndex = tabChangeEvent.index;
  }

  onScrollReviewers() {
    /*istanbul ignore else*/
    if (this.primaryId && this.ownerId) {
      this.pageNo += 1;
      this.versioningFacade.loadReviewers(
        this.primaryId,
        this.finishType,
        this.pageNo,
        this.pageSize,
        this.inputReviewer
      );
    }
  }

  searchRiviewers() {
    this.inputReviewerObs
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((event: any) => {
        /*istanbul ignore else*/
        if (this.primaryId && this.ownerId) {
          let selectedReviwerList = JSON.parse(JSON.stringify(this.selectedReviewerList));
          let dummy: any = [];
          this.pageNo = 0;
          /*istanbul ignore next*/
          selectedReviwerList?.forEach((selectedReviewer: any) => {
            let foundReviewer = this.reviewersList?.find((reviewer: any) => reviewer?.email === selectedReviewer);
            /*istanbul ignore else*/
            if (foundReviewer) {
              dummy.push(foundReviewer);
            }
          });
          this.reviewersList = dummy;
          this.versioningFacade.loadReviewers(
            this.primaryId,
            this.finishType,
            this.pageNo,
            this.pageSize,
            this.inputReviewer
          );
        }
      });
  }

  loadReviewersInfo() {
    if (this.primaryId && this.ownerId) {
      this.versioningFacade.loadReviewers(this.primaryId, this.finishType, this.pageNo, this.pageSize);
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.isCompare = false;
  }
}

