import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-progress-bar-configuration',
  templateUrl: './progress-bar-configuration.component.html',
  styleUrls: ['./progress-bar-configuration.component.scss'],
})
export class ProgressBarConfigurationComponent implements OnChanges, OnDestroy {
  @Input() control: any;
  @Input() configuration: any;
  @Input() orientation: any;
  configType: string;
  unsubscribe$ = new Subject();
  attributeList: any[] = [];
  hideDefaultValue: boolean = false;
  constructor() {}

  ngOnChanges(simple: SimpleChanges) {
    this.attributeList = [];
    this.configType = this.control.value.value.configurationType;
    this.setConfiguration();
    for (let [key, value] of Object.entries(this.control.value.value)) {
      /* istanbul ignore next*/
      if (value?.constructor?.name === 'Array') {
        /* istanbul ignore next */
        this.attributeList.push({
          displayValue: value[0].attr_displayName,
          value: this.control.value.controls[key].value,
        });
      } else if (value?.constructor?.name === 'Object') {
        this.attributeList.push({
          key: key,
          displayValue: value['displayName'],
          value: this.control.value.controls[key],
        });
      }
    }
  }

  setConfiguration() {
    this.configuration = this.configuration
      ? this.configuration
      : JSON.parse(this.control.value.value.configuration)?.colouring;
    this.orientation = this.orientation
      ? this.orientation
      : JSON.parse(this.control.value.value.configuration)?.orientation;
    document.documentElement.style.setProperty('--active-color', this.configuration.active);
    document.documentElement.style.setProperty('--inactive-color', this.configuration.Inactive);
    this.hideDefaultValue = JSON.parse(this.control?.value?.value?.configuration)?.hideDefaultValue;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
