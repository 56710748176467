import { Component} from '@angular/core';
import { AlertService} from '@common-services';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';
@Component({
  selector: 'app-insurance-templates',
  templateUrl: './insurance-templates.component.html',
  styleUrls: ['./insurance-templates.component.scss'],
})
export class InsuranceTemplatesComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService
  ) {
    super(    
      alertService
    );
  }
}
