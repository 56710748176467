import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationEndpointService } from './notification-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacadeService implements OnDestroy {
  ngUnsubscribe: any = new Subject();
  private showNotificationLoader = new BehaviorSubject(false);
  showNotificationLoader$ = this.showNotificationLoader.asObservable();
  lastPagingData: any;
  constructor(private notificationEndPoint: NotificationEndpointService) {}

  public notificationsData = new Subject();
  notificationsData$ = this.notificationsData.asObservable();

  public unreadNotificationCount = new Subject();
  unreadNotificationCount$ = this.unreadNotificationCount.asObservable();

  public notificationStatusChange = new Subject();
  notificationStatusChange$ = this.notificationStatusChange.asObservable();

  pushNotificationTokenValidator = new BehaviorSubject(false);
  pushNotificationTokenValidator$ = this.pushNotificationTokenValidator.asObservable();

  private resetNotificationComponent = new Subject();
  resetNotificationComponent$ = this.resetNotificationComponent.asObservable();

  // requestFCMPermission() {
  //   this.notificationEndPoint.requestPermission();
  // }

  // receiveFCMMessages() {
  //   this.notificationEndPoint.receiveMessage();
  // }

  // saveFCMToken(token : any){
  //   this.notificationEndPoint.saveFCMToken(token);
  // }

  // deRegisterToken() {
  //   this.notificationEndPoint.deRegisterToken();
  //   this.deleteFCMToken();
  // }

  // deleteFCMToken() {
  //   this.notificationEndPoint.deleteFCMToken();
  // }

  getAllNotifications(pagingData: any) {
    this.lastPagingData = pagingData;
    this.notificationEndPoint
      .getAllNotifications(pagingData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.notificationsData.next(res);
        this.changeLoaderStatus(false);
      });
  }

  changeOfNotificationStatus(notificationData: any) {
    this.notificationEndPoint
      .changeOfNotificationStatus(notificationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.notificationStatusChange.next(res);
        this.getUnreadNotificationCount();
      });
  }
  changeLoaderStatus(value: boolean) {
    this.showNotificationLoader.next(value);
  }

  markAllNotificationsAsRead() {
    this.notificationEndPoint.markAllNotificationsAsRead().subscribe((res: any) => {
      this.getAllNotifications(this.lastPagingData);
      this.getUnreadNotificationCount();
    });
  }

  searchNotification(page: any, size: any, keyword: any) {
    return this.notificationEndPoint.searchNotification(page, size, keyword);
  }

  getUnreadNotificationCount() {
    this.notificationEndPoint
      .getUnreadNotificationCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.unreadNotificationCount.next(res);
      });
  }

  updateTokenValidateForPushNotification(data: any) {
    this.pushNotificationTokenValidator.next(data);
  }

  clearNotificationComponent(flag: boolean) {
    this.resetNotificationComponent.next(flag);
  }
  getCalendarNotifications(pagingData: any) {
    return this.notificationEndPoint.getCalendarNotifications(pagingData);
  }
  updateAcceptanceStatus(userEmail: string, recordId: any, status: string) {
    return this.notificationEndPoint.updateAcceptanceStatus(userEmail, recordId, status);
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
