import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DecimalComponent } from './decimal.component';
import { CurrencyMaskDirective } from './currency-mask.directive';

@NgModule({
  declarations: [DecimalComponent, CurrencyMaskDirective],
  imports: [ControlsMaterialModule],

  exports: [DecimalComponent],
})
export class DecimalModule {
  static entry = {
    currency: DecimalComponent,
    decimal: DecimalComponent,
    decimalnumber: DecimalComponent,
    metric: DecimalComponent,
  };
}
