import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { GsiEndpointService, KanbanConfiguration } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TemplateDialog } from '../entity-grid-card/template-dialog/template-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kanban-view-template',
  templateUrl: './kanban-view-template.component.html',
  styleUrls: ['./kanban-view-template.component.scss'],
})
export class KanbanViewTemplateComponent implements OnChanges {
  @Input() cardsData: any;
  @Input() kanbanConfiguration: KanbanConfiguration;

  @Input() cardDisplay: any;
  @Input() gsiMapping: any;
  @Input() btnColors: any;
  @Input() cardDetails: any;
  @Input() cardColor: any;
  @Input() attributeColors: any;
  @Input() _otherStyle: any;
  @Input() conditionArray: any;
  @Input() cardShadow: any;
  @Input() cardBorderSize: any;
  @Input() cardBorderRadius: any;
  @Input() cardBorderColor: any;
  @Input() attrHoveredColors: any;
  @Input() cardHoveredColor: any;
  @Input() quantity: any;
  @Input() entityDataRaw: any;
  @Output() recordUpdateEmitter: any = new EventEmitter();
  KanbanData: any;

  ngUnsubscribe = new Subject();

  constructor(
    private gsiEndpointService: GsiEndpointService,
    private router: Router,
    private dialog: MatDialog,
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    this.displayKanbanRecords();
    this.updateCardsData();
    this.updateKanbanData();
  }

  openUnrelatedGsi(gsi: any) {
    if (gsi?.entityType === 'GSI') {
      const txnData = btoa(
        JSON.stringify({
          gsiId: gsi?.id,
          gsiMasterId: gsi.masterId,
        })
      );
      /* istanbul ignore next */
      localStorage.setItem('gsi-masterId', gsi?.masterId);
      if (gsi.popUp) {
        this.openDialog(txnData);
      } else {
        this.router.navigate(['transaction/view/' + txnData]);
      }
    }
  }

  openDialog(solutionData: any): void {
    this.dialog.open(TemplateDialog, {
      panelClass: 'mccGsiModal',
      data: { solutionData },
    });
  }
  updateCardsData() {
    this.cardsData.forEach((card: any) => {
      const status = card.recordData.find(
        (attr: any) => attr.attributeName == this.kanbanConfiguration.statusAttribute[0].name
      );
      card['kanbanStatus'] = status.value;
    });
  }

  displayKanbanRecords() {
    this.KanbanData = this.kanbanConfiguration.kanbanStatus;
    for(let i =0;i<this.KanbanData?.length;i++) {
      if(this.KanbanData?.[i]?.isHidden) {
        this.KanbanData = this.KanbanData.toSpliced(i,1)
        i--;
      }
    }
  }

  updateKanbanData() {
    this.KanbanData.forEach((data: any) => {
      data['status'] = [];
      this.cardsData.forEach((card: any) => {
        if (data.DATA.actualValue == card['kanbanStatus']) {
          data.status.push(card);
        }
      });
    });
  }

  dragStart(index: number): void {
    this.addGrabbingClass(index);
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    this.removeGrabbingClass(event.currentIndex);
  }

  addGrabbingClass(index: number): void {
    const draggedItem = document.getElementsByClassName('cdk-drag')[index] as HTMLElement;
    if (draggedItem) {
      draggedItem.classList.add('cursor-grabbing');
    }
  }

  removeGrabbingClass(index: number): void {
    const draggedItem = document.getElementsByClassName('cdk-drag')[index] as HTMLElement;
    if (draggedItem) {
      draggedItem.classList.remove('cursor-grabbing');
    }
  }

  drop(event: CdkDragDrop<string[]>, status: any) {
    let payload: any = {
      generalEntityId: this.cardDetails.primaryEntity.id,
      generalEntityMasterId: this.cardDetails.primaryEntity.masterId,
      id: event.item.data.recordId,
      // versionId: this.cardDetails.primaryEntity.version,
      ownerId: '',
      createdAt: '',
      updatedAt: '',
      updatedBy: '',
      txnNslAttribute: [],
    };
    const updateAttributeData = event.item.data.recordData;
    const updatedStatus = status.DATA.actualValue;
    let recordData = this.entityDataRaw.result.content[0].data.find((record: any)=>{
      if(record.id == event.item.data.recordId){
        return record;
      };
    });
    recordData.transEntityRecords[0].txnNslAttribute.forEach((attribute: any) => {
      if(attribute.name == this.kanbanConfiguration.statusAttribute[0].name){
        payload.txnNslAttribute.push({
          nslAttributeID: attribute.nslAttributeID,
          name: attribute.name,
          values: [updatedStatus]
        })
      } else {
        payload.txnNslAttribute.push({
          nslAttributeID: attribute.nslAttributeID,
          name: attribute.name,
          values: attribute.values,
        })
      }
    });
    this.gsiEndpointService.updateEntityData(payload).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      (res: any) => {
        this.recordUpdateEmitter.emit()
      },
      (error: any) => {
        this.recordUpdateEmitter.emit()
      }
    );
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  emitRecordUpdate(){
    this.recordUpdateEmitter.emit()
  }
}
