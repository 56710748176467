import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService, EventsFacadeService, AlertService } from '@common-services';

@Component({
  selector: 'app-basic-form',
  templateUrl: './basic-form.component.html',
  styles: [
    `
      h3 {
        font-size: 19px;
        color: var(--h3-color);
      }
      .hide {
        display: none;
      }
    `,
  ],
})
export class BasicFormComponent implements OnDestroy {
  @Input() control: any;
  @Input() submitted: any;
  @Input() group: any;
  @Input() isReadOnly: any;
  // @Output() submitEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangedEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() disableSubmitEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() waypointsData: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickableOnSelect: EventEmitter<any> = new EventEmitter<any>();
  unSubscribe = new Subject();
  form: FormGroup;
  /* get value() {
    return this.form.value;
  } */
  labels: any;

  constructor(
    private fb: FormBuilder,
    private translator: TranslatorService,
    private eventsService: EventsFacadeService,
    private alertService: AlertService
  ) {
    this.detectLanguageChange();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  bindValidations(validations: any) {
    /* istanbul ignore else */
    if (validations.length > 0) {
      const validList: any = [];
      validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  /* ngOnInit(): void {} */

  addMultipleControl(controlName: any, control: any, cont: any, formlevel: any, i: any, event?: any) {
    if (event !== 'multiFileUpload') {
      const validations = this.eventsService.getValidations(control[0].value.constraints, formlevel.value.inputType);
      const validConstarints = validations ? validations : [];
      control.push(
        this.fb.group({
          [controlName]: ['', this.bindValidations(validConstarints)],
          // [formlevel.value.id]: ['', this.bindValidations(validations || [])],
          attributeType: formlevel.attributeType,
          attr_id: formlevel.value.attr_id,
          constraints: [formlevel.value.constraints],
          attrtype: formlevel.value.attrtype,
          label: formlevel.value.label,
          labelType: formlevel.value.labelType,
          inputType: formlevel.value.inputType,
          validations: [validations],
          isMulti: false,
          recordId: formlevel.value.recordId ? formlevel.value.recordId : '',
          value: formlevel.value.value,
          attr_options: [formlevel.value.attr_options],
          isInPotentiality: formlevel.value.isInPotentiality,
          isDisabled: formlevel.value.isDisabled,
          triggerConditionalPotentiality: formlevel.value.triggerConditionalPotentiality,
          // optional change drivers related flags starts //
          alternativeChangeDriver: formlevel.value.alternativeChangeDriver
            ? formlevel.value.alternativeChangeDriver
            : '',
          isOptional: formlevel.value.isOptional,
          isNegative: formlevel.value.isNegative,
          // optional change drivers related flags ends //
        })
      );

      const obj = Object.assign({}, cont.value.value[0]);
      obj[obj.label] = '';
      obj.value = '';
      cont.value.value.push(obj);
    }
    this.formValueChange.emit(this.group);
  }

  // Removing the attribute from the list
  removeMultipleControl(controlName: any, control: any, cont: any, formlevel: any, i: any) {
    /* istanbul ignore else */
    if (control.length == 1) {
      this.alertService.showToaster('Cannot remove if one attribute is present', '', 'info');
    } else {
      cont.value.removeAt(i);
      this.formValueChange.emit(this.group);
    }
  }

  dateChnagedEvent(event: any) {
    this.dateChangedEmit.emit(event);
  }

  isDisabledButton(event: any) {
    this.disableSubmitEmit.emit(event);
  }
  getWaypointsData(event: any) {
    this.waypointsData.emit(event);
  }

  /*this is for to emit onselect event from clikable to nested form   @Author: Aravind*/
  getClickableOnSelectData(event: any) {
    this.clickableOnSelect.emit(event);
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}

