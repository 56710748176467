import { dldEntityMapper } from './dld-entity.mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function dldEntityListMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      CU: 'CU',
      result: 'result',
    },
    operate: [
      {
        run: function (val: any[]) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return dldEntityMapper(x);
          });
        },
        on: 'result',
      },
    ],
  };

  //  var attributeMap = {
  //    item : {
  //     'isMultiValue' : 'isMultiValue',
  //     'name': 'name',
  //     'nslAttributes' : 'nslAttributes',
  //     'dldId' :  'dldId',
  //     'confidence' : 'confidence',
  //     'displayName' : 'displayName'
  //    },
  //   operate: [
  //       {
  //           run: function (val: any[]) {
  //             return val?.map((x: any) => {
  //               return {
  //                 'name' : x.name,
  //                 'displayName' : x.displayName,
  //                 'constraints' : x.constraints,
  //                 'attributeType' : {
  //                     'type' : x.attributeType?.type,
  //                     'uiElement' : {
  //                         'name' :  x.attributeType?.uiElement?.name,
  //                         "dataType": x.attributeType?.uiElement?.dataType,
  //                         "uiElement": x.attributeType?.uiElement?.uiElement,
  //                         "isMulti": x.attributeType?.uiElement?.isMulti,
  //                         "properties": x.attributeType?.uiElement?.properties
  //                     },
  //                     'properties' : x.attributeType?.properties
  //                 },

  //               };
  //             });
  //           },
  //           on: 'nslAttributes',
  //      },
  //   ]
  // };

  const result = transform(data, map);

  return result;
}
