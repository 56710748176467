import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnimationOptions } from 'ngx-lottie';
import { NgForm } from '@angular/forms';
import JSEncrypt from 'jsencrypt';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../cdui-base/authentication.service';
import { CredentialsService } from '../cdui-base/credentials.service';
import { LoaderService } from '../loader/loader.service';
import { ManageConfigurationFacadeService } from '../manage-configuration/manage-configuration-facade.service';
import { OnboardingFacadeService } from '../onboarding/onboarding-facade.service';
import { RolesFacadeService } from '../roles/roles-facade.service';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';
import { UsersFacadeService } from '../users/users-facade.service';
import { ManageEnvDataFacadeService } from '../manage-environments/manager-environment-facade.service';
import { UsersEndpointService } from '@common-services';
import { ManageConfigurationEndpointService } from '../manage-configuration/manage-configuration-endpoint.service';
@Component({
  selector: 'app-self-onboarding-signup',
  templateUrl: './self-onboarding-signup.component.html',
  styleUrls: ['./self-onboarding-signup.component.scss'],
})
export class SelfOnboardingSignupComponent implements OnInit, OnDestroy {
  public ngUnsubscribe = new Subject();
  @ViewChild('adapterForm') signupForm: NgForm;
  tenantName: any;
  confirmPassword: string = '';
  termsAgreed: boolean = false;
  signupPayload: any = {
    email: '',
    firstName: '',
    isEnabled: true,
    lastName: '',
    mobileNumber: '',
    name: '',
    password: '',
    realm: '',
    roles: new Array(),
    customID: '',
    environments: new Array(),
    environmentName: '',
    countryCode: '',
  };
  verifyOtpPayload = {
    roles: new Array(),
  };
  signupMobile: any;
  signupOtp: any;
  password_info: any;
  labels: any;
  defaultRole: any = null;
  activateOtp: boolean = false;
  timeLeft: number = 60;
  interval: any;
  rolesList: any;
  selectedRole: any;
  emailValidator = true;
  mobileNumberValidatorEmail = true;
  mobileNumberValidatorMobile = true;
  otpTimeOutDuration: any;
  passwordCheck = true;
  lottieOptions: AnimationOptions = {
    path: '../../../assets/json/login_earth.json',
  };
  onboardingTenant: any[] = [
    'selfonboard',
    'systemintegrator',
    'b2b',
    'sdp',
    'bdp',
    'freelancer',
  ];
  onboarding: boolean = false;
  customLabel: any;
  showRoles: boolean = false;
  showPublicRoles: any;
  isRoleMandatory: any;
  errorToaster: boolean = false;
  signupOptions: string;
  customIdGeneration: string;
  clientId: string;
  envType: string;
  isEfundzz: boolean;
  isEzit: boolean;
  istelcel: boolean;
  environmentName: string;
  countryCodes: any;
  selectedCountryCode = '+91';
  faEnabled: boolean = false;
  isMedaxiom: boolean = false;
  mobileNumberMandatoryWhileEmailSignUp: boolean = false;
  showPassword: boolean = false;
  referralCode: string;
  termsAndConditions: any;
  displayTermsField: boolean = true;
  mobileNumberData: any[] = [];
  attributeList1: any[] = [];
  userCountryCode: any = [];
  attributeArray: any[] = [];
  defaultCountryCodeEditable: boolean;
  selectedCountryName: string = 'US';
  isValid: boolean;
  isSalud: boolean = false;

  get _countryName() {
    return this.selectedCountryName;
  }

  set _countryName(value: any) {
    this.selectedCountryName = value;
    if (this.countryCodes?.length > 0) {
      let index = this.countryCodes?.findIndex(
        (attr: any) => attr[1].countryName == value
      );
      if (index != -1) {
        this.selectedCountryCode = this.countryCodes[index]?.[1]?.countryCode;
      }
    }
  }

  constructor(
    public authenticationService: AuthenticationService,
    public alertService: AlertService,
    public router: Router,
    public loader: LoaderService,
    public rolesFacade: RolesFacadeService,
    public userFacade: UsersFacadeService,
    public onboardingFacadeService: OnboardingFacadeService,
    public translator: TranslatorService,
    public credentialsService: CredentialsService,
    public manageConfigurationFacade: ManageConfigurationFacadeService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public manageConfigurationEndpoint: ManageConfigurationEndpointService
  ) {
    this.tenantName = localStorage.getItem('TenantName');
    this.clientId = localStorage.getItem('envClientId');
    this.fetchSignupConfigurations();
    this.envType = localStorage.getItem('environmentType');
    this.environmentName = localStorage.getItem('environmentName');
    this.getRoles();
    this.detectActiveUserInfo();
    this.detectGsiMappingsByRoleName();
    /* istanbul ignore next */
    if (this.onboardingTenant?.includes(this.tenantName)) {
      this.onboarding = true;
    }
    this.detectLanguageChange();
    this.isEfundzz =
      this.router?.url?.includes('efundzz') ||
      localStorage.getItem('TenantName')?.includes('efundzz');
    this.istelcel =
      this.router?.url?.includes('telcel') ||
      localStorage.getItem('TenantName')?.includes('telcel');

    this.isEzit =
      this.router?.url?.includes('ezit') ||
      localStorage.getItem('TenantName')?.includes('ezit');
    this.isMedaxiom =
      this.router?.url?.includes('healthcaremx') ||
      localStorage.getItem('TenantName')?.includes('healthcaremx');
    this.isSalud = this.tenantName?.includes('salud');

    this.getCountryCodes();
    this.passwordPolicy();
    this.faEnabled = localStorage.getItem('2fa') == 'true' ? true : false;
    const envConfig = JSON.parse(localStorage.getItem('envConfigInfo'));
    if (envConfig?.termsAndConditions || envConfig?.defaultTermsAndConditions) {
      this.termsAndConditions = envConfig?.defaultTermsAndConditions
        ? envConfig.defaultTermsAndConditions
        : envConfig.termsAndConditions;
    } else {
      this.termsAgreed = true;
      this.displayTermsField = false;
    }
    this.getCountryCodeDetails();
    this.checkSignupProperties();
    this.userCustomAttributes();
  }
  ngOnInit(): void {
    //this.onCountrySelect();
    this.selectedCountryCode = localStorage.getItem('countryCode');
    if (this.tenantName === 'telceldev') {
      this.selectedCountryCode = '+52';
    }
  }
  userCustomAttributes() {
    this.manageConfigurationFacade.fetchSignupProperties(this.clientId);
    this.manageConfigurationFacade.getSignupProperties$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res?.result?.userCustomAttributes)
          this.attributeList1 = res?.result?.userCustomAttributes?.split(',');
      });
  }

  checkSignupProperties() {
    /* istanbul ignore next */
    if (!localStorage?.getItem('signupProperties')) {
      this.manageConfigurationFacade.fetchSignupProperties(this.clientId);
      this.loader.show();
      this.manageConfigurationFacade.getSignupProperties$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res?.status === 'OK') {
            localStorage.setItem('signupProperties', JSON.stringify(res));
            this.fetchSignupConfigurations();
            this.checkReferralCode();
            if (!res?.result?.canUsersSelfSignUp) {
              this.attributeList1 = res?.result?.userCustomAttributes.split(
                ','
              );
              this.alertService.showToaster(
                'self signup is not allowed in this tenant, please contact admin',
                '',
                'info'
              );
              this.router.navigate(['/login']);
            }
          }
        });
    } else {
      this.fetchSignupConfigurations();
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }
  // get active user response
  detectActiveUserInfo() {
    this.userFacade.activeUserInfo$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          if (this.selectedRole) {
            this.onboardingFacadeService.getGsiMappingsByRoleName(
              this.selectedRole?.name,
              'signup'
            );
          } else {
            if (this.onboardingTenant.includes(this.tenantName)) {
              this.router.navigateByUrl('/admin');
            } else {
              this.router.navigateByUrl('/mylibrary-dashboard');
            }
          }
        }
      });
  }

  detectGsiMappingsByRoleName() {
    this.onboardingFacadeService.gsiListByRoleName$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result) {
          this.userFacade.disableSideMenu();
          const solutionData = btoa(
            JSON.stringify({
              bookId: res?.result?.bookId,
              gsiId: res?.result?.gsiId,
            })
          );
          this.router.navigate(['transaction/view/' + solutionData]);
        } else {
          this.router.navigateByUrl('/mylibrary-dashboard');
        }
      });
  }

  getRoles() {
    this.rolesFacade
      .getPublicRoles(this.clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.rolesList = res;
          /*istanbul ignore else */
          if (this.rolesList?.length != 0) {
            this.showRoles = true;
          }
        }
      });
  }

  signup() {
    if (this.selectedCountryCode && this.selectedCountryCode.length > 0) {
      this.signupPayload['userPreferences'] = {
        countryCode: this.selectedCountryCode,
      };
    }

    this.errorToaster = true;
    /*istanbul ignore else*/
    if (this.signupPayload.customID == '') {
      this.signupPayload.customID = null;
    }
    this.signupPayload.firstName = this.signupPayload.firstName.trim();
    this.signupPayload.lastName = this.signupPayload.lastName.trim();
    this.signupPayload.name =
      this.signupPayload.firstName + '' + this.signupPayload.lastName;
    this.signupPayload.realm = this.tenantName;
    this.signupPayload.environments = [this.envType];
    this.signupPayload.environmentName = this.environmentName;
    if (this.defaultRole != null) {
      this.signupPayload.roles = this.rolesList?.filter((role: any) => {
        /*istanbul ignore else */
        if (role.name == this.defaultRole) {
          this.selectedRole = role;
        }
        return role.name == this.defaultRole;
      });
    } else {
      this.signupPayload.roles = this.selectedRole ? [this.selectedRole] : [];
    }
    /*istanbul ignore else */
    if (localStorage.getItem('ActiveUserInfo')) {
      let userName = JSON.parse(localStorage.getItem('ActiveUserInfo'))
        ?.guestUserName;
      if (
        localStorage.getItem('isGuestUser') == 'true' &&
        userName &&
        userName.includes('nslguest')
      ) {
        this.signupPayload.guestUserId = JSON.parse(
          localStorage.getItem('ActiveUserInfo')
        ).id;
        this.signupPayload.guestUsername = JSON.parse(
          localStorage.getItem('ActiveUserInfo')
        ).guestUserName;
      }
    }

    this.signupPayload.mobileNumber = this.signupPayload.mobileNumber
      ? this.signupPayload.mobileNumber
      : null;
    if (localStorage.getItem('isGuestUser') == 'true') {
      this.signupPayload.guestUserId = JSON.parse(
        localStorage.getItem('ActiveUserInfo')
      ).id;
      this.signupPayload.guestUsername = JSON.parse(
        localStorage.getItem('ActiveUserInfo')
      ).guestUserName;
    }
    this.loader.show();
    this.authenticationService
      .selfSignup(this.signupPayload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res?.message == 'User created successfully') {
            this.alertService.showToaster(
              "You've successfully signed-up",
              '',
              'success'
            );
            /* istanbul ignore next */
            const userInfo = {
              username: this.signupPayload.email,
              password: this.signupPayload.password,
              client_id: this.clientId,
              tenant: this.signupPayload.realm,
              remember: true,
            };
            this.validateLogin(userInfo);
          } else {
            this.alertService.showToaster(
              `${res.message}`,
              'Please try again',
              'error'
            );
            this.router.navigate(['/login']);
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
          this.router.navigate(['/login']);
        }
      );
  }
  ValidateEmail() {
    let email = this.signupPayload.email;
    let pattern = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9]+\.){1,2}[a-zA-Z]{2,7}$/;
    /*istanbul ignore else */
    if (pattern.test(email)) {
      this.emailValidator = true;
    } else {
      this.emailValidator = false;
    }
  }
  ValidateMobileNumberEmail(event?: any) {
    let number = event.key;
    // this.signupPayload.mobileNumber = number;

    if (
      isNaN(number * 1) ||
      this.signupPayload.mobileNumber.length >=
        this.mobileNumberData?.[1]?.mobileNumberLength
    ) {
      event.preventDefault();
    }

    /**
     * @author : Shakshi Agrawal
     * @Description : checks the length of the input number is valid or not
     * @Date 12/6/23
     */
    if (
      this.signupPayload.mobileNumber.length + 1 !=
      this.mobileNumberData?.[1]?.mobileNumberLength
    ) {
      this.mobileNumberValidatorEmail = false;
    } else {
      this.mobileNumberValidatorEmail = true;
      let pattern = /^[0-9]+$/;
      if (
        (this.selectedCountryCode == '+91' ||
          this.selectedCountryCode == '+52') &&
        number
      ) {
        if (pattern.test(this.signupPayload.mobileNumber)) {
          this.mobileNumberValidatorEmail = true;
        } else {
          this.mobileNumberValidatorEmail = false;
        }
      }
    }
  }
  ValidateMobileNumberMobile(e?: any) {
    this.activateOtp = false;
    if (this.countryCodes?.length > 0 && e) {
      let index = this.countryCodes?.findIndex(
        (attr: any) => attr[1].countryName == e?.value
      );
      if (index != -1) {
        this.selectedCountryCode = this.countryCodes[index]?.[1]?.countryCode;
      }
    }
    clearInterval(this.interval);
    let number = this.signupMobile;
    this.mobileNumberValidatorMobile = true;
    this.onCountrySelect();
    let pattern = /^[0-9]{10}$/;
    if (
      this.selectedCountryCode == '+91' ||
      this.selectedCountryCode == '+52'
    ) {
      if (pattern.test(number)) {
        this.mobileNumberValidatorMobile = true;
      } else {
        this.mobileNumberValidatorMobile = false;
      }
    }
  }
  ValidateConfirmPassword() {
    let password = this.signupPayload.password;
    let confirmPassword = this.confirmPassword;
    if (password === confirmPassword) {
      this.passwordCheck = true;
    } else {
      this.passwordCheck = false;
    }
  }
  passwordPolicy() {
    this.userFacade.getPasswordPolicyByAdmin(this.tenantName);
    this.userFacade.tenantPasswordPolicy$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.password_info = res.result;
      });
  }
  signin() {
    this.router.navigate(['/login']);
  }

  sendOtp() {
    this.signupMobile = this.signupPayload?.mobileNumber
      ? this.signupPayload?.mobileNumber
      : this.signupMobile;
    if (!this.signupMobile || this.signupMobile?.length == 0) {
      this.alertService.showToaster('Please provide mobile number', '', 'info');
      return;
    }
    this.loader.show();
    let country = '';
    if (this.selectedCountryCode.length > 0) {
      country = this.selectedCountryCode.replace('+', '%2B');
    }
    let payload = {
      countryCode: country,
      mobileNumber: this.signupMobile,
      tenantName: this.clientId,
    };
    this.authenticationService
      .otpSignup(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res?.status == 200) {
            this.alertService.showToaster(`${res.message}`, '', 'success');
            this.activateOtp = true;
            this.timeLeft = this.otpTimeOutDuration
              ? Number(this.otpTimeOutDuration) * 60
              : 60;
            this.interval = setInterval(() => {
              if (this.timeLeft > 0) {
                this.timeLeft--;
              } else {
                clearInterval(this.interval);
              }
            }, 1000);
            localStorage.setItem('ActiveUserFlag', 'user');
            this.userFacade.updateActiveUserFlag('user');
          } else {
            this.alertService.showToaster(
              `${res.message}`,
              'Please try again',
              'error'
            );
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
        }
      );
  }

  verifyOtp() {
    let guestUserId: any = '';
    if (this.defaultRole != null) {
      this.verifyOtpPayload.roles = this.rolesList?.filter((role: any) => {
        if (this.defaultRole == role.name) {
          this.selectedRole = role;
        }
        return role.name == this.defaultRole;
      });
    } else {
      this.verifyOtpPayload.roles = this.selectedRole
        ? [this.selectedRole]
        : [];
    }

    // this.selectedRole = this.selectedRole ? [this.selectedRole] : [];
    // this.verifyOtpPayload.roles = this.selectedRole;

    let country = '';
    if (this.selectedCountryCode.length > 0) {
      country = this.selectedCountryCode.replace('+', '%2B');
    }

    let userName = JSON.parse(localStorage.getItem('ActiveUserInfo'))
      ?.guestUserName;
    if (
      localStorage.getItem('isGuestUser') == 'true' &&
      userName &&
      userName.includes('nslguest')
    ) {
      guestUserId = JSON.parse(localStorage.getItem('ActiveUserInfo')).id;
    }
    this.loader.show();
    let payload = {
      mobileNumber: this.signupMobile,
      tenantName: this.clientId,
      roles: this.verifyOtpPayload.roles,
      otpNumber: this.signupOtp,
      countryCode: country,
      guestUserId: guestUserId,
    };
    this.authenticationService
      .verifySignupOtp(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res.status == 200) {
            this.alertService.showToaster(
              `${res.message}`,
              'Congratulations!',
              'success'
            );
            const data = {
              username: this.signupMobile,
              token: res.result?.access_token,
              client_id: this.clientId,
              refresh_token: res.result?.refresh_token,
              expires_in: res.result?.expires_in,
              last_loginTime: new Date(),
              tenant: this.tenantName,
            };
            this.credentialsService.setCredentials(data, true);
            this.afterLogin(res);
          } else {
            this.alertService.showToaster(
              `${res.message}`,
              'Please try again',
              'error'
            );
            this.signupMobile = '';
            this.signupOtp = '';
            this.router.navigate(['/login']);
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          this.signupMobile = '';
          this.signupOtp = '';
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
          this.router.navigate(['/login']);
        }
      );
  }

  acceptInteger(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar: any;
    /* istanbul ignore next */
    if (event && event?.charCode) {
      /* istanbul ignore next */
      inputChar = String.fromCharCode(event?.charCode);
    }
    /* istanbul ignore next */
    if (event?.keyCode != 8 && !pattern.test(inputChar)) {
      /* istanbul ignore next */
      try {
        event.preventDefault();
      } catch (e) {}
    }
  }

  async validateLogin(data: any) {
    /* istanbul ignore next */
    const loginDetails = {
      userName: data.username,
      encryptedPassword: this.encrypt(data.password),
      tenantName: data.tenant,
      clientId: data.client_id,
    };
    await this.authenticationService
      ?.login(loginDetails, data.remember)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        /* istanbul ignore next */
        this.afterLogin(response);
      });
  }

  // forgotPassword() {
  //   this.router.navigateByUrl('/forgotPass');
  // }
  afterLogin(response: any) {
    /* istanbul ignore else */
    if (response?.result && response?.status === 200) {
      sessionStorage.setItem('TenantId', localStorage.getItem('TenantId'));
      localStorage.setItem('ActiveUserFlag', 'user');
      this.userFacade.updateActiveUserFlag('user');
      setTimeout(() => {
        this.userFacade.loadActiveUsers('login');
      }, 100);
      this.alertService.showToaster(response?.message, '', 'success');
      localStorage.setItem('isGuestUser', 'false');
    } else if (response?.message === 'Please verify your email to continue.') {
      this.alertService.showToaster(response?.message, '', 'success');
    } else {
      this.alertService.showToaster(response?.message, '', 'error');
    }
  }

  // forgotPassword() {
  //   this.router.navigateByUrl('/forgotPass');
  // }

  fetchSignupConfigurations() {
    const res = localStorage.getItem('envConfigInfo')
      ? JSON.parse(localStorage.getItem('envConfigInfo'))
      : null;
    /*istanbul ignore else */
    if (res?.canUsersSelfSignUp) {
      this.checkReferralCode();
      this.customLabel = res?.customIdLabel;
      this.isRoleMandatory = res?.roleMandatoryWhileSignUp;
      this.showPublicRoles = res?.showPublicRoles;
      this.signupOptions = res?.signupOptions;
      this.customIdGeneration = res?.customIdGeneration;
      this.defaultRole = res?.defaultPublicRole;
      this.mobileNumberMandatoryWhileEmailSignUp =
        res?.mobileNumberMandatoryWhileEmailSignUp;
      this.otpTimeOutDuration = res?.otpPolicy?.otpTimeout;
      // this.defaultPublicRole =res?.result?.defaultPublicRole!=null ? true:false;
      /* istanbul ignore else*/
      // if(res?.result?.defaultPublicRole){
      //   this.rolesList?.forEach((ele: any) => {
      //     if(ele.description == res?.result?.defaultPublicRole)
      //     {
      //       this.selectedRole =ele;
      //     }
      //   })
      // }
    } else {
      this.alertService.showToaster(
        'self signup is not allowed in this tenant, please contact admin',
        '',
        'info'
      );
      this.router.navigate(['/login']);
    }
  }
  getCountryCodes() {
    this.onboardingFacadeService
      .getCountryCodes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.countryCodes = Object.entries(res.result);
        if (this.countryCodes) {
          this.selectedCountryCode = '+91';
          this.onCountrySelect();
        }
      });
  }

  encrypt(password: string) {
    const publicKey =
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLU0whc9gpsHOER0wCrE/j13OASD1h/fL9lFXgrDT1HynMhxwIhCJPja7ww06wA3B0uZ8kxEfCavohvbmjMph3CoPRXWynWuvenDGB2ek1nbCg2aHJvmb3Zg1Coea0gmGkBorVWtqkwmLpuX/2Jq+W+UoWSMfePuyPC3kY4VwFpQIDAQAB';
    let $encrypt = new JSEncrypt();
    const text = `${password}`.trim();
    /* istanbul ignore else */
    if (text.length < 117) {
      $encrypt.setPublicKey(publicKey);
      return String($encrypt.encrypt(text));
    }
  }

  validateName(event: any) {
    let regex = new RegExp('[a-zA-Z ]');
    let key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    /*istanbul ignore else */
    if (!regex.test(key)) {
      event.preventDefault();
      this.alertService.showToaster(
        'First Name and Last Name can only have alphabets',
        '',
        'warning'
      );
      return false;
    }
  }

  checkReferralCode() {
    const param =
      window?.location?.href?.indexOf(this.customLabel) !== -1 ? true : false;
    /* istanbul ignore next */
    if (param) {
      let decodedUrl = decodeURIComponent(window.location.href);
      this.referralCode = new URL(decodedUrl).searchParams.get(
        this.customLabel
      );
      this.signupPayload.customID = this.referralCode;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openDialog(templateRef: TemplateRef<any>) {
    if (this.showPublicRoles && this.selectedRole) {
      this.rolesFacade
        .getTnCbyClientIdAndRole(this.clientId, this.selectedRole.name)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if (
            res &&
            res?.result &&
            res?.result?.termsAndConditions &&
            res.result?.status?.toLowerCase() == 'approved'
          ) {
            this.dialog.open(templateRef, {
              data: res.result.termsAndConditions,
              width: '500px',
              height: '500px',
            });
          } else {
            this.dialog.open(templateRef, {
              data: this.termsAndConditions,
              width: '500px',
              height: '500px',
            });
          }
        });
    } else {
      this.dialog.open(templateRef, {
        data: this.termsAndConditions,
        width: '500px',
        height: '500px',
      });
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  closeDialog(type: string) {
    if (type == 'Agreed') {
      this.termsAgreed = true;
    } else {
      this.termsAgreed = false;
    }
    this.dialog.closeAll();
  }

  /**
   * @author : Shakshi Agrawal
   * @Description : stores the metadata of the particular selected countryCode
   * @Date 12/6/23
   */
  onCountrySelect() {
    if (this.countryCodes) {
      const mobileData = this.countryCodes.filter(
        (country: any) => country[1].countryCode == this.selectedCountryCode
      );
      this.mobileNumberData = mobileData[0];
      if (
        this.signupPayload.mobileNumber.length !=
        mobileData[0][1].mobileNumberLength
      ) {
        this.mobileNumberValidatorEmail = false;
      } else {
        this.mobileNumberValidatorEmail = true;
      }
    }
  }
  getCountryCodeDetails() {
    if (localStorage.getItem('envConfigInfo')) {
      let signupProperties = JSON.parse(localStorage.getItem('envConfigInfo'));
      if (signupProperties?.countryCode?.includes(':')) {
        this.userCountryCode = signupProperties?.countryCode.split(':');
      } else {
        this.userCountryCode = signupProperties?.countryCode;
      }

      if (
        this.userCountryCode !== 'undefined' &&
        this.userCountryCode !== null
      ) {
        if (Array.isArray(this.userCountryCode)) {
          this.selectedCountryCode = this.userCountryCode[1];
          this._countryName = this.userCountryCode[0];
        } else {
          this.selectedCountryCode = this.userCountryCode;
          if (this.countryCodes?.length > 0) {
            let index = this.countryCodes.findIndex(
              (attr: any) => attr[1] == this.selectedCountryCode
            );
            if (index != -1) {
              this._countryName = this.countryCodes[index][0];
            }
          }
        }
      }
      if (signupProperties.defaultCountryCodeEditable) {
        this.defaultCountryCodeEditable =
          signupProperties?.defaultCountryCodeEditable;
      }
    }
  }

  otp: string[] = new Array(6).fill('');
  onOtpInputChange(index: number, event: any): void {
    const inputValue = event.target.value;
    if (event.keyCode == 8) {
      const prevInput = (document.activeElement as HTMLInputElement)
        .previousElementSibling as HTMLElement;

      if (prevInput) {
        prevInput.focus();
      }
    }
    if (inputValue.length > 1) {
      event.target.value = inputValue.charAt(0);
      return;
    }
    this.otp[index - 1] = inputValue;
    if (index < this.otp.length && inputValue) {
      const nextInput = (document.activeElement as HTMLInputElement)
        .nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  getOTP() {
    this.onboardingFacadeService
      .sendOptRequest(
        this.signupPayload.mobileNumber,
        this.signupPayload.email,
        this.selectedCountryCode
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res?.status == 200) {
            this.alertService.showToaster(`${res.message}`, '', 'success');
            this.activateOtp = true;
            this.timeLeft = this.otpTimeOutDuration
              ? Number(this.otpTimeOutDuration)
              : 60;
            this.interval = setInterval(() => {
              if (this.timeLeft > 0) {
                this.timeLeft--;
              } else {
                clearInterval(this.interval);
              }
            }, 1000);
          } else {
            this.alertService.showToaster(
              `${res.message}`,
              'Please try again',
              'error'
            );
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
        }
      );
  }

  validateOTP(skipUserCreation: boolean = false) {
    const otpString: string = this.otp.join('');
    let payload = {
      countryCode: this.signupPayload.countryCode,
      mobileNumber: this.signupPayload.mobileNumber,
      tenantName: localStorage.getItem('envClientId'),
      // "userName": this.signupPayload.userName,
      otpNumber: parseInt(otpString),
      // "guestUserId": this.signupPayload.guestUserId,
      email: this.signupPayload.email,
      roles: [],
    };
    this.onboardingFacadeService
      .validateOTP(payload, skipUserCreation)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result: any) => {
          console.log(result);
          this.isValid = true;
        },
        (error: any) => {
          console.log(error?.error);
          this.isValid = false;
        }
      );
  }
}
