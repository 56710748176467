import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ChatBotMediaDialog } from "./chatbot-media-dialog/chatbot-media-dialog.component";
import { LivechatSmartComponent } from "./livechat-smart.component";
import { ApplicationPipesModule } from "@common-services";
import { EntityCardsModule } from "entity-cards";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    LivechatSmartComponent,
    ChatBotMediaDialog,
  ],
  exports: [
    LivechatSmartComponent
  ],
  imports: [
    MatDialogModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    PickerModule,
    MatRadioModule,
    ApplicationPipesModule,
    EntityCardsModule,
    DragDropModule  
  ]
})
export class LivechatSmartModule {

}
