import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  EventsFacadeService,
  FieldConfig,
  TransactionFacadeService,
  validateDependentExpression,
} from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { getUiControlCustomization } from '@common-services';
@Component({
  selector: 'app-checkbox',
  styleUrls: ['./checkbox.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <form
      class="checkbox-group-wrap custom-class"
      [formGroup]="group"
      [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }"
    >
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="
              (field?.sentence == null || field?.sentence === '') &&
              !field?.hideLabels &&
              !field?.configuration?.hideLabel &&
              !field?.hideLabelMultiAttr
            "
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate"
                >{{ field?.label }}
                <span class="main-label-colon">:</span></span
              >
              <span
                *ngIf="field?.isRequired && !field?.isInfo"
                class="mat-form-field-required-marker"
                >*</span
              ></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              class="material-icons-outlined helperText-infoIcon"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        >
          <mat-icon
            class="d-flex align-items-center justify-content-center"
            matTooltip="Sub-Transactional CU"
            matSuffix
            *ngIf="
              field?.nslAttributeProperties?.triggerSubTransaction == 'true'
            "
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img
                class="d-block"
                src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="
          field?.configuration?.helperTextPosition == 'top' &&
          !field?.configuration?.showHelperTextIcon
        "
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <mat-selection-list
        *ngIf="
          !(
            (this.field?.attribute)['isTableConfig'] &&
            (this.field?.attribute)['tableUiStyle']
          )
        "
        [(ngModel)]="field.value"
        [formControlName]="field.attribute?.name"
        id="{{ field?.attribute?.name }}"
        (selectionChange)="onSelectionChange($event)"
        [required]="field?.isRequired && !field?.isInfo"
        class="{{ appliedClass }}"
        [ngClass]="field?.horizontalOptions ? 'horizontal-cls' : 'vertical-cls'"
      >
        <mat-list-option
          [ngClass]="foundObject?.event === 'Option 1' ? 'checkbox1' : ''"
          [matTooltip]="
            field?.configuration?.hideTooltip ? '' : option.displayValue
          "
          [matTooltipPosition]="'above'"
          [disabled]="field.readonly"
          *ngFor="let option of field.options"
          [value]="option.actualValue"
          id="checked_{{ field?.attribute?.name }}_{{ option.displayValue }}"
        >
          {{ field?.hideOptionLabels ? '' : option.displayValue }}
        </mat-list-option>
      </mat-selection-list>
      <!--Radio groups in table-->
      <div
        class="radio-check-table"
        *ngIf="
          (this.field?.attribute)['isTableConfig'] &&
          (this.field?.attribute)['tableUiStyle']
        "
      >
        <p-overlayPanel
          #op
          styleClass="ui-control-dropdown ui-control-dropdown-rc checkbox-group-wrap"
        >
          <mat-selection-list
            [(ngModel)]="field.value"
            [formControlName]="field.attribute?.name"
            id="{{ field?.attribute?.name }}"
            (selectionChange)="onSelectionChange($event)"
            [required]="field?.isRequired && !field?.isInfo"
            class="{{ appliedClass }}"
          >
            <mat-list-option
              [ngClass]="foundObject?.event === 'Option 1' ? 'checkbox1' : ''"
              [matTooltip]="
                field?.configuration?.hideTooltip ? '' : option.displayValue
              "
              [matTooltipPosition]="'above'"
              [disabled]="field.readonly"
              *ngFor="let option of field.options"
              [value]="option.actualValue"
              id="checked_{{ field?.attribute?.name }}_{{
                option.displayValue
              }}"
            >
              {{ field?.hideOptionLabels ? '' : option.displayValue }}
            </mat-list-option>
          </mat-selection-list>
        </p-overlayPanel>
        <span
          (click)="op.toggle($event)"
          class="select-label"
          *ngIf="!group?.value?.value"
          >Select</span
        >
        <div
          class="selected-list"
          *ngIf="group?.value?.value?.length > 0"
          (click)="op.toggle($event)"
        >
          <ul>
            <li>{{group?.value?.value?.[0]}}</li>
            <li *ngIf="group?.value?.value?.[1]">
              ,{{group?.value?.value?.[1]}}
            </li>
            <li>
              {{
                group?.value?.value?.length > 2
                  ? '+' + (group?.value?.value?.length - 2).toString()
                  : ''
              }}
            </li>
          </ul>
          <figure class="w-auto">
            <img src="../../../assets/images/ui-controls/group-edit-icon.svg" />
          </figure>
        </div>
      </div>
      <p
        class="bottom-text"
        *ngIf="
          field?.configuration?.helperTextPosition == 'bottom' &&
          !field?.configuration?.showHelperTextIcon
        "
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        {{ field?.configuration?.helpertext }}
      </p>

      <!--<div class="uikit-check option2">
          <label class="check">
            <input type="checkbox" />
            <div class="box"></div>
          </label>
      </div>


      <div class="checkbox-container">
        <input type="checkbox" id="rememberMe"/>
        <label class="checkbox" for="rememberMe"></label>
        <label for="rememberMe">Remember me?</label>
      </div>-->

      <ng-container
        *ngFor="let validation of field.validations"
        ngProjectAs="mat-error"
      >
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </form>
  `,
  styles: [],
})
export class CheckBoxComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  options: any[];
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  foundObject: any;
  appliedClass: string;

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacadeService: TransactionFacadeService
  ) {}

  ngOnInit(): void {
    this.options = this.field?.options?.filter(
      (x: any) => x?.displayValue && x?.actualValue
    );
    this.addValueToOptions();
    /* istanbul ignore else */
    if (sessionStorage.getItem('uicontrolsMap')) {
      let arr = Object?.entries(
        JSON.parse(sessionStorage.getItem('uicontrolsMap'))
      )?.map(([type, event]) => ({
        type,
        event,
      }));
      this.foundObject = arr.find((obj) => obj.type === 'Checkbox');
    }
    /* istanbul ignore else */
    if (this.field.validations?.length > 0) {
      const validList: any = [];
      /* istanbul ignore next */
      this.field.validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      /* istanbul ignore next */
      this.group?.controls[this.field?.attribute?.name]?.setValidators(
        Validators.compose(validList)
      );
    }
    let checkboxOptions = getUiControlCustomization('Checkbox');
    /* istanbul ignore else */
    if (checkboxOptions) {
      let i = checkboxOptions.slice(-1);
      this.appliedClass = `form-checkbox-opts form-checkbox-opt${i}`;
    }
  }

  /**
   * Determines whether selection change on
   * @param event holds the selected checkboxes value
   */
  onSelectionChange(event: any) {
    if (!this.field.isHidden) {
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    if (validation?.name == 'valuesSelect') {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    return this.group?.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      if (validation?.name == 'valuesSelect') {
        return validateDependentExpression(
          validation,
          this.eventsService,
          this.field,
          this.group,
          this.transactionFacadeService
        );
      }
      return this.group?.controls[field.attribute.name]?.value
        .toString()
        .match(validation.requiredPattern)
        ? false
        : true;
    }
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  addValueToOptions() {
    let valueField: any;
    if (Array.isArray(this.field.value)) {
      /* istanbul ignore next */
      valueField = this.field.value[0];
    } else {
      /* istanbul ignore next */
      valueField = this.field.value;
    }
    /* istanbul ignore else */
    if (this.options && this.options.length > 0 && valueField !== '') {
      const optIndex = this.options.findIndex(
        (x: any) => x.actualValue == valueField
      );
      /* istanbul ignore else */
      if (optIndex == -1) {
        /* istanbul ignore else */
        if (Array.isArray(this.field.value)) {
          /* istanbul ignore next */
          this.field.value = [];
        } else {
          /* istanbul ignore next */
          this.field.value = '';
        }
      }
    }
  }
}
