import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class ManageIntegrationsEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getCategories() {
    return this.http.get(this.api?.getCategoriesURL);
  }

  getSubCategories(subCategory: any) {
    return this.http.get(this.api?.getSubCategoriesURL + subCategory);
  }

  getConfigurationEntity(configId: any) {
    return this.http.get(this.api?.getConfigurationURL + configId);
  }

  addConfiguration(postData: any) {
    return this.http.post(this.api?.configurableSaveEntity, postData);
  }
  addAdapter(formData: any, value: number) {
    if (value === 0) {
      //No files simple integration in json format
      return this.http.post(this.api?.addAdapter, formData);
    } else if (value === 1) {
      //Output file formData
      return this.http.post(this.api?.addoutputfile, formData, {});
    } else if (value === 11) {
      //Input file formdata
      return this.http.post(this.api?.addinputfile, formData, {});
    } else {
      //2 files formdata
      return this.http.post(this.api?.add2file, formData, {});
    }
  }
  addAdapterv2(formData: any) {
    return this.http.post(this.api?.addAdapterv2, formData, {});
  }
  addAdapterv3(formData: any) {
    return this.http.post(this.api?.addAdapterv3, formData, {});
  }
  updateadapterv2(formData: any, dsdId: any) {
    return this.http.put(this.api?.addAdapterv2 + dsdId, formData);
  }
  updateadapterv3(formData: any, dsdId: any) {
    return this.http.put(this.api?.addAdapterv3 + dsdId, formData);
  }
  addswagger(obj: any) {
    return this.http.post(this.api?.addSwagger, obj);
  }

  entitydrop(page: number, limit: number, searchString: string) {
    const ontology = '';
    const publisherId = '';
    return this.http
      .get(
        this.api?.entityList +
          `query=${searchString}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${page}&limit=${limit}&isPublished=true`
      )
      .pipe(pluck('result'));
  }

  getAllEntitiesOnRole(page: number, limit: number, searchString: string, role: any) {
    const ontology = '';
    const publisherId = '';
    return this.http
      .get(
        /* api.entityList +
          `query=${searchString}&publisherIdOrName=${publisherId}&ontology=
          ${ontology}&page=${page}&limit=${limit}&isPublished=true&roleId=${role}` */
        this.api?.entityList +
          `query=${searchString}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${page}&limit=${limit}&isPublished=true&userRight=TXN_DECISION_RIGHTS&rightHolderId=${role}&rightOwnerType=ROLE`
      )
      .pipe(pluck('result'));
  }
  
  listconnection() {
    return this.http.get(this.api?.listConnection);
  }
  connectionlist(id: string) {
    return this.http.get(this.api?.filterConnection + id);
  }
  updateadapter(integration: any) {
    return this.http.put(this.api?.addAdapter, integration);
  }

  extractEntity(id: any) {
    return this.http.get(this.api?.entityExtract + id);
  }
  /* api call for adapter table */
  getadapter(pageNumber: number, pageSize: number, query: string, adapter: string) {
    return this.http.get(
      this.api?.viewAdapter + `${pageNumber}&pageSize=${pageSize}&adapterType=${adapter}&query=${query}`
    );
  }

  getAimlAdapter(dsdId: any) {
    return this.http.get(this.api?.getaimlAdapter + dsdId);
  }

  getadapterbyId(dsdId: any) {
    return this.http.get(this.api?.addAdapter + dsdId);
  }

  getadapterv2byId(dsdId: any) {
    return this.http.get(this.api?.addAdapterv2 + dsdId);
  }
  getadapterv3byId(dsdId: any) {
    return this.http.get(this.api?.addAdapterv3 + dsdId);
  }
  updateConfiguration(postData: any) {
    return this.http.put(this.api?.updateConfigurationURL, postData);
  }

  getConfiguration(ConfigId: any) {
    return this.http.get(this.api?.getConfigurationURL + ConfigId);
  }

  postrestEndPoint(postData: any) {
    return this.http.post(this.api?.postrest, postData);
  }
  postsftpEndPoint(postData: any) {
    return this.http.post(this.api?.postsftp, postData);
  }

  postSSHsftpEndPoint(postData: any) {
    return this.http.post(this.api?.postsftp, postData);
  }

  getconnectionCheck(postData: any) {
    return this.http.post(this.api?.connectionCheck, postData);
  }

  getConnectionDetailsEndPoint(connectionId: any, pageno: any, pagesize: any, query: any) {
    return this.http.get(
      this.api?.connectionDetails +
        `connectionType=${connectionId}&pageNumber=${pageno}&pageSize=${pagesize}&query=${query}`
    );
  }
  getAllConnectionsRest(attrId: any) {
    return this.http.get(this.api?.postrest + '/' + attrId);
  }

  getAllConnectionsSftp(attrId: any) {
    return this.http.get(this.api?.postsftp + '/' + attrId);
  }
  getDropdownAndTableEndPoint(dropdowns: any) {
    return this.http.post(this.api?.listConnection, dropdowns);
  }

  PUTRestEndPoint(recordId: any, data: any) {
    return this.http.put(this.api?.postrest + '/' + recordId, data);
  }

  PUTSftpEndPoint(recordId: any, data: any) {
    return this.http.put(this.api?.postsftp + '/' + recordId, data);
  }
  aimlStatus(payload: any) {
    return this.http.put(this.api?.aimlStatus, payload);
  }

  getEntityName(id: any) {
    return this.http.get(this.api?.getentityName + id + '?isPublished=true&isReserved=false');
  }

  submitS3ConnectionData(payload: any) {
    return this.http.post(this.api?.postS3, payload);
  }

  getS3connections(id: any) {
    return this.http.get(this.api?.postS3 + '/' + id);
  }

  updateS3connections(id: any, payload: any) {
    return this.http.put(this.api?.postS3 + '/' + id, payload);
  }

  submitFacebookConnectionData(payload: any) {
    return this.http.post(this.api?.postFacebook, payload);
  }

  submitTwitterConnectionData(payload: any) {
    return this.http.post(this.api?.postTwitter, payload);
  }

  submitJiraConnectionData(payload: any) {
    return this.http.post(this.api?.postJira, payload);
  }

  submitLinkedinConnectionData(payload: any) {
    return this.http.post(this.api?.postLinkedin, payload);
  }

  submitDocusignConnectionData(payload: any) {
    return this.http.post(this.api?.postDocusign, payload);
  }

  updateFacebookConnection(id: any, payload: any) {
    return this.http.put(this.api?.postFacebook + '/' + id, payload);
  }

  updateTwitterConnection(id: any, payload: any) {
    return this.http.put(this.api?.postTwitter + '/' + id, payload);
  }

  updateJiraConnection(id: any, payload: any) {
    return this.http.put(this.api?.postJira + '/' + id, payload);
  }

  updateLinkedinConnection(id: any, payload: any) {
    return this.http.put(this.api?.postLinkedin + '/' + id, payload);
  }
  
  updateDocusignConnection(id: any, payload: any) {
    return this.http.put(this.api?.postDocusign + '/' + id, payload);
  }

  getFacebookConnections(id: any) {
    return this.http.get(this.api?.postFacebook + '/' + id);
  }

  submitGoogleConnectionData(payload: any) {
    return this.http.post(this.api?.postGoogle, payload);
  }

  finalSubmitGoogle(id: any) {
    return this.http.get(this.api?.postGoogle + '/refreshToken/' + id);
  }

  finalSubmitFacebook(id: any) {
    return this.http.get(this.api?.postFacebook + '/refreshToken/' + id);
  }

  finalSubmitTwitter(id: any) {
    return this.http.get(this.api?.postTwitter + '/refreshToken/' + id);
  }

  finalSubmitJira(id: any) {
    return this.http.get(this.api?.postJira + '/refreshToken/' + id);
  }

  finalSubmitLinkedin(id: any) {
    return this.http.get(this.api?.postLinkedin + '/refreshToken/' + id);
  }

  finalSubmitDocusign(id: any) {
    return this.http.get(this.api?.postDocusign + '/refreshToken/' + id);
  }

  finalSubmitRest(id: any) {
    return this.http.get(this.api?.postrest + '/refreshToken/' + id);
  }

  getGoogleConnections(id: any) {
    return this.http.get(this.api?.postGoogle + '/' + id);
  }

  updateGoogleConnections(id: any, payload: any) {
    return this.http.put(this.api?.postGoogle + '/' + id, payload);
  }
  getZoomConnections(id: any) {
    return this.http.get(this.api?.postZoom + '/' + id);
  }
  finalSubmitZoom(id: any) {
    return this.http.get(this.api?.postZoom + '/refreshToken/' + id);
  }
  submitZoomConnectionData(payload: any) {
    return this.http.post(this.api?.postZoom, payload);
  }

  submitTelegramConnectionData(payload: any) {
    return this.http.post(this.api?.postTelegram, payload);
  }

  updateZoomConnections(id: any, payload: any) {
    return this.http.put(this.api?.postZoom + '/' + id, payload);
  }

  updateTelegramConnections(id: any, payload: any) {
    return this.http.put(this.api?.postTelegram + '/' + id, payload);
  }

  submitCiscoConnectionData(payload: any) {
    return this.http.post(this.api?.postCisco, payload);
  }

  finalSubmitCisco(id: any) {
    return this.http.get(this.api?.postCisco + '/refreshToken/' + id);
  }

  getCiscoConnections(id: any) {
    return this.http.get(this.api?.postCisco + '/' + id);
  }

  updateCiscoConnections(id: any, payload: any) {
    return this.http.put(this.api?.postCisco + '/' + id, payload);
  }

  submitGraphConnectionData(payload: any) {
    return this.http.post(this.api?.postGraph, payload);
  }

  finalSubmitGraph(id: any) {
    return this.http.get(this.api?.postGraph + '/refreshToken/' + id);
  }

  getGraphConnections(id: any) {
    return this.http.get(this.api?.postGraph + '/' + id);
  }

  updateGraphConnections(id: any, payload: any) {
    return this.http.put(this.api?.postGraph + '/' + id, payload);
  }

  submitKafkaConnectionData(payload: any) {
    return this.http.post(this.api?.postKafka, payload);
  }

  getKafkaconnections(id: any) {
    return this.http.get(this.api?.postKafka + '/' + id);
  }

  updateKafkaconnections(id: any, payload: any) {
    return this.http.put(this.api?.postKafka + '/' + id, payload);
  }

  submitSMTPConnectionData(payload: any) {
    return this.http.post(this.api?.postSMTP, payload);
  }

  getSMTPConnections(id: any) {
    return this.http.get(this.api?.postSMTP + id);
  }

  updateSMTPConnections(id: any, payload: any) {
    return this.http.put(this.api?.postSMTP + id, payload);
  }

  submitIMAPConnectionData(payload: any) {
    return this.http.post(this.api?.postIMAP, payload);
  }

  getIMAPConnections(id: any) {
    return this.http.get(this.api?.postIMAP + '/' + id);
  }

  updateIMAPConnections(id: any, payload: any) {
    return this.http.put(this.api?.postIMAP + '/' + id, payload);
  }

  submitPOP3ConnectionData(payload: any) {
    return this.http.post(this.api?.postPOP3, payload);
  }

  getPOP3Connections(id: any) {
    return this.http.get(this.api?.postPOP3 + '/' + id);
  }

  updatePOP3Connections(id: any, payload: any) {
    return this.http.put(this.api?.postPOP3 + '/' + id, payload);
  }

  saveDbConnection(payload: any) {
    return this.http.post(this.api?.postDB, payload);
  }

  updateDbConnection(id: any, payload: any) {
    return this.http.put(this.api?.postDB + '/' + id, payload);
  }

  getDBConnections(id: any) {
    return this.http.get(this.api?.postDB + '/' + id);
  }

  downloadFileAdapter(cuname: string) {
    return this.http.get(this.api?.downloadfileApi + cuname);
    // return this.http.get(`https://qa3.nslhub.com/adapter/api/openapi3.0/swagger-json/${cuname}`)
  }
  saveManageKey(data: any) {
    return this.http.post(this.api?.saveManageKey + '/', data);
  }
  fetchManageKeys(pageSize: any, pageNumber: any, type: any) {
    const name = '';
    const alias = '';
    return this.http.get(
      this.api?.saveManageKey +
        `?sortMethod=name&pageSize=${pageSize}&pageNumber=${pageNumber}&name=${name}&alias=${alias}&type=${type}`
    );
  }

  searchManageKeys(name: any, pageSize: any, pageNumber: any) {
    return this.http.get(
      this.api?.saveManageKey +
        `?sortMethod=name&pageSize=${pageSize}&pageNumber=${pageNumber}&name=${name}&alias=&type=SSHKEY`
    );
  }
  saveFileAsEntity(formdata: any) {
    return this.http.post(this.api?.saveFileAsEntity, formdata);
  }
  aimlSubmit(formData: any) {
    return this.http.post(this.api?.aimlSubmit, formData);
  }
  aimlUpdate(formData: any, dsdId: any) {
    return this.http.put(this.api?.aimlSubmit + dsdId, formData);
  }
  getAIMLConnections(data: any) {
    return this.http.get(this.api?.aimlSubmit + data);
  }
  addAdapteraiml(data: any) {
    return this.http.post(this.api?.addAdapteraiml, data);
  }

  updateAimladapter(data: any) {
    return this.http.put(this.api?.addAdapteraiml, data);
  }
  getCiscoConnection(type: string, pagenum: number, pageSize: number, search: string) {
    return this.http.get(
      this.api?.connectionDetails + `connectionType=${type}&pageNumber=${pagenum}&pageSize=${pageSize}&query=${search}`
    );
  }
  getGSI(search: string, pageno: number, pagesize: number) {
    return this.http.get(
      this.api?.getGSI + `pageNo=${pageno}&pageSize=${pagesize}&searchTerm=${search}&statusList=DRAFT,PUBLISHED`
    );
  }
  testEmailConfig(payload: any) {
    return this.http.post(this.api?.testEmailConfig, payload);
  }
  saveConnection(payload: any, connection: any) {
    return this.http.post(this.api?.saveConnection + connection, payload);
  }
  updateConnection(payload: any, connection: any) {
    return this.http.put(this.api?.saveConnection + connection, payload);
  }
  getConnectionDetails(dsdId: any, type: any) {
    if (type == 'rest') {
      return this.http.get(this.api?.postrest + `/${dsdId}`);
    }
    if (type == 'kafka') {
      return this.http.get(this.api?.postKafka + `${dsdId}`);
    }
    return this.http.get(this.api?.saveConnection + `${type}/${dsdId}`);
  }

  getWebhookURL(adapterName: string) {
    return this.http.get(this.api?.webhookURL + `${adapterName}`);
  }

  aimlManualTraining(attributeId: any) {
    return this.http.get(this.api?.aimlManulaTraining + `/${attributeId}`);
  }

  getSchedularDetails(pageNumber: any, pageSize: any, adapterSelected: any, searchCuInput: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };
    if (!adapterSelected && !searchCuInput) {
      return this.http.get(this.api?.schedular + `fetch?pageNumber=${pageNumber}&pageSize=${pageSize}`, options);
    }

    if (adapterSelected && searchCuInput) {
      return this.http.get(
        this.api?.schedular +
          `fetch?pageNumber=${pageNumber}&pageSize=${pageSize}&adapter_search=${adapterSelected}&cuName_search=${searchCuInput}`,
        options
      );
    }

    if (!adapterSelected && searchCuInput) {
      return this.http.get(
        this.api?.schedular + `fetch?pageNumber=${pageNumber}&pageSize=${pageSize}&cuName_search=${searchCuInput}`,
        options
      );
    }
    if (adapterSelected && !searchCuInput) {
      return this.http.get(
        this.api?.schedular + `fetch?pageNumber=${pageNumber}&pageSize=${pageSize}&adapter_search=${adapterSelected}`,
        options
      );
    }
  }

  suspendSchedular(schedularName: any) {
    return this.http.put(this.api?.schedular + `suspend?jobName=${schedularName}`, schedularName);
  }

  resumeSchedular(schedularName: any, currentSchedular: any) {
    return this.http.put(this.api?.schedular + `resume?jobName=${schedularName}`, currentSchedular);
  }
}
