import { Component, OnInit } from '@angular/core';
import { InsuranceEcommerceExpandBase } from '../insurance-ecommerce-expand-base-component';
import { AlertService, EntityBoardEndpointService } from '@common-services';

@Component({
  selector: 'app-expand-ecommerce-templates',
  templateUrl: './expand-ecommerce-templates.component.html',
  styleUrls: ['./expand-ecommerce-templates.component.scss'],
})
export class ExpandEcommerceTemplatesComponent extends InsuranceEcommerceExpandBase {
  constructor(alertService: AlertService, entityBoardService: EntityBoardEndpointService) {
    super(alertService, entityBoardService);
  }
}
