<div class="info-box" *ngIf="!transactionViewOCRFlag">
    <div class="row">
      <div class="col-12 config-align">
        <!-- <span>Current Configuration</span>
        <div class="template-toggle">
          <span class="edit-icon">Template
           
          </span>
          
          <span>
            <mat-slide-toggle disabled labelPosition="before" [(ngModel)]="isTemplate">
            </mat-slide-toggle
          ></span>
        </div> -->
        <span>{{ labels?.Current }} {{ labels?.Configuration }}</span
        ><span class="edit-icon"><mat-icon (click)="openPromptBox()">edit</mat-icon></span>
      </div>
    </div>
  </div>
  <div
    class="browse-content content-browse"
    appDnd
    (fileDropped)="onFileDropped($event)"
    *ngIf="!showFile && !transactionViewOCRFlag && showBrowse"
  >
    <div class="row dragNdrop">
      <div class="col gray-txt">
        <input type="file" (change)="fileChange($event.target.files)" id="dragNdrop" class="dragNdropInput" />
        <label for="dragNdrop">Drag and drop file </label>
      </div>
      <div class="col-auto">
        or
      </div>
      <div class="col text-right">
        <label for="dragNdrop">
          <strong> Browse</strong>
        </label>
      </div>
    </div>
  </div>
  
  <div *ngIf="showFile && ocrData">
    <span *ngIf="transactionViewOCRFlag" class="material-icons icon-zoom" (click)="ExpandTransactionViewOCR()">
      open_in_full
    </span>
    <!-- <div class="d-flex justify-content-between close-fix">
    
      <a (click)="closeOCRModal()" class="icon-close">
        <span class="material-icons ml-3">
          highlight_off
        </span>
      </a>
    </div> -->
  
    <div class="info-box" *ngIf="!dataSelectedFromImage && !transactionViewOCRFlag">
      <div class="row">
        <!-- <div class="col-12" *ngIf="!enableMapping && !showAttr">
          <i class="bi bi-circle" (click)="startMapping()" id="manual_mapping"></i>
          <span>{{ labels?.Map_Manually }}</span>
        </div> -->
        <div class="col-12" *ngIf="enableMapping && !showAttr">
          <span>{{ labels?.Select_a_text_to_be_recognised_using_the_cursor }}</span>
        </div>
        <div class="col-12" *ngIf="!enableMapping && showAttr">
          <span>{{ labels?.Select_Component }}</span>
        </div>
        <div class="col" *ngIf="!enableMapping && showAttr">
          <div *ngIf="manualMappedAttributedata.attribute_name != 'other'">
            <strong>{{ manualMappedAttributedata.attribute_name }} </strong>
          </div>
          <div *ngIf="manualMappedAttributedata.attribute_name == 'other' && !showInputBox">
            <img [src]="'../../../../assets/images/warning-icon.svg' | CDNUrlRewritePipe" alt="" />
            <strong> {{ labels?.could_not_recognised }}</strong>
            <span class="enter-name" (click)="showInput()"> {{ labels?.Enter_Name_Manually }}</span>
          </div>
          <div *ngIf="manualMappedAttributedata?.attribute_name == 'other' && showInputBox" class="d-flex">
            <input
              matInput
              type="text"
              name="componentName"
              [(ngModel)]="nameManualMappedAttr"
              placeholder="Enter Component Name"
              #componentName
              class="input-name"
            />
            <span class="save-name" (click)="saveName()"> {{ labels?.Save }} </span>
          </div>
        </div>
  
        <div
          class="col-auto adding-icons"
          *ngIf="!enableMapping && showAttr && manualMappedAttributedata.attribute_name != 'other'"
        >
          <div class="d-flex">
            <a class="d-flex" (click)="createManualMappedAttribute()">
              <span class="material-icons ml-3">
                add_circle
              </span>
              Add
            </a>
            <a class="d-flex" (click)="selectedFieldFromManualImage()">
              <span class="material-icons pin ml-3">
                push_pin
              </span>
              Map
            </a>
            <!-- <a class="d-flex" (click)="editManualMappedAttribute()">
              <span class="material-icons ml-3">
                mode_edit
              </span>
              edit
            </a> -->
          </div>
        </div>
  
        <div class="col-12" *ngIf="!enableMapping && showAttrList">
          <strong>
            {{ labels?.Select_Attribute_To_Be_Mapped }}
          </strong>
          <ul class="attribute-list">
            <li *ngFor="let attr of nslAttributes">
              <a>
                {{ attr?.name }}
                <span
                  class="material-icons ml-3"
                  *ngIf="dataSelectedFromImageForMapping"
                  (click)="selectedAttributeForMapping(attr)"
                >
                  push_pin
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info-box" *ngIf="dataSelectedFromImage && !transactionViewOCRFlag">
      <div class="row">
        <div class="col-12">
          {{ labels?.Select_From_The_Image }}
        </div>
  
        <div class="col">
          <strong>{{ dataSelectedFromImage?.attribute_name }} </strong>
        </div>
        <div class="col-auto adding-icons">
          <div class="d-flex">
            <a class="d-flex" (click)="createAttribute()">
              <span class="material-icons ml-3">
                add_circle
              </span>
              Add
            </a>
            <a class="d-flex" (click)="selectedFieldFromImage()">
              <span class="material-icons pin ml-3">
                push_pin
              </span>
              Map
            </a>
          </div>
        </div>
  
        <div class="col-12">
          <strong>
            {{ labels?.Select_Attribute_To_Be_Mapped }}
          </strong>
          <ul class="attribute-list">
            <li *ngFor="let attr of nslAttributes">
              <a>
                {{ attr?.name }}
                <span
                  class="material-icons ml-3"
                  *ngIf="dataSelectedFromImageForMapping"
                  (click)="selectedAttributeForMapping(attr)"
                >
                  push_pin
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
    <ng-container *ngIf="image && originalDocumentSize && coordinates">
      <app-image-map
        [src]="image"
        [component]="'ocr'"
        [originalDocumentSize]="originalDocumentSize"
        [coordinates]="coordinates"
        (onClick)="selectedField($event)"
        [fileType]="fileType"
        canEdit="true"
        [canMap]="enableMapping"
        (finishMapping)="stopMapping($event)"
        [ontology]="ontology"
        [isTemplate]="isTemplate"
      ></app-image-map>
    </ng-container>
  </div>
  
  <ng-template #promptBox>
    <div class="primary-object">
      <div class="heading">
        <h4>{{ labels?.Warning }}</h4>
        <br />
      </div>
      <div class="data-style">
        <p>
          {{ labels?.If_you_change_the_current_configurations }}, {{ labels?.all_saved_mappings_will_be_deleted }}.
          {{ labels?.Do_you_want_to_continue }}?
        </p>
      </div>
      <mat-dialog-actions class="delete-user-btns">
        <button mat-raised-button (click)="openDialogBox()">{{ labels?.continue }}</button>
        <button mat-raised-button (click)="closeDialog()">{{ labels?.Cancel }}</button>
      </mat-dialog-actions>
    </div>
  </ng-template>
  
  <ng-template #templateDialog *ngIf="!transactionViewOCRFlag">
    <div class="primary-object">
      <div class="heading">
        <h5>{{ labels?.Select }} {{ labels?.Configuration }}</h5>
        <br />
      </div>
      <span class="cancel-icon" (click)="closeDialog()"> <i class="bi bi-x-lg"></i></span>
      <br />
      <div class="template-toggle">
        <span class="edit-icon"
          >{{ labels?.Template }}
          <p
            [matTooltipPosition]="'above'"
            matTooltipClass="tool-tip-custom"
            matTooltip="Enable Template for creating Templatisation CDEC and disable for Dynamic CDEC"
            id="infoToolTip"
            class="edit-icon tooltip-style"
          >
            <mat-icon>info</mat-icon>
          </p>
        </span>
  
        <span> <mat-slide-toggle labelPosition="before" [(ngModel)]="isTemplate"> </mat-slide-toggle></span>
      </div>
      <div class="add-btn" (click)="saveConfigurations()">
        <button mat-raised-button>{{ labels?.Save }}</button>
      </div>
    </div>
  </ng-template>
  