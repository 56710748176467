<div class="spl-wrap">
  <p>{{ labels?.NSL_Query_Conditions }}</p>
  <div *ngFor="let query of queryCondition; let i = index">
    <div class="btn-group and-or-btn" *ngIf="i != 0">
      <button type="button" class="btn btn-secondary" [ngClass]="{ active: !andOr[i] }" (click)="andOrToggle('AND', i)">
        {{ labels?.And }}
      </button>
      <button type="button" class="btn btn-secondary" [ngClass]="{ active: andOr[i] }" (click)="andOrToggle('OR', i)">
        {{ labels?.or }}
      </button>
    </div>
    <div class="slect-wrap">
      <div class="slt-item">
        <mat-form-field class="cstm-select" (click)="expandSource(i)" appearance="outline">
          <input [value]="selectSource[i] ? selectSource[i] : ''" matInput placeholder="Select Source"
            readonly="true" />
        </mat-form-field>
        <div class="expantion-wrap right-posi" *ngIf="sourceExpand"
          [ngClass]="{ 'right-posi': targetExpand }">
          <div *ngFor="let triggerCu of tempGsiObj?.solutionLogic; let i = index" class="sf-dropdown">
            <p *ngIf="i == 0" (click)="clearSelection()">{{ labels?.None }}</p>
            <mat-accordion [togglePosition]="'before'">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p>{{ triggerCu.name }}</p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let layer of triggerCu?.layers">
                  <mat-accordion [togglePosition]="'before'">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <p>{{ layer.type }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="spl-entity" *ngFor="let entity of layer?.participatingItems; let i = index">
                        <mat-accordion [togglePosition]="'before'">
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <p>
                                  {{ entity.name }}
                                </p>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="cstm-attbt">
                              <li *ngFor="let attr of entity.nslAttributes">
                                <p
                                  (click)="setQueryContextualId(i, entity, attr, attr.name, triggerCu.index, triggerCu)">
                                  {{ attr.name }}
                                  <span><img [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
                                      alt="" /></span>
                                </p>
                              </li>
                            </ul>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>

      <div class="selct-opetr slt-item">
        <mat-form-field class="cstm-select" appearance="outline">
          <mat-select placeholder="Operator" [(ngModel)]="selectOperator[i]"
            (selectionChange)="emitDatatoSpecialfeature()">
            <mat-option *ngFor="let operator of operators" [value]="operator?.value?.name">
              {{ operator?.value?.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div class="slt-item">
        <mat-form-field appearance="outline">
          <input [(ngModel)]="inputTarget[i]" matInput placeholder="Input Target" (change)="emitDatatoSpecialfeature()"
            [disabled]="selectTarget[i]" />
          <!-- <span matSuffix>/</span> -->
        </mat-form-field>
      </div>

      <div class="slt-item">
        <mat-form-field class="cstm-select" (click)="expandTarget(i)" appearance="outline">
          <input [value]="expandTarget[i] ? expandTarget[i] : ''" matInput placeholder="Select Target"
            readonly="true" />
        </mat-form-field>
        <div class="expantion-wrap right-posi" *ngIf= "targetExpand"
          [ngClass]="{ 'right-posi': targetExpand }">
          <div *ngFor="let triggerCu of tempGsiObj?.solutionLogic; let i = index" class="sf-dropdown">
            <p *ngIf="i == 0" (click)="clearSelection()">{{ labels?.None }}</p>
            <mat-accordion [togglePosition]="'before'">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p>{{ triggerCu.name }}</p>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let layer of triggerCu?.layers">
                  <mat-accordion [togglePosition]="'before'">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <p>{{ layer.type }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="spl-entity" *ngFor="let entity of layer?.participatingItems; let i = index">
                        <mat-accordion [togglePosition]="'before'">
                          <mat-expansion-panel>
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <p>
                                  {{ entity.name }}
                                </p>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="cstm-attbt">
                              <li *ngFor="let attr of entity.nslAttributes">
                                <p
                                  (click)="setQueryContextualId(i, entity, attr, attr.name, triggerCu.index, triggerCu)">
                                  {{ attr.name }}
                                  <span><img [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
                                      alt="" /></span>
                                </p>
                              </li>
                            </ul>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>

      <span (click)="deleteQuery(i)" class="del">
        <mat-icon>delete</mat-icon>
      </span>
    </div>
  </div>
  <div class="add-rule-wrap">
    <span (click)="addNewQuery()">
      <mat-icon>add_circle</mat-icon>{{ labels?.Rule }}
    </span>
  </div>
</div>