import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { A2aFacadeService } from '../a2a/a2a-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';
import { UploadFacadeService } from '../upload/upload-facade.service';

let EXTRACTION_TYPES = {
  SOP: 'sop',
  BPMN: 'bpmn',
  FC: 'fc',
  VITT: 'vitt',
  XML: 'xml',
  GUIDE: 'guide',
  digitalcanvas: 'digitalcanvas',
  IMAGE2NSL: 'image2nsl',
  Excel: 'excelparser',
};
export interface DialogData {
  extractionDialog: string;
  name: string;
}

@Component({
  selector: 'app-extraction',
  templateUrl: './extraction.component.html',
  styleUrls: ['./extraction.component.scss'],
})
export class ExtractionComponent implements OnInit, OnDestroy {
  labels: any;
  selectFileToPreview: string;
  private ngUnsubscribe = new Subject();
  uploadFile: any;
  selectedFileType: string = '';
  extractionType: string = EXTRACTION_TYPES.SOP;
  url: any;
  textContent: any = '';
  extractionTypes = EXTRACTION_TYPES;
  tempGsiObj: any;
  name: any;
  extractionDialog: any;
  bookDetails: any;
  @Input() gsiData?: any;

  constructor(
    private a2aFacadeService: A2aFacadeService,
    private alertService: AlertService,
    private translator: TranslatorService,
    private gsiFacadeService: GsiFacadeService,
    public dialog: MatDialog,
    private fileUploadService: UploadFacadeService,
    private loaderService: LoaderService,
  ) {
    this.detectLanguageChange();
    this.detectTempGsiObj();
    this.getBookData();
  }

  openDialog(type: string): void {
    const data: any = {
      type,
    };
    switch (type) {
      case 'image':
      case 'video':
        data.url = this.url;
        break;
      case 'diagram':
        data.raw = this.textContent;
        break;
    }

    this.dialog.open(ExtractionComponentDialog, {
      width: '60vw',
      minHeight: '60vh',
      position: { top: '150px' },
      data: data,
      backdropClass: 'backdropBackground',
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.extractionTypes = {
        SOP: this.labels.sop,
        BPMN: this.labels.bpmn,
        FC: this.labels.fc,
        VITT: this.labels.vitt,
        XML: this.labels.xml,
        GUIDE: this.labels.guide,
        digitalcanvas: this.labels.digitalcanvas,
        IMAGE2NSL: this.labels.IMAGE2NSL,
        Excel: 'excelparser',
      };
    });
  }

  ngOnInit(): void {}

  detectTempGsiObj() {
    this.gsiFacadeService.tempGsiObj$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && res !== undefined) {
        this.tempGsiObj = res;
      }
    });
  }

  getBookData() {
    this.gsiFacadeService.sendBookDataForExtraction$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.bookDetails = res;
    });
  }

  importExtraction(file: any) {
    let gsiName = '';
    /* istanbul ignore next */
    if (this.tempGsiObj && this.tempGsiObj?.appDetails && this.tempGsiObj?.appDetails?.gsiName) {
      gsiName = this.tempGsiObj.appDetails.gsiName;
    }
    /* istanbul ignore next */
    if (!this.tempGsiObj && this.gsiData && this.gsiData?.name) {
      gsiName = this.gsiData.name;
    }
    /* istanbul ignore else */
    if (!this.uploadFile) {
      return this.alertService.showToaster('No File', 'Please select your file for Extraction', 'error');
    }
    /* istanbul ignore next */
    if (!gsiName) {
      return this.alertService.showToaster('No GSI Name', 'Please mention your GSI Name for Extraction', 'error');
    }

    const formData = new FormData();
    formData.append('file', this.uploadFile);
    this.fileUploadService.dynamicFormUploadFiles(formData, 1).subscribe((res: any) => {
      this.loaderService.hide();
      /* istanbul ignore next */
      const payload = {
        solutionName: gsiName,
        ontology: 'common',
        bookName: this.bookDetails?.dsdId ? this.bookDetails?.dsdId : 'Sample',
        bookDisplayName: this.bookDetails?.displayName ? this.bookDetails?.displayName : 'Sample',
        bookDescription: this.bookDetails?.description ? this.bookDetails?.description : 'Sample',
        gsiID: '',
        fileMeta: [
          {
            fileUrl: res.contentUrl,
            mediaType: this.selectedFileType,
            extractionType: this.extractionType,
          },
        ],
      };
      /* istanbul ignore next */
      if (this.tempGsiObj && this.tempGsiObj?.id) {
        payload.gsiID = this.tempGsiObj.id;
      }
      /* istanbul ignore next */
      if (!this.tempGsiObj && this.gsiData && this.gsiData?.id) {
        payload.gsiID = this.gsiData.id;
      }
      this.a2aFacadeService.extractContent(payload);
      this.dialog.closeAll();
      this.gsiFacadeService.closeDialogSubject.next(true);
    });
  }

  onSelectFile(event: any) {
    this.uploadFile = event.target.files[0];
    this.selectedFileType = this.isCheckFileExtension(this.uploadFile.name);
    /* istanbul ignore else */
    if (!this.selectedFileType) {
      return this.alertService.showToaster(
        'Invalid File Selection',
        'Please select image, audio, video or text',
        'error'
      );
    }

    this.previewFile(this.uploadFile);
  }

  previewFile(file: any) {
    const reader = new FileReader();
    if (this.selectedFileType != 'text' && this.selectedFileType != 'xml' && this.selectedFileType != 'bpmn') {
      reader.readAsDataURL(file);
      // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    } else {
      reader.readAsText(file);
      // read file as data stream
      reader.onload = (event) => {
        // called once readAsText is completed
        this.textContent = event.target.result;
      };
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /* HELPER FUNCTIONS */
  getExtension(filename: any) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isCheckFileExtension(filename: any) {
    const ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        return 'image';
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
        return 'video';
      case 'ogg':
      case 'mp3':
        return 'audio';
      case 'txt':
        return 'text';
      case 'xml':
        return 'xml';
      case 'bpmn':
        return 'xml';
      case 'docx':
        return 'document';
      case 'xlsx':
        return 'xlsx';
      default:
        return null;
    }
  }

  cancel() {
    this.dialog.closeAll();
    this.a2aFacadeService.isExtractionVisible$.next(false);
    this.gsiFacadeService.closeDialogSubject.next(true);
  }
}

@Component({
  selector: 'extraction-component-dialog',
  templateUrl: 'extraction-component-dialog.html',
})
export class ExtractionComponentDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<ExtractionComponentDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
