<div mat-dialog-title>
  <button
    class="btn btn-outline-secondary selectBtn badge-pill px-4"
    *ngIf="src.fromDesignSystem"
    (click)="goBacktoDesignSystemPreview(src.selectedLayout)"
  >
    <i class="bi bi-toggle-on"></i> Select
  </button>
  <button class="nh-unsetAll cursor-pointer" (click)="downloadFile()" *ngIf="!isHtmlType() && canDownload">
    <em class="nh-icon nh-icon-download01 nh-fw-700 nh-fs-24"></em>
  </button>
  <div class="rotateAction nh-display-flex nh-column-gap-10" *ngIf="isImageType() && !src.fromDesignSystem">
    <button class="nh-unsetAll cursor-pointer" (click)="rotateLeft()">
      <em class="nh-icon nh-icon-rotate_left nh-fw-700"></em>
    </button>
    <button class="nh-unsetAll cursor-pointer" (click)="rotateRight()">
      <em class="nh-icon nh-icon-rotate_right nh-fw-700"></em>
    </button>
  </div>
  <button class="nh-unsetAll cursor-pointer close-btn-modal" (click)="closePopup()">
    <em class="nh-icon nh-icon-x-lg nh-fw-700"></em>
  </button>
</div>
<mat-dialog-content>
  <div class="popup-div">
    <iframe *ngIf="!src?.mimeType" [src]="src.contentUrl | safeResourceUrl"></iframe>
    <div *ngIf="src?.contentUrl?.changingThisBreaksApplicationSecurity"
      [innerHTML]="src.contentUrl.changingThisBreaksApplicationSecurity"></div>
    <iframe *ngIf="isHtmlType() && !src?.contentUrl?.changingThisBreaksApplicationSecurity"
      [src]="src.contentUrl | safeResourceUrl"></iframe>
    <iframe [src]="src.contentUrl " *ngIf="isFrameSupported()" title=""></iframe>
    <div class="pdf-viewer" *ngIf="isPdfType()">
      <iframe [src]="src.contentUrl| safeResourceUrl| useHttpImgSrc | async " title=""></iframe>
    </div>
    <div *ngIf="isSheet" class="table-preview">
      <table>
        <tbody>
          <tr *ngFor="let row of sheetData">
            <td *ngFor="let val of row">
              {{ val }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fileUploadAudioPreview" *ngIf="isAudioType()">
      <audio controls>
        <source [src]="src.contentUrl | safeResourceUrl" />
      </audio>
    </div>
    <div class="fileUploadImgPreview" *ngIf="isImageType()">
      <img
        class="file-upload-img rotateImage mx-auto"
        [src]="src.contentUrl "
        *ngIf="isImageType()"
        alt=""
      />
    </div>
    <div class="fileUploadVideoPreview" *ngIf="isVideoType()">
      <video controls>
        <source [src]="src.contentUrl" />
        <track label="English" kind="captions" srclang="en" src="" default />
        Your browser does not support HTML video.
      </video>
    </div>

    <div *ngIf="isOfficeType" class="office-preview">
      <iframe
        *ngIf="isOfficeType"
        [src]="officeUrl "
        width="100%"
        height="250px"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</mat-dialog-content>
