import { Rights } from './rights';
import { Agent } from './agent';
import { Layer } from './layer';
import { GsiList } from './gsi-list';
import { Gsi } from './gsi';

export interface ChangeUnit {
  actionLabel?: string;
  dsdId: any;
  agents: Agent[];
  contextualId: string[];
  description: string;
  designTimeRights: Rights[];
  displayName: string;
  gsiList: GsiList[];
  // guid: string;
  id: number;
  masterId: number;
  layers: Layer[];
  membershipList: string[];
  name: string;
  ownerId: number;
  isReserved: boolean;
  reservedCUType: string;
  space: string;
  subOrdinateCUList: number[];
  superOrdinateCUList: number[];
  symbolicId: string;
  time: Date;
  txnTimeRights: Rights[];
  constraint?: string;
  constraintName?: string;
  cuType?: string;
  roles?: any[];
  estimatedTime?: string;
  referencedChangeUnit?: number;
  mindCUList?: any[];
  exceptionCUList: any[];
  ontology: any[];
  keywords: any[];
  author: any;
  status: string;
  dsdStatus: string;
  tCUConditionalPotentiality?: any[];
  tCUConditionalPotentialityNames?: any[];
  slotItemProperties?: any;
  index?: number;
  source: string;
  isNameUpdated: boolean;
  uiProperties: any;
  isFinal: any;
  isReservedTemplate: boolean;
  solutionLogic: any;
  dcd?: any[];
  tfId?: any;
  nextTriggerSet?: any[];
  isEditable?: boolean;
  isSelected?: boolean;
  oldVersionId?: string;
  isEdit?: boolean;
  tfReferencedChangeUnit?: string;
  sentenceTags?: any;
  displayInNewline?: boolean;
  showTagsView?: boolean;
  showInfoTagsView?: boolean;
  showTriggerTagsView?: boolean;
  dsdReferencedChangeUnit?: string;
  parentcuId?: string;
  showInfo?: boolean;
  eventCUList?: any[];
  cuSystemProperties?: any;
  hasError?: boolean;
  errorList?: any;
  isParallel?: boolean;
  attributeMapping?: {};
  methodName?: any;
  level?: number; //useful for embeddedCU in Grammer
  slotAdded?: boolean;
  showEmb?: boolean;
  agentNames?: Agent[];
  nestedDeleted?: any;
  openSfSentence?: boolean; //useful for sf
  specialFeatureProperties?: any;
  conditionName?: any;
  condition?: any;
  disableSTEs?: any;
  triggerCULayers?: any;
  measures?: any;
  parentParallelCu?: any;
  isFaceted?: boolean;
  mainNestedCuName?: any;
  singlNestedSave?: any;
  txnDataSaveMode?: any;
  isTemplate?: boolean;
  seperateCuData?: boolean;
  transactionEndpoint?: any;
  triggerCUDialogList?: any;
}

export class ChangeUnit {
  constructor() {
    this.actionLabel = '';
    this.dsdId = null;
    this.agents = [{ agentType: 'human' }];
    this.contextualId = [];
    this.description = '';
    this.designTimeRights = [];
    this.displayName = '';
    this.gsiList = [];
    // this.guid '';
    this.id = null;
    this.layers = [];
    this.membershipList = [];
    this.name = '';
    this.ownerId = null;
    this.isReserved = false;
    this.reservedCUType = '';
    this.space = '';
    this.subOrdinateCUList = [];
    this.superOrdinateCUList = [];
    this.symbolicId = '';
    this.time = new Date();
    this.txnTimeRights = [];
    this.constraint = '';
    this.constraintName = '';
    this.cuType = 'BASIC';
    this.roles = [];
    this.estimatedTime = '';
    this.referencedChangeUnit = null;
    this.mindCUList = [];
    this.exceptionCUList = [];
    this.ontology = [];
    this.keywords = [];
    this.author = {};
    this.status = '';
    this.dsdStatus = '';
    this.tCUConditionalPotentiality = [];
    this.tCUConditionalPotentialityNames = [];
    this.slotItemProperties = {};
    this.index = null;
    this.source = '';
    this.isNameUpdated = null;
    this.uiProperties = {};
    this.isFinal = false;
    this.isReservedTemplate = false;
    this.solutionLogic = [];
    this.dcd = [];
    this.tfId = null;
    this.eventCUList = [];
    this.cuSystemProperties = {};
    this.level = -1;
    this.isParallel = false;
    this.isEditable = false;
    this.isFaceted = false;
    this.txnDataSaveMode = 'DEFAULT';
    this.isTemplate = false;
    this.transactionEndpoint = '';
    this.triggerCUDialogList = [];
  }
}
