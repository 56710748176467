<div
  id="suggestion-modal"
  class="dropdown-menu text-muted d-block suggestion-modal"
  style="max-width: 500px;"
  *ngIf="recommendationsList?.length > 0 || gsiMyLibrarySuggestionList?.length > 0"
>
  <div id="nsl-dictionary" class="nsl-dictionary">
    <ul
      class="list-group list-group-flush common-list"
      [ngStyle]="{ 'margin-top': _type === 'ChangeUnit' ? '30px' : '0' }"
    >
      <div id="my-collection" class="my-collections" *ngIf="recommendationsList?.length > 0">
        <p>{{ _label }}</p>
        <button id="view-all" class="btn view-all" (click)="openCULibraryPanel()">{{ labels?.View_All }}</button>
      </div>
      <div class="my-collections-list-titles" *ngIf="_isParagraph && recommendationsList?.length > 0">
        <ul class="d-flex">
          <li>
            GSI
          </li>
          <li class="stateli">
            Status
          </li>
          <!-- <li>
            Version
          </li> -->
        </ul>
      </div>
      <ng-container *ngFor="let item of recommendationsList; let i = index">
        <li class="list-group-item collection-data-items" id="badge-list-group-item_{{ i }}">
          <div class="d-flex item align-items-center active">
            <div class="details w70" id="badge-details_{{ i }}">
              <a class="badge badge-pill badge-light bg-white"> {{ item?.name ? item?.name : item?.gsi_name }}</a>
            </div>
            <a class="draft-link w15" *ngIf="item?.status">{{ item?.status }}</a>
            <!-- <a class="version-link">1.0</a> -->
            <div class="detail-btns w15 ml-space">
              <a (click)="handleGsiSelect(item?.id, item?.name ? item?.name : item?.gsi_name)" class="bi bi-plus"></a>
              <!-- <a class="bi bi-info-circle"></a> -->
            </div>
            <!-- <a class="copy-icon"><span></span></a>
            <a class="more-icon"><span></span></a> -->
          </div>
        </li>
      </ng-container>

      <div id="gsi-suggestion" class="my-collections" *ngIf="gsiMyLibrarySuggestionList?.length > 0">
        <p>GSI Suggestion</p>
        <button id="view-all" class="btn view-all" (click)="showFullList()">{{ labels?.View_All }}</button>
      </div>
      <div class="my-collections-list-titles" *ngIf="gsiMyLibrarySuggestionList?.length > 0">
        <ul class="d-flex">
          <li>
            GSI
          </li>
          <li>
            Domain
          </li>
          <li>
            Module
          </li>
        </ul>
      </div>
      <ng-container *ngFor="let item of gsiMyLibrarySuggestionList; let i = index">
        <li class="list-group-item" id="badge-list-group-item_{{ i }}">
          <div class="d-flex item align-items-center active">
            <div class="details w30" id="badge-details_{{ i }}">
              <a class="badge badge-pill badge-light bg-white">
                {{ item.name ? item.name : item?.gsi_name }}
              </a>
            </div>
            <a class="draft-link w30" matTooltip="{{ item?.Domain }}">{{ item?.Domain }}</a>
            <a class="version-link w30" matTooltip="{{ item?.Module }}">{{ item?.Module }}</a>
            <div class="detail-btns">
              <a class="bi bi-plus" (click)="handleSelect(item?.name ? item?.name : item?.gsi_name)"></a>
              <a class="bi bi-info-circle">
                <div class="gsi-suggestion-info-popup">
                  <p class="text-truncate">Sub Module: {{ item?.Sub_Module }}</p>
                  <p class="text-truncate">Sub Process: {{ item?.Sub_Process }}</p>
                </div>
              </a>
            </div>

            <!-- <a class="copy-icon"><span></span></a>
            <a class="more-icon"><span></span></a> -->
          </div>
        </li>
      </ng-container>
    </ul>
    <!-- <label>{{ _label }}</label> -->
    <!-- <label>Reserved suggestion</label>
    <div *ngIf="reserveCURecommendation">
      <div class="my-collections rcu">
        <p>Reserved Change Units</p>
      </div>
      <ul class="list-group list-group-flush common-list">
        <li *ngFor="let list of reserveCURecommendation" class="list-group-item">
          <div class="d-flex item align-items-center active">
            <div class="details">
              <a class="badge badge-pill badge-light bg-white">
                {{ list?.name }}
              </a>
            </div>
            <a class="add-icon" (click)="selectReserveCU(list?.dsdId)"><span></span></a>
            <a class="copy-icon"><span></span></a>
            <a class="more-icon"><span></span></a>
          </div>
        </li>
      </ul>
    </div> -->
  </div>
</div>
