<form
  class="dynamic-form"
  (change)="onFormChange($event)"
  [formGroup]="form"
  [ngClass]="{ 'layout-five': layoutInfo === 't-5' }"
>
  <div
    class="search-filter"
    [ngClass]="{ 'search-filter-active': isFacetSearchCu }"
  >
    <div
      class="facet-filter"
      *ngIf="isFacetSearchCu && facetPosition == 'side'"
    >
      <div class="filter-icon" id="filter_id">
        <!-- <span class="dsd-col-dropbtn"></span> -->
        <p>Filter</p>
      </div>
      <ul class="facet-panel">
        <li
          *ngFor="let attribute of facetedData?.nslAttributes; let ind = index"
        >
          <mat-expansion-panel id="{{ attribute.displayName }}_{{ ind }}">
            <mat-expansion-panel-header>
              {{ attribute.displayName }}
            </mat-expansion-panel-header>
            <mat-selection-list
              [(ngModel)]="facetedData.nslAttributes[ind].values"
              [ngModelOptions]="{ standalone: true }"
              id="{{ attribute?.name }}"
            >
              <mat-list-option
                *ngFor="let option of attribute.options"
                [value]="option.actualValue"
                id="checked_{{ option.displayValue }}"
              >
                {{ option.displayValue }}({{ option.count }})
              </mat-list-option>
            </mat-selection-list>
          </mat-expansion-panel>
        </li>
      </ul>
      <div class="save-reset">
        <span (click)="resetFilters()"
          ><i class="bi bi-arrow-clockwise"></i
        ></span>
        <span (click)="saveSearchFilters()"
          ><i class="bi bi-check-circle-fill"></i
        ></span>
      </div>
    </div>
    <ng-container
      *ngIf="
        layoutConfigurations?.templateName ==
          'layout-education-platform-video-learning' && !isInfo
      "
    >
      <div class="form-model-firstCol">
        <div
          class="form-model"
          [ngClass]="{
            hide: checkForHidden(form?.controls, form?.value, 0)
          }"
        >
          <ng-template
            *ngIf="form?.controls && ( form.controls | keyvalue)?.[0]"
            [ngTemplateOutlet]="entityDiv"
            [ngTemplateOutletContext]="{
              control: (form?.controls | keyvalue: originalOrder)[0],
              i: 0
            }"
          ></ng-template>
        </div>
        <div
          class="form-model"
          [ngClass]="{
            hide: checkForHidden(form?.controls, form?.value, 1)
          }"
        >
          <ng-template
            *ngIf="form?.controls && ( form.controls | keyvalue)?.[1]"
            [ngTemplateOutlet]="entityDiv"
            [ngTemplateOutletContext]="{
              control: (form?.controls | keyvalue: originalOrder)[1],
              i: 1
            }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        (layoutConfigurations?.templateName ==
          'layout-dealer-management-phy-info' ||
          layoutConfigurations?.templateName ==
            'layout-dealer-management-phy-info-02' ||
          layoutConfigurations?.templateName ==
            'layout-dealer-management-phy-tabs-info') &&
        !isInfo
      "
    >
      <div class="form-model-firstCol">
        <ng-template
          *ngIf="form?.controls && ( form.controls | keyvalue)?.[lengthofForm-1]"
          [ngTemplateOutlet]="entityDiv"
          [ngTemplateOutletContext]="{
            control: (form?.controls | keyvalue: originalOrder)[
              lengthofForm - 1
            ],
            i: lengthofForm - 1
          }"
        >
        </ng-template>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        layoutConfigurations?.templateName == 'layout-vrim-info-7-phy-5' &&
        isInfo
      "
    >
      <div class="form-model-firstCol">
        <div
          class="form-model"
          [ngClass]="{
            hide: checkForHidden(form?.controls, form?.value, 0)
          }"
        >
          <ng-template
            *ngIf="form?.controls && ( form.controls | keyvalue)?.[0]"
            [ngTemplateOutlet]="entityDiv"
            [ngTemplateOutletContext]="{
              control: (form?.controls | keyvalue: originalOrder)[0],
              i: 0
            }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        layoutConfigurations?.templateName == 'layout-enterprise-RCP-phy' &&
        !isInfo
      "
    >
      <div class="form-model-firstCol">
        <div
          class="form-model"
          [ngClass]="{
            hide: checkForHidden(form?.controls, form?.value, 0)
          }"
        >
          <ng-template
            *ngIf="form?.controls && ( form.controls | keyvalue)?.[0]"
            [ngTemplateOutlet]="entityDiv"
            [ngTemplateOutletContext]="{
              control: (form?.controls | keyvalue: originalOrder)[0],
              i: 0
            }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        layoutConfigurations?.templateName == 'layout-enterprise-CRM-phy' &&
        !isInfo
      "
    >
      <div class="form-model-firstCol">
        <div class="form-model">
          <mat-accordion class="custom-mat-expansion">
            <mat-expansion-panel
              (opened)="onOpened(0)"
              [expanded]="accordianIndex == 0"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ entityList[0]?.displayName }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template
                *ngIf="form?.controls && ( form.controls | keyvalue)?.[0]"
                [ngTemplateOutlet]="entityDiv"
                [ngTemplateOutletContext]="{
                  control: (form?.controls | keyvalue: originalOrder)[0],
                  i: 0
                }"
              ></ng-template>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="onOpened(1)"
              [expanded]="accordianIndex == 1"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ entityList[1]?.displayName }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template
                *ngIf="form?.controls && ( form.controls | keyvalue)?.[1]"
                [ngTemplateOutlet]="entityDiv"
                [ngTemplateOutletContext]="{
                  control: (form?.controls | keyvalue: originalOrder)[1],
                  i: 1
                }"
              ></ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </ng-container>
    <ul class="form-fields">
      <ng-container
        *ngIf="
          (layoutConfigurations?.templateName ===
            'studioverse-layout-phy-8-4-12-info-12' &&
            !isInfo) ||
          (layoutConfigurations?.templateName ===
            'studioverse-layout-phy-8-4-12' &&
            !isInfo)
        "
      >
        <div class="studioverse-topProfile">
          <div class="container">
            <div
              class="parent-accordion-cls"
              [ngClass]="{ row: isEntityClass }"
            >
              <div
                [ngClass]="{
                  hide: checkForHidden(form?.controls, form?.value, 0)
                }"
                class="form-model"
              >
                <ng-template
                  *ngIf="form?.controls && (form.controls | keyvalue)?.[0]"
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[0],
                    i: 0
                  }"
                ></ng-template>
              </div>
              <div
                [ngClass]="{
                  hide: checkForHidden(
                    form?.controls,
                    form?.value,
                    lengthofForm - 1
                  )
                }"
                class="form-model"
              >
                <ng-template
                  *ngIf="form?.controls && ( form.controls | keyvalue)?.[lengthofForm-1]"
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[
                      lengthofForm - 1
                    ],
                    i: lengthofForm - 1
                  }"
                ></ng-template>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="showMatTabForEntity(true)" [ngClass]="templateType">
        <mat-tab-group
          class="dynamic-entity-tab-classes"
          [(selectedIndex)]="selectedIndex"
          (selectedTabChange)="goToViewAll($event.index)"
          [disableRipple]="true"
          *ngIf="
            layoutConfigurations?.templateName !==
              'studioverse-layout-phy-8-4-12-info-12' ||
            layoutConfigurations?.templateName !==
              'studioverse-layout-phy-8-4-12'
          "
        >
          <mat-tab
            *ngFor="
              let control of form?.controls | keyvalue: originalOrder;
              trackBy: trackByFn;
              let i = index
            "
            [aria-label]="
              form.value[control.key]?.isHidden ||
              form.value[control.key]?.entityHideLabel
                ? 'thide'
                : ''
            "
          >
            <ng-template mat-tab-label>
              <div
                class="rightpanel-heading-multiple"
                [ngClass]="{
                  inValidClass:
                    !control.value.value?.isInfo &&
                    control.value.invalid &&
                    control.value.touched &&
                    isSubmit
                }"
              >
                {{
                  form.value[control.key]?.displayName
                    ? form.value[control.key]?.displayName
                    : (control.key | split: "$")
                }}
                <span
                  *ngIf="
                    form.value[control.key]?.isMulti &&
                    !isMultiHidden(form.value[control.key]?.configuration)
                  "
                  class="add-icon"
                  id="add_isMulti{{ form.value[control.key]?.displayName }}_{{
                    i
                  }}_displayMatTabs"
                  (click)="
                    addMultipleEntityControl(
                      control.key,
                      control.value.controls,
                      form,
                      i,
                      control
                    )
                  "
                >
                  <div
                    *ngIf="
                      getMultiEntityButton(
                        form.value[control.key]?.configuration
                      );
                      else icon
                    "
                  >
                    <button
                      [ngClass]="{
                        square: addButtonShape == 'Square',
                        circle: addButtonShape == 'Circle'
                      }"
                    >
                      {{
                        displayAddButton(form.value[control.key]?.configuration)
                          ? displayAddButton(
                              form.value[control.key]?.configuration
                            )
                          : "Add"
                      }}
                    </button>
                  </div>
                  <ng-template #icon>
                    <em class="nh-icon nh-icon-plus-lg"></em>
                  </ng-template>
                </span>
                <span
                  *ngIf="
                    control.value.controls.ent_multiCount &&
                    control?.value?.value?.configurationType !== 'table' &&
                    control?.value?.value?.configurationType !==
                      'tableWithLabels' &&
                    control?.value?.value?.configurationType !==
                      'cardWithTemplate' &&
                    control?.value?.value?.configurationType !== 'card'
                  "
                  >{{ control.value.controls.ent_multiCount.value }}</span
                >
                <span
                  *ngIf="
                    form.value[control.key]?.isMulti &&
                    !isMultiHidden(form.value[control.key]?.configuration) &&
                    !(
                      control?.value?.value?.configurationType === 'table' ||
                      control?.value?.value?.configurationType == 'widgets'
                    ) &&
                    getFormValueLength(control.key) > 1
                  "
                  class="remove-icon"
                  id="remove_isMulti{{
                    form.value[control.key]?.displayName
                  }}_{{ i }}_displayMatTabs"
                  (click)="
                    deleteEntityControl(
                      control.key,
                      control.value.controls,
                      form,
                      i
                    )
                  "
                  ><div
                    *ngIf="
                      getMultiEntityButton(
                        form.value[control.key]?.configuration
                      );
                      else icon
                    "
                  >
                    <button
                      [ngClass]="{
                        square: removeButtonShape == 'Square',
                        circle: removeButtonShape == 'Circle'
                      }"
                    >
                      {{
                        displayRemoveButton(
                          form.value[control.key]?.configuration
                        )
                          ? displayRemoveButton(
                              form.value[control.key]?.configuration
                            )
                          : "Remove"
                      }}
                    </button>
                  </div>
                  <ng-template #icon>
                    <em class="nh-icon nh-icon-dash-lg"></em>
                  </ng-template>
                </span>
              </div>
            </ng-template>

            <div
              *ngIf="
                control?.value?.value?.entType === 'entity';
                else other_form
              "
            >
              <div *ngIf="getConfigurationType(control); else not_table">
                <div
                  *ngIf="
                    control?.value?.value?.configurationType === 'table' ||
                      control?.value?.value?.configurationType == 'widgets' ||
                      control?.value?.value?.configurationType ==
                        'tableWithLabels' ||
                      control.value.value.configurationType ===
                        'verticalTable' ||
                      control?.value?.value?.configurationType ===
                        'progressBar';
                    else is_card
                  "
                  class="tbl-dynamic-ui"
                >
                  <app-dynamic-ui-table
                    *ngIf="control?.value?.value?.configurationType === 'table'"
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedTransactionArray]="selectedTableRows"
                    [eventCUList]="currentCu?.eventCUList"
                    [isFacetSearchCu]="isFacetSearchCu"
                    [facetedData]="facetedData"
                    [filterData]="filterData"
                    (pageChanged)="pageChangedInTable($event)"
                    [rowNumberChanged]="rowNumberChanged"
                    [tempEntityList]="tempEntityList"
                    [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                    (rowNumber)="rowNumberChange($event)"
                    (selectedTransactionsEmit)="getSelectedTransactions($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (tableUploaded)="tableUploaded($event)"
                    (fileUploadEvent)="
                      fileUploadData(
                        $event,
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                    [wayPointsData]="wayPointsData"
                    (emitWaypointstoUpdateForm)="updateWaypointsinForm($event)"
                    (dateChangedEmit)="dateChangedEvent($event)"
                    (updateTableData)="updateTableData($event)"
                    (selectedEditableRow)="getSelectedEditableRow($event)"
                    [selectedEditRow]="selectedEditableRow"
                  >
                  </app-dynamic-ui-table>
                  <app-vertical-dynamic-ui-table
                    *ngIf="
                      control?.value?.value?.configurationType ===
                      'verticalTable'
                    "
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedTransactionArray]="selectedTableRows"
                    (tableUploaded)="tableUploaded($event)"
                    [eventCUList]="currentCu?.eventCUList"
                    (pageChanged)="pageChangedInTable($event)"
                    [rowNumberChanged]="rowNumberChanged"
                    (rowNumber)="rowNumberChange($event)"
                    (selectedTransactionsEmit)="getSelectedTransactions($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (fileUploadEvent)="
                      fileUploadData(
                        $event,
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                    (dateChangedEmit)="dateChangedEvent($event)"
                  >
                  </app-vertical-dynamic-ui-table>
                  <div
                    class="widgetConfig"
                    *ngIf="
                      control?.value?.value?.configurationType == 'widgets'
                    "
                  >
                    <app-canvasjs-display
                      *ngFor="let key of entityNames"
                      [chartType]="entityWidgetData[key].chartType"
                      [entityName]="entityWidgetData[key].name"
                      [data]="entityWidgetData[key].data"
                      [entityWidget]="entityWidgetData"
                      [control]="control"
                      [transaction]="true"
                      [displayName]="
                        entityDisplayName.has(key)
                          ? entityDisplayName.get(key)
                          : ''
                      "
                    ></app-canvasjs-display>
                  </div>
                  <div
                    class="progressBar"
                    *ngIf="
                      control.value.value.configurationType === 'progressBar'
                    "
                  >
                    <app-progress-bar-configuration [control]="control">
                    </app-progress-bar-configuration>
                  </div>
                  <app-dynamic-ui-table-labels
                    *ngIf="
                      control?.value?.value?.configurationType ===
                      'tableWithLabels'
                    "
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedTransactionArray]="selectedTableRows"
                    [eventCUList]="currentCu?.eventCUList"
                    (pageChanged)="pageChangedInTable($event)"
                    [rowNumberChanged]="rowNumberChanged"
                    [isFacetSearchCu]="isFacetSearchCu"
                    [facetedData]="facetedData"
                    [filterData]="filterData"
                    [tempEntityList]="tempEntityList"
                    [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                    (rowNumber)="rowNumberChange($event)"
                    (selectedTransactionsEmit)="getSelectedTransactions($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (tableUploaded)="tableUploaded($event)"
                    (fileUploadEvent)="
                      fileUploadData(
                        $event,
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                  ></app-dynamic-ui-table-labels>
                </div>
                <ng-template #is_card>
                  <app-dynamic-ui-card
                    *ngIf="
                      control?.value?.value?.configurationType !== 'widgets'
                    "
                    [isInfo]="isInfo"
                    [control]="control"
                    [selectedCardsList]="selectedCards"
                    [eventCUList]="currentCu?.eventCUList"
                    [tempEntityList]="tempEntityList"
                    [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                    (selectedCardsEmit)="getSelectedCards($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (pageChanged)="pageChangedInTable($event)"
                    (dateChangedEmit)="dateChangedEvent($event)"
                  ></app-dynamic-ui-card>
                </ng-template>
                <!-- <ng-template> </ng-template> -->
              </div>
              <ng-template #not_table>
                <app-nested-form
                  *ngIf="
                    control?.value?.value?.configurationType !== 'accordion'
                  "
                  class="nested-dynamic-form row"
                  [ngClass]="{
                    'size-extra-large-uicontrols' : screenWidth > 1920,
                    'size-medium-uicontrols' : screenWidth > 1366 && screenWidth <= 1440 ,
                    'size-small-uicontrols' : screenWidth >=356 && screenWidth <= 1366
                  }"
                  [control]="control"
                  [group]="form"
                  [isInfo]="isInfo"
                  [eventCUList]="currentCu?.eventCUList"
                  [crmTemplate]="
                    layoutConfigurations?.templateName ==
                    'layout-enterprise-CRM-phy'
                  "
                  [selectedIndexChange]="selectedIndex"
                  (formValueChange)="addDynamicAttributes($event)"
                  (dateChangedEmit)="dateChangedEvent($event)"
                  (nestedEntityAdded)="nestedEntitiesAdded($event)"
                  (disableSubmitEmit)="disableSubmitEvent($event)"
                  (nestedEntityTableSelections)="
                    getSelectedTransactions($event)
                  "
                  (nestedEntitySelections)="getSelectedCards($event)"
                  (nestedEntityRemoveTableSelections)="
                    removeEntityfromTable($event)
                  "
                  (removeNestedEntity)="removeEntityfromTable($event)"
                  (waypointsData)="getWayPointsData($event)"
                >
                </app-nested-form>
              </ng-template>
            </div>

            <ng-template #other_form>
              <app-basic-form
                [control]="control"
                [group]="form"
                [isReadOnly]="isReadOnly"
                (formValueChange)="addDynamicAttributes($event)"
                (dateChangedEmit)="dateChangedEvent($event)"
                (disableSubmitEmit)="disableSubmitEvent($event)"
                (waypointsData)="getWayPointsData($event)"
              >
              </app-basic-form>
            </ng-template>
            <div
              class="entity-tab-btns"
              *ngIf="showMatTabForEntity() && keysLength(form?.controls)"
            >
              <button
                type="button"
                class="text-center rounded-circle"
                *ngIf="
                  selectedIndex != 0 && selectedIndex != firstUnhiddenEntIndex
                "
                (click)="previousAccordian(selectedIndex)"
              >
                <!-- {{ labels?.Previous }} -->
                <em
                  class="nh-icon nh-icon-chevron-left"                 
                ></em>
                <span
                  *ngIf="
                    layoutConfigurations?.templateName ==
                      'layout-dealer-management-phy-info' ||
                    layoutConfigurations?.templateName ==
                      'layout-dealer-management-phy-info-02' ||
                    layoutConfigurations?.templateName ==
                      'layout-dealer-management-phy-tabs-info'
                  "
                  >Back</span
                >
              </button>
              <button
                type="button"
                *ngIf="
                  selectedIndex != keysLength(form?.controls) &&
                  LastUnhiddenEntIndex != -1 &&
                  selectedIndex != LastUnhiddenEntIndex
                "
                (click)="nextAccordian(selectedIndex)"
                class="text-center rounded-circle"
              >
                <!-- {{ labels?.Next }} -->
                <em
                  class="nh-icon nh-icon-chevron-right"                 
                ></em>
                <span
                  *ngIf="
                    layoutConfigurations?.templateName ==
                      'layout-dealer-management-phy-info' ||
                    layoutConfigurations?.templateName ==
                      'layout-dealer-management-phy-info-02' ||
                    layoutConfigurations?.templateName ==
                      'layout-dealer-management-phy-tabs-info'
                  "
                  >Next</span
                >
              </button>
            </div>
          </mat-tab>
        </mat-tab-group>

        <ng-template
          *ngIf="
            layoutConfigurations?.templateName ===
              'studioverse-layout-phy-8-4-12-info-12' ||
            layoutConfigurations?.templateName ===
              'studioverse-layout-phy-8-4-12'
          "
        >
          <mat-tab-group
            class="dynamic-entity-tab-classes"
            [(selectedIndex)]="selectedIndex"
            (selectedTabChange)="goToViewAll($event.index)"
            [disableRipple]="true"
          >
            <mat-tab
              *ngFor="
                let control of form?.controls | keyvalue: originalOrder;
                trackBy: trackByFn;
                let i = index
              "
              [aria-label]="form.value[control.key]?.isHidden ? 'thide' : ''"
            >
              <div>
                <ng-template mat-tab-label>
                  <div class="rightpanel-heading-multiple">
                    {{
                      form.value[control.key]?.displayName
                        ? form.value[control.key]?.displayName
                        : (control.key | split: "$")
                    }}
                    <span
                      *ngIf="
                        form.value[control.key]?.isMulti &&
                        !isMultiHidden(form.value[control.key]?.configuration)
                      "
                      class="add-icon"
                      id="add_isMulti{{
                        form.value[control.key]?.displayName
                      }}_{{ i }}_displayMatTabs"
                      (click)="
                        addMultipleEntityControl(
                          control.key,
                          control.value.controls,
                          form,
                          i,
                          control
                        )
                      "
                      ><em class="nh-icon nh-icon-plus-lg"></em
                    ></span>
                    <span
                      *ngIf="
                        control.value.controls.ent_multiCount &&
                        !getConfigurationTableOrCard(control)
                      "
                      >{{ control.value.controls.ent_multiCount.value }}</span
                    >
                    <span
                      *ngIf="
                        form.value[control.key]?.isMulti &&
                        !isMultiHidden(
                          form.value[control.key]?.configuration
                        ) &&
                        !(
                          getConfigurationTableOrCard(control) ||
                          getConfigurationCard(control)
                        ) &&
                        getFormValueLength(control.key) > 1
                      "
                      class="remove-icon"
                      id="remove_isMulti{{
                        form.value[control.key]?.displayName
                      }}_{{ i }}_displayMatTabs"
                      (click)="
                        deleteEntityControl(
                          control.key,
                          control.value.controls,
                          form,
                          i
                        )
                      "
                      ><em class="nh-icon nh-icon-dash-lg"></em
                    ></span>
                  </div>
                </ng-template>
                <div *ngIf="getControlsName(control); else other_form">
                  <div *ngIf="getConfigurationType(control); else not_table">
                    <div
                      *ngIf="
                        control?.value?.value?.configurationType === 'table' ||
                          control?.value?.value?.configurationType ==
                            'widgets' ||
                          control.value.value.configurationType ===
                            'verticalTable' ||
                          control.value.value.configurationType ===
                            'progressBar';
                        else is_card
                      "
                      class="tbl-dynamic-ui"
                    >
                      <app-dynamic-ui-table
                        *ngIf="
                          control?.value?.value?.configurationType === 'table'
                        "
                        [control]="control"
                        [isInfo]="isInfo"
                        [selectedTransactionArray]="selectedTableRows"
                        [eventCUList]="currentCu?.eventCUList"
                        (pageChanged)="pageChangedInTable($event)"
                        [rowNumberChanged]="rowNumberChanged"
                        [tempEntityList]="tempEntityList"
                        [isFacetSearchCu]="isFacetSearchCu"
                        [facetedData]="facetedData"
                        [filterData]="filterData"
                        [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                        (rowNumber)="rowNumberChange($event)"
                        (selectedTransactionsEmit)="
                          getSelectedTransactions($event)
                        "
                        (removeEntity)="removeEntityfromTable($event)"
                        (selectionEvent)="isSubmitbuttonenabled($event)"
                        (tableUploaded)="tableUploaded($event)"
                        (fileUploadEvent)="
                          fileUploadData(
                            $event,
                            control.key,
                            control.value.controls,
                            form,
                            i,
                            control
                          )
                        "
                        [wayPointsData]="wayPointsData"
                        (emitWaypointstoUpdateForm)="
                          updateWaypointsinForm($event)
                        "
                        (dateChangedEmit)="dateChangedEvent($event)"
                        (updateTableData)="updateTableData($event)"
                        (selectedEditableRow)="getSelectedEditableRow($event)"
                        [selectedEditRow]="selectedEditableRow"
                      >
                      </app-dynamic-ui-table>
                      <app-vertical-dynamic-ui-table
                        *ngIf="
                          control?.value?.value?.configurationType ===
                          'verticalTable'
                        "
                        [control]="control"
                        [isInfo]="isInfo"
                        [selectedTransactionArray]="selectedTableRows"
                        (tableUploaded)="tableUploaded($event)"
                        [eventCUList]="currentCu?.eventCUList"
                        (pageChanged)="pageChangedInTable($event)"
                        [rowNumberChanged]="rowNumberChanged"
                        (rowNumber)="rowNumberChange($event)"
                        (selectedTransactionsEmit)="
                          getSelectedTransactions($event)
                        "
                        (removeEntity)="removeEntityfromTable($event)"
                        (selectionEvent)="isSubmitbuttonenabled($event)"
                        (fileUploadEvent)="
                          fileUploadData(
                            $event,
                            control.key,
                            control.value.controls,
                            form,
                            i,
                            control
                          )
                        "
                        (dateChangedEmit)="dateChangedEvent($event)"
                      >
                      </app-vertical-dynamic-ui-table>
                      <div
                        class="progressBar"
                        *ngIf="getConfigurationProgressBar(control)"
                      >
                        <app-progress-bar-configuration [control]="control">
                        </app-progress-bar-configuration>
                      </div>
                      <app-canvasjs-display
                        *ngFor="let key of entityNames"
                        [chartType]="entityWidgetData[key].chartType"
                        [entityName]="entityWidgetData[key].name"
                        [data]="entityWidgetData[key].data"
                        [entityWidget]="entityWidgetData"
                        [control]="control"
                        [transaction]="true"
                        [displayName]="
                          entityDisplayName.has(key)
                            ? entityDisplayName.get(key)
                            : ''
                        "
                      ></app-canvasjs-display>
                    </div>
                    <ng-template #is_card>
                      <app-dynamic-ui-card
                        *ngIf="
                          control?.value?.value?.configurationType !== 'widgets'
                        "
                        [isInfo]="isInfo"
                        [control]="control"
                        [selectedCardsList]="selectedCards"
                        [eventCUList]="currentCu?.eventCUList"
                        [tempEntityList]="tempEntityList"
                        [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                        (selectedCardsEmit)="getSelectedCards($event)"
                        (removeEntity)="removeEntityfromTable($event)"
                        (selectionEvent)="isSubmitbuttonenabled($event)"
                        (pageChanged)="pageChangedInTable($event)"
                        (dateChangedEmit)="dateChangedEvent($event)"
                      ></app-dynamic-ui-card>
                    </ng-template>
                    <!-- <ng-template> </ng-template> -->
                  </div>
                  <ng-template #not_table>
                    <app-nested-form
                      *ngIf="
                        control?.value?.value?.configurationType !== 'accordion'
                      "
                      class="nested-dynamic-form row"
                      [ngClass]="{
                        'size-extra-large-uicontrols' : screenWidth > 1920,
                        'size-medium-uicontrols' : screenWidth > 1366 && screenWidth <= 1440 ,
                        'size-small-uicontrols' : screenWidth >=356 && screenWidth <= 1366
                      }"
                      [control]="control"
                      [group]="form"
                      [isInfo]="isInfo"
                      [eventCUList]="currentCu?.eventCUList"
                      (formValueChange)="addDynamicAttributes($event)"
                      (dateChangedEmit)="dateChangedEvent($event)"
                      (nestedEntityAdded)="nestedEntitiesAdded($event)"
                      (disableSubmitEmit)="disableSubmitEvent($event)"
                      (nestedEntityTableSelections)="
                        getSelectedTransactions($event)
                      "
                      (nestedEntitySelections)="getSelectedCards($event)"
                      (nestedEntityRemoveTableSelections)="
                        removeEntityfromTable($event)
                      "
                      (removeNestedEntity)="removeEntityfromTable($event)"
                      (waypointsData)="getWayPointsData($event)"
                    >
                    </app-nested-form>
                  </ng-template>
                </div>

                <ng-template #other_form>
                  <app-basic-form
                    [control]="control"
                    [group]="form"
                    [isReadOnly]="isReadOnly"
                    (formValueChange)="addDynamicAttributes($event)"
                    (dateChangedEmit)="dateChangedEvent($event)"
                    (disableSubmitEmit)="disableSubmitEvent($event)"
                    (waypointsData)="getWayPointsData($event)"
                  >
                  </app-basic-form>
                </ng-template>
                <div
                  class="entity-tab-btns"
                  *ngIf="showMatTabForEntity() && keysLength(form?.controls)"
                >
                  <button
                    type="button"
                    class="text-center rounded-circle"
                    *ngIf="
                      selectedIndex != 0 &&
                      selectedIndex != firstUnhiddenEntIndex
                    "
                    (click)="previousAccordian(selectedIndex)"
                  >
                    <!-- {{ labels?.Previous }} -->
                    <em class="nh-icon nh-icon-chevron-left"></em>
                  </button>
                  <button
                    type="button"
                    *ngIf="
                      selectedIndex != keysLength(form?.controls) &&
                      LastUnhiddenEntIndex != -1 &&
                      selectedIndex != LastUnhiddenEntIndex
                    "
                    (click)="nextAccordian(selectedIndex)"
                    class="text-center rounded-circle"
                  >
                    <!-- {{ labels?.Next }} -->
                    <em class="nh-icon nh-icon-chevron-right"></em>
                  </button>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </ng-template>
      </div>
      <ng-container *ngIf="!showMatTabForEntity()">
        <div
          class="parent-accordion-cls"
          [ngClass]="{ row: isEntityClass }"
          *ngIf="!iscarnivalscreen"
        >
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName !== 'layout-enterprise-EMRI' &&
              layoutConfigurations?.templateName !==
                'layout-enterprise-EMRI-02' &&
              layoutConfigurations?.templateName !==
                'layout-enterprise-sanborns' &&
              layoutConfigurations?.templateName !==
                'layout-question-and-answer' &&
              layoutConfigurations?.templateName !==
                'layout-enterprise-sanborns-02' &&
              layoutConfigurations?.templateName !==
                'layout-enterprise-sanborns-03' &&
              layoutConfigurations?.templateName !==
                'layout-enterprise-84-phy-phy'
            "
          >
            <div
              class="form-model"
              *ngFor="
                let control of form?.controls | keyvalue: originalOrder;
                trackBy: trackByFn;
                let i = index
              "
              id="{{ layer }}_txnEntityExpan_{{ control.key | split: '$' }}_{{
                i
              }}"
              style="overflow: visible;"
              [ngClass]="{
                hide: checkForHidden(form?.controls, form?.value, i),
                'form-model-hidelabel':
                  form.value[control.key]?.entityHideLabel,
                'd-none':
                  layoutConfigurations?.templateName ===
                    'layout-enterprise-flatworld' && i === 2,
                'nh-display-none':
                  form.value[control.key]?.isEmptyEntity &&
                  !form.value[control.key]?.isPlaceholder
              }"
              class="{{ form.value[control.key]?.entityClass }}"
            >
              <ng-template
                *ngIf="
                  (layoutConfigurations?.templateName ===
                    'layout-enterprise-flatworld' &&
                    i != 1 &&
                    i != 2) ||
                  layoutConfigurations?.templateName !==
                    'layout-enterprise-flatworld'
                "
                [ngTemplateOutlet]="entityDiv"
                [ngTemplateOutletContext]="{ control: control, i: i }"
              ></ng-template>
              <div
                class="flatworld-div1"
                *ngIf="
                  layoutConfigurations?.templateName ===
                    'layout-enterprise-flatworld' && i == 1
                "
              >
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[1],
                    i: 1
                  }"
                ></ng-template>
              </div>
              <div
                class="flatworld-div2"
                *ngIf="
                  layoutConfigurations?.templateName ===
                    'layout-enterprise-flatworld' && i == 1
                "
              >
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[2],
                    i: 2
                  }"
                ></ng-template>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName === 'layout-enterprise-EMRI'
            "
          >
            <div class="emri-div1">
              <div class="emri-div1-inner">
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[0],
                    i: 0
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[1],
                    i: 1
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[2],
                    i: 2
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[3],
                    i: 3
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[4],
                    i: 4
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[5],
                    i: 5
                  }"
                ></ng-template>
              </div>
            </div>
            <div class="emri-div2">
              <div class="emri-div2-inner">
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[6],
                    i: 6
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[7],
                    i: 7
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[8],
                    i: 8
                  }"
                ></ng-template>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName === 'layout-enterprise-EMRI-02'
            "
          >
            <div class="emri-div1">
              <div class="emri-div1-inner">
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[0],
                    i: 0
                  }"
                ></ng-template>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 1)
                  }"
                  class="form-model-content"
                >
                  <ng-template
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[1],
                      i: 1
                    }"
                  ></ng-template>
                </div>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[2],
                    i: 2
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[3],
                    i: 3
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[4],
                    i: 4
                  }"
                ></ng-template>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 5)
                  }"
                  class="form-model-content"
                >
                  <ng-template
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[5],
                      i: 5
                    }"
                  ></ng-template>
                </div>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 6)
                  }"
                  class="form-model-content"
                >
                  <ng-template
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[6],
                      i: 6
                    }"
                  ></ng-template>
                </div>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[7],
                    i: 7
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[8],
                    i: 8
                  }"
                ></ng-template>
              </div>
            </div>
            <div class="emri-div2">
              <div class="emri-div2-inner">
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[9],
                    i: 9
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[10],
                    i: 10
                  }"
                ></ng-template>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 11)
                  }"
                  class="form-model-content"
                >
                  <ng-template
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[11],
                      i: 11
                    }"
                  ></ng-template>
                </div>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 12)
                  }"
                  class="form-model-content"
                >
                  <ng-template
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[12],
                      i: 12
                    }"
                  ></ng-template>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName ===
              'layout-enterprise-sanborns'
            "
          >
            <div class="sanborns-div1">
              <div class="sanborns-div1-inner">
                <ng-template
                  *ngIf="form?.controls && (form.controls | keyvalue)?.[0]"
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[0],
                    i: 0
                  }"
                ></ng-template>
                <ng-template
                  *ngIf="form?.controls && (form.controls | keyvalue)?.[1]"
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[1],
                    i: 1
                  }"
                ></ng-template>
              </div>
            </div>
            <div class="sanborns-div2">
              <div class="sanborns-div2-inner">
                <ng-template
                  *ngIf="form?.controls && (form.controls | keyvalue)?.[2]"
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[2],
                    i: 2
                  }"
                ></ng-template>
                <ng-template
                  *ngIf="form?.controls && (form.controls | keyvalue)?.[3]"
                  [ngTemplateOutlet]="entityDiv"
                  [ngTemplateOutletContext]="{
                    control: (form?.controls | keyvalue: originalOrder)[3],
                    i: 3
                  }"
                ></ng-template>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName ===
              'layout-question-and-answer'
            "
          >
            <div class="question-div1">
              <div class="question-div1-inner">
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 0)
                  }"
                  class="form-model"
                >
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[0]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[0],
                      i: 0
                    }"
                  ></ng-template>
                </div>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 1)
                  }"
                  class="form-model"
                >
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[1]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[1],
                      i: 1
                    }"
                  ></ng-template>
                </div>
              </div>
            </div>
            <div class="question-div2">
              <div class="question-div2-inner">
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 2)
                  }"
                  class="form-model"
                >
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[2]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[2],
                      i: 2
                    }"
                  ></ng-template>
                </div>
                <div
                  [ngClass]="{
                    hide: checkForHidden(form?.controls, form?.value, 3)
                  }"
                  class="form-model"
                >
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[3]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[3],
                      i: 3
                    }"
                  ></ng-template>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName ===
              'layout-enterprise-sanborns-02'
            "
          >
            <div class="sanbornsLayout-02 row">
              <div class="sanborns-div1 col-lg-9">
                <div class="sanborns-div1-inner">
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[0]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[0],
                      i: 0
                    }"
                  ></ng-template>
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[3]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[3],
                      i: 3
                    }"
                  ></ng-template>
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[4]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[4],
                      i: 4
                    }"
                  ></ng-template>
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[5]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[5],
                      i: 5
                    }"
                  ></ng-template>
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[6]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[6],
                      i: 6
                    }"
                  ></ng-template>
                </div>
              </div>
              <div class="sanborns-div2 col-lg-3">
                <div class="sanborns-div2-inner">
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[1]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[1],
                      i: 1
                    }"
                  ></ng-template>
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[2]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{
                      control: (form?.controls | keyvalue: originalOrder)[2],
                      i: 2
                    }"
                  ></ng-template>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName ===
              'layout-enterprise-sanborns-03'
            "
          >
            <div class="sanbornsLayout row">
              <div class="sanbornsLayout-left col-lg-9">
                <div class="sanbornsLayout-inner">
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 0)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && ( form.controls | keyvalue)?.[lengthofForm-1]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[
                          lengthofForm - 1
                        ],
                        i: lengthofForm - 1
                      }"
                    ></ng-template>
                  </div>
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 0)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && (form.controls | keyvalue)?.[0]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[0],
                        i: 0
                      }"
                    ></ng-template>
                  </div>
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 3)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && (form.controls | keyvalue)?.[3]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[3],
                        i: 3
                      }"
                    ></ng-template>
                  </div>

                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 4)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && (form.controls | keyvalue)?.[4]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[4],
                        i: 4
                      }"
                    ></ng-template>
                  </div>
                  <ng-container
                    *ngFor="
                      let control of form?.controls | keyvalue: originalOrder;
                      trackBy: trackByFn;
                      let i = index
                    "
                  >
                    <div
                      *ngIf="i >= 5 && i < lengthofForm - 1"
                      [ngClass]="{
                        hide: checkForHidden(form?.controls, form?.value, i),
                        'form-model-hidelabel':
                          form.value[control.key]?.entityHideLabel,
                        'd-none':
                          layoutConfigurations?.templateName ===
                            'layout-enterprise-flatworld' && i === 2,
                        'nh-display-none':
                          form.value[control.key]?.isEmptyEntity
                      }"
                    >
                      <ng-template
                        *ngIf="form?.controls && (form.controls | keyvalue)?.[i]"
                        [ngTemplateOutlet]="entityDiv"
                        [ngTemplateOutletContext]="{
                          control: (form?.controls | keyvalue: originalOrder)[
                            i
                          ],
                          i: i
                        }"
                      ></ng-template>
                    </div>
                  </ng-container>

                  <!--
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 6)
                    }" >
                  <ng-template
                    *ngIf="form?.controls && (form.controls | keyvalue)?.[6]"
                    [ngTemplateOutlet]="entityDiv"
                    [ngTemplateOutletContext]="{ control: (form?.controls | keyvalue: originalOrder)[6], i: 6 }"
                  ></ng-template> </div> -->
                </div>
              </div>
              <div class="sanbornsLayout-right col-lg-3">
                <div class="sanbornsLayout-inner">
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 1)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && (form.controls | keyvalue)?.[1]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[1],
                        i: 1
                      }"
                    ></ng-template>
                  </div>

                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 2)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && (form.controls | keyvalue)?.[2]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[2],
                        i: 2
                      }"
                    ></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              layoutConfigurations?.templateName ===
              'layout-enterprise-84-phy-phy'
            "
          >
            <div class="cuLayoutCustom nh-display-flex nh-flex-wrap">
              <div class="cuLayoutCustom-left">
                <div class="cuLayoutCustom-inner">
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 0)
                    }"
                  >
                    <ng-template
                      *ngIf="form?.controls && ( form.controls | keyvalue: originalOrder)?.[0]"
                      [ngTemplateOutlet]="entityDiv"
                      [ngTemplateOutletContext]="{
                        control: (form?.controls | keyvalue: originalOrder)[0],
                        i: 0
                      }"
                    ></ng-template>
                  </div>
                </div>
              </div>
              <div class="cuLayoutCustom-right">
                <div class="cuLayoutCustom-inner">
                  <div
                    [ngClass]="{
                      hide: checkForHidden(form?.controls, form?.value, 1)
                    }"
                  >
                    <ng-container
                      *ngFor="
                        let control of form.controls | keyvalue: originalOrder;
                        let i = index
                      "
                    >
                      <ng-template
                        *ngIf="i > 0"
                        [ngTemplateOutlet]="entityDiv"
                        [ngTemplateOutletContext]="{ control, i }"
                      ></ng-template>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #entityDiv let-control="control" let-i="i">
        <div class="form-model-content" *ngIf="control">
          <div
            (click)="currentOpendEntity(i)"
            *ngIf="
              (showEntityName || isInfo) &&
              control?.value?.value?.configurationType !== 'accordion'
            "
          >
            <div
              class="rightpanel-heading"
              *ngIf="!form.value[control.key]?.entityHideLabel"
            >
              <img
                (error)="imgError($event)"
                *ngIf="templateType !== 'transaction-book-menu'"
                height="34"
                [src]="form.value[control.key]?.entityicon"
                alt=""
                id="entityIcon_{{
                  form.value[control.key]?.displayName
                    ? form.value[control.key]?.displayName
                    : (control.key | split: '$')
                }}_{{
                  getFileNameFromImgUrl(form.value[control.key]?.entityicon)
                }}"
              />
              <span
                class="entity-font"
                id="{{ fontFamily }}"
                pTooltip="{{
                  form.value[control.key]?.displayName
                    ? form.value[control.key]?.displayName
                    : (control.key | split: '$')
                }}"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
              >
                {{
                  form.value[control.key]?.displayName
                    ? form.value[control.key]?.displayName
                    : (control.key | split: "$")
                }}
              </span>
              <div
                class="rightpanel-heading-iconfile"
                *ngIf="checkmultipleFileDownloadIndex.includes(control.key)"
              >
                <button
                  *ngIf="checkmultipleFileDownloadIndex.includes(control.key)"
                  matSuffix
                  (click)="downloadAllMultipleFilesAtOnce(control.key)"
                  class="nh-unsetAll cursor-pointer"
                >
                  <em class="nh-icon nh-icon-cloud-arrow-down-fill"></em>
                </button>
                <button
                  *ngIf="checkmultipleFileDownloadIndex.includes(control.key)"
                  matSuffix
                  (click)="viewAllMultipleFilesAtOnce(control.key)"
                  class="nh-unsetAll cursor-pointer"
                >
                  <em class="nh-icon nh-icon-eye-fill"></em>
                </button>
              </div>
              <div
                class="rightpanel-heading-multiple"
                *ngIf="
                  form.value[control.key]?.isMulti &&
                  !isMultiHidden(form.value[control.key]?.configuration)
                "
              >
                <span
                  *ngIf="
                    form.value[control.key]?.isMulti &&
                    !isMultiHidden(form.value[control.key]?.configuration)
                  "
                  class="add-icon"
                  id="add_isMulti{{ form.value[control.key]?.displayName }}_{{
                    i
                  }}_displayNonMatTabs"
                  (click)="
                    addMultipleEntityControl(
                      control.key,
                      control.value.controls,
                      form,
                      i,
                      control
                    )
                  "
                >
                  <div
                    *ngIf="
                      getMultiEntityButton(
                        form.value[control.key]?.configuration
                      );
                      else icon
                    "
                  >
                    <button
                      class="nh-unsetAll"
                      [ngClass]="{
                        square: addButtonShape == 'Square',
                        circle: addButtonShape == 'Circle'
                      }"
                    >
                      {{
                        displayAddButton(form.value[control.key]?.configuration)
                          ? displayAddButton(
                              form.value[control.key]?.configuration
                            )
                          : "Add"
                      }}
                    </button>
                  </div>
                  <ng-template #icon>
                    <em class="nh-icon nh-icon-plus-lg"></em>
                  </ng-template>
                </span>
                <span
                  *ngIf="
                    control.value.controls.ent_multiCount &&
                    control?.value?.value?.configurationType !== 'table' &&
                    control?.value?.value?.configurationType !==
                      'tableWithLabels' &&
                    control?.value?.value?.configurationType !==
                      'cardWithTemplate' &&
                    control?.value?.value?.configurationType !== 'card'
                  "
                  >{{ control.value.controls.ent_multiCount.value }}</span
                >
                <span
                  *ngIf="
                    form.value[control.key]?.isMulti &&
                    !isMultiHidden(form.value[control.key]?.configuration) &&
                    !(
                      control?.value?.value?.configurationType === 'table' ||
                      control?.value?.value?.configurationType === 'card' ||
                      control?.value?.value?.configurationType ===
                        'cardWithTemplate'
                    ) &&
                    getFormValueLength(control.key) > 1
                  "
                  class="remove-icon"
                  id="remove_isMulti{{
                    form.value[control.key]?.displayName
                  }}_{{ i }}_displayNonMatTabs"
                  (click)="
                    deleteEntityControl(
                      control.key,
                      control.value.controls,
                      form,
                      i
                    )
                  "
                  ><div
                    *ngIf="
                      getMultiEntityButton(
                        form.value[control.key]?.configuration
                      );
                      else icon
                    "
                  >
                    <button
                      class="nh-unsetAll"
                      [ngClass]="{
                        square: removeButtonShape == 'Square',
                        circle: removeButtonShape == 'Circle'
                      }"
                    >
                      {{
                        displayRemoveButton(
                          form.value[control.key]?.configuration
                        )
                          ? displayRemoveButton(
                              form.value[control.key]?.configuration
                            )
                          : "Remove"
                      }}
                    </button>
                  </div>
                  <ng-template #icon>
                    <em class="nh-icon nh-icon-dash-lg"></em>
                  </ng-template>
                </span>
              </div>
            </div>

            <div class="lrn-header-bar">
              <div class="lrn-header-bar-left">
                <h3>
                  <span>
                    {{
                      form.value[control.key]?.displayName
                        ? form.value[control.key]?.displayName
                        : (control.key | split: "$")
                    }}</span
                  >
                </h3>
              </div>
            </div>

            <div *ngIf="form.value[control.key]?.isPlaceholder">
              <mat-form-field appearance="none">
                <input
                  matInput
                  id="InputEwa"
                  class="input_ewa"
                  (keyup)="onKeyUp($event, control?.key)"
                  type="text"
                  [formControl]="EwaValidator"
                />
                <mat-error *ngIf="EwaValidator?.invalid"
                  >Invalid_input</mat-error
                >
              </mat-form-field>
            </div>
          </div>
          <div
            *ngIf="control?.value?.value?.entType === 'entity'; else other_form"
            class="form-model-inner"
          >
            <div *ngIf="getConfigurationType(control); else not_table">
              <div
                *ngIf="
                  control.value?.value.configurationType === 'table' ||
                    control?.value?.value?.configurationType == 'widgets' ||
                    control?.value?.value?.configurationType ==
                      'tableWithLabels' ||
                    control.value.value.configurationType === 'verticalTable' ||
                    control.value.value.configurationType === 'progressBar';
                  else is_card
                "
                class="tbl-dynamic-ui"
              >
                <app-dynamic-ui-table
                  *ngIf="control?.value?.value?.configurationType === 'table'"
                  [control]="control"
                  [isInfo]="isInfo"
                  [selectedTransactionArray]="selectedTableRows"
                  [eventCUList]="currentCu?.eventCUList"
                  (pageChanged)="pageChangedInTable($event)"
                  [tempEntityList]="tempEntityList"
                  [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                  [rowNumberChanged]="rowNumberChanged"
                  [isFacetSearchCu]="isFacetSearchCu"
                  [facetedData]="facetedData"
                  [filterData]="filterData"
                  (rowNumber)="rowNumberChange($event)"
                  (selectedTransactionsEmit)="getSelectedTransactions($event)"
                  (removeEntity)="removeEntityfromTable($event)"
                  (selectionEvent)="isSubmitbuttonenabled($event)"
                  (tableUploaded)="tableUploaded($event)"
                  (fileUploadEvent)="
                    fileUploadData(
                      $event,
                      control.key,
                      control.value.controls,
                      form,
                      i,
                      control
                    )
                  "
                  [wayPointsData]="wayPointsData"
                  (emitWaypointstoUpdateForm)="updateWaypointsinForm($event)"
                  (dateChangedEmit)="dateChangedEvent($event)"
                  (updateTableData)="updateTableData($event)"
                  (selectedEditableRow)="getSelectedEditableRow($event)"
                  [selectedEditRow]="selectedEditableRow"
                >
                </app-dynamic-ui-table>
                <app-vertical-dynamic-ui-table
                  *ngIf="
                    control?.value?.value?.configurationType === 'verticalTable'
                  "
                  [control]="control"
                  [isInfo]="isInfo"
                  [selectedTransactionArray]="selectedTableRows"
                  (tableUploaded)="tableUploaded($event)"
                  [eventCUList]="currentCu?.eventCUList"
                  (pageChanged)="pageChangedInTable($event)"
                  [rowNumberChanged]="rowNumberChanged"
                  (rowNumber)="rowNumberChange($event)"
                  (selectedTransactionsEmit)="getSelectedTransactions($event)"
                  (removeEntity)="removeEntityfromTable($event)"
                  (selectionEvent)="isSubmitbuttonenabled($event)"
                  (fileUploadEvent)="
                    fileUploadData(
                      $event,
                      control.key,
                      control.value.controls,
                      form,
                      i,
                      control
                    )
                  "
                  (dateChangedEmit)="dateChangedEvent($event)"
                >
                </app-vertical-dynamic-ui-table>
                <div
                  class="progressBar"
                  *ngIf="
                    control.value.value.configurationType === 'progressBar'
                  "
                >
                  <app-progress-bar-configuration [control]="control">
                  </app-progress-bar-configuration>
                </div>
                <div
                  class="widgetConfig"
                  *ngIf="control?.value?.value?.configurationType == 'widgets'"
                >
                  <app-canvasjs-display
                    *ngFor="let key of entityNames"
                    [chartType]="entityWidgetData[key].chartType"
                    [entityName]="entityWidgetData[key].name"
                    [data]="entityWidgetData[key].data"
                    [entityWidget]="entityWidgetData"
                    [control]="control"
                    [transaction]="true"
                    [displayName]="
                      entityDisplayName.has(key)
                        ? entityDisplayName.get(key)
                        : ''
                    "
                  ></app-canvasjs-display>
                </div>
                <app-dynamic-ui-table-labels
                  *ngIf="
                    control?.value?.value?.configurationType ===
                    'tableWithLabels'
                  "
                  [control]="control"
                  [isInfo]="isInfo"
                  [selectedTransactionArray]="selectedTableRows"
                  [eventCUList]="currentCu?.eventCUList"
                  (pageChanged)="pageChangedInTable($event)"
                  [rowNumberChanged]="rowNumberChanged"
                  [tempEntityList]="tempEntityList"
                  [isFacetSearchCu]="isFacetSearchCu"
                  [facetedData]="facetedData"
                  [filterData]="filterData"
                  [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                  (rowNumber)="rowNumberChange($event)"
                  (selectedTransactionsEmit)="getSelectedTransactions($event)"
                  (removeEntity)="removeEntityfromTable($event)"
                  (selectionEvent)="isSubmitbuttonenabled($event)"
                  (tableUploaded)="tableUploaded($event)"
                  (fileUploadEvent)="
                    fileUploadData(
                      $event,
                      control.key,
                      control.value.controls,
                      form,
                      i,
                      control
                    )
                  "
                ></app-dynamic-ui-table-labels>
              </div>
              <ng-template #is_card>
                <app-dynamic-ui-card
                  *ngIf="
                    control?.value?.value?.configurationType !== 'widgets' &&
                    control.value.value.configurationType !== 'tableWithLabels'
                  "
                  [isInfo]="isInfo"
                  [control]="control"
                  [selectedCardsList]="selectedCards"
                  [tempEntityList]="tempEntityList"
                  [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                  [eventCUList]="currentCu?.eventCUList"
                  (selectedCardsEmit)="getSelectedCards($event)"
                  (removeEntity)="removeEntityfromTable($event)"
                  (selectionEvent)="isSubmitbuttonenabled($event)"
                  (pageChanged)="pageChangedInTable($event)"
                  (dateChangedEmit)="dateChangedEvent($event)"
                ></app-dynamic-ui-card>
              </ng-template>
              <!-- <ng-template> </ng-template> -->
            </div>
            <ng-template #not_table>
              <ng-container
                *ngIf="
                  control?.value?.value?.configurationType == 'accordion' &&
                  !control?.key?.includes('NSL_FileBrowse')
                "
              >
                <mat-expansion-panel class="mat-elevation-z0 cstm-expansion">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div
                        class="rightpanel-heading rightpanel-heading-panel"
                        *ngIf="!form.value[control.key]?.entityHideLabel"
                      >
                        <img
                          (error)="imgError($event)"
                          [src]="form.value[control.key]?.entityicon"
                          alt=""
                          id="entityIcon_{{
                            form.value[control.key]?.displayName
                              ? form.value[control.key]?.displayName
                              : (control.key | split: '$')
                          }}_{{
                            getFileNameFromImgUrl(
                              form.value[control.key]?.entityicon
                            )
                          }}"
                        />
                        <span
                          class="entity-font"
                          id="{{ fontFamily }}"
                          pTooltip="{{
                            form.value[control.key]?.displayName
                              ? form.value[control.key]?.displayName
                              : (control.key | split: '$')
                          }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{
                            form.value[control.key]?.displayName
                              ? form.value[control.key]?.displayName
                              : (control.key | split: "$")
                          }}
                        </span>
                        <div
                          class="rightpanel-heading-multiple"
                          *ngIf="
                            form.value[control.key]?.isMulti &&
                            !isMultiHidden(
                              form.value[control.key]?.configuration
                            )
                          "
                        >
                          <span
                            *ngIf="form.value[control.key]?.isMulti"
                            class="add-icon"
                            id="add_isMulti{{
                              form.value[control.key]?.displayName
                            }}_{{ i }}_not_table"
                            (click)="
                              addMultipleEntityControl(
                                control.key,
                                control.value.controls,
                                form,
                                i,
                                control
                              )
                            "
                          >
                            <div
                              *ngIf="
                                getMultiEntityButton(
                                  form.value[control.key]?.configuration
                                );
                                else icon
                              "
                            >
                              <button
                                [ngClass]="{
                                  square: addButtonShape == 'Square',
                                  circle: addButtonShape == 'Circle'
                                }"
                              >
                                {{
                                  displayAddButton(
                                    form.value[control.key]?.configuration
                                  )
                                    ? displayAddButton(
                                        form.value[control.key]?.configuration
                                      )
                                    : "Add"
                                }}
                              </button>
                            </div>
                            <ng-template #icon>
                              <em class="nh-icon nh-icon-plus-lg"></em>
                            </ng-template>
                          </span>
                          <span
                            *ngIf="
                              control.value.controls.ent_multiCount &&
                              control?.value?.value?.configurationType !==
                                'table' &&
                              control?.value?.value?.configurationType !==
                                'tableWithLabels' &&
                              control?.value?.value?.configurationType !==
                                'cardWithTemplate' &&
                              control?.value?.value?.configurationType !==
                                'card'
                            "
                            >{{
                              control.value.controls.ent_multiCount.value
                            }}</span
                          >
                          <span
                            *ngIf="
                              form.value[control.key]?.isMulti &&
                              !(
                                control?.value?.value?.configurationType ===
                                  'table' ||
                                control?.value?.value?.configurationType ===
                                  'card' ||
                                control?.value?.value?.configurationType ===
                                  'cardWithTemplate'
                              ) &&
                              getFormValueLength(control.key) > 1
                            "
                            class="remove-icon"
                            id="remove_isMulti{{
                              form.value[control.key]?.displayName
                            }}_{{ i }}_not_table"
                            (click)="
                              deleteEntityControl(
                                control.key,
                                control.value.controls,
                                form,
                                i
                              )
                            "
                            ><div
                              *ngIf="
                                getMultiEntityButton(
                                  form.value[control.key]?.configuration
                                );
                                else icon
                              "
                            >
                              <button
                                [ngClass]="{
                                  square: removeButtonShape == 'Square',
                                  circle: removeButtonShape == 'Circle'
                                }"
                              >
                                {{
                                  displayRemoveButton(
                                    form.value[control.key]?.configuration
                                  )
                                    ? displayRemoveButton(
                                        form.value[control.key]?.configuration
                                      )
                                    : "Remove"
                                }}
                              </button>
                            </div>
                            <ng-template #icon>
                              <em class="nh-icon nh-icon-dash-lg"></em>
                            </ng-template>
                          </span>
                        </div>
                      </div>

                      <div class="lrn-header-bar">
                        <div class="lrn-header-bar-left">
                          <h3>
                            <span>
                              {{
                                form.value[control.key]?.displayName
                                  ? form.value[control.key]?.displayName
                                  : (control.key | split: "$")
                              }}</span
                            >
                          </h3>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="">
                    <!-- <mat-form-field appearance="fill" class="cstm-form-field">
            <mat-label>Select the Language</mat-label>
            <mat-select>
              <mat-option>
                English
              </mat-option>
              <mat-option>
                Not English
              </mat-option>
            </mat-select>
          </mat-form-field> -->
                    <app-nested-form
                      class="nested-dynamic-form row"
                      [ngClass]="{
                        'size-extra-large-uicontrols' : screenWidth > 1920,
                        'size-medium-uicontrols' : screenWidth > 1366 && screenWidth <= 1440 ,
                        'size-small-uicontrols' : screenWidth >=356 && screenWidth <= 1366
                      }"
                      [control]="control"
                      [group]="form"
                      [isInfo]="isInfo"
                      [eventCUList]="currentCu?.eventCUList"
                      (formValueChange)="addDynamicAttributes($event)"
                      (dateChangedEmit)="dateChangedEvent($event)"
                      (nestedEntityAdded)="nestedEntitiesAdded($event)"
                      (disableSubmitEmit)="disableSubmitEvent($event)"
                      (nestedEntityTableSelections)="
                        getSelectedTransactions($event)
                      "
                      (nestedEntitySelections)="getSelectedCards($event)"
                      (nestedEntityRemoveTableSelections)="
                        removeEntityfromTable($event)
                      "
                      (removeNestedEntity)="removeEntityfromTable($event)"
                      (waypointsData)="getWayPointsData($event)"
                    >
                    </app-nested-form>
                  </div>
                </mat-expansion-panel>
                <!-- <mat-expansion-panel class="mat-elevation-z0 cstm-expansion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Location
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="">
          <mat-form-field appearance="fill" class="cstm-form-field">
            <mat-label>Search Location</mat-label>
            <input matInput />
            <mat-icon matSuffix>location_on</mat-icon>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0 cstm-expansion">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Campaign Setting
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel> -->
              </ng-container>
              <app-nested-form
                *ngIf="
                  control?.value?.value?.configurationType !== 'accordion' &&
                  !control?.key?.includes('NSL_FileBrowse')
                "
                [ngClass]="{
                  'size-extra-large-uicontrols' : screenWidth > 1920,
                  'size-medium-uicontrols' : screenWidth > 1366 && screenWidth <= 1440 ,
                  'size-small-uicontrols' : screenWidth >=356 && screenWidth <= 1366
                }"
                class="nested-dynamic-form row"
                [control]="control"
                [group]="form"
                [isInfo]="isInfo"
                [eventCUList]="currentCu?.eventCUList"
                (formValueChange)="addDynamicAttributes($event)"
                (dateChangedEmit)="dateChangedEvent($event)"
                (nestedEntityAdded)="nestedEntitiesAdded($event)"
                (disableSubmitEmit)="disableSubmitEvent($event)"
                (nestedEntityTableSelections)="getSelectedTransactions($event)"
                (nestedEntitySelections)="getSelectedCards($event)"
                (nestedEntityRemoveTableSelections)="
                  removeEntityfromTable($event)
                "
                (removeNestedEntity)="removeEntityfromTable($event)"
                (waypointsData)="getWayPointsData($event)"
              >
              </app-nested-form>
              <div
                *ngIf="
                  showDownloadButtonForEntity &&
                  !control?.key?.includes('NSL_FileBrowse')
                "
              >
                <button
                  class="downloadAllButton"
                  id="downloadButton"
                  (click)="downloadAllFiles(control?.value?.value?.txnRecordId)"
                >
                  {{ labels?.download_all }}
                </button>
              </div>
              <app-folder-upload
                *ngIf="control?.key?.includes('NSL_FileBrowse')"
                [control]="control"
              ></app-folder-upload>
            </ng-template>
          </div>
        </div>
        <ng-template #other_form>
          <app-basic-form
            [control]="control"
            [group]="form"
            [isReadOnly]="isReadOnly"
            (formValueChange)="addDynamicAttributes($event)"
            (dateChangedEmit)="dateChangedEvent($event)"
            (disableSubmitEmit)="disableSubmitEvent($event)"
            (waypointsData)="getWayPointsData($event)"
          >
          </app-basic-form>
        </ng-template>
      </ng-template>

      <div class="parent-accordion-cls" *ngIf="iscarnivalscreen">
        <div class="d-flex choose-cu">
          <div
            *ngFor="
              let control of form?.controls | keyvalue: originalOrder;
              trackBy: trackByFn;
              let i = index
            "
            id="txnEntityExpan_{{ control.key | split: '$' }}_{{ i }}"
            style="overflow: visible;"
            (click)="currentOpendEntity(i)"
          >
            <span
              [ngClass]="
                currentEntityIndex == i
                  ? 'text-dark choose-cu selected-size'
                  : 'text-dark choose-cu'
              "
            >
              {{
                form.value[control.key].displayName
                  ? form.value[control.key].displayName
                  : (control.key | split: "$")
              }}
            </span>
          </div>
        </div>
        <div
          *ngFor="
            let control of form?.controls | keyvalue: originalOrder;
            trackBy: trackByFn;
            let i = index
          "
          id="txnEntityExpan_{{ control.key | split: '$' }}_{{ i }}"
          style="overflow: visible;"
          [ngClass]="{ hide: checkForHidden(form?.controls, form?.value, i) }"
        >
          <div *ngIf="currentEntityIndex == i">
            <div>
              <div
                class="rightpanel-heading"
                *ngIf="!form.value[control.key]?.entityHideLabel"
              >
                <img
                  (error)="imgError($event)"
                  height="34"
                  [src]="form.value[control.key].entityicon"
                  alt=""
                />
                <!-- <span class="text-dark">
                {{
                  form.value[control.key].displayName ? form.value[control.key].displayName : (control.key | split: '$')
                }}
              </span> -->
                <div
                  class="rightpanel-heading-multiple"
                  *ngIf="
                    form.value[control.key].isMulti &&
                    control?.value?.value?.configurationType !== 'table' &&
                    control?.value?.value?.configurationType !==
                      'tableWithLabels'
                  "
                >
                  <span
                    *ngIf="form.value[control.key].isMulti"
                    class="add-icon"
                    id="add_isMulti{{ form.value[control.key]?.displayName }}_{{
                      i
                    }}"
                    (click)="
                      addMultipleEntityControl(
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                  >
                    <div
                      *ngIf="
                        getMultiEntityButton(
                          form.value[control.key]?.configuration
                        );
                        else icon
                      "
                    >
                      <button
                        [ngClass]="{
                          square: addButtonShape == 'Square',
                          circle: addButtonShape == 'Circle'
                        }"
                      >
                        {{
                          displayAddButton(
                            form.value[control.key]?.configuration
                          )
                            ? displayAddButton(
                                form.value[control.key]?.configuration
                              )
                            : "Add"
                        }}
                      </button>
                    </div>
                    <ng-template #icon>
                      <em class="nh-icon nh-icon-plus-lg"></em>
                    </ng-template>
                  </span>
                  <span
                    *ngIf="
                      control.value.controls.ent_multiCount &&
                      control?.value?.value?.configurationType !== 'table' &&
                      control?.value?.value?.configurationType !==
                        'tableWithLabels' &&
                      control?.value?.value?.configurationType !==
                        'cardWithTemplate' &&
                      control?.value?.value?.configurationType !== 'card'
                    "
                    >{{ control.value.controls.ent_multiCount.value }}</span
                  >
                  <span
                    *ngIf="
                      form.value[control.key].isMulti &&
                      control?.value?.value?.configurationType !== 'table' &&
                      getFormValueLength(control.key) > 1 &&
                      control?.value?.value?.configurationType !==
                        'tableWithLabels'
                    "
                    class="remove-icon"
                    id="remove_isMulti{{
                      form.value[control.key]?.displayName
                    }}_{{ i }}"
                    (click)="
                      deleteEntityControl(
                        control.key,
                        control.value.controls,
                        form,
                        i
                      )
                    "
                    ><div
                      *ngIf="
                        getMultiEntityButton(
                          form.value[control.key]?.configuration
                        );
                        else icon
                      "
                    >
                      <button
                        [ngClass]="{
                          square: removeButtonShape == 'Square',
                          circle: removeButtonShape == 'Circle'
                        }"
                      >
                        {{
                          displayRemoveButton(
                            form.value[control.key]?.configuration
                          )
                            ? displayRemoveButton(
                                form.value[control.key]?.configuration
                              )
                            : "Remove"
                        }}
                      </button>
                    </div>
                    <ng-template #icon>
                      <em class="nh-icon nh-icon-dash-lg"></em>
                    </ng-template>
                  </span>
                </div>
              </div>

              <div class="lrn-header-bar">
                <div class="lrn-header-bar-left">
                  <h3>
                    <span>
                      {{
                        form.value[control.key].displayName
                          ? form.value[control.key].displayName
                          : (control.key | split: "$")
                      }}</span
                    >
                  </h3>
                </div>
              </div>
            </div>

            <div
              *ngIf="
                control?.value?.value?.entType === 'entity';
                else other_form
              "
            >
              <div *ngIf="getConfigurationType(control); else not_table">
                <div
                  *ngIf="
                    control?.value?.value?.configurationType === 'table' ||
                      control?.value?.value?.configurationType ===
                        'tableWithLabels' ||
                      control?.value?.value?.configurationType ===
                        'verticalTable' ||
                      control.value.value.configurationType === 'progressBar';
                    else is_card
                  "
                >
                  <app-dynamic-ui-table
                    *ngIf="control?.value?.value?.configurationType === 'table'"
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedTransactionArray]="selectedTableRows"
                    (tableUploaded)="tableUploaded($event)"
                    [eventCUList]="currentCu?.eventCUList"
                    (pageChanged)="pageChangedInTable($event)"
                    [tempEntityList]="tempEntityList"
                    [rowNumberChanged]="rowNumberChanged"
                    [isFacetSearchCu]="isFacetSearchCu"
                    [facetedData]="facetedData"
                    [filterData]="filterData"
                    [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                    (rowNumber)="rowNumberChange($event)"
                    (selectedTransactionsEmit)="getSelectedTransactions($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (fileUploadEvent)="
                      fileUploadData(
                        $event,
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                    [wayPointsData]="wayPointsData"
                    (emitWaypointstoUpdateForm)="updateWaypointsinForm($event)"
                    (dateChangedEmit)="dateChangedEvent($event)"
                    (updateTableData)="updateTableData($event)"
                    (selectedEditableRow)="getSelectedEditableRow($event)"
                    [selectedEditRow]="selectedEditableRow"
                  >
                  </app-dynamic-ui-table>
                  <app-vertical-dynamic-ui-table
                    *ngIf="
                      control?.value?.value?.configurationType ===
                      'verticalTable'
                    "
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedTransactionArray]="selectedTableRows"
                    (tableUploaded)="tableUploaded($event)"
                    [eventCUList]="currentCu?.eventCUList"
                    (pageChanged)="pageChangedInTable($event)"
                    [rowNumberChanged]="rowNumberChanged"
                    (rowNumber)="rowNumberChange($event)"
                    (selectedTransactionsEmit)="getSelectedTransactions($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (fileUploadEvent)="
                      fileUploadData(
                        $event,
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                    (dateChangedEmit)="dateChangedEvent($event)"
                  >
                  </app-vertical-dynamic-ui-table>
                  <div
                    class="progressBar"
                    *ngIf="
                      control.value.value.configurationType === 'progressBar'
                    "
                  >
                    <app-progress-bar-configuration [control]="control">
                    </app-progress-bar-configuration>
                  </div>
                  <app-dynamic-ui-table-labels
                    *ngIf="
                      control?.value?.value?.configurationType ===
                      'tableWithLabels'
                    "
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedTransactionArray]="selectedTableRows"
                    [eventCUList]="currentCu?.eventCUList"
                    (pageChanged)="pageChangedInTable($event)"
                    [rowNumberChanged]="rowNumberChanged"
                    [tempEntityList]="tempEntityList"
                    [isFacetSearchCu]="isFacetSearchCu"
                    [facetedData]="facetedData"
                    [filterData]="filterData"
                    [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                    (rowNumber)="rowNumberChange($event)"
                    (selectedTransactionsEmit)="getSelectedTransactions($event)"
                    (removeEntity)="removeEntityfromTable($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (tableUploaded)="tableUploaded($event)"
                    (fileUploadEvent)="
                      fileUploadData(
                        $event,
                        control.key,
                        control.value.controls,
                        form,
                        i,
                        control
                      )
                    "
                  ></app-dynamic-ui-table-labels>
                </div>
                <ng-template #is_card>
                  <app-dynamic-ui-card
                    *ngIf="
                      control?.value?.value?.configurationType !== 'widgets' &&
                      control.value.value.configurationType !==
                        'tableWithLabels'
                    "
                    [control]="control"
                    [isInfo]="isInfo"
                    [selectedCardsList]="selectedCards"
                    [eventCUList]="currentCu?.eventCUList"
                    [tempEntityList]="tempEntityList"
                    [selectedRowTxnRecordIds]="selectedRowTxnRecordIds"
                    (selectedCardsEmit)="getSelectedCards($event)"
                    (selectionEvent)="isSubmitbuttonenabled($event)"
                    (pageChanged)="pageChangedInTable($event)"
                    (dateChangedEmit)="dateChangedEvent($event)"
                  ></app-dynamic-ui-card>
                </ng-template>
              </div>
              <ng-template #not_table>
                <app-nested-form
                  class="nested-dynamic-form row"
                  [ngClass]="{
                    'size-extra-large-uicontrols' : screenWidth > 1920,
                    'size-medium-uicontrols' : screenWidth > 1366 && screenWidth <= 1440 ,
                    'size-small-uicontrols' : screenWidth >=356 && screenWidth <= 1366
                  }"
                  [control]="control"
                  [group]="form"
                  [isInfo]="isInfo"
                  [eventCUList]="currentCu?.eventCUList"
                  (formValueChange)="addDynamicAttributes($event)"
                  (dateChangedEmit)="dateChangedEvent($event)"
                  (nestedEntityAdded)="nestedEntitiesAdded($event)"
                  (nestedEntityTableSelections)="
                    getSelectedTransactions($event)
                  "
                  (disableSubmitEmit)="disableSubmitEvent($event)"
                  (nestedEntityRemoveTableSelections)="
                    removeEntityfromTable($event)
                  "
                  (nestedEntitySelections)="getSelectedCards($event)"
                  (removeNestedEntity)="removeEntityfromTable($event)"
                  (waypointsData)="getWayPointsData($event)"
                >
                </app-nested-form>
              </ng-template>
            </div>

            <ng-template #other_form>
              <app-basic-form
                [control]="control"
                [group]="form"
                [isReadOnly]="isReadOnly"
                (formValueChange)="addDynamicAttributes($event)"
                (dateChangedEmit)="dateChangedEvent($event)"
                (disableSubmitEmit)="disableSubmitEvent($event)"
                (waypointsData)="getWayPointsData($event)"
              >
              </app-basic-form>
            </ng-template>
          </div>
        </div>
      </div>
    </ul>
    <ng-container
      *ngIf="
        layoutConfigurations?.templateName == 'layout-enterprise-CRM-phy' &&
        !isInfo
      "
    >
      <div class="form-model-thirdCol">
        <div class="form-model">
          <ng-template
            *ngIf="form?.controls && ( form.controls | keyvalue)?.[lengthofForm-1]"
            [ngTemplateOutlet]="entityDiv"
            [ngTemplateOutletContext]="{
              control: (form?.controls | keyvalue: originalOrder)[
                lengthofForm - 1
              ],
              i: lengthofForm - 1
            }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        layoutConfigurations?.templateName == 'layout-enterprise-RCP-phy-02' &&
        !isInfo
      "
    >
      <div class="form-model-thirdCol">
        <div
          class="form-model"
          [ngClass]="{
            hide: checkForHidden(form?.controls, form?.value, lengthofForm - 1)
          }"
        >
          <ng-template
            *ngIf="form?.controls && ( form.controls | keyvalue)?.[lengthofForm-1]"
            [ngTemplateOutlet]="entityDiv"
            [ngTemplateOutletContext]="{
              control: (form?.controls | keyvalue: originalOrder)[
                lengthofForm - 1
              ],
              i: lengthofForm - 1
            }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #myDialog>
    <div mat-dialog-content>
      <div class="dragNdrop add-additional">
        <h2>
          {{ labels?.Add_Additional_Information }}
          <div class="close-btn" mat-dialog-actions>
            <button mat-button mat-dialog-close>
              <mat-icon>close</mat-icon>
            </button>
            <button
              mat-button
              *ngIf="addEntityFromLib"
              (click)="displayForm('')"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </h2>
        <div class="info-wrap nogap" [ngClass]="{ nogap: addEntityFromLib }">
          <ul *ngIf="!addEntityFromLib">
            <p>{{ labels?.Additional_Information_will_be_part_of }}</p>
            <li>
              <button
                mat-stroked-button
                (click)="displayForm('createnewentity')"
                id="{{
                  showSubmit
                    ? 'Create_New_Entity_physical'
                    : 'Create_New_Entity_information'
                }}"
              >
                <span class="d-flex align-items-center"
                  ><mat-icon>add_circle</mat-icon
                  >{{ labels?.Create_New_Entity }}
                </span>
              </button>
            </li>
            <li>
              <button
                mat-stroked-button
                (click)="displayForm('addattribute')"
                id="{{
                  showSubmit
                    ? 'Add_Attribute_in_Existing_Entity_physical'
                    : 'Add_Attribute_in_Existing_Entity_information'
                }}"
              >
                <span class="d-flex align-items-center"
                  ><mat-icon>add_circle</mat-icon
                  >{{ labels?.Add_Attribute_in_Existing_Entity }}
                </span>
              </button>
            </li>
            <li>
              <button
                mat-stroked-button
                (click)="displayForm('addfromlib')"
                id="{{
                  showSubmit
                    ? 'Add_Entity_From_Library_physical'
                    : 'Add_Entity_From_Library_information'
                }}"
              >
                <span class="d-flex align-items-center"
                  ><mat-icon>add_circle</mat-icon
                  >{{ labels?.Add_Entity_From_Library }}
                </span>
              </button>
            </li>
          </ul>
          <ul class="newcuadd" *ngIf="!addEntityFromLib && !isInfo">
            <li>
              <button
                mat-stroked-button
                (click)="addSubCU()"
                id="{{
                  showSubmit
                    ? 'Create_New_Subtransactional_Change_Unit_physical'
                    : 'Create_New_Subtransactional_Change_Unit_information'
                }}"
              >
                <span class="d-flex align-items-center"
                  ><mat-icon>add_circle</mat-icon
                  >{{ labels.Create_New_Subtransactional_Change_Unit }}
                </span>
              </button>
              <span class="curtcu"
                >{{ labels.Within_Current_CU }} ({{ selectedGsi?.name }})</span
              >
            </li>
            <li class="expand-li" *ngIf="expandSubCU">
              <div class="selt-wrap">
                <h4>{{ labels.Select_Trigger }}</h4>
                <mat-accordion id="accordian" class="layeraccorodion">
                  <mat-expansion-panel
                    id="txnCuExpan_{{ currentCu.name }}"
                    class="mat-elevation-z0"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title class="libraryInnerList">
                        <span>{{ currentCu.name }} </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container
                      *ngFor="
                        let layer of currentCu?.layers;
                        let layerIndex = index
                      "
                    >
                      <mat-accordion class="playerdata header-0">
                        <mat-expansion-panel
                          id="txnLayerExpan_{{
                            currentCu.layers[layerIndex].type
                          }}"
                          class="mat-elevation-z0"
                        >
                          <mat-expansion-panel-header
                            *ngIf="
                              currentCu.layers[layerIndex]?.type == 'physical'
                            "
                          >
                            <mat-panel-title class="active-bold">
                              <span>{{ labels.Physical_Layer }}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-container
                            class="spl-entity"
                            *ngFor="
                              let entity of (currentCu?.layers)[layerIndex]
                                ?.entityList;
                              let i = index
                            "
                          >
                            <mat-accordion>
                              <mat-expansion-panel
                                id="txnEntityExpan_{{ entity.name }}"
                                class="mat-elevation-z0 cstm-radio"
                              >
                                <mat-expansion-panel-header>
                                  <mat-panel-title>
                                    <p>
                                      {{ entity.name }}
                                    </p>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div *ngIf="entity">
                                  <app-entity-radio-dcd
                                    [entityIndex]="i"
                                    [entity]="entity"
                                    [entityName]="entity?.name"
                                    (attributeEmit)="attributeToEmit($event)"
                                  >
                                  </app-entity-radio-dcd>
                                </div>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </ng-container>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
                <button mat-button mat-dialog-close (click)="gotoSolution()">
                  {{ labels.Select }}
                </button>
              </div>
            </li>
          </ul>
          <app-create-new-entity
            class="w-100"
            *ngIf="addEntityFromLib == 'createnewentity'"
            [masterCuId]="masterCuId"
            [triggerCuIndex]="triggerCuIndex"
            [gsiMasterId]="gsiMasterId"
            [gsiName]="gsiName"
            [cuName]="cuName"
          ></app-create-new-entity>
          <app-add-attr-to-entity
            class="w-100"
            *ngIf="addEntityFromLib == 'addattribute'"
            [entityListPhysical]="entityList"
            [entityListInfo]="tempInfoEntList"
            [masterCuId]="masterCuId"
            [triggerCuIndex]="triggerCuIndex"
            [gsiMasterId]="gsiMasterId"
            [gsiName]="gsiName"
            [cuName]="cuName"
          ></app-add-attr-to-entity>
          <app-add-entity-from-lib
            class="w-100"
            *ngIf="addEntityFromLib == 'addfromlib'"
            [masterCuId]="masterCuId"
            [triggerCuIndex]="triggerCuIndex"
            [gsiMasterId]="gsiMasterId"
            [gsiName]="gsiName"
            [cuName]="cuName"
          ></app-add-entity-from-lib>
        </div>
      </div>
    </div>
  </ng-template>
</form>
