import { AudioComponent } from './audio/audio.component';
import { AutoIdentifierComponent } from './auto-identifier/auto-identifier.component';
import { BooleanComponent } from './boolean/boolean.component';
import { CaptureImageComponent } from './capture-image/capture-image.component';
import { ChartsComponent } from './charts/charts.component';
import { CheckBoxComponent } from './checkbox/checkbox.component';
import { ChipMultipleComponent } from './chip-multiple/chip-multiple.component';
import { ChipSingleComponent } from './chip-single/chip-single.component';
import { ClickableComponent } from './clickable/clickable.component';
import { currentTimeComponent } from './currentTime/currentTime.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { DateComponent } from './date/date.component';
import { DateOnlyComponent } from './dateonly/dateonly.component';
import { DatetimeComponent } from './datetime/datetime.component';
import { DecimalComponent } from './decimal/decimal.component';
import { DropDownComponent } from './dropdown/dropdown.component';
import { DurationComponent } from './duration/duration.component';
import { EditorComponent } from './editor/editor.component';
import { FileComponent } from './file/file.component';
import { FileWithIRDRComponent } from './filewith-irdr/filewith-irdr.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { HtmlComponent } from './html/html.component';
import { HyperlinkComponent } from './hyperlink/hyperlink.component';
import { ImageComponent } from './image/image.component';
import { InputComponent } from './input/input.component';
import { IntegerComponent } from './integer/integer.component';
import { LabelWithImageComponent } from './label-with-image/label-with-image.component';
import { LabelComponent } from './label/label.component';
import { ListComponent } from './list/list.component';
import { MMIMapsComponent } from './mmi-maps/mmi-maps.component';
import { MultiselectdropdownComponent } from './multiselectdropdown/multiselectdropdown.component';
import { PreviewdocumentComponent } from './previewdocument/previewdocument.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { QrScannerControlComponent } from './qr-scanner-control/qr-scanner-control.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { RatingComponent } from './rating/rating.component';
import { RecordAudioComponent } from './record-audio/record-audio.component';
import { RecordVideoComponent } from './record-video/record-video.component';
import { RedacComponent } from './redac/redac.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { ScormPlayerComponent } from './scorm-player/scorm-player';
import { SignatureComponent } from './signature/signature.component';
import { SliderComponent } from './slider/slider.component';
import { TableComponent } from './table/table.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimeComponent } from './time/time.component';
import { TimeOnlyComponent } from './timeonly/timeonly.component';
import { TimerComponent } from './timer/timer.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { VideoStreamingComponent } from './video-streaming/video-streaming.component';
import { VideoComponent } from './video/video.component';
import { WebexWidgetComponent } from './webexWidget/webex-widget.component';
import { YearComponent } from './year/year.component';

export const componentMapper = {
  scheduler: SchedulerComponent,
  file: FileComponent,
  image: ImageComponent,
  imageavatar: ImageComponent,
  // dialog: DialogComponent, /////not included....
  charts: ChartsComponent,
  table: TableComponent,
  scormvideo: ScormPlayerComponent,
  location: GoogleMapsComponent,
  mmilocation: MMIMapsComponent,
  filewithirdr: FileWithIRDRComponent,
  audio: AudioComponent,
  autoidentifier: AutoIdentifierComponent,
  boolean: BooleanComponent,
  capturingimage: CaptureImageComponent,
  checkbox: CheckBoxComponent,
  chipmultipleselection: ChipMultipleComponent,
  chipsingleselection: ChipSingleComponent,
  clickable: ClickableComponent,
  date: DateComponent,
  yearmonth: DateComponent,
  currentdate: DateComponent,
  newdate: DateComponent,
  daterange: DateRangeComponent,
  dateonly: DateOnlyComponent,
  datetime: DatetimeComponent,
  currentdateonly: DateOnlyComponent,
  currentdatetime: DatetimeComponent,
  newdatetime: DatetimeComponent,
  currency: DecimalComponent,
  decimal: DecimalComponent,
  decimalnumber: DecimalComponent,
  metric: DecimalComponent,
  dropdown: DropDownComponent,
  duration: DurationComponent,
  editor: EditorComponent,
  html: HtmlComponent,
  payment: HtmlComponent,
  hyperlink: HyperlinkComponent,
  input: InputComponent,
  text: InputComponent,
  email: InputComponent,
  number: InputComponent,
  scormprogress: InputComponent,
  mobilenumber: InputComponent,
  password: InputComponent,
  encrypttext: InputComponent,
  textavatar: InputComponent,
  integer: IntegerComponent,
  byte: IntegerComponent,
  short: IntegerComponent,
  char: IntegerComponent,
  period: IntegerComponent,
  float: IntegerComponent,
  label: LabelComponent,
  correlationcoefficient: LabelComponent,
  cuclock: LabelComponent,
  distance: LabelComponent,
  probability: LabelComponent,
  quantification: LabelComponent,
  similaritycoefficient: LabelComponent,
  attributesum: LabelComponent,
  variability: LabelComponent,
  weightedaverage: LabelComponent,
  ratioanalysis: LabelComponent,
  simplelinearregression: LabelComponent,
  multilinearregression: LabelComponent,
  quantile: LabelComponent,
  meansquarederror: LabelComponent,
  quartile: LabelComponent,
  movingaverage: LabelComponent,
  percentile: LabelComponent,
  labelwithimage: LabelWithImageComponent,
  list: ListComponent,
  multiselectdropdown: MultiselectdropdownComponent,
  previewdocument: PreviewdocumentComponent,
  progressbar: ProgressbarComponent,
  qrscanner: QrScannerControlComponent,
  radiobutton: RadioGroupComponent,
  rating: RatingComponent,
  audiorecording: RecordAudioComponent,
  videorecording: RecordVideoComponent,
  redact: RedacComponent,
  redirection: RedirectionComponent,
  signature: SignatureComponent,
  basicslider: SliderComponent,
  inputslider: SliderComponent,
  rangeslider: SliderComponent,
  stepslider: SliderComponent,
  verticalslider: SliderComponent,
  textarea: TextareaComponent,
  time: TimeComponent,
  newtime: TimeComponent,
  timeonly: TimeOnlyComponent,
  timer: TimerComponent,
  typeahead: TypeaheadComponent,
  video: VideoComponent,
  webex: WebexWidgetComponent,
  year: YearComponent,
  currenttimeonly: currentTimeComponent,
  currenttime: currentTimeComponent,
  skewness: LabelComponent,
  kurtosis: LabelComponent,
  confidenceinterval: LabelComponent,
  streamingvideo: VideoStreamingComponent,
  barcodescanner: InputComponent,
  percentage: DecimalComponent,
};
