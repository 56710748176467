import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  field: FieldConfig;
  group: FormGroup;
  labels: any;
  rating = 2.5;
  cropWidth = 75;
  ratingClicked: any;
  stars: any = [];
  constructor(private eventsService: EventsFacadeService) {}

  ngOnInit(): void {
    let value: any;
    /* istanbul ignore next */
    this.checkColor();
    if (Array.isArray(this.field.value)) {
      value = this.field.value[0];
    } else {
      value = this.field.value;
    }
    this.group?.controls?.[this.field?.attribute?.name]?.setValue(value);
    for (let i = 0; i < 5; i++) {
      /* istanbul ignore next */
      if (i < value) {
        if (i < Number(value?.toString()?.split('.')[0])) {
          this.stars.push({ width: '100%' });
        } else {
          let width = Number(value?.toString()?.split('.')[1]) * 10;
          this.stars.push({ width: `${width}%` });
        }
      } else {
        this.stars.push({ width: '0%' });
      }
    }
  }

  onClick(event: any) {
    for (let i = 0; i < 5; i++) {
      /* istanbul ignore next */
      if (i <= event) {
        this.stars[i].width = '100%';
      } else {
        this.stars[i] = { width: '0%' };
      }
    }
    /* istanbul ignore next */
    this.field.value = event + 1;
    this.group?.controls?.[this.field?.attribute?.name]?.setValue(this.field?.value);
    /* istanbul ignore next */
    this.group.controls[this.field?.attribute?.name]?.markAsDirty();
    /* istanbul ignore next */
    this.group?.updateValueAndValidity();
    this.onChange();
  }
  checkColor() {
    /* istanbul ignore next */
    if (!this.field?.configuration?.color) {
      let defaultColors: any;
      /* istanbul ignore next */
      if (localStorage?.getItem('defaultColors')) {
        defaultColors = JSON?.parse(localStorage?.getItem('defaultColors'));
      }
      this.field.configuration.color = defaultColors?.primary ? defaultColors?.primary : '#78b0dc';
    }
  }
  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange() {
    /* istanbul ignore next */
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    /* conditional potentiality check  */
    /* istanbul ignore next */
    if (this.field?.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent(data);
    } else {
      this.eventsService.setEvent(data);
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
