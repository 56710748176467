import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, officeFileMime, emptyObject } from '@common-services';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileUploadPreviewComponent } from '../file-upload-preview/file-upload-preview.component';
@Component({
  selector: 'app-previewdocument',
  template: `
    <div
      *ngIf="
        layoutConfigurations &&
        (layoutConfigurations?.layout != 'layout-enterprise-flatworld' ||
          layoutConfigurations?.specialComponent != 'enterprise')
      "
    >
    <div class="d-flex align-items-center nh-mb-8">
        <label
          class="main-label nh-mb-0"
          [style.color]="field.color"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >
        <span class="main-label-inner"
        ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
        <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker"
          >*</span
        ></span
      >
        </label>
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
          [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div class="d-flex" *ngIf="field.value">
        <a class="d-flex" (click)="onPreviewClick()">
          <span class="material-icons ml-3">
            photo_size_select_large
          </span>
        </a>
        <a class="d-flex" (click)="downloadFile()" *ngIf="canDownload">
          <span class="material-icons ml-3">
            cloud_download
          </span>
        </a>
        <span class="newtab-font" id="newtab-desc" pTooltip="Open in New Tab" tooltipPosition="top">
          <a
            class="d-flex"
            [href]="imgUrl | safeResourceUrl"
            target="_blank"
            *ngIf="field?.configuration?.showInNewTab"
          >
            <span class="material-icons ml-3">
              tab
            </span>
          </a>
        </span>
      </div>
      <iframe
        *ngIf="!isImageType() && !isOfficeType && !fileMimeType?.includes('spreadsheetml.sheet') && !isHtmlType() && imgUrl"
        class="VideoEnt prev-doc-width"
        [ngClass]="{ expandImage: tenant == 'goaemis' }"
        [src]="(imgUrl+'#toolbar=1') | safeResourceUrl"
        title=""
      ></iframe>
      <div class="video-wrapper" *ngIf="field.value && !isHtmlType() && isImageType() && !isOfficeType">
        <img
          class="VideoEnt"
          [ngClass]="{ expandImage: tenant == 'goaemis' }"
          [src]="imgUrl | useHttpImgSrc | async"
          title=""
        />
      </div>
      <iframe
        *ngIf="isOfficeType && !isHtmlType()"
        [src]="officeUrl | safeResourceUrl"
        [ngClass]="{ expandImage: tenant == 'goaemis' }"
        width="100%"
        height="250px"
        frameborder="0"
      ></iframe>
      <div
        id="htmltopdf"
        class="tbl-width file-upload-table"
        [ngClass]="{ expandImageiframe: tenant == 'goaemis' }"
        *ngIf="isHtmlType()"
        [innerHTML]="src.contentUrl"
      ></div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </div>
    <div
      *ngIf="
        layoutConfigurations &&
        layoutConfigurations?.layout == 'layout-enterprise-flatworld' &&
        layoutConfigurations?.specialComponent == 'enterprise'
      "
      class="pdf_viewer_div"
    >
      <div class="popup-scroll">
        <pdf-viewer
          [show-all]="false"
          [ngClass]="isExpanded ? 'ocr-pdfSmall-expanded' : 'ocr-pdfSmall'"
          [zoom]="isExpanded ? zommSize : '1'"
          [src]="imgUrl"
          [render-text]="true"
          [page]="pageNumber"
          [fit-to-page]="true"
          [original-size]="true"
          [stick-to-page]="true"
          style="display: block;"
          id="pdfMapping"
          (pageChange)="pageChange($event)"
          (page-rendered)="pageRendered($event)"
          (after-load-complete)="afterLoadComplete($event)"
          (scroll)="onScroll($event)"
          #pdfMappingScroll
        ></pdf-viewer>
        <div class="pagination-highlighted-text">
        <div class="pagination-style">
          <app-paginator
            class="d-flex"
            id="paggination"
            [pageIndex]="pageNumber - 1"
            [pageSize]="1"
            [totalRecords]="totalPages"
            (onPageChange)="changePage($event)"
            *ngIf="totalPages > 1"
          >
          </app-paginator>
          </div>
          <div class="pagination-style" *ngIf="isMultiValue">
            <app-paginator
              class="d-flex"
              id="highlightedPagination"
              [pageIndex]="highlightPageNumber - 1"
              [pageSize]="1"
              [totalRecords]="totalValues"
              (onPageChange)="onPageChange($event)"
              *ngIf="totalValues > 1"
            >
            </app-paginator>
          </div>
        </div>
      </div>
      <ng-container *ngIf="coordinates">
      <ng-container *ngFor="let coordinate of coordinates; let i = index">
      <div class="area" *ngIf="verifyCorrectPage(i)" [ngStyle]="getCoordinateStyle(coordinate)"></div>
    </ng-container>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .video-wrapper {
        position: relative;
        padding-bottom: 0 !important;
        height: 100%;
        margin-bottom: 30px;
        max-width: none;
        display: block;
      }
      .prev-doc-width {
        width: 100%;
        aspect-ratio: 2/1;
        min-height: 300px;
      }
      .video-wrapper img {
        width: auto !important;
        max-width: 100%;
        margin: 0 auto;
        display: block;
        /* height: 150px; */
        height: auto;
        border-radius: 12px;
        border: 1px solid #fafafa;
        margin-top: 5px;
      }
      .tbl-width {
        font-size: 18px;
      }
      .ocr-pdfSmall {
        position: relative;
        @media (min-width: 1700px) {
          height: 750px;
        }
        @media (min-width: 1500px) and (max-width: 1699px) {
          height: 600px;
        }
        @media (min-width: 1400px) and (max-width: 1599px) {
          height: 550px;
        }
        @media (min-width: 1200px) and (max-width: 1399px) {
          height: 500px;
        }
        @media (max-width: 1199px) {
          height: 600px;
        }
      }
      .ocr-pdfSmall-expanded {
        position: relative;
        overflow-y: scroll !important;
        overflow-x: auto !important;
        @media (min-width: 1700px) {
          height: 1600px;
        }
        @media (min-width: 1500px) and (max-width: 1699px) {
          height: 1300px;
        }
        @media (min-width: 1300px) and (max-width: 1599px) {
          height: 1100px;
        }
        @media (min-width: 1200px) and (max-width: 1299px) {
          height: 1060px;
        }
      }
      .popup-scroll {
        width: 100%;
      }
      .popimg {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
      .pagination-style {
        position: inherit;
      }
      .area {
        position: absolute;
        cursor: pointer;
        transition: all 0.1s;
        background: #ffff0082;
      }
      .pdf_viewer_div {
        position: relative;
      }
      .pagination-highlighted-text {
        display: flex;
        justify-content: space-between;
      }
      ::ng-deep .ng2-pdf-viewer-container {
        overflow-x: initial !important;
      }
      .newtab-font {
        font-size: var(--entity-font-fontSize);
        color: var(--entity-font-color);
        word-break: break-word;
        font-weight: 400;
        font-family: var(--font-family);
      }
    `,
  ],
  providers: [PdfViewerModule],
})
export class PreviewdocumentComponent implements OnInit {
  @ViewChild('pdfMappingScroll') private pdfMappingScroll: any;
  private unsubscribe: Subject<any> = new Subject<any>();
  field: FieldConfig;
  imgUrl: any;
  src: any;
  canDownload: boolean = false;
  imageMimeTypes = ['image/png', 'image/gif', 'image/pjpeg', 'image/jpg', 'image/jpeg', 'image/svg+xml', 'image/webp'];
  fileMimeType = '';
  htmlMimeTypes = ['application/html'];
  tenant: string;

  officeUrl: string;
  isOfficeType: boolean = false;
  pageNumber = 1;
  newCoordinatesByPageNumber: any;
  totalPages: number;
  domHeight: any;
  domWidth: any;
  coordinatesByPAgeNumber: any = [];
  coordinates: any;
  newCoordinates: any;
  scrollPosition: number = 0;
  scrollLeftPos: number = 0;
  originalDocumentSize: any;
  isExpanded: any = false;
  isMultiValue: boolean = false;
  totalValues: number;
  multiValuesData: any;
  highlightPageNumber = -1;
  isPdfView = true;
  zommSize = '1.5';
  public getScreenWidth: any;
  layoutConfigurations: any;
  constructor(
    private eventsService: EventsFacadeService,
    private dialogService: MatDialog,
    private http: HttpClient,
    private _sanitizer: DomSanitizer,
    private transactionFacadeService: TransactionFacadeService
  ) {
    this.detectCordOnFocus();
    this.detectSizeOfGrid();
    this.detectLayoutConfig();
  }
  @HostListener('window:resize', ['$event'])
  /* istanbul ignore next */
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    /* istanbul ignore next */
    if (this.getScreenWidth) {
      if (this.getScreenWidth > 1700) {
        this.zommSize = '1.5';
      } else if (this.getScreenWidth > 1500 && this.getScreenWidth < 1699) {
        this.zommSize = '1.2';
      } else if (this.getScreenWidth > 1200 && this.getScreenWidth < 1499) {
        this.zommSize = '1';
      }
    }
  }
  ngOnInit(): void {
    /* istanbul ignore next */
    this.tenant = localStorage.getItem('TenantName');
    /* istanbul ignore next */
    if (this.field?.canDownload) {
      this.canDownload = this.field?.canDownload;
    }
    if (this.field?.value && this.IsJsonString(this.field?.value)) {
      /* istanbul ignore next */
      let jsonObj: any = JSON.parse(this.field.value);
      /*istanbul ignore else */
      if (jsonObj?.contentUrl) {
        this.imgUrl = jsonObj.contentUrl;
        this.fileMimeType = jsonObj.mimeType;
        this.src = {
          mimeType: jsonObj.mimeType,
          contentUrl: jsonObj.contentUrl,
          canDownload: this.canDownload,
        };
      }
    } else {
      /* istanbul ignore next */
      this.imgUrl = this.field?.value;
      this.fileMimeType = this.updateMimeType(this.field.value);
      /* istanbul ignore next */
      if (this.fileMimeType == 'application/html' && this.field) {
        this.field.value = this._sanitizer.bypassSecurityTrustHtml(this.field.value);
      }
      this.src = {
        mimeType: this.fileMimeType,
        contentUrl: this.field.value,
        canDownload: this.canDownload,
      };
    }
    this.checkIsOfficeType();
    /* istanbul ignore next */
    if (window.innerWidth) {
      if (window.innerWidth > 1700) {
        this.zommSize = '1.5';
      } else if (window.innerWidth > 1500 && window.innerWidth < 1699) {
        this.zommSize = '1.2';
      } else if (window.innerWidth > 1200 && window.innerWidth < 1499) {
        this.zommSize = '1';
      }
    }
  }

  updateMimeType(url: string) {
    /* istanbul ignore next */
    let type = url?.split('.');
    /* istanbul ignore next */
    let len = type?.length;
    if (type && type[len - 1] == 'pdf') {
      return 'application/pdf';
    } else if (type && (type[len - 1] == 'png' || type[len - 1] == 'jpg' || type[len - 1] == 'jpeg')) {
      return 'image/' + type[len - 1];
    } else if (type && type[len - 1] == 'mp4') {
      return 'video/mp4';
    } else if (/<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(url)) {
      return 'application/html';
    }
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  /* istanbul ignore next */
  onPreviewClick() {
    /* istanbul ignore next */
    this.dialogService.open(FileUploadPreviewComponent, {
      minHeight: '600px',
      width: '900px',
      data: this.src,
      panelClass: 'fileUpLoadIframeDialog',
    });
  }

   /* downloadFile1() {
     this.transactionFacadeService.downloadFile(this?.src?.contentUrl);
   } */

  downloadFile() {
    if(!this?.src?.contentUrl?.changingThisBreaksApplicationSecurity){
      this.transactionFacadeService.downloadFile(this?.src?.contentUrl);
    }
    else{
    /* istanbul ignore next */
     this.openPrintDialog();
    }
  }

  isImageType() {
    /* istanbul ignore next */
    let index = this.imageMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isHtmlType() {
    /* istanbul ignore next */
    let index = this.htmlMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }
  checkIsOfficeType() {
    if (officeFileMime.has(this.src?.mimeType)) {
      this.officeUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        this.src?.contentUrl
      )}&embedded=true`;
      this.isOfficeType = true;
    }
  }

  pageChange(pageData: any) {
    this.pageNumber = pageData;
  }
  pageRendered(e: any) {
    /* istanbul ignore next */
    this.domHeight = e?.source?.height;
    /* istanbul ignore next */
    this.domWidth = e?.source?.width;
  }
  afterLoadComplete(pdf: any) {
    this.totalPages = pdf?.numPages;
  }
  /* istanbul ignore next */
  getCoordinateStyle(coordinate: any): object {
    /* istanbul ignore next */
    if (coordinate != undefined && !emptyObject(coordinate)) {
      const x = (this.domWidth / this.originalDocumentSize?.width) * coordinate?.x - this.scrollLeftPos;
      const y = (this.domHeight / this.originalDocumentSize?.height) * coordinate?.y - this.scrollPosition;
      const height = (this.domHeight / this.originalDocumentSize?.height) * coordinate?.height;
      const width = (this.domWidth / this.originalDocumentSize?.width) * coordinate?.width;
      return {
        height: `${height}px`,
        width: `${width}px`,
        left: `${x}px`,
        top: `${y}px`,
        position: `absolute`,
      };
    }
  }

   /* istanbul ignore next */
   onPageChange(event: any) {
    this.highlightPageNumber = event?.pageIndex + 1;
    this.pageNumber = this.multiValuesData?.page_no[event?.pageIndex];
    let coordinate = this.multiValuesData?.coordinates[event?.pageIndex];
    const x = (this.domWidth / this.originalDocumentSize?.width) * coordinate?.x - this.scrollLeftPos;
    const y = (this.domHeight / this.originalDocumentSize?.height) * coordinate?.y - this.scrollPosition;
    if (this.pdfMappingScroll?.element?.nativeElement?.scrollTop >= 0 && y > 100) {
      this.pdfMappingScroll.element.nativeElement.scrollTop = y - 100;
    }
    if (this.pdfMappingScroll?.element?.nativeElement?.scrollLeft >= 0 && x > 100) {
      this.pdfMappingScroll.element.nativeElement.scrollLeft = x - 100;
    }
    this.eventsService.pdfTextCord.next(null);
  }
  /* istanbul ignore next */
  verifyCorrectPage(index: any) {
    return this.pageNumber == this.multiValuesData.page_no[index];
  }

  changePage(event: any) {
    /* istanbul ignore next */
    this.pageNumber = event?.pageIndex + 1;
    this.coordinates = undefined;
  }
  /* istanbul ignore next */
  detectCordOnFocus() {
    this.eventsService.pdfTextCord$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      if (res) {
        this.isMultiValue = true;
        this.multiValuesData = res;
        this.totalValues = res?.page_no?.length;
        this.originalDocumentSize = res?.ref_dimensions;
        this.pageNumber = res?.page_no[0] ? res?.page_no[0] : this.pageNumber;
        this.highlightPageNumber = 1;
        this.coordinates = res?.coordinates ? res?.coordinates : [];
        const x = (this.domWidth / this.originalDocumentSize?.width) * this?.coordinates[0]?.x - this.scrollLeftPos;
        const y = (this.domHeight / this.originalDocumentSize?.height) * this.coordinates[0]?.y - this.scrollPosition;
        if (this.pdfMappingScroll?.element?.nativeElement?.scrollTop >= 0 && y > 100) {
          this.pdfMappingScroll.element.nativeElement.scrollTop = y - 100;
        }
        if (this.pdfMappingScroll?.element?.nativeElement?.scrollLeft >= 0 && x > 100) {
          this.pdfMappingScroll.element.nativeElement.scrollLeft = x - 100;
        }
        this.eventsService.pdfTextCord.next(null);
      }
    });
  }
  /* istanbul ignore next */
  detectLayoutConfig() {
    this.eventsService.storeLayoutConfigData$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.layoutConfigurations = res;
      }
    });
  }
  /* istanbul ignore next */
  detectSizeOfGrid() {
    this.transactionFacadeService.pdfResize$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      console.log(res);
      this.isExpanded = res;
      this.isPdfView = false;
      setTimeout(() => {
        this.isPdfView = true;
      }, 1000);
    });
  }
  /* istanbul ignore next */
  onScroll(event: any) {
    /*istanbul ignore next*/
    this.scrollPosition = (event?.target as Element)?.scrollTop;
    this.scrollLeftPos = (event?.target as Element)?.scrollLeft;
    /*istanbul ignore next*/
    if (!this.scrollPosition) this.scrollPosition = 0;
    /*istanbul ignore next*/
    if (!this.scrollLeftPos) this.scrollLeftPos = 0;
  }

  openPrintDialog() {
    var content = this.src.contentUrl.changingThisBreaksApplicationSecurity;
    const printWindow = window.open('', '_blank');
    printWindow?.document.open();
    printWindow?.document.write(content);
    setTimeout(() => {
      printWindow?.print();
    }, 100);
    printWindow?.document.close();
  }
}
export class ImageMapCoordinate {
  x = 0;
  y = 0;
  width = 100;
  height = 100;
  name?: string;
  page_no?: number;
  flag?: boolean;
  constructor(init?: Partial<ImageMapCoordinate>) {
    Object.assign(this, init);
  }
}
