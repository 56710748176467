import { Credentials } from './credentials.service';
import { AuthMockDataTest } from './auth-mock-data.test';

export class MockCredentialsService {
  credentials: Credentials | null = AuthMockDataTest.mockCredentials();

  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  setCredentials(credentials?: Credentials, _remember?: boolean) {
    /* istanbul ignore next */
    this.credentials = credentials || null;
  }
}
