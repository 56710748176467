import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChatbotEndpointService } from './chatbot-endpoint.service';
import { ChatBotStore, ChatBotStoreActions } from '../stores/chatbot-state';

@Injectable({
  providedIn: 'root',
})
/*istanbul ignore next*/
export class ChatbotFacadeService implements OnDestroy {
  ngUnsubscribe = new Subject();

  public updatedChatData = new Subject();
  updatedChatData$ = this.updatedChatData.asObservable();

  constructor(private chatBotEndPointService: ChatbotEndpointService, public chatBotStore: ChatBotStore) {
    this.detectChatBotStateChange();
  }

  resetStore() {
    this.chatBotStore.setStateData({}, ChatBotStoreActions.ResetStore);
  }

  resumeChatbotState() {
    let data = this.chatBotStore.getStateData();

    setTimeout(() => {
      this.updatedChatData.next({ ...data, playlastMessage: false });
    });
  }

  detectChatBotStateChange() {
    this.chatBotStore.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedStoreData: any) => {
      this.updatedChatData.next(updatedStoreData);
    });
  }

  setChatBotState(body: any, action: any) {
    this.chatBotStore.setStateData(body, action);
  }

  getChatBotAnswers(body: any) {
    this.setChatBotState(body, ChatBotStoreActions.UpdateQuestion);

    this.chatBotEndPointService
      .getChatBotAnswers(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.ans?.profanityStatus) {
          this.setChatBotState({ quest: res?.ans?.maskText[0] }, ChatBotStoreActions.UpdateProfanityQuestion);
        }
        /* istanbul ignore next */
        if (res) {
          this.setChatBotState(res, ChatBotStoreActions.UpdateAnswer);
        }
      });
  }

  getGreetMessage() {
    this.chatBotEndPointService
      .getGreetMessage()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((message: any) => {
        const { ans } = message;
        this.setChatBotState(ans, ChatBotStoreActions.GreetingMessage);
      });
  }

  sendFeedback(body: any) {
    this.chatBotEndPointService
      .sendFeedback(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
        }
      });
  }

  sendVoiceInput(body: any, blob: any) {
    this.setChatBotState(blob, ChatBotStoreActions.VoiceInput);
    this.chatBotEndPointService
      .sendVoiceInput(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.ans) {
          this.setChatBotState(res, ChatBotStoreActions.UpdateVoiceWithTextQuestionAndAnswer);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
