export interface AppConfigModel {
  api: string;
  production: boolean;
  hmr: boolean;
  version: string;
  firebase: FirebaseModel;
  name: string;
  defaultLanguage: string;
  supportedLanguages: Array<string>;
  dsdOrchUrl: string;
  cdmServerAPI: string;
  serverIp: string;
  loginApiKey: string;
  loginAuth: string;
  aadsApplicationUrl: string;
  googleAnalyticsTag: string;
  googleTagManager: string;
  googleMaps: string;
  paasLiveChatAgent: string;
  cdnUrl: string;
  defaultTenantName: string;
  xAuthToken: string;
  xUserId: string;
  SOCKET_ENDPOINT: string;
  iqm_voice_server: string;
  new_voice_server: string;
  gcr_voice_api: string;
  digitalMind: string;
  nhbot: string;
  nonProdPaasLiveChatAgent: string;
  prodPaasLiveChatAgent: string;
}

export interface FirebaseModel {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export function endPoints(environment: AppConfigModel) {
  // if(!environment){
  //   return;
  // }
  environment.serverIp = '';
  environment.nhbot = '/nhbot';
  if (
    window.location.host?.includes('localhost') ||
    window.location.host?.startsWith('10.')
  ) {
    environment.serverIp = '/api';
  }
  if (environment.dsdOrchUrl.indexOf(environment?.serverIp) === -1) {
    environment.dsdOrchUrl = environment?.serverIp + environment.dsdOrchUrl;
  }
  if (environment.cdmServerAPI.indexOf(environment.dsdOrchUrl) === -1) {
    environment.cdmServerAPI =
      environment.dsdOrchUrl + environment.cdmServerAPI;
  }
  environment.paasLiveChatAgent =
    localStorage.getItem('environmentType')?.toLocaleLowerCase() !==
    'production'
      ? environment?.nonProdPaasLiveChatAgent
      : environment?.prodPaasLiveChatAgent;

  const aadsApplicationUrl = `${environment.aadsApplicationUrl}`;
  const cdmTenantUrl = `${environment.cdmServerAPI}/api/cdm`;
  const serverIp = `${environment?.serverIp}`;
  const nslOTP = `${environment?.serverIp}/dsd-orch/nsl-iam`;
  const iam = `${environment?.serverIp}/nsl-iam/api`;
  const tfCoreUrl = `${environment.dsdOrchUrl}/core`;
  const dsdBetsStoreUrl = `${environment.dsdOrchUrl}/dsd-bets-store`;
  const tfTransactionUrl = `${environment.dsdOrchUrl}/txn/execute`;
  const tfDebugValue = `${environment.dsdOrchUrl}/execute`;
  const searchUrl = `${environment.dsdOrchUrl}/search`;
  const dldServiceUrl = `${environment.dsdOrchUrl}/dld`;
  const customTenantUI = `${environment.dsdOrchUrl}/design`;
  const transactionUrl = `${environment.dsdOrchUrl}/txn/transactions`;
  const secmcc = `${environment.dsdOrchUrl}/secmcc/transaction`;
  const translator = `${environment.dsdOrchUrl}/translator`;
  const dashboardUrl = `${environment.dsdOrchUrl}/query/`;
  const importexport = `${environment.dsdOrchUrl}/importexport`;
  const analytics = `${environment.dsdOrchUrl}/analytics`;
  const notificationsUrl = `${environment.dsdOrchUrl}/v1/notifications/api`;
  const globalSearchUrl = `${environment.dsdOrchUrl}/query/nsl-search`;
  const ocrUrl = `${environment.dsdOrchUrl}/v2_dpe`;
  const chatBot = `${environment.dsdOrchUrl}/siaorch`;
  const metering = `${environment.dsdOrchUrl}/metering`;
  const auditUrl = `${environment.dsdOrchUrl}/audit`;
  const query = `${environment.dsdOrchUrl}/query`;
  const sharableFilters = `${query}/facet-search/sharable/`;
  const specialFeatures = `${environment.dsdOrchUrl}/special-features`;
  const dsdFileStore = `${environment.dsdOrchUrl}/dsd-files-store/`;
  const dsdTransactionUrl = `${environment.dsdOrchUrl}/execute`;
  const globalBetsStore = `${dsdBetsStoreUrl}/global`;
  const nslIAMUrl = `${environment.dsdOrchUrl}/nsl-iam/api`;
  const iamUserBaseUrl = `${nslIAMUrl}/user`;
  const iamRolesBaseUrl = `${nslIAMUrl}/roles`;
  const iamOrganisationUnitBaseUrl = `${nslIAMUrl}/orgUnits`;
  const iamGroupsBaseUrl = `${nslIAMUrl}/groups`;
  const iamLdapGroupsBaseUrl = `${nslIAMUrl}/ldapgroups`;
  const iamOwnershipBaseUrl = `${nslIAMUrl}/owner-ship`;
  const cdmOrchBaseUrl = `${environment.dsdOrchUrl}/cdm`;
  const tenantBaseUrl = `${cdmOrchBaseUrl}/api/cdm`;
  const cdmApiUrl = `${cdmOrchBaseUrl}/api`;
  const dsdOrch = `${environment.dsdOrchUrl}`;
  const globalSearch = `${environment.dsdOrchUrl}/nsl/`;
  const globalSearchforNotepad = `${environment.dsdOrchUrl}/dsd-bets-store/global/browse`;
  const googleTagManager = 'https://www.googletagmanager.com/gtag/js?id=';
  const predectiveAnalytics = `${environment.dsdOrchUrl}/pa`;
  const nslbetAnalyzer = `${environment?.serverIp}/nsl-bet-analyzer/bet/`;
  const geEntitycard = `${environment.dsdOrchUrl}/design/entity-card/`;
  const expandedView = `${geEntitycard}expanded-view/`;
  const commentsAndRatings = `${dashboardUrl}ratings/v1/ge`;
  const scheduledJobs = `${environment.dsdOrchUrl}/async`;
  const blockchain = `${environment.serverIp}/nsl2blockchain`;
  const entityConfig = `/nsl-bet-store/bet`;
  const digitalMind = `${environment.digitalMind}`;
  const datastore = `${environment.serverIp}/datastore`;
  const entityCart = `${environment.dsdOrchUrl}/design/entity-cart`;
  const nhbot = `${environment.nhbot}`;
  const superset = `${environment.dsdOrchUrl}/query/superset`;
  const entityBoard = `${environment.dsdOrchUrl}/entity-board/`;
  const iamGroupBaseUrl = `${nslIAMUrl}/group`;


  return {
    // apiUrl: 'http://10.5.7.203:1337',
    notificationsUrl: notificationsUrl,
    analytics: analytics,
    importexport: importexport,
    customTenantUI: customTenantUI,
    metering: metering,
    aadsUrl: `${aadsApplicationUrl}/aads/wizard`,
    dsdBetsStoreUrl: dsdBetsStoreUrl,
    tfCoreUrl: tfCoreUrl,
    cdmTenantUrl: cdmTenantUrl,
    googleTagManager: googleTagManager,
    googleGeoLocation: `${environment.dsdOrchUrl}/advertisement/getLocation?latlng=`,
    enviroment: `${environment.dsdOrchUrl}`,

    /** Bulk Import Users */
    getUsersList: `${tenantBaseUrl}/getAssignedEnvUsers`,
    getUnassignedUsersList: `${tenantBaseUrl}/getUnassignedEnvUsers`,
    getAddUsersList: `${tenantBaseUrl}/getUnassignedEnvUsers`,
    unassignUsers: `${tenantBaseUrl}/users/update`,
    assignUsers: `${tenantBaseUrl}/assignUsersToEnv`,

    /** Translator */
    saveStaticLabels: `${translator}/tags/add/Global/`,
    getLanguages: `${translator}/language`,
    getStaticLabels: `${translator}/tags/Global/`,
    getStringTranslate: `${translator}/translations`,
    updateMultipleWords: `${translator}/translations/words`,
    addStaticLabels: `${translator}/label/add`,
    updateStaticLabels: `${translator}/label/update`,
    deleteStaticLabel: `${translator}/label/delete`,
    gsiTranslator: `${translator}/multi-translations/masterid`,
    tenantLabelsUpdate: `${translator}/label/tenant`,

    /**Auth */
    loginAuth: `${environment.loginAuth}`,
    logOut: `${nslIAMUrl}/login/logout-action`,
    getAllTenants: `${cdmTenantUrl}/getAll`,
    tenantInfo: `${cdmTenantUrl}/getTenantByName`,
    envBasicInfo: `${cdmTenantUrl}/envBasicInfo?`,
    envByName: `${cdmTenantUrl}/envByName/`,
    forgotPassword: `${nslIAMUrl}/user/resetPasswordByEmail`,
    forgotCustomerId: `${nslOTP}/api/user/getCustomID`,

    generateOTP: `${nslOTP}/login/otp/generateOtp`,
    validateOTP: `${nslOTP}/login/otp/validateOtp`,
    validateSignUpOTP: `${nslOTP}/login/otp/validateOTPForSelfSignup`,
    generateSignUpOtp: `${nslOTP}/login/otp/generateOTPForSelfSignup?mobileNumber=`,
    generateSignUpOtpNew: `${nslOTP}/login/otp/generateOTPForSelfSignup`,
    tokenGenerate: `${nslIAMUrl}/login/login-action?`,
    tokenGenerateV2: `${nslIAMUrl}/login/v2/login-action`,
    updateTempPassword: `${nslIAMUrl}/login/requiredAction/update/password`,
    validate2FaOtp: `${nslOTP}/login/otp/validateOtp`,
    resend2faOtp: `${nslOTP}/login/otp/resendOtpFor2FA`,
    refreshTokenGeneration: `${nslIAMUrl}/login/tokenFromRefreshToken?`,
    // getAllLabels: `http://10.16.2.170:9090/nsl-iam/api/roles/getAllLabels`,
    getAllLabels: `${nslIAMUrl}/roles/getAllLabels`,
    // getRolesByLabels:  `http://10.16.2.170:9090/nsl-iam/api/roles/getAllRolesInLabel?label=`,
    getRolesByLabels: `${nslIAMUrl}/roles/getAllRolesInLabel?label=`,
    enableOtp: `${dsdOrch}/nsl-iam/login/otp/enableOtpConfigInKeycloak?enableOtp=`,
    refreshToken: `${nslIAMUrl}/realm/setRefreshTokenUsageToOneTime?tenantName=`,
    systemUserToken: `${nslIAMUrl}/platform/admin/system-user-session`,
    generateCaptcha: `${serverIp}/nsl-iam/web/captcha/get`,
    verifyCaptcha: `${serverIp}/nsl-iam/web/captcha/validate`,

    /**
     * B2C Login
     */

    getIdentityProvidersList: `${nslIAMUrl}/client/getIdentityProviders?tenantName=`,
    fat2flat: `${environment.dsdOrchUrl}/nsl2text/batch_fat2flat`,
    getEntityIdByName: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/flat`,
    nestedEntity: `${environment.dsdOrchUrl}/nsl2text/nested_entity`,
    chatbotCanvasTfJson: `${environment.dsdOrchUrl}/nsl2text/tf_json`,
    compositeExtraction: `${environment.dsdOrchUrl}/nslgrammar/composite_extraction`,
    informationLayerExtraction: `${environment.dsdOrchUrl}/nslgrammar/info_layer_extraction`,
    triggerCESLayerExtraction: `${environment.dsdOrchUrl}/nslgrammar/trigger_ces_extraction`,
    cuValidate: `${environment.dsdOrchUrl}/nslgrammar/cu_validate`,
    excludedLanguageForFat2Flat: `${environment.dsdOrchUrl}/paragraph_grammar/languages_supported`,
    /**
     * DLD
     */
    dldCU: `${dldServiceUrl}/DLDCUSmartFullTF3`,
    dldGsi: `${dldServiceUrl}/DLDGSISmartTF3`,
    dldNestedCU: `${dldServiceUrl}/DLDNestedCUSmartFullTF3`,
    dldExceptionCU: `${dldServiceUrl}/DLDExceptionCUSmartFullTF3`,
    dldEntity: `${dldServiceUrl}/DLDEntitySmartFullTF3`,
    dldAttribute: `${dldServiceUrl}/DLDAttributesSmartTF3`,
    dldGsiFull: `${dldServiceUrl}/DLDGSISmartFullTF3`,
    dldGsiRoles: `${dldServiceUrl}/DLDGSIRoleSmartTF3`,
    dldCURoles: `${dldServiceUrl}/DLDCURoleSmartTF3`,
    dldEntityRoles: `${dldServiceUrl}/DLDEntityRoleSmartTF3`,
    dldAttributeRoles: `${dldServiceUrl}/DLDAttributeRoleSmartTF3`,
    dldDCDEntity: `${dldServiceUrl}/DLDDCDForEntityTF3`,
    dldDCDAttribute: `${dldServiceUrl}/DLDDCDForAttributeTF3`,
    dldFeedback: `${dldServiceUrl}/DLDFeedbackServiceTF3`,
    compositeSaveCU: `${tfCoreUrl}/CU/compositesave`,
    saveEntityConfig: `${dsdOrch}/query/global/gs-records-config/save/`,
    getEntityConfig: `${serverIp}/dsd-orch/query/global/gs-records-config/get?tenantId=`,
    getAdvSearchConfig: `${dsdOrch}/query/global/gs-type-config/get?tenantId=`,
    saveAdvSearchConfig: `${dsdOrch}/query/global/gs-type-config/save/`,

    /**
     * Global Bets Store
     */
    globalBets: globalBetsStore,
    globalBetsSearch: `${globalBetsStore}/browse/`,
    compareBets: `${globalBetsStore}/browse/compare-bets?betType=`,
    saveBetsComposite: `${dsdBetsStoreUrl}`,

    /**
     * OCR API
     */
    ocrExtractionUrl: `${ocrUrl}/extraction`,
    ocrSetMappingUrl: `${dsdBetsStoreUrl}/cdec/set_mappings`,
    ocrGetMappingUrl: `${ocrUrl}/get_mappings`,
    ocrManualMapping: `${ocrUrl}/manual_learning`,
    getpdfTextCord: `${ocrUrl}/focus`,
    feedBackCDEC: `${ocrUrl}/feedback`,
    /**chat bot */

    chatBot: `${chatBot}/get_answer`,
    chatBotGreetMessage: `${chatBot}/greet_user`,
    chatBotVoiceInput: `${chatBot}/get_answer_by_asr`,
    chatBotFeedBack: `${chatBot}/save_user_feedback`,
    paasLivechatUrl: `${environment.paasLiveChatAgent}/livechat`,
    feedbackLivechat: `${environment.paasLiveChatAgent}/chatsession/feedback`,
    setDepartment: `${environment.paasLiveChatAgent}/chatsession/addDepartment`,
    chatbotFileUpload: `${environment.paasLiveChatAgent}/api/v1/livechat-visitor/uploadfile`,
    tenantMsgConfiguration: `${dsdOrch}/chatbot/api/v1/tenantConfiguration/save`,
    getHistoryUrl: `${environment.paasLiveChatAgent}/chatsession/getHistory`,
    addLanguageForBot: `${environment.paasLiveChatAgent}/chatsession/addLanguageForBot`,
    getLanguage: `${dsdOrch}/chatbot/api/v1/tenant_languages/fetch/`,
    entityCardConfigureChatbot: `${dsdOrch}/chatbot/api/v1/entityCardSearch/save/`,
    getFileContent: `${environment.paasLiveChatAgent}/api/v1/livechat-agent/download/`,
    autoSuggestionChatbot: `${environment.dsdOrchUrl}/chatbot/api/v1/autosuggestion/`,
    conversationTotalizer: `${environment.paasLiveChatAgent}/api/v1/livechat/analytics/dashboards/conversation-totalizers`,
    agentProductivityTotalizer: `${environment.paasLiveChatAgent}/api/v1/livechat/analytics/dashboards/agents-productivity-totalizers`,
    chatTotalizer: `${environment.paasLiveChatAgent}/api/v1/livechat/analytics/dashboards/chats-totalizers`,
    productivityTotalizer: `${environment.paasLiveChatAgent}/api/v1/livechat/analytics/dashboards/productivity-totalizers`,
    agentsAvailableforServiceHistory: `${environment.paasLiveChatAgent}/api/v1/livechat/analytics/agents/available-for-service-history`,
    agentTotalServiceTime: `${environment.paasLiveChatAgent}/api/v1/livechat/analytics/agents/total-service-time`,
    tagReportsAndFeedback: `${environment.paasLiveChatAgent}/api/v1/livechat/rooms`,

    /** Transaction Api */

    debug: `${tfTransactionUrl}/fetchtxndata`,
    debugValue: `${tfDebugValue}/lifecycle/comment/triggerState/`,
    transactionUrl: `${tfTransactionUrl}/change-unit`,
    transactionresumeUrl: `${dsdTransactionUrl}/resume`,
    setChangeUnitEvents: `${dsdTransactionUrl}/event?baseTCUId=`,
    getDependentDropdownDetails: `${dsdTransactionUrl}/reference-source-values?`,
    getDependentDropdownDetailsRef: `${tfTransactionUrl}/reference-source-values?`,
    saveFCMToken: `${notificationsUrl}/token/device-token`,
    deleteFCMToken: `${notificationsUrl}/token/device-token`,
    getAllNotifications: `${notificationsUrl}/notification?`,
    changeOfNotificationStatus: `${notificationsUrl}/notification/`,
    markAllNotificationsAsRead: `${notificationsUrl}/notification/update-all-status-read`,
    searchNotofications: `${notificationsUrl}/search`,
    getUnreadNotificationCount: `${notificationsUrl}/notification/get-unread-count`,
    addMultiEntities: `${environment.dsdOrchUrl}/execute/refresh/multi-attributes?entityId=`,

    // globalAutocomplete: `${environment.dsdOrchUrl}/query/nsl-search/autocomplete?`,
    // globalSearch: `${environment.dsdOrchUrl}/query/nsl-search/search?`,
    globalAutocomplete: `${globalSearchUrl}/autocomplete?`,
    globalSearch: `${globalSearchUrl}/search?`,
    googleAPI: `https://www.googleapis.com/customsearch/v1?`,

    previousCuData: `${dsdTransactionUrl}/previousCU/`,
    reAssign: `${secmcc}/reAssign?`,
    escalateTxn: `${secmcc}/escalate?`,
    cancelAutoSubmitTxn: `${secmcc}/cancel-autoSubmit?`,
    getCreatedTxn: `${secmcc}/createdBy?`,
    getAssignedTxn: `${secmcc}/reAssign?`,
    getEscalatedTxn: `${secmcc}/escalate?`,
    pendingTransactionUrl: `${transactionUrl}/all?`,
    getTransactionHistoryCuList: `${secmcc}/history/`,
    getTransactionFullHistoryEmbeddedCuList: `${secmcc}/fullHistory/`,
    getCuTransactionHistory: `${secmcc}/cuTxnData/`,
    getReservedActionsList: `${transactionUrl}/all/reservedActionNames?`,
    getAllTransactions: `${dashboardUrl}transaction/txn-general-search?`,
    advancedSearchTxn: `${dashboardUrl}transaction/filtered-transactions`,
    txnStatusChange: `${secmcc}/status?`,
    txnStatusall: `${secmcc}`,
    getReservedActionsHistory: `${secmcc}/reserved-actions`,
    facetSearch: `${dsdTransactionUrl}/faceted-search`,
    notificationbtn: `${secmcc}/execute/`,
    // /txn/transaction/cancel
    cancelTransaction: `${environment.dsdOrchUrl}/txn/transaction/cancel`,
    customizedWarningDialog: `${environment.dsdOrchUrl}/execute/dialog-box/execute-and-get-message`,
    /** dashboard api */
    saveDataset: `${dashboardUrl}datasets/dataset`,
    groupList: `${dashboardUrl}dashboards/groups/list?`,
    getWidgets: `${dashboardUrl}datasets/dataset/groupName/`,
    getAttributes: `${dashboardUrl}dynamic-search/txn-report-custom`,
    getxandyValues: `${dashboardUrl}dynamic-search/txn-xy-report`,
    saveWidget: `${dashboardUrl}dashboards/widget-all`,
    deleteWidget: `${dashboardUrl}dashboards/widget/`,
    saveCollection: `${dashboardUrl}collections/collection`,
    getCollectionDetails: `${dashboardUrl}collections/collection/`,
    getdashboardDetailsOnCollection: `${dashboardUrl}dashboards/dashbaord-list/`,
    getDashboardsByName: `${dashboardUrl}dashboards/dashboard/name/`,
    getAttributeValues: `${dashboardUrl}dynamic-search/list-attributeValues`,
    getAggrigateValues: `${dashboardUrl}dynamic-search/txn-report`,
    getCollections: `${dashboardUrl}collections/list?`,
    getDatasetDetails: `${dashboardUrl}datasets/dataset/name/`,
    getDashboardDetails: `${dashboardUrl}dashboards/dashboard/`,
    publishDashboard: `${dashboardUrl}dashboards/collection/`,
    getWidgetDetails: `${dashboardUrl}dashboards/widget/`,
    getAllDashboardWidgets: `${dashboardUrl}dashboards/widgets/`,
    getAllDashboards: `${dashboardUrl}dashboards/dashboard?`,
    getAllDatasets: `${dashboardUrl}datasets/dataset`,
    deleteDatasets: `${dashboardUrl}datasets/dataset/`,
    getcuExecutionData: `${dashboardUrl}dynamic-search/cu-execution-report`,
    deleteDashboard: `${dashboardUrl}dashboards/dashboard/delete/`,
    getDashboardsByTenantID: `${superset}/dashboard/getByTenant`,
    rls: `${environment.dsdOrchUrl}/query/superset`,
    getDashboardsByUserID: `${superset}/dashboard/getByUserId`,
    promoteSupersetDashboard: `${superset}/promote`,
    // Superset Alerts
    alertDetails: `${superset}/alertDetails`,
    createAlert: `${superset}/create_Alert`,
    deleteAlerts: `${superset}/deleteAlerts`,
    listAlerts: `${superset}/list_alerts`,
    updateAlert: `${superset}/updateAlert`,
    promoteAlert: `${superset}/promoteAlert`,
    enableAlert: `${superset}/enableAlert`,
    disableAlert: `${superset}/disableAlert`,
    // Superset Reports
    reportDetails: `${superset}/reportDetails`,
    createReport: `${superset}/create_Report`,
    deleteReports: `${superset}/deleteReports`,
    listReports: `${superset}/list_reports`,
    updateReport: `${superset}/updateReport`,
    promoteReport: `${superset}/promoteReport`,
    enableReport: `${superset}/enableReport`,
    disableReport: `${superset}/disableReport`,

    getGEAttributes: `${dashboardUrl}txn-ge-search/ge-report-custom`,
    getGExandyValues: `${dashboardUrl}txn-ge-search/ge-xy-report`,
    getGEAggrigateValues: `${dashboardUrl}txn-ge-search/ge-report`,
    getGEAttributeValues: `${dashboardUrl}txn-ge-search/attributeValues/name/`,

    getTopTransactedSolutions: `${analytics}/analytics/topSolutions?`,
    getTopEditedSolutions: `${analytics}/analytics/topEditedSolutions?`,
    getTransactionsCount: `${analytics}/analytics/transactionsCount?`,
    getSolutionsCount: `${analytics}/analytics/solutionsCount?`,
    loadUsersCount: `${iamUserBaseUrl}/getUsersCount`,
    getTenentByName: `${tenantBaseUrl}/getTenantByName/`,
    getBillingData: `${cdmOrchBaseUrl}/api/billing/bills/`,
    featureUsageBill: `${cdmApiUrl}/billing/bills/usage`,
    getUsersCountByTenantName: `${iamUserBaseUrl}/getUsersCountByTenantName?`,
    exportBillingPdf: `${cdmOrchBaseUrl}/api/billing/bills/`,
    getEntityCount: `${analytics}/analytics/entitiesCount?`,
    getAllCdmUsers: `${tenantBaseUrl}/users`,
    getPaginateUsersTenantAdmin: `${tenantBaseUrl}/userspage`,
    updateUserAsTenantAdmin: `${tenantBaseUrl}/update/user`,
    specialFeatureAnalytics: `${dsdOrch}/special-features/analytics`,
    specialFeatureSave: `${dsdOrch}/special-features/`,
    /**manage Data API */
    importEntitiesFromCSV: `${importexport}/import/entities`,
    importEntity: `${importexport}/import/entity`,
    downloadTemplate: `${importexport}/import/entity/template?`,
    getDateAttributes: `${importexport}/import/dateAndCurrentDateTypeAttributes?`,
    saveNewEntity: `${importexport}/import/entity/save`,
    exportEntity: `${importexport}/export/entity`,
    getBooksByRights: `${nslIAMUrl}/rights/getBooksByRights?`,
    getSolutionsByRights: `${nslIAMUrl}/rights/getSolutionsByRights?`,
    getRelatedBetsData: `${importexport}/dependency`,
    fetchsingleLevelDependency: `${importexport}/singleLevelDependency/`,
    importHistoryData: `${importexport}/import-status/getAllStatus`,
    /**manage configuration API */
    configurableCPEntities: `${environment.dsdOrchUrl}/core/Entity/matching/criteria`,
    configurableEntityData: `${environment.dsdOrchUrl}/execute/entity-records`,
    configurableSaveEntity: `${environment.dsdOrchUrl}/execute/save/entity-records`,

    /* paginated Search API */
    getPaginatedRecords: `${environment.dsdOrchUrl}/execute/paginated-search`,

    /**manage Organisation Unit API */
    getAllOrgUnits: `${iamOrganisationUnitBaseUrl}/getAllOrgUnits`,
    getPaginateOrgUnits: `${iamOrganisationUnitBaseUrl}/getAllOrgUnits?`,
    createOrgUnit: `${iamOrganisationUnitBaseUrl}/save`,
    getOrgUnitById: `${iamOrganisationUnitBaseUrl}/getById`,
    getOrgUnitByName: `${iamOrganisationUnitBaseUrl}/getByName`,
    // addRoleInOrgUnit: `${iamOrganisationUnitBaseUrl}/roles?`,
    // deleteRoleInOrgUnit: `${iamOrganisationUnitBaseUrl}/roles?`,
    addUserInOrgUnit: `${iamOrganisationUnitBaseUrl}/users?`,
    deleteUserInOrgUnit: `${iamOrganisationUnitBaseUrl}/users?`,
    getUnassignUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/unassignedusers?`,
    getAssignUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/assignedusers?`,
    getUnassignRolesInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/unassignedroles?`,
    getAssignRolesInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/assignedroles?`,
    getUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/users?`,
    loadUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/getById`,
    //updateOrgUnit: `${iamOrganisationUnitBaseUrl}/save`,

    /** Roles API */
    getAllRoles: `${iamRolesBaseUrl}/getAllRoles`,
    getPaginateRoles: `${iamRolesBaseUrl}/getAllRoles?`,
    createRole: `${iamRolesBaseUrl}/save`,
    updateRole: `${iamRolesBaseUrl}/save-basic`,
    deleteRole: `${iamRolesBaseUrl}/delete/`,
    importRoleFromCSV: `${iamRolesBaseUrl}/import/roles/csv`,
    getRolesCount: `${iamRolesBaseUrl}/getRolesCount`,
    unAssignUserToRole: `${iamRolesBaseUrl}/unAssignUserToRole/`,
    unAssignGroupToRole: `${iamRolesBaseUrl}/unAssignGroupToRole/`,
    assignUserToRole: `${iamRolesBaseUrl}/assignUserToRole`,
    getUnAssignedGroupRoles: `${iamRolesBaseUrl}/getUnassignedGroupRoles?`,
    getAssignedGroupRoles: `${iamRolesBaseUrl}/getAssignedGroupRoles?`,
    assignGroupToRole: `${iamRolesBaseUrl}/assignGroupToRole`,
    getAllRolesByBookId: `${iamRolesBaseUrl}/getBookRoles?`,
    getAllRolesBySolutionId: `${iamRolesBaseUrl}/getSolutionRoles?`,
    getInformationLayer: `${nslIAMUrl}`,
    getDetailsByTargetRoleId: `${iamRolesBaseUrl}/getDelegationBetweenRoles/`,
    getEntitiesForDelegation: `${nslIAMUrl}/rights/fetchEntitiesFromBookOrSolution?`,
    getAttributesForDelegation: `${nslIAMUrl}/rights/fetchAttributesForEntity?`,
    createDelegation: `${iamRolesBaseUrl}/saveDelegation`,
    loadRoleDelegations: `${iamRolesBaseUrl}/getDelegations?`,
    deleteDelegation: `${iamRolesBaseUrl}/delegation?`,
    getDelegationById: `${iamRolesBaseUrl}/getDelegation?`,
    getAllUnassignedLDAPGroupsForRoles: `${iamRolesBaseUrl}/getAllUnassignedLDAPGroups?`,
    getAllAssignedLDAPGroupsForRoles: `${iamRolesBaseUrl}/getAllLDAPGroups?`,
    unassignLdapGroupForRoles: `${iamRolesBaseUrl}/unassignLdapGroup?`,
    assignLdapGroupsForRoles: `${iamRolesBaseUrl}/assignLdapGroups`,
    getLocalRoles: `${iamRolesBaseUrl}/getAllBookRoles?`,
    getAllBookTreePageable: `${iamRolesBaseUrl}/getAllBookTreePageable?`,
    getBookTreeById: `${iamRolesBaseUrl}/getBookTreeById?`,
    saveBookRole: `${iamRolesBaseUrl}/saveBookRole`,
    deleteBookRole: `${iamRolesBaseUrl}/deleteBookRole/`,
    getAssignedOwnersPageable: `${iamRolesBaseUrl}/getAssignedOwnersPageable?`,
    assignBookOwners: `${iamRolesBaseUrl}/assignOwners`,
    getAllRolesHierarchy: `${iamRolesBaseUrl}/getAllRolesHierarchy`,
    getBookRoleTree: `${iamRolesBaseUrl}/getBookRoleTree/`,
    saveIRDR: `${iamUserBaseUrl}/saveIRDRs`,
    getIRDRForBookOrSolution: `${iamRolesBaseUrl}/getIRDRForBookOrSolution?`,
    getIRDRForRolePageable: `${iamRolesBaseUrl}/getIRDRForRolePageable?`,
    getBookTreePageable: `${iamRolesBaseUrl}/getBookTreePageable?`,
    getAllOwners: `${iamRolesBaseUrl}/getAllOwners?`,
    //new role api
    getRoleWithBooks: `${iamRolesBaseUrl}/getRoleWithBooks`,
    createRoleb2c: `${iamRolesBaseUrl}/createRoleAndAttachToBooks`,
    getB2cRoles: `${iamRolesBaseUrl}/getB2cRoles`,
    deleteRoleb2c: `${iamRolesBaseUrl}/delete`,
    getRolesAttachedToBook: `${iamRolesBaseUrl}/getRolesAttachedToBook`,
    getRolesforBook: `${iamRolesBaseUrl}/getAllBookRoles`,
    getRolesByName: `${iamRolesBaseUrl}/getByName`,
    getPublicRoles: `${iamRolesBaseUrl}/getPublicRoles?`,

    deleteCustomLayout: `${dsdOrch}/design/tenant/v2/site/page/layout/`,
    fetchById: `${environment?.serverIp}/dsd-orch/solution-versioning/api/geFilter/findbyId`,
    updateById: `${environment?.serverIp}/dsd-orch/solution-versioning/api/geFilter/updateById`,
    save: `${environment?.serverIp}/dsd-orch/solution-versioning/api/geFilter/save`,
    customAttributes: `${environment?.serverIp}/dsd-orch/cdm/api/config/userAttributes/`,
    userCustomAttributes: `${dsdOrch}/cdm/api/config/userCustomAttributes/`,
    customDynamicAttributes: `${dsdOrch}/cdm/api/config/usersconfig`,
    deleteById: `${environment?.serverIp}/dsd-orch/solution-versioning/api/geFilter/deleteById`,

    /** Login, Loader and Fonts customisations */
    saveLoginDesign: `${dsdOrch}/design/tenant/v2/login/save`,
    fetchLoginDesign: `${dsdOrch}/design/tenant/v2/login/name`,
    deleteLoginDesign: `${dsdOrch}/design/tenant/v2/login/name`,

    /**Saas API */
    getPaginateSaasApplications: `${tenantBaseUrl}/saassolutionspage?`,
    createSaasApplication: `${tenantBaseUrl}/saassolution`,
    validateSaasApplication: `${tenantBaseUrl}/saassolutions/validate`,
    upgradeAllAssociatedTenants: `${tenantBaseUrl}/upgradeTenantSaasSolutionBySolUUId/`,
    associateAllAssociatedTenant: `${tenantBaseUrl}/assocforsolution/`,
    postSaaSApplications: `${tenantBaseUrl}/assoctenantsforsolution/`,
    getAssociatedSaasTenants: `${tenantBaseUrl}/tenants/all?`,
    getAssociatedSaasApplication: `${cdmOrchBaseUrl}/api/tenantSaasApps/saasApps/`,
    getAssociatedSaasApplications: `${cdmOrchBaseUrl}/api/tenantSaasApps/apps?`,
    getAssociatedTenantToApplication: `${cdmOrchBaseUrl}/api/tenantSaasApps/associatedSaasTenants?`,
    getBetDetailsByBetType: `${dsdBetsStoreUrl}/global/browse/Saas/bets`,
    fetchUnassociatedTenantList: `${cdmOrchBaseUrl}/api/tenantSaasApps/nonassociatedSaasTenants?`,
    fetchAssociatedTenantList: `${cdmOrchBaseUrl}/api/tenantSaasApps/associatedSaasTenants?`,
    saasApplicationImportedCdmInform: `${cdmOrchBaseUrl}/api/tenantSaasApps/`,

    /**subscription */
    createPlan: `${cdmApiUrl}/subscriptions/plans`,
    viewPlans: `${cdmApiUrl}/subscriptions/plans`,
    createViewFeature: `${cdmApiUrl}/subscriptions/features`,
    ViewFeature: `${cdmApiUrl}/subscriptions/featuresAsAddons/`,
    mapFeaturesToPlan: `${cdmApiUrl}/subscriptions/featureMappings`,
    getFeaturesUnmappedToPlan: `${cdmApiUrl}/subscriptions/featuresAsAddons/`,
    getPlanByName: `${cdmApiUrl}/subscriptions/plans/name/`,
    featuresMappedToPlan: `${cdmApiUrl}/subscriptions/plans/name/`,
    updatePlan: `${cdmApiUrl}/subscriptions/plans`,
    updateFeature: `${cdmApiUrl}/subscriptions/features`,
    deletePlan: `${cdmApiUrl}/subscriptions/plans/name/`,
    deleteFeatur: `${cdmApiUrl}/subscriptions/features/name/`,
    deleteFeatureMapping: `${cdmApiUrl}/subscriptions/featureMappings`,
    updateFeatureMapping: `${cdmApiUrl}/subscriptions/featureMappings`,
    meteringUrl: `${cdmApiUrl}/subscriptions/tenantsSubscriptions/featureMeteringData`,

    /* self onvoarding APIs */
    selfonboardTenantCreate: `${tenantBaseUrl}/saveSelfOnboardTenant`,
    selfonboardGetAllTenant: `${cdmOrchBaseUrl}/api/selfOnboard/allTenants`,
    selfonboardSignup: `${tenantBaseUrl}/signup`,
    selfonboardUpdateTenant: `${tenantBaseUrl}/updateSelfOnboardTenant/`,
    selfonboarddisableTenant: `${tenantBaseUrl}/disable/`,
    collaboratorAssign: `${cdmTenantUrl}/assign/tenantAdminUser?email=`,
    onboardClientUser: `${tenantBaseUrl}/subtenants/paged/tenant/`,
    selfSignup: `${iamUserBaseUrl}/createSelfSignUpUser`,
    selfSignupOtp: `${environment?.serverIp}/dsd-orch/nsl-iam/login/otp/generateOTPForSelfSignup`,
    verifySignupOtp: `${environment?.serverIp}/dsd-orch/nsl-iam/login/otp/validateOTPForSelfSignup`,
    getAdminsList: `${cdmTenantUrl}/environment`,

    /* Signup Configuration */
    getSignupConfig: `${cdmOrchBaseUrl}/api/config/tenantconfiguration/`,
    fetchSignupProperties: `${cdmOrchBaseUrl}/api/cdm/envConfigInfo?`,
    fetchGsiMappings: `${cdmOrchBaseUrl}/api/config/paginated/roles/gsiMappings`,
    fetchPublicGsiMappings: `${cdmOrchBaseUrl}/api/config/publicRoles/gsimappings`,
    deleteGsiMappings: `${cdmOrchBaseUrl}/api/config/tenantconfiguration/gsimappings/role/`,

    /* hot/cold config */
    hotConfiguration: `${environment?.dsdOrchUrl}/cdm/api/hwc/config/`,
    /* ui-control-creation */
    uiControlCreation: `${environment?.dsdOrchUrl}/design/ui-elem`,
    /*faceRecognitionConfiguration*/
    faceRecognitionConfiguration: `${cdmApiUrl}/config/facerecognitionconfig`,
    /*usersConfig*/
    userConfiguration: `${cdmApiUrl}/config/usersconfig`,
    /**tenants API */
    getPaginateTenants: `${tenantBaseUrl}/tenants/all?`,
    createTenant: `${tenantBaseUrl}/savetenant?`,
    saveTenantTheme: `${environment.dsdOrchUrl}/design/tenant/design`,
    getTenantTheme: `${environment.dsdOrchUrl}/design/tenant/design`,
    updateTenant: `${tenantBaseUrl}/updatetenant/`,
    getAllAssignSolution: `${tenantBaseUrl}/saassolutionspage?`,
    getTenentAssociatedSolutions: `${tenantBaseUrl}/assocfortenant/`,
    mapFeaturesToTenant: `${cdmApiUrl}/subscriptions/addonMappings`,
    featuresMappedToTenant: `${cdmApiUrl}/subscriptions/addonMappings/tenantName/`,
    deleteFeatureMapp: `${cdmApiUrl}/subscriptions/addonMappings`,
    updateFeatureMapp: `${cdmApiUrl}/subscriptions/addonMappings`,
    /**environment related api's */
    environmentsForTenant: `${tenantBaseUrl}/environments?tenantId=`,
    remainingEnv: `${tenantBaseUrl}/envRemaining?tenantId=`,
    createEnvironment: `${tenantBaseUrl}/environment`,
    /** book */
    getPaginateBooks: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/book/matching`,
    getPaginateGSIsBasedOnROle: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/matching`,
    getGsiIdFromMasterId: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/masterId`,
    gsiVersionsList: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/list/`,

    /** users API */
    getAllUser: `${iamUserBaseUrl}/getAllUsers?type=graph`,
    getAllModules: `${auditUrl}/audit/getAllDisplayName`,
    getPaginateUser: `${iamUserBaseUrl}/getAllUsers?`,
    getPaginateUsers: `${iamUserBaseUrl}/getAllUsersBySearchTerm?`,
    getAllUsersBasicInfo: `${iamUserBaseUrl}/getAllUsersBasicInfo?`,
    getPaginateUnAssignUsers: `${iamUserBaseUrl}/getUnAssignedManagerUsers?`,
    getUsersByRights: `${nslIAMUrl}/rights/fetchUsersForTriggerCu?`,
    createUser: `${tenantBaseUrl}/create/user`,
    updateUser: `${iamUserBaseUrl}/update/user?`,
    updateUserPassword: `${iamUserBaseUrl}/update/password?`,
    deleteUser: `${tenantBaseUrl}/delete/user?`,
    deleteBulkUsers: `${tenantBaseUrl}/bulk`,
    createSocialUser: `${iamUserBaseUrl}/createSocialUser`,
    confirmDeleteModal: `${iamUserBaseUrl}/delete/user?`,
    importUserFromCSV: `${nslIAMUrl}/user/import/userstoenv/csv`,
    updateUserFromCSV: `${environment?.dsdOrchUrl}/nsl-iam/api/user/update/users/csv`,
    tenantAdminupdateUserFromCSV: `${environment?.dsdOrchUrl}/cdm/api/cdm/bulk/update`,
    deleteUserFromCSV: `${tenantBaseUrl}/bulk/csv`,
    exportUsers: `${nslIAMUrl}/user/export/envusers/csv`,
    getPassword: `${nslIAMUrl}/user/random/string?generateAccordingToPasswordPolicy=true`,
    getActiveUser: `${iamUserBaseUrl}/getActiveUser`,
    getUsersCount: `${iamUserBaseUrl}/getUsersCount`,
    loadUnassignedRoleUsers: `${iamUserBaseUrl}/getUnAssignedRoleUsers?`,
    loadAssignedRoleUsers: `${iamRolesBaseUrl}/getRoleUsersById?`,
    loadUnassignedGroupUsers: `${iamUserBaseUrl}/getUnAssignedGroupUsers?`,
    loadAssignedGroupUsers: `${iamUserBaseUrl}/getUsersByFilter?`,
    loadUserDelegations: `${iamUserBaseUrl}/getDelegations?`,
    createDelega: `${iamUserBaseUrl}/saveDelegation`,
    getDetailsByTargetUserId: `${iamUserBaseUrl}/getDelegationBetweenUsersWithRule/`,
    deleteDelegat: `${iamUserBaseUrl}/delegation?`,
    getDelegatById: `${iamUserBaseUrl}/getDelegation?`,
    getTimeone: `${iamUserBaseUrl}/getAvailableTimezones`,
    //getTenantDesignData: `${environment.dsdOrchUrl}/design/tenant/public/design/`,
    getPasswordPolicyByAdmin: `${iamUserBaseUrl}/getPasswordPolicyByAdmin?`,
    setPasswordPolicyByAdmin: `${iamUserBaseUrl}/setPasswordPolicyByAdmin`,
    ////api/realm/setAccountLockParameters
    getAccountLockParameter: `${nslOTP}/api/realm/fetchAccountLockParameters?`,
    setAccountLockParameter: `${nslOTP}/api/realm/setAccountLockParameters`,

    getInvitationHistory: `${nslIAMUrl}/b2c/getB2CSharedNotifications`,
    invitingUsers: `${iamRolesBaseUrl}/addUserstoSpace`,
    getGsiDetail: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/`,
    getTracableGsiDetail: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsiWithTracebleAttribute/`,
    saveCreateGuestUser: `${cdmApiUrl}/config/guestUserConfiguration`,
    /** GE board */
    getAllGsisForgeBorad: `${nslbetAnalyzer}action-gsi/id?id=`,
    saveEntityCard: `${geEntitycard}save`,
    getAllEntitiesForRole: `${geEntitycard}role/`,
    getEntityCardData: `${geEntitycard}card/`,
    getEntityData: `${entityBoard}search/`,
    postSearchCall: `${dashboardUrl}facet-search/search/save`, // Save Searxh By making Post Call
    getAllSearchForEntityCard: `${dashboardUrl}facet-search/search/getSearch/`, // Fetch All Current Search Values for the Entity Card
    postSaveOrUpdateFilter: `${dashboardUrl}facet-search/filter/save/`, //Save or Update the given Filter
    getAllFiltersForEntityCard: `${dashboardUrl}facet-search/filter/getAllFilters/`, //Get All Filters for the Entity Card
    getParticularFilter: `${dashboardUrl}facet-search/filter/getFilter/`, // Get Particular Filter For Entity Card
    clearParticularSearch: `${dashboardUrl}facet-search/search/clearSearch/`, // Clear a particular Search for the Entity Card
    clearAllSearches: `${dashboardUrl}facet-search/search/clearAllSearches/`, // Clear all Searches for a Entity Card
    clearParticularFilter: `${dashboardUrl}facet-search/filter/clearFilter/`, // Clear a particular Filter for the Entity Card
    clearAllFilters: `${dashboardUrl}facet-search/filter/clearAllFilters/`, // Clear all filters for the Entity Card
    getAllConfigurationsByRoleAndEntityId: `${geEntitycard}configuration/role/entity/`, // get all configutaions entity card for specified role and entity
    deleteEntityCardById: `${geEntitycard}delete/card/`, // Delete  particular configuration
    getEntityCardByConfigurationName: `${geEntitycard}card/configuration/name/`,
    /**
     * * GE Expanded View
     */
    saveExpandedView: `${expandedView}save`,
    getCardByViewName: `${expandedView}view/name/`,
    getEntityCardViews: `${expandedView}entity/name/`,
    getExpandedRecords: `${dashboardUrl}expand-view/v1/entity/`,

    /**
     * * Entity Page Comments
     */
    commentsApi: `${commentsAndRatings}/`,
       /** GE Sharable Filters */
       sharableFilter: `${sharableFilters}filter/`,
       sharableFilters: `${sharableFilters}filters/`,
       favoriteFilters: `${sharableFilters}favorite/`,
       categoriesFilter: `${sharableFilters}categories/`,
    /** GE Board Cart */
    addToCart: `${query}/entity-cart/addToCart`,
    viewCart: `${query}/entity-cart/viewCart/`,
    updateCartQuantity: `${query}/entity-cart/updateCartQuantity/`,
    deleteCartItem: `${query}/entity-cart/deleteRecord/`,
    saveCart: `${entityCart}/save/`,
    getCartConfig: `${entityCart}/cart/`,
    /** AA dashboard */
    getEntitySearchData: `${dashboardUrl}txn-ge-search/search`,

    getSearchRecords: `${dsdOrch}/query/txn-ge-search/query`,
    /** FileExplorer API*/
    getAllBooks: `${customTenantUI}/assets/book-cover?`,
    getCardIcons: `${customTenantUI}/assets/card-icon?`,
    getCuIcons: `${customTenantUI}/assets/cu-icon?`,
    getSolutionIcons: `${customTenantUI}/assets/gsi-icon?`,
    getEntityIcons: `${customTenantUI}/assets/entity-icon?`,
    addBookCover: `${customTenantUI}/assets/book-cover`,
    addCardCover: `${customTenantUI}/assets/card-icon`,
    solutionAddIcon: `${customTenantUI}/assets/gsi-icon`,
    cuAddIcon: `${customTenantUI}/assets/cu-icon`,
    entityAddIcon: `${customTenantUI}/assets/entity-icon`,
    withFolder: `${dsdFileStore}folder/`,
    withoutFolder: `${dsdFileStore}folder`,
    deleteFolderImage: `${dsdFileStore}manage`,
    withFileFolder: `${dsdFileStore}folder/`,
    withoutFileFolder: `${dsdFileStore}folder?`,
    browseFile: `${dsdFileStore}browse?query=`,
    singleFileUpload: `${dsdFileStore}upload/file`,
    multipleFileUpload: `${dsdFileStore}upload/files`,
    base64FileUpload: `${dsdFileStore}upload/content`,
    uploadZipFile: `${dsdFileStore}upload/zip/file`,

    /** ERD API */
    uploadERDfile: `${environment.dsdOrchUrl}/extract/biz_notation`,
    saveImportedERDEntity: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/save/erd-entities`,
    allExtractedEntity: `${environment.dsdOrchUrl}/dsd-bets-store/erd/view/erd-entities?`,
    viewExtracedEntity: `${environment.dsdOrchUrl}/dsd-bets-store/erd/view/extracted-entity?`,
    deleteExtracedEntity: `${environment.dsdOrchUrl}/dsd-bets-store/erd/delete/extracted-entity?`,

    /** Gruop API*/
    getAllGroups: `${iamGroupsBaseUrl}/getAll`,
    getPaginateGroups: `${iamGroupsBaseUrl}/getAllGroups?`,
    createUpdateGroup: `${iamGroupsBaseUrl}/add`,
    loadUnassignedRoleGroups: `${iamGroupsBaseUrl}/getAllUnAssigned?`,
    loadAssignedRoleGroups: `${iamGroupsBaseUrl}/getAllAssigned?`,
    loadUnassignedLdapGroups: `${iamLdapGroupsBaseUrl}/getAllUnAssigned?`,
    loadAssignedLdapGroups: `${iamLdapGroupsBaseUrl}/getAllAssigned?`,
    loadAllLdapGroups: `${iamLdapGroupsBaseUrl}/getAll?`,
    assignLdapGroupToGroup: `${iamGroupsBaseUrl}/assignLdapGroups/`,
    unAssignLdapGroupToGroup: `${iamGroupsBaseUrl}/unassignLdapGroup?`,
    assignUsersToManager: `${nslOTP}/api/user/addUsersToManager?managerID=`,
    unAssignUserToManager: `${nslOTP}/api/user/unAssignUserToManager/`,

    assignedUsersList: `${nslOTP}/api/user/getReportees?userId=`,
    getActiveSessionsCount: `${nslOTP}/api/user/getActiveSessionsCount?clientId=`,
    getActiveUserSessionDetails: `${nslOTP}/api/user/getActiveUserSessionDetails?clientId=`,
    deleteGroup: `${iamGroupsBaseUrl}/delete`,
    /**Ldap API */
    createLDAP: `${nslIAMUrl}/ldap/add`,
    syncLDAPUsers: `${nslIAMUrl}/ldap/sync/users`,
    syncLDAPGroups: `${nslIAMUrl}/ldap/sync/groups`,
    syncLDAP: `${nslIAMUrl}/ldap/sync/usersgroups`,
    getLdapNames: `${nslIAMUrl}/ldap/getAll?`,
    ldapDetailsByName: `${nslIAMUrl}/ldap/get/`,
    getReportsForSolution: `${environment.dsdOrchUrl}/query/v3/report/txns/report`,

    /** manage audit API */
    getAuditData: `${auditUrl}/audit/getAuditData?`,
    getGeEntityData: `${auditUrl}/audit/exportGeDataAudit?`,

    /** EQL entities relation API */
    getEqlEntitiesRelation: `${query}/entity_relation/`,
    setEqlEntitiesRelation: `${query}/entity_relation/save`,

    elasticsearch: `${searchUrl}/v3/nsl/transactions/`,
    googleSearchAll: `${searchUrl}/google_search/all`,
    transactionUrlsecmecc: `${secmcc}`,
    eventsUrl: `${tfTransactionUrl}/event`,
    getEventExpressionDetails: `${serverIp}/dsd-orch/execute/expression?`,

    versionFinishURL: `${environment.dsdOrchUrl}/extsolns/api/version`,
    extsend: `${environment.dsdOrchUrl}/extsolns/api/ext_api/process`,
    solutionversioining: `${environment.dsdOrchUrl}/solution-versioning/api`,
    deleteGsiDraft: `${environment.dsdOrchUrl}/solution-versioning/api/version/discard/`,

    /** Marketplace Apis */
    ontologyMaster: `${dsdBetsStoreUrl}/ontology/master`,
    browseDetailed: `${globalBetsStore}/browse/detailed`,
    // browseDetailedOverview: `${globalBetsStore}/browse/detailed/overview/`,
    // browseOverview: `${globalBetsStore}/browse/overview/`,
    betsStoreImport: `${dsdBetsStoreUrl}/import/`,
    betStoreBookImport: `${dsdBetsStoreUrl}/import/book`,
    betStoreSolutionImport: `${dsdBetsStoreUrl}/import/gsi`,
    betsStorePublish: `${dsdBetsStoreUrl}/publish/`,
    importAndValidateFromBetStore: `${dsdBetsStoreUrl}/import/validate`,
    betsStorePublishtoNSL: `${dsdBetsStoreUrl}/publish/review/book/`,
    getBookEntityList: `${dsdBetsStoreUrl}/publish/fetch/entity/`,
    getBooksDetailList: `${dsdBetsStoreUrl}/publish/review/`,
    // nslBookOverview: `${dsdBetsStoreUrl}/global/browse/book/overview`,
    nslBookOverview: `${dsdBetsStoreUrl}/global/browse/overview`,
    // nslBookOverviewDetailed: `${dsdBetsStoreUrl}/global/browse/book/overview/detailed`,
    nslBookOverviewDetailed: `${dsdBetsStoreUrl}/global/browse/overview/detailed`,
    nslAddReview: `${dsdBetsStoreUrl}/review/library/review/add`,
    nslGetReviews: `${dsdBetsStoreUrl}/review/library/reviews`,

    // Get dashboard list for book
    getBookDashboardList: `${importexport}/bookId`,

    /** Marketplace notepad Apis */
    getNotepadList: `${dsdBetsStoreUrl}/browse/notepad`,

    /** Library overview Apis */
    myLibraryDetailedOverview: `${dsdBetsStoreUrl}/my-library/browse/detailed/overview/`,
    myLibraryOverview: `${dsdBetsStoreUrl}/my-library/browse/overview/`,

    /*personal connections apis */
    createPersonalConnection: `${environment.dsdOrchUrl}/adapter/api/v1/personal/connections`,
    getPersonalConnection: `${environment.dsdOrchUrl}/adapter/api/v1/personal/connections`,
    getPersonalConnections: `${environment.dsdOrchUrl}/adapter/api/v1/personal/connections`,
    oauthPersonalConnection: `${environment.dsdOrchUrl}/adapter/api/v1/personal/login/auth`,
    saveConnectioninprofile: `${environment.dsdOrchUrl}/adapter/api/v1/personal/oauth/code`,

    /** Solution Versioning Apis */
    betsStoreTenant: `${dsdBetsStoreUrl}/tenant/`,
    editPublishedBET: `${dsdBetsStoreUrl}/tenant/edit/`,
    solutionDiffGSI: `${environment.dsdOrchUrl}/solution-versioning/api/diff/GSI?draftId=`,
    solutionDiffCU: `${environment.dsdOrchUrl}/solution-versioning/api/diff/CU?draftId=`,
    solutionDiffEntity: `${environment.dsdOrchUrl}/solution-versioning/api/diff/entity?draftId=`,
    versionsListGsi: `${environment.dsdOrchUrl}/extsolns/api/version/v3/gsi`,
    versionsListInitiate: `${environment.dsdOrchUrl}/extsolns/api/version/v3/initiate`,
    versionFinishGSI: `${dsdBetsStoreUrl}/tenant/finish/gsi`,
    versionFinishCU: `${dsdBetsStoreUrl}/tenant/finish/change-unit`,
    versionFinishEntity: `${dsdBetsStoreUrl}/tenant/finish/entity`,
    versionPublishGSI: `${dsdBetsStoreUrl}/tenant/publish/gsi`,
    versionPublishCU: `${dsdBetsStoreUrl}/tenant/publish/change-unit`,
    versionPublishEntity: `${dsdBetsStoreUrl}/tenant/publish/entity`,
    versionReviewerGSI: `${environment.dsdOrchUrl}/solution-versioning/api/version/reviewer/`,
    versionReviewerCU: `${environment.dsdOrchUrl}/solution-versioning/api/cu/version/reviewer/`,
    versionReviewerEntity: `${environment.dsdOrchUrl}/solution-versioning/api/entity/version/reviewer/`,
    sendNotifications: `${notificationsUrl}/notification/send`,
    sendfilterNotifications: `${notificationsUrl}/notification/filter?page=0&size=10`,

    skipVersionPublishGSI: `${dsdBetsStoreUrl}/tenant/publish/gsi?skipApproval=true`,
    skipVersionPublishCU: `${dsdBetsStoreUrl}/tenant/publish/change-unit?skipApproval=true`,
    skipVersionPublishEntity: `${dsdBetsStoreUrl}/tenant/publish/entity?skipApproval=true`,
    detectReviewInfo: `${environment.dsdOrchUrl}/nsl-approvals/api/review/details/`,
    showReviewStatus: `${environment.dsdOrchUrl}/nsl-approvals/api/review/details/`,
    cancelReview: `${dsdBetsStoreUrl}/tenant/reject/`,
    gsiSafeGuard: `${tfCoreUrl}/safeguards/`,
    /** A2A api */
    extractionApi: `${environment.dsdOrchUrl}/a2aOrchestrator/extractFromFile`,
    extractionList: `${environment.dsdOrchUrl}/dsd-track/status-track`,
    extractedSolutionListApi: `${environment.dsdOrchUrl}/dsd-track/status/`,
    redact: `${environment.dsdOrchUrl}/v2_dpe/redact`,
    dicomFiletoJpg: `${environment.dsdOrchUrl}/v2_dpe/dcm_image`,
    manualTransaction: `${environment.dsdOrchUrl}/v2_dpe/manual_transact`,
    fileLoad: `${dsdFileStore}upload/file`,
    ontology: `${environment.dsdOrchUrl}/nsl-bet-store/bet/dsdMetadata/cu`,

    /** Entities*/
    reservedEntities: `${dsdBetsStoreUrl}/global/entity/reserved-action`,
    entityCount: `${tfCoreUrl}/Entity/matching`,
    getAllEntities: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity`,
    getExactEntityByName: `${environment.dsdOrchUrl}/core/Entity?entityName=`,
    getAttributeByEntityId: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity`,
    getAttributeByEntityIdForReservedActions: `${globalBetsStore}/entity/reserved-action/`,
    multipleEntitySave: `${dsdBetsStoreUrl}/tenant/multiple/entity`,
    /**attributes */
    listFunctions: `${tfCoreUrl}/attributeTypes/list/functions`,
    uiControls: `${tfCoreUrl}/UIAttribute`,
    attrTypes: `${tfCoreUrl}/attributeTypesWithDetails`,
    getAllAttributes: `${environment.dsdOrchUrl}/dsd-bets-store/custom-attribute/fetch`,
    getAttributesByName: `${environment.dsdOrchUrl}/dsd-bets-store/custom-attribute/fetch/name/`,
    getAttributesByID: `${environment.dsdOrchUrl}/dsd-bets-store/custom-attribute/fetch/id/`,

    /**labels */
    staticLabels: `${tfCoreUrl}/translator/tags/`,
    /**changeunit */
    ReservedCuList: `${dsdBetsStoreUrl}/change-unit/reserved/browse`,
    ReservedEntityList: `${dsdBetsStoreUrl}/entity/reserved/browse`,
    gsiLight: `${dsdBetsStoreUrl}/tenant/gsi/basic/`,
    savecu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/change-unit`,
    getBasicCu: `${dsdBetsStoreUrl}/tenant/change-unit`,
    convertCuToGsi: `${tfCoreUrl}/CU/convert`,
    getEmbededGsi: `${dsdBetsStoreUrl}/tenant/gsi/basic/deep/`,
    incomingDcds: `${tfCoreUrl}/all/incoming_dcd`,
    /** GSI API url's */

    addSeqCu: `${tfCoreUrl}/CU`,
    getAllGsis: `${dsdBetsStoreUrl}/tenant/gsi/matching`,
    getAllTenantBookB2c: `${dsdOrch}/nsl-iam/api/roles/CU`,
    getAllTenantBooks: `${dsdBetsStoreUrl}/tenant/book`,
    saveGsi: `${dsdBetsStoreUrl}/tenant/gsi`,
    saveTracableGsi: `${dsdBetsStoreUrl}/tenant/gsiWithTracebleAttribute`,
    multipleGsiSave: `${dsdBetsStoreUrl}/tenant/multiple/gsi`,
    saveBook: `${dsdBetsStoreUrl}/tenant/book`,
    getBookStatus: `${dsdBetsStoreUrl}/tenant/book/matching?`,
    getBookDesign: `${dsdOrch}/design/book/design`,
    gsiVersion: `${tfCoreUrl}/version/`,
    gsiDraft: `${tfCoreUrl}/GSI-Draft`,
    updateGsiRights: `${tfCoreUrl}/GSI/`,
    exportGsi: `${importexport}/Export/GSI`,
    exportApplication: `${importexport}/Export/Application`,
    exportApplicationToEnv: `${importexport}/Export/ApplicationToEnvironment?envType=`,
    importExportHistory: `${importexport}/application`,
    importGsi: `${importexport}/Import/GSI/File`,
    validateGsiFile: `${importexport}/validate/GSI/File`,
    downloadGeData: `${importexport}/search/GeDataExportRequest`,
    saveDraftCu: `${dsdOrch}/dsd-bets-store/tenant/change-unit`,
    fetchTriggerCu: `${dsdOrch}/dsd-bets-store/tenant/triggerCU`,
    saveCompositeGsi: `${dsdBetsStoreUrl}/tenant/gsi/composite-save`,
    convertGsiToJsonToXml: `${dsdOrch}/reverse_bpmn`,
    filteredTransactions: `${dsdOrch}/query/transaction/transactions-by-values`,
    /** Design Data */
    getBooksWithDesignData: `${dsdBetsStoreUrl}/tenant/book/matching?`,
    storeBookData: `${customTenantUI}/book/design`,
    resetDesignData: `${customTenantUI}`,
    storeCardData: `${customTenantUI}/book/card/design`,
    getIndustries: `${dsdBetsStoreUrl}/ontology/root`,
    getColors: `${customTenantUI}/assets/color?`,
    getWallpapers: `${customTenantUI}/assets/background-theme?`,
    getBookDetails: `${customTenantUI}/book/v2/design/`,
    getGsiDetails: `${customTenantUI}/book/v2/design/gsis/`,
    getCuDetails: `${customTenantUI}/book/v2/design/cus/`,
    getEntityDetails: `${customTenantUI}/book/v2/design/entities/`,
    // save gsi in a book  //
    saveGsiInBook: `${dsdBetsStoreUrl}/tenant/book/`,
    // layout customise apis //
    tenantPageLayout: `${customTenantUI}/tenant/v2/site/page/layout`,
    setRoleTOLayout: `${customTenantUI}/tenant/v2/site/config`,
    getMetaData: `${customTenantUI}/tenant/v2/site/metadata`,

    /** Ads Service */
    getAds: `${environment.dsdOrchUrl}/adserver/getAds`,
    adImpression: `${environment.dsdOrchUrl}/ads-collection/analytics/impression`,
    adClick: `${environment.dsdOrchUrl}/ads-collection/analytics/click`,
    getTopBannerAds:`${environment.dsdOrchUrl}/adserver/getAdsBySize`,

    /**Global Search */

    searchBooks: `${globalSearch}/book/`,
    searchGsi: `${globalSearch}/gsi/`,
    searchCu: `${globalSearch}/cu/`,
    searchEntity: `${globalSearch}/entity/`,
    searchAttributes: `${globalSearch}/attributes/`,
    googleSearch: `${dsdOrch}/search/google_search/all`,

    /** Notepad API*/

    globalSearchforNotepad: `${globalSearchforNotepad}`,
    saveNotepad: `${dsdBetsStoreUrl}/save/notepad`,
    getNotepadContent: `${dsdBetsStoreUrl}/notepad`,

    // Tenant Custom UI //
    fileUpload: `${environment.dsdOrchUrl}/dsd-files-store/upload/file`,
    customTenantUi: `${environment.dsdOrchUrl}/design/tenant/v2/public/design/`,
    sendTenantDesign: `${environment.dsdOrchUrl}/design/tenant/v2/design`,
    uploadContent: `${environment.dsdOrchUrl}/dsd-files-store/upload/content`,

    // MCC //
    mccRoles: `${environment.dsdOrchUrl}/design/tenant/v2/site/`,
    promoteMCC: `${environment.dsdOrchUrl}/design/migrate/mcc/`,

    // Admin BlockChain //
    blockchainAuth: `${environment.dsdOrchUrl}/blockchain/auth/`,
    accesskeys: `${environment.dsdOrchUrl}/blockchain/auth/keys`,
    generateAccess: `${environment.dsdOrchUrl}/blockchain/execute-chaincode-lifecycle`,
    newChannel: `${environment.dsdOrchUrl}/blockchain/create-new-channel`,
    createAccessKey: `${environment.dsdOrchUrl}/blockchain/auth/key`,
    deleteAccessKey: `${environment.dsdOrchUrl}/blockchain/auth/key/delete`,
    channelAsyncStatus: `${environment.dsdOrchUrl}/hlf/asyncstatus/search/`,
    channelAsyncStatusByGsiName: `${environment.dsdOrchUrl}/hlf/asyncstatus/search/gsi_name/`,
    resolveAsyncStatusById: `${environment.dsdOrchUrl}/hlf/asyncstatus/resolve/`,
    channelsOwnedByMe: `${environment.dsdOrchUrl}/blockchain/auth/channels/channel_owner/`,
    channelsOwnedByOthers: `${environment.dsdOrchUrl}/blockchain/auth/keys/tenant/`,
    gsiListByTenantName: `${environment.dsdOrchUrl}/blockchain/auth/gsi/`,
    entityListByGsiName: `${environment.dsdOrchUrl}/blockchain/auth/entity/`,
    getChannelList: `${environment.dsdOrchUrl}/blockchain/auth/channels`,
    saveChannelConfiguration: `${environment.dsdOrchUrl}/blockchain/encrypt/add`,
    updateChannelConfiguration: `${environment.dsdOrchUrl}/blockchain/encrypt/update`,
    deleteSolutionChannel: `${environment.dsdOrchUrl}/blockchain/encrypt/delete`,

    /** Carnival API urls */
    addUserProfile: `${environment.dsdOrchUrl}/design/user/profile`,
    getCarnivalFeatureFlag: `${cdmOrchBaseUrl}/api/featureflags/featureFlag?featureName=`,
    getCarnivalMatchingFeatureFlag: `${cdmOrchBaseUrl}/api/featureflags/featureFlagState?featureName=`,
    getAllFeatureFlags: `${cdmOrchBaseUrl}/api/featureflags/allFeatureFlags`,
    createFeatureFlag: `${cdmOrchBaseUrl}/api/featureflags/featureFlag`,
    getGSIsWithExecutionStatus: `${environment.dsdOrchUrl}/execute/executionStatus/`,
    generateOtpForLogin: `${environment.dsdOrchUrl}/nsl-iam/login/otp/generateOtpWithAuthentication?mobileNumber=`,
    validateGivenOtp: `${environment.dsdOrchUrl}/nsl-iam/login/otp/validateOtpWithAuthentication?mobileNumber=`,
    txnData: `${environment.dsdOrchUrl}/secmcc/transaction/`,
    entityData: `${environment.dsdOrchUrl}/secmcc/processmining/`,
    processMining: `${environment.dsdOrchUrl}/process-mining/`,

    //authentication policy
    getAuthPolicy: `${cdmApiUrl}/authconfig/get/current`,
    addAuthPolicy: `${cdmApiUrl}/authconfig/create`,
    updateAuthPolicy: `${cdmApiUrl}/authconfig/update`,

    // external configuration //
    getCategoriesURL: `${environment.dsdOrchUrl}/extsolns/ext_config/fetchCategories`,
    getSubCategoriesURL: `${environment.dsdOrchUrl}/extsolns/ext_config/fetchSubCategories/categoryName/`,
    getConfigurationURL: `${environment.dsdOrchUrl}/associate/reserved-entity/`,
    updateConfigurationURL: `${environment.dsdOrchUrl}/execute/update/entity-records`,
    updateTableDataURL: `${environment.dsdOrchUrl}/txn/txnData/update/entity/records/v2/`,

    // login configuration
    saveLoginConfig: `${cdmApiUrl}/customLogin/config`,

    //Calendar
    txnSearch: `${environment.dsdOrchUrl}/query/calendar/fetch/slots`,
    calendarScheduler: `${environment.dsdOrchUrl}/adapter/calendar/schedule`,
    calendarSlots: `${environment.dsdOrchUrl}/adapter/calendar/entity-records`,
    updateAcceptanceStatus: `${environment.dsdOrchUrl}/adapter/calendar/acceptanceStatus`,
    fetchCalendarEvents: `${environment.dsdOrchUrl}/adapter/calendar/records`,
    getCalendarNotifications: `${notificationsUrl}/notification/filter?`,

    //Eql api
    getEqlFilter: `${tfCoreUrl}/eql/FILTER`,
    getEqlOperator: `${tfCoreUrl}/eql/OPERATOR`,
    getEqlPredicate: `${tfCoreUrl}/eql/PREDICATE`,
    geteqlFunction: `${tfCoreUrl}/eql/function`,
    getEqlDateConstantValue: `${tfCoreUrl}/eql/datepart`,

    // EQL WhiteBoard
    eqlCanvasValidator: `${environment.dsdOrchUrl}/report/query-canvas/validator`,
    eqlBuilderValidator: `${environment.dsdOrchUrl}/report/query-validator/nsl-query`,

    //Adaptors endpoints
    testConnection: `${serverIp}/adapter/api/v1/connect/name/`,
    getaimlAdapter: `${environment.dsdOrchUrl}/aiml/api/v1/integration/`,
    addAdapter: `${environment.dsdOrchUrl}/adapter/api/v1/integration/`,
    entityExtract: `${environment.dsdOrchUrl}/adapter/api/v1/entity/extract/`,
    addAdapterv2: `${environment.dsdOrchUrl}/adapter/api/v2/integration/`,
    addAdapterv3: `${environment.dsdOrchUrl}/adapter/api/v3/integration/`,
    saveFileAsEntity: `${environment.dsdOrchUrl}/adapter/api/v1/converters/File`,
    add2file: `${environment.dsdOrchUrl}/adapter/api/v1/integration/file`,
    addinputfile: `${environment.dsdOrchUrl}/adapter/api/v1/integration/fileInput`,
    addoutputfile: `${environment.dsdOrchUrl}/adapter/api/v1/integration/fileOutput`,
    addSwagger: `${environment.dsdOrchUrl}/adapter/api/v1/resource/swagger-resources`,
    entityList: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity?`,
    listConnection: `${environment.dsdOrchUrl}/adapter/ext_config/fetchSubCategories/categoryName/Adapter`,
    viewAdapter: `${environment.dsdOrchUrl}/adapter/api/v3/integration/list?pageNumber=`,
    filterConnection: `${environment.dsdOrchUrl}/associate/reserved-entity/`,
    postrest: `${environment.dsdOrchUrl}/adapter/api/v1/connect/rest`,
    webhookURL: `${environment.dsdOrchUrl}/adapter/webhook/url/`,
    postsftp: `${environment.dsdOrchUrl}/adapter/connect/sftp`,
    aimlSubmit: `${environment.dsdOrchUrl}/aiml/api/v1/connect/`,
    addAdapteraiml: `${environment.dsdOrchUrl}/aiml/api/v1/integration`,
    aimlStatus: `${environment.dsdOrchUrl}/aiml/api/v1/status/connect`,
    aimlManulaTraining: `${environment.dsdOrchUrl}/aiml/api/v1/connect/notify_ml_engineer`,
    // postSSHsftp: `${environment.dsdOrchUrl}/adapter/api/v1/connect/sftp`,
    postS3: `${environment.dsdOrchUrl}/adapter/connect/s3`,
    postFacebook: `${environment.dsdOrchUrl}/adapter/connect/facebook`,
    postTwitter: `${environment.dsdOrchUrl}/adapter/connect/twitter`,
    postJira: `${environment.dsdOrchUrl}/adapter/connect/jira`,
    postLinkedin: `${environment.dsdOrchUrl}/adapter/connect/linkedin`,
    postDocusign: `${environment.dsdOrchUrl}/adapter/connect/docusign`,
    postGoogle: `${environment.dsdOrchUrl}/adapter/connect/google`,
    postZoom: `${environment.dsdOrchUrl}/adapter/connect/zoom`,
    postTelegram: `${environment.dsdOrchUrl}/adapter/connect/telegram`,
    postCisco: `${environment.dsdOrchUrl}/adapter/connect/cisco`,
    postGraph: `${environment.dsdOrchUrl}/adapter/connect/graph`,
    postKafka: `${environment.dsdOrchUrl}/adapter/api/v1/connect/kafka/`,
    postSMTP: `${environment.dsdOrchUrl}/adapter/connect/smtp/`,
    postIMAP: `${environment.dsdOrchUrl}/adapter/connect/imap`,
    postPOP3: `${environment.dsdOrchUrl}/adapter/connect/pop3`,
    postDB: `${environment.dsdOrchUrl}/adapter/connect/db`,
    connectionCheck: `${environment.dsdOrchUrl}/adapter/connect/sftp/test`,
    connectionDetails: `${environment.dsdOrchUrl}/adapter/api/v3/connections?`,

    //Permission Sets APIs
    fetchPermissionSets: `${nslIAMUrl}/po/get-all-features?`,
    addPermissionSets: `${nslIAMUrl}/po/save-bulk-permission-mapping`,

    //FE757
    //Elastic search
    saveTypeAheadSearchConfiguration: `${dsdOrch}/query/context-search/config`,
    fetchTypeAheadAttributeValues: `${dsdOrch}/query/txn-ge-search/attr_search`,
    attrSearchV2: `${dsdOrch}/query/txn-ge-search/attr_search/v2`,
    getentityName: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity/`,
    //subtransactional Entity
    getSubtransactionaEntity: `${dsdBetsStoreUrl}/subtransaction/subtransactionalEntity`,
    saveSubtransactionalGsi: `${dsdBetsStoreUrl}/subtransaction/subtransactionalCU`,
    //edit gsi
    publishedgsi: `${dsdBetsStoreUrl}/tenant/all-published-gsis`,
    publishGsiById: `${dsdBetsStoreUrl}/tenant/gsi/slot-item-properties`,
    editGsiDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/save-customization`,
    getIdDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/get-customization-by-targetid`,
    getReviewDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/review`,
    revertChangesDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/revert-changes`,
    //applyChangesDtrtApi: `http://10.16.2.111:9012/solution-versioning/solcustomization/apply-changes-to-Bet-store`,
    applyChangesDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/apply-changes-to-Bet-store`,
    //dtrt milestone
    getReviewMileStoneDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/milestones/review`,
    revertMileStoneDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/milestones/revert-changes`,
    applyMileStoneDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/milestones/apply-changes`,
    //dtrt irdr
    fetchDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/get-gsidto`,
    getReviewDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/review`,
    revertChangesDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/revert-changes`,
    applyChangesDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/apply-changes`,
    // ge irdr
    getReviewDtrtgeIRDR: `${dsdOrch}/solution-versioning/solcustomization/ge-irdr/review`,
    revertChangesDtrtgeIRDR: `${dsdOrch}/solution-versioning/solcustomization/ge-irdr/revert-changes`,
    applyChangesDtrtgeIRDR: `${dsdOrch}/solution-versioning/solcustomization/GE-IRDR/apply-changes`,
    //dtrt reserved action
    getReviewDtrtReservedAction: `${dsdOrch}/solution-versioning/solcustomization/reservedAction/review`,
    revertChangesDtrtReservedAction: `${dsdOrch}/solution-versioning/solcustomization/reserved-action/revert-changes`,
    applyChangesReservedActionApi: `${dsdOrch}/solution-versioning/solcustomization/reservedAction-add/apply-changes`,

    // dtrt promotion
    getSourceAndTargetEnvApi: `${dsdOrch}/solution-versioning/solcustomization/getSourceAndTargetEnv/customizations?envType=`,
    dtrtPromoteCustomizationApi: `${dsdOrch}/solution-versioning/solcustomization/promote-customizations?envType=`,

    //Node System Multiple Cu Save
    saveMultipleCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/multiple/change-unit`,
    saveTriggerCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/triggerCU`,
    updateCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/change-unit`,
    //Exceptional Category
    getExceptionalCategory: `${dsdOrch}/core/exception/categories`,

    // Ad Dashboard API
    getAdDashboard: `${dsdOrch}/ads-analytics/analytics/admin`,
    getAdAdvertiser: `${dsdOrch}/ads-analytics/analytics/advertiser`,
    getGsiAdDashboard: `${dsdOrch}/ads-analytics/gsiDetails`,
    getspend: `${dsdOrch}/ads-analytics/advertiser/v3/spent`,
    getBudget: `${dsdOrch}/advertisement/adsdata/budget`,
    getAdsAdDashboard: `${dsdOrch}/ads-analytics/analytics/Ads/filters`,
    getAllCampigns: `${dsdOrch}/advertisement/campaigns`,
    getAllCampaignsDetails: `${dsdOrch}/ads-analytics/analytics/filters`,
    getAdvertiserStreamAnalytics: `${dsdOrch}/ads-analytics/analytics/advertiser`,
    updateStatusOfCampaign: `${dsdOrch}/advertisement/campaign/status`,
    // Agency & advert -dashboard
    getTopPerformingDetails: `${dsdOrch}/ads-analytics/analytics/topNCampaigns`,

    // For Milestone
    getMilestone: `${dsdOrch}/milestones/v1/id/`,
    getAllMilestone: `${dsdOrch}/milestones/v1/milestones?`,
    saveMilestone: `${dsdOrch}/milestones/v1/milestone`,
    deleteMilestone: `${dsdOrch}/milestones/v1/delete/`,
    updateMilestone: `${dsdOrch}/milestones/v1/update`,

    //special feature dashboard
    specialFeatures: `${specialFeatures}/admin/dashboard/jobs`,
    scheduledJobs: `${specialFeatures}/admin/dashboard/view/jobs`,
    jobHistory: `${specialFeatures}/admin/dashboard/job/history`,
    rerunData: `${specialFeatures}/admin/dashboard/execute/job`,
    jobSuspension: `${specialFeatures}/admin/dashboard/job/suspend/V1`,
    jobResume: `${specialFeatures}/admin/dashboard/job/resume/V1`,
    jobDelete: `${specialFeatures}/admin/dashboard/job/delete/V1`,
    // geolocation
    geolocationurl: `http://ip-api.com/json`,
    getAllStatus: `${specialFeatures}/admin/dashboard/job/status`,
    getallspecialfeatures: `${dsdOrch}/core/specialFeatureTypes`,
    sfPostFactSentence: `${dsdOrch}/nsl2text/sf_sentence`,
    // Publisher Dashboard
    getPublisherDashboards: `${dsdOrch}/ads-analytics/analytics/publisher`,
    // Advertiser Dashboard
    getAdvertiserDashboards: `${dsdOrch}/ads-analytics/analytics/advertiser`,

    //milestone transactions
    notifyMilestone: `${dsdOrch}/milestones/v1/transaction/notify`,
    getParticularMilestone: `${dsdOrch}/milestones/v1/transaction/details?gsiId=`,
    getpercentMilestoneList: `${dsdOrch}/milestones/v1/transactions/percentage?gsiId=`,
    getMilestoneList: `${dsdOrch}/milestones/v1/transactions?gsiId=`,

    //gsi blocking
    getAllGsiInTenant: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/all`,
    updategsiConfigurationURL: `${environment.dsdOrchUrl}/execute/update/entity-records`,
    getGsiConfigurationURL: `${environment.dsdOrchUrl}/associate/reserved-entity/name/NSL_GSIMaintenance_Configuration`,
    saveGsiConfigurationURL: `${environment.dsdOrchUrl}/execute/save/entity-records`,
    // Pushback feature - FE757
    corePushbacks: `${dsdOrch}/core/pushbacks`,
    transPushbacks: `${dsdOrch}/txn/transaction/lifecycle/pushback`,
    pushbackChangeUnit: `${dsdOrch}/execute/pushback`,
    transPushbackComment: `${dsdOrch}/txn/transaction/lifecycle/comment`,

    //Scheduled jobs dash board APIs:
    getScheduledJobs: `${scheduledJobs}/v1/scheduled-job-details?`,
    getJobTriggers: `${scheduledJobs}/scheduled-gsi-triggers?`,
    getAllTriggers: `${scheduledJobs}/scheduled-triggers?`,
    gsiExecutions: `${scheduledJobs}/scheduled-gsi-executions?`,
    getUnscheduleJobs: `${scheduledJobs}/trigger/unschedule`,
    getUnscheduleTriggers: `${scheduledJobs}/trigger/unschedule`,
    pauseTrigger: `${scheduledJobs}/trigger/pause`,
    resumeTrigger: `${scheduledJobs}/trigger/resume`,
    pauseJob: `${scheduledJobs}/job/pause`,
    resumeJob: `${scheduledJobs}/job/resume`,

    /** Predective Analytics */
    paListUseCases: `${predectiveAnalytics}/ListUseCases`,
    paListFeatures: `${predectiveAnalytics}/ListFeatures`,
    fetchGsidetails: `${predectiveAnalytics}/gsi_attributes`,
    saveConfig: `${predectiveAnalytics}/SaveConfig`,
    updateConfig: `${predectiveAnalytics}/UpdateConfig`,
    tenantUsecases: `${predectiveAnalytics}/TenantUsecases`,
    deleteConfig: `${predectiveAnalytics}/delete_configid`,
    reservedRecomendation: `${environment.dsdOrchUrl}/nslgrammar/recommend_rcu`,
    populateReservedCU: `${environment.dsdOrchUrl}/nslgrammar/populate_rcu`,
    getMethodName: `${environment.dsdOrchUrl}/nslgrammar/get_method_names`,
    featureValues: `${predectiveAnalytics}/FeatureValues`,
    predictionsData: `${predectiveAnalytics}/DashboardPredictions`,
    chartSelection: `${predectiveAnalytics}/ChartSelection`,
    fetchFeatureDetail: `${dsdOrch}/api/po/get-all-features`,
    downloadfileApi: `${dsdOrch}/adapter/execute/swagger-json/`,

    //Dymamic schedule job
    scheduleJob: `${dsdOrch}/special-features/dynamic/schedule/jobs`,
    saveScheduleJob: `${dsdOrch}/special-features/dynamic/schedule/job`,
    getEntityByName: `${tfCoreUrl}/Entity`,
    getSubscriptionList: `${cdmApiUrl}/subscriptions/tenantsSubscriptions/tenantName/`,
    activateNow: `${cdmApiUrl}/subscriptions/tenantsSubscriptions/tenantName/`,
    getAllPlans: `${cdmApiUrl}/subscriptions/plans/planAndFeatures/`,
    getPlans: `${cdmApiUrl}/subscriptions/plans/paginated/planAndFeatures`,
    unsubscribePlan: `${cdmApiUrl}ext_api/process`,
    rearrangeCU: `${environment.dsdOrchUrl}/dsd-bets-store/tenant`,
    StripeRedirectBuyPlan: `${cdmApiUrl}/payments/createSession/subscriptionPurchase`,
    StripeRedirectBuyAddOn: `${cdmApiUrl}/payments/createSession/addon/`,

    //Key management
    saveManageKey: `${dsdOrch}/adapter/api/v1/keys`,
    //My Library
    libraryConfig: `${environment.dsdOrchUrl}/design/tenant/v2/config`,
    libraryLayout: `${environment.dsdOrchUrl}/design/tenant/v2/site/page/layout`,
    //get All campaign List for Advertiser dashboard
    getAllCampaignList: `${dsdOrch}/advertisement/adsdata/campaign/campaignList`,
    getAllSolutionList: `${dsdOrch}/ads-analytics/analytics/solutionList`,
    download: `${dsdOrch}/query/dashboards/export/`,
    subscribedsh: `${environment.dsdOrchUrl}/query/dashboards/export/subscribe/`,
    // Related GSIs list
    relatedGsis: `${specialFeatures}/related/connectors`,
    //RearrangeEntity
    reArrangeEntity: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/insert/entity`,

    //business details
    saveBusiness: `${environment.cdmServerAPI}/api/config/businessDetails/`,
    saveHolidayDetails: `${environment.cdmServerAPI}/api/config/holidays/`,
    saveKeyDetails: `${environment.cdmServerAPI}/api/config/keyManagementPersonnel/`,
    getKeyDetails: `${environment.cdmServerAPI}/api/config/KeyManagementPersonnel/`,
    //batch-processing api Import/Export
    batchAPI: `${dsdOrch}/batch-processor/batch-api/`,
    versionList: `${dsdOrch}/milestones/v1/transactions/versions?gsiId=`,
    assignAsTenant: `${environment.cdmServerAPI}/api/cdm/assign/tenantAdminUser?email=`,
    unassignAsTenant: `${environment.cdmServerAPI}/api/cdm/unassign/tenantAdminUser?email=`,
    assignAsEnv: `${environment.cdmServerAPI}/api/cdm/assign/`,
    unassignAsEnv: `${environment.cdmServerAPI}/api/cdm/unassign/`,

    CUToGSIConversion: `${dsdOrch}/core/CU/convert`,
    deleteCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/remove/CU`,

    // onboarding tenant admin configuration
    cdmCompanyProfile: `${cdmOrchBaseUrl}/api/config/companyProfiles`,
    saveBooksAndSolutionsInCart: `${dsdBetsStoreUrl}/cart/update/items`,
    cartDetails: `${dsdBetsStoreUrl}/cart/details`,
    updateCartDetails: `${dsdBetsStoreUrl}/cart/update/status`,
    bdpInfo: `${cdmOrchBaseUrl}/api/config/bdpinfo`,
    freelancerInfo: `${cdmOrchBaseUrl}/api/config/freelancerinfo`,
    tenantconfiguration: `${cdmOrchBaseUrl}/api/config/tenantconfiguration`,
    gsiMappingsByRole: `${cdmOrchBaseUrl}/api/config/tenantconfiguration/gsimappings/`,

    // campaign api
    generateCampaign: `${cdmOrchBaseUrl}/api/campaign/generate`,

    //file otp validation
    fileOtpValidation: `${environment.dsdOrchUrl}/dsd-files-store/secureotp`,
    refundProcess: `${cdmApiUrl}/subscriptions/cancellation/refund/`,
    updateProfile: `${tenantBaseUrl}/update/selfuser?originalEmail=`,
    passwordReseting: `${nslOTP}/api/user/update/password/v2?type=graph`,
    botbasic: `${cdmApiUrl}/chatbotConfiguration/chatbotDetails/`,
    faqSave: `${dsdOrch}/chatbot/api/v1/`,
    unassignedTenant: `${environment.cdmServerAPI}/api/cdm/unassociated/saas/tenants/`,
    assignedTenant: `${environment.cdmServerAPI}/api/cdm/assocforsolution/`,
    availableUser: `${iamOwnershipBaseUrl}/page/available/users`,
    getBooksByOwner: `${iamOwnershipBaseUrl}/filter/books`,
    getSolutionsByOwner: `${iamOwnershipBaseUrl}/filter/gsis`,
    assignOwnerToBookSol: `${iamOwnershipBaseUrl}/assign-users`,
    unassignOwnerToBookSol: `${iamOwnershipBaseUrl}/unassign-users`,
    assignedOwnerToBookSol: `${iamOwnershipBaseUrl}/page/assigned/users`,

     //GSI training in chatbot
     saveGSIConfiguration: `${dsdOrch}/chatbot/api/v1/botGsiConfig/save`,
     getGSIConfiguration: `${dsdOrch}/chatbot/api/v1/botGsiConfig/get`,
     fetchGSIDetails: `${dsdOrch}/nsl-bet-store/bet/gsi/deep/id`,

    //BET Name Validations
    validateEntityName: `${dsdOrch}/nsl-bet-store/bet/entity/name/`,
    validateGSIName: `${dsdOrch}/nsl-bet-store/bet/gsi/name/`,
    validateCUName: `${dsdOrch}/nsl-bet-store/bet/cu/name/`,
    entityAttributes: `${dsdOrch}/nsl-bet-store/bet/entity/nslAttributeByEntityName/`,
    validateBookName: `${dsdOrch}/bet/book/getBookByName/`,
    validateConnectionName: `${environment.dsdOrchUrl}/adapter/import_export/getConnectionByName`,
    // validateBETCount: `${}`,
    // validateDashboardName: `${}`,
    // validateDatasetName: `${}`
    viewClients: `${cdmApiUrl}/cdm/subtenants/paged/tenant/`,
    createClient: `${cdmApiUrl}/cdm/subtenants/`,
    onAssigningSubTenant: `${nslIAMUrl}/tenantspace/sharebookstosubtenants`,
    mapRoleToParent: `${nslIAMUrl}/rolemapping/save?isParent=true`,
    mappingRoles: `${nslIAMUrl}/rolemapping/mapped-unmapped/roles/?`,
    typeAheadSearch: `${environment.dsdOrchUrl}/query/global/type-ahead/v2/my-library`,
    typeAheadSearchNSLLibrary: `${globalSearchforNotepad}/type-ahead/nsl-library?searchCriteria=`,
    advTypeAheadSearchNSLLibrary: `${globalSearchforNotepad}/v2/type-ahead/nsl-library`,
    getSearchResults: `${environment.dsdOrchUrl}/query/global/search/v2/my-library`,
    viewAllSearchResults: `${environment.dsdOrchUrl}/query/global/view-all/v2/my-library`,
    getCardIds: `${environment.dsdOrchUrl}/design/entity-card/getcardIds`,
    getLanguagesforFaqDoc: `${dsdOrch}/chatbot/api/v1/bot_languages/fetch`,
    faqFileUploadHistory: `${dsdOrch}/chatbot/api/v1/faq/listDocuments`,
    unTrainFileUploadHistory: `${dsdOrch}/chatbot/api/v1/untrain/listDocuments`,
    faqFileDownload: `${dsdOrch}/chatbot/api/v1/faq/downloadDocument`,
    getEntitiesForText2SQL: `${dsdOrch}/internal/text2sql/api/v1/enableText2SQLForEntity`,
    getSchema: `${dsdOrch}/internal/text2sql/api/v1/getDummyDataSchema`,
    DummyData: `${dsdOrch}/internal/text2sql/api/v1/getDummyDataExcel`,
    unTrainFileDownload: `${dsdOrch}/chatbot/api/v1/untrain/downloadDocument`,

    // Header Customization APIs
    header: `${customTenantUI}/tenant/header`,
    getHeader: `${customTenantUI}/tenant/header/role/`,

    deleteAssigned: `${nslIAMUrl}/rolemapping/subtenantrolemappings/`,
    getGSI: `${nslIAMUrl}/owner-ship/filter/gsis?`,

    createGuestUser: `${nslIAMUrl}/user/createGuestUser`,
    /* AR */
    detectARImage: `https://preqa.nslhub.com/nsl_ar/detect`,
    trainARGsi: `https://preqa.nslhub.com/nsl_ar/train`,
    detectArEntity: `https://preqa.nslhub.com/nsl_ar/get_ar_cu_details`,
    publishtoBlockChain: `${blockchain}/api/translate`,
    testEmailConfig: `${environment.dsdOrchUrl}/extsolns/executor/email_config_test`,
    /* Trigger CU level */
    triggerCuAttrUiElement: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/change-unit/uiElement`,
    attributesComparison: `${tfCoreUrl}/triggercu/attribute/properties`,
    customTemplate: `${cdmApiUrl}/config/signup/template/upload`,
    customTemplateUsers: `${cdmApiUrl}/config/login/template/upload`,
    restPasswordPolicyforTenant: `${nslIAMUrl}/realm/resetPasswordPolicyByPlatformAdmin?realmName=`,
    userAsTenantAdmin: `${cdmTenantUrl}/assign/platform-admin/tenantAdminUser`,
    updatePswd: `${iamUserBaseUrl}/update/tenantAdminPasswordByPlatformAdmin?password=`,
    /** Collections */

    saveCollectionMCC: `${dsdBetsStoreUrl}/collection/save`,
    deleteItem: `${dsdBetsStoreUrl}/collection/removeItem`,
    deleteCollection: `${dsdBetsStoreUrl}/collection/deleteCollection`,
    getCollectionByName: `${dsdBetsStoreUrl}/collection/getCollection/`,
    getAllCollections: `${dsdBetsStoreUrl}/collection/getAllCollectionSpace?`,
    //paragraph-canvas
    blockParser: `${environment.dsdOrchUrl}/paragraph_grammar/block_parser/`,
    parseParagraph: `${environment.dsdOrchUrl}/paragraph_grammar/parse_paragraph/`,
    excelParser: `${environment.dsdOrchUrl}/paragraph_grammar/excel_parser/`,
    excelEntityParser: `${environment.dsdOrchUrl}/paragraph_grammar/parse_excel_entites/`,
    existingBetCheck: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/flat`,
    betUpdate: `${environment.dsdOrchUrl}/paragraph_grammar/update_bets`,
    freeFlow: `${environment.dsdOrchUrl}/paragraph_grammar/free_flow_parser`,
    betCount: `${environment.dsdOrchUrl}/paragraph_grammar/bet_count`,
    recommendSentence: `${environment.dsdOrchUrl}/paragraph_grammar/recommend_sentence`,
    recommendGsi: `${environment.dsdOrchUrl}/paragraph_grammar/recommend_gsi`,
    bpmnParser: `${environment.dsdOrchUrl}/paragraph_grammar/extract_bpmn`,
    autoDcd: `${environment.dsdOrchUrl}/paragraph_grammar/populate_dcds`,
    getNspMetaData: `${environment.dsdOrchUrl}/paragraph_grammar/filter_gpt_data?`,
    functionalParser: `${environment.dsdOrchUrl}/paragraph_grammar/functional_parser`,
    excelTemplate: `${environment.dsdOrchUrl}/paragraph_grammar/download_gsi_template`,
    entityTemplate: `${environment.dsdOrchUrl}/paragraph_grammar/download_entity_template`,
    structuredFreeFlow: `${environment.dsdOrchUrl}/paragraph_grammar/free_flow_parser?is_structured=`,

    /* location tracking APIs*/
    locationTracking: `${cdmApiUrl}/locationtracking/configuration`,
    locationUpdate: `${dsdOrch}/query/location/update`,
    saveConnection: `${environment.dsdOrchUrl}/adapter/connect/`,

    // grammar API
    labelMap: `${dsdOrch}/nslgrammar/label_map`,

    //orgunit type in dashboard
    orgUnitUser: `${dsdOrch}/nsl-iam/api/orgUnits/getById/`,

    //  DigitalMind
    digitalMind: `${digitalMind}`,
    socketEndpoint: `${digitalMind}/digitalmind/socket.io`,

    // country codes
    countryCode: `${environment?.serverIp}/dsd-orch/nsl-iam/login/otp/getCountryCodes`,
    transactionOwner: `${environment.dsdOrchUrl}/query/transaction/getById/`,
    sendLocationData: `${environment.dsdOrchUrl}/txn/txnData/save/slotData`,
    getLocationData: `${environment.dsdOrchUrl}/txn/txnData/fetchCUData/`,

    /* loader Apis */
    loader: `${customTenantUI}/loader`,
    loaderSave: `${customTenantUI}/loader/save`,
    // attributeSuggestion: `${environment?.serverIp}/nsl-grammar-ms0/attribute_datatype`,
    attributeSuggestion: `${environment?.serverIp}/dsd-orch/nsl-grammar-ms0/attribute_datatype`,
    updateSignUpGSIExecutedSuccessfully: `${iamUserBaseUrl}/signUpGsiExecution/completed?gsiExecutedSuccessfully=`,
    LoginConfiguration: `${cdmApiUrl}/config/loginconfig`,
    LogoutConfiguration: `${cdmApiUrl}/config/logoutconfig`,
    txnHistoryConfig: `${cdmApiUrl}/config/txnHistoryConfig`,

    /* Superset dashboard */
    saveSupersetDashboard: `${environment.dsdOrchUrl}/query/superset/dashboard/create`,
    manageTemplates: `${environment.dsdOrchUrl}/design/tenant/mccTemplate`,
    manageTemplatesByType: `${environment.dsdOrchUrl}/design/tenant/mccTemplatebyType`,
    promoteDynamicTemplate: `${environment.dsdOrchUrl}/design/migrate/mccTemplates`,
    campaignGsiLogin: `${nslIAMUrl}/login/token/confidential`,
    exportSupersetDashboard: `${environment.dsdOrchUrl}/query/superset/dashboard/export`,
    importSupersetDashboard: `${environment.dsdOrchUrl}/query/superset/dashboard/import`,

    /**DCD transaformation */
    getNonTemplateRCU: `${environment.dsdOrchUrl}/txn/dataTypeReservedCUNames`,
    getRCUFunctionName: `${environment.dsdOrchUrl}/txn/reservedCUFunctions`,
    //importExportTranslation Api
    importExportTranslation: `${environment.dsdOrchUrl}/translator/export/gsi`,
    importTranslation: `${environment.dsdOrchUrl}/translator/import/gsi`,
    searchChannel: `${environment.dsdOrchUrl}/blockchain/encrypt/search/`,
    channelmanageList: `${environment.dsdOrchUrl}/blockchain/encrypt/search`,
    loadTenantOnAdmin: `${cdmApiUrl}/clientIds`,
    saveFooter: `${environment.dsdOrchUrl}/design/tenant/footer`,
    saveQuickLink: `${environment.dsdOrchUrl}/design/tenant/quicklinks`,
    getQuickLink: `${environment.dsdOrchUrl}/design/tenant/quicklinks-bar/role`,
    deleteQuickLinks: `${environment.dsdOrchUrl}/design/tenant/quicklinks-bar/role/`,
    supportedJobNames: '/scheduler/handleNotifications-api/supportedJobNames',
    filterJobs: 'scheduler/handleNotifications-api/filterJobs',
    cancelScheduledJobs:
      'scheduler/handleNotifications-api/cancelScheduledJobs',
    getUserIdByName: `https://preqa.nslhub.com/irdr/api/user/ids`,
    getCreatedById: `https://preqa.nslhub.com/irdr/api/user/name`,
    searchEntityBasedCriteria: `${environment.dsdOrchUrl}/importexport/search/GeDataSearchRequest?`,
    entityIndex: `${environment.dsdOrchUrl}/nsl-entity-store/index/`,
    deactivate: `${environment.dsdOrchUrl}/cdm/api/cdm/user/deactivate`,

    /*Book page Customisation */
    design: `${environment.dsdOrchUrl}/design/tenant`,
    designBookPage: `${environment.dsdOrchUrl}/design/tenant/bookpage`,

    // Digital CEO APIs
    nhbot: `${nhbot}/get_answer`,
    schedular: `${environment.dsdOrchUrl}/adapter/api/v1/argoApi/`,
    nhmindConfig: `${environment.dsdOrchUrl}/design/tenant/nhmind/config`,
    getChatHistory: `${environment.paasLiveChatAgent}/chatsession/loadHistory`,
    dceoFeedBack: `${dashboardUrl}digital-ceo/feedback`,
    dceoRoles: `${environment.dsdOrchUrl}/design/tenant/nhmind/config/all`,
    getRoleId: `${environment.dsdOrchUrl}/design/tenant/nhmind/config/role`,
    nhmindColorConfig: `${environment.dsdOrchUrl}/design/tenant/dceo/color`,
    feedbackConfig: `${environment.dsdOrchUrl}/design/tenant/feedback/config`,

    assignedData: `${environment.dsdOrchUrl}/secmcc/transaction/createdBy/status?status=`,
    //manage language
    getTenantLanguages: `${translator}/language/tenantLanguage`,
    getExcludedLanguages: `${translator}/language/excluded`,
    deleteLanguage: `${translator}/language/delete`,
    addLanguage: `${translator}/language/tenant`,

    //otp password lock policy
    getOTPLockPolicy: `${environment.dsdOrchUrl}/cdm/api/config/otpaccountlockconfig/`,
    setOTPLockPolicy: `${environment.dsdOrchUrl}/cdm/api/config/otpaccountlockconfig`,
    updateOTPLockPolicy: `${environment.dsdOrchUrl}/cdm/api/config/otpaccountlockconfig`,
    deleteConfiguration: `${environment.dsdOrchUrl}/cdm/api/config/otpaccountlockconfig`,
    //get All Bets Count in Tenanat
    getBetCounts: `${dsdBetsStoreUrl}/tenant/count`,

    // Multisession policy
    multisessionPolicy: `${nslIAMUrl}/realm/multiSessionPolicy`,
    //multiple file download
    extsolns: `${environment.serverIp}/extsolns/pdf/merge`,
    //Mapper Reserved CU APIs
    getMapperSourceEntities: `${environment.dsdOrchUrl}/adapter/mapper/cu/source-entities/`,
    getMapperTargetEntities: `${environment.dsdOrchUrl}/adapter/mapper/cu/target-entities/`,
    getOperators: `${environment.dsdOrchUrl}/adapter/mapper/arithmetic-operators`,
    getFunctionCategories: `${environment.dsdOrchUrl}/adapter/mapper/function-categories`,
    getFunctionDetails: `${environment.dsdOrchUrl}/adapter/mapper/functions`,

    // Admin level saved filters for entity cards
    getFilterForEntityCard: `${environment.dsdOrchUrl}/query/facet-search/entityCards/`,
    setTokenExpiry: `${iam}/client/setTokenExpiry`,
    getTokenExpiry: `${iam}/client/getTokenExpiry`,
    resetTokenExpiry: `${iam}/client/resetTokenExpiry`,

    //for Entity upgrade in other BETs
    getAllCuWithArchivedEntity: `${tfCoreUrl}/upgrade/cu/entity/`,
    getAllGsiWithArchivedEntity: `${tfCoreUrl}/upgrade/gsi/cu/`,
    getAllEligibleBetsWithGsi: `${tfCoreUrl}/upgrade/gsi/eligible-bet/`,
    upgradeGsi: `${tfCoreUrl}/upgrade/validate/gsi`,
    saveUpgradedGsi: `${tfCoreUrl}/upgrade/save/gsi`,
     //for Calender slot integration
     executeSlot: `${environment.dsdOrchUrl}/execute/slot-booking`,
     gsiTrxData: `${environment.dsdOrchUrl}/mcc`,

     //terms and conditions
     getTNCforAllRoles: `${environment.dsdOrchUrl}/cdm/api/config/tnc/publicRole`,
     saveTnCforaRole: `${environment.dsdOrchUrl}/cdm/api/config/tnc`,
     deleteTnCforaRole: `${environment.dsdOrchUrl}/cdm/api/config/tnc`,
     getTnCbyClientId: `${environment.dsdOrchUrl}/cdm/api/config/tnc`,
     getDefaultTnCForTenant: `${environment.dsdOrchUrl}/cdm/api/config/tnc/default`,
     updateDefaultTnCForTenant: `${environment.dsdOrchUrl}/cdm/api/config/tnc/default`,
     updateStatus: `${environment.dsdOrchUrl}/cdm/api/config/tnc`,
     getTnCbyClientIdAndRole: `${environment.dsdOrchUrl}/cdm/api/config/tncByclientIdAndRole`,

     //Fetch GSI using name
     getGsiByName: `${tfCoreUrl}/GSI/name/`,
      //rule editor
    getReservedCus: `${dsdBetsStoreUrl}/tenant/gsi/cu`,
    ruleEditor: `${environment.dsdOrchUrl}/rule-engine/api/rules`,
    ruleEditor2: `${environment.dsdOrchUrl}/rule-engine`,
    applyRules: `${environment.dsdOrchUrl}/rule-engine/api/apply-rule/rules`,
    //Lock Account Reset
    getLockedAccountDetails: `${nslIAMUrl}/realm/fetchUserLockedAccountDetails/user`,
    resetLockedAccountUser: `${nslIAMUrl}/realm/resetLockedAccountUser/user`,
    resetLockedAccountForAllUsers: `${nslIAMUrl}/realm/resetLockedAccountForAllUsers`,

    dcdValidate: `${environment.dsdOrchUrl}/core/component/validate`,
     fetchAllSubAdminGroups: `${cdmApiUrl}/subadmin/all`,
     subAdminData: `${cdmApiUrl}/subadmin`,
     subAdminGroupUser: `${nslIAMUrl}/groups/`,
     getGroupAndUserDetails: `${nslIAMUrl}/groups/getByName/`,

      /*exception-cu */
    sendDataExceptionCu: `${dsdOrch}/core/exception/attach/cu`,
    // for postexceptiomns
    postExceptions: `${environment.dsdOrchUrl}/execute/post-exception-action `,
    //Kanban Board APIs
    entityRecordValidations: `${tfCoreUrl}/EntityData/validation-config`,
    entityRecordUpdate: `${dsdOrch}/txn/txnData/validate/update/record`,
    //GsiDeepClone
    deepClone:`${dsdOrch}/core/GSI/deepclone`
  };
}
