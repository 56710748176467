declare var require: any;
let transform = require('node-json-transform').transform;
let label_map = {
  nlg: 0,
  'Hum.': 1,
  'Mch.': 2,
  'life agent': 3,
  'Int.': 4,
  'Obj.': 5,
  'Att.': 6,
  const: 7,
};
export function compositeExtractionMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      physical: 'physical',
      information: 'information',
      nested_response: 'nested_response',
      triggerCES: 'triggerCES',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return mapLabelData(val, label_map);
        },
        on: 'physical',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return mapLabelData(val, label_map);
        },
        on: 'information',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return mapLabelData(val, label_map);
        },
        on: 'triggerCES',
      },
    ],
  };

  const result = transform(data, map);
  // to know the level in which the GSI is present, useful for nested embeded GSI
  return result;
}
function swapKeyValue(json: any) {
  var ret:any = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
}
function mapLabelData(val: any, label_map: any) {
  if (val?.length > 0) {
    label_map = swapKeyValue(label_map);
    val?.forEach((tags: any) => {
      if (typeof tags?.tag === 'number') {
        tags.tag = label_map[tags?.tag];
      }
    });
  }
  return val;
}
