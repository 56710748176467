import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '@common-services';

@Injectable({
  providedIn: 'root',
})
export class ManagePersonalConnectionsEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }
  createConnection(adaptertype: any, data: any) {
    return this.http.post(`${this.api?.createPersonalConnection}?type=${adaptertype}`, data);
  }
  getPersonalConnection(adaptertype: any) {
    return this.http.get(`${this.api?.getPersonalConnection}?type=${adaptertype}`);
  }
  getPersonalConnections() {
    return this.http.get(`${this.api?.getPersonalConnections}`);
  }
  oauthPersonalConnection(adaptertype: any) {
    return this.http.get(`${this.api?.oauthPersonalConnection}?type=${adaptertype}`);
  }
  saveConnection(code: any, state: any) {
    return this.http.post(`${this.api?.saveConnectioninprofile}?code=${code}&state=${state}`, {});
  }
}
