import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService } from '../translator/translator.service';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() pageIndex = 0;
  @Input() pageSize: number;
  @Input() totalRecords: number;
  @Input() dynamicClass: boolean;
  @Input() type: string = '';
  @Output() onPageChange = new EventEmitter();
  totalPages: number;
  labels: any;
  private ngUnsubscribe = new Subject();

  constructor(private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    if (this.pageIndex === -1) {
      this.pageIndex = 0;
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnChanges(changes: any) {
    if (this.pageSize !== undefined && this.totalRecords !== undefined) {
      this.totalPages = Math.ceil(this.totalRecords / this.pageSize);
      if (this.pageIndex + 1 > this.totalPages) {
        this.pageIndex = this.totalPages - 1;
      }
    }
    if (this.totalPages && this.pageIndex < 0) {
      this.pageIndex = 0;
    }
  }

  // Triggered when previous page button is clicked
  previousPage() {
    this.pageIndex -= 1;
    /* istanbul ignore next */
    this.onPageChange?.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      totalRecords: this.totalRecords,
      type: this.type,
    });
  }

  // Triggered when next page button is clicked
  nextPage() {
    this.pageIndex += 1;
    /* istanbul ignore next */
    this.onPageChange?.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      totalRecords: this.totalRecords,
      type: this.type,
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
