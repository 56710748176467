import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterCUData' })
export class FilterCUData implements PipeTransform {
  /**
   * Pipe returns the list where agentType === 'human'
   *
   * @param items list of elements to search in
   * @returns list of elements filtered
   */
  transform(items: any[]): any[] {
    if (items) {
      return items.filter((item) => item.DATA?.agents?.[0]?.agentType === 'human');
    }
    return [];
  }
}
