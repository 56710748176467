<div [formGroup]="group" class="{{ parentClass }}">
  <mat-form-field class="{{ appliedClass }}">
    <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate" pTooltip="{{ field.label }}" tooltipPosition="top"
            >{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          ></label
        >
        <p
          class="nh-ml-6 d-flex main-label-helperIcon"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <div
        class="d-flex align-items-center nh-column-gap-5 pb-2"
        *ngIf="
          field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
          (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
        "
      >
        <mat-icon matSuffix class="d-flex align-items-center justify-content-center"
          ><span
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            class="sub-cu"
            (click)="openSubPopUp()"
            ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
          /></span>
        </mat-icon>
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          (click)="addAttribute.next()"
          >add</mat-icon
        >
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          (click)="removeAttribute.next()"
          >remove</mat-icon
        >
      </div>
    </div>
    <p
      class="top-text"
      *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <div class="sub-right-class">
      <span *ngIf="showPlaceholder" class="label-flex-row-placeholder d-none">Type here</span>
      <input
        type="text"
        [readonly]="field.readonly"
        [value]="field.value"
        [required]="field?.isRequired && !field?.isInfo"
        matInput
        [formControlName]="field.attribute.name"
        [matAutocomplete]="auto2"
        id="{{ field.attribute.name }}"
        #searchInput
        (click)="onClick()"
        (keyup)="inputValueChange.next()"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
      />
    </div>
    <mat-autocomplete #auto2="matAutocomplete" class="custom-suggestion" (optionSelected)="onSelectionChange($event)">
      <div
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="0"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        class="type-ahead-option"
      >
        <mat-option *ngIf="options?.length === 0" disabled>None</mat-option>
        <mat-option
          *ngFor="let option of options"
          [value]="option.displayValue"
          matTooltip=" {{ option.displayValue }}"
          matTooltipClass="ui-table-tooltip"
          (click)="clearOptionData()"
          class="ui-table-tooltip"
        >
          {{ option.displayValue }}
        </mat-option>
      </div>
    </mat-autocomplete>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
  </mat-form-field>
  <ng-container *ngFor="let validation of field?.validations" ngProjectAs="mat-error">
    <mat-error
      *ngIf="
        validation.type &&
        (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
        getErrorMessage(field, validation)
      "
      >{{ validation.message }}</mat-error
    >
    <mat-error
      [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
      *ngIf="
        validation.type &&
        validation.type !== 'ERROR' &&
        validation.type !== 'BLOCK_WARN' &&
        getInfoWarnMessage(field, validation)
      "
      >{{ validation.message }}</mat-error
    >
  </ng-container>
</div>
