<div>
  <div [formGroup]="group">
    <!-- For Year UI control -->
    <div *ngIf="field.type === 'year'" class="yearField">
      <mat-form-field appearance="standard">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            pTooltip="{{ field.label }}"
            tooltipPosition="top"
            id="{{ field.attribute.name }}"
            tooltipStyleClass="transcustomtolltipbook"
            class="main-label nh-mb-0"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              class="material-icons-outlined helperText-infoIcon"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>

        <div class="custom-year-picker year-picker-wrapper" [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }">
          <p id="dateSelect_{{ field.attribute.name }}_{{ field.id }}" class="datedisplay">
            {{ field.value | date: requiredFormat }}
          </p>
          <em class="nh-icon nh-icon-calender02 actual-year-icon"></em>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [matTooltip]="field?.configuration?.hideTooltip ? '' : formattedValue"
            [matTooltipPosition]="'above'"
            [disabled]="field.readonly"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearForYear($event, picker)">
          </mat-datepicker>
        </div>
        <input
          matInput
          style="display: none;"
          [value]="field.value"
          [matDatepicker]="picker"
          [formControlName]="field.attribute.name"
          [readonly]="true"
          [required]="field.isRequired"
          id="dateSelect_{{ field.attribute.name }}_{{ field.id }}"
          (dateChange)="onDateChange($event)"
          [min]="minDate"
          [max]="maxDate"
          [matDatepickerFilter]="myFilter"
        />

        <mat-hint></mat-hint>
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="dateAddAttr_{{ field.attribute.name }}"
          (click)="addAttribute.next()"
          >add</mat-icon
        >
        <mat-icon
          *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="dateAddAttr_{{ field.attribute.name }}"
          (click)="removeAttribute.next()"
          >remove</mat-icon
        >
      </mat-form-field>
    </div>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>

    <!-- For validation message of UI controls -->
    <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
      <mat-error
        *ngIf="validation.type && validation.type.toLocaleLowerCase() === 'error' && getErrorMessage(field, validation)"
        >{{ validation.message }}</mat-error
      >
      <mat-error
        [ngClass]="validation.type == 'info' ? 'infocolor' : 'warncolor'"
        *ngIf="
          validation.type && validation.type.toLocaleLowerCase() !== 'error' && getInfoWarnMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
    </ng-container>
  </div>
</div>
