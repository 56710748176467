<div *ngIf="selectedView === 'hospital'" class="lms-science-bg"></div>
<div *ngIf="selectedView !== 'hospital'" class="common-gsi-bg"></div>
<!-- For Normal(Books) search -->
<div class="search-block">
  <div class="static searchbar2-class">
    <!-- <button class="search-ico sprite" [ngClass]="status ? 'search-open' : ''" (click)="clickedForSearch()">
      {{ labels?.Open_Search }}
    </button> -->
    <button class="search-ico sprite" [ngClass]="searchOpen ? 'search-open' : ''" (click)="searchOpen = !searchOpen">
      {{ labels?.Open_Search }}
    </button>
    <form class="search-bar">
      <input
        class="search-input"
        id="gsiid"
        name="searchString"
        (change)="searchGsi(currentPageNo, true)"
        [placeholder]="labels?.Search_Here"
        [(ngModel)]="searchString"
      />
      <!-- {{ myInput?.focus() }} -->
      <button class="search-ico sprite search-btn">
        {{ labels?.Search_GSI }}
      </button>
      <button class="clear-ico sprite search-close pointer" (click)="searchOpen = !searchOpen">
        {{ labels?.Close }}
      </button>
      <!-- <div class="search-org">
        <ul>
          <li (click)="status = !status"><mat-icon>keyboard</mat-icon> to cancel</li>
        </ul>
      </div> -->
    </form>
  </div>
</div>

<div class="mobile-add d-none"></div>

<div class="image-block-main">
  <div class="image-block d-none">
    <img src="../../assets/img/mylibrary/image-block-bg.png" />
  </div>
</div>

<div class="main-wrapper">
  <div>
    <div
      class="assign-filter dsd-assign-rights"
      *ngIf="book?.type !== 'manage_solutions'"
      [ngClass]="{ active: isOpenNavigation }"
    >
      <button class="dsd-col-dropbtn" id="myclooectionsHover" (click)="toggleNav()">
        <!-- {{ labels?.Collections }} -->
        <!-- {{ labels?.My_Collections }} -->
        <!-- <span class="material-icons">
          arrow_drop_down
        </span> -->
      </button>
      <div class="nsl-library-collections-list">
        <h2>
          {{ labels?.My_Collections }}
          <!-- <span class="material-icons">
            arrow_drop_up
          </span> -->
          <figure class="d-none" (click)="toggleNav()">
            <img src="../../../../assets/img/mylibrary/filter-close.svg" />
          </figure>
        </h2>
        <ul>
          <li>
            <div id="bookradioCheck" class="custom-control custom-radio">
              <input type="radio" id="customCheckBook" name="betType" value="books" class="custom-control-input" />
              <label for="customCheckBook" class="custom-control-label">Book</label>
            </div>
          </li>
          <li>
            <div id="bookradioCheck" class="custom-control custom-radio">
              <input type="radio" id="customCheckBook1" name="betType" value="books" class="custom-control-input" />
              <label for="customCheckBook1" class="custom-control-label">Solutions</label>
            </div>
          </li>
          <li>
            <div id="bookradioCheck" class="custom-control custom-radio">
              <input type="radio" id="customCheckBook2" name="betType" value="books" class="custom-control-input" />
              <label for="customCheckBook2" class="custom-control-label">Extraction</label>
            </div>
          </li>
          <li>
            <p>More</p>
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </li>

          <!-- <li *ngIf="inBooksTab" (click)="morefilters()">
            <p>{{ labels?.More_Filters }}</p>
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </li> -->
        </ul>

        <div class="bottom-nlc-list">
          <button class="btn apply" id="filterCollectionApply">
            {{ labels?.Apply }}
          </button>
          <button class="btn reset" id="filterCollectionReset">
            {{ labels?.Reset }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content" [ngClass]="{ moreHeight: gsiList.length > 10 }">
    <section class="page-header" *ngIf="selectedView !== 'hospital'">
      <div class="row align-items-center">
        <div class="col-12">
          <span class="sub-title mt-0 mb-4" *ngIf="isUser">
            <span (click)="openGsis()">
              {{ labels?.My_Library }}
            </span>
            /
            <span (click)="gotoPreferences()" *ngIf="router.url.includes('transaction/my-collection')"
              >{{ labels?.Preferences }}
            </span>
          </span>
        </div>
        <div class="col">
          <h2 class="page-title small-title">
            <i
              *ngIf="tenantName != 'Super-Admin'"
              class="bi bi-arrow-left-short"
              [routerLink]="['/mylibrary-dashboard']"
            ></i>
            <i *ngIf="tenantName == 'Super-Admin'" class="bi bi-arrow-left-short" [routerLink]="['/admin']"></i>
            {{ book?.displayName ? book?.displayName : book?.name }}
          </h2>
        </div>
        <div class="col-auto d-none" *ngIf="isUser">
          <div class="row align-items-center">
            <!-- <div class="col-auto gsi-search" *ngIf="book?.name !== 'Manage Solutions'">
              <button
                class="search-ico sprite"
                [ngClass]="searchOpen ? 'search-open' : ''"
                (click)="searchOpen = !searchOpen"
              >
                {{ labels?.Open_Search }}
              </button>
              <form class="search-bar gsi-search-bar">
                <input
                  class="search-input"
                  id="gsiid"
                  name="searchString"
                  (change)="searchGsi(currentPageNo, true)"
                  [placeholder]="labels?.Search_Here"
                  [(ngModel)]="searchString"
                />
                <button class="search-ico sprite search-btn">{{ labels?.Search_GSI }}</button>
                <button class="clear-ico sprite search-close" (click)="searchOpen = !searchOpen">
                  {{ labels?.Close }}
                </button>
              </form>
            </div> -->

            <div class="col-sm-auto">
              <ul class="d-flex library-icons">
                <li class="" [class.active]="selectedView === 'gridView'">
                  <button id="selectGridView" (click)="selectingGsiView('gridView')">
                    <span class="grid-icon d-block"></span>
                    <!-- <span class="tab-text">Grid View</span> -->
                  </button>
                </li>

                <li class="" [class.active]="selectedView === 'shelveView'">
                  <button id="selectListView" (click)="selectingGsiView('shelveView')">
                    <span class="list-icon library-list-icon d-block"></span>
                    <!-- <span class="tab-text">Shelve View</span> -->
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="isGsiList" class="gsiListData position-relative">
      <p *ngIf="selectedView === 'hospital'" class="science-title ml-3">
        Endocrinology & Reproduction
        <strong class="ml-3">{{ book?.name }}</strong>
      </p>
      <div
        class="bookGsiContent"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="300"
        (scrolled)="onScrollDown()"
      >
        <div class="gsiBook">
          <ng-container *ngIf="isControl(book?.background?.link?.any)">
            <div class="book-img">
              <video onmouseover="this.play()" onmouseout="this.pause();">
                <source src="{{ book?.background?.link?.any }}" type="video/mp4" />
              </video>
            </div>
          </ng-container>

          <ng-container *ngIf="isControl(book?.background?.link?.any) == false">
            <figure class="book-img">
              <img
                [src]="book?.background?.link?.any | useHttpImgSrc | async"
                alt=""
                [ngStyle]="{ 'z-index': gsiList.length + 2 }"
              />
              <span class="bg">
                <img [src]="book?.background?.link?.any | useHttpImgSrc | async" alt="" />
              </span>
            </figure>
          </ng-container>
        </div>

        <ng-container *ngIf="isGsiListActive">
          <ul
            class="gsi-list"
            [ngClass]="{
              'lms-gsi-view-physio': selectedView === 'gridView',
              gsiListActive: isGsiListActive,
              mouseHoverActive: isHoverActive
            }"
            (mouseover)="over()"
            (mouseout)="out()"
          >
            <!-- pTooltip="{{ gsi.displayName ? gsi.displayName : gsi.name }}"
              tooltipStyleClass="transcustomtolltipbook"
              tooltipPosition="top"  -->
            <li
              [ngClass]="{ 'cstm-design': selectedView === 'gridView' }"
              *ngFor="let gsi of gsiList; let i = index"
              (click)="selectedGsi(gsi, i, $event)"
              class="display-gsi hover-menu"
              [ngStyle]="{ 'animation-delay': i / 4 + 's', 'z-index': gsiList.length - i }"
            >
              <div class="triangle-with-shadow"></div>
              <span class="science-img" *ngIf="selectedView === 'gridView'">
                <img
                  [src]="gsi?.design?.gsiIcon?.link?.desktop"
                  class="sprite-gsi-icon"
                  [ngClass]="{ 'cstm-design-img': selectedView === 'gridView' }"
                  id="gsiIcon_{{ gsi.displayName ? gsi.displayName : gsi.name }}_{{
                    getFileNameFromImgUrl(gsi?.design?.gsiIcon?.link?.desktop)
                  }}"
                />
              </span>
              <div class="row no-gutters gsiTitleName" id="{{ gsi?.name + '_' + i }}">
                <div class="col position-static">
                  <div class="d-flex align-items-center gsi-block">
                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu" class="book-more">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <ul class="bookmore-list">
                        <li (click)="onChngSolution('overview', gsi)">Overview</li>
                        <li (click)="onChngSolution('genReport', gsi)">Generate_Report</li>
                        <li (click)="onChngSolution('translate', gsi)">Translate</li>
                        <li (click)="onChngSolution('pushback', gsi)">Manage Pushbacks</li>
                      </ul>
                    </mat-menu> -->
                    <!-- <div class="hover-menu-list d-none">
                      <ul>
                        <li (click)="onChngSolution('overview', gsi)"><p>Overview</p></li>
                        <li (click)="onChngSolution('genReport', gsi)"><p>Generate_Report</p></li>
                        <li (click)="onChngSolution('translate', gsi)"><p>Translate</p></li>
                        <li (click)="onChngSolution('pushback', gsi)"><p>Manage Pushbacks</p></li>
                      </ul>
                    </div> -->
                    <div
                      class="bookmark-dd-list"
                      [ngClass]="selectedView == 'gridView' ? 'gridViewHover' : 'shelveViewHover'"
                    >
                      <ul>
                        <li (click)="onChngSolution('overview', gsi, $event)" *ngIf="environmentType == 'development'">
                          <span>
                            <img src="../../../../assets/img/mylibrary/overview-icon.svg" />
                          </span>
                          <p [ngClass]="selectedView == 'gridView' ? 'dnone' : 'dblock'">
                            <span>{{ labels?.Overview }}</span>
                          </p>
                          <p [ngClass]="selectedView == 'gridView' ? 'dblock' : 'dnone'">{{ labels?.Overview }}</p>
                        </li>
                        <li
                          *ngIf="gsi?.status === 'PUBLISHED' && environmentType == 'development'"
                          (click)="onChngSolution('publishtonsl', gsi, $event)"
                        >
                          <span>
                            <img src="../../../../assets/img/mylibrary/publish-icon.svg" />
                          </span>
                          <p [ngClass]="selectedView == 'gridView' ? 'dnone' : 'dblock'">
                            <span>{{ labels?.Publish_to_NSL }}</span>
                          </p>
                          <p [ngClass]="selectedView == 'gridView' ? 'dblock' : 'dnone'">
                            {{ labels?.Publish_to_NSL }}
                          </p>
                        </li>
                        <li
                          *ngIf="gsi?.status === 'PUBLISHED' && environmentType == 'development'"
                          (click)="onChngSolution('translate', gsi, $event)"
                        >
                          <span>
                            <img src="../../../../assets/img/mylibrary/translate-icon.svg" />
                          </span>
                          <p [ngClass]="selectedView == 'gridView' ? 'dnone' : 'dblock'">
                            <span>{{ labels?.Translate }}</span>
                          </p>
                          <p [ngClass]="selectedView == 'gridView' ? 'dblock' : 'dnone'">{{ labels?.Translate }}</p>
                        </li>
                        <li *ngFor="let env of envDetails" (click)="onChngSolution(env.type, gsi, $event)">
                          <span>
                            <img src="../../../../assets/img/mylibrary/promote-production-icon.svg" />
                          </span>
                          <p [ngClass]="selectedView == 'gridView' ? 'dnone' : 'dblock'">
                            <span>Promote to {{ env.type }}</span>
                          </p>
                          <p [ngClass]="selectedView == 'gridView' ? 'dblock' : 'dnone'">Promote to {{ env.type }}</p>
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex" *ngIf="selectedView !== 'hospital'">
                      <span class="gsi-img" *ngIf="selectedView !== 'hospital'">
                        <img
                          [src]="gsi?.design?.gsiIcon?.link?.desktop"
                          class="sprite-gsi-icon"
                          id="gsiIcon_{{ gsi.displayName ? gsi.displayName : gsi.name }}_{{
                            getFileNameFromImgUrl(gsi?.design?.gsiIcon?.link?.desktop)
                          }}"
                        />
                      </span>
                      <span class="mt-2 gsi-display-name">
                        {{ gsi.displayName ? gsi.displayName : gsi.name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="gsi-actions row no-gutters"></div>
                </div>
              </div>
              <div class="fter-title" *ngIf="selectedView === 'hospital'">
                <h4>{{ gsi.displayName ? gsi.displayName : gsi.name }}</h4>
              </div>
            </li>
          </ul>
        </ng-container>

        <div class="text-center no-records" *ngIf="gsiList.length == 0">
          <div class="col">
            <h3>
              <!-- {{ labels?.No_Records_Found }} -->
              {{ labels?.Solutions_Are_Coming_Soon }}
            </h3>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="gsiList.length && totalRecords > 10">
        <mat-paginator
          [length]="totalRecords"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="changePage($event)"
          #paginator
        ></mat-paginator>
      </div> -->
    </div>
    <div *ngIf="!isGsiList">
      <ul class="manage-list row">
        <li class="col-auto" *ngFor="let gsi of manageList; let i = index">
          <div (click)="selectedGsi(gsi, i)" id="{{ gsi.url }}">
            <div class="manage-icon">
              <img [src]="'../../../../assets/img/icon-feature.png' | CDNUrlRewritePipe" alt="" />
            </div>
            <p class="title">{{ gsi.name }}</p>
          </div>
        </li>
      </ul>
      <div class="row text-center" *ngIf="manageList.length == 0">
        <div class="col">
          <h3>
            <!-- {{ labels?.No_Records_Found }} -->
            {{ labels?.Solutions_Are_Coming_Soon }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="aads-iframe" *ngIf="showAADS">
  <button type="button" class="close" aria-label="Close" (click)="closeAdds()">
    <span aria-hidden="true">&times;</span>
  </button>
  <iframe [src]="aadsLink" title="AADS"></iframe>
</div>
