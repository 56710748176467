import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class EventsEndpointService extends CduiBaseService {
   public emitEventClickable: Subject<any> = new Subject<any>();
   emitEventClickable$: Observable<any> = this.emitEventClickable.asObservable();

  constructor(public injector: Injector, private http: HttpClient) {
    super(injector);
  }

  setChangeUnitEvents(data: any, cuId: any) {
    return this.http.post(this.api?.setChangeUnitEvents + cuId, data);
  }

  getDependentDropdownDetails(
    payload: any,
    eventType: string,
    changeDriver: any,
    pageNo?: any,
    pageSize?: any,
    changeDriverValue?: any,
    attributeName?: any
  ) {
    if (changeDriverValue != undefined && attributeName && eventType === 'ON_CHANGE') {
      return this.http.post(
        this.api?.getDependentDropdownDetails +
          `pageNo=${pageNo}&pageSize=${pageSize}&` +
          `changeDriverId=${changeDriver}&eventType=${eventType}&changeDriverValue=${changeDriverValue}&searchAttributeName=${attributeName}`,
        payload
      );
    } else if (changeDriverValue != undefined && eventType === 'ON_LOAD') {
      return this.http.post(
        this.api?.getDependentDropdownDetails +
          `pageNo=${pageNo}&pageSize=${pageSize}&` +
          `changeDriverId=${changeDriver}&eventType=${eventType}&changeDriverValue=${changeDriverValue}&searchAttributeName=${attributeName}`,
        payload
      );
    }
    return this.http.post(
      this.api?.getDependentDropdownDetails +
        `pageNo=${pageNo}&pageSize=${pageSize}&` +
        `changeDriverId=${changeDriver}&eventType=${eventType}`,
      payload
    );
  }
  getDependentDropdownDetailsRefSource(payload: any, pageNo: any, pageSize: any, changeDriver?: any, eventType?: any) {
    return this.http.post(
      this.api?.getDependentDropdownDetails +
        `pageNo=${pageNo}&pageSize=${pageSize}&` +
        `changeDriverId=${changeDriver}&eventType=${eventType}`,
      payload
    );
  }
  getFolders(folderNames: string) {
    return this.http.get(`${this.api.withFolder}${folderNames}?limit=20&isFolder=true&query=`);
  }

  getUsers(searchString?: string, pageNumber?: number) {
    // return this.http.post(
    //   `${this.api.getPaginateUsers}pageNumber=${pageNumber ? pageNumber : 0}&pageSize=15&searchTerm=${searchString}`,
    //   {}
    // );
    return this.http.get(
      `${this.api.getAllUsersBasicInfo}pageNumber=${pageNumber ? pageNumber : 0}&pageSize=15&searchTerm=${searchString}`
    );
  }
  getRoles(searchString?: string) {
    const payload = {
      isActive: 'all',
      roleName: searchString || '',
    };
    // https://cduidev.dev3.nslhub.com/dsd-orch/nsl-iam/api/roles/getAllRoles?pageNumber=0&pageSize=15
    return this.http.post(`${this.api.getPaginateRoles}pageNumber=0&pageSize=15`, payload);
  }

  // Elastic Search - FE757
  // Fetch values for typeahead attribute at transaction side
  fetchSearchData(data: any, typeAheadPageData: any) {
    return this.http.post(
      this.api?.fetchTypeAheadAttributeValues +
        '?pageNumber=' +
        typeAheadPageData.pageNumber +
        '&pageSize=' +
        typeAheadPageData.pageSize,
      data
    );
  }

  getEventExpressionDetails(payload: any, eventType: any, changeDriverId: any, id: any) {
    return this.http.post(
      this.api?.getEventExpressionDetails + `baseTCUId=${id}&changeDriverId=${changeDriverId}&eventType=${eventType}`,
      payload
    );
  }

  downloadMultipleFiles(files: any) {
    return this.http.post(this.api?.extsolns, files);
  }

  /*istanbul ignore next*/
  getpdfTextCord(data: any) {
    return this.http.post(this.api?.getpdfTextCord, data);
  }

  setFeedBack(data: any) {
    return this.http.post(this.api?.feedBackCDEC, data);
  }

  sendPostExceptionsData(data: any) {
    return this.http.post(this.api?.postExceptions, data);
  }
  getAllFiles(folderNames: string) {
    return this.http.get(`${this.api.withFolder}${folderNames}?limit=20&query=`);
  }
}
