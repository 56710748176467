
<div
  class="qr-scaner-in-table"
  *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']"
>
  <div class="files-list" *ngIf="files?.length > 0">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <div class="info">
        <div class="info-data-cls">
          <h4 class="name" [title]="file && file.originalFileName ? file?.originalFileName : file?.name">
            <span *ngIf="file?.originalFileName; else name">{{ file?.originalFileName }}</span>
            <ng-template #name>{{ file?.name }}</ng-template>
          </h4>
          <!-- <mat-icon (click)="deleteFile(i)">delete</mat-icon> -->
          <img (click)="deleteFile(i)" src="../../../../assets/images/delete_box.svg" />
        </div>
      </div>
    </div>
  </div>
  <!--QR Scanner text in table-->
  <div appDnd (fileDropped)="onFileDropped($event)">
    <p-overlayPanel #op1 styleClass="ui-control-dropdown">
      <ng-template pTemplate="content">
        <div class="upload-wrapp-table">
          <div class="ui-custom-upload">
            <button class="btn-cam" (click)="camClickToScan(opencamera)" [ngClass]="camClicked ? 'd-none' : ''">
              <svg class="actual-qr-cam" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Group_104985" data-name="Group 104985" transform="translate(22870 7973)">
                  <rect
                    id="Rectangle_40260"
                    data-name="Rectangle 40260"
                    width="24"
                    height="24"
                    transform="translate(-22870 -7973)"
                    fill="#fff"
                    opacity="0"
                  />
                  <path
                    id="Path_105840"
                    data-name="Path 105840"
                    d="M19.89,20.72H4.11a2.275,2.275,0,0,1-2.27-2.27V8.42A2.275,2.275,0,0,1,4.11,6.15H6.44L7.7,3.89a1.132,1.132,0,0,1,.96-.62H15.3a1.174,1.174,0,0,1,1,.64l1.25,2.23h2.33a2.275,2.275,0,0,1,2.27,2.27V18.44a2.275,2.275,0,0,1-2.27,2.27ZM4.11,7.06A1.361,1.361,0,0,0,2.75,8.42V18.45a1.361,1.361,0,0,0,1.36,1.36H19.89a1.361,1.361,0,0,0,1.36-1.36V8.42a1.361,1.361,0,0,0-1.36-1.36h-2.6a.483.483,0,0,1-.4-.23l-1.4-2.49a.273.273,0,0,0-.22-.16H8.68a.227.227,0,0,0-.18.14L7.09,6.83a.458.458,0,0,1-.4.23H4.09Z"
                    transform="translate(-22870 -7973)"
                    fill="#999"
                  />
                  <path
                    id="Path_105841"
                    data-name="Path 105841"
                    d="M11.96,17.73a4.33,4.33,0,1,1,4.33-4.33,4.338,4.338,0,0,1-4.33,4.33Zm0-7.61a3.28,3.28,0,1,0,3.28,3.28A3.282,3.282,0,0,0,11.96,10.12Z"
                    transform="translate(-22870 -7973)"
                    fill="#999"
                  />
                </g>
              </svg>
            </button>
            <div class="scanner-wrapper" *ngIf="camClicked">
              <zxing-scanner
                class="scanner-cls"
                [torch]="torchEnabled"
                [device]="deviceCurrent"
                (deviceChange)="onDeviceChange($event)"
                (scanSuccess)="onCodeResult($event)"
                [formats]="formatsEnabled"
                [tryHarder]="tryHarder"
                (permissionResponse)="onHasPermission($event)"
                (camerasFound)="onCamerasFound($event)"
                (torchCompatible)="onTorchCompatible($event)"
              ></zxing-scanner>
            </div>
            <!-- <figure class="upload-img">
          <img src="../../../assets/images/ui-controls/icon-scanner.svg">
        </figure> -->
            <div class="ui-custom-upload-drag">
              <figure>
                <img src="../../../assets/images/ui-controls/icon-drag-drop.svg" />
              </figure>
              <div class="select-file">
                Drag & drop your files here
              </div>
            </div>
            <div class="ui-custom-upload-drag upload-browse">
              <input
                class="cur-pointer"
                [disabled]="field?.readonly"
                [required]="field?.isRequired && !field?.isInfo"
                type="file"
                #fileInput
                id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
                (change)="fileBrowseHandler($event.target.files)"
                accept="image/*"
              />
              <label
                class="mb-0"
                for="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
                id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
              >
                <a class="select-file-text" *ngIf="files?.length === 0">
                  {{ labels?.BROWSE }}
                </a>
                <a class="select-file-text" *ngIf="files?.length > 0" (click)="retry()">
                  {{ labels?.RETRY }}
                </a>
              </label>
            </div>
          </div>
        </div>
      </ng-template>
    </p-overlayPanel>
    <figure (click)="op1.toggle($event)" class="thumbnail-icon">
      <img src="../../../assets/images/ui-controls/icon-scanner.svg" />
    </figure>
  </div>
</div>

<p
  class="bottom-text"
  *ngIf="field?.configuration?.helperTextPosition == 'bottom'"
  [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
>
  {{ field?.configuration?.helpertext }}
</p>

<div class="qr-code-scanner" [ngClass]="{'disabled': false}" (fileDropped)="onFileDropped($event)">
  <div class="dot" [ngClass]="[cameraPermission ? 'green' : 'red']"></div>
  <input type="text" [(ngModel)]="qrResultString" >
  <div class="box nh-mr-4">
    <input
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
        class="d-none"
        type="file"
        #file
        (change)="fileBrowseHandler($event.target.files)"
        accept="image/*"
    />
    <em class="nh-icon nh-icon-uploads"  (click)="file.click()" ></em>
  </div>
  <div class="box">
    <em class="nh-icon nh-icon-camera" (click)="camClickToScan(opencamera)"></em>
  </div>
</div>
<ng-template #opencamera>
  <div class="crose-icon d-flex justify-content-end nh-mb-12">
    <em class="nh-icon nh-icon-x-lg"></em>
  </div>
  <div class="scanner-wrapper qr-camera">
    <zxing-scanner
      class="scannercls"
      [torch]="torchEnabled"
      [device]="deviceCurrent"
      (deviceChange)="onDeviceChange($event)"
      (scanSuccess)="onCodeResult($event)"
      [formats]="formatsEnabled"
      [tryHarder]="tryHarder"
      (permissionResponse)="onHasPermission($event)"
      (camerasFound)="onCamerasFound($event)"
      (torchCompatible)="onTorchCompatible($event)"
    ></zxing-scanner>
    <div class="scanner-area">
        <figure>
          <img src="../../../../../assets/img/scannerimg.png" alt="">
        </figure>
    </div>
  </div>
</ng-template>
