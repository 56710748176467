import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, Subscription, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DahsboardEndpointService } from './dashboard-endpoint.service';
import { AlertService } from '../toaster/alert.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardFacadeService implements OnDestroy {
  public ngUnsubscribe = new Subject();

  topTransSolutionsSubject = new Subject();
  topTransSolutions$ = this.topTransSolutionsSubject.asObservable();

  topEditedSolutionsSubject = new Subject();
  topEditedSolutions$ = this.topEditedSolutionsSubject.asObservable();

  transactionsCountSubject = new Subject();
  transactionsCount$ = this.transactionsCountSubject.asObservable();

  solutionsCountSubject = new Subject();
  solutionsCount$ = this.solutionsCountSubject.asObservable();

  usersCountSubject = new Subject();
  usersCount$ = this.usersCountSubject.asObservable();

  public groupList = new Subject();
  groupList$ = this.groupList.asObservable();

  public widgets = new Subject();
  widgets$ = this.widgets.asObservable();

  public widgetAttributes = new Subject();
  widgetAttributes$ = this.widgetAttributes.asObservable();

  public xandyAttributes = new Subject();
  xandyAttributes$ = this.xandyAttributes.asObservable();

  public saveWidgets = new Subject();
  saveWidgets$ = this.saveWidgets.asObservable();

  public deleteWidgets = new Subject();
  deleteWidgets$ = this.deleteWidgets.asObservable();

  public saveCollections = new Subject();
  saveCollections$ = this.saveCollections.asObservable();

  public collectionDetails = new Subject();
  collectionDetails$ = this.collectionDetails.asObservable();

  public dashboradDetails = new Subject();
  dashboradDetails$ = this.dashboradDetails.asObservable();

  public dashboardByName = new Subject();
  dashboardByName$ = this.dashboardByName.asObservable();

  public attributeValues = new Subject();
  attributeValues$ = this.attributeValues.asObservable();

  public aggrigateValues = new Subject();
  aggrigateValues$ = this.aggrigateValues.asObservable();

  public collections = new Subject();
  collections$ = this.collections.asObservable();

  public datasetDetails = new Subject();
  datasetDetails$ = this.datasetDetails.asObservable();

  public dashboardDetails = new Subject();
  dashboardDetails$ = this.dashboardDetails.asObservable();

  public dataSetData = new Subject();
  dataSetData$ = this.dataSetData.asObservable();

  public getDatasets = new Subject();
  getDatasets$ = this.getDatasets.asObservable();

  public deleteDataset = new Subject();
  deleteDataset$ = this.deleteDataset.asObservable();

  public deleteDashboards = new Subject();
  deleteDashboards$ = this.deleteDashboards.asObservable();

  public getDashboards = new Subject();
  getDashboards$ = this.getDashboards.asObservable();

  public dashboard = new BehaviorSubject({});
  dashboard$ = this.dashboard.asObservable();

  public cuExecutionData = new Subject();
  cuExecutionData$ = this.cuExecutionData.asObservable();

  public getAdDashboards = new Subject();
  getAdDashboards$ = this.getAdDashboards.asObservable();

  // public getGsiList = new Subject();
  // getGsiList$ = this.getGsiList.asObservable();

  solutions = new Subject();
  solutions$ = this.solutions.asObservable();

  specialFeatures = new Subject();
  specialFeatures$ = this.specialFeatures.asObservable();

  savedSpecialFeature = new Subject();
  savedSpecialFeature$ = this.savedSpecialFeature.asObservable();

  dashboardWidgets = new Subject();
  dashboardWidgets$ = this.dashboardWidgets.asObservable();
  tenantDetails = new Subject();
  tenantDetails$ = this.tenantDetails.asObservable();

  meteringDetails = new Subject();
  meteringDetails$ = this.meteringDetails.asObservable();

  billingDetails = new Subject();
  billingDetails$ = this.billingDetails.asObservable();

  usageDetails = new Subject();
  usageDetails$ = this.usageDetails.asObservable();

  tenantUserCount = new Subject();
  tenantUserCount$ = this.tenantUserCount.asObservable();

  entityCount = new Subject();
  entityCount$ = this.entityCount.asObservable();

  pdfDetails = new Subject();
  pdfDetails$ = this.pdfDetails.asObservable();

  getPublisherDashboards = new Subject();
  getPublisherDashboards$ = this.getPublisherDashboards.asObservable();

  public getAdvertiserDashboards = new Subject();
  getAdvertiserDashboards$ = this.getAdvertiserDashboards.asObservable();
  private specialFeature = new Subject();
  specialFeature$ = this.specialFeature.asObservable();

  private updateSpecialFeature = new Subject();
  updateSpecialFeature$ = this.updateSpecialFeature.asObservable();

  private deleteConfirmWidget = new Subject();
  deleteConfirmWidget$ = this.deleteConfirmWidget.asObservable();

  private BlocklistedRoles = new Subject();
  BlocklistedRoles$ = this.BlocklistedRoles.asObservable();

  private getAllGsiNameSubject = new Subject();
  getAllGsiNameSubject$ = this.getAllGsiNameSubject.asObservable();

  private getAllGsisInTenants = new Subject();
  getAllGsisInTenants$ = this.getAllGsisInTenants.asObservable();
  // updateSpecialFeatures$: any;
  addconfigurationSubject = new BehaviorSubject({});
  addconfigurationSubject$ = this.addconfigurationSubject.asObservable();

  expiredPlan = new Subject();
  expiredPlan$ = this.expiredPlan.asObservable();

  activePlanDetail = new Subject();
  activePlanDetail$ = this.activePlanDetail.asObservable();

  InActivePlan = new Subject();
  InActivePlan$ = this.InActivePlan.asObservable();

  allPlans = new Subject();
  allPlans$ = this.allPlans.asObservable();

  getCampaignList = new Subject();
  getCampaignList$ = this.getCampaignList.asObservable();

  getSolutionList = new Subject();
  getSolutionList$ = this.getSolutionList.asObservable();

  paymentComplete = new BehaviorSubject('');
  paymentComplete$ = this.paymentComplete.asObservable();

  public getDashboardVariablesList = new Subject();
  getDashboardVariablesList$ = this.getDashboardVariablesList.asObservable();
  callSubmit = new Subject();
  callSubmit$ = this.callSubmit.asObservable();

  showRefresh = new Subject();
  showRefresh$ = this.showRefresh.asObservable();

  supersetDashboardsByTenantList = new BehaviorSubject({});
  supersetDashboardsByTenantList$ = this.supersetDashboardsByTenantList.asObservable();

  supersetDashboardsByUserList = new BehaviorSubject({});
  supersetDashboardsByUserList$ = this.supersetDashboardsByUserList.asObservable();

  dateRange = new BehaviorSubject({});
  dateRange$ = this.dateRange.asObservable();

  constructor(
    private dahsboardEndpointService: DahsboardEndpointService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showRefreshButton() {
    this.showRefresh.next(true);
  }

  callSubmitButton(color: any) {
    this.callSubmit.next(color);
  }

  loadTopTransactedSolutions(tenantId: any, topNnum: number, environment?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getTopTransactedSolutions(tenantId, topNnum, environment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.topTransSolutionsSubject.next(res);
      });
  }

  loadTopEditedSolutions(tenantId: any, topNnum: number, environment?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getTopEditedSolutions(tenantId, topNnum, environment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.topEditedSolutionsSubject.next(res);
      });
  }

  loadTransactionsCount(lastNdays: number, tenantId: any, environment?: any, startDate?: any, endDate?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getTransactionsCount(lastNdays, tenantId, environment, startDate, endDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.transactionsCountSubject.next(res);
      });
  }

  loadSolutionsCount(lastNdays: number, tenantId: any, environment?: any, startDate?: any, endDate?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getSolutionsCount(lastNdays, tenantId, environment, startDate, endDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.solutionsCountSubject.next(res);
      });
  }

  loadUsersCount(selectedEnv?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .loadUsersCount(selectedEnv)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.usersCountSubject.next(res);
      });
  }

  //dashboard services
  getGroupList(pno: any, psize: any, searchCriteria: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getGroupList(pno, psize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.groupList.next(res);
      });
  }

  getwidgets(widgetName: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getwidgets(widgetName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.widgets.next(res);
      });
  }

  getAttributes(data: any, isTxnType: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAttributes(data, isTxnType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res && res?.result) res.result.sort = data?.sort;
        this.widgetAttributes.next(res);
      });
  }

  getxandyValues(data: any, isTxnType: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getxandyValues(data, isTxnType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.xandyAttributes.next(res);
      });
  }

  saveWidget(data: any, specialFeature?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .saveWidget(data, specialFeature)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.saveWidgets.next(res);
      });
  }

  deleteWidget(id: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .deleteWidget(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.deleteWidgets.next(res);
      });
  }

  saveCollection(data: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .saveCollection(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.saveCollections.next(res);
      });
  }

  getCollectionDetails(data: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getCollectionDetails(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.collectionDetails.next(res);
      });
  }

  getdashboardDetailsOnCollection(name: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getdashboardDetailsOnCollection(name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dashboradDetails.next(res);
      });
  }

  getDashboardsByName(name: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getDashboardsByName(name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dashboardByName.next(res);
      });
  }

  getAttributeValues(data: any, name: any, isTxnType: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAttributeValues(data, name, isTxnType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.attributeValues.next(res);
      });
  }

  getAggrigateValues(data: any, isTxnType: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAggrigateValues(data, isTxnType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.aggrigateValues.next(res);
      });
  }

  //RTCT cu execution data
  getcuExecutionData(data: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getcuExecutionData(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.cuExecutionData.next(res);
      });
  }

  getCollections(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    this.loader.show();
    this.dahsboardEndpointService
      .getCollections(pageNumber, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.collections.next(res);
      });
  }

  getDatasetDetails(name: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getDatasetDetails(name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.datasetDetails.next(res);
      });
  }

  getDashboardDetails(id: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getDashboardDetails(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dashboardDetails.next(res);
      });
  }

  getAllDatasets(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllDatasets(pageNumber, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getDatasets.next(res);
      });
  }

  deleteDatasets(id: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .deleteDatasets(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.deleteDataset.next(res);
      });
  }

  deleteDashboard(id: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .deleteDashboard(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.deleteDashboards.next(res);
      });
  }

  getAllDashboards(pageNumber: any, pageSize: any, searchCriteria?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllDashboards(pageNumber, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getDashboards.next(res);
      });
  }
  getAllAdDashboards(endDate: any, granularity: string, startDate: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllAdDashboards(endDate, granularity, startDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAdDashboards.next(res);
      });
  }

  getMCCDashboardByTenantId() {
    this.loader.show();
    this.dahsboardEndpointService
      .getMCCDashboardByTenantId()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.supersetDashboardsByTenantList.next(res);
        }
      });
  }

  getSupersetDashboardByUserId() {
    this.loader.show();
    this.dahsboardEndpointService
      .getSupersetDashboardByUserId()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.supersetDashboardsByUserList.next(res);
        }
      });
  }

  saveData(body: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .saveData(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next*/
        this.alertService.showToaster(res?.message, '', 'success');
        this.dataSetData.next(res);
      });
  }

  getAllDashboardWidgets(id: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllDashboardWidgets(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dashboardWidgets.next(res);
      });
  }
  dummyCallForDashboardWidgets(res: any) {
    this.dashboardWidgets.next(res);
  }

  publishDashboard(body: any, name: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .publishDashboard(body, name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dashboard.next(res);
      });
  }

  getTenantByName(tenentName: string) {
    this.loader.show();
    this.dahsboardEndpointService
      .getTenentByName(tenentName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.tenantDetails.next(res);
      });
  }

  getMeteringData(clientId: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getMeteringData(clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.meteringDetails.next(res);
      });
  }

  getBillingData(clientId: string) {
    this.loader.show();
    this.dahsboardEndpointService
      .getBillingData(clientId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.billingDetails.next(res);
      });
  }

  getBillingInfoByYear(tenentName: string, year: number, clientId: string, subscriptionid?: any) {
    this.loader.show();
    return this.dahsboardEndpointService.getBillingByYear(tenentName, year, clientId, subscriptionid);
  }

  featureUsageBill(payload: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .featureUsageBill(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.usageDetails.next(res);
      });
  }

  /**
   * Gets users count by tenant name
   * @param tenantName
   */
  getUsersCountByTenantName(tenantName: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getUsersCountByTenantName(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.tenantUserCount.next(res);
      });
  }

  /**
   * Gets entity count
   * @param lastNdays
   * @param tenantId
   */
  getEntityCount(lastNdays: number, tenantId: any, startDate?: any, endDate?: any, environment?: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getEntityCount(lastNdays, tenantId, startDate, endDate, environment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entityCount.next(res);
      });
  }

  /**
   * Exports billing pdf
   * @param tenantName
   * @param isDayWise
   */
  exportBillingPdf(clientId: string, isDayWise: boolean) {
    this.loader.show();
    this.dahsboardEndpointService
      .exportBillingPdf(clientId, isDayWise)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.pdfDetails.next(res);
      });
  }
  loadSolutions(pagenum: number, searchCriteria: string, isPublished: boolean, isTXNRights: boolean) {
    this.loader.show();
    this.dahsboardEndpointService
      .getSolutions(pagenum, searchCriteria, isPublished, isTXNRights)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.solutions.next(res);
      });
  }
  getSpecialFeatures() {
    this.dahsboardEndpointService.getSpecialFeatures().subscribe((res: any) => {
      this.specialFeatures.next(res);
    });
  }
  addSpecialFeatureToDashBoard(feature: any) {
    this.specialFeature.next(feature);
  }
  saveSpecialFeature(body: any) {
    this.dahsboardEndpointService.saveSpecialFeature(body).subscribe(
      (res: any) => {
        this.savedSpecialFeature.next(res);
        this.alertService.showToaster('', 'Special Features Saved Succesfully', 'success');
      },
      (error: any) => {
        this.alertService.showToaster(error?.message, 'Error', 'error');
      }
    );
  }
  loadSavedSpecialFeatures(dashboardId: string) {
    return this.dahsboardEndpointService.fetchSaveFeatures(dashboardId);
  }
  deleteSpecialFeature(lookupId: any) {
    this.dahsboardEndpointService.deleteSpecialFeature(lookupId).subscribe((res: any) => {});
  }
  detectupdateSpecialFeature(id: any, dashboardId: any, documentId: any, type?: boolean) {
    this.updateSpecialFeature.next({ id: id, dashboardId: dashboardId, docId: documentId, type: type });
  }
  updateSpecialFeatures(body?: any, lookupId?: any) {
    this.dahsboardEndpointService.updateSpecialFeature(body).subscribe((res: any) => {
      this.addSpecialFeatureToDashBoard(res);
    });
  }
  downloadBillHistoryPdf(clientId: string, billId: any) {
    this.loader.show();
    return this.dahsboardEndpointService.downloadBillHistoryPdf(clientId, billId);
  }
  getAllAdvertiserDashboards(advertiserId: string, campaignId: any, endDate: any, granularity: string, startDate: any) {
    /*istanbul ignore else*/
    if (campaignId === 'allCampaign') {
      campaignId = '';
    }
    this.loader.show();
    this.dahsboardEndpointService
      .getAllAdvertiserDashboards(advertiserId, campaignId, endDate, granularity, startDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAdvertiserDashboards.next(res);
      });
  }
  deleteConfirm() {
    this.deleteConfirmWidget.next(true);
  }
  getAllPublisherDashboards(
    publisherId: string,
    endDate: any,
    granularity: string,
    solutionName: string,
    startDate: any
  ) {
    /*istanbul ignore else*/
    if (solutionName === 'All Solutions') {
      solutionName = '';
    }
    this.loader.show();
    this.dahsboardEndpointService
      .getAllPublisherDashboards(publisherId, endDate, granularity, solutionName, startDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getPublisherDashboards.next(res);
      });
  }
  getallblocklistedRoles() {
    this.loader.show();
    this.dahsboardEndpointService
      .getallblocklistedRoles()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.BlocklistedRoles.next(res);
      });
  }

  getGsiName() {
    this.loader.show();
    this.dahsboardEndpointService
      .getGsiName()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAllGsiNameSubject.next(res);
      });
  }

  getAllGsiInTenant(pageNo: any, pageSize: any, searchString: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllGsiInTenant(pageNo, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAllGsisInTenants.next(res);
      });
  }

  addConfiguration(postData: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .addConfiguration(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }
  updateConfiguration(postData: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .updateConfiguration(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  isupdateconfiguration(update: any) {
    this.addconfigurationSubject.next(update);
  }
  subscribedPlans(tenantName: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .subscribedPlans(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.loader.hide();
        this.expiredPlan.next(data);
      });
  }

  // getActivePlan(tenantName: any) {
  //   this.loader.show();
  //   this.dahsboardEndpointService
  //     .getActivePlan(tenantName)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((data: any) => {
  //       this.getInActivePlan(tenantName);
  //       this.loader.hide();
  //       this.activePlanDetail.next(data);
  //     });
  // }
  // getInActivePlan(tenantName: any) {
  //   this.loader.show();
  //   this.dahsboardEndpointService
  //     .getInActivePlan(tenantName)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((data: any) => {
  //       this.loader.hide();
  //       this.InActivePlan.next(data);
  //     });
  // }
  getAllPlan(tenantName: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllPlan(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.loader.hide();
        this.allPlans.next(data);
      });
  }
  getBillData(tenantName: any, tenantSubscriptionId: any, year: any) {
    this.dahsboardEndpointService
      .getBills(tenantName, tenantSubscriptionId, year)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.loader.hide();
      });
  }
  ActivateNow(tenantName: any, SubscriptionId: any) {
    return this.dahsboardEndpointService.ActivateNow(tenantName, SubscriptionId);
  }
  FetchPlans() {
    return this.dahsboardEndpointService.FetchPlans();
  }

  getPlans(pageSize: number, pageNo: number, query: string) {
    return this.dahsboardEndpointService.getPlans(pageSize, pageNo, query);
  }

  FetchFeatures(subscriptionPlanName: any) {
    return this.dahsboardEndpointService.FetchFeatures(subscriptionPlanName);
  }
  StripeRedirectBuyPlan(payload: any) {
    return this.dahsboardEndpointService.StripeRedirectBuyPlan(payload);
  }
  StripeRedirectBuyAddOn(payload: any, tenantName: string) {
    return this.dahsboardEndpointService.StripeRedirectBuyAddOn(payload, tenantName);
  }

  getAllCampaignList(advertiserId: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllCampaignList(advertiserId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getCampaignList.next(res);
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getAllSolutionList(endDate: any, startDate: any, publisherId: any) {
    this.loader.show();
    this.dahsboardEndpointService
      .getAllSolutionList(endDate, startDate, publisherId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getSolutionList.next(res);
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }
  exportCSV(id: any) {
    return this.dahsboardEndpointService.exportCSV(id);
  }
  exportExcel(id: any) {
    return this.dahsboardEndpointService.exportExcel(id);
  }
  subscribeWidget(widget: any) {
    return this.dahsboardEndpointService.subscribeWidget(widget);
  }
  checkoutSessionCOmplete(type: any) {
    this.paymentComplete.next(type);
  }
  getDashboardVariables(tenant: string, advertiserId: string, endDate: any, startDate: any, granularity: string) {
    this.dahsboardEndpointService
      .getDashboardVariables(tenant, advertiserId, endDate, startDate, granularity)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getDashboardVariablesList.next(res);
      });
  }
  getAgencyDashboardVariables(advertiserId: string, endDate: any, startDate: any, granularity: string) {
    return this.dahsboardEndpointService.getAgencyDashboardVariables(advertiserId, endDate, startDate, granularity);
  }

  getAdvertiserTopPerformingDetails(tenant: string, endDate: any, startDate: any, advertiserId?: string) {
    return this.dahsboardEndpointService.getAdvertiserTopPerformingDetails(tenant, endDate, startDate, advertiserId);
  }
  getGsiDetails(tenant: any) {
    return this.dahsboardEndpointService.getGsiDetails(tenant);
  }
  getSpendDetails(tenant: any, endDate: any, startDate: any, advertiserId?: any) {
    return this.dahsboardEndpointService.getSpendDetails(tenant, endDate, startDate, advertiserId);
  }

  getBudgetDetails(tenant: any, endDate: any, startDate: any, advertiserId?: any) {
    return this.dahsboardEndpointService.getBudgetDetails(tenant, endDate, startDate, advertiserId);
  }
  getAdsDetails(tenent: any, startDate: any, endDate: any) {
    return this.dahsboardEndpointService.getAdsDetails(tenent, startDate, endDate);
  }
  // getAllCampaign(advertiserId: string) {
  //   return this.dahsboardEndpointService.getAllCampaigns(advertiserId);
  // }
  getAllCampaignsDetails(advertiserId: string, endDate: any, startDate: any, tenant?: any) {
    return this.dahsboardEndpointService.getAllCampaignsDetails(advertiserId, endDate, startDate, tenant);
  }

  getAdvertiserStreamAnalytics(
    advertiserId: string,
    tenantName: string,
    endDate: any,
    startDate: any,
    campaignId: any,
    granularity: string
  ) {
    return this.dahsboardEndpointService.getAdvertiserStreamAnalytics(
      advertiserId,
      tenantName,
      endDate,
      startDate,
      campaignId,
      granularity
    );
  }
  updateStatus(payload: any) {
    return this.dahsboardEndpointService.updateStatus(payload);
  }

  setDateRange(range: any) {
    this.dateRange.next(range);
  }
}
