<div class="nested-atr-wrap">
  <div class="top-head">
    <div class="save-wrap">
      <div class="save-blk">
        <h5>{{ labels?.Create_Attribute_Levels }}</h5>
        <button mat-stroked-button (click)="saveEntityWithSubattribute()" *ngIf="!selectedEntity?.viewNested">{{labels?.Save}}</button>
        <mat-icon matSuffix (click)="closeComponent()">close</mat-icon>
      </div>
    </div>
    <div></div>
    <div class="object-wrap">
      <div class="cust-details">
        <p>Object: {{ selectedEntity?.name }}</p>
      </div>
      <!-- <div class="nxt-pre">
        <mat-icon>chevron_left</mat-icon>
        <mat-icon>chevron_right</mat-icon>
      </div> -->
    </div>
  </div>
  <div class="object-content-wrap">
    <div *ngFor="let entity__ of levels">
      <ng-container [ngTemplateOutlet]="levelCode" [ngTemplateOutletContext]="{ entity_: entity__ }"> </ng-container>
    </div>
  </div>
</div>
<ng-template #levelCode let-entity="entity_">
  <div class="colomn-one overflowscroll">
    <div class="colmn-head pt-1">
      <h6>Level: {{ entity?.level }}</h6>
    </div>
    <!-- active bg -->
    <div *ngFor="let attribute of entity?.nslAttributes; let attrIndex = index">
      <div *ngIf="attribute?.show || attribute?.show || entity?.show" class="addinput-wrap">
        <div class="addinput-txt" [ngClass]="{ 'selected-entity-color': attribute?.showColor }">
          <mat-form-field>
            <input type="text" matInput [(ngModel)]="attribute.name" />
            <mat-icon matSuffix (click)="deleteAttribute(entity, attrIndex, entity?.level)">delete_outline</mat-icon>
          </mat-form-field>
        </div>
        <div class="swith-wrap-blk">
          <div class="swith-blk">
            <mat-slide-toggle><span>ON</span> {{ labels?.Off }}</mat-slide-toggle>
          </div>
          <div class="add-wrap-blk">
            <button class="csm-btn" (click)="openEntityLibrary()">
              Library
            </button>
            <button class="csm-btn" (click)="displayNextLevel(attribute, entity, entity?.level + 1)">Show</button>
            <button class="csm-btn">
              <mat-icon (click)="checkExistingEntity2(attribute); showColor(attribute, entity)">add</mat-icon>
            </button>
            <button class="csm-btn">
              <mat-icon (click)="attribute.openUiPanel = !attribute.openUiPanel">expand_more</mat-icon>
            </button>
          </div>
        </div>
        <div class="addpopup" *ngIf="existAsEntity?.name == attribute.name">
          <div class="addpopup-blk">
            <h5>{{ attribute.name }} Already exists in your library.</h5>
            <button class="csm-btn" (click)="addSameEntity(attribute, entity?.level)">Add Same Object</button>
            <button class="csm-btn">
              View Object
            </button>
            <hr class="hr-text" data-content="OR" />
            <button class="csm-btn" (click)="attribute.isRename = true">Create New Object</button>

            <div class="entity-input-blk" *ngIf="attribute.isRename">
              <div class="entity-input"><input type="text" [(ngModel)]="attribute.updatedName" /></div>
              <button
                class="csm-btn"
                type="submit"
                (click)="attribute.name = attribute.updatedName; attribute.isRename = false"
              >
                Save
              </button>
              <button class="csm-btn" type="button" (click)="attribute.isRename = false">Cancel</button>
            </div>
          </div>
        </div>
        <div class="uiconrols-blk">
          <mat-accordion *ngIf="attribute.openUiPanel">
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.UI_Controls }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="no-label">
                <mat-label>{{ attribute?.attributeType?.uiElement?.name }}</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select
                    class="uic-select"
                    panelClass="attribute-icons select-with-search"
                    [(ngModel)]="attribute.attributeType.uiElement"
                    (selectionChange)="selectAttrType($event, attribute)"
                    placeholder="Select Attribute Type"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <mat-form-field class="d-block search-input-nesd" appearance="outline">
                      <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup)="getAttributeTypes()" />
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <mat-option [value]="attrType" *ngFor="let attrType of attributeTypes"
                      >{{ attrType?.displayName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Constant Values
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <ng-container
                  *ngIf="
                    attribute.attributeType?.uiElement?.dataType != 'scheduler' &&
                    attribute.attributeType?.uiElement?.dataType != 'location'
                  "
                >
                  <div
                    class="constant-val-blk"
                    *ngIf="
                      attribute.attributeType?.uiElement?.uiElement == 'checkbox' ||
                      attribute.attributeType?.uiElement?.uiElement == 'dropdown' ||
                      attribute.attributeType?.uiElement?.uiElement == 'radioButton'
                    "
                  >
                    <input
                      class="add-value w-100"
                      placeholder="Add Options"
                      id="inputData"
                      type="text"
                      autocomplete="off"
                      [(ngModel)]="sourceVal"
                    />
                    <button
                      class="validate csm-btn"
                      id="addInputData"
                      (click)="addDefaultValuetoAttibute(attribute, sourceVal)"
                    >
                      {{ labels.Add }}
                    </button>
                    <div *ngIf="attribute.attributeType?.extendedProperties?.sourceValues.length > 0">
                      <div
                        class="d-flex added"
                        *ngFor="
                          let attrvalue of attribute.attributeType?.extendedProperties?.sourceValues;
                          let i = index
                        "
                      >
                        {{ attrvalue.DATA?.displayValue }}
                        <span class="pointer"
                          ><img
                            (click)="removeSourceValue(attribute, i)"
                            id="attrremoveimg"
                            [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span>
                      </div>
                    </div>
                    <br />
                    <mat-label>DefaultValue</mat-label>
                    <input
                      matInput
                      placeholder="Default Value"
                      name="defaultValue"
                      id="defaultValue"
                      type="text"
                      name="defaultValue"
                      autocomplete="off"
                      [(ngModel)]="attribute.DefaultSourceVal"
                    />
                    <div class="val-btn-wrap">
                      <button
                        class="validate csm-btn"
                        id="addInputData"
                        (click)="addDefaultValuetoAttibute(attribute, attribute.DefaultSourceVal, true)"
                      >
                        {{ labels.Add }}
                      </button>
                      <div *ngFor="let attrvalue of attribute?.allDefaultValues; let i = index">
                        <div class="d-flex added" *ngIf="defaultOption">
                          <p>{{ attrvalue }}</p>
                          <span class="pointer"
                            ><img
                              (click)="removeDefaultSourceValue(attribute)"
                              id="attrremoveimg"
                              [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                          /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="expected-time constant-val-blk mb-3"
                    *ngIf="
                      attribute.attributeType?.uiElement?.dataType != 'file' &&
                      attribute.attributeType?.uiElement?.dataType != 'image' &&
                      attribute.attributeType?.uiElement?.dataType != 'audio' &&
                      attribute.attributeType?.uiElement?.dataType != 'video' &&
                      attribute.attributeType?.uiElement?.dataType != 'Password' &&
                      attribute.attributeType?.uiElement?.uiElement != 'checkbox' &&
                      attribute.attributeType?.uiElement?.uiElement != 'dropdown' &&
                      attribute.attributeType?.uiElement?.uiElement != 'radioButton'
                    "
                  >
                    <!-- <mat-label>{{ attribute?.defaultValue }}</mat-label> -->

                    <input
                      matInput
                      placeholder="Default Value"
                      name="defaultValue"
                      id="defaultValue"
                      type="text"
                      name="defaultValue"
                      autocomplete="off"
                      [(ngModel)]="attribute.DefaultSourceVal"
                    />
                    <div class="val-btn-wrap">
                      <button
                        *ngIf="!(!attribute.multiDefaultValue && attribute?.allDefaultValues?.length > 0)"
                        class="validate csm-btn"
                        id="addInputData"
                        (click)="addDefaultValuetoAttibute(attribute, attribute.DefaultSourceVal, true)"
                      >
                        {{ labels.Add }}
                      </button>
                      <div *ngFor="let attrvalue of attribute?.allDefaultValues; let i = index">
                        <div class="d-flex added" *ngIf="defaultOption">
                          <p>{{ attrvalue }}</p>
                          <span class="pointer"
                            ><img
                              (click)="removeDefaultSourceValue(attribute, i)"
                              id="attrremoveimg"
                              [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                          /></span>
                        </div>
                      </div>
                      <div class="multi-file-upload mb-4">
                        <div class="multi-value">
                          <mat-label class="label-txt mr-2">Multi Value</mat-label>
                          <mat-radio-group fxLayout="column" formControlName="isMultiValue">
                            <mat-radio-button
                              [checked]="attribute?.multiDefaultValue"
                              name="isMultiValue"
                              id="attribute_multiYes"
                              [value]="true"
                              (click)="checkOfMultiDefaultValues(true, attribute)"
                            >
                              Yes
                            </mat-radio-button>
                            <mat-radio-button
                              [checked]="!attribute?.multiDefaultValue"
                              name="isMultiValue"
                              id="attribute_multiNo"
                              [value]="false"
                              (click)="checkOfMultiDefaultValues(false, attribute)"
                            >
                              No
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      attribute.attributeType?.uiElement?.dataType == 'file' ||
                      attribute.attributeType?.uiElement?.dataType == 'image' ||
                      attribute.attributeType?.uiElement?.dataType == 'audio' ||
                      attribute.attributeType?.uiElement?.dataType == 'video' ||
                      attribute.attributeType?.uiElement?.dataType == 'imageavatar'
                    "
                  >
                    <!-- {{ attrDetails.value.attributeType }} -->
                    <app-file-upload
                      [fileUploadFromEntity]="true"
                      [editFileUrl]="editFileUrl"
                      (fileUploadResponse)="fileUploadUrl($event, attribute)"
                      (deletedFileResponse)="deletedFileResponse($event, attribute)"
                      [fileType]="attribute.attributeType?.uiElement?.dataType"
                    >
                    </app-file-upload>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</ng-template>
