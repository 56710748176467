<div class="con-main-wrapper conditions-wrapper" id="conditions_root_div">
  <!--Header area-->
  <div class="con-header" id="conditions_header">
    <div class="con-header-left" id="conditions_header_left">
      <h2 *ngIf="conditionType!='AttributeConditionalPotentiality'">
        {{ conditionType + ' Conditions' }}
      </h2>
      <h2 *ngIf="conditionType=='AttributeConditionalPotentiality'">
        Attribute ConditionalPotentiality Conditions
      </h2>
    </div>
    <div class="con-header-right" id="conditions_header_right">
      <button class="btn save" id="conditions_btn_save" (click)="saveConditions()">Save</button>
      <button class="btn-close" id="conditions_btn_close" (click)="closeConditions()">
        <i class="bi bi-x-square"></i>
      </button>
    </div>
  </div>
  <!--Content area-->
  <div class="con-content" id="conditions_content">
    <!-- Alternate Conditions starts-->

    <!-- left bar displaying CUs starts -->
    <div class="con-src-content" id="conditions_src_content">
      <div class="commonbar source-bar" id="conditions_{{ conditionType }}" *ngIf="conditionType == 'Alternate'">
        <div class="pay-with" id="conditions_alternate_cus">
          <ul>
            <li
              [ngClass]="{ active: cuindex == activeIndex }"
              *ngFor="let cu of alternativeCus; let cuindex = index"
              (click)="selectCu(cuindex)"
            >
              <div id="cu_{{ cuindex }}">
                <p>
                  <figure>
                    <img
                      alt="alternate"
                      [src]="'../../../../../../assets/img/nsl-gram/icon-change.svg' | CDNUrlRewritePipe"
                    />
                  </figure>
                  {{ cu.name }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- left bar displaying CUs ends -->
    <div class="con-target-exp-content" id="conditions_target">
      <div class="con-left-content" id="conditions_target_left">
        <div class="commonbar target-bar" id="conditions_target_commonbar">
          <h4>{{ selectedCu?.name }}::</h4>
          <span class="input-tag" id="conditions_input_sourceAttribute" *ngIf="selectedInput == 'sourceAttribute'"
            >Select Source</span
          >
          <span class="input-tag" id="conditions_input_targetAttribute" *ngIf="selectedInput == 'targetAttribute'"
            >Select Target</span
          >
          <form>
            <div class="form-group" id="conditions_target_form" *ngIf="selectedCu?.layers?.length > 0">
              <mat-form-field appearance="standard" id="conditions_layer">
                <mat-label class="field-label">Layers</mat-label>
                <mat-select
                  (selectionChange)="changeLayer()"
                  class=""
                  name="selected_layer"
                  [(ngModel)]="layerIndex"
                  aria-label=""
                >
                  <mat-option value="-1" id="conditions_select_layer" selected>Select</mat-option>
                  <mat-option
                    [value]="lIndex"
                    id="select_{{ layer?.type }}_layer"
                    *ngFor="let layer of selectedCu?.layers; let lIndex = index"
                    >{{ layer?.type }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="form-group"
              id="conditions_form_{{ selectedLayer }}"
              *ngIf="selectedLayer && selectedLayer?.participatingItems.length > 0"
            >
              <mat-form-field appearance="standard" id="conditions_entity">
                <mat-label class="field-label">Object</mat-label>
                <mat-select
                  class=""
                  name="selected_entity"
                  id="conditions_entity_{{ entityIndex }}"
                  [(ngModel)]="entityIndex"
                  aria-label=""
                >
                  <mat-option value="-1" selected>Select</mat-option>
                  <mat-option
                    id="conditions_entity_select_{{ enIndex }}"
                    [value]="enIndex"
                    (click)="selectEntityDetails()"
                    *ngFor="let entity of selectedLayer?.participatingItems; let enIndex = index"
                    >{{ entity.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <!-- id="conditions_mat_select_{{ attributeIndex }}"
              [(ngModel)]="attributeIndex" -->
              <ng-template #attributeSourceDDBlock let-selectedEnt="selectedEnt">
                <div
                  class="form-group"
                  id="conditions_form_{{ entityIndex }}"
                  *ngIf="selectedEnt.nslAttributes.length > 0"
                >
                  <mat-form-field appearance="standard" id="conditions_attribute">
                    <mat-label class="field-label">Attributes</mat-label>
                    <mat-select
                      class=""
                      name="selected_attribute"
                      (selectionChange)="changeTargetAttribute(selectedEnt, $event)"
                      aria-label=""
                    >
                      <mat-option value="-1" selected>Select</mat-option>
                      <mat-option
                        id="conditions_attribute_select_{{ atIndex }}"
                        [value]="atIndex"
                        *ngFor="let attribute of selectedEnt?.nslAttributes; let atIndex = index"
                        >{{ attribute.name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="selectedEnt?.nslAttributes.length > 0">
                  <div *ngFor="let attr of selectedEnt?.nslAttributes">
                    <ng-container
                      *ngIf="attr.generalEntity && attr.isSelected"
                      [ngTemplateOutlet]="attributeSourceDDBlock"
                      [ngTemplateOutletContext]="{
                        selectedEnt: attr.generalEntity
                      }"
                    >
                    </ng-container>
                  </div>
                </div>
              </ng-template>

              <ng-container
                *ngIf="selectedEnt?.nslAttributes?.length > 0"
                [ngTemplateOutlet]="attributeSourceDDBlock"
                [ngTemplateOutletContext]="{
                  selectedEnt: selectedEnt
                }"
              >
              </ng-container>
            </div>
            <!-- Attribute Selection Ends -->

            <button class="btn add-fld ml-auto" id="conditions_btn_selectAttribute" (click)="selectAttribute()">
              Select Attribute
            </button>
          </form>
        </div>
      </div>
      <!-- Alternate Conditions ends-->
      <div class="con-right-content">
        <div class="con-right-topbar">
          <h4 *ngIf="conditionType!='AttributeConditionalPotentiality'">{{ conditionType + ' condition' }}</h4>
          <h4 *ngIf="conditionType=='AttributeConditionalPotentiality'">Attribute ConditionalPotentiality condition</h4>
          <div class="toggle-icon" id="conditions_toggle_icon" (click)="toggleCondition()">
            <mat-slide-toggle id="conditions_mat_slide_toggle"></mat-slide-toggle>
          </div>
        </div>
        <div class="con-list" *ngIf="!displayConditionFlag">
          <ol>
            <li>
              <div class="d-flex align-items-center">
                <div class="con-list-left">
                  <div class="ptype" [ngClass]="{ errorClass: recError }">
                    <p
                      pTooltip="{{ recError }}"
                      tooltipPosition="top"
                      tooltipStyleClass="transcustomtolltipbook connectortooltip"
                    >
                      <span id="span_display_{{ selectedCu }}"
                        >{{
                          conditionType != 'AttributeConditionalPotentiality' ? selectedCu?.name : displayAttr?.name
                        }}
                        ({{ displayCondition | OperatorsPipe: 'display' }})</span
                      >
                    </p>
                  </div>
                </div>
                <div class="con-list-right-edit" id="conditions_btn_editCondition" (click)="editCondition()"></div>
                <div class="con-list-right-del" id="conditions_btn_removeCondition" (click)="removeCondition()"></div>
              </div>
            </li>
          </ol>
        </div>

        <!-- New implementation for multiple conditions -->
        <div class="create-condition" *ngIf="displayConditionFlag">
          <div *ngFor="let query of conditionalPotentialityArray; let i = index" (click)="selectedCondition = i">
            <div id="condition_rule_{{ i }}" class="condition-rule-relation" *ngIf="i > 0">
              <select
                id="select_and_or_{{ i }}"
                placeholder="AND"
                [(ngModel)]="conditionalPotentialityArray[i - 1].predicate"
              >
                <option id="connections_and_{{ i }}" value="&&">
                  AND
                </option>
                <option id="connections_or_{{ i }}" value="||">
                  OR
                </option>
              </select>
            </div>
            <div>
              <ul class="conditions-block">
                <li
                  class="condition-item"
                  id="condition_item_{{ i }}_sourceAttribute"
                  (click)="conditionInput('sourceAttribute', i)"
                >
                  <input
                    id="condition_input_{{ i }}_sourceAttribute"
                    *ngIf="!query?.sourceAttribute"
                    matInput
                    type="text"
                    placeholder="Source Attribute"
                  />
                  <span id="conditions_span_{{ i }}_sourceAttribute" class="small" *ngIf="query?.sourceAttribute">{{
                    query?.sourceAttribute | dcd
                  }}</span>
                  <span
                    id="condition_mat_span_{{ i }}_sourceAttribute_cancel"
                    class="material-icons input-cancel"
                    *ngIf="query?.sourceAttribute"
                    aria-label="Clear"
                    (click)="query.sourceAttribute = ''"
                  >
                    cancel
                  </span>
                </li>
                <li class="condition-item condition-dropdown">
                  <mat-form-field appearance="standard">
                    <mat-select
                      style="padding-bottom: 0px;"
                      [(ngModel)]="query.operator"
                      placeholder="Operators"
                      panelClass="condition-operator-dropdown"
                    >
                      <mat-option id="conditions_query_click{{ i }}_operator" (click)="query.operator = ''"
                        >Clear
                      </mat-option>
                      <mat-option *ngFor="let operator of operatorG" [value]="operator">
                        {{ operator | OperatorsPipe: 'operator' }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li
                  class="condition-item"
                  id="conditions_item_click_{{ i }}_targetAttribute"
                  (click)="conditionInput('targetAttribute', i)"
                >
                  <input
                    id="conditions_input_{{ i }}_targetAttribute"
                    *ngIf="query.targetAttribute == ''"
                    matInput
                    type="text"
                    [(ngModel)]="query.targetAttributeInput"
                    (keypress)="checkTargetInput($event, query)"
                    placeholder="Target Attribute or Enter Value"
                  />
                  <span id="conditions_span_targetAttribute" class="small" *ngIf="query?.targetAttribute">{{
                    query?.targetAttribute | dcd
                  }}</span>
                  <span
                    id="conditions_mat_icon_clear_{{ i }}_targetAttribute"
                    class="material-icons input-cancel"
                    *ngIf="query?.targetAttribute || query?.targetAttributeInput"
                    aria-label="Clear"
                    (click)="query.targetAttribute = ''"
                    (click)="query.targetAttributeInput = ''"
                  >
                    cancel
                  </span>
                </li>
                <li>
                  <div class="eql-del-wrap" id="conditions_click_deleteConditions_{{ i }}" (click)="deleteCondition(i)">
                    <div class="con-list-right-del"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="eql-block first-btns">
            <ul class="btn-wrapp-right-condition">
              <li id="conditions_addNewCondition" (click)="addNewCondition()">
                <span id="conditions_add_circle"><mat-icon>add_circle</mat-icon></span
                ><span id="conditions_add_rule" class="condition-add-rule"> Rule </span>
              </li>
            </ul>
          </div>
        </div>
        <div id="conditions_create_btn_displayConditionFlag" class="create-btn" *ngIf="displayConditionFlag">
          <button id="conditions_createCondition" class="btn condition-btn" (click)="createCondition()">
            {{ 'Create ' + conditionType + ' conditions' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- && attr?.dsdId==selectedAttr?.id -->
