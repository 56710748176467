import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { parseLocal, findLocale } from '@common-services';

@Directive({
  selector: '[appCurrencyMask]',
})
export class CurrencyMaskDirective {
  @Input() set _labelType(value: any) {
    /* istanbul ignore else */
    if (value) {
      this.currLocale = findLocale(value.curr);
      this.previousLocale = findLocale(value.prev);
      this.format(this.temp);
    }
  }
  previousLocale: any;
  currLocale: any;

  temp: any;

  constructor(public el: ElementRef, public renderer: Renderer2, public ngControl: NgControl) {}

  ngOnInit() {
    this.format(this.el.nativeElement.value); // format any initial values
  }

  @HostListener('input', ['$event.target.value']) onInput(e: string) {
    this.format(e);
    this.temp = e;
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.format(event.clipboardData.getData('text/plain'));
    this.temp = event.clipboardData.getData('text/plain');
  }

  format(val: string) {
    // 1. test for non-number characters and replace/remove them
    if (
      this.previousLocale &&
      this.currLocale &&
      ((this.currLocale != 'de-DE' && val?.slice(-1) !== '.') || (this.currLocale == 'de-DE' && val?.slice(-1) !== ','))
    ) {
      let numericVal = parseLocal(val, this.previousLocale);
      this.previousLocale = this.currLocale;
      let convertedValue = '';
      /* istanbul ignore else */
      if (val && numericVal) {
        convertedValue = Intl.NumberFormat(this.currLocale).format(numericVal);
      }
      // 3. replace the input value with formatted numbers
      this.temp = convertedValue;
      this.ngControl.valueAccessor.writeValue(convertedValue);
    }
  }
}
