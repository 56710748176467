import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpCacheService } from '@common-services';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CacheInterceptor implements HttpInterceptor {
  reqToBeCached = [
    // '/dsd-orch/translator/language',
    '/dsd-orch/design/book/global/design/-1',
    '/dsd-orch/design/book/global/time/design/',
    '/dsd-orch/dsd-files-store/content/cdui/static/assets/covers',
  ];

  postCallMappings: any = {
    'design/book/design': ['/dsd-orch/design/book/global/time/design/', '/dsd-orch/design/book/global/design/-1'],
  };
  constructor(private cacheService: HttpCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isSubstring = false;
    let cachedResponse: any;
    if (req?.urlWithParams?.includes('/dld/')) {
      return of(null);
    }

    /* istanbul ignore next */
    if (
      (req.method == 'POST' || req.method == 'DELETE') &&
      (req?.urlWithParams.includes('design/book/design') || req?.urlWithParams.includes('design/gsi/design'))
    ) {
      let cacheMapKey = 'design/book/global/time/design';
      let bookId = req.method == 'POST' ? req?.body?.bookId : req?.url?.split('/').pop().split('?').shift(); // post url
      // const cacheMaps = this.postCallMappings[cacheMapKey];
      // if (cacheMaps) {
      // this.postCallMappings[cacheMapKey].forEach((key: string) => {
      // delete this.cacheService[key]
      // });
      // }
      for (const prop in this.cacheService) {
        if (this.cacheService && prop?.includes(cacheMapKey)) {
          delete this.cacheService[prop];
        }
      }

      // this.cacheService.delete(bookId);
    }
    if (localStorage.getItem('logout')) {
      delete this.cacheService['/api/dsd-orch/translator/language'];
      this.cacheService.deleteTranslation('/api/dsd-orch/translator/language');
    }
    this.reqToBeCached.forEach((item: any) => {
      /* istanbul ignore next */
      isSubstring = req?.urlWithParams?.includes(item);
      /* istanbul ignore next */
      if (isSubstring) {
        cachedResponse = this.cacheService[req?.urlWithParams] || null;
      }
    });
    /* istanbul ignore next */
    if (cachedResponse) {
      return of(cachedResponse);
    }
    /* istanbul ignore next */
    let index = this.reqToBeCached.findIndex((url: any) => url === req?.url);
    /* istanbul ignore next */
    if (index != -1) {
      cachedResponse = this.cacheService[req?.urlWithParams] || null;
      if (cachedResponse) {
        return of(cachedResponse);
      }
    }
    /* istanbul ignore next */
    return next?.handle(req).pipe(
      tap(
        (event) => this.handleResponse(req, event),
        (error) => this.handleError(req, error)
      )
    );
  }

  handleError(req: HttpRequest<any>, error: any): void {}

  handleResponse(req: HttpRequest<any>, event: HttpEvent<any>): void {
    /* istanbul ignore next */
    let index = this.reqToBeCached.findIndex((url: any) => url === req?.url);
    let isSubstring = false;
    this.reqToBeCached.forEach((item: any) => {
      /* istanbul ignore next */
      isSubstring = req?.urlWithParams?.includes(item);
      /* istanbul ignore next */
      if (isSubstring) {
        this.cacheService[req?.urlWithParams] = event;
      }
    });
    /* istanbul ignore next */
    if (event instanceof HttpResponse && index != -1) {
      this.cacheService[req?.urlWithParams] = event;
    }
  }
}

