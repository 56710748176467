import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AlertService, entityMapper, Librarytypes, NodeGsiFacadeService, TranslatorService
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
export interface DialogData {
  node: any;
  graphComponent: any;
  gsiData: any;
}
const REGEX = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
@Component({
  selector: 'app-node-reserved-action-entity',
  templateUrl: './node-reserved-action-entity.component.html',
  styleUrls: ['./node-reserved-action-entity.component.scss'],
})
export class NodeReservedActionEntityComponent implements OnInit, OnDestroy {
  @Input() node: any;
  @Input() graphComponent: boolean;
  @Input() gsiData: any;

  @Output() emitChangeCompoent: EventEmitter<any> = new EventEmitter<any>();
  entityListInCu: any = [];
  reservedInfo: any = { type: 'reservedActions', label: 'reservedActions', participatingItems: [] };
  ngUnsubscribe = new Subject();
  reservedActionList: any;
  selectedReservedAction: any = [];
  backupActions: any = [];
  labels: any;
  betData: any;
  selectedEntityList: any = [];
  changeUnit: any;
  openCu: boolean = false;
  editFileUrl: any;
  fileUrl: any;
  layerType: any;
  expressionEvalQuery: any = {
    sourceAttribute: '',
    operator: '',
    targetAttributeInput: '',
  };
  constantChangeDriverObj: any = {};
  layerData: any = [];
  constantChangeDrivers: any;
  selectedAttribute: any;
  isRtctBoolean: boolean = false;
  defaultValue: any;
  stringContainingRtct: string[] = [];
  reservedLayer: any;
  reservedLayerData: any;
  filteredName: string = '';
  filteredActionList: any = [];
  constantChangeDriverSlot: any = {};
  constructor(
    public dialogRef: MatDialogRef<NodeReservedActionEntityComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData,
    private gsiFacade: NodeGsiFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private entityFacadeService: NodeEntityFacadeService,
    private translator: TranslatorService,
    private alertService: AlertService
  ) {
    this.getAllReservedActionEntities();
    this.detectLanguageChange();
    this.detectReservedConstantChangeDriverData();
    // this.detectConstantChangeDriverData();
  }

  ngOnInit(): void {
    if (this.dialog) {
      this.betData = this.dialog;
    }
    if (this.betData?.node) {
      this.node = this.betData?.node;
      this.changeUnit = this.node?.data;
      // this.layerType = this.betData?.layerType;
      if (this.betData?.node?.data?.layers[0]?.participatingItems) {
        this.layerData = this.betData?.node?.data?.layers[0]?.participatingItems;
      }
      // this.constantChangeDrivers = this.betData?.constantChangeDrivers;
      // this.node.showAction = false;
    }
    if (this.betData?.graphComponent) {
      this.graphComponent = this.betData?.graphComponent;
    }
    if (this.betData?.gsiData) {
      this.gsiData = this.betData?.gsiData;
    }
    this.reservedLayer = this.node?.data?.layers.find((x: any) => {
      if (x.type == 'reservedActions') return x;
    });
    if (this.reservedLayer) {
      this.layerType = 'reservedActions';
      this.selectedEntityList = this.reservedLayer?.participatingItems;
      this.constantChangeDriverSlot = this.node?.data?.slotItemProperties;
      this.reservedLayerData = this.reservedLayer?.participatingItems;
    }
    if (this.selectedEntityList.length == 0) {
      this.addAction();
    }
    // this.node.showAction = false;
  }

  GotoPrevious() {
    // this.node.showAction = true;
    this.dialogRef.close();
    this.emitChangeCompoent.emit(Librarytypes.AddNodeActionMenu);
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getAllReservedActionEntities() {
    this.gsiFacade
      .getAllReservedActionEntities()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        //this.backupActions = res.result?.data; // for delete
        /* istanbul ignore next */
        this.reservedActionList = JSON.parse(JSON.stringify(res.result?.data));
        this.filteredActionList = JSON.parse(JSON.stringify(this.reservedActionList));
        this.prepareReservedActionForCurrentCU();
      });
  }
  prepareReservedActionForCurrentCU() {
    let alreadyAvailableActions: any[] = [];
    /* istanbul ignore else */
    if (this.gsiData && this.gsiData.solutionLogic && this.gsiData.solutionLogic.length > 0) {
      /* istanbul ignore next */
      this.gsiData.solutionLogic?.forEach((cu: any) => {
        if (cu?.name == this.node?.data?.name && cu?.layers.length > 0) {
          cu?.layers?.forEach((layer: any) => {
            if (layer.type == 'reservedActions') {
              layer.participatingItems?.forEach((item: any) => {
                alreadyAvailableActions.push(item?.name);
                this.reservedInfo.participatingItems.push(entityMapper(item));
                this.entityListInCu.push(item); // for delete
                this.selectedReservedAction.push(item);
              });
            }
          });
        }
      });
    }
    /* istanbul ignore next */
    alreadyAvailableActions?.forEach((action: any) => {
      const index = this.reservedActionList.findIndex((act: any) => act?.name === action);
      this.reservedActionList.splice(index, 1);
    });
  }

  addAction() {
    let blankData = {
      id: '',
      dsdId: false,
      name: '',
      isReserved: '',
      status: '',
    };
    this.selectedReservedAction.push(blankData);
  }
  removeAction() {
    let action = this.selectedReservedAction.pop();
    this.reservedActionList.push(action);
  }

  selectedReservedEntity(action: any, resAction: any, i: number) {
    //resAction = action.value;
    /* istanbul ignore next */
    this.fetchEntityById(action?.value?.dsdId, i);
    const addIndex = this.selectedReservedAction.findIndex((act: any) => act?.name === action?.value?.name);
    if (addIndex != -1) {
      this.selectedReservedAction[addIndex] = action.value;
    } else {
      this.selectedReservedAction[i] = action.value;
    }
    // const removeIndex = this.reservedActionList.findIndex((x: any) => x.dsdId === action.value.dsdId);
    // this.reservedActionList.splice(removeIndex, 1);
    this.filteredName = '';
  }
  fetchEntityById(id: number, index: any) {
    this.entityFacadeService
      .getEntityDetailsforReservedActions(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let machineEntitExist: boolean = false;
        let entityExist: boolean = false;
        this.selectedEntityList?.forEach((ent: any) => {
          if (ent?.name == res?.result?.name) {
            entityExist = true;
            this.alertService.showToaster('Entity Already Selected', '', 'info');
          }
        });
        this.selectedEntityList?.forEach((entity: any) => {
          if (entity?.name?.includes('NSL_Auto') && res?.result?.name?.includes('NSL_Auto')) {
            machineEntitExist = true;
            this.alertService.showToaster('Only One Machine Entity is Allowed', '', 'info');
          }
        });
        if (!machineEntitExist && !entityExist) {
          this.selectedEntityList[index] = res?.result;
          this.entityListInCu.push(res?.result);
          let foundLayer = this.changeUnit?.layers?.find((layer: any) => layer.type === 'reservedActions');
          this.layerType = 'reservedActions';
          /* istanbul ignore next */
          if (!foundLayer) {
            let reservedInfo: any = { type: 'reservedActions', label: 'reservedActions', participatingItems: [] };
            reservedInfo.participatingItems.push(this.selectedEntityList[index]);
            this.changeUnit?.layers.push(reservedInfo);
          }
        }
      });
  }

  fillEntities() {
    let tempList: any[] = [];
    this.selectedReservedAction.forEach((entity: any) => {
      const ind = this.entityListInCu.find((ent: any) => entity?.name == ent?.name);
      tempList.push(ind);
    });
    this.entityListInCu = tempList;
  }
  saveReservedEntities() {
    this.addCCDForDynamicAttr();
    if (this.constantChangeDriverSlot && Object.keys(this.constantChangeDriverSlot).length > 0) {
      this.changeUnit = this.saveConstantChangeDriver(this.changeUnit);
    }
    this.fillEntities();
    this.reservedInfo.participatingItems = [];
    /* istanbul ignore next */
    this.entityListInCu?.forEach((entity: any) => {
      this.reservedInfo.participatingItems.push(entityMapper(entity));
    });
    /* istanbul ignore next */
    const cu = this.node?.data;
    /* istanbul ignore next */
    const resLayerIndex = cu?.layers.findIndex((x: any) => x.type === 'reservedActions');
    if (resLayerIndex === -1) {
      /* istanbul ignore next */
      cu?.layers.push(this.reservedInfo);
    } else {
      cu.layers[resLayerIndex] = this.reservedInfo;
    }

    cu.id = cu.referencedChangeUnit;
    cu.tfId = cu.tfReferencedChangeUnit;

    this.changeunitFacade.saveDraftCu(cu);
    // this.gsiData.solutionLogic.forEach((cu: any) => {
    //   if (cu.name === this.node.data.name) {
    //     cu.layers.push(this.reservedInfo);
    //   }
    // });
    //
    // this.gsiFacade.saveGsi(this.gsiData);
    this.emitChangeCompoent.emit(Librarytypes.AddNodeActionMenu);
    this.dialogRef.close();
  }

  saveConstantChangeDriver(changeunitData: any) {
    let ccdData = [];
    /*istanbul ignore next*/

    Object.keys(this.constantChangeDriverSlot)?.forEach((ccd: any) => {
      let constantFound = false;
      this.constantChangeDriverSlot[ccd].propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords?.forEach(
        (txnRecord: any) => {
          constantFound = this.findPropertiesConstantChangeDriver(txnRecord.txnNslAttribute);
        }
      ); /*istanbul ignore next*/

      if (constantFound) {
        ccdData.push(ccd);
      } else {
        delete this.constantChangeDriverSlot[ccd];
      }
    });

    /*istanbul ignore next*/
    changeunitData.slotItemProperties =
      ccdData.length > 0 ? { ...changeunitData.slotItemProperties, ...this.constantChangeDriverSlot } : {};
    return changeunitData;
  }

  findPropertiesConstantChangeDriver(data: any): any {
    /*istanbul ignore next */
    let constantFound = false;
    data?.find((x: any) => x?.properties?.includes('CONSTANT'));
    for (let i = 0; i < data.length; i++) {
      /*istanbul ignore next */
      if (data[i]?.properties?.includes('CONSTANT')) {
        constantFound = true;
      } else if (data[i]?.txnGeneralEntity?.transEntityRecords.length > 0) {
        constantFound = true;
        data[i].txnGeneralEntity.transEntityRecords?.forEach((txnRecord: any) => {
          constantFound = constantFound && this.findPropertiesConstantChangeDriver(txnRecord.txnNslAttribute);
        });
      }
    }
    return constantFound;
  }

  addCCDForDynamicAttr() {
    this.changeUnit?.layers?.forEach((laye: any) => {
      laye?.participatingItems?.forEach((entit: any, index: any) => {
        this.stringContainingRtct = [];
        this.constantChangeDriverObj = undefined;
        entit.nslAttributes?.forEach((att: any) => {
          /* istanbul ignore next*/
          if (laye.type == 'reservedActions') {
            if (att?.isRtctBoolean == true && !this.stringContainingRtct.includes(att?.name)) {
              this.stringContainingRtct.push(att?.name);
            }
            if (att?.name == 'Dynamic Attributes' && this.stringContainingRtct.length > 0) {
              this.constantChangeDriverObj = {
                slotId: entit?.dsdId,
                slotIndex: index,
                entity: entit,
                attributeId: att?.dsdId,
                layerType: laye?.type,
                values: [this.stringContainingRtct.toString()],
                selected: true,
              };
            }
          }
        });
        /* istanbul ignore next*/
        if (this.constantChangeDriverObj && this.constantChangeDriverObj != null) {
          this.changeunitFacade.addConstantChangeDriverToSlot(this.constantChangeDriverObj);
          this.constantChangeDriverObj = undefined;
          this.stringContainingRtct = [];
        }
      });
    });
  }

  deleteAction(action: any, i: any) {
    this.selectedReservedAction.splice(i, 1);
    this.selectedEntityList.splice(i, 1);
    // let addIndex = this.backupActions.findIndex((x: any) => x.name === action.name); // for delete
    // this.reservedActionList.push(this.backupActions[addIndex]);
    //
    // this.reservedInfo.participatingItems.splice(
    //   this.entityListInCu.findIndex((x: any) => x.name === action.name),
    //   1
    // );
    // this.entityListInCu.splice(
    //   this.entityListInCu.findIndex((x: any) => x.name === action.name),
    //   1
    // );
    if (this.constantChangeDriverSlot && this.selectedEntityList?.length > 0) {
      let res = {
        slotIndex: i,
        layerType: 'reservedActions',
      };
      let layerSlot = this.getLayerId(res);
      delete this.constantChangeDriverSlot[layerSlot];
    }
  }

  getLayerId(res: any) {
    let indexPadding = this.getIndexPadding(res.slotIndex + 1);
    if (indexPadding) {
      let layerShortCut = 'PL_SL' + indexPadding;
      /*istanbul ignore next*/
      if (res?.layerType == 'information') {
        layerShortCut = 'IL_SL' + indexPadding;
      } else if (res?.layerType == 'triggerCES') {
        /*istanbul ignore next*/
        layerShortCut = 'triggerCES_SL' + indexPadding;
      } else if (res?.layerType === 'reservedActions') {
        /*istanbul ignore next*/
        layerShortCut = 'reservedActions_SL' + indexPadding;
      }
      return layerShortCut;
    }
  }

  openReservedAttr(entity: any, attr: any, attributeIndex: number, entityIndex: number) {
    attr.showAttribute = true;
    this.selectedAttribute = attr;
    entity.selectedAttribute = this.selectedAttribute;
    this.defaultValue = attr.defaultValue;
    this.editFileUrl = '';
    let res = {
      slotIndex: entityIndex,
      layerType: 'reservedActions',
    };
    let layerShortCut = this.getLayerId(res);
    entity.constantChangeDriverEntity = false;
    /* istanbul ignore next */
    if (this.node?.data?.slotItemProperties && this.node?.data?.slotItemProperties[layerShortCut]) {
      let ccdValues = this.changeUnit?.slotItemProperties[layerShortCut]?.propertyData?.CONSTANT?.changeDriverData?.DATA
        ?.transEntityRecords[0]?.txnNslAttribute;
      this.constantChangeDrivers = ccdValues;
      if (
        this.changeUnit?.slotItemProperties[layerShortCut]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.properties
          ?.length > 0
      ) {
        entity.constantChangeDriverEntity = true;
      }
    }
    /* istanbul ignore next */
    if (this.constantChangeDrivers && this.constantChangeDrivers?.length > 0) {
      let rtctToogleTrue: any;
      this.constantChangeDrivers.forEach((ccd: any) => {
        if (ccd?.name == 'Dynamic Attributes') {
          rtctToogleTrue = ccd?.values?.toString();
        }
      });
      let rtctToogleString: any;
      let ccdIdSet: Set<string> = new Set<string>();
      this.constantChangeDrivers.forEach((ccd: any) => {
        ccdIdSet.add(ccd?.dsdId);
        if (ccd?.name == 'Dynamic Attributes') {
          rtctToogleString = ccd?.values?.toString();
        }
      });
      this.reservedLayerData?.forEach((entity: any) => {
        entity?.nslAttributes?.forEach((attribute: any) => {
          /* istanbul ignore next*/
          if (
            attribute?.id &&
            ccdIdSet?.has(attribute.id.toString()) &&
            attribute?.name &&
            rtctToogleString?.includes(attribute?.name)
          ) {
            attribute.isRtctBoolean = true;
          }
        });
      });
      this.constantChangeDrivers?.forEach((CCD: any) => {
        let checkForId = CCD?.nslAttributeID ? CCD?.nslAttributeId : CCD?.dsdId;
        if (CCD?.dsdId == attr?.id) {
          /* istanbul ignore next*/
          if (rtctToogleTrue?.includes(CCD?.name)) {
            attr.isRtctBoolean = true;
            this.reservedLayerData[entityIndex].nslAttributes[attributeIndex].rtctData = CCD?.values[0];
          } else if (this.IsJsonString(CCD?.values[0])) {
            attr.defaultValue = JSON.parse(CCD.values[0]);
            if (attr?.attributeType?.type == 'file') {
              this.editFileUrl = attr.defaultValue;
            }
            if (attr?.attributeType?.type == 'boolean') attr.defaultValue = CCD.values[0];
          } else {
            attr.defaultValue = CCD.values[0];
          }
          if (this.layerType == 'reservedActions' && attr?.nslAttributeProperties?.isDynamic == 'true') {
            let resCondition = CCD.values[0];
            let operators = ['< ', '> ', '!=', '= ', '==', '<=', '>='];
            let splitedTemp = [];
            for (let operator of operators) {
              if (resCondition.includes(operator)) {
                splitedTemp = resCondition.split(operator);
                this.expressionEvalQuery.sourceAttribute = splitedTemp[0].trim();
                this.expressionEvalQuery.operator = operator.trim();
                this.expressionEvalQuery.targetAttributeInput = splitedTemp[1].trim();
                break;
              }
            }
          }
        }
      });
    }
  }

  /*istanbul ignore next*/
  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  rtctBooleanChange(attr: any) {
    if (attr) {
      attr.isRtctBoolean = !attr.isRtctBoolean;
    }
  }

  fileUploadUrl(event: any, attr: any) {
    this.fileUrl = event;

    /* istanbul ignore next */
    if (this.fileUrl) {
      attr.defaultValue = JSON.stringify(this.fileUrl);
    }
  }

  onDurationChange(event: any, attr: any) {
    attr.defaultValue = event;
  }

  onCheckedAttrVal(event: any, attr: any) {
    attr.defaultValue = event;
  }

  /* istanbul ignore next */
  saveConstantChangedriverData(entityIndex: any, layerType: any, entity: any, attr: any) {
    if (attr?.nslAttributeProperties?.isDynamic == 'true' && attr?.attributeType?.uiElement?.uiElement != 'duration') {
      if (isNaN(this.expressionEvalQuery.targetAttributeInput)) {
        this.expressionEvalQuery.targetAttributeInput = `'${this.expressionEvalQuery.targetAttributeInput}'`;
      }
      let condition = '';
      condition =
        this.expressionEvalQuery.sourceAttribute +
        this.expressionEvalQuery.operator +
        this.expressionEvalQuery.targetAttributeInput;
      attr.defaultValue = condition ? condition : attr?.defaultValue;
    }
    attr.showreservdInput = false;
    /* istanbul ignore next */
    if (typeof attr.defaultValue !== 'string') {
      attr.defaultValue = JSON.stringify(attr.defaultValue);
    }
    /* istanbul ignore next */
    if (attr?.isRtctBoolean == true) {
      /* istanbul ignore next */
      if (attr?.rtctData == null) {
        /* istanbul ignore next */
        attr.isRtctBoolean = false;
        this.alertService.showToaster('please select dynamic attribute', '', 'failed');
        return;
      } else {
        /* istanbul ignore next */
        this.constantChangeDriverObj = {
          slotId: entity?.dsdId ? entity?.dsdId : entity?.id,
          slotIndex: entityIndex,
          entity: entity,
          attributeId: attr?.dsdId,
          layerType: layerType,
          values: [attr?.rtctData],
          selected: true,
        };
      }
    } else {
      /* istanbul ignore next */
      this.constantChangeDriverObj = {
        slotId: entity?.dsdId,
        slotIndex: entityIndex,
        entity: entity,
        attributeId: attr?.dsdId,
        layerType: layerType,
        values: [attr.defaultValue],
        selected: true,
      };
      this.removeAttributeFromHiddenAttributeIfPresent(entity, attr, entityIndex);
    }
    this.changeunitFacade.addConstantChangeDriverToSlot(this.constantChangeDriverObj);
  }

  removeAttributeFromHiddenAttributeIfPresent(entity: any, attr: any, entityIndex: number) {
    this.constantChangeDrivers?.forEach((attribute1: any) => {
      {
        if (attribute1?.name == 'Dynamic Attributes') {
          let values = attribute1.values[0].split(',');
          values = values.filter((val1: any) => {
            return val1 != attr?.name;
          });
          attribute1.values[0] = values.toString();
        }
      }
    });
  }

  // detectConstantChangeDriverData() {
  //   this.changeunitFacade.updateConstantChangeDriverData$
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       /* istanbul ignore next */
  //       if (res && res?.length > 0) {
  //         this.constantChangeDrivers = res;
  //       }
  //     });
  // }

  addAttr(attr: any, entIndex: any, ent: any, layerType: any) {
    let formulaId = this.generateFormulaId(layerType, entIndex, ent?.name, attr?.name);
    if (this.selectedAttribute.attributeType.uiElement.uiElement == attr.attributeType.uiElement.uiElement) {
      this.selectedAttribute.rtctData = formulaId;
      this.selectedAttribute.isRtctBoolean = true;
    } else {
      this.alertService.showToaster('UI elements are different', '', 'failed');
    }
    this.expressionEvalQuery.sourceAttribute = formulaId;
  }

  generateFormulaId(
    layerType: string,
    entityIndex: any,
    entityName: string,
    attributeName: string,
    isForDependent?: boolean,
    isEntityname?: boolean
  ) {
    /* istanbul ignore next */
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    let indexPadding = this.getIndexPadding(entityIndex + 1);
    let dot = '.';
    let layerShortCut = 'PL.SL' + indexPadding;
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      layerShortCut = 'triggerCES.SL' + indexPadding;
    }
    let formulaId =
      isForDependent && !isEntityname ? layerShortCut + dot + 'EN' + entityName : layerShortCut + dot + entityName;
    /* istanbul ignore else */
    if (attributeName) {
      formulaId = formulaId + dot + attributeName;
    }
    return formulaId;
  }

  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  onKeyPress(event: any) {
    return event.charCode >= 48 && event.charCode != 101 && event.charCode != 69;
  }

  detectReservedConstantChangeDriverData() {
    this.changeunitFacade.constantChangeDriver$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.changeUnit?.layers.forEach((layer: any) => {
          let foundEnt = layer.participatingItems?.find((x: any) => x.id === res?.entity?.id);
          if (foundEnt && layer.type === res.layerType) {
            // this.addConstantFlag = true;
            let foundAttr = foundEnt?.nslAttributes?.find((x: any) => x.id === res?.attributeId);
            if (foundAttr && res.values?.length > 0) {
              foundAttr.values = res.values;
            }
          }
        });
        this.setReservedConstantChangeDriver(res);
      }
    });
  }

  setReservedConstantChangeDriver(res: any) {
    let layerShortCut = this.getLayerId(res);
    /* istanbul ignore else */
    if (this.constantChangeDriverSlot && this.constantChangeDriverSlot[layerShortCut]) {
      /*istanbul ignore next*/
      if (res?.entity?.constantChangeDriverEntity) {
        this.constantChangeDriverSlot[layerShortCut].propertyData.CONSTANT.changeDriverData.DATA.properties = [
          'CONSTANT',
        ];
      }
      let isEntityFoundInCCD = false;

      res?.entity?.nslAttributes?.forEach((attr: any, i: any) => {
        this.constantChangeDriverSlot[
          layerShortCut
        ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute.forEach(
          (ele: any) => {
            /* istanbul ignore else */
            if (ele?.dsdId == attr.dsdId) {
              isEntityFoundInCCD = true;
              this.updateCCDforAttributes(
                this.constantChangeDriverSlot[layerShortCut]?.propertyData?.CONSTANT?.changeDriverData?.DATA
                  ?.transEntityRecords[0],
                res,
                layerShortCut
              );
              return;
            } else {
              isEntityFoundInCCD = false;
            }
          }
        );
      });
      /*istanbul ignore next*/
      if (isEntityFoundInCCD == false) {
        this.createTxnEntity(res, layerShortCut, res.entity?.name === 'NSL_Query_Canvas' ? false : true);
      }
    } else {
      this.createTxnEntity(res, layerShortCut, false);
    }
  }

  createTxnEntity(res: any, layerShortCut: any, isCCDAvailable: any) {
    if (!isCCDAvailable) {
      if (res?.entity) {
        let constantPropertyData: any = {
          propertyData: {
            CONSTANT: {
              cuLayer: res?.layerType,
              slotItemIndex: res?.slotIndex,
              changeDriverData: {
                TYPE: 'TxnGeneralEntity',
                DATA: {
                  name: res?.entity?.name,
                  dsdId: res?.entity?.dsdId,
                  transEntityRecords: [
                    {
                      txnNslAttribute: [],
                    },
                  ],
                },
              },
            },
          },
        };
        res.entity?.nslAttributes?.forEach((attribute: any) => {
          let txnAttribute: any = {};
          txnAttribute.name = attribute?.name;
          txnAttribute.dsdId = attribute?.dsdId;
          if (attribute?.generalEntity) {
            txnAttribute.txnGeneralEntity = this.createTxnEntity(
              {
                attributeId: res.attributeId,
                generalEntity: attribute?.generalEntity,
                values: res.values,
                layerType: res.layerType,
                slotIndex: res.slotIndex,
                slotId: res.slotId,
                selected: res.selected,
              },
              layerShortCut,
              false
            );
            /*istanbul ignore next*/
            if (txnAttribute.txnGeneralEntity.transEntityRecords[0].txnNslAttribute.length != 0) {
              constantPropertyData?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute?.push(
                txnAttribute
              );
            }
          } else if (
            res.attributeId == attribute.dsdId &&
            res?.values?.length > 0 &&
            attribute?.attributeType?.type !== 'entity'
          ) {
            txnAttribute.values = res.values;
            if (res.selected) {
              txnAttribute.properties = ['CONSTANT'];
            }
            /*istanbul ignore next*/
            constantPropertyData?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute?.push(
              txnAttribute
            );
          }
        });
        if (res?.entity?.constantChangeDriverEntity) {
          constantPropertyData.propertyData.CONSTANT.changeDriverData.DATA.properties = ['CONSTANT'];
        }
        this.constantChangeDriverSlot[layerShortCut] = constantPropertyData;
        /*istanbul ignore next*/
        this.changeunitFacade.updateConstantChangeDriver(
          constantPropertyData?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute
        );
      } else {
        let txnGeneralEntity: any = {
          name: res.generalEntity?.name,
          dsdId: res.generalEntity?.dsdId,
          transEntityRecords: [
            {
              txnNslAttribute: [],
            },
          ],
        };
        /*istanbul ignore next*/
        res.generalEntity.nslAttributes?.forEach((attribute: any) => {
          let txnAttribute: any = {};
          txnAttribute.name = attribute?.name;
          txnAttribute.dsdId = attribute?.dsdId;
          if (attribute?.generalEntity) {
            txnAttribute.txnGeneralEntity = this.createTxnEntity(
              {
                attributeId: res.attributeId,
                generalEntity: attribute?.generalEntity,
                values: res.values,
                layerType: res.layerType,
                slotIndex: res.slotIndex,
                slotId: res.slotId,
                selected: res.selected,
              },
              layerShortCut,
              false
            );
            if (txnAttribute.txnGeneralEntity.transEntityRecords[0].txnNslAttribute.length != 0) {
              txnGeneralEntity?.transEntityRecords[0]?.txnNslAttribute?.push(txnAttribute);
            }
          } else if (
            res.attributeId == attribute.dsdId &&
            res?.values?.length > 0 &&
            attribute?.attributeType?.type !== 'entity' &&
            attribute?.attributeType?.type !== 'list'
          ) {
            txnAttribute.values = res.values;
            if (res.selected) {
              txnAttribute.properties = ['CONSTANT'];
            }
            /*istanbul ignore next*/
            txnGeneralEntity?.transEntityRecords[0]?.txnNslAttribute?.push(txnAttribute);
          }
        });
        return txnGeneralEntity;
      }
    } else {
      this.updateCCDforAttributes(
        this.constantChangeDriverSlot[layerShortCut]?.propertyData?.CONSTANT?.changeDriverData?.DATA
          ?.transEntityRecords[0],
        res,
        layerShortCut
      );
    }
  }

  updateCCDforAttributes(transEntityRecords: any, res: any, layerShortCut: any) {
    let isCCDupdate = false;
    transEntityRecords.txnNslAttribute.forEach((value: any, i: any) => {
      /*istanbul ignore next*/
      if (value?.txnGeneralEntity) {
        let tempRes = JSON.parse(JSON.stringify(res));
        isCCDupdate = this.updateCCDforAttributes(
          value.txnGeneralEntity?.transEntityRecords[0],
          tempRes,
          layerShortCut
        );
      } else {
        let foundAttrIndex = -1;
        foundAttrIndex = transEntityRecords.txnNslAttribute?.findIndex(
          (x: any) => x.nslAttributeID === res.attributeId || x.dsdId === res.attributeId
        );
        /*istanbul ignore next */
        if (foundAttrIndex !== -1) {
          /*istanbul ignore next */
          if (res.selected && res.values?.length > 0) {
            transEntityRecords.txnNslAttribute[foundAttrIndex].values = res.values ? res.values : [];
            transEntityRecords.txnNslAttribute[foundAttrIndex].properties = ['CONSTANT'];
            isCCDupdate = true;
          } else {
            transEntityRecords.txnNslAttribute.splice(foundAttrIndex, 1);
            isCCDupdate = true;
          }
        } else {
          //if we deleted an attribute and then gave ccd
          res?.entity?.nslAttributes.forEach((attribute: any) => {
            let index = -1;
            index = this.constantChangeDriverSlot[
              layerShortCut
            ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute?.findIndex(
              (x: any) => x.dsdId === attribute.dsdId
            );
            if (index === -1) {
              const attr: any = {
                name: attribute?.name,
                dsdId: attribute?.dsdId,
              };
              if (attribute?.dsdId === res?.attributeId) {
                if (res.values?.length > 0 && res.selected) {
                  attr.values = res.values;
                  attr.properties = ['CONSTANT'];
                  this.constantChangeDriverSlot[
                    layerShortCut
                  ]?.propertyData?.CONSTANT.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute.push(attr);
                  isCCDupdate = true;
                }
              }
            }
          });
        }
      }

      if (isCCDupdate) {
        return true;
      }
    });
    /*istanbul ignore next*/
    if (isCCDupdate) {
      return true;
    } else {
      // add new nested record
      let localBoolean: boolean = false;
      let attr = this.updateOldCCDwithNewData(res, layerShortCut);
      transEntityRecords.txnNslAttribute.forEach((attribute1: any) => {
        if (attribute1?.name == attr?.name) {
          attribute1.values = attr?.values;
          attribute1.properties = attr?.properties;
          localBoolean = true;
        }
      });
      if (localBoolean) {
        return true;
      }

      if (attr) {
        let tempAttr: any = this.findCCDToAttach(
          attr,
          transEntityRecords.txnNslAttribute,
          this.constantChangeDriverSlot[layerShortCut].propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0]
            .txnNslAttribute
        );
        if (!tempAttr || tempAttr == 0) {
          transEntityRecords.txnNslAttribute.push(attr);
        } else {
          transEntityRecords.txnNslAttribute = tempAttr;
        }
      }
      return true;
    }
  }

  findCCDToAttach(attr: any, txnNslAttribute: any, ccd: any) {
    for (let value of ccd) {
      if (value.txnGeneralEntity) {
        if (value?.dsdId == attr.dsdId) {
          txnNslAttribute.push(attr.txnGeneralEntity.transEntityRecords[0].txnNslAttribute[0]);
          return txnNslAttribute;
        }
      }
    }
  }

  updateOldCCDwithNewData(res: any, layerShortCut: any, name?: any) {
    /*istanbul ignore next*/
    if (res?.entity) {
      let txnAttrData: any = {};
      res.entity?.nslAttributes?.forEach((attribute: any) => {
        let txnAttribute: any = {};
        txnAttribute.name = attribute?.name;
        txnAttribute.dsdId = attribute?.dsdId;
        if (attribute?.generalEntity) {
          txnAttribute.txnGeneralEntity = this.updateOldCCDwithNewData(
            {
              attributeId: res.attributeId,
              generalEntity: attribute?.generalEntity,
              values: res.values,
              layerType: res.layerType,
              slotIndex: res.slotIndex,
              slotId: res.slotId,
              selected: res.selected,
            },
            layerShortCut
          );
          if (name && name == txnAttribute?.name) {
            txnAttrData = txnAttribute;
          }
        } else if (
          res.attributeId == attribute.dsdId &&
          res?.values?.length > 0 &&
          attribute?.attributeType?.type !== 'entity' &&
          attribute?.attributeType?.type !== 'list'
        ) {
          txnAttribute.values = res.values;
          if (res.selected) {
            txnAttribute.properties = ['CONSTANT'];
          }
          txnAttrData = txnAttribute;
        }
      });
      return txnAttrData;
    } else {
      let txnGeneralEntity: any = {
        name: res.generalEntity?.name,
        dsdId: res.generalEntity?.dsdId,
        transEntityRecords: [
          {
            txnNslAttribute: [],
          },
        ],
      };
      res.generalEntity.nslAttributes?.forEach((attribute: any) => {
        let txnAttribute: any = {};
        txnAttribute.name = attribute?.name;
        txnAttribute.dsdId = attribute?.dsdId;
        if (attribute?.generalEntity) {
          txnAttribute.txnGeneralEntity = this.updateOldCCDwithNewData(
            {
              attributeId: res.attributeId,
              generalEntity: attribute?.generalEntity,
              values: res.values,
              layerType: res.layerType,
              slotIndex: res.slotIndex,
              slotId: res.slotId,
              selected: res.selected,
            },
            layerShortCut
          );
          if (txnAttribute.txnGeneralEntity.transEntityRecords[0].txnNslAttribute.length != 0) {
            txnGeneralEntity?.transEntityRecords[0]?.txnNslAttribute?.push(txnAttribute);
          }
        } else if (
          res.attributeId == attribute.dsdId &&
          res?.values?.length > 0 &&
          attribute?.attributeType?.type !== 'entity' &&
          attribute?.attributeType?.type !== 'list'
        ) {
          txnAttribute.values = res.values;
          if (res.selected) {
            txnAttribute.properties = ['CONSTANT'];
          }
          /*istanbul ignore next*/
          txnGeneralEntity?.transEntityRecords[0]?.txnNslAttribute?.push(txnAttribute);
        }
      });
      return txnGeneralEntity;
    }
  }

  filterReservedActionList(filter: string) {
    let list = filter
      ? this.reservedActionList?.filter((action: any) => action?.name?.toLowerCase()?.includes(filter))
      : this.reservedActionList;
    this.filteredActionList = JSON.parse(JSON.stringify(list));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
