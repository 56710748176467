<div id="extract-stepper" class="extract-stepper-wrap">
  <mat-vertical-stepper #stepper class="extract-stpr" [selectedIndex]="1">
    <mat-step>
      <ng-template matStepLabel>{{ labels?.Extract_Solution }}</ng-template>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ labels?.Add_File }}</ng-template>
      <div class="extraction-content">
        <div class="imp-file-wrapp">
          <div class="imp-file" id="file-upload">
            <label class="custom-file-upload">
              <ng-container *ngIf="extractionType === extractionTypes.SOP">
                <input id="selectExtractionFile" type="file" accept="audio/*" (change)="onSelectFile($event)" />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.BPMN">
                <input id="selectExtractionFile" type="file" accept="video/*" (change)="onSelectFile($event)" />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.Excel">
                <input
                  id="selectExtractionFile"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  (change)="onSelectFile($event)"
                />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.FC">
                <input
                  id="selectExtractionFile"
                  type="file"
                  accept=".doc, .docx,.ppt, .pptx"
                  (change)="onSelectFile($event)"
                />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.VITT">
                <input id="selectExtractionFile" type="file" (change)="onSelectFile($event)" />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.XML">
                <input id="selectExtractionFile" type="file" accept="text/xml" (change)="onSelectFile($event)" />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.GUIDE">
                <input id="selectExtractionFile" type="file" (change)="onSelectFile($event)" />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.digitalcanvas">
                <input id="selectExtractionFile" type="file" (change)="onSelectFile($event)" />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
              <ng-container *ngIf="extractionType === extractionTypes.IMAGE2NSL">
                <input
                  id="selectExtractionFile"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  (change)="onSelectFile($event)"
                />
                <span id="selectFile" for="selectExtractionFile"
                  ><img [src]="'../../../../../assets/images/upload-icon-extract.png' | CDNUrlRewritePipe" alt=""
                /></span>
              </ng-container>
            </label>

            <div id="file-upload-filename"></div>
          </div>
        </div>
        <div id="extractionFilePreview" class="file-preview">
          <h3 *ngIf="uploadFile">{{ labels?.Preview }}</h3>
          <h3 *ngIf="!uploadFile">{{ labels?.Select_file_to_preview }}</h3>
          <audio id="audio" *ngIf="selectedFileType == 'audio'" [src]="url" controls></audio>
          <video
            (click)="openDialog('video')"
            id="video"
            class="video-preview"
            *ngIf="selectedFileType == 'video'"
            [src]="url"
            controls
          >
            <track label="English" kind="captions" srclang="en" src="" default />
          </video>
          <img
            (click)="openDialog('image')"
            class="image-preview"
            id="image"
            *ngIf="selectedFileType == 'image' && url"
            [src]="url"
            alt=""
          />
          <div class="textupload" id="text" *ngIf="selectedFileType == 'text'" [innerHTML]="textContent"></div>
          <div
            class="diagram-preview"
            *ngIf="textContent && (selectedFileType == 'bpmn' || selectedFileType == 'xml')"
            (click)="openDialog('diagram')"
          >
            <app-bpmn-viewer
              style="width: 100%; height: 100%;"
              [xml]="textContent"
              [showDiagramButtons]="false"
              [showExportButtons]="false"
            ></app-bpmn-viewer>
          </div>
        </div>
        <div class="select-type-wrap">
          <h3>{{ labels?.Select_file_type }}</h3>
          <ul class="custom-select-list">
            <li
              id="extractionTypeSop"
              [class.active]="extractionType === extractionTypes.SOP"
              (click)="extractionType = extractionTypes.SOP"
            >
              <img
                [src]="'../../../../../assets/images/extract-select-icon01.png' | CDNUrlRewritePipe"
                alt="file type"
              />
              {{ extractionTypes.SOP }}
            </li>
            <li
              id="extractionTypeBpmn"
              [class.active]="extractionType === extractionTypes.BPMN"
              (click)="extractionType = extractionTypes.BPMN"
            >
              <img
                [src]="'../../../../../assets/images/extract-select-icon02.png' | CDNUrlRewritePipe"
                alt="file type"
              />
              {{ extractionTypes.BPMN }}
            </li>
            <li
              id="extractionTypeFc"
              [class.active]="extractionType === extractionTypes.FC"
              (click)="extractionType = extractionTypes.FC"
            >
              <img
                [src]="'../../../../../assets/images/extract-select-icon03.png' | CDNUrlRewritePipe"
                alt="file type"
              />
              {{ extractionTypes.FC }}
            </li>

            <li
              id="extractionTypeDocx"
              [class.active]="extractionType === extractionTypes.GUIDE"
              (click)="extractionType = extractionTypes.GUIDE"
            >
              <img [src]="'../../../../../assets/images/doorway.png' | CDNUrlRewritePipe" alt="file type" />
              {{ extractionTypes.GUIDE }}
            </li>
            <li
              id="extractionTypedigitalcanvas"
              [class.active]="extractionType === extractionTypes.digitalcanvas"
              (click)="extractionType = extractionTypes.digitalcanvas"
            >
              <img [src]="'../../../../../assets/images/digital-canvas.svg' | CDNUrlRewritePipe" alt="file type" />
              {{ extractionTypes.digitalcanvas }}
            </li>
            <li
              id="extractionTypeVitt"
              [class.active]="extractionType === extractionTypes.VITT"
              (click)="extractionType = extractionTypes.VITT"
            >
              <img
                [src]="'../../../../../assets/images/extract-select-icon04.png' | CDNUrlRewritePipe"
                alt="file type"
              />
              {{ extractionTypes.VITT }}
            </li>
            <li
              id="extractionTypeImage"
              [class.active]="extractionType === extractionTypes.IMAGE2NSL"
              (click)="extractionType = extractionTypes.IMAGE2NSL"
            >
              <img
                [src]="'../../../../../assets/images/extract-select-icon02.png' | CDNUrlRewritePipe"
                alt="file type"
              />
              {{ labels?.INSL }}
            </li>
            <li
              id="extractionTypeImage"
              [class.active]="extractionType === extractionTypes.Excel"
              (click)="extractionType = extractionTypes.Excel"
            >
              <img src="../../../../../assets/images/excel-parser.svg" alt="file type" />
              Excel
            </li>
          </ul>
        </div>
        <div class="imp-btn-wrapp" id="import-btn">
          <div>
            <button class="btn cstm-btn" id="importBtn" (click)="importExtraction($event)">
              {{ labels?.Extract }}
              <img [src]="'../../../../../assets/images/extractbtn.png' | CDNUrlRewritePipe" alt="extractbtn" />
            </button>
          </div>
          <div>
            <button id="cancelBtn" class="btn cstm-btn cancel" (click)="cancel()">{{ labels?.Cancel }}</button>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
