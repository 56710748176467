<h5 *ngIf="cofiguration?.showTitle" class="heading-tuc">{{ title }}</h5>

<div class="tbl-com ui-tbl-section" [ngClass]="{ 'tbl-comp-section': isFullScreen, 'split-table': splitScreenclicked }">
  <p-table
    #vsTable
    class="ptable-wrap custom-p-table"
    [columns]="selectedColumns"
    [value]="listValues"
    (onPage)="onPage($event)"
    [paginator]="cofiguration?.pagination && (!customPagination || isInfo) && listValues.length > 10"
    [rows]="pagination?.rows"
    [rowsPerPageOptions]="[10, 25, 50]"
    paginatorDropdownAppendTo="body"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [rowsPerPageOptions]="newEnhancements ? [pagination?.rows] : [10, 25, 50]"
    (sortFunction)="customSort($event)"
    [customSort]="true"
    (onPage)="paginate($event)"
    [(selection)]="selectedTransactionArray"
    (onHeaderCheckboxToggle)="selectRows($event)"
    styleClass="p-datatable-sm"
  >
    <!-- file upload and download -->
    <ng-template pTemplate="caption">
      <div class="tbl-hder" *ngIf="tableDescription?.headerDescription">{{ tableDescription?.headerDescription }}</div>
      <div class="p-d-flex d-flex align-items-center justify-content-end">
        <p-multiSelect
          *ngIf="cofiguration?.toggleColumn"
          [options]="cols"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          selectedItemsLabel="{0} columns selected"
          [style]="{ minWidth: '150px' }"
          placeholder="Choose Columns"
        ></p-multiSelect>
        <button *ngIf="cofiguration?.inputTemplate" type="button" class="file-btn">
          <span class="cstm-lble">
            <a (click)="downloadTemplate()"> Download Template</a>
          </span>
        </button>
        <div class="file-btn" *ngIf="cofiguration?.allowUpload">
          <input
            class="cstm-button"
            id="input-file-id_{{ entityName }}_{{ slot }}"
            type="file"
            (change)="onFileChange($event)"
          />
          <label for="input-file-id_{{ entityName }}_{{ slot }}" class="cstm-lble"
            >Choose Files
            <mat-icon>
              upload
            </mat-icon></label
          >
        </div>
        <ng-container *ngIf="cofiguration?.allowDownload">
          <button type="button" class="file-btn" [matMenuTriggerFor]="downloadmenu">
            <span class="cstm-lble"><img src="../../../../../assets/img/ui-kits/export-icon.svg" /> Export File</span>
          </button>
          <mat-menu #downloadmenu="matMenu">
            <ul class="list-files">
              <li *ngIf="exportOptions?.[0]?.isChecked">
                <button mat-button (click)="exportPdf()" type="button" class="" tooltipPosition="bottom">
                  <img [src]="'../../../../../assets/img/icon-pdf.svg' | CDNUrlRewritePipe" alt="" />PDF portrait
                </button>
              </li>
              <li *ngIf="exportOptions?.[0]?.isChecked">
                <button mat-button (click)="exportLandScapePdf()" type="button" tooltipPosition="bottom">
                  <img [src]="'../../../../../assets/img/landScape-pdf.svg' | CDNUrlRewritePipe" alt="" /> PDF landscape
                </button>
              </li>
              <li *ngIf="exportOptions?.[1]?.isChecked">
                <button mat-button type="button" (click)="exportCSVFile(ptcsv)" tooltipPosition="bottom">
                  <img [src]="'../../../../../assets/img/icon-csv.svg' | CDNUrlRewritePipe" alt="" /> CSV
                </button>
              </li>
              <li *ngIf="exportOptions?.[2]?.isChecked">
                <button mat-button type="button" (click)="exportExcel()" tooltipPosition="bottom">
                  <img [src]="'../../../../../assets/img/icon-excel.svg' | CDNUrlRewritePipe" alt="" /> Excel
                </button>
              </li>
            </ul>
          </mat-menu>
        </ng-container>
        <div *ngIf="!isFullScreen && cofiguration?.allowExpand">
          <a href="javascript:;" class="" (click)="zoomTbl()">
            <img src="../../../../../assets/img/ui-kits/expand-icon.svg" />
          </a>
        </div>
        <div *ngIf="isFullScreen && cofiguration?.allowExpand">
          <a href="javascript:;" class="" (click)="minimizeTbl()">
            <img src="../../../../../assets/img/ui-kits/collapse-icon.svg" />
          </a>
        </div>
      </div>
    </ng-template>
    <!-- SrNo, attributes and filters,sorting -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          [ngClass]="{ 'freeze-element': freezeColumnName == 'selectionColumn' }"
          *ngIf="!cofiguration?.hideSelectButton && !hideSelectAllButton"
        >
          <p-tableHeaderCheckbox
            id="{{ entityName }}_{{ slot }}_Select All"
            (click)="selectedTransactions($event, -1)"
            [disabled]="isInfo"
          ></p-tableHeaderCheckbox>
        </th>
        <th *ngIf="cofiguration?.deleteRecord"></th>
        <th *ngIf="!cofiguration?.hideSelectButton && hideSelectAllButton"></th>
        <th *ngIf="!cofiguration?.hideSerialNumber">
          S.No
        </th>

        <!-- <th
          [ngClass]="{ 'freeze-element': freezeColumnName == 'selectionColumn' }"
          *ngIf="!cofiguration?.hideSelectButton && hideSelectAllButton"
        ></th>
        <th [ngClass]="{ 'freeze-element': freezeColumnName == 'SrNo' }" *ngIf="!cofiguration?.hideSerialNumber"></th>
        -->
        <th
          *ngFor="let col of columns; let index = index"
          id="col.header"
          [ngClass]="{
            hide: col?.form?.value?.isHidden === true,
            'freeze-element': (col.displayValue ? col.displayValue : col.header) == freezeColumnName
          }"
        >
          <div class="filter-wrap">
            {{ col.displayValue ? col.displayValue : col.header }}
            <div class="box-bg" *ngIf="cofiguration?.allowColumnsort" [pSortableColumn]="col.field">
              <img src="../../../../../assets/img/general-entity/table-sort.svg" alt="" />
            </div>

            <button
              type="button"
              class="box-bg"
              mat-icon-button
              [matMenuTriggerFor]="menu"
              *ngIf="cofiguration?.allowColumnFilter"
            >
              <img src="../../../../../assets/img/general-entity/table-filter.svg" alt="" />
            </button>
            <mat-menu #menu="matMenu">
              <div class="box-manu">
                <div class="input-del">
                  <span
                    ><input
                      class="input"
                      placeholder="Search by"
                      type="text"
                      [(ngModel)]="searchText"
                      (click)="innerSearch($event)"
                      #search
                  /></span>
                  <div class="delete-icon" (click)="filterFunction(col, searchText, 'Clear', searchText)">
                    <img src="../../../../../assets/img/general-entity/delete-icon.svg" alt="" />
                  </div>
                </div>

                <div
                  class="filter-list mat-menu-item"
                  *ngFor="let filter of filters"
                  (click)="filterFunction(col, searchText, filter, null)"
                >
                  {{ filter }}
                </div>
              </div>
            </mat-menu>

            <div class="box-bg" *ngIf="cofiguration?.allowColumnFilter">
              <img
                src="../../../../../assets/img/general-entity/table-serach.svg"
                (click)="searchClicked($event, index)"
                alt=""
              />
            </div>
            <div class="search-inputs" *ngIf="cofiguration?.allowColumnFilter">
              <div class="box-manu">
                <input
                  class="input"
                  placeholder="Search by"
                  type="text"
                  *ngIf="isSearchClicked && index == selectedIndex"
                  (change)="filterContainsWith($event, col, $event.target.value)"
                />
              </div>
              <img
                src="../../../../../assets/img/general-entity/close-icon.svg"
                (click)="closeInput()"
                *ngIf="isSearchClicked && index == selectedIndex"
                alt=""
              />
            </div>
          </div>
        </th>
      </tr>
    </ng-template>
    <!-- table body -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <tr *ngIf="!customPagination" [pSelectableRow]="rowData">
        <!-- tablerow selection -->
        <td
          *ngIf="!cofiguration?.hideSelectButton"
          class="w-10"
          [ngClass]="{ 'freeze-element': freezeColumnName == 'selectionColumn' }"
        >
          <div class="checkbox-flex">
            <p-tableRadioButton
              *ngIf="tableSelection === 'single'"
              [disabled]="isInfo"
              (click)="selectedTransactions($event, rowIndex)"
              [value]="rowData"
            ></p-tableRadioButton>
            <p-tableCheckbox
              *ngIf="tableSelection !== 'single' && !cofiguration?.hideSelectButton"
              class="checkbox"
              [disabled]="isInfo"
              (click)="selectedTransactions($event, rowIndex)"
              [value]="rowData"
            ></p-tableCheckbox>
          </div>
        </td>
        <!-- tablerow deletion -->
        <td *ngIf="cofiguration?.deleteRecord" class="w-10">
          <span class="span-minus" *ngIf="listValues.length > 1" (click)="deleteEntityControl(rowIndex, rowData)">
            <mat-icon>delete</mat-icon>
          </span>
        </td>
        <!-- serial number -->
        <td
          [ngClass]="{
            'freeze-element': freezeColumnName == 'SrNo'
          }"
          *ngIf="!cofiguration?.hideSerialNumber"
        >
          {{
            customPagination
              ? (paginationDetails.pageNumber ? paginationDetails.pageNumber - 1 : 1 - 1) * paginationDetails.pageSize +
                rowIndex +
                1
              : rowIndex + 1
          }}
        </td>
        <!-- attributes data -->
        <td
          *ngFor="let col of columns; let index = index"
          [ngClass]="{
            hide: col?.form?.value?.isHidden === true,
            'freeze-element': (col.displayValue ? col.displayValue : col.header) == freezeColumnName
          }"
          class="readOnly-table"
        >
          <div class="applabel-0 splitwrap" [ngClass]="splitScreenclicked ? 'split-view' : ''">
            <div
              [ngClass]="{
                'attribte-opacity': rowData[col.header]?.isDisabled
                  ? rowData[col.header]?.isDisabled
                  : rowData[col.header]?.isNegative
                  ? rowData[col.header]?.isNegative
                  : rowData[col.header]?.isInfo && rowData[col.header]?.value != ''
              }"
              class="splitwrap-info"
            >
              <!-- <ng-container
                *ngIf="
                  rowData[col.header]?.inputType !== 'label' ||
                  (rowData[col.header]?.inputType === 'label' && rowData[col.header]?.value)
                "
                dynamicField
                [formData]="rowData[col.header]"
                [group]="rowData[col.header + 'form']"
                [isTableConfig]="true"
                [hideLabels]="hideLabels"
                [isTableWithLabels]="true"
              >
              </ng-container> -->
              <span>{{ rowData[col.header]?.value }}</span>
              <!-- <div *ngIf="rowData[col.header].inputType === 'image' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="1" (click)="openpopup(rowData, rowData[col.header].value)"
                  >popup</mat-radio-button
                >
                <mat-radio-button value="2" (click)="newtab(rowData, rowData[col.header].value)"
                  >newtab</mat-radio-button
                >
                <mat-radio-button
                  value="3"
                  (click)="splitScreen(rowData[col.header].inputType, rowData[col.header].value)"
                  >splits Screen</mat-radio-button
                >
              </div> -->
              <div
                *ngIf="
                  (rowData[col.header].inputType === 'video' && viewOptionsInTable) ||
                  (rowData[col.header].inputType === 'file' && viewOptionsInTable)
                "
                class="split-radio"
              >
                <mat-radio-button value="2" (click)="newtab(rowData, rowData[col.header].value)"
                  >newtab</mat-radio-button
                >
                <mat-radio-button
                  value="3"
                  (click)="splitScreen(rowData[col.header].inputType, rowData[col.header].value)"
                  >splits Screen</mat-radio-button
                >
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="customPagination" [pSelectableRow]="rowData">
        <!-- tablerow selection and deletion -->
        <td
          class="w-10"
          *ngIf="!cofiguration?.hideSelectButton"
          [ngClass]="{ 'freeze-element': freezeColumnName == 'selectionColumn' }"
        >
          <div class="checkbox-flex">
            <p-tableRadioButton
              *ngIf="tableSelection === 'single'"
              [disabled]="isInfo"
              (click)="selectedTransactions($event, rowIndex)"
              [value]="rowData"
            ></p-tableRadioButton>
            <p-tableCheckbox
              *ngIf="tableSelection !== 'single' && !cofiguration?.hideSelectButton"
              class="checkbox"
              [disabled]="isInfo"
              (click)="selectedTransactions($event, rowIndex)"
              [value]="rowData"
            ></p-tableCheckbox>
            <td *ngIf="cofiguration?.deleteRecord" class="w-10">
              <span class="span-minus" *ngIf="listValues.length > 1" (click)="deleteEntityControl(rowIndex, rowData)">
                <mat-icon>delete</mat-icon>
              </span>
            </td>
          </div>
        </td>
        <!-- serial no -->
        <td
          [ngClass]="{
            'freeze-element': freezeColumnName == 'SrNo'
          }"
          *ngIf="!cofiguration?.hideSerialNumber"
        >
          {{
            customPagination
              ? (paginationDetails.pageNumber ? paginationDetails.pageNumber - 1 : 1 - 1) * paginationDetails.pageSize +
                rowIndex +
                1
              : rowIndex + 1
          }}
        </td>
        <!-- attributes data -->
        <td
          *ngFor="
            let col of columns
              | paginate
                : {
                    itemsPerPage: paginationDetails.pageSize,
                    currentPage: paginationDetails.pageNumber,
                    totalItems: paginationDetails.totalNumberOfRecords
                  };
            let index = index
          "
          [ngClass]="{
            hide: col?.form?.value?.isHidden === true,
            'freeze-element': (col.displayValue ? col.displayValue : col.header) == freezeColumnName
          }"
          class="readOnly-table"
        >
          <div class="applabel-0">
            <div
              [ngClass]="{
                'attribte-opacity': rowData[col.header]?.isDisabled
                  ? rowData[col.header]?.isDisabled
                  : rowData[col.header]?.isNegative
                  ? rowData[col.header]?.isNegative
                  : rowData[col.header]?.isInfo && rowData[col.header]?.value != ''
              }"
            >
              <!-- <ng-container
                dynamicField
                [formData]="rowData[col.header]"
                [group]="rowData[col.header + 'form']"
                [isTableConfig]="true"
                [hideLabels]="hideLabels"
                [isTableWithLabels]="true"
              >
              </ng-container> -->
              <span>{{ rowData[col.header]?.value }}</span>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <!-- table footer -->
    <ng-template pTemplate="summary">
      {{ tableDescription?.footerDescription }}
    </ng-template>
  </p-table>

  <p-table [hidden]="true" #ptcsv [columns]="csvColumns" [value]="csvData">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" id="col.header" [ngClass]="{ hide: col?.form?.value?.isHidden === true }">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td *ngFor="let col of columns" [ngClass]="{ hide: col?.form?.value?.isHidden === true }">
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="content-footer" *ngIf="customPagination && !isInfo">
    <div class="row align-items-center">
      <div class="col-auto">
        <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
