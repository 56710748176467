import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AcronymPipe } from './acronym.pipe';
import { FilterPipes } from './appFilter.pipe';
import { AttSymbolPipe } from './attsymbol.pipe';
import { CdnUrlRewritePipe } from './cdnUrlRewrite.pipe';
import { CustomdatePipe } from './customdate.pipe';
import { DatetimePipe } from './datetime.pipe';
import { DcdLabelPipe } from './dcd-label.pipe';
import { DcdPipe } from './dcd.pipe';
import { DLDAttributePipe } from './dld-attribute.pipe';
import { DLDChangeUnitPipe } from './dld-changeunit.pipe';
import { DLDEntityPipe } from './dld-entity.pipe';
import { FilterPipe } from './filter.pipe';
import { FormatTimePipe } from './format-time.pipe';
import { SafeResourceUrlPipe } from './safeurl.pipe';
import { UseHttpImageSourcePipe } from './securesrc.pipe';
import { SplitPipe } from './split.pipe';
import { StrReplacePipe } from './strReplace.pipe';
import { ExtractionPipe } from './extractedGsi.pipe';
import { NumberSuffixPipe } from './number-suffix.pipe';
import { OperatorsPipe } from './operators.pipe';
import { BetImageFileNamePipe } from './bet-image-file-name.pipe';
import { LanguageOperatorsPipe } from './language-operator.pipe';
import { ProgressBarClass } from './progress-bar-class.pipe';
import { FilterCUData } from './filter-cudata.pipe';
import { PaginatePipe } from './paginate.pipe';
import { MemberShipCriteriaOperatorsFilterPipe } from './member-ship-criteria-operators-filter.pipe';
import { DcdSourcePipe } from './dcd-source.pipe';
import { ConditionDisplayPipe } from './condition-display.pipe';
import { EventsPipe } from './events.pipe';
import { PhoneFormatPipe } from './phoneFormat.pipe';
export const customPipes = [
  DcdPipe,
  DcdLabelPipe,
  DatetimePipe,
  SplitPipe,
  FilterPipe,
  StrReplacePipe,
  CustomdatePipe,
  SafeResourceUrlPipe,
  DLDChangeUnitPipe,
  DLDEntityPipe,
  DLDAttributePipe,
  FilterPipes,
  UseHttpImageSourcePipe,
  CdnUrlRewritePipe,
  AcronymPipe,
  FormatTimePipe,
  ExtractionPipe,
  AttSymbolPipe,
  NumberSuffixPipe,
  OperatorsPipe,
  BetImageFileNamePipe,
  LanguageOperatorsPipe,
  ProgressBarClass,
  FilterCUData,
  PaginatePipe,
  MemberShipCriteriaOperatorsFilterPipe,
  DcdSourcePipe,
  ConditionDisplayPipe,
  EventsPipe,
  PhoneFormatPipe
];

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [...customPipes],
  exports: [...customPipes],
  providers: [DatePipe],
})
export class ApplicationPipesModule {}
