import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { pluck, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { getEnvClientId } from '../../generic-folder/generic-functions';
@Injectable({
  providedIn: 'root',
})
export class NotificationEndpointService extends CduiBaseService implements OnDestroy {
  tenantId = getEnvClientId();
  fcmKey = 'FCM_Token';
  // currentMessage = new BehaviorSubject(null);
  unsubscribe$: any = new Subject();
  constructor(
    //private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private injector: Injector
  ) {
    super(injector);
    /* istanbul ignore next  */
    // this.angularFireMessaging.messages?.pipe(takeUntil(this.unsubscribe$)).subscribe((_messaging: any) => {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    // });
  }
  //  requestPermission() {
  //     this.angularFireMessaging.requestToken.pipe(takeUntil(this.unsubscribe$)).subscribe(
  //       (token: any) => {
  //         let _checkFCMTokenIsRegisteredOrNot = getLocalStorageValue('FCM_Token');
  //         /* istanbul ignore next */
  //       //   if (_checkFCMTokenIsRegisteredOrNot === '') {
  //       //     localStorage.setItem(this.fcmKey, token);
  //       //     this.saveFCMToken(token);
  //       //   }
  //       // },
  //       /* istanbul ignore next  */
  //       (err: any) => {
  //         console.error('Unable to get permission to notify.', err);
  //       }
  //     );
  //   }
  // receiveMessage() {
  //   /* istanbul ignore next  */
  //   this.angularFireMessaging.messages.pipe(takeUntil(this.unsubscribe$)).subscribe((payload: any) => {
  //
  //   });
  // }

  // saveFCMToken(token: any) {
  //   this.http
  //     .post(this.api.saveFCMToken, { deviceToken: token })
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe((res: any) => {});
  // }

  // deRegisterToken() {
  //   const FCMToken = getLocalStorageValue(this.fcmKey);
  //   /* istanbul ignore next  */
  //   if (FCMToken) {
  //     this.angularFireMessaging
  //       .deleteToken(FCMToken)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((res: any) => {
  //
  //       });
  //   }
  // }

  // deleteFCMToken() {
  //   const FCMToken = getLocalStorageValue(this.fcmKey);
  //   const Authtoken: any = getLocalStorageValue('credentials');
  //   const { token } = Authtoken && JSON.parse(Authtoken);
  //   const data = { deviceToken: FCMToken, authToken: token };
  //   /* istanbul ignore else */
  //   if (FCMToken) {
  //     const httpOptions = {
  //       headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  //       body: data,
  //     };
  //     this.http
  //       .delete(this.api.deleteFCMToken, httpOptions)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((res: any) => {
  //         removeLocalStorageValue(this.fcmKey);
  //       });
  //   }
  // }

  getAllNotifications(paginationData: any) {
    return this.http
      .get(`${this.api.getAllNotifications}page=${paginationData.pageIndex}&size=${paginationData.pageSize}`)
      .pipe(pluck('result'));
  }

  changeOfNotificationStatus(notificationData: any) {
    return this.http.put(
      `${this.api.changeOfNotificationStatus}${notificationData.id}/status/${notificationData.status}`,
      {}
    );
  }

  markAllNotificationsAsRead() {
    return this.http.put(`${this.api.markAllNotificationsAsRead}`, {});
  }

  searchNotification(page: any, size: any, keyword: any) {
    return this.http.get(`${this.api.searchNotofications}?&page=${page}&searchCriteria=${keyword}&size=${size}`);
  }

  getUnreadNotificationCount() {
    return this.http.get(`${this.api.getUnreadNotificationCount}`);
  }
  getCalendarNotifications(paginationData: any) {
    let data = {
      notificationType: 'NSL_Calendar',
    };
    return this.http
      .post(
        `${this.api.getCalendarNotifications}page=${paginationData.pageIndex}&size=${paginationData.pageSize}`,
        data
      )
      .pipe(pluck('result'));
  }
  updateAcceptanceStatus(userEmail: string, data:any, status: string) {
    return this.http.put(`${this.api.updateAcceptanceStatus}?encodedData=${data}&button=${status}`,{});
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
