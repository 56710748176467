import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  MatDialog, MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ColorPickerModule } from 'ngx-color-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AddLanguageTemplateComponent } from './add-language-template/add-language-template.component';
import { AdvancesearchFilterModule } from './advancesearch-filter/advancesearch-filter.module';
import { BreadCrumbModule } from './bread-crumb/bread-crumb.module';
import { CanvasjsDisplayComponent } from './canvasjs-display/canvasjs-display.component';
import { CardViewControlModule } from './card-view/card-view-control.module';
import { CduiBaseComponent } from './cdui-base/cdui-base.component';
import { ChangeUnitEventComponent } from './change-unit-event/change-unit-event.component';
import { CommonServicesComponent } from './common-services.component';
import { DebugConfirmationComponent } from './debug-mode-confimation/debug-mode-confimation.component';
import { DicomIntegrationComponent } from './dicom-integration/dicom-integration.component';
import { EntityPrimaryKeyComponent } from './entity-primary-key/entity-primary-key.component';
import { ExtractionModule } from './extraction/extraction.module';
import { FileUploadPreviewComponent } from './file-upload-preview/file-upload-preview.component';
import { DragNDropDirective } from './file-upload/drag-drop.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CustomFusionChartsModule } from './fusion-charts/fusion-charts.module';
import { HideRecordAdditionalInfoComponent } from './hide-record-additional-info/hide-record-additional-info.component';
import { ImageMapComponent } from './image-map/image-map.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { OtherSolutionDialogComponent } from './library/other-solution-dialog/other-solution-dialog.component';
import { LoaderModule } from './loader/loader.module';
import { MaterialModule } from './materail/material.module';
import { AutoIdNestedEntityComponent } from './nested-entity/nested-entity.component';
import { NslDicomOperationComponent } from './nsl-dicom-operation/nsl-dicom-operation.component';
import { OCRModalComponent } from './ocr-modal/ocr-modal.component';
import { ClickOutsideDirective } from './outside-click.directive';
import { OutsideClickDirective } from './outsideclick.directive';
import { PaginatorModule } from './paginator/paginator.module';
import { ApplicationPipesModule } from './pipes/application-pipes.module';
import { SharedErrorHandlerModule } from './shared-error-handler/shared-error-handler.module';
import { AlertService } from './toaster/alert.service';
import { ToasterModule } from './ToasterService/toaster.module';
import { LibraryChangeUnitLayersComponent } from './library-change-unit-layers/library-change-unit-layers.component';
import { EntityDcdComponent } from './entity-dcd/entity-dcd.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';





@NgModule({
  declarations: [
    CommonServicesComponent,
    CduiBaseComponent,
    AutoIdNestedEntityComponent,
    FileUploadComponent,
    DragNDropDirective,
    FileUploadPreviewComponent,
    DicomIntegrationComponent,
    NslDicomOperationComponent,
    OutsideClickDirective,
    ClickOutsideDirective,
    LayoutsComponent,
    OCRModalComponent,
    ImageMapComponent,
    DebugConfirmationComponent,
    OtherSolutionDialogComponent,
    AddLanguageTemplateComponent,
    EntityPrimaryKeyComponent,
    HideRecordAdditionalInfoComponent,
    ChangeUnitEventComponent,
    CanvasjsDisplayComponent,
    LibraryChangeUnitLayersComponent,
    EntityDcdComponent,
  ],

  imports: [
    ToastrModule.forRoot(),
    PaginatorModule,
    MaterialModule,
    BreadCrumbModule,
    ExtractionModule,
    CardViewControlModule,
    InfiniteScrollModule,
    ColorPickerModule,
    HttpClientModule,
    SharedErrorHandlerModule,
    ApplicationPipesModule,
    MatFormFieldModule,
    MatInputModule,
    PdfViewerModule,
    AdvancesearchFilterModule,
    CustomFusionChartsModule,
    ToasterModule,
    LoaderModule,
    KeycloakAngularModule
  ],
  exports: [
    CommonServicesComponent,
    CduiBaseComponent,
    ApplicationPipesModule,
    PaginatorModule,
    AdvancesearchFilterModule,
    AutoIdNestedEntityComponent,
    FileUploadComponent,
    DragNDropDirective,
    FileUploadPreviewComponent,
    DicomIntegrationComponent,
    NslDicomOperationComponent,
    BreadCrumbModule,
    CardViewControlModule,
    LayoutsComponent,
    ExtractionModule,
    OutsideClickDirective,
    ClickOutsideDirective,
    OCRModalComponent,
    ImageMapComponent,
    DebugConfirmationComponent,
    SharedErrorHandlerModule,
    OtherSolutionDialogComponent,
    AddLanguageTemplateComponent,
    EntityPrimaryKeyComponent,
    HideRecordAdditionalInfoComponent,
    ChangeUnitEventComponent,
    CanvasjsDisplayComponent,
    CustomFusionChartsModule,
    ToasterModule,
    LoaderModule,
    LibraryChangeUnitLayersComponent,
    EntityDcdComponent,
  ],
  providers: [
    MatDialog,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    AlertService,
    ToastrService,
    KeycloakService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonServicesModule {}
