<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeAttribute()">
        close
      </button>
      <label class="status">Status: Draft (Ver 1.1 )</label>
    </div>
    <div class="heading-area">
      <h2>{{ labels?.ATTRIBUTE }}</h2>
      <button class="save" (click)="saveEntitywithAttr()">{{labels?.Save}}</button>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Attribute_Details }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'file'"
            class="file_configurations"
          >
            <div class="show-as-label">
              <p>{{ labels?.Hide_preview }}</p>
              <mat-slide-toggle
                id="nodeattrEdit_hidePreview"
                [(ngModel)]="hidePreview"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
            <div class="show-as-label">
              <p>{{ labels?.Hide_open_in_new_Tab }}</p>
              <mat-slide-toggle
                id="nodeattrEdit_hideOpenInNewTab"
                [(ngModel)]="hideOpenInNewTab"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
            <div class="show-as-label">
              <p>{{ labels?.Hide_Deletion }}</p>
              <mat-slide-toggle
                id="nodeattrEdit_hideDeletion"
                [(ngModel)]="hideDeletion"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
          </div>
          <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation'">
            <label> {{ labels?.Destination }} : </label>
            <input
              id="nodeattrEdit_mmiDestinationLat"
              type="number"
              matInput
              placeholder="Latitude"
              [(ngModel)]="mmiDestinationLat"
            />
            <input
              id="nodeattrEdit_mmiDestinationLon"
              type="number"
              matInput
              placeholder="Longitude"
              [(ngModel)]="mmiDestinationLon"
            />
            <button
              *ngIf="!mmiDestination"
              class="validate"
              id="nodeattrEdit_MMIDestinationValue"
              (click)="addMMIDestination()"
            >
              {{ labels?.Add }}
            </button>
            <div class="mmi-destination" *ngIf="mmiDestination">
              <p>{{ mmiDestination }}</p>
              <div class="edit-deleticons">
                <span class="pointer"
                  ><img
                    (click)="removeMMIDestination()"
                    id="mmi_defaultvalueremoveimg"
                    [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                    alt=""
                /></span>
                <span>
                  <img
                    (click)="editMMIDestination()"
                    class="edit-icon"
                    id="mmi_editiconimg"
                    [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe"
                    alt="Edit"
                  />
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="
                      configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'image' &&
                      configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'file' &&
                      configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'audio' &&
                      configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'video' &&
                      configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'label'
                    " class="show-as-label">
            <p>
              {{ labels?.Show_as_Label }}
            </p>
            <mat-slide-toggle id="slide_toggleBtn_isReadOnly" [(ngModel)]="isReadOnly" value="true"></mat-slide-toggle>
          </div>
          <div
            *ngIf="
              attrDetails?.value?.attributeUiElement === 'dropdown' ||
              attrDetails?.value?.attributeUiElement === 'multiselectdropdown'
            "
            class="show-as-label"
          >
            <p>
              {{ labels?.Hide_Search_Bar }}
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_hideSearchBar"
              [(ngModel)]="hideSearchBar"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div *ngIf="attrDetails?.value?.attributeUiElement === 'hyperlink'" class="show-as-label">
            <p>
              {{ labels?.Show_Copy_Icon }}
            </p>
            <mat-slide-toggle id="slide_toggleBtn_copyLink" [(ngModel)]="copyLink" value="true"></mat-slide-toggle>
          </div>
          <div class="rename-input-wrap" *ngIf="attrDetails?.value?.attributeUiElement === 'hyperlink'">
            <mat-form-field>
              <input
                matInput
                [placeholder]="labels?.Display_Label"
                type="text"
                autocomplete="off"
                [(ngModel)]="displayText"
                id="display"
              />
            </mat-form-field>
            <div class="show-as-label">
              <p>
                {{ labels?.Attribute_Mapping ? labels?.Attribute_Mapping : 'Attribute Mapping' }}
              </p>
              <mat-slide-toggle
                id="Attribute_Mapping"
                [(ngModel)]="attributeMapping"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              >
              </mat-slide-toggle>
            </div>
            <div *ngIf="attributeMapping">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  [placeholder]="labels?.Attribute_Path"
                  autocomplete="off"
                  [(ngModel)]="attrPath"
                  id="attrPath"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'radioButton'"
            class="show-as-label"
          >
            <p>
              {{ labels?.Show_Horizontal_Options }}
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_showHorizontalOptions"
              [(ngModel)]="showHorizontalOptions"
              value="true"
            ></mat-slide-toggle>
          </div>
          <div
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'percentage'"
            class="percentageDefaultType"
          >
            <div class="d-flex justify-content-between align-items-center">
              <mat-label class="label-txt"> {{ labels?.Set_default_type }} </mat-label>
              <mat-radio-group [(ngModel)]="percentageType" [ngModelOptions]="{ standalone: true }">
                <mat-radio-button
                  name="defaultPercentageType"
                  id="nodeAttrEdit_defaultPercentageType"
                  value="percentage"
                >
                  Percentage</mat-radio-button
                >
                <mat-radio-button name="defaultDecimalType" id="nodeAttrEdit_defaultDecimalType" value="decimal">
                  Decimal</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </div>
          <div
            class="show-as-label"
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipsingleselection' ||
                   configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipmultipleselection' ||
                   configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'textarea' ||
                   configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'label' ||
                   configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'multiselectdropdown'
                  "
          >
            <p>
              {{ labels?.Hide_Tooltip }}
            </p>
            <mat-slide-toggle
              id="slide_toggleBtn_hideTooltip"
              [(ngModel)]="hideTooltip"
              value="true"
            ></mat-slide-toggle>
          </div>
          <mat-form-field class="mb-4 mr-3 w-100">
            <mat-label>{{ labels?.Change_Driver_Type }}</mat-label>
            <mat-select  multiple [(ngModel)]="selectedChangedriverOptAttr">
              <mat-option
                *ngFor="let opt of optChangeDrivers; let i = index"
                [value]="opt"
                (click)="onChangeOptionAttr(opt, i)"
              >
                {{ opt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mb-4 mr-3 w-100" *ngIf="selectedChangedriverOptAttr?.includes('ALTERNATIVE')">
            <mat-label>{{ labels?.Alternative_Attributes }}</mat-label>
            <mat-select [(ngModel)]="SelectedalternativeAttr">
              <mat-option
                *ngFor="let attr of altAttr; let i = index"
                [value]="attr.name"
                (click)="onChangeOptionAttr(attr.name, i)"
              >
                {{ attr?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'mobilenumber'"
            class="mb-4 mr-3 w-100"
          >
            <mat-label> {{ labels?.Mobile_Number_Format }} </mat-label>
            <mat-select [(ngModel)]="selectedMobileNumberFormat.displayValue">
              <mat-option
                *ngFor="let opt of mobileNumberFormat; let i = index"
                [value]="opt.displayValue"
                (click)="selectedMobileNumberFormat = opt"
              >
                {{ opt.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="configurationData?.data?.attr?.name">
            <mat-label>{{ labels?.Attribute_Name }}</mat-label>
            <input matInput placeholder="Attribute name" [(ngModel)]="configurationData.data.attr.name" readonly />
          </mat-form-field>

          <mat-form-field *ngIf="configurationData?.data?.attr?.displayName">
            <mat-label>{{ labels?.Display_name }}</mat-label>
            <input
              matInput
              placeholder="Attribute name"
              [(ngModel)]="configurationData.data.attr.displayName"
              readonly
            />
          </mat-form-field>
          <div
            class="show-as-label nh-mb-20"
            *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'date'"
          >
            <p>Show as Tabs</p>
            <mat-slide-toggle id="slide_toggleBtn_tabs" [(ngModel)]="showDataAsTab" value="true"></mat-slide-toggle>
          </div>
          <div *ngIf="showDataAsTab" class="nh-mb-20">
            <div class="d-flex justify-content-between align-items-center">
              <p>Max.No.of Tabs</p>
              <div class="input-group justify-content-between border nh-radius-6 nh-border-80 mb-3 nh-w-100">
                <div class="input-group-prepend">
                  <button
                    [disabled]="dateTabConfig.maxNumberOfTabs < 2"
                    class="btn btn-outline-secondary bi bi-dash nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'minus', 'maxNumberOfTabs')"
                  ></button>
                </div>
                <input
                  type="tel"
                  [(ngModel)]="dateTabConfig.maxNumberOfTabs"
                  class="form-control nh-border-0"
                  placeholder=""
                  min="1"
                  value="true"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary bi bi-plus nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'plus', 'maxNumberOfTabs')"
                  ></button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column nh-mb-16">
              <label class="nh-mb-4">Select Design Style</label>
              <p-dropdown
                optionLabel="displayValue"
                optionValue="actualValue"
                [(ngModel)]="dateTabConfig.style"
                [options]="designStyleList"
                class="prime-ng-dropdown"
                id="designStyle"
                name="designStyle"
                appendTo="body"
              ></p-dropdown>
            </div>
            <div class="nh-mb-16">
              <mat-checkbox class="checkbox-sm" id="hideBlanks" value="true" [(ngModel)]="dateTabConfig.hideBlanks"
                >Hide Blanks</mat-checkbox
              >
            </div>
            <div>
              <mat-checkbox class="checkbox-sm" id="showPreDate" value="true" [(ngModel)]="dateTabConfig.showPredates"
                >Show Pre-date</mat-checkbox
              >
            </div>
            <div class="d-flex justify-content-between align-items-center" *ngIf="dateTabConfig.showPredates">
              <p>Max.No.of Pre-date Tabs</p>
              <div class="input-group justify-content-between border nh-radius-6 nh-border-80 mb-3 nh-w-100">
                <div class="input-group-prepend">
                  <button
                    [disabled]="dateTabConfig.maxNumberOfPreDates < 1"
                    class="btn btn-outline-secondary bi bi-dash nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'minus', 'maxNumberOfPreDates')"
                  ></button>
                </div>
                <input
                  type="tel"
                  [(ngModel)]="dateTabConfig.maxNumberOfPreDates"
                  class="form-control nh-border-0"
                  placeholder=""
                  min="0"
                  value="true"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary bi bi-plus nh-border-0"
                    type="button"
                    (click)="triggerOnchange($event, 'plus', 'maxNumberOfPreDates')"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button value="1">Information</mat-radio-button>
              <mat-radio-button value="2">Physical</mat-radio-button>
            </mat-radio-group>
          </div> -->
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Constant Values
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="attrDetails">
            <!-- Not for scheduler and location UI controls -->
            <ng-container
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'scheduler' &&
                (configurationData?.data?.attr?.attributeType?.uiElement?.dataType != 'location' || configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation')
              "
            >
              <!-- <div
                *ngIf="
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'checkbox' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'dropdown' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'radioButton' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'typeahead' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'list' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'multiselectdropdown' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipmultipleselection' ||
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'chipsingleselection'
                "
              >
                <input
                  matInput
                  class="add-value w-100"
                  placeholder="Add Option"
                  id="inputData"
                  type="text"
                  autocomplete="off"
                  formControlName="defaultOption"
                />
                <button
                  class="validate"
                  id="addInputData"
                  *ngIf="!attrDetails.value?.constantChangeDriver"
                  (click)="addDefaultValuetoAttibute()"
                >
                  {{ labels.Add }}
                </button>
                <div *ngIf="sourceValues">
                  <div class="d-flex added" *ngFor="let attrvalue of sourceValues; let i = index">
                    {{ attrvalue.DATA?.displayValue }}
                    <span class="pointer"
                      ><img
                        (click)="removeScourceValue(i)"
                        id="attrremoveimg"
                        class="ml-2"
                        [src]="'assets/img/nsl-gram/icon-delete.svg' | CDNUrlRewritePipe"
                        alt=""
                    /></span>
                  </div>
                </div>
              </div> -->
              <div *ngIf="!attrDetails.value?.constantChangeDriver">
                <ng-container
                  [ngTemplateOutlet]="defaultValueBlock"
                  [ngTemplateOutletContext]="{
                    attrDetails: configurationData?.data?.attr
                  }"
                >
                </ng-container>
              </div>
              <!-- For Constant Change Driver -->
              <div class="multi-value mb-4">
                <mat-label class="label-txt mr-2">
                  {{labels?.Constant_Change_Driver}}
                </mat-label>
                <mat-radio-group
                  fxLayout="column"
                  formControlName="constantChangeDriver"
                  [(ngModel)]="attrDetails.value.constantChangeDriver"
                >
                  <mat-radio-button name="isconstantChangeDriver" id="constantChangeDriverYes" [value]="true">
                    {{ labels?.Yes }}
                  </mat-radio-button>
                  <mat-radio-button name="isconstantChangeDriver" id="constantChangeDriverNo" [value]="false">
                    {{ labels?.No }}
                  </mat-radio-button>
                </mat-radio-group>
                <input
                  *ngIf="attrDetails.value.constantChangeDriver"
                  matInput
                  placeholder="Enter Constant Change Driver Value"
                  name="constantChangeDriverValue"
                  type="text"
                  name="constantChangeDriverValue"
                  autocomplete="off"
                  [(ngModel)]="attrDetails.value.constantChangeDriverValue"
                  formControlName="constantChangeDriverValue"
                />
              </div>
              <div *ngIf="attrDetails?.value?.attributeUiElement == 'html'">
                <mat-slide-toggle
                  id="slide_toggleBtn_newTab"
                  [(ngModel)]="showPopup"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="htmlToggle(showPopup)"
                  >{{ labels?.show_in_transaction }}</mat-slide-toggle
                >
              </div>
              <div class="multi-file-upload mb-4">
                <div class="multi-value">
                  <mat-label class="label-txt mr-2">{{labels?.Multi_Value}}</mat-label>
                  <mat-radio-group fxLayout="column" formControlName="isMultiValue">
                    <mat-radio-button
                      [checked]="multiDefaultValue"
                      name="isMultiValue"
                      id="attribute_multiYes"
                      [value]="true"
                      [disabled]="true"
                    >
                      Yes
                    </mat-radio-button>
                    <mat-radio-button
                      [checked]="!multiDefaultValue"
                      name="isMultiValue"
                      id="attribute_multiNo"
                      [value]="false"
                      [disabled]="true"
                    >
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <!-- <div
                  *ngIf="
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'file' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'image' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'audio' ||
                    configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'video'
                  "
                >
                  {{ attrDetails.value?.attributeType }}
                  <app-file-upload
                    [fileUploadFromEntity]="true"
                    [editFileUrl]="editFileUrl"
                    (fileUploadResponse)="fileUploadUrl($event)"
                    (deletedFileResponse)="deletedFileResponse($event)"
                    [isMulti]="multiDefaultValue"
                    [fileType]="configurationData.data.attr?.attributeType?.uiElement?.uiElement"
                  >
                  </app-file-upload>
                </div> -->
              </div>
              <div
              class="multi-file-upload mb-4"
              *ngIf="multiDefaultValue && configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'file'"
            >
              <div class="multi-value">
                <mat-label class="label-txt mr-2">Multi File Download</mat-label>
                <mat-radio-group fxLayout="column" formControlName="multidownload" [(ngModel)]="isMultiFileDownload">
                  <mat-radio-button id="attribute_multiDownloadYes" [value]="true">
                    {{ labels?.Yes }}
                  </mat-radio-button>
                  <mat-radio-button id="attribute_multiDownloadNo" [value]="false">
                    {{ labels?.No }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

              <!--for translation-->
              <div class="multi-value translation-type">
                <mat-form-field appearance="fill">
                  <mat-label class="label-txt">{{labels?.Translation_Type}} </mat-label>
                  <mat-select [(ngModel)]="translationType" [ngModelOptions]="{ standalone: true }" name="food">
                    <mat-option
                      *ngFor="let type of translationTypeArray"
                      [value]="type"
                      id="attribute_translationType_{{ type }}"
                      (click)="ChangeTranslationType(type)"
                    >
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!configurationData.data.entity?.isReserved">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.UI_Controls }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="no-label w-100">
            <mat-label>{{ labels?.Select_UI_Control }}</mat-label>
            <!-- <mat-form-field appearance="outline">
              <mat-select value="date">
                <mat-option value="date">Date</mat-option>
                <mat-option value="Table">Table </mat-option>
                <mat-option value="List">List </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field
              disableOptionCentering
              appearance="outline"
              class="mat-select-custom"
              *ngIf="configurationData?.data?.attr?.attributeType?.uiElement"
            >
              <mat-select
                class="uic-select"
                panelClass="attribute-icons search-control select-with-search"
                (selectionChange)="selectAttrType($event, configurationData.data.attr)"
                [(ngModel)]="configurationData.data.attr.attributeType.uiElement.uiElement"
                placeholder="Select Attribute Type"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-form-field class="d-block serch-uicontrol" appearance="outline">
                  <input
                    matInput
                    placeholder="Search"
                    [(ngModel)]="searchText"
                    (keyup)="getAttributeTypes(configurationData?.data?.attr?.attributeType?.uiElement?.dataType)"
                    (keydown)="$event.stopPropagation()"
                  />
                </mat-form-field>
                <mat-option [value]="attrType.uiElement" *ngFor="let attrType of attributeTypes"
                  >{{ attrType?.displayName ? attrType?.displayName : attrType?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'hyperlink' ||
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'html'
              "
            >
              <mat-checkbox
                class="example-margin"
                id="urlClicked"
                [(ngModel)]="configurationData.data.attr.configuration.autoRedirect"
                value="true"
                >open link directly on cu trigger</mat-checkbox
              >
            </div>

            <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'label'">
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="strikeThrough"
                (change)="strikeThroughConfig($event)"
                >strikethrough</mat-checkbox
              >
            </div>
            <div
              *ngIf="
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'chipmultipleselection' ||
                configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'chipsingleselection'
              "
            >
              <p>{{ labels?.chip_with_image }} {{ chipImageUrl?.length }}/{{ sourceValues?.length }}</p>
              <div class="independent-attr-upload-wrapper">
                <div class="multi-upload">
                  <p>{{ labels?.upload }}</p>
                  <button
                    class="upload-add"
                    id="multipleImageUpload"
                    (click)="uploadMultipleFiles($event)"
                    [disabled]="disableAddButton"
                  >
                    {{ labels?.add }}
                  </button>
                </div>

                <app-file-upload
                  class="file-upload"
                  [fileUploadFromEntity]="true"
                  [editFileUrl]="editFileUrl"
                  (fileUploadResponse)="chipImageUpload($event)"
                  (deletedFileResponse)="deletedFileResponse($event)"
                  [isMulti]="true"
                  [isDesignTime]="addButtonClicked"
                  [fileType]="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement"
                >
                </app-file-upload>
              </div>
            </div>
              <!-- To hide location search input at transaction time -->
              <div
              *ngIf="configurationData?.data?.attr?.attributeType?.uiElement=='location'"
              class="isReadOnly"
            >
              <p>
                {{ labels?.Hide_Search_Location }}
              </p>
              <mat-slide-toggle
                id="slide_toggleBtn_hideSearchLocation"
                [(ngModel)]="hideSearchLocation"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              ></mat-slide-toggle>
            </div>
          </div>
          <div
            class="time_limit_picker"
            *ngIf="
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'videoRecording' ||
              configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'audioRecording'
            "
          >
            <span class="time_limit_span">Time Limit</span>
            <div class="time_limit_inputs">
              <span>Hours:</span>
              <input
                class="time_limit_Hours"
                matInput
                placeholder="Hours"
                id="Hours_picker"
                [(ngModel)]="timeLimit.hours"
                type="number"
                autocomplete="off"
                (keyup)="setLimit($event, 'hours')"
                [ngModelOptions]="{ standalone: true }"
              />
              <span>Minutes:</span>
              <input
                matInput
                class="time_limit_Minutes"
                placeholder="Minutes"
                id="Minutes_picker"
                [(ngModel)]="timeLimit.minutes"
                type="number"
                autocomplete="off"
                (keyup)="setLimit($event, 'minutes')"
                [ngModelOptions]="{ standalone: true }"
              />
              <span>Seconds:</span>
              <input
                matInput
                class="time_limit_Seconds"
                placeholder="Seconds"
                id="Seconds_picker"
                [(ngModel)]="timeLimit.seconds"
                type="number"
                autocomplete="off"
                (keyup)="setLimit($event, 'seconds')"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
          </div>
          <div *ngIf="configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'labelwithimage'">
            <app-file-upload
              [fileUploadFromEntity]="true"
              [editFileUrl]="editFileUrl"
              (fileUploadResponse)="fileUploadUrl2($event)"
              (deletedFileResponse)="deletedFileImgResponse($event)"
              [isMulti]="multiDefaultValue"
              [fileType]="'image'"
            >
            </app-file-upload>
          </div>
          <!-- <div class="ui-control-list">
            <ul>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>

              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
              <li>
                <figure>
                  <img [src]="'../../../../assets/img/nsl-gram/add-con.svg' | CDNUrlRewritePipe" />
                </figure>
                <label>
                  Audio
                </label>
              </li>
            </ul>
          </div> -->
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="!configurationData.data.entity?.isReserved">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="Validators">
            <mat-accordion>
              <mat-expansion-panel
                *ngIf="
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormVideo' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'payment' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'currentdatetime' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'audio' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'currentdate' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'encrypttext' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormProgress' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'signature' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'password' &&
                  configurationData?.data?.attr?.attributeType?.uiElement?.uiElement != 'scormProgress'
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{labels?.Validation}}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- <div class="form-group">
                  <label>Select to Display</label>
                  <div class="d-flex">
                    <mat-checkbox>Mandatory</mat-checkbox>
                  </div>
                </div> -->
                <div class="level-dropdown no-label">
                  <mat-form-field appearance="float" appearance="outline"
                  ><mat-label>{{labels?.Select_Level}}</mat-label>
                  <mat-select>
                      <mat-option
                        *ngFor="let level of levelList"
                        [value]="level"
                        (click)="CompareProperties(level, 'ATTRIBUTE_CONSTRAINTS')"
                      >
                        {{ level }}
                      </mat-option>
                    </mat-select></mat-form-field
                  >
                </div>
                <div class="compare">
                  <button class="compareButton" (click)="compareValuePopup('ATTRIBUTE CONSTRAINTS')">
                    Compare Validators
                  </button>
                </div>
                <div class="no-label w-75 BasicCuLevel" *ngIf="betLevel">
                  <mat-form-field appearance="outline">
                    <mat-label>{{labels?.Select_Validators}}</mat-label>
                    <mat-select class="uic-select" id="validatorsSelectDropdown" [(ngModel)]="selectedUiValidator">
                      <mat-option
                        *ngFor="let validators of UiValidatorDropdown?.constraints; let i = index"
                        [value]="validators.name"
                        [class.disabled]="validators.disabled"
                        id="validatorsSelected_{{ validators.name }}"
                        (click)="validatorsChange(validators, i)"
                      >
                        {{ validators.displayName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <ng-container *ngIf="selectedValidatorsList && selectedValidatorsList?.length">
                    <div
                      *ngFor="let selected of selectedValidatorsList; let j = index"
                      class="selected-validate expected-time"
                    >
                      <div class="labelbtn">
                        <label class="mb-2">{{ selected.displayName }}</label>
                        <button mat-stroked-button class="cstm-matbtn" (click)="removeValidator(selected, j)">
                          Remove
                        </button>
                      </div>
                      <div *ngFor="let value of selected?.properties" class="selected-validate-input">
                        <div *ngIf="value?.name && value?.name != 'type'">
                          <input
                            matInput
                            class="cstm-input-text"
                            placeholder="{{ value?.message ? value?.message : value?.name }}"
                            id="{{ value.name }}"
                            type="text"
                            [(ngModel)]="value.message"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <mat-form-field class="pt-0" id="constraint_type_{{ j }}">
                        <mat-label>{{ labels?.constraint_type }}</mat-label>
                        <mat-select [(ngModel)]="selected.selectType" (change)="typeChange(selected.selectType, j)">
                          <mat-option id="info" value="INFO">{{ labels?.Info }}</mat-option>
                          <mat-option id="blockwarn" value="NONBLOCK_WARN"> {{ labels?.Non_Block_Warn }} </mat-option>
                          <mat-option id="nonblockwarn" value="BLOCK_WARN"> {{ labels?.Block_Warn }} </mat-option>
                          <mat-option id="error" value="ERROR">{{ labels?.Error }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
                <div class="no-label w-75 EntityLevel" *ngIf="!betLevel">
                  <mat-form-field appearance="outline">
                    <mat-label>Select Validators</mat-label>
                    <mat-select class="uic-select" id="validatorsSelectDropdown" [(ngModel)]="selectedUiValidator">
                      <mat-option
                        *ngFor="let validators of lowerBetProperties?.constraints; let i = index"
                        [value]="validators.name"
                        [class.disabled]="validators.disabled"
                        id="validatorsSelected_{{ validators.name }}"
                      >
                        {{ validators.displayName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <ng-container *ngIf="lowerBetProperties?.constraints && lowerBetProperties?.constraints?.length">
                    <div
                      *ngFor="let selected of lowerBetProperties?.constraints; let j = index"
                      class="selected-validate expected-time"
                    >
                      <div class="labelbtn">
                        <label class="mb-2">{{ selected.displayName }}</label>
                      </div>
                      <div *ngFor="let value of selected?.properties" class="selected-validate-input">
                        <div *ngIf="value?.name && value?.name != 'type'">
                          <input
                            matInput
                            class="cstm-input-text"
                            placeholder="{{ value?.message ? value?.message : value?.name }}"
                            id="{{ value.name }}"
                            type="text"
                            [(ngModel)]="value.message"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <mat-form-field class="pt-0" id="constraint_type_{{ j }}">
                        <mat-label>{{ labels?.constraint_type }}</mat-label>
                        <mat-select [(ngModel)]="selected.selectType">
                          <mat-option id="info" value="INFO">{{ labels?.Info }}</mat-option>
                          <mat-option id="blockwarn" value="NONBLOCK_WARN"> {{ labels?.Non_Block_Warn }} </mat-option>
                          <mat-option id="nonblockwarn" value="BLOCK_WARN"> {{ labels?.Block_Warn }} </mat-option>
                          <mat-option id="error" value="ERROR">{{ labels?.Error }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Permissions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="assign-rights">
                <ul>
                  <li class="roles-li" *ngFor="let role of roles; let i = index">
                    <div class="actions-item">
                      <button
                        (mouseenter)="buttonEnter(createPlanmenuTrigger)"
                        (mouseleave)="buttonLeave(createPlanmenuTrigger)"
                        style="z-index: 1050;"
                        [matMenuTriggerFor]="afterMenu"
                        #createPlanmenuTrigger="matMenuTrigger"
                      >
                        {{ role?.name }}
                      </button>
                      <mat-menu #afterMenu="matMenu" xPosition="after" id="cuRoleshovered">
                        <span
                          class="menu-hover"
                          (mouseenter)="menuenter()"
                          (mouseleave)="menuLeave(createPlanmenuTrigger)"
                        >
                          <button mat-menu-item (click)="showRights(i)">
                            <figure>
                              <img [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Edit }}</span>
                          </button>
                          <button mat-menu-item>
                            <figure>
                              <img [src]="'../../../../../assets/img/info-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Information }}</span>
                          </button>
                          <button mat-menu-item (click)="removeAgent(i)">
                            <figure>
                              <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.DELETE }} </span>
                          </button>
                        </span>
                      </mat-menu>
                    </div>
                    <div *ngIf="selectedindex == i" class="read-right-actions">
                      <div>
                        <label>{{ labels?.Solution_Design }} </label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                DesignRights &&
                                DesignRights.length > 0 &&
                                DesignRights[getDesignRightsIndex(role)] &&
                                DesignRights[getDesignRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                DesignRights &&
                                DesignRights.length > 0 &&
                                DesignRights[getDesignRightsIndex(role)] &&
                                DesignRights[getDesignRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(DesignRights[getDesignRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(TxnRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].executionRight
                              "
                              (change)="autoCheckRights(TxnRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Execute }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Events }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- <div class="no-label events-config" *ngFor="let event of eventCuList; let i = index">
                <div *ngIf="configurationData.data.attr.dsdId == event.dsdChangeDriverId">
                  <div class="header-event d-flex position-relative">
                    <mat-form-field appearance="outline">
                      <mat-select class="uic-select" value="Events" [(ngModel)]="event.eventType">
                        <mat-option *ngFor="let attrEvent of attributeEvents" [value]="attrEvent.name">{{
                          attrEvent.name
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="delete event-del">
                      <img
                        (click)="deleteEvent(i)"
                        [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                        class="condition-icon"
                        alt="delete"
                      />
                    </div>
                  </div>
                  <mat-radio-group (change)="changeEventRef(i)" class="d-flex mb-3" [(ngModel)]="event.eventRef">
                    <mat-radio-button value="GSI">{{ labels?.Select_GSI }}</mat-radio-button>
                    <mat-radio-button value="EntityReference">{{ labels?.Reference_Entity }}</mat-radio-button>
                  </mat-radio-group>
                  <div class="selectedGSI mb-1" *ngIf="event?.dsdGsiId">
                    {{ labels?.Selected_GSI }} :- {{ event?.gsiName ? event?.gsiName : event?.dsdGsiId }}
                  </div>
                  <div class="refernecEntityContainer" *ngIf="event.eventRef == 'EntityReference'">
                    events : {{ entityMap.get(event?.referenceEntityInfo?.referenceEntity?.toString()) | async }} check
                    :{{ entityMap | json }}
                    <div class="cstm-selt">
                      <mat-form-field>
                        <mat-select
                          class="entity-dropdown uic-select"
                          [value]="
                            entityMap.get(event?.referenceEntityInfo?.referenceEntity?.toString())?.name
                              ? entityMap.get(event?.referenceEntityInfo?.referenceEntity)?.name
                              : 'Select Entity'
                          "
                        >
                          <mat-form-field class="search-enity">
                            <input
                              matInput
                              type="text"
                              placeholder="Search"
                              [(ngModel)]="entitySearchText"
                              (keyup)="searchEntityText.next(true)"
                              (keydown)="$event.stopPropagation()"
                            />
                            <button mat-button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear">
                              <mat-icon>close</mat-icon>
                            </button>
                            <button mat-button matSuffix mat-icon-button>
                              <mat-icon class="search-icon-cls">search</mat-icon>
                            </button>
                          </mat-form-field>
                          <mat-option
                            [value]="
                              entityMap.get(event?.referenceEntityInfo?.referenceEntity?.toString())?.name
                                ? entityMap.get(event?.referenceEntityInfo?.referenceEntity?.toString())?.name
                                : 'Select Entity'
                            "
                          >
                            {{
                              entityMap.get(event?.referenceEntityInfo?.referenceEntity?.toString())?.name
                                ? entityMap.get(event?.referenceEntityInfo?.referenceEntity?.toString())?.name
                                : 'Select Entity'
                            }}
                          </mat-option>
                          <mat-option
                            *ngFor="let entity of selectedEntites"
                            [value]="entity.id"
                            (onSelectionChange)="onEntitySelection(i, entity)"
                          >
                            {{ entity?.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div
                      class="targetAtttributes"
                      *ngIf="eventCuList[i]?.referenceEntityInfo?.attributeReferences?.length > 0"
                    >
                      <div
                        class="targetAttribute"
                        *ngFor="
                          let attrReference of eventCuList[i].referenceEntityInfo.attributeReferences;
                          let attrReferenceIndex = index
                        "
                      >
                        <div class="matattribute" *ngIf="attrReference.targetAttributeId != ''">
                          <div class="" *ngIf="attrReference.targetAttributeName == null">
                            {{ attrReference.targetAttributeId.split('.').join('>>') }}
                          </div>
                          <div class="" *ngIf="attrReference.targetAttributeName != null">
                            {{ attrReference.targetAttributeName.split('.').join('>>') }}
                          </div>
                        </div>
                        <mat-accordion
                          *ngIf="
                            eventsPhysicalLayer?.length > 0 &&
                            !(attrReference.targetAttributeId != '' || attrReference.targetAttributeName != null)
                          "
                        >
                          <div class="physicalLayer">
                            {{ labels?.Physical_Layer }}
                          </div>

                          <mat-expansion-panel *ngFor="let entity of eventsPhysicalLayer; let entityIndex = index">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                {{ entity.name }}
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="attributes">
                              <li
                                class="attribute d-flex justify-content-between"
                                *ngFor="let attribute of entity.nslAttributes; let attrIndex = index"
                              >
                                <div class="attributeName">
                                  {{ attribute.name }}
                                </div>
                                <mat-icon (click)="SelectAttribute(attrReferenceIndex, attribute, i, entity)"
                                  >{{ labels?.add }}
                                </mat-icon>
                              </li>
                            </ul>
                          </mat-expansion-panel>
                        </mat-accordion>
                        <div class="displayValue">
                          <p>{{ labels?.Display_Value_Of_Refernece_Entity }}</p>

                          <mat-form-field appearance="outline">
                            <mat-select value="Events">
                              <mat-option value="Events">{{
                                (event?.referenceEntityInfo?.attributeReferences)[attrReferenceIndex]
                                  ?.refDisplayAttributeId
                                  ? (event?.referenceEntityInfo?.attributeReferences[
                                      attrReferenceIndex
                                    ]?.refDisplayAttributeId
                                      .split('.')
                                      .slice(-1))[0]
                                  : labels?.Select_Attribute
                              }}</mat-option>
                              <mat-option
                                (onSelectionChange)="onDisplayAttributeSelection(i, attribute, attrReferenceIndex)"
                                *ngFor="let attribute of event?.referenceEntityInfo.completeEntity?.nslAttributes"
                                [value]="attribute.name"
                                >{{ attribute.name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="ActualValue">
                          <p>{{ labels?.Actual_Value_Of_Refernece_Entity }}</p>
                          <mat-form-field appearance="outline">
                            <mat-select value="Events">
                              <mat-option value="Events">{{
                                (event?.referenceEntityInfo?.attributeReferences)[attrReferenceIndex]
                                  ?.refActualAttributeId
                                  ? (event?.referenceEntityInfo?.attributeReferences[
                                      attrReferenceIndex
                                    ]?.refActualAttributeId
                                      .split('.')
                                      .slice(-1))[0]
                                  : labels?.Select_Attribute
                              }}</mat-option>
                              <mat-option
                                (onSelectionChange)="onActualAttributeSelection(i, attribute, attrReferenceIndex)"
                                *ngFor="let attribute of event?.referenceEntityInfo.completeEntity?.nslAttributes"
                                [value]="attribute.name"
                                >{{ attribute.name }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="refernceValutype">
                          <mat-radio-group class="d-flex" [(ngModel)]="attrReference.referenceValueType">
                            <mat-radio-button value="OPTIONS">{{ labels?.OPTIONS }}</mat-radio-button>
                            <mat-radio-button value="VALUE">{{ labels?.VALUE }}</mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                    <div class="eventConditions" *ngIf="showConditions">
                      <div class="eventConditionsDisplay">
                        <div
                          class="eventConditions"
                          *ngIf="eventCuList[i]?.referenceEntityInfo?.membershipCriterias.length > 0"
                        >
                          <div class="conditions d-flex flex-wrap">
                            <div
                              class="condition d-flex w-100 pt-1"
                              *ngFor="
                                let condition of eventCuList[i]?.referenceEntityInfo?.membershipCriterias;
                                let conditionIndex = index
                              "
                            >
                              <div class="d-flex position-relative">
                                <span class="condition-text">{{ condition?.displayCondition }}</span>
                                <div
                                  class="img delete event-del del-posi delete-posi"
                                  *ngIf="condition.displayCondition.length > 0"
                                  (click)="deleteCondition(i, conditionIndex)"
                                >
                                  <img
                                    [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                                    class="condition-icon"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="newconditions">
                              {{ sourceFormulaAttr }}{{ selectedOperator }}{{ targetFormulaAttr }}
                            </div>
                            <div class="formulaInput">
                              <mat-form-field appearance="standard">
                                <input matInput placeholder="Formula" />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="operatorContainer">
                        <img
                          (click)="showOperators()"
                          [src]="'../../../../../assets/img/icon-keyboard.svg' | CDNUrlRewritePipe"
                          alt=""
                          srcset=""
                        />
                        <div class="operatorbox" *ngIf="showOperatorBox">
                          <div class="operator" *ngFor="let operator of conditionOperators">
                            <div class="selectoperator" (click)="selectOperator(operator)">{{ operator }}</div>
                          </div>
                        </div>
                      </div>
                      <mat-accordion *ngIf="eventsPhysicalLayer?.length > 0 && selectSource">
                        <div class="physicalLayer">
                          {{ labels?.Source_Physical_Layer }}
                        </div>
                        <mat-expansion-panel *ngIf="eventCuList[i].referenceEntityInfo.completeEntity">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ eventCuList[i].referenceEntityInfo.completeEntity.name }}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul class="attributes">
                            <li
                              class="attribute d-flex justify-content-between"
                              *ngFor="
                                let attribute of eventCuList[i].referenceEntityInfo.completeEntity.nslAttributes;
                                let attrIndex = index
                              "
                            >
                              <div class="attributeName">
                                {{ attribute.name }}
                              </div>
                              <mat-icon
                                (click)="
                                  SelectSourceAttribute(attribute, i, eventCuList[i].referenceEntityInfo.completeEntity)
                                "
                                >{{ labels?.add }}
                              </mat-icon>
                            </li>
                          </ul>
                        </mat-expansion-panel>
                      </mat-accordion>
                      <mat-accordion *ngIf="physicalLayer.length > 0 && selectTarget">
                        <div class="physicalLayer">
                          {{ labels?.Target_Physical_Layer }}
                        </div>
                        <mat-expansion-panel *ngFor="let entity of eventsPhysicalLayer; let entityIndex = index">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ entity.name }}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ul class="attributes">
                            <li
                              class="attribute d-flex justify-content-between"
                              *ngFor="let attribute of entity.nslAttributes; let attrIndex = index"
                            >
                              <div class="attributeName">
                                {{ attribute.name }}
                              </div>
                              <mat-icon (click)="SelectTargetAttribute(attribute, i, entity, entityIndex + 1)"
                                >{{ labels?.add }}
                              </mat-icon>
                            </li>
                          </ul>
                        </mat-expansion-panel>
                      </mat-accordion>
                      <div class="btn btn-info" (click)="AddCondition(i)">{{ labels?.Add }}</div>
                    </div>

                    <div class="targetContions">
                      <div class="add-targetAttribute" class="btn add-more-condition" (click)="AddTargetAttribute(i)">
                        <mat-icon>add</mat-icon>
                        {{ labels?.Add_Target_Attribute }}
                      </div>
                      <div class="add-Conditions" class="btn add-more-condition" (click)="AddConditions(i)">
                        <mat-icon>add</mat-icon>
                        {{ labels?.Add_Conditions }}
                      </div>
                    </div>
                  </div>
                  <div class="GSI-Container" *ngIf="EventRef == 'GSI' && (!event?.dsdGsiId || event?.dsdGsi == '')">
                    <div class="searchbox">
                      <input
                        (keyUp)="loadSearchedGsi()"
                        class="searchInput mt-3"
                        type="text"
                        name="searchgsi"
                        [(ngModel)]="gsiSearchText"
                        (keyup)="detectGsiSearchText.next(true)"
                        (keydown)="$event.stopPropagation()"
                        id=""
                        placeholder="search"
                      />
                    </div>
                    <div class="gsi-display">
                      <ul class="common-list">
                        <li *ngFor="let gsi of publishedGsi; let gsiIndex = index">
                          <div class="d-flex item align-items-center">
                            <div class="details">
                              <p class="title">{{ gsi?.name }}</p>
                            </div>
                            <a class="add-icon" (click)="selectGsiForEvent(gsiIndex, i)"><span></span></a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="add-more-condition" (click)="addMoreEvents()">
                <p>
                  <span class="material-icons">
                    {{ labels?.add }}
                  </span>
                  {{ labels?.Add_Events }}
                </p>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Metadata }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field class="description-textarea">
                <textarea matInput rows="5" placeholder="Description Text"></textarea>
              </mat-form-field>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="singleSelection?.includes(configurationData?.data?.attr?.attributeType?.uiElement?.uiElement)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{labels?.CU_Submit_Event|| 'CU Submit Event'}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-label>{{labels?.Enable_Submit || 'Enable Submit'}}</mat-label>
              <mat-slide-toggle
                (change)="changeSubmitToggle($event, warningPopup)"
                [(ngModel)]="toggleCUSubmit"
                name="submitConfiguration"
                id="submitConfiguration"
              >
              </mat-slide-toggle>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Conditional_Potentiality }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span class="addmem d-block">{{ labels?.Add_Conditional_Potentiality }}</span>
                <mat-radio-group
                  name="potentialityRadio_{{ configurationData.data.attr.name }}"
                  [(ngModel)]="potentialityFlag"
                  fxLayout="column"
                  (change)="addConditionalpotentiality(configurationData.data.attr)"
                >
                  <mat-radio-button
                    name="potentialityRadio_{{ configurationData.data.attr.name }}"
                    id="potentialityRadio_true_{{ configurationData.data.attr.name }}"
                    value="true"
                  >
                    {{ labels?.Yes }}
                  </mat-radio-button>
                  <mat-radio-button
                    name="potentialityRadio_{{ configurationData.data.attr.name }}"
                    id="potentialityRadio_false_{{ configurationData.data.attr.name }}"
                    value="false"
                  >
                    {{ labels?.No }}
                  </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="potentialityFlag === 'true'">
                  <div class="exptext mt-3">{{ labels?.Expression }}</div>
                  <span *ngIf="configurationData.data.attr.conditionalPotentialityName" class="m-l-20">
                    <p
                      class="display-flex"
                      *ngFor="let condition of configurationData.data.attr.conditionalPotentialityName; let i = index"
                      class="w-auto d-flex"
                    >
                      ({{ condition | dcd }})
                      <a
                        href="javascript:;"
                        id="hoveredDeleteIcons"
                        (click)="removeConditionalCriteria(configurationData.data.attr, i)"
                      >
                        <figure id="removeCriteriaIcon_{{ configurationData.data.attr?.name | stringReplace }}">
                          <img
                            [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                            class="condition-icon"
                            alt=""
                          />
                        </figure>
                      </a>
                    </p>
                  </span>

                  <div
                    class="d-flex align-items-baseline expression-form"
                    id="expressionDiv_{{ configurationData.data.attr.name }}"
                  >
                    <span class="mr-2" *ngIf="potentialityAttr">{{ potentialityAttr | dcd }}</span>
                    <span class="mr-2" *ngIf="currentOperator">{{ currentOperator }}</span>
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.Enter_Value }}</mat-label>
                      <input
                        matInput
                        placeholder="Enter Value"
                        type="text"
                        autocomplete="off"
                        [(ngModel)]="configurationData.data.attr.PotentialityInput"
                        (keypress)="checkTargetInput($event)"
                        autofocus
                      />
                    </mat-form-field>
                  </div>
                  <a
                    href="javascript:;"
                    (click)="updateConditionalPotentiality(configurationData.data.attr)"
                    class="d-inline-block mb-3"
                  >
                    <img [src]="'assets/img/icon-green-plus.svg' | CDNUrlRewritePipe" class="condition-icon" alt="" />
                    {{ labels?.Add }}
                  </a>
                </div>
                <ul *ngIf="potentialityFlag === 'true'" class="operators expression-form-operators">
                  <li
                    (click)="operator(op, configurationData.data.attr)"
                    *ngFor="let op of memberShipCriteriaOperators"
                  >
                    {{ op }}
                  </li>
                </ul>
              </div>
              <div>
                <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                  <mat-expansion-panel *ngIf="physicalLayer?.length > 0" id="accordianExpansionPhysical_cuRecursive">
                    <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                      <mat-panel-title class="active-bold">
                        <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [layerData]="physicalLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForPhyscialLayer"
                      [changeUnit]="changeUnit"
                      [conditionalPotentiality]="true"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                  <mat-expansion-panel *ngIf="informationLayer?.length > 0" id="expansionPanel_GSIRecursive">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="active-bold">
                        <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [layerData]="informationLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForInformationLayer"
                      [changeUnit]="changeUnit"
                      [conditionalPotentiality]="true"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-expansion-panel> -->
            <mat-expansion-panel
              *ngIf="
                (changeUnit?.reservedCUType === 'search' ||
                  changeUnit?.reservedCUType === 'NSL_Template_PaginatedSearch' ||
                  changeUnit?.reservedCUType?.indexOf('NSL_Report:') !== -1 ||
                  changeUnit?.reservedCUType === 'aggregatefunction' ||
                  changeUnit?.reservedCUType === 'statisticalfunction' ||
                  changeUnit?.reservedCUType === 'selection' ||
                  changeUnit?.reservedCUType === 'primarykeysearch') &&
                (configurationData?.data?.entity?.layerType === 'physical' ||
                  configurationData?.data?.layerType === 'physical') &&
                changeUnit?.isReserved
              "
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Membership Criteria
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span class="addmem">{{ labels?.Add_Membership_Criteria }}</span>
                <mat-radio-group
                  name="membershipRadio_{{ configurationData.data.attr.name }}"
                  [(ngModel)]="memberShipFlagsArr"
                  (change)="addMembership(configurationData.data.attr)"
                  fxLayout="column"
                >
                  <mat-radio-button
                    name="membershipRadio_{{ configurationData.data.attr.name }}"
                    id="membershipRadio_{{ configurationData.data.attr.name }}"
                    [value]="true"
                  >
                    {{ labels?.Yes }}
                  </mat-radio-button>
                  <mat-radio-button
                    name="membershipRadio1_{{ configurationData.data.attr.name }}"
                    id="membershipRadio1_{{ configurationData.data.attr.name }}"
                    [value]="false"
                  >
                    {{ labels?.No }}
                  </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="memberShipFlagsArr">
                  <div class="exptext">{{ labels?.Expression }}</div>
                  <span *ngIf="configurationData.data.attr.memberShipName" class="m-l-20">
                    <p class="display-flex" class="w-auto d-flex">
                      ({{ configurationData.data.attr.memberShipName }})
                      <a href="javascript:;" id="hoveredDeleteIcons" (click)="memberShipFlagsChange()">
                        <figure id="removeCriteriaIcon_{{ configurationData.data.attr?.name | stringReplace }}">
                          <img
                            [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                            class="condition-icon"
                          />
                        </figure>
                      </a>
                    </p>
                  </span>
                  <div
                    class="d-flex align-items-baseline expression-form"
                    id="expressionDiv_{{ configurationData.data.attr.name }}"
                  >
                    <span class="mr-2" *ngIf="currentOp">{{ currentOp }}</span>
                    <span class="mr-2" *ngIf="memberShipAttr">{{ memberShipAttr | dcd }}</span>
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.Enter_Value }}</mat-label>
                      <input
                        matInput
                        placeholder="Enter Value"
                        type="text"
                        autocomplete="off"
                        [(ngModel)]="configurationData.data.attr.membershipInput"
                        autofocus
                      />
                    </mat-form-field>
                  </div>
                  <a
                    href="javascript:;"
                    (click)="updateAttrMemberShip(configurationData.data.attr)"
                    class="d-inline-block mb-3"
                  >
                    <img [src]="'assets/img/icon-green-plus.svg' | CDNUrlRewritePipe" class="condition-icon" alt="" />
                    {{ labels?.Add }}
                  </a>
                </div>
                <ul
                  class="operators expression-form-operators"
                  *ngIf="changeUnit?.reservedCUType === 'primarykeysearch'"
                >
                  <li
                    class="w-auto"
                    (click)="currentOp = op"
                    *ngFor="let op of memberShipCriteriaOperatorsPrimaryKeySearch"
                  >
                    {{ op }}
                  </li>
                </ul>

                <ul
                  class="operators expression-form-operators"
                  *ngIf="!(changeUnit?.reservedCUType === 'primarykeysearch')"
                >
                  <li class="w-auto" (click)="currentOp = op" *ngFor="let op of memberShipCriteriaOperators">
                    {{ op }}
                  </li>
                </ul>
              </div>
              <div class="no-label w-75">
                <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                  <mat-expansion-panel *ngIf="physicalLayer.length > 0" id="accordianExpansionPhysical_cuRecursive">
                    <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                      <mat-panel-title class="active-bold">
                        <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [layerData]="physicalLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForPhyscialLayer"
                      [changeUnit]="changeUnit"
                      [isMembership]="true"
                      [setPrimaryKeySearch]="changeUnit"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                  <mat-expansion-panel *ngIf="informationLayer.length > 0" id="expansionPanel_GSIRecursive">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="active-bold">
                        <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [layerData]="informationLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForInformationLayer"
                      [changeUnit]="changeUnit"
                      [isMembership]="true"
                      [setPrimaryKeySearch]="changeUnit"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                  <mat-expansion-panel *ngIf="triggerCESLayer.length > 0" id="expansionPanel_GSIRecursive">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="active-bold">
                        <span id="infoLayr_GSIRecursive">{{ labels?.triggerCES_Layer }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [layerData]="triggerCESLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForTriggerCESLayer"
                      [changeUnit]="changeUnit"
                      [isMembership]="true"
                      [setPrimaryKeySearch]="changeUnit"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Helper Text
            </mat-panel-title>
          </mat-expansion-panel-header>
          <textarea placeholder="Enter Helper Text" [(ngModel)]="helperTextString" [maxlength]="250" rows="4" cols="35">
          </textarea>

          <label class="nopadding clrlbl"> Characters Remaining: {{ helperTextString?.length }}/250</label>
          <div class="Position">
            <span class="heading">Position</span>
            <div class="boxes">
              <div
                class="box"
                (click)="selectedPosition('bottom')"
                [ngClass]="{ 'box-active': helperTextPostion == 'bottom' }"
              >
                <span class="text-box"></span>
                <span class="text-content bottom-text">Bottom Helper Text</span>
              </div>
              <div
                class="box"
                (click)="selectedPosition('top')"
                [ngClass]="{ 'box-active': helperTextPostion == 'top' }"
              >
                <span class="text-content top-text">Top Helper Text</span>
                <span class="text-box"></span>
              </div>
            </div>
          </div>
          <div class="Choose">
            <h5 class="heading">Font Colour</h5>
            <div class="color-boxes" [ngClass]="helperTextString == '' || showHelperTextIcon ? 'events-none' : ''">
              <div
                class="color-box color1"
                (click)="selectedColor('#000000')"
                [ngClass]="{ active: helperTextColor == '#000000' }"
              ></div>
              <div
                class="color-box color2"
                (click)="selectedColor('#323232')"
                [ngClass]="{ active: helperTextColor == '#323232' }"
              ></div>
              <div
                class="color-box color3"
                (click)="selectedColor('#666666')"
                [ngClass]="{ active: helperTextColor == '#666666' }"
              ></div>
              <div
                class="color-box color4"
                (click)="selectedColor('#999999')"
                [ngClass]="{ active: helperTextColor == '#999999' }"
              ></div>
              <div
                class="color-box color5"
                (click)="selectedColor('#CB1FCB')"
                [ngClass]="{ active: helperTextColor == '#CB1FCB' }"
              ></div>
              <div
                class="color-box color6"
                (click)="selectedColor('#9900FF')"
                [ngClass]="{ active: helperTextColor == '#9900FF' }"
              ></div>
              <div
                class="color-box color7"
                (click)="selectedColor('#741B47')"
                [ngClass]="{ active: helperTextColor == '#741B47' }"
              ></div>
              <div
                class="color-box color8"
                (click)="selectedColor('#5B0F00')"
                [ngClass]="{ active: helperTextColor == '#5B0F00' }"
              ></div>
              <div
                class="color-box color9"
                (click)="selectedColor('#4A86E8')"
                [ngClass]="{ active: helperTextColor == '#4A86E8' }"
              ></div>
              <div
                class="color-box color10"
                (click)="selectedColor('#0000FF')"
                [ngClass]="{ active: helperTextColor == '#0000FF' }"
              ></div>
              <div
                class="color-box color11"
                (click)="selectedColor('#1C4587')"
                [ngClass]="{ active: helperTextColor == '#1C4587' }"
              ></div>
              <div
                class="color-box color12"
                (click)="selectedColor('#073763')"
                [ngClass]="{ active: helperTextColor == '#073763' }"
              ></div>
              <div
                class="color-box color13"
                (click)="selectedColor('#274E13')"
                [ngClass]="{ active: helperTextColor == '#274E13' }"
              ></div>
              <div
                class="color-box color14"
                (click)="selectedColor('#FFFFFF')"
                [ngClass]="{ active: helperTextColor == '#FFFFFF' }"
              ></div>
            </div>
            <h5 class="heading">Font Size</h5>
            <div class="font-boxes" [ngClass]="helperTextString == '' || showHelperTextIcon ? 'events-none' : ''">
              <div class="font-box" (click)="selectedFont('10px')" [ngClass]="{ active: helperTextFont == '10px' }">
                10 px
              </div>
              <div class="font-box" (click)="selectedFont('12px')" [ngClass]="{ active: helperTextFont == '12px' }">
                12 px
              </div>
              <div class="font-box" (click)="selectedFont('14px')" [ngClass]="{ active: helperTextFont == '14px' }">
                14 px
              </div>
            </div>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="showHelperTextIcon" value="true">{{
              labels?.Show_HelperText_Icon
            }}</mat-slide-toggle>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="specialFeature ilayerdata" *ngIf="isSpecialFeature">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="active-bold">
              <span>{{ labels?.Special_Features }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-node-special-features
            [specialFeatureIndex]="specialFeatureIndex"
            [gsiData]="tempGsiObj"
            [participatingLayer]="configurationData?.data?.entity?.layerType"
            [entity]="configurationData?.data.entity"
            [attribute]="configurationData?.data?.attr"
            [slotIndex]="slotIndex"
            [cuIndex]="CuIndex"
            [initialPayLoad]="specialFeatureProperties"
            (payLoadEmitter)="findSpecialFeaturePayload($event)"
          >
            ></app-node-special-features
          >
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<ng-template #defaultValueBlock let-attrDetails="attrDetails">
  <div *ngIf="!attrDetails?.value?.constantChangeDriver">
    <!-- Block for slider type UI control -->
    <div
      class="slider-target-div"
      *ngIf="
        attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
        attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
        attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
        attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
        attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
      "
    >
      <label class="slider-target-label">Target Value</label>
      <input
        matInput
        placeholder=""
        [(ngModel)]="targetValue"
        class="target-slider border-Bottom-slider"
        (keyup)="onlyNumberKey($event)"
      />
    </div>
    <div class="slider-target-div" *ngIf="attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider'">
      <label class="slider-target-label">Step Value</label>
      <input
        matInput
        placeholder=""
        [(ngModel)]="sliderStepValue"
        class="target-slider border-Bottom-slider"
        (keyup)="onlyNumberKey($event)"
      />
    </div>
    <!-- For default value of UI controls expect file type UI controls-->
    <div class="level-dropdown no-label">
      <mat-form-field appearance="float" appearance="outline">
        <mat-label>{{labels?.Select_Level}}</mat-label>
        <mat-select>
          <mat-option
            *ngFor="let level of levelList"
            [value]="level"
            (click)="CompareProperties(level, 'DEFAULT_VALUE')"
          >
            {{ level }}
          </mat-option>
        </mat-select></mat-form-field
      >
    </div>
    <div class="compare">
      <button class="compareButton" (click)="compareValuePopup('DEFAULT VALUE')" [disabled]="!compareDef">
        Compare Default Value
      </button>
    </div>
    <div
      class="expected-time"
      *ngIf="!excludeUiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement) && betLevelDefault"
    >
      <div>
        <!-- Enter default value for UI control -->
        <div *ngIf="uiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement); else defaultInputBlock">
          <!-- For Boolean  -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'boolean'">
            {{ labels?.Default_Value }}:
            <mat-slide-toggle [(ngModel)]="booleanDefaultValue" (ngModelChange)="booleanValue()"> </mat-slide-toggle>
          </div>

          <!-- For Date -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'date'" class="hideunderline">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <input
                matInput
                name="defaultValueForDate"
                id="defaultValueForDate"
                placeholder="Choose a date"
                [matDatepicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- For Year Month -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'yearMonth'">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <p>{{ dateTypeDefaultValue | date: yearMonthFormatType }}</p>
              <input
                matInput
                name="defaultValueForYearMonth"
                id="defaultValueForYearMonth"
                placeholder="Month and Year"
                [matDatepicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                style="display: none;"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker
                #picker
                startView="multi-year"
                (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, picker)"
              >
              </mat-datepicker>
            </mat-form-field>
          </div>

          <!-- For Year Month -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'year'">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <p>{{ defaultValue }}</p>
              <input
                matInput
                name="defaultValueForYear"
                id="defaultValueForYear"
                placeholder="Select Year"
                [matDatepicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                style="display: none;"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearForYear($event, picker)">
              </mat-datepicker>
            </mat-form-field>
          </div>

          <!-- For DateTime -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'datetime'" class="hideunderline">
            <mat-form-field appearance="standard" *ngIf="timeFormatType === '24-hr'">
              <label>{{ labels.Default_Value }}</label>
              <input
                matInput
                name="defaultValueForDatetime"
                id="defaultValueForDatetime"
                placeholder="Choose date and time"
                [ngxMatDatetimePicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="false"
                [disableMinute]="false"
                [hideTime]="false"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="timeFormatType !== '24-hr'">
              <label>{{ labels.Default_Value }}</label>
              <input
                matInput
                name="defaultValueForDatetime"
                id="defaultValueForDatetime"
                placeholder="Choose date and time"
                [ngxMatDatetimePicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="true"
                [disableMinute]="false"
                [hideTime]="false"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <!-- For Time -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'time'" class="hideunderline">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <br />
              <ul class="inner-cal" style="display: inline-flex;">
                <li *ngIf="timeFormatType === '24-hr'">
                  <input
                    matInput
                    name="defaultValueForTime"
                    id="defaultValueForTime"
                    placeholder="Choose time"
                    [ngxMatDatetimePicker]="picker"
                    [(ngModel)]="dateTypeDefaultValue"
                    (ngModelChange)="formatDate()"
                    readonly
                    style="display: none;"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="false"
                    [disableMinute]="false"
                    [hideTime]="false"
                    panelClass="hide-calendar  d-none"
                  >
                  </ngx-mat-datetime-picker>
                </li>
                <li *ngIf="timeFormatType !== '24-hr'">
                  <input
                    matInput
                    name="defaultValueForTime"
                    id="defaultValueForTime"
                    placeholder="Choose time"
                    [ngxMatDatetimePicker]="picker"
                    [(ngModel)]="dateTypeDefaultValue"
                    (ngModelChange)="formatDate()"
                    readonly
                    style="display: none;"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="true"
                    [disableMinute]="false"
                    [hideTime]="false"
                    panelClass="hide-calendar  d-none"
                  >
                  </ngx-mat-datetime-picker>
                </li>
                <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType === '24-hr'">
                  <p class="timedisplay">{{ dateTypeDefaultValue | date: 'HH:mm' }}</p>
                </li>
                <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType !== '24-hr'">
                  <p class="timedisplay">{{ dateTypeDefaultValue | date: 'hh:mm a' }}</p>
                </li>
              </ul>
            </mat-form-field>
          </div>

          <!-- For Date Range -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'dateRange'" class="hideunderline">
            <label>{{ labels.Default_Value }}</label>
            <p-calendar
              name="defaultValueForDateRange"
              id="defaultValueForDateRange"
              placeholder="Choose two dates"
              [(ngModel)]="dateTypeDefaultValue"
              (ngModelChange)="formatDate()"
              [showIcon]="true"
              appendTo="body"
              selectionMode="range"
              [readonlyInput]="true"
              inputId="range"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="1900:2050"
              dateFormat="dd-mm-yy"
            >
            </p-calendar>
          </div>

          <!-- For Duration -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'duration'" class="duration">
            <label>{{ labels.Default_Value }}</label>
            <table>
              <tr>
                <td>
                  <input
                    type="number"
                    id="duration_days"
                    placeholder="00"
                    [(ngModel)]="durationDefaultValue.days"
                    class="time-input"
                  />
                  Days :
                </td>
                <td>
                  <input
                    type="number"
                    id="duration_hours"
                    placeholder="00"
                    min="0"
                    max="23"
                    [(ngModel)]="durationDefaultValue.hours"
                    (ngModelChange)="checkDuration()"
                    class="time-input"
                  />
                  {{ labels?.Hours }} :
                </td>
                <td>
                  <input
                    type="number"
                    id="duration_minutes"
                    placeholder="00"
                    min="0"
                    max="59"
                    [(ngModel)]="durationDefaultValue.minutes"
                    (ngModelChange)="checkDuration()"
                    class="time-input"
                  />
                  {{ labels?.Minutes }}
                </td>
              </tr>
            </table>
          </div>

          <!-- Rich Text -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'editor'" class="text-editor">
            <mat-label>{{ labels.Default_Value }}</mat-label>
            <angular-editor [(ngModel)]="defaultValue" id="angularEditor" [config]="EditorConfig"></angular-editor>
          </div>

          <!-- MMI Location -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'MMILocation'" class="MMILocation">
            <label>{{ labels?.Default_Value }}</label>
            <input
              type="number"
              id="nodeattrEdit_mmiDefaultlat"
              matInput
              placeholder="Latitude"
              [(ngModel)]="mmiDefaultLat"
            />
            <input
              type="number"
              id="nodeattrEditt_mmiDefaultlon"
              matInput
              placeholder="Longitude"
              [(ngModel)]="mmiDefaultLon"
            />
          </div>

          <div
            *ngIf="
              attrDetails?.attributeType?.uiElement?.uiElement !== 'boolean' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'basicSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'inputSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'rangeSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'stepSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'verticalSlider'
            "
          >
            <!-- Add button for adding default values -->
            <button
              *ngIf="!(!multiDefaultValue && allDefaultValues?.length > 0)"
              mat-stroked-button
              type="button"
              class="addDefaultValBtn"
              id="Default_Value"
              (click)="addDefaultValue()"
            >
              {{ labels.Add }}
            </button>

            <!-- Show all default values of UI control -->
            <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
              <p
                *ngIf="
                  !dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) &&
                  attrDetails?.attributeType?.uiElement?.uiElement !== 'duration'
                "
              >
                {{ attrvalue }}
              </p>
              <p
                *ngIf="
                  dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) ||
                  attrDetails?.attributeType?.uiElement?.uiElement === 'duration'
                "
              >
                {{ displayDateDefaultValue(attrvalue, attrDetails?.attributeType?.uiElement?.uiElement) }}
              </p>
              <span class="pointer"
                ><img
                  (click)="removeDefaultValue(i)"
                  id="defaultvalueremoveimg"
                  src="assets/img/delete-icon.svg"
                  alt=""
              /></span>
            </div>
          </div>

          <!--Slider default value-->
          <div
            *ngIf="
              attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
            "
          >
            <label>{{ labels.Default_Value }}</label>
            <input
              matInput
              class="add-value-default"
              name="defaultValue"
              placeholder=""
              id="defaultValue_slider"
              type="number"
              autocomplete="off"
              [(ngModel)]="defaultValue"
            />

            <!-- Add button for adding default values -->
            <button
              *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
              mat-stroked-button
              type="button"
              class="addDefaultValBtn"
              id="Slider_Default_Value"
              (click)="sliderDefaultValue()"
            >
              {{ labels.Add }}
            </button>
            <!-- Show all default values of UI control -->
            <div *ngIf="allDefaultValues?.length > 0">
              <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
                {{ attrvalue }}
                <span class="pointer"
                  ><img
                    (click)="removeDefaultValue(i)"
                    id="sliderdefaultvalueremoveimg"
                    [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                    alt=""
                /></span>
              </div>
            </div>
          </div>
        </div>

        <ng-template #defaultInputBlock>
          <label>{{ labels.Default_Value }}</label>
          <input
            matInput
            class="add-value-default"
            name="defaultValue"
            placeholder="Default Value"
            id="defaultValue"
            type="text"
            autocomplete="off"
            [(ngModel)]="defaultValue"
            (keyup.enter)="addDefaultValue()"
          />

          <!-- Add button for adding default values -->
          <button
            *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
            mat-stroked-button
            type="button"
            class="addDefaultValBtn"
            id="Default_Value"
            (click)="addDefaultValue()"
          >
            {{ labels.Add }}
          </button>

          <!-- Show all default values of UI control -->
          <div>
            <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
              {{ attrvalue }}
              <span class="pointer"
                ><img
                  (click)="removeDefaultValue(i)"
                  class="delete-custom"
                  id="defaultvalueremoveimg"
                  src="assets/img/delete-icon.svg"
                  alt=""
              /></span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div
      class="expected-time"
      *ngIf="!excludeUiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement) && !betLevelDefault"
    >
      <div>
        <!-- Enter default value for UI control -->
        <div *ngIf="uiControlList.includes(attrDetails?.attributeType?.uiElement?.uiElement); else defaultInputBlock">
          <!-- For Boolean  -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'boolean'">
            {{ labels?.Default_Value }}:
            <mat-slide-toggle [(ngModel)]="booleanDefaultValue" (ngModelChange)="booleanValue()"> </mat-slide-toggle>
          </div>

          <!-- For Date -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'date'" class="hideunderline">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <input
                matInput
                name="defaultValueForDate"
                id="defaultValueForDate"
                placeholder="Choose a date"
                [matDatepicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- For Year Month -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'yearMonth'">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <p>{{ dateTypeDefaultValue | date: yearMonthFormatType }}</p>
              <input
                matInput
                name="defaultValueForYearMonth"
                id="defaultValueForYearMonth"
                placeholder="Month and Year"
                [matDatepicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                style="display: none;"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker
                #picker
                startView="multi-year"
                (yearSelected)="chosenYearHandler($event)"
                (monthSelected)="chosenMonthHandler($event, picker)"
              >
              </mat-datepicker>
            </mat-form-field>
          </div>

          <!-- For Year Month -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'year'">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <p>{{ defaultValue }}</p>
              <input
                matInput
                name="defaultValueForYear"
                id="defaultValueForYear"
                placeholder="Select Year"
                [matDatepicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                style="display: none;"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker startView="multi-year" (yearSelected)="chosenYearForYear($event, picker)">
              </mat-datepicker>
            </mat-form-field>
          </div>

          <!-- For DateTime -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'datetime'" class="hideunderline">
            <mat-form-field appearance="standard" *ngIf="timeFormatType === '24-hr'">
              <label>{{ labels.Default_Value }}</label>
              <input
                matInput
                name="defaultValueForDatetime"
                id="defaultValueForDatetime"
                placeholder="Choose date and time"
                [ngxMatDatetimePicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="false"
                [disableMinute]="false"
                [hideTime]="false"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="timeFormatType !== '24-hr'">
              <label>{{ labels.Default_Value }}</label>
              <input
                matInput
                name="defaultValueForDatetime"
                id="defaultValueForDatetime"
                placeholder="Choose date and time"
                [ngxMatDatetimePicker]="picker"
                [(ngModel)]="dateTypeDefaultValue"
                (ngModelChange)="formatDate()"
                readonly
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="true"
                [disableMinute]="false"
                [hideTime]="false"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <!-- For Time -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'time'" class="hideunderline">
            <mat-form-field appearance="standard">
              <label>{{ labels.Default_Value }}</label>
              <br />
              <ul class="inner-cal" style="display: inline-flex;">
                <li *ngIf="timeFormatType === '24-hr'">
                  <input
                    matInput
                    name="defaultValueForTime"
                    id="defaultValueForTime"
                    placeholder="Choose time"
                    [ngxMatDatetimePicker]="picker"
                    [(ngModel)]="dateTypeDefaultValue"
                    (ngModelChange)="formatDate()"
                    readonly
                    style="display: none;"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="false"
                    [disableMinute]="false"
                    [hideTime]="false"
                    panelClass="hide-calendar  d-none"
                  >
                  </ngx-mat-datetime-picker>
                </li>
                <li *ngIf="timeFormatType !== '24-hr'">
                  <input
                    matInput
                    name="defaultValueForTime"
                    id="defaultValueForTime"
                    placeholder="Choose time"
                    [ngxMatDatetimePicker]="picker"
                    [(ngModel)]="dateTypeDefaultValue"
                    (ngModelChange)="formatDate()"
                    readonly
                    style="display: none;"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1"
                    [stepMinute]="1"
                    [stepSecond]="1"
                    [touchUi]="false"
                    [color]="'primary'"
                    [enableMeridian]="true"
                    [disableMinute]="false"
                    [hideTime]="false"
                    panelClass="hide-calendar  d-none"
                  >
                  </ngx-mat-datetime-picker>
                </li>
                <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType === '24-hr'">
                  <p class="timedisplay">{{ dateTypeDefaultValue | date: 'HH:mm' }}</p>
                </li>
                <li style="margin-left: 2%; margin-top: 13%;" *ngIf="timeFormatType !== '24-hr'">
                  <p class="timedisplay">{{ dateTypeDefaultValue | date: 'hh:mm a' }}</p>
                </li>
              </ul>
            </mat-form-field>
          </div>

          <!-- For Date Range -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'dateRange'" class="hideunderline">
            <label>{{ labels.Default_Value }}</label>
            <p-calendar
              name="defaultValueForDateRange"
              id="defaultValueForDateRange"
              placeholder="Choose two dates"
              [(ngModel)]="dateTypeDefaultValue"
              (ngModelChange)="formatDate()"
              [showIcon]="true"
              appendTo="body"
              selectionMode="range"
              [readonlyInput]="true"
              inputId="range"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="1900:2050"
              dateFormat="dd-mm-yy"
            >
            </p-calendar>
          </div>

          <!-- For Duration -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'duration'" class="duration">
            <label>{{ labels.Default_Value }}</label>
            <table>
              <tr>
                <td>
                  <input
                    type="number"
                    id="duration_days"
                    placeholder="00"
                    [(ngModel)]="durationDefaultValue.days"
                    class="time-input"
                  />
                  Days :
                </td>
                <td>
                  <input
                    type="number"
                    id="duration_hours"
                    placeholder="00"
                    min="0"
                    max="23"
                    [(ngModel)]="durationDefaultValue.hours"
                    (ngModelChange)="checkDuration()"
                    class="time-input"
                  />
                  {{ labels?.Hours }} :
                </td>
                <td>
                  <input
                    type="number"
                    id="duration_minutes"
                    placeholder="00"
                    min="0"
                    max="59"
                    [(ngModel)]="durationDefaultValue.minutes"
                    (ngModelChange)="checkDuration()"
                    class="time-input"
                  />
                  {{ labels?.Minutes }}
                </td>
              </tr>
            </table>
          </div>

          <!-- Rich Text -->
          <div *ngIf="attrDetails?.attributeType?.uiElement?.uiElement === 'editor'" class="text-editor">
            <mat-label>{{ labels.Default_Value }}</mat-label>
            <angular-editor [(ngModel)]="defaultValue" id="angularEditor" [config]="EditorConfig"></angular-editor>
          </div>

          <div
            *ngIf="
              attrDetails?.attributeType?.uiElement?.uiElement !== 'boolean' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'basicSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'inputSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'rangeSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'stepSlider' &&
              attrDetails?.attributeType?.uiElement?.uiElement !== 'verticalSlider'
            "
          >
            <!-- Add button for adding default values -->

            <!-- Show all default values of UI control -->
            <div class="d-flex added" *ngFor="let defVal of lowerBetProperties?.defaultValue">
              <p
                *ngIf="
                  !dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) &&
                  attrDetails?.attributeType?.uiElement?.uiElement !== 'duration'
                "
              >
                {{ defVal }}
              </p>
              <p
                *ngIf="
                  dateTypeUiControls.includes(attrDetails?.attributeType?.uiElement?.uiElement) ||
                  attrDetails?.attributeType?.uiElement?.uiElement === 'duration'
                "
              >
                {{ displayDateDefaultValue(defVal, attrDetails?.attributeType?.uiElement?.uiElement) }}
              </p>
            </div>
          </div>

          <!--Slider default value-->
          <div
            *ngIf="
              attrDetails?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
              attrDetails?.attributeType?.uiElement?.uiElement == 'verticalSlider'
            "
          >
            <label>{{ labels.Default_Value }}</label>
            <input
              matInput
              class="add-value-default"
              name="defaultValue"
              placeholder=""
              id="defaultValue_slider"
              type="number"
              autocomplete="off"
              [(ngModel)]="defaultValue"
            />

            <!-- Add button for adding default values -->
            <button
              *ngIf="!(!multiDefaultValue && allDefaultValues.length > 0)"
              mat-stroked-button
              type="button"
              class="addDefaultValBtn"
              id="Slider_Default_Value"
              (click)="sliderDefaultValue()"
            >
              {{ labels.Add }}
            </button>
            <!-- Show all default values of UI control -->
            <div *ngIf="allDefaultValues?.length > 0">
              <div class="d-flex added" *ngFor="let attrvalue of allDefaultValues; let i = index">
                {{ attrvalue }}
                <span class="pointer"
                  ><img
                    (click)="removeDefaultValue(i)"
                    id="sliderdefaultvalueremoveimg"
                    [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                    alt=""
                /></span>
              </div>
            </div>
          </div>
        </div>

        <ng-template #defaultInputBlock>
          <label>{{ labels.Default_Value }}</label>
          <input
            matInput
            class="add-value-default"
            name="defaultValue"
            placeholder="Default Value"
            id="defaultValue"
            type="text"
            autocomplete="off"
            [(ngModel)]="defaultValue"
          />

          <!-- Show all default values of UI control -->
          <div>
            <div class="d-flex added" *ngFor="let defVal of lowerBetProperties?.defaultValue">
              {{ defVal }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
<!-- <ng-template #compareDialog>
  <app-node-compare
  [property]="propertyType"
  [upperBetProperties]="propertyType=='DEFAULT VALUE' ? allDefaultValues : selectedValidatorsList"
  [lowerBetProperties]="lowerBetProperties">
  </app-node-compare>
</ng-template> -->
<ng-template #warningPopup>
  <div>
    CU Submit event can be added to only one attribute in a Change Unit and the attribute cannot have any other events
    associated with it. Do you wish to continue?
  </div>
  <div class="yes-no">
    <button (click)="onSubmitSelected()">Yes</button>
    <button (click)="onSubmitCancel()">No</button>
  </div>
</ng-template>
