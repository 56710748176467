<ul>
  <li class="li-top-0">
    <div class="li-flex att-li">
      <ng-template #attrName>
        <p [matTooltipPosition]="'above'" matTooltipClass="tool-tip-custom" matTooltip="{{ attr?.name }}" class="attlist-widt" id="dcd_label_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{ nestedAttrName | stringReplace}}">
          
          <span class="entity-circle-icon">
            <!-- <img  src="../../../../../assets/images/hybrid-canvas-icon/entity_icon.svg" alt=""> -->
            <img alt="" src="../../../../../assets/img/entity-blue.svg" class="ng-star-inserted">
            <span class="dot"></span>
          </span>
         
          <span class="att-dcd-name">{{ attr?.name | dcd }}</span>

          <span (click)="addAttributetoDcd(attr)" class="hoverplusimg-icon" *ngIf="attributesList?.length === 0; else memberShipBlock1">
            <img [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe" alt="" id="dcd_plus_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{nestedAttrName | stringReplace}}" class="img-h25 hover-img hoverplusimg"/>
          </span>

          <ng-template #memberShipBlock1>
            <span *ngIf="attr?.dsdId">
              <span (click)="addAttributetoDcd(attr)" class="hoverplusimg-icon" *ngIf="attributesList.includes(attr.dsdId)">
                <img [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe" alt="" id="dcd_plus_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{nestedAttrName | stringReplace}}" class="img-h25 hover-img hoverplusimg"/>
              </span>
            </span>
          </ng-template>
        </p>
      </ng-template>
      <mat-accordion class="w-100" *ngIf="attr?.generalEntity; else attrName">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="active-p">
              <img src="../../../../../assets/images/hybrid-canvas-icon/entity_icon.svg" alt="">
                
              <p [matTooltipPosition]="'above'" matTooltipClass="tool-tip-custom" matTooltip="{{ attr?.name }}" id="dcd_label_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{nestedAttrName | stringReplace}}" class="ent-widt">
                {{ attr?.name | dcd }}
              </p>
              <span class="w-auto" *ngIf="attributesList?.length === 0; else memberShipBlock2">
                <img id="dcd_plus_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{nestedAttrName | stringReplace}}" (click)="addEntitytoDcd(attr)" [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe" alt="" class="img-h25 hover-img hoverplusimg"/>
              </span>
              <ng-template #memberShipBlock2>
                <span *ngIf="attr?.dsdId">
                  <span class="w-auto" *ngIf="attributesList.includes(attr.dsdId)">
                    <img id="dcd_plus_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{nestedAttrName | stringReplace}}" (click)="addEntitytoDcd(attr)" [src]="'../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe" alt="" class="img-h25 hover-img hoverplusimg"/>
                  </span>
                </span>
              </ng-template>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="attribute-list attribute-list-li">
            <app-entity-dcd
              *ngFor="let subEntity of attr?.generalEntity?.nslAttributes" [entityIndex]="entityIndex" [entity]="entity" [attr]="subEntity" [cuName]="cuName" [layerName]="layerName" [attrName]="nestedAttrName + '.' + subEntity?.name" [attrId]="nestedAttrId + '.' + subEntity.id" (attributeEmit)="nestedAttributeEmit($event)" (entityEmit)="nestedEntityEmit($event)">
            </app-entity-dcd>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </li>
</ul>

