import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuleSetComponent } from './components/rule-set/rule-set.component';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RuleSetComponent],
  imports: [CommonModule, DropdownModule, OverlayPanelModule, FormsModule],
  exports: [RuleSetComponent],
})
export class RuleSetModule {}
