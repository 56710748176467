<div class="card-type-view customcardpreview">
  <div class="card">
    <div class="card-img-top">
      <ng-container
        *ngIf="cardData?.[0]"
        dynamicField
        [formData]="cardData?.[0]?.[cardData?.[0]?.attributeName]"
        [group]="cardData?.[0]?.[cardData?.[0]?.attributeName + 'form']"
        [isTableConfig]="true"
      >
      </ng-container>

      <!-- Detect event gsi -->
      <div *ngIf="checkEventGSI(cardData?.[0]?.[cardData?.[0]?.attributeName], 0)">
        <div *ngFor="let currEvent of currentEventDataArray[0]">
          <button
            type="button"
            mat-button
            color="primary"
            (click)="saveEventGSI(currEvent, cardData?.[0]?.[cardData?.[0]?.attributeName])"
          >
            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="card-content">
        <div>
          <ng-container
            *ngIf="cardData?.[1]"
            dynamicField
            [formData]="cardData?.[1]?.[cardData?.[1]?.attributeName]"
            [group]="cardData?.[1]?.[cardData?.[1]?.attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
        </div>

        <!-- Detect event gsi -->
        <div *ngIf="checkEventGSI(cardData?.[1]?.[cardData?.[1]?.attributeName], 1)">
          <div *ngFor="let currEvent of currentEventDataArray[1]">
            <button
              type="button"
              mat-button
              color="primary"
              (click)="saveEventGSI(currEvent, cardData?.[1]?.[cardData?.[1]?.attributeName])"
            >
              {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
            </button>
          </div>
        </div>
      </div>
      <div class="rating-wrap">
        <div class="stars-holder card-temp">
          <div class="stars">
            <ng-container
              *ngIf="cardData?.[2]"
              dynamicField
              [formData]="cardData?.[2]?.[cardData?.[2]?.attributeName]"
              [group]="cardData?.[2]?.[cardData?.[2]?.attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>

            <!-- Detect event gsi -->
            <div *ngIf="checkEventGSI(cardData?.[2]?.[cardData?.[2]?.attributeName], 2)">
              <div *ngFor="let currEvent of currentEventDataArray[2]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, cardData?.[2]?.[cardData?.[2]?.attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </div>
          <div class="rating-holder">
            <div class="rating">
              <ng-container
                *ngIf="cardData?.[3]"
                dynamicField
                [formData]="cardData?.[3]?.[cardData?.[3]?.attributeName]"
                [group]="cardData?.[3]?.[cardData?.[3]?.attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>

              <!-- Detect event gsi -->
              <div *ngIf="checkEventGSI(cardData?.[3]?.[cardData?.[3]?.attributeName], 3)">
                <div *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, cardData?.[3]?.[cardData?.[3]?.attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="price-holder">
          <div class="price">
            <!-- {{ cardData[4]?.attributeValue ? '&#8377; ' + cardData[4]?.attributeValue : '' }} -->
            <ng-container
              *ngIf="cardData?.[4]"
              dynamicField
              [formData]="cardData?.[4]?.[cardData?.[4]?.attributeName]"
              [group]="cardData?.[4]?.[cardData?.[4]?.attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>

            <!-- Detect event gsi -->
            <div *ngIf="checkEventGSI(cardData?.[4]?.[cardData?.[4]?.attributeName], 4)">
              <div *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, cardData?.[4]?.[cardData?.[4]?.attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer p-0" *ngIf="settings?.selectionType == 'button'">
      <div class="">
        <button
          type="button"
          (click)="selectedCard()"
          class="btn btn-block rounded-0"
          [ngClass]="isChecked ? 'card-selected' : 'cstm-btn'"
        >
          {{ settings?.lableName }}
        </button>
      </div>
    </div>
  </div>
</div>
