import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DateComponent } from './date.component';
@NgModule({
  declarations: [DateComponent],
  imports: [
    ControlsMaterialModule,
  ],

  exports: [DateComponent],
})
export class DateModule {
  static entry = {
    date: DateComponent,
    yearmonth: DateComponent,
    currentdate: DateComponent,
  };
}
