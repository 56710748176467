<div class="cart-configure" *ngIf="tabSelected == 'cart'">
  <!-- cart -- 1 -->
  <div 
    class="cart-card" 
    [ngStyle]="{
      background: backGroundColor,
      boxShadow: cartBoxShadow,
      borderColor: cartBorderColor,
      'border-radius.px': cartBorderRadius,
      'border-width.px': cartBorderWidth
    }"
    *ngIf="cartTemplateType == '1'">
    <div class="total-item" id="totalitem1">
      {{ labels?.Total_Item_Added }}
    </div>
    <div class="cart-main-content">
      <div
        #cartAttributeStyles
        class="cart-image"
        id="position-0"
        (drop)="cartAttributesPositionMap?.[0] ? !(cartAttributesPositionMap?.[0]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'image')"
        (dragover)="allowDrop($event)"
        appConfigureAttribute
        [(cartAttrStyleArr)]="cartAttrStyleArr"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [_selectedDirectiveId]="attributeDirectiveId"
        [_style]="style"
        [cartAttrStyleArray]="cartAttrStyleArray?.[0]"
      >
        {{cartAttributesPositionMap?.[0] ? cartAttributesPositionMap?.[0]?.name : 'Attribute'}}
        <span *ngIf=" cartAttributesPositionMap?.[0]?.id"
          ><img
            class="cursor-pointer"
            (click)="onDeletingCartAttr(0)"
            [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
            alt=""
        /></span>
      </div>
      <div class="cart-item-titles">
        <div
          #cartAttributeStyles
          class="cart-item-name"
          id="position-1"
          (drop)="cartAttributesPositionMap?.[1] ? !(cartAttributesPositionMap?.[1]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
          (dragover)="allowDrop($event)"
          appConfigureAttribute
          [(cartAttrStyleArr)]="cartAttrStyleArr"
          (emitSelectedElement)="recieveEmittedDirectiveId($event)"
          [_selectedDirectiveId]="attributeDirectiveId"
          [_style]="style"
          [cartAttrStyleArray]="cartAttrStyleArray?.[1]"
        >
          {{cartAttributesPositionMap?.[1] ? cartAttributesPositionMap?.[1]?.name : 'Attribute'}}
          <span *ngIf=" cartAttributesPositionMap?.[1]?.id"
            ><img
              class="cursor-pointer"
              (click)="onDeletingCartAttr(1)"
              [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
              alt=""
          /></span>
        </div>
        <div class="cart-item-props">
          <div
            #cartAttributeStyles
            class="cart-item-prop"
            id="position-2"
            (drop)="cartAttributesPositionMap?.[2] ? !(cartAttributesPositionMap?.[2]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
            (dragover)="allowDrop($event)"
            appConfigureAttribute
            [(cartAttrStyleArr)]="cartAttrStyleArr"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [_selectedDirectiveId]="attributeDirectiveId"
            [_style]="style"
            [cartAttrStyleArray]="cartAttrStyleArray?.[2]"
          >
            {{cartAttributesPositionMap?.[2] ? cartAttributesPositionMap?.[2]?.name : 'Attribute'}}
            <span *ngIf=" cartAttributesPositionMap?.[2]?.id"
              ><img
                class="cursor-pointer"
                (click)="onDeletingCartAttr(2)"
                [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                alt=""
            /></span>
          </div>
          <div
            #cartAttributeStyles
            class="cart-item-prop"
            id="position-3"
            (drop)="cartAttributesPositionMap?.[3] ? !(cartAttributesPositionMap?.[3]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
            (dragover)="allowDrop($event)"
            appConfigureAttribute
            [(cartAttrStyleArr)]="cartAttrStyleArr"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [_selectedDirectiveId]="attributeDirectiveId"
            [_style]="style"
            [cartAttrStyleArray]="cartAttrStyleArray?.[3]"
          >
            {{cartAttributesPositionMap?.[3] ? cartAttributesPositionMap?.[3]?.name : 'Attribute'}}
            <span *ngIf=" cartAttributesPositionMap?.[3]?.id"
              ><img
                class="cursor-pointer"
                (click)="onDeletingCartAttr(3)"
                [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                alt=""
            /></span>
          </div>
          <div
            #cartAttributeStyles
            class="cart-item-prop"
            id="position-4"
            (drop)="cartAttributesPositionMap?.[4] ? !(cartAttributesPositionMap?.[4]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'currency')"
            (dragover)="allowDrop($event)"
            appConfigureAttribute
            [(cartAttrStyleArr)]="cartAttrStyleArr"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [_selectedDirectiveId]="attributeDirectiveId"
            [_style]="style"
            [cartAttrStyleArray]="cartAttrStyleArray?.[4]"
          >
            {{cartAttributesPositionMap?.[4] ? cartAttributesPositionMap?.[4]?.name : 'Price*'}}
            <span *ngIf=" cartAttributesPositionMap?.[4]?.id"
              ><img
                class="cursor-pointer"
                (click)="onDeletingCartAttr(4)"
                [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                alt=""
            /></span>
          </div>
        </div>
      </div>
      <div class="cart-item-button">
        <div class="btn btn-dark">-</div>
        <input type="number" />
        <div class="btn btn-dark">+</div>
      </div>
    </div>
    <div class="cart-buttons" disabled>
      <div class="btn btn-dark text-truncate" id="button1"></div>
      <div class="btn btn-dark text-truncate" id="button2"></div>
      <div class="btn btn-dark text-truncate" id="button3"></div>
      <div class="btn btn-dark text-truncate" id="button4"></div>
      <div class="btn btn-dark text-truncate" id="button5"></div>
    </div>
  </div>
  <!-- cart -- 3 -->
  <div 
    class="cart-tmplt-v3-wrfm" 
    [ngStyle]="{
      background: backGroundColor,
      boxShadow: cartBoxShadow,
      borderColor: cartBorderColor,
      'border-radius.px': cartBorderRadius,
      'border-width.px': cartBorderWidth
    }"
    *ngIf="cartTemplateType == '3'"
    >
    <div class="cart-tmplt-v3-wrfm-body">
      <div class="cart-tmplt-v3-wrfm-imgs">
        <ul>
          <li
            #cartAttributeStyles
            class="cart-tmplt-v3-wrfm-logo"
            id="position-0"
            (drop)="cartAttributesPositionMap?.[0] ? !(cartAttributesPositionMap?.[0]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'image')"
            (dragover)="allowDrop($event)"
            appConfigureAttribute
            [(cartAttrStyleArr)]="cartAttrStyleArr"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [_selectedDirectiveId]="attributeDirectiveId"
            [_style]="style"
            [cartAttrStyleArray]="cartAttrStyleArray?.[0]"
          >
            {{cartAttributesPositionMap?.[0] ? cartAttributesPositionMap?.[0]?.name : 'Attribute'}}
            <span *ngIf=" cartAttributesPositionMap?.[0]?.id"
              ><img
                class="cursor-pointer"
                (click)="onDeletingCartAttr(0)"
                [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                alt=""
            /></span>
          </li>
        </ul>
      </div>
      <div class="cart-tmplt-v3-wrfm-right">
        <div class="cart-tmplt-v3-wrfm-content">
          <ul>
            <li
              #cartAttributeStyles
              class="cart-tmplt-v3-wrfm-list cart-tmplt-full"
              id="position-1"
              (drop)="cartAttributesPositionMap?.[1] ? !(cartAttributesPositionMap?.[1]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
              (dragover)="allowDrop($event)"
              appConfigureAttribute
              [(cartAttrStyleArr)]="cartAttrStyleArr"
              (emitSelectedElement)="recieveEmittedDirectiveId($event)"
              [_selectedDirectiveId]="attributeDirectiveId"
              [_style]="style"
              [cartAttrStyleArray]="cartAttrStyleArray?.[1]"
            >
              {{cartAttributesPositionMap?.[1] ? cartAttributesPositionMap?.[1]?.name : 'Attribute'}}
              <span *ngIf=" cartAttributesPositionMap?.[1]?.id"
                ><img
                  class="cursor-pointer"
                  (click)="onDeletingCartAttr(1)"
                  [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
            </li>
            <li
              #cartAttributeStyles
              class="cart-tmplt-v3-wrfm-list"
              id="position-2"
              (drop)="cartAttributesPositionMap?.[2] ? !(cartAttributesPositionMap?.[2]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'currency')"
              (dragover)="allowDrop($event)"
              appConfigureAttribute
              [(cartAttrStyleArr)]="cartAttrStyleArr"
              (emitSelectedElement)="recieveEmittedDirectiveId($event)"
              [_selectedDirectiveId]="attributeDirectiveId"
              [_style]="style"
              [cartAttrStyleArray]="cartAttrStyleArray?.[2]"
            >
              {{cartAttributesPositionMap?.[2] ? cartAttributesPositionMap?.[2]?.name : 'Attribute'}}
              <span *ngIf=" cartAttributesPositionMap?.[2]?.id"
                ><img
                  class="cursor-pointer"
                  (click)="onDeletingCartAttr(2)"
                  [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
            </li>
            <li
              #cartAttributeStyles
              class="cart-tmplt-v3-wrfm-list cart-tmplt-bold-text"
              id="position-3"
              (drop)="cartAttributesPositionMap?.[3] ? !(cartAttributesPositionMap?.[3]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
              (dragover)="allowDrop($event)"
              appConfigureAttribute
              [(cartAttrStyleArr)]="cartAttrStyleArr"
              (emitSelectedElement)="recieveEmittedDirectiveId($event)"
              [_selectedDirectiveId]="attributeDirectiveId"
              [_style]="style"
              [cartAttrStyleArray]="cartAttrStyleArray?.[3]"
            >
              {{cartAttributesPositionMap?.[3] ? cartAttributesPositionMap?.[3]?.name : 'Attribute'}}
              <span *ngIf=" cartAttributesPositionMap?.[3]?.id"
                ><img
                  class="cursor-pointer"
                  (click)="onDeletingCartAttr(3)"
                  [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
            </li>
          </ul>
        </div>
        <div class="cart-tmplt-v3-wrfm-controls">
          <ul>
            <li id="button-btn-3">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <button class="btn btn-outline-secondary bi bi-dash" type="button"></button>
                </div>
                <input type="number" class="form-control" placeholder="" value="1" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary bi bi-plus" type="button"></button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cart-tmplt-v3-wrfm-bottom cart-tmplt-v3Action">
        <ul>
          <li class="cart-tmplt-v3-wrfm-list cart-tmplt-full-3" id="position-4">
            {{cartAttributesPositionMap?.[4] ? cartAttributesPositionMap?.[4]?.name : 'Estimated Total Pay'}}
          </li>
          <li id="position-5">
            {{cartAttributesPositionMap?.[5] ? cartAttributesPositionMap?.[5]?.name : '₹ 30,000'}}
          </li>
        </ul>
        <ul>
          <li class="cart-tmplt-v3-wrfm-list cart-tmplt-bold-btn-3" id="position-6">
            {{cartAttributesPositionMap?.[6] ? cartAttributesPositionMap?.[6]?.name : 'Make Payment'}}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- cart -- 4 -->
  <div 
    class="cart-tmplt-v4-wrfm" 
    [ngStyle]="{
      background: backGroundColor,
      boxShadow: cartBoxShadow,
      borderColor: cartBorderColor,
      'border-radius.px': cartBorderRadius,
      'border-width.px': cartBorderWidth
    }"
    *ngIf="cartTemplateType == '4'">
    <h3 class="totalItem">{{ labels?.Total_Item_Added }}</h3>
    <div class="cart-tmplt-v4-wrfm-mainBidy">
      <div class="cart-tmplt-v4-itemAdd">
        <div class="cart-tmplt-v4-body">
          <div class="cart-tmplt-v4-img">
            <ul>
              <li
                #cartAttributeStyles
                class="cart-tmplt-v4-logo"
                id="position-0"
                (drop)="cartAttributesPositionMap?.[0] ? !(cartAttributesPositionMap?.[0]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'image')"
                (dragover)="allowDrop($event)"
                appConfigureAttribute
                [(cartAttrStyleArr)]="cartAttrStyleArr"
                (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                [_selectedDirectiveId]="attributeDirectiveId"
                [_style]="style"
                [cartAttrStyleArray]="cartAttrStyleArray?.[0]"
              >
                {{cartAttributesPositionMap?.[0] ? cartAttributesPositionMap?.[0]?.name : 'Attribute'}}
                <span *ngIf=" cartAttributesPositionMap?.[0]?.id"
                  ><img
                    class="cursor-pointer"
                    (click)="onDeletingCartAttr(0)"
                    [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                    alt=""
                /></span>
              </li>
            </ul>
          </div>
          <div class="cart-tmplt-v4-content-body">
            <div class="cart-tmplt-v4-content">
              <ul>
                <li
                  #cartAttributeStyles
                  class="cart-title"
                  id="position-1"
                  (drop)="cartAttributesPositionMap?.[1] ? !(cartAttributesPositionMap?.[1]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
                  (dragover)="allowDrop($event)"
                  appConfigureAttribute
                  [(cartAttrStyleArr)]="cartAttrStyleArr"
                  (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                  [_selectedDirectiveId]="attributeDirectiveId"
                  [_style]="style"
                  [cartAttrStyleArray]="cartAttrStyleArray?.[1]"
                >
                  {{cartAttributesPositionMap?.[1] ? cartAttributesPositionMap?.[1]?.name : 'Attribute'}}
                  <span *ngIf=" cartAttributesPositionMap?.[1]?.id"
                    ><img
                      class="cursor-pointer"
                      (click)="onDeletingCartAttr(1)"
                      [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                      alt=""
                  /></span>
                </li>
              </ul>
              <div class="cart-tmplt-v4-content-control">
                <div class="membercontent">
                  <ul>
                    <li
                      #cartAttributeStyles
                      class="memberTitle"
                      id="position-2"
                      (drop)="cartAttributesPositionMap?.[2] ? !(cartAttributesPositionMap?.[2]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
                      (dragover)="allowDrop($event)"
                      appConfigureAttribute
                      [(cartAttrStyleArr)]="cartAttrStyleArr"
                      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                      [_selectedDirectiveId]="attributeDirectiveId"
                      [_style]="style"
                      [cartAttrStyleArray]="cartAttrStyleArray?.[2]"
                    >
                      {{cartAttributesPositionMap?.[2] ? cartAttributesPositionMap?.[2]?.name : 'Attribute'}}
                      <span *ngIf=" cartAttributesPositionMap?.[2]?.id"
                        ><img
                          class="cursor-pointer"
                          (click)="onDeletingCartAttr(2)"
                          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                    </li>
                    <li
                      #cartAttributeStyles
                      class="memberTitle"
                      id="position-3"
                      (drop)="cartAttributesPositionMap?.[3] ? !(cartAttributesPositionMap?.[3]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
                      (dragover)="allowDrop($event)"
                      appConfigureAttribute
                      [(cartAttrStyleArr)]="cartAttrStyleArr"
                      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                      [_selectedDirectiveId]="attributeDirectiveId"
                      [_style]="style"
                      [cartAttrStyleArray]="cartAttrStyleArray?.[3]"
                    >
                      {{cartAttributesPositionMap?.[3] ? cartAttributesPositionMap?.[3]?.name : 'Attribute'}}
                      <span *ngIf=" cartAttributesPositionMap?.[3]?.id"
                        ><img
                          class="cursor-pointer"
                          (click)="onDeletingCartAttr(3)"
                          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                    </li>
                  </ul>
                </div>
                <div class="cart-tmplt-v4-wrfm-controls">
                  <ul>
                    <li id="button-btn-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <button class="btn btn-outline-secondary bi bi-dash" type="button"></button>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="1"
                          aria-label=" button addon"
                          aria-describedby="button-addon1"
                        />
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary bi bi-plus" type="button"></button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="memberBold">
                  <ul>
                    <li
                      #cartAttributeStyles
                      class="memberTitlebold"
                      id="position-4"
                      (drop)="cartAttributesPositionMap?.[4] ? !(cartAttributesPositionMap?.[4]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'currency')"
                      (dragover)="allowDrop($event)"
                      appConfigureAttribute
                      [(cartAttrStyleArr)]="cartAttrStyleArr"
                      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                      [_selectedDirectiveId]="attributeDirectiveId"
                      [_style]="style"
                      [cartAttrStyleArray]="cartAttrStyleArray?.[4]"
                    >
                      {{cartAttributesPositionMap?.[4] ? cartAttributesPositionMap?.[4]?.name : 'Attribute'}}
                      <span *ngIf=" cartAttributesPositionMap?.[4]?.id"
                        ><img
                          class="cursor-pointer"
                          (click)="onDeletingCartAttr(4)"
                          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                    </li>
                    <li
                      #cartAttributeStyles
                      class="memberTitlebold"
                      id="position-5"
                      (drop)="cartAttributesPositionMap?.[5] ? !(cartAttributesPositionMap?.[5]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'currency')"
                      (dragover)="allowDrop($event)"
                      appConfigureAttribute
                      [(cartAttrStyleArr)]="cartAttrStyleArr"
                      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
                      [_selectedDirectiveId]="attributeDirectiveId"
                      [_style]="style"
                      [cartAttrStyleArray]="cartAttrStyleArray?.[5]"
                    >
                      {{cartAttributesPositionMap?.[5] ? cartAttributesPositionMap?.[5]?.name : 'Attribute'}}
                      <span *ngIf=" cartAttributesPositionMap?.[5]?.id"
                        ><img
                          class="cursor-pointer"
                          (click)="onDeletingCartAttr(5)"
                          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-tmplt-v3-wrfm-bottom cart-tmplt-v4Action">
      <ul>
        <li class="cart-tmplt-v3-wrfm-list cart-tmplt-full-4" id="position-6">
          {{cartAttributesPositionMap?.[6] ? cartAttributesPositionMap?.[6]?.name : 'Estimated Total Pay'}}
        </li>
        <li class="cart-tmplt-v3-wrfm-list-7" id="position-5">
          {{cartAttributesPositionMap?.[7] ? cartAttributesPositionMap?.[7]?.name : '₹ 30,000'}}
        </li>
      </ul>
      <ul>
        <li class="cart-tmplt-v3-wrfm-list cart-tmplt-bold-btn-4" id="position-8">
          {{cartAttributesPositionMap?.[8] ? cartAttributesPositionMap?.[8]?.name : 'Make Payment'}}
        </li>
      </ul>
    </div>
  </div>

  <!-- cart -- 5 -->
  <div 
    class="cart-tmplt-v4-wrfm" 
    [ngStyle]="{
      background: backGroundColor,
      boxShadow: cartBoxShadow,
      borderColor: cartBorderColor,
      'border-radius.px': cartBorderRadius,
      'border-width.px': cartBorderWidth
    }"
    *ngIf="cartTemplateType == '4'">
    <!-- <div class="cart-tmplt-v5-header">
        <ul>
          <li class="cart-tmplt-v5-logo">
            <img src="" alt="" />
          </li>
          <li class="cart-tmplt-v5-title">
            Deliver to Govind Jarapala, Door no 305,Gachi…
          </li>
          <li class="cart-tmplt-v5-btn">
            Edit Address
          </li>
        </ul>
      </div> -->

    <div class="cart-tmplt-v5-body">
      <div class="cart-tmplt-v5-imgs">
        <ul>
          <li
            #cartAttributeStyles
            class="cart-tmplt-v5-logo"
            id="position-0"
            (drop)="cartAttributesPositionMap?.[0] ? !(cartAttributesPositionMap?.[0]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'image')"
            (dragover)="allowDrop($event)"
            appConfigureAttribute
            [(cartAttrStyleArr)]="cartAttrStyleArr"
            (emitSelectedElement)="recieveEmittedDirectiveId($event)"
            [_selectedDirectiveId]="attributeDirectiveId"
            [_style]="style"
            [cartAttrStyleArray]="cartAttrStyleArray?.[0]"
          >
            {{cartAttributesPositionMap?.[0] ? cartAttributesPositionMap?.[0]?.name : 'Attribute'}}
            <span *ngIf=" cartAttributesPositionMap?.[0]?.id"
              ><img
                class="cursor-pointer"
                (click)="onDeletingCartAttr(0)"
                [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                alt=""
            /></span>
          </li>
        </ul>
      </div>
      <div class="cart-tmplt-v5-right">
        <div class="cart-tmplt-v5-content">
          <ul>
            <li
              #cartAttributeStyles
              class="cart-tmplt-v5-list cart-tmplt-full"
              id="position-1"
              (drop)="cartAttributesPositionMap?.[1] ? !(cartAttributesPositionMap?.[1]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'any')"
              (dragover)="allowDrop($event)"
              appConfigureAttribute
              [(cartAttrStyleArr)]="cartAttrStyleArr"
              (emitSelectedElement)="recieveEmittedDirectiveId($event)"
              [_selectedDirectiveId]="attributeDirectiveId"
              [_style]="style"
              [cartAttrStyleArray]="cartAttrStyleArray?.[1]"
            >
              {{cartAttributesPositionMap?.[1] ? cartAttributesPositionMap?.[1]?.name : 'Attribute'}}
              <span *ngIf=" cartAttributesPositionMap?.[1]?.id"
                ><img
                  class="cursor-pointer"
                  (click)="onDeletingCartAttr(1)"
                  [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
            </li>
            <li
              #cartAttributeStyles
              class="cart-tmplt-v5-list cart-tmplt-bold-text"
              id="position-2"
              (drop)="cartAttributesPositionMap?.[2] ? !(cartAttributesPositionMap?.[2]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'currency')"
              (dragover)="allowDrop($event)"
              appConfigureAttribute
              [(cartAttrStyleArr)]="cartAttrStyleArr"
              (emitSelectedElement)="recieveEmittedDirectiveId($event)"
              [_selectedDirectiveId]="attributeDirectiveId"
              [_style]="style"
              [cartAttrStyleArray]="cartAttrStyleArray?.[2]"
            >
              {{cartAttributesPositionMap?.[2] ? cartAttributesPositionMap?.[2]?.name : 'Attribute'}}
              <span *ngIf=" cartAttributesPositionMap?.[2]?.id"
                ><img
                  class="cursor-pointer"
                  (click)="onDeletingCartAttr(2)"
                  [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
            </li>
            <li
              #cartAttributeStyles
              class="cart-tmplt-v5-list txt-striped"
              id="position-3"
              (drop)="cartAttributesPositionMap?.[3] ? !(cartAttributesPositionMap?.[3]?.isSelected) : enableDroppedAttributeOnTemplate($event, 'currency')"
              (dragover)="allowDrop($event)"
              appConfigureAttribute
              [(cartAttrStyleArr)]="cartAttrStyleArr"
              (emitSelectedElement)="recieveEmittedDirectiveId($event)"
              [_selectedDirectiveId]="attributeDirectiveId"
              [_style]="style"
              [cartAttrStyleArray]="cartAttrStyleArray?.[3]"
            >
              {{cartAttributesPositionMap?.[3] ? cartAttributesPositionMap?.[3]?.name : 'Attribute'}}
              <span *ngIf=" cartAttributesPositionMap?.[3]?.id"
                ><img
                  class="cursor-pointer"
                  (click)="onDeletingCartAttr(3)"
                  [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                  alt=""
              /></span>
            </li>
          </ul>
        </div>
        <div class="cart-tmplt-v5-controls">
          <ul>
            <li>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <button class="btn btn-outline-secondary bi bi-dash" type="button"></button>
                </div>
                <input type="number" class="form-control" placeholder="" value="01" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary bi bi-plus" type="button"></button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="cart-tmplt-v5-bottom cart-tmplt-v5Action">
      <div class="cart-tmplt-v5-content-bottom">
        <ul>
          <li class="cart-tmplt-v5-list cart-tmplt-total-price" id="position-4">
            {{cartAttributesPositionMap?.[4] ? cartAttributesPositionMap?.[4]?.name : 'Total Amount'}}
          </li>
          <li class="cart-tmplt-v5-list cart-tmplt-price-display" id="position-5">
            {{cartAttributesPositionMap?.[5] ? cartAttributesPositionMap?.[5]?.name : ' ₹ 375.00'}}
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <li class="cart-tmplt-v4-wrfm-list cart-tmplt-bold-btn-5" id="position-6">
            {{cartAttributesPositionMap?.[6] ? cartAttributesPositionMap?.[6]?.name : 'Proceed To Pay'}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div>
  <p class="productprice-condition" *ngIf="tabSelected == 'cart'">
    <strong>NOTE: </strong>*To get 'totalprice' drop only price of the product at Price attribute
  </p>
</div>
