import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, TranslatorService } from '@common-services';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ImageZoomInoutComponent } from '../image-zoom-inout/image-zoom-inout.component';

@Component({
  selector: 'app-image',
  template: `
    <div class="d-flex justify-content-between nh-column-gap-10">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          ><span class="main-label-inner">
            <span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          >
        </label>
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <div
            class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10"
            *ngIf="
              field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
              (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
            "
          >
            <mat-icon
              *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
              matTooltip="Sub-Transactional CU"
              matSuffix
              class="d-flex align-items-center justify-content-center"
              ><span class="sub-cu" (click)="openSubPopUp()"
                ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
              /></span>
            </mat-icon>
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              (click)="addAttribute.next()"
              >add</mat-icon
            >
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              (click)="removeAttribute.next()"
              >remove</mat-icon
            >
          </div>
    </div>
    <p
      class="top-text"
      *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <div
      class="image-type presented-img presented-img-auto img-type"
      *ngIf="imgUrls?.length > 0 && field.inputType === 'image'; else datanotfound"
      [ngClass]="{ 'mini-popup': (minimumImages == field?.value?.length && field?.attribute?.['isTableConfig']) }"
    >
      <div
        class="image-block"
        *ngFor="let img of (field?.attribute?.['isTableConfig'] && field?.value?.length > 3) ? (imgUrls | slice: 0:minimumImages) : imgUrls ; let i = index"
        [ngClass]="{ 'mini-popup': (minimumImages == field?.value?.length && field?.attribute?.['isTableConfig']) }"
      >
        <div *ngIf="canDownload">
          <a (click)="downloadFile(i)" title="downloadFile">
            <span class="material-icons ml-3">
              cloud_download
            </span>
          </a>
        </div>
        <section
          class="example-section select-images"
          *ngIf="field?.configuration?.imgConfig?.imgAsChip; else notImgAsChip"
        >
          <mat-checkbox (change)="updateSelectedImages($event, i)" class="example-margin"
            ><img [src]="img" [ngClass]="field?.configuration?.imgConfig?.imgSize" (click)="openDialog()"
          /></mat-checkbox>
        </section>
        <ng-template #notImgAsChip>
          <div class="card flex justify-content-center imgcontrol">
          <img [src]="img" (click)="openingZoom(img)" />
          </div>
        </ng-template>
      </div>
      <div
        class="view-more"
        *ngIf="field?.attribute?.['isTableConfig'] && field?.value?.length > 3 && !showAllImages"
        (click)="minimumImages = field?.value?.length; showAllImages = true"
      >
        <span>View<br />More</span>
      </div>
      <div
        class="view-more"
        *ngIf="field?.attribute?.['isTableConfig'] && field?.value?.length > 3 && showAllImages"
        (click)="minimumImages = 3; showAllImages = false"
      >
        <span>View<br />less</span>
      </div>
    </div>
    <div *ngIf="field.inputType === 'imageavatar'">
      <div class="image-type presented-img presented-img-auto img-type">
        <img class="avater-image" *ngIf="imgUrls[0]" [src]="imgUrls[0]" alt="" />
        <img class="avater-image" *ngIf="!imgUrls[0]" src="../../../assets/images/ui-controls/avatar.svg" alt="" />

      </div>
    </div>
    <ng-template #datanotfound>
      <p class="notFound" *ngIf="field.inputType != 'imageavatar'">{{ labels?.No_Image_Found }}</p>
    </ng-template>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
  `,
  styles: [
    `
      img {
        width: 100%;
      }
      .main-label {
        color: #999999;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 400;
      }
      .select-images {
        .small1 {
          max-width: 64px;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
        .small2 {
          max-width: 102px;
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }
        .medium1 {
          max-width: 112px;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
        .medium2 {
          max-width: 174px;
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }
      }
      ::ng-deep .select-images .mat-checkbox-inner-container {
        margin-right: 10px;
      }
      .mini-popup {
        background: #fff;
        width: 100%;
        max-height: 125px;
        border: 1px solid #fafafa;
        border-radius: 10px;
        top: 0;
        box-shadow: 0px 3px 6px #00000029;
      }
      ::ng-deep .openImageZoom {
        max-width: 100vw !important;
        width: 100%;
        height: 100%;
        .mat-dialog-container {
          background: transparent;
          padding: 0;
          overflow: inherit;
        }
      }
    `,
  ],
})
export class ImageComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  count: number;
  labels: any;
  imgUrls: any = [];
  src: any;
  canDownload: boolean = false;
  private ngUnsubscribe = new Subject();
  selectedImages: any = [];
  isShowDivIf: boolean = false;
  minimumImages: number = 3;
  showAllImages: boolean = false;

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private http: HttpClient,
    private transactionFacadeService: TransactionFacadeService
  ) {
    this.detectLanguageChange();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      data: this.imgUrls,
      panelClass: ['nh-imageModal'],
    });
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field?.canDownload) {
      this.canDownload = this.field?.canDownload;
    }
    /* istanbul ignore next */
    if (this.field?.value?.length > 2) {
      this.count = this.field.value.length - 2;
    }
    this.imgUrls = [];
    /* istanbul ignore next */
    if (this.field && this.field.value) {
      this.field?.value?.forEach((obj: any, ind: any) => {
        /* istanbul ignore next */
        if (this.IsJsonString(obj)) {
          let jsonObj: any = JSON.parse(obj);
          /* istanbul ignore next */
          if (jsonObj?.contentUrl) {
            this.imgUrls[ind] = jsonObj?.contentUrl;
            this.src = {
              mimeType: jsonObj?.mimeType,
              contentUrl: jsonObj?.contentUrl,
            };
          }
        } else {
          this.imgUrls[ind] = obj;
        }
      });
    }
  }

  IsJsonString(str: any) {
    /* istanbul ignore next */
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  downloadFile(index: number) {
    if (this.src?.contentUrl) {
      this.src.contentUrl = this.imgUrls[index];
      this.transactionFacadeService.downloadFile(this.src?.contentUrl);
    } else {
      this.transactionFacadeService.downloadFile(this.imgUrls[index]);
    }
  }

  updateSelectedImages(event: any, index: number) {
    /* istanbul ignore next */
    if (event?.checked) {
      this.selectedImages?.push(index);
    } else {
      let delIndex = this.selectedImages?.findIndex((ele: any) => ele === index);
      this.selectedImages?.splice(delIndex, 1);
    }
    this.selectedImages?.sort();
    this.transactionFacadeService.sendCurrentSelectedImages(this.selectedImages);
  }

  openingZoom(img: any) {
    let src: any = {
      contentUrl: img,
    };
    this.dialog.open(ImageZoomInoutComponent, {
      panelClass: 'openImageZoom',
      data: { file: src, field: null },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
