<!-- <Button class="btn btn-info" (click)="openFolderComponent()">Upload File</Button> -->
<!-- <img
  class="img-upload"
  [src]="'../../../assets/images/upload-folder.svg' | CDNUrlRewritePipe"
  alt="upload"
  (click)="openFolderComponent()"
/>
<div class="">{{ field.attribute.displayName }}</div> -->

<div>
  <div>
    <div class="d-flex justify-content-between nh-column-gap-10">
      <div class="d-flex nh-mw-0">
        <label
          pTooltip="{{ field.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          class="main-label"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
        >
          <span class="main-label-inner">
            <span class="main-label-text text-truncate">{{ field.attribute.displayName }} :</span></span
          >
        </label>
      </div>
    </div>
    <div>
      <figure class="thumbnail-icon">
        <img
        class=""
        [src]="'../../../assets/images/ui-controls/icon-file-irdr.svg' | CDNUrlRewritePipe"
        alt="upload"
        (click)="openFolderComponent()"
        />
      </figure>
      
    </div>
  </div>
</div>
