import { entityMapper } from '../entity/entity-mapper';
import { solutionLogicMapper } from '../solution-logic/solutionLogic-mapper';
import { compositeExtractionMapper } from '../composite-extration/compositeExtraction-mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function gsiMapper(data: any, level: number = 0) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'dsdId',
      id: 'dsdId',
      tfId: 'id',
      enableBot: 'enableBot',
      intents: 'intents',
      finalResponse: 'finalResponse',
      solutionLogic: 'solutionLogic',
      nextTriggerSet: 'nextTriggerSet',
      dcd: 'dcd',
      description: 'description',
      exceptionCUList: 'exceptionCUList',
      entityDesignRights: 'entityDesignRights',
      entityTransactionRights: 'entityTransactionRights',
      attributeDesignRights: 'attributeDesignRights',
      attributeTransactionRights: 'attributeTransactionRights',
      allowPreviouCUView: 'allowPreviouCUView',
      isNavigableCu: 'isNavigableCu',
      eventCUList: 'eventCUList',
      mindCUList: 'mindCUList',
      cuType: 'cuType',
      agents: 'agents',
      layers: 'layers',
      gsiList: 'gsiList',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      dsdStatus: 'dsdStatus',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      name: 'name',
      displayName: 'displayName',
      ontology: 'ontology',
      attachments: 'attachments',
      keywords: 'keywords',
      author: 'author',
      ownerId: 'ownerId',
      version: 'version',
      finishType: 'finishType',
      isDld: 'isDld',
      masterId: 'masterId',
      mileStoneId: 'mileStoneId',
      constraint: 'constraint',
      constraintName: 'constraintName',
      specialFeatureProperties: 'specialFeatureProperties',
      measures: 'measures',
      /* used for embedded gsi */
      referencedChangeUnit: 'dsdReferencedChangeUnit',
      tfReferencedChangeUnit: 'referencedChangeUnit',
      index: 'index',
      /* ./used for embedded gsi */
      isNameUpdated: 'isNameUpdated',
      source: 'source',
      advancedProperties: 'advancedProperties',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      contextualId: 'contextualId',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      dsdMetadataId: 'dsdMetadataId',
      sentenceTags: 'querySentence',
      isParallel: 'isParallel',
      condition: 'condition',
      conditionName: 'conditionName',
      isEmbedded: 'isEmbedded',
      isExceptionSkippable: 'isExceptionSkippable',
      isCancellable: 'isCancellable',
      listRundownErrorInfo: 'listRundownErrorInfo',
      cdecCuMappings: 'cdecCuMappings',
      txnDataSaveMode: 'txnDataSaveMode',
      isGSILayerBasedRecursion: 'isGSILayerBasedRecursion',
      preRecursiveConditionCheck: 'preRecursiveConditionCheck',
      listRecursionContextualID: 'listRecursionContextualID',
      listRecursionContextualName: 'listRecursionContextualName',
      transactionEndpoint: 'transactionEndpoint',
      isTransactionalGSI: 'isTransactionalGSI',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          if (val?.length > 0) {
            /* istanbul ignore next */
            return val?.map((x: any) => {
              /* istanbul ignore next */
              if (x?.cuType === /*ChangeUnitTypes.GSI*/ 'GSI') {
                let y = gsiMapper({ ...x, isGSIType: true }, level + 1);
                /* istanbul ignore next */
                if (x?.condition) {
                  //In allternate embedded GSI scenario condition is present
                  y = {
                    ...y,
                    condition: x.condition,
                    conditionName: x.conditionName,
                  };
                  return y;
                }
                return y;
              } else {
                return x?.DATA ? solutionLogicMapper(x?.DATA) : solutionLogicMapper(x);
              }
            });
          } else {
            return [];
          }
        },
        on: 'solutionLogic',
      },
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'layers',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/

          if (val !== undefined && typeof val === 'string') {
            return compositeExtractionMapper(JSON.parse(val));
          } else {
            return val;
          }
        },
        on: 'sentenceTags',
      },
      {
        run: function (val: any) {
          return val ? val : [];
        },
        on: 'designTimeRights',
      },
      {
        run: function (val: any) {
          return val ? val : [];
        },
        on: 'txnTimeRights',
      },
      // used for embedded gsi
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            /* istanbul ignore next */
            if (data?.cuType === /*ChangeUnitTypes.GSI*/ 'GSI') {
              return {
                index: x.index,
                nextCUName: x.nextCUName,
                nextCUId: x.dsdNextCUId,
                isParallel: x?.isParallel,
              };
            } else {
              return x;
            }
          });
        },
        on: 'nextTriggerSet',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/

          if (val !== undefined && typeof val === 'string') {
            return JSON.parse(val);
          } else {
            return val;
          }
        },
        on: 'sentenceTags',
      },
    ],
  };

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return {
              /* istanbul ignore next */
              ...entityMapper(x?.item?.DATA),
              changeDriverMetaData: x.changeDriverMetaData,
              isMultiValue: x.isMultiValue,
              isPlaceholder: x.isPlaceholder,
              conditionalPotentiality: x?.conditionalPotentiality,
              conditionalPotentialityName: x?.conditionalPotentialityName,
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };

  const result = transform(data, map);
  // to know the level in which the GSI is present, useful for nested embeded GSI
  result.level = level + 1;
  return result;
}
