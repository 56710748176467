import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
})
export class SchedulerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
