import { ElementRef, Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService, LoaderService } from "@common-services";
import { Subject, BehaviorSubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BookPageCustomisationEndpointService } from "./book-page-customisation-endpoint.service";


@Injectable({
  providedIn: 'root',
})
export class BookPageCustomisationFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  shelfDataSubject = new Subject();
  shelfData$ = this.shelfDataSubject.asObservable();

  private saveBookPageSubject = new Subject();
  saveBookPage$ = this.saveBookPageSubject.asObservable();

  mccPageCustomizationBooksSubject = new Subject();
  mccPageCustomizationBooksSubject$ = this.mccPageCustomizationBooksSubject.asObservable();

  deleteShelfDataSubject = new Subject();
  deleteShelfData$ = this.deleteShelfDataSubject.asObservable();

  duplicateShelfDataSubject = new Subject();
  duplicateShelfData$ = this.duplicateShelfDataSubject.asObservable();

  private gsiDataSubject = new Subject();
  gsiData$ = this.gsiDataSubject.asObservable();

  highlightShelfSubject = new BehaviorSubject(null);
  highlightShelf$ = this.highlightShelfSubject.asObservable();

  hoverColoursSubject = new BehaviorSubject(null);
  hoverColours$ = this.hoverColoursSubject.asObservable();

  constructor(
    private bookPageCustomisationEndpointService: BookPageCustomisationEndpointService,
    private alertService: AlertService,
    private loader: LoaderService,
    private router: Router
  ) {}

  setShelfData(data: any, elref: ElementRef) {
    this.shelfDataSubject.next(data);
    this.hoverColoursSubject.next(elref);
  }

  setGSIData(data: any) {
    this.gsiDataSubject.next(data);
  }

  setHighlightData(data: any) {
    this.highlightShelfSubject.next(data);
  }

  onDeleteShelf(data: any) {
    this.deleteShelfDataSubject.next(data);
  }

  onDuplicateShelf(data: any) {
    this.duplicateShelfDataSubject.next(data);
  }

  saveBookPage(data: any) {
    this.loader.show();
    this.bookPageCustomisationEndpointService
      .saveBookPage(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res) {
            this.saveBookPageSubject.next(res);
            this.alertService.showToaster(res.message, '', 'success');
            this.router.navigateByUrl(`manage-mcc/book-page-customisation`);
          }
        },
        (error: any) => {
          this.loader.hide();
        }
      );
  }

  mccPageCustomizationBooks(
    pageNumber: number,
    pageSize: number,
    searchString: string,
    roleid: number,
    isActive?: boolean
  ) {
    this.loader.show();
    this.bookPageCustomisationEndpointService
      .getMCCPageCustomizationBooks(pageNumber, pageSize, searchString, roleid, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.mccPageCustomizationBooksSubject.next(res);
        } else {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  getBookPage(bookId: any, roleId: any) {
    return this.bookPageCustomisationEndpointService.getBookPage(bookId, roleId);
  }

  deleteBookPage(bookId: any) {
    return this.bookPageCustomisationEndpointService.deleteBookPage(bookId);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  detectScreenSize(): string {
    let screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 'desktop';
    } else if (screenWidth >= 768) {
      return 'tab';
    } else {
      return 'mobile';
    }
  }
}
