import { Component, Input, OnInit } from '@angular/core';
import { EntityBoardEndpointService, AlertService, getUserInfo } from '@common-services';

import { Observable, Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Star, AddComment, RatingStats, RatingPercentages, SortOptionsReviews,formatDate } from '../../../constants/customize-filter-templates.constants';

@Component({
  selector: 'app-comments-and-ratings',
  templateUrl: './comments-and-ratings.component.html',
  styleUrls: ['./comments-and-ratings.component.scss'],
})
export class CommentsAndRatingsComponent {
  @Input() isView: boolean;
  review: string;
  //TODO:
  stars: Star[] = [{ width: '0' }, { width: '0' }, { width: '0' }, { width: '0' }, { width: '0' }];
  globalRatingStars: Star[] = [{ width: '1' }, { width: '1' }, { width: '1' }, { width: '1' }, { width: '1' }];
  ngSubscribe = new Subject();

  recordId: number;
  commentsAndRatings: AddComment[];
  lastEvaluatedKeyString: string = '';
  ratingStats: RatingStats;
  ratingPercentages: RatingPercentages;
  showPagination: boolean;
  @Input() set _recordId(value: number) {
    this.recordId = value;
    this.getFilteredComments(
      true,
      false,
      this.selectedSortOption.actualValue,
      this.selectedSortOption.sortOrder,
      this.keyword,
      ''
    );
    this.getAllComments();
    this.getCurrentUserRatings();
  }

  generalEntityId: number;
  @Input() set _generalEntityId(value: number) {
    this.generalEntityId = value;
  }

  logo: string[] = [];
  selectedRating: string = '';

  set _selectedRating(value: string) {
    this.selectedRating = value === this.selectedRating ? '' : value;
    this.getAllComments();
  }

  sortDropDownOptions: SortOptionsReviews[] = [
    { displayValue: 'Recent Ratings', actualValue: 'updatedAt', sortOrder: 'desc' },
    { displayValue: 'Older Ratings', actualValue: 'updatedAt', sortOrder: 'asc' },
    { displayValue: 'Rating High To Low', actualValue: 'productRating', sortOrder: 'desc' },
    { displayValue: 'Rating Low To High', actualValue: 'productRating', sortOrder: 'asc' },
  ];

  selectedSortOption: SortOptionsReviews = {
    displayValue: 'Recent Ratings',
    actualValue: 'updatedAt',
    sortOrder: 'desc',
  };
  keyword: string = '';
  showUserReview: boolean = false;
  keyWordSubject: Subject<string> = new Subject<string>();
  keyWordSubject$: Observable<string> = this.keyWordSubject.asObservable();

  constructor(private entityBoardService: EntityBoardEndpointService, private alertService: AlertService) {
    this.keyWordSubscription();
  }

  addCommentToTheUser() {
    const payload: AddComment = {
      recordId: this.recordId,
      userName: getUserInfo('name'),
      productRating: this.findTheRatingFromStar(),
      reviewDescription: this.review,
      featureRatings: [],
      updatedAt: new Date().toString(),
      imageURL: getUserInfo('userLogo'),
    };
    this.entityBoardService
      .addCommentToTheUser(payload, this.generalEntityId)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res && res.result && res.status === 200) {
          this.alertService.showToaster(res.message, '', 'success');
          this.showUserReview = false;
          this.getAllComments();
          this.getFilteredComments(
            true,
            false,
            this.selectedSortOption.actualValue,
            this.selectedSortOption.sortOrder,
            this.keyword,
            ''
          );
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }

  private findTheRatingFromStar(): number {
    let rating: number = 0;
    this.stars.forEach((star: Star) => {
      rating += parseInt(star.width);
    });

    return rating;
  }

  findAndSetRating(event: any, star: number): void {
    this.stars.fill({ width: '1' }, 0, star);
    this.stars.fill({ width: '0' }, star, 5);
    const rectangle = event.target.getClientRects();
    const percentage = (event.clientX - rectangle[0].left) / rectangle[0].width;
    // this.stars[star] = {width: percentage.toFixed(2)}; // Uncomment for the irregular Widths.
    this.stars[star] = { width: '1' };
  }

  ngOnDestroy() {
    this.ngSubscribe.next();
  }

  getAllComments() {
    this.lastEvaluatedKeyString = '';
    this.getFilteredComments(
      false,
      false,
      this.selectedSortOption.actualValue,
      this.selectedSortOption.sortOrder,
      this.keyword,
      this.selectedRating
    );
  }

  getFilteredComments(
    summary: boolean,
    isAppendComments: boolean,
    sortBy: string,
    orderBy: string,
    keyword: string,
    rating: string
  ) {
    this.entityBoardService
      .getCommentToTheUser(
        this.recordId,
        this.generalEntityId,
        this.lastEvaluatedKeyString,
        summary,
        sortBy,
        orderBy,
        keyword,
        rating
      )
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res && res?.result && res?.status === 200 && !summary) {
          this.addCommentsFromApi(res, isAppendComments);
        } else if (res && res?.result && res?.status === 200 && summary) {
          this.updateStastics(res?.result);
        }
      });
  }

  private getCurrentUserRatings() {
    this.entityBoardService
      .getCurrentUserRatings(this.recordId, this.generalEntityId)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res && res?.result && res?.status === 200) {
          this.updateUserCurrentComment(res?.result);
        }
      });
  }

  private updateUserCurrentComment(result: AddComment) {
    this.setRating(result?.productRating ? result?.productRating : 0, this.stars);
    this.review = result?.reviewDescription ? result?.reviewDescription : '';
  }
  private addCommentsFromApi(res: any, isAppendComments: boolean) {
    this.lastEvaluatedKeyString = res?.result?.lastEvaluatedKeyString ? res?.result?.lastEvaluatedKeyString : '';
    this.updateCommentsAndRatings(isAppendComments, res?.result?.ratingDtoList);
    this.logo = [];
    this.commentsAndRatings.forEach((comment: AddComment) => {
      comment.updatedAt = formatDate(comment.updatedAt);
      this.setLogo(comment.userName);
    });
  }

  private updateCommentsAndRatings(isAppendComments: boolean, additionalComments: AddComment[]) {
    this.showPagination = additionalComments.length == 10;
    if (isAppendComments) {
      this.commentsAndRatings.push(...additionalComments);
    } else {
      this.commentsAndRatings = additionalComments;
    }
  }

  private setLogo(userName: string) {
    const splitArray: string[] = userName.split(' ');
    this.logo.push(
      splitArray[0][0].toLocaleUpperCase() + (splitArray.length > 1)
        ? ' ' + splitArray[splitArray.length - 1][0].toLocaleUpperCase()
        : ''
    );
  }

  updateStastics(stats: RatingStats) {
    this.ratingStats = stats;
    this.setRating(stats?.averageProductRating, this.globalRatingStars);
    this.ratingPercentages = {
      oneStarPercentage:
        (((stats?.oneStarCount ? stats?.oneStarCount : 0) * 100) / (stats?.userCount ? stats?.userCount : 1)).toFixed(
          2
        ) + '%',
      twoStarPercentage:
        (((stats?.twoStarCount ? stats?.twoStarCount : 0) * 100) / (stats?.userCount ? stats?.userCount : 1)).toFixed(
          2
        ) + '%',
      threeStarPercentage:
        (
          ((stats?.threeStarCount ? stats?.threeStarCount : 0) * 100) /
          (stats?.userCount ? stats?.userCount : 1)
        ).toFixed(2) + '%',
      fourStarPercentage:
        (((stats?.fourStarCount ? stats?.fourStarCount : 0) * 100) / (stats?.userCount ? stats?.userCount : 1)).toFixed(
          2
        ) + '%',
      fiveStarPercentage:
        (((stats?.fiveStarCount ? stats?.fiveStarCount : 0) * 100) / (stats?.userCount ? stats?.userCount : 1)).toFixed(
          2
        ) + '%',
    };
  }

  private setRating(rating: number, stars: Star[]) {
    const floor = Math.floor(rating);
    const decimal = rating - floor;
    stars.fill({ width: '1' }, 0, floor);
    stars.fill({ width: '0' }, floor, 5);
    if (floor != 5) {
      stars[floor] = { width: decimal.toFixed(2) };
    }
  }

  addAdditionalReviews() {
    this.getFilteredComments(
      false,
      true,
      this.selectedSortOption.actualValue,
      this.selectedSortOption.sortOrder,
      this.keyword,
      this.selectedRating
    );
  }

  toggleUserReview(showUserReview: boolean) {
    this.showUserReview = showUserReview;
  }

  keyWordSubscription() {
    this.keyWordSubject$.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value: string) => {
      this.keyword = value;
      this.getAllComments();
    });
  }
}
