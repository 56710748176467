import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DicomIntegrationComponent } from './dicom-integration/dicom-integration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { NslDicomOperationModule } from '../nsl-dicom-operation/nsl-dicom-operation.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    DicomIntegrationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    TooltipModule,
    NslDicomOperationModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    DicomIntegrationComponent
  ]
})
export class DicomIntegrationModule { }
