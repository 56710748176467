import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AlertService,
  Dcd,
  GsiFacadeService,
  NodeGsiFacadeService,
  TranslatorService,
  checkDataTypeInCondition, checkInputField,
  getCurrentUserName,
  getEstimatedTime,
  getObjectByValue,
  getselectedestimatedTime,isReservedCU,
  onlyTemplateOrNormalCU
} from '@common-services';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-node-cu-edit',
  templateUrl: './node-cu-edit.component.html',
  styleUrls: ['./node-cu-edit.component.scss'],
})
export class NodeCuEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tempGsiObj: any;
  @Input() configurationData: any;
  @Input() sentence: any;
  @Output() closecu: EventEmitter<any> = new EventEmitter<any>();

  close: boolean = false;
  enableConditionBtn: boolean = false;
  labels: any = { description: 'Description text' };
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  labelForInformationLayer = { name: 'information Layer', label: 'information Layer' };
  labelForTriggerCESLayer = { name: 'triggerCES Layer', label: 'triggerCES Layer' };
  informationLayer: any = [];
  physicalLayer: any = [];
  triggerCESLayer: any = [];
  showSubCategory: boolean = false;
  selectedChangeUnit: any;
  operators: any = ['<', '>', '==', '!=', '<=', '>=','IN POTENTIALITY', 'IN REALITY'];
  alternateOperator: any;
  recursiveOperator: any;
  constraintName: any;
  recursiveAttrType: any;
  constraint: any;
  changeUnit: any;
  conditionValue: any;
  formulaId: any;
  formulaWithId: any;
  desc: string;
  attributeValue: any;
  AlternateExpressionValue: string;
  AlternatexpressionValueWithId: string;
  roles: any = [];
  selectedindex: any;
  nestedOperator: any;
  nestedFormulaId: any;
  nestedFormulaWithId: any;
  nestedAttrValue: any;
  currentNestedIndex: number = -1;
  cuAttributeValue: any;
  currentOperator: any;
  fromDateModel: any;
  toDateModel: any;
  expressionValue: string;
  potentialityAttr: any;
  tCUConditionalPotentialityNames: any = [];
  tCUConditionalPotentiality: any = [];
  recursiveCU: boolean = false;
  editAlternateCUFlag: boolean = false;
  selectedAltCU: any;
  alternateCUs: any = [];
  isSOPEnabled: boolean = false;
  isRecursiveFlag: any = false;
  formulaString: any = '';
  formulaStringArray: any = [];
  isAlternateFlag: any = false;
  currenttagTokenCU: any;
  step: number = 0;
  nestedAttrType: string = '';
  listOfDCDs: any = [];
  isNestedSave: boolean = false;
  countListOfDCDs: number = 0;
  private ngUnsubscribe = new Subject();
  currentAttrType: any;
  attributeType: any;
  currentUielement: any;
  selected: any;
  ontologyList: any;
  orgOntologyList: any;
  metaInfo: any = { ontology: [], description: '', author: {}, keywords: '' };
  lastChild: any;
  attrTag: any;
  inputValue1: any;
  inputValue2: any;
  isNested: boolean = false;
  nestedFlag: boolean = false;
  estimatedTime: Date = new Date(null, null, null, 0, 0, 0);
  disableSTEs = false;
  closeAdvancedSettings: boolean = false;
  agentType: any = 'human';
  isTemplate:boolean = false;
  constructor(
    private changeunitFacade: NodeChangeUnitFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private translator: TranslatorService,
    private alertService: AlertService
  ) {
    this.getDCDStream();
    this.detectOfAttributeStream();
    this.detectNestedAttributeStream();
    this.detectLanguageChange();
    this.getCuConfigurationInfo();
    this.detectActionLabel();
    this.detectMasterOntologies();
    this.getCuDetails();
  }

  ngOnInit(): void {
    console.log(this.changeUnit?.mindCUList, 'mindCulist');
    this.metaInfo = { ...this.metaInfo, author: { name: getCurrentUserName() }, isEdit: true };
    this.fetchOntologies();
    let id: any;
    if (this.configurationData?.data?.isIndividualCu) {
      id = this.configurationData?.data?.cu?.id;
    } else {
      id = this.configurationData?.data?.referencedChangeUnit;
    }
    console.log(this.configurationData);
    if (id) {
      this.changeunitFacade.fetchCuDetailsById(id);
      this.changeunitFacade.getActionLabelData(id);
    }
    this.getLayersData();
    let referencedChangeUnit = '';
    /* istanbul ignore else*/
    if (this.configurationData) {
      if (id) {
        referencedChangeUnit = id;
      } else if (this.configurationData?.data?.id) {
        /* istanbul ignore next*/
        referencedChangeUnit = this.configurationData?.data?.id;
      }
      /* istanbul ignore next*/
      let foundCu: any;
      /* istanbul ignore next*/
      if (this.configurationData?.data?.isSeperateCU) {
        foundCu = this.configurationData?.data;
      } else if (this.configurationData?.label === 'NE') {
        foundCu = this.configurationData?.data?.nestedCU;
      } else if (this.configurationData?.data?.isIndividualCu) {
        foundCu = this.configurationData?.data;
      } else {
        foundCu = {
          ...this?.tempGsiObj?.solutionLogic?.find((x: any) => x.referencedChangeUnit == referencedChangeUnit),
        };
        foundCu.layers = this.configurationData?.data?.layers;
      }
      /* istanbul ignore else*/
      if (foundCu) {
        this.disableSTEs = foundCu?.disableSTEs;
        /* istanbul ignore next*/
        if (foundCu.nextTriggerSet?.length > 1) {
          foundCu.isAlternateFlag = true;
          foundCu.cuType = 'alternate';
        } else {
          foundCu.isAlternateFlag = false;
          foundCu.cuType = '';
        }
        if (!foundCu?.mindCUList || foundCu?.mindCulist?.length == 0) {
          foundCu.mindCUList = this.changeUnit?.mindCulist;
        }
        this.changeUnit = foundCu;
        this.attrTag = foundCu;
        this.getEstimatedTimeOnCuEdit();
        /* istanbul ignore next*/
        if (this.changeUnit?.constraint) {
          this.recursiveCU = true;
        }
        /* istanbul ignore next*/
        foundCu?.designTimeRights?.forEach((role: any) => {
          this.roles.push({
            name: role.name ? role.name : role.rightHolderName,
            id: role.rightHolderId,
            rightHolderId: role.rightHolderId,
          });
        });
      } else if (this.configurationData?.data?.isNested) {
        this.isNested = this.configurationData?.data?.isNested;
        this.changeUnit = this.configurationData?.data;
        foundCu = this.changeUnit;
        this.attrTag = foundCu;
      }
      /* istanbul ignore next*/
      if (this.changeUnit?.tCUConditionalPotentialityNames?.length > 0) {
        /* istanbul ignore next*/
        this.tCUConditionalPotentialityNames = this.changeUnit?.tCUConditionalPotentialityNames;
      }
      /* istanbul ignore next*/
      if (this.configurationData?.data?.cuLayers?.length == 0 || this.configurationData?.data?.cuLayers?.length > 0) {
        /* istanbul ignore next*/
        this.configurationData.data.cuLayers = foundCu?.layers;
        if (!foundCu?.mindCUList || foundCu?.mindCulist?.length == 0) {
          foundCu.mindCUList = this.changeUnit?.mindCulist;
        }
        this.changeUnit = foundCu;
        this.changeUnit.tCUConditionalPotentialityNames = [];
        this.changeUnit.tCUConditionalPotentiality = [];
      }
      /* istanbul ignore next*/
      if (foundCu?.dcd) {
        this.formulaStringArray = [];
        this.generateDCDSourceArray();
        this.configurationData.data.dcd = foundCu.dcd;
      }
      this.detectDCDChange();
      this.getDesignRightsIndex(foundCu.designTimeRights);
      this.getConditionRights(foundCu);
      this.getTxnRightsIndex(foundCu.txnTimeRights);
      this.getLayersData(foundCu);
      this.detectDCDChange();
      this.getTagTokenForCu();
      /* istanbul ignore next*/
      if (this.configurationData?.openConfigPanelForAgent) {
        this.setStep(1);
      }
      if (this.changeUnit && this.changeUnit?.agents && this.changeUnit?.agents?.length > 0) {
        this.agentType = this.changeUnit.agents[0].agentType;
      }
    }
  }
  ngOnChanges() {
    setTimeout(() => {
      this.detectMetaInfo();
    }, 1500);
  }

  detectActiveGsi() {
    this.gsiFacade.activeGsi$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.tempGsiObj = data;
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getDesignRightsIndex(role: any) {
    /* istanbul ignore next*/
    if (this.changeUnit?.designTimeRights && this.changeUnit?.designTimeRights?.length > 0 && role) {
      return this.changeUnit.designTimeRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.id === role.id
      );
    }
    return -1;
  }
  getConditionRights(cu: any) {
    /* istanbul ignore next*/
    if (cu?.designTimeRights[0]?.condition) {
      this.inputValue1 = cu.designTimeRights[0].condition?.fromDateTime;
      this.inputValue2 = cu.designTimeRights[0].condition?.toDateTime;
      this.enableConditionBtn = true;
    }
  }
  getTxnRightsIndex(role: any) {
    /* istanbul ignore next*/
    if (this.changeUnit?.txnTimeRights && this.changeUnit?.txnTimeRights?.length > 0 && role) {
      return this.changeUnit.txnTimeRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.id === role.id
      );
    }
    return -1;
  }
  fetchOntologies() {
    this.gsiFacade.fetchOntologies();
  }
  detectMasterOntologies() {
    this.gsiFacade.populateOntologies$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.ontologyList = [res];

        this.orgOntologyList = [res];
      }
    });
  }
  onClearClick(item: any) {
    item.searchText = '';
  }
  onChangeOntology(item: any, index: number) {
    /* istanbul ignore next */
    if (item) {
      this.lastChild = item;
      this.metaInfo = {
        ...this.metaInfo,

        ontology: [{ id: this.lastChild?.id }],
      };
    } else {
      this.lastChild = null;
      this.metaInfo = { ...this.metaInfo, ontology: [] };
    }
    this.ontologyList.splice(index + 1, this.ontologyList.length);
    this.changeUnit.ontology = this.metaInfo?.ontology;
    this.changeUnit.author = this.metaInfo?.author;
    this.changeUnit.description = this.metaInfo?.description;
    this.changeUnit.keywords =
      this.metaInfo?.keywords instanceof Array ? this.metaInfo?.keywords : this.metaInfo?.keywords?.split(',');
  }
  showRights(i: any) {
    this.selectedindex = i;
  }
  autoCheckRights(rights: any) {
    /* istanbul ignore else*/
    if ((rights && rights.decisionRight) || rights.executionRight) {
      rights.informationRight = true;
    }
  }
  actionSelected() {
    this.changeUnit = { ...this.changeUnit, actionLabel: this.selected };
    this.alertService.showToaster('actionLabel saved sucessfully', '', 'success');
    // this.changeunitFacade.saveDraftCu(this.changeUnit);
  }

  getDCDStream() {
    this.changeunitFacade.dcd$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res && res.formulaId && res?.cuType === 'recursive') {
        /* istanbul ignore else*/
        if (this.recursiveCU) {
          this.constraintName = res.formulaId;
          this.constraint = res.formulaId;
          this.currentAttrType = res?.attributeType;
          this.recursiveAttrType = res.attrType;
        }
      } else if (res && res.formulaId && res.cuType === 'conditionalPotentiality') {
        /* istanbul ignore next*/
        this.potentialityAttr = res.formulaId;
        this.attributeType = res.attributeType;
        /* istanbul ignore next*/
        this.currentUielement = res?.attributeuiElement;
      }
    });
  }

  detectOfAttributeStream() {
    this.changeunitFacade.currentAttributeForAlternateCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else*/
      if (res) {
        this.formulaId = res.formulaId;
        this.formulaWithId = res.formulaIdWithId;
      }
    });
  }
  detectNestedAttributeStream() {
    this.changeunitFacade.currentAttributeForNestedCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else*/
      if (res) {
        /* istanbul ignore next */
        this.nestedFormulaId = res?.formulaId;
        /* istanbul ignore next */
        this.nestedFormulaWithId = res?.formulaIdWithId;
        /* istanbul ignore next */
        this.nestedAttrType = res?.attributeType;
      }
    });
  }

  detectActionLabel() {
    this.changeunitFacade.fetchedCUForActionLabel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.actionLabel) {
        this.configurationData.data.actionLabel = res?.actionLabel;
        this.selected = res?.actionLabel;
      }
    });
  }
  getLayersData(foundCU?: any) {
    /* istanbul ignore else*/
    if (this.configurationData) {
      this.selectedAltCU = foundCU;
      this.physicalLayer = [];
      this.informationLayer = [];
      this.triggerCESLayer = [];
      /* istanbul ignore else*/
      if (!foundCU) {
        foundCU = this.configurationData.data;
      }
      /* istanbul ignore next*/
      if (foundCU?.layers?.length > 0) {
        foundCU?.layers?.forEach((layer: any) => {
          /* istanbul ignore else*/
          if (layer?.participatingItems?.length > 0) {
            if (layer?.type == 'information') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /* istanbul ignore else*/
                if (participatingItem && participatingItem?.name) {
                  entityIndex = this.informationLayer.findIndex((ele: any) =>
                    ele?.name ? ele.name === participatingItem.name : ele.item.DATA.name === participatingItem.name
                  );
                }
                /* istanbul ignore else*/
                if (entityIndex === -1) {
                  this.informationLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'physical') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /* istanbul ignore else*/
                if (participatingItem && participatingItem.name) {
                  entityIndex = this.physicalLayer.findIndex((ele: any) => ele.name === participatingItem.name);
                }
                /* istanbul ignore else*/
                if (entityIndex === -1) {
                  this.physicalLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'triggerCES') {
              /* istanbul ignore else*/
              layer?.participatingItems?.forEach((participatingItem: any) => {
                this.triggerCESLayer.push(participatingItem);
              });
            }
          }
        });
      }
    }
  }
  //To check attribute type and provide condition according to it
  checkAttrType(conditionValue: any) {
    if (
      this.currentAttrType == 'number' ||
      this.currentAttrType == 'decimalnumber' ||
      this.currentAttrType == undefined ||
      this.currentAttrType == ''
    ) {
      /* istanbul ignore else */
      if (isNaN(conditionValue)) {
        conditionValue = `'${conditionValue}'`;
      }
    } else {
      conditionValue = `'${conditionValue}'`;
    }
    return conditionValue;
  }

  // forming the expression for recursive cu type
  addCondition() {
    /* istanbul ignore next*/
    if (this.configurationData?.data.cuType === 'Recursive' || this.recursiveCU) {
      this.constraintName = this.constraintName ? this.constraintName : '';
      this.constraint = this.constraint ? this.constraint : '';
      this.recursiveOperator = this.recursiveOperator ? this.recursiveOperator : '';
      /* istanbul ignore else*/
      this.conditionValue = this.checkAttrType(this.conditionValue);
      this.changeUnit.constraintName = this.constraintName + this.recursiveOperator + this.conditionValue;
      /* istanbul ignore else*/
      if (this.constraint !== this.changeUnit.constraint) {
        this.changeUnit.constraint =
          this.constraint && this.recursiveOperator && this.conditionValue
            ? this.constraint + this.recursiveOperator + this.conditionValue
            : this.constraint + this.conditionValue;
      }
      /* istanbul ignore next*/
      if (this.changeUnit?.constraint === '' && this.changeUnit?.constraintName !== '') {
        this.changeUnit.constraint = this.changeUnit?.constraintName;
      }
    }
    this.constraintName = undefined;
    this.conditionValue = undefined;
    this.recursiveOperator = undefined;
  }
  editAlternateCU(cu: any) {
    this.editAlternateCUFlag = true;
    /* istanbul ignore next*/
    let foundCU = this.tempGsiObj.solutionLogic?.find((x: any) => x.index == cu.index);
    /* istanbul ignore else*/
    if (foundCU) {
      this.getLayersData(foundCU);
    }
  }
  editNested(i: number) {
    this.currentNestedIndex = i;

    this.nestedOperator = this.operators?.find((operator: string) => {
      return this.changeUnit.mindCUList[i].triggerConditionName.includes(operator);
    });
    let arr = this.changeUnit.mindCUList[i].triggerConditionName.split(this.nestedOperator);

    if (arr && arr?.length >= 2) {
      this.nestedFormulaId = arr[0]?.trim();

      this.nestedAttrValue = arr[1]?.trim();
    }
    this.nestedFlag = this.changeUnit.mindCUList[i].alwaysTrigger;
  }
  deleteNested(i: number) {
    this.isNestedSave = false;
    this.changeUnit.mindCUList[i].triggerConditionName = '';
    this.changeUnit.mindCUList[i].triggerCondition = '';
    this.nestedAttrValue = '';
    this.nestedFormulaId = '';
    this.changeUnit.mindCUList[i].alwaysTrigger = true;
  }
  //forming the expression for nested cu type
  addNestedCondition() {
    /* istanbul ignore else*/
    if (this.nestedFormulaId && this.nestedOperator) {
      let attrValue = this.nestedAttrValue;
      /* istanbul ignore else*/
      if (this.nestedOperator !== undefined && isNaN(attrValue)) {
        attrValue = `'${this.nestedAttrValue}'`;
      }
      /* istanbul ignore next*/
      this.changeUnit.mindCUList = this.changeUnit.mindCUList ? this.changeUnit.mindCUList : [];

      this.changeUnit.mindCUList[
        this.currentNestedIndex
      ].triggerConditionName = `${this.nestedFormulaId} ${this.nestedOperator} ${attrValue}`;
      // this.changeUnit.mindCUList[
      //   this.currentNestedIndex
      // ].triggerCondition = `${this.nestedFormulaWithId} ${this.nestedOperator} ${attrValue}`;
      this.changeUnit.mindCUList[this.currentNestedIndex].triggerCondition = this.changeUnit.mindCUList[
        this.currentNestedIndex
      ].triggerConditionName;
    }
    this.nestedFormulaId = '';
    this.nestedFormulaWithId = '';
    this.nestedAttrValue = '';
  }

  saveCu() {
    this.configurationData.data.dcd = [];
     /*istanbul ignore next*/
    this.changeUnit.author = this.metaInfo?.author;
     /*istanbul ignore next*/
    this.changeUnit.description = this.metaInfo?.description;
     /*istanbul ignore next*/
    this.changeUnit.keywords = this.metaInfo?.keywords instanceof Array ? this.metaInfo?.keywords : this.metaInfo?.keywords?.split(',');
    /*istanbul ignore next*/
    this.listOfDCDs?.forEach((dcd: any) => {
      /*istanbul ignore next*/
      if (!dcd?.isDeleted) {
        /*istanbul ignore next*/
        this.configurationData?.data?.dcd?.push(JSON.parse(JSON.stringify(dcd)));
        delete this.configurationData.data.dcd[this.configurationData.data.dcd.length - 1].isDeleted;
      }
    });
    this.changeUnit.disableSTEs = this.disableSTEs;
    /*istanbul ignore next*/
    if (this.changeUnit?.displayName == '') {
      this.changeUnit.displayName = this.changeUnit?.name;
    }
    /*istanbul ignore next*/
    if (this.tCUConditionalPotentialityNames?.length > 0) {
      this.changeUnit.tCUConditionalPotentialityNames = this.tCUConditionalPotentialityNames;
      this.changeUnit.tCUConditionalPotentiality = this.tCUConditionalPotentiality;
    }
    if (!this.configurationData?.data?.isSeperateCU && this.configurationData?.label !== 'NE') {
      this.changeUnit.tfId = this.changeUnit?.tfReferencedChangeUnit;
      this.changeUnit.id = this.changeUnit?.referencedChangeUnit;
    }
    /* istanbul ignore next*/
    if (this.configurationData?.data?.dcd) {
      /*istanbul ignore next*/
      this.changeUnit.dcd = this.configurationData?.data?.dcd;
    }
    /* istanbul ignore next */
    if (
      this.changeUnit?.sentenceTags?.physical?.[this.currenttagTokenCU?.index] &&
      this.changeUnit?.displayName &&
      this.changeUnit?.displayName &&
      this.changeUnit?.sentenceTags?.physical?.[this.currenttagTokenCU?.index].tag == 'Int.'
    ) {
      this.changeUnit.sentenceTags.physical[this.currenttagTokenCU?.index].token = this.changeUnit?.displayName;
    }
    /*istanbul ignore next*/
    this.currenttagTokenCU = this.changeUnit?.sentenceTags?.physical?.find(
      (tag: any) => tag.dsd_id == this.changeUnit.id
    );
    /*istanbul ignore next*/
    if (this.currenttagTokenCU) {
      this.currenttagTokenCU.index = this.changeUnit?.index - 1;
    }
    if (
      this.changeUnit?.id == this.configurationData?.data?.dsdRefId ||
      this.changeUnit?.id == this.configurationData?.data?.id
    ) {
      /*istanbul ignore next*/
      if (this.changeUnit?.name != this.configurationData?.data?.name) {
        /*istanbul ignore next*/
        this.changeUnit.description = this.changeUnit?.description?.replace(
          this.currenttagTokenCU?.token,
          this.changeUnit?.name
        );
        /*istanbul ignore next*/
        if (this.changeUnit?.sentenceTags?.physical?.[this.currenttagTokenCU?.index]) {
          this.changeUnit.sentenceTags.physical[this.currenttagTokenCU?.index].token = this.changeUnit?.name;
        }
      } else if (this.changeUnit?.displayName != this.configurationData?.data?.displayName) {
        /*istanbul ignore next*/
        this.changeUnit.description = this.changeUnit?.description?.replace(
          this.currenttagTokenCU?.token,
          this.changeUnit?.displayName
        );
        /*istanbul ignore next*/
        if (this.changeUnit?.sentenceTags?.physical?.[this.currenttagTokenCU?.index]) {
          this.changeUnit.sentenceTags.physical[this.currenttagTokenCU?.index].token = this.changeUnit?.displayName;
        }
      }
    }
    if (onlyTemplateOrNormalCU(this.changeUnit)) {
      if (this.changeUnit.cuType != 'GSI') {
        this.changeunitFacade.saveDraftCu(this.changeUnit, true);
      } else {
        this.gsiFacade.saveDraftGsi(this.changeUnit);
      }
    }
    /* istanbul ignore next */
    if (this.configurationData?.data?.isSeperateCU) {
      this.changeUnit.description = this.changeUnit?.description?.replace(
        this.currenttagTokenCU?.token,
        this.changeUnit?.name
      );
      this.changeUnit.description = this.changeUnit?.description?.replace(
        this.currenttagTokenCU?.token,
        this.changeUnit?.displayName
      );
      if (
        this.changeUnit?.sentenceTags?.physical?.[this.currenttagTokenCU?.index] &&
        this.changeUnit?.displayName &&
        this.changeUnit?.sentenceTags?.physical?.[this.currenttagTokenCU?.index].tag == 'Int.'
      ) {
        this.changeUnit.sentenceTags.physical[this.currenttagTokenCU?.index].token = this.changeUnit?.displayName;
      }
      this.changeunitFacade.updateChangeUnit(this.changeUnit, false, true);
    }
  }

  removeConstraint() {
    this.changeUnit.constraintName = '';
    this.constraintName = '';
    this.constraint = '';
    this.recursiveOperator = '';
    this.conditionValue = '';
  }
  resetNestedGsi() {
    this.nestedFormulaId = '';
    this.nestedFormulaWithId = '';
    this.nestedAttrValue = '';
    this.nestedOperator = '';
  }
  closeCu() {
    this.close = true;
    this.closecu.emit(this.close);
    this.changeunitFacade.closeActionpanel();
  }
  saveGsi() {
    this.gsiFacade.saveGsi(this.tempGsiObj);
    this.closeCu();
  }
  changeOfAttributeValue() {
    let attrValue = this.attributeValue;
    /* istanbul ignore else*/
    if (this.alternateOperator !== undefined && isNaN(attrValue)) {
      attrValue = `'${this.attributeValue}'`;
    }
    this.AlternateExpressionValue = `${this.formulaId} ${this.alternateOperator} ${attrValue}`;
    this.AlternatexpressionValueWithId = `${this.formulaId} ${this.alternateOperator} ${attrValue}`;
    /* istanbul ignore else*/
    if (this.formulaId && this.alternateOperator) {
      this.addAlternateCondition();
    }
  }

  addAlternateCondition() {
    this.selectedAltCU.conditionName = this.AlternateExpressionValue;
    this.selectedAltCU.condition = this.AlternatexpressionValueWithId;
    this.alternateOperator = '';
    this.attributeValue = '';
    this.formulaId = '';
    this.editAlternateCUFlag = false;
  }
  //TO check target input
  /* istanbul ignore next*/
  checkTargetInput(event: any) {
    const query = { sourceAttributeuiElement: this.currentUielement };
    return checkInputField(event, query);
  }
  // conditional potentiality expression at trigger cu level
  changeOfAttributeValueatCu() {
    /* istanbul ignore else*/
    let attrValue = this.cuAttributeValue;
    const obj = {
      sourceAttributeType: this.attributeType,
      targetAttributeInput: attrValue,
    };
    attrValue = checkDataTypeInCondition(obj).targetAttributeInput;
    // if (this.currentOperator !== undefined && isNaN(attrValue)) {
    //   attrValue = `'${this.cuAttributeValue}'`;
    // }
    this.expressionValue = `${this.potentialityAttr} ${this.currentOperator} ${attrValue}`;
    /* istanbul ignore else*/
    if (this.potentialityAttr && this.currentOperator) {
      this.tCUConditionalPotentialityNames.push(this.expressionValue);
      this.tCUConditionalPotentiality.push(this.expressionValue);
      this.resetCondition();
    }
  }
  /*removing the conditional potentiality */
  removeConditionalCriteria(changeUnit: any, i: any) {
    this.tCUConditionalPotentialityNames.splice(i, 1);
    this.tCUConditionalPotentiality.splice(i, 1);
    this.currentOperator = '';
  }
  // removing the condition after adding
  resetCondition() {
    this.potentialityAttr = '';
    this.currentOperator = '';
    this.cuAttributeValue = '';
  }

  resetConditionForm() {
    this.formulaId = '';
    this.formulaWithId = '';
    this.attributeValue = '';
    this.alternateOperator = '';
  }

  setStep(index: number) {
    this.step = index;
  }
  isShown: boolean = false;

  toggleShow() {
    this.isShown = !this.isShown;
  }
  menuenter() {
    this.gsiFacadeService.menuenter();
  }

  menuLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }

  buttonEnter(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }

  buttonLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }
  deleteDcd(dcdIndex: number) {
    /* istanbul ignore next*/
    if (dcdIndex !== undefined && this.listOfDCDs && this.listOfDCDs?.length > 0) {
      this.listOfDCDs[dcdIndex].isDeleted = true;
      this.configurationData.data.dcd[dcdIndex].isDeleted = true;
      this.countListOfDCDs--;
      /* istanbul ignore next*/
      this.gsiFacade.updateDcdInConfigurationData(this.configurationData?.data?.dcd);
    }
  }
  saveNestedCondition() {
    this.isNestedSave = true;

    if (this.nestedFormulaId && this.nestedOperator) {
      let attrValue = this.nestedAttrValue;

      if (this.nestedOperator !== undefined && (isNaN(attrValue) || this.nestedAttrType === 'string') && attrValue) {
        attrValue = `'${this.nestedAttrValue}'`;
      }

      this.changeUnit.mindCUList = this.changeUnit.mindCUList ? this.changeUnit.mindCUList : [];
      this.changeUnit.mindCUList[this.currentNestedIndex].triggerConditionName = attrValue
        ? `${this.nestedFormulaId} ${this.nestedOperator} ${attrValue}`
        : `${this.nestedFormulaId} ${this.nestedOperator}`;
      // this.changeUnit.mindCUList[
      //   this.currentNestedIndex
      // ].triggerCondition = `${this.nestedFormulaWithId} ${this.nestedOperator} ${attrValue}`;
      this.changeUnit.mindCUList[this.currentNestedIndex].triggerCondition = this.changeUnit.mindCUList[
        this.currentNestedIndex
      ].triggerConditionName;

      this.changeUnit.mindCUList[this.currentNestedIndex].alwaysTrigger = this.nestedFlag;
      this.nestedFlag = false;
    }
    this.changeUnit.mindCUList[this.currentNestedIndex].alwaysTrigger = this.nestedFlag;
    this.currentNestedIndex = -1;
    this.nestedOperator = '';
    this.nestedFormulaId = '';
    this.nestedAttrValue = '';
  }

  // genereate Source Array for DCD
  generateDCDSourceArray() {
    this.countListOfDCDs = 0;
    /*istanbul ignore next*/
    this.configurationData?.data?.dcd?.forEach((dcd: Dcd) => {
      dcd.sourceArray = [];
      let seperators = ['\\+', '-', '\\(', '\\)', '\\*', '/'];
      /*istanbul ignore else*/
      if (dcd.formula == '') {
        dcd.sourceArray.push({ name: dcd.sourceContextualName });
      } else {
        let tokens = dcd.formulaName?.split(new RegExp(seperators.join('|'), 'g'));
        tokens?.forEach((token: any) => {
          /*istanbul ignore else*/
          if (token) {
            dcd.sourceArray.push({ name: token });
          }
        });
      }
      /*istanbul ignore next*/
      if (dcd?.formulaName) {
        dcd.formulaArray = this.generateFormulaArray(dcd.formulaName);
      }
      if (!dcd.isDeleted) {
        this.countListOfDCDs++;
      }
    });
    /*istanbul ignore next*/
    this.listOfDCDs = [...this.configurationData?.data?.dcd];
  }
  //  generating formulae array for dcd
  generateFormulaArray(formulaName: string) {
    let operators: any = ['+', '-', '*', '%', '/', '(', ')'];
    const stringArray = Array.from(formulaName);
    let eachString = '';
    let splitArray = [];
    /*istanbul ignore next*/
    stringArray?.forEach((entry) => {
      eachString += entry;
      operators?.find((opt: any) => {
        if (opt == entry) {
          eachString = eachString?.slice(0, -1);
          splitArray.push(eachString);
          eachString = '';
          splitArray.push(opt);
        }
      });
    });
    /*istanbul ignore else*/
    if (/^[a-zA-Z0-9- ,_]*$/.test(formulaName) == false) {
      splitArray.push(eachString);
    }
    this.checkForSplit(splitArray);
    return (splitArray = splitArray.filter((entry) => entry.trim() != ''));
  }
  checkForSplit(formularray: any) {
    this.formulaString = '';
    /*istanbul ignore next*/
    if (formularray) {
      /*istanbul ignore next*/
      formularray?.forEach((data: any) => {
        /*istanbul ignore next*/
        const label = data?.split('.');
        /*istanbul ignore next*/
        let temp = label?.[label?.length - 1];
        this.formulaString += temp;
      });
      this.formulaStringArray.push(this.formulaString);
    }
  }
  detectDCDChange() {
    this.gsiFacade.dcdChange$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: boolean) => {
      /*istanbul ignore else*/
      if (res) {
        this.formulaStringArray = [];
        this.generateDCDSourceArray();
      }
    });
  }
  getTagTokenForCu() {
    this.changeunitFacade.receiveTag$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.currenttagTokenCU = res;
    });
  }

  onSOPCreate(value: any) {
    this.isSOPEnabled = value;
    /*istanbul ignore next*/
    this.changeunitFacade?.enableShadeOfPotentiality({
      gsi: this.tempGsiObj,
      isSOPEnabled: this.isSOPEnabled,
      cu: this.changeUnit,
    });
  }
  getCuConfigurationInfo() {
    this.changeunitFacade.fetchedCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res?.cudata?.actionLabel) {
        this.selected = res?.cudata?.actionLabel;
      } else {
        this.selected = 'SUBMIT';
      }
    });
  }
  onClickActionIcons() {
    if (!this.lastChild) {
      this.alertService.showToaster('Please select an ontology', '', 'info');
    } else if (this.lastChild?.children?.length > 0) {
      /* istanbul ignore next */
      this.ontologyList = [...this.ontologyList, this.lastChild];
      this.lastChild = {};
    } else {
      this.alertService.showToaster('Subcategory not found', '', 'info');
    }
  }
  detectMetaInfo() {
    /* istanbul ignore next */
    let ontData = this?.configurationData?.data?.ontology?.filter((x: any) => x.level != 1);
    let data: any = [];
    this.metaInfo.description = this.configurationData?.data?.description;
    /* istanbul ignore next */
    if (this.configurationData?.data?.keywords?.length > 0) {
      let str = '';
       /*istanbul ignore next*/
      for(let key of this.configurationData?.data?.keywords)
      {
         /*istanbul ignore next*/
        if(key != '')
        {
          str += key;
          str += ',';
        }
      }
      str = str.replace(/,\s*$/, "");
      this.metaInfo.keywords = str == ',' ? '' : str;
    }
    /*istanbul ignore else */
    if (ontData?.length > 0) {
      this.showSubCategory = true;
      for (let i = 0; i < ontData.length; i++) {
        let x = ontData[i];
        x = {
          id: getObjectByValue('id', x.id, this.ontologyList[i].children),
          children: this.ontologyList[i].children,
        };
        data.splice(i, 1);
        data.push(x);
        /*istanbul ignore next */
        if (x.children.length > 0 && x.id.children.length > 0 && i + 1 != ontData.length) {
          data.push(x.id);
        }
        this.lastChild = x.id;
        this.ontologyList = data;
      }
    } else {
      this.ontologyList = this.orgOntologyList;
    }
  }

  resetNestedCondition() {
    /*istanbul ignore else*/
    if (this.nestedFlag) {
      this.nestedFormulaId = '';
      this.nestedOperator = '';
      this.nestedAttrValue = '';
    }
  }
  getCuDetails() {
    this.changeunitFacade.fetchedCuDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      this.configurationData.data.ontology = res?.ontology;
      this.changeUnit.isTemplate = res?.isTemplate ? res?.isTemplate: false;
    });
  }
  // on select estimated Time
  onSelectEstimatedTime() {
    const date = this.estimatedTime;
    this.changeUnit = getselectedestimatedTime(this.changeUnit, date);
    /* istanbul ignore next */
    this.tempGsiObj?.solutionLogic.forEach((cu: any) => {
      if (cu?.id === this.changeUnit?.id) {
        cu.estimatedTime = this.changeUnit?.estimatedTime;
      }
    });
  }
  // get estimated Time on edit
  getEstimatedTimeOnCuEdit() {
    /* istanbul ignore next */
    if (this.changeUnit?.estimatedTime?.includes('PT')) {
      this.estimatedTime = getEstimatedTime(this.changeUnit);
    }
  }

  openCondition() {
    let sendTag;
    sendTag = this.getTagToSend();
    /* istanbul ignore next */
    this.changeunitFacade.openCondition(true, this.attrTag, 'Conditional Rights', sendTag);
  }

  enableCondition() {
    this.enableConditionBtn = true;
  }

  getTagToSend() {
    let sendTag: any;
    let from_Date: any;
    let to_Date: any;
    (from_Date = this.inputValue1),
      (to_Date = this.inputValue2),
      (sendTag = {
        from_Date: from_Date,
        to_Date: to_Date,
      });
    /* istanbul ignore next */
    return sendTag;
  }

  onKey1(event: any) {
    this.inputValue1 = event?.value;
  }
  onKey2(event: any) {
    this.inputValue2 = event?.value;
  }

  onhideRecordAdditionalInfoChange(hideRecordAdditionalInfo: any) {
    this.disableSTEs = hideRecordAdditionalInfo;
  }

  agentTypeChanged(agentType: string) {
    if (this.changeUnit && this.changeUnit?.agents && this.changeUnit?.agents?.length > 0) {
      this.changeUnit.agents[0].agentType = agentType;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

