declare var require: any;
let transform = require('node-json-transform').transform;

export function attributeMapper(data: any) {
  var map = {
    item: {
      agentNames: 'agentNames',
      attachments: 'attachments',
      attributeClassification: 'attributeClassification',
      attributeDSDIds: 'attributeDSDIds',
      attributeType: 'attributeType',
      changeUnitDSDIds: 'changeUnitDSDIds',
      constraints: 'constraints',
      dsdId: 'id',
      editable: 'editable',
      entitiesDSDIds: 'entitiesDSDIds',
      id: 'dsdId',
      indexedOntology: 'indexedOntology',
      isInPotentiality: 'isInPotentiality',
      configuration: 'configuration',
      isNameUpdated: 'isNameUpdated',
      isReserved: 'isReserved',
      keywords: 'keywords',
      maxAge: 'maxAge',
      minAge: 'minAge',
      name: 'name',
      nslAttributeProperties: 'nslAttributeProperties',
      effectiveUserRights: 'effectiveUserRights',
      ontology: 'ontology',
      ownerId: 'ownerId',
      solutionDSDIds: 'solutionDSDIds',
      solutionType: 'solutionType',
      source: 'source',
      status: 'status',
      tenant: 'tenant',
      triggerConditionalPotentiality: 'triggerConditionalPotentiality',
    },
  };
  return transform(data, map);
}
