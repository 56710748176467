import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent {
  currentIndex = 0;
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  gotoPrevious() {
    /* istanbul ignore else  */
    if (this.currentIndex != 0) this.currentIndex--;
  }
  gotoNext() {
    /* istanbul ignore else  */
    if (this.data.length != this.currentIndex + 1) this.currentIndex++;
  }
  onRightClick(event: any) {
    event.preventDefault();
  }
}
