import { Component, Injector, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService, Toast } from '../toaster/alert.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit {
  messagesflag: boolean = true;
  myToastsTimeoutIds: any = [];
  newToasterFlag: boolean = true;
  presentTimeout: any;
  buttonTimeout: any;
  private ngUnsubscribe = new Subject();
  toasts: Toast[] = [];
  imageOBJ: any = {
    success: '../../assets/images/Success-filled.svg',
    error: '../../assets/images/Error.svg',
    warning: '../../assets/images/Warning.svg',
    info: '../../assets/images/Information.svg',
  };
  constructor(private alertService: AlertService) {}
  ngOnInit(): void {
    this.showToaster();
  }
  showToaster() {
    this.alertService.toasterMessage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((toast) => {
      if (toast) {
        this.newToasterFlag = false;
        this.toasts = [toast, ...this.toasts];
        this.presentTimeout = setTimeout(() => this.toasts.pop(), toast.time || 5000);
        this.myToastsTimeoutIds = [this.presentTimeout, ...this.myToastsTimeoutIds];
        if (!this.messagesflag) {
          this.clearTimeouts();
        }
        setTimeout(() => {
          this.newToasterFlag = true;
        }, 1);
      } else {
        this.toasts.pop();
      }
    });
  }
  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
  }
  showmessages() {
    this.messagesflag = !this.messagesflag;
    if (this.messagesflag) {
      this.buttonTimeout = setTimeout(() => (this.toasts = []), 5000);
    } else {
      this.clearTimeouts();
    }
  }
  clearTimeouts() {
    if (this.myToastsTimeoutIds) {
      this.myToastsTimeoutIds.forEach((timeout: any) => {
        clearTimeout(timeout);
      });
      this.myToastsTimeoutIds = [];
    }
    clearTimeout(this.buttonTimeout);
  }
  removeall() {
    this.toasts = [];
    this.messagesflag = true;
  }
}
