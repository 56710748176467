import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

abstract class HttpCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | null;
  abstract put(req: HttpRequest<any>, resp: HttpResponse<any>): void;
}

@Injectable({providedIn: 'root'})
export class HttpCacheService implements HttpCache {
  cahce: any = {};

  put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    /* istanbul ignore next */
    this.cahce[req?.urlWithParams] = resp;
  }

  get(req: HttpRequest<any>): HttpResponse<any> | null {
    /* istanbul ignore next */
    return this.cahce[req?.urlWithParams];
  }

  delete(bookId: any) {
    /* istanbul ignore next */
    Object.keys(this.cahce).forEach((key: any) => {
      if (key.includes(bookId)) {
        delete this.cahce[key];
      }
    });
  }

  deleteTranslation(req: any) {
    delete this.cahce[req];
    localStorage.removeItem('logout');
  }
}
