import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeveloperLessDevelopmentFacadeService, NodeGsiFacadeService, SolutionDictionaryFacadeService, SuggestionFacadeService, TranslatorService, pagination } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-canvas-suggestion',
  templateUrl: './node-canvas-suggestion.component.html',
  styleUrls: ['./node-canvas-suggestion.component.scss'],
})
export class NodeCanvasSuggestionComponent implements OnInit, OnDestroy {
  suggestions: any;
  attributeSuggestions: any;
  displayType: any;
  agentSuggestions: any;
  isShown: boolean = false;
  dldFeedbackRating: number;
  nestedSuggestions: any = [];
  private ngUnsubscribe = new Subject();
  labels: any;
  nslSuggestions: any;
  hasConflict: any;
  currentBookDetails: any;
  pageIndex: number = 0;
  pageSize: number = 15;
  pageNumber: any;
  total: any = [];
  toggleShow() {
    this.isShown = !this.isShown;
    this.dldFeedbackRating = 0;
  }
  private unsubscribe$: Subject<any> = new Subject<any>();
  constructor(
    private suggestionFacadeService: SuggestionFacadeService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    public dialog: MatDialog,
    private dldFacade: DeveloperLessDevelopmentFacadeService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService
  ) {
    this.detectChangeUnitSuggestions();
    this.detectEntitySuggestions();
    this.detectAttributeSuggestions();
    this.detectAgentSuggestions();
    this.detectGsiNestedCuRecommendations();
    this.detectCompositeSaveApiResponse();
    this.detectLanguageChange();
    this.detectGsiSuggestion();
    this.detectBETConflicts();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  ngOnInit(): void {}
  //DLD ChangeUnit Suggestions
  detectChangeUnitSuggestions() {
    this.suggestionFacadeService.changeUnits$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.suggestions = res;
      this.displayType = BetType.Cu;
    });
  }
  // DLD Entity Suggestion
  detectEntitySuggestions() {
    this.suggestionFacadeService.entites$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.suggestions = res;
      this.displayType = BetType.Entity;
    });
  }
  // DLD Attribute Suggestion
  detectAttributeSuggestions() {
    this.suggestionFacadeService.attributes$.subscribe((res: any) => {
      this.attributeSuggestions = res;
      this.displayType = BetType.Attribute;
    });
  }
  // DLD Agents Suggestion
  detectAgentSuggestions() {
    this.suggestionFacadeService.agents$.subscribe((res: any) => {
      this.agentSuggestions = res;
      this.displayType = BetType.Agent;
    });
  }
  detectGsiSuggestion() {
    this.dldFacade.dsdSearchResults$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.nslSuggestions = res;
      this.total = res.totalResults;
      this.displayType = BetType.NSL;
    });
  }
  saveNslLibrary(gsiData: any) {
    this.currentBookDetails = gsiData;
    this.solutionDictionaryFacade.addBETToMylibrary('book', gsiData, 'gsi', true);
  }
  detectBETConflicts() {
    this.solutionDictionaryFacade.validateBookDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      this.hasConflict = res?.hasConflict;
      /* istanbul ignore else */
      if (this.hasConflict === false) {
        /* istanbul ignore next */
        let data = {
          conflictsAndResolutions: JSON.stringify(res),
        };
        this.solutionDictionaryFacade.betStoreImport(data, this.currentBookDetails?.dsdId, 'gsi');
      }
    });
  }
  onPageChange(pageData: pagination) {
    this.pageSize = pageData.pageSize;
    this.pageIndex = pageData.pageIndex;
    this.dldFacade.getDSDSearchQueries('gsi', '', this.pageIndex);
  }
  resolveConflicts() {
    this.solutionDictionaryFacade.setNslBookDetails({ ...this.currentBookDetails, isDsdRhs: true });
    // const dialogRef = this.dialog.open(BetConfiguration, {
    //   disableClose: true,
    //   width: '100vw',
    //   height: '100vw',
    //   maxWidth: '100vw',
    // });
    // dialogRef
    //   .afterClosed()
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((result) => {});
    // this.solutionDictionaryFacade.sendBetType('gsi');
  }
  detectGsiNestedCuRecommendations() {
    this.suggestionFacadeService.gsiRecommendationForNestedCU$.subscribe((res: any) => {
      this.nestedSuggestions = res;
      this.displayType = BetType.Nested;
    });
  }
  detectCompositeSaveApiResponse() {
    this.suggestionFacadeService.saveGSICompositeForNestedCU$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.gsiFacade.addNestedGsi(res, 'any');
        }
      });
  }
  // importing entity to canvas
  importEntity(entity?: any) {
    //Save the the DLD entity to Library
    this.nodeEntityFacadeService.saveDldEntity({ ...entity, isDld: true });
  }

  //import Attribute to Canvas
  importAttribute(attribute?: any) {
    this.suggestionFacadeService.addAttributeToCanvas({ ...attribute, isDld: true });
  }

  // import agent to Canvas
  importAgent(agent?: any) {
    this.suggestionFacadeService.addAgentToCanvas({ ...agent, isDld: true });
  }
  //gets dld feedback form rating
  getRating(index: any) {
    this.dldFeedbackRating = index;
  }
  //dld feedback Form submission
  submitFeedbackForm(feedbackFormComments: any) {
    let feedbackDldId;
    switch (this.displayType) {
      case 'Entity':
        /* istanbul ignore next */
        feedbackDldId = this.suggestions?.result[0]?.dldId;
        break;
      case 'Attribute':
        /* istanbul ignore next */
        feedbackDldId = this.attributeSuggestions?.result[0]?.dldId;
        break;
      case 'Cu':
        /* istanbul ignore next */
        feedbackDldId = this.suggestions?.result[0]?.dldId;
        break;
    }
    let requestBody = {
      dldId: feedbackDldId,
      userFeedback: {
        rating: this.dldFeedbackRating,
        comments: feedbackFormComments,
      },
    };
    this.suggestionFacadeService.sendDldFeedbackData(requestBody);
    this.toggleShow();
  }

  saveGSIWithCompositeAPI(gsiData: any) {
    const { id, guid, ...rest } = gsiData;
    // this.sendSelectedDataToDLDFeedBack({ dldId: gsiData.dldId, name: gsiData.name });
    this.suggestionFacadeService.saveGSICompositeAPI(rest);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
enum BetType {
  Cu = 'Cu',
  Entity = 'Entity',
  Attribute = 'Attribute',
  Agent = 'Agent',
  Nested = 'Nested',
  NSL = 'NSL_Library',
}
