import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { RolesFacadeService } from '../roles/roles-facade.service';
import { SolutionDictionaryFacadeService } from '../solution-dictionary/solution-dictionary-facade.service';
import { TranslatorService } from '../translator/translator.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bet-configuration',
  templateUrl: './bet-configuration.component.html',
  styleUrls: ['./bet-configuration.component.scss'],
})
export class BetConfiguration implements OnInit, OnDestroy {
  showLeftBar: boolean = true;
  isConflict: boolean = false;
  step = 0;
  bookFinalize: any;
  userRolesList: any = { rolesList: [] };
  changes: any[] = [];
  changeRoles: any[] = [];
  changeExistRoles: any[] = [];
  changeExistConRoles: any[] = [];
  changeMergeConRoles: any[] = [];
  changeRenamedRoles: any[] = [];
  allRoles: any[] = [];
  bookDetails: any;
  message: any;
  messageRoles: any;
  conRoleIndex: number = 0;
  rolesLength: number = 0;
  pageNumber: number = 0;
  pageSize: number = 10;
  searchString: string = '';
  isActive?: boolean = true;
  solutionChanges: any = {
    bookConflicts: [],
    gsiConflicts: [],
    cuConflicts: [],
    geConflicts: [],
  };
  solutionLevelVisibility = {
    book: true,
    gsi: false,
    cu: false,
    ge: false,
  };
  labels: any;

  @Output() solutionConflictParameters = {
    type: '',
    index: -1,
  };
  @Output() tabIndex: number = 0;
  validateBook: any;
  ngUnsubscribe = new Subject();

  totalSolutionConflicts: number;
  totalCUConflicts: number;
  totalEntityConflicts: number;
  bookData: any;
  NoOfRolesConflictedIndividually: any;
  betType: any;
  selectedBetType: any;
  currentBetTab: string;
  completedTab: any = [];
  saasapplication: boolean = false;
  targetTenant: string = '';

  constructor(
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private rolesFacadeService: RolesFacadeService,
    private translator: TranslatorService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<BetConfiguration>
  ) {
    this.getBetType();
    this.detectChangeTabIndex();
    this.detectSetToggleOptions();
    this.closePopupdetect();
    this.fetchIfSaasApplication();
  }

  ngOnInit(): void {
    this.getBookDetails();
    this.getConflictsAndRoles();
    this.detectLanguageChange();
    this.checkSolutionConflictNumber();
    /*istanbul ignore next*/
    for (let index = 0; index < 10; index++) {
      this.completedTab.push(false);
    }
    this.rolesFacadeService.loadPaginateRoles(this.pageNumber, this.pageSize, this.searchString, this.isActive);
    this.rolesFacadeService.rolesPaginateSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.userRolesList = res;
    });
  }

  getBookDetails() {
    /*istanbul ignore next*/
    if (this.route.snapshot.params?.id) {
      this.bookData = atob(this.route.snapshot.params?.id);
      const decodedData = decodeURIComponent(this.bookData);
      /*istanbul ignore next*/
      this.bookDetails = JSON.parse(decodedData);
    } else {
      this.detectNslBookDetails();
    }
    /*istanbul ignore next*/
    if (this.bookDetails) {
      this.fetchIfSaasApplication();
      if (this.saasapplication) {
        let systemUserDetails = '';
        let useremail = '';
        /*istanbul ignore next*/
        if (localStorage.getItem('ActiveUserInfo')) {
          let emailstring = localStorage.getItem('ActiveUserInfo');
          useremail = JSON.parse(emailstring)?.email;
        }
        systemUserDetails = `${this.targetTenant}:${useremail}`;
        this.solutionDictionaryFacade.importValidate('book', this.bookDetails, systemUserDetails);
      } else {
        this.solutionDictionaryFacade.addBETToMylibrary('book', this.bookDetails);
      }
    }
  }

  getBetType() {
    this.solutionDictionaryFacade.betType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.betType = res;
      this.selectedBetType = res;
      /*istanbul ignore else*/
      if (res === 'gsiImport') {
        this.betType = 'gsi';
      }
      this.solutionLevelVisibility.gsi = false;
      this.solutionLevelVisibility.book = false;
      this.solutionLevelVisibility.cu = false;
      this.solutionLevelVisibility.ge = false;
      localStorage.setItem('betType', this.betType);
      if (this.betType === 'changeunit') {
        this.solutionLevelVisibility.cu = true;
        this.currentBetTab = 'cu';
      } else if (this.betType === 'entity') {
        this.solutionLevelVisibility.ge = true;
        this.currentBetTab = 'entity';
      } else if (this.betType === 'gsi') {
        this.solutionLevelVisibility.gsi = true;
        this.currentBetTab = 'gsi';
      } else if (this.betType === 'books') {
        this.solutionLevelVisibility.book = true;
        this.currentBetTab = 'book';
      }
    });
    /*istanbul ignore else*/
    if (localStorage.getItem('betType')) {
      this.betType = localStorage.getItem('betType');
      this.selectedBetType = this.betType;
    }
  }

  getConflictsAndRoles() {
    this.solutionDictionaryFacade.validateBookDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.validateBook = {
        conflictsAndResolutions: res,
      };
      // this.validateBook.conflictsAndResolutions.connectionConflictCount=1;
      // this.validateBook.conflictsAndResolutions.connectionConflicts[0].conflicting=true;
      /*istanbul ignore next*/
      if (this.validateBook?.conflictsAndResolutions?.roleCount !== 0) {
        this.validateBook?.conflictsAndResolutions?.roleConflicts?.forEach((element: any, ind: number) => {
          this.rolesLength++;
          if (element?.isConflictResolved) {
            this.conRoleIndex++;
          }
        });
      } else {
        this.messageRoles = 'No Roles';
      }
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  detectNslBookDetails() {
    this.solutionDictionaryFacade.nslBookDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      /* istanbul ignore else */
      if (res) {
        this.bookDetails = res;
      }
    });
  }
  configureSolutionChanges(event: any) {
    this.bookFinalize = this.validateBook;
    this.bookFinalize.conflictsAndResolutions.bookConflicts = event.bookConflicts;
    this.bookFinalize.conflictsAndResolutions.gsiConflicts = event.gsiConflicts;
    this.bookFinalize.conflictsAndResolutions.cuConflicts = event.cuConflicts;
    this.bookFinalize.conflictsAndResolutions.geConflicts = event.geConflicts;
    this.solutionChanges.bookConflicts = event.bookConflicts;
    this.solutionChanges.gsiConflicts = event.gsiConflicts;
    this.solutionChanges.cuConflicts = event.cuConflicts;
    this.solutionChanges.geConflicts = event.geConflicts;
  }

  changeTabIndex(index: number) {
    this.tabIndex = index;
    this.completedTab[index - 1] = true;
    if (index >= 1 && index <= 3) {
      this.setStep(0);
    } else if (index > 3) {
      this.setStep(index - 3);
    }
  }

  detectChangeTabIndex() {
    this.solutionDictionaryFacade.changeTabIndex$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.changeTabIndex(res);
      }
    });
  }

  detectSetToggleOptions() {
    this.solutionDictionaryFacade.toggleOption$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.toggleOptionsSolutions(res);
      }
    });
  }

  toggleOptionsSolutions(type: string) {
    this.currentBetTab = type;
    /* istanbul ignore else */
    if (type === 'gsi') {
      this.solutionLevelVisibility.book = false;
      this.solutionLevelVisibility.gsi = true;
      this.solutionLevelVisibility.cu = false;
      this.solutionLevelVisibility.ge = false;
    } else if (type === 'cu') {
      this.solutionLevelVisibility.book = false;
      this.solutionLevelVisibility.cu = true;
      this.solutionLevelVisibility.gsi = false;
      this.solutionLevelVisibility.ge = false;
    } else if (type === 'ge') {
      this.solutionLevelVisibility.book = false;
      this.solutionLevelVisibility.ge = true;
      this.solutionLevelVisibility.cu = false;
      this.solutionLevelVisibility.gsi = false;
    } /*istanbul ignore next*/ else if (type === 'book') {
      this.solutionLevelVisibility.book = true;
      this.solutionLevelVisibility.ge = false;
      this.solutionLevelVisibility.cu = false;
      this.solutionLevelVisibility.gsi = false;
    }
  }

  showRenameButton(type: string, index: number) {
    this.solutionConflictParameters.type = type;
    this.solutionConflictParameters.index = index;
  }

  receivedRoles(event: any) {
    this.conRoleIndex = 0;
    /*istanbul ignore next*/
    event?.forEach((item: any) => {
      this.allRoles.push(item);
      /*istanbul ignore next*/
      if (!item.isConflictResolved && item.conflicting) {
        this.conRoleIndex++;
      }
    });
  }

  changedallroles(eve: any) {
    this.allRoles = [];
    /*istanbul ignore next*/
    eve?.forEach((item: any) => {
      this.allRoles.push(item);
      /*istanbul ignore next*/
      if (item.isConflictResolved && item.conflicting) {
        this.conRoleIndex = 0;
      }
    });
  }

  changedallDashboards(eve: any) {}

  changedallDatasets(eve: any) {}

  changedallConnections(eve: any) {}

  checkSolutionConflictNumber() {
    /*istanbul ignore next*/
    this.totalSolutionConflicts =
      this.validateBook?.conflictsAndResolutions?.bookConflictCount +
      this.validateBook?.conflictsAndResolutions?.entityConflictCount +
      this.validateBook?.conflictsAndResolutions?.changeUnitConflictCount +
      this.validateBook?.conflictsAndResolutions?.gsiConflictCount;
  }

  noOfRolesConflict($event: any) {
    this.NoOfRolesConflictedIndividually = $event;
  }

  mergeOrMapRoles(action: string) {
    this.rolesFacadeService.mergeOrMapRoles(action);
  }

  betToggleSelection(event: any) {
    if (event.index === 2) {
      this.toggleOptionsSolutions('book');
    } else if (event.index === 3) {
      this.toggleOptionsSolutions('gsi');
    } else if (event.index === 4) {
      this.toggleOptionsSolutions('cu');
    } else if (event.index === 5) {
      this.toggleOptionsSolutions('ge');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  closePopupdetect() {
    this.solutionDictionaryFacade.solutionDictionaryClosePopup$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.dialogRef.close();
      });
  }
  fetchIfSaasApplication() {
    // this.solutionDictionaryFacade.saasapplicationBet$.pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe((res:any)=>{
    //   if(res.includes('saasapplication')){
    //    this.saasapplication=true;
    //    let data = res.split('-');
    //    this.targetTenant=data[data.length-1];
    //   }
    // })
    this.route.queryParams.subscribe((param) => {
      if (param && param.saasApplicationTenant && param.saasBetType) {
        console.log(param);
        /*istanbul ignore next */
        this.targetTenant = param?.saasApplicationTenant;
        this.saasapplication = true;
        /*istanbul ignore next */
        this.selectedBetType = param?.saasBetType;
        /*istanbul ignore next */
        this.selectedBetType = this.selectedBetType?.toLowerCase();
      }
    });
  }
}
