import { dldLayerMapper } from './dld-layer.mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function dldChangeUnitMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      index: 'index',
      nextTriggerSet: 'nextTriggerSet',
      agents: 'agents',
      attachments: 'attachments',
      author: 'author',
      confidence: 'confidence',
      description: 'description',
      designTimeRights: 'designTimeRights',
      displayName: 'displayName',
      dldId: 'dldId',
      estimatedTime: 'estimatedTime',
      hasAlternates: 'hasAlternates',
      isReserved: 'isReserved',
      keywords: 'keywords',
      layers: 'layers',
      constraint: 'constraint',
      reservedCUType: 'reservedCUType',
      // layers: [{type: "physical", participatingItems: [{name: "Customer_D79956", displayName: "Customer",…}]}]
      name: 'name',
      ontology: 'ontology',
      source: 'source',
      txnTimeRights: 'txnTimeRights',
      type: 'type',
      cuList: 'cuList',
      condition: 'condition',
      conditionName: 'condition',
      dcd: 'dcd',
      solutionLogic: 'solutionLogic',
      cuType: 'cuType',
    },
    operate: [
      {
        run: function (val: any[]) {
          return val?.map((x: any) => {
            return dldLayerMapper(x);
          });
        },
        on: 'layers',
      },
      {
        run: function (val: any) {
          return val?.map((x: any) => {
            return dldChangeUnitMapper(x);
          });
        },
        on: 'cuList',
      },
    ],
  };

  const result = transform(data, map);

  return result;
}
