<div class="con-main-wrapper conditions-wrapper" id="conditions_root_div">
  <!--Header area-->
  <div class="con-header" id="conditions_header">
    <div class="con-header-left" id="conditions_header_left">
      <h2>
        Augmented Entity Creation
      </h2>
    </div>
    <div class="con-header-right" id="conditions_header_right">
      <button class="btn save" id="conditions_btn_save" (click)="saveEntity()">Save</button>
      <button class="btn-close" id="conditions_btn_close" (click)="closeArEntity()">
        <i class="bi bi-x-square"></i>
      </button>
    </div>
  </div>
  <!--Content area-->
  <div class="con-content" id="conditions_content">
    <div class="colomn-one">
      <div class="colmn-head pt-1">
        <h6>Entity</h6>
      </div>

      <div class="addinput-wrap">
        <div class="addinput-txt">
          <mat-form-field>
            <input
              type="text"
              placeholder="Name Your Object"
              matInput
              autocomplete="off"
              [(ngModel)]="entityObj.name"
            />
          </mat-form-field>
        </div>
        <div class="uiconrols-blk">
          <mat-accordion>
            <mat-form-field class="mb-4 mr-3 w-100">
              <mat-label>{{ labels?.Change_Driver_Type }}</mat-label>
              <mat-select [(ngModel)]="selectedChangedriverOptCu" panelClass="cdt-panel">
                <mat-option
                  *ngFor="let opt of optChangeDrivers; let i = index"
                  [value]="opt"
                  (click)="onChangeOption()"
                >
                  {{ opt }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- </mat-expansion-panel> -->
          </mat-accordion>
        </div>
      </div>
    </div>
    <div class="colomn-one">
      <div class="colmn-head">
        <h6>Attributes</h6>
      </div>
      <div class="addinput-wrap" *ngFor="let attribute of entityObj?.nslAttributes; let i = index">
        <div class="addinput-txt">
          <mat-form-field>
            <input
              type="text"
              placeholder="Define Object Properties"
              autocomplete="off"
              matInput
              [(ngModel)]="attribute.name"
            />
            <mat-icon matSuffix *ngIf="entityObj?.nslAttributes?.length > 1" (click)="deleteAttribute(i)"
              >delete_outline</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="swith-wrap-blk">
          <div class="add-wrap-blk">
            <button class="csm-btn" (click)="addNewAttribute()" *ngIf="i === entityObj?.nslAttributes.length - 1">
              <mat-icon>add</mat-icon>
            </button>
            <button class="csm-btn">
              <mat-icon>expand_more</mat-icon>
            </button>
            <button class="csm-btn">
              <mat-icon>expand_less</mat-icon>
            </button>
          </div>
        </div>
        <div class="uiconrols-blk">
          <div>UI controls</div>
          <mat-accordion>
            <div class="no-label">
              <mat-form-field appearance="outline">
                <mat-select
                  class="uic-select"
                  panelClass="attribute-icons select-with-search"
                  placeholder="Select Attribute Type"
                  [(ngModel)]="attribute.attributeType.uiElement.name"
                >
                  <mat-option *ngFor="let dataType of attributeTypes" [value]="dataType.name">{{
                    dataType.name
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="multi-value">
              <mat-label class="label-txt mr-2">Multi Value</mat-label>
              <mat-radio-group fxLayout="column" [(ngModel)]="attribute.isMultiValue" id="multi_value_{{ i }}">
                <mat-radio-button name="isMultiValue" id="attribute_multiYes_{{ i }}" [value]="true">{{
                  labels?.Yes
                }}</mat-radio-button>
                <mat-radio-button name="isMultiValue" id="attribute_multiNo_{{ i }}" [value]="false">{{
                  labels?.No
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div>
              Upload {{ attribute?.attributeType?.uiElement?.uiElement }}
              <app-file-upload
                [fileUploadFromEntity]="true"
                (fileUploadResponse)="fileUploadUrl($event, i, attribute)"
                [isMulti]="attribute.isMultiValue"
                [fileType]="attribute?.attributeType?.uiElement?.uiElement"
                [isDesignTime]="true"
              >
              </app-file-upload>
            </div>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
