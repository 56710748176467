import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SolutionDictionaryFacadeService } from '../solution-dictionary/solution-dictionary-facade.service';

@Component({
  selector: 'app-advancesearch-filter',
  templateUrl: './advancesearch-filter.component.html',
  styleUrls: ['./advancesearch-filter.component.scss'],
})
export class AdvancesearchFilterComponent implements OnInit {
  @Input() labels: any;
  @Input() isNslLibrary: any;
  @Output() filterData = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject<any>();
  ontologyMasterList: any[] = [];
  enableDetailedView: boolean = false;
  enableCategories: boolean = false;
  enableRating: boolean = false;
  selectedRating: string = '';
  searchText: string = '';
  parentOntology: any;
  childActiveOntologies: any[] = [];
  isEditableLabel: string = '';

  constructor(private solDictionaryFacadeService: SolutionDictionaryFacadeService) {
    this.detectOntologyMasterList();
  }

  ngOnInit() {
    this.getOntologyMasterList();
  }

  detectOntologyMasterList() {
    this.solDictionaryFacadeService.ontologyMasterList$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        /* istanbul ignore next */
        this.ontologyMasterList = res?.children;
        this.ontologyMasterList = this.ontologyMasterList.map((ont: any) => {
          return {
            ...ont,
            children: this.setOntologyChildonLoad(ont?.children),
            isActive: false,
          };
        });
        // this.originalOntologyMasterList = this.ontologyMasterList;
      }
    });
  }

  getOntologyMasterList() {
    this.solDictionaryFacadeService.loadOntologyMasterList();
  }

  setOntologyChildonLoad(ontChildren: any) {
    if (ontChildren?.length > 0) {
      return ontChildren.map((x: any) => {
        return { ...x, isActive: false };
      });
    } else {
      return ontChildren;
    }
  }

  showFilters() {
    document.body.classList.toggle('filter-active');
  }

  onFilter(label?: any) {
    let selectedOntList = this.getSelectedOntologies();
    /* istanbul ignore next */
    const filterData = {
      rating: this.selectedRating ? this.selectedRating : null,
      ontologyIds: selectedOntList,
      iseditable: this.isEditableLabel == 'editable' ? true : this.isEditableLabel == 'transactable' ? false : null,
    };
    if (label == 'apply') {
      this.filterData.emit(filterData);
    } else {
      const ftrData: any = {
        rating: null,
        ontologyIds: [],
        iseditable: null,
      };
      this.filterData.emit(ftrData);
      // this.ontologyMasterList = [...this.originalOntologyMasterList];
      this.solDictionaryFacadeService.loadOntologyMasterList();
      this.childActiveOntologies = [];
      this.isEditableLabel = '';
      this.enableCategories = false;
      this.enableDetailedView = false;
      this.enableRating = false;
      this.parentOntology = null;
      this.selectedRating = '';
    }
  }

  getSelectedOntologies() {
    let parentOntologies = this.ontologyMasterList.filter((x) => {
      /* istanbul ignore else */
      if (x.isActive) {
        return x;
      }
    });
    parentOntologies = parentOntologies.map((x) => {
      return x.id;
    });

    // get child ontology data
    // this.ontologyMasterList.map(x => {
    //   if(x?.children?.length > 0) {
    //     this.getActiveOntologyChildrenList(x?.children);
    //   }
    // });

    let filteredOntologyList = [...parentOntologies, ...this.childActiveOntologies];
    return filteredOntologyList;
  }

  // getActiveOntologyChildrenList(childOntList: any) {
  //   let flag = false;
  //   let chldOntologies = [];
  //   childOntList.map((x: any) => {
  //     if(!x.isActive) {
  //       flag = true;
  //     }
  //   });
  //   if(flag) {
  //     chldOntologies = childOntList.filter((x: any) => {
  //       if (x.isActive) {
  //         return x;
  //       }
  //     });
  //     chldOntologies = chldOntologies.map((x: any) => {
  //       return x.id;
  //     });
  //   }
  //   // this.childActiveOntologies.push(...new Set(chldOntologies));
  //   this.childActiveOntologies.push(...chldOntologies);
  //   this.childActiveOntologies = this.childActiveOntologies.filter((el, i, a) => i === a.indexOf(el));
  // }

  onClickFilterOptions(label: any) {
    this.enableDetailedView = true;
    /* istanbul ignore else */
    if (label == 'categories') {
      this.enableCategories = true;
    } else if (label == 'rating') {
      this.enableRating = true;
    }
  }

  onClickEndForDetailedView() {
    if (this.parentOntology) {
      this.parentOntology = null;
    } else {
      this.enableCategories = false;
      this.enableDetailedView = false;
    }
    this.enableRating = false;
  }

  onChangeRating(event: any) {
    this.selectedRating = event;
  }

  onClickParentOntology(ontology: any) {
    /* istanbul ignore next */
    if (ontology?.children?.length > 0) {
      this.parentOntology = ontology;
    } else {
      this.ontologyMasterList = this.ontologyMasterList.map((x) => {
        if (x.id == ontology.id) {
          return ontology;
        } else return x;
      });
    }
  }

  onClickChildOntology(childOntology: any, event: any) {
    childOntology.isActive = event.checked;
    this.setChildActiveOntList(childOntology);
    this.parentOntology.children = this.parentOntology.children.map((x: any) => {
      if (x.id == childOntology.id) {
        return childOntology;
      } else return x;
    });
    this.setParentOntologySelectAll();
    this.setChildActiveOntListIfSelectAll();
  }

  setChildActiveOntList(childOntology: any) {
    if (childOntology.isActive) {
      this.childActiveOntologies.push(childOntology.id);
      this.childActiveOntologies = this.childActiveOntologies.filter((el, i, a) => i === a.indexOf(el));
    } else {
      const index: number = this.childActiveOntologies.indexOf(childOntology);
      /* istanbul ignore else */
      if (index !== -1) {
        this.childActiveOntologies.splice(index, 1);
      }
    }
  }

  setChildActiveOntListIfSelectAll() {
    if (this.parentOntology.isActive) {
      this.parentOntology.children.map((x: any) => {
        const index: number = this.childActiveOntologies.indexOf(x.id);
        if (index !== -1) {
          this.childActiveOntologies.splice(index, 1);
        }
      });
    } else {
      this.parentOntology.children.map((x: any) => {
        if (x.isActive) {
          this.childActiveOntologies.push(x.id);
          this.childActiveOntologies = this.childActiveOntologies.filter((el, i, a) => i === a.indexOf(el));
        } else {
          const index: number = this.childActiveOntologies.indexOf(x.id);
          /* istanbul ignore else */
          if (index !== -1) {
            this.childActiveOntologies.splice(index, 1);
          }
        }
      });
    }
  }

  setParentOntologySelectAll() {
    let selectAllFlag = false;
    this.parentOntology.children.map((x: any) => {
      /* istanbul ignore else */
      if (!x.isActive) {
        selectAllFlag = true;
      }
      this.parentOntology.isActive = selectAllFlag ? false : true;
    });
    this.setontologyMasterListonUpdateParentOntology();
  }

  onSelectAll(event: any) {
    this.parentOntology.isActive = event.checked;
    this.parentOntology.children = this.parentOntology.children.map((x: any) => {
      if (this.parentOntology.isActive) {
        return { ...x, isActive: true };
      } else {
        return { ...x, isActive: false };
      }
    });
    this.setontologyMasterListonUpdateParentOntology();
    this.setChildActiveOntListIfSelectAll();
  }

  setontologyMasterListonUpdateParentOntology() {
    this.ontologyMasterList = this.ontologyMasterList.map((x: any) => {
      if (x.id == this.parentOntology.id) {
        return this.parentOntology;
      } else return x;
    });
  }

  onClickEditable(label?: string) {
    if (label == 'editable') {
      this.isEditableLabel = 'editable';
    } else this.isEditableLabel = 'transactable';
  }
}
