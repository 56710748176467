<ng-template #attributeTemplate let-data="data">
<span class="text" #attributeItem id="attr-{{ data?.position }}"  (dragover)="allowDrop($event)" (drop)="dropAttribute($event, 'any')">
    {{childPositionMap?.[data?.position] ? childPositionMap?.[data?.position]?.name : "Level"+ data?.position }}
</span>
<em
class="nh-icon nh-icon-trash01 cursor-pointer"
(click)="onDeletingAttr(attributeItem)"
*ngIf="attributeItem?.innerHTML?.trim() !== 'Level' + data?.position"
></em>
</ng-template>
<div class="treeview" (click)="showExpandedTree = false;createAnglesAndWidth();" *ngIf="showExpandedTree  && cardDisplay!='design'">

    <div class="item">
        <div class="title-wrapper">
            <h2 class="title" >{{level1?.value}}
                <span class="total">{{level1?.count}}</span>
            </h2>
        </div>
        <div class="inner">
            <ul class="level-1">
                <li>
                    <div class="label"><span class="text">{{level1?.relatedRecords?.[0]?.value}}</span> <span
                            class="count" *ngIf="level1?.relatedRecords?.[0]?.relatedRecords?.length >0">{{level1?.relatedRecords?.[0]?.count}}</span><span *ngIf="level1?.relatedRecords?.[0]?.relatedRecords?.length >0"class="hor-line"></span>
                    </div>
                    <ul class="level-2">
                        <li *ngFor="let level3 of level1?.relatedRecords?.[0]?.relatedRecords">
                            <div class="label"><span class="text">{{level3?.value}}</span><span
                                    class="count" *ngIf="level3?.relatedRecords?.length>0">{{level3?.count}}</span><span *ngIf="level3?.relatedRecords?.length>0" class="hor-line"></span></div>
                            <ul class="level-3" *ngIf="level3?.relatedRecords?.length>0" >
                                <li *ngFor="let level4 of level3?.relatedRecords">
                                    <div class="label"><span class="text">{{level4?.value}}</span></div>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="item" *ngFor="let level2 of level1?.relatedRecords.slice(1,level1?.relatedRecords?.length)">
        <div class="inner">
            <ul class="level-1">
                <li>
                    <div class="label"><span class="text">{{level2?.value}}</span> <span
                            class="count" *ngIf="level2?.relatedRecords?.length>0">{{level2?.count}}</span><span *ngIf="level2?.relatedRecords?.length>0" class="hor-line"></span></div>

                    <ul class="level-2">
                        <li *ngFor="let level3 of level2?.relatedRecords">
                            <div class="label"><span class="text">{{level3?.value}}</span><span
                                    class="count" *ngIf="level3?.relatedRecords?.length>0">{{level3?.count}}</span><span *ngIf="level3?.relatedRecords?.length>0"class="hor-line"></span></div>
                            <ul class="level-3" *ngIf="level3?.relatedRecords?.length>0">
                                <li *ngFor="let level4 of level3?.relatedRecords">
                                    <div class="label"><span class="text">{{level4?.value}}</span></div>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </li>
            </ul>
        </div>
    </div>


</div>

<div class="circle-wrapper"  *ngIf="cardDisplay!='design'">
    <div class="holderCircle"(mouseover)="stopRotation()" (mouseout)="resumeRotation()" >
        <div class="dotCircle dotCircle1" #mainCircle>  
            <span class="itemDot  itemDot1" *ngFor="let item of attributeValues">
            </span>


        </div>

        <div class="dotCircle dotCircle2" #attributeCircle>

            <span class="itemDot  itemDot2" *ngFor="let item of attributeValues"
                (click)="toogleCircle(item) ">
                <span class="label"><span class="ellipsis"> {{item?.value}}</span> <span
                        class="count">{{item?.count}}</span></span>

            </span>

        </div>

    </div>

</div>

<div class="treeview1" *ngIf="cardDisplay=='design'">

    <div class="item" [ngStyle]="{
        'flex':'0 0 30%'
    }">
        <div class="title-wrapper">
            <h2 class="title"><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0
                  }
                }"
              ></ng-template>
                <span class="total">No.</span>
            </h2>
        </div>
    </div>
    <div class="item">
        <div class="inner">
            <ul class="level-1">
                <li>
                    <div class="label"><span class="text"><ng-template
                        [ngTemplateOutlet]="attributeTemplate"
                        [ngTemplateOutletContext]="{
                          data: {
                            position: 1
                          }
                        }"
                      ></ng-template></span> <span
                            class="count">No.</span><span class="hor-line"></span>
                    </div>
                    <ul class="level-2">
                        <li >
                            <div class="label"><span class="text"><ng-template
                                [ngTemplateOutlet]="attributeTemplate"
                                [ngTemplateOutletContext]="{
                                  data: {
                                    position: 2
                                  }
                                }"
                              ></ng-template></span>
                              <span class="count">No.</span>
                              <span class="hor-line"></span>
                            </div>
                            <ul class="level-3">
                                <li>
                                    <div class="label">
                                        <span class="text">
                                            <ng-template
                                            [ngTemplateOutlet]="attributeTemplate"
                                            [ngTemplateOutletContext]="{
                                                data: {
                                                    position: 3
                                                }
                                            }"
                                         ></ng-template>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>