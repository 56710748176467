import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';
import { CarnivalEndpointService } from '../carnival/carnival-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class SharedFacadeService {
  private bookSubject = new BehaviorSubject('');
  bookData$ = this.bookSubject.asObservable();

  private viewedList = new BehaviorSubject('gridView');
  viewedListData$ = this.viewedList.asObservable();

  // private isSolutionPage = new BehaviorSubject(false);
  // isSolutionPage$ = this.isSolutionPage.asObservable();

  publishToNSLSubject = new BehaviorSubject(false);
  publishToNSL$ = this.publishToNSLSubject.asObservable();

  sendAccordian = new Subject();
  sendAccordian$ = this.sendAccordian.asObservable();

  public groupNameSubject = new BehaviorSubject('');
  groupName$ = this.groupNameSubject.asObservable();

  public aadsState = new Subject();
  aadsState$ = this.aadsState.asObservable();

  private bookDetailsCondition = new BehaviorSubject(false);
  bookDetailsCondition$ = this.bookDetailsCondition.asObservable();

  private solutionDetailsSubject = new Subject();
  carnivalSolutionDetails$ = this.solutionDetailsSubject.asObservable();

  gridLayoutSubject = new Subject();
  gridLayout$ = this.gridLayoutSubject.asObservable();

  loginRedirectionSubject = new Subject();
  loginRedirectionSubject$ = this.loginRedirectionSubject.asObservable();

  enableBookPromotionPopup = new Subject();
  enableBookPromotionPopup$ = this.enableBookPromotionPopup.asObservable();

  moreInfocardIndex: any = null;

  constructor(private carnivalEndpointService: CarnivalEndpointService, private loader: LoaderService) {
    this.getDesignedBookData();
  }

  gettingViewData(data: any) {
    this.viewedList.next(data);
  }

  getDesignedBookData() {
    const designedBookData = JSON.parse(localStorage.getItem('layout-designSystem'));
    if (designedBookData != null) {
      this.bookSubject.next(designedBookData);
    }
  }

  UpdateBookData(bookData: any) {
    this.bookSubject.next(bookData);
  }
  bookDetailsConditionPotentiality(data: any) {
    this.bookDetailsCondition.next(data);
  }
  // getSolutionPage(info: boolean) {
  //   this.isSolutionPage.next(info);
  // }

  /** Function to change the Group Name on Header  */
  changeGroup(groupName: string) {
    if (!localStorage.getItem('islanguageChangeHappened')) {
      localStorage.setItem('ActiveUserFlag', groupName);
      this.groupNameSubject.next(groupName);
    } else {
      localStorage.removeItem('islanguageChangeHappened');
    }
  }

  onPublishToNSL(data: any) {
    this.publishToNSLSubject.next(data);
  }

  setBookPromotionPopup(data: any) {
    this.enableBookPromotionPopup.next(data);
  }

  /**
   * Sets aads state to hide/show
   * @param state
   */
  setAadsState(state: any) {
    this.aadsState.next(state);
  }

  setGridLayout(grid: any) {
    this.gridLayoutSubject.next(grid);
  }

  getBookDetails(searchCriteria?: string) {
    this.loader.show();
    let result: any;
    this.carnivalEndpointService
      .getBookDetails(searchCriteria)
      .pipe(
        map((book: any) => {
          /* istanbul ignore next */
          result = { bookId: book?.result?.content[0].id };
          sessionStorage.setItem('bookData', JSON.stringify(book.result.content[0]));
          /* istanbul ignore next */
          return result?.bookId;
        }),
        mergeMap((bookId: any) => this.carnivalEndpointService.getGSIsWithExecutionStatus(bookId))
      )
      .subscribe(
        (resp) => {
          this.loader.hide();
          result = { ...result, content: resp };
          this.solutionDetailsSubject.next(result);
        },
        (error: any) => {
          this.loader.hide();
        }
      );
  }

  redirectToLogin(param: any) {
    this.loginRedirectionSubject.next(param);
  }
}
