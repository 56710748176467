import { entityMapper } from '../entity/entity-mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function triggerCuMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      name: 'name',
      id: 'dsdId',
      tfId: 'id',
      agents: 'agents',
      layers: 'layers',
      gsiList: 'gsiList',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      dsdStatus: 'dsdStatus',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      displayName: 'displayName',
      description: 'description',
      actionLabel: 'actionLabel',
      ontology: 'ontology',
      keywords: 'keywords',
      attachments: 'attachments',
      nextTriggerSet: 'nextTriggerSet',
      referencedChangeUnit: 'dsdReferencedChangeUnit',
      index: 'index',
      author: 'author',
      ownerId: 'ownerId',
      version: 'version',
      masterId: 'masterId',
      cuSystemProperties: 'cuSystemProperties',
      finishType: 'finishType',
      slotItemProperties: 'result.slotItemProperties',
      source: 'source',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      reservedCUType: 'reservedCUType',
      isNameUpdated: 'isNameUpdated',
      uiProperties: 'uiProperties',
      isFinal: 'isFinal', //  Usedfor final cu save
      oldVersionId: 'oldVersionId',
      estimatedTime: 'estimatedTime',
      sentenceTags: 'querySentence',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      triggerCULayers: 'triggerCULayers',
      dcd: 'dcd',
      dsdMetadataId: 'dsdMetadataId',
      isParallel: 'isParallel',
      attributeMapping: 'attributeMapping',
      mindCUList: 'mindCUList',
      eventCUList: 'eventCUList',
    },

    operate: [
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((y: any) => {
              return {
                nextCUName: y.nextCUName,
                index: y.index,
                nextCUId: y.dsdNextCUId,
                isParallel: y?.isParallel,
              };
            });
          } else {
            return [];
          }
        },
        on: 'nextTriggerSet',
      },
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'triggerCULayers',
      },
      {
        run: (val: any) => {
          if (val !== undefined && Object.keys(val).length) {
            return val;
          } else {
            return {};
          }
        },
        on: 'uiProperties',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/

          if (val !== undefined && typeof val === 'string') {
            return JSON.parse(val);
          } else {
            return val;
          }
        },
        on: 'sentenceTags',
      },
    ],
  };

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          /*istanbul ignore next*/

          return val?.map((x: any) => {
            return {
              /*istanbul ignore next*/

              ...entityMapper(x?.item?.DATA),
              changeDriverMetaData: x.changeDriverMetaData,
              participatingItemId: x?.id,
              isMultiValue: x.isMultiValue,
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };

  const result = transform(data, map);

  return result;
}
