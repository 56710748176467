import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ReadOnlyTransactionDialog,
  TransactionViewComponent,
} from './transaction-view.component';
import { TransactionMaterialModule } from '../transaction-material/transaction-material.module';
import { PhysicalLayerComponent } from './physical-layer/physical-layer.component';
import { InfoLayerComponent } from './info-layer/info-layer.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { DynamicFieldDirective } from './dynamic-form/dynamic-field.directive';
import { DynamicUiTableComponent } from './dynamic-form/dynamic-ui-table/dynamic-ui-table.component';
import { DynamicUiTableLabelsComponent } from './dynamic-form/dynamic-ui-table-labels/dynamic-ui-table-labels.component';
import { VerticalDynamicUiTableComponent } from './dynamic-form/vertical-dynamic-ui-table/vertical-dynamic-ui-table.component';
import { PushbackTransactionViewComponent } from './pushback-transaction-view/pushback-transaction-view.component';
import { ApplicationPipesModule, CommonServicesModule } from '@common-services';
import { BasicTransactionViewComponent } from './basic-transaction-view/basic-transaction-view.component';
import { OpencuTransactionViewComponent } from './opencu-transaction-view/opencu-transaction-view.component';
import { AssetTransactionViewComponent } from './asset-transaction-view/asset-transaction-view.component';
import { AssethorizontalTransactionViewComponent } from './assethorizontal-transaction-view/assethorizontal-transaction-view.component';
import { CarnivalTransactionViewComponent } from './carnival-transaction-view/carnival-transaction-view.component';
import { CudashboardTransactionViewComponent } from './cudashboard-transaction-view/cudashboard-transaction-view.component';
import { CuhorizantalTransactionViewComponent } from './cuhorizontal-transaction-view/cuhorizontal-transaction-view.component';
import { CupopupTransactionViewComponent } from './cupopup-transaction-view/cupopup-transaction-view.component';
import { EnterpriseTransactionViewComponent } from './enterprise-transaction-view/enterprise-transaction-view.component';
import { Enterprise3TransactionViewComponent } from './enterprise3-transaction-view/enterprise3-transaction-view.component';
import { EtcTransactionViewComponent } from './etc-transaction-view/etc-transaction-view.component';
import { ExtendedTransactionViewComponent } from './extended-transaction-view/extended-transaction-view.component';
import { LuxuryTransactionViewComponent } from './luxury-transaction-view/luxury-transaction-view.component';
import { MiscellaneousTransactionViewComponent } from './miscellaneous-transaction-view/miscellaneous-transaction-view.component';
import { MixedTransactionViewComponent } from './mixed-transaction-view/mixed-transaction-view.component';
import { ProgressbarTransactionViewComponent } from './progressbar-transaction-view/progressbar-transaction-view.component';
import { RegularTransactionViewComponent } from './regular-transaction-view/regular-transaction-view.component';
import { TabaccordTransactionViewComponent } from './tabaccord-transaction-view/tabaccord-transaction-view.component';
import { AddAttrToEntityComponent } from './dynamic-form/add-attr-to-entity/add-attr-to-entity.component';
import { AddEntityFromLibComponent } from './dynamic-form/add-entity-from-lib/add-entity-from-lib.component';
import { CuViewComponent } from './cu-view/cu-view.component';
import { BasicFormComponent } from './dynamic-form/basic-form/basic-form.component';
import { NestedFormComponent } from './dynamic-form/nested-form/nested-form.component';
import { DynamicUiCardComponent } from './dynamic-form/dynamic-ui-card/dynamic-ui-card.component';
import { DynamicUiCardEcommerceChildTemplatesComponent } from './dynamic-form/dynamic-ui-card/dynamic-ui-card-ecommerce-child-templates/dynamic-ui-card-ecommerce-child-templates.component';
import { DynamicUiCardEcommerceExpandTemplatesComponent } from './dynamic-form/dynamic-ui-card/dynamic-ui-card-ecommerce-expand-templates/dynamic-ui-card-ecommerce-expand-templates.component';
import { DynamicUiCardInsuranceChildTemplatesComponent } from './dynamic-form/dynamic-ui-card/dynamic-ui-card-insurance-child-templates/dynamic-ui-card-insurance-child-templates.component';
import { DynamicUiCardInsuranceExpandTemplatesComponent } from './dynamic-form/dynamic-ui-card/dynamic-ui-card-insurance-expand-templates/dynamic-ui-card-insurance-expand-templates.component';
import { DynamicUiCardExpandTemplatesComponent } from './dynamic-form/dynamic-ui-card-expand-templates/dynamic-ui-card-expand-templates.component';
import { DynamicUiCardTemplatesComponent } from './dynamic-form/dynamic-ui-card-templates/dynamic-ui-card-templates.component';
import { TemplateOneCardComponent } from './dynamic-form/template-cards/template-one-card/template-one-card.component';
import { TemplateTwoCardComponent } from './dynamic-form/template-cards/template-two-card/template-two-card.component';
// import { FileUploadPreviewComponent } from '../../../../ui-controls/src/lib/controls/file-upload-preview/file-upload-preview.component';
// import { FileUploadComponent } from '../../../../ui-controls/src/lib/controls/file-upload/file-upload.component';
import { EntityRadioDcdComponent } from './dynamic-form/entity-radio-dcd/entity-radio-dcd.component';
import { CreateNewEntityComponent } from './dynamic-form/create-new-entity/create-new-entity.component';
import { InputModule, UiControlsModule } from 'ui-controls';
import { QuestionandanswerTransactionViewComponent } from './questionandanswer-transaction-view/questionandanswer-transaction-view.component';
import { StudioverseTransactionViewComponent } from './studioverse-transaction-view/studioverse-transaction-view.component';
import { ProgressBarConfigurationComponent } from './dynamic-form/progress-bar-configuration/progress-bar-configuration.component';
import { AdvertisementPanelComponent } from './advertisement-panel/advertisement-panel.component';
import { MatStepperModule } from '@angular/material/stepper';

const components = [
  TransactionViewComponent,
  BasicTransactionViewComponent,
  OpencuTransactionViewComponent,
  AssetTransactionViewComponent,
  CuhorizantalTransactionViewComponent,
  ExtendedTransactionViewComponent,
  AssethorizontalTransactionViewComponent,
  LuxuryTransactionViewComponent,
  RegularTransactionViewComponent,
  EnterpriseTransactionViewComponent,
  Enterprise3TransactionViewComponent,
  TabaccordTransactionViewComponent,
  MiscellaneousTransactionViewComponent,
  EtcTransactionViewComponent,
  MixedTransactionViewComponent,
  CarnivalTransactionViewComponent,
  ProgressbarTransactionViewComponent,
  CudashboardTransactionViewComponent,
  CupopupTransactionViewComponent,
  ReadOnlyTransactionDialog,
  PhysicalLayerComponent,
  AddAttrToEntityComponent,
  InfoLayerComponent,
  AddEntityFromLibComponent,
  CuViewComponent,
  DynamicFormComponent,
  BasicFormComponent,
  DynamicUiCardComponent,
  NestedFormComponent,
  CreateNewEntityComponent,
  EntityRadioDcdComponent,
  DynamicFieldDirective,
  DynamicUiTableComponent,
  DynamicUiTableLabelsComponent,
  DynamicUiCardEcommerceChildTemplatesComponent,
  DynamicUiCardEcommerceExpandTemplatesComponent,
  DynamicUiCardInsuranceChildTemplatesComponent,
  DynamicUiCardInsuranceExpandTemplatesComponent,
  DynamicUiCardExpandTemplatesComponent,
  TemplateOneCardComponent,
  TemplateTwoCardComponent,
  DynamicUiCardTemplatesComponent,
  PushbackTransactionViewComponent,
  QuestionandanswerTransactionViewComponent,
  VerticalDynamicUiTableComponent,
  StudioverseTransactionViewComponent,
  ProgressBarConfigurationComponent,
  AdvertisementPanelComponent,
];
@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    FormsModule,
    MatListModule,
    MatSelectModule,
    ReactiveFormsModule,
    ApplicationPipesModule,
    TransactionMaterialModule,
    InputModule,
    CommonServicesModule,
    UiControlsModule,
    MatStepperModule,
  ],
})
export class TransactionViewModule {}
