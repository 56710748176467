import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Subject } from 'rxjs/internal/Subject';


@Injectable({
  providedIn: 'root',
})
export class DceoEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }
  private ngUnsubscribe = new Subject();

  /**
   * Saves nh mind solutions config
   * @param config
   * @returns
   */
  saveNhMindConfig(config: NhMindConfig) {
    return this.http.post(this.api.nhmindConfig, config);
  }

  /**
   * Gets nh mind solutions config
   * @returns
   */
  getNhMindConfig() {
    return this.http.get(this.api.nhmindConfig);
  }

  /**
   * Gets dceo feed back
   * @param feedBackQueries
   * @returns
   */
  getDceoFeedBack(feedBackQueries: any) {
    return this.http.post(`${this.api?.dceoFeedBack}`, feedBackQueries);
  }

  /**
   * Fetchs all gsis
   * @param searchCriteria
   * @param roleId
   * @returns
   */
  fetchAllGsis(searchCriteria: string, roleId: string) {
    return this.http.get(
      this.api?.getPaginateGSIsBasedOnROle +
        `?page=${1}&limit=5&query=${searchCriteria}&rightHolderId=${roleId}&rightOwnerType=ROLE&designMode=true&isPublished=true`
    );
  }

  /**
   * Gets dceo roles
   * @returns
   */
  getDceoRoles(query?: string) {
    query = query ? query : '';
    return this.http.get(this.api.dceoRoles + '?roleName=' + query);
  }

  /**
   * Gets role id config
   * @param roleDetails
   * @returns
   */
  getRoleIdConfig(roleDetails: string) {
    return this.http.get(`${this.api?.getRoleId}/${roleDetails}`);
  }

  /**
   * Fecths all entityes
   * @param searchCriteria
   * @returns
   */
  fecthAllEntityes(searchCriteria: string) {
    return this.http.get(
      this.api?.getAllEntities + `?query=${searchCriteria}&page=${1}&limit=${5}&isPublished=${true}`
    );
  }

  /**
   * Fetchs nhmind color config
   * @returns
   */
  fetchColorConfig() {
    return this.http.get(this.api.nhmindColorConfig);
  }

  /**
   * Saves color config
   * @param primary
   * @param secondary
   * @returns
   */
  saveColorConfig(primary: string, secondary: string) {
    return this.http.post(this.api.nhmindColorConfig, {
      primaryColor: primary,
      secondaryColor: secondary,
    });
  }

  fetchFeedbackConfig() {
    return this.http.get(`${this.api?.feedbackConfig}`);
  }

  /**
   * Saves feedback config
   * @param data
   * @returns
   */
  saveFeedbackConfig(data: FeedbackConfig) {
    return this.http.post(`${this.api?.feedbackConfig}`, data);
  }

  /**
   * Uploads file
   * @param files
   * @returns
   */
  uploadFile(files: any) {
    return this.http.post(this.api?.singleFileUpload, files);
  }
}

export interface NhMindConfig {
  faqEntity: MappedBet;
  solutionConfigs: SoluntionConfigs;
  roleId: string;
  roleName: string;
  chatHistoryConfig?: string;
}

export interface MappedBet {
  id: string;
  displayName: string;
  masterId?: string;
  betIcon?: string;
}

export interface SoluntionConfigs {
  [key: string]: MappedBet[];
}

export interface FeedbackConfig {
  categories: FeedbackCategory[];
  messages: string[];
}

export interface FeedbackCategory {
  name: string;
  subCategories?: FeedbackCategory[];
}
