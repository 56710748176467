import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';

declare var webkitSpeechRecognition: any;

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class RecordingAudioService {
  private mediaConstraints = {
    audio: true,
    video: false,
  };

  stream: any;
  recorder: RecordRTC;
  private interval: any;
  private startTime: any;

  _recordedAudio = new Subject<any>();

  _recordedAudioTime = new Subject<any>();
  recordingAudioTime$ = this._recordedAudioTime.asObservable();

  constructor() {}

  /* istanbul ignore next */
  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recordedAudio.asObservable();
  }

  /* istanbul ignore next */
  startRecording() {
    this._recordedAudioTime.next({ time: '00:00' });
    /* istanbul ignore next */
    navigator.mediaDevices.getUserMedia(this.mediaConstraints).then((s) => {
      this.stream = s;
      /* istanbul ignore next */
      this.record();
    });
  }

  /* istanbul ignore next */
  getStopMedia() {
    this.stopMedia();
  }
  /* istanbul ignore next */
  private stopMedia() {
    /* istanbul ignore next */
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track: any) => track.stop());
        this.stream.getVideoTracks().forEach((track: any) => track.stop());
        this.stream.stop();
        this.stream = null;
      }
    }
  }

  /* istanbul ignore next */
  private record() {
    this.recorder = new RecordRTC(this.stream, {
      recorderType: RecordRTC.StereoAudioRecorder,
      type: 'audio',
      mimeType: 'audio/wav',
    });
    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this._recordedAudioTime.next({
        time: time,
        minutes: diffTime.minutes(),
        seconds: diffTime.seconds(),
        hours: diffTime?.hours(),
      });
    }, 500);
  }

  /* istanbul ignore next */
  private toString(value: any) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  /* istanbul ignore next */
  stopRecording() {
    /* istanbul ignore next */
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        let blob = this.recorder.getBlob();
        if (blob) {
          this._recordedAudio.next(blob);
        }
      });
    }
  }
}
