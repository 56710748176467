import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationPipesModule } from '@common-services';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { TransactionMaterialModule } from '../transaction-material/transaction-material.module';
import { TransactionViewModule } from '../transaction-view/transaction-view-module';
import { DemoPopUpComponent } from './demo-pop-up/demo-pop-up.component';
import { GsiComponent } from './gsi/gsi.component';
import { TransactionSmartComponent } from './transaction-smart/transaction-smart.component';
import { TransactionComponent } from './transaction.component';

@NgModule({
  declarations: [
    TransactionComponent,
    DemoPopUpComponent, 
  ],
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    TransactionMaterialModule,
    ApplicationPipesModule,
    TransactionViewModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    TransactionViewModule
  ]
})
export class TransactionModule { }
