import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';

import { EntityGridCardFilterComponent } from './entity-board-templates/entity-grid-card/entity-grid-card-filter/entity-grid-card-filter.component';
import { EntityGridCardPaginatorComponent } from './entity-board-templates/entity-grid-card/entity-grid-card-paginator/entity-grid-card-paginator.component';
import { EntityGridCardComponent } from './entity-board-templates/entity-grid-card/entity-grid-card.component';
import { EntityGridTableComponent } from './entity-board-templates/entity-grid-card/entity-grid-table/entity-grid-table.component';

import { EntityCardsComponent } from './entity-cards.component';
import { ApplicationPipesModule, LoaderModule, PaginatorModule, ToasterModule } from '@common-services';
import { CardColorConfigDirective } from './entity-board-templates/card-color-config.directive';
import { MatIconModule } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { MaterialModule } from './material/material.module';
import { ReadMoreComponent } from './entity-board-templates/read-more/read-more.component';

import { AgmCoreModule } from '@agm/core';
import { OutsideClickDirective } from './entity-board-templates/outsideclick.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'primeng/carousel';
import { FilterTemplatesComponent } from './entity-board-templates/entity-grid-card/filter-templates/filter-templates.component';
import { TableDirectiveDirective } from './entity-board-templates/entity-grid-card/table-directive/table-directive.directive';
import { TemplateHost } from './entity-board-templates/entity-grid-card/template-dialog/template-host/template-host.component';
import { TemplateDialog } from './entity-board-templates/entity-grid-card/template-dialog/template-dialog.component';
import { CanvasDesignComponent } from './card-drop/canvas-design/canvas-design.component';
import { CardDropComponent } from './card-drop/card-drop.component';
import { CartDropComponent } from './cart-drop/cart-drop.component';
import { DropAdvancedFeaturesComponent } from './drop-advanced-features/drop-advanced-features.component';
import { TableDropComponent } from './table-drop/table-drop.component';
import { TemplateTypeDialogComponent } from './template-type-dialog/template-type-dialog.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RouterModule } from '@angular/router';
import { CartTemplateComponent } from './cart-template/cart-template.component';
import { DashboardAmbulanceTrackingComponent } from './entity-board-templates/entity-grid-card/dashboard-ambulance-tracking/dashboard-ambulance-tracking.component';

import { ConfigureExpandChildDropComponent } from './configure-expand-child-drop/configure-expand-child-drop.component';

import { PageViewComponent } from './entity-board-templates/page-view/page-view.component';
import { DashboardAmbulanceTrackingComponentDesign } from './templates/dashboard-ambulance-tracking/dashboard-ambulance-tracking.component';
import { AttributeTemplatesComponent } from './card-templates/attribute-templates/attribute-templates.component';
import { BannersTemplateComponent } from './card-templates/banners-template/banners-template.component';
import { CoreTemplatesComponent } from './card-templates/core-templates/core-templates.component';
import { AttributeDesignConfigureDirective } from './card-templates/design-attribute.directive';
import { CardDesignConfigDirective } from './card-templates/design-card.directive';
import { ECommerceTemplateComponent } from './card-templates/e-commerce-template/e-commerce-template.component';
import { ExpandEcommerceTemplatesComponent } from './card-templates/expand-ecommerce-templates/expand-ecommerce-templates.component';
import { ExpandInsuranceTemplatesComponent } from './card-templates/expand-insurance-templates/expand-insurance-templates.component';
import { ExpandTemplatesBaseComponent } from './card-templates/expand-templates-base/expand-templates-base.component';
import { FeaturesTemplatesComponent } from './card-templates/features-templates/features-templates.component';
import { InsuranceEcommerceExpandBase } from './card-templates/insurance-ecommerce-expand-base-component';
import { InsuranceTemplatesComponent } from './card-templates/insurance-templates/insurance-templates.component';
import { TemplatesBaseComponent } from './card-templates/templates-base/templates-base.component';
import { CommentsAndRatingsComponent } from './entity-board-templates/entity-grid-card/comments-and-ratings/comments-and-ratings.component';
import { ConfigureExpandPageComponent } from './templates/configure-expand-page/configure-expand-page.component';
import { BaseDropComponent } from './base-drop/base-drop.component';
import { ConfigureAttributeDirective } from './templates/configure-attribute.directive';
import { EntityConfigDirective } from './templates/entity-config.directive';
import { DropBase } from './drop-base/drop-base';
import { CreateEqlComponent } from './entity-board-templates/entity-grid-card/create-eql/create-eql.component';
import { RuleSetModule } from './entity-board-templates/entity-grid-card/rule-set/rule-set.module';
import { FilterPageComponent } from './entity-board-templates/filter-page/filter-page.component';
import { RuleSetComponent } from './entity-board-templates/entity-grid-card/rule-set/components/rule-set/rule-set.component';
import { CommonDcdComponent } from './common-dcd/common-dcd.component';
import { DcdConditionsComponent } from './dcd-conditions/dcd-conditions.component';

import { MappingDisplayComponent } from './mapping-display/mapping-display.component';
import { NestedConnectorsComponent } from './nested-connectors/nested-connectors.component';
import { EntityToEntityDCDComponent } from './entity-to-entity-dcd/entity-to-entity-dcd.component';
import { TransactionLibraryModule } from '@transaction-library';
import { CircularTemplateComponent } from './card-templates/circular-template/circular-template.component';
import { KanbanConfigurationComponent } from './kanban-configuration/kanban-configuration.component';
import { Templates1Component } from './kanban-configuration/templates1/templates1.component';
import { KanbanViewTemplateComponent } from './entity-board-templates/kanban-view-template copy/kanban-view-template.component';
import { DynmaicExpandCardComponent } from './dynmaic-expand-card/dynmaic-expand-card.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    EntityCardsComponent,
    CreateEqlComponent,

    EntityGridCardComponent,
    EntityGridCardFilterComponent,
    EntityGridCardPaginatorComponent,
    EntityGridTableComponent,

    CardColorConfigDirective,
    ReadMoreComponent,

    OutsideClickDirective,
    FilterTemplatesComponent,
    TableDirectiveDirective,
    TemplateHost,
    TemplateDialog,
    ConfigureAttributeDirective,
    EntityConfigDirective,
    CardDropComponent,
    TableDropComponent,
    CartDropComponent,
    CanvasDesignComponent,
    DropAdvancedFeaturesComponent,
    TemplateTypeDialogComponent,
    CartTemplateComponent,
    DashboardAmbulanceTrackingComponent,
    ConfigureExpandChildDropComponent,
    ConfigureExpandPageComponent,
    DashboardAmbulanceTrackingComponentDesign,
    PageViewComponent,
    CoreTemplatesComponent,
    TemplatesBaseComponent,
    CardDesignConfigDirective,
    AttributeDesignConfigureDirective,
    InsuranceTemplatesComponent,
    FeaturesTemplatesComponent,
    ExpandTemplatesBaseComponent,
    BannersTemplateComponent,
    ECommerceTemplateComponent,
    AttributeTemplatesComponent,
    ExpandEcommerceTemplatesComponent,
    ExpandInsuranceTemplatesComponent,
    InsuranceEcommerceExpandBase,
    CommentsAndRatingsComponent,
    BaseDropComponent,
    ConfigureAttributeDirective,
    DropBase,
    FilterPageComponent,
    CommonDcdComponent,
    DcdConditionsComponent,
    NestedConnectorsComponent,
    MappingDisplayComponent,
    EntityToEntityDCDComponent,
    CircularTemplateComponent,
    KanbanConfigurationComponent,
    Templates1Component,
    KanbanViewTemplateComponent,
    DynmaicExpandCardComponent,
  ],
  imports: [
    // BrowserAnimationsModule,
    TransactionLibraryModule,
    SwiperModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ColorPickerModule,
    SelectButtonModule,
    AccordionModule,
    PaginatorModule,
    ApplicationPipesModule,
    MatIconModule,
    TooltipModule,
    MaterialModule,
    CarouselModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC-mtQuL0Bc-ynNBWNJrg1HZyAccAQnHP0',
      libraries: ['places'],
    }),
    RouterModule,
    RuleSetModule,
    ToasterModule,
    LoaderModule,
    InfiniteScrollModule
  ],
  exports: [
    EntityGridCardComponent,
    EntityGridCardPaginatorComponent,
    FilterTemplatesComponent,
    ReadMoreComponent,
    CardDropComponent,
    CartDropComponent,
    TableDropComponent,
    DropAdvancedFeaturesComponent,
    PageViewComponent,
    ConfigureExpandChildDropComponent,
    DropBase,
    EntityCardsComponent,
    BaseDropComponent,
    CreateEqlComponent,
    RuleSetModule,
    FilterPageComponent,
    RuleSetComponent,
  ],
})
export class EntityCardsModule {}
