import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig, getTenantName, getUiControlCustomization } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
@Component({
  selector: 'app-chip-multiple',
  styleUrls: ['./chip-multiple.component.scss'],
  template: `
    <form
      class="{{ parentClass }}"
      [formGroup]="group"
      [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }"
    >
      <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            pTooltip="{{ field?.label }}"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            ></label
          >
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2 multivalue-btns"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true' || field.isMulti"
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            class="d-flex align-items-center justify-content-center"
            matSuffix
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div
        class="table-chip-selection sub-right-class"
        *ngIf="allImgUrls?.length === 0 && isCustomizationApplied"
        [ngClass]="{ 'mini-popup': (minimumOptions == field?.options?.length && field?.attribute?.['isTableConfig'])}"
      >
        <p-selectButton
          styleClass="{{ appliedStyleClass }}"
          class="{{ appliedClass }}"
          [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3) ? (field?.options | slice: 0:minimumOptions) : field?.options"
          [(ngModel)]="field.value"
          [formControlName]="field.attribute.name"
          id="{{ field?.attribute?.name }}"
          (onChange)="onSelectionChange($event)"
          [required]="field?.isRequired && !field?.isInfo"
          multiple="multiple"
          optionLabel="displayValue"
          [disabled]="field.readonly"
          optionValue="actualValue"
        >
          <ng-template let-item>
            <span
              [pTooltip]="field?.configuration?.hideTooltip ? '' : item.displayValue"
              tooltipPosition="top"
              class="text-truncate"
              >{{ item.displayValue }}</span
            >
            <i *ngIf="foundObject?.event !== 'Option 6'" [class]="item.icon"></i>
          </ng-template>
        </p-selectButton>
        <p-selectButton
          *ngIf="field?.attribute?.['isTableConfig'] && field?.options?.length > 3"
          styleClass="{{ appliedStyleClass }} more-less"
          [options]="showAllOptions ? hideOptionBtn : showOptionsBtn"
          (onChange)="OnSelection($event)"
        ></p-selectButton>
      </div>
      <div
        class="table-chip-selection"
        *ngIf="allImgUrls?.length === 0 && !isCustomizationApplied"
        [ngClass]="{ 'mini-popup': (minimumOptions == field?.options?.length && field?.attribute?.['isTableConfig'])}"
      >
        <p-selectButton
          [styleClass]="tenantName == 'healthcaremx' ? 'chip_selection chip_selection_med' : 'chip_selection'"
          class="{{ appliedClass }}"
          [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3) ? (field?.options | slice: 0:minimumOptions) : field?.options"
          [(ngModel)]="field.value"
          [formControlName]="field.attribute.name"
          id="{{ field?.attribute?.name }}"
          (onChange)="onSelectionChange($event)"
          [required]="field?.isRequired && !field?.isInfo"
          multiple="multiple"
          optionLabel="displayValue"
          [disabled]="field.readonly"
          optionValue="actualValue"
        >
          <ng-template let-item pTemplate>
            <span
              [pTooltip]="field?.configuration?.hideTooltip ? '' : item.displayValue"
              tooltipPosition="top"
              class="text-truncate"
              >{{ item.displayValue }}</span
            >
          </ng-template>
        </p-selectButton>
        <p-selectButton
          *ngIf="field?.attribute?.['isTableConfig'] && field?.options?.length > 3"
          [styleClass]="
            tenantName == 'healthcaremx' ? 'chip_selection chip_selection_med more-less' : 'chip_selection more-less'
          "
          [options]="showAllOptions ? hideOptionBtn : showOptionsBtn"
          (onChange)="OnSelection($event)"
        ></p-selectButton>
      </div>
      <div
        class="table-chip-selection"
        *ngIf="allImgUrls?.length >= 1"
        [ngClass]="{ 'mini-popup': (minimumOptions == field?.options?.length && field?.attribute?.['isTableConfig']) }"
      >
        <p-selectButton
          *ngIf="field?.configuration?.selectionType === 'chipDisplayWithImage'"
          [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3) ? (field?.options | slice: 0:minimumOptions) : field?.options"
          class="chipwithimage"
          [formControlName]="field.attribute.name"
          id="{{ field?.attribute?.name }}"
          (onChange)="onSelectionChange($event)"
          [required]="field?.isRequired && !field?.isInfo"
          multiple="multiple"
          optionLabel="displayValue"
          [disabled]="field.readonly"
          optionValue="actualValue"
        >
          <ng-template let-option let-i="index">
            <img [src]="allImgUrls[i]" /><span
            [pTooltip]="field?.configuration?.hideTooltip ? '' : option.displayValue"
              tooltipPosition="top"
              class="text-truncate"
              >{{ option.displayValue }}</span
            ></ng-template
          >
        </p-selectButton>
        <p-selectButton
          *ngIf="field?.configuration?.selectionType === 'chipDisplayAsImage'"
          [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3) ? (field?.options | slice: 0:minimumOptions) : field?.options"
          class="chipwithimage"
          [formControlName]="field.attribute.name"
          id="{{ field?.attribute?.name }}"
          (onChange)="onSelectionChange($event)"
          [required]="field?.isRequired && !field?.isInfo"
          multiple="multiple"
          optionLabel="displayValue"
          [disabled]="field.readonly"
          optionValue="actualValue"
        >
          <ng-template let-option let-i="index"> <img [src]="allImgUrls[i]" /></ng-template>
        </p-selectButton>
        <p-selectButton
          *ngIf="field?.attribute?.['isTableConfig'] && field?.options?.length > 3"
          styleClass="chip_single_selection more-less"
          [options]="showAllOptions ? hideOptionBtn : showOptionsBtn"
          (onChange)="OnSelection($event)"
        ></p-selectButton>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </form>
  `,
})
export class ChipMultipleComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  addAttribute: Subject<any> = new Subject();
  imageUrls: string[] = [];
  allImgUrls: string[] = [];
  removeAttribute: Subject<any> = new Subject();
  showAllOptions: boolean = false;
  minimumOptions: number = 3;
  hideOptionBtn: any[] = [{ label: 'X', value: 'off' }];
  showOptionsBtn: any[];
  appliedStyleClass: string = 'chip_selection';
  appliedClass: string = '';
  isCustomizationApplied: boolean = false;
  tenantName: string;
  foundObject: any;
  parentClass: string = 'demo-full-width';

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) {
    this.tenantName = getTenantName();
  }

  ngOnInit(): void {
    let optionsCount = this.field?.options?.length - this.minimumOptions;
    this.showOptionsBtn = [{ label: `+${optionsCount}`, value: 'on' }];
    if (
      (this.field?.configuration?.imageUrlwithChip &&
        this.field?.configuration?.selectionType == 'chipDisplayWithImage') ||
      (this.field?.configuration?.imageUrlChip && this.field?.configuration?.selectionType == 'chipDisplayAsImage')
    ) {
      this.imageUrls = this.field?.configuration?.imageUrlwithChip
        ? this.field?.configuration?.imageUrlwithChip
        : this.field?.configuration?.imageUrlChip;
      /* istanbul ignore next */
      this.imageUrls.forEach((url: any) => {
        this.allImgUrls.push(url.contentUrl);
      });
    }
    let chipOption = getUiControlCustomization('Chips');
    /* istanbul ignore else */
    if (chipOption) {
      this.foundObject = { event: chipOption };
      this.isCustomizationApplied = true;
      if (chipOption == 'Option 7') {
        this.parentClass = 'chips-multi-parent-class-styles';
      } else {
        this.appliedStyleClass = '';
        let icon: string;
        switch (chipOption) {
          case 'Option 1':
            icon = 'bi bi-plus-circle';
            break;
          case 'Option 2':
            icon = 'bi bi-plus-circle';
            break;
          case 'Option 3':
            icon = 'bi bi-check2';
            break;
          case 'Option 4':
            icon = 'bi bi-circle';
            break;
          case 'Option 5':
            icon = 'bi bi-plus-lg';
            break;
          case 'Option 6':
            icon = 'bi bi-x-lg';
            break;
        }
        this.field?.options?.forEach((op: any, ind: number) => {
          op = { ...op, icon: icon };
          this.field.options[ind] = op;
        });
        let option = chipOption.slice(-1);
        this.appliedClass = `forms-chips-selection chips-selection-opt${option}`;
      }
    }
  }

  /**
   * Determines whether selection change on
   * @param event holds the selected checkboxes value
   */
  onSelectionChange(event: any) {
    if (!this.field.isHidden) {
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore else */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  OnSelection(event: any) {
    this.minimumOptions = this.showAllOptions ? 3 : this.field?.options?.length;
    this.showAllOptions = !this.showAllOptions;
  }
}
