import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn : 'root'
})
export class TranslatorService extends CduiBaseService {
  jsonURL = 'assets/i18n/en.json';
  languageLabels = new BehaviorSubject('');
  languageLables$ = this.languageLabels.asObservable();
  labels: any = null;
  isLanguageTranslationFailed: boolean = true;
  isTranslatedFlag: boolean = false;
  translatedWords = new Subject();
  translatedWords$ = this.translatedWords.asObservable();
  translatedWordsResult = new Subject();
  translatedWordsResult$ = this.translatedWordsResult.asObservable();
  languages = new Subject();
  languages$ = this.languages.asObservable();
  private getAllLanguages = new BehaviorSubject('');
  getAllLanguages$ = this.getAllLanguages.asObservable();

  getAllExcludedLanguages = new Subject();
  getAllExcludedLanguages$ = this.getAllExcludedLanguages.asObservable();

  getUpdatedLanguageList = new Subject();
  getUpdatedLanguageList$ = this.getUpdatedLanguageList.asObservable();

  tenantLanguages = new Subject();
  tenantLanguages$ = this.tenantLanguages.asObservable();

  languageLabelsData = new Subject();
  languageLablesData$ = this.languageLabelsData.asObservable();

  private ngUnsubscribe = new Subject();
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private alertService: AlertService,
    private primengConfig: PrimeNGConfig
  ) {
    super(injector);
  }

  public getJSON(): Observable<any> {
    if (localStorage.getItem('TenantName') == 'telceldev') {
      this.jsonURL = 'assets/i18n/es.json';
    }
    return this.http.get(this.jsonURL);
  }

  saveStaticLabels(staticLabels: any, lang: any) {
    lang = lang ? lang.toUpperCase() : 'EN';
    /* istanbul ignore next */
    return this.http.post(this.api?.saveStaticLabels + lang.toUpperCase(), staticLabels);
  }

  getLanguages() {
    const requestOptions = this.getRequestOptions();
    /* istanbul ignore next */
    this.http.get(this.api?.getLanguages, requestOptions).subscribe((res: any) => {
      this.languages.next(res);
      this.getAllLanguages.next(res);
    });
  }

  callGetAllLanguage(languages: any) {
    this.getAllLanguages.next(languages);
  }

  getStingTranslate(sourceLanguage: string, targetLanguage: string, words: any[], tenantName: string) {
    /* istanbul ignore next */
    this.http
    .post(this.api?.getStringTranslate + `?sourceLanguage=${sourceLanguage}&targetLanguage=${targetLanguage}`, words)
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.translatedWords.next(res);
          for (let word in res) {
            /* istanbul ignore else */
            if (res[word] == '') {
              this.alertService.showToaster(`${word} not available`, '', 'error');
            }
          }
        }
      });
  }

  addStaticLabel(payload: any, tagName: string, langCode: string) {
    /* istanbul ignore next */
    this.http.post(this.api?.addStaticLabels + `/${tagName}` + `/${langCode}`, payload).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res.labels) {
        this.alertService.showToaster(`Labels added succesfully`, '', 'success');
      }
    });
  }

  updateStaticLabel(payload: any, tagName: string, langCode: string, allLang: boolean) {
    /* istanbul ignore next */
    this.http
      .put(this.api?.updateStaticLabels + `/${tagName}` + `/${langCode}` + `/${allLang}`, payload)
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res.labels) {
          this.alertService.showToaster(`Labels updated succesfully`, '', 'success');
        }
      });
  }

  deleteStaticLabel(tagName: string, word: string) {
    this.http.delete(this.api?.deleteStaticLabel + `/${tagName}` + `/${word}`).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.alertService.showToaster(`${res}`, '', 'error');
      }
    });
  }

  updateMultipleWords(
    isTranslate: boolean,
    sourceLanguage: string,
    targetLanguage: string,
    toupdateWords: any[],
    tenantName: string
  ) {
    /*istanbul ignore next */
    this.http
      .put(
        this.api?.updateMultipleWords +
          '?isTranslate=' +
          `${isTranslate}` +
          '&sourceLanguage=' +
          `${sourceLanguage}` +
          '&targetLanguage=' +
          `${targetLanguage}` +
          '&tenant=' +
          `${tenantName}`,
        toupdateWords
      )
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.translatedWordsResult.next(res);
        }
      });
  }

  getStaticLabels(lang: any) {
    lang = lang ? lang.toUpperCase() : 'EN';
    /*istanbul ignore next */
    this.http.get(this.api?.getStaticLabels + lang).subscribe((res) => {
      this.languageLabels.next(res['labels']);
      this.isTranslatedFlag = true;
      this.setPrimengTranslations(res['labels']);
    });
  }

  setPrimengTranslations(labels: any) {
    const calendarLocale = {
      monthNames: [
        labels?.CalUIJanuary || 'January',
        labels?.CalUIFebruary || 'February',
        labels?.CalUIMarch || 'March',
        labels?.CalUIApril || 'April',
        labels?.CalUIMay || 'May',
        labels?.CalUIJune || 'June',
        labels?.CalUIJuly || 'July',
        labels?.CalUIAugust || 'August',
        labels?.CalUISeptember || 'September',
        labels?.CalUIOctober || 'October',
        labels?.CalUINovember || 'November',
        labels?.CalUIDecember || 'December',
      ],
      monthNamesShort: [
        labels?.CalUIJan || 'Jan',
        labels?.CalUIFeb || 'Feb',
        labels?.CalUIMar || 'Mar',
        labels?.CalUIApr || 'Apr',
        labels?.CalUIMay || 'May',
        labels?.CalUIJun || 'Jun',
        labels?.CalUIJul || 'Jul',
        labels?.CalUIAug || 'Aug',
        labels?.CalUISep || 'Sep',
        labels?.CalUIOct || 'Oct',
        labels?.CalUINov || 'Nov',
        labels?.CalUIDec || 'Dec',
      ],
      dayNamesMin: [
        labels?.CalUISu || 'Su',
        labels?.CalUIMo || 'Mo',
        labels?.CalUITu || 'Tu',
        labels?.CalUIWe || 'We',
        labels?.CalUITh || 'Th',
        labels?.CalUIFr || 'Fr',
        labels?.CalUISa || 'Sa',
      ],
    };
    this.primengConfig.setTranslation(calendarLocale);
  }
  getStaticJsonFromLocale() {
    this.getJSON().subscribe((res) => {
      this.languageLabels.next(res['labels']);
    });
  }
  getTenantLanguages() {
    const requestOptions = this.getRequestOptions();
    this.http.get(this.api?.getTenantLanguages, requestOptions).subscribe((res: any) => {
      this.tenantLanguages.next(res);
    });
  }
  getExcludedLanguages() {
    /* istanbul ignore next */
    this.http.get(this.api?.getExcludedLanguages).subscribe((res: any) => {
      this.getAllExcludedLanguages.next(res);
    });
  }

  deleteLanguage(deleteList: any) {
    let tenantName = localStorage.getItem('TenantName');
    const headerDict = {
      tenant: tenantName,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
      body: deleteList,
    };

    this.http.delete(this.api?.deleteLanguage, requestOptions).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.alertService.showToaster(res?.message, '', 'success');
        this.getUpdatedLanguageList.next(res);
      }
    });
  }
  addLanguages(selectedLanguage: any) {
    const requestOptions = this.getRequestOptions();
    this.http.post(this.api?.addLanguage, selectedLanguage, requestOptions).subscribe((res: any) => {
      if (res) {
        this.alertService.showToaster(res?.message, '', 'success');
        this.getUpdatedLanguageList.next(res);
      }
    });
  }
  getRequestOptions() {
    let tenantName = localStorage.getItem('TenantName');
    const headerDict = {
      tenant: tenantName,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return requestOptions;
  }

  updateTenantStaticLabel(payload: any, tagName: string, langCode: string) {
    this.http.post(this.api?.tenantLabelsUpdate + `/${tagName}` + `/${langCode}`, payload).subscribe((res: any) => {
      if (res?.labels) {
        this.alertService.showToaster(`Labels updated succesfully`, '', 'success');
      }
    });
  }

  getLabelsData(lang: any) {
    lang = lang ? lang.toUpperCase() : 'EN';
    this.http.get(this.api?.getStaticLabels + lang).subscribe((res) => {
      this.languageLabelsData.next(res['labels']);
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
