import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Entity, TranslatorService, AlertService, GsiFacadeService, LoaderService, getParticipatingItems, gsiMapper, constructRightsObj, NodeGsiFacadeService } from '@common-services';
import { IDesignRights, IRecommendations } from '../../models/node-canvas.model';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-node-gsi-form',
  templateUrl: './node-gsi-form.component.html',
  styleUrls: ['./node-gsi-form.component.scss'],
})
export class NodeGsiFormComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  @Input() showTriggerCESLayer: any;
  @Input() gsiData: any;
  @Input() isEmbedded: boolean;
  layerType: string;
  extractedGsiObj: any;
  /*istanbul ignore next*/
  @Input()
  set _layerType(layerType: string) {
    if (layerType) {
      this.layerType = layerType;
    }
  }
  labels: any;
  agentName: any = '';
  agentSearchCriteria = new Subject<string>();
  sentenceSuggestion = new Subject<string>();
  designRights: IDesignRights = {
    decisionRight: true,
    disableParentRoleAccess: false,
    executionRight: false,
    informationRight: true,
    rightHolderType: 'ROLE',
    rightHolderName: '',
    rightHolderId: '',
  };
  txnRights: IDesignRights = {
    decisionRight: false,
    disableParentRoleAccess: false,
    executionRight: true,
    informationRight: true,
    rightHolderType: 'ROLE',
    rightHolderName: '',
    rightHolderId: '',
  };
  newAgent: any = {
    name: '',
    isEnabled: true,
    isAdmin: false,
    users: [],
    groups: [],
    description: '',
  };
  isVisible: boolean;
  gsiSentence: string = '';
  gsiInfoSentence: string = '';
  showInfoLayer: boolean;
  showILTagsView: boolean;
  showTriggerCES: boolean;
  gsiCompositeExtraction: any;
  agent: any;
  reservedCU: any;
  agentIndex: any;
  agentId: string;
  isCuInputFocus: boolean = true;
  nonReservedCuData: any;
  entityList: any = [{ ...new Entity() }];
  clickThreeDots: boolean = false;
  gsiThreeDotFlag = false;
  @Output() expandGsiDiv: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private translator: TranslatorService,
    private gsiFacade: NodeGsiFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private alertService: AlertService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private loader: LoaderService,
    private nodeGsiFacade: NodeGsiFacadeService
  ) {
    this.detectLanguageChange();
    this.getDraftGsiData();
    this.detectCloseDisplayTags();
    this.inputGSIAgentValidation();
    this.detectGSIDraft();
    this.detectEntityFromlib();
    this.detectAgentFromLibrary();
  }

  ngOnInit(): void {
    this.checkLayersData();
    this.gsiData.isEditable = false;
    /* istanbul ignore next */
    if (!this.gsiData.sentenceTags) {
      this.gsiSentence = this.gsiData.description;
    }
    this.extractSentece();
    /* istanbul ignore next */
    this.getGsiById(this.gsiData?.id);
  }
  checkLayersData() {
    this.gsiData.showInfoTagsView = this.gsiData?.layers?.find((x: any) => x.type === 'information') ? true : false;
    this.gsiSentence = '';
    /* istanbul ignore next */
    this.gsiData.sentenceTags?.physical?.forEach((sentence: any) => {
      this.gsiSentence += sentence.token + ' ';
    });
    /* istanbul ignore next */
    this.gsiSentence = this.gsiSentence.trim() === '' ? this.gsiData.displayName : this.gsiSentence.trim();
    this.gsiInfoSentence = '';
    /* istanbul ignore next */
    this.gsiData.sentenceTags?.information?.forEach((sentence: any) => {
      this.gsiInfoSentence += sentence.token + ' ';
    });
    this.gsiInfoSentence = this.gsiInfoSentence.trim();
    /* istanbul ignore next */
    this.showInfoLayer = this.gsiData.layers?.find((x: any) => x.type === 'information') ? true : false;
    if (this.gsiData.isReserved) {
      this.showInfoLayer = false;
    }
    if (this.showTriggerCESLayer) {
      this.showTriggerCES = this.showTriggerCESLayer;
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  extractSentece() {
    if (this.gsiData?.sentenceTags) {
      let tags = JSON.parse(JSON.stringify(this.gsiData?.sentenceTags));
      let text = '';
      /*istanbul ignore next*/
      tags?.physical?.forEach((val: any) => {
        text = text + ' ' + val.token;
      });
      if (!this.gsiSentence) {
        this.gsiSentence = text;
      }
    }
  }
  // hide display tags when changeunit display tags are shown
  detectCloseDisplayTags() {
    this.changeunitFacade.editableCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((index: number) => {
      if (index != -1 && this.gsiData) {
        this.gsiData.showTagsView = false;
        this.gsiData.isEditable = false;
        this.gsiData.showInfoTagsView = false;
      }
    });
  }

  getGSIInfo(event: any) {
    event.isEditable = true;
    if (event.sentenceTags) {
      this.changeunitFacade.editableCU(-1);
      this.gsiData.showTagsView = true;
    } else {
      this.gsiData.showTagsView = false;
    }
    this.checkLayersData();
    this.gsiFacade.isForGsi(true);
  }
  showGsiInfo(event: any) {
    event.stopPropagation();
    this.gsiData.showInfo = true;
    this.gsiData.showInfoTagsView = true;
  }
  hideGsiInfo(event: any) {
    event.stopPropagation();
    this.gsiData.showInfo = false;
  }
  createGsiAgent(event: any, appendAgentName: boolean) {
    this.newAgent.name = event.target.value;
    this.newAgent.description = this.newAgent.name;
    this.outsideGsiClick();
  }
  outsideGsiClick() {
    this.isVisible = false;
  }
  insideGsiClick() {
    this.isVisible = true;
  }

  validateGsi(name: string) {
    /* istanbul ignore if */
    if (!name) {
      this.alertService.showToaster(
        this.labels
          ?.Agent_Object_Attributes_missing_Complete_the_sentence_using_4_building_blocks_After_the_CU_name_add_the_Agent_and_the_object_with_its_attributes,
        '',
        'error'
      );
    } else {
      // let formData: FormData = new FormData();
      // formData.append('text', name);
      this.gsiData.showTagsView = true;
      let res = getParticipatingItems(this.gsiData, 'physical', name);
      this.gsiFacade.gsiValidation(res);
      this.expandGsiDiv.emit(false);
    }
  }
  expandDiv() {
    this.expandGsiDiv.emit(true);
  }
  detectGSIDraft() {
    this.gsiFacadeService.updateGSIData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && !this.isEmbedded) {
        this.gsiData = { ...res };
        this.checkLayersData();
      }
    });
  }

  getDraftGsiData() {
    this.gsiFacade.gsiDraftResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && !this.isEmbedded) {
        this.gsiData = { ...res };
        this.checkLayersData();
      }
    });
  }
  getGsiById(gsiId: any) {
    // //this.loader.show()sub;
    if (!gsiId) {
      // this.loader.hide();
      return;
    }
    if (this.isEmbedded && this.gsiData.referencedChangeUnit) {
      gsiId = this.gsiData.referencedChangeUnit;
    }
    this.gsiFacade
      .getGSIDetailsForForm(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          // this.loader.hide();

          this.gsiData = gsiMapper(res?.result);
        },
        (err: any) => {
          this.loader.hide();
        }
      );
  }
  hideDisplayTags() {
    this.gsiData.isEditable = false;
    this.gsiData.showTagsView = false;
    this.gsiFacade.saveGsi(this.gsiData);
    this.expandGsiDiv.emit(false);
  }
  openGsiPopUp() {
    this.gsiThreeDotFlag = true;
  }
  closeGsiPopup() {
    this.gsiThreeDotFlag = false;
  }
  editGsi(value: any) {
    this.gsiData.type = 'gsiLevel';
    if (value == 'edit') {
      this.gsiData.isEditable = true;
      this.gsiData.showTagsView = false;
      this.gsiData.infoLayerOpen = false;
    } else if (value == 'IF') {
      this.gsiData.infoLayerOpen = true;
      this.showInfoLayer = true;
    }
    this.closeGsiPopup();
  }

  handleOnGsiDeleteTag(event: string) {
    this.gsiSentence = event;
    this.validateGsi(this.gsiSentence);
  }
  handleGsiInfoEditTagsView(event: boolean) {
    /*istanbul ignore else*/
    if (event) {
      this.gsiData.showInfoTagsView = false;
    }
  }
  handleOnGsiDeleteTagInfo(event: string) {
    this.gsiInfoSentence = event;
    this.validateGsiIL();
  }
  validateGsiIL() {
    this.showILTagsView = true;
    this.gsiData.showInfoTagsView = true;
    let formData: FormData = new FormData();
    formData.append('text', this.gsiInfoSentence);
    // let res = getParticipatingItems(this.gsiData, 'information', this.gsiInfoSentence);
    this.changeunitFacade.validateIL(formData);
  }
  openIFPopUp() {
    this.clickThreeDots = true;
  }
  closeIFPopup() {
    this.clickThreeDots = false;
  }
  editGsiInfoLayer() {
    this.gsiData.type = 'gsiLevel';
    this.gsiData.infoLayerOpen = true;
    this.showInfoLayer = true;
    this.gsiData.showInfoTagsView = false;
    this.closeIFPopup();
  }
  handleGsiInfoLyer(event: any) {
    if (event == 'gsiLayerInfoEmpty') {
      this.showInfoLayer = false;
      this.gsiFacade.saveGsi(this.gsiData);
    }
  }
  deleteGsiInfoLayer() {
    this.gsiData?.layers?.forEach((layer: any, j: number) => {
      /*istanbul ignore else*/
      if (layer.type == 'information') {
        this.gsiData.layers.splice(j, 1);
      }
    });
    /*istanbul ignore next*/
    if (this.gsiData?.sentenceTags?.information) {
      this.gsiData.sentenceTags.information = [];
    }
    this.gsiInfoSentence = '';
    this.gsiFacade.saveGsi(this.gsiData);
    this.closeIFPopup();
  }
  inputGSIAgentValidation() {
    this.gsiFacade.gsiCompositeExtraction$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.gsiCompositeExtraction = res;
        this.agent = res?.extraction?.CU_list[0]?.agent[0]?.name;
        this.agentIndex = res?.extraction?.CU_list[0]?.agent[0]?.id;
        this.extractedGsiObj = res?.extraction?.CU_list[0];

        /* istanbul ignore next */
        if (this.agent === '$missing') {
          this.agent = '';
        } else if (this.agent === 'machine') {
          this.gsiData.agents[0].agentType = this.agent;
        } else {
          this.handleGsiSelectAgent(
            {
              name: res?.extraction?.CU_list[0]?.agent[0]?.name,
              id: res?.extraction?.CU_list[0]?.agent[0]?.id,
            },
            false
          );
        }
        this.nonReservedCuData = res;
        this.gsiData.showTagsView = true;
      }
    });
  }
  handleGsiSelectAgent(event: IRecommendations, appendAgentName: boolean) {
    this.agentName = event.name;
    this.agentId = event.id;
    this.gsiData.designTimeRights = [];
    this.gsiData.txnTimeRights = [];
    this.designRights.rightHolderId = this.agentId;
    this.designRights.rightHolderName = this.agentName;
    this.txnRights.rightHolderId = this.agentId;
    this.txnRights.rightHolderName = this.agentName;
    this.agent = event.name;
    /* istanbul ignore next */
    if (
      this.gsiData?.designTimeRights?.length == 0 ||
      (this.gsiData?.designTimeRights?.length > 0 &&
        this.gsiData.designTimeRights[0]?.rightHolderName != this.designRights?.rightHolderName)
    ) {
      this.gsiData.designTimeRights.push(this.designRights);
      this.gsiData.txnTimeRights.push(this.txnRights);
    }
    /* istanbul ignore next */
    if (appendAgentName && !this.gsiData?.description?.includes(event.name)) {
      this.gsiData.description = event.name + ' ' + this.gsiData?.description;
      this.gsiSentence = this.gsiData?.description;
    }
  }
  detectEntityFromlib() {
    this.nodeEntityFacadeService.entityAttributesGsi$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        if (this.gsiData.infoLayerOpen) {
          if (this.gsiData?.layers?.[1]?.length > 0) {
            this.gsiInfoSentence += '; and ';
          } else if (!(this.gsiInfoSentence.trim().endsWith('using') || this.gsiInfoSentence.trim().endsWith('with')))
            this.gsiInfoSentence += 'using';
          /* istanbul ignore next */
          this.gsiInfoSentence += ' ' + res.displayName + ' such as ';
          let i = res?.nslAttributes?.length - 1;
          res?.nslAttributes?.forEach((attr: any, index: number) => {
            this.gsiInfoSentence += attr.displayName;
            if (index < i) {
              this.gsiInfoSentence += ',';
            }
          });
          this.gsiInfoSentence = this.gsiInfoSentence.trim();
          this.validateGsiIL();
        } else {
          if (this.gsiData?.layers && this.gsiData?.layers?.length > 0) {
            this.gsiSentence += '; and ';
          } else if (!(this.gsiSentence.trim().endsWith('using') || this.gsiSentence.trim().endsWith('with')))
            this.gsiSentence += 'using';
          this.gsiSentence += ' ' + res.displayName + ' such as ';
          let i = res?.nslAttributes?.length - 1;
          res?.nslAttributes.forEach((attr: any, index: number) => {
            this.gsiSentence += attr.displayName;
            /* istanbul ignore next */
            if (index < i) {
              this.gsiSentence += ',';
            }
          });
          this.gsiSentence = this.gsiSentence.trim();
          this.validateGsi(this.gsiSentence);
        }
      }
    });
  }

  detectAgentFromLibrary() {
    this.nodeGsiFacade.selectedAgentinCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      console.log(res);
      /* istanbul ignore else */
      if (res && this.gsiData.isEditable) {
        let designInfo = constructRightsObj(res, 'design');
        let txnInfo = constructRightsObj(res, 'txn');
        /* istanbul ignore next */
        if (this.gsiData?.designTimeRights?.length > 0) {
          this.gsiSentence = ',' + this.gsiSentence;
        }
        this.gsiData.designTimeRights.push(designInfo);
        this.gsiData.txnTimeRights.push(txnInfo);
        /* istanbul ignore next */
        this.gsiSentence = res?.name + ' ' + this.gsiSentence;
        this.gsiData.description = this.gsiSentence;
        this.validateGsi(this.gsiSentence);
        this.nodeGsiFacade.CuIdForAgent = undefined;
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
