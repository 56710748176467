<ul>
  <li class="li-top-0">
    <div class="li-flex att-li">
      <ng-template #attrName>
        <p
          
          class="attlist-widt"
          id="dcd_label_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{
            nestedAttrName | stringReplace
          }}"
        >
          <span class="att-dcd-name" [matTooltipPosition]="'above'" matTooltipClass="tool-tip-custom" matTooltip="{{ attr?.name }}">{{ attr?.name | dcd }}</span>

          <span (click)="addAttributetoDcd(attr)" class="hoverplusimg-icon">
            <img
              [src]="'../../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
              alt=""
              id="dcd_plus_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{
                nestedAttrName | stringReplace
              }}"
              class="img-h25 hover-img hoverplusimg"
            />
          </span>
        </p>
      </ng-template>
      <mat-accordion class="w-100" *ngIf="attr?.generalEntity?.dsdId; else attrName">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="active-p">
              <p
                [matTooltipPosition]="'above'"
                matTooltipClass="tool-tip-custom"
                matTooltip="{{ attr?.name }}"
                id="dcd_label_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{
                  nestedAttrName | stringReplace
                }}"
                class="ent-widt"
              >
                {{ attr?.name | dcd }}
              </p>
              <span class="w-auto">
                <img
                  id="dcd_plus_{{ cuName | stringReplace }}.{{ layerName?.name | stringReplace }}.{{
                    nestedAttrName | stringReplace
                  }}"
                  (click)="addEntitytoDcd(attr)"
                  [src]="'../../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
                  alt=""
                  class="img-h25 hover-img hoverplusimg"
                />
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </li>
</ul>
