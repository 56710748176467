import { NgModule } from '@angular/core';
import { ApplicationGuardComponent } from './application-guard.component';



@NgModule({
  declarations: [
    ApplicationGuardComponent
  ],
  imports: [
  ],
  exports: [
    ApplicationGuardComponent
  ]
})
export class ApplicationGuardModule { }
