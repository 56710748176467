import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RolesFacadeService } from '../../roles/roles-facade.service';
import { SolutionDictionaryFacadeService } from '../../solution-dictionary/solution-dictionary-facade.service';
import { TranslatorService } from '../../translator/translator.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss'],
})
export class ConnectionComponent implements OnInit, OnDestroy {
  @Input() connectionsConflicted: any;
  @Output() changedconnections: EventEmitter<any> = new EventEmitter();
  @Input() saasapplication: boolean;
  private unsubscribe$: Subject<any> = new Subject<any>();
  private ngUnsubscribe = new Subject();

  conflicted: boolean = true;
  isResolvedcon: boolean[] = []; // array of conflict resolve for connections
  selectedNameconnection: boolean[] = [];
  indexdata: any[] = [];
  message: any;
  labels: any;
  connectionsList: any;
  currentIndex: number;
  betType: any;

  constructor(
    private router: Router,
    private translator: TranslatorService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private dialogRef: MatDialog,
    private rolesFacade: RolesFacadeService
  ) {
    this.detectLanguageChange();
    this.detectValidatedConnectionName();
    this.getAdapterType();
    this.getBetType();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnInit(): void {
    /* finding out if any conflicts present or not */
    /* istanbul ignore next */
    var conflictindex = this.connectionsConflicted?.findIndex(
      (x: any) => x.conflicting === true && x.isConflictResolved === false
    );

    /* istanbul ignore next */
    if (conflictindex === -1) {
      this.conflicted = false; // no conflict present
    }
  }

  moveToNextTab() {
    this.connectionsConflicted.forEach((element: any, ind: number) => {
      /*istanbul ignore next */
      if (element?.message) {
        delete element.message;
      }
      /*istanbul ignore next */
      if (element?.selectedNameconnection !== undefined) {
        delete element.selectedNameconnection;
      }
      /*istanbul ignore next */
      if (element?.isResolvedcon) {
        delete element.isResolvedcon;
      }
      return element;
    });
    this.changedconnections.emit(this.connectionsConflicted);
    this.solutionDictionaryFacade.setChangeTabIndex(9);
    // this.betConfiguration.changeTabIndex(9);
  }

  cancel() {
    if (!this.saasapplication) {
      if (this.betType === 'gsiImport') {
        this.rolesFacade.closeDialog(true);
        this.router.navigateByUrl('/admin/importsolution');
      } else {
        this.router.navigateByUrl('/solution-dictionary');
      }
    } else {
      this.solutionDictionaryFacade.saasapplicationBetSubect.next(null);
      this.router.navigateByUrl('admin/manage-saasapplications/addSaasApplication');
    }
    this.dialogRef.closeAll();
    this.solutionDictionaryFacade.closepopupDataService();
  }

  changeConnection(eve: any, data: any, index: any) {
    /* istanbul ignore next */
    if (eve.checked) {
      this.indexdata.push(index);
    } else if (!eve.checked) {
      this.indexdata.splice(index, 1);
      data.selectedNameconnection = false;
    }
  }

  renameConnection(i: number) {
    this.connectionsConflicted[i].selectedNameconnection = true;
    /* istanbul ignore next */
    this.connectionsConflicted[i].targetValue = this.connectionsConflicted[i]?.sourceValue;
    this.indexdata.push(i);
    // });
  }

  saveName(i: number) {
    this.currentIndex = i;
    this.saveNewConnectionName();
    // let adapterType = this.connectionsList[i].adapterType;
    // this.solutionDictionaryFacade.validateConnectionName(adapterType, this.connectionsConflicted[i].targetValue);
  }

  detectValidatedConnectionName() {
    this.solutionDictionaryFacade.validatedConnectionName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.saveNewConnectionName();
      }
    });
  }

  saveNewConnectionName() {
    for (var i = 0; i < this.indexdata.length; i++) {
      var data = this.indexdata[i];
      this.connectionsConflicted[data].conflictAction = 'RENAME';
      this.connectionsConflicted[data].isConflictResolved = true;
      this.connectionsConflicted[data].conflicting = true;
      this.connectionsConflicted[data].isResolvedcon = true;
      this.connectionsConflicted[data].selectedNameconnection = false;
      this.message = 'Renamed from' + ' ' + this.connectionsConflicted[data].sourceValue;
      this.connectionsConflicted[data].message = this.message;
      this.indexdata.splice(i, 1);
    }
  }

  getAdapterType() {
    this.solutionDictionaryFacade.connectionsList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.connectionsList = res;
      }
    });
  }

  checkConflicts() {
    let flag: boolean = true;
    /* istanbul ignore next */
    this.connectionsConflicted?.forEach((connection: any) => {
      /* istanbul ignore next */
      if (connection?.isConflictResolved === false && connection?.conflicting === true) {
        flag = flag && connection.conflicting;
      }
    });
    return !flag;
  }

  getBetType() {
    this.solutionDictionaryFacade.betType$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.betType = res;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
