import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { RedirectionComponent } from './redirection.component';

@NgModule({
  declarations: [RedirectionComponent],
  imports: [ControlsMaterialModule],
  exports: [RedirectionComponent],
})
export class RedirectionModule {
  static entry = {
    redirection: RedirectionComponent,
  };
}
