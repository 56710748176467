import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { DeviceType } from '../modal/mcc';


@Injectable({
  providedIn: 'root',
})
export class UiCustomizeEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getMapBookData(pageno: number, rightHolderId: string, text?: string) {
    /*istanbul ignore next*/
    if (text) {
      return this.http.get(
        this.api?.getPaginateBooks +
          `?rightHolderId=${rightHolderId}&rightOwnerType=ROLE&pageNo=${pageno}&pageSize=5&searchCriteria=${text}&designMode=true&fromUpdatedTime=&userRight=EXECUTION_RIGHTS`
      );
    } else {
      return this.http.get(
        this.api?.getPaginateBooks +
          `?rightHolderId=${rightHolderId}&rightOwnerType=ROLE&pageNo=${pageno}&pageSize=5&searchCriteria=&designMode=true&fromUpdatedTime=&userRight=EXECUTION_RIGHTS`
      );
    }
  }

  getGSIsByBookId(bookId: any, rightHolderId: any) {
    // const options = {
    //   headers: new HttpHeaders({ bookId: `${bookId}` }),
    // };
    // return this.http
    //   .get(`${this.api?.saveBook}/${bookId}/gsi?pageNo=0&pageSize=10&searchCriteria=&designMode=false`, options)
    //   .pipe(pluck('result'));
    /* istanbul ignore next */
    return this.http.get(
      this.api?.getPaginateGSIsBasedOnROle +
        `?page=1&limit=1000&query=&rightHolderId=${rightHolderId}&rightOwnerType=ROLE&designMode=true&isPublished=true&bookId=${bookId}`
    );
  }

  getMapGSIsData(pageno: number, rightHolderId: string, text?: string, limit: number = 5) {
    /*istanbul ignore next*/
    if (text) {
      return this.http.get(
        this.api?.getPaginateGSIsBasedOnROle +
          `?page=${pageno}&limit=${limit}&query=${text}&rightHolderId=${rightHolderId}&rightOwnerType=ROLE&designMode=true&isPublished=true`
      );
    } else {
      return this.http.get(
        this.api?.getPaginateGSIsBasedOnROle +
          `?page=${pageno}&limit=${limit}&query=&rightHolderId=${rightHolderId}&rightOwnerType=ROLE&designMode=true&isPublished=true`
      );
    }
  }

  uploadFile(files: any, isReportProgress?: boolean) {
    /* istanbul ignore next */
    return isReportProgress
      ? this.http.post(this.api?.singleFileUpload, files,{
          reportProgress: true,
          observe: 'events',
        })
      : this.http.post(this.api?.singleFileUpload, files);
  }

  getDynamicFont(limit: number, pageNumber: number){
    return this.http.get(`${this.api.withFolder}fonts?limit=${limit}&page=${pageNumber}`);
  }


  getAllMediaLinks(limit: number, pageNumber: number) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.withFolder}MyresourceFolder?limit=${limit}&page=${pageNumber}`);
  }

  postPageLayout(data: any, isCopy: boolean) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.tenantPageLayout}?isCopy=${isCopy}`, data);
  }
  getMetaData(id: any, deviceType: DeviceType = 'DESKTOP') {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.getMetaData}/role/${id}?Device=${deviceType}`);
  }

  getPageData(id: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.tenantPageLayout + '/' + id);
  }

  deletePage(id: any, deviceType: DeviceType) {
    /* istanbul ignore next */
    return this.http.delete(`${this.api?.tenantPageLayout}/${id}?Device=${deviceType}`);
  }

  getAllPages(id: any, searchText: string) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.tenantPageLayout + `/role/${id}?limit=15&pageNumber=0&pageSize=5&pageName=` + searchText
    );
  }
  getCollectionById(name: string) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getCollectionByName + name);
  }
  getAllPagesByROle(id: any, searchText: string) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.tenantPageLayout + '/role/' + id + '/?pageNumber=0&pageSize=5&pageName=' + searchText
    );
  }
  getAllPaginatedPagesByRole(pageno: number, id: any, search: string, deviceType: DeviceType) {
    /* istanbul ignore next */
    return this.http.get(
      `${
        this.api?.tenantPageLayout
      }/role/${id}/?Device=${deviceType}&pageNumber=${pageno}&pageSize=${5}&pageName=${search}`
    );
  }

  postSiteMetaData(data: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.getMetaData, data);
  }

  /* AA entity data */
  getEntitySearcgData(data: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.getEntitySearchData, data);
  }

  postRoleWithLayout(data: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.setRoleTOLayout, data);
  }

  getLibraryTemplateDataById(layoutId: string) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.libraryLayout}/${layoutId}`).pipe(pluck('result'));
  }
  getAllLinks(limit: number) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.withFolder}MyresourceFolder?limit=${limit}`);
  }

  getMapEntitiesData(roleId: string, pageSize: number, pagenum: number, search?: string) {
    /* istanbul ignore next */
    if (search) {
      /* istanbul ignore next */
      return this.http.get<any>(
        `${this.api?.getAllEntitiesForRole}${roleId}?limit=${pageSize}&page=${pagenum}&searchTerm=${search}`
      );
    } else {
      /* istanbul ignore next */
      return this.http.get<any>(`${this.api?.getAllEntitiesForRole}${roleId}?limit=${pageSize}&page=${pagenum}`);
    }
  }

  getGsiIdFromMasterId(masterId: number, version?: string) {
    /* istanbul ignore next */
    if(version) {
      return this.http.get(`${this.api?.getGsiIdFromMasterId}/${masterId}?version=${version}`);
    }
    else {
      return this.http.get(`${this.api?.getGsiIdFromMasterId}/${masterId}`);
    }

  }

  deleteMyresourceImage(payload: any) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.deleteFolderImage}/fileAccess`, payload);
  }

  postCustomizeHeader(payload: any) {
    return this.http.post(this.api.header, payload);
  }
  saveCollectionMCC(payload: any) {
    return this.http.post(this.api.saveCollectionMCC, payload);
  }
  removeItemd(payload: any, collectionName: any) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.deleteItem}?collectionName=${collectionName}`, payload);
  }
  deleteCollection(name: string) {
    /* istanbul ignore next */
    return this.http.delete(`${this.api?.deleteCollection}/${name}`);
  }
  getAllCollections(pageNo: number, pageSize: number, searchCrietria: string) {
    /* istanbul ignore next */
    return this.http.get(
      `${this.api?.getAllCollections}pageNo=${pageNo}&pageSize=${pageSize}&searchCriteria=${searchCrietria}`
    );
  }

  getAllDashboards(collectionName: string) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getdashboardDetailsOnCollection + collectionName);
  }

  getDashboardCollections(pageNumber: any, pageSize: any, searchCriteria: any = '') {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.getCollections +
        'pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize +
        '&searchCriteria=' +
        searchCriteria
    );
  }

  getAllDashboardWidgets(dashbordId: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAllDashboardWidgets + dashbordId);
  }

  getAllCustomTemplates(templateType?: string) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.manageTemplatesByType}/${templateType}`);
  }
  saveFooter(data: any, deviceType: DeviceType, isCopy: boolean) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.saveFooter}?Device=${deviceType}&isCopy=${isCopy}`, data);
  }
  fetchFooter(id: string, deviceType?: DeviceType) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.saveFooter}/${id}?Device=${deviceType}`);
  }
  fetchFooterByRoleId(id: string, deviceType: DeviceType) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.saveFooter}/role/${id}?Device=${deviceType}`);
  }
  saveQuickLink(data: any) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.saveQuickLink}`, data);
  }

  getAggrigateValues(data: any, isTxnType: any) {
    /* istanbul ignore next */
    if (isTxnType) {
      /* istanbul ignore next */
      return this.http.post(this.api?.getAggrigateValues, data);
    } else {
      /* istanbul ignore next */
      return this.http.post(this.api?.getGEAggrigateValues, data);
    }
  }
  getAttributes(data: any, isTxnType: any) {
    /* istanbul ignore next */
    if (isTxnType) {
      /* istanbul ignore next */
      return this.http.post(this.api?.getAttributes, data);
    } else {
      /* istanbul ignore next */
      return this.http.post(this.api?.getGEAttributes, data);
    }
  }

  getMCCDashboard() {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.getDashboardsByTenantID}`);
  }
  getLanguageData(data: any, language: string): any {
    let headers = new HttpHeaders().set('originating-urikey', 'cdui').set('originating-http-method', 'POST');
    const option = { headers: headers };
    return this.http.post(
      `${this.api?.enviroment}/translator/translations?sourceLanguage=EN&targetLanguage=${language}`,
      data,
      option
    );
  }
  fetchQuickLink(id: any) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.getQuickLink}/${id}?Device=DESKTOP`);
  }
  deleteQuickLinks(roleId: any, deviceType: string) {
    /* istanbul ignore next */
    return this.http.delete(this.api?.deleteQuickLinks + `${roleId}?Device=${deviceType}`);
  }

  getMapFiltersData(entityCardId: any, pageSize: number, pagenum: number, search?: string) {
    /* istanbul ignore next */
    if (search) {
      /* istanbul ignore next */
      return this.http.get<any>(
        `${this.api?.getAllEntitiesForRole}${entityCardId}/global/filters?filterId=&filterName=${search}`
      );
    } else {
      /* istanbul ignore next */
      return this.http.get<any>(
        `${this.api?.getFilterForEntityCard}${entityCardId}/global/filters?pageNumber=${pagenum}&pageSize=${pageSize}`
      );
    }
  }

  saveOrUpdateFilter(filterName: any, filterId: any, data: any) {
    /* istanbul ignore next */
    if (filterId) {
      /* istanbul ignore next */
      return this.http.put(`${this.api?.getFilterForEntityCard}global/filters?filterId=${filterId}`, data);
    } else {
      /* istanbul ignore next */
      return this.http.post(`${this.api?.getFilterForEntityCard}global/filters?filterName=${filterName}`, data);
    }
  }

  deleteFilter(entityCardId: any, filterId: any) {
    /* istanbul ignore next */
    return this.http.delete(`${this.api?.getFilterForEntityCard}${entityCardId}/global/filters/${filterId}`);
  }

  getFilter(entityCardId: any, filterId: any) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.getFilterForEntityCard}${entityCardId}/global/filters/${filterId}`);
  }
  validateGsi(id: number) {
    return this.http.get(`${this.api?.gsiTrxData}/validate/gsi/${id}`);
  }
  getGsiTrxData(masterId: number, id: number) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(`${this.api?.gsiTrxData}/gsi/txnData/${masterId}/${id}`, headers);
  }
  getGSIDetailsById(id: any) {
    return this.http.get(`${this.api?.saveGsi}/${id}`);
  }

  getGsiVersionsList(masterId: any, data: any, rightHolderId: any) {
    return this.http.get(
      `${this.api?.gsiVersionsList}${masterId}?statusList=PUBLISHED,ARCHIVED&limit=15&ontology=&page=${data.pageNo}&rightHolderId=${rightHolderId}&rightOwnerType=ROLE&designMode=false&versionSearchCriteria=${data?.searchCriteria}`
    );
  }
}
