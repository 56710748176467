import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { DialogData } from '../node-bet-edit-popup/node-bet-edit-popup.component';
@Component({
  selector: 'app-node-compare',
  templateUrl: './node-compare.component.html',
  styleUrls: ['./node-compare.component.scss'],
})
export class NodeCompareComponent implements OnInit {
  lowerBetProperties: any = { defaultValue: '', constraints: [] };
  upperBetProperties: any = { defaultValue: '', constraints: [] };
  property: string = '';
  ngUnsubscribe = new Subject();
  labels: any = {};
  @Input() status: string = '';
  merged: boolean = false;
  data: any;
  constructor(
    private translator: TranslatorService,
    public Dialog: MatDialog,
    public dialogRef: MatDialogRef<NodeCompareComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData
  ) {}

  ngOnInit(): void {
    this.data = this.dialog;
    this.status = this.data.status;
    this.initDialog();
  }
  initDialog() {
    this.labels.lowerBet =
      this.status == 'DRAFT' ? 'CHANGE UNIT-ENTITY DIFFERENCE' : 'TRIGGER CU-CHANGE UNIT DIFFERENCE';
    this.labels.upperBet = this.status == 'DRAFT' ? 'CHANGE UNIT LEVEL' : 'TRIGGER CU LEVEL';
    this.lowerBetProperties = this.data?.lowerBetProperties;
    this.upperBetProperties = this.data?.upperBetProperties;
    this.property = this.data?.property;
  }

  mergeLowertoUpper(prop: string) {
    this.upperBetProperties = [];
    if (prop == 'DEFAULT VALUE') {
      this.upperBetProperties.push(this.lowerBetProperties?.defaultValue);
    } else if (prop == 'ATTRIBUTE CONSTRAINTS') {
      this.upperBetProperties = this.lowerBetProperties?.constraints;
    }
    this.data.upperBetProperties = this.upperBetProperties;
    this.merged = true;
  }
  closePopup() {
    this.dialogRef.close(this.data);
  }
  ngOnDestroy() {
    this.merged = false;
    this.dialogRef.close(this.data);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
