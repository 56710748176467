import { Component, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GsiFacadeService } from '../gsi/gsi-facade.service';

@Component({
  templateUrl: './bpmn-view.component.html',
})
export class BpmnViewComponent implements OnInit, OnDestroy {
  diagram: any;
  gsiId: number;
  private ngUnsubscribe = new Subject();

  constructor(private gsiFacade: GsiFacadeService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.gsiFacade.bpmnDiagramData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.diagram = res;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getGsiData(): void {
    this.gsiFacade.exportGsis(this.gsiId);
    this.gsiFacade.exportGsi$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.diagram = res;
    });
  }
}
