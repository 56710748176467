import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AlertService, GsiButton, isSearchAttributeVal, TranslatorService } from '@common-services';
import { templateExpandMap } from '@common-services';
import { LoaderService } from '@common-services';
import { RolesFacadeService } from '@common-services';
import { EntityEndpointService } from '@common-services';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { EntityBoardEndpointService } from '@common-services';
import { EntityBoardFacadeService } from '@common-services';
import { DropBase } from '../drop-base/drop-base';
import { IFonts } from '../constatnts/customize-templates.constants';
import { BaseDropComponent } from '../base-drop/base-drop.component';
import { ColumnStyle } from '@common-services';

@Component({
  selector: 'app-table-drop',
  templateUrl: './table-drop.component.html',
  styleUrls: ['./table-drop.component.scss'],
})
export class TableDropComponent extends BaseDropComponent implements OnInit {
  headerHovered: boolean = false;
  footerHovered: boolean = false;
  isIconOnlyEnabled:boolean =false;
  hovered: boolean = false;
  dropMappedActionBts: any = [];
  headerFontColor: string;
  headerFontHoverColor: string;
  headerHoverColor: string;
  nestedRowHeaderFontColor: string;
  nestedRowHeaderFontHoverColor: string;
  nestedRowHeaderHoverColor: string;
  oddRowFontColor: string;
  oddRowFontHoverColor: string;
  oddRowHoverColor: string;
  evenRowFontColor: string;
  evenRowFontHoverColor: string;
  evenRowHoverColor: string;
  colorLabel: string;
  evenHovered: number;
  oddHovered: number;
  backgroundColor: string = '#ffffff';
  borderColor: string = '#000000';
  borderHoverColor: string = '#000000';
  textColor: string = '#000000';
  textHover: string = '#000000';
   backgroundHover: string = '#000000';
  colorModel: any = '#000000';
  entitytitletoggle: boolean = false;
  EntityTitle: string;
  nslAttributesTable: any[] = [];
  sortFields: any[] = [];
  searchTableFields: any[] = [];
  searchFieldsPlacement: any;
  filterFields: any[] = [];
  facetFields: any[] = [];
  advancedFeaturesTable: any;
  settings: any;
  extraWidthPercent: string;
  conditionTextColorToggler: boolean = false;
  gridLines: {
    outLine: any;
    rows: any;
    column: any;
    selectedStyle: string;
  } = {
    outLine: {
      selected: false,
      selectedOutLine: false,
      borderRadius: 0,
      borderThickness: 1,
      borderColor: '#000000',
    },
    rows: {
      selected: false,
      borderRadius: 0,
      borderThickness: 1,
      borderColor: '#000000',
    },
    column: {
      selected: false,
      borderRadius: 0,
      borderThickness: 1,
      borderColor: '#000000',
    },
    selectedStyle: '',
  };
  showPagination: boolean = false;
  alignmentOptions: any = [
    { class: 'nh-icon nh-icon-left-align', justify: 'left', viewClass: 'flex-start' },
    { class: 'nh-icon nh-icon-center-align', justify: 'center', viewClass: 'center' },
    { class: 'nh-icon nh-icon-right-align', justify: 'right', viewClass: 'flex-end' },
  ];
  alignment: any = { class: 'nh-icon nh-icon-left-align', justify: 'left', viewClass: 'flex-start' };
  isBold: boolean = false;
  isUnderLine: boolean = false;
  isItalic: boolean = false;
  fontSize: number = 16;
  tableFreezePosition: string = 'top';
  freezeAttribute: any;
  paginationRowSize: number = 15;
  paginatedAlignment: any = { class: 'nh-icon nh-icon-left-align', justify: 'left', viewClass: 'flex-start' };
  headerDescription: string;
  headerTempDescription: string = 'Description : Lorem ipsum dolor sit amet, consectetuer adipiscing elit';
  footerTempDescription: string = 'Description : Lorem ipsum dolor sit amet, consectetuer adipiscing elit';
  footerDescription: string;
  legendAlignmentOptions: any = [
    { class: 'nh-icon nh-icon-left-align', justify: 'left', viewClass: 'flex-start' },
    { class: 'nh-icon nh-icon-center-align', justify: 'center', viewClass: 'flex-center' },
    { class: 'nh-icon nh-icon-right-align', justify: 'right', viewClass: 'flex-end' },
  ];
  advanceTableFeatures: {
    multipleSelection: boolean;
    columnHighlights: boolean;
    attributeWrap: boolean;
    headerNoWrap: boolean;
    bodyNoWrap: boolean;
    serialNumbers: boolean;
    checkboxSelection: boolean;
    showLabels: boolean;
    hideDeleteIcon: boolean;
    allowExpand: boolean;
    allowUpload: boolean;
    hideSelectButton: boolean;
    allowDuplication: boolean;
    deleteRecord: boolean;
    freezeSeNum: boolean;
    freezeSelection: boolean;
    showResetPagination: boolean;
    updateTableData?: boolean;
    hidePagination?: boolean;
    singlePageTable?: boolean;
    multiRowsVerticalTable?:boolean;
    tableTemplate?: string;
  } = {
    multipleSelection: false,
    columnHighlights: false,
    // rowhighlights: false,
    attributeWrap: false,
    // rowalternate: false,
    headerNoWrap: false,
    bodyNoWrap: false,
    serialNumbers: false,
    checkboxSelection: false,
    showLabels: false,
    hideDeleteIcon: false,
    allowExpand: false,
    allowUpload: false,
    hideSelectButton: false,
    allowDuplication: false,
    deleteRecord: true,
    // gridTable: false,
    showResetPagination: false,
    freezeSelection: false,
    freezeSeNum: false,
    updateTableData: false,
    hidePagination: false,
    singlePageTable: false,
    multiRowsVerticalTable:false,
  };
  menuIcon:string = "";
  gsiConfigurations: {
    gsiPosition: 'onTop' | 'inline' | 'combine' | 'combineInline';
    combineName: string;
    isIconOnlyEnabled:boolean;
    isIconWithName: boolean;
    iconLink:string;
    gsiInlineTitle: string;
    hideAttrName?: boolean;
  } = {
    gsiPosition: 'onTop',
    gsiInlineTitle: 'Action',
    combineName: 'More',
    isIconOnlyEnabled:false,
    iconLink: "",
    isIconWithName:false,
    hideAttrName: false,
  };
  gsiOnlyIcon: boolean;
  // gsiNameWithIcon: boolean;
  imgSrc: string = '';

  selectGsiIcon: string = 'cart';
  gsiForConfigure: string = '';
  isSelectGsi: boolean;
  isSelectGsiIndex: number;
  headerAlignment: any;
  nestedRowHeaderAlignment: any;
  evenRowAlignment: any;
  oddRowAlignment: any;
  headerFontSize: number = 15;
  nestedRowHeaderFontSize: number = 15;
  evenRowFontSize: number = 15;
  oddRowFontSize: number = 15;
  headerFontFamily: IFonts;
  nestedRowHeaderFontFamily: IFonts;
  evenRowFontFamily: IFonts;
  oddRowFontFamily: IFonts;
  leftColumnFontFamily: IFonts;
  headerUnderLine: boolean;
  nestedRowHeaderUnderLine: boolean;
  evenRowUnderLine: boolean;
  oddRowUnderLine: boolean;
  headerBold: boolean;
  nestedRowHeaderBold: boolean;
  evenRowBold: boolean;
  oddRowBold: boolean;
  headerItalic: boolean;
  nestedRowHeaderItalic: boolean;
  evenRowItalic: boolean;
  oddRowItalic: boolean;
  selectedConditionTextColor: any = '#fff';
  toggleHeaderChecked: boolean = false;
  toggleFooterChecked: boolean = false;
  conditioncColorToggler: boolean = false;
  sizes: any[] = [
    { name: 'Small', class: 'p-datatable-sm' },
    { name: 'Normal', class: '' },
    { name: 'Large', class: 'p-datatable-lg' },
  ];
  headerDescriptionBackgroundSelected: boolean = false;
  headerDescriptionBackground: string;
  headerDescriptionFontColor: string;
  headerDescriptionFontHoverColor: string;
  headerDescriptionHoverColor: string;
  headerDescriptionFontStyle: IFonts;
  headerDescriptionBold: boolean;
  headerDescriptionItalic: boolean;
  headerDescriptionUnderline: boolean;
  headerDescriptionAlignment: any;
  headerDescriptionFontSize: number;
  footerDescriptionBackgroundSelected: boolean = false;
  footerDescriptionBackground: string;
  footerDescriptionFontColor: string;
  footerDescriptionFontHoverColor: string;
  footerDescriptionHoverColor: string;
  footerDescriptionFontStyle: IFonts;
  footerDescriptionBold: boolean;
  footerDescriptionItalic: boolean;
  footerDescriptionUnderline: boolean;
  footerDescriptionAlignment: any;
  footerDescriptionFontSize: number;
  defaultGsiImages = {
    cart: '../../../../../assets/img/general-entity/Advance-features/cart.svg',
    heart: '../../../../../assets/img/general-entity/Advance-features/heart.svg',
    bookmark: '../../../../../assets/img/general-entity/Advance-features/bookmark.svg',
    payment: '../../../../../assets/img/general-entity/Advance-features/payment.svg',
  };
  columnHovered: number;
  firstColumnFontColor: any;
  firstColumnHoverColor: any;
  firstColumnFontHoverColor: any;
  firstColumnFamily: any;
  firstColumnFontSize: any;
  firstColumnBold: any;
  firstColumnItalic: any;
  firstColumnUnderLine: any;
  firstColumnAlignment: any;
  paginationJustify: {
    allignment: 'right';
  };
  verticalTableConfig: any;
  isMore: boolean = false;
  inTableGSIButtons: boolean = false;
  gsiBtnBackgroundColor: any = [];
  gsiBtnFontColor: any = [];
  gsiBtnBackgroundHoverColor: any = [];
  gsiBtnFontHoverColor: any = [];
  gsiBtnHovered: any = [];
  gsiBtnAlignment: any;
  gsiBtnFontSize: number = 15;
  gsiBtnFontFamily: IFonts;
  gsiBtnBold: boolean;
  gsiBtnItalic: boolean;
  gsiBtnUnderline: boolean;
  gsiBtnBorderColor: any = [];
  gsiBtnHoverBorderColor: any = [];
  gsiBtnborderRadius: number = 0;
  gsiBtnborderWidth: number = 0;
  tableConfig: any;
  tableFeatures: {
    maximumselection: number;
    minimumselection: number;
    minimumselectionAll: boolean;
  } = {
    maximumselection: 0,
    minimumselection: 0,
    minimumselectionAll: false,
  };
  allowSelection: boolean;
  recordSelection: string;
  submitOnSelection: false;
  fontColor: string;
  textHoverColor: string;
  backgroundHoverColor: string;
  borderColorPicker: any = {
    column: false,
    rows: false,
    outline: false,
  };
  tableFreezeToggele: boolean = false;

  isNestedRow: boolean = false;
  isNestedRow1: boolean = false;
  activePagination: boolean = false;
  paginationHovered: boolean = false;
  enableAnalytics: boolean = false;
  nestedRowDropAttributes: any;
  nestedRowUiTypes: any;
  tableComponentScale: any;
  gsiBtnTopAlign: any;
  rowSize: any;
  gsiBtnChecked: boolean = false;
  gsiBtnRadius: any;
  gsiBtnselectedArray: boolean[] = new Array(this.gsiButtons?.length).fill(false);
  @ViewChildren('gsiActionBtn') gsiActionBtn: QueryList<ElementRef>;
  gsiNameWithIcon: boolean;
  actionBtnName: 'onlyIcon' | 'gsiNameWithIcon' | 'onlyName' = 'onlyName';
  attributeValue: string = '';
  isPreview: boolean = false;
  tableuiDesign: boolean = false;
  enableLegend: boolean = false;
  enableCompleteRecord: boolean = false;
  legendAlignment: any;
  fileObj: any;
  click$: Subject<any> = new Subject<any>();
  isColumnStyleVersion: boolean = true;
  tableHeight: number = 0;
  paginationStyle : any = {
    textHover: '',
    backgroundHover:'',
    font:'',
    background: '',
    border: '',
    borderHover: ''
  }
  constructor(
    router: Router,
    rolesFacadeService: RolesFacadeService,
    alertService: AlertService,
    entityBoardService: EntityBoardEndpointService,
    public dialog: MatDialog,
    loader: LoaderService,
    el: ElementRef,
    entityEndpointService: EntityEndpointService,
    entityBoardFacade: EntityBoardFacadeService,
    translator: TranslatorService,
    resolver: ComponentFactoryResolver
  ) {
    super(
      router,
      rolesFacadeService,
      alertService,
      entityBoardService,
      dialog,
      loader,
      el,
      entityEndpointService,
      entityBoardFacade,
      translator,
      resolver
    );
    this.detectDoubleClick();
    this.advanceTableFeatures.deleteRecord = this.tenantName == 'callhealth' ? false : true;
  }
  columnWidth: number = 0;
  detectDoubleClick() {
    this.click$
      .pipe(
        debounceTime(500),
        filter((list: any) => list.length === 2),
        map((list: any[]) => list[1])
      )
      .subscribe(this.editDisplayName);
  }

  editDisplayName(event: any) {
    this.selectedDisplayName = event.index;
  }

  /**
   * Handles the drop event when an attribute is dropped onto a table column.
   * @param event - The drop event object.
   * @param index - The index of the column where the attribute was dropped.
   */
  dropAttributeInTemplates(event: any, index: number) {
    event.stopPropagation();
    const target: HTMLElement = event.target as HTMLElement;
    const newAttribute = event.dataTransfer.getData('text');
    const attributeIndex = this.nslAttributes.findIndex((attr: any) => attr.name === newAttribute);
    if (attributeIndex === -1) {
      return;
    }
    if (target.innerHTML.trim() === 'Attribute') {
      target.innerHTML = newAttribute;
      const columnStyle = this.columnStyles[index];
      if(columnStyle){
        columnStyle.displayName = newAttribute;
        columnStyle.attributes = newAttribute;
      }
    } else {
      target.innerHTML = target.innerHTML.trim() + '.' + newAttribute;
      const columnStyle = this.columnStyles[index];
      if(columnStyle){
        columnStyle.displayName = target.innerHTML;
        columnStyle.sizes.push(12);
        columnStyle.Weight.push('normal');
        columnStyle.fontFamily.push(this.fonts[0]);
        columnStyle.fontSizes.push(18);
        columnStyle.seperators.push('');
        columnStyle.fontStyles.push('');
        columnStyle.isChip.push(false);
        columnStyle.colors.push('#000000');
        columnStyle.attributes = target.innerHTML;
        }
    }
    this.columnStyles?.[index]?.attributeList?.push(newAttribute);

    this.dropAttributes.push(this.nslAttributes[attributeIndex]);
    this.nslAttributes.splice(attributeIndex, 1);
  }

  onDeletingAttr(event: any, columnIndex?: number) {
    this.columnStyles[columnIndex].attributeList.forEach((attr: string) => {
      let attrIndex = this.dropAttributes.findIndex((attribute: any) => attribute.name == attr);
      if (attrIndex != -1) {
        this.nslAttributes.push(this.dropAttributes[attrIndex]);
        this.dropAttributes.splice(attrIndex, 1);
      }

    });
    this.columnStyles[columnIndex] = {
      ...JSON.parse(JSON.stringify(this.COLUMN_STYLES)),
      displayName: 'Attribute',
      attributes: '',
    };

    event.srcElement.parentElement.parentElement.children[0].innerHTML = 'Attribute';
  }
  onEditColumnName(index: number) {
    this.selectedDisplayName = index;
  }

  selectColumnAttribute(columnIndex: number, attributeInColumnStylesIndex: number) {
    if (this.selectedColumn == columnIndex && this.selectedColumnAttribute == attributeInColumnStylesIndex) {
      this.selectedColumn = -1;
      this.selectedColumnAttribute = -1;
      this.isAttributeLevelCustomizationActive = false;
    } else {
      this.selectedColumn = columnIndex;
      this.selectedColumnAttribute = attributeInColumnStylesIndex;
      this.isAttributeLevelCustomizationActive = true;
    }
  }

  decreaseAttributeSize() {
    let size = this.columnStyles[this.selectedColumn].sizes[this.selectedColumnAttribute];
    if (size <= 1) return;
    else this.columnStyles[this.selectedColumn].sizes[this.selectedColumnAttribute]--;
  }
  increaseAttributeSize() {
    let size = this.columnStyles[this.selectedColumn].sizes[this.selectedColumnAttribute];
    if (size >= 12) return;
    else this.columnStyles[this.selectedColumn].sizes[this.selectedColumnAttribute]++;
  }
  afterEditColumnName() {
    this.selectedDisplayName = -1;
  }
  getMapAttributes() {
    this.selectedEntityData?.nslAttributes.forEach((attribute: any, index: number) => {
      this.nslSourceAttributesMap.set(attribute?.name, index);
    });
  }
  // updateTableExisting(){
  //   if (
  //     this.entityData.data?.entityData?.configuration &&
  //     JSON.parse(this.entityData.data?.entityData?.configuration)?.configurationData
  //   ) {
  //     this.updateExistingConfiguration(
  //       JSON.parse(this.entityData.data?.entityData?.configuration)?.configurationData
  //     );
  //   }
  // }

  ngOnInit() {
    if (this.isVerticalTable) {
      this.selectedEntityData = this.entityData?.data?.entityData;
    } else {
      this.isEntityMccTable = true;
    }
    this.getMapAttributes();

    super.ngOnInit();
    if (this.isVerticalTable && this.isGrammer) {
      if (
        this.entityData.data?.entityData?.configuration &&
        JSON.parse(this.entityData.data?.entityData?.configuration)?.configurationData
      ) {
        this.updateExistingConfiguration(
          JSON.parse(this.entityData.data?.entityData?.configuration)?.configurationData
        );
      }
      this.getVerticalTableAttributes();
    }
    this.selectGsiList?.forEach((attr: any) => {
      this.gsiBtnHovered.push(false);
    });

    this.tableData.forEach((column: any) => {
      this.columnStyles.push({ ...JSON.parse(JSON.stringify(this.COLUMN_STYLES)), displayName: 'Attribute' });
    });
  }

  dropActionBtn(ev: any, childGsiMapping?: { [key: number]: GsiButton }, index?: number) {
    if (ev?.target?.innerHTML.trim() === 'Action Button' && this.dragAttrType === 'button') {
      ev.preventDefault();
      const data = ev.dataTransfer.getData('text');
      // ev.target.innerHTML = data;
      const foundIndex = this.selectGsiList.findIndex((x: any) => x.name === data);
      if (foundIndex != -1) {
        this.dropActionBts[index] = this.selectGsiList[foundIndex];
        this.selectGsiList.splice(foundIndex, 1);
      }
    }
  }
  deleteGsiActionBtn(index: any) {
    if (index !== -1 && this.dropActionBts[index]) {
      this.selectGsiList.push(this.dropActionBts[index]);
      this.dropActionBts.splice(index, 1);
    }
  }
  getMappedAttributes() {
    const listOfAttributes: any[] = [];
    const attributeStyle: any = {
      width: [],
    };
    const headerArray = this.dropTableData?.el?.nativeElement?.getElementsByTagName('th');
    /*istanbul ignore next*/
    if (headerArray) {
      Object.values(headerArray).forEach((headerElement: any) => {
        if (
          headerElement?.getElementsByClassName('entity-table-header-span')?.[0]?.innerHTML &&
          headerElement?.getElementsByClassName('entity-table-header-span')?.[0]?.innerHTML?.trim() != 'Attribute' &&
          headerElement?.id != 'nestedRowHeader'
        ) {
          listOfAttributes.push(headerElement?.getElementsByClassName('entity-table-header-span')?.[0].innerHTML?.trim());
          const style = getComputedStyle(headerElement);
          attributeStyle.width.push(style?.width === '' ? '186px' : style?.width);
          if(headerElement?.getElementsByClassName('entity-table-header-span')?.[0]?.innerHTML?.trim() == this.gsiConfigurations?.gsiInlineTitle
          && !this.isEntityMccTable && this.gsiConfigurations?.gsiPosition == 'inline')
          attributeStyle?.width?.splice(-1,1);
        }
      });
      this.dropMappedAttributes = listOfAttributes;
      if (this.columnStyles.length > 0) {
        this.columnStyles.forEach((columnStyle: ColumnStyle) => {
          columnStyle.attributeList.forEach((attr: string) => {
            const foundAttr = this.selectedEntityData?.nslAttributes?.find((obj: any) => obj.name == attr);
            if (foundAttr) {
              this.attributeTypes.push({
                name: foundAttr?.name,
                uiElement: foundAttr?.attributeType?.type,
                uiDataType: foundAttr?.attributeType?.uiElement?.uiElement,
                properties: foundAttr?.attributeType?.properties,
              });
            }
          });
        });
      } else {
        this.dropMappedAttributes.forEach((name: any) => {
          const foundAttr = this.selectedEntityData?.nslAttributes?.find((obj: any) => obj.name == name);
          this.attributeTypes.push({
            name: foundAttr?.name,
            uiElement: foundAttr?.attributeType?.type,
            uiDataType: foundAttr?.attributeType?.uiElement?.uiElement,
            properties: foundAttr?.attributeType?.properties,
          });
        });
      }
      this.nestedRowDropAttributes.forEach((name: any) => {
        const foundAttr = this.selectedEntityData?.nslAttributes?.find((obj: any) => obj.name == name);
        this.nestedRowUiTypes.push({
          name: foundAttr?.name,
          uiElement: foundAttr?.attributeType?.type,
          uiDataType: foundAttr?.attributeType?.uiElement?.uiElement,
          properties: foundAttr?.attributeType?.properties,
        });
      });
      this.widthOfTableArray = attributeStyle.width;
    }
    const bts = this.dropTableActionData?.nativeElement?.getElementsByClassName('action-btn');
    if (bts) {
      Object?.values(bts)?.forEach((btn: any) => {
        if (btn.innerHTML && btn.innerHTML.trim() != 'Action Button') {
          this.dropMappedActionBts.push(btn.innerHTML);
        }
      });
    }
  }

  getEntityPayload(): Object {
    return {
      cardName: 'default',
      configurationName: this.cardConfigName,
      childView: {
        attributeList: [],
        gsiList: [],
        style: this.getStyleConfig(),
        templateType: 'table',
      },
      gsiList: [],
      id: this.selectedEditCardID ? this.selectedEditCardID : '',
      locationFilter: this.locationFilterAttrData(),
      range: [],
      search: this.searchAttrData(),
      searchFields: this.searchFieldsAttrData(),
      filters: this.filterAttrData(),
      joinCriteria: {},
      joinedEntities: [],
      filterDesign: !this.isTable ? !this.isAdvancedFeature && this.filterDesign ? this.filterDesign: this.preparePayLoadForFilterSearch() : undefined,
      primaryEntity: {
        id: this.entityData.id,
        masterId: this.entityData.masterId,
        name: this.entityData.name,
        version: this.entityData.version,
      },
      roleId: this.selectedRoleId,
      sort: {
        sortOption: this.sortOption == '1' ? 'Ascending' : 'Descending',
        sortBy: [],
      },
    };
  }
  /* istanbul ignore next */

  saveEntityCard() {
    /* istanbul ignore next */
    if (this.chatbotData?.isSelected) {
      let isValid = true;
      /* istanbul ignore next */
      if (!this.chatbotData?.language) {
        this.alertService.showToaster('Please select language', '', 'error');
        isValid = false;
        return;
      }
      /* istanbul ignore next */
      if (this.chatbotData?.intents?.length === 0) {
        this.alertService.showToaster('Please add atleast one intent', '', 'error');
        isValid = false;
        return;
      }
      /* istanbul ignore next */
      this.chatbotData?.intents?.forEach((intent: any, intentIndex: any) => {
        /* istanbul ignore next */
        if (!intent?.intent) {
          this.alertService.showToaster(`Please write intent for Intent ${intentIndex + 1}`, '', 'error');
          isValid = false;
          return;
        }
        intent.filters = [];
        intent.filter_range_fixed = [];
        let anyAttrSelected = true;
        /* istanbul ignore next */
        intent?.attributes?.forEach((attr: any) => {
          /* istanbul ignore next */
          if (attr?.checked) {
            anyAttrSelected = true;
            intent.filters.push(attr.attributeName);
            intent.filter_range_fixed.push(attr.range);
          }
        });
        /* istanbul ignore next */
        if (!anyAttrSelected) {
          this.alertService.showToaster(
            `Please select atleast one attribute for Intent ${intentIndex + 1}`,
            '',
            'error'
          );
          isValid = false;
          return;
        }
        /* istanbul ignore next */
        if (intent?.attributes) {
          delete intent.attributes;
        }
      });
      /* istanbul ignore next */
      if (isValid) {
        delete this.chatbotData.isSelected;
        let TenantName = localStorage.getItem('TenantName');
        /* istanbul ignore next */
        this.rolesFacadeService.saveEntityCardConfigureChatbot(TenantName, this.chatbotData).subscribe((res: any) => {
          /* istanbul ignore next */
          if (res) {
            this.alertService.showToaster(res?.message, '', 'success');
          }
        });
      } else {
        return;
      }
    }

    let req: any = this.getEntityPayload();

    /*istanbul ignore next*/
    this.dropAttributes.forEach((attr: any) => {
      req.childView?.attributeList.push({
        attributeId: attr.id,
        attributeName: attr.name,
        attributeDisplayName: attr.displayName,
        id: this.entityData.id,
        masterId: this.entityData.masterId,
        name: this.entityData.name,
        version: this.entityData.version,
      });
    });
    /*istanbul ignore next*/
    if (this.dropActionBts) {
      let findGsi = this.dropActionBts.filter((gsi: any) => gsi.name !== 'Action Button');
      if (findGsi && findGsi.length > 0) {
        findGsi.forEach((gsi: any) => {
          req.childView.gsiList.push({
            id: gsi.id,
            masterId: gsi.masterId,
            name: gsi.name,
            version: gsi.version,
            displayName: gsi.displayName,
            popUp: gsi.enablePopup,
          });
        });
      }

      if (findGsi && findGsi.length > 0) {
        findGsi.forEach((gsi: any) => {
          req.gsiList.push({
            id: gsi.id,
            masterId: gsi.masterId,
            name: gsi.name,
            version: gsi.version,
            popUp: gsi.enablePopup,
            displayName: gsi.displayName,
            isUnrelated: gsi?.isUnrelated,
          });
          req.childView.style?.gsiact.push({
            id: gsi.id,
            masterId: gsi.masterId,
            name: gsi.name,
            version: gsi.version,
            popUp: gsi.enablePopup,
            displayName: gsi.displayName,
            configuration: gsi.gsiConfiguration,
            isUnrelated: gsi?.isUnrelated,
            entityType: gsi?.entityType,
          });
        });
      }
    }
    /*istanbul ignore next*/
    this.entityBoardService.saveEntityCard(req).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
      this.router.navigate(['/mylibrary-dashboard']);
    });
  }

  getStyleConfig(): any {
    /*istanbul ignore next*/
    const gsiStyle = {
      fontColor: this.gsiBtnFontColor,
      backgroundColor: this.gsiBtnBackgroundColor,
      gsiborderColor: this.gsiBtnBorderColor,
      gsihoverborderColor: this.gsiBtnHoverBorderColor,
      gsiborderRadius: this.gsiBtnborderRadius,
      gsiborderWidth: this.gsiBtnborderWidth,
      colorHover: this.gsiBtnBackgroundHoverColor,
      fontHover: this.gsiBtnFontHoverColor,
      text: {
        fontFamily: this.gsiBtnFontFamily,
        fontSize: this.gsiBtnFontSize,
        bold: this.gsiBtnBold,
        italic: this.gsiBtnItalic,
        underLine: this.gsiBtnUnderline,
        alignment: this.gsiBtnAlignment,
      },
    };
    return {
      attr: this.isVerticalTable ? this.mappedAttributesIdx : this.dropMappedAttributes,
      nestedAttr: this.nestedmappedAttributesIdx,

      attributeTypes: this.attributeTypes,
      gsiact: [],
      columnStyles: this.isVerticalTable ? [] : this.isColumnStyleVersion ? this.columnStyles : [],
      legendAlignment:this.legendAlignment,
      legendSelected:this.legendSelected,
      enableCompleteRecord:this.enableCompleteRecord,
      headerBackground: this.headerBackground,
      widthArray: this.widthOfTableArray,
      evenRowColor: this.evenRowColor,
      oddRowColor: this.oddRowColor,
      header: {
        fontColor: this.headerFontColor,
        colorHover: this.headerHoverColor,
        fontHover: this.headerFontHoverColor,
        text: {
          fontFamily: this.headerFontFamily,
          fontSize: this.headerFontSize,
          bold: this.headerBold,
          italic: this.headerItalic,
          underLine: this.headerUnderLine,
          alignment: this.headerAlignment,
        },
      },
      nestedRowHeader: {
        fontColor: this.nestedRowHeaderFontColor,
        colorHover: this.nestedRowHeaderHoverColor,
        fontHover: this.nestedRowHeaderFontHoverColor,
        text: {
          fontFamily: this.nestedRowHeaderFontFamily,
          fontSize: this.nestedRowHeaderFontSize,
          bold: this.nestedRowHeaderBold,
          italic: this.nestedRowHeaderItalic,
          underLine: this.nestedRowHeaderUnderLine,
          alignment: this.nestedRowHeaderAlignment,
        },
      },
      evenRow: {
        fontColor: this.evenRowFontColor,
        colorHover: this.evenRowHoverColor,
        fontHover: this.evenRowFontHoverColor,
        text: {
          fontFamily: this.evenRowFontFamily,
          fontSize: this.evenRowFontSize,
          bold: this.evenRowBold,
          italic: this.evenRowItalic,
          underLine: this.evenRowUnderLine,
          alignment: this.evenRowAlignment,
        },
      },
      oddRow: {
        fontColor: this.oddRowFontColor,
        colorHover: this.oddRowHoverColor,
        fontHover: this.oddRowFontHoverColor,
        text: {
          fontFamily: this.oddRowFontFamily,
          fontSize: this.oddRowFontSize,
          bold: this.oddRowBold,
          italic: this.oddRowItalic,
          underLine: this.oddRowUnderLine,
          alignment: this.oddRowAlignment,
        },
      },
      gridLines: this.gridLines,
      headerFreeze: {
        enabled: this.tableFreezeToggele,
        tableHeight: this.tableHeight ? this.tableHeight+ 'vh' : 0 ,
      },
      pagination: this.isVerticalTable
        ? {
            showPagination: this.showPagination,
            rows: this.paginationRowSize,
            alignment: this.paginatedAlignment,
          }
        : this.isTable
        ? {
            // showPagination: this.showPagination,
            rows: this.paginationRowSize,
            placementOfPagination: this.paginatedAlignment?.justify,
            // hidePagination: this.hidePagination,
          }
        : {
          showPagination: this.showPagination,
          rows: this.paginationRowSize,
          alignment: this.paginatedAlignment,
          style: this.paginationStyle,
        },
      description: {
        header: {
          message: this.toggleHeaderChecked ? this.headerDescription : null,
          style: {
            backgroundColor: this.headerDescriptionBackground,
            fontColor: this.headerDescriptionFontColor,
            colorHover: this.headerDescriptionHoverColor,
            fontHover: this.headerDescriptionFontHoverColor,
            text: {
              fontFamily: this.headerDescriptionFontStyle,
              fontSize: this.headerDescriptionFontSize,
              bold: this.headerDescriptionBold,
              italic: this.headerDescriptionItalic,
              underLine: this.headerDescriptionUnderline,
              alignment: this.headerDescriptionAlignment,
            },
          },
        },
        footer: {
          message: this.toggleFooterChecked ? this.footerDescription : null,
          style: {
            backgroundColor: this.footerDescriptionBackground,
            fontColor: this.footerDescriptionFontColor,
            colorHover: this.footerDescriptionHoverColor,
            fontHover: this.footerDescriptionFontHoverColor,
            text: {
              fontFamily: this.footerDescriptionFontStyle,
              fontSize: this.footerDescriptionFontSize,
              bold: this.footerDescriptionBold,
              italic: this.footerDescriptionItalic,
              underLine: this.footerDescriptionUnderline,
              alignment: this.footerDescriptionAlignment,
            },
          },
        },
      },
      tableDescription: {
        footerDescription: this.toggleFooterChecked ? this.footerDescription : null,
        headerDescription: this.toggleHeaderChecked ? this.headerDescription : null,
      },
      advanceTableFeatures: this.advanceTableFeatures,
      gsiConfigurations: this.isTable
        ? { ...this.gsiConfigurations, gsiStyle: gsiStyle, gsiBtnTopAlign: this.gsiBtnTopAlign }
        : { ...this.gsiConfigurations, gsiStyle: gsiStyle },
      nestedRow: this.isNestedRow1,
      nestedRowDropAttr: this.nestedRowDropAttributes,
      nestedRowUiTypes: this.nestedRowUiTypes,
      conditionArray: this.conditionArray,
      headerGsi:  Object.fromEntries(this.headerGsiMap),
    };
    // if (this.isVerticalTable)
    // obj = {...obj, tableComponentScale: this.tableComponentScale,
    // };
    // return obj;
  }
  /*istanbul ignore next*/
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedSearchFieldEntityData.nslAttributes, event.previousIndex, event.currentIndex);
  }

  setHeaderBackgroundSelected(event: MouseEvent, index: number) {
    this.click$.next({ ...event, index: index });
    this.headerBackgroundSelected = !this.headerBackgroundSelected;
  }
  setNestedRowHeaderBackgroundSelected() {
    this.nestedRowHeaderBackgroundSelected = !this.nestedRowHeaderBackgroundSelected;
  }
  setHeaderDescriptionBackgroundSelected() {
    this.headerDescriptionBackgroundSelected = !this.headerDescriptionBackgroundSelected;
  }
  setFooterDescriptionBackgroundSelected() {
    this.footerDescriptionBackgroundSelected = !this.footerDescriptionBackgroundSelected;
  }
  setRowBackGroundSelected(index: any) {
    if (index % 2 == 0) {
      this.evenRowSelected = !this.evenRowSelected;
    } else if (index % 2 == 1) {
      this.oddRowSelected = !this.oddRowSelected;
    }
  }
  setLegendSelected(){
    this.legendSelected = !this.legendSelected;
  }
  changeColor(event: any) {
    this.selectedColor = event;
    switch (this.colorLabel) {
      case 'background': {
        this.backgroundColor = this.selectedColor;
        break;
      }
      case 'font': {
        if (this.isAttributeLevelCustomizationActive) {
          this.columnStyles[this.selectedColumn].colors[this.selectedColumnAttribute] = this.selectedColor;
        } else {
          this.fontColor = this.selectedColor;
        }
        break;
      }
      case 'backgroundHover': {
        this.backgroundHoverColor = this.selectedColor;
        break;
      }
      case 'textHover': {
        this.textHoverColor = this.selectedColor;
        break;
      }
    }
    if (this.headerBackgroundSelected) {
      this.applyHeaderColor(event);
    }
    if (this.nestedRowHeaderBackgroundSelected) {
      this.applyNestedRowHeaderColor(event);
    }
    if (this.headerDescriptionBackgroundSelected) {
      this.applyHeaderDescriptionColor(event);
    }
    if (this.footerDescriptionBackgroundSelected) {
      this.applyFooterDescriptionColor(event);
    }
    if (this.oddRowSelected) {
      this.applyOddColor(event);
    }
    if (this.evenRowSelected) {
      this.applyEvenColor(event);
    }
    if (this.actionBtnSelection?.[this.isSelectGsiIndex] && this.isTable) {
      this.applyGsiBtnStyle(event);
      this.changeGsiBtnStyles(this.individualBtnStyle, this.colorLabel, this.selectedColor);
    }
    if (this.gsiBtnSelected && !this.isTable) {
      this.applyGsiBtnStyle(event);
      this.gsiBtnSelected.forEach((attr,index) =>{
        if(attr) {
          this.applyGsiBtnStyle(event,index);
        }
      })
    }
    if (this.activePagination) {
      this.paginationStyle[this.colorLabel] = event;
    }
    switch (this.colorLabel) {
      case 'background':
        this.backgroundColor = event;
        break;
      case 'border':
        this.borderColor = event;
        break;
      case 'borderHover': {
        this.borderHoverColor = event;
        break;
      }
      case 'font':
        this.textColor = event;
        break;
      case 'textHover':
        this.colorModel = event;
        this.textHover = event;
        break;
      case 'backgroundHoverr':
        this.colorModel = event;
        this.backgroundHoverColor = event;
        break;
    }
  }
  changeGsiBtnStyles(changeIndividualFn: any, key: string, value: any, dropActionBts: any[] = this.dropActionBts) {
    dropActionBts?.forEach((actionBtn: any, index: number) => {
      if (this.actionBtnSelection[index]) {
        changeIndividualFn(actionBtn?.gsiConfiguration, key, value);
      }
    });
  }

  private applyHeaderDescriptionColor(color: string) {
    switch (this.colorLabel) {
      case 'background': {
        this.headerDescriptionBackground = color;
        break;
      }
      case 'font': {
        this.headerDescriptionFontColor = color;
        break;
      }
      case 'backgroundHover': {
        this.headerDescriptionHoverColor = color;
        break;
      }
      case 'textHover': {
        this.headerDescriptionFontHoverColor = color;
        break;
      }
    }
  }
  private applyFooterDescriptionColor(color: string) {
    switch (this.colorLabel) {
      case 'background': {
        this.footerDescriptionBackground = color;
        break;
      }
      case 'font': {
        this.footerDescriptionFontColor = color;
        break;
      }
      case 'backgroundHover': {
        this.footerDescriptionHoverColor = color;
        break;
      }
      case 'textHover': {
        this.footerDescriptionFontHoverColor = color;
        break;
      }
    }
  }
  private applyHeaderColor(color: string) {
    switch (this.colorLabel) {
      case 'background': {
        this.headerBackground = color;
        break;
      }
      case 'font': {
        this.headerFontColor = color;
        break;
      }
      case 'backgroundHover': {
        this.headerHoverColor = color;
        break;
      }
      case 'textHover': {
        this.headerFontHoverColor = color;
        break;
      }
    }
  }
  private applyNestedRowHeaderColor(color: string) {
    switch (this.colorLabel) {
      case 'background': {
        this.nestedRowHeaderBackground = color;
        break;
      }
      case 'font': {
        this.nestedRowHeaderFontColor = color;
        break;
      }
      case 'backgroundHover': {
        this.nestedRowHeaderHoverColor = color;
        break;
      }
      case 'textHover': {
        this.nestedRowHeaderFontHoverColor = color;
        break;
      }
    }
  }
  private applyOddColor(color: string) {
    switch (this.colorLabel) {
      case 'background': {
        this.oddRowColor = color;
        break;
      }
      case 'font': {
        this.oddRowFontColor = color;
        break;
      }
      case 'backgroundHover': {
        this.oddRowHoverColor = color;
        break;
      }
      case 'textHover': {
        this.oddRowFontHoverColor = color;
        break;
      }
    }
  }

  private applyEvenColor(color: string) {
    switch (this.colorLabel) {
      case 'background': {
        this.evenRowColor = color;
        break;
      }
      case 'font': {
        this.evenRowFontColor = color;
        break;
      }
      case 'backgroundHover': {
        this.evenRowHoverColor = color;
        break;
      }
      case 'textHover': {
        this.evenRowFontHoverColor = color;
        break;
      }
    }
  }

  private applyGsiBtnStyle(color: string,index?: number) {
    switch (this.colorLabel) {
      case 'background': {
        this.gsiBtnBackgroundColor[index] = color;
        break;
      }
      case 'borderHover': {
        this.gsiBtnHoverBorderColor[index] = color;
        break;
      }
      case 'border': {
        this.gsiBtnBorderColor[index] = color;
        break;
      }
      case 'font': {
        this.gsiBtnFontColor[index] = color;
        break;
      }
      case 'backgroundHover': {
        this.gsiBtnBackgroundHoverColor[index] = color;
        break;
      }
      case 'textHover': {
        this.gsiBtnFontHoverColor[index] = color;
        break;
      }
    }
  }
  private individualBtnStyle(obj: any, key: string, value: string) {
    obj[key] = value;
  }
  btnBackgroundcolor: string;
  gsiBtnStyle(dropActionBts: any, borderRadius?: string, background?: string) {
    dropActionBts?.forEach((btn: any, index: number) => {
      if (this.actionBtnSelection[index]) {
        if(!btn.gsiConfiguration)
         btn['gsiConfiguration'] = {};
        btn.gsiConfiguration['borderRadius'] = borderRadius ? borderRadius : btn.gsiConfiguration['borderRadius'];
        btn.gsiConfiguration['background'] = background
          ? this.gsiBtnBackgroundColor
          : btn.gsiConfiguration['background'];
      }
    });
    this.btnBackgroundcolor = background;
  }
  toggleColorPickerTable(label: string) {
    this.colorPickerToggler = !this.colorPickerToggler;
    this.colorLabel = label;

    switch (label) {
      case 'background':
        this.colorModel = this.backgroundColor;
        break;
      case 'border':
        this.colorModel = this.borderColor;
        break;
      case 'borderHover':
        this.colorModel = this.borderHoverColor;
        break;
      case 'font':
        this.colorModel = this.textColor;
        break;
      case 'textHover':
        this.colorModel = this.textHover;
        break;
      case 'backgroundHover':
        this.colorModel = this.backgroundHoverColor;
        break;
    }
  }

  closeColorPicker(event: any) {
    this.colorPickerToggler = false;
  }

  setHover(i: number, j?: number) {
    this.evenHovered = i;
    this.oddHovered = i;
    this.columnHovered = j;
  }

  setOutHover(i: number, j?: number) {
    this.oddHovered = -1;
    this.evenHovered = -1;
    this.columnHovered = -1;
  }

  checkAlign(align: any) {
    this.alignment = align;
    this.checkalignmentSelected();
  }

  private checkalignmentSelected() {
    /*istanbul ignore else*/
    if (this.headerBackgroundSelected) {
      this.headerAlignment = this.alignment;
    }
    /*istanbul ignore else*/
    if (this.nestedRowHeaderBackgroundSelected) {
      this.nestedRowHeaderAlignment = this.alignment;
    }
    /*istanbul ignore else*/
    if (this.evenRowSelected) {
      this.evenRowAlignment = this.alignment;
    }
    /*istanbul ignore else*/
    if (this.oddRowSelected) {
      this.oddRowAlignment = this.alignment;
    }
    /*istanbul ignore else*/
    if (this.gsiBtnSelected) {
      this.gsiBtnAlignment = this.alignment;
      this.changeGsiBtnStyles(this.individualBtnStyle, 'alignment', this.alignment);
    }
    /*istanbul ignore else*/
    if (this.headerDescriptionBackgroundSelected) {
      this.headerDescriptionAlignment = this.alignment;
    }
    /*istanbul ignore else*/
    if (this.footerDescriptionBackgroundSelected) {
      this.footerDescriptionAlignment = this.alignment;
    }
    /*istanbul ignore else*/
    if(this.enableLegend) {
      this.legendAlignment = this.alignment;
    }
  }

  increaseFontSize() {
    this.fontSize = this.fontSize + 1;
    this.fontSizeSelected();
  }

  decreaseFontSize() {
    /*istanbul ignore else*/
    if (this.fontSize) {
      this.fontSize = this.fontSize - 1;
      this.fontSizeSelected();
    }
  }

  private fontSizeSelected() {
    /*istanbul ignore else*/
    if (this.headerBackgroundSelected) {
      this.headerFontSize = this.fontSize;
    }
    /*istanbul ignore else*/
    if (this.nestedRowHeaderBackgroundSelected) {
      this.nestedRowHeaderFontSize = this.fontSize;
    }
    /*istanbul ignore else*/
    if (this.evenRowSelected) {
      this.evenRowFontSize = this.fontSize;
    }
    /*istanbul ignore else*/
    if (this.oddRowSelected) {
      this.oddRowFontSize = this.fontSize;
    }
    /*istanbul ignore else*/
    if (this.gsiBtnSelected) {
      this.gsiBtnFontSize = this.fontSize;
      this.changeGsiBtnStyles(this.individualBtnStyle, 'fontSize', this.fontSize);

    }
    if (this.isAttributeLevelCustomizationActive) {
      this.columnStyles[this.selectedColumn].fontSizes[this.selectedColumnAttribute] = this.fontSize;
    }
    if (this.footerDescriptionBackgroundSelected) {
      this.footerDescriptionFontSize = this.fontSize;
    }
    if (this.headerDescriptionBackgroundSelected) {
      this.headerDescriptionFontSize = this.fontSize;
    }
  }

  selectedeFontChange(event: IFonts) {
    if (this.headerBackgroundSelected) {
      this.headerFontFamily = event;
    }
    if (this.nestedRowHeaderBackgroundSelected) {
      this.nestedRowHeaderFontFamily = event;
    }
    if (this.evenRowSelected) {
      this.evenRowFontFamily = event;
    }
    if (this.oddRowSelected) {
      this.oddRowFontFamily = event;
    }
    if (this.gsiBtnSelected) {
      this.gsiBtnFontFamily = event;
      this.changeGsiBtnStyles(this.individualBtnStyle, 'fontFamily', event);
    }
    if (this.headerDescriptionBackgroundSelected) {
      this.headerDescriptionFontStyle = event;
    }
    if (this.footerDescriptionFontStyle) {
      this.footerDescriptionFontStyle = event;
    }
  }
  deleteFreezeAttribute(i: number) {
    this.freezeAttribute.splice(i, 1);
  }

  increasePaginateSize() {
    this.paginationRowSize = this.paginationRowSize + 1;
  }

  decreasePaginateSize() {
    /*istanbul ignore else*/
    if (this.paginationRowSize) {
      this.paginationRowSize = this.paginationRowSize - 1;
    }
  }

  increaseBorderRadius(label: string) {
    this.gridLines[label].borderRadius = this.gridLines?.[label]?.borderRadius + 1;
  }
  increaseBorderThickness(label: string) {
    this.gridLines[label].borderThickness = this.gridLines?.[label]?.borderThickness + 1;
  }
  decreaseBorderRadius(label: string) {
    this.gridLines[label].borderRadius = this.gridLines?.[label]?.borderRadius - 1;
  }
  decreaseBorderThickness(label: string) {
    this.gridLines[label].borderThickness = this.gridLines?.[label]?.borderThickness - 1;
  }
  choseBorderColor(event: any, label: string) {
    this.gridLines[label].borderColor = event;
  }

  checkPaginatedAlign(align: any) {
    this.paginatedAlignment = align;
  }

  saveHeaderDescription(tempDescription: string) {
    this.headerDescription = tempDescription;
  }

  saveFooterDescription(tempDescription: string) {
    this.footerDescription = tempDescription;
  }

  saveEntityDescription(EntityTitle: string) {
    this.EntityTitle = EntityTitle;
  }

  uploadIcon(event: any,isMenuIcon?:boolean) {
    let newFormData = new FormData();
    let file = event?.target?.files[0];
    /* istanbul ignore next*/
    if (event?.target?.files && event?.target?.files[0]) {
      newFormData.append('file', file);
    }
    this.entityBoardService
      .uploadData(newFormData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          if(isMenuIcon){
            this.menuIcon = res?.contentUrl;
            this.gsiConfigurations.iconLink = res?.contentUrl;
          }
          else
          this.imgSrc = res?.contentUrl;
        }
      });
  }
  removeIcon() {
    this.gsiConfigurations.iconLink = this.defaultGsiImages.cart;
  }
  selectGsiButton(index: number) {
    /*istanbul ignore else*/
    if (this.dropActionBts[index]) {
      this.gsiForConfigure = this.dropActionBts?.[index]?.name ? this.dropActionBts?.[index]?.name :  this.dropActionBts?.[index]?.gsiName ;
      this.isSelectGsi = true;
      this.isSelectGsiIndex = index;
    }
  }

  saveGsiConfigurations() {
    /*istanbul ignore next*/
    if (this.isSelectGsi) {
      this.isSelectGsi = false;
      this.gsiForConfigure = '';
      const gsiConfiguration = this.isTable? { gsiDisplay: this.gsiNameWithIcon ? 'gsiNameWithIcon' : this.gsiOnlyIcon ? 'onlyIcon' : undefined,
      image: 'uploaded',
      imageSrc: this.imgSrc }:{
        gsiDisplay: this.gsiNameWithIcon ? 'gsiNameWithIcon' : this.gsiOnlyIcon ? 'onlyIcon' : undefined,
        image: this.imgSrc.length > 0 ? 'uploaded' : 'default',
        imageSrc: this.imgSrc.length > 0 ? this.imgSrc : this.selectGsiIcon,
      };
      if (this.isTable) {
        this.dropActionBts.forEach((btn: any, index: number) => {
          if (this.actionBtnSelection[index]) {
            this.addGsiConfigurations(gsiConfiguration, index);
          }
        });
      } else {
        this.addGsiConfigurations(gsiConfiguration, this.isSelectGsiIndex);
      }

      this.isSelectGsiIndex = null;
    }
  }
  addGsiConfigurations(gsiConfiguration: any, index: number) {
    this.dropActionBts[index] = {
      ...this.dropActionBts[index],
      gsiConfiguration: this.dropActionBts[index].gsiConfiguration ? {...this.dropActionBts[index].gsiConfiguration,...gsiConfiguration}:gsiConfiguration,
    };
  }

  checkIsUnderLine() {
    this.isUnderLine = !this.isUnderLine;
    this.checkIsSelectedUnderLine();
  }
  private checkIsSelectedUnderLine() {
    /*istanbul ignore else*/
    if (this.headerBackgroundSelected) {
      this.headerUnderLine = this.isUnderLine;
    }
    /*istanbul ignore else*/
    if (this.nestedRowHeaderBackgroundSelected) {
      this.nestedRowHeaderUnderLine = this.isUnderLine;
    }
    /*istanbul ignore else*/
    if (this.evenRowSelected) {
      this.evenRowUnderLine = this.isUnderLine;
    }
    /*istanbul ignore else*/
    if (this.oddRowSelected) {
      this.oddRowUnderLine = this.isUnderLine;
    }
    /*istanbul ignore else*/
    if (this.gsiBtnSelected) {
      this.gsiBtnUnderline = this.isUnderLine;
      this.changeGsiBtnStyles(this.individualBtnStyle, 'underLine', this.isUnderLine);
    }
    if (this.headerDescriptionBackgroundSelected) {
      this.headerDescriptionUnderline = this.isUnderLine;
    }
    if (this.footerDescriptionBackgroundSelected) {
      this.footerDescriptionUnderline = this.isUnderLine;
    }
  }
  checkIsbold() {
    this.isBold = !this.isBold;
    this.checkIsSelectedBold();
  }
  private checkIsSelectedBold() {
    /*istanbul ignore else*/
    if (this.headerBackgroundSelected) {
      this.headerBold = this.isBold;
    }
    /*istanbul ignore else*/
    if (this.nestedRowHeaderBackgroundSelected) {
      this.nestedRowHeaderBold = this.isBold;
    }
    /*istanbul ignore else*/
    if (this.evenRowSelected) {
      this.evenRowBold = this.isBold;
    }
    /*istanbul ignore else*/
    if (this.oddRowSelected) {
      this.oddRowBold = this.isBold;
    }
    /*istanbul ignore else*/
    if (this.gsiBtnSelected) {
      this.gsiBtnBold = this.isBold;
      this.changeGsiBtnStyles(this.individualBtnStyle, 'bold', this.gsiBtnBold);

    }
    if (this.isAttributeLevelCustomizationActive) {
      this.columnStyles[this.selectedColumn].Weight[this.selectedColumnAttribute] = this.isBold ? 'bold' : 'normal';
    }
    if (this.headerDescriptionBackgroundSelected) {
      this.headerDescriptionBold = this.isBold;
    }
    if (this.footerDescriptionBackgroundSelected) {
      this.footerDescriptionBold = this.isBold;
    }
  }

  checkIsItalic() {
    this.isItalic = !this.isItalic;
    this.checkIsSelectedItalic();
  }
  private checkIsSelectedItalic() {
    if (this.isAttributeLevelCustomizationActive) {
      this.columnStyles[this.selectedColumn].fontStyles[this.selectedColumnAttribute] = this.isItalic
        ? 'italic'
        : 'normal';
    }
    /*istanbul ignore else*/
    if (this.headerBackgroundSelected) {
      this.headerItalic = this.isItalic;
    }
    /*istanbul ignore else*/
    if (this.nestedRowHeaderBackgroundSelected) {
      this.nestedRowHeaderItalic = this.isItalic;
    }
    /*istanbul ignore else*/
    if (this.evenRowSelected) {
      this.evenRowItalic = this.isItalic;
    }
    /*istanbul ignore else*/
    if (this.oddRowSelected) {
      this.oddRowItalic = this.isItalic;
    }
    /*istanbul ignore else*/
    if (this.gsiBtnSelected) {
      this.gsiBtnItalic = this.isItalic;
      this.changeGsiBtnStyles(this.individualBtnStyle, 'italic', this.isItalic);
    }
    if (this.headerDescriptionBackgroundSelected) {
      this.headerDescriptionItalic = this.isItalic;
    }
    if (this.footerDescriptionBackgroundSelected) {
      this.footerDescriptionItalic = this.isItalic;
    }
  }

  detectHeaderFreeze(element: any): boolean {
    this.freezeAttribute?.forEach((attr: any) => {
      if (attr == element) {
        return true;
      }
    });
    if (this.tableFreezePosition === 'left' || this.tableFreezePosition === 'topLeft') return true;
    if (this.tableFreezePosition === 'top' || this.tableFreezePosition === 'topLeft') return true;
    return false;
  }

  createCondition() {
    if (
      this.conditionColor &&
      this.conditionAttribute.length > 0 &&
      this.selectedConditonValue &&
      this.selectedCondition &&
      this.selectedConditionTextColor
    ) {
      this.conditionAttribute.forEach((attr: any) => {
        this.conditionArray.push({
          fillColor: this.conditionColor,
          conditionAttribute: attr,
          condition: this.selectedCondition,
          selectedConditonValue: this.selectedConditonValue,
          textColor: this.selectedConditionTextColor,
        });
      });
      this.addCondition();
      this.conditionAttribute = [];
      this.conditionColor = '#fff';
      this.selectedCondition = null;
      this.selectedConditonValue = null;
      this.selectedConditionTextColor = '#fff';
    }
  }
  resetVerticalTable(resetData?:boolean) {
    if(!resetData){
      this.isVericalListRundown = !this.isVericalListRundown;
    }
    this.parentAttrSoureValues = undefined;
    this.dropAttributes = [];
    this.verticalTableData = [];
    this.mappedAttributesIdx = [];
    this.prevDragType = undefined;
    if (this.dropAttributes.length > 0) {
      this.nslAttributes.push(...this.dropAttributes);
      this.getVerticalTableAttributes();
    }
    this.dialog.closeAll();
  }
  openDialog(dialog: any) {
    this.dialog.open(dialog, { width: '600px' });
  }
  getTableStyles() {
    return {
      actionButton: this.dropActionBts,
      droppedAtt: this.dropMappedAttributes,
      setting: {
        showTitle: this.entitytitletoggle,
        entityTitle: this.EntityTitle,
        attributeWrap: this.advanceTableFeatures?.attributeWrap,
        pagination: this.showPagination,
        allowExpand: this.advanceTableFeatures?.allowExpand,
        hideSerialNumber: this.advanceTableFeatures?.serialNumbers,
        hideSelectButton: this.advanceTableFeatures?.hideSelectButton,
        hideLabels: this.advanceTableFeatures?.showLabels,
        allowSelection: this.allowSelection,
        freezeColumns: this.freezeAttribute,
        tableFreezePosition: this.tableFreezePosition,
        allowDuplication: this.advanceTableFeatures?.allowDuplication,
        showResetPagination: this.advanceTableFeatures?.showResetPagination,
        updateTableData: this.advanceTableFeatures?.updateTableData,
        hidePagination: this.advanceTableFeatures?.hidePagination,
        singlePageTable: this.advanceTableFeatures?.singlePageTable,
        // gridTable: this.advanceTableFeatures?.gridTable,
        multiRowsVerticalTable:this.advanceTableFeatures?.multiRowsVerticalTable,
        deleteRecord: this.advanceTableFeatures?.deleteRecord,
        tableComponentScale: this.tableComponentScale,
        tableDesign: this.tableuiDesign,
        freezeSelection: this.advanceTableFeatures?.freezeSelection,
        freezeSeNum: this.advanceTableFeatures?.freezeSeNum,
      },

      export: [
        {
          label: 'Pdf',
          isChecked: this.advancedFeaturesTable?.export?.checkBoxValues[0]?.checked,
        },
        {
          label: 'CSV',
          isChecked: this.advancedFeaturesTable?.export?.checkBoxValues[2]?.checked,
        },
        {
          label: 'Excel',
          isChecked: this.advancedFeaturesTable?.export?.checkBoxValues[1]?.checked,
        },
      ],
      colouring: {
        tableBackground: this.headerBackground,
        tableHeader: this.headerBackground,
        alternateBackground: this.evenRowColor,
        selectAlternatePattern: '',
        tableBorder: this.gridLines.outLine.borderColor,
        cellHoverColor: this.evenRowHoverColor,
      },
      advancedFeatures: {
        allowColumnsort: this.advancedFeaturesTable?.sort?.checked,
        allowColumnFilter: this.advancedFeaturesTable?.filter?.checked,
        allowColumnSearch: this.advancedFeaturesTable?.search?.checked,
        toggleColumn: this.advancedFeaturesTable?.chooseColumns?.checked,
        allowDownload: this.advancedFeaturesTable?.export?.checked,
        allowSearchFields: this.advancedFeaturesTable?.searchFields?.checked,
        allowUpload: this.advancedFeaturesTable?.import?.checked,
        searchFields: this.advancedFeaturesTable?.searchFields?.styles[0]?.value === 'left' ? 'top' : 'side',
        filtersConfig: this.advancedFeaturesTable?.filtersConfig,
        sort: {
          sortFields: this.sortFields,
          sortPlacement: this.advancedFeaturesTable?.sort?.header?.checked
            ? 'header'
            : this.advancedFeaturesTable?.sort?.top?.checked
            ? 'top'
            : '',
        },
        search: {
          searchFields: this.searchTableFields,
          searchPlacement: this.advancedFeaturesTable?.search?.top?.checked ? 'top' : '',
        },
        filter: {
          filterFields: this.filterFields,
          filterPlacement: this.advancedFeaturesTable?.filter?.header?.checked ? 'header' : '',
        },
        facet: {
          facetFields: this.facetFields,
          configs: this.advancedFeaturesTable?.searchFields?.checkBoxValues
        },
      },
      newTableConfiguration: true,
      hideSelectAll: this.recordSelection == 'single' ? true : false,
      tableSelection: this.recordSelection,
      // gsiConfiguration: this.gsiBtnConfig(this.gsiActionBtn),
      // gsiButtons: this.gsiButtons,
      tableSettings:
        this.recordSelection == 'single'
          ? {
              maximumselection: 1,
              minimumselection: 1,
              minimumselectionAll: false,
              submitOnSelection: this.submitOnSelection,
            }
          : this.recordSelection == 'multiple' && !this.advanceTableFeatures?.hideSelectButton
          ? {
              maximumselection: this.tableFeatures?.maximumselection,
              minimumselection: this.tableFeatures?.minimumselection,
              minimumselectionAll: this.tableFeatures.minimumselectionAll,
            }
          : {},
    };
  }
  sortDetails() {
    let sortBy: any[] = [];
    this.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
      if (attr.sort.checked) {
        sortBy.push({
          attributeId: attr.id,
          attributeName: attr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });
    return sortBy;
  }
  getAdditionalVerticalStyleConfig(config: any) {
    config['isListRunDown'] = this.isVericalListRundown;
    config['actionButtons'] = this.dropActionBts;
  }
  updateExistingConfiguration(configData: any) {
    this.mappedAttributesIdx = configData.attr ? configData.attr : [];
    this.nestedmappedAttributesIdx = configData?.nestedAttr ? configData.nestedAttr : [];
    // this.nestedmappedAttributesIdx=[];
    this.dropMappedAttributes = configData.droppedAtt ? configData.droppedAtt : [];
    this.nestedRowDropAttributes = configData?.nestedRowDropAttr ? configData.nestedRowDropAttr : [];
    // this.nestedRowDropAttributes=[];
    this.attributeTypes = configData.attributeTypes ? configData.attributeTypes : this.attributeTypes;
    this.headerBackground = configData.headerBackground ? configData.headerBackground : this.headerBackground;
    this.isVericalListRundown = configData.isListRunDown ? configData.isListRunDown : this.isVericalListRundown;
    this.widthOfTableArray = configData.widthArray ? configData.widthArray : this.widthOfTableArray;
    this.settings = configData;
    this.evenRowColor = configData.evenRowColor ? configData.evenRowColor : this.evenRowColor;
    this.oddRowColor = configData.oddRowColor ? configData.oddRowColor : this.oddRowColor;
    this.dropActionBts = configData.actionButton ? configData.actionButton : [];
    this.isNestedRow1 = configData?.nestedRow ? configData?.nestedRow : false;
    if (this.isTable) {
      this.conditionArray = configData?.conditionArray ? configData?.conditionArray : [];
      this.tableFreezePosition = configData?.tableFreezePosition ? configData?.tableFreezePosition : '';
    }
    this.actionBtnSelection = new Array(this.dropActionBts?.length).fill(false);

    if (configData?.header) {
      this.headerFontColor = configData.header?.fontColor;
      this.headerHoverColor = configData.header?.colorHover;
      this.headerFontHoverColor = configData.header?.fontHover;
      // this.headerTextColor = configData.header?.text.headerTextColor;
      this.headerFontFamily = configData.header?.text.fontFamily;
      this.headerFontSize = configData.header?.text.fontSize;
      this.headerBold = configData.header?.text.bold;
      this.headerItalic = configData.header?.text.italic;
      this.headerUnderLine = configData.header?.text.underLine;
      this.headerAlignment = configData.header?.text.alignment;
    }
    if (configData?.evenRow) {
      this.evenRowFontColor = configData.evenRow?.fontColor;
      this.evenRowHoverColor = configData.evenRow?.colorHover;
      this.evenRowFontHoverColor = configData.evenRow?.fontHover;
      this.evenRowFontFamily = configData.evenRow?.text?.fontFamily;
      this.evenRowFontSize = configData.evenRow?.text?.fontSize;
      this.evenRowBold = configData.evenRow?.text?.bold;
      this.evenRowItalic = configData.evenRow?.text.italic;
      this.evenRowUnderLine = configData.evenRow?.text?.underLine;
      this.evenRowAlignment = configData.evenRow?.text?.alignment;
    }
    if (configData?.oddRow) {
      this.oddRowFontColor = configData.oddRow.fontColor;
      this.oddRowHoverColor = configData.oddRow.colorHover;
      this.oddRowFontHoverColor = configData.evenRow.fontHover;
      this.oddRowFontFamily = configData.oddRow.text.fontFamily;
      this.oddRowFontSize = configData.oddRow.text.fontSize;
      this.oddRowBold = configData.oddRow.text.bold;
      this.oddRowItalic = configData.oddRow.text.italic;
      this.oddRowUnderLine = configData.oddRow.text.underLine;
      this.oddRowAlignment = configData.oddRow.text.alignment;
    }
    if (configData.gridLines) {
      this.gridLines = configData.gridLines;
    }
    if (configData?.headerFreeze) {
      this.tableFreezePosition = configData.headerFreeze?.tableFreezePosition;
      this.freezeAttribute = configData.headerFreeze?.freezeAtrributeRows;
    }

    if (configData?.pagination) {
      this.showPagination = this.isTable ? configData.setting?.pagination : configData.pagination.showPagination;
      this.paginationRowSize = configData.pagination.rows;
      // this.hidePagination = configData?.hidePagination ? configData?.hidePagination : false;
      this.paginatedAlignment = configData.pagination.alignment;
      this.paginationStyle = configData?.pagination?.style ? configData?.pagination?.style : this.paginationStyle;
      if (this.isTable) {
        let obj = this.alignmentOptions.find((x: any) => x.justify == configData?.pagination?.placementOfPagination );
        this.paginatedAlignment = obj;
      }
    }
    if (configData?.description?.header?.style) {
      this.headerDescriptionBackground = configData.description.header?.style?.backgroundColor;
      this.headerDescriptionFontColor = configData.description.header?.style?.fontColor;
      this.headerDescriptionHoverColor = configData.description.header?.style?.colorHover;
      this.headerDescriptionFontHoverColor = configData.description.header?.style?.fontHover;
      this.headerDescriptionAlignment = configData.description.header?.style?.text?.alignment;
      this.headerDescriptionFontSize = configData.description.header?.style?.text?.fontSize;
      this.headerDescriptionUnderline =
        configData.description.header?.style?.text?.underline == 'underline' ? true : false;
      this.headerDescriptionBold = configData.description.header?.style?.text?.bold == 'bold' ? true : false;
      this.headerDescriptionItalic = configData.description.header?.style?.text?.italic == 'italic' ? true : false;
      this.headerDescriptionFontStyle = configData.description.header?.style?.text?.fontStyle;
    }
    if (configData?.description?.header?.message) {
      this.toggleHeaderChecked = true;
      this.headerDescription = configData.description.header?.message;
    }
    if (configData?.description?.header?.message) {
      this.toggleFooterChecked = true;
      this.footerDescription = configData.description.header?.message;
    }
    if (configData?.description?.footer?.style) {
      this.footerDescriptionBackground = configData.description.footer?.style?.backgroundColor;
      this.footerDescriptionFontColor = configData.description.footer.style?.fontColor;
      this.footerDescriptionHoverColor = configData.description.footer.style?.colorHover;
      this.footerDescriptionFontHoverColor = configData.description.footer.style?.fontHover;
      this.footerDescriptionAlignment = configData.description.footer.style?.text?.alignment;
      this.footerDescriptionFontSize = configData.description.footer.style?.text?.fontSize;
      this.footerDescriptionUnderline =
        configData.description.footer.style?.text?.underline == 'underline' ? true : false;
      this.footerDescriptionBold = configData.description.footer.style?.text?.bold == 'bold' ? true : false;
      this.footerDescriptionItalic = configData.description.footer.style?.text?.italic == 'italic' ? true : false;
      this.footerDescriptionFontStyle = configData.description.footer.style?.text?.fontStyle;
    }
    if (configData?.tableDescription) {
      this.toggleHeaderChecked = true;
      this.headerDescription = configData?.description?.header?.message;
      this.toggleFooterChecked = true;
      this.footerDescription = configData?.description?.header?.message;
    }
    this.conditionArray = configData?.conditionArray ? configData?.conditionArray : this.conditionArray;
    this.advanceTableFeatures = configData?.advanceTableFeatures
      ? configData.advanceTableFeatures
      : this.advanceTableFeatures;
    if (this.advanceTableFeatures?.tableTemplate) {
      let str = this.advanceTableFeatures?.tableTemplate;
      this.fileObj = {contentUrl: this.advanceTableFeatures?.tableTemplate, name: str.substring(str?.lastIndexOf('/')+1)}
    };
    this.tableFeatures = configData?.tableSettings ? configData?.tableSettings : this.tableFeatures;
    this.recordSelection = configData?.tableSelection;
    this.submitOnSelection = configData?.tableSettings?.submitOnSelection || false;
    this.allowSelection = configData?.setting?.allowSelection;
    this.entitytitletoggle = configData.setting?.showTitle;
    this.EntityTitle = configData?.setting?.entityTitle ? configData?.setting?.entityTitle : '';
    this.gsiConfigurations = configData?.gsiConfigurations ? configData?.gsiConfigurations : this.gsiConfigurations;

       this.gsiBtnBorderColor=configData?.gsiConfigurations?.gsiStyle?.gsiborderColor;
       this.gsiBtnHoverBorderColor=configData?.gsiConfigurations?.gsiStyle?.gsihoverborderColor;
       this.gsiBtnborderRadius=configData?.gsiConfigurations?.gsiStyle?.gsiborderRadius;
       this.gsiBtnborderWidth=configData?.gsiConfigurations?.gsiStyle?.gsiborderWidth;
       this.gsiBtnFontFamily=configData?.gsiConfigurations?.gsiStyle?.text?.fontFamily;
       this.gsiBtnFontSize=configData?.gsiConfigurations?.gsiStyle?.text?.fontSize;
       this.gsiBtnBold=configData?.gsiConfigurations?.gsiStyle?.text?.bold;
       this.gsiBtnItalic=configData?.gsiConfigurations?.gsiStyle?.text?.italic;
       this.gsiBtnUnderline=configData?.gsiConfigurations?.gsiStyle?.text?.underLine;
       this.gsiBtnAlignment=configData?.gsiConfigurations?.gsiStyle?.text?.alignment;
       if(Array.isArray(configData?.gsiConfigurations?.gsiStyle?.backgroundColor)) {
        this.gsiBtnBackgroundColor=configData?.gsiConfigurations?.gsiStyle?.backgroundColor;
        this.gsiBtnBackgroundHoverColor= configData?.gsiConfigurations?.gsiStyle?.colorHover;
        this.gsiBtnFontColor= configData?.gsiConfigurations?.gsiStyle?.fontColor;
        this.gsiBtnFontHoverColor = configData?.gsiConfigurations?.gsiStyle?.fontHover;
       } else {
        let btnBg =configData?.gsiConfigurations?.gsiStyle?.backgroundColor;
        let btnhover = configData?.gsiConfigurations?.gsiStyle?.colorHover;
        let btnfontcolor = configData?.gsiConfigurations?.gsiStyle?.fontColor;
        let btnfonthover = configData?.gsiConfigurations?.gsiStyle?.fontHover;

        this.selectedGsi?.forEach((btn: any) => {
          this.gsiBtnBackgroundColor.push(btnBg);
          this.gsiBtnBackgroundHoverColor.push(btnhover);
          this.gsiBtnFontColor.push(btnfontcolor);
          this.gsiBtnFontHoverColor.push(btnfonthover);
        });
       }



    this.tableuiDesign = !!configData?.setting?.tableDesign;
    this.tableComponentScale = this.isVerticalTable ? configData?.tableComponentScale ?  configData?.tableComponentScale : 'medium': configData?.setting?.tableComponentScale
      ? configData?.setting?.tableComponentScale
      : 'medium';
    if (this.gsiConfigurations?.gsiPosition) {
      this.gsiConfigurations.gsiPosition == 'onTop'
        ? (this.inTableGSIButtons = false)
        : (this.inTableGSIButtons = true);
    } else {
      this.inTableGSIButtons = true;
    }
    if (configData?.advancedFeatures) {
      this.sortFields = configData?.advancedFeatures?.sort?.sortFields;
      this.searchTableFields = configData?.advancedFeatures?.search?.searchFields;
      this.filterFields = configData?.advancedFeatures?.filter?.filterFields;
      this.facetFields = configData?.advancedFeatures?.facet?.facetFields;
    }
    // this.advanceTableFeatures.rowalternate = configData.

    if (this.mappedAttributesIdx.length > 0 && !this.isEntityMccTable) {
      if(this.mappedAttributesIdx.length > this.nslAttributes?.length){
        this.resetVerticalTable(true);
        return
      }
      this.mappedAttributesIdx.forEach((attrIndex: any, index: number) => {
        if(this.nslAttributes?.[attrIndex]){
          this.parentAttrSoureValues = this.parentAttrSoureValues
          ? this.parentAttrSoureValues
          : this.nslAttributes?.[attrIndex]?.attributeType?.extendedProperties?.sourceValues;
        if (this.isVerticalTable)
          this.getVerticalTableAttributes(this.parentAttrSoureValues, this.nslAttributes?.[attrIndex]?.name);
        if (
          this.isVericalListRundown &&
          !this.nslAttributes[attrIndex]?.conditionalPotentiality &&
          this.listRunDownAttributeSet.has(this.nslAttributes[attrIndex]?.attributeType?.uiElement?.uiElement)
        ) {
          this.prevDragType = this.nslAttributes[attrIndex]?.attributeType?.uiElement?.uiElement;
        }
        }else{
           attrIndex = this.nslAttributes.findIndex((attr: any) => attr.name == attrIndex );
        }
        if(attrIndex != -1){
          this.dropAttributes.push(this.nslAttributes?.[attrIndex]);
          this.nslAttributes[attrIndex] = -1;
        }
      });
    }
    if (this.isTable) {
      // if (this.dropMappedAttributes.length > 0) {
      //   this.dropMappedAttributes = this.dropMappedAttributes.filter((attribute: any) => {
      //     return attribute !== 'Action';
      //   });
      //   this.dropAttributes = this.dropMappedAttributes.map((column: any) =>
      //     this.dropAttributes.find((header: any) => header.displayName === column)
      //   );
      //
      this.nestedmappedAttributesIdx.forEach((attrIndex: number, index: number) => {
        this.nslAttributes[attrIndex] = -1;
      });
    }
    this.nslAttributes = this.nslAttributes.filter((attribute: any) => attribute !== -1);
    this.getEntityTableAttributes(this.dropAttributes);
    this.getnestedEntityTableAttributes(this.nestedRowDropAttributes);
  }
  borderColorpickerToggler(label: any) {
    this.borderColorPicker[label] = !this.borderColorPicker[label];
  }

  goBackTable() {
    if (this.isCardConfig) {
      this.gotoMylibrary();
    } else if (this.isAdvancedFeature) {
      this.isCardConfig = true;
      this.isAdvancedFeature = false;
    }
  }
  getEntityTableAttributes(droppedAttr?: any) {
    this.numberOfTableColumns = this.selectedEntityData?.nslAttributes?.length;
    let tableColumns = [];
    let editedWidth = 0;
    const width = 186 * this.numberOfTableColumns;
    this.tableWidth = width.toString() + 'px';
    for (let i = 0; i < this.numberOfTableColumns; i++) {
      let widthOfCol = this.widthOfTableArray?.[i] ? this.widthOfTableArray?.[i] : '186px';
      let colWidth = parseInt(widthOfCol.split('p')[0]);
      let colPer = (colWidth * 100) / editedWidth;
      if (droppedAttr && droppedAttr[i]?.name)
        tableColumns.push({
          attributeHeading: droppedAttr[i]?.name,
          attributeValue: '',
          width: this.widthOfTableArray?.[i] ? this.widthOfTableArray?.[i] : '186px',
        });
      else
        tableColumns.push({
          attributeHeading: 'Attribute',
          attributeValue: '',
          width: this.widthOfTableArray?.[i] ? this.widthOfTableArray?.[i] : '186px',
        });
    }
    for (let i = 0; i < this.numberOfTableRows; i++) {
      this.tableData.push(tableColumns);
    }
  }
  getnestedEntityTableAttributes(droppedAttr?: any) {
    this.numberOfTableColumns = this.selectedEntityData?.nslAttributes?.length;
    let tableColumns = [];
    const width = 186 * this.numberOfTableColumns;
    this.tableWidth = width.toString() + 'px';
    for (let i = 0; i < this.numberOfTableColumns; i++) {
      if (droppedAttr && droppedAttr[i]) tableColumns.push({ attributeHeading: droppedAttr[i], attributeValue: '' });
      else tableColumns.push({ attributeHeading: 'Attribute', attributeValue: '' });
    }
    for (let i = 0; i < this.numberOfTableRows; i++) {
      this.nestedTableData.push(tableColumns);
    }
  }
  sortCheckedFieldsEmit(event: any) {
    this.sortFields = event;
  }
  searchCheckedFieldsEmit(event: any) {
    this.searchTableFields = event;
  }
  filterCheckedFieldsEmit(event: any) {
    this.filterFields = event;
  }
  facetCheckedFieldsEmit(event: any) {
    this.facetFields = event;
  }
  tableadvancedFeaturesEmit(event: any) {
    this.advancedFeaturesTable = event;
  }
  changeRowSize(size: string): void {
    this.tableComponentScale = size;
    switch (size) {
      case 'small':
        this.rowSize = 50;
        break;
      case 'medium':
        this.rowSize = 60;
        break;
      case 'large':
        this.rowSize = 70;
        break;
      default:
        this.rowSize = 50; // Default size
        break;
    }
  }
  gsiBtnConfig(gsiActionBtn: QueryList<ElementRef>): void {
    const style: { [key: number]: any } = {};
    gsiActionBtn?.forEach((button: any) => {
      let buttonId = button.nativeElement.id;
      const position = parseInt(buttonId.split('-')[1]);
      style[position] = {
        fontColor: '',
        backgroundColor: '',
        colorHover: '',
        fontHover: '',
        text: {
          fontFamily: '',
          fontSize: '',
          bold: '',
          italic: '',
          underLine: '',
          alignment: '',
        },
      };
      //   styles[position].fontColor = button.nativeElement.styles.color;
      //   styles[position].backgroundColor = button.nativeElement.styles.backgroundColor;
      //   styles[position].text.fontSize = button.nativeElement.styles.fontSize;
      //   styles[position].text.fontFamily = button.nativeElement.styles.fontFamily;
      //   styles[position].text.bold = button.nativeElement.styles.fontWeight=='bold'? true: false;
      //   styles[position].text.italic =  button.nativeElement.styles.fontWeight == 'italic' ? true:false;
      //   styles[position].text.Alignment = button.nativeElement.styles.justifyContent;
      //   styles[position].text.underLine = button.nativeElement.styles.textDecorationLine == 'underLine'?true:false;
    });
  }
  searchAttributesTable() {
    isSearchAttributeVal(this.attributeValue, this.nslAttributes);
  }

  setIsPreView(isPreview: boolean) {
    this.isPreview = isPreview;
  }

  editSelectedCondition(index: any, condition: any, template: any) {
    this.addConditionVariable = true;
    this.conditionAttribute = [condition.conditionAttribute];
    this.selectedCondition = condition.condition;
    this.selectedConditonValue = condition.selectedConditonValue;
    this.conditionColor = condition.fillColor;
    this.selectedConditionTextColor = condition.textColor;
    this.deleteSelectedCondition(index, 'child');
  }
  handleDragEvent(event: any, rowIndex: any, dragIndex: any) {
    this.columnWidth = document.getElementById('attrRow').getBoundingClientRect()?.width / 12;
    event.dataTransfer.setData('left', event?.screenX);
    event.dataTransfer.setData('dropIndex', dragIndex);
    event.dataTransfer.setData('rowIndex', rowIndex);
  }
  handleDropEvent(event: any) {
    let intialPos = event.dataTransfer.getData('left');
    let dropIndex = event.dataTransfer.getData('dropIndex');
    let rowIndex = event.dataTransfer.getData('rowIndex');
    if (intialPos) {
      let finalPos = event?.screenX;
      let diff = finalPos - intialPos;
      let percentage = Math.floor(diff / this.columnWidth);
      let dummy = this.columnStyles[rowIndex]?.sizes[dropIndex];
      if (percentage + dummy <= 12) {
        this.columnStyles[rowIndex].sizes[dropIndex] += percentage;
      }
      if (this.columnStyles[rowIndex].sizes[dropIndex] != 12) {
        this.columnStyles[rowIndex].sizes[Number(dropIndex) + 1] = 12 - this.columnStyles[rowIndex]?.sizes[dropIndex];
      } else {
        this.columnStyles[rowIndex].sizes[Number(dropIndex) + 1] = 12;
      }
    }
  }
  // rgbToHex(x: any): string {
  //   if (x?.[0] == '#' || !x) return x;
  //   let a = x?.split('(')?.[1]?.split(',');
  //   return '#' + this.componentToHex(a[0]) + this.componentToHex(a[1]) + this.componentToHex(a[2]);
  // }
  // componentToHex(c: any) {
  //   var hex = c.toString(16);
  //   return hex.length == 1 ? '0' + hex : hex;
  // }
  saveTable() {
    this.saveEntityCard();
    this.closeDialog();
  }
  mapHeaderAttr(mappedAttr: any) {
    this.dropAttributes = [];
    this.dropMappedAttributes = mappedAttr?.attr;
    if(!mappedAttr?.columnStyles) {
    this.tableData[0].forEach((table: any, index: number) => {
      let foundIndex = this.nslAttributes.findIndex((attr: any) => attr.name == mappedAttr?.attr[index]?.trim());
      if (mappedAttr?.attr[index] && foundIndex != -1) {
        table.attributeHeading = mappedAttr?.attr[index];
          this.columnStyles[index].displayName = mappedAttr?.attr[index];
          this.columnStyles[index].attributes = mappedAttr?.attr[index];
          this.columnStyles[index].attributeList.push(mappedAttr?.attr[index]);
          this.dropAttributes.push(this.nslAttributes[foundIndex]);
          this.nslAttributes.splice(foundIndex, 1);
      } else {
        table.attributeHeading = 'Attribute';
      }
    });
  } else {
    this.columnStyles.forEach((attr:ColumnStyle) =>{
      attr.attributeList.forEach((at:string)=>{
        let foundIndex = this.nslAttributes.findIndex((attr: any) => attr.name == at);
        if (foundIndex != -1) {
          this.dropAttributes.push(this.nslAttributes[foundIndex]);
          this.nslAttributes.splice(foundIndex, 1);
        }
      });
    });
  }
  }
  mapNestedHeaderAttr(mappedAttr: any) {
    this.nestedRowDropAttributes = [];
    this.nestedTableData[0].forEach((table: any, index: number) => {
      if (mappedAttr[index]) {
        table.attributeHeading = mappedAttr[index];
        let foundIndex = this.nslAttributes.findIndex((attr: any) => attr.name == mappedAttr[index]);
        if (foundIndex != -1) {
          this.nestedRowDropAttributes.push(this.nslAttributes[foundIndex]);
          this.nslAttributes.splice(foundIndex, 1);
        }
      } else {
        table.attributeHeading = 'Attribute';
      }
    });
  }
  mapGsiBtn(mappedGsi: any) {
    this.dropActionBts = [];
    mappedGsi.forEach((gsi: any, index: number) => {
      let foundIndex = this.selectGsiList.findIndex((gsiMain: any) => gsiMain.name == gsi.name);
      if (foundIndex != -1) {
        this.dropActionBts[index] = {...gsi,...this.selectGsiList[foundIndex]};
        this.selectGsiList.splice(foundIndex, 1);
        this.selectedGsi.push(gsi?.name);
      }
    });
  }

  getEntityCardEditConfiguration() {
    let config = this.entityBoardFacade.cardConfiguration;
    if (config) {
      if (config?.configurationName) {
        this.cardConfigName = config?.configurationName;
      }
      this.selectedEditCardID = config?.id;
      this.childConfig = config?.childView;
      this.filterDesign = config?.filterDesign;
      if (config?.childView.style?.advanceTableFeatures) {
        this.advanceTableFeatures = config?.childView.style?.advanceTableFeatures;
      }
      this.selectGsiList = [...this.selectGsiList,...config.gsiList];

      this.updateExistingConfiguration(config?.childView?.style);
      this.setCondition(config?.childView);

      if (config?.childView.templateType == 'table') {
        if (config?.childView.style?.attr) {
          this.mapHeaderAttr(config?.childView.style);
        }
        if (config?.childView.style?.nestedRowDropAttr) {
          this.mapNestedHeaderAttr(config?.childView.style?.nestedRowDropAttr);
        }
        if (config?.childView.style?.gsiact) {
          this.mapGsiBtn(config?.childView.style?.gsiact);
        }
        if(config?.childView.style?.headerGsi){
          let attrArray = Object.entries(config?.childView.style?.headerGsi);
          if(attrArray.length>0){
            attrArray.forEach((attr: any)=>{
              this.headerGsiMap.set(attr[0],attr[1]);
            })
          }
        }
      }
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  }
  ChangeConditionColorToggle() {
    this.conditioncColorToggler = !this.conditioncColorToggler;
  }

  prevDrag(event: any,k?:number){
    super.dropAttributeInTemplates(event, k)
  }
  prevDelete(event: any,k?:number){
    super.onDeletingAttr(event, k)
  }
  paginationConfiguration() {
    this.activePagination = !this.activePagination;
  }
}
