<!-- <div (click)="closeDialog()">close</div> -->

<div class="events-wrapper">
  <div class="header">
    <h2 class="title">{{ labels?.Events }}<span class="plusBtn" id="addNewEvent" (click)="addEvent()"> + </span></h2>
    <div class="close-icon">
      <figure class="save-events" id="eventsPlusClick">
        <img src="../../../../../assets/img/tick.svg" alt="" (click)="saveEvents()" />
      </figure>
      <figure class="cancel-img" id="eventsCloseClick">
        <img src="../../../../../assets/img/icon-cancel.svg" alt="" (click)="closeDialog()" />
      </figure>
    </div>
  </div>

  <div class="eventBody">
    <div class="row">
      <ng-container *ngFor="let evt of eventsList; let evtIndex = index">
        <div class="col-md-8">
          <div *ngIf="evt" class="eventSelection">
            <div class="eventTile">
              <h4>{{ evtIndex + 1 }}.{{ labels?.Event }}</h4>
              <div *ngIf="eventsList?.[0]?.eventType || eventsList?.length> 1">
                <figure class="deleteEvent" (click)="onDeleteEventClick(evt, evtIndex)" id="evtremoveimg">
                  <img src="../../../../../assets/img/delete-icon.svg" alt="" />
                </figure>
              </div>
            </div>
            <div class="selectInput">
              <mat-form-field class="custom-matselect search-event popup-bal" appearance="fill" floatLabel="never">
                <mat-select
                  class="drop-arrow"
                  placeholder="{{ labels?.Select_Event }}"
                  [(ngModel)]="evt.eventType"
                  [ngModelOptions]="{ standalone: true }"
                  id="selectedEvent_{{ evtIndex }}_{{ evt?.eventType }}"
                >
                  <mat-option
                    *ngFor="let event of currentAttr?.events; let idx = index"
                    [value]="event.value"
                    id="event_{{ event.value }}"
                    (click)="onEventClick(event.value, idx, event, evt, evtIndex)"
                  >
                    {{ labels[event.name] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-label class="popup-open" *ngIf="evt.eventType == 'ON_SELECT'">Is Popup Open </mat-label>
              <mat-slide-toggle
                class="bot-toggle"
                name="ispopupopen"
                *ngIf="evt.eventType == 'ON_SELECT'"
                [(ngModel)]="evt.isPopupEvent"
                id="ispopupopen_{{ evtIndex }}"
                id="ispopupopen"
              >
              </mat-slide-toggle>

              <ng-template #gsiState>
                <div class="gsi-selected">
                  <label *ngIf="evt?.dsdGsiId">{{ labels?.Selected_GSI }}:-</label>
                  <span *ngIf="evt?.dsdGsiId && evt.gsiName">{{ evt?.gsiName }} </span>
                  <span *ngIf="evt?.dsdGsiId && !evt.gsiName">{{ evt?.dsdGsiId }}</span>
                </div>
                <div *ngIf="evt?.dsdGsiId">
                  <mat-dialog-content *ngIf="gsiSourceCuList?.length != 0">
                    <div>
                      <mat-label>{{labels?.Select_CU}}</mat-label>
                      <input
                        type="text"
                        placeholder="{{ evt?.selectedCu?.name ? evt?.selectedCu?.name : 'Select Change Unit' }}"
                        aria-label="change units"
                        matInput
                        [formControl]="myControl"
                        [matAutocomplete]="auto"
                      />
                      <mat-autocomplete
                        (optionSelected)="selectedChangeUnitFromSearch($event.option.value,evtIndex)"
                        #auto="matAutocomplete"
                      >
                        <mat-option *ngFor="let cu of filteredOptions | async" [value]="cu?.name">
                          {{ cu.name }}
                        </mat-option>
                      </mat-autocomplete>
                    </div>
                  </mat-dialog-content>
                  <div *ngIf="evt?.selectedCu?.name || evt?.attributeMappings?.length > 0">
                    <ng-container *ngFor="let currentMapping of evt?.attributeMappings; let mapIndex = index">
                      <div class="">
                        <div class="t-attr-delete">
                          <label class="d-block custom-label"
                            >{{ labels?.Target_Attribute }}: {{ targetContextualNameExpEvent }}</label
                          >
                          <div class="row no-gutters">
                            <a class="col-auto ml-auto" (click)="deleteAttributeMapping(evt, mapIndex, evtIndex)">
                              <figure>
                                <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" alt="" />
                              </figure>
                            </a>
                          </div>
                        </div>
                        <ng-container *ngIf="!currentMapping?.targetAttributeId; else targetAttributeInfo">
                          <mat-accordion class="playerdata custom-acc">
                            <mat-expansion-panel *ngIf="physicalLayer.length > 0">
                              <mat-expansion-panel-header
                              (click)="
                              onClickLayerExpPanelGsi('physical', currentMapping, mapIndex, null, null, evtIndex)
                            "
                              >
                                <mat-panel-title class="active-bold">
                                  <span>{{ labels?.Physical_Layer }}</span>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <app-library-change-unit-layers
                                [onlyAttribute]="true"
                                [layerLabel]="labelForPhyscialLayer"
                                [layerData]="physicalLayer"
                                [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                                (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                              >
                              </app-library-change-unit-layers>
                            </mat-expansion-panel>
                          </mat-accordion>

                          <mat-accordion class="ilayerdata custom-acc">
                            <mat-expansion-panel *ngIf="informationLayer.length > 0">
                              <mat-expansion-panel-header
                              (click)="
                              onClickLayerExpPanelGsi('information', currentMapping, mapIndex, null, null, evtIndex)
                            "
                              >
                                <mat-panel-title class="active-bold">
                                  {{ labels?.Information_Layer }}
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <app-library-change-unit-layers
                                [onlyAttribute]="true"
                                [layerLabel]="labelForInformationLayer"
                                [layerData]="informationLayer"
                                [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                                (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                              >
                              </app-library-change-unit-layers>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </ng-container>
                        <ng-template #targetAttributeInfo>
                          <p class="attr-output d-flex align-items-center mb-3">
                            {{ currentMapping?.targetAttributeId }}
                          </p>
                        </ng-template>
                        <div class="source attributes">
                          <div class="display value">
                            <ng-container *ngIf="!currentMapping?.refDisplayAttributeId; else displaySourceInfo">
                              <label class="d-block custom-label">{{
                                labels?.Display_Value_Of_Refernece_Entity
                              }}</label>
                              <mat-accordion class="playerdata custom-acc">
                                <mat-expansion-panel *ngIf="sourceInformationLayer.length > 0">
                                  <mat-expansion-panel-header
                                    (click)="
                                      onClickLayerExpPanelGsi(
                                        'information',
                                        currentMapping,
                                        mapIndex,
                                        'refDisplayAttributeName',
                                        null,
                                        evtIndex
                                      )
                                    "
                                  >
                                    <mat-panel-title class="active-bold">
                                      <span>{{ labels?.Information_Layer }}</span>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <app-library-change-unit-layers
                                    [onlyAttribute]="true"
                                    [layerLabel]="labelForInformationLayer"
                                    [layerData]="sourceInformationLayer"
                                    [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                                    (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                                  >
                                  </app-library-change-unit-layers>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </ng-container>
                            <ng-template #displaySourceInfo>
                              <div
                                *ngIf="
                                  (currentMapping?.refDisplayInfo || currentMapping?.refDisplayAttributeId) &&
                                  sourceInformationLayer.length > 0
                                "
                              >
                                <label class="d-block custom-label">{{
                                  labels?.Display_Value_Of_Refernece_Entity
                                }}</label>
                                <p class="attr-output d-flex align-items-center mb-3">
                                  {{ currentMapping?.refDisplayAttributeId }}
                                </p>
                              </div>
                            </ng-template>
                            <ng-template #displaySourceTrigger>
                              <div
                                *ngIf="
                                  (currentMapping?.refDisplayTrigger || currentMapping?.refDisplayAttributeId) &&
                                  sourceTriggerCESLayer.length > 0
                                "
                              >
                                <label class="d-block custom-label">{{
                                  labels?.Display_Value_Of_Refernece_Entity
                                }}</label>
                                <p class="attr-output d-flex align-items-center mb-3">
                                  {{ currentMapping?.refDisplayAttributeId }}
                                </p>
                              </div>
                            </ng-template>
                            <ng-container *ngIf="!currentMapping?.refDisplayAttributeId; else displaySourceTrigger">
                              <mat-accordion class="ilayerdata custom-acc">
                                <mat-expansion-panel *ngIf="sourceTriggerCESLayer.length > 0">
                                  <mat-expansion-panel-header
                                    (click)="
                                      onClickLayerExpPanelGsi(
                                        'triggerCES',
                                        currentMapping,
                                        mapIndex,
                                        'refDisplayAttributeName',
                                        null,
                                        evtIndex
                                      )
                                    "
                                  >
                                    <mat-panel-title class="active-bold">
                                      {{ labels?.TriggerCES }}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <app-library-change-unit-layers
                                    [onlyAttribute]="true"
                                    [layerLabel]="labelForTriggerCESLayer"
                                    [layerData]="sourceTriggerCESLayer"
                                    [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                                    (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                                  >
                                  </app-library-change-unit-layers>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </ng-container>
                          </div>
                          <div class="Actual Value">
                            <ng-container *ngIf="!currentMapping?.refActualAttributeId; else actualSourceInfo">
                              <label class="d-block custom-label">{{ labels?.Actual_Value_Of_Refernece_Entity }}</label>
                              <mat-accordion class="playerdata custom-acc">
                                <mat-expansion-panel *ngIf="sourceInformationLayer.length > 0">
                                  <mat-expansion-panel-header
                                    (click)="
                                      onClickLayerExpPanelGsi(
                                        'information',
                                        currentMapping,
                                        mapIndex,
                                        'refActualAttributeName',
                                        null,
                                        evtIndex
                                      )
                                    "
                                  >
                                    <mat-panel-title class="active-bold">
                                      <span>{{ labels?.Information_Layer }}</span>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <app-library-change-unit-layers
                                    [onlyAttribute]="true"
                                    [layerLabel]="labelForInformationLayer"
                                    [layerData]="sourceInformationLayer"
                                    [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                                    (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                                  >
                                  </app-library-change-unit-layers>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </ng-container>
                            <ng-template #actualSourceInfo>
                              <div *ngIf="currentMapping?.refActualInfo">
                                <label class="d-block custom-label">{{
                                  labels?.Actual_Value_Of_Refernece_Entity
                                }}</label>
                                <p class="attr-output d-flex align-items-center mb-3">
                                  {{ currentMapping?.refActualAttributeId }}
                                </p>
                              </div>
                            </ng-template>
                            <ng-template #actualSourceTrigger>
                              <div *ngIf="currentMapping.refActualTrigger">
                                <label class="d-block custom-label">{{
                                  labels?.Actual_Value_Of_Refernece_Entity
                                }}</label>
                                <p class="attr-output d-flex align-items-center mb-3">
                                  {{ currentMapping.refActualAttributeId }}
                                </p>
                              </div>
                            </ng-template>
                            <ng-container *ngIf="!currentMapping?.refActualAttributeId; else actualSourceTrigger">
                              <mat-accordion class="ilayerdata custom-acc">
                                <mat-expansion-panel *ngIf="sourceTriggerCESLayer.length > 0">
                                  <mat-expansion-panel-header
                                    (click)="
                                      onClickLayerExpPanelGsi(
                                        'triggerCES',
                                        currentMapping,
                                        mapIndex,
                                        'refActualAttributeName',
                                        null,
                                        evtIndex
                                      )
                                    "
                                  >
                                    <mat-panel-title class="active-bold">
                                      {{ labels?.TriggerCES }}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <app-library-change-unit-layers
                                    [onlyAttribute]="true"
                                    [layerLabel]="labelForTriggerCESLayer"
                                    [layerData]="sourceTriggerCESLayer"
                                    [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                                    (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                                  >
                                  </app-library-change-unit-layers>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="entityAdd" *ngIf="evt?.selectedCu">
                    <h5 (click)="addGsiTargetAttribute(evt)"><span class="entityAddBtn">+</span> {{labels?.Add_Target}}</h5>
                    <!-- <h5><span class="entityAddBtn">+</span> Add
                      Conditions</h5> -->
                  </div>
                </div>
                <div *ngIf="evt?.eventType == 'ON_SELECT' && allCustomizations && allCustomizations[evtIndex]">
                  <p>Display As:</p>
                  <div *ngIf="allCustomizations[evtIndex]?.displayType">
                    <mat-radio-group
                      id="gsi_display_options_{{ evtIndex }}"
                      [(ngModel)]="allCustomizations[evtIndex].displayType"
                    >
                      <mat-radio-button value="TEXT">Text</mat-radio-button>
                      <mat-radio-button value="IMAGE">Image</mat-radio-button>
                      <mat-radio-button value="IMAGE_AND_TEXT">Image and Text</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div *ngIf="allCustomizations[evtIndex]?.displayType == 'TEXT'">
                    <input type="text" [(ngModel)]="allCustomizations[evtIndex].displayText" />
                  </div>
                  <div *ngIf="allCustomizations[evtIndex]?.displayType == 'IMAGE'">
                    <app-file-upload
                      [fileUploadFromEntity]="true"
                      (fileUploadResponse)="imageChipFileUpload($event, evtIndex)"
                    >
                    </app-file-upload>
                  </div>
                  <div *ngIf="allCustomizations[evtIndex]?.displayType == 'IMAGE_AND_TEXT'">
                    <app-file-upload
                      [fileUploadFromEntity]="true"
                      (fileUploadResponse)="imageChipFileUpload($event, evtIndex)"
                    ></app-file-upload>
                    <input type="text" [(ngModel)]="allCustomizations[evtIndex].displayText" />
                  </div>
                </div>

              </ng-template>

              <div class="eventOnFocus">
                <!-- dependent dropdown -->
                <mat-radio-group
                  aria-label="Select an option"
                  *ngIf="evt?.isEnableActions"
                  [(ngModel)]="evt.isEnableRefEntity"
                  (change)="onActionChange($event, evt, evtIndex)"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-radio-button
                    *ngFor="let action of actionItems"
                    id="events_{{ action.name }}_{{ evt.eventType }}_{{ evtIndex }}"
                    [value]="action.value"
                    [disabled]="
                      (action.name == 'Reference Entity' && disableEntityRef) ||
                      (action.name == 'Expression Event' && onselectFlag)
                    "
                    [matTooltip]="'Reference Entity and Contextual Search Configuration doesnt Exist together'"
                    [matTooltipDisabled]="!(action.name == 'Reference Entity' && disableEntityRef)"
                  >
                    {{ action.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div *ngIf="evt.isEnableRefEntity == 'refEnt'; else gsiState">
                <mat-form-field class="custom-matselect search-event label-class" appearance="fill" floatLabel="never">
                  <mat-select
                    [placeholder]="evt?.refEntity ? evt?.refEntity.name : 'Reference Entity'"
                    [(ngModel)]="evt.referenceEntityInfo.dsdReferenceEntityId"
                    (selectionChange)="onSelectRefEntity(evt)"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <mat-form-field class="search-select search-event left-mar width-search" floatLabel="never">
                      <input
                        matInput
                        type="text"
                        id="refEntity"
                        class="search-input-cls"
                        [(ngModel)]="entitySearchText"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Search"
                        (keyup)="searchEntityInput.next(); pageIndex = 0"
                        (keydown)="$event.stopPropagation()"
                      />
                      <button
                        mat-button
                        *ngIf="searchText"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="onClearEntityClick()"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                      <button mat-button matSuffix mat-icon-button (click)="getEntities(true)">
                        <mat-icon class="search-icon-cls">search</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-option *ngIf="selectedEntities?.length === 0">NA</mat-option>
                    <mat-option
                      *ngFor="let entity of selectedEntities"
                      [value]="entity.id"
                      (click)="onEntitySelection(entity, evt)"
                    >
                      {{ entity?.name }}
                    </mat-option>
                    <app-paginator
                      [pageIndex]="pageIndex"
                      [pageSize]="pageSize"
                      [totalRecords]="totalRefEntity === undefined ? selectedEntities?.length : totalRefEntity"
                      (onPageChange)="onEntityPageChange($event)"
                    >
                    </app-paginator>
                  </mat-select>
                </mat-form-field>

                <!-- conditions block for events -->

                <!-- target Attributes block for events -->
                <ng-container
                  *ngFor="let targetAttr of evt?.referenceEntityInfo?.attributeReferences; let attInd = index"
                >
                  <div class="">
                    <div class="t-attr-delete">
                      <label class="d-block custom-label">{{ labels?.Target_Attribute }}:</label>
                      <div class="row no-gutters">
                        <a class="col-auto ml-auto" (click)="onDeleteAttrClick(evt, attInd)">
                          <figure>
                            <img
                              id="deleteTargetAttributeEvent_{{ evt.eventType }}_{{ evtIndex }}"
                              [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                              alt=""
                            />
                          </figure>
                        </a>
                      </div>
                    </div>
                    <ng-container *ngIf="targetAttr?.isEnableTargetAttribute; else targetAttrInfo">
                      <mat-accordion class="playerdata custom-acc">
                        <mat-expansion-panel *ngIf="physicalLayer.length > 0">
                          <mat-expansion-panel-header
                            (click)="onClickLayerExpPanelForEvent('physical', targetAttr, evtIndex, attInd)"
                          >
                            <mat-panel-title class="active-bold">
                              <span>{{ labels?.Physical_Layer }}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <app-library-change-unit-layers
                            [onlyAttribute]="true"
                            [layerLabel]="labelForPhyscialLayer"
                            [layerData]="physicalLayer"
                            [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                            (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                          >
                          </app-library-change-unit-layers>
                        </mat-expansion-panel>
                      </mat-accordion>

                      <mat-accordion class="ilayerdata custom-acc">
                        <mat-expansion-panel *ngIf="informationLayer.length > 0">
                          <mat-expansion-panel-header
                            (click)="onClickLayerExpPanelForEvent('information', targetAttr, evtIndex, attInd)"
                          >
                            <mat-panel-title class="active-bold">
                              {{ labels?.Information_Layer }}
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <app-library-change-unit-layers
                            [onlyAttribute]="true"
                            [layerLabel]="labelForInformationLayer"
                            [layerData]="informationLayer"
                            [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                            (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                          >
                          </app-library-change-unit-layers>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </ng-container>
                    <ng-template #targetAttrInfo>
                      <p class="attr-output d-flex align-items-center mb-3">
                        {{ targetAttr.currentLayer }} > {{ targetAttr?.entityDetails?.entity?.name }} >
                        {{ targetAttr?.entityDetails?.attribute?.name }}
                      </p>
                    </ng-template>
                    <label class="d-block custom-label">{{ labels?.Display_Value_Of_Refernece_Entity }}</label>
                    <mat-accordion class="playerdata custom-acc layers-show">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title class="active-bold">
                            <mat-chip-list #chipGrid aria-label="display value">
                              <mat-chip
                                *ngFor="let value of targetAttr?.refDisplayAttributeIds; let i = index"
                                [removable]="true"
                                (removed)="removeValue(i, attInd, evtIndex)"
                              >
                                {{ value }}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                            </mat-chip-list>
                          </mat-panel-title>
                         
                        </mat-expansion-panel-header>

                        <app-entity-dcd
                          *ngFor="let attr of evt?.refEntity?.nslAttributes"
                          [entity]="evt?.refEntity"
                          [attr]="attr"
                          [attrName]="evt?.refEntity?.name + '.' + attr?.name"
                          (attributeEmit)="onDisplayEntityChange($event, evt, targetAttr)"
                        ></app-entity-dcd>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <!-- {{ targetAttr.actualAttId | json }} -->
                    <label class="d-block custom-label">{{ labels?.Actual_Value_Of_Refernece_Entity }}</label>
                    <mat-accordion class="playerdata custom-acc">
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title class="active-bold">
                            <span>{{ targetAttr['refActualAttributeName'] }}</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-entity-dcd
                          *ngFor="let attr of evt?.refEntity?.nslAttributes"
                          [entity]="evt?.refEntity"
                          [attr]="attr"
                          [attrName]="evt?.refEntity?.name + '.' + attr?.name"
                          (attributeEmit)="onActualEntityChange($event, evt, targetAttr)"
                        ></app-entity-dcd>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <mat-radio-group
                      aria-label="Select an option"
                      [(ngModel)]="targetAttr.referenceValueType"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <mat-radio-button value="OPTIONS">{{ labels?.Options }}</mat-radio-button>
                      <mat-radio-button value="VALUE">{{ labels?.Values }}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </ng-container>
                <div class="entityAdd" *ngIf="evt?.referenceEntityInfo?.dsdReferenceEntityId">
                  <h5 (click)="addTargetAttribute(evt)" id="addTargetAttr_{{ evt.eventType }}_{{ evtIndex }}">
                    <span class="entityAddBtn">+</span> {{ labels?.Add_Target_Attribute }}
                  </h5>
                  <h5
                    (click)="addConditions(evt)"
                    id="addConditions_{{ evt.eventType }}_{{ evtIndex }}"
                    *ngIf="evt?.referenceEntityInfo?.membershipCriterias?.length == 0"
                  >
                    <span class="entityAddBtn">+</span>{{ labels?.Add_Conditions }}
                  </h5>
                  <h5 (click)="addFilters(evt)" class="ml-4" id="addFilters_{{ evt.eventType }}_{{ evtIndex }}">
                    <span class="entityAddBtn">+</span> {{ labels?.AddFilter }}
                  </h5>
                </div>
                <div *ngIf="evt?.referenceEntityInfo?.dataFilterDto?.sortList" class="filters-container">
                  <div
                    class="add-filter-container"
                    *ngFor="let filter of evt.referenceEntityInfo.dataFilterDto.sortList; let filterInd = index"
                  >
                    <div class="add-filter-sort-headgrp row">
                      <div class="add-filter-sort-header col-md-3">
                        <p class="add-filter-text"><strong>Sort</strong></p>
                      </div>
                      <div class="col-md-7"></div>
                      <div class="add-filter-sort-btngrp col-md-2">
                        <button
                          class="sort-reset-icon col-6"
                          (click)="resetFilter(evt.referenceEntityInfo.dataFilterDto.sortList, filterInd)"
                        >
                          <svg class="img-reset" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                            <path
                              d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"
                            />
                          </svg>
                        </button>
                        <button
                          class="sort-delete-icon col-6"
                          (click)="deleteFilter(evt.referenceEntityInfo.dataFilterDto.sortList, filterInd)"
                        >
                          <svg class="img-delete" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                            <path
                              d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="dropdown-grp-filter">
                      <mat-form-field appearance="fill" class="filter-dropdwn">
                        <mat-label>{{ labels?.Select_Attribute }}</mat-label>
                        <mat-select [(ngModel)]="filter.fieldName" name="attributeName_{{ filterInd }}_{{ evtIndex }}">
                          <mat-option
                            *ngFor="let attr of evt.refEntity.nslAttributes"
                            value="{{ evt.refEntity.name }}.{{ attr.name }}"
                          >
                            {{ attr.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="fill" class="filter-dropdwn">
                        <mat-label>{{ labels?.SelectOrder }}</mat-label>
                        <mat-select [(ngModel)]="filter.order" name="order_{{ filterInd }}_{{ evtIndex }}">
                          <mat-option [value]="'ASC'">
                            {{ labels?.Ascending }}
                          </mat-option>
                          <mat-option [value]="'DESC'">
                            {{ labels?.Descending }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div
                  class="select-search-type"
                  *ngIf="
                    evt?.referenceEntityInfo?.contextSearchRequest && evt?.referenceEntityInfo?.dsdReferenceEntityId
                  "
                >
                  <mat-form-field appearance="fill" floatLabel="never">
                    <mat-label>{{ labels?.Select_Search_Type }}</mat-label>
                    <mat-select
                      (selectionChange)="setSearchType(evt)"
                      [(ngModel)]="contextAttrSearchType"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <mat-option value="CONTAINS">{{ labels?.Contains }} </mat-option>
                      <mat-option value="STARTS_WITH">{{ labels?.Start_With }} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="condition-cls" *ngIf="evt?.showCondition">
                  <div>
                    <form class="altcuform">
                      <div class="valuePluscount-event">
                        <div
                          *ngIf="evt?.referenceEntityInfo?.membershipCriterias?.length > 0"
                          class="display-condition"
                        >
                          <div
                            class="add-intered-value display-cond-ev"
                            *ngFor="
                              let condition of evt?.referenceEntityInfo?.membershipCriterias;
                              let criteriaIndex = index
                            "
                          >
                            {{ condition?.displayCondition }}
                            <span class="pointer-delete" *ngIf="condition?.displayCondition"
                              ><img
                                (click)="removeMembershipCritira(criteriaIndex, evtIndex, evt)"
                                id="attrremoveimg"
                                class="remove-condition-cls"
                                [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                                alt=""
                            /></span>
                          </div>
                        </div>

                        <div class="conditon-div-event">
                          <span class="add-intered-value" *ngIf="sourceFormulaAttr && sourceFormulaAttr !== ''"
                            >{{ sourceFormulaAttr }} {{ selectedOperator }}</span
                          >
                          <div class="eventsoperations-wrap input-cond" id="alternateKeyboard">
                            <input
                              class="add-value-alternate-input"
                              type="text"
                              [placeholder]="labels?.Add_Value"
                              name="expression"
                              [ngModel]="targetAttributeValue"
                              (ngModelChange)="onFreeTextChange($event)"
                              id="expressionInput"
                            />
                            <figure
                              (click)="toggleExpressionsOperators = !toggleExpressionsOperators"
                              class="eventsoperations-icon ml-4"
                            >
                              <img
                                id="keyboard_{{ evt.eventType }}_{{ evtIndex }}"
                                [src]="'../../../../../assets/img/icon-keyboard.svg' | CDNUrlRewritePipe"
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </form>

                    <ul class="operators eventsoperations" id="expressionOperators" *ngIf="toggleExpressionsOperators">
                      <li id="operatorsList" class="addaddVlaue">
                        <span
                          class="addVlaueBtn"
                          *ngFor="let operator of operators; let operatorIndex = index"
                          id="cuDetails_{{ operator }}"
                          (click)="addFormulaToEvent(operator, evt)"
                          >{{ operator | LanguageOperatorsPipe: labels }}</span
                        >
                      </li>
                    </ul>
                    <div class="eventAction mb-4">
                      <button
                        class="eventActionBtn"
                        id="{{ 'saveExpression_' + evtIndex }}"
                        (click)="changeOfAttributeValue(evtIndex)"
                      >
                        {{ labels?.Save }}
                      </button>
                    </div>
                    <ng-container class="layers-show" *ngIf="evt.isEnableConditionSelection">
                      <div *ngIf="!evt?.isRefCondition">
                        <mat-accordion class="playerdata custom-acc">
                          <mat-expansion-panel *ngIf="physicalLayer.length > 0">
                            <mat-expansion-panel-header
                              (click)="onClickLayerExpPanelForEventCondition('physical', evtIndex, evt)"
                            >
                              <mat-panel-title class="active-bold">
                                <span>{{ labels?.Physical_Layer }}</span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-library-change-unit-layers
                              [onlyAttribute]="true"
                              [layerLabel]="labelForPhyscialLayer"
                              [layerData]="conditionphysicalLayer"
                              [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                              (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                            >
                            </app-library-change-unit-layers>
                          </mat-expansion-panel>
                        </mat-accordion>

                        <mat-accordion class="ilayerdata custom-acc">
                          <mat-expansion-panel *ngIf="informationLayer.length > 0">
                            <mat-expansion-panel-header
                              (click)="onClickLayerExpPanelForEventCondition('information', evtIndex, evt)"
                            >
                              <mat-panel-title class="active-bold">
                                {{ labels?.Information_Layer }}
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-library-change-unit-layers
                              [onlyAttribute]="true"
                              [layerLabel]="labelForInformationLayer"
                              [layerData]="conditioninformationLayer"
                              [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                              (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                            >
                            </app-library-change-unit-layers>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                      <div *ngIf="evt?.isRefCondition">
                        <mat-accordion class="playerdata custom-acc">
                          <mat-expansion-panel *ngIf="evt?.refEntity">
                            <mat-expansion-panel-header
                              (click)="onClickLayerExpPanelForEventCondition('physical', evtIndex, evt)"
                            >
                              <mat-panel-title class="active-bold">
                                <span>{{ labels?.Physical_Layer }}</span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-library-change-unit-layers
                              [onlyAttribute]="true"
                              [layerLabel]="labelForPhyscialLayer"
                              [layerData]="conditionphysicalLayer"
                              [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                              (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                            >
                            </app-library-change-unit-layers>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <!-- <div class="text-right my-4">
                  <a class="add-target" >+ {{ labels?.Add_Target_Attribute }}</a
                  ><a class="add-condition" >+ {{ labels?.Add_Conditions }}</a>
                </div> -->
              </div>
              <!-- <div *ngIf="evt.isEnableRefEntity == 'selectGsi'">
                <div class="gsi-selected">
                  <label *ngIf="evt?.dsdGsiId">{{ labels?.Selected_GSI }}:-</label
                  ><span *ngIf="evt?.dsdGsiId && evt.gsiName">{{ evt?.gsiName }}</span
                  ><span *ngIf="evt?.dsdGsiId && !evt.gsiName">{{ evt?.dsdGsiId }}</span>
                </div>
              </div> -->
              <div *ngIf="evt.isEnableRefEntity == 'expressionEvent'">
                <div class="t-attr-delete">
                  <label class="d-block custom-label">{{ labels?.Target_Attribute }}: </label>
                  <div class="row no-gutters">
                    <a class="col-auto ml-auto" (click)="deleteTargetForExpEvents(evt, evtIndex)">
                      <figure>
                        <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" alt="" />
                      </figure>
                    </a>
                  </div>
                </div>
                <ng-container
                  *ngIf="(evt?.eventExpressions)?.[0]?.targetContextualName == ''; else targetAttributeInfo"
                >
                  <mat-accordion class="playerdata custom-acc">
                    <mat-expansion-panel
                      *ngIf="physicalLayer.length > 0"
                      [expanded]="(evt?.eventExpressions)?.[0]?.targetPanel"
                      id="target_attr_physical_layer_{{ evtIndex + 1 }}"
                    >
                      <mat-expansion-panel-header
                        (click)="
                          onClickLayerExpPanelGsi('physical', currentMapping, mapIndex, null, 'target', evtIndex)
                        "
                      >
                        <mat-panel-title class="active-bold">
                          <span>{{ labels?.Physical_Layer }}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-library-change-unit-layers
                        [onlyAttribute]="true"
                        [layerLabel]="labelForPhyscialLayer"
                        [layerData]="physicalLayer"
                        [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                        (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                      >
                      </app-library-change-unit-layers>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion class="ilayerdata custom-acc">
                    <mat-expansion-panel *ngIf="informationLayer.length > 0">
                      <mat-expansion-panel-header
                        (click)="
                          onClickLayerExpPanelGsi('information', currentMapping, mapIndex, null, 'target', evtIndex)
                        "
                        id="target_attr_info_layer_{{ evtIndex + 1 }}"
                      >
                        <mat-panel-title class="active-bold">
                          {{ labels?.Information_Layer }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-library-change-unit-layers
                        [onlyAttribute]="true"
                        [layerLabel]="labelForInformationLayer"
                        [layerData]="informationLayer"
                        [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                        (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                      >
                      </app-library-change-unit-layers>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-container>
                <ng-template #targetAttributeInfo>
                  <p class="attr-output d-flex align-items-center mb-3">
                    {{ evt.eventExpressions?.[0].targetContextualName }}
                  </p>
                </ng-template>
                <div class="t-attr-delete">
                  <label class="d-block custom-label">{{ labels?.Source_Attribute }}:</label>
                </div>
                <ng-container *ngIf="!currentMapping?.targetAttributeId; else targetAttributeInfo">
                  <mat-accordion class="playerdata custom-acc">
                    <mat-expansion-panel
                      *ngIf="physicalLayer.length > 0"
                      [expanded]="(evt?.eventExpressions)?.[0]?.sourcePanel"
                      id="source_attr_physical_layer_{{ evtIndex + 1 }}"
                    >
                      <mat-expansion-panel-header
                        (click)="
                          onClickLayerExpPanelGsi('physical', currentMapping, mapIndex, null, 'source', evtIndex)
                        "
                      >
                        <mat-panel-title class="active-bold">
                          <span>{{ labels?.Physical_Layer }}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-library-change-unit-layers
                        [onlyAttribute]="true"
                        [layerLabel]="labelForPhyscialLayer"
                        [layerData]="physicalLayer"
                        [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                        (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                      >
                      </app-library-change-unit-layers>
                    </mat-expansion-panel>
                  </mat-accordion>

                  <mat-accordion class="ilayerdata custom-acc">
                    <mat-expansion-panel *ngIf="informationLayer.length > 0">
                      <mat-expansion-panel-header
                        (click)="
                          onClickLayerExpPanelGsi('information', currentMapping, mapIndex, null, 'source', evtIndex)
                        "
                        id="source_attr_info_layer_{{ evtIndex + 1 }}"
                      >
                        <mat-panel-title class="active-bold">
                          {{ labels?.Information_Layer }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-library-change-unit-layers
                        [onlyAttribute]="true"
                        [layerLabel]="labelForInformationLayer"
                        [layerData]="informationLayer"
                        [(tempDataForEmbededGSIs)]="contextualIdWithNestedConditions"
                        (clearEmbededGSIArray)="clearEmbededGSIArray($event)"
                      >
                      </app-library-change-unit-layers>
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-container>
                <ng-template #targetAttributeInfo>
                  <p class="attr-output d-flex align-items-center mb-3">
                    {{ currentMapping.currentLayer }} > {{ currentMapping?.entityDetails?.entity?.name }} >
                    {{ currentMapping?.entityDetails?.attribute?.name }}
                  </p>
                </ng-template>
                <ul class="operators eventsoperations" id="expressionsOperators">
                  <li id="operatorsList" class="addaddVlaue">
                    <span
                      class="addVlaueBtn cursor-pointer"
                      *ngFor="let operator of operatorsForExpressionEvents; let operatorIndex = index"
                      id="cuDetails_{{ operator }}"
                      (click)="addOperatorToExpression(operator, evtIndex)"
                      >{{ operator | LanguageOperatorsPipe: labels }}</span
                    >
                  </li>
                </ul>
                <ul class="" id="expressiondisp">
                  <li class="expressionList" id="operatorsList">
                    <span class="addVlaueBtn" id="displayExp"
                      >Expression:
                      <span *ngFor="let char of evt.eventExpressions?.[0].expArray; let ind = index">
                        <span *ngIf="ind % 2 === 0">{{ char.split('.')[char.split('.')?.length - 1] }}</span>
                        <span class="nh-fw-800" *ngIf="ind % 2 === 1">{{ char }}</span>
                      </span>
                    </span>
                    <span class="material-icons cursor-pointer" (click)="deleteLastCharFromExpression(evtIndex)"
                      >cancel</span
                    >
                  </li>
                  <li style="color: red;">
                    <p>{{ (evt?.eventExpressions)?.[0]?.expressionError }}</p>
                  </li>
                </ul>
                <ul class="cursor-pointer" id="expressiondisp">
                  <li id="resetExpression" class="">
                    <button (click)="resetExpression(evtIndex)">RESET</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div
        class="col-md-4"
        *ngIf="selectedEventGSI && selectedEventGSI?.isEnableRefEntity == 'selectGsi' && selectedEventGSI?.eventType"
      >
        <div class="eventList">
          <h4>Event GSI List</h4>
          <div class="searchBar searchBar-gsi">
            <input
              type="text"
              id="eventGsi_input"
              placeholder="Search"
              name="search"
              [(ngModel)]="searchText"
              (keyup)="searchGSIInput.next()"
              (keydown)="$event.stopPropagation()"
            />
            <button mat-button matSuffix mat-icon-button>
              <mat-icon class="search-icon-cls" id="eventGsi_search_btn">search</mat-icon>
            </button>
            <!-- <button type="submit"><img src="../../../../../assets/images/icon-search.png" alt="" /></button> -->
          </div>
          <ul>
            <li
              class="gsilist-li"
              id="eventGsi_{{ eventGsi?.displayName }}"
              *ngFor="let eventGsi of gsiEventList"
              (click)="selectedGsiEvent(eventGsi)"
            >
              {{ eventGsi.displayName }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
