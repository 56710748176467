import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeUnit, ChangeUnitTypes, Librarytypes, nodeAddChangeUnitInCUList, removeEmptyCu, TranslatorService, NodeGsiFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-node-nested-cu',
  templateUrl: './node-nested-cu.component.html',
  styleUrls: ['./node-nested-cu.component.scss'],
})
export class NodeNestedCuComponent implements OnInit {
  @Input() gsiData: any;
  @Input() node: any;
  @Input() graphComponent: boolean = false;
  @Output() emitChangeCompoent: EventEmitter<any> = new EventEmitter<any>();
  @Output() nestedCuEmit: EventEmitter<any> = new EventEmitter<any>();
  cuName: string;
  private ngUnsubscribe = new Subject();
  nestedCuList: any = [];
  labels: any;
  cuLabel: any;
  changeUnitList: any = [{ ...new ChangeUnit(), index: 1, nextTriggerSet: [] }];
  instanceNumber: number = 0;
  isSecondlevelSaved = false;
  parentnestedCu: any;
  isAddedFromLibrary: boolean = false;
  isDisabled: boolean = false;
  nestedCuLib: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private changeunitFacade: NodeChangeUnitFacadeService
  ) {
    this.detectNestedGsiData();
    this.detectLanguageChange();
    this.detectSavedCU();
    this.getTriggerChangeUnitData();
    this.detectParentNested();
    this.getNestedCUDetails();
  }

  ngOnInit(): void {
    /* istanbul ignore else */
    if (this.node?.data?.instanceNumber) {
      this.instanceNumber = this.node?.data?.instanceNumber;
    }
    if (this.node?.data?.parentNestedId) {
      this.changeunitFacade.fetchNestedTriggerCu(this.node.data.parentNestedId, true);
    }
  }

  selectNestedGsiFromLibrary() {
    if (this.instanceNumber == 0) {
      this.gsiFacade.openLibrary(Librarytypes.GSInested, this.instanceNumber + 1);
    } else {
      this.gsiFacade.openLibrary(Librarytypes.ChangeUnit, this.instanceNumber + 1);
    }
  }

  detectNestedGsiData() {
    this.gsiFacade.nestedGsiData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.instanceNumber == 0) {
        res.isNested = true;
        this.isAddedFromLibrary = true;
        this.changeUnitList[0].name = res?.name;
        this.nestedCuList = [];
        this.nestedCuList.push({
          dsdGsiId: res?.dsdId,
          gsiId: res?.id,
          triggerConditionName: '',
          triggerCondition: '',
          nestedCUName: res?.name,
        });
        if (this.node?.data?.mindCUList?.length > 0) {
          this.node?.data?.mindCUList.forEach((mindCu: any) => {
            this.nestedCuList.push(mindCu);
          });
        }
        this.isDisabled = true;
      }
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  saveNestedCu() {
    if (this.isAddedFromLibrary) {
      if (this.nestedCuLib) {
        this.nestedCuLib.name = this.changeUnitList[0].name;
        this.changeUnitList[0].name = '';
        this.changeunitFacade.saveTriggerCu(this.nestedCuLib, this.instanceNumber);
      } else {
        this.nestedCuEmit.emit(this.nestedCuList);
      }
    } else {
      this.changeunitFacade.saveCU(this.changeUnitList[0]);
    }
  }
  detectSavedCU() {
    this.changeunitFacade.savedIndCuSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        if (this.instanceNumber == 0) {
          res.referencedChangeUnit = res.id;
          res.tfReferencedChangeUnit = res.tfId;

          this.nestedCuList = {
            dsdGsiId: res.id,
            gsiId: res.id,
            triggerConditionName: '',
            triggerCondition: '',
            nestedCUName: res.name,
            isSingleTrigger: true,
          };

          delete res.id;
          delete res.dsdId;
          res.index = 1;
          this.changeunitFacade.saveTriggerCu(res);
        } else if (this.instanceNumber !== 0) {
          res.referencedChangeUnit = res.id;
          res.tfReferencedChangeUnit = res.tfId;
          delete res.id;
          delete res.dsdId;
          res.index = 1;
          this.changeunitFacade.saveTriggerCu(res, this.instanceNumber, false, false, false);
        }
      }
    });
  }
  getTriggerChangeUnitData() {
    this.changeunitFacade.savedNestedChangeUnitList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        if (this.instanceNumber === 0) {
          this.nestedCuList = {
            dsdGsiId: res[0].tfId,
            gsiId: res[0].tfId,
            triggerConditionName: null,
            triggerCondition: null,
            nestedCUName: res[0].name,
            alwaysTrigger: true,
            isSingleTrigger: true,
          };
          const changeUnitData = this.gsiData?.solutionLogic;
          const mindCuData = this.nestedCuList;

          const parentCuData = this.gsiData?.solutionLogic.find((x: any) => x.index == this.node.data.index);
          this.gsiData.solutionLogic = nodeAddChangeUnitInCUList(
            parentCuData,
            changeUnitData,
            mindCuData,
            ChangeUnitTypes.Nested
          );
          this.gsiData = removeEmptyCu(this.gsiData);
          this.gsiFacade.saveGsi(this.gsiData);
        } else if (this.instanceNumber !== 0 && !res[0].isFinalNested && !this.isSecondlevelSaved) {
          this.nestedCuList = {
            dsdGsiId: res[0].tfId,
            gsiId: res[0].tfId,
            triggerConditionName: null,
            triggerCondition: null,
            nestedCUName: res[0].name,
            alwaysTrigger: true,
            isSingleTrigger: true,
          };
          /* istanbul ignore else */
          if (!this.parentnestedCu.mindCUList) {
            this.parentnestedCu.mindCUList = [];
          }

          this.parentnestedCu.mindCUList.push(this.nestedCuList);
          this.isSecondlevelSaved = true;
          this.changeunitFacade.saveTriggerCu(this.parentnestedCu, this.instanceNumber, null, true);
        } else {
          this.changeunitFacade.multiNestedAdded(true);
        }
      }
    });
  }
  detectParentNested() {
    this.changeunitFacade.getMultiNestedTriggerCu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.parentnestedCu = res;
      }
    });
  }
  GotoPrevious() {
    this.emitChangeCompoent.emit(Librarytypes.AddNodeActionMenu);
  }

  deleteNestedCu() {
    this.changeUnitList[0].name = '';
    this.nestedCuList = [];
    this.isDisabled = false;
    this.nestedCuLib = {};
    this.isAddedFromLibrary = false;
  }
  getNestedCUDetails() {
    this.changeunitFacade.nestedCuDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.changeUnitList[0].name = res.name;
        res.referencedChangeUnit = res.id;
        res.tfReferencedChangeUnit = res.tfId;
        delete res.id;
        delete res.dsdId;
        res.index = 1;
        this.nestedCuLib = res;
        this.isAddedFromLibrary = true;
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
