import { Component, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { TranslatorService } from '../translator/translator.service';

@Component({
  selector: 'app-debug',
  templateUrl: './debug-mode-confimation.component.html',
  styleUrls: ['./debug-mode-confimation.component.scss'],
})
export class DebugConfirmationComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DebugConfirmationComponent>, private translator: TranslatorService, private gsiFacadeService : GsiFacadeService) {
    this.detectLanguageChange();
  }
  ngUnsubscribe = new Subject();
  ngOnInit(): void {}
  labels: any;
  close(showDebug: string) {
    this.gsiFacadeService.sendCloseData(showDebug)
    // this.dialogRef.closeAll();
  }

  private detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
}
