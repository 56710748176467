<div>
  <form
    novalidate
    [formGroup]="group"
    [ngClass]="
      foundObject?.event == 'Option 7' ? 'date-parent-class-styles' : ''
    "
  >
    <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
      <label
        class="main-label nh-mb-0"
        pTooltip="{{ field.label }}"
        id="{{ field.attribute.name }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="
          !field?.hideLabels &&
          !field?.configuration?.hideLabel &&
          !field?.hideLabelMultiAttr
        "
        ><span class="main-label-inner"
          ><span class="main-label-text text-truncate"
            >{{ field.label }} <span class="main-label-colon">:</span></span
          >
          <span
            *ngIf="field?.isRequired && !field?.isInfo"
            class="mat-form-field-required-marker"
            >*</span
          ></span
        ></label
      >
      <p
        class="nh-ml-6 d-flex main-label-helperIcon"
        *ngIf="field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        <mat-icon
          class="material-icons-outlined helperText-infoIcon"
          [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
          matTooltip="{{ field?.configuration?.helpertext }}"
          matTooltipPosition="above"
          *ngIf="!field?.hideLabels"
          >info_outline</mat-icon
        >
      </p>
    </div>
    <p
      class="top-text"
      *ngIf="
        field?.configuration?.helperTextPosition == 'top' &&
        !field?.configuration?.showHelperTextIcon
      "
      [ngStyle]="{
        color: field?.configuration?.helperTextColor,
        'font-size': field?.configuration?.helperTextFont
      }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <div
      class="time-picker-wrapper"
      [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }"
    >
      <p-calendar
        *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])"
        class="{{ appliedClass }}"
        [placeholder]="labels?.Hhmmss || 'HH/MM/SS'"
        [disabled]="field?.readonly"
        [(ngModel)]="field.value"
        formControlName="{{ field.attribute.name }}"
        [required]="field?.isRequired && !field?.isInfo"
        (onBlur)="onBlur($event)"
        (onClickOutside)="doneClicked()"
        [hourFormat]="requiredFormat == '24-hr' ? 24 : 12"
        [timeOnly]="true"
        [showTime]="true"
        [showSeconds]="true"
        [showIcon]="true"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        [showButtonBar]="foundObject?.event == 'Option 3' ? true : false"
        [appendTo]="bodyAppend"
        panelStyleClass="time-picker-popup"
        #primecalendar
        icon="nh-icon nh-icon-time"
      >
        <ng-template pTemplate="footer">
          <div class="time-calendar-footer">
            <button type="button" class="p-calendar-footer-now" (click)="nowClicked(); primecalendar.hideOverlay()">
              {{ labels?.Now || 'Now' }}
            </button>
            <button
              type="button"
              class="p-calendar-footer-done"
              (click)="primecalendar.hideOverlay(); doneClicked()"
            ></button>
          </div>
        </ng-template>
      </p-calendar>
      <p-calendar
        *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']"
        class="datecalendar-input time-input"
        panelStyleClass="date-calender"
        [placeholder]="labels?.Hhmmss || 'HH:MM:SS'"
        [disabled]="field?.readonly"
        [(ngModel)]="field.value"
        formControlName="{{ field.attribute.name }}"
        [required]="field?.isRequired && !field?.isInfo"
        (onBlur)="onBlur($event)"
        [hourFormat]="requiredFormat == '24-hr' ? 24 : 12"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        [timeOnly]="true"
        [showIcon]="true"
        icon="nh-icon nh-icon-time"
        appendTo="body"
        [showSeconds]="true"
        [showTime]="true"
        [touchUI]="true"
        hourFormat="12"
        pTooltip="{{ field?.value | date: 'hh:mm:ss' }}"
        tooltipPosition="top"
        #primecalendar
      >
        <ng-template pTemplate="header">
          <div class="date-calender-header">
            <ul>
              <li class="calendarclose" (click)="primecalendar.hideOverlay()">
                <i class="bi bi-x-lg"></i>
              </li>
            </ul>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="date-calender-footer">
            <ul>
              <li (click)="nowClicked(); primecalendar.hideOverlay()">
                <i class="bi bi-check2"></i>
              </li>
              <li class="calendarclose" (click)="primecalendar.hideOverlay()">
                <i class="bi bi-x-lg"></i>
              </li>
            </ul>
          </div>
        </ng-template>
      </p-calendar>
      <svg
        *ngIf="!foundObject?.event || foundObject?.event == ''"
        class="actual-clock-icon"
        id="time"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <rect id="Rectangle_38765" data-name="Rectangle 38765" width="24" height="24" fill="#fff" opacity="0" />
        <g id="Group_98140" data-name="Group 98140">
          <path
            id="Path_101795"
            data-name="Path 101795"
            d="M14.023,16.946H7.988a.5.5,0,0,1-.5-.5V7.478a.5.5,0,0,1,1,0v8.469h5.535a.5.5,0,1,1,0,1Z"
            transform="translate(4.012 -3.761)"
            fill="#999"
          />
          <path
            id="Ellipse_14847"
            data-name="Ellipse 14847"
            d="M12,1A11,11,0,0,0,4.222,19.778,11,11,0,0,0,19.778,4.222,10.928,10.928,0,0,0,12,1m0-1A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            fill="#999"
          />
        </g>
      </svg>
      <!-- <em class="nh-icon nh-icon-time actual-clock-icon" *ngIf="!foundObject?.event || foundObject?.event == ''"></em> -->
    </div>
    <p
      class="bottom-text"
      *ngIf="
        field?.configuration?.helperTextPosition == 'bottom' &&
        !field?.configuration?.showHelperTextIcon
      "
      [ngStyle]="{
        color: field?.configuration?.helperTextColor,
        'font-size': field?.configuration?.helperTextFont
      }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <ng-container
      *ngFor="let validation of field.validations"
      ngProjectAs="mat-error"
    >
      <mat-error
        *ngIf="
          validation.type &&
          (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
          getErrorMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
      <mat-error
        [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
        *ngIf="
          validation.type &&
          validation.type !== 'ERROR' &&
          validation.type !== 'BLOCK_WARN' &&
          getInfoWarnMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
    </ng-container>
  </form>
</div>
