import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appTableDirective]',
})
export class TableDirectiveDirective implements OnChanges {
  private htmlElement: HTMLElement;
  @Input() record: any;
  @Input() rowFillProperties: boolean;
  @Input() rowTextProperties: boolean;
  @Input() rowIndex: number;
  @Input() columnIndex: number;
  @Input() evenRowProperties: any;
  @Input() oddRowProperties: any;
  @Input() evenHoveredIndex: number;
  @Input() isChip1: boolean = false;
  @Input() isColumnStyle: boolean = false;
  @Input() oddHoveredIndex: number;
  @Input() columnHoveredIndex: number;
  @Input() columnHovered: boolean;
  @Input() conditionArray: any;
  @Input() evenRowColor: string;
  @Input() oddRowColor: string;
  @Input() tenantfont: string;
  @Input() urlBindingAttributeColor: string = '';
  @Input() enableCompleteRecord: boolean = true;
  @Input() conditionMap: Map<number, any>;
  @Input() columnStyles: any;

  constructor(private render: Renderer2, private element: ElementRef) {
    this.htmlElement = element.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    /*istanbul ignore else */
    if (this.rowIndex % 2 == 0) {
      this.changeEvenRowProperties();
    } else if (this.rowIndex % 2 == 1) {
      this.changeOddRowProperties();
    }
    if (
      this.enableCompleteRecord &&
      this.conditionMap &&
      this.conditionMap.has(this.rowIndex)
    ) {
      this.updateFillColor(this.conditionMap.get(this.rowIndex)?.fillColor);
      this.updateTextColor(this.conditionMap.get(this.rowIndex)?.textColor);
    }
  }

  private changeEvenRowProperties() {
    if (this.rowFillProperties) {
      this.updateRowFillProperties(
        this.evenRowColor,
        this.evenRowProperties,
        this.evenHoveredIndex
      );
    }
    if (this.rowTextProperties) {
      this.updateRowTextProperties(
        this.evenRowProperties,
        this.evenHoveredIndex
      );
    }
  }

  private changeOddRowProperties() {
    if (this.rowFillProperties) {
      this.updateRowFillProperties(
        this.oddRowColor,
        this.oddRowProperties,
        this.oddHoveredIndex
      );
    }
    if (this.rowTextProperties) {
      this.updateRowTextProperties(this.oddRowProperties, this.oddHoveredIndex);
    }
  }

  private updateRowFillProperties(
    rowFillColor: string,
    rowProperties: any,
    hoveredIndex: number
  ) {
    if (
      hoveredIndex == this.rowIndex ||
      (this.columnHoveredIndex == this.columnIndex && this.columnHovered)
    ) {
      this.updateFillColor(rowProperties?.colorHover);
    } else {
      //! It should be in this order because condition has priority.
      this.updateFillColor(rowFillColor);
      this.assignCondition(this.record, this.conditionArray);
    }
  }

  private updateFillColor(fillColor: string) {
    if (!this.isChip1) this.htmlElement.style.backgroundColor = fillColor ? fillColor : '#ffffff';
  }

  private updateTextColor(textColor: string) {
    this.htmlElement.style.color = textColor;
  }

  private updateRowTextProperties(rowProperties: any, hoveredIndex: number) {
    if (
      hoveredIndex == this.rowIndex ||
      (this.columnHoveredIndex == this.columnIndex && this.columnHovered)
    ) {
      this.updateHoverStateProperties(rowProperties);
    } else {
      //! It should be in this order because condition has priority.
      this.updateStateProperties(rowProperties);
      this.assignCondition(this.record, this.conditionArray);
    }
  }

  private updateHoverStateProperties(rowProperties: any) {
    this.htmlElement.style.color = rowProperties?.fontHover;
  }

  private updateStateProperties(rowProperties: any) {
    this.htmlElement.style.color = this.urlBindingAttributeColor
      ? this.urlBindingAttributeColor
      : rowProperties?.fontColor;
    this.htmlElement.style.fontFamily = rowProperties?.text?.fontFamily?.font
      ? rowProperties?.text?.fontFamily?.font
      : this.tenantfont;
    this.htmlElement.style.textDecorationLine = rowProperties?.text?.underLine ? 'underline' : '';
    
      this.htmlElement.style.fontWeight = rowProperties?.text?.bold ? 'bold' : 'normal';
      this.htmlElement.style.fontStyle = rowProperties?.text?.italic ? 'italic' : 'normal';
      this.htmlElement.style.fontSize = rowProperties?.text?.fontSize + 'px';
    
    this.htmlElement.style.textAlign = rowProperties?.text?.alignment?.justify;
  }

  private assignCondition(record: any, conditionArray: any) {
    if (record && conditionArray && conditionArray.length > 0) {
      const { attributeName, value, type } = record;
      conditionArray.forEach((conditions: any, index: number) => {
        const {
          condition,
          conditionAttribute,
          fillColor,
          textColor,
          selectedConditonValue,
        } = conditions;
        if (
          attributeName === conditionAttribute &&
          this.checkConditon(value, condition, selectedConditonValue, type)
        ) {
          /*istanbul ignore else */

          if (this.rowFillProperties && !this.columnStyles?.isChip?.[0]) {
            this.htmlElement.style.backgroundColor = fillColor;
          } else if (this.rowTextProperties) {
            if (this.isChip1) {
              this.htmlElement.style.backgroundColor = fillColor;
            }
            this.htmlElement.style.color = textColor;
          }
        }
      });
    }
  }

  private checkConditon(
    value: string,
    condition: string,
    selectedConditonValue: string,
    type: string
  ): boolean {
    const updatedValue = type === 'number' ? parseInt(value) : value;
    switch (condition) {
      case '==': {
        return updatedValue == selectedConditonValue;
      }
      case '!=': {
        return updatedValue != selectedConditonValue;
      }
      case '<=': {
        return updatedValue <= selectedConditonValue;
      }
      case '>=': {
        return updatedValue >= selectedConditonValue;
      }
      case '<': {
        return updatedValue < selectedConditonValue;
      }
      case '>': {
        return updatedValue > selectedConditonValue;
      }
    }
  }
}
