import { ChangeUnit, Layer } from "@common-services";


export interface IOutputData {
  parent: number;
  selectedVal: number;
  altPathIndex: ChangeUnit;
}

export interface IRecommendations {
  name?: string;
  id?: string;
  gsi_name?: string;
  paragraph?: string;
  status?: string;
}

export interface gsiMyLibrarySuggestionList {
  gsi_name?: string;
  status?: string;
  name?: string;
  id: number;
}

export enum RecommendationTypes {
  Gsi = 'Gsi',
  ChangeUnit = 'ChangeUnit',
  Agent = 'Agent',
}

export interface IDesignRights {
  decisionRight: boolean;
  disableParentRoleAccess: boolean;
  executionRight: boolean;
  informationRight: boolean;
  rightHolderType: string;
  rightHolderName: string;
  rightHolderId: string;
}
export interface IConfigData {
  tag: string;
  index: number;
  entityIndex: number;
  sentenceTag: ISentenceTag;
  cu: ChangeUnit;
  module: string;
  layerType: string;
  currentComp: string;
  layers: Layer[];
}
export interface ISentenceTag {
  tag: string;
  token: string;
}

export interface ICuOptionSelectionData {
  type: string;
  index: ChangeUnit;
}
export interface IExtractionModel {
  extraction: ICU_list[];
  sentence_tags: ITag[];
  success: IextractionSuccess;
}

export interface ICU_list {
  agent: any;
  entities: IEntity[];
  name: string;
}

export interface IEntity {
  name: string;
  attributes_list: IAttribute[];
}
export interface IAttribute {
  name: string;
}

export interface ITag {
  token: string;
  tag: string;
}

export interface IextractionSuccess {
  error_code: string;
  sentence: string;
  status: string;
}
