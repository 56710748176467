import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'betImageFileName',
  pure: true,
})
export class BetImageFileNamePipe implements PipeTransform {
  transform(value: string): string {
    if (value === '') {
      return 'Asset Not uploaded';
    } else {
      let valArr: string[] = value?.split('/');
      let betImageName = valArr?.[valArr?.length - 1];
      let mimeType = betImageName?.split('.')?.pop();
      betImageName = betImageName?.split('.')[0];
      betImageName = betImageName?.substring(0, betImageName.length - 36);
      betImageName = (decodeURI(betImageName) + '.' + mimeType)?.split('+').join(' ');
      return betImageName;
    }
  }
}
