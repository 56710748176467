import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EqlEndpointService extends CduiBaseService {

  public isEqlInputOutputEnable = new Subject();
  isEqlInputOutputEnable$ = this.isEqlInputOutputEnable.asObservable();

  constructor(private http: HttpClient, private injector: Injector) {
    super(injector);
  }

  getEqlFilter() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getEqlFilter);
  }

  getEqlOperator() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getEqlOperator);
  }

  getEqlPredicate() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getEqlPredicate);
  }

  geteqlFunction() {
    /* istanbul ignore next */
    return this.http.get(this.api?.geteqlFunction);
  }

  getEqlDateConstValue() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getEqlDateConstantValue);
  }
  updateEqlInpOut(event: any){
    this.isEqlInputOutputEnable.next(event);
  }
}
