import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  TemplateRef,
  Inject,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  entityMapper,
  Librarytypes,
  NodeGsiFacadeService,
  OtherSolutionDialogComponent,
  TranslatorService,
  updateEntityData,
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
``;
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';

export interface DialogData {
  gsi: Object;
  currentCU: Object;
  mode: string;
  txnData: Object;
  isReadOnly: Boolean;
}

@Component({
  selector: 'app-node-bet-edit-popup',
  templateUrl: './node-bet-edit-popup.component.html',
  styleUrls: ['./node-bet-edit-popup.component.scss'],
})
export class NodeBetEditPopupComponent implements OnInit, OnDestroy {
  betType: string;
  betData: any;
  gsiData: any;
  node: any;
  expandNode: any;
  private ngUnsubscribe = new Subject();
  labels: any;
  changeUnit: any;
  isPublished: any;
  cloneInfo: any;
  cloneName: any;
  constructor(
    public Dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData,
    private gsiFacadeService: NodeGsiFacadeService,
    private translator: TranslatorService,
    public dialogRef: MatDialogRef<NodeBetEditPopupComponent>,
    private entityFacadeService: NodeEntityFacadeService,
    private nodeChangeUnitFacadeService: NodeChangeUnitFacadeService
  ) {
    this.detectUpgradedCU();
    this.detectUpgradedEntity();
    this.detectLanguageChange();
    this.detectCloneResponse();
  }
  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }
  ngOnInit(): void {
    this.betData = this.dialog;
    this.initDialog();
  }

  initDialog() {
    this.betType = this.betData.betType;
    this.node = this.betData.node;
    this.gsiData = this.betData.gsiData;
  }
  upgrade(): void {
    /* istanbul ignore next */
    //const dsdId = this.node?.data?.tfReferencedChangeUnit ? this.node.data?.tfReferencedChangeUnit : this.node.data?.referencedChangeUnit;
    let dsdId = this.node?.data?.referencedChangeUnit
      ? this.node?.data?.referencedChangeUnit
      : this.node?.data?.dsdRefId;
    let index = undefined;
    if (this.betType === 'change-unit') {
      index = this.node?.data?.index
        ? this.node?.data?.index
        : this.node?.data?.currentcu?.data?.index;
      if (!index) {
        index = this.node?.data?.cu?.data?.index;
      }
    }
    /* istanbul ignore next */
    if (this.node?.label == 'AT' && this.betType == 'entity') {
      dsdId = this.node?.data?.entity
        ? this.node?.data?.entity?.id
        : this.node?.data?.entityData?.id;
    } else if (this.node.label == 'AT' && this.betType == 'change-unit') {
      dsdId = this.node?.data?.currentcu?.data?.referencedChangeUnit
        ? this.node?.data?.currentcu?.data?.referencedChangeUnit
        : this.node?.data?.currentcu?.data?.dsdRefId;
    } else if (this.node.label == 'EN' && this.betType == 'change-unit') {
      dsdId = this.node?.data?.cu?.data?.referencedChangeUnit
        ? this.node?.data?.cu?.data?.referencedChangeUnit
        : this.node?.data?.cu?.data?.dsdRefId;
    } else if (this.node.label == 'EN' && this.betType == 'entity') {
      dsdId = this.node?.data?.entityData?.id
        ? this.node?.data?.entityData?.id
        : this.node?.data?.entityData?.dsdId;
    }
    this.gsiFacadeService.editPublished(dsdId, this.betType, index);
  }

  clone(templateRef: TemplateRef<any>): void {
    this.Dialog.open(templateRef, {
      width: '500px',
      disableClose: true,
    });
    let changeUnit = this.betData?.node?.data;
    /* istanbul ignore else */
    if (this.betType === 'change-unit') {
      /* istanbul ignore next */
      if (changeUnit?.status === 'PUBLISHED') {
        this.isPublished = true;
      }
      this.cloneInfo = {
        data: changeUnit,
        type: Librarytypes.ChangeUnit,
        isPublished: this.isPublished,
      };
    }
  }

  /* istanbul ignore next */
  cloneData() {
    const data = { name: this.cloneName };
    this.entityFacadeService.getCloneDetails(
      this.cloneInfo.data?.dsdRefId,
      this.cloneInfo?.isPublished,
      'change-unit',
      data
    );
    this.Dialog.closeAll();
  }

  cancelClone() {
    this.Dialog.closeAll();
  }

  detectCloneResponse() {
    this.entityFacadeService.cloneResponse$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.cloneInfo = null;
        }
      });
  }

  detectUpgradedCU() {
    this.gsiFacadeService.draftCu$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          //res.index = this.node.id;
          const i = this.gsiData?.solutionLogic.findIndex(
            (x: any) => x.masterId == res.masterId && x.index === res.index
          );
          /* istanbul ignore next */
          if (i != -1) {
            res.index = this.gsiData.solutionLogic[i].index;
            /* istanbul ignore next */
            res.referencedChangeUnit = res.referencedChangeUnit
              ? res.referencedChangeUnit
              : res?.id;
            /* istanbul ignore next */
            res.tfReferencedChangeUnit = res.tfReferencedChangeUnit
              ? res.tfReferencedChangeUnit
              : res?.tfId;
            delete res.id;
            delete res.tfId;
            res.ontology = this.gsiData?.solutionLogic[i]?.ontology;
            res.uiProperties = this.gsiData?.solutionLogic[i]?.uiProperties;
            res.nextTriggerSet = this.gsiData?.solutionLogic[i].nextTriggerSet;
            res.dcd = this.gsiData?.solutionLogic[i]?.dcd;
            res.constraint = this.gsiData?.solutionLogic[i]?.constraint;
            res.constraintName = this.gsiData?.solutionLogic[i]?.constraintName;
            let tCuCP = this.gsiData?.solutionLogic[i]
              ?.tCUConditionalPotentiality;
            res.tCUConditionalPotentiality =
              tCuCP?.length == 0
                ? this.gsiData?.solutionLogic[i]
                    ?.tCUConditionalPotentialityNames
                : tCuCP;
            res.txnDataSaveMode = this.gsiData?.solutionLogic[
              i
            ]?.txnDataSaveMode;
            res.disableSTEs = this.gsiData?.solutionLogic[i]?.disableSTEs,
            res.tCUConditionalPotentialityNames = this.gsiData?.solutionLogic[
              i
            ]?.tCUConditionalPotentialityNames;
            res.mindCUList = this.gsiData?.solutionLogic[i]?.mindCUList;
            res.eventCUList = this.gsiData?.solutionLogic[i]?.eventCUList;
            res.condition = this.gsiData.solutionLogic[i]?.condition;
            res.conditionName = this.gsiData.solutionLogic[i]?.conditionName;
            res.sentenceTags = this.gsiData.solutionLogic[i]?.sentenceTags;
            res.slotItemProperties = this.gsiData.solutionLogic[
              i
            ]?.slotItemProperties;
            res.specialFeatureProperties = this.gsiData.solutionLogic[
              i
            ]?.specialFeatureProperties;
            res.triggerCUDialogList = this.gsiData.solutionLogic[i]?.triggerCUDialogList;
            res.transactionEndpoint = this.gsiData.solutionLogic[i]?.transactionEndpoint;
            res.measures = this.gsiData.solutionLogic[i]?.measures;
            this.gsiData.solutionLogic[i] = res;
            this.expandNode = this.node;
            if (this.node.label == 'AT') {
              this.expandNode.data.currentcu.data = {
                ...res,
                cuType: this.node.data?.currentcu?.data?.cuType,
                dsdRefId: res?.referencedChangeUnit,
                layers: this.node.data?.currentcu?.data?.layers,
                cuLayers: this.node.data?.currentcu?.data?.layers,
                name: res?.name,
                color: '#ffc400',
                nextTriggerSet: this.node.data?.currentcu?.data?.nextTriggerSet,
                dimension: this.node.data.dimension,
                index: this.gsiData.solutionLogic[i].index,
              };
            } else if (this.node.label == 'EN') {
              this.expandNode.data.cu.data = {
                ...res,
                cuType: this.node.data?.cu?.data?.cuType,
                dsdRefId: res?.referencedChangeUnit,
                layers: this.node.data?.cu?.data?.layers,
                cuLayers: this.node.data?.cu?.data?.layers,
                name: res?.name,
                color: '#ffc400',
                nextTriggerSet: this.node.data?.cu?.data?.nextTriggerSet,
                dimension: this.node.data.dimension,
                index: this.gsiData.solutionLogic[i].index,
              };
            } else {
              this.expandNode.data = {
                ...res,
                cuType: this.node.data?.cuType,
                dsdRefId: res?.referencedChangeUnit,
                layers: this.node.data?.layers,
                cuLayers: this.node.data?.layers,
                name: res?.name,
                color: '#ffc400',
                nextTriggerSet: this.node.data?.nextTriggerSet,
                dimension: this.node.data.dimension,
                index: this.gsiData.solutionLogic[i].index,
              };
            }
            let dataToSend = {
              expandNode: this.expandNode,
              gsiData: this.gsiData,
            };
            this.dialogRef.close(dataToSend);
          }
        }
      });
  }

  attributeUpgrade(res: any) {
    let i = this.gsiData?.solutionLogic.findIndex(
      (x: any) => x.masterId == this.node?.data?.currentcu?.data?.masterId
    );
    if (i != -1) {
      res.result = entityMapper(res.result);
      this.gsiData?.solutionLogic[i]?.layers.forEach((layer: any) => {
        if (layer.type == this.node?.data?.entity?.layerType) {
          let entityIndex = layer.participatingItems?.findIndex(
            (_ent: any) => _ent?.masterId == res?.result?.masterId
          );
          layer.participatingItems[entityIndex] = res?.result;
          this.changeUnit = this.gsiData.solutionLogic[i];
          this.expandNode = this.node;
          /* istanbul ignore next */
          this.expandNode.data = {
            ...this.node?.data,
            entity: res?.result,
          };
        }
      });
      /* istanbul ignore next */
      this.changeUnit.tfId = this.changeUnit?.tfReferencedChangeUnit;
      this.changeUnit.dsdId = this.changeUnit?.referencedChangeUnit;
      this.changeUnit.id = this.changeUnit?.referencedChangeUnit;
    }

    let dataToSend = {
      expandNode: this.expandNode,
      gsiData: this.gsiData,
      changeUnit: this.changeUnit,
      entity: res?.result,
    };
    /* istanbul ignore next */
    this.dialogRef.close(dataToSend);
  }

  /* istanbul ignore next */
  detectUpgradedEntity() {
    this.gsiFacadeService.draftEntity$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res && this.node?.label == 'EN') {
          /* istanbul ignore next */
          const i = this.gsiData?.solutionLogic.findIndex(
            (x: any) => x.masterId == this.node?.data?.cu?.data?.masterId
          );
          /* istanbul ignore next */
          if (i != -1) {
            res.result = entityMapper(res.result);
            this.gsiData?.solutionLogic[i]?.layers.forEach((layer: any) => {
              if (layer.type == this.node?.data?.layerType) {
                let entityIndex = layer.participatingItems?.findIndex(
                  (_ent: any) => _ent?.masterId == res?.result?.masterId
                );
                res.result.nslAttributes = updateEntityData(
                  this.node?.data?.cu?.data,
                  layer?.type,
                  res?.result
                );
                if (this.node?.data?.entityData?.configuration) {
                  res.result.configuration = this.node?.data?.entityData?.configuration;
                }
                layer.participatingItems[entityIndex] = res?.result;
                this.node.data.cu = this.gsiData.solutionLogic[i];
                this.changeUnit = this.gsiData.solutionLogic[i];
                this.expandNode = this.node;
                /* istanbul ignore next */
                this.expandNode.data = {
                  entityData: res?.result,
                  cu: this.node?.data?.cu,
                  dsdId: res?.result?.id,
                  color: 'red',
                  name: res?.result.name,
                  changeUnitName: this.node?.data?.cu?.data?.name,
                  attributeList: res?.result.nslAttributes,
                  dimension: this.node?.dimension,
                  layerType: res?.result.layerType,
                  entityIndex: this.node?.data?.entityIndex,
                  version: res?.result?.version,
                  status: res?.result?.status,
                };
              }
            });
            /* istanbul ignore next */
            this.changeUnit.tfId = this.changeUnit?.tfReferencedChangeUnit;
            this.changeUnit.dsdId = this.changeUnit?.referencedChangeUnit;
            this.changeUnit.id = this.changeUnit?.referencedChangeUnit;
          }
          /* istanbul ignore next */
          let dataToSend = {
            expandNode: this.expandNode,
            gsiData: this.gsiData,
            changeUnit: this.changeUnit,
            entity: res?.result,
          };
          /* istanbul ignore next */
          this.dialogRef.close(dataToSend);
        } else if (this.node?.label == 'AT' && res) {
          this.attributeUpgrade(res);
        }
      });
  }
  showList() {
    /* istanbul ignore next */
    this.gsiFacadeService.setCloseActionMenu(true);
    if (this.node?.label == 'CU') {
      let data = {
        versionData: this.betData?.node?.versionData,
        type: 'ChangeUnit',
        cuData: this.betData?.node?.data,
      };
      const dialogotherRef = this.Dialog.open(OtherSolutionDialogComponent, {
        disableClose: false,
        data: data,
        width: '600px',
        height: '600px',
      });
    } else {
      this.nodeChangeUnitFacadeService
        ?.getRelatedBetsData(
          this.node?.data?.entityData?.id
            ? this.node?.data?.entityData?.id
            : this.node?.data?.entity?.id,
          'GeneralEntity'
        )
        .subscribe((res: any) => {
          /* istanbul ignore next */
          if (res) {
            let data = {
              versionData: this.betData?.node?.versionData
                ? this.betData?.node?.versionData
                : res?.result,
              type: 'ChangeUnit',
              cuData: this.betData.node.data,
            };
            const dialogotherRef = this.Dialog.open(
              OtherSolutionDialogComponent,
              {
                disableClose: false,
                data: data,
                width: '600px',
                height: '600px',
              }
            );
          }
        });
    }
  }
  closeMenu() {
    this.gsiFacadeService.setCloseActionMenu(true);
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
