import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'LanguageOperatorsPipe',
  pure: true,
})
export class LanguageOperatorsPipe implements PipeTransform {
  /* istanbul ignore next */
  transform(value: any, labels: any): any {
    let modifiedValue = value?.split(' ')?.join('_');
    return labels?.[modifiedValue] ? labels?.[modifiedValue] : value;
  }
}
