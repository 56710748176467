import { Component,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@common-services';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';

@Component({
  selector: 'app-attribute-templates',
  templateUrl: './attribute-templates.component.html',
  styleUrls: ['./attribute-templates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttributeTemplatesComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService
  ) {
    super(    
      alertService
    );
  }
}
