<mat-expansion-panel id="mat_panel_record_additional-info" *ngIf="canvas!='hybrid'">
  <mat-expansion-panel-header id="mat_panel_header_record_additional-info">
    <mat-panel-title id="mat_panel_titel_record_additional-info">
      {{ labels?.Record_Additional_Information }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="cal-height" id="mat_panel_toggle_record_additional-info">
    <span id="disable_record_additional-info">{{ labels?.Disable }}</span>
    <mat-slide-toggle [(ngModel)]="disableSTEs" (change)="onhideRecordAdditionalInfoChange()"></mat-slide-toggle>
    <span id="enable_record_additional-info">{{ labels?.Enable }}</span>
  </div>
</mat-expansion-panel>

<div *ngIf="canvas=='hybrid'">
  <div class="config-ttle">
    <h4>{{ labels?.Record_Additional_Information || 'Record Additional Information' }}</h4>
  </div>
  <div class="custom-radio-btns">
    <mat-radio-group [(ngModel)]="disableSTEs" (change)="onhideRecordAdditionalInfoChange()">
      <mat-radio-button id="enabled" name="enable" [value]="true">{{ labels?.Enable || 'Enable'}}</mat-radio-button>
      <mat-radio-button id="disabled" name="disable" [value]="false">{{ labels?.Disable || 'Disable'}}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>