import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatorService,getIndexPadding } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface listRundownData {
  listRundownErrorInfo: any;
  layers: any;
  gsiName: string;
}

@Component({
  selector: 'app-failed-records-handling',
  templateUrl: './failed-records-handling.component.html',
  styleUrls: ['./failed-records-handling.component.scss'],
})
export class FailedRecordsHandlingComponent implements OnInit {
  labels: any;
  private ngUnsubscribe = new Subject();
  listRundownErrorInfo: any;
  layers: any = [];
  selectedLayer: any = {};
  selectedEntityIndex: number = -1;
  gsiName: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: listRundownData,
    private translator: TranslatorService,
    public dialogRef: MatDialogRef<FailedRecordsHandlingComponent>
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.listRundownErrorInfo = this.dialog?.listRundownErrorInfo;
    this.layers = this.dialog?.layers;
    this.gsiName = this.dialog?.gsiName;
    if (this.listRundownErrorInfo?.failedRecordsEntityContextualName) {
      let layer: any;
      if (
        this.listRundownErrorInfo?.failedRecordsEntityContextualName &&
        this.listRundownErrorInfo?.failedRecordsEntityContextualName != ''
      ) {
        let layerType = this.listRundownErrorInfo?.failedRecordsEntityContextualName?.split('.')?.[1]?.split('.')?.[0];
        if (layerType) this.selectedLayer = this.layers?.find((lay: any) => lay?.type == layerType);
        this.selectedEntityIndex =
          Number(this.listRundownErrorInfo?.failedRecordsEntityContextualName?.split('slot:')?.[1]?.split('.')[0]) - 1;
      } else this.selectedEntityIndex = -1;
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  generateContextualId() {
    if (this.selectedEntityIndex != -1) {
      let contextualId = this.gsiName + '.';
      contextualId +=
        this.selectedLayer?.type +
        '.' +
        'slot' +
        ':' +
        (this.selectedEntityIndex + 1) +
        '.' +
        this.selectedLayer?.participatingItems[this.selectedEntityIndex]?.name;
      this.listRundownErrorInfo.failedRecordsEntityContextualID = this.listRundownErrorInfo.failedRecordsEntityContextualName = contextualId;
    } else
      this.listRundownErrorInfo.failedRecordsEntityContextualID = this.listRundownErrorInfo.failedRecordsEntityContextualName =
        '';
    this.close();
  }

  deleteSelectedEntity() {
    this.selectedLayer = {};
    this.selectedEntityIndex = -1;
  }

  close() {
    this.dialogRef.close(this.listRundownErrorInfo);
  }
}
