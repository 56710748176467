<ng-template #attributeItem let-data="data">
  <li
    entityCardColor
    [isAttributeLevel]="true"
    [isEntityLevel]="false"
    [attributeColors]="[(cardDetails?.dropExpandColors)?.[data?.position]]"
    [conditionArray]="cardDetails?.conditionArrayExpand"
    [_otherStyle]="[(cardDetails?.droppedStylesExpand)?.[data?.position]]"
    [class]="data?.valueClass + ' page-label'"
    *ngIf="data?.showLabel && expandNameValueMap?.has(expandTemplateTypeData?.[data?.position + 1])"
  >
    {{ expandTemplateTypeData?.[data?.position + 1] }}
  </li>
  <ng-container *ngIf="!expandTemplateTypeData?.[data?.position + 1] in relatedRecords">
    <li
      [class]="data?.valueClass"
      entityCardColor
      [attrHoveredColors]="[(cardDetails?.expandHoveredColors)?.[data?.position + 1]]"
      [isAttributeLevel]="true"
      [isEntityLevel]="false"
      [attributeColors]="[(cardDetails?.dropExpandColors)?.[data?.position]]"
      [conditionArray]="cardDetails?.conditionArrayExpand"
      [_otherStyle]="[(cardDetails?.droppedStylesExpand)?.[data?.position]]"
      *ngIf="expandNameValueMap?.has(expandTemplateTypeData?.[data?.position + 1])"
    >
      {{ expandNameValueMap?.get(expandTemplateTypeData?.[data?.position + 1]) }}
    </li>
  </ng-container>
  <ng-container *ngIf="expandTemplateTypeData?.[data?.position + 1] in relatedRecords">
    <ul class="d-flex flex-wrap nh-gap-16">
      <li
        *ngFor="let record of relatedRecords?.[expandTemplateTypeData?.[data?.position + 1]]"
        [class]="data?.valueClass + ' cursor-pointer'"
        entityCardColor
        [attrHoveredColors]="[(cardDetails?.expandHoveredColors)?.[data?.position + 1]]"
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [attributeColors]="[(cardDetails?.dropExpandColors)?.[data?.position]]"
        [conditionArray]="cardDetails?.conditionArrayExpand"
        [_otherStyle]="[(cardDetails?.droppedStylesExpand)?.[data?.position]]"
        (click)="goToRelatedRecord(record?.selectableRecordIds)"
        [ngClass]="{ active: record?.selected, 'record-disabled': record?.disabled }"
      >
        {{ record?.value }}
      </li>
    </ul>
  </ng-container>
</ng-template>
<ng-template #attributeImage let-data="data">
  <ng-container
    *ngIf="!expandTemplateTypeData?.[data?.position + 1] in relatedRecords && expandNameValueMap?.has(expandTemplateTypeData?.[data?.position + 1])"
  >
    <img
      [class]="data?.valueClass + 'cursor-pointer'"
      [src]="expandNameValueMap?.get(expandTemplateTypeData?.[data?.position + 1]) | CDNUrlRewritePipe"
      alt="general-entity"
      (click)="imagePlaceHolderPosition = data?.position + 1"
    />
  </ng-container>
  <ng-container *ngIf="expandTemplateTypeData?.[data?.position + 1] in relatedRecords">
    <img
      [class]="data?.valueClass"
      *ngFor="let record of relatedRecords[expandTemplateTypeData?.[data?.position + 1]]"
      [src]="record?.value | CDNUrlRewritePipe"
      (click)="goToRelatedRecord(record?.recordIds)"
      alt="general-entity"
    />
  </ng-container>
</ng-template>
<div class="d-flex justify-content-end">
  <mat-icon (click)="emitClosePage.emit(true)">close</mat-icon>
</div>
<div class="col-12" *ngIf="cardDetails?.templateType == '1'">
  <div
    class="ecommerce-expandpreview-card-13 ecommerce-expandpreview"
    entityCardColor
    [cardShadow]="cardDetails?.cardBoxShadow"
    [cardBorderColor]="cardDetails?.cardBorderColor"
    [cardBorderRadius]="cardDetails?.cardBorderRadius"
    [cardBorderSize]="cardDetails?.cardBorderSize"
    [ngStyle]="{ 'background-color': cardDetails?.expandBackground }"
    [cardColor]="cardDetails?.expandBackground"
    [cardHoveredColor]="cardDetails?.expandCardHoveredColor"
    *ngIf="cardDetails?.templateType == '1'"
  >
    <div class="expandpreviewcard row">
      <div class="col-6">
        <div class="col-12 title">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'e-card-title', position: 0 } }"
            ></ng-template>
          </ul>
        </div>
        <div class="col-12 leftSide">
          <div class="col-6 image">
            <ul>
              <li>
                <figure class="card-logo">
                  <ng-template
                    [ngTemplateOutlet]="attributeImage"
                    [ngTemplateOutletContext]="{
                      data: { showLabel: true, valueClass: 'e-commerce-imgsize', position: 1 }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="col-6 image">
            <ul>
              <li>
                <figure class="card-logo">
                  <ng-template
                    [ngTemplateOutlet]="attributeImage"
                    [ngTemplateOutletContext]="{
                      data: { showLabel: true, valueClass: 'e-commerce-imgsize', position: 2 }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 leftSide">
          <div class="col-3 image">
            <ul>
              <li>
                <figure class="small-card-image">
                  <ng-template
                    [ngTemplateOutlet]="attributeImage"
                    [ngTemplateOutletContext]="{
                      data: { showLabel: true, valueClass: 'e-commerce-imgsize', position: 3 }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="col-3 image">
            <ul>
              <li>
                <figure class="small-card-image">
                  <ng-template
                    [ngTemplateOutlet]="attributeImage"
                    [ngTemplateOutletContext]="{
                      data: { showLabel: true, valueClass: 'e-commerce-imgsize', position: 4 }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="col-3 image">
            <ul>
              <li>
                <figure class="small-card-image">
                  <ng-template
                    [ngTemplateOutlet]="attributeImage"
                    [ngTemplateOutletContext]="{
                      data: { showLabel: true, valueClass: 'e-commerce-imgsize', position: 5 }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="col-3 image">
            <ul>
              <li>
                <figure class="small-card-image">
                  <ng-template
                    [ngTemplateOutlet]="attributeImage"
                    [ngTemplateOutletContext]="{
                      data: { showLabel: true, valueClass: 'e-commerce-imgsize', position: 6 }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-6 card-data">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'e-card-title', position: 7 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-subtitle', position: 8 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-rating', position: 9 } }"
          ></ng-template>
          <div class="hr"></div>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-price', position: 10 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-discount', position: 11 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-small-text', position: 12 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-size', position: 13 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'card-size-type', position: 14 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-delivery', position: 15 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-textbox', position: 16 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-information', position: 17 } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{
              data: { showLabel: true, valueClass: 'card-product-description', position: 18 }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'card-product-summary', position: 19 } }"
          ></ng-template>
        </ul>
        <div class="cta-btns">
          <div class="btn-cta">
            <button
              class="btn btn-preview cta-btn btn-tooltip text-truncate"
              (click)="executeCard(gsiExpandData?.['action-1'])"
              [ngStyle]="{ background: (cardDetails?.expandBtnColors)[0] }"
              pTooltip="{{ gsiExpandData?.['action-1']?.name }}"
              tooltipPosition="top"
              *ngIf="gsiExpandData?.['action-1']?.name"
            >
              {{ gsiExpandData?.['action-1']?.name }}
            </button>
          </div>
          <div class="btn-cta">
            <button
              class="btn btn-preview cta-btn1 cta-btn btn-tooltip text-truncate"
              (click)="executeCard(gsiExpandData?.['action-2'])"
              [ngStyle]="{ background: (cardDetails?.expandBtnColors)[1] }"
              pTooltip="{{ gsiExpandData?.['action-2']?.name }}"
              tooltipPosition="top"
              *ngIf="gsiExpandData?.['action-2']?.name"
            >
              {{ gsiExpandData?.['action-2']?.name }}
            </button>
          </div>
          <div class="btn-cta">
            <button
              class="btn btn-preview cta-btn1 cta-btn btn-tooltip text-truncate"
              (click)="executeCard(gsiExpandData?.['action-3'])"
              [ngStyle]="{ background: (cardDetails?.expandBtnColors)[2] }"
              pTooltip="{{ gsiExpandData?.['action-3']?.name }}"
              tooltipPosition="top"
              *ngIf="gsiExpandData?.['action-3']?.name"
            >
              {{ gsiExpandData?.['action-3']?.name }}
            </button>
          </div>
        </div>
        <div class="border-top border-bottom nh-pt-40 nh-pb-20 nh-mt-30">
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-30', position: 20 } }"
          ></ng-template>
          <div class="row">
            <div class="col-6">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 21 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 22 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 23 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 24 } }"
              ></ng-template>
            </div>
            <div class="col-6">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 25 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 26 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 27 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 28 } }"
              >
              </ng-template>
            </div>
          </div>
        </div>
        <div class="border-bottom nh-pb-20 nh-mt-30">
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-30', position: 29 } }"
          ></ng-template>
          <div class="row">
            <div class="col-6">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 30 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 31 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 32 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 33 } }"
              >
              </ng-template>
            </div>
            <div class="col-6">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 34 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 35 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 36 } }"
              >
              </ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'nh-mb-16', position: 37 } }"
              >
              </ng-template>
            </div>
          </div>
        </div>
        <app-comments-and-ratings
          *ngIf="allowComments"
          [isView]="true"
          [_generalEntityId]="generalEntityId"
          [_recordId]="recordId"
        ></app-comments-and-ratings>
      </div>
    </div>
  </div>
</div>
<div class="col-12" *ngIf="cardDetails?.templateType == '2'">
  <div
    class="product-expand-card-1"
    entityCardColor
    [cardShadow]="cardDetails?.cardBoxShadow"
    [cardBorderColor]="cardDetails?.cardBorderColor"
    [cardBorderRadius]="cardDetails?.cardBorderRadius"
    [cardBorderSize]="cardDetails?.cardBorderSize"
    [ngStyle]="{ 'background-color': cardDetails?.expandBackground }"
    [cardColor]="cardDetails?.expandBackground"
    [cardHoveredColor]="cardDetails?.expandCardHoveredColor"
    *ngIf="cardDetails?.templateType == '2'"
  >
    <div class="prod-exp-container">
      <ul class="prod-exp-header">
        <ng-template
          [ngTemplateOutlet]="attributeItem"
          [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'prod-exp-title', position: 0 } }"
        ></ng-template>
        <button (click)="copyLinkToClipboard()" class="share-btn cursor-pointer">
          <i class="bi bi-reply-fill"></i> Share
        </button>
      </ul>
      <div class="prod-exp-main">
        <ul class="image-section">
          <li class="main-lg-img-item">
            <figure>
              <img
                class="lg-img"
                [src]="expandNameValueMap?.get(expandTemplateTypeData?.[imagePlaceHolderPosition]) | CDNUrlRewritePipe"
                alt="general-entity"
              />
            </figure>
          </li>
          <div class="secondary-sm-img-section">
            <li class="secondary-sm-img-item">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeImage"
                  [ngTemplateOutletContext]="{
                    data: { showLabel: false, valueClass: 'sm-img', position: 2 }
                  }"
                ></ng-template>
              </figure>
            </li>
            <li class="secondary-sm-img-item">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeImage"
                  [ngTemplateOutletContext]="{
                    data: { showLabel: false, valueClass: 'sm-img', position: 3 }
                  }"
                ></ng-template>
              </figure>
            </li>
            <li class="secondary-sm-img-item">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeImage"
                  [ngTemplateOutletContext]="{
                    data: { showLabel: false, valueClass: 'sm-img', position: 4 }
                  }"
                ></ng-template>
              </figure>
            </li>
          </div>
        </ul>
        <ul class="text-section">
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'item-name', position: 5 } }"
          ></ng-template>
          <div class="rating-section">
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'rating-value', position: 6 } }"
            ></ng-template>
            <mat-icon>star</mat-icon>
          </div>
          <div class="price-section">
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'final-price', position: 7 } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'initial-price', position: 8 } }"
            ></ng-template>
          </div>
          <div class="cta-btns">
            <div class="btn-cta">
              <button
                class="btn btn-preview cta-btn btn-tooltip text-truncate"
                (click)="executeCard(gsiExpandData?.['action-1'])"
                [ngStyle]="{ background: (cardDetails?.expandBtnColors)[0] }"
                pTooltip="{{ gsiExpandData?.['action-1']?.name }}"
                tooltipPosition="top"
                *ngIf="gsiExpandData?.['action-1']?.name"
              >
                {{ gsiExpandData?.['action-1']?.name }}
              </button>
            </div>
            <div class="btn-cta btn2">
              <button
                class="btn btn-preview cta-btn1 cta-btn btn-tooltip text-truncate"
                (click)="executeCard(gsiExpandData?.['action-2'])"
                [ngStyle]="{ background: (cardDetails?.expandBtnColors)[1] }"
                pTooltip="{{ gsiExpandData?.['action-2']?.name }}"
                tooltipPosition="top"
                *ngIf="gsiExpandData?.['action-2']?.name"
              >
                {{ gsiExpandData?.['action-2']?.name }}
              </button>
            </div>
          </div>
          <div class="offer-section">
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'offer-text', position: 9 } }"
            ></ng-template>
          </div>
          <ng-template
            [ngTemplateOutlet]="attributeItem"
            [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'delivery-text', position: 10 } }"
          ></ng-template>
          <div class="customisation-section">
            <div class="color-section">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'color-value', position: 11 } }"
              ></ng-template>
            </div>
            <div class="storage-section">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'storage-value', position: 12 } }"
              ></ng-template>
            </div>
          </div>
          <div class="highlights-section">
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: true, valueClass: 'highlight-value', position: 13 } }"
            ></ng-template>
          </div>
          <div class="specifications-section">
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-heading', position: 14 } }"
            ></ng-template>
            <div class="spec-content">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 15 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 16 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 17 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 18 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 19 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 20 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 21 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 22 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 23 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 24 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 25 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 26 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 27 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 28 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 29 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 30 } }"
              ></ng-template>
            </div>
          </div>
          <div class="feature-section">
            <ng-template
              [ngTemplateOutlet]="attributeItem"
              [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-heading', position: 31 } }"
            ></ng-template>
            <div class="spec-content">
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 32 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 33 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 34 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 35 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 36 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 37 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 38 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 39 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 40 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 41 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 42 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 43 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 44 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 45 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 46 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 47 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 48 } }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeItem"
                [ngTemplateOutletContext]="{ data: { showLabel: false, valueClass: 'spec-value', position: 49 } }"
              ></ng-template>
            </div>
          </div>
          <app-comments-and-ratings
            *ngIf="allowComments"
            [isView]="true"
            [_generalEntityId]="generalEntityId"
            [_recordId]="recordId"
          ></app-comments-and-ratings>
        </ul>
      </div>
    </div>
  </div>
</div>

<div>
  <div
    class="dynamic-template-placeholder"
  ></div>
  <app-comments-and-ratings
            *ngIf="allowComments && cardDetails?.templateType?.includes('dynamic')"
            [isView]="true"
            [_generalEntityId]="generalEntityId"
            [_recordId]="recordId"
          ></app-comments-and-ratings>
</div>
<app-loader></app-loader>
<app-toaster></app-toaster>
