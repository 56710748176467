import { Component } from '@angular/core';
import { AlertService } from '@common-services';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';

@Component({
  selector: 'app-features-templates',
  templateUrl: './features-templates.component.html',
  styleUrls: ['./features-templates.component.scss'],
})
export class FeaturesTemplatesComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService
  ) {
    super(    
      alertService
    );
  }
}

