import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-list',
  template: `
    <form class="demo-full-width">
    <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
          <label
            class="main-label nh-mb-0"
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            ></label
          >
          <p
            class="nh-ml-6 d-flex"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"

         >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            matSuffix
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            class="d-flex align-items-center justify-content-center"

          >
            <span class="sub-cu" (click)="openSubPopUp()">
              <img class="d-block" src="../assets/img/transaction/trigger-att.svg" />
            </span>
          </mat-icon>
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <mat-list role="list">
        <mat-list-item
          class="custom-list"
          role="listitem"
          id="{{ item.displayValue }}"
          *ngFor="let item of field.options"
          ><span class="list-content"
           pTooltip="{{ item.displayValue }}"
          tooltipPosition="top"
          tooltipStyleClass="customtolltipbook"
          >{{ item.displayValue }}</span>
        </mat-list-item>
      </mat-list>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </form>
  `,
  styles: [
    '::ng-deep .custom-list .mat-list-item-content{position:relative;} ::ng-deep .custom-list .mat-list-item-content:before{content: "";width: 5px; height: 5px;  background: #333; position: absolute;   left: 0;  border-radius: 50%;} .mat-list-base .mat-list-item{min-height: 48px; height:auto;}',
  ],
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field?.value && this.field?.value?.length > 0) {
      if (Array.isArray(this.field?.value)) {
        this.field.options = this.field?.value;
      } else {
        this.field.options = [];
        this.field.options.push({ actualValue: this.field?.value, displayValue: this.field?.value });
      }
    }
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
}
