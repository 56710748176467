import { Injectable, OnDestroy } from '@angular/core';
import { EqlEndpointService } from './eql-endpoint.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';
@Injectable({
  providedIn: 'root',
})
export class EqlFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  eqlData = new Subject();
  eqlData$ = this.eqlData.asObservable();

  functionData = new Subject();
  functionData$ = this.functionData.asObservable();

  //nsl query integration with probability special feature
  operatorData = new Subject();
  operatorData$ = this.operatorData.asObservable();

  dateConstValueData = new Subject();
  dateConstValueData$ = this.dateConstValueData.asObservable();

  constructor(private eqlEndpoint: EqlEndpointService, private loader: LoaderService) {}

  getFunction() {
    this.loader.show();
    this.eqlEndpoint
      .geteqlFunction()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.functionData.next(res);
      });
  }

  getEqlData(canvas?: string) {
    let eqlOperator;
    let eqlFunction;
    let eqlFilter;
    let eqlPredicate;
    /* istanbul ignore next */
    if (canvas == 'grammar') {
      eqlFilter = this.eqlEndpoint.getEqlFilter();
      eqlOperator = this.eqlEndpoint.getEqlOperator();
      eqlPredicate = this.eqlEndpoint.getEqlPredicate();
      eqlFunction = this.eqlEndpoint.geteqlFunction();
      forkJoin([eqlFilter, eqlOperator, eqlPredicate, eqlFunction]).subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res?.[0] && res?.[1] && res?.[2]) {
          this.eqlData.next(res);
        }
      });
    } else {
      eqlFilter = this.eqlEndpoint.getEqlFilter();
      eqlPredicate = this.eqlEndpoint.getEqlPredicate();
      forkJoin([eqlFilter, eqlPredicate]).subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res?.[0] && res?.[1]) {
          this.eqlData.next(res);
        }
      });
    }
  }

  //nsl query integration with probability special feature
  getOperators() {
    this.loader.show();
    this.eqlEndpoint
      .getEqlOperator()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.operatorData.next(res);
      });
  }
  getoperatordata(alloperatordata: any) {
    this.operatorData.next(alloperatordata);
  }

  getDateConstValue() {
    this.loader.show();
    this.eqlEndpoint
      .getEqlDateConstValue()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dateConstValueData.next(res);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
