<span>
  <span *ngIf="attributeMapping?.function">{{ attributeMapping?.function }}(</span>
  <span *ngIf="attributeMapping?.arguments"
    ><span *ngFor="let obj of attributeMapping?.arguments">
      <span *ngIf="!obj?.arguments && !obj?.scope">{{ getValue(obj) }},</span>
      <app-mapping-display *ngIf="obj?.arguments || obj?.scope" [attributeMapping]="obj"></app-mapping-display> </span
  ></span>
  <span *ngIf="attributeMapping?.function">)</span>
  <span *ngIf="attributeMapping?.scope"
    >(<span *ngFor="let obj of attributeMapping?.scope">
      <span *ngIf="!obj?.arguments && !obj?.scope">{{ getValue(obj) }}</span>
      <app-mapping-display *ngIf="obj?.arguments || obj?.scope" [attributeMapping]="obj"></app-mapping-display> </span
    >)</span
  >
  <span *ngIf="!attributeMapping?.arguments && !attributeMapping?.scope && checkAttrmap(attributeMapping)"
    ><span *ngFor="let obj of attributeMapping?.[0]">{{ getValue(obj) }}</span>
  </span>
</span>
