import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { getUiControlCustomization } from '@common-services';
@Component({
  selector: 'app-boolean',
  styleUrls: ['./boolean.component.scss'],
  template: `
    <form [formGroup]="group">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field?.color"
            [style.font-size.px]="field?.fontSize"
            *ngIf="
              (field?.sentence == null || field?.sentence === '') &&
              !field?.hideLabels &&
              !field?.configuration?.hideLabel
            "
          >
            <span class="main-label-inner">
              <span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              class="material-icons-outlined helperText-infoIcon"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>

        <p
          class="top-text ml-0"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <mat-slide-toggle
          disableRipple
          #switchMode
          [ngClass]="applyBooleanConfig ? 'labelToggle' : appliedClass"
          formControlName="{{ field?.attribute?.name }}"
          (change)="changed()"
          [checked]="field?.value"
          [disabled]="field?.readonly"
          [ngStyle]="{ 'pointer-events': field?.readonly ? 'none' : 'inherit' }"
          id="{{ field?.attribute?.name }}_{{ instanceNumber }}"
        >
          <span *ngIf="foundObject?.event == 'Option 1'">Slide me! </span>
        </mat-slide-toggle>
        <mat-icon
          matTooltip="Sub-Transactional CU"
          matSuffix
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          ><span class="sub-cu" (click)="openSubPopUp()"></span>
        </mat-icon>
        <p
          class="bottom-text ml-0"
          *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
      </div>

      <ng-container *ngFor="let validation of field?.validations" ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation?.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </form>
  `,
  styles: [
    `
      .mat-slide-toggle {
        height: auto;
        width: fit-content;
      }
    `,
  ],
})
export class BooleanComponent implements OnInit, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  checked: boolean;
  instanceNumber: number = -1;
  foundObject: any;
  static componentCounter: number = 0;
  appliedClass: string = 'ui-kit-toggle';
  boolenConfig: any;
  labelContent: string = '';
  applyBooleanConfig: boolean = false;
  @ViewChild('switchMode', { read: ElementRef }) element:
  | ElementRef
  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService,private renderer: Renderer2) {
    BooleanComponent.componentCounter++;
    this.instanceNumber = BooleanComponent.componentCounter;
  }

  /**
   * Triggered when the toggle is changed.
   */
  changed() {
    if (!this.field.isHidden) {
      // if (this.field.configuration?.enableBooleanConfiguration || localStorage.getItem('environmentName') == 'defisolutions'){
      // this.setValues()
      // }
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
      /* conditional potentiality check  */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      }
    }
  }

  ngOnInit(): void {
    this.setInitialValue();
    this.boolenConfig = this.field?.configuration?.booleanConfiguration ? this.field?.configuration?.booleanConfiguration : null;
    this.applyBooleanConfig = this.field?.configuration?.enableBooleanConfiguration || localStorage.getItem('environmentName') == 'defisolutions';
    if (sessionStorage.getItem('uicontrolsMap')) {
      let arr = Object?.entries(JSON.parse(sessionStorage.getItem('uicontrolsMap')))?.map(([type, event]) => ({
        type,
        event,
      }));
      this.foundObject = arr.find((obj) => obj.type === 'TextArea');
      /* istanbul ignore else */
    }
    let radioOptions = getUiControlCustomization('Toggle');
    if (radioOptions) {
      let i = radioOptions.slice(-1);
      this.appliedClass = `form-toggle form-toggle-opt${i}`;
    }
  }

  ngAfterViewInit() {
    // if (this.field.configuration?.enableBooleanConfiguration || localStorage.getItem('environmentName') == 'defisolutions'){
    //   this.setValues()
    //   }
  }

  setInitialValue() {
    /* istanbul ignore next */
    const controlvalue =
      this.field?.value != '' && this.field?.value != 'false' && this.field?.value != 0 ? true : false;
    /* istanbul ignore next */
    this.group?.controls[this.field?.attribute?.name]?.setValue(controlvalue);
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    return this.group?.get(String(this.field?.attribute?.name))?.status == 'VALID' && this.field?.value != ''
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).toString().match(validation.requiredPattern)
        ? false
        : true;
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  setValues() {
    if (this.element) {
      const targetSpan: HTMLElement = this.element.nativeElement.querySelector(
        '.mat-slide-toggle-thumb'
      );
      while (targetSpan.firstChild) {
        targetSpan.firstChild.remove();
      }
      const elem = this.renderer.createElement('mat-icon');
      const value = this.group?.controls[this.field.attribute.name].value;
      // const icon = value ? this.boolenConfig.onValue : this.boolenConfig.offValue; //need to add dynamic value
      this.labelContent = value ? "Yes" : "No";
      elem.setAttribute('label', '');
      elem.textContent = this.labelContent;
      targetSpan.appendChild(elem);
    }
  }
}
