import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialFeaturesAdminDashboardEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getScheduledJobs(request: any) {
    return this.http.post(this.api?.specialFeatures, request);
  }
  viewScheduledJobs(req: any) {
    return this.http.post(this.api?.scheduledJobs, req);
  }
  getJobHistory(request: any) {
    return this.http.post(this.api?.jobHistory, request);
  }
  rerunData(data: any) {
    return this.http.post(this.api?.rerunData, data);
  }

  getallspecialfeature() {
    return this.http.get(this.api?.getallspecialfeatures);
  }

  getalluniquestatus() {
    return this.http.get(this.api?.getAllStatus);
  }
  jobSuspension(element: any) {
    return this.http.put(this.api?.jobSuspension, element);
  }
  jobResume(element: any) {
    return this.http.put(this.api?.jobResume, element);
  }
  jobDelete(element: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: element,
    };
    return this.http.delete(this.api?.jobDelete, options);
  }
}
