import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'chatbot-media-dialog',
  template: `
  <mat-dialog-actions class="customclosebtn">
    <button mat-dialog-close>
      <img [src]="'../../../../assets/images/team/close-icon.svg' | CDNUrlRewritePipe" alt="" />
    </button>
  </mat-dialog-actions>

  <div *ngIf="data.typeOfMedia === mediaTypes.Image " class="chatmedia">
    <figure>
      <img [src]="data.src | CDNUrlRewritePipe" alt="chat info" />
    </figure>
  </div>

  <div *ngIf="data.typeOfMedia === mediaTypes.Video" class="chatmedia">
    <!-- <video controls id="mainvideoplayer" autoplay>
      <source [src]="videoSrc" type="video/mp4" />
      <track label="English" kind="captions" srclang="en" src="" default />
    </video> -->
    <iframe [src]="videoSrc" width="100%" height="500px"></iframe>
  </div>
  `,
})
export class ChatBotMediaDialog {
  mediaTypes = MediaTypes;
  videoSrc: SafeResourceUrl;
  constructor(
    public dialogRef: MatDialogRef<ChatBotMediaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private sanitizer: DomSanitizer
  ) {
    this.getUrl(this.data.src);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getUrl(url: string) {
    if (this.data.typeOfMedia === 'video') {
      this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}

export enum MediaTypes {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
}

export interface DialogData {
  typeOfMedia: string;
  src: string;
}
