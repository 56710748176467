import { NgModule } from '@angular/core';
import { SliderComponent } from './slider.component';
import { ControlsMaterialModule } from '../material.module';
import { WebcamModule } from 'ngx-webcam';
import { SliderModule } from 'primeng/slider';

@NgModule({
  declarations: [SliderComponent],
  imports: [
    ControlsMaterialModule,
    WebcamModule,
    SliderModule,
  ],
  exports: [SliderComponent],
})
export class SliderControlModule {
  static entry = {
    basicslider: SliderComponent,
    inputslider: SliderComponent,
    rangeslider: SliderComponent,
    stepslider: SliderComponent,
    verticalslider: SliderComponent,
  };
}
