<div class="card-type-view">
  <div class="card">
    <div class="card-body">
      <div class="card-boxes">
        <div>
          <!-- <p>{{ attr1 }}</p> -->
          <ng-container
            *ngIf="attr1"
            dynamicField
            [formData]="attr1[attr1.attributeName]"
            [group]="attr1[attr1.attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>

          <!-- Detect event gsi -->
          <div *ngIf="attr1 && checkEventGSI(attr1[attr1.attributeName], 0)">
            <div *ngFor="let currEvent of currentEventDataArray[0]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attr1[attr1.attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </div>
        <div>
          <!-- <p>{{ attr2 }}</p> -->
          <ng-container
            *ngIf="attr2"
            dynamicField
            [formData]="attr2[attr2.attributeName]"
            [group]="attr2[attr2.attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>

          <!-- Detect event gsi -->
          <div *ngIf="attr2 && checkEventGSI(attr2[attr2.attributeName], 1)">
            <div *ngFor="let currEvent of currentEventDataArray[1]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attr2[attr2.attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </div>
        <div>
          <!-- {{ attr3 }} -->
          <ng-container
            *ngIf="attr3"
            dynamicField
            [formData]="attr3[attr3.attributeName]"
            [group]="attr3[attr3.attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>

          <!-- Detect event gsi -->
          <div *ngIf="attr3 && checkEventGSI(attr3[attr3.attributeName], 2)">
            <div *ngFor="let currEvent of currentEventDataArray[2]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attr3[attr3.attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </div>
        <div class="three-boxes row">
          <div class="col-4">
            <!-- {{ attr4 }} -->
            <ng-container
              *ngIf="attr4"
              dynamicField
              [formData]="attr4[attr4.attributeName]"
              [group]="attr4[attr4.attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>

            <!-- Detect event gsi -->
            <div *ngIf="attr4 && checkEventGSI(attr4[attr4.attributeName], 3)">
              <div *ngFor="let currEvent of currentEventDataArray[3]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attr4[attr4.attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-4">
            <!-- {{ attr5 }} -->
            <ng-container
              *ngIf="attr5"
              dynamicField
              [formData]="attr5[attr5.attributeName]"
              [group]="attr5[attr5.attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>

            <!-- Detect event gsi -->
            <div *ngIf="attr5 && checkEventGSI(attr5[attr5.attributeName], 4)">
              <div *ngFor="let currEvent of currentEventDataArray[4]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attr5[attr5.attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-4">
            <!-- {{ attr6 }} -->
            <ng-container
              *ngIf="attr6"
              dynamicField
              [formData]="attr6[attr6.attributeName]"
              [group]="attr6[attr6.attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>

            <!-- Detect event gsi -->
            <div *ngIf="attr6 && checkEventGSI(attr6[attr6.attributeName], 5)">
              <div *ngFor="let currEvent of currentEventDataArray[5]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attr6[attr6.attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <!-- {{ attr7 }} -->
          <ng-container
            *ngIf="attr7"
            dynamicField
            [formData]="attr7[attr7.attributeName]"
            [group]="attr7[attr7.attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>

          <!-- Detect event gsi -->
          <div *ngIf="attr7 && checkEventGSI(attr7[attr7.attributeName], 6)">
            <div *ngFor="let currEvent of currentEventDataArray[6]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attr7[attr7.attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer p-0" *ngIf="settings?.selectionType == 'button'">
      <div class="">
        <button
          type="button"
          (click)="selectedCard()"
          class="btn btn-block rounded-0"
          [ngClass]="isChecked ? 'card-selected' : 'cstm-btn'"
        >
          {{ settings?.lableName }}
        </button>
      </div>
    </div>
  </div>
</div>
