import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EventsFacadeService, FieldConfig, defaultEditorConfig, getUiControlCustomization, TransactionFacadeService, validateDependentExpression } from '@common-services';

@Component({
  selector: 'app-editor',
  styleUrls: ['./editor.component.scss'],
  template: `
    <div id="{{ id }}" class="demo-full-width text-editor" [formGroup]="group">
      <div class="d-flex align-items-center nh-mb-8 main-label-wrapper">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          ></label
        >
        <p
          class="nh-ml-6 d-flex main-label-helperIcon"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div *ngIf="!field?.attribute?.['isTableConfig']" class="{{ appliedparentClass }}" [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }">
        <angular-editor
          class="{{ appliedClass }}"
          *ngIf="field && field.config"
          [formControlName]="field.attribute.name"
          id="{{ id }}"
          [config]="field.config"
          [required]="field?.isRequired && !field?.isInfo"
        ></angular-editor>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
       <!--rich text in table-->
       <div *ngIf="field?.attribute?.['isTableConfig']" class="rich-text-in-table">
        <input class="form-control" (click)="toggleShow($event)" type="text" value="{{ field.label }}" readonly />

        <div class="richtext-popup" *ngIf="isShown" id="divshow">
          <div class="richtext-panel">
            <div class="richtext-popup-header">
              <h2 class="richtext-pop-title">{{ field.label }}</h2>
              <div class="richtext-action-btns">
                <ul>
                  <li (click)="toggleShow($event)">
                    <i class="bi bi-x"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="richtext-popup-content">
              <div
                class="{{ appliedparentClass }}"
                [ngClass]="{
                  inValidClass:
                    !field?.isInfo &&
                    (group?.controls)[field?.attribute?.name]?.invalid &&
                    (group?.controls)[field?.attribute?.name]?.touched
                }"
              >
                <angular-editor
                  class="{{ appliedClass }}"
                  *ngIf="field && field.config"
                  [formControlName]="field.attribute.name"
                  id="{{ id }}"
                  [config]="field.config"
                  [required]="field?.isRequired && !field?.isInfo"
                ></angular-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      angular-editor {
        font-family: FontAwesome !important;
      }
    `,
  ],
})
export class EditorComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  id: any;
  EditorConfig: AngularEditorConfig;
  appliedClass: string = 'ui-rich-text';
  appliedparentClass: string = '';
  validationList: any[] = [
    'length'
  ];

  constructor(private eventsService: EventsFacadeService, private transactionFacadeService: TransactionFacadeService,) {}
  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field && !this.field.config) {
      this.EditorConfig = JSON.parse(JSON.stringify(defaultEditorConfig));
      this.setDefaultConfig();
    }
    /* istanbul ignore else */
    let editorOption = getUiControlCustomization('RichText');
    if (editorOption) {
      let i = editorOption.slice(-1);
      this.appliedClass = `form-rich-text${i}`;
      this.appliedparentClass = `form-rich-text-opt${i}`;
    }
  }
  isShown: boolean = false; // hidden by default
  toggleShow(event: any) {
    this.isShown = !this.isShown;
    event.stopPropagation();
  }

  setDefaultConfig() {
    if (this.field.readonly) {
      this.id = this.field.attribute.name + '_InformationLayer';
      this.EditorConfig.editable = false;
      this.field.config = this.EditorConfig;
    } else {
      this.id = this.field.attribute.name + '_PhysicalLayer';
      this.EditorConfig.editable = true;
      this.field.config = this.EditorConfig;
    }
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    /* istanbul ignore next */
    return this.group?.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }
}
