import { Component, ElementRef, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { getFileNameFromImgUrl } from '../../generic-folder/generic-functions';
import { AlertService } from '../toaster/alert.service';
import { FileExplorerFacadeService } from '../file-explorer/file-explorer-facade.service';
import { TranslatorService } from '../translator/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewLayoutSolutionFacadeService } from '../new-layout-solution-service/layout-solution-facade.service';
import { LayoutService } from '../layout-solution-service/custom-layout-service';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadPreviewComponent } from '../file-upload-preview/file-upload-preview.component';

@Component({
  selector: 'app-layouts-old',
  templateUrl: './layouts.component.html',
  styleUrls: [
    './layouts.component.scss',
    './layout-sidebar.component.scss'
  ],
})
export class LayoutsComponent implements OnInit, OnChanges {
  public outlineColor: string = '';
  public dropShadowColor: string = '';
  toggleColor: boolean = false;
  toggleBackgroundColor: boolean = false;
  enhancements: boolean = false;
  currentLayout: any;
  customLayoutData: any;
  isAsserts: boolean = false;
  layoutNameSelected: boolean = false;
  layouts: any[] = [];
  folderTooltip: any = [];
  pageCrumb: any = [];
  searchFolderName: string = '';
  currentFolderName: any;
  @Input() currentTab?: any;
  @Input() currentGsiForLayout?: any;
  @Input() currentCuForLayout?: any;
  @Input() currentCuName?: any;
  @Input() selectedCuListForLayout?: any;

  @ViewChild('selectAllpopup') selectAllpopup: TemplateRef<any>;
  changeDesignToAll: string = '';
  showLayoutList: boolean = true;
  selectedBackground: { [key: string]: any } = {};
  selectedAssert: { [key: string]: any } = {};
  backgroundModal: boolean = false;
  showResources: boolean = false;
  showLibrary: boolean = false;
  showImageType: boolean = false;
  imageType: string = '';
  backgroundType: string = '';
  assertsType: string = '';
  private ngUnsubscribe = new Subject();
  limit: any = 20;
  iconLimit: any = 10;
  page: any = 1;
  wallpapers: any = [];
  selectedImageTypeWallpapers: any = [];
  availableVideos: any = [];
  breadcrumb = [
    {
      link: 'image',
      subLinks: [
        { link: 'svg' },
        { link: 'jpg', nextLevel: [{ link: 'wallpaper' }, { link: 'color' }] },
        { link: 'png' },
      ],
    },
    { link: 'video', subLinks: [] },
    { link: 'icon', subLinks: [] },
  ];
  currentBrdc: number = -1;
  currentSubBrdc: number = -1;
  isBreadcrumb: boolean = true;
  isCurrentBread: boolean = false;
  selectedGsiList: any = [];
  selectedCuList: any = [];
  oldBookDesign: any;
  bookDesign: any;
  selectedFormColor: string = '#FFFEEF';
  assertsModal: boolean = false;
  availableIcons: any;
  myResorces: any = [];
  myResorcesAllData: any = [];
  labels: any;
  activeAssetImg: number = -1;
  formColor: any = '#FFFEEF';
  backgroundColor: any = '#FFFEEF';
  backGroundWallpaper: any = '';
  isBackgroundWallpaper = false;
  isOutline = false;
  panelOpenState = false;
  outlineSize: number = 0;
  outlineCorner: any = 0;
  dropShadowXAxis: any = 0;
  dropShadowYAxis: any = 0;
  dropShadowOpacity: any = 0;
  dropShadowBlur: any = 0;
  backgroundBlurBlur: any = 0;
  backgroundBlurBrightness: any = 100;
  backgroundBlurOpacity: any = 100;
  changeUnitFontSize: number = 32;
  EntityFontPhySize: any = 18;
  EntityFontInfoSize: any = 18;
  backgroundOpacity: any = 100;
  changeUnitFontColor: string = '#000000';
  entityFontSize: number = 18;
  entityFontColor: string = '#000000';
  entityFontPhyColor: string = '#000000';
  entityFontInfoColor: string = '#000000';
  saveOutlineToggleButton: boolean = false;
  toggleBackgroundBlurButton: boolean = false;
  dropShadowToggleButton: boolean = false;
  EntityFontType: string = 'physical';
  src: any;
  checkedtemplate: any;
  showEnhancements: boolean = false;
  currentLayoutName: any;
  oldAssert: any;

  isHeaderCustomization: boolean = false;
  imageFileExt: any = ['gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];
  GSIFontColor: any = '#000000';
  AttributeFontColor: any = '#000000';
  actionLabelPayload: any = {
    Submit: {
      format: '',
      actionName: 'Submit',
      svgUrl: '',
      hoverSvgUrl: '',
      defaultButton: '#ffffff',
      hoverButton: '#ff657d',
      style: '',
      hoverText: '#000000',
      defaultText: '#000000',
      disableButton: false,
    },
    Cancel: {
      format: '',
      actionName: 'Cancel',
      svgUrl: '',
      hoverSvgUrl: '',
      defaultButton: '#ffffff',
      hoverButton: '#ffffff',
      style: '',
      hoverText: '#000000',
      defaultText: '#000000',
      disableButton: false,
    },
    Save: {
      format: '',
      actionName: 'Save',
      svgUrl: '',
      hoverSvgUrl: '',
      defaultButton: '#ffffff',
      hoverButton: '#ffffff',
      style: '',
      hoverText: '#000000',
      defaultText: '#000000',
      disableButton: false,
    },
    buttonAlignment: 'left',
  };
  toggleChooseStyle: boolean;
  actionButtonFormat: String = '';
  actionButton: any = {};
  currentActionButton: any = 'Submit';
  submitButton: String = '';
  cancelButton: String = '';
  saveButton: String = '';
  actionName: any = 'Submit';
  formatStateDefaultOrHover: String = 'Default';
  selected: any;
  disableButton: any = false;
  buttonAlignment: String = 'left';
  pageNumber: any = 1;
  pageLimit: any = 10;
  totalRecords: any;
  folderNamePagination: any;
  layoutConfigurations: any;
  GSIFontSize: any = 12;

  constructor(
    private layoutFacade: NewLayoutSolutionFacadeService,
    private alertService: AlertService,
    private fileExplorerService: FileExplorerFacadeService,
    private translator: TranslatorService,
    private layoutService: LayoutService,
    public dialog: MatDialog,
    private elRef: ElementRef
  ) {
    this.selectedBackground.url = '';
    this.selectedBackground.color = '';
    this.selectedAssert.url = '';
    this.selectedAssert.color = '';
    this.getWallpapers();
    this.detectBookDetails();
    this.detectGsiListForlayout();

    this.detectAllFiles();
    this.detectLanguageChange();
    this.detectCuListForLayout();
    this.openResourceType('nslLibrary');
    this.detectHeaderCustomization();
  }
  onClick() {
    this.disableButton = !this.disableButton;
    /* istanbul ignore next */
    if (this.currentActionButton !== 'Submit') {
      this.actionLabelPayload[this.currentActionButton].disableButton = this.disableButton;
    }
  }
  ngOnChanges() {
    /* istanbul ignore next */
    if (this.bookDesign && this.bookDesign?.cuDesignLayout) {
      let id = this.currentCuForLayout;
      this.isAsserts = false;
      /* istanbul ignore next */
      if (
        this.currentCuForLayout != '' &&
        this.bookDesign?.cuDesignLayout?.[id] != undefined &&
        this.customLayoutData != this.bookDesign?.cuDesignLayout?.[id]?.[0]
      ) {
        /* istanbul ignore next */
        let layoutName = this.bookDesign?.cuDesignLayout?.[id]?.[0]?.template;
        this.enhancements = true;
        /* istanbul ignore next */
        this.customLayoutData = JSON.parse(JSON.stringify(this.bookDesign?.cuDesignLayout?.[id]?.[0]));
        /* istanbul ignore next */
        let flag = true;
        for (let i = 0; i < this.layouts?.length && flag; i++) {
          for (let j = 0; j < this.layouts[i].list?.length && flag; j++) {
            this.layouts[i].list[j].multiLayout?.forEach((layout: any) => {
              if (layout.layout == layoutName) {
                layout.active = true;
                this.customLayoutData.template = JSON.parse(JSON.stringify(this.layouts[i].list[j]));
                flag = false;
                this.layoutNameSelected = true;
                this.showLayoutList = false;
                this.currentLayout = this.layouts[i].list[j];
                this.currentLayoutName = this.layouts[i].name;
                this.templateChecked(layout);
              }
            });
          }
        }
        /* istanbul ignore next */
        if (
          this.customLayoutData.template.name == 'layout-13' ||
          this.customLayoutData.template.name == 'layout-4' ||
          this.customLayoutData.template.name == 'layout-5' ||
          this.customLayoutData.template.name == 'layout-asset-2' ||
          this.customLayoutData.template.name == 'layout-2' ||
          this.addAssetLayoutArray.includes(this.customLayoutData.template.name)
        )
          this.isAsserts = true;
        /* istanbul ignore next */
        this.selectedBackground = this.customLayoutData?.background
          ? this.customLayoutData?.background
          : this.selectedBackground;
        /* istanbul ignore next */
        this.backgroundColor = this.customLayoutData?.background?.color;
        this.selectedAssert = this.customLayoutData.asserts ? this.customLayoutData.asserts : this.selectedAssert;
        this.oldAssert = this.customLayoutData.asserts;
        this.selectedFormColor = this.customLayoutData.formColor;
        /* istanbul ignore next */
        this.outlineSize = this.customLayoutData?.outline?.size || 0;
        /* istanbul ignore next */
        this.outlineColor = this.customLayoutData?.outline?.color;
        /* istanbul ignore next */
        this.outlineCorner = this.customLayoutData?.outline?.corner || 0;
        /* istanbul ignore next */
        this.dropShadowOpacity = this.customLayoutData?.dropShadow?.opacity || 0;
        /* istanbul ignore next */
        this.dropShadowBlur = this.customLayoutData?.dropShadow?.blur || 0;
        /* istanbul ignore next */
        this.dropShadowColor = this.customLayoutData?.dropShadow?.color;
        /* istanbul ignore next */
        this.backgroundBlurBlur = this.customLayoutData?.backgroundBlur?.blur;
        /* istanbul ignore next */
        this.backgroundBlurBrightness = this.customLayoutData?.backgroundBlur?.brightness || 100;
        /* istanbul ignore next */
        this.backgroundBlurOpacity = this.customLayoutData?.backgroundBlur?.opacity || 100;
        /* istanbul ignore next */
        this.changeUnitFontSize = this.customLayoutData?.font?.cuFont?.fontSize || 32;
        /* istanbul ignore next */
        this.changeUnitFontColor = this.customLayoutData?.font?.cuFont?.color || '#000000';
        /* istanbul ignore next */
        this.EntityFontPhySize = this.customLayoutData?.font?.physicalLayerFont?.fontSize;
        /* istanbul ignore next */
        this.entityFontPhyColor = this.customLayoutData?.font?.physicalLayerFont?.color || '#000000';
        /* istanbul ignore next */
        this.EntityFontInfoSize = this.customLayoutData?.font?.informationLayerFont?.fontSize;
        /* istanbul ignore next */
        this.entityFontInfoColor = this.customLayoutData?.font?.informationLayerFont?.color || '#000000';
        this.entityFontSize = this.customLayoutData?.font?.physicalLayerFont?.fontSize || 18;
        this.GSIFontColor = this.customLayoutData?.font?.gsiTabs?.color || '#000000';
        this.AttributeFontColor = this.customLayoutData?.font?.attribute?.color || '#000000';
        // this.currentLayout = this.customLayoutData;
        /* istanbul ignore next */
        if (this.customLayoutData?.actionLabel) {
          this.actionLabelPayload = JSON.parse(this.customLayoutData?.actionLabel);
          this.actionButtonRadios(this.actionLabelPayload[this.currentActionButton].format);
          // this.actionLabelPayload[this.currentActionButton].defaultButton = this.actionLabelPayload.defaultButton;
          // this.actionLabelPayload[this.currentActionButton].hoverButton = this.actionLabelPayload.hoverButton;
          // this.actionLabelPayload[this.currentActionButton].defaultText = this.actionLabelPayload.defaultText;
          // this.actionLabelPayload[this.currentActionButton].hoverText = this.actionLabelPayload.hoverText;
        }
        this.layoutFacade.updateCustomLayout(this.customLayoutData);
      } else if (this.currentCuForLayout != '' && this.bookDesign?.cuDesignLayout?.[id] == undefined) {
        this.customLayoutData = null;
        this.layoutNameSelected = false;
        this.showLayoutList = true;
        this.currentLayout = '';
        this.currentLayoutName = '';
        this.checkedtemplate = null;
        this.showEnhancements = false;
        this.layoutFacade.checkedTemplateDatatoCustomLayoutSubject.next(this.checkedtemplate);
        this.layoutFacade.updateCustomLayout('defaultLayout');
      }
    }
    if (this.currentCuName) {
      this.layoutFacade.updateCurrentCuName(this.currentCuName);
    }
  }

  getDefaultLayoutConfig() {
    /* istanbul ignore next */
    {
      this.layoutConfigurations = null;
      this.layouts.forEach((layouts: any) => {
        layouts.list.forEach((lst: any) => {
          lst.multiLayout.forEach((tmpl: any) => {
            this.layoutConfigurations = {
              ...this.layoutConfigurations,
              [tmpl.layout]: {
                ...tmpl,
                subFolderLevelConfig: lst.subFolderLevelConfig,
                folderLevelConfig: layouts.folderLevelConfig,
              },
            };
          });
        });
      });
    }
  }

  detectGsiListForlayout() {
    this.layoutFacade.selectedGsiDetailsForLayout$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.selectedGsiList = res;
    });
  }

  detectCuListForLayout() {
    this.layoutFacade.selectedCuDetailsForLayout$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res != '') {
        this.selectedCuList = res;
      }
    });
  }
  searchFolder() {
    if (this.searchFolderName == '') {
      this.myResorces = this.myResorcesAllData;
    } else {
      this.myResorces = [];
      for (let i = 0; i < this.myResorcesAllData.length; i++) {
        if (this.myResorcesAllData[i].name.toLocaleLowerCase().includes(this.searchFolderName.toLocaleLowerCase())) {
          this.myResorces.push(this.myResorcesAllData[i]);
        }
      }
    }
  }

  detectBookDetails() {
    this.layoutFacade.bookDetailsCustomLayout$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res != '') {
        this.layoutNameSelected = false;
        this.bookDesign = res;
        this.oldBookDesign = this.bookDesign;
      }
    });
  }

  toggleBackgroundType() {
    this.isBackgroundWallpaper = false;
  }

  toggleOutline() {
    this.isOutline = false;
  }
  changeImagesPage(event: any, folderName: any) {
    this.pageLimit = event?.pageSize;
    this.pageNumber = event?.pageIndex + 1;
    this.fileExplorerService.getAllFolders(folderName, '', false, this.pageLimit, this.pageNumber);
  }
  openFolder(folder: any) {
    this.folderTooltip = [];
    let folderName = folder.dsdId;
    let folderDisplayName = folder.name;
    this.folderNamePagination = folder.name;
    /* istanbul ignore next */
    if (folderName !== '') {
      this.currentFolderName = this.currentFolderName ? this.currentFolderName + '/' + folderName : folderName;
      this.pageCrumb.push({ displayName: folderDisplayName, link: this.currentFolderName });
      this.fileExplorerService.getAllFolders(folder.displayName, '', false, 5, 1);
    }
  }

  //page crumb navigation0
  goToFolder(folderName: any, i: any) {
    //erase all previous data
    this.folderTooltip = [];
    this.currentFolderName = null;
    /* istanbul ignore next */
    this.currentFolderName = this.currentFolderName ? this.currentFolderName + '/' + folderName : folderName;
    let pageCrumb = [];
    for (let j = 0; j <= i; j++) {
      pageCrumb.push(this.pageCrumb[j]); // change page crumb
    }
    this.pageCrumb = pageCrumb; // new page crumb
    /* istanbul ignore next */
    folderName = this.pageCrumb[this.pageCrumb.length - 1]?.link; // last folder name i.e. not active
    this.fileExplorerService.getAllFolders(folderName);
    /* istanbul ignore next */
    sessionStorage.setItem('folderNumber', this.pageCrumb.length);
    /* istanbul ignore next */
    sessionStorage.setItem('folderName', this.pageCrumb[this.pageCrumb.length - 1]?.link);
  }

  openAssertsModal() {
    if (this.layoutNameSelected) {
      this.assertsModal = true;
      /* istanbul ignore next */
      if (this.selectedAssert?.url || this.selectedAssert?.color) {
        this.selectedAssert.url = '';
        this.selectedAssert.color = '';
      }
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }

  openBackgroundModal() {
    if (this.layoutNameSelected) {
      this.isBackgroundWallpaper = true;
      this.backgroundModal = true;
      this.selectedBackground.url = '';
      this.selectedBackground.color = '';
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }

  cancelBackgroundModal() {
    this.selectedBackground.url = '';
    this.selectedBackground.color = this.backgroundColor;
    this.backgroundType = '';
    this.page = 1;
    this.limit = 10;
    this.imageType = '';
    this.currentBrdc = -1;
    this.currentSubBrdc = -1;
    this.isBreadcrumb = true;
    this.showResources = false;
    this.showLibrary = false;
    this.assertsType = '';
    this.backgroundModal = false;
    this.isBackgroundWallpaper = false;
  }

  cancelAssertsModal() {
    this.selectedAssert.url = '';
    this.selectedAssert.color = '';
    this.assertsModal = false;
    this.assertsType = '';
    this.page = 1;
    this.limit = 10;
    this.imageType = '';
    this.currentBrdc = -1;
    this.currentSubBrdc = -1;
    this.isBreadcrumb = true;
    this.showResources = false;
    this.showLibrary = false;
    this.backgroundType = '';
  }

  updateFormColor(event: any) {
    if (this.layoutNameSelected) {
      this.formColor = event;
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }

  updateEntityFontColor(event: any) {
    this.entityFontColor = event;
    if (this.layoutNameSelected) {
      if (this.EntityFontType == 'physical') {
        this.entityFontPhyColor = event;
      } else {
        this.entityFontInfoColor = event;
      }
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }
  updateChangeUnitFontSize(value: number) {
    if (this.changeUnitFontSize != undefined) {
      this.changeUnitFontSize = this.changeUnitFontSize + value;
    } else {
      this.changeUnitFontSize = 5 + value;
      this.changeUnitFontColor = '#00000';
    }
  }

  updateEntityFontSize(value: number) {
    this.entityFontSize = this.entityFontSize + value;
    if (this.layoutNameSelected) {
      if (this.EntityFontType == 'physical') {
        this.EntityFontPhySize = this.entityFontSize;
      } else {
        this.EntityFontInfoSize = this.entityFontSize;
      }
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }

  updateGSIFontSize(value: number) {
    if (this.GSIFontSize != undefined) {
      this.GSIFontSize = this.GSIFontSize + value;
    } else {
      this.GSIFontSize = 5 + value;
    }
  }
  attrFontSize: any = 16;
  updateAttrFontSize(value: number) {
    /* istanbul ignore next */
    if (this.attrFontSize != undefined) {
      this.attrFontSize = this.attrFontSize + value;
    } else {
      this.attrFontSize = 5 + value;
    }
  }

  updateBackgroundColor(event: any) {
    this.backgroundColor = event;
  }

  saveFormColor() {
    this.selectedFormColor = this.formColor;
    this.changeDesignToAll = 'formColor';
    this.toggleColor = !this.toggleColor;
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
  }

  saveBackgroundColor() {
    this.toggleBackgroundColor = !this.toggleBackgroundColor;
    this.changeDesignToAll = 'backGroundColor';
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
  }

  cancelFormColor() {
    this.formColor = '#FFFEEF';
    this.changeDesignToAll = 'formColor';
    this.toggleColor = !this.toggleColor;
    this.changeDesignToCurrentCU();
  }

  cancelBackgroundColor() {
    this.backgroundColor = '';
    this.toggleBackgroundColor = !this.toggleBackgroundColor;
  }

  saveBackgroundModal() {
    this.backgroundModal = false;
    this.UpdateData();
    this.backgroundType = '';
    this.page = 1;
    this.limit = 10;
    this.imageType = '';
    this.currentBrdc = -1;
    this.currentSubBrdc = -1;
    this.isBreadcrumb = true;
    this.showResources = false;
    this.showLibrary = false;
    this.assertsType = '';
    this.backgroundModal = false;
  }

  selectIconForAsserts(gsi: any) {
    /* istanbul ignore next */
    if (!this.isHeaderCustomization) {
      this.selectedAssert.url = gsi.link.desktop;
      this.selectedAssert.color = '';
      /* istanbul ignore next */
      if (this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout]?.[0]) {
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].asserts = this.selectedAssert;
      }
      this.customLayoutData.asserts = this.selectedAssert;
      this.UpdateData();
    } else {
      let urlArr = gsi.link.desktop.split('.');
      let extension = urlArr[urlArr.length - 1];
      if (this.imageFileExt.includes(extension)) {
        this.selectedAssert.url = gsi.link.desktop;
      } else {
        this.alertService.showToaster('Please select only image file', '', 'error');
      }
    }
  }

  UpdateData() {
    this.layoutFacade.fetachDesignSystemData(this.bookDesign);
    this.layoutFacade.updateCustomLayout(this.customLayoutData);
  }

  selectIconForBackground(gsi: any) {
    this.selectedBackground.url = gsi.link.any;
    this.selectedBackground.color = '';
    /* istanbul ignore next */
    if (this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout]?.[0]) {
      this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background = this.selectedBackground;
    }
    this.getNameFromUrl(gsi.link.any);
    this.customLayoutData.background = this.selectedBackground;
    this.UpdateData();
  }

  selectVedioForAssert(vedioUrl: any) {
    this.selectedAssert.url = vedioUrl;
    this.selectedAssert.color = '';
    /* istanbul ignore next */
    if (this.bookDesign?.cuDesignLayout?.[this.currentGsiForLayout]?.[0]) {
      this.bookDesign.cuDesignLayout[this.currentGsiForLayout][0].asserts = this.selectedAssert;
    }
    this.customLayoutData.asserts = this.selectedAssert;
    this.UpdateData();
  }

  selectVedioForBackground(vedioUrl: any) {
    this.selectedBackground.url = vedioUrl;
    this.selectedBackground.color = '';
    /* istanbul ignore next */
    if (this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout]?.[0]) {
      this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background = this.selectedBackground;
    }
    this.getNameFromUrl(vedioUrl);
    this.customLayoutData.background = this.selectedBackground;
    this.UpdateData();
  }

  getNameFromUrl(url: any) {
    /* istanbul ignore next */
    let folders = url?.split('/');
    this.backGroundWallpaper = folders[folders.length - 1];
  }

  saveAssertModal() {
    this.assertsModal = false;
    this.assertsType = '';
    this.page = 1;
    this.limit = 10;
    this.imageType = '';
    this.currentBrdc = -1;
    this.currentSubBrdc = -1;
    this.isBreadcrumb = true;
    this.showResources = false;
    this.showLibrary = false;
    this.backgroundType = '';
    /* istanbul ignore next */
    if (!this.isHeaderCustomization) {
      this.UpdateData();
    } else {
      this.layoutFacade.updateSelectedAssertUrl(this.selectedAssert.url);
    }
  }

  openResourceType(type: string) {
    /* istanbul ignore next */
    if (type == 'myResouces') {
      this.showResources = true;
      this.folderNamePagination = '';
      this.fileExplorerService.getAllFolders('');
      this.backgroundType = '';
      this.assertsType = '';
      /* istanbul ignore next */
      if (this.pageCrumb.length == 0) {
        this.pageCrumb.push({ displayName: 'My Resources', link: '' }); //first pagecrumb default
        sessionStorage.setItem('folderNumber', this.pageCrumb.length);
      }
      this.showLibrary = false;
    } else if (type == 'nslLibrary') {
      this.backgroundType = '';
      this.assertsType = '';
      this.pageCrumb = [];
      this.showLibrary = true;
      this.showResources = false;
    }
  }

  selectWallpaperForBackGround(index: number) {
    this.backGroundWallpaper = this.wallpapers[index].link.any;
    this.changeDesignToAll = 'backGroundWalpaper';
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
    this.UpdateData();
  }

  closeSelectAllPopup() {
    this.dialog.closeAll();
  }

  changeDesignToAllCU() {
    /* istanbul ignore next */
    switch (this.changeDesignToAll) {
      case 'formColor':
        this.customLayoutData.formColor = this.formColor;
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].formColor = this.formColor;
          } else if (this.bookDesign) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].formColor = this.formColor;
          }
        }
        break;
      case 'backGroundColor':
        this.selectedBackground.color = this.backgroundColor;
        this.selectedBackground.url = '';
        this.customLayoutData.background = this.selectedBackground;
        /* istanbul ignore next */

        if (this.bookDesign?.backGround?.link?.any) {
          this.bookDesign.backGround.link.any = '';
        }

        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].background = this.selectedBackground;
          } else if (this.bookDesign && this.bookDesign?.cuDesignLayout) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].background = this.selectedBackground;
          }
        }
        break;
      case 'backGroundWalpaper':
        if (!this.selectedBackground.url) {
          this.selectedBackground.url = '';
        }
        this.selectedBackground.url = this.backGroundWallpaper;
        this.selectedBackground.color = '';
        /* istanbul ignore next */
        if (this.bookDesign?.backGround?.link?.any) {
          this.bookDesign.backGround.link.any = '';
        }
        this.customLayoutData.background = this.selectedBackground;
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].background = this.selectedBackground;
          } else if (this.bookDesign) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].background = this.selectedBackground;
          }
        }
        this.getNameFromUrl(this.backGroundWallpaper);
        break;
      case 'outline':
        const payload = {
          color: this.outlineColor,
          size: +this.outlineSize,
          corner: this.outlineCorner,
        };
        this.customLayoutData.outline = payload;
        /* istanbul ignore next */
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].outline = payload;
          } else if (this.bookDesign) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].outline = payload;
          }
        }
        break;
      case 'dropShadow':
        const payloadDropShadow = {
          color: this.dropShadowColor,
          xAxis: +this.dropShadowXAxis,
          yAxis: +this.dropShadowYAxis,
          opacity: +this.dropShadowOpacity,
          blur: +this.dropShadowBlur,
        };
        this.customLayoutData.dropShadow = payloadDropShadow;
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].dropShadow = payloadDropShadow;
          } else if (this.bookDesign) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].dropShadow = payloadDropShadow;
          }
        }
        break;
      case 'backgroundBlur':
        const payloadforBgBlur = {
          blur: this.backgroundBlurBlur,
          brightness: this.backgroundBlurBrightness,
          opacity: this.backgroundBlurOpacity,
        };
        this.customLayoutData.backgroundBlur = payloadforBgBlur;
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].backgroundBlur = payloadforBgBlur;
          } else if (this.bookDesign) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].backgroundBlur = payloadforBgBlur;
          }
        }
        break;
      case 'font':
        const payloadforFont = {
          cuFont: {
            fontSize: this.changeUnitFontSize,
            color: this.changeUnitFontColor,
          },
          physicalLayerFont: {
            fontSize: this.EntityFontPhySize,
            color: this.entityFontPhyColor,
          },
          informationLayerFont: {
            fontSize: this.EntityFontInfoSize,
            color: this.entityFontInfoColor,
          },
          gsiTabs: {
            color: this.GSIFontColor,
            fontSize: this.GSIFontSize,
          },
          attribute: {
            color: this.AttributeFontColor,
            fontSize: this.attrFontSize,
          },
        };
        this.customLayoutData.font = payloadforFont;
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].font = payloadforFont;
          } else if (this.bookDesign) {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].font = payloadforFont;
          }
        }
        break;
      case 'asserts':
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].asserts = this.selectedAssert;
          } else {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].asserts = this.selectedAssert;
          }
        }
        break;
      case 'color':
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].background = this.selectedBackground;
          } else {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id] = this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout];
            this.bookDesign.cuDesignLayout[id][0].background = this.selectedBackground;
          }
        }
        break;
      case 'actionButton':
        for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
          let id = this.selectedCuListForLayout[i];
          /* istanbul ignore next */
          if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
            this.bookDesign.cuDesignLayout[id][0].actionLabel = JSON.stringify(this.actionLabelPayload);
          } else {
            /* istanbul ignore next */
            this.bookDesign.cuDesignLayout[id][0].actionLabel = JSON.stringify(this.actionLabelPayload);
            this.customLayoutData.actionLabel = JSON.stringify(this.actionLabelPayload);
          }
        }
        break;
    }
    this.UpdateData();
    this.dialog.closeAll();
  }

  changeDesignToCurrentCU() {
    /* istanbul ignore next */
    switch (this.changeDesignToAll) {
      case 'formColor':
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].formColor = this.formColor;
        this.customLayoutData.formColor = this.formColor;
        break;
      case 'backGroundColor':
        this.selectedBackground.color = this.backgroundColor;
        this.selectedBackground.url = '';
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background = this.selectedBackground;
        this.customLayoutData.background = this.selectedBackground;
        /* istanbul ignore next */
        if (this.bookDesign?.backGround?.link?.any) {
          this.bookDesign.backGround.link.any = '';
        }
        break;
      case 'backGroundWalpaper':
        this.selectedBackground.url = this.backGroundWallpaper;
        this.selectedBackground.color = '';
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background = this.selectedBackground;
        this.customLayoutData.background = this.selectedBackground;
        this.getNameFromUrl(this.backGroundWallpaper);
        /* istanbul ignore next */
        if (this.bookDesign?.backGround?.link?.any) {
          this.bookDesign.backGround.link.any = '';
        }
        break;
      case 'outline':
        const payload = {
          color: this.outlineColor,
          size: +this.outlineSize,
          corner: this.outlineCorner,
        };
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].outline = payload;
        this.customLayoutData.outline = payload;
        break;
      case 'dropShadow':
        const payloadDropShadow = {
          color: this.dropShadowColor,
          xAxis: +this.dropShadowXAxis,
          yAxis: +this.dropShadowYAxis,
          opacity: +this.dropShadowOpacity,
          blur: +this.dropShadowBlur,
        };
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].dropShadow = payloadDropShadow;
        this.customLayoutData.dropShadow = payloadDropShadow;
        break;
      case 'backgroundBlur':
        const payloadforBgBlur = {
          blur: this.backgroundBlurBlur,
          brightness: this.backgroundBlurBrightness,
          opacity: this.backgroundBlurOpacity,
        };
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].backgroundBlur = payloadforBgBlur;
        this.customLayoutData.backgroundBlur = payloadforBgBlur;
        break;
      case 'font':
        const payloadforFont = {
          cuFont: {
            fontSize: this.changeUnitFontSize,
            color: this.changeUnitFontColor,
          },
          physicalLayerFont: {
            fontSize: this.EntityFontPhySize,
            color: this.entityFontPhyColor,
          },
          informationLayerFont: {
            fontSize: this.EntityFontInfoSize,
            color: this.entityFontInfoColor,
          },
          gsiTabs: {
            color: this.GSIFontColor,
            fontSize: this.GSIFontSize,
          },
          attribute: {
            color: this.AttributeFontColor,
            fontSize: this.attrFontSize,
          },
        };
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].font = payloadforFont;
        this.customLayoutData.font = payloadforFont;
        break;
      case 'asserts':
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].asserts = this.selectedAssert;
        break;
      case 'color':
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background = this.selectedBackground;
        this.customLayoutData.background = this.selectedBackground;
        break;
      case 'actionButton':
        this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].actionLabel = JSON.stringify(
          this.actionLabelPayload
        );
        this.customLayoutData.actionLabel = JSON.stringify(this.actionLabelPayload);
        break;
    }
    this.UpdateData();
    this.dialog.closeAll();
  }

  selectWallpaperForBackGroundFromMyresouces(url: string) {
    this.backGroundWallpaper = url;
    this.changeDesignToAll = 'backGroundWalpaper';
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
    this.UpdateData();
  }

  deletedWallpaper() {
    this.backGroundWallpaper = '';
    this.isBackgroundWallpaper = false;
    this.selctColorForBackGround('#FFFEEF');
  }

  selectWallpaperForAsserts(index: number) {
    this.activeAssetImg = index;
    this.selectedAssert = {
      url: String,
      color: String,
    };
    this.selectedAssert.url = this.selectedImageTypeWallpapers[index].link.desktop;
    this.selectedAssert.color = '';
    // this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].asserts = this.selectedAssert;
    this.changeDesignToAll = 'asserts';
    this.customLayoutData.asserts = this.selectedAssert;
    /* istanbul ignore next */
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
    // this.UpadteData();
    // this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].asserts = this.selectedAssert;
    // this.customLayoutData.asserts = this.selectedAssert;
    // /* istanbul ignore next */
    // if(this.currentGsiForLayout){
    //   for (let i = 0; i < this.selectedCuListForLayout.length; i++) {
    //     let id = this.selectedCuListForLayout[i];
    //     /* istanbul ignore next */
    //     if (this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] != undefined) {
    //       this.bookDesign.cuDesignLayout[id][0].asserts = this.selectedAssert;
    //     } else {
    //       /* istanbul ignore next */
    //       this.bookDesign.cuDesignLayout[id][0].asserts = this.selectedAssert;
    //     }
    //   }
    // }
    // this.UpadteData();
  }

  selectWallpaperForAssertsFromMyresouces(url: string) {
    this.selectedAssert = {
      url: String,
      color: String,
    };
    /* istanbul ignore next */
    if (!this.isHeaderCustomization) {
      this.selectedAssert.url = url;
      this.selectedAssert.color = '';
      // this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].asserts = this.selectedAssert;
      this.changeDesignToAll = 'asserts';
      this.customLayoutData.asserts = this.selectedAssert;
      /* istanbul ignore next */
      if (this.currentGsiForLayout != '') {
        this.dialog.open(this.selectAllpopup);
      } else {
        this.changeDesignToCurrentCU();
      }
    } else {
      let urlArr = url.split('.');
      let extension = urlArr[urlArr.length - 1];
      if (this.imageFileExt.includes(extension)) {
        this.selectedAssert.url = url;
      } else {
        this.alertService.showToaster('Please select only image file', '', 'error');
      }
    }
  }

  selctColorForBackGround(color: string) {
    this.changeDesignToAll = 'color';
    this.selectedBackground.color = color;
    this.selectedBackground.url = '';
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
    // this.UpdateData();
  }

  selctColorForAsserts(color: string) {
    this.selectedAssert.color = color;
    this.selectedAssert.url = '';
    this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].asserts = this.selectedAssert;
    this.customLayoutData.asserts = this.selectedAssert;
    this.UpdateData();
  }

  getWallpapers() {
    /* istanbul ignore next */
    this.layoutFacade
      .getWallpapers(this.limit, this.page)
      ?.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result?.data) {
          this.wallpapers = [...this.wallpapers, ...res.result.data];
          if (this.imageType) {
            this.selectedImageTypeWallpapers = this.wallpapers.filter((wallpaper: any) => {
              let len = wallpaper.link.any.split('.').length;
              if (this.imageType == wallpaper.link.any.split('.')[len - 1]) {
                return true;
              } else return false;
            });
          }
        }
      });
  }

  openImageType(type: string, ind: number) {
    this.showImageType = true;
    this.currentBrdc = ind;
    if (type == 'icon') {
      this.assertsType = type;
      this.backgroundType = type;
      this.getGsiIcons();
    }
    if (type == 'video') {
      this.assertsType = type;
      this.backgroundType = type;
      // this.fileExplorerService.getAllFolders('/Videos/videos');
    }
  }

  closeImageType() {
    this.showImageType = false;
    this.currentBrdc = -1;
    this.deSelectImageType();
  }

  detectAllFiles() {
    /* istanbul ignore next */
    this.fileExplorerService.allFolders$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.data) {
        this.myResorces = res.data;
        this.myResorcesAllData = res.data;
        this.totalRecords = res?.totalResults;
        for (let value of res.data) {
          /* istanbul ignore next */
          if (value.folders) {
            var tempTooltip = '';
            for (let data of value.folders) {
              if (tempTooltip === '') {
                tempTooltip = data;
              } else {
                tempTooltip = tempTooltip + '/ ' + data;
              }
            }
            tempTooltip = tempTooltip + '/ ' + value.name;
            this.folderTooltip.push(tempTooltip);
          } else {
            this.folderTooltip.push(value.name);
          }
        }
      }
    });
  }

  onModalScrollDown() {
    this.page = ++this.page;
    this.getWallpapers();
  }

  selectImageType(imageType: string, index: number) {
    this.imageType = imageType;
    this.currentSubBrdc = index;
    this.isBreadcrumb = false;
  }

  deSelectImageType() {
    this.imageType = null;
    this.isBreadcrumb = true;
  }

  showBackgroundType(backroundType: string) {
    this.backgroundType = backroundType;
  }
  showAssertsType(backroundType: string) {
    /* istanbul ignore next */
    if (this.imageType) {
      this.selectedImageTypeWallpapers = this.wallpapers.filter((wallpaper: any) => {
        let len = wallpaper.link.any.split('.').length;
        if (this.imageType == wallpaper.link.any.split('.')[len - 1]) {
          return true;
        } else return false;
      });
    }
    this.assertsType = backroundType;
  }

  tenantName: string = '';
  planName: string = '';
  // adBasedPlanFolders : any =['Basic Layouts' ,'Open CU Layouts','Asset Layouts','CU layout horizontal Attributes', 'Assets Horizontal Attributes', 'Regular', 'Tab & Accordion', 'Miscellaneous'];
  hashMap = new Map<string, number>();
  /* istanbul ignore next */
  ngOnInit(): void {
    this.tenantName = localStorage.getItem('TenantName');
    this.planName = JSON.parse(localStorage.getItem('ActiveUserInfo'))?.planNFeatureMapping?.planName;

    this.hashMap.set('Basic Layouts', 1);
    this.hashMap.set('Open CU Layouts', 1);
    this.hashMap.set('Asset Layouts', 1);
    this.hashMap.set('CU layout horizontal Attributes', 1);
    this.hashMap.set('Assets Horizontal Attributes', 1);
    this.hashMap.set('Regular', 1);
    this.hashMap.set('Tab & Accordion', 1);
    this.hashMap.set('Miscellaneous', 1);

    this.layoutService.getProductsSmall().subscribe((data: any) => {
      this.layouts = data;
      
      if (!this.tenantName.includes('sanborns') && !this.tenantName.includes('demoinventory')) {
        this.layouts.forEach((layout: any, ind: any) => {
          if (layout.name == 'Enterprise 1') {
            layout.list.forEach((sublayout: any, i: any) => {
              if (sublayout.name.includes('sanborns')) {
                this.layouts[ind].list.splice(i, 1);
              }
            });
          }
        });
      }
      for (let i = 0; i < this.layouts.length; i++) {
        if (
          (this.tenantName !== 'efundzz' &&
            this.tenantName !== 'callhealth' &&
            this.tenantName !== 'healthcaremx' &&
            this.tenantName == 'healthcaremxstage' &&
            this.layouts[i].name == 'ProgressBar') ||
          (this.planName == 'AdBasedPlan' && !this.hashMap.has(this.layouts[i].name))
        ) {
          this.layouts.splice(i, 1);
          i--;
        }
      }

      this.layouts.forEach((layout: any) => {
        if (layout.name == 'Basic Layouts') {
          layout.name = this.labels?.Basic_Layouts;
        }
        if (layout.name == 'Open CU Layouts') {
          layout.name = this.labels?.Open_CU_Layouts;
        }
        if (layout.name == 'Asset Layouts') {
          layout.name = this.labels?.Asset_Layouts;
        }
        if (layout.name == 'CU layout horizontal Attributes') {
          layout.name = this.labels?.CU_layout_horizontal_Attributes;
        }
        if (layout.name == 'Extended Templates') {
          layout.name = this.labels?.Extended_Templates;
        }
        if (layout.name == 'Assets Horizontal Attributes') {
          layout.name = this.labels?.Assets_Horizontal_Attributes;
        }
        if (layout.name == 'Luxury') {
          layout.name = this.labels?.Luxury;
        }
        if (layout.name == 'Regular') {
          layout.name = this.labels?.Regular;
        }
      });
      if (sessionStorage?.bookData && JSON.parse(sessionStorage?.bookData)?.isGSILevelDS == true) {
        const index = this.layouts?.findIndex((res: any) => res.name == this.labels?.Luxury);
        this.layouts.splice(index, 1);
      }
      if (sessionStorage?.bookData && JSON.parse(sessionStorage?.bookData)?.isGSILevelDS == true) {
        const index = this.layouts?.findIndex((res: any) => res.name == this.labels?.Regular);
        this.layouts.splice(index, 1);
      }
      if (sessionStorage?.bookData && JSON.parse(sessionStorage?.bookData)?.isGSILevelDS == true) {
        const index = this.layouts?.findIndex((res: any) => res.name == this.labels?.Extended_Templates);
        this.layouts.splice(index, 1);
      }
      this.getDefaultLayoutConfig();
    });
  }
  closeLayoutList() {
    this.showLayoutList = false;
  }

  detectLanguageChange() {
    /* istanbul ignore next */
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.ngOnInit();
    });
  }

  openLayoutList() {
    this.showLayoutList = true;
    this.showEnhancements = false;
    this.disableAssets = false;
  }

  addAssetLayoutArray: any = [
    'layout-asset-2',
    'layout-4',
    'layout-5',
    'layout-asset-6-phy',
    'layout-asset-4-phy-info',
    'layout-asset-4-info-phy',
    'layout-6-asset-phy',
    'layout-6-asset-info',
    'layout-6e1',
    'layout-5e1',
    'layout-4e1',
    'layout-6e1-57',
    'AHA-6e1',
    'AHA-5e1',
    'AHA-4e1',
    'AHA-6e1-75',
    'AHA-5e1-75',
    'AHA-4e1-75',
    'AHA-6e1-57',
    'AHA-5e1-57',
    'AHA-4e1-57',
    'AHA-6e1-444',
    'AHA-5e1-444',
    'AHA-4e1-444',
    'AHA-6e1-12',
    'AHA-5e1-12',
    'AHA-4e1-12',
    'progressbar-84',
    'progressbar-33333',
    'progressbar-8',
    'progressbar-66',
    'progressbar-12-middle',
    'layout-header-asset-12-hr',
    'layout-header-asset-66-hr',
    'layout-tab-444-ast-phy',
    'layout-tab-444-ast-info',
    'layout-accordion-444-ast-phy',
    'layout-accordion-444-ast-info',
    'layout-tab-66-ast-phy',
    'layout-tab-66-ast-info',
    'layout-tab-12-ast-phy',
    'layout-tab-12-ast-info',
    'layout-accordion-8-ast-phy',
    'layout-accordion-8-ast-info',
  ];

  getDefaultConfigAttribute(layoutName: string, element: string, defaultVal: any) {
    const templateLevelConfig = this.layoutConfigurations[layoutName]?.templateLevelConfig;
    const folderLevelConfig = this.layoutConfigurations[layoutName]?.folderLevelConfig;
    const subFolderLevelConfig = this.layoutConfigurations[layoutName]?.subFolderLevelConfig;
    return (
      templateLevelConfig?.[element] || subFolderLevelConfig?.[element] || folderLevelConfig?.[element] || defaultVal
    );
  }

  /**
   * we can give layout configs(layout.json) at
   * Folder level : folderLevelConfig (Ex: Enterprise 1)
   * Subfolder level : subFolderLevelConfig (Ex:layout-enterprise-66)
   * Layout level : templateLevelConfig (Ex: layout-enterprise-66-phy-info).
   * Following are the elements we can use. Also, can be expandable.
   * formColor,outlineColor,outlineCorner, outlineSize,dropShadowColor,dropShadowXAxis,dropShadowYAxis,dropShadowOpacity,dropShadowBlur,backgroundBlurBlur,backgroundBlurBrighness,backgroundBlurOpacity,changeUnitFontSize,changeUnitFontColor,entityFontPhySize,entityFontPhyColor,entityFontInfoSize,entityFontInfoColor,gsiTabColor,attributeFontColor
   */
  setDefaultTemplateConfig(layoutName: string) {
    this.backgroundColor = this.getDefaultConfigAttribute(layoutName, 'backgroundColor', this.backgroundColor);
    this.selectedBackground.color = this.backgroundColor;
    this.formColor = this.getDefaultConfigAttribute(layoutName, 'formColor', this.selectedFormColor);
    this.selectedFormColor = this.formColor;
    this.outlineColor = this.getDefaultConfigAttribute(layoutName, 'outlineColor', this.outlineColor);
    this.outlineCorner = this.getDefaultConfigAttribute(layoutName, 'outlineCorner', this.outlineCorner);
    this.outlineSize = this.getDefaultConfigAttribute(layoutName, 'outlineSize', this.outlineSize);
    this.dropShadowColor = this.getDefaultConfigAttribute(layoutName, 'dropShadowColor', this.dropShadowColor);
    this.dropShadowXAxis = this.getDefaultConfigAttribute(layoutName, 'dropShadowXAxis', this.dropShadowXAxis);
    this.dropShadowYAxis = this.getDefaultConfigAttribute(layoutName, 'dropShadowYAxis', this.dropShadowYAxis);
    this.dropShadowOpacity = this.getDefaultConfigAttribute(layoutName, 'dropShadowOpacity', this.dropShadowOpacity);
    this.dropShadowBlur = this.getDefaultConfigAttribute(layoutName, 'dropShadowBlur', this.dropShadowBlur);
    this.backgroundBlurBlur = this.getDefaultConfigAttribute(layoutName, 'backgroundBlurBlur', this.backgroundBlurBlur);
    this.backgroundBlurBrightness = this.getDefaultConfigAttribute(
      layoutName,
      'backgroundBlurBrighness',
      this.backgroundBlurBrightness
    );
    this.backgroundBlurOpacity = this.getDefaultConfigAttribute(
      layoutName,
      'backgroundBlurOpacity',
      this.backgroundBlurOpacity
    );
    this.changeUnitFontSize = this.getDefaultConfigAttribute(layoutName, 'changeUnitFontSize', this.changeUnitFontSize);
    this.changeUnitFontColor = this.getDefaultConfigAttribute(
      layoutName,
      'changeUnitFontColor',
      this.changeUnitFontColor
    );
    this.EntityFontPhySize = this.getDefaultConfigAttribute(layoutName, 'entityFontPhySize', this.EntityFontPhySize);
    this.entityFontPhyColor = this.getDefaultConfigAttribute(layoutName, 'entityFontPhyColor', this.entityFontPhyColor);
    this.EntityFontInfoSize = this.getDefaultConfigAttribute(layoutName, 'entityFontInfoSize', this.EntityFontInfoSize);
    this.entityFontInfoColor = this.getDefaultConfigAttribute(
      layoutName,
      'entityFontInfoColor',
      this.entityFontInfoColor
    );
    this.GSIFontColor = this.getDefaultConfigAttribute(layoutName, 'gsiTabColor', this.GSIFontColor);
    this.AttributeFontColor = this.getDefaultConfigAttribute(layoutName, 'attributeFontColor', this.AttributeFontColor);
  }

  selectingLayout(clm: any, layoutName: any) {
    this.showLayoutList = false;
    this.isAsserts = false;
    this.layoutNameSelected = false;
    this.currentLayout = clm;
    this.currentLayoutName = layoutName;
    /* istanbul ignore next */
    if (layoutName.includes('Asset') || layoutName.includes('Assets')) {
      this.selectedAssert = this.oldAssert ? this.oldAssert : this.selectedAssert;
    } else {
      this.selectedAssert = {};
      this.selectedAssert.url = '';
      this.selectedAssert.color = '';
    }
    this.setCustomData();
  }

  setCustomData() {
    this.customLayoutData = {
      template: this.currentLayout,
      asserts: this.selectedAssert,
      background: this.selectedBackground,
      formColor: this.selectedFormColor,
      outline: {
        color: this.outlineColor,
        corner: this.outlineCorner,
        size: this.outlineSize,
      },
      dropShadow: {
        color: this.dropShadowColor,
        xAxis: this.dropShadowXAxis,
        yAxis: this.dropShadowYAxis,
        opacity: this.dropShadowOpacity,
        blur: this.dropShadowBlur,
      },
      backgroundBlur: {
        blur: this.backgroundBlurBlur,
        brightness: this.backgroundBlurBrightness,
        opacity: this.backgroundBlurOpacity,
      },
      font: {
        cuFont: {
          fontSize: this.changeUnitFontSize,
          color: this.changeUnitFontColor,
        },
        physicalLayerFont: {
          fontSize: this.EntityFontPhySize,
          color: this.entityFontPhyColor,
        },
        informationLayerFont: {
          fontSize: this.EntityFontInfoSize,
          color: this.entityFontInfoColor,
        },
        gsiTabs: {
          color: this.GSIFontColor,
          fontSize: this.GSIFontSize,
        },
        attribute: {
          color: this.AttributeFontColor,
          fontSize: this.attrFontSize,
        },
      },
      actionLabel: JSON.stringify(this.actionLabelPayload),
    };
    /* istanbul ignore next */
    if (this.addAssetLayoutArray.includes(this.customLayoutData.template.name)) this.isAsserts = true;
    this.layoutFacade.updateCustomLayout(this.customLayoutData);
  }
  disableAssets: boolean = false;
  selectedLayout(clm: any, index: number) {
    if (clm && clm.layout) {
      this.setDefaultTemplateConfig(clm.layout);
      this.setCustomData();
    }
    this.layoutNameSelected = true;
    /* istanbul ignore next */
    if (clm.layout == 'transaction-book-menu') {
      this.disableAssets = true;
      this.selectedFormColor = 'rgba(255, 255, 255, 0.4)';
      this.selectedBackground.color = '#D4E3F8';
      if (this.customLayoutData?.background.color !== '') {
        this.selectedBackground.color = this.customLayoutData?.background.color;
      }
    }
    /* istanbul ignore next */
    if (this.addAssetLayoutArray.includes(clm?.layout)) this.isAsserts = true;
    this.currentLayout.multiLayout.forEach((layout: any) => {
      layout.active = false;
    });
    this.currentLayout.multiLayout[index].active = true;
    /* istanbul ignore next */
    if (this.selectedCuList?.length == 0 && this.selectedGsiList?.length == 0) {
      this.alertService.showToaster('No Cu & GSi selected', '', 'info');
      return;
    }
    this.enhancements = true;
    /* istanbul ignore next */
    if (this.currentCuForLayout) {
      /* istanbul ignore next */
      if (this.bookDesign && this.bookDesign?.cuDesignLayout == undefined) {
        this.bookDesign['cuDesignLayout'] = {};
      }
      this.updateCULayoutPayload(this.currentCuForLayout, index);
    }

    /* istanbul ignore next */
    if (this.currentGsiForLayout) {
      for (let i = 0; i < this.selectedCuListForLayout?.length; i++) {
        let id = this.selectedCuListForLayout[i];
        /* istanbul ignore next */

        if (this.bookDesign && this.bookDesign?.cuDesignLayout?.[this.selectedCuListForLayout?.[i]] == undefined) {
          /* istanbul ignore next */
          this.bookDesign.cuDesignLayout[id] = [];
          this.bookDesign.cuDesignLayout[id][0] = {};
          this.bookDesign.cuDesignLayout[id][0].template = this.currentLayout.multiLayout[index].layout;
        }
        this.updateCULayoutPayload(id, index);
      }
    }

    /* istanbul ignore next */
    this.layoutFacade.fetachDesignSystemData(this.bookDesign);
    this.customLayoutData = {
      template: this.currentLayout,
      asserts: this.selectedAssert,
      background: this.selectedBackground,
      formColor: this.selectedFormColor,
    };
    this.layoutFacade.updateCustomLayout(this.customLayoutData);
  }

  updateCULayoutPayload(currentCuMasterId: number, index: number) {
    /* istanbul ignore next */
    this.bookDesign.cuDesignLayout[currentCuMasterId] = [
      {
        template: this.currentLayout.multiLayout[index].layout,
        asserts: this.selectedAssert,
        background: this.selectedBackground,
        formColor: this.selectedFormColor,
        outline: {
          color: this.outlineColor,
          size: +this.outlineSize,
          corner: this.outlineCorner,
        },
        dropShadow: {
          color: this.dropShadowColor,
          xAxis: +this.dropShadowXAxis,
          yAxis: +this.dropShadowYAxis,
          opacity: +this.dropShadowOpacity,
          blur: +this.dropShadowBlur,
        },
        backgroundBlur: {
          blur: this.backgroundBlurBlur,
          brightness: this.backgroundBlurBrightness,
          opacity: this.backgroundBlurOpacity,
        },
        font: {
          cuFont: {
            fontSize: this.changeUnitFontSize,
            color: this.changeUnitFontColor,
          },
          physicalLayerFont: {
            fontSize: this.EntityFontPhySize,
            color: this.entityFontPhyColor,
          },
          informationLayerFont: {
            fontSize: this.EntityFontInfoSize,
            color: this.entityFontInfoColor,
          },
          gsiTabs: {
            color: this.GSIFontColor,
          },
          attribute: {
            color: this.AttributeFontColor,
          },
        },
        actionLabel: JSON.stringify(this.actionLabelPayload),
      },
    ];
  }

  public onEventLog(event: string, data: any): void {
    switch (event) {
      case 'backgroundColorPickerClose':
        this.updateBackgroundColor(data);
        break;
      case 'formColorPickerClose':
        this.updateFormColor(data);
        break;
      case 'outLineColorPickerClose':
        this.updateOutlineBackgroundColor(data);
        break;
      case 'dropShadowColorPickerClose':
        this.updatedropShadowColor(data);
        break;
      case 'ChangeUnitFontColorPickerClose':
        this.changeCUFontColor(data);
        break;
      case 'entityFontColorPickerClose':
        this.updateEntityFontColor(data);
        break;
      case 'GSIFontColorPickerClose':
        this.updateGSIColor(data);
        break;
      case 'AttributeFontColorPickerClose':
        this.updateAttributeColor(data);
    }
  }

  getGsiIcons() {
    let ontologyId = undefined;
    this.layoutFacade.getGsiIcons(ontologyId, this.iconLimit, this.page);
    this.layoutFacade.getGsiIconsList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result?.data) {
        this.availableIcons = res.result.data;
      }
    });
  }

  saveDesign() {
    /* istanbul ignore next */
    if (this.layoutNameSelected) {
      // this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background.opacity = this.backgroundOpacity;
      this.saveEntityDesign();
      this.layoutFacade.saveDesign(this.bookDesign);
      this.layoutFacade.updateCurrentCuName('');
      this.layoutFacade.updateCustomTab('');
      /* istanbul ignore next */
      if (JSON.parse(sessionStorage?.bookData)?.isGSILevelDS == true) {
        sessionStorage.removeItem('bookData');
      }
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }

  saveEntityDesign() {
    this.layoutFacade.entityDesign$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      Object.entries(this.bookDesign.cuDesignLayout).forEach(([key, value]) => {
        /* istanbul ignore next */
        if (res?.[key]) {
          value[0].entityDesign = res[key];
        }
      });
    });
  }

  cancelDesign() {
    this.layoutFacade.updateCurrentCuName('');
    this.bookDesign = this.oldBookDesign;
    this.layoutFacade.updateCustomTab('');
  }

  getFileNameFromImgUrl(imgUrl: any) {
    return getFileNameFromImgUrl(imgUrl);
  }
  changeDropShadowBlur(event: any) {
    this.dropShadowBlur = event.value;
  }
  changedropShadowOpacity(event: any) {
    this.dropShadowOpacity = event.value;
  }

  dropShadowToggle(event: any) {
    this.dropShadowToggleButton = event.checked;
  }

  toggleBackgroundBlur(event: any) {
    this.toggleBackgroundBlurButton = event.checked;
  }

  saveOutlineToggle(event: any) {
    this.saveOutlineToggleButton = event.checked;
  }
  changeBackgroundBlurBlur(event: any) {
    this.backgroundBlurBlur = event.value;
  }
  changeBackgroundBlurBrightness(event: any) {
    this.backgroundBlurBrightness = event.value;
  }
  changeBackgroundBlurOpacity(event: any) {
    this.backgroundBlurOpacity = event.value;
  }
  // toggleChangeforFontButton : boolean = false;
  toggleChangeforFont(event: any, layerType: string) {
    /* istanbul ignore next */
    if (layerType == 'info') {
      this.EntityFontType = 'info';
      this.entityFontSize = this.EntityFontInfoSize;
      this.entityFontColor = this.entityFontInfoColor;
    } else {
      this.entityFontSize = this.EntityFontPhySize;
      this.entityFontColor = this.entityFontPhyColor;

      this.EntityFontType = 'physical';
    }
  }
  changeBackgroundOpacity(event: any) {
    this.backgroundOpacity = event.value;
    this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].background.opacity = this.backgroundOpacity;
    this.customLayoutData.background.opacity = this.backgroundOpacity;
    this.UpdateData();
  }
  saveoutline() {
    /* istanbul ignore next */
    if (this.saveOutlineToggleButton) {
      this.changeDesignToAll = 'outline';
      /* istanbul ignore next */
      if (this.currentGsiForLayout != '') {
        this.dialog.open(this.selectAllpopup);
      } else {
        this.changeDesignToCurrentCU();
      }
    } else {
      this.alertService.showToaster('Please enable Toggle Button to Apply', '', 'info');
    }
  }
  updateOutlineBackgroundColor(event: any) {
    this.outlineColor = event;
  }
  updatedropShadowColor(event: any) {
    this.dropShadowColor = event;
  }
  saveDropShadow() {
    /* istanbul ignore next */
    if (this.dropShadowToggleButton) {
      this.changeDesignToAll = 'dropShadow';
      /* istanbul ignore next */
      if (this.currentGsiForLayout != '') {
        this.dialog.open(this.selectAllpopup);
      } else {
        this.changeDesignToCurrentCU();
      }
    } else {
      this.alertService.showToaster('Please enable Toggle Button to Apply', '', 'info');
    }
  }
  saveBackgroundBlur() {
    /* istanbul ignore next */
    if (this.toggleBackgroundBlurButton) {
      this.changeDesignToAll = 'backgroundBlur';
      /* istanbul ignore next */
      if (this.currentGsiForLayout != '') {
        this.dialog.open(this.selectAllpopup);
      } else {
        this.changeDesignToCurrentCU();
      }
    } else {
      this.alertService.showToaster('Please enable Toggle Button to Apply', '', 'info');
    }
  }

  changeCUFontColor(event: any) {
    /* istanbul ignore next */
    if (event.value) {
      this.changeUnitFontColor = event.value;
    } else {
      this.changeUnitFontColor = event;
    }
  }

  saveFont() {
    this.changeDesignToAll = 'font';
    /* istanbul ignore next */
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
  }

  onPreviewClick(layout: any) {
    this.src = {
      mimeType: 'image/png',
      contentUrl: layout.templateImg,
      canDownload: false,
      fromDesignSystem: true,
      selectedLayout: layout,
    };
    let dialogRef = this.dialog.open(FileUploadPreviewComponent, {
      panelClass: 'designSystem-selectlayout',
      data: this.src,
    });
    /* istanbul ignore next */
    dialogRef.afterClosed().subscribe((res: any) => {
      // received data from dialog-component
      if (res?.layoutSelected) {
        this.templateChecked(res?.layoutSelected);
      }
    });
  }

  templateChecked(layout: any) {
    this.checkedtemplate = layout;
    this.showEnhancements = true;
    this.layoutFacade.checkedTemplateDatatoCustomLayoutSubject.next(this.checkedtemplate);
  }
  gotoTemplate() {
    this.showEnhancements = false;
    this.disableAssets = false;
  }

  cancelCustomDesign(customComparator: any) {
    /* istanbul ignore next */
    switch (customComparator) {
      case 'outline':
        if (this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout]?.[0]?.outline) {
          delete this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].outline;
        }
        delete this.customLayoutData.outline;
        this.UpdateData();
        break;
      case 'dropShadow':
        if (this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout]?.[0]?.dropShadow) {
          delete this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].dropShadow;
        }
        delete this.customLayoutData.dropShadow;
        this.UpdateData();
        break;
      case 'backgroundBlur':
        if (this.bookDesign?.cuDesignLayout?.[this.currentCuForLayout]?.[0]?.backgroundBlur) {
          delete this.bookDesign.cuDesignLayout[this.currentCuForLayout][0].backgroundBlur;
        }
        delete this.customLayoutData.backgroundBlur;
        this.UpdateData();
        break;
      case 'font':
        this.changeUnitFontSize = 32;
        this.EntityFontPhySize = 18;
        this.EntityFontInfoSize = 18;
        this.entityFontSize = 18;
        this.entityFontColor = '#000000';
        this.entityFontPhyColor = '#000000';
        this.entityFontInfoColor = '#000000';
        this.changeUnitFontColor = '#000000';
        this.GSIFontColor = '#000000';
        this.AttributeFontColor = '#000000';
        this.saveFont();
        break;
      case 'actionButton':
        this.actionLabelPayload = {
          Submit: {
            format: '',
            actionName: 'Submit',
            svgUrl: '',
            hoverSvgUrl: '',
            defaultButton: '#ffffff',
            hoverButton: '#ff657d',
            style: '',
            hoverText: '#000000',
            defaultText: '#000000',
            disableButton: false,
          },
          Cancel: {
            format: '',
            actionName: 'Cancel',
            svgUrl: '',
            hoverSvgUrl: '',
            defaultButton: '#ffffff',
            hoverButton: '#ffffff',
            style: '',
            hoverText: '#000000',
            defaultText: '#000000',
            disableButton: false,
          },
          Save: {
            format: '',
            actionName: 'Save',
            svgUrl: '',
            hoverSvgUrl: '',
            defaultButton: '#ffffff',
            hoverButton: '#ffffff',
            style: '',
            hoverText: '#000000',
            defaultText: '#000000',
            disableButton: false,
          },
          buttonAlignment: 'left',
        };
        this.alertService.showToaster('ActionButton configuration cancelled successfully', '', 'info');
    }
  }

  findExt(fileName: any) {
    /* istanbul ignore next*/
    if (fileName !== undefined) {
      let extArr = ['.png', '.PNG', '.mp4', '.jpg', '.JPEG', '.jpeg', '.gif', '.GIF'];
      let ext = extArr.filter((x) => {
        if (fileName.includes(x)) {
          return x;
        }
      });
      let splitData = fileName.split(ext[0]);
      if (splitData[1] == '') {
        return true;
      } else {
        return false;
      }
    }
  }

  detectHeaderCustomization() {
    this.layoutFacade.headerCustomization$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.isHeaderCustomization = true;
        this.layoutNameSelected = true;
        this.openAssertsModal();
      } else {
        this.isHeaderCustomization = false;
      }
    });
  }
  updateGSIColor(event: any) {
    /* istanbul ignore next */
    if (this.layoutNameSelected) {
      this.GSIFontColor = event;
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }
  updateAttributeColor(event: any) {
    if (this.layoutNameSelected) {
      this.AttributeFontColor = event;
    } else {
      this.alertService.showToaster('Please select Layout Type', '', 'info');
    }
  }

  actionSelected() {
    this.alertService.showToaster('actionLabel saved sucessfully', '', 'success');
    // this.changeunitFacade.saveDraftCu(this.changeUnit);
  }

  actionButtonRadios(radioType: String) {
    this.actionButtonFormat = radioType;
    this.actionLabelPayload[this.currentActionButton].format = this.actionButtonFormat;
    /* istanbul ignore next*/
    switch (radioType) {
      case 'icon':
        this.toggleChooseStyle = false;

        break;
      case 'button':
        this.toggleChooseStyle = true;
    }
  }

  selectButton(event: any) {
    /* istanbul ignore next*/
    if (event?.target?.innerText == 'Submit') {
      this.submitButton = 'Submit';
      this.actionName = this.currentActionButton = 'Submit';
    }
    /* istanbul ignore next*/
    if (event.target.innerText == 'Cancel') {
      this.cancelButton = 'Cancel';
      this.actionName = this.currentActionButton = 'Cancel';
    }
    /* istanbul ignore next*/
    if (event.target.innerText == 'Save') {
      this.saveButton = 'Save';
      this.actionName = this.currentActionButton = 'Save';
    }
    /* istanbul ignore next*/
    if (this.actionLabelPayload[this.currentActionButton].format != '') {
      this.actionButtonRadios(this.actionLabelPayload[this.currentActionButton].format);
    }

    // this.actionLabelPayload[this.currentActionButton].actionName = this.actionName;
    this.imgSrc = this.actionLabelPayload[this.currentActionButton]?.svgUrl || '';
    if (this.currentActionButton !== 'Submit') {
      this.disableButton = this.actionLabelPayload[this.currentActionButton].disableButton;
    }
    this.changeActionButtonColorOnToggle();
  }
  changeActionButtonColorOnToggle() {
    /* istanbul ignore next*/
    this.elRef?.nativeElement?.style?.setProperty(
      '--defaultButtonBg',
      this.actionLabelPayload[this.currentActionButton]?.defaultButton
    );
    /* istanbul ignore next*/
    this.elRef?.nativeElement?.style?.setProperty(
      '--hoverButtoBg',
      this.actionLabelPayload[this.currentActionButton]?.hoverButton
    );
    /* istanbul ignore next*/
    this.elRef?.nativeElement?.style?.setProperty(
      '--defaultTextColor',
      this.actionLabelPayload[this.currentActionButton]?.defaultText
    );
    /* istanbul ignore next*/
    this.elRef?.nativeElement?.style?.setProperty(
      '--hoverTextColor',
      this.actionLabelPayload[this.currentActionButton]?.hoverText
    );
  }
  actionNameChange(name: any) {
    this.actionName = name;
    this.actionLabelPayload[this.currentActionButton].actionName = this.actionName;
  }

  imgSrc: any = '';
  uploadIcon(event: any) {
    let newFormData = new FormData();
    let file = event?.target?.files[0];
    /* istanbul ignore next*/
    if (event?.target?.files && event?.target?.files[0]) {
      newFormData.append('file', file);
    }
    this.layoutFacade.uploadImage(newFormData);
    /* istanbul ignore next*/
    this.layoutFacade.iconUrlSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (this.svgUrl) {
        this.imgSrc = this.actionLabelPayload[this.currentActionButton].svgUrl = res?.contentUrl;
      }
      /* istanbul ignore next*/
      if (this.hoverSvgUrl) {
        this.actionLabelPayload[this.currentActionButton].hoverSvgUrl = res?.contentUrl;
      }
      this.actionLabelPayload[this.currentActionButton].actionName = this.actionName;
    });
  }

  displayHoverImg() {
    /* istanbul ignore next*/
    if (this.actionLabelPayload[this.currentActionButton]?.hoverSvgUrl) {
      this.imgSrc = this.actionLabelPayload[this.currentActionButton]?.hoverSvgUrl;
    } else {
      this.alertService.showToaster('hover image not uploaded', '', 'warning');
    }
  }
  displayNormalImg() {
    /* istanbul ignore next*/
    this.imgSrc = this.actionLabelPayload[this.currentActionButton]?.svgUrl;
  }

  changeOfState(event: any) {
    this.formatStateDefaultOrHover = event.target.innerText;
  }
  colorChange(event: any, state: any) {
    /* istanbul ignore next*/
    if (state == 'Button') {
      if (this.formatStateDefaultOrHover == 'Default') {
        this.actionLabelPayload[this.currentActionButton].defaultButton = event.target.value;
        if (this.actionLabelPayload) {
          this.elRef?.nativeElement?.style?.setProperty(
            '--defaultButtonBg',
            this.actionLabelPayload[this.currentActionButton]?.defaultButton
          );
        }
      }
      if (this.formatStateDefaultOrHover == 'Hover') {
        this.actionLabelPayload[this.currentActionButton].hoverButton = event.target.value;
        this.elRef?.nativeElement?.style?.setProperty(
          '--hoverButtoBg',
          this.actionLabelPayload[this.currentActionButton]?.hoverButton
        );
      }
    }
    /* istanbul ignore next*/
    if (state == 'Text') {
      if (this.formatStateDefaultOrHover == 'Default') {
        this.actionLabelPayload[this.currentActionButton].defaultText = event.target.value;
        this.elRef?.nativeElement?.style?.setProperty(
          '--defaultTextColor',
          this.actionLabelPayload[this.currentActionButton]?.defaultText
        );
      }
      if (this.formatStateDefaultOrHover == 'Hover') {
        this.actionLabelPayload[this.currentActionButton].hoverText = event.target.value;
        this.elRef?.nativeElement?.style?.setProperty(
          '--hoverTextColor',
          this.actionLabelPayload[this.currentActionButton]?.hoverText
        );
      }
    }
  }

  styleButtonRadios(text: any) {
    this.actionLabelPayload[this.currentActionButton].style = text;
  }

  svgUrl: boolean = true;
  hoverSvgUrl: boolean = false;
  toggleIconUpload(event: any) {
    /* istanbul ignore next */
    if (event.target.innerText == 'Primary') {
      this.svgUrl = true;
      this.hoverSvgUrl = false;
    } else if (event.target.innerText == 'Hover') {
      this.svgUrl = false;
      this.hoverSvgUrl = true;
    }
  }
  saveActionButton() {
    this.changeDesignToAll = 'actionButton';
    /* istanbul ignore next */
    if (this.currentGsiForLayout != '') {
      this.dialog.open(this.selectAllpopup);
    } else {
      this.changeDesignToCurrentCU();
    }
  }
  alignButtonRadios(radioType: String) {
    this.buttonAlignment = radioType;
    this.actionLabelPayload.buttonAlignment = radioType;
  }
}
