import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatorService, NodeGsiFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogData, NodeBetEditPopupComponent } from '../node-bet-edit-popup/node-bet-edit-popup.component';

@Component({
  selector: 'app-node-multiuse-popup',
  templateUrl: './node-multiuse-popup.component.html',
  styleUrls: ['./node-multiuse-popup.component.scss'],
})
export class NodeMultiusePopupComponent implements OnInit {
  betType: string;
  panelData: any;
  labels: any;
  private ngUnsubscribe = new Subject();
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData,
    private gsiFacadeService: NodeGsiFacadeService,
    public dialogRef: MatDialogRef<NodeBetEditPopupComponent>,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.panelData = this.dialog;
    this.betType = this.panelData.betType;
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  upgrade() {
    this.dialogRef.close(true);
  }
}
