import { CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewSolutionFacadeService } from '../create-solution/create-solution-facade.service';
import { TranslatorService } from '../translator/translator.service';
import { TransactionFacadeService } from '@common-services';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  unsubscribe$: Subject<any> = new Subject<any>();
  labels: any;
  isSubmit: boolean=false; 
  constructor(
    private router: Router,
    private newSolutionService: NewSolutionFacadeService,
    private translator: TranslatorService,
    public transactionFacadeService: TransactionFacadeService,
  ) {
    this.detectLanguageChange();
    this.detectSubmitting();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  /* istanbul ignore next */
  detectSubmitting() {
    this.transactionFacadeService.submitButtonEmit$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.isSubmit = true;
      }
    });
  }
  /* istanbul ignore next */
  checkDialogWarning(component: any) {
    if (component?.listData?.currentCU?.triggerCUDialogList?.length > 0 && !this.isSubmit) {
      let data = {
        ...component?.listData,
        triggerCuId: component?.listData?.currentCU.id,
        triggerCUDialogAction: 'BACK',
      };
      this.transactionFacadeService.getcustomizedDialogWarning(data).subscribe((res: any) => {
        if (res) {
          if (res && res?.result?.conditionEvaluationResult) {
            if (window.confirm(res?.result?.message)) {
              return true;
            }
          }
        }
      });
    }
    return false;
  }
  canActivate() {
    const TenantType = localStorage.getItem('TenantType');
    const environmentType = localStorage.getItem('environmentType');
    const envType = localStorage.getItem('envType');
    const env = environmentType ? environmentType : envType;
    //condition to not allow saastenant and subtenant users to create a solution //
    /* istanbul ignore else */
    if (TenantType == 'saas' || TenantType == 'subtenant' || env !== 'development') {
      this.router.navigateByUrl('/mylibrary-dashboard');
      return false;
    }
    return true;
  }
  /* istanbul ignore next */
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    this.newSolutionService.resetAlertPopUpState();
    if (component === null) return true;
    // if there are no pending changes, just allow deactivation; else confirm first
    return this.checkDialogWarning(component)
      ? true
      : component.canDeactivate()
      ? true
      : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      localStorage.getItem('TenantName') !== 'palmbeachstate' &&
        localStorage.getItem('TenantName') !== 'healthcaremx' &&
        localStorage.getItem('TenantName') !== 'healthcaremxstage' &&
        localStorage.getItem('credentials')
      ? confirm(
          this.labels
            ?.warning_Any_changes_made_will_be_lost_Press_Cancel_to_go_back_and_save_these_changes_or_OK_to_lose_these_changes
        )
      : true;
  }
}
