import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'template-dialog',
  templateUrl: 'template-dialog.component.html',
  styleUrls: ['template-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateDialog {
  constructor(public dialogRef: MatDialogRef<TemplateDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
