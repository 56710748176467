import { ComponentFactoryResolver, ComponentRef, Directive, Input, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDesign } from '@common-services';
import { loginMapper, signupMapper } from './login-signup.mapper';

@Directive({
  selector: '[loginTemplate]',
})
export class LoginSignupDirective {
  template: ComponentRef<any>;
  loginDesign: LoginDesign;
  identityProvidersList: any[] = [];
  page: string = 'login';

  currentPages: 'login' | 'signup' | 'forgotPassword' | '' | undefined = 'login';
  private hasView = false;

  @Input() set setLoginDesign(logindDesign: LoginDesign) {
    this.loginDesign = logindDesign;
    this.templateType = this.loginDesign.templateType;
    this.loadTemplate();

  }
  @Input() set setIdentityProvidersList(identityProvidersList: any[]) {
    this.identityProvidersList = identityProvidersList;
    this.loadTemplate();
  }
  mode: string = 'transaction';
  @Input() set setMode(mode: string) {
    this.mode = mode;
    this.loadTemplate();
  }
  templateType: string = '';

  constructor(public resolver: ComponentFactoryResolver, public containerRef: ViewContainerRef, private router: Router,) {
    this.currentPages = router.url.includes('login') ? 'login' : router.url.includes('SignUp') ? 'signup' : '';
  }
  @Input() set setLoginTemplate(page: 'login' | 'signup' | 'forgotPassword' | '') {
    this.currentPages = page;
    this.loadTemplate();
  }

  /*istanbul ignore next*/
  loadTemplate() {
    if (this.templateType.length) {
      if (this.template) {
        this.template.destroy();
      }
      if (this.currentPages === 'login' || this.currentPages === 'forgotPassword') {
        var factory = this.resolver.resolveComponentFactory(loginMapper[this.templateType]);
      }
      else if (this.currentPages === 'signup') {
        var factory = this.resolver.resolveComponentFactory(signupMapper[this.templateType]);
      }
      this.template = this.containerRef.createComponent(factory);
      if (this.loginDesign) {
        this.template.instance.loginDesign = this.loginDesign;
        this.template.instance.identityProvidersList = this.identityProvidersList;
        this.template.instance.mode = this.mode;
      }
      if (this.currentPages === 'forgotPassword') {
        this.template.instance.forgotPasswordScreen = true;
      } else {
        this.template.instance.forgotPasswordScreen = false;
      }
    }
    console.log(this.loginDesign);
  }
}
