import { RuleSetCondition, displayDateDefaultValue,IsJsonString } from '@common-services';
import { Buffer } from 'buffer';

interface ID {
  id: number;
  templateId: number;
}

export interface Ratings extends ID {
  series?: {
    borderColor: boolean | string;
  };
  type?: 'Radio' | 'CheckBox';
  count: boolean;
  data?: single[];
}

export const entityFlipSet: Set<string> = new Set([
  '5',
  '10',
  '149',
  '150',
  '154',
  '156',
  '158',
  '159',
  '160',
  '166',
  '167',
  '168',
  '170',
  '175',
  '176',
  '205',
  '211',
  '213',
  '216',
  '225',
  '227',
]);
export const RATINGS: Ratings[] = [
  {
    id: 33,
    templateId: 1,
    count: true,
    type: 'CheckBox',
    data: [
      {
        value: 5,
        count: 0,
      },
      {
        value: 4,
        count: 0,
      },
      {
        value: 3,
        count: 0,
      },
      {
        value: 2,
        count: 0,
      },
      {
        value: 1,
        count: 0,
      },
    ],
  },

  {
    id: 34,
    templateId: 2,
    series: {
      borderColor: false,
    },
    count: false,
    type: 'CheckBox',
    data: [
      {
        value: 5,
      },
      {
        value: 4,
      },
      {
        value: 3,
      },
      {
        value: 2,
      },
      {
        value: 1,
      },
    ],
  },
  {
    id: 35,
    templateId: 3,
    series: {
      borderColor: false,
    },
    count: false,
    type: 'Radio',
    data: [
      {
        value: 5,
      },
      {
        value: 4,
      },
      {
        value: 3,
      },
      {
        value: 2,
      },
      {
        value: 1,
      },
    ],
  },
  {
    id: 36,
    templateId: 4,
    series: {
      borderColor: 'color-add',
    },
    count: false,
    data: [
      {
        value: 5,
      },
      {
        value: 4,
      },
      {
        value: 3,
      },
      {
        value: 2,
      },
      {
        value: 1,
      },
    ],
  },
  {
    id: 43,
    templateId: 5,
    count: false,
    type: 'CheckBox',
    data: [
      {
        value: 5,
      },
      {
        value: 4,
      },
      {
        value: 3,
      },
      {
        value: 2,
      },
      {
        value: 1,
      },
    ],
  },
];
export interface CheckBox extends ID {
  dataType: 'Range' | 'Single';
  count: boolean;
  countDisplay?: {
    brackets: boolean;
    oppositeSides: boolean;
    shadowHistogram: boolean;
  };
  checkboxColor?: string;
  data: range[] | single[];
}

export const CHECK_BOX_RANGE: CheckBox[] = [
  {
    id: 4,
    templateId: 4,
    dataType: 'Range',
    count: true,
    countDisplay: {
      brackets: true,
      oppositeSides: false,
      shadowHistogram: false,
    },
    data: [
      {
        minValue: 0,
        maxValue: 20,
        count: 45242,
        rangeValues: [0, 20],
      },
      {
        minValue: 21,
        maxValue: 40,
        count: 23644,
        rangeValues: [20, 40],
      },
      {
        minValue: 41,
        maxValue: 60,
        count: 12464,
        rangeValues: [40, 60],
      },
      {
        minValue: 61,
        maxValue: 80,
        count: 9456,
        rangeValues: [60, 80],
      },
      {
        minValue: 81,
        maxValue: 100,
        count: 4675,
        rangeValues: [80, 100],
      },
    ],
  },
];

export const CHECK_BOX_SINGLE: CheckBox[] = [
  {
    id: 1,
    templateId: 1,
    dataType: 'Single',
    count: false,
    data: [
      {
        value: 1000,
      },
      {
        value: 2000,
      },
      {
        value: 3000,
      },
      {
        value: 4000,
      },
    ],
  },
  {
    id: 2,
    templateId: 2,
    dataType: 'Single',
    count: true,
    countDisplay: {
      brackets: true,
      oppositeSides: false,
      shadowHistogram: false,
    },
    data: [
      {
        value: 1000,
        count: 1234,
      },
      {
        value: 2000,
        count: 123,
      },
      {
        value: 3000,
        count: 134,
      },
      {
        value: 4000,
        count: 34,
      },
    ],
  },
  // {
  //   id: 3,
  //   templateId: 3,
  //   dataType: 'Single',
  //   count: true,
  //   countDisplay: {
  //     brackets: true,
  //     oppositeSides: false,
  //     shadowHistogram: false,
  //   },
  //   data: [{
  //     value: 1000,
  //   }],
  // },

  // {
  //   id: 5,
  //   templateId: 5,
  //   dataType: 'Range',
  //   count: false,
  //   data: [{
  //     minValue: 10,
  //     maxValue: 30000,
  //   }],
  // },
  // {
  //   id: 6,
  //   templateId: 6,
  //   dataType: 'Range',
  //   count: true,
  //   countDisplay: {
  //     brackets: true,
  //     oppositeSides: false,
  //     shadowHistogram: true,
  //   },
  //   data:[ {
  //     minValue: 10,
  //     maxValue: 30000,
  //   }],
  // },
  // {
  //   id: 7,
  //   templateId: 7,
  //   dataType: 'Single',
  //   count: false,
  //   checkboxColor: 'color-add',
  //   data:[ {
  //     value: 1000,
  //   }],
  // },
  // {
  //   id: 8,
  //   templateId: 8,
  //   dataType: 'Single',
  //   count: false,
  //   checkboxColor: 'color-add',
  //   data:[ {
  //     value: 1000,
  //   }],
  // },
];

export interface Slider extends ID {
  type: number;
  inputs: number;
  label: number;
  data: range | single;
}

export const SLIDER_RANGE: Slider[] = [
  {
    id: 9,
    templateId: 1,
    type: 2,
    inputs: 0,
    label: 2,
    data: {
      minValue: 10,
      maxValue: 30000,
      rangeValues: [0, 100],
    },
  },
  {
    id: 10,
    templateId: 2,
    type: 2,
    inputs: 2,
    label: 2,
    data: {
      minValue: 10,
      maxValue: 30000,
      rangeValues: [0, 100],
    },
  },
  {
    id: 11,
    templateId: 3,
    type: 2,
    inputs: 2,
    label: 0,
    data: {
      minValue: 10,
      maxValue: 30000,
      rangeValues: [0, 100],
    },
  },
];
export const SLIDER_SINGLE: Slider[] = [
  {
    id: 12,
    templateId: 4,
    type: 1,
    inputs: 0,
    label: 1,
    data: {
      minValue: 10,
      maxValue: 30000,
      rangeValues: 75,
    },
  },
  {
    id: 13,
    templateId: 5,
    type: 1,
    inputs: 1,
    label: 2,
    data: {
      minValue: 10,
      maxValue: 30000,
      rangeValues: 75,
    },
  },
];

export interface ChipSelection extends ID {
  dataType: 'Multi' | 'Single';
  selection: {
    backgroundcolor?: string;
    borderColor?: string;
    tick: boolean;
  };
  borderRadius: number;
  size: 'small' | 'medium' | 'large';
  data: range[] | single[];
}
export const CHIP_SELECTION_SINGLE: ChipSelection[] = [
  {
    id: 16,
    templateId: 3,
    dataType: 'Single',
    selection: {
      borderColor: 'color-add',
      tick: false,
    },
    borderRadius: 6,
    size: 'large',
    data: [
      {
        value: 1000,
        checked: true,
      },
    ],
  },
  {
    id: 14,
    templateId: 1,
    dataType: 'Single',
    selection: {
      backgroundcolor: 'color-add',
      tick: false,
    },
    borderRadius: 0,
    size: 'large',
    data: [
      {
        value: 2000,
        checked: true,
      },
    ],
  },
  {
    id: 17,
    templateId: 4,
    dataType: 'Single',
    selection: {
      backgroundcolor: 'color-add',
      tick: false,
    },
    borderRadius: 30,
    size: 'large',
    data: [
      {
        value: 3000,
        checked: false,
      },
    ],
  },
  // {
  //   id: 20,
  //   templateId: 7,
  //   dataType: 'Single',
  //   selection: {
  //     backgroundcolor: 'color-add',
  //     tick: false,
  //   },
  //   borderRadius: 0,
  //   size: 'small',
  //   data: [
  //     {
  //       value: 4000,
  //       checked: true,
  //     },
  //   ],
  // },
  {
    id: 21,
    templateId: 8,
    dataType: 'Single',
    selection: {
      borderColor: 'color-add',
      tick: false,
    },
    borderRadius: 0,
    size: 'small',
    data: [
      {
        value: 5000,
        checked: true,
      },
    ],
  },
  {
    id: 22,
    templateId: 9,
    dataType: 'Single',
    selection: {
      backgroundcolor: 'color-add',
      tick: false,
    },
    borderRadius: 4,
    size: 'small',
    data: [
      {
        value: 6000,
        checked: false,
      },
    ],
  },
  {
    id: 25,
    templateId: 12,
    dataType: 'Single',
    selection: {
      borderColor: 'color-add',
      tick: true,
    },
    borderRadius: 16,
    size: 'large',
    data: [
      {
        value: 7000,
        checked: false,
      },
    ],
  },
];
export const CHIP_SELECTION_RANGE: ChipSelection[] = [
  {
    id: 15,
    templateId: 2,
    dataType: 'Multi',
    selection: {
      backgroundcolor: 'color-add',
      tick: false,
    },
    borderRadius: 6,
    size: 'large',
    data: [
      {
        minValue: 0,
        maxValue: 1000,
        checked: false,
      },
      {
        minValue: 1000,
        maxValue: 25000,
        checked: false,
      },
      {
        minValue: 25000,
        maxValue: 50000,
        checked: false,
      },
    ],
  },
  {
    id: 18,
    templateId: 5,
    dataType: 'Multi',
    selection: {
      borderColor: 'color-add',
      tick: false,
    },
    borderRadius: 30,
    size: 'large',
    data: [
      {
        minValue: 500,
        maxValue: 1000,
        checked: true,
      },
      {
        minValue: 1000,
        maxValue: 25000,
        checked: true,
      },
      {
        minValue: 25000,
        maxValue: 50000,
        checked: true,
      },
    ],
  },
  {
    id: 19,
    templateId: 6,
    dataType: 'Multi',
    selection: {
      borderColor: 'color-add',
      tick: true,
    },
    borderRadius: 4,
    size: 'large',
    data: [
      {
        minValue: 1000,
        maxValue: 5000,
        checked: false,
      },
    ],
  },
  {
    id: 23,
    templateId: 10,
    dataType: 'Multi',
    selection: {
      borderColor: 'color-add',
      tick: false,
    },
    borderRadius: 4,
    size: 'small',
    data: [
      {
        minValue: 10,
        maxValue: 30000,
        checked: true,
      },
    ],
  },
  {
    id: 24,
    templateId: 11,
    dataType: 'Multi',
    selection: {
      backgroundcolor: 'color-add',
      tick: false,
    },
    borderRadius: 18,
    size: 'large',
    data: [
      {
        minValue: 10,
        maxValue: 30000,
        checked: true,
      },
    ],
  },
  // {
];
export interface Location extends ID {
  type: string;
  inputs: number;
  data?: any;
}
export const LOCATE: Location[] = [
  {
    id: 26,
    templateId: 1,
    type: 'Single Place',
    inputs: 1,
  },
  // {
  //   id: 27,
  //   templateId: 2,
  //   type: 'From-To',
  //   inputs: 2,
  // },
  {
    id: 28,
    templateId: 3,
    type: 'Search Template',
    inputs: 1,
  },
];
export interface Filterone extends ID {
  id: number;
  type: string;
}
export const CSTM_FILTER: Filterone[] = [
  {
    id: 44,
    templateId: 1,
    type: 'search Field',
  },
]
export interface Date1 extends ID {
  type: string;
  inputs?: number;
  data?: singleDate | rangeDate;
  time?: boolean;
}
export const DATES: Date1[] = [
  // {
  //   id: 29,
  //   templateId: 1,
  //   type: 'Start and End Date (With Calender)',
  //   inputs: 2,
  // },
  {
    id: 30,
    templateId: 2,
    type: 'Select Date (With Calender)',
    inputs: 1,
  },
];
export interface RadioButton extends ID {
  color?: string;
  data: range[] | single[];
}
export const DATE_SINGLE: Date1[] = [
  {
    id: 38,
    templateId: 1,
    type: 'Single',
    time: false,
    data: {
      value: new Date(),
    },
  },
  //
  // Date Time Support is not there.
];
export const DATE_RANGE: Date1[] = [
  {
    id: 37,
    templateId: 1,
    type: 'Range',
    time: false,
    data: {
      value: [new Date(), new Date()],
    },
  },
];
export const RADIO_BUTTON: RadioButton[] = [
  {
    id: 42,
    templateId: 1,
    data: [
      {
        value: 'property with offers',
      },
      {
        value: 'Brane Exclusive Properties',
      },
      {
        value: 'Listed by owner',
      },
      {
        value: 'BERA certified properties',
      },
    ],
  },
];
export interface attributeDetails {
  attributeName: string;
  attributeDisplayName?: string;
}

interface Range extends attributeDetails {}

interface sortCombination extends ID {
  values: sortCombinationValues[];
  saved: boolean;
  name: string;
}

interface sortCombinationValues extends ID {
  isChecked: boolean;
}
export interface singleDate {
  value: any;
}
export interface rangeDate {
  value: any[];
}
export interface dateAndCost extends ID {
  type: string;
  fields: number;
  data?: any;
}
export const DATE_AND_COST: dateAndCost[] = [
  {
    id: 31,
    templateId: 1,
    type: 'Template Name',
    fields: 3,
  },
  {
    id: 32,
    templateId: 2,
    type: 'Template Name',
    fields: 2,
  },
];
export interface CardRecord {
  entityName: string;
  isMore: boolean;
  recordData: IData;
  recordId: number;
}

export interface IData {
  attributeName: string;
  contentUrl: string;
  displayName: string;
  type: string;
  value: string;
}

export interface FilterDesignTemplate {
  filter: Filter;
  sort: Sort;
  search: Search;
  hiddenFilterRule: RuleSetCondition;
  searchFields: SearchFieldDetails;
  locationFilter: LocationFilter;
  export: ExportConfig;
  // layout: Layout;
  urlBindingData: UrlBindingData;
}

export interface UrlBindingData {
  gsiId: number;
  masterId: number;
  attributeId: number;
  attributeName: string;
  attributeColor?: string;
  isConfigName?: boolean;
}

export interface Layout {
  horizontalLayout: attributeLayoutDetails[];
  verticalLayout: attributeLayoutDetails[];
  templateType: number;
  style: LayoutStyle;
}

export interface LayoutStyle {
  backgroundColor: string;
  labelColor: string;
  labelFontSize: string;
  placeHolderColor: string;
  placeHolderFontSize: string;
}

export interface attributeLayoutDetails {
  name: string;
  id: number;
  displayName?: string;
  data?: any;
  defaultValue?: any;
  divisions?: any;
}
export interface FilterData extends attributeDetails {
  filterTemplate: number;
  defaultValue?: any;
  divisions?: any;
}

export interface Filter {
  attributeDetails: FilterData[];
  filterConfiguration: FilterConfiguration;
}

export interface Sort {
  individual: IndividualSort;
  combination: Combination[];
}

export interface IndividualSort {
  attributeDetails: attributeDetails[];
  sortConfigurations: SortConfiguration;
}

export interface SortConfiguration {
  position: string;
  expandView: boolean;
  uploadIcon: string;  
  uploadHoverIcon: string;
}

export interface SearchConfiguration {
  position: string;
  showHeaderSearch: boolean;
  placeHolderText: string;
  showSavedSearch?: boolean;
  uploadIcon: string;  
  uploadHoverIcon: string;
  isSearching?: boolean;
}

export interface SearchFieldsConfiguration {
  position: string;
  showFilters: boolean;
  showAggregation: boolean;
  fieldsWrap: boolean;
  saveUserFilters: boolean;
  accordionView: boolean;
  additionalSearchFields: boolean;
  alignment: string;
  saveSharableFilters: boolean;
}

export interface FilterConfiguration {
  position: string;
  sidePanelBehaviour: string;
  toggleView: boolean;
  showFiltered: boolean;
  showAggregation: boolean;
  expandView: boolean;
  numberOfFields?: any;
  uploadIcon: string;
  uploadHoverIcon: string;
}

export interface Combination {
  combinationName: string;
  filterTemplate: number;
  attributes: attributeDetails[];
}

export interface Search {
  attributeDetails: attributeDetails[];
  filterTemplate: number;
  searchConfiguration: SearchConfiguration;
}

export interface SearchFields extends attributeDetails {
  selectedRefValue: RefAttrValue;
  selectedRefAttrValue: RefAttrValue;
  uiElement: string;
  filterTemplate: number;
  selectedNestedForDate: SelectedNestedAttribute;
}

interface SelectedNestedAttribute {
  selectNestedAttribute: string[];
  selectNestedAttributeFunction: string;
}

export interface SearchFieldDetails {
  attributeDetails: SearchFields[];
  searchFieldsConfiguration: SearchFieldsConfiguration;
}

export interface RefAttrValue {
  name: string;
  displayName: string;
  id: number;
}

export interface LocationFilterDetails extends attributeDetails {
  radius: number;
}

export interface LocationFilter {
  attributeDetails: LocationFilterDetails[];
}

export interface range {
  minValue?: number;
  maxValue?: number;
  rangeValues?: number[] | number;
  count?: number;
  checked?: boolean;
}
export interface single {
  value: number | string;
  count?: number;
  checked?: boolean;
}

export interface ToggleButtons extends ID {
  type: number;
  data: range[] | single[];
}

export const TOGGLE_BUTTONS: ToggleButtons[] = [
  {
    id: 40,
    templateId: 1,
    type: 1,
    data: [
      {
        value: 'property with offers',
        checked: false,
      },
      {
        value: 'Brane Exclusive Properties',
        checked: false,
      },
      {
        value: 'Listed by owner',
        checked: false,
      },
      {
        value: 'BERA certified properties',
        checked: true,
      },
    ],
  },
  {
    id: 41,
    templateId: 2,
    type: 2,
    data: [
      {
        value: 'property with offers',
        checked: false,
      },
      {
        value: 'Brane Exclusive Properties',
        checked: false,
      },
      {
        value: 'Listed by owner',
        checked: false,
      },
      {
        value: 'BERA certified properties',
        checked: true,
      },
    ],
  },
];
export const templateMapper = {
  sortBy: 'SORT_BY',
  1: 'CHECK_BOX_SINGLE',
  2: 'CHECK_BOX_SINGLE',
  3: 'CHECK_BOX_SINGLE',
  4: 'CHECK_BOX_RANGE',
  5: 'CHECK_BOX_SINGLE',
  6: 'CHECK_BOX_SINGLE',
  7: 'CHECK_BOX_SINGLE',
  8: 'CHECK_BOX_SINGLE',
  9: 'SLIDER_RANGE',
  10: 'SLIDER_RANGE',
  11: 'SLIDER_RANGE',
  12: 'SLIDER_SINGLE',
  13: 'SLIDER_SINGLE',
  14: 'CHIP_SELECTION_SINGLE',
  15: 'CHIP_SELECTION_RANGE',
  16: 'CHIP_SELECTION_SINGLE',
  17: 'CHIP_SELECTION_SINGLE',
  18: 'CHIP_SELECTION_RANGE',
  19: 'CHIP_SELECTION_RANGE',
  20: 'CHIP_SELECTION_SINGLE',
  21: 'CHIP_SELECTION_SINGLE',
  22: 'CHIP_SELECTION_SINGLE',
  23: 'CHIP_SELECTION_RANGE',
  24: 'CHIP_SELECTION_RANGE',
  25: 'CHIP_SELECTION_SINGLE',
  26: 'LOCATE',
  27: 'LOCATE',
  28: 'LOCATE',
  29: 'DATES',
  30: 'DATES',
  31: 'DATE_AND_COST',
  32: 'DATE_AND_COST',
  33: 'RATINGS',
  34: 'RATINGS',
  35: 'RATINGS',
  36: 'RATINGS',
  37: 'DATE_RANGE',
  38: 'DATE_SINGLE',
  39: 'DATE_SINGLE',
  40: 'TOGGLE_BUTTONS',
  41: 'TOGGLE_BUTTONS',
  42: 'RADIO_BUTTON',
  43: 'RATINGS',
  44: 'CSTM_FILTER'
};

// Present Facet
// IN for Single Value,
// RANGE for Sliding Range Values
// HISTOGRAM for Selecting Multiple Range Values
export const facetMapper = {
  1: 'IN',
  2: 'IN',
  3: 'IN',
  4: 'HISTOGRAM_PERCENTAGE',
  5: 'IN',
  6: 'IN',
  7: 'IN',
  8: 'IN',
  9: 'RANGE',
  10: 'RANGE',
  11: 'RANGE',
  12: 'RANGE_SINGLE',
  13: 'RANGE_SINGLE',
  14: 'IN',
  15: 'HISTOGRAM',
  16: 'IN',
  17: 'IN',
  18: 'HISTOGRAM',
  19: 'HISTOGRAM',
  20: 'IN',
  21: 'IN',
  22: 'IN',
  23: 'HISTOGRAM',
  24: 'HISTOGRAM',
  25: 'IN',
  26: 'LOCATE',
  27: 'LOCATE',
  28: 'LOCATE',
  29: 'DATES',
  30: 'DATES',
  31: 'DATE_AND_COST',
  32: 'DATE_AND_COST',
  33: 'IN',
  34: 'IN',
  35: 'IN',
  36: 'IN',
  37: 'DATE_RANGE',
  38: 'DATE_SINGLE',
  39: 'DATE_SINGLE',
  40: 'IN',
  41: 'IN',
  42: 'IN',
  43: 'IN',
};

export interface ExportConfig {
  pdf: boolean;
  excel: boolean;
  csv: boolean;
}

const filterValues = {
  checked: false,
  top: {
    position: false,
    checked: false,
  },
  header: {
    position: true,
    checked: false,
  },
  side: {
    position: true,
    checked: true,
  },
};

const filterTabValues = [
  {
    displayName: 'Side Panel Behaviour',
    actualName: 'sidePanelBehaviour',
    values: [
      {
        actualValue: 'fixed',
        displayValue: 'Fixed',
        checked: true,
      },
      {
        actualValue: 'collapsible',
        displayValue: 'Collapsible',
        checked: false,
      },
    ],
  },
];
const filterCheckBoxValues = [
  {
    actualValue: 'toggleView',
    displayValue: 'Toggle View',
    checked: false,
  },
  {
    actualValue: 'showFiltered',
    displayValue: 'Show Filtered',
    checked: false,
  },
  {
    actualValue: 'showAggregation',
    displayValue: 'Show Aggregation',
    checked: false,
  },
  {
    actualValue: 'expandView',
    displayValue: 'Expand View',
    checked: false,
  },
];
const filterInputValues = [
  {
    actualValue: 'numberOfFields',
    displayValue: 'Number of Fields',
    value: '',
    type: 'number',
  },
];
const sortValues = {
  checked: false,
  top: {
    position: true,
    checked: true,
  },
  header: {
    position: true,
    checked: false,
  },
  side: {
    position: true,
    checked: false,
  },
};
const sortCheckBoxValues = [
  {
    actualValue: 'expandView',
    displayValue: 'Expand View',
    checked: false,
  },
];
const searchFieldsValues = {
  checked: false,
  top: {
    position: false,
    checked: false,
  },
  header: {
    position: false,
    checked: false,
  },
  side: {
    position: false,
    checked: false,
  },
};
const searchFieldsCheckBoxValues = [
  {
    actualValue: 'showFilters',
    displayValue: 'Show Filters',
    checked: false,
  },
  {
    actualValue: 'showAggregation',
    displayValue: 'Show Aggregation',
    checked: false,
  },
  {
    actualValue: 'fieldsWrap',
    displayValue: 'Fields Wrap',
    checked: false,
  },
];
const searchFieldsToggleValues = [
  {
    actualValue: 'additionalSearchFields',
    displayValue: 'Additional Search Fields',
    checked: false,
  },
  {
    actualValue: 'saveUserFilters',
    displayValue: 'Save User Filters',
    checked: false,
  },
  {
    actualValue: 'saveSharableFilters',
    displayValue: 'Sharable Filters',
    checked: false,
  },
  {
    actualValue: 'accordionView',
    displayValue: 'Accordion View',
    checked: false,
  },
];
const searchFieldsStyles = [
  {
    actualValue: 'alignment',
    displayValue: 'Alignment',
    value: 'left',
  },
];
const searchValues = {
  checked: false,
  top: {
    position: true,
    checked: true,
  },
  header: {
    position: false,
    checked: false,
  },
  side: {
    position: true,
    checked: false,
  },
};
const searchToggleValues = [
  {
    actualValue: 'showHeaderSearch',
    displayValue: 'Show Header Search',
    checked: false,
  },
  {
    actualValue: 'showSavedSearch',
    displayValue: 'Show Saved Search',
    checked: false,
  },
];
const apiVersionToggleValues = [
  {
    actualValue: 1,
    displayValue: 'Version 1',
    checked: true,
  },
  {
    actualValue: 2,
    displayValue: 'Version 2',
    checked: false,
  },
];
const searchInputValues = [
  {
    actualValue: 'placeHolderText',
    displayValue: 'Place Holder Text',
    value: '',
    type: 'text',
  },
];
const locationFilterValues = {
  checked: false,
  top: {
    position: false,
    checked: false,
  },
  header: {
    position: false,
    checked: false,
  },
  side: {
    position: false,
    checked: false,
  },
};
const hiddenFilterValues = {
  checked: false,
  top: {
    position: false,
    checked: false,
  },
  header: {
    position: false,
    checked: false,
  },
  side: {
    position: false,
    checked: false,
  },
};
const urlBindingValues = {
  checked: false,
  top: {
    position: false,
    checked: false,
  },
  header: {
    position: false,
    checked: false,
  },
  side: {
    position: false,
    checked: false,
  },
};
const exportValues = {
  checked: false,
  top: {
    position: false,
    checked: false,
  },
  header: {
    position: false,
    checked: false,
  },
  side: {
    position: false,
    checked: false,
  },
};
const exportCheckBoxValues = [
  {
    actualValue: 'pdf',
    displayValue: 'PDF',
    checked: false,
  },
  {
    actualValue: 'excel',
    displayValue: 'Excel',
    checked: false,
  },
  {
    actualValue: 'csv',
    displayValue: 'CSV',
    checked: false,
  },
];

const iconUrlValues = [
  { 
    checked: false,
    actualValue: 'icon',
    displayValue: 'Icon',
    isDefault: true,
    value: '../../../../../assets/img/general-entity/Advance-features/heart.svg',
  },
 {
  checked: false,
  actualValue: 'hoverIcon',
  displayValue: 'Hover Icon',
  isDefault: true,
  value: '../../../../../assets/img/general-entity/Advance-features/heart.svg'
}
]
 

export const advancedFeatures = {
  filter: {
    ...filterValues,
    tabValues: filterTabValues,
    checkBoxValues: filterCheckBoxValues,
    icon : iconUrlValues
  },
  sort: {
    ...sortValues,
    checkBoxValues: sortCheckBoxValues,
    icon : iconUrlValues
  },
  searchFields: {
    ...searchFieldsValues,
    checkBoxValues: searchFieldsCheckBoxValues,
    toggleValues: searchFieldsToggleValues,
    styles: searchFieldsStyles,

  },
  search: {
    ...searchValues,
    toggleValues: searchToggleValues,
    inputValues: searchInputValues,   
    icon : iconUrlValues
  },
  locationFilter: {
    ...locationFilterValues,
  },
  hiddenFilter: {
    ...hiddenFilterValues,
  },
  urlBinding: {
    ...urlBindingValues,
  },
  export: {
    ...exportValues,
    checkBoxValues: exportCheckBoxValues,
  },
  chatBot: {
    checked: false,
  },
  cardVersion: {
    checked: true,
    toggleValues: apiVersionToggleValues,
  },
};
const chooseColumns = {
  checked: false,
};
export const tableAdvancedFeatures = {
  filter: {
    checked: false,
  },
  sort: {
    ...sortValues,
  },
  searchFields: {
    ...searchFieldsValues,
    styles: searchFieldsStyles,
    checkBoxValues: searchFieldsCheckBoxValues,
  },
  search: {
    ...searchValues,
  },
  export: {
    ...exportValues,
    checkBoxValues: exportCheckBoxValues,
  },
  chooseColumns: {
    ...chooseColumns,
  },
  import: {
    checked: false,
  },
  filtersConfig: {
    hover: false,
    nextRow: false,
  },
};

export const setFontColor = (node: HTMLElement, otherStyles: any[], index: number, style: string) => {
  switch (style) {
    case 'font-size':
      node.style.fontSize = otherStyles[index][style];
      break;
    case 'font-style':
      node.style.fontStyle = otherStyles[index][style];
      break;
    case 'font-weight':
      node.style.fontWeight = otherStyles[index][style];
      break;
    case 'font-family':
      node.style.fontFamily = otherStyles[index][style];
      break;
    case 'text-align':
      node.style.textAlign = otherStyles[index][style];
      break;
    default:
      break;
  }
};

export interface ITxnNslAttribute {
  name: string;
  nslAttributeID: number;
  values: string[];
}

export interface IRecordDetail {
  id: number;
  ownerId: number;
  orgUnitId: number;
  generalEntityMasterId: number;
  generalEntityId: number;
  txnNslAttribute: ITxnNslAttribute[];
}

export type RelatedValues = {
  [x: string]: string[];
};

export type RelatedRecords = {
  [x: string]: Record[];
};

export type Record = {
  value: string;
  recordIds: string[];
  selected: boolean;
  disabled: boolean;
  selectableRecordIds: string[];
};

export interface IRelatedRecords {
  [x: string]: RelatedValues[];
}

export interface IRelatedRecordsAndView {
  recordDetail: IRecordDetail;
  relatedRecords: IRelatedRecords;
  viewDesign: any;
}

export interface IAttributeType {
  name: string;
  uiElement: string;
  uiDataType: string;
}

export interface IRelatedRecordsValues {
  recordId: string;
  value: string;
}

export interface IRelatedTxnGeneralEntity {
  displayName: string;
  generalEntityID: number;
  id: number;
  name: string;
  transEntityRecords: { txnNslAttribute: ITxnNslAttribute[] }[];
}

export interface IRelatedAddToCart {
  txnGeneralEntity: IRelatedTxnGeneralEntity;
  cartView: any;
}

export function appendValue(value: any, type: string,timeFormat: any,customNoValue?:string) {
  let finalValue = '';
  type = type ? type.toLowerCase() :'';
  if (type === 'datetime' || type === 'currentdatetime') {
    finalValue = formatDateTime(value?.values[0], timeFormat);
  } else if (type == 'dateonly') {
    finalValue = displayDateDefaultValue(value?.values[0], 'date', 'yyyy-MM-dd', '12-hr', 'yyyy-MM');
  } else if (type === 'date' || type === 'currentdate') {
    finalValue = formatDate(value?.values[0]);
  } else if (type == 'image') {
    if (IsJsonString(value?.values[0])) {
      finalValue = JSON.parse(value?.values[0]).contentUrl;
    } else {
      finalValue = value?.values[0];
      if (!finalValue) {
        finalValue = '';
      }
    }
  } else {
    finalValue = value?.values?.[0] ? value?.values?.join() : getNoValue(type,customNoValue);
  }
  return finalValue;
}
export function getNoValue(type: string,value: string) {
  let val = '';
  if (value ||value == '')
    val = value;
  else val = type == 'number' || type == 'decimalnumber' || type == 'mobilenumber' ? '0' : 'none';
  return val;
}

export function formatImage(value: any) {
  try {
    const parsedData = JSON.parse(value);
    if (parsedData && parsedData?.contentUrl) {
      return parsedData?.contentUrl;
    }
  } catch (error) {
    return value;
  }
}
export function formatDateTime(dt: any,timeFormat: any): any {
  /* istanbul ignore else */
  if (dt) {
    let x = dt.split('T');
    x[0] = new Date(dt);
    let hrs = x[0].getHours();
    hrs = hrs < 10 ? '0' + hrs : '' + hrs;
    let mins = x[0].getMinutes();
    mins = mins < 10 ? '0' + mins : '' + mins;
    let time = '';
      if (timeFormat === '24-hr') {
        time = hrs + ':' + mins;
      } else {
        const meridien = hrs < 12 ? 'AM' : 'PM';
        if (hrs === '00') {
          hrs = '12';
        } else if (hrs > 12) {
          hrs = hrs - 12;
        }
        time = hrs + ':' + mins + meridien;
    }
    let date = displayDateDefaultValue(x[0], 'date', 'MM/dd/yyyy', timeFormat, 'yyyy-MM');
    return date + ' ' + time;
  } else return 'none';
}

/**
 * The function will convert the date to MM/dd/yyyy format and time to HH:MM AM OR PM format
 *
 */

export function formatDate(dt: any): any {
  /* istanbul ignore else */
  if (dt) {
    let x = dt.split('T');
    x[0] = new Date(dt);
    let date = displayDateDefaultValue(x[0], 'date', 'MM/dd/yyyy', '12-hr', 'yyyy-MM');
    return date;
  } else return 'none';
}

export type Star = {
  width: string;
};

export type AddComment = {
  recordId: number;
  userName: string;
  productRating: number;
  reviewDescription: string;
  featureRatings: FeatureRating[];
  updatedAt: string;
  imageURL: string;
};

export type FeatureRating = {
  featureName: string;
  featureRating: number;
};

export type RatingStats = {
  averageProductRating: number;
  userCount: number;
  fiveStarCount: number;
  fourStarCount: number;
  threeStarCount: number;
  twoStarCount: number;
  oneStarCount: number;
  averageFeatureRating: FeatureRating[];
};

export type RatingPercentages = {
  oneStarPercentage: string;
  twoStarPercentage: string;
  threeStarPercentage: string;
  fourStarPercentage: string;
  fiveStarPercentage: string;
};

export type SortOptionsReviews = {
  displayValue: string;
  actualValue: string;
  sortOrder: string;
};
export function encodeString(s: string) {
  let encoded = [];
  while (s.length > 0) {
    let mid = Math.floor(s.length / 2);
    encoded.push(s[mid]);
    s = s.slice(0, mid) + s.slice(mid + 1);
  }
  return Buffer.from(encoded.join('')).toString('base64');
}

export function decodeString(str: string): string {
  str = Buffer.from(str, 'base64').toString('ascii');
  var len = str.length;
  var retStr = '';
  while (len > 0) {
    if (len % 2 === 0) {
      retStr = retStr + str.slice(0, 1);
    } else {
      retStr = str.slice(0, 1) + retStr;
    }
    str = str.slice(1, len);
    len--;
  }

  return retStr;
}

export type EQLUser = {
  name: string;
  value: string;
};
