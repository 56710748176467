import { NgModule } from '@angular/core';
import { VideoComponent } from './video.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [VideoComponent],
  imports: [ControlsMaterialModule],
  exports: [VideoComponent],
})
export class VideoModule {
  static entry = {
    video: VideoComponent,
  };
}
