import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ContentChildren, EventEmitter, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { AlertService, EntityBoardEndpointService, GsiFacadeService, TransactionFacadeService } from '@common-services';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-core-templates',
  templateUrl: './core-templates.component.html',
  styleUrls: ['./core-templates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoreTemplatesComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService
  ) {
    super(    
      alertService
    );
  }
}
