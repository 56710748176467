<ng-container *ngIf="gsiData">
  <div
    class="titleClass position-none"
    [ngClass]="{ 'd-inline-block': gsiData?.isEditable === false, 'w-100 d-block': gsiData?.isEditable === true }"
  >
    <ng-container *ngIf="gsiData?.isEditable != true">
      <span (click)="getGSIInfo(gsiData)" class="cursorPointer position-relative">
        <span
          *ngIf="gsiData?.sentenceTags?.information && showInfoLayer"
          class="position-absolute cursor-pointer info-btn"
        >
          <div class="popover-main pop-alert" *ngIf="gsiData?.showInfo">
            <a href="javascript:;" class="info-close" (click)="hideGsiInfo($event)"> </a>
            <p>
              <span *ngFor="let sentence of gsiData?.sentenceTags.information">
                <span [ngClass]="{ 'font-weight-bold': sentence.tag !== 'nlg' }">{{ sentence.token }} </span>
              </span>
            </p>
          </div>
          <a href="javascript:;" class="information-icon" (click)="showGsiInfo($event)">i</a>
        </span>
        <span
          *ngIf="
            !gsiData.sentenceTags ||
            gsiData?.sentenceTags?.physical?.length === 0 ||
            (gsiData?.sentenceTags && gsiData?.sentenceTags?.physical === undefined)
          "
          class="font-weight-bold gsi-name"
          (click)="expandDiv()"
        >
          {{ gsiData?.name }}
        </span>
        <span
          *ngIf="gsiData?.sentenceTags && gsiData?.sentenceTags?.physical && gsiData?.sentenceTags?.physical.length > 0"
          class="gsi-name"
          (click)="expandDiv()"
        >
          <span *ngFor="let sentence of gsiData?.sentenceTags?.physical">
            <span class="sentence-text" [ngClass]="{ 'font-weight-bold': sentence.tag !== 'nlg' }"
              >{{ sentence.token }}
            </span>
          </span>
        </span>
      </span>
    </ng-container>

    <ng-container *ngIf="gsiData?.isEditable === true">
      <div class="fatTextElement gsi" *ngIf="!gsiData?.showTagsView">
        <div class="closeSequential" (click)="gsiData['isEditable'] = false">
          <img alt="closeSequential" src="../../../assets/img/nsl-parallel/close.svg" />
        </div>
        <a href="javascript:;" class="wish-text cu-steps d-block gsi-wish"> {{ labels?.Wish }}</a>
        <div
          class="gsiform gsiTitle fw-bolder gsilink isSelected creatingField position-relative"
          [ngClass]="{ 'gsi-primary': isCuInputFocus }"
        >
          <input
            class="input-gsi"
            type="text"
            [(ngModel)]="gsiSentence"
            name="sentence"
            autocomplete="off"
            autofocus="off"
            (ngModelChange)="sentenceSuggestion.next($event)"
            (click)="outsideGsiClick()"
            (focus)="isCuInputFocus = true"
            (focusout)="isCuInputFocus = false"
          />
          <ul class="show labels-modal cu-moreLinks edit-options">
            <li>
              <a href="javascript:;" class="cu-more-icon" (click)="openGsiPopUp()">
                <i class="bi bi-three-dots-vertical"></i>
              </a>
              <ul class="more-modal" *ngIf="gsiThreeDotFlag" (clickOutside)="closeGsiPopup()">
                <li (click)="editGsi('edit')">
                  <a href="javascript:;">
                    <span class="edit"></span>
                    {{ labels?.Edit }}</a
                  >
                </li>
                <li (click)="editGsi('IF')">
                  <a href="javascript:;">
                    <span class="adding"></span>
                    {{ labels?.Information_Layer }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-n5 position-relative gsi" *ngIf="gsiData.showTagsView">
        <a href="javascript:;" class="layertypelink cu-steps fixed-cu-input d-block gsi-wish">{{ labels?.Wish }}</a>
        <node-display-tags
          [_index]="gsiData"
          [layerSentence]="gsiData.sentenceTags?.physical"
          [_layerType]="'physical'"
          [currentComp]="'GSI'"
          [gsiSentence]="gsiSentence"
          (onDeleteTag)="handleOnGsiDeleteTag($event)"
        ></node-display-tags>
        <ul class="show labels-modal cu-moreLinks edit-options">
          <li>
            <a href="javascript:;" class="cu-more-icon" (click)="openGsiPopUp()">
              <i class="bi bi-three-dots-vertical"></i>
            </a>
            <ul class="more-modal" *ngIf="gsiThreeDotFlag" (clickOutside)="closeGsiPopup()">
              <li (click)="editGsi('edit')">
                <a href="javascript:;">
                  <span class="edit"></span>
                  {{ labels?.Edit }}</a
                >
              </li>
              <li (click)="editGsi('IF')">
                <a href="javascript:;">
                  <span class="adding"></span>
                  {{ labels?.Information_Layer }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="row-gsi gsi">
        <div class="text-right bottom-act-btns">
          <button
            [hidden]="gsiData?.showTagsView || gsiSentence == ''"
            class="btn btn-light btn-validate"
            (click)="validateGsi(gsiSentence)"
          >
            {{ labels?.Validate }}
          </button>
          <button class="btn btn-light" [disabled]="!gsiData?.showTagsView" (click)="hideDisplayTags()">
            {{ labels?.Next }}
          </button>
        </div>
      </div>
      <!--- Information cu-->
      <ng-container *ngIf="showInfoLayer">
        <div class="row">
          <div class="col text-right" *ngIf="!gsiData.showInfoTagsView">
            <div class="fatTextElement gsi">
              <div class="gsiTitle fw-bolder gsilink isSelected creatingField nested-control">
                <input
                  type="text"
                  class="form-control w-100 info-input info-input-font"
                  placeholder="Information Layer"
                  [(ngModel)]="gsiInfoSentence"
                  name="gsiInfoSentence"
                  autocomplete="off"
                  autofocus="off"
                />
              </div>
            </div>
          </div>
          <div class="mt-n5 position-relative gsi row-correction" *ngIf="gsiData.showInfoTagsView">
            <node-display-tags
              [_index]="gsiData"
              [layerSentence]="gsiData.sentenceTags?.information"
              [_layerType]="'information'"
              [currentComp]="'GSI'"
              (onEditTagsView)="handleGsiInfoEditTagsView($event)"
              (onDeleteTag)="handleOnGsiDeleteTag($event)"
              (onDeleteTagInfo)="handleOnGsiDeleteTagInfo($event)"
              (OnInfoEmpty)="handleGsiInfoLyer($event)"
            ></node-display-tags>
            <ul class="show labels-modal cu-moreLinks edit-options sm-drop-list">
              <li>
                <a href="javascript:;" class="cu-more-icon" (click)="openIFPopUp()">
                  <i class="bi bi-three-dots-vertical"></i>
                </a>
                <ul class="more-modal" *ngIf="clickThreeDots" (clickOutside)="closeIFPopup()">
                  <li (click)="editGsiInfoLayer()">
                    <a href="javascript:;">
                      <span class="edit"></span>
                      {{ labels?.Edit }}</a
                    >
                  </li>
                  <li (click)="deleteGsiInfoLayer()">
                    <a href="javascript:;">
                      <span class="delete"></span>
                      {{ labels?.Delete }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="fatTextElement gsi">
          <div class="row">
            <div class="col text-right bottom-act-btns">
              <button
                class="btn btn-light btn-validate"
                (click)="validateGsiIL()"
                [hidden]="gsiInfoSentence == '' || gsiData.showInfoTagsView"
              >
                {{ labels?.Validate }}
              </button>
              <button class="btn btn-light" [hidden]="!gsiData?.showTagsView" (click)="hideDisplayTags()">
                {{ labels?.Next }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
