let transform = require('node-json-transform').transform;

export function entityCardMapper(data: any) {
  var map = {
    item: {
      apiVersion: 'apiVersion',
      cardName: 'cardName',
      cartView: 'cartView',
      childView: 'childView',
      expandedView: 'expandedView',
      enableAnalytics: 'enableAnalytics',
      filterBy: 'filters',
      gsiList: 'gsiList',
      id: 'id',
      locationFilter: 'locationFilter',
      range: 'range',
      search: 'search',
      searchFields: 'searchFields',
      filters: 'filters',
      joinCriteria: 'joinCriteria',
      joinedEntities: 'joinedEntities',
      primaryEntity: 'primaryEntity',
      roleId: 'roleId',
      sort: 'sort',
      sortBy: 'sort.sortBy',
      defaultFilter: 'defaultFilter',
      cardConfigurationName: 'configurationName',
      templateMappingFields: {
        attr: 'childView.style.attr',
        columnStyles: 'childView.style.attr',
        isNestedRow1: 'childView.style.nestedRow',
        nestedRowDropAttr: 'childView.style.nestedRowDropAttr',
        legendSelected: 'childView.style.legendSelected',
        enableCompleteRecord:'childView.style.enableCompleteRecord',
        legendAlignment:'childView.style.legendAlignment',
        color: 'childView.style.color',
        btnColors: 'childView.style.btnColors',
        cardBackground: 'childView.style.cardBackground',
        styles: 'childView.style.otherStyles',
        gsiact: 'childView.style.gsiact',
        attributeTypes: 'childView.style.attributeTypes',
        nestedRowUiTypes:'childView.style.nestedRowUiTypes',
        oddRowColor: 'childView.style.oddRowColor',
        cardBoxShadow: 'childView.style.cardBoxShadow',
        cardBorderSize: 'childView.style.cardBorderSize',
        cardBorderRadius: 'childView.style.cardBorderRadius',
        cardBorderColor: 'childView.style.cardBorderColor',
        evenRowColor: 'childView.style.evenRowColor',
        widthArray: 'childView.style.widthArray',
        headerBackground: 'childView.style.headerBackground',
        nestedRowHeaderBackground: 'childView.style.nestedRowHeaderBackground',
        pagination: 'childView.style.pagination',
        gsiConfigurations: 'childView.style.gsiConfigurations',
        header: 'childView.style.header',
        nestedRowHeader: 'childView.style.nestedRowHeader',
        evenRow: 'childView.style.evenRow',
        oddRow: 'childView.style.oddRow',
        description: 'childView.style.description',
        headerFreeze: 'childView.style.headerFreeze',
        gridLines: 'childView.style.gridLines',
        advanceTableFeatures: 'childView.style.advanceTableFeatures',
        expandPopup: 'expandedView.style.expandPopup',
        defaultViewType: 'defaultViewType',
        sortOption: 'sort.sortOption',
        expandTemplateTypeData: 'expandedView.style.expandTemplateTypeData',
        gsiExpandData: 'expandedView.style.gsiExpandData',
        search: 'search',
        range: 'range',
        isMultiAttributeExpan: 'childView.isMultiAttributeExpan',
        conditionArray: 'childView.style.conditionArray',
        numberOfRows: 'childView.numberOfRows',
        dropExpandColors: 'expandedView.style.dropExpandColors',
        stylesExpand: 'expandedView.style.droppedStylesExpand',
        expandBackground: 'expandedView.style.expandBackground',
        expandBtnColors: 'expandedView.style.expandBtnColors',
        expandBoxShadow: 'expandedView.style.expandBoxShadow',
        conditionArrayExpand: 'expandedView.style.conditionArrayExpand',
        searchFieldAttr: 'searchFields',
        locationFilter: 'locationFilter',
        groupFilterDataBy: 'filters',
        expandType: 'expandedView.style.defaultType',
        cardHoveredColor: 'childView.style.cardHoverColor',
        attrHoveredColors: 'childView.style.hoveredColors',
        expandHoveredColors: 'expandedView.style.expandHoveredColors',
        expandCardHoveredColor: 'expandedView.style.expandCardHoveredColor',
        btnHoverColors: 'childView.style.btnHoverColors',
        expandBtnHoverColors: 'expandedView.style.expandBtnHoverColors',
        headerGsi:'childView.style.headerGsi',
        customNoValue: 'childView.style.customNoValue',
        cardGsiMap: 'childView.style.cardGsiMap',
        gsiAttrMap: 'childView.style.gsiAttrMap',
        gsiAttrMapExpand: 'childView.style.gsiAttrMapExpand',
        hyperlinkAttribute: 'childView.style.hyperlinkAttribute',
        expandHyperlinkAttributes: 'childView.style.expandHyperlinkAttributes',
        hyperlinkCtaDropArray: 'childView.style.hyperlinkCtaDropArray',
       
        
      },
      expandTemplateType: 'expandTemplateType',
      templateType: 'childView.templateType',
      filterDesign: 'filterDesign',
      kanbanConfiguration: 'childView.style.kanbanConfiguration',
    },
    operate: [
      {
        run: function (val: any) {
          if (data.templateMappingFields) {
            return data.templateMappingFields;
          } else {
            return JSON.stringify(val);
          }
        },
        on: 'templateMappingFields',
      },
      {
        run: function (val: any) {
          if (data.sortBy) {
            return data.sortBy;
          } else {
            return val;
          }
        },
        on: 'sortBy',
      },
      {
        run: function (val: any) {
          if (data.templateType) {
            return data.templateType;
          } else {
            return val;
          }
        },
        on: 'templateType',
      },
      {
        run: function (val: any) {
          if (data.filterBy) {
            return data.filterBy;
          } else {
            let arr: any[] = [];
            Object.values(val).forEach((attr: any) => {
              arr = [...arr, ...attr];
            });
            return arr;
          }
        },
        on: 'filterBy',
      },
      {
        run: function (val: any) {
          if (data.filterDesign && data?.filterDesign?.hiddenFilter) {
            data.filterDesign.hiddenFilter.inConditions = data?.filterDesign?.hiddenFilter?.inConditions?.[0];
            data.filterDesign['hiddenFilterRule'] = {
              ruleSet: [data?.filterDesign?.hiddenFilter],
              ruleSetCondition: 'AND',
            };
            return data.filterDesign;
          } else {
            return val;
          }
        },
        on: 'filterDesign',
      },
    ],
  };

  const result = transform(data, map);

  return result;
}
