import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subscription, timer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-physical-layer',
  templateUrl: './physical-layer.component.html',
  styleUrls: ['./physical-layer.component.scss'],
})
export class PhysicalLayerComponent implements OnInit {
  @Input() showForm: boolean;
  @Input() entity: any;
  @Input() gsi: any;
  @Input() estimatedTime: string;
  @Input() currentCu: string;
  @Output() emitFormData = new EventEmitter();
  @Output() entityDataEmitForTable = new EventEmitter();
  @Output() formValueChanges = new EventEmitter();
  @Output() addMultipleEntities = new EventEmitter();
  @Input() UIcontrols: any;
  @Input() physicalEntities: any;
  @Input() entitiesList: any;
  @Input() layoutSetting: any;
  @Input() isReadOnly: any;
  @Input() cuLayoutTemplate: any;
  @Input() tempInfoEntList: any;
  @Input() layoutConfigurations: any;
  @Input() selectedCard: any;
  @Input() isTransactionActive: boolean;
  @Input() gsiDesign: any;
  @Input() indexChange?: number;

  estimatedTimeInMin: number;
  timerSubscription: Subscription;
  unsubscribe$ = new Subject();
  tick = 1000;
  labels: any;
  highestCurrentEntityIndex = 0;
  layerData: any = {
    type: 'physical',
    txnSlotItems: [],
  };
  currentEntityIndex = 0;

  constructor(private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.estimatedTime) {
      this.setTimer();
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }

  private setTimer() {
    const timeArr = this.estimatedTime.split(':');
    if (timeArr.length === 1) {
      this.estimatedTimeInMin = Number(timeArr[0].trim()) * 60;
    } else if (timeArr.length === 2) {
      this.estimatedTimeInMin =
        Number(timeArr[0].trim()) * 60 + Number(timeArr[1].trim());
    }
    this.timerSubscription = timer(0, this.tick).subscribe(
      () => --this.estimatedTimeInMin
    );
  }

  onSubmit(formValues: any) {
    this.emitFormData.emit(formValues);
  }

  isNumber() {
    return !isNaN(this.estimatedTimeInMin);
  }

  submitCu(
    submitEntity: any,
    entityIndex: number,
    isFromvalueChange?: boolean
  ) {
    if (!submitEntity) {
      this.emitFormData.emit({
        layerData: this.layerData,
        isFormValueChange: false,
      });
    } else {
      const { guid, id, type } = this.physicalEntities;
      let txnSlots = this.layerData.txnSlotItems;
      this.layerData = { guid, id, type };
      if (txnSlots && txnSlots[entityIndex]) {
        txnSlots.splice(entityIndex, 1, submitEntity);
      } else {
        txnSlots.push(submitEntity);
      }
      this.layerData.txnSlotItems = [...txnSlots];
      let formSubmit =
        this.currentEntityIndex ===
        this.physicalEntities.participatingItems.length - 1
          ? true
          : false;
      if (formSubmit || isFromvalueChange) {
        this.emitFormData.emit({
          layerData: this.layerData,
          isFormValueChange: isFromvalueChange,
        });
      }
      if (!isFromvalueChange) {
        if (
          this.currentEntityIndex !==
          this.physicalEntities.participatingItems.length - 1
        ) {
          this.currentEntityIndex += 1;
        }
      }
      this.highestCurrentEntityIndex = this.currentEntityIndex;
    }
  }

  submitFormData(data: any) {
    this.emitFormData.emit({
      data: data.data,
      layer: 'physical',
      details: data.details,
    });
  }

  entityDataForTable(event: any) {
    this.entityDataEmitForTable.emit(event);
  }

  formValueChange(data: any) {
    this.formValueChanges.emit({
      data: data.data,
      layer: 'physical',
      details: data.details,
      cuBtnValidations: data?.cuBtnValidations,
      entitySelectionMap: data?.entitySelectionMap,
    });
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openEntity(event: any) {
    if (
      event.layer === 'physical' &&
      this.highestCurrentEntityIndex >= event.entityIndex
    ) {
      this.currentEntityIndex = event.entityIndex;
      this.highestCurrentEntityIndex =
        this.highestCurrentEntityIndex > this.currentEntityIndex
          ? this.highestCurrentEntityIndex
          : this.currentEntityIndex;
    }
  }

  addMultiEntities(entity: any) {
    this.addMultipleEntities.emit(entity);
  }
}
