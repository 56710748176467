import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dldAttribute',
  /* istanbul ignore next */
  pure: true,
})
export class DLDAttributePipe implements PipeTransform {
  transform(data: any, layerToShow: string): any {
    /* istanbul ignore next */
    if (data) {
      return this.extractEntitiesFromCU(data, layerToShow);
    }
  }

  private extractEntitiesFromCU(cuData: any, layerToShow: string) {
    const changeUnit = cuData.DATA;
    /* istanbul ignore next */
    if (changeUnit) {
      /* istanbul ignore next */
      if (changeUnit?.layers?.length > 0) {
        for (let cu of changeUnit.layers) {
          const layerData = cu;
          /* istanbul ignore next */
          if (layerData.type === layerToShow) {
            let attributesList: any = [];
            for (let entity of layerData.participatingItems) {
              entity?.nslAttributes.map((attribute: any) => attributesList.push(attribute.name));
            }
            return attributesList;
          }
        }
      }
    }
  }
}
