<div class="review-status" mat-dialog-content>
  <div class="review-status-heading">
    <h1 mat-dialog-title class="review">{{ labels?.Review_Status }}</h1>
  </div>

  <button mat-button color="warn" class="deletereview show-review" *ngIf="ownerId" (click)="cancelReview()">
    {{ labels?.Delete_Review_Request }}
  </button>

  <table mat-table [dataSource]="OwnerdataSource" class="mat-elevation-z0 table" aria-describedby="">
    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef id="name">{{ labels?.Owner_Name }}</th>

      <td mat-cell *matCellDef="let element">{{ element.betOwner }}</td>
    </ng-container>

    <ng-container matColumnDef="submitDate">
      <th mat-header-cell *matHeaderCellDef id="submittedData">{{ labels?.Submitted_Date }}</th>
      <td mat-cell *matCellDef="let element">{{ element.submittedTime }}</td>
    </ng-container>

    <ng-container matColumnDef="solutionName">
      <th mat-header-cell *matHeaderCellDef id="solutionName">{{ labels?.Solution_Name }}</th>
      <td mat-cell *matCellDef="let element">{{ element.betName }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef id="comments">{{ labels?.Comments }}</th>
      <td class="commnets" mat-cell *matCellDef="let element">
        {{ element.betDescription !== 'null' ? element.betDescription : element.betName + ' to be reviewed' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="headerColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: headerColumns"></tr>
  </table>

  <hr />

  <table mat-table [dataSource]="dataSource.reviewers" class="mat-elevation-z0" aria-describedby="">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef id="name">{{ labels?.Name }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <!-- STATUS Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef id="status">{{ labels?.Status }}</th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'is-white': element.status === 'Not seen yet',
          'is-red': element.status === 'Rejected',
          'is-green': element.status === 'Approved'
        }"
      >
        {{ element.status }}
      </td>
    </ng-container>

    <!-- ACTED ON DATE -->
    <ng-container matColumnDef="acted_on">
      <th mat-header-cell *matHeaderCellDef id="acted">{{ labels?.Acted_On }}</th>
      <td mat-cell *matCellDef="let element">{{ element.completedTime || ' ' }}</td>
    </ng-container>

    <!-- COMMENTS -->
    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef id="comment">{{ labels?.Comments }}</th>
      <td class="commnets" mat-cell *matCellDef="let element">{{ element.comments }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
