import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { SignatureComponent } from './signature.component';
import { DigitalSignatureModule } from '../digital-signature/digital-signature.module';

@NgModule({
  declarations: [SignatureComponent],
  imports: [ControlsMaterialModule, DigitalSignatureModule],
  exports: [SignatureComponent],
})
export class SignatureModule {
  static entry = {
    signature: SignatureComponent,
  };
}
