import { NgModule } from '@angular/core';
import { RecordVideoComponent } from './record-video.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [RecordVideoComponent],
  imports: [
    ControlsMaterialModule,
  ],
  exports: [RecordVideoComponent],
  // providers: [VideoRecordingService],
})
export class RecordVideoModule {
  // static entry = {
  //   recordvideo: RecordVideoComponent,
  // };
  static entry = {
    videorecording: RecordVideoComponent,
  };
}
