import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-entity-radio-dcd',
  templateUrl: './entity-radio-dcd.component.html',
  styleUrls: ['./entity-radio-dcd.component.scss'],
})
export class EntityRadioDcdComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  // @Input() attr: any;
  @Input() attrName: any;
  @Input() entityName: any;
  // @Input() attrId: any;
  @Input() entityIndex: any;
  @Input() entity: any;
  // @Input() cuName: any;
  // @Input() layerName: any;
  @Output() attributeEmit = new EventEmitter();
  @Output() entityEmit = new EventEmitter();
  nestedAttrName: string;
  attribute: any;

  constructor() {}

  ngOnInit(): void {}
  attributeToEmit(event: any) {
    /*istanbul ignore next*/
    if (event?.formatName) {
      event.formatName = this.entity?.name + '.' + event?.formatName;
      event.formatId = this.entity?.id + '.' + event?.formatId;
    }
    /*istanbul ignore next*/
    this.attributeEmit.emit({
      entityIndex: this.entityIndex,
      entity: event.entity,
      attribute: event.attr,
      formatName: event.formatName,
      formatId: event.formatId,
    });
  }
  //selecting trigger attribute for subtransactional cu
  triggerAttribute(entity: any, attr: any) {
    this.attribute = attr;
    let formatName: any;
    let formatId: any;
    /*istanbul ignore next*/
    if (this.attribute) {
      formatName = this.entity?.name + '.' + this.attribute?.name;
      formatId = this.entity?.id + '.' + this.attribute?.id;
    }
    this.attributeEmit.emit({
      entityIndex: this.entityIndex,
      entity: this.entity,
      attribute: this.attribute,
      formatName: formatName,
      formatId: formatId,
    });
  }
}
