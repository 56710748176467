import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig, TransactionFacadeService } from '@common-services';

@Component({
  selector: 'app-timeonly',
  templateUrl: './timeonly.component.html',
  styleUrls: ['./timeonly.component.scss'],
})
export class TimeOnlyComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  time: any;
  constructor(private transacationFacadeService: TransactionFacadeService) {}
  ngOnInit() {
    /*istanbul ignore next*/
    if (this.field?.value) {
      if (this.field?.value?.length == 5) {
        this.field.value += ':00';
      }
      /*istanbul ignore next*/
      let timeDetails = new Date();
      /*istanbul ignore next*/
      let timeArray = this.field?.value.split(':');
      /*istanbul ignore next*/
      timeDetails.setHours(timeArray[0]);
      /*istanbul ignore next*/
      timeDetails.setMinutes(timeArray[1]);
      /*istanbul ignore next*/
      timeDetails.setSeconds(timeArray[2]);
      this.time = this.field.value;
      this.field.value = timeDetails;
      this.transacationFacadeService.disableSubmitButtonInteger(false);
    }
  }
  /*istanbul ignore next*/
  clicked(event: any) {
    console.log(event);
    /*istanbul ignore next*/
    if (event) {
      /*istanbul ignore next*/
      let timeDetails = this.field?.value;
      /*istanbul ignore next*/
      this.time = timeDetails.getHours() + ':' + timeDetails.getMinutes() + ':' + timeDetails.getSeconds();
      /*istanbul ignore next*/
      this.group.controls[this.field?.attribute?.name].setValue(this.field?.value);
    }
  }
}
