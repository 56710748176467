<div class="nested-atr-wrap">
  <div class="top-head">
    <div class="save-wrap">
      <div class="save-blk">
        <h5>Entity Creation</h5>
        <button mat-stroked-button (click)="saveEntity()">Save</button>
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
          <mat-icon matSuffix (click)="closePanel()">close</mat-icon>
        </button>
      </div>
    </div>
    <div></div>
    <div class="object-wrap">
      <div class="cust-details">
        <p>Object: {{ entity?.name }}</p>
      </div>
      <div class="nxt-pre">
        <mat-icon>chevron_left</mat-icon>
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
  </div>
  <div class="object-content-wrap">
    <div>
      <div class="colomn-one">
        <div class="colmn-head pt-1">
          <h6>Entity</h6>
        </div>
        <!-- active bg -->
        <div>
          <div class="addinput-wrap">
            <div class="addinput-txt">
              <mat-form-field>
                <input type="text" matInput [(ngModel)]="entity.name" />
                <mat-icon matSuffix>delete_outline</mat-icon>
              </mat-form-field>
            </div>
            <div class="swith-wrap-blk">
              <div class="swith-blk">
                <mat-slide-toggle><span>ON</span> {{ labels?.Off }}</mat-slide-toggle>
              </div>
              <div class="add-wrap-blk">
                <button class="csm-btn" (click)="addentconfig()">
                  Configure
                </button>
                <button class="csm-btn">
                  <mat-icon (click)="addAttribute()">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="colomn-one colomn-two">
        <div class="colmn-head pt-1">
          <h6>Attributes</h6>
        </div>
        <div *ngFor="let attribute of entity?.nslAttributes; let attrIndex = index">
          <div class="addinput-wrap">
            <div class="addinput-txt">
              <mat-form-field>
                <input type="text" matInput [(ngModel)]="attribute.name" />
                <mat-icon matSuffix (click)="deleteAttribute(attrIndex)">delete_outline</mat-icon>
              </mat-form-field>
            </div>
            <div class="swith-wrap-blk">
              <div class="add-wrap-blk edt-btn">
                <button class="csm-btn" (click)="addconfig(attribute, attrIndex)">
                  Edit Attribute
                </button>
                <button class="csm-btn" (click)="openEntityLibrary()">
                  Add From Library
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- active bg -->
      </div>
    </div>
  </div>

  <!-- [singleEntity]="true" -->
  <div *ngIf="openconfigure" class="independent-section">
    <app-node-independent-attribute-edit
      *ngIf="configurationEntityData"
      [singleEntity]="true"
      [configurationData]="configurationEntityData"
      [tempGsiObj]="tempGsiObj"
      (closeattribute)="closeAttribute($event)"
    >
    </app-node-independent-attribute-edit>
  </div>

  <div *ngIf="openentityconfigure" class="independent-section">
    <app-node-independent-entity-edit
      *ngIf="configurationEntityData"
      [configurationData]="configurationEntityData"
      [tempGsiObj]="tempGsiObj"
      (closeentity)="closeEntity($event)"
    >
    </app-node-independent-entity-edit>
  </div>
</div>
