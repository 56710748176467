import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';

import { BpmnViewComponent } from './bpmn-view.component';
import { BpmnViewerComponent } from './bpmn-viewer/bpmn-viewer.component';

@NgModule({
  declarations: [BpmnViewComponent, BpmnViewerComponent],
  imports: [MatStepperModule, ApplicationPipesModule,CommonModule ],
  exports: [BpmnViewComponent, BpmnViewerComponent],
})
export class BPMNViewModule {}
