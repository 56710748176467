import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { RadioGroupComponent } from './radio-group.component';

@NgModule({
  declarations: [RadioGroupComponent],
  imports: [ControlsMaterialModule],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {
  static entry = {
    radiobutton: RadioGroupComponent,
  };
}
