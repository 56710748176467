import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FieldConfig, IsJsonString, TransactionFacadeService, TranslatorService } from '@common-services';
// var shaka = require('shaka-player');
declare let shaka: any;
// import * as shaka from 'shaka-player';

@Component({
  selector: 'app-video-streaming',
  styleUrls: ['./video-streaming.component.scss'],
  template: `
    <div class="demo-full-width player-custom-video">
      <div class="d-flex">
        <label
          class="main-label"
          pTooltip="{{ field.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field?.color"
          [style.font-size.px]="field?.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >
          <span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
          </span>
        </label>
      </div>
      <div class="d-flex" *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])">
        <ng-container>
          <div #videoContainer class="video-data-cont">
            <video #videoPlayer id="video" width="100%"></video>
          </div>
        </ng-container>

        <ng-template #datanotfound>
          <p>{{ labels?.No_Video_Found }}</p>
        </ng-template>
      </div>

      <!--video text in table-->
      <div class="video-in-table" *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']">
        <figure (click)="toggleShow($event)" class="video-icon">
          <img src="../../../assets/images/ui-controls/icon-video-recording.svg" alt="" />
        </figure>

        <div class="video-popup" [ngClass]="{ 'd-none': !isShown }" id="divshow">
          <div class="video-panel">
            <div class="video-popup-header">
              <h2 class="video-pop-title">{{ field?.label }}</h2>
              <div class="video-action-btns">
                <ul>
                  <li (click)="toggleShow($event)">
                    <i class="bi bi-x"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="video-popup-content">
              <div class="d-flex">
                <ng-container>
                  <div #videoContainer class="video-data-cont">
                    <video #videoPlayer id="video" width="100%"></video>
                  </div>
                </ng-container>

                <ng-template #datanotfound>
                  <p>{{ labels?.No_Video_Found }}</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      img {
        width: 100%;
      }
      .main-label {
        color: #999999;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 400;
      }
    `,
  ],
})
export class VideoStreamingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoPlayer') videoElementRef: ElementRef;
  @ViewChild('videoContainer') videoContainerElementRef: ElementRef;
  field: FieldConfig;
  group: FormGroup;
  labels: any;

  ngUnsubscribe = new Subject();
  videoUrls: any[];
  videoElement: any;

  src: any;
  dashFormat: boolean = false;
  videoFlag: boolean = false;
  videoContainerElement: any;
  isShown: boolean = false; // hidden by default
  

  constructor(private translator: TranslatorService, private transacationFacadeService: TransactionFacadeService) {
    this.detectLanguageChange();
  }
  /**
   * Author : Sunil Goyal
   * Description : Once the component is loaded it will check that shaka player is supported
   * date :9/11/2023
   */
  /*  istanbul ignore next */
  ngAfterViewInit() {
    /*  istanbul ignore next */
    shaka?.polyfill?.installAll();
    /* istanbul ignore next */
    if (shaka?.Player?.isBrowserSupported()) {
      this.videoElement = this.videoElementRef?.nativeElement;
      this.videoContainerElement = this.videoContainerElementRef?.nativeElement;
      // this.videoElement = document.getElementById('video');
      this.initPlayer();
    }
  }

  /**
   * Author : Sunil Goyal
   * Description : Method will initialize the shaka player and load the video
   * date :9/11/2023
   */
  /*  istanbul ignore next */
  initPlayer() {
    let player = new shaka.Player(this.videoElement);
    const ui = new shaka.ui.Overlay(player, this.videoContainerElement, this.videoElement);
    const config = {
      overflowMenuButtons: ['playback_rate', 'quality', 'language', 'captions'],
    };
    ui.configure(config);
    // player.getNetworkingEngine().registerRequestFilter((request_type: any, request: any) => {
    //   request.allowCrossSiteCredentials = true;
    // });
    /* istanbul ignore next */
    let credentials: any;
    // Set headers for segment requests
    if (IsJsonString(localStorage?.getItem('credentials'))) {
      credentials = JSON.parse(localStorage?.getItem('credentials'));
    }
    player?.getNetworkingEngine()?.registerRequestFilter((request_type: any, request: any) => {
      // This function filters manifest and segment request and add custom headers:
      if (
        request?.uris[0]?.includes('nslhub') &&
        (request_type === shaka?.net?.NetworkingEngine?.RequestType?.SEGMENT ||
          request_type == shaka?.net?.NetworkingEngine?.RequestType?.MANIFEST)
      ) {
        request.headers['Authorization'] = `Bearer ` + credentials?.token;
      }
    });
    player?.load(this.videoUrls[0]);
  }

  ngOnInit(): void {
    //disable submit button when video loads
    this.transacationFacadeService.disableSubmitButton(true);
    this.videoUrls = [];
    /*  istanbul ignore next */
    if (this.field && this.field?.value) {
      if (typeof this.field?.value === 'string') {
        this.videoUrls.push(this.field?.value);
      }
    }
    /* istanbul ignore next */
    if (this.videoUrls?.length === 0) {
      this.transacationFacadeService.disableSubmitButton(false);
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.labels = res;
      }
    });
  }

  toggleShow(event: any) {
    this.isShown = !this.isShown;
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.transacationFacadeService.disableSubmitButton(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}