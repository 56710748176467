declare var require:any;
let transform = require('node-json-transform').transform;

export function sfPostFactMapper(data: any) {
  console.log(data);
  var map = {
    /*
        node json mapper default item
        used to map one key to another
      */
    item: {
      eventType: 'eventType',
      'till now factor': 'tillNow',
      'source value': 'sourceValue',
      duration: 'durationFactor',
      'result format': 'resultFormat',
      source: 'sourceContextualName',
      target: 'targetContextualName',
      'unit of time': 'timeFactor',
      query: 'queryCondition',
      'moving frequency': 'movingFrequency',
      'comparison period': 'comparisonPeriod',
    },
    operate: [
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              context: val,
            };
          }
        },
        on: 'source value',
      },
      {
        run: function (val: any) {
          console.log(val);
          return {
            event: val ? val : '',
          };
        },
        on: 'eventType',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              tillNow: val,
            };
          }
        },
        on: 'till now factor',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              durationFactor: val,
            };
          }
        },
        on: 'duration',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              resultFormat: val,
            };
          }
        },
        on: 'result format',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            let values = val?.split(',');
            let sourceContextualname = '';
            values?.forEach((value: any) => {
              sourceContextualname += value?.split('.')?.[value.split('.')?.length - 1] + ',';
            });
            sourceContextualname = sourceContextualname.substring(0, sourceContextualname?.length - 1);
            // let value = val?.split('.')?.[val.split('.')?.length - 1];
            return {
              sourceContextualName: sourceContextualname,
            };
          }
        },
        on: 'source',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            let value = val?.split('.')?.[val.split('.')?.length - 1];
            return {
              targetContextualName: value,
            };
          }
        },
        on: 'target',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              timeFactor: val,
            };
          }
        },
        on: 'unit of time',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              queryCondition: val,
            };
          }
        },
        on: 'query',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              movingFrequency: val,
            };
          }
        },
        on: 'moving frequency',
      },
      {
        run: function (val: any) {
          console.log(val);
          /*istanbul ignore else*/
          if (val) {
            return {
              comparisonPeriod: val,
            };
          }
        },
        on: 'comparison period',
      },
    ],
  };
  return transform(data, map);
}
