<ng-template #actionButton let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <span class="btn-close">
      <i class="bi bi-x" *ngIf="childGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
      <button
        *ngIf="!isGrammer"
        #gsiAction
        attributeDesignConfig
        [isEdit]="isEdit"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [_style]="style"
        [tabSelected]="tabSelected"
        [isDesign]="isDesign"
        [isButton]="true"
        [_selectedDirectiveId]="selectedDirectiveId"
        (drop)="dropActionBtn($event)"
        (dragover)="allowDrop($event)"
        class="btn template-btn action-button btnaction"
        pTooltip="Action Button"
        [id]="'action-' + data?.position"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [ngClass]="data?.class"
        [cardDisplay]="cardDisplay"
        [styleGsi]="styleGsi?.[data?.position]"
      >
        {{childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'Action Button'}}
      </button>
    </span>
  </ng-container>

  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="data?.isSponser">
      <div
        [ngClass]="data?.class"
        *ngIf="gsiMapping[data?.position]?.displayName"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
      ></div>
    </ng-container>
    <ng-container *ngIf="!data?.isSponser">
      <button
        [ngStyle]="{ background: btnHoveredBoolean?.[data?.position] ? btnHoverColors?.[data?.position] :btnColors?.[data?.position] }"
        (mouseover)="btnHoveredBoolean[data?.position] = true"
        (mouseout)="btnHoveredBoolean[data?.position] = false"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name && quantity[txnDataIndex] == 0"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </button>
    </ng-container>

    <div
      class="quantity-section"
      *ngIf="gsiMapping?.[data?.position]?.name && quantity[txnDataIndex] > 0 && gsiMapping?.[data?.position]?.entityType == 'CART'  "
    >
      <div class="quantity-input">
        <button class="quantity-btn">
          <i class="bi bi-dash" (click)="changeQuantity('decreaseQuantity', txnDataIndex)"></i>
        </button>
        <span class="quantity">{{ quantity[txnDataIndex] }}</span>
        <button class="quantity-btn">
          <i class="bi bi-plus" (click)="changeQuantity('increaseQuantity', txnDataIndex)"></i>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <li
      *ngIf="!data.isImage"
      [id]="attributeItem?.innerHTML"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [position]="data?.position"
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      [_style]="style"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      [tabSelected]="tabSelected"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="styleAttribute[data?.position]"
    >
      <div [ngClass]="{ 'nh-pr-12': attributeItem.innerHTML !== 'Attribute' }" class="attribute-unlink">
        <div class="text-truncate" #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <span class="delete-attr" *ngIf="attributeItem.innerHTML !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
      /></span> -->
      <span class="delete-attr" *ngIf="childPositionMap?.[data?.position]?.addOnChild?.value"
        >addon<img
          class="cursor-pointer"
          (click)="onDeletingAddOn(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
      /></span>
    </li>
    <li
      *ngIf="data?.isImage"
      id="attr-{{ data?.position }}"
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      [ngClass]="data?.class"
      #attributeItemImage
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem class="text-truncate" id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf="data.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.isImage">
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        (click)="emitAddOnClick($event, data?.position, card)"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <li
        [ngClass]="data?.class"
        *ngIf="data?.isSingleDisplay"
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.displayName }}
        >
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        *ngIf="data?.isSingleValueWOC"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
    </ng-container>

    <ng-container *ngIf="data?.isSopnserSuffix">
      {{ card?.recordData?.[data.position]?.value  | numberSuffix}}
    </ng-container>

    <ng-container *ngIf="data.isImage">
      <li>
        <img
          [ngClass]="data.class"
          *ngIf="(card?.recordData)[data.position]?.contentUrl"
          [src]="(card?.recordData)[data.position]?.contentUrl | CDNUrlRewritePipe"
          alt="general-entity"
        />
      </li>
    </ng-container>
  </ng-container>
</ng-template>
<!-- <div [ngClass]="{ 'col-4': cardDisplay == 'design' }"> -->
<!-- twoAttributesTemplates -->
<mat-icon *ngIf="cardGsiMap?.mapped" (click)="deleteCardGsiMap()">close</mat-icon>
<div class="templateType5 templates-type-hor" *ngIf="templateType == '5'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'template-img',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
    </ul>
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="templates-type-hor templateType6" *ngIf="templateType == '6'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>

      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="templates-type-hor templateType7" *ngIf="templateType == '7'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType8A" *ngIf="templateType == '8'">
  <div
    class="template-inner-card"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, class: 'template-img', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="templates-type-ver templateType9" *ngIf="templateType == '9'">
  <div
    class="template-inner-card cardoption5"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, class: 'e-commerce-imgsize', cardDisplay: cardDisplay }
        }"
      ></ng-template>

      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType8" *ngIf="templateType == '121'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, class: 'template-img', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <!-- <h3 class="template-title text-truncate" pTooltip="{{ (card?.recordData)[1]?.value }}" tooltipPosition="top"> -->
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates entity-template-t33" *ngIf="templateType == '130'">
  <div
    class="template-inner-card entity-template-type d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="entitytemplates type-templates entity-template-t34" *ngIf="templateType == '131'">
  <div
    class="template-inner-card entity-template-type d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>

    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>

<!-- threeAttributesTemplates -->
<div class="templates-type-hor templateType10" *ngIf="templateType == '10'">
  <div class="template-inner-card"
  [ngStyle]="{ 'background-color': cardColor }"
  CardDesignConfig
  [cardHoveredColor]="cardHoveredColor"
  (dragover)="allowDrop($event)"
  (drop)="dropCard($event)"
  [isEdit]="isEdit"
  [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
  [id]="'Template-' + templateType"
  (emitHoverColor)="recieveHoverColor($event)"
  [_changeShadowStyle]="boxShadowData"
  [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId"
  (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize"
  [cardBorderRadius]="cardBorderRadius"
  [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow"
  [cardColor]="cardColor"
  [card]="card"
  [cardDisplay]="cardDisplay"
  [(cardStyle)]="cardStyle">
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'template-img',
            isImage: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
    </ul>
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="col-md-12 templates-type-hor templateType11" *ngIf="templateType == '11'">
  <div class="template-card cardoption7"
  [ngStyle]="{ 'background-color': cardColor }"
  CardDesignConfig
  [cardHoveredColor]="cardHoveredColor"
  (dragover)="allowDrop($event)"
  (drop)="dropCard($event)"
  [isEdit]="isEdit"
  [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
  [id]="'Template-' + templateType"
  (emitHoverColor)="recieveHoverColor($event)"
  [_changeShadowStyle]="boxShadowData"
  [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId"
  (emitSelectedElement)="recieveEmittedDirectiveId($event)"
  [cardBorderSize]="cardBorderSize"
  [cardBorderRadius]="cardBorderRadius"
  [cardBorderColor]="cardBorderColor"
  [cardShadow]="cardShadow"
  [cardColor]="cardColor"
  [card]="card"
  [cardDisplay]="cardDisplay"
  [(cardStyle)]="cardStyle">
    <div class="cardoption7-flex">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </figure>
      <div>
        <h3 class="template-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </h3>
        <p class="template-para">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </p>
      </div>
    </div>
    <div class="template-btns">
      <button>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: { position: 0, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </button>
      <button>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: { position: 1, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </button>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType12" *ngIf="templateType == '12'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            isImage: true,
            class: 'template-img',
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            isSingleValue: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            isSingleValue: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="templates-type-ver templateType13" *ngIf="templateType == '13'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            isImage: true,
            class: 'template-img',
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            isSingleValueWOC: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            isSingleValueWOC: true,
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
    </ul>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType14" *ngIf="templateType == '14'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            isImage: true,
            class: 'template-img',
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="templates-type-ver templateType15" *ngIf="templateType == '15'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            isImage: true,
            class: 'template-img',
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </ul>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates template-type-132" *ngIf="templateType == '132'">
  <div
    class="template-inner-card d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content lineclamp nh-n-2',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'card-title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="entitytemplates type-templates template-type-132" *ngIf="templateType == '133'">
  <div
    class="template-inner-card d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, cardDisplay: cardDisplay, isSingleValue: true, class: 'card-title' }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 1, cardDisplay: cardDisplay, isSingleValue: true, class: 'card-content' }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 2, cardDisplay: cardDisplay, isSingleValue: true, class: 'card-title' }
        }"
      ></ng-template>
    </ul>

    <div class="row">
      <div class="col-6">
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              cardDisplay: cardDisplay,
              class: 'btn template-btn btn-tooltip w-100 tem-without-bg'
            }
          }"
        ></ng-template>
      </div>
      <div class="col-6">
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: { position: 1, cardDisplay: cardDisplay, class: 'btn template-btn btn-tooltip w-100' }
          }"
        ></ng-template>
      </div>
    </div>
  </div>
</div>

<!-- FourAttributesTemplates -->
<div class="templates-type-hor templateType16" *ngIf="templateType == '16'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <div class="passcolor">{{ (card?.recordData)[3]?.displayName }}</div>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="templates-type-hor templateType17" *ngIf="templateType == '17'">
  <div
    class="template-inner-card"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <div class="passcolor">{{ (card?.recordData)[3]?.displayName }}</div>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType18" *ngIf="templateType == '18'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>

      <ul class="template-inner-list-card">
        <!-- <h3 class="template-title text-truncate" pTooltip=" {{ (card?.recordData)[1]?.value }}" tooltipPosition="top"> -->
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <!-- </h3> -->
        <!-- <p class="template-para lineclamp" pTooltip=" {{ (card?.recordData)[2]?.value }}" tooltipPosition="top"> -->
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <!-- </p> -->
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <!-- <span
              class="passcolor text-truncate"
              pTooltip=" {{ (card?.recordData)[3]?.displayName }}"
              tooltipPosition="top"
            > -->
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <!-- </span>
            <span class="text-truncate" pTooltip=" {{ (card?.recordData)[4]?.value }}" tooltipPosition="top"> -->
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <!-- </span> -->
    </div>
  </div>
</div>
<div class="templates-type-ver templateType19" *ngIf="templateType == '19'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <div class="passcolor">{{ (card?.recordData)[3]?.displayName }}</div>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType20" *ngIf="templateType == '20'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <div>
        <div class="passcolor">{{ (card?.recordData)[3]?.displayName }}</div>
        <ul class="template-inner-list-card">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType21" *ngIf="templateType == '21'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>

      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <div class="passcolor">{{ (card?.recordData)[3]?.displayName }}</div>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates entity-template-t134" *ngIf="templateType == '134'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content lineclamp',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'card-title text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul class="template-inner-list-card">
      <li class="class text-truncate" pTooltip="{{ (card?.recordData)[3]?.displayName }}" tooltipPosition="top">
        {{ (card?.recordData)[3]?.displayName }}
      </li>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: 'member text-truncate',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div class="entitytemplates type-templates entity-template-t135" *ngIf="templateType == '135'">
  <div
    class="template-inner-card d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ul class="template-inner-list-card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 4,
            class: 'card-content',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>

<!-- fiveAttributesTemplates -->
<div class="templates-type-hor templateType22" *ngIf="templateType == '22'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <!-- <h3 class="template-title text-truncate" pTooltip=" {{ (card?.recordData)[1]?.value }}" tooltipPosition="top"> -->
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <!-- </h3> -->
        <!-- <p class="template-para lineclamp" pTooltip=" {{ (card?.recordData)[2]?.value }}" tooltipPosition="top"> -->
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <!-- </p> -->
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="templates-type-hor templateType23" *ngIf="templateType == '23'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType24" *ngIf="templateType == '24'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType25" *ngIf="templateType == '25'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType26" *ngIf="templateType == '26'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType27" *ngIf="templateType == '27'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates templateType136" *ngIf="templateType == '136'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              class: 'card-title',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 1,
              class: 'card-content',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 2,
              class: 'card-title',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 3,
              class: 'card-content',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 4,
              class: 'card-content',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates templateType137" *ngIf="templateType == '137'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              class: 'card-title',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 1,
              class: 'card-content',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 2,
              class: 'card-title',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 3,
              class: 'card-content',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 4,
              class: 'card-content',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>

<!-- sixAttributesTemplates -->
<div class="templates-type-hor templateType28" *ngIf="templateType == '28'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>

    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 7, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 8, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="templates-type-hor templateType29" *ngIf="templateType == '29'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 6, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="col-md-12 templates-type-ver templateType30" *ngIf="templateType == '30'">
  <div
    class="template-card cardoption26"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <figure>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            isImage: true,
            class: 'template-img',
            cardDisplay: cardDisplay
          }
        }"
      ></ng-template>
    </figure>
    <div class="template-body">
      <h3 class="template-title">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </h3>
      <p class="template-para">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </p>
      <div class="cardoption-values">
        <ul>
          <li>
            <span class="passcolor">{{ (card?.recordData)[3]?.displayName }}</span>
            <span
              ><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template
            ></span>
          </li>
          <li>
            <span class="passcolor">{{ (card?.recordData)[4]?.displayName }}</span>
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </span>
          </li>
          <li>
            <span class="passcolor">{{ (card?.recordData)[5]?.displayName }}</span>
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType31" *ngIf="templateType == '31'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType32" *ngIf="templateType == '32'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
  </div>
</div>
<div class="templates-type-ver templateType33" *ngIf="templateType == '33'">
  <div
    class="template-inner-card"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="template-inner-list-cardA">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              isImage: true,
              class: 'template-img',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-inner-list-cardB w-100">
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="template-inner-list-card">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
    </div>
    <div class="template-list-btns">
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 0, class: 'btn btn-cta text-truncate template-btn-1', cardDisplay: cardDisplay }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="actionButton"
        [ngTemplateOutletContext]="{
          data: { position: 1, class: 'btn btn-cta text-truncate template-btn-2', cardDisplay: cardDisplay }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates col-md-12 row" *ngIf="templateType == '138'">
  <div
    class="entity-template-t33 template-type-138 entity-template-type d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="row">
      <div class="col-6">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
      <div class="col-6">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 4,
                class: 'card-title',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
      <div class="col-6">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 5,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>
<div *ngIf="templateType == 'false'">
  <div
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [attrHoveredColors]="attrHoveredColors"
    [cardHoveredColor]="cardHoveredColor"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
    class="kanbancard"
  >
    <ul>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>

      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 3,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 4,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 5,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 6,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 7,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 8,
            class: '',
            isImage: true,
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 9,
            class: '',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
  </div>
</div>
<div *ngIf="templateType == '332'">
  <div
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [attrHoveredColors]="attrHoveredColors"
    [cardHoveredColor]="cardHoveredColor"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
    class="kanban-entitycard-one"
  >
    <ul class="kanban-section-one">
      <ul class="d-flex nh-column-gap-10 text-truncate">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 0,
              class: 'design-block status-block',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 1,
              class: 'workflow-block status-block',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 2,
            class: 'filenos-block status-block',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 3,
          class: 'topic-block lineclamp',
          cardDisplay: cardDisplay,
          isSingleValue: true
        }
      }"
    ></ng-template>
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 4,
          class: 'kanban-content1-block lineclamp',
          cardDisplay: cardDisplay,
          isSingleValue: true
        }
      }"
    ></ng-template>
    <div class="kanban-section-two">
      <div class="kanban-sub-section">
        <ul class="member-details-block attribute-value">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 5,
                class: 'kanbanclass-logo',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 6,
                class: 'kanban-class-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
        <ul class="member-details-block attribute-value">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 7,
                class: 'kanbanclass-logo',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 8,
                class: 'kanban-class-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 9,
            class: 'member-profile-block',
            cardDisplay: cardDisplay,
            isImage: true
          }
        }"
      ></ng-template>
    </div>
    <div class="kanban-section-three">
      <div class="kanban-sub-section">
        <ul class="member-details-block attribute-value">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 10,
                class: 'kanbanclass-logo',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 11,
                class: 'kanban-class-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
        <ul class="member-details-block attribute-value">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 12,
                class: 'kanbanclass-logo',
                cardDisplay: cardDisplay,
                isImage: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 13,
                class: 'kanban-class-text',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 14,
            class: 'piority-block',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </div>
  </div>
</div>
<div class="entitytemplates type-templates col-md-12 row" *ngIf="templateType == '139'">
  <div
    class="entity-template-t34 entity-template-type d-block"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    (dragover)="allowDrop($event)"
    (drop)="dropCard($event)"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 0,
            class: 'card-title',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 1,
            class: 'card-content',
            cardDisplay: cardDisplay,
            isSingleValue: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="row">
      <div class="col-6">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
      <div class="col-6">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <ul>
      <li class="card-title">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 4,
              cardDisplay: cardDisplay,
              isSingleValueWOC: true
            }
          }"
        ></ng-template>
      </li>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 4,
            cardDisplay: cardDisplay,
            isSingleValueWOC: true
          }
        }"
      ></ng-template>
    </ul>
    <div class="row">
      <div class="col-6">
        <button>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </button>
      </div>
      <div class="col-6">
        <button>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 1, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- sponser templates -->
<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '141'">
  <div class="col-10">
    <div
      class="Sponsor-template Sponsor-template-1"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div class="sponsor-status">
        <div class="description">
          <span *ngIf="card?.recordData?.[0]?.attributeName !== 'Email'"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSignleDisplay: true
                }
              }"
            ></ng-template>
            {{ card?.recordData?.[0]?.value | numberSuffix }}</span
          >

          <span *ngIf="(card?.recordData)[0]?.displayName == 'Email'">{{
            card?.entityName == 'LeadDashboardDetails' ? 'Leads' : card?.entityName
          }}</span>
        </div>
      </div>
      <div class="sponsor-status">
        <div class="text col-4">
          <span class="num passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSopnserSuffix: true
                }
              }"
            ></ng-template>
          </span>
          <span class="passcolor"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
        </div>
        <div class="text col-4">
          <span class="num passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSopnserSuffix: true
                }
              }"
            ></ng-template>
          </span>
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="text col-4">
          <span class="num passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSopnserSuffix: true
                }
              }"
            ></ng-template>
          </span>
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '142'">
  <div class="col-4">
    <div
      class="Sponsor-template Sponsor-template-2"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div
        class="sponsor-entity"
        CardDesignConfig
        [cardHoveredColor]="cardHoveredColor"
        [isEdit]="isEdit"
        [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
        [id]="'Template-' + templateType"
        (emitHoverColor)="recieveHoverColor($event)"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [cardBorderSize]="cardBorderSize"
        [cardBorderRadius]="cardBorderRadius"
        [cardBorderColor]="cardBorderColor"
        [cardShadow]="cardShadow"
        [cardColor]="cardColor"
        [card]="card"
        [cardDisplay]="cardDisplay"
        [(cardStyle)]="cardStyle"
      >
        <span class="name passcolor">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleDisplay: true
              }
            }"
          ></ng-template>
        </span>
        <span class="number"
          ><ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template
        ></span>
      </div>
      <div class="sponsor-data">
        <div class="data">
          <span class="name passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </span>
          <span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 5,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 5,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 6,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 6,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 7,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 7,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 8,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 8,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 9,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 9,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
        <div class="data">
          <span class="passcolor">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 10,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template> </span
          ><span class="number">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 10,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '143'">
  <div class="col-6">
    <div
      class="Sponsor-3 Sponsor-template-3"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div class="sponsors-top-3">
        <div class="left-entity-dets">
          <div class="description">
            <span class="passcolor" pTooltip="{{ (card?.recordData)[0]?.value }}" tooltipPosition="top">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </span>
            <small pTooltip="{{ (card?.recordData)[0]?.displayName }}" tooltipPosition="top">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    cardDisplay: cardDisplay,
                    isSingleDisplay: true
                  }
                }"
              ></ng-template>
            </small>
          </div>
          <div class="right-entity-icons passcolor template-btns">
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'en-icon template-btns en-icon-1',
                  cardDisplay: cardDisplay,
                  isSponser: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'en-icon template-btns en-icon-2',
                  cardDisplay: cardDisplay,
                  isSponser: true
                }
              }"
            ></ng-template>
          </div>
        </div>

        <ul class="sponsor-status">
          <li class="text col-4">
            <span
              class="passcolor text-truncate"
              pTooltip="{{ (card?.recordData)[1]?.value }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              ><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template
            ></span>
            <small
              class="text-truncate"
              pTooltip="{{ (card?.recordData)[1]?.displayName | numberSuffix }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
            >
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </small>
          </li>
          <li class="text col-4">
            <span
              class="passcolor text-truncate"
              pTooltip="{{ (card?.recordData)[2]?.value }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              ><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template
            ></span>
            <small
              class="text-truncate"
              pTooltip="{{ (card?.recordData)[2]?.displayName | numberSuffix }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
            >
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template>
            </small>
          </li>
          <li class="text col-4">
            <span
              class="passcolor text-truncate"
              pTooltip="{{ (card?.recordData)[3]?.value }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              pTooltip="{{ (card?.recordData)[3]?.displayName | numberSuffix }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              ><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template
            ></span>
            <small class="text-truncate">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    cardDisplay: cardDisplay,
                    isSingleValueWOC: true
                  }
                }"
              ></ng-template
            ></small>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '144'">
  <div class="col-6">
    <div
      class="Sponsor-3 Sponsor-template-3"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div class="sponsors-top">
        <div class="left-entity-dets">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[0]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[0]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template
          ></small>
        </div>
        <div class="right-entity-icons passcolor template-btns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'en-icon template-btns en-icon-2', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
        </div>
      </div>

      <ul class="sponsor-status">
        <li class="text col-4">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[1]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[1]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template
          ></small>
        </li>
        <li class="text col-4">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[2]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[2]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
          >
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
        <li class="text col-4">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[3]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>

          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[3]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
          >
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '145'">
  <div class="col-6">
    <div
      class="Sponsor-3 Sponsor-template-3"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div class="sponsors-top">
        <div class="left-entity-dets">
          <span class="passcolor" pTooltip="{{ (card?.recordData)[0]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
          <small pTooltip="{{ (card?.recordData)[0]?.displayName }}" tooltipPosition="top"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template
          ></small>
        </div>
        <div class="right-entity-icons passcolor template-btns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'en-icon template-btns en-icon-3', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 1, class: 'en-icon template-btns en-icon-1', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 2, class: 'en-icon template-btns en-icon-2', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
        </div>
      </div>

      <ul class="sponsor-status">
        <li class="text col-3">
          <span class="passcolor text-truncate" pTooltip="{{ (card?.recordData)[1]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
          <small pTooltip="{{ (card?.recordData)[1]?.displayName }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
        <li class="text col-3">
          <span class="passcolor text-truncate" pTooltip="{{ (card?.recordData)[2]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
          <small pTooltip="{{ (card?.recordData)[2]?.displayName }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
        <li class="text col-3">
          <span class="passcolor text-truncate" pTooltip="{{ (card?.recordData)[3]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
          <small pTooltip="{{ (card?.recordData)[3]?.displayName }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 3,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
        <li class="text col-3">
          <span class="passcolor text-truncate" pTooltip="{{ (card?.recordData)[4]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template>
          </span>
          <small pTooltip="{{ (card?.recordData)[4]?.displayName }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 4,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '146'">
  <div
    class="Sponsor-3 Sponsor-template-3"
    CardDesignConfig
    [cardHoveredColor]="cardHoveredColor"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="sponsors-top">
      <div class="left-entity-dets">
        <span class="passcolor" pTooltip="{{ (card?.recordData)[0]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </span>
        <small pTooltip="{{ (card?.recordData)[0]?.displayName }}" tooltipPosition="top"
          ><ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 0,
                cardDisplay: cardDisplay,
                isSingleDisplay: true
              }
            }"
          ></ng-template
        ></small>
      </div>
      <div class="right-entity-icons passcolor template-btns">
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: { position: 0, class: 'en-icon template-btns en-icon-4', cardDisplay: cardDisplay, isSponser: true }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{
            data: { position: 1, class: 'en-icon template-btns en-icon-5', cardDisplay: cardDisplay, isSponser: true }
          }"
        ></ng-template>
      </div>
    </div>
    <ul class="sponsor-status">
      <li class="text col-6">
        <span class="passcolor" pTooltip="{{ (card?.recordData)[1]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </span>
        <small pTooltip="{{ (card?.recordData)[1]?.displayName }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,
                cardDisplay: cardDisplay,
                isSingleDisplay: true
              }
            }"
          ></ng-template>
        </small>
      </li>
      <li class="text col-6">
        <span class="passcolor" pTooltip="{{ (card?.recordData)[2]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                isSingleValueWOC: true
              }
            }"
          ></ng-template>
        </span>
        <small pTooltip="{{ (card?.recordData)[2]?.displayName }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,
                cardDisplay: cardDisplay,
                isSingleDisplay: true
              }
            }"
          ></ng-template>
        </small>
      </li>
    </ul>
  </div>
</div>
<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '147'">
  <div class="col-12">
    <div
      class="Sponsor-3 Sponsor-template-3"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div class="sponsors-top">
        <div class="left-entity-dets">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[0]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[0]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template
          ></small>
        </div>
        <div class="right-entity-icons passcolor template-btns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'en-icon template-btns en-icon-2', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
        </div>
      </div>
      <ul class="sponsor-status">
        <li class="text col-6">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[1]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[1]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
          >
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
        <li class="text col-6">
          <span
            class="passcolor text-truncate"
            pTooltip="{{ (card?.recordData)[2]?.value }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small
            class="text-truncate"
            pTooltip="{{ (card?.recordData)[2]?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
          >
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 2,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </small>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="entitytemplates Sponsor type-templates row" *ngIf="templateType == '148'">
  <div class="col-12">
    <div
      class="Sponsor-3 Sponsor-template-3"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle"
    >
      <div class="sponsors-top">
        <div class="left-entity-dets">
          <span class="passcolor" pTooltip="{{ (card?.recordData)?.[0]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleValueWOC: true
                }
              }"
            ></ng-template
          ></span>
          <small pTooltip="{{ (card?.recordData)?.[0]?.displayName }}" tooltipPosition="top"
            ><ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template
          ></small>
        </div>

        <div class="right-entity-icons passcolor template-btns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, class: 'en-icon template-btns en-icon-2', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 1, class: 'en-icon template-btns en-icon-6', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 2, class: 'en-icon template-btns en-icon-1', cardDisplay: cardDisplay, isSponser: true }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="stringer-child-item" *ngIf="templateType == '340'"
      CardDesignConfig
      [cardHoveredColor]="cardHoveredColor"
      [isEdit]="isEdit"
      [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
      [ngClass]="{
        'active' : cardSelected
      }"
      [id]="'Template-' + templateType"
      (emitHoverColor)="recieveHoverColor($event)"
      [_changeShadowStyle]="boxShadowData"
      [style]="style"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [cardBorderSize]="cardBorderSize"
      [cardBorderRadius]="cardBorderRadius"
      [cardBorderColor]="cardBorderColor"
      [cardShadow]="cardShadow"
      [cardColor]="cardColor"
      [card]="card"
      [cardDisplay]="cardDisplay"
      [(cardStyle)]="cardStyle">
  <ul class="stringer-child-header">
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 0,
          cardDisplay: cardDisplay,
          isSingleValue: true,
          class: 'only-value text-truncate'
        }
      }"
    ></ng-template>
  </ul>
  <ul class="stringer-child-tag-img-section">
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 1,
          cardDisplay: cardDisplay,
          isSingleValue: true,
          class: 'only-value  text-truncate'
        }
      }"
    ></ng-template>
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 2,
          cardDisplay: cardDisplay,
          isSingleValue: true,
          class: 'only-value  text-truncate'
        }
      }"
    ></ng-template>
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 3,
          cardDisplay: cardDisplay,
          isSingleValue: true,
          class: 'only-value  text-truncate'
        }
      }"
></ng-template>
  </ul>
  <ul class="stringer-child-detail-section">
      <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 4,
              isImage: true,
              class: 'card-logo text-truncate',
              cardDisplay: cardDisplay
            }
          }"
        ></ng-template>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 5,
            cardDisplay: cardDisplay,
            isSingleValue: true,
            class: 'only-value text-truncate'
          }
        }"
      ></ng-template>
  </ul>
  <ul class="stringer-child-final-section">
    <ng-template
      [ngTemplateOutlet]="attributeTemplate"
      [ngTemplateOutletContext]="{
        data: {
          position: 6,
          cardDisplay: cardDisplay,
          isSingleValue: true,
          class: 'only-value text-truncate'
        }
      }"
    ></ng-template>
      <div class="icon-section">
          <mat-icon>cached</mat-icon>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 7,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'only-value text-truncate'
              }
            }"
          ></ng-template>
      </div>
      <div class="icon-section icon-section2">
          <em class="nh-icon nh-icon-filter-small"></em>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 8,
                cardDisplay: cardDisplay,
                isSingleValue: true,
                class: 'only-value text-truncate'
              }
            }"
          ></ng-template>
      </div>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: {
            position: 9,
            cardDisplay: cardDisplay,
            isSingleValue: true,
            class: 'only-value text-truncate'
          }
        }"
      ></ng-template>
  </ul>
</div>
