import { Component, OnInit, Inject, Input, Optional, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import * as XLSX from 'xlsx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';
import { TransactionFacadeService } from '../transaction/transaction-facade.service';
import { officeFileMime } from '../modal/mimetypes';

type AOA = any[][];
@Component({
  selector: 'app-file-upload-preview',
  templateUrl: './file-upload-preview.component.html',
  styleUrls: ['./file-upload-preview.component.scss'],
})
export class FileUploadPreviewComponent implements OnInit {
  @Input() src: any;
  canDownload: boolean = true;
  fileToBeDownloaded: string;
  sheetData: AOA = [
    [1, 2],
    [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  rawSheetData: any;
  fileName: string = 'SheetJS.xlsx';
  isSheet: boolean = false;
  htmlMimeTypes = ['application/html','text/html'];
  audioMimeTypes = ['audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/x-mpeg-3'];
  imageMimeTypes = ['image/png', 'image/gif', 'image/pjpeg', 'image/jpeg', 'image/svg+xml', 'image/webp'];
  videoMimeTypes: any[] = ['video/x-msvideo', 'video/mp4', 'application/ogg', 'video/webm', 'video/x-ms-asf'];
  docMimeTypes = ['text/plain'];
  pdfMimeType = ['application/pdf'];
  rotatedAngle: any = 0;

  onlyForSheet: any[] = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  ngSubscribe = new Subject();
  labels: any;
  officeUrl: string;
  isOfficeType: boolean = false;
  isHyperlink: boolean = false;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(
    private alertService: AlertService,
    public dialogRef: MatDialogRef<FileUploadPreviewComponent>,
    private translator: TranslatorService,
    public dialogService: MatDialog,
    private transactionFacadeService: TransactionFacadeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.detectLanguageChange();
    this.fetchurl();
    /* istanbul ignore next */
    if(Object.keys(data).length!=0){
      this.src = data;
    }else if(this.src){
      data = this.src;
    }
    /* istanbul ignore next */
    if (data?.rawSheetData?.target?.files[0]) {
      this.rawSheetData = data.rawSheetData;
      this.onFileChange(this.rawSheetData);
    }
    this.src = data;
    this.canDownload = data.canDownload;
    /* istanbul ignore else */
    if (data.mimeType) {
      this.checkFileType();
    } else {
      this.isHyperlink = true;
      let type = data.contentUrl?.split(/[#?]/)[0].split('.').pop().trim();
      const allFileTypes = [
        ...this.audioMimeTypes,
        ...this.videoMimeTypes,
        ...this.docMimeTypes,
        ...this.onlyForSheet,
        ...this.imageMimeTypes,
        ...this.pdfMimeType,
        ...this.htmlMimeTypes,
      ];
      allFileTypes.forEach((Type) => {
        if (Type.split('/')[1] == type) {
          this.src.mimeType = Type;
        }
      });
    }

    // if (!this.rawSheetData && !this.isHyperlink) {
    //   this.src.mimeType = 'text/plain';
    // }
    this.fileToBeDownloaded = data?.displayName;
  }
  ngOnInit(): void {
    document.documentElement.style.setProperty(`--rotatedAngleOffset`, this.rotatedAngle + 'deg');
  }

  fetchurl(){
    /* istanbul ignore next */
    this.transactionFacadeService.filepreviewData$.pipe(takeUntil(this.ngSubscribe))
    .subscribe((res:any)=>{
      console.log(res)
      this.data = res;
      this.src=res;
    })
   }
  rotateLeft() {
    this.rotatedAngle = this.rotatedAngle - 90;
    let rem = this.rotatedAngle % 180;
    if ((rem >= 0 ? rem : -rem) == 90) {
      document.querySelector('.popup-div')?.classList.add('parentClass');
    } else {
      document.querySelector('.popup-div')?.classList.remove('parentClass');
    }
    document.documentElement.style.setProperty(`--rotatedAngleOffset`, this.rotatedAngle + 'deg');
  }

  rotateRight() {
    this.rotatedAngle = this.rotatedAngle + 90;
    let rem = this.rotatedAngle % 180;
    if ((rem > 0 ? rem : -rem) == 90) {
      document.querySelector('.popup-div')?.classList.add('parentClass');
    } else {
      document.querySelector('.popup-div')?.classList.remove('parentClass');
    }
    document.documentElement.style.setProperty(`--rotatedAngleOffset`, this.rotatedAngle + 'deg');
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  checkFileType() {
    const allFileTypes = [
      ...this.audioMimeTypes,
      ...this.videoMimeTypes,
      ...this.docMimeTypes,
      ...this.onlyForSheet,
      ...this.imageMimeTypes,
      ...this.pdfMimeType,
      ...this.htmlMimeTypes,
    ];
    if (this.checkOfficeType()) {
      return;
    }
    let index = allFileTypes.findIndex((fileType: any) => fileType === this.src.mimeType);
    /* istanbul ignore else */
    if (index === -1) {
      this.dialogRef.close();
      /* istanbul ignore next */
      this.alertService.showToaster(this.labels?.Preview_not_supported_for_this_file_format, '', 'error');
      /* istanbul ignore next */
      const download_index = this.src?.contentUrl?.indexOf('download=true');
      let downloadUrl = this.src.contentUrl;
      /* istanbul ignore else */
      if (download_index === -1) {
        downloadUrl = `${downloadUrl}?download=true`;
      }
      if (this.canDownload) {
        window.open(downloadUrl);
      }
    }
  }
  isPdfType() {
    /* istanbul ignore next */
    let index = this.pdfMimeType.findIndex((ele: any) => ele === this.src.mimeType);
    return index === -1 ? false : true;
  }

  isAudioType() {
    /* istanbul ignore next */
    let index = this.audioMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isHtmlType() {
    /* istanbul ignore next */
    let index = this.htmlMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isVideoType() {
    /* istanbul ignore next */
    let index = this.videoMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isImageType() {
    /* istanbul ignore next */
    let index = this.imageMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isFrameSupported() {
    /* istanbul ignore next */
    let index = this.docMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  checkOfficeType() {
    if (officeFileMime.has(this.src?.mimeType)) {
      this.officeUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        this.src?.contentUrl
      )}&embedded=true`;
      this.isOfficeType = true;

      return true;
    }
    return false;
  }
  downloadFile() {
    if (this.isSheet) {
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.sheetData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
    } else {
      this.transactionFacadeService.downloadFile(this?.src?.contentUrl, this.fileToBeDownloaded);
    }
  }
  ngOnDestroy() {
    this.ngSubscribe.next();
    this.ngSubscribe.complete();
  }
  closePopup() {
    this.closeDialog.emit();
    this.dialogService.closeAll();
  }

  onFileChange(rawSheetData: any) {
    const target: DataTransfer = <DataTransfer>rawSheetData.target;
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.sheetData = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.isSheet = true;
    };
    /* istanbul ignore next */
    reader.readAsBinaryString(target?.files[0]);
  }
  goBacktoDesignSystemPreview(selectedLayout: any) {
    this.dialogRef.close({ layoutSelected: selectedLayout });
  }
}
