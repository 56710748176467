<div class="table-main-wrapper">
  <div class="title">
      <h3>{{ labels?.Card_Configuration }} Card Configuration</h3>
  </div>
  <div class="select-action-type d-flex nh-column-gap-16">
    <div>
    <mat-label class="action-type">{{ labels?.Select_Action_Type || 'Select Action Type' }}</mat-label>
      <mat-select
        [(ngModel)]="selectionType"
        name="selectionName"
        id="selectionName"
        placeholder="{{ labels?.Select_Action_Type }}"
        class="d-block"
      >
        <mat-option selected value="">
          {{ labels?.Select || 'Select' }}
        </mat-option>
        <mat-option value="checkbox">
          {{ labels?.Check_Box || 'Check Box ' }}
        </mat-option>
        <mat-option value="radiobutton">
          {{ labels?.Radio_Button || 'Radio Button' }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <mat-slide-toggle class="enable-delete-toggle" [(ngModel)]="isDeleteButton">
        <span>
          <p class="nh-fs-14">Enable Delete Card</p>
        </span>
      </mat-slide-toggle>
    </div>
  </div>
    <app-base-drop
      [isGrammer]="true"
      [isCardConfig]="true"
      [isAdvancedFeature]="false"
      [nslAttributes]="nslattributes"
      [nslAttributesExpand]="nslattributesExpand"
      [attributes]="attributes"
      [isDeleteButton]="isDeleteButton"
      (cardConfigEvent)="getCardConfigData($event)"
    >
    </app-base-drop>

</div>
