<div
  class="{{ appliedClass }}"
  appDnd
  [isFolder]="isFolderUpload"
  (itemsDropped)="folderDropHandler($event)"
  (fileDropped)="onFileDropped($event)"
  *ngIf="!isMulti && !field?.isMulti && files?.length < 1"
>
  <div class="nh-mw-100" [ngClass]="foundObject ? 'form-custom-upload-drag' : 'ui-custom-upload-drag'">
    <!-- <figure class="upload-img">
      <img src="../../../../assets/img/ui-kits/upload.svg" />
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="28" viewBox="0 0 40 28">
        <path
          id="Icon_awesome-cloud-upload-alt"
          data-name="Icon awesome-cloud-upload-alt"
          d="M33.6,14.413A6.007,6.007,0,0,0,28,6.25a5.969,5.969,0,0,0-3.331,1.012A10,10,0,0,0,6,12.25c0,.169.006.337.012.506A9,9,0,0,0,9,30.25H32a8,8,0,0,0,1.6-15.837ZM24.588,18.25H20.5v7a1,1,0,0,1-1,1h-3a1,1,0,0,1-1-1v-7H11.413a1,1,0,0,1-.706-1.706l6.588-6.588a1,1,0,0,1,1.412,0l6.587,6.588a1,1,0,0,1-.706,1.706Z"
          transform="translate(0 -2.25)"
          fill="#fec1cb"
        />
      </svg>
    </figure> -->
    <figure class="upload-img" *ngIf="foundObject?.event">
      <i *ngIf="foundObject?.event === 'Option 1'" class="bi bi-upload"></i>
      <i *ngIf="foundObject?.event === 'Option 3'" class="bi bi-files"></i>
      <i *ngIf="foundObject?.event === 'Option 4'" class="bi bi-camera"></i>
      <i *ngIf="foundObject?.event === 'Option 5'" class="bi bi-camera-video"></i>
      <i *ngIf="foundObject?.event === 'Option 6'" class="bi bi-file-earmark-fill"></i>
      <i *ngIf="foundObject?.event === 'Option 8'" class="bi bi-cloud-arrow-up-fill"></i>
    </figure>
    <div class="select-file" *ngIf="foundObject; else other_label">
      {{
        foundObject?.event === 'Option 1'
          ? 'Click to upload or drag and drop'
          : foundObject?.event === 'Option 2'
          ? 'Upload file'
          : foundObject?.event === 'Option 3'
          ? 'Drop file(s) here...'
          : foundObject?.event === 'Option 4'
          ? 'Add Image'
          : foundObject?.event === 'Option 5'
          ? 'Add Video'
          : foundObject?.event === 'Option 7'
          ? 'Drag Files Or Choose File'
          : foundObject?.event === 'Option 8'
          ? 'Upload All Files'
          : ''
      }}
      <div *ngIf="foundObject?.event === 'Option 6'">Drag your file here or <span>Choose</span></div>
    </div>
    <ng-template #other_label>
      <div class="select-file">
        {{ labels?.Drop_files_here }}
      </div>
    </ng-template>
  </div>
  <div [ngClass]="foundObject ? 'input-display' : 'ui-custom-upload-drag upload-browse'">
    <input
      class="cur-pointer"
      *ngIf="!isFolderUpload || !isFolder"
      [disabled]="(field?.readonly || files?.length === 1) && !isMulti"
      [required]="field?.isRequired && !field?.isInfo"
      type="file"
      #fileInput
      accept="{{ field?.type === 'redact' ? '.pdf,.DCM,image/*' : '*' }}"
      id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}_{{ instanceNumber }}"
      (change)="fileBrowseHandler($event)"
    />
    <input
    *ngIf="isFolderUpload && isFolder"
    [disabled]="(field?.readonly || files?.length === 1) && !isMulti"
    [required]="field?.isRequired && !field?.isInfo"
    type="file"
    #fileInput
    multiple
    webkitdirectory
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    (change)="fileBrowseHandler($event)"
  />
    <label
      class="mb-0 cur-pointer"
      [ngClass]="{ disabled: (field?.readonly || files?.length === 1) && !isMulti }"
      for="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}_{{ instanceNumber }}"
      id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}_{{ instanceNumber }}"
      ><a class="select-file-text" href="#"
        >{{
          foundObject?.event === 'Option 4'
            ? 'File supported: JPG, PNG, GIF'
            : foundObject?.event === 'Option 5'
            ? 'Max file size: 100 KB'
            : foundObject?.event === 'Option 6'
            ? 'File size should be less than 2mb'
            : labels?.BROWSE
        }}
        <figure class="upload-img">
          <img src="../../../../assets/img/ui-kits/upload.svg" />
        </figure> </a
    ></label>
  </div>
</div>
<div
  class="{{ appliedClass }}"
  appDnd
  [isFolder]="isFolderUpload"
  (itemsDropped)="folderDropHandler($event)"
  (fileDropped)="onFileDropped($event)"
  *ngIf="(isMulti || field?.isMulti) && !field?.readonly"
>
  <div
    class="nh-mw-100"
    [ngClass]="foundObject ? 'form-custom-upload-drag' : 'ui-custom-upload-drag'"
    *ngIf="files?.length == 0 || isDesignTime"
  >
    <!-- <figure class="upload-img">
      <img src="../../../../assets/img/ui-kits/upload.svg" />
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="28" viewBox="0 0 40 28">
        <path
          id="Icon_awesome-cloud-upload-alt"
          data-name="Icon awesome-cloud-upload-alt"
          d="M33.6,14.413A6.007,6.007,0,0,0,28,6.25a5.969,5.969,0,0,0-3.331,1.012A10,10,0,0,0,6,12.25c0,.169.006.337.012.506A9,9,0,0,0,9,30.25H32a8,8,0,0,0,1.6-15.837ZM24.588,18.25H20.5v7a1,1,0,0,1-1,1h-3a1,1,0,0,1-1-1v-7H11.413a1,1,0,0,1-.706-1.706l6.588-6.588a1,1,0,0,1,1.412,0l6.587,6.588a1,1,0,0,1-.706,1.706Z"
          transform="translate(0 -2.25)"
          fill="#fec1cb"
        />
      </svg>
    </figure> -->
    <figure class="upload-img" *ngIf="foundObject?.event">
      <i *ngIf="foundObject?.event === 'Option 1'" class="bi bi-upload"></i>
      <i *ngIf="foundObject?.event === 'Option 3'" class="bi bi-files"></i>
      <i *ngIf="foundObject?.event === 'Option 4'" class="bi bi-camera"></i>
      <i *ngIf="foundObject?.event === 'Option 5'" class="bi bi-camera-video"></i>
      <i *ngIf="foundObject?.event === 'Option 6'" class="bi bi-file-earmark-fill"></i>
      <i *ngIf="foundObject?.event === 'Option 8'" class="bi bi-cloud-arrow-up-fill"></i>
    </figure>
    <div class="select-file" *ngIf="foundObject; else other_label">
      {{
        foundObject?.event === 'Option 1'
          ? 'Click to upload or drag and drop'
          : foundObject?.event === 'Option 2'
          ? 'Upload file'
          : foundObject?.event === 'Option 3'
          ? 'Drop file(s) here...'
          : foundObject?.event === 'Option 4'
          ? 'Add Image'
          : foundObject?.event === 'Option 5'
          ? 'Add Video'
          : foundObject?.event === 'Option 7'
          ? 'Drag Files Or Choose File'
          : foundObject?.event === 'Option 8'
          ? 'Upload All Files'
          : ''
      }}
      <div *ngIf="foundObject?.event === 'Option 6'">Drag your file here or <span>Choose</span></div>
    </div>
    <ng-template #other_label>
      <div class="select-file">
        {{ labels?.Drop_files_here }}
      </div>
    </ng-template>
  </div>

  <div class="ui-custom-upload-drag upload-browse" *ngIf="files?.length == 0 || isDesignTime">
    <input
    *ngIf="!isFolderUpload || !isFolder"
    [disabled]="field?.readonly"
    [required]="field?.isRequired && !field?.isInfo"
    type="file"
    #fileInput
    multiple
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    (change)="fileBrowseHandler($event)"
  />
  <input
    *ngIf="isFolderUpload && isFolder"
    [disabled]="field?.readonly"
    [required]="field?.isRequired && !field?.isInfo"
    type="file"
    #fileInput
    multiple
    webkitdirectory
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    (change)="fileBrowseHandler($event)"
  />
    <label
      class="mb-0"
      for="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
      id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
      ><a class="select-file-text" href="#"
        >{{
          foundObject?.event === 'Option 4'
            ? 'File supported: JPG, PNG, GIF'
            : foundObject?.event === 'Option 5'
            ? 'Max file size: 100 KB'
            : foundObject?.event === 'Option 6'
            ? 'File size should be less than 2mb'
            : labels?.BROWSE
        }}
        <figure class="upload-img">
          <img src="../../../../assets/img/ui-kits/upload.svg" />
        </figure> </a
    ></label>
  </div>
</div>

<!-- <div class="container-upload" appDnd (fileDropped)="onFileDropped($event)" *ngIf="isMulti && !field?.readonly">
  <input
    [disabled]="field?.readonly"
    [required]="field?.isRequired"
    type="file"
    #fileInput
    multiple
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    (change)="fileBrowseHandler($event)"
  />
  <h3 class="dropfile-text">{{ labels?.Drop_files_here }}</h3>
  <label
    for="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    >{{ labels?.BROWSE }}</label
  >
</div> -->
<div class="files-list mt-0">
  <div *ngIf="!infoWarnMessage">
    <div class="single-file uploaded-file-lists" *ngFor="let file of files; let i = index">
      <div class="files-list-panel">
        <div class="file-list-type" *ngIf="file?.status === 'success'">
          <h4
            class="name"
            [title]="file && file.originalFileName ? file?.originalFileName : file?.name"
            *ngIf="!field?.configuration?.hideFileInfo"
          >
            <span *ngIf="file?.originalFileName; else name">{{ file?.originalFileName }}</span>
            <ng-template #name>{{ file?.name }}</ng-template>
          </h4>
          <div class="file-list-view">
            <div class="d-flex align-items-center">
              <mat-icon
                class="material-icon-align success-icon-cls"
                *ngIf="file?.status === 'success'"
                pTooltip="File Uploaded"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
              >
                check_circle_outline
              </mat-icon>
              <img
              *ngIf="
              !fileUploadFromEntity &&
              !field?.configuration?.hidePreview &&
              (!imageTypes.has(file.mimeType) || isRedac)
            "
                [src]="'assets/images/eye.svg' | CDNUrlRewritePipe"
                class="ml-2 eye-icon"
                [ngClass]="{ disbaled: file?.status !== 'success' }"
                (click)="onPreviewClick(file, i)"
                alt=""
                pTooltip="View"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
              />
              <img
                *ngIf="
                  !fileUploadFromEntity &&
                  !field?.configuration?.hidePreview &&
                  imageTypes.has(file.mimeType) &&
                  !isRedac
                "
                [src]="'assets/images/eye.svg' | CDNUrlRewritePipe"
                class="ml-2 eye-icon"
                [ngClass]="{ disbaled: file?.status !== 'success' }"
                (click)="openImageZoom(file)"
                alt=""
                pTooltip="View"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
              />
              <div *ngIf="!fileUploadFromEntity && !field?.configuration?.hideOpenInNewTab" class="tab-icons">
                <img
                  src="../../../../assets/images/new-tab.svg"
                  class="ml-2 tab-color cur-pointer"
                  (click)="openInNewTab(file, i)"
                  alt="openInNewTab"
                  pTooltip="Open in new tab"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                />
              </div>
              <div class="file-icons">
                <img
                  *ngIf="!field?.readonly && !field?.configuration?.hideDeletion"
                  class="ml-2"
                  [src]="'../../../../assets/images/delete_box.svg' | CDNUrlRewritePipe"
                  [ngClass]="{ disbaled: !field?.value && field?.readonly }"
                  (click)="deleteFile(i)"
                  alt=""
                  pTooltip="Delete"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="file-uploading d-flex justify-content-between align-items-center progress"
          *ngIf="file?.status === 'inprogress'"
        >
          <h4 class="text-truncate progress progress-spinner-cls">Uploading (1)</h4>
          <em class="nh-icon nh-icon-x-lg" (click)="deleteFile(i)"></em>
        </div>
      </div>
      <div class="progress">
        <mat-spinner class="progress-spinner-cls" *ngIf="file?.status === 'inprogress'"></mat-spinner>
        <div *ngIf="file?.status === 'inprogress'" class="fu-uploading"></div>
        <div *ngIf="file?.status === 'success'" class="fu-success"></div>
        <div *ngIf="file?.status === 'failed'" class="fu-failed"></div>

        <!-- <mat-icon class="material-icon-align success-icon-cls"
          >error_outline
        </mat-icon> -->
      </div>

      <div class="info">
        <div class="info-data-cls">
          <p class="size">
            {{ formatBytes(file?.size) }}
          </p>
        </div>
        <!-- <div class="status-container">
          <div class="status-msg">
            <span *ngIf="file?.status === 'success'">{{ labels?.Success }}!</span>
            <span *ngIf="file?.status === 'inprogress'">{{ labels?.In_progress }}!</span>
            <span *ngIf="file?.status === 'failed'">{{ labels?.Failed }}!</span>
          </div>
        </div> -->
      </div>
    </div>
    <button
      type="button"
      *ngIf="!field?.readonly && (field?.value || isDesignTime) && isMulti"
      class="remove-all"
      (click)="onRemoveAllClick()"
    >
      {{ labels?.Remove_All }}
    </button>
  </div>

  <ng-container *ngFor="let validation of field?.validations" ngProjectAs="mat-error">
    <mat-error
      *ngIf="
        validation.type &&
        (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
        infoWarnMessage &&
        !validation.isValidFile
      "
      >{{ validation.message }}</mat-error
    >
    <mat-error
      [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
      *ngIf="
        validation.type &&
        validation.type !== 'ERROR' &&
        validation.type !== 'BLOCK_WARN' &&
        infoWarnMessage &&
        !validation.isValidFile
      "
      >{{ validation.message }}</mat-error
    >
  </ng-container>
</div>

<ng-template #editImage>
  <div mat-dialog-content>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="false"
      [cropperMinWidth]="130"
      [canvasRotation]="canvasRotation"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
    ></image-cropper>
  </div>
  <div mat-dialog-actions class="btn-wrap text-center file-btns-info">
    <span class="cstmsize"> File Size: {{ formatBytes(viewFileSize?.size) }} </span>
    <div>
      <button mat-button class="btn-1" (click)="uploadFile()">{{ labels?.Upload }}</button>
      <button mat-button class="btn-1" (click)="cancel()">{{ labels?.Cancel }}</button>
    </div>
    <div class="editImageRotate nh-display-flex">
      <mat-icon class="cur-pointer" (click)="rotateLeft()">rotate_left</mat-icon>
      <mat-icon class="cur-pointer" (click)="rotateRight()">rotate_right</mat-icon>
    </div>
    <span class="cstmsize1">Resolution: {{ height }}px X {{ width }}px </span>
  </div>
</ng-template>
<div class="hide-fileinfo" *ngIf="isInfoOpen && !isFolderUpload && !isFolder">
  Hide File Info
  <mat-slide-toggle id="hideFileInfo_{{attrval}}" [(ngModel)]="isHideFileInfo" (change)="hideFileChange($event)"></mat-slide-toggle>
</div>
