<div class="paginator-container"
(mouseover)="paginationHovered = true"
(mouseout)="paginationHovered = false"
[ngStyle]="{ 
  'flex-direction': paginationJustify?.viewClass == 'flex-start' ? 'row-reverse' : '',
  'background-color': paginationHovered ? paginationStyle?.['backgroundHover'] : paginationStyle?.['background'],
  'color': paginationHovered ? paginationStyle?.['textHover'] : paginationStyle?.['font'],
  'border-color': paginationHovered ? paginationStyle?.['borderHover'] : paginationStyle?.['border']
 }"
 [ngClass]="{'defi-solution': tenantName?.includes('defisolutions')}">
  <span *ngIf="!tenantName?.includes('defisolutions')" class="pages-count"
    >{{ (index - 1) * pageSize + 1 }}-{{ index * pageSize <= length ? index * pageSize : length }} of
    {{ length }} items</span>
    <span *ngIf="tenantName?.includes('defisolutions')" class="pages-count">{{ length }} items</span>
  <ol class="page-numbers"  [ngStyle]="{ 'justify-content': paginationJustify?.viewClass == 'flex-start' ? 'flex-start' : paginationJustify?.viewClass == 'center' ? 'center' : 'flex-end' }">
    <li (click)="navigateToPage(1)">
      <em class="nh-icon nh-icon-backword" *ngIf="!tenantName?.includes('defisolutions')"></em>
      <img  *ngIf="tenantName?.includes('defisolutions')" src="../../../../../../assets/img/general-entity/paginator/pagination-arrows-first.svg" alt="">
    </li>
    <li (click)="navigateToPage(index - 1)">
      <em class="nh-icon nh-icon-previous" *ngIf="!tenantName?.includes('defisolutions')"></em>
      <img  *ngIf="tenantName?.includes('defisolutions')" src="../../../../../../assets/img/general-entity/paginator/pagination-arrows-left.svg" alt="">
    </li>
    <li (click)="navigateToPage(1)" *ngIf="pagination.pages[0] !== 1">
      1
    </li>
    <li *ngIf="pagination.pages[0] !== 1 && pagination.pages[0] > 2">
      ...
    </li>
    <li
      *ngFor="let page of pagination.pages; trackBy: trackByFn"
      id="{{ page }}"
      [class.active]="page === pagination.index"
      (click)="navigateToPage(page)"
    >
      {{ page }}
    </li>
    <li *ngIf="pagination.pages[rulerLength - 1] !== maxPages && pagination.pages[rulerLength - 1] < maxPages - 1">
      ...
    </li>
    <li (click)="navigateToPage(maxPages)" *ngIf="pagination.pages[rulerLength - 1] !== maxPages">
      {{ maxPages }}
    </li>
    <li (click)="navigateToPage(index + 1)">
      <em class="nh-icon nh-icon-next" *ngIf="!tenantName?.includes('defisolutions')"></em>
      <img class="scale" *ngIf="tenantName?.includes('defisolutions')" src="../../../../../../assets/img/general-entity/paginator/pagination-arrows-left.svg" alt="">
    </li>
    <li (click)="navigateToPage(maxPages)">
      <em class="nh-icon nh-icon-forward" *ngIf="!tenantName?.includes('defisolutions')"></em>
      <img class="scale" *ngIf="tenantName?.includes('defisolutions')" src="../../../../../../assets/img/general-entity/paginator/pagination-arrows-first.svg" alt="">
    </li>
    <div class="page-dropdown">
      <p-dropdown [options]="pageSizeOptions" [(ngModel)]="pageSize" appendTo="body" panelStyleClass="pagination-overlay" (onChange)="changePageSize($event)"></p-dropdown>
      <span>item per page</span>
    </div>
  </ol>
</div>
