import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, validateDependentExpression } from '@common-services';

@Component({
  selector: 'app-hyperlink',
  template: `
      <form class="example-form" [formGroup]="group">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
          class="main-label nh-mb-0"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          >
            <span class="main-label-inner" >
              <span class="main-label-text text-truncate">{{ field?.label }} :</span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
      </div>
      <div class="custom-hyperlink">
        <!-- <a *ngFor="let option of field.options" [href]="option" target="_blank">{{ option }}</a> -->
        <a
          [href]="field.value"
          target="_blank"
          class="text-break anchor-link"
          id="{{ this.field?.attribute?.name + '_' + this.field?.entityName }}"
          >{{ field?.configuration?.displayText ? field?.configuration?.displayText : field.value }}
        </a>
        <span
          *ngIf="field?.configuration?.copyLink"
          [cdkCopyToClipboard]="field?.value"
          class="material-icons cursor-pointer nh-fs-12 nh-lh-17"
          >content_copy</span
        >
        <div class="d-flex">
          <a class="d-flex" (click)="downloadFile()" *ngIf="canDownload">
            <span class="material-icons ml-3">
              cloud_download
            </span>
          </a>
        </div>
      </div>
      <!-- <a *ngFor="let option of field.options" [href]="option" target="_blank">{{ option }}</a> -->
      <!-- <a [href]="field.value" target="_blank" class="text-break anchor-link">{{ field.value }}</a> -->
      <!-- <a
        [href]="field.value"
        target="_blank"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        class="text-break anchor-link"
        >{{ field?.configuration?.displayText ? field?.configuration?.displayText : field.value }}</a
      > -->
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </form>
  `,
  styleUrls: ['./hyperlink.component.scss'],
})
export class HyperlinkComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  checked: boolean;
  src: any;
  canDownload: boolean = false;
  audioMimeTypes = ['audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/x-mpeg-3'];
  videoMimeTypes: any[] = ['video/x-msvideo', 'video/mp4', 'application/ogg', 'video/webm', 'video/x-ms-asf'];
  docMimeTypes = [
    'text/plain',
    'application/pdf',
    'image/png',
    'image/gif',
    'image/pjpeg',
    'image/jpeg',
    'image/svg+xml',
    'image/webp',
  ];

  constructor(private dialogService: MatDialog, private transactionFacadeService: TransactionFacadeService, private eventsService: EventsFacadeService) {}

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field.configuration?.autoRedirect) {
      this.transactionFacadeService.redirectWithoutErrorPopup.next(true);
      /* istanbul ignore next */
      setTimeout(() => {
        document.getElementById(this.field?.attribute?.name + '_' + this.field.entityName).click();
      }, 100);
    }

    /* istanbul ignore next */
    if (this.field?.canDownload) {
      this.canDownload = this.field?.canDownload;
    }
    /* istanbul ignore next */
    if (this.field?.value && this.field?.value !== '' && this.IsJsonString(this.field?.value)) {
      let jsonObj: any = JSON.parse(this.field.value);
      /* istanbul ignore next */
      if (jsonObj?.contentUrl) {
        const allFileTypes = [...this.audioMimeTypes, ...this.videoMimeTypes, ...this.docMimeTypes];
        /* istanbul ignore next */
        const index = allFileTypes?.findIndex((fileType: any) => fileType === jsonObj?.mimeType);
        /* istanbul ignore next */
        const download_index = jsonObj?.contentUrl?.indexOf('download=true');
        if (index === -1 && download_index === -1) {
          this.field.value = `${jsonObj.contentUrl}?download=true`;
        } else {
          /* istanbul ignore next */
          this.src = {
            mimeType: jsonObj?.mimeType,
            contentUrl: jsonObj?.contentUrl,
            canDownload: this.canDownload,
          };
          /* istanbul ignore next */
          this.field.value = jsonObj?.contentUrl;
        }
      }
    } else {
      /* istanbul ignore next */
      if (
        this.field?.value &&
        typeof this.field?.value === 'string' &&
        this.field?.value !== '' &&
        this.field?.value?.includes('http')
      ) {
        this.src = { contentUrl: this.field.value };
      }
    }
    if (this.field.configuration?.attributeMapping) {
      let validation = { name: 'hyperLink', dependentExpression: this.field.configuration.attrPath };
      this.field.configuration['displayText'] = validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
  }

  downloadFile() {
    /* istanbul ignore next */
    this.transactionFacadeService.downloadFile(this?.src?.contentUrl);
  }
  // onPreviewClick() {
  //   this.dialogService.open(FileUploadPreviewComponent, {
  //     data: this.src,
  //     panelClass: 'fileuploadpreviews',
  //   });
  // }
  openInOtherPage(url: string) {
    window.open(url, '_blank');
  }
}
