<div class="measure">
  <div class="measure-heading">
    <h2 class="heading">Measure</h2>

    <div class="measure-actions">
      <button (click)="saveGSI()" class="btn btnsave" [disabled]="!saveFlag">save</button>

      <span class="cancel">
        <figure (click)="onCloseDialog()">
          <img [src]="'../../../../../assets/img/measure/cancel.svg'" alt="Cancel" />
        </figure>
      </span>
    </div>
  </div>
  <div class="special-measure">
    <h3 class="heading">
      Add Measures
    </h3>
  </div>
  <div class="measure-box">
    <div class="boxes">
      <div class="entity-box">
        <div class="info">
          <span>
            <h3 class="name">Entity</h3>
          </span>
          <span class="info-icon">
            <i
              class="bi bi-info-circle"
              pTooltip="The user is expected to create the entity or the entity name with the measure attributes."
              tooltipPosition="right"
              tooltipStyleClass="transcustomtolltipbook"
            >
            </i>
          </span>
        </div>
        <div><input [(ngModel)]="createNewEntity" class="box" type="text" placeholder="Entity Name" /></div>
      </div>

      <!-- <div *ngFor="let att of attributes_Arr; let i = index">
        <div class="attribute-box">
          <div class="info">
            <span><h3 class="name">Attribute</h3></span>
            <span><i class="bi bi-info-circle"></i></span>
          </div>
          <div><input class="box" type="text" [(ngModel)]="att.key" placeholder="Attribute Name" /></div>
          <span (click)="deleteAttribute(i)" class="cancel"><i class="bi bi-x-lg"></i></span>
        </div>
      </div>
      <div class="select-box">
        <div class="box">
          <span><i (click)="addAttributes()" class="bi bi-plus-lg"></i></span> -->
      <!-- </div>
      </div> -->

      <div class="attributes-wrap" *ngFor="let att of attributes_Arr; let i = index">
        <div class="attribute-box">
          <div class="info">
            <span>
              <h3 class="name">Attribute</h3>
            </span>
            <span class="info-icon">
              <i
                class="bi bi-info-circle"
                pTooltip="The user is expected to give the attributes for the measures. Ex: Rating, Message for Delivery, Emoji, Delivery Feedback"
                tooltipPosition="right"
                tooltipStyleClass="transcustomtolltipbook"
              >
              </i>
            </span>
          </div>
          <div class="formgroup">
            <div class="formgroup-box selected-uicontrol">
              <span (click)="deleteAttribute(i)" class="cancel-before"><i class="bi bi-x-lg"></i></span>
              <div><input class="box" type="text" [(ngModel)]="att.key" placeholder="Attribute Name" /></div>
              <span class="cancel"><i class="bi bi-x-lg"></i></span>
              <figure (click)="openUiElement(i)" class="uicontrolicon">
                <img src="../../../../../../assets/img/addmeasures/uicoontrol-icon.svg" />
              </figure>
              <div *ngIf="openUiElementType && i == openUiElementIndex" class="uicontrollist">
                <div class="uicontrol-search">
                  <input
                    type="text"
                    (keyup)="findAllUiElements()"
                    [(ngModel)]="searchUiElement"
                    class="form-control"
                    placeholder="Search"
                  />
                </div>

                <ul *ngIf="allUiElements?.length > 0">
                  <li *ngFor="let data of allUiElements">
                    <!-- <input
                      type="text"
                      (keyup)="findAllUiElements()"
                      [(ngModel)]="searchUiElement"
                      class="select-ui-control w-100"
                      placeholder="search"
                    /> -->

                    <div class="uicontrol-list">
                      <span class="select-ui-element"
                        ><span>{{ data.name }}</span>
                        <figure>
                          <img
                            [src]="'assets/images/plus-icon.png ' | CDNUrlRewritePipe"
                            id="eventsPlusClick"
                            (click)="addUiElementToAttribute(data, i)"
                            alt=""
                          />
                        </figure>
                      </span>
                    </div>
                  </li>
                </ul>
                <!-- <div class="btngroup">
                  <button class="btn btn-done" (click)="openUiElement(i)">Done</button>
                  <button class="btn btn-canel">Cancel</button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isEntity" class="addmore">
        <button (click)="addAttributes()" class="plus-btn">
          <i class="bi bi-plus-lg"></i>
        </button>
      </div>
    </div>
    <div class="validate-btn">
      <mat-icon class="red-delete-icon" (click)="deleteEnity()">delete</mat-icon>
      <button (click)="createEntity()">Validate</button>
    </div>
    <div class="add-measres">
      <div [ngClass]="{ 'add-condition': enableConditions, 'add-condition-inactive': !enableConditions }">
        <div class="measure-select">
          <h5>{{ this.selectedAttribute?.displayName }}</h5>
        </div>
        <div class="addcondition-right">
          <h3 (click)="addConditions()" class="heading">+ Add Condition</h3>
          <!-- <figure>
            <img src="../../../../../../assets/img/addmeasures/delete.svg" />
          </figure> -->
        </div>
      </div>

      <div class="add-measure-box">
        <div class="boxes" *ngFor="let condition of conditions; let i = index">
          <figure class="selected-delete d-flex justify-content-end">
            <img (click)="deleteCondition(i)" src="../../../../../../assets/img/addmeasures/delete.svg" />
          </figure>
          <div>
            <div class="info">
              <span>
                <h3 class="name">Operator</h3>
              </span>
              <span class="info-icon"><i class="bi bi-info-circle"></i></span>
            </div>
            <div [id]="'operator' + i" class="Operators">
              <mat-form-field appearance="fill">
                <mat-label *ngIf="operators[i].operator == ''">Select</mat-label>
                <mat-select [(ngModel)]="operators[i].operator">
                  <mat-option *ngFor="let operator of operatorsDropdown" [value]="operator">
                    {{ operator }}
                  </mat-option>
                  <!-- <mat-option [value]="&gt;"> &gt;</mat-option>
                  <mat-option [value]="&le;"> &le; </mat-option>
                  <mat-option [value]="&ge;"> &ge;</mat-option>
                  <mat-option [value]="="> = </mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="Value-attr">
            <div class="info">
              <span>
                <h3 class="name">Value / Referral Attribute</h3>
              </span>
              <span class="info-icon"><i class="bi bi-info-circle"></i></span>
            </div>
            <div>
              <input
                [(ngModel)]="operators[i].value"
                (change)="setCondition(i)"
                class="number"
                type="text"
                placeholder="Enter Value"
              />
            </div>
          </div>

          <div class="groupform" *ngFor="let attribute of condition.valueMap; let index = index">
            <div>
              <label>{{ attribute.attributeName }}</label>
              <div
                *ngIf="
                  attributes_Arr[index]?.uiElement?.uiElement != 'file' &&
                  attributes_Arr[index]?.uiElement?.uiElement != 'FileWithIRDR'
                "
                class="formgroup-addon"
              >
                <input [(ngModel)]="conditions[i].valueMap[index].attributeValue" type="text" placeholder="Text" />
                <!-- <span class="input-remove"><img src="../../../../../../assets/img/addmeasures/delete.svg" /></span> -->
              </div>
              <div
                *ngIf="
                  attributes_Arr[index]?.uiElement?.uiElement == 'file' ||
                  attributes_Arr[index]?.uiElement?.uiElement == 'FileWithIRDR'
                "
                class="formgroup-addon"
              >
                <input
                  [(ngModel)]="conditions[i].valueMap[index].attributeValue"
                  type="text"
                  placeholder="Drop file here"
                  readonly
                />
                <span class="input-attach" (click)="attachDialog(i, index, attachmodal, attribute)"
                  ><img src="../../../../../../assets/img/addmeasures/attach.svg"
                /></span>
                <!-- <span class="input-remove"><img src="../../../../../../assets/img/addmeasures/delete.svg" /></span> -->
              </div>
            </div>
          </div>
          <!-- <div class="groupform">
            <label>Emoji</label>
            <div class="formgroup-addon addonbefore">
              <input type="text" placeholder="Drop file here" />
              <span class="input-attach" (click)="attachDialog()"
                ><img src="../../../../../../assets/img/addmeasures/attach.svg"
              /></span>
              <span class="input-remove"><img src="../../../../../../assets/img/addmeasures/delete.svg" /></span>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="add-measure-box add-measure-box-selected">
        <figure class="selected-delete">
          <img src="../../../../../../assets/img/addmeasures/delete.svg" />
        </figure>
        <div class="boxes">
          <div>
            <div class="info">
              <span>
                <h3 class="name">Operator</h3>
              </span>
              <span class="info-icon"><i class="bi bi-info-circle"></i></span>
            </div>
            <div class="Operators">
              <mat-form-field appearance="fill">
                <mat-label>Select</mat-label>
                <mat-select>
                  <mat-option>Employee Id</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="Value-attr">
            <div class="info">
              <span>
                <h3 class="name">Value / Referral Attribute</h3>
              </span>
              <span class="info-icon"><i class="bi bi-info-circle"></i></span>
            </div>
            <div>
              <input class="number" type="text" placeholder="Enter Value" />
            </div>
          </div>

          <div class="groupform">
            <label>Expression</label>
            <div class="formgroup-addon">
              <input type="text" placeholder="Text" />
              <span class="input-remove"><img src="../../../../../../assets/img/addmeasures/delete.svg" /></span>
            </div>
          </div>
          <div class="groupform">
            <label>Emoji</label>
            <div class="formgroup-addon addonbefore">
              <input type="text" placeholder="Drop file here" />
              <span class="input-attach" (click)="attachDialog()"
                ><img src="../../../../../../assets/img/addmeasures/attach.svg"
              /></span>
              <span class="input-remove"><img src="../../../../../../assets/img/addmeasures/delete.svg" /></span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<!--
<div
  class="custom-radio-btns multi-file-upload"
  *ngIf="

  "
>
  <label style="margin-right: 60%;"> Upload </label>
  <app-file-upload
    [fileUploadFromEntity]="true"
    [editFileUrl]="editFileUrl"
    [isMulti]="multiDefaultValue"
    [fileType]="attrDetails?.value?.attributeUiElement"
    (deletedFileResponse)="deletedFileResponse($event)"
    (fileUploadResponse)="fileUploadUrl($event)"
  >
  </app-file-upload>
</div> -->
<ng-template #attachmodal let-attribute="attribute">
  <div class="attachmodal-wrap">
    <div class="modalheader">
      <h3>Attribute</h3>
      <mat-icon class="mat-close cursorPointer" (click)="closeFileUpload()">close </mat-icon>
      <figure mat-dialog-close>
        <!-- <img [src]="'../../../../../assets/img/measure/cancel.svg'" alt="Cancel" /> -->
      </figure>
    </div>
    <div class="modalbody">
      <div class="customfileuplod d-none">
        <div>
          <label class="fileupload" for="inputGroupFile01">
            <figure>
              <img src="../../../../../../assets/img/addmeasures/fileupload.JPG" />
            </figure>
          </label>
          <input type="file" class="form-control" id="inputGroupFile01" />
          <div class="upload-text">
            <span>
              Select File to Upload
            </span>
            <span>Or drag and drop, copy and paste files</span>
          </div>
        </div>
      </div>
      <div class="customfile-content">
        <ul class="content-listgroup">
          <li>
            <!-- <h3>Image</h3>
            <div class="content-area">
              <div class="content-left">
                <figure>
                  <img src="../../../../../../assets/img/addmeasures/upload-img.JPG" alt="Cancel" />
                </figure>
                <div class="content-text">
                  <h4>Emoji.jpeg</h4>
                  <p>20 KB</p>
                </div>
              </div>
              <div class="content-right">
                <figure>
                  <img src="../../../../../../assets/img/addmeasures/edit.svg" alt="Cancel" />
                </figure>
                <span class="cancel"><i class="bi bi-x-lg"></i></span>
              </div>
            </div> -->
          </li>
          <app-file-upload
            [fileUploadFromEntity]="true"
            [editFileUrl]="existingFileUrl"
            [isMulti]="false"
            [fileType]="fileType"
            (deletedFileResponse)="deletedFile($event)"
            (fileUploadResponse)="fileUploaded($event)"
          >
          </app-file-upload>
        </ul>
        <!-- <div class="upload-btns">
          <button class="btn btn-more">Upload more</button>
          <button class="btn btn-upload">
            <label class="fileupload" for="inputGroupFile01"> Upload</label>
            <input type="file" class="form-control" id="inputGroupFile01" />
          </button>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>
