import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  Optional,
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AlertService,
  AxisType,
  EntityWidget,
  entityWidgets,
  LibraryFacadeService,
  TranslatorService,
} from '@common-services';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';

@Component({
  selector: 'app-widget-configuration',
  templateUrl: './widget-configuration.component.html',
  styleUrls: ['./widget-configuration.component.scss'],
})
export class WidgetConfigurationComponent implements OnInit {
  attributeList: any[];
  entity: any;
  labels: any;
  componentType: any;
  @Input() set _entity(value: any) {
    this.entity = value.data?.entityData;
    this.attributeList = this.entity?.nslAttributes;
    this.attributeList.forEach((attribute) => (attribute.checked = false));
    this.attributeList.forEach((attribute) => {
      attribute.checked = false;
      if (attribute.attributeType?.type === 'decimalnumber') {
        attribute.attributeType.type = 'number';
      }
    });
  }
  saveData: any;
  widgetTypes: EntityWidget[] = entityWidgets;
  selectedWidget: EntityWidget;
  selectedWidgetIndex: number = -1;
  configuration: any = {};
  showCanvas: boolean = false;
  canvasChanged: number = 0;
  submitDisabled: boolean = true;
  @Output() dataEmitter: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private translatorService: TranslatorService,
    private entityFacadeService: NodeEntityFacadeService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private libraryFacadeService: LibraryFacadeService
  ) {
    if (data && data.type == 'hybrid') {
      this.componentType = data.type;
      this.entity = data;
      this.attributeList = this.entity?.nslAttributes;
      this.attributeList.forEach((attribute) => (attribute.checked = false));
      this.attributeList.forEach((attribute) => {
        attribute.checked = false;
        if (attribute.attributeType?.type === 'decimalnumber') {
          attribute.attributeType.type = 'number';
        }
      });
    }
    this.detectLangugaeTranslation();
  }

  ngOnInit(): void {}
  selectAll() {
    this.attributeList.forEach((attribute) => (attribute.checked = true));
  }
  selectWidget(index: number): void {
    this.configuration = {};
    this.submitDisabled = true;
    this.selectedWidgetIndex = index;
    this.selectedWidget = this.widgetTypes[index];
    this.selectedWidget.axises.forEach((x: AxisType) => {
      this.configuration[x.name] = -1;
    });
  }
  /*
   *detect labels
   */
  detectLangugaeTranslation() {
    this.translatorService.languageLables$.subscribe((res: any) => {
      this.labels = res;
    });
  }
  closeWidgetSetion(): void {
    this.entityFacadeService.widgetUIConfiguration(false, {});
    this.dialog.closeAll();
  }
  showCanvasJs() {
    this.showCanvas = true;
    this.canvasChanged = this.canvasChanged + 1;
  }

  saveWidget() {
    let keys = Object.keys(this.configuration);
    let attributes = [
      ...this.attributeList.map((attribute: any) => {
        return attribute.name;
      }),
    ];
    let attributMap: any[] = [];
    keys.forEach((key: any) => {
      let obj = {};
      obj[attributes[this.configuration[key]]] = key;
      attributMap.push(obj);
    });
    this.saveData = {
      type: 'widgets',
      setting: {
        attributes: attributMap,
        configuration: this.configuration,
        selectedWidget: this.selectedWidget,
      },
    };
    if (this.componentType == 'hybrid') {
      this.libraryFacadeService.saveWidgetData(this.saveData);
      this.dialog.closeAll();
    } else {
      // this.entity.configuration = JSON.stringify(this.saveData);
      this.dataEmitter.emit(this.saveData);
    }
  }
  validateForm(): void {
    let flag: boolean = false;
    Object.values(this.configuration).forEach(
      (value: number, index: number) => {
        if (value == -1 && !this.selectedWidget.axises[index].isOptional) {
          flag = true;
        }
      }
    );
    this.submitDisabled = flag;
  }
}
