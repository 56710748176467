<div>
  <ul *ngFor="let entity of layerData; let entityIndex = index" class="mt-20">
    <li class="cstm-attr-plus li-top-0">
      <mat-accordion class="w-100">
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="active-p">
              <p
                [matTooltipPosition]="'above'"
                matTooltipClass="tool-tip-custom"
                matTooltip="{{ entity?.name }}"
                class="ent-widt"
              >
                {{ entity?.name }}
              </p>
              <span class="w-auto ml-2">
                <img
                  (click)="addAttributetoDcd(entityIndex, entity?.name, entity?.id, null, null, entity)"
                  [src]="'../../../../../../assets/images/plusicon.svg' | CDNUrlRewritePipe"
                  alt=""
                  class="img-h25 hover-img hoverplusimg"
                />
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="attribute-list attribute-list-li" id="attributeList">
            <ng-container *ngIf="isPrimaryKeySearch === false">
              <app-node-entity-dcd
                [entityIndex]="entityIndex"
                [entity]="entity"
                [attr]="attr"
                [attrName]="entity?.name + '.' + attr?.name"
                [attrId]="entity?.id + '.' + attr.id"
                [cuName]="cuName"
                [layerName]="layerLabel"
                *ngFor="let attr of entity?.nslAttributes; let attrIndex = index"
                (attributeEmit)="addAttributeToDCD($event)"
                (entityEmit)="addEntityToDCD($event)"
              ></app-node-entity-dcd>
            </ng-container>
            <ng-container *ngIf="isPrimaryKeySearch === true">
              <app-node-entity-dcd
                [entityIndex]="entityIndex"
                [entity]="entity"
                [attr]="attr"
                [attrName]="entity?.name + '.' + attr?.name"
                [attrId]="entity?.id + '.' + attr.id"
                [cuName]="cuName"
                [layerName]="layerLabel"
                *ngFor="let attr of (entity?.uniqueConstraints)[0]; let attrIndex = index"
                (attributeEmit)="addAttributeToDCD($event)"
                (entityEmit)="addEntityToDCD($event)"
              ></app-node-entity-dcd>
            </ng-container>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</div>
