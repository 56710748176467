let transform = require('node-json-transform').transform;

export function eventsListMapper(data: any) {
  var map = {
    item: {
      id: 'id',
    },
  };
  return transform(data, map);
}
