
import { Component, Input, OnInit } from '@angular/core';
import { ContentChildren, EventEmitter, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { DraggableAttribute, GsiButton, ICardStyle, IStyle } from '@common-services';
import { AlertService } from '@common-services';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-templates-base',
  templateUrl: './templates-base.component.html',
  styleUrls: ['./templates-base.component.scss'],
})
export class TemplatesBaseComponent implements OnInit {
  @Input() cardSelected: any;
  @Input() styleAttribute: any;
  @Input() isCuCardsView: boolean;
  @Input() cardDisplay: string;
  @Input() txnDataIndex: any;
  @Input() gsiMapping: any;
  @Input() btnColors: any;
  @Output() expand: any = new EventEmitter();
  @Input() cardDetails: any;
  @Input() card: any;
  @Input() cardShadow: string;
  @Input() cardsData: any;
  @Input() cardBorderSize: number;
  @Input() cardBorderRadius: number;
  @Input() cardBorderColor: string;
  @Input() conditionArray: any;
  @Input() attributeColors: any;
  @Input() cardColor: any;
  @Input() attributeUIElementMap: Map<string, string>;
  @Input() _otherStyle: any;
  @Output() emitExecuteCard = new EventEmitter();
  @Input() attrHoveredColors: any;
  @Input() cardHoveredColor: any;
  @Input() isEdit: boolean = false;
  @Input() styleGsi: ICardStyle[];
  @Input() quantity: number[];
  @Input() kanbanPositionMap: { [key: number]: DraggableAttribute } = {};
  @Input() kanbanGsiConfiguration: { [key: number]: GsiButton } = {};
  @Output() emitQuantity = new EventEmitter();

  mappingFields: any;
  knowMore: boolean;
  somevar: boolean;
  strvar: string;
  attributeData: any = {};
  isHovered: boolean;
  ngSubscribe = new Subject();
  @Output() onDeleteAddOn = new EventEmitter();
  @Output() dropCardEmit = new EventEmitter();
  @Output() deleteDropCardEmit = new EventEmitter();
  @Output() addOnClick = new EventEmitter();
  btnHoveredBoolean: boolean[] = [];
  @Input() btnHoverColors: any = [];

  constructor(
    public alertService: AlertService
  ) {}

  accordion: boolean = false;
  @Input() entityCardFlip: boolean = false;
  ngOnInit(): void {
    if (this.cardDisplay == 'view') {
      this.btnColors?.forEach((color: any, index: number) => {
        this.btnHoveredBoolean[index] = false;
      });
    }
    this.mappingFields = this.cardDetails?.templateMappingFields;
    /*istanbul ignore else*/
    if (this.mappingFields && typeof this.mappingFields === 'string') {
      this.mappingFields = JSON.parse(this.mappingFields);
      this.knowMore =
        this.mappingFields?.expandTemplateTypeData &&
        Object.keys(this.mappingFields?.expandTemplateTypeData).length > 0;
    }
    this.entityCardFlip = this.cardDetails?.childView?.style?.entityCardFlip
      ? this.cardDetails?.childView?.style?.entityCardFlip
      : this.entityCardFlip;
    this.prepareDataForAttributeTemplate();
  }
  prepareDataForAttributeTemplate() {
    if (this.cardDisplay == 'view') {
      this.templateType = this.cardDetails?.templateType;
    }
  }

  noOfValue(len: number) {
    return Array.from({ length: len }, (v, i) => i);
  }

  stopProgationBigdiv(event: any) {
    event.stopPropagation();
  }
  stopProgationSmalldiv(event: any) {
    this.expand.emit(this.card);
  }
  gotoTxnPage(gsi: any, card?: any, event?: any) {
    this.emitExecuteCard.emit({ gsi: gsi, card: card, txnDataIndex: this.txnDataIndex, event: event });
  }
  changeQuantity(event: any, txnDataIndex: number) {
    this.emitQuantity.emit({ event: event, txnDataIndex: txnDataIndex });
  }
  @Input() hoveredColors: any = [];
  dropAttributes: any = [];
  @Input() dragAttrType: any;
  @Input() nslAttributes: any;
  @Input() templateType: any;
  @Input() tabSelected: any;
  @Input() isGrammer: any = false;
  @Input() selectedFont: string;
  @Input() fontSize: number;
  @Output() droppedAttr = new EventEmitter();
  @Output() droppedActionButton = new EventEmitter();
  @Output() emitSelectedElement = new EventEmitter();
  @Output() emitConditionColor = new EventEmitter();
  @Input() isDesign: boolean = false;
  @Input() selectedColor: string = '';
  @Input() isItalic: boolean;
  @Input() isBold: boolean;
  @Input() alignment: any;
  @Input() boxShadowData: string = '';
  @Input() borderSize: number;
  @Input() borderRadius: number;
  @Input() selectedBorderColor: string;
  @Input() hoveredColor: string;
  @Input() hoverColorsExpand: any;
  @Output() onDeletion = new EventEmitter();
  @Output() removeGsi = new EventEmitter();
  @Input() childGsiMapping: { [key: number]: GsiButton } = {};
  @Input() set colorConditionSetter(value: any) {
    this.conditionColors = value;
  }
  @Input() childPositionMap: { [key: number]: DraggableAttribute } = {};
  @ViewChild('gsiAction') gsiAction: any;
  conditionColors: any;
  @Input() selectedDirectiveId: string = '';
  @Input() style: IStyle;
  @Output() cardHoverColor = new EventEmitter();
  @Input() entityCardFlipDesign: boolean = false;
  @ContentChildren(TemplateRef) templates: QueryList<TemplateRef<any>>;
  @Input() cardStyle: ICardStyle;
  @Input() cardGsiMap: any;
  dropAttribute(ev: any, attrType?: any) {
    /*istanbul ignore next*/
    if (
      (this.dragAttrType && attrType == 'image' && this.dragAttrType == 'image') ||
      (this.dragAttrType && attrType == 'any' && this.dragAttrType !== 'image')
    ) {
      this.dropAttributeInTemplates(ev, attrType);
    } else if (
      attrType == this.dragAttrType ||
      (attrType == 'label' && (this.dragAttrType == 'metric' || this.dragAttrType == 'currency')) ||
      (attrType == 'number' && (this.dragAttrType == 'metric' || this.dragAttrType == 'currency'))
    ) {
      this.dropAttributeInTemplates(ev);
    } else {
      this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
    }
  }
  dropAttributeInTemplates(ev: any, type?: any) {
    if (type) this.droppedAttr.emit({ event: ev, attrtype: type });
    else this.droppedAttr.emit(ev);
  }
  onDeletingAttr(ev: any) {
    this.onDeletion.emit(ev);
  }
  allowDrop(ev: any) {
    /*istanbul ignore next*/
    ev.preventDefault();
  }
  dropActionBtn(ev: any) {
    this.droppedActionButton.emit(ev);
  }
  recieveEmittedDirectiveId(event: string): void {
    this.emitSelectedElement.emit(event);
    this.selectedDirectiveId = event;
  }
  recieveConditionColor(event: any) {
    this.emitConditionColor.emit(event);
  }
  removeGsiFromCard(event: any) {
    this.removeGsi.emit(event);
  }
  dblClickButtonInCard(event: MouseEvent) {
    console.log(event);
  }
  recieveHoverColor(event: string) {
    this.cardHoverColor.emit(event);
  }
  onDeletingAddOn(event: string) {
    this.onDeleteAddOn.emit(event);
  }
  dropCard(event: any) {
    this.dropCardEmit.emit(event);
  }
  deleteCardGsiMap() {
    this.deleteDropCardEmit.emit(true);
  }
  emitAddOnClick(event: any, position: number, card: string) {
    this.addOnClick.emit({ event: event, position: position, card: card, txnDataIndex: this.txnDataIndex });
  }
}
