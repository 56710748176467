import { AttributeTemplatesComponent } from "../card-templates/attribute-templates/attribute-templates.component";
import { BannersTemplateComponent } from "../card-templates/banners-template/banners-template.component";
import { CoreTemplatesComponent } from "../card-templates/core-templates/core-templates.component";
import { ECommerceTemplateComponent } from "../card-templates/e-commerce-template/e-commerce-template.component";
import { ExpandEcommerceTemplatesComponent } from "../card-templates/expand-ecommerce-templates/expand-ecommerce-templates.component";
import { ExpandInsuranceTemplatesComponent } from "../card-templates/expand-insurance-templates/expand-insurance-templates.component";
import { ExpandTemplatesBaseComponent } from "../card-templates/expand-templates-base/expand-templates-base.component";
import { FeaturesTemplatesComponent } from "../card-templates/features-templates/features-templates.component";
import { InsuranceTemplatesComponent } from "../card-templates/insurance-templates/insurance-templates.component";
import { CircularTemplateComponent } from '../card-templates/circular-template/circular-template.component';


export interface CustomizeCardTemplates {
  tenant: boolean;
  firstVariable: boolean;
  secondVariable: boolean;
  thirdVariable: boolean;
  templateNumber: string | number;
  numberOfAttributes: number;
  contentURL: string;
  contentURLGrammer: string | null;
  componentName?: string;
}
export interface Templates {
  name: string;
  isMulti: boolean;
  isGrammer: boolean;
  template: CustomizeCardTemplates[] | Templates[];
  noOfItemsinAcolumn: number;
}
const pageCardTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '1',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w13.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w13.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '2',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/multi-attributes/Product Expand Cards.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/multi-attributes/Product Expand Cards.png',
  },
];

export const pageTemplates: Templates[] = [
  {
    name: 'Page Templates',
    isMulti: false,
    isGrammer: false,
    template: pageCardTemplates,
    noOfItemsinAcolumn: 1,
  },
];

const twoAttributesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '332',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/kanban-1.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '5',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '6',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t2.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '7',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/2-1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '8',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  // {
  //   tenant: false,
  //   firstVariable: false,
  //   secondVariable: false,
  //   thirdVariable: false,
  //   templateNumber: '9',
  //   numberOfAttributes: 2,
  //   contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t2.png',
  //   contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/2-3.png',
  //   componentName: 'AttributeTemplatesComponent',
  // },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '121',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/2-2.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '130',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/without-img-1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/without-img-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '131',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/without-img-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/without-img-4.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '196',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-196.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '197',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-197.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '198',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-198.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '199',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-199.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '200',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-200.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '201',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-201.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '202',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/twoattributes/2-202.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '304',
    numberOfAttributes: 2,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/304.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '305',
    numberOfAttributes: 2,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/305.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
];
const threeAttributesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '10',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/horizontal-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/horizontal-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '11',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/without-img-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/3-1.png',
    componentName: 'CoreTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '12',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '13',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/3-2.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '14',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '15',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t4.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/3-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '132',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/without-img-1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/without-img-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '133',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/without-img-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/threeattributes/without-img-4.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '203',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-203.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '204',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-204.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '205',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-205.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '206',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-206.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '207',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-207.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '208',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-208.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '209',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-209.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '210',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-210.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '211',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/threeattributes/3-211.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
];
const fourAttributesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '16',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/horizontal-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/horizontal-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  // {
  //   tenant: false,
  //   firstVariable: false,
  //   secondVariable: false,
  //   thirdVariable: false,
  //   templateNumber: '17',
  //   numberOfAttributes: 3,
  //   contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/horizontal-t2.png',
  //   contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/4-1.png',
  //   componentName: 'AttributeTemplatesComponent',
  // },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '18',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '19',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/4-2.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '20',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '21',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t4.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/4-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '134',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/without-img-1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/without-img-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '135',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/without-img-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/without-img-4.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '212',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/4-212.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '213',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/4-213.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '214',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/4-214.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '215',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/4-215.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '216',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/4-216.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '217',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/fourattributes/4-217.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
];
const fiveAttributesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '22',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/horizontal-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/horizontal-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '23',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/horizontal-t2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/5-1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '24',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '25',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/5-2.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '26',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '27',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t4.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/5-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '136',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/without-img-1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/without-img-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '137',
    numberOfAttributes: 5,
    contentURL: '../../../../../assets/img/general-entity/templates/fiveattributes/without-img-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fiveattributes/without-img-4.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '328',
    numberOfAttributes: 4,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/fourattributes/common-selection-card.png',
    componentName: 'FeaturesTemplatesComponent',

  },
];
const sixAttributesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '28',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/horizontal-t1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/horizontal-t1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '29',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/horizontal-t2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/6-1.png',
    componentName: 'AttributeTemplatesComponent',
  },
  // {
  //   tenant: false,
  //   firstVariable: false,
  //   secondVariable: false,
  //   thirdVariable: false,
  //   templateNumber: '31',
  //   numberOfAttributes: 6,
  //   contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t2.png',
  //   contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/6-2.png',
  //   componentName: 'AttributeTemplatesComponent',
  // },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '32',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '33',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t4.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/6-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '138',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/without-img-1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/without-img-3.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '139',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/without-img-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/sixattributes/without-img-4.png',
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '219',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/sixattributes/6-219.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '306',
    numberOfAttributes: 2,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/twoattributes/306.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
];
const ninteenAttributesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '331',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/circularTemplates/circularTemplate-331.png',
    contentURLGrammer: null,
    componentName: 'CircularTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '181',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-181.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-181-1.png',
    componentName: 'CoreTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '334',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/nineteenattributes/studioverse-video-card.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '182',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-182.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-182-1.png',
    componentName: 'CoreTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '303',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-303.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '307',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/307.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '308',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-308.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '309',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/309.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '310',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/310.jpg',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '311',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-311.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '312',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-312.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '313',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-313.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '314',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-314.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '315',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-315.png',
    contentURLGrammer: null,
    componentName: 'CoreTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '316',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-316.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '317',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-317.png',
    contentURLGrammer: null,
    componentName: 'CoreTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '318',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-318.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '322',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-322.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '323',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-323.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '324',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-324.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '325',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-325.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '326',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-326.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '327',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-327.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '329',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-329.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '340',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/nineteenattributes/template-340.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
];
const featuresTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '149',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-149.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '150',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-150.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '151',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-151.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '152',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-152.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '153',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-153.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '154',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-154.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '155',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-155.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '156',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-156.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '157',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-157.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '158',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-158.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '159',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-159.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '160',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-160.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '161',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-161.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '162',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-162.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '163',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-163.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '164',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-164.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '165',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-165.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '166',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-166.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '167',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-167.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '168',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-168.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '169',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-169.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '170',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-170.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '171',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-171.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '172',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-172.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '173',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-173.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '174',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-174.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '175',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-175.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '176',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-176.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '177',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-177.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '178',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-178.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '179',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-179.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '180',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-180.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '224',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-145.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '225',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-146.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '226',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-147.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '227',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-148.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '233',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-233.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '234',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/features/feature-234.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
];
const BannerTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '183',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-1.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '184',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-2.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '185',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-3.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '186',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-4.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '187',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-5.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '188',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-6.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '189',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-7.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '190',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-8.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '191',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-9.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '192',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-10.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '193',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-11.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '194',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-12.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '195',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/banner-13.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '300',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/SD-1.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  // {
  //   tenant: false,
  //   firstVariable: false,
  //   secondVariable: true,
  //   thirdVariable: false,
  //   templateNumber: '301',
  //   numberOfAttributes: 19,
  //   contentURL: '../../../../../assets/img/general-entity/templates/banners/SD-2.png',
  //   contentURLGrammer: null,
  //   componentName: 'BannersTemplateComponent',
  // },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '302',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/SD-3.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
];
const FAQTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '228',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/faq-1.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '229',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/faq-2.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '230',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/faq-3.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '231',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/faq-4.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '232',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/templates/banners/faq-5.png',
    contentURLGrammer: null,
    componentName: 'BannersTemplateComponent',
  },
];
const aggregateEntitiesTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '1224',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/Aggregate/Aggregate_Entity_001.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '2224',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/Aggregate/Aggregate_Entity_002.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '3224',
    numberOfAttributes: 2,
    contentURL: '../../../../../assets/img/general-entity/templates/Aggregate/Aggregate_Entity_003.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
];
const tenantTemplate: CustomizeCardTemplates[] = [
  {
    tenant: true,
    firstVariable: false,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '242',
    numberOfAttributes: 19,
    contentURL: '../../../../../assets/img/general-entity/efundzz1.png',
    contentURLGrammer: null,
    componentName: 'CoreTemplatesComponent',
  },
  // {
  //   tenant: true,
  //   firstVariable: false,
  //   secondVariable: true,
  //   thirdVariable: false,
  //   templateNumber: '330',
  //   numberOfAttributes: 19,
  //   contentURL: '../../../../../assets/img/general-entity/efundzz1.png',
  //   contentURLGrammer: null,
  // },
  // {
  //   tenant: true,
  //   firstVariable: true,
  //   secondVariable: false,
  //   thirdVariable: true,
  //   templateNumber: '334',
  //   numberOfAttributes: 19,
  //   contentURL: '../../../../../assets/img/general-entity/efundzz1.png',
  //   contentURLGrammer: '../../../../../assets/img/general-entity/efundzz1.png',
  // },
];
const eCommerceTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '54',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-1.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w1.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '55',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-2.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w2.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '56',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-3.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w3.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '57',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-4.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w4.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '58',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-5.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w5.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '59',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-6.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w6.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '60',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-7.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w7.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '61',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-8.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w8.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '62',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-9.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w9.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '63',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-10.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w10.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '64',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-11.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w11.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '65',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-12.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w12.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '66',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-13.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w13.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '67',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-14.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w14.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '68',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-15.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w15.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '69',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-16.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w16.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '70',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-17.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w17.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '71',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-18.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w18.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '72',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-19.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w19.png',
    componentName: 'ECommerceTemplateComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '73',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-20.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-w20.png',
    componentName: 'ECommerceTemplateComponent',
  },
];
const miscellaneous: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '220',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/multi-attributes/geniusrx-1.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '221',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/multi-attributes/geniusrx-2.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '222',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/multi-attributes/geniusrx-3.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: true,
    thirdVariable: false,
    templateNumber: '223',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/multi-attributes/genius-rx.png',
    contentURLGrammer: null,
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '319',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/multi-attributes/photo-319.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '320',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/multi-attributes/photo-320.png',
    componentName: 'FeaturesTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '321',
    numberOfAttributes: 19,
    contentURL: null,
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/multi-attributes/photo-321.png',
    componentName: 'FeaturesTemplatesComponent',
  },
];
const InsuranceTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '34',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card1.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w1.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '35',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card2.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w2.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '36',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card3.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w3.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '37',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card4.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w4.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '38',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card5.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w5.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '39',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card6.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w6.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '40',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card7.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w7.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '41',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card8.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w8.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '42',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card9.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w9.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '43',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card10.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w10.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '44',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card11.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w11.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '45',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card12.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w12.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '46',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card13.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w13.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '47',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card14.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w14.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '48',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card15.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w15.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '49',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card16.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w16.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '50',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card17.png',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w17.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '51',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card18.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w18.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '52',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card19.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w19.png',
    componentName: 'InsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: true,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '53',
    numberOfAttributes: 3,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card20.jpg',
    contentURLGrammer:
      '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card-w20.png',
    componentName: 'InsuranceTemplatesComponent',
  },
];
const SponsorTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '141',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-1.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '142',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-2.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '143',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-3.PNG',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '144',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-5.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '145',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-6.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '146',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-7.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '147',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-8.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '148',
    numberOfAttributes: 6,
    contentURL: '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-9.png',
    contentURLGrammer: null,
    componentName: 'AttributeTemplatesComponent',
  },
];
const multiAttributesTemplates: Templates[] = [
  {
    name: 'E-Commerce',
    isMulti: false,
    template: eCommerceTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: 'miscellaneous',
    isMulti: false,
    template: miscellaneous,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: 'Insurance',
    isMulti: false,
    template: InsuranceTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: 'Sponsor',
    isMulti: false,
    template: SponsorTemplates,
    isGrammer: false,
    noOfItemsinAcolumn: 2,
  },
];

export const Templates: Templates[] = [
  {
    name: '2 Attributes',
    isMulti: false,
    template: twoAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: '3 Attributes',
    isMulti: false,
    template: threeAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: '4 Attributes',
    isMulti: false,
    template: fourAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: '5 Attributes',
    isMulti: false,
    template: fiveAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: '6 Attributes',
    isMulti: false,
    template: sixAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 3,
  },
  {
    name: '19 Attributes',
    isMulti: false,
    template: ninteenAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'Features',
    isMulti: false,
    template: featuresTemplates,
    isGrammer: false,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'Banners',
    isMulti: false,
    template: BannerTemplates,
    isGrammer: false,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'FAQ',
    isMulti: false,
    template: FAQTemplates,
    isGrammer: false,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'Aggregate Entities',
    isMulti: false,
    template: aggregateEntitiesTemplates,
    isGrammer: false,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'tenant',
    isMulti: false,
    template: tenantTemplate,
    isGrammer: false,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'Multi Attributes',
    isMulti: true,
    template: multiAttributesTemplates,
    isGrammer: true,
    noOfItemsinAcolumn: 2,
  },
];
export const templateURLMapper: { [key: string]: string } = {
  '5': '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t1.png',
  '6': '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t2.png',
  '7': '../../../../../assets/img/general-entity/templates/twoattributes/horizontal-t3.png',
  '8': '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t1.png',
  '9': '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t2.png',
  '10': '../../../../../assets/img/general-entity/templates/threeattributes/horizontal-t1.png',
  '11': '../../../../../assets/img/general-entity/templates/threeattributes/without-img-2.png',
  '12': '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t1.png',
  '13': '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t2.png',
  '14': '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t3.png',
  '15': '../../../../../assets/img/general-entity/templates/threeattributes/vertical-t4.png',
  '16': '../../../../../assets/img/general-entity/templates/fourattributes/horizontal-t1.png',
  '17': '../../../../../assets/img/general-entity/templates/fourattributes/horizontal-t2.png',
  '18': '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t1.png',
  '19': '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t2.png',
  '20': '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t3.png',
  '21': '../../../../../assets/img/general-entity/templates/fourattributes/vertical-t4.png',
  '22': '../../../../../assets/img/general-entity/templates/fiveattributes/horizontal-t1.png',
  '23': '../../../../../assets/img/general-entity/templates/fiveattributes/horizontal-t2.png',
  '24': '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t1.png',
  '25': '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t2.png',
  '26': '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t3.png',
  '27': '../../../../../assets/img/general-entity/templates/fiveattributes/vertical-t4.png',
  '28': '../../../../../assets/img/general-entity/templates/sixattributes/horizontal-t1.png',
  '29': '../../../../../assets/img/general-entity/templates/sixattributes/horizontal-t2.png',
  '31': '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t2.png',
  '32': '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t3.png',
  '33': '../../../../../assets/img/general-entity/templates/sixattributes/vertical-t4.png',
  '34': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card1.jpg',
  '35': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card2.jpg',
  '36': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card3.jpg',
  '37': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card4.jpg',
  '38': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card5.jpg',
  '39': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card6.jpg',
  '40': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card7.jpg',
  '41': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card8.jpg',
  '42': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card9.jpg',
  '43': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card10.jpg',
  '44': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card11.jpg',
  '45': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card12.jpg',
  '46': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card13.jpg',
  '47': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card14.jpg',
  '48': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card15.jpg',
  '49': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card16.jpg',
  '50': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card17.png',
  '51': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card18.jpg',
  '52': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card19.jpg',
  '53': '../../../../../assets/img/general-entity/templates/insurance/child-cards/insurance-child-card20.jpg',
  '54': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-1.png',
  '55': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-2.png',
  '56': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-3.png',
  '57': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-4.png',
  '58': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-5.png',
  '59': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-6.png',
  '60': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-7.png',
  '61': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-8.png',
  '62': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-9.png',
  '63': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-10.png',
  '64': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-11.png',
  '65': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-12.png',
  '66': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-13.png',
  '67': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-14.png',
  '68': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-15.png',
  '69': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-16.png',
  '70': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-17.png',
  '71': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-18.png',
  '72': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-19.png',
  '73': '../../../../../assets/img/general-entity/templates/ecommerce/child-cards/e-commerce-child-card-20.png',
  '121': '../../../../../assets/img/general-entity/templates/twoattributes/vertical-t3.png',
  '130': '../../../../../assets/img/general-entity/templates/twoattributes/without-img-1.png',
  '131': '../../../../../assets/img/general-entity/templates/twoattributes/without-img-2.png',
  '132': '../../../../../assets/img/general-entity/templates/threeattributes/without-img-1.png',
  '133': '../../../../../assets/img/general-entity/templates/threeattributes/without-img-2.png',
  '134': '../../../../../assets/img/general-entity/templates/fourattributes/without-img-1.png',
  '135': '../../../../../assets/img/general-entity/templates/fourattributes/without-img-2.png',
  '136': '../../../../../assets/img/general-entity/templates/fiveattributes/without-img-1.png',
  '137': '../../../../../assets/img/general-entity/templates/fiveattributes/without-img-2.png',
  '138': '../../../../../assets/img/general-entity/templates/sixattributes/without-img-1.png',
  '139': '../../../../../assets/img/general-entity/templates/sixattributes/without-img-2.png',
  '141': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-1.png',
  '142': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-2.png',
  '143': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-3.PNG',
  '144': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-5.png',
  '145': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-6.png',
  '146': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-7.png',
  '147': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-8.png',
  '148': '../../../../../assets/img/general-entity/templates/Sponsor/Sponsor-9.png',
  '149': '../../../../../assets/img/general-entity/templates/features/feature-149.png',
  '150': '../../../../../assets/img/general-entity/templates/features/feature-150.png',
  '151': '../../../../../assets/img/general-entity/templates/features/feature-151.png',
  '152': '../../../../../assets/img/general-entity/templates/features/feature-152.png',
  '153': '../../../../../assets/img/general-entity/templates/features/feature-153.png',
  '154': '../../../../../assets/img/general-entity/templates/features/feature-154.png',
  '155': '../../../../../assets/img/general-entity/templates/features/feature-155.png',
  '156': '../../../../../assets/img/general-entity/templates/features/feature-156.png',
  '157': '../../../../../assets/img/general-entity/templates/features/feature-157.png',
  '158': '../../../../../assets/img/general-entity/templates/features/feature-158.png',
  '159': '../../../../../assets/img/general-entity/templates/features/feature-159.png',
  '160': '../../../../../assets/img/general-entity/templates/features/feature-160.png',
  '161': '../../../../../assets/img/general-entity/templates/features/feature-161.png',
  '162': '../../../../../assets/img/general-entity/templates/features/feature-162.png',
  '163': '../../../../../assets/img/general-entity/templates/features/feature-163.png',
  '164': '../../../../../assets/img/general-entity/templates/features/feature-164.png',
  '165': '../../../../../assets/img/general-entity/templates/features/feature-165.png',
  '166': '../../../../../assets/img/general-entity/templates/features/feature-166.png',
  '167': '../../../../../assets/img/general-entity/templates/features/feature-167.png',
  '168': '../../../../../assets/img/general-entity/templates/features/feature-168.png',
  '169': '../../../../../assets/img/general-entity/templates/features/feature-169.png',
  '170': '../../../../../assets/img/general-entity/templates/features/feature-170.png',
  '171': '../../../../../assets/img/general-entity/templates/features/feature-171.png',
  '172': '../../../../../assets/img/general-entity/templates/features/feature-172.png',
  '173': '../../../../../assets/img/general-entity/templates/features/feature-173.png',
  '174': '../../../../../assets/img/general-entity/templates/features/feature-174.png',
  '175': '../../../../../assets/img/general-entity/templates/features/feature-175.png',
  '176': '../../../../../assets/img/general-entity/templates/features/feature-176.png',
  '177': '../../../../../assets/img/general-entity/templates/features/feature-177.png',
  '178': '../../../../../assets/img/general-entity/templates/features/feature-178.png',
  '179': '../../../../../assets/img/general-entity/templates/features/feature-179.png',
  '180': '../../../../../assets/img/general-entity/templates/features/feature-180.png',
  '181': '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-181.png',
  '182': '../../../../../assets/img/general-entity/templates/nineteenattributes/photo-182.png',
  '196': '../../../../../assets/img/general-entity/templates/twoattributes/2-196.png',
  '197': '../../../../../assets/img/general-entity/templates/twoattributes/2-197.png',
  '198': '../../../../../assets/img/general-entity/templates/twoattributes/2-198.png',
  '199': '../../../../../assets/img/general-entity/templates/twoattributes/2-199.png',
  '200': '../../../../../assets/img/general-entity/templates/twoattributes/2-200.png',
  '201': '../../../../../assets/img/general-entity/templates/twoattributes/2-201.png',
  '202': '../../../../../assets/img/general-entity/templates/twoattributes/2-202.png',
  '203': '../../../../../assets/img/general-entity/templates/threeattributes/3-203.png',
  '204': '../../../../../assets/img/general-entity/templates/threeattributes/3-204.png',
  '205': '../../../../../assets/img/general-entity/templates/threeattributes/3-205.png',
  '206': '../../../../../assets/img/general-entity/templates/threeattributes/3-206.png',
  '207': '../../../../../assets/img/general-entity/templates/threeattributes/3-207.png',
  '208': '../../../../../assets/img/general-entity/templates/threeattributes/3-208.png',
  '209': '../../../../../assets/img/general-entity/templates/threeattributes/3-209.png',
  '210': '../../../../../assets/img/general-entity/templates/threeattributes/3-210.png',
  '211': '../../../../../assets/img/general-entity/templates/threeattributes/3-211.png',
  '212': '../../../../../assets/img/general-entity/templates/fourattributes/4-212.png',
  '213': '../../../../../assets/img/general-entity/templates/fourattributes/4-213.png',
  '214': '../../../../../assets/img/general-entity/templates/fourattributes/4-214.png',
  '215': '../../../../../assets/img/general-entity/templates/fourattributes/4-215.png',
  '216': '../../../../../assets/img/general-entity/templates/fourattributes/4-216.png',
  '217': '../../../../../assets/img/general-entity/templates/fourattributes/4-217.png',
  '219': '../../../../../assets/img/general-entity/templates/sixattributes/6-219.png',
  '220': '../../../../../assets/img/general-entity/templates/multi-attributes/geniusrx-1.png',
  '221': '../../../../../assets/img/general-entity/templates/multi-attributes/geniusrx-2.png',
  '222': '../../../../../assets/img/general-entity/templates/multi-attributes/geniusrx-3.png',
  '223': '../../../../../assets/img/general-entity/templates/multi-attributes/genius-rx.png',
  '224': '../../../../../assets/img/general-entity/templates/features/feature-145.png',
  '225': '../../../../../assets/img/general-entity/templates/features/feature-146.png',
  '226': '../../../../../assets/img/general-entity/templates/features/feature-147.png',
  '227': '../../../../../assets/img/general-entity/templates/features/feature-148.png',
  '228': '../../../../../assets/img/general-entity/templates/banners/faq-1.png',
  '229': '../../../../../assets/img/general-entity/templates/banners/faq-2.png',
  '230': '../../../../../assets/img/general-entity/templates/banners/faq-3.png',
  '231': '../../../../../assets/img/general-entity/templates/banners/faq-4.png',
  '232': '../../../../../assets/img/general-entity/templates/banners/faq-5.png',
  '233': '../../../../../assets/img/general-entity/templates/features/feature-233.png',
  '234': '../../../../../assets/img/general-entity/templates/features/feature-234.png',
  '242': '../../../../../assets/img/general-entity/efundzz1.png',
  '303': null,
  '304': null,
  '305': null,
  '306': null,
  '307': null,
  '308': null,
  '309': null,
  '310': null,
  '311': null,
  '312': null,
  '313': null,
  '314': null,
  '315': '../../../../../assets/img/general-entity/templates/nineteenattributes/template-315.png',
  '316': null,
  '317': '../../../../../assets/img/general-entity/templates/nineteenattributes/template-317.png',
  '318': null,
  '319': null,
  '320': null,
  '321': null,
  '322': null,
  '323': null,
  '324': null,
  '325': null,
  '326': null,
  '327': null,
  '328': null,
  '329': '../../../../../assets/img/general-entity/templates/nineteenattributes/template-329.png',
  '330': '../../../../../assets/img/general-entity/efundzz1.png',
  '334': '../../../../../assets/img/general-entity/templates/nineteenattributes/studioverse-video-card.jpg',
  '1224': '../../../../../assets/img/general-entity/templates/Aggregate/Aggregate_Entity_001.png',
  '2224': '../../../../../assets/img/general-entity/templates/Aggregate/Aggregate_Entity_002.png',
  '3224': '../../../../../assets/img/general-entity/templates/Aggregate/Aggregate_Entity_003.png',
  '340': '../../../../../assets/img/general-entity/templates/nineteenattributes/template-340.png',
};
export const componentTemplateMapper: { [key: string]: string } = {
  '1': 'ExpandTemplatesBaseComponent',
  '4': 'ExpandCardTemplateComponent',
  '5': 'AttributeTemplatesComponent',
  '6': 'AttributeTemplatesComponent',
  '7': 'AttributeTemplatesComponent',
  '8': 'AttributeTemplatesComponent',
  '9': 'AttributeTemplatesComponent',
  '10': 'AttributeTemplatesComponent',
  '11': 'CoreTemplatesComponent',
  '12': 'AttributeTemplatesComponent',
  '13': 'AttributeTemplatesComponent',
  '14': 'AttributeTemplatesComponent',
  '15': 'AttributeTemplatesComponent',
  '16': 'AttributeTemplatesComponent',
  '17': 'AttributeTemplatesComponent',
  '18': 'AttributeTemplatesComponent',
  '19': 'AttributeTemplatesComponent',
  '20': 'AttributeTemplatesComponent',
  '21': 'AttributeTemplatesComponent',
  '22': 'AttributeTemplatesComponent',
  '23': 'AttributeTemplatesComponent',
  '24': 'AttributeTemplatesComponent',
  '25': 'AttributeTemplatesComponent',
  '26': 'AttributeTemplatesComponent',
  '27': 'AttributeTemplatesComponent',
  '28': 'AttributeTemplatesComponent',
  '29': 'AttributeTemplatesComponent',
  '31': 'AttributeTemplatesComponent',
  '32': 'AttributeTemplatesComponent',
  '33': 'AttributeTemplatesComponent',
  '34': 'InsuranceTemplatesComponent',
  '35': 'InsuranceTemplatesComponent',
  '36': 'InsuranceTemplatesComponent',
  '37': 'InsuranceTemplatesComponent',
  '38': 'InsuranceTemplatesComponent',
  '39': 'InsuranceTemplatesComponent',
  '40': 'InsuranceTemplatesComponent',
  '41': 'InsuranceTemplatesComponent',
  '42': 'InsuranceTemplatesComponent',
  '43': 'InsuranceTemplatesComponent',
  '44': 'InsuranceTemplatesComponent',
  '45': 'InsuranceTemplatesComponent',
  '46': 'InsuranceTemplatesComponent',
  '47': 'InsuranceTemplatesComponent',
  '48': 'InsuranceTemplatesComponent',
  '49': 'InsuranceTemplatesComponent',
  '50': 'InsuranceTemplatesComponent',
  '51': 'InsuranceTemplatesComponent',
  '52': 'InsuranceTemplatesComponent',
  '53': 'InsuranceTemplatesComponent',
  '54': 'ECommerceTemplateComponent',
  '55': 'ECommerceTemplateComponent',
  '56': 'ECommerceTemplateComponent',
  '57': 'ECommerceTemplateComponent',
  '58': 'ECommerceTemplateComponent',
  '59': 'ECommerceTemplateComponent',
  '60': 'ECommerceTemplateComponent',
  '61': 'ECommerceTemplateComponent',
  '62': 'ECommerceTemplateComponent',
  '63': 'ECommerceTemplateComponent',
  '64': 'ECommerceTemplateComponent',
  '65': 'ECommerceTemplateComponent',
  '66': 'ECommerceTemplateComponent',
  '67': 'ECommerceTemplateComponent',
  '68': 'ECommerceTemplateComponent',
  '69': 'ECommerceTemplateComponent',
  '70': 'ECommerceTemplateComponent',
  '71': 'ECommerceTemplateComponent',
  '72': 'ECommerceTemplateComponent',
  '73': 'ECommerceTemplateComponent',
  '121': 'AttributeTemplatesComponent',
  '130': 'AttributeTemplatesComponent',
  '131': 'AttributeTemplatesComponent',
  '132': 'AttributeTemplatesComponent',
  '133': 'AttributeTemplatesComponent',
  '134': 'AttributeTemplatesComponent',
  '135': 'AttributeTemplatesComponent',
  '136': 'AttributeTemplatesComponent',
  '137': 'AttributeTemplatesComponent',
  '138': 'AttributeTemplatesComponent',
  '139': 'AttributeTemplatesComponent',
  '141': 'AttributeTemplatesComponent',
  '142': 'AttributeTemplatesComponent',
  '143': 'AttributeTemplatesComponent',
  '144': 'AttributeTemplatesComponent',
  '145': 'AttributeTemplatesComponent',
  '146': 'AttributeTemplatesComponent',
  '147': 'AttributeTemplatesComponent',
  '148': 'AttributeTemplatesComponent',
  '149': 'FeaturesTemplatesComponent',
  '150': 'FeaturesTemplatesComponent',
  '151': 'FeaturesTemplatesComponent',
  '152': 'FeaturesTemplatesComponent',
  '153': 'FeaturesTemplatesComponent',
  '154': 'FeaturesTemplatesComponent',
  '155': 'FeaturesTemplatesComponent',
  '156': 'FeaturesTemplatesComponent',
  '157': 'FeaturesTemplatesComponent',
  '158': 'FeaturesTemplatesComponent',
  '159': 'FeaturesTemplatesComponent',
  '160': 'FeaturesTemplatesComponent',
  '161': 'FeaturesTemplatesComponent',
  '162': 'FeaturesTemplatesComponent',
  '163': 'FeaturesTemplatesComponent',
  '164': 'FeaturesTemplatesComponent',
  '165': 'FeaturesTemplatesComponent',
  '166': 'FeaturesTemplatesComponent',
  '167': 'FeaturesTemplatesComponent',
  '168': 'FeaturesTemplatesComponent',
  '169': 'FeaturesTemplatesComponent',
  '170': 'FeaturesTemplatesComponent',
  '171': 'FeaturesTemplatesComponent',
  '172': 'FeaturesTemplatesComponent',
  '173': 'FeaturesTemplatesComponent',
  '174': 'FeaturesTemplatesComponent',
  '175': 'FeaturesTemplatesComponent',
  '176': 'FeaturesTemplatesComponent',
  '177': 'FeaturesTemplatesComponent',
  '178': 'FeaturesTemplatesComponent',
  '179': 'FeaturesTemplatesComponent',
  '180': 'FeaturesTemplatesComponent',
  '181': 'CoreTemplatesComponent',
  '182': 'CoreTemplatesComponent',
  '183': 'BannersTemplateComponent',
  '184': 'BannersTemplateComponent',
  '185': 'BannersTemplateComponent',
  '186': 'BannersTemplateComponent',
  '187': 'BannersTemplateComponent',
  '188': 'BannersTemplateComponent',
  '189': 'BannersTemplateComponent',
  '190': 'BannersTemplateComponent',
  '191': 'BannersTemplateComponent',
  '192': 'BannersTemplateComponent',
  '193': 'BannersTemplateComponent',
  '194': 'BannersTemplateComponent',
  '195': 'BannersTemplateComponent',
  '196': 'FeaturesTemplatesComponent',
  '197': 'FeaturesTemplatesComponent',
  '198': 'FeaturesTemplatesComponent',
  '199': 'FeaturesTemplatesComponent',
  '200': 'FeaturesTemplatesComponent',
  '201': 'FeaturesTemplatesComponent',
  '202': 'FeaturesTemplatesComponent',
  '203': 'FeaturesTemplatesComponent',
  '204': 'FeaturesTemplatesComponent',
  '205': 'FeaturesTemplatesComponent',
  '206': 'FeaturesTemplatesComponent',
  '207': 'FeaturesTemplatesComponent',
  '208': 'FeaturesTemplatesComponent',
  '209': 'FeaturesTemplatesComponent',
  '210': 'FeaturesTemplatesComponent',
  '211': 'FeaturesTemplatesComponent',
  '212': 'FeaturesTemplatesComponent',
  '213': 'FeaturesTemplatesComponent',
  '214': 'FeaturesTemplatesComponent',
  '215': 'FeaturesTemplatesComponent',
  '216': 'FeaturesTemplatesComponent',
  '217': 'FeaturesTemplatesComponent',
  '219': 'FeaturesTemplatesComponent',
  '220': 'FeaturesTemplatesComponent',
  '221': 'FeaturesTemplatesComponent',
  '222': 'FeaturesTemplatesComponent',
  '223': 'FeaturesTemplatesComponent',
  '224': 'FeaturesTemplatesComponent',
  '225': 'FeaturesTemplatesComponent',
  '226': 'FeaturesTemplatesComponent',
  '227': 'FeaturesTemplatesComponent',
  '228': 'BannersTemplateComponent',
  '229': 'BannersTemplateComponent',
  '230': 'BannersTemplateComponent',
  '231': 'BannersTemplateComponent',
  '232': 'BannersTemplateComponent',
  '233': 'FeaturesTemplatesComponent',
  '234': 'FeaturesTemplatesComponent',
  '242': 'AttributeTemplatesComponent',
  '300': 'BannersTemplateComponent',
  '301': 'BannersDesignComponent',
  '302': 'BannersTemplateComponent',
  '303': 'FeaturesTemplatesComponent',
  '304': 'FeaturesTemplatesComponent',
  '305': 'FeaturesTemplatesComponent',
  '306': 'FeaturesTemplatesComponent',
  '307': 'FeaturesTemplatesComponent',
  '308': 'FeaturesTemplatesComponent',
  '309': 'FeaturesTemplatesComponent',
  '310': 'FeaturesTemplatesComponent',
  '311': 'FeaturesTemplatesComponent',
  '312': 'FeaturesTemplatesComponent',
  '313': 'FeaturesTemplatesComponent',
  '314': 'FeaturesTemplatesComponent',
  '340': 'AttributeTemplatesComponent',
  '315': 'CoreTemplatesComponent',
  '316': 'FeaturesTemplatesComponent',
  '317': 'CoreTemplatesComponent',
  '318': 'FeaturesTemplatesComponent',
  '319': 'FeaturesTemplatesComponent',
  '320': 'FeaturesTemplatesComponent',
  '321': 'FeaturesTemplatesComponent',
  '322': 'FeaturesTemplatesComponent',
  '323': 'FeaturesTemplatesComponent',
  '324': 'FeaturesTemplatesComponent',
  '325': 'FeaturesTemplatesComponent',
  '326': 'FeaturesTemplatesComponent',
  '327': 'FeaturesTemplatesComponent',
  '328': 'FeaturesTemplatesComponent',
  '329': 'BannersTemplateComponent',
  '332': 'AttributeTemplatesComponent',
  // "330": undefined,
   "334": 'FeaturesTemplatesComponent',
  '1224': 'FeaturesTemplatesComponent',
  '2224': 'FeaturesTemplatesComponent',
  '3224': 'FeaturesTemplatesComponent',
};
export const EntitycardComponentMapper: { [key: string]: any } = {
  CircularTemplateComponent:CircularTemplateComponent,
  AttributeTemplatesComponent: AttributeTemplatesComponent,
  BannersTemplateComponent: BannersTemplateComponent,
  ECommerceTemplateComponent: ECommerceTemplateComponent,
  InsuranceTemplatesComponent: InsuranceTemplatesComponent,
  CoreTemplatesComponent: CoreTemplatesComponent,
  FeaturesTemplatesComponent: FeaturesTemplatesComponent,
  ExpandTemplatesBaseComponent: ExpandTemplatesBaseComponent,
  ExpandInsuranceTemplatesComponent: ExpandInsuranceTemplatesComponent,
  ExpandEcommerceTemplatesComponent: ExpandEcommerceTemplatesComponent,
};

const expandCardTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '1',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template01.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template01.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '2',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template02.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template02.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '12',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template03.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template03.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '4',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template04.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template04.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '5',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template05.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template05.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '6',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template06.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template06.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '329',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template329.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/expand/Template329.png',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    componentName: 'ExpandTemplatesBaseComponent',
    templateNumber: '340',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/expand/Template340.png',
    contentURLGrammer: null,
  },
];
const insuranceExpandTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '74',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w1.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '75',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w2.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '76',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w3.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '77',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand4.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w4.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '78',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand5.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w5.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '79',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand6.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w6.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '80',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand7.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w7.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '81',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand8.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w8.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '82',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand9.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w9.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '83',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand10.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w10.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '84',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand11.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w11.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '85',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand12.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w12.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '86',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand13.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w13.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '87',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand14.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w14.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '88',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand15.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w15.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '89',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand16.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w16.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '90',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand17.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w17.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '77',
    numberOfAttributes: 91,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand18.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w18.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '92',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand19.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w19.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '93',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand20.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/insurance/expand-cards/expand-w20.png',
    componentName: 'ExpandInsuranceTemplatesComponent',
  },
];
const ecommerceExpandTemplates: CustomizeCardTemplates[] = [
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '94',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-1.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w1.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '103',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-10.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w10.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '104',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-11.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-11.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '105',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-12.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-12.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '106',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-13.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w13.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '107',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-14.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w14.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '108',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-15.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-15.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '109',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-16.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-16.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '110',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-17.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w17.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '111',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-18.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w18.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '112',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-19.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w19.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '95',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-2.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w2.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '113',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-20.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w20.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '96',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-3.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w3.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '97',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-4.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w4.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '98',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-5.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w5.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '99',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-6.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w6.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '100',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-7.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-w7.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '101',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-8.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-8.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
  {
    tenant: false,
    firstVariable: false,
    secondVariable: false,
    thirdVariable: false,
    templateNumber: '102',
    numberOfAttributes: 10,
    contentURL: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-9.png',
    contentURLGrammer: '../../../../../assets/img/general-entity/templates/ecommerce/expand-cards/expand-9.png',
    componentName: 'ExpandEcommerceTemplatesComponent',
  },
];
export const expandTemplates: Templates[] = [
  {
    name: 'expand Templates',
    isMulti: false,
    isGrammer: false,
    template: expandCardTemplates,
    noOfItemsinAcolumn: 1,
  },
  {
    name: 'ecommerce',
    isMulti: false,
    isGrammer: false,
    template: ecommerceExpandTemplates,
    noOfItemsinAcolumn: 2,
  },
  {
    name: 'Insurance',
    isMulti: false,
    isGrammer: false,
    template: insuranceExpandTemplates,
    noOfItemsinAcolumn: 2,
  },
];
export const componentExpandTemplateMapper: { [key: string]: string } = {
  '1': 'ExpandTemplatesBaseComponent',
  '2': 'ExpandTemplatesBaseComponent',
  '4': 'ExpandTemplatesBaseComponent',
  '5': 'ExpandTemplatesBaseComponent',
  '6': 'ExpandTemplatesBaseComponent',
  '12': 'ExpandTemplatesBaseComponent',
  '74': 'ExpandInsuranceTemplatesComponent',
  '75': 'ExpandInsuranceTemplatesComponent',
  '76': 'ExpandInsuranceTemplatesComponent',
  '77': 'ExpandInsuranceTemplatesComponent',
  '78': 'ExpandInsuranceTemplatesComponent',
  '79': 'ExpandInsuranceTemplatesComponent',
  '80': 'ExpandInsuranceTemplatesComponent',
  '81': 'ExpandInsuranceTemplatesComponent',
  '82': 'ExpandInsuranceTemplatesComponent',
  '83': 'ExpandInsuranceTemplatesComponent',
  '84': 'ExpandInsuranceTemplatesComponent',
  '85': 'ExpandInsuranceTemplatesComponent',
  '86': 'ExpandInsuranceTemplatesComponent',
  '87': 'ExpandInsuranceTemplatesComponent',
  '88': 'ExpandInsuranceTemplatesComponent',
  '89': 'ExpandInsuranceTemplatesComponent',
  '90': 'ExpandInsuranceTemplatesComponent',
  '92': 'ExpandInsuranceTemplatesComponent',
  '93': 'ExpandInsuranceTemplatesComponent',
  '94': 'ExpandEcommerceTemplatesComponent',
  '95': 'ExpandEcommerceTemplatesComponent',
  '96': 'ExpandEcommerceTemplatesComponent',
  '97': 'ExpandEcommerceTemplatesComponent',
  '98': 'ExpandEcommerceTemplatesComponent',
  '99': 'ExpandEcommerceTemplatesComponent',
  '100': 'ExpandEcommerceTemplatesComponent',
  '101': 'ExpandEcommerceTemplatesComponent',
  '102': 'ExpandEcommerceTemplatesComponent',
  '103': 'ExpandEcommerceTemplatesComponent',
  '104': 'ExpandEcommerceTemplatesComponent',
  '105': 'ExpandEcommerceTemplatesComponent',
  '106': 'ExpandEcommerceTemplatesComponent',
  '107': 'ExpandEcommerceTemplatesComponent',
  '108': 'ExpandEcommerceTemplatesComponent',
  '109': 'ExpandEcommerceTemplatesComponent',
  '110': 'ExpandEcommerceTemplatesComponent',
  '111': 'ExpandEcommerceTemplatesComponent',
  '112': 'ExpandEcommerceTemplatesComponent',
  '113': 'ExpandEcommerceTemplatesComponent',
  '329': 'ExpandTemplatesBaseComponent',
  '340': 'ExpandTemplatesBaseComponent',
};
export interface IFonts {
  name: string;
  font: string;
}
