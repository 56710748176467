import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DurationComponent } from './duration.component';

@NgModule({
  declarations: [DurationComponent],
  imports: [ControlsMaterialModule],

  exports: [DurationComponent],
})
export class DurationModule {
  static entry = {
    duration: DurationComponent,
  };
}
