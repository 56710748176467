import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { PreviewdocumentComponent } from './previewdocument.component';

@NgModule({
  declarations: [PreviewdocumentComponent],
  imports: [
    ControlsMaterialModule,
  ],
  exports: [PreviewdocumentComponent],
})
export class PreviewdocumentModule {
  static entry = {
    previewdocument: PreviewdocumentComponent,
  };
}
