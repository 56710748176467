import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { GsiEndpointService } from './gsi-endpoint.service';
import { GsiStoreService } from '../stores/gsi-store.service';
import { LoaderService } from '../loader/loader.service';
import { gsiListMapper } from '../mappers/list/gsi';
import { gsiDeMapper } from '../mappers/gsi/gsi-demapper';
import { gsiMapper } from '../mappers/gsi/gsi-mapper';
import { ChangeUnit } from '../modal/change-unit';
import { Gsi } from '../modal/gsi';
import { changeUnitDeMapper } from '../mappers/change-unit/cu-demapper';
import { changeUnitMapper } from '../mappers/change-unit/cu-mapper';
import { AlertService } from '../toaster/alert.service';
import { UploadEndpointService } from '../upload/upload-endpoint.service';
import { SolutionDictionaryFacadeService } from '../solution-dictionary/solution-dictionary-facade.service';
@Injectable({
  providedIn: 'root',
})
export class GsiFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  gsiData: any = [];
  gsiObj: any;

  prevButtonTrigger: any;
  enteredButton = false;
  isMatMenuOpen = false;
  menuItem: string;
  // private gsiSubject = new BehaviorSubject(this.gsiData);
  public gsiSubject = new Subject();
  gsi$ = this.gsiSubject.asObservable();

  public BookGsisSubject = new Subject();
  BookGsisSubject$ = this.BookGsisSubject.asObservable();

  private entityCardDetails: Subject<any> = new Subject<any>();
  public entityCardDetails$: Observable<
    any
  > = this.entityCardDetails.asObservable();

  private bookDesign = new Subject();
  bookDesign$ = this.bookDesign.asObservable();

  exportGsiSubject = new Subject();
  exportGsi$ = this.exportGsiSubject.asObservable();

  exportApplicationSubject = new Subject();
  exportApplication$ = this.exportApplicationSubject.asObservable();

  private exportGsiAppSubject = new Subject();
  exportGsiApp$ = this.exportGsiAppSubject.asObservable();

  importGsiSubject = new Subject();
  importGsi$ = this.importGsiSubject.asObservable();

  public rolesSubject = new Subject();
  roles$ = this.rolesSubject.asObservable();
  usersSubject = new Subject();
  usersSubject$ = this.usersSubject.asObservable();

  private orgUnitsSubject = new Subject();
  orgUnits$ = this.orgUnitsSubject.asObservable();
  usersInOrgUnitSubject = new Subject();
  usersInOrgUnitSubject$ = this.usersInOrgUnitSubject.asObservable();

  private allrolesInOrgUnitData = new BehaviorSubject(this.gsiData);
  allrolesInOrgUnit$ = this.allrolesInOrgUnitData.asObservable();

  private allrolesData = new BehaviorSubject(this.gsiData);
  allroles$ = this.allrolesData.asObservable();

  tempGsiObj = new BehaviorSubject('');
  tempGsiObj$ = this.tempGsiObj.asObservable();

  public currentCUIndex = new Subject();
  currentCUIndex$ = this.currentCUIndex.asObservable();

  tempDcdUpdate = new BehaviorSubject('');
  tempDcdUpdate$ = this.tempDcdUpdate.asObservable();

  dcd = new Subject();
  dcd$ = this.dcd.asObservable();

  private gsiName = new BehaviorSubject('');
  gsiName$ = this.gsiName.asObservable();

  exportedDetails = new BehaviorSubject('');
  exportedDetails$ = this.exportedDetails.asObservable();

  private bookSubject = new Subject();
  book$ = this.bookSubject.asObservable();

  public bookDataSubject = new BehaviorSubject({});
  bookData$ = this.bookDataSubject.asObservable();

  public componentSubject = new BehaviorSubject('');
  componentData$ = this.componentSubject.asObservable();

  private bookSaveSubject = new Subject();
  bookSave$ = this.bookSaveSubject.asObservable();

  rolesCount = new Subject();
  rolesCount$ = this.rolesCount.asObservable();

  private gsiCount = new Subject();
  gsiCount$ = this.gsiCount.asObservable();

  private booksCount = new Subject();
  booksCount$ = this.booksCount.asObservable();

  gsiSavedSubject = new Subject();
  gsiSavedInBook$ = this.gsiSavedSubject.asObservable();

  private gsiSaveId = new Subject();
  gsiSaveId$ = this.gsiSaveId.asObservable();

  private draftGSISaved = new BehaviorSubject({});
  draftGSISaved$ = this.draftGSISaved.asObservable();

  // private draftEditGSI = new Subject();
  // draftEditGSI$ = this.draftEditGSI.asObservable();

  private draftApproveReject = new Subject();
  draftApproveReject$ = this.draftApproveReject.asObservable();

  // private saveDraftGSI = new Subject();
  // saveDraftGSI$ = this.saveDraftGSI.asObservable();

  private suggestedGsiData = new Subject();
  suggestedGsiData$ = this.suggestedGsiData.asObservable();

  private stepIndex = new Subject();
  stepIndex$ = this.stepIndex.asObservable();

  draftedCuData = new Subject();
  draftedCuData$ = this.draftedCuData.asObservable();

  openSelectedCU = new Subject();
  openSelectedCU$ = this.openSelectedCU.asObservable();

  selectedCuForLibrary = new Subject();
  selectedCuForLibrary$ = this.selectedCuForLibrary.asObservable();
  versionResponse: any;
  draftId: any;

  skipWarningMsgs = new Subject();
  skipWarningMsgs$ = this.skipWarningMsgs.asObservable();
  // private gsiPublishFailed = new Subject();
  // gsiPublishFail$ = this.gsiPublishFailed.asObservable();

  private gsiNameIdSubject = new BehaviorSubject(null);
  gsiNameId$ = this.gsiNameIdSubject.asObservable();

  public updateGSIData = new BehaviorSubject(null);
  updateGSIData$ = this.updateGSIData.asObservable();

  public fileValidation = new BehaviorSubject('');
  fileValidation$ = this.fileValidation.asObservable();

  //To save data of Conditional potentiality in gsi
  private updateCPInGSIData = new Subject();
  updateCPInGSIData$ = this.updateCPInGSIData.asObservable();

  private getBookList = new Subject();
  getBookList$ = this.getBookList.asObservable();

  getEntityList = new Subject();
  getEntityList$ = this.getEntityList.asObservable();

  getEntityListWithRole = new Subject();
  getEntityListWithRole$ = this.getEntityListWithRole.asObservable();

  getEntityCardData = new Subject();
  getEntityCardData$ = this.getEntityCardData.asObservable();

  getEntityData = new Subject();
  getEntityData$ = this.getEntityData.asObservable();

  bpmnDiagramData$ = new BehaviorSubject(null);

  private updatedCUListFromHoverComponent = new Subject();
  updatedCUListFromHoverComponent$ = this.updatedCUListFromHoverComponent.asObservable();

  getSelectCUData = new Subject();
  getSelectedCUData$ = this.getSelectCUData.asObservable();

  /** Observable to Hit Draft GSI call from Change Unit Component */

  private saveDraftGSIinCU = new Subject();
  draftGSIinCU$ = this.saveDraftGSIinCU.asObservable();

  /** Stream Declaration for Passing BookID for B2c Scenario */

  private b2cHeaderSet = new Subject();
  b2csettingHeader$ = this.b2cHeaderSet.asObservable();

  public saveCcd = new Subject();
  saveCcd$ = this.saveCcd.asObservable();

  public saveCcdEQLGrammar = new Subject();
  saveCcdEQLGrammar$ = this.saveCcdEQLGrammar.asObservable();

  public alternateCu = new Subject();
  alternateCu$ = this.alternateCu.asObservable();

  toggleParagraph = new Subject();
  toggleParagraph$ = this.toggleParagraph.asObservable();
  lastSavedEntityatGSI = new BehaviorSubject('');
  lastSavedEntityatGSI$ = this.lastSavedEntityatGSI.asObservable();

  currentLayerIndexSubject = new BehaviorSubject(null);
  currentLayerIndex$ = this.currentLayerIndexSubject.asObservable();

  private saveGsiAsDraftSubject = new Subject();
  saveGsiAsDraft$ = this.saveGsiAsDraftSubject.asObservable();

  private draftCuStatus = new Subject();
  draftCuStatus$ = this.draftCuStatus.asObservable();

  getCuDetailsByid = new Subject();
  getCuDetailsByid$ = this.getCuDetailsByid.asObservable();
  publishEditgsi = new Subject();
  publishEditgsi$ = this.publishEditgsi.asObservable();
  publsihedGsi = new Subject();
  publsihedGsi$ = this.publsihedGsi.asObservable();

  publishGsiById = new Subject();
  publishGsiById$ = this.publishGsiById.asObservable();

  entityIdById = new Subject();
  entityIdById$ = this.entityIdById.asObservable();

  public editGsiDtrtSubject = new Subject();
  editGsiDtrtSubject$ = this.editGsiDtrtSubject.asObservable();

  getIdDtrtSubject = new Subject();
  getIdDtrtSubject$ = this.getIdDtrtSubject.asObservable();

  getReviewDtrtSubject = new Subject();
  getReviewDtrt$ = this.getReviewDtrtSubject.asObservable();

  applyChangesReservedActionSubject = new Subject();
  applyChangesReservedAction$ = this.applyChangesReservedActionSubject.asObservable();

  private revertChangesDtrtSubject = new Subject();
  revertChangesDtrt$ = this.revertChangesDtrtSubject.asObservable();

  private applyChangesDtrtSubject = new Subject();
  applyChangesDtrt$ = this.applyChangesDtrtSubject.asObservable();

  getReviewMileStoneDtrtSubject = new Subject();
  getReviewMileStoneDtrt$ = this.getReviewMileStoneDtrtSubject.asObservable();

  private revertMileStoneDtrtSubject = new Subject();
  revertMileStoneDtrt$ = this.revertMileStoneDtrtSubject.asObservable();

  private applyMileStoneDtrtSubject = new Subject();
  applyMileStoneDtrt$ = this.applyMileStoneDtrtSubject.asObservable();

  fetchDtrtIRDRSubject = new Subject();
  fetchDtrtIRDRSubject$ = this.fetchDtrtIRDRSubject.asObservable();

  getReviewDtrtIRDRSubject = new Subject();
  getReviewDtrtIRDRSubject$ = this.getReviewDtrtIRDRSubject.asObservable();

  revertChangesDtrtIRDRSubject = new Subject();
  revertChangesDtrtIRDRSubject$ = this.revertChangesDtrtIRDRSubject.asObservable();

  applyChangesDtrtIRDRSubject = new Subject();
  applyChangesDtrtIRDRSubject$ = this.applyChangesDtrtIRDRSubject.asObservable();

  getReviewDtrtgeIRDRSubject = new Subject();
  getReviewDtrtgeIRDRSubject$ = this.getReviewDtrtgeIRDRSubject.asObservable();

  revertChangesDtrtgeIRDRSubject = new Subject();
  revertChangesDtrtgeIRDRSubject$ = this.revertChangesDtrtgeIRDRSubject.asObservable();

  applyChangesDtrtgeIRDRSubject = new Subject();
  applyChangesDtrtgeIRDRSubject$ = this.applyChangesDtrtgeIRDRSubject.asObservable();

  revertChangesReservedActionSubject = new Subject();
  revertChangesReservedAction$ = this.revertChangesReservedActionSubject.asObservable();

  getReviewReservedActionSubject = new Subject();
  getReviewReservedAction$ = this.getReviewReservedActionSubject.asObservable();

  public getExceptionCategoryList = new Subject();
  getExceptionCategoryList$ = this.getExceptionCategoryList.asObservable();

  // GSI List for RTCT Dashboard  // FE757
  private gsiDataForRTCT = new Subject();
  gsiDataForRTCT$ = this.gsiDataForRTCT.asObservable();

  /* For Milestone - START ------------------ */
  public showmilestone = new Subject();
  showmilestone$ = this.showmilestone.asObservable();

  public saveMilestoneSubject = new Subject();
  saveMilestoneSubject$ = this.saveMilestoneSubject.asObservable();

  public editMilestoneSubject = new Subject();
  editMilestoneSubject$ = this.editMilestoneSubject.asObservable();

  public deleteMilestoneSubject = new Subject();
  deleteMilestoneSubject$ = this.deleteMilestoneSubject.asObservable();

  public getAllMilestoneSubject = new Subject();
  getAllMilestoneSubject$ = this.getAllMilestoneSubject.asObservable();
  /* For Milestone - END ------------------- */

  private sendBookDataForExtraction = new BehaviorSubject(null);
  sendBookDataForExtraction$ = this.sendBookDataForExtraction.asObservable();

  // milestone transaction
  gsiDataForMilestone = new Subject();
  gsiDataForMilestone$ = this.gsiDataForMilestone.asObservable();

  milestoneList = new Subject();
  milestoneList$ = this.milestoneList.asObservable();

  percentmilestoneList = new Subject();
  percentmilestoneList$ = this.percentmilestoneList.asObservable();

  particularMilestone = new Subject();
  particularMilestone$ = this.particularMilestone.asObservable();

  notifyMilestoneSubject = new Subject();
  notifyMilestone$ = this.notifyMilestoneSubject.asObservable();

  private subtransactionalDCD = new BehaviorSubject('');
  subtransactionalDCD$ = this.subtransactionalDCD.asObservable();

  private scheduledjobsData = new BehaviorSubject('');
  scheduledjobsData$ = this.scheduledjobsData.asObservable();

  private savedScheduledjobData = new BehaviorSubject('');
  savedScheduledjobData$ = this.savedScheduledjobData.asObservable();

  private deleteScheduleJob = new BehaviorSubject('');
  deleteScheduleJob$ = this.deleteScheduleJob.asObservable();

  public lastCuid = new Subject();
  lastCuid$ = this.lastCuid.asObservable();

  private validateGsiFileSubject = new Subject();
  validateGsiFile$ = this.validateGsiFileSubject.asObservable();

  importSolutionFile = new Subject();
  importSolutionFile$ = this.importSolutionFile.asObservable();

  validatedGSIName = new Subject();
  validatedGSIName$ = this.validatedGSIName.asObservable();

  triggerCupayloadData = new BehaviorSubject({});
  triggerCupayloadData$ = this.triggerCupayloadData.asObservable();

  credsSubject = new Subject();
  credsSubject$ = this.credsSubject.asObservable();

  public showNewCu = new Subject();
  showNewCu$ = this.showNewCu.asObservable();

  public changeUnitUIControl = new Subject();
  changeUnitUIControl$ = this.changeUnitUIControl.asObservable();

  public eventAllgsi = new Subject();
  eventAllgsi$ = this.eventAllgsi.asObservable();

  importExportTranslationSubject = new Subject();
  importExportTranslation$ = this.importExportTranslationSubject.asObservable();

  closeDialog = new Subject();
  closeDialog$ = this.closeDialog.asObservable();

  totalErrorsCount = new Subject();
  totalErrorsCount$ = this.totalErrorsCount.asObservable();

  getSourceAndTargetEnvSubject = new Subject();
  getSourceAndTargetEnv$ = this.getSourceAndTargetEnvSubject.asObservable();

  dtrtPromoteCustomizationSubject = new Subject();
  dtrtPromoteCustomization$ = this.dtrtPromoteCustomizationSubject.asObservable();

  dtrtPromotionData = new BehaviorSubject({});
  dtrtPromotionData$ = this.dtrtPromotionData.asObservable();

  envData = new BehaviorSubject({});
  envData$ = this.envData.asObservable();

  dtrtCustomizationData = new Subject();
  dtrtCustomizationData$ = this.dtrtCustomizationData.asObservable();

  paraBookGsisSubject = new Subject();
  paraBookGsisSubject$ = this.paraBookGsisSubject.asObservable();


  includedLanguages = {
    hi: 'hindi',
    en: 'english',
    ar: 'arabic',
    gu: 'gujarati',
    sa: 'sanskrit',
    te: 'telugu',
    es: 'spanish',
    fr: 'french',
    de: 'german',
    pt: 'portuguese',
  };

  excludedLanguageForFat2Flat = new BehaviorSubject(this.includedLanguages);
  excludedLanguageForFat2Flat$ = this.excludedLanguageForFat2Flat.asObservable();

  validateVantagePoint = new Subject();
  validateVantagePoint$ = this.validateVantagePoint.asObservable();

  bookIDb2c: any;

  adminSolutions: any;
  tempGSIId: any;
  currentCuEventsData: any;
  safeGuardBetName: any;
  showReview: boolean;
  showFinish: boolean;
  showPublish: boolean;

  showPublishNotB2c: boolean;
  showPublishB2c: boolean;
  showDebug: boolean;

  showSubTransactional: boolean;

  gsiButtonClickData = new Subject();
  gsiButtonClickData$ = this.gsiButtonClickData.asObservable();

  showBookInGSI = new Subject();
  showBookInGSI$ = this.showBookInGSI.asObservable();

  firstStep: boolean;

  closeDialogSubject = new Subject();
  closeDialogSubject$ = this.closeDialogSubject.asObservable();

  indpndtCuCreation: boolean;
  rearrangeEntityData = new Subject();
  rearrangeEntityData$ = this.rearrangeEntityData.asObservable();

  constructor(
    private gsiEndPointService: GsiEndpointService,
    private gsiSToreService: GsiStoreService,
    private alertService: AlertService,
    private loader: LoaderService,
    private router: Router,
    private uploadEndpointService: UploadEndpointService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService
  ) {
    this.gsiSToreService.stateChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        if (state?.gsiList) {
          this.gsiSubject.next(state.gsiList);
        }
      });
  }
  setCredentials(creds: any) {
    this.credsSubject.next(creds);
  }

  addNestedCu() {
    this.gsiEndPointService.addNestedCu().subscribe((res: any) => {
      if (res) {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        let cus: any = [];
        /* istanbul ignore next */
        res?.result?.solutionLogic.forEach((cu: any) => {
          cus.push(cu.DATA);
        });
        res.result.solutionLogic = cus;
      }
    });
  }

  addSeqCu(gsiId: number, index: number, body: any) {
    this.loader.show();
    this.gsiEndPointService
      .addSeqCu(gsiId, index, body)
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          let cus: any = [];
          /* istanbul ignore next */
          res?.result?.solutionLogic.forEach((cu: any) => {
            cus.push(cu.DATA);
          });
          res.result.solutionLogic = cus;
        }
      });
  }

  getAllGsis(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean,
    isTXNRights: boolean
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(
        pagenum + 1,
        pageSize,
        searchCriteria,
        isPublished,
        isTXNRights
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.gsiSToreService.setStateData(gsiList);
      });
  }

  // Fetch GSI list for RTCT Dashboard  // FE757
  /* istanbul ignore next */
  getGsiListForRTCT(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum + 1, pageSize, searchCriteria, false, false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.gsiDataForRTCT.next(gsiList);
      });
  }

  getCurrentCUIIndex(index: number) {
    this.currentCUIndex.next(index);
  }

  // GSI List for Milestone Dashboard
  getGsiListForMilestone(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean,
    isTXNRights: boolean
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum + 1, pageSize, searchCriteria, true, true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.gsiDataForMilestone.next(res.result);
      });
  }

  getMilestoneList(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getMilestoneList(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.milestoneList.next(res);
      });
  }

  getpercentMilestoneList(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getpercentMilestoneList(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.percentmilestoneList.next(res);
      });
  }

  getParticularMilestone(gsiId: number, milestoneid: number) {
    this.loader.show();
    this.gsiEndPointService
      .getParticularMilestone(gsiId, milestoneid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.particularMilestone.next(res);
      });
  }

  notifyMilestone(element: any) {
    this.loader.show();
    this.gsiEndPointService
      .notifyMilestone(element)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();

        let response = JSON.parse(res.result.body);
        this.alertService.showToaster(response.message, '', 'success');

        this.notifyMilestoneSubject.next(res);
      });
  }

  getGsiSuggestions(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum, pageSize, searchCriteria, isPublished, false)
      .pipe(debounceTime(1000), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.suggestedGsiData.next(gsiList);
      });
  }

  // getAllDraftGsis(pagenum: number, pageSize: number, searchCriteria: string) {
  //   this.loader.show();
  //   this.gsiEndPointService
  //     .getAllDraftGSIs(pagenum, pageSize, searchCriteria)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res) => {
  //       this.loader.hide();
  //       this.gsiSToreService.setStateData(res);
  //     });
  // }

  getAllRoles(searchCriteria: string) {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles(searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.rolesSubject.next({ content: res });
      });
  }
  getAllUsers(id: any) {
    this.loader.show();
    if (id) {
      this.gsiEndPointService
        .getAllUsers(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.loader.hide();
          this.usersSubject.next(res);
        });
    }
  }

  getAllOrgUnits(searchCriteria: string) {
    this.loader.show();
    this.gsiEndPointService
      .getAllOrgUnits(searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.orgUnitsSubject.next({ content: res });
      });
  }
  getAllUsersInOrgUnit(id: any) {
    this.loader.show();
    if (id) {
      this.gsiEndPointService
        .getAllUsersInOrgUnit(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.loader.hide();
          this.usersInOrgUnitSubject.next(res);
        });
    }
  }

  getROlesCount() {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesCount.next(res?.length);
      });
  }

  getRoleNames() {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.allrolesData.next(res);
      });
  }

  getRoleInOrgUnitNames() {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.allrolesInOrgUnitData.next(res);
      });
  }

  getAllBooks(pageNo: number, pageSize: number, searchCriteria: string) {
    return this.gsiEndPointService.getAllBooks(
      pageNo + 1,
      pageSize,
      searchCriteria
    );
  }

  dashBoardgetAllBooks(
    pageNo: number,
    pageSize: number,
    searchCriteria: string
  ) {
    return this.gsiEndPointService.dashBoardgetAllBooks(
      pageNo,
      pageSize,
      searchCriteria
    );
  }

  // getBooksCount(pageSize: number, searchCriteria: string) {
  //   this.loader.show();
  //   return this.gsiEndPointService
  //     .getBooksCount(0, pageSize, searchCriteria)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.loader.hide();
  //       this.booksCount.next(res);
  //     });
  // }

  saveGsi(body: any, type?: any, bookId?: number) {
    this.loader.show();
    let gsiData = gsiDeMapper(body);
    this.gsiEndPointService
      .saveGsi(gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res) {
            /* istanbul ignore next */
            res.result.source = gsiData?.source; //source added to GSI while saving it
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
            /* istanbul ignore next */
            const gsiRes = gsiMapper(res?.result);
            this.updateGSIObject(gsiRes);
          }
        },
        (error) => {
          const gsiRes = gsiMapper(gsiData);
          if (!this.firstStep) {
            this.updateGSIObject(gsiRes);
          } else {
            this.firstStep = false;
          }

          // const { err } = error;
          // this.loader.hide();
          // // this.gsiPublishFailed.next();
          // if (err) {
          //   this.alertService.showToaster(`${err}`, '', 'error');
          // }
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }
  //to save conditional potemtiality in gsi
  saveCPInGsi(body: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveCPInGsi(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res) {
            /* istanbul ignore next */
            res.result.source = body?.source;
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
            /* istanbul ignore next */
            const gsiRes = gsiMapper(res?.result);
            this.updateCPInGSIData.next(gsiRes);
          }
        },
        (err: any) => {
          /* istanbul ignore else */
          if (err.status === 400) {
            this.updateCPInGSIData.next(err);
            this.alertService.showToaster(err.message, '', 'error');
          }
        }
      );
  }

  updateGSIObject(gsiData: any) {
    this.updateGSIData.next(gsiData);
    /* istanbul ignore else */
    if (gsiData?.solutionLogic) {
      this.lastCuid.next(
        gsiData.solutionLogic[gsiData.solutionLogic?.length - 1]?.id
      );
      this.triggerCupayloadData.next(gsiDeMapper(gsiData));
    }
  }

  updateTempGsiObject(body: any) {
    this.tempGsiObj.next(body);
  }
  selectedMenuIndex(menuItem: string) {
    this.menuItem = menuItem;
  }
  /* istanbul ignore next */
  updateDcdInSolutionLogic(body: any) {
    this.tempDcdUpdate.next(body);
  }
  // //gets data of impacted bet
  loadSingleLevelDetailsById(id: any, type: string) {
    /* istanbul ignore next */
    if (id && type) {
      return this.gsiEndPointService.loadSingleLevelDetailsById(id, type);
    }
  }

  addNewDcd(dcd: any) {
    this.dcd.next(dcd);
  }
  addGsiToDcd(gsi: any) {
    this.gsiName.next(gsi);
  }

  exportData(data: any) {
    this.exportedDetails.next(data);
  }

  saveBook(book: any, saveCpInBook?: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveBook(book, saveCpInBook)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          // res.result.source=(book?.source)?book.source:res.result?.source;
          this.sendBookDataForExtraction.next(res.result);
          this.loader.hide();
          this.bookSaveSubject.next(res.result);
          this.bookDataSubject.next(res.result);
          this.alertService.showToaster(res.message, '', 'success');
        },
        (err: any) => {
          /* istanbul ignore next */
          if (err.status === 400) {
            this.bookSaveSubject.next(err);
            this.alertService.showToaster(err.message, '', 'error');
          }
        }
      );
  }

  getBookDetailsById(
    bookId: any,
    gsiComponent?: boolean,
    isConditionShowHide?: boolean
  ) {
    if (!isConditionShowHide) {
      sessionStorage.setItem(
        'bookData',
        JSON.stringify({ id: bookId, isFromLibraryBook: true })
      );
    }
    this.bookIDb2c = bookId;
    this.gsiEndPointService
      .getBookDetails(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (gsiComponent) {
            this.bookSubject.next(res.result);
            this.bookDataSubject.next(res.result);
          } else {
            this.bookSaveSubject.next(res.result);
          }
        },
        (err: any) => {
          /* istanbul ignore next */
          if (err.status === 400) {
            this.bookSaveSubject.next(err);
          }
        }
      );
  }

  lastesavedEntityResponse(res: any) {
    this.lastSavedEntityatGSI.next(res);
  }
  resetBook() {
    this.bookSaveSubject.next(null);
  }

  saveGsiInBook(bookId: number, gsi: Gsi) {
    this.loader.show();
    this.gsiEndPointService
      .saveGsiInBook(bookId, gsi)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.gsiSavedSubject.next(res);
      });
  }
  //post method for cu.
  editGsiDtrt(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .editGsiDtrt(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.editGsiDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getIdDtrt(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .getIdDtrt(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        // res.comp = value;
        this.getIdDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewOfDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewOfDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getRevertChangesDtrt(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .getRevertChangesDtrt(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getApplyChangesDtrt(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .getApplyChangesDtrt(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyChangesDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewMileStoneDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewMileStoneDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewMileStoneDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getRevertMileStoneDtrt(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .getRevertMileStoneDtrt(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertMileStoneDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getApplyMileStoneDtrt(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .getApplyMileStoneDtrt(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyMileStoneDtrtSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  fetchDtrtIRDR(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .fetchDtrtIRDR(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.fetchDtrtIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewDtrtIRDR() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewDtrtIRDR()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewDtrtIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  revertChangesDtrtIRDR(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .revertChangesDtrtIRDR(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesDtrtIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  applyChangesDtrtIRDR(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .applyChangesDtrtIRDR(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyChangesDtrtIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewDtrtgeIRDR() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewDtrtgeIRDR()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewDtrtgeIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  revertChangesDtrtgeIRDR(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .revertChangesDtrtgeIRDR(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesDtrtgeIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  applyChangesDtrtgeIRDR(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .applyChangesDtrtgeIRDR(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyChangesDtrtgeIRDRSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewDtrtReservedAction() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewDtrtReservedAction()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewReservedActionSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getRevertChangesDtrtReservedAction(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .getRevertChangesDtrtReservedAction(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesReservedActionSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  applyChangesDtrtReservedAction(ids: any) {
    this.loader.show();
    this.gsiEndPointService
      .applyChangesDtrtReservedAction(ids)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyChangesReservedActionSubject.next(res);
        if (res.status === 200)
          this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getBookGsis(
    bookId: any,
    pageSize: number,
    pageNumber: number,
    search?: string,
    isFromLibraryBook?: boolean,
    isParagraph?: boolean
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getBookGsis(bookId, pageSize, pageNumber, search, isFromLibraryBook)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (
          res &&
          res.content &&
          res.content?.length &&
          res?.content[0] === 'no record found'
        ) {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.gsiSubject.next(res);
        /* istanbul ignore next */
        if (res) {
          if (isParagraph) {
            this.paraBookGsisSubject.next(res);
          } else {
            this.BookGsisSubject.next(res);
          }

        }
      });
  }
  getBookDesign(bookId: any) {
    this.loader.show();
    this.gsiEndPointService
      .getBookDesign(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (
          res &&
          res.content &&
          res.content?.length &&
          res?.content[0] === 'no record found'
        ) {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.bookDesign.next(res);
      });
  }
  updateGSI(id: any, index: any, body: any) {
    this.loader.show();
    this.gsiEndPointService
      .updateGSI(id, index, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
      });
  }

  selectedBook(book: any) {
    /* istanbul ignore next */
    this.getBookDetailsById(book?.id, true);
    // this.bookSubject.next(book);
  }
  deleteCuFromGsi(gsiId: any, cuId: any) {
    this.loader.show();
    this.gsiEndPointService
      .deleteCuFromGsi(gsiId, cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
      });
  }
  updateGsiRights(gsi: any, body: any) {
    this.loader.show();
    this.gsiEndPointService.updateGsiRights(gsi, body).subscribe((res: any) => {
      this.loader.hide();
      if (res) this.alertService.showToaster(res.message, '', 'success');
    });
  }

  // Service API Calls for Versioning Draft API Calls //

  saveDraftVersion(body: any, type: any, bookId: number) {
    this.loader.show();
    this.gsiEndPointService
      .saveDraftVersion(body, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          let resObj = { ...res.result, type: type };
          this.draftGSISaved.next(resObj);
          if (type == 'publish') {
            if (bookId !== null) {
              this.loader.hide();
              this.alertService.showToaster(res.message, '', 'success');
              this.saveGsiInBook(bookId, res.result);
            }
            this.router.navigate(['/mylibrary-dashboard']);
          } else if (type == 'finish') {
            this.alertService.showToaster(res.message, '', 'success');
          }
        }
      });
  }

  // saveDraftEditVersion(body: any, type: any) {
  //   this.loader.show();
  //   this.gsiEndPointService
  //     .saveDraftEditVersion(body, type)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(
  //       (res: any) => {
  //         if (res) {
  //           this.loader.hide();
  //           if (res.status === 400) {
  //             this.alertService.showToaster(res.message, '', 'error');
  //           } else {
  //             this.alertService.showToaster(res.message, '', 'success');
  //           }

  //           this.draftEditGSI.next(res.result);
  //         } else {
  //
  //           this.alertService.showToaster(res.message, '', 'error');
  //           this.loader.hide();
  //         }
  //       },
  //       (err: any) => {
  //
  //       }
  //     );
  // }

  // Show milestone based on show value
  setMilestone(show: boolean) {
    this.showmilestone.next(show);
  }

  // Save a milestone
  saveMilestone(payload: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveMilestone(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.saveMilestoneSubject.next(res);
      });
  }

  // Edit a milestone
  editMilestone(payload: any) {
    this.loader.show();
    this.gsiEndPointService
      .editMilestone(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.editMilestoneSubject.next(res);
      });
  }

  // Delete a milestone based on milestone Id
  deleteMilestone(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .deleteMilestone(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.deleteMilestoneSubject.next(res);
      });
  }

  // Fetch all milestones based on gsi and tenant Id
  getAllMilestone(gsiId: any) {
    this.loader.show();
    this.gsiEndPointService
      .getAllMilestone(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAllMilestoneSubject.next(res);
      });
  }

  saveonAppoveReject(body: any, type: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveApproveReject(body, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          this.draftApproveReject.next(res.result);
        }
      });
  }

  // saveDraftedGSI(body: any, type: any) {
  //   this.loader.show();
  //   if (body.name && body.name !== '') {
  //     this.gsiEndPointService
  //       .saveDraftGSI(body)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe(
  //         (res: any) => {
  //           if (res) {
  //             if (res.status === 400) {
  //               res.result.hideAgentStep = true;
  //             } else {
  //               res.result.hideAgentStep = false;
  //             }
  //             this.loader.hide();
  //             this.alertService.showToaster(res.message, '', 'success');
  //             this.saveDraftGSI.next(res.result);
  //           }
  //         },
  //         (error) => {
  //           // this.alertService.showToaster(error.message, '', 'error');
  //           //this.saveDraftGSI.next(error);
  //         }
  //       );
  //   } else {
  //     this.loader.hide();
  //   }
  // }

  exportGsis(gsiId: number) {
    this.loader.show();
    this.gsiEndPointService
      .exportGsis(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          this.exportGsiSubject.next(res);
        }
      });
  }

  exportGsiApp(data: any) {
    return this.gsiEndPointService.exportGsiApp(data);
  }
  exportApplicationToEnv(data: any, envData: any, promoteWithDTRT: any) {
    this.loader.show();
    this.gsiEndPointService
      .exportApplicationToEnv(data, envData, promoteWithDTRT)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore else */
          if (res) {
            this.loader.hide();
            this.exportApplicationSubject.next(res);
            this.alertService.showToaster(
              res.message,
              '',
              res.status == 200 ? 'success' : 'error'
            );
          }
        },
        (err: any) => {
          const error =
            err?.error?.result?.cuExceptions?.BASIC[0]?.errorDetails[0];
          this.alertService.showToaster(
            error?.message ? error?.message : err.message,
            '',
            'error'
          );
        }
      );
  }
  exportHistory(searchText: any, pageSize: number, pageNumber: number) {
    return this.gsiEndPointService.exportHistory(
      searchText,
      pageSize,
      pageNumber
    );
  }

  importGSIs(reqPayload: any) {
    this.loader.show();
    this.gsiEndPointService
      .importGSIs(reqPayload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res.status !== 417) {
            this.importGsiSubject.next(res);
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (err: any) => {
          this.importGsiSubject.next(err);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  validateGsiFile(reqPayload: any) {
    this.loader.show();
    this.gsiEndPointService
      .validateGsiFile(reqPayload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res?.status == 200) {
          this.validateGsiFileSubject.next(res?.result);
          this.solutionDictionaryFacade.getGSIImportDetails(res?.result);
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }

  getAdminSolutions(type: string, labels: any, isCarnvial?: boolean) {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    this.adminSolutions = [
      {
        type: 'manage_roles',
        solutions: [
          {
            name: labels.View_Roles,
            url: 'admin/roles',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-roles/view-role.svg',
          },
          {
            name: labels.Add_Role,
            url: 'admin/addrole',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-roles/add-role.svg',
          },
          {
            name: labels.Update_Role,
            url: 'admin/updaterole',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-roles/update-role.svg',
          },
          {
            name: labels.Delete_Role,
            url: 'admin/deleterole',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-roles/delete-role.svg',
          },
          {
            name: labels.Import_Role,
            url: 'admin/importrole',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-roles/import-role.svg',
          },
        ],
      },
      {
        type: 'manage_mcc',
        solutions: [
          {
            name: 'MCC Customisation',
            url: 'manage-mcc',
            imageUrl: '../../../../assets/img/admin-icons/manage-mcc/mcc.svg',
          },
          // {
          //   name: 'Customise Theme',
          //   url: 'tenant',
          // },
          {
            name: 'Login Customisation',
            url: 'manage-mcc/login-customization',
            imageUrl: '../../../../assets/img/admin-icons/manage-mcc/mcc.svg',
          },
          {
            name: 'Loader Customisation',
            url: 'manage-mcc/loader-customisation',
            imageUrl: '../../../../assets/img/admin-icons/manage-mcc/mcc.svg',
          },
          {
            name: 'Font Customization',
            url: 'manage-mcc/font-customization',
            imageUrl: '../../../../assets/img/admin-icons/manage-mcc/mcc.svg',
          },
        ],
      },

      {
        type: 'Feature_Flag',
        solutions: [
          {
            name: 'View Feature Flags',
            url: 'admin/feature-flag-view',
          },
          {
            name: 'Create Feature Flag',
            url: 'admin/feature-flag-create',
          },
        ],
      },

      {
        type: 'manage_organisation_unit',
        solutions: [
          {
            name: labels.View_Organisation_Unit,
            url: 'admin/manageorganisationunit/vieworganisationUnit',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-organisation-unit/view-org-unit.svg',
          },
          {
            name: labels.Add_Organisation_Unit,
            url: 'admin/manageorganisationunit/addorganisationUnit',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-organisation-unit/add-org-unit.svg',
          },
          {
            name: labels.Update_Organisation_Unit,
            url: 'admin/manageorganisationunit/updateorganisationUnit',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-organisation-unit/update-org-unit.svg',
          },
        ],
      },
      {
        type: 'manage_users',
        solutions: [
          {
            name: labels.View_Users,
            url: 'admin/manage-users/viewuser',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/view-users.svg',
          },
          {
            name: labels.Add_User,
            url: 'admin/manage-users/adduser',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/add-user.svg',
          },
          {
            name: labels.Update_User,
            url: 'admin/manage-users/updateuser',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/update-user.svg',
          },
          {
            name: labels.Delete_User,
            url: 'admin/manage-users/deleteuser',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/delete-user.svg',
          },
          {
            name: labels.Import_User,
            url: 'admin/manage-users/importuser',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/import-user.svg',
          },
          {
            name: 'Update Users Under Manager',
            url: 'admin/manage-users/updatemanager',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/update-manager.svg',
          },
          {
            name: 'Bulk Delete Users',
            url: 'admin/manage-users/bulkdeleteusers',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/update-manager.svg',
          },
          {
            name: 'Bulk update Users',
            url: 'admin/manage-users/bulkupdateusers',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-users/update-manager.svg',
          },
        ],
      },
      {
        type: 'manage_tenants',
        solutions: [
          {
            name: labels.View_Tenants,
            url: 'admin/manage-tenants/viewtenant',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-tenants/viewtenants.svg',
          },
          {
            name: labels.Add_Tenant,
            url: 'admin/manage-tenants/addtenant',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-tenants/addtenant.svg',
          },
          {
            name: labels.Update_Tenant,
            url: 'admin/manage-tenants/updatetenant',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-tenants/updatetenant.svg',
          },
        ],
      },
      {
        type: 'manage_termsAndConditions',
        solutions: [
          {
            name: labels.TnC || 'TnC',
            url: 'admin/manage-tenants/tnc',
            imageUrl: '../../../../assets/img/admin-icons/manage-tenants/updatetenant.svg',
          },
        ],
      },
      {
        type: 'translation_config',
        solutions: [
          {
            name: labels.Add_Label,
            url: 'admin/addlabel',
            imageUrl:
              '../../../../assets/img/admin-icons/translation-config/addlabel.svg',
          },
          {
            name: labels.Update_Label,
            url: 'admin/updatelabel',
            imageUrl:
              '../../../../assets/img/admin-icons/translation-config/updatelabel.svg',
          },
        ],
      },
      {
        type: 'manage_tenants_selfOnboard',
        solutions: [
          {
            name: labels.View_Tenants,
            url: 'admin/selfOnboardTenant',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-tenants/viewtenants.svg',
          },
          {
            name: labels.Add_Tenant,
            url: 'admin/addSelfOnboardTenant',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-tenants/addtenant.svg',
          },
          {
            name: labels.Update_Tenant,
            url: 'admin/updateSelfOnboardTenant',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-tenants/updatetenant.svg',
          },
        ],
      },
      {
        type: 'manage_configration',
        solutions: [
          {
            name: labels.Translation_Enhancement,
            url: 'admin/translationenhancement',
          },
        ],
      },
      {
        type: 'gsi_blocking',
        solutions: [
          {
            name: labels.Add_Configuration,
            url: 'admin/gsiblocking/addconfigurations',
            imageUrl:
              '../../../../assets/img/admin-icons/gsi-blocking/addconfig.svg',
          },
          {
            name: labels.View_Configuration,
            url: 'admin/gsiblocking/viewconfigurations',
            imageUrl:
              '../../../../assets/img/admin-icons/gsi-blocking/viewconfig.svg',
          },
        ],
      },
      {
        type: 'sms_email_tracker',
        solutions: [
          {
            name: 'SMS',
            url: 'admin/tracker/sms',
          },
          {
            name: 'EMAIL',
            url: 'admin/tracker/email',
          },
        ],
      },
      {
        type: 'manage_subscriptions',
        solutions: [
          {
            type: 'manage_features_plans',
            features: [
              {
                name: labels.View_Features,
                url: 'admin/features/viewfeature',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/viewfeatures.svg',
              },
              {
                name: labels.Add_Feature,
                url: 'admin/features/addfeature',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/addfeature.svg',
              },
              {
                name: labels.Update_Feature,
                url: 'admin/features/updatefeature',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/updatefeature.svg',
              },
              {
                name: labels.Delete_Feature,
                url: 'admin/features/deletefeature',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/deletefeature.svg',
              },
            ],
            plans: [
              {
                name: labels.View_Plans,
                url: 'admin/plans/viewplan',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/viewplans.svg',
              },
              {
                name: labels.Add_Plan,
                url: 'admin/plans/addplan',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/addplan.svg',
              },
              {
                name: labels.Update_Plan,
                url: 'admin/plans/updateplan',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/updateplan.svg',
              },
              {
                name: labels.Delete_Plan,
                url: 'admin/plans/deleteplan',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-subscriptions/deleteplan.svg',
              },
            ],
          },
        ],
      },
      {
        type: 'manage_saasapplications',
        solutions: [
          {
            // name: labels.View_Saas_Tenants,
            name: 'View_Saas_Tenants',
            // name: 'View SAAS Applications',
            url: 'admin/manage-saasapplications/viewSaasTenants',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-saas-applications/viewsaasapplications.svg',
          },
          {
            // name: labels.View_Associated_Saas_Applications,
            name: 'View_Associated_Saas_Applications',
            // name: 'Add SAAS Applications',
            url: 'admin/manage-saasapplications/viewAssociatedSaasApplications',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-saas-applications/addsaasapplication.svg',
          },
          {
            name: 'Add_Saas_Applications',
            // name: 'Add SAAS Applications',
            url: 'admin/manage-saasapplications/addSaasApplication',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-saas-applications/addsaasapplication.svg',
          },
        ],
      },
      {
        type: 'manage_groups',
        solutions: [
          {
            name: labels.View_Ldap,
            url: 'admin/managegroups/groups',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-groups/view-ldap.svg',
          },
          {
            name: 'Add_Group',
            url: 'admin/managegroups/addgroup',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-groups/view-ldap.svg',
          },
          {
            name: 'Update_Group',
            url: 'admin/managegroups/updategroup',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-groups/view-ldap.svg',
          },
          {
            name: 'Delete_Group',
            url: 'admin/managegroups/deletegroup',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-groups/view-ldap.svg',
          },
          {
            name: 'View_Group',
            url: 'admin/managegroups/viewgroup',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-groups/view-ldap.svg',
          },
        ],
      },
      {
        type: 'manage_id_providers',
        solutions: [
          {
            name: labels.Add_LDAP_Config,
            url: 'admin/addldapconfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-id-providers/addldapconfig.svg',
          },
          {
            name: labels.View_LDAP_Config,
            url: 'admin/viewldapconfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-id-providers/viewldapconfig.svg',
          },
        ],
      },
      {
        type: 'manage_data',
        solutions: [
          {
            name: labels.Import,
            url: 'admin/managedata/importentity',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-data/import.svg',
          },
          {
            name: labels.Export,
            url: 'admin/managedata/exportentity',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-data/export.svg',
          },
          {
            name: 'View Import/Export Job Processes',
            url: 'admin/managedata/viewimportexportjobs',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-data/viewimportexportjobs.svg',
          },
          {
            name: 'Import History',
            url: 'admin/managedata/importhistory',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-data/importhistory.svg',
          },
          {
            name: 'EQL History',
            url: 'admin/managedata/eqlupdatehistory',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-data/importhistory.svg',
          },
          {
            name: labels?.Data_Actions,
            url: 'admin/managedata/entity-search-criteria',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-data/viewimportexportjobs.svg',
          },
        ],
      },
      {
        type: 'manage_solutions',
        solutions: [
          {
            name: labels.Import,
            url: 'admin/importsolution',
            isUser: false,
            imageUrl:
              '../../../../assets/img/admin-icons/manage-solutions/import.svg',
          },
          {
            name: 'Import_Translation',
            url: 'admin/importtranslation',
            isUser: false,
            imageUrl:
              '../../../../assets/img/admin-icons/manage-solutions/import.svg',
          },

          {
            name: labels.View_Import_History,
            url: 'admin/importsolutiontable',
            isUser: false,
            imageUrl:
              '../../../../assets/img/admin-icons/manage-solutions/viewimporthistory.svg',
          },
          {
            // adding path for both files
            name: labels.Extract_Entity_from_ERD,
            url: 'admin/managedata/extractentity',
            isUser: false,
            imageUrl:
              '../../../../assets/img/admin-icons/manage-solutions/extractentityfromERD.svg',
          },
          {
            name: labels.View_Extract_Entity,
            url: 'admin/managedata/extractentitytable',
            isUser: false,
            imageUrl:
              '../../../../assets/img/admin-icons/manage-solutions/viewextractentity.svg',
          },
        ],
      },
      {
        type: 'tenant_UI',
        solutions: [
          {
            name: labels.Customise_UI,
            url: 'admin/customise',
          },
        ],
      },
      {
        type: 'manage_enironments',
        solutions: [
          {
            name: labels.Manage_Environments,
            url: 'admin/manageenv',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-environments/manageenvironments.svg',
          },
        ],
      },
      // {
      //   type: 'delegation',
      //   solutions: [
      //     {
      //       name: labels.List_Delegation,
      //       url: 'admin/listdelegation',
      //     },
      //   ],
      // },
      {
        type: 'manage_audit',
        solutions: [
          {
            name: labels.View_Audit_Log,
            url: 'admin/manageaudit',
            imageUrl:
              '../../../../assets/img/admin-icons/trace-audit/viewauditlog.svg',
          },
        ],
      },
      {
        type: 'manage_ge_irdr',
        solutions: [
          {
            name: labels.Manage_GE_IRDR,
            url: 'admin/managegeirdr',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-ge-irdr/managegeirdr.svg',
          },
          {
            name: 'Indexing',
            url: 'admin/managegeirdr/indexing',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-ge-irdr/managegeirdr.svg',
          },
        ],
      },
      {
        type: 'manage_entity_relationship',
        solutions: [
          {
            name: labels?.View_Eql_Entity_Relation,
            url: 'admin/eql/vieweqlentityrelation',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-entity-relationship/vieweqlentityrelation.svg',
          },
        ],
      },
      {
        type: 'Manage_Configurations',
        solutions: [
          {
            name:
              labels.Special_Features && labels.Scheduled_Jobs
                ? labels.Special_Features + ' ' + labels.Scheduled_Jobs
                : '',
            url: 'admin/specialfeatures',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/scheduledjobs.svg',
          },
          {
            name: 'Conditional Potentiality Entity',
            url: 'admin/manageconfiguration/conditionalpotentialityentity',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/conditionalpotentialityentity.svg',
          },
          {
            name: labels.Translation_Enhancement,
            url: 'admin/manageconfiguration/translationenhancement',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/translationenhancement.svg',
          },
          {
            name: labels?.UI_Control_Creation,
            url: 'admin/manageconfiguration/ui-control-creation',
            imageUrl: '../../../../assets/img/admin-icons/manage-configurations/translationenhancement.svg',
          },
          {
            name: labels.Configure_Bot,
            url: 'admin/manageconfiguration/configureBot',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/configurationbot.svg',
          },
          {
            name: labels.GSI_training_for_chatbot,
            url: 'admin/manageconfiguration/configureGSI',
            imageUrl: '../../../../assets/img/admin-icons/manage-configurations/configurationbot.svg',
          },
          {
            name: labels?.Chatbot_Dashboard,
            url: 'admin/manageconfiguration/configureBot/chatbot-dashboard',
            imageUrl: '../../../../assets/img/admin-icons/manage-configurations/configurationbot.svg',
          },
          {
            name: 'Signup Configuration',
            url: 'admin/manageconfiguration/signupConfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/signupconfiguration.svg',
          },
          {
            name: 'Login Configuration',
            url: 'admin/manageconfiguration/loginGSIConfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/signupconfiguration.svg',
          },
          {
            name: labels?.Logout_Configuration,
            url: 'admin/manageconfiguration/logoutConfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/locationconfiguration.svg',
          },
          {
            name: 'Guest User Configuration',
            url: 'admin/manageconfiguration/createguestuser',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/landingpageconfiguration.svg',
          },
          {
            name: 'OTP Configuration',
            url: 'admin/manageconfiguration/otpconfiguration',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/landingpageconfiguration.svg',
          },
          {
            name: labels?.Transaction_History_Configuration,
            url: 'admin/manageconfiguration/transactionHistoryConfiguration',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/landingpageconfiguration.svg',
          },
          {
            name: 'Location Configuration',
            url: 'admin/manageconfiguration/locationConfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/locationconfiguration.svg',
          },
          {
            name: 'Face Recognition Configuration',
            url: 'admin/manageconfiguration/facerecognitionconfiguration',
            imageUrl: '../../../../assets/img/admin-icons/manage-configurations/signupconfigurationLanding.svg',
          },
          {
            name: 'Hot/Cold/Warm Configuration',
            url: 'admin/manageconfiguration/hotconfiguration',
            imageUrl: '../../../../assets/img/admin-icons/manage-configurations/hotconfigLanding.svg',
          },

          {
            name: 'Custom Login Configuration',
            url: 'admin/manageconfiguration/loginConfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/locationconfiguration.svg',
          },

          {
            name: 'Campaign Configuration',
            url: 'admin/manageconfiguration/campaignConfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/locationconfiguration.svg',
          },
          {
            name: 'OTP policy',
            url: 'admin/manageconfiguration/otpaccountlockconfig',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-password-policy/passwordlimitpolicy.svg',
          },
          {
            name: 'Users Configuration',
            url: 'admin/manageconfiguration/usersconfiguration',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/signupconfigurationLanding.svg',
          },
          {
            name: labels?.HOT_Configuration,
            url: 'admin/manageconfiguration/hotconfiguration',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/hotconfigLanding.svg',
          },
          {
            name: labels?.Face_Recognition,
            url: 'admin/manageconfiguration/facerecognitionconfiguration',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-configurations/signupconfigurationLanding.svg',
          },
          {
            name: 'Terms And Conditions Configuration',
            url: 'admin/manageconfiguration/termsAndConditions',
          },
        ],
      },
      {
        type: 'Authentication_Policy',
        solutions: [
          {
            name: labels.Authentication_Policy,
            url: 'admin/authenticationpolicy',
            imageUrl:
              '../../../../assets/img/admin-icons/authentication-policy/authpolicy.svg',
          },
          {
            name: 'refresh token',
            url: 'admin/refreshToken',
          },
          {
            name: labels?.Multi_Session_Policy,
            url: 'admin/multi-session-policy',
          },
        ],
      },
      {
        type: 'irdr_book',
        solutions: [
          {
            type: 'manage_irdr',
            local: [
              {
                name: labels.View_Local_Role,
                url: 'admin/viewLocalRole',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-roles/view-role.svg',
              },
              {
                name: labels.Create_Local_Role,
                url: 'admin/createLocalRole',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-roles/add-role.svg',
              },
              {
                name: labels.Update_Local_Role,
                url: 'admin/updateLocalRole',
                imageUrl:
                  '../../../../assets/img/admin-icons/manage-roles/update-role.svg',
              },
            ],
          },
        ],
      },
      {
        type: 'manage_password',
        solutions: [
          {
            name: labels.Password_Reset_Policy,
            url: 'admin/passwordResetPolicy',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-password-policy/passwordresetpolicy.svg',
          },
          {
            name: labels.Password_Limit_Policy,
            url: 'admin/limitcount',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-password-policy/passwordlimitpolicy.svg',
          },
          {
            name: 'Reset/Reset All Locked User(s)',
            url: 'admin/resetLockedUsers',
            imageUrl: '../../../../assets/img/admin-icons/manage-password-policy/passwordlimitpolicy.svg',
          },
        ],
      },
      {
        type: 'permission_sets',
        solutions: [
          {
            name: 'Add Permission Set',
            url: 'admin/permissionsets/addpermissionset',
            imageUrl:
              '../../../../assets/img/admin-icons/permission-sets/addpermissionsets.svg',
          },
          {
            name: 'View Permission Set',
            url: 'admin/permissionsets/viewpermissionset',
            imageUrl:
              '../../../../assets/img/admin-icons/permission-sets/viewpermissionsets.svg',
          },
        ],
      },
      {
        type: 'key_management',
        solutions: [
          {
            name: 'Add Key',
            url: 'admin/keymanagement/addkeymanagement',
            imageUrl:
              '../../../../assets/img/admin-icons/key-management/addkey.svg',
          },
          {
            name: 'View Key',
            url: 'admin/keymanagement/viewkeymanagement',
            imageUrl:
              '../../../../assets/img/admin-icons/key-management/viewkey.svg',
          },
        ],
      },
      {
        type: 'Tenant_Setup_and_Customisation',
        solutions: [
          {
            name: 'Tenant Setup and Customisation',
            url: 'admin/tenantsetupandcustomisation',
            imageUrl:
              '../../../../assets/img/admin-icons/tenant-setup-customisation/tenantsetupcustomisation.svg',
          },
        ],
      },
      {
        type: 'book_ownership',
        solutions: [
          {
            name: labels.Book_Ownership,
            url: 'admin/bookownership',
            imageUrl:
              '../../../../assets/img/admin-icons/book-ownership/bookownership.svg',
          },
        ],
      },
      {
        type: 'tenant_irdr',
        solutions: [
          {
            name: labels?.IR_DR,
            url: 'admin/tenantIrdr',
            imageUrl: '../../../../assets/img/admin-icons/ir-dr/irdr.svg',
          },
        ],
      },
      {
        type: 'manage_sub_tenant',
        solutions: [
          {
            name: labels?.Sub_Tenants,
            url: 'admin/manage-sub-tenant',
          },
          {
            name: labels?.Manage_Book_Sharing,
            url: 'admin/manage-book-sharing',
          },
          {
            name: labels?.Map_New_Roles,
            url: 'admin/map-new-roles',
          },
        ],
      },
      {
        type: 'Manage_Personal_Connections',
        solutions: [
          {
            name: labels?.Create_Connection,
            url: 'admin/personal_connection/create',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-integrations/Add Connections.svg',
          },
          {
            name: labels?.View_Connection,
            url: 'admin/personal_connection/view',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-integrations/View Connections.svg',
          },
        ],
      },
      {
        type: 'manage_superset',
        solutions: [
          {
            name: labels?.Add_Superset_Dashboard,
            url: 'admin/superset_dashboard/create',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-integrations/Add Connections.svg',
          },
          {
            name: labels?.View_Superset_Dashboard,
            url: 'admin/superset_dashboard/view',
            imageUrl:
              '../../../../assets/img/admin-icons/manage-integrations/View Connections.svg',
          },
          {
            name: labels?.Row_level_Security,
            url: 'admin/superset_dashboard/row-level-security',
            imageUrl:
              '../../../../assets/img/admin-icons/authentication-policy/authpolicy.svg',
          },
          {
            name: labels?.Alert,
            url: 'admin/superset_dashboard/alert',
            imageUrl:
              '../../../../assets/img/admin-icons/authentication-policy/authpolicy.svg',
          },
          {
            name: labels?.Schedule_Report,
            url: 'admin/superset_dashboard/schedule-report',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/generatereport.svg',
          },
        ],
      },
      {
        type: 'configure',
        solutions: [
          // configuration for book
          {
            name: labels?.View_Local_Role_For_Book,
            label: 'viewLocalRole',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/viewlocalrolesforbook.svg',
            type: 'book',
          },
          {
            name: labels?.IR_DR,
            label: 'irdr',
            imageUrl: '../../../../assets/img/admin-icons/configure/irdr.svg',
            type: 'book',
          },

          // configuration for gsi
          {
            name: labels?.Generate_Report,
            label: 'generateReport',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/generatereport.svg',
            type: 'gsi',
          },
          {
            name: labels?.ManagePushbacks,
            label: 'managePushbacks',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/managepushbacks.svg',
            type: 'gsi',
          },
          {
            name: labels?.Schedule_Jobs,
            label: 'scheduleJobs',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/schedulejobs.svg',
            type: 'gsi',
          },
          // for book and gsi
          {
            name: labels?.Publish_To_BlockChain,
            label: 'publishToBlockchain',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/publishtoblockchain.svg',
            type: 'book',
          },
          {
            name: labels?.Conditional_Show_Hide,
            label: 'conditionalShowHide',
            imageUrl:
              '../../../../assets/img/admin-icons/configure/conditionalshowhide.svg',
            type: 'book',
          },
        ],
      },
      {
        type: 'Blockchain_Management_Key',
        solutions: [
          {
            name: 'Key Management',
            url: 'admin/accessKeys',
            imageUrl:
              '../../../../assets/img/admin-icons/environment-admin/blockchain-channel-keys.svg',
          },
          {
            name: 'Encryption Configuration',
            url: 'admin/configurationKeys',
            imageUrl:
              '../../../../assets/img/admin-icons/environment-admin/blockchain-channel-keys.svg',
          },
          {
            name: 'Channel Async Status',
            url: 'admin/channelasyncstatus',
            imageUrl:
              '../../../../assets/img/admin-icons/environment-admin/blockchain-channel-async-status.svg',
          },
        ],
      },
      {
        type: 'Manage_DCEO',
        solutions: [
          {
            name: 'NH Roles Configuration',
            url: 'admin/dceo-roles',
            imageUrl: '../../../../assets/images/DCEO/nh_soln_config.svg',
          },
          {
            name: 'DCEO Color Configuration',
            url: 'admin/dceo-color-config',
            imageUrl: '../../../../assets/images/DCEO/nh_history_config.svg',
          },
          {
            name: 'Chat Feedback Configuration',
            url: 'admin/dceo-feedback-config',
            imageUrl: '../../../../assets/images/DCEO/nh_history_config.svg',
          },
        ],
      },
      {
        type: 'Manage_Notifications',
        solutions: [
          {
            name: labels?.Schedule_Jobs,
            url: 'admin/manageNotifications/scheduleJobs',
            imageUrl:
              '../../../../assets/img/admin-icons/tenant-admin/manage-notifications.svg',
          },
        ],
      },
      {
        type: 'tenant_Language_Management',
        solutions: [
          {
            name: labels?.Manage_Languages,
            url: 'admin/manageLanguages',
            imageUrl:
              '../../../../assets/img/admin-icons/gsi-blocking/addconfig.svg',
          },
        ],
      },
    ];
    let solutions: any = [];
    isCarnvial = isCarnvial ? isCarnvial : false;
    const environmentType = localStorage.getItem('environmentType');
    const envType = localStorage.getItem('envType');
    /* istanbul ignore next */
    const env = environmentType ? environmentType : envType;
    /* istanbul ignore next */
    if (
      activeUserFlag == 'Environment_Admin' &&
      (activeUserFlag == 'Environment_Admin' || env !== 'development') &&
      !isCarnvial
    ) {
      this.adminSolutions.forEach((sol: any) => {
        if (sol.type == 'manage_users') {
          for (let i = 0; i < sol.solutions?.length; i++)
            if (
              sol.solutions[i].url === 'adduser' ||
              sol.solutions[i].url === 'deleteuser' ||
              sol.solutions[i].url === 'importuser'
            ) {
              sol.solutions.splice(i, 1);
              i--;
            }
        }
      });
    }
    if (
      activeUserFlag == 'Environment_Admin' &&
      env !== 'development' &&
      !isCarnvial
    ) {
      this.adminSolutions.forEach((sol: any) => {
        if (sol.type == 'manage_roles') {
          for (let i = 0; i < sol.solutions?.length; i++)
            if (
              sol.solutions[i].url === 'addrole' ||
              sol.solutions[i].url === 'deleterole' ||
              sol.solutions[i].url === 'importrole'
            ) {
              sol.solutions.splice(i, 1);
              i--;
            }
        }
      });
    }
    if (activeUserFlag == 'Environment_Admin') {
      this.adminSolutions.forEach((sol: any) => {
        if (sol.type == 'manage_users') {
          for (let i = 0; i < sol.solutions.length; i++)
            if (sol.solutions[i].url?.includes('bulkdeleteusers')) {
              sol.solutions.splice(i, 1);
              i--;
            }
        }
      });
    }
    /* istanbul ignore next */
    this.adminSolutions?.forEach((adminSolution: any) => {
      /* istanbul ignore next */
      if (type === 'manage_solutions' && solutions?.length == 0) {
        if (type === 'manage_solutions' && activeUserFlag === 'user') {
          /* istanbul ignore next */
          solutions = adminSolution.solutions.filter(
            (x: any) => x?.isUser === true && x
          );
        } else {
          /* istanbul ignore next */
          solutions = adminSolution.solutions.filter(
            (x: any) => x?.isUser === false && x
          );
        }
      } else if (adminSolution.type === type && solutions?.length == 0) {
        /* istanbul ignore next */
        solutions = adminSolution.solutions;
      }
    });
    return of(solutions);
  }

  screenIndex(position: any) {
    this.stepIndex.next(position);
  }
  saveDraftCu(cu: any, cuSave?: boolean) {
    if (cu && cu.name) {
      this.loader.show();
      const cuInfo = changeUnitDeMapper(cu);
      this.gsiEndPointService
        .saveDraftCu(cuInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) =>
            /* istanbul ignore next */
            {
              this.loader.hide();
              const data = changeUnitMapper(res?.result);
              data.cuDraftSave = cuSave ? cuSave : false;
              data.tCUConditionalPotentiality = cu.tCUConditionalPotentiality;
              data.txnDataSaveMode = cu?.txnDataSaveMode;
              data.oldVersionId = cu.oldVersionId;
              data.tCUConditionalPotentialityNames =
                cu.tCUConditionalPotentialityNames;
              data.constraint = cu?.constraint;
              data.constraintName = cu?.constraintName;
              /* istanbul ignore next */
              data.slotItemProperties = cu?.slotItemProperties;
              /* istanbul ignore next */
              data.isOcr = cu?.isOcr;
              /* istanbul ignore next */
              (data.disableSTEs = cu?.disableSTEs),
                (data.layerType = cu?.layerType);
              if (cu?.eventCUList) {
                data.eventCUList = cu?.eventCUList;
              }
              data.transactionEndpoint = cu?.transactionEndpoint;
              data.isTemplate = cu?.isTemplate;
              data.triggerCUDialogList = cu?.triggerCUDialogList;
              this.draftedCuData.next(data);
            },
          /* istanbul ignore next */
          (error) => {}
        );
    }
  }
  saveCompositeGsi(gsiData: any, isGsiNew?: boolean) {
    this.loader.show();
    this.gsiEndPointService
      .saveCompositeGsi(gsiDeMapper(gsiData))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.updateGSIObject({ ...gsiMapper(res?.result), isEdit: isGsiNew });
        this.loader.hide();
      });
  }

  publishEditGsi(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.gsiEndPointService
      .publishEditGsi(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next */
        if (
          res &&
          res.content &&
          res.content?.length &&
          res?.content[0] === 'no record found'
        ) {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.publishEditgsi.next(res);
      });
  }

  getPublishedGsi(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.gsiEndPointService
      .publishEditGsi(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next */
        if (
          res &&
          res.content &&
          res.content.length &&
          res?.content[0] === 'no record found'
        ) {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.publishEditgsi.next(res);
        this.publsihedGsi.next(res);
      });
  }

  publishgsibyid(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getpublishGsiById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /*istanbul ignore next */
          if (
            res &&
            res.content &&
            res?.content?.length &&
            res?.content[0] === 'no record found'
          ) {
            this.alertService.showToaster(res?.content[0], '', 'error');
          }
          this.publishGsiById.next(res);
        },
        (error) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }

  getEntityById(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getEntityById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next */
        if (
          res &&
          res.content &&
          res?.content?.length &&
          res?.content[0] === 'no record found'
        ) {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.entityIdById.next(res);
      });
  }
  /* mat menu close on mouse over nad lease on button and menu */
  menuenter() {
    this.isMatMenuOpen = true;
  }

  menuLeave(trigger: any) {
    if (!this.enteredButton) {
      this.isMatMenuOpen = false;
      trigger.closeMenu();
    }
  }
  /*istanbul ignore next */
  menuButtonEnter(trigger: any) {
    if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
      this.prevButtonTrigger?.closeMenu();
      this.prevButtonTrigger = trigger;
      this.isMatMenuOpen = false;
      /*istanbul ignore next */
      trigger?.openMenu();
    } else if (!this.isMatMenuOpen) {
      this.enteredButton = true;
      this.prevButtonTrigger = trigger;
      /*istanbul ignore next */
      trigger?.openMenu();
    } else {
      this.enteredButton = true;
      this.prevButtonTrigger = trigger;
    }
  }
  /*istanbul ignore next */
  menuButtonLeave(trigger: any) {
    if (this.enteredButton && !this.isMatMenuOpen) {
      trigger.closeMenu();
    }
    if (!this.isMatMenuOpen) {
      trigger.closeMenu();
    } else {
      this.enteredButton = false;
    }
  }

  exportGsiToBpmnDiagram(gsi: any) {
    this.loader.show();
    this.gsiEndPointService
      .convertGsiToJsonToXml(gsi.gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.bpmnDiagramData$.next(res);
        this.router.navigate(['newsolution/bpmn-view'], {
          queryParams: { gsiId: gsi.gsiId },
        });
        this.loader.hide();
      });
  }

  resetSolution() {
    this.draftGSISaved.next('');
  }

  getAllEntitiesForRole(pagenum: any, pageSize: any, searchString: string) {
    this.loader.show();
    return this.gsiEndPointService
      .getAllEntities(pagenum, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEntityList.next(res.result);
      });
  }

  getAllEntiteWithRole(role: any, pagenum: any, pageSize: any) {
    return this.gsiEndPointService
      .getAllEntitesWithRole(role, pagenum, pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEntityListWithRole.next(res);
      });
  }

  getEntityCardDetails(id: any) {
    if (id) {
      return this.gsiEndPointService.getEntityCardDetails(id);
    }
  }
  getEntityDataDetails(req: any, pageNum: any, pageSize: any, v?: string) {
    let version : string = v? v : 'v1';
    return this.gsiEndPointService.getEntityDataDetails(req, pageNum, pageSize, version);
  }
  getSearchRecords(req: any) {
    return this.gsiEndPointService.getSearchRecords(req);
  }

  getAllGsisForDashboard(
    pagenum: any,
    pageSize: any,
    searchCriteria: string,
    isPublished: boolean | any,
    isTXNRights: boolean,
    isDesignMode?: boolean,
    selectedWeek?: number
  ) {
    if (selectedWeek)
      return this.gsiEndPointService
        .getAllGsis(
          pagenum + 1,
          pageSize,
          searchCriteria,
          isPublished,
          isTXNRights,
          isDesignMode,
          selectedWeek
        )
        .pipe(
          map((response: any) => {
            return gsiListMapper(response.result);
          })
        );
    else
      return this.gsiEndPointService
        .getAllGsis(
          pagenum + 1,
          pageSize,
          searchCriteria,
          isPublished,
          isTXNRights,
          isDesignMode
        )
        .pipe(
          map((response: any) => {
            /* istanbul ignore next */
            return gsiListMapper(response.result);
          })
        );
  }

  getAllBookStatus(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    approvalStatuses: any
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getAllBookStatus(pagenum, pageSize, searchCriteria, approvalStatuses)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getBookList.next(res);
      });
  }

  getGsiNameId(gsi: any) {
    this.gsiNameIdSubject.next(gsi);
  }

  openSelectedCu(res: any) {
    this.openSelectedCU.next(res);
  }

  sendCuDataToLibray(res: any) {
    this.selectedCuForLibrary.next(res);
  }
  importHistory(searchText: any, pageSize: number, pageNumber: number) {
    return this.gsiEndPointService.importHistory(
      searchText,
      pageSize,
      pageNumber
    );
  }
  viewExportSolution(Id: any) {
    return this.gsiEndPointService.viewExportSolution(Id);
  }

  updateCUListByHoverComponentChanges(cuList: any) {
    this.updatedCUListFromHoverComponent.next(cuList);
  }

  getGSIDetailsById(id: any) {
    return this.gsiEndPointService.getGSIDetailsById(id);
  }

  getTracableGSIDetailsById(id: any) {
    return this.gsiEndPointService.getTracableGSIDetailsById(id);
  }

  getCuDetailsById(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .getCuDetailsById(data.referencedChangeUnit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getCuDetailsByid.next(res);
      });
  }

  setCurrentCuEventsData(data: any) {
    this.currentCuEventsData = data;
  }

  getCurrentCuEventsData() {
    return this.currentCuEventsData;
  }

  getSelectCU(data: any) {
    this.getSelectCUData.next(data);
  }

  // save current layer index
  saveCurrentLayerIndex(index: any) {
    this.currentLayerIndexSubject.next(index);
  }

  /** Method to Subscribe the Stream to call Draft GSI in CU Component */
  draftGSIinCU() {
    this.saveDraftGSIinCU.next();
  }

  saveCcdInGSI(ccd: any) {
    this.saveCcd.next(ccd);
  }
  saveCcdInGSIEQLGrammar(ccd: any) {
    this.saveCcdEQLGrammar.next(ccd);
  }
  showMessageIntoaster(message: string, title: string, typeOfAlert: any) {
    this.alertService.showToaster(message, title, typeOfAlert);
  }
  updateAlternateCu(cu: any) {
    this.alternateCu.next(cu);
  }

  toggleParagraphView() {
    this.toggleParagraph.next('');
  }

  // save gsi as draft
  saveGsiAsDraft(data: any) {
    this.saveGsiAsDraftSubject.next(data);
  }
  //To Fetch exception category List
  getExceptionCategory() {
    this.loader.show();
    this.gsiEndPointService
      .getExceptionCategory()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getExceptionCategoryList.next(res);
      });
  }
  //UT of special-features-query
  getTempGsi(tempgsi: any) {
    this.tempGsiObj.next(tempgsi);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAllGsisForTransactionVIew(
    pagenum: any,
    pageSize: any,
    searchCriteria: string,
    isPublished: boolean | any,
    isTXNRights: boolean
  ) {
    return this.gsiEndPointService
      .getAllGsis(
        pagenum + 1,
        pageSize,
        searchCriteria,
        isPublished,
        isTXNRights
      )
      .pipe(
        map((response: any) => {
          return response.result;
        })
      );
  }

  /* -------------------------- Pushback - FE757 - START -------------------------- */

  // Get all pushbacks for GSI
  fetchPushbacks(data: any) {
    return this.gsiEndPointService.getPushbacks(data);
  }

  // Get all target CUs for any particular CU
  fetchTargetCUsForPushback(data: any) {
    return this.gsiEndPointService.getTargetCUsForPushbacks(data);
  }

  // Save/Update pushback
  savePushback(data: any) {
    return this.gsiEndPointService.postPushback(data);
  }

  // Delete Pushback
  removePushback(data: any) {
    return this.gsiEndPointService.deletePushback(data);
  }

  // Get CUs list for transaction side
  fetchCUsInTransForPushback(data: any) {
    return this.gsiEndPointService.getCUsInTransForPushback(data);
  }

  /* -------------------------- Pushback - FE757 - END-------------------------- */

  //save subtransaction gsi
  /* istanbul ignore next */
  saveSubtransactionalGsi(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveSubtransactionalGSI(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res) {
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (error) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }

  subtransactionalDCDSave(data: any) {
    this.subtransactionalDCD.next(data);
  }
  getAllscheduledjobs(gsiId: any, gsiMaterId: any, gsiVersion: any) {
    this.loader.show();
    this.gsiEndPointService
      .getAllscheduledjobs(gsiId, gsiMaterId, gsiVersion)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res) {
            this.scheduledjobsData.next(res);
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (error) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }
  saveSchedulerJob(ScheduleData: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveSchedulerJob(ScheduleData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res) {
            this.savedScheduledjobData.next(res);
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (error: any) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }
  // Delete Pushback
  removeSchedulerJob(gsiId: any, jobId: any) {
    this.loader.show();
    this.gsiEndPointService
      .deleteSchedulerJob(gsiId, jobId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.deleteScheduleJob.next(res);
      });
  }
  getAllScheduleJobsData(data: any) {
    this.scheduledjobsData.next(data);
  }
  deleteScheduleJobFromData(data: any) {
    this.deleteScheduleJob.next(data);
  }
  saveScheduleJobtoData(data: any) {
    this.savedScheduledjobData.next(data);
  }
  rearrangeEntity(
    cuId: any,
    layerType: any,
    currentSlotIndex: any,
    newSlotIndex: any,
    gsiId: any,
    Data: any
  ) {
    this.gsiEndPointService
      .rearrangeEntity(
        cuId,
        layerType,
        currentSlotIndex,
        newSlotIndex,
        gsiId,
        Data
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const gsiRes = gsiMapper(res?.result);
        this.rearrangeEntityData.next(gsiRes);
      });
  }

  validateFile(postData: any) {
    this.loader.show();
    this.uploadEndpointService
      .singleFileUpload(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.status !== 417) {
          this.fileValidation.next(res);
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }
  getVersionList(id: any) {
    return this.gsiEndPointService.getVersionList(id);
  }
  importSolutionWithFile(data: any) {
    this.importSolutionFile.next(data);
  }

  validateGSIName(tenantId: any, gsiName: string) {
    this.loader.show();
    /* istanbul ignore next*/
    this.gsiEndPointService
      .validateGSIName(tenantId, gsiName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res) {
            this.alertService.showToaster('GSI already exists', '', 'error');
          }
        },
        (error: any) => {
          this.validatedGSIName.next(true);
        }
      );
  }
  fetchSourceCuList(gsiId: any) {
    return this.gsiEndPointService.getSourceCuList(gsiId);
  }

  deleteGsiDraft(gsiId: any) {
    return this.gsiEndPointService.deleteGsiDraft(gsiId);
  }

  importGsiFromNslLibrary(data: any, dsdId: any) {
    this.gsiEndPointService
      .importGsiFromNslLibrary(data, dsdId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        console.log(res);
      });
  }

  loadUIControls(cuId: any) {
    this.loader.hide();
    this.gsiEndPointService
      .loadUIControls(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.changeUnitUIControl.next(res);
      });
  }
  getGsiIdFromMasterId(masterId: number) {
    return this.gsiEndPointService.getGsiIdFromMasterId(masterId);
  }
  getEventAllGsis(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean,
    isTXNRights: boolean
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(
        pagenum + 1,
        pageSize,
        searchCriteria,
        isPublished,
        isTXNRights
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.eventAllgsi.next(gsiList);
      });
  }
  getSearchedEntityList(pagenum: any, pageSize: any, searchString: string) {
    return this.gsiEndPointService.getAllEntities(
      pagenum,
      pageSize,
      searchString
    );
  }
  getTranslationGsiById(gsiId: any) {
    this.loader.show();
    this.gsiEndPointService
      .getTranslationGsiById(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.importExportTranslationSubject.next(res);
      });
  }
  importTranslation(file: any, ischecked: boolean) {
    return this.gsiEndPointService.importTranslation(file, ischecked);
  }

  sendCloseData(data: string) {
    this.closeDialog.next(data);
  }

  setTotalErrorsCount(count: number) {
    this.totalErrorsCount.next(count);
  }
  addToCart(data: any): Observable<any> {
    return this.gsiEndPointService.addToCart(data);
  }
  viewCart(): Observable<any> {
    return this.gsiEndPointService.viewCart();
  }

  getCartConfiguration(): Observable<any> {
    return this.gsiEndPointService.getCartConfiguration();
  }
  updateCartQuantity(id: string, isReduce: boolean): Observable<any> {
    return this.gsiEndPointService.updateCartQuantity(id, isReduce);
  }
  deleteCartItem(id: number) {
    return this.gsiEndPointService.deleteCartItem(id);
  }

  getSourceAndTargetEnv(data: any, selectEnv: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.gsiEndPointService
      .getSourceAndTargetEnv(data, selectEnv)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.getSourceAndTargetEnvSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  dtrtPromoteCustomization(data: any, selectEnv: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.gsiEndPointService
      .dtrtPromoteCustomization(data, selectEnv)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.dtrtPromoteCustomizationSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  updateDtrtData(data: any) {
    this.dtrtPromotionData.next(data);
  }
  setReviewFinishIconVariable(
    review: boolean,
    finish: boolean,
    showPublishNotB2c: boolean,
    showPublishB2c: boolean,
    debug: boolean,
    subTransactional: boolean
  ) {
    this.showReview = review;
    this.showFinish = finish;
    this.showPublishNotB2c = showPublishNotB2c;
    this.showPublishB2c = showPublishB2c;
    this.showDebug = debug;
    this.showSubTransactional = subTransactional;
  }
  sendGsiButtonClickData(data: any) {
    this.gsiButtonClickData.next(data);
  }

  getExludedLanguageForFat2Flat() {
    this.gsiEndPointService
      .getExludedLanguageForFat2Flat()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.excludedLanguageForFat2Flat.next(res);
        },
        (error: any) => {
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  skipWarnings(betName: any) {
    this.skipWarningMsgs.next(betName);
  }

  updatePromoteEnv(env: any) {
    this.envData.next(env);
  }
  getAllReservedCUs(id: any) {
    return this.gsiEndPointService.getAllReservedCUs(id);
  }
  saveRuleEditor(data: any) {
    return this.gsiEndPointService.saveRuleEditor(data);
  }
  fetchRules() {
    return this.gsiEndPointService.fetchRules();
  }
  updateRule(data: any, key: any, ruleId: any) {
    return this.gsiEndPointService.updateRule(data, key, ruleId);
  }
  fetchRule(id: any) {
    return this.gsiEndPointService.fetchRule(id);
  }
  testRules(ruleKey: any) {
    return this.gsiEndPointService.testRules(ruleKey);
  }
  deployRules(ruleKey: any) {
    return this.gsiEndPointService.deployRules(ruleKey);
  }
  applyRules(data: any) {
    return this.gsiEndPointService.applyRules(data);
  }
  getCommits(ruleKey: any) {
    return this.gsiEndPointService.getCommits(ruleKey);
  }
  viewSelectedVersion(ruleKey: any, version: number) {
    return this.gsiEndPointService.viewSelectedVersion(ruleKey, version);
  }
  versionPagination(pageIndex: number, pageSize: number, ruleKey: any) {
    return this.gsiEndPointService.versionPagination(pageIndex, pageSize, ruleKey);
  }
  revertVersion(version: number, ruleId: any, formData: any) {
    return this.gsiEndPointService.revertVersion(version, ruleId, formData);
  }
  ruleOperatorsAPI() {
    return this.gsiEndPointService.ruleOperatorsAPI();
  }

  validateVantagePoints(param: any) {
    return this.gsiEndPointService
      .validateVantagePoints(param)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.alertService.showToaster(res?.message, '', 'success');
          this.validateVantagePoint.next(res);
        },
        (error: any) => {
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

}

