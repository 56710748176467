import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { CduiBaseService } from '../../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class CustomLoginEndpointService extends CduiBaseService {
  private templateChange = new Subject();
  templateChangeObservable = this.templateChange.asObservable();
  private loginDesign = new Subject();
  loginDesignObservable = this.loginDesign.asObservable();

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  changeingTemplate(templateName: string) {
    this.templateChange.next(templateName);
  }

  saveLoginDesign(loginDesign: LoginDesign) {
    return this.http.post(`${this.api.saveLoginDesign}`, loginDesign);
  }

  fetchLoginDesign() {
    let tenantName = localStorage.getItem('envClientId');
    return this.http.get(`${this.api.fetchLoginDesign}/${tenantName}`);
  }

  updateLoginDesign(loginDesign: LoginDesign) {
    this.loginDesign.next(loginDesign);
  }

  uploadFile(files: any) {
    return this.http.post(this.api?.singleFileUpload, files);
  }

  deleteDesign() {
    let tenantName = localStorage.getItem('envClientId');
    return this.http.delete(`${this.api.deleteLoginDesign}/${tenantName}`);
  }
}

export interface LoginDesign {
  backGround?: BackGround;
  caption?: Caption;
  headingText?: HeadingText;
  asset?: ImageLinks;
  templateType?: string;
  tenantName?: string;
  chip?: Chip;
  actionButton?: ActionButton;
  roleDesign?: RoleDesign[];
}

export interface BackGround {
  color?: string;
  link?: ImageLinks;
}

export interface Caption {
  heading?: string;
  text?: string;
  font?: string;
  headingFontColor?: string;
  textFontColor?: string;
}

export interface HeadingText {
  text?: string;
  font?: string;
  fontColor?: string;
}

export interface Chip {
  link?: ImageLinks;
  color?: string;
}

export interface ImageLinks {
  desktop?: string;
  mobile?: string;
  tab?: string;
}

export interface ActionButton {
  text?: string;
  fontColor?: string;
  buttonColor?: string;
}

export interface RoleDesign {
  roleName: string;
  icon?: BackGround;
  caption?: Caption;
  headingText?: HeadingText;
}
