import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dcdSource',
  pure: true,
})
export class DcdSourcePipe implements PipeTransform {
  //this pipe separates a contextual id with sach for dcd label display.
  transform(item: any): string {
    let splitItem: any;
    let output = '';

    if (item) {
      splitItem = item.split('.')[0];
      if (splitItem == 'PL') {
        output = 'physical/';
      } else if (splitItem == 'IL') {
        output = 'information/';
      } else if (splitItem == 'triggerCES') {
        output = 'triggerCES/';
      }
    }
    return output;
  }
}
