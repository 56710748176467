import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../materail/material.module';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';

import { SharedErrorHandlerComponent } from './shared-error-handler.component';


@NgModule({
  declarations: [SharedErrorHandlerComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, ApplicationPipesModule],
  exports: [SharedErrorHandlerComponent],
  providers: [],
})
export class SharedErrorHandlerModule {}
