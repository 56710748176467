import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpecialFeaturesAdminDashboardEndpointService } from './special-features-admin-dashboard-endpoint.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class SpecialFeaturesAdminDashboardFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  public allScheduledJobsSubject = new Subject();
  allScheduledJobs$ = this.allScheduledJobsSubject.asObservable();

  public allJobHistorySubject = new Subject();
  allJobHistory$ = this.allJobHistorySubject.asObservable();

  private allspecialfeature = new BehaviorSubject('');
  allspecialfeature$ = this.allspecialfeature.asObservable();

  private alluniquestatus = new Subject();
  alluniquestatus$ = this.alluniquestatus.asObservable();

  public viewAllScheduledJobsSubject = new Subject();
  viewAllScheduledJobsSubject$ = this.viewAllScheduledJobsSubject.asObservable();

  constructor(
    private SpecialFeaturesAdminDashboardEndpointService: SpecialFeaturesAdminDashboardEndpointService,
    private loader: LoaderService,
    private alertService: AlertService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getScheduledJobs(request: any) {
    this.loader.show();
    this.SpecialFeaturesAdminDashboardEndpointService.getScheduledJobs(request)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allScheduledJobsSubject.next(res);
      });
  }
  viewScheduledJobs(request: any) {
    this.loader.show();
    this.SpecialFeaturesAdminDashboardEndpointService.viewScheduledJobs(request)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.viewAllScheduledJobsSubject.next(res);
      });
  }
  getJobHistory(request: any) {
    this.loader.show();
    this.SpecialFeaturesAdminDashboardEndpointService.getJobHistory(request)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allJobHistorySubject.next(res);
      });
  }
  rerunData(data: any) {
    this.loader.show();
    this.SpecialFeaturesAdminDashboardEndpointService.rerunData(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        //
      });

    this.loader.hide();
    this.alertService.showToaster('Re-run Successfully', '', 'success');
  }
  getallspecialfeature() {
    this.loader.show();
    this.SpecialFeaturesAdminDashboardEndpointService.getallspecialfeature()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allspecialfeature.next(res);
      });
  }
  getalluniquestatus() {
    this.loader.show();
    this.SpecialFeaturesAdminDashboardEndpointService.getalluniquestatus()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alluniquestatus.next(res);
      });
  }
  jobSuspension(element: any) {
    return this.SpecialFeaturesAdminDashboardEndpointService.jobSuspension(element);
  }
  jobResume(element: any) {
    return this.SpecialFeaturesAdminDashboardEndpointService.jobResume(element);
  }
  jobDelete(element: any) {
    return this.SpecialFeaturesAdminDashboardEndpointService.jobDelete(element);
  }
}
