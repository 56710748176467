let transform = require('node-json-transform').transform;

export function advSearchAdminConfigMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      myLibrary: 'myLibrary',
      nslLibrary: 'nslLibrary',
      tenantId: 'tenantId',
    },

    operate: [
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'myLibrary',
      },
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'nslLibrary',
      },
    ],
  };

  const nestedMap = {
    item: {
      ALL: 'ALL',
      BOOK: 'BOOK',
      CU: 'CU',
      GE: 'GE',
      GSI: 'GSI',
      RECORDS: 'RECORDS',
    },
    operate: [
      {
        run: function (val: any) {
          return {
            displayName: val?.displayName,
            tempDisplayName: val?.displayName,
            enabled: val?.enabled,
            isEdit: false,
          };
        },
        on: 'ALL',
      },
      {
        run: function (val: any) {
          return {
            displayName: val?.displayName,
            tempDisplayName: val?.displayName,
            enabled: val?.enabled,
            isEdit: false,
          };
        },
        on: 'BOOK',
      },
      {
        run: function (val: any) {
          return {
            displayName: val?.displayName,
            tempDisplayName: val?.displayName,
            enabled: val?.enabled,
            isEdit: false,
          };
        },
        on: 'CU',
      },
      {
        run: function (val: any) {
          return {
            displayName: val?.displayName,
            tempDisplayName: val?.displayName,
            enabled: val?.enabled,
            isEdit: false,
          };
        },
        on: 'GE',
      },
      {
        run: function (val: any) {
          return {
            displayName: val?.displayName,
            tempDisplayName: val?.displayName,
            enabled: val?.enabled,
            isEdit: false,
          };
        },
        on: 'GSI',
      },
      {
        run: function (val: any) {
          return {
            displayName: val?.displayName,
            tempDisplayName: val?.displayName,
            enabled: val?.enabled,
            isEdit: false,
          };
        },
        on: 'RECORDS',
      },
    ],
    // each: function(val: any){
    //   return {
    //     displayName: val?.displayName,
    //     tempDisplayName: val?.displayName,
    //     enabled: val?.enabled,
    //     isEdit: false
    //   };
    // },
  };

  const result = transform(data, map);

  return result;
}