import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NodeGsiFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonDcdComponent } from '../common-dcd/common-dcd.component';

@Component({
  selector: 'app-connector-mapping-component',
  templateUrl: './connector-mapping-component.component.html',
  styleUrls: ['./connector-mapping-component.component.scss'],
})
export class ConnectorMappingComponentComponent implements OnInit {
  @Input() gsi: any = {};
  @Input() cu: any = {};
  ngUnsubscribe: any = new Subject();
  labels: any;
  sourceEntities: any = [];
  targetEntities: any = [];
  constructor(
    private nodeGsiFacade: NodeGsiFacadeService,
    public dialog: MatDialog,
    private translator: TranslatorService,
  ) {
    this.detectLanguageChange();
    this.getSourceEntities();
    this.getTargetEntities();
  }

  ngOnInit(): void {
    this.nodeGsiFacade.getMapperSourceEntities(this.gsi, this.cu);
    this.nodeGsiFacade.getMapperTargetEntities(this.gsi, this.cu);
    // this.nodeGsiFacade.getMappedConfiguration(this.cu);
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  //To get soource entities for Template RCU
  getSourceEntities() {
    this.nodeGsiFacade.mapperSourceEntities$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.sourceEntities = res;
      }
    });
  }

  //To get target entities for template RCU
  getTargetEntities() {
    this.nodeGsiFacade.mapperTargetEntities$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.targetEntities = res;
      }
    });
  }

  // getMappedConfiguration() {
  //   this.nodeGsiFacade.mappedConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
  //     if (res) {
  //       console.log(res);
  //     }
  //   });
  // }

  //To open Mapping screen
  openMapper() {
    let mappings = this.cu?.slotItemProperties?.PL_SL001?.propertyData?.CONSTANT?.changeDriverData?.DATA
      ?.transEntityRecords?.[0]?.txnNslAttribute?.[0]?.values;
    let dialogRef = this.dialog?.open(CommonDcdComponent, {
      width: '90%',
      height: '80%',
      data: {
        changeUnit: this.cu,
        gsiData: this.gsi,
        templateMapping: true,
        mapData: {
          sourceEntities: this.sourceEntities,
          targetEntities: this.targetEntities,
          map: mappings ? JSON.parse(mappings) : [],
        },
      },
    });
    /* istanbul ignore next */
    dialogRef?.afterClosed()?.subscribe((res) => {
      /* istanbul ignore next */
      // this.nodeGsiFacade.saveMappedConfiguration(this.cu, res);
      let entity = this.cu?.layers?.[0]?.participatingItems?.[0];
      let attr = entity?.nslAttributes?.[0];
      this.cu.slotItemProperties = {
        PL_SL001: {
          propertyData: {
            CONSTANT: {
              changeDriverData: {
                DATA: {
                  dsdID: entity?.id,
                  generalEntityID: entity?.id,
                  name: entity?.name,
                  transEntityRecords: [
                    {
                      txnNslAttribute: [
                        { name: attr?.name, nslAttributeID: attr?.id, dsdId: attr?.id, values: [JSON.stringify(res)] },
                      ],
                    },
                  ],
                },
                TYPE: 'TxnGeneralEntity',
              },
              cuLayer: 'physical',
              slotItemIndex: 0,
            },
          },
        },
      };
    });
  }
}
