<div class="livechat">
  <div
    class="chat-wrapper"
    *ngIf="isShown"
    id="divshow"
    [ngClass]="{ 'chat-wrapper-carnival': chatBotIcons.Robot === iconType }"
    cdkDrag
    [cdkDragDisabled]="!dragEnabled"
  >
    <div class="chat-contr chat-container">
      <div class="infowrapper" (click)="checkLanguageOptions()">
        <div class="infor-left">
          <p [ngStyle]="{ 'font-size': size + 2 + 'px' }">
            {{ botName }}
            <!-- <span  [ngStyle]="{'font-size': (size-2)+'px' }"> {{ labels?.Chatbotlabel }} </span> -->
          </p>
        </div>
        <div class="infor-right" [ngStyle]="{ 'font-size': size + 4 + 'px' }">
          <span class="material-icons">
            <mat-icon (click)="getHistory()">history</mat-icon>
          </span>
          <span class="material-icons" *ngIf="isAudio" (click)="enableAudio()">
            volume_up
          </span>

          <span class="material-icons" *ngIf="!isAudio" (click)="enableAudio()">
            volume_off
          </span>
          <span class="material-icons" (click)="isShown = false">
            remove
          </span>

          <span class="chatclose" (click)="endChat()">
            <mat-icon>close</mat-icon>
          </span>
        </div>
      </div>
      <div class="chat-sys" (click)="checkLanguageOptions()">
        <div class="message-chating" #scroll [scrollTop]="scroll.scrollHeight">
          <ng-container *ngFor="let data of previousChatData; let i = index">
            <div
              class="chat-type"
              *ngIf="data?.ans?.text !== '' && hideTranscriptConditions.indexOf(data?.ans?.text) == -1"
            >
              <div
                class="chatype-span"
                *ngIf="data?.user.includes('guest')"
                [ngStyle]="{ 'font-size': size + 1 + 'px' }"
              >
                <span> {{ data?.ans?.text }}</span>
              </div>
              <span class="boticon" *ngIf="data?.user.includes('guest')" [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                ><img src="../../../../../assets/images/boat/User-Icon.svg" alt="user" />
              </span>
            </div>
            <div
              class="chat-replay"
              *ngIf="
                data?.user === 'nslhub.bot' ||
                (!data?.user.includes('guest') &&
                  data?.user !== 'nslhub' &&
                  hideTranscriptConditions.indexOf(data?.ans?.text) == -1)
              "
              [ngClass]="{ 'chat-replay-entity-card': data?.media?.entity_card?.length > 0 }"
            >
              <span
                class="usricon"
                [ngStyle]="{ 'font-size': size + 2 + 'px' }"
                *ngIf="
                  data?.ans?.text !== '' ||
                  data?.media?.image?.length > 0 ||
                  data?.media?.video?.length > 0 ||
                  data?.media?.audio?.length > 0 ||
                  data?.media?.file?.length > 0 ||
                  data?.media?.entity_card?.length > 0
                "
              >
                <img [src]="botResponseIconUrl" width="30" alt="user" />
              </span>
              <div class="w-100">
                <div
                  #chathistorybotreply
                  class="suggestionh2"
                  *ngIf="
                    data?.ans?.text !== '' ||
                    data?.media?.image?.length > 0 ||
                    data?.media?.video?.length > 0 ||
                    data?.media?.audio?.length > 0 ||
                    data?.media?.file?.length > 0 ||
                    data?.media?.entity_card?.length > 0
                  "
                >
                  <h2
                    class="formatted"
                    [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                    *ngIf="data?.media?.file?.length > 0"
                  >
                    <span class="fileUpload" [ngStyle]="{ 'font-size': size + 2 + 'px' }">
                      <p>{{ agentFileName }}</p>
                      <a>
                        <mat-icon (click)="filedownload((data?.media?.file)[0])">get_app</mat-icon>
                      </a>
                    </span>
                  </h2>
                  <h2 class="formatted" [ngStyle]="{ 'font-size': size + 1 + 'px' }" [innerHTML]="data?.ans?.text"></h2>
                  <h2 class="formatted d-none" [ngStyle]="{ 'font-size': size + 1 + 'px' }">{{ data?.ans?.text }}</h2>

                  <!-- FOR IMAGE MEDIA -->
                  <div class="select-suggestion-wrap" *ngIf="data?.media?.image?.length > 0">
                    <div class="select-suggestions suggestion-img">
                      <ul>
                        <li *ngFor="let med of data?.media?.image">
                          <figure (click)="openMediaDialog(mediaTypes.Image, med)">
                            <img [src]="med" alt="chat info" />
                            <!-- <figcaption>Filename.jpg</figcaption> -->
                          </figure>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- FOR VIDEO MEDIA -->
                  <div class="select-suggestion-wrap" *ngIf="data?.media?.video?.length > 0">
                    <div class="select-suggestions suggestion-img">
                      <ul>
                        <li *ngFor="let med of data?.media?.video">
                          <div class="chat-video">
                            <video #videoPlayerMini controls>
                              <source [src]="med" type="video/mp4" />
                            </video>
                            <mat-icon class="material-icons-outlined" (click)="openMediaDialog(mediaTypes.Video, med)">
                              play_circle_outline
                            </mat-icon>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- FOR AUDIO MEDIA -->
                  <div class="select-suggestion-wrap" *ngIf="data?.media?.audio?.length > 0">
                    <div class="select-suggestions suggestion-img">
                      <ul>
                        <li *ngFor="let med of data?.media?.audio">
                          <div class="chat-audio">
                            <audio controls="">
                              <source [src]="med" type="audio/wav" />
                            </audio>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <!-- FOR ENTITY CARD MEDIA -->
                  <ng-container
                    [ngTemplateOutlet]="entityCardMedia"
                    [ngTemplateOutletContext]="{
                      chat: null,
                      data: data
                    }"
                  >
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="cstmsend" *ngIf="data?.user === 'nslhub.bot' && data?.button && data?.button?.length > 0">
              <div class="w-100">
                <div class="select-suggestions suggestions-list" *ngIf="data?.button">
                  <table
                    class="suggestion-table"
                    [ngStyle]="{ 'font-size': size + 4 + 'px' }"
                    [ngClass]="isOption ? 'options-list' : 'table'"
                  >
                    <tr>
                      <th>Select from the options</th>
                    </tr>
                    <tr *ngFor="let button of data?.button | slice: 0:optionsCount" (click)="sendButtonAction(button)">
                      <td>{{ button?.displayText }}</td>
                    </tr>
                    <tr *ngIf="data?.button?.length > 5" (click)="optionsCount = data?.button?.length">
                      <td class="more-topics" style="background-color: #e9e9e9;">More Topics</td>
                    </tr>
                    <tr *ngIf="data?.button?.length > 5" (click)="clickedTopics('not listed')">
                      <td class="more-topics" style="background-color: #e9e9e9;">Not Listed</td>
                    </tr>
                  </table>
                  <!-- <h3 [ngStyle]="{'font-size': (size+2)+'px' }">Select from the options</h3>
                  <ul>
                    <li [ngStyle]="{'font-size': (size+1)+'px' }"  *ngFor="let button of data?.button" (click)="sendButtonAction(button)">
                      <span> {{ button?.displayText }} </span>
                    </li>
                  </ul> -->
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngFor="let chat of updatedChatData?.messagesList; let i = index">
            <div class="chat-type" *ngIf="chat?.question">
              <div class="chatype-span" [ngStyle]="{ 'font-size': size + 1 + 'px' }">
                <span>{{ chat?.question }}</span>
              </div>
              <span class="boticon" [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                ><img src="../../../../../assets/images/boat/User-Icon.svg" alt="user" />
                <!-- <p>
                {{ userInfo?.name }}
              </p> -->
              </span>
            </div>
            <div
              class="cstmsend w-100 select-suggestions suggestions-list"
              *ngIf="
                chat?.answer && chat?.answer?.response_type === 'button' && chat?.answer?.buttonType == 'radioButton'
              "
            >
              <mat-radio-group id="radioBtnOptions_{{ i }}" class="add-radio-group" [(ngModel)]="option">
                <mat-radio-button
                  *ngFor="let button of chat?.answer?.button"
                  id="{{ button.displayText }}"
                  (click)="sendButtonAction(button)"
                  [value]="button.value"
                >
                  {{ button?.displayText }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div
              class="cstmsend"
              *ngIf="chat?.answer && chat?.answer?.response_type === 'button' && !chat?.answer?.buttonType"
            >
              <div class="w-100">
                <div class="select-suggestions suggestions-list" *ngIf="chat?.answer?.button">
                  <table
                    class="suggestion-table"
                    [ngStyle]="{ 'font-size': size + 4 + 'px' }"
                    [ngClass]="isOption ? 'options-list' : 'table'"
                  >
                    <tr>
                      <th>Select from the options</th>
                    </tr>
                    <tr
                      *ngFor="let button of chat?.answer?.button | slice: 0:optionsCount"
                      (click)="sendButtonAction(button)"
                    >
                      <td>{{ button?.displayText }}</td>
                    </tr>
                    <tr *ngIf="chat?.answer?.button?.length > 5" (click)="optionsCount = chat?.answer?.button?.length">
                      <td class="more-topics" style="background-color: #e9e9e9;">More Topics</td>
                    </tr>
                    <tr *ngIf="chat?.answer?.button?.length > 5" (click)="clickedTopics('not listed')">
                      <td class="more-topics" style="background-color: #e9e9e9;">Not Listed</td>
                    </tr>
                  </table>
                  <!-- <h3 [ngStyle]="{'font-size': (size+2)+'px' }" >Select from the options</h3>
                  <ul>
                    <li [ngStyle]="{'font-size': (size+1)+'px' }" *ngFor="let button of chat?.answer?.button" (click)="sendButtonAction(button)">
                      <span> {{ button?.displayText }} </span>
                    </li>
                  </ul> -->
                </div>
              </div>
            </div>
            <div class="cstmsend" *ngIf="chat?.answer && chat?.answer?.buttonType === 'chipsingleselection'">
              <div class="w-100">
                <div class="select-suggestions suggestions-list nh-ml-36 nh-pt-15" *ngIf="chat?.answer?.button">
                  <!-- <h3>Select from the options</h3> -->
                  <ul>
                    <li *ngFor="let button of chat?.answer?.button" (click)="sendButtonAction(button)">
                      <span id="chipselect"> {{ button?.displayText }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="cstmsend" *ngIf="chat?.answer && chat?.answer?.buttonType === 'dropdown'">
              <div class="w-100 nh-ml-36 nh-pt-15" id="dropdown">
                <mat-form-field appearance="fill">
                  <!-- <mat-label>Select from the options</mat-label> -->
                  <mat-select>
                    <mat-option
                      *ngFor="let button of chat?.answer?.button | slice: 0:optionsCount"
                      (click)="sendButtonAction(button)"
                    >
                      {{ button?.displayText }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="cstmsend" *ngIf="chat?.answer && chat?.answer?.response_type === 'suggestions'">
              <div class="w-100">
                <div class="select-suggestions suggestions-list" *ngIf="chat?.answer?.suggestions">
                  <table
                    class="suggestion-table"
                    [ngStyle]="{ 'font-size': size + 4 + 'px' }"
                    [ngClass]="isOption ? 'options-list' : 'table'"
                  >
                    <tr>
                      <th>Select from the options</th>
                    </tr>
                    <tr
                      *ngFor="let button of chat?.answer?.button | slice: 0:optionsCount"
                      (click)="sendButtonAction(button)"
                    >
                      <td>{{ button?.displayText }}</td>
                    </tr>
                    <tr *ngIf="chat?.answer?.button?.length > 5" (click)="optionsCount = chat?.answer?.button?.length">
                      <td class="more-topics" style="background-color: #e9e9e9;">More Topics</td>
                    </tr>
                    <tr *ngIf="chat?.answer?.button?.length > 5" (click)="clickedTopics('not listed')">
                      <td class="more-topics" style="background-color: #e9e9e9;">Not Listed</td>
                    </tr>
                  </table>
                  <!-- <h3 [ngStyle]="{'font-size': (size+2)+'px' }">Select from the topics</h3>
                  <ul>
                    <li [ngStyle]="{'font-size': (size+1)+'px' }" *ngFor="let button of chat?.answer?.button" (click)="sendButtonAction(button)">
                      <span> {{ button?.displayText }} </span>
                    </li>
                  </ul> -->
                  <p class="text-right">
                    <button
                      class="btn btn-more"
                      [ngStyle]="{ 'font-size': size + 'px' }"
                      [ngClass]="{ active: moreTopicsButton }"
                      (click)="clickedTopics('more topics')"
                    >
                      More Topics
                    </button>
                  </p>
                  <p class="not-listed">
                    <button
                      class="btn btn-not"
                      [ngStyle]="{ 'font-size': size + 'px' }"
                      [ngClass]="{ active: notListedButton }"
                      (click)="clickedTopics('not listed')"
                    >
                      Not Listed
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <!-- agents-calls -->
            <!-- <div class="cstmsend">
              <div class="w-100">
                <div class="call-list">
                  <ul class="call-list-inner">
                    <li>
                      <div class="calls-icon"></div>
                      <span>Chat with agent</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <!-- end-calls -->

            <div class="voice-input" *ngIf="chat?.voice">
              <audio controls="" [src]="chat?.voice | safeResourceUrl" type="audio/wav"></audio>
              <span class="boticon"><img src="../../../../../assets/images/boat/User-Icon.svg" alt="user" /></span>
            </div>
            <ng-container *ngIf="chat?.answer && (chat?.answer?.response_type === 'text' || chat?.answer?.response_type === 'number' || chat?.answer?.response_type === 'decimal')">
              <ng-container *ngIf="chat?.answer?.url">
                <ng-container [ngSwitch]="chat?.answer?.url?.type">
                  <ng-container *ngSwitchCase="'video_conference'">
                    <div class="chat-replay">
                      <span class="usricon" [ngStyle]="{ 'font-size': size + 2 + 'px' }">
                        <img [src]="botResponseIconUrl" width="30" alt="user" />
                      </span>
                      <span
                        class="d-block"
                        *ngIf="chat?.answer?.url?.type === 'video_conference'"
                        [ngStyle]="{ 'font-size': size + 2 + 'px' }"
                      >
                        <button
                          class="btn-send"
                          [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                          (click)="makeCall((chat?.answer?.url?.url)[0])"
                        >
                          Join the call
                        </button>
                      </span>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <div
                      class="chat-replay"
                      [ngClass]="{ 'chat-replay-entity-card': chat?.answer?.media?.entity_card?.length > 0 }"
                    >
                      <span
                        class="usricon"
                        *ngIf="chat?.answer?.user === 'yantr'"
                        [ngStyle]="{ 'font-size': size + 2 + 'px' }"
                      >
                        <img
                          [src]="'../../../../assets/img/chat-kia-circle.svg' | CDNUrlRewritePipe"
                          alt="kia"
                          width="10"
                        />
                      </span>
                      <span
                        *ngIf="chat?.answer?.user !== 'yantr'"
                        class="usricon"
                        [ngStyle]="{ 'font-size': size + 2 + 'px' }"
                      >
                        <img [src]="botResponseIconUrl" width="30" alt="user" />
                      </span>
                      <div #botreply class="w-100">
                        <div class="suggestionh2">
                          <!-- FILE UPLOAD  -->
                          <h2
                            class="formatted"
                            [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                            *ngIf="chat?.answer?.media?.file?.length > 0"
                          >
                            <span class="fileUpload" [ngStyle]="{ 'font-size': size + 2 + 'px' }">
                              <p>{{ agentFileName }}</p>
                              <a>
                                <mat-icon (click)="filedownload((chat?.answer?.media?.file)[0])">get_app</mat-icon>
                              </a>
                            </span>
                          </h2>

                          <h2
                            class="formatted"
                            [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                            [innerHTML]="chat?.answer?.ans?.text"
                          ></h2>
                          <h2 class="formatted d-none" [ngStyle]="{ 'font-size': size + 1 + 'px' }">
                            {{ chat?.answer?.ans?.text }}
                          </h2>

                          <!-- FOR IMAGE MEDIA -->
                          <div
                            class="select-suggestion-wrap"
                            *ngIf="
                              chat?.answer &&
                              chat?.answer?.response_type === 'text' &&
                              chat?.answer?.media?.image?.length > 0
                            "
                          >
                            <div class="select-suggestions suggestion-img">
                              <ul>
                                <li
                                  *ngFor="let med of chat?.answer?.media?.image"
                                  [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                                >
                                  <figure (click)="openMediaDialog(mediaTypes.Image, med)">
                                    <img [src]="med" alt="chat info" />
                                    <!-- <figcaption [ngStyle]="{'font-size': (size)+'px' }">Filename.jpg</figcaption> -->
                                  </figure>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <!-- FOR VIDEO MEDIA -->
                          <div
                            class="select-suggestion-wrap"
                            *ngIf="
                              chat?.answer &&
                              chat?.answer?.response_type === 'text' &&
                              chat?.answer?.media?.video?.length > 0
                            "
                          >
                            <div class="select-suggestions suggestion-img">
                              <ul>
                                <li
                                  *ngFor="let med of chat?.answer?.media?.video"
                                  [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                                >
                                  <div class="chat-video">
                                    <video #videoPlayerMini controls>
                                      <source [src]="med" type="video/mp4" />
                                    </video>
                                    <mat-icon
                                      class="material-icons-outlined"
                                      (click)="openMediaDialog(mediaTypes.Video, med)"
                                    >
                                      play_circle_outline
                                    </mat-icon>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <!-- FOR AUDIO MEDIA -->
                          <div
                            class="select-suggestion-wrap"
                            *ngIf="
                              chat?.answer &&
                              chat?.answer?.response_type === 'text' &&
                              chat?.answer?.media?.audio?.length > 0
                            "
                          >
                            <div class="select-suggestions suggestion-img">
                              <ul>
                                <li
                                  *ngFor="let med of chat?.answer?.media?.audio"
                                  [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                                >
                                  <div class="chat-audio">
                                    <audio controls="">
                                      <source [src]="med" type="audio/wav" />
                                    </audio>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <!-- FOR ENTITY CARD MEDIA -->
                          <ng-container
                            [ngTemplateOutlet]="entityCardMedia"
                            [ngTemplateOutletContext]="{
                              chat: chat,
                              data: null
                            }"
                          >
                          </ng-container>

                          <!-- FOR HTML TABLE -->
                          <!-- <div
                            class="html-table-wrap"
                            *ngIf="chat?.answer && chat?.answer?.media?.html_table?.length > 0"
                          >
                          <div>
                            <ul>
                              <li
                                *ngFor="let med of chat?.answer?.media?.html_table"
                                [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                              >
                                <h2
                                  id="{{'html-table'+i}}"
                                  [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                                  [innerHTML]="displaytable"
                                ></h2>
                              </li>
                            </ul>
                          </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!chat?.answer?.url">
                <div
                  class="chat-replay"
                  [ngClass]="{ 'chat-replay-entity-card': chat?.answer?.media?.entity_card?.length > 0 }"
                >
                  <span
                    class="usricon"
                    *ngIf="chat?.answer?.user === 'yantr'"
                    [ngStyle]="{ 'font-size': size + 2 + 'px' }"
                  >
                    <img
                      [src]="'../../../../assets/img/chat-kia-circle.svg' | CDNUrlRewritePipe"
                      alt="kia"
                      width="10"
                    />
                  </span>
                  <span
                    *ngIf="chat?.answer?.user !== 'yantr'"
                    class="usricon"
                    [ngStyle]="{ 'font-size': size + 2 + 'px' }"
                  >
                    <img [src]="botResponseIconUrl" width="30" alt="user" />
                  </span>
                  <div #botreply class="w-100">
                    <div class="suggestionh2">
                      <!--AGENT FILE UPLOAD  -->
                      <h2
                        class="formatted"
                        [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                        *ngIf="chat?.answer?.media?.file?.length > 0"
                      >
                        <span class="fileUpload" [ngStyle]="{ 'font-size': size + 2 + 'px' }">
                          <p>{{ agentFileName }}</p>
                          <a>
                            <mat-icon (click)="filedownload((chat?.answer?.media?.file)[0])">get_app</mat-icon>
                          </a>
                        </span>
                      </h2>
                      <h2
                        class="formatted"
                        [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                        [innerHTML]="chat?.answer?.ans?.text"
                      ></h2>
                      <h2 class="formatted d-none" [ngStyle]="{ 'font-size': size + 1 + 'px' }">
                        {{ chat?.answer?.ans?.text }}
                      </h2>

                      <!-- FOR IMAGE MEDIA -->
                      <div
                        class="select-suggestion-wrap"
                        *ngIf="
                          chat?.answer &&
                          chat?.answer?.response_type === 'text' &&
                          chat?.answer?.media?.image &&
                          chat?.answer?.media?.image?.length > 0
                        "
                      >
                        <div class="select-suggestions suggestion-img">
                          <ul>
                            <li
                              *ngFor="let med of chat?.answer?.media?.image"
                              [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                            >
                              <figure (click)="openMediaDialog(mediaTypes.Image, med)">
                                <img [src]="med" alt="chat info" />
                                <!-- <figcaption [ngStyle]="{'font-size': (size)+'px' }">Filename.jpg</figcaption> -->
                              </figure>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- FOR VIDEO MEDIA -->
                      <div
                        class="select-suggestion-wrap"
                        *ngIf="
                          chat?.answer &&
                          chat?.answer?.response_type === 'text' &&
                          chat?.answer?.media?.video?.length > 0
                        "
                      >
                        <div class="select-suggestions suggestion-img">
                          <ul>
                            <li
                              *ngFor="let med of chat?.answer?.media?.video"
                              [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                            >
                              <div class="chat-video">
                                <video #videoPlayerMini controls>
                                  <source [src]="med" type="video/mp4" />
                                </video>
                                <mat-icon
                                  class="material-icons-outlined"
                                  (click)="openMediaDialog(mediaTypes.Video, med)"
                                >
                                  play_circle_outline
                                </mat-icon>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- FOR AUDIO MEDIA -->
                      <div
                        class="select-suggestion-wrap"
                        *ngIf="
                          chat?.answer &&
                          chat?.answer?.response_type === 'text' &&
                          chat?.answer?.media?.audio?.length > 0
                        "
                      >
                        <div class="select-suggestions suggestion-img">
                          <ul>
                            <li *ngFor="let med of chat?.answer?.media?.audio">
                              <div class="chat-audio">
                                <audio controls="">
                                  <source [src]="med" type="audio/wav" />
                                </audio>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <!-- FOR ENTITY CARD MEDIA -->
                      <ng-container
                        [ngTemplateOutlet]="entityCardMedia"
                        [ngTemplateOutletContext]="{
                          chat: chat,
                          data: null
                        }"
                      >
                      </ng-container>

                      <!-- FOR HTML TABLE -->
                      <!-- <div class="html-table-wrap" *ngIf="chat?.answer && chat?.answer?.media?.html_table?.length > 0">
                        <ul>
                          <li
                            *ngFor="let med of chat?.answer?.media?.html_table"
                            [ngStyle]="{ 'font-size': size - 2 + 'px' }"
                          >
                            <h2
                              id="{{'html-table'+i}}"
                              [ngStyle]="{ 'font-size': size + 1 + 'px' }"
                              [innerHTML]="displaytable"
                            ></h2>
                          </li>
                        </ul>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="emoj-btns" *ngIf="chat?.answer?.isFaqOrAtq && isCaptureFeedbackEnabled">
                  <ul [ngStyle]="{ 'font-size': size + 'px' }">
                    <div>Was this answer helpful?</div>
                    <div class="feedback-icons">
                      <li
                        (click)="
                          chat.answer.isFaqOrAtq = false; question = 'Thanks, this is helpful'; sendQuestions(scroll)
                        "
                      >
                        <!-- <span>Was this answer helpful?</span> -->
                        <mat-icon class="feedback">thumb_up</mat-icon>
                        <!-- <figure>
                      <img src="../../../../assets/img/chatbot/emoj01.png" alt="" />
                    </figure> -->
                        <!-- <span>was this answer helpful?</span> -->
                      </li>
                      <li
                        (click)="
                          chat.answer.isFaqOrAtq = false; question = 'No, this is NOT helpful'; sendQuestions(scroll)
                        "
                      >
                        <mat-icon class="feedback">thumb_down</mat-icon>
                        <!-- <figure>
                      <img src="../../../../assets/img/chatbot/emoj02.png" alt="" />
                    </figure> -->
                        <!-- <span>No, this is NOT helpful</span> -->
                      </li>
                    </div>
                  </ul>
                </div>
              </ng-container>
            </ng-container>
            <div class="chat-type" *ngIf="chat?.file">
              <div class="chatype-span w-auto" [ngStyle]="{ 'font-size': size + 1 + 'px' }">
                <ng-container>
                  <span class="filespan" *ngIf="chat?.file != 'error'" [ngStyle]="{ 'font-size': size + 1 + 'px' }">
                    <p>{{ chat?.file?.fileName }}&nbsp;&nbsp;</p>
                    <a>
                      <mat-icon (click)="filedownload(this.chat?.file?.filePath)">get_app</mat-icon>
                    </a>
                  </span>
                </ng-container>
              </div>
              <span class="boticon"><img src="../../../../../assets/images/boat/User-Icon.svg" alt="user" /></span>
            </div>
          </ng-container>
          <div #target class="chatscript-close">
            <div class="prompt" *ngIf="openPrompt">
              Do you want a transcript sent to your mail?
              <br />
              <div class="cstmsend">
                <button class="btn send" (click)="sendTranscript(true)">Yes</button>
                <button class="btn close" (click)="sendTranscript(false)">No</button>
              </div>
            </div>
          </div>
          <div class="comments-box" *ngIf="toggleFeedback && feedbackEnabled">
            <div class="chat-rating">
              <p [ngStyle]="{ 'font-size': size + 'px' }">{{ labels?.Chat_Exprience }}</p>
              <div class="rating-stars">
                <mat-icon
                  [ngClass]="{ 'rating-active': feedback?.rating >= 1 }"
                  class="material-icons-outlined star"
                  (click)="ratingAtSessionLevel(1)"
                  >star_outline
                </mat-icon>
                <mat-icon
                  [ngClass]="{ 'rating-active': feedback?.rating >= 2 }"
                  class="material-icons-outlined star"
                  (click)="ratingAtSessionLevel(2)"
                  >star_outline</mat-icon
                >
                <mat-icon
                  [ngClass]="{ 'rating-active': feedback?.rating >= 3 }"
                  class="material-icons-outlined star"
                  (click)="ratingAtSessionLevel(3)"
                  >star_outline</mat-icon
                >
                <mat-icon
                  [ngClass]="{ 'rating-active': feedback?.rating >= 4 }"
                  class="material-icons-outlined star"
                  (click)="ratingAtSessionLevel(4)"
                  >star_outline</mat-icon
                >
                <mat-icon
                  [ngClass]="{ 'rating-active': feedback?.rating === 5 }"
                  class="material-icons-outlined star"
                  (click)="ratingAtSessionLevel(5)"
                  >star_outline</mat-icon
                >
              </div>
            </div>

            <div class="comments-info">
              <p [ngStyle]="{ 'font-size': size + 'px' }">{{ labels?.Chat_Comments }}</p>
              <textarea rows="4" [(ngModel)]="comments"></textarea>
              <div class="cstmsend">
                <button class="btn send" (click)="sendFeedback(true)">{{ labels?.Send }}</button>
                <button class="btn comment-btn" (click)="sendFeedback(false)">
                  {{ labels?.Chatbot_Nocomments }}
                </button>
              </div>
            </div>
          </div>
          <div class="emoj-btns" *ngIf="false">
            <ul [ngStyle]="{ 'font-size': size + 'px' }">
              <li (click)="emojiFeedbackFlag = false">
                <figure>
                  <img [src]="'../../../../assets/img/chatbot/emoj01.png' | CDNUrlRewritePipe" alt="" />
                </figure>
                <span>Thanks, this is helpful</span>
              </li>
              <li (click)="emojiFeedbackFlag = false; question = 'No, this is NOT helpful'; sendQuestions(scroll)">
                <figure>
                  <img [src]="'../../../../assets/img/chatbot/emoj02.png' | CDNUrlRewritePipe" alt="" />
                </figure>
                <span>No, this is NOT helpful</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="calender-clock width=50">
          <form class="calender-clock" [formGroup]="dateForm">
            <div class="calender-clock-input" *ngIf="isCallBackEnabled">
              <mat-form-field>
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker"
                  placeholder="Choose date and time"
                  [formControl]="dateControl"
                  [disabled]="disabled"
                  [min]="minDate"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker
                  [showSpinners]="showSpinners"
                  [showSeconds]="showSeconds"
                  [stepHour]="stepHour"
                  [stepMinute]="stepMinute"
                  [stepSecond]="stepSecond"
                  [touchUi]="touchUi"
                  [color]="'primary'"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <mat-icon class="sendDateTime" (click)="CalendarClock()">send</mat-icon>
            </div>
          </form>
        </div>
      </div>
      <ng-container *ngIf="sugestions?.length">
        <div class="suggestion">
          <ng-container *ngFor="let suggestion of sugestions">
            <p (click)="question = suggestion; sugestions = []">{{ suggestion }}</p>
          </ng-container>
        </div>
      </ng-container>
      <!-- <div class="chat-user" [ngStyle]="{'font-size': (size-1)+'px' }">
        <emoji-mart
          class="emoji-mart"
          *ngIf="emojiePickerFlag"
          (emojiSelect)="addEmoji($event)"
          title="Choose your emoji"
        ></emoji-mart>
        <form class="send-message-form">
          <input
            class="chat-user-text"
            [ngStyle]="{'font-size': (size)+'px' }"
            type="text"
            name="question"
            [(ngModel)]="question"
            (keydown.enter)="sendQuestions(scroll)"
            placeholder="Your message"
          />
        </form>
        <div class="upload">
          <div class="chat-send">
            <div>
              <figure>
                <img
                  [src]="'../../../../assets/img/chatbot/chat-send.png' | CDNUrlRewritePipe"
                  alt=""
                  (click)="sendQuestions(scroll)"
                />
              </figure>
            </div>
          </div>
          <div class="chat-voice">
            <mat-icon class="material-icons-outlinede" (click)="record()">mic_none</mat-icon>
          </div>
          <div class="file-upload">
            <div class="custom-file">
              <input type="file" class="custom-file-input" (change)="fileUpload($event.target.files)" />
              <label class="custom-file-label">
                <img [src]="'../../../../assets/img/chatbot/attachment.svg' | CDNUrlRewritePipe" alt="" />
              </label>
            </div>
          </div>
          <div class="emojies">
            <figure (click)="emojiePickerFlag = !emojiePickerFlag" *ngIf="useEmojis">
              <img [src]="'../../../../assets/img/chatbot/emoj01.png' | CDNUrlRewritePipe" alt="" />
            </figure>
          </div>
          <div class="setLanguage">
            <mat-icon (click)="displayLanguageSettings = !displayLanguageSettings">language</mat-icon>
          </div>
          <div class="LanguageDropdown" *ngIf="displayLanguageSettings">
            <ul>
              <li *ngFor="let each of langulageOptions">
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group"
                  [(ngModel)]="selectedLanguage"
                  (change)="setLanguage(selectedLanguage)"
                >
                  <mat-radio-button class="example-radio-button" [value]="each.value">
                    {{ each.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </li>
            </ul>
          </div>
        </div>
      </div> -->

      <div class="chat-user" [ngStyle]="{ 'font-size': size - 1 + 'px' }">
        <emoji-mart
          class="emoji-mart"
          *ngIf="emojiePickerFlag"
          (emojiSelect)="addEmoji($event)"
          title="Choose your emoji"
        ></emoji-mart>
        <form class="send-message-form">
          <input
            class="chat-user-text"
            [ngStyle]="{ 'font-size': size - 1 + 'px' }"
            type="text"
            name="question"
            [(ngModel)]="question"
            (keydown.enter)="sendQuestions(scroll)"
            placeholder="Type a message"
            [disabled]="disableInput"
            (ngModelChange)="questionInput.next($event)"
          />
        </form>
        <div class="upload">
          <!-- send que -->
          <div class="chat-voice cht-bg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              (click)="sendQuestions(scroll)"
            >
              <g id="Group_116383" data-name="Group 116383" transform="translate(10617 17774)">
                <circle
                  id="Ellipse_12432"
                  data-name="Ellipse 12432"
                  cx="14"
                  cy="14"
                  r="14"
                  transform="translate(-10617 -17774)"
                  fill="#00000"
                />
                <g id="Group_96514" data-name="Group 96514" transform="translate(-10607.899 -17766.936)">
                  <path
                    id="Path_101766"
                    data-name="Path 101766"
                    d="M.5.5,14.361,7.43.5,14.361,3.067,7.43Z"
                    transform="translate(-0.5 -0.5)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <line
                    id="Line_4698"
                    data-name="Line 4698"
                    x1="11.284"
                    transform="translate(2.569 6.93)"
                    fill="fffff"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
          </div>

          <!-- mic -->
          <div class="chat-voice cht-bg">
            <svg
              id="Component_572_9"
              data-name="Component 572 – 9"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              (click)="record()"
            >
              <circle
                id="Ellipse_16408"
                class="icon-circle"
                data-name="Ellipse 16408"
                cx="14"
                cy="14"
                r="14"
                fill="#f5effe"
              />
              <g id="Group_116253" data-name="Group 116253" transform="translate(6 6)">
                <g
                  class="icon-bg"
                  id="Rectangle_23978"
                  data-name="Rectangle 23978"
                  stroke="#707070"
                  stroke-width="1"
                  opacity="0"
                >
                  <rect width="16" height="16" stroke="none" />
                  <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
                </g>
                <g id="Microphone" transform="translate(3.292)">
                  <rect
                    class="icon-bg"
                    id="Rectangle_22768"
                    data-name="Rectangle 22768"
                    width="6"
                    height="11"
                    rx="3"
                    transform="translate(1.708)"
                    fill="none"
                    stroke="#a765ff"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <path
                    class="icon-bg"
                    id="Path_93477"
                    data-name="Path 93477"
                    d="M-172.085,660.5a4.707,4.707,0,0,1-4.707,4.707A4.707,4.707,0,0,1-181.5,660.5"
                    transform="translate(181.5 -652.517)"
                    fill="none"
                    stroke="#a765ff"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    class="icon-bg"
                    id="Line_4632"
                    data-name="Line 4632"
                    y2="2"
                    transform="translate(4.708 13)"
                    fill="none"
                    stroke="#a765ff"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    class="icon-bg"
                    id="Line_4633"
                    data-name="Line 4633"
                    x2="6.548"
                    transform="translate(1.434 15.43)"
                    fill="none"
                    stroke="#a765ff"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
            <video
              src="../../../assets/images/chatbot-canvas/loader.mp4"
              type="video/mp4"
              class="mic_video_one"
              width="28"
              height="28"
              loop
              autoplay
              (click)="record()"
              *ngIf="isRecordingEnabled"
            ></video>
          </div>

          <div class="show-icons" *ngIf="showIcons">
            <!-- file attachment -->
            <div class="chat-voice cht-bg">
              <label class="file">
                <input type="file" class="hidden" (change)="fileUpload($event.target.files)" />
                <svg
                  id="Component_574_3"
                  data-name="Component 574 – 3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                >
                  <circle
                    id="Ellipse_16410"
                    class="icon-circle"
                    data-name="Ellipse 16410"
                    cx="14"
                    cy="14"
                    r="14"
                    fill="#f5effe"
                  />
                  <g id="Group_116411" data-name="Group 116411" transform="translate(12336 -6895)">
                    <rect
                      class="icon-bg1"
                      id="Rectangle_35408"
                      data-name="Rectangle 35408"
                      width="16"
                      height="16"
                      transform="translate(-12330 6901)"
                      fill="#a765ff"
                      opacity="0"
                    />
                    <path
                      class="icon-bg1"
                      id="paperclip"
                      d="M6.654,16.89a5.285,5.285,0,0,1-3.64-1.424,4.707,4.707,0,0,1,0-6.916L10.056,1.89a3.766,3.766,0,0,1,5.111,0,3.312,3.312,0,0,1,0,4.864l-7.05,6.661a2.168,2.168,0,0,1-2.942,0,1.916,1.916,0,0,1,0-2.813l6.506-6.147a.567.567,0,0,1,.782.008.52.52,0,0,1-.008.754L5.949,11.364a.875.875,0,0,0,0,1.289,1.028,1.028,0,0,0,1.394,0l7.05-6.661a2.271,2.271,0,0,0,0-3.34,2.625,2.625,0,0,0-3.563,0L3.789,9.313a3.666,3.666,0,0,0,0,5.392,4.223,4.223,0,0,0,5.731,0l7.042-6.661a.567.567,0,0,1,.782.008.52.52,0,0,1-.008.754l-7.042,6.661A5.285,5.285,0,0,1,6.654,16.89Z"
                      transform="translate(-12331.502 6900.11)"
                      fill="#a765ff"
                    />
                  </g>
                </svg>
              </label>
            </div>

            <!-- emoji -->
            <div class="chat-voice cht-bg" *ngIf="useEmojis">
              <svg
                id="Component_575_3"
                data-name="Component 575 – 3"
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                (click)="emojiePickerFlag = !emojiePickerFlag"
              >
                <circle
                  id="Ellipse_16409"
                  class="icon-circle"
                  data-name="Ellipse 16409"
                  cx="14"
                  cy="14"
                  r="14"
                  fill="#f5effe"
                />
                <g id="Group_72072" data-name="Group 72072" transform="translate(6 6)">
                  <g id="smile">
                    <path
                      class="icon-bg1"
                      id="Ellipse_11594"
                      data-name="Ellipse 11594"
                      d="M7.5-.5a8,8,0,1,1-8,8,8,8,0,0,1,8-8Zm0,15.059A7.059,7.059,0,1,0,.441,7.5,7.059,7.059,0,0,0,7.5,14.559Z"
                      transform="translate(0.5 0.5)"
                      fill="#a765ff"
                    />
                    <path
                      class="icon-bg1"
                      id="Path_72115"
                      data-name="Path 72115"
                      d="M11.2,16.1a4.8,4.8,0,0,1-3.6-1.8.5.5,0,1,1,.8-.6,3.848,3.848,0,0,0,2.8,1.4A3.807,3.807,0,0,0,14,13.7a.5.5,0,0,1,.8.6,4.8,4.8,0,0,1-3.6,1.8Z"
                      transform="translate(-3.2 -4.567)"
                      fill="#a765ff"
                    />
                    <path
                      class="icon-bg"
                      id="Line_1"
                      data-name="Line 1"
                      d="M.008.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H.008a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5Z"
                      transform="translate(5.75 5.75)"
                      stroke="#a765ff"
                      stroke-width="1"
                    />
                    <path
                      class="icon-bg"
                      id="Line_2"
                      data-name="Line 2"
                      d="M.008.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H.008a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5Z"
                      transform="translate(10.25 5.75)"
                      stroke="#a765ff"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <!-- languages -->
            <div class="chat-voice cht-bg" (click)="displayLanguageSettings = !displayLanguageSettings">
              <svg
                id="Component_576_4"
                data-name="Component 576 – 4"
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
              >
                <circle
                  id="Ellipse_16411"
                  class="icon-circle"
                  data-name="Ellipse 16411"
                  cx="14"
                  cy="14"
                  r="14"
                  fill="#f5effe"
                />
                <g id="English_En_" data-name="English (En)" transform="translate(6 6)">
                  <path
                    id="Rectangle_20859"
                    data-name="Rectangle 20859"
                    d="M1,1V15H15V1H1M0,0H16V16H0Z"
                    fill="#323232"
                    opacity="0"
                  />
                  <g id="Group_72067" data-name="Group 72067">
                    <path
                      class="icon-bg1"
                      id="Ellipse_11593"
                      data-name="Ellipse 11593"
                      d="M8,1a7,7,0,1,0,7,7A7,7,0,0,0,8,1M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z"
                      transform="translate(16) rotate(90)"
                      fill="#a765ff"
                    />
                    <path
                      class="icon-bg1"
                      id="Path_72100"
                      data-name="Path 72100"
                      d="M1,14.342H0V0H1Z"
                      transform="translate(15.155 4.835) rotate(90)"
                      fill="#a765ff"
                    />
                    <path
                      class="icon-bg1"
                      id="Path_72101"
                      data-name="Path 72101"
                      d="M1,14.342H0V0H1Z"
                      transform="translate(15.155 10.165) rotate(90)"
                      fill="#a765ff"
                    />
                    <path
                      class="icon-bg1"
                      id="Path_72102"
                      data-name="Path 72102"
                      d="M-9868.26-23208.6l-.832-.555a12.507,12.507,0,0,0,2.068-5.365,11.829,11.829,0,0,0-.312-4.492,12.769,12.769,0,0,0-1.969-4.234l.784-.615a13.506,13.506,0,0,1,2.153,4.6,12.79,12.79,0,0,1,.337,4.869,13.507,13.507,0,0,1-2.231,5.791Z"
                      transform="translate(9877.028 23224.168)"
                      fill="#a765ff"
                    />
                    <path
                      class="icon-bg1"
                      id="Path_72103"
                      data-name="Path 72103"
                      d="M-9867.09-23208.6a13.5,13.5,0,0,1-2.229-5.793,12.773,12.773,0,0,1,.336-4.869,13.517,13.517,0,0,1,2.153-4.6l.785.615-.393-.309.394.309a12.805,12.805,0,0,0-1.98,4.271,11.779,11.779,0,0,0-.3,4.479,12.5,12.5,0,0,0,2.065,5.338Z"
                      transform="translate(9874.363 23224.168)"
                      fill="#a765ff"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>

          <div class="LanguageDropdown" *ngIf="displayLanguageSettings">
            <ul>
              <li *ngFor="let each of langulageOptions">
                <mat-radio-group [(ngModel)]="selectedLanguage" (change)="setLanguage(selectedLanguage)">
                  <mat-radio-button [value]="each.value">
                    {{ each.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </li>
            </ul>
          </div>

          <!-- slide icon -->
          <div class="chat-voice cht-bg" (click)="showIcons = !showIcons">
            <svg
              id="Component_572_8"
              data-name="Component 572 – 8"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
            >
              <circle
                id="Ellipse_12432"
                class="icon-circle"
                data-name="Ellipse 12432"
                cx="14"
                cy="14"
                r="14"
                fill="#f5effe"
              />
              <path
                class="icon-bg"
                id="Path_105164"
                data-name="Path 105164"
                d="M-13362.715,6735.1l-6,6,6,6"
                transform="translate(13379.715 -6727.073)"
                fill="none"
                stroke="#a765ff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="cstmsend">
      <button class="btn close" (click)="toggleShow()">{{ labels?.Close }}</button>
      <button class="btn-send" [disabled]="!question" (click)="sendQuestions(target)">{{ labels?.Send }}</button>
    </div> -->
</div>

<div
  class="chat-click-icon"
  (click)="toggleShow()"
  [ngClass]="{ 'chat-click-icon-carnival': chatBotIcons.Robot === iconType }"
  *ngIf="!isShown && chatBotEnabled && !hideIcon && enableChatBotForRole"
>
  <div class="hovertext">
    <div class="bot-relative">
      <figure *ngIf="chatBotIcons.Messenger === iconType && !isOnboarding">
        <!-- <img [src]="'../../../../assets/images/kiaSmall.png' | CDNUrlRewritePipe" alt="kia" /> -->
        <img [src]="botIconUrl" alt="labels?.bot_image" />
      </figure>
      <figure *ngIf="chatBotIcons.Robot === iconType && !isOnboarding">
        <!-- <img [src]="'../../../../assets/images/kiaSmall.png' | CDNUrlRewritePipe" alt="kia" /> -->
        <img [src]="botIconUrl" alt="labels?.bot_image" />
      </figure>
      <!-- for onboarding -->
      <figure *ngIf="isOnboarding">
        <img src="../../../../assets/img/onboarding/icon-chat.png" alt="labels?.bot_image" />
      </figure>
      <!-- <div class="chatcount" *ngIf="unreadMessages > 0">
          <span [ngStyle]="{'font-size': (size)+'px' }">{{ unreadMessages }}</span>
        </div> -->
    </div>
  </div>
</div>

<ng-template #entityCardMedia let-chat="chat" let-data="data">
  <div
    class="select-suggestion-wrap zoom-9"
    *ngIf="chat?.answer && chat?.answer?.response_type === 'text' && chat?.answer?.media?.entity_card?.length > 0"
  >
    <div class="w-100" *ngIf="chat?.answer?.media?.entity_card?.[0]?.cardId">
      <app-entity-grid-card
        class="col-auto grid-card-style"
        [cardId]="chat?.answer?.media?.entity_card?.[0]?.cardId"
        [isShowCardDetails]="false"
        [chatbot]="true"
        [chatbotCardData]="chat?.answer?.media?.entity_card?.[0]?.data"
        (backFromCard)="backFromCardEntity($event)"
      >
      </app-entity-grid-card>
    </div>
  </div>

  <div class="select-suggestion-wrap zoom-9" *ngIf="data?.media?.entity_card?.length > 0">
    <div class="w-100" *ngIf="data?.media?.entity_card?.[0]?.cardId">
      <app-entity-grid-card
        class="col-auto"
        [cardId]="data?.media?.entity_card?.[0]?.cardId"
        [isShowCardDetails]="false"
        [chatbot]="true"
        [chatbotCardData]="data?.media?.entity_card?.[0]?.data"
        (backFromCard)="backFromCardEntity($event)"
      >
      </app-entity-grid-card>
    </div>
  </div>
</ng-template>
