import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapping-display',
  templateUrl: './mapping-display.component.html',
  styleUrls: ['./mapping-display.component.scss'],
})
export class MappingDisplayComponent implements OnInit {
  @Input() attributeMapping: any;
  constructor() {}

  ngOnInit(): void {
    if (this.attributeMapping?.expression)
      this.attributeMapping = JSON.parse(JSON.stringify(this.attributeMapping?.expression));
    console.log(this.attributeMapping, 'attrMap');
  }

  //Fpr displaying Value of Object
  getValue(obj: any) {
    return Object.values(obj)?.[0] ? Object.values(obj)?.[0] : '';
  }

  //For Checking Array type
  checkAttrmap(map: any) {
    return Array.isArray(map);
  }
}
