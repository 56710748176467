<ng-container>
  <mat-dialog-content class="confirm-delete py-3">
    <div class="users-table">
      <h5>Are you sure you want to Delete this {{ betType }}?</h5>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <span>
      <button mat-button (click)="closePopup(true)" color="primary">Delete</button>
      <button mat-button matDialogClose color="accent" (click)="closePopup(false)">Cancel</button>
    </span>
  </mat-dialog-actions>
</ng-container>
