import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TransactionFacadeService, getCurrentEventDataArray, getEventData, templateExpandMap } from '@common-services';

@Component({
  selector: 'app-dynamic-ui-card-ecommerce-child-templates',
  templateUrl: './dynamic-ui-card-ecommerce-child-templates.component.html',
  styleUrls: ['./dynamic-ui-card-ecommerce-child-templates.component.scss'],
})
export class DynamicUiCardEcommerceChildTemplatesComponent implements OnInit {
  @Input() expandIndex: number = -2;
  @Input() currentIndex: number;
  @Input() showExpand: boolean = false;
  @Input() entity: any;
  @Input() settings: any;
  @Input() eventCUList: any;
  attributes: any = {};
  attributes_expand: any = {};
  attr2: any;
  contentUrl: any = {};
  templateType: any;
  expandType: any;
  @Input() listValues: any;
  @Output() selectCardEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() dCard: any;
  @Output() expandEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOnSelectDelete: EventEmitter<any> = new EventEmitter<any>();

  hasExpand: boolean = false;
  set childTemplate(value: number) {
    this.templateType = value;
    this.expandType = templateExpandMap[this.templateType];
  }
  currentEventDataArray: any = [[], [], [], [], [], [], []];
  constructor(private transactionFacadeService: TransactionFacadeService) {}

  ngOnInit(): void {
    this.configureCard();
    /* istanbul ignore next */
    if (this.settings?.customizeCardconfigData?.templateType) {
      this.templateType = this.settings?.customizeCardconfigData?.templateType;
    }
    if (this.settings?.customizeCardconfigData?.expandTemplateTypeData) {
      this.hasExpand = true;
      this.childTemplate = this.settings?.customizeCardconfigData?.templateType;
      this.configureExpandData();
    }
    this.attachImages();
    // this.expandEmitter.emit(true); if in commits pls choose this in dev don't remove this let it be in comments
  }

  configureCard(): void {
    let attributePositions = this.settings?.customizeCardconfigData?.attr;
    this.entity?.forEach((ele: any) => {
      const index = attributePositions.findIndex((attr: any) => {
        return attr == ele.attributeName;
      });
      this.mapingAttributeValues(index, ele);
    });
  }

  configureExpandData(): void {
    let attributePositions = this.swapKeyValues(this.settings?.customizeCardconfigData?.expandTemplateTypeData);
    this.entity?.forEach((ele: any) => {
      this.attributes_expand[attributePositions[ele.attributeName]] = ele;
    });
  }

  swapKeyValues(obj: any): any {
    let result = {};
    Object.keys(obj).forEach((key: any) => {
      result[obj[key]] = key;
    });
    return result;
  }

  mapingAttributeValues(index: any, ele: any) {
    this.attributes[index] = ele;
  }
  openExpand(event: any): void {
    if (!this.hasExpand) return;
    if (event.srcElement.tagName !== 'INPUT') {
      this.showExpand = true;
      this.expandEmitter.emit(this.showExpand); // if in commits pls choose this in dev don't remove this emitter
    }
  }

  closeExpand(event: any): void {
    this.showExpand = false;
    this.expandEmitter.emit(this.showExpand); // if in commits pls choose this in dev don't remove this emitter
  }

  attachImages() {
    Object.keys(this.attributes).forEach((key, index) => {
      if (this.attributes[key].inputType == 'image') {
        this.contentUrl[parseInt(key) + 1] = this.attributes[key].attributeValue;
      }
    });
  }
  selectedCards(event: any, type?: string) {
    if (type == 'radiobutton') this.dCard.isChecked = !this.dCard.isChecked;
    event.stopPropagation();
    event.checked = this.dCard.isChecked;
    this.selectCardEmitter.emit(event);
  }

  deleteCard(event: any) {
    this.deleteEventEmitter.emit(event);
  }
  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any) {
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }
  // Check event data based on attribute id
  checkEventGSI(attr: any, index: number = 0) {
    if (attr && attr?.attributeName && attr?.[attr?.attributeName]) {
      /*istanbul ignore next*/
      this.currentEventDataArray[index] = getCurrentEventDataArray(this.eventCUList, attr[attr?.attributeName]);
      if (this.currentEventDataArray[index].length > 0) {
        return true;
      }
    }
    return false;
  }
  onSelectDelete(event: any) {
    this.emitOnSelectDelete.next(event);
  }
}