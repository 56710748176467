import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { returnMobileNumber } from '@common-services';

@Directive({
  selector: '[appPhoneMask]',
})
export class PhoneMaskDirective {
  @Input() mobileNumberFormat: any;
  @Input() type: any;
  private onChange: (val: string) => void;
  private onTouched: () => void;
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event: any, backspace: any) {
    if (this.type === 'mobilenumber') {
      if (this.mobileNumberFormat && this.mobileNumberFormat == 'USA') {
        let newVal = this.mobileNumberFormat ? returnMobileNumber(event, this.mobileNumberFormat, backspace) : event;
        this.ngControl.valueAccessor.writeValue(newVal);
      } else if (this.mobileNumberFormat && this.mobileNumberFormat == 'MEXICO') {
        let newVal = this.mobileNumberFormat ? returnMobileNumber(event, this.mobileNumberFormat, backspace) : event;
        this.ngControl.valueAccessor.writeValue(newVal);
      } else {
        let newVal = event.replace(/[^+-\d]/g, '');
        var pattern = /(\+\+|--|\+\-|\-\+|[\+\-]{3,})/;
        if (pattern.test(newVal)) {
          newVal = newVal.slice(0, -1);
        }
        this.ngControl.valueAccessor.writeValue(newVal);
      }
    }
  }
}
