import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService, EventsFacadeService, TransactionFacadeService,  FileUploadPreviewComponent, FieldConfig} from '@common-services';
import { Subject } from 'rxjs';
var Plyr = require('plyr');

@Component({
  selector: 'app-video',
  styleUrls: ['./video.component.scss'],
  template: `
    <div class="demo-full-width player-custom-video">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >
          <span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
          </span>
        </label>
        <p
          class="nh-ml-6 d-flex main-label-helperIcon"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>

      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div class="d-flex" *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])">
        <ng-container *ngIf="videoUrls?.length > 0; else datanotfound">
          <ng-container>
            <div class="w-100 video-data-cont" *ngIf="!(iscarnivalscreen && field.readonly)">
              <video id="phy" class="plyrID" controls playsinline>
                <source [src]="videoUrls[0]" />
              </video>
            </div>
            <div class="w-100 video-data-cont" (click)="onPreviewClick()" *ngIf="iscarnivalscreen && field.readonly">
              <video id="info" controls playsinline poster="../../../../../assets/img/transaction/video-poster.png">
                <source [src]="" />
              </video>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #datanotfound>
          <p class="novideofound">{{ labels?.No_Video_Found }}</p>
        </ng-template>
      </div>

      <!--video text in table-->
      <div *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']" class="video-in-table">
        <figure (click)="toggleShow($event)" class="video-icon">
          <img src="../../../assets/images/ui-controls/icon-video-recording.svg" alt="" />
        </figure>

        <div class="video-popup" *ngIf="isShown" id="divshow">
          <div class="video-panel">
            <div class="video-popup-header">
              <h2 class="video-pop-title">{{ field?.label }}</h2>
              <div class="video-action-btns">
                <ul>
                  <li (click)="toggleShow($event)">
                    <i class="bi bi-x"></i>
                  </li>
                </ul>
              </div>
            </div>
            <div class="video-popup-content">
              <div class="d-flex">
                <ng-container>
                  <div class="w-100 video-data-cont">
                    <video #videoPlayer id="video" width="100%" controls>
                      <source [src]="videoUrls[0]" />
                    </video>
                  </div>
                </ng-container>

                <ng-template #datanotfound>
                  <p>{{ labels?.No_Video_Found }}</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>

    </div>
  `,
  styles: [
    `
      img {
        width: 100%;
      }
      .main-label {
        color: #999999;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 400;
      }
    `,
  ],
})
export class VideoComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  labels: any;
  splitscreenClicked: boolean = false;

  private ngUnsubscribe = new Subject();
  videoUrls: any[];

  src: any;
  iscarnivalscreen: boolean = false;
  isShown: boolean = false; // hidden by default
  toggleShow(event: any) {
    this.isShown = !this.isShown;
    event.stopPropagation();
  }
  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private transacationFacadeService: TransactionFacadeService,
    private dialogService: MatDialog
  ) {
    this.detectLanguageChange();
    this.detectCarnivalScreen();
  }

  ngAfterViewInit() {
    const players = Array.from(document.querySelectorAll('.plyrID')).map(
      (p) =>
        new Plyr(p, {
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'settings',
            'airplay',
            'fullscreen',
          ],
        })
    );

    /*  istanbul ignore next */
    if (!this.field.isInfo && this.field.isRequired) {
      this.eventsService.isPhysicalLayerVideos(true);
      players?.forEach((player) =>
        player.on('ended', () => {
          //enable submit button when video completes
          /*  istanbul ignore next */
          this.transacationFacadeService.disableSubmitButton(false);
          this.eventsService.isPhysicalLayerVideos(false);
        })
      );
    }
  }

  ngOnInit(): void {
    //disable submit button when video loads
    this.transacationFacadeService.disableSubmitButton(true);

    this.videoUrls = [];
    /*  istanbul ignore next */
    if (this.field && this.field.value) {
      this.field.value?.forEach((obj: any, ind: any) => {
        /*  istanbul ignore else */
        if (this.IsJsonString(obj)) {
          let jsonObj: any = JSON.parse(obj);
          if (jsonObj.contentUrl) {
            /*  istanbul ignore next */
            this.videoUrls[ind] = jsonObj?.contentUrl;
            this.src = {
              mimeType: jsonObj.mimeType,
              contentUrl: jsonObj.contentUrl,
              canDownload: false,
            };
          }
        } else {
          this.videoUrls[ind] = obj;
        }
      });
    }
    //Add video player

    /* istanbul ignore next */
    if (this.videoUrls?.length === 0) {
      this.transacationFacadeService.disableSubmitButton(false);
    }
  }

  openNewTab() {
    window.open(this.src.contentUrl, '_blank');
  }
  splitScreen() {
    this.splitscreenClicked = true;
  }
  onPreviewClick() {
    this.dialogService.open(FileUploadPreviewComponent, {
      height: '600px',
      width: '900px',
      data: this.src,
      panelClass: 'file-upload-preview-dialog',
    });
  }

  detectCarnivalScreen() {
    this.transacationFacadeService.carnivalScreen$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.iscarnivalscreen = res;
      }
    });
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnDestroy() {
    this.transacationFacadeService.disableSubmitButton(false);
    this.eventsService.isPhysicalLayerVideos(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
