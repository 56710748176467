import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelfOnboardingSignupComponent } from './self-onboarding-signup.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '../materail/material.module';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';

function playerFactory() {
  return player;
}
let components = [SelfOnboardingSignupComponent];
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatTabsModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatOptionModule,
    MatInputModule,
    MaterialModule,
    ApplicationPipesModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class SignUpModule {}
