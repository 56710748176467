import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
//@ts-ignore
import * as cornerstone from 'cornerstone-core';
//@ts-ignore
import * as cornerstoneTools from 'cornerstone-tools';
//@ts-ignore
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
//@ts-ignore
import * as cornerstoneMath from 'cornerstone-math';
//@ts-ignore
import * as Hammer from 'hammerjs';
//@ts-ignore
import * as dicomParser from 'dicom-parser';
import { getHostUrl } from '@common-services';
@Component({
  selector: 'app-nsl-dicom-operation',
  templateUrl: './nsl-dicom-operation.component.html',
  styleUrls: ['./nsl-dicom-operation.component.scss'],
})
export class NslDicomOperationComponent implements OnInit {
  @Input() config: any;
  @Output() initialized = new EventEmitter<any>();
  @ViewChild('dicomViewport') dicomViewport: ElementRef<HTMLInputElement>;

  isMultiFrame: boolean = false;
  playClipActive: boolean = false;
  stack: any;
  element: any;
  provider: IViewerProvider = {
    element: null,
    cornerstoneTools: cornerstoneTools,
    cornerstone: cornerstone,
  };

  constructor() {
    /* istanbul ignore next */
    cornerstoneTools.external.cornerstone = cornerstone;
    /* istanbul ignore next */
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    /* istanbul ignore next */
    cornerstoneTools.external.Hammer = Hammer;
    /* istanbul ignore next */
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone; // intialize WADO Image loader
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

    // configure codec web workers
    /* istanbul ignore next */
    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      webWorkerPath: './assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
      taskConfiguration: {
        decodeTask: {
          codecsPath: '../codecs/cornerstoneWADOImageLoaderCodecs.js',
        },
      },
    });

    console.log(this.dicomViewport?.nativeElement);
  }
  // ngAfterViewChecked() {
  //   console.log(this.dicomViewport?.nativeElement);
  // }
  ngAfterViewInit() {
    console.log(this.dicomViewport?.nativeElement);
    this.element = this.dicomViewport.nativeElement;
    // Tool define as cursor
    cornerstoneTools.init({
      showSVGCursors: true,
    });
    //prepare the element to display images
    cornerstone.enable(this.element);
    this.registerImageLoader();
  }
  ngOnInit() {
    console.log(this.config);
    if(this.config?.fileUrl && !this.config?.fileUrl?.includes('https://')){
      let url = getHostUrl();
      this.config.fileUrl = url + this.config?.fileUrl;
    }
    console.log(this.provider);
    console.log(this.dicomViewport?.nativeElement);
  }

  registerImageLoader() {
    /* istanbul ignore next */
    const schema = this.config.fileUrl.split(':')[0];
    cornerstone.registerImageLoader(schema, cornerstoneWADOImageLoader.wadouri.loadImage);
    this.load();
  }

  load() {
    /* istanbul ignore next */
    cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
      .load(this.config.fileUrl, cornerstoneWADOImageLoader.internal.xhrRequest)
      .then((dataSet: any) => {
        const numFrames = dataSet.intString('x00280008');
        /* istanbul ignore next */
        if (!numFrames) {
          this.loadImage(this.config.fileUrl);
        } else {
          this.isMultiFrame = true;
          this.loadWithMultiFrame(numFrames);
        }
      });
  }

  loadWithMultiFrame(numFrames: number) {
    const imageIds = [];
    const imageIdRoot = 'wadouri:' + this.config.fileUrl;

    /* istanbul ignore next */
    for (let i = 0; i < numFrames; i++) {
      const imageId = imageIdRoot + '?frame=' + i;
      imageIds.push(imageId);
    }

    this.stack = {
      currentImageIdIndex: 0,
      imageIds: imageIds,
    };

    // cornerstoneTools.setElementToolStateManager(this.element, cornerstoneTools.newStackSpecificToolStateManager);
    cornerstoneTools.addStackStateManager(this.element, ['stack']);
    cornerstoneTools.addToolState(this.element, 'stack', this.stack);
    this.loadImage(imageIds[0]);
  }

  loadImage(url: string) {
    /* istanbul ignore next */
    cornerstone.loadAndCacheImage(url).then((image: any) => {
      cornerstone.displayImage(this.element, image);
      this.initTools();
      cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(url);
      this.initializeProvider();
    });
  }

  private initTools() {
    /* istanbul ignore next */
    if (this.config.tools) {
      /* istanbul ignore next  */
      this.config?.tools?.forEach((tool: any) => {
        const apiTool = cornerstoneTools[`${tool.name}Tool`];
        cornerstoneTools.addTool(apiTool);
        cornerstoneTools[`setTool${tool.mode}`](tool.name, tool.options);
        if (tool.state) {
          cornerstoneTools.addToolState(this.element, tool.name, tool.state);
        }
      });
    }
  }

  private initializeProvider() {
    /* istanbul ignore next */
    if (this.isMultiFrame) {
      this.provider['playClip'] = {
        play: (frameRate: any) => {
          if (!this.playClipActive) {
            cornerstoneTools.playClip(this.element, frameRate);
            this.playClipActive = true;
          }
        },
        stop: () => {
          if (this.playClipActive) {
            cornerstoneTools.stopClip(this.element);
            this.playClipActive = false;
          }
        },
      };
    }
    this.provider.element = this.element;
    this.initialized.emit(this.provider);
  }
}

interface IViewerProvider {
  element: any;
  cornerstoneTools: any;
  cornerstone: any;
  playClip?: {
    play(frameRate: number): void;
    stop(): void;
  };
}
