import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { ChangeUnitList } from '../modal/change-unit-list';

export interface StoreState {
  changeUnitList: ChangeUnitList[];
}

@Injectable({
  providedIn: 'root',
})
export class ChangeUnitStoreService extends ObservableStore<StoreState> {
  constructor() {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  getStateData() {
    return this.getState();
  }
  setStateData(data: any) {
    this.setState({ changeUnitList: data }, ChangeUnitStoreActions.SetChangeUnits);
  }
}
export enum ChangeUnitStoreActions {
  SetChangeUnits = 'SET_ChangeUnits',
}
