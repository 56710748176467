import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { checkInputField, createConditionalArray, displayCUWithCondition, Layer, NodeGsiFacadeService, updateConditionalPotentiality } from '@common-services';
import { Subject } from 'rxjs';
import { NodeChangeUnitFacadeService } from '../node-change-unit-facade.service';
export interface DialogData {
  gsiData: Object;
  changeUnit: Object;
  condition: any;
}
@Component({
  selector: 'app-dcd-conditions',
  templateUrl: './dcd-conditions.component.html',
  styleUrls: ['./dcd-conditions.component.scss'],
})
export class DcdConditionsComponent implements OnInit {
  selectedEntity: any;
  changeUnitIndex: number = -1;
  changeUnit: any;
  changeUnitMap: Map<string, number> = new Map<string, number>();
  gsi: any;
  layerIndex: number = -1;
  selectedDCDCu: any;
  activeIndex: number = 0;
  selectedLayer: Layer;
  entityIndex: number = -1;
  attributeIndex: number = -1;
  selectedAttribute: any;
  operators: string[] = ['<', '>', '==', '!=', '<=', '>=', 'IN POTENTIALITY', 'IN REALITY', 'INCLUDES'];
  conditionalRightsOperators: string[] = ['<', '>', '=', '!='];
  showOperators: boolean = false;
  selectedOperator: string = '';
  formulaId: string = '';
  formulaIdWithId: string = '';
  contextualId: string = '';
  contextualIdWithId: string = '';
  currentAttributeType: any;
  recError: any;
  AlternatexpressionValueWithId: any;
  currentUielement: any;
  private ngUnsubscribe = new Subject();
  currentAlternateCU: any;
  //multi-condition variables starts
  conditionalPotentialityArray: any = [];
  selectedCondition: any;
  selectedInput: any;
  selectedEnt: any;
  selectedAttr: any;
  selectedPredicateIndex: any;
  queries = {
    sourceAttribute: '',
    sourceAttributeType: '',
    sourceAttributeuiElement: '',
    operator: '',
    targetAttribute: '',
    targetAttributeInput: '',
    predicate: '',
  };
  displayAttr: any;
  displayConditionFlag = true;
  valueParam: any;
  valueNameParam: any;
  displayCondition: string;
  layerIndexForAttr: number;
  entityIndexForAttr: number;
  attrIndexForAttr: number;
  nestedIndexForAttr: number;
  attrPath: any = '';
  condition: any;
  //multi-condition variables ends

  constructor(
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    public Dialog: MatDialog,
    public dialogRef: MatDialogRef<DcdConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData
  ) {}
  ngOnInit(): void {
    /* istanbul ignore next */
    this.changeUnit = this.dialog?.changeUnit;
    /* istanbul ignore next */
    this.gsi = this.dialog?.gsiData;
    /* istanbul ignore next */
    this.condition = this.dialog?.condition;
    this.displayConditionFlag = false;
    /* istanbul ignore next */
    this.selectedDCDCu = JSON?.parse(JSON?.stringify(this.changeUnit));
    this.createDCDConditionArray(this.condition, true);
  }

  closeDCDConditions() {
    this.Dialog.closeAll();
  }

  selectDCDEntityDetails() {
    /* istanbul ignore next */
    this.selectedEnt = this.selectedLayer?.participatingItems[this.entityIndex];
  }

  selectDCDAttribute(): void {
    /* istanbul ignore next */
    this.selectedEntity = this.selectedLayer?.participatingItems[this.entityIndex];
    this.attrPath = '';
    this.generateDCDSourceAttrPath(this.selectedEntity);
    /* istanbul ignore next */
    this.currentUielement = this.selectedAttribute?.attributeType?.uiElement?.uiElement;
    /* istanbul ignore next */
    this.currentAttributeType = this.selectedAttribute?.attributeType?.type;
    /* istanbul ignore next */
    const layer = this.selectedLayer?.type;
    /* istanbul ignore next */
    if (this.entityIndex != -1 && this.selectedEntity?.name && this.selectedAttribute?.name) {
      this.formulaId = this.generateDCDFormulaId(
        layer,
        this.entityIndex,
        this.selectedEntity?.name,
        this.selectedAttribute?.name
      );
    }

    //new implementation for muticondition
    if (this.selectedInput == undefined) {
      this.selectedInput = 'sourceAttribute';
    }
    if (this.selectedCondition == undefined) {
      this.selectedCondition = this.conditionalPotentialityArray.length - 1;
    }
    /* istanbul ignore next */
    if (this.formulaId) {
      this.conditionalPotentialityArray[this.selectedCondition][this.selectedInput] = this.formulaId;
    }
    /* istanbul ignore next */
    if (this.currentAttributeType) {
      this.conditionalPotentialityArray[this.selectedCondition]['sourceAttributeType'] = this.currentAttributeType;
    }
    /* istanbul ignore next */
    if (this.currentUielement) {
      this.conditionalPotentialityArray[this.selectedCondition]['sourceAttributeuiElement'] = this.currentUielement;
    }
    // if(this.conditionType!='AttributeConditionalPotentiality')
    this.resetDCDConditions();
  }

  createDCDCondition(): void {
    this.assignDCDCondition();
    this.resetDCDConditions();
    this.selectedEntity = undefined;
  }
  checkDCDTargetInput(event: any, query: any) {
    // query = {...query, sourceAttributeuiElement: this.currentUielement };
    return checkInputField(event, query);
  }

  resetDCDConditions(): void {
    this.selectedAttribute = undefined;
    this.selectedLayer = undefined;
    this.showOperators = false;
    this.entityIndex = -1;
    this.attributeIndex = -1;
    this.layerIndex = -1;
    // this.currentUielement = undefined;
    // this.currentAttributeType = undefined;
  }

  generateDCDFormulaId(
    layerType: string,
    entityIndex: any,
    entityName: string,
    attributeName: string,
    isForDependent?: boolean,
    isEntityname?: boolean
  ) {
    /* istanbul ignore next */
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    let indexPadding = this.getDCDIndexPadding(entityIndex + 1);
    let dot = '.';
    let layerShortCut = 'PL.SL' + indexPadding;
    /* istanbul ignore else */
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      layerShortCut = 'triggerCES.SL' + indexPadding;
    }
    let formulaId =
      isForDependent && !isEntityname ? layerShortCut + dot + 'EN' + entityName : layerShortCut + dot + entityName;
    /* istanbul ignore else */
    // if (attributeName) {
    //   formulaId = formulaId + dot + attributeName;
    // }
    formulaId = formulaId + dot + this.attrPath;
    return formulaId;
  }

  getDCDIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  changeEntityLayer(): void {
    this.selectedLayer = this.selectedDCDCu.layers[this.layerIndex];
  }

  /* istanbul ignore next */
  saveDCDConditions(): void {
    this.dialogRef?.close({
      condition: this.condition,
    });
  }

  /* Multi condition methods starts from here */
  createDCDConditionArray(condition: any, displayConditionString: any) {
    if (condition) {
      this.conditionalPotentialityArray = createConditionalArray(condition, [], displayConditionString);
      /*istanbul ignore else */
      if (displayConditionString && this.conditionalPotentialityArray.length > 0) {
        this.displayCondition = displayCUWithCondition(this.conditionalPotentialityArray);
      }
    } else {
      this.displayCondition = '';
      this.conditionalPotentialityArray = [];
      this.conditionalPotentialityArray.push(JSON.parse(JSON.stringify(this.queries)));
    }
  }

  //To select current input of alternate condition
  conditionDCDInput(item: string, index: any) {
    this.selectedInput = item;
    this.selectedPredicateIndex = index;
  }

  //adding new condition
  addNewDCDCondition() {
    this.displayConditionFlag = true;
    this.conditionalPotentialityArray.push(JSON.parse(JSON.stringify(this.queries)));
    this.selectedInput = 'sourceAttribute';
    /*istanbul ignore else */
    if (this.conditionalPotentialityArray.length > 1) {
      this.conditionalPotentialityArray[this.conditionalPotentialityArray.length - 2].predicate = '&&';
    }
  }

  //deleting a condition from multiple conditions
  deleteCondition(rowIndex: number) {
    this.conditionalPotentialityArray.splice(rowIndex, 1);
    /* istanbul ignore next */
    if (
      this.conditionalPotentialityArray[rowIndex - 1]?.predicate &&
      this.conditionalPotentialityArray.length == rowIndex
    ) {
      this.conditionalPotentialityArray[rowIndex - 1].predicate = '';
    }
  }
  assignDCDCondition() {
    this.condition = updateConditionalPotentiality(this.conditionalPotentialityArray);
    /* istanbul ignore next */
    if (this.condition) {
      this.displayCondition = displayCUWithCondition(this.conditionalPotentialityArray);
      this.displayConditionFlag = false;
    } else {
      this.condition = '';
    }
    this.displayConditionFlag = false;
  }

  removeCondition() {
    /*istanbul ignore else*/
    this.displayCondition = '';
    this.conditionalPotentialityArray = [];
  }

  editCondition() {
    /*istanbul ignore else*/
    if (this.conditionalPotentialityArray.length == 0) {
      this.addNewDCDCondition();
    } else {
      this.displayConditionFlag = true;
      this.createDCDConditionArray(this.condition, false);
    }
  }

  toggleDCDCondition() {
    this.displayConditionFlag = !this.displayConditionFlag;
    this.createDCDConditionArray(this.condition, !this.displayConditionFlag);
  }

  changeDCDTargetAttribute(entity: any, event: any) {
    /*istanbul ignore next*/
    this.setFalseDCDAll(entity, event?.value);
  }

  setFalseDCDAll(entity: any, attrIndex: any) {
    /*istanbul ignore next*/
    entity?.nslAttributes.forEach((ele: any, index: any) => {
      /*istanbul ignore next*/
      ele.isSelected = false;
      /*istanbul ignore next*/
      if (ele.id === entity?.nslAttributes[attrIndex].id) {
        ele.isSelected = true;
        this.selectedAttribute = ele;
      }
    });
  }

  generateDCDSourceAttrPath(entity: any) {
    /* istanbul ignore next */
    entity?.nslAttributes?.forEach((ele: any, index: any) => {
      /*istanbul ignore next*/
      if (ele?.isSelected) {
        /*istanbul ignore next*/
        if (this.attrPath == '') {
          /* istanbul ignore next */
          this.attrPath += ele?.name;
        } else {
          /* istanbul ignore next */
          this.attrPath += '.' + ele?.name;
        }
      }
      /*istanbul ignore next*/
      if (ele?.generalEntity && ele?.isSelected) {
        this.generateDCDSourceAttrPath(ele?.generalEntity);
      }
    });
  }
}
