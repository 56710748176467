import { KeyValue, formatDate } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AlertService,
  ChangeUnitFacadeService,
  EntityWidget,
  EventsFacadeService,
  GsiFacadeService,
  LibraryFacadeService,
  LoaderService,
  TransactionFacadeService,
  TranslatorService,
  displayUSAMobileFormat,
  getFileNameFromImgUrl,
  parseLocal,
  setPropertyInDivLayout,
  findLocale,
  TransactionEndpointService,
} from '@common-services';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// import { paginationDetails } from '@app/solution-dictionary/models/notepad.model';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment';
import { displayMexicoMobileFormat } from '@common-services';
import { tableDataPayload } from './table.DataPayload';
import { FileUploadPreviewComponent } from 'ui-controls';
import { random } from 'lodash';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('myDialog') dialogTemplate: any;
  tempEntityList: any;
  @Input() entityList: any;
  @Input() gsi: any;
  @Input() showNext: boolean;
  @Input() showSubmit: boolean;
  @Input() currentCu: any;
  @Input() isInfo: any;
  @Input() isReadOnly: any;
  @Input() tempInfoEntList: any;
  @Input() layoutConfigurations: any;
  @Input() isTransactionActive: boolean;

  @Output() submitEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataEmitForTable: EventEmitter<any> = new EventEmitter<any>();
  @Output() addMultiEntities: EventEmitter<any> = new EventEmitter<any>();
  @Input() layoutInfo: any;
  @Input() selectedCard: any;
  @Input() indexChange: number;
  entitySelectionMap: Map<any, any>;
  keysLength = (param: any) => {
    return Object.keys(param).length - 1;
  };

  unSubscribe = new Subject();
  unsubscribe$: Subject<any> = new Subject<any>();
  submitted = false;
  attributesArray: Array<any> = [];
  rowNumberChanged: any = { rows: 0, first: 0 };
  form: any;
  tempForm: any;
  isFrst = true;
  accordianIndex: any = 0;
  LastUnhiddenEntIndex: number = -1;
  firstUnhiddenEntIndex: number = -1;
  isSubmitButtonDisable = false;
  //These data requires in sub-transactional class
  selectedGsi: any;
  gsiMasterId: any;
  gsiName: any;
  triggerCuIndex: any;
  facetedData: any;
  masterCuId: any;
  cuName: any;
  finalFormat: any;
  selectedRowTxnRecordIds: any = [];
  iscarnivalscreen: boolean = false;
  sentenceStartIndex: number;
  showEntityName: boolean;
  status: boolean = false;
  showwidget: boolean = false;
  showDownloadButtonForEntity: boolean = false;
  selectedImages: any = [];
  temptableEntityList: any = [];
  isSelection: boolean = false;
  filesArray: any = [];
  addButtonShape: string;
  removeButtonShape: string;
  screenWidth: number;
  get value() {
    return this.form.value;
  }
  labels: any;
  currentEntityIndex = 0;
  selectedCards: any = [];
  selectedTransactions: any = [];
  addEntityFromLib: any = '';
  tableEntityName: string = '';
  selectedTableRows: any = [];
  expandSubCU = false;
  subtransactionalCU: any;
  formatAttribute: any;
  subtransactionCUCondition: string;
  subtransactionCUConditionId: string;
  selectedAttr = false;
  filedData: any = [];
  targetCUContextualId: any;
  viewHistoryStatus: boolean = false;
  tablecardAttrCount: any = 0;
  submitButtonValidations: any;
  paginationDetails: any;
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  entityNames: any[] = [];
  entityWidgets: Map<string, EntityWidget>;
  entityWidgetData: { [key: string]: any };
  entityDisplayName: Map<string, string> = new Map<string, string>();
  EwaValidator: FormControl;
  templateType: any = '';
  onlyInfoLayer: any;
  isHideMulti: boolean = false;
  isFacetSearchCu: boolean = false;
  facetPosition: string = 'side';
  isTableOrCard: boolean = false;
  filterData: any;
  selectedIndex: any = 0;
  integNestedEnt = ['NSL_Geolocation'];
  integNestedEntMap = { NSL_Geolocation: 'location' };
  integControlVal: any;
  uiElementDemap: any = { location: 'text', date: 'text' };
  checkmultipleFileDownloadIndex: number[] = [];
  recordVideoDisable: any;
  recordAudioDisable: any;
  reqAttr: any;
  layer: string = 'physical';
  wayPointsData: any = [];
  isSubmit = false;
  selectedEditableRow: number = -1;
  SfList: any = [
    'quantification',
    'probability',
    'correlationCoefficient',
    'cuclock',
    'distance',
    'variability',
    'similarityCoefficient',
    'ratioAnalysis',
    'simpleLinearRegression',
    'weightedAverage',
    'multiLinearRegression',
    'percentile',
    'quartile',
    'movingAverage',
    'quantile',
    'meanSquaredError',
  ];
  constructor(
    private fb: FormBuilder,
    private translator: TranslatorService,
    private eventsService: EventsFacadeService,
    private ref: ChangeDetectorRef,
    private alertService: AlertService,
    private transactionFacadeService: TransactionFacadeService,
    private transactionEndpointService: TransactionEndpointService,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private gsiFacade: GsiFacadeService,
    public dialog: MatDialog,
    private loader: LoaderService,
    private router: Router,
    private libraryfacadeservice: LibraryFacadeService,
    private elRef: ElementRef,
    private transacationFacadeService: TransactionFacadeService,
    private httpClient: HttpClient,
    public dialogService: MatDialog
  ) {
    this.detectLanguageChange();
    this.detectFormSubmitEvent();
    this.getGsiDetails();
    this.getCuDetails();
    this.detectEventGsiData();
    this.detectCarnivalScreen();
    this.detectSubmitting();
    this.getCurrentSelectedImages();
    this.getAttributeSubmitEvent();
    this.getScreenSize('');
    setInterval(() => {
      // require view to be updated
      this.ref?.detectChanges();
    }, 1000);
    this.templateType = sessionStorage.getItem('template');
    /* istanbul ignore next */
    this.showEntityName =
      localStorage.getItem('TenantName') &&
      (localStorage.getItem('TenantName') === 'grls' ||
        localStorage.getItem('TenantName') === 'grlstest' ||
        localStorage.getItem('TenantName') === 'grlsguyton')
        ? false
        : true;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  /* istanbul ignore next */
  onKeyUp(event: any, key: any) {
    if (this.form?.value[key]?.value)
      this.form.value[key].value = event?.target?.value;
    if (event?.target?.value !== '') {
      this.transacationFacadeService?.disableSubmitButtonEwa(false);
    } else {
      this.transacationFacadeService?.disableSubmitButtonEwa(true);
    }
    //this.group.controls[this.field?.attribute?.name].setValue(this.field.value);
  }
  prepareWidgetsData() {
    this.entityWidgets = new Map<string, EntityWidget>();

    this.iterateLayer();
    this.setEntityWidgetData();
  }
  setEntityWidgetData() {
    if (this.entityWidgets.size > 0) {
      this.entityWidgetData = {};
      this.entityWidgets.forEach((value: EntityWidget, key: string) => {
        this.entityWidgetData[key] = value;
      });
      // this.entityWidgetData = Object.fromEntries(this.entityWidgets);
      this.entityNames = Object.keys(this.entityWidgetData);
      if (this.entityWidgetData[this.entityNames[0]].data.length > 0)
        this.showwidget = true;
      else {
        this.alertService.showToaster(
          'Entity doesnt have ',
          'No Data in the Entity',
          'info'
        );
      }
    } else {
      this.showwidget = false;
    }
  }

  iterateLayer() {
    let tempentitylist3 = this.deepColneObject(this.tempEntityList);
    tempentitylist3?.forEach((entity: any) => {
      if (entity?.configuration?.type == 'widgets') {
        let attributesconfig =
          entity?.configuration?.setting?.setting?.attributes;
        let name = entity.name.split('$')[0];
        let displayName = entity?.displayName;
        name = name + '$' + entity.slot?.toString();
        if (this.entityWidgets.has(name)) {
          let widget: EntityWidget = this.entityWidgets.get(name);
          let value = {};
          this.iterateEntity(entity, attributesconfig, value);
          widget.data.push(value);
        } else {
          let widget: EntityWidget =
            entity.configuration?.setting?.setting?.selectedWidget;
          let value: any = {};
          this.iterateEntity(entity, attributesconfig, value);
          widget.name = entity.name + '$' + entity.slot?.toString();
          widget.data = [];
          if (value?.value || value?.x || value?.y) widget.data.push(value);

          this.entityWidgets.set(name, widget);
          this.entityDisplayName.set(name, displayName);
        }
      }
    });
  }

  iterateEntity(entity: any, attributesconfig: any, value: any) {
    entity.nslAttributes.forEach((attribute: any) => {
      if (Array.isArray(attributesconfig)) {
        attributesconfig?.forEach((attr: any) => {
          if (
            attr[attribute.name] == 'value' ||
            attr[attribute.name] == 'svalue'
          ) {
            value[attr[attribute.name]] = parseFloat(attribute.values[0]);
          } else {
            value[attr[attribute.name]] = attribute.values[0];
          }
        });
      } else if (attributesconfig) {
        if (
          attributesconfig[attribute.name] == 'value' ||
          attributesconfig[attribute.name] == 'svalue'
        ) {
          value[attributesconfig[attribute.name]] = parseFloat(
            attribute.values[0]
          );
        } else {
          value[attributesconfig[attribute.name]] = attribute.values[0];
        }
      }
    });
  }
  openPopup(templateRef?: TemplateRef<any>) {
    this.dialog.open(templateRef, { width: '700px' });
  }

  onCancelClick() {
    this.dialog.closeAll();
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }

  detectLayerType() {
    this.transactionFacadeService.layerType$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          if (
            (res?.physical && !this.isInfo) ||
            (!res?.physical && res?.information && this.isInfo)
          ) {
            this.openDialog(this.dialogTemplate);
          }
        }
      });
  }

  detectFormSubmitEvent() {
    this.transactionFacadeService.dynamicFormSubmit$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (this.isInfo) {
          this.attributesArray = [];
          this.checkEntitieswithCardandTable(true);
          /* istanbul ignore next */
          if (!this.transacationFacadeService.eventGsiData) {
            this.formValueChange.emit({
              data: this.attributesArray,
              isInfolayer: false,
            });
          }
        }
      });
  }

  bindValidations(
    validations: any,
    uielement?: any,
    isOptional?: boolean,
    isInPotentiality?: any,
    isNegative?: boolean
  ) {
    /* istanbul ignore next */
    if (
      validations?.length > 0 &&
      uielement != 'datetime' &&
      !isOptional &&
      !isInPotentiality
    ) {
      const validList: any = [];
      /* istanbul ignore next */
      validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      if (!isOptional)
        return Validators.compose([Validators.required, ...validList]);
      else return Validators.compose(validList);
    } else if (
      validations?.length == 0 &&
      uielement != 'datetime' &&
      !isOptional &&
      !isNegative &&
      !isInPotentiality &&
      uielement != 'scormVideo' &&
      uielement != 'scormProgress' &&
      uielement != 'autoIdentifier' &&
      uielement != 'year' &&
      uielement != 'yearMonth' &&
      uielement != 'rating' &&
      uielement != 'currentdate' &&
      uielement != 'audioRecording' &&
      uielement != 'videoRecording' &&
      uielement != 'capturingImage' &&
      uielement != 'timer' &&
      uielement != 'duration'
    ) {
      return Validators.required;
    }
    return null;
  }
  fontFamily: any;
  ngOnInit(): void {
    if (this.showMatTabForEntity()) {
      let x = -1;
      /* istanbul ignore next */
      if (
        this.layoutConfigurations?.templateName ===
          'studioverse-layout-phy-8-4-12-info-12' ||
        this.layoutConfigurations?.templateName ===
          'studioverse-layout-phy-8-4-12' ||
        this.layoutConfigurations?.templateName ===
          'layout-vrim-info-7-phy-5' ||
        this.layoutConfigurations?.templateName === 'layout-enterprise-RCP-phy'
      ) {
        x = 0;
      } else if (
        this.layoutConfigurations?.templateName ===
          'layout-enterprise-CRM-phy' ||
        this.layoutConfigurations?.templateName ===
          'layout-education-platform-video-learning'
      ) {
        x = 1;
      } else {
        x = -1;
      }
      this.nextAccordian(x);
    }
    this.entitySelectionMap = new Map<any, any>();
    this.fontFamily = this.gsi?.changeUnits?.design?.font?.family;
    // this.button = this.labels.SUBMIT;
    this.detectLayerType();
    this.EwaValidator = new FormControl('', [Validators.required]);
    // this.getAttributeSubmitEvent();
    // if (this.EwaValidator?.invalid && ) {
    //   this.transacationFacadeService.disableSubmitButtonEwa(true);
    // }
    // if(this.form.value?.[this.control]configuration) {
    //   let confg = JSON.parse(this.form.value?.configuration);
    //   if(confg?.hideMulti) this.isHideMulti = confg?.hideMulti;
    // }
    /* istanbul ignore next */
    this.eventsService.storeLayoutConfig(this.layoutConfigurations);
    if (this.isInfo) this.layer = 'information';
    this.getFormValueLength();
    this.checkMultiFileDownload();
  }

  //For disabling submit button
  detectRecordVideoDisable() {
    /* istanbul ignore next */
    this.transactionFacadeService.recordVideoDisable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.recordVideoDisable = res;
      });
  }
  //For disabling submit button
  detectRecordAudioDisable() {
    this.transactionFacadeService.recordAudioDisable$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.recordAudioDisable = res;
      });
  }

  /* Remove multiple entities with same slot number and pushed those entitie's nslattributes into one entity*/

  prepareTableDataForMultipleEntyties(data: any) {
    let ent: any;
    let entData: any = [];
    /* istanbul ignore next */
    for (let i = 0; i < data?.length; i++) {
      if (i > 0) {
        /* istanbul ignore next */
        if (
          (data[i]?.configuration && data[i]?.configuration?.type == 'table') ||
          data[i]?.configuration?.type == 'card' ||
          data[i]?.configuration?.type == 'cardWithTemplate' ||
          data[i]?.configuration?.type == 'widgets' ||
          data[i]?.configuration?.type == 'tableWithLabels' ||
          (data[i]?.configuration?.type == 'verticalTable' &&
            data[i]?.configuration?.configurationData?.advanceTableFeatures
              ?.multiRowsVerticalTable)
        ) {
          const index = entData.findIndex((entity: any) => {
            return entity.slot == data[i].slot;
          });
          if (index == -1) {
            data[i]['tablecardAttrCount'] = data[i]?.nslAttributes?.length;
            entData.push(data[i]);
          } else {
            data[i]['tablecardAttrCount'] = data[i]?.nslAttributes?.length;
            entData[index].nslAttributes = entData[
              index
            ]?.nslAttributes?.concat(data[i].nslAttributes);
          }
        } else {
          data[i]['tablecardAttrCount'] = data[i]?.nslAttributes?.length;
          entData.push(data[i]);
          data[i]['tablecardAttrCount'] = data[i]?.nslAttributes?.length;
          this.checkNestedEntityTable(data[i]);
        }
      } else {
        data[i]['tablecardAttrCount'] = data[i]?.nslAttributes?.length;
        entData.push(data[i]);
        data[i]['tablecardAttrCount'] = data[i]?.nslAttributes?.length;
        this.checkNestedEntityTable(entData[i]);
      }
    }
    return entData;
  }

  //nested entity table config
  checkNestedEntityTable(entData: any) {
    /* istanbul ignore next */
    if (
      (entData?.type == 'entity' &&
        entData.configuration &&
        entData.configuration?.type !== 'table') ||
      entData.configuration?.type !== 'card' ||
      entData.configuration?.type !== 'cardWithTemplate' ||
      entData?.configuration?.type == 'widgets'
    ) {
      let nestedEntity: any = [];
      let indexes: any = [];
      /* istanbul ignore next */
      entData?.nslAttributes?.forEach((attr: any, ind: any) => {
        if (attr?.type == 'entity') {
          if (
            (attr.configuration && attr.configuration?.type == 'table') ||
            attr.configuration?.type == 'card' ||
            attr.configuration?.type == 'cardWithTemplate' ||
            attr?.configuration?.type == 'widgets'
          ) {
            if (nestedEntity.length == 0) {
              attr['tablecardAttrCount'] = attr?.nslAttributes?.length;
              nestedEntity.push(attr);
            } else {
              const index = nestedEntity.findIndex((entity: any) => {
                return entity.name == attr.name;
              });

              if (index == -1) {
                attr['tablecardAttrCount'] = attr?.nslAttributes?.length;
                nestedEntity.push(attr);
              } else {
                attr['tablecardAttrCount'] = attr?.nslAttributes?.length;
                nestedEntity[index].nslAttributes = nestedEntity[
                  index
                ].nslAttributes.concat(attr.nslAttributes);
                indexes.push(ind);
              }
            }
          } else {
            this.checkNestedEntityTable(attr);
          }
        }
      });
      /* istanbul ignore next */
      if (indexes.length > 0) {
        /* istanbul ignore next */
        indexes?.forEach((ind: any) => {
          if (entData?.nslAttributes[ind]) {
            delete entData.nslAttributes[ind];
          }
        });
        entData.nslAttributes = entData?.nslAttributes.filter(
          (x: any) => x != undefined
        );
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(
      'indexChange from dynamiuc form for defi--------------',
      this.indexChange
    );
    if (changes.indexChange) {
      this.selectedIndex = this.indexChange;
    }
    this.isFrst = true;
    this.isSubmit = false;
    /*istanbul ignore next*/
    if (changes?.currentCu || changes?.entityList) {
      /*istanbul ignore next*/
      if (
        changes?.currentCu?.currentValue?.id !==
        changes?.currentCu?.previousValue?.id
      ) {
        this.selectedIndex =
          this.layoutConfigurations?.templateName ===
            'studioverse-layout-phy-8-4-12-info-12' ||
          this.layoutConfigurations?.templateName ===
            'studioverse-layout-phy-8-4-12'
            ? 1
            : 0;
        this.selectedCards = [];
        this.selectedTableRows = [];
        this.selectedRowTxnRecordIds = [];
        this.selectedTransactions = [];
        this.transactionFacadeService.selectedCradsArray = {};
      }
    }
    if (
      Object.keys(this.transactionFacadeService.selectedCradsArray).length == 0
    ) {
      this.selectedCards = [];
    }
    this.transactionFacadeService.selectedCardsRecordList = this.selectedCards;
    this.transactionFacadeService.selectedTableRecordList = this.selectedTransactions;
    if (Object.keys(this.transactionFacadeService.paginationDetails).length) {
      this.paginationDetails = this.transactionFacadeService.paginationDetails;
    }
    // this.currentEntityIndex = 0; // removing this line for events purpose
    /*istanbul ignore next*/
    if (changes?.entityList) {
      this.createFormData(true);
      this.prepareWidgetsData();
    }
    /*istanbul ignore next*/
    this.onlyInfoLayer = this.currentCu?.layers?.findIndex(
      (layer: any) => layer?.type === 'physical'
    );
    this.isFacetSearchCu =
      this.currentCu.reservedCUType == 'NSL_Template_FacetedSearch' ||
      this.currentCu.isFaceted
        ? true
        : false;
    this.filterData = JSON.parse(JSON.stringify(this.currentCu));
    /*istanbul ignore next*/
    if (this.tempInfoEntList) {
      this.facetedData = this.paginationDetails?.pageSize
        ? this.tempInfoEntList?.find((ent: any) => ent.slot == 2)
        : this.tempInfoEntList?.find((ent: any) => ent.slot == 1);
    }

    if (this.isFacetSearchCu) {
      let idx = this.paginationDetails?.pageSize ? 1 : 0;
      this.isTableOrCard =
        this.entityList[idx].configuration &&
        (this.entityList[idx].configuration.type == 'table' ||
          this.entityList[idx].configuration.type == 'card' ||
          this.entityList[idx].configuration.type == 'cardWithTemplate' ||
          this.entityList[idx].configuration.type == 'tableWithLabels' ||
          this.entityList[idx].configuration.type == 'verticalTable');

      this.facetPosition = this.entityList[idx]?.configuration?.advancedFeatures
        ?.searchFields
        ? this.entityList[idx].configuration.advancedFeatures.searchFields
        : 'side';
    }

    /*istanbul ignore next*/
    if (changes?.selectedCard && this.selectedCard?.length > 0) {
      this.selectedCards = this.selectedCard;
    }
    if (this.showMatTabForEntity()) {
      this.checkForHiddenEnt();
    }
  }

  checkForHiddenEnt() {
    if (
      this.entityList[this.selectedIndex] &&
      this.entityList[this.selectedIndex]?.isHidden
    ) {
      while (
        this.entityList[this.selectedIndex] &&
        this.entityList[this.selectedIndex]?.isHidden
      ) {
        this.selectedIndex = this.selectedIndex + 1;
      }
    }
  }

  renameEntity(data: any) {
    /* istanbul ignore next */
    data?.forEach((element: any) => {
      element.name = element.name.split('$')[0];
      element.nslAttributes?.forEach((el1: any) => {
        if (el1?.type == 'entity') {
          this.renameNestedentity(el1);
        }
      });
    });

    return data;
  }

  renameNestedentity(element: any) {
    /* istanbul ignore next */
    element.name = element?.name?.split('$')[0];
    /* istanbul ignore next */
    element?.nslAttributes?.forEach((ele: any) => {
      if (ele?.type == 'entity') {
        this.renameNestedentity(ele);
      }
    });
  }
  lengthofForm: any;
  createFormData(isDatechanged: any) {
    let group = {};
    this.sentenceStartIndex = 0;
    if (this.entityList) {
      if (this.entityList && this.entityList.length > 0) {
        this.entityList = this.renameEntity(this.entityList);
      }
      /* istanbul ignore next */
      if (isDatechanged) {
        /* istanbul ignore next */
        if (this.entityList?.length > 0) {
          // this.updateEntitieswithEntIndex(this.tempEntityList);
          this.updateEntitieswithEntIndex(this.entityList);
          this.tempEntityList = JSON.parse(JSON.stringify(this.entityList));
          this.updateEntitieswithMultiEntityCount(this.entityList);
          this.dataEmitForTable.emit(this.tempEntityList);
        }
      } else {
        this.entityAttributesOrder(this.entityList);
      }
      this.entityList = this.prepareTableDataForMultipleEntyties(
        this.entityList
      );
    }
    this.entityList?.forEach((input_template: any, index: any) => {
      let recordId;
      let nestedEntityId;
      let nestedEntityName;
      let alternativeChangeDriver;
      let isOptional;
      let isNegative;
      let isHidden;
      let tablecardAttrCount = 0;
      // let ent_index = input_template.slot;
      // input_template['ent_index'] = ent_index;
      /* istanbul ignore next */
      let configurationType = input_template?.configuration?.type
        ? input_template?.configuration?.type
        : 'listType';
      // optional change drivers related flags starts //
      /* istanbul ignore next */
      if (input_template?.alternativeChangeDriver) {
        alternativeChangeDriver = input_template.alternativeChangeDriver;
      }
      /* istanbul ignore next */
      if (input_template?.isOptional) {
        isOptional = input_template.isOptional;
      }
      /* istanbul ignore next */
      if (input_template?.isNegative) {
        isNegative = input_template.isNegative;
      }
      /* istanbul ignore next */
      if (input_template?.tablecardAttrCount) {
        tablecardAttrCount = input_template.tablecardAttrCount;
      }
      /* istanbul ignore next */
      if (input_template?.isHidden) {
        isHidden = input_template.isHidden;
      }
      // optional change drivers related flags ends //
      /* istanbul ignore next */
      if (input_template?.recordId) {
        recordId = input_template.recordId;
      }
      /* istanbul ignore next */
      if (input_template?.nestedEntityId) {
        nestedEntityId = input_template.nestedEntityId;
      }
      /* istanbul ignore next */
      if (input_template?.nestedEntityName) {
        nestedEntityName = input_template.nestedEntityName;
      }
      /* istanbul ignore next */
      if (input_template?.type === 'entity') {
        this.nestedEntitiesFormgroup(
          input_template?.nslAttributes,
          group,
          input_template?.name,
          input_template?.displayName,
          input_template?.isMulti,
          input_template?.id,
          input_template?.slot,
          index,
          input_template?.design,
          recordId,
          alternativeChangeDriver,
          isNegative,
          isHidden,
          isOptional,
          nestedEntityId,
          nestedEntityName,
          configurationType,
          input_template.configuration,
          tablecardAttrCount,
          input_template?.txnRecordId,
          input_template?.ent_index,
          input_template?.isInPotentiality,
          input_template?.isPlaceholder,
          input_template?.attributeType,
          input_template?.masterId,
          input_template?.ent_multiCount
        );
      }
    });
    this.form = this.fb.group(group);
    this.lengthofForm = Object.keys(this.form.controls).length;
    let copyForm = this.form.getRawValue();
    if (this.isFrst && Object.keys(copyForm).length > 0) {
      this.tempForm = this.fb.group(copyForm);
      this.isFrst = false;
    }
    this.form.valueChanges
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        if (!this.isInfo)
          this.eventsService.formData = this.form?.getRawValue();
        if (!this.form.pristine) {
          this.setEntityListAndTriggerFormChange(data);
        }
      });
    this.setEntityListAndTriggerFormChange(this.form.value);
    /* istanbul ignore next */
    if (!this.isInfo) this.eventsService.formData = this.form?.getRawValue();
    this.emitFormValueonEventTrigger();
    if (this.isReadOnly) {
      this.form.disable();
    }
    this.entityList?.forEach((entity: any) => {
      if (entity?.configuration?.downloadAll) {
        this.showDownloadButtonForEntity = entity?.configuration?.downloadAll;
      }
    });
    if (this.showMatTabForEntity()) {
      this.getLastUnhiddenEntityIndex();
      this.getFirstUnhiddenEntityIndex();
    }
  }

  setEntityListAndTriggerFormChange(data: any) {
    let changedFormValues = this.getFormDataFormat(data, {});
    this.setValidations();
    //this.removeattributeOrder(changedFormValues);
    /* istanbul ignore next */
    let formControlData = Object.entries(
      this.form?.controls
    )?.map(([type, value]) => ({ type, value }));
    /* istanbul ignore next */
    for (let ent of formControlData) {
      /* istanbul ignore next */
      let str = ent?.type;
      str = str?.split('$')[0];
      let val: any = ent?.value;
      this.entitySelectionMap?.set(str, val?.status == 'VALID' ? true : false);
    }
    /* istanbul ignore next */
    this.formValueChange.emit({
      data: changedFormValues,
      details: this.submitButtonValidations,
      cuBtnValidations: this.form?.status == 'VALID' ? true : false,
      entitySelectionMap: this.entitySelectionMap,
    });
    this.entityList = JSON.parse(JSON.stringify(changedFormValues));
    this.setStyles();
  }

  setStyles() {
    /* istanbul ignore next */
    if (this.entityList?.[0]?.design?.LayerFont?.color) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--entity-font-color',
        this.entityList?.[0]?.design?.LayerFont?.color
      );
    }
    /* istanbul ignore next */
    if (this.entityList?.[0]?.design?.LayerFont?.fontSize) {
      /* istanbul ignore next */
      setPropertyInDivLayout(
        this.elRef,
        '--entity-font-fontSize',
        this.entityList?.[0]?.design?.LayerFont?.fontSize + 'px'
      );
    }
  }

  dateChangedEvent(event: any) {
    if (event) {
      this.setEntityListAndTriggerFormChange(this.form.value);
    } else {
      this.createFormData(false);
    }
  }

  detectCarnivalScreen() {
    this.transactionFacadeService.carnivalScreen$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.iscarnivalscreen = res;
        }
      });
  }
  onFormChange(event: any) {
    this.eventsService.entityList = { ...this.entityList };
  }

  updateEntitieswithMultiEntityCount(entityList: any) {
    let multientity: Map<string, any> = new Map<string, any>();
    entityList.forEach((entity: any) => {
      if (
        entity?.isMulti &&
        entity?.configuration &&
        entity?.configuration?.showEntityCount
      ) {
        if (!multientity.has(entity.name)) {
          multientity.set(entity.name, 1);
        } else {
          multientity.set(entity.name, multientity.get(entity.name) + 1);
        }
        entity['ent_multiCount'] = multientity.get(entity.name);
      }
    });
  }

  addDynamicAttributes(event: any) {
    //let changedFormValues = this.getFormDataFormat(event.value, {});
    this.checkEntitieswithCardandTable(false);
    this.entityAttributesOrder(this.attributesArray);
    this.setValidations();
    this.formValueChange.emit({
      data: this.attributesArray,
      details: this.submitButtonValidations,
    });
    this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
    this.createFormData(true);
  }

  nestedEntitiesAdded(event: any) {
    let nestedTableUpload = true;
    /* istanbul ignore next */
    Object.entries(event)?.forEach(([key1, value1], index) => {
      if (key1 == 'slot') nestedTableUpload = false;
    });
    if (!nestedTableUpload) {
      if (!event?.slot) {
        this.form.updateValueAndValidity();
        this.checkEntitieswithCardandTable(false);
        this.entityAttributesOrder(this.attributesArray);
        this.setValidations();
        this.formValueChange.emit({
          data: this.attributesArray,
          details: this.submitButtonValidations,
        });
        this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
        this.createFormData(true);
      } else {
        this.addMultiEntities.emit(event);
      }
    } else {
      this.form.updateValueAndValidity();
      this.checkEntitieswithCardandTable(false);
      this.entityAttributesOrder(this.attributesArray);
      this.setValidations();
      this.formValueChange.emit({
        data: this.attributesArray,
        details: this.submitButtonValidations,
      });
      this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
      this.createFormData(true);
    }
  }

  /**set the original order */
  entityAttributesOrder(changedFormValues: any) {
    changedFormValues.sort((x: any, y: any) => {
      return x.attr_order_num - y.attr_order_num;
    });
    /* istanbul ignore next */
    changedFormValues?.forEach((ent: any) => {
      ent.nslAttributes.sort((x: any, y: any) => {
        return x.attr_order_num - y.attr_order_num;
      });
      /* istanbul ignore next */
      ent.nslAttributes?.forEach((attr: any) => {
        if (attr?.type == 'entity') {
          this.nestedEntityAttributesOrder(attr);
        }
      });
    });
  }

  /**set the original order  for nested*/
  nestedEntityAttributesOrder(ent: any) {
    ent.nslAttributes.sort((x: any, y: any) => {
      return x.attr_order_num - y.attr_order_num;
    });
    /* istanbul ignore next */
    ent.nslAttributes?.forEach((attribute: any) => {
      if (attribute?.type == 'entity') {
        this.nestedEntityAttributesOrder(attribute);
      }
    });
  }

  nestedEntitiesFormgroup(
    nslAttributes: any,
    group: any,
    name: any,
    displayName: any,
    isMulti: any,
    id: any,
    slot: any,
    ind: any,
    design: any,
    recordId: any,
    alternativeChangeDriver: any,
    isNegative: any,
    isHidden: any,
    isOptional: any,
    nestedEntityId: any,
    nestedEntityName: any,
    configurationType: any,
    configuration: any,
    tablecardAttrCount: any,
    txnRecordId: any,
    ent_index?: any,
    isInPotentiality?: boolean,
    isPlaceholder?: boolean,
    attributeType?: any,
    masterId?: any,
    ent_multiCount?: any
  ) {
    if (!isMulti) isMulti = false;
    if (!isInPotentiality) isInPotentiality = false;
    if (!isHidden) isHidden = false;
    if (!isNegative) isNegative = false;
    if (!isOptional) isOptional = false;
    if (!isPlaceholder) isPlaceholder = false; //isdisabled , is template missing not needed
    let nestedGroup = {};
    let validations: any;
    /* istanbul ignore next */
    nslAttributes?.forEach((entity_template: any, index: any) => {
      let recordId;
      let alternativeChangeDriver;
      let isOptional;
      let isNegative;
      let isHidden;

      if (!entity_template?.isDisabled) entity_template.isDisabled = false;
      if (!entity_template?.isHidden) entity_template.isHidden = false;
      if (!entity_template?.isInPotentiality)
        entity_template.isInPotentiality = false;
      if (!entity_template?.isMulti) entity_template.isMulti = false;
      if (!entity_template?.isNegative) entity_template.isNegative = false;
      if (!entity_template?.isOptional) entity_template.isOptional = false;
      if (!entity_template?.isPlaceholder)
        entity_template.isPlaceholder = false;
      if (!entity_template?.triggerConditionalPotentiality)
        entity_template.triggerConditionalPotentiality = false;
      if (!entity_template?.canDownload) entity_template.canDownload = false;
      if (!entity_template?.constraints) entity_template.constraints = [];
      if (!entity_template?.isTemplate) entity_template.isTemplate = false;

      // let temp_ent_index = ent_index;
      /* istanbul ignore next */
      let configurationType = entity_template?.configuration?.type
        ? entity_template?.configuration?.type
        : 'listType';
      if (
        entity_template?.type == 'entity' &&
        entity_template?.nestedEntityName &&
        entity_template?.nestedEntityName == 'NSL_Geolocation'
      ) {
        entity_template?.nslAttributes?.forEach((attr: any, i: number) => {
          attr.nestedEntityName = entity_template?.nestedEntityName;
          if (attr?.values && attr?.values?.length != 0) {
            if (attr.name == 'latitude') this.reqAttr = attr;
            else {
              this.reqAttr['values'] = [
                JSON.stringify({
                  latitude: JSON.parse(this.reqAttr.values?.[0]),
                  longitude: JSON.parse(attr.values?.[0]),
                }),
              ];
            }
          }
        });
      }
      if (
        entity_template?.type == 'attribute' &&
        entity_template?.nestedEntityName &&
        this.integNestedEnt?.includes(entity_template?.nestedEntityName)
      )
        entity_template.uiElement = this.integNestedEntMap[
          entity_template?.nestedEntityName
        ];
      /* istanbul ignore next */
      if (entity_template?.recordId) {
        recordId = entity_template.recordId;
      }
      // optional change drivers related flags starts //
      /* istanbul ignore next */
      if (entity_template?.alternativeChangeDriver) {
        recordId = entity_template.alternativeChangeDriver;
      }
      /* istanbul ignore next */
      if (entity_template?.isOptional) {
        isOptional = entity_template.isOptional;
      }
      /* istanbul ignore next */
      if (entity_template?.isNegative) {
        isNegative = entity_template.isNegative;
      }
      /* istanbul ignore next */
      if (entity_template?.isHidden) {
        isHidden = entity_template.isHidden;
      }
      if (!isInPotentiality) {
        isHidden = true;
        isOptional = true;
      }
      // optional change drivers related flags ends //
      /* istanbul ignore next */
      // if (entity_template?.nestedEntityId) {
      //   nestedEntityId = entity_template.nestedEntityId;
      // }
      // /* istanbul ignore next */
      // if (entity_template?.nestedEntityName) {
      //   nestedEntityName = entity_template.nestedEntityName;
      // }
      if (entity_template?.type === 'entity') {
        // temp_ent_index = ind + 1;
        /* istanbul ignore next */
        this.nestedEntitiesFormgroup(
          entity_template.nslAttributes,
          nestedGroup,
          entity_template?.name,
          entity_template?.displayName,
          entity_template?.isMulti,
          entity_template?.id,
          entity_template.slot ? entity_template?.slot : '',
          index,
          entity_template.design,
          recordId,
          alternativeChangeDriver,
          isNegative,
          isHidden,
          isOptional,
          entity_template?.nestedEntityId,
          entity_template?.nestedEntityName,
          configurationType,
          entity_template.configuration,
          tablecardAttrCount,
          entity_template.txnRecordId,
          entity_template?.ent_index,
          entity_template.isInPotentiality,
          entity_template?.isPlaceholder,
          entity_template?.attributeType,
          entity_template?.masterId
        );
      } else {
        validations = this.eventsService.getValidations(
          entity_template.constraints,
          entity_template.uiElement
        );
        nestedGroup[entity_template.name + '$' + index] = new FormArray([]);
        /* istanbul ignore next */
        if (entity_template.values?.length === 0) {
          /* istanbul ignore next */
          nestedGroup[entity_template.name + '$' + index].push(
            this.fb.group({
              [entity_template.name + '$' + index]: [
                '',
                this.bindValidations(
                  validations || [],
                  entity_template.uiElement,
                  entity_template.isOptional,
                  !entity_template?.isInPotentiality || !isInPotentiality,
                  entity_template.isNegative
                ),
              ],
              // [entity_template?.id]: ['', Validators.required],
              attr_id: entity_template?.id,
              constraints: [entity_template.constraints],
              attrtype: entity_template?.type,
              labelType: entity_template.label,
              label: entity_template.name + '$' + index,
              attrInstanceId: 0,
              attr_displayName: entity_template?.displayName,
              inputType: entity_template.uiElement,
              validations:
                !entity_template?.isInPotentiality ||
                !isInPotentiality ||
                entity_template.isOptional
                  ? []
                  : [validations],
              optionalValidation:
                !entity_template?.isInPotentiality ||
                !isInPotentiality ||
                entity_template.isOptional
                  ? [validations]
                  : [],
              isMulti: entity_template?.isMulti,
              isPlaceholder: entity_template?.isPlaceholder,
              value: '',
              canDownload: entity_template.canDownload,
              recordId: entity_template.recordId
                ? entity_template.recordId
                : '',
              entity_txnRecordId: entity_template?.txnRecordId,
              ent_index: ent_index,
              isInfo: this.isInfo,
              attr_options: entity_template.options
                ? [entity_template.options]
                : null,
              isInPotentiality:
                entity_template.isInPotentiality && isInPotentiality,
              tablecardAttrCount: tablecardAttrCount,
              isHidden:
                (!entity_template?.isInPotentiality || !isInPotentiality) &&
                !entity_template?.configuration?.maskable
                  ? true
                  : entity_template?.isHidden, // FE757 - For Hidden Attribute - Attribute level
              isDisabled: entity_template.isDisabled,
              triggerConditionalPotentiality:
                entity_template.triggerConditionalPotentiality,
              isMultiEntity: isMulti,
              // optional change drivers related flags starts //
              alternativeChangeDriver: entity_template?.alternativeChangeDriver
                ? entity_template?.alternativeChangeDriver
                : '',
              isOptional:
                !entity_template?.isInPotentiality || !isInPotentiality
                  ? true
                  : entity_template.isOptional,
              isNegative: entity_template.isNegative,
              isAlternate: this.evenOddClass(entity_template?.isHidden),
              // optional change drivers related flags ends //
              entityName: name + '$' + (ind + 1),
              entityId: id,
              entityMasterId: masterId,
              slotNumber: slot,
              attr_order_num: index,
              attrTxnRecordId: index,
              isCardConfig:
                configuration?.type == 'card' ||
                configuration?.type == 'cardWithTemplate' ||
                configuration?.type == 'cardCustomiZation'
                  ? true
                  : false,
              isReadOnly:
                (configuration?.type == 'table' &&
                  configuration?.setting?.loadLabels) ||
                entity_template?.configuration?.isReadOnly
                  ? true
                  : false,
              attributeType: entity_template.attributeType,
              nslAttributeProperties: entity_template?.nslAttributeProperties,
              effectiveUserRights: [entity_template?.effectiveUserRights],
              attr_configuration: JSON.stringify(entity_template.configuration),
              hideLabelMultiAttr: false,
              sentence:
                !this.isInfo && !this.showEntityName
                  ? this.getSentenceContent(ind, index)
                  : '',
              attr_color: entity_template?.design?.color,
              attr_fontSize: entity_template?.design?.fontSize,
              nestedEntityName: nestedEntityName,
              design: entity_template?.design,
              attrClass: entity_template?.attrClass,
              entity_configuration: JSON.stringify(configuration),
            })
          );
        } else {
          if (
            entity_template.uiElement == 'multiselectdropdown' ||
            entity_template.uiElement == 'checkbox' ||
            entity_template.uiElement == 'image' ||
            entity_template.uiElement == 'imageavatar' ||
            entity_template.uiElement == 'labelwithimage' ||
            entity_template.uiElement == 'audio' ||
            entity_template.uiElement == 'video' ||
            entity_template.uiElement == 'location' ||
            entity_template.uiElement == 'chipmultipleselection' ||
            entity_template.uiElement == 'label' ||
            entity_template.configuration?.isReadOnly ||
            (entity_template.uiElement == 'date' && entity_template?.isMulti) ||
            this.SfList.includes(entity_template.uiElement)
          ) {
            nestedGroup[entity_template.name + '$' + index].push(
              /* istanbul ignore next */
              this.fb.group({
                [entity_template.name + '$' + index]: [
                  entity_template.uiElement == 'location' &&
                  typeof entity_template.values === 'object'
                    ? entity_template.values
                    : [entity_template.values],
                  this.bindValidations(
                    validations || [],
                    entity_template.uiElement,
                    entity_template.isOptional,
                    !entity_template?.isInPotentiality || !isInPotentiality,
                    entity_template.isNegative
                  ),
                ],
                // [entity_template?.id]: [[entity_template.values], this.bindValidations(validations || [])],
                attr_id: entity_template?.id,
                isReadOnly:
                  (configuration?.type == 'table' &&
                    configuration?.setting?.loadLabels) ||
                  entity_template?.configuration?.isReadOnly
                    ? true
                    : false,
                attributeType: entity_template.attributeType,
                constraints: [entity_template.constraints],
                attrtype: entity_template?.type,
                labelType: entity_template.label,
                label: entity_template.name + '$' + index,
                attrInstanceId: 0,
                attr_displayName: entity_template?.displayName,
                inputType: entity_template.uiElement,
                validations:
                  !entity_template?.isInPotentiality ||
                  !isInPotentiality ||
                  entity_template.isOptional
                    ? []
                    : [validations],
                optionalValidation:
                  !entity_template?.isInPotentiality ||
                  !isInPotentiality ||
                  entity_template.isOptional
                    ? [validations]
                    : [],
                isMulti: entity_template?.isMulti,
                isPlaceholder: entity_template?.isPlaceholder,
                canDownload: entity_template.canDownload,
                value: [entity_template.values],
                recordId: entity_template.recordId
                  ? entity_template.recordId
                  : '',
                entity_txnRecordId: entity_template?.txnRecordId,
                ent_index: ent_index,
                isInfo: this.isInfo,
                attr_options: entity_template.options
                  ? [entity_template.options]
                  : null,
                isInPotentiality:
                  entity_template.isInPotentiality && isInPotentiality,
                tablecardAttrCount: tablecardAttrCount,
                isHidden:
                  (!entity_template?.isInPotentiality || !isInPotentiality) &&
                  !entity_template?.configuration?.maskable
                    ? true
                    : entity_template?.isHidden, // FE757 - For Hidden Attribute - Attribute level
                isDisabled: entity_template.isDisabled,
                triggerConditionalPotentiality:
                  entity_template.triggerConditionalPotentiality,
                isMultiEntity: isMulti,
                attrTxnRecordId: index,
                // optional change drivers related flags starts //
                alternativeChangeDriver: entity_template?.alternativeChangeDriver
                  ? entity_template?.alternativeChangeDriver
                  : '',
                isOptional:
                  !entity_template?.isInPotentiality || !isInPotentiality
                    ? true
                    : entity_template?.isOptional,
                isNegative: entity_template?.isNegative,
                // optional change drivers related flags ends //
                entityName: name + '$' + (ind + 1),
                entityId: id,
                entityMasterId: masterId,
                slotNumber: slot,
                attr_order_num: index,
                isCardConfig:
                  configuration?.type == 'card' ||
                  configuration?.type == 'cardWithTemplate' ||
                  configuration?.type == 'cardCustomiZation'
                    ? true
                    : false,
                isAlternate: this.evenOddClass(entity_template?.isHidden),
                nslAttributeProperties: entity_template?.nslAttributeProperties,
                effectiveUserRights: [entity_template?.effectiveUserRights],
                attr_configuration: JSON.stringify(
                  entity_template.configuration
                ),
                hideLabelMultiAttr: false,
                hideMultiValueIcon: false,
                sentence:
                  !this.isInfo && !this.showEntityName
                    ? this.getSentenceContent(ind, index)
                    : '',
                attr_color: entity_template?.design?.color,
                attr_fontSize: entity_template?.design?.fontSize,
                nestedEntityName: nestedEntityName,
                design: entity_template?.design,
                attrClass: entity_template?.attrClass,
                entity_configuration: JSON.stringify(configuration),
              })
            );
          } else {
            entity_template.values?.forEach((element: any, i: any) => {
              /* istanbul ignore next */
              nestedGroup[entity_template.name + '$' + index].push(
                this.fb.group({
                  [entity_template.name + '$' + index]: [
                    element,
                    this.bindValidations(
                      validations || [],
                      entity_template.uiElement,
                      entity_template.isOptional,
                      !entity_template?.isInPotentiality || !isInPotentiality,
                      entity_template.isNegative
                    ),
                  ],
                  // [entity_template?.id]: [element, Validators.required],
                  attr_id: entity_template?.id,
                  isReadOnly:
                    (configuration?.type == 'table' &&
                      configuration?.setting?.loadLabels) ||
                    entity_template?.configuration?.isReadOnly
                      ? true
                      : false,
                  attributeType: entity_template.attributeType,
                  constraints: [entity_template.constraints],
                  attrtype: entity_template?.type,
                  labelType: entity_template.label,
                  label: entity_template.name + '$' + index,
                  attrInstanceId: i,
                  attr_displayName: entity_template?.displayName,
                  inputType: entity_template.uiElement,
                  validations:
                    !entity_template?.isInPotentiality ||
                    !isInPotentiality ||
                    entity_template.isOptional
                      ? []
                      : [validations],
                  optionalValidation:
                    !entity_template?.isInPotentiality ||
                    !isInPotentiality ||
                    entity_template.isOptional
                      ? [validations]
                      : [],
                  isMulti: entity_template?.isMulti,
                  isPlaceholder: entity_template?.isPlaceholder,
                  canDownload: entity_template.canDownload,
                  value: element,
                  recordId: entity_template.recordId
                    ? entity_template.recordId
                    : '',
                  entity_txnRecordId: entity_template?.txnRecordId,
                  ent_index: ent_index,
                  isInfo: this.isInfo,
                  attr_options: entity_template.options
                    ? [entity_template.options]
                    : null,
                  isInPotentiality:
                    entity_template.isInPotentiality && isInPotentiality,
                  tablecardAttrCount: tablecardAttrCount,
                  isHidden:
                    (!entity_template?.isInPotentiality || !isInPotentiality) &&
                    !entity_template?.configuration?.maskable
                      ? true
                      : entity_template?.isHidden, // FE757 - For Hidden Attribute - Attribute level
                  isDisabled: entity_template.isDisabled,
                  isAlternate: this.evenOddClass(entity_template?.isHidden),
                  triggerConditionalPotentiality:
                    entity_template.triggerConditionalPotentiality,
                  isMultiEntity: isMulti,
                  // optional change drivers related flags starts //
                  alternativeChangeDriver: entity_template?.alternativeChangeDriver
                    ? entity_template?.alternativeChangeDriver
                    : '',
                  isOptional:
                    !entity_template?.isInPotentiality || !isInPotentiality
                      ? true
                      : entity_template?.isOptional,
                  isNegative: entity_template?.isNegative,
                  attrTxnRecordId: index + '$' + i,
                  // optional change drivers related flags ends //
                  entityName: name + '$' + (ind + 1),
                  entityId: id,
                  entityMasterId: masterId,
                  slotNumber: slot,
                  attr_order_num: index,
                  isCardConfig:
                    configuration?.type == 'card' ||
                    configuration?.type == 'cardWithTemplate' ||
                    configuration?.type == 'cardCustomiZation'
                      ? true
                      : false,
                  nslAttributeProperties:
                    entity_template?.nslAttributeProperties,
                  effectiveUserRights: [entity_template?.effectiveUserRights],
                  attr_configuration: JSON.stringify(
                    entity_template.configuration
                  ),
                  hideLabelMultiAttr:
                    i !== 0
                      ? entity_template?.configuration?.hideLabelMultiAttr
                      : false,
                  hideMultiValueIcon: false,
                  sentence:
                    !this.isInfo && !this.showEntityName
                      ? this.getSentenceContent(ind, index)
                      : '',
                  attr_color: entity_template?.design?.color,
                  attr_fontSize: entity_template?.design?.fontSize,
                  nestedEntityName: nestedEntityName,
                  design: entity_template?.design,
                  attrClass: entity_template?.attrClass,
                  entity_configuration: JSON.stringify(configuration),
                })
              );
              // used to add the scorm score to next cu
              if (entity_template.uiElement === 'scormProgress') {
                this.eventsService.updateScormScore(element);
              }
            });
          }
        }
      }
    });
    nestedGroup['isMulti'] = isMulti;
    nestedGroup['isPlaceholder'] = isPlaceholder;
    if (slot != '') {
      nestedGroup['slot'] = slot;
    }
    if (isPlaceholder) {
      nestedGroup['value'] = '';
    }
    nestedGroup['entType'] = 'entity';
    nestedGroup['enty_id'] = id;
    /* istanbul ignore next */
    if (nestedEntityId) {
      nestedGroup['nestedEntityId'] = nestedEntityId;
    }
    /* istanbul ignore next */
    if (nestedEntityName) {
      nestedGroup['nestedEntityName'] = nestedEntityName;
    }

    if (ent_index) {
      nestedGroup['ent_index'] = ent_index;
    }
    if (ent_multiCount) {
      nestedGroup['ent_multiCount'] = ent_multiCount;
    }
    /* istanbul ignore next */
    nestedGroup['masterId'] = masterId;
    /* istanbul ignore next */
    if (recordId) {
      nestedGroup['recordId'] = recordId;
    }
    // optional change drivers related flags starts //
    if (alternativeChangeDriver) {
      nestedGroup['alternativeChangeDriver'] = alternativeChangeDriver;
    }
    if (isOptional) {
      nestedGroup['isOptional'] = isOptional;
    }
    if (isNegative) {
      nestedGroup['isNegative'] = isNegative;
    }
    if (isHidden) {
      nestedGroup['isHidden'] = isHidden;
    }
    if (!isInPotentiality) {
      nestedGroup['isOptional'] = true;
      nestedGroup['isHidden'] = true;
    }
    if (attributeType) {
      nestedGroup['attributeType'] = JSON.stringify(attributeType);
    }
    // optional change drivers related flags ends //
    nestedGroup['entityHideLabel'] =
      configuration?.hideEntityLabel || configuration?.hideLabel;
    nestedGroup['entityClass'] = configuration?.entityClass;
    nestedGroup['configuration'] = JSON.stringify(configuration);
    if (configurationType !== 'listType') {
      nestedGroup['configurationType'] = configurationType;
      nestedGroup['tablecardAttrCount'] = tablecardAttrCount;
    } else {
      nestedGroup['configurationType'] = configurationType;
    }
    /* istanbul ignore next */
    if (design?.entityIcon?.link?.desktop) {
      nestedGroup['entityicon'] = design?.entityIcon?.link?.desktop;
    } else {
      nestedGroup['entityicon'] = '/assets/images/icon-entity-hover.svg';
    }
    nestedGroup['design'] = JSON.stringify(design);
    nestedGroup['isInPotentiality'] = isInPotentiality;
    nestedGroup['txnRecordId'] = txnRecordId;
    nestedGroup['attr_order_num'] = ind;
    nestedGroup['displayName'] = displayName;
    nestedGroup['isEmptyEntity'] = !nslAttributes?.some(
      (attr: any) => !attr.isHidden && attr.isInPotentiality
    );
    group[name + '$' + (ind + 1)] = this.fb.group(nestedGroup);
  }
  /* istanbul ignore next */
  getSentenceContent(entityIndex: number, attributeIndex: number) {
    if (this.currentCu?.tCUsentenceName) {
      let CUSentence = JSON.parse(this.currentCu?.tCUsentenceName);
      let physicalSentence = CUSentence?.physical;
      let sentenceString = '';
      for (let i = 0; i < physicalSentence.length; i++) {
        if (this.sentenceStartIndex <= i) {
          if (
            physicalSentence[i].entity_counter === entityIndex &&
            physicalSentence[i].attribute_counter === attributeIndex
          ) {
            this.sentenceStartIndex = physicalSentence[i + 1]
              ? i + 1
              : this.sentenceStartIndex;
            sentenceString +=
              ' <b class="sen-txt">' + physicalSentence[i].token + '</b>';
            break;
          } else {
            sentenceString +=
              physicalSentence[i].tag === 'nlg'
                ? ' ' + physicalSentence[i].token
                : ' <b>' + physicalSentence[i].token + '</b>';
          }
        }
      }
      return sentenceString.trim();
    }
  }

  getControlsName(control: any) {
    return control?.value?.value?.entType === 'entity';
  }

  evenOddClass(isHidden: boolean) {
    if (!isHidden) {
      this.status = !this.status;
      return this.status ? 'alternateclass' : '';
    } else {
      return 'hide';
    }
  }

  getConfigurationType(control: any) {
    return (
      control.value.value.configurationType !== 'listType' &&
      control.value.value.configurationType !== 'accordion' &&
      this.checkWidget(control)
    );
  }
  checkWidget(control: any): boolean {
    if (control?.value?.value?.configurationType == 'widgets') {
      return this.showwidget;
    } else return true;
  }
  getConfigurationTableOrCard(control: any) {
    return control.value.value.configurationType === 'table';
  }
  getConfigurationVerticalTable(control: any) {
    return control.value.value.configurationType === 'verticalTable';
  }
  getConfigurationTableWithLabels(control: any) {
    return control.value.value.configurationType === 'tableWithLabels';
  }
  getConfigurationCardOrWidget(control: any) {
    return control?.value?.value?.configurationType == 'widgets';
  }
  getConfigurationProgressBar(control: any) {
    return control.value.value.configurationType === 'progressBar';
  }

  getConfigurationCard(control: any) {
    return (
      control.value.value.configurationType === 'card' ||
      control.value.value.configurationType === 'cardWithTemplate'
    );
  }

  getTableOrCard(control: any) {
    return (
      control.value.value.configurationType === 'table' ||
      control.value.value.configurationType === 'card' ||
      control.value.value.configurationType === 'cardWithTemplate' ||
      control.value.value.configurationType == 'progressBar' ||
      control.value.value.configurationType === 'tableWithLabels' ||
      control.value.value.configurationType == 'verticalTable'
    );
  }

  // Return empty values for attributes
  returnEmptyForm(value: any) {
    const formValue = { ...value };
    let getAllKeys = Object.keys(formValue);
    let dolArr = ['$'];
    /* istanbul ignore next */
    dolArr?.forEach((item) => {
      /* istanbul ignore next */
      getAllKeys?.forEach((keyName) => {
        if (keyName.indexOf(item) !== -1) {
          if (!formValue[keyName][0]?.value) {
            this.returnEmptyForm(formValue[keyName]);
          } else {
            formValue[keyName][0].value = '';
            formValue[keyName][0][keyName] = '';
          }
        }
      });
    });
    return formValue;
  }

  addingMultipleEntites(
    entity: any,
    entityControls: any,
    entityFom: any,
    index: number,
    formcontrol: any,
    isUpload: any,
    fileDataObj: any
  ) {
    let control: any;
    for (let [key, value] of Object.entries(this.tempForm.value)) {
      /* istanbul ignore next */
      if (key.split('$')[0] == entity.split('$')[0]) {
        if (this.getTableOrCard(formcontrol)) {
          control = value;
          /* istanbul ignore next */
          let entity = this.tempEntityList?.find(
            (obj: any) => obj.name == key.split('$')[0]
          );
          /* istanbul ignore next */
          Object.entries(control)?.forEach(([key1, value1], index) => {
            /* istanbul ignore next */
            if (index >= entity.nslAttributes.length) {
              delete control[key1];
            }
          });
        } else {
          control = value;
        }
      }
    }
    if (isUpload) {
      const contr = JSON.parse(JSON.stringify(control));
      /* istanbul ignore next */
      Object.entries(contr)?.forEach(([ke, val], ind) => {
        if (
          val[0].inputType == 'date' ||
          val[0].inputType == 'currentdate' ||
          val[0].inputType == 'datetime'
        ) {
          let uploadedDate = fileDataObj[val[0].attr_displayName];
          let dateFormat = val[0].attributeType?.properties?.format;
          /*istanbul ignore else */
          if (dateFormat == 'dd/MM/yyyy') {
            const originalMoment = moment(uploadedDate, 'DD/MM/YYYY');
            uploadedDate = originalMoment.format('MM/DD/YYYY');
          }
          const dd = new Date(uploadedDate);
          val[0].value = dd;
          val[0][val[0].label] = dd;
        } else if (val[0].inputType == 'currentdatetime') {
          const dd = new Date();
          val[0].value = dd;
          val[0][val[0].label] = dd;
        } else if (val[0].inputType == 'multiselectdropdown') {
          let arr: any = [];
          // console.log(fileDataObj[val[0].label.split('$')[0]]);
          let temp = fileDataObj[val[0].attr_displayName];
          arr = temp.split(',');
          arr.forEach((str: any) => {
            const ind = arr.indexOf(str);
            arr[ind] = str.trim();
          });
          val[0].value = arr;
          val[0][val[0].label] = arr;
        } else if (val[0]?.inputType == 'dropdown') {
          let fileVal = fileDataObj[val[0].attr_displayName];
          if (
            val[0]?.attr_options?.filter((element: any) => {
              return (
                element?.actualValue == fileVal ||
                element?.displayValue == fileVal
              );
            })?.length > 0
          ) {
            val[0].value = fileVal;
            val[0][val[0].label] = fileVal;
          } else {
            val[0].value = '';
            val[0][val[0].label] = '';
          }
        } else if (
          val[0].inputType == 'mobilenumber' &&
          val[0].attr_configuration &&
          JSON.parse(val[0].attr_configuration)?.mobileFormat?.displayValue ==
            'USA'
        ) {
          val[0].value = displayUSAMobileFormat(
            fileDataObj[val[0].attr_displayName]
          );
          val[0][val[0].label] = displayUSAMobileFormat(
            fileDataObj[val[0].attr_displayName]
          );
        } else if (
          val[0].inputType == 'percentage' &&
          val[0].attr_configuration &&
          JSON.parse(val[0].attr_configuration)?.percentageType == 'percentage'
        ) {
          let tempValue: any = fileDataObj[val[0].attr_displayName] * 100;
          val[0].value = tempValue.toString();
          val[0][val[0].label] = val[0].value;
        } else if (
          val[0].inputType == 'mobilenumber' &&
          val[0].attr_configuration &&
          JSON.parse(val[0].attr_configuration)?.mobileFormat?.displayValue ==
            'MEXICO'
        ) {
          val[0].value = displayMexicoMobileFormat(
            fileDataObj[val[0].attr_displayName]
          );
          val[0][val[0].label] = displayMexicoMobileFormat(
            fileDataObj[val[0].attr_displayName]
          );
        } else {
          val[0].value = fileDataObj[val[0].attr_displayName];
          val[0][val[0].label] = fileDataObj[val[0].attr_displayName];
        }
      });
      entityFom.controls[entity + '$' + (index + 1)] = this.fb.group(contr);
      entityFom.value[entity + '$' + (index + 1)] = Object.assign({}, contr);
    } else {
      // let values: any;
      // for (let [key, value] of Object.entries(control)) {
      //   if (key.includes('$')) {
      //     values = value;
      //     /*istanbul ignore next*/
      //     if (!values[0]?.isDisabled) {
      //       values[0].value = '';
      //       values[0][key] = '';
      //     }
      //   }
      // }
      entityFom.controls[entity + '$' + (index + 1)] = this.fb.group(control);
      entityFom.value[entity + '$' + (index + 1)] = Object.assign({}, control);
    }
    entityFom.controls[entity + '$' + (index + 1)].value.isMulti = true;
    entityFom.value[entity + '$' + (index + 1)].isMulti = true;
    entityFom.value[entity + '$' + (index + 1)].slot =
      entityFom.value[entity]?.slot;
    entityFom.value[entity + '$' + (index + 1)].entityicon =
      entityFom.value[entity]?.entityicon;
    entityFom.value[entity + '$' + (index + 1)].design =
      entityFom.value[entity]?.design;
    entityFom.value[entity + '$' + (index + 1)].isInPotentiality =
      entityFom.value[entity]?.isInPotentiality;
    let crypto: Crypto = window.crypto;
    let array = new Uint32Array(1);
    let randomNumber = crypto.getRandomValues(array)[0];
    randomNumber = Number('0.' + randomNumber);
    entityFom.value[entity + '$' + (index + 1)].txnRecordId = Math.floor(
      randomNumber * 100000000000 + 1
    );
    entityFom.value[entity + '$' + (index + 1)].entType =
      entityFom.value[entity]?.entType;
    entityFom.value[entity + '$' + (index + 1)].enty_id =
      entityFom.value[entity]?.enty_id;
    entityFom.value[entity + '$' + (index + 1)].displayName =
      entityFom.value[entity]?.displayName;
    entityFom.value[entity + '$' + (index + 1)].isPlaceholder =
      entityFom.value[entity]?.isPlaceholder;
    entityFom.value[entity + '$' + (index + 1)].attr_order_num =
      entityFom.value[entity]?.attr_order_num;
    entityFom.value[entity + '$' + (index + 1)].entityHideLabel =
      entityFom.value[entity]?.entityHideLabel;
    entityFom.value[entity + '$' + (index + 1)].entityClass =
      entityFom.value[entity]?.entityClass;
    /* istanbul ignore next */
    if (entityFom?.value[entity]?.isPlaceholder) {
      entityFom.value[entity + '$' + (index + 1)].value = entityFom?.value[
        entity
      ]?.value
        ? entityFom?.value[entity]?.value
        : '';
    }
    // optional change drivers related flags starts //
    /* istanbul ignore next */
    if (entityFom.value[entity].alternativeChangeDriver) {
      entityFom.value[entity + '$' + (index + 1)].alternativeChangeDriver =
        entityFom.value[entity].alternativeChangeDriver;
    }
    /* istanbul ignore next */
    if (entityFom.value[entity].isOptional) {
      entityFom.value[entity + '$' + (index + 1)].isOptional =
        entityFom.value[entity].isOptional;
    }
    /* istanbul ignore next */
    if (entityFom.value[entity].isNegative) {
      entityFom.value[entity + '$' + (index + 1)].isNegative =
        entityFom.value[entity].isNegative;
    }
    /* istanbul ignore next */
    if (entityFom.value[entity].isHidden) {
      entityFom.value[entity + '$' + (index + 1)].isHidden =
        entityFom.value[entity].isHidden;
    }
    // optional change drivers related flags ends //
    /* istanbul ignore next */
    if (entityFom.value[entity].nestedEntityId) {
      entityFom.value[entity + '$' + (index + 1)].nestedEntityId =
        entityFom.value[entity].nestedEntityId;
    }
    /* istanbul ignore next */
    if (entityFom.value[entity].nestedEntityName) {
      entityFom.value[entity + '$' + (index + 1)].nestedEntityName =
        entityFom.value[entity].nestedEntityName;
    }
    /* istanbul ignore next */
    if (entityFom.value[entity].configuration) {
      entityFom.value[entity + '$' + (index + 1)].configuration =
        entityFom.value[entity].configuration;
    }
  }

  addMultipleEntityControl(
    entity: any,
    entityControls: any,
    entityFom: any,
    index: number,
    formcontrol: any,
    isTable?: boolean
  ) {
    /* istanbul ignore next */
    if (!isTable) {
      /* istanbul ignore next */
      let configuration = entityFom?.value?.[entity]?.configuration
        ? JSON.parse(entityFom?.value?.[entity]?.configuration)
        : {};
      /* istanbul ignore next */
      if (
        (configuration?.type == 'table' &&
          configuration?.setting?.allowDuplication) ||
        (configuration?.type == 'card' &&
          configuration?.cardSettings?.allowDuplicationCard) ||
        configuration?.allowDuplication
      ) {
        this.addingMultipleEntites(
          entity,
          entityControls,
          entityFom,
          index,
          formcontrol,
          false,
          null
        );
        this.checkEntitieswithCardandTable(false, true);
        this.entityAttributesOrder(this.attributesArray);
        this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
        this.createFormData(true);
      } else {
        this.addMultiEntities.emit({
          id: entityControls?.enty_id?.value,
          slot: entityControls?.slot?.value,
        });
      }
    } else {
      /* istanbul ignore next */
      this.filedData?.forEach((obj: any, ind: any) => {
        this.addingMultipleEntites(
          entity,
          entityControls,
          entityFom,
          index,
          formcontrol,
          true,
          obj
        );
        index++;
      });
      this.checkEntitieswithCardandTable(false, true);
      this.entityAttributesOrder(this.attributesArray);
      this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
      //this.dataEmitForTable.emit(this.entityList);
      this.createFormData(true);
    }
  }

  getFormValueLength(entity?: any) {
    const entname = entity?.split('$')[0];
    let entities = this.getFormDataFormat(this.form?.value, {});
    /* istanbul ignore next */
    entities?.forEach((ent: any) => {
      ent.name = ent.name?.split('$')[0];
    });
    let entCount = entities.filter((ent: any) => ent.name === entname).length;
    return entCount;
  }
  //removing the entities from the list
  deleteEntityControl(
    entity: any,
    entityControls: any,
    entityFom: any,
    index: number
  ) {
    let entities = this.getFormDataFormat(this.form.value, {});
    /* istanbul ignore next */
    entities?.forEach((ent: any) => {
      ent.name = ent.name.split('$')[0];
    });
    const entname = entity.split('$')[0];
    /* istanbul ignore next */
    const isDuplicate = entities?.reduce((a: any, e: any) => {
      a[e.name] = ++a[e.name] || 0;
      return a;
    }, {});
    /* istanbul ignore next */
    if (isDuplicate[entname] == 0) {
      this.alertService.showToaster(
        'Cannot remove if only one Entity is present',
        '',
        'warn'
      );
    } else {
      entityFom.removeControl(entity);
      this.checkEntitieswithCardandTable(false);
      this.entityAttributesOrder(this.attributesArray);
      this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
      this.createFormData(true);
    }
  }

  emitFormValueonEventTrigger() {
    this.eventsService
      .getEvent()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        if (
          data &&
          (data?.eventType === 'ON_LOAD' || data?.eventType === 'ON_FOCUS')
        ) {
          /* istanbul ignore next */
          if (
            this.currentCu &&
            this.currentCu?.eventCUList &&
            this.currentCu?.eventCUList?.length > 0
          ) {
            /* istanbul ignore next */
            this.currentCu?.eventCUList?.findIndex(
              (event: any) =>
                event?.changeDriverId === data?.attributeId &&
                event?.eventType === data?.eventType
            );
          }
        }
      });
  }

  getSelectedCards(event: any) {
    /* istanbul ignore next */
    if (this.selectedCards?.length > 0) {
      /*istanbul ignore next*/
      const index = this.selectedCards?.findIndex((card: any) => {
        return card?.entityName == event?.entityName;
      });
      if (index !== -1) {
        this.selectedCards[index] = event;
      } else {
        this.selectedCards.push(event);
      }
    } else {
      this.selectedCards.push(event);
    }
    // this.selectedRowTxnRecordIds = event?.selectedRowTxnRecordIds;
    // this.selectedRowTxnRecordIds?.push(event?.selectedRowTxnRecordIds) ;
    if (event?.selectedRowTxnRecordIds?.length) {
      event?.selectedRowTxnRecordIds?.forEach((id: any) => {
        this.selectedRowTxnRecordIds.push(id);
      });
    } else {
      this.selectedRowTxnRecordIds = [];
    }
    this.transactionFacadeService.selectedCardsRecordList = this.selectedCards;
  }

  // getting the dynamics attributes of table
  getSelectedTransactions(event: any) {
    /* istanbul ignore next */
    if (event?.selectedTransactions?.length > 0) {
      if (this.selectedTransactions.length === 0) {
        this.selectedTransactions.push({
          entityName: event.entityName,
          selectedTransactions: event.selectedTransactions,
          selectedIndexes: event.selectedIndexes,
          selectedRowTxnRecordIds: event?.selectedRowTxnRecordIds,
          slot: event.slot,
        });
      } else {
        const index = this.selectedTransactions.findIndex((x: any) => {
          return x.entityName == event.entityName;
        });
        if (index !== -1) {
          this.selectedTransactions[index].selectedTransactions =
            event.selectedTransactions;
          this.selectedTransactions[index].selectedIndexes =
            event.selectedIndexes;
          this.selectedTransactions[index].selectedRowTxnRecordIds =
            event?.selectedRowTxnRecordIds;
          this.selectedTransactions[index].slot = event.slot;
        } else {
          this.selectedTransactions.push({
            entityName: event.entityName,
            selectedTransactions: event.selectedTransactions,
            selectedRowTxnRecordIds: event?.selectedRowTxnRecordIds,
            selectedIndexes: event.selectedIndexes,
            slot: event.slot,
          });
        }
      }
      this.tableEntityName = event.entityName;
      // this.selectedRowTxnRecordIds = event?.selectedRowTxnRecordIds;
      // this.selectedRowTxnRecordIds?.push(event?.selectedRowTxnRecordIds) ;
      if (event?.selectedRowTxnRecordIds?.length) {
        event?.selectedRowTxnRecordIds?.forEach((id: any) => {
          this.selectedRowTxnRecordIds.push(id);
        });
      } else {
        this.selectedRowTxnRecordIds = [];
      }
    } else {
      const index = this.selectedTransactions.findIndex((x: any) => {
        return x.entityName == event.entityName;
      });
      this.selectedTransactions.splice(index, 1);
    }
    this.transactionFacadeService.selectedTransactions = this.selectedTransactions;
    this.transacationFacadeService.selectedTableRecordList = this.selectedTransactions;
  }

  //Uploading data from excel/csv
  fileUploadData(
    event: any,
    entity: any,
    entityControls: any,
    entityFom: any,
    index: number,
    formcontrol: any
  ) {
    this.filedData = event.fileData;
    this.addMultipleEntityControl(
      entity,
      entityControls,
      entityFom,
      index,
      formcontrol,
      true
    );
  }

  //Removing entities from table
  removeEntityfromTable(event: any) {
    /* istanbul ignore next */
    for (let [key, value] of Object.entries(event?.rowData)) {
      /* istanbul ignore next */
      if (value && value?.constructor?.name === 'Object') {
        for (let [key1, value1] of Object.entries(this.form.value)) {
          /* istanbul ignore next */
          if (value1 && value1?.constructor?.name === 'Object') {
            this.findDeleteRecord(value1, value);
          }
        }
      }
    }
    let index = this.tempEntityList?.findIndex(
      (ent: any) => ent?.name == event?.entityName.split('$')[0]
    );
    /* istanbul ignore next */
    if (index !== -1) {
      this.tempEntityList?.splice(index + event?.index, 1);
    } else {
      this?.tempEntityList?.forEach((entity: any) => {
        this.checkforNestedEntityDelete(entity?.nslAttributes, event);
      });
    }

    this.checkEntitieswithCardandTable(false);
    /* istanbul ignore next */
    let tempEntityList = this.attributesArray.filter(
      (ent: any) => ent?.name !== event?.entityName
    );
    /* istanbul ignore next */
    let temptableEntityList = this.attributesArray.filter(
      (ent: any) => ent?.name == event?.entityName
    );
    //temptableEntityList.splice(event.index, 1);
    /* istanbul ignore next */
    this.selectedTableRows = event?.selectedRows;
    /* istanbul ignore next */
    this.attributesArray = tempEntityList?.concat(temptableEntityList);
    this.entityAttributesOrder(this.attributesArray);
    this.entityList = JSON.parse(JSON.stringify(this.attributesArray));
    //this.dataEmitForTable.emit(this.entityList);
    this.createFormData(true);
  }

  findDeleteRecord(formValue: any, rowDataValue: any) {
    /* istanbul ignore next */
    for (let [key1, value1] of Object.entries(formValue)) {
      if (value1 && value1?.constructor?.name == 'Array') {
        if (
          value1[0]?.label == rowDataValue['label'] &&
          value1[0]?.entityName == rowDataValue['entityName']
        ) {
          delete formValue[key1];
        }
      } else if (value1 && value1?.constructor?.name == 'Object') {
        this.findDeleteRecord(value1, rowDataValue);
      }
    }
  }
  // Detect event GSI data
  detectEventGsiData() {
    this.transactionFacadeService.eventGsiData$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.attributesArray = [];
          this.checkEntitieswithCardandTable(false);
          this.submitEmit.emit({ data: this.attributesArray, details: {} });
          localStorage.removeItem('resumeTransactionUrl'); // reset resume transaction url for b2c
        }
      });
  }

  onSubmit(event: Event) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    /* istanbul ignore next */
    if (event == null && this.paginationDetails?.pageSize) {
      this.attributesArray = [];
      this.checkEntitieswithCardandTable(true);
      if (
        this.transactionFacadeService.previousPageNumber >= 0 &&
        this.transactionFacadeService.selectedTableRecords[
          this.transactionFacadeService.previousPageNumber
        ]
      ) {
        this.transactionFacadeService.selectedTableAtrributeRecords[
          this.transactionFacadeService.previousPageNumber
        ] = this.attributesArray;
        if (this.transactionFacadeService.previousPageNumber == 0) {
          this.transactionFacadeService.selectedTableAtrributeRecords[
            this.transactionFacadeService.previousPageNumber + 1
          ] = this.attributesArray;
        }
        if (this.transactionFacadeService.previousPageNumber == 1) {
          this.transactionFacadeService.selectedTableAtrributeRecords[
            this.transactionFacadeService.previousPageNumber - 1
          ] = this.attributesArray;
        }
      }
      this.selectedCards = [];
      this.selectedTableRows = [];
      this.submitEmit.emit({
        data: this.attributesArray,
        details: this.paginationDetails,
      });
    } else if (
      (this.form.valid && !this.isInfo && this.onlyInfoLayer !== -1) ||
      ((this.onlyInfoLayer == -1 || this.layoutConfigurations?.hidePhyLayer) &&
        this.isInfo)
    ) {
      this.attributesArray = [];
      this.checkEntitieswithCardandTable(true);
      if (
        this.transactionFacadeService.presentPageNumber >= 0 &&
        this.transactionFacadeService.selectedTableRecords[
          this.transactionFacadeService.presentPageNumber
        ]
      ) {
        this.transactionFacadeService.selectedTableAtrributeRecords[
          this.transactionFacadeService.presentPageNumber
        ] = this.attributesArray;
        if (this.transactionFacadeService.presentPageNumber == 0) {
          this.transactionFacadeService.selectedTableAtrributeRecords[
            this.transactionFacadeService.presentPageNumber + 1
          ] = this.attributesArray;
        }
        if (this.transactionFacadeService.presentPageNumber == 1) {
          this.transactionFacadeService.selectedTableAtrributeRecords[
            this.transactionFacadeService.presentPageNumber - 1
          ] = this.attributesArray;
        }
      }
      if (this.isFacetSearchCu) {
        this.transactionFacadeService.selectedTransactions = this
          .paginationDetails?.pageSize
          ? this.attributesArray.filter((ent: any) => ent.slot == 2)
          : this.attributesArray.filter((ent: any) => ent.slot == 1);
      }
      if (this.currentCu?.reservedCUType == 'NSL_EQL_IN_Memory') {
        if (this.transactionFacadeService.selectedTableRecordList?.length > 0) {
          if (
            this.transactionFacadeService.selectedTableAtrributeRecords?.[1]
              ?.length > 0
          ) {
            this.transactionFacadeService.selectedTableAtrributeRecords[1] = this.transactionFacadeService.selectedTableAtrributeRecords[1].concat(
              this.attributesArray
            );
          } else {
            this.transactionFacadeService.selectedTableAtrributeRecords[1] = this.attributesArray;
          }
        }
      }
      this.selectedTransactions = [];
      this.selectedCards = [];
      this.selectedTableRows = [];
      this.selectedRowTxnRecordIds = [];
      this.transactionFacadeService.selectedCradsArray = {};
      this.submitEmit.emit({
        data: this.attributesArray,
        details: {},
        isOnlyInformational: this.onlyInfoLayer,
      });
      localStorage.removeItem('resumeTransactionUrl'); // reset resume transaction url for b2c
    } else {
      this.form.markAllAsTouched({ onlySelf: true });
    }
    this.paginationDetails = {};
  }

  deepColneObject(obj: any) {
    if (JSON.stringify(obj)) return JSON.parse(JSON.stringify(obj));
  }

  formatTableData(
    multiEntityObj: any,
    prop: any,
    entityCountObj: any,
    finalArray: any,
    tableEntities: any,
    addEntityControl: boolean
  ) {
    let rowObj = {};
    let multiEntArry: any = [];
    let lastRowEntity;
    /* istanbul ignore next */
    if (multiEntityObj) {
      rowObj = JSON.parse(JSON.stringify(multiEntityObj));
    }
    multiEntityObj.nslAttributes.sort((a: any, b: any) => {
      return a.attr_order_num - b.attr_order_num;
    });
    /* istanbul ignore next */
    const attrCount =
      multiEntityObj?.nslAttributes?.length / entityCountObj[prop];
    if (addEntityControl) {
      lastRowEntity = finalArray[finalArray.length - 1];
      finalArray.pop();
    }
    /* istanbul ignore next */
    multiEntityObj?.nslAttributes?.forEach((x: any, index: any) => {
      multiEntArry.push(x);
      if ((index + 1) % attrCount === 0) {
        const entIndex = (index + 1) / attrCount;
        if (tableEntities[entIndex - 1]?.recordId) {
          rowObj['recordId'] = tableEntities[entIndex - 1].recordId;
        } else {
          delete rowObj['recordId'];
        }
        if (tableEntities?.[entIndex - 1]?.txnRecordId) {
          rowObj['txnRecordId'] = tableEntities[entIndex - 1].txnRecordId;
        } else {
          delete rowObj['txnRecordId'];
        }
        rowObj['nslAttributes'] = multiEntArry;
        finalArray.push(rowObj);
        rowObj = {};
        rowObj = JSON.parse(JSON.stringify(multiEntityObj));
        multiEntArry = [];
      }
    });
    /* istanbul ignore next */
    if (addEntityControl) {
      finalArray.push(lastRowEntity);
    }
  }

  checkEntitieswithCardandTable(isSubmit: boolean, addEntityControl?: boolean) {
    this.attributesArray = this.getFormDataFormat(this.form.value, {});
    const entityCountObj = this.find_duplicate_in_array(this.tempEntityList);
    /* istanbul ignore next */
    if (this.attributesArray?.length > 0) {
      for (let prop in entityCountObj) {
        Object.keys(entityCountObj[prop])?.forEach((key) => {
          if (entityCountObj[prop][key] >= 2) {
            let entIndex;
            let multiEntityObj: any;

            for (let i = 0; i < this.attributesArray.length; i++) {
              const ent = this.attributesArray[i];
              /* istanbul ignore else */
              if (ent.name.split('$')[0] == key && ent.slot == prop) {
                multiEntityObj = this.deepColneObject(ent);
                let tableEntities = this.tempEntityList.filter(
                  (x: any) =>
                    x.name === multiEntityObj.name.split('$')[0] &&
                    x?.slot === multiEntityObj?.slot
                );
                this.attributesArray.splice(i, 1);
                this.formatTableData(
                  multiEntityObj,
                  key,
                  entityCountObj[prop],
                  this.attributesArray,
                  tableEntities,
                  addEntityControl
                );
                break;
              } else {
                this.nestedCheckEntitiesWithTableAndCard(
                  ent,
                  prop,
                  entityCountObj,
                  key,
                  multiEntityObj,
                  this.tempEntityList,
                  i,
                  addEntityControl
                );
              }
            }
          }
        });
      }
      if (
        this.selectedCards?.length > 0 &&
        this.selectedCards[0]?.selectedCards?.length > 0 &&
        isSubmit
      ) {
        let removeIndexs: any = [];
        let entityIndex = 0;
        /* istanbul ignore next */
        this.selectedCards?.forEach((card: any) => {
          /* istanbul ignore next */
          let tempEntityList = this.attributesArray.filter(
            (ent: any) => ent.name.split('$')[0] !== card.entityName
          );
          let tableRows: any = [];
          this.temptableEntityList = this.attributesArray.filter(
            (ent: any) => ent.name.split('$')[0] == card.entityName
          );
          /* istanbul ignore else */
          if (this.temptableEntityList.length == 0) {
            this.setNestedEntitySelection(
              card,
              this.attributesArray,
              tableRows,
              true
            );
            this.attributesArray = tempEntityList;
          } else {
            this.temptableEntityList?.forEach((ele: any, index: any) => {
              card.selectedCards.forEach((o3: any) => {
                if (index === o3) {
                  tableRows.push(ele);
                }
              });
            });
            this.attributesArray = tempEntityList.concat(tableRows);
          }
        });
      }
      //table rows selection
      /* istanbul ignore next */
      if (this.selectedTransactions?.length > 0 && isSubmit) {
        /* istanbul ignore next */
        this.selectedTransactions?.forEach((obj: any) => {
          /* istanbul ignore next */
          let tempEntityList = this.attributesArray.filter(
            (ent: any) => ent.name !== obj.entityName
          );
          let tableRows: any = [];
          this.temptableEntityList = this.attributesArray.filter(
            (ent: any) => ent.name == obj.entityName
          );
          /* istanbul ignore else */
          if (this.temptableEntityList.length == 0) {
            this.setNestedEntitySelection(obj, this.attributesArray, tableRows);
            this.attributesArray = tempEntityList;
          } else {
            this.temptableEntityList?.forEach((ele: any) => {
              obj.selectedTransactions.forEach((o3: any) => {
                let result = ele.nslAttributes.filter((o1: any) =>
                  o3.nslAttributes.some(
                    (o2: any) => o1.attr_order_num === o2.attr_order_num
                  )
                );
                if (result.length > 0) {
                  tableRows.push(ele);
                }
              });
            });
            this.attributesArray = tempEntityList.concat(tableRows);
          }
        });
      }

      this.attributesArray.sort((a, b) => {
        return a.slot - b.slot;
      });
      if (isSubmit) {
        this.removeattributeOrder(this.attributesArray);
      }
    }
  }

  setNestedEntitySelection(
    obj: any,
    attributesArray: any,
    tableRows: any,
    isCard?: boolean
  ) {
    let nestedTableEntities: any = [];
    attributesArray?.forEach((ent: any) => {
      if (ent?.type == 'entity') {
        nestedTableEntities = ent?.nslAttributes?.filter(
          (value: any) =>
            value?.type == 'entity' &&
            value?.name.split('$')[0] == obj?.entityName.split('$')[0]
        );
      }
      /* istanbul ignore else */
      if (nestedTableEntities?.length > 0) {
        this.temptableEntityList = nestedTableEntities;
        this.temptableEntityList?.forEach((ele: any, index: any) => {
          if (isCard) {
            obj.selectedCards.forEach((o3: any) => {
              if (index === o3) {
                tableRows.push(ele);
              }
            });
          } else {
            obj.selectedTransactions.forEach((o3: any) => {
              let result = ele?.nslAttributes?.filter((o1: any) =>
                o3?.nslAttributes.some(
                  (o2: any) => o1?.attr_order_num === o2?.attr_order_num
                )
              );
              if (result?.length > 0) {
                tableRows.push(ele);
              }
            });
          }
        });
        attributesArray?.forEach((entity: any) => {
          let res = entity?.nslAttributes?.filter(
            (value: any) =>
              value.name.split('$')[0] != obj.entityName.split('$')[0]
          );
          if (res && res.length > 0) {
            entity.nslAttributes = res;
            entity.nslAttributes = entity.nslAttributes.concat(tableRows);
          }
        });
      } else if (ent?.type == 'entity') {
        this.setNestedEntitySelection(obj, ent.nslAttributes, tableRows);
      }
    });
  }

  nestedCheckEntitiesWithTableAndCard(
    ent: any,
    prop: any,
    entityCountObj: any,
    key: any,
    multiEntityObj: any,
    tempEntityList: any,
    index: any,
    addEntityControl?: boolean
  ) {
    for (let j = 0; j < ent?.nslAttributes?.length; j++) {
      /* istanbul ignore else */
      if (
        ent.nslAttributes[j]?.type == 'entity' &&
        ent.nslAttributes[j].name.split('$')[0] == key &&
        ent.nslAttributes[j].ent_index == prop
      ) {
        multiEntityObj = this.deepColneObject(ent.nslAttributes[j]);
        let tableEntities: any;
        tempEntityList?.forEach((list: any) => {
          if (list?.type == 'entity') {
            tableEntities = list?.nslAttributes?.filter(
              (value: any) =>
                ent.nslAttributes[j]?.type == 'entity' &&
                ent.nslAttributes[j].name.split('$')[0] == value.name
            );
          }
        });
        ent.nslAttributes.splice(j, 1);
        this.formatTableData(
          multiEntityObj,
          key,
          entityCountObj[prop],
          ent.nslAttributes,
          tableEntities,
          addEntityControl
        );
        break;
      } else if (ent.nslAttributes[j]?.type == 'entity') {
        this.nestedCheckEntitiesWithTableAndCard(
          ent.nslAttributes[j],
          prop,
          entityCountObj,
          key,
          multiEntityObj,
          tempEntityList[index].nslAttributes,
          j,
          addEntityControl
        );
      }
    }
  }

  /** Removing the order key for attributes*/
  removeattributeOrder(attributesArray: any) {
    /* istanbul ignore next */
    attributesArray?.forEach((ent: any) => {
      delete ent.attr_order_num;
      ent.nslAttributes?.sort((a: any, b: any) => {
        return a.attr_order_num - b.attr_order_num;
      });
      /* istanbul ignore next */
      ent.nslAttributes?.forEach((attr: any) => {
        delete attr.attr_order_num;
        /* istanbul ignore next */
        if (attr?.type == 'entity') {
          this.nestedEntityRemoveattributeOrder(attr);
        }
      });
    });
  }

  /** Removing the order key for nested attributes*/
  nestedEntityRemoveattributeOrder(ent: any) {
    /* istanbul ignore next */
    ent.nslAttributes?.sort((a: any, b: any) => {
      return a.attr_order_num - b.attr_order_num;
    });
    ent.nslAttributes?.forEach((att: any) => {
      delete att.attr_order_num;
      if (att?.type == 'entity') {
        this.nestedEntityRemoveattributeOrder(att);
      }
    });
  }

  find_duplicate_in_array(array: any) {
    let count: any = [];
    /* istanbul ignore next */
    array?.forEach((item: any) => {
      /* istanbul ignore next */
      if (
        item?.configuration &&
        (item?.configuration?.type == 'table' ||
          (item?.configuration?.type == 'verticalTable' &&
            item?.configuration?.configurationData?.advanceTableFeatures
              ?.multiRowsVerticalTable) ||
          item?.configuration?.type == 'tableWithLabels' ||
          item?.configuration?.type == 'card' ||
          item?.configuration?.type == 'cardWithTemplate' ||
          item?.configuration?.type == 'widgets')
      ) {
        if (count[item.ent_index] && count[item.ent_index][item.name]) {
          count[item.ent_index][item.name] += 1;
          return;
        }
        count[item.ent_index] = { ...count[item.ent_index], [item.name]: 1 };
      } else if (item?.type == 'entity') {
        item?.nslAttributes?.forEach((attr: any) => {
          if (
            attr?.type == 'entity' &&
            ((attr?.configuration && attr?.configuration?.type == 'table') ||
              attr?.configuration?.type == 'card' ||
              attr?.configuration?.type == 'cardWithTemplate' ||
              attr?.configuration?.type == 'widgets' ||
              (attr?.configuration?.type == 'verticalTable' &&
                attr?.configuration?.configurationData?.advanceTableFeatures
                  ?.multiRowsVerticalTable) ||
              attr?.configuration?.type == 'tableWithLabels')
          ) {
            /* istanbul ignore next */
            if (
              count?.[attr?.ent_index] &&
              count?.[attr?.ent_index][attr.name.split('$')[0]]
            ) {
              count[attr.ent_index][attr.name.split('$')[0]] += 1;
              return;
            }
            count[attr.ent_index] = {
              ...count[attr.ent_index],
              [attr.name.split('$')[0]]: 1,
            };
          } else if (attr?.type == 'entity') {
            let countObj = this.find_duplicate_in_array(attr.nslAttributes);
            countObj.forEach((x: any) => {
              count[attr.ent_index] = x;
            });
          }
        });
      }
    });
    return count;
  }
  getFormDataFormat(data: any, obj: any) {
    let attributesArray = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && value.constructor.name === 'Object') {
        obj = this.getNestedFormDataFormat(value, [], obj, key);
      }
    }
    for (let [el, vl] of Object.entries(obj)) {
      /* istanbul ignore next */
      if (vl && vl.constructor.name === 'Object' && el != 'nslAttributes') {
        attributesArray.push(vl);
        delete obj[el];
      }
    }
    return attributesArray;
  }

  getNestedFormDataFormat(data: any, nslAttributes: any, obj: any, key: any) {
    let nestedObj = {};
    for (let [key1, value] of Object.entries(data)) {
      /* istanbul ignore next */
      if (value && value?.constructor.name === 'Object') {
        this.getNestedFormDataFormat(value, [], nestedObj, key1);
      } else if (value && value?.constructor.name === 'Array') {
        /* istanbul ignore next */
        nslAttributes.push(this.prepareObgectfromArry(value, key1));
      }
    }
    nestedObj['name'] = key;
    nestedObj['displayName'] = data?.displayName;
    nestedObj['id'] = data?.enty_id;
    nestedObj['type'] = data?.entType;
    nestedObj['isMulti'] = data?.isMulti;
    nestedObj['isPlaceholder'] = data?.isPlaceholder;
    nestedObj['attr_order_num'] = data?.attr_order_num;
    nestedObj['isInPotentiality'] = data.isInPotentiality;
    nestedObj['txnRecordId'] = data.txnRecordId;
    /* istanbul ignore next */
    if (data?.slot) {
      nestedObj['slot'] = data.slot;
    }
    /* istanbul ignore next */
    if (data?.isPlaceholder) {
      nestedObj['value'] = data?.value;
    }
    /* istanbul ignore next */
    if (data?.recordId) {
      nestedObj['recordId'] = data.recordId;
    }
    // optional change drivers related flags starts //
    /* istanbul ignore next */
    if (data?.alternativeChangeDriver) {
      nestedObj['alternativeChangeDriver'] = data.alternativeChangeDriver;
    }
    /* istanbul ignore next */
    if (data?.isOptional) {
      nestedObj['isOptional'] = data.isOptional;
    }
    /* istanbul ignore next */
    if (data?.isNegative) {
      nestedObj['isNegative'] = data.isNegative;
    }
    /* istanbul ignore next */
    if (data?.isHidden) {
      nestedObj['isHidden'] = data.isHidden;
    }
    // optional change drivers related flags ends //

    /* istanbul ignore next */
    if (data?.nestedEntityId) {
      nestedObj['nestedEntityId'] = data.nestedEntityId;
    }
    /* istanbul ignore next */
    if (data?.nestedEntityName) {
      nestedObj['nestedEntityName'] = data.nestedEntityName;
    }

    if (data?.ent_index || data?.ent_index === 0) {
      nestedObj['ent_index'] = data.ent_index;
    }

    if (data?.ent_multiCount) {
      nestedObj['ent_multiCount'] = data.ent_multiCount;
    }
    /* istanbul ignore next */
    nestedObj['masterId'] = data?.masterId;

    nestedObj['nslAttributes'] = nslAttributes;
    for (let [el, vl] of Object.entries(nestedObj)) {
      /* istanbul ignore next */
      if (vl && vl?.constructor?.name === 'Object' && el != 'nslAttributes') {
        nestedObj['nslAttributes'].push(vl);
        delete nestedObj[el];
      }
    }
    /* istanbul ignore next */
    if (data?.design) {
      nestedObj['design'] = JSON.parse(data.design);
    }
    /* istanbul ignore next */
    if (data?.configuration) {
      nestedObj['configuration'] = JSON.parse(data.configuration);
    }
    /* istanbul ignore next */
    if (data?.attributeType) {
      nestedObj['attributeType'] = JSON.parse(data.attributeType);
    }
    this.uiElementDemapper(
      nestedObj['nslAttributes'],
      nestedObj['nestedEntityName']
    );
    obj[key] = nestedObj;
    return obj;
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  /* istanbul ignore next */
  prepareObgectfromArry(values: any, key: any) {
    let obj = {};
    /* istanbul ignore next */
    obj['attrClass'] = values?.[0]?.attrClass;
    /* istanbul ignore next */
    obj['attributeType'] = values?.[0]?.attributeType;
    /* istanbul ignore next */
    obj['id'] = values?.[0]?.attr_id;
    /* istanbul ignore next */
    obj['attr_order_num'] = values?.[0]?.attr_order_num;
    /* istanbul ignore next */
    obj['type'] = values?.[0]?.attrtype;
    /* istanbul ignore next */
    obj['label'] = values?.[0]?.labelType;
    /* istanbul ignore next */
    obj['name'] = values?.[0]?.label.split('$')?.[0];
    /* istanbul ignore next */
    obj['displayName'] = values?.[0]?.attr_displayName;
    /* istanbul ignore next */
    obj['options'] = values?.[0]?.attr_options;
    /* istanbul ignore next */
    obj['design'] = values?.[0]?.design;
    /* istanbul ignore next */
    obj['hideLabelMultiAttr'] = values?.[0]?.hideLabelMultiAttr;
    obj['hideMultiValueIcon'] = values?.[0]?.hideMultiValueIcon;
    /* istanbul ignore next */
    obj['isMulti'] = values?.[0]?.isMulti;
    /* istanbul ignore next */
    obj['isPlaceholder'] = values?.[0]?.isPlaceholder;
    /* istanbul ignore next */
    obj['constraints'] = values?.[0]?.constraints;
    /* istanbul ignore next */
    obj['uiElement'] = values?.[0]?.inputType;
    /* istanbul ignore next */
    obj['isInPotentiality'] = values?.[0]?.isInPotentiality;
    /* istanbul ignore next */
    obj['isHidden'] = values?.[0]?.isHidden; // FE757 - For Hidden Attribute - Attribute level
    /* istanbul ignore next */
    obj['isDisabled'] = values?.[0]?.isDisabled;
    /* istanbul ignore next */
    obj['triggerConditionalPotentiality'] =
      values?.[0]?.triggerConditionalPotentiality;
    /* istanbul ignore next */
    obj['isMultiEntity'] = values?.[0]?.isMultiEntity;
    /* istanbul ignore next */
    obj['isOptional'] = values?.[0]?.isOptional;
    /* istanbul ignore next */
    obj['isNegative'] = values?.[0]?.isNegative;
    /* istanbul ignore next */
    obj['canDownload'] = values?.[0]?.canDownload;
    obj['ent_index'] = values?.[0]?.ent_index;
    obj['ent_multiCount'] = values?.[0]?.ent_multiCount;
    /* istanbul ignore next */
    obj['masterId'] = values?.[0]?.masterId;
    if (values?.[0]?.isPlaceholder) {
      obj['value'] = values?.[0]?.value ? values?.[0]?.value : '';
    }
    /* istanbul ignore next */
    if (values.length > 0) {
      obj['configuration'] = JSON.parse(values?.[0]?.attr_configuration);
    }
    /* istanbul ignore next */
    if (values.length > 0) {
      obj['entity_configuration'] = JSON.parse(
        values?.[0]?.entity_configuration
      );
    }
    /* istanbul ignore next */
    if (values?.[0]?.recordId && values?.[0]?.recordId != '') {
      /* istanbul ignore next */
      obj['recordId'] = values?.[0]?.recordId;
    }
    /* istanbul ignore next */
    if (
      values?.[0]?.alternativeChangeDriver &&
      values?.[0]?.alternativeChangeDriver != ''
    ) {
      /* istanbul ignore next */
      obj['alternativeChangeDriver'] = values?.[0]?.alternativeChangeDriver;
    }
    /* istanbul ignore next */
    if (values?.[0]?.isOptional && values?.[0]?.isOptional != '') {
      /* istanbul ignore next */
      obj['isOptional'] = values?.[0]?.isOptional;
    }
    /* istanbul ignore next */
    if (values?.[0]?.isNegative && values?.[0]?.isNegative != '') {
      /* istanbul ignore next */
      obj['isNegative'] = values?.[0]?.isNegative;
    }
    /* istanbul ignore next */
    if (values?.[0]?.nslAttributeProperties) {
      /* istanbul ignore next */
      obj['nslAttributeProperties'] = values?.[0]?.nslAttributeProperties;
    }
    obj['effectiveUserRights'] = values?.[0]?.effectiveUserRights;
    /* istanbul ignore next */
    if (values?.[0]?.isHidden && values?.[0]?.isHidden != '') {
      /* istanbul ignore next */
      obj['isHidden'] = values?.[0]?.isHidden;
    } else {
      obj['isHidden'] = false;
    }
    /* istanbul ignore next */
    obj['values'] = <any>[];
    /* istanbul ignore next */
    values?.forEach((element: any) => {
      /* istanbul ignore next */
      if (element?.inputType == 'file') {
        /* istanbul ignore next */
        if (element?.[element?.label]?.length > 0) {
          /* istanbul ignore next */
          if (typeof element?.[element?.label] === 'string') {
            /* istanbul ignore next */
            obj?.['values']?.push(element?.[element?.label]);
          } else {
            /* istanbul ignore next */
            for (let value of element?.[element?.label]) {
              if (typeof value == 'object') {
                /* istanbul ignore next */
                obj?.['values']?.push(JSON.stringify(value));
              } else {
                /* istanbul ignore next */
                obj?.['values']?.push(value);
              }
            }
          }
        } else {
          // obj['values'] = [];
          /* istanbul ignore next */
          obj?.['values']?.push('');
        }
      } else if (element?.inputType === 'dateRange') {
        let value;
        /* istanbul ignore next */
        if (element?.[element?.label]) {
          /* istanbul ignore next */
          if (this.IsJsonString(element?.[element?.label])) {
            value = element?.[element?.label];
          } else {
            let dateArr = [];
            if (element?.[element?.label][0]) {
              dateArr.push(element[element.label][0].toISOString());
            }
            if (element?.[element?.label][1]) {
              dateArr.push(element[element.label][1].toISOString());
            }
            value = JSON.stringify(dateArr);
          }
        } else {
          value = '';
        }
        /* istanbul ignore next */
        obj?.['values']?.push(value);
      } else if (
        element?.inputType === 'checkbox' ||
        element?.inputType === 'multiselectdropdown' ||
        element?.inputType === 'chipmultipleselection' ||
        element?.inputType === 'labelwithimage' ||
        element?.inputType === 'label' ||
        this.SfList.includes(element?.inputType) ||
        (element?.attr_configuration &&
          this.IsJsonString(element?.attr_configuration) &&
          JSON.parse(element?.attr_configuration)?.isReadOnly)
      ) {
        /* istanbul ignore next */
        if (element?.[element?.label] == '') {
          obj['values'] = [];
        } else {
          /* istanbul ignore next */
          obj['values'] = Array.isArray(element?.[element?.label]?.[0])
            ? element?.[element?.label]?.[0]
            : element?.[element?.label];
        }
      } else if (element?.inputType === 'chipsingleselection') {
        /* istanbul ignore next */
        if (element?.isMulti) {
          if (
            Array.isArray(element?.[element?.label]) &&
            element?.[element?.label]?.length > 1
          ) {
            element?.[element?.label].splice(0, 1);
          }
          obj['values'].push(
            Array.isArray(element?.[element?.label])
              ? element?.[element?.label][0]
              : element?.[element?.label]
          );
        } else {
          obj['values'] = Array.isArray(element?.[element?.label])
            ? element?.[element?.label]
            : [element?.[element?.label]];
        }
      } else if (
        element?.inputType === 'mobilenumber' &&
        element?.attr_configuration &&
        (JSON.parse(element?.attr_configuration)?.mobileFormat?.displayValue ==
          'USA' ||
          JSON.parse(element?.attr_configuration)?.mobileFormat?.displayValue ==
            'MEXICO')
      ) {
        obj['values'].push(
          element?.[element?.label]
            ? element?.[element?.label].replace(/[^\w]/gi, '')
            : ''
        );
      } else if (
        element?.inputType === 'percentage' &&
        element?.attr_configuration &&
        JSON.parse(element?.attr_configuration)?.percentageType == 'percentage'
      ) {
        let value: any;
        if (element?.[element?.label]) {
          value = element?.[element?.label] * 100;
          value = value.toString();
        }
        obj['values'].push(element?.[element?.label] ? value : '');
      } else if (
        element?.inputType === 'currency' &&
        element?.[element?.label] &&
        this.transacationFacadeService.savedCurrencyType.has(
          element?.entityName
        ) &&
        this.transacationFacadeService.savedCurrencyType
          .get(element?.entityName)
          .has(element?.attrTxnRecordId)
      ) {
        let selectedCurrency = this.transacationFacadeService.savedCurrencyType.get(
          element.entityName
        );
        obj['values'].push(
          parseLocal(
            element?.[element?.label] || '',
            findLocale(
              selectedCurrency.get(element?.attrTxnRecordId).selectedCurrency
            )
          ).toString()
        );
      } else if (
        element?.inputType === 'image' ||
        element?.inputType === 'video' ||
        element?.inputType === 'audio' ||
        element?.inputType === 'imageavatar'
      ) {
        /* istanbul ignore next */
        if (
          element?.inputType === 'image' &&
          this.selectedImages?.length != 0
        ) {
          let arr: any = [];
          this.selectedImages?.forEach((index: any) => {
            arr.push(element?.[element?.label]?.[0]?.[index]);
          });
          element[element.label][0] = arr;
        }
        /* istanbul ignore next */
        obj['values'] = element?.[element?.label]?.[0]
          ? element?.[element?.label]?.[0]
          : [];
      } else if (
        element?.inputType === 'signature' ||
        (element?.inputType === 'location' &&
          element?.nestedEntityName != 'NSL_Geolocation') ||
        element?.inputType === 'MMILocation'
      ) {
        /* istanbul ignore next */
        if (element?.[element?.label]?.label) {
          delete element[element.label].label;
        }
        /* istanbul ignore next */
        if (Array.isArray(element?.[element?.label])) {
          obj['values'] = element?.[element?.label];
        } else if (typeof element?.[element?.label] === 'object') {
          obj['values'] = [JSON.stringify(element?.[element?.label])];
        } else {
          obj['values'] = element?.[element?.label]
            ? [element?.[element?.label]]
            : [];
        }
      } else if (
        element?.inputType === 'location' &&
        element?.nestedEntityName == 'NSL_Geolocation'
      ) {
        if (element[element.label]) {
          if (this.IsJsonString(element[element.label]))
            this.integControlVal = JSON.parse(element[element.label]);
          else
            this.integControlVal = JSON.parse(
              JSON.stringify(element[element.label])
            );
        } else obj['values'] = [];
        if (
          this.integControlVal &&
          this.integControlVal?.[element?.label?.split('$')?.[0]]
        )
          obj['values'].push(
            this.integControlVal[element?.label?.split('$')?.[0]].toString()
          );
        else if (this.integControlVal) {
          obj['values'].push(this.integControlVal.toString());
        }
      } else if (
        element?.inputType === 'typeahead' &&
        element?.[element?.label] !== ''
      ) {
        let option = element?.attr_options?.find(
          (option: any) => option?.displayValue == element?.[element?.label]
        );
        if (option) {
          obj['values'].push(option.actualValue);
        } else {
          obj['values'].push(element?.[element?.label]);
        }
      } else if (
        element?.inputType === 'yearMonth' &&
        element?.[element?.label] !== ''
      ) {
        /* istanbul ignore next */
        obj['values'] = [
          formatDate(element?.[element?.label], 'yyyy-MM', 'en-US'),
        ];
      } else if (
        element?.inputType === 'timeonly' &&
        element?.[element?.label] !== ''
      ) {
        /* istanbul ignore next */
        if (this.checkTimeFormat(element?.[element?.label])) {
          /*if time is of HH:mm:ss format*/
          obj['values'] = [element?.[element?.label]];
        } else {
          obj['values'] = [
            formatDate(element?.[element?.label], 'HH:mm:ss', 'en-US'),
          ];
        }
      } else if (
        element?.inputType === 'timer' &&
        element?.[element?.label] !== ''
      ) {
        /* istanbul ignore next */
        obj['values'] = [element?.[element?.label]];
      } else if (
        (element?.inputType === 'dateonly' ||
          element?.inputType === 'currentdateonly') &&
        element?.[element?.label] !== ''
      ) {
        /* istanbul ignore if */
        if (
          element?.[element?.label].length == 7 &&
          element?.entityName?.includes('NSL_SlotBooking')
        ) {
          obj?.['values']?.push(
            formatDate(element?.[element?.label], 'yyyy-MM', 'en-US')
          );
        } else {
          /* istanbul ignore next */
          obj?.['values']?.push(
            formatDate(element?.[element?.label], 'yyyy-MM-dd', 'en-US')
          );
        }
      } else if (element?.inputType === 'year') {
        /* istanbul ignore next */
        obj?.['values']?.push(
          element?.[element?.label] !== ''
            ? formatDate(element?.[element?.label], 'yyyy', 'en-US')
            : ''
        );
      } else if (element?.inputType === 'time') {
        /* istanbul ignore next */
        if (
          !isNaN(Date.parse(element?.[element?.label])) &&
          element?.label !== ''
        ) {
          obj['values'] = [
            formatDate(element?.[element?.label], 'HH:mm:ss', 'en-US'),
          ];
        } else {
          /* istanbul ignore next */
          if (element?.[element?.label]) {
            obj['values'] = [element?.[element?.label]];
          } else {
            obj['values'] = [];
          }
        }

        let timeValue = obj['values'][0];
        /* istanbul ignore next */
        if (timeValue && typeof timeValue === 'string') {
          /* istanbul ignore next */
          timeValue = timeValue.split(' ')[0];
          timeValue = timeValue.split(':');
          let hr: any = parseInt(timeValue[0]);
          let mm: any = parseInt(timeValue[1]);
          let ss: any = parseInt(timeValue[2]);
          if (hr < 10) {
            hr = '0' + hr;
          }
          if (mm < 10) {
            mm = '0' + mm;
          }
          if (ss < 10) {
            ss = '0' + ss;
          }
          if (hr >= 24) {
            hr = hr - 24;
          }
          /* istanbul ignore next */
          obj['values'] = [hr + ':' + mm + ':' + ss];
        }
      } else if (
        element?.inputType?.toLocaleLowerCase() === 'date' &&
        element?.[element?.label] !== ''
      ) {
        /* istanbul ignore next */
        if (element?.[element?.label] !== null) {
          /* istanbul ignore next */
          obj['values'] = Array.isArray(element?.[element?.label])
            ? element?.[element?.label]
            : [element?.[element?.label]];
        } else if (
          element?.[element?.label] === null &&
          !Array.isArray(obj?.['values'])
        ) {
          obj['values'] = [];
        }
      } else if (element?.inputType === 'autoIdentifier') {
        /* istanbul ignore next */
        obj['values'] =
          element?.[element?.label] == '' ? [] : [element?.[element?.label]];
      } else if (
        element?.inputType == 'text' ||
        element?.inputType == 'textarea' ||
        element?.inputType == 'email' ||
        element?.inputType == 'editor' ||
        element?.inputType == 'input' ||
        element?.inputType == 'textavatar' ||
        element?.inputType === 'currentTime'
      ) {
        /* istanbul ignore next */
        const value = element?.[element?.label];
        /* istanbul ignore next */
        if (
          element?.['entityName'].includes('NSL_FileBrowse') &&
          typeof value != 'string'
        ) {
          value.forEach((val: string) => {
            obj?.['values']?.push(val);
          });
        } else {
          if (
            value &&
            typeof value != 'number' &&
            value.trim() &&
            value.trim() != ''
          ) {
            obj?.['values']?.push(value.trim());
          } else {
            obj?.['values']?.push(value);
          }
        }
      } else if (element?.inputType === 'datetime') {
        /* istanbul ignore next */
        obj?.['values']?.push(
          element?.[element?.label] ? element?.[element?.label] : ''
        );
        // if(element?.nestedEntityName=='NSL_DateRange'){
        // }
      } else if (element?.inputType === 'number') {
        obj?.['values']?.push(
          element?.[element?.label] == 0 || element?.[element?.label]
            ? element?.[element?.label]
            : ''
        );
      } else if (element?.inputType === 'label') {
        obj?.['values']?.push(
          element?.[element?.label] ? element?.[element?.label] : ''
        );
      } else if (element?.inputType === 'dropdown') {
        obj['values'].push(
          element?.[element?.label] ? element?.[element?.label] : ''
        );
      } else if (element?.inputType === 'redact') {
        obj['values'].push(
          element?.[element?.label].length > 0 ? element?.[element?.label] : ''
        );
      } else {
        obj?.['values']?.push(element?.[element?.label]);
      }
      /* istanbul ignore next */
      element.value = element?.[element?.label];
    });
    return obj;
  }

  isEntityClass = false;
  checkForHidden(controls: any, values: any, index: any) {
    let isHidden = false;
    if (controls && values) {
      /* istanbul ignore next */
      isHidden = values[Object.keys(controls)[index]]?.isHidden ? true : false;
    }
    if (values[Object.keys(controls)[index]]?.entityClass) {
      this.isEntityClass = true;
    }
    return isHidden;
  }
  /* istanbul ignore next */
  // validateAllFormFields(formGroup: FormGroup) {
  //   /* istanbul ignore next */
  //   Object.keys(formGroup.controls)?.forEach((control: any) => {
  //     control.markAsTouched();
  //     if (control.controls) {
  //       this.validateAllFormFields(control);
  //     }
  //   Object.keys(formGroup.controls)?.forEach((field) => {
  //     const control = formGroup.get(field);
  //     control.markAsTouched({ onlySelf: true });
  //   });
  // }
  /* istanbul ignore next */
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  isSubmitbuttonenabled(event: any) {
    this.transactionFacadeService.submitButtonDisabling(event);
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    sessionStorage.removeItem('template');
  }

  currentOpendEntity(index: number) {
    this.currentEntityIndex = index;
  }
  //fetching GSI details from API
  getGsiDetails() {
    this.changeUnitFacadeService.relatedChangeUnitListByGSI$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.selectedGsi = res;
          /* istanbul ignore next */
          this.gsiMasterId = res?.masterId;
          /* istanbul ignore next */
          this.gsiName = res?.name;
        }
        /* istanbul ignore next */
        if (this.selectedGsi && this.selectedGsi.solutionLogic) {
          /* istanbul ignore next */
          const foundCU = this.selectedGsi?.solutionLogic?.find(
            (x: any) =>
              x.tfReferencedChangeUnit === Number(sessionStorage.currentRefCuID)
          );
          /* istanbul ignore next */
          if (foundCU) {
            /* istanbul ignore next */
            this.triggerCuIndex = foundCU?.index;
            this.gsiFacade.getCuDetailsById(foundCU);
          }
        }
      });
  }
  //fetching CU details from API
  getCuDetails() {
    this.gsiFacade.getCuDetailsByid$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.subtransactionalCU = res?.result;
        /* istanbul ignore next */
        this.masterCuId = res?.result.masterId;
        /* istanbul ignore next */
        this.cuName = res?.result.name;
      });
  }

  //fetching CU details from API
  getTransactionId(data: any) {
    this.transactionFacadeService.updateTransactionIdData$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          data.transaction = res.result;
        }
      });
  }
  //DialogBox for subtransactional class
  openDialog(templateRef: TemplateRef<any>) {
    /* istanbul ignore next */
    this.changeUnitFacadeService.getrelatedCulistByGsiId(
      this.gsi?.changeUnits?.dsdId
    );

    this.dialog.closeAll();
    this.dialog.open(templateRef, { width: '1100px' });
  }
  //Selecting the component and displaying it
  displayForm(event: any) {
    this.addEntityFromLib = event;
  }
  replaceNestedEntityName(entity: any) {
    entity?.nslAttributes.forEach((attr: any) => {
      if (attr?.nslAttributes) {
        this.replaceEntityName(attr);
      }
    });
  }

  replaceEntityName(entity: any) {
    if (entity?.type == 'entity') {
      for (let value of entity?.name) {
        if (value == '$') {
          entity.name = entity?.name?.substring(0, entity.name.indexOf('$'));
        }
      }
    }
    /* istanbul ignore next */
    if (entity?.nslAttributes) {
      this.replaceNestedEntityName(entity);
    }
  }
  //Removing $ from entity name
  addSubCU() {
    this.expandSubCU = true;
    /* istanbul ignore next */
    this.currentCu?.layers?.forEach((layer: any) => {
      layer?.entityList?.forEach((ent: any) => {
        this.replaceEntityName(ent);
      });
    });
  }

  // nevigating to newsolution
  gotoSolution() {
    if (this.selectedAttr) {
      /* istanbul ignore next */
      let data = {
        subtransactionalGsiData: this.selectedGsi,
        subtransactionalCU: {
          ...this.subtransactionalCU,
          index: this.currentCu?.index,
        },
        transaction: this.currentCu,
        subtransactionCUCondition: this.subtransactionCUCondition,
        subtransactionCUConditionId: this.subtransactionCUConditionId,
      };
      this.getTransactionId(data);
      this.router.navigate(['/newsolution']);
      this.changeUnitFacadeService.subtransactionalCU(data);
      this.libraryfacadeservice.changetoCurrentComp('gsi');
    } else {
      this.alertService.showToaster('Select Trigger Attribute', '', 'info');
    }
  }
  disableSubmitEvent(event: any) {
    this.isSubmitButtonDisable = event;
  }

  getFileNameFromImgUrl(imgUrl: any) {
    return getFileNameFromImgUrl(imgUrl);
  }

  imgError(event: Event) {
    /* istanbul ignore next */
    (event.target as HTMLImageElement).style.display = 'none';
  }

  detectSubmitting() {
    this.transactionFacadeService.submitButtonEmit$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res && this.isTransactionActive) {
          this.isSubmit = true;
          this.onSubmit(res);
        }
      });
  }
  setValidations() {
    this.submitButtonValidations = {
      isSubmitButtonDisable: this.isSubmitButtonDisable,
      showNext: this.showNext,
      invalid: this.form.invalid,
    };
  }
  rowNumberChange(event: any) {
    this.rowNumberChanged = event;
  }
  attributeToEmit(event: any) {
    let indexPadding = this.getIndexPadding(event?.entityIndex + 1);
    event.formatName = 'PL.SL' + indexPadding + '.' + event?.formatName;
    event.formatId = 'PL.SL' + indexPadding + '.' + event?.formatId;
    /* istanbul ignore next */
    if (event != null || event != undefined) {
      this.selectedAttr = true;
    }
    this.subtransactionCUCondition = event?.formatName;
    this.subtransactionCUConditionId = event?.formatId;
  }

  pageChangedInTable(event: any) {
    this.paginationDetails = event;
    this.onSubmit(null);
  }

  checkforNestedEntityDelete(entity: any, event: any) {
    entity.forEach((attr: any, i: number) => {
      if (attr?.name == event?.entityName.split('$')[0]) {
        entity.splice(i + event?.index, 1);
      } else if (attr?.type === 'entity') {
        this.checkforNestedEntityDelete(attr.nslAttributes, event);
      }
    });
  }
  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  getCurrentSelectedImages() {
    this.transactionFacadeService.currentSelectedImages$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        this.selectedImages = res;
      });
  }
  isMultiHidden(configuration: string) {
    if (configuration) {
      let confg = JSON.parse(configuration);
      if (confg?.hideMulti && confg?.hideMulti == true) {
        return true;
      }
    }
    return false;
  }

  // getMultiEntityConfiguration(){}
  getMultiEntityButton(configuration: string) {
    if (configuration) {
      let confg = JSON.parse(configuration);
      if (
        confg?.showMultiEntConf &&
        confg?.showMultiEntConf?.showMultiEntText == true
      ) {
        this.addButtonShape = confg?.showMultiEntConf?.addButtonShape;
        this.removeButtonShape = confg?.showMultiEntConf?.removeButtonShape;
        return true;
      }
    }
    return false;
  }

  displayAddButton(configuration: string) {
    if (configuration) {
      // "{\"showMultiEntConf\":{\"showMultiEntText\":true,\"addButton\":\"a1\",\"removeButton\":\"b1\",\"addButtonShape\":\"Square\",\"removeButtonShape\":\"Square\"}}"
      let confg = JSON.parse(configuration);
      return confg?.showMultiEntConf?.addButton;
    }
  }

  displayRemoveButton(configuration: string) {
    if (configuration) {
      let confg = JSON.parse(configuration);
      return confg?.showMultiEntConf?.removeButton;
    }
  }

  // getClass(configuration:string,type:string){
  //   if(configuration){
  //     let confg=JSON.parse(configuration);
  //     return type=='Add' ? confg?.showMultiEntConf?.addButtonShape : confg?.showMultiEntConf?.removeButtonShape
  //   }
  // }
  getAttributeSubmitEvent() {
    this.eventsService.submitEventSubject$
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((data: any) => {
        /* istanbul ignore next */
        if (data && this.isTransactionActive) {
          if (this.form?.invalid == false) {
            let event = new Event('submit');
            this.onSubmit(event);
          } else if (!this.isInfo) {
            // this.alertService.showToaster('Form is Invalid', '', 'warning');
            this.form.controls[data.entityName].controls[
              data.attrName
            ].controls[0].controls[data.attrName].setValue('');
            this.form.updateValueAndValidity();
            this.form.markAllAsTouched({ onlySelf: true });
          }
        }
      });
  }

  showMatTabForEntity(checkForHidden?: any) {
    if (this.layoutConfigurations) {
      const value =
        !this.iscarnivalscreen &&
        (this.layoutConfigurations.showMatTabForEntity ||
          (this.layoutConfigurations.showMatTabForEntityInfo && this.isInfo) ||
          (this.layoutConfigurations.showMatTabForEntityPhy && !this.isInfo));
      if (value && checkForHidden) {
        const nonHiddednTabs = this.tempEntityList
          ? this.tempEntityList?.filter((a: any) => !a.isHidden)
          : this.tempInfoEntList?.filter((a: any) => !a.isHidden);
        return nonHiddednTabs?.length;
      }
      return value;
    }
    return false;
  }

  goToViewAll(index: any) {
    sessionStorage.setItem('entityTabIndex', index);
    console.log(index);
  }

  nextAccordian(index: any) {
    this.selectedIndex = index + 1;
    /* istanbul ignore next */
    if (sessionStorage.getItem('entityTabIndex')) {
      var str = sessionStorage.getItem('entityTabIndex');
      var num = +str;
      this.selectedIndex = num;
      sessionStorage.removeItem('entityTabIndex');
    }
    if (this.entityList[this.selectedIndex]?.isHidden) {
      while (
        this.entityList[this.selectedIndex] &&
        this.entityList[this.selectedIndex].isHidden
      ) {
        this.selectedIndex = this.selectedIndex + 1;
      }
    }
  }

  getLastUnhiddenEntityIndex() {
    for (let i = this.entityList?.length - 1; i >= 0; i--) {
      if (!this.entityList?.[i]?.isHidden) {
        this.LastUnhiddenEntIndex = i;
        break;
      }
    }
  }

  getFirstUnhiddenEntityIndex() {
    for (let i = 0; i < this.entityList?.length; i++) {
      if (!this.entityList?.[i]?.isHidden) {
        this.firstUnhiddenEntIndex = i;
        break;
      }
    }
  }
  previousAccordian(index: any) {
    this.selectedIndex = index - 1;
    if (this.entityList[this.selectedIndex].isHidden) {
      while (
        this.entityList[this.selectedIndex] &&
        this.entityList[this.selectedIndex].isHidden
      ) {
        this.selectedIndex = this.selectedIndex - 1;
      }
    }
  }

  tableUploaded(data: any) {
    this.setEntityListAndTriggerFormChange(this.form.value);
    if (data?.singleRowUpload) {
      this.createFormData(true);
    }
  }

  saveSearchFilters() {
    this.filterData.layers = this.filterData?.layers?.filter((layer: any) => {
      return layer?.type == 'information';
    });
    if (this.paginationDetails?.pageSize) {
      this.filterData.layers[0].entityList[1] = this.facetedData;
      if (this.isFacetSearchCu) {
        this.transactionFacadeService.facetedData = this.facetedData;
      }
      this.pageChangedInTable(this.paginationDetails);
    } else {
      // this.filterData.layers[0].entityList = [];
      this.filterData.layers[0].entityList[0] = this.facetedData;
      this.transacationFacadeService.facetSearch(
        this.filterData,
        this.paginationDetails
      );
    }
  }

  resetFilters() {
    this.facetedData?.nslAttributes?.forEach((attr: any) => {
      attr.values = [];
    });
  }

  onFacetFilterChange(selectedOption: MatCheckboxChange, attrIdx: number) {
    if (attrIdx >= 0) {
      if (selectedOption.checked) {
        if (
          !this.facetedData.nslAttributes[attrIdx].values.includes(
            selectedOption.source.value
          )
        )
          this.facetedData.nslAttributes[attrIdx].values.push(
            selectedOption.source.value
          );
      } else {
        if (
          this.facetedData.nslAttributes[attrIdx].values.includes(
            selectedOption.source.value
          )
        )
          this.facetedData.nslAttributes[attrIdx].values.pop(
            selectedOption.source.value
          );
      }
    }
    // this.saveSearchFilters() ;
  }

  updateEntitieswithEntIndex(entityList: any) {
    let crypto: Crypto = window.crypto;
    let array = new Uint32Array(1);
    let randomNumber = crypto.getRandomValues(array)[0];
    randomNumber = Number('0.' + randomNumber);
    entityList?.forEach((input_template: any, index: number) => {
      let ent_index = input_template.slot;
      input_template['txnRecordId'] = input_template['txnRecordId']
        ? input_template['txnRecordId']
        : Math.floor(randomNumber * 100000000000 + 1);
      input_template['ent_index'] = ent_index;
      if (input_template?.type === 'entity') {
        this.checkNestedEntities(input_template, index);
      }
    });
  }

  checkNestedEntities(input_template: any, index: number) {
    input_template?.nslAttributes?.forEach((entity_template: any, ind: any) => {
      let ent_index;
      if (entity_template?.type === 'entity') {
        /* istanbul ignore next */
        let crypto: Crypto = window.crypto;
        let array = new Uint32Array(1);
        let randomNumber = crypto.getRandomValues(array)[0];
        randomNumber = Number('0.' + randomNumber);
        entity_template['txnRecordId'] = entity_template['txnRecordId']
          ? entity_template['txnRecordId']
          : Math.floor(randomNumber * 10000000000 + 1);
        ent_index = index + 1;
        entity_template['ent_index'] = ent_index;
        this.checkNestedEntities(entity_template, index);
        if (
          input_template?.nslAttributes?.[ind + 1] &&
          input_template?.nslAttributes?.[ind + 1]?.type === 'entity'
        ) {
          if (
            input_template?.nslAttributes?.[ind + 1]?.nestedEntityName !==
              entity_template?.nestedEntityName &&
            input_template?.nslAttributes?.[ind + 1]?.nestedEntityId !==
              entity_template?.nestedEntityId
          ) {
            index = index + 1;
          }
        }
      } else {
        entity_template['txnRecordId'] = input_template['txnRecordId'];
      }
    });
  }

  uiElementDemapper(nslAttributes: any, nestedEntityName: string) {
    nslAttributes?.forEach((attr: any) => {
      if (
        this.uiElementDemap[attr?.uiElement] &&
        this.integNestedEnt?.includes(nestedEntityName)
      ) {
        attr.uiElement = this.uiElementDemap[attr?.uiElement];
      }
    });
  }

  downloadAllFiles(id: any) {
    this.tempEntityList?.forEach((entity: any) => {
      entity?.nslAttributes?.forEach((attribute: any) => {
        if (id === entity.txnRecordId) {
          if (
            attribute?.name === entity?.configuration?.selectedAttributeFormat
          ) {
            this.formatAttribute = attribute;
          }
          if (attribute?.uiElement === 'file' && !attribute.isHidden) {
            this.filesArray?.push(JSON.parse(attribute?.values[0])?.contentUrl);
          }
        }
      });
    });
    this.createZip(this.filesArray, 'Sample');
  }
  async getFile(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    const res = await this.httpClient
      .get(url, httpOptions)
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        const error = err.error;
        return error;
      });
    return res;
  }
  async createZip(files: any[], zipName: string) {
    const zip = new JSZip();
    const name = `${this.formatAttribute?.values}` + '.zip';
    for (let i = 0; i < files.length; i++) {
      const element = this.filesArray[i];
      const fileData: any = await this.getFile(element);
      const b: any = new Blob([fileData], { type: '' + fileData?.type + '' });
      /* istanbul ignore next */
      zip.file(element?.substring(element.lastIndexOf('/') + 1), b);
    }
    /* istanbul ignore next */
    zip.generateAsync({ type: 'blob' }).then((content) => {
      if (content) {
        FileSaver.saveAs(content, name);
      }
    });
    this.filesArray = [];
  }

  trackByFn(index: number, item: any): any {
    return item.id; // Use a unique identifier property from your items
  }

  checkTimeFormat(value: any) {
    if (/^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(value))
      return true;
    return false;
  }

  checkMultiFileDownload() {
    this.entityList?.forEach((entity: any, index: number) => {
      entity?.nslAttributes?.forEach((attribute: any) => {
        if (attribute?.configuration?.multipleFileDownload) {
          // let entName = entity.name.split('$')[0];
          this.checkmultipleFileDownloadIndex.push(entity.name);
        }
      });
    });
  }

  sendDataForMultiFileDownload(entityName: any) {
    let files: any = [];
    this.entityList?.forEach((entity: any) => {
      if (entity.name == entityName) {
        entity?.nslAttributes?.forEach((attribute: any) => {
          /*istanbul ignore next */
          if (attribute?.uiElement == 'file') {
            attribute?.values?.forEach((value: any) => {
              let fileData = JSON.parse(value);
              files.push(fileData.contentUrl);
            });
          }
        });
      }
    });
    return files;
  }

  downloadAllMultipleFilesAtOnce(entityName: any) {
    let files = this.sendDataForMultiFileDownload(entityName);
    this.eventsService
      .downloadMultipleFiles(files)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        let url = res.result;
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            import('file-saver').then((fileSaver) => {
              (fileSaver as any).default.saveAs(blob, 'multiplefileDownload');
            });
          });
      });
  }
  viewAllMultipleFilesAtOnce(entityName: any) {
    let url;
    let files = this.sendDataForMultiFileDownload(entityName);
    this.eventsService
      .downloadMultipleFiles(files)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        url = res.result;
        let data: any = {
          canDownload: false,
          contentUrl: url,
          displayName: 'multipleDownload',
          name: 'multifileDownload',
        };
        /*istanbul ignore next */
        this.transacationFacadeService.filepreviewDataTransaction.next({
          contentUrl: url,
        });
        this.dialogService.open(FileUploadPreviewComponent, {
          minHeight: '400px',
          minWidth: '700px',
          maxWidth: '1000px',
          data: data,
          panelClass: 'fileUploadPreviewDialog',
        });
      });
  }

  getWayPointsData(event: any) {
    this.wayPointsData = event;
  }
  updateWaypointsinForm(event: any) {
    this.createFormData(true);
  }

  updateTableData(event: any) {
    this.attributesArray = [];
    this.checkEntitieswithCardandTable(true);
    let transEntityRecords = tableDataPayload({
      data: this.attributesArray.slice(1),
    });
    let payload = {
      name: this.attributesArray[1]?.name.split('$')[0],
      generalEntityID: this.attributesArray[1]?.id,
      slot: this.attributesArray[1]?.slot,
      transEntityRecords: transEntityRecords.content,
      isLastBatch: false,
      isMasterData: false,
      isBulkImport: false,
    };
    this.transactionEndpointService
      .updateTableData(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.alertService.showToaster(
            res.message,
            '',
            res.status == 200 ? 'success' : 'error'
          );
        }
      });
  }
  getSelectedEditableRow(event: any) {
    this.selectedEditableRow = event.selectedEditableRow;
  }
  onOpened(ind: any) {
    this.accordianIndex = ind;
  }
}
