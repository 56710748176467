<div class="scroll-div">
  <div *ngIf="!messagesflag && toasts.length>0" class="toaster-actions">
    <button class="toaster-close-all" id="closeAll" (click)="removeall()">
      Close all
    </button>
    <button class="toaster-close" id="toasterLength" (click)="showmessages()">
      <span>{{ toasts.length }} <img src="../../assets/images/Down-arrow.svg" alt="" /></span>
    </button>
  </div>
  <span *ngIf="!messagesflag" class="toaster-panel">
    <ng-container *ngFor="let toast of toasts; let i = index">
      <div class="toast fadeInUp toast-{{ toast?.typeOfAlert }}" [style.bottom.px]="i * 66" (click)="remove(i)">
        <figure class="toaster-icons">
          <img [src]="imageOBJ[toast?.typeOfAlert]" alt="" />
        </figure>
        <div class="toast-section d-flex justify-content-between align-items-center">
          <div class="toast-content d-flex flex-column justify-content-between">
            <h1 class="content-heading">{{ toast?.typeOfAlert }}</h1>
            <p class="content-message">{{ toast?.message }}</p>
          </div>
          <a class="close-icon" id="{{ 'closeToast_' + i }}" (click)="remove(i)">&times;</a>
        </div>
      </div>
    </ng-container>
  </span>
</div>
<div class="toaster-panel position-fixed">
  <ng-container *ngIf="toasts.length > 0 && messagesflag">
    <div [ngClass]="{ fadeInUp: newToasterFlag }">
      <div class="toast  toast-{{ toasts[0]?.typeOfAlert }}" [style.bottom.px]="0 * 66">
        <figure class="toaster-icons">
          <img [src]="imageOBJ[toasts[0]?.typeOfAlert]" alt="" />
        </figure>
        <div class="toast-section d-flex justify-content-between align-items-center">
          <div class="toast-content d-flex flex-column justify-content-between" (click)="remove(toasts.length - 1)">
            <h1 class="content-heading">{{ toasts?.[0]?.typeOfAlert }}</h1>
            <p class="content-message">{{ toasts?.[0]?.message }}</p>
          </div>
          <button *ngIf="toasts.length > 1" id="toggle" class="toaster-open" type="button" (click)="showmessages()">
            <span>{{ toasts.length }} <img src="../../assets/images/up-arrow.svg" alt="" /></span>
          </button>
          <a class="close-icon" id="closeToast" (click)="remove(toasts.length - 1)">&times;</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
