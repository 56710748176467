import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig, getTenantName, getUiControlCustomization ,AttributeStoreService} from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-chip-single',
  styleUrls: ['./chip-single.component.scss'],
  template: `
    <div class="{{ parentClass }}" [formGroup]="group" [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }">
    <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
        <label
          [style.color]="field.color"
          pTooltip="{{ field?.label }}"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          class="main-label nh-mb-0"
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          ></label
        >
        <p
          class="nh-ml-6 d-flex main-label-helperIcon"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
        </div>
        <div
        class="d-flex align-items-center nh-column-gap-5 pb-2 multivalue-btns"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true' || field.isMulti"
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            class="d-flex align-items-center justify-content-center"
            matSuffix
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          >
            <span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            id="radioClick_{{ field.attribute.name }}"
            (click)="addAttribute.next()"
            >add</mat-icon
          >
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            id="radioClick_{{ field.attribute.name }}"
            (click)="removeAttribute.next()"
            >remove</mat-icon
          >
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div
      class="table-chip-selection sub-right-class"
      *ngIf="allImgUrls.length === 0 && isCustomizationApplied"
      [ngClass]="{ 'mini-popup': (minimumOptions == field?.options?.length && field?.attribute?.['isTableConfig']) }"
    >
      <p-selectButton
        styleClass="{{ appliedStyleClass }}"
        class="{{ appliedClass }}"
        [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels) ? (field?.options | slice: 0:minimumOptions) : field?.options"
        [(ngModel)]="selectedField"
        multiple="multiple"
        [formControlName]="field.attribute.name"
        id="{{ field?.attribute?.name }}"
        (onChange)="onSelectionChange($event)"
        optionLabel="displayValue"
        [disabled]="field.readonly"
        optionValue="actualValue"
        [required]="field?.isRequired && !field?.isInfo"
      >
        <ng-template let-item>
        <span
          [pTooltip]="field?.configuration?.hideTooltip ? '' : item.displayValue"
          tooltipPosition="top"
          class="text-truncate"
          [ngClass]="{ disable: item?.count == 0 && field?.entityName?.includes('NSL_SlotBooking') }"
          >{{
            item.displayValue
          }}</span>
          <i *ngIf="foundObject?.event !== 'Option 6'" [class]="item.icon"></i>
        </ng-template>
      </p-selectButton>
      <p-selectButton
        *ngIf="field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels"
        styleClass="{{ appliedStyleClass }} more-less"
        class="more-less"
        [options]="showAllOptions ? hideOptionBtn : showOptionsBtn"
        (onChange)="OnSelection($event)"
      ></p-selectButton>
    </div>
    <div
      class="table-chip-selection"
      *ngIf="allImgUrls.length === 0 && !isCustomizationApplied"
      [ngClass]="{ 'mini-popup': (minimumOptions == field?.options?.length && field?.attribute?.['isTableConfig']) }"
    >
      <p-selectButton
        [styleClass]="returnChipClass()"
        class="{{ appliedClass }}"
        [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels) ? (field?.options | slice: 0:minimumOptions) : field?.options"
        [(ngModel)]="selectedField"
        multiple="multiple"
        [formControlName]="field.attribute.name"
        id="{{ field?.attribute?.name }}"
        (onChange)="onSelectionChange($event)"
        optionLabel="displayValue"
        [disabled]="field.readonly"
        optionValue="actualValue"
        [required]="field?.isRequired && !field?.isInfo"
      >
      <ng-template let-item pTemplate>
          <span
            [pTooltip]="field?.configuration?.hideTooltip ? '' : item.displayValue"
            tooltipPosition="top"
            class="text-truncate"
            [ngClass]="{ disable: item?.count == 0 && field?.entityName?.includes('NSL_SlotBooking') }"
            >{{
            item.displayValue
          }}</span>
        </ng-template>
      </p-selectButton>
      <p-selectButton
        *ngIf="field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels"
        [styleClass]="
          tenantName == 'healthcaremx' || 'projectemri'
            ? 'chip_single_selection chip_single_selection_med more-less'
            : 'chip_single_selection more-less'
        "
        [styleClass]="
          tenantName === 'projectemri'
            ? 'chip_single_selection chip_single_selection_projectemri more-less'
            : tenantName === 'healthcaremx'
            ? 'chip_single_selection chip_single_selection_med more-less'
            : 'chip_single_selection more-less'
        "
        class="more-less"
        [options]="showAllOptions ? hideOptionBtn : showOptionsBtn"
        (onChange)="OnSelection($event)"
      ></p-selectButton>
    </div>
      <div
        class="table-chip-selection"
        *ngIf="allImgUrls.length >= 1"
        [ngClass]="{ 'mini-popup': (minimumOptions == field?.options?.length && field?.attribute?.['isTableConfig']) }"
      >
        <p-selectButton  *ngIf="field?.configuration?.selectionType === 'chipDisplayWithImage'"
          class="chipwithimage"
          [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels) ? (field?.options | slice: 0:minimumOptions) : field?.options"
          [(ngModel)]="selectedField"
          multiple="multiple"
          [formControlName]="field.attribute.name"
          id="{{ field?.attribute?.name }}"
          (onChange)="onSelectionChange($event)"
          optionLabel="displayValue"
          [disabled]="field.readonly"
          optionValue="actualValue"
          [required]="field?.isRequired && !field?.isInfo"
        >
          <ng-template let-option let-i="index">
          <img [src]="allImgUrls[i]" /><span
            [pTooltip]="field?.configuration?.hideTooltip ? '' : option.displayValue"
            tooltipPosition="top"
            class="text-truncate"
            [ngClass]="{ disable: option?.count == 0 && field?.entityName?.includes('NSL_SlotBooking') }"
            >{{ option.displayValue }}</span
          >
        </ng-template>
      </p-selectButton>
      <!-- this is for only chip as image -->
      <p-selectButton
        *ngIf="field.configuration.selectionType === 'chipDisplayAsImage'"
        class="chipwithimage"
        [options]="(field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels) ? (field?.options | slice: 0:minimumOptions) : field?.options"
        [(ngModel)]="selectedField"
        multiple="multiple"
        [formControlName]="field.attribute.name"
        id="{{ field?.attribute?.name }}"
        (onChange)="onSelectionChange($event)"
        optionLabel="displayValue"
        [disabled]="field.readonly"
        optionValue="actualValue"
        [required]="field?.isRequired && !field?.isInfo"
      >
        <ng-template let-option let-i="index">
          <img [src]="allImgUrls[i]" />
        </ng-template>
      </p-selectButton>
      <p-selectButton
        class="more-less"
        *ngIf="field?.attribute?.['isTableConfig'] && field?.options?.length > 3 && !field?.hideOptionLabels"
        styleClass="chip_single_selection"
        [options]="showAllOptions ? hideOptionBtn : showOptionsBtn"
        (onChange)="OnSelection($event)"
      ></p-selectButton>
    </div>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
  </div>`,
  styles: [],
})
export class ChipSingleComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  selectedField: string[] = [];
  imageUrls: string[] = [];
  allImgUrls: string[] = [];
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  showAllOptions: boolean = false;
  minimumOptions: number = 3;
  hideOptionBtn: any[] = [{ label: 'X', value: 'off' }];
  showOptionsBtn: any[];
  chipDataChanged: Subject<any> = new Subject();
  foundObject: any;
  appliedClass: string = '';
  appliedStyleClass: string = 'chip_single_selection';
  isCustomizationApplied: boolean = false;
  tenantName: string;
  parentClass: string = 'demo-full-width';

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService, private attributeStoreService:AttributeStoreService) {
    this.tenantName = getTenantName();
  }

  ngOnInit(): void {
    let optionsCount = this.field?.options?.length - this.minimumOptions;
    this.showOptionsBtn = [{ label: `+${optionsCount}`, value: 'on' }];
    if(this.field?.entityName?.includes('NSL_SlotBooking')){
      this.field.value = '';
    }
    if (this.field?.value) {
      this.selectedField.push(Array.isArray(this.field?.value) ? this.field?.value[0] : this.field?.value);
    }
    if (
      (this.field?.configuration?.imageUrlwithChip &&
        this.field?.configuration?.selectionType == 'chipDisplayWithImage') ||
      (this.field?.configuration?.imageUrlChip && this.field?.configuration?.selectionType == 'chipDisplayAsImage')
    ) {
      this.imageUrls = this.field?.configuration?.imageUrlwithChip
        ? this.field?.configuration?.imageUrlwithChip
        : this.field?.configuration?.imageUrlChip;
      /* istanbul ignore next */
      this.imageUrls.forEach((url: any) => {
        this.allImgUrls.push(url.contentUrl);
      });
    }
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    let chipOption = getUiControlCustomization('Chips');
    if (chipOption) {
      this.foundObject = { event: chipOption };
      this.isCustomizationApplied = true;
      if (chipOption == 'Option 7') {
        this.parentClass = 'chips-parent-class-styles';
      } else {
        this.appliedStyleClass = '';
        let icon: string;
        switch (chipOption) {
          case 'Option 1':
            icon = 'bi bi-plus-circle';
            break;
          case 'Option 2':
            icon = 'bi bi-plus-circle';
            break;
          case 'Option 3':
            icon = 'bi bi-check2';
            break;
          case 'Option 4':
            icon = 'bi bi-circle';
            break;
          case 'Option 5':
            icon = 'bi bi-plus-lg';
            break;
          case 'Option 6':
            icon = 'bi bi-x-lg';
            break;
        }
        this.field?.options?.forEach((op: any, ind: number) => {
          op = { ...op, icon: icon };
          this.field.options[ind] = op;
        });
        let option = chipOption.slice(-1);
        this.appliedClass = `forms-chips-selection chips-selection-opt${option}`;
      }
    }
  }
  onSelectionChange(event: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore else */
      if (event?.value?.length > 1) {
        event?.value.splice(0, 1);
      }
      this.field.value = event?.value?.length > 0 ? event?.value[0] : '';

      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore else */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.value !== '' && this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
      event?.originalEvent?.stopPropagation();
    }
     /* istanbul ignore next */
     if(this.selectedField){
      this.attributeStoreService.setStateData(this.selectedField[0]);
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  OnSelection(event: any) {
    this.minimumOptions = this.showAllOptions ? 3 : this.field?.options?.length;
    this.showAllOptions = !this.showAllOptions;
  }

  returnChipClass(): string {
    /* istanbul ignore next */
    if (this.tenantName === 'projectemri') {
      return 'chip_single_selection chip_single_selection_projectemri';
    } else if (this.tenantName === 'healthcaremx') {
      if (
        !this.field?.isInfo &&
        (this.group?.controls)[this.field?.attribute?.name]?.invalid &&
        (this.group?.controls)[this.field?.attribute?.name]?.touched
      ) {
        return 'chip_single_selection invalid-chip';
      }
      return 'chip_single_selection chip_single_selection_med';
    } else {
      return 'chip_single_selection';
    }
  }
}
