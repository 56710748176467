<section [ngClass]="templateCSS[loginDesign?.templateType]">
  <figure>
    <img
      [src]="loginDesign?.backGround?.link?.desktop"
      alt=""
      onerror="'../../../../../assets/images/mcc/login-img.png'"
    />
  </figure>
  <div class="shelf-inner">
    <div class="logo d-none">
      <figure class="logo-bg">
        <img src="../../../../../assets/images/mcc/logo-bg.png" alt="" />
      </figure>
      <figure class="logo-img">
        <img src="../../../../../assets/images/mcc/logo.png" alt="" />
      </figure>
    </div>
    <div class="container">
      <div class="left">
        <article class="box">
          <figure class="box-img">
            <img
              [src]="loginDesign?.backGround?.link?.desktop"
              alt=""
              onerror="'../../../../../assets/images/mcc/login-img.png'"
            />
          </figure>
          <div>
            <h3 class="box-title">
              <span>{{ loginDesign?.caption?.heading }}</span>
            </h3>
            <p class="box-para">
              <span>{{ loginDesign?.caption?.text }}</span>
            </p>
          </div>
        </article>
      </div>
      <div class="right">
        <div class="login-card" *ngIf="!forgotPasswordScreen">
          <h2 class="title">
            <span>{{ loginDesign?.headingText?.text }}</span>
          </h2>
          <div class="form-section">
            <form *ngIf="!otpLogin && !twofactorverification" [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
              <div class="form-group">
                <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                  <input
                    matInput
                    type="text"
                    id="userName"
                    autocomplete="username"
                    placeholder="Enter Your Username"
                    formControlName="username"
                  />

                  <span class="form-icon login-email-icon"></span>
                </mat-form-field>
                <small
                  [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                  class="text-danger"
                  name="otpNumber"
                  (keydown)="checkForNumbers($event)"
                  class="text-danger"
                  translate
                >
                  {{ labels?.Usernameisrequired }}
                </small>
              </div>

              <div class="form-group">
                <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                  <input
                    matInput
                    [type]="showPassword ? 'text' : 'password'"
                    id="passWord"
                    formControlName="password"
                    autocomplete="current-password"
                    [placeholder]="labels?.Password"
                    required
                  />
                  <div class="toggle-eye-icon">
                    <mat-icon (click)="toggleShowPassword()">
                      {{ showPassword ? 'visibility' : 'visibility_off' }}
                    </mat-icon>
                  </div>
                </mat-form-field>
                <small
                  [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                  class="text-danger"
                  translate
                >
                  {{ labels?.Password_is_required }}
                </small>
              </div>
            </form>
            <form *ngIf="otpLogin && !twofactorverification" [formGroup]="loginForm" novalidate>
              <div class="form-container otplogin viamobile">
                <div class="form-field row">
                  <div class="col-auto w-100">
                    <div class="form-group">
                      <div class="ui-custom-select">
                        <mat-form-field appearance="fill" floatLabel="never">
                          <mat-select
                            panelClass="ui-custom-select-options"
                            [(ngModel)]="selectedCountryCode"
                            [ngModelOptions]="{ standalone: true }"
                          >
                          <mat-option *ngFor="let country of countryCodes" [value]="country['countryCode']"
                          >{{ country['countryName'] }} : {{ country['countryCode'] }}</mat-option
                        >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm">
                    <div class="form-group">
                      <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                        <input
                          matInput
                          type="tel"
                          id="mobileNumberRegistered"
                          name="mobileNumber"
                          [(ngModel)]="mobileNumber"
                          placeholder="Registered Mobile Number"
                          (keydown)="mobileNumberEntered(mobileNumber)"
                          (keypress)="acceptMobileNumber($event)"
                          [ngModelOptions]="{ standalone: true }"
                          required
                        />
                        <span class="form-icon phone-icon"></span>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="otpvalid" *ngIf="mobileNumberFieldEntered">
                  <div class="form-field">
                    <div class="form-group">
                      <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                        <input
                          matInput
                          type="text"
                          maxlength="6"
                          size="6"
                          class="form-control"
                          [(ngModel)]="otpCapture"
                          id="otpReceived"
                          placeholder="Enter OTP"
                          [ngModelOptions]="{ standalone: true }"
                          (keypress)="numberOnly($event)"
                          required
                        />
                        <span class="form-icon phone-icon"></span>
                      </mat-form-field>
                    </div>
                    <div class="otpvalid-info" *ngIf="otpValidval">
                      <span class="otpText">OTP valid for</span>
                      <span>{{ display }}</span>
                    </div>
                    <div class="otpdidnt" *ngIf="resendOTP">
                      <span class="otpText">Didn't receive OTP?</span>
                      <span (click)="resendOTPtoMobile()">Resend OTP</span>
                    </div>
                  </div>
                </div>

                <div *ngIf="displayTermsField">
                  <span (click)="openTncDialog(termsAndConditions)"
                    >I have read and Agree to all the <a class="terms">{{ labels?.terms_and_conditions }}*</a>
                  </span>
                </div>
                <div class="submit-button-wrapper">
                  <div class="login-otp-cls" *ngIf="!otpSubmitButtonVisibility">
                    <div class="footer-cls">
                      <button id="sentOTPbtn" class="btn-custom" (click)="generateOTP()">
                        Send OTP
                      </button>
                    </div>
                  </div>
                  <div class="footer-cls" *ngIf="otpSubmitButtonVisibility">
                    <button
                      class="btn-custom"
                      type="submit"
                      [disabled]="
                        isLoading ||
                        (otpLogin && !mobileNumberFieldEntered) ||
                        !mobileNumber ||
                        (mobileNumberFieldEntered && !otpCapture)
                      "
                      (click)="validateOTP()"
                      id="submitBtn"
                    >
                      <em class="fas fa-cog fa-spin" [hidden]="!isLoading"></em>
                      <span *ngIf="otpLogin">{{ labels?.Submit }}</span>
                    </button>
                  </div>
                  <div>
                    <a
                    *ngIf="otpLogin && enableLoginViaEmail"
                    class="loginOTP loginotpclr"
                    (click)="cancelForgotPassword()"
                      >Login via Email</a
                    >
                  </div>
                </div>
              </div>
            </form>
            <div class="login-right-content" *ngIf="twofactorverification">
              <div class="w-100">
                <div>
                  <h4 class="2fa-title">2 Factor Authentication</h4>
                </div>
                <form>
                  <div class="form-field">
                    <input
                      type="email"
                      class="form-control"
                      id="registeredEmailId"
                      email
                      [(ngModel)]="otp"
                      name="email"
                      required
                      placeholder="Enter OTP"
                    />
                  </div>
                  <div class="footer-cls">
                    <button class="btn-custom" id="forgotSubmit" (click)="clickSubmit()">{{ labels?.Submit }}</button>
                    <button
                      class="btn-custom"
                      id="forgotPswdCancel"
                      (click)="resendOtp()"
                      [disabled]="timeLeft > 0"
                      [ngClass]="{ 'my-class': timeLeft > 0 }"
                    >
                      Resend
                    </button>
                  </div>
                  <div class="footer-resend-text">
                    <p *ngIf="timeLeft != 0">
                      Re-send OTP in <span>{{ timeLeft }}</span> seconds
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div *ngIf="!otpLogin && !twofactorverification">
            <div class="forgot-text" id="forgotPasswords" (click)="forgotClick()">
              <a id="forgot-password" class="text">Forgot Password?</a>
            </div>
            <div class="login-btns">
              <div class="login-cta">
                <button type="submit" class="login-btn" (click)="login()">Login</button>
              </div>

              <div class="or orx" *ngIf="signupProperties2fa?.sessionType != '2fa' && enableLoginViaOtp">or</div>

              <div *ngIf="signupProperties2fa?.sessionType != '2fa' && enableLoginViaOtp" class="login-mobile">
                <p class="blue-text" (click)="otpLoginClick()">Sign In via Mobile</p>
              </div>
            </div>
          </div>
          <div *ngIf="identityProvidersList?.length > 0 && !twofactorverification" class="social-logins">
            <div class="top-level">
              <div class="login-via">
                or continue with
              </div>
              <div class="signupwith" *ngFor="let provider of identityProvidersList">
                <a href="javascript:;" *ngIf="provider.isImageExist">
                  <img
                    src="../../assets/img/login/icon-{{ provider?.alias }}.svg"
                    (click)="socialLogin(provider?.alias)"
                    alt=""
                  />
                </a>
                <a href="javascript:;" *ngIf="!provider.isImageExist" class="provider-btn">
                  <p (click)="socialLogin(provider?.alias)">{{ provider?.displayName }}</p>
                </a>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div class="sign-up-text" *ngIf="tenantName === 'selfonboard' || allowSelfSignup">
            <p class="text-left">I don’t have an account</p>
            <p class="text-right" (click)="selfonboardSignUp()">Sign Up</p>
          </div>
        </div>
        <div class="login-card" *ngIf="forgotPasswordScreen">
          <h1 class="title" [ngStyle]="{ color: loginDesign?.headingText?.fontColor }">
            Forgot Password ?
          </h1>
          <div class="form-section">
            <form>
              <div class="form-group">
                <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
                  <input
                    matInput
                    type="text"
                    id="userName"
                    autocomplete="username"
                    placeholder="Enter Registered Email Address"
                    [(ngModel)]="forgotPasswordUserName"
                    [ngModelOptions]="{ standalone: true }"
                  />

                  <span class="form-icon login-email-icon"></span>
                </mat-form-field>
              </div>

              <div class="login-btns">
                <div class="login-cta">
                  <button
                    [ngStyle]="{
                      background: loginDesign?.actionButton?.buttonColor,
                      color: loginDesign?.actionButton?.fontColor
                    }"
                    class="login-btn"
                    (click)="fpclickSubmit()"
                  >
                    Submit
                  </button>
                  <button
                    [ngStyle]="{
                      background: loginDesign?.actionButton?.buttonColor,
                      color: loginDesign?.actionButton?.fontColor
                    }"
                    class="login-btn"
                    (click)="fpcancelClick()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
