import { NslAttribute } from '@common-services';
import { IConfigData } from './node-canvas.model';
export class NodeCanvasUtils {
  static loadMoreLables(labels: any): LabelType[] {
    return [
      //{ label: 'Change before', active: false, existingCus: [], show: true, class: 'adding' },
      {
        label: labels?.Change_Unit,
        active: false,
        show: true,
        class: 'adding',
        existingCus: [
          { label: labels?.Sequence, active: false, show: true, class: 'sequential', existingCus: [] },
          { label: labels?.Alternate, active: false, show: true, class: 'alternate', existingCus: [] },
          { label: labels?.Nested, active: false, show: true, class: 'nested-menu', existingCus: [] },
          { label: labels?.Embeded, active: false, existingCus: [], class: 'embedded', show: true },
          { label: labels?.Parallel, active: false, existingCus: [], class: 'parallel', show: true },
          { label: labels?.Exception, active: false, existingCus: [], class: 'exception', show: true },
        ],
      },
      { label: labels?.Delete, active: false, existingCus: [], show: true, class: 'delete' },
      // { label: 'Advanced Search', active: false, existingCus: [], show: true, class: 'delete' },
      //{ label: 'Cut', active: false, existingCus: [], show: true, class: 'cut' },
      {
        label: 'Trigger CES Layer',
        active: false,
        existingCus: [],
        show: true,
        class: 'trigger-ces',
        disableClass: 'trigger-dissable',
      },
      { label: labels?.Edit, active: false, existingCus: [], show: true, class: 'edit' },
      { label: labels?.Move_CU_Before, active: false, existingCus: [], show: true, class: 'move-cu' },
      { label: labels?.Move_CU_After, active: false, existingCus: [], show: true, class: 'move-cu' },

      {
        label: labels?.Link_to_pathway,
        active: false,
        show: true,
        existingCus: [],
        class: 'anlLink',
      },
      {
        label: labels?.UnLink,
        active: false,
        show: false,
        existingCus: [],
        class: 'unLink',
      },
      // { label: 'Special features', active: false, existingCus: [], show: true, class: 'splFeatures' },
      {
        label: labels?.Information_Layer,
        active: false,
        existingCus: [],
        show: true,
        class: 'adding',
        disableClass: 'info-dissable',
      },
      { label: 'Embed CU', active: false, existingCus: [], show: true, class: 'adding' },
      {
        label: 'AR',
        active: false,
        existingCus: [
          { label: 'Physical Object Training', active: false, existingCus: [], show: true, class: '' },
          { label: 'AR Entity Creation', active: false, existingCus: [], show: true, class: '' },
        ],
        show: true,
        class: 'AR-icon',
      },
      { label: 'Advance Search', active: false, existingCus: [], show: true, class: 'search' },
      { label: 'Advance Update', active: false, existingCus: [], show: true, class: 'search' },
    ];
  }
  static loadNestedLabels(labels: any): LabelType[] {
    return [
      { label: 'Add-Nested', active: false, existingCus: [], show: true, class: 'add-nested' },
      { label: 'Edit', active: false, existingCus: [], show: true, class: 'add-nested' },
      { label: 'Delete', active: false, existingCus: [], show: true, class: 'add-nested' },
    ];
  }

  static prepareConfigurationData(res: IConfigData) {
    if (res.sentenceTag.tag === 'Att.' || res.sentenceTag.tag === 'attributes') {
      return this.prepareAttributeData(res);
    }
  }

  static prepareAttributeData(res: IConfigData) {
    let p_layer: any;
    if (res.layerType == 'physical') {
      if (res.currentComp == 'GSI') {
        p_layer = res?.layers?.find((m: any) => m?.type === 'physical');
      } else {
        p_layer = res?.cu?.layers?.find((m) => m?.type === 'physical');
      }
      if (p_layer) {
        const tag = res.sentenceTag.token;
        // const node = p_layer?.participatingItems[res?.entityIndex]?.nslAttributes?.find((m) => {
        //   let nameVal = m?.displayName ? m?.displayName : m?.name;
        //   if (nameVal === tag) {
        //     return m;
        //   } else if (
        //     p_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
        //       res.sentenceTag['attribute_counter']
        //     ].name
        //   ) {
        //     return p_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[res.sentenceTag['attribute_counter']];
        //   }
        // }) as NslAttribute;
        const node = p_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
          res.sentenceTag['attribute_counter']
        ] as NslAttribute;
        const temp = {} as any;
        temp.id = node?.id;
        temp.label = 'AT';
        temp.data = {
          dsdId: node?.id,
          color: 'red',
          name: node?.name,
          attr: node,
          entity: p_layer?.participatingItems[res?.entityIndex],
          currentcu: res?.cu,
          cu: res?.cu,
          layerType: res?.layerType,
          entityIndex: res?.entityIndex,
        };
        temp.data.entity.layerType = res?.layerType;
        return temp;
      }
    }
    if (res.layerType == 'information') {
      let i_layer: any;
      if (res.currentComp == 'GSI') {
        i_layer = res?.layers?.find((m) => m?.type == 'information');
      } else {
        i_layer = res?.cu?.layers?.find((m) => m?.type == 'information');
      }
      if (i_layer) {
        const tag = res.sentenceTag.token;
        // const node = i_layer?.participatingItems[res?.entityIndex]?.nslAttributes?.find((m) => {
        //   let nameVal = m?.displayName ? m?.displayName : m?.name;
        //   if (nameVal === tag) {
        //     return m;
        //   } else if (
        //     i_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
        //       res.sentenceTag['attribute_counter']
        //     ].name
        //   ) {
        //     return i_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
        //       res.sentenceTag['attribute_counter']
        //     ];
        //   }
        // }) as NslAttribute;
        const node = i_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
          res.sentenceTag['attribute_counter']
        ] as NslAttribute;
        const temp = {} as any;
        temp.id = node?.id;
        temp.label = 'AT';
        temp.data = {
          dsdId: node?.id,
          color: 'red',
          name: node?.name,
          attr: node,
          entity: i_layer?.participatingItems[res?.entityIndex],
          currentcu: res?.cu,
          cu: res?.cu,
          layerType: res?.layerType,
          entityIndex: res?.entityIndex,
        };
        return temp;
      }
    }
    if (res.layerType == 'triggerCES') {
      let i_layer: any;
      if (res.currentComp == 'GSI') {
        i_layer = res?.layers?.find((m) => m?.type == 'information');
      } else {
        i_layer = res?.cu?.layers?.find((m) => m?.type == 'information');
      }
      if (i_layer) {
        const tag = res.sentenceTag.token;
        // const node = i_layer?.participatingItems[res?.entityIndex]?.nslAttributes?.find((m) => {
        //   let nameVal = m?.displayName ? m?.displayName : m?.name;
        //   if (nameVal === tag) {
        //     return m;
        //   } else if (
        //     i_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
        //       res.sentenceTag['attribute_counter']
        //     ].name
        //   ) {
        //     return i_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
        //       res.sentenceTag['attribute_counter']
        //     ];
        //   }
        // }) as NslAttribute;
        const node =
          i_layer?.participatingItems[res.sentenceTag['entity_counter']]?.nslAttributes[
            res.sentenceTag['attribute_counter']
          ];
        const temp = {} as any;
        temp.id = node?.id;
        temp.label = 'AT';
        temp.data = {
          dsdId: node?.id,
          color: 'red',
          name: node?.name,
          attr: node,
          entity: i_layer?.participatingItems[res?.entityIndex],
          currentcu: res?.cu,
          cu: res?.cu,
          layerType: res?.layerType,
          entityIndex: res?.entityIndex,
        };
        return temp;
      }
    }
    if (res.layerType == 'triggerCES') {
      let t_layer: any;
      if (res.currentComp == 'GSI') {
        t_layer = res?.layers?.find((m) => m?.type == 'triggerCES');
      } else {
        t_layer = res?.cu?.layers?.find((m) => m?.type == 'triggerCES');
      }
      if (t_layer) {
        const tag = res?.sentenceTag?.token;
        const node = t_layer?.participatingItems[res?.entityIndex]?.nslAttributes?.find(
          (m: any) => m?.name === tag
        ) as NslAttribute;
        const temp = {} as any;
        temp.id = node?.id;
        temp.label = 'AT';
        temp.data = {
          dsdId: node?.id,
          color: 'red',
          name: node?.name,
          attr: node,
          entity: t_layer?.participatingItems[res?.entityIndex],
          currentcu: res?.cu,
        };
        return temp;
      }
    }
  }
  static formCCD(res: any) {
    /*istanbul ignore next*/
    let slotItemProperties = {};
    res.tf_details.layers = this.removeEmptyLayers(res?.tf_details?.layers);
    let index = res?.tf_details?.layers.findIndex((x: any) => {
      return x?.type === 'physical';
    });
    /*istanbul ignore next*/
    let layer = res?.tf_details?.layers[index];
    let layerString = this.getIndexPadding('PL_SL', index + 1);
    let entity = layer.participatingItems.find((y: any) => {
      /*istanbul ignore next*/
      return y?.item?.DATA.name === res?.entity[0].entity_name;
    });
    /*istanbul ignore next*/
    let constantPropertyData: any = {
      propertyData: {
        CONSTANT: {
          cuLayer: 'physical',
          slotItemIndex: index,
          changeDriverData: {
            TYPE: 'TxnGeneralEntity',
            DATA: {
              name: res?.entity[0].entity_name,
              dsdId: entity.item?.DATA.dsdId,
              transEntityRecords: [
                {
                  txnNslAttribute: [],
                },
              ],
            },
          },
        },
      },
    };
    /*istanbul ignore next*/
    entity.item?.DATA?.nslAttributes?.forEach((attribute: any) => {
      let txnAttribute: any = { values: [] };
      if (attribute?.name === res?.constant_target?.target_name) {
        txnAttribute.name = attribute?.name;
        txnAttribute.dsdId = attribute?.dsdId;
        txnAttribute.properties = ['CONSTANT'];
        if (res?.constant_target?.method_name) {
          txnAttribute.values.push(res?.constant_target?.method_name);
        } else if (res?.constant_target?.target_name == 'extraction_type') {
          txnAttribute.values.push(res?.constant_target?.type);
        }
        constantPropertyData.propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute.push(
          txnAttribute
        );
      }
    });
    slotItemProperties[layerString] = constantPropertyData;
    return slotItemProperties;
  }
  static getIndexPadding(layer: string, index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return layer + input;
  }
  static removeEmptyLayers(layers: any = []) {
    /*istanbul ignore next*/
    if (layers?.length > 0) {
      return layers.filter((m: any) => m.participatingItems && m.participatingItems.length);
    }
    return layers;
  }
}
export interface LabelType {
  label: string;
  active: boolean;
  existingCus: any[];
  show: boolean;
  class: string;
  disableClass?: string;
}
