<div
  class="demo-full-width"
  [formGroup]="group"
  [ngClass]="{
    inValidClass:
      !field?.isInfo &&
      (group?.controls)[field?.attribute?.name]?.invalid &&
      (group?.controls)[field?.attribute?.name]?.touched
  }"
>
  <div class="d-flex justify-content-between nh-column-gap-10">
    <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
      <label
        class="main-label nh-mb-0"
        pTooltip="{{ field?.label }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="
          !field?.hideLabels &&
          !field?.configuration?.hideLabel &&
          !field?.hideLabelMultiAttr
        "
        ><span class="main-label-inner">
          <span class="main-label-text text-truncate"
            >{{ field.label }} <span class="main-label-colon">:</span></span
          >
          <span
            *ngIf="field?.isRequired && !field?.isInfo"
            class="mat-form-field-required-marker"
            >*</span
          ></span
        >
      </label>
    </div>
    <p
      class="nh-ml-6 d-flex"
      *ngIf="field?.configuration?.showHelperTextIcon"
      [ngStyle]="{
        color: field?.configuration?.helperTextColor,
        'font-size': field?.configuration?.helperTextFont
      }"
    >
      <mat-icon
        [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
        class="material-icons-outlined helperText-infoIcon"
        matTooltip="{{ field?.configuration?.helpertext }}"
        matTooltipPosition="above"
        *ngIf="!field?.hideLabels"
        >info_outline</mat-icon
      >
    </p>
  </div>
  <p
    class="top-text"
    *ngIf="
      field?.configuration?.helperTextPosition == 'top' &&
      !field?.configuration?.showHelperTextIcon
    "
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
  <app-qrcode-scanner
    [formControlName]="field.attribute.name"
    id="{{ field.attribute.name }}"
    [field]="field"
  >
  </app-qrcode-scanner>
  <mat-icon
    matSuffix
    *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
    ><span class="sub-cu" (click)="openSubPopUp()"></span>
  </mat-icon>
  <p
    class="bottom-text"
    *ngIf="
      field?.configuration?.helperTextPosition == 'bottom' &&
      !field?.configuration?.showHelperTextIcon
    "
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
</div>
