import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EntityFacadeService } from '../entity/entity-facade.service';
import { LibraryFacadeService } from '../library/library-facade.service';
import { DeveloperLessDevelopmentEndpointService } from './developer-less-development-endpoint.service';
import { ChangeUnitFacadeService } from '../change-unit/change-unit-facade.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';
import { DLDRecommendationsStore, DLDStoreActions } from '../stores/dld-recommendations-state';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { getEnvClientId, getHostUrl } from '../../generic-folder/generic-functions';
import { dldGSIFullListMapper } from '../mappers/dld/dld-gsi-full-list.mapper';
import { dldChangeUnitListMapper } from '../mappers/dld/dld-cu-list-mapper';
import { dldEntityListMapper } from '../mappers/dld/dld-entity-list-mapper';
import { dldGSIListMapper } from '../mappers/dld/dld-gsi-list.mapper';
import { dldGSIMapper } from '../mappers/dld/dld-gsi.mapper';
import { gsiDeMapper } from '../mappers/gsi/gsi-demapper';
import { gsiMapper } from '../mappers/gsi/gsi-mapper';
@Injectable({
  providedIn: 'root',
})
export class DeveloperLessDevelopmentFacadeService implements OnDestroy {
  private solutionDetailsSubject = new Subject();
  solutionDetails$ = this.solutionDetailsSubject.asObservable();

  recommendedCuSubject = new Subject();
  recommendedCu$ = this.recommendedCuSubject.asObservable();

  compareCusSubject = new BehaviorSubject([]);
  compareCus$ = this.compareCusSubject.asObservable();

  recommendedEntities = new Subject();
  recommendedEntityList$ = this.recommendedEntities.asObservable();

  public recommendedAttributes = new Subject();
  recommendedAttributes$ = this.recommendedAttributes.asObservable();

  private changeOfComponentDetect = new Subject();
  changeOfComponentDetect$ = this.changeOfComponentDetect.asObservable();

  recommendedGsiList = new Subject();
  recommendedGsiList$ = this.recommendedGsiList.asObservable();

  mulitipleGsiDataStream = new Subject();
  mulitipleGsiDataStream$ = this.mulitipleGsiDataStream.asObservable();

  recommendedGsiCuList = new Subject();
  recommendedGsiCuList$ = this.recommendedGsiCuList.asObservable();

  recommendedRolesList = new Subject();
  recommendedRolesList$ = this.recommendedRolesList.asObservable();

  gsiRecommendationForNestedCU = new Subject();
  gsiRecommendationForNestedCU$ = this.gsiRecommendationForNestedCU.asObservable();

  gsiRecommendationForExceptionCU = new Subject();
  gsiRecommendationForExceptionCU$ = this.gsiRecommendationForExceptionCU.asObservable();
  saveGSICompositeForNestedCU = new Subject();
  saveGSICompositeForNestedCU$ = this.saveGSICompositeForNestedCU.asObservable();
  public DCDRecommendations = new Subject();
  DCDRecommendations$ = this.DCDRecommendations.asObservable();

  gsiRecommendations = new Subject();
  gsiRecommendations$ = this.gsiRecommendations.asObservable();

  dsdSearchResults = new Subject();
  dsdSearchResults$ = this.dsdSearchResults.asObservable();

  dsdSearchResultsByDsdId = new Subject();
  dsdSearchResultsByDsdId$ = this.dsdSearchResultsByDsdId.asObservable();

  dsdRhsCompare = new Subject();
  dsdRhsCompare$ = this.dsdRhsCompare.asObservable();

  isDSDFlag = new BehaviorSubject(null);
  isDSDFlag$ = this.isDSDFlag.asObservable();

  public betNameForDSD = new BehaviorSubject(null);
  betNameForDSD$ = this.betNameForDSD.asObservable();

  private ngUnsubscribe = new Subject();

  constructor(
    private dldtEndpointService: DeveloperLessDevelopmentEndpointService,
    private changeunitfacadeservice: ChangeUnitFacadeService,
    private loader: LoaderService,
    private alertService: AlertService,
    private dldRecommendationsStore: DLDRecommendationsStore,
    private gsiFacadeService: GsiFacadeService,
    private entityFacadeService: EntityFacadeService,
    private libraryFacadeService: LibraryFacadeService
  ) {
    this.detectDLDStoreChanges();
  }

  previousRequestState: any;
  dataCaptureByType(res: any, action: any) {
    /* istanbul ignore next */
    switch (action) {
      case DLDStoreActions.ReadGSI:
        return res?.gsiList;

      case DLDStoreActions.ReadRole:
        return res?.roleList;

      case DLDStoreActions.ReadChangeUnit:
        return res?.changeUnitList;

      case DLDStoreActions.ReadEntity:
        return res?.entityList;

      case DLDStoreActions.ReadAttribute:
        return res?.attributeList;

      case DLDStoreActions.ReadDCD:
        return res?.DCDList;

      case DLDStoreActions.ReadNestedCU:
        return res?.nestedCUList;
      case DLDStoreActions.ReadExceptionCu:
        return res?.exceptionCUList;
    }
  }

  resumeDLDState(flag: any) {
    /* istanbul ignore next */
    if (flag) {
      const data = this.dldRecommendationsStore.getStateData();

      this.setDLDRecommendationsStream(data, data?.previousAction);
    }
  }

  detectDLDStoreChanges() {
    this.dldRecommendationsStore.stateChanged
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          const { previousAction } = res;
          this.setDLDRecommendationsStream(res, previousAction);
        }
      });
  }

  setDLDRecommendationsStream(data: any, action: any) {
    switch (action) {
      case DLDStoreActions.ReadGSI:
        this.recommendedGsiList.next(this.dataCaptureByType(data, action));
        break;

      case DLDStoreActions.ReadRole:
        this.recommendedRolesList.next(this.dataCaptureByType(data, action));
        break;

      case DLDStoreActions.ReadChangeUnit:
        this.recommendedCuSubject.next(this.dataCaptureByType(data, action));
        break;

      case DLDStoreActions.ReadEntity:
        this.recommendedEntities.next(this.dataCaptureByType(data, action));
        break;

      case DLDStoreActions.ReadAttribute:
        this.recommendedAttributes.next(this.dataCaptureByType(data, action));
        break;

      case DLDStoreActions.ReadDCD:
        this.DCDRecommendations.next(this.dataCaptureByType(data, action));
        break;

      case DLDStoreActions.ReadNestedCU:
        this.gsiRecommendationForNestedCU.next(this.dataCaptureByType(data, action));
        break;
      case DLDStoreActions.ReadExceptionCu:
        this.gsiRecommendationForExceptionCU.next(this.dataCaptureByType(data, action));
        break;
    }
  }

  setStateData(data: any, action: any) {
    this.dldRecommendationsStore.setStateData(data, action);
  }
  changeOfComponent(data: any) {
    this.changeOfComponentDetect.next(data);
  }

  getSolutionDetails(data: any) {
    this.solutionDetailsSubject.next(data);
    if (data.gsiName) {
      this.getCuRecommendation(data);
    }
  }

  getExceptionCuRecommendation(baseObject: any, isException?: boolean, entities?: any) {
    // this.loader.show();
    /* istanbul ignore next */
    this.dldtEndpointService
      .getExceptionCuRecommendations({ ...baseObject, hostPortURL: getHostUrl() }, isException, entities)
      .pipe()
      .subscribe((res: any) => {
        //  this.loader.hide();
        /* istanbul ignore next */
        if (res) {
          this.setStateData(res, DLDStoreActions.ReadExceptionCu);
        }
      });
  }
  getCuRecommendation(baseObject: any, isNested?: boolean, entities?: any) {
    this.loader.show();
    if (isNested) {
      this.dldtEndpointService
        .getNestedCuRecommendations({ ...baseObject, hostPortURL: getHostUrl() }, isNested, entities)
        .pipe()
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res) {
            this.setStateData(dldGSIFullListMapper(res), DLDStoreActions.ReadNestedCU);
          }
        });
    } else {
      /* istanbul ignore next */
      if (!this.previousRequestState || JSON.stringify(this.previousRequestState) !== JSON.stringify(baseObject)) {
        //triggers api call when previous and present request is same
        this.dldtEndpointService
          .getCuRecommendation({ ...baseObject, hostPortURL: getHostUrl() }, isNested, entities)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            this.loader.hide();
            this.setStateData(dldChangeUnitListMapper(res), DLDStoreActions.ReadChangeUnit);
          });
        this.previousRequestState = baseObject;
      }
    }
    this.loader.hide();
  }

  // getCuRecommendation(gsi: any, cus: any, isNested?: boolean, book?: any, chapter?: any, entities?: any) {
  //   this.loader.show();
  //   if (isNested) {
  //     this.dldtEndpointService
  //       .getNestedCuRecommendations(gsi, cus, getHostUrl(), isNested, book, chapter, entities)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe((res: any) => {
  //         this.loader.hide();
  //         if (res) {
  //           this.setStateData(res.result, DLDStoreActions.ReadNestedCU);
  //           // this.gsiRecommendationForNestedCU.next(res.result);
  //         }
  //       });
  //   } else {
  //     this.dldtEndpointService
  //       .getCuRecommendation(gsi, cus, getHostUrl(), isNested, book, chapter, entities)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe((res: any) => {
  //         this.loader.hide();
  //         if (res) {
  //           this.setStateData(res.result, DLDStoreActions.ReadChangeUnit);
  //         }
  //         // if (res) {
  //         //   this.recommendedCuSubject.next(this.dldRecommendationsStore.setStateData(res.result , DLDStoreActions.ReadChangeUnit));
  //         // }
  //       });
  //   }
  // }

  updatedCompareCusData(data: any) {
    this.compareCusSubject.next(data);
  }

  changeCurrentComponent(data: string, gsi: any, cu: any, entity: any, attributeList?: any) {
    if (data == 'cuedit') {
      this.dldtEndpointService
        .getEntityRecommendation(gsi, cu, entity)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          // let data  = dldEntityListMapper(res)
          // setTimeout(() => {
          this.setStateData(dldEntityListMapper(res), DLDStoreActions.ReadEntity);
        });
    }
    if (data == 'entityedit') {
      if (entity && entity != '' && entity != undefined) {
        //this.loader.show();
        if (gsi) {
          this.dldtEndpointService
            .getAttributeRecommendation(gsi, cu, entity, attributeList)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              //this.loader.hide();
              if (res) {
                this.setStateData(res.result, DLDStoreActions.ReadAttribute);
                // this.recommendedAttributes.next(res.result);
              }
            });
        }
      } else {
        this.recommendedAttributes.next([]);
      }
    }
  }
  getGsiRecommendations(bookName: any) {
    this.dldtEndpointService
      .getGsiRecommendations(bookName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.setStateData(dldGSIListMapper(res), DLDStoreActions.ReadGSI);
      });
  }
  getCuRecommendationForSeletedGsi(bookName: any, gsiName: any, multipleGsis?: boolean, viewDetails?: boolean) {
    let gsiList = [];
    if (multipleGsis) {
      gsiList = gsiName;
    } else {
      gsiList.push(gsiName);
    }
    this.loader.show();
    let hostUrl = !multipleGsis ? getHostUrl() : false;
    this.dldtEndpointService
      .getCuRecommendationForSeletedGsi(bookName, gsiList, hostUrl)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (multipleGsis) {
          this.mulitipleGsiDataStream.next(res);
        } else if (viewDetails) {
          this.recommendedGsiCuList.next(res[0]);
          // this.setStateData(res[0], DLDStoreActions.);
        } else {
          /* istanbul ignore next */
          this.gsiFacadeService.saveCompositeGsi(dldGSIMapper(res)?.[0], true);
        }
        // this.recommendedGsiCuList.next(this.dldRecommendationsStore.setStateData(res[0] , DLDStoreActions.ReadChangeUnit));
        // } else {
        //   this.changeunitfacadeservice.getCusByGsiId(res[0], true);
        // }
      });
  }
  getRolesRecommendations(bookName: any, gsiName: any, selectedRoleList?: any) {
    this.dldtEndpointService
      .getRolesRecommendations(bookName, gsiName, getHostUrl(), selectedRoleList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.setStateData(res, DLDStoreActions.ReadRole);
        }
      });
  }

  getDLDAgentSuggestionsForCU(data: any) {
    const body = {
      ...data,
      tenant: getEnvClientId(),
      hostPortURL: getHostUrl(),
    };
    this.dldtEndpointService
      .getDLDAgentRecommendationsForCU(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: any) => {
        /* istanbul ignore next */
        if (r) {
          this.setStateData(r, DLDStoreActions.ReadRole);
        }
      });
  }

  getDLDAgentSuggestionsForEntity(data: any) {
    const body = {
      ...data,
      tenant: getEnvClientId(),
      hostPortURL: getHostUrl(),
    };
    this.dldtEndpointService
      .getDLDAgentRecommendationsForEntity(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((resp: any) => {
        /* istanbul ignore next */
        if (resp) {
          this.setStateData(resp, DLDStoreActions.ReadRole);
        }
      });
  }

  getDLDAgentSuggestionsForAttribute(data: any) {
    const body = {
      ...data,
      tenant: getEnvClientId(),
      hostPortURL: getHostUrl(),
    };
    this.dldtEndpointService
      .getDLDAgentRecommendationsForAttribute(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((re: any) => {
        /* istanbul ignore next */
        if (re) {
          this.setStateData(re, DLDStoreActions.ReadRole);
        }
      });
  }
  saveGSICompositeAPI(data: any, cutype?: any) {
    this.dldtEndpointService
      .saveGSICompositeAPI(gsiDeMapper(data))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          if (cutype === 'embedded') {
            this.libraryFacadeService.changeCu(gsiMapper(res));
          } else {
            this.saveGSICompositeForNestedCU.next(gsiMapper(res));
          }
        }
      });
  }
  getDCDFromDLDByEntity(data: any) {
    const dataObject = { ...data, hostPortURL: getHostUrl() };
    this.dldtEndpointService
      .getDCDFromDLDByEntity(dataObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.setStateData(res, DLDStoreActions.ReadDCD);
        }
      });
  }
  getDCDFromDLDByAttributes(data: any) {
    const dataObject = { ...data, hostPortURL: getHostUrl() };
    this.dldtEndpointService
      .getDCDFromDLDByAttributes(dataObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res) {
            this.setStateData(res, DLDStoreActions.ReadDCD);
          } else {
            this.setStateData({}, DLDStoreActions.ReadDCD);
          }
        },
        (err) => {
          this.DCDRecommendations.next(err);
        }
      );
  }

  sendSelectedDataForDldFeedBack(data: any) {
    this.dldtEndpointService
      .sendSelectedDataForDldFeedBack(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {});
  }

  sendDldFeedbackData(data: any) {
    this.dldtEndpointService
      .sendDldFeedbackData(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {});
  }

  getDSDSearchQueries(searchType: any, searchText: any, pageNumber?: any, limit?: any) {
    /* istanbul ignore next */
    searchType = searchType == 'books' ? 'book' : searchType;
    this.dldtEndpointService
      .getDSDSearchQueries(searchType, searchText, pageNumber, limit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res.result && res.result) {
          this.dsdSearchResults.next(res.result);
        }
      });
  }

  getGlobalBETDetails(dsdId: any, betType: any, compositeSave?: any) {
    this.dldtEndpointService
      .getGlobalBETDetails(dsdId, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (compositeSave && res && res.result) {
          if (betType == 'entity') {
            this.entityFacadeService.saveEntity(res.result);
          } else {
            this.saveBETCompositeAPIDsd(res.result, betType);
          }
        } else {
          this.dsdSearchResultsByDsdId.next(res);
        }
      });
  }
  compareBets(dsdIds: any, betType: any) {
    /* istanbul ignore next */
    betType = betType == 'BOOKS' ? 'BOOK' : betType;
    this.dldtEndpointService
      .compareBets(dsdIds, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res.result) {
          this.dsdRhsCompare.next({ ...res, betType: betType });
        }
      });
  }
  saveBETCompositeAPIDsd(gsiData: any, betType: any) {
    betType = betType == 'change-unit' ? 'cu' : betType;
    this.dldtEndpointService
      .saveBETCompositeAPIDsd(gsiData, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (betType == 'cu') {
          this.changeunitfacadeservice.updateDraftCU(res?.result);
          this.libraryFacadeService.changeCu({ ...res?.result, id: res?.result?.dsdId, source: 'TF' });
        } else {
          this.libraryFacadeService.fetchGSIdetails(res?.result);
        }
      });
  }

  detectIsDSDFlag(info: any) {
    this.isDSDFlag.next(info);
  }

  getBETNameForDSD(betName: any, betType?: any) {
    this.betNameForDSD.next({ betName: betName, betType: betType });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
