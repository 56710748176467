<section class="tab-header">
  <div class="node-select" id="selectSolutionVersion">
    <mat-form-field appearance="standard">
      <select matNativeControl [(ngModel)]="filterType" (change)="loadData($event)">
        <option value="all" id="getAllSolutions">{{ labels?.ALL }}</option>
        <option value="published" id="getPublishedSolutions">{{ labels?.PUBLISHED }}</option>
        <option value="drafts" id="getDraftsSolutions">{{ labels?.DRAFTS }}</option>
      </select>
    </mat-form-field>
  </div>
  <div class="row no-gutters align-items-center">
    <div class="col node-search-wrap justify-content-end">
      <div class="node-search-right">
        <div class="node-create right-item" (click)="freshStart()">
          <button class="create-new-btn" id="createNew_Solution"></button>
        </div>
        <!-- <div class="node-create right-item">
          <button class="toggle-btn-icon" id="toggleBtn"></button>
        </div> -->
        <!-- <div class="node-create right-item">
          <button class="compare-btn-icon"></button>
        </div> -->
        <div class="node-search-block right-item">
          <button (click)="clickedForSearch()" class="search-btn-icon search-btn-large" id="search_Solution"></button>
        </div>
      </div>
      <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
        <mat-form-field appearance="legacy">
          <button
            mat-icon-button
            matSuffix
            (click)="displayNodesearch = !displayNodesearch; searchText = ''; getAllSolutions()"
          >
            <mat-icon matSuffix>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchText" placeholder="Search" (keyup)="getAllSolutions()" id="search_element" />
        </mat-form-field>
      </div>
    </div>
  </div>
</section>
<section class="tab-body">
  <ul class="common-list">
    <li *ngFor="let gsi of gsiList?.data; let gsiInd = index">
      <mat-accordion>
        <mat-expansion-panel hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header (click)="expandGsi(gsi, gsiInd)">
            <div class="d-flex item align-items-center" [ngClass]="{ active: gsiInd == activeGsi }">
              <div class="details">
                <p class="sub">{{ gsi.status }} [{{ gsi.version }}]</p>
                <p class="title" pTooltip="{{ gsi.name }}" tooltipPosition="top">
                  {{ gsi.name }}
                </p>
              </div>
              <a
                class="add-icon"
                id="addSolution_{{ gsi?.name?.replaceAll(' ', '_') }}"
                (click)="importGsiData(gsi, clonedTemplate); $event.stopPropagation()"
                ><span></span
              ></a>
              <a
                class="copy-icon"
                id="copySolution_{{ gsi?.name?.replaceAll(' ', '_') }}"
                (click)="viewData(gsi, clonedTemplate); $event.stopPropagation()"
                ><span></span
              ></a>
              <a
                *ngIf="gsi.status == 'DRAFT'"
                class="delete-icon"
                id="deleteSolution_{{ gsi?.name?.replaceAll(' ', '_') }}"
                (click)="deleteGsiDraft(gsi, $event)"
                ><span></span
              ></a>
              <a
                class="more-icon"
                id="moreSolutionDetails_{{ gsi?.name?.replaceAll(' ', '_') }}"
                (click)="expandGsi(gsi, gsiInd)"
                ><span></span
              ></a>
            </div>
          </mat-expansion-panel-header>
          <div class="item-body" *ngIf="gsi.displayCUList">
            <mat-expansion-panel
              hideToggle
              class="mat-elevation-z0"
              *ngFor="let cu of gsi?.displayCUList?.solutionLogic"
            >
              <mat-expansion-panel-header>
                <div class="d-flex item level-two align-items-center">
                  <div class="details">
                    <!-- <p class="sub"></p> -->
                    <p class="title">{{ cu.displayName }}</p>
                  </div>
                  <a class="more-icon" id="moreDisplayCuDetails_{{ cu?.displayName?.replaceAll(' ', '_') }}"
                    ><span></span
                  ></a>
                </div>
              </mat-expansion-panel-header>
              <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let cus of cu?.solutionLogic">
                <mat-expansion-panel-header>
                  <div class="d-flex item level-three align-items-center">
                    <div class="details">
                      <!-- <p class="sub"></p> -->
                      <p class="title">{{ cus.displayName }}</p>
                    </div>
                    <a class="more-icon" id="moreCuDetails_{{ cus?.displayName?.replaceAll(' ', '_') }}"
                      ><span></span
                    ></a>
                  </div>
                </mat-expansion-panel-header>
                <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of cus.layers">
                  <mat-expansion-panel-header>
                    <div class="d-flex item level-three align-items-center">
                      <div class="details">
                        <!-- <p class="sub"></p> -->
                        <p class="title">{{ layer.type }}</p>
                      </div>
                      <a class="more-icon" id="moreLayersDetails_{{ layer?.type }}"><span></span></a>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let ent of layer.participatingItems">
                    <mat-expansion-panel-header>
                      <div class="d-flex item level-four align-items-center">
                        <div class="details">
                          <!-- <p class="sub"></p> -->
                          <p class="title">{{ ent.item.DATA.name }}</p>
                        </div>
                        <a class="more-icon" id="moreEntityDetails_{{ ent?.item?.DATA?.name?.replaceAll(' ', '_') }}"
                          ><span></span
                        ></a>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="mat-elevation-z0" *ngFor="let attr of ent.item?.DATA?.nslAttributes">
                      <div class="d-flex item level-five align-items-center">
                        <div class="d-flex" *ngIf="!attr.generalEntity">
                          {{ attr.name }}<a class="create-new-btn"></a>
                        </div>
                        <div class="d-flex" *ngIf="attr.generalEntity">
                          <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-expansion-panel>
              </mat-expansion-panel>
              <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of cu.layers">
                <mat-expansion-panel-header>
                  <div class="d-flex item level-three align-items-center">
                    <div class="details">
                      <!-- <p class="sub"></p> -->
                      <p class="title">{{ layer.type }}</p>
                    </div>
                    <a class="more-icon"><span></span></a>
                  </div>
                </mat-expansion-panel-header>
                <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let ent of layer.participatingItems">
                  <mat-expansion-panel-header>
                    <div class="d-flex item level-four align-items-center">
                      <div class="details">
                        <!-- <p class="sub"></p> -->
                        <p class="title">{{ ent.item.DATA.name }}</p>
                      </div>
                      <a class="more-icon"><span></span></a>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="mat-elevation-z0" *ngFor="let attr of ent.item?.DATA?.nslAttributes">
                    <div class="d-flex item level-five align-items-center">
                      <div class="details">
                        <!-- <p class="sub"></p> -->
                        <p class="title">{{ attr.name }}</p>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-expansion-panel>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- <a class="add-icon" (click)="importGsiData(gsi)"><span></span></a>
        <a class="copy-icon" (click)="viewData(gsi, clonedTemplate)"><span></span></a>
        <a class="more-icon" (click)="expandGsi(gsi)"><span></span></a> -->
    </li>
  </ul>
</section>
<div class="tab-footer">
  <app-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [totalRecords]="gsiList?.totalResults"
    (onPageChange)="onPageChange($event)"
  >
  </app-paginator>
</div>
<ng-template #clonedTemplate>
  <mat-dialog-content class="alert-dialog py-3">
    <div class="users-table" *ngIf="alertModal">
      <label>Alert</label>
      <input type="text" [(ngModel)]="clonedGsiName" placeholder="Rename your clone GSI" />
    </div>

    <div class="users-table" *ngIf="!alertModal">
      <h5>Would you like to discard existing Solution? Proceed</h5>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <span *ngIf="!alertModal">
      <button mat-button (click)="cloningGsi()" color="primary">Yes</button>
      <button mat-button (click)="this.dialog.closeAll()" color="accent">No</button>
    </span>
    <span *ngIf="alertModal">
      <button mat-button matDialogClose (click)="cloneData()">Done</button>
    </span>
  </mat-dialog-actions>
</ng-template>

<ng-template #deleteDraftWarning>
  <div class="delete-sol-draft">
    <h3>Delete Solution Draft</h3>
    <p>
      Are you sure you want to delete "{{ draftGsi.displayName }}"<br />You will revert to the last published
      version.<br />You can't undo this action
    </p>
    <div class="delete-sol-draft-btns">
      <button class="" (click)="cancelDeleteDraft()">
        Cancel
      </button>
      <button class="del-draft" (click)="clickDeleteDraft()">
        Delete Draft
      </button>
    </div>
  </div>
</ng-template>
