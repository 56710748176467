import { NgModule } from '@angular/core';
import { DigitalSignatureComponent } from './digital-signature.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [DigitalSignatureComponent],
  imports: [ControlsMaterialModule],
  exports: [DigitalSignatureComponent],
})
export class DigitalSignatureModule { }
