<div class="entityTable-configuration" id="table-config" *ngIf="!canvasDesignPage">
  <div class="nhCard" [ngClass]="{ 'show-table-preview': isPreview }">
    <div class="nhCard_header">
      <div class="nhCard_header_inner d-flex align-items-center justify-content-between">
        <div class="nhCard_header_left d-flex align-items-center">
          <button class="nh-unsetAll cursor-pointer nhCard_header_back">
            <em class="nh-icon nh-icon-chevron-left" (click)="goBackToSolution()"></em>
          </button>
          <h4 class="nh-fs-18">Configure Table</h4>
        </div>
        <div
          class="nhCard_header_right d-flex align-items-center"
          *ngIf="!isGrammer && (isCardConfig || isAdvancedFeature)"
        >
          <button class="btn publish" *ngIf="!isAdvancedFeature && !isPreview" (click)="setIsPreView(true)">
            Preview
          </button>
          <button
            *ngIf="!isAdvancedFeature && !isPreview"
            class="nh-btn nh-btn-next cursor-pointer"
            id="next_1"
            (click)="gotoAdvancedFeature()"
          >
            {{ labels?.Next }}
          </button>
          <button
            *ngIf="isAdvancedFeature && !canvasDesignPage && !isPreview"
            class="nh-btn nh-btn-next cursor-pointer"
            id="next_2"
            (click)="saveEntityTableData('table')"
          >
            {{ labels?.Done }}
          </button>
          <button
            *ngIf="!isAdvancedFeature && canvasDesignPage && !isPreview"
            class="nh-btn nh-btn-next cursor-pointer"
            id="done_1"
          >
            {{ labels?.Done }}
          </button>
          <button class="nh-btn nh-btn-cancel cursor-pointer" id="cancel_1" (click)="isHybrid ? saveEntityTableData(null) : goBackToSolution()">
            {{ labels?.Cancel }}
          </button>
          <button class="eth-close d-block" *ngIf="!isAdvancedFeature && isPreview" (click)="setIsPreView(false)">
            <i class="bi bi-x-lg"></i>
          </button>
          <ul class="nhCard_header_right_list">
            <!-- <li class="eth-close"><i class="bi bi-x-lg"></i></li>
            <li class="eth-check"><i class="bi bi-check2"></i></li>
            <li><button class="btn publish">Publish</button></li> -->
            <!-- <li class="eth-expand"><i class="bi bi-arrows-angle-expand"></i></li>
              <li class="eth-resp active"><i class="bi bi-laptop"></i></li>
              <li class="eth-resp"><i class="bi bi-tablet-landscape"></i></li>
              <li class="eth-resp"><i class="bi bi-tablet"></i></li>
              <li class="eth-resp"><i class="bi bi-phone"></i></li> -->
          </ul>
        </div>
      </div>
    </div>

    <div class="nhCard_maincontent">
      <div class="nhrow">
        <div class="nhrow_col nhrow_left" *ngIf="isCardConfig || !isAdvancedFeature">
          <div class="et-config-content-left">
            <!-- <i class="bi bi-grip-vertical"></i> -->
            <mat-tab-group class="form-tabs form-tabs-opt1">
              <mat-tab label="Attributes">
                <div class="attributes-list">
                  <ul>
                    <li
                      *ngFor="let attr of nslAttributes"
                      draggable="true"
                      (dragstart)="drag($event, attr, true)"
                      [id]="attr?.name"
                    >
                      <i class="bi bi-grip-vertical"></i>
                      <p>{{ attr?.name }}</p>
                    </li>
                  </ul>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <div class="nhrow_col nhrow_middle" [ngClass]="{ nhrow_middle_isAdvancedFeature: isAdvancedFeature }">
          <div class="entity-config-info" *ngIf="isCardConfig || !isAdvancedFeature">
            <div class="entity-config-info-header">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="entity-expand-wrap">
              <div class="entity-expand" *ngIf="!isAdvancedFeature">
                <div class="gsibuttons" *ngIf="!inTableGSIButtons">
                  <div class="entityTitle" id="entityTitle" *ngIf="entitytitletoggle && EntityTitle">
                    {{ EntityTitle }}
                  </div>
                  <ng-container *ngFor="let btn of dropActionBts; let i = index">
                    <div
                      class="icon-btn"
                      (click)="
                        selectGsiButton(i);
                        actionBtnSelection[i] = !actionBtnSelection[i];
                        gsiBtnSelected[i] = !gsiBtnSelected[i]
                      "
                      [ngClass]="{'table-button-selected': actionBtnSelection?.[i]}"
                      [ngStyle]="{
                        border: gsiBtnBorderColor ? '1px solid' : '',
                      'background-color': gsiBtnHovered?.[i] ? dropActionBts?.[i]?.gsiConfiguration?.backgroundHover : dropActionBts?.[i]?.gsiConfiguration?.background,
                      'text-decoration-line': gsiBtnUnderline ? 'underline' : 'none',
                      'border-radius':dropActionBts?.[i]?.gsiConfiguration?.borderRadius,
                      'border-color' : dropActionBts?.[i]?.gsiConfiguration?.border,
                      'font-weight': gsiBtnBold ? 'bold' : 'normal',
                      'font-style': gsiBtnItalic ? 'italic' : 'normal',
                      'font-size': gsiBtnFontSize + 'px',
                      'justify-content': gsiBtnAlignment?.viewClass}"
                      (mouseout)="gsiBtnHovered[i] = false"
                      (mouseover)="gsiBtnHovered[i] = true"
                    >
                      <button
                        #gsiAction
                        class="active action-button"
                        [ngClass]="
                        {'d-none':dropActionBts?.[i]?.gsiConfiguration?.gsiDisplay==='onlyIcon'}"
                        matTooltip="{{ btn?.gsiName }}"
                      >
                       <span [ngStyle]="{
                        'color':   gsiBtnHovered?.[i] ? dropActionBts?.[i]?.gsiConfiguration?.textHover : dropActionBts?.[i]?.gsiConfiguration?.font,
                        'font-family': fonts?.[i]?.gsiConfiguration?.fontFamily
                        }"> {{ btn?.gsiName }} </span>
                      </button>
                      <span *ngIf="dropActionBts?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'">
                        <img
                          [src]="dropActionBts?.[i]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[i]?.gsiConfiguration?.imageSrc: defaultGsiImages[dropActionBts?.[i]?.gsiConfiguration?.imageSrc]"
                        />
                      </span>
                      <span>
                        <img
                          class="icon"
                          *ngIf="dropActionBts?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                          [src]="dropActionBts?.[i]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[i]?.gsiConfiguration?.imageSrc:defaultGsiImages[dropActionBts?.[i]?.gsiConfiguration?.imageSrc]"
                          matTooltip="{{ btn?.gsiName }}"
                        />
                      </span>
                    </div>
                  </ng-container>
                </div>
                <div
                  id="headerDescription"
                  class="table-description"
                  [ngClass]="headerDescriptionBackgroundSelected ? 'table-header-selected' : ''"
                  *ngIf="headerDescription && toggleHeaderChecked"
                  (click)="setHeaderDescriptionBackgroundSelected()"
                  (mouseover)="headerHovered = true"
                  (mouseout)="headerHovered = false"
                  [ngStyle]="{
                    'background-color': headerDescriptionBackground,
                    color: headerDescriptionFontColor,
                    'font-family': headerDescriptionFontStyle?.font,
                    'font-weight': headerDescriptionBold ? 'bold' : 'normal',
                    'font-style': headerDescriptionItalic ? 'italic' : 'normal',
                    'text-decoration-line': headerDescriptionUnderline ? 'underline' : 'none',
                    'text-align': headerDescriptionAlignment?.justify,
                    'font-size': headerDescriptionFontSize + 'px'
                  }"
                >
                  {{ headerDescription }}
                </div>
                <p-table
                  #dropTableData
                  class="entity-table-design"
                  [value]="tableData"
                  [resizableColumns]="true"
                  [styleClass]="gridLines?.selectedStyle?.class"
                  columnResizeMode="expand"
                  [ngClass]="{
                    'table-outline': gridLines?.outLine?.selected == true,
                    'header-wrap': true,
                    'body-wrap': advanceTableFeatures?.attributeWrap
                  }"
                  [tableStyle]="{
                    'min-width': tableWidth,
                    'border-color': gridLines?.outLine?.selected ? gridLines?.outLine?.borderColor : 'none',
                    'border-width': gridLines?.outLine?.selectedOutLine ? '5px' : '1px',
                    'border-radius': gridLines?.outLine?.selected ? gridLines?.outLine?.borderRadius + 'px' : '0px'
                  }"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="w-50 t-center"
                        *ngIf="
                          (!allowSelection && recordSelection == 'single' && !advanceTableFeatures.hideSelectButton) ||
                          (recordSelection == 'multiple' && !advanceTableFeatures.hideSelectButton)
                        "
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        pResizableColumn
                      ></th>
                      <th
                        class="w-70 t-center"
                        *ngIf="!advanceTableFeatures.serialNumbers"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        pResizableColumn
                      >
                        <span
                          [ngStyle]="{
                            color: hovered ? headerFontHoverColor : headerFontColor,
                            'font-family': headerFontFamily?.font,
                            'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                            'font-weight': headerBold ? 'bold' : 'normal',
                            'font-style': headerItalic ? 'italic' : 'normal',
                            'font-size': headerFontSize + 'px',
                            'text-align': headerAlignment?.justify
                          }"
                          >S.No.</span
                        >
                      </th>
                      <th
                        class="w-70 t-center"
                        *ngIf="isNestedRow1"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        pResizableColumn
                      >
                        <button
                          type="button"
                          pButton
                          pRipple
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="isNestedRow ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                          (click)="isNestedRow = !isNestedRow"
                        ></button>
                      </th>
                      <th
                        class="btn-tooltip d-flex"
                        pTooltip="{{ row.attributeHeading }}"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        [ngStyle]="{
                          'background-color':
                            hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                              ? headerHoverColor
                              : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        *ngFor="let row of tableData?.[0];let k = index"
                        class="table-row"
                        (drop)="prevDrag($event, k)"
                        (dragover)="allowDrop($event)"
                        (click)="setHeaderBackgroundSelected($event, k)"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        pResizableColumn
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [style.width]="row.width ? row.width : ''"
                      >
                        <span
                          class="entity-table-header-span main-attr text-truncate"
                          [ngStyle]="{
                            color:
                              hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                                ? headerFontHoverColor
                                : headerFontColor,
                            'font-family': headerFontFamily?.font,
                            'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                            'font-weight': headerBold ? 'bold' : 'normal',
                            'font-style': headerItalic ? 'italic' : 'normal',
                            'font-size': headerFontSize + 'px',
                            'text-align': headerAlignment?.justify
                          }"
                          #attributeItem
                          >{{ row?.attributeHeading }}
                        </span>
                        <span class="delete-attr" *ngIf="attributeItem.innerHTML.trim() !== 'Attribute'"
                          ><img
                            class="cursor-pointer"
                            (click)="prevDelete(attributeItem,k); $event.stopPropagation()"
                            [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span>
                      </th>
                      <th
                        *ngIf="
                          (dropActionBts?.length > 0 && gsiConfigurations.gsiPosition == 'inline') ||
                          gsiConfigurations.gsiPosition == 'combineInline'
                        "
                        class="btn-tooltip d-flex"
                        class="table-row action-button"
                        pTooltip="Action Buttons"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        (click)="setHeaderBackgroundSelected($event, -1)"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        pResizableColumn
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                      >
                        <span
                          class="entity-table-header-span main-attr text-truncate"
                          [ngStyle]="{
                            color: hovered ? headerFontHoverColor : headerFontColor,
                            'font-family': headerFontFamily?.font,
                            'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                            'font-weight': headerBold ? 'bold' : 'normal',
                            'font-style': headerItalic ? 'italic' : 'normal',
                            'font-size': headerFontSize + 'px',
                            'text-align': headerAlignment?.justify
                          }"
                          >{{ gsiConfigurations?.gsiInlineTitle }}
                        </span>
                      </th>
                    </tr>
                    <tr [ngClass]="isNestedRow ? '' : 'd-none'">
                      <th
                        class="w-50 t-center"
                        *ngIf="
                          (!allowSelection && recordSelection == 'single' && !advanceTableFeatures.hideSelectButton) ||
                          (recordSelection == 'multiple' && !advanceTableFeatures.hideSelectButton)
                        "
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        pResizableColumn
                      ></th>
                      <th
                        class="w-70 t-center"
                        *ngIf="!advanceTableFeatures.serialNumbers"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        pResizableColumn
                      ></th>
                      <th
                        class="w-70 t-center"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        pResizableColumn
                      ></th>
                      <th
                        class="btn-tooltip d-flex"
                        pTooltip="{{ row.attributeHeading }}"
                        id="nestedRowHeader"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        [ngStyle]="{
                          'background-color':
                            hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                              ? headerHoverColor
                              : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'None',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        *ngFor="let row of nestedTableData?.[0];let k = index"
                        class="table-row"
                        (drop)="nestedRowDropAttributeInTemplates($event, k)"
                        (dragover)="allowDrop($event)"
                        (click)="setHeaderBackgroundSelected($event, k)"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        pResizableColumn
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                      >
                        <!-- [frozen]="detectHeaderFreeze(row.attributeHeading)" -->
                        <span
                          class="entity-table-header-span main-attr text-truncate"
                          [ngStyle]="{
                            color:
                              hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                                ? headerFontHoverColor
                                : headerFontColor,
                            'font-family': headerFontFamily?.font,
                            'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                            'font-weight': headerBold ? 'bold' : 'normal',
                            'font-style': headerItalic ? 'italic' : 'normal',
                            'font-size': headerFontSize + 'px',
                            'text-align': headerAlignment?.justify
                          }"
                          #attributeItem
                          >{{ row?.attributeHeading }}
                        </span>
                        <span class="delete-attr" *ngIf="attributeItem.innerHTML.trim() !== 'Attribute'"
                          ><img
                            class="cursor-pointer"
                            (click)="onDeletingNestedAttr(attributeItem); $event.stopPropagation()"
                            [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-cols let-i="rowIndex">
                    <tr
                      [ngClass]="{
                        'row-outline': gridLines?.rows?.selected == true
                      }"
                      [ngStyle]="{
                        'border-top-color': gridLines?.rows?.selected ? gridLines?.rows?.borderColor : 'none',
                        height: rowSize + 'px'
                      }"
                    >
                      <td
                        class="table-row w-50 t-center"
                        *ngIf="recordSelection == 'multiple' && !advanceTableFeatures.hideSelectButton"
                        (click)="setRowBackGroundSelected(i)"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i
                              ? oddRowHoverColor
                              : oddRowColor,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'None',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseout)="setOutHover(i)"
                      >
                        <p-tableCheckbox [value]="i + 2"></p-tableCheckbox>
                      </td>
                      <td
                        class="table-row w-50 t-center"
                        *ngIf="!allowSelection && recordSelection == 'single' && !advanceTableFeatures.hideSelectButton"
                        (click)="setRowBackGroundSelected(i)"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i
                              ? oddRowHoverColor
                              : oddRowColor,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseout)="setOutHover(i)"
                      >
                        <p-tableRadioButton id="radio" [value]="i + 2"></p-tableRadioButton>
                      </td>
                      <td
                        class="table-row w-70 t-center"
                        (click)="setRowBackGroundSelected(i)"
                        *ngIf="!advanceTableFeatures.serialNumbers"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i
                              ? oddRowHoverColor
                              : oddRowColor,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseout)="setOutHover(i)"
                      >
                        <span
                          [ngStyle]="{
                            color:
                              i % 2 == 0
                                ? evenHovered == i
                                  ? evenRowFontHoverColor
                                  : evenRowFontColor
                                : oddHovered == i
                                ? oddRowFontHoverColor
                                : oddRowFontColor,
                            'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                            'text-decoration-line':
                              i % 2 == 0
                                ? evenRowUnderLine
                                  ? 'underline'
                                  : 'none'
                                : oddRowUnderLine
                                ? 'underline'
                                : 'none',
                            'font-weight':
                              i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                            'font-style':
                              i % 2 == 0 ? (evenRowItalic ? 'italic' : 'normal') : oddRowItalic ? 'italic' : 'normal',
                            'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                            'text-align': i % 2 == 0 ? evenRowAlignment?.justify : oddRowAlignment?.justify
                          }"
                        >
                          {{ i + 1 }}
                        </span>
                      </td>
                      <td
                        class="table-row w-70 t-center"
                        (click)="setRowBackGroundSelected(i)"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i
                              ? oddRowHoverColor
                              : oddRowColor,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          height: rowSize + 'px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseout)="setOutHover(i)"
                        *ngIf="isNestedRow1"
                      ></td>
                      <td
                        *ngFor="let col of cols; let j = index"
                        class="table-row"
                        (click)="setRowBackGroundSelected(i)"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                              ? oddRowHoverColor
                              : oddRowColor,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseover)="setHover(i, j)"
                        (mouseout)="setOutHover(i)"
                        [style.width]="col.width ? col.width : ''"
                      >
                        <p
                          class="table-value btn-tooltip"
                          pTooltip="{{ col.attributeValue }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                          [ngStyle]="{
                            color:
                              i % 2 == 0
                                ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                  ? evenRowFontHoverColor
                                  : evenRowFontColor
                                : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                ? oddRowFontHoverColor
                                : oddRowFontColor,
                            'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                            'text-decoration-line':
                              i % 2 == 0
                                ? evenRowUnderLine
                                  ? 'underline'
                                  : 'none'
                                : oddRowUnderLine
                                ? 'underline'
                                : 'none',
                            'font-weight':
                              i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                            'font-style':
                              i % 2 == 0 ? (evenRowItalic ? 'italic' : 'normal') : oddRowItalic ? 'italic' : 'normal',
                            'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                            'text-align': i % 2 == 0 ? evenRowAlignment?.justify : oddRowAlignment?.justify
                          }"
                        >
                          {{ col.attributeValue }} value
                        </p>
                      </td>
                      <td
                        class="table-row"
                        *ngIf="
                          (dropActionBts?.length > 0 && gsiConfigurations.gsiPosition == 'inline') ||
                          gsiConfigurations.gsiPosition == 'combineInline'
                        "
                        class="table-row"
                        (click)="setRowBackGroundSelected(i)"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i
                              ? oddRowHoverColor
                              : oddRowColor
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseover)="setHover(i)"
                        (mouseout)="setOutHover(i)"
                      >
                        <ng-container *ngIf="!isMore" class="table-value btn-tooltip">
                          <div class="gsibuttons" *ngIf="dropActionBts?.length > 0">
                            <ng-container *ngFor="let btn of dropActionBts; index as ind">
                              <div
                                class="icon-btn"
                                (click)="
                                  selectGsiButton(ind);
                                  actionBtnSelection[ind] = !actionBtnSelection[ind];
                                  gsiBtnSelected[ind] = !gsiBtnSelected[ind];
                                "
                                [ngClass]="{ 'table-button-selected': actionBtnSelection?.[ind] }"
                                [ngStyle]="{
                                  border: gsiBtnBorderColor ? '1px solid' : '',
                                'background-color': gsiBtnHovered?.[ind] && ( i % 2 == 0 ? evenHovered == i :oddHovered == i ) ? dropActionBts?.[ind]?.gsiConfiguration?.backgroundHover : dropActionBts?.[ind]?.gsiConfiguration?.background,
                                'text-decoration-line': gsiBtnUnderline ? 'underline' : 'none',
                                'border-radius':dropActionBts?.[i]?.gsiConfiguration?.borderRadius,
                                'border-color' : dropActionBts?.[i]?.gsiConfiguration?.border,
                                'font-weight': gsiBtnBold ? 'bold' : 'normal',
                                'font-style': gsiBtnItalic ? 'italic' : 'normal',
                                'font-size': gsiBtnFontSize + 'px',
                                'justify-content': gsiBtnAlignment?.viewClass}"
                                (mouseout)="gsiBtnHovered[ind] = false"
                                (mouseover)="gsiBtnHovered[ind] = true"
                              >
                                <button
                                  [ngClass]="{'d-none':dropActionBts?.[ind]?.gsiConfiguration?.gsiDisplay==='onlyIcon'}"
                                >
                                <span [ngStyle]="{
                                  'color':  gsiBtnHovered?.[ind] && ( i % 2 == 0  ? evenHovered == i :oddHovered == i ) ? dropActionBts?.[ind]?.gsiConfiguration?.textHover : dropActionBts?.[ind]?.gsiConfiguration?.font,
                                  'font-family': fonts?.[i]?.gsiConfiguration?.fontFamily
                                  }">  {{ btn?.gsiName }} </span>
                                </button>
                                <span *ngIf="dropActionBts?.[ind]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'">
                                  <img
                                    [src]="dropActionBts?.[ind]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[ind]?.gsiConfiguration?.imageSrc: defaultGsiImages[dropActionBts?.[ind]?.gsiConfiguration?.imageSrc]"
                                  />
                                </span>
                                <span>
                                  <img
                                    class="icon"
                                    *ngIf="dropActionBts?.[ind]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                                    [src]="dropActionBts?.[ind]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[ind]?.gsiConfiguration?.imageSrc:defaultGsiImages[dropActionBts?.[ind]?.gsiConfiguration?.imageSrc]"
                                    matTooltip="{{ btn?.gsiName }}"
                                  />
                                </span>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="isMore">
                          <div
                            class="tbl-accordion cursor-pointer"
                            *ngIf="dropActionBts?.length > 0"
                          >
                            <p-overlayPanel styleClass="filterOverlay" #more>
                              <!-- <span class="search-filter">
                                <input type="text" placeholder="Search" />
                                <em class="nh-icon nh-icon-search-line"></em>
                                <em class="nh-icon nh-icon-x-lg d-none"></em>
                              </span> -->
                              <div class="checckbox d-flex flex-column nh-row-gap-5">
                                <!-- <mat-option> -->
                                <ng-container *ngFor="let btn of dropActionBts; index as ind">
                                  <div
                                    class="attr-name icon-btn"
                                    (click)="
                                      selectGsiButton(ind);
                                      actionBtnSelection[ind] = !actionBtnSelection[ind];
                                      gsiBtnSelected[ind] = !gsiBtnSelected[ind]
                                    "
                                    [ngClass]="{ 'table-button-selected': actionBtnSelection?.[ind] }"
                                    [ngStyle]="{
                                      border: gsiBtnBorderColor ? '1px solid' : '',
                                    'background-color': gsiBtnHovered?.[ind] ? dropActionBts?.[ind]?.gsiConfiguration?.backgroundHover : dropActionBts?.[ind]?.gsiConfiguration?.background,
                                    'text-decoration-line': gsiBtnUnderline ? 'underline' : 'none',
                                    'border-radius':dropActionBts?.[i]?.gsiConfiguration?.borderRadius,
                                    'border-color' : dropActionBts?.[i]?.gsiConfiguration?.border,
                                    'font-weight': gsiBtnBold ? 'bold' : 'normal',
                                    'font-style': gsiBtnItalic ? 'italic' : 'normal',
                                    'font-size': gsiBtnFontSize + 'px',
                                    'justify-content': gsiBtnAlignment?.viewClass}"
                                    (mouseout)="gsiBtnHovered[ind] = false"
                                    (mouseover)="gsiBtnHovered[ind] = true"
                                  >
                                    <span
                                      *ngIf="dropActionBts?.[ind]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'"
                                    >
                                      <img
                                        [src]="dropActionBts?.[ind]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[ind]?.gsiConfiguration?.imageSrc: defaultGsiImages[dropActionBts?.[ind]?.gsiConfiguration?.imageSrc]"
                                      />
                                    </span>
                                    <button
                                      [ngClass]="{'d-none':dropActionBts?.[ind]?.gsiConfiguration?.gsiDisplay==='onlyIcon'}"
                                      matTooltip="{{ btn?.gsiName }}"
                                    >
                                     <span [ngStyle]="{
                                      'color':   gsiBtnHovered?.[ind] ? dropActionBts?.[ind]?.gsiConfiguration?.textHover : dropActionBts?.[ind]?.gsiConfiguration?.font,
                                    'font-family': fonts?.[i]?.gsiConfiguration?.fontFamily
                                      }"> {{ btn?.gsiName }}</span>
                                    </button>
                                    <span>
                                      <img
                                        class="icon"
                                        *ngIf="dropActionBts?.[ind]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                                        [src]="dropActionBts?.[ind]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[ind]?.gsiConfiguration?.imageSrc:defaultGsiImages[dropActionBts?.[ind]?.gsiConfiguration?.imageSrc]"
                                        matTooltip="{{ btn?.gsiName }}"
                                      />
                                    </span>
                                  </div>
                                </ng-container>
                                <!-- </mat-option> -->
                              </div>
                            </p-overlayPanel>
                            <span
                              class="filter-name text-truncate saved-menu"
                              [ngClass]="{ active: false }"
                              [ngStyle]="{
                                color:
                                  i % 2 == 0
                                    ? evenHovered == i
                                      ? evenRowFontHoverColor
                                      : evenRowFontColor
                                    : oddHovered == i
                                    ? oddRowFontHoverColor
                                    : oddRowFontColor,
                                'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                                'text-decoration-line':
                                  i % 2 == 0
                                    ? evenRowUnderLine
                                      ? 'underline'
                                      : 'none'
                                    : oddRowUnderLine
                                    ? 'underline'
                                    : 'none',
                                'font-weight':
                                  i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                                'font-style':
                                  i % 2 == 0
                                    ? evenRowItalic
                                      ? 'italic'
                                      : 'normal'
                                    : oddRowItalic
                                    ? 'italic'
                                    : 'normal',
                                'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                                'text-align': i % 2 == 0 ? evenRowAlignment?.justify : oddRowAlignment?.justify
                              }"
                              >{{ gsiConfigurations?.combineName }}</span
                            >
                            <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
                              <ng-container *ngFor="let btn of dropActionBts; index as ind">
                                <mat-option>
                                  <div class="attr-name">{{ btn?.gsiName }}</div>
                                </mat-option>
                              </ng-container>
                            </mat-menu>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div
                  id="footerDescription"
                  class="table-description"
                  *ngIf="footerDescription && toggleFooterChecked"
                  [ngClass]="footerDescriptionBackgroundSelected ? 'table-header-selected' : ''"
                  (click)="setFooterDescriptionBackgroundSelected()"
                  (mouseover)="footerHovered = true"
                  (mouseout)="footerHovered = false"
                  [ngStyle]="{
                    'background-color': footerDescriptionBackground,
                    color: footerDescriptionFontColor,
                    'font-family': footerDescriptionFontStyle?.font,
                    'font-weight': footerDescriptionBold ? 'bold' : 'normal',
                    'font-style': footerDescriptionItalic ? 'italic' : 'normal',
                    'text-decoration-line': footerDescriptionUnderline ? 'underline' : 'none',
                    'text-align': footerDescriptionAlignment?.justify,
                    'font-size': footerDescriptionFontSize + 'px'
                  }"
                >
                  {{ footerDescription }}
                </div>
                <div class="table-pagination" *ngIf="showPagination">
                  <app-entity-grid-card-paginator
                    [length]="5"
                    [pageSize]="paginationRowSize"
                    [paginationJustify]="paginatedAlignment"
                  >
                  </app-entity-grid-card-paginator>
                </div>
              </div>
            </div>
          </div>
          <!-- <app-advanced-features-table
          *ngIf="isAdvancedFeature"
          [entityData]="entityData"
          [selectedEntityData]="selectedEntityData"
          [dropAdvancedFeature]="dropAdvancedFeature"
          ></app-advanced-features-table> -->
          <app-drop-advanced-features
            *ngIf="isAdvancedFeature"
            [entityData]="entityData"
            [settings]="settings"
            [_selectedEntityData]="selectedEntityData"
            [selectedRoleId]="selectedRoleId"
            [setIsEntityTable]="isEntityTable"
            [combinationArray]="combinationArray"
            [dropAdvancedFeature]="dropAdvancedFeature"
            [childGsiList]="childGsiList"
            [urlBindingGsi]="urlBindingGsi"
            [urlBindingAttribute]="urlBindingAttribute"
            (combinationArrayEmit)="combinationArrayEmit($event)"
            (hiddenRuleSetEmit)="hiddenRuleSetEmit($event)"
            (selectedEntityDataEmit)="selectedEntityDataEmit($event)"
            (urlBindingEmit)="urlBindingEmit($event)"
            (tableadvancedFeaturesEmit)="tableadvancedFeaturesEmit($event)"
            (sortCheckedFieldsEmit)="sortCheckedFieldsEmit($event)"
            (searchCheckedFieldsEmit)="searchCheckedFieldsEmit($event)"
            (filterCheckedFieldsEmit)="filterCheckedFieldsEmit($event)"
            (facetCheckedFieldsEmit)="facetCheckedFieldsEmit($event)"
          >
          </app-drop-advanced-features>
        </div>

        <div class="nhrow_col nhrow_right" *ngIf="isCardConfig">
          <div class="et-config-content-right">
            <mat-tab-group class="form-tabs form-tabs-opt1">
              <mat-tab label="Design">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Color
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="color-customisation">
                      <ul>
                        <li>
                          <p>Fill Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ background: backgroundColor }"></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('background')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Border Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ background: borderColor }"></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('border')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Text Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ background: textColor }"></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('font')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Text Hover Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ background: textHover }"></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('textHover')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Hover Color</p>
                          <p class="box-color">
                            <span class="color-fill" [ngStyle]="{ background: backgroundHoverColor }"></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('backgroundHover')"></i>
                          </p>
                        </li>
                      </ul>
                      <span
                        *ngIf="colorPickerToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        (colorPickerChange)="changeColor($event)"
                        [(colorPicker)]="colorModel"
                        [cpOKButton]="true"
                        (colorPickerSelect)="closeColorPicker($event)"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Gridlines
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="grid-customisation">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.outLine.selected"
                            disableRipple
                            ngDefaultControl
                            name="gridline-outLine"
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/outline.svg" />
                              <p>OutLine</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <div *ngIf="gridLines.outLine.selected">
                          <ng-template
                            [ngTemplateOutlet]="borderStyle"
                            [ngTemplateOutletContext]="{ data: { label: 'outLine' } }"
                          >
                          </ng-template>
                        </div>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.rows.selected"
                            ngDefaultControl
                            name="gridline-rows"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/rows.svg" />
                              <p>Rows</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <div *ngIf="gridLines.rows.selected">
                          <ng-template
                            [ngTemplateOutlet]="borderStyle"
                            [ngTemplateOutletContext]="{ data: { label: 'rows' } }"
                          >
                          </ng-template>
                        </div>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.column.selected"
                            ngDefaultControl
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/column.svg" />
                              <p>Columns</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <div *ngIf="gridLines.column.selected">
                          <ng-template
                            [ngTemplateOutlet]="borderStyle"
                            [ngTemplateOutletContext]="{ data: { label: 'column' } }"
                          >
                          </ng-template>
                        </div>
                        <li *ngIf="gridLines?.outLine?.selected">
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.outLine.selectedOutLine"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/thickline.svg" />
                              <p>Thick outline</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <!-- <li class="row-sizes">
                          <p-selectButton
                            [options]="sizes"
                            [(ngModel)]="gridLines.selectedStyle"
                            optionLabel="name"
                          ></p-selectButton>
                        </li> -->
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Text
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="test">
                      <p class="title text-color">Font Family</p>
                      <div class="entity-custom-select">
                        <mat-form-field appearance="fill" floatLabel="never">
                          <mat-select
                            (ngModelChange)="selectedeFontChange($event)"
                            [(ngModel)]="selectedFont"
                            placeholder="Inter"
                            panelClass="entity-custom-select-options"
                          >
                            <mat-option *ngFor="let font of fonts" [value]="font">{{ font?.name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <p class="title text-color">Font Style</p>
                      <div class="entity-custom-select"></div>
                      <div class="font-style">
                        <ul>
                          <li (click)="checkIsbold()">
                            <span>B</span>
                          </li>
                          <li (click)="checkIsItalic()">
                            <span>i</span>
                          </li>
                          <li (click)="checkIsUnderLine()">
                            <span>U</span>
                          </li>
                        </ul>
                      </div>
                      <div class="alignment">
                        <p class="title text-color">Alignment</p>
                        <ul>
                          <li
                            *ngFor="let align of alignmentOptions"
                            (click)="checkAlign(align)"
                            [ngClass]="{ active: alignment == align }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                      <div class="font-section">
                        <p class="title text-color">Font Size</p>
                        <div class="font-input">
                          <button class="font-btn">
                            <i class="bi bi-dash" (click)="decreaseFontSize()"></i>
                          </button>
                          <span>{{ fontSize }}</span>
                          <button class="font-btn">
                            <i class="bi bi-plus" (click)="increaseFontSize()"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="dropActionBts?.length > 0">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Gsi Button Configuration
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="gsi-configuration">
                      <div class="gsi-sec">
                        <ul>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'onTop'; isMore = false; inTableGSIButtons = false
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'onTop' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-ontop"></em>
                            </button>
                            <p>On Top</p>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'inline'; isMore = false; inTableGSIButtons = true
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'inline' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-inline"></em>
                            </button>
                            <p>Inline</p>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'combineInline'; isMore = true; inTableGSIButtons = true
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'combineInline' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-combine-inline"></em>
                            </button>
                            <p>Combine Inline</p>
                          </li>
                        </ul>
                        <div
                          class="advance-features nh-mt-16"
                          *ngIf="gsiConfigurations?.gsiPosition === 'combineInline'"
                        >
                          <ul>
                            <li class="flex-grow-1">
                              <mat-slide-toggle
                                id="slide_toggleBtn_hideAttrName"
                                [(ngModel)]="gsiConfigurations.hideAttrName"
                                [ngModelOptions]="{ standalone: true }"
                                value="true"
                                disableRipple
                                class="er-filter-toggle er-filter-toggle-a"
                              >
                                <span>
                                  <p class="nh-mt-0">{{ labels?.Hide_Attribute_Name || 'Hide Attribute Name' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="input-section"
                        *ngIf="
                          gsiConfigurations.gsiPosition == 'combineInline' || gsiConfigurations.gsiPosition == 'inline'
                        "
                      >
                        <p class="title text-color">Column Title</p>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Actions"
                          [(ngModel)]="gsiConfigurations.gsiInlineTitle"
                        />
                      </div>
                      <div
                        class="input-section"
                        *ngIf="
                          gsiConfigurations.gsiPosition == 'combine' || gsiConfigurations.gsiPosition == 'combineInline'
                        "
                      >
                        <p class="title text-color">Combination Name</p>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Actions"
                          [(ngModel)]="gsiConfigurations.combineName"
                        />
                      </div>
                      <div class="add-icon" *ngIf="isSelectGsi">
                        <p class="nh-mt-16">configure {{ gsiForConfigure }} :</p>
                        <mat-slide-toggle
                          [(ngModel)]="gsiNameWithIcon"
                          [disabled]="gsiOnlyIcon"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a nh-mt-16 nh-mb-16"
                        >
                          <span>
                            <p class="title">Icon with GSI Name</p>
                          </span>
                        </mat-slide-toggle>
                        <mat-slide-toggle
                          [(ngModel)]="gsiOnlyIcon"
                          [disabled]="gsiNameWithIcon"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a"
                        >
                          <span>
                            <p class="title">Only Icon</p>
                          </span>
                        </mat-slide-toggle>

                        <div class="icon-section">
                          <ul class="nh-mt-24">
                            <li>
                              <button
                                class="btn btn-icon"
                                (click)="imgSrc = '/assets/img/general-entity/Advance-features/cart.svg'"
                              >
                                <img [src]="'/assets/img/general-entity/Advance-features/cart.svg'" alt="cart" />
                              </button>
                            </li>
                            <li>
                              <button
                                class="btn btn-icon"
                                (click)="
                                  selectGsiIcon = 'heart';
                                  imgSrc = '/assets/img/general-entity/Advance-features/heart.svg'
                                "
                              >
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/heart.svg"
                                  alt="heart"
                                />
                              </button>
                            </li>
                            <li>
                              <button
                                class="btn btn-icon"
                                (click)="
                                  selectGsiIcon = 'bookmark';
                                  imgSrc = '/assets/img/general-entity/Advance-features/bookmark.svg'
                                "
                              >
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/bookmark.svg"
                                  alt="bookmark"
                                />
                              </button>
                            </li>
                            <li>
                              <button
                                class="btn btn-icon"
                                (click)="
                                  selectGsiIcon = 'payment';
                                  imgSrc = '/assets/img/general-entity/Advance-features/payment.svg'
                                "
                              >
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/payment.svg"
                                  alt="payment"
                                />
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div class="upload-section">
                          <ul>
                            <li class="items">
                              <article class="box">
                                <div class="box-left">
                                  <p class="box-title text-color">Default Icon upload</p>
                                  <small class="text-color">file format: png, svg</small>
                                  <div class="btn-upload" (change)="uploadIcon($event)">
                                    <input type="file" placeholder="Attachment" />
                                    <button class="btn-acc btn-dark" type="button">
                                      Upload
                                    </button>
                                  </div>
                                </div>
                                <div class="box-right">
                                  <div class="close-icon">
                                    <em class="nh-icon nh-icon-x-circle"></em>
                                  </div>
                                  <div class="box-icon">
                                    <img
                                      [src]="imgSrc.length > 0 ? imgSrc : defaultGsiImages[selectGsiIcon]"
                                      alt="calender"
                                    />
                                  </div>
                                </div>
                              </article>
                            </li>
                          </ul>
                        </div>

                        <div class="button-sec">
                          <mat-slide-toggle
                            [(ngModel)]="buttonstyle"
                            class="er-filter-toggle er-filter-toggle-a nh-mb-12"
                          >
                            <span>
                              <p class="title">Button</p>
                            </span>
                          </mat-slide-toggle>
                          <ng-container *ngIf="buttonstyle">
                            <p class="title text-color">Button style:</p>
                            <div class="d-flex nh-column-gap-20">
                              <ng-container *ngFor="let i of [this.gsiBtnBackgroundColor, '#E3E3E3']">
                                <button
                                  [ngStyle]="{ backgroundColor: i }"
                                  (click)="gsiBtnStyle(dropActionBts, '', i)"
                                  class="btn-style w-100 cursor-pointer"
                                >
                                  CTA
                                </button>
                              </ng-container>
                            </div>
                            <p class="title text-color nh-mt-12">Button shape:</p>
                            <div class="d-flex nh-column-gap-20">
                              <ng-container *ngFor="let i of ['0px', '8px', '100px']">
                                <button
                                  [ngStyle]="{ borderRadius: i, backgroundColor: btnBackgroundcolor }"
                                  (click)="gsiBtnStyle(dropActionBts, i)"
                                  class="btn-style w-100 cursor-pointer"
                                >
                                  CTA
                                </button>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                        <button class="btn-acc btn-dark nh-mt-20 ml-auto" (click)="saveGsiConfigurations()">
                          Save
                        </button>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Freeze columns
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- <div class="header-freeze">
                      <div class="header-btn">
                        <ul>
                          <li>
                            <button
                              (click)="tableFreezePosition = 'top'"
                              class="btn-acc btn-gsi"
                              [ngClass]="{ active: tableFreezePosition == 'top' }"
                            >
                              <em class="nh-icon nh-icon-freeze-top"></em>
                            </button>
                          </li>
                          <li>
                            <button
                              (click)="tableFreezePosition = 'left'"
                              class="btn-acc btn-gsi"
                              [ngClass]="{ active: tableFreezePosition == 'left' }"
                            >
                              <em class="nh-icon nh-icon-freeze-left"></em>
                            </button>
                          </li>
                          <li>
                            <button
                              (click)="tableFreezePosition = 'topLeft'"
                              class="btn-acc btn-gsi"
                              [ngClass]="{ active: tableFreezePosition == 'topLeft' }"
                            >
                              <em class="nh-icon nh-icon-freeze-top-left"></em>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="entity-custom-select nh-mt-16"
                        *ngIf="tableFreezePosition == 'left' || tableFreezePosition == 'topLeft'"
                      >
                        <mat-form-field appearance="fill" floatLabel="never">
                          <mat-select
                            [(ngModel)]="freezeAttribute"
                            placeholder="Select Attributes"
                            panelClass="entity-custom-select-options entity-multi-select"
                            multiple
                          >
                            <mat-option *ngFor="let attr of dropAttributes" [value]="attr.name">{{
                              attr.name
                            }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div> -->
                    <mat-slide-toggle
                      id="freezeSelection"
                      labelPosition="before"
                      [(ngModel)]="advanceTableFeatures.freezeSelection"
                      disableRipple
                    >
                      Freeze Selection
                    </mat-slide-toggle>
                    <mat-slide-toggle
                      id="freezeSeNum"
                      labelPosition="before"
                      [(ngModel)]="advanceTableFeatures.freezeSeNum"
                      disableRipple
                    >
                      Freeze Sr.No
                    </mat-slide-toggle>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Pagination
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="pagination-sec">
                      <ul>
                        <li class="nh-mb-12">
                          <mat-slide-toggle
                            [(ngModel)]="showPagination"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Enable</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                      <div class="rows">
                        <p class="title text-color">{{ labels?.Number_of_rows_per_page }}</p>
                        <div class="entity-custom-select">
                          <mat-form-field appearance="fill" floatLabel="never">
                            <mat-select
                              [(ngModel)]="paginationRowSize"
                              panelClass="entity-custom-select-options"
                              placeholder="10"
                            >
                              <mat-option [value]="10">10</mat-option>
                              <mat-option [value]="15">15</mat-option>
                              <mat-option [value]="25">25</mat-option>
                              <mat-option [value]="50">50</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!-- <div class="font-section">
                        <p class="title text-color">Rows Per Page</p>
                        <div class="font-input">
                          <button class="btn-acc font-btn">
                            <i (click)="decreasePaginateSize()" class="bi bi-dash"></i>
                          </button>
                          <span>{{ paginationRowSize }}</span>
                          <button class="btn-acc font-btn">
                            <i (click)="increasePaginateSize()" class="bi bi-plus"></i>
                          </button>
                        </div>
                      </div> -->

                      <div class="alignment">
                        <p class="title text-color">Alignment</p>
                        <ul>
                          <li
                            *ngFor="let align of alignmentOptions"
                            (click)="checkPaginatedAlign(align)"
                            [ngClass]="{ active: paginatedAlignment?.justify == align?.justify }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                      <!-- <ul>
                        <li class="nh-mb-12">
                          <mat-slide-toggle
                            [(ngModel)]="hidePagination"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>hide Pagination Numbers</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul> -->
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Entity Description
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="entity-description">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="entitytitletoggle"
                            class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                          >
                            <span>
                              Entity Title
                            </span>
                          </mat-slide-toggle>
                          <textarea
                            id="desc"
                            name="desc"
                            maxlength="100"
                            class="text-field"
                            [(ngModel)]="EntityTitle"
                            *ngIf="entitytitletoggle"
                          >
                        {{ EntityTitle }}
                        </textarea
                          >
                          <div class="count-sec" *ngIf="entitytitletoggle">
                            <button class="btn-acc btn-dark" (click)="saveEntityDescription(EntityTitle)">
                              Save
                            </button>
                          </div>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="toggleHeaderChecked"
                            class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                          >
                            <span>
                              Table Header Description
                            </span>
                          </mat-slide-toggle>
                          <textarea
                            id="desc"
                            name="desc"
                            maxlength="100"
                            class="text-field"
                            [(ngModel)]="headerTempDescription"
                            *ngIf="toggleHeaderChecked"
                          >
                              {{ headerTempDescription }}
                              </textarea
                          >

                          <div class="count-sec" *ngIf="toggleHeaderChecked">
                            <button class="btn-acc btn-dark" (click)="saveHeaderDescription(headerTempDescription)">
                              Save
                            </button>
                          </div>
                        </li>
                        <li class="nh-mb-12">
                          <mat-slide-toggle
                            [(ngModel)]="toggleFooterChecked"
                            class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                          >
                            <span>
                              Table Footer Description
                            </span>
                          </mat-slide-toggle>
                          <textarea
                            id="desc"
                            name="desc"
                            maxlength="100"
                            class="text-field"
                            [(ngModel)]="footerTempDescription"
                            *ngIf="toggleFooterChecked"
                          >
                              {{ footerTempDescription }}
                              </textarea
                          >

                          <div class="count-sec" *ngIf="toggleFooterChecked">
                            <button class="btn-acc btn-dark" (click)="saveFooterDescription(footerTempDescription)">
                              Save
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Component Scale
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="header-freeze">
                      <div class="header-btn">
                        <ul>
                          <li>
                            <button
                              (click)="changeRowSize('large')"
                              class="btn-acc btn-gsi"
                              [ngClass]="{ active: tableComponentScale == 'large' }"
                            >
                              <em class="nh-icon nh-icon-freeze-top"></em>
                            </button>
                            <span>Large</span>
                          </li>
                          <li>
                            <button
                              (click)="changeRowSize('medium')"
                              class="btn-acc btn-gsi"
                              [ngClass]="{ active: tableComponentScale == 'medium' }"
                            >
                              <em class="nh-icon nh-icon-freeze-left"></em>
                            </button>
                            <span>Medium</span>
                          </li>
                          <li>
                            <button
                              (click)="changeRowSize('small')"
                              class="btn-acc btn-gsi"
                              [ngClass]="{ active: tableComponentScale == 'small' }"
                            >
                              <em class="nh-icon nh-icon-freeze-top-left"></em>
                            </button>
                            <span>Small</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Advance Features
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <ul>
                        <!-- <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.rowhighlights"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Row Highlights</p>
                            </span>
                          </mat-slide-toggle>
                        </li> -->
                        <!-- <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.rowalternate"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Row Alternate</p>
                            </span>
                          </mat-slide-toggle>
                        </li> -->
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.columnHighlights"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Column Highlights</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.attributeWrap"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Attribute Wrap</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.serialNumbers"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Hide Serial Numbers</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.showLabels"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Hide Labels</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <!-- <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.allowUpload"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Allow Upload</p>
                            </span>
                          </mat-slide-toggle>
                        </li> -->
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.allowExpand"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Allow Expand</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.hideSelectButton"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Hide Select Button</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.deleteRecord"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Allow Delete</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <!-- <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.gridTable"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>View as Grid Table</p>
                            </span>
                          </mat-slide-toggle>
                        </li> -->
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.allowDuplication"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Allow Duplication</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.showResetPagination"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Show reset Pagination size</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li *ngIf="entityData?.data?.cu?.reservedCUType == 'NSL_Template_SearchResult'">
                          <mat-slide-toggle
                            id="updateTableData"
                            [(ngModel)]="advanceTableFeatures.updateTableData"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>{{ labels?.Allow_Update_Data || 'Allow Update Data' }}</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li *ngIf="entityData?.data?.cu?.reservedCUType == 'NSL_Template_SearchResult'">
                          <mat-slide-toggle
                            id="hidePagination"
                            [(ngModel)]="advanceTableFeatures.hidePagination"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>{{ labels?.Hide_Pagination || 'Hide Pagination' }}</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            id="singlePageTable"
                            [(ngModel)]="advanceTableFeatures.singlePageTable"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Show Single Page Table</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li class="d-block design-fileupload">
                          <span>
                              <p>Upload Template</p>
                          </span>
                          <app-file-upload
                            [editFileUrl]="fileObj"
                            (filestoSend)="updateFiles($event)"
                            (sheetData)="getSheetData($event)"
                            [fileUploadFromEntity]="false"
                            (fileUploadResponse)="getTemplateUrl($event)"
                            (deletedFileResponse)="updateFiles($event)"
                          ></app-file-upload>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Record Selection
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="record-selection">
                      <mat-radio-group
                        fxLayout="column"
                        [(ngModel)]="recordSelection"
                        name="configureTableSelection"
                        class="radiogrp nh-mb-12"
                      >
                        <mat-radio-button id="configureSingle" value="single">
                          <span class="nh-fs-14 nh-lh-17 nh-fw-500">Single Select</span>
                        </mat-radio-button>
                        <mat-radio-button id="configureMulti" value="multiple">
                          <span class="nh-fs-14 nh-lh-17 nh-fw-500">Multi Select</span>
                        </mat-radio-button>
                      </mat-radio-group>

                      <div *ngIf="recordSelection == 'single'">
                        <mat-slide-toggle
                          id="submitOnSelection"
                          [(ngModel)]="submitOnSelection"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a label-color line-clamp nh-mb-8"
                        >
                          <span>
                            Do you want to submit on selection ?
                          </span>
                        </mat-slide-toggle>
                      </div>
                      <div *ngIf="recordSelection == 'multiple'">
                        <div
                          class="d-flex align-items-center justify-content-between nh-mb-12 nh-column-gap-16 minmax-input"
                        >
                          <mat-form-field floatLabel="never" class="custom-input-mat">
                            <label class="title text-color nh-mb-8">{{ labels?.Minimum_Selection }}</label>
                            <input type="number" [min]="1" [(ngModel)]="tableFeatures.minimumselection" matInput />
                          </mat-form-field>
                          <mat-form-field floatLabel="never" class="custom-input-mat">
                            <label class="title text-color nh-mb-8">{{ labels?.Maximum_Selection }}</label>
                            <input type="number" [(ngModel)]="tableFeatures.maximumselection" matInput />
                          </mat-form-field>
                        </div>
                        <div class="check-all">
                          <mat-checkbox [(ngModel)]="tableFeatures.minimumselectionAll" class="cstm-check">
                            {{ labels?.All }}
                          </mat-checkbox>
                        </div>
                      </div>
                      <mat-slide-toggle
                        [(ngModel)]="allowSelection"
                        disableRipple
                        class="er-filter-toggle er-filter-toggle-a nh-mt-12 label-color"
                      >
                        <span>Enable Box Selection</span>
                      </mat-slide-toggle>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Nested Row
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            name="expaned_Row"
                            [(ngModel)]="isNestedRow1"
                            (ngModelChange)="nestedRowToggle($event)"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Allow Expand Row</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Table Ui Controls Design
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            name="expaned_Row"
                            [(ngModel)]="tableuiDesign"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Apply Table Ui Controls Design</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>
              <mat-tab label="Condition">
                <div class="condition-wrapper">
                  <div
                    class="add-condition d-flex justify-content-center align-items-center cursor-pointer"
                    (click)="addCondition()"
                  >
                    <span><i class="bi bi-plus-lg"></i></span>
                    <span class="text">{{ labels?.Add_Condition }}</span>
                  </div>
                  <div class="condition-box d-flex">
                    <ng-container *ngFor="let condition of conditionArray; let index = index">
                      <div class="d-flex nh-fs-14 nh-column-gap-10">
                        <span>{{ condition.conditionAttribute }}</span>
                        <span>{{ condition.condition }}</span>
                        <span [ngStyle]="{ color: condition.textColor, 'background-color': condition.fillColor }"
                          >{{ condition.selectedConditonValue }}
                        </span>
                        <i class="bi bi-trash3 nh-fs-14 ml-auto" (click)="deleteSelectedCondition(index, 'child')"></i>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="condition-tab" *ngIf="addConditionVariable">
                  <div class="entity-custom-select">
                    <mat-form-field appearance="fill" floatLabel="never">
                      <mat-select
                        [(ngModel)]="conditionAttribute"
                        placeholder="Select Attributes"
                        panelClass="entity-custom-select-options entity-multi-select"
                        multiple
                      >
                        <mat-option *ngFor="let attr of dropAttributes"  [value]="attr.name">{{ attr?.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="operator-list">
                    <ul (click)="addConditionOperator()">
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          [ngClass]="{ active: selectedCondition === '==' }"
                          (click)="setSelectedCondition('==')"
                        >
                          <span>==</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          [ngClass]="{ active: selectedCondition === '!=' }"
                          (click)="setSelectedCondition('!=')"
                        >
                          <span>!=</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('>')"
                          [ngClass]="{ active: selectedCondition === '>' }"
                        >
                          <span>></span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('\<')"
                          [ngClass]="{ active: selectedCondition === '\<' }"
                        >
                          <span><</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('>=')"
                          [ngClass]="{ active: selectedCondition === '>=' }"
                        >
                          <span>>=</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('\<=')"
                          [ngClass]="{ active: selectedCondition === '\<=' }"
                        >
                          <span><=</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="input-section">
                    <p class="title text-color">Value</p>
                    <input type="text" class="form-control" placeholder="" [(ngModel)]="selectedConditonValue" />
                  </div>
                  <div class="color-customisation">
                    <ul>
                      <li (click)="ChangeConditionColorToggle()">
                        <p>Fill Color</p>
                        <p class="box-color">
                          <span class="color-fill" [ngStyle]="{ 'background-color': conditionColor }"></span
                          ><i class="bi bi-eyedropper"></i>
                        </p>
                      </li>
                      <span
                        *ngIf="conditioncColorToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        [(colorPicker)]="conditionColor"
                        [cpOKButton]="true"
                        (colorPickerSelect)="ChangeConditionColorToggle()"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                      <!-- <li (click)="conditionTextColorToggler = !conditionTextColorToggler">
                        <p>{{ labels?.Text_Color || 'Text Color' }}</p>
                        <p class="box-color">
                          <span class="color-fill" [ngStyle]="{ 'background-color': selectedConditionTextColor }"></span
                          ><i class="bi bi-eyedropper"></i>
                        </p>
                      </li>
                      <span
                        *ngIf="conditionTextColorToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        [(colorPicker)]="selectedConditionTextColor"
                        [cpOKButton]="true"
                        (colorPickerSelect)="conditionTextColorToggler = !conditionTextColorToggler"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                    </ul>
                  </div>
                  <div class="color-customisation">
                    <ul>
                      <li (click)="ChangeConditionColorToggle()">
                        <p>Fill Color</p>
                        <p class="box-color">
                          <span class="color-fill" [ngStyle]="{ 'background-color': conditionColor }"></span
                          ><i class="bi bi-eyedropper"></i>
                        </p>
                      </li>
                      <span
                        *ngIf="conditioncColorToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        [(colorPicker)]="conditionColor"
                        [cpOKButton]="true"
                        (colorPickerSelect)="ChangeConditionColorToggle()"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                      <!-- <li (click)="conditionTextColorToggler = !conditionTextColorToggler">
                        <p>{{ labels?.Text_Color || 'Text Color' }}</p>
                        <p class="box-color">
                          <span class="color-fill" [ngStyle]="{ 'background-color': selectedConditionTextColor }"></span
                          ><i class="bi bi-eyedropper"></i>
                        </p>
                      </li>
                      <span
                        *ngIf="conditionTextColorToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        [(colorPicker)]="selectedConditionTextColor"
                        [cpOKButton]="true"
                        (colorPickerSelect)="conditionTextColorToggler = !conditionTextColorToggler"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span> -->
                    </ul>
                  </div>
                  <button class="btn-acc btn-dark create-btn" (click)="createCondition()">
                    Create
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="entity-right entity-right-config d-none" *ngIf="!isGrammer">
            <div class="entity-editfields">
              <div class="entitytab-one entitytab-fields">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                  <mat-tab label="Configure">
                    <div class="editfields-form" *ngIf="isSlectedRole">
                      <label>ROLES</label>
                      <div class="editfields-info">
                        <h4
                          pTooltip="{{ selectedRole }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{ selectedRole }}
                        </h4>
                        <button class="btn btnedit">
                          <img
                            [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                            (click)="backtoRolePage()"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="editfields-form" *ngIf="isSlectedEntity">
                      <label>ENTITY</label>
                      <div class="editfields-info">
                        <h4
                          pTooltip="{{ selectedEntity }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{ selectedEntity }}
                        </h4>
                        <button class="btn btnedit">
                          <img
                            [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                            (click)="backtoEntityPage()"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="editfields-form" *ngIf="isSlectedGsi">
                      <label>Solution</label>
                      <div class="editfields-info">
                        <h4
                          pTooltip="{{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }} {{
                            selectedGsi[2] ? ',' + selectedGsi[2] : ''
                          }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}
                          {{ selectedGsi[2] ? ',' + selectedGsi[2] : '' }}
                        </h4>
                        <button class="btn btnedit">
                          <img
                            [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                            (click)="backtoGsiPage()"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="condition">
                      <span><i class="bi bi-plus-lg"></i></span>
                      <span class="text">Add More CTA Button</span>
                    </div>
                  </mat-tab>
                  <mat-tab label="Design" *ngIf="!isAdvancedFeature">
                    <div class="entitytab-two entitytab-fields">
                      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight>
                        <mat-tab label="Color">
                          <div class="color-picker1">
                            <div class="choose-color" *ngIf="tabSelected === 'child' || !applySameToExpand">
                              <div
                                class="color-picker-me btn btn-primary"
                                *ngIf="!colorPickerToggler"
                                (click)="toggleColorPicker()"
                              >
                                Choose Color
                              </div>
                              <span
                                *ngIf="colorPickerToggler"
                                [cpPositionRelativeToArrow]="true"
                                [cpDialogDisplay]="'inline'"
                                (colorPickerChange)="changeColor($event)"
                                [(colorPicker)]="colorModel"
                                [cpOKButton]="true"
                                (colorPickerSelect)="closeColorPicker($event)"
                                [cpToggle]="true"
                                [cpOKButtonText]="'Done'"
                              >
                              </span>
                            </div>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #borderStyle let-data="data">
  <div class="color-customisation">
    <ul>
      <li>
        <p>Border Color</p>
        <p class="box-color">
          <span class="color-fill" [ngStyle]="{ 'background-color': gridLines[data.label].borderColor }"></span>
          <i class="bi bi-eyedropper" (click)="borderColorpickerToggler(data.label)"></i>
        </p>
      </li>
    </ul>
  </div>
  <span
    *ngIf="borderColorPicker[data.label]"
    [cpPositionRelativeToArrow]="true"
    [cpDialogDisplay]="'inline'"
    (colorPickerChange)="choseBorderColor($event, data.label)"
    [(colorPicker)]="colorModel"
    [cpOKButton]="true"
    (colorPickerSelect)="borderColorpickerToggler(data.label)"
    [cpToggle]="true"
    [cpOKButtonText]="'Done'"
  >
  </span>
  <!-- <div class="font-section">
    <p class="title text-color">Border Thickness</p>
    <div class="font-input">
      <button class="btn-acc font-btn">
        <i (click)="decreaseBorderThickness(data.label)" class="bi bi-dash"></i>
      </button>
      <span>{{ gridLines[data.label]?.borderThickness }}</span>
      <button class="btn-acc font-btn">
        <i (click)="increaseBorderThickness(data.label)" class="bi bi-plus"></i>
      </button>
    </div>
  </div> -->
  <!-- <div class="font-section">
    <p class="title text-color">Border Radius</p>
    <div class="font-input">
      <button class="btn-acc font-btn">
        <i (click)="decreaseBorderRadius(data.label)" class="bi bi-dash"></i>
      </button>
      <span>{{ gridLines[data.label]?.borderRadius }}</span>
      <button class="btn-acc font-btn">
        <i (click)="increaseBorderRadius(data.label)" class="bi bi-plus"></i>
      </button>
    </div>
  </div> -->
</ng-template>
