<ng-template #attributeTemplate let-data="data">
  <li
    class="{{ data?.class }}"
    (drop)="dropAttribute($event, 'any')"
    appConfigureAttribute
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [isDesign]="isDesign"
    [_isCondition]="conditionColors"
    [_style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedConditionElement)="recieveConditionColor($event)"
    (dragover)="allowDrop($event)"
  >
    <span class="main-attr" #attributeItem>Attribute</span>
    <span class="delete-attr" *ngIf="attributeItem.innerHTML !== 'Attribute'"
      ><img
        class="cursor-pointer"
        (click)="onDeletingAttr(attributeItem)"
        [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
        alt=""
    /></span>
  </li>
  <!-- <span #showDeleteIcon hidden>false</span> -->
</ng-template>
<div
  class="custom-dashboard-table"
  CardColorConfig
  [_changeShadowStyle]="boxShadowData"
  [style]="style"
  [_selectedDirectiveId]="selectedDirectiveId"
  (emitSelectedElement)="recieveEmittedDirectiveId($event)"
>
  <table>
    <thead>
      <tr>
        <td colspan="3">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { class: 'border nh-p-16 nh-radius-8 nh-mr-10 nh-mb-10 bg-light-blue' }
            }"
          ></ng-template>
        </td>
        <ng-container *ngFor="let event of ambulanceDetails[0].events">
          <td [attr.colspan]="event.seriesOfEventsHappening.length">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { class: 'border nh-p-16 nh-radius-8 nh-mr-10 nh-mb-10  bg-light-yellow' }
              }"
            ></ng-template>
            <!-- <div
              class="border nh-p-16 nh-radius-8 nh-mr-10 nh-mb-10"
              [ngClass]="{
               : event.eventName === 'Off Road',
                'bg-light-red': event.eventName === 'Workshop',
                'bg-light-purple': event.eventName === 'Back Redeployment'
              }"
            >
              {{ event.eventName }}
            </div> -->
          </td>
        </ng-container>
      </tr>
      <tr>
        <td>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { class: 'border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue' }
            }"
          ></ng-template>
          <!-- <div class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Registration Number</p>
          </div> -->
        </td>
        <td>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { class: 'border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue' }
            }"
          ></ng-template>
          <!-- <div class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Off Road Date and Time</p>
          </div> -->
        </td>
        <td>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { class: 'border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue' }
            }"
          ></ng-template>
          <!-- <div class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-blue">
            <p>Off Road Duration</p>
          </div> -->
        </td>
        <ng-container *ngFor="let event of ambulanceDetails[0].events">
          <td *ngFor="let seriesEvent of event.seriesOfEventsHappening">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { class: 'border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10 bg-light-yellow' }
              }"
            ></ng-template>
            <!-- <div
              class="border nh-radius-8 w-100px h-100 nh-pt-16 nh-pb-16 nh-mr-10 nh-mb-10"
              [ngClass]="{
                'bg-light-yellow': event.eventName === 'Off Road',
                'bg-light-red': event.eventName === 'Workshop',
                'bg-light-purple': event.eventName === 'Back Redeployment'
              }"
            >
              {{ seriesEvent.name }}
            </div> -->
          </td>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ambulance of ambulanceDetails">
        <tr class="border h-60">
          <td>
            <div>
              {{ ambulance.registrationNumber }}
            </div>
          </td>
          <td>
            <div>
              {{ ambulance.offRoadDateTime }}
            </div>
          </td>
          <td>
            <div>
              {{ ambulance.offRoadDuration }}
            </div>
          </td>
          <ng-container *ngFor="let event of ambulance.events">
            <td *ngFor="let seriesEvent of event.seriesOfEventsHappening">
              <div (click)="handleDotClick(seriesEvent.status)">
                <div
                  class="dot"
                  [ngClass]="{
                    'dot-blue': seriesEvent.status === 'blue',
                    'dot-red': seriesEvent.status === 'red',
                    'dot-yellow': seriesEvent.status === 'yellow',
                    ringing: seriesEvent.status === 'red' && showRedCircle
                  }"
                ></div>
                <div class="circle" *ngIf="showRedCircle && seriesEvent.status === 'red'"></div>
                <p class="text-danger" *ngIf="showRedCircle && seriesEvent.status === 'red'">High Priority</p>
              </div>
            </td>
          </ng-container>
        </tr>
        <tr class="h-12">
          <td></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
