import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '@common-services';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [{ name: 'widget', src: './assets/widget/widget-space.min.js' }];

declare var document: any;

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoaderService extends CduiBaseService {
  private scripts: any = {};

  constructor(private injector: Injector) {
    super(injector);
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }
  /*istanbul ignore next */
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  /*istanbul ignore next */
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.addingCDNURL(this.scripts[name].src);
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document?.getElementsByTagName('head')[0]?.appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  addingCDNURL(staticPath: any) {
    /*istanbul ignore next */
    if (this.environment.cdnUrl) {
      let staticUrl = staticPath.split('assets');
      return this.environment.cdnUrl + '/assets' + staticUrl[1];
    }
    return staticPath;
  }
}
