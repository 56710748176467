<!-- <p
  class="top-text"
  *ngIf="field?.configuration?.helperTextPosition == 'top'"
  [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
>
  {{ field?.configuration?.helpertext }}
</p> -->
<div class="signature-container">
  <div class="signature-draw">
    <canvas id="signature-pad" #signaturePadRef class="signature-pad-v2" height="150" [width]="isInTable ? 200 : 300"></canvas>
  </div>
  <div class="signaturepad-button">
    <button class="clear-btn float-left" type="button" (click)="clearSignature()">
      {{ labels?.Clear }}
    </button>
  </div>
</div>
<!-- <p
  class="bottom-text"
  *ngIf="field?.configuration?.helperTextPosition == 'bottom'"
  [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
>
  {{ field?.configuration?.helpertext }}
</p> -->
