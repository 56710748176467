export interface EntityList {
  id?: number;
  name: string;
  isMultiValue: boolean;
  isReserved: boolean;
  nslAttributes?: any;
  layerType?: any;
  // dsdId?: number;
  ontology?: [];
  keywords?: [];
  author?: {};
  description: '';
  tfId?: number;
  status?: any;
  dsdStatus?: any;
  version?: any;
  configuration?: string;
  isNameUpdated?: boolean;
  entityIndex?: number;
}
export class EntityList {
  constructor() {
    this.id = null;
    this.name = '';
    this.nslAttributes = [];
    this.layerType = '';
    this.description = '';
    this.isReserved = false;
    this.isMultiValue = false;
  }
}
