<div class="input-field">
  <div>
    <div
      class="reality"
      cdkDropList
      #realityField="cdkDropList"
      [cdkDropListData]="realityList"
      [cdkDropListConnectedTo]="[potentialityField, entityDisplayList]"
      (cdkDropListDropped)="drop($event)"
    >
      <label for="reality-input-field">{{ labels?.Reality }}</label>
      <div
        class="input-box gsiTitle creatingField gsilink isSelected"
        *ngFor="let realdata of realityList; let i = index"
        cdkDrag
      >
        <input
          type="text"
          name="reality"
          class="form-control w-100"
          placeholder="Drag and Drop the BET"
          id="reality-input-field"
          placeholder="Drag and Drop Your BET"
          autocomplete="off"
          [(ngModel)]="realdata.label"
        />
      </div>
    </div>
  </div>
  <div class="toggle">
    <mat-slide-toggle [(ngModel)]="isOrderEnabled" [ngModelOptions]="{ standalone: true }">{{
      labels?.Order_Sensitivity
    }}</mat-slide-toggle>
  </div>

  <div>
    <div
      class="potentiality"
      cdkDropList
      #potentialityField="cdkDropList"
      [cdkDropListData]="potentialityList"
      [cdkDropListConnectedTo]="[realityField, entityDisplayList]"
      (cdkDropListDropped)="drop($event)"
    >
      <label for="potentiality-input-field">{{ labels?.Potentiality }}</label>
      <div
        class="input-box gsiTitle creatingField gsilink isSelected"
        *ngFor="let potentialdata of potentialityList"
        cdkDrag
      >
        <input
          type="text"
          name="reality"
          class="form-control input-box w-100"
          placeholder="Drag and Drop the BET"
          id="potentiality-input-field"
          placeholder="Drag and Drop Your BET"
          autocomplete="off"
          [(ngModel)]="potentialdata.label"
        />
      </div>
    </div>
  </div>
  <div class="btns-wrap">
    <button mat-stroked-button (click)="createSolution()">{{ labels?.Create_Solution }}</button>
    <button mat-stroked-button (click)="clickedConnector()">{{ labels?.Create_Connector }}</button>
    <button mat-stroked-button (click)="save()">{{ labels?.Save }}</button>
  </div>
  <div class="drag-fields-wrap">
    <div class="potentiality">
      <label class="dropdown mr-2"
        >{{ labels?.Shades }}

        <span class="pt-2" (click)="expandShadesList()">
          <span *ngIf="!expandShadeItems" class="pointer"><mat-icon>expand_more</mat-icon></span>
          <span *ngIf="expandShadeItems" class="pointer"><mat-icon>expand_less</mat-icon></span>
        </span>
      </label>

      <input
        type="text"
        name="reality"
        class="form-control input-box w-100 search-item"
        placeholder="Search"
        id="potentiality-input-field"
        placeholder="Search"
        autocomplete="on"
        *ngIf="!expandShadeItems"
        (keyup)="filterSearchData($event.target.value)"
      />
    </div>
    <div
      class="drag-fields-items"
      cdkDropList
      #entityDisplayList="cdkDropList"
      [cdkDropListData]="entityList"
      [cdkDropListConnectedTo]="[realityField, potentialityField]"
      (cdkDropListDropped)="drop($event)"
      *ngIf="!expandShadeItems"
    >
      <div class="drag-items" *ngFor="let label of filteredOptions; let i = index" cdkDrag>{{ label.label }}</div>
    </div>
  </div>

  <div class="potentiality" *ngIf="expandShadeItems">
    <label for="wish-block">{{ labels?.Wish }}</label>
    <div class="gsiTitle creatingField gsilink isSelected w-100">
      <input
        readonly
        class="form-control w-100"
        id="wish-block"
        type="text"
        placeholder="Select a solution from collection"
        [(ngModel)]="gsiName"
      />
    </div>
  </div>
  <div class="btns-wrap">
    <button mat-stroked-button (click)="saveImportedGsi()">{{ labels?.Done }}</button>
  </div>
</div>
