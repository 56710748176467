import { dldGSIMapper } from './dld-gsi.mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function dldGSIFullListMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      result: 'result',
    },
    operate: [
      {
        run: function (val: any[]) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return dldGSIMapper(x);
          });
        },
        on: 'result',
      },
    ],
  };

  const result = transform(data, map);

  /* istanbul ignore next */
  return result?.result;
}
