import { entityDeMapper } from '../entity/entity-demapper';
import { solutionLogicDeMapper } from '../solution-logic/solutionLogic-demapper';

declare var require: any;
let transform = require('node-json-transform').transform;

export function gsiDeMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'dsdId',
      id: 'id',
      agents: 'agents',
      attributeDesignRights: 'attributeDesignRights',
      attributeTransactionRights: 'attributeTransactionRights',
      allowPreviouCUView: 'allowPreviouCUView',
      isNavigableCu: 'isNavigableCu',
      changeUnits: 'changeUnits',
      condition: 'condition',
      conditionName: 'conditionName',
      constraint: 'constraint',
      constraintName: 'constraintName',
      contextualId: 'contextualId',
      cuType: 'cuType',
      dcd: 'dcd',
      description: 'description',
      designTimeRights: 'designTimeRights',
      displayName: 'displayName',
      entityDesignRights: 'entityDesignRights',
      entityTransactionRights: 'entityTransactionRights',
      exceptionCUList: 'exceptionCUList',
      gsiList: 'gsiList',
      guid: 'guid',
      index: 'index',
      layers: 'layers',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      mindCUList: 'mindCUList',
      name: 'name',
      nextTriggerSet: 'nextTriggerSet',
      ontology: 'ontology',
      ownerId: 'ownerId',
      referencedChangeUnit: 'referencedChangeUnit',
      reserved: 'reserved',
      reservedCUType: 'reservedCUType',
      enableBot: 'enableBot',
      intents: 'intents',
      finalResponse: 'finalResponse',
      solutionLogic: 'solutionLogic',
      space: 'space',
      status: 'status',
      dsdStatus: 'dsdStatus',
      subOrdinateCUList: 'subOrdinateCUList',
      symbolicId: 'symbolicId',
      txnTimeRights: 'txnTimeRights',
      userId: 'userId',
      masterId: 'masterId',
      author: 'author',
      keywords: 'keywords',
      version: 'version',
      specialFeatureProperties: 'specialFeatureProperties',
      isNameUpdated: 'isNameUpdated',
      dsdReferencedChangeUnit: 'referencedChangeUnit',
      source: 'source',
      advancedProperties: 'advancedProperties',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      mileStoneId: 'mileStoneId',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      dsdMetadataId: 'dsdMetadataId',
      querySentence: 'sentenceTags',
      isParallel: 'isParallel',
      isEmbedded: 'isEmbedded',
      isExceptionSkippable: 'isExceptionSkippable',
      isCancellable: 'isCancellable',
      listRundownErrorInfo: 'listRundownErrorInfo',
      measures: 'measures',
      isGSILayerBasedRecursion: 'isGSILayerBasedRecursion',
      preRecursiveConditionCheck: 'preRecursiveConditionCheck',
      cdecCuMappings: 'cdecCuMappings',
      txnDataSaveMode: 'txnDataSaveMode',
      listRecursionContextualID: 'listRecursionContextualID',
      listRecursionContextualName: 'listRecursionContextualName',
      transactionEndpoint: 'transactionEndpoint',
      isTransactionalGSI: 'isTransactionalGSI'
    },
    operate: [
      {
        run: function (val: any) {
          return val ? val : null;
        },
        on: 'dsdId',
      },
      {
        run: (val: any) => {
          return val?.map((x: any) => {
            return {
              ...x,
              formula: x?.formulaName ? x?.formulaName : x?.formula,
              sourceContextualId: x?.sourceContextualName,
              targetContextualId: x?.targetContextualName,
              sourceArray: setSourceArray(x?.sourceArray),
            };
          });
        },
        on: 'dcd',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((x: any) => {
              if (x?.cuType === /*ChangeUnitTypes.GSI*/ 'GSI') {
                return gsiDeMapper(x);
              } else {
                if (x?.triggerCULayers?.length > 0 && x?.status == 'DRAFT') {
                  x.triggerCULayers = [];
                }
                if (
                  x?.slotItemProperties &&
                  Object?.keys(x?.slotItemProperties)?.length === 0
                )
                  delete x.slotItemProperties;
                return solutionLogicDeMapper(x);
              }
            });
          } else {
            return [];
          }
        },
        on: 'solutionLogic',
      },
      {
        run: function (val: any) {
          let layers = transform(val, nestedMap);
          // check if gsi layers are empty
          /* istanbul ignore next */
          return layers?.filter(
            (layer: any) => layer.participatingItems?.length > 0
          );
        },
        on: 'layers',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraint',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraintName',
      },
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((y: any) => {
              return {
                nextCUName: y?.nextCUName,
                index: y?.index,
                dsdNextCUId: y?.nextCUId,
                isParallel: y?.isParallel,
              };
            });
          } else {
            return [];
          }
        },
        on: 'nextTriggerSet',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/
          if (val !== undefined) {
            // val = removeDataType(val);
            return JSON.stringify(val);
          } else {
            return val;
          }
        },
        on: 'querySentence',
      },
    ],
  };

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return {
              changeDriverMetaData: x.changeDriverMetaData, /// optional changes drivers data ///
              item: {
                DATA: entityDeMapper(x),
                TYPE: 'GeneralEntity',
              },
              isMultiValue: x.isMultiValue ? x.isMultiValue : false,
              isPlaceholder: x.isPlaceholder,
              conditionalPotentiality: x?.conditionalPotentiality,
              conditionalPotentialityName: x?.conditionalPotentialityName,
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };
  return transform(data, map);
}

function setSourceArray(sourceArray: any) {
  return sourceArray?.map((x: any) => {
    return {
      ...x,
      id: x?.name,
    };
  });
}
