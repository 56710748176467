export interface GlobalSearch {
  solutionsList: any;
  entitiesList: any;
  googleSearchAllList: any;
}

export interface AdvSearchAdminConfigType {
  tenantId: string;
  myLibrary: any;
  nslLibrary: any;
}

export interface AdvSearchFetchConfig {
  searchCriteria: string;
  recordType: string;
  status: string[];
  gsTypeConfig: any;
  pageNumber: number;
  pageSize: number;
  gsFilters: any;
}

export class AdvSearchFetchConfig {
  constructor() {
    this.searchCriteria = '';
    this.recordType = null;
    this.status = ['DRAFT', 'READY', 'REJECTED', 'PUBLISHED', 'APPROVED'];
    this.gsTypeConfig = null;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.gsFilters = null;
  }
}

// export const advSearchAdminConfig: AdvSearchAdminConfigType = {
//   tenantId: '',
//   myLibrary: {
//     ALL: {
//       displayName: 'All',
//       tempDisplayName: 'All',
//       enabled: true,
//       isEdit: false
//     },
//     BOOK: {
//       displayName: 'Books',
//       tempDisplayName: 'Books',
//       enabled: true,
//       isEdit: false
//     },
//     GE: {
//       displayName: 'Entities',
//       tempDisplayName: 'Entities',
//       enabled: true,
//       isEdit: false
//     },
//     GSI: {
//       displayName: 'Solutions',
//       tempDisplayName: 'Solutions',
//       enabled: true,
//       isEdit: false
//     },
//     RECORDS: {
//       displayName: "Records",
//       tempDisplayName: "Records",
//       enabled: true,
//       isEdit: false
//     },
//     CU: {
//       displayName: "CU",
//       tempDisplayName: "CU",
//       enabled: true,
//       isEdit: false
//     }
//   },
//   nslLibrary: {
//     ALL: {
//       displayName: 'All',
//       tempDisplayName: 'All',
//       enabled: true,
//       isEdit: false
//     },
//     BOOK: {
//       displayName: 'Books',
//       tempDisplayName: 'Books',
//       enabled: true,
//       isEdit: false
//     },
//     GE: {
//       displayName: 'Entities',
//       tempDisplayName: 'Entities',
//       enabled: true,
//       isEdit: false
//     },
//     GSI: {
//       displayName: 'Solutions',
//       tempDisplayName: 'Solutions',
//       enabled: true,
//       isEdit: false
//     },
//     RECORDS: {
//       displayName: "Records",
//       tempDisplayName: "Records",
//       enabled: true,
//       isEdit: false
//     },
//     CU: {
//       displayName: "CU",
//       tempDisplayName: "CU",
//       enabled: true,
//       isEdit: false
//     }
//   }
// }