<div class="modal-wrapper">
  <ng-container *ngIf="screen == 0">
    <div class="modal-header p-0 nh-mb-20">
      <p class="modal-header-heading">Create Name</p>
    </div>
    <div class="modal-body p-0 nh-mb-20">
      <div class="form-group">
        <label for="fileName" class="nh-mb-4">Filter Name</label>
        <input [(ngModel)]="filterName" type="text" class="form-control nh-w-400" />
      </div>
    </div>
    <div class="modal-footer p-0 justify-content-start nh-ml-16">
      <button (click)="closeDialog()" class="btn-rounded cursor-pointer">Cancel</button>
      <button (click)="checkIfFilterNameIsGiven()" class="btn-rounded cursor-pointer bg-primary-blue">Submit</button>
    </div>
  </ng-container>
  <ng-container *ngIf="screen == 1">
    <div mat-dialog-title class="modal-header p-0 nh-mb-20">
      <p class="modal-header-heading">Create EQL</p>
    </div>
    <app-rule-set [(hiddenRuleSet)]="hiddenRuleSet" [selectedEntityData]="selectedEntityData"> </app-rule-set>
    <div mat-dialog-actions class="footer-grp d-flex nh-column-gap-16 justify-content-between align-items-center">
      <mat-checkbox [(ngModel)]="isFavorite" id="markAsFavorite">
        Mark as Favorite
      </mat-checkbox>
      <div class="modal-footer p-0 justify-content-start">
        <button class="btn-rounded cursor-pointer bg-primary-blue" (click)="submitToApi()">Submit</button>
        <button (click)="closeDialog()" class="btn-rounded cursor-pointer">Cancel</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="screen == 2">
    <div class="modal-header p-0 nh-mb-20">
      <p class="modal-header-heading">Share {{ filterName && filterName != '' ? filterName : 'Filters' }}</p>
    </div>
    <div>
      <div class="form-group">
        <label for="fileName" class="nh-mb-4">Category</label>
        <input [(ngModel)]="category" type="text" class="form-control nh-w-400" />
      </div>
      <div class="form-group">
        <label for="fileName" class="nh-mb-4">Description</label>
        <input [(ngModel)]="description" type="text" class="form-control nh-w-400" />
      </div>
    </div>
    <div class="modal-body p-0 nh-mb-20">
      <h4>General Access</h4>
      <div class="and-or-conditions users-rights">
        <label class="nh-mb-4">Users</label>
        <mat-select
          #userMultiple
          multiple
          placeholder="{{ labels?.Select_Users || 'Select Users' }}"
          [(ngModel)]="selectedUsers"
          name="User"
          panelClass="mat-select-class nh-ml-30"
        >
          <div class="dropdown-search" OutsideClick>
              <input
              type="text"
              class="input-search"
              [(ngModel)]="searchUserText"
              id="searchText"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Search"
              (ngModelChange)="searchUserText$.next($event)"
              *ngIf="users?.length >= 0"
            />
              <em class="nh-icon nh-icon-search-line"></em>
          </div>
          <div class="user-options">
            <mat-option tooltipStyleClass="z-9999" pTooltip="{{ user.name }}" tooltipPosition="top" *ngFor="let user of users" [value]="user.value" class="users-choose">
              {{ user.name }}
            </mat-option>
          </div>
        </mat-select>
        <label class="nh-mb-4 nh-mt-12">Rights</label>
        <mat-select
          placeholder="{{ labels?.Select_Rights || 'Select Rights' }}"
          [(ngModel)]="selectedRights"
          name="Rights"
          panelClass="mat-select-class nh-ml-5"
        >
          <mat-option tooltipStyleClass="z-9999" pTooltip="{{ right }}" tooltipPosition="top"  *ngFor="let right of rights" [value]="right">
            {{ right }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="modal-footer p-0 justify-content-start">
      <button (click)="getLinkOfTheFilter()" class="btn-rounded cursor-pointer">Get Link</button>
      <button (click)="closeDialog()" class="btn-rounded cursor-pointer">Cancel</button>
      <button (click)="submitEditApi()" class="btn-rounded cursor-pointer bg-primary-blue">Submit</button>
    </div>
  </ng-container>
</div>
