import { DOCUMENT } from '@angular/common';
import {
  Component, EventEmitter,
  Inject,
  Input,
  OnInit,
  Output, TemplateRef, ViewChild, ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplatesBaseComponent } from '../../card-templates/templates-base/templates-base.component';
import { AlertService } from '@common-services';

@Component({
  selector: 'app-templates1',
  templateUrl: './templates1.component.html',
  styleUrls: ['./templates1.component.scss'],
})
export class Templates1Component extends TemplatesBaseComponent implements OnInit {
  @Input() kanbanStatus: any[];
  @ViewChildren('attributeItem') attributeItem: any;
  @ViewChild('kanbanStatusConfigTemplate') kanbanStatusConfigModal: TemplateRef<HTMLElement>;
  @Output() kanbanStatusEmitter: any = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) document: Document,
    public dialog: MatDialog,
    public alertService: AlertService,
  ) {
    super(
    alertService
    );
  }

  ngOnInit(): void {}

  dropAttribute(ev: any, attrType?: any, position?: number) {
    super.dropAttribute(ev, attrType);
    console.log(this.kanbanPositionMap);
    if (
      this.kanbanPositionMap?.[0] &&
      this.kanbanPositionMap?.[0]?.attributeType?.extendedProperties?.sourceValues?.length > 0
    ) {
      this.kanbanStatus = this.kanbanPositionMap?.[0]?.attributeType?.extendedProperties?.sourceValues?.filter(
        (status: any) => {
          /* istanbul ignore else */
          if (status?.TYPE === 'OptionValue') {
            return status;
          }
        }
      );
      this.kanbanStatusEmitter.emit(this.kanbanStatus);
    } else {
      this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      this.onDeletingAttr(this.attributeItem?.toArray()?.[0]?.nativeElement);
    }
  }

  openKanbanStatusConfigModal() {
    this.dialog.open(this.kanbanStatusConfigModal, {
      width: '800px',
      data: this.kanbanStatus,
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.kanbanStatus, event.previousIndex, event.currentIndex);
    this.kanbanStatusEmitter.emit(this.kanbanStatus);
  }

  hideKanbanStatus(status: any) {
    status.isHidden = !status.isHidden;
    this.kanbanStatusEmitter.emit(this.kanbanStatus);
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
