let transform = require('node-json-transform').transform;

export function tableDataPayload(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      content: 'data',
      total: 'totalResults',
    },
    operate: [
      {
        run: function (val: any[]) {
          /*istanbul ignore next*/
          return val?.map((x: any) => {
            let txnNslAttribute: any[] = [];
            x?.nslAttributes.forEach((attr: any) => {
              txnNslAttribute.push({
                name: attr?.name,
                nslAttributeID: attr?.id,
                values: attr?.values,
              });
            });
            return {
              generalEntityId: x?.id,
              txnNslAttribute: txnNslAttribute,
              txnRecordId: x?.txnRecordId,
              id: x?.recordId,
            };
          });
        },
        on: 'content',
      },
    ],
  };
  return transform(data, map);
}