let transform = require('node-json-transform').transform;

export function entityDeMapper(data: any, createEntity?: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'id',
      id: 'id',
      name: 'name',
      isMultiValue: 'isMultiValue',
      botQuestion: 'botQuestion',
      isPlaceholder: 'isPlaceholder',
      isReserved: 'isReserved',
      isMasterData: 'isMasterData',
      nslAttributes: 'nslAttributes',
      attachments: 'attachments',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      displayName: 'displayName',
      keywords: 'keywords',
      masterId: 'masterId',
      ontology: 'ontology',
      publisher: 'publisher',
      status: 'status',
      dsdStatus: 'dsdStatus',
      version: 'version',
      ownerId: 'ownerId',
      author: 'author',
      description: 'description',
      showAgents: 'showAgents',
      showAttributes: 'showAttributes',
      configuration: 'configuration',
      isNameUpdated: 'isNameUpdated',
      entityProperties: 'entityProperties',
      source: 'source',
      dsdMetadataId: 'dsdMetadataId',
      memberShip: 'memberShip',
      memberShipName: 'memberShipName',
      isArEntity: 'isArEntity',
      uiProperties: 'uiProperties',
      uniqueConstraints: 'uniqueConstraints',
      conditionalPotentiality: 'conditionalPotentiality',
      conditionalPotentialityName: 'conditionalPotentialityName',
      entityType: 'entityType',
    },
    operate: [
      {
        run: function (val: any) {
          return val ? val : null;
        },
        on: 'dsdId',
      },
      {
        run: function (val: any) {
          return (val && Object?.keys(val)?.length === 0) || val?.type != 'enum' ? undefined : val;
        },
        on: 'entityType',
      },
      {
        run: function (val: any) {
          if (val?.length > 0) {
            /* istanbul ignore next */
            return val?.map((x: any) => {
              if (x?.['configuration'] && typeof x['configuration'] !== 'string') {
                x['configuration'] = JSON.stringify(x['configuration']);
              }
              if (x?.attributeType?.nestedNslDataTypeProperties?.item_type) {
                x.attributeType.nestedNslDataTypeProperties = {
                  ...x?.attributeType?.nestedNslDataTypeProperties,
                  itemType: x.attributeType.nestedNslDataTypeProperties?.item_type,
                };
                delete x.attributeType.nestedNslDataTypeProperties?.item_type;
              }
              if (x.generalEntity) {
                // x.generalEntity.name = x?.name;
                // x.generalEntity.displayName = x?.displayName;
                x.generalEntity.dsdId = x?.generalEntity?.id ? x?.generalEntity?.id : x?.generalEntity?.dsdId;
                x.generalEntity.id = x?.generalEntity?.id ? x?.generalEntity?.id : x?.generalEntity?.dsdId;
                if (!createEntity) {
                  const generalEntityConfig={
                    ...x,
                    dsdId: x.id,
                    id: x.tfId ? x.tfId : x.id,
                    tfId: undefined,
                    /* istanbul ignore next */
                    nslAttributeMetaData: x.generalEntity?.nslAttributeMetaData,
                    /* istanbul ignore next */
                    conditionalPotentiality: x.generalEntity?.conditionalPotentiality
                      ? x.generalEntity?.conditionalPotentiality
                      : x.conditionalPotentiality,
                    isMultiValue: x?.generalEntity?.isMultiValue,
                    /* istanbul ignore next */
                    conditionalPotentialityName: x.generalEntity?.conditionalPotentialityName
                      ? x.generalEntity?.conditionalPotentialityName
                      : x.conditionalPotentialityName,
                    attributeType: x.attributeType,
                    generalEntity: entityDeMapper(x.generalEntity),
                    configuration:x?.generalEntity?.configuration,
                  };
                  Object.keys(generalEntityConfig).forEach((key:any) => generalEntityConfig[key] === undefined && delete generalEntityConfig[key]);
                  return generalEntityConfig
                } else {
                  return {
                    ...x,
                    dsdId: x.id,
                    id: x.tfId ? x.tfId : x.id,
                    tfId: undefined,
                    // attributeType : {...x.attributeType,nestedNslDataTypeProperties:{...x.attributeType?.nestedNslDataTypeProperties,itemType:  x.attributeType?.nestedNslDataTypeProperties?.itemType ? x.attributeType?.nestedNslDataTypeProperties?.itemType : x.attributeType?.nestedNslDataTypeProperties?.item_type,}},
                    /* istanbul ignore next */
                    nslAttributeMetaData: x.generalEntity?.nslAttributeMetaData,
                    /* istanbul ignore next */
                    conditionalPotentiality: x.generalEntity?.conditionalPotentiality
                      ? x.generalEntity?.conditionalPotentiality
                      : x.conditionalPotentiality,
                    isMultiValue: x?.generalEntity?.isMultiValue,
                    /* istanbul ignore next */
                    conditionalPotentialityName: x.generalEntity?.conditionalPotentialityName
                      ? x.generalEntity?.conditionalPotentialityName
                      : x.conditionalPotentialityName,
                    generalEntity: entityDeMapper(x.generalEntity),
                  };
                }
              } else {
                x.tfId = x.id ? x.id : x.dsdId;
                return {
                  ...x,
                  dsdId: x.id,
                  id: x.tfId ? x.tfId : x.id,
                  tfId: undefined,
                  nslAttributeMetaData: x?.nslAttributeMetaData,
                };
              }
            });
          } else {
            return [];
          }
        },
        on: 'nslAttributes',
      },
    ],
  };
  return transform(data, map);
}
