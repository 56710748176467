import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pagination',
})
export class PaginatePipe implements PipeTransform {
  transform(items: any, pageSize: number, pageNo: number): any[] {
    if (!items || !pageSize) return [];
    return items.slice(pageSize * pageNo, (pageNo + 1) * pageSize);
  }
}
