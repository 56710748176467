<ng-container *ngIf="currentLocation">
  <div class="{{ parentClass }}">
    <div class="suport-sub">
      <div class="sub-left-class">
        <!-- <p class="main-label">{{ field.displayName }} :</p> -->
        <div class="d-flex align-items-center nh-mb-8">
          <label
            class="main-label nh-mb-0"
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="
              (field.sentence == null || field?.sentence === '') &&
              !field?.hideLabels &&
              !field?.configuration?.hideLabel
            "
          >
            <span class="main-label-inner">
              <span class="main-label-text text-truncate"
                >{{
                  field?.nestedEntityName == "NSL_Geolocation"
                    ? (field?.entityName | split: "$")
                    : field?.label
                }}
                <span class="main-label-colon">:</span> </span
              >
              <span
                *ngIf="field?.isRequired && !field?.isInfo"
                class="mat-form-field-required-marker"
                >*</span
              ></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              class="material-icons-outlined helperText-infoIcon"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <p
          class="top-text"
          *ngIf="
            field?.configuration?.helperTextPosition == 'top' &&
            !field?.configuration?.showHelperTextIcon
          "
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
      </div>
      <div class="sub-right-class">
        <mat-form-field
          *ngIf="!hideSearchLocation"
          appearance="standard"
          class="{{ appliedClass }}"
          [ngClass]="
            field?.configuration?.locationType == 'Current Location' &&
            field?.configuration?.readCurrentLocation
              ? 'disable-Input'
              : ''
          "
        >
          <div class="google-maps-wrapper">
            <input
              matInput
              placeholder="search for location"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              #search
              [formControl]="searchControl"
              id="searchControl"
              (focus)="onFocus($event)"
              (focusout)="onFocousOut()"
            />
            <div
              *ngIf="
                foundObject?.event == 'Option 1' ||
                foundObject?.event == 'Option 2'
              "
              class="icon"
            >
              <i class="bi bi-cursor"></i>
            </div>
            <div *ngIf="foundObject?.event == 'Option 3'" class="icon">
              <i class="bi bi-compass"></i>
            </div>
            <div *ngIf="foundObject?.event == 'Option 4'" class="icon">
              <i class="bi bi-geo-alt"></i>
            </div>

            <em
              class="nh-icon nh-icon-location actual-map-icon"
              *ngIf="!foundObject?.event || foundObject?.event == ''"
              #iconsvg
            ></em>
          </div>
        </mat-form-field>
      </div>
    </div>
    <p *ngIf="displayLocationstatus" class="warning">
      {{
        labels?.Location_is_disabled_in_Browser_Please_enable_and_reload_the_page_to_Apply_Settings
      }}
    </p>
  </div>
  <div class="nh-position-relative">
    <button
      (click)="openGoogleMaps(origin, destination, wayPoints)"
      class="nh-unsetAll nh-position-absolute opentab-icon nh-cursor-pointer"
      id="googleMaps-redirection"
    >
      <em class="bi bi-arrow-up-right"></em>
    </button>
    <agm-map
      [latitude]="latitude"
      [longitude]="longitude"
      [scrollwheel]="false"
      [zoom]="zoom"
      [usePanning]="true"
    >
      <agm-marker
        [latitude]="latitude"
        (dragEnd)="markerDragEnd($event)"
        [markerDraggable]="
          field?.configuration?.locationType == 'Current Location' &&
          field?.configuration?.readCurrentLocation
            ? false
            : true
        "
        (markerClick)="markerClick(latitude, longitude, $event)"
        [longitude]="longitude"
      ></agm-marker>
    </agm-map>
  </div>
  <p
    class="bottom-text"
    *ngIf="
      field?.configuration?.helperTextPosition == 'bottom' &&
      !field?.configuration?.showHelperTextIcon
    "
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
</ng-container>
<ng-container *ngIf="showMarkers">
  <div class="form-group">
    <div class="d-flex">
      <label
        class="main-label"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="
          (field.sentence == null || field?.sentence === '') &&
          !field?.hideLabels &&
          !field?.configuration?.hideLabel
        "
      >
        <span class="main-label-inner">
          <span
            class="main-label-text text-truncate"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            >{{ field?.label }} :</span
          >
          <span *ngIf="field?.isRequired" class="mat-form-field-required-marker"
            >*</span
          >
        </span>
      </label>
    </div>
    <mat-form-field
      appearance="standard"
      class="{{ appliedClass }}"
      *ngIf="!hideSearchLocation"
    >
      <input
        matInput
        placeholder="search for location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        #search
        [formControl]="searchControl"
        id="searchControl"
        (focus)="onFocus($event)"
      />
      <span
        *ngIf="!foundObject?.event || foundObject?.event == ''"
        class="form-icon map-icon"
      ></span>
      <div
        *ngIf="
          foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'
        "
        class="icon"
      >
        <i class="bi bi-cursor"></i>
      </div>
      <div *ngIf="foundObject?.event == 'Option 3'" class="icon">
        <i class="bi bi-compass"></i>
      </div>
      <div *ngIf="foundObject?.event == 'Option 4'" class="icon">
        <i class="bi bi-geo-alt"></i>
      </div>
    </mat-form-field>
  </div>
  <agm-map
    [latitude]="mapData.lat"
    [longitude]="mapData.lng"
    [zoom]="mapData.zoom"
    [disableDefaultUI]="mapData.disableDefaultUI"
    [zoomControl]="mapData.zoomControl"
  >
    <agm-marker
      *ngFor="let m of mapData.markers; let i = index"
      (markerClick)="markerClick(m.lat, m.lng, m)"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [label]="m.label"
      [markerDraggable]="true"
      (dragEnd)="markerDragEnd($event)"
    >
      <agm-info-window #infowindow>
        <strong>{{ addressByCoord }}</strong>
      </agm-info-window>
    </agm-marker>
    <!-- <agm-circle
      [latitude]="mapData.lat"
      [longitude]="mapData.lng"
      [radius]="radius"
      [fillColor]="'red'"
      [circleDraggable]="true"
      [editable]="false"
    >
    </agm-circle> -->
  </agm-map>
  <p
    class="bottom-text"
    *ngIf="
      field?.configuration?.helperTextPosition == 'bottom' &&
      !field?.configuration?.showHelperTextIcon
    "
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
</ng-container>
<ng-container *ngIf="showNavigation">
  <div class="nh-position-relative">
    <button
      (click)="openGoogleMaps(origin, destination, wayPoints)"
      class="nh-unsetAll nh-position-absolute opentab-icon nh-cursor-pointer"
      id="googleMaps-waypoints"
    >
      <em class="bi bi-arrow-up-right"></em>
    </button>
    <agm-map
      [latitude]="currentLocationData.origin.latitude"
      [longitude]="currentLocationData.origin.longitude"
      [zoom]="mapData.zoom"
      [disableDefaultUI]="mapData.disableDefaultUI"
      [zoomControl]="mapData.zoomControl"
    >
      <agm-direction
        [origin]="origin"
        [destination]="destination"
        [waypoints]="wayPoints"
      >
        <agm-marker
          [latitude]="origin.lat"
          [longitude]="origin.lng"
          [iconUrl]="currentLocationData?.origin?.icon"
        ></agm-marker>
        <agm-marker
          [latitude]="destination.lat"
          [longitude]="destination.lng"
          [iconUrl]="currentLocationData?.destination?.icon"
        ></agm-marker>
      </agm-direction>
    </agm-map>
  </div>
</ng-container>
<div id="map"></div>
<div class="waypoints-route">
  <ul>
    <li *ngFor="let waypt of routeArr">
      {{ waypt }}
    </li>
  </ul>
</div>
