import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatorService } from '@common-services';

@Component({
  selector: 'app-node-confirm-delete',
  templateUrl: './node-confirm-delete.component.html',
  styleUrls: ['./node-confirm-delete.component.scss'],
})
export class NodeConfirmDeleteComponent implements OnInit {
  data: any;
  betType: string = '';
  confirmDelete: boolean = false;
  constructor(
    private translator: TranslatorService,
    public Dialog: MatDialog,
    public dialogRef: MatDialogRef<NodeConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData
  ) {}

  ngOnInit(): void {
    this.data = this.dialog;
    this.betType = this.data?.betType;
  }

  closePopup(res: boolean) {
    this.confirmDelete = res;
    this.dialogRef.close(this.confirmDelete);
  }
}
export interface DialogData {
  gsi: Object;
  currentCU: Object;
  mode: string;
  txnData: Object;
  isReadOnly: Boolean;
}
