declare var require: any;
let transform = require('node-json-transform').transform;
let label_map = {
  nlg: 0,
  'Hum.': 1,
  'Mch.': 2,
  'life agent': 3,
  'Int.': 4,
  'Obj.': 5,
  'Att.': 6,
  const: 7,
  'human agent': 1,
  'machine agent': 2,
  cu: 4,
  entity: 5,
  attributes: 6,
};
export function compositeExtractionDeMapper(data: any) {
  var map = {
    item: {
      physical: 'physical',
      information: 'information',
      nested_response: 'nested_response',
      triggerCES: 'triggerCES',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return mapLabelData(val, label_map);
        },
        on: 'physical',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return mapLabelData(val, label_map);
        },
        on: 'information',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return mapLabelData(val, label_map);
        },
        on: 'triggerCES',
      },
    ],
  };

  const result = transform(data, map);
  return result;
}
function mapLabelData(val: any, label_map: any) {
  /* istanbul ignore next */
  if (val?.length > 0) {
    val?.forEach((tags: any) => {
      if (typeof tags?.tag !== 'number') {
        tags.tag = label_map[tags?.tag];
      }
    });
  }
  return val;
}
