import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionFacadeService, getEventData, getCurrentEventDataArray } from '@common-services';

@Component({
  selector: 'app-dynamic-ui-card-insurance-expand-templates',
  templateUrl: './dynamic-ui-card-insurance-expand-templates.component.html',
  styleUrls: ['./dynamic-ui-card-insurance-expand-templates.component.scss'],
})
export class DynamicUiCardInsuranceExpandTemplatesComponent implements OnInit {
  @Input() attributes: any = {};
  @Output() closeExpandEmitter = new EventEmitter();
  @Input() expandType: any;
  @Input() eventCUList: any;
  contentUrl: any = {};
  showExpand: boolean;
  templateType: any;
  childTemplate: any;
  @Input() listValues: any;
  @Output() selectCardEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() dCard: any;
  @Output() expandEmitter: EventEmitter<any> = new EventEmitter<any>();
  currentEventDataArray: any = [[], [], [], [], [], [], []];
  constructor(private transactionFacadeService: TransactionFacadeService) {}

  ngOnInit(): void {
    this.attachImages();
    this.expandEmitter.emit(true);
  }
  closeExpand() {
    this.closeExpandEmitter.emit(true);
  }
  attachImages() {
    Object.keys(this.attributes).forEach((key, index) => {
      if (this.attributes[key].inputType == 'image' && parseInt(key) >= 0) {
        this.contentUrl[parseInt(key)] = this.attributes[key].attributeValue;
      }
    });
  }
  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any) {
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }
  // Check event data based on attribute id
  checkEventGSI(attr: any, index: number = 0) {
    if (attr && attr?.attributeName && attr?.[attr?.attributeName]) {
      /*istanbul ignore next*/
      this.currentEventDataArray[index] = getCurrentEventDataArray(this.eventCUList, attr[attr?.attributeName]);
      if (this.currentEventDataArray[index].length > 0) {
        return true;
      }
    }
    return false;
  }
}
