<div *ngIf="errorlist.length > 0" class="popover-main popover-small cuError">
  <div class="close-pop" (click)="errorlist.length = 0">
    <i class="bi bi-x-circle-fill" (click)="errorAlertModal = false"></i>
  </div>
  <div class="err-head"><span>CU Name</span> <span>Error Message</span></div>
  <ul class="err-msg">
    <div *ngFor="let error of errorlist">
      <li *ngIf="error.message == 'cu.1'">
        <span>{{ error?.name }}</span
        >CU must always be in [Verb + Noun] or [Verb + Preposition/Article/Determiner + Noun] format
      </li>
      <li *ngIf="error.message == 'cu.2'">
        <span>{{ error?.name }}</span
        >At least 1 noun and at most 3 nouns will be allowed in the CU
      </li>
      <li *ngIf="error.message == 'cu.3'">
        <span>{{ error?.name }}</span
        >Either 1 preposition/1 article/1 Preposition + 1 article is allowed
      </li>
      <li *ngIf="error.message == 'cu.4'">
        <span>{{ error?.name }}</span
        >Prepositions allowed in the CU are "for", "to", "of" and "in"
      </li>
    </div>
  </ul>
</div>
<div class="create-main-section" *ngIf="!indcudata.isEditable && (isValidCuDisplay || physicalLayerSentence)">
  <a href="javascript:;" class="layertypelink cu-steps d-block one"> CU</a>
  <span class="titleClass">
    <span (click)="getCuInfo(indcudata)">
      <span *ngIf="isValidCuDisplay" class="font-weight-bold">
        {{ cuName }}
      </span>
      <span *ngIf="physicalLayerSentence && physicalLayerSentence.length > 0">
        <span *ngFor="let sentence of physicalLayerSentence">
          <span class="sentence-text" [ngClass]="{ 'font-weight-bold': sentence.tag !== 'nlg' }"
            >{{ sentence.token }}
          </span>
        </span>
      </span>
    </span>
    <span
      *ngIf="indcudata?.sentenceTags?.information && indcudata?.sentenceTags?.information.length > 0"
      class="position-absolute cursor-pointer info-btn"
    >
      <div id="main-popover" class="popover-main pop-alert" *ngIf="indcudata.showInfo">
        <a href="javascript:;" class="info-close" (click)="hideInfo()"> </a>
        <p>
          <span *ngFor="let sentence of indcudata.sentenceTags.information">
            <span id="{{ sentence.token }}" [ngClass]="{ 'font-weight-bold': sentence.tag !== 'nlg' }"
              >{{ sentence.token }}
            </span>
          </span>
        </p>
      </div>
      <a href="javascript:;" class="information-icon" (click)="showInfo()">i</a>
    </span>
  </span>
</div>

<div *ngIf="!indcudata.tfId">
  <div>
    <div class="row">
      <div class="gsiTitle fw-bolder gsilink isSelected creatingField change-unit w-100 ml-3 mr-3 gsi-primary">
        <a href="javascript:;" class="layertypelink cu-steps two"> CU</a>
        <div class="w-100 d-flex flex-wrap">
          <input
            type="text"
            class="form-control col w-100"
            [(ngModel)]="cuName"
            (focus)="isCuInputFocus = true; editedCuinput = -1"
            (focusout)="isCuInputFocus = false"
            name="sentence"
            autocomplete="off"
            autofocus
            #changeUnitName
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row form-help-text example-text">
    <div class="col cstm_position">
      <button class="btn btn-light-gray" (click)="validateCU(cuName)">Done</button>
    </div>
  </div>
</div>

<div *ngIf="indcudata.isEditable" class="position-relative">
  <div *ngIf="!indcudata.showTagsView">
    <div class="row">
      <a href="javascript:;" class="layertypelink cu-steps three"> CU</a>
      <div class="gsiTitle fw-bolder gsilink isSelected creatingField change-unit w-100 ml-3 mr-3 gsi-primary">
        <div class="w-100 d-flex flex-wrap sentences-wrapper">
          <span class="symbol" *ngIf="indcudata?.isReserved">R</span>
          <input
            type="text"
            class="form-control col w-100"
            [(ngModel)]="cuSentence"
            (focus)="isCuInputFocus = true; editedCuinput = -1"
            (focusout)="isCuInputFocus = false"
            name="sentence"
            autocomplete="off"
            autofocus
            #changeUnitName
          />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="indcudata.showTagsView">
    <a href="javascript:;" class="layertypelink cu-steps fixed-cu-input d-block four"> CU</a>
    <node-separateCu-display-tags
      [changeUnitIndex]="cuIndex"
      [layerSentence]="physicalLayerSentence"
      [layerType]="'physical'"
      [indcudata]="indcudata"
      [isParagraph]="isParagraph"
    >
    </node-separateCu-display-tags>
  </div>

  <ul class="show labels-modal cu-moreLinks edit-options">
    <li>
      <a href="javascript:;" class="cu-more-icon" (click)="openPopUp('physical')">
        <i class="bi bi-three-dots-vertical"></i>
      </a>
      <ul class="more-modal" *ngIf="clickThreeDot" (clickOutside)="closePopup('physical')">
        <li (click)="closeTagsView('physical')">
          <a href="javascript:;">Edit</a>
        </li>
        <li (click)="openInfoLayers()">
          <a href="javascript:;">information Layer</a>
        </li>
        <li *ngIf="indcudata?.isReserved" (click)="addTriggerCesLayer()">
          <a href="javascript:;">Trigger CES Layer</a>
        </li>
      </ul>
    </li>
  </ul>
  <div class="row">
    <div class="col text-right bottom-act-btns">
      <button
        [disabled]="indcudata?.showTagsView || cuSentence == ''"
        class="btn btn-light btn-validate"
        (click)="validateCu(cuSentence)"
      >
        Validate
      </button>
      <button class="btn btn-light" [disabled]="cuSentence == '' || !indcudata?.showTagsView" (click)="onNextClick()">
        Next
      </button>
    </div>
  </div>

  <div id="reservedcus-info-suggestion" class="reservedcus-info" *ngIf="isReserved">
    <p>
      {{ labels?.Your_requirement_matches_with_Reserved_Change_Units_Would_you_like_to_choose_from_below_Suggestions }}
    </p>
    <div id="reserved-change-units" class="reserved-label">
      <label for="">{{ labels?.RESERVED_CHANGE_UNITS }}</label>
      <ul>
        <li>
          <span
            id="reservedCuSelection_{{ reservedCu?.user_rcu_selection }}"
            style="cursor: pointer;"
            (click)="reservedCuSelection(reservedCu?.user_rcu_selection, reservedCu)"
            >{{ reservedCu?.user_rcu_selection }}</span
          >
          <span>d</span>
        </li>
      </ul>
      <div class="viewall">
        <button id="cancel-button" class="btn btnview" (click)="Cancel()">{{ labels?.Cancel }}</button>
      </div>
    </div>
  </div>

  <!--- Information layer-->
  <div *ngIf="isInfoOpen" class="position-relative">
    <div *ngIf="!indcudata.showInfoTagsView">
      <div class="row">
        <div class="gsiTitle fw-bolder gsilink isSelected creatingField change-unit w-100 ml-3 mr-3 gsi-primary">
          <!-- <a href="javascript:;" class="layertypelink cu-steps five"> CU</a> -->
          <div class="w-100 d-flex flex-wrap sentences-wrapper">
            <input
              type="text"
              class="form-control col w-100"
              [(ngModel)]="cuInfoSentence"
              placeholder="Information Layer"
              name="infoSentence"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="indcudata.showInfoTagsView">
      <node-separateCu-display-tags
        [changeUnitIndex]="cuIndex"
        [layerSentence]="informationLayerSentence"
        [layerType]="'information'"
        [indcudata]="indcudata"
        [isParagraph]="isParagraph"
      >
      </node-separateCu-display-tags>
    </div>
    <ul class="show labels-modal cu-moreLinks edit-options">
      <li>
        <a href="javascript:;" class="cu-more-icon" (click)="openPopUp('information')">
          <i class="bi bi-three-dots-vertical"></i>
        </a>
        <ul class="more-modal" *ngIf="clickThreeDotInfo" (clickOutside)="closePopup('information')">
          <li (click)="closeTagsView('information')">
            <a href="javascript:;" class="pl-0">Edit</a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="row">
      <div class="col text-right bottom-act-btns">
        <button
          [disabled]="cuInfoSentence == '' || indcudata.showInfoTagsView"
          class="btn btn-light btn-validate"
          (click)="validateIL()"
        >
          Validate
        </button>
      </div>
    </div>
  </div>
  <!-- TriggerCES Layer-->
  <div *ngIf="showTriggerCES" class="position-relative">
    <div *ngIf="!indcudata.showTriggerTagsView">
      <div class="row">
        <div class="gsiTitle fw-bolder gsilink isSelected creatingField change-unit w-100 ml-3 mr-3 gsi-primary">
          <!-- <a href="javascript:;" class="layertypelink cu-steps five"> CU</a> -->
          <div class="w-100 d-flex flex-wrap sentences-wrapper">
            <input
              type="text"
              class="form-control col w-100"
              [(ngModel)]="triggerSentence"
              placeholder="TrigerCES Layer"
              name="triggerSentence"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="indcudata.showTriggerTagsView">
      <node-separateCu-display-tags
        [changeUnitIndex]="cuIndex"
        [layerSentence]="triggerCESLayerSentence"
        [layerType]="'triggerCES'"
        [indcudata]="indcudata"
        [isParagraph]="isParagraph"
      >
      </node-separateCu-display-tags>
    </div>
    <ul class="show labels-modal cu-moreLinks edit-options">
      <li>
        <a href="javascript:;" class="cu-more-icon" (click)="openPopUp('triggerCES')">
          <i class="bi bi-three-dots-vertical"></i>
        </a>
        <ul class="more-modal" *ngIf="clickThreeDotTrigger" (clickOutside)="closePopup('triggerCES')">
          <li (click)="closeTagsView('triggerCES')">
            <a href="javascript:;" class="pl-0">Edit</a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="row">
      <div class="col text-right bottom-act-btns">
        <button
          [disabled]="triggerSentence == '' || indcudata.showTriggerTagsView"
          class="btn btn-light btn-validate"
          (click)="validateTC()"
        >
          Validate
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="popover-main pop-alert popup" [ngClass]="{ 'primary-popup': alertTooltip == 'Sentence is Completed' }"
    *ngIf="alertModal" [ngStyle]="{ top: helpModalPositionY - 70 + 'px' }" style="top: -85px !important;">
    <p>
      {{ alertTooltip }}
    </p>
  </div> -->
