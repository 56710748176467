import { NgModule } from '@angular/core';
import { CheckBoxComponent } from './checkbox.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [CheckBoxComponent],
  imports: [ControlsMaterialModule],
  exports: [CheckBoxComponent],
})
export class CheckboxModule {
  static entry = {
    checkbox: CheckBoxComponent,
  };
}
