<div class="studioverse-signup">
  <div class="banner">
    <!-- background image/video for the entire template starts here-->
    <video autoplay loop="loop">
      <source
        [src]="
          loginDesign?.backGround?.link?.desktop
            ? loginDesign.backGround.link.desktop
            : '../../../../../assets/images/login-screen/studio_video.mp4'
        "
        type="video/mp4"
      />
    </video>
    <!-- background image/video for the entire template ends here-->

    <div class="studioverse-content">
      <div class="container">
        <!--role page starts here-->
        <div class="role-wrapper" [ngClass]="{ 'd-none': isFirst || defaultRole}">
          <div class="studioverse-left">
            <div *ngIf="loginDesign?.asset?.desktop" class="logo-sec">
              <figure class="logo nh-mb-24">
                <img
                  [src]="loginDesign?.asset?.desktop"
                  alt=""
                  onerror='this.src="../../../../../assets/images/login-screen/studioverse-logo.png"'
                />
              </figure>
              <!-- <span>StudioVerse</span> -->
            </div>
            <h1
              class="role-title nh-mb-22"
              [ngStyle]="{
                'font-family': loginDesign?.caption?.font,
                color: loginDesign?.caption?.headingFontColor
              }"
            >
              <app-change-detection
                [text]="
                  (loginDesign?.caption?.heading?.split('/n'))[1]
                    ? (loginDesign?.caption?.heading?.split('/n'))[1]
                    : (loginDesign?.caption?.heading?.split('/n'))[0]
                "
              >
              </app-change-detection>
            </h1>
            <p
              class="sub-title nh-fw-400"
              [ngStyle]="{
                'font-family': loginDesign?.caption?.font,
                color: loginDesign?.caption?.textFontColor
              }"
            >
              <app-change-detection
                [text]="
                  (loginDesign?.caption?.text?.split('/n'))[1]
                    ? (loginDesign?.caption?.text?.split('/n'))[1]
                    : (loginDesign?.caption?.text?.split('/n'))[0]
                "
              >
              </app-change-detection>
            </p>
          </div>
          <div class="studioverse-right" [ngClass]="{ 'd-none': isFirst }">
            <div class="studioverse-role text-center">
              <h5
                class="title-role"
                [ngStyle]="{
                  'font-family': loginDesign?.headingText?.font,
                  color: loginDesign?.headingText?.fontColor
                }"
              >
                <app-change-detection [text]="(loginDesign?.headingText?.text.split('/n'))[1]" class="content-text">
                </app-change-detection>
              </h5>
              <!-- <p class="para nh-color-900">Are you a Buyer or Seller</p> -->
              <div class="role-swiper">
                <div class="items swiper-wrapper">
                  <swiper [config]="signupConfig">
                    <ng-container class="item swiper-slide" *ngFor="let role of rolesList">
                      <ng-template swiperSlide>
                        <article
                          class="box"
                          [ngStyle]="{ backgroundColor: rolesDesignData.get(role?.name)?.icon.color }"
                        >
                          <div class="box-img">
                            <img
                              [src]="(rolesDesignData.get(role?.name)?.icon.link)?.['desktop']"
                              onerror="this.src='../../../assets/images/mcc/alternative.svg'"
                            />
                          </div>
                          <h3 class="box-title" (click)="change(role)">
                            <span> {{ role?.name }}</span>
                          </h3>
                        </article>
                      </ng-template>
                    </ng-container>
                  </swiper>
                  <!-- Add Pagination -->
                  <div class="swiper-btn swiper-prev">
                    <i class="bi bi-chevron-left"></i>
                  </div>
                  <div class="swiper-btn swiper-next">
                    <i class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
              <p class="signup-link nh-mt-24 nh-color-899">
                Already a user? <a class="nh-color-200 cursor-pointer" (click)="signin()">Sign In</a>
              </p>
            </div>
          </div>
        </div>
        <!-- signup page starts here-->
        <div class="form-wrapper" [ngClass]="{ 'd-none': !isFirst || !isSubmit }">
          <div class="studioverse-left">
            <div class="logo-sec" *ngIf="loginDesign?.asset?.desktop">
              <figure class="logo nh-mb-24">
                <img
                  [src]="loginDesign?.asset?.desktop"
                  alt=""
                  onerror='this.src="../../../../../assets/images/login-screen/studioverse-logo.png"'
                />
              </figure>
              <!-- <span>StudioVerse</span> -->
            </div>
            <h1
              class="form-title nh-fw-600 nh-mb-22"
              [ngStyle]="{
                'font-family': loginDesign?.caption?.font,
                color: loginDesign?.caption?.headingFontColor
              }"
            >
              <app-change-detection
                [text]="(stepIdx >=0) && (loginDesign['roleDesign']?.[selectedRoleIdx]?.caption?.heading?.split('/n'))[stepIdx]
              ?  (loginDesign['roleDesign']?.[selectedRoleIdx]?.caption?.heading?.split('/n'))[stepIdx]
              : (loginDesign['roleDesign']?.[selectedRoleIdx]?.caption?.heading?.split('/n'))[0]"
                class="content-text"
              >
              </app-change-detection>
            </h1>
            <p
              class="sub-title nh-fw-400"
              [ngStyle]="{
                'font-family': loginDesign?.caption?.font,
                color: loginDesign?.caption?.textFontColor
              }"
            >
              <app-change-detection
                [text]="(stepIdx >=0) && (loginDesign['roleDesign']?.[selectedRoleIdx]?.caption?.text?.split('/n'))[stepIdx]
              ?  (loginDesign['roleDesign']?.[selectedRoleIdx]?.caption?.text?.split('/n'))[stepIdx]
              : (loginDesign['roleDesign']?.[selectedRoleIdx]?.caption?.text?.split('/n'))[0]"
                class="content-text"
              >
              </app-change-detection>
            </p>
          </div>
          <div class="studioverse-right">
            <div class="studioverse-form">
              <mat-stepper linear class="stepper-opt2 stepper" #stepper>
                <mat-step [editable]="isEditable">
                  <form>
                    <h5
                      class="text-center title"
                      [ngStyle]="{
                        'font-family': loginDesign?.headingText?.font,
                        color: loginDesign?.headingText?.fontColor
                      }"
                    >
                      <app-change-detection
                        [text]="(stepIdx >=0) && (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[stepIdx]
                                  ?  (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[stepIdx]
                                  : (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[0]"
                        class="content-text"
                      >
                      </app-change-detection>
                    </h5>
                    <div class="studioverse-input">
                      <ul class="items">
                        <li class="item">
                          <mat-form-field appearance="standard" class="custom-input-mat nh-mb-24">
                            <label class="main-label">{{isSalud?'Alias' :'Name'}}</label>
                            <input
                              matInput
                              type="text"
                              [placeholder]="isSalud ? 'Enter Alias' : 'Enter Name'"
                              required
                              [(ngModel)]="signupPayload.firstName"
                              [ngModelOptions]="{ standalone: true }"
                            />
                          </mat-form-field>
                        </li>
                        <li class="item">
                          <mat-form-field
                            class="custom-matselect"
                            appearance="standard"
                            class="custom-input-mat nh-mb-8"
                          >
                            <label class="main-label">Email</label>
                            <input
                              matInput
                              [(ngModel)]="signupPayload.email"
                              id="email_id"
                              type="text"
                              placeholder="Enter Email"
                              (keyup)="ValidateEmail()"
                              [ngModelOptions]="{ standalone: true }"
                            />
                            <small class="text-danger" [ngClass]="{ 'myclass-2': emailValidator == false }" *ngIf="!emailValidator">
                              Enter a Valid Email
                            </small>
                          </mat-form-field>
                        </li>
                        <li class="item">
                          <!-- <mat-label apperance="outline">country code</mat-label>
                          <mat-form-field appearance="fill" floatLabel="never">
                            <mat-select
                              panelClass="admincustomselect"
                              [(ngModel)]="selectedCountryCode"
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <mat-option *ngFor="let country of countryCodes" [value]="country[1]"
                                >{{ country[0] }} : {{ country[1] }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field> -->
                          <div class="select-country">
                            <mat-label class="action-type">Country Code</mat-label>
                            <mat-select
                              panelClass="admincustomselect"
                              [(ngModel)]="_countryName"
                              [ngModelOptions]="{ standalone: true }"
                              [disabled]="defaultCountryCodeEditable"
                            >
                              <mat-option *ngFor="let country of countryCodes" [value]="country[1].countryCode"
                                >{{ country[1].countryName }} : {{ country[1].countryCode }}</mat-option
                              >
                            </mat-select>
                          </div>
                        </li>
                        <li class="item">
                          <mat-form-field appearance="standard" class="custom-input-mat nh-mb-24">
                            <label class="main-label">Mobile</label>
                            <input
                              matInput
                              id="mobile"
                              type="text"
                              placeholder="Enter Mobile Number"
                              onkeydown="return((event.keyCode >=48 && event.keyCode <=57)||event.keyCode ==8 || event?.code?.startsWith('Numpad'))"
                              [(ngModel)]="signupPayload.mobileNumber"
                              (keypress)="ValidateMobileNumberEmail($event)"
                              (keydown.space)="$event.preventDefault()"
                              name="mobileNumber"
                              [ngModelOptions]="{ standalone: true }"
                              [required]="faEnabled || mobileNumberMandatoryWhileEmailSignUp"
                            />
                            <small [ngClass]="{ 'text-danger': mobileNumberValidatorEmail == false }" *ngIf="!mobileNumberValidatorEmail && signupPayload.mobileNumber">
                              Mobile Number Must be {{ mobileNumberData?.[1]?.mobileNumberLength }} digits
                            </small>
                          </mat-form-field>
                        </li>
                      </ul>
                    </div>
                    <div class="btn-grp">
                      <button
                        [ngStyle]="{
                          background: loginDesign?.actionButton?.buttonColor,
                          color: loginDesign?.actionButton?.fontColor
                        }"
                        class="btn btn-next"
                        (click)="isFirst = false; stepIdx = 0"
                        [disabled]=""
                        matStepperPrevious
                        *ngIf ="!skipRoleSeletionPage"
                      >
                        Back
                      </button>
                      <button
                        [disabled]="
                          signupPayload.email === '' || !emailValidator ||
                          (signupPayload.mobileNumber === '' && mobileNumberMandatoryWhileEmailSignUp)||
                          signupPayload.firstName === ''
                        "
                        [ngStyle]="{
                          background: loginDesign?.actionButton?.buttonColor,
                          color: loginDesign?.actionButton?.fontColor
                        }"
                        class="btn btn-next"
                        (click)="getOTP(); login1Next = true; stepIdx = 1"
                        matStepperNext
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [editable]="isEditable">
                  <form>
                    <h5 class="text-center title">
                      <app-change-detection
                        [text]="(stepIdx >=0) && (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[stepIdx]
                      ?  (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[stepIdx]
                      : 'Register'"
                        class="content-text"
                      >
                      </app-change-detection>
                    </h5>
                    <p class="text-center nh-mb-24">OTP has been sent to {{ signupPayload.email }}</p>
                    <div class="studioverse-input text-center">
                      <p class="nh-mt-40 nh-mb-8 text">Enter OTP</p>
                      <div class="otp-field">
                        <input type="number" maxlength="1" (keyup)="onOtpInputChange(1, $event)" />
                        <input type="number" maxlength="1" (keyup)="onOtpInputChange(2, $event)" />
                        <input type="number" maxlength="1" (keyup)="onOtpInputChange(3, $event)" />
                        <input type="number" maxlength="1" (keyup)="onOtpInputChange(4, $event)" />
                        <input type="number" maxlength="1" (keyup)="onOtpInputChange(5, $event)" />
                        <input type="number" maxlength="1" (keyup)="onOtpInputChange(6, $event)" />
                      </div>
                      <p *ngIf="timeLeft != 0" class="nh-mt-8 text">OTP will expire in {{ timeLeft }} secs</p>
                    </div>
                    <div class="btn-grp">
                      <button
                        [ngStyle]="{
                          background: loginDesign?.actionButton?.buttonColor,
                          color: loginDesign?.actionButton?.fontColor
                        }"
                        class="btn btn-next"
                        (click)="getOTP()"
                      >
                        Resend OTP
                      </button>
                      <button
                        [ngStyle]="{
                          background: loginDesign?.actionButton?.buttonColor,
                          color: loginDesign?.actionButton?.fontColor
                        }"
                        class="btn btn-next"
                        (click)="validate(true)"
                      >
                        Validate OTP
                      </button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [editable]="isEditable">
                  <form>
                    <h5 class="text-center title">
                      <app-change-detection
                        [text]="(stepIdx >=0) && (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[stepIdx]
                      ?  (loginDesign['roleDesign']?.[selectedRoleIdx]?.headingText?.text?.split('/n'))[stepIdx]
                      : 'Register'"
                        class="content-text"
                      >
                      </app-change-detection>
                    </h5>
                    <div class="studioverse-input">
                      <ul class="items">
                        <li class="item">
                          <mat-form-field appearance="standard" class="custom-input-mat">
                            <label class="main-label">Create Password</label>
                            <div class="password-icon">
                              <input
                                matInput
                                type="password"
                                placeholder="Enter Password"
                                [(ngModel)]="signupPayload.password"
                                (keyup)="ValidateConfirmPassword();passwordPolicyRequirementsCheck()"
                                [ngModelOptions]="{ standalone: true }"
                                [type]="showCreatePassword ? 'text' : 'password'"
                              />
                              <mat-icon (click)="toggleShowCreatePassword()" class="eye-icon">
                                {{ showCreatePassword ? 'visibility' : 'visibility_off' }}
                              </mat-icon>
                            </div>
                          </mat-form-field>
                        </li>
                        <li class="item">
                          <mat-form-field appearance="standard" class="custom-input-mat">
                            <label class="main-label">Confirm Password</label>
                            <div class="password-icon">
                              <input
                                matInput
                                type="password"
                                placeholder="Re Enter Password"
                                [(ngModel)]="confirmPassword"
                                (keyup)="ValidateConfirmPassword()"
                                [ngModelOptions]="{ standalone: true }"
                                [type]="showPassword ? 'text' : 'password'"
                              />
                              <mat-icon (click)="toggleShowPassword()" class="eye-icon">
                                {{ showPassword ? 'visibility' : 'visibility_off' }}
                              </mat-icon>
                            </div>
                          </mat-form-field>
                          <div>
                            <small
                              class="text-danger"
                              [ngClass]="{ 'myclass-2': passwordCheck == false }"
                              *ngIf="!passwordCheck"
                            >
                              Password not matched
                            </small>
                            <small
                              class="text-success"
                              [ngClass]="{ 'myclass-1': passwordCheck == true }"
                              *ngIf="passwordCheck && confirmPassword.length"
                            >
                              Password matched
                            </small>
                          </div>
                        </li>
                      </ul>
                      
                      <ul class="password-policy-items d-flex" *ngIf="password_info">
                          <li 
                              *ngIf="password_info?.length > 0" 
                              class="password-policy-item" 
                              [ngClass]="{'pass': isMinLengthMet, 'fail': !isMinLengthMet}">
                              Minimum {{ password_info?.length }} characters in length
                          </li>
                          <li 
                              *ngIf="password_info?.digits> 0" 
                              class="password-policy-item" 
                              [ngClass]="{'pass': isMinDigitsMet, 'fail': !isMinDigitsMet}">
                              At least {{ password_info?.digits }} digit
                          </li>
                          <li 
                              *ngIf="password_info?.upperCase > 0" 
                              class="password-policy-item" 
                              [ngClass]="{'pass': isMinUppercaseMet, 'fail': !isMinUppercaseMet}">
                              At least {{ password_info?.upperCase }} uppercase letter
                          </li>
                          <li 
                              *ngIf="password_info?.specialChars > 0" 
                              class="password-policy-item" 
                              [ngClass]="{'pass': isMinSpecialCharsMet, 'fail': !isMinSpecialCharsMet}">
                              At least {{ password_info?.specialChars }} special character
                          </li>
                      </ul>
                    
                    
                    </div>
                    <div class="btn-grp">
                      <button
                        [ngStyle]="{
                          background: loginDesign?.actionButton?.buttonColor,
                          color: loginDesign?.actionButton?.fontColor
                        }"
                        class="btn btn-next"
                        matStepperNext
                        [disabled]="!passwordCheck || !isMinLengthMet || !isMinDigitsMet || !isMinUppercaseMet || !isMinSpecialCharsMet"
                        (click)="stepIdx = 3"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [editable]="isEditable">
                  <div class="nh-mb-16">
                    <h5 class="text-center title">Terms & Conditions</h5>
                    <iframe
                      *ngIf="termsAndConditions"
                      class="text-center desc"
                      [src]="termsAndConditions | safeResourceUrl"
                      width="100%"
                      height="220px"
                    >
                    </iframe>
                    <div class="term-condition text-center">
                      <mat-checkbox
                        [(ngModel)]="termsAgreed"
                        class="cstm-check nh-mt-32 nh-ml-16"
                        [ngModelOptions]="{ standalone: true }"
                        >I Accept</mat-checkbox
                      >
                    </div>
                  </div>
                  <div class="btn-grp">
                    <button
                      [disabled]="!termsAgreed"
                      [ngStyle]="{
                        background: loginDesign?.actionButton?.buttonColor,
                        color: loginDesign?.actionButton?.fontColor
                      }"
                      class="btn btn-next"
                      matStepperNext
                      (click)="signup()"
                    >
                      Next
                    </button>
                  </div>
                </mat-step>
              </mat-stepper>
              <p class="signup-link nh-mt-24 nh-color-899">
                Already a user? <a class="nh-color-200 cursor-pointer" (click)="signin()">Sign In</a>
              </p>
            </div>
          </div>
        </div>
        <!-- signup page ends here-->

        <!-- confirmation page starts here-->
        <div class="confirmation" [ngClass]="{ 'd-none': isSubmit }">
          <div class="studioverse-left">
            <div class="logo-sec" *ngIf="loginDesign?.asset?.desktop">
              <figure class="logo nh-mb-24">
                <img
                  [src]="loginDesign?.asset?.desktop"
                  alt=""
                  onerror='this.src="../../../../../assets/images/login-screen/studioverse-logo.png"'
                />
              </figure>
              <!-- <span>StudioVerse</span> -->
            </div>
            <h1
              class="role-title nh-mb-22"
              [ngStyle]="{
                'font-family': loginDesign?.caption?.font,
                color: loginDesign?.caption?.headingFontColor
              }"
            >
              <!-- The Stage is <span>All Set</span> <br />
              to Buy Rights -->
              {{ loginDesign?.caption?.heading }}
            </h1>
            <p
              class="sub-title nh-fw-400"
              [ngStyle]="{
                'font-family': loginDesign?.caption?.font,
                color: loginDesign?.caption?.textFontColor
              }"
            >
              {{ loginDesign?.caption?.text }}1
            </p>
          </div>
          <div class="studioverse-right">
            <div class="studioverse-role text-center">
              <figure class="mailbox">
                <img src="../../../../../assets/images/login-screen/mailbox.png" alt="mail-box" />
              </figure>
              <h5
                class="title-role nh-mb-24"
                [ngStyle]="{
                  'font-family': loginDesign?.headingText?.font,
                  color: loginDesign?.headingText?.fontColor
                }"
              >
                {{ loginDesign?.headingText?.text }}
              </h5>
              <p class="confirmation-para nh-color-900">
                Your profile and wallet have been created and details have been sent to your email. This wallet will
                hold all digital assets for this account
              </p>
              <button
                [ngStyle]="{
                  background: loginDesign?.actionButton?.buttonColor,
                  color: loginDesign?.actionButton?.fontColor
                }"
                class="btn btn-next cursor-pointer"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
