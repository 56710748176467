import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Attribute, RuleSetCondition, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rule-set',
  templateUrl: './rule-set.component.html',
  styleUrls: ['./rule-set.component.scss'],
})
export class RuleSetComponent implements OnInit {
  @Input() selectedEntityData: any;
  @Input() hiddenRuleSet: RuleSetCondition = {
    ruleSet: [],
    ruleSetCondition: 'AND',
  };
  operators: string[] = ['==', '>', '<', '!=', '>=', '<='];
  userData: any;
  showUserData: boolean;
  labels: any;
  ngUnsubscribe = new Subject();
  constructor(protected translator: TranslatorService) {}

  ngOnInit(): void {
    this.detectLanguageChange();
  }

  private detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  addRuleSet() {
    this.hiddenRuleSet?.ruleSet.push({
      hiddenConditions: [],
      inConditions: 'AND',
    });
    const len = this.hiddenRuleSet?.ruleSet.length - 1;
    this.hiddenRuleSet?.ruleSet[len].hiddenConditions.push({
      conditionName: '',
      attriubteName: {} as Attribute,
      value: '',
    });
  }

  CheckOrRule() {
    this.hiddenRuleSet.ruleSetCondition = 'OR';
  }

  CheckAndRule() {
    this.hiddenRuleSet.ruleSetCondition = 'AND';
  }

  deleteRuleSet(ruleIndex: number) {
    this.hiddenRuleSet?.ruleSet.splice(ruleIndex, 1);
  }

  addRule(ruleIndex: number) {
    if (!this.hiddenRuleSet?.ruleSet?.[ruleIndex]) {
      this.hiddenRuleSet?.ruleSet.push({
        hiddenConditions: [],
        inConditions: 'AND',
      });
    }
    this.hiddenRuleSet?.ruleSet[ruleIndex].hiddenConditions.push({
      conditionName: '',
      attriubteName: {} as Attribute,
      value: '',
    });
  }

  CheckAnd(ruleIndex: number) {
    this.hiddenRuleSet.ruleSet[ruleIndex].inConditions = 'AND';
  }
  /**
   * The function updates the condition at a specific index in the hiddenRuleSet array to 'OR'.
   * @param {number} i - The parameter "i" in the function "CheckOr" is a number.
   */
  CheckOr(ruleIndex: number) {
    this.hiddenRuleSet.ruleSet[ruleIndex].inConditions = 'OR';
  }

  /**
   * The function deletes a condition from a hidden rule set at a specific index.
   * @param {number} index - The index parameter is a number that represents the position of the
   * condition to be deleted in the hiddenConditions array.
   * *This will delete the Condition from the Array.
   */

  currentUser(event: any) {
    this.showUserData = true;
    this.userData = JSON.parse(localStorage.getItem('userInfo'));
  }

  setUserToCondition(event: any, value: any, userValue: string) {
    value.value = '@' + userValue;
    this.showUserData = false;
  }

  deleteCondition(conditionIndex: number, ruleIndex: number) {
    this.hiddenRuleSet?.ruleSet[ruleIndex].hiddenConditions.splice(conditionIndex, 1);
  }
}
