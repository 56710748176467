import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dcd',
  pure: true,
})
export class DcdPipe implements PipeTransform {
  /* istanbul ignore next */
  transform(item?: any, isMulti?: boolean): string {
    /* istanbul ignore next */
    if (item) {
      //This if block is for multiple values
      if (isMulti) {
        let attrNameArray: any = [];
        /* istanbul ignore next */
        const arr = item?.split(',');
        /* istanbul ignore next */
        for (let i = 0; i < arr?.length; i++) {
          let individualArray = arr[i];
          /* istanbul ignore next */
          let separateName = individualArray?.split('.');
          /* istanbul ignore next */
          attrNameArray?.push(separateName[separateName?.length-1]);
        }
        return attrNameArray;
      }
      //This block is for single values
      else {
        let operators: any = ['!=', '==', '>', '<'];
        const re = /\s*(?:!=|$)|(?:==|$)|(?:<|$)|(?:>|$)\s*/;
        /* istanbul ignore next */
        let conditionArray = item?.split(re);
        /* istanbul ignore next */
        operators?.filter((item: any) => {
          /* istanbul ignore next */
          if (item?.includes(item)) {
            /* istanbul ignore next */
            conditionArray?.splice(1, 0, item);
          }
        });
        if (conditionArray[conditionArray?.length - 1].indexOf('.') == -1) {
          /* istanbul ignore next */
          if (this.isNum(conditionArray[0])) {
            return item;
          } else {
            /* istanbul ignore next */
            let res = item?.split('.');
            /* istanbul ignore next */
            let xo = res[res?.length - 1];
            xo = xo.replace('==', '=');
            return xo;
          }
          /* istanbul ignore next */
        } else if (conditionArray[conditionArray?.length - 1].indexOf('.') !== -1) {
          /* istanbul ignore next */
          let res = item?.split('.');
          /* istanbul ignore next */
          return res[res?.length - 2] + '.' + res[res?.length - 1];
        } else {
          return '';
        }
      }
    }
  }
  isNum(val: any) {
    return !isNaN(val);
  }
}
