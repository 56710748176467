declare var require: any;
let transform = require('node-json-transform').transform;

export function bookListMapper(data: any) {
  var map = {
    /*
        node json mapper default item
        used to map one key to another
        */
    item: {
      content: 'data',
      total: 'totalResults',
    },
    operate: [
      {
        run: function (val: any[]) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return {
              ...x,
              dsdId: x.dsdId,
              id: x.dsdId,
              bookId: x.id,
            };
          });
        },
        on: 'content',
      },
    ],
  };

  const result = transform(data, map);

  return result;
}
