import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService, TransactionFacadeService, AlertService, LoaderService, EventsFacadeService, FieldConfig, UploadFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { RecordingAudioService } from './record-audio.service';

@Component({
  selector: 'app-record-audio',
  styleUrls: ['./record-audio.component.scss'],
  template: `
    <div
      class="notepad-record"
      [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }"
    >
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
          <label
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            class="main-label nh-mb-0"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field.label }} :</span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div class="record-actions" id="audio_actions">
        <button
          class="btn actionbtn"
          id="audio_start_btn"
          *ngIf="!isRecordingEnabled && !audioBlobUrl"
          (click)="startAudioRecording()"
        >
          <!-- <mat-icon>play_circle_outline</mat-icon> -->
          <figure class="audio-icon"><img src="../../../assets/images/ui-controls/icon-audio-recording.svg" /></figure>
        </button>
        <div class="actions-bts audio-entered" id="audio_actions_btn" *ngIf="isRecordingEnabled">
          <button class="btn actionbtn" id="audio_ajust_btn" *ngIf="isRecordingEnabled" (click)="stopAudioRecording()">
            <!-- <mat-icon>adjust</mat-icon> -->
            <figure class="w-auto"><img src="../../../assets/images/ui-controls/icon-audio-play.svg" /></figure>
            <figure class="w-auto"><img src="../../../assets/images/ui-controls/icon-audio-playing.svg" /></figure>
          </button>
          <!-- <button class="btn actionbtn"><mat-icon>pause_circle_outline</mat-icon></button> -->

          <div class="record-time" *ngIf="isRecordingEnabled && !audioBlobUrl" id="audio_reord_time">
            <span class="record-time-span">{{ audioRecordedTime }} sec</span>
          </div>
          <div *ngIf="timeLimit">
            <span class="timeLimit">Time Limit : {{ timeLimit }}</span>
          </div>
        </div>
      </div>
      <div class="uploaded-audio">
        <audio class="w-100" id="audioBlob" *ngIf="!isRecordingEnabled && audioBlobUrl" controls>
          <source [src]="audioBlobUrl" type="audio/mp3" />
        </audio>

        <button
          class="btn actionbtn"
          id="audio_clear_btn"
          *ngIf="!isRecordingEnabled && audioBlobUrl"
          (click)="clearAudioRecordedData()"
        >
          <!-- <mat-icon>highlight_off</mat-icon> -->
          <figure class="w-auto"><img src="../../../assets/images/ui-controls/icon-trash.svg" /></figure>
        </button>
        <div class="record-title" id="audio_record-title" *ngIf="!isRecordingEnabled && audioBlobUrl">
          <div class="record-btns" id="audio_save_div">
            <button class="btn btnsave" id="audio_save" (click)="saveAudio()">{{ labels?.Save }}</button>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class RecordAudioComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  field: FieldConfig;
  group: FormGroup;
  labels: any;
  iscarnivalscreen: boolean = false;

  //variables for audio recording
  isRecordingEnabled: boolean = false;
  audioBlobUrl: any;
  audioRecordedTime: string;
  audioToSave: FormData;
  urlOfAudioFile: string = '';
  htmlOfAudioFile: string = '';
  timeLimit: string = '';

  constructor(
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private transacationFacadeService: TransactionFacadeService,
    private sanitizer: DomSanitizer,
    private recordingAudioService: RecordingAudioService,
    private alertService: AlertService,
    private loader: LoaderService,
    private uploadFacadeService: UploadFacadeService
  ) {
    this.detectLanguageChange();
    this.detectBlobFileForRecording();
    this.detectRecordingTime();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      this.transacationFacadeService.disableSubmitButtonRecordAudio(true);
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.hours) {
      this.timeLimit = this.timeLimit + ` ${this.field?.configuration?.hours} H :`;
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.minutes) {
      this.timeLimit = this.timeLimit + ` ${this.field?.configuration?.minutes} M :`;
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.seconds) {
      this.timeLimit = this.timeLimit + ` ${this.field?.configuration?.seconds} S`;
    }
    if (this.field?.value) {
      this.audioBlobUrl = JSON.parse(this.field.value)?.contentUrl;
    }
  }

  startAudioRecording() {
    this.recordingAudioService.startRecording();
    this.isRecordingEnabled = true;
  }

  stopAudioRecording() {
    this.recordingAudioService.stopRecording();
    this.isRecordingEnabled = false;
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = null;
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      this.transacationFacadeService.disableSubmitButtonRecordAudio(true);
    }
  }

  detectBlobFileForRecording() {
    this.recordingAudioService
      .getRecordedBlob()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        /* istanbul ignore else */
        if (data) {
          this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
          let formData = new FormData();
          let file = new File([data], 'test');
          formData.append('file', file);
          this.audioToSave = formData;
        }
      });
  }

  detectRecordingTime() {
    this.recordingAudioService.recordingAudioTime$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      /* istanbul ignore next */
      if (res) {
        this.audioRecordedTime = res?.time;
        /* istanbul ignore next */
        if (
          res?.minutes == this.field?.configuration?.minutes &&
          res?.hours == this.field?.configuration?.hours &&
          res?.seconds == this.field?.configuration?.seconds + 1
        ) {
          this.stopAudioRecording();
        }
      }
    });
  }
  saveAudio() {
    /* istanbul ignore next */
    if (this.audioToSave) {
      this.saveFiles(this.audioToSave, 1);
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  saveFiles(formDataFAQ: any, fileLength?: any) {
    this.loader.show();
    this.uploadFacadeService
      .dynamicFormUploadFiles(formDataFAQ, fileLength)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (data) {
            if (Array.isArray(this.field.value)) {
              /* istanbul ignore next */
              this.field.value[0] = JSON?.stringify(data);
            } else {
              /* istanbul ignore next */
              this.field.value = JSON?.stringify(data);
            }
            this.group?.controls[this.field?.attribute?.name]?.setValue(JSON?.stringify(data));
            this.transacationFacadeService.disableSubmitButtonRecordAudio(false);
          }
        },
        /* istanbul ignore next */
        (err: any) => {
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
