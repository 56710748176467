import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { TransactionFacadeService, FieldConfig, FileUploadPreviewComponent } from '@common-services';


@Component({
  selector: 'app-html',
  styleUrls: ['./html.component.scss'],
  template: `
    <div class="example-form">
      <div class="d-flex align-items-center nh-mb-8">
        <label
          class="main-label nh-mb-0"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          >
        </label>
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div class="d-flex">
        <!-- <a class="d-flex" (click)="onPreviewClick()">
          <img src="../../../assets/img/ui-kits/icon-html.svg" />
        </a> -->

        <div
          *ngIf="
            field?.type == 'html' && !(field?.configuration?.showPopup || field?.attributeType?.properties?.showPopup)
          "
        >
          <a class="d-flex" (click)="onPreviewClick()" style="font-style:italic;color:blue;font-weight:400">
            View
          </a>
        </div>
        <ng-template #openPopup>
          <div mat-dialog-title class="d-flex flex-row-reverse">
            <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
          </div>
          <div mat-dialog-content>
            <div
              class="htmlPopup"
              [innerHTML]="
                field.value
              "
            ></div>
          </div>
        </ng-template>

        <div *ngIf="field.type == 'payment'" class="payment-block">
          <figure class="payment-bg-color">
            <!-- <svg
              class="actual-payment-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <g id="Group_117082" data-name="Group 117082" transform="translate(-866.605 -389.605)">
                <rect
                  id="Rectangle_39880"
                  data-name="Rectangle 39880"
                  width="32"
                  height="32"
                  transform="translate(866.605 389.605)"
                  fill="#f2f2f2"
                  opacity="0"
                />
                <g id="Group_117180" data-name="Group 117180" transform="translate(863.402 384.569)">
                  <g id="Group_107481" data-name="Group 107481" transform="translate(7.648 9.835)">
                    <g id="Group_107483" data-name="Group 107483">
                      <path
                        id="Path_105862"
                        data-name="Path 105862"
                        d="M24.129,19.367v4.046a1.139,1.139,0,0,1-1.138,1.138H2.229a1.139,1.139,0,0,1-1.138-1.138V8.486A1.139,1.139,0,0,1,2.229,7.348H22.991a1.139,1.139,0,0,1,1.138,1.138v4.175"
                        transform="translate(-1.091 -2.149)"
                        fill="none"
                        stroke="#ff657d"
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_105863"
                        data-name="Path 105863"
                        d="M24.223,18.685H14.848a2.416,2.416,0,0,1-2.416-2.416V14.261a2.245,2.245,0,0,1,2.244-2.244h9.547a.41.41,0,0,1,.411.41v5.848A.411.411,0,0,1,24.223,18.685Z"
                        transform="translate(0.476 -1.504)"
                        fill="none"
                        stroke="#ff657d"
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_105864"
                        data-name="Path 105864"
                        d="M2.194,7.906,18.5,2.886s2.09-.659,2.09,1.319v3.7"
                        transform="translate(-0.939 -2.78)"
                        fill="none"
                        stroke="#ff657d"
                        stroke-width="1.5"
                      />
                      <circle
                        id="Ellipse_16834"
                        data-name="Ellipse 16834"
                        cx="1.216"
                        cy="1.216"
                        r="1.216"
                        transform="translate(14.954 12.594)"
                        fill="none"
                        stroke="#ff657d"
                        stroke-width="1.5"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg> -->
            <img src="../../../assets/images/ui-controls/icon-payment.png" />
          </figure>
        </div>
      </div>
      <div
        class="tbl-width html-frame"
        id="{{ this.field?.attribute?.name + '_' + this.field?.entityName }}"
        [innerHTML]="
          field.value
        "
      ></div>
    </div>
  `,
})
export class HtmlComponent implements OnInit {
  @ViewChild('openPopup') openPopupRef: TemplateRef<any>;
  field: FieldConfig;
  group: FormGroup;
  checked: boolean;
  src: any;
  canDownload: boolean = false;
  constructor(
    private _sanitizer: DomSanitizer,
    private dialogService: MatDialog,
    private http: HttpClient,
    private transactionFacadeService: TransactionFacadeService
  ) { }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  changed() { }

  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field?.canDownload) {
      this.canDownload = this.field?.canDownload;
    }
    if (this.field.value && this.field.value !== '' && this.IsJsonString(this.field.value)) {
      let jsonObj: any = JSON.parse(this.field.value);
      /* istanbul ignore next */
      this.src = {
        mimeType: jsonObj?.mimeType,
        contentUrl: jsonObj?.contentUrl,
        canDownload: this.canDownload,
      };
    } else {
      /* istanbul ignore next */
      this.field.value = this._sanitizer.bypassSecurityTrustHtml(this.field.value);
      this.src = {
        mimeType: 'application/html',
        contentUrl: this.field.value,
        canDownload: this.canDownload,
      };
    }
    /* istanbul ignore next */
    if (this.field?.configuration.autoRedirect) {
      this.transactionFacadeService.redirectWithoutErrorPopup.next(true);
      setTimeout(() => {
        /* istanbul ignore next */
        let htmlElement = document.getElementById(this.field?.attribute?.name + '_' + this.field.entityName);
        let hyperlinkElement = htmlElement?.getElementsByTagName('a');
        if (htmlElement && hyperlinkElement) {
          hyperlinkElement[0]?.click();
        }
      }, 500);
    }
  }

  downloadFile() {
    this.transactionFacadeService.downloadFile(this?.src?.contentUrl);
  }

  onPreviewClick() {
    // this.dialogService.open(FileUploadPreviewComponent, {
    //   data: this.src,
    //   panelClass: 'fileuploadpreviews',
    // });
    /* istanbul ignore next */
    this.dialogService.open(this.openPopupRef, {
      data: this.src,
    });
  }
  closeDialog() {
    this.dialogService.closeAll();
  }
}
