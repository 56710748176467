<ng-template #templateHeader>
  <div class="entity-card-header">
    <div
      class="btn-corner"
      *ngIf="listValues.length > 1 && !settings?.customizeCardconfigData?.onSelectGsiConfig?.onSelectGsi"
      (click)="deleteCard($event)"
    >
      <!-- <div class="delete-images"><i class="bi bi-trash3"></i></div> -->
    </div>

    <div
      class="btn-corner"
      *ngIf="listValues.length > 1"
      (click)="onSelectDelete(settings?.customizeCardconfigData?.onSelectGsiConfig?.selectedOnSelectGsi)"
    >
      <div class="delete-images" *ngIf="settings?.customizeCardconfigData?.onSelectGsiConfig?.onSelectGsi">
        <i class="bi bi-trash3"></i>
      </div>
    </div>

    <div class="btn-corner" [ngClass]="dCard.isChecked ? 'showbtncheck' : ''">
      <div class="uikit-radio" *ngIf="settings?.selectionType == 'radiobutton'">
        <label class="radio">
          <input
            type="radio"
            [(checked)]="dCard.isChecked"
            [(value)]="dCard.isChecked"
            (change)="selectedCards($event, 'radiobutton')"
          />
          <div class="box"></div>
        </label>
      </div>
      <div class="uikit-radio option3" *ngIf="settings?.selectionType == 'checkbox'">
        <label class="radio">
          <input [(ngModel)]="dCard.isChecked" type="checkbox" (change)="selectedCards($event)" />
          <div class="box" (click)="selectedCards($event)"></div>
        </label>
      </div>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="!showExpand">
  <div class="ecommercewireframes ecommercewireframes-bg" (click)="openExpand($event)">
    <div class="entitytemplates row">
      <div class="col-12 Attribut-4" *ngIf="templateType == '54'">
        <div class="e-commerce-child-card-1 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-6" *ngIf="templateType == '55'">
        <div class="e-commerce-child-card-2 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-mrp">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '56'">
        <div class="e-commerce-child-card-3 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '57'">
        <div class="e-commerce-child-card-4 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '58'">
        <div class="e-commerce-child-card-5 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '59'">
        <div class="e-commerce-child-card-6 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small col-6">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '60'">
        <div class="e-commerce-child-card-7 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small col-6">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '61'">
        <div class="e-commerce-child-card-8 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small col-6">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '62'">
        <div class="e-commerce-child-card-9 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <span class="card-subtitle1 col-6"
                  ><ng-container
                    *ngIf="attributes[2]"
                    dynamicField
                    [formData]="attributes[2][attributes[2].attributeName]"
                    [group]="attributes[2][attributes[2].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
                <span class="card-subtitle2 col-5"
                  ><ng-container
                    *ngIf="attributes[3]"
                    dynamicField
                    [formData]="attributes[3][attributes[3].attributeName]"
                    [group]="attributes[3][attributes[3].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
              </li>
              <li class="card-subtitle">
                <span class="card-subtitle-small col-5"
                  ><ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '63'">
        <div class="e-commerce-child-card-10 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <span class="card-subtitle1 col-5"
                ><ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </span>
              <span class="card-subtitle2 col-6"
                ><ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </span>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '64'">
        <div class="e-commerce-child-card-11 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-6" *ngIf="templateType == '65'">
        <div class="e-commerce-child-card-12 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="special-title">
                <ng-container
                  *ngIf="attributes[0]"
                  dynamicField
                  [formData]="attributes[0][attributes[0].attributeName]"
                  [group]="attributes[0][attributes[0].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-logo">
                <img *ngIf="contentUrl[2]" [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-caption">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-mrp">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '66'">
        <div class="e-commerce-child-card-13 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '67'">
        <div class="e-commerce-child-card-14 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '68'">
        <div class="e-commerce-child-card-15 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '69'">
        <div class="e-commerce-child-card-16 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small col-6">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-4" *ngIf="templateType == '70'">
        <div class="e-commerce-child-card-17 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small col-6">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="show-more" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '71'">
        <div class="e-commerce-child-card-18 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle-small col-6">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '72'">
        <div class="e-commerce-child-card-19 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <div>
            <ul>
              <li class="card-logo">
                <img *ngIf="contentUrl[1]" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <ul class="Child-body">
              <li class="card-title">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <span class="card-subtitle1 col-6"
                  ><ng-container
                    *ngIf="attributes[2]"
                    dynamicField
                    [formData]="attributes[2][attributes[2].attributeName]"
                    [group]="attributes[2][attributes[2].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
                <span class="card-subtitle2 col-5"
                  ><ng-container
                    *ngIf="attributes[3]"
                    dynamicField
                    [formData]="attributes[3][attributes[3].attributeName]"
                    [group]="attributes[3][attributes[3].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
              </li>
              <li class="card-subtitle">
                <span class="card-subtitle-small col-8"
                  ><ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
                <div class="btn-cta col-4"></div>
              </li>
              <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
                Show more
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 Attribut-5" *ngIf="templateType == '73'">
        <div class="e-commerce-child-card-20 e-commerce-child" [ngClass]="{ 'card-checked': dCard.isChecked }">
          <ng-template [ngTemplateOutlet]="templateHeader"></ng-template>
          <ul class="Child-body">
            <li class="card-logo">
              <ng-container
                *ngIf="attributes[0]"
                dynamicField
                [formData]="attributes[0][attributes[0].attributeName]"
                [group]="attributes[0][attributes[0].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[0] && checkEventGSI(attributes[0], 0)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[0]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[0][attributes[0].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-title">
              <ng-container
                *ngIf="attributes[1]"
                dynamicField
                [formData]="attributes[1][attributes[1].attributeName]"
                [group]="attributes[1][attributes[1].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-caption">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li class="card-subtitle">
              <span class="card-subtitle1 col-5"
                ><ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div></span
              >
              <span class="card-subtitle2 col-6"
                ><ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div></span
              >
            </li>
          </ul>
          <div class="show-more" *ngIf="hasExpand" (click)="openExpand($event)">
            Show more
          </div>
        </div>
      </div> 
    </div>
  </div>
</ng-container>

<app-dynamic-ui-card-ecommerce-expand-templates
  *ngIf="showExpand && currentIndex == expandIndex"
  [attributes]="attributes_expand"
  (closeExpandEmitter)="closeExpand($event)"
  [expandType]="expandType"
  [eventCUList]="eventCUList"
></app-dynamic-ui-card-ecommerce-expand-templates>
