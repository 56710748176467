export const fonts = [
  {
    type: 'Sans Serif',
    fonts: [
      {
        name: 'Albert Sans',
        apiName: 'albert-sans',
        fontName: "'Albert Sans', sans-serif",
      },
      {
        name: 'Arial',
        apiName: 'arial',
        fontName: 'Arial, Helvetica, sans-serif',
      },
      {
        name: 'Assistant',
        apiName: 'assistant',
        fontName: "'Assistant', sans-serif",
      },
      {
        name: 'Exo',
        apiName: 'exo',
        fontName: "'Exo', sans-serif",
      },
      {
        name: 'Heebo',
        apiName: 'heebo',
        fontName: "'Heebo', sans-serif",
      },
      {
        name: 'Inter',
        apiName: 'Inter',
        fontName: "'Inter', sans-serif",
      },
      {
        name: 'Josefin Sans',
        apiName: 'jsoefin-sans',
        fontName: "'Josefin Sans', sans-serif",
      },
      {
        name: 'Montserrat',
        apiName: 'montserrat',
        fontName: "'Montserrat', sans-serif",
      },
      {
        name: 'Oswald',
        apiName: 'oswald',
        fontName: "'Oswald', sans-serif",
      },
      {
        name: 'Overpass',
        apiName: 'overpass',
        fontName: "'Overpass', sans-serif",
      },
      {
        name: 'Quicksand',
        apiName: 'quicksand',
        fontName: "'Quicksand', sans-serif",
      },
      {
        name: 'Raleway',
        apiName: 'raleway',
        fontName: "'Raleway', sans-serif",
      },
      {
        name: 'Roboto',
        apiName: 'roboto-regular',
        fontName:
          "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      },
      {
        name: 'Tahoma',
        apiName: 'tahoma',
        fontName: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      },
      {
        name: 'Trebuchet MS',
        apiName: 'trebuchet-ms',
        fontName: "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
      },
      {
        name: 'Verdana',
        apiName: 'verdana',
        fontName: 'Verdana, Geneva, Tahoma, sans-serif',
      },
    ],
  },
  {
    type: 'Serif',
    fonts: [
      {
        name: 'Bitter',
        apiName: 'bitter',
        fontName: "'Bitter', serif",
      },
      {
        name: 'EB Garamond',
        apiName: 'eb-garamond',
        fontName: "'EB Garamond', serif",
      },
      {
        name: 'Garamond',
        apiName: 'garamond',
        fontName: 'Garamond',
      },
      {
        name: 'Georgia',
        apiName: 'georgia',
        fontName: "Georgia, 'Times New Roman', Times, serif",
      },
      {
        name: 'Josefin Slab',
        apiName: 'josefin-slab',
        fontName: "'Josefin Slab', serif",
      },
      {
        name: 'Lora',
        apiName: 'lora',
        fontName: "'Lora', serif",
      },
      {
        name: 'Playfair Display',
        apiName: 'playfair-display',
        fontName: "'Playfair Display', serif",
      },
      {
        name: 'Roboto Slab',
        apiName: 'roboto-slab',
        fontName: "'Roboto Slab', serif",
      },
      {
        name: 'Times New Roman',
        apiName: 'times-new-roman',
        fontName: "'Times New Roman', Times, serif",
      },
    ],
  },
  {
    type: 'Monospace',
    fonts: [
      {
        name: 'Courier New',
        apiName: 'courier-new',
        fontName: "'Courier New', Courier, monospace",
      },
      {
        name: 'Inconsolata',
        apiName: 'inconsolata',
        fontName: "'Inconsolata', Inconsolata, monospace",
      },
      {
        name: 'Overpass Mono',
        apiName: 'overpass-mono',
        fontName: "'Overpass Mono', monospace",
      },
      {
        name: 'Roboto Mono',
        apiName: 'roboto-mono',
        fontName: "'Roboto Mono', monospace",
      },
      {
        name: 'Source Code Pro',
        apiName: 'source-code-pro',
        fontName: "'Source Code Pro', monospace",
      },
      {
        name: 'Spline Sans Mono',
        apiName: 'spline-sans-mono',
        fontName: "'Spline Sans Mono', monospace",
      },
    ],
  },
  {
    type: 'Script',
    fonts: [
      {
        name: 'Brush Script MT ',
        apiName: 'brush-script-MT',
        fontName: "'Brush Script MT', cursive",
      },
    ],
  },
  {
    type: 'Default',
    fonts: [
      {
        name: 'Default ',
        apiName: 'Inter',
        fontName: "'Inter', sans-serif",
      },
    ],
  },
];
