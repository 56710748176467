<div class="add-lang-wrapper">
  <div class="close-library">
    <h5 class="lang-temp-title">Add Language Template</h5>
    <button class="lang-temp-save-btn" id="saveLanguageTemplate" (click)="saveTemplateCCD()">{{ labels.Save }}</button>
  </div>
  <div *ngFor="let transEntity of transEntityRecords; let i = index">
    <mat-form-field class="add-lang-temp-dropdown" *ngIf="languages?.length">
      <mat-label class="add-lang-temp-dropdown-label">{{ labels?.Select_Language }}</mat-label>
      <mat-select
        panelClass="addlang-temp-dropdown-popup"
        id="SelectLanguageDropDown_{{ i }}"
        [(ngModel)]="transEntity.txnNslAttribute[0].values[0]"
      >
        <mat-option
          *ngFor="let language of languages; let i = index"
          [value]="language.languageCode"
          id="{{ language?.languageName }}"
        >
          {{ language.languageName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="upload-lang-temp-wrapper">
      <h5 class="upload-lang-temp-title">Upload Language Template</h5>
      <app-file-upload
        (fileUploadResponse)="getUploadedTemplate($event, i)"
        [field]="{value:transEntity?.txnNslAttribute?.[1]?.values,isRequired:true}"
        [editFileUrl]="transEntity?.txnNslAttribute?.[1]?.values"
      >
      </app-file-upload>
    </div>
    <div class="upload-lang-btns">
      <button class="upload-lang-btns-deletebtn" id="deleteTemplate_{{ i }}" (click)="deleteTemplate(i)">
        {{ labels?.Delete }}
      </button>
      <button class="upload-lang-btns-addmore" id="addMoreTemplate" (click)="addRecord()">
        <i class="bi bi-plus-circle"></i>
        {{ labels?.Add_More }}
      </button>
    </div>
  </div>
</div>
