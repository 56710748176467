import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EventsFacadeService, FieldConfig, TranslatorService, getUiControlCustomization } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { formatDate } from '@angular/common';
import { TimePickerComponent } from 'ngx-datetime-picker';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-time',
  styleUrls: ['./time.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: './time.component.html',
  styles: [
    `
      .timedisplay {
        width: 100%;
        margin-top: 15px;
      }
    `,
  ],
})
export class TimeComponent implements OnInit, AfterViewInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  requiredFormat: string = '24-hr';
  format: string = 'HH:mm:ss';
  meridiem: string = '';
  autoOff = 'on';
  @ViewChild('timePickerInput') timePicker: TimePickerComponent;
  component: any;
  isDisabled: boolean = true;
  labels: any;
  foundObject: any;
  appliedClass: string = 'ui-custom-calendar ui-custom-time';
  bodyAppend: string = 'body';
  tempValue: any = null;
  private ngUnsubscribe = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private rendrer: Renderer2,
    private dateAdapter: DateAdapter<any>,
    private translator: TranslatorService
  ) { }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.autoOff = this.field?.readonly ? 'off' : 'on';
    this.createForm();
    /* istanbul ignore next */
    if (this.field?.configuration?.dateTimeFormat?.timeFormat) {
      /* istanbul ignore next */
      this.requiredFormat = this.field.configuration.dateTimeFormat.timeFormat;
    } else if (this.field?.attributeType?.properties?.timeFormat) {
      /* istanbul ignore next */
      this.requiredFormat = this.field.attributeType.properties.timeFormat;
    }
    /* istanbul ignore next */
    if (!this.field?.value) {
      this.field.value = null;
      /* istanbul ignore next */
      this.group.controls[this.field?.attribute?.name].setValue(null);
    }
    /* istanbul ignore next */
    if (this.field?.value) {
      // this.field.value = new Date(this.field.value);
      this.updateTimeFormatWithOutOnChange(this.field.value);
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.submitCU) {
      /* istanbul ignore next */
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    let dateOption = getUiControlCustomization('DatePicker');
    if (dateOption) {
      this.foundObject = { event: dateOption };
      let op = dateOption.slice(-1);
      if (dateOption == 'Option 7') {
        op = 1;
      }
      this.appliedClass = `form-custom-date form-custom-date-opt${op}`;
    }
    let mccPopup = sessionStorage.getItem('template');
    this.bodyAppend = mccPopup?.includes('cupopup')
      ? (this.field.attribute['isTableConfig'] ? 'body' : '')
      : !this.foundObject?.event || this.foundObject?.event == ''
      ? 'body'
      : '';
  }

  ngAfterViewInit(): void {
    if (this.timePicker?.isMobile) {
      this.isDisabled = false;
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.languageChange();
    });
  }
  languageChange() {
    this.dateAdapter.setLocale(localStorage.locale);
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  onBlur(event?: any) {
    if (this.field.value != this.tempValue) {
      this.updateTimeFormat(this.field.value);
      this.doneClicked();
    }
  }

  updateTimeFormat(event: any) {
    this.tempValue = this.field.value;
    /* istanbul ignore next */
    this.field.value = event;
    /* istanbul ignore next */
    this.group.controls[this.field?.attribute?.name].setValue(event);
  }

  updateTimeFormatWithOutOnChange(event: any) {
    /* istanbul ignore next */
    if (event.length == 24) {
      event = formatDate(event, 'HH:mm:ss', 'en-US');
    }
    if (event.length == 8 || event.length == 18) {
      const date = new Date();
      const time = event.split(':');
      date.setHours(time[0], time[1], time[2]);
      event = date;
    }
    this.field.value = event;
    this.tempValue = this.field.value;
    /* istanbul ignore next */
    this.group.controls[this.field?.attribute?.name].setValue(this.field.value);
  }

  nowClicked() {
    this.updateTimeFormat(new Date());
    this.doneClicked();
  }

  doneClicked() {
    this.onChange();
  }

  createForm() {
    let control = new FormControl();
    this.group.addControl(this.field.attribute.name, control);
  }

  onChange() {
    if (!this.field.isHidden) {
      const data = {
        attributeId: this.field.attribute['id'],
        isTableConfig: this.field.attribute['isTableConfig'],
        attrName: this.field.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
