import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// import { Logger } from '@core';

import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertService, AuthenticationService, CredentialsService, LoaderService, TranslatorService, UsersFacadeService } from '@common-services';

// const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  private unsubscribe$: Subject<any> = new Subject<any>();
  labels: any;
  private restrictedMCCPath: String[] = [
    'preferences',
    'admin',
    'node',
    'paragraph',
    'newsolution',
    'newlayoutsolution',
  ];
  private otherRestricedPath: String[] = ['node', 'paragraph', 'newsolution', 'newlayoutsolution'];
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private alertService: AlertService,
    private loader: LoaderService,
    private authenticationService: AuthenticationService,
    private translator: TranslatorService,
    private userFacade: UsersFacadeService,
  ) {
    this.detectLanguageChange();
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /* istanbul ignore next */
    if (
      sessionStorage.getItem('mccCurrentUrl') &&
      sessionStorage.getItem('mccCurrentUrl').includes('siteLayoutId') &&
      localStorage.getItem('environmentType') !== 'development'
    ) {
      const mccUrl = sessionStorage.getItem('mccCurrentUrl');
      if (this.router.url === '/' && this.restrictedMCCPath.includes(route.routeConfig.path)) {
        this.router.navigateByUrl(mccUrl);
        return;
      }
    }
    if (localStorage.getItem('environmentType') !== 'development') {
      if (this.router.url === '/' && this.otherRestricedPath.includes(route.routeConfig.path)) {
        this.router.navigate(['/']);
        return;
      }
    }

    if (this.credentialsService.isAuthenticated()) {
      let credentials = JSON.parse(localStorage.getItem('credentials'));
      if (
        credentials &&
        credentials?.username?.startsWith('nslguest-') &&
        localStorage.getItem('initiatedNonGuest') == 'true'
      ) {
        this.credentialsService.setCredentials();
      } else {
        return this.isValidRoute(state?.url);
      }
    }

    if (!this.credentialsService.isAuthenticated() && state.url.includes('/browse')) {
      return await this.createGuestUser(route, state);
    } else {
      this.redirectToLogin(route, state);
    }
  }

  redirectToLogin(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // this.alertService.showToaster('Unauthorised action. Login to continue', '', 'error');
    this.loader.hide();
    /* istanbul ignore next */
    const isCarnival = route?.data?.isCarnival;
    if (isCarnival) {
      this.router.navigate([`/carnival-login`]);
    } else {
      /* istanbul ignore next */
      this.router.navigate(['/login'], { queryParams: { redirect: state?.url }, replaceUrl: true });
    }
    return false;
  }

  /**
   * Determines whether b2b2c valid route or not
   * @param url
   * @returns
   */
   isValidRoute(url: string) {
    /* istanbul ignore else */
    if (url.indexOf('b2b2c') !== -1) {
      this.authenticationService.setB2B2Cstate(true);
    }
    /* istanbul ignore next */
    if (url?.indexOf('brfLogin') !== -1) {
      this.authenticationService.setBrfLoginState(true);
    }
    /* istanbul ignore next */
    if (
      (this.authenticationService.getB2B2CState() || this.authenticationService.getBrfLoginState()) &&
      url.indexOf('transaction') !== -1
    ) {
      return true;
    } else if (!this.authenticationService.getB2B2CState() || !this.authenticationService?.getBrfLoginState()) {
      return true;
    } else {
      this.alertService.showToaster(
        this.labels.Authorization_error_ensure_that_you_have_access_to_this_feature,
        this.labels.oops,
        'error'
      );
      return false;
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  async createGuestUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let tenantName = localStorage.getItem('TenantName');
    let clientId = localStorage.getItem('envClientId');
    /*istanbul ignore else */
    if (!localStorage.userInfo && localStorage?.canCreateGuestUsers) {
      let guestExecution = await this.authenticationService
        .createGuestUser(clientId)
        .pipe(
          switchMap((res: any) => {
            return this.authenticationService
              ?.login(
                {
                  userName: res.result.email,
                  encryptedPassword:
                    'WcdciDGpIfhGsyaitBGiKv81vbHa3ORB/GQvrXRPEnDA6AxQXSa1RH89XfsCwpxeZDFaIsYSyReKNhCg95ZegYe+2n3Ja5O4yyiUEOpLOr1YaSVS3grMqpvYn/5ZDKCpYsyTDTq7FqQghdaxWcclW91YAYK31bfXvczknU5XTno=',
                  tenantName: tenantName,
                  clientId: localStorage.getItem('envClientId'),
                },
                true
              )
              .pipe(
                map((response: any) => {
                  if (response?.result) {
                    this.userFacade.getUserProfile();
                    this.translator.getStaticLabels('EN');
                    this.userFacade.loadActiveUsers('login');
                    sessionStorage.setItem('TenantId', tenantName);
                    localStorage.setItem('ActiveUserFlag', 'user');
                    this.userFacade.checkToken(true);
                    localStorage.setItem('isGuestUser', 'true');
                    return true;
                  }
                })
              );
          })
        )
        .toPromise();
      return !!guestExecution;
    } else {
      this.redirectToLogin(route, state);
    }
  }
}
