export enum ChangeUnitTypes {
  Alternate = 'alternate',
  Reserved = 'reserved',
  Exception = 'exception',
  Nested = 'nested',
  Embedded = 'embeded',
  Basic = 'BASIC',
  GSI = 'GSI',
  Parallel = 'Parallel',
}
