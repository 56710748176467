import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FolderuiComponent } from '../folderui/folderui.component';
import { EventsFacadeService, FieldConfig } from '@common-services';

@Component({
  selector: 'app-filewith-irdr',
  templateUrl: './filewith-irdr.component.html',
  styleUrls: ['./filewith-irdr.component.scss'],
})
export class FileWithIRDRComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(private dialog: MatDialog, private eventService: EventsFacadeService) {}

  ngOnInit(): void {}
  openFolderComponent() {
    this.eventService.getFolders('');
    this.dialog
      .open(FolderuiComponent, {
        width: '600px',
        height: '500px',
        data: {
          data: { field: this.field, group: this.group },
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        /* istanbul ignore next */
        this.field.value = result?.value;
        this.group?.controls?.[this.field?.attribute?.name]?.setValue(result?.value);
      });
  }
}
