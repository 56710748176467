import { Injector, Input, Directive, Injectable } from '@angular/core';
import { ChangeUnit, Gsi } from '@common-services';
import { Subject } from 'rxjs';
import { NodeChangeUnitFacadeService } from '../node-services/node-change-unit-facade.service';

@Directive()
export abstract class NslBaseComponent {
  ngUnsubscribe = new Subject();
  @Input() _gsiList: Gsi[] = [];
  originalData: ChangeUnit[] = [];
  modifiedData: ChangeUnit[] = [];
  modifiedDataAlternate: ChangeUnit[] = [];
  cuIndexSet: Set<number> = new Set<number>();

  palChangeUnitList: any;
  palCUDisplayList: any;
  palOriginalData: any[] = [];
  private baseChangeunitFacade: NodeChangeUnitFacadeService;

  constructor(baseInjector: Injector) {
    this.baseChangeunitFacade = baseInjector.get(NodeChangeUnitFacadeService);
  }

  loadChildren(selectedVal: number) {
    const returnData: any = [];
    // this.originalData.forEach((m: ChangeUnit) => (m.isEditable = m.isEditable ? m.isEditable : false));
    this.originalData.forEach((m: ChangeUnit) => (m.isEditable = false));
    const index = this.originalData?.find((m: any) => m.index === selectedVal);
    if (index) {
      this.findFirstNotSelectedCU(index);
    }

    if (index) {
      returnData.push(index);
      if (index.nextTriggerSet.length > 0) {
        index.nextTriggerSet.forEach((nextCU: ChangeUnit) => {
          if (nextCU && this.cuIndexSet.has(nextCU.index)) {
            let pushCu = this.originalData?.find((n: any) => n.index === nextCU.index);
            this.cuIndexSet.delete(nextCU.index);
            returnData.push(pushCu);
            return returnData;
          } else {
            if (nextCU) {
              if (nextCU?.isSelected && !nextCU?.isParallel) {
                this.cuIndexSet.add(nextCU.index);
                returnData.push(...this.loadChildren(nextCU.index));
              }
            }
          }
        });
      }
    }
    return returnData;
  }

  prepareModifiedData(solutionLOgics: ChangeUnit[]) {
    const modifiedData: ChangeUnit[] = this.deepClone(solutionLOgics);
    const cloneData: ChangeUnit[] = [];
    cloneData.push(modifiedData[0]);
    if (modifiedData[0].nextTriggerSet.length > 0) {
      modifiedData[0].nextTriggerSet.forEach((nextCU: any) => {
        if (!nextCU?.isParallel && nextCU?.isSelected) {
          cloneData.push(...this.loadChildren(nextCU.index));
        }
      });
    }
    for (let i = 0; i < cloneData.length; i++) {
      for (let j = i + 1; j < cloneData.length; j++) {
        if (cloneData[j].id === cloneData[i].id && cloneData[j].index === cloneData[i].index) {
          cloneData.splice(j, 1);
        }
      }
    }

    this.cuIndexSet.clear();
    this.modifiedData = [...cloneData];
  }
  loadDataService(data: ChangeUnit[]) {
    this.modifiedData = [];
    this.originalData = [];
    this.cuIndexSet.clear();
    const solutionLOgics: any = [];
    // data = this.sortBynexttriggerset(data);
    const showTagsIndex = data.find((m) => m.showTagsView);
    data?.forEach((m: ChangeUnit) => {
      m.isEditable = m.isEditable ? m.isEditable : false;

      if (m.nextTriggerSet && m.nextTriggerSet.length > 0) {
        // m.nextTriggerSet = this.sortArray(m.nextTriggerSet, data);
        this.findFirstNotSelectedCU(m);
        m.nextTriggerSet[0]['isSelected'] = true;
        m.nextTriggerSet?.forEach((o: any) => {
          if (!o.nextCUId) {
            const index = data?.find((n: any) => n.index === o.index);
            if (index) {
              o.nextCUId = index.id;
            }
          }
          if (showTagsIndex && showTagsIndex.index === o.index) {
            this.setIsSelectedForNextTriggerSet(m.nextTriggerSet, showTagsIndex.index);
          }
        });
      }
      solutionLOgics.push(m);
    });

    this.originalData = this.deepClone(solutionLOgics);
    this.prepareModifiedData(solutionLOgics);
  }

  findFirstNotSelectedCU(cu: any) {
    /* istanbul ignore next */
    let selectedCU = cu?.nextTriggerSet?.find((m: any) => m?.isSelected);
    /* istanbul ignore next */
    if (!selectedCU) {
      const len = cu.nextTriggerSet.length;
      let i;
      for (i = 0; i < len; i++) {
        if (!cu.nextTriggerSet[i]?.isParallel && !cu.nextTriggerSet[i]?.isSelected) {
          cu.nextTriggerSet[i]['isSelected'] = true;
          break;
        }
      }
    }
  }
  sortArray(objs: any, data: ChangeUnit[]) {
    return objs.sort((a: any, b: any) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));
  }

  sortBynexttriggerset(data: ChangeUnit[]) {
    data?.forEach((m: ChangeUnit) => {
      m.nextTriggerSet = this.sortArray(m.nextTriggerSet, data);
    });
    return data.sort((a: ChangeUnit, b: ChangeUnit) =>
      a?.nextTriggerSet[0]?.index > b?.nextTriggerSet[0]?.index
        ? 1
        : b?.nextTriggerSet[0]?.index > a?.nextTriggerSet[0]?.index
        ? -1
        : 0
    );
  }

  deepClone(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  setIsSelectedForNextTriggerSet(nextTriggerSet: any[], index: number) {
    nextTriggerSet.forEach((o: any) => {
      o['isSelected'] = false;
      if (index === o.index) {
        o['isSelected'] = true;
      }
    });
  }
}
