import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AlertService, Librarytypes, LoaderService, NodeGsiFacadeService, pagination, TranslatorService
} from '@common-services';
import { NodeChangeUnitFacadeService } from '../../../node-services/node-change-unit-facade.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-node-system-solutions',
  templateUrl: './node-system-solutions.component.html',
  styleUrls: ['./node-system-solutions.component.scss'],
})
export class NodeSystemSolutionsComponent implements OnInit, OnDestroy {
  @ViewChild('deleteDraftWarning') deleteDraftWarning: TemplateRef<any>;
  @Output() betCountEmitter = new EventEmitter();
  private ngUnsubscribe = new Subject();
  pageIndex: number = 0;
  pageSize: number = 15;
  searchText: string = '';
  isPublished: any = '';
  gsiList: any = {
    data: [],
    totalResults: [],
  };
  clonedGsiName: any;
  displayNodesearch: any;
  cloningData: any;
  filterType: string = 'all';
  @Input() libraryType: any;
  libraryData: any;
  activeGsi: number = -1;
  labels: any;
  cloningGsiData: any;
  alertModal: boolean = true;
  draftGsi: any;
  selectedCyType: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private loader: LoaderService,
    public dialog: MatDialog,
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private translator: TranslatorService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.getAllSolutions();
    this.detectChangeUnitList();
    this.getLibraryData();
    this.detectLanguageChange();
    this.GetCuType();
    this.getCurrentGsi();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.libraryType === Librarytypes.GSInested || this.libraryType === Librarytypes.GSIEmbedded) {
      this.filterType = 'published';
      this.loadData('');
    }
    // this.getDeleteResponse();
  }

  freshStart() {
    this.gsiFacade.freshStart();
  }
  getAllSolutions() {
    /* istanbul ignore next */
    this.gsiFacade.getAllGsis(this.pageIndex, this.pageSize, this.searchText, this.isPublished, false);
  }
  loadData(event: any) {
    if (this.filterType == 'published') {
      this.isPublished = true;
    } else if (this.filterType == 'drafts') {
      this.isPublished = false;
    } else {
      this.isPublished = '';
    }
    this.getAllSolutions();
  }
  detectChangeUnitList() {
    this.gsiFacade.gsiList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.gsiList = res.result;
      this.betCountEmitter.next(res?.result?.totalResults)
    });
  }
  onPageChange(pageData: pagination) {
    this.pageSize = pageData.pageSize;
    this.pageIndex = pageData.pageIndex;
    this.getAllSolutions();
  }

  getCuDetails(gsi: any) {
    if (gsi.status === 'DRAFT') {
      this.gsiFacade.getCuDetails(gsi.id);
    } else {
      this.gsiFacade.editPublishedBET(gsi.id, 'gsi');
    }
  }
  importGsiData(gsi: any, ClonedtemplateRef: TemplateRef<any>) {
    /* istanbul ignore else */
    if (!(this.selectedCyType == 'Embedded' && gsi.status == 'DRAFT')) {
      this.alertModal = false;
      this.cloningGsiData = gsi;
      this.gsiFacade.saveCurrentGsi(gsi);
      switch (this.libraryType) {
        case Librarytypes.GSInested:
          this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSInested);
          break;
        case Librarytypes.GSIexception:
          this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSIexception);
          break;
        case Librarytypes.GSIEmbedded:
          this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSIEmbedded);
          break;
        case Librarytypes.ShadesOfPotentiality:
          this.cloningGsi();
          return;
      }
      // this.dialog.closeAll();
      /* istanbul ignore next */
      if (
        this.libraryType !== Librarytypes.GSInested &&
        this.libraryType !== Librarytypes.GSIexception &&
        this.libraryType !== Librarytypes.GSIEmbedded &&
        !this.gsiFacade.isFromAlternate.value
      ) {
        this.dialog.open(ClonedtemplateRef, { width: '520px' });
      } else if (this.gsiFacade.isFromAlternate.value && this.libraryType !== Librarytypes.GSIEmbedded) {
        this.gsiFacade.getAlternateGsiDetails(gsi);
      }
      //this.gsiFacade.getTempGsiData(gsi)
      // this.alertModal = false;
      // this.cloningGsiData = gsi;
      // this.gsiFacade.saveCurrentGsi(gsi);
      // switch (this.libraryType) {
      //   case Librarytypes.GSInested:
      //     this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSInested);
      //     break;
      //   case Librarytypes.GSIexception:
      //     this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSIexception);
      //     break;
      //   case Librarytypes.GSIEmbedded:
      //     this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSIEmbedded);
      //     break;
      //   case Librarytypes.ShadesOfPotentiality:
      //     this.cloningGsi();
      //     return;
      // }
    }
  }

  cloningGsi() {
    let gsi = this.cloningGsiData;
    switch (this.libraryType) {
      case Librarytypes.GSInested:
        this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSInested);
        break;
      case Librarytypes.GSIexception:
        this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSIexception);
        break;
      case Librarytypes.GSIEmbedded:
        this.gsiFacade.addNestedGsi(gsi, Librarytypes.GSIEmbedded);
        break;
      case Librarytypes.ShadesOfPotentiality:
        this.gsiFacade.getTempGsiData(gsi);
        break;
      case Librarytypes.ChangeUnit:
        this.changeUnitFacade.addEmbeddedCu(gsi);
      default:
        if (this.libraryType !== Librarytypes.ChangeUnit) this.gsiFacade.getCuDetails(gsi.dsdId);
    }
    /* istanbul ignore next */
    this.changeUnitFacade.getRelatedGsis(gsi.id);
    this.dialog.closeAll();
  }

  viewData(gsi: any, ClonedtemplateRef: TemplateRef<any>) {
    this.dialog.closeAll();
    this.dialog.open(ClonedtemplateRef, { width: '520px' });
    this.cloningData = {
      ...gsi,
      isPublished: this.isPublished,
    };
  }
  cloneData() {
    const data = { name: this.clonedGsiName };
    this.cancelDeleteDraft();
    /* istanbul ignore next */
    this.gsiFacade.getcloningDetails(this.cloningData?.dsdId, this.cloningData?.isPublished, 'gsi', data);
  }
  expandGsi(gsi: any, currentInd: number) {
    this.activeGsi = currentInd;
    this.gsiFacade.viewBetDetails(gsi.id, Librarytypes.GSI);
  }
  getLibraryData() {
    this.gsiFacade.viewLibraryData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.libraryData = res;
        const foundIndex = this.gsiList.data.findIndex((gsi: any) => gsi.id == res.id);
        /* istanbul ignore else */
        if (foundIndex != -1) {
          this.gsiList.data[foundIndex].displayCUList = res;
        }
      }
    });
  }

  clickedForSearch() {
    this.displayNodesearch = !this.displayNodesearch;
    let ele = document.getElementById('search_element');
    /* istanbul ignore else */
    if (ele) {
      /* istanbul ignore next */
      setTimeout(() => {
        /* istanbul ignore next */
        ele?.focus();
      }, 100);
    }
  }

  deleteGsiDraft(data: any, event: any) {
    /* istanbul ignore next */
    if (event) {
      event?.stopPropagation();
    }
    this.dialog?.open(this.deleteDraftWarning, {
      panelClass: 'onboardingtype-dialog',
      width: '469px',
      height: '243px',
      disableClose: true,
      data: { gsi: data },
    });
    this.draftGsi = data;
  }

  cancelDeleteDraft() {
    this.dialog?.closeAll();
  }

  clickDeleteDraft() {
    this.gsiFacade.deleteGsiDraft(this.draftGsi?.id, (res: any) => {
      /*istanbul ignore next */
      if (res) {
        console.log(res);
        this.alertService.showToaster(res?.message, '', 'success');
        this.getAllSolutions();
        this.cancelDeleteDraft();
        if (this.cloningGsiData?.id === this.draftGsi?.id) {
          this.router.navigateByUrl('/mylibrary-dashboard');
        }
      }
    });
  }
  GetCuType() {
    this.changeUnitFacade.selectedCuType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.selectedCyType = res;
      }
    });
  }

  // getDeleteResponse() {
  //   this.gsiFacade.deleteDraftGsi$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
  //     (res: any) => {
  //       if (res) {
  //         console.log(res);
  //         this.alertService.showToaster(res?.message, '', 'success');
  //         this.getAllSolutions();
  //         this.cancelDeleteDraft();
  //         window.location.reload();
  //       } else {
  //         this.alertService.showToaster(res?.message, '', 'error');
  //       }
  //     },
  //     (error: any) => {
  //       this.alertService.showToaster(error?.error?.message, '', 'error');
  //     });
  // }
  getCurrentGsi() {
    this.gsiFacade.getGsiData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        if (res) {
          this.cloningGsiData = res;
        } else {
          this.alertService.showToaster(res?.message, '', 'error');
        }
      },
      (error: any) => {
        this.alertService.showToaster(error?.error?.message, '', 'error');
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
