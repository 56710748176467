import { Component, OnInit, Input } from '@angular/core';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeGsiFacadeService } from '@common-services';

@Component({
  selector: 'app-node-expand-layers',
  templateUrl: './node-expand-layers.component.html',
  styleUrls: ['./node-expand-layers.component.scss'],
})
export class NodeExpandLayersComponent implements OnInit {
  @Input() layerData: any;
  @Input() cuName: any;
  @Input() tempGsiObj: any;
  @Input() configurationData: any;
  @Input() layerLabel: any;
  @Input() changeUnit: any;
  @Input() conditionalPotentiality: any;
  @Input() isAlternateFlag: any;
  @Input() isRecursiveFlag: any;
  @Input() isMembership: boolean = false;
  @Input() SOPTarget: boolean = false;
  @Input() SOPSource: boolean = false;
  expressionType: any = 'dcd';
  attributeType: string = '';
  currentUielement: any;
  isPrimaryKeySearch: boolean = false;
  @Input() set setPrimaryKeySearch(data: any) {
    this.isPrimaryKeySearch = data?.reservedCUType === 'primarykeysearch' ? true : false;
  }

  constructor(private changeunitFacade: NodeChangeUnitFacadeService,private nodeGsiFacade: NodeGsiFacadeService) {}

  ngOnInit(): void {}

  addEntityToDCD(res: any) {
    /* istanbul ignore next */
    this.addAttributetoDcd(res.entityIndex, res.entity?.name, res.entity?.tfId, null, null, res.entity);
  }

  addAttributeToDCD(res: any) {
    /* istanbul ignore next */
    this.attributeType = res?.attribute?.attributeType?.type;
    this.currentUielement = res?.attribute?.attributeType?.uiElement?.uiElement;
    /* istanbul ignore next */
    this.addAttributetoDcd(
      res.entityIndex,
      res.entity?.name,
      res.entity?.tfId,
      res.attribute?.name,
      res.attribute?.id,
      res.entity
    );
    /* istanbul ignore next */
    this.getAttDetails(
      res.entity,
      res.attribute,
      res.entityIndex,
      res.entity?.name,
      res.entity?.tfId,
      res.attribute?.name,
      res.attribute?.id
    );
  }
  getAttDetails(
    entity: any,
    attribute: any,
    entityIndex?: number,
    entityName?: string,
    entityId?: string,
    attributeName?: string,
    attributeId?: string
  ) {
    /* istanbul ignore next */
    let layerLabel = this.layerLabel?.name.split(' ');
    const layerType = layerLabel[0];
    const data = {
      entity: entity,
      attribute: attribute,
    };
    /* istanbul ignore else */
    if (entityIndex !== -1) {
      data['contextualAttrId'] = this.generateFormulaId(layerType, entityIndex, entityId, attributeId, true);
      data['contextualAttrName'] = this.generateFormulaId(
        layerType,
        entityIndex,
        entityName,
        attributeName,
        true,
        true
      );
    }

    //this.libraryFacadeService.getSingleAttributeDetailsWithEnt(data);
  }
  addAttributetoDcd(
    entityIndex: number,
    entityName: string,
    entityId: string,
    attributeName: string,
    attributeId: string,
    entityData?: any
  ) {
    /* istanbul ignore next */
    let cuName = this.configurationData?.name ? this.configurationData?.name : this.cuName;
    if (cuName == undefined) {
      cuName = this.configurationData?.data?.cu?.name;
    }

    /* istanbul ignore next */
    const cuIndex = this.configurationData?.index;
    /* istanbul ignore next */
    let layerLabel = this.layerLabel?.name.split(' ');
    const layerType = layerLabel[0];
    let contextualIdWithId = this.generateContextualId(
      cuName,
      cuIndex,
      layerType,
      entityIndex,
      entityId,
      attributeId,
      entityData
    );
    let formulaIdWithId = this.generateFormulaId(
      layerType,
      entityIndex,
      entityId,
      attributeId,
      false,
      false,
      this.isMembership
    );
    let contextualId = this.generateContextualId(
      cuName,
      cuIndex,
      layerType,
      entityIndex,
      entityName,
      attributeName,
      entityData
    );
    let formulaId = this.generateFormulaId(layerType, entityIndex, entityName, attributeName);
    let tempDcdObj = {
      contextualId: contextualId,
      contextualIdWithId: contextualIdWithId,
      formulaId: formulaId,
      formulaIdWithId: formulaIdWithId,
      label: attributeName,
      name: attributeName,
      expressionType: this.expressionType,
      attributeType: this.attributeType,
      attributeuiElement: this.currentUielement,
    };
    /* istanbul ignore next */
    if (this.changeUnit?.cuType === 'alternate' && !(this.changeUnit?.mindCUList?.length > 0) && this.isAlternateFlag) {
      this.changeunitFacade.addAttributetoAlternateCU({ ...tempDcdObj, isAlternateCU: this.changeUnit.cuType });
    }
    if (this.changeUnit?.mindCUList?.length > 0) {
      this.changeunitFacade.addAttributetoNestedCU({
        ...tempDcdObj,
        cuType: 'nested',
        attributeType: this.attributeType,
      });
    }
    if (this.conditionalPotentiality) {
      this.changeunitFacade.addExpression({ ...tempDcdObj, cuType: 'conditionalPotentiality' });
    }
    if (this.isRecursiveFlag) {
      let idType = attributeName ? 'attribute' : 'entity';
      this.nodeGsiFacade.addExpression({
        ...tempDcdObj,
        cuType: 'recursive',
        contextualIdType: idType,
        attrType: this.attributeType,
      });
    }
    if (this.changeUnit?.isReserved == true) {
      this.changeunitFacade.addExpression({ ...tempDcdObj, cuType: 'membership' });
    }
    if (this.SOPSource) {
      this.changeunitFacade.addExpression({ ...tempDcdObj, cuType: 'SOPSource' });
      // this.SOPSource = false;
    }
    if (this.SOPTarget) {
      this.changeunitFacade.addExpression({ ...tempDcdObj, cuType: 'SOPTarget' });
      // this.SOPTarget = false;
    }
  }
  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }
  generateFormulaId(
    layerType: string,
    entityIndex: any,
    entityName: string,
    attributeName: string,
    isForDependent?: boolean,
    isEntityname?: boolean,
    membershipWithId?: boolean
  ) {
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    let indexPadding = this.getIndexPadding(entityIndex + 1);
    let dot = '.';
    let layerShortCut = 'PL.SL' + indexPadding;
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      layerShortCut = 'triggerCES.SL' + indexPadding;
    }
    let formulaId =
      isForDependent && !isEntityname ? layerShortCut + dot + 'EN' + entityName : layerShortCut + dot + entityName;
    /* istanbul ignore else */
    if (membershipWithId) {
      formulaId = layerShortCut + dot + 'EN' + entityName;
    }
    if (membershipWithId && attributeName) {
      formulaId = formulaId + dot + 'AT' + attributeName;
    } else if (attributeName) {
      formulaId = formulaId + dot + attributeName;
    }
    return formulaId;
  }
  generateContextualId(
    cuName: string,
    cuIndex: number,
    layerType: string,
    entityIndex: number,
    entityName: string,
    attributeName: string,
    currentEntityData: any
  ) {
    let dot = '.';
    let colon = ':';
    let slot = 'slot';
    let gsiName;
    /* istanbul ignore else */
    if (this.tempGsiObj && this.tempGsiObj.appDetails) {
      gsiName = this.tempGsiObj.appDetails.gsiName
        ? this.tempGsiObj.appDetails.gsiName
        : this.tempGsiObj.appDetails.name;
    }
    let contextualId = '';

    if (cuIndex) {
      contextualId =
        gsiName +
        dot +
        cuName +
        colon +
        cuIndex +
        dot +
        layerType +
        dot +
        slot +
        colon +
        (entityIndex + 1) +
        dot +
        entityName;
    } else {
      contextualId = gsiName + dot + layerType + dot + slot + colon + (entityIndex + 1) + dot + entityName;
    }
    /* istanbul ignore else */
    if (attributeName) {
      contextualId = contextualId + dot + attributeName;
    }

    // if (this.mindLayerFlag) {
    //   gsiName = this.tempGsiObj.appDetails.gsiName;
    //   cuName = this.tempGsiObj.cuList[this.tempGsiObj.activeCuIndex].name;
    //   const actualIndex = this.tempGsiObj.cuList[this.tempGsiObj.activeCuIndex].index;
    //   contextualId = gsiName + dot + cuName + colon + actualIndex + dot + 'nestedSSA' + colon + contextualId;
    // }
    // if (this.currentCUType === 'dcd') {
    //   this.libraryFacadeService.updateCurrentEntityData(currentEntityData);
    //   //this.updateTempGSI(currentEntityData);
    // }

    return contextualId;
  }
}
