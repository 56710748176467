import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeUnitFacadeService } from '../../change-unit/change-unit-facade.service';
import { EntityFacadeService } from '../../entity/entity-facade.service';
import { SourceTypes } from '../../enums/dld-source.enums';
import { GsiFacadeService } from '../../gsi/gsi-facade.service';
import { ChangeUnit } from '../../modal/change-unit';
import { RolesFacadeService } from '../../roles/roles-facade.service';
import { SolutionDictionaryFacadeService } from '../../solution-dictionary/solution-dictionary-facade.service';
import { AlertService } from '../../toaster/alert.service';
import { TranslatorService } from '../../translator/translator.service';

@Component({
  selector: 'app-configure-solutions',
  templateUrl: './configure-solutions.component.html',
  styleUrls: ['./configure-solutions.component.scss'],
})
export class ConfigureSolutions implements OnInit {
  @Input() bookConflicts: any = [];
  @Input() gsiConflicts: any = [];
  @Input() cuConflicts: any = [];
  @Input() geConflicts: any = [];
  @Output() changes: EventEmitter<any> = new EventEmitter();
  @Input() isDsdRhs: boolean;
  @Input() saasapplication: boolean;
  changed = {
    bookConflictsResolvednumber: 0,
    gsiConflictsResolvednumber: 0,
    cuConflictsResolvednumber: 0,
    geConflictsResolvednumber: 0,
  };
  bookHover: boolean[] = [];
  gsiHover: boolean[] = [];
  cuHover: boolean[] = [];
  geHover: boolean[] = [];
  bookRenameFalg: boolean[] = [];
  gsiRenameFalg: boolean[] = [];
  cuRenameFalg: boolean[] = [];
  geRenameFalg: boolean[] = [];
  newName: string = '';
  @Input() showBooks: boolean;
  @Input() showGsis: boolean;
  @Input() showCus: boolean;
  @Input() showGes: boolean;
  @Input() solutionConflictParameters: any;
  @Input() selectedType: string;
  labels: any;
  ngUnsubscribe = new Subject();
  disableRenameFlag: boolean;
  betType: any;
  indexdata: any = [];
  selectedBetType: string;
  conflictBookIndex: any;
  conflictGSIIndex: number;
  conflictCUIndex: number;
  conflictGEIndex: number;

  constructor(
    // private betConfiguration: BetConfiguration,
    private router: Router,
    private translator: TranslatorService,
    private dialogRef: MatDialog,
    private gsiFacadeService: GsiFacadeService,
    private cuFacadeService: ChangeUnitFacadeService,
    private entityFacadeService: EntityFacadeService,
    private solutionDictFacade: SolutionDictionaryFacadeService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private rolesFacade: RolesFacadeService,
    private alertService: AlertService
  ) {
    this.getBetType();
    this.detectMergeBETs();
    this.detectLanguageChange();
    this.validatedGSINameOrNot();
    this.validatedCUNameOrNot();
    this.validatedBookNameOrNot();
    this.validatedEntityNameOrNot();
    this.detectcuValidate();
  }

  ngOnInit(): void {
    this.showLists();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  showLists() {
    /* istanbul ignore next */
    this.bookConflicts?.forEach((item: any) => {
      this.bookHover.push(false);
      this.bookRenameFalg.push(false);
    });
    /* istanbul ignore next */
    this.gsiConflicts?.forEach((item: any) => {
      this.gsiHover.push(false);
      this.gsiRenameFalg.push(false);
    });
    /* istanbul ignore next */
    this.cuConflicts?.forEach((item: any) => {
      this.cuHover.push(false);
      this.cuRenameFalg.push(false);
    });
    /* istanbul ignore next */
    this.geConflicts?.forEach((item: any) => {
      this.geHover.push(false);
      this.geRenameFalg.push(false);
    });
  }

  moveToNextTab() {
    // this.betConfiguration.changeTabIndex(6);
    this.solutionDictionaryFacade.setChangeTabIndex(6);
    this.changes.emit({
      changed: this.changed,
      bookConflicts: this.bookConflicts,
      gsiConflicts: this.gsiConflicts,
      cuConflicts: this.cuConflicts,
      geConflicts: this.geConflicts,
    });
    this.bookConflicts = [];
    this.gsiConflicts = [];
    this.cuConflicts = [];
    this.geConflicts = [];
    this.changed.bookConflictsResolvednumber = 0;
    this.changed.gsiConflictsResolvednumber = 0;
    this.changed.cuConflictsResolvednumber = 0;
    this.changed.geConflictsResolvednumber = 0;
  }

  toggleRenameButton(type: string, index: number) {
    if (type === 'gsi') {
      this.gsiHover[index] = true;
    } else if (type === 'cu') {
      this.cuHover[index] = true;
    } else if (type === 'ge') {
      this.geHover[index] = true;
    } else if (type === 'book') {
      this.bookHover[index] = true;
    }
  }

  toggleEdit(type: string, index: number) {
    this.gsiRenameFalg[index] = false;
    this.cuRenameFalg[index] = false;
    this.geRenameFalg[index] = false;
    this.bookRenameFalg[index] = false;
    this.disableRenameFlag = false;
    /* istanbul ignore next */
    if (type === 'gsi') {
      this.gsiRenameFalg = this.gsiRenameFalg.map((bet: any) => {
        return false;
      });
      this.gsiHover[index] = false;
      this.gsiRenameFalg[index] = true;
      if (this.gsiConflicts[index]?.targetValue) {
        this.newName = this.gsiConflicts[index]?.targetValue;
        this.disableRenameFlag = true;
      } else this.newName = this.gsiConflicts[index]?.sourceValue;
    } else if (type === 'cu') {
      this.cuRenameFalg = this.cuRenameFalg.map((bet: any) => {
        return false;
      });
      this.cuHover[index] = false;
      this.cuRenameFalg[index] = true;
      if (this.cuConflicts[index]?.targetValue) {
        this.newName = this.cuConflicts[index]?.targetValue;
        this.disableRenameFlag = true;
      } else this.newName = this.cuConflicts[index]?.sourceValue;
    } else if (type === 'ge') {
      this.geRenameFalg = this.geRenameFalg.map((bet: any) => {
        return false;
      });
      this.geHover[index] = false;
      this.geRenameFalg[index] = true;
      if (this.geConflicts[index]?.targetValue) {
        this.newName = this.geConflicts[index]?.targetValue;
        this.disableRenameFlag = true;
      } else this.newName = this.geConflicts[index]?.sourceValue;
    } else if (type === 'book') {
      this.bookRenameFalg = this.bookRenameFalg.map((bet: any) => {
        return false;
      });
      this.bookHover[index] = false;
      this.bookRenameFalg[index] = true;
      if (this.bookConflicts[index]?.targetValue) {
        this.newName = this.bookConflicts[index]?.targetValue;
        this.disableRenameFlag = true;
      } else this.newName = this.bookConflicts[index]?.sourceValue;
      // renameList = this.bookRenameFalg;
    }
    this.solutionConflictParameters.type = '';
    this.solutionConflictParameters.index = -1;
  }

  save(ind: number, type: string) {
    let tenantName = localStorage.getItem('TenantName');
    if (type === 'gsi') {
      this.conflictGSIIndex = ind;
      let posRename = this.indexdata.findIndex((j: any) => j === this.conflictGSIIndex);
      if (posRename > -1) this.indexdata.splice(posRename, 1);
      if (this.newName === this.gsiConflicts[this.conflictGSIIndex]?.sourceValue) {
        this.alertService.showToaster('Please rename the GSI', '', 'error');
      } else {
        this.gsiFacadeService.validateGSIName(tenantName, this.newName);
      }
    } else if (type === 'cu') {
      this.conflictCUIndex = ind;
      let posRename = this.indexdata.findIndex((j: any) => j === this.conflictCUIndex);
      if (posRename > -1) this.indexdata.splice(posRename, 1);
      if (this.newName === this.cuConflicts[this.conflictCUIndex]?.sourceValue) {
        this.alertService.showToaster('Please rename the Change Unit', '', 'error');
      } else {
        this.cuFacadeService.validateCUName(tenantName, this.newName);
      }
    } else if (type === 'ge') {
      this.conflictGEIndex = ind;
      let posRename = this.indexdata.findIndex((j: any) => j === this.conflictGEIndex);
      if (posRename > -1) this.indexdata.splice(posRename, 1);
      if (this.newName === this.geConflicts[this.conflictGEIndex].sourceValue) {
        this.alertService.showToaster('Please rename the Entity', '', 'error');
      } else {
        this.entityFacadeService.validateEntityName(tenantName, this.newName);
      }
    } else if (type === 'book') {
      this.conflictBookIndex = ind;
      let posRename = this.indexdata.findIndex((j: any) => j === this.conflictBookIndex);
      if (posRename > -1) this.indexdata.splice(posRename, 1);
      if (this.newName === this.bookConflicts[this.conflictBookIndex].sourceValue) {
        this.alertService.showToaster('Please rename the Book', '', 'error');
      } else {
        this.solutionDictFacade.validateBookName(tenantName, this.newName);
      }
    }
  }

  validatedGSINameOrNot() {
    this.gsiFacadeService.validatedGSIName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.conflictGSIIndex >= 0) {
        this.gsiConflicts[this.conflictGSIIndex] = {
          ...this.gsiConflicts[this.conflictGSIIndex],
          targetValue: this.newName,
        };
        this.gsiConflicts[this.conflictGSIIndex].isConflictResolved = true;
        this.gsiConflicts[this.conflictGSIIndex].conflictAction = 'RENAME';
        this.gsiConflicts[this.conflictGSIIndex].message =
          'Renamed from ' + this.gsiConflicts[this.conflictGSIIndex].sourceValue;
        this.gsiRenameFalg[this.conflictGSIIndex] = false;
        this.changed.gsiConflictsResolvednumber = this.changed.gsiConflictsResolvednumber + 1;
        this.newName = '';
      }
    });
  }

  validatedCUNameOrNot() {
    this.cuFacadeService.validatedCUName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.conflictCUIndex >= 0) {
        const cuData: any = {
          ...new ChangeUnit(),
          name: this.newName,
          source: SourceTypes.UserCreated,
        };
        let formData: FormData = new FormData();
        formData.append('text', cuData.name);
        if (cuData.name) this.cuFacadeService.validateCuList(formData, cuData);
      }
    });
  }

  detectcuValidate() {
    this.cuFacadeService.cuValidate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.conflictCUIndex >= 0) {
        this.cuConflicts[this.conflictCUIndex] = {
          ...this.cuConflicts[this.conflictCUIndex],
          targetValue: this.newName,
        };
        this.cuConflicts[this.conflictCUIndex].isConflictResolved = true;
        this.cuConflicts[this.conflictCUIndex].conflictAction = 'RENAME';
        this.cuConflicts[this.conflictCUIndex].message =
          'Renamed from ' + this.cuConflicts[this.conflictCUIndex].sourceValue;
        this.cuRenameFalg[this.conflictCUIndex] = false;
        this.changed.cuConflictsResolvednumber = this.changed.cuConflictsResolvednumber + 1;
        this.newName = '';
      }
    });
  }

  validatedEntityNameOrNot() {
    this.entityFacadeService.validatedEntityName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.conflictGEIndex >= 0) {
        this.geConflicts[this.conflictGEIndex] = {
          ...this.geConflicts[this.conflictGEIndex],
          targetValue: this.newName,
        };
        this.geConflicts[this.conflictGEIndex].isConflictResolved = true;
        this.geConflicts[this.conflictGEIndex].conflictAction = 'RENAME';
        this.geConflicts[this.conflictGEIndex].message =
          'Renamed from ' + this.geConflicts[this.conflictGEIndex].sourceValue;
        this.geRenameFalg[this.conflictGEIndex] = false;
        this.changed.geConflictsResolvednumber = this.changed.geConflictsResolvednumber + 1;
        this.newName = '';
      }
    });
  }

  validatedBookNameOrNot() {
    this.solutionDictFacade.validatedBookName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.conflictBookIndex >= 0) {
        this.bookConflicts[this.conflictBookIndex] = {
          ...this.bookConflicts[this.conflictBookIndex],
          targetValue: this.newName,
        };
        this.bookConflicts[this.conflictBookIndex].isConflictResolved = true;
        this.bookConflicts[this.conflictBookIndex].conflictAction = 'RENAME';
        this.bookConflicts[this.conflictBookIndex].message =
          'Renamed from ' + this.bookConflicts[this.conflictBookIndex].sourceValue;
        this.bookRenameFalg[this.conflictBookIndex] = false;
        this.changed.bookConflictsResolvednumber = this.changed.bookConflictsResolvednumber + 1;
        this.newName = '';
      }
    });
  }

  checkConflicts() {
    let flag: boolean = true;
    /* istanbul ignore else */
    if (this.selectedType === 'book') {
      /* istanbul ignore next */
      this.bookConflicts?.forEach((book: any) => {
        if (book.conflicting) {
          flag = flag && book.isConflictResolved;
        }
      });
    }

    /* istanbul ignore else */
    if (this.selectedType === 'gsi') {
      /* istanbul ignore next */
      this.gsiConflicts?.forEach((gsi: any) => {
        if (gsi.conflicting) {
          flag = flag && gsi.isConflictResolved;
        }
      });
    }

    /* istanbul ignore else */
    if (this.selectedType === 'cu') {
      /* istanbul ignore next */
      this.cuConflicts?.forEach((cu: any) => {
        if (cu.conflicting) {
          flag = flag && cu.isConflictResolved;
        }
      });
    }

    /* istanbul ignore else */
    if (this.selectedType === 'ge') {
      /* istanbul ignore next */
      this.geConflicts?.forEach((ge: any) => {
        if (ge.conflicting) {
          flag = flag && ge.isConflictResolved;
        }
      });
    }

    return !flag;
  }

  cancel() {
    if (!this.saasapplication) {
      if (this.isDsdRhs) {
        this.dialogRef.closeAll();
      } else {
        if (this.betType === 'gsiImport') {
          this.rolesFacade.closeDialog(true);
          this.router.navigateByUrl('/admin/importsolution');
        } else {
          this.router.navigateByUrl('/solution-dictionary');
        }
        this.dialogRef.closeAll();
      }
    } else {
      this.dialogRef.closeAll();
      this.solutionDictionaryFacade.saasapplicationBetSubect.next(null);
      this.router.navigateByUrl('admin/manage-saasapplications/addSaasApplication');
    }
    this.solutionDictionaryFacade.closepopupDataService();
  }

  betTabchange() {
    let nextTab;
    switch (this.selectedType) {
      case 'book':
        nextTab = 3;
        // this.betConfiguration.toggleOptionsSolutions('gsi');
        this.solutionDictionaryFacade.setToggleOptions('gsi');
        break;
      case 'gsi':
        nextTab = 4;
        // this.betConfiguration.toggleOptionsSolutions('cu');
        this.solutionDictionaryFacade.setToggleOptions('cu');
        break;
      case 'cu':
        nextTab = 5;
        // this.betConfiguration.toggleOptionsSolutions('ge');
        this.solutionDictionaryFacade.setToggleOptions('ge');
        break;
    }
    // this.betConfiguration.changeTabIndex(nextTab);
    this.solutionDictionaryFacade.setChangeTabIndex(nextTab);
    this.indexdata = [];
  }

  getBetType() {
    this.solutionDictionaryFacade.betType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.betType = res;
      }
    });
  }

  changeCheckboxSelect(eve: any, index: any, type: string) {
    /* istanbul ignore next */
    if (eve?.checked) {
      this.indexdata?.push(index);
    } else if (!eve?.checked) {
      this.indexdata?.splice(index, 1);
    }
  }

  mergeBETsWithExisting(betArray: any[]) {
    /* istanbul ignore next */
    this.indexdata?.forEach((index: any) => {
      betArray[index].isConflictResolved = true;
      betArray[index].conflictAction = 'MERGE';
      betArray[index].targetValue = betArray[index]?.sourceValue;
      betArray[index].isResolvedcon = true;
      betArray[index].message = ' Merged with Existing Role';
      betArray[index].selectedrole = false;
    });
    this.indexdata = [];
  }

  detectMergeBETs() {
    this.rolesFacade.mergeOrMapRolesObs$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res === 'merge') {
        switch (this.selectedType) {
          case 'ge': {
            this.changed.geConflictsResolvednumber = this.changed.geConflictsResolvednumber + 1;
            this.mergeBETsWithExisting(this.geConflicts);
            break;
          }
          case 'cu': {
            this.changed.cuConflictsResolvednumber = this.changed.cuConflictsResolvednumber + 1;
            this.mergeBETsWithExisting(this.cuConflicts);
            break;
          }
          case 'gsi': {
            this.changed.gsiConflictsResolvednumber = this.changed.gsiConflictsResolvednumber + 1;
            this.mergeBETsWithExisting(this.gsiConflicts);
            break;
          }
          case 'book': {
            this.changed.bookConflictsResolvednumber = this.changed.bookConflictsResolvednumber + 1;
            this.mergeBETsWithExisting(this.bookConflicts);
            break;
          }
        }
      }
    });
  }
}
