import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnJS from 'bpmn-js';
import BpmnPalletteModule from 'bpmn-js/lib/features/palette';

import { importDiagram } from './import-diagram';

import { throwError } from 'rxjs';

@Component({
  selector: 'app-bpmn-viewer',
  template: `
    <div #ref id="canvas" class="diagram-container" [ngClass]="{'bpmneditor': showDiagramButtons && showExportButtons}"></div>
    <div class="upload-btns" *ngIf="showExportButtons">
      <button class="btn btn-upload" (click)="onSvgExport()">Export SVG</button>
      <button class="btn btn-upload" (click)="onBpmnExport()">Export BPMN</button>
      <button class="btn btn-cancel" (click)="cancelBPMN()">Cancel</button>
    </div>
  `,
  styles: [
    `
      .diagram-container {
        height: calc(80vh - 300px);
        width: 100%;
      }
      .bpmneditor {
        height: calc(100vh - 140px);
      }
      .upload-btns {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
      }
      .upload-btns .btn {
        padding: 8px 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .upload-btns .btn:focus {
        box-shadow: none;
      }
      .upload-btns .btn.btn-upload {
        background: #C1C1C1;
        border: 1px solid #C1C1C1;
        margin-right: 24px;
      }
      .upload-btns .btn.btn-cancel {
        background: #fff;
        border: 1px solid #707070;
        color: #323232;
      }
      .upload-btns .btn.btn-cancel:hover {
        background: #F4F5F4;
      }
    `,
  ],
})
export class BpmnViewerComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  private bpmnJS: any;

  @Input() set _showDiagramButtons(value:any){
    this.showDiagramButtons = value;
  }
  @Input() set _showExportButtons(value:any){
    this.showExportButtons = value;
  }
  @ViewChild('ref', { static: true }) private el: ElementRef;
  @Output() private importDone: EventEmitter<any> = new EventEmitter();

  @Input() private url: string;
  @Input() private xml: any;
  @Input() showExportButtons: boolean = true;
  @Input() showDiagramButtons: boolean = true;
  bpmnData: any;
  @Output() savedBPMNData = new EventEmitter();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    if (!this.showDiagramButtons) {
      this.bpmnJS = new BpmnJS();
    } else {
      this.bpmnJS = new BpmnModeler({
        keyboard: { bindTo: document },
        BpmnPalletteModule,
      });
    }

    /* istanbul ignore next */
    if (this.xml) {
      this.displayXmlDiagram();
    }
    /* istanbul ignore next */
    this.bpmnJS.on('import.done', (err: any) => {
      if (!err.error) {
        this.bpmnJS.get('canvas').zoom('fit-viewport');
      }
    });
  }
  
  displayXmlDiagram() {
    /* istanbul ignore next */
    this.bpmnJS?.importXML(this.xml, (err: any) => {
      if (err) {
        // import failed :-(
      } else {
        // we did well!

        var canvas = this.bpmnJS.get('canvas');
        canvas.zoom('fit-viewport');
      }
    });
  }

  ngAfterContentInit(): void {
    this.bpmnJS.attachTo(this.el.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    // re-import whenever the url changes
    /* istanbul ignore next */
    if (changes?.url) {
      this.loadUrl(changes.url.currentValue);
    }

    /* istanbul ignore next */
    if (changes?.xml) {
      this.displayXmlDiagram();
    }
  }

  ngOnDestroy(): void {
    this.bpmnJS.destroy();
  }

  /**
   * Load diagram from URL and emit completion event
   */
  loadUrl(url: string) {
    return this.http
      .get(url, { responseType: 'text' })
      .pipe(
        catchError((err) => throwError(err)),
        importDiagram(this.bpmnJS)
      )
      .subscribe(
        /* istanbul ignore next */
        (warnings) => {
          this.importDone.emit({
            type: 'success',
            warnings,
          });
        },
        (err) => {
          this.importDone.emit({
            type: 'error',
            error: err,
          });
        }
      );
  }

  onSvgExport() {
    this.bpmnJS.saveSVG((err: any, svg: any) => {
      const a = document.createElement('a');
      document.body?.appendChild(a);
      const blob = new Blob([svg], { type: 'text/plain;charset=utf-8' }),
        url = window.URL.createObjectURL(blob);
      /* istanbul ignore next */
      if (navigator.appVersion.toString().indexOf('.NET') > 0 && window.navigator.msSaveBlob) {
      } else {
        a.href = url;
        a.download = 'diagram' + '.svg';
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }
  /* istanbul ignore next */
  async onBpmnExport() {
    const { xml } = await this.bpmnJS.saveXML({ format: true });

    const a = document.createElement('a');
    document.body?.appendChild(a);
    const blob = new Blob([xml], {
        type: 'data:application/bpmn20-xml;charset=UTF-8',
      }),
      url = window.URL.createObjectURL(blob);
    /* istanbul ignore next */
    if (navigator.appVersion.toString().indexOf('.NET') > 0 && window.navigator.msSaveBlob) {
    } else {
      a.href = url;
      a.download = 'diagram' + '.bpmn';
      const file = new File([blob], a.download, { type: 'data:application/bpmn20-xml;charset=UTF-8' });
      this.bpmnData = file;
      this.savedBPMNData.emit(this.bpmnData);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }
  cancelBPMN(){
    this.savedBPMNData.emit('');
  }
}
