<div class="add-info-wrap">
    <div class="info-items">
      <div class="layertype" *ngIf="!layerflag">
        <div class="mr-4" *ngFor="let layer of layerType">
          <mat-checkbox [(ngModel)]="layer.completed" id="{{ layer?.name }}">
            {{ layer?.name }}
          </mat-checkbox>
        </div>
      </div>
  
      <div class="add-info-item" *ngIf="!selectedentityFlag">
        <h4>{{ labels?.Add_From_Library_Entities }}</h4>
        <form class="example-form">
          <mat-form-field class="example-full-width" appearance="standard">
            <input
              matInput
              placeholder="Search"
              [(ngModel)]="entityName"
              (keyup)="SearchTEntityext(entityName)"
              required
              id="searchBar"
              placeholder=""
              name="entityName"
            />
            <mat-icon matSuffix id="searchIcon">{{ labels?.search }}</mat-icon>
          </mat-form-field>
        </form>
        <div>
          <mat-radio-group class="add-radio-group" aria-label="Select an option">
            <mat-radio-button
              *ngFor="let entity of entityList; let i = index"
              name="entity.name"
              id="{{ 'entity_' + entity?.name?.replaceAll(' ', '_') }}"
              (click)="addEntity(entity, i)"
              (click)="clearSearchText()"
              [value]="entity.name"
              >{{ entity.name }}
            </mat-radio-button>
          </mat-radio-group>
          <div class="alert alert-danger" *ngIf="error">
            <h1>{{ labels?.An_Error_Occured }}</h1>
            <p>{{ error }}</p>
          </div>
        </div>
        <div *ngIf="!selectedentityFlag" id="entityListPaginator">
          <app-paginator
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [totalRecords]="totalEntities"
            (onPageChange)="onPageChange($event)"
          >
          </app-paginator>
        </div>
      </div>
      <div class="add-info-wrap-stepper" *ngIf="selectedentityFlag">
        <mat-vertical-stepper fxLayout="column" [linear]="false" #stepper>
          <mat-step *ngFor="let entity of entityListNew; let entityindex = index">
            <ng-template matStepLabel>
              <span (click)="selectedEntity(entityindex)" id="{{ entity?.name + '_entity' }}">{{ entity?.name }}</span>
            </ng-template>
  
            <ul class="add-etity">
              <li *ngFor="let attr of entity?.nslAttributes; let attrindex = index">
                <input
                  matInput
                  type="text"
                  placeholder="Name Your Attributes"
                  (change)="checkAttrName(attrindex, entityindex)"
                  id="attrInputField"
                  autocomplete="off"
                  [(ngModel)]="attr.name"
                  [(ngModel)]="attr.displayName"
                  [ngClass]="{ alertclass: alertFlag }"
                />
                <span class="existmsg" *ngIf="attrName === attr?.name" id="alertMessage">{{ msg }}</span>
                <div class="ui-controls">
                  <span
                    [class.disabled]="attr.attributeType?.uiElement?.name == '' ? false : true"
                    id="{{ 'dataType_' + attr?.name }}"
                    (click)="showDataTypes(attrindex, entityindex, attr)"
                    ><img [src]="'../../../../../assets/images/ui-icon.png' | CDNUrlRewritePipe" alt=""
                  /></span>
                  <span
                    [class.disabled]="attr.attributeType?.uiElement?.name == '' ? true : false"
                    id="{{ 'dataType_' + attr.name }}"
                    ><img [src]="'../../../../../assets/images/ui-icon.png' | CDNUrlRewritePipe" alt=""
                  /></span>
                  <span
                    *ngIf="attr.attributeType?.uiElement?.name"
                    id="{{ 'uiElement_' + attr.attributeType?.uiElement?.name }}"
                    >{{
                      labels[attr?.attributeType?.uiElement?.name?.replaceAll(' ', '_')]
                        ? labels[attr?.attributeType?.uiElement?.name?.replaceAll(' ', '_')]
                        : attr?.attributeType?.uiElement?.name
                    }}
                  </span>
                  <span
                    *ngIf="!attr.attributeType?.uiElement?.name"
                    id="uiElement"
                    (click)="showDataTypes(attrindex, entityindex, attr)"
                    >{{ labels?.UI_Controls }}</span
                  >
                </div>
                <div class="del-wrap" *ngIf="attr.attributeType?.uiElement?.name && attr?.name && attr?.editable">
                  <span (click)="editAttribute(attrindex, entityindex, attr)" id="{{ 'Edit_' + attr?.name }}"
                    ><mat-icon>edit</mat-icon> {{ labels?.Edit }}</span
                  >
                  <span (click)="deleteAttribute(attrindex, entityindex)" id="{{ 'delete_' + attr?.name }}"
                    ><mat-icon>delete</mat-icon> {{ labels?.Delete }}</span
                  >
                </div>
              </li>
            </ul>
            <span
              class="d-flex align-items-center mb-4 curser-pointer"
              (click)="addAttribute(entityindex)"
              id="add_attribute"
              ><mat-icon>add_circle</mat-icon>{{ labels?.Add_Another_Attribute }}</span
            >
          </mat-step>
        </mat-vertical-stepper>
        <ul class="add-another">
          <li (click)="addAnEntity()" *ngIf="!addAnEntityFlag" id="add_another_entity">
            <mat-icon>add_circle</mat-icon>{{ labels?.Add_Another_Entity_from_Library }}
          </li>
        </ul>
  
        <div class="add-info-item" *ngIf="addAnEntityFlag" class="addanotherentity">
          <h4>{{ labels?.Add_From_Library_Entities }}</h4>
          <form class="example-form">
            <mat-form-field class="example-full-width" appearance="standard">
              <input
                matInput
                placeholder="Search"
                [(ngModel)]="entityName"
                (keyup)="SearchTEntityext(entityName)"
                id="searchBar"
                required
                placeholder="search"
                name="entityName"
              />
              <mat-icon matSuffix id="searchIcon">{{ labels?.search }}</mat-icon>
            </mat-form-field>
          </form>
          <div>
            <mat-radio-group class="add-radio-group" aria-label="Select an option">
              <mat-radio-button
                *ngFor="let entity of entityList; let i = index"
                name="entity"
                id="{{ 'entity_' + entity?.name }}"
                (click)="addEntity(entity, i)"
                (click)="clearSearchText()"
                (click)="addAnEntity()"
                [value]="entity.name"
                >{{ entity?.name }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="addAnEntityFlag" id="entityList_Paginator">
            <app-paginator
              [pageIndex]="pageIndex"
              [pageSize]="pageSize"
              [totalRecords]="totalEntities"
              (onPageChange)="onPageChange($event)"
            >
            </app-paginator>
          </div>
        </div>
      </div>
    </div>
  
    <div class="info-items left-bdr" *ngIf="uiControls && selectUI">
      <p><em class="arrow right"></em></p>
      <h5>{{ labels?.UI_Controls }}</h5>
      <form class="example-form">
        <mat-form-field class="example-full-width" appearance="standard">
          <input
            matInput
            placeholder="Search"
            [(ngModel)]="attrType"
            (keyup)="searchTextAttrData(attrType)"
            id="attrSearch"
            required
            placeholder="search"
            name="attrType"
          />
          <mat-icon matSuffix id="attrSearchIcon">{{ labels?.search }}</mat-icon>
        </mat-form-field>
      </form>
      <mat-option *ngFor="let dataType of uiControls" [value]="dataType.name">
        <mat-radio-button
          (click)="selectUiControl(dataType)"
          (click)="clearSearchText()"
          name=""
          [value]=""
          [checked]="attrDataTypeName == dataType.name ? true : false"
        >
          <span id="{{ 'uiControl_' + dataType }}">{{ dataType?.name }}</span>
        </mat-radio-button>
      </mat-option>
      <div class="ui-controls-pop three" *ngIf="openDefaultVlaue">
        <form class="example-form" [formGroup]="attrDetails">
          <div *ngIf="!isInEventSelection && attrDetails.value.attributeUiElement != 'autoIdentifier'">
            <ng-container
              *ngIf="
                isEditAttrFromEntityPanel &&
                attrDetails?.value?.attributeUiElement != 'scheduler' &&
                attrDetails?.value?.attributeUiElement != 'location'
              "
            >
              <input
                matInput
                class="add-value w-100"
                placeholder="Add Options"
                id="inputData"
                type="text"
                autocomplete="off"
                formControlName="defaultOption"
                *ngIf="multiValue"
              />
              <div class="add-icon my-2" *ngIf="multiValue" id="addInputData" (click)="addDefaultValuetoAttibute()">
                Add
              </div>
              <div *ngIf="sourceValues && multiValue">
                <div class="d-flex added" *ngFor="let attrvalue of sourceValues; let i = index">
                  {{ attrvalue.DATA?.displayValue }}
                  <span class="pointer"
                    ><img
                      (click)="removeScourceValue(i)"
                      id="attrremoveimg"
                      [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                      width="100"
                      height="15"
                      alt=""
                  /></span>
                </div>
              </div>
              <div
                class="expected-time"
                *ngIf="
                  attrDetails?.value?.attributeUiElement != 'file' &&
                  attrDetails?.value?.attributeUiElement != 'image' &&
                  attrDetails?.value?.attributeUiElement != 'audio' &&
                  attrDetails?.value?.attributeUiElement != 'video' &&
                  attrDetails?.value?.attributeUiElement != 'Password'
                "
              >
                <mat-form-field appearance="standard">
                  <mat-label>{{ labels.Default_Value }}</mat-label>
                  <input
                    matInput
                    placeholder=""
                    name="defaultValue"
                    id="defaultValue"
                    type="text"
                    name="defaultValue"
                    autocomplete="off"
                    formControlName="defaultValue"
                  />
                </mat-form-field>
              </div>
              <div class="custom-radio-btns multi-file-upload">
                <div class="multi-value">
                  <div class="label-txt">{{ labels?.Multi_Value }}</div>
                  <div>
                    <mat-radio-group fxLayout="column" formControlName="isMultiValue">
                      <mat-radio-button name="isMultiValue" id="attribute_multiYes" [value]="true">{{
                        labels?.Yes
                      }}</mat-radio-button>
                      <mat-radio-button name="isMultiValue" id="attribute_multiNo" [value]="false">{{
                        labels?.No
                      }}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div
                  *ngIf="
                    attrDetails?.value?.attributeUiElement == 'file' ||
                    attrDetails?.value?.attributeUiElement == 'image' ||
                    attrDetails?.value?.attributeUiElement == 'audio' ||
                    attrDetails?.value?.attributeUiElement == 'video'
                  "
                >
                  {{ attrDetails.value.attributeType }}
                  <app-file-upload-transaction
                    [fileUploadFromEntity]="true"
                    [editFileUrl]="editFileUrl"
                    (fileUploadResponse)="fileUploadUrl($event)"
                  >
                  </app-file-upload-transaction>
                </div>
              </div>
              <div
                class="expected-time"
                *ngIf="
                  attrDetails?.value?.attributeUiElement == 'currency' ||
                  attrDetails?.value?.attributeUiElement == 'metric'
                "
              >
                <label>{{ labels.Type }}</label>
                <input
                  matInput
                  [placeholder]="
                    attrDetails?.value?.attributeUiElement == 'currency' ? labels.Currency_Type : labels.Metric_Type
                  "
                  id="defaultValue"
                  type="text"
                  autocomplete="off"
                  formControlName="unitType"
                />
              </div>
            </ng-container>
          </div>
  
          <div class="formatWrap auto-formfields" *ngIf="attrDetails.value.attributeUiElement === 'autoIdentifier'">
            <div class="labelwraps">
              <mat-label (click)="addField('format')">{{ labels?.Format }}</mat-label>
              <mat-label (click)="addField('date')">date</mat-label>
              <mat-label (click)="addField('separator')">Separator</mat-label>
              <mat-label (click)="addField('increment')">increment</mat-label>
              <mat-label (click)="addField('attribute')">attribute</mat-label>
            </div>
            <div
              cdkDropList
              [cdkDropListData]="attrDetails.get('formatFields')['controls']"
              class="example-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div
                formArrayName="formatFields"
                *ngFor="let field of attrDetails.get('formatFields')['controls']; let i = index"
                class="example-box"
                cdkDrag
              >
                <div [formGroupName]="i">
                  <div *ngIf="field.value.type === 'format'" class="autofield-flex">
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.Format }}</mat-label>
                      <input matInput placeholder="Format" formControlName="format" type="text" autocomplete="off" />
                    </mat-form-field>
                    <mat-icon cdkDragHandle>reorder</mat-icon>
                    <mat-icon (click)="removeField(i)">clear</mat-icon>
                  </div>
                  <div *ngIf="field.value.type === 'date'" class="autofield-flex">
                    <mat-form-field>
                      <mat-label>{{ labels?.Choose_a_date_format }}</mat-label>
                      <mat-select formControlName="dateFormat" (selectionChange)="checkYearFormat()">
                        <mat-option *ngFor="let date of dateFormats" [value]="date">
                          {{ date }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-icon cdkDragHandle>reorder</mat-icon>
                    <mat-icon (click)="removeField(i)">clear</mat-icon>
                  </div>
                  <div *ngIf="field.value.type === 'attribute'">
                    <span *ngIf="field?.controls?.entAttributeName?.value">
                      {{ field.controls.entAttributeName.value }}
                      <span class="pointer"
                        ><img
                          (click)="removeAttributeSelection(field)"
                          [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                          alt=""
                      /></span>
                    </span>
                    <div class="attributeflex-items">
                      <div class="autofield-flex">
                        <mat-form-field class="">
                          <mat-select formControlName="entAttributeString">
                            <mat-option *ngFor="let string of attributeNameOptions" [value]="string.value">
                              {{ string.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
  
                        <mat-form-field *ngIf="field.value.entAttributeString !== ''">
                          <input
                            matInput
                            placeholder="Value"
                            formControlName="entAttributeStringValue"
                            type="text"
                            autocomplete="off"
                          />
                        </mat-form-field>
                        <mat-icon cdkDragHandle>reorder</mat-icon>
                        <mat-icon (click)="removeField(i)">clear</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="field.value.type === 'separator' && separatingOperatorSelected">
                    {{ selectorSeparated }}
                    <mat-icon cdkDragHandle>reorder</mat-icon>
                    <mat-icon (click)="removeField(i)">clear</mat-icon>
                  </div>
                  <div *ngIf="field.value.type === 'increment'">
                    INCREMENT_ID
                    <mat-icon cdkDragHandle>reorder</mat-icon>
                    <mat-icon (click)="removeField(i)">clear</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="autoFormFields">
              <mat-form-field *ngIf="getFinancialMonth">
                <mat-label>Financial Month</mat-label>
                <mat-select formControlName="financialMonth">
                  <mat-option *ngFor="let month of Months" [value]="month.value">
                    {{ month.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="isIncrementValue">
                <mat-form-field floatLabel="never">
                  <mat-label>{{ labels?.Max_Value }}</mat-label>
                  <input
                    matInput
                    placeholder="Placeholder"
                    id="maxValue"
                    type="number"
                    autocomplete="off"
                    formControlName="maxValue"
                  />
                </mat-form-field>
                <mat-form-field floatLabel="never">
                  <mat-label>Count Start Parameter</mat-label>
                  <input
                    matInput
                    placeholder="Placeholder"
                    id="maxValue"
                    type="number"
                    autocomplete="off"
                    formControlName="countStartParameter"
                  />
                </mat-form-field>
                <mat-form-field floatLabel="never">
                  <mat-label>ID Leading Zeros</mat-label>
                  <input
                    matInput
                    placeholder="Placeholder"
                    id="maxValue"
                    type="number"
                    autocomplete="off"
                    formControlName="incIdLeadingZeroes"
                  />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>{{ labels?.Count_Reset_Parameter }}</mat-label>
                  <mat-select formControlName="countReset">
                    <mat-option *ngFor="let countReset of countResetParams" [value]="countReset">
                      {{ countReset }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="autoform-operators">
              <div class="formatWrapSeparators operators-list">
                <div *ngFor="let separator of separators" class="operator-circle" (click)="selectSeparator(separator)">
                  <span id="selectDcdOperator">{{ separator }}</span>
                </div>
              </div>
              <div *ngIf="separatingOperatorSelected" class="seletedseparator">
                {{ labels?.Separator }} : {{ selectorSeparated }}
              </div>
            </div>
          </div>
  
          <div class="schedul-wrap" *ngIf="attrDetails?.value.attributeUiElement === 'location'">
            <mat-form-field>
              <mat-label>{{ labels?.Location_Type }}</mat-label>
              <mat-select formControlName="locationType">
                <mat-option *ngFor="let option of locationArray" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <div class="select-ui-control-leftpanel select-valr" *ngIf="isEditAttrFromEntityPanel">
          <mat-form-field
            class="mb-4"
            *ngIf="
              attrDetails?.value?.attributeUiElement != 'scheduler' &&
              attrDetails?.value?.attributeUiElement != 'location'
            "
          >
            <mat-label>{{ labels?.Select_Validators }}</mat-label>
            <mat-select id="validatorsSelectDropdown" [(ngModel)]="selectedUiValidator">
              <mat-option
                *ngFor="let validators of UiValidatorDropdown?.constraints; let i = index"
                [value]="validators.name"
                [class.disabled]="validators.disabled"
                id="validatorsSelected_{{ validators.name }}"
                (click)="validatorsChange(validators, i)"
              >
                {{ validators.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Optional Change drivers starts here-->
          <mat-form-field class="mb-4" *ngIf="attrDetails?.value?.attributeUiElement != 'scheduler'">
            <mat-label>{{ labels?.Change_Driver_Type }}</mat-label>
            <mat-select [(ngModel)]="selectedChangedriverOpt">
              <mat-option *ngFor="let opt of optChangeDrivers; let i = index" [value]="opt">
                {{ opt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="mb-4"
            *ngIf="attrDetails?.value?.attributeUiElement != 'scheduler' && selectedChangedriverOpt == 'ALTERNATIVE'"
          >
            <mat-label>{{ labels?.Alternative_Attribute }}</mat-label>
            <mat-select [(ngModel)]="selectedAlternateAttr">
              <mat-option
                *ngFor="let attr of entityListNew[entityIndex].nslAttributes; let i = index"
                [value]="attr.name"
              >
                <span *ngIf="attr.name != currentAttrName">{{ attr.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Optional Change drivers ends here-->
          <!-- form for scheduler ui-control -->
          <form
            class="schedul-wrap"
            [formGroup]="schedulerDetails"
            *ngIf="attrDetails?.value?.attributeType === 'scheduler'"
          >
            <mat-form-field>
              <mat-label>{{ labels?.interval }}</mat-label>
              <mat-select
                formControlName="interval"
                id="schedularIntervalSelect"
                (selectionChange)="onSelectionChange($event)"
              >
                <mat-option *ngFor="let option of intervalOptions" [value]="option" id="schedular_{{ option }}">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="mr-25">
              <mat-form-field>
                <mat-label>{{ labels?.Start_Date_Time }}</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker" formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [stepSecond]="1"
                  [touchUi]="false"
                  [color]="'primary'"
                  [enableMeridian]="false"
                  [disableMinute]="false"
                  [hideTime]="false"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-label>{{ labels?.End_Date_Time }}</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker1" formControlName="endDate" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker1
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [stepHour]="1"
                  [stepMinute]="1"
                  [stepSecond]="1"
                  [touchUi]="false"
                  [color]="'primary'"
                  [enableMeridian]="false"
                  [disableMinute]="false"
                  [hideTime]="false"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <mat-form-field [hidden]="!isTime">
              <mat-label>{{ labels?.select_Time }}</mat-label>
              <p class="timedisplay">{{ timeFormat | date: 'HH:mm' }}</p>
              <input
                style="display: none;"
                matInput
                [ngxMatDatetimePicker]="picker2"
                [(ngModel)]="timeFormat"
                formControlName="time"
                required
              />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #picker2
                [showSpinners]="true"
                [showSeconds]="false"
                [stepHour]="1"
                [stepMinute]="1"
                [stepSecond]="1"
                [touchUi]="false"
                [color]="'primary'"
                [enableMeridian]="false"
                [disableMinute]="false"
                [hideTime]="false"
                (dateInput)="updateNewTimeFormat($event)"
                (dateChange)="updateNewTimeFormat($event)"
                panelClass="hide-calendar  d-none"
              >
              </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field [hidden]="!isDay">
              <mat-label>{{ labels?.Day }}</mat-label>
              <div>
                <input
                  matInput
                  placeholder="{{ labels?.Enter_Value }}"
                  id="formatDay"
                  type="text"
                  autocomplete="off"
                  formControlName="day"
                />
              </div>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ labels?.incrementvalue }}</mat-label>
              <input
                matInput
                placeholder="{{ labels?.Enter_Value }}"
                id="formatSchedular"
                type="number"
                autocomplete="off"
                formControlName="incrementValue"
              />
            </mat-form-field>
            <div class="orline">or</div>
            <mat-form-field class="w-100">
              <mat-label>{{ labels?.recurrenceExpression }}</mat-label>
              <input
                matInput
                placeholder="{{ labels?.Enter_Value }}"
                id="formatRecurrenceExpression"
                type="text"
                autocomplete="off"
                formControlName="recurrenceExpression"
              />
            </mat-form-field>
          </form>
          <!-- scheduler ends here -->
  
          <ng-container *ngIf="selectedValidatorsList && selectedValidatorsList.length">
            <div *ngFor="let selected of selectedValidatorsList; let j = index" class="selected-validate expected-time">
              <div class="labelbtn">
                <label>{{ selected.displayName }}</label>
                <button (click)="removeValidator(selected, j)">remove</button>
              </div>
              <div *ngFor="let value of selected?.properties" class="selected-validate-input">
                <!-- {{value | json}} -->
                <input
                  [placeholder]="value.displayName"
                  [id]="value.name"
                  type="text"
                  [value]="value.message"
                  [(ngModel)]="value.message"
                  autocomplete="off"
                />
              </div>
            </div>
          </ng-container>
        </div>
        <div class="d-flex justify-contect-space-between my-3">
          <button mat-button class="add-btn" id="add_btn" (click)="addDefaultValue()">{{ labels?.Save }}</button>
        </div>
      </div>
    </div>
    <div class="btns-wrap" *ngIf="nextIcon">
      <button mat-button mat-dialog-close id="close"><mat-icon>close</mat-icon> {{ labels?.Cancel }}</button>
      <button mat-button mat-dialog-close (click)="submitDefaultValues()" id="play_arrow">
        <mat-icon>play_arrow</mat-icon>{{ labels?.Next }}
      </button>
    </div>
  </div>
  