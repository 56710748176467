import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EventsFacadeService, FieldConfig, getUiControlCustomization } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
@Component({
  selector: 'app-date-range',
  styleUrls: ['./date-range.component.scss'],
  template: `
    <form class="demo-full-width date-range" [formGroup]="group">
      <div class="d-flex align-items-center nh-mb-8">
        <label
          class="main-label nh-mb-0"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          ></label
        >
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>

        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <div
        class="date-range-wrapper"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            field?.validations?.length == 0 &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
      >
        <p-calendar
          class="{{ appliedClass }}"
          #dateRangeCalender
          [formControlName]="field.attribute.name"
          (onSelect)="onDateChange($event)"
          [showIcon]="true"
          appendTo="body"
          selectionMode="range"
          [readonlyInput]="true"
          inputId="range"
          [showButtonBar]="foundObject?.event == 'Option 3' ? true : false"
          [appendTo]="bodyAppend"
          [monthNavigator]="true"
          dateFormat="dd-mm-yy"
          panelStyleClass="daterange-picker-popup"
        >
          <p-footer style="float: right;">
            <button pButton type="button" label="Done" (click)="close(); $event.stopPropagation()"></button>
          </p-footer>
        </p-calendar>
        <svg *ngIf="!foundObject?.event || foundObject?.event == ''" class="date-range-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g id="Group_104425" data-name="Group 104425" transform="translate(-679 -222)">
            <rect
              id="Rectangle_38766"
              data-name="Rectangle 38766"
              width="24"
              height="24"
              transform="translate(679 222)"
              fill="#333"
              opacity="0"
            />
            <g id="Group_98139" data-name="Group 98139" transform="translate(680.5 222.732)">
              <path
                id="Path_101798"
                data-name="Path 101798"
                d="M23.489,3.777h15.75a3.146,3.146,0,0,1,3.125,3.171v16a3.146,3.146,0,0,1-3.121,3.167H23.489a3.146,3.146,0,0,1-3.125-3.171v-16a3.146,3.146,0,0,1,3.121-3.167Zm0,21.335H39.239a2.147,2.147,0,0,0,2.125-2.163v-16a2.147,2.147,0,0,0-2.129-2.167H23.491A2.147,2.147,0,0,0,21.364,6.94v16a2.147,2.147,0,0,0,2.129,2.167Z"
                transform="translate(-20.864 -2.844)"
                fill="#333"
              />
              <path
                id="Line_5451"
                data-name="Line 5451"
                d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
                transform="translate(15.75)"
                fill="#333"
              />
              <path
                id="Line_5452"
                data-name="Line 5452"
                d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
                transform="translate(5.25)"
                fill="#333"
              />
              <path
                id="Line_5453"
                data-name="Line 5453"
                d="M20.25.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H20.25a.5.5,0,0,1,.5.5A.5.5,0,0,1,20.25.5Z"
                transform="translate(0.375 7.504)"
                fill="#333"
              />
            </g>
          </g>
        </svg>
      </div>
      <mat-icon
        matTooltip="Sub-Transactional CU"
        matSuffix
        class="d-flex align-items-center justify-content-center"
        *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        ><span class="sub-cu" (click)="openSubPopUp()"
          ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
        /></span>
      </mat-icon>
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        id="daterangeAddAttr_{{ field.attribute.name }}"
        (click)="addAttribute.next()"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.attribute['isTableConfig']"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        id="daterangeAddAttr_{{ field.attribute.name }}"
        (click)="removeAttribute.next()"
        >remove</mat-icon
      >
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error *ngIf="validation.type && validation.type === 'error' && getErrorMessage(field, validation)">{{
          validation.message
        }}</mat-error>
        <mat-error
          [ngClass]="validation.type == 'info' ? 'infocolor' : 'warncolor'"
          *ngIf="validation.type && validation.type !== 'error' && getInfoWarnMessage(field, validation)"
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </form>
  `,
  styles: [],
})
export class DateRangeComponent implements OnInit {
  @ViewChild('dateRangeCalender') datePicker: any;

  field: FieldConfig;
  dash: string = '';
  group: FormGroup;
  requiredFormat: string = 'MM/dd/yyyy';
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  dateChanged: Subject<any> = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  range = new FormGroup({
    begin: new FormControl(),
    end: new FormControl(),
  });
  foundObject: any;
  bodyAppend: string = 'body';
  appliedClass: string = 'ui-custom-daterange-input date-range-touch-issue';

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.field.value = this.field.value ? JSON.parse(this.field.value) : '';
    /* istanbul ignore if  */
    if (this.field.value) {
      if (this.IsJsonString(this.field.value)) {
        let jsonObj: any = JSON.parse(this.field.value).split(',');
        if (jsonObj) {
          // this.field.value.begin = new Date(jsonObj.begin);
          // this.field.value.end = new Date(jsonObj.end);
          this.group?.get(String(this.field.attribute.name)).setValue(new Date(this.field.value));
          this.dash = '-';
        }
      } else {
        // this.field.value.begin = new Date(this.field.value.begin);
        // this.field.value.end = new Date(this.field.value.end);
        let values: any = this.field.value;
        if (typeof this.field.value === 'string') {
          values = this.field.value.split(',') as Array<string>;
        }
        const da = values.map((m: any) => new Date(m));
        this.group?.get(String(this.field.attribute.name)).setValue(da);
        this.dash = '-';
      }
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.dateTimeFormat?.format) {
      /* istanbul ignore next */
      this.requiredFormat = this.field?.configuration?.dateTimeFormat?.format;
    } else if (this.field?.attributeType?.properties?.format) {
      /* istanbul ignore next */
      this.requiredFormat = this.field?.attributeType?.properties?.format;
    }
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    this.group.setValidators(this.validateInput());
    let dateOption = getUiControlCustomization('DatePicker');
    if (dateOption) {
      this.foundObject = { event: dateOption };
      let op = dateOption.slice(-1);
      /* istanbul ignore else */
      if (dateOption == 'Option 7') {
        op = 1;
      }
      this.appliedClass = `form-custom-date form-custom-date-opt${op}`;
    }
    let mccPopup = sessionStorage.getItem('template');
    this.bodyAppend = mccPopup?.includes('cupopup')
      ? (this.field.attribute['isTableConfig'] ? 'body' : '')
      : !this.foundObject?.event || this.foundObject?.event == ''
      ? 'body'
      : '';
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /* istanbul ignore next */
  close() {
    this.datePicker.hideOverlay();
    this.datePicker.overlayVisible = false;
    this.datePicker.datepickerClick = true;
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      /* istanbul ignore next */
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  /**
   * Determines whether selection change on
   * @param event holds the selected date range value
   */
  onDateChange(event: any) {
    if (
      Array.isArray(this.group?.controls[this.field?.attribute?.name]?.value) &&
      this.group?.controls[this.field?.attribute?.name]?.value[0] &&
      this.group?.controls[this.field?.attribute?.name]?.value[1] &&
      !this.field.isHidden
    ) {
      this.dateChanged.next();
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /*istanbul ignore else*/
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
    }
  }

  validateInput(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      let attrName = this?.field?.attribute?.name;
      const dateRangeControl = group?.controls[attrName];
      if (Array.isArray(dateRangeControl?.value) && (!dateRangeControl.value[0] || !dateRangeControl.value[1]))
        group?.controls[attrName].setErrors({ bothDateNotGiven: true });
      return;
    };
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
}
