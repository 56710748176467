import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Attribute, AugumentedRealityEndpointService, CrossDomainMessagingService, emptyObject, Entity, EntityFacadeService, generateARId, NodeGsiFacadeService, TranslatorService, updateArId
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-ar-entity',
  templateUrl: './ar-entity.component.html',
  styleUrls: ['./ar-entity.component.scss'],
})
export class ArEntityComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  labels: any;
  selectedChangedriverOptCu: any = 'HIDDEN';
  optChangeDrivers: any = ['OPTIONAL', 'HIDDEN', 'NONE'];
  entityObj: any = { ...new Entity() };
  attributeTypes: any = [
    {
      name: 'File',
      dataType: 'file',
      esDataType: 'string',
      uiElement: 'file',
      isMulti: false,
      properties: [],
    },
  ];
  fileUrl: any;
  allDefaultValues: any[] = [];
  defaultText: any;
  gsiData: any;
  cuData: any;
  arID: any;
  trainData: any;
  constructor(
    private translator: TranslatorService,
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private entityFacadeService: EntityFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private crossDomainMessagingService : CrossDomainMessagingService,
    private arService: AugumentedRealityEndpointService,
  ) {
    this.detectLanguageChange();
    this.detectSaveEntity();
    this.detectGsiData();
    this.getStoreArData();
  }

  ngOnInit(): void {
    this.entityObj.isReserved = false;
    this.entityObj.isMultiValue = false;
    this.addNewAttribute();
    /* istanbul ignore next */
    if (this.trainData && !emptyObject(this.trainData) && this.gsiData && !emptyObject(this.gsiData)) {
      if (this.trainData?.gsi_json.id != this.gsiData?.id) {
        this.gsiFacade.setStoreTrainData({});
      } else {
        this.arID = this.trainData?.ar_id;
        let tempGSIObj = JSON.parse(JSON.stringify(this.gsiData));
        if (tempGSIObj?.id == this.trainData?.gsi_json?.id) {
          tempGSIObj?.solutionLogic?.forEach((cu: any, cuIndex: any) => {
            let index = this.trainData?.gsi_json?.solutionLogic?.findIndex(
              (trainedCu: any) => trainedCu?.masterId == cu?.masterId
            );
            if (index != -1) {
              this.cuData = this.trainData?.gsi_json?.solutionLogic[index];
            }
          });
        }
      }
    }
  }
  getStoreArData() {
    this.gsiFacade.storeTrainData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && !emptyObject(res)) {
        /* istanbul ignore next */
        this.trainData = res;
        if (res?.ar_id) {
          this.arID = res.ar_id;
        }
      }
    });
  }
  detectGsiData() {
    this.arService.openArWindow$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      let arData = this.crossDomainMessagingService.getData();
      if(arData){
        res = arData;
       }
      if (res) {
        this.gsiData = res?.gsiData;
        this.cuData = res?.cuData;
        if (res?.cuData?.uiProperties?.ar_id) {
          this.arID = res?.cuData?.uiProperties?.ar_id;
        } else {
          this.arID = generateARId(this.gsiData?.id);
        }
        /* istanbul ignore next */
        if (this.gsiData && updateArId(this.gsiData)) {
          this.arID = updateArId(this.gsiData);
        }
      }
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  deleteAttribute(index: number) {
    this.entityObj.nslAttributes.splice(index, 1);
  }
  addNewAttribute() {
    let attr = { ...new Attribute() } as any;
    attr.name = 'Object' + `${this.entityObj?.nslAttributes?.length + 1}`;
    attr.isMultiValue = false;
    attr.attributeType.uiElement = this.attributeTypes[0];
    attr.attributeType.type = this.attributeTypes[0]?.dataType;
    this.entityObj.nslAttributes.push(attr);
  }
  fileUploadUrl(event: any, i: number, attribute: any) {
    this.fileUrl = event;
    this.allDefaultValues.push(JSON.stringify(this.fileUrl));
    /* istanbul ignore next */
    if (attribute?.isMultiValue) {
      attribute.defaultValue = JSON.stringify(this.allDefaultValues);
    } else {
      attribute.defaultValue = JSON.stringify(this.fileUrl);
    }
  }
  closeArEntity() {
    this.changeUnitFacade.setArEntity({ flag: false });
  }
  saveEntity() {
    /* istanbul ignore next */
    this.entityObj?.nslAttributes?.forEach((attr: any) => {
      /* istanbul ignore next */
      if (attr?.isMultiValue) {
        const dataType = 'entity';
        attr.attributeType.type = 'list';
        attr.attributeType.nestedNslDataTypeProperties = { item_type: { type: dataType } };
      }
      delete attr.isEntMultiValue;
    });
    this.entityFacadeService.saveEntity(this.entityObj);
  }
  // saving the AR changeunit with given payload
  saveARCU() {
    let tempGSIObj = JSON.parse(JSON.stringify(this.gsiData));
    /* istanbul ignore next */
    if (tempGSIObj?.id == this.trainData?.gsi_json?.id) {
      tempGSIObj?.solutionLogic?.forEach((cu: any, cuIndex: any) => {
        /* istanbul ignore next */
        let index = this.trainData?.gsi_json?.solutionLogic?.findIndex(
          (trainedCu: any) => trainedCu?.masterId == cu?.masterId
        );
        /* istanbul ignore next */
        if (index != -1) {
          tempGSIObj.solutionLogic[cuIndex] = this.trainData?.gsi_json?.solutionLogic[index];
        }
      });
    }
    let tempARCUObj = { ...this.cuData, uiProperties: { ar_id: this.arID } };
    this.cuData = { ...this.cuData, uiProperties: { ar_id: this.arID } };
    /*istanbul ignore next*/
    let index = tempGSIObj?.solutionLogic?.findIndex((cu: any) => this.cuData.id === cu.id);
    /* istanbul ignore next */
    if (index != -1) {
      tempGSIObj.solutionLogic[index] = tempARCUObj;
      this.gsiData.solutionLogic[index].uiProperties = { ar_id: this.arID };
    }
    let payload = {
      ar_id: this.arID,
      gsi_json: tempGSIObj,
    };
    this.gsiFacade.setStoreTrainData(payload);
    this.closeArWindow();
  }
  detectSaveEntity() {
    this.entityFacadeService.saveEntityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next */
      if (res) {
        this.entityObj = JSON.parse(JSON.stringify(res));
        this.entityObj.uiProperties = { ar_id: this.arID };
        this.entityObj['isVirtualEntity'] = true;
        this.entityObj['isArEntity'] = true;
        /* istanbul ignore next */
        this.entityObj = {
          ...this.entityObj,
          changeDriverMetaData: {
            changeDriverTypes: this.selectedChangedriverOptCu,
          },
        };
        this.entityObj?.nslAttributes?.forEach((attr: any) => {
          attr['isArObjectFile'] = true;
        });
        /* istanbul ignore next */
        let entityIndex = this.cuData?.layers[0]?.participatingItems?.findIndex(
          (ent: any) => ent?.id === this.entityObj?.id
        );
        /* istanbul ignore next */
        if (entityIndex != -1) {
          this.cuData.layers[0].participatingItems[entityIndex] = this.entityObj;
        } else {
          this.cuData.layers[0].participatingItems.push(this.entityObj);
        }
        /* istanbul ignore next */
        let index = this.gsiData?.solutionLogic?.findIndex((cu: any) => this.cuData?.id === cu?.id);
        /* istanbul ignore next */
        if (index != -1) {
          this.gsiData.solutionLogic[index] = JSON.parse(JSON.stringify(this.cuData));
        } else {
          console.log('INDEX IS -1');
        }
        this.saveARCU();
      }
    });
  }
  closeArWindow() {
    this.changeUnitFacade.setArEntity({ flag: false });
    this.arService.closeArPanel({ window: false, gsiData: this.gsiData, cuData: this.cuData });
    this.arService.setArData('');
  }
  onChangeOption() {
    if (this.selectedChangedriverOptCu !== 'NONE') {
      this.entityObj.changeDriverMetaData = {
        changeDriverTypes: this.selectedChangedriverOptCu,
      };
    } else {
      /*istanbul ignore else */
      if (this.entityObj?.changeDriverMetaData) {
        delete this.entityObj.changeDriverMetaData;
      }
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
