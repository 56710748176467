import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/internal/operators/pluck';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { getHostUrl, getValueFromArrayOfObjectById } from '../../generic-folder/generic-functions';

@Injectable({
  providedIn: 'root',
})
export class DeveloperLessDevelopmentEndpointService extends CduiBaseService {
  constructor(private http: HttpClient, private injector: Injector) {
    super(injector);
  }
  private temp: any = null;
  getCuRecommendation(baseObject: any, isNested?: any, entities?: any) {
    if (isNested) {
      this.temp = {
        book: baseObject.bookName,
        chapter: baseObject?.chapter == undefined ? '' : baseObject?.chapter,
        gsi: baseObject.gsiName,
        cu: baseObject.cus,
        activeCuIndex: baseObject.actualCuIndex && baseObject.cus.length > 0 ? baseObject.actualCuIndex : undefined,
        hostPortURL: getHostUrl(),
        isNested: isNested,
        entities: entities,
      };
    } else {
      this.temp = {
        gsi: baseObject.gsiName,
        selectedCUList: baseObject.cus.length > 0 ? baseObject.cus : undefined,
        hostPortURL: getHostUrl(),
        activeCuIndex: baseObject.actualCuIndex && baseObject.cus.length > 0 ? baseObject.actualCuIndex : undefined,
      };
    }
    const body = this.temp;
    if (this.temp.activeCuIndex === 0 || !this.temp.activeCuIndex) {
      this.temp.activeCuIndex = baseObject?.cus?.length;
    }
    return this.http.post(this.api?.dldCU, body);
  }
  getNestedCuRecommendations(baseObject: any, isNested?: any, entities?: any) {
    this.temp = {
      book: baseObject?.bookName == undefined ? '' : baseObject?.bookName,
      chapter: baseObject?.chapter == undefined ? '' : baseObject?.chapter,
      gsi: [baseObject.gsiName],
      // cu: baseObject.cus,
      cu: [getValueFromArrayOfObjectById('name', 'index', baseObject?.actualCuIndex, baseObject?.cus)],
      // activeCuIndex: baseObject?.actualCuIndex,
      hostPortURL: getHostUrl(),
      isNested: isNested,
      entities: entities,
    };
    const body = this.temp;

    return this.http.post(this.api?.dldNestedCU, body);
  }
  getExceptionCuRecommendations(baseObject: any, isException?: any, entities?: any) {
    /*istanbul ignore next*/
    this.temp = {
      hostPortURL: getHostUrl(),
      book: baseObject?.bookName == undefined ? '' : baseObject?.bookName,
      gsi: baseObject.gsiName,
      // cu: baseObject.cus,
      cu: getValueFromArrayOfObjectById('name', 'index', baseObject?.actualCuIndex, baseObject?.cus),
      // activeCuIndex: baseObject?.actualCuIndex,

      isException: isException,
    };
    const body = this.temp;

    return this.http.post(this.api?.dldExceptionCU, body);
  }

  getEntityRecommendation(gsi: any, cu: any, selectedEntityList: any) {
    const body = {
      gsi: gsi,
      cu: cu === '' ? undefined : cu,
      selectedEntityList: selectedEntityList,
    };
    return this.http.post(this.api?.dldEntity, body);
  }

  getAttributeRecommendation(gsi: any, cu: any, entity: any, attributeList: any) {
    const body = {
      gsi: gsi,
      cu: cu,
      entity: entity,
      selectedAttributesList: attributeList,
    };
    return this.http.post(this.api?.dldAttribute, body);
  }
  getGsiRecommendations(bookName: any) {
    const body = { book: bookName };
    return this.http.post(this.api?.dldGsi, body);
  }
  getCuRecommendationForSeletedGsi(bookName: any, gsiList: any, hostUrl?: any) {
    const body = hostUrl ? { book: bookName, gsi: gsiList, hostPortURL: hostUrl } : { book: bookName, gsi: gsiList };
    return this.http.post(this.api?.dldGsiFull, body);
  }
  getRolesRecommendations(bookName: any, gsiName: any, hostPortURL: any, selectedRoleList?: any) {
    let body: any = { book: bookName, gsi: gsiName, hostPortURL: hostPortURL };
    if (selectedRoleList) {
      body['selectedRoleList'] = selectedRoleList;
    }
    return this.http.post(this.api?.dldGsiRoles, body);
  }
  getDLDAgentRecommendationsForCU(data: any) {
    return this.http.post(this.api?.dldCURoles, data);
  }
  getDLDAgentRecommendationsForEntity(data: any) {
    return this.http.post(this.api?.dldEntityRoles, data);
  }
  getDLDAgentRecommendationsForAttribute(data: any) {
    return this.http.post(this.api?.dldAttributeRoles, data);
  }
  saveGSICompositeAPI(gsiData: any) {
    return this.http.post(this.api?.saveCompositeGsi, gsiData).pipe(pluck('result'));
  }
  getDCDFromDLDByEntity(data: any) {
    return this.http.post(this.api?.dldDCDEntity, data).pipe(pluck('result'));
  }
  getDCDFromDLDByAttributes(data: any) {
    return this.http.post(this.api?.dldDCDAttribute, data).pipe(pluck('result'));
  }
  sendSelectedDataForDldFeedBack(data: any) {
    return this.http.post(this.api?.dldFeedback, data);
  }
  sendDldFeedbackData(data: any) {
    return this.http.post(this.api?.dldFeedback, data);
  }

  // RHS Integrations
  getDSDSearchQueries(searchType: any, searchText: any, pageNumber?: any, limit?: any) {
    if (!pageNumber) {
      pageNumber = 1;
    }
    if (!limit) {
      limit = 5;
    }
    return this.http.get(
      this.api?.globalBetsSearch +
        searchType +
        `?limit=${limit}&ontology=&page=` +
        pageNumber +
        '&publisherIdOrName=&query=' +
        searchText
    );
  }
  getGlobalBETDetails(dsdId: any, betType: any) {
    return this.http.get(this.api?.globalBets + `/${betType}/` + dsdId);
  }
  compareBets(dsdIds: any, betType: any) {
    return this.http.get(this.api?.compareBets + `${betType}&id=` + dsdIds);
  }
  saveBETCompositeAPIDsd(gsiData: any, betType: any) {
    return this.http.post(this.api?.saveBetsComposite + `/tenant/${betType}/composite-save`, gsiData);
  }
}
