<section class="tab-header">
  <div class="row no-gutters align-items-center">
    <div class="col node-search-wrap">
      <div class="node-select" id="selectIntentVersion">
        <mat-form-field appearance="standard">
          <select matNativeControl [(ngModel)]="filterType" (change)="loadData($event)">
            <option value="all" id="getAllIntents">{{ labels?.ALL }}</option>
            <option value="published" id="getPublishedIntents">{{ labels?.PUBLISHED }}</option>
            <option value="drafts" id="getDraftsIntents">{{ labels?.DRAFTS }}</option>
          </select>
        </mat-form-field>
      </div>
      <div class="node-search-right">
        <div class="node-create right-item">
          <button class="create-new-btn" (click)="addNewCU()" id="createNew_Intent"></button>
        </div>
        <div class="node-search-block right-item">
          <button
            (click)="displayNodesearch = !displayNodesearch"
            class="search-btn-icon search-btn-large"
            id="search_Intent"
          ></button>
        </div>
      </div>

      <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
        <mat-form-field appearance="legacy">
          <button
            mat-icon-button
            matSuffix
            (click)="displayNodesearch = !displayNodesearch; searchText = ''; getChangeUnits()"
          >
            <mat-icon matSuffix>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchText" placeholder="Search" (keyup)="getChangeUnits()" />
        </mat-form-field>
      </div>
    </div>
  </div>
</section>
<section class="tab-body">
  <ul class="common-list">
    <li *ngFor="let cu of changeUnitList?.data">
      <mat-accordion>
        <mat-expansion-panel hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header (click)="expandCU(cu)">
            <div class="d-flex item align-items-center">
              <div
                class="details"
                pTooltip="{{ cu.name }}"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
              >
                <p class="sub">{{ cu.status }} [{{ cu.version }}]</p>
                <p class="title">
                  {{ cu.name }}
                </p>
              </div>
              <a
                class="add-icon"
                (click)="addChangeunit(cu); $event.stopPropagation()"
                id="addIntent_{{ cu?.name?.replaceAll(' ', '_') }}"
                ><span></span
              ></a>
              <a
                class="copy-icon"
                (click)="cloneChangeUnit(cu); $event.stopPropagation()"
                id="copyIntent_{{ cu?.name?.replaceAll(' ', '_') }}"
                ><span></span
              ></a>
              <a class="more-icon" (click)="expandCU(cu)" id="moreIntentDetails_{{ cu?.name?.replaceAll(' ', '_') }}"
                ><span></span
              ></a>
            </div>
          </mat-expansion-panel-header>
          <div class="item-body" *ngIf="cu.displayList">
            <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of cu.displayList">
              <mat-expansion-panel-header>
                <div class="d-flex item level-two align-items-center">
                  <div class="details">
                    <!-- <p class="sub"></p> -->
                    <p class="title">{{ layer.type }}</p>
                  </div>
                  <a class="more-icon" id="moreLayersDetails_{{ layer?.type }}"><span></span></a>
                </div>
              </mat-expansion-panel-header>
              <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let ent of layer.participatingItems">
                <mat-expansion-panel-header>
                  <div class="d-flex item level-three align-items-center">
                    <div class="details">
                      <!-- <p class="sub"></p> -->
                      <p class="title">{{ ent.item.DATA.name }}</p>
                    </div>
                    <a class="more-icon" id="moreEntityDetails_{{ ent?.item?.DATA?.name?.replaceAll(' ', '_') }}"
                      ><span></span
                    ></a>
                  </div>
                </mat-expansion-panel-header>
                <div class="mat-elevation-z0" *ngFor="let attr of ent.item?.DATA?.nslAttributes">
                  <div class="d-flex item level-four align-items-center">
                    <div class="d-flex" *ngIf="!attr.generalEntity">
                      {{ attr.name }}<a class="create-new-btn" id="addAttr_{{ attr.name }}"></a>
                    </div>
                    <div class="d-flex" *ngIf="attr.generalEntity">
                      <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</section>
<app-clone-modal *ngIf="openModal" [data]="selectedCu" [title]="title" [type]="type" [isPublished]="isPublished">
</app-clone-modal>
<div class="tab-footer">
  <app-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [totalRecords]="changeUnitList.totalResults"
    (onPageChange)="onPageChange($event)"
  >
  </app-paginator>
</div>
