import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsEndpointService, EventsFacadeService, FieldConfig, getUiControlCustomization, setErrorDependentAttribute, TransactionFacadeService, TranslatorService, validateDependentExpression } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { MatSelect } from '@angular/material/select';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { SPACE } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-dropdown',
  styleUrls: ['./dropdown.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <form class="demo-full-width" [formGroup]="group">
      <div
        [ngClass]="
          !foundObject?.event || foundObject?.event == ''
            ? 'ui-custom-select'
            : foundObject?.event == 'Option 7'
            ? 'dropdown-parent-class-styles'
            : ''
        "
      >
        <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
          <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
            <label
              class="main-label nh-mb-0"
              pTooltip="{{ field?.label }}"
              id="{{ field.attribute.name }}"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              [style.color]="field.color"
              [style.font-size.px]="field.fontSize"
              *ngIf="
                (field?.sentence == null || field?.sentence === '') &&
                !field?.hideLabels &&
                !field?.configuration?.hideLabel &&
                !field?.hideLabelMultiAttr
              "
              ><span class="main-label-inner"
                ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
                <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
              ></label
            >
            <p
              class="nh-ml-6 d-flex main-label-helperIcon"
              *ngIf="field?.configuration?.showHelperTextIcon"
              [ngStyle]="{
                color: field?.configuration?.helperTextColor,
                'font-size': field?.configuration?.helperTextFont
              }"
            >
              <mat-icon
                class="material-icons-outlined helperText-infoIcon"
                [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
                matTooltip="{{ field?.configuration?.helpertext }}"
                matTooltipPosition="above"
                *ngIf="!field?.hideLabels"
                >info_outline</mat-icon
              >
            </p>
          </div>
          <div
            class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10"
            *ngIf="
              field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
              (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
            "
          >
            <mat-icon
              *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
              matTooltip="Sub-Transactional CU"
              matSuffix
              class="d-flex align-items-center justify-content-center"
              ><span class="sub-cu" (click)="openSubPopUp()"
                ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
              /></span>
            </mat-icon>
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              (click)="addAttribute.next()"
              >add</mat-icon
            >
            <mat-icon
              *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
              [ngClass]="{ 'mat-icon-disable': field.readonly }"
              matSuffix
              (click)="removeAttribute.next()"
              >remove</mat-icon
            >
          </div>
        </div>
        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <div
          class="{{ appliedClass }} sub-right-class"
          [ngClass]="{
            inValidClass:
              !field?.isInfo &&
              (group?.controls)[field?.attribute?.name]?.invalid &&
              (group?.controls)[field?.attribute?.name]?.touched
          }"
        >
          <mat-form-field appearance="fill" floatLabel="never">
            <mat-select
              #select="matSelect"
              #matSelectInfniteScrol
              [disabled]="field?.readonly"
              [required]="field?.isRequired && !field?.isInfo"
              [formControlName]="field.attribute.name"
              id="dropdown_{{ field.label }}"
              (selectionChange)="onSelectionChange($event)"
              title="{{ field?.value }}"
              panelClass="{{ appliedPanelClass }} {{ dropdownClass }} {{ tenantName === 'ramcodev' ? 'ramcodev-dropdown-options' : '' }} {{ tenantName === 'defisolutions' ? 'defisolutions-dropdown-options' : '' }}"
            >
              <div class="dropdown-search sticky-dropdown-search" *ngIf="!field?.configuration?.hideSearchBar">
                <input
                  type="text"
                  class="input-search"
                  (keyup)="inputSearchFilter.next(searchText)"
                  [(ngModel)]="searchText"
                  autocomplete="off"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="{{ labels?.Search }}"
                  id="{{ field.attribute.name }}"
                />
                <figure>
                  <img src="../../../../assets/img/icon-search.svg" />
                </figure>
              </div>
              <!-- This is the Scrollable Part Pls let me know If any changes to structure. Ts code also needs to be changed Accordingly -->
              <div class="select-dropdown-list" >
                <mat-option disabled *ngIf="options?.length === 0 || !options">None</mat-option>
                <ng-container *ngIf="showTollTip; else hideTooltip">
                <mat-option
                  tooltipDirective
                  class="tool-tip-container"
                  *ngFor="let option of options"
                  id="dropdownselect_{{ field.label }}_{{
                    option.displayValue
                  }}"
                  [value]="option.actualValue"
                  [displayValue]="option.displayValue"

                >
                  {{ option.displayValue }}
                </mat-option>
                </ng-container>
                <ng-template #hideTooltip>
                <mat-option
                  class="tool-tip-container"
                  *ngFor="let option of options"
                  id="dropdownselect_{{ field.label }}_{{
                    option.displayValue
                  }}"
                  [value]="option.actualValue"

                >
                  {{ option.displayValue }}
                </mat-option>
                </ng-template>
              </div>

            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </form>
  `,
  styles: [],
})
export class DropDownComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  title: any = '';
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  searchText: string;
  options: any[];
  prevScroll: any = -1;
  ngUnsubscribe = new Subject();
  pageNumber: any = 2;
  refAttributes: any;
  stopScroll: boolean = true;
  @ViewChild(MatSelect, { static: true }) scrollElement: MatSelect;
  @ViewChild('select', { static: true }) select: any;
  scrolledDataGot: boolean = false;
  searchData: string;
  isMobile: boolean;
  labels: any;
  foundObject: any;
  appliedClass: string = '';
  appliedPanelClass: string = 'ui-custom-select-options';
  tenantName: any;
  dropdownClass: string = '';

  private unsubscribe$: Subject<any> = new Subject<any>();
  inputSearchFilter: any = new Subject();
  constructor(
    private eventEndpointService: EventsEndpointService,
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacade: TransactionFacadeService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.tenantName = localStorage.getItem('TenantName');
    this.options = this.field?.options?.filter((x: any) => x?.displayValue && x?.actualValue);
    this.addValueToOptions();
    this.setInitialValue();
    /* istanbul ignore next */
    this.scrollElement?.openedChange.subscribe((open: any) => {
      if (open) {
        // Adding Scroll Event for the Options.
        this.scrollElement?.panel?.nativeElement?.children?.[1]?.addEventListener('scroll', (event: any) => {
          this.onInfiniteScroll(event);
        });
      }
    });
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode == SPACE) return;
      if (!this.select.disabled) {
        this.select.panelOpen ? this.select._handleOpenKeydown(event) : this.select._handleClosedKeydown(event);
      }
    };
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    this.dropdownInputSearchField();
    let dropdownOptions = getUiControlCustomization('DropdownSelect');
    if (dropdownOptions) {
      this.foundObject = { event: dropdownOptions };
      if (dropdownOptions !== 'Option 7') {
        this.appliedClass = 'form-custom-select form-custom-select-opt';
        this.appliedPanelClass = 'form-custom-select-options form-custom-single-select-opt';
        switch (dropdownOptions) {
          case 'Option 1':
            this.appliedClass = 'form-custom-select';
            this.appliedPanelClass += '1';
            break;
          case 'Option 2':
            this.appliedClass += '2';
            this.appliedPanelClass += '1';
            break;
          case 'Option 3':
            this.appliedClass += '5';
            this.appliedPanelClass += '2';
            break;
          case 'Option 4':
            this.appliedClass += '3';
            this.appliedPanelClass += '3';
            break;
          case 'Option 5':
            this.appliedClass += '6';
            this.appliedPanelClass += '4';
            break;
          case 'Option 6':
            this.appliedClass += '7';
            this.appliedPanelClass += '5';
            break;
        }
      }
    }
    let size = this.extractSizeSubstring(this.field?.attrClass);
    this.dropdownClass = `ui-custom-${size}-size-options`;
  }

  extractSizeSubstring(input: string): string | null {
    const regex  = /size-(.*?)-uicontrols/;
    const match = input.match(regex);
    return match ? match[1] : null;
  }

  onInfiniteScroll(event: any) {
    if (
      event.target.scrollTop >= event.target.scrollHeight * 0.8 &&
      event.target.scrollTop > this.prevScroll &&
      (this.transactionFacade.depDropdownPayloadOnLoad || this.transactionFacade?.depDropdownPayloadOnChange) &&
      !this.scrolledDataGot
    ) {
      const cuDetails = this.transactionFacade.depDropdownPayloadOnLoad;
      /* istanbul ignore next */
      if (cuDetails?.currentCU?.eventCUList.length > 0) {
        cuDetails?.currentCU?.eventCUList?.sort(this.sortOrderEvent('eventType'));
        const referenceEntityInfo = cuDetails?.currentCU?.eventCUList?.find((ele: any) => {
          const attributeReferences = ele?.referenceEntityInfo?.attributeReferences?.find((refAtt: any) => {
            const tempArrayString = refAtt?.targetAttributeId?.split('.');
            return this.field.attribute.id == tempArrayString?.[tempArrayString.length - 1]?.slice(2) &&
            refAtt?.referenceValueType == 'OPTIONS';
          });
          if (this.transactionFacade?.depDropdownPayloadOnChange && attributeReferences) {
            this.refAttributes = attributeReferences;
            return true;
          } else if (this.transactionFacade?.depDropdownPayloadOnLoad && attributeReferences) {
            if (ele.eventType === 'ON_CHANGE') {
              return false;
            }
            this.refAttributes = attributeReferences;
            return true;
          }
        });
        if (
          referenceEntityInfo &&
          this.stopScroll &&
          (referenceEntityInfo?.eventType === this.transactionFacade?.depDropdownPayloadOnLoad?.type ||
            referenceEntityInfo?.eventType === this.transactionFacade?.depDropdownPayloadOnChange?.type)
        ) {
          this.scrolledDataGot = true;
          this.eventEndpointService
            .getDependentDropdownDetails(
              this.transactionFacade?.depDropdownPayloadOnChange?.currentCU?.id == cuDetails?.currentCU?.id
                ? this.transactionFacade?.depDropdownPayloadOnChange
                : cuDetails,
              referenceEntityInfo?.eventType,
              referenceEntityInfo?.changeDriverId,
              this.pageNumber,
              referenceEntityInfo?.eventType === 'ON_LOAD' ? 500 : 100,
              this.searchData,
              this.refAttributes?.refDisplayAttributeName
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              res.result.forEach((key: any) => {
                this.scrolledDataGot = false;
                if (
                  key.refActualAttributeId === this.refAttributes?.refActualAttributeId &&
                  key.refDisplayAttributeId === this.refAttributes?.refDisplayAttributeId &&
                  key.targetAttributeId === this.refAttributes?.targetAttributeId
                ) {
                  if (key.options?.length == 0) {
                    this.stopScroll = false;
                  }
                  key?.options?.forEach((ele: any) => {
                    this.options.push(ele);
                    this.field.options.push(ele);
                  });
                }
              });
              // if (res.result[0].options.length == 0) {
              //   this.stopScroll = false;
              // }
              // res.result[0].options.forEach((ele: any) => {
              //   this.options.push(ele);
              // });
            });
          this.prevScroll = event.target.scrollTop;
          this.pageNumber = this.pageNumber + 1;
        }
      }
    }
  }

  addValueToOptions() {
    let valueField: any;
    if (Array.isArray(this.field.value)) {
      /* istanbul ignore next */
      valueField = this.field.value[0];
    } else {
      /* istanbul ignore next */
      valueField = this.field.value;
    }
    /* istanbul ignore else */
    if (this.options && this.options.length > 0 && valueField !== '') {
      const optIndex = this.options.findIndex((x: any) => x.actualValue == valueField);
      /* istanbul ignore else */
      if (optIndex == -1) {
        /* istanbul ignore else */
        if (Array.isArray(this.field.value)) {
          /* istanbul ignore next */
          this.field.value = [];
        } else {
          /* istanbul ignore next */
          this.field.value = '';
        }
      }
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  dropdownInputSearchField() {
    this.inputSearchFilter
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data: any) => {
        this.onSearch(data);
      });
  }

  onSearch(searchData: string) {
    /* istanbul ignore next */
    this.searchData = searchData;
    this.pageNumber = 1;
    this.scrolledDataGot = false;
    this.stopScroll = true;
    const cuDetails = this.transactionFacade.depDropdownPayloadOnLoad;
    /* istanbul ignore next */
    if (cuDetails?.currentCU?.eventCUList?.length > 0) {
      cuDetails?.currentCU?.eventCUList?.sort(this.sortOrderEvent('eventType'));
      const referenceEntityInfo = cuDetails?.currentCU?.eventCUList?.find((ele: any) => {
        const attributeReferences = ele?.referenceEntityInfo?.attributeReferences?.find((refAtt: any) => {
          const tempArrayString = refAtt?.targetAttributeId?.split('.');
          return this.field.attribute.id == tempArrayString?.[tempArrayString.length - 1]?.slice(2) &&
          refAtt?.referenceValueType == 'OPTIONS';
        });
        if (this.transactionFacade?.depDropdownPayloadOnChange && attributeReferences) {
          this.refAttributes = attributeReferences;
          return true;
        } else if (this.transactionFacade?.depDropdownPayloadOnLoad && attributeReferences) {
          if (ele.eventType === 'ON_CHANGE') {
            return false;
          }
          this.refAttributes = attributeReferences;
          return true;
        }
      });
      if (
        referenceEntityInfo &&
        this.stopScroll &&
        (referenceEntityInfo?.eventType === this.transactionFacade?.depDropdownPayloadOnLoad?.type ||
          referenceEntityInfo?.eventType === this.transactionFacade?.depDropdownPayloadOnChange?.type)
      ) {
        this.eventEndpointService
          .getDependentDropdownDetails(
            this.transactionFacade?.depDropdownPayloadOnChange?.currentCU?.id == cuDetails?.currentCU?.id
              ? this.transactionFacade?.depDropdownPayloadOnChange
              : cuDetails,
            referenceEntityInfo?.eventType,
            referenceEntityInfo?.changeDriverId,
            this.pageNumber,
            referenceEntityInfo?.eventType === 'ON_LOAD' ? 500 : 100,
            searchData,
            this.refAttributes?.refDisplayAttributeName
          )
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            res.result.forEach((key: any) => {
              if (
                key.refActualAttributeId === this.refAttributes.refActualAttributeId &&
                key.refDisplayAttributeId === this.refAttributes.refDisplayAttributeId &&
                key.targetAttributeId === this.refAttributes.targetAttributeId
              ) {
                if (key?.options?.length == 0) {
                  this.stopScroll = false;
                }
                this.options = key.options;
              }
            });
          });
        this.pageNumber = this.pageNumber + 1;
      } else {
        this.options = this.field?.options?.filter(
          (data: any) =>
            data?.displayValue &&
            data?.actualValue &&
            data?.displayValue?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
        );
      }
    } else {
      this.options = this.field?.options?.filter(
        (data: any) =>
          data?.displayValue &&
          data?.actualValue &&
          data?.displayValue?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
      );
    }
  }

  onClearClick() {
    this.searchText = '';
    /* istanbul ignore next */
    this.options = this.field?.options?.filter((x: any) => x?.displayValue && x?.actualValue);
  }

  setInitialValue() {
    /* istanbul ignore next */
    if (
      this.group?.controls?.[this.field.attribute.name]?.value &&
      this.group?.controls[this.field.attribute.name].value != ''
    ) {
      const option = this.options?.findIndex((option) => {
        return option.actualValue === this.group?.controls?.[this.field.attribute.name]?.value;
      });
      if (option !== -1) {
        this.field.value = this.group?.controls[this.field.attribute.name].value;
      }
    }
    if (Array.isArray(this.field.value)) {
      /* istanbul ignore next */
      this.group?.controls[this.field.attribute.name].setValue(this.field.value[0]);
    } else {
      /* istanbul ignore next */
      this.group?.controls[this.field.attribute.name].setValue(this.field.value);
    }
  }

  getValue() {
    /* istanbul ignore next */
    return this.group?.get(String(this.field.attribute.name)).value;
  }

  /**
   * Determines whether selection change on
   * @param event holds the selected option value
   */
  onSelectionChange(event: any) {
    if (!this.field.isHidden) {
      this.title = event.value;
      this.field.value = event.value;
      const option = this.field?.options?.find((option) => {
        return option.actualValue === this.field.value;
      });
      const push_option = this.options.find((option) => {
        return option.actualValue === this.field.value;
      });
      /*istanbul ignore next*/
      if (!option) {
        this.field.options.push(push_option);
        this.group?.controls?.attr_options.setValue(this.field.options);
      }
      /* istanbul ignore next */
      //this.group?.controls[this.field?.attribute?.name].setValue(this.field?.value);
      const data = {
        attributeId: this.field.attribute['id'],
        isTableConfig: this.field.attribute['isTableConfig'],
        attrName: this.field.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore else */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
      // document.getElementById(`dropdown_${this.field.label}`).focus();
    }
  }

  validateNotEmptyValidator(validator: any) {
    let flag = false;
    /* istanbul ignore next*/
    if (validator?.name == 'notempty') {
      if (!this?.group?.invalid) return (flag = false);
      setErrorDependentAttribute(validator, this.field, this.group);
      // this.field.isRequired = false;
      return (flag = true);
    }
    this.group?.status === 'INVALID'
      ? this.transactionFacade.disableSubmitButtonFlag.next(true)
      : this.transactionFacade.disableSubmitButtonFlag.next(false);
    return flag;
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (validation?.name == 'valuesSelect' || validation?.name == 'notequalTo') {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacade
      );
    }
    if(validation?.name == 'notempty') this.validateNotEmptyValidator(validation);
    return this.group?.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (validation?.name == 'valuesSelect') {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacade
      );
    }
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  sortOrderEvent(prop: any) {
    /* istanbul ignore next */
    return function (a: any, b: any) {
      if (a[prop] >= b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  get showTollTip(){
    if(this.field?.configuration?.hasOwnProperty('hideTooltip') && this.field?.configuration?.hideTooltip){
      return true;
    }
    return false;
  }
}
