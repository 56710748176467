import { Component, OnInit } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-node-menu-tab',
  templateUrl: './node-menu-tab.component.html',
  styleUrls: ['./node-menu-tab.component.scss'],
})
export class NodeMenuTabComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  labels: any;
  constructor(private translator: TranslatorService) {
    this.detectLanguageChange();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnInit(): void {}
}
