import { Agent } from "./agent";
import { ChangeUnitList } from "./change-unit-list";
import { Dcd } from "./dcd";
import { DesignRights } from "./design-rights";
import { ExceptionChangeUnitList } from "./exception-change-unit-list";
import { GsiList } from "./gsi-list";
import { Layer } from "./layer";
import { MindChangeUnitList } from "./mind-change-unit-list";
import { NextTriggerSet } from "./next-trigger-set";
import { Rights } from "./rights";
import { TransactionRights } from "./transaction-rights";


export interface Gsi {
  agents: Agent[];
  attributeDesignRights: DesignRights[];
  attributeTransactionRights: TransactionRights[];
  allowPreviouCUView: boolean;
  isNavigableCu: boolean;
  condition: string;
  constraint: string;
  constraintName: string;
  contextualId: string[];
  cuType: string;
  dcd: Dcd[];
  description: string;
  author: any;
  designTimeRights: Rights[];
  displayName: string;
  entityDesignRights: DesignRights[];
  entityTransactionRights: TransactionRights[];
  gsiList?: GsiList[];
  guid: string;
  id: number;
  index: number;
  layers: Layer[];
  membershipList: string[];
  mindCUList: MindChangeUnitList[];
  exceptionCUList: ExceptionChangeUnitList[];
  name: string;
  nextTriggerSet: NextTriggerSet[];
  ownerId: number;
  referencedChangeUnit: number;
  masterId: number;
  reserved: boolean;
  reservedCUType: string;
  solutionLogic: any[];
  space: string;
  status: string;
  subOrdinateCUList: number[];
  superOrdinateCUList: number[];
  symbolicId: string;
  time: Date;
  txnTimeRights: Rights[];
  userId: string;
  changeUnits?: ChangeUnitList[];
  ontology: [];
  keywords: [];
  isNameUpdated?: boolean;
  dsdId: string;
  source: string;
  tfId?: string;
  level?: number;
  dsdReferencedChangeUnit?: string;
  sentenceTags: any;
  version?: any;
  isExceptionSkippable?: boolean;
  isCancellable?: boolean;
  isTransactionalGSI?: boolean;
  listRundownErrorInfo?: any;
  listRecursionContextualID ?:string;
  listRecursionContextualName?:string;
  isGSILayerBasedRecursion?: boolean;
  preRecursiveConditionCheck?: boolean;
}

export class Gsi {
  constructor() {
    this.agents = [{ agentType: 'human' }];
    this.attributeDesignRights = [];
    this.attributeTransactionRights = [];
    this.condition = '';
    this.constraint = '';
    this.constraintName = '';
    this.contextualId = [];
    this.cuType = 'GSI';
    this.dcd = [];
    this.description = '';
    this.author = {};
    this.designTimeRights = [];
    this.displayName = '';
    this.entityDesignRights = [];
    this.entityTransactionRights = [];
    this.gsiList = [];
    this.guid = '';
    this.id = null;
    this.index = 0;
    this.layers = [];
    this.membershipList = [];
    this.mindCUList = [];
    this.exceptionCUList = [];
    this.name = '';
    this.nextTriggerSet = [];
    this.ownerId = null;
    this.referencedChangeUnit = null;
    this.reserved = false;
    this.reservedCUType = '';
    this.solutionLogic = [];
    this.space = '';
    this.status = 'DRAFT';
    this.subOrdinateCUList = [];

    this.symbolicId = '';
    this.time = new Date();
    this.txnTimeRights = [];
    this.userId = '';
    this.changeUnits = [];
    this.ontology = [];
    this.keywords = [];
    this.dsdId = '';
    this.source = '';
    this.isExceptionSkippable = false;
    this.isCancellable = false;
    this.isTransactionalGSI = false;
  }
}
