import { Component } from '@angular/core';
import { AlertService } from '@common-services';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';

@Component({
  selector: 'app-e-commerce-template',
  templateUrl: './e-commerce-template.component.html',
  styleUrls: ['./e-commerce-template.component.scss'],
})
export class ECommerceTemplateComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService
  ) {
    super(    
      alertService
    );
  }
}
