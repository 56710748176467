import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ChangeUnit, changeUnitMapper, checkInputField, createConditionalArray, displayCUWithCondition, Layer, NodeGsiFacadeService, updateConditionalPotentiality } from '@common-services';
import { iteratee } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss'],
})
export class ConditionsComponent implements OnInit {
  selectedEntity: any;
  changeUnitIndex: number = -1;
  conditionType: string;
  @Input() changeUnit: ChangeUnit;
  alternativeCus: ChangeUnit[] = [];
  changeUnitMap: Map<string, number> = new Map<string, number>();
  @Input() gsi: any;
  @Input() condition: string;
  @Input() tagData: any;
  @Input() isRecursive: any;
  @Input() openedComp: any;
  @Output() closeCondition = new EventEmitter();
  layerIndex: number = -1;
  selectedCu: any;
  activeIndex: number = 0;
  selectedLayer: Layer;
  entityIndex: number = -1;
  attributeIndex: number = -1;
  selectedAttribute: any;
  operators: string[] = ['<', '>', '==', '!=', '<=', '>=', 'IN POTENTIALITY', 'IN REALITY', 'INCLUDES'];
  conditionalRightsOperators: string[] = ['<', '>', '=', '!='];
  showOperators: boolean = false;
  selectedOperator: string = '';
  formulaId: string = '';
  formulaIdWithId: string = '';
  contextualId: string = '';
  contextualIdWithId: string = '';
  currentAttributeType: any;
  recError: any;
  AlternatexpressionValueWithId: any;
  currentUielement: any;
  private ngUnsubscribe = new Subject();
  currentAlternateCU: any;
  //multi-condition variables starts
  conditionalPotentialityArray: any = [];
  selectedCondition: any;
  selectedInput: any;
  selectedEnt: any;
  selectedAttr: any;
  selectedPredicateIndex: any;
  queries = {
    sourceAttribute: '',
    sourceAttributeType: '',
    sourceAttributeuiElement: '',
    operator: '',
    targetAttribute: '',
    targetAttributeInput: '',
    predicate: '',
  };
  displayAttr: any;
  displayConditionFlag = true;
  valueParam: any;
  valueNameParam: any;
  displayCondition: string;
  layerIndexForAttr: number;
  entityIndexForAttr: number;
  attrIndexForAttr: number;
  nestedIndexForAttr: number;
  nestedCondition: boolean = false;
  attrPath: any = '';
  operatorG: any = [];

  //multi-condition variables ends

  constructor(private changeUnitFacade: NodeChangeUnitFacadeService, private gsiFacade: NodeGsiFacadeService) {}
  ngOnInit(): void {
    this.operatorG = this.operators;
    this.detectActiveGSI();
    console.log(this.tagData);
    if (this.condition == 'Recursive' && this.tagData) {
      this.getTagError();
    }
    this.conditionType = this.condition;
    this.loadComponent();
    this.assignType();
  }
  assignType() {
    if (this.conditionType == 'Alternate') {
      this.valueParam = 'condition';
      this.valueNameParam = 'conditionName';
    } else if (this.conditionType == 'Recursive') {
      this.valueParam = 'constraint';
      this.valueNameParam = 'constraintName';
    } else if (this.conditionType == 'AddConditionalPotentiality') {
      if (!this.selectedCu?.tCUConditionalPotentiality) {
        this.selectedCu = { ...this.selectedCu, tCUConditionalPotentiality: [], tCUConditionalPotentialityNames: [] };
      }
      this.valueParam = 'tCUConditionalPotentiality';
      this.valueNameParam = 'tCUConditionalPotentialityNames';
    }
    // this.valueParam = this.conditionType == 'Alternate' ? 'condition' : 'constraint';
    // this.valueNameParam = this.conditionType == 'Alternate' ? 'conditionName' : 'constraintName';
  }

  detectActiveGSI() {
    this.gsiFacade.activeEmbGsiData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        if (this.gsi == undefined && res != '') {
          this.gsi = res;
        }
      }
    });
  }
  getTagError() {
    /*istanbul ignore next*/
    this.tagData?.errorData?.forEach((data: any) => {
      data?.forEach((errData: any) => {
        if (errData.errorType == 'RECURSIVE_CONSTRAINT') {
          this.recError = errData?.message;
        }
      });
    });
  }
  parseErrorForAlternate() {
    let altSendTag: any[] = [];
    this.gsi?.solutionLogic.forEach((cu: any) => {
      if (this.tagData?.includes(cu?.name)) {
        cu?.sentenceTags?.physical?.forEach((tag: any) => {
          /*istanbul ignore next*/
          if (tag?.token == cu?.name) {
            altSendTag.push(tag);
          }
        });
      }
    });
    /*istanbul ignore next*/
    this.alternativeCus?.forEach((alternateCU: any, index: number) => {
      if (altSendTag[index]?.hasError) {
        alternateCU.hasError = true;
        alternateCU.errorMessage = altSendTag[index].errorData[0][0].message;
      }
    });
  }

  closeConditions() {
    this.changeUnitFacade.openCondition(false);
    this.closeCondition.next(true);
    this.changeUnitFacade.closeActionpanel();
  }

  // loads component data based on the conditionType
  loadComponent(): void {
    /* istanbul ignore next */
    this.changeUnitIndex = this.gsi?.solutionLogic?.findIndex((cu: any) => {
      return cu.masterId == this.changeUnit.masterId && cu.index == this.changeUnit.index;
    });
    if (this.conditionType == 'Alternate') {
      /* istanbul ignore next */
      this.gsi?.solutionLogic?.forEach((cu: ChangeUnit, index: number) => {
        this.changeUnitMap.set(cu.id.toString(), index);
      });
      /* istanbul ignore next */
      this.changeUnit?.nextTriggerSet.forEach((cu: any) => {
        if (!cu?.isParallel) {
          this.alternativeCus.push(this.gsi?.solutionLogic[this.changeUnitMap.get(cu.nextCUId.toString())]);
        }
      });
      this.selectedCu = this.alternativeCus[0];
      this.getCUByID(0);
      /*istanbul ignore next*/
      if (this.condition == 'Alternate' && this.alternativeCus.length > 0) {
        this.parseErrorForAlternate();
      }
      /*istanbul ignore next*/
      this.createConditionArray(this.selectedCu?.conditionName, true, this.conditionType);
    } else if (this.conditionType == 'Recursive') {
      /* istanbul ignore else */
      this.selectedCu = JSON.parse(JSON.stringify(this.changeUnit));
      /*istanbul ignore next*/
      this.createConditionArray(this.selectedCu?.constraintName, true, this.conditionType);
    } else if (this.conditionType == 'Conditional Rights') {
      /*istanbul ignore next*/
      this.operatorG = this.conditionalRightsOperators;
      /* istanbul ignore else */
      this.selectedCu = JSON.parse(JSON.stringify(this.changeUnit));
      /*istanbul ignore next*/
      this.createConditionArray(
        this.selectedCu?.designTimeRights[0]?.condition?.predicates[0],
        true,
        this.conditionType
      );
    } else if (this.conditionType == 'AddConditionalPotentiality') {
      console.log('else coverage');
      this.selectedCu = JSON.parse(JSON.stringify(this.changeUnit));
      /*istanbul ignore next*/
      if (this.selectedCu?.tCUConditionalPotentialityNames?.length > 0) {
        this.createConditionArray(this.selectedCu?.tCUConditionalPotentialityNames[0], true, this.conditionType);
      }
    } else if (this.conditionType == 'AttributeConditionalPotentiality') {
      this.selectedCu = JSON.parse(JSON.stringify(this.changeUnit));
      this.layerIndexForAttr = this.tagData?.layer_index;
      this.entityIndexForAttr = this.tagData?.entity_index;
      this.attrIndexForAttr = this.tagData?.attr_index;
      this.displayAttr = this.selectedCu?.layers[this.layerIndexForAttr]?.participatingItems[
        this.entityIndexForAttr
      ]?.nslAttributes[this.attrIndexForAttr];
      this.createConditionArray(this.displayAttr?.conditionalPotentialityName?.[0], true, this.conditionType);
    } else if (this.conditionType == 'NestedAttributeConditionalPotentiality') {
      this.nestedCondition = true;
      this.selectedCu = this?.changeUnit?.index
        ? JSON.parse(JSON.stringify(this.changeUnit?.index))
        : JSON.parse(JSON.stringify(this.changeUnit));
      this.layerIndexForAttr = this.tagData?.layer_index;
      this.entityIndexForAttr = this.tagData?.entity_index;
      this.attrIndexForAttr = this.tagData?.attr_index;
      this.nestedIndexForAttr = this.tagData?.nestedIndex;
      this.displayAttr = this.selectedCu?.layers[this.layerIndexForAttr]?.participatingItems[
        this.entityIndexForAttr
      ]?.nslAttributes[this.attrIndexForAttr]?.generalEntity?.nslAttributes[this.nestedIndexForAttr];
      this.createConditionArray(this.displayAttr?.conditionalPotentialityName?.[0], true, this.conditionType);
    }
    else if (this.conditionType == 'AddEntityConditionalPotentiality') {
      this.selectedCu = JSON.parse(JSON.stringify(this.changeUnit));
      this.layerIndexForAttr = this.tagData?.layer_index;
      this.entityIndexForAttr = this.tagData?.entity_index;
      this.displayAttr = this.selectedCu?.layers[this.layerIndexForAttr]?.participatingItems[this.entityIndexForAttr];
      this.createConditionArray(this.displayAttr?.conditionalPotentialityName?.[0], true, this.conditionType);
    }
    this.displayConditionFlag = false;
  }

  selectCu(activeIndex: number) {
    this.activeIndex = activeIndex;
    this.selectedCu = this.alternativeCus[activeIndex];
    this.getCUByID(activeIndex);
    /* istanbul ignore next */
    this.selectedLayer = this.selectedCu?.layers.find((layer: Layer) => {
      return layer.type == 'physical';
    });
    this.displayConditionFlag = false;
    this.createConditionArray(this.selectedCu[this.valueNameParam], !this.displayConditionFlag, this.conditionType);
    this.resetConditions();
  }
  getCUByID(activeIndex: number) {
    /* istanbul ignore next */
    this.changeUnitFacade
      .getCuDetailsByCUId(this.alternativeCus[activeIndex]?.tfReferencedChangeUnit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res?.result && res?.status === 200) {
          this.currentAlternateCU = changeUnitMapper(res.result);
        }
      });
  }
  getAttributeType() {
    /* istanbul ignore next */
    this.currentAlternateCU?.layers?.find((layer: any) => {
      if (layer?.type === this.selectedLayer?.type) {
        layer.participatingItems[this.entityIndex]?.nslAttributes?.find((attr: any) => {
          if (attr.dsdId === this.selectedAttribute.dsdId) {
            this.currentUielement = attr?.attributeType?.uiElement?.uiElement;
            this.currentAttributeType = attr?.attributeType?.type;
          }
        });
      }
    });
  }
  selectEntityDetails() {
    this.selectedEnt = this.selectedLayer?.participatingItems[this.entityIndex];
  }

  // selectAttrDetails(selectedEnt:any)
  // {
  //   this.selectedAttr = selectedEnt?.nslAttributes[this.attributeIndex];
  // }
  selectAttribute(): void {
    this.selectedEntity = this.selectedLayer?.participatingItems[this.entityIndex];
    this.attrPath = '';
    this.generateSourceAttrPath(this.selectedEntity);
    /* istanbul ignore next */
    // this.selectedAttribute = this.selectedEntity?.nslAttributes[this.attributeIndex];
    if (this.conditionType == 'Alternate') {
      this.getAttributeType();
    }
    /* istanbul ignore else */
    // if (!this.currentUielement) {
    this.currentUielement = this.selectedAttribute?.attributeType?.uiElement?.uiElement;
    /* istanbul ignore else */
    // if (!this.currentAttributeType) {
    this.currentAttributeType = this.selectedAttribute?.attributeType?.type;

    const layer = this.selectedLayer?.type;
    /* istanbul ignore next */
    if (this.entityIndex != -1 && this.selectedEntity?.name && this.selectedAttribute?.name) {
      this.formulaId = this.generateFormulaId(
        layer,
        this.entityIndex,
        this.selectedEntity?.name,
        this.selectedAttribute?.name
      );
    }

    //new implementation for muticondition
    if (this.selectedInput == undefined) {
      this.selectedInput = 'sourceAttribute';
    }
    if (this.selectedCondition == undefined) {
      this.selectedCondition = this.conditionalPotentialityArray.length - 1;
    }
    /* istanbul ignore next */
    if (this.formulaId) {
      this.conditionalPotentialityArray[this.selectedCondition][this.selectedInput] = this.formulaId;
    }
    if (this.currentAttributeType) {
      this.conditionalPotentialityArray[this.selectedCondition]['sourceAttributeType'] = this.currentAttributeType;
    }
    if (this.currentUielement) {
      this.conditionalPotentialityArray[this.selectedCondition]['sourceAttributeuiElement'] = this.currentUielement;
    }
    // if(this.conditionType!='AttributeConditionalPotentiality')
    this.resetConditions();
  }

  createCondition(): void {
    this.assignCondition();
    this.resetConditions();
    this.selectedEntity = undefined;
  }
  checkTargetInput(event: any, query: any) {
    // query = {...query, sourceAttributeuiElement: this.currentUielement };
    return checkInputField(event, query);
  }

  resetConditions(): void {
    this.selectedAttribute = undefined;
    this.selectedLayer = undefined;
    this.showOperators = false;
    this.entityIndex = -1;
    this.attributeIndex = -1;
    this.layerIndex = -1;
    // this.currentUielement = undefined;
    // this.currentAttributeType = undefined;
  }

  generateFormulaId(
    layerType: string,
    entityIndex: any,
    entityName: string,
    attributeName: string,
    isForDependent?: boolean,
    isEntityname?: boolean
  ) {
    /* istanbul ignore next */
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    let indexPadding = this.getIndexPadding(entityIndex + 1);
    let dot = '.';
    let layerShortCut = 'PL.SL' + indexPadding;
    /* istanbul ignore else */
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      layerShortCut = 'triggerCES.SL' + indexPadding;
    }
    let formulaId =
      isForDependent && !isEntityname ? layerShortCut + dot + 'EN' + entityName : layerShortCut + dot + entityName;
    /* istanbul ignore else */
    // if (attributeName) {
    //   formulaId = formulaId + dot + attributeName;
    // }
    formulaId = formulaId + dot + this.attrPath;
    return formulaId;
  }

  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  changeLayer(): void {
    this.selectedLayer = this.selectedCu.layers[this.layerIndex];
  }

  saveConditions(): void {
    if (this.conditionType == 'Alternate') {
      this.alternativeCus.forEach((altCu: any) => {
        this.gsi.solutionLogic[this.changeUnitMap.get(altCu.id.toString())].conditionName = altCu.conditionName;
        this.gsi.solutionLogic[this.changeUnitMap.get(altCu.id.toString())].condition = altCu.condition;
      });
    } else if (this.conditionType == 'Recursive') {
      if (this.openedComp == 'GSI') {
        this.gsi.constraint = this.selectedCu.constraint;
        this.gsi.constraintName = this.selectedCu.constraintName;
      } else {
        this.gsi.solutionLogic[this.changeUnitIndex].constraint = this.selectedCu.constraint;
        this.gsi.solutionLogic[this.changeUnitIndex].constraintName = this.selectedCu.constraintName;
      }
    } else if (this.conditionType == 'Conditional Rights') {
      /* istanbul ignore next */
      if (this.selectedCu?.referencedChangeUnit) {
        this.selectedCu.id = this.selectedCu?.referencedChangeUnit;
      }
      this.changeUnitFacade.saveCU(this.selectedCu);
    } else if (this.conditionType == 'AddConditionalPotentiality') {
      let cu = this.gsi.solutionLogic[this.changeUnitIndex];
      /*istanbul ignore else */
      cu.tCUConditionalPotentiality = this.selectedCu?.tCUConditionalPotentiality;
      cu.tCUConditionalPotentialityNames = this.selectedCu?.tCUConditionalPotentialityNames;
      if (this.selectedCu?.tCUConditionalPotentiality == null) {
        cu.tCUConditionalPotentiality = [];
        cu.tCUConditionalPotentialityNames = [];
      }
    } else if (
      this.conditionType == 'AttributeConditionalPotentiality' ||
      this.conditionType == 'NestedAttributeConditionalPotentiality' ||
      this.conditionType == 'AddEntityConditionalPotentiality'
    ) {
      /* istanbul ignore else */
      /* istanbul ignore else */
      if (this.selectedCu?.referencedChangeUnit) {
        this.selectedCu.id = this.selectedCu?.referencedChangeUnit;
      }
      this.changeUnitFacade.saveCU(this.selectedCu);
    }
    /*istanbul ignore next */
    if (this.gsi?.referencedChangeUnit && this.gsi?.tfReferencedChangeUnit) {
      this.gsi.dsdId = this.gsi?.referencedChangeUnit;
      this.gsi.id = this.gsi?.tfReferencedChangeUnit;
    }
    this.gsiFacade.saveGsi(this.gsi);
    this.resetConditions();
    this.closeConditions();
  }

  /* Multi condition methods starts from here */
  createConditionArray(condition: any, displayConditionString: any, conditionType?: string) {
    if (condition) {
      this.conditionalPotentialityArray = createConditionalArray(condition, [], displayConditionString);
      /*istanbul ignore else */
      if (displayConditionString && this.conditionalPotentialityArray && this.conditionalPotentialityArray?.length > 0) {
        this.displayCondition = displayCUWithCondition(this.conditionalPotentialityArray);
      }
    } else {
      this.displayCondition = '';
      this.conditionalPotentialityArray = [];
      this.conditionalPotentialityArray.push(JSON.parse(JSON.stringify(this.queries)));
    }
  }

  //To select current input of alternate condition
  conditionInput(item: string, index: any) {
    this.selectedInput = item;
    this.selectedPredicateIndex = index;
  }

  //adding new condition
  addNewCondition() {
    this.displayConditionFlag = true;
    this.conditionalPotentialityArray.push(JSON.parse(JSON.stringify(this.queries)));
    this.selectedInput = 'sourceAttribute';
    /*istanbul ignore else */
    if (this.conditionalPotentialityArray.length > 1) {
      this.conditionalPotentialityArray[this.conditionalPotentialityArray.length - 2].predicate = '&&';
    }
  }

  //deleting a condition from multiple conditions
  deleteCondition(rowIndex: number) {
    this.conditionalPotentialityArray.splice(rowIndex, 1);
    /* istanbul ignore next */
    if (
      this.conditionalPotentialityArray[rowIndex - 1]?.predicate &&
      this.conditionalPotentialityArray.length == rowIndex
    ) {
      this.conditionalPotentialityArray[rowIndex - 1].predicate = '';
    }
  }
  assignCondition() {
    /* istanbul ignore next */
    let isRec = this.conditionType == 'Recursive' ? true : false;
    let condition = updateConditionalPotentiality(this.conditionalPotentialityArray, isRec);
    if (condition) {
      /*istanbul ignore else*/
      if (this.conditionType == 'Alternate' || this.conditionType == 'Recursive') {
        this.selectedCu[this.valueParam] = condition.trim();
        this.selectedCu[this.valueNameParam] = condition.trim();
        /*istanbul ignore else*/
        if (this.conditionType == 'Alternate') {
          this.alternativeCus[this.activeIndex] = this.selectedCu;
        }
        this.createConditionArray(this.selectedCu[this.valueNameParam], true, this.conditionType);
      } else if (this.conditionType == 'AddConditionalPotentiality') {
        if (!this.selectedCu?.tCUConditionalPotentiality) {
          this.selectedCu = { ...this.selectedCu, tCUConditionalPotentiality: [], tCUConditionalPotentialityNames: [] };
        }
        this.selectedCu[this.valueParam][0] = condition.trim();
        this.selectedCu[this.valueNameParam][0] = condition.trim();
      } else if (this.conditionType == 'AttributeConditionalPotentiality') {
        /*istanbul ignore next */
        let attr = this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr]
          .nslAttributes[this.attrIndexForAttr];
        if (!attr?.conditionalPotentiality) {
          attr.conditionalPotentiality = [];
          attr.conditionalPotentialityName = [];
        }
        attr['conditionalPotentiality'][0] = condition.trim();
        attr['conditionalPotentialityName'][0] = condition.trim();
        if (attr?.generalEntity?.conditionalPotentiality) {
          attr.generalEntity['conditionalPotentiality'][0] = condition.trim();
          attr.generalEntity['conditionalPotentialityName'][0] = condition.trim();
        }
      } else if (this.conditionType == 'NestedAttributeConditionalPotentiality') {
        /*istanbul ignore next */
        let attr = this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr]
          .nslAttributes[this.attrIndexForAttr].generalEntity.nslAttributes[this.nestedIndexForAttr];
        if (!attr?.conditionalPotentiality) {
          attr.conditionalPotentiality = [];
          attr.conditionalPotentialityName = [];
        }
        attr['conditionalPotentiality'][0] = condition.trim();
        attr['conditionalPotentialityName'][0] = condition.trim();
      }
      else if (this.conditionType == 'AddEntityConditionalPotentiality') {
        let entity = this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr];
        if (!entity?.conditionalPotentiality) {
          entity.conditionalPotentiality = [];
          entity.conditionalPotentialityName = [];
        }
        entity['conditionalPotentiality'][0] = condition.trim();
        entity['conditionalPotentialityName'][0] = condition.trim();
      }
      else if (this.conditionType == 'Conditional Rights') {
        /*istanbul ignore next */
        let cond = {
          fromDateTime: this.tagData.from_Date,
          toDateTime: this.tagData.to_Date,
          predicates: [condition.trim()],
        };
        /*istanbul ignore next */
        this.selectedCu.designTimeRights[0].condition = cond;
        this.selectedCu.txnTimeRights[0].condition = cond;
        this.gsi.solutionLogic[0].designTimeRights[0].condition = cond;
        this.gsi.solutionLogic[0].txnTimeRights[0].condition = cond;
      }
      this.displayCondition = displayCUWithCondition(this.conditionalPotentialityArray);
      this.displayConditionFlag = false;
    } else {
      condition = '';
    }
    this.displayConditionFlag = false;
  }

  removeCondition() {
    /*istanbul ignore else*/
    if (
      this.conditionType == 'Alternate' ||
      this.conditionType == 'Recursive' ||
      this.conditionType == 'AddConditionalPotentiality'
    ) {
      delete this.selectedCu[this.valueParam];
      delete this.selectedCu[this.valueNameParam];
    } else if (this.conditionType == 'AttributeConditionalPotentiality') {
      delete this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr].nslAttributes[
        this.attrIndexForAttr
      ]['conditionalPotentialityName'];
      delete this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr].nslAttributes[
        this.attrIndexForAttr
      ]['conditionalPotentiality'];
    } else if (this.conditionType == 'NestedAttributeConditionalPotentiality') {
      delete this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr].nslAttributes[
        this.attrIndexForAttr
      ].generalEntity.nslAttributes[this.nestedIndexForAttr]['conditionalPotentialityName'];
      delete this.selectedCu.layers[this.layerIndexForAttr].participatingItems[this.entityIndexForAttr].nslAttributes[
        this.attrIndexForAttr
      ].generalEntity.nslAttributes[this.nestedIndexForAttr]['conditionalPotentiality'];
    }

    this.displayCondition = '';
    this.conditionalPotentialityArray = [];
  }

  editCondition() {
    /*istanbul ignore else*/
    if ( this.conditionalPotentialityArray && this.conditionalPotentialityArray?.length == 0) {
      this.addNewCondition();
    } else {
      this.displayConditionFlag = true;
      this.createConditionArray(this.conditionCheck(), false, this.conditionType);
    }
  }

  toggleCondition() {
    this.displayConditionFlag = !this.displayConditionFlag;
    this.createConditionArray(this.conditionCheck(), !this.displayConditionFlag, this.conditionType);
  }

  conditionCheck() {
    let condition: any;
    console.log(this.selectedCu);
    /*istanbul ignore else*/
    if (this.conditionType == 'AttributeConditionalPotentiality') {
      condition = this.selectedCu.layers[this.layerIndexForAttr]?.participatingItems[this.entityIndexForAttr]
        ?.nslAttributes[this.attrIndexForAttr]?.['conditionalPotentialityName']?.[0];
    } else if (this.conditionType == 'NestedAttributeConditionalPotentiality') {
      condition = this?.selectedCu?.layers[this?.layerIndexForAttr]?.participatingItems[this?.entityIndexForAttr]
        ?.nslAttributes[this?.attrIndexForAttr]?.generalEntity?.nslAttributes[this?.nestedIndexForAttr][
        'conditionalPotentialityName'
      ]?.[0];
    }
    else if (this.conditionType == 'AddEntityConditionalPotentiality') {
      condition = this.selectedCu.layers[this.layerIndexForAttr]?.participatingItems[this.entityIndexForAttr]?.[
        'conditionalPotentialityName'
      ]?.[0];
    }
    else if (this.conditionType == 'Conditional Rights') {
      condition = this.selectedCu?.designTimeRights[0]?.condition?.predicates[0];
    } else {
      condition =
        this.conditionType == 'AddConditionalPotentiality'
          ? this.selectedCu[this.valueNameParam]?.[0]
          : this.selectedCu[this.valueNameParam];
    }
    return condition;
  }

  changeTargetAttribute(entity: any, event: any) {
    /*istanbul ignore next*/
    this.setFalseAll(entity, event?.value);
  }

  setFalseAll(entity: any, attrIndex: any) {
    /*istanbul ignore next*/
    entity?.nslAttributes.forEach((ele: any, index: any) => {
      /*istanbul ignore next*/
      ele.isSelected = false;
      /*istanbul ignore next*/
      if (ele.id === entity?.nslAttributes[attrIndex].id) {
        ele.isSelected = true;
        this.selectedAttribute = ele;
      }
    });
  }

  generateSourceAttrPath(entity: any) {
    entity?.nslAttributes?.forEach((ele: any, index: any) => {
      /*istanbul ignore next*/
      if (ele.isSelected) {
        /*istanbul ignore next*/
        if (this.attrPath == '') {
          /* istanbul ignore next */
          this.attrPath += ele?.name;
        } else {
          /* istanbul ignore next */
          this.attrPath += '.' + ele?.name;
        }
      }
      /*istanbul ignore next*/
      if (ele.generalEntity && ele.isSelected) {
        this.generateSourceAttrPath(ele.generalEntity);
      }
    });
  }
}
