import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService, EventsEndpointService, TransactionFacadeService, LibraryFacadeService, EventsFacadeService, FieldConfig, getUiControlCustomization, validateDependentExpression } from '@common-services';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  inputValueChange = new Subject();

  field: FieldConfig;
  group: FormGroup;
  options: any = [];
  orignalOptions: any = [];
  cuDetails: any;
  refEntPageNumber = 1;
  refEntPageSize = 500;
  refAttributes: any;
  referenceEntityInfo: any;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  unSubscribe = new Subject();
  prevSearchValue = '';
  contextualSearchData: any = [];
  previousGsiIdList: any = [];
  attrBasedGsiId: any;
  ngUnsubscribe = new Subject();
  gsiMasterId: any;
  isOptionsDataPresent: boolean;
  appliedClass: string = 'custom-input-mat';
  parentClass: string = 'typeahead-main';
  showPlaceholder: boolean = false;
  validationList: any[] = [
    'greaterThan',
    'greaterThanOrEqual',
    'smallerThan',
    'smallerThanOrEqual',
    'equalTo',
    'notequalTo',
    'valuesSelect',
  ];
  scroll: boolean = false;

  constructor(
    public dialog: MatDialog,
    private eventsFacadeService: EventsFacadeService,
    private libraryFacadeService: LibraryFacadeService,
    private transactionFacade: TransactionFacadeService,
    private eventEndpointService: EventsEndpointService,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.detectSearchInput();
    this.setOptionsData();
    this.detectContextualSearchData();
    this.getContextualSearchData(); // At initially, Fetch all values for typeahead attribute
    this.createForm();
    this.listenToChange();
    this.getTypeAheadResponse();
    this.setTypeAheadOptionsData();
    let typeaheadOption = getUiControlCustomization('Typeahead');
    if (typeaheadOption) {
      if (typeaheadOption == 'Option 10') {
        if (!this.field.value) this.showPlaceholder = true;
        this.parentClass = 'typeahead-parent-class-styles';
        this.appliedClass = 'form-input-var form-input-var-opt1 placeholder-icon';
      } else {
        let i = typeaheadOption.slice(-1);
        this.appliedClass = `form-input-var form-input-var-opt${i}`;
      }
    }
  }

  setOptionsData() {
    this.orignalOptions = this.options = this.field?.options;
    this.isOptionsDataPresent = this.field.options?.length > 0 ? true : false;
  }
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  filter(name: string) {
    /* istanbul ignore next */
    this.options = this.options.filter((data: any) =>
      /* istanbul ignore next */
      data?.displayValue?.toLocaleLowerCase().includes(name?.toLocaleLowerCase())
    );
  }

  createForm() {
    let control = new FormControl();
    /* istanbul ignore next */
    this.group?.addControl(this.field?.attribute?.name, control);
  }

  listenToChange() {
    /* istanbul ignore next */
    this.group?.controls[this.field?.attribute?.name].valueChanges.subscribe((data: any) => {
      this.filter(data);
    });
  }

  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange() {
    /* istanbul ignore next */
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      slotNumber: this.field?.slotNumber,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
      // this.transactionFacade.isOnChangeEventTriggered[this.field.attribute.id.toString()] = true;
    /* istanbul ignore next */
    if (this.field?.triggerConditionalPotentiality) {
      this.eventsFacadeService.setTriggerEvent(data);
    } else {
      this.eventsFacadeService.setEvent(data);
    }
  }

  /**
   * Determines whether selection change on
   * @param event
   */
  onSelectionChange(event: any) {
    const option = this.field?.options?.find((option) => {
      return option.displayValue === this.searchInput?.nativeElement?.value;
    });
    const push_option = this.options?.find((option:any) => {
      return option.displayValue === this.searchInput?.nativeElement?.value;
    });
    /*istanbul ignore next*/
    if (!option) {
      this.field?.options?.push(push_option);
      this.group?.controls?.attr_options?.setValue(this.field?.options);
    }
    this.onChange();
  }

  /**
   * Determines whether load on
   */

  // Elastic Search - FE757
  // Fetch GSI details and Contextual search data
  getContextualSearchData() {
    this.gsiMasterId = this.transactionFacade?.gsiMasterId;
    // restricting the API call based on the GSIID
    this.libraryFacadeService.previousGsiId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /* istanbul ignore else */
      let previousGsiIdIndex = this.previousGsiIdList.findIndex((id: any) => id === data.id);
      if (previousGsiIdIndex === -1 && data.id !== this.gsiMasterId) {
        this.previousGsiIdList.push(data.id);
        this.libraryFacadeService.getContextualSearchData(this.gsiMasterId);
      }
    });
  }

  detectContextualSearchData() {
    this.libraryFacadeService.contextualSearchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /* istanbul ignore next */
      if (data?.containsData) {
        let dataIndex = this.contextualSearchData.findIndex((ele: any) => ele.gsiId === data.gsiId);
        if (dataIndex >= 0) {
          this.contextualSearchData[dataIndex].data = data?.data;
        } else {
          this.contextualSearchData.push({
            gsiId: data.gsiId,
            data: data?.data,
          });
        }
        this.eventsFacadeService.typeAheadEventType = 'click';
        if (this.isContextualSearchPresent()) {
          this.callattrSearchApi();
        }
      }
      // this.postdata();
    });
  }

  filterBasedOnAttrVal(option: any, attrVal: any) {
    let optionDisplayVal = option?.displayValue?.toLowerCase();
    attrVal = attrVal?.toLowerCase();
    return optionDisplayVal?.includes(attrVal);
  }

  checkEventWithReferenceEntity(eventType: string) {
    this.cuDetails = JSON.parse(JSON.stringify(this.transactionFacade?.cuData));
    /*istanbul ignore else*/
    if (this.cuDetails.currentCU.eventCUList.length > 0) {
      const referenceEntityInfo = this.cuDetails.currentCU.eventCUList.find((ele: any) => {
        const attributeReferences = ele.referenceEntityInfo?.attributeReferences?.find((refAtt: any) => {
          return this.field.attribute.id == ele.changeDriverId;
        });

        /*istanbul ignore else*/
        if (ele.changeDriverId == this.field.attribute.id && ele.eventType === eventType) {
          this.refAttributes = attributeReferences;
          return true;
        }
        return false;
      });

      /*istanbul ignore else*/
      if (referenceEntityInfo && this.refAttributes) {
        this.referenceEntityInfo = referenceEntityInfo;
        return true;
      }
    }

    return false;
  }

  checkEventWithReferenceEntityWithTarget(eventType: string) {
    this.cuDetails = JSON.parse(JSON.stringify(this.transactionFacade?.cuData));
    /*istanbul ignore else*/
    if (this.cuDetails.currentCU.eventCUList.length > 0) {
      const referenceEntityInfo = this.cuDetails.currentCU.eventCUList.find((ele: any) => {
        const attributeReferences = ele.referenceEntityInfo?.attributeReferences?.find((refAtt: any) => {
          const tempArrayString = refAtt?.targetAttributeId?.split('.');
          return this.field.attribute.id == tempArrayString?.[tempArrayString.length - 1]?.slice(2);
        });

        /*istanbul ignore else*/
        if (ele.eventType === eventType) {
          this.refAttributes = attributeReferences;
          return true;
        }
        return false;
      });

      /*istanbul ignore else*/
      if (referenceEntityInfo && this.refAttributes) {
        this.referenceEntityInfo = referenceEntityInfo;
        return true;
      }
    }

    return false;
  }

  callReferenceEntityApi(eventType: string) {
    this.loader.show();
    this.eventEndpointService
      .getDependentDropdownDetails(
        this.cuDetails,
        eventType,
        this.referenceEntityInfo ? this.referenceEntityInfo.changeDriverId : this.field.attribute.id,
        this.refEntPageNumber,
        this.refEntPageSize,
        this.searchInput.nativeElement.value,
        this.field?.attribute?.name
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore else*/
        if (res) {
          res.result?.forEach((key: any) => {
            /*istanbul ignore else*/
            if (
              key.refActualAttributeId === this.refAttributes.refActualAttributeId &&
              key.refDisplayAttributeId === this.refAttributes.refDisplayAttributeId &&
              key.targetAttributeId === this.refAttributes.targetAttributeId
            ) {
              key?.options?.forEach((ele: any) => {
                this.options.push(ele);
                this.field.options.push(ele);
                this.group?.controls?.attr_options?.setValue(this.options);
              });
            }
          });
        }
      });
  }

  detectSearchInput() {
    /* istanbul ignore next */
    this.inputValueChange
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        this.scroll = false;
        this.eventsFacadeService.typeAheadEventType = 'keypup';
          this.showPlaceholder = this.searchInput.nativeElement.value == '' ? true : false;
        if (
          this.isOptionsDataPresent &&
          !this.checkEventWithReferenceEntity('ON_LOAD') &&
          !this.checkEventWithReferenceEntity('ON_CHANGE')
        ) {
          /*istanbul ignore else*/
          this.options = this.orignalOptions.filter((option: any) =>
            this.filterBasedOnAttrVal(option, this.searchInput.nativeElement.value)
          );
          return;
        }

        this.eventsFacadeService.updateTypeAheadPageNumber(0);
        this.refEntPageNumber = 1;
        this.options = [];
        this.postdata();
      });
  }

  onClick(){
    /*istanbul ignore else*/
    if (this.isContextualSearchPresent()) {
      this.eventsFacadeService.typeAheadEventType = 'click';
      this.callattrSearchApi();
    }
  }

  // Send request and fetch options for attr
  postdata() {
    /*istanbul ignore else*/
    if (
      this.isOptionsDataPresent &&
      !this.checkEventWithReferenceEntity('ON_LOAD') &&
      !this.checkEventWithReferenceEntityWithTarget('ON_CHANGE')
    )
      return;

    if (!this.checkEventWithReferenceEntityWithTarget('ON_CHANGE') && this.checkEventWithReferenceEntity('ON_LOAD')) {
      this.callReferenceEntityApi('ON_LOAD');
      if (this.field?.triggerConditionalPotentiality && !this.scroll) {
        this.onChange();
      }
    } else if (
      this.checkEventWithReferenceEntityWithTarget('ON_CHANGE') &&
      this.checkEventWithReferenceEntity('ON_LOAD') &&
      !this.transactionFacade?.isOnChangeEventTriggered[this.field.attribute.id.toString()]
    ) {
      this.callReferenceEntityApi('ON_LOAD');
      if (this.field?.triggerConditionalPotentiality && !this.scroll) {
        this.onChange();
      }
    } else if (
      this.checkEventWithReferenceEntity('ON_LOAD') &&
      this.checkEventWithReferenceEntityWithTarget('ON_CHANGE') &&
      this.transactionFacade?.isOnChangeEventTriggered[this.field.attribute.id.toString()]
    ) {
      this.callReferenceEntityApi('ON_CHANGE');
      if (this.field?.triggerConditionalPotentiality && !this.scroll) {
        this.onChange();
      }
    }else if(this.checkEventWithReferenceEntityWithTarget('ON_CHANGE')){
      this.callReferenceEntityApi('ON_CHANGE');
      if (this.field?.triggerConditionalPotentiality && !this.scroll) {
        this.onChange();
      }
    } else if (this.checkEventWithReferenceEntity('ON_CHANGE')) this.onChange();
    // For contextual Search Typeahead attribute
    else if (this.isContextualSearchPresent()) {
      this.callattrSearchApi();
    } else {
      this.onChange();
    }
  }

  getTypeAheadResponse() {
    this.eventsFacadeService.typeAHeadResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /* istanbul ignore else */
      if (!this.options) this.options = [];
      /* istanbul ignore next */
      for (let i = 0; i < data?.result?.attrValues?.length; i++) {
        this.options.push({ actualValue: data.result.attrValues[i], displayValue: data.result.attrValues[i] });
      }
    });
  }

  setTypeAheadOptionsData() {
    this.eventsFacadeService.typeAheadOptionsData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /*istanbul ignore else*/
      if (data) {
        this.options = data;
      }
    });
  }

  clearOptionData() {
    this.showPlaceholder = false;
    if (this.checkEventWithReferenceEntity('ON_LOAD') || this.checkEventWithReferenceEntityWithTarget('ON_CHANGE'))
      this.options = [];
    else if (this.isContextualSearchPresent()) this.eventsFacadeService.typeAheadOptionsData.next([]);
    else return;
    this.eventsFacadeService.updateTypeAheadPageNumber(0);
    this.refEntPageNumber = 1;
    this.scroll = false;
    this.postdata();
  }
  onScroll() {
    this.scroll = true;
    this.eventsFacadeService.typeAheadEventType = 'scroll';
    this.eventsFacadeService.increaseTypeAheadPageNumber();

    /*istanbul ignore else*/
    if (this.checkEventWithReferenceEntity('ON_LOAD') || this.checkEventWithReferenceEntityWithTarget('ON_CHANGE'))
      this.refEntPageNumber += 1;
    this.postdata();
  }

  isContextualSearchPresent() {
    // Remove $ symbol from attrName and entityName
    let attrName: string = this.field.attribute['name'];
    let attrNameArray: string[] = attrName.split('$', 2);
    let entityName: string = this.field.entityName;
    let entityNameArray: string[] = entityName.split('$', 2);
    let data = {};

    /*istanbul ignore else*/
    if (!this.attrBasedGsiId) {
      this.attrBasedGsiId = this.gsiMasterId;
    }
    let contextualSearchDataIndex = this.contextualSearchData?.findIndex(
      (data: any) => data.gsiId === this.attrBasedGsiId
    );

    /*istanbul ignore else*/
    if (contextualSearchDataIndex >= 0 && this.contextualSearchData?.[contextualSearchDataIndex]?.data?.result) {
      const indexEnt = this.contextualSearchData[contextualSearchDataIndex]?.data?.result?.ctxEntityConfigs?.findIndex(
        (key: any) => {
          return key.entityName == entityNameArray[0];
        }
      );

      const indexAttr = this.contextualSearchData[contextualSearchDataIndex]?.data?.result?.ctxEntityConfigs[
        indexEnt
      ]?.searchAttrConfigs.findIndex((key: any) => {
        return key.attrName == attrNameArray[0];
      });

      /*istanbul ignore else*/
      if (indexAttr != -1) return true;
    }

    return false;
  }

  callattrSearchApi() {
    let attrName: string = this.field.attribute['name'];
    let attrNameArray: string[] = attrName.split('$', 2);
    let entityName: string = this.field.entityName;
    let entityNameArray: string[] = entityName.split('$', 2);
    let data = {};

    /*istanbul ignore else*/
    if (!this.attrBasedGsiId) {
      this.attrBasedGsiId = this.gsiMasterId;
    }
    let contextualSearchDataIndex = this.contextualSearchData.findIndex(
      (data: any) => data.gsiId === this.attrBasedGsiId
    );

    const indexEnt = this.contextualSearchData[contextualSearchDataIndex]?.data?.result?.ctxEntityConfigs?.findIndex(
      (key: any) => {
        return key.entityName == entityNameArray[0];
      }
    );
    const indexAttr = this.contextualSearchData[contextualSearchDataIndex]?.data?.result?.ctxEntityConfigs[
      indexEnt
    ]?.searchAttrConfigs.findIndex((key: any) => {
      return key.attrName == attrNameArray[0];
    });

    data = {
      attrName: attrNameArray[0],
      attrValue: this.searchInput.nativeElement.value,
      contextAttrInfo: this.contextualSearchData[contextualSearchDataIndex]?.data?.result?.ctxEntityConfigs[indexEnt]
        ?.searchAttrConfigs[indexAttr]?.contextAttrConfigs,
      entityName: entityNameArray[0],
      gsiId: this.attrBasedGsiId ? this.attrBasedGsiId : this.gsiMasterId,
      scrollId: 'string',
      size: 100,
    };
    this.eventsFacadeService.addTypeAheadEvent(data);
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsFacadeService,
        this.field,
        this.group,
        this.transactionFacade
      );
    }
    return this.group?.get(String(this.field?.attribute?.name))?.status == 'VALID'
      ? false
      : this.eventsFacadeService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (
      this.group?.controls[field?.attribute.name]?.value != null ||
      this.group?.controls[field?.attribute.name]?.value != undefined
    ) {
      if (validation?.name == 'min') {
        return parseInt(this.group?.controls[field?.attribute?.name].value) < parseInt(validation?.value);
      } else if (validation?.name == 'max') {
        return parseInt(this.group.controls[field?.attribute?.name].value) > parseInt(validation?.value);
      } else {
        if (this.validationList?.includes(validation?.name)) {
          return validateDependentExpression(
            validation,
            this.eventsFacadeService,
            this.field,
            this.group,
            this.transactionFacade
          );
        }
        return this.group.controls[field?.attribute?.name]?.value?.toString()?.match(validation?.requiredPattern)
          ? false
          : true;
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
