import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class FileExplorerEndpointService extends CduiBaseService {
  constructor(private http: HttpClient, private injector: Injector) {
    super(injector);
  }

  getAllBooks(pageNumber: number) {
    return this.http.get(this.api?.getAllBooks + `page=${pageNumber}&limit=30`);
  }

  getCardIcons(pageNumber: number) {
    return this.http.get(this.api?.getCardIcons + `page=${pageNumber}&limit=30`);
  }

  getCuIcons(pageNumber: number) {
    return this.http.get(this.api?.getCuIcons + `page=${pageNumber}&limit=100`);
  }

  getSolutionIcons(pageNumber: number) {
    return this.http.get(this.api?.getSolutionIcons + `page=${pageNumber}&limit=100`);
  }

  getEntityIcons(pageNumber: number) {
    return this.http.get(this.api?.getEntityIcons + `page=${pageNumber}&limit=100`);
  }

  addBookCover(bookData: any) {
    return this.http.post(this.api?.addBookCover, bookData);
  }

  addCardCover(bookData: any) {
    return this.http.post(this.api?.addCardCover, bookData);
  }

  addIcon(iconData: any, iconType: any) {
    if (iconType === 'solution') {
      return this.http.post(this.api?.solutionAddIcon, iconData);
    } else if (iconType === 'cu') {
      return this.http.post(this.api?.cuAddIcon, iconData);
    } else if (iconType === 'entity') {
      return this.http.post(this.api?.entityAddIcon, iconData);
    }
  }

  getAllFolders(folderName: string, query?: string, isExclusive?: boolean, limit: any = 150, pageNumber: any = 1) {
    /* istanbul ignore next */
    if (query == undefined) {
      query = '';
    }
    /* istanbul ignore next */
    if (!isExclusive) {
      isExclusive = false;
    }
    /* istanbul ignore next */
    if (folderName) {
      return this.http.get(
        this.api?.withFolder +
          `${folderName}?limit=${limit}&page=${pageNumber}` +
          `&query=${query}` +
          `&isExclusive=${isExclusive}`
      );
    } else {
      /* istanbul ignore next */
      return this.http.get(this.api?.withoutFolder + '?limit=150' + `&query=${query}` + `&isExclusive=${isExclusive}`);
    }
  }

  getNextFiles(folderName: string, pageNumber: any) {
    if (folderName) {
      return this.http.get(this.api?.withFileFolder + `${folderName}?page=${pageNumber}&limit=40`);
    } else {
      return this.http.get(this.api?.withoutFileFolder + `page=${pageNumber}&limit=40`);
    }
  }

  browseFiles(query: any, pageNumber: any, folderName: any) {
    if (folderName == '') {
      return this.http.get(this.api?.browseFile + `${query}&page=${pageNumber}&limit=40`);
    } else {
      return this.http.get(this.api?.browseFile + `browse/${folderName}?${query}&page=${pageNumber}&limit=40`);
    }
  }
}
