import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, GsiFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FilterConfiguration,
  SearchConfiguration,
  SortConfiguration,
  encodeString,
} from '../../../constants/customize-filter-templates.constants';
import { TemplateDialog } from '../template-dialog/template-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { PageViewComponent } from '../../page-view/page-view.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ColumnStyle } from '@common-services';
@Component({
  selector: 'app-entity-grid-table',
  templateUrl: './entity-grid-table.component.html',
  styleUrls: ['./entity-grid-table.component.scss'],
})
export class EntityGridTableComponent implements OnInit, AfterViewInit {
  @Input() attributeUIElementMap: any;
  @Input() longestRecordPos: number;
  @Input() filterConfigurations: FilterConfiguration;
  @Input() sortConfigurations: SortConfiguration;
  @Input() searchConfigurations: SearchConfiguration;
  @Input() tableData: any;
  @Input() columnStyles: ColumnStyle[] = [];
  @Input() nestedRowTableData: any;
  @Input() oddRowColor: any;
  @Input() evenRowColor: any;
  @Input() widthArray: any;
  @Input() headerBackground: any;
  @Input() nestedRowHeaderBackground: any;
  @Input() tableActionButtons: any;
  @Input() primaryEntityName: any;
  @Output() _updateTableData = new EventEmitter();
  @Output() _keyUpSearchField = new EventEmitter();
  @Output() _downloadData = new EventEmitter();
  @Input() paginatorNumber: number = 0;
  selectedSize: { name: string };
  selectedStyle: { class: string };
  tableWidth: number = 0;
  heightOfTable: string = 'auto';
  tenantfont: any;
  extraWidthPercent: string;
  displayTableWidth: string = '';
  gsiPosition: string = '';
  labels: any;
  ngUnsubscribe = new Subject();
  isMenuIconOnly: boolean = false;
  menuIconValue:string = '';
  hfilter: any;
isIconOnlyEnabled: any;
  @Input() set gsiConfigurations(value: any) {
    this.gsiPosition = value?.gsiPosition ? value?.gsiPosition.toLowerCase() : '';
    if (value?.gsiPosition?.toLowerCase().includes('inline')) {
      this.inTableGSIButtons = true;
      this.gsiColumnName = value?.gsiInlineTitle;
    } else {
      this.inTableGSIButtons = false;
      this.gsiColumnName = '';
    }
    if (value?.gsiPosition?.toLowerCase().includes('combine')) {
      this.isMore = true;
      this.isMenuIconOnly = value?.isIconOnlyEnabled;
      this.menuIconValue = value?.iconLink;
    } else {
      this.isMore = false;
    }
    if (value?.combineName) {
      this.combinationName = value?.combineName;
    } else {
      this.combinationName = '';
    }
    this.gsiStyle ={ ...value?.gsiStyle};
  }
  @Input() description: any;
  @Input() gridLines: any;
  @Input() pagination: any;
  @Input() headerFreeze: any;
  @Input() header: any;
  @Input() nestedRowHeader: any;
  @Input() evenRow: any;
  @Input() oddRow: any;
  @Input() advanceTableFeatures: any;
  selectedRecord: any;
  index: number = 0;
  entitySearchFields: any;
  @Input() searchConfigurationData: any[] = [];
  @Input() headerFooterDescription: any;
  @Input() conditionArray: any[];
  @Input() legendSelected: boolean;
  @Input() enableCompleteRecord: boolean;
  @Input() legendAlignment: any;
  @ViewChild('dropTableData') tableDataSearch: any;
  ngSubscribe = new Subject();
  isMore: boolean = false;
  inTableGSIButtons: boolean = false;
  combinationName: string = '';
  isHeaderFreeze: boolean = false;
  freezedColumns: any = {};
  searchData: any[] = [];
  name: string;
  sort: boolean = false;
  @Output() applyFilterAndSortEmit = new EventEmitter();
  @Output() sortEmit = new EventEmitter();

  evenHovered: number;
  oddHovered: number;
  hovered: 1 | -1;
  gsiColumnHovered: 1 | -1;
  columnHovered: number;
  serialNumberHover: 1 | -1;
  checkboxHover: 1 | -1;
  headerHovered: 1 | -1;
  footerHovered: 1 | -1;
  isHeaderDescription: boolean = true;
  isFooterDescription: boolean = true;
  gsiColumnName: any = '';
  resultFooter: any;
  resultHeader: any;
  gsiEvenRow: any;
  gsiOddRow: any;
  gsiStyle: any;
  gsiBtnHovered: any = [];
  numberOfButtons: number = 0;
  conditionMap: Map<number, any> = new Map();
  @Input() headerArray: any;
  @Input() isNestedRow2: boolean;
  @Input() exportConfigurations: any;
  @Input() enableAnalytics: boolean;
  isNestedRow1: boolean[] = [];
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() urlBindingDataConfiguration: any;
  widthArrayPercentage: any = [];
  public dialog: MatDialog;
  @Input() headerGsi: any = {};
  @Input() configurationName: string = "";
  isFilterHover: boolean[] = [];
  isSortHover: boolean[] = [];
  isSearchHover: boolean[] = [];
  tenantName: string ='';
  gsiConditioned : any = {
    attributeName: '',
    attributeValue: '',
    gsiTobeExcludedMasterID: -1
  };
  conditionAttributeIndex: number;
  constructor(
    private alertService: AlertService,
    private router: Router,
    private gsiFacadeService: GsiFacadeService,
    templateBaseInjector: Injector,
    private elementRef: ElementRef,
    private clipboard: Clipboard,
    private translator: TranslatorService,

  ) {
    this.dialog = templateBaseInjector?.get(MatDialog);
    this.detectLanguageChange();
  }

  ngOnInit(): void {}
  ngOnChanges(): void {
    this.setTenantFontFamily();
    this.tableWidth = 0;
    this.tenantName = localStorage.getItem('TenantName')
    if (this.tenantName.includes('defisolutions')) {
      this.gsiConditioned = {
        attributeName: 'Primary Status',
        attributeValue: 'Inactive',
        gsiTobeExcludedMasterID:  337770452668
      }
    }
    this.tableData?.[this.longestRecordPos]?.recordData?.forEach(
      (attr: any, index: number) => {
        if(this.tenantName.includes('defisolutions') && attr.attributeName ==  this.gsiConditioned.attributeName){
          this.conditionAttributeIndex = index;
        }
        this.tableWidth =
          this.tableWidth + parseInt(this.widthArray?.[index]?.split('p')[0]);
        if (attr.type === 'image') {
          const width = this.widthArray[index];
          let imageWidth = parseInt(width.split('p')[0]) - 30;
          const imgWidth = imageWidth.toString() + 'px';
          this.widthArray[index] = {
            width: width,
            widthImage: imgWidth,
          };
        }
      }
    );
    if (this.tableData.length == 0 && this.headerArray?.length > 0) {
      this.headerArray.forEach((attr: any, index: number) => {
        this.tableWidth =
          this.tableWidth + parseInt(this.widthArray?.[index]?.split('p')[0]);
      });
    }
    if (this.gsiPosition.includes('inline')) {
      this.tableWidth =
        this.tableWidth +
        parseInt(this.widthArray?.[this.widthArray?.length - 1]?.split('p')[0]);
    }
    this.tableWidth = this.updateTableWidthWithSnoCheckBox(
      this.tableWidth,
      this.conditionCheck()
    );
    this.extraWidthPercent = ((70 / this.tableWidth) * 100).toString() + '%';
    this.tableData?.[this.longestRecordPos]?.recordData?.forEach(
      (attr: any, index: number) => {
        this.isSortHover.push(false);
        this.isSearchHover.push(false);
        this.isFilterHover.push(false);
        const width = this.widthArray[index];
        let colWidth: any;
        if (width?.widthImage) colWidth = parseInt(width.width.split('p')[0]);
        else colWidth = parseInt(width.split('p')[0]);
        let colPer = (colWidth * 100) / this.tableWidth;
        if (attr.type === 'image') {
          const imgWidth = colWidth - 30;
          let imgPer = (imgWidth * 100) / this.tableWidth;
          if (width?.width) {
            this.widthArrayPercentage[index] = {
              width: colPer.toString() + '%',
              widthImage: imgPer.toString() + '%',
            };
          } else {
            this.widthArrayPercentage[index] = {
              width: colPer.toString() + '%',
              widthImage: imgPer.toString() + '%',
            };
          }
        } else {
          this.widthArrayPercentage[index] = colPer.toString() + '%';
        }
      }
    );

    if (this.tableData.length == 0 && this.headerArray?.length > 0) {
      this.headerArray.forEach((attr: any, index: number) => {
        const width = this.widthArray?.[index];
        let colWidth: any;
        colWidth = parseInt(width.split('p')[0]);
        let colPer = (colWidth * 100) / this.tableWidth;

        this.widthArrayPercentage[index] = colPer.toString() + '%';
      });
    }
    if (this.gsiPosition.includes('inline')) {
      const width = this.widthArray[this.widthArray?.length - 1];
      let colWidth: any;
      colWidth = parseInt(width.split('p')[0]);
      let colPer = (colWidth * 100) / this.tableWidth;
      this.widthArrayPercentage[this.widthArray?.length - 1] =
        colPer.toString() + '%';
    }
    this.tableData?.[0]?.recordData?.forEach((attr: any, index: number) => {
      this.searchData.push({ searchValue: '' });
    });
    if (typeof this.gsiStyle?.backgroundColor == 'string' ){
      let btnBg = this.gsiStyle.backgroundColor;
      let btnhover = this.gsiStyle.colorHover;
      let btnfontcolor = this.gsiStyle.fontColor;
      let btnfonthover = this.gsiStyle.fontHover;
      this.gsiStyle.backgroundColor = [];
      this.gsiStyle.colorHover = [];
      this.gsiStyle.fontColor = [];
      this.gsiStyle.fontHover = [];
      this.tableActionButtons?.forEach((btn: any) => {
        this.gsiStyle.backgroundColor.push(btnBg);
        this.gsiStyle.colorHover.push(btnhover);
        this.gsiStyle.fontColor.push(btnfontcolor);
        this.gsiStyle.fontHover.push(btnfonthover);
  
      });
    }
  
    this.tableActionButtons?.forEach((btn: any) => {
      this.gsiBtnHovered.push(false);
    });
    this.setDisplayWidth();
    this.setConditionColors();
  }

  setConditionColors() {
    this.tableData?.forEach((row: any, index: number) => {
      row?.recordData?.forEach((data: any) => {
        this.conditionArray.forEach((conditions: any) => {
          const {
            condition,
            conditionAttribute,
            fillColor,
            textColor,
            selectedConditonValue,
          } = conditions;
          if (
            data?.attributeName === conditionAttribute &&
            this.checkConditon(
              data?.value,
              condition,
              selectedConditonValue,
              data?.type
            )
          ) {
            this.conditionMap.set(index, conditions);
          }
        });
      });
    });
  }

  private checkConditon(
    value: string,
    condition: string,
    selectedConditonValue: string,
    type: string
  ): boolean {
    const updatedValue = type === 'number' ? parseInt(value) : value;
    switch (condition) {
      case '==': {
        return updatedValue == selectedConditonValue;
      }
      case '!=': {
        return updatedValue != selectedConditonValue;
      }
      case '<=': {
        return updatedValue <= selectedConditonValue;
      }
      case '>=': {
        return updatedValue >= selectedConditonValue;
      }
      case '<': {
        return updatedValue < selectedConditonValue;
      }
      case '>': {
        return updatedValue > selectedConditonValue;
      }
    }
  }
  private setDisplayWidth() {
    if (this.elementRef?.nativeElement?.offsetWidth < this.tableWidth) {
      this.displayTableWidth = this.tableWidth?.toString() + 'px';
    } else {
      this.displayTableWidth = '';
    }
  }

  private setTenantFontFamily() {
    this.tenantfont = localStorage.getItem('selectedFont');
    if (this.tenantfont) {
      try {
        this.tenantfont = JSON.parse(this.tenantfont);
      } catch {
        this.tenantfont = null;
      }
    }
    return this.tenantfont?.fontName;
  }

  updateTableWidthWithSnoCheckBox(width: any, numberOfPieces: number): number {
    return width + numberOfPieces * 70;
  }

  conditionCheck(): number {
    let sum = 0;
    if (this.isNestedRow2) {
      sum++;
    }
    if (this.advanceTableFeatures?.checkboxSelection) {
      sum++;
    }
    if (this.advanceTableFeatures?.serialNumbers) {
      sum++;
    }
    return sum;
  }

  updateTableData(event: any, option: any, searchField: any) {
    this._updateTableData.emit({ event, option, searchField });
  }
  keyUpSearchField(value: any) {
    this._keyUpSearchField.emit({ attr: value });
  }
  downloadData(type: string, total?: number) {
    this._downloadData.emit({ type: type, total: total });
  }
  gotoTxnPage(gsi: any, card?: any, event?: MouseEvent) {
    /*istanbul ignore next*/
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (card || gsi?.isUnrelated) {
      if (this.urlBindingDataConfiguration?.masterId == gsi.masterId && this.urlBindingDataConfiguration?.attributeId) {
        let index: number = -1;
        if (this.columnStyles.length > 0) {
          let finalColIndex: number = -1;
          card[0].recordData.forEach((attr: any, i: number) => {
            let col = attr.attributeName.split('.');
            let colIndex: number = -1;
            if (col.length > 0) {
              colIndex = col.findIndex((att: any) => att == this.urlBindingDataConfiguration?.attributeName);
              if (colIndex != -1) {
                finalColIndex = colIndex;
                index = i;
              }
            }
          });
          if (index != -1 && finalColIndex != -1) {
            if(this.urlBindingDataConfiguration?.isConfigName && this.configurationName) this.router.navigateByUrl('/navigate/' + this.configurationName + '/' +  card[0]?.recordData[index]?.value?.[finalColIndex]);
            else   this.router.navigateByUrl(
              '/browse/' + this.primaryEntityName + '/' + card[0]?.recordData[index]?.value?.[finalColIndex]
            );
          }
        } else {
          index = card[0].recordData.findIndex(
            (attr: any) => attr.attributeName == this.urlBindingDataConfiguration?.attributeName
          );
          if (index != -1) {
           this.router.navigateByUrl('/browse/' + this.primaryEntityName + '/' + card[0]?.recordData[index]?.value);
          }
        }
      } else if (gsi?.entityType != 'PAGE') {
        const txnData = this.multipleEntitiesPayLoad(gsi, card);
        /* istanbul ignore next */
        localStorage.setItem('gsi-masterId', gsi?.masterId);
        if (gsi.popUp || gsi?.enablePopup) {
          this.openDialog(txnData);
        } else {
          this.router.navigate(['transaction/view/' + txnData]);
        }
      } else if (gsi?.entityType == 'PAGE') {
        if (!gsi?.popUp && !gsi?.enablePopup) {
          this.router.navigate(
            [
              `view/${encodeString(
                card?.[0]?.entitySendGsiName
                  ? card?.[0]?.entitySendGsiName
                  : this.primaryEntityName
              )}/${encodeString(card?.[0]?.recordId.toString())}`,
            ],
            {
              queryParams: { viewname: encodeString(gsi?.name) },
            }
          );
        } else {
          this.openPageDialog(card, gsi);
        }
      }
    } else {
      this.alertService.showToaster(
        this.labels?.['Pls_select_a_record_to_go_to_Transaction'] || 'Pls select a record to go to Transaction',
        '',
        'error'
      );
    }
  }
  openPageDialog(card: any, gsi: any) {
    let dialogInstance = this.dialog.open(PageViewComponent);
    if (dialogInstance) {
      dialogInstance.componentInstance.isRouted = false;
      dialogInstance.componentInstance.viewName = gsi?.name;
      dialogInstance.componentInstance.recordId = card?.[0]?.recordId;
      dialogInstance.componentInstance.entityName = card?.[0]?.entitySendGsiName
        ? card?.[0]?.entitySendGsiName
        : this.primaryEntityName;
      dialogInstance.componentInstance.emitClosePage.subscribe((res) => {
        if (res) {
          this.closeDialog();
        }
      });
    }
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  setHover(i: number, j?: number) {
    this.evenHovered = i;
    this.oddHovered = i;
    this.columnHovered = j;
  }

  setOutHover(i: number) {
    this.oddHovered = -1;
    this.evenHovered = -1;
    this.columnHovered = -1;
  }
  openDialog(solutionData: any): void {
    const dialogRef = this.dialog.open(TemplateDialog, {
      panelClass: 'mccGsiModal',
      data: { solutionData },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.applyFilterAndSort(true, true);
    });
  }
  sortFunction(attributeName: string) {
    this.sort = !this.sort;
    this.sortEmit.emit({
      sortOption: this.sort,
      sortOptionAttr: attributeName,
    });
  }
  applyFilterAndSort(
    isSearch?: boolean,
    isSearchFields?: boolean,
    isSendPayload?: boolean,
    searchOrFilter?: string,
    idForCard?: any,
    page?: any,
    isHeaderSearch?: {
      checked: boolean;
      searchAttribute: string;
      searchValue: string;
    }
  ) {
    this.applyFilterAndSortEmit.emit({
      isSearch: isSearch,
      isSearchFields: isSearchFields,
      isSendPayload: isSendPayload,
      searchOrFilter: searchOrFilter,
      idForCard: idForCard,
      page: page,
      isHeaderSearch: isHeaderSearch,
    });
  }

  private multipleEntitiesPayLoad(gsi: any, card: any, res?: any): any {
    if (gsi?.isUnrelated) {
      const txnData = btoa(
        JSON.stringify({
          gsiId: res?.result?.id ? res?.result?.id : gsi.id,
          gsiMasterId: gsi?.gsiMasterId,
        })
      );
      return txnData;
    }
    let recordIdsList: any[] = [];
    let quantitiesList: any[] = [];
    card = this.makeArray(card);
    card?.forEach((cardList: any) => {
      recordIdsList.push(cardList.recordId);
      quantitiesList.push(1);
    });

    const txnData = btoa(
      JSON.stringify({
        gsiId: res?.result?.id ? res?.result?.id : gsi.id,
        gsiMasterId: gsi.masterId,
        quantitiesList: [quantitiesList],
        recordIdsList: [recordIdsList],
        entities: [this.primaryEntityName],
      })
    );
    return txnData;
  }
  makeArray(card: any) {
    if (Array.isArray(card)) {
      return card;
    } else if (card?.recordId) {
      card = [card];
      return card;
    }
  }
  hyperlinkAttributeOpen(name: any, link: any, event: any, index?: number) {
    if (this.attributeUIElementMap.get(name) == 'hyperlink') {
      window.open(link, '_blank');
      event?.stopPropagation();
    } else if (this.headerGsi?.[name]) {
      this.gotoTxnPage(this.headerGsi?.[name], [this.tableData[index]], event);
      event?.stopPropagation();
    } else if (this.urlBindingDataConfiguration?.attributeName == name) {
      this.router.navigateByUrl(
        '/browse/' + this.primaryEntityName + '/' + link
      );
      event?.stopPropagation();
    }
  }
  @ViewChild('buttonContainer', { static: false }) buttonContainer:
    | ElementRef
    | undefined;
  ngAfterViewInit() {
    /* istanbul ignore next*/
    if (this.buttonContainer) {
      const buttons = this.buttonContainer?.nativeElement?.querySelectorAll(
        'button'
      );
      this.numberOfButtons = buttons?.length;
    }

    if (this.tableDataSearch?.el?.nativeElement) {
      const style = getComputedStyle(this.tableDataSearch?.el?.nativeElement);
      this.heightOfTable = style.height;
    }
  }
  cancelSearch() {
    this.searchData[this.index].searchValue = '';
    this.applyFilterAndSort(true);
  }

  closeMatMenu() {
    this.trigger.closeMenu();
  }
  menuClosed() {
    this.hfilter = "";
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setDisplayWidth();
  }
  copyLinkToClipboard(link: string, name: string, event: any ,index?:number) {
    let url = window.location.origin;
    if (this.urlBindingDataConfiguration?.attributeName == name) {
      url = url + ((this.urlBindingDataConfiguration?.isConfigName && this.configurationName)?('/navigate/' + this.configurationName + '/' +  link ):('/browse/' + this.primaryEntityName + '/' + link));

    } else if (this.headerGsi?.[name]?.entityType != 'PAGE') { 
      const txnData = this.multipleEntitiesPayLoad(this.headerGsi?.[name], [this.tableData[index]]);
      url += '/transaction/view/' + txnData;
     } else if (this.headerGsi?.[name]?.entityType == 'PAGE') {
      url = url +
      `/view/${encodeString(this.primaryEntityName)}/${encodeURIComponent(
        encodeString([this.tableData[index]]?.[0]?.recordId?.toString())
      )}?viewname=${encodeString(this.headerGsi?.[name]?.name)}`;
     }
    this.clipboard.copy(url);
    this.alertService.showToaster('Link Copied to Clipboard', '', 'success');
    event?.stopPropagation();   
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.labels = res;
      }
    });
  }
}
