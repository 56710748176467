import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { UploadFacadeService } from '../upload/upload-facade.service';

@Injectable({
  providedIn: 'root',
})
export class OCREndpointService extends CduiBaseService {
  constructor(private uploadFacadeService: UploadFacadeService, private http: HttpClient, private injector: Injector) {
    super(injector);
  }

  fileUpload(file: File) {
    return this.uploadFacadeService.dynamicFormUploadFiles(file, 1);
  }
  saveOCRGSIandFileMapping(data: any) {
    return this.http.post(this.api?.ocrExtractionUrl, data);
  }

  saveOCRMappingData(data: any) {
    return this.http.post(this.api?.ocrSetMappingUrl, data);
  }

  manualMappedAttribute(data: any) {
    return this.http.post(this.api?.ocrManualMapping, data);
  }

  getOCRMappings(data: any) {
    // get and post both methods are available
    return this.http.post(this.api?.ocrGetMappingUrl, data);
  }
}
