import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventsFacadeService, FieldConfig, TranslatorService, dependentAttributeValue, getAttributeValue, setAttributeValue, getUiControlCustomization, setErrorDependentAttribute, TransactionFacadeService } from '@common-services';
import { DateAdapter } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { DateTimePickerComponent } from 'ngx-datetime-picker';
import * as moment from 'moment';

@Component({
  selector: 'app-datetime',
  styleUrls: ['./datetime.component.scss'],
  templateUrl: './datetime.component.html',
  styles: [
    `
      .datetimedisplay {
        width: 100%;
        margin-top: 15px;
      }
    `,
  ],
})
export class DatetimeComponent implements OnInit, OnDestroy, AfterViewInit {
  field: FieldConfig;
  group: FormGroup;
  minDate: Date;
  maxDate: Date;
  myFilter: any;
  isCurrentDate: boolean = false;
  dateTime: Date;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  dateChanged: Subject<any> = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  requiredDateFormat: string = 'MM/dd/yyyy';
  requiredFormatPrime: string = 'mm/dd/yy';
  requiredTimeFormat: string = '24-hr';
  format: string = 'HH:mm';
  formattedValue: any;
  infoValue: Date;
  meridiem: string = '';
  @ViewChild('dateFormatter') updateDateInput: DateTimePickerComponent;
  private ngUnsubscribe = new Subject();
  isDisabled: boolean = true;
  labels: any;
  tempValue: any = null;
  isTimeHidden: boolean = false;
  appliedClass: string = 'ui-custom-calendar ui-custom-datetime';
  foundObject: any;
  bodyAppend: string = 'body';
  slash1 = 2;
  slash2 = 5;
  
  validationList: any[] = [
    'onorafterValidation',
    'afterValidation',
    'onorbeforeValidation',
    'beforeValidation',
    'equalTo',
    'notequalTo',
    'pastorpresent',
    'present',
    'past',
    'future',
    'presentorfuture',
    'pastorfuture',
  ];
  calendarDateClicked: boolean=false;
  invalidDate: boolean = false;
  isMulti: any = false;

  get getDateTimePlaceHolder(){

    if(this.labels?.[this.requiredDateFormat] && this.isTimeHidden){
      return this.labels?.[this.requiredDateFormat];
    }else if(this.labels?.[this.requiredDateFormat] && this.labels?.Hhmmss ){
      return (this.labels?.[this.requiredDateFormat] +' '+ this.labels?.Hhmmss).toUpperCase();
    }else if(this.labels?.[this.requiredDateFormat]){
      return (this.labels?.[this.requiredDateFormat] + ' HH:MM:SS').toUpperCase();
    }else if(this.labels?.Hhmmss){
      return (this.requiredDateFormat +' '+ this.labels?.Hhmmss).toUpperCase();
    }else{
      return (this.requiredDateFormat +' '+ ' HH:MM:SS').toUpperCase();
    }
  }

  constructor(
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    public dialog: MatDialog,
    private dateAdapter: DateAdapter<any>,
    private rendrer: Renderer2,
    public transactionFacadeService: TransactionFacadeService
  ) {
    this.detectLanguageChange();
  }
  ngAfterViewInit(): void {
    // this.updateDateInput.offClick = (event: any) => {
    //   this.rendrer.addClass(document.body, 'expand-table-date');
    //   this.updateDateInput.offClick(event);
    // };

    //@ts-ignore
    if (this.field?.type == 'currentdatetime' && this.updateDateInput.eRef && this.updateDateInput?.eRef?.nativeElement?.getElementsByClassName('ngx-picker')?.[0]?.style?.pointerEvents) {
      //@ts-ignore
      this.updateDateInput.eRef.nativeElement.getElementsByClassName('ngx-picker')[0].style.pointerEvents = 'none';
    }
    this.updateDateFormat();
    /*istanbul ignore next*/
    if (this.updateDateInput?.isMobile) {
      this.isDisabled = false;
    }
  }

  closeFix(event: any) {
    /* istanbul ignore next */
    if ((<any>document.querySelector('ngx-date'))?.hidden == false) {
      document.body?.classList.add('expand-table1');
    } else if ((<any>document.querySelector('ngx-date'))?.hidden == true) {
      document.body?.classList.remove('expand-table1');
    }

    /* istanbul ignore next */
    if (event.target.nodeName == 'NGX-DATE') {
      this.updateDateInput.setPickerVisible(false);
      document.body?.classList.remove('expand-table1');
    }
  }

  updateDateFormat() {
    //@ts-ignore
    if (
      /*istanbul ignore next*/
      //@ts-ignore
      this.updateDateInput?.eRef?.nativeElement?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.value &&
      //@ts-ignore
      this.updateDateInput?.eRef?.nativeElement?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.value != ''
    ) {
      //@ts-ignore
      const dateAndTime = this.updateDateInput?.eRef?.nativeElement?.children?.[0]?.children[0]?.children?.[0]?.children?.[0]?.value?.split(
        ' '
      );
      const stringArray = dateAndTime[0].split('/');
      /* istanbul ignore else */
      if (this.requiredDateFormat === 'dd/MM/yyyy') {
        /*istanbul ignore next*/
        //@ts-ignore
        this.updateDateInput.eRef.nativeElement.children[0].children[0].children[0].children[0].value =
          stringArray[1] + '/' + stringArray[0] + '/' + stringArray[2] + ' ' + dateAndTime[1] + ' ' + this.meridiem;
      } else if (this.requiredDateFormat === 'yyyy/MM/dd') {
        /*istanbul ignore next*/
        //@ts-ignore
        this.updateDateInput.eRef.nativeElement.children[0].children[0].children[0].children[0].value =
          stringArray[2] + '/' + stringArray[0] + '/' + stringArray[1] + ' ' + dateAndTime[1] + ' ' + this.meridiem;
      }
    }
  }
  ngOnInit(): void {
    this.isMulti = this.field?.isMulti
    this.languageChange();
    /*istanbul ignore next*/
    this.field?.validations?.forEach((obj) => {
      /* istanbul ignore next */
      if (obj.name != 'required') {
        this.setMinAndMaxDate(obj.name, obj);
      }
    });
    /*istanbul ignore next*/
    if (this.field?.configuration?.isTimeHidden) {
      this.isTimeHidden = this.field.configuration.isTimeHidden;
    }
    /*istanbul ignore next*/
    if (this.field?.configuration?.dateTimeFormat?.format) {
      /*istanbul ignore next*/
      this.requiredDateFormat = this.field.configuration?.dateTimeFormat?.format;
    } else if (this.field.attributeType?.properties?.format) {
      /*istanbul ignore next*/
      this.requiredDateFormat = this.field.attributeType?.properties?.format;
    }
    this.requiredFormatPrime = this.eventsService.convertDateFormat(this.requiredDateFormat);
    /*istanbul ignore next*/
    if (this.field?.configuration?.dateTimeFormat?.timeFormat) {
      /*istanbul ignore next*/
      this.requiredTimeFormat = this.field.configuration?.dateTimeFormat?.timeFormat;
    } else if (this.field.attributeType?.properties?.timeFormat) {
      /*istanbul ignore next*/
      this.requiredTimeFormat = this.field.attributeType?.properties?.timeFormat;
    }
    this.createForm();
    /* istanbul ignore next */
    if (this.field?.type === 'currentdatetime') {
      this.field.readonly = true;
      /* istanbul ignore next */
      this.group?.controls[this.field?.attribute?.name].setValue(new Date());
      if (this.field?.value == '' || this.field?.value == null) {
        this.field.value = this.group?.controls[this.field?.attribute?.name].value;
      }
    }
    /* istanbul ignore next */
    if (this.field?.value) {
      this.infoValue = new Date(this.field?.value);
      this.field.value = new Date(this.field.value);
      this.updateTimeFormatWithoutOnchange(this.field.value);
    } else {
      this.field.value = null;
    }
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    this.dateChanged.next(true);
    let dateOption = getUiControlCustomization('DatePicker');
    if (dateOption) {
      this.foundObject = { event: dateOption };
      let op = dateOption.slice(-1);
      if (dateOption == 'Option 7') {
        op = 1;
      }
      this.appliedClass = `form-custom-date form-custom-date-opt${op}`;
    }
    let mccPopup = sessionStorage.getItem('template');
    this.bodyAppend = mccPopup?.includes('cupopup')
      ? (this.field.attribute['isTableConfig'] ? 'body' : '')
      : !this.foundObject?.event || this.foundObject?.event == ''
      ? 'body'
      : '';
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.languageChange();
    });
  }
  languageChange() {
    this.dateAdapter.setLocale(localStorage.locale);
  }

  onBlur(event?: any) {
    if (this.field.value != this.tempValue || this.calendarDateClicked) {
      this.updateTimeFormat(this.field.value);
      this.doneClicked();
    }
    this.calendarDateClicked = false;
  }

  updateTimeFormat(event: any) {
    this.tempValue = this.field.value;
    if (this.requiredTimeFormat === '12-hr') {
      this.format = 'hh:mm';
      let time;
      if (Object.prototype.toString.call(event) !== '[object Date]') {
        time = event.split(',')[1];
      } else if (Object.prototype.toString.call(event) === '[object Date]') {
        time = event.toTimeString();
      } else {
        const d = new Date();
        time = d.toTimeString();
      }
      if (time && parseInt(time.split(':')[0]) >= 12 && parseInt(time.split(':')[0]) != 24) {
        this.meridiem = 'pm';
      } else {
        this.meridiem = 'am';
      }
    }
    /* istanbul ignore next */
    this.field.value = event;
    if (this.group?.controls) this.group.controls[this.field?.attribute?.name].setValue(event);
  }

  updateTimeFormatWithoutOnchange(event: any) {
    if (this.requiredTimeFormat === '12-hr') {
      this.format = 'hh:mm';
      let time;
      if (Object.prototype.toString.call(event) !== '[object Date]') {
        time = event.split(',')[1];
      } else if (Object.prototype.toString.call(event) === '[object Date]') {
        time = event.toTimeString();
      } else {
        const d = new Date();
        time = d.toTimeString();
      }
      if (time && parseInt(time.split(':')[0]) >= 12 && parseInt(time.split(':')[0]) != 24) {
        this.meridiem = 'pm';
      } else {
        this.meridiem = 'am';
      }
    }
    /* istanbul ignore next */
    this.field.value = event;
    this.tempValue = this.field.value;
    if (this.group?.controls) this.group.controls[this.field?.attribute?.name].setValue(event);
  }

  nowClicked() {
    this.updateTimeFormat(new Date());
    this.doneClicked();
  }

  doneClicked() {
    this.onChange(this.field.value);
  }

  /**
   * Creates form
   */
  createForm() {
    let control = new FormControl();
    /* istanbul ignore next */
    this.group?.addControl(this.field?.attribute?.name, control);
  }

  notBeforeDaysFunction(obj: any) {
    if (obj?.notBeforeDays) {
      this.minDate = new Date(new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000);
    } else {
      /* istanbul ignore next */
      this.minDate = new Date(obj?.notBeforeDate);
    }
  }

  notAfterDaysFunction(obj: any) {
    if (obj?.notAfterDays) {
      this.maxDate = new Date(new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000);
    } else {
      /* istanbul ignore next */
      this.maxDate = new Date(obj?.notAfterDate);
    }
  }

  setMinAndMaxDate(type: any, obj?: any) {
    switch (type) {
      case 'pastorpresent':
        this.maxDate = new Date();
        this.notBeforeDaysFunction(obj);
        break;

      case 'present':
        this.maxDate = this.minDate = new Date();
        break;

      case 'past':
        let maxdateObj = new Date();
        maxdateObj.setDate(maxdateObj.getDate() - 1);
        this.maxDate = maxdateObj;
        this.notBeforeDaysFunction(obj);
        break;

      case 'future':
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() + 1);
        this.minDate = dateObj;
        this.notAfterDaysFunction(obj);
        break;

      case 'presentorfuture':
        this.minDate = new Date();
        this.notAfterDaysFunction(obj);
        break;

      case 'pastorfuture':
        this.notBeforeDaysFunction(obj);
        this.notAfterDaysFunction(obj);
        break;
    }
  }

  dateToEpoch(date: any) {
    return date.setHours(0, 0, 0, 0);
  }

  validateDependentExpressionDate(validation: any) {
    let min: Date,
      max: Date,
      flag = false;
    max = min = this.getAfterDate(validation, this.field) || new Date(validation?.value);
    if (max) {
      min.setHours(0, 0, 0, 0);
      max.setHours(0, 0, 0, 0);
    }
    if((this.group?.controls[this.field?.attribute.name]?.value!=null &&this.group?.controls[this.field?.attribute.name]?.value!=undefined &&this.group?.controls[this.field?.attribute.name]?.value!='')){
    switch (validation.name) {
      case 'onorafterValidation':
      case 'afterValidation':
        /* istanbul ignore else */
        if (min != undefined) {
          if (this.group?.controls[this.field?.attribute?.name]?.value?.getTime() < min?.getTime()) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
        }
        break;
      case 'onorbeforeValidation':
      case 'beforeValidation':
        /* istanbul ignore else */
        if (max != undefined) {
          if (this.group?.controls[this.field?.attribute?.name]?.value?.getTime() > max?.getTime()) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
        }
        break;
      case 'equalTo':
        /* istanbul ignore next */
        if (this.group?.controls[this.field?.attribute?.name]?.value?.getTime() != max?.getTime()) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;
      case 'pastorpresent':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value?.setHours(0,0,0,0) < this.minDate?.setHours(0,0,0,0) ||
          this.group?.controls[this.field?.attribute?.name]?.value?.setHours(0,0,0,0) > this.maxDate?.setHours(0,0,0,0)
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'present':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'past':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'future':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'presentorfuture':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'pastorfuture':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;
    }
  }
    this.group?.status === 'INVALID'
      ? this.transactionFacadeService.disableSubmitButtonFlag.next(true)
      : this.transactionFacadeService.disableSubmitButtonFlag.next(false);
    return flag;
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return this.validateDependentExpressionDate(validation);
    }
    /* istanbul ignore next */
    return this.group?.get(String(this.field?.attribute?.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).toString().match(validation.requiredPattern) ? false : true;
    }
  }

  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange(event?: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* istanbul ignore next */
      if (this.field?.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
      /* istanbul ignore next */
      //@ts-ignore
      if (
        //@ts-ignore
        this.updateDateInput?.eRef?.nativeElement?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.style
          ?.opacity ||
        //@ts-ignore
        this.updateDateInput?.eRef?.nativeElement?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.style
          ?.opacity == ''
      ) {
        //@ts-ignore
        this.updateDateInput.eRef.nativeElement.children[0].children[0].children[0].children[0].style.opacity = 0;
      }
      setTimeout(() => {
        this.updateDateFormat();
        //@ts-ignore
        if (
          //@ts-ignore
          this.updateDateInput?.eRef?.nativeElement?.children?.[0]?.children?.[0]?.children?.[0]?.children?.[0]?.style
            ?.opacity
        ) {
          //@ts-ignore
          this.updateDateInput.eRef.nativeElement.children[0].children[0].children[0].children[0].style.opacity = '';
        }
      }, 500);
    }
  }

  /**
   * Determines whether load on and triggers on load event
   */
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    }
  }
  
  dateClicked(event: any) {
    this.field?.validations?.forEach((obj) => {
      let min, max, date;
      max = min = this.getAfterDate(obj, this.field);
      switch (obj.name) {
        case 'onorafterValidation':
          /* istanbul ignore else */
          if (min) {
            this.minDate = min;
          }
          break;
        case 'afterValidation':
          /* istanbul ignore else */
          if (min != undefined) {
            date = new Date(min);
            date?.setDate(date?.getDate() + 1);
            this.minDate = date;
          }
          break;
        case 'onorbeforeValidation':
          /* istanbul ignore else */
          if (max) {
            this.maxDate = max;
          }
          break;
        case 'beforeValidation':
          /* istanbul ignore else */
          if (max != undefined) {
            date = new Date(max);
            date?.setDate(date?.getDate() - 1);
            this.maxDate = date;
          }
          break;
        case 'equalTo':
          /* istanbul ignore else */
          if (max != undefined) {
            this.maxDate = max;
          }
          /* istanbul ignore else */
          if (min != undefined) {
            this.minDate = min;
          }
          break;
      }
    });
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }

    this.field?.validations?.forEach((obj) => {
      /* istanbul ignore next */
      if (obj.name != 'required') {
        this.setMinAndMaxDate(obj.name, obj);
      }
    });
  }
  getAfterDate(obj: any, field: any) {
    /*istanbul ignore next*/
    let dE = obj?.dependentExpression;
    let formData = this.eventsService?.formData;
    /* istanbul ignore else */
    if (dE) {
      setAttributeValue();
      dependentAttributeValue(dE, formData, false, field);
      let val = getAttributeValue();
      return val ? new Date(val) : val;
    }
  }

  inputChange(event: any) {
    var currDate = event.currentTarget.value;
    if (event.data && event.data != '/') {
      if (currDate.length == this.slash1) {
        event.currentTarget.value = currDate + '/';
      } else if (currDate.length == this.slash2) {
        event.currentTarget.value = currDate + '/';
      } else if (currDate.length == 10) {
        event.currentTarget.value = currDate + ' ';
      } else if (currDate.length == 13 || currDate.length == 16) {
        event.currentTarget.value = currDate + ':';
      }
    }
    this.invalidDate =
      currDate?.length >= 19 &&
      !moment(currDate, this.requiredDateFormat.toUpperCase() + " HH:mm:ss", true).isValid();
  }

  clearCalendar() {
    this.field.value = '';
  }

  registerOnChange(event: any){
    this.calendarDateClicked = false;
    if (!event || event == '' ) {
      return;
    } else {
      this.updateTimeFormat(this.field.value);
    }
  }

  ngOnDestroy() {
    if (this.field?.value) this.updateTimeFormatWithoutOnchange(this.field.value.toString());
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
