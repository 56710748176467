<div>
  <div>
    <mat-dialog-actions >
      <button mat-button mat-dialog-close class="btnprcd pull-right" type="button" >Close</button>
    </mat-dialog-actions>
    
    <br />
  </div>
  <div class="message">
    {{ labels?.This_modification_will_not_be_applied_in_the_following_places }}
  </div>
  <div *ngIf="relatedBetsData.length > 0">
    <hr class="line" />
    <p>{{ title }}</p>
    <br />
    <p-tree
      [value]="treeItems"
      [(selection)]="selectedFiles"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)"
      selectionMode="single"
    >
    </p-tree>
  </div>
</div>
