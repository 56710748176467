<div pTooltip="{{ field.label }}" tooltipPosition="top" tooltipStyleClass="transcustomtolltipbook">
  <div
    [formGroup]="group"
    pTooltip="{{ field?.label }}"
    tooltipPosition="top"
    tooltipStyleClass="transcustomtolltipbook"
    class="form-input-field"
  >
    <div class="w-100 basic-slider a" *ngIf="field.type == 'basicslider'">
      <div class="d-flex">
        <label
          class="main-label"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >
          <span class="main-label-inner"
            ><span
              class="main-label-text text-truncate"              
              >{{ field.label }} :</span
            >
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
            <span class="value-span">{{ group.value[field?.attribute?.name] }}</span></span
          >
        </label>
      </div>
      <div class="default-slider" *ngIf="appliedClass == ''; else customSlider">
        <p-slider [formControlName]="field?.attribute?.name" [max]="maxValue" (onSlideEnd)="onChange($event)"></p-slider>
      </div>
      <ng-template #customSlider>
        <div class="{{ appliedBackgroundClass }}">
          <mat-slider
            class="{{ appliedClass }}"
            [formControlName]="field?.attribute?.name"
            [max]="maxValue"
            (onSlideEnd)="onChange($event)"
          ></mat-slider>
        </div>
      </ng-template>
      <div class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10" *ngIf="
                    field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
                    (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
                  ">
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="addAttribute.next()">add</mat-icon>
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="removeAttribute.next()">remove</mat-icon>
      </div>
    </div>

    <div class="w-100 basic-slider input-slider" *ngIf="field.type == 'inputslider'">
      <mat-form-field appearance="standard" class="custom-input-mat mb-2">
        <div class="d-flex">
          <label
            class="main-label"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          >
            <span class="main-label-inner"
              ><span
                class="main-label-text text-truncate"
                [style.color]="field.color"
                [style.font-size.px]="field.fontSize"
                >{{ field.label }} :</span
              >
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
            </span>
          </label>
        </div>
        <input
          (keyup)="updateValue($event); onlyNumberKey($event)"
          class="w-100"
          matInput
          type="text"
          [value]="group.value[field?.attribute?.name]"
          [formControlName]="field?.attribute?.name"
          pInputText
        />
      </mat-form-field>
      <div class="default-slider" *ngIf="appliedClass == ''; else customSlider">
        <p-slider
          [formControlName]="field?.attribute?.name"
          [(ngModel)]="(group?.value)[field?.attribute?.name]"
          [max]="maxValue"
          (onSlideEnd)="onChange($event)"
        ></p-slider>
        <div class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10" *ngIf="
                    field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
                    (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
                  ">
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="addAttribute.next()">add</mat-icon>
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="removeAttribute.next()">remove</mat-icon>
      </div>
      </div>
      <ng-template #customSlider>
        <div class="{{ appliedBackgroundClass }}">
          <mat-slider
            class="{{ appliedClass }}"
            [formControlName]="field?.attribute?.name"
            [(ngModel)]="(group?.value)[field?.attribute?.name]"
            [max]="maxValue"
            (onSlideEnd)="onChange($event)"
          ></mat-slider>
        </div>
        <div class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10" *ngIf="
                    field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
                    (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
                  ">
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="addAttribute.next()">add</mat-icon>
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="removeAttribute.next()">remove</mat-icon>
      </div>
      </ng-template>
    </div>

    <!-- <div class="w-100" *ngIf="field.type == 'rangeslider'">
      <label class="main-label" *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel &&
            !field?.hideLabelMultiAttr"
        >{{ field.label }} : <span class="value-span">{{ group.value[field?.attribute?.name] }}</span></label
      >
      <p-slider [formControlName]="field?.attribute?.name" [range]="true" [max]="maxValue"></p-slider>
    </div> -->

    <div class="w-100 basic-slider a" *ngIf="field.type == 'stepslider'">
      <div class="d-flex">
        <label
          class="main-label"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >
          <span class="main-label-inner"
            ><span
              class="main-label-text text-truncate"
              [style.color]="field.color"
              [style.font-size.px]="field.fontSize"
              >{{ field.label }} :</span
            >
            <span class="value-span">{{ group.value[field?.attribute?.name] }}</span>
          </span>
        </label>
      </div>
      <div class="default-slider" *ngIf="appliedClass == ''; else customSlider">
        <p-slider [formControlName]="field?.attribute?.name" [step]="stepValue" [max]="maxValue" (onSlideEnd)="onChange($event)"></p-slider>
      </div>
      <ng-template #customSlider>
        <div class="{{ appliedBackgroundClass }}">
          <mat-slider
            class="{{ appliedClass }}"
            [formControlName]="field?.attribute?.name"
            [step]="stepValue"
            [max]="maxValue"
            (onSlideEnd)="onChange($event)"
          ></mat-slider>
        </div>
      </ng-template>
      <div class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10" *ngIf="
                    field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
                    (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
                  ">
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="addAttribute.next()">add</mat-icon>
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="removeAttribute.next()">remove</mat-icon>
      </div>
    </div>

    <div class="w-100" *ngIf="field.type == 'verticalslider'">
      <div class="d-flex">
        <label
          class="main-label"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >
          <span class="main-label-inner"
            ><span
              class="main-label-text text-truncate"
              [style.color]="field.color"
              [style.font-size.px]="field.fontSize"
              >{{ field.label }} :</span
            >
            <span class="value-span" *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])">{{
              group.value[field?.attribute?.name]
            }}</span></span
          >
        </label>
      </div>
      <ng-container *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])">
        <div class="default-slider" *ngIf="appliedClass == ''; else customSlider">
          <p-slider [formControlName]="field?.attribute?.name" orientation="vertical" [max]="maxValue" (onSlideEnd)="onChange($event)"></p-slider>
        </div>
        <ng-template #customSlider>
          <div class="{{ appliedBackgroundClass }}">
            <mat-slider
              class="{{ appliedClass }}"
              [formControlName]="field?.attribute?.name"
              orientation="vertical"
              [max]="maxValue"
              (onSlideEnd)="onChange($event)"
            ></mat-slider>
          </div>
        </ng-template>
      </ng-container>
      <!--vertical slider in table-->
      <div class="vertical-slider-in-table" *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']">
        <div class="enter-slider-value" (click)="op.toggle($event)">
          <span>{{ group.value[field?.attribute?.name] }}</span>
        </div>
        <p-overlayPanel #op styleClass="ui-control-dropdown vertical-slider-in-table">
          <div class="vertical-slider">
            <p-slider [formControlName]="field?.attribute?.name" orientation="vertical" [max]="maxValue" (onSlideEnd)="onChange($event)"></p-slider>
          </div>
        </p-overlayPanel>
      </div>
      <div class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10" *ngIf="
                    field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
                    (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
                  ">
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="addAttribute.next()">add</mat-icon>
        <mat-icon *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
          [ngClass]="{ 'mat-icon-disable': field.readonly }" matSuffix (click)="removeAttribute.next()">remove</mat-icon>
      </div>
    </div>

    <ng-container *ngFor="let validation of field?.validations" ngProjectAs="mat-error">
      <mat-error
        *ngIf="
          validation.type &&
          (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
          getErrorMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
      <mat-error
        [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
        *ngIf="
          validation.type &&
          validation.type !== 'ERROR' &&
          validation.type !== 'BLOCK_WARN' &&
          getInfoWarnMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
    </ng-container>
  </div>
</div>
