<link *ngIf="fontUrl" [href]="fontUrl | safeResourceUrl" rel="stylesheet" type="text/css" />
<!-- For display value of bgStyleContent for cypress testcases. It will always be hidden -->
<div style="display: none;">
  <label *ngIf="bgStyleContent.backgroundImage">
    <span id="backgroundImage_{{ getFileNameFromImgUrl(bgStyleContent.backgroundImage) }}">
      {{ getFileNameFromImgUrl(bgStyleContent.backgroundImage) }}
    </span>
  </label>
  <label *ngIf="bgStyleContent.opacity">
    <span id="opacity_{{ bgStyleContent.opacity * 100 }}">
      {{ bgStyleContent.opacity * 100 }}
    </span>
  </label>
</div>
<!-- {{bgStyleContent | json}} -->
<div [ngClass]="{ 'd-none': isLmstemplate }">
  <div class="bg-img position-fixed" [ngStyle]="bgStyleContent"></div>
</div>

<div
  class="cuLayouts layoutQuestionandAnswer {{ cuLayoutTemplate.template }} {{ tenantName }}"
  id="layoutQuestionandAnswer_{{ cuLayoutTemplate.template }}"
  *ngIf="
    cuLayoutTemplate.template !== 't-1' &&
    templateType !== 't-13' &&
    !isCarnival &&
    !isOnboarding &&
    cuLayoutTemplate.template !== 't-13'
  "
  [ngClass]="{
    cuButtonFixed: buttonAction?.positionFixed,
    cuLayouts_extraLarge: cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE',
    cuLayouts_medium: cuLayoutTemplate?.uiControlSize === 'MEDIUM',
    cuLayouts_small: cuLayoutTemplate?.uiControlSize === 'SMALL'
  }"
>
  <div
    class="cuLayouts_colorbg nh-z-n1 position-fixed nh-start-0 nh-top-0 nh-hp-100 nh-wp-100"
    id="background_{{
      getFileNameFromImgUrl(
        bgStyleContent.backgroundImage ? bgStyleContent.backgroundImage : cuLayoutTemplate?.background?.color
      )
    }}"
    [ngStyle]="{
      'background-image': bgStyleContent.backgroundImage ? bgStyleContent.backgroundImage : 'none',
      'background-color': cuLayoutTemplate?.background?.color ? cuLayoutTemplate.background.color : 'transparent',
      'opacity': bgStyleContent.backgroundImage ? bgStyleContent.opacity * 100 / 100 : 1}"
  ></div>
  <div
    class="cuLayouts_imagebg nh-z-n1 position-fixed nh-start-0 nh-top-0 nh-hp-100 nh-wp-100"
    id="background_{{ getFileNameFromImgUrl(cuLayoutTemplate?.background?.url) }}"
    *ngIf="templateType !== 't-13' && !isCarnival && !isOnboarding"
  >
    <img
      *ngIf="cuLayoutTemplate?.background?.url"
      [src]="cuLayoutTemplate?.background?.url"
      alt="image"
      class="nh-hp-100 nh-wp-100"
    />
  </div>
  <div class="nhCard_container">
    <div class="nhCard_innerContent">
      <div class="row nhCard_row">
        <div class="nhCard_row_col nhCard_row_left col-12">
          <div class="nhCard_row_left_inner">
            <app-physical-layer
              [isTransactionActive]="isTransactionActive"
              [layoutConfigurations]="layoutConfigurations"
              class="app-physical-layer"
              [tempInfoEntList]="tempInfoEntList"
              [estimatedTime]="selectedCu && selectedCu.estimatedTime"
              [entitiesList]="physicalEntities"
              [layoutSetting]="templateType"
              [isReadOnly]="isReadOnly"
              (emitFormData)="getFormData($event)"
              (formValueChanges)="dynamicformValueChange($event)"
              [gsi]="gsi"
              [currentCu]="selectedCu"
              (entityDataEmitForTable)="entityDataForTable($event)"
              [cuLayoutTemplate]="cuLayoutTemplate"
              (addMultipleEntities)="addMultipleEntities($event)"
              [selectedCard]="selectedCard"
            >
            </app-physical-layer>
          </div>
        </div>
      </div>
      <div
        class="nhCard_footer"
        [ngClass]="buttonAction?.positionFixed ? 'nh-position-fixed' : 'nh-position-static'"
        [attr.culayoutActionPosition]="buttonAction?.positionFixed ? 'fixed' : 'static'"
      >
        <div class="cuButton_inner" [ngClass]="{ 'justify-content-end': buttonAction?.buttonAlignment === 'right', 'justify-content-center': buttonAction?.buttonAlignment === 'center' }">
          <div class="cuButtons" *ngIf="!saveButtonStyle?.Save?.disableButton">
            <button
              (click)="saveAsDraft()"
              [disabled]="isReadOnly"
              class="cuButtons-btn cuButtons-btn-save"
              [ngClass]="{
                'cuButtons-btn-rounded': saveButtonStyle?.Save?.style == 'rounded',
                'cuButtons-btn-corner': saveButtonStyle?.Save?.style == 'rounded corner',
                'cuButtons-btn-solid': saveButtonStyle?.Save?.style == 'solid',
                'cuButtons-btn-save-icon': !saveButtonStyle?.Save?.svgUrl,
                'cuButtons-btn-save-noicon': saveButtonStyle?.Save?.format == 'icon'
              }"
            >
              <span *ngIf="saveButtonStyle?.Save?.format == 'button' && saveButtonStyle?.Save?.style">
                {{ labels?.[saveButtonStyle?.Save?.actionName] ? labels?.[saveButtonStyle?.Save?.actionName]: saveButtonStyle?.Save?.actionName }}
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="!saveButtonStyle?.Save?.svgUrl && saveButtonStyle?.Save?.format == 'icon'"
              >
                <img class="e-commerce-imgsize" src="../../../../../assets/img/save.svg" alt="Save" />
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  showdefaultSaveButtons ||
                  (saveButtonStyle?.Save?.format == 'button' && !saveButtonStyle?.Save?.style) ||
                  (saveButtonStyle?.Save?.format == 'icon' && !saveButtonStyle?.Save?.svgUrl)
                "
              >
                <img class="e-commerce-imgsize" src="../../../../../assets/img/save.svg" alt="Save" />
              </span>
            </button>
            <span
              *ngIf="
                saveButtonStyle?.Save?.format !== 'button' ||
                showdefaultSaveButtons ||
                (saveButtonStyle?.Save?.format == 'button' && !saveButtonStyle?.Save?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[saveToolTip] ? labels?.[saveToolTip]: saveToolTip }}
            </span>
          </div>
          <div class="cuButtons" *ngIf="!details?.showNext && !cancelButtonStyle?.Cancel?.disableButton && !isMobile">
            <button
              (click)="goBack()"
              [disabled]="isReadOnly"
              class="cuButtons-btn cuButtons-btn-cancel"
              [ngClass]="{
                'cuButtons-btn-rounded': cancelButtonStyle?.Cancel?.style == 'rounded',
                'cuButtons-btn-corner': cancelButtonStyle?.Cancel?.style == 'rounded corner',
                'cuButtons-btn-solid': cancelButtonStyle?.Cancel?.style == 'solid',
                'cuButtons-btn-cancel-icon': !cancelButtonStyle?.Cancel?.svgUrl,
                'cuButtons-btn-cancel-noicon': cancelButtonStyle?.Cancel?.format == 'icon'
              }"
            >
              <span *ngIf="cancelButtonStyle?.Cancel?.format == 'button' && cancelButtonStyle?.Cancel?.style">
                {{ labels?.[cancelButtonStyle?.Cancel?.actionName] ? labels?.[cancelButtonStyle?.Cancel?.actionName]: cancelButtonStyle?.Cancel?.actionName }}
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="!cancelButtonStyle?.Cancel?.svgUrl && cancelButtonStyle?.Cancel?.format == 'icon'"
              >
                <img class="e-commerce-imgsize" src="../../../../../assets/img/cancel.svg" alt=" " />
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  showdefaultCancelButtons ||
                  (cancelButtonStyle?.Cancel?.format == 'button' && !cancelButtonStyle?.Cancel?.style) ||
                  (cancelButtonStyle?.Cancel?.format == 'icon' && !cancelButtonStyle?.Cancel?.svgUrl)
                "
              >
                <img class="e-commerce-imgsize" src="../../../../../assets/img/cancel.svg" alt=" " />
              </span>
            </button>
            <span
              *ngIf="
                cancelButtonStyle?.Cancel?.format !== 'button' ||
                showdefaultCancelButtons ||
                (cancelButtonStyle?.Cancel?.format == 'button' && !cancelButtonStyle?.Cancel?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[cancelToolTip] ? labels?.[cancelToolTip]: cancelToolTip }}
            </span>
          </div>
          <div class="cuButtons" *ngIf="!hideSubmitButton">
            <button
              (click)="onSubmit($event)"
              id="submitTrans"
              class="cuButtons-btn cuButtons-btn-submit"
              [ngClass]="{
                'cuButtons-btn-rounded': submitButtonStyle?.Submit?.style == 'rounded',
                'cuButtons-btn-corner': submitButtonStyle?.Submit?.style == 'rounded corner',
                'cuButtons-btn-solid': submitButtonStyle?.Submit?.style == 'solid',
                'cuButtons-btn-submit-icon': !submitButtonStyle?.Submit?.svgUrl,
                'cuButtons-btn-submit-noicon': submitButtonStyle?.Submit?.format == 'icon'
              }"
            >
              <span *ngIf="details?.showNext">{{ labels?.Next }}</span>
              <span *ngIf="submitButtonStyle?.Submit?.format == 'button' && submitButtonStyle?.Submit?.style">
                {{ labels?.[submitButtonStyle?.Submit?.actionName] ? labels?.[submitButtonStyle?.Submit?.actionName]: submitButtonStyle?.Submit?.actionName }}
              </span>
              <span
                *ngIf="
                  !details?.showNext &&
                  !submitButtonStyle?.Submit?.svgUrl &&
                  submitButtonStyle?.Submit?.format == 'icon'
                "
                class="cuButtons-imgs"
              >
                <img class="e-commerce-imgsize" src="../../../../../assets/img/right-arrow-submit.svg" alt=" "
              /></span>
              <span
                *ngIf="
                  showdefaultSubmitButtons ||
                  (submitButtonStyle?.Submit?.format == 'button' && !submitButtonStyle?.Submit?.style) ||
                  (submitButtonStyle?.Submit?.format == 'icon' && !submitButtonStyle?.Submit?.svgUrl)
                "
                class="cuButtons-imgs"
              >
                <img class="e-commerce-imgsize" src="../../../../../assets/img/right-arrow-submit.svg" alt=" "
              /></span>
            </button>
            <span
              *ngIf="
                submitButtonStyle?.Submit?.format !== 'button' ||
                showdefaultSubmitButtons ||
                (submitButtonStyle?.Submit?.format == 'button' && !submitButtonStyle?.Submit?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[submitToolTip] ? labels?.[submitToolTip]: submitToolTip }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #myDialog>
  <div mat-dialog-content>
    <div class="row dragNdrop">
      <h6>OCR Extraction</h6>
      <div class="col text-center">
        <label for="dragNdrop">
          <span class="raised">Browse</span>
        </label>
      </div>
      <div class="col gray-txt">
        <input type="file" (change)="fileChange($event)" id="dragNdrop" class="dragNdropInput" />
        <label for="dragNdrop">Or Drop file here</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #thankumodal>
  <div mat-dialog-content class="thankumodal">
    <div class="welcome-pop text-center">
      <figure>
        <img
          [src]="'../../../assets/images/carnival/carnival/roadmap/thanku-redirect.svg' | CDNUrlRewritePipe"
          alt="Thank you for being a part of NH Carnival!"
        />
      </figure>
      <h2>Thank you for being a part of NH Carnival!</h2>
      <h5>Redirecting to nslhub in 00:{{ timerToRedirect }} sec.</h5>
      <p>We have sent you an e-mail with your nslhub login details.</p>
      <p>You can always login later using that.</p>
    </div>
  </div>
</ng-template>

<ng-template #ArDialog>
  <div mat-dialog-content class="thankumodal">
    <button mat-button mat-dialog-close class="close-btn-ar-box"><mat-icon>close</mat-icon></button>
    <div class="welcome-pop text-center">
      <h3>Change Unit is Containing Augmented Reality Entities</h3>
      <button *ngIf="physicalBtn" mat-button class="ar-btns" (click)="openArCamera('PE')">Physical Entity View</button>
      <button *ngIf="arBtnEntity" mat-button class="ar-btns" (click)="openArCamera('AE')">Augmented Entity View</button>
      <button *ngIf="hybridBtn" mat-button class="ar-btns" (click)="openArCamera('HE')">Hybrid Entity View</button>
      <p *ngIf="!physicalBtn && !arBtnEntity && !hybridBtn">No Data Found</p>
    </div>
  </div>
</ng-template>
