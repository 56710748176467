import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FieldConfig, TransactionFacadeService, isNumber, EventsFacadeService, validateDependentExpression } from '@common-services';
import { Subject } from 'rxjs';
const REGEX = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?/;
@Component({
  selector: 'app-integer',
  templateUrl: './integer.component.html',
  styleUrls: ['./integer.component.scss'],
})
export class IntegerComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  year: any;
  month: any;
  day: any;
  week: any;
  yearData: any = [];
  monthData: any = [];
  weekData: any = [];
  dayData: any = [];
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  validationList: any[] = [
    'greaterThan',
    'greaterThanOrEqual',
    'smallerThan',
    'smallerThanOrEqual',
    'equalTo',
    'notequalTo',
    'valuesSelect',
    'afterValidation',
    'onorafterValidation',
    'beforeValidation',
    'onorbeforeValidation',
    'min',
    'max'
  ];
  constructor(private transacationFacadeService: TransactionFacadeService , private eventsService: EventsFacadeService) {}
  ngOnInit(): void {
    if (this.field?.value != '') {
      this.transacationFacadeService.disableSubmitButtonInteger(false);
    }
    // if (this.field?.isRequired) {
    //   this.transacationFacadeService.disableSubmitButtonInteger(true);
    // }
    let i = 0;
    /*istanbul ignore next*/
    while (i <= 50) {
      if (i >= 0) {
        this.yearData.push(i);
        /*istanbul ignore next*/
        this.monthData.push(i);
        /*istanbul ignore next*/
        this.weekData.push(i);
        this.dayData.push(i);
      }
      i++;
    }
    /*istanbul ignore next*/
    this.periodTime(this.field?.value);
    // console.log(this.field);
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }
  }

  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid?.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    }
  }

  checkLength(data: any): any {
    /*istanbul ignore next*/
    if (data.length == 1) {
      /*istanbul ignore next*/
      return true;
    }
    /*istanbul ignore next*/
    return false;
  }
  periodTime(string: string) {
    if (string.includes('Y')) {
      let index = string.indexOf('Y');
      let str = '';
      for (let i = index - 1; i >= 0; i--) {
        if (isNumber(string[i]) || string[i] == '-') {
          str += string[i];
        } else {
          break;
        }
      }
      str = [...str].reverse().join('');
      this.year = parseInt(str);
    }
    if (string.includes('M')) {
      let index = string.indexOf('M');
      let str = '';
      for (let i = index - 1; i >= 0; i--) {
        if (isNumber(string[i]) || string[i] == '-') {
          str += string[i];
        } else {
          break;
        }
      }
      str = [...str].reverse().join('');
      this.month = parseInt(str);
    }
    if (string.includes('W')) {
      let index = string.indexOf('W');
      let str = '';
      for (let i = index - 1; i >= 0; i--) {
        if (isNumber(string[i]) || string[i] == '-') {
          str += string[i];
        } else {
          break;
        }
      }
      str = [...str].reverse().join('');
      this.week = parseInt(str);
    }
    if (string.includes('D')) {
      let index = string.indexOf('D');
      let str = '';
      for (let i = index - 1; i >= 0; i--) {
        if (isNumber(string[i]) || string[i] == '-') {
          str += string[i];
        } else {
          break;
        }
      }
      str = [...str].reverse().join('');
      this.day = parseInt(str);
    }
  }
  getCharInput(event: any, inputType: any): any {
    /*istanbul ignore next*/
    if (this.field?.type == 'char') {
      /*istanbul ignore next*/
      if (event.keycodes === '\\' && this.field?.value?.length < 2) {
        return true;
      }
      if (this.field?.value?.length === 1 && this.field?.value !== '\\') {
        event.preventDefault();
      }
      if (this.field?.value?.length >= 2) {
        event.preventDefault();
      }
      /*istanbul ignore next*/
      return event.charCode == 8 || event.charCode == 0 ? null : event.charCode >= 33 && event.charCode <= 126;
    }
  }
  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedData = clipboardData.getData('text');
    if (pastedData.length == 2 && pastedData.includes('\\n')) {
      return true;
    } else if (pastedData.length > 1) {
      event.preventDefault();
    }
  }
  period(data: any, type: any) {
    console.log(data);
    switch (type) {
      case 'year':
        this.year = data;
        break;
      case 'month':
        this.month = data;
        break;
      case 'week':
        this.week = data;
        break;
      case 'day':
        this.day = data;
        break;
    }
    /*istanbul ignore next*/
    let periodData: string = 'P';
    /*istanbul ignore next*/
    if (this.year < 0) {
      periodData = '-P';
    }
    /*istanbul ignore next*/
    if (this.year) {
      periodData += this.year + 'Y';
    }
    /*istanbul ignore next*/
    if (this.month) {
      periodData += this.month + 'M';
    }
    /*istanbul ignore next*/
    if (this.week) {
      periodData += this.week + 'W';
    }
    /*istanbul ignore next*/
    if (this.day) {
      periodData += this.day + 'D';
    }
    this.field.value = periodData;
    /*istanbul ignore next*/
    this.group.controls[this.field?.attribute?.name].setValue(periodData);
    /*istanbul ignore next*/
    this.transacationFacadeService.disableSubmitButtonInteger(false);
  }

  getNumberType(event: any): any {
    if (this.field?.type == 'short') {
      let val: number = Number(event.target.value);
      /*istanbul ignore next*/
      if (val < -32768) {
        /*istanbul ignore next*/
        return (event.target.value = Math.ceil(event.target.value / 10));
      }
      /*istanbul ignore next*/
      if (val >= 32767) {
        /*istanbul ignore next*/
        return (event.target.value = Math.floor(event.target.value / 10));
      }
    }
    if (this.field?.type == 'integer') {
      /*istanbul ignore next*/
      if (event.target.value < -2147483648) {
        /*istanbul ignore next*/
        return (event.target.value = Math.ceil(event.target.value / 10));
      }
      /*istanbul ignore next*/
      if (event.target.value > 2147483647) {
        /*istanbul ignore next*/
        return (event.target.value = Math.floor(event.target.value / 10));
      }
    }
    if (this.field?.type == 'byte') {
      let val: any;
      /*istanbul ignore next*/
      if (event.target.value < -128) {
        /*istanbul ignore next*/
        val = Math.ceil(event.target.value / 10);
        this.field.value = [val];
        this.group.controls[this.field?.attribute?.name].setValue(val);
        return (event.target.value = val);
      }
      /*istanbul ignore next*/
      if (event.target.value > 127) {
        /*istanbul ignore next*/
        val = Math.floor(event.target.value / 10);
        this.field.value = [val];
        this.group.controls[this.field?.attribute?.name].setValue(val);
        return (event.target.value = val);
      }
    }
    if (this.field?.type == 'float') {
      /*istanbul ignore next*/
      if (event.target.value.length > 0 && event.target.value < -3.4000000000000007e-38) {
        /*istanbul ignore next*/
        event.target.value = '';
        this.field.value = event.target.value;
        return event.target.value;
      }
      /*istanbul ignore next*/
      if (event.target.value && event.target.value > 3.4e38) {
        /*istanbul ignore next*/
        event.target.value = event.target.value.slice(0, -1);
        this.field.value = event.target.value;
        this.group.controls[this.field?.attribute?.name].setValue(event.target.value);
        return event.target.value;
      }
      if (event.data != '-' && event.data != '.') {
        this.field.value = event.target.value;
        this.group.controls[this.field?.attribute?.name].setValue(event.target.value);
      }
    }
    /*istanbul ignore next*/
    this.transacationFacadeService.disableSubmitButtonInteger(false);
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transacationFacadeService
      );
    }
    return this.group?.get(String(this.field?.attribute?.name))?.status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (
      this.group?.controls[field?.attribute.name]?.value != null ||
      this.group?.controls[field?.attribute.name]?.value != undefined
    ) {
      if (validation?.name == 'min') {
        return parseInt(this.group?.controls[field?.attribute?.name].value) < parseInt(validation?.value);
      } else if (validation?.name == 'max') {
        return parseInt(this.group.controls[field?.attribute?.name].value) > parseInt(validation?.value);
      } else {
        if (this.validationList?.includes(validation?.name)) {
          return validateDependentExpression(
            validation,
            this.eventsService,
            this.field,
            this.group,
            this.transacationFacadeService
          );
        }
        return this.group.controls[field?.attribute?.name]?.value?.toString()?.match(validation?.requiredPattern)
          ? false
          : true;
      }
    }
  }
}
