<div class="preview-right-pane">
  <div class="right-pane" *ngIf="panelType === 'files'">
    <div class="prev-rit-panehead">
      <h4>Recent Files</h4>
      <h4>Last 7 days</h4>
    </div>
    <div class="prev-rit-panelist">
      <ul>
        <li>
          <div class="fileneame">
            <mat-icon>description</mat-icon>
            <span>invoice.jpg</span>
          </div>
          <div class="datetime">12, 3:28 pm</div>
        </li>
        <li>
          <div class="fileneame">
            <mat-icon>description</mat-icon>
            <span>invoice.jpg</span>
          </div>
          <div class="datetime">12, 3:28 pm</div>
        </li>
      </ul>
    </div>
  </div>
  <div class="right-pane" *ngIf="panelType === 'attributes'">
    <div class="prev-rit-panehead">
      <mat-icon (click)="goBack('files')">keyboard_arrow_left</mat-icon>
      <h4>
        Select from the <span class="ocr-filename">{{ ocrData?.file_name }}</span>
      </h4>
    </div>
    <div class="prev-rit-panelist">
      <ul>
        <li *ngFor="let attr of clickedAttribute">
          <div class="fileneame">
            <span>{{ attr.attribute_name }}</span>
          </div>
          <div class="ocr-btns">
            <a class="add-ocr-btn" (click)="addAttribute(attr)"> <mat-icon>add</mat-icon><span> Add</span> </a>
            <a class="map-ocr-btn" (click)="mapAttribute(attr)">
              <mat-icon class="attach-icon"> attach_file</mat-icon><span> Map</span>
            </a>
          </div>
        </li>
      </ul>
      <div>
        <mat-label>Added Attributes</mat-label>
        <ul>
          <li *ngFor="let attr of addedAttributes">
            <div class="fileneame">
              <span>{{ attr.attribute_name }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div>
        <mat-label>Mapped Attributes</mat-label>
        <ul>
          <li *ngFor="let attrSet of mappedAttributes">
            <div class="fileneame">
              <span>{{ attrSet.fromAttr }}</span> <mat-icon>keyboard_arrow_right</mat-icon>
              <span>{{ attrSet.toAttr }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="right-pane" *ngIf="panelType === 'mapAttributes'">
    <div class="prev-rit-panehead">
      <mat-icon (click)="goBack('attributes')">keyboard_arrow_left</mat-icon>
      <h4>
        {{ chosenAttributeToMap?.attribute_name }}
      </h4>
    </div>
    <div class="prev-rit-panelist">
      <ul>
        <li *ngFor="let attr of entity?.nslAttributes; let attrIndex = index">
          <div class="fileneame">
            <span>{{ attr.name }}</span>
          </div>
          <div class="ocr-btns">
            <a class="map-ocr-btn" (click)="mapToAttribute(attr)">
              <mat-icon class="attach-icon"> attach_file</mat-icon><span> Map</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <button class="btn btn-done" (click)="doneAndClose()">Done</button>
</div>
