<div pTooltip="{{ field.label }}" tooltipPosition="top" tooltipStyleClass="transcustomtolltipbook">
  <div [formGroup]="group">
    <label 
        class="main-label"
        pTooltip="{{ field.label }}"       
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
      ><span class="main-label-inner">
        <span class="main-label-text text-truncate"
          >{{ field.label }} <span class="main-label-colon">:</span></span
        >
        <span
          *ngIf="field?.isRequired && !field?.isInfo"
          class="mat-form-field-required-marker"
          >*</span
        ></span
      ></label
    >

    <div>
      <mat-form-field appearance="standard" class="timeonly-field">
        <p>{{ time }}</p>
        <input
          matInput
          [ngxMatDatetimePicker]="startDateTime"
          name="startDateTime"
          placeholder="Choose time"
          [required]="field?.isRequired && !field?.isInfo"
          [(ngModel)]="field.value"
          formControlName="{{ field.attribute.name }}"
          (dateChange)="clicked($event)"
          style="display: none;"
        />
        <mat-datepicker-toggle matSuffix [for]="startDateTime"> </mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #startDateTime
          [showSpinners]="true"
          [showSeconds]="true"
          [stepHour]="1"
          [stepMinute]="1"
          [stepSecond]="1"
          panelClass="hide-calendar  d-none"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
  </div>
</div>
