<div class="con-main-wrapper connectors-wrapper">
  <!--Header area-->
  <div class="con-header">
    <div class="con-header-left">
      <h2>
        Connector
        <span (click)="AddConnector()">
          <figure><i class="bi bi-plus-square"></i></figure>
          <figcaption>Create Connector</figcaption>
        </span>
      </h2>
    </div>
    <div class="con-header-right">
      <!-- <button class="btn save" (click)="resetConnectors()">Reset Connectors</button> -->
      <button class="btn save" (click)="saveConnectors()">Save</button>
      <button class="btn-close" (click)="closeConnectors()"><i class="bi bi-x-square"></i></button>
    </div>
  </div>
  <!--Content area-->
  <div class="con-content">
    <div class="con-left-content">
      <!-- Source Area -->
      <div class="commonbar source-bar" *ngIf="addConnector">
        <h4>SOURCE:</h4>
        <h5>{{ changeUnit.name }}</h5>
        <div>
          <div>
            <mat-checkbox class="event-checkbox-cls" id="eventCheckd" (change)="eventClicked()" [checked]="isEvent">
              Events</mat-checkbox
            >
            <mat-button-toggle-group (change)="onChildEvent($event.value)" class="connectors-toggle-group">
              <mat-button-toggle class="event-checkbox-cls" labelPosition="before" value="false"
                >Parent to child GSI</mat-button-toggle
              >
              <mat-button-toggle
                class="event-checkbox-cls"
                id="childToParentCheckd"
                [(ngModel)]="isChildEvent"
                ngDefaultControl
                labelPosition="before"
                value="true"
                >Child To parent GSI</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
          <div class="rad-check">
            <mat-radio-group name="arrival_departure" aria-label="Select Connector Type" [(ngModel)]="event">
              <mat-radio-button value="ARRIVAL">Arrival</mat-radio-button>
              <mat-radio-button value="DEPARTURE">Departure</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group
              *ngIf="istarget"
              name="APPEND_REPLACE"
              aria-label="Multi Value Mode"
              [(ngModel)]="multiValueMode"
              class="layers-types"
            >
              <mat-radio-button value="APPEND">Append</mat-radio-button>
              <mat-radio-button class="multi-mode-replace" value="REPLACE">Replace</mat-radio-button>
            </mat-radio-group>

            <mat-radio-group
              (change)="sourceTypeChange()"
              name="sourceType_select"
              aria-label="Select Connector Type"
              [(ngModel)]="sourceType"
              class="layers-types"
            >
              <mat-radio-button value="CU">CU’s</mat-radio-button>
              <mat-radio-button value="Collection">My Collection</mat-radio-button>
              <mat-radio-button value="GSI_Layers" *ngIf="changeUnit?.cuType === 'GSI'">GSI Layers</mat-radio-button>
              <mat-radio-button value="RCU">RCU Info</mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="sourceType == 'Collection' && sourceDcdValue.solutionLogic.length == 0">
            <div class="search-field">
              <input name="gsiSearch" placeholder="search" type="text" (keyup)="searchGsi()" [(ngModel)]="searchText" />
            </div>
            <div class="gsiList">
              <ul class="gsilist-pop">
                <li *ngFor="let gsi of myCollection" (click)="getGsiDetails(gsi)">
                  <span class="gsiName">
                    {{ gsi.name }}
                  </span>
                  <div class="ml-auto">
                    <span *ngIf="gsi.status === 'DRAFT'">D</span>
                    <span *ngIf="gsi.status === 'ARCHIVED'">A</span>
                    <span *ngIf="gsi.status === 'PUBLISHED'">P</span>
                    <span *ngIf="gsi.status === 'READY'">R</span>
                    <span *ngIf="gsi.status === 'APPROVED'">Ap</span>
                  </div>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="sourceType == 'Collection' && sourceDcdValue?.solutionLogic?.length > 0">
            <div class="form-group">
              <label class="field-label">{{ sourceDcdValue.selectedGsi.name }}</label>
            </div>
            <div class="form-group">
              <label class="field-label">CHANGE_UNIT</label>
              <select
                class="custom-select"
                name="changeUnitName_myCollections"
                aria-label=""
                (change)="sourceChangeUnitSelect(sourceDcdValue.changeUnitIndex, true)"
                [(ngModel)]="sourceDcdValue.changeUnitIndex"
              >
                <option value="-1" selected>Select Change Unit</option>
                <option
                  *ngFor="let sourceCu of sourceDcdValue?.solutionLogic; let sourceIndex = index"
                  [value]="sourceIndex"
                >
                  {{ sourceCu.name }}</option
                >
              </select>
            </div>
            <div class="form-group" *ngIf="sourceDcdValue?.selectedChangeUnit?.layers?.length > 0">
              <label>Layer</label>
              <select
                class="custom-select"
                aria-label=""
                name="layer_select_myCollections"
                (change)="changeLayer(sourceDcdValue.layerType, true)"
                [(ngModel)]="sourceDcdValue.layerType"
                aria-label=""
              >
                <option value="">Select</option>
                <option
                  *ngFor="let layer of sourceDcdValue?.selectedChangeUnit?.layers; let layerIndex = index"
                  [value]="layer.type"
                >
                  {{ layer.type }}
                </option>
              </select>
            </div>
            <div class="form-group" *ngIf="sourceDcdValue?.layerType && sourceDcdValue.participatingItems?.length > 0">
              <mat-label class="field-label">Object</mat-label>
              <select
                class="custom-select"
                aria-label=""
                name="select_object_myCollections"
                (change)="changeEntity(sourceDcdValue.entityIndex)"
                [(ngModel)]="sourceDcdValue.entityIndex"
              >
                <option value="-1" selected>Select Option</option>

                <option
                  *ngFor="let entity of sourceDcdValue?.participatingItems; let entityIndex = index"
                  [value]="entityIndex"
                >
                  <span *ngIf="entity?.item?.DATA?.name">{{ entity.item.DATA.name }}</span>
                  <span *ngIf="entity?.name">{{ entity.name }}</span>
                </option>
              </select>
            </div>

            <!-- Attribute Selection Starts -->
            <ng-container
              *ngIf="sourceDcdValue?.nslAttributes?.length > 0"
              [ngTemplateOutlet]="attributeTargetDDBlock"
              [ngTemplateOutletContext]="{
                entity: sourceDcdValue
              }"
            >
            </ng-container>
            <!-- Attribute Selection Ends -->
          </div>
          <div class="form-group" *ngIf="sourceType != 'Collection'">
            <!-- CU Selection begins for embedded GSI -->
            <label class="field-label" *ngIf="changeUnit.cuType === 'GSI' && !chooseGSILayers">CU</label>
            <select
              class="custom-select"
              aria-label="cu-selection"
              name="cu_select"
              (change)="sourceChangeUnitSelect(sourceDcdValue.changeUnitIndex)"
              [(ngModel)]="sourceDcdValue.changeUnitIndex"
              *ngIf="changeUnit.cuType === 'GSI' && !chooseGSILayers"
            >
              <option value="-1" selected>Select Change Unit</option>
              <option *ngFor="let cu of changeUnit.solutionLogic; let cuIndex = index" [value]="cuIndex">
                {{ cu.name }}
              </option>
            </select>
            <!-- RCU Selection begins -->
            <label class="field-label" *ngIf="RCUsFlag">RCU Name</label>
            <select
              class="custom-select"
              aria-label=""
              name="layer_select"
              (change)="rcuChange(reservedCUName)"
              [(ngModel)]="reservedCUName"
              *ngIf="RCUsFlag"
            >
              <option value="" selected>Select RCU</option>
              <option *ngFor="let rcu of DataTypeRcuList; let rcuIndex = index" [value]="rcu">
                {{ rcu }}
              </option>
            </select>
            <!-- RCU Selection begins -->
            <label class="field-label" *ngIf="RCUsFlag && reservedCUName">Function Name</label>
            <select
              class="custom-select"
              aria-label=""
              name="layer_select"
              (change)="rcuLayerChange(reservedCUName, functionName)"
              [(ngModel)]="functionName"
              *ngIf="RCUsFlag && reservedCUName"
            >
              <option value="" selected>Select Function</option>
              <option
                *ngFor="let rcuLayer of functionsNamesOfSelectedRcu; let rcuLayerIndex = index"
                [value]="rcuLayer"
              >
                {{ rcuLayer }}
              </option>
            </select>
            <div class="function-Info" *ngIf="RCUsFlag && reservedCUName && functionName && SelectedRcuFInfo">
              <textarea class="function-Info-name" [(ngModel)]="SelectedRcuFInfo" readonly> </textarea>
            </div>
            <!-- Layer Selection begins -->
            <label class="field-label" *ngIf="!RCUsFlag || (RCUsFlag && functionName)">Layer</label>
            <select
              class="custom-select"
              aria-label=""
              name="layer_select"
              (change)="changeLayer(sourceDcdValue.layerType)"
              [(ngModel)]="sourceDcdValue.layerType"
              *ngIf="((changeUnit.cuType !== 'GSI' || chooseGSILayers) && !RCUsFlag) || (RCUsFlag && functionName)"
            >
              <option value="" selected>Select Layer</option>
              <option *ngFor="let layer of changeUnit.layers; let layerIndex = index" [value]="layer.type">
                {{ layer.type }}
              </option>
            </select>
            <!-- Layer Selection ends -->

            <!-- Embedded gsi selected cu Layer Selection begins -->
            <select
              class="custom-select"
              *ngIf="changeUnit.cuType === 'GSI' && !chooseGSILayers"
              aria-label=""
              name="layer_select"
              (change)="sourceLayerChange(sourceDcdValue.layerType)"
              [(ngModel)]="sourceDcdValue.layerType"
            >
              <option value="" selected>Select Layer</option>
              <option
                *ngFor="
                  let layer of (changeUnit?.solutionLogic)[sourceDcdValue.changeUnitIndex]?.layers;
                  let layerIndex = index
                "
                [value]="layer.type"
              >
                {{ layer.type }}
              </option>
            </select>
          </div>
          <!-- Embedded gsi selected cu Layer Selection ends -->

          <!-- Entity Selection Begins -->
          <div
            class="form-group"
            *ngIf="
              sourceDcdValue?.layerType && sourceDcdValue.participatingItems?.length > 0 && sourceType != 'Collection'
            "
          >
            <mat-label class="field-label">Object</mat-label>
            <select
              class="custom-select"
              aria-label=""
              name="select_object"
              (change)="changeEntity(sourceDcdValue.entityIndex)"
              [(ngModel)]="sourceDcdValue.entityIndex"
            >
              <option value="-1" selected>Select Option</option>

              <option
                *ngFor="let entity of sourceDcdValue.participatingItems; let entityIndex = index"
                [value]="entityIndex"
                >{{ entity.name }}</option
              >
            </select>
          </div>
          <!-- Entity Selection ends -->

          <!-- Attribute Selection Starts -->
          <!-- <div class="form-group" *ngIf="">
            <label class="field-label">Attributes</label>
            <select
              class="custom-select"
              aria-label=""
              name="select_attribute"
              [(ngModel)]="sourceDcdValue.attrIndex"
              (change)="changeAttribute(sourceDcdValue.attrIndex)"
            >
              <option value="-1" selected>Select Attribute</option>
              <option *ngFor="let attr of sourceDcdValue.nslAttributes; let attrIndex = index" [value]="attrIndex">
                {{ attr.name }}
              </option>
            </select>
          </div> -->
          <ng-container
            *ngIf="sourceDcdValue?.nslAttributes?.length > 0 && sourceType != 'Collection'"
            [ngTemplateOutlet]="attributeSourceDDBlock"
            [ngTemplateOutletContext]="{
              entity: sourceDcdValue
            }"
          >
          </ng-container>

          <!-- for measures entity -->
          <ng-container
            *ngIf="showMeasures"
            [ngTemplateOutlet]="attributeSourceMeasuresBlock"
            [ngTemplateOutletContext]="{
              entity: measureEntity
            }"
          >
          </ng-container>
          <!-- Attribute Selection Ends -->

          <button class="btn add-fld ml-auto" (click)="addSource()">Add Source</button>
          <div class="add-source-pan">
            <div class="form-group">
              <label class="field-label"> Source</label>
              <p *ngFor="let display of sourceArray" class="selected-filed d-flex justify-content-between">
                <span>{{ display.formulaId | dcd }}</span>
                <span class="material-icons list-alert" (click)="addToFormula(display, false)">add</span>
              </p>
            </div>
            <div class="sob-middle" *ngIf="showOPerator" id="divshow">
              <ul class="d-flex flex-wrap">
                <li class="operator" *ngFor="let op of operators" (click)="addToFormula(op.operator, true)">
                  <span> {{ op.operator }}</span> {{ op.name }}
                </li>
              </ul>
              <div
                class="actions-item"
                *ngFor="let item of dcd?.formulaArray; let i = index"
                [matMenuTriggerFor]="afterMenu"
                #createPlanmenuTrigger="matMenuTrigger"
              >
                <label *ngIf="!item.name" class="formula" style="z-index: 1052;">
                  {{ item | dcd }}
                </label>
                <label *ngIf="item.name" class="formula-label" style="z-index: 1053;">
                  {{ item.name | dcd }}
                </label>
                <mat-menu #afterMenu="matMenu" xPosition="after">
                  <span class="menu-hover" (mouseenter)="menuenter()" (mouseleave)="menuLeave(createPlanmenuTrigger)">
                    <button mat-menu-item id="deleteExpressionDcd">
                      <figure>
                        <img
                          alt="createFormula"
                          (click)="clearFormula(dcd, i)"
                          [src]="'../../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                        />
                      </figure>
                      <span>Delete</span>
                    </button>
                  </span>
                </mat-menu>
              </div>
              <div class="enter-value">
                <input
                  name="dcdformula"
                  id="dcdInput"
                  type="text"
                  [(ngModel)]="formulaFreeText"
                  (click)="onFormulaEnter()"
                  placeholder="Enter your value"
                />
              </div>
            </div>
            <div class="select-oprt">
              <span>Select Operator</span>
              <figure (click)="showOperators()">
                <img alt="keyboard" [src]="'../../../../../../assets/img/nsl-gram/keyboard.svg' | CDNUrlRewritePipe" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <!-- Target Area -->
      <div class="commonbar target-bar" *ngIf="showTarget">
        <h4>TARGET:</h4>
        <div>
          <div class="rad-check">
            <mat-radio-group
              (change)="targetTypeChange()"
              name="targetType_select"
              aria-label="Select Connector Type"
              [(ngModel)]="targetType"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-radio-button value="GSI">GSI’s</mat-radio-button>
              <mat-radio-button value="CU">CU’s</mat-radio-button>
              <mat-radio-button value="Collection">My Collection</mat-radio-button>
              <mat-radio-button value="GSI_Layers">GSI Layers</mat-radio-button>
              <mat-radio-button *ngIf="changeUnit?.mindCUList?.length > 0" value="Nested">Nested</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="Gsi Layers" *ngIf="targetType == 'GSI_Layers'">
            <div class="form-group">
              <label class="field-label">GSI</label>
              <select
                class="custom-select"
                name="gsiName"
                aria-label=""
                (change)="ChangeTargetGsi(targetDcdValue.gsiIndex)"
                [(ngModel)]="targetDcdValue.gsiIndex"
              >
                <option value="-1" selected>Select Gsi</option>
                <option *ngFor="let targetGsi of targetListGsi; let targetgsiIndex = index" [value]="targetgsiIndex">
                  {{ targetGsi?.name }}</option
                >
              </select>
            </div>
            <div class="form-group" *ngIf="openGsiLayer && !selectedTargetGsi?.isEmbedded">
              <label>Layer</label>
              <select
                class="custom-select"
                aria-label=""
                name="layer_select"
                (change)="changeTargetLayer(targetDcdValue.layerType)"
                [(ngModel)]="targetDcdValue.layerType"
                aria-label=""
              >
                <option value="">Select</option>
                <option *ngFor="let layer of selectedTargetGsi.layers; let layerIndex = index" [value]="layer.type">
                  {{ layer?.type }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="targetType != 'Collection'">
            <div class="form-group" *ngIf="targetType == 'GSI'">
              <label class="field-label">GSI</label>
              <select
                class="custom-select"
                name="gsiName"
                aria-label=""
                (change)="targetChangeUnitChange(targetDcdValue.gsiIndex, true)"
                [(ngModel)]="targetDcdValue.gsiIndex"
                (click)="getTargetEmbGsiData()"
              >
                <option value="-1" selected>Select Gsi</option>
                <option *ngFor="let targetGsi of targetGSIList; let targetgsiIndex = index" [value]="targetgsiIndex">
                  {{ targetGsi?.name }}</option
                >
              </select>
            </div>

            <div class="form-group" *ngIf="targetType == 'GSI'">
              <label class="field-label">Change Unit</label>
              <select
                class="custom-select"
                name="changeUnitName"
                aria-label=""
                (change)="targetChangeUnitChange(targetDcdValue.changeUnitIndex)"
                [(ngModel)]="targetDcdValue.changeUnitIndex"
              >
                <option value="-1" selected>Select Change Unit</option>
                <option
                  *ngFor="let targetCu of targetDcdValue.solutionLogic; let targetIndex = index"
                  [value]="targetIndex"
                >
                  {{ targetCu?.name }}</option
                >
              </select>
            </div>
            <div class="form-group slot-group-display" *ngIf="targetType == 'CU'">
              <label class="field-label">Change Unit</label>
              <select
                class="custom-select"
                name="changeUnitName"
                aria-label=""
                (change)="targetChangeUnitChange(targetDcdValue.changeUnitIndex)"
                [(ngModel)]="targetDcdValue.changeUnitIndex"
              >
                <option value="-1" selected>Select Change Unit</option>
                <option
                  *ngFor="let targetCu of targetCuList; let targetIndex = index"
                  [value]="targetIndex"
                  id="changeUnit_{{ targetCu?.name }}_slot_{{ targetIndex }}"
                >
                  {{ targetCu?.name }}</option
                >
              </select>
              <span class="slot-display" *ngIf="targetDcdValue?.changeUnitIndex < 0">Slot</span>
              <span class="slot-display" *ngIf="targetDcdValue?.changeUnitIndex >= 0">{{
                targetDcdValue?.changeUnitIndex
              }}</span>
            </div>

            <!-- <mat-radio-group
              *ngIf="targetDcdValue.selectedChangeUnit?.mindCUList?.length > 0"
              (change)="targetMindCuList(targetDcdValue.selectedChangeUnit.mindCUList[0]?.gsiId, $event)"
              name="basicOrNetsed_select"
              aria-label="Select Connector Type"
              [(ngModel)]="basicOrNested"
            >
              <mat-radio-button value="Basic">BasicCU’s</mat-radio-button>
              <mat-radio-button value="Nested">NestedCU’s</mat-radio-button>
            </mat-radio-group> -->
            <!-- <select nested> -->
            <!-- <div class="form-group" *ngIf="basicOrNested == 'Nested'">
              <label>NestedCU</label>
              <select
                class="custom-select"
                aria-label=""
                name="nestedSelect"
                (change)="selectionChange()"
                [(ngModel)]="nestedCU"
                aria-label=""
              >
                <option value="">Select</option>
                <option *ngFor="let cu of fetchedNested; let layerIndex = index" [value]="layerIndex">
                  {{ cu.name }}
                </option>
              </select>
            </div> -->
            <!-- <div *ngIf="nestedCU">
              <ng-container *ngTemplateOutlet="nestedDetails; context: { nestedcu: nestedCU  }"></ng-container>
            </div> -->

            <!--ends-->
            <div
              class="form-group"
              *ngIf="targetDcdValue?.selectedChangeUnit?.layers?.length > 0 && targetType !== 'Nested'"
            >
              <label>Layer</label>
              <select
                class="custom-select"
                aria-label=""
                name="layer_select"
                (change)="changeTargetLayer(targetDcdValue.layerType)"
                [(ngModel)]="targetDcdValue.layerType"
                aria-label=""
              >
                <option value="">Select</option>
                <option
                  *ngFor="let layer of targetDcdValue.selectedChangeUnit.layers; let layerIndex = index"
                  [value]="layer.type"
                >
                  {{ layer.type }}
                </option>
              </select>
            </div>
            <div class="form-group" *ngIf="targetDcdValue?.layerType && targetDcdValue.participatingItems?.length > 0">
              <mat-label class="field-label">Object</mat-label>
              <select
                class="custom-select"
                aria-label=""
                name="select_object"
                (change)="changeTargetEntity(targetDcdValue.entityIndex)"
                [(ngModel)]="targetDcdValue.entityIndex"
              >
                <option value="-1" selected>Select Option</option>

                <option
                  *ngFor="let entity of targetDcdValue.participatingItems; let entityIndex = index"
                  [value]="entityIndex"
                  >{{ entity.name }}</option
                >
              </select>
            </div>
            <!-- Attribute Selection Starts -->
            <ng-container
              *ngIf="targetDcdValue.nslAttributes.length > 0"
              [ngTemplateOutlet]="attributeTargetDDBlock"
              [ngTemplateOutletContext]="{
                entity: targetDcdValue
              }"
            >
            </ng-container>
            <!-- <div class="form-group" *ngIf="basicOrNested == 'Nested' && fetchedNested[0].layers.length > 0">
              <label>Layer</label>
              <select
                class="custom-select"
                aria-label=""
                name="layer_select"
                (change)="changeTargetLayer(targetDcdValue.layerType)"
                [(ngModel)]="targetDcdValue.layerType"
                aria-label=""
              >
                <option value="">Select</option>
                <option *ngFor="let layer of fetchedNested[0].layers; let layerIndex = index" [value]="layer.type">
                  {{ layer.type }}
                </option>
              </select>
            </div> -->
            <!-- <div
              class="form-group"
              *ngIf="
                basicOrNested == 'Nested' && targetDcdValue?.layerType && targetDcdValue.participatingItems?.length > 0
              "
            >
              <mat-label class="field-label">Object</mat-label>
              <select
                class="custom-select"
                aria-label=""
                name="select_object"
                (change)="changeTargetEntity(targetDcdValue.entityIndex)"
                [(ngModel)]="targetDcdValue.entityIndex"
              >
                <option value="-1" selected>Select Option</option>

                <option
                  *ngFor="let entity of targetDcdValue.participatingItems; let entityIndex = index"
                  [value]="entityIndex"
                  >{{ entity.name }}</option
                >
              </select>
            </div> -->
            <!-- Attribute Selection Starts -->
            <!-- <ng-container
              *ngIf="basicOrNested == 'Nested' && targetDcdValue.nslAttributes.length > 0"
              [ngTemplateOutlet]="attributeTargetDDBlock"
              [ngTemplateOutletContext]="{
                entity: targetDcdValue
              }"
            >
            </ng-container> -->
            <!-- Attribute Selection Ends -->
          </div>
          <div *ngIf="targetType == 'Collection' && targetDcdValue.solutionLogic.length == 0">
            <div class="search-field">
              <input name="gsiSearch" placeholder="search" type="text" (keyup)="searchGsi()" [(ngModel)]="searchText" />
            </div>
            <div class="gsiList">
              <ul class="gsilist-pop">
                <li *ngFor="let gsi of myCollection" (click)="getGsiDetails(gsi)">
                  <span class="gsiName">
                    {{ gsi.name }}
                  </span>
                  <div class="ml-auto">
                    <span *ngIf="gsi.status === 'DRAFT'">D</span>
                    <span *ngIf="gsi.status === 'ARCHIVED'">A</span>
                    <span *ngIf="gsi.status === 'PUBLISHED'">P</span>
                    <span *ngIf="gsi.status === 'READY'">R</span>
                    <span *ngIf="gsi.status === 'APPROVED'">Ap</span>
                  </div>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="targetType == 'Collection' && targetDcdValue?.solutionLogic?.length > 0">
            <div class="form-group">
              <label class="field-label">{{ targetDcdValue.selectedGsi.name }}</label>
            </div>
            <div class="form-group">
              <label class="field-label">CHANGE_UNIT</label>
              <select
                class="custom-select"
                name="changeUnitName"
                aria-label=""
                (change)="targetChangeUnitChange(targetDcdValue.changeUnitIndex)"
                [(ngModel)]="targetDcdValue.changeUnitIndex"
              >
                <option value="-1" selected>Select Change Unit</option>
                <option
                  *ngFor="let targetCu of targetDcdValue?.solutionLogic; let targetIndex = index"
                  [value]="targetIndex"
                >
                  {{ targetCu.name }}</option
                >
              </select>
            </div>
            <div class="form-group" *ngIf="targetDcdValue?.selectedChangeUnit?.layers?.length > 0">
              <label>Layer</label>
              <select
                class="custom-select"
                aria-label=""
                name="layer_select"
                (change)="changeTargetLayer(targetDcdValue.layerType)"
                [(ngModel)]="targetDcdValue.layerType"
                aria-label=""
              >
                <option value="">Select</option>
                <option
                  *ngFor="let layer of targetDcdValue?.selectedChangeUnit?.layers; let layerIndex = index"
                  [value]="layer.type"
                >
                  {{ layer.type }}
                </option>
              </select>
            </div>
            <div class="form-group" *ngIf="targetDcdValue?.layerType && targetDcdValue.participatingItems?.length > 0">
              <mat-label class="field-label">Object</mat-label>
              <select
                class="custom-select"
                aria-label=""
                name="select_object"
                (change)="changeTargetEntity(targetDcdValue.entityIndex)"
                [(ngModel)]="targetDcdValue.entityIndex"
              >
                <option value="-1" selected>Select Option</option>

                <option
                  *ngFor="let entity of targetDcdValue?.participatingItems; let entityIndex = index"
                  [value]="entityIndex"
                >
                  <span *ngIf="entity?.item?.DATA?.name">{{ entity.item.DATA.name }}</span>
                  <span *ngIf="entity?.name">{{ entity.name }}</span>
                </option>
              </select>
            </div>

            <!-- Attribute Selection Starts -->
            <ng-container
              *ngIf="targetDcdValue.nslAttributes.length > 0"
              [ngTemplateOutlet]="attributeTargetDDBlock"
              [ngTemplateOutletContext]="{
                entity: targetDcdValue
              }"
            >
            </ng-container>
            <!-- Attribute Selection Ends -->
          </div>
          <!--nested dcd flow-->
          <div class="form-group slot-group-display" *ngIf="targetType == 'Nested'">
            <label class="field-label">Change Unit</label>
            <select
              class="custom-select"
              name="changeUnitName"
              aria-label=""
              (change)="targetChangeUnitChange(targetDcdValue.selectedNestedCU.changeUnitIndex, false, true)"
              [(ngModel)]="targetDcdValue.selectedNestedCU.changeUnitIndex"
            >
              <option value="-1" selected>Select Change Unit</option>
              <option
                *ngFor="let targetCu of targetNestedCu; let targetIndex = index"
                [value]="targetIndex"
                id="changeUnit_{{ targetCu?.name }}_slot_{{ targetIndex }}"
              >
                {{ targetCu.name }}</option
              >
            </select>
            <span class="slot-display" *ngIf="targetDcdValue.changeUnitIndex < 0">Slot</span>
            <span class="slot-display" *ngIf="targetDcdValue.changeUnitIndex >= 0">{{
              targetDcdValue?.changeUnitIndex
            }}</span>
            <div>
              <mat-radio-group
                *ngIf="targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.mindCUList?.length > 0"
                (change)="changeType()"
                name="nested_or_layers_select"
                aria-label="Select Layers Or Nested"
                [(ngModel)]="layerOrNested"
              >
                <mat-radio-button value="Layers">Layers</mat-radio-button>
                <mat-radio-button value="Nested">Nested</mat-radio-button>
              </mat-radio-group>
            </div>
            <app-nested-connectors
              *ngIf="openNested"
              [targetDcdValue]="targetDcdValue?.selectedNestedCU"
              [dcdNumber]="0"
            >
            </app-nested-connectors>
            <div
              class="form-group"
              *ngIf="
                targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.layers?.length > 0 && layerOrNested == 'Layers'
              "
            >
              <label>Layer</label>
              <select
                class="custom-select"
                aria-label=""
                name="layer_select"
                (change)="changeTargetLayer(targetDcdValue.selectedNestedCU.layerType)"
                [(ngModel)]="targetDcdValue.selectedNestedCU.layerType"
                aria-label=""
              >
                <option value="">Select</option>
                <option
                  *ngFor="
                    let layer of targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.layers;
                    let layerIndex = index
                  "
                  [value]="layer.type"
                >
                  {{ layer.type }}
                </option>
              </select>
            </div>
            <div
              class="form-group"
              *ngIf="
                targetDcdValue?.selectedNestedCU?.layerType &&
                targetDcdValue?.selectedNestedCU?.participatingItems?.length > 0
              "
            >
              <mat-label class="field-label">Object</mat-label>
              <select
                class="custom-select"
                aria-label=""
                name="select_object"
                (change)="changeTargetEntity(targetDcdValue.selectedNestedCU.entityIndex, true)"
                [(ngModel)]="targetDcdValue.selectedNestedCU.entityIndex"
              >
                <option value="-1" selected>Select Option</option>

                <option
                  *ngFor="let entity of targetDcdValue?.selectedNestedCU?.participatingItems; let entityIndex = index"
                  [value]="entityIndex"
                  >{{ entity.name }}</option
                >
              </select>
            </div>
            <ng-container
              *ngIf="targetDcdValue?.selectedNestedCU?.nslAttributes?.length > 0"
              [ngTemplateOutlet]="nestedAttributeTargetDDBlock"
              [ngTemplateOutletContext]="{
                entity: targetDcdValue.selectedNestedCU
              }"
            >
            </ng-container>
          </div>

          <button class="btn add-fld ml-auto" (click)="addTarget()">Add Target</button>
          <div class="add-source-pan">
            <div class="form-group mb-0">
              <label class="field-label">Target</label>
              <p
                *ngFor="let display of targetArray; let targetIndex = index"
                class="selected-filed d-flex justify-content-between"
              >
                <span>{{ display.formulaId | dcd }}</span>
                <span class="material-icons list-alert" (click)="deleteTarget(targetIndex)">delete</span>
              </p>
            </div>
          </div>
          <div class="cstm-btn-wrap">
            <button class="btn create-fld save" (click)="resetConnectors()">Reset</button>
            <button class="btn create-fld ml-auto" (click)="completeDCD()">Create</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Displaying Connectors begins -->
    <div class="con-right-content">
      <div class="con-right-topbar">
        <h4>LIST OF CONNECTORS ({{ totalDcds }})</h4>

        <div class="toggle-icon">
          <img [src]="'../../../../../../assets/img/nsl-gram/icon-toggle.svg' | CDNUrlRewritePipe" />
        </div>
      </div>
      <div class="individual-dcd">
        <div class="con-list">
          <ol>
            <!-- connector -->
            <div cdkDropList [cdkDropListData]="dcdsList" (cdkDropListDropped)="onNodeDCDRearrange($event)">
              <li *ngFor="let completedDcd of dcdsList; let i = index" cdkDrag>
                <div
                  *ngIf="!completedDcd?.dcds"
                  class="d-flex align-items-center"
                  [ngClass]="{ errorClass: completedDcd?.hasError }"
                >
                  <div class="con-list-left">
                    <div class="ptype">
                      <p *ngIf="completedDcd.sourceArray?.length > 0">
                        <span
                          *ngFor="let display of completedDcd.sourceArray; let last = last"
                          pTooltip="{{ completedDcd?.errorMessage }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook connectortooltip"
                        >
                          <span id="source">{{ display.name | dcd }}<span *ngIf="!last">,</span></span>
                        </span>
                      </p>
                    </div>
                    <div class="ctype">
                      <figure class="w-auto mr-2">
                        <img [src]="'../../../../../../assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" />
                      </figure>
                      <div class="hovertext">
                        <span id="dcd_{{ i }}">
                          {{ completedDcd.targetContextualName | dcdLabel: false }}
                        </span>
                        <span id="formula_{{ i }}" *ngIf="completedDcd?.formulaArray?.length > 1">
                          , {{ formulaStringArray[i] }}
                        </span>

                        <!-- <span *ngIf="completedDcd?.targetContextualName && !completedDcd?.isEntity && !completeDCD.isAttribute">{{
                        completedDcd.targetContextualName | dcd
                      }}</span> -->
                      </div>
                    </div>
                  </div>
                  <!-- delete icon -->
                  <div class="checkbox-dcdgrop" *ngIf="dcdsList?.length > 1">
                    <input
                      type="checkbox"
                      [(ngModel)]="completedDcd.isInDCDGroup"
                      (change)="toggleVisibility($event, i)"
                    />
                  </div>
                  <div class="con-condition" (click)="openDcdConditions(i)"></div>
                  <div class="con-list-right" (click)="deleteConnector(i)"></div>
                </div>
                <ol class="grouped-dcd-main" *ngIf="completedDcd?.dcds">
                  <div>
                    <li *ngFor="let groupedDcd of completedDcd.dcds; let j = index" cdkDrag>
                      <div class="d-flex align-items-center" [ngClass]="{ errorClass: groupedDcd?.hasError }">
                        <div class="con-list-left">
                          <div class="ptype">
                            <p *ngIf="groupedDcd.sourceArray?.length > 0">
                              <span
                                *ngFor="let display of groupedDcd.sourceArray; let last = last"
                                pTooltip="{{ groupedDcd?.errorMessage }}"
                                tooltipPosition="top"
                                tooltipStyleClass="transcustomtolltipbook connectortooltip"
                              >
                                <span id="source">{{ display.name | dcd }}<span *ngIf="!last">,</span></span>
                              </span>
                            </p>
                          </div>
                          <div class="ctype">
                            <figure class="w-auto mr-2">
                              <img [src]="'../../../../../../assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <div class="hovertext">
                              <span id="dcd_{{ i }}_{{ j }}">
                                {{ groupedDcd.targetContextualName | dcdLabel: false }}
                              </span>
                              <span id="formula_{{ i }}_{{ j }}" *ngIf="groupedDcd?.formulaArray?.length > 1">
                                , {{ formulaStringArray[j] }}
                              </span>

                              <!-- <span *ngIf="completedDcd?.targetContextualName && !completedDcd?.isEntity && !completeDCD.isAttribute">{{
                            completedDcd.targetContextualName | dcd
                          }}</span> -->
                            </div>
                          </div>
                        </div>
                        <!-- delete icon -->
                        <div
                          *ngIf="completedDcd.dcds.length > 2"
                          class="con-list-right"
                          (click)="deleteGroupedConnector(i, j)"
                        ></div>
                      </div>
                    </li>
                  </div>
                  <div class="icon-dcd-main">
                    <div class="con-condition" (click)="openDcdConditions(i)"></div>
                    <div class="con-list-right" (click)="deleteConnector(i)"></div>
                  </div>
                </ol>
              </li>
            </div>
            <!-- connector ends -->
          </ol>
        </div>
      </div>

      <div class="btn group-btn" *ngIf="dcdsList?.length > 1" (click)="openDcdConditions()">
        <button>Group</button>
      </div>
      <!-- <div class="group-dcd">
      <div class="con-list">
        <ol>
          <div cdkDropList [cdkDropListData]="dcdsList" (cdkDropListDropped)="onNodeDCDRearrange($event)">
            <li *ngFor="let completedDcdGroup of dcdsList; let i = index" cdkDrag>
              
            </li>
          </div>
        </ol>
      </div>
     </div> -->
    </div>
    <!-- Displaying Connectors Ends -->
  </div>
</div>

<ng-template #attributeSourceDDBlock let-entity="entity">
  <!-- Attribute Selection Starts -->
  <div class="form-group" *ngIf="entity.nslAttributes.length > 0">
    <label class="field-label">Attributes</label>
    <select
      class="custom-select"
      aria-label=""
      name="select_attribute"
      (change)="changeAttribute(entity, $event.target.value)"
    >
      <option value="-1" selected>Select Attribute</option>
      <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option>
    </select>
  </div>

  <div *ngIf="entity.nslAttributes.length > 0">
    <div *ngFor="let attr of entity.nslAttributes">
      <ng-container
        *ngIf="attr.generalEntity && attr.isSelected"
        [ngTemplateOutlet]="attributeSourceDDBlock"
        [ngTemplateOutletContext]="{
          entity: attr.generalEntity
        }"
      >
      </ng-container>
    </div>
  </div>
  <!-- Attribute Selection Ends -->
</ng-template>

<!-- for measure entity -->
<ng-template #attributeSourceMeasuresBlock let-entity="entity">
  <div class="form-group">
    <label class="field-label">Measure Entity</label>
    <select class="custom-select" aria-label="" name="select_measure_entity">
      <option value="-1" selected>{{ entity.name }}</option>
      <!-- <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option> -->
    </select>
  </div>
  <!-- Attribute Selection Starts -->
  <div class="form-group" *ngIf="entity.nslAttributes.length > 0">
    <label class="field-label">Measure Attributes</label>
    <select
      class="custom-select"
      aria-label=""
      name="select_measure_attribute"
      (change)="selectedMeasureAttribute($event.target.value)"
    >
      <option value="-1" selected>Select Attribute</option>
      <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option>
    </select>
  </div>

  <!-- <div *ngIf="entity.nslAttributes.length > 0">
    <div *ngFor="let attr of entity.nslAttributes">
      <ng-container
        *ngIf="attr.generalEntity && attr.isSelected"
        [ngTemplateOutlet]="attributeSourceDDBlock"
        [ngTemplateOutletContext]="{
          entity: attr.generalEntity
        }"
      >
      </ng-container>
    </div>
  </div> -->
  <!-- Attribute Selection Ends -->
</ng-template>

<ng-template #attributeTargetDDBlock let-entity="entity">
  <!-- Attribute Selection Starts -->
  <div class="form-group" *ngIf="entity.nslAttributes.length > 0">
    <label class="field-label">Attributes</label>
    <select
      class="custom-select"
      aria-label=""
      name="select_attribute"
      (change)="changeTargetAttribute(entity, $event.target.value)"
    >
      <option value="-1" selected>Select Attribute</option>
      <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option>
    </select>
  </div>
  <div *ngIf="entity.nslAttributes.length > 0">
    <div *ngFor="let attr of entity.nslAttributes">
      <ng-container
        *ngIf="attr.generalEntity && attr.isSelected"
        [ngTemplateOutlet]="attributeTargetDDBlock"
        [ngTemplateOutletContext]="{
          entity: attr.generalEntity
        }"
      >
      </ng-container>
    </div>
  </div>
  <!-- Attribute Selection Ends -->
</ng-template>
<ng-template #nestedAttributeTargetDDBlock let-entity="entity">
  <!-- Attribute Selection Starts -->
  <div class="form-group" *ngIf="entity.nslAttributes.length > 0">
    <label class="field-label">Attributes</label>
    <select
      class="custom-select"
      aria-label=""
      name="select_attribute"
      (change)="changeTargetAttribute(entity, $event.target.value)"
    >
      <option value="-1" selected>Select Attribute</option>
      <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option>
    </select>
  </div>
  <div *ngIf="entity.nslAttributes.length > 0">
    <div *ngFor="let attr of entity.nslAttributes">
      <ng-container
        *ngIf="attr.generalEntity && attr.isSelected"
        [ngTemplateOutlet]="nestedAttributeTargetDDBlock"
        [ngTemplateOutletContext]="{
          entity: attr.generalEntity
        }"
      >
      </ng-container>
    </div>
  </div>
  <!-- Attribute Selection Ends -->
</ng-template>
<ng-template #nestedDetails let-nested="nestedcu">
  <!-- <mat-radio-group
    *ngIf="(nested?.mindCUList)[0]?.length > 0"
    (change)="targetMindCuList(nested.mindCUList[0]?.gsi, $event.target.value)"
    name="basicOrNetsed_select"
    aria-label="Select Connector Type"
  >
    <mat-radio-button value="Basic">BasicCU’s</mat-radio-button>
    <mat-radio-button value="Nested">NestedCU’s</mat-radio-button>
  </mat-radio-group> -->
  <!-- <select nested> -->
  <!-- <div class="form-group" *ngIf="basicOrNested == 'Nested'">
    <label>NestedCU</label>
    <select class="custom-select" aria-label="" name="nestedSelect" aria-label="">
      <option value="">Select</option>
      <option *ngFor="let cu of fetchedNested; let layerIndex = index" [value]="cu">
        {{ cu.name }}
      </option>
    </select>
  </div> -->
  <!-- <div *ngIf="basicOrNested == 'Nested' && (nested?.mindCUList)[0]?.length > 0">
    <ng-container *ngTemplateOutlet="nestedDetails; context: { nestedcu: (nested?.mindCUList)[0] }"> </ng-container>
  </div> -->
  <div class="form-group" *ngIf="targetDcdValue.selectedChangeUnit.layers.length">
    0">
    <label>Layer</label>
    <select
      class="custom-select"
      aria-label=""
      name="layer_select"
      (change)="changeTargetLayer(targetDcdValue.layerType)"
      [(ngModel)]="targetDcdValue.layerType"
      aria-label=""
    >
      <option value="">Select</option>
      <option
        *ngFor="let layer of targetDcdValue.selectedChangeUnit.layers; let layerIndex = index"
        [value]="layer.type"
      >
        {{ layer.type }}
      </option>
    </select>
  </div>
  <div class="form-group" *ngIf="targetDcdValue?.layerType && targetDcdValue.participatingItems?.length > 0">
    <mat-label class="field-label">Object</mat-label>
    <select
      class="custom-select"
      aria-label=""
      name="select_object"
      (change)="changeTargetEntity(targetDcdValue.entityIndex)"
      [(ngModel)]="targetDcdValue.entityIndex"
    >
      <option value="-1" selected>Select Option</option>

      <option *ngFor="let entity of targetDcdValue.participatingItems; let entityIndex = index" [value]="entityIndex">{{
        entity.name
      }}</option>
    </select>
  </div>
  <!--ends-->
</ng-template>
