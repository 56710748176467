import { NgModule } from '@angular/core';
import { FusionChartsComponent } from './fusion-charts.component';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as Widgets from 'fusioncharts/fusioncharts.widgets';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as TreeMap from 'fusioncharts/fusioncharts.treemap.js';
import * as Gantt from 'fusioncharts/fusioncharts.gantt';
import * as PowerCharts from 'fusioncharts/fusioncharts.powercharts';
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme, PowerCharts, Widgets, TreeMap, Gantt);
FusionCharts.options['license']({
  key:
    'xjB3fB-8qH2B7B2A5A4A5E4D4B3F3C11A5C7vaiD4F1nhfxiD5D3A-8teB6A6E3E5B4E4E3F2I3C8A4C4D5D1D3G4poiB9D2C6iG4F4D3G-7B-31jA5C5E3tpgA1C3A4tllG2F4H1C2C6D5C5A3E6D4G4ckeC6D4PF4kmI-8F2C2D5uf1C2KC1C1G-7B-21oC1E5C1B3H3E2E2C7A3D3C4C2G1C3w==',
  creditLabel: false,
});





@NgModule({
  declarations: [
    
    FusionChartsComponent
  ],

  imports: [
   
    FusionChartsModule
  ],
  exports: [
    
    FusionChartsComponent
  ],
 
})
export class CustomFusionChartsModule {}
