<div mat-dialog-content class="modal-body p-0 nh-mb-20 screen-2-modal-body">
  <div class="sort-order adfeatures">
    <div class="d-flex flex-row-reverse nh-column-gap-10 nh-mb-16  nh-mr-16">
      <div class="btns">
        <button (click)="addRuleSet()"><span>+</span> Ruleset</button>
      </div>
      <div class="and-or" *ngIf="hiddenRuleSet?.ruleSet?.length > 1">
        <button [ngClass]="{ active: hiddenRuleSet.ruleSetCondition == 'AND' }" (click)="CheckAndRule()">
          {{ labels?.AND }}
        </button>
        <button [ngClass]="{ active: hiddenRuleSet.ruleSetCondition == 'OR' }" (click)="CheckOrRule()">
          {{ labels?.OR }}
        </button>
      </div>
    </div>
    <div
      class="condition-container d-flex nh-row-gap-15 flex-column"
      *ngFor="let rule of hiddenRuleSet?.ruleSet; let ruleIndex = index"
    >
      <div
        class="conditions nh-ml-30"
        [ngClass]="{'after':ruleIndex!=0,'before':hiddenRuleSet?.ruleSet?.[ruleIndex+1]}"
      >
        <span class="name">{{ labels?.Add_Condition }}</span>
        <div class="d-flex flex-row-reverse nh-column-gap-10">
          <div class="btns">
            <button (click)="deleteRuleSet(ruleIndex)">
              {{ labels?.Delete }}
            </button>
            <button (click)="addRule(ruleIndex)"><span>+</span> {{ labels?.Rule }}</button>
          </div>
          <div *ngIf="rule?.hiddenConditions?.length > 1" class="and-or">
            <button [ngClass]="{ active: rule.inConditions == 'AND' }" (click)="CheckAnd(ruleIndex)">
              {{ labels?.AND }}
            </button>
            <button [ngClass]="{ active: rule.inConditions == 'OR' }" (click)="CheckOr(ruleIndex)">
              {{ labels?.OR }}
            </button>
          </div>
        </div>
        <div class="and-or-conditions" *ngFor="let condition of rule?.hiddenConditions; let conditionIndex = index">
          <div
            class="choose-operator nh-ml-30"
            [ngClass]="{'after':conditionIndex!=0,'before':rule?.hiddenConditions?.[conditionIndex+1]}"
          >
            <ng-container>
              <p-dropdown
                header="Select Attribute"
                placeholder="{{ labels?.Select_Attribute }}"
                [(ngModel)]="condition.attributeName"
                [editable]="true"
                [options]="selectedEntityData?.nslAttributes"
                optionLabel="name"
                appendTo="body"
                panelStyleClass="ruleset-dropdown"
              >
              </p-dropdown>
              <p-dropdown
                header="Select Operator"
                placeholder="{{ labels?.Select_Operator }}"
                [(ngModel)]="condition.conditionName"
                [editable]="true"
                [options]="operators"
                appendTo="body"
                panelStyleClass="ruleset-dropdown"
              >
              </p-dropdown>
              <input
                class="default-search-input"
                (click)="type.toggle($event)"
                type="text"
                placeholder="{{ labels?.text }}"
                [(ngModel)]="condition.value"
              />
              <p-overlayPanel styleClass="typeOverlay" #type>
                <ul *ngIf="!showUserData">
                  <li (click)="currentUser($event)">{{ labels?.Current_User || 'CurrentUser' }}</li>
                  <li (click)="setUserToCondition($event, condition, 'currentDate')">
                    {{ labels?.Current_Date }}
                  </li>
                </ul>
                <ul class="user-chips" *ngIf="showUserData">
                  <li (click)="setUserToCondition($event, condition, 'email')">{{ labels?.Email || 'Email' }}</li>
                  <li (click)="setUserToCondition($event, condition, 'firstName')">
                    {{ labels?.First_Name }}
                  </li>
                  <li (click)="setUserToCondition($event, condition, 'name')">{{ labels?.Name || 'Name' }}</li>
                  <li (click)="setUserToCondition($event, condition, 'mobileNumber')">
                    {{ labels?.Mobile_Number }}
                  </li>
                  <li (click)="setUserToCondition($event, condition, 'lastName')">
                    {{ labels?.Last_Name || 'Last Name' }}
                  </li>
                </ul>
              </p-overlayPanel>
              <button
                *ngIf="condition.length != 1"
                class="remove-condition"
                (click)="deleteCondition(conditionIndex, ruleIndex)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </ng-container>
          </div>
          <div class="and-or" *ngIf="rule.hiddenConditions?.[conditionIndex+1]">
            <button class="active one-button">
              {{ rule.inConditions }}
            </button>
          </div>
        </div>
      </div>
      <div class="and-or" *ngIf="hiddenRuleSet?.ruleSet?.[ruleIndex+1]">
        <button class="active one-button">
          {{ hiddenRuleSet?.ruleSetCondition }}
        </button>
      </div>
    </div>
  </div>
</div>
