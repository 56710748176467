<div class="attr-list" *ngIf="sort && individual && individual.length > 0">
  <mat-radio-group class="order" aria-label="Select sort option" (change)="emitSortRadioButton($event, 'sortOption')">
    <mat-radio-button [value]="1">Ascending</mat-radio-button>
    <mat-radio-button [value]="2">Descending</mat-radio-button>
  </mat-radio-group>
  <mat-radio-group
    class="order order-option"
    aria-label="Select sort option"
    (change)="emitSortRadioButton($event, 'sortOptionAttr')"
  >
    <mat-radio-button [value]="attr?.attributeName" *ngFor="let attr of individual; let i = index">{{
      attr?.attributeName
    }}</mat-radio-button>
  </mat-radio-group>
</div>
<div class="range-types">
  <ng-container *ngIf="templateMap[id].includes('CHIP')">
    <form class="er-filter-templates">
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable?.dataType === 'Multi'">
        <div
          *ngIf="
            dummyVariable?.borderRadius === 6 ||
            dummyVariable?.borderRadius === 30 ||
            dummyVariable?.borderRadius === 18
          "
          class="er-filter-btn-group"
          [ngClass]="{
            'er-filter-btn-group-opt1': dummyVariable?.borderRadius === 6,
            'er-filter-btn-group-opt2': dummyVariable?.borderRadius === 30,
            'er-filter-btn-group-opt4': dummyVariable?.borderRadius === 18
          }"
        >
          <ul>
            <li *ngFor="let attr of dummyVariable?.data">
              <button class="er-filter-btn" [ngClass]="{ active: attr.checked }" (click)="emitChipData(attr)">
                {{ attr?.minValue }}-{{ attr?.maxValue }}
              </button>
            </li>
          </ul>
        </div>
        <div
          *ngIf="dummyVariable?.borderRadius === 4"
          class="er-filter-btn-group"
          [ngClass]="{
            'er-filter-btn-group-opt3': dummyVariable?.borderRadius === 4
          }"
        >
          <ng-container>
            <ul *ngFor="let attr of dummyVariable?.data">
              <li>
                <button class="er-filter-btn" [ngClass]="{ active: attr.checked }" (click)="emitChipData(attr)">
                  {{ attr?.minValue }}
                </button>
              </li>
              <h6>-</h6>
              <li>
                <button class="er-filter-btn" [ngClass]="{ active: attr.checked }" (click)="emitChipData(attr)">
                  {{ attr?.maxValue }}
                </button>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable?.dataType === 'Single'">
        <!-- <div class="range-type search-field-type"> -->
        <div
          class="er-filter-btn-group"
          [ngClass]="{
            'er-filter-btn-group-opt4': dummyVariable?.borderRadius === 6,
            'er-filter-btn-group-opt5': dummyVariable?.borderRadius === 30,
            'er-filter-btn-group-opt1': dummyVariable?.borderRadius === 4,
            'er-filter-btn-group-opt3': dummyVariable?.borderRadius === 16,
            'er-filter-btn-group-opt2': dummyVariable?.borderRadius === 0
          }"
        >
          <ul>
            <li class="w-100" *ngFor="let attr of dummyVariable?.data">
              <button
                matTooltip="{{ attr?.value }}"
                matTooltipPosition="right"
                class="er-filter-btn text-truncate"
                [ngClass]="{ active: attr.checked }"
                (click)="emitChipData(attr)"
              >
                {{ attr?.value }}
              </button>
            </li>
          </ul>
        </div>
        <!-- </div> -->
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id].includes('SLIDER')">
    <form class="er-filter-templates">
      <div class="er-filter-slider" *ngIf="dummyVariable?.type === 2">
        <ul class="filter-range" *ngIf="dummyVariable?.inputs === 2">
          <li>
            <p>
              Minimum<span>₹ {{ sliderValues[0] }}</span>
            </p>
          </li>
          <li>
            <p>
              Maximum<span>₹ {{ sliderValues[1] }}</span>
            </p>
          </li>
        </ul>
        <p-slider
          [name]="dummyVariable.id"
          [(ngModel)]="dummyVariable.data['rangeValues']"
          [range]="true"
          (onChange)="changeSliderEvent($event)"
        ></p-slider>
        <ul class="filter-value" *ngIf="dummyVariable?.label === 2">
          <li>
            <p>{{ sliderValues[0] }}</p>
          </li>
          <li>
            <p>{{ sliderValues[1] }}</p>
          </li>
        </ul>
        <ul class="filter-value" *ngIf="dummyVariable?.label === 1">
          <!-- <li><p>100</p></li> -->
          <li>
            <p>{{ sliderValues[1] }}</p>
          </li>
        </ul>
      </div>
      <div class="er-filter-slider er-filter-range-slider" *ngIf="dummyVariable?.type === 1">
        <ul class="filter-range" *ngIf="dummyVariable?.inputs === 1">
          <li>
            <p>
              Minimum<span>₹ {{ sliderValues[0] }}</span>
            </p>
          </li>
        </ul>
        <p-slider
          [name]="dummyVariable.id"
          [(ngModel)]="dummyVariable.data['rangeValues']"
          (onChange)="changeSingleSliderValue($event)"
          class="w-full"
        ></p-slider>
        <ul class="filter-value" *ngIf="dummyVariable?.label === 1">
          <!-- <li><p>100</p></li> -->
          <li>
            <p>{{ sliderValues[1] }}</p>
          </li>
        </ul>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id].includes('CHECK_BOX')">
    <form class="er-filter-templates">
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable?.dataType == 'Single'">
        <!-- <label class="dummy-lable d-block">Option 1</label> -->
        <ng-container *ngIf="!dummyVariable?.count">
          <div class="d-flex flex-column">
            <mat-checkbox
              *ngFor="let attr of dummyVariable?.data"
              (change)="emitCheckBox($event, attr)"
              disableRipple
              class="er-filter-check er-filter-check-a"
              pTooltip="{{ attr?.value }}"
              tooltipPosition="top"
              >{{ attr?.value }}</mat-checkbox
            >
          </div>
        </ng-container>
        <ng-container *ngIf="dummyVariable?.count">
          <div class="d-flex flex-column">
            <mat-checkbox
              (change)="emitCheckBox($event, attr)"
              disableRipple
              class="er-filter-check er-filter-check-a"
              *ngFor="let attr of dummyVariable?.data"
              [checked]="attr.checked"
              pTooltip="{{ attr?.value }} ({{ attr?.count }})"
              tooltipPosition="top"
              >{{ attr?.value }} ({{ attr?.count }})</mat-checkbox
            >
          </div>
        </ng-container>
      </div>
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable?.dataType == 'Range'">
        <div class="d-flex flex-column">
          <mat-checkbox
            disableRipple
            class="er-filter-check er-filter-check-b"
            (change)="emitCheckBox($event, attr)"
            *ngFor="let attr of dummyVariable?.data"
            pTooltip="{{ (attr?.rangeValues)[0] }}-{{ (attr?.rangeValues)[1] }}%"
            tooltipPosition="top"
            >{{ (attr?.rangeValues)[0] }}-{{ (attr?.rangeValues)[1] }}%
          </mat-checkbox>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'TOGGLE_BUTTONS'">
    <form class="er-filter-templates">
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable?.type === 1">
        <div class="d-flex flex-column">
          <mat-slide-toggle
            *ngFor="let attr of dummyVariable?.data"
            [checked]="attr.checked"
            (change)="emitToggleData($event, attr)"
            disableRipple
            class="er-filter-toggle er-filter-toggle-a"
            >{{ attr?.value }}</mat-slide-toggle
          >
        </div>
      </div>
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable?.type === 2">
        <div class="d-flex flex-column">
          <mat-slide-toggle
            *ngFor="let attr of dummyVariable?.data"
            [checked]="attr.checked"
            (change)="emitToggleData($event, attr)"
            disableRipple
            class="er-filter-toggle er-filter-toggle-b"
            >{{ attr?.value }}</mat-slide-toggle
          >
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'RADIO_BUTTON'">
    <form class="er-filter-templates">
      <div class="mb-3 d-flex flex-column">
        <mat-radio-group
          [name]="name"
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="radioButtonValue"
          (change)="emitRadioButton($event)"
        >
          <div class="d-flex flex-column">
            <mat-radio-button
              *ngFor="let attr of dummyVariable?.data"
              [name]="dummyVariable.value"
              disableRipple
              class="er-filter-radio er-filter-radio-a"
              [value]="attr?.value"
              pTooltip="{{ attr?.value }}"
              tooltipPosition="top"
              >{{ attr?.value }}</mat-radio-button
            >
          </div>
        </mat-radio-group>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'RATINGS'">
    <form class="er-filter-templates">
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable.type == 'CheckBox' && !dummyVariable.series">
        <ng-container *ngIf="dummyVariable.count">
          <div class="d-flex flex-column">
            <mat-checkbox
              *ngFor="let data of dummyVariable.data"
              (change)="emitRatingCheckBox($event, data)"
              disableRipple
              class="er-filter-check er-filter-check-a"
              >{{ data?.value }} <i class="bi bi-star-fill"></i> & Above ({{ data?.count }})</mat-checkbox
            >
          </div>
        </ng-container>
        <ng-container *ngIf="!dummyVariable.count">
          <div class="d-flex flex-column">
            <mat-checkbox
              disableRipple
              class="er-filter-check er-filter-check-a"
              *ngFor="let data of dummyVariable.data"
              >{{ data?.value }} <i class="bi bi-star-fill"></i> & Above
            </mat-checkbox>
          </div>
        </ng-container>
      </div>
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable.type == 'CheckBox' && dummyVariable.series">
        <div class="d-flex flex-column" *ngIf="!dummyVariable.count && dummyVariable.series.borderColor == false">
          <mat-checkbox
            (change)="emitRatingCheckBox($event, data)"
            *ngFor="let data of dummyVariable.data"
            disableRipple
            class="er-filter-check er-filter-check-a"
            ><i class="bi bi-star-fill"></i>
            <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value == '1' }"></i>
            <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 2 }"></i>
            <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 3 }"></i>
            <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 4 }"></i> & Above
          </mat-checkbox>
        </div>
      </div>
      <div class="mb-3 d-flex flex-column" *ngIf="dummyVariable.type == 'Radio' && dummyVariable.series">
        <ng-container *ngIf="!dummyVariable.count">
          <div class="d-flex flex-column" *ngIf="dummyVariable.series.borderColor == false">
            <mat-radio-button
              disableRipple
              class="er-filter-radio er-filter-radio-a"
              (change)="emitRatingRadioButton($event)"
              *ngFor="let data of dummyVariable.data"
              ><i class="bi bi-star-fill"></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value == '1' }"></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 2 }"></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 3 }"></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 4 }"></i> & Above
            </mat-radio-button>
          </div>
        </ng-container>
      </div>
      <div class="mb-3 d-flex flex-column" *ngIf="!dummyVariable.type && dummyVariable.series">
        <ng-container *ngIf="dummyVariable.series.borderColor == 'color-add'">
          <ul class="d-flex flex-column" *ngFor="let data of dummyVariable.data">
            <!-- 10 star template -->
            <li class="er-filter-star" (click)="emitRatingClick(data)">
              <i class="bi bi-star-fill"></i>
              <i
                class="bi bi-star-fill"
                [ngClass]="{ disabled: data.value == '1', 'bi bi-star': data.value == '1' }"
              ></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 2, 'bi bi-star': data.value <= 2 }"></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 3, 'bi bi-star': data.value <= 3 }"></i>
              <i class="bi bi-star-fill" [ngClass]="{ disabled: data.value <= 4, 'bi bi-star': data.value <= 4 }"></i>
              <span>& Above</span>
            </li>
          </ul>
        </ng-container>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'DATE_SINGLE' || templateMap[id] == 'DATE_RANGE'">
    <form class="er-filter-templates">
      <div class="mb-3" *ngIf="dummyVariable?.type == 'Range' && !dummyVariable?.time">
        <p-calendar
          class="er-filter-date er-filter-date-range"
          [name]="dummyVariable.id"
          [(ngModel)]="rangeDates"
          selectionMode="range"
          [readonlyInput]="true"
          [inline]="true"
          inputId="range"
          (onSelect)="emitDateRange($event)"
        ></p-calendar>
      </div>

      <div class="mb-3" *ngIf="dummyVariable?.type == 'Single' && !dummyVariable?.time">
        <p-calendar
          class="er-filter-date"
          [name]="dummyVariable.id"
          (onSelect)="emitDateSingle($event)"
          [(ngModel)]="date14"
          [inline]="true"
        ></p-calendar>
      </div>

      <div class="mb-3" *ngIf="dummyVariable?.type == 'Single' && dummyVariable?.time">
        <p-calendar
          class="er-filter-date er-filter-datetime"
          [name]="dummyVariable.id"
          [inline]="true"
          [showWeek]="true"
          [(ngModel)]="date14"
          (onSelect)="emitDateSingle($event)"
          [showTime]="true"
          [showSeconds]="true"
        ></p-calendar>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'LOCATE'">
    <div class="range-type search-field-type">
      <div class="place" *ngIf="dummyVariable?.inputs === 1">
        <input type="text" placeholder="{{ labels?.Locality }},{{ labels?.Landmark }},{{ labels?.city }}" />
      </div>
      <div class="place" *ngIf="dummyVariable?.inputs === 2">
        <input type="text" placeholder="Where from?" />
        <input type="text" placeholder="Where to?" />
      </div>
      <!-- above Add "search-field-type" class for search-field  -->
    </div>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'DATES'">
    <div class="range-type search-field-type">
      <div class="select-date" *ngIf="dummyVariable?.inputs === 2">
        <button class="from-date">Start Date</button>
        <span class="text">to</span>
        <button class="from-date">End Date</button>
        <!-- Use w-100 class for full width button -->
      </div>
      <div class="select-date" *ngIf="dummyVariable?.inputs === 1">
        <button class="from-date">Select Date</button>
        <!-- Use w-100 class for full width button -->
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="templateMap[id] == 'CSTM_FILTER'">
    <div class="range-type search-field-type">
      <div class="cst-filter">
        Name
      </div>
    </div>
  </ng-container>
</div>
<div class="combinations-templates" *ngIf="templateMap[id] == 'DATE_AND_COST'">
  <div class="template-types">
    <div class="template-type">
      <div class="template-flied">
        <span *ngIf="dummyVariable?.fields === 3 || dummyVariable?.fields === 2">Wed, 12 Jul</span>
        <span *ngIf="dummyVariable?.fields === 3 || dummyVariable?.fields === 2">Rs. 2,850</span>
        <span *ngIf="dummyVariable?.fields === 3">Less then 2kms</span>
      </div>
      <div class="template-flied">
        <span *ngIf="dummyVariable?.fields === 3 || dummyVariable?.fields === 2">Wed, 12 Jul</span>
        <span *ngIf="dummyVariable?.fields === 3 || dummyVariable?.fields === 2">Rs. 2,850</span>
        <span *ngIf="dummyVariable?.fields === 3">Less then 2kms</span>
      </div>
      <div class="template-flied">
        <span *ngIf="dummyVariable?.fields === 3 || dummyVariable?.fields === 2">Wed, 12 Jul</span>
        <span *ngIf="dummyVariable?.fields === 3 || dummyVariable?.fields === 2">Rs. 2,850</span>
        <span *ngIf="dummyVariable?.fields === 3">Less then 2kms</span>
      </div>
    </div>
  </div>
</div>
