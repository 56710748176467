import { AttributeType } from './attribute-type';
import { Attribute } from './attribute';
export interface cartStyles {
  color: string;
  font: string;
  fontSize: string;
  fontStyle?: string;
  textAlign: string;
  borderColor?: string;
  borderSize?: number;
  borderRadius?: number;
  boxShadow?: string;
  isItalic?: string;
  isBold?: string;
}
export interface GsiButton {
  displayName: string;
  entityType: 'GSI' | 'CART' | 'HYPERLINK' | 'PAGE';
  id: string;
  isSelected: boolean | { [key: string]: CartSelectionType };
  masterId: string;
  name: string;
  status?: string;
  version: string;
  position?: number;
  gsiData?: any;
}

export type PositionType = 'childPosition' | 'expandPosition' | 'position' | 'kanbanPosition';
export type SelectionType = 'selectedInChild' | 'selectedInExpand' | 'isSelected' | 'selectedInKanban';
export type TabType = 'child' | 'expand' | 'cart';
// whenever the attribute is not mapped the positon should be -1
// TODO:styles should have a interface
//! name and display name shouldn't be null or undefined
export interface DraggableAttribute {
  name: string;
  displayName: string;
  id: number;
  isSelected: boolean; //cart
  attributeType: AttributeType;
  uiElement: string;
  masterId: number;
  position?: number;
  childPosition: number;
  expandPosition: number;
  kanbanPosition?: number;
  selectedInKanban?: boolean;
  version?: string;
  selectedInChild: boolean;
  selectedInExpand: boolean;
  addOnChild?: attributeMap;
  addOnExpand?: attributeMap;
  styles?: { [x: string]: string };
}
export interface attributeMap {
  type?: mapType;
  value?: any;
}
export type mapType = 'hyperlink' | 'GSI' | 'PAGE';

export const CART_BUTTON: GsiButton = {
  displayName: 'Add to Cart',
  name: 'Add to Cart',
  id: '-1',
  entityType: 'CART',
  isSelected: {
    child: {
      isSelected: false,
      position: -1,
    },
    expand: {
      isSelected: false,
      position: -1,
    },
  },
  masterId: '-1',
  status: 'cart',
  version: '0',
};
export interface CartConfig {
  gsis: ButtonConfig[];
  templateType: number;
  background: string;
}

export interface ButtonConfig {
  position: number;
  color: string;
  border: string;
  borderRadius: string;
  font: string;
  fontSize: string;
  alignment: string;
  buttonTitle: string;
  gsiName: string;
  gsiId: number;
  gsiMasterId: number;
}

export interface CartItem {
  recordId: number;
  quantity: number;
  transEntityRecords: TransEntityRecords;
  modifiedRecord?: { [name: string]: any };
  price: number;
  createdAt: string;
  updatedAt: string;
  cartView?: CartView;
  entityName: string;
}

export interface CartView {
  attributeList: CartAttribute[];
  gsiList: any[];
  templateType: number;
  style: Style;
}

export interface Style {
  attrStyles?: any;
  attr: Attr;
  cartButton?: CartButton;
  pricePosition: number;
}

export interface CartButton {
  displayName: string;
  name: string;
  id: string;
  entityType: string;
  isSelected: boolean;
  masterId: string;
  status: string;
  version: string;
  position: number;
}

export interface Attr {
  [key: string]: number;
}

export interface CartAttribute {
  attributeId: number;
  attributeName: string;
}

export interface TransEntityRecords {
  txnNslAttribute: TxnNslAttribute[];
}

export interface TxnNslAttribute {
  name: string;
  nslAttributeID: number;
  values: string[];
}

interface CartSelectionType {
  isSelected: boolean;
  position: number;
}
export type Condition = {
  conditionName: string;
  attriubteName: Attribute;
  attributeName?: Attribute;
  type?: string;
  value: string;
};

export type RuleSet = {
  hiddenConditions: Condition[];
  inConditions: boolCondition;
};

export type RuleSetCondition = {
  ruleSet: RuleSet[];
  ruleSetCondition: boolCondition;
};

export type boolCondition = 'AND' | 'OR';

export type EntityData = {
  cardName: string;
  cartView: any;
  childView: ChildView;
  expandedView: ExpandedView;
  gsiList: any[];
  locationFilter: any[];
  range: any[];
  search: any[];
  searchFields: any[];
  filters: any;
  joinCriteria: any;
  joinedEntities: any[];
  primaryEntity: PrimaryEntity;
  roleId: number;
  sort: any;
  expandTemplateType: string;
  filterDesign: any;
  defaultFilter: RuleSet;
};
export type ChildView = {
  attributeList: any[];
  gsiList: any[];
  isMultiAttributeExpan: boolean;
  style: any;
  templateType: string | number;
  numberOfRows: number;
};
export type ExpandedView = {
  attributeList: any[];
  gsiList: any[];
  style: any;
};
// interface Style {
//   attr: string[];
//   attributeTypes: AttributeType[];
//   otherStyles: OtherStyle[];
//   btnColors: any[];
//   cardBackground: string;
//   color: string[];
//   conditionArray: any[];
//   gsiact: any[];
//   defaultViewType: string;
// }
export interface relatedAttribute {
  attributeName: string;
  attributeDisplayName: string;
}

export interface relatedAttributeGroup {
  groupName: string;
  relatedAttribute: relatedAttribute[];
}

export interface relatedAttributeCondition {
  group: relatedAttributeGroup;
}
export type IStyle = {
  changedElementValue: any;
  changedElementStyle: ElementStyle;
  isBorder?: boolean;
};
export type ICardStyle = {
  color: string;
  hoverColor: string;
  borderColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  boxShadow?: string;
};
export type IAttributeStyle = {
  attrColor: string;
  attHoverColor: string;
  fontFamily: string;
  fontSize: string;
  alignment: string;
  isBold: boolean;
  isItalic: boolean;
};
export type ElementStyle =
  | 'hoverBackgroundColor'
  | 'selectedColor'
  | 'fontSize'
  | 'fontFamily'
  | 'isItalic'
  | 'isBold'
  | 'alignment'
  | 'borderRadius'
  | 'borderSize'
  | 'selectedBorderColor';

export enum operatorType {
  '==' = 'IN',
  '<' = 'LT',
  '>' = 'GT',
  '!=' = 'NOT_EQUALS',
  '>=' = 'GTE',
  '<=' = 'LTE',
  'LIKE' = 'CONTAINS',
}

export enum reverseOperator {
  'IN' = '==',
  'LT' = '<',
  'GT' = '>',
  'NOT_EQUALS' = '!=',
  'GTE' = '>=',
  'LTE' = '<=',
  'CONTAINS' = 'LIKE',
}

export type PrimaryEntity = {
  id: string;
  masterId: string;
  name: string;
  version: string;
  displayName?: string;
};

export type ViewAndEditPayload = {
  viewers: {
    users: string[];
  };
  editors: {
    users: string[];
  };
  category: string;
  description: string;
};

export function addValueToAlreadyAddedSearchQuery(hiddenRuleSet: RuleSetCondition, primaryEntity: any) {
  const searchQuery: any = {
    searchQueryDepth: 0,
    operationType: hiddenRuleSet?.ruleSetCondition,
    queries: [],
  };
  hiddenRuleSet?.ruleSet?.forEach((rule: RuleSet) => {
    let conditionMap: Map<string, string[]> = new Map<string, string[]>();
    let queries: any[] = [];
    setQueriesFromCondition(rule, conditionMap, queries, primaryEntity);
    if (queries?.length > 0) {
      searchQuery?.queries?.push({
        searchQueryDepth: 0,
        operationType: rule?.inConditions,
        queries: queries,
      });
    }
  });
  return searchQuery;
}

function setQueriesFromCondition(
  rule: RuleSet,
  conditionMap: Map<string, string[]>,
  queries: any[],
  primaryEntity: any
) {
  rule?.hiddenConditions?.forEach((condition: any) => {
    setTime(condition);
    setConditionMap(conditionMap, condition);
    if (condition?.value && condition?.value != '') {
      addHiddenFilter(condition, conditionMap, queries, primaryEntity);
    }
  });
}

export function addHiddenFilter(
  condition: Condition,
  conditionMap: Map<string, string[]>,
  queries: any[],
  primaryEntity: any
) {
  const name = primaryEntity?.name + '.' + condition?.attributeName?.name;
  let valueFlag = false;
  setTime(condition);
  if (condition?.value && condition?.value != '' && condition?.type != 'date') {
    setTextTypeSearchQuery(condition, conditionMap, queries, valueFlag, name);
  } else if (condition?.value && condition?.value != '' && condition?.type == 'date') {
    setDateQuery(queries, condition, name);
  }
}

export function setTextTypeSearchQuery(
  condition: Condition,
  conditionMap: Map<string, string[]>,
  queries: any,
  valueFlag: boolean,
  name: any
) {
  const ind = findIfSearchQueryIsPreviouslyAddedInThisLevel(condition, queries, valueFlag, name);
  if (ind != -1) {
    queries[ind].values = conditionMap.get(condition?.attributeName?.name + condition?.conditionName);
  } else if (!valueFlag) {
    queries.push({
      searchQueryDepth: 1,
      //@ts-ignore
      fieldName: name,
      searchOperatorType: operatorType[condition?.conditionName],
      values: conditionMap?.get(condition?.attributeName?.name + condition?.conditionName),
    });
  }
}

export function findIfSearchQueryIsPreviouslyAddedInThisLevel(
  condition: Condition,
  queries: any,
  valueFlag: boolean,
  name: any
) {
  return queries.findIndex(
    (query: any) =>
      query?.fieldName == name &&
      query?.searchOperatorType == operatorType[condition?.conditionName] &&
      (findValue(query?.values, condition?.value) || (valueFlag = true))
  );
}

export function setDateSearchQueryForHiddenFilters(condition: Condition, operator: any, name: string): any {
  return {
    searchQueryDepth: 1,
    //@ts-ignore
    fieldName: name,
    searchOperatorType: returnDateSearchOperatorType(operator),
    values: returnDateValues(condition, operator),
  };
}

export function setDateQuery(queries: any, condition: Condition, name: string) {
  if (operatorType[condition?.conditionName] != 'NOT_EQUALS') {
    queries.push(setDateSearchQueryForHiddenFilters(condition, operatorType[condition?.conditionName], name));
  } else if (operatorType[condition?.conditionName] == 'NOT_EQUALS') {
    const queryLessThan = setDateSearchQueryForHiddenFilters(condition, 'LT', name);
    const queryGreaterThan = setDateSearchQueryForHiddenFilters(condition, 'GT', name);
    queries.push({
      searchQueryDepth: 0,
      operationType: 'OR',
      queries: [queryLessThan, queryGreaterThan],
    });
  }
}

export function returnDateValues(condition: Condition, operator: string): any[] {
  if (operator == 'IN' || operator == 'EQUALS' || operator == 'CONTAINS') {
    return [condition?.value, condition?.value];
  }
  return [condition?.value];
}

export function returnDateSearchOperatorType(operator: string): string {
  if (operator == 'IN' || operator == 'EQUALS' || operator == 'CONTAINS') {
    return 'RANGE';
  }
  return operator;
}

/* istanbul ignore next */
export function addUserData(value: string) {
  if (
    value === '@firstName' ||
    value === '@lastName' ||
    value === '@email' ||
    value === '@mobileNumber' ||
    value === '@name'
  ) {
    const userData = JSON.parse(localStorage?.getItem('userInfo'));
    const val = value?.split('@')[1];
    value = userData?.[val];
  } else if (value === '@currentDate') {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    value = convertToDateFormat(currentDate);
  }
  return value;
}

function setTime(condition: Condition) {
  if (condition?.value === '@currentDate') {
    condition.type = 'date';
  }
}

export function convertToDateFormat(date: any) {
  const year = date?.getFullYear()?.toString();
  const month = (date?.getMonth() + 1)?.toString();
  const day = date?.getUTCDate()?.toString();
  const full_date = year + '-' + (month?.length == 1 ? '0' + month : month) + '-' + (day.length == 1 ? '0' + day : day);
  return full_date;
}

export function findValue(query: any, value: any) {
  let flag = true;
  query?.forEach((que: any) => {
    if (que == value) {
      flag = false;
    }
  });
  return flag;
}

export function setConditionMap(conditionMap: Map<string, string[]>, condition: any) {
  if (conditionMap.has(condition?.attributeName?.name + condition?.conditionName)) {
    conditionMap.set(condition?.attributeName?.name + condition?.conditionName, [
      ...conditionMap.get(condition?.attributeName?.name + condition?.conditionName),
      condition?.value,
    ]);
  } else {
    conditionMap?.set(condition?.attributeName?.name + condition?.conditionName, [condition?.value]);
  }
}

export type FilterTable = {
  category: string;
  description: string;
  editors: {
    users: string[];
  };
  id: string;
  isFavorite: boolean;
  name: string;
  owner: string;
  userId: number;
  viewers: {
    users: string[];
  };
};

export type ApiResponse<T> = {
  message: string;
  result: T;
  status: number;
};

export function updateHiddenFilterRuleSet(searchQuery: any, hiddenRuleSet: RuleSetCondition, selectedEntityData: any) {
  if (searchQuery?.queries?.length > 0 && searchQuery?.queries.every((query: any) => query?.queries?.length > 0)) {
    searchQuery?.queries.forEach((query: any) => {
      updatePrimaryCondition(query, hiddenRuleSet.ruleSet, selectedEntityData);
    });
    hiddenRuleSet.ruleSetCondition = searchQuery.operationType;
  } else if (searchQuery?.queries?.length > 0) {
    searchQuery?.queries.forEach((query: any) => {
      const conditions: Condition[] = [];
      updateCondition(query, conditions, selectedEntityData);
      hiddenRuleSet.ruleSet.push({
        hiddenConditions: conditions,
        inConditions: 'AND',
      });
      hiddenRuleSet.ruleSetCondition = searchQuery.operationType;
    });
  }
}

function updatePrimaryCondition(query: any, ruleSet: RuleSet[], selectedEntityData: any) {
  ruleSet.push({
    hiddenConditions: updateConditions(query.queries, selectedEntityData),
    inConditions: query.operationType,
  });
}

function updateConditions(queries: any, selectedEntityData: any): Condition[] {
  if (queries.length > 0) {
    const conditions: Condition[] = [];
    queries.forEach((query: any) => {
      updateCondition(query, conditions, selectedEntityData);
    });
    return conditions;
  } else {
    return [];
  }
}

function updateCondition(query: any, conditions: Condition[], selectedEntityData: any) {
  const fieldName = query.fieldName.split('.')[1];
  const attribute = selectedEntityData.nslAttributes.find((attr: any) => attr.name === fieldName);

  if (
    'searchOperatorType' in query &&
    query.searchOperatorType === 'RANGE' &&
    'values' in query &&
    Array.isArray(query.values) &&
    query.values.length === 2
  ) {
    if (
      query.values.every((value: any) => {
        const val = value.toString().split('-');
        return (
          (val?.length == 3 && val?.[0]?.length == 4 && val?.[1]?.length == 2 && val?.[2]?.length == 2) ||
          (val?.length == 1 && val?.[0] == '@currentDate')
        );
      })
    ) {
      conditions.push(addCondition('==', attribute, '@currentDate'));
    } else {
      conditions.push(addCondition(reverseOperator['GTE'], attribute, checkValue(query.values[0])));
      conditions.push(addCondition(reverseOperator['LTE'], attribute, checkValue(query.values[1])));
    }
  } else if (
    'searchQueryDepth' in query &&
    query.searchQueryDepth === 0 &&
    'queries' in query &&
    Array.isArray(query.queries) &&
    query.queries.length === 2
  ) {
    const subFieldName = query.queries[0]?.fieldName.split('.')[1];
    const subAttribute = selectedEntityData.nslAttributes.find((attr: any) => attr.name === subFieldName);
    conditions.push(addCondition('!=', subAttribute, '@currentDate'));
  } else if (
    'searchOperatorType' in query &&
    query.searchOperatorType !== 'RANGE' &&
    'searchQueryDepth' in query &&
    query.searchQueryDepth !== 0
  ) {
    query.values.forEach((value: any) => {
      conditions.push(addCondition(reverseOperator[query.searchOperatorType], attribute, checkValue(value)));
    });
  }
}

// function updateCondition(query: any,conditions: Condition[],selectedEntityData: any) {
//   if (query.searchOperatorType != 'RANGE' && query?.searchQueryDepth != 0) {
//     query.values.forEach((value: any) => {
//       conditions.push(
//         addCondition(
//           reverseOperator[query.searchOperatorType],
//           selectedEntityData.nslAttributes.find(
//             (attribute: any) => attribute.name === query.fieldName.split('.')[1]
//           ),
//           checkValue(value)
//         )
//       );
//     });
//   } else if ('searchOperatorType' in query && query.searchOperatorType == 'RANGE' && 'values' in query && Array.isArray(query.values) && query.values.length == 2 && query.values[0].toString().split('-').length == 3 && query.values[1].toString().split('-').length == 3) {

//     conditions.push(
//       addCondition(
//         '==',
//         selectedEntityData.nslAttributes.find((attribute: any) => attribute.name === query.fieldName.split('.')[1]),
//         '@currentDate'
//       )
//     );
//   } else if ('searchOperatorType' in query && query.searchOperatorType == 'RANGE' && 'values' in query && Array.isArray(query.values) && query.values.length == 2) {
//     conditions.push(
//       addCondition(
//         reverseOperator['GTE'],
//         selectedEntityData.nslAttributes.find((attribute: any) => attribute.name === query.fieldName.split('.')[1]),
//         checkValue(query.values[0])
//       )
//     );
//     conditions.push(
//       addCondition(
//         reverseOperator['LTE'],
//         selectedEntityData.nslAttributes.find((attribute: any) => attribute.name === query.fieldName.split('.')[1]),
//         checkValue(query.values[1])
//       )
//     );
//   } else if ('searchQueryDepth' in query && query.searchQueryDepth == 0 && 'queries' in query && Array.isArray(query.queries) && query.queries.length == 2 ) {
//     conditions.push(
//       addCondition(
//         '!=',
//         selectedEntityData.nslAttributes.find(
//           (attribute: any) => attribute.name === query?.queries?.[0]?.fieldName.split('.')[1]
//         ),
//         '@currentDate'
//       )
//     );
//   }
// }
function addCondition(conditionName: string, attributeName: any, value: string): Condition {
  const condition: Condition = {
    conditionName: conditionName,
    attriubteName: {} as Attribute,
    attributeName: attributeName,
    value: value,
  };
  return condition;
}

function checkValue(value: string): string {
  const userData = JSON.parse(localStorage?.getItem('userInfo'));
  if (value) {
    const val = value.toString().split('-');
    if (val?.[0]?.length == 4 && val?.[1]?.length == 2 && val?.[2]?.length == 2) {
      return '@currentDate';
    } else if (userData?.['firstName'] == value) {
      return '@firstName';
    } else if (userData?.['lastName'] == value) {
      return '@lastName';
    } else if (userData?.['name'] == value) {
      return '@name';
    } else if (userData?.['mobileNumber'] == value) {
      return '@mobileNumber';
    } else if (userData?.['email'] == value) {
      return '@email';
    } else {
      return value;
    }
  } else {
    return value;
  }
}

export type FilterType = 'sharable' | 'admin';

export type Query = {
  fieldName: string;
  searchOperatorType: string;
  searchQueryDepth: number;
  values: string[];
};

export type SearchQuery<T = Query> = {
  searchQueryDepth: number;
  operationType: string;
  queries: T[];
};

export type SearchQueryOrQuery<T = Query> = SearchQuery<T> | Query;

export function iterateSearchQueryToAppendCurrentData<T extends SearchQueryOrQuery<Query>>(searchQuery: T): void {
  if ('queries' in searchQuery && Array.isArray(searchQuery.queries)) {
    setSameLevelSearchQuery(searchQuery);
    for (const subQuery of searchQuery.queries) {
      iterateSearchQueryToAppendCurrentData(subQuery);
    }
  } else {
    if (isQuery(searchQuery)) {
      if ('values' in searchQuery && Array.isArray(searchQuery.values)) {
        for (var i = 0; i < searchQuery.values.length; i++) {
          searchQuery.values[i] = addUserData(searchQuery.values[i]);
        }
      }
    }
  }
}

function setSameLevelSearchQuery(searchQuery: SearchQuery): void {
  const query: Query[] = [];
  const fieldNameSet: Set<string> = new Set();
  for (const subQuery of searchQuery.queries) {
    appendSameLevelQueriesFieldnames(subQuery,fieldNameSet,query);
  }
  searchQuery.queries.splice(0,searchQuery.queries.length);
  searchQuery.queries.push(...query);
}

function appendSameLevelQueriesFieldnames<T extends SearchQueryOrQuery<Query>>(searchQuery: T,fieldNameSet: Set<string>,query: Query[]): void {
  if (isQuery(searchQuery)) {
    if ('fieldName' in searchQuery && 'searchOperatorType' in searchQuery && checkMultiInput(searchQuery.searchOperatorType)) {
      if(fieldNameSet.has(searchQuery.fieldName)) {
        const ind = query.findIndex((_:Query) => _.fieldName === searchQuery.fieldName && checkMultiInput(_.searchOperatorType));
        if(ind != -1) {
          query[ind].values.push(...searchQuery.values);
        } else {
          query.push(searchQuery);
        }
      } else {
        query.push(searchQuery);
        fieldNameSet.add(searchQuery.fieldName);
      }
    } else {
      // @ts-ignore
      if (searchQuery.values.length > 0 && !notMultiInput(searchQuery.searchOperatorType) ) {
        for (let i = 0; i < searchQuery.values.length; i++) {
          query.push({
            fieldName: searchQuery.fieldName,
            searchOperatorType: searchQuery.searchOperatorType,
            searchQueryDepth: searchQuery.searchQueryDepth,
            values: [searchQuery.values[i]],
          });
        }
      } else {
        query.push(searchQuery);
      }
    }
  } else {
    // @ts-ignore
    query.push(searchQuery);
  }
}

function notMultiInput(operator:string): boolean {
  return operator == 'IN' || operator == 'RANGE' || operator == 'GEO_DISTANCE'
}

function checkMultiInput(operator: string): boolean {
  return operator === 'IN' 
}

const isQuery = (arg: any): arg is Query => {
  return (
    arg &&
    typeof arg.fieldName === 'string' &&
    typeof arg.searchOperatorType === 'string' &&
    typeof arg.searchQueryDepth === 'number' &&
    Array.isArray(arg.values)
  );
};

export type RelatedRecordPayload = {
  relatedRecordsFilters: RelatedRecordsFilter[];
};

export type RelatedRecordsFilter = {
  attributeName: string;
  attributeValue: string;
};

export type KanbanConfiguration = {
  statusAttribute: DraggableAttribute[];
  kanbanGsis: { [key: number]: GsiButton };
  kanbanStatus: any;
  allAttributes: any;
};
export interface ColumnStyle {
  sizes: number[];
  colors: string[];
  fontSizes: number[];
  Weight: string[];
  fontStyles: string[];
  fontFamily: any[];
  displayName: string;
  attributes: string;
  attributeList: string[];
  seperators: string[];
  isChip: boolean[];
}
