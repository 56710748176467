import { Injectable, Injector } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/internal/operators/pluck';
import { Router } from '@angular/router';
import { CduiBaseService } from './../cdui-base/cdui-base.service';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class NewLayoutSolutionEndpointService extends CduiBaseService {
  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    public injector: Injector
  ) {
    super(injector);
  }

  // getAllBooks(pagenum: number, pageSize: number, searchText: string) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl +
  //         '/CU/books?pageNo=' +
  //         pagenum +
  //         '&pageSize=' +
  //         pageSize +
  //         '&searchCriteria=' +
  //         searchText
  //     )
  //     .pipe(pluck('result', 'content'));
  // }

  getAllBooksWithDesignData(pagenum: number, pageSize: number, searchText: string, approvalStatus?: string) {
    if (approvalStatus) {
      return this.http
        .get(
          `${this.api?.getBooksWithDesignData}pageNo=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchText}&approvalStatuses=${approvalStatus}`
        )
        .pipe(pluck('result'));
    } else {
      return this.http
        .get(`${this.api?.getBooksWithDesignData}pageNo=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchText}`)
        .pipe(pluck('result'));
    }
  }

  getAllChapters() {
    return of([
      {
        label: 'Select Chapter',
        name: 'Select Chapter',
        id: 2233,
        parentId: 1,
        childCount: 1,
      },
    ]);
  }

  storeBookData(bookData: any) {
    return this.http.post(this.api?.uiControls, bookData);
  }

  storeChangeUnitData(bookData: any) {
    return this.http.post(this.api?.uiControls, bookData);
  }

  fetchBookData(bookId: any) {
    return this.http.get(`${this.api?.uiControls}?cuId=${bookId}&cuType=CHANGEUNIT`).pipe(pluck('result'));
  }

  saveDesign(bookData: any, flag?: any) {
    /* istanbul ignore next */
    if (flag) {
      return this.http.post(this.api?.storeBookData + `?isCopy=true`, bookData).pipe(pluck('result'));
    }
    return this.http.post(this.api?.storeBookData, bookData).pipe(pluck('result'));
  }

  saveCardDesign(bookData: any) {
    return this.http.post(this.api?.storeBookData, bookData).pipe(pluck('result'));
  }

  getIndustries() {
    return this.http.get(this.api?.getIndustries);
  }
  getColors(limit: number, bgPage: number) {
    return this.http.get(`${this.api?.getColors}limit=${limit}&page=${bgPage}`);
  }

  getWallpapers(bgsLimit: number, bgPage: number) {
    return this.http.get(`${this.api?.getWallpapers}limit=${bgsLimit}&page=${bgPage}`);
  }
  getBookDetails(bookId: any, platform?: string) {
    return this.http.get(`${this.api?.getBookDetails}${bookId}` + `?device=${platform}`);
  }
  getGsisInBook(bookId: any, platform?: string) {
    return this.http.get(`${this.api?.getGsiDetails}${bookId}` + `?device=${platform}`);
  }
  getCusInBook(bookId: any, gsiIds: any, platform?: string) {
    return this.http.post(`${this.api?.getCuDetails}${bookId}` + `?device=${platform}`, gsiIds);
  }
  getEntitiesInBook(bookId: any, cuIds: any, platform?: string) {
    return this.http.post(`${this.api?.getEntityDetails}${bookId}` + `?device=${platform}`, cuIds);
  }

  ////////////Fetching Indusry List////////////

  getBookCovers(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getAllBooks}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getAllBooks}limit=${limit}&page=${page + 1}`);
  }

  getCardCovers(ontologyId: any, limit: any, page: any, layout: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getAllBooks}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getAllBooks}limit=${limit}&page=${page + 1}&layout=${layout}`);
  }

  getGsiIcons(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getSolutionIcons}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getSolutionIcons}limit=${limit}&page=${page}`);
  }

  getEntityIcons(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getEntityIcons}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getEntityIcons}limit=${limit}&page=${page}`);
  }
  getCuIcons(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getCuIcons}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getCuIcons}limit=${limit}&page=${page}`);
  }

  getAllLayoutDesigns(bookId: any) {
    return this.http.get(`${this.api?.uiControls}?cuId=${bookId}&cuType=CHANGEUNIT`).pipe(pluck('result'));
  }

  updateUserData(userId: any, body: any) {
    return this.http.post(`${this.api?.updateUser}type=graph&userId=${userId}`, body).pipe(pluck('result'));
  }
  getEntitiesinCU(cuId: any) {
    return this.http.get(`${this.api?.getBasicCu}/${cuId}`);
  }
  uploadData(data: any) {
    /* istanbul ignore next */
    if (data) {
      return this.http.post(this.api?.singleFileUpload, data);
    }
  }

  resetDesign(platform: any, betLevel: any, masterId: any) {
    if (betLevel == 'BOOK') {
      return this.http.delete(`${this.api?.storeBookData}/${masterId}?device=${platform}`);
    }
  }
}
