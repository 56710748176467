import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  HostListener,
  ViewEncapsulation,
  Injector,
  AfterViewInit,
} from '@angular/core';
import { TransactionViewComponent } from '../transaction-view.component';

@Component({
  selector: 'app-cupopup-transaction-view',
  templateUrl: './cupopup-transaction-view.component.html',
  styleUrls: ['./cupopup-transaction-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CupopupTransactionViewComponent extends TransactionViewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() transactionCU: any;
  @Input() gsi: any;
  @Input() selectedCu: any;
  @Input() mode: string;
  @Input() txnData: any;
  @Input() bgStyleContent: any;
  @Input() solutionInfo: any;
  @Output() inividualGsiData: any = new EventEmitter();
  @Output() setInividualGsiData: any = new EventEmitter();
  @Input() isReadOnly: any;
  @Input() isDebugMode: boolean;
  @Input() isCarnival: boolean;
  @Input() isOnboarding: boolean;
  @Input() layoutConfigurations: any;
  @Input() isTransactionActive: boolean;
  @Input() gsiDesign: any;
  @Input() gsiPopup: boolean = false;
  
  @Output() getNextCUEmitter = new EventEmitter();
  @Output() formValueChange = new EventEmitter();
  @Output() tempEntityListEmit = new EventEmitter();
  @Output() tempInfoEntityListEmit = new EventEmitter();
  @ViewChild('thankumodal') thankumodal: TemplateRef<any>;
  @ViewChild('cuMatTab', { static: false }) cuMatTab: any;
  allowFullscreen :boolean =false;
  constructor(private injector: Injector) {
    super(injector);
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth < 767;
    this.isIpadPatroit = this.screenWidth < 991;
    this.isIpadLandscape = this.screenWidth < 1199;
  }
  ngOnInit(): void {
    /* istanbul ignore next */
    super.ngOnInit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore next */
    super.ngOnChanges(changes);
  }
  ngAfterViewInit(): void {
    // document.body.classList.add('nh-overflow-hidden');
    document.body.classList.add('mccGsiModal_cupopup');
    let elem = document.getElementById('layoutCupopupnhCard');
    if (elem.scrollHeight > elem.clientHeight) {
      document.getElementById('layoutCupopup').classList.add('layoutCupopup--isScrolled');
    } else {
      document.getElementById('layoutCupopup').classList.remove('layoutCupopup--isScrolled');
    }
    setTimeout(function () {
      document.getElementById('layoutCupopup').classList.add('layoutCupopup--isVisible');
    }, 500);
  }
  ngOnDestroy(): void {
    /* istanbul ignore next */
    super.ngOnDestroy();
    // document.body.classList.remove('nh-overflow-hidden');
    document.body.classList.remove('mccGsiModal_cupopup');
  }
}

