import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { BPMNViewModule } from '../bpmn-view/bpmn-view.module';
import { MaterialModule } from '../materail/material.module';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';

import { ExtractionComponentDialog } from './extraction.component';

@NgModule({
  declarations: [ExtractionComponentDialog],
  imports: [
    BPMNViewModule, 
    MaterialModule,
     MatStepperModule,
      ApplicationPipesModule],
  exports: [ExtractionComponentDialog],
})
export class ExtractionComponentDialogModule {}
