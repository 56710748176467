import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-entity-grid-card-paginator',
  templateUrl: './entity-grid-card-paginator.component.html',
  styleUrls: ['./entity-grid-card-paginator.component.scss'],
})
export class EntityGridCardPaginatorComponent implements OnInit {
  maxPages: number = 0;
  paginationHovered: boolean = false;
  tenantName: string;
  @Input() index: any = 1;
  @Input() length: any;
  @Input() pageSize: number = 15;
  @Input() rulerLength: number = 3;
  @Input() paginationJustify: any;
  @Input() paginationStyle: any;

  @Output() page: any = new EventEmitter();
  @Output() pageSizeEmit: any = new EventEmitter();
  pageSizeOptions: number[];
  justifyContent: any = 'flex-end';
  flexDirection: string = 'row';
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.maxPages = Math.ceil(this.length / this.pageSize);
    if (this.maxPages < this.rulerLength) {
      this.rulerLength = this.maxPages;
    }
    if (changes?.pageSize) {
      this.updatePageSizeOptionsArray();
    }
    if (this.paginationJustify?.viewClass) {
      this.justifyContent = this.paginationJustify?.viewClass == 'flex-start' ? 'flex-start' : this.paginationJustify?.viewClass == 'center' ? 'center' : 'flex-end';
      this.flexDirection = this.paginationJustify?.viewClass == 'flex-start' ? 'row-reverse' : '';
    }
  }

  private updatePageSizeOptionsArray() {
    this.pageSizeOptions = [this.pageSize, this.pageSize * 2, this.pageSize * 3];
    let checkedOptions: Set<number> = new Set();
    checkedOptions.add(this.pageSize);
    checkedOptions.add(this.pageSize * 2);
    checkedOptions.add(this.pageSize * 3);
    this.addOptionsToPageSize(10, checkedOptions);
    this.addOptionsToPageSize(25, checkedOptions);
    this.addOptionsToPageSize(50, checkedOptions);
    this.addOptionsToPageSize(100, checkedOptions);
    this.pageSizeOptions.sort((a: number, b: number) => a - b);
  }

  private addOptionsToPageSize(value: number, checkedOptions: Set<number>) {
    if (!checkedOptions.has(value)) {
      this.pageSizeOptions.push(value);
    }
  }
  ngOnInit(): void {
    this.tenantName = localStorage.getItem('TenantName');
  }

  get pagination(): NumberedPagination {
    const { index, maxPages, rulerLength } = this;
    const pages = ruler(index, maxPages, rulerLength);
    return { index, maxPages, pages } as NumberedPagination;
  }

  navigateToPage(pageNumber: number): void {
    if (allowNavigation(pageNumber, this.index, this.maxPages)) {
      this.index = pageNumber ? pageNumber : this.index;
      this.page.emit(this.index - 1);
    }
  }

  changePageSize(event: any) {
    this.pageSize = event.value;
    this.pageSizeEmit.emit(this.pageSize);
  }

  trackByFn(index: number): number {
    return index;
  }
}

const ruler = (currentIndex: number, maxPages: number, rulerLength: number): number[] => {
  const array = new Array(rulerLength).fill(null);
  const min = Math.floor(rulerLength / 2);

  return array.map((_, index) => rulerFactory(currentIndex, index, min, maxPages, rulerLength));
};

const rulerOption = (currentIndex: number, min: number, maxPages: number): RulerFactoryOption => {
  if (currentIndex <= min) return RulerFactoryOption.Start;
  else if (currentIndex >= maxPages - min) return RulerFactoryOption.End;
  else return RulerFactoryOption.Default;
};

const rulerFactory = (
  currentIndex: number,
  index: number,
  min: number,
  maxPages: number,
  rulerLength: number
): number => {
  const factory = {
    [RulerFactoryOption.Start]: () => index + 1,
    [RulerFactoryOption.End]: () => maxPages - rulerLength + index + 1,
    [RulerFactoryOption.Default]: () => currentIndex + index - min,
  };

  return factory[rulerOption(currentIndex, min, maxPages)]();
};

const allowNavigation = (pageNumber: number, index: number, maxPages: number): boolean => {
  return pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
};
export enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT',
}
export interface NumberedPagination {
  index: number;
  maxPages: number;
  pages: number[];
}
