import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'useHttpImgSrc',
})
export class UseHttpImageSourcePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(url: any): Observable<SafeUrl> {
    /* istanbul ignore next */
    if (url?.includes('dsd-files-store')) {
      return this.http
        .get(url, { responseType: 'blob' })
        .pipe(map((val: any) => this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val))));
    } else {
      return of(url);
    }
  }
}
