import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { A2aFacadeService, correctDsdFileUrl } from '@common-services';

import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-redac-mapping',
  templateUrl: './redac-mapping.component.html',
  styleUrls: ['./redac-mapping.component.scss'],
})
export class RedacMappingComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollElement', { static: false }) scrollElement: ElementRef;
  @Output() onClickBox: EventEmitter<any> = new EventEmitter();
  @Output() manualEdit: EventEmitter<any> = new EventEmitter();
  @Input() coordinates: any = [];
  @Input() newcoordinates: any = [];
  @Input() src: string = '';
  @Input() originalDocumentSize: any = {};

  @ViewChild('imagemapping') imagecoordinates: ElementRef;
  private unsubscribe: Subject<any> = new Subject<any>();
  coordinateData: any;
  imagePosition: any = {};
  originalDocumentSizeData: any;
  domHeight: any;
  domWidth: any;
  p: any;
  startX: any;
  startY: any;
  endX: any;
  endY: any;
  pageNumber: number = 1;
  totalPages: number;
  fileType: any;
  expanded: any;
  scrollPosition: number;
  coordinatesByPAgeNumber: any;
  receivedData: boolean;

  attributeId: any;
  attributeName: string;
  entityName: string;
  flag: boolean;
  dragClickStarted: any;
  newcoordinatesByPAgeNumber: any;
  type: string;
  preview: any = false;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private a2afacade: A2aFacadeService,
    public dialog: MatDialog
  ) {
    /*istanbul ignore next*/
    this.src = data?.src ? data.src : '';
    /*istanbul ignore next*/
    this.originalDocumentSize = data?.originalDocumentSize
      ? data?.originalDocumentSize
      : {};
    /*istanbul ignore next*/
    this.coordinates = data?.coordinates ? data?.coordinates : [];
    this.fileType = data?.fileType;
    this.expanded = data?.expanded;
    /*istanbul ignore next*/
    this.coordinatesByPAgeNumber = data?.coordinates ? data?.coordinates : [];
    this.attributeId = data?.attributeId;
    this.entityName = data?.entityName;
    this.attributeName = data?.attributeName;
    /*istanbul ignore next*/
    this.preview = data?.preview ? data?.preview : false;
    this.src = correctDsdFileUrl(this.src);
  }

  ngOnInit(): void {
    this.originalDocumentSizeData = this.originalDocumentSize;
    //this.coordinateData = this.coordinates;
  }
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf?.numPages;
    this.coordinatesByPAgeNumber = [];
    for (let ele of this.coordinates) {
      /* istanbul ignore else */
      if (ele?.page_no == this.pageNumber) {
        this.coordinatesByPAgeNumber.push(ele);
      }
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: any) {
    /*istanbul ignore next*/
    this.scrollPosition = (event.target as Element)?.scrollTop;
    /*istanbul ignore else*/
    if (this.flag && this.scrollElement?.nativeElement) {
      this.scrollElement.nativeElement.scrollTo(0, 0);
      this.flag = false;
    }
  }

  changePage(event: any) {
    this.pageNumber = event?.pageIndex + 1;
    this.coordinatesByPAgeNumber = [];
    for (let ele of this.coordinates) {
      /* istanbul ignore else */
      if (ele?.page_no == this.pageNumber) {
        this.coordinatesByPAgeNumber.push(ele);
      }
    }
  }

  pageChange(pageData: any) {
    this.pageNumber = pageData;
  }

  pageRendered(e: any) {
    /* istanbul ignore next */
    this.domHeight = e?.source?.height;
    /* istanbul ignore next */
    this.domWidth = e?.source?.width;
  }

  getActualX(event: any) {
    let val = 10.5892;
    var path = event.path || (event.composedPath && event.composedPath());
    /*istanbul ignore next*/
    let windowX = (event?.view?.innerWidth - path[0]?.clientWidth) / 2;
    // let windowX=(event.view.innerWidth/val) + 24;
    let actualX = event?.clientX - windowX;
    return actualX;
  }
  getActualY(event: any) {
    let val = 24;
    /*istanbul ignore next*/
    let actualY = event?.clientY - val;
    return actualY;
  }

  undoBox(index: any) {
    /*istanbul ignore next*/
    if (this.fileType == 'pdf') {
      /*istanbul ignore next*/
      this.coordinates.forEach((ele: any, ind: any) => {
        if (
          ele?.page_no == this.pageNumber &&
          ele?.height == this.coordinatesByPAgeNumber[index]?.height &&
          ele?.width == this.coordinatesByPAgeNumber[index]?.width &&
          ele?.xmin == this.coordinatesByPAgeNumber[index]?.xmin &&
          ele?.xmax == this.coordinatesByPAgeNumber[index]?.xmax &&
          ele?.ymin == this.coordinatesByPAgeNumber[index]?.ymin &&
          ele?.ymax == this.coordinatesByPAgeNumber[index]?.ymax
        ) {
          /*istanbul ignore next*/
          this.coordinates.splice(ind, 1);
        }
      });
      /*istanbul ignore next*/
      this.coordinatesByPAgeNumber.splice(index, 1);
    } else {
      /*istanbul ignore next*/
      this.coordinatesByPAgeNumber.splice(index, 1);
    }
  }

  getCoordinateStyle(coordinate: any): object {
    if (this.fileType === 'image' && this.imagecoordinates) {
      /* istanbul ignore next */
      this.domHeight = this.imagecoordinates?.nativeElement?.height;
      /* istanbul ignore next */
      this.domWidth = this.imagecoordinates?.nativeElement?.width;
    }
    // console.log('scrollpos',this.scrollPosition)

    const x =
      (this.domWidth / this.originalDocumentSize?.width) * coordinate?.xmin;

    const y = this.scrollPosition
      ? (this.domHeight / this.originalDocumentSize?.height) *
          coordinate?.ymin -
        this.scrollPosition
      : (this.domHeight / this.originalDocumentSize?.height) * coordinate?.ymin;

    const height =
      coordinate?.height * (this.domHeight / this.originalDocumentSize?.height);
    const width =
      coordinate?.width * (this.domWidth / this.originalDocumentSize?.width);

    return {
      height: `${height}px`,
      width: `${width}px`,
      left: `${x}px`,
      top: `${y}px`,
    };
  }

  @HostListener('window:pointerdown', ['$event'])
  startDrag(event: MouseEvent) {
    this.dragClickStarted = true;
    this.startX = this.getActualX(event);
    this.startY = this.getActualY(event);
  }

  @HostListener('window:pointermove', ['$event'])
  moveDrag(e: MouseEvent) {
    if (this.dragClickStarted) {
      this.type = 'move';
      this.createManualBoxCoordinates(e, 'move');
      console.log(e);
    }
  }

  @HostListener('window:pointerup', ['$event'])
  stopDrag(event: MouseEvent) {
    this.type = 'stop';
    this.dragClickStarted = false;
    console.log('insideDrag', this.scrollPosition);
    this.createManualBoxCoordinates(event, 'stop');
  }
  createManualBoxCoordinates(event: any, type: string) {
    //console.log('insideDrag', this.scrollPosition);
    this.endX = event?.clientX;
    this.endY = event?.clientY;
    /*istanbul ignore next*/
    let data = {
      height: Math.round(this.getActualY(event) - this.startY),
      page_no: this.imagecoordinates ? 0 : this.pageNumber,
      width: Math.round(this.getActualX(event) - this.startX),
      ymax: this.scrollPosition
        ? Math.round(this.getActualY(event)) + this.scrollPosition
        : Math.round(this.getActualY(event)),
      xmin: Math.round(this.startX),
      xmax: Math.round(this.getActualX(event)),
      ymin: this.scrollPosition
        ? Math.round(this.startY) + this.scrollPosition
        : Math.round(this.startY),
      exist: true,
    };
    let checkFlag: boolean = data?.height > 5 && data?.width > 5;
    let ratioW = this.originalDocumentSize?.width / this.domWidth;
    let ratioH = this.originalDocumentSize?.height / this.domHeight;
    data.xmax = Math.round(ratioW * data?.xmax);
    data.xmin = Math.round(ratioW * data?.xmin);
    data.ymax = Math.round(ratioH * data?.ymax);
    data.ymin = Math.round(ratioH * data?.ymin);
    data.height = Math.round(ratioH * data?.height);
    data.width = Math.round(ratioW * data?.width);

    if (checkFlag) {
      this.newcoordinatesByPAgeNumber = this.coordinatesByPAgeNumber;
      if (type == 'stop') {
        if (this.fileType == 'pdf') {
          this.coordinates.push(data);
          this.coordinatesByPAgeNumber.push(data);
        }
        if (this.fileType == 'image') {
          this.coordinatesByPAgeNumber.push(data);
        }
      } else if (type == 'move') {
        this.newcoordinatesByPAgeNumber = [];
        if (this.fileType == 'pdf') {
          this.newcoordinatesByPAgeNumber.push(data);
        }
        if (this.fileType == 'image') {
          this.newcoordinatesByPAgeNumber.push(data);
        }
      }
    }
  }
  closePopUp() {
    for (let ind = 0; ind < this.coordinates.length; ind++) {
      if (this.coordinates[ind].exist) {
        /*istanbul ignore next*/
        this.coordinates.splice(ind, 1);
        ind = ind - 1;
      }
    }
    for (let ind = 0; ind < this.coordinatesByPAgeNumber.length; ind++) {
      if (this.coordinatesByPAgeNumber[ind].exist) {
        /*istanbul ignore next*/
        this.coordinatesByPAgeNumber.splice(ind, 1);
        ind = ind - 1;
      }
    }
    /*istanbul ignore next*/
    this.dialog.closeAll();
  }

  onSubmit() {
    let mimeType;
    mimeType = `application/${this.fileType}`;

    if (this.fileType == 'image') {
      mimeType = 'image/png';
    }

    let payLoad = {
      file_link: this.src,
      ref_dimensions: {
        height: this.data?.originalDocumentSize?.height,
        width: this.data?.originalDocumentSize?.width,
      },
      redacted_boxes: this?.coordinates,
      mimeType: mimeType,
    };
    if (this.data?.dicomfile) {
      // redact mapping came from through dicom
      payLoad.file_link = this.data?.dicomfile;
    }
    /*istanbul ignore next*/
    this.a2afacade
      .redactData(payLoad)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          /*istanbul ignore next*/
          console.log(res);
          this.src = res?.redacted_file;
          /*istanbul ignore next*/
          this.pageNumber = 1;
          res.redacted_boxes = this?.coordinates;
          res.attributeId = this.attributeId;
          res.attributeName = this.attributeName;
          res.entityName = this.entityName;
          res.type = 'redacted';
          res.ref_dimensions = {
            height: this.data?.originalDocumentSize?.height,
            width: this.data?.originalDocumentSize?.width,
          };
          /*istanbul ignore next*/
          this.a2afacade.onSubmitRedact.next(res);
          /*istanbul ignore next*/
          this.dialog.closeAll();
          //this.group.controls[this.field.attribute.name].setValue(res.redacted_file);
        }
      });
  }

  onRightClick(event: any) {
    event.preventDefault();
  }

  ngAfterViewInit(): void {
    this.flag = true;
  }
}
