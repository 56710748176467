import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Injector,
  ViewChild,
  ElementRef,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


import { AnimationOptions } from 'ngx-lottie';
import { LoaderService, TenantCustomUiFacadeService, TranslatorService } from '@common-services';
import { CduiBaseComponent } from '../cdui-base/cdui-base.component';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent extends CduiBaseComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;
  @Input() message: string | undefined = '';
  ngUnsubscribe = new Subject();
  loaderData: any = [];
  labels: any;
  lottieOptions: AnimationOptions;
  blurToggle: boolean = false;
  isCustomLoader: boolean = false;
  loaderContentUrl: string = undefined;
  tenantName: any;
  path: string;
  // count =0;
  constructor(
    private injector: Injector,
    private loaderService: LoaderService,
    private translator: TranslatorService,
    private el: ElementRef,
    private tenantCustomUiFacadeService: TenantCustomUiFacadeService
  ) {
    super(injector);
    this.detectLanguageChange();
    this.detectChange();
    this.detectBlurStatus();
    let staticPath = '../../../assets/json/loader.json';
    /*istanbul ignore next */
    if (this.environment.cdnUrl) {
      let staticUrl = staticPath.split('assets');
      this.path = this.environment.cdnUrl + '/assets' + staticUrl[1];
    } else {
      this.path = staticPath;
    }
    this.getLoader(this.path);
  }

  getLoader(path: string) {
    /*istanbul ignore next*/
    this.loaderService.getLoader(path).subscribe((result: any) => {
      this.lottieOptions = { animationData: result };
    });
  }

  ngOnInit() {
    this.detectLoaderChanges();
    this.tenantCustomUiFacadeService.getLoaderData();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectChange() {
    this.loaderService.loader$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      const { showLoader, message }: any = res;
      if (showLoader === true) {
        // this.count++;
        this.loaderData.push(showLoader);
      } else {
        // if(this.count>0){
        //   this.count--;
        // }
        this.loaderData.pop();
      }
      // console.warn(this.count);
      // console.trace();
      this.isLoading = this.loaderData.length > 0 ? true : false;
      this.message = message;
    });
  }
  detectBlurStatus() {
    this.loaderService.blur$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res.blur === true) {
        this.blurToggle = true;
      } else {
        this.blurToggle = false;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  detectLoaderChanges() {
    this.tenantCustomUiFacadeService.detectLoaderChanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res && res != undefined) {
        this.isCustomLoader = true;
        this.loaderContentUrl = res;
        this.detectCustomLoader();
      } else {
        this.isCustomLoader = false;
        /*istanbul ignore else*/
        if (this.el.nativeElement.querySelector('.custom-loader-ref')) {
          this.el.nativeElement.querySelector('.custom-loader-ref').innerHTML = ``;
        }
      }
    });
  }
  detectCustomLoader() {
    /*istanbul ignore else*/
    if (this.el.nativeElement.querySelector('.custom-loader-ref')) {
      this.el.nativeElement.querySelector('.custom-loader-ref').innerHTML = this.loaderContentUrl;
    } else {
      this.isCustomLoader = false;
    }
  }
}
