import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from './../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  loaderSubject = new Subject();
  loader$ = this.loaderSubject.asObservable();

  blurSubject = new Subject();
  blur$ = this.blurSubject.asObservable();

  show(message?: string, blur?: boolean) {
    let isBlur = blur ? blur : true;
    this.blurSubject.next({ blur: isBlur });
    this.loaderSubject.next({ showLoader: true, message: message });
    setTimeout(() => {
      this.blurSubject.next({ blur: false });
    }, 1000);
  }
  hide() {
    this.loaderSubject.next({ showLoader: false });
    this.blurSubject.next({ blur: false });
  }
  fetchAllLoadersAPI(pageno: number, limit: number): any {
    return this.http.get(`${this.api.loader}/fetch/all?limit=${limit}&page=${pageno}`);
  }
  saveLoader(payload: any) {
    return this.http.post(this.api.loaderSave, payload);
  }

  getLoaderById(id: any) {
    return this.http.get(`${this.api.loader}/fetch/${id}`);
  }
  publishLoader(payload: any) {
    return this.http.post(this.api.loaderSave, payload);
  }

  updateLoader(payload: any) {
    return this.http.post(this.api.loaderSave, payload);
  }
  deleteCurrentLoader(id: any) {
    return this.http.delete(`${this.api.loader}/delete/${id}`, id);
  }
  getLoader(path: string) {
    return this.http.get(path);
  }
}
