import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService,AlertService } from '@common-services';
import { ManagePersonalConnectionsEndpointService } from './manage-personal-connections-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class ManagePersonalConnectionsFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  public transmitDataToEditSubject = new BehaviorSubject('');
  transmitDataToEditSubject$ = this.transmitDataToEditSubject.asObservable();

  constructor(
    private loader: LoaderService,
    private alertService: AlertService,
    private personalConnectionEndpointService: ManagePersonalConnectionsEndpointService
  ) {}
  createConnection(type: any, data: any) {
    return this.personalConnectionEndpointService.createConnection(type, data);
  }

  getPersonalConnection(type: any) {
    return this.personalConnectionEndpointService.getPersonalConnection(type);
  }
  getPersonalConnections() {
    return this.personalConnectionEndpointService.getPersonalConnections();
  }
  oauthPersonalConnection(type: any) {
    return this.personalConnectionEndpointService.oauthPersonalConnection(type);
  }
  saveConnection(code: any, state: any) {
    return this.personalConnectionEndpointService.saveConnection(code, state);
  }
  transmitDataToEdit(element: any) {
    this.transmitDataToEditSubject.next(element);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
