<form
  novalidate
  [formGroup]="group"
  [ngClass]="foundObject?.event == 'Option 7' ? 'date-parent-class-styles' : ''"
>
  <!-- <label *ngIf="!field?.hideLabels">{{ field?.label }}:</label> -->
  <div class="d-flex justify-content-between nh-column-gap-10">
    <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
      <label
        class="main-label nh-mb-0"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="
          !field?.hideLabels &&
          !field?.configuration?.hideLabel &&
          !field?.hideLabelMultiAttr
        "
        ><span class="main-label-inner"
          ><span class="main-label-text text-truncate"
            >{{ field?.label }} <span class="main-label-colon">:</span></span
          >
          <span
            *ngIf="field?.isRequired && !field?.isInfo"
            class="mat-form-field-required-marker"
            >*</span
          ></span
        ></label
      >
      <p
        class="nh-ml-6 d-flex main-label-helperIcon"
        *ngIf="field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        <mat-icon
          class="material-icons-outlined helperText-infoIcon"
          [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
          matTooltip="{{ field?.configuration?.helpertext }}"
          matTooltipPosition="above"
          *ngIf="!field?.hideLabels"
          >info_outline</mat-icon
        >
      </p>
    </div>
    <div
      class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10"
      *ngIf="
        field.isMulti &&
        !field.configuration?.hideMultiValueIcon &&
        field.type !== 'yearmonth' &&
        !(field?.attribute)['isTableConfig']
      "
    >
      <mat-icon
        *ngIf="
          field.isMulti &&
          !field.configuration?.hideMultiValueIcon &&
          !(field?.attribute)['isTableConfig']
        "
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        id="dateAddAttr_{{ field.attribute.name }}"
        (click)="addAttribute.next()"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="
          field.isMulti &&
          !field.configuration?.hideMultiValueIcon &&
          !(field?.attribute)['isTableConfig']
        "
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        id="dateAddAttr_{{ field.attribute.name }}"
        (click)="removeAttribute.next()"
        >remove</mat-icon
      >
    </div>
  </div>
  <p
    class="top-text"
    *ngIf="
      field?.configuration?.helperTextPosition == 'top' &&
      !field?.configuration?.showHelperTextIcon
    "
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
  <div
    class="datetime-picker-wrapper"
    [ngClass]="{
      inValidClass:
        !field?.isInfo &&
        (group?.controls)[field?.attribute?.name]?.invalid &&
        (group?.controls)[field?.attribute?.name]?.touched
    }"
  >
    <ng-container *ngIf="field.type === 'currentdatetime'; else newdatetime">
      <p-calendar
        #dateFormatter
        *ngIf="!field?.readonly"
        class="ui-custom-calendar ui-custom-datetime"
        [placeholder]="this?.requiredDateFormat + ' ' + this?.format"
        [disabled]="isDisabled"
        [showTime]="requiredTimeFormat == '24-hr'"
        formControlName="{{ field.attribute.name }}"
        [required]="field?.isRequired && !field?.isInfo"
        (click)="dateClicked($event)"
        [(ngModel)]="field.value"
        [dateFormat]="requiredFormatPrime"
        [hourFormat]="requiredTimeFormat == '24-hr' ? 24 : 12"
        (onInput)="inputChange($event)"
        [showSeconds]="!isTimeHidden"
        [showOnFocus]="false"
        (selectedDateTimeChange)="updateTimeFormat($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        (document:click)="closeFix($event)"
      ></p-calendar>
      <p-calendar
        #dateFormatter
        *ngIf="field?.readonly"
        class="ui-custom-calendar ui-custom-datetime"
        [placeholder]="this?.requiredDateFormat + ' ' + this?.format"
        [disabled]="isDisabled"
        [(ngModel)]="field.value"
        formControlName="{{ field.attribute.name }}"
        [dateFormat]="requiredFormatPrime"
        [hourFormat]="requiredTimeFormat == '24-hr' ? 24 : 12"
        [showSeconds]="!isTimeHidden"
        (selectedDateTimeChange)="updateTimeFormat($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [showTime]="requiredTimeFormat === '24-hr'"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
      ></p-calendar>
      <em class="nh-icon nh-icon-calender datetime-icon"></em>
    </ng-container>
    <ng-template #newdatetime>
      <div
        *ngIf="
          !(
            (this.field?.attribute)['isTableConfig'] &&
            (this.field?.attribute)['tableUiStyle']
          )
        "
      >
        <p-calendar
          class="{{ appliedClass }}"
          panelStyleClass="datetime-datepicker-calendar"
          [placeholder]="getDateTimePlaceHolder"
          [disabled]="field?.readonly"
          [(ngModel)]="field.value"
          formControlName="{{ field.attribute.name }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (onShow)="dateClicked($event)"
          [required]="field?.isRequired && !field?.isInfo"
          (onSelect)="registerOnChange($event)"
          (onClose)="calendarDateClicked = true; onBlur($event)"
          (onBlur)="!calendarDateClicked && onBlur($event)"
          [showOnFocus]="false"
          [dateFormat]="requiredFormatPrime"
          icon="nh-icon nh-icon-calender datetime-icon"
          [showIcon]="true"
          [showTime]="!isTimeHidden"
          [showSeconds]="!isTimeHidden"
          [yearNavigator]="false"
          [monthNavigator]="true"
          [hourFormat]="requiredTimeFormat == '24-hr' ? 24 : 12"
          [hideOnDateTimeSelect]="false"
          id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
          [touchUI]="true"
          (onInput)="inputChange($event)"
          [showButtonBar]="foundObject?.event == 'Option 3' ? true : false"
          [appendTo]="bodyAppend"
          #primecalendar
        >
          <ng-template pTemplate="footer">
            <div class="datetime-calendar-footer">
              <button
                type="button"
                class="p-calendar-footer-now"
                (click)="nowClicked(); primecalendar.hideOverlay()"
              >
                {{ labels?.Now || "Now" }}
              </button>
              <div *ngIf="field?.value">
                <!-- <button
                  type="button"
                  id="p_calendar_done"
                  class="p-calendar-footer-done"
                  (click)="primecalendar.hideOverlay()"
                ></button> -->
                <button
                  type="button"
                  class="p-calendar-footer-reset"
                  (click)="primecalendar.hideOverlay(); clearCalendar()"
                  id="p_calendar_reset"
                ></button>
              </div>
              <div *ngIf="!field?.value">
                <button
                  type="button"
                  class="p-calendar-footer-close"
                  id="p_calendar_close"
                  (click)="primecalendar.hideOverlay()"
                ></button>
              </div>
            </div>
          </ng-template>
        </p-calendar>
      </div>
      <div
        *ngIf="
          (this.field?.attribute)['isTableConfig'] &&
          (this.field?.attribute)['tableUiStyle']
        "
      >
        <p-calendar
          class="datecalendar-input dateandtime"
          panelStyleClass="date-calender"
          placeholder="DD/MM/YYYY"
          appendTo="body"
          [touchUI]="true"
          [readonlyInput]="true"
          [placeholder]="labels?.[requiredDateFormat] && isTimeHidden ? labels?.[requiredDateFormat] : (labels?.Hhmmss ? (labels?.[requiredDateFormat] +' '+ labels?.Hhmmss)  :(( requiredDateFormat + ' HH:MM:SS') | uppercase))"
          [disabled]="field?.readonly"
          [(ngModel)]="field.value"
          formControlName="{{ field.attribute.name }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (onShow)="dateClicked($event)"
          [required]="field?.isRequired && !field?.isInfo"
          (onSelect)="registerOnChange($event)"
          (onBlur)="!calendarDateClicked && onBlur($event)"
          (onClose)="calendarDateClicked = true; onBlur($event)"
          [dateFormat]="requiredFormatPrime"
          icon="nh-icon nh-icon-calender datetime-icon"
          [showIcon]="true"
          [showTime]="!isTimeHidden"
          [showSeconds]="!isTimeHidden"
          [monthNavigator]="true"
          [showOnFocus]="false"
          [hourFormat]="requiredTimeFormat == '24-hr' ? 24 : 12"
          [hideOnDateTimeSelect]="false"
          [touchUI]="true"
          id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
          pTooltip="{{ field?.value | date: requiredDateFormat }}"
          tooltipPosition="top"
          #primecalendar
        >
          <ng-template pTemplate="header">
            <div class="date-calender-header">
              <ul>
                <li class="calendarclose" (click)="primecalendar.hideOverlay()">
                  <i class="bi bi-x-lg"></i>
                </li>
              </ul>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div class="date-calender-footer">
              <ul>
                <li (click)="primecalendar.hideOverlay()">
                  <i class="bi bi-check2"></i>
                </li>
                <li class="calendarclose" (click)="primecalendar.hideOverlay()">
                  <i class="bi bi-x-lg"></i>
                </li>
              </ul>
            </div>
          </ng-template>
        </p-calendar>
      </div>
    </ng-template>
    <label class="date-placeholder-label" *ngIf="!isMulti && invalidDate">{{
      labels?.InvalidDate || "Invalid date"
    }}</label>
  </div>
  <p
    class="bottom-text"
    *ngIf="
      field?.configuration?.helperTextPosition == 'bottom' &&
      !field?.configuration?.showHelperTextIcon
    "
    [ngStyle]="{
      color: field?.configuration?.helperTextColor,
      'font-size': field?.configuration?.helperTextFont
    }"
  >
    {{ field?.configuration?.helpertext }}
  </p>
  <!-- For validation message of UI controls -->
  <ng-container
    *ngFor="let validation of field.validations"
    ngProjectAs="mat-error"
  >
    <mat-error
      *ngIf="
        validation.type &&
        (validation.type.toLocaleLowerCase() === 'error' ||
          validation.type.toLocaleLowerCase() === 'block_warn') &&
        getErrorMessage(field, validation)
      "
    >
      {{ validation.message }}</mat-error
    >
    <mat-error
      [ngClass]="validation.type == 'info' ? 'infocolor' : 'warncolor'"
      *ngIf="
        validation.type &&
        validation.type.toLocaleLowerCase() !== 'error' &&
        validation.type.toLocaleLowerCase() !== 'block_warn' &&
        getInfoWarnMessage(field, validation)
      "
      >{{ validation.message }}</mat-error
    >
  </ng-container>
</form>
