<div class="expand-type-temp-previews">
  <div class="expand-entity-template-t1 expand-type-2 expand-attributes" *ngIf="expandType == 4">
    <div class="card template-t1">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div>
        <figure class="card-logo">
          <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
        </figure>
      </div>
      <div class="content">
        <ul>
          <li id="" class="card-title">
            <div class="uicontrol">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li id="" class="card-title-small">
            <div class="uicontrol">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="t1-list-text">
          <div class="list-text-section">
            <ul class="listgroup">
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="listgroup">
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[10]"
                    dynamicField
                    [formData]="attributes[10][attributes[10].attributeName]"
                    [group]="attributes[10][attributes[10].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[11]"
                    dynamicField
                    [formData]="attributes[11][attributes[11].attributeName]"
                    [group]="attributes[11][attributes[11].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[12]"
                    dynamicField
                    [formData]="attributes[12][attributes[12].attributeName]"
                    [group]="attributes[12][attributes[12].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[13]"
                    dynamicField
                    [formData]="attributes[13][attributes[13].attributeName]"
                    [group]="attributes[13][attributes[13].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[14]"
                    dynamicField
                    [formData]="attributes[14][attributes[14].attributeName]"
                    [group]="attributes[14][attributes[14].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="list-text-section">
            <ul class="listgroup">
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[15]"
                    dynamicField
                    [formData]="attributes[15][attributes[15].attributeName]"
                    [group]="attributes[15][attributes[15].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[16]"
                    dynamicField
                    [formData]="attributes[16][attributes[16].attributeName]"
                    [group]="attributes[16][attributes[16].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[17]"
                    dynamicField
                    [formData]="attributes[17][attributes[17].attributeName]"
                    [group]="attributes[17][attributes[17].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[18]"
                    dynamicField
                    [formData]="attributes[18][attributes[18].attributeName]"
                    [group]="attributes[18][attributes[18].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="listgroup">
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[19]"
                    dynamicField
                    [formData]="attributes[19][attributes[19].attributeName]"
                    [group]="attributes[19][attributes[19].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[20]"
                    dynamicField
                    [formData]="attributes[20][attributes[20].attributeName]"
                    [group]="attributes[20][attributes[20].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[21]"
                    dynamicField
                    [formData]="attributes[21][attributes[21].attributeName]"
                    [group]="attributes[21][attributes[21].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[22]"
                    dynamicField
                    [formData]="attributes[22][attributes[22].attributeName]"
                    [group]="attributes[22][attributes[22].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[23]"
                    dynamicField
                    [formData]="attributes[23][attributes[23].attributeName]"
                    [group]="attributes[23][attributes[23].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[24]"
                    dynamicField
                    [formData]="attributes[24][attributes[24].attributeName]"
                    [group]="attributes[24][attributes[24].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li id="" class="list-title">
                <div class="uicontrol">
                  <ng-container
                    *ngIf="attributes[25]"
                    dynamicField
                    [formData]="attributes[25][attributes[25].attributeName]"
                    [group]="attributes[25][attributes[25].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[25] && checkEventGSI(attributes[25], 25)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[25]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[25][attributes[25].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row cu-btn-ctn" (click)="closeExpand()">
          <button class="btn btn-tooltip">
            Show less
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="expand-entity-template-t2 expand-entity-template-t7 expand-type-3 expand-attributes"
    *ngIf="expandType == 1"
  >
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="template-t7">
        <div class="left-content">
          <img class="card-logo" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
        </div>
        <div class="card-data">
          <ul>
            <li id="" class="card-title">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-title-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-title-medium">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle mt-32">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[6]"
                dynamicField
                [formData]="attributes[6][attributes[6].attributeName]"
                [group]="attributes[6][attributes[6].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="t7-section-2">
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[7]"
                dynamicField
                [formData]="attributes[7][attributes[7].attributeName]"
                [group]="attributes[7][attributes[7].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle mt-24">
              <ng-container
                *ngIf="attributes[8]"
                dynamicField
                [formData]="attributes[8][attributes[8].attributeName]"
                [group]="attributes[8][attributes[8].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="mt-32">
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[9]"
                dynamicField
                [formData]="attributes[9][attributes[9].attributeName]"
                [group]="attributes[9][attributes[9].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle mt-24">
              <ng-container
                *ngIf="attributes[10]"
                dynamicField
                [formData]="attributes[10][attributes[10].attributeName]"
                [group]="attributes[10][attributes[10].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="cu-btn-ctn" (click)="closeExpand()">
            <button class="btn btn-tooltip">
              Show less
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t3 expand-type-4" *ngIf="expandType == 12">
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ul class="card-img">
            <li id="" class="card-logo">
              <figure>
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <ul class="card-img-grid">
                <li id="" class="card-logo">
                  <figure>
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
                <li id="" class="card-logo mb-0">
                  <figure>
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul class="card-img-grid">
                <li id="" class="card-logo">
                  <figure>
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
                <li id="" class="card-logo mb-0">
                  <figure>
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card-data">
            <ul>
              <li id="" class="card-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="linebar"></li>
            </ul>
            <ul class="list-group">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title-small">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title-small">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group pt-0">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="linebar"></li>
              <li id="" class="list-group-title-small">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <!-- <ul class="list-group pad-125">
              <li id="" class="list-group-title-small">
                <ng-container
          *ngIf="attributes[13]"
          dynamicField
          [formData]="attributes[13][attributes[13].attributeName]"
          [group]="attributes[13][attributes[13].attributeName + 'form']"
          [isTableConfig]="true"
        >
        </ng-container>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data-box">
            <ul class="data-box-title">
              <!-- <li id="" class="card-title">
                <ng-container
          *ngIf="attributes[13]"
          dynamicField
          [formData]="attributes[13][attributes[13].attributeName]"
          [group]="attributes[13][attributes[13].attributeName + 'form']"
          [isTableConfig]="true"
        >
        </ng-container>
              </li> -->
              <li id="" class="card-title">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="data-box-bold">
              <li id="" class="card-title-bold">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-semi">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-semi">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-semi">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-semi">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="linebar"></li>
              <li id="" class="card-title-semi">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row cu-btn-ctn" (click)="closeExpand()">
        <button class="btn btn-tooltip">
          Show less
        </button>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t4 expand-type-5" *ngIf="expandType == 5">
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="row">
        <div class="col-md-6">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="card-data pl-50">
            <ul>
              <li id="" class="card-title">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-group">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row list-group-mid">
        <div class="col-md-6">
          <div class="card-data clr-black">
            <ul class="list-group">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data">
            <ul class="list-group">
              <li id="" class="list-group-title w-100">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="linebar"></div>
      <div class="row list-group-last">
        <div class="col-md-6">
          <div class="card-data">
            <ul class="list-group mb-0">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[21]"
                  dynamicField
                  [formData]="attributes[21][attributes[21].attributeName]"
                  [group]="attributes[21][attributes[21].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[22]"
                  dynamicField
                  [formData]="attributes[22][attributes[22].attributeName]"
                  [group]="attributes[22][attributes[22].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[23]"
                  dynamicField
                  [formData]="attributes[23][attributes[23].attributeName]"
                  [group]="attributes[23][attributes[23].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-data">
            <ul class="list-group mb-0">
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[24]"
                  dynamicField
                  [formData]="attributes[24][attributes[24].attributeName]"
                  [group]="attributes[24][attributes[24].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[25]"
                  dynamicField
                  [formData]="attributes[25][attributes[25].attributeName]"
                  [group]="attributes[25][attributes[25].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[25] && checkEventGSI(attributes[25], 25)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[25]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[25][attributes[25].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[26]"
                  dynamicField
                  [formData]="attributes[26][attributes[26].attributeName]"
                  [group]="attributes[26][attributes[26].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[26] && checkEventGSI(attributes[26], 26)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[26]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[26][attributes[26].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[27]"
                  dynamicField
                  [formData]="attributes[27][attributes[27].attributeName]"
                  [group]="attributes[27][attributes[27].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[27] && checkEventGSI(attributes[27], 27)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[27]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[27][attributes[27].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[28]"
                  dynamicField
                  [formData]="attributes[28][attributes[28].attributeName]"
                  [group]="attributes[28][attributes[28].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[28] && checkEventGSI(attributes[28], 28)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[28]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[28][attributes[28].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="list-group-title">
                <ng-container
                  *ngIf="attributes[29]"
                  dynamicField
                  [formData]="attributes[29][attributes[29].attributeName]"
                  [group]="attributes[29][attributes[29].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[29] && checkEventGSI(attributes[29], 29)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[29]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[29][attributes[29].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row cu-btn-ctn" (click)="closeExpand()">
        <button class="btn btn-tooltip">
          Show less
        </button>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t5 expand-type-6" *ngIf="expandType == 3">
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="row">
        <div class="col-md-7">
          <ul>
            <li id="" class="card-logo">
              <figure>
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </figure>
            </li>
          </ul>
        </div>
        <div class="col-md-5">
          <div class="card-data">
            <ul>
              <li id="" class="card-title">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-small">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-small">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-medium">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div class="list-group">
              <ul class="list-group-item">
                <li id="" class="list-group-logo">
                  <figure>
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
                <li id="" class="list-group-second">
                  <ul>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[7]"
                        dynamicField
                        [formData]="attributes[7][attributes[7].attributeName]"
                        [group]="attributes[7][attributes[7].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li id="" class="list-group-subtitle">
                      <ng-container
                        *ngIf="attributes[8]"
                        dynamicField
                        [formData]="attributes[8][attributes[8].attributeName]"
                        [group]="attributes[8][attributes[8].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li id="" class="list-group-third">
                  <ul>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[9]"
                        dynamicField
                        [formData]="attributes[9][attributes[9].attributeName]"
                        [group]="attributes[9][attributes[9].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[10]"
                        dynamicField
                        [formData]="attributes[10][attributes[10].attributeName]"
                        [group]="attributes[10][attributes[10].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul class="list-group-item">
                <li id="" class="list-group-logo">
                  <figure>
                    <img [src]="contentUrl[11] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
                <li id="" class="list-group-second">
                  <ul>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[12]"
                        dynamicField
                        [formData]="attributes[12][attributes[12].attributeName]"
                        [group]="attributes[12][attributes[12].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li id="" class="list-group-subtitle">
                      <ng-container
                        *ngIf="attributes[13]"
                        dynamicField
                        [formData]="attributes[13][attributes[13].attributeName]"
                        [group]="attributes[13][attributes[13].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li id="" class="list-group-third">
                  <ul>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[14]"
                        dynamicField
                        [formData]="attributes[14][attributes[14].attributeName]"
                        [group]="attributes[14][attributes[14].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[15]"
                        dynamicField
                        [formData]="attributes[15][attributes[15].attributeName]"
                        [group]="attributes[15][attributes[15].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul class="list-group-item">
                <li id="" class="list-group-logo">
                  <figure>
                    <img [src]="contentUrl[16] | CDNUrlRewritePipe" alt="" />
                  </figure>
                </li>
                <li id="" class="list-group-second">
                  <ul>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[17]"
                        dynamicField
                        [formData]="attributes[17][attributes[17].attributeName]"
                        [group]="attributes[17][attributes[17].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li id="" class="list-group-subtitle">
                      <ng-container
                        *ngIf="attributes[18]"
                        dynamicField
                        [formData]="attributes[18][attributes[18].attributeName]"
                        [group]="attributes[18][attributes[18].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li id="" class="list-group-third">
                  <ul>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[19]"
                        dynamicField
                        [formData]="attributes[19][attributes[19].attributeName]"
                        [group]="attributes[19][attributes[19].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li id="" class="list-group-title">
                      <ng-container
                        *ngIf="attributes[20]"
                        dynamicField
                        [formData]="attributes[20][attributes[20].attributeName]"
                        [group]="attributes[20][attributes[20].attributeName + 'form']"
                        [isTableConfig]="true"
                      >
                      </ng-container>
                      <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                        <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                          <button
                            type="button"
                            mat-button
                            color="primary"
                            (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                          >
                            {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li id="" class="card-subtitle">
                  <ng-container
                    *ngIf="attributes[21]"
                    dynamicField
                    [formData]="attributes[21][attributes[21].attributeName]"
                    [group]="attributes[21][attributes[21].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="card-subtitle">
                  <ng-container
                    *ngIf="attributes[22]"
                    dynamicField
                    [formData]="attributes[22][attributes[22].attributeName]"
                    [group]="attributes[22][attributes[22].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="card-subtitle">
                  <ng-container
                    *ngIf="attributes[23]"
                    dynamicField
                    [formData]="attributes[23][attributes[23].attributeName]"
                    [group]="attributes[23][attributes[23].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-md-12 list-data">
          <ul class="col-md-6 list-data-items">
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[24]"
                dynamicField
                [formData]="attributes[24][attributes[24].attributeName]"
                [group]="attributes[24][attributes[24].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[25]"
                dynamicField
                [formData]="attributes[25][attributes[25].attributeName]"
                [group]="attributes[25][attributes[25].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[25] && checkEventGSI(attributes[25], 25)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[25]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[25][attributes[25].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[26]"
                dynamicField
                [formData]="attributes[26][attributes[26].attributeName]"
                [group]="attributes[26][attributes[26].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[26] && checkEventGSI(attributes[26], 26)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[26]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[26][attributes[26].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[27]"
                dynamicField
                [formData]="attributes[27][attributes[27].attributeName]"
                [group]="attributes[27][attributes[27].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[27] && checkEventGSI(attributes[27], 27)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[27]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[27][attributes[27].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[28]"
                dynamicField
                [formData]="attributes[28][attributes[28].attributeName]"
                [group]="attributes[28][attributes[28].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[28] && checkEventGSI(attributes[28], 28)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[28]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[28][attributes[28].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[29]"
                dynamicField
                [formData]="attributes[29][attributes[29].attributeName]"
                [group]="attributes[29][attributes[29].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[29] && checkEventGSI(attributes[29], 29)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[29]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[29][attributes[29].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="col-md-6 list-data-items">
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[30]"
                dynamicField
                [formData]="attributes[30][attributes[30].attributeName]"
                [group]="attributes[30][attributes[30].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[30] && checkEventGSI(attributes[30], 30)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[30]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[30][attributes[30].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[31]"
                dynamicField
                [formData]="attributes[31][attributes[31].attributeName]"
                [group]="attributes[31][attributes[31].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[31] && checkEventGSI(attributes[31], 31)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[31]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[31][attributes[31].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[32]"
                dynamicField
                [formData]="attributes[32][attributes[32].attributeName]"
                [group]="attributes[32][attributes[32].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[32] && checkEventGSI(attributes[32], 32)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[32]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[32][attributes[32].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[33]"
                dynamicField
                [formData]="attributes[33][attributes[33].attributeName]"
                [group]="attributes[33][attributes[33].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[33] && checkEventGSI(attributes[33], 33)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[33]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[33][attributes[33].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[34]"
                dynamicField
                [formData]="attributes[34][attributes[34].attributeName]"
                [group]="attributes[34][attributes[34].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[34] && checkEventGSI(attributes[34], 34)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[34]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[34][attributes[34].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[35]"
                dynamicField
                [formData]="attributes[35][attributes[35].attributeName]"
                [group]="attributes[35][attributes[35].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[35] && checkEventGSI(attributes[35], 35)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[35]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[35][attributes[35].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="cu-btn-ctn" (click)="closeExpand()">
        <button class="btn btn-tooltip">
          Show less
        </button>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t6 expand-type-7 attributes-15 expand-attributes" *ngIf="expandType == 2">
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="template-t6">
        <div class="card-logo">
          <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
        </div>
        <div class="card-data">
          <ul>
            <li id="" class="card-title">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="cardtitlesmall">
            <li id="" class="card-title-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-title-small">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul>
            <li id="" class="card-title-medium">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <ul class="list-group">
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[10]"
                    dynamicField
                    [formData]="attributes[10][attributes[10].attributeName]"
                    [group]="attributes[10][attributes[10].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-sm-12">
              <ul class="list-group">
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[11]"
                    dynamicField
                    [formData]="attributes[11][attributes[11].attributeName]"
                    [group]="attributes[11][attributes[11].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[12]"
                    dynamicField
                    [formData]="attributes[12][attributes[12].attributeName]"
                    [group]="attributes[12][attributes[12].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[13]"
                    dynamicField
                    [formData]="attributes[13][attributes[13].attributeName]"
                    [group]="attributes[13][attributes[13].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[14]"
                    dynamicField
                    [formData]="attributes[14][attributes[14].attributeName]"
                    [group]="attributes[14][attributes[14].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li id="" class="list-group-title">
                  <ng-container
                    *ngIf="attributes[15]"
                    dynamicField
                    [formData]="attributes[15][attributes[15].attributeName]"
                    [group]="attributes[15][attributes[15].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="cu-btn-ctn" (click)="closeExpand()">
            <button class="btn btn-tooltip">
              Show less
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t7 expand-type-3 Attribute-8 expand-attributes" *ngIf="expandType == 6">
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="template-t7">
        <div class="left-content">
          <img class="card-logo" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
        </div>
        <div class="card-data">
          <ul>
            <li id="" class="card-title">
              <ng-container
                *ngIf="attributes[2]"
                dynamicField
                [formData]="attributes[2][attributes[2].attributeName]"
                [group]="attributes[2][attributes[2].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-title-small">
              <ng-container
                *ngIf="attributes[3]"
                dynamicField
                [formData]="attributes[3][attributes[3].attributeName]"
                [group]="attributes[3][attributes[3].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-title-medium">
              <ng-container
                *ngIf="attributes[4]"
                dynamicField
                [formData]="attributes[4][attributes[4].attributeName]"
                [group]="attributes[4][attributes[4].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle mt-32">
              <ng-container
                *ngIf="attributes[5]"
                dynamicField
                [formData]="attributes[5][attributes[5].attributeName]"
                [group]="attributes[5][attributes[5].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle-small">
              <ng-container
                *ngIf="attributes[6]"
                dynamicField
                [formData]="attributes[6][attributes[6].attributeName]"
                [group]="attributes[6][attributes[6].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="t7-section-2">
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[7]"
                dynamicField
                [formData]="attributes[7][attributes[7].attributeName]"
                [group]="attributes[7][attributes[7].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle mt-24">
              <ng-container
                *ngIf="attributes[8]"
                dynamicField
                [formData]="attributes[8][attributes[8].attributeName]"
                [group]="attributes[8][attributes[8].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul class="mt-32">
            <li id="" class="card-subtitle">
              <ng-container
                *ngIf="attributes[9]"
                dynamicField
                [formData]="attributes[9][attributes[9].attributeName]"
                [group]="attributes[9][attributes[9].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
            <li id="" class="card-subtitle mt-24">
              <ng-container
                *ngIf="attributes[10]"
                dynamicField
                [formData]="attributes[10][attributes[10].attributeName]"
                [group]="attributes[10][attributes[10].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="cu-btn-ctn" (click)="closeExpand()">
            <button class="btn btn-tooltip">
              Show less
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t8 expand-type-3 Attribute-8 expand-attributes" *ngIf="expandType == 7">
    <div class="card">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="template-t8">
        <div class="left-content">
          <div class="card-data">
            <ul>
              <li id="" class="card-title text-truncate">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-small text-truncate">
                <ng-container
                  *ngIf="attributes[2]"
                  dynamicField
                  [formData]="attributes[2][attributes[2].attributeName]"
                  [group]="attributes[2][attributes[2].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-medium text-truncate">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle-small text-truncate">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="mt-32">
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle mb-8 text-truncate">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-content">
          <div class="card-data">
            <ul>
              <li id="" class="card-subtitle mt-0 mb-26 text-truncate">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-title-small text-truncate">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle cstm-subtitle-lh text-truncate">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle mb-24 text-truncate">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="hl mb-24"></div>
              <li id="" class="card-subtitle-small text-truncate">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="mt-26">
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle mb-8 text-truncate">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="mt-32">
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li id="" class="card-subtitle text-truncate">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cu-btn-ctn mt-32" (click)="closeExpand()">
        <button class="btn btn-tooltip">
          Show less
        </button>
      </div>
    </div>
  </div>
  <div class="expand-entity-template-t9" *ngIf="expandType == 8">
    <div class="card entity-template-type">
      <section class="row expand-head">
        <div class="col-6">
          <div class="row primary-list">
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[2]"
                    dynamicField
                    [formData]="attributes[2][attributes[2].attributeName]"
                    [group]="attributes[2][attributes[2].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[3]"
                    dynamicField
                    [formData]="attributes[3][attributes[3].attributeName]"
                    [group]="attributes[3][attributes[3].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ul class="note">
            <li>
              <ng-container
                *ngIf="attributes[10]"
                dynamicField
                [formData]="attributes[10][attributes[10].attributeName]"
                [group]="attributes[10][attributes[10].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                1
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <div class="secondary-list">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-3">
          <ul>
            <li>
              <ng-container
                *ngIf="attributes[17]"
                dynamicField
                [formData]="attributes[17][attributes[17].attributeName]"
                [group]="attributes[17][attributes[17].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-3">
          <ul>
            <li>
              <ng-container
                *ngIf="attributes[18]"
                dynamicField
                [formData]="attributes[18][attributes[18].attributeName]"
                [group]="attributes[18][attributes[18].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="expand-view">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[19]"
              dynamicField
              [formData]="attributes[19][attributes[19].attributeName]"
              [group]="attributes[19][attributes[19].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[20]"
              dynamicField
              [formData]="attributes[20][attributes[20].attributeName]"
              [group]="attributes[20][attributes[20].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li>
            <ng-container
              *ngIf="attributes[21]"
              dynamicField
              [formData]="attributes[21][attributes[21].attributeName]"
              [group]="attributes[21][attributes[21].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[22]"
              dynamicField
              [formData]="attributes[22][attributes[22].attributeName]"
              [group]="attributes[22][attributes[22].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li>
            <ng-container
              *ngIf="attributes[23]"
              dynamicField
              [formData]="attributes[23][attributes[23].attributeName]"
              [group]="attributes[23][attributes[23].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[24]"
              dynamicField
              [formData]="attributes[24][attributes[24].attributeName]"
              [group]="attributes[24][attributes[24].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li>
            <ng-container
              *ngIf="attributes[25]"
              dynamicField
              [formData]="attributes[25][attributes[25].attributeName]"
              [group]="attributes[25][attributes[25].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[25] && checkEventGSI(attributes[25], 25)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[25]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[25][attributes[25].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[26]"
              dynamicField
              [formData]="attributes[26][attributes[26].attributeName]"
              [group]="attributes[26][attributes[26].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[26] && checkEventGSI(attributes[26], 26)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[26]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[26][attributes[26].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>

          <li>
            <ng-container
              *ngIf="attributes[27]"
              dynamicField
              [formData]="attributes[27][attributes[27].attributeName]"
              [group]="attributes[27][attributes[27].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[27] && checkEventGSI(attributes[27], 27)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[27]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[27][attributes[27].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[28]"
              dynamicField
              [formData]="attributes[28][attributes[28].attributeName]"
              [group]="attributes[28][attributes[28].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[28] && checkEventGSI(attributes[28], 28)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[28]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[28][attributes[28].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </section>
      <div class="tertiary-list">
        <div class="row">
          <div class="col-3">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[29]"
                  dynamicField
                  [formData]="attributes[29][attributes[29].attributeName]"
                  [group]="attributes[29][attributes[29].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[29] && checkEventGSI(attributes[29], 29)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[29]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[29][attributes[29].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[30]"
                  dynamicField
                  [formData]="attributes[30][attributes[30].attributeName]"
                  [group]="attributes[30][attributes[30].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[30] && checkEventGSI(attributes[30], 30)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[30]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[30][attributes[30].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-3">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[31]"
                  dynamicField
                  [formData]="attributes[31][attributes[31].attributeName]"
                  [group]="attributes[31][attributes[31].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[31] && checkEventGSI(attributes[31], 31)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[31]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[31][attributes[31].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-3 cu-btn-ctn" (click)="closeExpand()">
            <button class="btn btn-tooltip">View Less</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="expandType == 9">
    <div class="card expand-entity-template-t10 entity-template-type">
      <section class="row expand-view">
        <div class="col-6">
          <div class="row primary-list">
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[2]"
                    dynamicField
                    [formData]="attributes[2][attributes[2].attributeName]"
                    [group]="attributes[2][attributes[2].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[3]"
                    dynamicField
                    [formData]="attributes[3][attributes[3].attributeName]"
                    [group]="attributes[3][attributes[3].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[5]"
                    dynamicField
                    [formData]="attributes[5][attributes[5].attributeName]"
                    [group]="attributes[5][attributes[5].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ul class="note">
            <li>
              <ng-container
                *ngIf="attributes[10]"
                dynamicField
                [formData]="attributes[10][attributes[10].attributeName]"
                [group]="attributes[10][attributes[10].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <div class="secondary-list">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div class="tertiary-list">
        <div class="row">
          <div class="col-4">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <li>
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row cu-btn-ctn" (click)="closeExpand()">
        <button class="col-12 btn btn-tooltip">View Less</button>
      </div>
    </div>
  </div>
  <div class="parac-expand-card" *ngIf="expandType == 309">
    <div class="top-header">
      <mat-icon (click)="closeExpand()">close</mat-icon>
    </div>
    <div class="parac-expand-banner">
      <div class="parac-expand-img">
        <img [src]="contentUrl[imageVariable] | CDNUrlRewritePipe" alt="" />
      </div>
      <div class="parac-expand-imgs">
        <ul>
          <li (click)="imageChange(1, $event)">
            <img *ngIf="attributes[1]?.attributeValue" [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(2, $event)">
            <img *ngIf="attributes[2]?.attributeValue" [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(3, $event)">
            <img *ngIf="attributes[3]?.attributeValue" [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(4, $event)">
            <img *ngIf="attributes[4]?.attributeValue" [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(5, $event)">
            <img *ngIf="attributes[5]?.attributeValue" [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="parac-expand-right">
      <div class="parac-expand-heading">
        <ul>
          <li class="parac-expand-title">
            <ng-container
              *ngIf="attributes[6]"
              dynamicField
              [formData]="attributes[6][attributes[6].attributeName]"
              [group]="attributes[6][attributes[6].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-sub-title">
            <ng-container
              *ngIf="attributes[7]"
              dynamicField
              [formData]="attributes[7][attributes[7].attributeName]"
              [group]="attributes[7][attributes[7].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-info">
            <ng-container
              *ngIf="attributes[8]"
              dynamicField
              [formData]="attributes[8][attributes[8].attributeName]"
              [group]="attributes[8][attributes[8].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-pricingTag">
        <ul>
          <li class="parac-expand-mrp">
            <ng-container
              *ngIf="attributes[9]"
              dynamicField
              [formData]="attributes[9][attributes[9].attributeName]"
              [group]="attributes[9][attributes[9].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-offer">
            <ng-container
              *ngIf="attributes[10]"
              dynamicField
              [formData]="attributes[10][attributes[10].attributeName]"
              [group]="attributes[10][attributes[10].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-discount">
            <ng-container
              *ngIf="attributes[11]"
              dynamicField
              [formData]="attributes[11][attributes[11].attributeName]"
              [group]="attributes[11][attributes[11].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-description">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[12]"
              dynamicField
              [formData]="attributes[12][attributes[12].attributeName]"
              [group]="attributes[12][attributes[12].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-quantity">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[13]"
              dynamicField
              [formData]="attributes[13][attributes[13].attributeName]"
              [group]="attributes[13][attributes[13].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-btm-description">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[14]"
              dynamicField
              [formData]="attributes[14][attributes[14].attributeName]"
              [group]="attributes[14][attributes[14].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[15]"
              dynamicField
              [formData]="attributes[15][attributes[15].attributeName]"
              [group]="attributes[15][attributes[15].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[16]"
              dynamicField
              [formData]="attributes[16][attributes[16].attributeName]"
              [group]="attributes[16][attributes[16].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="parac-expand-card" *ngIf="expandType == 310">
    <div class="top-header">
      <mat-icon (click)="closeExpand()">close</mat-icon>
    </div>
    <div class="parac-expand-banner">
      <div class="parac-expand-img">
        <img [src]="contentUrl[imageVariable] | CDNUrlRewritePipe" alt="" />
        <img
          *ngIf="attributes[1]?.attributeValue"
          class="logo"
          [src]="'../../../../../assets/img/general-entity/templates/nineteenattributes/357.svg' | CDNUrlRewritePipe"
          alt=""
        />
      </div>
      <div class="parac-expand-imgs">
        {{ attributes[1]?.attributeValue }}
        <ul>
          <li (click)="imageChange(2, $event)">
            <img *ngIf="attributes[2]?.attributeValue" [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(3, $event)">
            <img *ngIf="attributes[3]?.attributeValue" [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(4, $event)">
            <img *ngIf="attributes[4]?.attributeValue" [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(5, $event)">
            <img *ngIf="attributes[5]?.attributeValue" [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
          </li>
          <li (click)="imageChange(6, $event)">
            <img *ngIf="attributes[6]?.attributeValue" [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <div class="parac-expand-right">
      <div class="parac-expand-heading">
        <ul>
          <li class="parac-expand-title">
            <ng-container
              *ngIf="attributes[7]"
              dynamicField
              [formData]="attributes[7][attributes[7].attributeName]"
              [group]="attributes[7][attributes[7].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-sub-title">
            <ng-container
              *ngIf="attributes[8]"
              dynamicField
              [formData]="attributes[8][attributes[8].attributeName]"
              [group]="attributes[8][attributes[8].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-info">
            <ng-container
              *ngIf="attributes[9]"
              dynamicField
              [formData]="attributes[9][attributes[9].attributeName]"
              [group]="attributes[9][attributes[9].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-pricingTag">
        <ul>
          <li class="parac-expand-mrp">
            <ng-container
              *ngIf="attributes[10]"
              dynamicField
              [formData]="attributes[10][attributes[10].attributeName]"
              [group]="attributes[10][attributes[10].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-offer">
            <ng-container
              *ngIf="attributes[11]"
              dynamicField
              [formData]="attributes[11][attributes[11].attributeName]"
              [group]="attributes[11][attributes[11].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="parac-expand-discount">
            <ng-container
              *ngIf="attributes[12]"
              dynamicField
              [formData]="attributes[12][attributes[12].attributeName]"
              [group]="attributes[12][attributes[12].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-description">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[13]"
              dynamicField
              [formData]="attributes[13][attributes[13].attributeName]"
              [group]="attributes[13][attributes[13].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-quantity">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[14]"
              dynamicField
              [formData]="attributes[14][attributes[14].attributeName]"
              [group]="attributes[14][attributes[14].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="parac-expand-btm-description">
        <ul>
          <li>
            <ng-container
              *ngIf="attributes[15]"
              dynamicField
              [formData]="attributes[15][attributes[15].attributeName]"
              [group]="attributes[15][attributes[15].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[16]"
              dynamicField
              [formData]="attributes[16][attributes[16].attributeName]"
              [group]="attributes[16][attributes[16].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li>
            <ng-container
              *ngIf="attributes[17]"
              dynamicField
              [formData]="attributes[17][attributes[17].attributeName]"
              [group]="attributes[17][attributes[17].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="template-311-expand" *ngIf="expandType == 311">
    <div class="t-311-text-container">
      <div class="top-header">
        <mat-icon (click)="closeExpand()">close</mat-icon>
      </div>
      <div class="left-container">
        <div class="outer-border">
          <div class="outer-info-section">
            <div class="image-section">
              <li (click)="imageChange(1, $event)">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
                <img *ngIf="!contentUrl[1]" src="../../../../../assets/default-images/default-image.png" alt="" />
              </li>
            </div>
            <div class="attribute">
              <ul>
                <li class="attribute-field lineclamp">
                  <ng-container
                    *ngIf="attributes[2]"
                    dynamicField
                    [formData]="attributes[2][attributes[2].attributeName]"
                    [group]="attributes[2][attributes[2].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[2] && checkEventGSI(attributes[2], 2)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[2]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[2][attributes[2].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <ng-container
                    *ngIf="attributes[3]"
                    dynamicField
                    [formData]="attributes[3][attributes[3].attributeName]"
                    [group]="attributes[3][attributes[3].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <ng-container
                    *ngIf="attributes[4]"
                    dynamicField
                    [formData]="attributes[4][attributes[4].attributeName]"
                    [group]="attributes[4][attributes[4].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="outer-class-mem-section">
            <ul>
              <li class="member lineclamp">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="outer-last-section">
            <div class="ols-item">
              <ul>
                <li class="memberID">
                  <ng-container
                    *ngIf="attributes[6]"
                    dynamicField
                    [formData]="attributes[6][attributes[6].attributeName]"
                    [group]="attributes[6][attributes[6].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="memberID">
                  <ng-container
                    *ngIf="attributes[7]"
                    dynamicField
                    [formData]="attributes[7][attributes[7].attributeName]"
                    [group]="attributes[7][attributes[7].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="memberID">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="ols-item">
              <ul>
                <li class="memberID">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="memberID">
                  <ng-container
                    *ngIf="attributes[10]"
                    dynamicField
                    [formData]="attributes[10][attributes[10].attributeName]"
                    [group]="attributes[10][attributes[10].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="memberID">
                  <ng-container
                    *ngIf="attributes[11]"
                    dynamicField
                    [formData]="attributes[11][attributes[11].attributeName]"
                    [group]="attributes[11][attributes[11].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="right-container">
        <div class="col-second">
          <ul class="col-second-header">
            <li class="member">
              <ng-container
                *ngIf="attributes[12]"
                dynamicField
                [formData]="attributes[12][attributes[12].attributeName]"
                [group]="attributes[12][attributes[12].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>

          <div class="col-second-text-section">
            <div class="section-class">
              <ul>
                <li class="section-member">
                  <ng-container
                    *ngIf="attributes[13]"
                    dynamicField
                    [formData]="attributes[13][attributes[13].attributeName]"
                    [group]="attributes[13][attributes[13].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="section-member">
                  <ng-container
                    *ngIf="attributes[14]"
                    dynamicField
                    [formData]="attributes[14][attributes[14].attributeName]"
                    [group]="attributes[14][attributes[14].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="section-member">
                  <ng-container
                    *ngIf="attributes[15]"
                    dynamicField
                    [formData]="attributes[15][attributes[15].attributeName]"
                    [group]="attributes[15][attributes[15].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <ul>
                <li>
                  <ng-container
                    *ngIf="attributes[16]"
                    dynamicField
                    [formData]="attributes[16][attributes[16].attributeName]"
                    [group]="attributes[16][attributes[16].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="section-member">
                  <ng-container
                    *ngIf="attributes[17]"
                    dynamicField
                    [formData]="attributes[17][attributes[17].attributeName]"
                    [group]="attributes[17][attributes[17].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <ul>
                <li class="section-member">
                  <ng-container
                    *ngIf="attributes[18]"
                    dynamicField
                    [formData]="attributes[18][attributes[18].attributeName]"
                    [group]="attributes[18][attributes[18].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right-border">
          <ul>
            <!-- <li class="rb-class-1">Class</li> -->

            <li class="rb-mem-1">
              <ng-container
                *ngIf="attributes[19]"
                dynamicField
                [formData]="attributes[19][attributes[19].attributeName]"
                [group]="attributes[19][attributes[19].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>

            <!-- <li class="rb-class-2">Class</li> -->

            <li class="rb-mem-2">
              <ng-container
                *ngIf="attributes[20]"
                dynamicField
                [formData]="attributes[20][attributes[20].attributeName]"
                [group]="attributes[20][attributes[20].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>

            <li class="rb-mem-3">
              <ng-container
                *ngIf="attributes[21]"
                dynamicField
                [formData]="attributes[21][attributes[21].attributeName]"
                [group]="attributes[21][attributes[21].attributeName + 'form']"
                [isTableConfig]="true"
              >
              </ng-container>
              <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                  <button
                    type="button"
                    mat-button
                    color="primary"
                    (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                  >
                    {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="template-319-expand" *ngIf="expandType == 319">
    <div class="top-header">
      <mat-icon (click)="closeExpand()">close</mat-icon>
    </div>
    <div class="t-319-container-top">
      <div class="left-container">
        <div class="display-img-text">
          <li class="member-text">
            <ng-container
              *ngIf="attributes[1]"
              dynamicField
              [formData]="attributes[1][attributes[1].attributeName]"
              [group]="attributes[1][attributes[1].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </div>
        <div class="display-image">
          <img [src]="contentUrl[imageVariable] | CDNUrlRewritePipe" alt="" />
        </div>
        <div class="image-wrapper">
          <li
            *ngIf="attributes[2]?.attributeValue"
            (click)="imageChange(2, $event)"
            [ngClass]="{ 'image-active': imageVariable == 2 }"
          >
            <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
          </li>
          <li
            *ngIf="attributes[3]?.attributeValue"
            (click)="imageChange(3, $event)"
            [ngClass]="{ 'image-active': imageVariable == 3 }"
          >
            <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
          </li>
          <li
            *ngIf="attributes[4]?.attributeValue"
            (click)="imageChange(4, $event)"
            [ngClass]="{ 'image-active': imageVariable == 4 }"
          >
            <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
          </li>
          <li
            *ngIf="attributes[5]?.attributeValue"
            (click)="imageChange(5, $event)"
            [ngClass]="{ 'image-active': imageVariable == 5 }"
          >
            <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
          </li>
          <li
            *ngIf="attributes[6]?.attributeValue"
            (click)="imageChange(6, $event)"
            [ngClass]="{ 'image-active': imageVariable == 6 }"
          >
            <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
          </li>
        </div>
      </div>
      <div class="right-container">
        <div class="row-1">
          <li class="row-1-title">
            <ng-container
              *ngIf="attributes[7]"
              dynamicField
              [formData]="attributes[7][attributes[7].attributeName]"
              [group]="attributes[7][attributes[7].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="row-1-subtitle">
            <ng-container
              *ngIf="attributes[8]"
              dynamicField
              [formData]="attributes[8][attributes[8].attributeName]"
              [group]="attributes[8][attributes[8].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="row-1-description">
            <ng-container
              *ngIf="attributes[9]"
              dynamicField
              [formData]="attributes[9][attributes[9].attributeName]"
              [group]="attributes[9][attributes[9].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
        </div>
        <div class="row-2">
          <li class="offer-desc">
            <ng-container
              *ngIf="attributes[10]"
              dynamicField
              [formData]="attributes[10][attributes[10].attributeName]"
              [group]="attributes[10][attributes[10].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <li class="offer-desc">
            <ng-container
              *ngIf="attributes[11]"
              dynamicField
              [formData]="attributes[11][attributes[11].attributeName]"
              [group]="attributes[11][attributes[11].attributeName + 'form']"
              [isTableConfig]="true"
            >
            </ng-container>
            <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
              <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                <button
                  type="button"
                  mat-button
                  color="primary"
                  (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                >
                  {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                </button>
              </div>
            </div>
          </li>
          <div class="row-2-specifications-wrapper">
            <div class="spec-card-1">
              <li>
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </div>
            <div class="spec-card">
              <li>
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </div>
            <div class="spec-card">
              <li class="cl-black">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </div>
            <div class="spec-card">
              <li>
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t-319-container-middle">
      <div class="left-contents-wrapper">
        <li>
          <ng-container
            *ngIf="attributes[16]"
            dynamicField
            [formData]="attributes[16][attributes[16].attributeName]"
            [group]="attributes[16][attributes[16].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="attribute-field">
          <ng-container
            *ngIf="attributes[17]"
            dynamicField
            [formData]="attributes[17][attributes[17].attributeName]"
            [group]="attributes[17][attributes[17].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[18]"
            dynamicField
            [formData]="attributes[18][attributes[18].attributeName]"
            [group]="attributes[18][attributes[18].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
      <div class="right-contents-wrapper">
        <li>
          <ng-container
            *ngIf="attributes[19]"
            dynamicField
            [formData]="attributes[19][attributes[19].attributeName]"
            [group]="attributes[19][attributes[19].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="attribute-field">
          <ng-container
            *ngIf="attributes[20]"
            dynamicField
            [formData]="attributes[20][attributes[20].attributeName]"
            [group]="attributes[20][attributes[20].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li>
          <ng-container
            *ngIf="attributes[21]"
            dynamicField
            [formData]="attributes[21][attributes[21].attributeName]"
            [group]="attributes[21][attributes[21].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
    </div>
    <div class="t-319-container-bottom">
      <div class="desc-row">
        <li class="desc-heading">
          <ng-container
            *ngIf="attributes[22]"
            dynamicField
            [formData]="attributes[22][attributes[22].attributeName]"
            [group]="attributes[22][attributes[22].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="desc">
          <ng-container
            *ngIf="attributes[23]"
            dynamicField
            [formData]="attributes[23][attributes[23].attributeName]"
            [group]="attributes[23][attributes[23].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
      <div class="desc-row">
        <li class="desc-heading">
          <ng-container
            *ngIf="attributes[24]"
            dynamicField
            [formData]="attributes[24][attributes[24].attributeName]"
            [group]="attributes[24][attributes[24].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="desc">
          <ng-container
            *ngIf="attributes[25]"
            dynamicField
            [formData]="attributes[25][attributes[25].attributeName]"
            [group]="attributes[25][attributes[25].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[25] && checkEventGSI(attributes[25], 25)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[25]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[25][attributes[25].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
      <div class="desc-row">
        <li class="desc-heading">
          <ng-container
            *ngIf="attributes[26]"
            dynamicField
            [formData]="attributes[26][attributes[26].attributeName]"
            [group]="attributes[26][attributes[26].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[26] && checkEventGSI(attributes[26], 26)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[26]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[26][attributes[26].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="desc">
          <ng-container
            *ngIf="attributes[27]"
            dynamicField
            [formData]="attributes[27][attributes[27].attributeName]"
            [group]="attributes[27][attributes[27].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[27] && checkEventGSI(attributes[27], 27)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[27]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[27][attributes[27].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
      <div class="desc-row">
        <li class="desc-heading">
          <ng-container
            *ngIf="attributes[28]"
            dynamicField
            [formData]="attributes[28][attributes[28].attributeName]"
            [group]="attributes[28][attributes[28].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[28] && checkEventGSI(attributes[28], 28)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[28]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[28][attributes[28].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="desc">
          <ng-container
            *ngIf="attributes[29]"
            dynamicField
            [formData]="attributes[29][attributes[29].attributeName]"
            [group]="attributes[29][attributes[29].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[29] && checkEventGSI(attributes[29], 29)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[29]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[29][attributes[29].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
      <div class="desc-row">
        <li class="desc-heading">
          <ng-container
            *ngIf="attributes[30]"
            dynamicField
            [formData]="attributes[30][attributes[30].attributeName]"
            [group]="attributes[30][attributes[30].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[30] && checkEventGSI(attributes[30], 30)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[30]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[30][attributes[30].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
        <li class="desc">
          <ng-container
            *ngIf="attributes[31]"
            dynamicField
            [formData]="attributes[31][attributes[31].attributeName]"
            [group]="attributes[31][attributes[31].attributeName + 'form']"
            [isTableConfig]="true"
          >
          </ng-container>
          <div *ngIf="attributes[31] && checkEventGSI(attributes[31], 31)">
            <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[31]">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, attributes[31][attributes[31].attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </li>
      </div>
    </div>
  </div>
</div>
