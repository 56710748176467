import { Injectable, OnDestroy } from '@angular/core';
import { EntityEndpointService } from './entity-endpoint.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityStoreService } from '../stores/entity-store';
import { LoaderService } from '../loader/loader.service';
import { Entity } from '../modal/entity';
import { entityDeMapper } from '../mappers/entity/entity-demapper';
import { entityMapper } from '../mappers/entity/entity-mapper';
import { entityListMapper } from '../mappers/list/entity';
import { SourceTypes } from '../enums/dld-source.enums';
import { betMapper } from '../../generic-folder/generic-functions';
import { triggerCuMapper } from '../mappers/trigger-cu/trigger-cu-mapper';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class EntityFacadeService implements OnDestroy {
  pageNumber!: number;
  pageSize = 15;
  searchCriteria!: string;
  entitySaveFlag!: boolean;
  isFromDialogBox!: boolean;
  previousCuID: any;
  entitySubject = new Subject();
  entities$ = this.entitySubject.asObservable();

  // Clone Data
  public getCloneDataSubject = new BehaviorSubject(null);
  getCloneData$ = this.getCloneDataSubject.asObservable();

  public cloneResponse = new Subject();
  cloneResponse$ = this.cloneResponse.asObservable();

  public attrInfo = new BehaviorSubject('');
  attrInfo$ = this.attrInfo.asObservable();

  public addAttrInfo = new Subject();
  addAttrInfo$ = this.addAttrInfo.asObservable();

  reservedEntity = new Subject();
  reservedEntity$ = this.reservedEntity.asObservable();

  // private entityAttributeDataStream = new Subject();
  // entityAttributeDataStream$ = this.entityAttributeDataStream.asObservable();

  public entity = new Subject();
  entityflag = this.entity.asObservable();

  private entityCount = new Subject();
  entityCount$ = this.entityCount.asObservable();

  public saveEntityResponse = new Subject();
  saveEntityResponse$ = this.saveEntityResponse.asObservable();

  attributeValidation = new Subject();
  attributeValidation$ = this.attributeValidation.asObservable();

  private bindEntityResponseToCu = new Subject();
  bindEntityResponseToCu$ = this.bindEntityResponseToCu.asObservable();

  attributeName = new Subject();
  attributeName$ = this.attributeName.asObservable();

  public editEntityFromCu = new BehaviorSubject(null);
  editEntityFromCu$ = this.editEntityFromCu.asObservable();

  private entityCCD = new Subject();
  entityCCD$ = this.entityCCD.asObservable();

  public createEntityFromGsi = new BehaviorSubject('');
  createEntityFromGsi$ = this.createEntityFromGsi.asObservable();

  public entityDataForEQLQuerySubject = new Subject();
  entityDataForEQLQuery$ = this.entityDataForEQLQuerySubject.asObservable();

  public entityDataForTarget = new Subject();
  entityDataForTarget$ = this.entityDataForTarget.asObservable();

  entStatustoAttr = new BehaviorSubject(false);
  entStatustoAttr$ = this.entStatustoAttr.asObservable();

  triggerCuAttrUiElement = new BehaviorSubject('');
  triggerCuAttrUiElement$ = this.triggerCuAttrUiElement.asObservable();

  entityNslAttributes = new Subject();
  entityNslAttributes$ = this.entityNslAttributes.asObservable();

  AttributeComparisonData = new Subject();
  AttributeComparisonData$ = this.AttributeComparisonData.asObservable();
  validatedEntityName = new Subject();
  validatedEntityName$ = this.validatedEntityName.asObservable();

  nestedEntity = new BehaviorSubject({});
  nestedEntity$ = this.nestedEntity.asObservable();

  independentEntityData = new Subject();
  independentEntityData$ = this.independentEntityData.asObservable();
  uiControlData: any;

  cuListWithArchivedEntity = new Subject();
  cuListWithArchivedEntity$ = this.cuListWithArchivedEntity.asObservable();

  gsiListWithArchivedEntity = new Subject();
  gsiListWithArchivedEntity$ = this.gsiListWithArchivedEntity.asObservable();

  eligibleBETList = new Subject();
  eligibleBETList$ = this.eligibleBETList.asObservable();

  upgradedGsi = new Subject();
  upgradedGsi$ = this.upgradedGsi.asObservable();

  savedGsi = new Subject();
  savedGsi$ = this.savedGsi.asObservable();

  constructor(
    private entityEndpoint: EntityEndpointService,
    private entityStoreService: EntityStoreService,
    private alertService: AlertService,
    private loader: LoaderService,
  ) {
    this.entityStoreService.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
      if (state) {
        this.entitySubject.next(state.entityList);
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  entityAttributes = new Subject();
  entityAttributes$ = this.entityAttributes.asObservable();

  public entityAttributesEql = new Subject();
  entityAttributesEql$ = this.entityAttributesEql.asObservable();

  public entityForEql = new Subject();
  entityForEql$ = this.entityForEql.asObservable();

  public entityAttributesEqlSubQuery = new Subject();
  entityAttributesEqlSubQuery$ = this.entityAttributesEqlSubQuery.asObservable();

  public entityAttributesTargetAttr = new Subject();
  entityAttributesTargetAttr$ = this.entityAttributesTargetAttr.asObservable();

  public entityAttributesEntityRelation = new Subject();
  entityAttributesEntityRelation$ = this.entityAttributesEntityRelation.asObservable();

  public primaryEntityAttributesEntityRelationForEdit = new Subject();
  primaryEntityAttributesEntityRelationForEdit$ = this.primaryEntityAttributesEntityRelationForEdit.asObservable();

  public relatedEntityAttributesEntityRelationForEdit = new Subject();
  relatedEntityAttributesEntityRelationForEdit$ = this.relatedEntityAttributesEntityRelationForEdit.asObservable();

  public nestedEntityAttributes = new Subject();
  nestedEntityAttributes$ = this.nestedEntityAttributes.asObservable();

  public entityDataForEQLWhiteboardQuery = new Subject();
  entityDataForEQLWhiteboardQuery$ = this.entityDataForEQLWhiteboardQuery.asObservable();

  private dependentDropdownAttributes = new Subject();
  dependentDropdownAttributes$ = this.dependentDropdownAttributes.asObservable();

  subEntityAttributes = new Subject();
  subEntityAttributes$ = this.subEntityAttributes.asObservable();

  // For DLD Use Only
  private savedEntityResponse = new Subject();
  savedEntityResponse$ = this.savedEntityResponse.asObservable();

  private betDataToTrain = new BehaviorSubject({});
  betDataToTrain$ = this.betDataToTrain.asObservable();

  private ARCUImage = new BehaviorSubject('');
  ARCUImage$ = this.ARCUImage.asObservable();

  public triggerCuAttrData = new BehaviorSubject('');
  triggerCuAttrData$ = this.triggerCuAttrData.asObservable();


  entityUpdateFailed = new Subject();
  entityUpdateFailed$ = this.entityUpdateFailed.asObservable();

  // private currentEntityUIControl = new Subject();
  // currentEntityUIControl$ = this.currentEntityUIControl.asObservable();

  private ngUnsubscribe = new Subject();

  getUIValidations() {
    this.entityEndpoint
      .getValidations()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.attributeValidation.next(res);
      });
  }

  callNestedEntityAttributes(entInfo: any) {
    this.nestedEntityAttributes.next(entInfo);
  }
  saveGEEntity(body: Entity, isDld?: boolean, isIRDRUpdate?: boolean) {
    this.loader.show();
    let entInfo = entityDeMapper(body);
    this.entityEndpoint
      .saveGEEntity(entInfo, isIRDRUpdate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /*istanbul ignore next */
          res.result.source = body?.source; //to know the source of import
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          const entInform = entityMapper(res.result);
          this.saveEntityResponse.next(entInform);

          if (isDld === true) {
            this.saveEntityResponse.next(entInform);
          } else {
            this.entityFlag(entInform);
          }
        },
        (error) => {
          const { err } = error.message;
          this.loader.hide();
          this.alertService.showToaster(err, '', 'error');
        }
      );
  }
  fetchById(entityId: any, tenantId: any) {
    return this.entityEndpoint.fetchById(entityId, tenantId);
  }
  save(EntityName: any, EntityId: any, tenanatId: any, data: any) {
    return this.entityEndpoint.save(EntityName, EntityId, tenanatId, data);
  }
  UpdateById(EntityName: any, EntityId: any, tenanatId: any, data: any) {
    return this.entityEndpoint.updateById(EntityName, EntityId, tenanatId, data);
  }
 
  deleteById(entityId: any, tenantId: any) {
    return this.entityEndpoint.deleteById(entityId, tenantId);
  }
  saveEntity(body: Entity, isDld?: boolean, currentLayerIndex?: number, createEntity?: string) {
    this.loader.show();
    let entInfo = entityDeMapper(body, createEntity);
    this.entityEndpoint
      .saveEntity(entInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /*istanbul ignore next */
          res.result.source = body?.source; //to know the source of import
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          const entInform = entityMapper(res.result);
          this.saveEntityResponse.next(entInform);

          if (isDld === true) {
            this.saveEntityResponse.next(entInform);
          } else {
            this.entityFlag(entInform);
          }
          localStorage.removeItem('skipWarnings');
        },
        (error) => {
          const { err } = error.message;
          this.loader.hide();
          localStorage.removeItem('skipWarnings');
          this.alertService.showToaster(err, '', 'error');
        }
      );
  }

  updateEntity(body: any, isDld?: boolean, isOCR?: boolean) {
    this.loader.show();
    const entInfo = entityDeMapper(body);
    this.entityEndpoint
      .saveEntity(entInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          const entInform = entityMapper(res.result);
          if ((isDld === false || isDld === undefined) && (isOCR === false || isOCR === undefined)) {
            this.saveEntityResponse.next(entInform);
          } else if (isDld === false && isOCR === true) {
            this.savedEntityResponse.next({
              entityDataCurrentState: entInform,
              isOCR: true,
              entityDataPreviousState: body,
            });
          }

          if (isDld === true) {
            entInfo.updateAttribute = true;
            this.savedEntityResponse.next(entInform);
          } else {
            this.entityFlag(entInform);
          }
          localStorage.removeItem('skipWarnings');
        },
        (error) => {
          const { errs } = error.message;
          this.loader.hide();
          this.alertService.showToaster(errs, '', 'error');
          this.entityUpdateFailed.next(true);
          localStorage.removeItem('skipWarnings');
        }
      );
  }
  saveEntityResponsetoCU(entity: any) {
    this.bindEntityResponseToCu.next(entity);
  }

  getAllEntitiesForEntityRelation(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: any,
    layerType?: any
  ) {
    this.loader.show();
    /* istanbul ignore next */
    this.entityEndpoint
      .getAllEntities(pagenum + 1, pageSize, searchCriteria, isPublished, layerType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entityForEql.next(res);
      });
  }

  getAllEntities(pagenum: number, pageSize: number, searchCriteria: string, isPublished: any, layerType?: any) {
    this.loader.show();
    this.entityEndpoint
      .getAllEntities(pagenum + 1, pageSize, searchCriteria, isPublished, layerType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const entList = entityListMapper(res);
        this.entityStoreService.setStateData(entList);
      });
  }

  getEntityDataForEQLQueryLayer(searchCriteria: string) {
    this.loader.show();
    /*istanbul ignore next */
    this.entityEndpoint
      .getExactEntityByName(searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const entInfo = entityMapper(res);
        this.entityDataForEQLQuerySubject.next(entInfo);
      });
  }

  getTargetEntityData(searchCriteria: string) {
    this.loader.show();
    /*istanbul ignore next */
    this.entityEndpoint
      .getExactEntityByName(searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const entInfo = entityMapper(res);
        this.entityDataForTarget.next(entInfo);
      });
  }

  getAllEqlEntities(pagenumber: number, pageSize: number, searchCriteria: string, isPublished: any, layerType?: any) {
    this.loader.show();
    this.entityEndpoint
      .getAllEntities(pagenumber + 1, pageSize, searchCriteria, isPublished, layerType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: any) => {
        this.loader.hide();
        const entList = entityListMapper(r);
        this.entityStoreService.setStateData(entList);
      });
  }

  getAllEntitiesCount(pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.entityEndpoint
      .getAllEntitiesCount(0, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.entityCount.next(res);
      });
  }

  loadAttributesByEntId(
    id: any,
    component?: any,
    entityLevel?: any,
    isReserved?: any,
    reservedActions?: any,
    entityIndex?: number,
    source?: string,
    isNested?: boolean
  ) {
    /* istanbul ignore next */
    if (id) {
      this.loader.show();
      this.entityEndpoint
        .loadAttributesByEntId(id, isReserved, reservedActions)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          // adding the source to the body of attribute
          if (source) {
            res.result.source = source;
            for (let attribute of res.result.nslAttributes) {
              attribute.source = source;
            }
          }
          this.loader.hide();
          let entInfo = entityMapper(res.result);
          if (reservedActions) {
            this.reservedEntity.next(res);
          }
          entInfo = { ...entInfo, component: component, entityIndex: entityIndex };
          if (entityLevel) {
            entInfo.gsiLevelEntity = true;
          }
          //entInfo.isPlaceholder = isPlaceholder;
          if (entInfo?.nslAttributes?.length === 0) {
            entInfo.isPlaceholder = true;
          } else {
            entInfo.isPlaceholder = false;
          }
          // For EQL Component
          if (component === 'eql') {
            this.entityAttributesEql.next(entInfo);
          }
          // For EQL Subquery Component
          else if (component === 'EQLsubQuery') {
            this.entityAttributesEqlSubQuery.next(entInfo);
          }
          // For EQL Entity Relation
          else if (component === 'EntityRelation') {
            this.entityAttributesEntityRelation.next(entInfo);
          }
          // For EQL Edit Entity Relation
          else if (component === 'editEntityRelation') {
            this.primaryEntityAttributesEntityRelationForEdit.next(entInfo);
          }
          // For EQL Report Entity Target Attributes
          else if (component === 'targetAttr') {
            this.entityAttributesTargetAttr.next(entInfo);
          }
          // For EQL Whiteboard Query
          else if (component === 'eqlWhiteBoardQuery') {
            this.entityDataForEQLWhiteboardQuery.next(entInfo);
          }
          //For nested entity addition in geolocation, location data type
          else if (isNested) {
            this.nestedEntity.next(entInfo);
          }
          // For Other Components
          else {
            this.entityAttributes.next(entInfo);
          }
        });
    }
  }

  loadnestedEntityDataByEntId(id: any, component?: any, entityLevel?: any, isReserved?: any, reservedActions?: any) {
    /* istanbul ignore next */
    if (id) {
      this.loader.show();
      this.entityEndpoint
        .loadAttributesByEntId(id, isReserved, reservedActions)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.loader.hide();
          res.result.source = SourceTypes.TF;
          res.result.nslAttributes?.forEach((ele: any) => {
            ele.source = SourceTypes.TF;
          });
          const entInfo = entityMapper(res.result);
          this.nestedEntityAttributes.next(entInfo);
        });
    }
  }

  getEntityDetails(res: any) {
    this.entityAttributes.next(entityMapper(res));
  }

  loadAttributesByEntIdForDependentDropdown(id: any) {
    /* istanbul ignore next */
    if (id) {
      return this.entityEndpoint.loadAttributesByEntId(id);
    }
  }

  loadEntityAttributes(id: any) {
    /* istanbul ignore next */
    if (id) {
      return this.entityEndpoint.loadAttributesByEntId(id);
    }
  }

  // loadUIControl(id: any) {
  //   this.loader.show();
  //   return this.entityEndpoint
  //     .loadUIControl(id)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res) => {
  //       this.loader.hide();
  //       this.currentEntityUIControl.next(res);
  //     });
  // }

  // loadUIControlFromEndPoint(id: any) {
  //   return this.entityEndpoint.loadUIControl(id);
  // }

  // attributeDataStream(id: any) {
  //   if (id) {
  //     this.loader.show();
  //     this.entityEndpoint
  //       .loadAttributesByEntId(id)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe((res) => {
  //         this.loader.hide();
  //         this.entityAttributeDataStream.next(res);
  //       });
  //   }
  // }

  loadSubEntitiesAttributesByEntId(id: any, isReserved: any) {
    this.loader.show();
    this.entityEndpoint
      .loadAttributesByEntId(id, isReserved)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        /*istanbul ignore next */
        this.subEntityAttributes.next(entityMapper(res?.result));
      });
  }

  editAttrInfo(attr: any, index: any) {
    this.attrInfo.next({ ...attr, index });
  }

  addAttributeInfo(add: any) {
    this.addAttrInfo.next(add);
  }

  entityFlag(message: any) {
    this.entity.next(message);
  }
  entitySubjectNext(res: any) {
    this.entitySubject.next(res);
  }
  // getEntitiesByCuId(cuId: number) {
  //   this.loader.show();
  //   this.entityEndpoint
  //     .getEntitiesCyCuId(cuId)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res) => {
  //       this.loader.hide();
  //       this.entitySubject.next(res);
  //     });
  // }

  getCloneDetails(cuId: number, isPublished: boolean, type: any, data: any) {
    this.loader.show();
    this.entityEndpoint
      .getCloneDetails(cuId, isPublished, type, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res['message'], '', 'success');
        this.loader.hide();
        /* istanbul ignore next */
        let returnedValue = betMapper(type, res);
        this.cloneResponse.next(returnedValue);
      });
  }

  resetClone() {
    this.cloneResponse.next('reset');
  }

  fetchCloneData(res: any) {
    this.getCloneDataSubject.next(res);
  }

  setAttibuteInfo(attributeName: string, attdisplayname: string, entity: any, attrIndex: any, attributeId: any) {
    this.attributeName.next({
      name: attributeName,
      entity: entity,
      attrIndex: attrIndex,
      attributeId: attributeId,
      displayName: attdisplayname,
    });
  }

  onEditEntityFromCu(data: any) {
    this.editEntityFromCu.next(data);
  }

  setEntityCCD(status: any) {
    this.entityCCD.next(status);
  }

  onCreateEntityFromGsi(data: any) {
    this.createEntityFromGsi.next(data);
  }
  // sets isFromDialogBox variable to true or false to identify if the call is coming from related bets dialog box
  setFlag(value: boolean) {
    this.isFromDialogBox = value;
  }
  // return isFromDialogBox
  getFlag() {
    return this.isFromDialogBox;
  }
  //sets previous cu id
  previousCuId(id: any) {
    this.previousCuID = id;
  }
  //returns previous cu id
  getPreviousCuId() {
    return this.previousCuID;
  }

  getAttributesByEntId(id: any, entity: any, index: any, layerType: any) {
    /* istanbul ignore next */
    if (id) {
      this.loader.show();
      /* istanbul ignore next */
      this.entityEndpoint
        .loadAttributesByEntId(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.loader.hide();
          /* istanbul ignore next */
          let entInfo = entityMapper(res?.result);
          /* istanbul ignore next */
          this.entityNslAttributes.next({ result: entInfo, oldEntity: entity, index: index, layerType: layerType });
        });
    }
  }
  validateEntityName(tenantName: any, entityName: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.entityEndpoint
      .validateEntityName(tenantName, entityName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res) {
            this.alertService.showToaster('Entity already exists', '', 'error');
          }
        },
        (error: any) => {
          this.validatedEntityName.next(true);
        }
      );
  }
  sendBETDataToTrain(obj: any) {
    this.betDataToTrain.next(obj);
  }

  ARCUImageSave(image: any) {
    this.ARCUImage.next(image);
  }
  getTriggerCuAttrUiElement(data: any, demapped?: boolean) {
    this.entityEndpoint
      .getTriggerCuAttrUiElement(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        let triggerCu: any = !demapped ? triggerCuMapper(res?.result) : res?.result;
        this.triggerCuAttrUiElement.next(triggerCu);
      });
  }
  triggerCuAttrdata(data: any) {
    this.triggerCuAttrData.next(data);
  }
  getAttributeComparisonData(data: any) {
    this.entityEndpoint
      .getAttributeComparisonData(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.AttributeComparisonData.next(res);
      });
  }
  fetchIndependentEnt(id: any) {
    /* istanbul ignore next */
    if (id) {
      /* istanbul ignore next */
      this.entityEndpoint
        .loadAttributesByEntId(id, false, false)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          /* istanbul ignore next */
          if (res) {
            this.independentEntityData.next(entityMapper(res.result));
          }
        });
    }
  }

  fetchEntityByName(tenantName: any, entityName: any) {
    /* istanbul ignore next */
    return this.entityEndpoint.validateEntityName(tenantName, entityName);
  }
  fetchAttributesDetails(id: any) {
    return this.entityEndpoint.loadAttributesByEntId(id);
  }
  searchEntityBasedCriteria(payload: any, pagenumber: number, pagesize: number) {
    return this.entityEndpoint.searchEntityBasedCriteria(payload, pagenumber, pagesize);
  }
  getEntityIndexData(pageNo: number, pageSize: number, entitySearchText: any) {
    return this.entityEndpoint.getEntityIndexData(pageNo, pageSize, entitySearchText);
  }

  getIndexData(entityName: any) {
    return this.entityEndpoint.getIndexData(entityName);
  }

  getAttributesOfEntity(tenantId: any, entityName: any) {
    return this.entityEndpoint.getAttributesOfEntity(tenantId, entityName);
  }

  deleteEntityIndex(entityName: string, indexList: string[]) {
    return this.entityEndpoint.deleteEntityIndex(entityName, indexList);
  }

  createEntityIndex(createIndexObject: any, entityName: string) {
    return this.entityEndpoint.createEntityIndex([createIndexObject], entityName);
  }

  getUiControlsForAttribute(attributeNamesArray: string[]) {
    return this.entityEndpoint.getUiControlsForAttribute(attributeNamesArray);
  }
  setUiControlData(data: any) {
    this.uiControlData = data;
  }
  downloadGeData(payload: any) {
    return this.entityEndpoint.downloadGeData(payload);
  }

  //for Entity upgrade in other BETs
  getAllCuWithArchivedEntity(entityId: any) {
    /* istanbul ignore next */
    if (entityId) {
      this.loader.show();
      /* istanbul ignore next */
      this.entityEndpoint
        .getAllCuWithArchivedEntity(entityId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if ((res && res?.status == 200) || (202 && res?.result)) {
            /* istanbul ignore next */
            this.cuListWithArchivedEntity.next(res?.result);
          }
        });
    }
  }

  getAllGsiWithArchivedEntity(cuId: any) {
    /* istanbul ignore next */
    if (cuId) {
      this.loader.show();
      /* istanbul ignore next */
      this.entityEndpoint
        .getAllGsiWithArchivedEntity(cuId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if ((res && res?.status == 200) || (202 && res?.result)) {
            /* istanbul ignore next */
            this.gsiListWithArchivedEntity.next(res?.result);
          }
        });
    }
  }

  getAllEligibleBetsWithGsi(gsiId: any, isReserved: boolean) {
    /* istanbul ignore next */
    if (gsiId) {
      this.loader.show();
      /* istanbul ignore next */
      this.entityEndpoint
        .getAllEligibleBetsWithGsi(gsiId, isReserved)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if ((res && res?.status == 200) || (202 && res?.result)) {
            /* istanbul ignore next */
            this.eligibleBETList.next(res?.result);
          }
        });
    }
  }

  upgradeGsi(gsi: any) {
    /* istanbul ignore next */
    if (gsi) {
      this.loader.show();
      /* istanbul ignore next */
      this.entityEndpoint
        .upgradeGsi(gsi)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if ((res && res?.status == 200) || (202 && res?.result)) {
            /* istanbul ignore next */
            this.upgradedGsi.next(res?.result);
          }
        });
    }
  }

  saveUpgradedGsi(gsi: any) {
    /* istanbul ignore next */
    if (gsi) {
      this.loader.show();
      /* istanbul ignore next */
      this.entityEndpoint
        .saveUpgradedGsi(gsi)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if ((res && res?.status == 200) || 202) {
            /* istanbul ignore next */
            this.savedGsi.next(res?.message);
          }
        });
    }
  }

  getCurrencyJson() {
    return this.entityEndpoint.getCurrencyJson();
  }
}
