import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SolutionDictionaryFacadeService } from '../../solution-dictionary/solution-dictionary-facade.service';
import { TranslatorService } from '../../translator/translator.service';
import { RolesFacadeService } from '../../roles/roles-facade.service';

export interface viewroletable {
  roleid: string;
  rolename: string;
  resolution: string;
}

@Component({
  selector: 'app-view-roles',
  templateUrl: './view-roles.component.html',
  styleUrls: ['./view-roles.component.scss'],
})
export class ViewRoles implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();
  displayedColumns: string[] = ['roleid', 'rolename', 'resolution'];
  dataSource = new MatTableDataSource();
  @Input() roles: any;
  @Input() rolesConflicted: any;
  @Input() messages: any;
  @Input() allRoles: any;
  @Input() rolesCount: any;
  @Input() userRolesList: any[];
  @Output() noOfRolesEmiter: EventEmitter<any> = new EventEmitter();
  @Input() isDsdRhs: boolean;
  @Input() saasapplication: boolean;
  labels: any;
  viewMsg: any;
  isViewMsg: boolean = false;
  NoOfRolesConflictedIndividually = {
    mapped: 0,
    merged: 0,
    renamed: 0,
  };
  betType: any;
  constructor(
    private router: Router,
    private translator: TranslatorService,
    private dialogRef: MatDialog,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private rolesFacadeService: RolesFacadeService
  ) {
    this.detectLanguageChange();
    this.getBetType();
  }

  moveToNextTab() {
    // this.betConfiguration.changeTabIndex(2);
    this.solutionDictionaryFacade.setChangeTabIndex(2);
  }

  cancel() {
    if (this.isDsdRhs) {
      this.dialogRef.closeAll();
    } else {
      if (this.betType === 'gsiImport') {
        this.rolesFacadeService.closeDialog(true);
        this.router.navigateByUrl('/admin/importsolution');
      } else {
        this.router.navigateByUrl('/solution-dictionary');
      }
      this.dialogRef.closeAll();
    }
    this.solutionDictionaryFacade.closepopupDataService();
  }

  getBetType() {
    this.solutionDictionaryFacade.betType$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.betType = res;
      }
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnInit(): void {
    // var viewindex = this.rolesConflicted?.findIndex((x: any) => x.isConflictResolved == true);
    /* istanbul ignore next */
    if (this.rolesConflicted?.length <= 0) {
      this.viewMsg = 'No Roles';
      this.isViewMsg = true;
    }
    this.viewRolesData();
    this.getNumberOfRolesRenamedAndMapedAndMergerged();
    this.noOfRolesEmiter.emit(this.NoOfRolesConflictedIndividually);
  }
  viewRolesData() {
    var Elementdata: viewroletable[] = [];
    /* istanbul ignore next */
    this.rolesConflicted?.forEach((ele: any, ind: number) => {
      if (this.rolesConflicted[ind].message) {
        Elementdata.push({
          roleid: this.rolesConflicted[ind].id,
          rolename: this.rolesConflicted[ind].targetValue
            ? this.rolesConflicted[ind].targetValue
            : this.rolesConflicted[ind].sourceValue,
          resolution: this.rolesConflicted[ind].message,
        });
      } else if (this.rolesConflicted[ind].message === '' && this.rolesConflicted[ind].conflictAction === 'SKIP') {
        Elementdata.push({
          roleid: this.rolesConflicted[ind].id,
          rolename: this.rolesConflicted[ind].targetValue
            ? this.rolesConflicted[ind].targetValue
            : this.rolesConflicted[ind].sourceValue,
          resolution: 'Previously Installed',
        });
      } else {
        Elementdata.push({
          roleid: this.rolesConflicted[ind].id,
          rolename: this.rolesConflicted[ind].targetValue
            ? this.rolesConflicted[ind].targetValue
            : this.rolesConflicted[ind].sourceValue,
          resolution: '',
        });
      }
    });
    this.dataSource.data = Elementdata;
  }

  getNumberOfRolesRenamedAndMapedAndMergerged() {
    this.rolesConflicted?.forEach((ele: any) => {
      /* istanbul ignore next */
      if (ele?.conflictAction === 'MAP') {
        this.NoOfRolesConflictedIndividually.mapped++;
      }
      /* istanbul ignore next */
      if (ele?.conflictAction === 'MERGE') {
        this.NoOfRolesConflictedIndividually.merged++;
      }
      /* istanbul ignore next */
      if (ele?.conflictAction === 'RENAME') {
        this.NoOfRolesConflictedIndividually.renamed++;
      }
    });
  }
}
