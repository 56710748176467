<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeGsi()">
        {{ labels?.close }}
      </button>
    </div>
    <div class="heading-area">
      <h2>{{labels?.Solution}}</h2>
      <button class="save" (click)="saveGsi()">{{labels?.Save}}</button>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{labels?.Solution_Details}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{labels?.Solution_Name}}</mat-label>
            <input id="readonlyName" matInput placeholder="Change unit name" [(ngModel)]="tempGsiObj.name" readonly />
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{labels?.Solution_Display_Name}}</mat-label>
            <input matInput placeholder="Solution Display Name" [(ngModel)]="tempGsiObj.displayName" />
          </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Permissions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="assign-rights">
                <ul>
                  <li class="roles-li" *ngFor="let role of roles; let i = index">
                    <div class="actions-item">
                      <button>
                        {{ role.name }}
                      </button>
                      <div id="cuRoleshovered">
                        <span class="menu-hover">
                          <button (click)="showRights(i)">
                            <figure>
                              <img [src]="'/assets/img/edit-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Edit }}</span>
                          </button>
                          <!-- <button>
                            <figure>
                              <img [src]="'/assets/img/info-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Information }}</span>
                          </button> -->
                          <button (click)="removeAgent(i)">
                            <figure>
                              <img [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Delete }}</span>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div *ngIf="selectedindex == i" class="read-right-actions">
                      <div>
                        <label>{{ labels?.Solution_Design }}</label>
                        <ul class="sub-assign customCheck">
                          <li id="design-rights">
                            <mat-checkbox
                              id="design-rights-read"
                              [(ngModel)]="
                                tempGsiObj.designTimeRights &&
                                tempGsiObj.designTimeRights.length > 0 &&
                                tempGsiObj.designTimeRights[getDesignRightsIndex(role)] &&
                                tempGsiObj.designTimeRights[getDesignRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}
                            </mat-checkbox>
                            <mat-checkbox
                              id="design-rights-write"
                              [(ngModel)]="
                                tempGsiObj.designTimeRights &&
                                tempGsiObj.designTimeRights.length > 0 &&
                                tempGsiObj.designTimeRights[getDesignRightsIndex(role)] &&
                                tempGsiObj.designTimeRights[getDesignRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(tempGsiObj.designTimeRights[getDesignRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li id="transaction-rights">
                            <mat-checkbox
                              id="transaction-rights-read"
                              [(ngModel)]="
                                tempGsiObj.txnTimeRights &&
                                tempGsiObj.txnTimeRights.length > 0 &&
                                tempGsiObj.txnTimeRights[getTxnRightsIndex(role)] &&
                                tempGsiObj.txnTimeRights[getTxnRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              id="transaction-rights-write"
                              [(ngModel)]="
                                tempGsiObj.txnTimeRights &&
                                tempGsiObj.txnTimeRights.length > 0 &&
                                tempGsiObj.txnTimeRights[getTxnRightsIndex(role)] &&
                                tempGsiObj.txnTimeRights[getTxnRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(tempGsiObj.txnTimeRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                            <mat-checkbox
                              id="transaction-rights-execute"
                              [(ngModel)]="
                                tempGsiObj.txnTimeRights &&
                                tempGsiObj.txnTimeRights.length > 0 &&
                                tempGsiObj.txnTimeRights[getTxnRightsIndex(role)] &&
                                tempGsiObj.txnTimeRights[getTxnRightsIndex(role)].executionRight
                              "
                              (change)="autoCheckRights(tempGsiObj.txnTimeRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Execute }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="configurationData?.cuType == 'Recursive' || recursiveGSI">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Recursive_Conditions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <span *ngIf="tempGsiObj?.constraintName">{{ tempGsiObj?.constraintName | dcd }}</span>
              <div class="no-label w-75">
                <mat-label>{{ labels?.Select_Recursive_Conditions }}</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select [(ngModel)]="recursiveOperator">
                    <mat-option *ngFor="let opt of operators" [value]="opt">{{ opt }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="enter-value one">
                <mat-form-field>
                  <span class="mr-2" *ngIf="constraintName">{{ constraintName | dcd }}</span>
                  <span class="mr-2" *ngIf="recursiveOperator">{{ recursiveOperator }}</span>
                  <input matInput placeholder="Enter Value" [(ngModel)]="conditionValue" />
                </mat-form-field>
                <span (click)="removeConstraint()"><mat-icon>delete</mat-icon></span>
              </div>
              <div class="no-label w-75">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Solution </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                    <mat-expansion-panel *ngIf="physicalLayer?.length > 0" id="accordianExpansionPhysical_cuRecursive">
                      <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                        <mat-panel-title class="active-bold">
                          <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-node-expand-layers
                        [layerData]="physicalLayer"
                        [tempGsiObj]="tempGsiObj"
                        [cuName]="configurationData?.data?.name"
                        [configurationData]="configurationData"
                        [layerLabel]="labelForPhyscialLayer"
                        [isRecursiveFlag]="true"
                      >
                      </app-node-expand-layers>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                    <mat-expansion-panel *ngIf="informationLayer?.length > 0" id="expansionPanel_GSIRecursive">
                      <mat-expansion-panel-header>
                        <mat-panel-title class="active-bold">
                          <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <app-node-expand-layers
                        [layerData]="informationLayer"
                        [tempGsiObj]="tempGsiObj"
                        [cuName]="configurationData?.data?.name"
                        [configurationData]="configurationData"
                        [layerLabel]="labelForInformationLayer"
                        [isRecursiveFlag]="true"
                      >
                      </app-node-expand-layers>
                    </mat-expansion-panel>
                  </mat-accordion>
                </mat-expansion-panel>

                <!--cus-->
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{ labels?.Change_Units }}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel *ngFor="let recursiveCU of recursiveCUList">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span id="{{ recursiveCU?.name | stringReplace }}">{{ recursiveCU?.name }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                      <mat-expansion-panel
                        *ngIf="cuPhysicalLayer?.length > 0"
                        id="accordianExpansionPhysical_cuRecursive"
                      >
                        <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                          <mat-panel-title class="active-bold">
                            <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }}</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-node-expand-layers
                          [layerData]="cuPhysicalLayer"
                          [tempGsiObj]="tempGsiObj"
                          [cuName]="configurationData?.data?.name"
                          [configurationData]="configurationData"
                          [layerLabel]="labelForPhyscialLayer"
                          [isRecursiveFlag]="true"
                          [currentCUName]="recursiveCU?.name"
                        >
                        </app-node-expand-layers>
                      </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                      <mat-expansion-panel *ngIf="cuInformationLayer?.length > 0" id="expansionPanel_GSIRecursive">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="active-bold">
                            <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-node-expand-layers
                          [layerData]="cuInformationLayer"
                          [tempGsiObj]="tempGsiObj"
                          [cuName]="configurationData?.data?.name"
                          [configurationData]="configurationData"
                          [layerLabel]="labelForInformationLayer"
                          [isRecursiveFlag]="true"
                          [currentCUName]="recursiveCU?.name"
                        >
                        </app-node-expand-layers>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-expansion-panel>
                </mat-expansion-panel>
              </div>
              <div class="add-more-condition">
                <button type="button" class="save" (click)="addCondition()">
                  {{ labels?.save }}
                </button>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel id="mat_panel_metadata">
              <mat-expansion-panel-header id="mat_panel_header_metadata">
                <mat-panel-title id="mat_panel_titel_metadata">
                  {{labels?.Metadata}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let item of ontologyList; let i = index">
                <mat-form-field appearance="fill">
                  <mat-label>{{labels?.Select_Ontology}}</mat-label>
                  <mat-select
                    name="item.name"
                    [(ngModel)]="item.id"
                    panelClass="select-panel"
                    id="choosecategory{{ i }}"
                    [placeholder]="i == 0 ? labels?.Choose_Category : labels?.Choose_SubCategory"
                    (selectionChange)="onChangeOntology(item.id, i)"
                  >
                    <mat-form-field class="search-select" floatLabel="never">
                      <input
                        matInput
                        type="text"
                        id="parentontologyInput"
                        class="search-input-cls"
                        [(ngModel)]="item.searchText"
                        [ngModelOptions]="{ standalone: true }"
                        [placeholder]="labels?.Search"
                      />
                      <button
                        mat-button
                        *ngIf="item.searchText"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="onClearClick(item)"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-option>{{ labels?.None }}</mat-option>
                    <mat-option
                      *ngFor="let ontology of item.children | filter: item.searchText"
                      [value]="ontology"
                      id="{{ 'ontology_' + ontology.displayName }}"
                    >
                      {{ ontology?.displayName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-auto">
                <button mat-icon-button (click)="onClickActionIcons()" id="addsubcategory">
                  <mat-icon>add</mat-icon>
                  {{ labels?.Add_Sub_Category }}
                </button>
              </div>
              <mat-form-field class="description-textarea">
                <textarea
                  matInput
                  rows="5"
                  [(ngModel)]="metaInfo.description"
                  placeholder="Description Text"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="description-textarea" id="keyword-ontology">
                <textarea matInput rows="1" [placeholder]="labels?.Keywords" [(ngModel)]="metaInfo.keywords"></textarea>
              </mat-form-field>
            </mat-expansion-panel>
            <mat-expansion-panel id="mat_panel_record_additional-info">
              <mat-expansion-panel-header id="mat_panel_header_record_additional-info">
                <mat-panel-title id="mat_panel_titel_record_additional-info">
                  {{labels?.Record_Additional_Information}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="cal-height" id="mat_panel_toggle_record_additional-info">
                <label>{{labels?.Disable}}</label>
                <mat-checkbox [(ngModel)]="disableSTEs" class="example-margin" (click)="disableSTEGSI()"></mat-checkbox>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="bottom-area">
      <h3>{{ labels?.Recursive }}</h3>
      <mat-slide-toggle disableRipple [(ngModel)]="recursiveGSI"></mat-slide-toggle>
    </div>
    <div class="bottom-area">
      <mat-checkbox [(ngModel)]="tempGsiObj.allowPreviouCUView">
        {{labels?.Show_previous_CU}}
      </mat-checkbox>
    </div>
  </div>
</div>
