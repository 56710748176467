import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './toaster.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AlertService } from '../toaster/alert.service';



@NgModule({
  declarations: [ToasterComponent],
  imports: [
    CommonModule,
  ],
  providers: [AlertService,ToastrService],
  exports: [ToasterComponent],
})
export class ToasterModule { }