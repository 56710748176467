import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { TextareaComponent } from './textarea.component';

@NgModule({
  declarations: [TextareaComponent],
  imports: [ControlsMaterialModule],
  exports: [TextareaComponent],
})
export class TextareaModule {
  static entry = {
    textarea: TextareaComponent,
  };
}
