import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { GsiFacadeService, TranslatorService } from '@common-services';

//Component which shows the list of impacted BETs
@Component({
  selector: 'app-other-solution-dialog',
  templateUrl: './other-solution-dialog.component.html',
  styleUrls: ['./other-solution-dialog.component.scss'],
})
export class OtherSolutionDialogComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  relatedBetsData: any = []; //stores version data
  treeSelector: TreeNode[] = [];

  selectedFiles: TreeNode[] = [];
  dataArray: string[] = [];
  title: any = '';
  labels: any;

  treeItems: TreeNode[] = [];

  constructor(
    public dialogotherRef: MatDialogRef<OtherSolutionDialogComponent>,
    public dialog: MatDialog,
    private translator: TranslatorService,
    private gsiFacadeService: GsiFacadeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initializeTree();
    this.detectLanguageChange();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  //builds the structure of tree
  initializeTree() {
    if (this.data) {
      /*istanbul ignore next */
      this.relatedBetsData = this.data.versionData ? this.data.versionData : [];
      /*istanbul ignore next */
      this.title = this.data?.type;
      /*istanbul ignore next */
      this.relatedBetsData?.forEach((item: any) => {
        let files: TreeNode;
        files = {
          label: '',
          data: {
            type: '',
            id: '',
          },
          icon: 'assets/img/gsi-dot-icon.svg',
          collapsedIcon: 'fa-folder',
          expandedIcon: 'fa-folder-open',
          parent: null,
          children: null,
        };
        files.label = item.name;
        files.data.id = item.id;
        (files.icon = item.entityType == 'GSI' ? 'assets/img/icon-bulb-yellow.svg' : 'assets/img/gsi-dot-icon.svg'),
          (files.data.type = item.entityType);
        files.children = [];
        this.treeItems.push(files);
      });
    }
  }

  ngOnInit(): void {}

  //stores BET data in the tree structure
  loadBETdata(children: any, relatedBetsData: any) {
    /*istanbul ignore next */
    relatedBetsData?.forEach((item: any) => {
      let files: TreeNode;
      files = {
        label: '',
        data: {
          type: '',
          id: '',
        },
        collapsedIcon: 'fa-folder',
        expandedIcon: 'fa-folder-open',
        parent: null,
        children: null,
      };
      files.label = item.name;
      files.data.id = item.id;
      files.data.type = item.entityType;
      files.children = [];
      children.push(files);
    });
  }

  /**
   * Nodes select. Gets the books based upon the selected role.
   * @param event
   * @param type
   */
  nodeSelect(event: any) {
    this.selectedFiles = event.node;
    /*istanbul ignore else */
    if (event.node.children.length === 0) {
      /*istanbul ignore next */
      this.gsiFacadeService
        .loadSingleLevelDetailsById(event.node?.data.id, event.node?.data.type)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          /*istanbul ignore else */
          if (res && res.result) {
            this.loadBETdata(event.node.children, res.result);
          }
        });
    }
  }

  /**
   * Nodes unselect
   * @param event
   * @param type
   */
  nodeUnselect(event: any) {
    this.selectedFiles = event.node;
  }
  //closes the dialog Box
  close() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
