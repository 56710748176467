<div id="image-container" [ngClass]="plusCursor ? 'crosshair' : ''">
    <div [ngSwitch]="fileType">
      <ng-container *ngSwitchCase="'image'">
        <div [ngClass]="expanded ? 'popup-scroll' : 'pop-img'" (scroll)="onScroll($event)">
          <img [src]="src" class="popimg abc" #imagemapping alt="" draggable="false" />
        </div>
      </ng-container>
  
      <ng-container *ngSwitchCase="'pdf'">
        <div *ngIf="refreshPage" [ngClass]="expanded ? 'popup-scroll' : 'pop-img'" (scroll)="onScroll($event)">
          <pdf-viewer
            [show-all]="false"
            [ngClass]="expanded ? 'ocr-pdf' : ''"
            [src]="src"
            [render-text]="true"
            [page]="pageNumber"
            [fit-to-page]="true"
            [original-size]="false"
            style="display: block;"
            id="pdfMapping"
            [ngStyle]="!expanded ? getHeight() : ''"
            (pageChange)="pageChange($event)"
            (page-rendered)="pageRendered($event)"
            (after-load-complete)="afterLoadComplete($event)"
          ></pdf-viewer>
          <div class="pagination-style">
            <app-paginator
              class="d-flex"
              [pageIndex]="pageNumber - 1"
              [pageSize]="1"
              [totalRecords]="totalPages"
              (onPageChange)="changePage($event)"
              *ngIf="fileType === 'pdf' && totalPages > 1"
            >
            </app-paginator>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!fileType && src">
      <img [src]="src" class="popimg xyz" #imagemapping alt="" />
    </ng-container>
  
    <!-- <embed class="img-fluid" [src]="src | safeResourceUrl" #imagemapping /> -->
  
    <div class="coordinates-container position-static" *ngIf="refreshPage">
      <ng-container *ngIf="newCoordinatesByPageNumber && !component">
        <ng-container *ngFor="let c of newCoordinatesByPageNumber; let i = index">
          <div class="area" [ngStyle]="getCoordinateStyle(c)">
            <span class="material-icons" (click)="resetBox()">
              cancel
            </span>
            <span (click)="onSubmit()" class="material-icons done-icon">
              done
            </span>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let coordinate of coordinatesByPAgeNumber; let j = index">
        <ng-container *ngFor="let objectNodes of coordinate | keyvalue">
          <div
            [hidden]="!(objectNodes.key === 'attribute_name_box')"
            id="attribute_name_box_{{ coordinate[objectNodes.key].height }}_{{ coordinate[objectNodes.key].width }}"
            class="area"
            [ngStyle]="getCoordinateStyle(coordinate[objectNodes.key], 'attribute_name_box')"
            (click)="onAreaClick(coordinate)"
          ></div>
          <div
            id="attribute_value_box_{{ coordinate[objectNodes.key].height }}_{{ coordinate[objectNodes.key].width }}"
            [hidden]="!(objectNodes.key === 'attribute_value_box' && coordinate.isTable === 'False')"
            class="newarea"
            [ngStyle]="getCoordinateStyle(coordinate[objectNodes.key], 'attribute_value_box')"
            (click)="onAreaClick(coordinate)"
          ></div>
          <div
            [hidden]="!(objectNodes.key === 'attribute_value_box' && coordinate.isTable === 'True')"
            class="table"
            [ngStyle]="getCoordinateStyle(coordinate[objectNodes.key])"
            (click)="onAreaClick(coordinate)"
          ></div>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="startingCoordinate != undefined" class="coordinates-container position-static">
      <div class="newarea" [ngStyle]="viewManualMapping(startingCoordinate)"></div>
    </ng-container>
    <ng-container *ngIf="selectedCoordinate != undefined" class="coordinates-container position-static">
      <div class="selectedarea" [ngStyle]="viewManualMapping(selectedCoordinate)">
        <button class="btn btn-danger btn-map" (click)="manualMappedAttribute()">Map</button>
      </div>
    </ng-container>
    <div class="icon-close" *ngIf="src">
      <span class="material-icons ml-3" (click)="closeImage(myDialog)">highlight_off</span>
    </div>
  </div>
  
  <ng-template #myDialog>
    <div class="primary-object">
      <div class="heading">
        <h4>{{ labels?.Delete_Document }}</h4>
        <br />
      </div>
      <div class="data-style">
        <p>{{ confirmDeleteData }}</p>
      </div>
      <!-- <div class="add-btn" (click)="onDeleteClick()"><button mat-raised-button>Confirm</button></div>
      <div class="add-btn" (click)="onCancelClick()"><button mat-raised-button>Cancel</button></div> -->
      <mat-dialog-actions class="delete-user-btns">
        <button mat-raised-button (click)="onDeleteClick()">{{ labels?.Confirm }}</button>
        <button mat-raised-button (click)="onCancelClick()">{{ labels?.Cancel }}</button>
      </mat-dialog-actions>
    </div>
  </ng-template>
  