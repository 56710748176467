<div class="viewroles-wrap">
  <h5>{{ labels?.View_All_Roles }}</h5>
  <table mat-table [dataSource]="dataSource" class="viewroles-table" *ngIf="isViewMsg === false" aria-describedby="">
    <ng-container matColumnDef="roleid">
      <th mat-header-cell *matHeaderCellDef id="">{{ labels?.Role_Id }}</th>
      <td mat-cell *matCellDef="let element">{{ element.roleid }}</td>
    </ng-container>

    <ng-container matColumnDef="rolename">
      <th mat-header-cell *matHeaderCellDef id="">{{ labels?.Role }}</th>
      <td mat-cell *matCellDef="let element">{{ element.rolename }}</td>
    </ng-container>

    <ng-container matColumnDef="resolution">
      <th mat-header-cell *matHeaderCellDef id="">{{ labels?.Resolution }}</th>
      <td mat-cell *matCellDef="let element">{{ element.resolution }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div *ngIf="isViewMsg === true">{{ viewMsg }}</div>
  <div class="configure-btns btns-custom">
    <button class="btn btn-next btn-active" (click)="moveToNextTab()">{{ labels?.Next }}</button>
    <button class="btn btn-cancel" (click)="cancel()">{{ labels?.CANCEL }}</button>
  </div>
</div>
