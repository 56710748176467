import {
  Directive,
  Input,
  ComponentFactoryResolver,
  ViewContainerRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { FieldConfig, IsJsonString, TransactionFacadeService } from '@common-services';
import { FormGroup } from '@angular/forms';
import { componentMapper } from 'ui-controls'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit {
  field = {} as FieldConfig;
  @Input() formData: any;
  @Input() group: FormGroup;
  @Input() horizontalOptions = false;
  @Input() isTableConfig = false;
  @Input() tableUiStyle = false;
  @Input() isOnSelectSubmit = false;
  @Input() hideLabels: boolean;
  @Input() hideOptionLabels: boolean;
  @Output() addmoreAttributes: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAttributes: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() isDisabledButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() chipDataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() wayPointsLocationData: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickableOnSelect: EventEmitter<any> = new EventEmitter<any>();
  private unsubscribe$ = new Subject<any>();
  currentCu: any;


  componentRef: any;
  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef, public transactionfacadeService: TransactionFacadeService,) {
    this.transactionfacadeService.currentCuDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
          this.currentCu = res?.result?.currentCU;

    });
  }

  ngOnInit() {
    if (!this.isTableConfig) {
      this.field.type =
        this.formData.value.inputType !== 'scormProgress'
          ? /* istanbul ignore next */
            this.formData.value.inputType?.toLocaleLowerCase()
          : /* istanbul ignore next */
            this.formData.value.inputType;

      this.field.attribute = {
        id: this.formData.value.attr_id,
        name: this.formData.value.label,
        isTableConfig: this.isTableConfig,
        displayName: this.formData.value.attr_displayName,
      };
      this.field.isCardConfig = this.formData.value.isCardConfig;
      this.field.isOnSelectSubmit = this.isOnSelectSubmit;
      this.field.attrTxnRecordId = this.formData.value.attrTxnRecordId;
      this.field.id = this.formData.value.attr_id;
      this.field.validations = this.formData.value.validations;
      this.field.optionalValidation = this.formData.value.optionalValidation;
      this.field.inputType = this.formData.value.inputType;
      this.field.labelType = this.formData.value.labelType;
      this.field.sentence = this.formData.value.sentence;
      this.field.attrInstanceId = this.formData.value.attrInstanceId;
      /* istanbul ignore next */
      this.field.label = this.formData.value.attr_displayName
        ? this.formData.value.attr_displayName
        : /* istanbul ignore next */
          this.formData.value.label?.split('$')[0];
      /* istanbul ignore next */
      this.field.name = this.formData.value.label?.split('$')[0];
      this.field.attributeType = this.formData.value.attributeType;
      this.field.displayName = this.formData.value.attr_displayName;
      this.field.color = this.formData.value.attr_color;
      this.field.fontSize = this.formData.value.attr_fontSize;
      this.field.options = this.formData.value.attr_options;
      this.field.isMulti = this.formData.value.isMulti;
      this.field.isPlaceholder = this.formData.value.isPlaceholder;
      this.field.value = this.formData.value.value;
      this.field.isInfo = this.formData.value.isInfo;
      this.field.isInPotentiality = this.formData.value.isInPotentiality;
      this.field.isReadOnly = this.formData.value.isReadOnly;
      this.field.txnRecordId = this.formData.value.entity_txnRecordId;
      this.field.ent_index = this.formData.value.ent_index;
      this.field.canDownload = this.formData.value.canDownload;
      /* istanbul ignore next */
      this.field.isHidden = this.formData?.value?.isHidden; // FE757 - For Hidden Attribute - Attribute level
      this.field.isDisabled = this.formData.value.isDisabled;
      this.field.hideLabelMultiAttr = this.formData?.value?.hideLabelMultiAttr;
      this.field.hideMultiValueIcon = this.formData?.value?.hideMultiValueIcon;
      this.field.attrClass = this.formData?.value?.attrClass;
      if (this.formData.value.isDisabled) {
        this.field.readonly = this.formData.value.isDisabled;
      } else {
        /* istanbul ignore next */
        this.field.readonly = this.formData.value?.isNegative;
      }
      this.field.triggerConditionalPotentiality = this.formData.value.triggerConditionalPotentiality;
      this.field.isMultiEntity = this.formData?.value?.isMultiEntity;
      this.field.isRequired = this.formData.value?.isNegative
        ? /* istanbul ignore next */
          !this.formData.value?.isNegative
        : /* istanbul ignore next */
          !this.formData.value?.isOptional;
      // optional change drivers related flags starts //
      /* istanbul ignore next */
      this.field.isOptional = this.formData.value?.isOptional;
      /* istanbul ignore next */
      this.field.isNegative = this.formData.value?.isNegative;
      /* istanbul ignore next */
      this.field.alternativeChangeDriver = this.formData.value?.alternativeChangeDriver;
      // optional change drivers related flags ends //,
      this.field.entityName = this.formData.value?.entityName;
      this.field.entityId = this.formData.value?.entityId;
      this.field.entityMasterId = this.formData.value?.entityMasterId;
      /* istanbul ignore next */
      this.field.slotNumber = this.formData.value?.slotNumber;
      this.field.nslAttributeProperties = this.formData.value?.nslAttributeProperties;
      this.field.nestedEntityName = this.formData?.value?.nestedEntityName
        ? this.formData?.value?.nestedEntityName
        : '';
      if (IsJsonString(this.formData?.value?.attr_configuration)) {
        this.field.configuration = JSON.parse(this.formData.value.attr_configuration);
      }
      if (IsJsonString(this.formData?.entity_configuration)) {
        this.field.entity_configuration = JSON.parse(this.formData.value.entity_configuration);
      }
    } else {
      /* istanbul ignore next */
      this.field.type =
        this.formData.inputType !== 'scormProgress'
          ? /* istanbul ignore next */
            this.formData.inputType?.toLocaleLowerCase()
          : this.formData.inputType;
      this.field.attribute = {
        id: this.formData.attr_id,
        name: this.formData.label,
        isTableConfig: this.isTableConfig,
        tableUiStyle: this.tableUiStyle,
        displayName: this.formData.attr_displayName,
      };
      this.field.id = this.formData.attr_id;
      this.field.color = this.formData.attr_color;
      this.field.fontSize = this.formData.attr_fontSize;
      this.field.validations = this.formData.validations;
      this.field.optionalValidation = this.formData.optionalValidation;
      this.field.inputType = this.formData.inputType;
      this.field.labelType = this.formData.labelType;
      this.field.attrClass = this.formData?.attrClass;
      this.field.isCardConfig = this.formData.isCardConfig;
      this.field.isOnSelectSubmit = this.isOnSelectSubmit;
      this.field.attrTxnRecordId = this.formData.attrTxnRecordId;
      /* istanbul ignore next */
      this.field.label = this.formData.attr_displayName
        ? this.formData.attr_displayName /* istanbul ignore next */
        : this.formData.label?.split('$')[0];
      /* istanbul ignore next */
      this.field.name = this.formData.label?.split('$')[0];
      this.field.attributeType = this.formData?.attributeType;
      this.field.displayName = this.formData.attr_displayName;
      this.field.attrInstanceId = this.formData.attrInstanceId;
      this.field.hideLabels = this.hideLabels;
      this.field.hideOptionLabels = this.hideOptionLabels;
      this.field.horizontalOptions = this.horizontalOptions;
      this.field.options = this.formData.attr_options;
      this.field.isMulti = this.formData.isMulti;
      this.field.txnRecordId = this.formData.entity_txnRecordId;
      this.field.ent_index = this.formData.ent_index;
      this.field.isPlaceholder = this.formData.isPlaceholder;
      this.field.value = this.formData.value;
      this.field.isInfo = this.formData.isInfo;
      this.field.isInPotentiality = this.formData.isInPotentiality;
      this.field.isReadOnly = this.formData.isReadOnly;
      /* istanbul ignore next */
      this.field.isHidden = this.formData?.isHidden; // FE757 - For Hidden Attribute - Attribute level
      this.field.isDisabled = this.formData.isDisabled;
      this.field.hideLabelMultiAttr = this.formData?.value?.hideLabelMultiAttr;
      this.field.nestedEntityName = this.formData?.value?.nestedEntityName
        ? this.formData?.value?.nestedEntityName
        : '';
      this.field.hideMultiValueIcon = this.formData?.value?.hideMultiValueIcon;
      if (this.formData.isDisabled) {
        this.field.readonly = this.formData.isDisabled;
      } else {
        /* istanbul ignore next */
        this.field.readonly = this.formData?.isNegative;
      }
      this.field.triggerConditionalPotentiality = this.formData.triggerConditionalPotentiality;
      this.field.isMultiEntity = this.formData?.value?.isMultiEntity;
      this.field.isRequired = this.formData?.isNegative ? !this.formData?.isNegative : !this.formData?.isOptional;
      // optional change drivers related flags starts //
      this.field.isOptional = this.formData?.isOptional;
      this.field.isNegative = this.formData?.isNegative;
      this.field.alternativeChangeDriver = this.formData?.alternativeChangeDriver;
      // optional change drivers related flags ends //
      this.field.entityName = this.formData?.entityName;
      this.field.entityId = this.formData?.entityId;
      this.field.entityMasterId = this.formData?.entityMasterId;
      this.field.slotNumber = this.formData?.slotNumber;
      this.field.canDownload = this.formData.canDownload;
      if (IsJsonString(this.formData?.attr_configuration)) {
        this.field.configuration = JSON.parse(this.formData.attr_configuration);
      }
      if (IsJsonString(this.formData?.entity_configuration)) {
        this.field.entity_configuration = JSON.parse(this.formData.entity_configuration);
      }
    }

    /* istanbul ignore next */
    if (this.field.type !== 'scormProgress') {
      if (this.field.isReadOnly == true) this.field.type = 'label';
      if (componentMapper[this.field.type]) {
        const factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);
        const componentRef = this.container.createComponent(factory);
        if (componentRef) {
          this.addOtherInfoToContainer(componentRef);
        }
      }
    }
    if(this.currentCu?.eventCUList)
    this.field.eventsOnSelects = this.currentCu.eventCUList.filter((event:any)=>event.changeDriverId == this.formData.attr_id && event.eventType == 'ON_SELECT' );

  }

  addOtherInfoToContainer(componentRef: any) {
    componentRef.instance.field = this.field;
    componentRef.instance.group = this.group;
    componentRef.instance.addAttribute?.subscribe((res: any) => {
      if (res === 'multiFileUpload') {
        this.addmoreAttributes.emit(res);
      } else {
        this.addmoreAttributes.emit();
      }
    });

    componentRef.instance.removeAttribute?.subscribe((res: any) => {
      this.removeAttributes.emit();
    });
    componentRef.instance.dateChanged?.subscribe((res: any) => {
      this.dateChanged.emit(res);
    });
    componentRef.instance.isDisabledButton?.subscribe((res: any) => {
      this.isDisabledButton.emit(res);
    });
    componentRef.instance.chipDataChanged?.subscribe((res: any) => {
      this.chipDataChanged.emit(res);
    });
    componentRef.instance.wayPointsLocationData?.subscribe((res: any) => {
      this.wayPointsLocationData.emit(res);
    });
    componentRef.instance.clickableOnSelect?.subscribe((res: any) => {
      this.clickableOnSelect.emit(res);
    });

  }
}
