import { AttributeType } from './attribute-type';

export interface Attribute {
  id?: number;
  name: string;
  botQuestion?: string;
  displayName: string;
  attributeClassification: string;
  attributeType: AttributeType;
  // isMultiValue: boolean;
  constraints: any[];
  dsdId: string;
  conditionalPotentiality?: any[];
  isReserved?: boolean;
  defaultValue: any;
  generalEntity: any;
  isNameUpdated?: boolean;
  nslAttributeMetaData?: {};
  source?: string;
  canDownload?: boolean;
  openUiPanel?: boolean;
  nslAttributeProperties?: {
    isTraceable: boolean;
  };
}
export class Attribute {
  constructor() {
    this.name = '';
    this.displayName = '';
    this.attributeType = {
      uiElement: { name: '', dataType: '', uiElement: '', isMulti: false },
      type: '',
      properties: {},
      nestedNslDataTypeProperties: { item_type: {} },
      extendedProperties: {
        sourceValues: [],
      },
    };
    this.nslAttributeProperties = {
      isTraceable: false,
    };
  }
}
