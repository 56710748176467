import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader.component';
import { LottieModule } from 'ngx-lottie';
import { CommonModule } from '@angular/common';

export function playerFactory() {
  // return player;
  return import('lottie-web');
}
@NgModule({
  declarations: [LoaderComponent],
  imports: [MatProgressSpinnerModule, CommonModule, LottieModule.forRoot({ player: playerFactory })],
  exports: [LoaderComponent],
})
export class LoaderModule {}
