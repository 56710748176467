import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-file',
  styleUrls: ['./file.component.scss'],
  template: `
    <div class="demo-full-width" [formGroup]="group" [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
          <label
            class="main-label nh-mb-0"
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            ></label
          >
          <p
            class="nh-ml-6 d-flex"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true' || field.isMulti"
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            matSuffix
            class="d-flex align-items-center justify-content-center"
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.readonly"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            (click)="addAttribute.next()"
            >add</mat-icon
          >
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.readonly && (field?.isOptional || field?.attrInstanceId != 0)"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            (click)="removeAttribute.next()"
            >remove</mat-icon
          >
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>

      <app-file-upload-transaction
        [formControlName]="field.attribute.name"
        id="{{ field.attribute.name }}"
        [fileUploadPath]="field.fileUploadPath"
        (deletedFileResponse)="deletedFileResponse($event)"
        [field]="field"
        (isMultiUpload)="multiple($event)"
      ></app-file-upload-transaction>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </div>
  `,
  styles: [],
})
export class FileComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void { }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  multiple(event: any) {
    if (event) this.addAttribute.next('multiFileUpload');
  }

  deletedFileResponse(event: any) {
    if (event) this.removeAttribute.next();
  }
}
