<div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data.extractionDialog" cdkFocusInitial>
      <img [src]=" '../../../../../assets/images/expand-less.svg' | CDNUrlRewritePipe " alt="" />
    </button>
  </div>
  <div mat-dialog-content>
    <div class="filepreview">
      <div *ngIf="data.type == 'image'">
        <img [src]="data.url" alt="" />
      </div>

      <div *ngIf="data.type == 'video'">
        <video [src]="data.url" controls>
          <track label="English" kind="captions" srclang="en" src="" default />
        </video>
      </div>

      <div *ngIf="data.type == 'diagram'">
        <app-bpmn-viewer
          style="width: 100%; height: 100%;"
          [xml]="data.raw"
          [showExportButtons]="false"
          [showDiagramButtons]="false"
        >
        </app-bpmn-viewer>
      </div>
    </div>
  </div>
</div>
