import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { pluck } from 'rxjs/operators';
import { FilterType, RelatedRecordPayload, getEnvClientId } from '@common-services';
import { ViewAndEditPayload } from '@common-services';
@Injectable({
  providedIn: 'root',
})

export class EntityBoardEndpointService extends CduiBaseService {
  filter: FormControl;
  droppedAttrExpand = new Subject();
  droppedAttrExpand$ = this.droppedAttrExpand.asObservable();

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  addSeqCu(gsiId: number, index: number, body: any) {
    const params = new HttpParams().set('index', index + '');
    return this.http.put(`${this.api.addSeqCu}/${gsiId}/triggerCU-Draft`, body, { params });
  }

  // Get all gsi list
  getAllGsis(id: any) {
    return this.http.get<any>(`${this.api.getAllGsisForgeBorad}${id}`);
  }

  // Save entitycard
  saveEntityCard(payload: any) {
    return this.http.post(this.api.saveEntityCard, payload);
  }
  /* istanbul ignore next */
  saveExpandedView(payload: any) {
    return this.http.post(this.api.saveExpandedView, payload);
  }

  sendDataToEntityCard(payload: any) {
    this.droppedAttrExpand.next(payload);
  }

  getAllreferenceEntities(searchCriteria: any, pagenum: any, pageSize: any, role: any) {
    const publisherId = '';
    const ontology = '';
    const isPublished = true;
    return this.http.get(
      this.api?.getAllEntities +
        `?query=${searchCriteria}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${pagenum}&limit=${pageSize}&isPublished=${isPublished}&userRight=TXN_DECISION_RIGHTS&rightHolderId=${role}&rightOwnerType=ROLE`
    );
  }

  getAllattributesById(id: any) {
    return this.http.get<any>(this.api?.getAttributeByEntityId + `/${id}?isReserved=false`);
  }
  getTemplatesByLayout(layoutType: string): Observable<any> {
    return this.http.get(`${this.api?.manageTemplatesByType}/${layoutType}?=DESKTOP`);
  }
  getTemplate(templateId: string): Observable<any> {
    return this.http.get(`${this.api?.manageTemplates}/${templateId}`);
  }

  saveSearch(payload: any) {
    return this.http.post(this.api?.postSearchCall, payload);
  }

  getAllSearches(cardId: string) {
    return this.http.get(`${this.api?.getAllSearchForEntityCard + cardId}`);
  }

  clearParticularSearch(cardId: string, searchValue: string) {
    return this.http.get(`${this.api?.clearParticularSearch + cardId + '/' + searchValue}`);
  }

  clearAllSearchRecords(cardId: string) {
    return this.http.get(`${this.api?.clearAllSearches + cardId}`);
  }

  /**
   * This function retrieves data from a specific API endpoint with certain search criteria and
   * pagination parameters.
   * @param {number} pageno - The page number of the data to be retrieved from the API.
   * @param {string} searchCriteria - The search criteria parameter is a string that is used to filter
   * the results of the API call. It is used to search for specific data based on certain keywords or
   * phrases.
   * @returns an HTTP GET request to a specific API endpoint with parameters such as page number,
   * search criteria, and user rights. The response from the API endpoint is not shown in the code
   * snippet, so it is unclear what is being returned from the function.
   */
  getAllGsisPaginated(pageno: number, searchCriteria: string) {
    /*istanbul ignore next*/
    let headers: any;
    return this.http.get(
      `${this.api?.getAllGsis}?isPublished=true&limit=10&ontology=&page=${pageno}&publisherIdOrName=&query=${searchCriteria}&userRights=EXECUTION_RIGHTS&designMode=false`,
      headers
    );
  }

  // Update milestone
  /* editMilestone(payload: any) {
    return this.http.put(api.updateMilestone, payload);
  } */

  // Delete Milestone
  /* deleteMilestone(id: any) {
    return this.http.delete(api.deleteMilestone + id);
  } */

  saveCartConfig(requestBody: any) {
    return this.http.post(this.api.saveCart, requestBody);
  }
  fetchAttrSearchData(data:any,afterKey:string) {
    return this.http.post(
      `${this.api.attrSearchV2}?afterKey=${afterKey}`,
     data
    );
  }
  uploadData(data: any) {
    /* istanbul ignore next */
    if (data) {
      return this.http.post(this.api?.singleFileUpload, data);
    }
  }
  /*istanbul ignore next*/
  getCartConfiguration(): Observable<any> {
    let client_id: string = getEnvClientId();
    return this.http.get(`${this.api?.getCartConfig + client_id}`);
  }
  /* istanbul ignore next */
  getCardByViewName(viewName: string) {
    return this.http.get(`${this.api?.getCardByViewName + viewName}`);
  }
  /* istanbul ignore next */
  getAllViewsByEntityName(entityName: string) {
    return this.http.get(`${this.api?.getEntityCardViews + entityName}`);
  }
  /* istanbul ignore next */
  getEntityRelatedAndExpandView(entityName: string, viewName: string, recordId: any, payLoad: RelatedRecordPayload) {
    return this.http.post(
      `${this.api?.getExpandedRecords + entityName + '/' + recordId + '/views/' + viewName}`,
      payLoad ? payLoad : {}
    );
  }
  /**
   * @description  This function is responsible for making an HTTP GET request for getting all GSIs
   * @param {number} pageSize
   * @param {number} pageNumber
   * @param {string} searchCriteria - string to search
   */
  getAllUnrelatedGsis(pageSize: number, pageNumber: number, searchCriteria: string) {
    /*istanbul ignore next*/
    return this.http.get(
      `${this.api?.getAllGsis}?isPublished=true&limit=${pageSize}&ontology=&page=${pageNumber}&publisherIdOrName=&query=${searchCriteria}&userRights=SOLUTIONS_INFO_RIGHTS&designMode=true`
    );
  }

  getAllConfigurationsByRoleAndEntityId(
    entityId: string,
    pageSize: number,
    pageNumber: number,
    roleId: string,
    searchTerm: string
  ) {
    return this.http.get<any>(
      `${this.api?.getAllConfigurationsByRoleAndEntityId}${entityId}?Device=DESKTOP&limit=${pageSize}&page=${pageNumber}&roleId=${roleId}&searchTerm=${searchTerm}`
    );
  }

  deleteEntityCardById(id: any) {
    return this.http.delete<any>(`${this.api?.deleteEntityCardById}${id}`);
  }
  getEntityCardDetails(id: any) {
    return this.http.get<any>(`${this.api?.getEntityCardData}${id}`);
  }
  addCommentToTheUser(payload: AddComment, generalEntityID: number) {
    return this.http.post(
      `${this.api?.commentsApi + generalEntityID}/records/${payload.recordId}/currentUser/ratings`,
      payload
    );
  }
  getCommentToTheUser(
    recordId: number,
    generalEntityID: number,
    lastEvaluatedKeyString: string,
    summary: boolean,
    sortBy: string,
    orderBy: string,
    keyword: string,
    rating: string
  ) {
    return this.http.get(
      `${
        this.api?.commentsApi + generalEntityID
      }/records/${recordId}/ratings?lastEvaluatedKeyString=${lastEvaluatedKeyString}&summary=${summary}&limit=10&orderBy=${orderBy}&sortBy=${sortBy}&keyword=${keyword}&productRating=${rating}`
    );
  }

  getCurrentUserRatings(recordId: number, generalEntityID: number) {
    return this.http.get(`${this.api?.commentsApi + generalEntityID}/records/${recordId}/currentUser/ratings`);
  }

  saveSharableFilter(payload: any, filterName: string, isFavorite: boolean) {
    /*istanbul ignore next*/
    return this.http.post(`${this.api?.sharableFilter}${filterName}?favorite=${isFavorite}`, payload);
  }

  getSharableFilters(
    cardId: string,
    getAll: boolean,
    favorite: boolean,
    recent: boolean,
    searchTerm: string,
    criteria: string,
    pageNumber: number,
    pageSize: number
  ) {
    /*istanbul ignore next*/
    return this.http.get(
      `${this.api?.sharableFilters}${cardId}?getAll=${getAll}&favorite=${favorite}&recent=${recent}&searchTerm=${searchTerm}&criteria=${criteria}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  saveEditAccess(cardId: string, filterId: string, payload: ViewAndEditPayload) {
    /*istanbul ignore next*/
    return this.http.put(`${this.api?.sharableFilter}${cardId}/${filterId}`, payload);
  }

  getFilterById(cardId: string, filterId: string, filterType: FilterType) {
    /*istanbul ignore next*/
    if (filterType === 'sharable') {
      return this.http.get(`${this.api?.sharableFilter}${cardId}/${filterId}`);
    } else if (filterType === 'admin') {
      return this.http.get<any>(`${this.api?.getFilterForEntityCard}${cardId}/global/filters/${filterId}`);
    }
  }

  changeFilterFavorite(cardId: string, filterId: string, isFavorite: boolean) {
    /*istanbul ignore next*/
    return this.http.put(`${this.api?.favoriteFilters}${cardId}/${filterId}?isFavorite=${isFavorite}`, {});
  }

  deleteFilterByFilterId(cardId: string, filterId: string) {
    return this.http.delete(`${this.api?.sharableFilter}${cardId}/${filterId}`);
  }

  // Save Kanban Constraints
  saveKanbanConstraints(payload: any) {
    return this.http.post(this.api.entityRecordValidations, payload);
  }

  getKanbanConstraints(entityId: any) {
    return this.http.get(this.api.entityRecordValidations + `/${entityId}`);
  }

  deleteKanbanConstraints(entityId: any) {
    return this.http.delete(this.api.entityRecordValidations + `/${entityId}`);
  }
  getLanguages(tenantSpecific: boolean) {
    let tenant_name = localStorage.getItem('TenantName');
    return this.http.get(this.api?.getLanguage + `${tenant_name}?is_tenant=${tenantSpecific}`);
  }
}

export type AddComment = {
  recordId: number;
  userName: string;
  productRating: number;
  reviewDescription: string;
  featureRatings: FeatureRating[];
  updatedAt: string;
  imageURL: string;
};
export type FeatureRating = {
  featureName: string;
  featureRating: number;
};
