export enum Librarytypes {
  MyLibrary = 'mylibrary',
  Book = 'books',
  Chapter = 'chapter',
  GSI = 'gsi',
  GSInested = 'gsinested',
  GSIexception = 'gsiexception',
  ChangeUnit = 'cu',
  Agent = 'agents',
  OrgUnit = 'orgunits',
  Entity = 'entity',
  NSLKitCU = 'nslhubKitCU',
  NSLKitEntity = 'nslhubKitEntity',
  Collaborator = 'collaborators',
  Collaborate = 'collaborate',
  MailBox = 'mailBox',
  Import = 'import',
  Export = 'export',
  Search = 'search',
  DataType = 'dataTypes',
  DcdAttributes = 'dcdAttributes',
  Attributes = 'Attributes',
  EventGsi = 'EventGsi',
  Dashboard = 'Dashboard',
  Admin = 'Admin',
  MyDashboard = 'My Dashboard',
  ProcessMining = 'Process Mining',
  DataResources = 'Data Resources',
  Notifications = 'notifications',
  GSIEmbedded = 'gsiEmbedded',
  SpecialFeatures = 'SpecialFeatures',
  ReservedActions = 'reservedActions',
  Users = 'users',
  EQL = 'eql',
  EQLsubQuery = 'EQLsubQuery',
  AddNodeActionMenu = 'addNodeActionMenu',
  Graph = 'graph',
  ReservedActionEntity = 'reservedActionEntity',
  MileStone = 'mileStone',
  FileExplorer = 'fileExplorer',
  Calendar = 'calendar',
  SpecialEvent = 'specialEvent',
  AdDashboard = 'AdDashboard',
  NslLibrary = 'nslLibrary',
  AdvertiserDashboard = 'AdvertiserDashboard',
  AgencyDashboard = 'AgencyDashboard',
  PublisherDashboard = 'publisherDashboard',
  IndividualCU = 'individualCU',
  IndividualEntity = 'individualEntity',
  VerticalGraph = 'verticalGraph',
  SubAttribute = 'subAttribute',
  NSLGsi = 'nslGsi',
  ShadesOfPotentiality = 'ShadesOfPotentiality',
  Parallel = 'parallel',
  MyProfile = 'MyProfile',
  OCR = 'ocr',
  Login = 'Login',
  Logout = 'Logout',
  MyCart = 'MyCart',
  Profile = 'Profile',
  MyLearning = 'MyLearning',
  EmailTemplateEditor = 'EmailTemplateEditor',
  Notepad = 'Notepad',
  MyTransactions = 'MyTransactions',
  EditGSI = 'EditGSI',
  SchedularDashboard = 'SchedularDashboard',
  MyCourses = 'MyCourses',
  LearningVideos = 'LearningVideos',
  Certificate = 'Certificate',
  Canvas = 'Canvas',
  HybridCanvas = 'HybridCanvas',
  Preferences = 'Preferences',
  Language = 'Language',
  Support = 'Support',
  Roles = 'Roles',
  Paragraph = 'Paragraph',
  chatbotCanvas = 'ChatbotCanvas',
  Account = 'Account',
  Measures = 'Measures',
  MCCSwitch = 'MCCSwitch',
  DigitalMind = 'DigitalMind',
  DigitalMindLearning = 'DigitalMindLearning',
  Cart = 'Cart',
  dceo = 'dceo',
  DeleteAccount = 'DeleteAccount',
  languageTemplateCCD = 'languageTemplateCCD',
  My_Pending_Submission = 'My_Pending_Submission',
  HeaderText = 'HeaderText',
  GoToCanvas = 'GoToCanvas',
  RuleEditor = 'RuleEditor',
}
