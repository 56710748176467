import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CredentialsService } from '@common-services';

@Injectable({providedIn: 'root'})
export class OnboardingGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /* istanbul ignore else */
    if (this.credentialsService.isAuthenticated()) {
      const tenantName = localStorage.getItem('TenantName');
      if (tenantName == 'b2c') this.router.navigateByUrl('/mylibrary-dashboard');
      else this.router.navigateByUrl('/admin');
      return false;
    } else {
      return true;
    }
  }
}
