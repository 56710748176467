import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LibraryFacadeService } from '../library/library-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';

@Component({
  selector: 'app-library-change-unit-layers',
  templateUrl: './library-change-unit-layers.component.html',
  styleUrls: ['./library-change-unit-layers.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LibraryChangeUnitLayersComponent implements OnInit {
  @Input() showLayerLabel: any;
  @Input() layerLabel: any;
  @Input() layerData: any;
  @Input() cuDetails: any;
  @Input() cuName: any;
  @Input() relatedCUGsiName: any;
  @Input() tempDataForEmbededGSIs: EmbededGSIData[];
  @Input() onlyAttribute = false;
  mindLayerFlag: any;
  isActivePhyLayer: boolean;
  isActiveInfoLayer: boolean;
  isActiveTriLayer: boolean;
  tempGsiObj: any;
  currentCuIndex: number;
  expressionType: any = 'dcd';
  currentCUType: any;
  private ngUnsubscribe = new Subject();
  gsiDcdName: any;
  gsiIndex: any;
  gsiLayerMappingFlag: boolean = false;
  dataForEmbededGSIs: string = '';
  flagForEmbededGSIArray: boolean = false;
  @Output() clearEmbededGSIArray: EventEmitter<any> = new EventEmitter();
  currentUielement: any;

  expand: boolean = false;
  currentAttrdetails: any;
  constructor(private gsiFacade: GsiFacadeService, private libraryFacadeService: LibraryFacadeService) {
    this.detectTempGsiStream();
    this.detectChangeOfCUTypeStream();
    this.detectdcdtonested();
    this.embeddedCUGsiName();
  }

  ngOnInit(): void {
    this.layerData?.forEach((data: any) => {
      data['expand'] = false;
    });
  }

  detectTempGsiStream() {
    this.gsiFacade.tempGsiObj$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.tempGsiObj = res;
      this.currentCuIndex = this.tempGsiObj.activeCuIndex;
    });
  }
  detectdcdtonested() {
    this.libraryFacadeService.dcdmappingtonestedcu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.mindLayerFlag = res;
    });
  }

  embeddedCUGsiName() {
    this.gsiFacade.gsiName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.gsiDcdName = res.name;
        this.gsiIndex = res.index;
      }
    });
  }

  addAttributeToDCD(res: any) {
    this.currentUielement = res?.attribute?.attributeType?.uiElement?.uiElement;
    /* istanbul ignore next */
    this.addAttributetoDcd(
      res?.entityIndex,
      res?.entity?.name,
      res?.entity?.tfId,
      res?.attribute?.name,
      /* istanbul ignore next */
      res?.attribute?.id,
      res?.entity
    );
    /* istanbul ignore next */
    this.getAttDetails(
      res?.entity,
      res?.attribute,
      res?.entityIndex,
      res?.entity?.name,
      res?.entity?.tfId,
      res?.attribute?.name,
      /* istanbul ignore next */
      res?.attribute?.id
    );
  }

  addEntityToDCD(res: any) {
    /* istanbul ignore next */
    this.addAttributetoDcd(res?.entityIndex, res?.entity?.name, res?.entity?.id, null, null, res?.entity);
  }

  detectChangeOfCUTypeStream() {
    this.libraryFacadeService.currentCUType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cuType: any) => {
      /* istanbul ignore next */
      if (cuType) {
        this.currentCUType = cuType;
      }
    });
  }

  /**
   * @author Ajit Dhayal
   * @param entityData
   * @param attributeId
   * @returns the data type of an Attribute
   */
  checkDataType(entityData: any, attributeId: any) {
    if (this.currentAttrdetails) {
      return;
    }
    for (let attr of entityData?.nslAttributes) {
      /* istanbul ignore next */
      if (attr?.generalEntity) {
        this.checkDataType(attr?.generalEntity, attributeId);
      } else if (attr?.id == attributeId?.split('.')?.pop()) {
        /* istanbul ignore next */
        this.currentAttrdetails = attr?.attributeType?.type;
      }
    }
  }
  addAttributetoDcd(
    entityIndex: number,
    entityName: string,
    entityId: string,
    attributeName: string,
    attributeId: string,
    entityData?: any
  ) {
    /* istanbul ignore else */
    if (attributeName) {
      /* istanbul ignore next */
      this.currentAttrdetails = undefined;
      this.checkDataType(entityData, attributeId);
    }
    /* istanbul ignore next */
    const cuName = this.cuDetails?.name;
    /* istanbul ignore next */
    const cuIndex = this.cuDetails?.index;
    /* istanbul ignore next */
    let layerLabel = this.layerLabel?.name.split(' ');
    const layerType = layerLabel[0];
    let contextualIdWithId = this.generateContextualId(
      cuName,
      cuIndex,
      layerType,
      entityIndex,
      entityId,
      attributeId,
      entityData
    );
    let formulaIdWithId = this.generateFormulaId(layerType, entityIndex, entityId, attributeId);
    let contextualId = this.generateContextualId(
      cuName,
      cuIndex,
      layerType,
      entityIndex,
      entityName,
      attributeName,
      entityData
    );
    let formulaId = this.generateFormulaId(layerType, entityIndex, entityName, attributeName);
    let tempDcdObj = {
      contextualId: contextualId,
      contextualIdWithId: contextualIdWithId,
      formulaId: formulaId,
      formulaIdWithId: formulaIdWithId,
      label: attributeName,
      name: attributeName,
      expressionType: this.expressionType,
      attributeType: this.currentAttrdetails,
      attributeuiElement: this.currentUielement,
    };
    /* istanbul ignore next */
    if (!attributeName) {
      tempDcdObj = {
        ...tempDcdObj,
        isMultiValue: entityData?.isMultiValue == true || entityData?.attributeType?.type == 'list' ? true : false,
      } as any;
    }
    if (this.currentCUType === 'alternate') {
      this.libraryFacadeService.addAttributetoAlternateCU({ ...tempDcdObj, isAlternateCU: this.currentCUType });
    }
    if (this.currentCUType === 'cuConditionalPotentiality') {
      this.libraryFacadeService.addAttributetoCuPotentiality({ ...tempDcdObj });
    } else if (this.currentCUType === 'entityMemberShip') {
      this.libraryFacadeService.addAttributetoEntityMemberShip({ ...tempDcdObj });
    } else {
      let idType = attributeName ? 'attribute' : 'entity';
      this.gsiFacade.addNewDcd({ ...tempDcdObj, cuType: this.currentCUType, contextualIdType: idType });
    }
  }

  getAttDetails(
    entity: any,
    attribute: any,
    entityIndex?: number,
    entityName?: string,
    entityId?: string,
    attributeName?: string,
    attributeId?: string
  ) {
    /* istanbul ignore next */
    let layerLabel = this.layerLabel?.name.split(' ');
    const layerType = layerLabel[0];
    const data = {
      entity: entity,
      attribute: attribute,
    };
    /* istanbul ignore next */
    if (entityIndex !== -1) {
      data['contextualAttrId'] = this.generateFormulaId(layerType, entityIndex, entityId, attributeId, true);
      data['contextualAttrName'] = this.generateFormulaId(
        layerType,
        entityIndex,
        entityName,
        attributeName,
        true,
        true
      );
    }
    this.libraryFacadeService.getSingleAttributeDetailsWithEnt(data);
  }

  generateContextualId(
    cuName: string,
    cuIndex: number,
    layerType: string,
    entityIndex: number,
    entityName: string,
    attributeName: string,
    currentEntityData: any
  ) {
    this.createEmbededGSIString();
    this.tempDataForEmbededGSIs = [];

    let dot = '.';
    let colon = ':';
    let slot = 'slot';
    let gsiName;
    if (this.relatedCUGsiName) {
      gsiName = this.relatedCUGsiName;
    } else if (this.tempGsiObj && this.tempGsiObj.appDetails) {
      gsiName = this.tempGsiObj.appDetails.gsiName;
    }
    // if (this.gsiDcdName) {
    //   gsiName = this.gsiDcdName;
    // }
    if (this.mindLayerFlag) {
      gsiName = this.mindLayerFlag;
    }
    let contextualId = '';

    if (cuIndex) {
      if (this.dataForEmbededGSIs) {
        contextualId =
          gsiName +
          dot +
          this.dataForEmbededGSIs +
          cuName +
          colon +
          cuIndex +
          dot +
          layerType +
          dot +
          slot +
          colon +
          (entityIndex + 1) +
          dot +
          entityName;
      } else {
        contextualId =
          gsiName +
          dot +
          cuName +
          colon +
          cuIndex +
          dot +
          layerType +
          dot +
          slot +
          colon +
          (entityIndex + 1) +
          dot +
          entityName;
      }
    } else {
      contextualId =
        gsiName + dot + this.dataForEmbededGSIs + layerType + dot + slot + colon + (entityIndex + 1) + dot + entityName;
    }

    if (this.gsiDcdName && !this.dataForEmbededGSIs) {
      contextualId =
        this.tempGsiObj.appDetails.gsiName +
        dot +
        gsiName +
        colon +
        this.gsiIndex +
        dot +
        cuName +
        colon +
        cuIndex +
        dot +
        layerType +
        dot +
        slot +
        colon +
        (entityIndex + 1) +
        dot +
        entityName;
    }
    /* istanbul ignore next */
    if (attributeName) {
      contextualId = contextualId + dot + attributeName;
    }

    if (this.mindLayerFlag) {
      gsiName = this.tempGsiObj.appDetails.gsiName;
      cuName = this.tempGsiObj.cuList[this.tempGsiObj.activeCuIndex].name;
      const actualIndex = this.tempGsiObj.cuList[this.tempGsiObj.activeCuIndex].index;
      contextualId = gsiName + dot + cuName + colon + actualIndex + dot + 'nestedSSA' + colon + contextualId;
    }
    /* istanbul ignore next */
    if (this.currentCUType === 'dcd') {
      this.libraryFacadeService.updateCurrentEntityData(currentEntityData);
    }
    this.flagForEmbededGSIArray = true;
    return contextualId;
  }

  updateTempGSI(currentEntityData: any) {
    const data = { ...this.tempGsiObj, currentEntityForDCD: currentEntityData };
    this.gsiFacade.updateTempGsiObject(data);
  }

  generateFormulaId(
    layerType: string,
    entityIndex: any,
    entityName: string,
    attributeName: string,
    isForDependent?: boolean,
    isEntityname?: boolean
  ) {
    /* istanbul ignore next */
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    let indexPadding = this.getIndexPadding(entityIndex + 1);
    let dot = '.';
    let layerShortCut = 'PL.SL' + indexPadding;
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      layerShortCut = 'triggerCES.SL' + indexPadding;
    }
    let formulaId =
      isForDependent && !isEntityname ? layerShortCut + dot + 'EN' + entityName : layerShortCut + dot + entityName;
    /* istanbul ignore else */
    if (attributeName) {
      formulaId = formulaId + dot + attributeName;
    }
    return formulaId;
  }

  onChangeMatTab() {
    /* istanbul ignore next */
    if (this.layerLabel?.name == 'physical Layer') {
      this.isActivePhyLayer = !this.isActivePhyLayer;
    } else if (this.layerLabel?.name == 'information Layer') {
      this.isActiveInfoLayer = !this.isActiveInfoLayer;
    } else if (this.layerLabel?.name == 'triggerCES Layer') {
      this.isActiveTriLayer = !this.isActiveTriLayer;
    }
  }

  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  createEmbededGSIString() {
    this.tempDataForEmbededGSIs?.forEach((embededGsi: any) => {
      this.dataForEmbededGSIs = this.dataForEmbededGSIs + embededGsi?.gsiName + ':' + embededGsi?.index + '.';
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.clearEmbededGSIArray.emit(this.flagForEmbededGSIArray);
  }
}

export interface EmbededGSIData {
  gsiName: string;
  index: number;
}