import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {

  constructor(
    private http: HttpClient,
  ) { }

  getEnvBasicInfo(): Observable<any> {
    return this.http.get(endPoints.envBasicInfo);
  }
}

export const url = 'https://cduidev.preqa.nslhub.com/'

export const endPoints = {
  login: '',
  envBasicInfo: url + 'dsd-orch/cdm/api/cdm/envBasicInfo?name=cduidev',
}
