import { Injectable } from '@angular/core';
import { TenantCustomEndpointService } from './tenant-custom-endpoint.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';
@Injectable({
  providedIn: 'root',
})
export class TenantCustomUiFacadeService {
  savedTemplate = new BehaviorSubject('');
  savedTemplate$ = this.savedTemplate.asObservable();

  savedLayouts = new BehaviorSubject('');
  savedLayouts$ = this.savedLayouts.asObservable();

  adminHeader = new Subject();
  adminHeader$ = this.adminHeader.asObservable();

  editTemplate = new Subject();
  editTemplate$ = this.editTemplate.asObservable();

  createTemplate = new Subject();
  createTemplate$ = this.createTemplate.asObservable();

  htmlDataTemplate = new Subject();
  htmlDataTemplate$ = this.htmlDataTemplate.asObservable();

  tenantName = new Subject();
  tenantName$ = this.tenantName.asObservable();

  savedLayoutTemplates = new BehaviorSubject('');
  savedLayoutTemplates$ = this.savedLayoutTemplates.asObservable();

  templateData = new BehaviorSubject('');
  templateData$ = this.templateData.asObservable();

  savedHeaderTemplates = new BehaviorSubject({});
  savedHeaderTemplates$ = this.savedHeaderTemplates.asObservable();

  headerListByRoleId = new BehaviorSubject('');
  headerListByRoleId$ = this.headerListByRoleId.asObservable();

  themeRoleId = new BehaviorSubject('');
  themeRoleId$ = this.themeRoleId.asObservable();

  enableHomeInfo = new BehaviorSubject('');
  enableHomeInfo$ = this.enableHomeInfo.asObservable();

  detectLoaderChanges = new Subject();
  detectLoaderChanges$ = this.detectLoaderChanges.asObservable();

  constructor(
    private tenantCustomEndpointService: TenantCustomEndpointService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {}

  //// Fetch Tenant ///
  isTenant(tenant: any) {
    this.tenantName.next(tenant);
  }

  ///// upload image ////
  dynamicFormUploadFiles(files: any, fileLength: number) {
    if (fileLength === 1) {
      return this.tenantCustomEndpointService.singleFileUpload(files);
    } else {
      return this.tenantCustomEndpointService.multipleFileUpload(files);
    }
  }

  ///// upload content ////
  dynamicContentUpload(content: any) {
    return this.tenantCustomEndpointService
      .uploadContent(content)
      .pipe(
        catchError((error) => {
          this.alertService.showToaster(error.error.message, '', 'error');
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.createTemplate.next(res);
      });
  }

  ///// edit footer dynamic page ////
  dynamicContentEdit(content: any, updatedLink: any) {
    return this.tenantCustomEndpointService
      .editFooterContent(content, updatedLink)
      .pipe(
        catchError((errors) => {
          this.alertService.showToaster(errors.error.message, '', 'error');
          return throwError(errors);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.editTemplate.next(res);
      });
  }

  ///// Get data page onload ////
  fetchTenantData(tenantName: string) {
    this.tenantName.next(tenantName);
    this.loader.show();
    this.tenantCustomEndpointService
      .getTenantDesign(tenantName)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.savedTemplate.next(res);
      });
  }

  ///// Get data footer data onload ////
  fetchTenantDataAfterlogin(tenantName: string) {
    this.tenantName.next(tenantName);
    this.loader.show();
    this.tenantCustomEndpointService
      .fetchTenantDataAfterlogin()
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.savedTemplate.next(res);
      });
  }

  fetchHtmlData(editFooterLink: any) {
    this.tenantCustomEndpointService.getHtmlDataFromURL(editFooterLink).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
      this.htmlDataTemplate.next(res);
    });
  }

  ///// Save changes  ////
  saveDesign(bookDetails: any) {
    this.loader.show();
    this.tenantCustomEndpointService
      .sendTenantDesign(bookDetails)
      .pipe(
        catchError((geterror) => {
          this.loader.hide();
          this.alertService.showToaster(geterror.error.message, '', 'error');
          return throwError(geterror);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.savedTemplate.next(res);
      });
  }

  ///// Save changes  ////
  saveLayout(layout: any) {
    this.loader.show();
    this.tenantCustomEndpointService
      .createLayout(layout)
      .pipe(
        catchError((geterror) => {
          this.loader.hide();
          this.alertService.showToaster(geterror.error.message, '', 'error');
          return throwError(geterror);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.savedLayouts.next(res);
      });
  }

  ///// Get template data page onload ////
  fetchTemplateData() {
    this.loader.show();
    this.tenantCustomEndpointService
      .getLayoutAll()
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.savedLayoutTemplates.next(res);
      });
  }

  ///// Get template data page onload ////
  fetchTemplateInfo(id: any) {
    this.loader.show();
    this.tenantCustomEndpointService
      .getTemplateInfo(id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.templateData.next(res);
      });
  }

  dummyCallForsavedTemplate(res: any) {
    this.savedTemplate.next(res);
  }

  setHomeContentStatus(res: any) {
    this.enableHomeInfo.next(res);
  }

  ///// Get data page onload ////
  fetchHeaderData(roleId: number, fetchHeader: boolean) {
    this.loader.show();
    this.tenantCustomEndpointService
      .getAllHeaders(roleId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.headerListByRoleId.next(res?.result);
        res.roleId = roleId;
        this.themeRoleId.next(res);
        if (res?.result?.length > 0) {
          /*istanbul ignore next*/
          let headerId = res?.result?.[res?.result?.length - 1].id;
          if (headerId && fetchHeader) {
            this.getHeaderById(headerId);
          }
        } else {
          this.savedHeaderTemplates.next('null');
        }
      });
  }

  getHeaderById(headerId: any) {
    // this.loader.show();
    this.tenantCustomEndpointService
      .getHeaderById(headerId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        // this.loader.hide();
        this.savedHeaderTemplates.next(res);
      });
  }

  deleteHeaderFromRole(roleId: number) {
    this.loader.show();
    this.tenantCustomEndpointService
      .deleteHeaderFromRole(roleId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        if (res?.status === 200) {
          this.alertService.showToaster(res?.message, '', 'success');
          this.fetchHeaderData(roleId, false);
        }
      });
  }
  getLoaderData() {
    const tenantName = localStorage.getItem('TenantName');
    this.tenantCustomEndpointService.getTenantDesign(tenantName).subscribe((res: any) => {
      if (res) {
        this.detectLoaderChanges.next(res?.result?.webTemplateType);
      }
    });
  }
  detectScreenSize(): string {
    let screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      return 'desktop';
    } else if (screenWidth >= 768) {
      return 'tab';
    } else {
      return 'mobile';
    }
  }
}
