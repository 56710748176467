<div [ngClass]="{ 'default-card-views': cuLayoutTemplate?.template == 't-1' }">
  <dynamic-form
    class="dynamic-form"
    [isTransactionActive]="isTransactionActive"
    [entityList]="entitiesList"
    [gsi]="gsi"
    [isInfo]="true"
    [showSubmit]="false"
    [isReadOnly]="isReadOnly"
    [layoutConfigurations]="layoutConfigurations"
    (formValueChange)="formValueChange($event)"
    (submitEmit)="submitFormData($event)"
    [currentCu]="currentCu"
    (dataEmitForTable)="entityDataForTable($event)"
    (addMultiEntities)="addMultiEntities($event)"
    [indexChange]="indexChange"
  >
  </dynamic-form>
</div>
