<div>
  <div class="connector-heading">
    <span>{{ labels?.Choose_Attributes_for_Connectors_Mapping }}:</span>
    <button class="cancel-mapping" (click)="closeEntityMap()">
      <span>
        <img [src]="'../../../assets/img/icon-cross.svg' | CDNUrlRewritePipe" alt="" />
      </span>
    </button>
  </div>

  <div class="entity-source-container">
    <div>
      <span class="source-container-heading">{{ labels?.Source_Object }}:</span>
      <div class="source-entity">
        <span class="entity-heading">{{ sourceEntity?.name }}</span>
        <mat-radio-group
          class="attr-items"
          aria-label="Select an option"
          [(ngModel)]="sourceAttr"
          (change)="initialiseMap()"
          id="select_source_attr"
        >
          <mat-radio-button
            *ngFor="let attr of sourceAttrList; let i = index"
            [value]="attr"
            (click)="confirmation = true"
            id="{{ 'sourceAttr_' + attr?.name?.replaceAll(' ', '_') }}"
            >{{ attr.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="source-container-border"></div>

    <div>
      <span class="source-container-heading">{{ labels?.Target_Object }}:</span>
      <div class="target-entity">
        <span class="entity-heading">{{ targetEntity?.name }}</span>
        <!-- <mat-radio-group class="attr-items" aria-label="Select an option" [(ngModel)]="sourceAttr">
        <mat-radio-button 
          *ngFor="let attr of sourceAttrList; let i = index"
          [value]="attr"
          (click)="confirmation = true"
          id="{{ 'sourceAttr_' + attr?.name?.replaceAll(' ', '_') }}"
          >{{ attr.name }}
        </mat-radio-button>
      </mat-radio-group> -->

        <mat-radio-group aria-label="Select an option" class="attr-items" [(ngModel)]="targetAttr">
          <mat-radio-button
            *ngFor="let attr of targetAttrList; let i = index"
            [value]="attr"
            id="{{ 'targetAttr_' + attr?.name?.replaceAll(' ', '_') }}"
            >{{ attr.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <div *ngIf="entityDCDMap?.matchedAttributesMap?.length > 0 && !templateMapping">
    <p class="source-list">{{ labels?.LIST_OF_CONNECTORS }}</p>
    <span *ngFor="let map of entityDCDMap?.matchedAttributesMap; let i = index" class="mapped-attr">
      <span style="text-align: left; font: normal normal 600 16px/24px Inter; letter-spacing: 0px; color: #333333;">{{
        map?.sourceAttribute
      }}</span>
      <figure class="w-auto">
        <img [src]="'../../../../../../assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" />
      </figure>
      <span style="text-align: left; font: normal normal 600 16px/24px Inter; letter-spacing: 0px; color: #333333;">{{
        map?.targetAttribute
      }}</span>
      <figure (click)="deleteMapping(i)">
        <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
      </figure>
    </span>
  </div>

  <div class="source-entity-text" *ngIf="!templateMapping">
    {{labels?.Are_you_sure || 'Are you sure'}}, {{labels?.you_want_to_map_two_different_entities ||'you want to map two different entities'}}, {{labels?.holding_two_different_attributes || 'holding two different attributes'}}?
  </div>

  <div class="source-checkbox-section mb-3">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="confirmation">
      <mat-radio-button id="check_map_True" [value]="true">{{labels?.Yes}} </mat-radio-button>
      <mat-radio-button id="check_map_False" [value]="false" (click)="discardMapping()">{{labels?.No}} </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="templateMapping">
    <div class="form-group">
      <label class="field-label">{{ labels?.Select_Operator }}</label>
      <!--placeholder="{{ labels?.Select_Operator }}"-->
      <select [(ngModel)]="selectedOperator" class="custom-select" (change)="setAttribute()">
        <option *ngFor="let operator of operators" [value]="operator" id="{{ operator }}">
          <span>{{ operator }}</span></option
        >
      </select>
    </div>
    <div class="form-group">
      <label class="field-label">{{ labels?.Select_Category }}</label>
      <select class="custom-select" [(ngModel)]="selectedCategory" (change)="fetchFunctionDetails(selectedCategory)">
        <option *ngFor="let category of functionCategories" [value]="category?.name" id="{{ category?.name }}">
          <span>{{ labels?.category?.name ? labels?.category?.name : category?.name }}</span>
        </option>
      </select>
    </div>

    <div class="form-group" *ngIf="selectedCategory">
      <label class="field-label">{{ labels?.Select_Function }}</label>
      <select class="custom-select" [(ngModel)]="selectedFunction" (change)="setAttribute()">
        <option *ngFor="let function of functionDetails" [value]="function?.name" id="{{ function?.name }}">
          <span>{{ function?.name }}</span>
        </option>
      </select>
    </div>

    <div class="form-group">
      <label class="field-label">{{ labels?.Enter_Value }}</label>
      <input
        class="form-control"
        [(ngModel)]="firstValue"
        (keyup.enter)="addFunctionOrExpression(selectedFunction, selectedOperator)"
        placeholder="{{ labels?.Enter_Value }}"
        id="first_parameter"
      />
      <!-- <button id="add_value" (click)="addFunctionOrExpression(selectedFunction, selectedOperator)">
        {{ labels?.Add }}
      </button> -->
    </div>

    <div class="form-group" *ngIf="firstValue">
      <label class="field-label">{{ labels?.Enter_Value }}</label>
      <input
        class="form-control"
        [(ngModel)]="secondValue"
        (keyup.enter)="addFunctionOrExpression(selectedFunction, selectedOperator)"
        placeholder="{{ labels?.Enter_Value }}"
        id="second_parameter"
      />
      <button
        id="add_value"
        *ngIf="sourceAttr?.name && firstValue"
        (click)="addFunctionOrExpression(selectedFunction, selectedOperator)"
      >
        {{ labels?.Add }}
      </button>
    </div>

    <div *ngIf="currentAttrMap">
      <p class="source-list">{{ labels?.Current_Mapping }}</p>
      <app-mapping-display
        [attributeMapping]="currentAttrMap"
        style="text-align: left; font: normal normal 600 16px/24px Inter; letter-spacing: 0px; color: #333333;"
      ></app-mapping-display>
      <figure class="w-auto">
        <img [src]="'../../../../../../assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" />
      </figure>
      <span>{{ currentAttrMap?.name }}</span>
    </div>
    <div *ngIf="templateMapppings?.[mapIndex]?.attributes?.length">
      <p class="source-list">{{ labels?.LIST_OF_MAPPINGS }}</p>
      <div *ngFor="let attrMap of templateMapppings?.[mapIndex]?.attributes; let i = index">
        <app-mapping-display
          [attributeMapping]="attrMap"
          style="text-align: left; font: normal normal 600 16px/24px Inter; letter-spacing: 0px; color: #333333;"
        ></app-mapping-display>
        <figure class="w-auto">
          <img [src]="'../../../../../../assets/img/attribute-red-icon.svg' | CDNUrlRewritePipe" />
        </figure>
        <span>{{ attrMap?.name }}</span>
        <figure (click)="deleteTemplateMapping(i)">
          <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
        </figure>
      </div>
    </div>
  </div>
  <div class="source-btn-container">
    <button
      [disabled]="(!sourceAttr?.name || !targetAttr?.name) && !templateMapping"
      class="map-btns"
      (click)="mapAttr()"
      id="btn_mapAttributes"
    >
      {{ labels?.Map }}
    </button>
    <button class="confirm-btns" (click)="closeEntityMap()">
      {{ labels?.Done }}
    </button>
  </div>
</div>
