<div class="specialFeature">
  <form [formGroup]="specialFeaturesFormGroup" (ngSubmit)="generatePayload()">
    <div class="" *ngFor="let property of properties">
      <mat-form-field
        *ngIf="
          !autoProp.includes(property.name) &&
          property.permittedValue.length === 0 &&
          property.name != 'queryCondition' &&
          property.name != 'potentialSlotItems' &&
          property.name != 'realitySlotItems' &&
          !(
            noOfTransactionFlag &&
            (property.name == 'duration' || property.name == 'durationFactor' || property.name == 'tillNow'|| property.name == 'time'||property.name == 'timeFactor')
          ) &&
          (noOfTransactionFlag || property.name != 'maxLimit')
        "
        appearance="legacy"
      >
        <mat-label
          >{{ property?.name }} <span class="bi bi-info-circle pe" matTooltip="{{ property?.info }}"></span
        ></mat-label>
        <input
           #inputDuration
          [formControlName]="property?.name"
          [type]="dataMap[property?.dataType]"
          matInput
          [required]="property?.isMandatory"
          (keydown)="onKeydown($event, property?.name)"
          (input)="validateValue(inputDuration.value, property?.name)"
          [min]="durationMinValue"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="
          !autoProp.includes(property?.name) &&
          property.permittedValue.length > 0 &&
          property.name != 'queryCondition' &&
          !(
            noOfTransactionFlag &&
            (property.name == 'duration' || property.name == 'durationFactor' || property.name == 'tillNow'|| property.name == 'time'||property.name == 'timeFactor')
          ) &&
          (noOfTransactionFlag || property.name != 'maxLimit')
        "
        appearance="legacy"
      >
        <mat-label
          >{{ property.name }} <span class="bi bi-info-circle pe" matTooltip="{{ property?.info }}"></span
        ></mat-label>
        <mat-select [formControlName]="property?.name"
        [required]="property?.isMandatory">
          <mat-option
            (click)="isReservedCheck(property.name, showinfoReserve)"
            *ngFor="let opt of property?.permittedValue"
            [value]="opt?.name"
            >{{ opt.displayName ? opt.displayName : opt.name }}</mat-option

          >
        </mat-select>
      </mat-form-field>
      <div class="querycondition" *ngIf="property.name == 'queryCondition'">
        <app-node-special-feature-query-condition
          [tempGsiObj]="gsiData"
          [OtherInfo]="otherInfo"
          (sendQueryCondition)="getQueryCondition($event)"
        ></app-node-special-feature-query-condition>
      </div>
    </div>
    <div class="ContextualInfo">
      <div *ngIf="showPotentialSlotItems" class="inpotentiality ent-att-font">
        <div id="potentialDriversSlot" class="heading-potent-reality">{{ labels?.Change_Drivers_in_Potentiality }}</div>

        <mat-form-field>
          <div>
            <mat-label id="potententialEntity" class="selectEnt-att">{{ labels?.Select_Entity }}</mat-label>
            <mat-select id="potententialEntityslected" class="select-opt" [(value)]="inPotentialityEnt">
              <mat-option *ngFor="let name of triggerCuEntities" [value]="name">{{ name.name }}</mat-option>
            </mat-select>
          </div>
        </mat-form-field>
        <mat-form-field>
          <div>
            <mat-label id="potententialAttr" class="selectEnt-att">{{ labels?.Select_Attribute }}</mat-label>
            <mat-select id="potententialAttrSelected" class="select-opt" [(value)]="inPotentialityAtt" multiple>
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let attr of inPotentialityAtt" [removable]="true">
                    {{ attr.displayName }}
                    <mat-icon matChipRemove (click)="removeChip(attr, inPotentialityAtt)">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <mat-option
                (click)="generatePotentialSlot(inPotentialityEnt, inPotentialityAtt)"
                *ngFor="let name of inPotentialityEnt?.nslAttributes"
                [value]="name"
                >{{ name.displayName }}</mat-option
              >
            </mat-select>
          </div>
        </mat-form-field>
      </div>
      <div *ngIf="showRealitySlotItems" class="inReality ent-att-font">
        <div id="realityentattr" class="heading-potent-reality">{{ labels?.Change_Drivers_in_Reality }}</div>
        <mat-form-field>
          <div>
            <mat-label id="realityEnt" class="selectEnt-att">{{ labels?.Select_Entity }}</mat-label>
            <mat-select id="realityEntselected" class="select-opt" [(value)]="inRealityEnt">
              <mat-option *ngFor="let name of triggerCuEntities" [value]="name">{{ name.name }}</mat-option>
            </mat-select>
          </div>
        </mat-form-field>
        <mat-form-field>
          <div>
            <mat-label id="realityAttr" class="selectEnt-att">{{ labels?.Select_Attribute }}</mat-label>
            <mat-select id="realityAttrSelected" class="select-opt" [(value)]="inRealityAtt" multiple>
              <mat-select-trigger>
                <mat-chip-list>
                  <mat-chip *ngFor="let attr of inRealityAtt" [removable]="true">
                    {{ attr.displayName }}
                    <mat-icon matChipRemove (click)="removeChip(attr, inRealityAtt)">cancel</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </mat-select-trigger>

              <mat-option
                (click)="generateRealitySlot(inRealityEnt, inRealityAtt)"
                *ngFor="let name of inRealityEnt?.nslAttributes"
                [value]="name"
                >{{ name.displayName }}</mat-option
              >
            </mat-select>

          </div>
        </mat-form-field>
      </div>
      <div class="sourceContextualInfo contextualplist">
        <div class="contextualplist-value contextualplist-wrapper">
          <div>
            <div class="title-info" (click)="displaySource()">
              {{ labels?.Source_Contextual_Name }}
            </div>
            <div class="sourceData" *ngIf="!displaySourceEdit && !isMulti">
              : {{ sourceContextualName | dcd }}
            </div>
            <div class="sourceData" *ngIf="!displaySourceEdit && isMulti">
              <div class="sel-attr-preview lineclamp">{{ sourceContextualName | dcd: true }}</div>
              <div class="source-value">SourceValue:</div>
              <div class="sv-item" *ngFor="let value of sourceValueNameMlr; let i = index">
                <p class="attr-title text-truncate" matTooltip="{{ sourceValueNameMlr[i] | dcd:true}} :">
                  {{ sourceValueNameMlr[i] | dcd:true }} :
                </p>
                <input
                  class="sv-input"
                  placeholder="{{ labels?.Select_sourceValue_Factor }}"
                  [(ngModel)]="sourceValueMlr[i]"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
              <div class="sv-btn">
                <!-- <button id="sfConfigReset" type="button" class="btn btn-light" (click)="resetAttributeContextualData()">
                  {{ labels?.RESET }}
                </button> -->
                <div class="divider"></div>
                <button id="sfConfigSave" type="button" class="btn btn-light" (click)="saveAttributeContextualData()">
                  {{ labels?.DONE_ }}
                </button>
              </div>
            </div>
          </div>
          <div class="selecteData">
            <mat-accordion class="ilayerdata" *ngIf="displaySourceEdit">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="active-bold">
                    <span>{{ gsiData?.name }}</span>
                    <span class="w-auto ml-2 mr-3">
                      <img
                        *ngIf="vantagePoints.includes('GSI')"
                        (click)="generateSourceContextualInfo('GSI')"
                        [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                        alt=""
                        class="img-h25 hover-img hoverplusimg"
                      />
                    </span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel *ngFor="let cu of gsiData.solutionLogic">
                  <mat-expansion-panel-header>
                    <p
                      [matTooltipPosition]="'above'"
                      matTooltipClass="tool-tip-custom"
                      matTooltip="{{ cu?.name }}"
                      class="ent-widt"
                    >
                      {{ cu?.name }}
                    </p>
                    <span class="w-auto ml-2 mr-3">
                      <img
                        *ngIf="vantagePoints.includes('CU')"
                        (click)="generateSourceContextualInfo('CU', { cu: cu })"
                        [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                        alt=""
                        class="img-h25 hover-img hoverplusimg"
                      />
                    </span>
                  </mat-expansion-panel-header>
                  <div *ngFor="let layer of cu.layers; let layerIndex = index">
                    <mat-accordion
                      class="w-100"
                      *ngFor="let entity of layer.participatingItems; let entityIndex = index"
                    >
                      <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="active-p">
                            <p
                              [matTooltipPosition]="'above'"
                              matTooltipClass="tool-tip-custom"
                              matTooltip="{{ entity?.name }}"
                              class="ent-widt"
                            >
                              {{ entity?.name }}
                            </p>
                            <span class="w-auto ml-2 mr-3">
                              <img
                                *ngIf="vantagePoints.includes('Entity')"
                                (click)="generateSourceContextualInfo('EN', { cu: cu, en: entity }, layer, entityIndex)"
                                [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                                alt=""
                                class="img-h25 hover-img hoverplusimg"
                              />
                            </span>
                            <span class="w-auto ml-2"> </span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="attribute-list attribute-list-li" id="attributeList">
                          <li
                            class="d-flex justify-content-between align-items-center nh-mb-8"
                            *ngFor="let attr of entity.nslAttributes"
                          >
                            <p
                              [matTooltipPosition]="'above'"
                              matTooltipClass="tool-tip-custom"
                              matTooltip="{{ attr?.name }}"
                              class="ent-widt"
                            >
                              {{ attr?.name }}
                            </p>
                            <span class="w-auto ml-2">
                              <img
                                *ngIf="vantagePoints.includes('Attribute')"
                                (click)="
                                  generateSourceContextualInfo(
                                    'AT',
                                    { att: attr, en: entity, cu: cu },
                                    layer,
                                    entityIndex
                                  )
                                "
                                [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                                alt=""
                                class="img-h25 hover-img hoverplusimg"
                              />
                            </span>
                          </li>
                        </ul>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </mat-expansion-panel>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <div class="TargetContextualInfo contextualplist" *ngIf="showTarget">
        <div class="contextualplist-value contextualplist-wrapper">
          <div class="title-info">
            {{ labels?.Target_Contextual_Name }}
          </div>
          <div class="targetData" *ngIf="!displayTargetEdit&&!isMulti" (click)="displayTarget()">
            <!-- {{ targetContextualName?.split('.')?.at(-1) }} -->
            : {{ targetContextualName | dcd }}
          </div>
          <div class="targetData" *ngIf="!displayTargetEdit&&isMulti" (click)="displayTarget()">
            <!-- {{ targetContextualName?.split('.')?.at(-1) }} -->
            : {{ targetContextualName | dcd:true }}
          </div>
          <div class="selecteData">
            <mat-accordion class="ilayerdata" *ngIf="displayTargetEdit">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="active-bold">
                    <span>{{ gsiData?.name }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-expansion-panel *ngFor="let cu of gsiData.solutionLogic">
                  <mat-expansion-panel-header>
                    <p
                      [matTooltipPosition]="'above'"
                      matTooltipClass="tool-tip-custom"
                      matTooltip="{{ cu?.name }}"
                      class="ent-widt"
                    >
                      {{ cu?.name }}
                    </p>
                    <span class="w-auto ml-2 mr-3">
                      <img
                        *ngIf="vantagePoints.includes('CU')"
                        (click)="generateTargetContextualInfo('CU', { cu: cu })"
                        [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                        alt=""
                        class="img-h25 hover-img hoverplusimg"
                      />
                    </span>
                  </mat-expansion-panel-header>
                  <div *ngFor="let layer of cu.layers; let layerIndex = index">
                    <mat-accordion
                      class="w-100"
                      *ngFor="let entity of layer.participatingItems; let entityIndex = index"
                    >
                      <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                          <mat-panel-title class="active-p">
                            <p
                              [matTooltipPosition]="'above'"
                              matTooltipClass="tool-tip-custom"
                              matTooltip="{{ entity?.name }}"
                              class="ent-widt"
                            >
                              {{ entity?.name }}
                            </p>
                            <span class="w-auto ml-2">
                              <img
                                *ngIf="vantagePoints.includes('Entity')"
                                (click)="generateTargetContextualInfo('EN', { cu: cu, en: entity },layer, entityIndex)"
                                [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                                alt=""
                                class="img-h25 hover-img hoverplusimg"
                              />
                            </span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="attribute-list attribute-list-li" id="attributeList">
                          <li
                            class="d-flex justify-content-between align-items-center nh-mb-8"
                            *ngFor="let attr of entity.nslAttributes"
                          >
                            <p
                              [matTooltipPosition]="'above'"
                              matTooltipClass="tool-tip-custom"
                              matTooltip="{{ attr?.name }}"
                              class="ent-widt"
                            >
                              {{ attr?.name }}
                            </p>
                            <span class="w-auto ml-2">
                              <img
                                *ngIf="vantagePoints.includes('Attribute')"
                                (click)="
                                  generateTargetContextualInfo(
                                    'AT',
                                    { att: attr, en: entity, cu: cu },
                                    layer,
                                    entityIndex
                                  )
                                "
                                [src]="'/assets/images/plusicon.svg' | CDNUrlRewritePipe"
                                alt=""
                                class="img-h25 hover-img hoverplusimg"
                              />
                            </span>
                          </li>
                        </ul>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </mat-expansion-panel>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <div class="reset-btn">
        <button *ngIf="isMulti" (click)="resetSourceTarget(); resetAttributeContextualData()">Reset</button>
      </div>
    </div>
    <input *ngIf="!isGrammar" type="submit" value="save" class="btn btn-info save btnsave" />
  </form>
</div>
<ng-template #showinfoReserve>
  <div mat-dialog-content class="mypopup">
    <div class="add-additional1">
      <h2>
        This will add reserve Entity to your current Entity!!
        <div class="yn-btn" mat-dialog-actions>
          <button (click)="addReserveEnt()">YES</button>
          <button (click)="closeReserveEntPanel()">NO</button>
        </div>
      </h2>
    </div>
  </div>
</ng-template>
