<div class="form-group">
  <label class="field-label">CHANGE_UNIT</label>
  <select
    class="custom-select"
    name="changeUnitName"
    aria-label=""
    (change)="targetChangeUnitChange(targetDcdValue.selectedNestedCU.changeUnitIndex)"
    [(ngModel)]="targetDcdValue.selectedNestedCU.changeUnitIndex"
  >
    <option value="-1" selected>Select Change Unit</option>
    <option *ngFor="let targetCu of targetNestedCu; let targetIndex = index" [value]="targetIndex">
      {{ targetCu.name }}</option
    >
  </select>
</div>

<mat-radio-group
  *ngIf="targetDcdValue?.selectedNestedCU.selectedChangeUnit?.mindCUList?.length > 0"
  (change)="changeType()"
  name="nested_or_layers_select"
  aria-label="Select Layers Or Nested"
  [(ngModel)]="layerOrNested"
>
  <mat-radio-button value="Layers">Layers</mat-radio-button>
  <mat-radio-button value="Nested">Nested</mat-radio-button>
</mat-radio-group>

<app-nested-connectors
  *ngIf="openNested"
  [targetDcdValue]="targetDcdValue.selectedNestedCU"
  [dcdNumber]="dcdNumber + 1"
>
</app-nested-connectors>

<div
  class="form-group"
  *ngIf="targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.layers?.length > 0 && layerOrNested == 'Layers'"
>
  <label>Layer</label>
  <select
    class="custom-select"
    aria-label=""
    name="layer_select"
    (change)="changeTargetLayer(targetDcdValue.selectedNestedCU.layerType)"
    [(ngModel)]="targetDcdValue.selectedNestedCU.layerType"
    aria-label=""
  >
    <option value="">Select</option>
    <option
      *ngFor="let layer of targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.layers; let layerIndex = index"
      [value]="layer.type"
    >
      {{ layer.type }}
    </option>
  </select>
</div>
<div
  class="form-group"
  *ngIf="targetDcdValue?.selectedNestedCU?.layerType && targetDcdValue.selectedNestedCU.participatingItems?.length > 0"
>
  <mat-label class="field-label">Object</mat-label>
  <select
    class="custom-select"
    aria-label=""
    name="select_object"
    (change)="changeTargetEntity(targetDcdValue.selectedNestedCU.entityIndex)"
    [(ngModel)]="targetDcdValue.selectedNestedCU.entityIndex"
  >
    <option value="-1" selected>Select Option</option>

    <option
      *ngFor="let entity of targetDcdValue?.selectedNestedCU?.participatingItems; let entityIndex = index"
      [value]="entityIndex"
      >{{ entity.name }}</option
    >
  </select>
</div>
<ng-container
  *ngIf="targetDcdValue?.selectedNestedCU?.nslAttributes?.length > 0"
  [ngTemplateOutlet]="attributeTargetDDBlock"
  [ngTemplateOutletContext]="{
    entity: targetDcdValue?.selectedNestedCU
  }"
>
</ng-container>

<ng-template #attributeTargetDDBlock let-entity="entity">
  <!-- Attribute Selection Starts -->
  <div class="form-group" *ngIf="entity.nslAttributes.length > 0">
    <label class="field-label">Attributes</label>
    <select
      class="custom-select"
      aria-label=""
      name="select_attribute"
      (change)="changeTargetAttribute(entity, $event.target.value)"
    >
      <option value="-1" selected>Select Attribute</option>
      <option *ngFor="let attr of entity.nslAttributes; let attrIndex = index" [value]="attrIndex">
        {{ attr.name }}
      </option>
    </select>
  </div>
  <div *ngIf="entity.nslAttributes.length > 0">
    <div *ngFor="let attr of entity.nslAttributes">
      <ng-container
        *ngIf="attr.generalEntity && attr.isSelected"
        [ngTemplateOutlet]="attributeTargetDDBlock"
        [ngTemplateOutletContext]="{
          entity: attr.generalEntity
        }"
      >
      </ng-container>
    </div>
  </div>
  <!-- Attribute Selection Ends -->
</ng-template>
