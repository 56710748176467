import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Librarytypes,
  NodeGsiFacadeService,
  TranslatorService,
  pagination
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-system-entities',
  templateUrl: './node-system-entities.component.html',
  styleUrls: ['./node-system-entities.component.scss'],
})
export class NodeSystemEntitiesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Input() isFromGsi: boolean;
  @Output() betCountEmitter = new EventEmitter();
  // @Input() currentComponent : any;
  pageIndex: number = 0;
  pageSize: number = 15;
  searchText: string = '';
  isPublished: any = '';
  entityList: any = {
    data: [],
    totalResults: [],
  };
  displayNodesearch: any;
  @Input() nodeSection: boolean = false;
  filterType: string = 'all';
  openModal: boolean = false;
  selectedEntity: any;
  title: string;
  type: string;
  libraryType: any;
  libraryData: any;
  labels: any;

  index: any;
  currentSubAttributeEvent: any;
  layerType: any;
  gsiList: any;
  tagInd: any;
  indCu: string = '';
  IsChecked: boolean = false;
  checkedAttributeList: any[] = [];
  isHybrid: boolean = false;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private entityFacadeService: NodeEntityFacadeService,
    private changeUnitFacadeService: NodeChangeUnitFacadeService
  ) {
    /* istanbul ignore next */
    this.libraryPanelEntityfilter();
    /* istanbul ignore next */
    if (location?.pathname?.includes('newsolution')) {
      this.isHybrid = true;
    }

    this.getAllEntities();
    this.detectEntityList();
    this.getLibraryData();
    this.detectLanguageChange();
    this.detectAddEntInGso();
    this.getIndCu();
  }

  ngOnInit(): void {}
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getIndCu() {
    this.entityFacadeService.nodeSeparateCu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.indCu = res;
      }
    });
  }
  getAllEntities() {
    /* istanbul ignore next */

    if (this.filterType == 'published') {
      this.isPublished = true;
    } else if (this.filterType == 'drafts') {
      this.isPublished = false;
    } else {
      this.isPublished = '';
    }
    this.entityFacadeService.getAllEntities(this.pageIndex, this.pageSize, this.searchText, this.isPublished);
  }

  detectAddEntInGso() {
    this.gsiFacade.entityinGsi$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res != undefined) {
        this.isFromGsi = res;
      }
    });
  }
  addEntity(entity: any) {
    if (this.isFromGsi) {
      this.entityFacadeService.loadAttributesByEntId(entity.id, null, null, null, null, this.isFromGsi);
      this.isFromGsi = false;
    } else {
      this.entityFacadeService.loadAttributesByEntId(entity.id, null, null, null, null, null, null, null, this.indCu);
    }
  }
  cloneEntity(entity: any) {
    this.openModal = true;
    this.title = 'Rename the Cloned Entity';
    this.type = 'entity';
    this.selectedEntity = entity;
  }
  loadData(event: any) {
    if (this.filterType == 'published') {
      this.isPublished = true;
    } else if (this.filterType == 'drafts') {
      this.isPublished = false;
    } else {
      this.isPublished = '';
    }
    this.getAllEntities();
  }
  detectEntityList() {
    this.entityFacadeService.entityList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.entityList = res.result;
      this.betCountEmitter.next(res?.result?.totalResults)
    });
  }
  onPageChange(pageData: pagination) {
    this.pageSize = pageData.pageSize;
    this.pageIndex = pageData.pageIndex;
    this.getAllEntities();
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addNewEntity() {
    if (this.nodeSection) {
      this.entityFacadeService.addNewEntity(true);
    } else {
      this.entityFacadeService.addNewIndEntity(true);
    }
  }

  expandEntity(entity: any) {
    this.gsiFacade.viewBetDetails(entity.id, Librarytypes.Entity);
  }
  getLibraryData() {
    this.gsiFacade.viewLibraryData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.libraryData = res;
        const foundIndex = this.entityList.data.findIndex((gsi: any) => gsi.id == res.id);
        /* istanbul ignore else */
        if (foundIndex != -1) {
          this.entityList.data[foundIndex].displayList = res.nslAttributes;
        }
      }
    });
  }
  addAttrToEntity(entity: any) {
    this.entityFacadeService?.loadAttributesByEntId(
      entity.id,
      this.checkedAttributeList,
      null,
      null,
      null,
      null,
      this.isFromGsi
    );
  }
  Checkboxchanged(isChecked: any, attr: any) {
    if (isChecked) {
      this.checkedAttributeList?.push(attr);
    } else {
      this.checkedAttributeList?.forEach((data: any, ind: any) => {
        if (data === attr) {
          this.checkedAttributeList?.splice(ind, 1);
        }
      });
    }
  }
  libraryPanelEntityfilter() {
    this.changeUnitFacadeService.libraryPanelEntityfilter$.subscribe((res: any) => {
      if (res?.entityType === 'source') {
        this.filterType = 'published';
      } else {
        this.filterType = 'all';
      }
    });
  }
}

