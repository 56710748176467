import { NgModule } from '@angular/core';
import { LivechatLibComponent } from './livechat-lib.component';
import { LivechatSmartModule } from './livechat-smart/livechat-smart.module';
import { EntityCardsModule } from 'entity-cards';


@NgModule({
  declarations: [
    LivechatLibComponent
  ],
  imports: [
    LivechatSmartModule,
    EntityCardsModule
  ],
  exports: [
    LivechatLibComponent,
    LivechatSmartModule
  ]
})
export class LivechatLibModule { }
