import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GsiFacadeService } from '../../gsi/gsi-facade.service';
import { ErrorHandlerServiceService } from '../../http/error-handler-service.service';
import { RolesFacadeService } from '../../roles/roles-facade.service';
import { SolutionDictionaryFacadeService } from '../../solution-dictionary/solution-dictionary-facade.service';
import { TranslatorService } from '../../translator/translator.service';

@Component({
  selector: 'app-bet-book-overview',
  templateUrl: './book-overview.component.html',
  styleUrls: ['./book-overview.component.scss'],
})
export class BookOverview implements OnInit, OnChanges {
  @Input() bookFinalize: any;
  @Input() solutionChanges: any;
  @Input() bookDetails: any;
  @Input() NoOfRolesConflictedIndividually: any;
  @Input() isDsdRhs: boolean;
  @Input() betType: string;
  @Input() saasapplication: boolean;
  @Input() targetTenant: string;
  dsdId: any;
  free: boolean = true;
  labels: any;
  isMaped: boolean = false;
  isMerged: boolean = false;
  isRenamed: boolean = false;
  isBookRenamed: boolean = false;
  isGsiRenamed: boolean = false;
  isCuRenamed: boolean = false;
  isEntRenamed: boolean = false;
  isConnRenamed: boolean = false;
  ngUnsubscribe = new Subject();
  configurationType: string;
  isRightSidebar: boolean;
  errorData: any;
  fileName: string = '';

  constructor(
    private router: Router,
    private translator: TranslatorService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private dialogRef: MatDialog,
    private errorHandler: ErrorHandlerServiceService,
    private gsiFacadeService: GsiFacadeService,
    private rolesFacade: RolesFacadeService
  ) {
    this.errorHandlerData();
    this.checkBetImportStatus();
    this.detectFileNameToImport();
    this.checksaasImportStatus();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.dsdId = this.bookDetails?.dsdId ? this.bookDetails?.dsdId : this.bookDetails?.marketplaceId?.dsdId;
    this.detectLanguageChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.detailsInfo();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  errorHandlerData() {
    this.errorHandler.errorMesssages.subscribe((res: any) => {
      if (res?.isSuccessful) {
        this.configurationType = 'NoErrors';
      } else {
        this.errorData = res;
        this.isRightSidebar = true;
        this.configurationType = 'Errors';
      }
    });
  }

  cancel() {
    if (!this.saasapplication) {
      if (this.isDsdRhs) {
        this.dialogRef.closeAll();
      } else {
        if (this.betType === 'gsiImport') {
          this.rolesFacade.closeDialog(true);
          this.router.navigateByUrl('/admin/importsolution');
        } else {
          this.router.navigateByUrl('/solution-dictionary');
        }
        this.dialogRef.closeAll();
      }
    } else {
      this.dialogRef.closeAll();
      this.solutionDictionaryFacade.saasapplicationBetSubect.next(null);
      this.router.navigateByUrl('admin/manage-saasapplications/addSaasApplication');
    }
    this.solutionDictionaryFacade.closepopupDataService();
  }

  importFromBetStore() {
    let data = {
      conflictsAndResolutions: JSON.stringify(this.bookFinalize?.conflictsAndResolutions),
    };
    if (this.saasapplication) {
      let systemUserDetails = '';
      let useremail = JSON.parse(localStorage.getItem('ActiveUserInfo')).email;
      systemUserDetails = `${this.targetTenant}:${useremail}`;
      this.solutionDictionaryFacade.betstoreSaas(data, this.dsdId, this.targetTenant, systemUserDetails, this.betType);
    } else {
      if (this.betType) {
        if (this.betType === 'gsiImport') {
          this.gsiFacadeService.importSolutionWithFile(data);
        } else {
          this.solutionDictionaryFacade.betStoreImport(data, this.dsdId, this.betType);
        }
      } else {
        this.solutionDictionaryFacade.betStoreBookImport(data, this.dsdId);
      }
    }
  }
  checkBetImportStatus() {
    this.solutionDictionaryFacade.betImportStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.router.navigateByUrl('/cdui/solution-dictionary');
      }
    });
  }
  detailsInfo() {
    /* istanbul ignore next */
    this.bookFinalize?.conflictsAndResolutions?.roleConflicts?.forEach((element: any, ind: number) => {
      /* istanbul ignore else */
      if (element.conflictAction == 'MAP') {
        this.isMaped = true;
      } else if (element.conflictAction == 'MERGE') {
        this.isMerged = true;
      } else if (element.conflictAction == 'RENAME') {
        this.isRenamed = true;
      }
    });
    /* istanbul ignore next */
    this.bookFinalize?.conflictsAndResolutions?.bookConflicts?.forEach((element: any, ind: number) => {
      if (element.conflictAction == 'RENAME') {
        this.isBookRenamed = true;
      }
    });
    /* istanbul ignore next */
    this.bookFinalize?.conflictsAndResolutions?.gsiConflicts?.forEach((element: any, ind: number) => {
      if (element.conflictAction == 'RENAME') {
        this.isGsiRenamed = true;
      }
    });
    /* istanbul ignore next */
    this.bookFinalize?.conflictsAndResolutions?.cuConflicts?.forEach((element: any, ind: number) => {
      if (element.conflictAction == 'RENAME') {
        this.isCuRenamed = true;
      }
    });
    /* istanbul ignore next */
    this.bookFinalize?.conflictsAndResolutions?.geConflicts?.forEach((element: any, ind: number) => {
      if (element.conflictAction == 'RENAME') {
        this.isEntRenamed = true;
      }
    });
    /*istanbul ignore next */
    this.bookFinalize?.conflictsAndResolutions?.connectionConflicts?.forEach((element: any, ind: number) => {
      if (element.conflictAction == 'RENAME') {
        this.isConnRenamed = true;
      }
    });
  }
  closeRightBar(event: any) {
    /* istanbul ignore next */
    if (event) {
      this.isRightSidebar = false;
      this.configurationType = 'false';
    }
  }

  detectFileNameToImport() {
    this.solutionDictionaryFacade.fileNameToImport$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.fileName = res;
      }
    });
  }

  checksaasImportStatus() {
    this.solutionDictionaryFacade.saasImport$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.saasApplicationImportedCdm();
        this.router.navigateByUrl('/admin/manage-saasapplications/addSaasApplication');
      }
    });
  }

  saasApplicationImportedCdm() {
    let payload = {
      betName: this.bookDetails.name,
      betId: this.bookDetails.marketplaceId.approverMetaId,
      betType: this.betType.toUpperCase(),
      betVersion: this.bookDetails.version,
    };
    this.solutionDictionaryFacade
      .saasApplicationImportedCdm(this.targetTenant, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
        }
      });
  }
}
