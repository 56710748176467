import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IsJsonString, gsiMapper, LoaderService, GsiFacadeService, TranslatorService, TransactionFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pushback-transaction-view',
  templateUrl: './pushback-transaction-view.component.html',
  styleUrls: ['./pushback-transaction-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PushbackTransactionViewComponent implements OnInit {
  unSubscribe = new Subject();

  @Input() callTriggerPushback?: number = 0;
  @Input() selectedCuIndexForPushback: number = -1;
  @Input() comment: any = '';
  @Output() isPushbackEnabled = new EventEmitter();
  @Output() isPushbackCommentEnabled = new EventEmitter();
  @Output() sendCUsListForPushback = new EventEmitter();
  @Output() sendSelectedCuIndexForPushback = new EventEmitter();
  @Output() sendCommentsOnCU = new EventEmitter();
  @Output() clearComment = new EventEmitter();

  gsiDetailsForPushback: any;
  CuDataForPushback: any;
  CUsListForPushback: any = new Array();
  targetCUContextualId: any;
  commentsOnCU: any = [];
  latestCommentsOnCU: any = [];
  userInfo: any;
  labels: any;

  constructor(
    private transactionFacadeService: TransactionFacadeService,
    private loader: LoaderService,
    private gsiFacade: GsiFacadeService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
    this.detectCurrentCU();
    this.getAllCommentsForSelectedCU();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.userInfo = localStorage.getItem('userInfo');
    /* istanbul ignore next */
    this.userInfo = IsJsonString(this.userInfo) ? JSON.parse(this.userInfo) : {};
  }

  ngOnChanges(changes: SimpleChanges) {
    /* istanbul ignore next */
    if (changes?.callTriggerPushback && this.callTriggerPushback) {
      this.triggerPushback();
      this.callTriggerPushback = 0;
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  // Get current CU details from Changeunit API
  detectCurrentCU() {
    this.transactionFacadeService.listChangeUnit$.pipe(takeUntil(this.unSubscribe)).subscribe((cuData: any) => {
      /* istanbul ignore next */
      if (cuData && (cuData?.status === 200 || cuData?.result)) {
        /* istanbul ignore next */
        this.CuDataForPushback = cuData?.result;
        this.selectedCuIndexForPushback = -1;
        this.sendSelectedCuIndexForPushback.emit(-1);
        this.CUsListForPushback = [];
        this.commentsOnCU = [];
        this.sendCommentsOnCU.emit(this.commentsOnCU);
        this.latestCommentsOnCU = [];
        /* istanbul ignore next */
        if (this.CuDataForPushback?.enablePushback || this.CuDataForPushback?.enableComments) {
          this.isPushbackEnabled.emit(true);
          this.isPushbackCommentEnabled.emit(this.CuDataForPushback?.enableComments ? true : false);
          let SS_contextualId = sessionStorage.getItem('contextualId');
          let mainGSIid = '';
          /* istanbul ignore next */
          if (SS_contextualId && SS_contextualId?.split('.')?.[0]?.substring(2)) {
            mainGSIid = SS_contextualId.split('.')[0].substring(2);
          }
          /* istanbul ignore next */
          let data = {
            txnId: this.CuDataForPushback?.transId,
            gsiId: mainGSIid,
            tfId: this.CuDataForPushback?.currentCU?.id,
            contextualId: this.CuDataForPushback?.currentCU?.contextualId,
          };

          this.loader.show();
          this.gsiFacade
            .fetchCUsInTransForPushback(data)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((targetCuListData: any) => {
              this.loader.hide();
              /* istanbul ignore next */
              if (
                targetCuListData &&
                targetCuListData?.status === 200 &&
                targetCuListData?.result &&
                targetCuListData?.result?.length > 0
              ) {
                /* istanbul ignore next */
                this.CUsListForPushback = targetCuListData?.result;
                this.sendCUsListForPushback.emit(this.CUsListForPushback);
                /*istanbul ignore next*/
                if (this.CUsListForPushback?.length === 1) {
                  this.selectedCuIndexForPushback = 0;
                  this.sendSelectedCuIndexForPushback.emit(0);
                }
                /* istanbul ignore next */
                if (mainGSIid) {
                  this.detectGsiDetails(mainGSIid);
                } else {
                  /* istanbul ignore next */
                  this.detectGsiDetails(cuData?.result?.gsiId);
                }
              }
            });

          this.updateCommentsList(this.CuDataForPushback?.commentsOnCU);
        } else {
          // this.enableBackBtn.emit(false);
          this.isPushbackEnabled.emit(false);
          this.sendCUsListForPushback.emit(this.CUsListForPushback);
          this.isPushbackCommentEnabled.emit(false);
        }
      } else {
        // this.enableBackBtn.emit(false);
        this.isPushbackEnabled.emit(false);
        this.sendCUsListForPushback.emit(this.CUsListForPushback);
        this.isPushbackCommentEnabled.emit(false);
      }
    });
  }

  // Detect GSI details for pushback
  detectGsiDetails(gsiId: any) {
    this.loader.show();
    this.gsiFacade
      .getGSIDetailsById(gsiId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        this.gsiDetailsForPushback = gsiMapper(res?.result);
      });
  }

  triggerPushback() {
    /* istanbul ignore next */
    if (this.CUsListForPushback?.length === 1) {
      this.sendCommentOrPushBack();
    } else if (
      !this.CuDataForPushback?.enablePushback &&
      this.CuDataForPushback?.enableComments &&
      this.selectedCuIndexForPushback >= 0 &&
      this.comment?.length > 0 &&
      this.CUsListForPushback.length > 1
    ) {
      this.sendComment();
    } else if (
      this.CuDataForPushback?.enablePushback &&
      this.selectedCuIndexForPushback >= 0 &&
      this.CUsListForPushback.length > 1
    ) {
      this.sendPushback();
    }
  }

  sendCommentOrPushBack() {
    /* istanbul ignore next */
    if (this.CuDataForPushback?.enablePushback && this.selectedCuIndexForPushback >= 0) {
      this.sendPushback();
    } else if (
      !this.CuDataForPushback?.enablePushback &&
      this.CuDataForPushback?.enableComments &&
      this.selectedCuIndexForPushback >= 0 &&
      this.comment?.length
    ) {
      this.sendComment();
    }
  }

  // Send Pushback and get next CU details
  sendPushback() {
    this.targetCUContextualId = '';
    /* istanbul ignore next */
    this.getTargetCUContextualId(
      this.gsiDetailsForPushback,
      this.CUsListForPushback[this.selectedCuIndexForPushback]?.triggerCUId
    );
    /* istanbul ignore next */
    let data = {
      rootGsiId: this.gsiDetailsForPushback?.id,
      rootGSIMasterId: this.gsiDetailsForPushback?.masterId,
      sourceTriggerCUId: this.CuDataForPushback?.currentCU?.id,
      targetTriggerCUId: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUId,
      txnId: this.CuDataForPushback?.transId,
      sourceCUContextualId: this.CuDataForPushback?.currentCU?.contextualId,
      targetCUContextualId: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUContextualId
        ? this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUContextualId
        : this.targetCUContextualId,
    };

    // If user added a comment in pushback
    if (this.comment) {
      /* istanbul ignore next */
      data['cuCommentDTO'] = {
        comments: [
          {
            commentLogDateTime: new Date().getTime(),
            message: this.comment,
            userId: this.userInfo?.id,
            userName: this.userInfo?.name,
          },
        ],
        sourceTriggerCuId: this.CuDataForPushback?.currentCU?.id,
        targetTriggerCuId: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUId,
        sourceCUContextualId: this.CuDataForPushback?.currentCU?.contextualId,
        targetCUContextualId: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUContextualId
          ? this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUContextualId
          : this.targetCUContextualId,
        sourceTriggerCuName: this.CuDataForPushback?.currentCU?.name,
        targetTriggerCuName: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUName,
      };
      /* istanbul ignore next */
      data['userId'] = this.userInfo?.id;
      /* istanbul ignore next */
      data['userName'] = this.userInfo?.name;
    }
    this.comment = '';
    this.clearComment.emit(this.comment);
    this.transactionFacadeService.saveTransPushbackData(data);
  }

  // Send Comment
  sendComment() {
    this.targetCUContextualId = '';
    /* istanbul ignore next */
    this.getTargetCUContextualId(
      this.gsiDetailsForPushback,
      this.CUsListForPushback[this.selectedCuIndexForPushback]?.triggerCUId
    );

    /* istanbul ignore next */
    let data = {
      comments: [
        {
          commentLogDateTime: new Date().getTime(),
          message: this.comment,
          userId: this.userInfo?.id,
          userName: this.userInfo?.name,
        },
      ],
      sourceTriggerCuId: this.CuDataForPushback?.currentCU?.id,
      targetTriggerCuId: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUId,
      sourceTriggerCuName: this.CuDataForPushback?.currentCU?.name,
      targetTriggerCuName: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUName,
      sourceCUContextualId: this.CuDataForPushback?.currentCU?.contextualId,
      targetCUContextualId: this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUContextualId
        ? this.CUsListForPushback?.[this.selectedCuIndexForPushback]?.triggerCUContextualId
        : this.targetCUContextualId,
    };
    this.comment = '';
    this.clearComment.emit(this.comment);
    /* istanbul ignore next */
    this.transactionFacadeService.saveTransPushbackCommentData(this.CuDataForPushback?.transId, data);
  }

  getTargetCUContextualId(gsiDetails: any, tfId: any) {
    /* istanbul ignore next */
    gsiDetails?.solutionLogic?.forEach((cu: any) => {
      /* istanbul ignore next */
      if (cu?.tfId === tfId && cu?.contextualId) {
        /* istanbul ignore next */
        this.targetCUContextualId = cu?.contextualId[0];
      }
      if (cu.cuType === 'GSI') {
        this.getTargetCUContextualId(cu, tfId);
      }
    });
  }

  // Get all comments for a cu
  getAllCommentsForSelectedCU() {
    this.transactionFacadeService.listOfAllComments$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.updateCommentsList(res);
      }
    });
  }

  // Update list of all comments
  updateCommentsList(res: any) {
    /* istanbul ignore next */
    if (res && res?.length > 0) {
      this.commentsOnCU = [];
      this.latestCommentsOnCU = [];

      this.commentsOnCU = res;
      this.sendCommentsOnCU.emit(this.commentsOnCU);
      /* istanbul ignore next */
      this.commentsOnCU?.forEach((element: any) => {
        /* istanbul ignore next */
        if (element?.comments && element?.comments?.length > 0) {
          /* istanbul ignore next */
          element.comments = element?.comments.sort((a: any, b: any) => b.commentLogDateTime - a.commentLogDateTime);
          /* istanbul ignore next */
          element.comments?.forEach((comment: any) => {
            let dateObj = new Date(comment.commentLogDateTime);
            comment.displayDate = dateObj.toLocaleDateString() + ' ' + dateObj.toLocaleTimeString();
          });

          /* istanbul ignore next */
          if (element?.comments?.length > 0) {
            /* istanbul ignore next */
            this.latestCommentsOnCU?.push(element.comments[0]);
          }
          if (element?.comments?.length > 1) {
            this.latestCommentsOnCU?.push(element.comments[1]);
          }

          /* istanbul ignore next */
          if (this.latestCommentsOnCU?.length > 2) {
            /* istanbul ignore next */
            this.latestCommentsOnCU = this.latestCommentsOnCU.sort(
              (a: any, b: any) => b?.commentLogDateTime - a?.commentLogDateTime
            );
            /* istanbul ignore next */
            this.latestCommentsOnCU?.splice(2);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
