<div class="viewroles-wrap">
  <div class="mapping-details w-100">
    <div class="rolemap-tables">
      <!-- if conflict is present -->
      <div class="table-responsive" *ngIf="conflicted === false">
        <table class="table" aria-describedby="">
          <!-- Heading and Rename button -->
          <thead>
            <tr>
              <th class="d-flex justify-content-between" id="">
                <div>View datasets with conflict</div>
              </th>
              <th class="text-right" id="">
                <button mat-stroked-button class="cstm-btn" (click)="renameDataset()">
                  {{ labels?.Rename }}
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- List of all datasets -->
            <tr *ngFor="let dataset of datasetsConflicted; let i = index">
              <td class="conflict-active">
                <span *ngIf="dataset?.isConflictResolved === false && dataset?.conflicting === true">
                  <mat-checkbox class="cstm-check" (change)="changeDataset($event, dataset, i)">
                    {{ dataset?.sourceValue }}
                  </mat-checkbox>
                </span>
                <span *ngIf="dataset?.isConflictResolved === true && dataset?.conflicting === true">
                  <mat-checkbox
                    class="cstm-check"
                    [disabled]="dataset.isResolvedcon"
                    (change)="changeDataset($event, dataset, i)"
                    *ngIf="dataset.conflictAction && dataset.isResolvedcon"
                  >
                    {{ dataset?.targetValue }}
                  </mat-checkbox>
                </span>
                <span *ngIf="!dataset.isResolvedcon && dataset?.targetValue === ''">
                  <mat-icon>
                    warning_amber
                  </mat-icon>
                  {{ labels?.Resolve_Conflict }}
                </span>
              </td>

              <td>
                <span *ngIf="dataset?.isConflictResolved && dataset.isResolvedcon">
                  {{ dataset.message }}
                </span>
                <span *ngIf="dataset.selectedNameDataset">
                  <input type="text" [(ngModel)]="dataset.targetValue" (keydown.enter)="saveName()" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- if conflict is not present -->
      <div *ngIf="conflicted === true">
        No Conflicted Dataset
      </div>

      <!-- Next and Cancel button -->
      <div class="configure-btns btns-custom">
        <button class="btn btn-next btn-active" (click)="moveToNextTab()" [disabled]="checkConflicts()">
          {{ labels?.Next }}
        </button>
        <button class="btn btn-cancel" (click)="cancel()">
          {{ labels?.CANCEL }}
        </button>
      </div>
    </div>
  </div>
</div>
