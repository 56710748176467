<section class="tab-body">
  <ul class="common-list">
    <li *ngFor="let entity of entityList?.data">
      <mat-accordion>
        <mat-expansion-panel hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <div class="d-flex item align-items-center">
              <div class="details">
                <p class="sub">{{ entity?.status }} [{{ entity?.version }}]</p>
                <p
                  class="title"
                  pTooltip="{{ entity?.name }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                >
                  {{ entity?.name }}
                </p>
              </div>
              <a class="more-icon"><span></span></a>
            </div>
          </mat-expansion-panel-header>
          <div *ngIf="entity?.nslAttributes.length > 0">
            <div class="mat-elevation-z0" *ngFor="let attr of entity.nslAttributes; let i = index">
              <div class="d-flex">{{ attr?.name }}<a class="create-new-btn"></a></div>
              <div class="tab-body" *ngIf="attr?.generalEntity !== undefined">
                <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</section>
