import {
  Component,
  Input,
  Renderer2,
  HostListener,
  Directive,
  ElementRef,
  TemplateRef,
  ViewContainerRef,
  ContentChild,
  ComponentRef,
} from '@angular/core';

@Directive({ selector: '[tooltipDirective]' })
export class TooltipDirective {
  private tooltipId: string;
  tooltip: any;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input() parametroPlantilla: TemplateRef<any>;
  @Input() displayValue: any;

  @ContentChild('tooltipTemplate')
  private tooltipTemplateRef: TemplateRef<Object>;

  /* istanbul ignore next */

  @HostListener('mouseenter') onMouseEnter(): void {
    // const title = this.elementRef.nativeElement.innerText.slice(0,13);
    this.tooltip = this.renderer.createElement('div');
    this.tooltip.className = 'tool-tip-container';
    this.tooltip.style.position = 'absolute';
    this.tooltip.style.zIndex = 99999999;
    this.tooltip.textContent = this.displayValue;
    this.update('enter');
    this.renderer.appendChild(document.body, this.tooltip);
  }

  /* istanbul ignore next */
  update(action: any, e: any = true) {
    // console.log(action);
    const rect = this.elementRef?.nativeElement?.getBoundingClientRect();
    // this.renderer.addClass(e, 'tool-tip-container');
    // console.log('rect', rect);
    const offsetX = 25; // Adjust this value to control the distance from the mouse
    const offsetY = 25; // Adjust this value to control the distance from the mouse
    let self = this;
    if (e) {
      if (rect?.top) {
        this.renderer?.setStyle(self.tooltip, 'top', e.clientY + window.scrollY + offsetY + 'px');
      }

      if (rect?.left) {
        this.renderer?.setStyle(self.tooltip, 'left', e.clientX + window.scrollX + offsetX + 'px');
      }
    }

    // this.tooltip.style.top ='200px'
  }

  /* istanbul ignore next */
  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null; 
    }
  }
  @HostListener('mousemove', ['$event']) onMouseMove(e: any) {
    // console.log(e)
    if (this.tooltip) {
      this.update('move', e);
    }
  }
  @HostListener('click') onMouseClick(): void {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }
}

