import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, EntityEndpointService, LoaderService, RolesFacadeService, TranslatorService } from '@common-services';
import { EntityBoardEndpointService } from '@common-services';
import { EntityBoardFacadeService } from '@common-services';
import { TableDropComponent} from 'entity-cards';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-table-configurtion',
  templateUrl: './table-configurtion.component.html',
  styleUrls: ['./table-configurtion.component.scss'],
})
export class TableConfigurtionComponent extends TableDropComponent implements OnInit {
  isEntityTable: boolean = true;
  cardformGroup: FormGroup;
  toolTipMsg: string = 'Image cannot be filtered.';
  options: string[] = ['filter', 'sort', 'searchFields', 'search', 'locationFilter'];
  @Input() isHybrid: boolean = false;
  @Output() emitTableConfigData = new EventEmitter();
  buttonstyle: boolean = false;
  files: any = [];
  rawSheetData: any;

  public entityFacade: NodeEntityFacadeService ;
  constructor(
    router: Router,
    rolesFacadeService: RolesFacadeService,
    alertService: AlertService,
    entityBoardService: EntityBoardEndpointService,
    public dialog: MatDialog,
    // livechatFacade: LivechatFacadeService,
    loader: LoaderService,
    el: ElementRef,
    entityEndpointService: EntityEndpointService,
    entityBoardFacade: EntityBoardFacadeService,
    translator: TranslatorService,
    injector: Injector,
    resolver: ComponentFactoryResolver,
  ) {
    super(
      router,
      rolesFacadeService,
      alertService,
      entityBoardService,
      dialog,
      loader,
      el,
      entityEndpointService,
      entityBoardFacade,
      translator,
      resolver,
    );
    if(!this.entityFacade){
      this.entityFacade = injector.get( NodeEntityFacadeService);
    }
  }
  goBackToSolution() {
    if (this.isAdvancedFeature) {
      this.isTable = true;
      this.isCardConfig  = true;
      this.isAdvancedFeature = false;
    } else if(this.isTable){
      this.entityFacade.entityTableUIConfiguration(false);
    }
  }
  ngOnInit(): void {
    this.selectedEntityData = this.isHybrid ? this.entityData?.data : this.entityData?.data?.entityData;
    super.getMapAttributes();
    this.getEntityDetails();
    this.checkEventGSI();
    this.tableFreezePosition = '';
  }

  checkEventGSI() {
    const tempEventCUList = this.isHybrid ? this.entityData?.eventCUList : this.entityData?.data?.cu?.eventCUList;
    this.cuEventList = tempEventCUList?.filter((attr: any) => attr.eventType == 'ON_SELECT');
    let temp:any = [];
    this.selectedEntityData?.nslAttributes.forEach((attr: any) => {
      if (this.cuEventList?.length > 0) {
        this.cuEventList?.forEach((event: any, index: number) => {
          if (event?.changeDriverId == attr.id) {
            // event['gsiConfiguration'] = {};
            let idx = this.dropActionBts.findIndex((bts:any)=>bts?.changeDriverId == attr?.id);
            if(idx != -1)
                temp[index] = this.dropActionBts[idx]?.gsiConfiguration?{...event,  ...{gsiConfiguration : this.dropActionBts[idx]?.gsiConfiguration}}:event;
            else temp[index] = event;
          }
        });
      }
    });
    this.dropActionBts = temp?.filter((attr:any)=>(attr != null && attr !=undefined));
  }

  getEntityDetails() {
    // if (this.entityData.data?.entityData?.configuration) {
    //   this.updateExistingConfiguration(JSON.parse(this.entityData.data?.entityData?.configuration));
    // }
    this.selectedEntityData = this.isHybrid ? this.entityData?.data : this.entityData?.data?.entityData;
    /*filtering based on type */
    // this.cuEventList = this.entityData?.data?.cu?.eventCUList.filter((attr: any) => attr.eventType == 'ON_SELECT');
    // this.cuEventList.forEach((event: any) => {
    //   this.selectedEntityData?.nslAttributes.forEach((attr: any) => {
    //     if (event?.changeDriverId == attr.id) this.gsiButtons.push(event);
    //   });
    // });
    // if(this.gsiButtons.length >0)
    // this.containsOnSelectEvent = true;


    if (this.isHybrid ? this.entityData?.data?.configuration : this.entityData?.data?.entityData?.configuration) {
      this.updateExistingConfiguration(
        JSON.parse(
          this.isHybrid ? this.entityData?.data?.configuration : this.entityData?.data?.entityData?.configuration
        )
      );
    } else {
      this.getEntityTableAttributes();
      super.getnestedEntityTableAttributes();
    }

    this.EntityTitle = this.isHybrid ? this.entityData?.data?.name : this.entityData?.data?.entityData?.name;

    this.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
      attr['checked'] = false;
      if (attr.attributeType.type === 'image') {
        this.attributesFields(attr, false, true, 'Image cannot be filtered.', this.options);
      } else {
        this.attributesFields(attr, false, false, '', this.options);
      }
      this.attributes.push({ name: 'Attribute' });
    });
  }
  saveEntityTableData(type: any) {
    if (type) {
      let droppedAttributes: any[] = [];
      if (this.tableData.length > 0) {
        this.tableData?.[0].forEach((attr: any) => {
          droppedAttributes.push(attr?.attributeHeading);
        });
      }
      this.tableConfig = { ...this.getStyleConfig(), ...this.getTableStyles() };
      this.tableConfig['type'] = type;
      if (!this.isHybrid) {
        this.entityFacade.verticalTableEntitySaveDetailsMethod(false, this.tableConfig);
        this.entityFacade.entityTableUIConfiguration(false);
      } else {
        this.emitTableConfigData.emit(this.tableConfig);
      }
    } else {
      this.emitTableConfigData.emit(false);
    }
  }
  attributesFields(attr: any, checked: boolean, disabled: boolean, tooltip: string, options: string[]): void {
    options.forEach((opt: any) => {
      this.addNewFieldToAttributesArray(attr, opt, checked, disabled, tooltip);
    });
    attr['disabled'] = disabled;
    attr['tooltip'] = tooltip;
  }

  getTemplateUrl(event: any) {
    const excel_reader = new FileReader();
    let workSheet: any = null;
    let JSONsheetData: any;
    const template_file = this.rawSheetData?.[0];
    /* istanbul ignore next */
    excel_reader.onload = () => {
      const data = excel_reader.result;
      workSheet = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
      });
      JSONsheetData = workSheet?.SheetNames.reduce(
        (sheet_initial: any, sheet_name: any) => {
          const data1 = workSheet?.Sheets?.[sheet_name];
          sheet_initial[sheet_name] = XLSX.utils.sheet_to_json(data1, {
            blankrows: false,
            raw: false,
            rawNumbers: true,
            header: 0,
            defval: '',
          });
          return sheet_initial;
        },
        {}
      );
      if (JSONsheetData) {
        /* istanbul ignore else */
        const sheetName = workSheet?.SheetNames?.[0];
        const filteredData = Object.keys(workSheet?.Sheets?.[sheetName]).filter((item: string) => {
          const pattern = /^[A-Z]1$/;
          return pattern.test(item);
        });

        if (
          (this.rawSheetData?.target?.files?.[0]?.type == 'text/csv' ||
            this.rawSheetData?.target?.files?.[0]?.type ==
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
            filteredData?.length == this.selectedEntityData?.nslAttributes?.length
        ) {
          this.advanceTableFeatures.tableTemplate = event?.contentUrl;
          // this.formGroup?.controls?.['inputTemplate']?.setValue(event.contentUrl);
        } else if (
          workSheet?.Strings?.Count == this.selectedEntityData?.nslAttributes?.length.toString() ||
          filteredData?.length == this.selectedEntityData?.nslAttributes?.length
        ) {
          this.advanceTableFeatures.tableTemplate = event?.contentUrl;
        } else {
          this.alertService.showToaster('Uploaded file is mismatched to the attributes', '', 'Error');
          this.files = [];
        }
      }
    };
    if (template_file)
    excel_reader.readAsBinaryString(template_file);
  }

  getSheetData(event: any) {
    this.rawSheetData = event?.target?.files;
  }

  updateFiles(event: any) {
    this.files = event;
  }

  resetNestedData(){
    let nestedTableColumns = [];
    this.nestedTableData =[]
    for (let i = 0; i < this.numberOfTableColumns; i++) {
      nestedTableColumns.push({
        attributeHeading: 'Attribute',
        attributeValue: '',
      });
    }
    for (let i = 0; i < this.numberOfTableRows; i++) {
      this.nestedTableData.push(nestedTableColumns);
    }
  }

  nestedRowToggle(event:any){
    if(!event){
      this.onDeletingNestedAttr('',0,true)
      this.resetNestedData();
      this.isNestedRow=event;
    }
  }
}
