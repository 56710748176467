import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { displayUSAMobileFormat, EventsFacadeService, FieldConfig, displayValue, TransactionFacadeService, getUiControlCustomization } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-label',
  styleUrls: ['./label.component.scss'],
  template: `
    <form class="{{ parentClass }}">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            pTooltip="{{ field.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
            </span>
          </label>
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            matSuffix
            class="d-flex align-items-center justify-content-center"
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          >
            <span class="sub-cu" (click)="openSubPopUp()">
              <img class="d-block" src="../assets/img/transaction/trigger-att.svg" />
            </span>
          </mat-icon>
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>

      <div [formGroup]="group" class="form-input-field" *ngIf="displayVal">
        <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon">
          <input
            matInput
            style="display: none;"
            [formControlName]="field?.attribute?.name"
            [value]="field.value"
            [readonly]="true"
          />
          <span *ngIf="field.inputType == 'mobilenumber'">
            {{ field.configuration?.mobileFormat ? field.configuration?.mobileFormat?.actualValue : '+91' }}
          </span>
          <span
            [id]="field?.attribute?.name"
            class="{{ appliedClass }}"
            (click)="onClickValue()"
            *ngIf="!field?.configuration?.configureAsTag"
            [pTooltip]="field?.configuration?.hideTooltip ? '' : displayVal"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [ngClass]="{ strike: field?.configuration?.strikeThrough }"
            [ngStyle]="{
              color: labelTextColor[displayVal],
              'background-color': labelbackgroundColor[displayVal]
            }"
          >
            {{ displayVal }}
          </span>
          <mat-chip-list
            *ngIf="field?.configuration?.configureAsTag"
            class="tags-list"
          >
            <mat-chip
              *ngFor="let selectedOption of field.value"
              [pTooltip]="field?.configuration?.hideTooltip ? '' : selectedOption"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
              [ngStyle]="{
                color: labelTextColor[selectedOption],
                'background-color': labelbackgroundColor[selectedOption]
              }"
            >
              {{ selectedOption?.length > 30 ? selectedOption.slice(0, 30) + '...' : selectedOption }}
              <!-- <mat-icon matChipRemove>cancel</mat-icon> -->
            </mat-chip>
          </mat-chip-list>

          <!-- <span
            [id]="field?.attribute?.name"
            class="line-clamp ui-control-label"
            *ngIf="!field?.configuration?.configureAsTag"
          >
            {{ displayValue(field.value) }}
          </span> -->
          <!-- <mat-chip-list
          *ngIf="field?.configuration?.isReadOnly && field?.configuration?.configureAsTag"
          class="tags-list"
        >
        <mat-chip
            [pTooltip]="field?.configuration?.hideTooltip ? '' : field?.value"
              tooltipPosition="top"
              tooltipStyleClass="transcustomtolltipbook"
            *ngIf="field?.configuration?.isReadOnly && field?.configuration?.configureAsTag"
              [ngStyle]="{
              color: labelTextColor[field?.value],
              'background-color': labelbackgroundColor[field?.value]
              }"
            >
            {{ field.value }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list> -->
          <!-- until here -->
        </mat-form-field>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
    </form>
  `,
  styles: ['.cstm-margin{margin-bottom:10px;} .cstm-margin span {white-space: normal !important}'],
})
export class LabelComponent implements OnInit {
  private unsubscribe: Subject<any> = new Subject<any>();
  field: FieldConfig;
  group: FormGroup;
  cuData: any;
  displayVal: string = '';
  isSelected: boolean = false;
  appliedClass: string = 'ui-control-label';
  parentClass: string = 'errorLables main-lable';
  labelbackgroundColor: any = {};
  labelTextColor: any = {};
  // displayVal: boolean = true;

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacadeService: TransactionFacadeService
  ) {
    this.detectCurrentCu();
    // this.detectSelectedLabel();
  }

  ngOnInit(): void {
    this.displayVal = this.displayValue(this.field.value);
    if (this.field?.configuration?.maskable && !this.field?.isInPotentiality) {
      this.displayVal = '*****';
    } else {
      this.displayVal = this.displayValue(this.field.value);
    }
    let labelOption = getUiControlCustomization('Labels');
    if (labelOption) {
      /* istanbul ignore else */
      if (labelOption == 'Option 5') {
        this.parentClass = 'label-parent-class-styles';
      } else {
        let option = labelOption.slice(-1);
        this.appliedClass = `form-label form-label-opt${option} mb-4`;
      }
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  displayValue(label: any) {
    let dateFormatType = 'MM/dd/yyyy';
    let timeFormatType = 'HH:mm';
    /* istanbul ignore next */
    let colorconfig = this.field?.configuration?.customColorConfiguration;
    if (colorconfig?.length > 0 && this.field?.value?.length > 0) {
      this.checkConditions(colorconfig);
     }
    /* istanbul ignore next */
    if (this.field?.configuration?.dateTimeFormat?.format) {
      dateFormatType = this.field?.configuration?.dateTimeFormat?.format;
    } else if (this.field?.attributeType?.properties?.format) {
      dateFormatType = this.field.attributeType.properties.format;
    }
    let dateFormatsForShowForDropdown = [
      { value: 'dd/MM/yyyy', displayValue: 'dd/mm/yy'},
      { value: 'MM/dd/yyyy', displayValue: 'mm/dd/yy'},
      { value: 'yyyy/MM/dd', displayValue: 'yy/mm/dd'},
    ];
    /*istanbul ignore next*/
    let ind = dateFormatsForShowForDropdown.findIndex((ele: any) => ele.displayValue === dateFormatType);
    /*istanbul ignore next*/
    if(ind >= 0) {
      dateFormatType = dateFormatsForShowForDropdown[ind].value;
    }
    /* istanbul ignore next */
    if (this.field?.attributeType?.properties?.timeFormat) {
      /* istanbul ignore next */
      if (this.field?.attributeType?.properties?.timeFormat === '24-hr') {
        timeFormatType = 'HH:mm';
      } else {
        timeFormatType = 'hh:mm a';
      }
    }
    if (this.field?.attributeType?.properties?.currencyType || this.field?.attributeType?.properties?.unit) {
      return displayValue(label, dateFormatType, timeFormatType, this.field?.labelType);
    } else {
      if (this.field?.configuration?.mobileFormat?.displayValue == 'USA') {
        return displayUSAMobileFormat(this.field?.value);
      } else {
        return displayValue(label, dateFormatType, timeFormatType, '', this.field?.attributeType?.type);
      }
    }
  }
  onClickValue() {
    /* istanbul ignore next */
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute['name'],
      eventType: 'ON_FOCUS',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      entityMasterId: this.field?.entityMasterId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
      attribute_type: this.field?.type,
      attribute_displayName: this.field?.attribute?.displayName,
    };
    /* istanbul ignore next */
    /* istanbul ignore next */
    if (this.cuData?.isReserved && this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review') {
      this.isSelected = true;
      this.eventsService.updateSelctedAttrLabel({ isSelected: this.isSelected, attrName: data?.attrName });
      this.updateDataForPDf(data, this.cuData);
    }
  }
  updateDataForPDf(data: any, cuData: any) {
    /* istanbul ignore next */
    const res = {
      currentCU: cuData,
      selected_atrribute: {
        enity_name: data?.entityName?.split('$')[0],
        attribute_name: data?.attrName?.split('$')[0],
        entity_master_id: data?.entityMasterId,
        attribute_id: data?.attributeId,
        entity_index: data?.entityName?.split('$')[1] - 1,
        // attribute_index: data?.attrName?.split('$')[1],
        attribute_index: 0,
        value_index: 0,
      },
    };
    this.eventsService.getPdfTextCord(res);
  }
  /* istanbul ignore next */
  detectCurrentCu() {
    this.transactionFacadeService.currentCuDetails$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result?.currentCU) {
        this.cuData = res?.result?.currentCU;
      }
    });
  }
  /* istanbul ignore next */
  detectSelectedLabel() {
    this.eventsService.updateLabelColor$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      this.isSelected = false;
      /* istanbul ignore next */
      if (
        this.field?.attribute['name'] == res?.attrName &&
        this.cuData?.isReserved &&
        this.cuData?.reservedCUType == 'NSL_Template_CDEC_Review'
      ) {
        this.isSelected = res?.isSelected;
      }
    });
  }
  checkConditions(colorconfig: any) {
    colorconfig?.forEach((config: any) => {
      this.field?.value.forEach((val: any) => {
        let matchFound = false;
        switch (config?.operator) {
          case '=':
            if (config?.value == val) {
              matchFound = true;
            }
            break;
          case '>':
            if (!isNaN(val) && !isNaN(config?.value) && Number(val) > Number(config?.value)) {
              matchFound = true;
            }
            break;
          case '<':
            if (!isNaN(val) && !isNaN(config?.value) && Number(val) < Number(config?.value)) {
              matchFound = true;
            }
            break;
          case '!=':
            if (!isNaN(val) && !isNaN(config?.value) && Number(val) !== Number(config?.value)) {
              matchFound = true;
            }
            break;
          case '<=':
            if (!isNaN(val) && !isNaN(config?.value) && Number(val) <= Number(config?.value)) {
              matchFound = true;
            }
            break;
          case '>=':
            if (!isNaN(val) && !isNaN(config?.value) && Number(val) >= Number(config?.value)) {
              matchFound = true;
            }
            break;
        }
        if (matchFound) {
          this.labelbackgroundColor[val] = config?.backgroundcolour;
          this.labelTextColor[val] = config?.textcolour;
        }
      });
    });
  }
}
