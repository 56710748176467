<ng-container>
  <div *ngIf="cardType == 'card' && !hideSelectAllButton && !hideSelection" class="select-All">
    <label for="selectAll"
      >{{ labels?.Select_All }}
      <input
        class="allSelect"
        id="selectAll"
        type="checkbox"
        [(ngModel)]="isselectAllCards"
        (click)="selecteAllCards()"
      />
    </label>
  </div>
  <div
    class="transaction-view w-100 transactionCard clenderPosition template-type-{{
      configuration?.customizeCardconfigData?.templateType
    }}"
    #transactionCard
    [ngClass]="{
      threeGrid: selectedGridLaoyout === 'three',
      twoGrid: selectedGridLaoyout === 'two',
      singleGrid: selectedGridLaoyout === 'single',
      expandCardBlock: showExpand
    }"
  >
    <ng-container
      *ngFor="let card of listValues | paginate
    : {
        itemsPerPage: paginationDetails?.pageSize,
        currentPage: paginationDetails?.pageNumber,
        totalItems: paginationDetails?.totalNumberOfRecords
      };; let i = index"
      [style]="{ 'margin-bottom': '2em' }"
    >
      <!-- [ngClass]="{ cardMinH: currentCard !== i, cardFinH: currentCard === i || listValues?.length == 1 }" -->
      <p-card
        *ngIf="cardType == 'card'"
        class="dynamic-card-ui-main"
        [ngClass]="{
          cardMinH: currentCard !== i,
          cardFinH: currentCard === i
        }"
        #dynamicCardUIMain
      >
        <div class="cardId {{ 'card' + i }}"></div>

        <div style="display: flex;">
          <mat-checkbox
            class="example-margin mb-3 d-block"
            (change)="selectedCards($event, i, 'checkbox')"
            [(ngModel)]="card.isChecked"
            *ngIf="configuration?.selectionType !== 'radiobutton' && cardSelection !== 'single' && !hideSelection"
          ></mat-checkbox>
          <mat-radio-button
            *ngIf="(configuration?.selectionType == 'radiobutton' || cardSelection === 'single') && !hideSelection"
            [checked]="card.isChecked"
            name="{{title}}_{{slot}}"
            [value]="card.isChecked"
            (change)="selectedCards($event, i, 'radiobutton')"
          >
            {{ configuration?.lableName }}
          </mat-radio-button>
          <span class="span-minus" *ngIf="listValues.length > 1" (click)="deleteEntityControl(i, card.object)"
            ><mat-icon>remove</mat-icon></span
          >
        </div>
        <div class="att-list" *ngFor="let entity of card.cardArry" [ngClass]="{ hide: entity?.isHidden === true }">
          <ng-container
            dynamicField
            [formData]="entity[entity.attributeName]"
            [group]="entity[entity.attributeName + 'form']"
            [isTableConfig]="true"
            (dateChanged)="dateChnagedEvent($event)"
            (chipDataChanged)="selectedCards($event, i, 'checkbox', true)"
            [isOnSelectSubmit]="cardSelection === 'single' && cardSettings?.submitOnSelection"
          >
          </ng-container>

          <!-- Detect event gsi -->
          <div class="psd-btn" *ngIf="checkEventGSI(entity[entity.attributeName]) && !isInfo">
            <div *ngFor="let currEvent of currentEventData">
              <button
                type="button"
                mat-button
                color="primary"
                (click)="saveEventGSI(currEvent, entity[entity.attributeName])"
              >
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </div>
          </div>
        </div>

        <div class="info-btn" *ngIf="card?.cardArry?.length > 3 || showMoreInfo">
          <span class="cursor-pointer" *ngIf="currentCard === i" (click)="toggleCardHeight(-1)">
            Less Info
          </span>
          <span class="cursor-pointer" *ngIf="currentCard !== i" (click)="toggleCardHeight(i)">
            {{ labels?.more_info }}
          </span>
        </div>
      </p-card>

      <div
        *ngIf="cardType == 'cardWithTemplate' && (configuration?.templateType == 1 || configuration?.templateType == 2)"
        [ngStyle]="{ width: configuration?.templateType == '2' ? '85%' : 'auto' }"
        class="col-4 custom-col-4"
      >
        <div class="d-flex">
          <mat-checkbox
            *ngIf="configuration?.selectionType == 'checkbox'"
            class="example-margin"
            (change)="selectedCards($event, i, 'checkbox')"
            [(ngModel)]="card.isChecked"
            >{{ configuration?.lableName }}</mat-checkbox
          >
          <div class="d-flex">
            <mat-radio-button
              *ngIf="configuration?.selectionType == 'radiobutton'"
              name="{{title}}_{{slot}}"
              [checked]="card.isChecked"
              [value]="card.isChecked"
              (change)="selectedCards($event, i, 'radiobutton')"
            >
              {{ configuration?.lableName }}
            </mat-radio-button>
            <span
              class="span-minus"
              *ngIf="listValues.length > 1 && cardType !== 'card'"
              (click)="deleteEntityControl(i, card.object)"
              ><mat-icon>remove</mat-icon></span
            >
          </div>
        </div>

        <app-template-one-card
          *ngIf="configuration?.templateType == '1'"
          [entity]="card.cardArry"
          [attributePositions]="configuration?.attributePositions"
          [settings]="configuration"
          [eventCUList]="eventCUList"
          (cardselected)="selectedCards($event, i, 'checkbox')"
        >
        </app-template-one-card>

        <app-template-two-card
          *ngIf="configuration?.templateType == '2'"
          [entity]="card.cardArry"
          [attributePositions]="configuration?.attributePositions"
          [settings]="configuration"
          [eventCUList]="eventCUList"
          (cardselected)="selectedCards($event, i, 'checkbox')"
        >
        </app-template-two-card>
      </div>
      <div
        *ngIf="cardType == 'cardWithTemplate' && configuration?.templateType == 'customization'"
        [ngStyle]="{ width: configuration?.templateType == '2' ? '85%' : 'auto' }"
        [ngClass]="
          configuration?.customizeCardconfigData?.templateType == 181 ||
          expandedIndex[i] ||
          configuration?.customizeCardconfigData?.templateType == 303 ||
          configuration?.customizeCardconfigData?.templateType == 308 ||
          configuration?.customizeCardconfigData?.templateType == 307 ||
          configuration?.customizeCardconfigData?.templateType?.includes('dynamic')
            ? ''
            : configuration?.customizeCardconfigData?.templateType == 28 ||
              configuration?.customizeCardconfigData?.templateType == 29 ||
              configuration?.customizeCardconfigData?.templateType == 304 ||
              configuration?.customizeCardconfigData?.templateType == 305 ||
              configuration?.customizeCardconfigData?.templateType == 306 ||
              configuration?.customizeCardconfigData?.templateType == 309 ||
              configuration?.customizeCardconfigData?.templateType == 310 ||
              configuration?.customizeCardconfigData?.templateType == 311 ||
              configuration?.customizeCardconfigData?.templateType == 312 ||
              configuration?.customizeCardconfigData?.templateType == 313 ||
              configuration?.customizeCardconfigData?.templateType == 314 ||
              configuration?.customizeCardconfigData?.templateType == 316 ||
              configuration?.customizeCardconfigData?.templateType == 318 ||
              configuration?.customizeCardconfigData?.templateType == 322 ||
              configuration?.customizeCardconfigData?.templateType == 323 ||
              configuration?.customizeCardconfigData?.templateType == 324 ||
              configuration?.customizeCardconfigData?.templateType == 325 ||
              configuration?.customizeCardconfigData?.templateType == 326 ||
              configuration?.customizeCardconfigData?.templateType == 327 ||
              configuration?.customizeCardconfigData?.templateType == 132 ||
              configuration?.customizeCardconfigData?.templateType == 319 ||
              configuration?.customizeCardconfigData?.templateType == 320 ||
              configuration?.customizeCardconfigData?.templateType == 321 ||
              configuration?.customizeCardconfigData?.templateType == 134 ||
              configuration?.customizeCardconfigData?.templateType == 182 ||
              configuration?.customizeCardconfigData?.templateType == 26 ||
              configuration?.customizeCardconfigData?.templateType == 18 ||
              configuration?.customizeCardconfigData?.templateType == 8 ||
              configuration?.customizeCardconfigData?.templateType == 334 ||
              configuration?.customizeCardconfigData?.templateType == 328 ||
              configuration?.customizeCardconfigData?.templateType == 136
            ? 'entitycard-transaction entity-card-specific'
            : 'entitycard-transaction'
        "
        class="card-tab-view dynamic-expand-template"
      >
        <app-dynamic-ui-card-templates
          [entity]="card.cardArry"
          [settings]="configuration"
          [_dynamicTemplate]="dynamicTemplate"
          [eventCUList]="eventCUList"
          [showExpand]="showExpand"
          [expandIndex]="expandIndex"
          [currentIndex]="i"
          [dCard]="card"
          [listValues]="listValues"
          (selectCardEmitter)="selectedCards($event, i,cardSelection=='single' ? 'radiobutton' : configuration?.customizeCardconfigData?.selectionType)"
          (deleteEventEmitter)="deleteEntityControl(i, card.object)"
          (expandEmitter)="toggleClass($event, i)"
        >
        </app-dynamic-ui-card-templates>
        <app-dynamic-ui-card-ecommerce-child-templates
          [entity]="card.cardArry"
          [settings]="configuration"
          [eventCUList]="eventCUList"
          (cardselected)="selectedCards($event, i, 'checkbox')"
          [listValues]="listValues"
          [dCard]="card"
          (selectCardEmitter)="selectedCards($event, i, configuration?.selectionType)"
          (deleteEventEmitter)="deleteEntityControl(i, card.object)"
          (expandEmitter)="toggleClass($event, i)"
        ></app-dynamic-ui-card-ecommerce-child-templates>

        <app-dynamic-ui-card-insurance-child-templates
          [entity]="card.cardArry"
          [settings]="configuration"
          [eventCUList]="eventCUList"
          (cardselected)="selectedCards($event, i, 'checkbox')"
          [listValues]="listValues"
          [dCard]="card"
          (selectCardEmitter)="selectedCards($event, i, configuration?.selectionType)"
          (deleteEventEmitter)="deleteEntityControl(i, card.object)"
          (expandEmitter)="toggleClass($event, i)"
        >
        </app-dynamic-ui-card-insurance-child-templates></div
    ></ng-container>
  </div>
  <div class="content-footer" *ngIf="customPagination && !isInfo">
    <div class="row align-items-center">
      <div class="col-auto">
        <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
      </div>
    </div>
  </div>
</ng-container>
