<ul [ngClass]="!graphComponent ? 'node-static' : ''">
  <li>
    <div [ngClass]="!graphComponent ? 'node-block sol' : ''">
      <section class="pop-outer">
        <div class="pop-inner">
          <header class="pop-header">
            <div class="row no-gutters flex-nowrap align-items-center range-control agent-control">
              <div class="col">
                <h2 class="pop-title">
                  <span class="clickbk" (click)="GotoPrevious()"
                    ><img [src]="'../../../../assets/node/back.svg'" alt=""
                  /></span>
                  <a> Parallel</a>
                </h2>
              </div>
              <div class="col-auto">
                <button mat-icon-button (click)="removeChangeUnit()">
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
              <div class="col-auto">
                <input [(ngModel)]="palCUList.length" />
              </div>
              <div class="col-auto">
                <button mat-icon-button (click)="addChangeUnit()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </header>
          <div class="pop-body">
            <div class="mb-4">
              <div *ngIf="palCUList.length > 0">
                <div class="no-label no-space" *ngFor="let cu of palCUList; let i = index">
                  <mat-form-field class="d-block" appearance="outline" *ngIf="cu.cuType === 'BASIC'">
                    <input matInput placeholder="Add Parallel Change Unit" [(ngModel)]="cu.name" />
                  </mat-form-field>
                  <div class="d-block mb-2" appearance="outline" *ngIf="cu.cuType === 'GSI'" class="d-flex flex-wrap">
                    <div *ngFor="let cuInGsi of cu.solutionLogic" class="d-flex flex-wrap">
                      <span *ngFor="let tag of cuInGsi?.sentenceTags?.physical" class="d-flex flex-wrap">
                        <span
                          class="fatElement"
                          [ngClass]="{
                            ChangeUnit_ele: tag.tag == 'Int.',
                            entity_ele: tag.tag == 'Obj.',
                            agent_ele: tag.tag == 'Ag.',
                            attribute_ele: tag.tag == 'Att.',
                            human_ele: tag.tag == 'Hum.',
                            machine_ele: tag.tag == 'Mch.'
                          }"
                        >
                          <span class="flatTxt">
                            {{ tag.token }}
                          </span>
                          <span> &nbsp; </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="mb-2">
                    <button class="delete-btn-icon ml-auto" (click)="remove(cu, i)">{{ labels?.Delete }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="add-more-condition">
              <p (click)="selectEmbeddedGsiFromLibrary()">
                <span class="material-icons">
                  {{ labels?.add }}
                </span>
                {{ labels?.Add_From_Library }}
              </p>
            </div>
          </div>
          <footer class="pop-footer">
            <div class="row mb-3">
              <div class="col-auto mx-auto">
                <button class="primary-btn" (click)="saveParallel()">{{ labels?.Done }}</button>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  </li>
</ul>
