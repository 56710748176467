import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService, EntityBoardFacadeService, TranslatorService } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-entity-grid-card-filter',
  templateUrl: './entity-grid-card-filter.component.html',
  styleUrls: ['./entity-grid-card-filter.component.scss'],
})
export class EntityGridCardFilterComponent implements OnInit {
  @Input() filterData: any;
  @Output() filterDataEmitter: EventEmitter<any> = new EventEmitter();
  @Output() searchDataEmitter: EventEmitter<any> = new EventEmitter();
  labels: any = [];
  ngSubscribe = new Subject();
  openFilter: boolean = false;
  defaultFilterData: any;
  constructor(
    private alertService: AlertService,
    private entityBoardFacadeService: EntityBoardFacadeService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.defaultFilterData = JSON.parse(JSON.stringify(this.filterData));
  }

  detectLanguageChange() {
    /* istanbul ignore next */
    this.translator.languageLables$?.pipe(takeUntil(this.ngSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  applyFilterAndSort(boolean?: boolean, id?: number) {
    this.filterDataEmitter.emit({
      rangeData: this.filterData.rangeData,
      filterValues: this.filterData.filterValues,
      sortOptionAttr: this.filterData.sortOptionAttr,
      sortOption: this.filterData.sortOption,
      eventPassed: true,
      saveFilter: boolean,
      id: id,
    });
    // this.alertService.showToaster('Given Filters are applied', '', 'success');
  }
  parseIntDuplicate(number: string): number {
    return parseInt(number);
  }
  onSelectionChange(event: any, data: any) {
    //
  }
  // noSpancing() {
  //   this.searchDataEmitter.emit({
  //     attributeValue: this.filterData.attributeValue,
  //     selectedAttribute: this.filterData.selectedAttribute,
  //     eventPassed: true,
  //   });
  // }
  resetFilterData() {
    this.filterData = JSON.parse(JSON.stringify(this.defaultFilterData));
    this.filterDataEmitter.emit({
      rangeData: this.filterData.rangeData,
      filterValues: this.filterData.filterValues,
      sortOptionAttr: this.filterData.sortOptionAttr,
      sortOption: this.filterData.sortOption,
      eventPassed: true,
    });
    this.searchDataEmitter.emit({
      attributeValue: this.filterData.attributeValue,
      selectedAttribute: this.filterData.selectedAttribute,
      eventPassed: false,
    });
    this.alertService.showToaster('Applied Filters are reset', '', 'success');
  }
  closeFilter() {}
}
