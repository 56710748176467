import { Component, ElementRef, OnInit } from '@angular/core';
import { DesignExpandedBase } from '../templates/design-expanded-base-component';
import { AlertService, EntityBoardEndpointService } from '@common-services';

@Component({
  selector: 'lib-dynmaic-expand-card',
  templateUrl: './dynmaic-expand-card.component.html'
})
export class DynmaicExpandCardComponent extends DesignExpandedBase implements OnInit {
  constructor(alertService: AlertService, entityBoardService: EntityBoardEndpointService, element: ElementRef) {
    super(alertService, entityBoardService, element);
  }
  ngOnInit(): void {
  }

}
