import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ApiResponse, EntityBoardEndpointService, FilterType, GsiFacadeService, PrimaryEntity } from '@common-services';
import { decodeString } from '../../constants/customize-filter-templates.constants';
@Component({
  selector: 'app-filter-page',
  templateUrl: './filter-page.component.html',
  styleUrls: ['./filter-page.component.scss'],
})
export class FilterPageComponent implements OnInit {
  @Input() filterIds: string[];
  @Input() cardId: string;
  @Input() isRouted: boolean = true;
  savedSearchQueries: any[] = [];
  @Input() primaryEntity: PrimaryEntity;
  @Input() filterType: FilterType;
  selectedFilterName: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private entityBoardEndpointService: EntityBoardEndpointService,
    private gsiFacadeService: GsiFacadeService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    if (this.isRouted) {
      this.getRouteParameters();
      this.getEntityCardDetails(this.cardId);
      this.filterType = 'sharable';
    }
    if (this.filterType === 'admin') {
      this.getEntityCardDetails(this.cardId);
    }
    this.openEntityCards(this.filterIds, this.cardId, this.filterType);
  }

  private getRouteParameters = () => {
    this.filterIds = JSON.parse(decodeString(this.route?.snapshot?.params?.filterIds));
    this.cardId = decodeString(this.route?.snapshot?.params?.cardId);
  };

  private getEntityCardDetails(cardId: string) {
    this.gsiFacadeService.getEntityCardDetails(cardId).subscribe((res: ApiResponse<any>) => {
      if (res && res?.result && res?.status === 200) {
        this.primaryEntity = res.result.primaryEntity;
      }
    });
  }

  openEntityCards(filters: string[], cardId: string, filterType: FilterType) {
    this.selectedFilterName = [];
    this.savedSearchQueries = [];
    const query = filterType == 'sharable' ? 'savedQuery' : 'query';
    filters.forEach((filter: string) => {
      this.entityBoardEndpointService.getFilterById(cardId, filter, filterType).subscribe(
        (res: ApiResponse<any>) => {
          if (res && res?.status === 200 && res?.result && res?.result?.[query]?.searchQuery) {
            this.savedSearchQueries.push(res.result[query].searchQuery);
            this.selectedFilterName.push(res.result.filterName);
            this.alertService.showToaster(res.message, '', 'sucess');
          } else {
            this.alertService.showToaster(res.message, '', 'error');
          }
        },
        (error: ApiResponse<unknown>) => {
          this.alertService.showToaster(error.message, '', 'error');
        }
      );
    });
  }
}
