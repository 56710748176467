export interface QueryFormula {
  input_output: any[];
  queryData: any;
  groupbyAggregate: any;
  filters: any[];
  nslCase: any[];
  nslCaseForCondition: any[];
  nslFormulaForCondition: any[];
  nslFormula: any[];
  nslFunction: any[];
  nslFunctionForCondition: any[];
  attributesList: any[];
  attributesListWithPL: any[];
  targetAttributesList: any[];
  entityList: any[];
}

export class QueryFormula {
  constructor() {
    this.input_output = [];
    this.queryData = [];
    this.groupbyAggregate = [
      {
        GroupbyAttribute: '',
        queryData: [],
        nslFunction: [],
      },
    ];
    this.filters = [];
    this.nslCase = [];
    this.nslFormula = [];
    this.nslFunction = [];
    this.nslFunctionForCondition = [];
    this.nslFormulaForCondition = [];
    this.nslCaseForCondition = [];
    this.attributesList = [];
    this.targetAttributesList = [];
    this.entityList = [];
    this.attributesListWithPL = [];
  }
}
