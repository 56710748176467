import { NgModule } from "@angular/core";
import { LoginComponent } from "./login.component";
import { SelectRolefromPublicUserRoleComponent } from "./select-rolefrom-public-user-role/select-rolefrom-public-user-role.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [LoginComponent,SelectRolefromPublicUserRoleComponent, ResetPasswordComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatOptionModule,
    CommonModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule
  ],
  exports: [
    LoginComponent,
  ]
})
export class LoginModule { }
