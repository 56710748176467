<div [ngClass]="dynamicClass ? 'pending-tran' : 'paginator-container'">
  <button
    mat-icon-button
    (click)="previousPage()"
    [disabled]="pageIndex <= 0"
    [ngClass]="{ 'pointer-disable': pageIndex <= 0 }"
    [disableRipple]="true"
  >
    <mat-icon class="small-icon icon-direction" id="navigate_before">navigate_before</mat-icon>
  </button>
  <span [ngClass]="dynamicClass ? 'pending-tran' : 'paginator-content'"
    >{{ totalPages <= 0 ? 0 : pageIndex + 1 }} / {{ totalPages }}</span
  >
  <button
    mat-icon-button
    (click)="nextPage()"
    [disabled]="!totalPages || pageIndex === totalPages - 1"
    [disableRipple]="true"
    [ngClass]="{ 'pointer-disable': !totalPages || pageIndex === totalPages - 1 }"
  >
    <mat-icon class="small-icon" id="navigate_next">navigate_next</mat-icon>
  </button>
</div>
