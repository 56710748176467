import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-node-entity-dcd',
  templateUrl: './node-entity-dcd.component.html',
  styleUrls: ['./node-entity-dcd.component.scss'],
})
export class NodeEntityDcdComponent implements OnInit {
  @Input() attr: any;
  @Input() attrName: any;
  @Input() attrId: any;
  @Input() entityIndex: any;
  @Input() entity: any;
  @Input() cuName: any;
  @Input() layerName: any;
  @Output() attributeEmit = new EventEmitter();
  @Output() entityEmit = new EventEmitter();
  expanded: boolean = false;
  nestedAttrName: string;
  labels: any;
  private ngUnsubscribe = new Subject();
  nestedAttrId: string;
  constructor(private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.nestedAttrName = this.attrName;
    this.nestedAttrId = this.attrId;
  }
  addAttributetoDcd(attr: any) {
    const tempAttr = { ...attr };
    tempAttr.name = this.nestedAttrName.replace(this.entity.name + '.', '');
    tempAttr.id = this.nestedAttrId.replace(this.entity.id + '.', '');
    /* istanbul ignore next */
    this?.attributeEmit.emit({
      entityIndex: this.entityIndex,
      entity: this.entity,
      attribute: tempAttr,
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  addEntitytoDcd(entity: any) {
    const tempEntity = { ...entity };
    tempEntity.name = this.nestedAttrName;
    tempEntity.id = this.nestedAttrId;
    this.entityEmit.emit({ entityIndex: this.entityIndex, entity: tempEntity });
  }
  nestedAttributeEmit(attribute: any) {
    /* istanbul ignore next */
    this?.attributeEmit.emit(attribute);
  }

  nestedEntityEmit(entity: any) {
    this.entityEmit.emit(entity);
  }
}
