import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';

@Component({
  selector: 'app-entity-primary-key',
  templateUrl: './entity-primary-key.component.html',
  styleUrls: ['./entity-primary-key.component.scss'],
})
export class EntityPrimaryKeyComponent implements OnInit {
  @Input() configurationData: any;
  @Output() updatedUniqueCons = new EventEmitter();
  ngUnsubscribe = new Subject();
  attributesList: any = [];
  uniqueKey: any = [];
  labels: any;
  @Input() isHybrid: boolean = false;
  constructor(public dialog: MatDialog, private alertService: AlertService, private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  openDialog(templateRef: TemplateRef<any>) {
    /*istanbul ignore next*/
    this.attributesList = this.configurationData?.data?.entityData?.nslAttributes;
    /*istanbul ignore next*/
    this.attributesList = this.attributesList?.map((attr: any, i: number) => {
      attr = { ...attr, visible: true };
      return attr;
    });
    this.dialog.closeAll();
    this.dialog.open(templateRef);
  }

  selectPrimaryAttribute(event: any, i: number) {
    let attrObj = this.attributesList[i];
    /*istanbul ignore else*/
    if (attrObj.visible) delete attrObj.visible;
    /*istanbul ignore next*/
    if (event?.checked) {
      this.uniqueKey.push(attrObj);
      this.attributesList[i].visible = false;
    } else {
      this.uniqueKey = this.uniqueKey?.filter((attr: any) => attr.id !== attrObj.id);
      this.attributesList[i].visible = true;
    }
  }

  saveUniqueKey() {
    if (this.uniqueKey.length) {
      /*istanbul ignore next*/
      if (!this.configurationData?.data?.entityData?.uniqueConstraints)
        this.configurationData.data.entityData = {
          ...this.configurationData?.data?.entityData,
          uniqueConstraints: [this.uniqueKey],
        };
      else this.configurationData?.data?.entityData?.uniqueConstraints?.push(this.uniqueKey);
      this.uniqueKey = [];
      /*istanbul ignore next*/
      this.attributesList = this.configurationData?.data?.entityData?.uniqueConstraints;
      this.updatedUniqueCons.emit(this.configurationData?.data?.entityData?.uniqueConstraints);
    } else {
      this.alertService.showToaster('Select Attributes to create Primary key', '', 'warning');
    }
    this.closeDialog();
  }

  deleteUniqueKey(iKey: number) {
    this.configurationData.data.entityData.uniqueConstraints.splice(iKey, 1);
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
