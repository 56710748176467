<div
  [formGroup]="group"
  [ngClass]="foundObject?.event == 'Option 7' ? 'date-parent-class-styles' : ''"
>
  <div class="d-flex justify-content-between nh-column-gap-10">
    <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
      <label
        class="main-label nh-mb-0"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
      >
        <span class="main-label-inner">
          <span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
          <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
        </span>
      </label>
    </div>
    <div
      class="d-flex align-items-center nh-column-gap-5 pb-2"
      *ngIf="
        field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
        (field?.isMulti && !(field?.attribute)['isTableConfig'])
      "
    >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="addAttribute.next()"
      >
        add
      </mat-icon>

      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="removeAttribute.next()"
      >
        remove
      </mat-icon>
    </div>
  </div>
  <div>
    <div *ngIf="!field?.entityName?.includes('NSL_SlotBooking')"
      class="form-group date-picker-wrapper" [ngClass]="{
      inValidClass:
        !field?.isInfo &&
        (group?.controls)[field?.attribute?.name]?.invalid &&
        (group?.controls)[field?.attribute?.name]?.touched
    }">
      <p-calendar
        class="{{ appliedClass }}"
        [placeholder]="labels?.[requiredFormat] || (requiredFormat | uppercase)"
        [disabled]="field?.readonly || field?.type === 'currentdateonly'"
        [(ngModel)]="field.value"
        [formControlName]="field.attribute.name"
        [required]="field?.isRequired && !field?.isInfo"
        [matTooltip]="field?.configuration?.hideTooltip ? '' : formattedValue"
        [matTooltipPosition]="'above'"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (onShow)="dateClicked($event)"
        (onSelect)="clicked($event)"
        (onBlur)="onBlur($event)"
        (keypress)="keyPress($event)"
        (onInput)="inputChange($event)"
        [showOnFocus]="false"
        [dateFormat]="requiredFormatPrime"
        [monthNavigator]="true"
        [showIcon]="true"
        [showButtonBar]="foundObject?.event == 'Option 3' ? true : false"
        [appendTo]="bodyAppend"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        panelStyleClass="date-datepicker-calendar"
        icon="nh-icon nh-icon-calender02"
      ></p-calendar>
      <label class="date-placeholder-label" *ngIf="invalidDate">{{ labels?.InvalidDate || 'Invalid date' }}</label>
    </div>
    <!-- For NSL_SlotBooking yearMonth format-->
    <div *ngIf="field?.entityName?.includes('NSL_SlotBooking')"  class="yearField">
      <mat-form-field appearance="standard">
        <div
          class="custom-year-picker year-month-wrapper"
          [ngClass]="{
            inValidClass:
              !field?.isInfo &&
              (group?.controls)[field?.attribute?.name]?.invalid &&
              (group?.controls)[field?.attribute?.name]?.touched
          }"
        >
          <p id="dateSelect_{{ field.attribute.name }}_{{ field.id }}" class="datedisplay">
            {{ field.value | date: requiredFormat }}
          </p>
          <svg
          class="actual-year-month-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <g id="Group_104425" data-name="Group 104425" transform="translate(-679 -222)">
            <rect
              id="Rectangle_38766"
              data-name="Rectangle 38766"
              width="24"
              height="24"
              transform="translate(679 222)"
              fill="#333"
              opacity="0"
            />
            <g id="Group_98139" data-name="Group 98139" transform="translate(680.5 222.732)">
              <path
                id="Path_101798"
                data-name="Path 101798"
                d="M23.489,3.777h15.75a3.146,3.146,0,0,1,3.125,3.171v16a3.146,3.146,0,0,1-3.121,3.167H23.489a3.146,3.146,0,0,1-3.125-3.171v-16a3.146,3.146,0,0,1,3.121-3.167Zm0,21.335H39.239a2.147,2.147,0,0,0,2.125-2.163v-16a2.147,2.147,0,0,0-2.129-2.167H23.491A2.147,2.147,0,0,0,21.364,6.94v16a2.147,2.147,0,0,0,2.129,2.167Z"
                transform="translate(-20.864 -2.844)"
                fill="#333"
              />
              <path
                id="Line_5451"
                data-name="Line 5451"
                d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
                transform="translate(15.75)"
                fill="#333"
              />
              <path
                id="Line_5452"
                data-name="Line 5452"
                d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
                transform="translate(5.25)"
                fill="#333"
              />
              <path
                id="Line_5453"
                data-name="Line 5453"
                d="M20.25.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H20.25a.5.5,0,0,1,.5.5A.5.5,0,0,1,20.25.5Z"
                transform="translate(0.375 7.504)"
                fill="#333"
              />
            </g>
          </g>
        </svg>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [matTooltip]="field?.configuration?.hideTooltip ? '' : formattedValue"
            [matTooltipPosition]="'above'"
            id="dateSelect_{{ field.attribute.name }}_{{ field.id }}"
            [disabled]="field.readonly"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker
            startView="{{availableDatesArray?.length ==0 ? 'multi-year' :'month'}}"
            [startAt]="availableDatesArray?.length > 0 ? selectedMonth: null"
            (monthSelected)="chosenMonthHandler($event, picker)"
          >
          </mat-datepicker>
        </div>
        <input
          matInput
          style="display: none;"
          [(ngModel)]="field.value"
          [matDatepicker]="picker"
          [formControlName]="field?.attribute.name"
          [name]="field?.attribute?.name"
          [readonly]="true"
          [required]="field?.isRequired && !field?.isInfo"
          id="dateSelect_{{ field?.attribute?.name }}_{{ field?.id }}"
          [matDatepickerFilter]="specificDatesArrays"
          (dateChange)="calendarClosed($event, picker)"
        />
      </mat-form-field>
    </div>
  </div>
  <!-- For validation message of UI controls -->
  <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
    <mat-error
      *ngIf="validation.type && (validation.type.toLocaleLowerCase() === 'error' || validation.type.toLocaleLowerCase() === 'block_warn') && getErrorMessage(field, validation)"
    >
      {{ validation.message }}</mat-error
    >
    <mat-error
      [ngClass]="validation.type == 'info' ? 'infocolor' : 'warncolor'"
      *ngIf="
        validation.type && validation.type.toLocaleLowerCase() !== 'error' && validation.type.toLocaleLowerCase() !== 'block_warn' && getInfoWarnMessage(field, validation)
      "
      >{{ validation.message }}</mat-error
    >
  </ng-container>
</div>
