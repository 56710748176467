import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { DcdValue } from '../../models/connectors';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-nested-connectors',
  templateUrl: './nested-connectors.component.html',
  styleUrls: ['./nested-connectors.component.scss'],
})
export class NestedConnectorsComponent implements OnInit {
  @Input() targetDcdValue: DcdValue;
  @Input() dcdNumber: number;

  targetNestedCu: any[] = [];
  ngUnsubscribe: any = new Subject();
  layerOrNested: string;
  openNested: boolean;
  constructor(private changeUnitFacade: NodeChangeUnitFacadeService) {
    this.detectTriggercuDetails();
  }

  ngOnInit(): void {
    this.targetDcdValue.selectedNestedCU = new DcdValue();
    /*istanbul ignore else*/
    if (this.targetDcdValue?.selectedChangeUnit?.mindCUList?.length > 0) {
      this.fetchNested(this.targetDcdValue?.selectedChangeUnit?.mindCUList[0].gsiId);
    }
  }
  fetchNested(id: any) {
    this.changeUnitFacade.fetchedNestedParentCU(id, -1, false, true, this.dcdNumber);
  }
  detectTriggercuDetails() {
    this.changeUnitFacade.getTriggerCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && res.dcd == this.dcdNumber) {
        // this.targetDcdValue.selectedNestedCU.selectedChangeUnit =res;
        this.targetNestedCu.push(res);
      }
    });
  }

  targetChangeUnitChange(index: number): void {
    /*istanbul ignore else*/
    if (index >= 0) {
      this.targetDcdValue.selectedNestedCU.selectedChangeUnit = this.targetNestedCu[index];
      this.layerOrNested = 'Layers';
    }
  }
  changeType() {
    /*istanbul ignore else*/
    if (this.layerOrNested == 'Nested') {
      this.openNested = true;
    }
  }

  changeTargetLayer(layerType: string): void {
    this.targetDcdValue.selectedNestedCU.selectedChangeUnit.layers.forEach((layer: any) => {
      /*istanbul ignore else*/
      if (layerType == layer.type) {
        this.targetDcdValue.selectedNestedCU.participatingItems = layer?.participatingItems;
      }
    });
  }
  changeTargetEntity(index: number, isNested?: boolean): void {
    /*istanbul ignore else*/
    let entity: any = this.targetDcdValue?.selectedNestedCU.participatingItems[index]; //selects entity from the participatingItems
    this.targetDcdValue.selectedNestedCU.selectedEntity = entity;
    this.targetDcdValue.selectedNestedCU.selectedAttribute = [];
    this.targetDcdValue.selectedNestedCU.nslAttributes = [];
    this.targetDcdValue.selectedNestedCU.nslAttributes = entity.nslAttributes
      ? entity.nslAttributes
      : entity.item.DATA.nslAttributes;
  }

  changeTargetAttribute(entity: any, attrIndex: number) {
    this.setFalseAll(entity, attrIndex);
    /*istanbul ignore else*/
    if (!entity.nslAttributes[attrIndex].generalEntity) {
      this.targetDcdValue.selectedNestedCU.selectedAttribute = entity.nslAttributes[attrIndex];
    }
  }

  setFalseAll(entity: any, attrIndex: any) {
    entity.nslAttributes.forEach((ele: any, index: any) => {
      ele.isSelected = false;
      /*istanbul ignore else*/
      if (ele.id === entity.nslAttributes[attrIndex].id) {
        ele.isSelected = true;
      }
    });
  }
}
