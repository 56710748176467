import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SsoLoginService {
  detectSSO = new BehaviorSubject(false);
  detectSSOObeservable = this.detectSSO.asObservable();
  constructor() {}

  setDetectSSO(flag: boolean) {
    this.detectSSO.next(flag);
  }
}
