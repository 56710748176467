import { Component,  Inject,  } from '@angular/core';
import { AlertService, } from '@common-services';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';

@Component({
  selector: 'app-banners-template',
  templateUrl: './banners-template.component.html',
  styleUrls: ['./banners-template.component.scss'],
})
export class BannersTemplateComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService
  ) {
    super(    
      alertService
    );
  }
}
