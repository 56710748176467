import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService,DraggableAttribute,GsiButton,IStyle,TranslatorService } from '@common-services';
import { relatedAttributeCondition } from '@common-services';
import { LoaderService } from '@common-services';
import { RolesFacadeService } from '@common-services';
import { EntityEndpointService } from '@common-services';
import { EntityBoardEndpointService } from '@common-services';
import { EntityBoardFacadeService } from '@common-services';
import { DropBase } from '../drop-base/drop-base';
import { TabType, IAttributeStyle, ICardStyle, PositionType, SelectionType } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntitycardComponentMapper } from '../constatnts/customize-templates.constants';

@Component({
  selector: 'app-card-drop',
  templateUrl: './card-drop.component.html',
  styleUrls: ['./card-drop.component.scss'],
})
export class CardDropComponent {
  ngUnsubscribe = new Subject();
  entityCardFlip: boolean = false;
  @Input() set _entityCardFlip(value: boolean){
    this.entityCardFlip = value;
    this.attachInstanceToTheComponent(this.componentRef,'entityCardFlip',value);
  };
  //------------------------------------Template Related Variables Start --------------------------------
  templateType: string | number;
  labels: any;
  @Input() kanbanGsiConfiguration: { [key: number]: GsiButton } = {};
  @Input() set _templateType(value: string | number) {
    if (value && value != 'default') {
      this.templateType = value;
      this.isEdit = false;
      if (value == 'default') {
        this.isSelectTemplate = false;
      } else {
        this.removeSelectionFromChild();
        this.renderTemplateComponents();
      }
    }
  }

  expandTemplateType: any;
  @Input() boxShadowData: any;

  @Input() set _kanbanStatus(value: any[]) {
    this.kanbanStatus = value;
    this.attachInstanceToTheComponent(this.componentRef, 'kanbanStatus', this.kanbanStatus);
  }
  kanbanStatus: any;

  @Input() set _expandTemplateType(value: string | number) {
    this.expandTemplateType = value;
    this.removeSelectionFromExpand();
    this.renderExpandTemplateComponents();
  }
  childComponentName: any;
  expandComponentName: any;
  @Input() set _expandComponentName(value: string) {
    this.expandComponentName = value;
    this.removeSelectionFromExpand();
    this.renderExpandTemplateComponents();
  }

  @Input() set _childComponentName(value: string) {
      this.childComponentName = value;
      this.renderTemplateComponents();
  }
  @Input() showKanbanCardConfiguration: boolean;


  //------------------------------------Template Related Variables End ---------------------------------
  dragAttrType: string;
  @Input() set _dragAttrType(value: string) {
    this.dragAttrType = value;
    this.setInstance('dragAttrType', value);
  }
  entitycardComponentMapper: { [key: string]: any } = {};
  //componentTemplateMapper: { [key: string]: string } = {};
  componentRef: ComponentRef<any>;
  expandComponentRef: ComponentRef<any>;
  tabSelected: TabType;
  @Input() set _tabSelected(value: TabType) {
    this.tabSelected = value;
    this.onTabSelect();
  }
  @Input() expandGsiList: any;
  @Input() isGrammer: boolean;
  @Input() isDesign: boolean;
  @Input() isDesignTab: boolean;
  @ViewChild('childTemplateContainer', { read: ViewContainerRef }) templateContainer: ViewContainerRef;
  @ViewChild('expandTemplateContainer', { read: ViewContainerRef }) expandtemplateContainer: ViewContainerRef;
  @ViewChild('alertDialog') alertDialog: TemplateRef<HTMLElement>;
  gsiActionButton: any = [{ name: 'Action Button' }];
  // ------------------Child GSI variables --------------------------------
  @Input() childGsiMapping: { [key: number]: GsiButton } = {};
  childAttributePositionMap: { [key: number]: DraggableAttribute } = {};
  //----------------  Child GSI variables End --------
  expandedPositionMap: { [key: number]: DraggableAttribute } = {};
  @Input() selectGsiListExpand: any = [];
  style: IStyle = {
    changedElementStyle: 'selectedColor',
    changedElementValue: '',
  };
  @Input() set _style(style: IStyle) {
    this.style = style;
    if (this.tabSelected === 'child') {
      this.attachInstanceToTheComponent(this.componentRef, 'style', style);
    } else if (this.tabSelected === 'expand') {
      this.attachInstanceToTheComponent(this.expandComponentRef, 'style', style);
    }
  }
  colorConditionSetter: any;
  @Input() set _colorConditionSetter(colorConditionSetter: any) {
    this.colorConditionSetter = colorConditionSetter;
    if (this.tabSelected == 'child') {
      this.attachInstanceToTheComponent(this.componentRef, 'colorConditionSetter', this.colorConditionSetter);
    } else {
      this.attachInstanceToTheComponent(this.expandComponentRef, 'colorConditionSetter', this.colorConditionSetter);
    }
  }
  @Input() selectedDirectiveId: string = '';
  @Input() changeObj: any;
  @Input() isSelectTemplate: boolean;
  draggableAttributes: DraggableAttribute[] = [];
  @Input() set _draggableAttributes(value: DraggableAttribute[]) {
    if (value) {
      this.draggableAttributes = value;
      this.callEdit();
      this.setDraggableAttributesToPositionMap(value);
    }
  }

  @Input() draggedAttribute: DraggableAttribute;
  @Input() draggedGSI: GsiButton;
  @Input() conditionColor: any;
  @Input() conditionAttribute: any = [];
  @Input() conditionAttributeExpand: any = [];
  @Input() dropEntityExpandData: any = {};
  @Input() isCardConfig: boolean = true;
  @Input() attributeDirectiveId: string;
  @Input() cartTemplateType: any;
  @Input() isAdvancedFeature: boolean = false;
  @Input() renderDynamiTemplate: boolean = false;
  @Input() attri: any[] = [];
  @Output() cardConfigEvent = new EventEmitter();
  @Input() styleAttribute: IAttributeStyle[] = [];
  @Input() styleCard: ICardStyle = {
    color: '',
    hoverColor: '',
  };
  @Input() expandCardStyle: ICardStyle;
  @Input() expandStyleAttribute: any;
  @Input() childGsiList: any;
  @Input() expandStyleGsi: any;
  @Input() styleGsi: any;
  // -----------------------------------cart Related Variables Start--------------------------------
  @ViewChildren('cartAttributeStyles') cartAttributeStyles: QueryList<ElementRef>;
  @Output() cartAttributeStylesEmit = new EventEmitter();
  @Output() enableDroppedAttributeEvent = new EventEmitter();
  cartAttributes: DraggableAttribute[] = [];
  @Input() cartAttributesPositionMap: { [key: number]: DraggableAttribute } = {};
  @Input() kanbanAttributePositionMap: { [key: number]: DraggableAttribute } = {};
  @Input() CART_BUTTON: GsiButton;
  @Input() cartAttrStyleArray: any = [];
  // cart Edit Related Variables
  @Input() set _cartViewConfig(value: any) {
    if (value) {
      this.cartViewConfig = value;
      this.callEdit();
    }
  }
  @Input() set _cartAttributes(value: DraggableAttribute[]) {
    if (value) {
      this.cartAttributes = value;
      this.callEdit();
    }
  }
  @Input() cartAttrStyleArr: any = [];
  // -----------------------------------cart Related Variables End----------------------------------

  //------------------------------------edit Related Variables Start --------------------------------
  childConfig: any;
  expandConfig: any;
  cartViewConfig: any;
  @Input() set _childConfig(value: any) {
    if (value) {
      this.childConfig = value;
      this.callEdit();
    }
  }
  @Input() set _expandConfig(value: any) {
    if (value) {
      this.expandConfig = value;
      this.callEdit();
    }
  }
  @Input() isEdit: boolean = false;
  mappedExpandGsi: any = [];
  @Input() cardGsiMap: any = {
    mapped: false,
    value: '',
    index: -1,
  };
  @Input() gsiAttrMap = {};
  dragedHyperlink: boolean = false;
  expandDragedHyperlink: boolean = false;
  @Input() gsiAttrMapExpand: any = {};
  @Input() hyperlinkCtaDropArray: any = [];
  @Input() buttonDroppedInAttribute: any = {};
  buttonDroppedInCard: boolean;
  @Input() hyperLinkAttributes: any = [];
  @Input() expandHyperLinkAttributes: any = [];
  @Input() hyperlinkMap = new Map();
  @Input() expandHyperlinkMap = new Map();
  dropedHyperlinkattr: any = [];
  expandDropedHyperlinkattr: any = [];
  @Input() hyperlinkArray: any = [];
  @Input() expandHyperlinkArray: any = [];
  @Input() isExpand: boolean = false;
  @Input() isDynamicExpandCardSelected: boolean = false;

  //------------------------------------edit Related Variables end --------------------------------

  constructor(
    protected router: Router,
    protected rolesFacadeService: RolesFacadeService,
    protected alertService: AlertService,
    protected entityBoardService: EntityBoardEndpointService,
    public dialog: MatDialog,
    protected loader: LoaderService,
    protected el: ElementRef,
    protected entityEndpointService: EntityEndpointService,
    protected entityBoardFacade: EntityBoardFacadeService,
    protected translator: TranslatorService,
    protected resolver: ComponentFactoryResolver
  ) {
    this.entitycardComponentMapper = EntitycardComponentMapper;
    this.detectLanguageChange();
  }

  callEdit() {
    if (this.childConfig) {
      this.isEdit = false;
      this.setChildDetails();
      this.renderTemplateComponents();
    }
    if (this.expandConfig) {
      this.isEdit = false;
      this.setExpandDetails();
      this.renderExpandTemplateComponents();
    }
    if (this.cartViewConfig) {
      this.isEdit = false;
      this.setCartAttrDetails();
    }
  }

  setChildDetails() {
    this.childConfig?.style?.attr?.forEach((childAttr: any, i: number) => {
      let index = this.draggableAttributes?.findIndex((attr: any) => attr.name == childAttr);
      if (index != -1) {
        this.draggedAttribute = this.draggableAttributes[index];
        this.mapAttributeToPosition(
          this.draggableAttributes[index],
          i,
          'childPosition',
          'selectedInChild',
          this.childAttributePositionMap
        );
      }
    });
    this.styleCard.color = this.childConfig?.style?.cardBackground;
    this.styleCard.hoverColor = this.childConfig?.style.cardHoverColor;
    this.styleCard.borderColor = this.childConfig?.style.cardBorderColor;
    this.styleCard.borderWidth = this.childConfig?.style?.cardBorderSize;
    this.styleCard.borderRadius = this.childConfig?.style?.cardBorderRadius;
    this.styleCard.boxShadow = this.childConfig?.style?.cardBoxShadow;
    this.attachInstanceToTheComponent(this.componentRef, 'cardStyle', this.styleCard);
    this.setStyles(this.childConfig.style.color, 'attrColor', this.styleAttribute);
    this.setStyles(this.childConfig.style.hoveredColors, 'attHoverColor', this.styleAttribute);
    this.setOtherStyles(this.childConfig.style.otherStyles, this.styleAttribute);
    this.attachInstanceToTheComponent(this.componentRef, 'styleAttribute', this.styleAttribute);
    this.setGsiBtnChild(this.childConfig?.style?.gsiact);
    this.attachInstanceToTheComponent(this.componentRef, 'childGsiMapping', this.childGsiMapping);
    this.attachInstanceToTheComponent(this.componentRef, 'kanbanGsiConfiguration', this.kanbanGsiConfiguration);
    this.setStyles(this.childConfig?.style?.btnColors, 'color',this.styleGsi);
    this.setStyles(this.childConfig?.style?.btnHoverColors, 'hoverColor',this.styleGsi);
    this.attachInstanceToTheComponent(this.componentRef, 'styleGsi', this.styleGsi);
    this.isEdit = true;
    this.attachInstanceToTheComponent(this.componentRef, 'isedit', this.isEdit);
  }

  public setCartAttrDetails() {
    let temp = Object.entries(this.cartViewConfig?.style?.attr || {});
    if(temp?.length>0){
      temp.forEach(([key, value]: any) => {
        let index = this.cartAttributes?.findIndex((attr: any) => attr.name == key);
        this.draggedAttribute = this.cartAttributes[index];
        if (index != -1) {
          this.mapAttributeToPosition(
            this.cartAttributes[index],
            value,
            'position',
            'isSelected',
            this.cartAttributesPositionMap
          );
        }
      });
      this.setAddToCart(this.childConfig?.gsiList, this.childGsiMapping);
      this.setCartStyles(this.cartViewConfig?.style?.attrStyle);
    }
  }
  setCartStyles(cartStyle: any) {
    if (cartStyle?.length > 0) {
      this.cartAttrStyleArray = cartStyle;
    }
  }
  setAddToCart(gsiList: any, childGsiMapping: { [key: number]: GsiButton }) {
    let index = gsiList?.findIndex((gsi: any) => gsi?.id == -1 && gsi?.entityType == 'CART');
    if(index!=-1){
      this.makeCartButtonFrom(gsiList[index], childGsiMapping);
    }
  }
  setStyles(styleArr: any, label: any, stylesArr: any) {
    if (styleArr?.length > 0) {
      styleArr.forEach((attr: any, index: number) => {
        if(stylesArr[index]) {
          stylesArr[index][label] = attr;
        } else {
          stylesArr[index] = {};
          stylesArr[index][label] = attr;
        }
      });
    }
  }
  setOtherStyles(arr: any, stylesArr: any) {
    arr?.forEach((attr: any, index: number) => {
      stylesArr[index].fontFamily = attr['font-family'];
      stylesArr[index].alignment = attr['text-align'];
      stylesArr[index].isItalic = attr['font-style'] == 'italic' ? true : false;
      stylesArr[index].isBold = attr['font-weight'] == 'bold' ? true : false;
      stylesArr[index].fontSize = attr['font-size'];
    });
  }
  setGsiBtnChild(arr: any) {
    if (arr?.length > 0) {
      arr.forEach((attr: any, index: number) => {
        let tempGsi = this.childGsiList.find((gsi: any) => gsi.name == attr || gsi.name == attr?.name);
        if (tempGsi) {
          this.draggedGSI = tempGsi;
          this.makeGsiButtonFromId(index, tempGsi, this.childGsiMapping);
        }
      });
    }
  }
  // setGsiStyle(config: any, arr: any) {
  //   if (config && arr?.length > 0) {
  //     config.forEach((attr: any, index: number) => {
  //       arr[index].color = attr;
  //     });
  //   }
  // }
  setGsiBtnExpand(arr: any) {
    this.mappedExpandGsi = [];
    if (Object.values(arr)?.length > 0) {
      Object.values(arr).forEach((gsi: any) => {
        this.mappedExpandGsi.push(gsi.name);
      });
    }
  }

  setExpandDetails() {
    let tempArr = Object.entries(this.expandConfig?.style?.expandTemplateTypeData || {})
    if (tempArr.length > 0) {
      tempArr.forEach(([key, value]) => {
        let index = this.draggableAttributes?.findIndex((attr: any) => attr.name == value);
        this.draggedAttribute = this.draggableAttributes[index];
        if (index != -1) {
          this.mapAttributeToPosition(
            this.draggableAttributes[index],
            Number(key) - 1,
            'expandPosition',
            'selectedInExpand',
            this.expandedPositionMap
          );
        }
      });
      this.expandCardStyle.color = this.expandConfig?.style?.expandBackground;
      this.expandCardStyle.hoverColor = this.expandConfig?.style?.expandCardHoveredColor;
      this.setStyles(this.expandConfig?.style?.dropExpandColors, 'attrColor', this.expandStyleAttribute);
      this.setStyles(this.expandConfig.style.expandHoveredColors, 'attHoverColor', this.expandStyleAttribute);
      this.setOtherStyles(this.expandConfig.style.droppedStylesExpand, this.expandStyleAttribute);
      this.attachInstanceToTheComponent(this.expandComponentRef, 'expandStyleAttribute', this.expandStyleAttribute);
      this.attachInstanceToTheComponent(this.expandComponentRef, 'expandCardStyle', this.expandCardStyle);
      this.setGsiBtnExpand(this.expandConfig.style.gsiExpandData);
      this.attachInstanceToTheComponent(this.expandComponentRef, 'mappedExpandGsi', this.mappedExpandGsi);
      this.setStyles(this.expandConfig.style.expandBtnColors,'color' ,this.expandStyleGsi);
      this.setStyles(this.expandConfig.style.expandBtnHoverColors,'hoverColor', this.expandStyleGsi);
      this.attachInstanceToTheComponent(this.expandComponentRef, 'expandStyleGsi', this.expandStyleGsi);
      this.isEdit = true;
      this.attachInstanceToTheComponent(this.expandComponentRef, 'isEdit', this.isEdit);
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  /**
   * !After Constructor Only Some Functions are Added If any Conflict arises.
   * !Please check and compare those Functions in Drop-Base Component and Resolve It.
   */

  private renderTemplateComponents(): void {
    this.templateContainer?.clear();
    if (this.childComponentName) {
      const component = this.entitycardComponentMapper[this.childComponentName];
      const factory = this.resolver?.resolveComponentFactory(component);
      this.componentRef = this.templateContainer?.createComponent(factory);
      this.attachVariablesToComponent(this.componentRef);
      this.subscribeToOutputVariables(this.componentRef);
    }
  }
  private renderExpandTemplateComponents(): void {
    this.expandtemplateContainer?.clear();
    if (this.expandTemplateType && this.expandComponentName) {
      const expandComponent = this.entitycardComponentMapper[this.expandComponentName];
      const factory = this.resolver?.resolveComponentFactory(expandComponent);
      this.expandComponentRef = this.expandtemplateContainer.createComponent(factory);
      this.attachVariablesToExpandComponent(this.expandComponentRef);
      this.subscribeToOutputVariablesExpand(this.expandComponentRef);
      this.onTabSelect();
    }
  }

  private setInstance(label: string, value: any) {
    if (this.componentRef) {
      this.componentRef.instance[label] = value;
    }
    if (this.expandComponentRef) {
      this.expandComponentRef.instance[label] = value;
    }
  }
  protected subscribeToOutputVariables(componentRef: ComponentRef<any>) {
    if (componentRef) {
      componentRef.instance.droppedAttr.subscribe((event: any) => {
        if (event?.attrtype) this.enableDroppedAttributeOnTemplate(event.event, event.attrtype);
        else this.enableDroppedAttributeOnTemplate(event);
      });
      componentRef.instance.onDeletion.subscribe((event: any) => {
        const index: number = parseInt(event.id.split('-')[1]);
        this.onDeletingAttribute(index, true);
      });
      componentRef.instance.droppedActionButton.subscribe((event: any) => {
        this.dropActionBtn(event, this.checkIfKanban());
      });
      componentRef.instance.emitConditionColor.subscribe((event: any) => {
        this.conditionColorEmit(event);
      });
      componentRef.instance.removeGsi.subscribe((event: any) => {
        this.removeGsiFromCard(event, this.checkIfKanban());
      });
      componentRef.instance?.onDeleteAddOn.subscribe((event: any) => {
        const index: number = parseInt(event.id.split('-')[1]);
        this.onDeletingaddOn(index);
      });

      componentRef.instance?.deleteDropCardEmit.subscribe((event: any) => {
        this.removeGsiFromWholeCard('event');
      });

      componentRef.instance?.dropCardEmit.subscribe((event: any) => {
        this.dropCardGsi(event);
      });
      componentRef.instance?.emitSelectedElement.subscribe((event: any) => {
        this.recieveEmittedDirectiveId(event);
      });
    }
  }
  recieveEmittedDirectiveId(event: string): void {
    this.attributeDirectiveId = event;
    // this.cartView.attributeDirectiveId = this.attributeDirectiveId;
  }

  checkIfKanban(): { [key: number]: GsiButton } {
    if (this.showKanbanCardConfiguration) {
      return this.kanbanGsiConfiguration;
    }
    return this.childGsiMapping;
  }

  private subscribeToOutputVariablesExpand(expandComponentRef: ComponentRef<any>) {
    expandComponentRef.instance.droppedAttr.subscribe((event: any) => {
      this.droppedAttr(event);
    });
    expandComponentRef.instance.droppedAttrExpandEmit.subscribe((event: any) => {
      this.recieveDropEntityExpandData(event);
    });
    expandComponentRef.instance.droppedActionButton.subscribe((event: any) => {
      this.droppedActionButton(event);
    });
    expandComponentRef.instance.onDeletion.subscribe((event: any) => {
      const index: number = parseInt(event.id.split('-')[1]);
      this.onDeletingAttribute(index, true);
    });
    expandComponentRef.instance.emitConditionColor.subscribe((event: any) => {
      this.conditionColorEmit(event, 'expand');
    });
    expandComponentRef.instance.dialogEmit.subscribe((event: any) => {
      this.alertGSIMappedToCart();
    });
    expandComponentRef.instance.CART_BUTTON_EMIT.subscribe((event: any) => {
      this.cartButtonEmit(event);
    });
    expandComponentRef.instance.expandGsiListemit.subscribe((event: any) => {
      this.expandGsiListemit(event);
    });
  }

  expandGsiListemit(event: GsiButton[]) {
    this.expandGsiList = event;
  }

  cartButtonEmit(event: GsiButton) {
    this.CART_BUTTON = event;
  }

  protected attachVariablesToComponent(componentRef: ComponentRef<any>) {
    this.attachInstanceToTheComponent(componentRef, 'tabSelected', this.tabSelected);
    this.attachInstanceToTheComponent(componentRef, 'isGrammer', this.isGrammer);
    this.attachInstanceToTheComponent(componentRef, 'isDesign', this.isDesign);
    this.attachInstanceToTheComponent(componentRef, 'childPositionMap', this.childAttributePositionMap);
    this.attachInstanceToTheComponent(componentRef, 'kanbanPositionMap', this.kanbanAttributePositionMap);
    this.attachInstanceToTheComponent(componentRef, 'childGsiMapping', this.childGsiMapping);
    this.attachInstanceToTheComponent(componentRef, 'kanbanGsiConfiguration', this.kanbanGsiConfiguration);
    this.attachInstanceToTheComponent(componentRef, 'style', this.style);
    this.attachInstanceToTheComponent(componentRef, 'colorConditionSetter', this.colorConditionSetter);
    this.attachInstanceToTheComponent(componentRef, 'dragAttrType', this.dragAttrType);
    this.attachInstanceToTheComponent(componentRef, 'selectedDirectiveId', this.selectedDirectiveId);
    this.attachInstanceToTheComponent(componentRef, 'templateType', this.templateType);
    this.attachInstanceToTheComponent(componentRef, 'cardDisplay', 'design');
    this.attachInstanceToTheComponent(componentRef, 'styleAttribute', this.styleAttribute);
    this.attachInstanceToTheComponent(componentRef, 'cardStyle', this.styleCard);
    this.attachInstanceToTheComponent(componentRef, 'isEdit', this.isEdit);
    this.attachInstanceToTheComponent(componentRef, 'styleGsi', this.styleGsi);
    this.attachInstanceToTheComponent(componentRef, 'cardGsiMap', this.cardGsiMap);
    this.attachInstanceToTheComponent(componentRef, 'boxShadowData', this.boxShadowData);
    this.attachInstanceToTheComponent(componentRef, 'kanbanStatus', this.kanbanStatus);
    this.attachInstanceToTheComponent(componentRef, 'entityCardFlip', this.entityCardFlip);
  }

  private attachInstanceToTheComponent(componentRef: ComponentRef<any>, type: string, value: any) {
    if (componentRef) {
      componentRef.instance[type] = value;
    }
  }

  private attachVariablesToExpandComponent(expandComponentRef: ComponentRef<any>) {
    this.attachInstanceToTheComponent(expandComponentRef, 'selectGsiListExpand', this.selectGsiListExpand);
    this.attachInstanceToTheComponent(expandComponentRef, 'expandPositionMap', this.expandedPositionMap);
    this.attachInstanceToTheComponent(expandComponentRef, 'changeObj', this.changeObj);
    this.attachInstanceToTheComponent(expandComponentRef, 'previewType', 'wireFrame');
    this.attachInstanceToTheComponent(expandComponentRef, 'selectedDirectiveId', this.selectedDirectiveId);
    this.attachInstanceToTheComponent(expandComponentRef, 'style', this.style);
    this.attachInstanceToTheComponent(expandComponentRef, 'colorConditionSetter', this.colorConditionSetter);
    this.attachInstanceToTheComponent(expandComponentRef, 'isDesign', this.isDesignTab);
    this.attachInstanceToTheComponent(expandComponentRef, 'templateType', this.templateType);
    this.attachInstanceToTheComponent(expandComponentRef, '_expandType', this.expandTemplateType);
    this.attachInstanceToTheComponent(expandComponentRef, 'isGrammer', this.isGrammer);
    this.attachInstanceToTheComponent(expandComponentRef, 'dragAttrType', this.dragAttrType);
    this.attachInstanceToTheComponent(expandComponentRef, 'expandGsiList', this.expandGsiList);
    this.attachInstanceToTheComponent(expandComponentRef, 'CART_BUTTON', this.CART_BUTTON);
    this.attachInstanceToTheComponent(expandComponentRef, 'cardDisplay', 'design');
    this.attachInstanceToTheComponent(expandComponentRef, 'expandStyleAttribute', this.expandStyleAttribute);
    this.attachInstanceToTheComponent(expandComponentRef, 'expandCardStyle', this.expandCardStyle);
    this.attachInstanceToTheComponent(expandComponentRef, 'isEdit', this.isEdit);
    this.attachInstanceToTheComponent(expandComponentRef, 'mappedExpandGsi', this.mappedExpandGsi);
    this.attachInstanceToTheComponent(expandComponentRef, 'expandStyleGsi', this.expandStyleGsi);
    this.attachInstanceToTheComponent(expandComponentRef, '_isDynamicExpandCardSelected', this.isDynamicExpandCardSelected);
  }

  private onTabSelect() {
    if (this.componentRef) {
      this.componentRef.instance.tabSelected = this.tabSelected;
    }
    if (this.expandComponentRef) {
      this.expandComponentRef.instance.tabSelected = this.tabSelected;
    }
    if (this.tabSelected == 'child') {
      this.expandComponentRef?.location.nativeElement.classList.add('d-none');
      this.componentRef?.location.nativeElement.classList.remove('d-none');
    } else if (this.tabSelected == 'expand') {
      this.expandComponentRef?.location.nativeElement.classList.remove('d-none');
      this.componentRef?.location.nativeElement.classList.add('d-none');
    } else if (this.tabSelected == 'cart') {
      this.componentRef?.location.nativeElement.classList.add('d-none');
      this.expandComponentRef?.location.nativeElement.classList.add('d-none');
    }
  }

  droppedAttr(ev: any) {
    this.enableDroppedAttributeOnTemplate(ev);
  }

  dropAttribute(ev: any, attrType?: any, index?: any) {
    ev?.stopPropagation();
    if (this.isSelectTemplate) {
      /* istanbul ignore else */
      if (
        this.dragAttrType !== 'button' &&
        this.dragAttrType !== 'cart' &&
        ((attrType == 'image' && this.dragAttrType == 'image') || (attrType == 'any' && this.dragAttrType !== 'image'))
      ) {
        this.enableDroppedAttributeOnTemplate(ev, index);
      } else {
        this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      }
    } else {
      /*istanbul ignore next*/
      if (this.dragAttrType) {
        this.enableDroppedAttributeOnTemplate(ev, index);
      } else {
        this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      }
    }
  }

  enableDroppedAttributeOnTemplate(event: DragEvent, type?: any) {
    let attributeList: DraggableAttribute[] = [];
    attributeList = this.draggableAttributes;

    if (type && !(this.draggedAttribute?.attributeType?.type == type) && type != 'any') {
      this.alertService.showToaster(`attribute of type ${type} only can be dropped `, 'error', 'error');
      return;
    }
    const droppedPosition: number = parseInt(this.getTargetIdFromEvent(event).split('-')[1]);
    if (!droppedPosition && droppedPosition != 0) {
      this.alertService.showToaster(`please drop properly`, 'error', 'error');
      return;
    }
    if (droppedPosition > -1) {
      this.makeSelection(this.draggedAttribute, droppedPosition);
    }

    /* istanbul ignore next*/
    if (this.tabSelected == 'cart') {
      this.cartAttributesPositionMap[droppedPosition] = this.draggedAttribute;
    }
    event?.stopPropagation();
  }

  /**
   * The function `makeSelection` takes a draggable attribute and a dropped position, and based on the
   * selected tab, maps the attribute to the position in the corresponding map.
   * @param {DraggableAttribute} draggedAttr - Attribute that is being dragged
   * @param {number} droppedPosition - position where attribute is dragged
   */
  private makeSelection(draggedAttr: DraggableAttribute, droppedPosition: number): void {
     /* istanbul ignore next */
     if (this.showKanbanCardConfiguration) {
      this.mapAttributeToPosition(
        draggedAttr,
        droppedPosition,
        'kanbanPosition',
        'selectedInKanban',
        this.kanbanAttributePositionMap
      );
    } else {
      switch (this.tabSelected) {
        case 'cart': {
          this.mapAttributeToPosition(
            draggedAttr,
            droppedPosition,
            'position',
            'isSelected',
            this.cartAttributesPositionMap
          );
          break;
        }
        case 'child': {
          this.mapAttributeToPosition(
            draggedAttr,
            droppedPosition,
            'childPosition',
            'selectedInChild',
            this.childAttributePositionMap
          );
          break;
        }
        case 'expand': {
          this.mapAttributeToPosition(
            draggedAttr,
            droppedPosition,
            'expandPosition',
            'selectedInExpand',
            this.expandedPositionMap
          );
          break;
        }
      }
    }
  }

  /**
   * The function maps a draggable attribute to a specific position and updates the selection and map
   * accordingly, while also showing an alert if the position is already mapped.
   * @param {DraggableAttribute} draggedAttr - Attribute that is being dragged
   * @param {number} droppedPosition - position where attribute is dragged
   * @param {positionType} position - parameter in DraggableAttribute
   * @param {selectionType} selection - parameter in DraggableAttribute
   * @param map - position Map
   * @returns The function is of type Void.
   */
  private mapAttributeToPosition(
    draggedAttr: DraggableAttribute,
    droppedPosition: number,
    position: PositionType,
    selection: SelectionType,
    map: { [key: number]: DraggableAttribute }
  ): void {
    if (map?.[droppedPosition]) {
      if (this.dragAttrType == 'button' || this.dragAttrType == 'hyperlink') {
        this.mapAddOnAttributes(draggedAttr, droppedPosition, position, selection, map);
      }
      return;
    }
    draggedAttr[position] = droppedPosition;
    draggedAttr[selection] = true;
    map[droppedPosition] = this.draggedAttribute;
  }

  private mapAddOnAttributes(
    draggedAttr: DraggableAttribute,
    droppedPosition: number,
    position: PositionType,
    selection: SelectionType,
    map: { [key: number]: DraggableAttribute }
  ) {
    switch (this.tabSelected) {
      case 'child': {
        if (this.dragAttrType == 'button') {
          let index = this.draggableAttributes.find((attr: any) => attr == map?.[droppedPosition]);
          if (index) {
            index.addOnChild = { type: 'GSI', value: this.draggedGSI?.name };
            this.draggedGSI.isSelected = true;
            this.draggedGSI.position = -1;
          }
        } else if (this.dragAttrType == 'hyperlink') {
          let index = this.draggableAttributes.find((attr: any) => attr == map?.[droppedPosition]);
          if (index) {
            index.addOnChild = { type: 'hyperlink', value: this.draggedAttribute?.name };
            draggedAttr[position] = -1;
            draggedAttr[selection] = true;
          }
        } else {
          this.showCantMapAlert();
        }
        break;
      }

      case 'expand': {
        if (this.dragAttrType == 'button') {
          let index = this.draggableAttributes.find((attr: any) => attr == map?.[droppedPosition]);
          index.addOnExpand = { type: 'GSI', value: this.draggedAttribute?.name };
          draggedAttr[position] = droppedPosition;
          draggedAttr[selection] = true;
        } else if (this.dragAttrType == 'hyperlink') {
          let index = this.draggableAttributes.find((attr: any) => attr == map?.[droppedPosition]);
          index.addOnExpand = { type: 'hyperlink', value: this.draggedAttribute?.name };
          draggedAttr[position] = droppedPosition;
          draggedAttr[selection] = true;
        } else {
          this.showCantMapAlert();
        }
      }
    }
  }

  private showCantMapAlert(): void {
    this.alertService.showToaster('Attribute Cant Be Mapped', 'Attribute Cant Be Mapped', 'error');
  }

  /**
   * This function enables dropped attributes on a template and updates their position and selection
   * status.
   * @param {any} event - The event parameter is an object that represents  a drag and drop event.
   * TODO: add support for child and expand templates attributes
   */

  getTargetIdFromEvent(event: any): string {
    return event.target.id;
  }

  enableDroppedAttributeOnTemplate1(event: any) {
    this.enableDroppedAttributeOnTemplate(event.event, event.type);
  }

  /**
   * @param {number} index - The index of the attribute need to delete
   * @description This function is used to delete the attributes from cartAttributesPositionMap which have been dragged
   * dropped in selected cart template atrribute position i.e from cartAttributes by assigning isSelected as false and
   * making position of attribute to -1
   */
  onDeletingCartAttr(index: number) {
    const foundIndex = this.cartAttributes.findIndex(
      (x: any) => x?.name === this.cartAttributesPositionMap[index]?.name
    );
    /*istanbul ignore else*/
    if (foundIndex != -1) {
      this.cartAttributes[foundIndex].isSelected = false;
      this.cartAttributes[foundIndex].position = -1;
    }
    delete this.cartAttributesPositionMap[index];
  }

  onDeletingAttribute(index: number, isPosition?: boolean) {
    if (this.showKanbanCardConfiguration) {
      const currentAttribute = isPosition ? this.kanbanAttributePositionMap[index] : this.draggableAttributes[index];
      this.removeAttributeFromCard(
        currentAttribute,
        'selectedInKanban',
        'kanbanPosition',
        this.kanbanAttributePositionMap,
        currentAttribute.kanbanPosition
      );
    } else
    switch (this.tabSelected) {
      case 'child': {
        const currentAttribute = isPosition ? this.childAttributePositionMap[index] : this.draggableAttributes[index];
        this.removeAttributeFromCard(
          currentAttribute,
          'selectedInChild',
          'childPosition',
          this.childAttributePositionMap,
          currentAttribute.childPosition
        );
        break;
      }
      case 'cart': {
        const currentAttribute = isPosition ? this.cartAttributesPositionMap[index] : this.draggableAttributes[index];
        this.removeAttributeFromCard(
          currentAttribute,
          'isSelected',
          'position',
          this.cartAttributesPositionMap,
          currentAttribute.position
        );
        break;
      }
      case 'expand': {
        const currentAttribute = isPosition ? this.expandedPositionMap[index] : this.draggableAttributes[index];
        this.removeAttributeFromCard(
          currentAttribute,
          'selectedInExpand',
          'expandPosition',
          this.expandedPositionMap,
          currentAttribute.expandPosition
        );
        break;
      }
    }
  }

  /**
   * The function removes an attribute from a card by setting its position and selection type to default
   * values.
   * @param {DraggableAttribute} draggedAttribute - attribute to be removed from card
   * @param {SelectionType} selectionType -
   * @param {PositionType} positionType -
   */
  removeAttributeFromCard(
    draggedAttribute: DraggableAttribute,
    selectionType: SelectionType,
    positionType: PositionType,
    map: { [key: number]: DraggableAttribute },
    position: number
  ) {
    if (draggedAttribute?.addOnChild?.value) {
      this.onDeletingaddOn(position);
    }
    delete map[position];
    draggedAttribute[positionType] = -1;
    draggedAttribute[selectionType] = false;
  }

  /**
   * The function handles the drop action of a button or cart attribute and assigns it to a specific
   * position, while also checking if the dragged attribute type matches the dropped attribute type.
   * @param {any} event - The event parameter is an object that represents DragEvent the
   * function. It contains information about the event, such as the target element, the type of event,
   * and any data that was transferred during a drag and drop operation.
   * * using the data we get buttonID and the gsiId and mapping is done accordingly
   * *
   */
  dropActionBtn(event: any, childGsiMapping: { [key: number]: GsiButton }) {
    /* istanbul ignore next */
    event?.stopPropagation();
    if (this.dragAttrType === 'button' || this.dragAttrType === 'cart') {
      if (this.checkGSIMappingCondition(childGsiMapping)) {
        this.alertGSIMappedToCart();
        return;
      }
      let buttonPosition = parseInt(event.target.id.split('-')[1]);
      if (!childGsiMapping?.[buttonPosition]) {
        event.preventDefault();
        if (this.dragAttrType == 'button') {
          const data = event.dataTransfer.getData('text');
          const gsiIndex = parseInt(data.split('-')[1]);
          this.makeGsiButtonFromId(buttonPosition, this.draggedGSI, childGsiMapping);
        } else {
          this.makeCartButtonFrom(buttonPosition, childGsiMapping);
        }
      } else {
        if (childGsiMapping[buttonPosition].entityType == 'GSI' && this.dragAttrType == 'cart')
          this.alertGSIMappedToCart();
        this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      }
    }
  }

  /**
   * This function prevents the default behavior of an event.
   * @param {any} ev - The parameter "ev" is an event object that is passed as an argument to the
   * function "allowDrop". It is typically used in drag and drop functionality to handle events such as
   * dragover, dragenter, and dragleave. In this specific code snippet, the "ev" parameter is used to
   */
  allowDrop(ev: any) {
    ev.preventDefault();
  }

  private checkGSIMappingCondition(childGsiMapping: { [key: number]: GsiButton }): boolean {
    let keysLength = Object.keys(childGsiMapping).length;
    if (keysLength > 0 && this.dragAttrType == 'cart') {
      return true;
    } else if (
      this.dragAttrType == 'button' &&
      (this.CART_BUTTON?.isSelected?.['child']?.isSelected || this.CART_BUTTON?.isSelected?.['expand']?.isSelected)
    ) {
      return true;
    }
    return false;
  }

  /**
   * This function maps a GSI object to a button ID and sets its position while marking it as selected.
   * @param {number} buttonId - The ID of the button that the GSI (Game State Integration) will be
   * mapped to.
   * @param {number} gsiId - The `gsiId` parameter is a number that represents the index of a specific
   * item in the `childGsiList` array. This item is then selected and its position is set to the
   * `buttonId` parameter value.
   */
  private makeGsiButtonFromId(buttonId: number, gsi: GsiButton, childGsiMapping: { [key: number]: GsiButton }) {
    childGsiMapping[buttonId] = this.draggedGSI;
    this.draggedGSI.isSelected = true;
    this.draggedGSI.position = buttonId;
  }

  /**
   * This function sets the position and selection status of a cart button and adds it to a child
   * mapping object.
   * @param {number} buttonId - The parameter `buttonId` is a number that represents the position of
   * the cart button in the childGsiMapping array. It is used to set the position property of the
   * CART_BUTTON object and to assign the CART_BUTTON object to the childGsiMapping array at the
   * specified position.
   */
  private makeCartButtonFrom(buttonId: number, childGsiMapping: { [key: number]: GsiButton }) {
    this.CART_BUTTON.isSelected['child'].isSelected = true;
    this.CART_BUTTON.isSelected['child'].position = buttonId;
    childGsiMapping[buttonId] = this.CART_BUTTON;
  }

  conditionColorEmit(event: any, expand?: string) {
    const value = event.value?.innerText ? event.value?.innerText : event?.value?.innerHTML;
    if (value.trim() !== 'Attribute') {
      this.setConditionAttribute(event, value, expand);
    }
  }
  setConditionAttribute(event: any, value: any, expand?: string) {
    if (expand) {
      this.conditionColor = event.color;
      this.conditionAttributeExpand.push({ name: value });
    } else {
      this.conditionColor = event.color;
      this.conditionAttribute.push({ name: value });
    }
  }

  /**
   * The function removes particular GSI from action BUtton.
   * @param {number} index - The index parameter is a number that represents the button position
   */
  removeGsiFromCard(index: number, childGsiMapping: { [key: number]: GsiButton }) {
    /*istanbul ignore else */
    if (childGsiMapping[index].entityType === 'GSI') {
      childGsiMapping[index].isSelected = false;
      delete childGsiMapping[index];
    } else {
      childGsiMapping[index].isSelected['child'].isSelected = false;
      delete childGsiMapping[index];
    }
  }

  recieveDropEntityExpandData(event: any) {
    this.dropEntityExpandData = event.droppedAttrExpand;
    /* istanbul ignore else*/
    if (
      this.changeObj &&
      this.dropEntityExpandData &&
      this.changeObj?.expandTemplateTypeData !== this.dropEntityExpandData
    ) {
      this.changeObj['expandTemplateTypeData'] = this.dropEntityExpandData;
    }
    /* istanbul ignore else*/
    if (this.changeObj && event.event) {
      this.cardConfigEvent.emit(this.changeObj);
    } else if (this.changeObj && !event.event) {
      this.isCardConfig = false;
    }
  }

  droppedActionButton(ev: any) {
    this.dropActionBtn(ev, this.childGsiMapping);
  }

  alertGSIMappedToCart() {
    this.dialog.open(this.alertDialog);
  }

  private setDraggableAttributesToPositionMap = (draggableAttributes: DraggableAttribute[]) => {
    draggableAttributes.forEach((attribute: DraggableAttribute) => {
      if (attribute?.selectedInChild) {
        this.childAttributePositionMap[attribute.childPosition] = attribute;
      }
      if (attribute?.selectedInExpand) {
        this.expandedPositionMap[attribute.expandPosition] = attribute;
      }
      if (attribute?.selectedInKanban) {
        this.kanbanAttributePositionMap[attribute.kanbanPosition] = attribute;
      }
    });
  };

  private removeSelectionFromExpand(): void {
    let keys: string[] = Object.keys(this.expandedPositionMap);
    keys.forEach((key: string) => {
      let i = parseInt(key);
      this.removeAttributeFromCard(
        this.expandedPositionMap[key],
        'selectedInExpand',
        'expandPosition',
        this.expandedPositionMap,
        i
      );
    });
  }

  private removeSelectionFromChild(): void {
    let keys: string[] = Object.keys(this.childAttributePositionMap);
    keys.forEach((key: string) => {
      let i = parseInt(key);
      this.removeAttributeFromCard(
        this.childAttributePositionMap[key],
        'selectedInChild',
        'childPosition',
        this.childAttributePositionMap,
        i
      );
    });
  }

  dblClickButtonInCard(event: MouseEvent) {
    console.log(event);
  }
  enableDroppedAttributeOnCartTemplate(event: any) {
    this.enableDroppedAttributeEvent.emit(event);
  }
  receiveCartAttributeStyles(event: any) {
    this.cartAttributeStyles = event;
    this.cartAttributeStylesEmit.emit(this.cartAttributeStyles);
  }
  onDeletingaddOn(event: any) {
    switch (this.tabSelected) {
      case 'child': {
        let tempDragAttr = this.childAttributePositionMap[event];
        if (tempDragAttr?.addOnChild?.type == 'hyperlink') {
          let index = this.draggableAttributes.findIndex((attr: any) => attr?.name == tempDragAttr?.addOnChild?.value);
          this.draggableAttributes[index]['selectedInChild'] = false;
          this.draggableAttributes[index]['childPosition'] = -1;
        } else if (tempDragAttr?.addOnChild?.type == 'GSI') {
          let index = this.childGsiList.findIndex((attr: any) => attr?.name == tempDragAttr?.addOnChild?.value);
          this.childGsiList[index].isSelected = false;
          this.childGsiList[index].position = -1;
        }

        delete tempDragAttr.addOnChild;
        break;
      }
      case 'expand': {
        let tempDragAttr = this.expandedPositionMap[event];
        if (tempDragAttr?.addOnExpand?.type == 'hyperlink') {
          let index = this.draggableAttributes.findIndex((attr: any) => attr?.name == tempDragAttr?.addOnExpand?.value);
          this.draggableAttributes[index]['selectedInExpand'] = false;
          this.draggableAttributes[index]['expandPosition'] = -1;
        } else if (tempDragAttr?.addOnExpand?.type == 'GSI') {
          let index = this.expandGsiList.findIndex((attr: any) => attr?.name == tempDragAttr?.addOnExpand?.value);
          this.expandGsiList[index].isSelected = false;
          this.expandGsiList[index].position = -1;
        }
        delete tempDragAttr.addOnExpand;
        break;
      }
    }
  }
  dropCardGsi(event: any) {
    /*istanbul ignore else */
    if (this.tabSelected == 'child' && !this.isExpand) {
      if (this.dragAttrType == 'button' && !this.buttonDroppedInCard) {
        this.cardGsiMap.mapped = true;
        this.cardGsiMap.value = this.draggedGSI;
        this.draggedGSI.isSelected = true;
        this.buttonDroppedInCard = true;
      } else {
        this.alertService.showToaster(`attribute of type GSI only can be dropped `, 'error', 'error');
      }
    } else {
      this.alertService.showToaster(
        `Dragged attribute type is not matched to the dropped attribute.`,
        `error`,
        `error`
      );
    }
  }

  removeGsiFromWholeCard(event?: any) {
    if (this.cardGsiMap.value) {
      this.cardGsiMap.mapped = false;
      this.cardGsiMap.value.isSelected = false;
      this.buttonDroppedInCard = false;
      this.cardGsiMap.value = '';
    }
  }

  @ViewChild('childTemplateContainer', { read: ElementRef }) templateContainerElement!: ElementRef;

  ngAfterViewInit() {
    const intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && this.tabSelected.toLowerCase() == 'child') {
          this.renderTemplateComponents();
        } else {
          console.log('Element is out of view');
        }
      });
    });

    const element = this.templateContainerElement.nativeElement;

    intersectionObserver.observe(element);
  }
}
