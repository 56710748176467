import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-feature-flag-dialog',
  template: `
    <p>
      This feature is under review. Once the review is done we can enable it.
    </p>
    <button
      pButton
      pRipple
      type="button"
      label="Ok"
      class="btn btn-primary nh-radius-16"
      style="float: right"
      (click)="closeDialog()"
    >OK</button> 
  `,
})
export class FeatureFlagDialogComponent {
  constructor(public ref: DynamicDialogRef) {}

  closeDialog() {
    this.ref.close();
  }
}
