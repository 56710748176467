import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeUnitTypes } from '../lib/enums/changeunit-types.enum';
import { Entity } from '../lib/modal/entity';
import { takeUntil } from 'rxjs/operators';
// import { shelfTypes} from 'projects/common-services/src/generic-folder/shelf-types.constant';

import * as ShelfTypes from '../assets/shelf-types.json';
export const shelfTypes: any = ShelfTypes;
import * as ContentTypes from '../assets/content-types.json';
export const contentTypes: any = ContentTypes;
import * as FooterTypes from '../assets/footer-designs.json';
export const footerType: any = FooterTypes;
import * as ArticleTypes from '../assets/article_template.json';
export const article_template: any = ArticleTypes;
// import * as Fonts from '../assets/fonts.constants.ts';
// export const fonts: any = fonts;
import * as Fonts from '../assets/fonts.constant';
export const fonts: any = Fonts;
import * as QuickLinkType from '../assets/quickLinks-type.json';
export const quickLinkType: any = QuickLinkType;
import * as DesignSystemLayouts from '../assets/layouts.json';
export const designSystemLayouts: any = DesignSystemLayouts;
import * as LoginTemplates from '../assets/login-design-types.json';
export const loginTemplates: any = LoginTemplates;
import * as Layouts from '../assets/layouts.json';
export const layouts: any = Layouts;
import * as applayouts from '../assets/app-view-layouts.json';
export const applayout : any = applayouts;
import * as PrimeNgConfigs from '../assets/prime-ng-configs.json';
export const primeNgConfigs: any = PrimeNgConfigs;
import {
  Agents,
  AttributeDict,
  AttributeFlat,
  CUFlat,
  Entities,
  EntityFlat,
  EntityFlatKey,
  Flat2Flat
} from '../lib/Flat2Flat';
import { changeUnitMapper } from '../lib/mappers/change-unit/cu-mapper';
import { entityMapper } from '../lib/mappers/entity/entity-mapper';
import { gsiMapper } from '../lib/mappers/gsi/gsi-mapper';
import * as menuIconUrlArray from './../assets/menu-icon-url-array.json';
import { Font } from '../lib/modal/fonts';
import { AttributeType } from '../lib/modal/attribute-type';
import { changeUnitDeMapper } from '../lib/mappers/change-unit/cu-demapper';
import { HeaderCustomization } from '../lib/enums/header-customization.enum';
import { ManageIntegrationsFacadeService } from '../lib/manage-integrations/manage-integrations-facade.service';
import { LoaderService } from '../lib/loader/loader.service';
import { AlertService } from '../lib/toaster/alert.service';
// import { Shelf } from '../lib/modal/mcc';
import { uiElementObj } from '../lib/modal/uiElementObj';
import { nslAttribute } from '../lib/modal/nslAttribute';
import { IDesignRights } from '../lib/modal/IDesignRights';
import { IRecommendations } from '../lib/modal/IRecommendations';
import { EventsFacadeService } from '../lib/events/events-facade.service';
import { TransactionFacadeService } from '../lib/transaction/transaction-facade.service';
import { AdvSearchFetchConfig } from '../lib/modal/globalSearch';
import  { currency}  from '../assets/currency';
export * from '../assets/currency';

// used to pass (field/key) and value and original Data and get  Filtered Data Bases on the (value/input) you give
export function getObjectByValue(field: string, value: string, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
  value !== '' &&
    value !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData.filter((x: any) => {
      /* istanbul ignore else */
      if (x[field] === value) {
        return x;
      }
    });
    return data[0];
  }
}
// used to pass  (field/key) and id and original Data and get  Filtered Data Bases on the (id/input) you give
export function getObjectById(field: string, id: any, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    id !== '' &&
    id !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData.filter((x: any) => {
      /* istanbul ignore else */
      if (parseInt(x[field]) === parseInt(id)) {
        return x;
      }
    });
    return data[0];
  }
}
// pass (field/key) and assigned value from the object
export function getValueFromObjectByKey(field: string, object: any) {
  /* istanbul ignore else */
  if (field !== '' && field !== undefined && object !== undefined && object !== null) {
    return object[field];
  }
}
// pass the (field/key) and (idField) where to Match with the  (id/input) from Original Data and particular value of key after Filter
export function getValueFromArrayOfObjectById(field: string, idField: string, id: any, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    idField !== '' &&
    idField !== undefined &&
    id !== '' &&
    id !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData.filter((x: any) => {
      /* istanbul ignore next */
      if (parseInt(x[idField]) === parseInt(id)) {
        return x;
      }
    });

    return data[0] != undefined ? data[0][field] : '-';
  }
}

export function getArrayValueByIndex(positionIndex: number, field: string, originalData: any) {
  /* istanbul ignore next */
  if (
    positionIndex !== null &&
    positionIndex !== undefined &&
    field !== '' &&
    field !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData[positionIndex];
    return data != undefined ? data[field] : '';
  }
}

export function getValueFromCUObjectfromGSI(
  positionIndex: number,
  field: string,
  typeOfKeyInObject: string,
  originalGSIData: any
) {
  /* istanbul ignore next */
  if (
    positionIndex !== null &&
    positionIndex !== undefined &&
    field !== '' &&
    field !== undefined &&
    typeOfKeyInObject !== '' &&
    typeOfKeyInObject !== undefined &&
    originalGSIData !== undefined
  ) {
    const data = getArrayValueByIndex(
      positionIndex,
      field,
      getValueFromObjectByKey(typeOfKeyInObject, originalGSIData)
    );
    /* istanbul ignore next */
    return data != undefined ? data : '';
  }
}

export function FilterDataNotEquals(field: string, object: any, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    object !== undefined &&
    object !== null &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    return originalData.reduce((acc: any, obj: any) => {
      return acc.filter((x: any) => x[field] !== object[field]);
    }, originalData);
  }
}

export function timeDiff_CalculateInMinutes(currentTime: Date, previousTime: Date) {
  /* istanbul ignore next */
  var diff = (currentTime?.getTime() - previousTime?.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function timeDiff_CalculateInSeconds(currentTime: Date, previousTime: Date) {
  /* istanbul ignore next */
  var diff = (currentTime?.getTime() - previousTime?.getTime()) / 1000;
  return Math.abs(Math.round(diff));
}

export function convertToEntityJSONModel(data: any) {
  /* istanbul ignore next */
  if (data !== undefined) {
    return { ...data, TYPE: 'GeneralEntity' };
  }
}
export function getHostUrl() {
  /* istanbul ignore next */
  if (window.location.host?.includes('localhost') || window.location.host?.startsWith('10.')) {
    let loginApiKey = localStorage.getItem('loginApiKey');
    let defaultTenantName = localStorage.getItem('TenantName');
    if (!loginApiKey) {
      return `https://${defaultTenantName}.dev3.nslhub.com`;
    } else if (loginApiKey === 'qa') {
      return `https://${defaultTenantName}.${loginApiKey}3.nslhub.com`;
    }
    return `https://${defaultTenantName}.${loginApiKey}.nslhub.com`;
  } else {
    /* istanbul ignore next */
    return window.location.origin;
  }
}

export function APMServiceActivationConfig() {
  return false;
}

// Get envronment client id
export function getEnvClientId() {
  const credentials = JSON.parse(localStorage.getItem('credentials'));
    /* istanbul ignore next */
  if (credentials) {
    return credentials?.client_id;
  }
}

// get tenant name
export function getTenantName() {
  let tenantName = localStorage.getItem('TenantName');
  return tenantName;
}

/**
 * This function retrieves the tenant ID from session storage.
 * @returns a string value which is the tenant ID retrieved from the sessionStorage.
 */
export function getTenantId(): string {
  let tenantId: string = localStorage.getItem('TenantId');
  return tenantId;
}

/**
 * This function retrieves the user ID from local storage.
 * @returns A string value representing the user ID.
 */
export function getUserId(): string {
  let userInfo: any = JSON.parse(localStorage.getItem('userInfo'));
  let userId: string = userInfo?.id;
  return userId;
}

export function getCurrentUserName() {
  const credentials = JSON.parse(localStorage.getItem('credentials'));
    /* istanbul ignore next */
  if (credentials) {
    return credentials?.username;
  }
}

export function checkTokenExistsorNot() {
  const credentials = localStorage.getItem('credentials');
  /* istanbul ignore next */
  if (credentials) {
    let decodedJWT: any = decodeJwtoken(JSON.parse(credentials)?.token)?.data;
    /* istanbul ignore next */
    let expiryTime = new Date(decodedJWT?.exp * 1000);
    let clientId = decodedJWT?.azp;
    /* istanbul ignore next */
    if (
      decodedJWT &&
      expiryTime > new Date() &&
      (clientId === localStorage.getItem('envClientId') || clientId.endsWith('_confidential'))
    ) {
      return credentials;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function decodeJwtoken(token: string) {
  try {
    /* istanbul ignore next */
    if (!token) {
      /* istanbul ignore next */
      throw new Error('no token');
    }
    const base64Payload = token.split('.')[1];
    let payload = '';
    try {
      payload = atob(base64Payload);
    } catch (error) {
      /* istanbul ignore next */
      throw new Error('no decoded');
    }
    return {
      data: JSON.parse(payload.toString()),
    };
  } catch (error) {
    /* istanbul ignore next */
    return {
      error: 'not decoded',
    };
  }
}

export function getLocalStorageValue(value: any) {
  const existingValue = localStorage.getItem(value);
  /* istanbul ignore next */
  if (existingValue) {
    return existingValue;
  } else {
    return '';
  }
}

export function removeLocalStorageValue(value: any) {
  const existingValue = localStorage.getItem(value);
  /* istanbul ignore next */
  if (existingValue) {
    localStorage.removeItem(value);
  }
}

export function addLocalStorageValue(key: string, value: any) {
  localStorage.setItem(key, value);
}

export function dynamicGroupBy(data: any, property: string) {
  /* istanbul ignore next */
  if (data && property) {
    return data.reduce((acc: any, obj: any) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
}
// need to work on thsi functionality
// export function deleteCuFromCuList(parentCu:any,originalCulist:any,currentCu:any){

//   let tempNextTriggerSetList: any = [];
//   originalCulist?.forEach((cu:any) => {
//     tempNextTriggerSetList.push({
//       cu: cu,
//       cuname: cu.name,
//       nextTriggerSet: cu.nextTriggerSet,
//       index: cu.index
//     });
//   });

//   let indexToDelete = currentCu?.index;

//   if(parentCu && parentCu.nextTriggerSet.length > 0){
//     tempNextTriggerSetList?.forEach((cuItem: any) =>{
//       if(cuItem.index == indexToDelete){

//       }
//     });
//   }
//   if(parentCu && parentCu.nextTriggerSet.length > 0){
//     let foundNextTriggerSetIndex = parentCu?.nextTriggerSet?.findIndex((x: any)=>x.index===currentCu.index);
//     let deleteCuIndex = originalCulist.findIndex((x: any)=>x.index === currentCu.index);
//     originalCulist.splice(deleteCuIndex,1);
//     parentCu?.nextTriggerSet.splice(foundNextTriggerSetIndex,1);
//   }
//   for(let i=0;i<originalCulist.length;i++){
//     if(originalCulist[i].index===parentCu.index){
//       if(originalCulist.length)
//       originalCulist[i] = parentCu;
//     }
//     // if(originalCulist.length===originalCulist[i].index){
//     //   parentcuIndex =
//     // }
//   }
//   return originalCulist;
// }

//here
export function nodeAddChangeUnitInCUList(parentCU: any, originalCUList: any, changeUnitData: any, type?: any) {
  let isParallelValue = false;
  /* istanbul ignore next */
  if (changeUnitData?.isParallel) {
    isParallelValue = changeUnitData.isParallel;
  }
  //  Used to Nested CU Add Feature
  originalCUList = returnNestedorExceptionCUList(parentCU, originalCUList, changeUnitData, type);
  // will generated the cu index based on the conditions
  let maxIndex: number = 1;
  /* istanbul ignore next */
  if (originalCUList?.length > 0) {
    /* istanbul ignore next */
    originalCUList?.forEach((cu: any) => {
      maxIndex = Math.max(maxIndex, cu.index);
    });
    maxIndex = maxIndex + 1;
  }
  /* istanbul ignore next */
  if (!changeUnitData.nextTriggerSet) {
    changeUnitData.nextTriggerSet = [];
  }
  /* istanbul ignore next */
  let CUIndex = originalCUList.findIndex((x: any) => x.index === parentCU?.index);
  /* istanbul ignore next */
  let parrallelCU: any = [];

  /* istanbul ignore next */
  if (
    originalCUList[CUIndex]?.nextTriggerSet?.length > 0 &&
    !changeUnitData?.isAlternate &&
    type != 'nested' &&
    type != 'exception'
  ) {
    originalCUList[CUIndex].nextTriggerSet.forEach((x: any, index: number) => {
      if (!x.isParallel) {
        changeUnitData.nextTriggerSet.push(x);
      } else {
        parrallelCU.push(x);
      }
    });

    originalCUList[CUIndex].nextTriggerSet = parrallelCU;
  }
  // will find the change unit and update the next trigger set in the parent cu
  /* istanbul ignore next */
  if (parentCU && originalCUList.length > 0 && changeUnitData && changeUnitData?.isEdit !== true) {
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.index === parentCU?.index);

    /* istanbul ignore next */
    if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
      /* istanbul ignore next */
      if (type === ChangeUnitTypes.Embedded) {
        originalCUList[findIndexMatchedCU].nextTriggerSet.push({
          nextCUName: changeUnitData?.name,
          index: maxIndex,
          isParallel: isParallelValue,
        });
      } else {
        if (type !== ChangeUnitTypes.Nested && type !== ChangeUnitTypes.Exception) {
          let nextCuName = changeUnitData?.name;
          let Index = maxIndex;
          originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
            nextCUName: nextCuName,
            index: Index,
            isParallel: isParallelValue,
          });
        }
      }
      originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
      return originalCUList;
    }
  } else if (originalCUList?.length > 0 && changeUnitData) {
    let id = changeUnitData?.parentcuId == undefined ? changeUnitData?.id : changeUnitData?.parentcuId;
    // used to update the cu data with the id / reference change unit
    originalCUList = returnOriginalCUList(originalCUList, changeUnitData, id);
  } else if (originalCUList?.length === 0 && changeUnitData) {
    // used to push the cu when empty cu list is present
    originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex, isParallel: isParallelValue }];
    return originalCUList;
  }
}

/* istanbul ignore next */
function returnOriginalCUList(originalCUList: any, changeUnitData: any, id: any) {
  return originalCUList?.map((x: any) => {
    if (x && id == x.referencedChangeUnit) {
      /* istanbul ignore next */
      const cuData = {
        ...changeUnitData,
        referencedChangeUnit: x.referencedChangeUnit,
        tfReferencedChangeUnit: x.tfReferencedChangeUnit,
        id: x?.id,
        tfId: x.tfId,
        index: (changeUnitData?.isEdit === true || changeUnitData?.parentcuId) && x.index,
        nextTriggerSet: x?.nextTriggerSet,
        dcd: x?.dcd,
        specialFeatureProperties: x?.specialFeatureProperties,
        measures: x?.measures,
        constraint: changeUnitData?.constraint,
        constraintName: changeUnitData?.constraintName,
        condition: x?.condition,
        conditionName: x?.conditionName,
        exceptionCUList: x?.exceptionCUList,
        source: x?.source,
        transactionEndpoint: x?.transactionEndpoint,
        triggerCUDialogList: x?.triggerCUDialogList,
      };
      if (x?.eventCUList) {
        cuData['eventCUList'] = x?.eventCUList;
      }
      return cuData;
    } else {
      return x;
    }
  });
}

function returnNestedorExceptionCUList(parentCU: any, originalCUList: any, changeUnitData: any, type?: any) {
  /* istanbul ignore next */
  if (type === ChangeUnitTypes.Nested) {
    /* istanbul ignore next */
    let findIndexMatchedCU = originalCUList?.findIndex(
      (x: any) => x?.referencedChangeUnit === parentCU?.referencedChangeUnit
    );
    if (findIndexMatchedCU !== -1) {
      if (!originalCUList[findIndexMatchedCU].mindCUList) {
        originalCUList[findIndexMatchedCU].mindCUList = [];
      }
      if (Array.isArray(changeUnitData)) {
        originalCUList[findIndexMatchedCU].mindCUList = [...changeUnitData];
      } else {
        originalCUList[findIndexMatchedCU].mindCUList.push({ ...changeUnitData });
      }
    }
    return originalCUList;
  } else if (type === ChangeUnitTypes.Exception) {
    /* istanbul ignore next */
    // used for Exception CU Feature
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.id === parentCU?.id);
    if (findIndexMatchedCU !== -1) {
      if (!originalCUList[findIndexMatchedCU].exceptionCUList) {
        originalCUList[findIndexMatchedCU].exceptionCUList = [];
      }
      originalCUList[findIndexMatchedCU].exceptionCUList.push(...changeUnitData);
    }
    return originalCUList;
  } else {
    return originalCUList;
  }
}

export function addChangeUnitInCUList(parentCU: any, originalCUList: any, changeUnitData: any, type?: any) {
  //  Used to Nested CU Add Feature
  originalCUList = returnNestedorExceptionCUList(parentCU, originalCUList, changeUnitData, type);
  // will generated the cu index based on the conditions
  let maxIndex = 1;
  /* istanbul ignore next */
  if (originalCUList?.length > 0) {
    /* istanbul ignore next */
    originalCUList.forEach((x: any) => {
      if (maxIndex <= x.index) {
        maxIndex = x.index + 1;
      }
    });
  }

  // will find the change unit and update the next trigger set in the parent cu

  /* istanbul ignore next */
  if (
    parentCU &&
    originalCUList?.length > 0 &&
    changeUnitData &&
    changeUnitData?.isEdit !== true &&
    changeUnitData?.oldVersionId === undefined
  ) {
    /* istanbul ignore next */
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.id === parentCU?.id);
    /* istanbul ignore next */
    if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
      originalCUList[findIndexMatchedCU].nextTriggerSet = originalCUList[findIndexMatchedCU]?.nextTriggerSet
        ? originalCUList[findIndexMatchedCU]?.nextTriggerSet
        : [];
      originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
        nextCUName: changeUnitData?.name,
        index: maxIndex,
        isParallel: changeUnitData?.isParallel ? true : false,
      });
      originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
      return originalCUList;
    }
  } else if (originalCUList?.length > 0 && changeUnitData) {
    let id = changeUnitData?.oldVersionId == undefined ? changeUnitData?.id : changeUnitData?.oldVersionId;
    // used to update the cu data with the id / reference change unit
    return originalCUList?.map((x: any) => {
      if (x && id == x.referencedChangeUnit && x.index === changeUnitData?.index) {
        const cuData = {
          ...changeUnitData,
          referencedChangeUnit:
            changeUnitData?.oldVersionId == undefined ? x.referencedChangeUnit : changeUnitData?.referencedChangeUnit,
          tfReferencedChangeUnit:
            changeUnitData?.oldVersionId == undefined
              ? x.tfReferencedChangeUnit
              : changeUnitData?.tfReferencedChangeUnit,
          id: x?.id,
          tfId: x.tfId,
          index: (changeUnitData?.isEdit === true || changeUnitData?.oldVersionId) && x.index,
          nextTriggerSet: x?.nextTriggerSet,
          dcd: x?.dcd,
          specialFeatureProperties: x?.specialFeatureProperties,
          measures: x?.measures,
          constraint: changeUnitData?.constraint,
          constraintName: changeUnitData?.constraintName,
          condition: x?.condition,
          conditionName: x?.conditionName,
          exceptionCUList: x?.exceptionCUList,
          slotItemProperties:
            changeUnitData.slotItemProperties &&
              Object.keys(changeUnitData.slotItemProperties)?.length > 0 &&
              x?.index == changeUnitData?.index
              ? changeUnitData.slotItemProperties
              : x?.slotItemProperties,
          source: x?.source,
          transactionEndpoint: x?.transactionEndpoint,
          triggerCUDialogList: x?.triggerCUDialogList,
        };
        if (x?.eventCUList) {
          cuData['eventCUList'] = x?.eventCUList;
        }
        return cuData;
      } else {
        return x;
      }
    });
  } else if (originalCUList?.length === 0 && changeUnitData) {
    // used to push the cu when empty cu list is present
    originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
    return originalCUList;
  }
}

export function constructRightsObj(rights: any, type: any) {
  let decisionRights, informationRights, executionRights;
  /* istanbul ignore next */
  if (type === 'design') {
    decisionRights = rights?.designTimeRights?.decisionRight ? rights?.designTimeRights.decisionRight : false;
  } else if (rights?.txnTimeRights?.decisionRight) {
    /* istanbul ignore next */
    decisionRights = rights?.txnTimeRights.decisionRight;
  } else {
    decisionRights = false;
  }
  /* istanbul ignore next */
  if (type === 'design') {
    informationRights = rights?.designTimeRights?.informationRight ? rights?.designTimeRights.informationRight : false;
  } else if (rights?.txnTimeRights?.informationRight) {
    /* istanbul ignore next */
    informationRights = rights?.txnTimeRights.informationRight;
  } else {
    informationRights = false;
  }
  /* istanbul ignore next */
  if (type === 'design') {
    executionRights = false;
  } else {
    /* istanbul ignore next */
    executionRights = rights?.txnTimeRights?.executionRight ? rights?.txnTimeRights.executionRight : false;
  }
  return {
    name:
      /* istanbul ignore next */
      rights?.rightHolderName ? rights?.rightHolderName : rights?.name,
    /* istanbul ignore next */
    rightHolderId:
      /* istanbul ignore next */
      rights?.rightHolderId ? rights?.rightHolderId : rights?.id,
    /* istanbul ignore next */
    rightHolderType:
      /* istanbul ignore next */
      rights?.rightHolderType ? rights?.rightHolderType : 'ROLE',
    /* istanbul ignore next */
    rightHolderName:
      /* istanbul ignore next */
      rights?.rightHolderName ? rights?.rightHolderName : rights?.name,
    decisionRight: decisionRights,
    informationRight: informationRights,
    executionRight: executionRights,
  };
}

export function addChangeUnitOnlyInParentCUNextTriggerSet(parentCU: any, originalCUList: any, changeUnitData: any) {
  // add change unit to the cuList , it will not update the parent cu next triggerset used for link / unlink cu's
  /* istanbul ignore next */
  if (parentCU && originalCUList?.length > 0 && changeUnitData && changeUnitData?.index) {
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.id === parentCU?.id);
    if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
      originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
        nextCUName: changeUnitData?.name,
        index: changeUnitData.index,
      });
      return originalCUList;
    }
  } else {
    return originalCUList;
  }
}

export function selectedCUModelForDLD(changeUnit: any) {
  /* istanbul ignore next */
  if (changeUnit) {
    /* istanbul ignore next */
    return {
      name: changeUnit?.name,
      cuId: changeUnit?.id,
      index: changeUnit.index,
      nextTriggerSet: changeUnit.nextTriggerSet?.map((x: any) => {
        return getnextTriggerSet(x);
      }),
      source: changeUnit?.source,
    };
  }
}

export function getnextTriggerSet(triggerSet: any) {
  /* istanbul ignore next */
  if (triggerSet) {
    return {
      index: triggerSet.index,
      nextCUName: triggerSet.nextCUName,
    };
  }
}

export function generateDLDRequestBasedonTempGsiObject(tempGsiObject: any) {
  /* istanbul ignore next */
  return {
    ...tempGsiObject?.appDetails,
    cus: tempGsiObject?.cuList?.map((x: any) => {
      return selectedCUModelForDLD(x);
    }),
    actualCuIndex: getValueFromObjectByKey('index', tempGsiObject?.cuList[tempGsiObject?.activeCuIndex]),
    // activeCuIndex: tempGsiObject.activeCuIndex,
  };
}

export function generateDLDRequestBasedonTempGsiObjectInNode(tempGsiObject: any, cuIndex: number) {
  /* istanbul ignore next */
  return {
    // ...tempGsiObject?.appDetails,
    gsiName: tempGsiObject?.name,
    cus: tempGsiObject?.solutionLogic?.map((x: any) => {
      return selectedCUModelForDLD(x);
    }),
    actualCuIndex: getValueFromObjectByKey('index', tempGsiObject?.solutionLogic?.[cuIndex]),
    // activeCuIndex: tempGsiObject.activeCuIndex,
  };
}

/**
 *
 * @param previousState
 * @param data
 * @param typeOfMessage
 * @returns  updated messageList
 * Used to push the latest message or question in to the same array
 *
 */

export function chatBotModelFormat(previousState: any, data: any, typeOfMessage: string) {
  /* istanbul ignore next */
  if (data) {
    /* istanbul ignore next */
    const previousStateData = previousState?.messagesList;
    if (typeOfMessage === 'GreetingMessage') {
      /* istanbul ignore next */
      previousStateData?.push({ greetMessage: data });
    } else if (typeOfMessage === 'Update_Question') {
      /* istanbul ignore next */
      previousStateData?.push({ question: data?.quest, read: data?.read ? data?.read : true });
    } else if (typeOfMessage === 'Update_Answer') {
      /* istanbul ignore next */
      previousStateData?.push({ answer: data });
    } else if (typeOfMessage === 'Send_Voice_Input') {
      /* istanbul ignore next */
      previousStateData?.push({ voice: data });
    } else if (typeOfMessage === 'Update_ProfanityQuestion') {
      /* istanbul ignore next */
      let previousQuestionIndex = previousState?.messagesList?.length - 1;
      previousStateData?.splice(previousQuestionIndex, 1, { question: data?.quest });
    } else if (typeOfMessage === 'Update_VoiceWithTextQuestionAndAnswer') {
      /* istanbul ignore next */
      let previousQuestionIndex = previousState?.messagesList?.length - 1;
      previousStateData?.splice(previousQuestionIndex, 1, {
        question: data?.ans?.profanityStatus === true ? data?.ans?.maskText : data?.ans?.text,
      });
      previousStateData?.push({ answer: data?.ans?.answerData });
    } else if (typeOfMessage === 'UpdateEvent') {
      previousStateData?.push({ event: data });
    } else if (typeOfMessage === 'UpdateFile') {
      previousStateData?.push({ file: data });
    }

    return {
      messagesList: previousStateData,
    };
  }
}

// Adds cloned cu to the current gsi
export function clonePublishedCuToGSI(cuList: any, cuData: any) {
  /* istanbul ignore next */
  return cuList?.map((changeUnit: any) => {
    if (changeUnit.referencedChangeUnit === cuData?.oldVersionId) {
      return {
        ...cuData,
        referencedChangeUnit: cuData.id,
        tfReferencedChangeUnit: cuData?.tfId,
        id: null,
      };
    } else {
      return changeUnit;
    }
  });
}

export function isNumber(val: any) {
  return !isNaN(val);
}

export function createEntityObj(entity: Entity) {
  var Attributes: any = [];
  /* istanbul ignore next */
  entity?.nslAttributes?.forEach((attribute: any) => {
    let attrSourceValues = [];
    /* istanbul ignore next */
    let sourceValues = attribute?.attributeType?.properties?.sourceValues;
    if (sourceValues) {
      // attrSourceValues = JSON.parse(sourceValues);
      //
      /* istanbul ignore next */
      sourceValues = sourceValues?.map((x: any) => x?.DATA?.actualValue);
    }
    /* istanbul ignore next */
    Attributes.push({
      name: attribute['name'],
      attributeType: {
        type: attribute['attributeType']['type'],
        uiElement: attribute?.uiElement?.uiElement
          ? attribute?.uiElement?.uiElement
          : attribute['attributeType']['uiElement'],
        values: sourceValues ? sourceValues : [],
      },
    });
  });
  return { name: entity['name'], nslAttributes: Attributes };
}

//To check data type of source attribute and provide condition accordingly
export function checkDataTypeInCondition(cpArray: any) {
  /* istanbul ignore next */
  if (
    cpArray?.sourceAttributeType == 'number' ||
    cpArray?.sourceAttributeType == 'decimalnumber' ||
    cpArray?.sourceAttributeType == undefined ||
    cpArray?.sourceAttributeType == ''
  ) {
    /* istanbul ignore else */
    if (isNaN(cpArray.targetAttributeInput)) {
      cpArray.targetAttributeInput = `'${cpArray.targetAttributeInput}'`;
    }
  } else {
    cpArray.targetAttributeInput = `'${cpArray.targetAttributeInput}'`;
  }
  return cpArray;
}
export function getFlatData(cunit: any, nested?: any, activeLayer?: any, defaultAgent?: any) {
  let dataToSend: Flat2Flat[] = [];
  /* istanbul ignore next */
  let changeUnit: CUFlat = new CUFlat(
    cunit?.name,
    cunit?.referencedChangeUnit ? cunit?.referencedChangeUnit : cunit?.id,
    '<cu TF ID>',
    cunit?.isReserved ? 'true' : 'false'
  );
  /* istanbul ignore next */
  if (!changeUnit?.dsd_id) {
    changeUnit.dsd_id = '<cu DSD ID>';
  }
  /* istanbul ignore next */
  if (cunit?.isReserved) {
    changeUnit.isReserved = 'true';
  }
  let agentsList: any = [];
  let agentName: string = '';
  /* istanbul ignore next */
  if (cunit?.agents && cunit?.agents[0]?.agentType?.toLocaleLowerCase() == 'machine') {
    agentName = 'machine';
    let agents: Agents = new Agents(agentName, '', '');
    agentsList.push(agents);
  }
  /* istanbul ignore next */
  if (cunit?.designTimeRights && cunit?.designTimeRights?.length > 0) {
  /* istanbul ignore next */
    cunit?.designTimeRights?.forEach((agent: any) => {
      if (agent.rightHolderName && agent.rightHolderName?.toLocaleLowerCase() != 'machine') {
        let agents: Agents = new Agents(agent.rightHolderName, '<agents DSD ID>', '<agents TF ID>');
        agentsList.push(agents);
      } else if (agent?.name && agent.name.toLocaleLowerCase() != 'machine') {
        let agents: Agents = new Agents(agent.name, '<agents DSD ID>', '<agents TF ID>');
        agentsList.push(agents);
      }
    });
  }
  /* istanbul ignore next */
  let machineAgent = cunit?.sentenceTags?.physical?.find((tag: any) => tag?.tag == 'Mch.');
  if (machineAgent && agentName?.toLocaleLowerCase() != 'machine') {
    agentName = machineAgent?.token;
    let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
    agentsList.push(agents);
  }
  /* istanbul ignore next */
  let machineAgentTag = cunit?.sentenceTags?.physical?.find((tag: any) => tag?.tag == 'machine agent');
  if (machineAgentTag && agentName.toLocaleLowerCase() != 'machine') {
    agentName = machineAgentTag?.token;
    let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
    agentsList.push(agents);
  }
  let layers: Entities = {};
  let Nested_payload,
    NestedL = {},
    Nested_data = {};
  if (!nested) {
    /* istanbul ignore next */
    cunit?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      let entites: EntityFlatKey = {};
      /* istanbul ignore next */
      if (layer.type == activeLayer || activeLayer === true) {
      /* istanbul ignore next */
        layer?.participatingItems?.forEach((entity: any) => {
          let attributes: AttributeDict = {};
          /* istanbul ignore next */
          if (entity?.nslAttributes && entity?.nslAttributes.length > 0) {
            /* istanbul ignore next */
            entity?.nslAttributes?.forEach((attribute: any) => {
              attributes[attribute?.name] = new AttributeFlat(
                attribute?.dsdId ? attribute?.dsdId : '<attr 1.1 DSD ID>',
                '<attr 1.1 TF ID>'
              );
              /* istanbul ignore next */
              if (attribute?.generalEntity) {
                Nested_payload = nestedEntityPayloadS(attribute?.generalEntity, attribute?.dsdId);
                Nested_data[attribute?.name] = Nested_payload[attribute?.name];
              }
            });
          }
          entites[entity?.name] = new EntityFlat('<entity1 TF ID>', entity?.id, attributes);
        });
        layers[layer?.type] = entites;
        NestedL[layer?.type] = Nested_data;
      }
    });
  } else {
    /* istanbul ignore next */
    cunit?.layers?.forEach((layer: any) => {
      /*istanbul ignore else*/
      let entites: EntityFlatKey = {};
      /* istanbul ignore next */
      layer?.participatingItems?.forEach((entity: any) => {
        let attributes: AttributeDict = {};
        /* istanbul ignore next */
        if (entity?.item?.DATA?.nslAttributes && entity?.item?.DATA?.nslAttributes.length > 0) {
          /* istanbul ignore next */
          entity?.item?.DATA?.nslAttributes?.forEach((attribute: any) => {
            attributes[attribute?.name] = new AttributeFlat('<attr 1.1 DSD ID>', '<attr 1.1 TF ID>');
          });
        }
        entites[entity?.item?.DATA?.name] = new EntityFlat('<entity1 TF ID>', entity?.item?.DATA?.id, attributes);
      });
      layers[layer?.type] = entites;
    });
  }

  let data: Flat2Flat = new Flat2Flat(changeUnit, agentsList, layers);
  if (Nested_payload) {
    data['nested_payload'] = { entities: {} };
    data['nested_payload'].entities = NestedL;
    data['is_nested'] = 'True';
  }
  dataToSend.push(data);
  return dataToSend;
}

export function callfat2flatGSI(gsiData: any) {
  let dataToSend: Flat2Flat[] = [];

  let changeUnit: CUFlat = new CUFlat(
    gsiData?.name,
    gsiData?.referencedChangeUnit ? gsiData?.referencedChangeUnit : '',
    '<cu TF ID>',
    gsiData?.isReserved ? 'True' : 'false'
  );
  let agentsList: any = [];
  let agentName: string = '';
  if (gsiData?.designTimeRights && gsiData?.designTimeRights?.length > 0) {
    gsiData?.designTimeRights?.forEach((agent: any) => {
      agentName = agent?.rightHolderName;
      let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
      agentsList.push(agents);
    });
  }

  let layers: Entities = {};

  gsiData?.layers?.forEach((layer: any) => {
    /*istanbul ignore else*/
    let entites: EntityFlatKey = {};
    layer?.participatingItems?.forEach((entity: any) => {
      let attributes: AttributeDict = {};
      if (entity?.nslAttributes && entity?.nslAttributes.length > 0) {
        entity?.nslAttributes?.forEach((attribute: any) => {
          attributes[attribute?.name] = new AttributeFlat('<attr 1.1 DSD ID>', '<attr 1.1 TF ID>');
        });
      }
      entites[entity?.name] = new EntityFlat('<entity1 TF ID>', entity?.id, attributes);
    });
    layers[layer?.type] = entites;
  });
  let data: Flat2Flat = new Flat2Flat(changeUnit, agentsList, layers);
  dataToSend.push(data);

  return dataToSend;
}

export function callfat2flat(gsiData: any) {
  let dataToSend: Flat2Flat[] = [];
  /*istanbul ignore next*/
  gsiData?.solutionLogic?.forEach((cunit: any) => {
    let changeUnit: CUFlat = new CUFlat(
      cunit?.name,
      cunit?.referencedChangeUnit ? cunit.referencedChangeUnit : '',
      '<cu TF ID>',
      cunit.isReserved ? 'true' : 'false'
    );
    let agentsList: any = [];
    let agentName: string = '';
    /*istanbul ignore else*/
    if (cunit?.agents && cunit?.agents[0]?.agentType == 'machine') {
      agentName = 'machine';
      let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
      agentsList.push(agents);
    }
    /*istanbul ignore else*/
    if (cunit?.designTimeRights && cunit?.designTimeRights?.length > 0) {
      cunit?.designTimeRights?.forEach((agent: any) => {
        let agents: Agents = new Agents(
          agent.rightHolderName ? agent.rightHolderName : agent.name,
          '<agents DSD ID>',
          '<agents TF ID>'
        );
        agentsList.push(agents);
      });
    }
    /*istanbul ignore else*/
    let machineAgent = cunit?.sentenceTags?.physical?.find((tag: any) => tag.tag == 'Mch.');
    if (machineAgent) {
      agentName = machineAgent?.token;
      let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
      agentsList.push(agents);
    }
    let layers: Entities = {};
    let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
    cunit?.layers?.forEach((layer: any) => {
      /*istanbul ignore else*/
      let entites: EntityFlatKey = {};
      layer?.participatingItems?.forEach((entity: any) => {
        let attributes: AttributeDict = {};
        if (entity?.nslAttributes && entity?.nslAttributes?.length > 0) {
          entity?.nslAttributes?.forEach((attribute: any) => {
            attributes[attribute?.name] = new AttributeFlat('<attr 1.1 DSD ID>', '<attr 1.1 TF ID>');
          });
        }
        entites[entity?.name] = new EntityFlat('<entity1 TF ID>', entity?.id, attributes);
      });
      layers[layer?.type] = entites;
    });
    let data: Flat2Flat = new Flat2Flat(changeUnit, agentsList, layers);
    dataToSend.push(data);
  });
  return dataToSend;
}
export function wrapperDrop(event: CdkDragDrop<string[]>) {
  /* istanbul ignore next */
  if (event.previousContainer === event.container) {
    moveItemInArray(event.container?.data, event.previousIndex, event.currentIndex);
  } else {
    transferArrayItem(event.previousContainer?.data, event.container?.data, event.previousIndex, event.currentIndex);
  }
}

/* istanbul ignore next */
export function keyIdentifierGetter(fieldObj: any, selectortype: any) {
  let keyIdentifier = '';
  let field = fieldObj.value;
  if (field.type === 'attribute' && field.entAttributeName !== '') {
    keyIdentifier += '{${' + field.entAttributeName + '}';
    if (field.entAttributeString !== '') {
      keyIdentifier += '.' + field.entAttributeString + '.' + field.entAttributeStringValue;
    }
    keyIdentifier += '}';
  } else if (field.type === 'separator' && selectortype) {
    keyIdentifier += '{$SEP}';
  } else if (field.type === 'increment') {
    keyIdentifier += '{$INC_ID}';
  } else if (field.type === 'date') {
    keyIdentifier += '{$' + field.dateFormat + '}';
  } else if (field.type === 'format') {
    keyIdentifier += '{' + field.format + '}';
  }
  else if (field?.type === 'grouping') {
    keyIdentifier += '{$' + field?.grouping + '}';
  } else if (field?.type === 'orgLevel') {
    keyIdentifier += '{$' + field?.orgLevel + '}';
  }
  else if (field?.type === 'orgUnitName') {
    keyIdentifier += '{$' + field?.orgUnitName + '}';
  }
  return keyIdentifier;
}

// to add new condition array
export function addNewCondition(conditionalPotentiality: any, queries: any, toggleCondition: any) {
  conditionalPotentiality.push(JSON.parse(JSON.stringify(queries)));
  toggleCondition.push(false);
  /* istanbul ignore else  */
  if (conditionalPotentiality.length > 1) {
    conditionalPotentiality[conditionalPotentiality.length - 2].predicate = '&&';
  }
  return {
    conditionalPotentiality: conditionalPotentiality,
    toggleCondition: toggleCondition,
  };
}

// changing predicate of conditions  to conditional potentiality array
export function predicateChanged(predicate: any, index: any, conditionalPotentiality: any, toggleCondition: any) {
  conditionalPotentiality[index - 1].predicate = predicate;
  /* istanbul ignore next  */
  if (predicate == '&&') {
    /* istanbul ignore else  */
    if (toggleCondition[index]) {
      toggleCondition[index] = false;
    }
  } else if (predicate == '||') {
    /* istanbul ignore else  */
    if (!toggleCondition[index]) {
      toggleCondition[index] = true;
    }
  }
  return {
    conditionalPotentiality: conditionalPotentiality,
    toggleCondition: toggleCondition,
  };
}

// deleting the query of conditions from conditional potentiality array
export function deleteCondition(rowIndex: number, conditionalPotentiality: any, toggleCondition: any) {
  /* istanbul ignore else  */
  conditionalPotentiality.splice(rowIndex, 1);
  toggleCondition.splice(rowIndex, 1);
  /* istanbul ignore next  */
  if (conditionalPotentiality[rowIndex - 1]?.predicate) {
    conditionalPotentiality[rowIndex - 1].predicate = '';
  }
  return {
    conditionalPotentiality: conditionalPotentiality,
    toggleCondition: toggleCondition,
  };
}

//updating condition
export function updateConditionalPotentiality(conditionalPotentiality: any, isRecursive?: any) {
  let condition = '';
  let conditionalPotentialityAttr = conditionalPotentiality;
  for (let conditonAltobj of conditionalPotentialityAttr) {
    /* istanbul ignore else */
    if (conditonAltobj.targetAttributeInput) {
      conditonAltobj = checkDataTypeInCondition(conditonAltobj);
    }
    conditonAltobj.targetAttribute =
      conditonAltobj.targetAttribute.trim() !== ''
        ? conditonAltobj.targetAttribute
        : conditonAltobj.targetAttributeInput;
    if (
      !conditonAltobj.sourceAttribute &&
      !conditonAltobj.operator &&
      !conditonAltobj.targetAttribute &&
      conditionalPotentialityAttr.length == 1
    ) {
      condition = condition.trim();
    } else {
      if (!conditonAltobj.operator && !isRecursive) {
        return '';
      } else {
        if (conditonAltobj.operator == 'INCLUDES') {
          let res = conditonAltobj.targetAttribute.replaceAll("'", '');
          condition += conditonAltobj.sourceAttribute + ' ## (' + res + ') ' + conditonAltobj.predicate + ' ';
        } else {
          condition =
            condition +
            conditonAltobj.sourceAttribute +
            ' ' +
            conditonAltobj.operator +
            ' ' +
            conditonAltobj.targetAttribute +
            ' ' +
            conditonAltobj.predicate +
            ' ';
        }
      }
    }
  }
  condition = condition.trim();
  return condition;
}

//creates Recursive Conditons
export function createConditionalArray(condition: any, conditionAttr: any, nameConditionFlag?: any): any {
  let predicateConditionIndex: any;
  if (condition?.indexOf('&&') > condition?.indexOf('||')) {
    if (condition?.indexOf('||') == -1) {
      predicateConditionIndex = condition.indexOf('&&');
    } else {
      predicateConditionIndex = condition.indexOf('||');
    }
  } else if (condition?.indexOf('&&') < condition?.indexOf('||')) {
    if (condition?.indexOf('&&') == -1) {
      predicateConditionIndex = condition.indexOf('||');
    } else {
      predicateConditionIndex = condition.indexOf('&&');
    }
  } else {
    conditionAttr.push(getSourceTargetArray(condition, '', nameConditionFlag));
    return conditionAttr;
  }
  /* istanbul ignore next */
  if (condition[predicateConditionIndex] === '&') {
    let tempCondition = condition.substring(0, predicateConditionIndex);
    conditionAttr.push(getSourceTargetArray(tempCondition, '&&', nameConditionFlag));
    condition = condition.substring(predicateConditionIndex + 2, condition.length);
    /* istanbul ignore else */
    if (condition) {
      return createConditionalArray(condition.trim(), conditionAttr, nameConditionFlag);
    }
  } else if (condition[predicateConditionIndex] === '|') {
    let tempCondition = condition.substring(0, predicateConditionIndex);
    conditionAttr.push(getSourceTargetArray(tempCondition, '||', nameConditionFlag));
    condition = condition.substring(predicateConditionIndex + 2, condition.length);
    /* istanbul ignore else */
    if (condition) {
      return createConditionalArray(condition.trim(), conditionAttr, nameConditionFlag);
    }
  }
}
// gets source and target
export function getSourceTargetArray(tempCondition: string, predicate: string, nameConditionFlag?: any) {
  let operators = [' < ', ' > ', '!=', ' = ', '==', '<=', '>=', 'IN POTENTIALITY', 'IN REALITY', '##', ' ! '];
  let splitedTemp: any = [];
  let modTargetAttr;
  let onlyNumberValue = true;
  let entityName = true;
  for (let operator of operators) {
    /* istanbul ignore next */
    if (tempCondition?.includes(operator)) {
      onlyNumberValue = false;
      entityName = false;
      splitedTemp = tempCondition.split(operator);
      if (operator == '##') {
        modTargetAttr = splitedTemp?.[1]?.replace('(', '');
        modTargetAttr = modTargetAttr?.replace(')', '');
      }
      if (nameConditionFlag) {
        for (let k = 0; k < splitedTemp.length; k++) {
          /* istanbul ignore next */
          if (splitedTemp[k] != undefined && !isNumber(splitedTemp[k]) && splitedTemp[k]?.includes('.SL')) {
            let j = splitedTemp[k].split('.');
            splitedTemp[k] = j[j.length - 1];
          }
        }
        return {
          sourceAttribute: splitedTemp?.[0].trim(),
          operator: operator == '##' ? 'INCLUDES' : operator.trim(),
          targetAttribute: operator == '##' ? modTargetAttr : splitedTemp?.[1].trim(),
          targetAttributeInput: '',
          predicate: predicate.trim(),
        };
      } else {
        return {
          sourceAttribute: splitedTemp?.[0].trim(),
          operator: operator == '##' ? 'INCLUDES' : operator.trim(),
          targetAttribute: operator == '##' ? modTargetAttr : splitedTemp?.[1].trim(),
          targetAttributeInput: '',
          predicate: predicate.trim(),
        };
      }
    }
  }
  /* istanbul ignore next */
  if (tempCondition != undefined) {
    if (isNumber(tempCondition) && onlyNumberValue) {
      entityName = false;
      return {
        sourceAttribute: '',
        operator: '',
        targetAttribute: '',
        targetAttributeInput: Number(tempCondition),
        predicate: predicate.trim(),
      };
    } else if (entityName) {
      return {
        sourceAttribute: tempCondition,
        operator: '',
        targetAttribute: '',
        targetAttributeInput: '',
        predicate: predicate.trim(),
      };
    }
  }
}

export function isReservedCU(changeUnit: any) {
  /* istanbul ignore next */
  if (changeUnit?.isReserved || changeUnit?.name?.includes('NSL_')) {
    return true;
  } else {
    return false;
  }
}
export function onlyTemplateOrNormalCU(rCu: any) {
  /*istanbul ignore next*/
  if (rCu?.isReserved || rCu?.name?.includes('NSL_')) {
    if (rCu?.name?.includes('NSL_Template') || isTemplate(rCu) || rCu?.reservedCUType?.includes('EQL')) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}
export function onlyTempateCU(rCu:any){
  if (rCu?.name?.includes('NSL_Template') || isTemplate(rCu) || rCu?.reservedCUType?.includes('EQL')) {
    return true;
  }
  return false;
}
// To get proper display value for date types UI controls
export function displayDateDefaultValue(
  value: any,
  attributeUiElement: any,
  dateFormatType: string,
  timeFormatType: string,
  yearMonthFormatType: string,
  customDateFormat?: string
) {
  let dispalyValue;

  /* istanbul ignore next */
  if ((attributeUiElement === 'date' || attributeUiElement === 'dateonly') && !isNaN(Date.parse(value))) {
    /* istanbul ignore next */
    dispalyValue = formatDate(value, customDateFormat ? customDateFormat : dateFormatType, 'en-US');
  }

  /* istanbul ignore next */
  if (attributeUiElement === 'datetime' && !isNaN(Date.parse(value))) {
    /* istanbul ignore next */
    if (timeFormatType === '24-hr') {
      /* istanbul ignore next */
      dispalyValue = formatDate(value, customDateFormat ? customDateFormat : `${dateFormatType} HH:mm:ss`, 'en-US');
    } else {
      /* istanbul ignore next */
      dispalyValue = formatDate(value, customDateFormat ? customDateFormat : `${dateFormatType} hh:mm:ss a`, 'en-US');
    }
  }

  /* istanbul ignore next */
  if ((attributeUiElement === 'time' || attributeUiElement === 'timer') && !isNaN(Date.parse(value))) {
    /* istanbul ignore next */
    if (timeFormatType === '24-hr') {
      /* istanbul ignore next */
      dispalyValue = formatDate(value, customDateFormat ? customDateFormat : `HH:mm:ss`, 'en-US');
    } else {
      /* istanbul ignore next */
      dispalyValue = formatDate(value, customDateFormat ? customDateFormat : `hh:mm:ss a`, 'en-US');
    }
  } else if (attributeUiElement === 'timer' && isNaN(Date.parse(value))) {
    dispalyValue = value;
  }

  /* istanbul ignore next */
  if (attributeUiElement === 'dateRange') {
    /* istanbul ignore next */
    dispalyValue =
      formatDate(JSON.parse(value)[0], customDateFormat ? customDateFormat : dateFormatType, 'en-US') +
      ' - ' +
      formatDate(JSON.parse(value)[1], customDateFormat ? customDateFormat : dateFormatType, 'en-US');
  }

  /* istanbul ignore next */
  if (attributeUiElement === 'yearMonth' && !isNaN(Date.parse(value))) {
    dispalyValue = formatDate(value, yearMonthFormatType, 'en-US');
  }

  /* istanbul ignore next */
  if (attributeUiElement === 'year') {
    dispalyValue = value;
  }
  if(attributeUiElement === 'timeonly' && isNaN(Date.parse(value))){
    dispalyValue = convertTime(value ,timeFormatType , dispalyValue);
  }
  /* istanbul ignore next */
  if (attributeUiElement === 'duration') {
    let durationDefaultValue = durationTime(value);
    dispalyValue = getDurationDisplayValue(durationDefaultValue);
  }
  return dispalyValue;
}

function convertTime(timeStr : any, timeFormat : any , dispalyValue : any) {
  if(timeStr && timeFormat){
  const [hours, minutes, seconds] = timeStr?.split(':')?.map(Number);
  if (timeFormat === '12-hr') {
      const period = hours < 12 ? 'AM' : 'PM';
      const formattedHours = hours % 12 || 12;
      return `${formattedHours}:${minutes?.toString()?.padStart(2, '0')}:${seconds?.toString()?.padStart(2, '0')} ${period}`;
  }

  return `${hours?.toString()?.padStart(2, '0')}:${minutes?.toString()?.padStart(2, '0')}:${seconds?.toString()?.padStart(2, '0')}`;
}
return dispalyValue;
}

// To get duration object
export function durationTime(String: string) {
  let duration = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  const REGEX = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  /* istanbul ignore next */
  const [, , , , day, hour, min] = String.match(REGEX) || [];
  /* istanbul ignore next */
  duration.days = parseInt(day) || null;
  /* istanbul ignore next */
  duration.hours = parseInt(hour) || null;
  /* istanbul ignore next */
  duration.minutes = parseInt(min) || null;
  return duration;
}

// To get duration display value
export function getDurationDisplayValue(value: any) {
  let durationDisplayValue = '';
  /* istanbul ignore next */
  if (value?.days !== null) {
    /* istanbul ignore next */
    durationDisplayValue += `${value?.days} Days `;
  }
  /* istanbul ignore next */
  if (value?.hours !== null) {
    /* istanbul ignore next */
    durationDisplayValue += `${value?.hours} Hours `;
  }
  /* istanbul ignore next */
  if (value?.minutes !== null) {
    /* istanbul ignore next */
    durationDisplayValue += `${value?.minutes} Minutes`;
  }
  return durationDisplayValue;
}

// Validate default value based on datatype and UI control
export function validateDefaultValue(
  dateTypeUiControls: any,
  uiControl: any,
  dataType: any,
  value: any,
  sourceValues: any,
  allDefaultValues: any
) {
  let res = {
    isValid: false,
    message: new Array(),
  };

  /* istanbul ignore next */
  if (dateTypeUiControls?.includes(uiControl)) {
    res.isValid = true;
    return res;
  }

  // For empty value
  /* istanbul ignore next */
  if (value === '') {
    res.isValid = false;
    return res;
  }

  // For Boolean
  if (dataType === 'boolean') {
    if (value === 'true' || value === 'false') {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'info',
        msg: 'Default value for boolean can only be true or false',
      });
      return res;
    }
  }

  // For integer,short,byte,float
  if(dataType == 'integer' || dataType == 'short' || dataType == 'byte' || dataType == 'float'){
    if(dataType == 'short' && (value <-32768 || value> 32767)){
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: "Default value is not in short value range"
      });
      return res;
    }
    else if(value < 0){
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: "Default value should not be negative"
      });
      return res;
    }
  }

  // For Decimal, Metric, Curreny
  if (dataType === 'decimalnumber' || uiControl === 'currency') {
    if (!isNaN(Number(value))) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a number`,
      });
      return res;
    }
  }

  // For Number, Mobile number, Progress,
  if (
    dataType === 'number' ||
    uiControl === 'mobilenumber' ||
    uiControl === 'progressbar' ||
    uiControl === 'clickable'
  ) {
    /* istanbul ignore next */
    if (!isNaN(Number(value)) && !value?.includes('.')) {
      if (uiControl === 'progressbar' && (value < 0 || value > 100)) {
        res.isValid = false;
        res.message.push({
          type: 'info',
          msg: 'Default value should be present in between 0 and 100',
        });
        return res;
      }
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a number`,
      });
      return res;
    }
  }

  // For Checkbox, Dropdown, Multiselectdropdown, Radio Button, Typeahead
  if (
    uiControl === 'checkbox' ||
    uiControl === 'dropdown' ||
    uiControl === 'multiselectdropdown' ||
    uiControl === 'radioButton' ||
    uiControl === 'chipmultipleselection' ||
    uiControl === 'chipsingleselection'
  ) {
    /* istanbul ignore next */
    if (sourceValues.findIndex((x: any) => x?.DATA?.actualValue === value) >= 0 && !allDefaultValues?.includes(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      let msg = allDefaultValues?.includes(value)
        ? `${value} is already present in default options.`
        : `${value} is not present in default options.`;
      res.message.push({
        type: 'info',
        msg: msg,
      });
      return res;
    }
  }

  // For Duration
  if (uiControl === 'duration') {
    let regExp = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
    res.isValid = regExp.test(value);
    return res;
  }

  // For Email
  if (uiControl === 'email') {
    let regExp = /\S+@\S+\.\S+/;
    if (regExp.test(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a valid email address`,
      });
      return res;
    }
  }

  // For HTML, Payment, Rich Text
  if (uiControl === 'html' || uiControl === 'payment' || uiControl === 'editor') {
    /* istanbul ignore next */
    const isHTML = (str: string) =>
      !(str || '')
        .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
        .replace(/(<([^>]+)>)/gi, '')
        .trim();
    if (isHTML(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: 'Given default value is not a valid HTML text!',
      });
      return res;
    }
  }

  // For Hyperlink, Preview Document, Redirection, Scorm Video
  if (
    uiControl === 'hyperlink' ||
    uiControl === 'previewdocument' ||
    uiControl === 'redirection' ||
    uiControl === 'scormVideo'
  ) {
    let regExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (regExp.test(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a valid hyperlink!`,
      });
      return res;
    }
  }

  // For label type UI control
  res.isValid = true;
  return res;
}

export function getFileNameFromImgUrl(imgUrl: string) {
  if (!imgUrl) {
    return '';
  }
  /* istanbul ignore next */
  if (imgUrl?.includes("url('")) {
    imgUrl = imgUrl.substring(5, imgUrl.length - 2);
  }
  let imgUrlArr = imgUrl.split('/');
  let name = imgUrlArr[imgUrlArr.length - 1];
  return name;
}

export function getAttributeObj(
  attr: any,
  dataType: any = {
    name: 'text',
    dataType: 'string',
    esDataType: 'string',
    uiElement: 'text',
    isMulti: false,
    properties: [],
  },
  dispName?: any
) {
  let constraints: [];
  /* istanbul ignore next */
  let uiElement: uiElementObj = {
    ...dataType,
  };
  let attributeType: AttributeType = {
    uiElement,
    type: uiElement.dataType,
    properties: {},
    nestedNslDataTypeProperties: {
      item_type: {},
    },
    extendedProperties: {},
  };
  /* istanbul ignore next */
  let attribute: nslAttribute = {
    name: attr?.trim(),
    displayName: dispName ? dispName?.trim() : attr?.trim(),
    attributeType,
    constraints,
    nslAttributeProperties: {
      isTraceable: false,
    },
  };
  return attribute;
}

export function nestedEntityPayload(entity: any, cu?: any) {
  let dataToSend = {};
  dataToSend[entity.name] = {
    tf_id: '',
    dsd_id: '',
    type: 'nested',
    attribute_dict: {},
  };
  /* istanbul ignore next */
  entity?.nslAttributes?.forEach((attr: any) => {
    /* istanbul ignore next */
    if (attr?.generalEntity) {
      let payload = nestedEntityPayload(attr.generalEntity);
      let key = Object.keys(payload)[0];
      dataToSend[entity.name].attribute_dict[key] = payload[key];
      //dataToSend[entity.name].attribute_dict = {...nestedEntityPayload(attr.generalEntity)}
    } else {
      /* istanbul ignore next */
      dataToSend[entity.name].attribute_dict[attr.name] = {
        name: attr?.name,
        dsd_id: '<2.4 DSD ID>',
        tf_id: '<2.4 TF ID>',
      };
    }
  });
  return dataToSend;
}

export function getDummyDataForTemplate(template: string) {
  return {
    sectionName: 'Title Name',
    betType: 'Book',
    contentType: '',
    templateType: template,
    betData: [
      {
        betId: '123',
        betName: 'Title Name',
        betType: 'GSI',
      },
    ],
    hideTitle: false,
  };
}

export function getIsOnBoarding() {
  if (localStorage.getItem('onBoarding') === 'true') {
    return true;
  } else {
    return false;
  }
}

export function getOnboardPersona(userDetails: any) {
  let realmname = localStorage.getItem('TenantName');
  /* istanbul ignore next */
  if (realmname === 'b2c') {
    return 'individualdeveloper';
  } else if (userDetails?.groups?.length > 0) {
    /* istanbul ignore next */
    return userDetails.groups[0]?.name;
  }
}

export function validateProviderImageExistOrNot(alias: string) {
  if (
    alias === 'linkedin' ||
    alias === 'github' ||
    alias === 'facebook' ||
    alias === 'google' ||
    alias === 'apple' ||
    alias === 'microsoft'
  ) {
    return true;
  } else {
    return false;
  }
}
export function getImage(item: any) {
  /*istanbul ignore next*/
  if (item?.betImage && item?.betImage !== 'url') {
    return item?.betImage;
  }
}

export function moveToTransactions(item: any, mode: any, betId: number, router?: Router) {
  /*istanbul ignore next*/
  if (mode == 'transaction') {
    if (item.betType == 'gsi' || item.betType == 'GSI' || item.betType == 'banner_type_gsis') {
      const solutionData = btoa(
        JSON.stringify({
          gsiId: item.betMasterId ? item.betMasterId : item.betId,
          bookId: betId,
        })
      );
      // router.navigate(['transaction/view/' + solutionData]);
    }
    // const solutionData = btoa(JSON.stringify({ gsiId: this.bet?.betData[0].betId }));
    // this.router.navigate(['transaction/view/' + solutionData]);
    // this.router.navigate(['transaction/view/' + solutionData]);
    /*istanbul ignore next*/
    if (item.betType == 'book' || item.betType == 'Book') {
      const solutionData = btoa(
        JSON.stringify({
          bookId: item.betId,
        })
      );
      //  router.navigate(['transaction/view/' + solutionData]);
    }
  }
}
export function getBetDataLength(bet: any) {
  /* istanbul ignore next */
  // for (let i = 0; i < bet?.length - 1; i++) {
  //   /* istanbul ignore next */
  //   bet?.betData?.push(bet?.betData?.[bet?.betData?.length - 1]);
  // }
}
// excellent example of pass byreference in non primitives in Typescript by me, Thankyou

export function getEntityByDsdId(cu: any, tag: any, layerType: any) {
  /*istanbul ignore next*/
  if (cu) {
    let layer = cu?.layers?.find((layer: any) => layer?.type == layerType);
    return layer?.participatingItems[tag.entity_counter];
  }
}
export function formatCuRights(reqData: any) {
  /*istanbul ignore next*/
  if (reqData && reqData?.designTimeRights && reqData?.designTimeRights?.length > 0) {
    reqData?.designTimeRights?.map((obj: any) => {
      if (obj?.condition && obj?.condition?.fromDateTime == '' && obj?.condition?.toDateTime == '') {
        delete obj.condition;
      }
    });

    reqData?.txnTimeRights?.map((obj: any) => {
      if (obj?.condition && obj?.condition?.fromDateTime == '' && obj?.condition?.toDateTime == '') {
        delete obj.condition;
      }
    });
  }
  return reqData;
}

export function checkInputField(event: any, query: any) {
  /*istanbul ignore next*/
  if (query.sourceAttributeuiElement === 'number' || query.sourceAttributeuiElement === 'mobilenumber') {
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode == 45 || (event.charCode > 46 && event.charCode <= 57);
  }
}

export function createChartValues(keyValue: any, checkedValue: any, countStore: any) {
  let newChartVal: any = {};
  /*istanbul ignore else */
  if (countStore[keyValue]) countStore[keyValue].checked = checkedValue;
  for (let [key, value] of Object.entries(countStore)) {
    /*istanbul ignore next */
    if (value['checked']) newChartVal[key?.split('--')[0]] = Number(value['count']);
  }
  return newChartVal;
}

export function prepareTxnReportPayload(
  data: any,
  isTxn: boolean,
  isOpenChart: boolean,
  isTimeSeries: boolean,
  pageNumberReport: number,
  pageSizeReport: number,
  countStore: any,
  moduleType: string
) {
  if (isTxn) {
    return formatTxnReportPayload(
      data?.gsiMasterId,
      data?.referrenceCUMasterId,
      data?.entity,
      data?.attr,
      pageSizeReport,
      pageNumberReport,
      isTimeSeries,
      data?.selectedInterval,
      countStore,
      isOpenChart
    );
  } else {
    return prepareGEDashboardPayloadForCharts(
      data?.entity,
      data?.attr,
      pageNumberReport,
      pageSizeReport,
      isTimeSeries,
      data?.selectedInterval,
      isOpenChart,
      moduleType,
      countStore
    );
  }
}

export function prepareGEDashboardPayload(query: any, pageNo: any, pageSize: any) {
  /* istanbul ignore next */
  let req = {
    entityName: query?.geFilters?.[0]?.entityName,
    outputEntityName: 'Result',
    inputOutputMapping: {},
    xyChart: [] as any,
    limit: pageSize,
    offset: pageNo,
  };

  /*istanbul ignore next */
  query?.geFilters?.forEach((entity: any) => {
    /*istanbul ignore next */
    entity?.attributeNames?.forEach((attr: any) => {
      /*istanbul ignore next */
      req.inputOutputMapping[entity?.entityName + '.' + attr] = 'Result.' + attr;
      req.xyChart.push('Result.' + attr);
    });
  });

  return req;
}

export function prepareGEDashboardPayloadForCharts(
  selectedEntity: any,
  selectedAttribute: any,
  pageNo: any,
  pageSize: any,
  isTimeSeries?: boolean,
  selectedInterval?: string,
  isOpenChart?: boolean,
  moduleType?: string,
  countGEStore?: any
) {
  /*istanbul ignore next*/
  let req: any = {
    entityName: selectedEntity,
    outputEntityName: 'Result',
    inputOutputMapping: {
      nsl_system_txn_id: 'Result.Name_count',
    },
    groupBy: [],
    functions: [
      {
        fieldName: 'nsl_system_txn_id',
        functionType: 'COUNT',
      },
    ],
    limit: pageSize,
    offset: pageNo,
  };
  /*istanbul ignore next*/
  req.inputOutputMapping[selectedEntity + '.' + selectedAttribute] = 'Result.' + selectedAttribute;

  /*istanbul ignore else*/
  if (isTimeSeries) {
    req.groupBy.push({
      fieldName: 'aggUpdatedAt',
      type: 'DATE_HISTOGRAM',
      additional: {
        interval: selectedInterval,
      },
    });
  } else {
    /*istanbul ignore next*/
    req.groupBy.push({ fieldName: selectedEntity + '.' + selectedAttribute });
    if (isOpenChart) {
      req = {
        ...req,
        searchQuery: {
          searchQueryDepth: 0,
          operationType: 'OR',
          queries: [] as any,
        },
      };
      if (moduleType !== 'KPI' && moduleType !== 'msline') {
        for (const [key, val] of Object.entries(countGEStore)) {
          /*istanbul ignore else */
          if (val['checked']) {
            req.searchQuery.queries.push({
              searchQueryDepth: 1,
              fieldName: val['attributeName'] ? val['attributeName'] : '',
              searchOperatorType: 'EQUALS',
              values: [key],
            });
          }
        }
      }
    }
  }

  return req;
}

export function splitAttr(item: any) {
  let resultSplit = '';
  /*istanbul ignore next */
  const [a, ...b] = item?.split('.');
  if (b.length > 1) {
    resultSplit = b.join('.');
  } else {
    resultSplit = b.length ? b[0] : a;
  }
  if (b.length == 0 && item) return item;
  return resultSplit;
}

export function convertToPercentage(y: any, sum: any) {
  return (parseInt(y) * 100) / sum;
}
export function stringToColour(str: any) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
export function setPropertyInDivLayout(
  elRef: ElementRef,
  propertyName: string,
  property: any,
  totalSplit?: number,
  splitIndex?: number
) {
  elRef?.nativeElement?.style?.setProperty(propertyName, property);
}
export function setColorToSplit(elRef: ElementRef, totalSplit: number, array: string[], width: number[]) {
  let number = totalSplit === 3 ? 33 : 50;
  let color1 = array[0];
  let color2 = array[1];
  let data;
  if (totalSplit === 3) {
    let color3 = array[2];
    data = `linear-gradient(to right, ${color1} ${33}%, ${color2} ${33}%,${color2} ${66}%,${color3} ${66}%)`;
  } else {
    data = `linear-gradient(to right, ${color1} ${width[0]}%, ${color2} ${width[1]}%)`;
  }
  elRef?.nativeElement?.style?.setProperty('--color-gradient', data);
}

export function addSequentialCuInCuList(parentCU: any, originalCUList: any, changeUnitData: any) {
  let maxIndex: number = 0;
  /* istanbul ignore next */
  if (!changeUnitData.nextTriggerSet) {
    changeUnitData.nextTriggerSet = [];
  }
  /* istanbul ignore next */
  if (originalCUList?.length > 0) {
    originalCUList?.forEach((cu: any) => {
      maxIndex = Math.max(maxIndex, cu.index);
    });
    maxIndex = maxIndex + 1;
  }
  /* istanbul ignore next */
  let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.index === parentCU?.index);

  /* istanbul ignore else */
  let nonParrallelCU: any = [];
  if (originalCUList[findIndexMatchedCU].nextTriggerSet.length > 0) {
    originalCUList[findIndexMatchedCU].nextTriggerSet.forEach((x: any, index: number) => {
      if (!x.isParallel) {
        changeUnitData.nextTriggerSet.push(x);
      } else {
        nonParrallelCU.push(x);
      }
    });

    originalCUList[findIndexMatchedCU].nextTriggerSet = nonParrallelCU;
    // originalCUList[findIndexMatchedCU].nextTriggerSet.filter((x:any)=>x.isParallel)
  }

  /* istanbul ignore next */
  if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
    originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
      nextCUName: changeUnitData.name,
      index: maxIndex,
    });
  }
  originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
  return originalCUList;
}

export function trimArray(item: string = null) {
  let elements: string[] = [];
  /* istanbul ignore next */
  if (item.trim()) {
    elements = item
      .split('.')
      .map((m) => m.trim())
      .filter((n) => n !== '');
  }
  return elements;
}
export function setStyles(bet: any, elRef: ElementRef) {
  if (bet?.properties) {
    let properties = JSON.parse(bet.properties);
    let splitShelves = bet?.splitShelves;

    // if (bet?.templateType?.includes('split')) {
    //   let properties_1;
    //   let properties_2;
    //   let properties_3;
    //   if (splitShelves?.[0]?.properties) {
    //     properties_1 = JSON.parse(splitShelves?.[0]?.properties);
    //   }
    //   if (splitShelves?.[1]?.properties) {
    //     properties_2 = JSON.parse(splitShelves?.[1]?.properties);
    //   }

    //   if (splitShelves?.length > 2 && splitShelves?.[2]?.properties) {
    //     properties_3 = JSON.parse(splitShelves?.[2]?.properties);
    //   }
    //   let total = splitShelves?.length;
    //   let array = [];

    //   if (properties_1) {
    //     setPropertyInDivLayout(elRef, '--split-1-background', properties_1?.backgroundColor);
    //   }

    //   if (properties_2) {
    //     setPropertyInDivLayout(elRef, '--split-2-background', properties_2?.backgroundColor);
    //   }
    //   if (properties_3) {
    //     setPropertyInDivLayout(elRef, '--split-3-background', properties_3?.backgroundColor);
    //   }
    //   array.push(properties_1?.backgroundColor ? properties_1?.backgroundColor : 'white');
    //   array.push(properties_2?.backgroundColor ? properties_2?.backgroundColor : 'white');
    //   array.push(properties_3?.backgroundColor ? properties_3?.backgroundColor : 'white');
    //   if (total > 1 && bet?.fullColor) {
    //     setColorToSplit(elRef, total, array, bet?.splitWidth);
    //   }
    // }
    if (properties?.backgroundColor) {
      setPropertyInDivLayout(elRef, '--container-color', properties?.backgroundColor);
    }
    if (properties?.containerColor) {
      setPropertyInDivLayout(elRef, '--card-color', properties?.containerColor);
    }
    if (properties?.containerHoverColor) {
      setPropertyInDivLayout(elRef, '--card-hover', properties?.containerHoverColor);
    }
    if (properties?.titleColor) {
      setPropertyInDivLayout(elRef, '--title-color', properties?.titleColor);
    }
    if (properties?.containerHeadingColor) {
      setPropertyInDivLayout(elRef, '--font-color', properties?.containerHeadingColor);
    }
    if (properties?.containerHeadingHoverColor) {
      setPropertyInDivLayout(elRef, '--font-hover-color', properties?.containerHeadingHoverColor);
    }
  }
}

export function setHoverColours(bet: any, elRef: ElementRef) {
  if (bet?.background?.hoverColour) {
    setPropertyInDivLayout(elRef, '--bpc-background-hover', bet.background.hoverColour);
  }
  if (bet?.gsiShelf?.properties?.hoverColour) {
    setPropertyInDivLayout(elRef, '--bpc-gsi-hover', bet.gsiShelf.properties.hoverColour);
  }
  if (bet?.descriptionProperties?.hoverColour) {
    setPropertyInDivLayout(elRef, '--bpc-description-hover', bet.descriptionProperties.hoverColour);
  }
  if (bet?.titleProperties?.hoverColour) {
    setPropertyInDivLayout(elRef, '--bpc-title-hover', bet.titleProperties.hoverColour);
  }
}

export function removeEmptyCu(gsiData: any) {
  /*istanbul ignore next*/
  let emptyCuFound = gsiData?.solutionLogic?.findIndex((x: any) => !x.name || !x.id);
  /*istanbul ignore next*/
  if (emptyCuFound != -1) {
    gsiData?.solutionLogic?.splice(emptyCuFound, 1);
  }
  /*istanbul ignore next*/
  gsiData?.solutionLogic?.forEach((cu: any) => {
    let emptyNexttriggerSetElementFound = cu?.nextTriggerSet?.findIndex((x: any) => !x.nextCUName);
    if (emptyNexttriggerSetElementFound !== -1) {
      cu?.nextTriggerSet?.splice(emptyNexttriggerSetElementFound, 1);
    }
  });
  return gsiData;
}
/* istanbul ignore next */
export function betMapper(type: any, res: any) {
  if (type == 'gsi') {
    /* istanbul ignore next */
    const gsiRes = gsiMapper(res?.result);
    return gsiRes;
  } else if (type == 'change-unit') {
    /* istanbul ignore next */
    const cuRes = changeUnitMapper(res?.result);
    return cuRes;
  } else {
    /* istanbul ignore next */
    const entRes = entityMapper(res?.result);
    return entRes;
  }
}
export function handleSelectAgent(
  event: IRecommendations,
  appendAgentName: boolean,
  index: any,
  gsiData: any,
  agent: any
) {
  /* istanbul ignore next */
  if (index?.agents?.[0]) {
    index.agents[0].agentType = agent;
  }
  /* istanbul ignore next */
  let agentName = event?.name;
  /* istanbul ignore next */
  let agentId = event?.id;
  let designRights: IDesignRights = {
    decisionRight: false,
    disableParentRoleAccess: false,
    executionRight: false,
    informationRight: false,
    rightHolderType: 'ROLE',
    rightHolderName: agentName,
    rightHolderId: agentId,
  };
  let txnRights: IDesignRights = {
    decisionRight: false,
    disableParentRoleAccess: false,
    executionRight: true,
    informationRight: false,
    rightHolderType: 'ROLE',
    rightHolderName: agentName,
    rightHolderId: agentId,
  };
  /* istanbul ignore next */
  index.designTimeRights = index.designTimeRights?.length > 0 ? index.designTimeRights : [];
  /* istanbul ignore next */
  index.txnTimeRights = index.txnTimeRights?.length > 0 ? index.txnTimeRights : [];
  /* istanbul ignore next */
  let availableIndex = index.designTimeRights.findIndex(
    (designTimeRight: any) => designTimeRight.rightHolderName === designRights.rightHolderName
  );
  /* istanbul ignore next */
  if (availableIndex == -1 && !agentName?.includes('$')) {
    /* istanbul ignore next */
    index?.designTimeRights?.push(designRights);
    /* istanbul ignore next */
    index?.txnTimeRights?.push(txnRights);
  }
  /* istanbul ignore next */
  if (appendAgentName && !index?.description?.includes(event?.name)) {
    /* istanbul ignore next */
    index.description = event?.name + ' ' + index?.description;
    /* istanbul ignore next */
    // this.cuSentence = index?.description;
  }
  /* istanbul ignore next */
  gsiData?.solutionLogic?.forEach((element: any) => {
    /* istanbul ignore next */
    if (element?.referencedChangeUnit == index?.referencedChangeUnit) {
      /* istanbul ignore next */
      element.designTimeRights = index?.designTimeRights;
      /* istanbul ignore next */
      element.txnTimeRights = index?.txnTimeRights;
    }
  });
  return { index: index, gsiData: gsiData };
}
export function fillRights(res: any, index: any) {
  if (res?.extraction.CU_list?.[0]?.agent.length === 0) {
    index.designTimeRights = [];
    index.txnTimeRights = [];
  } else {
    if (index?.designTimeRights?.length > 0) {
      for (let dtRights = 0; dtRights < index.designTimeRights.length; dtRights++) {
        let dtRightsIndex = res?.extraction.CU_list?.[0]?.agent.findIndex((agent: any) => {
          return agent.name === index.designTimeRights[dtRights].rightHolderName;
        });
        if (dtRightsIndex === -1) {
          index.designTimeRights.splice(dtRights, 1);
          dtRights--;
        }
      }
    }
    if (index?.txnTimeRights?.length > 0) {
      for (let ttRights = 0; ttRights < index.txnTimeRights.length; ttRights++) {
        let dtRightsIndex = res?.extraction.CU_list?.[0]?.agent.findIndex((agent: any) => {
          return agent.name === index.txnTimeRights[ttRights].rightHolderName;
        });
        if (dtRightsIndex === -1) {
          index.txnTimeRights.splice(ttRights, 1);
          ttRights--;
        }
      }
    }
  }
  return index;
}
export function getSchedulerDetails(attrData: any) {
  let data = {
    incrementValue: '',
    day: '',
    time: '',
    interval: '',
    recurrenceExpression: '',
    startDate: '',
    endDate: '',
  };
  /*istanbul ignore next*/
  if (attrData && attrData?.properties) {
    /*istanbul ignore next*/
    data.incrementValue = attrData?.properties?.incrementValue;
    /*istanbul ignore next*/
    data.day = attrData?.properties?.day;
    /*istanbul ignore next*/
    data.time = attrData?.properties?.time;
    /*istanbul ignore next*/
    data.interval = attrData?.properties?.interval;
    /*istanbul ignore next*/
    data.recurrenceExpression = attrData?.properties?.recurrenceExpression;
    /*istanbul ignore next*/
    data.startDate = attrData?.properties?.startDate;
    /*istanbul ignore next*/
    data.endDate = attrData?.properties?.endDate;
  }
  return data;
}

export function displayValue(label: any, dateFormatType?: string, timeFormatType?: string, labelType?: string,uiElement?:string) {
  if (!dateFormatType) {
    dateFormatType = 'MM/dd/yyyy';
  }
  if (!timeFormatType) {
    timeFormatType = 'HH:mm:ss';
  }
  let value: any;
  if (label) {
    if (Array.isArray(label)) {
      value = [...label];
      value = value.flat();
      value.forEach((element: string, index: number) => {
        if (!isNaN(Date.parse(element)) && isISODate(element)) {
          if ((uiElement === 'date' || uiElement === 'currentDate') || formatDate(element, 'HH:mm:ss', 'en-US') === '00:00:00' || element?.includes('00:00:00')) {
            element = formatDate(element, `${dateFormatType}`, 'en-US');
          } else {
            element = formatDate(element, `${dateFormatType} ${timeFormatType}`, 'en-US');
          }
          value[index] = element;
        }
        if (Array.isArray(element)) {
          element = displayValue(element, dateFormatType, timeFormatType, labelType);
          value[index] = element;
        }
      });
    } else if (typeof label == 'string') {
      // value = Object.assign('', label);
      if (!isNaN(Date.parse(label)) && isISODate(label)) {
        if (formatDate(label, 'HH:mm:ss', 'en-US') === '00:00:00' || label?.includes('00:00:00')) {
          value = formatDate(label, `${dateFormatType}`, 'en-US');
        } else {
          value = formatDate(label, `${dateFormatType} ${timeFormatType}`, 'en-US');
        }
      } else {
        value = label;
      }
    }
    if (IsJsonString(label)) {
      if (Array.isArray(label)) {
        value = label[0];
      } else if (typeof label === 'object') {
        value = JSON.stringify(JSON.parse(label));
      } else {
        value = label;
      }
      let dateArr = JSON.parse(value);
      /* istanbul ignore next */
      if (
        Array.isArray(dateArr) &&
        dateArr?.length === 2 &&
        !isNaN(Date.parse(dateArr?.[0])) &&
        dateArr?.[0]?.includes('T') &&
        dateArr?.[0]?.includes('Z') &&
        !isNaN(Date.parse(dateArr?.[1])) &&
        dateArr?.[1]?.includes('T') &&
        dateArr?.[1]?.includes('Z')
      ) {
        value = formatDate(dateArr[0], 'MM/dd/yyyy', 'en-US') + ' - ' + formatDate(dateArr[1], 'MM/dd/yyyy', 'en-US');
      }
    }
    if (labelType) {
      value = labelType + ' ' + value;
    }
  }
  return value;
}

export function displayUSAMobileFormat(value: any) {
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  /* istanbul ignore else */
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return value;
}
export function displayMexicoMobileFormat(value: any) {
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  /* istanbul ignore else */
  if (match) {
    return match[1] + ' ' + match[2] + ' ' + match[3];
  }
  return value;
}

export function IsJsonString(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function genericvoiceToTextInit(voiceToText: any) {
  voiceToText.interimResults = true;
  voiceToText.lang = 'en-US';
  voiceToText.addEventListener('result', (e: any) => {
    const transcript = Array.from(e.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join(' ');

    return transcript;
  });
  //return words;
}

export function startVoiceToText(voiceToText: any, voiceToTextFlag = false) {
  voiceToText.start();

  voiceToText.addEventListener('end', (condition: any) => {
    if (voiceToTextFlag) {
      voiceToText.stop();
    }
    // else {
    //   voiceToText.start();
    // }
  });
}

// Return display value if UI element is date range
export function returnAttrDisplayValue(value: any) {
  let dispalyValue = '';
  if (value && IsJsonString(value)) {
    dispalyValue =
      formatDate(JSON.parse(value)[0], 'MM/dd/yyyy', 'en-US') +
      ' - ' +
      formatDate(JSON.parse(value)[1], 'MM/dd/yyyy', 'en-US');
  }
  return dispalyValue;
}

export function validateEmptySlotItem(cu: any) {
  /* istanbul ignore next */
  if (cu?.slotItemProperties != undefined || cu?.slotItemProperties != null) {
    Object?.keys(cu?.slotItemProperties).forEach((slot: any) => {
      cu?.slotItemProperties[slot]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.forEach(
        (txnEnt: any) => {
          txnEnt?.txnNslAttribute?.forEach((txnAttr: any, attrIndex: any) => {
            if (Object?.keys(txnAttr).length === 0) {
              txnEnt?.txnNslAttribute.splice(attrIndex, 1);
            }
          });
        }
      );
    });
  }
  return cu;
}

export function ValidateMemberShipCriteriaEvent(cu: any) {
  /* istanbul ignore next */
  if (cu?.eventCUList?.length > 0) {
    cu?.eventCUList?.forEach((event: any) => {
      if (event?.referenceEntityInfo?.membershipCriterias?.length > 0) {
        event.referenceEntityInfo.membershipCriterias = event?.referenceEntityInfo?.membershipCriterias?.filter(
          (memberShip: any) => memberShip != null
        );
      }
    });
  }
  return cu;
}
export function isTemplate(rCu: any) {
  /* istanbul ignore next */
  if (rCu?.name?.includes('NSL_') || rCu?.isReserved) {
    /* istanbul ignore next */
    if (rCu?.name?.includes('NSL_Template') || rCu?.isTemplate || rCu?.reservedCUType?.includes('NSL_Template')) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

//This method is to remove the data type of an attribute in query sentence before saving the CU or GSI
export function removeDataType(querySentence: any) {
  Object?.entries(querySentence).forEach((layer: any) => {
    if (Array.isArray(layer[1])) {
      layer[1].forEach((token: any) => {
        /* istanbul ignore next */
        if (token?.data_type) {
          delete token.data_type;
        }
      });
    }
  });
  return querySentence;
}

export function displayCUWithCondition(conditionDisplayAttr: any) {
  let displayCondition = '';
  for (let value of conditionDisplayAttr) {
    /* istanbul ignore next */
    value.targetAttribute =
      typeof value?.targetAttribut == 'string' ? value?.targetAttribute?.trim() : value?.targetAttribute;
    value.targetAttribute = value.targetAttribute ? value.targetAttribute : value.targetAttributeInput;
    displayCondition =
      displayCondition +
      ' ' +
      value?.sourceAttribute +
      ' ' +
      value?.operator +
      ' ' +
      value?.targetAttribute +
      ' ' +
      value?.predicate +
      ' ';
  }
  return displayCondition;
}
export function emptyObject(obj: any) {
  return obj && Object?.keys(obj)?.length === 0;
}

export function stripHtmlToString(htmlString: string) {
  return htmlString.replace(/<[^>]+>/g, '');
}


export function isISODate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && d.toISOString() === str;
}

export function getCUNode(cu: any) {
  if (cu) {
    /* istanbul  ignore next*/
    return {
      id: cu?.index,
      label: 'CU',
      data: {
        ...cu,
        cuType: cu.cuType,
        dsdRefId: cu?.referencedChangeUnit,
        dsdId: cu?.id,
        layers: cu?.layers,
        cuLayers: cu?.layers,
        name: cu?.name,
        color: 'red',
        nextTriggerSet: cu?.nextTriggerSet,
        index: cu?.index,
      },
    };
  }
}
export function getEstimatedTime(changeUnit: any) {
  let time = changeUnit.estimatedTime;
  let formattedTime: any = time.replace('PT', '').replace('H', ':').replace('M', ':').replace('S', '');
  formattedTime = formattedTime.split(':');
  let hours: any = formattedTime[0];
  let minutes: any = formattedTime[1];
  let seconds: any = parseInt(formattedTime[2]);
  let estimatedTime = new Date(null, null, null, hours, minutes, seconds);
  return estimatedTime;
}
export function getselectedestimatedTime(changeUnit: any, date: any) {
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  let seconds: any = date.getSeconds();
  /* istanbul ignore next */
  if (hours || minutes || seconds) {
    seconds = seconds.toFixed(3);
    changeUnit.estimatedTime = `PT${hours}H${minutes}M${seconds}S`;
  } else {
    /* istanbul ignore next */
    changeUnit.estimatedTime = null;
  }
  return changeUnit;
}
export function upperVsLowerBetData(
  temp?: any,
  upperBetProperties?: any,
  UiValidatorDropdown?: any,
  lowerBetProperties?: any,
  iseditAttr?: boolean
) {
  lowerBetProperties.constraints = [];
  // if (temp?.length == 0 || temp == undefined) {
  //   temp = upperBetProperties?.constraints;
  //   upperBetProperties?.constraints?.forEach((constraint: any) => {
  //     constraint.modified = false;
  //   });
  // }
  if (temp?.length > 0) {
    temp?.forEach((validator: any) => {
      validator.modified = true;
    });
  }
  upperBetProperties?.constraints?.forEach((value: any) => {
    value.modified = true;
  });

  lowerBetProperties = formatValidators(temp, UiValidatorDropdown, iseditAttr, lowerBetProperties);
  return lowerBetProperties;
}
export function formatValidators(temp: any, UiValidatorDropdown: any, iseditAttr: any, lowerBetProperties: any) {
  temp?.forEach((value: any) => {
    /* istanbul ignore next */
    const option = UiValidatorDropdown?.constraints?.find((val: any) => {
      return val.name == value.name;
    });
    /* istanbul ignore next */
    const obj = {
      name: value.name,
      optionSelected: false,
      displayName: option?.displayName ? option.displayName : value.name,
      type: value?.type,
      modified: value?.modified ? value?.modified : false,
    };
    obj['properties'] = [];
    /* istanbul ignore next */
    if (value.properties && Object.keys(value.properties)?.length) {
      let editConstraintsArrKeys = Object.keys(value.properties);
      let editConstraintsArrValues = Object.values(value.properties);
      /* istanbul ignore next */
      editConstraintsArrKeys?.forEach((val, i) => {
        obj['properties'].push({
          name: val,
          message: editConstraintsArrValues[i],
        });
      });
    }
    /* istanbul ignore else */
    if (iseditAttr) {
      /*istanbul ignore next */
      lowerBetProperties.constraints.push(obj);
      lowerBetProperties.constraints.filter((el: any) => {
        for (var i = 0; el.properties.length > i; i++) {
          /* istanbul ignore next */
          if (el.properties[i].name === 'type') {
            el.selectType = el.properties[i].message;
            el.properties.splice(i, 1);
          }
        }
        /* istanbul ignore else */
        if (UiValidatorDropdown) {
          /* istanbul ignore next */
          UiValidatorDropdown.constraints = UiValidatorDropdown?.constraints?.filter((el1: any) => {
            return el1.name !== el.name;
          });
        }
      });
    }
  });
  return lowerBetProperties;
}
export function sentenceTagUpdate(data: any, label_map?: any) {
  /* istanbul ignore next */
  if (data?.length > 0) {
    label_map = swapKeyValue(label_map);
    data?.forEach((tags: any) => {
      if (typeof tags?.tag === 'number') {
        tags.tag = label_map[tags?.tag];
      }
    });
    return data;
  }
}
export function swapKeyValue(json: any) {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
}

export function generateARId(gsiId: any) {
  let crypto: Crypto = window.crypto;
  let array = new Uint32Array(1);
  let randomNumber = crypto.getRandomValues(array)[0];
  randomNumber = Number('0.' + randomNumber);
  return Math.floor(randomNumber * 10000 + 1) + gsiId;
}
export function updateArId(gsiData: any) {
  let arID: any;
  /* istanbul ignore next */
  gsiData?.solutionLogic?.forEach((cu: any) => {
    if (cu?.uiProperties && !emptyObject(cu?.uiProperties) && cu?.uiProperties?.ar_id) {
      arID = cu?.uiProperties?.ar_id;
    }
  });
  return arID;
}

export function convertJsonToCsv(object: any[]) {
  var str = '';
  for (var i = 0; i < object.length; i++) {
    var line = '';
    for (var index in object[i]) {
      if (line != '') line += ',';
      line += object[i][index];
    }
    str += line + '\r';
  }
  return str;
}

export function nestedEntityPayloadS(entity: any, id?: any) {
  let dataToSend = {};
  /* istanbul ignore next */
  dataToSend[entity?.name] = {
    tf_id: '',
    dsd_id: id,
    type: 'nested',
    attribute_dict: {},
  };
  /* istanbul ignore next */
  entity?.nslAttributes?.forEach((attr: any) => {
    /* istanbul ignore next */
    if (attr?.generalEntity) {
      let payload = nestedEntityPayloadS(attr?.generalEntity, attr?.dsdId);
      let key = Object.keys(payload)[0];
      dataToSend[entity?.name].attribute_dict[key] = payload[key];
    } else {
      /* istanbul ignore next */
      dataToSend[entity?.name].attribute_dict[attr?.name] = {
        name: attr?.name,
        dsd_id: attr?.dsdId ? attr?.dsdId : '',
        tf_id: '',
      };
    }
  });
  return dataToSend;
}

//validating the default values for diaplaying
export function validateImg(res: any) {
  /*istanbul ignore next*/
  if (res?.result?.attributes?.length != undefined) {
    res?.result?.attributes?.forEach((attr: any) => {
      if (attr?.uiElement?.uiElement == 'image' || attr?.uiElement?.uiElement == 'video') {
        attr.defaultValue = JSON.parse(attr?.defaultValue);
        if (attr.defaultValue?.length > 0) {
          attr?.defaultValue?.map((attr: any) => {
            return JSON.parse(attr);
          });
        }
      }
    });
  }
  return res;
}
export function setDefaultColorWithVariant(color: string) {
  /* istanbul ignore next */
  const htmlTag = document?.getElementsByTagName('html')[0] as any;
  htmlTag.style.setProperty('--defaultColorPrimary', hexToRGBA(color, 1), 'important');
  htmlTag.style.setProperty('--defaultColorPrimary10', hexToRGBA(color, 0.9), 'important');
  htmlTag.style.setProperty('--defaultColorPrimary20', hexToRGBA(color, 0.8), 'important');
  htmlTag.style.setProperty('--defaultColorPrimary60', hexToRGBA(color, 0.4), 'important');
  htmlTag.style.setProperty('--defaultColorPrimary90', hexToRGBA(color, 0.1), 'important');
  htmlTag.style.setProperty('--defaultColorPrimary95', hexToRGBA(color, 0.05), 'important');
  htmlTag.style.setProperty('--Primary', hexToRGBA(color, 1), 'important');
  htmlTag.style.setProperty('--Primary10', hexToRGBA(color, 0.9), 'important');
  htmlTag.style.setProperty('--Primary20', hexToRGBA(color, 0.8), 'important');
  htmlTag.style.setProperty('--Primary60', hexToRGBA(color, 0.4), 'important');
  htmlTag.style.setProperty('--Primary90', hexToRGBA(color, 0.1), 'important');
  htmlTag.style.setProperty('--Primary95', hexToRGBA(color, 0.05), 'important');
}

export function hexToRGBA(hex: string, opacity: number) {
  /* istanbul ignore next */
  if (!hex || hex?.includes('rgba')) return '';
  /* istanbul ignore next */
  return (
    'rgba(' +
    (hex = hex?.replace('#', ''))
      ?.match(new RegExp('(.{' + hex?.length / 3 + '})', 'g'))
      ?.map(function (l) {
        return parseInt(hex?.length % 2 ? l + l : l, 16);
      })
      ?.concat(isFinite(opacity) ? opacity : 1)
      ?.join(',') +
    ')'
  );
}

export function getCurrentEventDataArray(eventCUList: any, attr: any) {
  let currentEventDataArray: any = [];
  /*istanbul ignore next*/
  if (eventCUList && eventCUList?.length > 0) {
    /*istanbul ignore next*/
    eventCUList.forEach((event: any) => {
      /*istanbul ignore next*/
      if(event?.slotContextualId) {
        /*istanbul ignore next*/
        if (
          event?.changeDriverId === attr?.attr_id &&
          event?.eventType === 'ON_SELECT' &&
          Number(event?.slotContextualId?.split('.')?.[1]?.split('SL')?.[1]) === attr?.slotNumber
        ) {
          currentEventDataArray.push(event);
        }
      } else {
        /*istanbul ignore next*/
        if (event?.changeDriverId === attr?.attr_id && event?.eventType === 'ON_SELECT') {
          currentEventDataArray.push(event);
        }
      }
    });
  }
  return currentEventDataArray;
}

export function getEventData(attrData: any) {
  /* istanbul ignore next */
  const data = {
    attributeId: attrData?.attr_id,
    isTableConfig: true,
    attrName: attrData?.label,
    eventType: 'ON_SELECT',
    entityName: attrData?.entityName,
    entityId: attrData?.entityId,
    slotNumber: attrData?.slotNumber,
    isInfo: attrData?.isInfo,
    isMulti: attrData?.isMultiEntity,
    recordId: attrData?.recordId,
    attribute_type: attrData?.inputType,
    attribute_displayName: attrData?.attr_displayName,
  };
  return data;
}

export function getMenuIconUrlArray() {
  let iconArr: any = menuIconUrlArray;
  /* istanbul ignore next */
  if(iconArr?.default) iconArr = iconArr?.default;
  /* istanbul ignore next */
  return iconArr ? Object.values(iconArr) : [];
}

export function isMissingAttr(entityList: any) {
  let res = true;
  /* istanbul ignore next */
  entityList?.forEach((entity: any) => {
    entity?.nslAttributes?.forEach((attr: any) => {
      /* istanbul ignore next */
      if (attr?.name == '$missing') res = false;
    });
  });
  return res;
}

export function findFont(apiName: string): any {
  if (apiName?.toString()?.includes('.ttf')) return apiName;
  let fontTypes = fonts?.fonts;
  let selectedFont: Font | any;
  /* istanbul ignore next */
  fontTypes?.forEach((element: any) => {
    element?.fonts?.forEach((font: Font) => {
      /* istanbul ignore next */
      if (font?.apiName === apiName) {
        selectedFont = font;
      }
    });
  });
  return selectedFont;
}

export function opGsisGen(integrationFacade: ManageIntegrationsFacadeService, router: Router) {
  if (localStorage.getItem('onBoarding') === 'true') {
    integrationFacade.update_add_adapters_variables('adapterType', null);
    /*istanbul ignore next*/
    // router.navigateByUrl(`onboard/adapters`);
  } else {
    router.navigateByUrl(`admin/transactions`);
  }
}

export function updateGen(
  integration: any,
  dsdId: any,
  integrationFacade: ManageIntegrationsFacadeService,
  loader: LoaderService,
  router: Router,
  alertService: AlertService
) {
  loader.show();
  integrationFacade.updateadapterv2(integration, dsdId).subscribe(
    (res: any) => {
      loader.hide();
      alertService.showToaster(res.message, '', 'success');
      integrationFacade.setupdateValue({});
      opGsisGen(integrationFacade, router);
    },
    (error: any) => {
      loader.hide();
      /* istanbul ignore next */
      alertService.showToaster(error?.error?.message ? error?.error?.message : error?.error, '', 'error');
    }
  );
}

export function createGen(
  integration: any,
  integrationFacade: ManageIntegrationsFacadeService,
  loader: LoaderService,
  router: Router,
  alertService: AlertService
) {
  loader.show();
  integrationFacade.addAdapterv2(integration).subscribe(
    (res: any) => {
      loader.hide();
      alertService.showToaster(res.message, '', 'success');
      opGsisGen(integrationFacade, router);
    },
    (error: any) => {
      loader.hide();
      /* istanbul ignore next */
      alertService.showToaster(error?.error?.message ? error?.error?.message : error?.error, '', 'error');
    }
  );
}

export function typewriterEffect(
  text: string,
  divObject: HTMLElement,
  i: number = 0,
  displayText: string = '',
  isTag: boolean = false
): void {
  displayText = text.slice(0, ++i);
  if (text === displayText) return;
  divObject.innerHTML = displayText;
  let char = displayText.slice(-1);
  if (char === '<') isTag = true;
  if (char === '>') isTag = false;
  if (isTag) typewriterEffect(text, divObject, i, displayText, isTag);
  else
    setTimeout(() => {
      typewriterEffect(text, divObject, i, displayText, isTag);
    }, 50);
}

export function addChangeUnitInCuList(parentcuIndex: any, originalCUList: any, changeUnitData: any) {
  // will generated the cu index based on the conditions
  let maxIndex: number = 1;
  /* istanbul ignore next*/
  if (originalCUList?.length > 0) {
    maxIndex = originalCUList[originalCUList?.length - 1]?.index + 1;
  }
  /* istanbul ignore next*/
  let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.index === parentcuIndex);
  /* istanbul ignore next*/
  if (findIndexMatchedCU !== -1 && originalCUList?.[findIndexMatchedCU]?.nextTriggerSet) {
    /* istanbul ignore next*/
    originalCUList?.[findIndexMatchedCU]?.nextTriggerSet?.push({
      isParallel: changeUnitData?.isParallel ? true : false,
      nextCUName: changeUnitData?.name,
      index: maxIndex,
    });
  }
  originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
  return originalCUList;
}

export function prepareKanbanBoardData(
  attr: any,
  entName: any,
  kanbanDataResponse: any,
  widgetPosition: number,
  isTxn: boolean
) {
  let currentAttr: string;
  /*istanbul ignore else */
  if (!isTxn) currentAttr = attr;
  else currentAttr = entName + '.' + attr;
  let bucketTitles = [] as any;
  kanbanDataResponse?.forEach((ele: any) => {
    if (!bucketTitles.find((title: string) => ele[currentAttr] === title)) bucketTitles.push(ele[currentAttr]);
  });
  let bucketValues = {};
  bucketTitles?.forEach((title: any) => {
    bucketValues = { ...bucketValues, [title]: [] };
  });
  kanbanDataResponse?.forEach((ele: any) => {
    let tempObj = {};

    for (const [key, val] of Object.entries(ele)) {
      tempObj = { ...tempObj, [splitAttr(key)]: val };
    }
    if (tempObj[attr]) delete tempObj[attr];
    bucketValues[ele[currentAttr]].push(tempObj);
  });
  return { bucketTitles: bucketTitles, bucketValues: bucketValues };
}

export function getFilterTableData(data: any, widget: any, isTxn: any) {
  let query: any = {};
  /*istanbul ignore else */
  if (data.isFilter) {
    let filterQuery: any = {};
    if (data.columnsList.length > 1) {
      filterQuery = {
        searchQueryDepth: 0,
        operationType: 'OR',
        queries: [] as any,
      };
      /*istanbul ignore next */
      data.columnsList?.forEach((ele: any, index: number) => {
        filterQuery.queries.push({
          searchQueryDepth: 0,
          fieldName: ele,
          searchOperatorType: data.searchOperatorType[index],
          values: data.filterNames[index],
        });
      });
    } else {
      filterQuery = {
        fieldName: data.columnsList[0],
        searchOperatorType: data.searchOperatorType[0],
        values: data.filterNames[0],
      };
    }
    query = {
      filters: [
        {
          gsiMasterId: '',
          referrenceCUfilters: [
            {
              referrenceCUMasterId: '',
              inputOutputMapping: {},
              searchQuery: filterQuery,
            },
          ],
        },
      ],
    };
    /*istanbul ignore next */
    query.filters[0].gsiMasterId = widget.requests[0]?.filters[0].gsiMasterId;
    /*istanbul ignore next */
    query.filters[0].referrenceCUfilters[0].referrenceCUMasterId =
      widget.requests[0]?.filters[0].referrenceCUfilters[0].referrenceCUMasterId;
    /*istanbul ignore next */
    query.filters[0].referrenceCUfilters[0].inputOutputMapping =
      widget.requests[0]?.filters[0].referrenceCUfilters[0].inputOutputMapping;
  } else {
    if (isTxn) {
      const payloadDataTable = JSON.parse(widget?.additional?.tableData);
      /*istanbul ignore next */
      query = {
        filters: Object.assign([], payloadDataTable?.filters),
        limit: 10,
        offset: 0,
        sort: [
          {
            fieldName: data.columnName,
            order: data.sortProperty,
          },
        ],
      };
    } else {
      /*istanbul ignore next */
      query = {
        entityName: widget.geRequests[0]?.entityName,
        outputEntityName: widget.geRequests[0]?.entityName,
        inputOutputMapping: widget.geRequests[0]?.inputOutputMapping,
        sort: [
          {
            fieldName: widget.geRequests[0]?.entityName + '.' + data.columnName,
            order: data.sortProperty,
          },
        ],
      };
    }
  }

  return { query: query };
}

let attributeData: any = null;
export function setAttributeValue() {
  attributeData = null;
}

/* to get attribute Value for dependent expression [input-decimal-date-&-dateTime-components] */
export function dependentAttributeValue(dependentExpression: any, formData: any, valueSelect?: any, field?: any) {
  /* istanbul ignore next*/
  if(valueSelect && !dependentExpression){
    dependentExpression = field?.entityName?.split('$')?.[0] + '.' + field?.name;
  }
  if (typeof dependentExpression == 'string' && dependentExpression?.includes('.')) {
    /* istanbul ignore next*/
    let dE = dependentExpression?.trim()?.split('.');
    /* istanbul ignore next*/
    if (attributeData) {
      return;
    }
    /* istanbul ignore next*/
    if (formData) {
      Object.keys(formData)?.forEach((key: any) => {
        /* istanbul ignore next*/
        if (attributeData) {
          return;
        }
        /* istanbul ignore next*/
        // let entityName = key?.split('$')?.[0];
        /* istanbul ignore next*/
        let [entityName, tempKey] = dE[0] !== field?.entityName?.split('$')?.[0] ? [dE[0], key?.split('$')?.[0]] : [field?.entityName, key];
        if ( tempKey && tempKey == entityName) {
          let entity = formData?.[key];
          /* istanbul ignore next*/
          if (entity) {
            /* istanbul ignore next*/
            Object.keys(entity)?.forEach((attrKey: any) => {
              if (attributeData) {
                return;
              }
              /* istanbul ignore next*/
              let attrName = attrKey?.split('$')?.[0];
              /* istanbul ignore next*/
              let depAttr: boolean = false;
              /* istanbul ignore next*/
              if (
                field?.attribute['isTableConfig'] &&
                entity['tablecardAttrCount'] &&
                attrName == dE?.[1] &&
                dE[0] == field?.entityName?.split('$')?.[0]
              ) {
                depAttr =
                  Math.floor(Number(field.attribute?.['name']?.split('$')?.[1]) / entity['tablecardAttrCount']) ==
                  Math.floor(Number(attrKey?.split('$')?.[1]) / entity['tablecardAttrCount']);
              } else {
                depAttr = attrName == dE?.[1];
              }
              if (depAttr) {
                if (valueSelect) {
                  attributeData = entity?.[attrKey];
                } else {
                  let attrValue = entity?.[attrKey]?.[0];
                  /* istanbul ignore next*/
                  if (attrValue?.[attrKey]) {
                      /* istanbul ignore next*/
                     if((IsJsonString(attrValue?.attr_configuration) && JSON.parse(attrValue?.attr_configuration)?.isReadOnly)|| attrValue?.inputType=='label'){
                       attributeData = attrValue?.[attrKey]?.[0]?.[0];
                     }
                  else{
                    attributeData = attrValue?.[attrKey];
                  }
                  }
                }
              }
            });
          }
        } else if (formData?.[key]?.entType == 'entity') {
          /* istanbul ignore next*/
          dependentAttributeValue(dependentExpression, formData?.[key], valueSelect, field);
        }
      });
    }
  }
}

export function getAttributeValue() {
  return attributeData;
}

export function prepareTxnReportCustomDashboard(searchQuery: any, pageNumberReport: any, pageSizeReport: any) {
  const tempQuery = JSON.parse(JSON.stringify(searchQuery));
  /*istanbul ignore next */
  tempQuery?.filters?.forEach((gsi: any) => {
    /*istanbul ignore next */
    gsi?.cuDatasetRelationship?.forEach((cu: any) => {
      /*istanbul ignore next */
      cu?.entityDatasetRelationship?.forEach((ent: any) => {
        /*istanbul ignore next */
        ent?.attributeNames?.forEach((attr: any) => {
          (cu['referrenceCUMasterId'] = cu['referenceCUMasterId']),
            (cu['inputOutputMapping'] = {
              ...cu['inputOutputMapping'],
              [ent.entityName + '.' + attr]: 'Result.' + attr,
            });
        });
        cu['offset'] = pageNumberReport;
        cu['limit'] = pageSizeReport;
      });
      delete cu.entityDatasetRelationship;
      delete cu.referenceCUMasterId;
    });
    gsi['referrenceCUfilters'] = gsi.cuDatasetRelationship;
    delete gsi.cuDatasetRelationship;
  });
  return {
    filters: tempQuery?.filters,
  };
}

export function formatTxnReportPayload(
  gsiMasterId: any,
  referrenceCUMasterId: any,
  entity: any,
  attr: any,
  pageSizeReport: number,
  pageNumberReport: number,
  isTimeSeries: boolean,
  selectedInterval: string,
  countTXNStore?: any,
  openChartCheck?: boolean
) {
  let searchQueryFilter: any = {
    filters: [
      {
        gsiMasterId: gsiMasterId,
        referrenceCUfilters: [
          {
            referrenceCUMasterId: referrenceCUMasterId,
            inputOutputMapping: {
              [entity + '.' + attr]: 'Result.' + attr,
            },
            groupBy: [] as any,
            limit: pageSizeReport,
            offset: pageNumberReport,
          },
        ],
      },
    ],
  };
  /*istanbul ignore else */
  if (isTimeSeries) {
    searchQueryFilter.filters[0].referrenceCUfilters[0] = {
      ...searchQueryFilter.filters[0].referrenceCUfilters[0],
      groupBy: [
        {
          fieldName: 'aggUpdatedAt', // fixed
          type: 'DATE_HISTOGRAM', // fixed
          additional: {
            interval: selectedInterval,
          },
        },
      ],
    };
  } else {
    searchQueryFilter.filters[0].referrenceCUfilters[0] = {
      ...searchQueryFilter.filters[0].referrenceCUfilters[0],
      functions: [
        {
          fieldName: entity + '.' + attr,
          functionType: 'COUNT',
        },
      ],
      groupBy: [
        {
          fieldName: entity + '.' + attr,
        },
      ],
    };
    /*istanbul ignore else */
    if (openChartCheck) {
      searchQueryFilter.filters[0].referrenceCUfilters[0] = {
        ...searchQueryFilter.filters[0].referrenceCUfilters[0],
        searchQuery: {
          searchQueryDepth: 0,
          operationType: 'OR',
          queries: [] as any,
        },
      };

      for (const [key, val] of Object.entries(countTXNStore)) {
        /*istanbul ignore else */
        if (val['checked'] && entity + '.' + attr === key.split('--')[2]) {
          searchQueryFilter.filters[0].referrenceCUfilters[0].searchQuery.queries.push({
            searchQueryDepth: 1,
            fieldName: entity + '.' + attr,
            searchOperatorType: 'EQUALS',
            values: [key.split('--')[0]],
          });
        }
      }
    }
  }

  return searchQueryFilter;
}

export function generateSupersetURL(dashboard: any, userType: string, iframeId?: any) {
  let urlName = '';
  let envName = '';
  /* istanbul ignore next */
  const token = JSON.parse(localStorage.getItem('credentials'))?.token;
  const loginApiKey = localStorage.getItem('loginApiKey');
  const envType = localStorage.getItem('environmentType');
  const tenant = localStorage.getItem('TenantName');
  const urlLocation = window.location.host;
  /*istanbul ignore next */
  if (!urlLocation?.includes('localhost') && !urlLocation?.startsWith('10.')) {
    /*istanbul ignore next */
    const urlArray = urlLocation?.split('.');
    /*istanbul ignore next */
    const nslhubIndex = urlArray?.indexOf('nslhub');
    /*istanbul ignore else */
    if (nslhubIndex !== -1) {
      envName = urlArray[nslhubIndex - 1];
    } else {
      envName = urlLocation?.split('.')[1];
    }
  } else {
    /*istanbul ignore next */
    if (loginApiKey == 'preqa') {
      envName = 'preqa';
    } else if (loginApiKey == 'qa' || loginApiKey == 'qa3') {
      envName = 'qa3';
    } else if (loginApiKey == 'carnivalsc') {
      envName = 'carnivalsc';
    } else if (loginApiKey == 'b2ccarnivals') {
      envName = 'carnivalsb';
    } else if (loginApiKey == 'carnivals') {
      envName = 'carnivals';
    } else {
      envName = 'preqa';
    }
  }
  /*istanbul ignore next */
  let dashboardName = dashboard?.dashboardName;
  /*istanbul ignore next */
  let dashboardSupersetId = dashboard?.supersetId;

  /* istanbul ignore else*/
  if (envType === 'development') {
    urlName = `analytics-dev-${envName}`;
  } else {
    urlName = `analytics-${envName}`;
  }
  /* istanbul ignore else*/
  if (envName == 'production') envName = 'carnivalsb';
  let srcUrl = '';

  /* istanbul ignore else*/
  if (dashboardSupersetId)
    srcUrl = `https://${tenant}${dashboardSupersetId}.${urlName}.nslhub.com/login/?dashboard_name=${dashboardName}&env=${envName}&dashboard_id=${dashboardSupersetId}&userType=${userType}&jwtToken=${token}&iframeId=${iframeId}`;
  else
    srcUrl = `https://${tenant}${dashboardSupersetId}.${urlName}.nslhub.com/login/?dashboard_name=${dashboardName}&env=${envName}&userType=${userType}&jwtToken=${token}`;

  return srcUrl;
}

export function fillRightsPara(res: any, index: any) {
  if (res?.solutionLogic?.[0]?.agents.length === 0) {
    index.designTimeRights = [];
    index.txnTimeRights = [];
  } else {
    if (index?.designTimeRights?.length > 0) {
      for (let dtRights = 0; dtRights < index.designTimeRights.length; dtRights++) {
        let dtRightsIndex = res?.solutionLogic?.[0]?.agents.findIndex((agent: any) => {
          return agent.name === index.designTimeRights[dtRights].rightHolderName;
        });
        if (dtRightsIndex === -1) {
          index.designTimeRights.splice(dtRights, 1);
          dtRights--;
        }
      }
    }
    if (index?.txnTimeRights?.length > 0) {
      for (let ttRights = 0; ttRights < index.txnTimeRights.length; ttRights++) {
        let dtRightsIndex = res?.solutionLogic?.[0]?.agents.findIndex((agent: any) => {
          return agent.name === index.txnTimeRights[ttRights].rightHolderName;
        });
        if (dtRightsIndex === -1) {
          index.txnTimeRights.splice(ttRights, 1);
          ttRights--;
        }
      }
    }
  }
  return index;
}

export function assignLayer(foundCu: any, res: any) {
  /* istanbul ignore next*/
  let foundLayerIndex = foundCu?.layers?.findIndex((x: any) => x?.type === res?.layer);
  foundCu.sentenceTags = res.sentenceTags;
  /* istanbul ignore next */
  if (foundCu.layers?.length === 0 || foundLayerIndex === -1) {
    let physicalLayerEntity: any = [];
    res.result.forEach((entity: any) => {
      let entObj = JSON.parse(JSON.stringify(entity));
      physicalLayerEntity.push(entObj);
    });
    /* istanbul ignore next */
    if (physicalLayerEntity?.length > 0) {
      foundCu.layers.push({ type: res.layer, participatingItems: physicalLayerEntity });
    }
  } else if (foundLayerIndex !== -1) {
    /* istanbul ignore next*/
    res?.result?.forEach((entity: any) => {
      /* istanbul ignore next*/
      let foundEntityIndex = foundCu?.layers?.[foundLayerIndex]?.participatingItems?.findIndex(
        (x: any) => x?.id === entity?.id
      );
      let entObj = JSON.parse(JSON.stringify(entity));
      if (foundEntityIndex !== -1) {
        foundCu.layers[foundLayerIndex].participatingItems[foundEntityIndex] = entObj;
      } else {
        // let slotItem: any = { item: { DATA: entity, TYPE: 'GeneralEntity' } };
        foundCu.layers[foundLayerIndex]?.participatingItems.push(entObj);
      }
    });
  }
}

export function getCustomizedHeaderHeight(templateName: any) {
  let customizedHeaderHeight = '80px';

  /* istanbul ignore next */
  switch (templateName) {
    case HeaderCustomization.Regular_Doubled:
    case HeaderCustomization.Extended_Doubled:
    case HeaderCustomization.Extended_LongLogoDoubled:
      customizedHeaderHeight = '116px';
      break;
    case HeaderCustomization.Extended_HeightedLongLogoDoubled:
      customizedHeaderHeight = '156px';
      break;
    case HeaderCustomization.Extended_Top_Multiple_CTA_Buttons:
    case HeaderCustomization.Extended_Bottom_Multiple_CTA_Buttons:
      customizedHeaderHeight = '132px';
      break;
    case HeaderCustomization.Extended_Single_CTA_Button_BottomPanel:
      customizedHeaderHeight = '128px';
      break;
    case HeaderCustomization.Extended_Single_CTA_Button_CenteredPanel:
      customizedHeaderHeight = '198px';
      break;
    case HeaderCustomization.Extended_2_CTA_Buttons_ListPanel:
    case HeaderCustomization.Extended_2_CTA_Buttons_top_dropdownPanel:
      customizedHeaderHeight = '208px';
      break;
    case HeaderCustomization.Extended_2_CTA_Buttons_bottom_dropdownPanel:
      customizedHeaderHeight = '196px';
      break;
    case HeaderCustomization.Extended_2_CTA_Buttons_SmallPanel:
      customizedHeaderHeight = '104px';
      break;
    case HeaderCustomization.Extended_2_CTA_Buttons_LargePanel:
      customizedHeaderHeight = '172px';
      break;
    case HeaderCustomization.Extended_3_CTA_Buttons_SidePanel:
      customizedHeaderHeight = '100px';
      break;
    case HeaderCustomization.Extended_5_CTA_Buttons_SmallPanel:
      customizedHeaderHeight = '90px';
      break;
    case HeaderCustomization.Extended_Defy_CTA_Buttons_Template:
      customizedHeaderHeight = window.screen.width < 1366 && window.screen.width >= 992 ? '62px' : '72px';
      break;
  }

  return customizedHeaderHeight;
}

export function getParticipatingItems(cu: any, extraction: string, name: string) {
  let participatingItems: any = [];
  let demappedCu = JSON.parse(JSON.stringify(cu));
  demappedCu = changeUnitDeMapper(demappedCu);
  let layer = demappedCu?.layers?.find((layer: any) => layer?.type == extraction);
  if (layer && layer?.participatingItems?.length > 0) participatingItems = layer?.participatingItems;
  return { text: name, participatingItems: participatingItems };
}

/** ------------------------------------------- EQL Functions - START ------------------------------------------------------ */

export function UpdatedgsiData(res: any, gsiData: any) {
  const index = gsiData?.solutionLogic?.findIndex(
    (m: any) => m?.tfReferencedChangeUnit === res?.tfId && m?.index === res?.index
  );
  /* istanbul ignore next */
  if (index !== -1) {
    let dcd = gsiData?.solutionLogic?.[index]?.dcd;
    let currentCU = JSON.parse(JSON.stringify(res));
    currentCU.referencedChangeUnit = JSON.parse(JSON.stringify(res?.id));
    currentCU.tfReferencedChangeUnit = JSON.parse(JSON.stringify(res?.tfId));
    currentCU.id = gsiData?.solutionLogic?.[index]?.id;
    currentCU.tfId = gsiData?.solutionLogic?.[index]?.tfId;
    currentCU.isParallel = gsiData?.solutionLogic?.[index]?.isParallel;
    /* istanbul ignore next*/
    currentCU.designTimeRights = res?.designTimeRights ? JSON.parse(JSON.stringify(res?.designTimeRights)) : [];
    /* istanbul ignore next*/
    currentCU.txnTimeRights = res?.txnTimeRights ? JSON.parse(JSON.stringify(res?.txnTimeRights)) : [];
    /* istanbul ignore else*/
    if (res?.agents && res?.agents?.length > 0) currentCU.agents = res?.agents;
    currentCU.dcd = dcd;
    /* istanbul  ignore next*/
    if (gsiData?.solutionLogic?.[index]?.condition) {
      currentCU.condition = gsiData?.solutionLogic?.[index]?.condition;
      currentCU.conditionName = gsiData?.solutionLogic?.[index]?.conditionName;
    }
    currentCU.nextTriggerSet = gsiData?.solutionLogic?.[index]?.nextTriggerSet;
    gsiData.solutionLogic[index] = { ...currentCU };
  }
  return gsiData;
}

// Validate EQL query modal data
export function validatedModalData(validateQueryParams: any) {
  let isValidateModal = true;
  /* istanbul ignore next */
  validateQueryParams?.forEach((params: any) => {
    /* istanbul ignore next */
    params?.attrValue?.forEach((attrValue: any) => {
      /* istanbul ignore next */
      if (params?.operator === 'GEO_DISTANCE' || params?.operator === 'GEO_DISTANCE_EXCLUSION') {
        /* istanbul ignore next */
        if (!attrValue?.latitude || !attrValue?.longitude || !attrValue?.radius || !attrValue?.unit) {
          isValidateModal = false;
        }
      } else if (params?.attrUiElement === 'location' || params?.attrUiElement === 'MMILocation') {
        /* istanbul ignore next */
        if (!attrValue?.latitude || !attrValue?.longitude) {
          isValidateModal = false;
        }
      } else {
        /* istanbul ignore next */
        if (!attrValue?.value) {
          isValidateModal = false;
        }
      }
    });
  });
  return isValidateModal;
}
// check deleted entities from i/o array for EQL component
/* istanbul ignore next */
export function getDuplicateAttr(value: any, transEntityRecords: any, isAttrValue: boolean) {
  let isAvailable = true;
  /* istanbul ignore next */
  if (isAttrValue && value?.includes('PL.SL00')) {
    return isAvailable;
  }
  for (let j = 0; j < transEntityRecords.attributesList.length; j++) {
    if (transEntityRecords.attributesList[j] !== value) {
      isAvailable = false;
    } else {
      isAvailable = true;
      break;
    }
  }
  return isAvailable;
}

/* istanbul ignore next */
export function fillFilterBlock(txnNslAttribute: any, transEntityRecords: any, EQLVersion: any) {
  /* istanbul ignore next */
  transEntityRecords.filterData = [];
  let transEntityRecordsForFilter =
    txnNslAttribute?.[EQLVersion == '3.0' ? 3 : 2]?.txnGeneralEntity?.transEntityRecords;
  /* istanbul ignore next */
  let filterData: any;
  filterData = [];
  for (let i = 0; i < transEntityRecordsForFilter?.length; i++) {
    let txnNslAttributeForFilter = transEntityRecordsForFilter[i].txnNslAttribute;
    filterData.push({ type: '', value: '', filterAttributeValue: '' });
    for (let j = 0; j < txnNslAttributeForFilter.length; j++) {
      /* istanbul ignore next */
      if (txnNslAttributeForFilter[j].values) {
        // Filter Type
        /* istanbul ignore next */
        if (j === 0 && txnNslAttributeForFilter[j]?.values?.length > 0) {
          /* istanbul ignore next */
          filterData[i].type = txnNslAttributeForFilter?.[j]?.values?.[0];
        }
        // Filter Value
        /* istanbul ignore next */
        if (j === 1 && txnNslAttributeForFilter[j]?.values?.length > 0) {
          transEntityRecords.showFilter = true;
          /* istanbul ignore next */
          filterData[i].value = txnNslAttributeForFilter?.[j]?.values?.[0];
        }
        // Filter Attribute Value
        /* istanbul ignore next */
        if (j === 2 && txnNslAttributeForFilter[j]?.values?.length > 0) {
          transEntityRecords.showFilter = true;
          /* istanbul ignore next */
          filterData[i].filterAttributeValue = txnNslAttributeForFilter?.[j]?.values?.[0];
        }
      }
    }
  }

  /* istanbul ignore next */
  if (filterData?.length > 0) {
    let flagCnt = 3;
    let tempFilterData: any;
    tempFilterData = [];
    filterData?.forEach((filter: any) => {
      /* istanbul ignore next */
      if (flagCnt > 0) {
        tempFilterData.push(filter);
        flagCnt -= 1;
      }
      /* istanbul ignore next */
      if (flagCnt === 0) {
        transEntityRecords.filterData.push(tempFilterData);
        tempFilterData = [];
        flagCnt = 3;
      }
    });
    /* istanbul ignore next */
    if (tempFilterData.length > 0) {
      transEntityRecords.filterData.push(tempFilterData);
      tempFilterData = [];
    }
  }

  /* istanbul ignore next */
  transEntityRecords = checkGroupByFilter(transEntityRecords);

  //check limit is free value or not
  /* istanbul ignore next */
  transEntityRecords?.filterData?.[0]?.forEach((filters: any) => {
    /* istanbul ignore next */
    if (filters?.type === 'LIMIT') {
      let notaNumber = isNaN(filters.filterAttributeValue);
      /* istanbul ignore next */
      if (notaNumber == false) {
        filters.enterFreeValue = true;
      } else {
        filters.enterFreeValue = false;
      }
    }
  });
  return transEntityRecords;
}

export function checkGroupByFilter(transEntityRecords: any) {
  let groupByData: any = [];
  groupByData = getIsGroupAvailable(transEntityRecords);
  /* istanbul ignore next */
  if (groupByData?.requiredAttributesForGroupFilter > 0) {
    if (groupByData?.requiredAttributesForGroupFilter < groupByData?.availableGroupByFilters) {
      let tempFilterData: any = [];
      /* istanbul ignore next */
      transEntityRecords?.attributesListForGroupFilter.forEach((groupfilters: any, i: any) => {
        transEntityRecords?.filterData.forEach((filterData: any, j: any) => {
          filterData.forEach((filter: any, k: any) => {
            if (filter.type === 'GROUP') {
              if (filter.filterAttributeValue === groupfilters) {
                tempFilterData.push(filter);
              }
            }
          });
        });
      });
      transEntityRecords.filterData = [];
      transEntityRecords.filterData[0] = tempFilterData;
    }
  } else {
    transEntityRecords.filterData = [];
    transEntityRecords.showFilter = false;
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function getIsGroupAvailable(transEntityRecords: any) {
  let groupByData = { allAttributesForAllentities: 0, requiredAttributesForGroupFilter: 0, availableGroupByFilters: 0 };
  groupByData.allAttributesForAllentities = transEntityRecords?.attributesListForQueryLayer?.length;

  transEntityRecords.attributesListForGroupFilter = [];
  let allAttr: any = [];
  let availableAttr = 0;
  transEntityRecords?.queryInputOutputArray?.forEach((attr: any) => {
    if (attr?.sourceAttribute?.length > 0) {
      availableAttr++;
      if (attr?.function?.length > 0) {
        allAttr.push(attr.sourceAttribute);
      }
    }
  });
  /* istanbul ignore next */
  let extraAttrFound = transEntityRecords?.attributesListForQueryLayer?.filter((x: any) => !allAttr?.includes(x));

  if (availableAttr === allAttr?.length) {
    extraAttrFound = transEntityRecords?.attributesListForQueryLayer;
    transEntityRecords.attributesListForGroupFilter = allAttr;
    groupByData.requiredAttributesForGroupFilter = allAttr?.length;
    transEntityRecords.requiredGroupByFilters = 0;
  } else {
    transEntityRecords.attributesListForGroupFilter = extraAttrFound;
    groupByData.requiredAttributesForGroupFilter = transEntityRecords?.attributesListForGroupFilter?.length;
    transEntityRecords.requiredGroupByFilters = availableAttr;
  }

  groupByData.availableGroupByFilters = 0;
  transEntityRecords?.filterData.forEach((filterData: any, i: any) => {
    filterData?.forEach((filter: any, j: any) => {
      if (filter.type === 'GROUP') {
        groupByData.availableGroupByFilters++;
      }
    });
  });
  return groupByData;
}

/* istanbul ignore next */
export function addAllEntities(transEntityRecords: any, entityFacadeService: any, entityList: any) {
  /* istanbul ignore next */
  entityList?.forEach((entityName: string, i: any) => {
    /* istanbul ignore next */
    if (entityName) {
      entityFacadeService.getEntityDataForEQLQueryLayer(entityName);
    } else {
      /* istanbul ignore next */
      entityList?.splice(i, 1);
    }
  });

  // transEntityRecords.attributesListForGroupFilter = transEntityRecords.attributesListForQueryLayer;
  return transEntityRecords;
}

/* istanbul ignore next */
export function getTargetAttrForQueryParams(
  transEntityRecords: any,
  validateQueryParams: any,
  eqlCuDetails: any,
  EQLType?: any
) {
  let layer = findLayer(eqlCuDetails, 'physical');

  transEntityRecords?.queryData?.forEach((query: any) => {
    getNestedTargetAttrForQueryParamsInQueryData(query, validateQueryParams, layer, EQLType);
  });

  /* istanbul ignore next */
  transEntityRecords?.nslFormula?.forEach((formula: any) => {
    formula?.forEach((formula: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(formula, validateQueryParams, EQLType);
    });
  });

  /* istanbul ignore next */
  transEntityRecords?.nslFormulaForCondition?.forEach((formula: any) => {
    formula?.forEach((formula: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(formula, validateQueryParams, EQLType);
    });
  });

  /* istanbul ignore next */
  transEntityRecords?.nslCase?.forEach((nslCase: any) => {
    nslCase?.forEach((cases: any) => {
      getNestedTargetAttrForQueryParamsInNslCase(cases, validateQueryParams, EQLType);
    });
  });

  /* istanbul ignore next */
  transEntityRecords?.queryData?.forEach((condition: any) => {
    if (condition.nslCaseForCondition) {
      condition.nslCaseForCondition?.forEach((cases: any) => {
        getNestedTargetAttrForQueryParamsInNslCase(cases, validateQueryParams, EQLType);
      });
    }
  });

  /* istanbul ignore next */
  transEntityRecords?.nslFunction?.forEach((nslfunction: any) => {
    /* istanbul ignore next */
    if (nslfunction) {
      /* istanbul ignore next */
      getNestedTargetAttrForQueryParamsInNslFunction(nslfunction[0], validateQueryParams, layer, EQLType);
    }
  });

  transEntityRecords?.nslFunctionForCondition?.forEach((nslfunction: any) => {
    /* istanbul ignore next */
    if (nslfunction) {
      /* istanbul ignore next */
      getNestedTargetAttrForQueryParamsInNslFunction(nslfunction[0], validateQueryParams, layer, EQLType);
    }
  });

  /* istanbul ignore next */
  transEntityRecords?.groupbyAggregate?.[0]?.GroupbyAttribute?.forEach((attr: any) => {
    /* istanbul ignore next */
    getNestedTargetAttrForQueryParamsInGroupByAggregate(attr, validateQueryParams);
  });

  /* istanbul ignore next */
  transEntityRecords?.groupbyAggregate?.[0]?.queryData?.forEach((query: any) => {
    /* istanbul ignore next */
    if (query) {
      /* istanbul ignore next */
      getNestedTargetAttrForQueryParamsInQueryData(query, validateQueryParams, layer, EQLType);
    }
  });

  transEntityRecords?.groupbyAggregate?.[0]?.nslFunction?.forEach((nslfunction: any) => {
    /* istanbul ignore next */
    if (nslfunction) {
      /* istanbul ignore next */
      getNestedTargetAttrForQueryParamsInNslFunction(nslfunction[0], validateQueryParams, layer, EQLType);
    }
  });

  transEntityRecords?.filterData?.forEach((query: any) => {
    getNestedTargetAttrForQueryParamsInFilterData(query, validateQueryParams, layer, EQLType);
  });
  return validateQueryParams;
}

export function getNestedTargetAttrForQueryParamsInFilterData(
  filter: any,
  validateQueryParams: any,
  layer: any,
  EQLType?: any
) {
  filter?.forEach((filterValues: any) => {
    if (!filterValues.enterFreeValue && filterValues?.filterAttributeValue?.includes('PL.SL')) {
      let param = createValidateQueryParam(layer, EQLType, filterValues?.filterAttributeValue, '');
      /* istanbul ignore next */
      validateQueryParams?.push(param);
    }
  });
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInQueryData(
  query: any,
  validateQueryParams: any,
  layer: any,
  EQLType?: any
) {
  /* istanbul ignore next */
  if (!query?.length) {
    /* istanbul ignore next */
    if (query?.attributeValue?.includes('PL.SL')) {
      /* istanbul ignore next */
      let param = createValidateQueryParam(layer, EQLType, query?.attributeValue, query?.queryOperator);
      /* istanbul ignore next */
      validateQueryParams?.push(param);
    }
  } else {
    query.forEach((subQuery: any) => {
      getNestedTargetAttrForQueryParamsInQueryData(subQuery, validateQueryParams, layer, EQLType);
    });
  }
  return validateQueryParams;
}

export function createValidateQueryParam(layer: any, EQLType: any, attributeValue: any, queryOperator: any) {
  /* istanbul ignore next */
  let tempSplitted = attributeValue?.split('.', 4);
  /* istanbul ignore next */
  let tempAttrName = tempSplitted?.[2] + '.' + tempSplitted?.[3];
  /* istanbul ignore next */
  let tempAttributeNameForDataType = tempSplitted?.[3];
  /* istanbul ignore next */
  let tempAttributeObjectForDataType: any = undefined;
  /* istanbul ignore next */
  let startIndex = EQLType === 'EQL-Report' || EQLType === 'NSL_Template_PaginatedEQLSearch' ? 2 : 1;
  let endIndex = layer?.participatingItems.length;
  loop1: while (startIndex < endIndex) {
    tempAttributeObjectForDataType = layer?.participatingItems?.[startIndex]?.nslAttributes?.find(
      (attr: any) => attr?.name === tempAttributeNameForDataType
    );
    startIndex++;
    if (tempAttributeObjectForDataType != undefined) {
      break loop1;
    }
  }
  /* istanbul ignore next */
  let tempAttrType = tempAttributeObjectForDataType?.attributeType?.type;
  /* istanbul ignore next */
  let tempAttrUiElement = tempAttributeObjectForDataType?.attributeType?.uiElement?.uiElement;
  /* istanbul ignore next */
  let tempAttrDataType = tempAttributeObjectForDataType?.attributeType?.uiElement?.dataType;
  let isMultiValue = false;
  /* istanbul ignore next */
  if (tempAttrType == 'list') {
    /* istanbul ignore next */
    tempAttrType = tempAttributeObjectForDataType?.attributeType?.nestedNslDataTypeProperties?.itemType?.type
      ? tempAttributeObjectForDataType.attributeType.nestedNslDataTypeProperties.itemType.type
      : tempAttributeObjectForDataType?.attributeType?.nestedNslDataTypeProperties?.item_type?.type
      ? tempAttributeObjectForDataType.attributeType.nestedNslDataTypeProperties.item_type.type
      : tempAttributeObjectForDataType.attributeType.uiElement.dataType;
  }
  /* istanbul ignore next */
  if (
    queryOperator == 'BETWEEN' ||
    queryOperator == 'IN' ||
    queryOperator == 'GEO_DISTANCE' ||
    queryOperator == 'GEO_DISTANCE_EXCLUSION'
  ) {
    isMultiValue = true;
  }
  /* istanbul ignore next */
  let param: any = {
    attr: attributeValue,
    attrName: tempAttrName,
    attrValue: isMultiValue ? [{ value: '' }, { value: '' }] : ([{ value: '' }] as any),
    attrType: tempAttrType,
    isMultiValue: isMultiValue,
    attrUiElement: tempAttrUiElement,
    attrDataType: tempAttrDataType,
    operator: queryOperator,
  };

  /* istanbul ignore next */
  if (queryOperator === 'GEO_DISTANCE' || queryOperator === 'GEO_DISTANCE_EXCLUSION') {
    param.attrValue = [{ longitude: '', latitude: '', radius: '', unit: '' }];
  } else if (tempAttrUiElement === 'location' || tempAttrUiElement === 'MMILocation') {
    param.attrValue = [{ longitude: '', latitude: '' }];
  }
  return param;
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInNslCase(query: any, validateQueryParams: any, EQLType: any) {
  /* istanbul ignore next */
  if (
    query?.nslQueryCondition?.attributeName?.charAt(0) !== "'" &&
    query?.nslQueryCondition?.attributeName?.charAt(query?.nslQueryCondition?.attributeName?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslQueryCondition?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.nslQueryCondition.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      /* istanbul ignore next */
      validateQueryParams?.push({
        attr: query.nslQueryCondition.attributeName,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
        operator: query.queryOperator,
      });
    }
  }

  /* istanbul ignore next */
  if (
    query?.nslQueryCondition?.attributeValue?.charAt(0) !== "'" &&
    query?.nslQueryCondition?.attributeValue?.charAt(query?.nslQueryCondition?.attributeValue?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslQueryCondition?.attributeValue?.includes('PL.SL')) {
      let tempSplitted = query.nslQueryCondition.attributeValue.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      /* istanbul ignore next */
      validateQueryParams?.push({
        attr: query.nslQueryCondition.attributeValue,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
        operator: query?.queryOperator,
      });
    }
  }

  /* istanbul ignore next */
  if (
    query?.nslThen?.attributeName?.charAt(0) !== "'" &&
    query?.nslThen?.attributeName?.charAt(query?.nslThen?.attributeName?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslThen?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.nslThen.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      /* istanbul ignore next */
      validateQueryParams?.push({
        attr: query.nslThen.attributeName,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
        operator: query?.queryOperator,
      });
    }
  }

  /* istanbul ignore next */
  if (
    query?.nslElse?.attributeName?.charAt(0) !== "'" &&
    query?.nslElse?.attributeName?.charAt(query?.nslElse?.attributeName?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslElse?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.nslElse.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      /* istanbul ignore next */
      validateQueryParams?.push({
        attr: query.nslElse.attributeName,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
        operator: query?.queryOperator,
      });
    }
  }

  if (query?.nslQueryCondition?.nslFormula?.length > 0) {
    query?.nslQueryCondition?.nslFormula?.forEach((formula: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(formula, validateQueryParams, EQLType);
    });
  }

  if (query?.nslThen?.nslFormula?.length > 0) {
    query?.nslThen?.nslFormula?.forEach((formula: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(formula, validateQueryParams, EQLType);
    });
  }

  if (query?.nslElse?.nslFormula?.length > 0) {
    query?.nslElse?.nslFormula?.forEach((formula: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(formula, validateQueryParams, EQLType);
    });
  }
  return validateQueryParams;
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInNslFormula(query: any, validateQueryParams: any, EQLType: any) {
  /* istanbul ignore next */
  if (!query?.length) {
    /* istanbul ignore next */
    if (query?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      // let tempAttributeNameForDataType = tempSplitted[3];
      // tempAttributeNameForDataType = layer?.participatingItems?.[
      //   EQLType === 'EQL-Report' ? 2 : 1
      // ]?.nslAttributes.find((attr: any) => attr.name === tempAttributeNameForDataType);
      // let tempAttrType = tempAttributeNameForDataType.attributeType.type;
      let isMultiValue = false;
      // if (tempAttrType == 'list') {
      //   tempAttrType = tempAttributeNameForDataType.attributeType.nestedNslDataTypeProperties.item_type.type;
      // }
      /* istanbul ignore next */
      if (
        query?.queryOperator == 'BETWEEN' ||
        query?.queryOperator == 'IN' ||
        query?.queryOperator == 'GEO_DISTANCE' ||
        query?.queryOperator == 'GEO_DISTANCE_EXCLUSION'
      ) {
        isMultiValue = true;
      }
      /* istanbul ignore next */
      validateQueryParams?.push({
        attr: query.attributeName,
        attrName: tempAttrName,
        attrValue: isMultiValue
          ? query?.queryOperator !== 'GEO_DISTANCE' && query?.queryOperator !== 'GEO_DISTANCE_EXCLUSION'
            ? [{ value: '' }, { value: '' }]
            : [{ longitude: '' }, { latitude: '' }, { radius: '' }, { unit: '' }]
          : [{ value: '' }],
        isMultiValue: isMultiValue,
        operator: query?.queryOperator,
      });
    }
  } else {
    query.forEach((subQuery: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(subQuery, validateQueryParams, EQLType);
    });
  }
  return validateQueryParams;
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInNslFunction(
  nslfunction: any,
  validateQueryParams: any,
  layer: any,
  EQLType: any
) {
  /* istanbul ignore next */
  nslfunction?.functionParams?.forEach((funcParam: any) => {
    /* istanbul ignore next */
    if (funcParam?.functionAttribute?.includes('PL.SL')) {
      /* istanbul ignore next */
      let param = createValidateQueryParam(layer, EQLType, funcParam?.functionAttribute, '');
      /* istanbul ignore next */
      validateQueryParams?.push(param);
    }
    /* istanbul ignore next */
    if (funcParam?.NslFunction && funcParam?.NslFunction?.length > 0) {
      /* istanbul ignore next */
      getNestedTargetAttrForQueryParamsInNslFunction(funcParam?.NslFunction[0], validateQueryParams, layer, EQLType);
    }
  });
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInGroupByAggregate(attr: any, validateQueryParams: any) {
  /* istanbul ignore next */
  if (attr?.includes('PL.SL')) {
    let tempSplitted = attr.split('.', 4);
    let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
    let isMultiValue = false;
    /* istanbul ignore next */
    validateQueryParams?.push({
      attr: attr,
      attrName: tempAttrName,
      attrValue: [{ value: '' }],
      isMultiValue: isMultiValue,
      operator: '',
    });
  }
  return validateQueryParams;
}

/* istanbul ignore next */
export function getSelectedOperation(transEntityRecords: any) {
  /* istanbul ignore next */
  transEntityRecords?.selectedOperation?.forEach((operation: any, i: any) => {
    /* istanbul ignore next */
    if (operation === 'formula' || operation === 'case' || operation === 'function') {
      transEntityRecords.isSelectedOperation[i] = true;
    } else {
      transEntityRecords.isSelectedOperation[i] = false;
    }
  });
  return transEntityRecords;
}
/* istanbul ignore next */
export function getDuplicatesEql(transEntityRecords: any) {
  /* istanbul ignore next */
  for (let i = 0; i < transEntityRecords?.queryData?.length; i++) {
    let isAttrNameAvailable = true;
    let tempAttributeName = transEntityRecords.queryData[i].attributeName;
    isAttrNameAvailable = getDuplicateAttr(tempAttributeName, transEntityRecords, false);
    /* istanbul ignore next */
    if (!isAttrNameAvailable) {
      transEntityRecords.queryData[i].attributeName = '';
    }

    let isAttrValueAvailable = true;
    let tempAttributeValue = transEntityRecords.queryData[i].attributeValue;
    isAttrValueAvailable = getDuplicateAttr(tempAttributeValue, transEntityRecords, true);
    /* istanbul ignore next */
    if (!isAttrValueAvailable) {
      transEntityRecords.queryData[i].attributeValue = '';
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function pushAllAttrInDDFromEntities(
  transEntityRecords: any,
  itemAttributeValueDropDown: any,
  attributeValueDropDown: any
) {
  if (transEntityRecords?.attributesListForQueryLayer && transEntityRecords?.attributesListForQueryLayer?.length > 0) {
    for (let j = 0; j < transEntityRecords.attributesListForQueryLayer.length; j++) {
      let item = {
        display: transEntityRecords.attributesListForQueryLayer[j],
        displayName: transEntityRecords.attributesListForQueryLayer[j],
        item: transEntityRecords.attributesListForQueryLayer[j],
      };
      // attributeValueDropDownForJoin.push(item);
      /* istanbul ignore next */
      if (!itemAttributeValueDropDown?.includes(item?.item) && transEntityRecords?.entityList?.length > 1) {
        attributeValueDropDown.push(item);
      }
    }
  }

  attributeValueDropDown = attributeValueDropDown.filter(
    (arr: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.item === arr.item && t.display === arr.display)
  );
  return attributeValueDropDown;
}

export function getLayerId(slotIndex: any) {
  let indexPadding = getIndexPadding(slotIndex + 1);
  let layerShortCut = 'PL.SL' + indexPadding;
  return layerShortCut;
}

export function getIndexPadding(index: number) {
  let input = index + '';
  while (input.length < 3) {
    input = '0' + input;
  }
  return input;
}

export function populateNestedAttributeValue(
  attr: any,
  entityName: string,
  existingValueDropDown: any,
  reservedCuType: any,
  layerShortCut: any
) {
  let item: any;
  let itemAttributeValueDropDown: any = [];
  existingValueDropDown?.forEach((value: any) => {
    itemAttributeValueDropDown.push(value.item);
  });
  if (!attr?.generalEntity) {
    item = {
      display: entityName + '.' + attr.name,
      displayName: entityName + '.' + attr.name,
      item: layerShortCut + '.' + entityName + '.' + attr.name,
    };
    if (!itemAttributeValueDropDown.includes(item.name)) {
      existingValueDropDown?.push(item);
    }
    /* istanbul ignore else */
  } else if (attr?.generalEntity) {
    attr?.generalEntity?.nslAttributes?.forEach((attribute: any) => {
      existingValueDropDown.concat(
        populateNestedAttributeValue(
          attribute,
          entityName + '.' + attr.name,
          existingValueDropDown,
          reservedCuType,
          layerShortCut
        )
      );
    });
  }
  return existingValueDropDown;
}

/* istanbul ignore next */
export function loadNSLFormula(tempVarForNslFormula: any, eqlLabel: any) {
  let tempNSLTransRecord: any = [];

  /* istanbul ignore next */
  if (
    !tempVarForNslFormula?.txnNslAttribute[3]?.txnGeneralEntity ||
    !tempVarForNslFormula?.txnNslAttribute[3]?.txnGeneralEntity?.transEntityRecords
  ) {
    let formulae = {
      nslFunction: '',
      attributeName: '',
      arithmeticOperator: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
    };
    tempNSLTransRecord = formulae;

    if (tempVarForNslFormula?.txnNslAttribute?.[0]?.txnGeneralEntity) {
      // tempNSLTransRecord.nslFunction = tempVarForNslFormula?.txnNslAttribute?.[0]?.txnGeneralEntity;
      tempNSLTransRecord.nslFunction = fillNSLFunction(tempVarForNslFormula.txnNslAttribute[0], eqlLabel);
    } else {
      tempNSLTransRecord.nslFunction = '';
    }

    /* istanbul ignore next */
    tempNSLTransRecord.attributeName = tempVarForNslFormula?.txnNslAttribute?.[1]?.values?.[0];

    /* istanbul ignore next */
    if (tempVarForNslFormula?.txnNslAttribute?.[2]?.txnGeneralEntity) {
      /* istanbul ignore next */
      let tempIOFormula =
        tempVarForNslFormula?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[0]
          .txnGeneralEntity.transEntityRecords?.[0].txnNslAttribute;
      tempNSLTransRecord.nslQuery = { sourceAttribute: '', targetAttribute: '', function: '' };
      /* istanbul ignore next */
      tempNSLTransRecord.nslQuery.sourceAttribute = tempIOFormula?.[0]?.values?.[0];
      /* istanbul ignore next */
      tempNSLTransRecord.nslQuery.targetAttribute = tempIOFormula?.[1]?.values?.[0];
      /* istanbul ignore next */
      tempNSLTransRecord.nslQuery.function = tempIOFormula?.[2]?.values?.[0];
    } else {
      /* istanbul ignore next */
      tempNSLTransRecord.nslQuery = tempVarForNslFormula?.txnNslAttribute?.[2]?.values?.[0];
    }
    /* istanbul ignore next */
    tempNSLTransRecord.nslFormula = tempVarForNslFormula?.txnNslAttribute[3]?.txnGeneralEntity?.transEntityRecords;
    /* istanbul ignore next */
    tempNSLTransRecord.nslCase = tempVarForNslFormula?.txnNslAttribute[4]?.values?.[0];
    /* istanbul ignore next */
    tempNSLTransRecord.arithmeticOperator = tempVarForNslFormula?.txnNslAttribute[5]?.values?.[0];
  } else {
    /* istanbul ignore next */
    for (let i = 0; i < tempVarForNslFormula.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.length; i++) {
      /* istanbul ignore next */
      tempNSLTransRecord.push(
        loadNSLFormula(tempVarForNslFormula?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[i], eqlLabel)
      );
      /* istanbul ignore next */
      if (tempVarForNslFormula.txnNslAttribute?.[5]?.values?.[0]) {
        tempNSLTransRecord.arithmeticOperator = '';
        /* istanbul ignore next */
        tempNSLTransRecord.arithmeticOperator = tempVarForNslFormula.txnNslAttribute?.[5]?.values?.[0];
      }
    }
  }
  return tempNSLTransRecord;
}

/* istanbul ignore next */
export function addPLSLAttributesToQueryLayer(transEntityRecords: any, attributeValueDropDown: any) {
  /* istanbul ignore next */
  if (transEntityRecords?.attributesListForQueryLayer) {
    /* istanbul ignore next */
    let tempPLattributes: any = [];
    attributeValueDropDown?.forEach((attr: any) => {
      tempPLattributes.push(attr.item);
    });
    transEntityRecords?.attributesListForQueryLayer?.push(...tempPLattributes);
    /* istanbul ignore next */
    transEntityRecords.attributesListForQueryLayer = transEntityRecords.attributesListForQueryLayer?.filter(function (
      elem: any,
      index: any,
      self: string | any[]
    ) {
      return index === self.indexOf(elem);
    });
  }
  return transEntityRecords;
}

export function addSourceToAttributeDropDown(queryFormula: any, attributeValueDropdown: any) {
  if (queryFormula?.attributesList) {
    let tempPLattributes: any = [];
    attributeValueDropdown?.forEach((attr: any) => {
      tempPLattributes.push(attr.item);
    });
    queryFormula?.attributesList?.push(...tempPLattributes);
    queryFormula.attributesList = queryFormula?.attributesList?.filter(function (
      elem: any,
      index: any,
      self: string | any[]
    ) {
      return index === self.indexOf(elem);
    });
    if (!attributeValueDropdown) {
      attributeValueDropdown = [];
    }
    for (let i = 0; i < queryFormula?.attributesList?.length; i++) {
      let item = {
        display: queryFormula?.attributesList?.[i],
        displayName: queryFormula?.attributesList?.[i],
        item: queryFormula?.attributesList?.[i],
      };
      attributeValueDropdown.push(item);
    }
    attributeValueDropdown = attributeValueDropdown.filter(
      (value: any, index: any, array: any) => index == array.findIndex((item: any) => item.item == value.item)
    );
  }
  return { queryFormula: queryFormula, attributeValueDropdown: attributeValueDropdown };
}

/* istanbul ignore next */
export function loadNSLCase(tempVarForNslCase: any, eqlLabel: any) {
  let tempNSLCaseTransRecord: any = [];
  let NslCase = {
    nslQueryCondition: {
      nslFunction: '',
      nslFormula: '',
      attributeName: '',
      queryOperator: '',
      attributeValue: '',
      nslQuery: '',
      nslQueryCondition: '',
      nslCase: '',
      predicate: '',
    },
    nslThen: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', nslCase: '' },
    nslElse: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', nslCase: '' },
  };
  tempNSLCaseTransRecord = NslCase;
  /* istanbul ignore next */
  if (
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[0]
      ?.txnGeneralEntity
  ) {
    // tempNSLCaseTransRecord.nslQueryCondition.nslFunction =
    // tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute[0]?.values?.[0];
    /* istanbul ignore next */
    tempNSLCaseTransRecord.nslQueryCondition.nslFunction = fillNSLFunction(
      tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[0],
      eqlLabel
    );
  } else {
    tempNSLCaseTransRecord.nslQueryCondition.nslFunction = '';
  }
  /* istanbul ignore next */
  if (
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[0]
      ?.txnGeneralEntity
  ) {
    // tempNSLCaseTransRecord.nslQueryCondition.nslFunction =
    // tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute[0]?.values?.[0];
    tempNSLCaseTransRecord.nslQueryCondition.nslFunction = fillNSLFunction(
      tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute[0],
      eqlLabel
    );
  } else {
    tempNSLCaseTransRecord.nslQueryCondition.nslFunction = '';
  }
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.attributeName =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[2]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.queryOperator =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[3]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.attributeValue =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[4]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.nslQuery =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[5]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.nslQueryCondition =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[6]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.nslCase =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[7]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslQueryCondition.predicate =
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[8]?.values?.[0];
  /* istanbul ignore next */
  if (
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[1]
      ?.txnGeneralEntity
  ) {
    tempNSLCaseTransRecord.nslQueryCondition.nslFormula = [];
    /* istanbul ignore next */
    tempVarForNslCase?.txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[1]?.txnGeneralEntity?.transEntityRecords?.forEach(
      (element: any) => {
        tempNSLCaseTransRecord.nslQueryCondition.nslFormula.push(loadNSLFormula(element, eqlLabel));
      }
    );
  } else {
    tempNSLCaseTransRecord.nslQueryCondition.nslFormula =
      tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[1]?.values?.[0];
  }
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslThen.functionName =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[0]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslThen.attributeName =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[1]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslThen.nslQuery =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[2]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslThen.nslFormula =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[3]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslThen.nslCase =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[4]?.values?.[0];
  /* istanbul ignore next */
  if (
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[3]
      ?.txnGeneralEntity
  ) {
    tempNSLCaseTransRecord.nslThen.nslFormula = [];
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[3]?.txnGeneralEntity.transEntityRecords.forEach(
      (element: any) => {
        tempNSLCaseTransRecord.nslThen.nslFormula.push(loadNSLFormula(element, eqlLabel));
      }
    );
  } else {
    tempNSLCaseTransRecord.nslThen.nslFormula =
      tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[3]?.values?.[0];
  }
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslElse.functionName =
    tempVarForNslCase?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[0]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslElse.attributeName =
    tempVarForNslCase?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[1]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslElse.nslQuery =
    tempVarForNslCase?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[2]?.values?.[0];
  /* istanbul ignore next */
  tempNSLCaseTransRecord.nslElse.nslCase =
    tempVarForNslCase?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[4]?.values?.[0];

  if (
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[3]
      ?.txnGeneralEntity
  ) {
    tempNSLCaseTransRecord.nslElse.nslFormula = [];
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[3]?.txnGeneralEntity.transEntityRecords.forEach(
      (element: any) => {
        tempNSLCaseTransRecord.nslElse.nslFormula.push(loadNSLFormula(element, eqlLabel));
      }
    );
  } else {
    tempNSLCaseTransRecord.nslElse.nslFormula =
      tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[3]?.values?.[0];
  }
  return tempNSLCaseTransRecord;
}

/* istanbul ignore next */
export function resetAttributeNames(nslCase: any) {
  if (
    nslCase?.nslQueryCondition?.attributeValue?.charAt(0) == "'" &&
    nslCase?.nslQueryCondition?.attributeValue?.charAt(nslCase?.nslQueryCondition?.attributeValue?.length - 1) == "'"
  ) {
    let attributeValue = '';
    /* istanbul ignore next */
    for (let k = 1; k < nslCase?.nslQueryCondition?.attributeValue?.length - 1; k++) {
      attributeValue += nslCase?.nslQueryCondition?.attributeValue[k];
    }
    nslCase.nslQueryCondition.attributeValue = attributeValue;
    nslCase.nslQueryCondition.enterFreeValue = true;
  } else {
    nslCase.nslQueryCondition.enterFreeValue = false;
  }

  if (
    nslCase?.nslThen?.attributeName?.charAt(0) == "'" &&
    nslCase?.nslThen?.attributeName?.charAt(nslCase?.nslThen?.attributeName?.length - 1) == "'"
  ) {
    let attributeName = '';
    /* istanbul ignore next */
    for (let k = 1; k < nslCase?.nslThen?.attributeName?.length - 1; k++) {
      attributeName += nslCase?.nslThen?.attributeName[k];
    }
    nslCase.nslThen.attributeName = attributeName;
    nslCase.nslThen.enterFreeValue = true;
  } else {
    nslCase.nslThen.enterFreeValue = false;
  }

  if (
    nslCase?.nslElse?.attributeName?.charAt(0) == "'" &&
    nslCase?.nslElse?.attributeName?.charAt(nslCase?.nslElse?.attributeName?.length - 1) == "'"
  ) {
    let attributeName = '';
    /* istanbul ignore next */
    for (let k = 1; k < nslCase?.nslElse?.attributeName?.length - 1; k++) {
      attributeName += nslCase?.nslElse?.attributeName[k];
    }
    nslCase.nslElse.attributeName = attributeName;
    nslCase.nslElse.enterFreeValue = true;
  } else {
    nslCase.nslElse.enterFreeValue = false;
  }
  return nslCase;
}
/* istanbul ignore next */
export function checkEQLExist(reservedCuIndex: number, tempGsiObj: any, type: string, mode?: string) {
  var txnNslAttribute;
  if (mode === 'grammar') {
    if (reservedCuIndex >= 0 && tempGsiObj?.solutionLogic?.[reservedCuIndex]?.slotItemProperties) {
      /* istanbul ignore next */
      if (type === 'EQL') {
        txnNslAttribute =
          tempGsiObj?.solutionLogic?.[reservedCuIndex]?.slotItemProperties?.PL_SL001?.propertyData?.CONSTANT
            ?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
      }
      /* istanbul ignore next */
      if (type === 'EQLReport') {
        txnNslAttribute =
          tempGsiObj?.solutionLogic?.[reservedCuIndex]?.slotItemProperties?.PL_SL002?.propertyData?.CONSTANT
            ?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
      }

      if (type === 'blockChain') {
        txnNslAttribute =
          tempGsiObj?.solutionLogic?.[reservedCuIndex]?.slotItemProperties?.PL_SL002?.propertyData?.CONSTANT
            ?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
      }
      if (type === 'transact') {
        txnNslAttribute =
          tempGsiObj?.solutionLogic?.[reservedCuIndex]?.slotItemProperties?.PL_SL003?.propertyData?.CONSTANT
            ?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
      }
    }
  } else {
    /* istanbul ignore next */
    if (reservedCuIndex >= 0 && tempGsiObj?.cuList?.[reservedCuIndex]?.slotItemProperties) {
      if (type === 'EQL') {
        txnNslAttribute =
          tempGsiObj?.cuList?.[reservedCuIndex]?.slotItemProperties?.PL_SL001?.propertyData?.CONSTANT?.changeDriverData
            ?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
      }
      /* istanbul ignore next */
      if (type === 'EQLReport') {
        txnNslAttribute =
          tempGsiObj?.cuList?.[reservedCuIndex]?.slotItemProperties?.PL_SL002?.propertyData?.CONSTANT?.changeDriverData
            ?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
      }
    }
  }
  return txnNslAttribute;
}

/* istanbul ignore next */
export function fillGroupByAggregateData(txnNslAttribute: any, transEntityRecords: any, query1: any, eqlLabel: any) {
  transEntityRecords.groupbyAggregate = [
    {
      GroupbyAttribute: '',
      queryData: [],
      attributesList: [],
      attributesListForGroupFilter: [],
      attributesListForQueryLayer: [],
    },
  ];
  let transEntityRecordsForGroup = txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords;
  if (transEntityRecordsForGroup?.length > 0) {
    transEntityRecordsForGroup.forEach((trans: any, i: any) => {
      transEntityRecords.groupbyAggregate[0].GroupbyAttribute = trans?.txnNslAttribute?.[0]?.values;
      var tempConditionForGroup = trans?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords;
      transEntityRecords.groupbyAggregate[0].queryData = [];
      tempConditionForGroup?.forEach((condition: any, j: any) => {
        transEntityRecords.groupbyAggregate?.[0].queryData.push(JSON.parse(JSON.stringify(query1)));
        transEntityRecords.groupbyAggregate[0].queryData[j].attributeName =
          condition?.txnNslAttribute?.[2]?.values?.[0];
        transEntityRecords.groupbyAggregate[0].queryData[j].queryOperator =
          condition?.txnNslAttribute?.[3]?.values?.[0];
        transEntityRecords.groupbyAggregate[0].queryData[j].attributeValue =
          condition?.txnNslAttribute?.[4]?.values?.[0];
        transEntityRecords.groupbyAggregate[0].queryData[j].predicate = condition?.txnNslAttribute?.[8]?.values?.[0];
        if (condition?.txnNslAttribute?.[0]?.txnGeneralEntity) {
          if (!transEntityRecords.groupbyAggregate?.[0].nslFunction) {
            transEntityRecords.groupbyAggregate[0].nslFunction = [];
          }
          transEntityRecords.groupbyAggregate[0].nslFunction[j] = fillNSLFunction(
            condition?.txnNslAttribute?.[0],
            eqlLabel
          );
          transEntityRecords.groupbyAggregate[0].queryData[j].attributeName = 'function';
        }
        // Set toggle for transEntityRecords based on Predicate
        if (transEntityRecords?.groupbyAggregate?.[0]?.queryData?.[j]?.predicate) {
          /* istanbul ignore next */
          if (transEntityRecords.groupbyAggregate?.[0]?.queryData[j].predicate === 'AND') {
            if (transEntityRecords.groupbyAggregate?.[0]?.queryData?.toggle) {
              transEntityRecords.groupbyAggregate?.[0]?.queryData.toggle.push(false);
            } else {
              transEntityRecords.groupbyAggregate[0].queryData.toggle = [];
              transEntityRecords.groupbyAggregate?.[0]?.queryData.toggle.push(false);
            }
          }
          /* istanbul ignore next */
          if (transEntityRecords?.groupbyAggregate?.[0]?.queryData?.[j]?.predicate === 'OR') {
            if (transEntityRecords.groupbyAggregate?.[0]?.queryData?.toggle) {
              transEntityRecords.groupbyAggregate?.[0]?.queryData.toggle.push(true);
            } else {
              transEntityRecords.groupbyAggregate[0].queryData.toggle = [];
              transEntityRecords.groupbyAggregate?.[0]?.queryData.toggle.push(true);
            }
          }
        } else {
          if (transEntityRecords.groupbyAggregate?.[0]?.queryData?.toggle) {
            transEntityRecords.groupbyAggregate?.[0]?.queryData.toggle.push(false);
          } else {
            transEntityRecords.groupbyAggregate[0].queryData.toggle = [];
            transEntityRecords.groupbyAggregate?.[0]?.queryData.toggle.push(false);
          }
        }
      });
    });
  }
  transEntityRecords.groupbyAggregate[0].attributesList = transEntityRecords.attributesList;
  transEntityRecords.groupbyAggregate[0].attributesListForGroupFilter = transEntityRecords.attributesListForGroupFilter;
  transEntityRecords.groupbyAggregate[0].attributesListForQueryLayer = transEntityRecords.attributesListForQueryLayer;

  if (transEntityRecords.groupbyAggregate[0].GroupbyAttribute == '') {
    delete transEntityRecords.groupbyAggregate;
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function fillConditionBlock(
  transEntityRecords: any,
  txnNslAttribute: any,
  level: number,
  query1: any,
  EQLVersion: any,
  eqlLabel: any
) {
  for (let j = 0; j < txnNslAttribute?.[1].txnGeneralEntity?.transEntityRecords?.length; j++) {
    transEntityRecords.queryData.push(JSON.parse(JSON.stringify(query1)));

    let tempVarForTransEntityRecords = txnNslAttribute?.[1]?.txnGeneralEntity?.transEntityRecords;

    // Attribute Name
    /* istanbul ignore next */
    transEntityRecords.queryData[j].attributeName =
      EQLVersion === '2.0' || EQLVersion === '3.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[1]?.txnGeneralEntity
          ? 'formula'
          : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[7]?.txnGeneralEntity
          ? 'case'
          : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.txnGeneralEntity
          ? 'function'
          : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[2]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.values?.[0];

    if (transEntityRecords?.attributesListForQueryLayer?.length > 0) {
    } else {
      transEntityRecords.attributesListForQueryLayer = [];
    }
    if (
      transEntityRecords.queryData[j].attributeName !== 'formula' &&
      transEntityRecords.queryData[j].attributeName !== 'case' &&
      transEntityRecords.queryData[j].attributeName !== 'function'
    ) {
      transEntityRecords.attributesListForQueryLayer.push(transEntityRecords.queryData[j].attributeName);
    }

    //NSL Formula in Condition
    let tempReturnValue = fillNSLFormulae(transEntityRecords, tempVarForTransEntityRecords, j, 'condition', eqlLabel);
    /* istanbul ignore next */
    transEntityRecords = tempReturnValue?.transEntityRecords;
    /* istanbul ignore next */
    tempVarForTransEntityRecords = tempReturnValue?.tempVarForTransEntityRecords;

    //NSL Case in Condition
    tempReturnValue = fillNSLCase(transEntityRecords, tempVarForTransEntityRecords, j, 'condition', eqlLabel);
    /* istanbul ignore next */
    transEntityRecords = tempReturnValue?.transEntityRecords;
    /* istanbul ignore next */
    tempVarForTransEntityRecords = tempReturnValue?.tempVarForTransEntityRecords;

    //NSL Function in Condition
    if (tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.txnGeneralEntity) {
      transEntityRecords.queryData[j].nslFunctionForCondition = [];
      transEntityRecords.queryData[j].nslFunctionForCondition = fillNSLFunction(
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute[0],
        eqlLabel
      );
    }

    // Query Operator
    /* istanbul ignore next */
    transEntityRecords.queryData[j].queryOperator =
      EQLVersion === '2.0' || EQLVersion === '3.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[3]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[1]?.values?.[0];

    // Attribute Value
    /* istanbul ignore next */
    transEntityRecords.queryData[j].attributeValue =
      EQLVersion === '2.0' || EQLVersion === '3.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[4]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[2].values?.[0];

    /* istanbul ignore next */
    let queryConditionIndex = EQLVersion === '2.0' || EQLVersion === '3.0' ? 5 : 3;
    // For SubQuery
    if (
      !tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[queryConditionIndex]?.txnGeneralEntity ||
      !tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[queryConditionIndex]?.txnGeneralEntity?.transEntityRecords
    ) {
      transEntityRecords.queryData[j].nslQuery = {};
    } else {
      transEntityRecords.queryData[j].nslQuery = this.loadtempObjData(
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[queryConditionIndex]?.txnGeneralEntity
          ?.transEntityRecords?.[0]?.txnNslAttribute,
        false,
        level + 1
      );
      transEntityRecords.queryData[j].nslQuery.showLibraryBtn = true;
    }

    // Query Condition
    /* istanbul ignore next */
    transEntityRecords.queryData[j].queryCondition =
      EQLVersion === '2.0' || EQLVersion === '3.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[6]?.txnGeneralEntity
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[4]?.txnGeneralEntity;

    // Predicate
    /* istanbul ignore next */
    transEntityRecords.queryData[j].predicate =
      EQLVersion === '2.0' || EQLVersion === '3.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[8]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[5]?.values?.[0];

    // Set toggle for transEntityRecords based on Predicate
    if (transEntityRecords?.queryData?.[j]?.predicate) {
      /* istanbul ignore next */
      if (transEntityRecords.queryData[j].predicate === 'AND') {
        transEntityRecords.queryData.toggle.push(false);
      }
      /* istanbul ignore next */
      if (transEntityRecords?.queryData?.[j]?.predicate === 'OR') {
        transEntityRecords.queryData.toggle.push(true);
      }
    } else {
      transEntityRecords.queryData.toggle.push(false);
    }

    // Query Condition for RuleSet Block
    if (transEntityRecords?.queryData[j]?.queryCondition) {
      let nslQueryData = transEntityRecords?.queryData?.[j]?.nslQuery;
      let queryConditionData = transEntityRecords?.queryData?.[j]?.queryCondition;
      // transEntityRecords.queryData[j] = [];
      /* istanbul ignore next */
      let ruleSetindex = EQLVersion == '2.0' || EQLVersion === '3.0' ? 6 : 4;
      transEntityRecords.queryData[j] = fetchRuleSetData(
        queryConditionData,
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[ruleSetindex]?.txnGeneralEntity?.transEntityRecords,
        0,
        query1,
        EQLVersion,
        eqlLabel
      );

      // For single subquery of whole RuleSet
      /* istanbul ignore next */
      if (!transEntityRecords.queryData?.[j]?.nslQuery) {
        transEntityRecords.queryData[j].nslQuery = {};
      }
      transEntityRecords.queryData[j].nslQuery = nslQueryData;

      transEntityRecords.queryData.isGroupQuery[j] = true;
    } else {
      transEntityRecords.queryData.isGroupQuery[j] = false;
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function fillInputOutputBlock(transEntityRecords: any, txnNslAttribute: any, EQLVersion: any, eqlLabel: any) {
  let tempVarForTransEntityRecords = txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords;
  for (let j = 0; j < txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.length; j++) {
    transEntityRecords.queryInputOutputArray.push({});

    //for Source Attribute at 0 location (NSL_formula Not Available)
    /* istanbul ignore next */
    if (EQLVersion === '1.0') {
      // Source Attribute
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].sourceAttribute =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.values?.[0];

      /* istanbul ignore next */
      transEntityRecords.attributesList.push(tempVarForTransEntityRecords?.[j].txnNslAttribute[0]?.values?.[0]);

      // Target Attribute
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].targetAttribute =
        tempVarForTransEntityRecords[j].txnNslAttribute[1]?.values?.[0];

      // Function
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].function =
        tempVarForTransEntityRecords[j].txnNslAttribute[2]?.values?.[0];
      transEntityRecords.nslFormula.push(null);
    } else if (EQLVersion === '2.0' || EQLVersion === '3.0') {
      //for NSL Formula at 0 location (NSL_formula Available)
      if (!transEntityRecords.isSelectedOperation) {
        transEntityRecords.isSelectedOperation = [];
      }
      transEntityRecords.isSelectedOperation.push(false);
      transEntityRecords.selectedOperation.push('');
      transEntityRecords.queryInputOutputArray[j].sourceAttribute =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[2]?.values?.[0];
      /* istanbul ignore next */
      transEntityRecords.attributesList.push(tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.values?.[0]);

      // Target Attribute
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].targetAttribute =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[3]?.values?.[0];

      // Function and NSL_Function
      /* istanbul ignore next */
      if (EQLVersion === '2.0') {
        transEntityRecords.queryInputOutputArray[j].function =
          tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[4]?.values?.[0];
      } else if (EQLVersion === '3.0') {
        /* istanbul ignore next */
        if (tempVarForTransEntityRecords?.[j]?.txnNslAttribute[4]?.txnGeneralEntity) {
          transEntityRecords.selectedOperation[j] = 'function';
          transEntityRecords.isSelectedOperation[j] = true;
          /* istanbul ignore next */
          transEntityRecords.nslFunction[j] = fillNSLFunction(
            tempVarForTransEntityRecords?.[j]?.txnNslAttribute[4],
            eqlLabel
          );
        }
      }

      /* istanbul ignore next */
      let tempReturnValue = fillNSLFormulae(
        transEntityRecords,
        tempVarForTransEntityRecords,
        j,
        'inputoutput',
        eqlLabel
      );
      /* istanbul ignore next */
      transEntityRecords = tempReturnValue?.transEntityRecords;
      /* istanbul ignore next */
      tempVarForTransEntityRecords = tempReturnValue?.tempVarForTransEntityRecords;

      // NSL Case
      /* istanbul ignore next */
      tempReturnValue = fillNSLCase(transEntityRecords, tempVarForTransEntityRecords, j, 'inputoutput', eqlLabel);
      /* istanbul ignore next */
      transEntityRecords = tempReturnValue?.transEntityRecords;
      /* istanbul ignore next */
      tempVarForTransEntityRecords = tempReturnValue?.tempVarForTransEntityRecords;
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function returnFunctionParamsStruct() {
  let functionParamsStruct: any = {
    functionAttribute: null,
    functionConstantValue: null,
    functionOperator: null,
    NslFunction: null,
  };
  return JSON.parse(JSON.stringify(functionParamsStruct));
}

/* istanbul ignore next */
export function addNSLFunctionParameters(Nslfunction: any) {
  Nslfunction.functionParams.push(returnFunctionParamsStruct()); // For Attribute
  Nslfunction.functionParams[Nslfunction.functionParams.length - 1].functionAttribute = '';

  Nslfunction.functionParams.push(returnFunctionParamsStruct()); // For Operator
  Nslfunction.functionParams[Nslfunction.functionParams.length - 1].functionOperator = '';

  Nslfunction.functionParams.push(returnFunctionParamsStruct()); // For Constant Value
  Nslfunction.functionParams[Nslfunction.functionParams.length - 1].functionConstantValue = '';
}

/* istanbul ignore next */
export function createNSLFunctionStruct() {
  let Nslfunction: any = {
    functionName: '',
    functionParams: new Array(),
  };
  addNSLFunctionParameters(Nslfunction);
  return Nslfunction;
}

/* istanbul ignore next */
export function fillNSLFunction(NSL_Function: any, eqlLabel: any) {
  let nslFunction: any = [];
  nslFunction.push(createNSLFunctionStruct());
  nslFunction[0].functionParams = new Array();
  /* istanbul ignore next */
  nslFunction[0].functionName =
    NSL_Function?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[0]?.values?.[0];
  /* istanbul ignore next */
  NSL_Function?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[1]?.txnGeneralEntity?.transEntityRecords?.forEach(
    (function_param: any) => {
      // For Attribute
      /* istanbul ignore next */
      if (
        function_param.txnNslAttribute[0].name === eqlLabel?.Function_Attribute &&
        function_param.txnNslAttribute[0]?.values?.length > 0
      ) {
        addNSLFunctionParameters(nslFunction[0]);
        /* istanbul ignore next */
        let currentFuncParamsLength = nslFunction?.[0]?.functionParams?.length;
        /* istanbul ignore next */
        nslFunction[0].functionParams[currentFuncParamsLength - 3].functionAttribute =
          function_param?.txnNslAttribute?.[0].values?.[0];
      }

      // For Operator
      /* istanbul ignore next */
      if (
        function_param.txnNslAttribute[3].name === eqlLabel?.Function_Operator &&
        function_param.txnNslAttribute[3]?.values?.length > 0
      ) {
        addNSLFunctionParameters(nslFunction[0]);
        let currentFuncParamsLength = nslFunction?.[0]?.functionParams.length;
        /* istanbul ignore next */
        nslFunction[0].functionParams[currentFuncParamsLength - 2].functionOperator =
          function_param?.txnNslAttribute?.[3]?.values?.[0];
      }

      // For Constant Value
      /* istanbul ignore next */
      if (
        function_param.txnNslAttribute[1].name === eqlLabel?.Function_Constant_Value &&
        function_param.txnNslAttribute[1]?.values?.length > 0
      ) {
        addNSLFunctionParameters(nslFunction[0]);
        /* istanbul ignore next */
        let currentFuncParamsLength = nslFunction?.[0]?.functionParams.length;
        /* istanbul ignore next */
        nslFunction[0].functionParams[currentFuncParamsLength - 1].functionConstantValue =
          function_param.txnNslAttribute[1]?.values?.[0];
        /* istanbul ignore next */
        if (nslFunction?.[0]?.functionParams?.[currentFuncParamsLength - 1]?.functionConstantValue?.[0] === "'") {
          /* istanbul ignore next */
          nslFunction[0].functionParams[
            currentFuncParamsLength - 1
          ].functionConstantValue = nslFunction[0].functionParams[
            currentFuncParamsLength - 1
          ].functionConstantValue.substring(
            1,
            nslFunction[0].functionParams[currentFuncParamsLength - 1].functionConstantValue.length - 1
          );
        }
      }

      // For Nested NSL Function
      /* istanbul ignore next */
      if (
        function_param.txnNslAttribute[2].name === eqlLabel?.NSL_Function &&
        function_param.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.length > 0
      ) {
        /* istanbul ignore next */
        nslFunction[0].functionParams.push(returnFunctionParamsStruct()); // For Nested NSL Function
        /* istanbul ignore next */
        nslFunction[0].functionParams[nslFunction[0].functionParams.length - 1].NslFunction = fillNSLFunction(
          function_param.txnNslAttribute[2],
          eqlLabel
        );
      }
    }
  );

  /* istanbul ignore next */
  deleteExtraFuncParams(nslFunction?.[0]?.functionParams);
  return nslFunction;
}

/* istanbul ignore next */
export function deleteExtraFuncParams(functionParams: any) {
  /* istanbul ignore next */
  if (functionParams && functionParams?.length > 0) {
    let initialIndex = 0;
    /* istanbul ignore next */
    while (initialIndex < functionParams.length) {
      /* istanbul ignore next */
      if (checkUnusedParameter(functionParams[initialIndex])) {
        /* istanbul ignore next */
        if (initialIndex + 1 < functionParams.length && checkUnusedParameter(functionParams[initialIndex + 1])) {
          /* istanbul ignore next */
          if (initialIndex + 2 < functionParams.length && checkUnusedParameter(functionParams[initialIndex + 2])) {
            functionParams.splice(initialIndex, 3);
          } else {
            initialIndex = initialIndex + 3;
          }
        } else {
          initialIndex = initialIndex + 2;
        }
      } else {
        /* istanbul ignore next */
        if (functionParams[initialIndex]?.NslFunction?.length > 0) {
          deleteExtraFuncParams(functionParams[initialIndex].NslFunction[0].functionParams);
        }
        initialIndex = initialIndex + 1;
      }
    }
  }
}

/* istanbul ignore next */
function checkUnusedParameter(nslFunction: any) {
  /* istanbul ignore next */
  let flag =
    !nslFunction?.functionAttribute &&
    !nslFunction?.functionOperator &&
    !nslFunction?.functionConstantValue &&
    (!nslFunction?.NslFunction || (nslFunction?.NslFunction && nslFunction?.NslFunction?.length === 0));
  return flag;
}

/* istanbul ignore next */
export function fetchRuleSetData(
  queryCondition: any,
  transEntityRecords: any,
  level: number,
  query1: any,
  EQLVersion: any,
  eqlLabel: any
) {
  /* istanbul ignore next */
  let RuleSet: any;
  RuleSet = [];
  RuleSet.toggle = [];
  RuleSet.isGroupQuery = [];
  RuleSet.nslQuery = {};

  /* istanbul ignore next */
  if (queryCondition) {
    var groupQueryLength = queryCondition?.transEntityRecords?.length;

    // Loop for each queryData/Rule of RuleSet
    for (let k = 0; k < groupQueryLength; k++) {
      RuleSet.push(JSON.parse(JSON.stringify(query1)));

      // Attribute Name
      /* istanbul ignore next */
      RuleSet[k].attributeName =
        EQLVersion === '2.0' || EQLVersion === '3.0'
          ? transEntityRecords[k]?.txnNslAttribute?.[1]?.txnGeneralEntity
            ? 'formula'
            : transEntityRecords[k].txnNslAttribute?.[7]?.txnGeneralEntity
            ? 'case'
            : transEntityRecords[k].txnNslAttribute?.[0]?.txnGeneralEntity
            ? 'function'
            : transEntityRecords[k].txnNslAttribute?.[2]?.values?.[0]
          : transEntityRecords[k].txnNslAttribute?.[0]?.values?.[0];

      if (EQLVersion !== 'EQLUpdate') {
        if (
          RuleSet[k].attributeName !== 'formula' &&
          RuleSet[k].attributeName !== 'case' &&
          RuleSet[k].attributeName !== 'function'
        ) {
          if (transEntityRecords?.attributesListForQueryLayer?.length > 0) {
            transEntityRecords.attributesListForQueryLayer.push(RuleSet[k].attributeName);
          } else {
            transEntityRecords.attributesListForQueryLayer = [];
            transEntityRecords.attributesListForQueryLayer.push(RuleSet[k].attributeName);
          }
        }
      }

      //NSL Nested Formula in Condition
      fillNestedNSLFormulae(RuleSet, transEntityRecords, k, eqlLabel);
      fillNestedNSLCase(RuleSet, transEntityRecords, k, eqlLabel);
      if (RuleSet[k].attributeName == 'function') {
        // RuleSet[k].nslFunctionForCondition = [];
        let tempNSLFunction = fillNSLFunction(transEntityRecords?.[k]?.txnNslAttribute[0], eqlLabel);
        RuleSet[k].nslFunctionForCondition = tempNSLFunction[0];
      }

      // Query Operator
      /* istanbul ignore next */
      RuleSet[k].queryOperator =
        EQLVersion === '2.0' || EQLVersion === '3.0'
          ? transEntityRecords[k].txnNslAttribute[3]?.values?.[0]
          : transEntityRecords[k].txnNslAttribute[1]?.values?.[0];

      // Attribute Value
      /* istanbul ignore next */
      RuleSet[k].attributeValue =
        EQLVersion === '2.0' || EQLVersion === '3.0'
          ? transEntityRecords[k].txnNslAttribute[4]?.values?.[0]
          : transEntityRecords[k].txnNslAttribute[2]?.values?.[0];

      /* istanbul ignore next */
      if (EQLVersion !== 'EQLUpdate') {
        let queryConditionIndex = EQLVersion === '2.0' || EQLVersion === '3.0' ? 5 : 3;
        // For SubQuery
        if (
          !transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity ||
          !transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity.transEntityRecords
        ) {
          RuleSet[k].nslQuery = {};
        } else {
          RuleSet[k].nslQuery = this.loadtempObjData(
            transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity.transEntityRecords[0]
              .txnNslAttribute,
            false,
            level + 1
          );
          RuleSet[k].nslQuery.showLibraryBtn = true;
        }
      }

      // Query Condition
      /* istanbul ignore next */
      if (EQLVersion !== 'EQLUpdate') {
        RuleSet[k].queryCondition =
          EQLVersion === '2.0' || EQLVersion === '3.0'
            ? transEntityRecords[k].txnNslAttribute[6].txnGeneralEntity
            : transEntityRecords[k].txnNslAttribute[4].txnGeneralEntity;
      } else {
        RuleSet[k].nslUpdateQueryCondition = transEntityRecords[k].txnNslAttribute[3].txnGeneralEntity;
      }
      // Predicate
      /* istanbul ignore next */
      RuleSet[k].predicate =
        EQLVersion === '2.0' || EQLVersion === '3.0'
          ? transEntityRecords[k].txnNslAttribute[8]?.values?.[0]
          : EQLVersion === 'EQLUpdate'
          ? transEntityRecords[k].txnNslAttribute[4]?.values?.[0]
          : transEntityRecords[k].txnNslAttribute[5]?.values?.[0];

      // Set predicate for RulSet
      /* istanbul ignore next */
      if (RuleSet[k].predicate === 'AND') {
        RuleSet.toggle.push(false);
      }
      /* istanbul ignore next */
      if (RuleSet[k].predicate === 'OR') {
        RuleSet.toggle.push(true);
      }

      // Query Condition for RuleSet Block
      if (EQLVersion !== 'EQLUpdate') {
        if (RuleSet[k].queryCondition) {
          let nslQueryData = RuleSet[k].nslQuery;
          let queryConditionData = RuleSet[k].queryCondition;
          /* istanbul ignore next */
          let queryConditionIndex = EQLVersion === '2.0' || EQLVersion === '3.0' ? 6 : 4;
          let tempPredicate = RuleSet[k]?.predicate;
          RuleSet[k] = fetchRuleSetData(
            queryConditionData,
            transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity.transEntityRecords,
            level + 1,
            query1,
            EQLVersion,
            eqlLabel
          );
          RuleSet[k].predicate = tempPredicate;

          // For single subquery of whole RuleSet
          if (!RuleSet[k].nslQuery) {
            RuleSet[k].nslQuery = {};
          }
          RuleSet[k].nslQuery = nslQueryData;

          RuleSet.isGroupQuery.push(true);
        } else {
          RuleSet.isGroupQuery.push(false);
        }
      } else {
        if (RuleSet[k].nslUpdateQueryCondition) {
          let nslQueryData = RuleSet[k].nslQuery;
          let queryConditionData = RuleSet[k].nslUpdateQueryCondition;
          /* istanbul ignore next */
          let tempPredicate = RuleSet[4]?.predicate;
          RuleSet[k] = fetchRuleSetData(
            queryConditionData,
            transEntityRecords[k].txnNslAttribute[3].txnGeneralEntity.transEntityRecords,
            level + 1,
            query1,
            EQLVersion,
            eqlLabel
          );
          RuleSet[k].predicate = tempPredicate;

          // For single subquery of whole RuleSet
          if (!RuleSet[k].nslQuery) {
            RuleSet[k].nslQuery = {};
          }
          RuleSet[k].nslQuery = nslQueryData;

          RuleSet.isGroupQuery.push(true);
        } else {
          RuleSet.isGroupQuery.push(false);
        }
      }
    }
  }
  return RuleSet;
}

// Add new Rule block
/* istanbul ignore next */
export function addNewQuery(transEntityRecords: any, query1: any) {
  /* istanbul ignore next */
  if (!transEntityRecords.queryData) {
    transEntityRecords.queryData = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.toggle) {
    transEntityRecords.queryData.toggle = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.isGroupQuery) {
    transEntityRecords.queryData.isGroupQuery = [];
  }

  transEntityRecords.queryData.push(JSON.parse(JSON.stringify(query1)));
  transEntityRecords.queryData.toggle.push(false);
  let index = transEntityRecords.queryData.length - 1;
  /* istanbul ignore next */
  if (index > 0) {
    transEntityRecords.queryData[index - 1].predicate = 'AND';
    transEntityRecords.queryData.toggle[index - 1] = false;
  }
  transEntityRecords.queryData.isGroupQuery.push(false);
  return transEntityRecords;
}

//Add new RuleSet block
/* istanbul ignore next */
export function addNewGroupQuery(transEntityRecords: any) {
  /* istanbul ignore next */
  if (!transEntityRecords.queryData) {
    transEntityRecords.queryData = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.toggle) {
    transEntityRecords.queryData.toggle = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.isGroupQuery) {
    transEntityRecords.queryData.isGroupQuery = [];
  }

  transEntityRecords.queryData.push([]);
  let index = transEntityRecords.queryData.length - 1;
  transEntityRecords.queryData.toggle.push(false);
  /* istanbul ignore next */
  if (index > 0) {
    transEntityRecords.queryData[index - 1].predicate = 'AND';
    transEntityRecords.queryData.toggle[index - 1] = false;
  }
  transEntityRecords.queryData.isGroupQuery.push(true);
  return transEntityRecords;
}

// Add new rule block within RuleSet block
/* istanbul ignore next */
export function addNewQueryForGroup(RuleSet: any, query1: any) {
  /* istanbul ignore next */
  if (!RuleSet.toggle) {
    RuleSet.toggle = [];
  }
  /* istanbul ignore next */
  if (!RuleSet.isGroupQuery) {
    RuleSet.isGroupQuery = [];
  }

  RuleSet.push(JSON.parse(JSON.stringify(query1)));
  let index = RuleSet.length - 1;
  // Set previous rule predicate as AND
  /* istanbul ignore next */
  if (index > 0) {
    RuleSet[index - 1].predicate = 'AND';
  }

  RuleSet.toggle.push(false);
  RuleSet.isGroupQuery.push(false);
  return RuleSet;
}

// Add new RuleSet block within RuleSet block
/* istanbul ignore next */
export function addNewGroupQueryForGroup(RuleSet: any) {
  /* istanbul ignore next */
  if (!RuleSet.toggle) {
    RuleSet.toggle = [];
  }
  /* istanbul ignore next */
  if (!RuleSet.isGroupQuery) {
    RuleSet.isGroupQuery = [];
  }

  RuleSet.push([]);
  RuleSet.toggle.push(false);
  RuleSet.isGroupQuery.push(true);
  let index = RuleSet.length - 1;
  /* istanbul ignore next */
  if (index > 0) {
    RuleSet[index - 1].predicate = 'AND';
  }
  return RuleSet;
}

/* istanbul ignore next */
export function validateQuery(
  tempGsiObj: any,
  reservedCuIndex: any,
  validateQueryParams: any,
  EQLVersion: any,
  ccdRequest: any,
  entityForTargetDropDown?: any,
  type?: any
) {
  var validateQueryFormat: any = [];
  let outputGeneralEntity;
  if (type == 'EQL-Report') {
    outputGeneralEntity = entityForTargetDropDown;
  } else {
    outputGeneralEntity = tempGsiObj?.cuList?.[reservedCuIndex]?.layers.find(
      (layer: any) => layer.type === 'triggerCES'
    );
  }
  let tempQueryParams: any = {};
  let parametersDataType: any = {};

  for (let i = 0; i < validateQueryParams.length; i++) {
    /* istanbul ignore next */
    if (
      validateQueryParams?.[i]?.operator === 'GEO_DISTANCE' ||
      validateQueryParams?.[i]?.operator === 'GEO_DISTANCE_EXCLUSION'
    ) {
      let tempValue: any = [];
      /* istanbul ignore next */
      tempValue[0] = {
        longitude: validateQueryParams?.[i]?.attrValue?.[0]?.longitude,
        latitude: validateQueryParams?.[i]?.attrValue?.[0]?.latitude,
      };
      tempValue[0] = JSON.stringify(tempValue[0]);
      tempValue[1] = validateQueryParams?.[i]?.attrValue[0].radius + validateQueryParams?.[i]?.attrValue[0].unit;
      tempQueryParams[validateQueryParams[i].attr] = [];
      // parametersDataType[validateQueryParams[i].attr] = [];
      parametersDataType[validateQueryParams[i].attr] = validateQueryParams[i].attrType;
      tempQueryParams[validateQueryParams[i].attr] = tempValue;
    } else if (
      validateQueryParams?.[i]?.attrUiElement === 'location' ||
      validateQueryParams?.[i]?.attrUiElement === 'MMILocation'
    ) {
      let tempValue: any = [];
      /* istanbul ignore next */
      tempValue[0] = {
        longitude: validateQueryParams?.[i]?.attrValue?.[0]?.longitude,
        latitude: validateQueryParams?.[i]?.attrValue?.[0]?.latitude,
      };
      tempValue[0] = JSON.stringify(tempValue[0]);
      tempQueryParams[validateQueryParams[i].attr] = [];
      parametersDataType[validateQueryParams[i].attr] = [];
      tempQueryParams[validateQueryParams[i].attr] = tempValue;
      parametersDataType[validateQueryParams[i].attr] = validateQueryParams[i].attrType;
    } else {
      /* istanbul ignore next */
      if (validateQueryParams?.[i]?.attrValue?.[0] !== '' || validateQueryParams?.[i]?.attrValue?.[1] !== '') {
        tempQueryParams[validateQueryParams[i].attr] = [];
        parametersDataType[validateQueryParams[i].attr] = [];
        validateQueryParams[i].attrValue.forEach((value: any, j: any) => {
          tempQueryParams[validateQueryParams[i].attr].push(validateQueryParams[i].attrValue[j].value);
        });
        parametersDataType[validateQueryParams[i].attr] = validateQueryParams[i].attrType;
      }
    }
  }

  validateQueryFormat = {
    version: EQLVersion,
    nslQuery:
      type == 'EQL-Report'
        ? ccdRequest.PL_SL002.propertyData.CONSTANT.changeDriverData.DATA
        : ccdRequest.PL_SL001.propertyData.CONSTANT.changeDriverData.DATA,
    outputGeneralEntity:
      type == 'EQL-Report'
        ? outputGeneralEntity
        : type == 'NSL_Template_PaginatedEQLSearch'
        ? outputGeneralEntity?.participatingItems?.[2]?.item?.DATA
        : outputGeneralEntity?.participatingItems?.[0]?.item?.DATA,
    queryParameters: tempQueryParams,
    parametersDataType: parametersDataType,
  };
  return validateQueryFormat;
}

/* istanbul ignore next */
export function checkConditionValidation(queryFormula: any, validationMessage: any) {
  let providedAllRequiredData = true;
  if (queryFormula && queryFormula.queryData) {
    for (let i = 0; i < queryFormula?.queryData?.length; i++) {
      // For ruleset
      if (queryFormula.queryData[i].length) {
        providedAllRequiredData = checkRuleSetBlock(
          queryFormula.queryData?.[i],
          providedAllRequiredData,
          validationMessage
        );
      }
      // For Rule
      else {
        providedAllRequiredData = checkRuleBlock(
          queryFormula.queryData?.[i],
          providedAllRequiredData,
          validationMessage
        );
      }
    }
  }
  return providedAllRequiredData;
}

// Check all input fields data of rule block
/* istanbul ignore next */
export function checkRuleBlock(Rule: any, providedAllRequiredData: any, validationMessage: any) {
  let conditions = {
    attributeName: true,
    queryOperator: true,
    attributeValue: true,
  };
  /* istanbul ignore next */
  if (Rule) {
    if (Rule.attributeName === undefined || Rule.attributeName === '') {
      conditions.attributeName = false;
      providedAllRequiredData = false;
      validationMessage.push('Please Provide Attribute Name in Condition Block');
    }
    if (Rule.attributeName == 'formula' || Rule.attributeName == 'case') {
      conditions.attributeName = true;
      providedAllRequiredData = true;
      // Rule.attributeName = '';
    }
    if (Rule.queryOperator === undefined || Rule.queryOperator === '') {
      conditions.queryOperator = false;
      providedAllRequiredData = false;
      validationMessage.push('Please Provide Query Operator in Condition Block');
    }
    if (Rule.attributeValue === undefined || Rule.attributeValue === '') {
      conditions.attributeValue = false;
      providedAllRequiredData = false;
      validationMessage.push('Please Provide Attribute Value in Condition Block');
    }
    Rule.conditions = conditions;
  }
  return providedAllRequiredData;
}

// Check all input fields data of ruleset block
/* istanbul ignore next */
export function checkRuleSetBlock(RuleSet: any, providedAllRequiredData: any, validationMessage: any) {
  let anyRuleExist = true;
  /* istanbul ignore next */
  if (RuleSet) {
    /* istanbul ignore next */
    if (RuleSet?.length > 0) {
      /* istanbul ignore next */
      for (let i = 0; i < RuleSet?.length; i++) {
        // For ruleset
        if (RuleSet?.isGroupQuery?.[i]) {
          providedAllRequiredData = checkRuleSetBlock(RuleSet[i], providedAllRequiredData, validationMessage);
        }
        // For rule
        else {
          providedAllRequiredData = checkRuleBlock(RuleSet[i], providedAllRequiredData, validationMessage);
        }
      }
    } else {
      // if there is no rule inside a ruleset
      anyRuleExist = false;
      providedAllRequiredData = false;
      validationMessage.push('Please Provide at least one Condition inside Condition Group');
    }
    RuleSet.anyRuleExist = anyRuleExist;
  }
  return providedAllRequiredData;
}

/* istanbul ignore next */
export function loadEntityAttributeData(res: any, transEntityRecords: any) {
  /* istanbul ignore next */
  if (!transEntityRecords) {
    transEntityRecords = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords?.queryData) {
    // transEntityRecords = JSON.parse(JSON.stringify(transEntityRecordsparams));
    transEntityRecords.queryData = [];
    transEntityRecords.queryData.isGroupQuery = [];
    transEntityRecords.queryData.toggle = [];
    transEntityRecords.nslFormula = [];
    transEntityRecords.nslCase = [];
    if (!transEntityRecords.queryInputOutputArray) {
      transEntityRecords.queryInputOutputArray = [];
    }
    transEntityRecords.filterData = [];
    transEntityRecords.currentLevel = 0;
  }
  /* istanbul ignore next */
  transEntityRecords = addQueryAttributes(res, res?.name, transEntityRecords);
  /* istanbul ignore next */
  let entityName = res?.name;
  let checkEntityAvailable = false;
  /* istanbul ignore next */
  transEntityRecords?.entityList?.forEach((entity: any) => {
    /* istanbul ignore next */
    if (entity === entityName) {
      checkEntityAvailable = true;
    }
  });

  /* istanbul ignore next */
  if (!checkEntityAvailable) {
    transEntityRecords?.entityList?.push(entityName);
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function addQueryAttributes(entityData: any, entityname: string, transEntityRecords: any) {
  let entityName = entityname;
  let sourceAttribute = entityData?.nslAttributes;
  let newAttrName: string;
  /* istanbul ignore next */
  for (var i = 0; i < sourceAttribute?.length; i++) {
    newAttrName = entityName + '.' + sourceAttribute[i]?.name;
    /* istanbul ignore next */
    if (transEntityRecords?.attributesList?.length > 0) {
    } else {
      transEntityRecords.attributesList = [];
      transEntityRecords.entityList = [];
    }
    let tempSourceAttr: any = [];
    transEntityRecords?.queryInputOutputArray?.forEach((element: any) => {
      if (element?.sourceAttribute) {
        tempSourceAttr.push(element.sourceAttribute);
      }
    });
    /* istanbul ignore next */
    if (!tempSourceAttr?.includes(newAttrName)) {
      /* istanbul ignore next */
      if (!sourceAttribute[i]?.generalEntity) {
        transEntityRecords?.queryInputOutputArray?.push({});
        transEntityRecords.queryInputOutputArray[
          transEntityRecords?.queryInputOutputArray?.length - 1
        ].sourceAttribute = newAttrName;
        /* istanbul ignore next */
        if (!transEntityRecords?.attributesList?.includes(newAttrName)) {
          transEntityRecords?.attributesList?.push(newAttrName);
        }
      } /* istanbul ignore next */ else if (sourceAttribute[i]?.generalEntity) {
        transEntityRecords = addQueryAttributes(sourceAttribute[i].generalEntity, newAttrName, transEntityRecords);
      }
    }
    /* istanbul ignore next */
    transEntityRecords?.nslFormula?.push([]);
    transEntityRecords?.nslCase?.push([]);
  }
  return transEntityRecords;
}

// Find layer in reserved cu
/* istanbul ignore next */
export function findLayer(reservedCu: any, type: string) {
  /* istanbul ignore next */
  return reservedCu?.layers?.find((layer: any) => layer?.type === type);
}

/* istanbul ignore next */
export function setEntityId(reservedCu: any, ccdRequest: any, type: string) {
  let layer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    let entityId = layer?.participatingItems?.[0].id;
    ccdRequest.PL_SL001.propertyData.CONSTANT.changeDriverData.DATA.dsdId = entityId;
  } else {
    let entityId = layer?.participatingItems?.[1]?.id;
    ccdRequest.PL_SL002.propertyData.CONSTANT.changeDriverData.DATA.dsdId = entityId;
  }
  return ccdRequest;
}

//transform form values to txnGeneralEntity
/* istanbul ignore next */
export function transformQueryRecords(
  transEntityRecords: any,
  fields: any,
  entityIndex: number,
  reservedCu: any,
  type: any
) {
  let main: any = [];
  /* istanbul ignore next */
  transEntityRecords?.forEach((record: any) => {
    if (record) {
      let tempEnt: any = { txnNslAttribute: [] };
      /* istanbul ignore next */
      fields?.forEach((field: any, i: any) => {
        /* istanbul ignore next */
        let rec: any;
        if (reservedCu.reservedCUType !== 'NSL_Template_BL_Query') {
          rec = generateTransEntityRecord(field, i, record[field?.name], entityIndex, reservedCu, type);
        } else {
          rec = generateTransEntityRecord(
            field,
            i,
            record[field?.name],
            entityIndex,
            reservedCu,
            'NSL_Template_BL_Query'
          );
        }
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    }
  });
  return main;
}

/* istanbul ignore next */
export function transformQueryRecordsForNSLHaving(
  transEntityRecords: any,
  fields: any,
  reservedCu: any,
  type: any,
  EQLVersion: any,
  eqlLabel: any
) {
  var query = transformQueryRecords(transEntityRecords[0].queryData, fields, 1, reservedCu, type);
  var aggregateQueryData = extractSubEntityId(
    reservedCu,
    eqlLabel?.NSL_Query_Condition,
    query,
    type,
    EQLVersion,
    eqlLabel
  );
  return aggregateQueryData.txnGeneralEntity;
}

//generatetransentity record from input fields
/* istanbul ignore next */
export function generateTransEntityRecord(
  field: any,
  index: number,
  value: any,
  entityIndex: number,
  reservedCu: any,
  type: string
) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  /* istanbul ignore next */
  let rec: any = {
    name: field.display,
    dsdId:
      type === 'EQL'
        ? physicalLayer?.participatingItems?.[0]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
            ?.dsdId
          ? physicalLayer?.participatingItems?.[0]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
              ?.dsdId
          : physicalLayer?.participatingItems?.[0]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
              ?.id
        : physicalLayer?.participatingItems?.[1]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
            ?.dsdId
        ? physicalLayer?.participatingItems?.[1]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
            ?.dsdId
        : physicalLayer?.participatingItems?.[1]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
            ?.id,
    values: [],
  };
  /* istanbul ignore next */
  if (value) {
    /* istanbul ignore next */
    if (field?.name === 'functionConstantValue' && value?.[0] !== "'") {
      value = "'" + value + "'";
    }
    if (field.name === 'nslQuery') {
      rec.values = [];
    } else {
      if (type == 'NSL_Template_BL_Query' && field.name === 'attributeValue') {
        rec.values = value;
      } else if (type !== 'NSL_Template_BL_Query' && field.name === 'GroupbyAttribute') {
        rec.values = value;
      } else {
        rec?.values?.push(value);
      }
    }
  }
  return rec;
}

/* istanbul ignore next */
export function transformNSLCaseRecords(
  transEntityRecords: any,
  fields: any,
  entityIndex: number,
  reservedCu: any,
  type?: any
) {
  let main: any = [];
  transEntityRecords?.forEach((record: any) => {
    if (record) {
      let tempEnt: any = { txnNslAttribute: [] };
      /* istanbul ignore next */
      fields?.forEach((field: any, i: any) => {
        /* istanbul ignore next */
        let rec = generateNSLCaseTransEntityRecord(field, i, record?.[field?.name], entityIndex, reservedCu, type);
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    } else if (record.length) {
      transformNSLCaseRecords(record, fields, entityIndex, reservedCu);
    }
  });
  return main;
}

/* istanbul ignore next */
export function generateNSLCaseTransEntityRecord(
  field: any,
  index: number,
  value: any,
  entityIndex: number,
  reservedCu: any,
  type?: any
) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  let rec: any;
  if (type != 'caseThen' && type != 'caseElse') {
    rec = {
      name: field.display,
      dsdId:
        entityIndex === 0
          ? physicalLayer?.participatingItems?.[0].nslAttributes?.[1]?.generalEntity?.nslAttributes?.[index]?.dsdId
          : physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes?.[1]?.generalEntity
              ?.nslAttributes?.[index]?.dsdId,
      values: [],
    };
  } else {
    rec = {
      name: field.display,
      dsdId:
        type === 'caseThen'
          ? physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes?.[1]?.generalEntity
              ?.nslAttributes?.[1]?.generalEntity?.nslAttributes?.[index]?.dsdId
          : physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes?.[1]?.generalEntity
              ?.nslAttributes?.[2]?.generalEntity?.nslAttributes?.[index]?.dsdId,
      values: [],
    };
  }

  /* istanbul ignore next */
  if (value) {
    if (field.name === 'nslQuery') {
      rec.values = [];
    } else {
      rec?.values?.push(value);
    }
  }
  return rec;
}

//get sub entity id from reserved cu
/* istanbul ignore next */
export function extractSubEntityId(
  reservedCu: any,
  name: string,
  input: any,
  type: any,
  EQLVersion: any,
  eqlLabel?: any
) {
  var entity;
  /* istanbul ignore else */
  if (name === eqlLabel?.NSL_Formula) {
    entity = getNSLFormulaEntityData(name, reservedCu, type);
    let newInput: any = [];
    input.forEach((attr: any) => {
      if (attr && !attr.length) {
        newInput.push(attr);
      } else if (attr.length > 0) {
        let arithmeticFields = [
          { name: 'functionName', display: eqlLabel?.Function_Name },
          { name: 'attributeName', display: eqlLabel?.Attribute_Name },
          { name: 'nslQuery', display: eqlLabel?.NSL_Query },
          { name: 'nslFormula', display: eqlLabel?.NSL_Formula },
          { name: 'nslCase', display: eqlLabel?.NSL_Case },
          { name: 'arithmeticOperator', display: eqlLabel?.Arithmetic_Operator },
        ];
        /* istanbul ignore next */
        if (EQLVersion === '3.0') {
          arithmeticFields[0] = { name: 'nslFunction', display: eqlLabel?.NSL_Function };
        }
        let tempGroupFormula = [
          {
            functionName: '',
            attributeName: '',
            nslQuery: '',
            nslFormula: '',
            nslCase: '',
            arithmeticOperator: attr?.arithmeticOperator,
          },
        ];
        let tempInput: any = [];
        tempInput = transformNSLFormulaRecords(tempGroupFormula, arithmeticFields, 0, reservedCu, type);
        newInput.push(tempInput[0]);
        newInput[newInput.length - 1].txnNslAttribute[3] = extractSubEntityId(
          reservedCu,
          name,
          attr,
          type,
          EQLVersion,
          eqlLabel
        );
      }
    });
    input = newInput;
  } else if (name === eqlLabel?.NSL_Then || name === eqlLabel?.NSL_Else) {
    entity = getNSLThenElseEntityData(name, reservedCu, type);
  } else if (name === eqlLabel?.NSL_Case) {
    entity = getNSLCaseEntityData(name, reservedCu, type);
  } else if (name == eqlLabel?.NSL_Function_Parameters) {
    entity = getFunctionParametersEntityData(name, reservedCu, type);
  } else if (name == eqlLabel?.NSL_Function) {
    entity = getFunctionsEntityData(name, reservedCu, type);
  } else {
    entity = getEntityData(name, reservedCu, type);
  }

  /* istanbul ignore next */
  if (
    entity &&
    !entity?.generalEntity &&
    name !== eqlLabel?.NSL_Query_Input_Output &&
    name !== eqlLabel?.NSL_Query_Condition &&
    name !== eqlLabel?.NSL_Filter
  ) {
    /* istanbul ignore next */
    return {
      name: name,
      dsdId: entity?.id,
      values: [] as any,
      properties: ['CONSTANT'],
    };
  }

  /* istanbul ignore next */
  if (input) {
    return {
      name: name,
      dsdId: entity?.id,
      values: [] as any,
      properties: ['CONSTANT'],
      txnGeneralEntity: {
        name: name,
        dsdId: entity?.generalEntity?.dsdId ? entity?.generalEntity?.dsdId : entity?.generalEntity?.id,
        transEntityRecords: input,
      },
    };
  } else {
    return {
      name: name,
      dsdId: entity?.id,
      values: [] as any,
      properties: ['CONSTANT'],
    };
  }
}

//get NSL_Formula entity data from reserved cu
/* istanbul ignore next */
export function getNSLFormulaEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0]?.nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  } else {
    return physicalLayer?.participatingItems?.[1]?.nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  }
}

/* istanbul ignore next */
export function getNSLThenElseEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes[1]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes[1]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  }
}

/* istanbul ignore next */
export function getFunctionsEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes[1]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  }
}

/* istanbul ignore next */
export function getFunctionParametersEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes[4]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes[1]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  }
}
/* istanbul ignore next */
export function getNSLCaseEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  }
}

//get entity data from reserved cu
/* istanbul ignore next */
export function getEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  /* istanbul ignore next */
  if (reservedCu.reservedCUType !== 'NSL_Template_BL_Query') {
    if (type === 'EQL') {
      return physicalLayer?.participatingItems?.[0].nslAttributes?.find((ent: any) => {
        return ent.name === name;
      });
    } else {
      return physicalLayer?.participatingItems?.[1].nslAttributes?.find((ent: any) => {
        return ent.name === name;
      });
    }
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.find((ent: any) => {
      return ent.name === name;
    });
  }
}

/* istanbul ignore next */
export function transformNSLFormulaRecords(
  transEntityRecords: any,
  fields: any,
  entityIndex: number,
  reservedCu: any,
  type: any
) {
  let main: any = [];
  transEntityRecords?.forEach((record: any) => {
    if (record && !record.length) {
      let tempEnt: any = { txnNslAttribute: [] };
      /* istanbul ignore next */
      fields?.forEach((field: any, i: any) => {
        /* istanbul ignore next */
        let rec = generateNSLFormulaTransEntityRecord(field, i, record?.[field?.name], entityIndex, reservedCu, type);
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    } else if (record?.length) {
      main.push(transformNSLFormulaRecords(record, fields, entityIndex, reservedCu, type));
      if (record?.arithmeticOperator) {
        main[main.length - 1].arithmeticOperator = record.arithmeticOperator;
      }
    }
  });
  return main;
}

//generatetransentity Formula record from input fields
/* istanbul ignore next */
export function generateNSLFormulaTransEntityRecord(
  field: any,
  index: number,
  value: any,
  entityIndex: number,
  reservedCu: any,
  type: any
) {
  let physicalLayer = findLayer(reservedCu, 'physical');

  let rec: any = {
    name: field.display,
    dsdId:
      type === 'EQL'
        ? physicalLayer?.participatingItems?.[0]?.nslAttributes?.[0]?.generalEntity?.nslAttributes?.[0]?.generalEntity
            ?.nslAttributes?.[index]?.dsdId
        : physicalLayer?.participatingItems?.[1]?.nslAttributes?.[0]?.generalEntity?.nslAttributes?.[0]?.generalEntity
            ?.nslAttributes?.[index]?.dsdId,
    values: [],
  };
  /* istanbul ignore next */
  if (value) {
    if (field.name === 'nslQuery') {
      rec.values = [];
    } else {
      rec?.values?.push(value);
    }
  }
  return rec;
}

/* istanbul ignore next */
export function transformGroupFormulaData(
  Formula: any,
  reservedCu: any,
  arithmeticFields: any,
  type: any,
  EQLVersion: any,
  eqlLabel: any
) {
  var tempGroupFormulaIndex = [];
  let tempGroupFormulaData = [];
  let tempGroupFormulaRecords: any;
  let tempGroupNslFormula: any;

  // Check weather any rule or ruleset exists or not
  /* istanbul ignore next */
  if (Formula?.length > 0) {
    /* istanbul ignore next */
    for (let i = 0; i < Formula?.length; i++) {
      // For Rule
      /* istanbul ignore next */
      if (!Formula?.[i]?.length) {
        tempGroupFormulaData.push(Formula?.[i]);
      }
      // For RuleSet Block
      else {
        let tempGroupFormula = {
          functionName: '',
          attributeName: '',
          nslQuery: '',
          nslFormula: '',
          nslCase: '',
          arithmeticOperator: '',
        };

        tempGroupFormula.nslFormula = transformGroupFormulaData(
          Formula?.[i],
          reservedCu,
          arithmeticFields,
          type,
          EQLVersion,
          eqlLabel
        );
        tempGroupFormulaIndex.push(i);
        tempGroupFormulaData.push(tempGroupFormula);
      }
    }
  }

  // Transform data to API JSON format
  tempGroupNslFormula = transformNSLFormulaRecords(tempGroupFormulaData, arithmeticFields, 0, reservedCu, type);

  tempGroupFormulaRecords = extractSubEntityId(
    reservedCu,
    eqlLabel?.NSL_Formula,
    tempGroupNslFormula,
    type,
    EQLVersion,
    eqlLabel
  );

  // For RuleSet inside a RuleSet
  /* istanbul ignore next */
  // Rules of RuleSet Block

  if (tempGroupFormulaIndex?.length > 0) {
    if (tempGroupFormulaIndex?.length > 0) {
      tempGroupFormulaRecords.txnGeneralEntity.transEntityRecords = returnFormulaRecords(
        tempGroupFormulaRecords?.txnGeneralEntity?.transEntityRecords,
        tempGroupFormulaIndex
      );
    }
  }

  // Return only txnGeneralEntity from NSL_Query_Condition
  return tempGroupFormulaRecords.txnGeneralEntity;
}

/* istanbul ignore next */
export function transformGroupQueryData(
  queryData: any,
  reservedCu: any,
  EQLVersion: any,
  queryFields: any,
  type: any,
  eqlLabel: any,
  reservedCuType?: any
) {
  var tempGroupQueryIndex = [];
  var tempGroupQueryData = [];
  let tempGroupTransEntityRecords: any;
  let tempGroupQuery: any;

  // Check weather any rule or ruleset exists or not
  /* istanbul ignore next */
  if (queryData.length > 0) {
    for (let i = 0; i < queryData?.isGroupQuery?.length; i++) {
      // For Rule
      if (!queryData?.isGroupQuery?.[i]) {
        tempGroupQueryData.push(queryData?.[i]);
      }
      // For RuleSet Block
      else {
        let tempGroupTransEntityRecord: any;
        if (reservedCuType !== 'NSL_EQL_Update') {
          tempGroupTransEntityRecord = {
            attributeName: '',
            attributeValue: '',
            nslQuery: '',
            predicate: queryData?.length - 1 != i ? queryData[i].predicate : '',
            queryCondition: {},
            queryOperator: '',
          };
          // QueryCondition for RuleSet inside a RuleSet
          tempGroupTransEntityRecord.queryCondition = transformGroupQueryData(
            queryData?.[i],
            reservedCu,
            EQLVersion,
            queryFields,
            type,
            eqlLabel,
            reservedCuType
          );
        } else {
          tempGroupTransEntityRecord = {
            attributeName: '',
            attributeValue: '',
            predicate: queryData.length - 1 != i ? queryData[i].predicate : '',
            nslUpdateQueryCondition: {},
            queryOperator: '',
          };
          // QueryCondition for RuleSet inside a RuleSet
          tempGroupTransEntityRecord.nslUpdateQueryCondition = transformGroupQueryData(
            queryData?.[i],
            reservedCu,
            EQLVersion,
            queryFields,
            type,
            eqlLabel,
            reservedCuType
          );
        }

        tempGroupQueryIndex.push(i);
        tempGroupQueryData.push(tempGroupTransEntityRecord);
      }
    }
  }

  // Transform data to API JSON format
  tempGroupQuery = transformQueryRecords(tempGroupQueryData, queryFields, 1, reservedCu, type);
  let tempEntname: any;
  let queryConditionIndex: any;
  if (reservedCuType !== 'NSL_EQL_Update') {
    tempEntname = reservedCuType === 'NSL_Template_BL_Query' ? 'NSL_BL_Query_Condition' : eqlLabel?.NSL_Query_Condition;
    queryConditionIndex = EQLVersion == '2.0' || EQLVersion === '3.0' ? 6 : 4;
  } else {
    tempEntname = eqlLabel?.NSL_Update_Query_Condition;
    queryConditionIndex = 3;
  }
  tempGroupTransEntityRecords = extractSubEntityId(reservedCu, tempEntname, tempGroupQuery, type, EQLVersion, eqlLabel);

  /* istanbul ignore next */
  // For RuleSet inside a RuleSet
  /* istanbul ignore next */
  if (tempGroupQueryIndex?.length > 0) {
    let transEntityRecords = tempGroupTransEntityRecords?.txnGeneralEntity?.transEntityRecords;
    for (let i = 0; i < transEntityRecords?.length; i++) {
      for (let value of tempGroupQueryIndex) {
        /* istanbul ignore next */
        if (i === value) {
          transEntityRecords[i].txnNslAttribute[queryConditionIndex].txnGeneralEntity =
            transEntityRecords?.[i]?.txnNslAttribute?.[queryConditionIndex]?.values?.[0];

          transEntityRecords[i].txnNslAttribute[queryConditionIndex].values = [];
        }
      }
    }
  }

  // Return only txnGeneralEntity from NSL_Query_Condition
  return tempGroupTransEntityRecords.txnGeneralEntity;
}

/* istanbul ignore next */
export function convertFromToTransEntity(
  transEntityRecords: any,
  reservedCu: any,
  EQLVersion: any,
  queryInputOutputFields: any,
  queryFields: any,
  arithmeticFields: any,
  filterFields: any,
  caseFields: any,
  ccdRequest: any,
  eqlLabel: any,
  type: string,
  reservedCuType?: any
) {
  let txnNslAttribute = [];
  var tempTransEntityRecords = [];
  var tempGroupQueryIndex = [];
  var tempGroupQueryData = [];

  let caseTxnNslAttributeForIO: any = [];
  let nslCaseForIO = [];

  let caseTxnNslAttributeForCondition: any = [];
  let nslCaseForCondition = [];

  let formulaTxnNslAttributeForIO: any = [];
  let nslFormulaForIO = [];

  let formulaTxnNslAttributeForCondition: any = [];
  let nslFormulaForCondition = [];

  if (reservedCuType !== 'NSL_Template_BL_Query') {
    ccdRequest = setEntityId(reservedCu, ccdRequest, type);
  } else {
    ccdRequest = setEntityId(reservedCu, ccdRequest, 'NSL_Template_BL_Query');
  }
  var inputoutput: any = [];
  // For Input Output Block
  /* istanbul ignore next */
  if (EQLVersion !== '2.0' && EQLVersion !== '3.0' && reservedCuType !== 'NSL_Template_BL_Query') {
    inputoutput = transformQueryRecords(
      transEntityRecords.queryInputOutputArray,
      queryInputOutputFields,
      0,
      reservedCu,
      type
    );
  } else {
    queryInputOutputFields = [
      { name: 'nslFormula', display: eqlLabel?.NSL_Formula },
      { name: 'nslCase', display: eqlLabel?.NSL_Case },
      { name: 'sourceAttribute', display: eqlLabel?.Source_Attribute },
      { name: 'targetAttribute', display: eqlLabel?.Target_Attribute },
      { name: 'function', display: eqlLabel?.Function },
    ];
    /* istanbul ignore next */
    if (EQLVersion === '3.0') {
      queryInputOutputFields[4] = { name: 'nslFunction', display: eqlLabel?.NSL_Function };
    }

    inputoutput = transformQueryRecords(
      transEntityRecords.queryInputOutputArray,
      queryInputOutputFields,
      0,
      reservedCu,
      type
    );

    // Add EQL NSL function
    /* istanbul ignore next */
    if (EQLVersion === '3.0' && transEntityRecords?.nslFunction?.length > 0) {
      /* istanbul ignore next */
      transEntityRecords?.nslFunction.forEach((funcElem: any, funcIndex: number) => {
        /* istanbul ignore next */
        if (funcElem?.length > 0) {
          /* istanbul ignore next */
          let inputoutput_NSL_Function: any = inputoutput[funcIndex].txnNslAttribute[4];
          /* istanbul ignore next */
          inputoutput_NSL_Function.txnGeneralEntity = [];
          /* istanbul ignore next */
          inputoutput[funcIndex].txnNslAttribute[4] = createNSLFunction(
            funcElem,
            0,
            reservedCu,
            type,
            EQLVersion,
            eqlLabel
          );
        }
      });
    }
  }

  /* istanbul ignore next */
  if ((EQLVersion == '2.0' || EQLVersion === '3.0') && reservedCuType == 'NSL_Template_BL_Query') {
    queryInputOutputFields = [
      { name: 'sourceAttribute', display: eqlLabel?.Source_Attribute },
      { name: 'targetAttribute', display: eqlLabel?.Target_Attribute },
      { name: 'function', display: eqlLabel?.Function_Name },
    ];

    inputoutput = transformQueryRecords(
      transEntityRecords.queryInputOutputArray,
      queryInputOutputFields,
      0,
      reservedCu,
      type
    );
  }

  // For NSL Case in Query Input Output
  let caseForInputOutput = createNSLCase(
    transEntityRecords,
    queryFields,
    reservedCu,
    type,
    'inputoutput',
    caseFields,
    arithmeticFields,
    EQLVersion,
    eqlLabel
  );
  transEntityRecords = caseForInputOutput.transEntityRecords;
  caseTxnNslAttributeForIO = caseForInputOutput.caseTxnNslAttribute;
  nslCaseForIO = caseForInputOutput.nslCaseRecords;

  // For NSL Case in Condition Filter
  let caseForCondition = createNSLCase(
    transEntityRecords,
    queryFields,
    reservedCu,
    type,
    'condition',
    caseFields,
    arithmeticFields,
    EQLVersion,
    eqlLabel
  );
  transEntityRecords = caseForCondition.transEntityRecords;
  caseTxnNslAttributeForCondition = caseForCondition.caseTxnNslAttribute;
  nslCaseForCondition = caseForCondition.nslCaseRecords;

  // For NSL Formula in Query Input Output
  let formulaForInputOutput = createNSLFormula(
    transEntityRecords,
    arithmeticFields,
    reservedCu,
    type,
    'inputoutput',
    EQLVersion,
    eqlLabel
  );
  transEntityRecords = formulaForInputOutput.transEntityRecords;
  formulaTxnNslAttributeForIO = formulaForInputOutput.formulaTxnNslAttribute;
  nslFormulaForIO = formulaForInputOutput.nslFormula;

  // For NSL Formula in Condition Filter
  let formulaForCondition = createNSLFormula(
    transEntityRecords,
    arithmeticFields,
    reservedCu,
    type,
    'condition',
    EQLVersion,
    eqlLabel
  );
  formulaTxnNslAttributeForCondition = formulaForCondition.formulaTxnNslAttribute;

  // For QueryData of transEntityRecords
  /* istanbul ignore next */
  for (var i = 0; i < transEntityRecords?.queryData?.isGroupQuery?.length; i++) {
    // For Rule
    if (!transEntityRecords?.queryData?.isGroupQuery?.[i]) {
      tempTransEntityRecords.push(transEntityRecords.queryData?.[i]);
    }
    // For RuleSet Block
    else {
      var tempGroupTransEntityRecord = {
        attributeName: '',
        attributeValue: '',
        nslQuery: '',
        predicate: transEntityRecords.queryData[i].predicate
          ? transEntityRecords.queryData[i].predicate
          : transEntityRecords.queryData.isGroupQuery.length - 1 !== i
          ? transEntityRecords.queryData?.toggle?.[i] === true
            ? 'OR'
            : 'AND'
          : '',
        queryCondition: '',
        queryOperator: '',
      };
      tempTransEntityRecords.push(tempGroupTransEntityRecord);
      tempGroupQueryIndex.push(i);
      tempGroupQueryData.push(transEntityRecords?.queryData?.[i]);
    }
  }

  var query: any = [];
  /* istanbul ignore next */
  if ((EQLVersion === '2.0' || EQLVersion === '3.0') && reservedCuType !== 'NSL_Template_BL_Query') {
    queryFields = [
      { name: 'functionName', display: eqlLabel?.Function_Name },
      { name: 'nslFormula', display: eqlLabel?.NSL_Formula },
      { name: 'attributeName', display: eqlLabel?.Attribute_Name },
      { name: 'queryOperator', display: eqlLabel?.Query_Operator },
      { name: 'attributeValue', display: eqlLabel?.Attribute_Value },
      { name: 'nslQuery', display: eqlLabel?.NSL_Query },
      { name: 'queryCondition', display: eqlLabel?.NSL_Query_Condition },
      { name: 'nslCase', display: eqlLabel?.NSL_Case },
      { name: 'predicate', display: eqlLabel?.Predicate },
    ];
    /* istanbul ignore next */
    if (EQLVersion === '3.0') {
      queryFields[0] = { name: 'nslFunction', display: eqlLabel?.NSL_Function };
    }
  } /* istanbul ignore next */ else {
    queryFields = [
      { name: 'attributeName', display: eqlLabel?.Attribute_Name },
      { name: 'queryOperator', display: eqlLabel?.Query_Operator },
      { name: 'attributeValue', display: eqlLabel?.Attribute_Value },
      { name: 'nslQuery', display: eqlLabel?.NSL_Query },
      { name: 'queryCondition', display: eqlLabel?.NSL_Query_Condition },
      { name: 'predicate', display: eqlLabel?.Predicate },
    ];
  }

  if (EQLVersion === '2.0' && reservedCuType == 'NSL_Template_BL_Query') {
    queryFields = [
      { name: 'attributeName', display: eqlLabel?.Attribute_Name },
      { name: 'attributeDataType', display: 'Attribute_DataType' },
      { name: 'queryOperator', display: eqlLabel?.Query_Operator },
      { name: 'attributeValue', display: eqlLabel?.Attribute_Value },
      { name: 'predicate', display: eqlLabel?.Predicate },
    ];
  }
  //remove predicate from last index of query data
  /* istanbul ignore next */
  if (tempTransEntityRecords?.length > 0) {
    tempTransEntityRecords[tempTransEntityRecords?.length - 1].predicate = null;
    query = transformQueryRecords(tempTransEntityRecords, queryFields, 1, reservedCu, type);
  }

  // For Filter
  let filterData: any;
  filterData = [];
  /* istanbul ignore next */
  if (transEntityRecords?.filterData) {
    transEntityRecords?.filterData?.forEach((filterDataArray: any) => {
      filterDataArray?.forEach((filter: any) => {
        /* istanbul ignore next */
        if (filter.type === 'LIMIT' || filter.type === 'GROUP') {
          filter.value = '';
        }
        /* istanbul ignore next */
        if (filter?.filterAttributeValue !== '') {
          filterData?.push(filter);
        }
      });
    });
  }

  /* istanbul ignore next */
  if (EQLVersion == '2.0') {
    filterFields = [
      { name: 'type', display: eqlLabel?.Type },
      { name: 'value', display: eqlLabel?.Value },
      { name: 'filterAttributeValue', display: eqlLabel?.Filter_Attribute_Value },
      { name: 'nslCase', display: eqlLabel?.NSL_Case },
    ];
  }
  if (EQLVersion === '3.0') {
    filterFields = [
      { name: 'type', display: eqlLabel?.Type },
      { name: 'value', display: eqlLabel?.Value },
      { name: 'filterAttributeValue', display: eqlLabel?.Filter_Attribute_Value },
      { name: 'nslCase', display: eqlLabel?.NSL_Case },
      { name: 'nslFunction', display: eqlLabel?.NSL_Function },
    ];
  }

  if (reservedCuType == 'NSL_Template_BL_Query') {
    filterFields = [
      { name: 'type', display: eqlLabel?.Type },
      { name: 'value', display: eqlLabel?.Value },
      { name: 'filterAttributeValue', display: eqlLabel?.Filter_Attribute_Value },
    ];
  }

  var filter = transformQueryRecords(filterData, filterFields, EQLVersion === '3.0' ? 3 : 2, reservedCu, type);

  txnNslAttribute = [];
  let tempEntname =
    reservedCuType === 'NSL_Template_BL_Query' ? 'NSL_BL_Query_Input_Output' : eqlLabel?.NSL_Query_Input_Output;
  txnNslAttribute.push(extractSubEntityId(reservedCu, tempEntname, inputoutput, type, EQLVersion, eqlLabel));

  //push case input output in the final json
  /* istanbul ignore next */
  for (i = 0; i < caseTxnNslAttributeForIO?.length; i++) {
    if (caseTxnNslAttributeForIO?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0) {
      txnNslAttribute[0].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[1] = caseTxnNslAttributeForIO[i];
    }
  }

  //push formula input output in the final json
  /* istanbul ignore next */
  for (i = 0; i < nslFormulaForIO.length; i++) {
    if (nslFormulaForIO?.[i]?.length > 0) {
      txnNslAttribute[0].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[0] = formulaTxnNslAttributeForIO[i];
    }
  }

  tempEntname = reservedCuType === 'NSL_Template_BL_Query' ? 'NSL_BL_Query_Condition' : eqlLabel?.NSL_Query_Condition;
  txnNslAttribute.push(extractSubEntityId(reservedCu, tempEntname, query, type, EQLVersion, eqlLabel));

  /* istanbul ignore next */
  let queryConditionIndex = EQLVersion === '2.0' || EQLVersion === '3.0' ? 6 : 4;
  // Rules of RuleSet Block
  /* istanbul ignore next */
  for (let i = 0; i < query.length; i++) {
    for (let j = 0; j < tempGroupQueryIndex.length; j++) {
      /* istanbul ignore next */
      if (i === tempGroupQueryIndex[j]) {
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[
          queryConditionIndex
        ].txnGeneralEntity = '';
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[
          queryConditionIndex
        ].txnGeneralEntity = transformGroupQueryData(
          tempGroupQueryData?.[j],
          reservedCu,
          EQLVersion,
          queryFields,
          type,
          eqlLabel,
          reservedCuType
        );
      }
    }
  }

  //push formula Condition in the final json
  /* istanbul ignore next */
  for (i = 0; i < formulaTxnNslAttributeForCondition.length; i++) {
    if (!formulaTxnNslAttributeForCondition?.[i]?.length) {
      if (
        formulaTxnNslAttributeForCondition?.[i]?.length !== 0 &&
        formulaTxnNslAttributeForCondition?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0
      ) {
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[1] =
          formulaTxnNslAttributeForCondition[i];
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[2].values = [];
      }
    } else {
      for (let j = 0; j < formulaTxnNslAttributeForCondition[i].length; j++) {
        pushNestedFormulaInTxn(
          formulaTxnNslAttributeForCondition[i],
          txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[6].txnGeneralEntity
            .transEntityRecords
        );
      }
    }
  }

  //push case condition in the final json
  /* istanbul ignore next */
  for (i = 0; i < caseTxnNslAttributeForCondition?.length; i++) {
    if (!caseTxnNslAttributeForCondition?.[i]?.length) {
      if (
        caseTxnNslAttributeForCondition?.[i]?.length !== 0 &&
        caseTxnNslAttributeForCondition?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0
      ) {
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[7] =
          caseTxnNslAttributeForCondition[i];
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[2].values = [];
      }
    } else {
      for (let j = 0; j < caseTxnNslAttributeForCondition[i].length; j++) {
        pushNestedCaseInTxn(
          caseTxnNslAttributeForCondition[i],
          txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[6].txnGeneralEntity
            .transEntityRecords
        );
      }
    }
  }

  if (EQLVersion === '3.0') {
    /* istanbul ignore next */
    transEntityRecords?.queryData?.forEach((condition: any, i: any) => {
      /* istanbul ignore next */
      if (!condition.length) {
        if (
          condition?.nslFunctionForCondition?.[0]?.functionName &&
          condition?.nslFunctionForCondition?.[0]?.functionParams?.[0]?.functionAttribute
        ) {
          /* istanbul ignore next */
          let query_NSL_Function: any = query[i].txnNslAttribute[0];
          /* istanbul ignore next */
          query_NSL_Function.txnGeneralEntity = [];
          /* istanbul ignore next */
          query[i].txnNslAttribute[0] = createNSLFunction(
            condition?.nslFunctionForCondition,
            0,
            reservedCu,
            type,
            EQLVersion,
            eqlLabel
          );
          if (query[i].txnNslAttribute[0].txnGeneralEntity) {
            query[i].txnNslAttribute[2].values = [];
          }
        }
      } else {
        condition?.forEach((nestedCondition: any, j: any) => {
          createNestedNSLFunction(
            nestedCondition,
            query[i].txnNslAttribute[6].txnGeneralEntity.transEntityRecords[j],
            i,
            reservedCu,
            type,
            EQLVersion,
            eqlLabel
          );
        });
      }
    });
  }
  /* istanbul ignore next */
  if (EQLVersion === '3.0') {
    let grouByFields = [
      { name: 'GroupbyAttribute', display: eqlLabel?.Group_by_Attribute },
      { name: 'nslFunction', display: eqlLabel?.NSL_Function },
      { name: 'nslHavingClause', display: eqlLabel?.NSL_Having_Clause },
    ];
    if (transEntityRecords?.groupbyAggregate?.[0]?.GroupbyAttribute?.length == 0) {
      transEntityRecords.groupbyAggregate = [];
    }
    var groupbyAggregate = transformQueryRecords(
      transEntityRecords?.groupbyAggregate,
      grouByFields,
      2,
      reservedCu,
      type
    );
    if (transEntityRecords?.groupbyAggregate?.[0]?.GroupbyAttribute?.length > 0) {
      groupbyAggregate[0].txnNslAttribute[1].values = [];
      if (transEntityRecords?.groupbyAggregate?.[0]?.queryData?.length > 0) {
        groupbyAggregate[0].txnNslAttribute[2].txnGeneralEntity = transformQueryRecordsForNSLHaving(
          transEntityRecords.groupbyAggregate,
          queryFields,
          reservedCu,
          type,
          EQLVersion,
          eqlLabel
        );
        groupbyAggregate[0].txnNslAttribute[2].txnGeneralEntity.transEntityRecords.forEach(
          (record: any, index: any) => {
            if (record?.txnNslAttribute?.[2]?.values?.[0] == 'function') {
              transEntityRecords.groupbyAggregate?.[0]?.nslFunction.forEach((funcElem: any, funcIndex: number) => {
                /* istanbul ignore next */
                if (funcIndex == index) {
                  groupbyAggregate[0].txnNslAttribute[2].txnGeneralEntity.transEntityRecords[
                    index
                  ].txnNslAttribute[0] = createNSLFunction(funcElem, 0, reservedCu, type, EQLVersion, eqlLabel);
                  groupbyAggregate[0].txnNslAttribute[2].txnGeneralEntity.transEntityRecords[
                    index
                  ].txnNslAttribute[2].values = [];
                }
              });
            }
          }
        );
      }
    }
    txnNslAttribute.push(
      extractSubEntityId(reservedCu, eqlLabel.NSL_Group_By_Aggregate, groupbyAggregate, type, EQLVersion, eqlLabel)
    );
  }

  let filterName = reservedCuType === 'NSL_Template_BL_Query' ? eqlLabel?.NSL_BL_Query_Filter : eqlLabel?.NSL_Filter;
  txnNslAttribute.push(extractSubEntityId(reservedCu, filterName, filter, type, EQLVersion, eqlLabel));

  /* istanbul ignore next */
  if (EQLVersion === '3.0') {
    txnNslAttribute.push(extractSubEntityId(reservedCu, eqlLabel?.Combine_Type, [], type, EQLVersion, eqlLabel));
  }

  /* istanbul ignore next */
  if(txnNslAttribute && txnNslAttribute?.length > 0) {
    /* istanbul ignore next */
    txnNslAttribute?.forEach((record: any) => {
      /* istanbul ignore next */
      if(
        record &&
        record?.txnGeneralEntity &&
        (!record?.txnGeneralEntity?.transEntityRecords || record?.txnGeneralEntity?.transEntityRecords?.length === 0)
      ) {
        delete record.txnGeneralEntity;
      }
    });
  }

  return txnNslAttribute;
}

function pushNestedFormulaInTxn(formulaTxnNslAttributeForCondition: any, txnNslAttribute: any) {
  for (let i = 0; i < formulaTxnNslAttributeForCondition.length; i++) {
    if (!formulaTxnNslAttributeForCondition?.[i]?.length) {
      if (
        formulaTxnNslAttributeForCondition?.[i]?.length !== 0 &&
        formulaTxnNslAttributeForCondition?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0
      ) {
        txnNslAttribute[i].txnNslAttribute[1] = formulaTxnNslAttributeForCondition[i];
        txnNslAttribute[i].txnNslAttribute[2].values = [];
      }
    } else {
      for (let j = 0; j < formulaTxnNslAttributeForCondition[i].length; j++) {
        pushNestedFormulaInTxn(
          formulaTxnNslAttributeForCondition[i],
          txnNslAttribute[i].txnNslAttribute[6].txnGeneralEntity.transEntityRecords
        );
      }
    }
  }
}

function pushNestedCaseInTxn(caseTxnNslAttributeForCondition: any, txnNslAttribute: any) {
  for (let i = 0; i < caseTxnNslAttributeForCondition.length; i++) {
    if (!caseTxnNslAttributeForCondition?.[i]?.length) {
      if (
        caseTxnNslAttributeForCondition?.[i]?.length !== 0 &&
        caseTxnNslAttributeForCondition?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0
      ) {
        txnNslAttribute[i].txnNslAttribute[7] = caseTxnNslAttributeForCondition[i];
        txnNslAttribute[i].txnNslAttribute[2].values = [];
      }
    } else {
      for (let j = 0; j < caseTxnNslAttributeForCondition[i].length; j++) {
        pushNestedCaseInTxn(
          caseTxnNslAttributeForCondition[i],
          txnNslAttribute[i].txnNslAttribute[6].txnGeneralEntity.transEntityRecords
        );
      }
    }
  }
}

/* istanbul ignore next */
function createNSLFunction(
  nslFunction: any,
  entityIndex: any,
  reservedCu: any,
  type: any,
  EQLVersion: any,
  eqlLabel: any
) {
  let NSL_Function_transEntityRecords: any = [];
  let NSL_Function_Fields = [
    { name: 'functionName', display: eqlLabel?.Function_Name },
    { name: 'functionParams', display: eqlLabel?.NSL_Function_Parameters },
  ];

  NSL_Function_transEntityRecords = transformQueryRecords(
    nslFunction,
    NSL_Function_Fields,
    entityIndex,
    reservedCu,
    type
  );

  let Function_Parameters_transEntityRecords: any = [];
  let Function_Parameters_Fields = [
    { name: 'functionAttribute', display: eqlLabel?.Function_Attribute },
    { name: 'functionConstantValue', display: eqlLabel?.Function_Constant_Value },
    { name: 'NslFunction', display: eqlLabel?.NSL_Function },
    { name: 'functionOperator', display: eqlLabel?.Function_Operator },
  ];

  let functionParams: any = [];

  /* istanbul ignore next */
  nslFunction?.[0]?.functionParams?.forEach((parameter: any) => {
    /* istanbul ignore next */
    if (
      parameter?.functionAttribute ||
      parameter?.functionOperator ||
      parameter?.functionConstantValue ||
      parameter?.NslFunction?.length > 0
    ) {
      functionParams.push(parameter);
    }
  });

  Function_Parameters_transEntityRecords = transformQueryRecords(
    functionParams,
    Function_Parameters_Fields,
    entityIndex,
    reservedCu,
    type
  );

  /* istanbul ignore next */
  functionParams?.forEach((parameter: any, parameterIndex: number) => {
    /* istanbul ignore next */
    if (parameter?.NslFunction && parameter?.NslFunction?.length > 0) {
      /* istanbul ignore next */
      Function_Parameters_transEntityRecords[parameterIndex].txnNslAttribute[2] = createNSLFunction(
        parameter?.NslFunction,
        entityIndex,
        reservedCu,
        type,
        EQLVersion,
        eqlLabel
      );
    }
  });

  /* istanbul ignore next */
  if (NSL_Function_transEntityRecords?.[0]?.txnNslAttribute?.[1]) {
    NSL_Function_transEntityRecords[0].txnNslAttribute[1] = extractSubEntityId(
      reservedCu,
      eqlLabel?.NSL_Function_Parameters,
      Function_Parameters_transEntityRecords,
      type,
      EQLVersion,
      eqlLabel
    );
  }

  /* istanbul ignore next */
  return extractSubEntityId(
    reservedCu,
    eqlLabel?.NSL_Function,
    NSL_Function_transEntityRecords,
    type,
    EQLVersion,
    eqlLabel
  );
}

/* istanbul ignore next */
function createNSLCase(
  transEntityRecords: any,
  queryFields: any,
  reservedCu: any,
  type: any,
  stepper: any,
  caseFields: any,
  arithmeticFields: any,
  EQLVersion: any,
  eqlLabel: any
) {
  let caseQueryCondition: any = [];
  let caseThen: any = [];
  let caseElse: any = [];
  let caseTxnNslAttribute: any = [];
  let nslCaseRecords: any = [];
  /* istanbul ignore next */
  if (stepper === 'inputoutput') {
    for (let i = 0; i < transEntityRecords?.nslCase?.length; i++) {
      caseTxnNslAttribute.push([]);
      caseQueryCondition.push([]);
      caseThen.push([]);
      caseElse.push([]);
      for (let j = 0; j < transEntityRecords?.nslCase?.[i]?.length; j++) {
        let formulaInCaseTxnNslAttributeForIO: any = [];
        let nslFormulaInCaseForIO: any = [];
        let tempCase = [];
        tempCase.push(transEntityRecords.nslCase[i][j].nslQueryCondition);
        caseQueryCondition[caseQueryCondition.length - 1].push([]);
        caseQueryCondition[i][j] = transformNSLCaseRecords(tempCase, queryFields, 0, reservedCu, 'caseQuery');
        if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute?.[1]?.values?.length > 0) {
          let tempFormulaInCase: any = { nslFormula: [] };
          tempFormulaInCase.nslFormula.push(transEntityRecords.nslCase[i][j].nslQueryCondition.nslFormula);
          let formulaInCaseForInputOutput = createNSLFormulaInCase(
            tempFormulaInCase,
            arithmeticFields,
            reservedCu,
            type,
            'inputoutput',
            EQLVersion,
            eqlLabel
          );
          formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
          nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

          caseQueryCondition[i][j][0].txnNslAttribute[1].values = [];
          caseQueryCondition[i][j][0].txnNslAttribute[1].txnGeneralEntity = {};
          caseQueryCondition[i][j][0].txnNslAttribute[1].txnGeneralEntity =
            formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
        }

        if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute?.[0]?.values?.length > 0) {
          /* istanbul ignore next */
          caseQueryCondition[i][j][0].txnNslAttribute[0] = createNSLFunction(
            transEntityRecords.nslCase[i][j].nslQueryCondition?.nslFunction,
            0,
            reservedCu,
            type,
            EQLVersion,
            eqlLabel
          );
        }

        let caseThenFields = [
          { name: 'functionName', display: eqlLabel?.Function_Name },
          { name: 'attributeName', display: eqlLabel?.Attribute_Name },
          { name: 'nslQuery', display: eqlLabel?.NSL_Query },
          { name: 'nslFormula', display: eqlLabel?.NSL_Formula },
          { name: 'nslCase', display: eqlLabel?.NSL_Case },
        ];

        /* istanbul ignore next */
        if (EQLVersion === '3.0') {
          caseThenFields[0] = { name: 'nslFunction', display: eqlLabel?.NSL_Function };
        }
        tempCase = [];
        tempCase.push(transEntityRecords.nslCase[i][j].nslThen);
        caseThen[caseThen.length - 1].push([]);
        caseThen[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu, 'caseThen');
        if (caseThen?.[i]?.[j]?.[0]?.txnNslAttribute?.[3]?.values?.length > 0) {
          let tempFormulaInCase: any = { nslFormula: [] };
          tempFormulaInCase.nslFormula.push(transEntityRecords.nslCase[i][j].nslThen.nslFormula);
          let formulaInCaseForInputOutput = createNSLFormulaInCase(
            tempFormulaInCase,
            arithmeticFields,
            reservedCu,
            type,
            'inputoutput',
            EQLVersion,
            eqlLabel
          );
          formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
          nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

          caseThen[i][j][0].txnNslAttribute[3].values = [];
          caseThen[i][j][0].txnNslAttribute[3].txnGeneralEntity = {};
          caseThen[i][j][0].txnNslAttribute[3].txnGeneralEntity = formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
        }
        if (transEntityRecords.nslCase[i][j].nslElse.attributeName) {
          tempCase = [];
          tempCase.push(transEntityRecords.nslCase[i][j].nslElse);
          caseElse[caseElse.length - 1].push([]);
          caseElse[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu, 'caseElse');
          if (caseElse?.[i]?.[j]?.[0]?.txnNslAttribute?.[3]?.values?.length > 0) {
            let tempFormulaInCase: any = { nslFormula: [] };
            tempFormulaInCase.nslFormula.push(transEntityRecords.nslCase[i][j].nslElse.nslFormula);
            let formulaInCaseForInputOutput = createNSLFormulaInCase(
              tempFormulaInCase,
              arithmeticFields,
              reservedCu,
              type,
              'inputoutput',
              EQLVersion,
              eqlLabel
            );
            formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
            nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

            caseElse[i][j][0].txnNslAttribute[3].values = [];
            caseElse[i][j][0].txnNslAttribute[3].txnGeneralEntity = {};
            caseElse[i][j][0].txnNslAttribute[3].txnGeneralEntity =
              formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
          }
        }
        caseTxnNslAttribute[i].push([]);
        caseTxnNslAttribute[i][j] = { txnNslAttribute: [] };
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(
            reservedCu,
            eqlLabel?.NSL_Query_Condition,
            caseQueryCondition[i][j],
            type,
            EQLVersion,
            eqlLabel
          )
        );
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, eqlLabel?.NSL_Then, caseThen[i][j], type, EQLVersion, eqlLabel)
        );
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, eqlLabel?.NSL_Else, caseElse[i][j], type, EQLVersion, eqlLabel)
        );

        caseTxnNslAttribute[i][j].txnNslAttribute[2].values = [];
      }
    }

    var tempCaseTxnNslAttribute: any = [];
    /* istanbul ignore next */
    for (let i = 0; i < transEntityRecords?.nslCase?.length; i++) {
      nslCaseRecords.push([]);
      tempCaseTxnNslAttribute.push([]);
      for (let j = 0; j < caseTxnNslAttribute[i].length; j++) {
        nslCaseRecords[i] = transformNSLCaseRecords(caseTxnNslAttribute[i], caseFields, 1, reservedCu);
        tempCaseTxnNslAttribute[i] = extractSubEntityId(
          reservedCu,
          eqlLabel?.NSL_Case,
          nslCaseRecords[i],
          type,
          EQLVersion,
          eqlLabel
        );
        for (let z = 0; z < caseTxnNslAttribute[i].length; z++) {
          tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[z].txnNslAttribute[0].txnGeneralEntity =
            caseTxnNslAttribute[i][z].txnNslAttribute[0].txnGeneralEntity;
          tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[z].txnNslAttribute[1].txnGeneralEntity =
            caseTxnNslAttribute[i][z].txnNslAttribute[1].txnGeneralEntity;
          tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[z].txnNslAttribute[2].txnGeneralEntity =
            caseTxnNslAttribute[i][z].txnNslAttribute[2].txnGeneralEntity;
        }
      }
    }
  } else {
    var tempCaseTxnNslAttribute: any = [];
    transEntityRecords?.queryData?.forEach((condition: any, i: any) => {
      if (!condition.length) {
        if (condition.nslCaseForCondition) {
          caseTxnNslAttribute[i] = [];
          caseQueryCondition[i] = [];
          caseThen[i] = [];
          caseElse[i] = [];
          if (condition.nslCaseForCondition) {
            for (let j = 0; j < condition?.nslCaseForCondition?.length; j++) {
              let formulaInCaseTxnNslAttributeForIO: any = [];
              let nslFormulaInCaseForIO: any = [];
              let tempCase = [];
              tempCase.push(condition?.nslCaseForCondition[j].nslQueryCondition);
              caseQueryCondition[caseQueryCondition.length - 1].push([]);
              caseQueryCondition[i][j] = transformNSLCaseRecords(tempCase, queryFields, 0, reservedCu, 'caseQuery');

              if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute?.[1]?.values?.length > 0) {
                let tempFormulaInCase: any = { nslFormulaForCondition: [] };
                tempFormulaInCase.nslFormulaForCondition.push(
                  condition?.nslCaseForCondition[j].nslQueryCondition.nslFormula
                );
                let formulaInCaseForInputOutput = createNSLFormulaInCase(
                  tempFormulaInCase,
                  arithmeticFields,
                  reservedCu,
                  type,
                  'condition',
                  EQLVersion,
                  eqlLabel
                );
                formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
                nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

                caseQueryCondition[i][j][0].txnNslAttribute[1].values = [];
                caseQueryCondition[i][j][0].txnNslAttribute[1].txnGeneralEntity = {};
                caseQueryCondition[i][j][0].txnNslAttribute[1].txnGeneralEntity =
                  formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
              }
              if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute?.[0]?.values?.length > 0) {
                /* istanbul ignore next */
                caseQueryCondition[i][j][0].txnNslAttribute[0] = createNSLFunction(
                  transEntityRecords.queryData[i].nslCaseForCondition[j].nslQueryCondition?.nslFunction,
                  0,
                  reservedCu,
                  type,
                  EQLVersion,
                  eqlLabel
                );

                if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute[0]?.txnGeneralEntity) {
                  caseQueryCondition[i][j][0].txnNslAttribute[2].values = [];
                }
              }

              let caseThenFields = [
                { name: 'nslFunction', display: eqlLabel?.NSL_Function },
                { name: 'attributeName', display: eqlLabel?.Attribute_Name },
                { name: 'nslQuery', display: eqlLabel?.NSL_Query },
                { name: 'nslFormula', display: eqlLabel?.NSL_Formula },
                { name: 'nslCase', display: eqlLabel?.NSL_Case },
              ];
              /* istanbul ignore next */
              if (EQLVersion === '3.0') {
                caseThenFields[0] = { name: 'nslFunction', display: eqlLabel?.NSL_Function };
              }
              tempCase = [];
              tempCase.push(condition?.nslCaseForCondition[j].nslThen);
              caseThen[caseThen.length - 1].push([]);
              caseThen[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu, 'caseThen');

              if (caseThen?.[i]?.[j]?.[0]?.txnNslAttribute?.[3]?.values?.length > 0) {
                let tempFormulaInCase: any = { nslFormulaForCondition: [] };
                tempFormulaInCase.nslFormulaForCondition.push(condition?.nslCaseForCondition[j].nslThen.nslFormula);
                let formulaInCaseForInputOutput = createNSLFormulaInCase(
                  tempFormulaInCase,
                  arithmeticFields,
                  reservedCu,
                  type,
                  'condition',
                  EQLVersion,
                  eqlLabel
                );
                formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
                nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

                caseThen[i][j][0].txnNslAttribute[3].values = [];
                caseThen[i][j][0].txnNslAttribute[3].txnGeneralEntity = {};
                caseThen[i][j][0].txnNslAttribute[3].txnGeneralEntity =
                  formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
              }

              tempCase = [];
              tempCase.push(condition?.nslCaseForCondition[j].nslElse);
              caseElse[caseElse.length - 1].push([]);
              caseElse[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu, 'caseElse');

              if (caseElse?.[i]?.[j]?.[0]?.txnNslAttribute?.[3]?.values?.length > 0) {
                let tempFormulaInCase: any = { nslFormulaForCondition: [] };
                tempFormulaInCase.nslFormulaForCondition.push(condition?.nslCaseForCondition[j].nslElse.nslFormula);
                let formulaInCaseForInputOutput = createNSLFormulaInCase(
                  tempFormulaInCase,
                  arithmeticFields,
                  reservedCu,
                  type,
                  'condition',
                  EQLVersion,
                  eqlLabel
                );
                formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
                nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

                caseElse[i][j][0].txnNslAttribute[3].values = [];
                caseElse[i][j][0].txnNslAttribute[3].txnGeneralEntity = {};
                caseElse[i][j][0].txnNslAttribute[3].txnGeneralEntity =
                  formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
              }

              caseTxnNslAttribute[i].push([]);
              caseTxnNslAttribute[i][j] = { txnNslAttribute: [] };
              caseTxnNslAttribute[i][j].txnNslAttribute.push(
                extractSubEntityId(
                  reservedCu,
                  eqlLabel?.NSL_Query_Condition,
                  caseQueryCondition[i][j],
                  type,
                  EQLVersion,
                  eqlLabel
                )
              );
              caseTxnNslAttribute[i][j].txnNslAttribute.push(
                extractSubEntityId(reservedCu, eqlLabel?.NSL_Then, caseThen[i][j], type, EQLVersion, eqlLabel)
              );
              caseTxnNslAttribute[i][j].txnNslAttribute.push(
                extractSubEntityId(reservedCu, eqlLabel?.NSL_Else, caseElse[i][j], type, EQLVersion, eqlLabel)
              );

              caseTxnNslAttribute[i][j].txnNslAttribute[2].values = [];
            }
          }
          /* istanbul ignore next */
          nslCaseRecords[i] = [];
          tempCaseTxnNslAttribute[i] = [];
          for (let j = 0; j < caseTxnNslAttribute?.[i]?.length; j++) {
            nslCaseRecords[i].push(transformNSLCaseRecords(caseTxnNslAttribute[i], caseFields, 0, reservedCu));
            tempCaseTxnNslAttribute[i] = extractSubEntityId(
              reservedCu,
              eqlLabel?.NSL_Case,
              nslCaseRecords[i][j],
              type,
              EQLVersion,
              eqlLabel
            );
            tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords = caseTxnNslAttribute[i];
          }
        }
      } else {
        nslCaseRecords[i] = [];
        tempCaseTxnNslAttribute[i] = [];
        tempCaseTxnNslAttribute[i] = createNestedNSLCase(
          condition,
          caseFields,
          queryFields,
          arithmeticFields,
          reservedCu,
          type,
          eqlLabel,
          EQLVersion,
          nslCaseRecords[i],
          tempCaseTxnNslAttribute[i]
        );
      }
    });
  }

  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    caseTxnNslAttribute: tempCaseTxnNslAttribute,
    nslCaseRecords: nslCaseRecords,
  };
  return tempReturnValue;
}

/* istanbul ignore next */
export function createNSLFormulaInCase(
  transEntityRecords: any,
  arithmeticFields: any,
  reservedCu: any,
  type: any,
  stepper: any,
  EQLVersion: any,
  eqlLabel: any
) {
  let formulaTxnNslAttribute: any = [];
  // For NSL Formula in Query Input Output
  var nslFormula = [];
  /* istanbul ignore next */
  if (stepper === 'inputoutput') {
    for (var i = 0; i < transEntityRecords?.nslFormula?.length; i++) {
      if (transEntityRecords?.nslFormula[i] !== null || transEntityRecords?.nslFormula?.[i]?.length !== 0) {
        nslFormula.push([]);
        nslFormula[i] = transformNSLFormulaRecords(
          transEntityRecords?.nslFormula?.[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
        nslFormula?.[i]?.forEach((formula1: any, j: any) => {
          if (formula1.txnNslAttribute?.[0]?.values?.length > 0) {
            /* istanbul ignore next */
            formula1.txnNslAttribute[0] = createNSLFunction(
              transEntityRecords.nslFormula?.[i]?.[j]?.nslFunction,
              0,
              reservedCu,
              type,
              EQLVersion,
              eqlLabel
            );
          }
        });
      } else {
        transEntityRecords.nslFormula.push();
      }
      formulaTxnNslAttribute.push([]);
      if (nslFormula[i].length > 0) {
        formulaTxnNslAttribute[i] = extractSubEntityId(
          reservedCu,
          eqlLabel?.NSL_Formula,
          nslFormula[i],
          type,
          EQLVersion,
          eqlLabel
        );
      }
    }
  } else {
    for (var i = 0; i < transEntityRecords?.nslFormulaForCondition?.length; i++) {
      if (
        transEntityRecords?.nslFormulaForCondition[i] !== null ||
        transEntityRecords?.nslFormulaForCondition?.[i]?.length !== 0
      ) {
        nslFormula.push([]);
        nslFormula[i] = transformNSLFormulaRecords(
          transEntityRecords?.nslFormulaForCondition?.[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
        nslFormula?.[i]?.forEach((formula1: any, j: any) => {
          if (formula1.txnNslAttribute?.[0]?.values?.length > 0) {
            /* istanbul ignore next */
            formula1.txnNslAttribute[0] = createNSLFunction(
              transEntityRecords.nslFormulaForCondition?.[i]?.[j]?.nslFunction,
              0,
              reservedCu,
              type,
              EQLVersion,
              eqlLabel
            );
          }
        });
      } else {
        transEntityRecords.nslFormulaForCondition.push([]);
      }
      formulaTxnNslAttribute.push([]);
      if (nslFormula[i].length > 0) {
        formulaTxnNslAttribute[i] = extractSubEntityId(
          reservedCu,
          eqlLabel?.NSL_Formula,
          nslFormula[i],
          type,
          EQLVersion,
          eqlLabel
        );
      }
    }
  }
  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    formulaTxnNslAttribute: formulaTxnNslAttribute,
    nslFormula: nslFormula,
  };
  return tempReturnValue;
}

/* istanbul ignore next */
function createNSLFormula(
  transEntityRecords: any,
  arithmeticFields: any,
  reservedCu: any,
  type: any,
  stepper: any,
  EQLVersion: any,
  eqlLabel: any
) {
  let formulaTxnNslAttribute: any = [];
  // For NSL Formula in Query Input Output
  var nslFormula: any = [];
  /* istanbul ignore next */
  if (stepper === 'inputoutput') {
    for (var i = 0; i < transEntityRecords?.nslFormula?.length; i++) {
      if (transEntityRecords?.nslFormula[i] !== null || transEntityRecords?.nslFormula?.[i]?.length !== 0) {
        nslFormula.push([]);
        nslFormula[i] = transformNSLFormulaRecords(
          transEntityRecords?.nslFormula?.[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
        nslFormula?.[i]?.forEach((formula1: any, j: any) => {
          if (formula1.txnNslAttribute?.[0]?.values?.length > 0) {
            /* istanbul ignore next */
            formula1.txnNslAttribute[0] = createNSLFunction(
              transEntityRecords.nslFormula?.[i]?.[j]?.nslFunction,
              0,
              reservedCu,
              type,
              EQLVersion,
              eqlLabel
            );

            if (formula1?.txnNslAttribute[0]?.txnGeneralEntity) {
              formula1.txnNslAttribute[1].values = [];
            }
          }
        });
      } else {
        transEntityRecords.nslFormula.push();
      }
      formulaTxnNslAttribute.push([]);
      if (nslFormula[i].length > 0) {
        formulaTxnNslAttribute[i] = extractSubEntityId(
          reservedCu,
          eqlLabel?.NSL_Formula,
          nslFormula[i],
          type,
          EQLVersion,
          eqlLabel
        );
      }
    }
  } else {
    transEntityRecords?.queryData.forEach((query: any, i: any) => {
      if (!query.length) {
        if (query.nslFormulaForCondition) {
          nslFormula[i] = [];
          nslFormula[i] = transformNSLFormulaRecords(
            query?.nslFormulaForCondition,
            arithmeticFields,
            0,
            reservedCu,
            type
          );
          nslFormula?.[i]?.forEach((formula1: any, j: any) => {
            if (formula1.txnNslAttribute?.[0]?.values?.length > 0) {
              /* istanbul ignore next */
              formula1.txnNslAttribute[0] = createNSLFunction(
                query.nslFormulaForCondition?.[j]?.nslFunction,
                0,
                reservedCu,
                type,
                EQLVersion,
                eqlLabel
              );

              if (formula1?.txnNslAttribute[0]?.txnGeneralEntity) {
                formula1.txnNslAttribute[1].values = [];
              }
            }
          });
          formulaTxnNslAttribute.push([]);
          if (nslFormula?.[i]?.length > 0) {
            formulaTxnNslAttribute[i] = extractSubEntityId(
              reservedCu,
              eqlLabel?.NSL_Formula,
              nslFormula[i],
              type,
              EQLVersion,
              eqlLabel
            );
          }
        }
      } else {
        formulaTxnNslAttribute[i] = createNestedNSLFormula(
          query,
          arithmeticFields,
          reservedCu,
          type,
          eqlLabel,
          EQLVersion
        );
      }
    });
  }
  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    formulaTxnNslAttribute: formulaTxnNslAttribute,
    nslFormula: nslFormula,
  };
  return tempReturnValue;
}

function createNestedNSLFunction(
  queryData: any,
  query: any,
  index: any,
  reservedCu: any,
  type: any,
  EQLVersion: any,
  eqlLabel: any
) {
  /* istanbul ignore next */
  if (!queryData.length) {
    if (
      queryData?.nslFunctionForCondition?.functionName &&
      queryData?.nslFunctionForCondition?.functionParams?.[0]?.functionAttribute
    ) {
      let tempVal = JSON.parse(JSON.stringify(queryData?.nslFunctionForCondition));
      queryData.nslFunctionForCondition = [];
      queryData.nslFunctionForCondition.push(tempVal);
    }
    if (
      queryData?.nslFunctionForCondition?.[0]?.functionName &&
      queryData?.nslFunctionForCondition?.[0]?.functionParams?.[0]?.functionAttribute
    ) {
      /* istanbul ignore next */
      let query_NSL_Function: any = query.txnNslAttribute[0];
      /* istanbul ignore next */
      query_NSL_Function.txnGeneralEntity = [];
      /* istanbul ignore next */
      query.txnNslAttribute[0] = createNSLFunction(
        queryData?.nslFunctionForCondition,
        0,
        reservedCu,
        type,
        EQLVersion,
        eqlLabel
      );
      if (query.txnNslAttribute[0].txnGeneralEntity) {
        query.txnNslAttribute[2].values = [];
      }
    }
  } else {
    queryData?.forEach((nestedCondition: any, i: any) => {
      createNestedNSLFunction(
        nestedCondition,
        query.txnNslAttribute[6].txnGeneralEntity.transEntityRecords[i],
        i,
        reservedCu,
        type,
        EQLVersion,
        eqlLabel
      );
    });
  }
}
function createNestedNSLFormula(
  condition: any,
  arithmeticFields: any,
  reservedCu: any,
  type: any,
  eqlLabel: any,
  EQLVersion: any
) {
  let formulaTxnNslAttribute: any = [];
  var nslFormula: any = [];
  condition?.forEach((query: any, i: any) => {
    if (!query.length) {
      if (query.nslFormulaForCondition) {
        nslFormula[i] = [];
        nslFormula[i] = transformNSLFormulaRecords(
          query?.nslFormulaForCondition,
          arithmeticFields,
          0,
          reservedCu,
          type
        );
        nslFormula?.[i]?.forEach((formula1: any, j: any) => {
          if (formula1.txnNslAttribute?.[0]?.values?.length > 0) {
            /* istanbul ignore next */
            formula1.txnNslAttribute[0] = createNSLFunction(
              query.nslFormulaForCondition?.[j]?.nslFunction,
              0,
              reservedCu,
              type,
              EQLVersion,
              eqlLabel
            );

            if (formula1?.txnNslAttribute[0]?.txnGeneralEntity) {
              formula1.txnNslAttribute[1].values = [];
            }
          }
        });
        formulaTxnNslAttribute.push([]);
        if (nslFormula?.[i]?.length > 0) {
          formulaTxnNslAttribute[i] = extractSubEntityId(
            reservedCu,
            eqlLabel?.NSL_Formula,
            nslFormula[i],
            type,
            EQLVersion,
            eqlLabel
          );
        }
      }
    } else {
      formulaTxnNslAttribute[i] = createNestedNSLFormula(
        query,
        arithmeticFields,
        reservedCu,
        type,
        eqlLabel,
        EQLVersion
      );
    }
  });
  return formulaTxnNslAttribute;
}

function createNestedNSLCase(
  queryData: any,
  caseFields: any,
  queryFields: any,
  arithmeticFields: any,
  reservedCu: any,
  type: any,
  eqlLabel: any,
  EQLVersion: any,
  nslCaseRecords: any,
  tempCaseTxnNslAttribute: any
) {
  let caseQueryCondition: any = [];
  let caseThen: any = [];
  let caseElse: any = [];
  let caseTxnNslAttribute: any = [];
  queryData?.forEach((condition: any, i: any) => {
    if (!condition.length) {
      caseTxnNslAttribute[i] = [];
      caseQueryCondition[i] = [];
      caseThen[i] = [];
      caseElse[i] = [];
      if (condition.nslCaseForCondition) {
        for (let j = 0; j < condition?.nslCaseForCondition?.length; j++) {
          let formulaInCaseTxnNslAttributeForIO: any = [];
          let nslFormulaInCaseForIO: any = [];
          let tempCase = [];
          tempCase.push(condition?.nslCaseForCondition[j].nslQueryCondition);
          caseQueryCondition[caseQueryCondition.length - 1].push([]);
          caseQueryCondition[i][j] = transformNSLCaseRecords(tempCase, queryFields, 0, reservedCu, 'caseQuery');

          if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute?.[1]?.values?.length > 0) {
            let tempFormulaInCase: any = { nslFormulaForCondition: [] };
            tempFormulaInCase.nslFormulaForCondition.push(
              condition?.nslCaseForCondition[j].nslQueryCondition.nslFormula
            );
            let formulaInCaseForInputOutput = createNSLFormulaInCase(
              tempFormulaInCase,
              arithmeticFields,
              reservedCu,
              type,
              'condition',
              EQLVersion,
              eqlLabel
            );
            formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
            nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

            caseQueryCondition[i][j][0].txnNslAttribute[1].values = [];
            caseQueryCondition[i][j][0].txnNslAttribute[1].txnGeneralEntity = {};
            caseQueryCondition[i][j][0].txnNslAttribute[1].txnGeneralEntity =
              formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
          }
          if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute?.[0]?.values?.length > 0) {
            /* istanbul ignore next */
            caseQueryCondition[i][j][0].txnNslAttribute[0] = createNSLFunction(
              queryData[i].nslCaseForCondition[j].nslQueryCondition?.nslFunction,
              0,
              reservedCu,
              type,
              EQLVersion,
              eqlLabel
            );

            if (caseQueryCondition?.[i]?.[j]?.[0]?.txnNslAttribute[0]?.txnGeneralEntity) {
              caseQueryCondition[i][j][0].txnNslAttribute[2].values = [];
            }
          }

          let caseThenFields = [
            { name: 'nslFunction', display: eqlLabel?.NSL_Function },
            { name: 'attributeName', display: eqlLabel?.Attribute_Name },
            { name: 'nslQuery', display: eqlLabel?.NSL_Query },
            { name: 'nslFormula', display: eqlLabel?.NSL_Formula },
            { name: 'nslCase', display: eqlLabel?.NSL_Case },
          ];
          /* istanbul ignore next */
          if (EQLVersion === '3.0') {
            caseThenFields[0] = { name: 'nslFunction', display: eqlLabel?.NSL_Function };
          }
          tempCase = [];
          tempCase.push(condition?.nslCaseForCondition[j].nslThen);
          caseThen[caseThen.length - 1].push([]);
          caseThen[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu, 'caseThen');

          if (caseThen?.[i]?.[j]?.[0]?.txnNslAttribute?.[3]?.values?.length > 0) {
            let tempFormulaInCase: any = { nslFormulaForCondition: [] };
            tempFormulaInCase.nslFormulaForCondition.push(condition?.nslCaseForCondition[j].nslThen.nslFormula);
            let formulaInCaseForInputOutput = createNSLFormulaInCase(
              tempFormulaInCase,
              arithmeticFields,
              reservedCu,
              type,
              'condition',
              EQLVersion,
              eqlLabel
            );
            formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
            nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

            caseThen[i][j][0].txnNslAttribute[3].values = [];
            caseThen[i][j][0].txnNslAttribute[3].txnGeneralEntity = {};
            caseThen[i][j][0].txnNslAttribute[3].txnGeneralEntity =
              formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
          }

          tempCase = [];
          tempCase.push(condition?.nslCaseForCondition[j].nslElse);
          caseElse[caseElse.length - 1].push([]);
          caseElse[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu, 'caseElse');

          if (caseElse?.[i]?.[j]?.[0]?.txnNslAttribute?.[3]?.values?.length > 0) {
            let tempFormulaInCase: any = { nslFormulaForCondition: [] };
            tempFormulaInCase.nslFormulaForCondition.push(condition?.nslCaseForCondition[j].nslElse.nslFormula);
            let formulaInCaseForInputOutput = createNSLFormulaInCase(
              tempFormulaInCase,
              arithmeticFields,
              reservedCu,
              type,
              'condition',
              EQLVersion,
              eqlLabel
            );
            formulaInCaseTxnNslAttributeForIO = formulaInCaseForInputOutput.formulaTxnNslAttribute;
            nslFormulaInCaseForIO = formulaInCaseForInputOutput.nslFormula;

            caseElse[i][j][0].txnNslAttribute[3].values = [];
            caseElse[i][j][0].txnNslAttribute[3].txnGeneralEntity = {};
            caseElse[i][j][0].txnNslAttribute[3].txnGeneralEntity =
              formulaInCaseTxnNslAttributeForIO[0].txnGeneralEntity;
          }

          caseTxnNslAttribute[i].push([]);
          caseTxnNslAttribute[i][j] = { txnNslAttribute: [] };
          caseTxnNslAttribute[i][j].txnNslAttribute.push(
            extractSubEntityId(
              reservedCu,
              eqlLabel?.NSL_Query_Condition,
              caseQueryCondition[i][j],
              type,
              EQLVersion,
              eqlLabel
            )
          );
          caseTxnNslAttribute[i][j].txnNslAttribute.push(
            extractSubEntityId(reservedCu, eqlLabel?.NSL_Then, caseThen[i][j], type, EQLVersion, eqlLabel)
          );
          caseTxnNslAttribute[i][j].txnNslAttribute.push(
            extractSubEntityId(reservedCu, eqlLabel?.NSL_Else, caseElse[i][j], type, EQLVersion, eqlLabel)
          );

          caseTxnNslAttribute[i][j].txnNslAttribute[2].values = [];
        }
      }
      /* istanbul ignore next */
      nslCaseRecords ? nslCaseRecords.push([]) : (nslCaseRecords = []);
      tempCaseTxnNslAttribute ? tempCaseTxnNslAttribute.push([]) : (tempCaseTxnNslAttribute = []);
      for (let j = 0; j < caseTxnNslAttribute[i].length; j++) {
        nslCaseRecords[i].push(transformNSLCaseRecords(caseTxnNslAttribute[i], caseFields, 0, reservedCu));
        tempCaseTxnNslAttribute[i] = extractSubEntityId(
          reservedCu,
          eqlLabel?.NSL_Case,
          nslCaseRecords[i][j],
          type,
          EQLVersion,
          eqlLabel
        );
        tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords = caseTxnNslAttribute[i];
      }
    } else {
      nslCaseRecords[i] = [];
      tempCaseTxnNslAttribute[i] = [];
      tempCaseTxnNslAttribute[i] = createNestedNSLCase(
        condition,
        caseFields,
        queryFields,
        arithmeticFields,
        reservedCu,
        type,
        eqlLabel,
        EQLVersion,
        nslCaseRecords[i],
        tempCaseTxnNslAttribute[i]
      );
    }
  });
  return tempCaseTxnNslAttribute;
}

/* istanbul ignore next */
export function deleteInputOutputRow(transEntityRecords: any, rowIndex: number, mapsource: boolean) {
  /* istanbul ignore next */
  // let attrToDelete = transEntityRecords?.queryInputOutputArray?.[rowIndex]?.sourceAttribute;
  /* istanbul ignore next */
  // let actualAttrIndex = transEntityRecords?.attributesList?.findIndex((x: any) => x === attrToDelete);
  /* istanbul ignore next */
  // let tempAttr = transEntityRecords.queryInputOutputArray[rowIndex].sourceAttribute;
  // let noEntityFound;
  transEntityRecords.queryInputOutputArray.splice(rowIndex, 1);

  // if (tempAttr) {
  //   let tempSplittedAttr = tempAttr.split('.', 1);

  //   noEntityFound = transEntityRecords.queryInputOutputArray.find((element: any) => {
  //     if (element?.sourceAttribute?.includes(tempSplittedAttr)) {
  //       return true;
  //     }
  //   });

  //   if (!noEntityFound) {
  //     let entityIndex = transEntityRecords.entityList.findIndex((x: any) => x === tempSplittedAttr[0]);
  //     transEntityRecords.entityList.splice(entityIndex, 1);
  //   }
  // }
  // if (transEntityRecords?.queryInputOutputArray?.length == 0) {
  //   transEntityRecords.attributesList = [];
  //   transEntityRecords.attributesListForGroupFilter = [];
  //   transEntityRecords.attributesListForQueryLayer = [];
  //   transEntityRecords.entityList = [];
  // }
  /* istanbul ignore next */
  // transEntityRecords.attributesList.splice(actualAttrIndex > -1 ? actualAttrIndex : rowIndex, 1);
  // transEntityRecords.attributesList = [];
  transEntityRecords = getEntityList(transEntityRecords, false, mapsource);
  /* istanbul ignore next */
  transEntityRecords?.nslFormula?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.nslCase?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.nslFunction?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.isSelectedOperation?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.selectedOperation?.splice(rowIndex, 1);
}

/* istanbul ignore next */
export function getEntityList(transEntityRecords: any, createEntityList: any, mapsource: boolean) {
  var splitted = [];
  let attributesList: any = [];
  /* istanbul ignore next */
  // if (mapsource == true) {
  for (let i = 0; i < transEntityRecords?.queryInputOutputArray?.length; i++) {
    if (transEntityRecords?.queryInputOutputArray?.[i]?.sourceAttribute) {
      attributesList.push(transEntityRecords?.queryInputOutputArray?.[i]?.sourceAttribute);
    }
  }

  for (let i = 0; i < transEntityRecords?.nslCase?.length; i++) {
    for (let j = 0; j < transEntityRecords?.nslCase?.[i].length; j++) {
      /* istanbul ignore next */
      if (
        transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.charAt(0) !== "'" &&
        transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.charAt(
          transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.length - 1
        ) !== "'" &&
        !transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.includes('PL.SL00') &&
        transEntityRecords?.nslFormula?.[i]?.[j]?.nslQueryCondition?.attributeName !== 'function'
      ) {
        attributesList.push(transEntityRecords.nslCase[i][j].nslQueryCondition.attributeName);
      }

      /* istanbul ignore next */
      if (
        transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.charAt(0) !== "'" &&
        transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.charAt(
          transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.length - 1
        ) !== "'" &&
        !transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.includes('PL.SL00')
      ) {
        attributesList.push(transEntityRecords.nslCase[i][j].nslQueryCondition.attributeValue);
      }

      /* istanbul ignore next */
      if (
        transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.charAt(0) !== "'" &&
        transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.charAt(
          transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.length - 1
        ) !== "'" &&
        !transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.includes('PL.SL00')
      ) {
        attributesList.push(transEntityRecords.nslCase[i][j].nslThen.attributeName);
      }

      /* istanbul ignore next */
      if (
        transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.charAt(0) !== "'" &&
        transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.charAt(
          transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.length - 1
        ) !== "'" &&
        !transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.includes('PL.SL00')
      ) {
        attributesList.push(transEntityRecords.nslCase[i][j].nslElse.attributeName);
      }
    }
  }

  for (let i = 0; i < transEntityRecords?.nslFormula?.length; i++) {
    for (let j = 0; j < transEntityRecords?.nslFormula?.[i]?.length; j++) {
      /* istanbul ignore next */
      if (
        transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.charAt(0) !== "'" &&
        transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.charAt(
          transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.length - 1
        ) !== "'" &&
        !transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.includes('PL.SL00') &&
        transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName !== 'function'
      ) {
        attributesList.push(transEntityRecords.nslFormula[i][j].attributeName);
      }
    }
  }
  attributesList = getEntityListFromCondition(transEntityRecords?.queryData, attributesList);

  if (transEntityRecords.attributesList) {
    attributesList.push(...transEntityRecords.attributesList);
  }
  var uniqueAttributesList = new Set(attributesList);
  attributesList = [...uniqueAttributesList];

  for (let k = 0; k < attributesList?.length; k++) {
    var attrSplitted = attributesList?.[k]?.split('.', 1);
    splitted.push(attrSplitted?.[0]);
  }
  // }

  if (createEntityList) {
    if (mapsource == true) {
      for (let index = 0; index < attributesList?.length; index++) {
        /* istanbul ignore next */
        if (attributesList[index]) {
          transEntityRecords.attributesList.push(attributesList[index]);
          /* istanbul ignore next */
          var tempSplitted = attributesList?.[index]?.split('.', 1);
          splitted.push(tempSplitted?.[0]);
        }
      }
    } else {
      for (let index = 0; index < transEntityRecords?.queryInputOutputArray?.length; index++) {
        /* istanbul ignore next */
        if (transEntityRecords?.queryInputOutputArray?.[index]?.sourceAttribute) {
          transEntityRecords.attributesList.push(transEntityRecords?.queryInputOutputArray?.[index]?.sourceAttribute);
          /* istanbul ignore next */
          var tempSplitted = transEntityRecords?.queryInputOutputArray?.[index]?.sourceAttribute?.split('.', 1);
          splitted.push(tempSplitted?.[0]);
        }
      }
    }

    /* istanbul ignore next */
    transEntityRecords.entityList = splitted?.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }
  transEntityRecords.attributesListForQueryLayer = [];
  /* istanbul ignore next */
  if (transEntityRecords?.attributesList && transEntityRecords?.attributesList?.length > 0) {
    transEntityRecords.attributesListForQueryLayer.push(...transEntityRecords?.attributesList);
  }

  /* istanbul ignore next */
  transEntityRecords.attributesList = transEntityRecords.attributesList?.filter(function (
    elem: any,
    index: any,
    self: string | any[]
  ) {
    return index === self.indexOf(elem);
  });

  /* istanbul ignore next */
  transEntityRecords.attributesListForQueryLayer = transEntityRecords.attributesListForQueryLayer?.filter(function (
    elem: any,
    index: any,
    self: string | any[]
  ) {
    return index === self.indexOf(elem);
  });

  /* istanbul ignore next */
  transEntityRecords.attributesListForGroupFilter = transEntityRecords.attributesListForGroupFilter?.filter(function (
    elem: any,
    index: any,
    self: string | any[]
  ) {
    return index === self.indexOf(elem);
  });

  return transEntityRecords;
}

/* istanbul ignore next */
export function getEntityListFromCondition(queryData: any, attributesList: any) {
  for (let i = 0; i < queryData?.length; i++) {
    if (!queryData?.[i]?.length) {
      if (
        queryData?.[i]?.attributeName &&
        queryData?.[i]?.attributeName !== 'formula' &&
        queryData?.[i]?.attributeName !== 'case' &&
        queryData?.[i]?.attributeName !== 'function'
      ) {
        attributesList.push(queryData?.[i]?.attributeName);
      }
    } else {
      attributesList = getEntityListFromCondition(queryData[i], attributesList);
    }
  }
  return attributesList;
}

/* istanbul ignore next */
export function returnFormulaRecords(entityRecord: any, tempGroupFormulaIndex: any) {
  let transEntityRecords = entityRecord;
  for (let i = 0; i < transEntityRecords?.length; i++) {
    for (let value of tempGroupFormulaIndex) {
      /* istanbul ignore next */
      if (i === value) {
        transEntityRecords[i].txnNslAttribute[3].txnGeneralEntity =
          transEntityRecords?.[i]?.txnNslAttribute?.[3]?.values?.[0];

        transEntityRecords[i].txnNslAttribute[3].values = [];
      }
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function addNslFunction(nslFunction: any, transEntityRecords: any, mapSource: any, query: any) {
  if (mapSource == true) {
    transEntityRecords.nslFunction[nslFunction.index] = nslFunction.value;
  } else {
    if (query?.nslFunctionForCondition) {
      /* istanbul ignore next */
      query.nslFunctionForCondition = nslFunction.value;
    } else {
      query.nslFunctionForCondition = [];
      query.nslFunctionForCondition = nslFunction.value;
    }
    delete query?.nslFormulaForCondition;
    delete query?.nslCaseForCondition;
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function addNslFormula(nslFormula: any, transEntityRecords: any, mapSource: any, query: any) {
  /* formula for input output stepper */
  /* istanbul ignore next */
  if (mapSource == true) {
    if (transEntityRecords?.nslFormula?.length) {
      /* istanbul ignore next */
      for (let i = 0; i < transEntityRecords.nslFormula.length; i++) {
        /* istanbul ignore next */
        if (transEntityRecords.nslFormula.length > 0) {
          transEntityRecords.nslFormula[nslFormula.index] = nslFormula.value;
        } else if (transEntityRecords.nslFormula.length == 0) {
          transEntityRecords.nslFormula = [];
          transEntityRecords.nslFormula.push(nslFormula.value);
        }
      }
    } else {
      transEntityRecords.nslFormula = [];
      transEntityRecords.nslFormula.push(nslFormula.value);
    }
  } else {
    /* formula for condition stepper */
    /* istanbul ignore next */
    if (query?.nslFormulaForCondition) {
      /* istanbul ignore next */
      query.nslFormulaForCondition = nslFormula.value;
    } else {
      query.nslFormulaForCondition = [];
      query.nslFormulaForCondition = nslFormula.value;
    }
    delete query?.nslCaseForCondition;
    delete query?.nslFunctionForCondition;
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function addNslCase(nslCase: any, transEntityRecords: any, mapSource: any, query: any) {
  /* istanbul ignore next */
  if (mapSource == true) {
    if (transEntityRecords?.nslCase?.length > 0) {
      transEntityRecords.nslCase[nslCase.index] = nslCase.value;
    } else {
      transEntityRecords.nslCase = [];
      transEntityRecords.nslCase.push(nslCase.value);
    }
  } else {
    if (query?.nslCaseForCondition) {
      /* istanbul ignore next */
      query.nslCaseForCondition = nslCase.value;
    } else {
      query.nslCaseForCondition = [];
      query.nslCaseForCondition = nslCase.value;
    }
    delete query?.nslFormulaForCondition;
    delete query?.nslFunctionForCondition;
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function selectSortFilter(
  filterType: any,
  filterValue: any,
  filterIndex: number,
  innerFilterIndex: number,
  transEntityRecords: any,
  alertService: any,
  labels: any
) {
  /* istanbul ignore next */
  transEntityRecords?.filterData?.forEach((filterData: any, i: any) => {
    /* istanbul ignore next */
    for (let j = 0; j < filterData.length; j++) {
      if (filterType === filterData[j].type && i !== filterIndex) {
        /* istanbul ignore else */
        if (filterData[j].filterAttributeValue === filterValue) {
          alertService.showToaster(labels?.entity_already_filtered_please_select_different_entity, '', 'error');
          setTimeout(() => {
            transEntityRecords.filterData[filterIndex][innerFilterIndex].filterAttributeValue = '';
          }, 10);
        }
      }
    }
  });

  return transEntityRecords;
}

/* istanbul ignore next */
export function selectGroupFilter(
  filterType: any,
  filterValue: any,
  filterIndex: number,
  innerFilterIndex: number,
  transEntityRecords: any,
  alertService: any,
  labels: any
) {
  /* istanbul ignore next */
  transEntityRecords?.filterData?.forEach((filterData: any, i: any) => {
    /* istanbul ignore next */
    for (let j = 0; j < filterData?.length; j++) {
      /* istanbul ignore next */
      if (filterType === filterData[j]?.type && i !== filterIndex) {
        /* istanbul ignore next */
        if (filterData[j]?.filterAttributeValue === filterValue) {
          alertService.showToaster(labels.entity_already_filtered_please_select_different_entity, '', 'error');
          /* istanbul ignore next */
          setTimeout(() => {
            transEntityRecords.filterData[filterIndex][innerFilterIndex].filterAttributeValue = '';
          }, 10);
        }
      }
    }
  });

  return transEntityRecords;
}

/* istanbul ignore next */
export function closeDialogEql(event: any, transEntityRecords: any) {
  /* istanbul ignore next */
  if (
    event?.type === 'case' &&
    (transEntityRecords?.nslCase?.[event?.index]?.[0]?.nslQueryCondition?.attributeName === '' ||
      transEntityRecords?.nslCase?.[event?.index]?.[0]?.nslQueryCondition?.attributeName === undefined)
  ) {
    transEntityRecords.nslCase[event.index] = [];
  }
  /* istanbul ignore next */
  if (
    event?.type === 'formula' &&
    (transEntityRecords?.nslFormula?.[event?.index]?.[0]?.attributeName === '' ||
      transEntityRecords?.nslFormula?.[event?.index]?.[0]?.attributeName === undefined)
  ) {
    transEntityRecords.nslFormula[event.index] = [];
  }
  /* istanbul ignore next */
  if (
    event?.type === 'function' &&
    transEntityRecords?.nslFunction &&
    (transEntityRecords?.nslFunction?.[event?.index]?.[0]?.functionName === '' ||
      transEntityRecords?.nslFormula?.[event?.index]?.[0]?.functionName === undefined)
  ) {
    transEntityRecords.nslFunction[event.index] = [];
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function removeAttrForGroupFilter(transEntityRecords: any, index: any) {
  /* istanbul ignore next */
  if (transEntityRecords?.attributesListForGroupFilter?.[index]) {
    transEntityRecords.attributesListForGroupFilter.splice(index, 1);
  } /* istanbul ignore next */ else {
    transEntityRecords.attributesListForGroupFilter = [];
    transEntityRecords.attributesListForGroupFilter.splice(index, 1);
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function addOperationLine(transEntityRecords: any) {
  /* istanbul ignore next */
  transEntityRecords.queryInputOutputArray.push({});
  /* istanbul ignore next */
  if (!transEntityRecords.isSelectedOperation) {
    transEntityRecords.isSelectedOperation = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.selectedOperation) {
    transEntityRecords.selectedOperation = [];
  }
  /* istanbul ignore next */
  for (let i = 0; i < transEntityRecords.queryInputOutputArray.length; i++) {
    /* istanbul ignore next */
    if (!transEntityRecords.isSelectedOperation[i]) {
      transEntityRecords.isSelectedOperation[i] = false;
    }
  }
  /* istanbul ignore next */
  transEntityRecords.isSelectedOperation[transEntityRecords.queryInputOutputArray.length - 1] = true;

  return transEntityRecords;
}

/* istanbul ignore next */
export function checkGroupByFilterToAddInQueryCanvas(transEntityRecords: any) {
  /* istanbul ignore next */
  let groupByCount = 0;
  let tempGroupByAttr: any = [];
  let indexofGroupBy = -1;
  /* istanbul ignore next */
  transEntityRecords?.filterData?.forEach((filter: any, i: any) => {
    /* istanbul ignore next */
    transEntityRecords?.filterData?.[i]?.forEach((filterData: any, j: any) => {
      /* istanbul ignore next */
      if (filterData?.type === 'GROUP' && filterData?.filterAttributeValue?.length > 0) {
        indexofGroupBy = tempGroupByAttr.indexOf(filterData.filterAttributeValue);
        /* istanbul ignore next */
        if (indexofGroupBy == -1) {
          tempGroupByAttr.push(filterData.filterAttributeValue);
          groupByCount++;
        }
      }
    });
  });

  let groupFilterCount = 0;
  /* istanbul ignore next */
  transEntityRecords?.attributesListForGroupFilter?.forEach((attr: any) => {
    /* istanbul ignore else */
    if (attr) {
      groupFilterCount++;
    }
  });

  let functionsCountinIO = 0;
  /* istanbul ignore next */
  transEntityRecords?.queryInputOutputArray?.forEach((queryIO: any, j: any) => {
    /* istanbul ignore next */
    /* istanbul ignore else */
    if (queryIO?.sourceAttribute?.length > 0 && queryIO?.function?.length > 0) {
      functionsCountinIO++;
    }
  });

  return false;
}

function fillNestedNSLFormulae(transEntityRecords: any, tempVarForTransEntityRecords: any, index: any, eqlLabel: any) {
  let tempVarForNslFormula =
    tempVarForTransEntityRecords?.[index]?.txnNslAttribute?.[1]?.txnGeneralEntity?.transEntityRecords;
  if (transEntityRecords?.[index]?.nslFormulaForCondition) {
    transEntityRecords[index].nslFormulaForCondition.push([]);
  } else {
    if (tempVarForNslFormula) {
      transEntityRecords[index] = { ...transEntityRecords[index], ...{ nslFormulaForCondition: [] } };
    }
  }

  for (let k = 0; k < tempVarForNslFormula?.length; k++) {
    if (
      !tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity ||
      !tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords
    ) {
      let formulae = {
        nslFunction: '',
        attributeName: '',
        arithmeticOperator: '',
        nslQuery: '',
        nslFormula: '',
        nslCase: '',
      };

      transEntityRecords[index].nslFormulaForCondition[k] = JSON.parse(JSON.stringify(formulae));
      transEntityRecords[index].nslFormulaForCondition[k] = loadNSLFormula(tempVarForNslFormula?.[k], eqlLabel);
    } else {
      transEntityRecords[index].nslFormulaForCondition[k] = [];
      for (
        let l = 0;
        l < tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.length;
        l++
      ) {
        transEntityRecords[index].nslFormulaForCondition[k].push(
          loadNSLFormula(
            tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[l],
            eqlLabel
          )
        );
        transEntityRecords[index].nslFormulaForCondition[k].arithmeticOperator =
          tempVarForNslFormula?.[k]?.txnNslAttribute?.[5]?.values?.[0];
      }
    }
  }
}

/* istanbul ignore next */
export function fillNSLFormulae(
  transEntityRecords: any,
  tempVarForTransEntityRecords: any,
  index: any,
  stepper: any,
  eqlLabel: any
) {
  let stepperVar = stepper === 'inputoutput' ? 0 : 1;
  //for NSL Formula
  let tempVarForNslFormula =
    tempVarForTransEntityRecords?.[index]?.txnNslAttribute?.[stepperVar]?.txnGeneralEntity?.transEntityRecords;
  if (stepper === 'inputoutput') {
    transEntityRecords.nslFormula.push([]);
  } else {
    if (transEntityRecords?.queryData?.[index]?.nslFormulaForCondition) {
      transEntityRecords.queryData[index].nslFormulaForCondition.push([]);
    } else {
      if (tempVarForNslFormula) {
        transEntityRecords.queryData[index] = {
          ...transEntityRecords.queryData[index],
          ...{ nslFormulaForCondition: [] },
        };
      }
    }
  }
  if (tempVarForNslFormula && stepper === 'inputoutput') {
    transEntityRecords.selectedOperation[index] = 'formula';
    transEntityRecords.isSelectedOperation[index] = true;
  }
  for (let k = 0; k < tempVarForNslFormula?.length; k++) {
    if (
      !tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity ||
      !tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords
    ) {
      let formulae = {
        nslFunction: '',
        attributeName: '',
        arithmeticOperator: '',
        nslQuery: '',
        nslFormula: '',
        nslCase: '',
      };
      if (stepper === 'inputoutput') {
        transEntityRecords.nslFormula?.[index].push(JSON.parse(JSON.stringify(formulae)));
        transEntityRecords.nslFormula[index][k] = loadNSLFormula(tempVarForNslFormula?.[k], eqlLabel);
      } else {
        transEntityRecords.queryData[index].nslFormulaForCondition[k] = JSON.parse(JSON.stringify(formulae));
        transEntityRecords.queryData[index].nslFormulaForCondition[k] = loadNSLFormula(
          tempVarForNslFormula?.[k],
          eqlLabel
        );
      }
    } else {
      if (stepper === 'inputoutput') {
        transEntityRecords.nslFormula[index].push([]);
      }
      for (
        let l = 0;
        l < tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.length;
        l++
      ) {
        if (stepper === 'inputoutput') {
          transEntityRecords.nslFormula[index][k].push(
            loadNSLFormula(
              tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[l],
              eqlLabel
            )
          );
          transEntityRecords.nslFormula[index][k].arithmeticOperator = '';
          transEntityRecords.nslFormula[index][k].arithmeticOperator =
            tempVarForNslFormula?.[k]?.txnNslAttribute?.[5]?.values?.[0];
        } else {
          if (!transEntityRecords?.queryData?.[index]?.nslFormulaForCondition?.[k]) {
            transEntityRecords.queryData[index].nslFormulaForCondition[k] = [];
          }
          transEntityRecords.queryData[index].nslFormulaForCondition[k].push(
            loadNSLFormula(
              tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[l],
              eqlLabel
            )
          );
          transEntityRecords.queryData[index].nslFormulaForCondition[k].arithmeticOperator =
            tempVarForNslFormula?.[k]?.txnNslAttribute?.[5]?.values?.[0];
        }
      }
    }
  }
  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    tempVarForTransEntityRecords: tempVarForTransEntityRecords,
  };
  return tempReturnValue;
}

function fillNestedNSLCase(transEntityRecords: any, tempVarForTransEntityRecords: any, index: any, eqlLabel: any) {
  let tempVarForNslCase =
    tempVarForTransEntityRecords?.[index]?.txnNslAttribute?.[7]?.txnGeneralEntity?.transEntityRecords;
  transEntityRecords[index].nslCaseForCondition = [];
  for (let k = 0; k < tempVarForNslCase?.length; k++) {
    if (
      !tempVarForNslCase?.[k]?.txnNslAttribute?.[4]?.txnGeneralEntity ||
      !tempVarForNslCase?.[k]?.txnNslAttribute?.[4]?.txnGeneralEntity?.transEntityRecords
    ) {
      let NslCase = {
        nslQueryCondition: { nslFormula: '', attributeName: '', nslCase: '', queryOperator: '', attributeValue: '' },
        nslThen: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', NSL_Case: '' },
        nslElse: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', NSL_Case: '' },
      };
      if (!transEntityRecords[index]?.nslCaseForCondition) {
        transEntityRecords[index].nslCaseForCondition = [];
      }
      transEntityRecords[index].nslCaseForCondition[k] = JSON.parse(JSON.stringify(NslCase));
      transEntityRecords[index].nslCaseForCondition[k] = loadNSLCase(tempVarForNslCase?.[k], eqlLabel);
    }
  }
}

/* istanbul ignore next */
export function fillNSLCase(
  transEntityRecords: any,
  tempVarForTransEntityRecords: any,
  index: any,
  stepper: any,
  eqlLabel: any
) {
  let stepperVar = stepper === 'inputoutput' ? 1 : 7;
  /* istanbul ignore next */
  let tempVarForNslCase =
    tempVarForTransEntityRecords?.[index]?.txnNslAttribute?.[stepperVar]?.txnGeneralEntity?.transEntityRecords;
  if (stepper === 'inputoutput') {
    transEntityRecords.nslCase.push([]);
  } else {
    transEntityRecords.queryData[index].nslCaseForCondition = [];
  }

  if (tempVarForNslCase && stepper === 'inputoutput') {
    transEntityRecords.selectedOperation[index] = 'case';
    transEntityRecords.isSelectedOperation[index] = true;
  }
  for (let k = 0; k < tempVarForNslCase?.length; k++) {
    if (
      !tempVarForNslCase?.[k]?.txnNslAttribute?.[4]?.txnGeneralEntity ||
      !tempVarForNslCase?.[k]?.txnNslAttribute?.[4]?.txnGeneralEntity?.transEntityRecords
    ) {
      let NslCase = {
        nslQueryCondition: { nslFormula: '', attributeName: '', nslCase: '', queryOperator: '', attributeValue: '' },
        nslThen: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', NSL_Case: '' },
        nslElse: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', NSL_Case: '' },
      };
      if (stepper === 'inputoutput') {
        transEntityRecords.nslCase?.[index].push(JSON.parse(JSON.stringify(NslCase)));
        transEntityRecords.nslCase[index][k] = loadNSLCase(tempVarForNslCase?.[k], eqlLabel);
      } else {
        if (!transEntityRecords.queryData?.[index]?.nslCaseForCondition) {
          transEntityRecords.queryData[index].nslCaseForCondition = [];
        }
        transEntityRecords.queryData[index].nslCaseForCondition[k] = JSON.parse(JSON.stringify(NslCase));
        transEntityRecords.queryData[index].nslCaseForCondition[k] = loadNSLCase(tempVarForNslCase?.[k], eqlLabel);
      }
    } else {
      if (stepper === 'inputoutput') {
        transEntityRecords.nslCase[index].push([]);
      }
    }
  }

  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    tempVarForTransEntityRecords: tempVarForTransEntityRecords,
  };
  return tempReturnValue;
}

export function fillFormulaDataForDialog(nslFormula: any) {
  /* istanbul ignore next */
  for (let i = 0; i < nslFormula?.length; i++) {
    /* istanbul ignore next */
    for (let j = 0; j < nslFormula?.[i]?.length; j++) {
      /* istanbul ignore next */
      for (let k = 0; k < nslFormula?.[i]?.[j]?.length; k++) {
        /* istanbul ignore else */
        if (!nslFormula[i][j][k].length) {
          resetAttributeNamesForDialog(nslFormula[i][j][k]);
        } else {
          for (let l = 0; l < nslFormula?.[i]?.[j]?.[k]?.length; l++) {
            resetAttributeNamesForDialog(nslFormula[i][j][k][l]);
          }
        }
      }
    }
  }
  return nslFormula;
}

export function resetAttributeNamesForDialog(nslFormula: any) {
  /* istanbul ignore else */
  if (!nslFormula.length) {
    /* istanbul ignore next */
    if (
      nslFormula?.attributeName?.charAt(0) == "'" &&
      nslFormula?.attributeName?.charAt(nslFormula?.attributeName?.length - 1) == "'"
    ) {
      let attributeName = '';
      /* istanbul ignore next */
      for (let k = 1; k < nslFormula?.attributeName?.length - 1; k++) {
        attributeName += nslFormula?.attributeName[k];
      }
      nslFormula.attributeName = attributeName;
      nslFormula.enterFreeValue = true;
    }
  } else {
    for (let j = 0; j < nslFormula.length; j++) {
      resetAttributeNamesForDialog(nslFormula[j]);
    }
  }
}

export function addFormula(nslFormula: any, createAttrIndex: number) {
  let formula = [
    {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      arithmeticOperator: '',
      enterFreeValue: true,
      isNslQuery: false,
    },
    {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      arithmeticOperator: '',
      enterFreeValue: true,
      isNslQuery: false,
    },
  ];
  /* istanbul ignore else */
  if (!nslFormula) {
    nslFormula = [];
  }
  /* istanbul ignore next */
  if (!nslFormula?.[createAttrIndex]) {
    nslFormula[createAttrIndex] = [];
  }
  nslFormula[createAttrIndex].push(formula);
  nslFormula[createAttrIndex][nslFormula[createAttrIndex].length - 1].attrValue = {
    attributeName: '',
    displayName: '',
    uiElement: '',
  };
  nslFormula[createAttrIndex].isValid = false;
  return nslFormula;
}

export function addGroupFormula(nslFormula: any, createAttrIndex: any) {
  /* istanbul ignore next */
  if (!nslFormula) {
    nslFormula = [];
  }
  nslFormula[createAttrIndex][nslFormula[createAttrIndex].length - 1].push([]);
  let nestedLength = nslFormula[createAttrIndex][nslFormula[createAttrIndex].length - 1].length - 1;
  let formula: any = [
    {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      arithmeticOperator: '',
      enterFreeValue: true,
      isNslQuery: false,
    },
    {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      arithmeticOperator: '',
      enterFreeValue: true,
      isNslQuery: false,
    },
  ];

  formula.arithmeticOperator = '';
  nslFormula[createAttrIndex][nslFormula[createAttrIndex].length - 1][nestedLength] = formula;
  nslFormula[createAttrIndex].disableFunctionGroup = true;
  return nslFormula;
}

export function dataValidator(nslFormula: any, dataValidate: any) {
  /* istanbul ignore else */
  if (!nslFormula.length) {
    /* istanbul ignore else */
    if (!nslFormula.isNslQuery) {
      if (nslFormula.attributeName == null || nslFormula.attributeName == undefined || nslFormula.attributeName == '') {
        dataValidate = true;
        return dataValidate;
      }
    }
  } else {
    for (let i = 0; i < nslFormula.length; i++) {
      dataValidate = dataValidator(nslFormula[i], dataValidate);
    }
  }
  return dataValidate;
}

export function operatorsValidator(nslFormula: any, operatorsValidate: any) {
  /* istanbul ignore else */
  if (!nslFormula.length) {
    /* istanbul ignore next */
    if (
      nslFormula.arithmeticOperator == null ||
      nslFormula.arithmeticOperator == undefined ||
      nslFormula.arithmeticOperator == ''
    ) {
      operatorsValidate = true;
      return operatorsValidate;
    }
  } else {
    for (let i = 0; i < nslFormula.length; i++) {
      if (i < nslFormula.length - 1 && !nslFormula[i + 1].length) {
        operatorsValidate = operatorsValidator(nslFormula[i], operatorsValidate);
      }
    }
  }
  return operatorsValidate;
}

export function saveNslCase(
  $event: any,
  nslCase: any,
  alertService: any,
  panelOpenState: any,
  targetAddedFromLibrary: any,
  stepperType: any,
  targetEntity: any,
  constUIControls: any,
  nodeEntityFacade: any
) {
  let dataValidate = true;
  /* istanbul ignore next */
  for (let i = 0; i < nslCase?.length; i++) {
    for (let j = 0; j < nslCase?.[i]?.length; j++) {
      /* istanbul ignore next */
      if (
        nslCase[i][j].nslQueryCondition.attributeName == undefined ||
        nslCase[i][j].nslQueryCondition.attributeName == '' ||
        nslCase[i][j].nslQueryCondition.queryOperator == undefined ||
        nslCase[i][j].nslQueryCondition.queryOperator == '' ||
        nslCase[i][j].nslQueryCondition.attributeValue == undefined ||
        nslCase[i][j].nslQueryCondition.attributeValue == '' ||
        nslCase[i][j].nslThen.attributeName == undefined ||
        nslCase[i][j].nslThen.attributeName == ''
      ) {
        dataValidate = false;
        break;
      }
    }
  }

  /* istanbul ignore else */
  if (!dataValidate) {
    alertService.showToaster('Please Select/Enter All Values', '', 'error');
    panelOpenState = true;
    $event.stopPropagation();
  } else {
    if (targetAddedFromLibrary == false && stepperType !== 'condition') {
      updateTargetEntity(nslCase, targetEntity, constUIControls, nodeEntityFacade, 'case');
    }
    panelOpenState = !panelOpenState;
  }
  return nslCase;
}

export function updateTargetEntity(
  operation: any,
  targetEntity: any,
  constUIControls: any,
  nodeEntityFacade: any,
  type: any
) {
  /* istanbul ignore next */
  if (operation?.length > 0) {
    operation.forEach((op: any) => {
      /* istanbul ignore else */
      if (op.length > 0) {
        /* istanbul ignore next */
        let foundAttr: number;
        if (type == 'case') {
          foundAttr = targetEntity.nslAttributes.findIndex((attr: any) => attr.name == op.attrValue.actualName);
        } else {
          foundAttr = targetEntity.nslAttributes.findIndex((attr: any) => attr.name == op?.[0]?.attrValue.actualName);
        }
        /* istanbul ignore next */
        if (foundAttr == -1) {
          let attribute: any;
          let dataType: any;
          if (type == 'case') {
            dataType = constUIControls.find((ui: any) => ui.uiElement === op.attrValue.uiElement);
            attribute = getAttributeObj(op?.attrValue?.actualName, dataType, op?.attrValue?.displayName);
          } else {
            dataType = constUIControls.find((ui: any) => ui.uiElement === op?.[0]?.attrValue.uiElement);
            attribute = getAttributeObj(op?.[0]?.attrValue?.actualName, dataType, op?.[0]?.attrValue?.displayName);
          }
          targetEntity.nslAttributes.push(attribute);
        }
      }
    });
    nodeEntityFacade.updateTargetEntity(targetEntity);
  }
}

export function fillCaseDataForDialog(nslCase: any) {
  for (let i = 0; i < nslCase?.length; i++) {
    for (let j = 0; j < nslCase?.[i]?.length; j++) {
      /* istanbul ignore else */
      if (nslCase[i].length) {
        nslCase[i][j] = resetAttributeNames(nslCase[i][j]);
      } else {
        nslCase[i] = resetAttributeNames(nslCase[i]);
      }
    }
  }

  //check whether to disable else or not
  /* istanbul ignore next */
  for (let i = 0; i < nslCase?.length; i++) {
    for (let j = 0; j < nslCase?.[i]?.length; j++) {
      if (nslCase[i].length) {
        resetElseDisabled(nslCase[i][j]);
      } else {
        resetElseDisabled(nslCase[i]);
      }
    }
  }
  return nslCase;
}

export function resetElseDisabled(nslCase: any) {
  if (
    !nslCase.nslElse.attributeName ||
    nslCase.nslElse.attributeName == '' ||
    nslCase.nslElse.attributeName == undefined
  ) {
    nslCase.elseDisabled = true;
  } else {
    nslCase.elseDisabled = false;
  }
}

export function addCase(nslCase: any) {
  let tempNslCase = {
    nslQueryCondition: {
      functionName: '',
      nslFormula: '',
      attributeName: '',
      queryOperator: '',
      attributeValue: '',
      nslQuery: '',
      nslQueryCondition: '',
      nslCase: '',
      predicate: '',
      enterFreeValue: false,
    },
    nslThen: {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      enterFreeValue: false,
    },
    nslElse: {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      enterFreeValue: false,
    },
    elseDisabled: false,
  };
  /* istanbul ignore else */
  if (!nslCase) {
    nslCase = [];
  }
  return { tempNslCase: tempNslCase, nslCase: nslCase };
}

export function addInnerCase(nslCase: any, index: any) {
  let NslCase = {
    nslQueryCondition: {
      functionName: '',
      nslFormula: '',
      attributeName: '',
      queryOperator: '',
      attributeValue: '',
      nslQuery: '',
      nslQueryCondition: '',
      nslCase: '',
      predicate: '',
      enterFreeValue: false,
    },
    nslThen: {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      enterFreeValue: false,
    },
    nslElse: {
      functionName: '',
      attributeName: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
      enterFreeValue: false,
    },
    elseDisabled: false,
  };

  nslCase[index].push(NslCase);
  return nslCase;
}

export function removeElse(currentCase: any) {
  currentCase.elseDisabled = true;
  currentCase.nslElse.attributeName = '';
  currentCase.nslElse.enterFreeValue = false;
  return currentCase;
}

export function deleteInnerCase(nslCase: any, index: any, innerIndex: any) {
  nslCase[index].splice(innerIndex, 1);
  return nslCase;
}

export function convertOperatorFunction(res: any) {
  let updatedRes = [];
  /* istanbul ignore next */
  if (res?.result?.length > 0) {
    /* istanbul ignore next */
    if (res?.result?.[0]?.name) {
      updatedRes = res.result;
    } else {
      /* istanbul ignore next */
      for (let result of res?.result) {
        updatedRes.push({ name: result, displayName: result });
      }
    }
  }
  return updatedRes;
}

export function removeExtraAttributeNameFromFormulaCase(attr: any) {
  /* istanbul ignore next */
  if (!attr?.txnNslAttribute?.[6]?.txnGeneralEntity) {
    /* istanbul ignore next */
    if (
      attr?.txnNslAttribute?.[0]?.txnGeneralEntity ||
      attr?.txnNslAttribute?.[1]?.txnGeneralEntity ||
      attr?.txnNslAttribute?.[7]?.txnGeneralEntity
    ) {
      attr.txnNslAttribute[2].values = [];
    }
  } else {
    /* istanbul ignore next */
    removeExtraAttributeNameFromFormulaCase(attr?.txnNslAttribute?.[6]?.txnGeneralEntity?.transEntityRecords);
  }
}

export function updateEqlLabels(labels: any, reservedCuType: any) {
  let flag: boolean = (reservedCuType == 'NSL_EQL' || reservedCuType == 'EQL_Report' || reservedCuType == 'NSL_EQL_Update');

  return {
    NSL_Query_Input_Output:
      labels?.NSL_Query_Input_Output && flag
        ? labels?.NSL_Query_Input_Output
        : 'NSL_Query_Input_Output',
    NSL_Formula:
      labels?.NSL_Formula && flag
        ? labels?.NSL_Formula
        : 'NSL_Formula',
    NSL_Case:
      labels?.NSL_Case && flag
        ? labels?.NSL_Case
        : 'NSL_Case',
    Source_Attribute:
      labels?.Source_Attribute_EQL && flag
        ? labels?.Source_Attribute_EQL
        : 'Source_Attribute',
    Target_Attribute:
      labels?.Target_Attribute_EQL && flag
        ? labels?.Target_Attribute_EQL
        : 'Target_Attribute',
    Function:
      labels?.Function && flag
        ? labels?.Function
        : 'Function',
    Function_Name:
      labels?.Function_Name && flag
        ? labels?.Function_Name
        : 'Function_Name',
    Attribute_Name:
      labels?.Attribute_Name_EQL && flag
        ? labels?.Attribute_Name_EQL
        : 'Attribute_Name',
    NSL_Query:
      labels?.NSL_Query && flag
        ? labels?.NSL_Query
        : 'NSL_Query',
    Arithmetic_Operator:
      labels?.Arithmetic_Operator && flag
        ? labels?.Arithmetic_Operator
        : 'Arithmetic_Operator',
    NSL_Query_Condition:
      labels?.NSL_Query_Condition && flag
        ? labels?.NSL_Query_Condition
        : 'NSL_Query_Condition',
    NSL_Then:
      labels?.NSL_Then && flag
        ? labels?.NSL_Then
        : 'NSL_Then',
    NSL_Else:
      labels?.NSL_Else && flag
        ? labels?.NSL_Else
        : 'NSL_Else',
    Query_Operator:
      labels?.Query_Operator && flag
        ? labels?.Query_Operator
        : 'Query_Operator',
    Attribute_Value:
      labels?.Attribute_Value && flag
        ? labels?.Attribute_Value
        : 'Attribute_Value',
    Predicate:
      labels?.Predicate && flag
        ? labels?.Predicate
        : 'Predicate',
    NSL_Filter:
      labels?.NSL_Filter && flag
        ? labels?.NSL_Filter
        : 'NSL_Filter',
    Type:
      labels?.Type && flag
        ? labels?.Type
        : 'Type',
    Value:
      labels?.Value && flag
        ? labels?.Value
        : 'Value',
    Filter_Attribute_Value:
      labels?.Filter_Attribute_Value && flag
        ? labels?.Filter_Attribute_Value
        : 'Filter_Attribute_Value',
    NSL_Function:
      labels?.NSL_Function && flag
        ? labels?.NSL_Function
        : 'NSL_Function',
    NSL_Function_Parameters:
      labels?.NSL_Function_Parameters && flag
        ? labels?.NSL_Function_Parameters
        : 'NSL_Function_Parameters',
    Function_Attribute:
      labels?.Function_Attribute && flag
        ? labels?.Function_Attribute
        : 'Function_Attribute',
    Function_Constant_Value:
      labels?.Function_Constant_Value && flag
        ? labels?.Function_Constant_Value
        : 'Function_Constant_Value',
    Function_Operator:
      labels?.Function_Operator && flag
        ? labels?.Function_Operator
        : 'Function_Operator',
    Combine_Type:
      labels?.Combine_Type && flag
        ? labels?.Combine_Type
        : 'Combine_Type',
    NSL_Set_Attribute:
      labels?.NSL_Set_Attribute && flag
        ? labels?.NSL_Set_Attribute
        : 'NSL_Set_Attribute',
    Update_Attribute_Value:
      labels?.Update_Attribute_Value && flag
        ? labels?.Update_Attribute_Value
        : 'Update Attribute Value',
    Update_Attribute_Value_EQL:
      labels?.Update_Attribute_Value_EQL && flag
        ? labels?.Update_Attribute_Value_EQL
        : 'Update_Attribute_Value',
    NSL_Update_Query_Condition:
      labels?.NSL_Update_Query_Condition && flag
        ? labels?.NSL_Update_Query_Condition
        : 'NSL_Update_Query_Condition',
    NSL_Update_Query:
      labels?.NSL_Update_Query && flag
        ? labels?.NSL_Update_Query
        : 'NSL_Update_Query',
    NSL_Group_By_Aggregate:
      labels?.NSL_Group_By_Aggregate && flag
        ? labels?.NSL_Group_By_Aggregate
        : 'NSL_Group_By_Aggregate',
    Group_by_Attribute:
      labels?.Group_by_Attribute && flag
        ? labels?.Group_by_Attribute
        : 'Group_by_Attribute',
    NSL_Having_Clause:
      labels?.NSL_Having_Clause && flag
        ? labels?.NSL_Having_Clause
        : 'NSL_Having_Clause',
    NSL_BL_Query_Filter:
      labels?.NSL_BL_Query_Filter && flag
        ? labels?.NSL_BL_Query_Filter
        : 'NSL_BL_Query_Filter',
  };
}
/** ------------------------------------------- EQL Functions - END -------------------------------------------------------- */

export function validateEvent(eventList: any) {
  let error = false;
  eventList?.forEach((e: any, ind: any) => {
    if (!e?.dsdChangeDriverId || !e?.eventType) {
      delete eventList?.[ind];
      error = true;
    }
  });
  eventList = eventList.filter((event: any) => event != null || event != undefined);
  return { eventlist: eventList, error: error };
}
export function isParseNumber(str: any) {
  try {
    Number(str);
  } catch (e) {
    /*istanbul ignore next*/
    return false;
  }
  return true;
}

export function updateEntityData(changeUnit: any, layer: any, entity: any) {
  /*istanbul ignore next*/
  let layerIndex = changeUnit?.layers?.findIndex((q: any) => {
    return q.type == layer;
  });
  /*istanbul ignore next*/
  let cuEntity = changeUnit?.layers[layerIndex]?.participatingItems?.find((ent: any) => ent?.name == entity?.name);
  /*istanbul ignore next*/
  let nslAttributes = cuEntity?.nslAttributes;
  let entityObjAttributes: any = [];
  /*istanbul ignore next*/
  nslAttributes?.forEach((attr: any) => {
    if (attr) {
      let attributeType = attr?.attributeType;
      let foundAttr = entity?.nslAttributes.find((x: any) => x?.name === attr?.name);
      /* istanbul ignore next */
      if (foundAttr) {
        if (foundAttr?.attributeType) foundAttr.attributeType = attributeType;
        entityObjAttributes.push(foundAttr);
      }
    }
  });
  return entityObjAttributes;
}

export function configureAggregateWithPayload(
  indexStore: any,
  totalKPICount: number,
  groupList: any,
  aggregateResultValues: any,
  dataSubTransaction: any
) {
  let returnAggregated: any;
  /*istanbul ignore next */
  if (indexStore?.moduleType === 'KPI') totalKPICount = 0;
  /*istanbul ignore next */
  if (indexStore?.isTxn) {
    /*istanbul ignore next */
    if (!indexStore?.openChart && !indexStore?.isSubTransOpen) {
      /* istanbul ignore next */
      if (!groupList?.attributeList[indexStore?.cu_index]) {
        groupList.attributeList[indexStore?.cu_index] = [];
      }
      /* istanbul ignore next */
      if (!groupList?.attributeList[indexStore?.cu_index][indexStore?.entIndex]) {
        groupList.attributeList[indexStore?.cu_index][indexStore?.entIndex] = [];
      }
      groupList.attributeList[indexStore?.cu_index][indexStore?.entIndex][indexStore?.attributeIndex] = [];
    }

    for (const [key, val] of Object.entries(aggregateResultValues)) {
      indexStore = {
        ...indexStore,
        currentAttributeName: key.split('--')[2],
        key: key.split('--')[0],
        value: val,
        attrWholeKey: key,
      };
      returnAggregated = evaluateEachTxnCall(indexStore, totalKPICount, groupList, dataSubTransaction, val);
    }
  } else {
    /*istanbul ignore next */
    if (!indexStore?.openChart && !indexStore?.isSubTransOpen) {
      /*istanbul ignore next */
      if (indexStore?.entIndex || indexStore?.entIndex === 0) {
        /* istanbul ignore next */
        if (!groupList?.attributeList[indexStore?.entIndex]) {
          groupList.attributeList[indexStore?.entIndex] = [];
        }
        groupList.attributeList[indexStore?.entIndex][indexStore?.attributeIndex] = [];
      }
    }
    /*istanbul ignore next*/
    aggregateResultValues?.forEach((row: any) => {
      indexStore = {
        ...indexStore,
        key: row.key,
        attrWholeKey: row.originalKey,
        value: row.value,
      };
      returnAggregated = evaluateEachTxnCall(indexStore, totalKPICount, groupList, dataSubTransaction, row.value);
    });
  }
  return returnAggregated;
}

export function evaluateEachTxnCall(
  indexStore: any,
  totalKPICount: number,
  groupList: any,
  dataSubTransaction: any,
  value: any
) {
  let returnedTxn: any;
  /*istanbul ignore next */
  if (indexStore?.moduleType === 'KPI') {
    totalKPICount += Number(value);
    return { ...returnedTxn, totalKPICount: totalKPICount, indexStore: indexStore, groupList: groupList };
  } else {
    returnedTxn = createAggregatePayloadForFusion(dataSubTransaction, indexStore, groupList);
    return returnedTxn;
  }
}

export function createAggregatePayloadForFusion(dataSubTransaction: any, indexStore: any, groupList: any) {
  /*istanbul ignore next */
  if (indexStore?.chartType === 'msline') {
    /*istanbul ignore next */
    if ((indexStore?.multiSerieschart)[indexStore?.currentAttributeName]) {
      (indexStore?.multiSerieschart)[indexStore?.currentAttributeName] = {
        ...(indexStore?.multiSerieschart)[indexStore?.currentAttributeName],
        [indexStore?.key]: Number(indexStore?.value),
      };
    } else {
      /*istanbul ignore next */
      indexStore.multiSerieschart = {
        ...indexStore.multiSerieschart,
        [indexStore?.currentAttributeName]: { [indexStore?.key]: Number(indexStore?.value) },
      };
    }
  } else {
    /*istanbul ignore next */
    const attrTXN = indexStore?.countStore[indexStore?.attrWholeKey];
    /*istanbul ignore next */
    if (!indexStore?.openChart && !indexStore?.isSubTransOpen) {
      /*istanbul ignore next */
      if (indexStore?.isTxn) {
        /*istanbul ignore next */
        groupList?.attributeList[indexStore?.cu_index][indexStore?.entIndex][indexStore?.attributeIndex]?.push({
          key: indexStore?.key,
          isChecked: attrTXN ? attrTXN.checked : false,
        });
      } else {
        /*istanbul ignore next */
        if (
          (indexStore?.entIndex || indexStore?.entIndex === 0) &&
          (indexStore?.attributeIndex || indexStore?.attributeIndex === 0)
        ) {
          /*istanbul ignore next */
          groupList?.attributeList[indexStore?.entIndex][indexStore?.attributeIndex]?.push({
            key: indexStore?.key,
            isChecked: attrTXN ? attrTXN.checked : false,
          });
        }
      }
    }
    /*istanbul ignore next */
    if (indexStore?.isSubTransOpen) {
      dataSubTransaction = { ...dataSubTransaction, [indexStore?.key]: Number(indexStore?.value) };
    } else {
      /*istanbul ignore next */
      if (indexStore?.countStore[indexStore?.attrWholeKey]) {
        indexStore.countStore[indexStore?.attrWholeKey].count = indexStore?.value;
        /*istanbul ignore next */
        if (indexStore?.countStore[indexStore?.attrWholeKey].checked) {
          /*istanbul ignore next */
          indexStore.saveAggrResult = { ...indexStore.saveAggrResult, [indexStore?.key]: Number(indexStore?.value) };
        }
      } else {
        /*istanbul ignore next */
        indexStore.countStore = {
          ...indexStore.countStore,
          [indexStore?.attrWholeKey]: {
            count: Number(indexStore?.value),
            keys: indexStore?.key,
            checked: false,
            attributeName: indexStore?.currentAttributeName,
          },
        };
      }
    }
  }
  return {
    groupList: groupList,
    dataSubTransaction: dataSubTransaction,
    indexStore: indexStore,
  };
}

export function getUiControlCustomization(uiElement: string) {
  let foundObject: any;
  if (sessionStorage.getItem('uicontrolsMap')) {
    let arr = Object?.entries(JSON.parse(sessionStorage.getItem('uicontrolsMap')))?.map(([type, event]) => ({
      type,
      event,
    }));
    foundObject = arr.find((obj) => obj.type === uiElement);
    if (foundObject?.event) {
      return foundObject.event;
    }
  }
}
export function getUsernameInitials() {
  /* istanbul ignore next */
  return localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))?.name
    ? JSON.parse(localStorage.getItem('userInfo'))?.name?.[0].toUpperCase()
    : '';
}

export function formatTimeSeriesTxnReport(
  gsiMasterId: any,
  referrenceCUMasterId: any,
  entity: any,
  attr: any,
  selectedInterval: any,
  pageSizeReport: number,
  pageNumberReport: number
) {
  const searchQueryFilter: any = {
    filters: [
      {
        gsiMasterId: gsiMasterId,
        referrenceCUfilters: [
          {
            referrenceCUMasterId: referrenceCUMasterId,
            inputOutputMapping: {
              [entity + '.' + attr]: 'Result.' + attr,
            },
            groupBy: [
              {
                fieldName: 'aggUpdatedAt', // fixed
                type: 'DATE_HISTOGRAM', // fixed
                additional: {
                  interval: selectedInterval,
                },
              },
            ],
            limit: pageSizeReport,
            offset: pageNumberReport,
          },
        ],
      },
    ],
  };
  return searchQueryFilter;
}

/* istanbul ignore next */
export function getUserInfo(label: string) {
  return localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))?.[label]
    ? JSON.parse(localStorage.getItem('userInfo'))?.[label]
    : '';
}
export function formatStringIntoDate(dateString: string) {
  const dateParts = dateString.split('/');

  // Ensure there are three parts (day, month, and year)
  if (dateParts.length !== 3) {
    throw new Error("Invalid date format. Please use 'dd/mm/yyyy'.");
  }

  // Extract day, month, and year
  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based (0-11)
  const year = parseInt(dateParts[2], 10);

  // Create a Date object
  const convertedDate = new Date(year, month, day);

  return convertedDate;
}

export function getPreparedRoleData(finalRoles: any, data: any) {
  /* istanbul ignore next */
  if (finalRoles?.length === 0) {
    /* istanbul ignore next */
    data?.forEach((roles: any) => {
      let roleData = {
        roleId: roles?.roleId,
        roleName: roles?.roleName,
        siteLayoutId: roles?.siteLayoutId,
        defaultPageId: roles?.defaultPageId,
        defaultColors: roles?.defaultColors,
        uicontrolsMap: roles?.uicontrolsMap,
        themeId: roles?.themeId,
        footerId: roles?.footerId,
        headerId: roles?.headerId,
        dceoPageId: roles?.dceoPageId,
        layoutName: 'Default Layout',
        device: roles?.device,
      };
      /* istanbul ignore next */
      if (roleData?.siteLayoutId || roleData?.defaultPageId || roleData?.footerId || roleData?.headerId) {
        roleData.layoutName = 'Custom Layout';
      }
      finalRoles.push(roleData);
    });
  } else {
    /* istanbul ignore next */
    data?.forEach((role: any) => {
      /* istanbul ignore next */
      let index = finalRoles?.findIndex((metaData: any) => {
        return metaData?.roleId == role?.roleId;
      });
      /* istanbul ignore next */
      if (index === -1) {
        /* istanbul ignore next */
        let roleData = {
          roleId: role?.roleId,
          roleName: role?.roleName,
          siteLayoutId: role?.siteLayoutId,
          defaultPageId: role?.defaultPageId,
          defaultColors: role?.defaultColors,
          uicontrolsMap: role?.uicontrolsMap,
          themeId: role?.themeId,
          footerId: role?.footerId,
          headerId: role?.headerId,
          dceoPageId: role?.dceoPageId,
          layoutName: 'Default Layout',
          device: role?.device,
        };
        /* istanbul ignore next */
        if (roleData?.siteLayoutId || roleData?.defaultPageId || roleData?.footerId || roleData?.headerId) {
          roleData.layoutName = 'Custom Layout';
        }
        finalRoles.push(roleData);
      } else {
        /* istanbul ignore next */
        if (role?.defaultPageId) {
          /* istanbul ignore next */
          finalRoles[index].defaultPageId = role?.defaultPageId;
        }
        /* istanbul ignore next */
        if (role?.siteLayoutId) {
          /* istanbul ignore next */
          finalRoles[index].siteLayoutId = role?.siteLayoutId;
        }
        /* istanbul ignore next */
        if (role?.defaultColors) {
          /* istanbul ignore next */
          finalRoles[index].defaultColors = role?.defaultColors;
        }
        /* istanbul ignore next */
        if (role?.themeId) {
          /* istanbul ignore next */
          finalRoles[index].themeId = role?.themeId;
        }
        /* istanbul ignore next */
        if (role?.footerId) {
          /* istanbul ignore next */
          finalRoles[index].footerId = role?.footerId;
        }
        /* istanbul ignore next */
        if (role?.headerId) {
          /* istanbul ignore next */
          finalRoles[index].headerId = role?.headerId;
        }
        /* istanbul ignore next */
        if (role?.uicontrolsMap) {
          /* istanbul ignore next */
          finalRoles[index].uicontrolsMap = role?.uicontrolsMap;
        }
        /* istanbul ignore next */
        if (role?.dceoPageId) {
          /* istanbul ignore next */
          finalRoles[index].dceoPageId = role?.dceoPageId;
        }

        /* istanbul ignore next */
        if (
          finalRoles[index]?.siteLayoutId ||
          finalRoles[index]?.defaultPageId ||
          finalRoles[index]?.footerId ||
          finalRoles[index]?.headerId
        ) {
          finalRoles[index].layoutName = 'Custom Layout';
        }

        /* istanbul ignore next */
        if (role?.device) {
          /* istanbul ignore next */
          finalRoles[index].device = role?.device;
        }
      }
    });
  }
  return finalRoles;
}

export function findLocale(selectedCurrency: any) {
  let a = currency.find((cu: any) => cu.name === selectedCurrency);
  return a?.locale || 'en-US';
}

export function parseLocal(value: any, locale: any) {
  let thousand = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  let decimal = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');
  return parseFloat(value?.replace(new RegExp('\\' + thousand, 'g'), '').replace(new RegExp('\\' + decimal), '.'));
}

export function setAdvSearchConfig(data: any) {
  const myLibraryBets = ['ALL', 'BOOK', 'GE', 'GSI', 'RECORDS'];
  const nslLibraryBets = ['ALL', 'BOOK', 'GE', 'GSI'];
  let overallBets = [];
  let isRecords: boolean = false;
  let enabledBets: any = { myLibrary: [], nslLibrary: [], myLibDisplayNames: {}, nslLibDisplayNames: {} };
  myLibraryBets.forEach((x) => {
    if (data?.myLibrary[x]?.enabled) {
      enabledBets.myLibrary.push(x);
      const y = x == 'RECORDS' ? 'Records' : x;
      enabledBets.myLibDisplayNames = {
        ...enabledBets.myLibDisplayNames,
        [y]: data?.myLibrary[x]?.displayName,
      };
      // enabledBets.myLibDisplayNames.push({name: x, displayName: data?.myLibrary[x]?.displayName});
    }
  });
  nslLibraryBets.forEach((x) => {
    if (data?.nslLibrary[x]?.enabled) {
      enabledBets.nslLibrary.push(x);
      // enabledBets.nslLibDisplayNames.push({[x]: data?.myLibrary[x]?.displayName});
      enabledBets.nslLibDisplayNames = {
        ...enabledBets.nslLibDisplayNames,
        [x]: data?.nslLibrary[x]?.displayName,
      };
    }
  });

  overallBets = [...enabledBets.myLibrary, ...enabledBets.nslLibrary];
  if (overallBets.length == 1 && overallBets[0] == 'RECORDS') isRecords = true;

  const response = {
    isRecords: isRecords,
    config: data,
    myLibraryBets: enabledBets.myLibrary,
    nslLibraryBets: enabledBets.nslLibrary,
    myLibDisplayNames: enabledBets.myLibDisplayNames,
    nslLibDisplayNames: enabledBets.nslLibDisplayNames,
  };
  localStorage.setItem('advSearchConfig', JSON.stringify(response));

  return overallBets.length > 0;
}

export function getAdvSearchFetchPayload(searchString: string, bets?: any): AdvSearchFetchConfig {
  let advSearchFetchConfig: AdvSearchFetchConfig = { ...new AdvSearchFetchConfig() };
  let bet: string = 'Records';
  if (bets) {
    const data = bets.filter((x: any) => x != 'ALL');
    bet = data[0] == 'RECORDS' ? bet : data[0];
  }
  return {
    ...advSearchFetchConfig,
    searchCriteria: searchString,
    recordType: bet,
    pageSize: 50,
    gsFilters: null,
  };
}

export function returnMobileNumber(event: any, mobileNumberFormat: string, backspace?: any) {
  if (mobileNumberFormat && mobileNumberFormat == 'USA') {
    let newVal = event.replace(/\D/g, '');
    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
  }
  if (mobileNumberFormat && mobileNumberFormat == 'MEXICO') {
    let newVal = event.replace(/\D/g, '');

    if (backspace && newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1 $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1 $2 $3');
    }
    return newVal;
  }
}
/**
 * @param data : Accepts entity index,entityname, attributename,layertype
 * @returns string for example PL.SL001.entityname.attrname
 */

export function generateFormulaId(data: {
  layerType: string;
  entityIndex: any;
  entityName: string;
  attributeName: string;
  isForDependent?: boolean;
  isEntityname?: boolean;
  membershipWithId?: boolean;
}) {
  /* istanbul ignore next */
  if (typeof data?.entityIndex == 'string') {
    data.entityIndex = parseInt(data?.entityIndex);
  }
  let indexPadding: string = getIndexPadding(data?.entityIndex + 1);
  let dot = '.';
  let layerShortCut: string = 'PL.SL' + indexPadding;
  if (data?.layerType == 'information') {
    layerShortCut = 'IL.SL' + indexPadding;
  } else if (data.layerType == 'triggerCES') {
    /*istanbul ignore else*/
    layerShortCut = 'triggerCES.SL' + indexPadding;
  }
  let formulaId =
    data?.isForDependent && !data?.isEntityname
      ? layerShortCut + dot + 'EN' + data.entityName
      : layerShortCut + dot + data.entityName;
  /* istanbul ignore next */
  if (data?.membershipWithId) {
    formulaId = layerShortCut + dot + 'EN' + data?.entityName;
  }
  if (data?.membershipWithId && data?.attributeName) {
    formulaId = formulaId + dot + 'AT' + data?.attributeName;
  } else if (data?.attributeName) {
    formulaId = formulaId + dot + data?.attributeName;
  }
  /* istanbul ignore next*/
  return formulaId;
}


export function isSearchAttributeVal(attributeValue: string, attributes: any[]) {
  attributes.forEach((attr: any) => {
    if (attributeValue) {
      attr.isSearched = false;
      /* istanbul ignore else */
      if (attr?.name.includes(attributeValue)) {
        attr.isSearched = true;
      }
    } else {
      attr.isSearched = true;
    }
  });
}
export function getDependentAttributeValue(validator: any, formData: any, valueSelect?: any, field?: any) {
  attributeData = null;
  /* istanbul ignore next*/
  dependentAttributeValue(validator?.dependentExpression, formData, valueSelect, field);
  return attributeData;
}
export function setErrorDependentAttribute(validator: any, field: any, group: any) {
  /* istanbul ignore next*/
  if (validator?.type !== 'INFO') {
    /* istanbul ignore next*/
    group?.controls[field?.attribute?.name]?.setErrors({ [validator?.name || 'incorrect']: true });
    /* istanbul ignore next*/
    group?.setErrors({ invalid: true });
  }
}

export function validateDependentExpression(
  validator: any,
  eventsService: EventsFacadeService,
  field: any,
  group: any,
  transactionFacadeService?: TransactionFacadeService
) {
  /* istanbul ignore next*/
  let formData = eventsService?.formData;
  let flag = false,
    val = undefined;
  /* istanbul ignore next*/
  if((group?.controls[field?.attribute.name]?.value!=null &&group?.controls[field?.attribute.name]?.value!=undefined && (group?.controls[field?.attribute.name]?.value?.toString()!=''||field?.entityName?.includes('NSL_SlotBooking')))){
  switch (validator?.name) {
    case 'smallerThan':
    case 'beforeValidation':
      val = getDependentAttributeValue(validator, formData, false, field);
      /* istanbul ignore next*/
      if (
        ((field?.type == 'number' || field?.type =='short') &&
          group?.controls[field?.attribute?.name]?.value >=
            (Number.isInteger(val) ? val :(typeof val == 'string' ? parseInt(val) : parseInt(validator?.value)))) ||
        ((field?.type == 'decimal' || field?.type == 'currency' || field?.type =='metric') &&
          parseFloat(group?.controls[field?.attribute?.name]?.value) >=
            (val?.length ? parseFloat(val) : parseFloat(validator?.value)))
            ||(field?.type=='period' && comparePeriods(group, field,val ,validator)>=0)
            ||(field?.type=='duration' && compareDurations(group, field,val ,validator)>=0)
      ) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;
    case 'smallerThanOrEqual':
    case  'onorbeforeValidation':
    case 'max':
      val = getDependentAttributeValue(validator, formData, false, field);
      if (
        ((field?.type == 'number' || field?.type =='short') &&
          group?.controls[field?.attribute?.name]?.value >
          (Number.isInteger(val) ? val :(typeof val == 'string' ? parseInt(val) : parseInt(validator?.value)))) ||
        ((field?.type == 'decimal' || field?.type == 'currency' || field?.type =='metric') &&
          parseFloat(group?.controls[field?.attribute?.name]?.value) >
            (val?.length ? parseFloat(val) : parseFloat(validator?.value)))
            ||(field?.type=='period' && comparePeriods(group, field,val ,validator)>0)
            ||(field?.type=='duration' && compareDurations(group, field,val ,validator)>0)
      ) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;
    case 'greaterThan':
    case 'afterValidation':
    case 'positive':
      val = getDependentAttributeValue(validator, formData, false, field);
      if (
        ((field?.type == 'number' || field?.type =='short') &&
          group?.controls[field?.attribute?.name]?.value <=
          (Number.isInteger(val) ? val :(typeof val == 'string' ? parseInt(val) : parseInt(validator?.value)))) ||
        ((field?.type == 'decimal' || field?.type == 'currency' || field?.type =='metric') &&
          parseFloat(group?.controls[field?.attribute?.name]?.value) <=
            (val?.length ? parseFloat(val) : parseFloat(validator?.value)))
            ||(field?.type=='period' && comparePeriods(group, field,val ,validator)<=0)
            ||(field?.type=='duration' && compareDurations(group, field,val ,validator)<=0)
      ) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;
    case 'greaterThanOrEqual':
    case 'onorafterValidation':
    case 'positiveorzero':
    case 'min':
      val = getDependentAttributeValue(validator, formData, false, field);
      if (
        ((field?.type == 'number' || field?.type =='short') &&
          group?.controls[field?.attribute?.name]?.value <
            (Number.isInteger(val) ? val :(typeof val == 'string' ? parseInt(val) : parseInt(validator?.value)))) ||
        ((field?.type == 'decimal' || field?.type == 'currency' || field?.type =='metric') &&
          parseFloat(group?.controls[field?.attribute?.name]?.value) <
            (val?.length ? parseFloat(val) : parseFloat(validator?.value)))
            ||(field?.type=='period' && comparePeriods(group, field,val ,validator)<0)
            ||(field?.type=='duration' && compareDurations(group, field,val ,validator)<0)
      ) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;
    case 'equalTo':
      val = getDependentAttributeValue(validator, formData, false, field);
      if (
        ((field?.type == 'number' || field?.type =='short') &&
          group?.controls[field?.attribute?.name]?.value !=
            (Number.isInteger(val) ? val :(typeof val == 'string' ? parseInt(val) : parseInt(validator?.value)))) ||
        ((field?.type == 'text' || field?.type == 'textarea' || field?.type =='char' || field?.type =='email') && group?.controls[field?.attribute?.name]?.value != (val ? val : validator?.value)) ||
        ((field?.type == 'decimal' || field?.type == 'currency' || field?.type =='metric') &&
          group?.controls[field?.attribute?.name]?.value != (val?.length ? parseFloat(val) : parseFloat(validator?.value))) ||
        (field?.type == 'radiobutton' &&
          group?.controls[field?.attribute?.name]?.value != (val ? val : validator?.value)) || (field?.type == 'mobilenumber' &&
          group?.controls[field?.attribute?.name]?.value.toString() !=
            (typeof val == 'string' ? val : validator?.value.toString())) ||
            (field?.type == 'typeahead' && group?.controls[field?.attribute?.name]?.value != (val ? val : validator?.value))
            ||(field?.type=='period' && comparePeriods(group, field,val ,validator)!=0)
            ||(field?.type=='duration' && compareDurations(group, field,val ,validator)!=0)
      ) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;

    case 'notequalTo':
      val = getDependentAttributeValue(validator, formData, false, field);
      if (
        ((field?.type == 'number' || field?.type =='short') &&
          group?.controls[field?.attribute?.name]?.value ==
            (Number.isInteger(val) ? val :(typeof val == 'string' ? parseInt(val) : parseInt(validator?.value)))) ||
        ((field?.type == 'text' || field?.type =='char' || field?.type == 'textarea' || field?.type =='email') && group?.controls[field?.attribute?.name]?.value == (val ? val : validator?.value)) ||
        ((field?.type == 'decimal' || field?.type == 'currency' || field?.type =='metric') &&
          group?.controls[field?.attribute?.name]?.value == (val?.length ? parseFloat(val) : parseFloat(validator?.value))) ||
        (field?.type == 'radiobutton' &&
          group?.controls[field?.attribute?.name]?.value == (val ? val : validator?.value)) ||
        (field?.type == 'mobilenumber' &&
          group?.controls[field?.attribute?.name]?.value.toString() ==
            (typeof val == 'string' ? val : validator?.value.toString())) ||
            ((field?.type == 'typeahead' || field?.type == 'dropdown') && group?.controls[field?.attribute?.name]?.value == (val ? val : validator?.value))
             ||(field?.type=='period' && comparePeriods(group, field,val ,validator)==0)
             ||(field?.type=='duration' && compareDurations(group, field,val ,validator)==0)
      ) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;

    case 'valuesSelect':
      val = getDependentAttributeValue(validator, formData, false, field) || field.value || [];
      if (Array.isArray(val) && val?.length && (val?.length > parseInt(validator?.max) || val?.length < parseInt(validator?.min))) {
        setErrorDependentAttribute(validator, field, group);
        flag = true;
      }
      break;
    case 'hyperLink':
      val = getDependentAttributeValue(validator, formData, true, field) || field.value || [];
      if (Array.isArray(val) && val[0].value) {
        return val[0].value;
      }
      break;
    case 'NSL_SlotBooking':
      val = getDependentAttributeValue(validator, formData, true, field);
      if (val) return val[0].attr_options;
      break;
    case 'length':
      val = getDependentAttributeValue(validator, formData, false, field);
      if (field?.type == 'editor' &&
            group?.controls[field?.attribute?.name]?.value?.replace(/<[^>]*>/g, '')?.length !=
              (Number.isInteger(val) ? val : parseInt(validator?.value)))
            {
              setErrorDependentAttribute(validator, field, group);
              flag = true;
              }
      break;
  }
}
  group?.status === 'INVALID'
    ? transactionFacadeService.disableSubmitButtonFlag.next(true)
    : transactionFacadeService.disableSubmitButtonFlag.next(false);
  return flag;
}

export function validateRights(gsiData: any, action: any) {
  /* istanbul ignore next */
  for (let cu of gsiData[action]) {
    if (cu?.agents && cu?.agents[0]?.agentType == 'machine') {
      cu.txnTimeRights = [];
      cu.designTimeRights = [];
    } else {
      if (cu && cu?.designTimeRights && cu?.designTimeRights?.length > 0) {
        cu.designTimeRights = removeEmptyConditions(cu.designTimeRights);
        cu.txnTimeRights = removeEmptyConditions(cu.txnTimeRights);
      }
    }
  }
  return gsiData;
}
function removeEmptyConditions(objArray: any[]) {
  return objArray?.map((obj: any) => {
    if (obj?.condition?.fromDateTime === '' && obj?.condition?.toDateTime === '') {
      delete obj.condition;
    }
    return obj;
  });
}

export function updateBetSelectedItems(item: string, selectedChangedriverOpt: any): any[] {
  let selectedItems = selectedChangedriverOpt || [];
  if (item) {
    if (selectedItems?.length == 0 && item === 'NONE') {
      selectedItems = [];
    } else if (selectedItems?.length > 1 && item === 'NONE') {
      selectedItems = ['NONE'];
    } else if (item != 'NONE') {
      selectedItems = selectedItems?.filter((i: any) => i !== 'NONE');
    }
  }
  return selectedItems;
}

// ['ent3.ent2.ent1.id' => 'id']
export function splitAndDisplayLastElement(attr: string) {
  const splittedArr = attr.split('.');
  return splittedArr.pop();
}

//enables edit gsi by solution customizer role
export function checkSolutionCustomizer() {
  let userinfo = localStorage.getItem('userInfo') || localStorage.getItem('ActiveUserInfo');
  let UserInfo = JSON.parse(userinfo);
  let isSolutionCustomizer = false;
  /*istanbul ignore else*/
  if (UserInfo) {
    /*istanbul ignore next*/
    const findIndexUser = UserInfo?.roles?.find((role: any) => role.name == 'SolutionCustomizer');
    /*istanbul ignore else*/
    if (findIndexUser) {
      isSolutionCustomizer = true;
    }
  }
  return isSolutionCustomizer;
}

/**
 * Corrects the given file URL to prevent CORS errors.
 * @author Ajit Dhayal
 * @param fileUrl The original file URL to be corrected.
 * @returns The corrected file URL.
 */
 export function correctDsdFileUrl(fileUrl: any) {
  let url = getHostUrl();

  // If the URL does not contain 'https://', it may lead to CORS errors, so we will correct it.
  if (fileUrl && !fileUrl?.includes('https://')) {
    fileUrl = url + fileUrl;
  }
  // Even if the URL contains 'https://', there might be missing tenant information or other issues, leading to CORS problems, so we will correct it.
  else if (fileUrl && fileUrl?.includes('https://')) {
    const nslhubIndex = fileUrl?.indexOf("nslhub.com");
    // Extract the substring starting from "nslhub.com"
    const modifiedUrl = fileUrl?.substring(nslhubIndex + 10);
    // Append the provided base URL
    fileUrl = url + modifiedUrl;
  }

  return fileUrl;
}


/**
 * Parses the given time string into an object containing years, months, weeks, days, hours, and minutes.
 * @author Ajit Dhayal
 * @returns An object containing the parsed time components.
 */
export function parseTime(timeString: string, isDuration: boolean): any {

  const regex = isDuration ? /P(?:([0-9]+)D)?(?:T(?:([0-9]+)H)?(?:([0-9]+)M)?)?/ : /P(?:([0-9]+)Y)?(?:([0-9]+)M)?(?:([0-9]+)W)?(?:([0-9]+)D)?/;
  const matches = timeString ? timeString.match(regex) : null;

  return {
      years: isDuration ? 0 : matches?.[1] ? parseInt(matches[1]) : 0,
      months: isDuration ? 0 : matches?.[2] ? parseInt(matches[2]) : 0,
      weeks: isDuration ? 0 : matches?.[3] ? parseInt(matches[3]) : 0,
      days: isDuration ? (matches?.[1] ? parseInt(matches[1]) : 0) : matches?.[4] ? parseInt(matches[4]) : 0,
      hours: isDuration ? (matches?.[2] ? parseInt(matches[2]) : 0) : 0,
      minutes: isDuration ? (matches?.[3] ? parseInt(matches[3]) : 0) : 0,
  };
}

/**
 * Compares two periods and returns a number representing their comparison.
 * @author Ajit Dhayal
 * @returns A number(positive if the first period is greater, negative if the second period is greater, zero if equal).
 */

export function comparePeriods(group: any, field: any, val: any, validator: any): number {
  const period1 = group?.controls[field?.attribute?.name]?.value;
  const period2 = val ? val : validator?.value;

  const parsedTime1 = parseTime(period1, false);
  const parsedTime2 = parseTime(period2, false);

  const propertiesToCompare = ['years', 'months', 'weeks', 'days'];
  for (const prop of propertiesToCompare) {
      if (parsedTime1[prop] !== parsedTime2[prop]) {
          return parsedTime1[prop] - parsedTime2[prop];
      }
  }
  return 0;
}

/**
 * Compares two durations and returns a number representing their comparison.
 * @author Ajit Dhayal
 * @returns A number(positive if the first duration is greater, negative if the second duration is greater, zero if equal).
 */

export function compareDurations(group: any, field: any, val: any, validator: any): number {
  const duration1 = group?.controls[field?.attribute?.name]?.value;
  const duration2 = val ? val : validator?.value;

  const parsedDuration1 = parseTime(duration1, true);
  const parsedDuration2 = parseTime(duration2, true);

  const totalMinutes1 = parsedDuration1.days * 24 * 60 + parsedDuration1.hours * 60 + parsedDuration1.minutes;
  const totalMinutes2 = parsedDuration2.days* 24 * 60 + parsedDuration2.hours * 60 + parsedDuration2.minutes;

  return totalMinutes1 - totalMinutes2;
}
export function getDTFormat() {
  let dateTime = new Date();
  return (
    dateTime.getFullYear() +
    '-' +
    ('0' + (dateTime.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + dateTime.getDate()).slice(-2) +
    'T' +
    dateTime.getHours() +
    ':' +
    dateTime.getMinutes() +
    ':' +
    dateTime.getSeconds() +
    '.' +
    dateTime.getMilliseconds() +
    'Z'
  );
}
/**
 * generated an Object For Impression API call
 * @param ad
 * @param data
 * @returns
 */
 export function generateImpressionCallObject(ad:any, data:any){
  /*istanbul ignore next*/
  const {
    adId,
    adName,
    adSize,
    adType,
    advertiserId,
    bidAmount,
    campaignId,
    campaignName,
    advertisementId,
    layout,
    impressionId,
    tenant,
    ontology,
  } = ad;
  /* istanbul ignore next */
  let adObj = {
    adId: adId,
    adName: adName,
    adSize: adSize,
    adType: adType,
    advertiserId: advertiserId,
    advertiserName: advertisementId,
    campaignId: campaignId,
    campaignName: campaignName,
    bidAmount: bidAmount,
    orgUnitId: data?.userInfo?.orgUnitId,
    campaignType: ad?.gsiData?.adSlotType,
    correlatorValue: ad?.gsiData?.correlatorValue,
    platformType: 'Desktop',
    timestamp: new Date().getTime(),
    uniqueImpressionId: impressionId?.value,
    publisherId: data?.localStorage?.TenantName,
    layout: layout,
    solutionName: data?.gsiName,
    userId: ad?.gsiData?.userId,
    sector: ontology,
    tenant: tenant,
    geoLocation: ad?.gsiData?.geoLocation,
    transactionId: data.transId
  };
  return adObj;
}

/**
 * generated an Object For ClickAd API call
 * @param ad
 * @param data
 * @returns
 */
export function generateClickadObject(clickedAd: any,data:any){
  /*istanbul ignore next*/
  const {
    adId,
    adName,
    advertiserId,
    campaignId,
    impressionId,
    bidAmount,
    tenant,
    ontology,
    campaignName,
    adType,
  } = clickedAd;
  /* istanbul ignore next */
  let adObj = {
    adId: adId,
    adName: adName,
    advertiserId: advertiserId,
    campaignId: campaignId,
    campaignName: campaignName,
    campaignType: clickedAd?.gsiData?.adSlotType,
    bidAmount: bidAmount,
    correlatorValue: clickedAd?.gsiData?.correlatorValue,
    platformType: 'Desktop',
    timestamp: new Date().getTime(),
    uniqueClickId: data?.uniqueClickId?.value,
    uniqueImpressionId: impressionId?.value,
    publisherId: data?.localStorage?.TenantName,
    solutionName: data?.gsiName,
    orgUnitId: data?.userInfo?.orgUnitId,
    tenant: tenant,
    userId: clickedAd?.gsiData?.userId,
    sector: ontology,
    adType: adType,
    geoLocation: clickedAd?.gsiData?.geoLocation,
  };
  return adObj;
}

/* istanbul ignore next */
export function getMenu(menus: any, pageUrl: any) {
  /* istanbul ignore next */
  if (menus && menus?.length > 0) {
    /* istanbul ignore next */
    let menu = menus?.find((menu: any) => menu?.pageUrl === pageUrl);
    /* istanbul ignore next */
    if (menu) return menu;
  }
  return {};
}

/* istanbul ignore next */
export function getHeaderText(menuMappedBet: any, flag: boolean = false) {
  let staticText: string = '';
  let dynamicText: string = '';

  /* istanbul ignore next */
  if (menuMappedBet?.name && menuMappedBet?.name?.includes('`+`')) {
    /* istanbul ignore next */
    let nameSplit = menuMappedBet?.name?.split('`+`');
    staticText = nameSplit[0];
    dynamicText = nameSplit[1];
  } else if (menuMappedBet?.name) {
    staticText = menuMappedBet?.name;
  }

  /* istanbul ignore next */
  if (menuMappedBet?.id) {
    /* istanbul ignore next */
    dynamicText = menuMappedBet?.id;
  }

  /* istanbul ignore next */
  if (flag) {
    let userInfo = localStorage.getItem('userInfo');
    /* istanbul ignore next */
    if (dynamicText && userInfo && IsJsonString(userInfo)) {
      /* istanbul ignore next */
      userInfo = JSON.parse(userInfo);
      /* istanbul ignore next */
      if (dynamicText === 'fullName') {
        /* istanbul ignore next */
        dynamicText = userInfo?.['firstName'] ? userInfo?.['firstName'] : '' + ' ' + userInfo?.['lastName'] ? userInfo?.['lastName'] : '';
      } else {
        /* istanbul ignore next */
        dynamicText = userInfo?.[dynamicText] ? userInfo?.[dynamicText] : '';
      }
    } else {
      dynamicText = '';
    }
  }

  /* istanbul ignore next */
  return staticText + dynamicText;
}
