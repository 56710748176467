<div>
  <mat-dialog-content class="alert-dialog py-3">
    <div class="users-table">
      <label>{{ labels?.Alert }}</label>
    </div>
  </mat-dialog-content>
  <div class="mat-dialg-center">
    <p>
      The {{ betType }} {{ labels?.you_are_trying_to_edit_is_present_in_other_solutions }} (<span
        (click)="showList()"
        >{{ labels?.see_list }}</span
      >)
    </p>
  </div>
  <mat-dialog-actions class="justify-content-end">
    <button mat-button (click)="clone(cloneTemplate)">Clone</button>
    <button mat-button (click)="upgrade()">{{ labels?.Upgrade_Version }}</button>
    <button mat-button mat-dialog-close (click)="closeMenu()">{{ labels?.Cancel }}</button>
  </mat-dialog-actions>
</div>

<ng-template #cloneTemplate>
  <div class="clone-changed-popup">
    <form>
      <input
        type="text"
        name="cloneName"
        [(ngModel)]="cloneName"
        autofocus
        [placeholder]="labels?.Name_Your_Cloned_Change_Unit"
      />
    </form>
    <mat-dialog-actions>
      <div class="clone-changed-popup-action-btns">
        <button mat-button mat-dialog-close (click)="cloneData()">{{ labels?.Next }}</button>
        <button mat-button (click)="cancelClone()">
          {{ labels?.Cancel }}
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-template>
