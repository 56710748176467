import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { TableModule } from "primeng/table";
import { TreeModule } from "primeng/tree";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  exports: [
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatOptionModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTableModule,
    TableModule,
    TreeModule,
    DragDropModule,
    MatTabsModule,
    // FusionChartsModule,
    MatInputModule,
    MatSelectModule,
    
  ],
})
export class MaterialModule { }