<!-- <p>node-ocr-upload works!</p> -->
<div class="upload-wrapp">
  <h4>Upload Documents</h4>
  <div class="upload-area">
    <div class="upload-sub-area" appDnd (fileDropped)="onFileSelected($event)">
      <div class="upload-item">
        <span><img src="../../../../assets/images/upload-icon-extract.png" /></span>
      </div>

      <div class="upload-item">
        <input type="file" (change)="onFileSelected($event)" id="dragNdrop" class="dragNdropInput" />
        <label for="dragNdrop">Drag and drop file </label>
      </div>
      <div class="file file-upload">
        <label for="input-file">
          <!-- <i class="material-icons">cloud_upload</i>  -->
          Browse file
        </label>
        <input id="input-file" type="file" (change)="onFileSelected($event)" />
      </div>
    </div>
    <span class="note">Only jpeg, png, gif and pdf files with max size of 2mb</span>
  </div>
  <!-- <div *ngIf="showProgress">
    <mat-progress-bar mode="determinate" value="progress"></mat-progress-bar>
  </div> -->
</div>
