import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  GsiFacadeService, Librarytypes,
  NodeGsiFacadeService,
  TranslatorService
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-node-book-edit',
  templateUrl: './node-book-edit.component.html',
  styleUrls: ['./node-book-edit.component.scss'],
})
export class NodeBookEditComponent implements OnInit, OnDestroy {
  @Input() tempGsiObj: any;
  @Input() configurationData: any;
  @Output() emitChangeCompoent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closegsi: EventEmitter<any> = new EventEmitter<any>();
  close: boolean = false;

  roles: any = [];
  selectedindex: any;
  step: number = 0;
  labels: any;
  bookData: any;

  private ngUnsubscribe = new Subject();
  constructor(
    private gsiFacadeService: GsiFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.bookData = this.configurationData[0]?.data?.bookData;
    /* istanbul ignore next */
    this.bookData?.designTimeRights?.forEach((role: any) => {
      this.roles.push({
        name: role.name ? role.name : role.rightHolderName,
        id: role.rightHolderId,
        rightHolderId: role.rightHolderId,
      });
    });
  }
  getDesignRightsIndex(role: any) {
    /* istanbul ignore next */
    if (this.bookData?.designTimeRights && this.bookData?.designTimeRights.length > 0 && role) {
      return this.bookData.designTimeRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.id === role.id
      );
    }
    return -1;
  }
  getTxnRightsIndex(role: any) {
    /* istanbul ignore next */
    if (this.bookData?.txnTimeRights && this.bookData?.txnTimeRights.length > 0 && role) {
      return this.bookData.txnTimeRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.id === role.id
      );
    }
    return -1;
  }

  removeAgent(i: any) {
    this.roles.splice(i, 1);
    this.bookData.designTimeRights.splice(i, 1);
    this.bookData.txnTimeRights.splice(i, 1);
  }
  showRights(i: any) {
    this.selectedindex = i;
  }
  GotoPrevious() {
    this.emitChangeCompoent.emit(Librarytypes.AddNodeActionMenu);
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  closeGsi() {
    this.close = true;
    this.closegsi.emit(this.close);
  }
  saveBook() {
    this.GotoPrevious();
    this.gsiFacade.saveBook(this.bookData);
    this.closeGsi();
  }
  setStep(index: number) {
    this.step = index;
  }

  menuenter() {
    this.gsiFacadeService.menuenter();
  }

  menuLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }

  buttonEnter(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }

  buttonLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
