<div
  class="gsibuttons"
  [ngClass]="{
    'nh-mb-6 nh-pb-6':
      tableActionButtons?.length > 0 ||
      exportConfigurations?.pdf || exportConfigurations?.csv || exportConfigurations?.excel
  }"
>
  <ng-container *ngIf="tableActionButtons?.length > 0">
    <ng-container   *ngFor="let btn of tableActionButtons; index as ind">
    <button
      pTooltip="{{ btn?.displayName }}"
      tooltipPosition="top"
    *ngIf="((!inTableGSIButtons && !isMore) ||((inTableGSIButtons || isMore)&& btn.isUnrelated )) && (btn?.configuration?.gsiDisplay != 'onlyIcon')"
      (click)="gotoTxnPage(btn, selectedRecord)"
      [ngStyle]="{
        'background-color': gsiBtnHovered?.[ind] ? gsiStyle?.colorHover?.[ind]: gsiStyle?.backgroundColor?.[ind],
        'color': gsiBtnHovered?.[ind] ? gsiStyle?.fontHover?.[ind]: gsiStyle?.fontColor?.[ind] ,
        'border-color': gsiBtnHovered?.[ind] ? gsiStyle?.gsihoverborderColor?.[ind]: gsiStyle?.gsiborderColor?.[ind],
        'border-width': gsiStyle?.gsiborderWidth ? gsiStyle?.gsiborderWidth + 'px' : '0px',
        'font-family': gsiStyle?.text?.fontFamily?.font,
        'text-decoration-line': gsiStyle?.text?.underLine ? 'underline' : 'none',
        'font-weight': gsiStyle?.text?.bold ? 'bold' : 'normal',
        'font-style': gsiStyle?.text?.italic ? 'italic' : 'normal',
        'font-size': gsiStyle?.text?.fontSize + 'px',
        'text-align': gsiStyle?.text?.alignment?.justify,
        'border-radius': gsiStyle?.gsiborderRadius + 'px'
      }"
      [ngClass]="{'nh-mr-16': (tableActionButtons?.length > 1) && ind !== tableActionButtons.length - 1}"
      (mouseout)="gsiBtnHovered[ind] = false"
      (mouseover)="gsiBtnHovered[ind] = true"
    >
    <img *ngIf="btn?.configuration?.gsiDisplay === 'gsiNameWithIcon'" class="icon"
    [src]="btn?.configuration?.imageSrc" />
      {{ btn?.displayName }}
    </button>
    <button
      pTooltip="{{ btn?.displayName }}"
      tooltipPosition="top"
      *ngIf="((!inTableGSIButtons && !isMore) ||((inTableGSIButtons || isMore)&& btn.isUnrelated )) && (btn?.configuration?.gsiDisplay == 'onlyIcon')"
      (click)="gotoTxnPage(btn, selectedRecord)"
      [ngClass]="{'nh-mr-16': (tableActionButtons?.length > 1) && ind !== tableActionButtons.length - 1}"
    >
    <img  [src]="btn?.configuration?.imageSrc" (click)="gotoTxnPage(btn, selectedRecord)">
    </button>
   
  </ng-container>
  </ng-container>
  <ng-container *ngIf="tableActionButtons?.length > 0 && !inTableGSIButtons && isMore">
    <button class="gsibtn-only-icon" [ngClass]="{'ontop-combine': isMenuIconOnly && combinationName == ''}" (click)="more.toggle($event)">
      <img [src]="menuIconValue" class="custom-icon" *ngIf="isMenuIconOnly" [ngClass]="{ active:false }"> 
      <span>{{ combinationName }}</span>  
    </button>
    <p-overlayPanel styleClass="filterOverlay menu-gsi" #more>
      <div class="checkboxes">
        <div class="checckbox" *ngFor="let btn of tableActionButtons; index as ind">
          <mat-option (click)="gotoTxnPage(btn, selectedRecord)" *ngIf="!btn.isUnrelated">
            <div
              class="attr-name text-truncate"
              pTooltip="{{ btn?.displayName }}"
              tooltipPosition="top"
              tooltipStyleClass="index-tooltip"
            >
              {{ btn?.displayName }}
            </div>
          </mat-option>
        </div> 
      </div> 
    </p-overlayPanel>
    <!-- <img [src]="menuIconValue" class="filter-name text-truncate saved-menu" *ngIf="isIconOnlyEnabled" [ngClass]="{ active: false }" > -->
    <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
      <ng-container *ngFor="let btn of tableActionButtons; index as ind">
        <mat-option (click)="gotoTxnPage(btn, selectedRecord)" *ngIf="!btn.isUnrelated">
          <div
            class="attr-name text-truncate" 
            pTooltip="{{ btn?.displayName }}"
            tooltipPosition="top"
            tooltipStyleClass="index-tooltip"
          >
            {{ btn?.displayName }} 
          </div>
        </mat-option>
      </ng-container>
    </mat-menu>
  </ng-container>
  <div
    class="export nh-ml-16"
    *ngIf="
      exportConfigurations?.pdf ||
      exportConfigurations?.csv ||
      exportConfigurations?.excel
    "
  >
    <button (click)="export.toggle($event)">Export</button>
    <p-overlayPanel styleClass="exportOverlay" #export>
      <ul let>
        <li *ngIf="exportConfigurations?.pdf" (click)="downloadData('pdf')">
          PDF
        </li>
        <li *ngIf="exportConfigurations?.csv" (click)="downloadData('csv')">
          CSV
        </li>
        <li *ngIf="exportConfigurations?.excel" (click)="downloadData('excel')">
          Excel
        </li>
      </ul>
    </p-overlayPanel>
  </div>
  <!-- <p-selectButton [options]="sizes" [(ngModel)]="selectedStyle" optionLabel="name"></p-selectButton> -->
</div>
<div
  id="headerDescription"
  class="table-description"
  *ngIf="description?.header?.message"
  (mouseover)="headerHovered = 1"
  (mouseout)="headerHovered = -1"
  [ngStyle]="{
    'background-color':
      headerHovered == 1
        ? description?.header?.style?.colorHover
        : description?.header?.style?.backgroundColor,
    color:
      headerHovered == 1
        ? description?.header?.style?.fontHover
        : description?.header?.style?.fontColor
  }"
>
  {{ description?.header?.message }}
</div>
<p-table
  #dropTableData
  class="entity-table-view"
  [value]="tableData"
  [(selection)]="selectedRecord"
  selectionMode="multiple"
  [metaKeySelection]="!advanceTableFeatures?.multipleSelection"
  [scrollable]="true"
  [scrollHeight]="headerFreeze?.enabled && headerFreeze?.tableHeight? headerFreeze?.tableHeight : '100%'"
  scrollWidth="100%"
  [styleClass]="gridLines?.selectedStyle?.class"
  [ngClass]="{
    'table-outline': gridLines?.outLine?.selected == true,
    'header-nowrap': advanceTableFeatures?.headerNoWrap,
    'body-nowrap': advanceTableFeatures?.bodyNoWrap,
    'nh-mt-6':
      tableActionButtons?.length > 0 ||
      exportConfigurations?.pdf || exportConfigurations?.csv || exportConfigurations?.excel
  }"
  [tableStyle]="{
    'border-color': gridLines?.outLine?.selected ? gridLines?.outLine?.borderColor : 'none',
    'border-width': gridLines?.outLine?.selected ? gridLines?.outLine?.borderThickness + 'px' : '0px',
    'border-radius': gridLines?.outLine?.selected ? gridLines?.outLine?.borderRadius + 'px' : '0px',
    'border-collapse': gridLines?.outLine?.selected ? 'separate' : 'collapse',
    height: heightOfTable,
    width: displayTableWidth
  }"
>
  <mat-menu #matMenu="matMenu" (closed)="menuClosed()" class="menu-Overlay">
    <span class="search-filter">
      <input type="text" placeholder="Search" (click)="$event.stopPropagation()" [(ngModel)]="hfilter" />
      <em class="nh-icon nh-icon-search-line"></em>
      <em class="nh-icon nh-icon-x-lg d-none"></em>
    </span>
    <div class="checkboxes">
      <ng-container *ngFor="let attr of searchConfigurationData?.[index]?.selectedSearchValues | filter: hfilter">
        <mat-checkbox
          disableRipple
          class="menu-checkbox-option"
          [checked]="attr.selected"
          (change)="updateTableData($event,attr,searchConfigurationData?.[index])"
          (click)="$event.stopPropagation()"
        >
          <div
            class="attr-name text-truncate"
            pTooltip="{{ attr?.modifiedValue? attr?.modifiedValue:attr?.displayValue ? attr.displayValue : attr.value }}"
            tooltipPosition="top"
            tooltipStyleClass="index-tooltip"
          >
            {{ attr?.modifiedValue ? attr?.modifiedValue :attr?.displayValue ? attr.displayValue : attr.value }}
          </div>
        </mat-checkbox>
      </ng-container>
    </div>
  </mat-menu>
  <mat-menu #search="matMenu" class="menu-Overlay">
    <!-- <span class="searchbar" *ngIf="searchData?.length > 0">
      <input
        matInput
        (click)="$event.stopPropagation()"
        (keyup)="
          applyFilterAndSort(false, false, false, '', '', 0, {
            checked: true,
            searchAttribute: name,
            searchValue: searchData[index].searchValue
          })
        "
        [(ngModel)]="searchData[index].searchValue"
        type="text"
        placeholder="Search"
      />
      <em class="nh-icon nh-icon-search-line" *ngIf="searchData[index].searchValue?.length == 0"></em>
      <em
        class="nh-icon nh-icon-x-lg"
        (click)="cancelSearch(); closeMatMenu()"
        *ngIf="searchData[index].searchValue?.length != 0"
      ></em>
    </span> -->
  </mat-menu>

  <ng-template pTemplate="header">
    <tr
      class="text-break-word"
      [ngClass]="{
        'row-outline': gridLines?.rows?.selected == true
      }"
      [ngStyle]="{
        'border-color': gridLines?.rows?.selected
          ? gridLines?.rows?.borderColor
          : 'none',
        'border-width': gridLines?.rows?.selected
          ? gridLines?.rows?.borderThickness + 'px'
          : '0px',
        'border-radius': gridLines?.rows?.selected
          ? gridLines?.rows?.borderRadius + 'px'
          : '0px',
        'justify-content': header?.text?.alignment?.viewClass
      }"
    >
      <!-- check box -->
      <th
        class="table-row"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="header"
        [oddRowProperties]="header"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="checkboxHover"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="headerBackground"
        [evenRowColor]="headerBackground"
        (mouseover)="hovered = 1; checkboxHover = 1"
        (mouseout)="hovered = -1; checkboxHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
        *ngIf="advanceTableFeatures?.checkboxSelection"
        style="display: flex; justify-content: center;"
      >
        <p-tableHeaderCheckbox
          *ngIf="advanceTableFeatures?.multipleSelection"
        ></p-tableHeaderCheckbox>
        <ng-container *ngIf="!advanceTableFeatures?.multipleSelection"
          >Select</ng-container
        >
      </th>
      <!-- Nested Row -->
      <th
        class="table-row"
        *ngIf="isNestedRow2"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="header"
        [oddRowProperties]="header"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="serialNumberHover"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="headerBackground"
        [evenRowColor]="headerBackground"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></th>
      <!-- Serial Number -->
      <th
        class="table-row"
        *ngIf="advanceTableFeatures?.serialNumbers"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="header"
        [oddRowProperties]="header"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="serialNumberHover"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="headerBackground"
        [evenRowColor]="headerBackground"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      >
        S.No.
      </th>
      <!-- Attribute Names -->
      <ng-container *ngIf="columnStyles.length == 0">
        <th
          [ngClass]="{ active: false }"
          (click)="index = idx; name = row.attributeName"
          pTooltip="{{ row.displayName }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          (mouseover)="hovered = 1"
          (mouseout)="hovered = -1"
          appTableDirective
          [tenantfont]="tenantfont"
          [rowFillProperties]="true"
          [rowTextProperties]="false"
          [rowIndex]="1"
          [columnIndex]="idx"
          [evenRowProperties]="header"
          [oddRowProperties]="header"
          [evenHoveredIndex]="hovered"
          [oddHoveredIndex]="hovered"
          [columnHoveredIndex]="columnHovered"
          [columnHovered]="advanceTableFeatures?.columnHighlights"
          [conditionArray]="conditionArray"
          [oddRowColor]="headerBackground"
          [evenRowColor]="headerBackground"
          [ngStyle]="{
          width: row.type === 'image' ? widthArrayPercentage?.[idx]?.width : widthArrayPercentage?.[idx],
          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
          'border-right-width': gridLines?.column?.selected ? gridLines?.column?.borderThickness + 'px' : '0px',
          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px',
          'justify-content': header?.text?.alignment?.viewClass
        }"
          *ngFor="let row of headerArray; index as idx"
          class="table-row"
          [ngClass]="{
            'column-outline': gridLines?.column?.selected == true
          }"
        >
          <span
            class="entity-table-header-span"
            [ngClass]="{ wc_56: numberOfButtons == 3, wc_36: numberOfButtons == 2, wc_16: numberOfButtons == 1 }"
            appTableDirective
            [tenantfont]="tenantfont"
            [rowFillProperties]="false"
            [rowTextProperties]="true"
            [rowIndex]="1"
            [columnIndex]="idx"
            [evenRowProperties]="header"
            [oddRowProperties]="header"
            [evenHoveredIndex]="hovered"
            [oddHoveredIndex]="hovered"
            [columnHoveredIndex]="columnHovered"
            [columnHovered]="advanceTableFeatures?.columnHighlights"
            [conditionArray]="conditionArray"
            [oddRowColor]="headerBackground"
            [evenRowColor]="headerBackground"
            >{{ row.displayName }}</span
          >
          <span
            #buttonContainer
            class="table-filter-icons"
            [ngStyle]="{ color: hovered == 1 || columnHovered == idx ? header?.fontHover : header?.fontColor }"
          >
            <!-- <button *ngIf="filterConfigurations?.position == 'header'" (mouseover)="isFilterHover = true" (mouseout)="isFilterHover = false">
              <em class="nh-icon nh-icon-filter-small" *ngIf="!(filterConfigurations?.uploadIcon?.length>0 == true)" [matMenuTriggerFor]="matMenu"></em>
              <img [src]="isFilterHover ? filterConfigurations?.uploadHoverIcon : filterConfigurations?.uploadIcon" *ngIf="filterConfigurations?.uploadIcon?.length>0== true" [matMenuTriggerFor]="matMenu">

            </button>
            <button *ngIf="searchConfigurations?.showHeaderSearch" (mouseover)="isSearchHover = true" (mouseout)="isSearchHover = false">
              <em class="nh-icon nh-icon-search" *ngIf="!(searchConfigurations?.uploadIcon?.length>0)" [matMenuTriggerFor]="search"></em>
              <img [src]="isSearchHover ? searchConfigurations?.uploadHoverIcon : searchConfigurations?.uploadIcon" *ngIf="searchConfigurations?.uploadIcon?.length>0== true" [matMenuTriggerFor]="search" >

            </button>
            <button *ngIf="sortConfigurations?.position == 'header'" (mouseover)="isSortHover = true" (mouseout)="isSortHover = false">
              <img  [src]=" isSortHover ? sortConfigurations?.uploadHoverIcon : sortConfigurations?.uploadIcon " *ngIf="sortConfigurations?.uploadIcon?.length>0== true" (click)="sortFunction(row.attributeName)" >
              <em class="nh-icon nh-icon-Sorting" *ngIf="!(sortConfigurations?.uploadIcon?.length>0== true)" (click)="sortFunction(row.attributeName)"></em>
            </button> -->
          </span>
        </th>
      </ng-container>
      <ng-container *ngIf="columnStyles.length > 0">
        <th
          [ngClass]="{ active: false }"
          (click)="index = idx; name = row.attributeName"
          pTooltip="{{ columnStyles[idx].displayName }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          (mouseover)="hovered = 1"
          (mouseout)="hovered = -1"
          appTableDirective
          [tenantfont]="tenantfont"
          [rowFillProperties]="true"
          [rowTextProperties]="false"
          [rowIndex]="1"
          [columnIndex]="idx"
          [evenRowProperties]="header"
          [oddRowProperties]="header"
          [evenHoveredIndex]="hovered"
          [oddHoveredIndex]="hovered"
          [columnHoveredIndex]="columnHovered"
          [columnHovered]="advanceTableFeatures?.columnHighlights"
          [conditionArray]="conditionArray"
          [oddRowColor]="headerBackground"
          [evenRowColor]="headerBackground"
          [ngStyle]="{
          width:  widthArrayPercentage?.[idx]?.width ? widthArrayPercentage?.[idx]?.width : widthArrayPercentage?.[idx],
          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
          'border-right-width': gridLines?.column?.selected ? gridLines?.column?.borderThickness + 'px' : '0px',
          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px',
          'justify-content': header?.text?.alignment?.viewClass
        }"
          *ngFor="let row of headerArray; index as idx"
          class="table-row"
          [ngClass]="{
            'column-outline': gridLines?.column?.selected == true
          }"
        >
          <span
            class="entity-table-header-span"
            [ngClass]="{ wc_56: numberOfButtons == 3, wc_36: numberOfButtons == 2, wc_16: numberOfButtons == 1 }"
            appTableDirective
            [tenantfont]="tenantfont"
            [rowFillProperties]="false"
            [rowTextProperties]="true"
            [rowIndex]="1"
            [columnIndex]="idx"
            [evenRowProperties]="header"
            [oddRowProperties]="header"
            [evenHoveredIndex]="hovered"
            [oddHoveredIndex]="hovered"
            [columnHoveredIndex]="columnHovered"
            [columnHovered]="advanceTableFeatures?.columnHighlights"
            [conditionArray]="conditionArray"
            [oddRowColor]="headerBackground"
            [evenRowColor]="headerBackground"
            >{{ columnStyles[idx].displayName }}</span
          >
          <span
            #buttonContainer
            class="table-filter-icons"
            [ngStyle]="{ color: hovered == 1 || columnHovered == idx ? header?.fontHover : header?.fontColor }"
          >
          <button *ngIf="filterConfigurations?.position == 'header'" (mouseover)="isFilterHover[idx] = true" (mouseout)="isFilterHover[idx] = false">
            <em class="nh-icon nh-icon-filter-small" *ngIf="!(filterConfigurations?.uploadIcon?.length>0 == true)" [matMenuTriggerFor]="matMenu"></em>
            <img [src]="isFilterHover[idx] ? filterConfigurations?.uploadHoverIcon : filterConfigurations?.uploadIcon" *ngIf="filterConfigurations?.uploadIcon?.length>0== true" [matMenuTriggerFor]="matMenu">

          </button>
          <button *ngIf="searchConfigurations?.showHeaderSearch" (mouseover)="isSearchHover[idx] = true" (mouseout)="isSearchHover[idx] = false">
            <em class="nh-icon nh-icon-search" *ngIf="!(searchConfigurations?.uploadIcon?.length>0)" [matMenuTriggerFor]="search"></em>
            <img [src]="isSearchHover[idx] ? searchConfigurations?.uploadHoverIcon : searchConfigurations?.uploadIcon" *ngIf="searchConfigurations?.uploadIcon?.length>0== true" [matMenuTriggerFor]="search" >

          </button>
          <button *ngIf="sortConfigurations?.position == 'header'" (mouseover)="isSortHover[idx] = true" (mouseout)="isSortHover[idx] = false">
            <img  [src]=" isSortHover[idx] ? sortConfigurations?.uploadHoverIcon : sortConfigurations?.uploadIcon " *ngIf="sortConfigurations?.uploadIcon?.length>0== true" (click)="sortFunction(row.attributeName)" >
            <em class="nh-icon nh-icon-Sorting" *ngIf="!(sortConfigurations?.uploadIcon?.length>0== true)" (click)="sortFunction(row.attributeName)"></em>
          </button>
          </span>
        </th>
      </ng-container>
      <!-- Gsi Column Name -->
      <th
        pTooltip="{{ gsiColumnName }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        class="table-row"
        *ngIf="inTableGSIButtons"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="header"
        [oddRowProperties]="header"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="gsiColumnHovered"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="headerBackground"
        [evenRowColor]="headerBackground"
        (mouseover)="hovered = 1; gsiColumnHovered = 1"
        (mouseout)="hovered = -1; gsiColumnHovered = -1"
        [ngStyle]="{
          width: widthArrayPercentage?.[widthArrayPercentage?.length-1]
        }"
      >
        <span
          class="entity-table-header-span"
          appTableDirective
          [tenantfont]="tenantfont"
          [rowFillProperties]="false"
          [rowTextProperties]="true"
          [rowIndex]="1"
          [columnIndex]="1"
          [evenRowProperties]="header"
          [oddRowProperties]="header"
          [evenHoveredIndex]="hovered"
          [oddHoveredIndex]="hovered"
          [columnHoveredIndex]="gsiColumnHovered"
          [columnHovered]="false"
          [conditionArray]="conditionArray"
          [oddRowColor]="headerBackground"
          [evenRowColor]="headerBackground"
          >{{ gsiColumnName }}</span
        >
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-cols
    let-i="rowIndex"
    let-expanded="expanded"
  >
    <tr
      class="text-break-word"
      [pSelectableRow]="cols"
      [pReorderableRow]="i"
      [ngClass]="{
        'row-outline': gridLines?.rows?.selected == true
      }"
      [ngStyle]="{
        'border-color': gridLines?.rows?.selected
          ? gridLines?.rows?.borderColor
          : 'none',
        'border-width': gridLines?.rows?.selected
          ? gridLines?.rows?.borderThickness + 'px'
          : '0px',
        'border-radius': gridLines?.rows?.selected
          ? gridLines?.rows?.borderRadius + 'px'
          : '0px',
        'justify-content': header?.text?.alignment?.viewClass
      }"
    >
      <!-- check box -->
      <td
        class="table-row"
        pReorderableRowHandle
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="checkboxHover"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="setHover(i); checkboxHover = 1"
        (mouseout)="setOutHover(i); checkboxHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
        (click)="$event?.stopPropagation()"
        *ngIf="
          advanceTableFeatures?.checkboxSelection &&
          advanceTableFeatures?.multipleSelection
        "
        style="display: flex; justify-content: center;"
      >
        <p-tableCheckbox [value]="cols"></p-tableCheckbox>
      </td>
      <!-- radio button -->
      <td
        (click)="$event?.stopPropagation()"
        class="table-row"
        pReorderableRowHandle
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="checkboxHover"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="setHover(i); checkboxHover = 1"
        (mouseout)="setOutHover(i); checkboxHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
        *ngIf="
          advanceTableFeatures?.checkboxSelection &&
          !advanceTableFeatures?.multipleSelection
        "
        style="display: flex; justify-content: center;"
      >
        <p-tableRadioButton id="radio" [value]="cols"></p-tableRadioButton>
      </td>
      <!-- nested row -->
      <td
        class="table-row"
        [ngStyle]="{
          width: extraWidthPercent
        }"
        *ngIf="isNestedRow2"
      >
        <button
          *ngIf="isNestedRow2"
          type="button"
          pButton
          pRipple
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="
            isNestedRow1[i] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
          "
          (click)="isNestedRow1[i] = !isNestedRow1[i]"
        ></button>
      </td>
      <!-- Serial Number -->
      <td
        class="table-row"
        pReorderableRowHandle
        *ngIf="advanceTableFeatures?.serialNumbers"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="serialNumberHover"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="setHover(i); serialNumberHover = 1"
        (mouseout)="setOutHover(i); serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      >
        {{ paginatorNumber * (pagination?.numberOfRows ? pagination?.numberOfRows : pagination?.rows? pagination?.rows: 1) + i + 1 }}
      </td>
      <!-- attribute values -->
      <td
        *ngFor="let col of cols.recordData; index as ind"
        class="table-row"
        [ngClass]="{
          'column-outline': gridLines?.column?.selected == true,
          'link-class':
            attributeUIElementMap.get(col?.attributeName) == 'hyperlink',
          'link-active':
            col?.attributeName == urlBindingDataConfiguration?.attributeName || headerGsi?.[col?.attributeName]
        }"
        appTableDirective
        [tenantfont]="tenantfont"
        [record]="col"
        [enableCompleteRecord]="enableCompleteRecord"
        [conditionMap]="conditionMap"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="i"
        [columnIndex]="ind"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="columnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        [columnStyles]="columnStyles[ind]"
        [ngStyle]="{
          width: col.type === 'image' ? widthArrayPercentage?.[ind]?.width : widthArrayPercentage?.[ind],
          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
          'border-right-width': gridLines?.column?.selected ? gridLines?.column?.borderThickness + 'px' : '0px',
          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px',
          'justify-content': ind % 2 == 0 ? evenRow?.text?.alignment?.viewClass : oddRow?.text?.alignment?.viewClass
        }"
        pReorderableRowHandle
        (mouseover)="setHover(i, ind)"
        (mouseout)="setOutHover(i)"
      >
      <ng-container *ngIf="columnStyles.length > 0">
        <div
          class="container-fluid"
        >
          <div class="row no-gutters">
            <ng-container *ngFor="let colstyle of columnStyles[ind].sizes; index as colDataIndex">
              <div
                class="col-{{ colstyle }}"
                [ngClass]="{
                'link-active' : col?.attributeName?.split('.')?.[colDataIndex] == urlBindingDataConfiguration?.attributeName
              }"
                [ngStyle]="{
                  'text-align': i % 2 == 0 ? evenRow?.text?.alignment?.justify : oddRow?.text?.alignment?.justify
                }"
              >
                <p
                  *ngIf="col.type[colDataIndex] != 'image' &&
                  col?.attributeName?.split('.')?.[colDataIndex] != urlBindingDataConfiguration?.attributeName "
                  (click)="hyperlinkAttributeOpen(col?.attributeName, col?.value, $event)"
                  class="table-value"
                  pTooltip="{{ (col?.value)[colDataIndex] }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                  appTableDirective
                  [isChip1]="(columnStyles[ind]?.isChip)[colDataIndex]"
                  [isColumnStyle]="true"
                  [tenantfont]="tenantfont"
                  [record]="col"
                  [rowFillProperties]="false"
                  [rowTextProperties]="true"
                  [rowIndex]="i"
                  [columnIndex]="ind"
                  [evenRowProperties]="evenRow"
                  [oddRowProperties]="oddRow"
                  [evenHoveredIndex]="evenHovered"
                  [oddHoveredIndex]="oddHovered"
                  [columnHoveredIndex]="columnHovered"
                  [columnHovered]="advanceTableFeatures?.columnHighlights"
                  [conditionArray]="conditionArray"
                  [oddRowColor]="oddRowColor"
                  [evenRowColor]="evenRowColor"
                  [ngClass]="{
                    tableInChip: columnStyles[ind].isChip[colDataIndex]
                  }"
                  [ngStyle]="{
                    color: columnStyles[ind].colors[colDataIndex],
                    'font-size': columnStyles[ind].fontSizes[colDataIndex],
                    'font-weight': columnStyles[ind].Weight[colDataIndex]
                  }"
                  [innerHTML]="(col?.value)[colDataIndex] + columnStyles[ind].seperators[colDataIndex]"
                ></p>
                <a
                  class="table-value"
                  pTooltip="{{ (col?.value)[colDataIndex] + columnStyles[ind].seperators[colDataIndex] }}"
                  tooltipPosition="top"
                  tooltipStyleClass="transcustomtolltipbook"
                  *ngIf="col.type != 'image' && col?.attributeName?.split('.')?.[colDataIndex] == urlBindingDataConfiguration?.attributeName"
                  appTableDirective
                  [urlBindingAttributeColor]="
          col?.attributeName?.split('.')?.[colDataIndex] == urlBindingDataConfiguration?.attributeName
            ? urlBindingDataConfiguration?.attributeColor
            : ''
        "
                  [isChip1]="(columnStyles[ind]?.isChip)[colDataIndex]"
                  [isColumnStyle]="true"
                  [tenantfont]="tenantfont"
                  [record]="col"
                  [rowFillProperties]="false"
                  [rowTextProperties]="true"
                  [rowIndex]="i"
                  [columnIndex]="ind"
                  [evenRowProperties]="evenRow"
                  [oddRowProperties]="oddRow"
                  [evenHoveredIndex]="evenHovered"
                  [oddHoveredIndex]="oddHovered"
                  [columnHoveredIndex]="columnHovered"
                  [columnHovered]="advanceTableFeatures?.columnHighlights"
                  [conditionArray]="conditionArray"
                  [oddRowColor]="oddRowColor"
                  [evenRowColor]="evenRowColor"
                  [ngClass]="{
                    tableInChip: columnStyles[ind].isChip[colDataIndex]
                  }"
                  [ngStyle]="{
                    color: columnStyles[ind].colors[colDataIndex],
                    'font-size': columnStyles[ind].fontSizes[colDataIndex],
                    'font-weight': columnStyles[ind].Weight[colDataIndex]
                  }"
                  [routerLink]="[((this.urlBindingDataConfiguration?.isConfigName && this.configurationName) ? ('/navigate/' + configurationName + '/' +  (col?.value)[colDataIndex]):('/browse/' + primaryEntityName + '/' + (col?.value)[colDataIndex]))]"
                  >{{ (col?.value)[colDataIndex] + columnStyles[ind].seperators[colDataIndex] }}</a
                >
                <em
                  *ngIf="col?.attributeName?.split('.')?.[colDataIndex] == urlBindingDataConfiguration?.attributeName"
                  class="nh-icon nh-icon-attach cursor-pointer"
                  (click)="copyLinkToClipboard((col?.value)[colDataIndex],col?.attributeName?.split('.')?.[colDataIndex], $event, i); $event?.stopPropagation()"
                ></em>
                <img class="table-img"
                  *ngIf="col.type[colDataIndex] == 'image' && (col?.value)[colDataIndex] "
                  [src]="(col?.value)[colDataIndex]"
                  [ngStyle]="{ width: widthArray[ind].widthImage}"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="columnStyles.length == 0">
        <p
      class="table-value"
      (click)="
        hyperlinkAttributeOpen(col?.attributeName, col?.value, $event, i)
      "
      pTooltip="{{ col?.value }}"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      *ngIf="col.type != 'image' && col?.attributeName != urlBindingDataConfiguration?.attributeName &&
      columnStyles.length == 0"
      [innerHTML]="col?.value "
      [urlBindingAttributeColor]="headerGsi?.[col?.attributeName] ? '#0044CC' : '' "
      appTableDirective
      [tenantfont]="tenantfont"
      [record]="col"
      [enableCompleteRecord]="enableCompleteRecord"
      [conditionMap]="conditionMap"
      [rowFillProperties]="false"
      [rowTextProperties]="true"
      [rowIndex]="i"
      [columnIndex]="ind"
      [evenRowProperties]="evenRow"
      [oddRowProperties]="oddRow"
      [evenHoveredIndex]="evenHovered"
      [oddHoveredIndex]="oddHovered"
      [columnHoveredIndex]="columnHovered"
      [columnHovered]="advanceTableFeatures?.columnHighlights"
      [conditionArray]="conditionArray"
      [oddRowColor]="oddRowColor"
      [evenRowColor]="evenRowColor"
    ></p>
    <a
      class="table-value"
      pTooltip="{{ col?.value }}"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      *ngIf="col.type != 'image' && col?.attributeName == urlBindingDataConfiguration?.attributeName && !headerGsi?.[col?.attributeName]"
      [innerHTML]="col?.value"
      appTableDirective
      [urlBindingAttributeColor]="
        col?.attributeName == urlBindingDataConfiguration?.attributeName
          ? urlBindingDataConfiguration?.attributeColor
          : ''
      "
      [tenantfont]="tenantfont"
      [record]="col"
      [rowFillProperties]="false"
      [rowTextProperties]="true"
      [rowIndex]="i"
      [columnIndex]="ind"
      [evenRowProperties]="evenRow"
      [oddRowProperties]="oddRow"
      [evenHoveredIndex]="evenHovered"
      [oddHoveredIndex]="oddHovered"
      [columnHoveredIndex]="columnHovered"
      [columnHovered]="advanceTableFeatures?.columnHighlights"
      [conditionArray]="conditionArray"
      [oddRowColor]="oddRowColor"
      [evenRowColor]="evenRowColor"
      [routerLink]="['/browse/' + primaryEntityName + '/' + col?.value]"
      >{{ col?.value }}</a
    >
    <em
    *ngIf="col?.attributeName == urlBindingDataConfiguration?.attributeName || headerGsi?.[col?.attributeName]"
    class="nh-icon nh-icon-attach cursor-pointer"
    (click)="copyLinkToClipboard(col?.value, col?.attributeName, $event, i)"
    ></em>
        <img
          *ngIf="col?.type == 'image'"
          [src]="col?.contentUrl"
          [ngStyle]="{
            width: widthArray[ind].widthImage,
            'object-fit': 'contain'
          }"
        /></ng-container>
    
      </td>
      <!-- gsi buttons -->
      <td
        class="table-row"
        pReorderableRowHandle
        *ngIf="inTableGSIButtons"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="gsiColumnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="setHover(i); gsiColumnHovered = 1"
        (mouseout)="setOutHover(i); gsiColumnHovered = -1"
        [ngStyle]="{
          width: widthArrayPercentage?.[widthArrayPercentage?.length-1]
        }"
      >
        <div
          class="table-value inline-gsibuttons"
          [ngClass]="{'only-icon': combinationName == ''}"
          *ngIf="tableActionButtons.length > 0"
        >
          <ng-container *ngIf="!isMore">
            <div class="gsibuttons" *ngIf="tableActionButtons?.length > 0">
              <ng-container *ngFor="let btn of tableActionButtons; index as ind">

              <button
              *ngIf="!btn?.isUnrelated && btn?.configuration?.gsiDisplay != 'onlyIcon'"
              pTooltip="{{ btn?.displayName }}"
              tooltipPosition="top"
                (click)="gotoTxnPage(btn, [tableData?.[i]])"
                [ngStyle]="{
                  'background-color': gsiBtnHovered?.[ind] ? gsiStyle?.colorHover?.[ind]: gsiStyle?.backgroundColor?.[ind],
                  'color': gsiBtnHovered?.[ind] ? gsiStyle?.fontHover?.[ind]: gsiStyle?.fontColor?.[ind] ,
                  'border-color': gsiBtnHovered?.[ind] ? gsiStyle?.gsihoverborderColor?.[ind]: gsiStyle?.gsiborderColor?.[ind],
                  'border-width': gsiStyle?.gsiborderWidth ? gsiStyle?.gsiborderWidth + 'px' : '0px',
                  'font-family': gsiStyle?.text?.fontFamily?.font,
                  'text-decoration-line': gsiStyle?.text?.underLine ? 'underline' : 'none',
                  'font-weight': gsiStyle?.text?.bold ? 'bold' : 'normal',
                  'font-style': gsiStyle?.text?.italic ? 'italic' : 'normal',
                  'font-size': gsiStyle?.text?.fontSize + 'px',
                  'text-align': gsiStyle?.text?.alignment?.justify,
                  'border-radius': gsiStyle?.gsiborderRadius + 'px'
                }"
                (mouseout)="gsiBtnHovered[ind] = false"
                (mouseover)="gsiBtnHovered[ind] = true"
              >
              <img *ngIf="btn?.configuration?.gsiDisplay === 'gsiNameWithIcon'" class="icon"
              [src]="btn?.configuration?.imageSrc" />
                {{ btn?.displayName }}
              </button>
              <button
              *ngIf="!btn?.isUnrelated && btn?.configuration?.gsiDisplay == 'onlyIcon'"
              pTooltip="{{ btn?.displayName }}"
              tooltipPosition="top"
                (click)="gotoTxnPage(btn, [tableData?.[i]])"
                
               
              >
              <img  [src]="btn?.configuration?.imageSrc" (click)="gotoTxnPage(btn, selectedRecord)">

           
              </button>
            </ng-container>

            </div>
          </ng-container>
          <ng-container *ngIf="isMore">
            <div
              class="tbl-accordion cursor-pointer"
              *ngIf="tableActionButtons?.length > 0"
              (click)="more.toggle($event); $event?.stopPropagation()"
            >
              <p-overlayPanel styleClass="filterOverlay menu-gsi" #more>
                <div class="checkboxes">
                  <div class="checckbox" *ngFor="let btn of tableActionButtons; index as ind">
                    <mat-option class="gsi" (click)="gotoTxnPage(btn, [tableData?.[i]])" *ngIf="!btn.isUnrelated && !(gsiConditioned.attributeValue? (gsiConditioned.attributeValue == cols.recordData?.[conditionAttributeIndex]?.value && btn?.masterId == gsiConditioned?.gsiTobeExcludedMasterID):false ) ">
                      <div
                        class="attr-name text-truncate"
                        pTooltip="{{ btn?.displayName }}"
                        tooltipPosition="top"
                        tooltipStyleClass="index-tooltip"
                      >
                        {{ btn?.displayName }}
                      </div>
                    </mat-option>
                  </div>
                </div>
              </p-overlayPanel>
              <div class="d-flex"  [ngClass]="{ active: false, 'filter-name text-truncate saved-menu':!isMenuIconOnly }" >
                <span class="nh-mr-3" *ngIf="!isMenuIconOnly">{{ combinationName }}</span>
                <img width="32" height="32" [src]="menuIconValue" class="custom-menu-icon" *ngIf="isMenuIconOnly" [ngClass]="{ active:false }">  
              </div>

              <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
                <ng-container
                  *ngFor="let btn of tableActionButtons; index as ind"
                >
                  <mat-option (click)="gotoTxnPage(btn, [tableData[i]])" *ngIf="!btn.isUnrelated && !(gsiConditioned.attributeValue? (gsiConditioned.attributeValue == cols.recordData?.[conditionAttributeIndex]?.value && btn?.masterId == gsiConditioned?.gsiTobeExcludedMasterID):false ) ">
                    <div
                      class="attr-name text-truncate"
                      pTooltip="{{ btn?.displayName }}"
                      tooltipPosition="top"
                      tooltipStyleClass="index-tooltip" *ngIf="!btn.isUnrelated"
                    >
                      {{ btn?.displayName }}
                    </div>
                  </mat-option>
                </ng-container> 
              </mat-menu>
            </div>
          </ng-container>
        </div>
      </td>
    </tr>
    <tr *ngIf="isNestedRow1[i]">
      <td
        class="table-row"
        *ngIf="isNestedRow2"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="nestedRowHeader"
        [oddRowProperties]="nestedRowHeader"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="serialNumberHover"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="nestedRowHeaderBackground"
        [evenRowColor]="nestedRowHeaderBackground"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></td>
      <td
        class="table-row"
        *ngIf="
          isNestedRow2 &&
          (advanceTableFeatures?.checkboxSelection ||
            advanceTableFeatures?.serialNumbers)
        "
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="nestedRowHeader"
        [oddRowProperties]="nestedRowHeader"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="serialNumberHover"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="nestedRowHeaderBackground"
        [evenRowColor]="nestedRowHeaderBackground"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></td>
      <td
        class="table-row"
        *ngIf="
          isNestedRow2 &&
          advanceTableFeatures?.checkboxSelection &&
          advanceTableFeatures?.serialNumbers
        "
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="true"
        [rowIndex]="1"
        [columnIndex]="1"
        [evenRowProperties]="nestedRowHeader"
        [oddRowProperties]="nestedRowHeader"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="serialNumberHover"
        [columnHovered]="false"
        [conditionArray]="conditionArray"
        [oddRowColor]="nestedRowHeaderBackground"
        [evenRowColor]="nestedRowHeaderBackground"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></td>
      <td
        [ngClass]="{ active: false }"
        (click)="index = idx; name = row.attributeName"
        pTooltip="{{ row.displayName }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        (mouseover)="hovered = 1"
        (mouseout)="hovered = -1"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="1"
        [columnIndex]="idx"
        [evenRowProperties]="nestedRowHeader"
        [oddRowProperties]="nestedRowHeader"
        [evenHoveredIndex]="hovered"
        [oddHoveredIndex]="hovered"
        [columnHoveredIndex]="columnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="nestedRowHeaderBackground"
        [evenRowColor]="nestedRowHeaderBackground"
        [ngStyle]="{
          width: row.type === 'image' ? widthArrayPercentage?.[idx]?.width : widthArrayPercentage?.[idx],          
          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
          'border-right-width': gridLines?.column?.selected ? gridLines?.column?.borderThickness + 'px' : '0px',
          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px',
          'justify-content': header?.text?.alignment?.viewClass
        }"
        *ngFor="let row of nestedRowTableData?.[0]?.recordData; index as idx"
        class="table-row"
        [ngClass]="{
          'column-outline': gridLines?.column?.selected == true
        }"
      >
        <span
          class="entity-table-header-span"
          [ngClass]="{
            wc_56: numberOfButtons == 3,
            wc_36: numberOfButtons == 2,
            wc_16: numberOfButtons == 1
          }"
          appTableDirective
          [tenantfont]="tenantfont"
          [rowFillProperties]="false"
          [rowTextProperties]="true"
          [rowIndex]="1"
          [columnIndex]="idx"
          [evenRowProperties]="nestedRowHeader"
          [oddRowProperties]="nestedRowHeader"
          [evenHoveredIndex]="hovered"
          [oddHoveredIndex]="hovered"
          [columnHoveredIndex]="columnHovered"
          [columnHovered]="advanceTableFeatures?.columnHighlights"
          [conditionArray]="conditionArray"
          [oddRowColor]="nestedRowHeaderBackground"
          [evenRowColor]="nestedRowHeaderBackground"
          >{{ row.displayName }}</span
        >
      </td>
    </tr>
    <tr *ngIf="isNestedRow1[i]">
      <td
        class="table-row"
        *ngIf="isNestedRow2"
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="columnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></td>
      <td
        class="table-row"
        *ngIf="
          isNestedRow2 &&
          (advanceTableFeatures?.checkboxSelection ||
            advanceTableFeatures?.serialNumbers)
        "
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="columnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></td>
      <td
        class="table-row"
        *ngIf="
          isNestedRow2 &&
          advanceTableFeatures?.checkboxSelection &&
          advanceTableFeatures?.serialNumbers
        "
        appTableDirective
        [tenantfont]="tenantfont"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="i"
        [columnIndex]="1"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="columnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        (mouseover)="hovered = 1; serialNumberHover = 1"
        (mouseout)="hovered = -1; serialNumberHover = -1"
        [ngClass]="{
          'column-outline': gridLines?.column === true
        }"
        [ngStyle]="{
          width: extraWidthPercent
        }"
      ></td>
      <td
        *ngFor="let col of nestedRowTableData?.[i]?.recordData; index as ind"
        class="table-row"
        [ngClass]="{
          'column-outline': gridLines?.column?.selected == true
        }"
        appTableDirective
        [tenantfont]="tenantfont"
        [record]="col"
        [enableCompleteRecord]="enableCompleteRecord"
        [conditionMap]="conditionMap"
        [rowFillProperties]="true"
        [rowTextProperties]="false"
        [rowIndex]="i"
        [columnIndex]="ind"
        [evenRowProperties]="evenRow"
        [oddRowProperties]="oddRow"
        [evenHoveredIndex]="evenHovered"
        [oddHoveredIndex]="oddHovered"
        [columnHoveredIndex]="columnHovered"
        [columnHovered]="advanceTableFeatures?.columnHighlights"
        [conditionArray]="conditionArray"
        [oddRowColor]="oddRowColor"
        [evenRowColor]="evenRowColor"
        [ngStyle]="{
          width: col.type === 'image' ? widthArrayPercentage?.[ind]?.width : widthArrayPercentage?.[ind],          
          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
          'border-right-width': gridLines?.column?.selected ? gridLines?.column?.borderThickness + 'px' : '0px',
          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px',
          'justify-content': ind % 2 == 0 ? evenRow?.text?.alignment?.viewClass : oddRow?.text?.alignment?.viewClass
        }"
        pReorderableRowHandle
        (mouseover)="setHover(i, ind)"
        (mouseout)="setOutHover(i)"
      >
        <p
          class="table-value"
          pTooltip="{{ col.value }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          *ngIf="col.type != 'image'"
          [innerHTML]="col.value"
          appTableDirective
          [tenantfont]="tenantfont"
          [record]="col"
          [enableCompleteRecord]="enableCompleteRecord"
          [conditionMap]="conditionMap"
          [rowFillProperties]="false"
          [rowTextProperties]="true"
          [rowIndex]="i"
          [columnIndex]="ind"
          [evenRowProperties]="evenRow"
          [oddRowProperties]="oddRow"
          [evenHoveredIndex]="evenHovered"
          [oddHoveredIndex]="oddHovered"
          [columnHoveredIndex]="columnHovered"
          [columnHovered]="advanceTableFeatures?.columnHighlights"
          [conditionArray]="conditionArray"
          [oddRowColor]="oddRowColor"
          [evenRowColor]="evenRowColor"
        ></p>
        <img
          *ngIf="col.type == 'image'"
          [src]="col.contentUrl"
          [ngStyle]="{
            width: widthArray[ind]?.widthImage,
            'object-fit': 'contain'
          }"
        />
      </td>
    </tr>
  </ng-template>
</p-table>
<div
  id="footerDescription"
  class="table-description"
  *ngIf="description?.footer?.message"
  (mouseover)="footerHovered = 1"
  (mouseout)="footerHovered = -1"
  [ngStyle]="{
    'background-color':
      footerHovered == 1
        ? description?.footer?.style?.colorHover
        : description?.footer?.style?.backgroundColor,
    color:
      footerHovered == 1
        ? description?.footer?.style?.fontHover
        : description?.footer?.style?.fontColor
  }"
>
  {{ description?.footer?.message }}
</div>
<ng-container *ngFor="let condition of this.conditionArray; index as i">
  <div
    class="condition-box d-flex"
    *ngIf="legendSelected"
    [ngStyle]="{
      'justify-content': legendAlignment?.justify
    }"
  >
    <div class="d-flex nh-fs-14 justify-content-between">
      <div class="d-flex nh-column-gap-5 condition-attr">
        <p
          class="text-truncate"
          [ngStyle]="{
            color: this.condition?.textColor,
            'background-color': this.condition?.fillColor
          }"
          tooltipPosition="top"
        >
          {{ this.condition?.conditionAttribute }}
        </p>
        <p>
          {{ this.condition?.selectedConditonValue }}
        </p>
      </div>
    </div>
  </div>
</ng-container>
