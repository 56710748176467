import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
})
export class CustomdatePipe implements PipeTransform {
  transform(value: any): Date {
    /* istanbul ignore next */
    if (value) {
      let tempValue: any = '(' + value + ')';
      return new Date(tempValue.match(/\d+/)[0] * 1);
    }
  }
}
