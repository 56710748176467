<div class="viewroles-wrap">
  <div class="mapping-details w-100">
    <div class="rolemap-tables">
      <!-- if conflict is present -->
      <div class="table-responsive" *ngIf="conflicted === false">
        <table class="table" aria-describedby="">
          <!-- Heading and Rename button -->
          <thead>
            <tr>
              <th class="d-flex justify-content-between" id="">
                <div>View dashboards with conflict</div>
              </th>
              <th class="text-right" id="">
                <button mat-stroked-button class="cstm-btn" (click)="renameDashboard()">
                  {{ labels?.Rename }}
                </button>
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- List of all dashboards -->
            <tr *ngFor="let dashboard of dashboardsConflicted; let i = index">
              <td class="conflict-active">
                <span *ngIf="dashboard?.isConflictResolved === false && dashboard?.conflicting === true">
                  <mat-checkbox class="cstm-check" (change)="changeDashboard($event, dashboard, i)">
                    {{ dashboard?.sourceValue }}
                  </mat-checkbox>
                </span>
                <span *ngIf="dashboard?.isConflictResolved === true && dashboard?.conflicting === true">
                  <mat-checkbox
                    class="cstm-check"
                    [disabled]="dashboard.isResolvedcon"
                    (change)="changeDashboard($event, dashboard, i)"
                    *ngIf="dashboard.conflictAction && dashboard.isResolvedcon"
                  >
                    {{ dashboard?.targetValue }}
                  </mat-checkbox>
                </span>
                <span *ngIf="!dashboard.isResolvedcon && dashboard?.targetValue === ''">
                  <mat-icon>
                    warning_amber
                  </mat-icon>
                  {{ labels?.Resolve_Conflict }}
                </span>
              </td>

              <td>
                <span *ngIf="dashboard?.isConflictResolved && dashboard.isResolvedcon">
                  {{ dashboard.message }}
                </span>
                <span *ngIf="dashboard.selectedNameDashboard">
                  <input type="text" [(ngModel)]="dashboard.targetValue" (keydown.enter)="saveName()" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- if conflict is not present -->
      <div *ngIf="conflicted === true">
        No Conflicted Dashboard
      </div>

      <!-- Next and Cancel button -->
      <div class="configure-btns btns-custom">
        <button class="btn btn-next btn-active" (click)="moveToNextTab()" [disabled]="checkConflicts()">
          {{ labels?.Next }}
        </button>
        <button class="btn btn-cancel" (click)="cancel()">
          {{ labels?.CANCEL }}
        </button>
      </div>
    </div>
  </div>
</div>
