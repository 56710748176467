import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { gsiMapper } from '../mappers/gsi/gsi-mapper';

@Injectable({
  providedIn: 'root',
})
export class ManageConfigurationEndpointService extends CduiBaseService {
  chatbotDashboardAnalytics = new Subject();
  chatbotDashboardAnalyticsObservable = this.chatbotDashboardAnalytics.asObservable();

  private gsiDetails = new Subject();
  gsiDetails$ = this.gsiDetails.asObservable();

  private ngUnsubscribe = new Subject();

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getConfigurableCPEntities(pageSize?: any, name?: any) {
    pageSize = pageSize == undefined || pageSize == 0 ? 100 : pageSize;
    name = name == undefined ? '' : name;
    return this.http.get(
      `${this.api?.configurableCPEntities}?criteria="isConditionalPotentialityEntity":"true","entityName":"${name}"&pageNo=&pageSize=${pageSize}`
    );
  }
  getConfigurableEntityData(generalEntityID: any) {
    return this.http.get(this.api?.configurableEntityData + `/${generalEntityID}`);
  }
  saveConfigurableEntityData(postData: any) {
    return this.http.post(this.api?.configurableSaveEntity, postData);
  }
  saveLoginWithOtp(flag: any,otpLimit: number) {
    return this.http.post(this.api?.enableOtp + `${flag}`+`&otpLimit=`+`${otpLimit}`,'');
  }
  saveHotConf(ttlConfiguration: number) {
    let tenantId = localStorage.getItem('TenantName');
    let ttlConfg: any = {
      ttl: ttlConfiguration,
    };
    return this.http.post(this.api?.hotConfiguration + tenantId, ttlConfg);
  }
  fetchHotConf() {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.get(this.api?.hotConfiguration + tenantId);
  }
  saveUiControlCreation(payload: any) {
    return this.http.post(this.api?.uiControlCreation, payload);
  }
  tenantBotBasicSave(payload: any) {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.post(this.api?.botbasic + tenantId, payload);
  }
  fetchBotDetails(isTenantSpecificBot: boolean) {
    let tenantId = localStorage.getItem('TenantName');
    if (isTenantSpecificBot) return this.http.get(this.api?.botbasic + tenantId);
    else return this.http.get(this.api?.botbasic + `${tenantId}?isTenant=${isTenantSpecificBot}`);
  }

  faqSave(formData: any, language: any, fileType: string) {
    let tenantId = localStorage.getItem('TenantName');
    if (fileType == 'faqFile') {
      return this.http.post(this.api?.faqSave + `faq/tenantDocuments/${tenantId}?language=${language}`, formData);
    } else {
      return this.http.post(this.api?.faqSave + `untrain/tenantDocuments/${tenantId}?language=${language}`, formData);
    }
  }

  fetchSignupConfig() {
    let tenantId = localStorage.getItem('TenantName');
    let clientId = localStorage.getItem('envClientId');
    return this.http.get(this.api?.getSignupConfig + `${clientId}`);
  }
  saveCreateguestUserFlag(flag: any) {
    return this.http.post(this.api.saveCreateGuestUser, flag);
  }

  saveSignupConfig(payload: any) {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.post(this.api?.getSignupConfig, payload);
  }

  deleteSignupConfig() {
    let clientId = localStorage.getItem('envClientId');
    return this.http.delete(this.api?.getSignupConfig + `${clientId}`);
  }
  getGuestuserConfiguration(tenantName: any) {
    let envClientId = localStorage.getItem('envClientId');
    return this.http.get(this.api?.getSignupConfig + `${envClientId}`);
  }

  fetchSignupProperties(clientId: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.fetchSignupProperties + `clientId=${clientId}`);
  }
  fetchGsiMappings(purpose: any, pageNo: number, pageSize: number, roleName:string) {
    return this.http.get(this.api?.fetchGsiMappings + `?purpose=${purpose}&searchCriteria=${roleName}&pageNo=${pageNo}&pageSize=${pageSize}`);
  }
  fetchPublicGsiMappings(purpose: any) {
    return this.http.get(this.api?.fetchPublicGsiMappings + `?purpose=${purpose}`);
  }
  deleteGsiMapping(role: any, purpose: any) {
    return this.http.delete(this.api?.deleteGsiMappings + role + `?purpose=${purpose}`);
  }
  botIconUpload(file: any) {
    return this.http.post(this.api?.singleFileUpload, file);
  }
  getLanguages() {
    let tenantName = localStorage.getItem('TenantName');
    return this.http.get(this.api?.getLanguagesforFaqDoc + `/${tenantName}`);
  }

  saveMsgConfigDoc(formData: any) {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.post(this.api?.tenantMsgConfiguration + `/${tenantId}`, formData);
  }
  faqUploadHistory(page_number: number, page_size: number) {
    let tenantName = localStorage.getItem('TenantName');
    return this.http.get(
      this.api?.faqFileUploadHistory + `/${tenantName}?page_number=${page_number}&page_size=${page_size}`
    );
  }

  unTrainUploadHistory(page_number: number, page_size: number) {
    let tenantName = localStorage.getItem('TenantName');
    return this.http.get(
      this.api?.unTrainFileUploadHistory + `/${tenantName}?page_number=${page_number}&page_size=${page_size}`
    );
  }

  faqFileDownload(fileName: string) {
    let tenantName = localStorage.getItem('TenantName');
    return this.http.get<Blob>(this.api?.faqFileDownload + `/${tenantName}?filename=${fileName}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  getEntitiesForText2SQL(body: any) {
    return this.http.post(this.api?.getEntitiesForText2SQL, body);
  }
  getSchema(tenantName: string, entity: string) {
    return this.http.get(this.api?.getSchema + `/${tenantName}/${entity}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  DummyData(body: any) {
    return this.http.post(this.api?.DummyData, body);
  }
  unTrainFileDownload(fileName: string) {
    let tenantName = localStorage.getItem('TenantName');
    return this.http.get<Blob>(this.api?.unTrainFileDownload + `/${tenantName}?filename=${fileName}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
  // atqSave(formData:any){
  //   let tenantId= localStorage.getItem('TenantName');
  //   return this.http.post('',formData);
  // }
  customFileForSignUpCustomization(formdata: any) {
    return this.http.post(this.api?.customTemplate, formdata);
  }

  getCampaignUrl(payload: any) {
    return this.http.post(this.api?.generateCampaign, payload);
  }

  saveLoginConfig(payload: any) {
    return this.http.post(this.api?.saveLoginConfig, payload);
  }

  getLoginConfig() {
    let clientId = localStorage.getItem('clientId');
    return this.http.get(this.api?.saveLoginConfig + '?' + `clientId=${clientId}`);
  }

  updateLoginConfig(payload: any) {
    return this.http.put(this.api?.saveLoginConfig, payload);
  }
  deleteLoginConfig() {
    return this.http.delete(this.api?.saveLoginConfig);
  }

  /*istanbul ignore next*/
  loadCPEntityDataById(id: any) {
    return this.http.get(`${this.api?.getAttributeByEntityId}/${id}?isReserved=false`);
  }
  fetchLoginConfiguration(clientId: any) {
    return this.http.get(`${this.api?.LoginConfiguration}?clientId?=${clientId}`);
  }
  saveLoginConfiguration(payload: any) {
    return this.http.post(`${this.api?.LoginConfiguration}`, payload);
  }
  updateLoginConfiguration(payload: any) {
    return this.http.put(`${this.api?.LoginConfiguration}`, payload);
  }
  deleteLoginConfiguration() {
    return this.http.delete(`${this.api?.LoginConfiguration}`);
  }
  fetchLogoutConfiguration(clientId: any) {
    return this.http.get(`${this.api?.LogoutConfiguration}?clientId=${clientId}`);
  }
  saveLogoutConfiguration(payload: any) {
    return this.http.post(`${this.api?.LogoutConfiguration}`, payload);
  }
  updateLogoutConfiguration(payload: any) {
    return this.http.put(`${this.api?.LogoutConfiguration}`, payload);
  }
  deleteLogoutConfiguration() {
    return this.http.delete(`${this.api?.LogoutConfiguration}`);
  }


  fetchMachineCuTxnHistoryConfiguration() {
    return this.http.get(`${this.api?.txnHistoryConfig}`);
  }
  saveMachineCuTxnHistoryConfiguration(payload: any) {
    return this.http.post(`${this.api?.txnHistoryConfig}`, payload);
  }
  updateMachineCuTxnHistoryConfiguration(payload: any) {
    return this.http.put(`${this.api?.txnHistoryConfig}`, payload);
  }
  deleteMachineCuTxnHistoryConfiguration() {
    return this.http.delete(`${this.api?.txnHistoryConfig}`);
  }
  saveFaceRecogConfg(isFaceRecognitionEnabled: boolean) {
    let faceConfg: any = {
      isFaceRecognitionEnabled: isFaceRecognitionEnabled,
    };
    return this.http.post(`${this.api?.faceRecognitionConfiguration}`, faceConfg);
  }
  getFaceRecogConfg(clientId: any) {
    //let tenantId = localStorage.getItem('TenantName');
    return this.http.get(`${this.api?.faceRecognitionConfiguration}?clientId?=${clientId}`);
  }
  deleteFaceRecogConfg() {
    return this.http.delete(`${this.api?.faceRecognitionConfiguration}`);
  }
  updateFaceRecogConfg(isFaceRecognitionEnabled: boolean) {
    let faceConfg: any = {
      isFaceRecognitionEnabled: isFaceRecognitionEnabled,
    };
    return this.http.put(`${this.api?.faceRecognitionConfiguration}`, faceConfg);
  }
  getUserConfiguration() {
    return this.http.get(`${this.api?.userConfiguration}`);
  }
  saveuserConfiguration(payload: any) {
    return this.http.post(`${this.api?.userConfiguration}`, payload);
  }
  updateUserConfiguration(payload: any) {
    return this.http.put(`${this.api?.userConfiguration}`, payload);
  }
  deleteUserConfiguration() {
    return this.http.delete(`${this.api?.userConfiguration}`);
  }
  customFileForUsersCustomization(formdata: any, subject: any) {
    return this.http.post(this.api?.customTemplateUsers, formdata, subject);
  }
  saveGSIConfiguration(tenantName: string, payload: any) {
    return this.http.post(`${this.api?.saveGSIConfiguration}/${tenantName}`, payload);
  }
  getGSIConfiguration(tenantName: string, gsiId: any) {
    return this.http.get(`${this.api?.getGSIConfiguration}/${tenantName}/${gsiId}`);
  }
  userCustomAttributes() {
    return this.http.get(this.api?.customDynamicAttributes);
  }
  fetchGSIDetails(id: any, tenantName: string) {
    return this.http.get(this.api?.fetchGSIDetails + `/${id}?tenantId=${tenantName}`).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: any) => {
      if (data && data?.result) {
        this.gsiDetails.next(gsiMapper(data?.result));
      }
    });
  }
}
