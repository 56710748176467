<div class="canvas-design">
  <div class="canvas-header">
    <div class="header-left">
      <em class="nh-icon nh-icon-arrow-left-short"></em>
      <h1 class="name">Advanced Features Layout</h1>
    </div>
    <div class="header-right">
      <button (click)="saveLayout(true)" *ngIf="layoutOpen">Done</button>
      <button (click)="goToLayout(false)" *ngIf="layoutOpen">Cancel</button>
      <button (click)="goToLayout(true)" [disabled]="!layoutTemplateType" *ngIf="!layoutOpen">Next</button>
      <button (click)="saveLayout(false)" *ngIf="!layoutOpen">Cancel</button>
    </div>
  </div>
  <div class="select-template" *ngIf="!layoutOpen">
    <p class="title">Please choose the Desired template for runtime/transaction time</p>
    <div class="templates">
      <figure (click)="selectTemplate(1)">
        <label for="">Template 01</label>
        <img
          [ngClass]="{ active: layoutTemplateType === 1 }"
          src="../../assets/ATemplate-1.png"
          alt=""
        />
      </figure>
      <figure (click)="selectTemplate(2)">
        <label for="">Template 02</label>
        <img
          [ngClass]="{ active: layoutTemplateType === 2 }"
          src="../../../../../assets/img/general-entity/Advance-features/ATemplate-1.png"
          alt=""
        />
      </figure>
    </div>
  </div>
  <div class="canvas-design-con" *ngIf="layoutOpen">
    <div class="change-design">
      <div class="btns">
        <button [ngClass]="{ active: tab === 'filter' }" (click)="switchTabs('filter')">Filter</button>
        <button [ngClass]="{ active: tab === 'design' }" (click)="switchTabs('design')">Design</button>
      </div>
      <div class="combination-fields" [ngClass]="{ 'd-none': tab === 'design' }">
        <div
          cdkDropList
          #dragList="cdkDropList"
          [cdkDropListData]="dragabbleAdvancedFeatures"
          [cdkDropListConnectedTo]="[verticalDropList, horizontalDropList]"
          class="combination-fields-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="field" *ngFor="let item of dragabbleAdvancedFeatures" cdkDrag>
            {{ item?.name }} <em class="nh-icon nh-icon-arrows-move"></em>
          </div>
        </div>
      </div>
      <div class="update-design combination-fields" [ngClass]="{ 'd-none': tab === 'filter' }">
        <p-accordion>
          <p-accordionTab header="Background Color">
            <p class="title">Color</p>
            <div class="choose-color">
              <span
                (click)="closeColorPicker($event, 'background')"
                [ngStyle]="{ 'background-color': colorModel }"
              ></span>
              <span>{{ colorModel }}</span>
            </div>
            <!-- <p class="title">Opacity</p>
            <span class="oneway-slider">
              <p-slider [step]="20"></p-slider>
              <div class="s-range">
                <span>0%</span>
                <span>100%</span>
              </div>
            </span> -->
            <div class="btns">
              <button class="nh-bg-208">Done</button>
              <button>Cancel</button>
            </div>
            <span
              *ngIf="backgroundColor"
              [cpPositionRelativeToArrow]="true"
              [cpDialogDisplay]="'inline'"
              [(colorPicker)]="colorModel"
              [cpOKButton]="true"
              (colorPickerSelect)="closeColorPicker($event, 'background')"
              [cpToggle]="true"
              [cpOKButtonText]="'Done'"
            >
            </span>
          </p-accordionTab>
          <p-accordionTab header="Label Title">
            <p class="title">Font Color</p>
            <div class="choose-color">
              <span
                (click)="closeColorPicker($event, 'label')"
                [ngStyle]="{ 'background-color': labelFontColorModel }"
              ></span>
              <span>{{ labelFontColorModel }}</span>
            </div>
            <span
              *ngIf="labelColor"
              [cpPositionRelativeToArrow]="true"
              [cpDialogDisplay]="'inline'"
              [(colorPicker)]="labelFontColorModel"
              [cpOKButton]="true"
              (colorPickerSelect)="closeColorPicker($event, 'label')"
              [cpToggle]="true"
              [cpOKButtonText]="'Done'"
            >
            </span>
            <p class="title">Font Size</p>
            <input placeholder="Font Size" [(ngModel)]="labelFontSize" />
            <div class="btns">
              <button class="nh-bg-208">Done</button>
              <button>Cancel</button>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Placeholder Title">
            <p class="title">Font Color</p>
            <div class="choose-color">
              <span
                (click)="closeColorPicker($event, 'placeholder')"
                [ngStyle]="{ 'background-color': placeHolderFontColorModel }"
              ></span>
              <span>{{ placeHolderFontColorModel }}</span>
            </div>
            <span
              *ngIf="placeHolderColor"
              [cpPositionRelativeToArrow]="true"
              [cpDialogDisplay]="'inline'"
              [(colorPicker)]="placeHolderFontColorModel"
              [cpOKButton]="true"
              (colorPickerSelect)="closeColorPicker($event, 'placeholder')"
              [cpToggle]="true"
              [cpOKButtonText]="'Done'"
            >
            </span>
            <p class="title">Font Size</p>
            <input placeholder="Font Size" [(ngModel)]="placeHolderFontSize" />
            <div class="btns">
              <button class="nh-bg-208">Done</button>
              <button>Cancel</button>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
    <div class="d-flex flex-column w-100">
      <div class="horizontal-container containers" [ngClass]="{ 'd-none': reoderableSearchField?.length === 0 }">
        <div
          cdkDropList
          #reorderSearchFields="cdkDropList"
          [cdkDropListData]="reoderableSearchField"
          class="drag-area filter-search-fields"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="drag-items">
            <div class="drag-item" *ngFor="let item of reoderableSearchField" cdkDrag>
              <span class="item-name">{{ item?.name }}</span>
            </div>
          </div>
          <div class="btns">
            <button class="Search-btn">Search</button>
            <button class="Search-btn reset-btn">Reset</button>
          </div>
        </div>
      </div>
      <div class="horizontal-container containers" [ngClass]="{ 'd-none': layoutTemplateType === 1 }">
        <div
          cdkDropList
          #horizontalDropList="cdkDropList"
          [cdkDropListData]="horizontalDropped"
          [cdkDropListConnectedTo]="[dragList, verticalDropList]"
          class="drag-area"
          [ngStyle]="{ background: colorModel }"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="drag-item" *ngFor="let item of horizontalDropped" cdkDrag>
            <p [ngStyle]="{ color: labelFontColorModel, 'font-size': labelFontSize + 'px' }">{{ item?.name }}</p>
            <app-filter-templates
              [id]="item?.filter?.id ? item?.filter?.id : item?.id"
              [individual]="individual"
            ></app-filter-templates>
          </div>
        </div>
      </div>
      <div class="vertical-container containers" [ngClass]="{ 'd-none': layoutTemplateType === 2 }">
        <div
          cdkDropList
          #verticalDropList="cdkDropList"
          [cdkDropListData]="verticalDropped"
          [cdkDropListConnectedTo]="[dragList, horizontalDropList]"
          class="drag-area"
          [ngStyle]="{ background: colorModel }"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="drag-item" *ngFor="let item of verticalDropped" cdkDrag>
            <p [ngStyle]="{ color: labelFontColorModel, 'font-size': labelFontSize + 'px' }">{{ item?.name }}</p>
            <app-filter-templates
              [id]="item?.filter?.id ? item?.filter?.id : item?.id"
              [individual]="individual"
            ></app-filter-templates>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
