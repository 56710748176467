import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ManageIntegrationsEndpointService } from './manage-integrations-endpoint.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ManageIntegrationsFacadeService implements OnDestroy {
  public ngUnsubscribe = new Subject();
  public isAdapterBook = false;
  public viewAdapterPageNumber: number = 1;
  public viewAdapterPageSize: number = 5;
  public viewAdaptersTotalhits: number;
  public viewAdapterType: string = 'REST';

  public categoriesSubject = new Subject();
  categories$ = this.categoriesSubject.asObservable();

  public dynamicCompData = new Subject();
  dynamicCompData$ = this.dynamicCompData.asObservable();

  public subCategoriesSubject = new Subject();
  subCategories$ = this.subCategoriesSubject.asObservable();

  public configurationsSubject = new Subject();
  configurations$ = this.configurationsSubject.asObservable();

  public schedularDetails = new Subject();
  schedularDetails$ = this.schedularDetails.asObservable();

  //adapters related components

  //getting swagger api
  getSwaggerSubject = new Subject();
  getSwagger$ = this.getSwaggerSubject.asObservable();

  // //getting sftp connections api
  // private addsftpSubject = new Subject();
  // addsftp$ = this.addsftpSubject.asObservable();

  // //getting rest connections api
  // private addrestSubject = new Subject();
  // addrest$ = this.addsftpSubject.asObservable();

  //enitity dropdown
  entitydropSubject = new Subject();
  entitydrop$ = this.entitydropSubject.asObservable();

  //for view
  adapterView = new Subject();
  adapterView$ = this.adapterView.asObservable();

  //list connection
  listConnection = new Subject();
  listConnetion$ = this.listConnection.asObservable();
  connectionList = new Subject();
  connectionList$ = this.connectionList.asObservable();

  singleadapterSubject = new Subject();
  singleadapterData$ = this.singleadapterSubject.asObservable();

  singleadapterv2Subject = new Subject();
  singleadapterv2Data$ = this.singleadapterv2Subject.asObservable();

  configurationEntitiesSubject = new Subject();
  configurationEntities$ = this.configurationEntitiesSubject.asObservable();

  public configUpdateSubject = new Subject();
  configUpdateSubject$ = this.configUpdateSubject.asObservable();

  public postrestSubject = new Subject();
  postrestSubject$ = this.postrestSubject.asObservable();

  public postsftpSubject = new Subject();
  postsftpSubject$ = this.postsftpSubject.asObservable();

  public postsftpsshSubject = new Subject();
  postsftpsshSubject$ = this.postrestSubject.asObservable();

  public testConnectionSubject = new Subject();
  testConnectionSubject$ = this.testConnectionSubject.asObservable();

  public getAllConnectionRestSubject = new Subject();
  getAllConnectionRestSubject$ = this.getAllConnectionRestSubject.asObservable();

  public getAllConnectionSftpSubject = new Subject();
  getAllConnectionSftpSubject$ = this.getAllConnectionSftpSubject.asObservable();

  public getDropdownAndTableSubject = new Subject();
  getDropdownAndTableSubject$ = this.getDropdownAndTableSubject.asObservable();

  public getConnectionDetailsSubject = new Subject();
  getConnectionDetailsSubject$ = this.getConnectionDetailsSubject.asObservable();

  public PUTRestSubject = new Subject();
  PUTRestSubject$ = this.PUTRestSubject.asObservable();

  public PUTSftpSubject = new Subject();
  PUTSftpSubject$ = this.PUTSftpSubject.asObservable();

  public elementSubject = new Subject();

  public transmit2AddComponentSubject = new BehaviorSubject('');
  transmit2AddComponentSubject$ = this.transmit2AddComponentSubject.asObservable();

  public viewComponentSubject = new BehaviorSubject('');
  viewComponentSubject$ = this.viewComponentSubject.asObservable();
  ////adapter use
  public dataSubject = new BehaviorSubject('');
  dataSubject$ = this.dataSubject.asObservable();

  getEntityNameInputSubject = new Subject();
  getEntityNameInputSubject$ = this.getEntityNameInputSubject.asObservable();

  getEntityNameOutputSubject = new Subject();
  getEntityNameOutputSubject$ = this.getEntityNameOutputSubject.asObservable();

  getheaderEntityIdSubject = new Subject();
  getheaderEntityIdSubject$ = this.getheaderEntityIdSubject.asObservable();

  getqueryEntityIdSubject = new Subject();
  getqueryEntityIdSubject$ = this.getqueryEntityIdSubject.asObservable();

  getpathEntityIdSubject = new Subject();
  getpathEntityIdSubject$ = this.getpathEntityIdSubject.asObservable();

  getDownloadFileSubject = new Subject();
  getDownloadFile$ = this.getDownloadFileSubject.asObservable();

  manageKeyData = new Subject();
  manageKeyData$ = this.manageKeyData.asObservable();

  allKeysData = new Subject();
  allKeysData$ = this.allKeysData.asObservable();

  encryptionKeysData = new Subject();
  encryptionKeysData$ = this.encryptionKeysData.asObservable();

  SSLKeysData = new Subject();
  SSLKeysData$ = this.SSLKeysData.asObservable();

  SSHKeysData = new Subject();
  SSHKeysData$ = this.SSHKeysData.asObservable();

  storeKeydata = new BehaviorSubject('');
  storeKeydata$ = this.storeKeydata.asObservable();

  entitydropOutSubject = new Subject();
  entitydropOut$ = this.entitydropOutSubject.asObservable();

  entitydropHeaderSubject = new Subject();
  entitydropHeader$ = this.entitydropHeaderSubject.asObservable();

  entitydropPathSubject = new Subject();
  entitydropPath$ = this.entitydropPathSubject.asObservable();

  entitydropQuerySubject = new Subject();
  entitydropQuery$ = this.entitydropQuerySubject.asObservable();

  onboarding_adapters_dropdownAdapter = new Subject();
  onboarding_adapters_dropdownAdapter$ = this.onboarding_adapters_dropdownAdapter.asObservable();

  onboarding_adapters_dropdownConnection = new Subject();
  onboarding_adapters_dropdownConnection$ = this.onboarding_adapters_dropdownConnection.asObservable();

  onboarding_add_adapters_adapterType = new BehaviorSubject('');
  onboarding_add_adapters_adapterType$ = this.onboarding_add_adapters_adapterType.asObservable();

  onboarding_add_connections_adapterType = new BehaviorSubject('');
  onboarding_add_connections_adapterType$ = this.onboarding_add_connections_adapterType.asObservable();

  view_adapters_selectedDropdown = new Subject();
  view_adapters_selectedDropdown$ = this.view_adapters_selectedDropdown.asObservable();

  view_adapters_searchInput = new BehaviorSubject('');
  view_adapters_searchInput$ = this.view_adapters_searchInput.asObservable();

  onboarding_add_adapters_connectionType = new Subject();
  onboarding_add_adapters_connectionType$ = this.onboarding_add_adapters_connectionType.asObservable();

  add_adapters_adapterType = new BehaviorSubject('');
  add_adapters_adapterType$ = this.add_adapters_adapterType.asObservable();

  view_connections_selectedDropdown = new Subject();
  view_connections_selectedDropdown$ = this.view_connections_selectedDropdown.asObservable();

  view_connections_searchInput = new BehaviorSubject('');
  view_connections_searchInput$ = this.view_connections_searchInput.asObservable();

  onboarding_add_connections_dropdowns = new Subject();
  onboarding_add_connections_dropdowns$ = this.onboarding_add_connections_dropdowns.asObservable();

  add_connections_adapterType = new BehaviorSubject('');
  add_connections_adapterType$ = this.add_connections_adapterType.asObservable();

  constructor(
    public manageIntegrationsEndpointService: ManageIntegrationsEndpointService,
    public loader: LoaderService,
    public alertService: AlertService
  ) {}

  getCategories() {
    this.manageIntegrationsEndpointService
      .getCategories()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.categoriesSubject.next(res);
      });
  }

  sendDataToDynamicComponents(res: any) {
    this.dynamicCompData.next(res);
  }
  getSubCategories(subCategory: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getSubCategories(subCategory)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        let data = { ...res, name: subCategory };
        this.subCategoriesSubject.next(data);
      });
  }

  getConfiguration(ConfigId: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getConfiguration(ConfigId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.configurationsSubject.next(res);
      });
  }

  getConfigurationEntity(configId: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getConfigurationEntity(configId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.configurationEntitiesSubject.next(res);
      });
  }

  updateConfiguration(postData: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .updateConfiguration(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.configUpdateSubject.next(res);
      });
  }

  addConfiguration(postData: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .addConfiguration(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  //adapter component functions
  // for submit the payload to api
  addAdapter(formData: any, value: number) {
    return this.manageIntegrationsEndpointService.addAdapter(formData, value);
  }

  addAdapterv2(formData: any) {
    return this.manageIntegrationsEndpointService.addAdapterv2(formData);
  }
  addAdapterv3(formData: any) {
    return this.manageIntegrationsEndpointService.addAdapterv3(formData);
  }
  // getting information for swagger url
  addswagger(obj: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .addswagger(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getSwaggerSubject.next(res);
      });
  }

  // entity dropdown sftp schema No get added debounce time
  entitydrop(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .entitydrop(pagenum, pageSize, searchCriteria)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entitydropSubject.next(res);
      });
  }

  // entity dropdown sftp schema No get added debounce time
  entitydropOutRest(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .entitydrop(pagenum, pageSize, searchCriteria)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entitydropOutSubject.next(res);
      });
  }

  // entity dropdown sftp schema No get added debounce time
  entitydropHeaderRest(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .entitydrop(pagenum, pageSize, searchCriteria)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entitydropHeaderSubject.next(res);
      });
  }

  // entity dropdown sftp schema No get added debounce time
  entitydropPathRest(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .entitydrop(pagenum, pageSize, searchCriteria)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entitydropPathSubject.next(res);
      });
  }

  // entity dropdown sftp schema No get added debounce time
  entitydropQueryRest(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .entitydrop(pagenum, pageSize, searchCriteria)
      .pipe(debounceTime(500), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.entitydropQuerySubject.next(res);
      });
  }

  //get ids and all types connection
  listconnection() {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .listconnection()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.listConnection.next(res);
      });
  }

  //get connection list for choosen connecction
  connectionlist(id: string) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .connectionlist(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.connectionList.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }
  // put
  updateadapter(integration: any) {
    return this.manageIntegrationsEndpointService.updateadapter(integration);
  }

  // put
  updateadapterv2(integration: any, dsdId: any) {
    return this.manageIntegrationsEndpointService.updateadapterv2(integration, dsdId);
  }

  ///put
  updateadapterv3(formData: any, dsdId: any) {
    return this.manageIntegrationsEndpointService.updateadapterv3(formData, dsdId);
  }

  setupdateValue(transmitObj: any) {
    this.dataSubject.next(transmitObj);
  }

  transmit2AddComponent(res2Add: any) {
    this.transmit2AddComponentSubject.next(res2Add);
  }
  viewComponent(res2Add: any) {
    this.viewComponentSubject.next(res2Add);
  }

  extractEntity(id: any) {
    return this.manageIntegrationsEndpointService.extractEntity(id);
  }

  passValue(data: any) {
    //passing the data as the next observable
    this.elementSubject.next(data);
  }

  // connectionNameChecker(postData: any) {
  //   this.loader.show();
  //   this.manageIntegrationsEndpointService.getconnectionCheck(postData)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(
  //       (res: any) => {
  //         this.loader.hide();
  //         this.alertService.showToaster(res.result, '', 'success');
  //         this.testConnectionSubject.next(res);
  //       },
  //       (error: any) => {
  //         this.alertService.showToaster(error.message, '', 'error');
  //       }
  //     );
  //     }

  // view adapters
  getadapter(pageNumber: number, pageSize: number, query: string, adapter: string) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .getadapter(pageNumber, pageSize, query, adapter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.adapterView.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }

  getAimlAdapter(dsdId: any) {
    return this.manageIntegrationsEndpointService.getAimlAdapter(dsdId);
  }
  // get single adapter
  getadapterbyId(dsdId: any) {
    return this.manageIntegrationsEndpointService.getadapterbyId(dsdId);
  }

  // get single adapter
  getadapterv2byId(dsdId: any) {
    return this.manageIntegrationsEndpointService.getadapterv2byId(dsdId);
  }
  getadapterv3byId(dsdId: any) {
    return this.manageIntegrationsEndpointService.getadapterv3byId(dsdId);
  }

  postrest(postData: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .postrestEndPoint(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res?.status === 400) {
            this.alertService.showToaster(res?.message, '', 'error');
          } else {
            this.alertService.showToaster(res?.message, '', 'success');
            this.postrestSubject.next(res);
          }
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  postsftp(postData: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .postsftpEndPoint(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.postsftpSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  postSSHsftp(postData: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .postSSHsftpEndPoint(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.postsftpsshSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  connectionNameChecker(postData: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .getconnectionCheck(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res.result == 'Failed to connect') {
            this.alertService.showToaster(res.result, '', 'error');
          } else {
            this.alertService.showToaster(res.result, '', 'success');
          }
          this.testConnectionSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  getAllConnectionRest(attrId: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getAllConnectionsRest(attrId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAllConnectionRestSubject.next(res);
      });
  }

  getAllConnectionSftp(attrId: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getAllConnectionsSftp(attrId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAllConnectionSftpSubject.next(res);
      });
  }

  getDropdownAndTablefacade(dropdowns: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getDropdownAndTableEndPoint(dropdowns)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getDropdownAndTableSubject.next(res);
      });
  }

  getConnectionDetailsFacade(ConnectionId: any, pagenum: any, pageSize: any, query: any) {
    return this.manageIntegrationsEndpointService.getConnectionDetailsEndPoint(ConnectionId, pagenum, pageSize, query);
  }
  getCiscoConnection(ConnectionId: any, pagenum: any, pageSize: any, query: any) {
    return this.manageIntegrationsEndpointService.getConnectionDetailsEndPoint(ConnectionId, pagenum, pageSize, query);
  }

  PUTRest(recordId: any, data: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .PUTRestEndPoint(recordId, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster('update ' + res.message, '', 'success');
          this.PUTRestSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  PUTSftp(recordId: any, data: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .PUTSftpEndPoint(recordId, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster('update ' + res.message, '', 'success');
          this.PUTSftpSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  getEntityNameInput(id: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getEntityName(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEntityNameInputSubject.next(res);
      });
  }

  getEntityNameOutput(id: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getEntityName(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEntityNameOutputSubject.next(res);
      });
  }

  getheaderEntityId(id: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getEntityName(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getheaderEntityIdSubject.next(res);
      });
  }

  getqueryEntityId(id: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getEntityName(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getqueryEntityIdSubject.next(res);
      });
  }

  getpathEntityId(id: any) {
    this.loader.show();
    this.manageIntegrationsEndpointService
      .getEntityName(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getpathEntityIdSubject.next(res);
      });
  }
  aimlStatus(payload: any) {
    return this.manageIntegrationsEndpointService.aimlStatus(payload);
  }
  s3ConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitS3ConnectionData(payload);
  }

  getAllConnectionsS3(id: any) {
    return this.manageIntegrationsEndpointService.getS3connections(id);
  }

  updateS3ConnectionData(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateS3connections(id, payload);
  }

  facebookConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitFacebookConnectionData(payload);
  }

  twitterConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitTwitterConnectionData(payload);
  }

  jiraConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitJiraConnectionData(payload);
  }

  linkedinConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitLinkedinConnectionData(payload);
  }

  docusignConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitDocusignConnectionData(payload);
  }

  updateFacebookConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateFacebookConnection(id, payload);
  }

  updateTwitterConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateTwitterConnection(id, payload);
  }

  updateJiraConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateJiraConnection(id, payload);
  }

  updateLinkedinConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateLinkedinConnection(id, payload);
  }

  updateDocusignConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateDocusignConnection(id, payload);
  }

  getAllFacebookConnections(id: any) {
    return this.manageIntegrationsEndpointService.getFacebookConnections(id);
  }

  googleConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitGoogleConnectionData(payload);
  }

  submitGoogleConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitGoogle(id);
  }

  submitFacebookConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitFacebook(id);
  }

  submitTwitterConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitTwitter(id);
  }

  submitJiraConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitJira(id);
  }

  submitLinkedinConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitLinkedin(id);
  }

  submitDocusignConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitDocusign(id);
  }

  submitRestConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitRest(id);
  }

  getAllGoogleConnections(id: any) {
    return this.manageIntegrationsEndpointService.getGoogleConnections(id);
  }

  updateGoogleConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateGoogleConnections(id, payload);
  }
  getAllZoomConnections(id: any) {
    return this.manageIntegrationsEndpointService.getZoomConnections(id);
  }
  submitZoomConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitZoom(id);
  }

  zoomConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitZoomConnectionData(payload);
  }

  updateZoomConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateZoomConnections(id, payload);
  }

  telegramConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitTelegramConnectionData(payload);
  }

  updateTelegramConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateTelegramConnections(id, payload);
  }

  ciscoConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitCiscoConnectionData(payload);
  }

  submitCiscoConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitCisco(id);
  }

  getAllCiscoConnections(id: any) {
    return this.manageIntegrationsEndpointService.getCiscoConnections(id);
  }

  updateCiscoConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateCiscoConnections(id, payload);
  }

  graphConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitGraphConnectionData(payload);
  }

  submitGraphConnection(id: any) {
    return this.manageIntegrationsEndpointService.finalSubmitGraph(id);
  }

  getAllGraphConnections(id: any) {
    return this.manageIntegrationsEndpointService.getGraphConnections(id);
  }

  updateGraphConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateGraphConnections(id, payload);
  }

  kafkaConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitKafkaConnectionData(payload);
  }

  getAllConnectionsKafka(id: any) {
    return this.manageIntegrationsEndpointService.getKafkaconnections(id);
  }

  updateKafkaConnectionData(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateKafkaconnections(id, payload);
  }

  smtpConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitSMTPConnectionData(payload);
  }

  getAllConnectionsSMTP(id: any) {
    return this.manageIntegrationsEndpointService.getSMTPConnections(id);
  }

  updateSMTPConnectionData(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateSMTPConnections(id, payload);
  }

  imapConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitIMAPConnectionData(payload);
  }

  getAllConnectionsIMAP(id: any) {
    return this.manageIntegrationsEndpointService.getIMAPConnections(id);
  }

  updateIMAPConnectionData(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateIMAPConnections(id, payload);
  }

  pop3ConnectionData(payload: any) {
    return this.manageIntegrationsEndpointService.submitPOP3ConnectionData(payload);
  }

  getAllConnectionsPOP3(id: any) {
    return this.manageIntegrationsEndpointService.getPOP3Connections(id);
  }

  updatePOP3ConnectionData(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updatePOP3Connections(id, payload);
  }

  dbConnectionSave(payload: any) {
    return this.manageIntegrationsEndpointService.saveDbConnection(payload);
  }

  updateDbConnection(id: any, payload: any) {
    return this.manageIntegrationsEndpointService.updateDbConnection(id, payload);
  }

  getAllDBConnections(id: any) {
    return this.manageIntegrationsEndpointService.getDBConnections(id);
  }

  downloadFileAdapter(cuname: string) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .downloadFileAdapter(cuname)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.getDownloadFileSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  saveManageKey(payload: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .saveManageKey(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.manageKeyData.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  fetchEncryptionKEYKeys(pageSize: any, pageNumber: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .fetchManageKeys(pageSize, pageNumber, 'ENCRYPTIONKEY')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.encryptionKeysData.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  fetchSSLKeys(pageSize: any, pageNumber: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .fetchManageKeys(pageSize, pageNumber, 'SSLCERTIFICATE')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.SSLKeysData.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  fetchSSHKeys(pageSize: any, pageNumber: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .fetchManageKeys(pageSize, pageNumber, 'SSHKEY')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.SSHKeysData.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  searchSSHKeys(name: any, pageSize: any, pageNumber: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .searchManageKeys(name, pageSize, pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.SSHKeysData.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  getSchedularDetails(pageNumber: any, pageSize: any, adapterSelected: any, searchCuInput: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.manageIntegrationsEndpointService
      .getSchedularDetails(pageNumber, pageSize, adapterSelected, searchCuInput)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.schedularDetails.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }

  suspendSchedular(schedularName: any) {
    return this.manageIntegrationsEndpointService.suspendSchedular(schedularName);
  }
  resumeSchedular(schedularName: any, currentSchedular: any) {
    return this.manageIntegrationsEndpointService.resumeSchedular(schedularName, currentSchedular);
  }

  storeKeyFUpdate(data: any) {
    this.storeKeydata.next(data);
  }

  update_onboarding_adapters_variables(variable: string, data: any) {
    let key = 'onboarding_adapters_' + variable;
    this[key].next(data);
  }

  update_onboarding_add_adapters_variables(variable: string, data: any) {
    let key = 'onboarding_add_adapters_' + variable;
    this[key].next(data);
  }

  update_onboarding_add_connections_variables(variable: string, data: any) {
    let key = 'onboarding_add_connections_' + variable;
    this[key].next(data);
  }

  update_view_adapters_variables(variable: string, data: any) {
    let key = 'view_adapters_' + variable;
    this[key].next(data);
  }

  update_view_connections_variables(variable: string, data: any) {
    let key = 'view_connections_' + variable;
    this[key].next(data);
  }

  update_add_adapters_variables(variable: string, data: any) {
    let key = 'add_adapters_' + variable;
    this[key].next(data);
  }

  update_add_connections_variables(variable: string, data: any) {
    let key = 'add_connections_' + variable;
    this[key].next(data);
  }

  saveFileAsEntity(formData: any) {
    return this.manageIntegrationsEndpointService.saveFileAsEntity(formData);
  }
  aimlSubmit(formData: any) {
    return this.manageIntegrationsEndpointService.aimlSubmit(formData);
  }
  aimlUpdate(formData: any, dsdId: any) {
    return this.manageIntegrationsEndpointService.aimlUpdate(formData, dsdId);
  }
  addAdapteraiml(data: any) {
    return this.manageIntegrationsEndpointService.addAdapteraiml(data);
  }

  updateAimladapter(data: any) {
    return this.manageIntegrationsEndpointService.updateAimladapter(data);
  }
  getAIMLConnections(data: any) {
    return this.manageIntegrationsEndpointService.getAIMLConnections(data);
  }
  getGSI(search: string) {
    return this.manageIntegrationsEndpointService.getGSI(search, 0, 10);
  }
  testEmailConfig(payload: any) {
    return this.manageIntegrationsEndpointService.testEmailConfig(payload);
  }
  saveConnection(payload: any, connection: any) {
    return this.manageIntegrationsEndpointService.saveConnection(payload, connection);
  }
  updateConnection(payload: any, connection: any) {
    return this.manageIntegrationsEndpointService.updateConnection(payload, connection);
  }
  getConnectionDetails(dsdId: any, type: any) {
    return this.manageIntegrationsEndpointService.getConnectionDetails(dsdId, type);
  }

  getWebhookURL(adapterName: string) {
    return this.manageIntegrationsEndpointService.getWebhookURL(adapterName);
  }

  aimlManualTraining(attributeId: any) {
    return this.manageIntegrationsEndpointService.aimlManualTraining(attributeId);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
