import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NewSolutionEndpointService } from './create-solution-endpoint.service';
import { takeUntil } from 'rxjs/operators';
import { Gsi } from '../modal/gsi';
@Injectable({
  providedIn: 'root',
})
export class NewSolutionFacadeService {
  gsi$: Observable<Gsi[]>;
  // private changeUnitSource = new BehaviorSubject('');
  // cuList = this.changeUnitSource.asObservable();
  private selectedBookSubject = new BehaviorSubject('');
  selectedBook = this.selectedBookSubject.asObservable();
  private ngUnsubscribe = new Subject();
  searchSidebar$ = new BehaviorSubject(null);
  calenderView = new Subject();

  alertPopUpState = new Subject();
  searchSuggestions = new Subject();
  searchSuggestions$ = this.searchSuggestions.asObservable();

  public searchResults = new Subject();
  searchResults$ = this.searchResults.asObservable();

  public allSearchResults = new Subject();
  allSearchResults$ = this.allSearchResults.asObservable();

  public cardIds = new Subject();
  cardIds$ = this.cardIds.asObservable();

  public fromMcc = new BehaviorSubject(false);

  searchQueryTemp = new Subject();
  searchQueryTemp$ = this.searchQueryTemp.asObservable();

  closeMCCSearch = new Subject();
  closeMCCSearch$ = this.closeMCCSearch.asObservable();

  libraryType = new Subject();
  libraryType$ = this.libraryType.asObservable();

  constructor(private newsolutionendpoint: NewSolutionEndpointService, private http: HttpClient) {}
  /**
   * Sets calender view
   * @param value
   */
  setCalenderView(value: boolean) {
    this.calenderView.next(value);
  }

  /**
   * Gets calender view value
   * @returns
   */
  getCalendarViewValue() {
    return this.calenderView.asObservable();
  }

  /**
   * Gets calendar events
   * @returns
   */
  getCalendarEvents(payload: any, isForDropdown: boolean, queryParamsForFilter?: any) {
    return this.newsolutionendpoint.getCalendarEvents(payload, isForDropdown, queryParamsForFilter);
  }


  //scheduler API
  scheduleCalendarEvent(payload: any) {
    return this.newsolutionendpoint.scheduleCalendarEvent(payload);
  }

  updateCalendarEvent(payload: any) {
    return this.newsolutionendpoint.updateCalendarEvent(payload);
  }

  deleteCalendarEvent(payload: any) {
    return this.newsolutionendpoint.deleteCalendarEvent(payload);
  }

  getCalendarAvailability(payload: any) {
    return this.newsolutionendpoint.getCalendarAvailability(payload);
  }

  getTypeAheadSearcheResults(payload: any) {
    this.newsolutionendpoint
      .getTypeAheadSearcheResults(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.searchSuggestions.next(res?.result?.result);
      });
  }

  getTypeAheadSearcheResultsNSLLibrary(query: string) {
    this.newsolutionendpoint
      .getTypeAheadSearcheResultsNSLLibrary(query)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.searchSuggestions.next(res?.result?.result);
      });
  }

  getSearchResults(searchedText: string) {
    this.newsolutionendpoint
      .getSearchResults(searchedText)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.searchResults.next(res?.result);
      });
  }

  getAdvTypeAheadSearcheResultsNSLLibrary(payload: any) {
    this.newsolutionendpoint
      .getAdvTypeAheadSearcheResultsNSLLibrary(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.searchSuggestions.next(res?.result?.result);
      });
  }

  viewAllSearchResults(payload: any) {
      this.newsolutionendpoint
      .viewAllSearchResults(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.allSearchResults.next({
          data: res?.result,
          recordType: payload?.recordType,
          pageNumber: payload?.pageNumber,
        });
      });
  }

  getCardIds(data: any, recordData?: any) {
    this.newsolutionendpoint
      .getCardIds(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.cardIds.next({ data: res?.result[data], recordData: recordData });
      });
  }

  /**
   * Sets alert pop up state
   * @param state
   */
  setAlertPopUpState(state: boolean) {
    this.alertPopUpState.next(state);
  }

  /**
   * Gets alert pop up state
   * @returns
   */
  getAlertPopUpState() {
    return this.alertPopUpState.asObservable();
  }

  /**
   * Resets alert pop up state
   */
  resetAlertPopUpState() {
    setTimeout(() => {
      this.alertPopUpState.next(false);
    }, 500);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // saveCU(cu: any) {
  //   this.newsolutionendpoint
  //     .saveCU(cu)
  //     .pipe(untilDestroyed(this))
  //     .subscribe((res) => {
  //       // this.toastr.success('Chnage unit created successfully');
  //       return res;
  //     });
  // }

  // updateBookData(book: any) {
  //   this.selectedBookSubject.next(book);
  // }

  // getAllCus(pagenum: number, searchCriteria: string) {
  //   this.newsolutionendpoint
  //     .getAllCus(pagenum, searchCriteria)
  //     .pipe(untilDestroyed(this))
  //     .subscribe((response: any) => {
  //
  //       this.changeUnitSource.next(response.result.content);
  //     });
  // }

  // saveGsi(body: any) {
  //   this.newsolutionendpoint
  //     .saveGsi(body)
  //     .pipe(untilDestroyed(this))
  //     .subscribe((res: any) => {
  //
  //     });
  // }

  sendChangedLibraryType(str: string) {
    this.libraryType.next(str);
  }

  sendSearchQuery(str: string) {
    this.searchQueryTemp.next(str);
  }

  setCloseMCCSearch() {
    this.closeMCCSearch.next(true);
  }
}
