import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getCurrentUserName, getObjectByValue } from '../../generic-folder/generic-functions';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { SolutionDictionaryFacadeService } from '../solution-dictionary/solution-dictionary-facade.service';
import { AlertService } from '../toaster/alert.service';

@Component({
  selector: 'app-common-ontology',
  templateUrl: './common-ontology.component.html',
  styleUrls: ['./common-ontology.component.scss'],
})
export class CommonOntologyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() labels: any;
  @Input() metaInfo: any = { ontology: [], description: '', author: {}, keywords: '' };
  @Input() isGSIMetaData: boolean;
  @Output() getMetaData: EventEmitter<any> = new EventEmitter<any>();
  @Output() flagOntology: EventEmitter<any> = new EventEmitter<any>();
  private ngUnsubscribe = new Subject();
  ontologyData: any = [];
  orgOntologyData: any = [];
  lastChild: any;
  enableMetaData: boolean = false;

  constructor(
    private solDictionaryFacadeService: SolutionDictionaryFacadeService,
    private alertService: AlertService,
    private gsiFacadeService: GsiFacadeService
  ) {
    this.detectOntologyMasterList();
  }

  ngOnInit() {
    this.loadOntologyList();
    this.metaInfo = { ...this.metaInfo, author: { name: getCurrentUserName() }, isEdit: true };
    this.detectMetaInfo();
    this.onChangeMetaData();
    this.assignMetaDataDescription();
  }

  assignMetaDataDescription() {
    this.gsiFacadeService.componentData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res == 'gsi') this.getGsiMetaDataDescription();

      /*istanbul ignore else*/
      if (res == 'book') this.getBookMetaDataDescription();
    });
  }

  getGsiMetaDataDescription() {
    this.gsiFacadeService.updateGSIData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res && this.isGSIMetaData) {
        this.metaInfo.description = res.description;
        /* istanbul ignore next */
        if (res?.keywords?.length > 0) {
          let str = '';
          for(let key of res?.keywords)
          {
            if(key != '')
            {
              str += key;
              str += ',';
            }
          }
          str = str.replace(/,\s*$/, "");
          this.metaInfo.keywords = str == ',' ? '' : str;
        }
      }
    });
  }
  getBookMetaDataDescription() {
    this.gsiFacadeService.bookData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.metaInfo.description = res.description;
        /* istanbul ignore else */
        if (res.keywords?.length > 0) {
          this.metaInfo.keywords = res.keywords[0];
        }
      }
    });
  }

  ngOnChanges() {
    setTimeout(() => {
      this.detectMetaInfo();
    }, 500);
  }

  detectOntologyMasterList() {
    this.solDictionaryFacadeService.ontologyMasterList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.ontologyData = [res];
        this.orgOntologyData = [res];
      }
    });
  }

  detectMetaInfo() {
    this.metaInfo.author = this.metaInfo.author ? this.metaInfo.author : { name: getCurrentUserName() };
    /* istanbul ignore next */
    this.metaInfo?.ontology?.sort((obj1: any, obj2: any) => {
      if (obj1.id > obj2.id) {
        return 1;
      }

      if (obj1.id < obj2.id) {
        return -1;
      }

      return 0;
    });
    /* istanbul ignore next */
    if (this.metaInfo?.isEdit && this.ontologyData.length > 0) {
      this.enableMetaData = true;
      let ontData = this.metaInfo?.ontology?.filter((x: any) => x.level != 1);
      let data: any = [];
      if (ontData?.length > 0) {
        for (let i = 0; i < ontData.length; i++) {
          let x = ontData[i];
          x = {
            id: getObjectByValue('id', x.id, this.ontologyData[i].children),
            children: this.ontologyData[i].children,
          };
          data.splice(i, 1);
          data.push(x);
          /* istanbul ignore next */
          if (x.children.length > 0 && x.id.children.length > 0 && i + 1 != ontData.length) {
            data.push(x.id);
          }
          this.lastChild = x.id;
          this.ontologyData = data;
        }
      } else {
        this.ontologyData = this.orgOntologyData;
      }
      this.metaInfo.isEdit = false;
    }
  }

  loadOntologyList() {
    this.solDictionaryFacadeService.loadOntologyMasterList();
  }

  onChangeOntology(item: any, index: number) {
    /* istanbul ignore next */
    if (item) {
      this.lastChild = item;
      this.metaInfo = {
        ...this.metaInfo,

        ontology: [{ id: this.lastChild?.id }],
      };
    } else {
      this.lastChild = null;
      this.metaInfo = { ...this.metaInfo, ontology: [] };
    }
    this.flagOntology.emit(true);
    this.getMetaData.emit(this.metaInfo);
    this.ontologyData.splice(index + 1, this.ontologyData.length);
  }

  onClickActionIcons(label?: any) {
    if (label) {
      this.enableMetaData = true;
    } else {
      /* istanbul ignore next */
      if (!this.lastChild) {
        this.alertService.showToaster('Please select an ontology', '', 'info');
      } else if (this.lastChild?.children?.length > 0) {
        /* istanbul ignore next */
        this.ontologyData = [...this.ontologyData, this.lastChild];
        this.lastChild = {};
        this.flagOntology.emit(false);
      } else {
        this.alertService.showToaster('Subcategory not found', '', 'info');
      }
    }
  }

  onChangeMetaData() {
    this.getMetaData.emit(this.metaInfo);
  }

  onClearClick(item: any) {
    item.searchText = '';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
