<div *ngIf="!field?.configuration?.showDataAsTab">
  <div [formGroup]="group" [ngClass]="foundObject?.event == 'Option 7' ? 'date-parent-class-styles' : ''">
    <!-- For date type UI controls -->
    <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
      <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
        <label
          pTooltip="{{ field.label }}"
          tooltipPosition="top"
          id="{{ field.attribute.name }}"
          tooltipStyleClass="transcustomtolltipbook"
          class="main-label nh-mb-0"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="
            field?.type != 'yearmonth' &&
            !field?.hideLabels &&
            !field?.configuration?.hideLabel &&
            !field?.hideLabelMultiAttr
          "
          ><span class="main-label-inner">
            <span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="text-danger">*</span>
          </span>
        </label>
        <p
          class="nh-ml-6 d-flex main-label-helperIcon"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <div
        class="d-flex align-items-center nh-column-gap-5 pb-2 nh-lh-10"
        *ngIf="
          field.isMulti &&
          !field.configuration?.hideMultiValueIcon &&
          field.type !== 'yearmonth' &&
          !(field?.attribute)['isTableConfig']
        "
      >
        <mat-icon
          *ngIf="
            field.type != 'date' &&
            field.isMulti &&
            !field.configuration?.hideMultiValueIcon &&
            field.type !== 'yearmonth' &&
            !(field?.attribute)['isTableConfig']
          "
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="dateAddAttr_{{ field.attribute.name }}"
          (click)="addAttribute.next()"
          >add</mat-icon
        >
        <mat-icon
          *ngIf="
            field.type != 'date' &&
            field.isMulti &&
            !field.configuration?.hideMultiValueIcon &&
            field.type !== 'yearmonth' &&
            !(field?.attribute)['isTableConfig']
          "
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="dateAddAttr_{{ field.attribute.name }}"
          (click)="removeAttribute.next()"
          >remove</mat-icon
        >
      </div>
    </div>
    <p
      class="top-text"
      *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <div
      *ngIf="field?.type === 'currentdate' || field.type === 'date'"
      class="date-picker-wrapper sub-right-class"
      [ngClass]="{
        inValidClass:
          !field?.isInfo &&
          (group?.controls)[field?.attribute?.name]?.invalid &&
          (group?.controls)[field?.attribute?.name]?.touched
      }"
      [ngClass]="isMulti ? 'pointer-cursor' : ''"
    >
      <ng-container *ngIf="field.type === 'currentdate'">
        <p-calendar
          #dateFormatter
          class="ui-custom-calendar ui-custom-date"
          [style.pointerEvents]="'none'"
          [placeholder]="requiredFormat"
          [(ngModel)]="field.value"
          [matTooltip]="field?.configuration?.hideTooltip ? '' : formattedValue"
          [matTooltipPosition]="'above'"
          formControlName="{{ field.attribute.name }}"
          [disabled]="field?.readonly"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (click)="dateClicked($event)"
          [ngClass]="{ 'right-class-side': rightSideOffset }"
          [required]="field?.isRequired && !field?.isInfo"
          [dateFormat]="requiredFormatPrime"
          (onSelect)="registerOnChange($event)"
          (document:click)="closeFix($event)"
          id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        ></p-calendar>
        <em class="nh-icon nh-icon-calender02 date-icon"></em>
      </ng-container>
      <ng-container *ngIf="field.type === 'date'">
        <p-calendar
          *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])"
          class="{{ appliedClass }}"
          [ngClass]="isMulti ? 'pointer-cursor' : ''"
          panelStyleClass="date-datepicker-calendar"
          [ngClass]="{ 'empty-cal-val': isMulti && field?.value?.length }"
          [matTooltip]="field?.configuration?.hideTooltip ? '' : formattedValue"
          [matTooltipPosition]="'above'"
          [placeholder]="((labels?.[requiredFormat] || requiredFormat) | uppercase)"
          [disabled]="field?.readonly"
          [(ngModel)]="field.value"
          formControlName="{{ field.attribute.name }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (onShow)="dateClicked($event, true)"
          [required]="field?.isRequired && !field?.isInfo"
          (onSelect)="registerOnChange($event, true)"
          (keypress)="keyPress($event)"
          (onInput)="inputChange($event)"
          [showOnFocus]="false"
          (onBlur)="!calendarDateClicked && onBlur($event)"
          [dateFormat]="requiredFormatPrime"
          [disabledDates]="disableSpecificDate"
          [monthNavigator]="true"
          icon="nh-icon nh-icon-calender02 date-icon"
          [showIcon]="true"
          [disabledDays]="disabledDays?.length ? disabledDays : []"
          [appendTo]="bodyAppend"
          [showButtonBar]="foundObject?.event == 'Option 3' ? true : false"
          id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
          [selectionMode]="isMulti ? 'multiple' : 'single'"
          [readonlyInput]="isMulti ? true : false"
        ></p-calendar>
        <p-calendar
          *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']"
          class="datecalendar-input"
          panelStyleClass="date-calender"
          [placeholder]="labels?.[requiredFormat] || (requiredFormat | uppercase)"
          [disabled]="field?.readonly"
          [(ngModel)]="field.value"
          formControlName="{{ field.attribute.name }}"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (onShow)="dateClicked($event, true)"
          [required]="field?.isRequired && !field?.isInfo"
          (onSelect)="registerOnChange($event, true)"
          (keypress)="keyPress($event)"
          (onInput)="inputChange($event)"
          [showOnFocus]="false"
          (onBlur)="onBlur($event)"
          [dateFormat]="requiredFormatPrime"
          [disabledDates]="disableSpecificDate"
          [monthNavigator]="true"
          icon="nh-icon nh-icon-calender02 date-icon"
          [showIcon]="true"
          appendTo="body"
          [touchUI]="true"
          [selectionMode]="isMulti ? 'multiple' : 'single'"
          [readonlyInput]="isMulti ? true : false"
          pTooltip="{{ field?.value | date: requiredFormat }}"
          tooltipPosition="top"
          #dateCalendar
        >
          <ng-template pTemplate="header">
            <div class="date-calender-header">
              <ul>
                <li class="calendarclose" (click)="dateCalendar.hideOverlay()">
                  <i class="bi bi-x-lg"></i>
                </li>
              </ul>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <div class="date-calender-footer">
              <ul>
                <li (click)="dateCalendar.hideOverlay()">
                  <i class="bi bi-check2"></i>
                </li>
                <li class="calendarclose" (click)="dateCalendar.hideOverlay()">
                  <i class="bi bi-x-lg"></i>
                </li>
              </ul>
            </div>
          </ng-template>
        </p-calendar>
        <label class="date-placeholder-label" *ngIf="!isMulti && invalidDate">{{
          labels?.InvalidDate || 'Invalid date'
        }}</label>
        <mat-chip-list class="date-mat-chips" *ngIf="isMulti">
          <mat-chip *ngFor="let item of field.value; let i = index" [removable]="true" (removed)="remove(i, $event)"
            >{{ item | date: requiredFormat }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </div>

    <!-- For Year Month UI control -->
    <div
      *ngIf="
        field.type === 'yearmonth' &&
        !((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])
      "
      class="yearField"
    >
      <mat-form-field appearance="standard">
        <div class="d-flex align-items-center">
          <label
            class="main-label"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner">
              <span class="main-label-text text-truncate">{{ field.label }} :</span></span
            >
          </label>
          <p
            class="nh-ml-6 d-flex"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="custom-year-picker year-month-wrapper"
          [ngClass]="{
            inValidClass:
              !field?.isInfo &&
              (group?.controls)[field?.attribute?.name]?.invalid &&
              (group?.controls)[field?.attribute?.name]?.touched
          }"
        >
          <p id="dateSelect_{{ field.attribute.name }}_{{ field.id }}" class="datedisplay">
            {{ field.value | date: requiredFormat }}
          </p>
          <svg
            class="actual-year-month-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <g id="Group_104425" data-name="Group 104425" transform="translate(-679 -222)">
              <rect
                id="Rectangle_38766"
                data-name="Rectangle 38766"
                width="24"
                height="24"
                transform="translate(679 222)"
                fill="#333"
                opacity="0"
              />
              <g id="Group_98139" data-name="Group 98139" transform="translate(680.5 222.732)">
                <path
                  id="Path_101798"
                  data-name="Path 101798"
                  d="M23.489,3.777h15.75a3.146,3.146,0,0,1,3.125,3.171v16a3.146,3.146,0,0,1-3.121,3.167H23.489a3.146,3.146,0,0,1-3.125-3.171v-16a3.146,3.146,0,0,1,3.121-3.167Zm0,21.335H39.239a2.147,2.147,0,0,0,2.125-2.163v-16a2.147,2.147,0,0,0-2.129-2.167H23.491A2.147,2.147,0,0,0,21.364,6.94v16a2.147,2.147,0,0,0,2.129,2.167Z"
                  transform="translate(-20.864 -2.844)"
                  fill="#333"
                />
                <path
                  id="Line_5451"
                  data-name="Line 5451"
                  d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
                  transform="translate(15.75)"
                  fill="#333"
                />
                <path
                  id="Line_5452"
                  data-name="Line 5452"
                  d="M0,4.6a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V4.1A.5.5,0,0,1,0,4.6Z"
                  transform="translate(5.25)"
                  fill="#333"
                />
                <path
                  id="Line_5453"
                  data-name="Line 5453"
                  d="M20.25.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H20.25a.5.5,0,0,1,.5.5A.5.5,0,0,1,20.25.5Z"
                  transform="translate(0.375 7.504)"
                  fill="#333"
                />
              </g>
            </g>
          </svg>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            [matTooltip]="field?.configuration?.hideTooltip ? '' : formattedValue"
            [matTooltipPosition]="'above'"
            id="dateSelect_{{ field.attribute.name }}_{{ field.id }}"
            [disabled]="field.readonly"
            (click)="openDatePicker()"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker startView="multi-year" (monthSelected)="chosenMonthHandler($event, picker)">
          </mat-datepicker>
        </div>
        <input
          matInput
          style="display: none;"
          [value]="field.value"
          [matDatepicker]="picker"
          [formControlName]="field.attribute.name"
          [readonly]="true"
          [required]="field?.isRequired && !field?.isInfo"
          id="dateSelect_{{ field.attribute.name }}_{{ field.id }}"
          (dateChange)="onDateChange($event)"
          [min]="minDate"
          [max]="maxDate"
          [matDatepickerFilter]="myFilter"
        />
        <mat-hint></mat-hint>
        <mat-icon
          *ngIf="
            field?.type != 'date' &&
            field?.isMulti &&
            !field.configuration?.hideMultiValueIcon &&
            !(field?.attribute)['isTableConfig']
          "
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="dateAddAttr_{{ field.attribute.name }}"
          (click)="addAttribute.next()"
          >add</mat-icon
        >
        <mat-icon
          *ngIf="
            field?.type != 'date' &&
            field?.isMulti &&
            !field.configuration?.hideMultiValueIcon &&
            !(field?.attribute)['isTableConfig']
          "
          [ngClass]="{ 'mat-icon-disable': field.readonly }"
          matSuffix
          id="dateAddAttr_{{ field.attribute.name }}"
          (click)="removeAttribute.next()"
          >remove</mat-icon
        >
      </mat-form-field>
    </div>
    <div
      *ngIf="
        field.type === 'yearmonth' &&
        (this.field?.attribute)['isTableConfig'] &&
        (this.field?.attribute)['tableUiStyle']
      "
    >
      <div class="d-flex align-items-center">
        <label
          class="main-label"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          ><span class="main-label-inner">
            <span class="main-label-text text-truncate">{{ field.label }} :</span></span
          >
        </label>
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <p-calendar
        class="datecalendar-input"
        panelStyleClass="date-calender"
        placeholder="MM/YYYY"
        [formControlName]="field.attribute.name"
        appendTo="body"
        view="month"
        dateFormat="mm/yy"
        [readonlyInput]="true"
        [touchUI]="true"
        icon="nh-icon nh-icon-calender02 date-icon"
        [showIcon]="true"
        [disabled]="field.readonly"
        id="dateSelect_{{ field.attribute.name }}_{{ field.id }}"
        pTooltip="{{ field?.value | date: 'MM/YYYY' }}"
        tooltipPosition="top"
        #dateCalendar
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
      >
        <ng-template pTemplate="header">
          <div class="date-calender-header">
            <ul>
              <li class="calendarclose" (click)="dateCalendar.hideOverlay()">
                <i class="bi bi-x-lg"></i>
              </li>
            </ul>
          </div>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="date-calender-footer">
            <ul>
              <li (click)="dateCalendar.hideOverlay()">
                <i class="bi bi-check2"></i>
              </li>
              <li class="calendarclose" (click)="dateCalendar.hideOverlay()">
                <i class="bi bi-x-lg"></i>
              </li>
            </ul>
          </div>
        </ng-template>
      </p-calendar>
    </div>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>

    <!-- For validation message of UI controls -->
    <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
      <mat-error
        *ngIf="validation.type && (validation.type.toLocaleLowerCase() === 'error' || validation.type.toLocaleLowerCase() === 'block_warn') && getErrorMessage(field, validation)"
      >
        {{ validation.message }}</mat-error
      >
      <mat-error
        [ngClass]="validation.type == 'info' ? 'infocolor' : 'warncolor'"
        *ngIf="
          validation.type && validation.type.toLocaleLowerCase() !== 'error' && validation.type.toLocaleLowerCase() !== 'block_warn' && getInfoWarnMessage(field, validation)
        "
        >{{ validation.message }}</mat-error
      >
    </ng-container>
  </div>
</div>
<div
  class="dateAstab"
  *ngIf="field?.configuration?.showDataAsTab"
  [ngClass]="{
    inValidClass:
      !field?.isInfo &&
      (group?.controls)[field?.attribute?.name]?.invalid &&
      (group?.controls)[field?.attribute?.name]?.touched
  }"
>
  <label
    pTooltip="{{ field.label }}"
    tooltipPosition="top"
    id="{{ field.attribute.name }}"
    tooltipStyleClass="transcustomtolltipbook"
    class="main-label"
    [style.color]="field.color"
    [style.font-size.px]="field.fontSize"
    *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
    ><span class="main-label-inner">
      <span class="main-label-text text-truncate">{{ field.label }} :</span>
      <span *ngIf="field?.isRequired && !field?.isInfo" class="text-danger">*</span>
    </span>
  </label>
  <p-carousel
    class="dateAstab_slider"
    [value]="days"
    [numVisible]="field?.configuration?.dateTabConfig?.maxNumberOfTabs"
    [numScroll]="1"
    [showIndicators]="false"
  >
    <ng-template let-day pTemplate="day">
      <button
        class="nh-unsetAll text-center nh-cursor-pointer nh-wp-100"
        [ngClass]="{ active: selectedDay?.name === day.name }"
        (click)="selectedDate(day)"
      >
        <label class="nh-display-block nh-fw-700">{{
          field?.configuration?.dateTabConfig?.style == 14
            ? (day.name | date: 'ccc')
            : field?.configuration?.dateTabConfig?.style == 30
            ? (day.name | date: 'ccccc')
            : (day.name | date: 'EEE')
        }}</label>
        <span class="nh-display-block">{{
          field?.configuration?.dateTabConfig?.style == 14
            ? (day.name | date: 'dd MMM, yy')
            : field?.configuration?.dateTabConfig?.style == 30
            ? (day.name | date: 'dd')
            : (day.name | date: 'dd MMM, yyyy')
        }}</span>
      </button>
    </ng-template>
  </p-carousel>
</div>
