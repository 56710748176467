import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NodeGsiFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
@Component({
  selector: 'app-shade-of-potentiality',
  templateUrl: './shade-of-potentiality.component.html',
  styleUrls: ['./shade-of-potentiality.component.scss'],
})
export class ShadeOfPotentialityComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  gsiDetails: any;
  entityList: any = [];
  @Input() gsiObj: any;
  @Input() gsiData: any;
  @Input() changeUnit: any;
  @Output() isSOPEnabled: EventEmitter<any> = new EventEmitter<any>();
  layerType: string;
  isOrderEnabled: boolean = false;
  realitySlotItems: any = [];
  potentialSlotItems: any = [];
  realityList: any = [{ label: '', entityContextualId: '' }];
  potentialityList: any = [{ label: '', entityContextualId: '' }];
  solutionCreateFlag = false;
  dragIndex: number = 0;
  libraryType: any;
  entityDisplayList: any;
  gsiName: any;
  connectorClicked: boolean = false;
  expandShadeItems: boolean = false;
  myControl = new FormControl();
  filteredOptions: any = [];
  selectedShadeItem: any;
  SopDCDlist: any;
  wishGsi: any;
  currentCU: any;
  labels: any;
  wishGsiId: any;
  constructor(
    private changeunitFacade: NodeChangeUnitFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService
  ) {
    this.detectImportGsi();
    this.getSopDCD();
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.gsiObj?.solutionLogic?.forEach((cu: any) => {
      /* istanbul ignore next */
      cu?.layers?.forEach((layer: any) => {
        /* istanbul ignore next */
        layer?.participatingItems?.forEach((entity: any, k: any) => {
          /* istanbul ignore next */
          let string = cu?.name + '/' + layer?.type + ' layer/' + entity?.name;
          /* istanbul ignore next */
          let layerId = this.getLayerId(k, layer?.type);
          this.entityList.push({ label: string, entityContextualId: layerId });
        });
      });
    });
    let sopObj = this.changeUnit?.tCUShadesOfPotentiality?.[0];
    if (sopObj) {
      this.realitySlotItems = sopObj?.realitySlotItems ? sopObj?.realitySlotItems : [];
      this.potentialSlotItems = sopObj?.potentialSlotItems ? sopObj?.potentialSlotItems : [];
      this.isOrderEnabled = sopObj?.isOrderEnabled;
      this.SopDCDlist = Object.values(sopObj?.masterGSIDCDMap)?.[0];
      this.wishGsiId = Object.keys(sopObj?.masterGSIDCDMap)?.[0];
    }
    this.filteredOptions = this.entityList;
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getSopDCD() {
    this.gsiFacade.sopConnectionData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        /* istanbul ignore next */
        this.SopDCDlist = res?.DCD;
        /* istanbul ignore next */
        this.wishGsi = res?.wishGsi;
        /* istanbul ignore next */
        this.currentCU = res?.currentCU;
      }
    });
  }

  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  getLayerId(slotIndex: number, layerType: string) {
    let indexPadding = this.getIndexPadding(slotIndex + 1);
    let layerShortCut = 'PL.SL' + indexPadding;
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      layerShortCut = 'triggerCES.SL' + indexPadding;
    } else if (layerType === 'reservedActions') {
      layerShortCut = 'reservedActions.SL' + indexPadding;
    }
    return layerShortCut;
  }

  drop(event: CdkDragDrop<string[]>) {
    /* istanbul ignore next */
    let temp = JSON.parse(JSON.stringify(event?.previousContainer?.data[event?.previousIndex]));

    /* istanbul ignore next */
    if (event?.container?.element?.nativeElement?.innerText === 'Reality') {
      /* istanbul ignore next */
      let indexR = this.realitySlotItems?.indexOf(temp?.entityContextualId);

      if (indexR === -1) {
        /* istanbul ignore next */
        this.realitySlotItems.push(temp?.entityContextualId);
      }
    }
    /* istanbul ignore next */
    if (event?.container?.element?.nativeElement?.innerText === 'Potentiality') {
      /* istanbul ignore next */
      let indexP = this.potentialSlotItems?.indexOf(temp?.entityContextualId);

      if (indexP === -1) {
        /* istanbul ignore next */
        this.potentialSlotItems.push(temp?.entityContextualId);
      }
    }
    /* istanbul ignore next */
    if (event?.previousContainer === event?.container) {
      /* istanbul ignore next */
      moveItemInArray(event?.container?.data, event?.previousIndex, event?.currentIndex);
    } else {
      /* istanbul ignore next */
      transferArrayItem(
        event?.previousContainer?.data,
        event?.container?.data,
        event?.previousIndex,
        event?.currentIndex
      );
    }
  }

  // detectToggle(event: any) {
  //
  //
  // }

  // detectLibraryType(){
  //   this.gsiFacade.openMyCollection$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
  //     /* istanbul ignore else */
  //     if (res) {
  //       this.libraryType = res;
  //
  //     }
  //   });
  // }

  clickedConnector() {
    this.connectorClicked = true;
    let data = {
      connectorClicked: 'shade',
      wishGsi: this.gsiDetails,
      currentGsi: this.gsiObj,
      changeUnit: this.changeUnit,
      listOfSopDCDs: this.SopDCDlist ? this.SopDCDlist : [],
    };
    this.changeunitFacade.shadeConnector(data);
  }

  detectImportGsi() {
    this.gsiFacade.tempGSI$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.gsiDetails = res;
        /* istanbul ignore next */
        this.gsiName = res?.name;
      }
    });
  }

  displayItems(solutionLogic: any) {
    let unlinkList = solutionLogic;
  }

  expandShadesList() {
    this.expandShadeItems = !this.expandShadeItems;
  }

  filterSearchData(value: any) {
    /* istanbul ignore next */
    value = value?.toLowerCase().trim();

    /* istanbul ignore else */
    if (value) {
      this.filteredOptions = [];
      for (let entity of this.entityList) {
        let label = entity.label.split('/');
        /* istanbul ignore next */
        let entitydata = label[label.length - 1]?.toLowerCase();

        /* istanbul ignore next */
        if (entitydata?.includes(value)) {
          /* istanbul ignore next */
          this.filteredOptions?.push(entity);
          break;
        } else {
          this.filteredOptions = [];
        }
      }
    } else {
      this.filteredOptions = this.entityList;
    }
  }

  //expandShadeItems = true

  createSolution() {
    this.expandShadeItems = true;
    this.gsiFacade.openLibrary('ShadesOfPotentiality');
  }

  save() {
    /* istanbul ignore next */
    let gsiMasterid = this.wishGsi?.masterId ? this.wishGsi?.masterId : this.wishGsiId;
    let sopObj = {
      tCUShadesOfPotentiality: [
        {
          isOrderEnabled: this.isOrderEnabled,
          realitySlotItems: this.realitySlotItems,
          potentialSlotItems: this.potentialSlotItems,
          masterGSIDCDMap: {
            [gsiMasterid]: this.SopDCDlist,
          },
        },
      ],
    };
    /* istanbul ignore next */
    this.changeUnit.tfId = this.changeUnit?.tfReferencedChangeUnit;
    /* istanbul ignore next */
    this.changeUnit.id = this.changeUnit?.referencedChangeUnit;
    let cuObj = this.changeUnit;
    if (this.SopDCDlist?.length > 0) cuObj = { ...this.changeUnit, ...sopObj };
    this.changeunitFacade.saveDraftCu(cuObj, true);
    this.isSOPEnabled.emit(false);
  }

  saveImportedGsi() {}

  ngOnDestroy() {
    this.changeunitFacade.enableShadeOfPotentiality(false);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
