import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dldChangeUnit',
  /* istanbul ignore next */
  pure: true,
})
export class DLDChangeUnitPipe implements PipeTransform {
  transform(data: any, propertyToShow: string): any {
    if (data && propertyToShow === 'CUName') {
      return data?.name;
    }
  }
}
