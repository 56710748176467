import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertService, AttributeEndpointService, defaultEditorConfig, displayDateDefaultValue, EntityFacadeService, EventsFacadeService, getSchedulerDetails, NodeGsiFacadeService, TranslatorService, triggerCuMapper, upperVsLowerBetData, validateDefaultValue, wrapperDrop
} from '@common-services';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import { NodeCompareComponent } from '../node-compare/node-compare.component';
// import {  } from 'dist/common-services/lib/mappers/trigger-cu/trigger-cu-mapper';
@Component({
  selector: 'app-node-trigger-attribute-edit',
  templateUrl: './node-trigger-attribute-edit.component.html',
  styleUrls: ['./node-trigger-attribute-edit.component.scss'],
})
export class NodeTriggerAttributeEditComponent implements OnInit {
  ngUnsubscribe = new Subject();
  step: number = 0;
  @Input() tempGsiObj: any;
  @Input() singleEntity: boolean;
  @Input() configurationData: any;
  @Output() closeattribute: EventEmitter<any> = new EventEmitter<any>();

  searchText: string = '';
  attributeTypes: any;
  selectedUiValidator: any;
  selectedValidatorsList: any;
  UiValidatorDropdown: any;
  constraints: any[];
  dataType: any;
  unitType: boolean;
  sourceValues: any = [];
  attributeValidations: any;
  iseditAttr: any = true;
  memberShipFlagsArr: boolean = false;
  attrDetails = this.formBuilder.group({
    defaultOption: [null],
    isMultiValue: [false],
    unitType: [''],
    defaultValue: [''],
    constantChangeDriver: [''],
    constantChangeDriverValue: [''],
    attributeType: ['', Validators.required],
    attributeUiElement: ['', Validators.required],
    locationType: [''],
  });
  addEntityBtn: boolean = true;
  flagsave: boolean = true;
  fileUrl: any;
  labels: any;
  editFileUrl: any;
  changeUnit: any;
  constantChangeDriverObj: any = {};
  constantChangeDrivers: any = {};
  selectorSeparated: string;
  formatFields: FormArray;
  autoIdForm: FormGroup = this.formBuilder.group({
    maxValue: [''],
    formatFields: this.formBuilder.array([]),
    countReset: [''],
    financialMonth: [''],
    countStartParameter: [''],
    incIdLeadingZeroes: [''],
  });
  Months: any = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
  getFinancialMonth: boolean;
  attributeNameOptions: any = [
    { name: 'Full', value: '' },
    { name: 'Suffix', value: 'suf' },
    { name: 'Prefix', value: 'pre' },
  ];
  separators: any = ['+', '-', '*', '%', '/', '(', ')', '_'];
  dateFormats: any = [
    'MM',
    'dd',
    'yy',
    'yyyy',
    'dd/MM/yyyy',
    'yyyy-MM-dd',
    'dd-MMM-yyyy',
    'E, MMM dd yyyy',
    'yy-yy',
    'yyyy-yyyy',
  ];
  dateFormatsForShow: any = ['dd/MM/yyyy', 'MM/dd/yyyy', 'yyyy/MM/dd'];
  yearMonthFormatsForShow: any = ['MM-yyyy', 'MM-yy', 'yy-MM', 'yyyy-MM'];
  timeFormatsForShow: any = ['12-hr', '24-hr'];
  countResetParams: any = ['None', 'MM', 'dd', 'yyyy'];
  separatingOperatorSelected: boolean = false;
  isIncrementValue: boolean;
  entityAttributeSelection: any = [];
  financialMonth: any;
  incIdLeadingZeroes: any;
  countStartParameter: any;

  /* ----------For Default value START -------- */
  allDefaultValues: any = [];
  multiDefaultValue: boolean = false;
  excludeUiControlList = [
    'file',
    'image',
    'audio',
    'video',
    'password',
    'encrypttext',
    'currentdate',
    'currentdatetime',
    'qrScanner',
    'qrDecoder',
    'scheduler',
    'signature',
    'scormProgress',
    'webex',
    'location',
    'videoRecording',
    'audioRecording',
    'capturingImage',
  ]; // Exclude UI controls list
  uiControlList = [
    'boolean',
    'date',
    'datetime',
    'dateRange',
    'duration',
    'editor',
    'time',
    'yearMonth',
    'year',
    'basicSlider',
    'inputSlider',
    'rangeSlider',
    'stepSlider',
    'verticalSlider',
  ]; // For seperate UI controls list apart from normat text value
  defaultValue: any = ''; // Common default value
  booleanDefaultValue = false; // For Boolean
  dateTypeDefaultValue: any; // For date, datetime, dateRange
  displayDateTypeDefaultValue: any; // For display purpose
  dateTypeUiControls: any = ['date', 'datetime', 'dateRange', 'time', 'yearMonth', 'year']; // For date type UI controls
  durationDefaultValue: any = {
    days: null,
    hours: null,
    minutes: null,
  }; // For duration
  durationDisplayValue = ''; // For duration
  EditorConfig: AngularEditorConfig = JSON.parse(JSON.stringify(defaultEditorConfig));
  dateFormatType: string = 'MM/dd/yyyy';
  timeFormatType: string = '24-hr';
  yearMonthFormatType: string = 'yyyy-MM';
  /* ----------For Default value END -------- */

  locationType: any;
  attrConfiguration: { locationType: any };
  locationArray: any = [];
  selectedChangedriverOptAttr: any;
  SelectedalternativeAttr: any;
  optChangeDrivers: any = ['OPTIONAL', 'NEGATIVE', 'ALTERNATIVE', 'HIDDEN', 'NONE'];
  altAttr: any = [];

  /*-------------- Scheduler UI Control - START --------------------*/
  schedulerDetails = this.formBuilder.group({
    incrementValue: [''],
    day: [''],
    time: [''],
    interval: [''],
    recurrenceExpression: [''],
    startDate: [''],
    endDate: ['', Validators.required],
  });
  isDay: boolean = false;
  isTime: boolean = false;
  intervalOptions: any[];
  timeFormat: any;
  currentEntity: any;
  targetValue = 100;
  sliderStepValue: number;
  sliderRangeValue: any;
  selectedType: any;
  GsiObj: any;
  triggerCuData: any;
  levelChoice: any = ['Trigger', 'Basic'];
  propertyType: string;
  currentOp: string;
  memberShipAttr: string = '';
  memberShipAttrWithId: string = '';
  memberShipCriteriaOperators: any = ['<', '>', '=', '==', '!=', '<=', '>='];
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  labelForInformationLayer = { name: 'information Layer', label: 'information Layer' };
  labelForTriggerCESLayer = { name: 'triggerCES Layer', label: 'triggerCES Layer' };
  physicalLayer: any[];
  informationLayer: any[];
  triggerCESLayer: any[];
  selectedChangeUnit: any;
  lowerBetProperties: any = { defaultValue: [], constraints: [], memberShipCriteria: '' };
  UpperBetProperties: any = { defaultValue: [], constraints: [], memberShipCriteria: '' };
  betLevel: boolean = true;
  betLevelDefault: boolean = true;
  levelList: any[] = ['Trigger', 'Basic'];
  betLevelMembership: boolean = true;
  dialogRef: any;
  compareDef: boolean = false;
  compareVal: boolean = false;
  compareCriteria: boolean = false;
  /*-------------- Scheduler UI Control - END --------------------*/
  translationType: string = 'NONE';
  translationTypeArray: any = ['TRANSLATE', 'TRANSLITERATE', 'NONE'];
  constructor(
    private entityFacadeService: EntityFacadeService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private attributeEndpointService: AttributeEndpointService,
    private eventsFacadeService: EventsFacadeService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService,
    private alertService: AlertService,
    private gsiFacade: NodeGsiFacadeService,
    private nodeChangeUnitFacade: NodeChangeUnitFacadeService,
    public dialog: MatDialog
  ) {
    this.getTriggerCuLayerData();
    this.getValidations();
    this.detectLanguageChange();
    this.detectEntityData();
    this.getDCDStream();
    this.getAttributeComparisonData();
    // this.getGsiData();
  }

  ngOnInit(): void {
    this.updateTriggerCuData();
    /*istanbul ignore next*/
    this.getUiControlDataType();
    this.fetchCurrentEntity(this.configurationData.data.entity?.id);
    /*istanbul ignore next*/
    if (this.configurationData.data.attr?.attributeType?.type == 'list') {
      this.multiDefaultValue = true;
    }
    /*istanbul ignore next*/
    if (this.configurationData.data?.attr?.nslAttributeMetaData) {
      /*istanbul ignore next*/
      this.selectedChangedriverOptAttr = this.configurationData?.data?.attr?.nslAttributeMetaData?.changeDriverTypes;
      /*istanbul ignore next*/
      this.SelectedalternativeAttr = this.configurationData?.data?.attr?.nslAttributeMetaData?.alternativeAttribute;
      /*istanbul ignore next*/
      if (this.configurationData?.data?.attr?.nslAttributeMetaData?.translationType == 'TRANSLATE') {
        this.translationType = this.labels?.TRANSLATE;
      } else if (this.configurationData.data?.attr?.nslAttributeMetaData?.translationType == 'TRANSLITERATE') {
        this.translationType = this.labels?.TRANSLITERATE;
      } else {
        this.translationType = this.labels?.NONE;
      }
    }
    /*istanbul ignore next*/
    this.altAttr = this.configurationData.data.entity?.nslAttributes;
    this.entityFacadeService.getUIValidations();
    /* istanbul ignore next*/
    this.tempGsiObj = this.GsiObj;
    const currentCUReferencedChangeUnit = this.configurationData?.data?.currentcu?.data?.dsdRefId
      ? this.configurationData.data.currentcu.data.dsdRefId
      : this.configurationData?.data?.currentcu?.referencedChangeUnit;
    /*istanbul ignore next*/
    if (currentCUReferencedChangeUnit) {
      /*istanbul ignore next*/
      this.changeUnit = this.tempGsiObj?.solutionLogic?.find(
        (x: any) => x?.referencedChangeUnit == currentCUReferencedChangeUnit
      );
      // let layer = this.changeUnit?.triggerCULayers?.filter(
      //   (layer: any) => layer?.type == this.configurationData?.data?.layerType
      // );
      this.changeUnit?.triggerCULayers?.forEach((layer: any) => {
        layer?.participatingItems?.forEach((entity: any) => {
          entity?.nslAttributes?.forEach((attribute: any) => {
            if (attribute?.id == this.configurationData?.data?.attr?.id) this.configurationData.data.attr = attribute;
          });
        });
      });
    }
    /*istanbul ignore next*/
    if (
      this.configurationData?.data?.attr?.configuration &&
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location'
    ) {
      let foundAttr = this.configurationData?.data?.attr?.configuration;
      this.attrConfiguration = this.IsJsonString(foundAttr) ? JSON.parse(foundAttr) : [];
      this.attrDetails.patchValue({
        locationType: this.attrConfiguration.locationType,
      });
    }

    /* istanbul ignore next */
    if (
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'file' ||
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'image' ||
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'video' ||
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'audio'
    ) {
      /* istanbul ignore next */
      this.editFileUrl = this.IsJsonString(this.configurationData?.data?.attr?.defaultValue)
        ? JSON.parse(this.configurationData?.data?.attr?.defaultValue)
        : null;
    }

    /* istanbul ignore next */
    if (this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues?.length > 0) {
      this.sourceValues = this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues;
    }
    this.editAttributes();
    /* istanbul ignore next */
    let autoIdFormat = this.configurationData?.data?.attr?.attributeType?.properties?.format;
    /* istanbul ignore next */
    if (autoIdFormat && autoIdFormat !== '') {
      this.autoIdForm.controls.formatFields['controls'] = [];
      let replacedFormat = autoIdFormat?.slice(1, autoIdFormat?.length - 1);
      let splitFormat = replacedFormat?.split('}{');
      let formFields = [];
      /* istanbul ignore next */
      splitFormat?.forEach((field: any, fieldIndex: number) => {
        this.formatFields = this.autoIdForm.get('formatFields') as FormArray;
        /* istanbul ignore next */
        if (field?.includes('${')) {
          this.formatFields.push(this.createFormat('attribute'));
          this.autoIdForm.value.formatFields[fieldIndex].type = 'attribute';
          let attributeSplit = field?.split('}');
          this.autoIdForm.value.formatFields[fieldIndex].entAttributeName = attributeSplit[0].replace('${', '');
          /* istanbul ignore next */
          if (attributeSplit[1]) {
            let stringTypeSplit = attributeSplit[1]?.split('.');
            this.autoIdForm.value.formatFields[fieldIndex].entAttributeString = stringTypeSplit[1];
            this.autoIdForm.value.formatFields[fieldIndex].entAttributeStringValue = stringTypeSplit[2];
          }
        } else if (field[0] === '$') {
          if (
            field === '$+' ||
            field === '$-' ||
            field === '$*' ||
            field === '$%' ||
            field === '$/' ||
            field === '$(' ||
            field === '$)' ||
            field === '$_'
          ) {
            this.formatFields.push(this.createFormat('separator'));
            this.separatingOperatorSelected = true;
            this.selectorSeparated = field.replace('$', '');
            this.autoIdForm.value.formatFields[fieldIndex].type = 'separator';
          } else if (field === '$INC_ID') {
            this.formatFields.push(this.createFormat('increment'));
            this.autoIdForm.value.formatFields[fieldIndex].type = 'increment';
          } else {
            this.formatFields.push(this.createFormat('date'));
            this.autoIdForm.value.formatFields[fieldIndex].dateFormat = field.replace('$', '');
            this.autoIdForm.value.formatFields[fieldIndex].type = 'date';
          }
        } else {
          this.formatFields.push(this.createFormat('format'));
          this.autoIdForm.value.formatFields[fieldIndex].format = field;
          this.autoIdForm.value.formatFields[fieldIndex].type = 'format';
        }
      });
      this.autoIdForm.patchValue({
        formatFields: this.autoIdForm.value.formatFields,
        maxValue: this.configurationData.data?.attr?.attributeType?.properties?.incIdMaxValue,
        countStartParameter: this.configurationData.data.attr?.attributeType?.properties?.countStartParameter,
        incIdLeadingZeroes: this.configurationData.data.attr?.attributeType.properties?.incIdLeadingZeroes,
        countReset: this.configurationData.data.attr?.attributeType?.properties?.countResetParameter,
      });
      this.checkIncrementIDPresence();
    }
    /* istanbul ignore next */
    if (this.singleEntity) {
      this.flagsave = false;
    }

    /* --------- For Scheduler UI control - START ------------ */
    /* istanbul ignore next */
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'scheduler') {
      /* istanbul ignore next */
      let attrData = this.configurationData?.data?.attr?.attributeType;
      /* istanbul ignore next */
      let interval = attrData?.properties?.interval;
      /* istanbul ignore next */
      if (interval === 'MONTH' || interval === 'WEEK') {
        this.isDay = true;
        this.isTime = true;
      } else if (interval === 'DAY') {
        this.isDay = false;
        this.isTime = true;
      } else {
        this.isDay = false;
        this.isTime = false;
      }

      /* conversion of time */
      /* istanbul ignore next */
      if (attrData?.properties?.time) {
        this.timeFormat = new Date('2/16/2021, ' + attrData.properties.time + ' UTC');
        attrData.properties.time = new Date('2/16/2021, ' + attrData.properties.time + ' UTC');
      }

      /* patching the values for scheduler ui-control on edit */
      /* istanbul ignore next */
      this.schedulerDetails?.patchValue(getSchedulerDetails(attrData));
    }
    this.getLayersData();
    // this.callAttributeComparisonData('DEFAULT_VALUE');
    // this.callAttributeComparisonData('ATTRIBUTE_CONSTRAINTS');
    // if(this.changeUnit?.reservedCUType === 'search') this.callAttributeComparisonData('MEMBERSHIP CRITERIA');
  }

  // to check of the entity is version is 1.0 and draft
  getUiControlDataType() {
    let val = this.configurationData;
    /* istanbul ignore next */
    let attr = val?.data?.attr;
    /* istanbul ignore next */
    let entity = val?.data?.entity;
    /* istanbul ignore next */
    if ((entity?.status === 'DRAFT' && entity?.version === '1.0') || !attr?.id) {
      this.getAttributeTypes();
    } else {
      this.checkAttributeUiControl(attr);
    }
  }
  checkAttributeUiControl(attr: any) {
    /* istanbul ignore next */
    if (attr?.attributeType?.uiElement?.esDataType || attr?.attributeType?.uiElement?.dataType) {
      /* istanbul ignore next */
      this.getAttributeTypes(
        attr?.attributeType?.uiElement?.esDataType
          ? attr?.attributeType?.uiElement?.esDataType
          : attr?.attributeType?.uiElement?.dataType
      );
    }
  }
  /*istanbul ignore next*/
  createFormat(type: string): FormGroup {
    return this.formBuilder.group({
      type: type,
      format: '',
      entAttributeName: '',
      dateFormat: '',
      entAttributeString: '',
      entAttributeStringValue: '',
    });
  }

  typeChange(selectType: any, ind: number) {
    this.selectedValidatorsList[ind].selectType = selectType;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      /*istanbul ignore next*/
      this.locationArray = [
        this.labels?.Current_Location,
        this.labels?.Location_Markers,
        this.labels?.Location_Tracker,
      ];
      /*istanbul ignore next*/
      this.intervalOptions = [
        this.labels?.SECOND,
        this.labels?.MINUTE,
        this.labels?.HOUR,
        this.labels?.DAY,
        this.labels?.MONTH,
        this.labels?.WEEK,
      ];
    });
  }
  /* autoIdentifier code start */
  checkIncrementIDPresence() {
    this.isIncrementValue = false;
    /* istanbul ignore next */
    this.autoIdForm?.controls?.formatFields['controls']?.forEach((field: any) => {
      if (field.value.type === 'increment') {
        this.isIncrementValue = true;
      }
    });
  }
  addField(fieldType: string) {
    /* istanbul ignore next */
    if (fieldType === 'attribute' && this.configurationData?.data?.entity?.nslAttributes?.length > 0) {
      this.entityAttributeSelection = [];
      /* istanbul ignore next */
      this.configurationData?.data?.entity?.nslAttributes?.forEach((attr: any) => {
        if (attr.name != this.configurationData.data.attr.name) {
          this.entityAttributeSelection.push(attr);
        }
      });
    }
    /* istanbul ignore next */
    this.formatFields = this.autoIdForm?.get('formatFields') as FormArray;
    /* istanbul ignore next */
    this.formatFields.push(this.createFormat(fieldType));
    this.checkIncrementIDPresence();
  }
  removeField(fieldIndex: number) {
    /* istanbul ignore next */
    this.autoIdForm?.controls?.formatFields['controls']?.splice(fieldIndex, 1);
    this.checkIncrementIDPresence();
  }

  selectSeparator(res: string) {
    this.selectorSeparated = res;
    this.separatingOperatorSelected = true;
  }

  removeAttributeSelection(field: any) {
    field.controls.entAttributeName.value = '';
  }

  checkYearFormat() {
    this.getFinancialMonth = false;
    /* istanbul ignore next */
    this.autoIdForm.value.formatFields?.forEach((field: any) => {
      /* istanbul ignore next */
      if (field?.type === 'date' && (field.dateFormat === 'yy-yy' || field.dateFormat === 'yyyy-yyyy')) {
        this.getFinancialMonth = true;
      }
    });
  }

  /**
   * re-arrange Auto id fields
   * @param event
   */
  /*istanbul ignore next*/
  drop(event: CdkDragDrop<string[]>) {
    wrapperDrop(event);
  }

  onChangeOption(event: any, index: any) {
    /*istanbul ignore next*/
    if (this.selectedChangedriverOptAttr !== 'none') {
      this.configurationData['nslAttributeMetaData'] = { changeDriverTypes: this.selectedChangedriverOptAttr };
    }
  }
  /* optional change drivers attribute level */
  onChangeOptionAttr(event: any, index: any) {
    /* istanbul ignore if */
    if (!this.selectedChangedriverOptAttr && this.selectedChangedriverOptAttr !== 'none') {
      this.configurationData.data.attr['nslAttributeMetaData'] = { changeDriverTypes: this.selectedChangedriverOptAttr };
    } else if (!this.selectedChangedriverOptAttr || this.selectedChangedriverOptAttr == 'none') {
      delete this.selectedChangedriverOptAttr['nslAttributeMetaData'];
    } else {
      this.configurationData.data.attr.nslAttributeMetaData = {
        changeDriverTypes: this.selectedChangedriverOptAttr,
        alternativeAttribute: this.SelectedalternativeAttr,
      };
    }
  }
  /*istanbul ignore next*/
  ChangeTranslationType(value: string) {
    this.translationType = value;
  }
  selectedAttribute(attr: any, field: any) {
    field.controls.entAttributeName.value = attr;
    field.value.entAttributeName = attr;
  }
  /* istanbul ignore next */
  inclLeadingZeroesGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.incIdLeadingZeroes = this.autoIdForm?.value?.incIdLeadingZeroes;
  }
  /* istanbul ignore next */
  maxValueGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.incIdMaxValue = this.autoIdForm?.value?.maxValue;
  }
  /* istanbul ignore next */
  financialMonthGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.financialMonth = this.autoIdForm?.value?.financialMonth;
  }
  countResetParameterGetter() {
    /*istanbul ignore next*/
    this.configurationData.data.attr.attributeType.properties.countResetParameter = this.autoIdForm?.value?.countReset;
  }
  countStartParameterGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.countStartParameter = this.autoIdForm?.value?.countStartParameter;
  }
  /* autoIdentifier code end*/
  /**
   * Determines whether json string or not
   * @param str
   * @returns
   */
  IsJsonString(str: any) {
    /* istanbul ignore next */
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  // addDataToEntity() {
  //   /*istanbul ignore next*/
  //   if (this.attrDetails?.valid) {
  //     // default value
  //     if (this.allDefaultValues?.length > 0) {
  //       /* istanbul ignore next */
  //       if (this.configurationData.data.attr.attributeType.type == 'list') {
  //         this.attrDetails.patchValue({
  //           defaultValue: JSON.stringify(this.allDefaultValues),
  //         });
  //       } else {
  //         this.attrDetails.patchValue({
  //           defaultValue: this.allDefaultValues[0],
  //         });
  //       }
  //     }
  //     this.attrDetails.value.defaultValue !== ''
  //       ? (this.configurationData.data.attr.defaultValue = this.attrDetails.value.defaultValue)
  //       : (this.configurationData.data.attr.defaultValue = '');
  //     /*istanbul ignore else*/
  //     if (this.fileUrl) {
  //       this.configurationData.data.attr.defaultValue = JSON.stringify(this.fileUrl);
  //     }
  //     /*istanbul ignore next*/
  //     if (this.configurationData.data.attr.attributeType.uiElement.uiElement === 'currency') {
  //       this.configurationData.data.attr.attributeType.properties.currencyType = this.attrDetails.value.unitType;
  //     }
  //     /*istanbul ignore next*/
  //     if (this.configurationData.data.attr.attributeType.uiElement.uiElement === 'metric') {
  //       this.configurationData.data.attr.attributeType.properties.unit = this.attrDetails.value.unitType;
  //     }
  //     if (this.configurationData.data.attr.attributeType.uiElement.dataType == 'autoIdentifier') {
  //       this.configurationData.data.attr.attributeType.properties = {};
  //       let keyIdentifier = '';
  //       /* istanbul ignore next*/
  //       this.autoIdForm?.controls?.formatFields['controls']?.forEach((fieldObj: any) => {
  //         keyIdentifier += keyIdentifierGetter(fieldObj, this.selectorSeparated);
  //       });
  //       this.configurationData.data.attr.attributeType.properties.format = keyIdentifier;
  //       this.configurationData.data.attr.attributeType.properties.separator = this.selectorSeparated;
  //       if (this.isIncrementValue) {
  //         /* istanbul ignore next */
  //         if (this.autoIdForm?.value?.maxValue) {
  //           this.maxValueGetter();
  //         }
  //         /* istanbul ignore next */
  //         if (this.autoIdForm?.value?.countReset && this.autoIdForm?.value?.countReset !== 'None') {
  //           this.countResetParameterGetter();
  //         }
  //         /* istanbul ignore next */
  //         if (this.autoIdForm?.value?.countStartParameter) {
  //           this.countStartParameterGetter();
  //         }
  //         /* istanbul ignore next */
  //         if (this.autoIdForm?.value?.incIdLeadingZeroes) {
  //           this.inclLeadingZeroesGetter();
  //         }
  //         if (this.getFinancialMonth) {
  //           /* istanbul ignore next */
  //           this.financialMonthGetter();
  //         }
  //       }
  //     }
  //     // slider value update
  //     /* istanbul ignore next */
  //     if (
  //       this.configurationData.data.attr.attributeType.uiElement.dataType == 'basicSlider' ||
  //       this.configurationData.data.attr.attributeType.uiElement.dataType == 'inputSlider' ||
  //       this.configurationData.data.attr.attributeType.uiElement.dataType == 'rangeSlider' ||
  //       this.configurationData.data.attr.attributeType.uiElement.dataType == 'stepSlider' ||
  //       this.configurationData.data.attr.attributeType.uiElement.dataType == 'verticalSlider'
  //     ) {
  //       let attrConfiguration: any = {
  //         targetValue: this.targetValue,
  //       };
  //       if (this.configurationData.data.attr.attributeType.uiElement.dataType == 'stepSlider') {
  //         attrConfiguration = { ...attrConfiguration, sliderStepValue: this.sliderStepValue };
  //       } else if (this.configurationData.data.attr.attributeType.uiElement.dataType == 'rangeSlider') {
  //         attrConfiguration = { ...attrConfiguration, sliderRangeValue: this.sliderRangeValue };
  //       }
  //       this.configurationData.data.attr['configuration'] = JSON.stringify(attrConfiguration);
  //     }
  //     //changing the time format
  //     /* istanbul ignore next */
  //     if (this.configurationData.data.attr.attributeType.uiElement.dataType === 'scheduler') {
  //       if (!this.configurationData.data.attr.attributeType.properties) {
  //         this.configurationData.data.attr.attributeType.properties = {};
  //       }
  //       if (this.schedulerDetails?.value?.time) {
  //         let time = new Date(this.schedulerDetails.value.time);
  //         this.schedulerDetails.value.time =
  //           ('0' + time.getUTCHours())?.slice(-2) +
  //           ':' +
  //           ('0' + time.getUTCMinutes())?.slice(-2) +
  //           ':' +
  //           ('0' + time.getUTCSeconds())?.slice(-2);
  //       }
  //       if (this.schedulerDetails?.value?.recurrenceExpression == null) {
  //         this.schedulerDetails.value.recurrenceExpression = '';
  //       }
  //       /* istanbul ignore next */
  //       if (this.schedulerDetails?.value?.startDate && typeof this.schedulerDetails?.value?.startDate === 'object') {
  //         this.schedulerDetails.value.startDate = this.schedulerDetails?.value?.startDate.toISOString();
  //       }
  //       /* istanbul ignore next */
  //       if (this.schedulerDetails?.value?.endDate && typeof this.schedulerDetails?.value?.endDate === 'object') {
  //         this.schedulerDetails.value.endDate = this.schedulerDetails?.value?.endDate.toISOString();
  //       }
  //       this.configurationData.data.attr.attributeType.properties = this.schedulerDetails.value;
  //     }

  //     if (this.attrDetails.value.isMultiValue || this.multiDefaultValue) {
  //       this.configurationData.data.attr.attributeType.type = 'list';
  //       this.configurationData.data.attr.attributeType.nestedNslDataTypeProperties = {
  //         item_type: { type: this.dataType },
  //       };
  //     } else {
  //       this.configurationData.data.attr.attributeType.type = this.configurationData.data.attr.attributeType.uiElement.dataType;
  //     }
  //     this.configurationData.data.attr.attributeType.extendedProperties.sourceValues = [...this.sourceValues];
  //   }
  // }
  addDefaultValuetoAttibute() {
    /* istanbul ignore next */
    if (this.sourceValues?.length >= 0) {
      this.addEntityBtn = true;
    }
    /*istanbul ignore next*/
    if (this.attrDetails.value.defaultOption.trim() !== '') {
      this.sourceValues.push({
        TYPE: 'OptionValue',
        DATA: {
          actualValue: this.attrDetails.value.defaultOption,
          displayValue: this.attrDetails.value.defaultOption,
        },
      });
    }
    this.attrDetails.patchValue({
      defaultOption: '',
    });
  }

  editAttributes() {
    // For default value
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr) {
      this.attrDetails.patchValue({
        defaultValue: '',
        attributeUiElement: this.configurationData.data.attr?.attributeType?.uiElement?.uiElement,
        attributeType: this.configurationData.data.attr?.attributeType?.uiElement?.dataType,
      });

      this.allDefaultValues = [];
      if (this.configurationData?.data?.attr?.defaultValue) {
        let attrDefaultValue = this.configurationData.data.attr.defaultValue;
        if (
          (this.configurationData.data.attr?.attributeType?.uiElement?.dataType === 'list' ||
            attrDefaultValue[0] == '[') &&
          this.configurationData.data.attr?.attributeType?.uiElement?.uiElement !== 'dateRange'
        ) {
          this.allDefaultValues = this.IsJsonString(attrDefaultValue) ? JSON.parse(attrDefaultValue) : [];
          this.multiDefaultValue = true;
        } else {
          this.allDefaultValues = [attrDefaultValue];
        }
      }
      /* istanbul ignore next */
      if (
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'boolean' &&
        this.allDefaultValues[0] === 'true'
      ) {
        this.booleanDefaultValue = true;
      }
    }

    /*istanbul ignore next*/
    this.constantChangeDriverObj = {
      slotId: this.configurationData.data?.entity?.id,
      slotIndex: this.configurationData.data.entityIndex,
      entity: this.configurationData.data.entity,
      attributeId: this.configurationData.data?.attr?.id,
      layerType: this.configurationData.data.entity?.layerType,
    };
    // let layerShortCut = this.getLayerId(this.constantChangeDriverObj);
    // // this.constantChangeDrivers = [];
    // // DATA[i].constantChangeDriverEntity = false;
    // /*istanbul ignore next*/
    // if (this.changeUnit?.slotItemProperties && this.changeUnit?.slotItemProperties[layerShortCut]) {
    //   this.constantChangeDrivers = this.changeUnit.slotItemProperties[
    //     layerShortCut
    //   ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute;
    //   let changeDriverCCDValue = this.constantChangeDrivers?.find(
    //     (x: any) => x.dsdId === this.configurationData.data.attr.dsdId
    //   );
    //   /*istanbul ignore next*/
    //   if (changeDriverCCDValue) {
    //     this.attrDetails.patchValue({
    //       constantChangeDriverValue: changeDriverCCDValue?.values ? changeDriverCCDValue.values : false,
    //       constantChangeDriver: true,
    //     });
    //   }
    // }
  }
  removeScourceValue(i: any) {
    if (this.sourceValues.length <= 1) {
      this.addEntityBtn = false;
    }
    /*istanbul ignore next*/
    if (this.allDefaultValues?.length > 0) {
      /*istanbul ignore next*/
      let value = this.sourceValues?.[i]?.DATA?.actualValue;
      /*istanbul ignore next*/
      let index = this.allDefaultValues?.findIndex((ele: any) => ele === value);
      /*istanbul ignore next*/
      if (index >= 0) {
        this.allDefaultValues.splice(index, 1);
      }
    }
    this.sourceValues.splice(i, 1);
  }
  checkOfMultiDefaultValues(value: boolean) {
    this.multiDefaultValue = value;
    this.configurationData.data.attr.attributeType.type = 'list';
    this.attrDetails.patchValue({
      isMultiValue: value,
    });
  }
  fileUploadUrl(event: any) {
    this.fileUrl = event;
  }

  // saveEntitywithAttr() {
  //   /* istanbul ignore next */

  //   if (
  //     this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'scheduler' &&
  //     !this.schedulerDetails?.value?.endDate
  //   ) {
  //     this.alertService.showToaster('Please provide end date and time for scheduler', '', 'info');
  //     return false;
  //   }
  //   /*istanbul ignore next*/
  //   //this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
  //   this.onChangeOptionAttr(event, 1);
  //   this.appendUiAttributes();
  //   // this.addDataToEntity();
  //   /*istanbul ignore next*/
  //   if (this.configurationData.data.attr?.attributeType?.type == undefined) {
  //     this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
  //   }
  //   this.configurationData.data.attr.constraints = this.constraints;
  //   /*istanbul ignore next*/
  //   const findAttrIndex = this.configurationData.data.entity.nslAttributes?.findIndex(
  //     (attr: any) => attr.name == this.configurationData.data.attr.name
  //   );
  //   /*istanbul ignore next*/
  //   if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location') {
  //     this.attrConfiguration = { locationType: this.attrDetails?.value?.locationType };
  //     this.configurationData.data.attr.configuration = JSON.stringify(this.attrConfiguration);
  //   }
  //   this.configurationData.data.entity.nslAttributes[findAttrIndex] = this.configurationData.data.attr;

  //   if (this.configurationData?.isGSI) {
  //     this.tempGsiObj.layers.forEach((layer: any) => {
  //       layer.participatingItems.forEach((item: any) => {
  //         if (item.name === this.configurationData.data.entity.name) {
  //           item.nslAttributes = this.configurationData.data.entity.nslAttributes;
  //         }
  //       });
  //     });
  //     this.gsiFacade.saveGsi(this.tempGsiObj);
  //     // (
  //   } else if (!this.addEntityBtn) {
  //     this.alertService.showToaster('Please add atleast one option', '', 'info');
  //     return false;
  //   } else {
  //     const currentAttrIndex = this.currentEntity.nslAttributes?.findIndex(
  //       (attr: any) => attr.name == this.configurationData.data.attr.name
  //     );
  //     this.currentEntity.nslAttributes[currentAttrIndex] = this.configurationData?.data?.attr;
  //     /*istanbul ignore next*/
  //     this.nodeEntityFacadeService.saveEntity(
  //       this.currentEntity,
  //       false,
  //       this.configurationData?.data?.cu,
  //       this.configurationData.data.entity.nslAttributes,
  //       this.configurationData.data?.layerType,
  //       false,
  //       this.configurationData?.configuration
  //     );
  //   }

  //   this.schedulerDetails.reset();
  // }
  closeAttribute() {
    this.closeattribute.emit(true);
  }
  getValidations() {
    this.entityFacadeService.attributeValidation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.attributeValidations = res;
        this.getvalidationsList();
      }
    });
  }
  getAttributeTypes(dataType?: any) {
    /*istanbul ignore next*/
    this.attributeEndpointService.getAllAttributeTypes(this.searchText, 1, 100, dataType).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res?.data) {
        this.attributeTypes = res.data;
      }
    });
  }
  appendUiAttributes() {
    this.constraints = [];
    /*istanbul ignore next*/
    this.selectedValidatorsList?.forEach((element: any, i: any) => {
      /*istanbul ignore next*/
      if (element) {
        this.constraints.push({
          name: element.name,
          properties: {},
          type: element.type,
        });
        /*istanbul ignore next*/
        if (element.properties && element.properties?.length) {
          element.properties?.forEach((value: any) => {
            this.constraints[i].properties[value.name] = value.message;
          });
        }
        if (element?.selectType) {
          this.constraints[i].properties['type'] = element?.selectType;
        }
      }
    });
  }
  validatorsChange(event: any, index: any) {
    event.properties?.map((val: any) => {
      val.message = '';
    });
    this.selectedUiValidator = event.name;
    this.selectedValidatorsList.push(event);
    this.UiValidatorDropdown.constraints?.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });

    this.UiValidatorDropdown.constraints.splice(index, 1);
    this.constraints = [];
    const constraintsArr = this.eventsFacadeService.getDisableValidatorsList(event.name);
    /* istanbul ignore next */
    if (constraintsArr && constraintsArr?.length) {
      /*istanbul ignore next*/
      constraintsArr?.forEach((element: any, i: any) => {
        this.UiValidatorDropdown.constraints?.forEach((value: any, j: any) => {
          if (element === value.name) {
            value.disabled = true;
          }
        });
      });
    }
  }
  removeValidator(row: any, index: any) {
    this.selectedValidatorsList.splice(index, 1);
    this.UiValidatorDropdown.constraints?.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });
    this.UiValidatorDropdown.constraints.push(row);
    /*istanbul ignore next*/
    this.UiValidatorDropdown.constraints = this.UiValidatorDropdown.constraints.filter(
      (v: any, i: any, a: any) => a?.findIndex((t: any) => t.name === v.name) === i
    );
  }
  validationMultiValue(attrType: any) {
    this.attributeEndpointService.getAllAttributeTypes(this.searchText, 1, 100).subscribe((result: any) => {
      /*istanbul ignore next*/
      if (result?.data) {
        let res: any;
        this.addEntityBtn = true;
        result?.data.forEach((type: any, i: any) => {
          if (attrType?.value == type?.uiElement) {
            res = type;
          }
        });
        if (res && res?.isMulti === true) {
          /* istanbul ignore next */
          if (res.isMulti === true && res?.uiElement != 'typeahead') {
            this.addEntityBtn = false;
          } else {
            this.addEntityBtn = true;
          }
        }
      }
    });
  }
  selectAttrType(attrType: any, attr: any) {
    /*istanbul ignore next*/
    if (attrType?.value !== 'checkbox' && attrType?.value !== 'dropdown' && attrType?.value !== 'radioButton') {
      this.sourceValues = [];
    }
    this.validationMultiValue(attrType);
    attr['uiElement'] = {};
    /*istanbul ignore next*/
    let uiElement = this.attributeTypes?.find((x: any) => x.uiElement.toLowerCase() === attrType.value.toLowerCase());
    /*istanbul ignore next*/
    if (uiElement && attr?.attributeType) {
      attr.attributeType.uiElement = uiElement;
      attr.attributeType.type = uiElement.dataType;
      this.dataType = uiElement.dataType;
      this.attrDetails?.patchValue({
        attributeUiElement: uiElement.uiElement,
        attributeType: uiElement.dataType,
      });
    }
    this.getAttributeTypes();
    this.getvalidationsList();
  }
  getvalidationsList() {
    /*istanbul ignore next*/
    if (!this.dataType) {
      /*istanbul ignore next*/
      this.dataType = this.configurationData?.data?.attr?.attributeType?.type;
    } /*istanbul ignore else*/
    if (this.dataType) {
      const constraintsData =
        this.attributeValidations?.length > 0 ? JSON.parse(JSON.stringify(this.attributeValidations)) : [];
      this.UiValidatorDropdown = constraintsData?.find((value: any) => {
        return value.name === this.dataType;
      });

      this.UiValidatorDropdown?.constraints?.map((val: any) => {
        /*istanbul ignore next*/
        if (val.name == 'optional' || val.name == 'empty') {
          val.disabled = true;
        }
      });
      /*istanbul ignore next*/
      if (this.attributeValidations && this.attributeValidations.length) {
        this.selectedValidatorsList = [];
        /*istanbul ignore next*/
        if (
          this.configurationData?.data?.attr &&
          this.configurationData.data.attr['constraints'] &&
          this.configurationData.data.attr['constraints'].length
        ) {
          this.configurationData.data.attr['constraints']?.forEach((value: any) => {
            const option = this.UiValidatorDropdown?.constraints?.find((val: any) => {
              return val.name == value.name;
            });
            const obj = {
              name: value.name,
              displayName: option?.displayName ? option.displayName : value.name,
            };
            obj['properties'] = [];
            /*istanbul ignore next*/
            if (value.properties && Object.keys(value.properties).length) {
              let editConstraintsArrKeys = Object.keys(value.properties);
              let editConstraintsArrValues = Object.values(value.properties);

              editConstraintsArrKeys?.forEach((val, i) => {
                obj['properties'].push({
                  name: val,
                  message: editConstraintsArrValues[i],
                });
              });
            }
            /*istanbul ignore next*/
            if (this.iseditAttr) {
              this.selectedValidatorsList.push(obj);
              this.selectedValidatorsList.filter((el: any) => {
                this.UiValidatorDropdown.constraints = this.UiValidatorDropdown?.constraints?.filter((el1: any) => {
                  return el1.name !== el.name;
                });
              });
            }
          });
        }
        if (this.selectedValidatorsList) {
          this.selectedValidatorsList.forEach((selected: any) => {
            selected.properties.forEach((value: any) => {
              if (value.name === 'type') {
                selected.selectType = value.message;
              }
            });
          });
        }
      }
    }
  }

  // Function to add default values
  addDefaultValue() {
    this.dateTypeDefaultValue = null;
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'duration') {
      /* istanbul ignore next */
      if (
        this.durationDefaultValue?.days !== null ||
        this.durationDefaultValue?.hours !== null ||
        this.durationDefaultValue?.minutes !== null
      ) {
        let durationFormat = '';

        /* istanbul ignore next */
        let days: any = this.durationDefaultValue?.days;
        /* istanbul ignore next */
        let hours: any = this.durationDefaultValue?.hours;
        /* istanbul ignore next */
        let minutes: any = this.durationDefaultValue?.minutes;
        if (!days && !hours) {
          if (minutes) durationFormat = `PT${minutes}M`;
          else durationFormat = `P`;
        } else if (!days) {
          if (minutes) durationFormat = `PT${hours}H${minutes}M`;
          else durationFormat = `PT${hours}H`;
        } else if (!hours) {
          if (minutes) durationFormat = `P${days}DT${minutes}M`;
          else durationFormat = `P${days}D`;
        } else {
          if (minutes) durationFormat = `P${days}DT${hours}H${minutes}M`;
          else durationFormat = `P${days}DT${hours}H`;
        }

        this.defaultValue = durationFormat;

        this.durationDefaultValue.days = null;
        this.durationDefaultValue.hours = null;
        this.durationDefaultValue.minutes = null;
      } else {
        this.alertService.showToaster('Please enter duration!', '', 'error');
        return;
      }
    }
    /* istanbul ignore next */
    if (this.defaultValue === '') {
      return;
    }
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'rating' && Number(this.defaultValue) > 5) {
      this.alertService.showToaster('Provide default value in range of 0 to 5', '', 'info');
      return;
    }

    /* istanbul ignore next */
    this.attrDetails?.patchValue({ defaultValue: this.defaultValue });
    this.configurationData.data.attr.defaultValue = this.attrDetails?.value?.defaultValue;
    /* istanbul ignore next */
    if (this.validateDefaultValue()) {
      /* istanbul ignore next */
      if (this.multiDefaultValue || this.allDefaultValues?.length === 0) {
        /* istanbul ignore next */
        if (
          this.attrDetails?.value?.defaultValue &&
          this.dateTypeUiControls?.includes(this.attrDetails?.value?.attributeUiElement)
        ) {
          /* istanbul ignore next */
          this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
          /* istanbul ignore next */
          this.attrDetails?.patchValue({ defaultValue: '' });
          this.defaultValue = '';
        } else {
          /* istanbul ignore next */
          if (this.attrDetails?.value?.defaultValue?.trim() !== '') {
            /* istanbul ignore next */
            this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
            /* istanbul ignore next */
            this.attrDetails?.patchValue({ defaultValue: '' });
            this.defaultValue = '';
          } else {
            this.alertService.showToaster(
              'Default value is already exist and multiple values are not allowed',
              '',
              'error'
            );
          }
        }
      } else {
        this.alertService.showToaster('Please enter valid default value!', '', 'error');
      }
    }
  }

  // Validate default value based on datatype and UI control
  validateDefaultValue() {
    /* istanbul ignore next */
    let uiControl = this.attrDetails?.value?.attributeUiElement;
    /* istanbul ignore next */
    let dataType = this.attrDetails?.value?.attributeType;
    /* istanbul ignore next */
    let value = this.attrDetails?.value?.defaultValue;

    let res = validateDefaultValue(
      this.dateTypeUiControls,
      uiControl,
      dataType,
      value,
      this.sourceValues,
      this.allDefaultValues
    );
    if (res.message.length > 0) {
      res.message.forEach((msg: any) => {
        this.alertService.showToaster(msg.msg, '', msg.type);
      });
    }
    return res.isValid;
  }

  // Function to remove default values
  removeDefaultValue(i: any) {
    this.allDefaultValues.splice(i, 1);
  }

  booleanValue() {
    this.allDefaultValues = [];
    if (this.booleanDefaultValue) {
      this.allDefaultValues.push('true');
    }
  }

  formatDate() {
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'time' && !isNaN(Date.parse(this.dateTypeDefaultValue))) {
      let updateTime = this.dateTypeDefaultValue?.setSeconds(0);
      this.dateTypeDefaultValue = new Date(updateTime);
    }
    this.defaultValue = this.dateTypeDefaultValue;
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'dateRange') {
      this.defaultValue = JSON.stringify(this.defaultValue);
    }
  }

  displayDateDefaultValue(value: any, attributeUiElement: any) {
    return displayDateDefaultValue(
      value,
      attributeUiElement,
      this.dateFormatType,
      this.timeFormatType,
      this.yearMonthFormatType
    );
  }

  // For yearMonth UI control
  /* istanbul ignore next */
  chosenYearHandler(normalizedYear: any) {
    this.dateTypeDefaultValue = new Date(normalizedYear);
  }

  // For yearMonth UI control
  /* istanbul ignore next */
  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.dateTypeDefaultValue = new Date(normalizedMonth);
    /* istanbul ignore next */
    datepicker?.close();
    this.formatDate();
  }

  /* istanbul ignore next */
  chosenYearForYear(normalizedYear: any, yearPicker: MatDatepicker<any>) {
    this.defaultValue = new Date(normalizedYear).getFullYear();
    /* istanbul ignore next */
    yearPicker?.close();
  }

  checkDuration() {
    if (this.durationDefaultValue.hours < 0 || this.durationDefaultValue.hours > 23) {
      this.durationDefaultValue.hours = null;
    }
    if (this.durationDefaultValue.minutes < 0 || this.durationDefaultValue.minutes > 59) {
      this.durationDefaultValue.minutes = null;
    }
  }

  // validations for a schedular-ui-control form values
  onSelectionChange(event: any) {
    /* istanbul ignore next */
    if (event?.value === 'MONTH' || event?.value === 'WEEK') {
      /* istanbul ignore next */
      this.schedulerDetails?.get('day')?.patchValue('');
      /* istanbul ignore next */
      this.schedulerDetails?.get('time')?.patchValue('');
      this.isDay = true;
      this.isTime = true;
    } else if (event?.value === 'DAY') {
      /* istanbul ignore next */
      this.schedulerDetails?.get('day')?.patchValue('');
      /* istanbul ignore next */
      this.schedulerDetails?.get('time')?.patchValue('');
      this.isDay = false;
      this.isTime = true;
    } else {
      /* istanbul ignore next */
      this.schedulerDetails?.get('day')?.patchValue('');
      /* istanbul ignore next */
      this.schedulerDetails?.get('time')?.patchValue('');
      this.isDay = false;
      this.isTime = false;
    }
  }

  updateNewTimeFormat(event: any) {
    /* istanbul ignore next */
    this.timeFormat = event?.value;
  }
  /* istanbul ignore next */
  detectEntityData() {
    this.nodeEntityFacadeService.editAttributeEntity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.configurationData.data.entity = response;
    });
  }

  deletedFileResponse($event: any) {
    this.allDefaultValues = [];
  }
  /* istanbul ignore next */
  fetchCurrentEntity(id: number) {
    this.nodeEntityFacadeService
      .getEntityDeatils(id, false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entity: any) => {
        this.currentEntity = entity?.result;
      });
  }

  saveAttr() {
    /* istanbul ignore next */

    if (
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'scheduler' &&
      !this.schedulerDetails?.value?.endDate
    ) {
      this.alertService.showToaster('Please provide end date and time for scheduler', '', 'info');
      return false;
    }
    /*istanbul ignore next*/
    this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
    this.onChangeOptionAttr(event, 1);
    this.appendUiAttributes();
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.nslAttributeMetaData) {
      this.configurationData.data.attr.nslAttributeMetaData['translationType'] = this.translationType;
    } else {
      this.configurationData.data.attr.nslAttributeMetaData = { translationType: this.translationType };
    }
    this.translationType = 'NONE';
    // this.addDataToEntity();
    /*istanbul ignore next*/
    if (this.configurationData.data.attr?.attributeType?.type == undefined) {
      this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
    }
    this.configurationData.data.attr.constraints = this.constraints;
    /*istanbul ignore next*/
    const findAttrIndex = this.configurationData.data.entity.nslAttributes?.findIndex(
      (attr: any) => attr.name == this.configurationData.data.attr.name
    );
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location') {
      this.attrConfiguration = { locationType: this.attrDetails?.value?.locationType };
      this.configurationData.data.attr.configuration = JSON.stringify(this.attrConfiguration);
    }
    this.configurationData.data.entity.nslAttributes[findAttrIndex] = this.configurationData.data.attr;
    this.changeUnit?.triggerCULayers?.forEach((layer: any, layerIndex: number) => {
      layer?.participatingItems?.forEach((entity: any, entityIndex: number) => {
        entity?.nslAttributes?.forEach((attr: any, attrIndex: number) => {
          /*istanbul ignore else*/ if (attr?.id == this.configurationData?.data?.attr?.id) {
            this.changeUnit.triggerCULayers[layerIndex].participatingItems[entityIndex].nslAttributes[
              attrIndex
            ] = this.configurationData?.data?.attr;
            /*istanbul ignore else*/ if (this.multiDefaultValue)
              attr.defaultValue = JSON.stringify(this.allDefaultValues);
          }
        });
      });
    });
    this.gsiFacade.saveGsi(this.tempGsiObj);
    this.schedulerDetails.reset();
    this.closeAttribute();
  }
  onlyNumberKey(event: any) {
    /* istanbul ignore next */
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode == 45 || (event.charCode > 46 && event.charCode <= 57);
  }
  sliderDefaultValue() {
    /* istanbul ignore next */
    if (this.defaultValue > this.targetValue) {
      this.alertService.showToaster(`Please provide Default value in range of 0 to ${this.targetValue}`, '', 'info');
    } else {
      this.attrDetails?.patchValue({ defaultValue: this.defaultValue });
      this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
      this.attrDetails?.patchValue({ defaultValue: '' });
      this.defaultValue = '';
    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.nodeChangeUnitFacade.openTriggerEdit.next(false);
  }
  // getGsiData() {
  //   this.gsiFacade.uiElementPayloadData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
  //     if (data) {
  //       this.GsiObj = data;
  //     }
  //   });
  // }
  getDCDStream() {
    this.nodeChangeUnitFacade.dcd$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res && res.formulaId && res.cuType === 'membership') {
        this.memberShipAttr = res.formulaId;
        this.memberShipAttrWithId = res.formulaIdWithId;
        // this.updateAttrMemberShip(this.configurationData.data.attr);
      }
      // if (res && res.formulaId && res.cuType === 'conditionalPotentiality') {
      //   this.potentialityAttr = res.formulaId;
      //   this.attributeType = res.attributeType;
      //   /*istanbul ignore next*/
      //   this.currentUielement = res?.attributeuiElement;
      // }
    });
  }
  addMembership(attr: any, index?: any) {
    /*istanbul ignore next*/
    if (!this.memberShipFlagsArr) {
      /*istanbul ignore next*/
      if (attr && attr.membershipInput) {
        attr.membershipInput = '';
        attr.memberShipName = '';
        attr.memberShip = '';
        this.currentOp = '';
      }
    }
  }
  memberShipFlagsChange() {
    this.memberShipAttr = '';
    this.memberShipAttrWithId = '';
    this.currentOp = '';
    this.configurationData.data.attr.memberShipName = '';
    this.configurationData.data.attr.memberShip = '';
  }
  updateAttrMemberShip(attr: any) {
    /*istanbul ignore else*/
    if (attr && !attr.membershipInput) {
      attr.membershipInput = '';
      attr.membershipWithIdInput = '';
    }
    attr.memberShipName = (this.currentOp + this.memberShipAttr + attr.membershipInput).trim();
    attr.memberShip = (this.currentOp + this.memberShipAttrWithId + attr.membershipInput).trim();

    /*istanbul ignore else*/
    if (this.currentOp) {
      this.memberShipAttr = '';
      this.currentOp = '';
      this.configurationData.data.attr.membershipInput = '';
    }
  }
  getLayersData() {
    /*istanbul ignore next*/
    if (this.configurationData) {
      this.selectedChangeUnit = this.configurationData;
      this.physicalLayer = [];
      this.informationLayer = [];
      this.triggerCESLayer = [];
      /*istanbul ignore next*/
      if (this.configurationData?.data?.currentcu?.data?.cuLayers?.length > 0) {
        this.configurationData?.data?.currentcu?.data?.cuLayers?.forEach((layer: any) => {
          /*istanbul ignore next*/
          if (layer?.participatingItems?.length > 0) {
            if (layer?.type == 'information') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /*istanbul ignore next*/
                if (participatingItem && participatingItem?.item?.DATA?.name) {
                  entityIndex = this.informationLayer?.findIndex(
                    (ele: any) => ele?.item?.DATA?.name === participatingItem.item.DATA.name
                  );
                }
                if (entityIndex === -1) {
                  this.informationLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'physical') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                if (participatingItem && participatingItem.name) {
                  entityIndex = this.physicalLayer?.findIndex((ele: any) => ele?.name === participatingItem.name);
                }
                if (entityIndex === -1) {
                  this.physicalLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'triggerCES') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                this.triggerCESLayer.push(participatingItem);
              });
            }
          }
        });
      }
    }
  }
  getTriggerCuLayerData() {
    this.nodeEntityFacadeService.triggerCuAttrUiElement$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((triggerCu: any) => {
        if (triggerCu?.result) {
          this.triggerCuData = triggerCuMapper(triggerCu?.result);
        }
      });
  }
  updateTriggerCuData() {
    this.GsiObj = this.tempGsiObj;
    this.GsiObj?.solutionLogic?.forEach((x: any) => {
      if (x?.referencedChangeUnit == this.triggerCuData?.referencedChangeUnit) {
        x.triggerCULayers = this.triggerCuData?.triggerCULayers;
        // let layer = x.triggerCULayers?.find((layer: any) => layer?.type == this.configurationData?.data?.layerType);
        x.triggerCULayers?.forEach((layer: any) => {
          layer?.participatingItems[this.configurationData?.data?.entityIndex].nslAttributes?.forEach((attr: any) => {
            if (attr.id == this.configurationData.data.attr.id)
              this.configurationData.data.attr.attributeType = attr?.attributeType;
          });
        });
      }
    });
  }
  CompareProperties(upper: string, prop: string) {
    if (prop == 'ATTRIBUTE_CONSTRAINTS') this.betLevel = upper == 'Trigger' ? true : false;
    else if (prop == 'DEFAULT_VALUE') this.betLevelDefault = upper == 'Trigger' ? true : false;
    else this.betLevelMembership = upper == 'Trigger' ? true : false;
    if (!this.betLevel || !this.betLevelDefault || !this.betLevelMembership) {
      this.callAttributeComparisonData(prop);
    }
  }
  prepareComparisondata(prop: string) {
    this.propertyType = JSON.parse(JSON.stringify(prop));
    prop = prop.replace(' ', '_');
    let data = this.configurationData.data;
    // let layer: any=this.triggerCuData.layers.find((lay: any) => lay?.type == data?.layerType);
    let entity = data.entity;
    let attr = data.attr;
    let str = JSON.stringify(data.entityIndex + 1);
    let contextualId = entity?.layerType + '.slot:' + str + '.' + entity?.name + '.' + attr?.name;
    let payload: any = {
      property: [prop],
      attributeName: attr?.name,
      attributeType: attr.attributeType?.uiElement?.uiElement,
      referenceChangeUnitId: this.changeUnit?.referencedChangeUnit,
      triggerCUID: this.changeUnit.id,
      entityID: entity.id,
      entityPath: contextualId,
      gsiId: this.tempGsiObj.id,
      properties: {
        constraints: attr['constraints'],
        defaultValue: this.multiDefaultValue ? JSON.stringify(this.allDefaultValues) : this.allDefaultValues[0],
        memberShipCriteria: attr?.memberShipName,
      },
    };
    return payload;
  }
  callAttributeComparisonData(property: string) {
    this.nodeEntityFacadeService.getAttributeComparisonData(this.prepareComparisondata(property));
  }
  getAttributeComparisonData() {
    this.nodeEntityFacadeService.AttributeComparisonData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        let properties = res.result[0]?.properties;
        if (this.propertyType == 'DEFAULT_VALUE') {
          this.compareDef = true;
          if (this.multiDefaultValue) {
            this.UpperBetProperties.defaultValue = JSON.parse(properties?.TRIGGER_CU_LEVEL_PROPERTIES?.defaultValue);
            let defVal = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.defaultValue;
            if (defVal) this.lowerBetProperties.defaultValue = JSON.parse(defVal);
          } else {
            this.UpperBetProperties.defaultValue[0] = properties?.TRIGGER_CU_LEVEL_PROPERTIES?.defaultValue;
            let defVal = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.defaultValue;
            if (defVal)
              this.lowerBetProperties.defaultValue[0] = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.defaultValue;
          }
          if (this.lowerBetProperties.defaultValue == undefined || this.lowerBetProperties.defaultValue?.length == 0)
            this.lowerBetProperties.defaultValue = this.UpperBetProperties?.defaultValue;
        } else if (this.propertyType == 'ATTRIBUTE_CONSTRAINTS') {
          this.compareVal = true;
          this.UpperBetProperties.constraints = properties?.TRIGGER_CU_LEVEL_PROPERTIES?.constraints;
          let temp = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.constraints;
          this.lowerBetProperties = upperVsLowerBetData(
            temp,
            this.UpperBetProperties,
            this.UiValidatorDropdown,
            this.lowerBetProperties,
            this.iseditAttr
          );
        } else {
          this.compareCriteria = true;
          this.UpperBetProperties.memberShipCriteria = properties?.TRIGGER_CU_LEVEL_PROPERTIES?.memberShipCriteria;
          this.lowerBetProperties.memberShipCriteria = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.memberShipCriteria;
          if (
            this.lowerBetProperties.memberShipCriteria == undefined ||
            this.lowerBetProperties.memberShipCriteria == ''
          )
            this.lowerBetProperties.memberShipCriteria = this.UpperBetProperties?.memberShipCriteria;
        }
      }
    });
  }
  compareValuePopup(prop: string) {
    this.callAttributeComparisonData(prop);
    this.propertyType = prop;
    let membership = [this.configurationData?.data?.attr?.memberShipName];
    this.dialogRef = this.dialog.open(NodeCompareComponent, {
      width: '600px',
      height: '700px',
      data: {
        property: this.propertyType,
        upperBetProperties:
          this.propertyType == 'DEFAULT VALUE'
            ? this.allDefaultValues
            : this.propertyType == 'MEMBERSHIP CRITERIA'
            ? membership
            : this.selectedValidatorsList,
        lowerBetProperties: this.lowerBetProperties,
        status: 'PUBLISHED',
      },
    });
    this.dialogRef?.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.propertyType == 'DEFAULT VALUE') this.allDefaultValues = result?.upperBetProperties;
        else if (this.propertyType == 'ATTRIBUTE CONSTRAINTS') this.selectedValidatorsList = result?.upperBetProperties;
        else this.configurationData.data.attr.memberShipName = result?.memberShipCriteria[0];
      }
    });
  }
}


