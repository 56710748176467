<div class="card-templates">
  <!-- <mat-radio-group name="templateType" [(ngModel)]="templateType" (change)="selectTemaplate($event)"> -->
  <div class="template-wrap">
    <div class="template-blck template" (click)="selectTemaplate('template_3', $event)" *ngIf="isGrammer && editObject">
      <span>
        <img [src]="'../../../../../assets/img/general-entity/attributes.png' | CDNUrlRewritePipe" alt="" />
      </span>
      <p>Customise Card</p>
      <!-- <div class="template"></div> -->
      <!-- <mat-radio-button class="temp-radio" value="template_3">customize Template</mat-radio-button> -->
    </div>
    <div class="template-selection" (click)="selectTemaplate('template_1', $event)">
      <div class="template-blck" [ngClass]="{ 'card-selected': isSelectTemplate && templateType == 'template_1' }">
        <span>
          <img [src]="'../../../../../assets/img/general-entity/attributes.png' | CDNUrlRewritePipe" alt="" />
        </span>
        <p>5 Attributes</p>
        <!-- <div class="template"></div> -->
        <!-- <mat-radio-button class="temp-radio" value="template_1">5 Attributes Template</mat-radio-button> -->
      </div>

      <div class="template-blck" [ngClass]="{ 'card-selected': isSelectTemplate }" (click)="selectTemaplate('template_2', $event)">
        <span>
          <img [src]="'../../../../../assets/img/general-entity/attributes.png' | CDNUrlRewritePipe" alt="" />
        </span>
        <p>7 Attributes</p>
        <!-- <div class="template"></div> -->
        <!-- <mat-radio-button class="temp-radio" value="template_2">7 Attributes Template</mat-radio-button> -->
      </div>
    </div>
  </div>
  <!-- </mat-radio-group> -->
</div>

<div *ngIf="isSelectTemplate" class="template-design-wrap">
  <div *ngIf="templateType !== 'template_3'">
    <mat-label class="fontbold">{{ cardEntityData?.name }}</mat-label>
    <div class="nh-display-flex nh-justify-end">
      <mat-checkbox [(ngModel)]="isChecked" class="temp-title"> Map Attributes</mat-checkbox>
    </div>
    <mat-form-field floatLabel="never" class="w-100">
      <div>
        <mat-label>Select Action Type</mat-label>
        <mat-select
          [(ngModel)]="selectionType"
          (selectionChange)="onSelectionChange($event)"
          name="selectionName"
          id="selectionName"
        >
          <mat-option value="checkbox">
            Check Box
          </mat-option>
          <mat-option value="radiobutton">
            Radio Button
          </mat-option>
          <mat-option value="button">
            Button
          </mat-option>
        </mat-select>
      </div>
    </mat-form-field>
  </div>

  <div *ngIf="selectionType && selectionType != ''" class="action-input-lable">
    <mat-form-field floatLabel="never" class="w-100">
      <mat-label>Action Name</mat-label>
      <input class="w-100" matInput [(ngModel)]="lableName" />
    </mat-form-field>
  </div>
  <div
    *ngIf="templateType == 'template_2' && isChecked"
    class="template-design"
    [ngClass]="isChecked ? 'selected' : 'mat-icon-disable'"
  >
    <div class="zero-attr onhover" [ngClass]="{ active: selcetedPositions.pos_1 }" (click)="slectePosionForAttr('one')">
      <span
        style="cursor: pointer;"
        [ngClass]="{ 'div-disable': !selcetedPositions.pos_1 }"
        mat-icon-button
        [matMenuTriggerFor]="attrMenu"
        (click)="openAttributeList($event)"
        >{{ templateObj.pos_1 }}</span
      >
      <span class="del" *ngIf="selcetedPositions.pos_1" (click)="deleteSelection($event, 'pos_1')">delete</span>
      <span
        style="cursor: pointer;"
        *ngIf="selcetedPositions.pos_1 && templateObj.pos_1 != 'Attribute 1'"
        (click)="unMapAttribute($event, 'pos_1')"
        >Unmap</span
      >
    </div>
    <div class="one-attr onhover" [ngClass]="{ active: selcetedPositions.pos_2 }" (click)="slectePosionForAttr('two')">
      <span
        style="cursor: pointer;"
        [ngClass]="{ 'div-disable': !selcetedPositions.pos_2 }"
        mat-icon-button
        [matMenuTriggerFor]="attrMenu"
        (click)="openAttributeList($event)"
        >{{ templateObj.pos_2 }}</span
      >
      <span class="del" *ngIf="selcetedPositions.pos_2" (click)="deleteSelection($event, 'pos_2')">delete</span>
      <span
        style="cursor: pointer;"
        *ngIf="selcetedPositions.pos_2 && templateObj.pos_2 != 'Attribute 2'"
        (click)="unMapAttribute($event, 'pos_2')"
        >Unmap</span
      >
    </div>
    <div
      class="two-attr onhover"
      [ngClass]="{ active: selcetedPositions.pos_3 }"
      (click)="slectePosionForAttr('three')"
    >
      <span
        style="cursor: pointer;"
        [ngClass]="{ 'div-disable': !selcetedPositions.pos_3 }"
        mat-icon-button
        [matMenuTriggerFor]="attrMenu"
        (click)="openAttributeList($event)"
        >{{ templateObj.pos_3 }}</span
      >
      <span class="del" *ngIf="selcetedPositions.pos_3" (click)="deleteSelection($event, 'pos_3')">delete</span>
      <span
        style="cursor: pointer;"
        *ngIf="selcetedPositions.pos_3 && templateObj.pos_3 != 'Attribute 3'"
        (click)="unMapAttribute($event, 'pos_3')"
        >Unmap</span
      >
    </div>
    <div class="three-attr">
      <div
        class="zero-attr onhover"
        [ngClass]="{ active: selcetedPositions.pos_4 }"
        (click)="slectePosionForAttr('four')"
      >
        <span
          style="cursor: pointer;"
          [ngClass]="{ 'div-disable': !selcetedPositions.pos_4 }"
          mat-icon-button
          [matMenuTriggerFor]="attrMenu"
          (click)="openAttributeList($event)"
          >{{ templateObj.pos_4 }}</span
        >
        <span class="del" *ngIf="selcetedPositions.pos_4" (click)="deleteSelection($event, 'pos_4')">delete</span>
        <span
          style="cursor: pointer;"
          *ngIf="selcetedPositions.pos_4 && templateObj.pos_4 != 'Attribute 4'"
          (click)="unMapAttribute($event, 'pos_4')"
          >Unmap</span
        >
      </div>
      <div
        class="one-attr onhover"
        [ngClass]="{ active: selcetedPositions.pos_5 }"
        (click)="slectePosionForAttr('five')"
      >
        <span
          style="cursor: pointer;"
          [ngClass]="{ 'div-disable': !selcetedPositions.pos_5 }"
          mat-icon-button
          [matMenuTriggerFor]="attrMenu"
          (click)="openAttributeList($event)"
          >{{ templateObj.pos_5 }}</span
        >
        <span class="del" *ngIf="selcetedPositions.pos_5" (click)="deleteSelection($event, 'pos_5')">delete</span>
        <span
          style="cursor: pointer;"
          *ngIf="selcetedPositions.pos_5 && templateObj.pos_5 != 'Attribute 5'"
          (click)="unMapAttribute($event, 'pos_5')"
          >Unmap</span
        >
      </div>
      <div
        class="two-attr onhover"
        [ngClass]="{ active: selcetedPositions.pos_6 }"
        (click)="slectePosionForAttr('six')"
      >
        <span
          style="cursor: pointer;"
          [ngClass]="{ 'div-disable': !selcetedPositions.pos_6 }"
          mat-icon-button
          [matMenuTriggerFor]="attrMenu"
          (click)="openAttributeList($event)"
          >{{ templateObj.pos_6 }}</span
        >
        <span class="del" *ngIf="selcetedPositions.pos_6" (click)="deleteSelection($event, 'pos_6')">delete</span>
        <span
          style="cursor: pointer;"
          *ngIf="selcetedPositions.pos_6 && templateObj.pos_6 != 'Attribute 6'"
          (click)="unMapAttribute($event, 'pos_6')"
          >Unmap</span
        >
      </div>
    </div>
    <div
      class="four-attr onhover"
      [ngClass]="{ active: selcetedPositions.pos_7 }"
      (click)="slectePosionForAttr('seven')"
    >
      <span
        style="cursor: pointer;"
        [ngClass]="{ 'div-disable': !selcetedPositions.pos_7 }"
        mat-icon-button
        [matMenuTriggerFor]="attrMenu"
        (click)="openAttributeList($event)"
        >{{ templateObj.pos_7 }}</span
      >
      <span class="del" *ngIf="selcetedPositions.pos_7" (click)="deleteSelection($event, 'pos_7')">delete</span>
      <span
        style="cursor: pointer;"
        *ngIf="selcetedPositions.pos_7 && templateObj.pos_7 != 'Attribute 7'"
        (click)="unMapAttribute($event, 'pos_7')"
        >Unmap</span
      >
    </div>
  </div>

  <mat-menu #attrMenu="matMenu" classList="sortby-panel">
    <button
      *ngFor="let attr of cardEntityData?.nslAttributes"
      mat-menu-item
      (click)="slectAttribute(attr)"
      [disabled]="attr.isSelected"
    >
      {{ attr.name }}
    </button>
  </mat-menu>

  <div
    *ngIf="templateType == 'template_1' && isChecked"
    class="template-design card-type-view"
    [ngClass]="isChecked ? 'selected' : 'mat-icon-disable'"
  >
    <mat-card class="mat-elevation-z0">
      <div
        class="img-holder onhover default-bg"
        [ngClass]="{ active: selcetedPositions.pos_1 }"
        (click)="slectePosionForAttr('one')"
        id="{{ templateObj.pos_1 }}"
      >
        <span
          style="cursor: pointer;"
          [ngClass]="{ 'div-disable': !selcetedPositions.pos_1 }"
          mat-icon-button
          [matMenuTriggerFor]="attrMenu"
          (click)="openAttributeList($event)"
          >{{ templateObj.pos_1 }}</span
        >
        <span class="del" *ngIf="selcetedPositions.pos_1" (click)="deleteSelection($event, 'pos_1')">delete</span>
        <span
          style="cursor: pointer;"
          *ngIf="selcetedPositions.pos_1 && templateObj.pos_1 != 'Attribute 1'"
          (click)="unMapAttribute($event, 'pos_1')"
          >Unmap</span
        >
      </div>
      <mat-card-content>
        <div
          class="card-content onhover default-bg"
          [ngClass]="{ active: selcetedPositions.pos_2 }"
          (click)="slectePosionForAttr('two')"
          id="{{ templateObj.pos_2 }}"
        >
          <span
            style="cursor: pointer;"
            [ngClass]="{ 'div-disable': !selcetedPositions.pos_2 }"
            mat-icon-button
            [matMenuTriggerFor]="attrMenu"
            (click)="openAttributeList($event)"
            >{{ templateObj.pos_2 }}</span
          >
          <span class="del" *ngIf="selcetedPositions.pos_2" (click)="deleteSelection($event, 'pos_2')">delete</span>
          <span
            style="cursor: pointer;"
            *ngIf="selcetedPositions.pos_2 && templateObj.pos_2 != 'Attribute 2'"
            (click)="unMapAttribute($event, 'pos_2')"
            >Unmap</span
          >
          <!-- <p>25-Hydroxy Vitamin D(Calcifidiol)/Vitamin D -Serum</p> -->
        </div>
        <div class="rating-wrap">
          <div class="stars-holder">
            <div
              class="stars onhover default-bg brdr-radius"
              [ngClass]="{ active: selcetedPositions.pos_3 }"
              (click)="slectePosionForAttr('three')"
            >
              <div class="spandv" id="{{ templateObj.pos_3 }}">
                <span
                  style="cursor: pointer;"
                  [ngClass]="{ 'div-disable': !selcetedPositions.pos_3 }"
                  mat-icon-button
                  [matMenuTriggerFor]="attrMenu"
                  (click)="openAttributeList($event)"
                  >{{ templateObj.pos_3 }}</span
                >
                <span class="del" *ngIf="selcetedPositions.pos_3" (click)="deleteSelection($event, 'pos_3')"
                  >delete</span
                >
                <span
                  style="cursor: pointer;"
                  *ngIf="selcetedPositions.pos_3 && templateObj.pos_3 != 'Attribute 3'"
                  (click)="unMapAttribute($event, 'pos_3')"
                  >Unmap</span
                >
              </div>
            </div>
            <div class="rating-holder">
              <div
                class="rating onhover default-bg brdr-radius"
                [ngClass]="{ active: selcetedPositions.pos_4 }"
                (click)="slectePosionForAttr('four')"
              >
                <div class="spandv" id="{{ templateObj.pos_4 }}">
                  <span
                    style="cursor: pointer;"
                    [ngClass]="{ 'div-disable': !selcetedPositions.pos_4 }"
                    mat-icon-button
                    [matMenuTriggerFor]="attrMenu"
                    (click)="openAttributeList($event)"
                    >{{ templateObj.pos_4 }}</span
                  >
                  <span class="del" *ngIf="selcetedPositions.pos_4" (click)="deleteSelection($event, 'pos_4')"
                    >delete</span
                  >
                  <span
                    style="cursor: pointer;"
                    *ngIf="selcetedPositions.pos_4 && templateObj.pos_4 != 'Attribute 4'"
                    (click)="unMapAttribute($event, 'pos_4')"
                    >Unmap</span
                  >
                </div>
                <!-- 4.1 -->
              </div>
            </div>
            <div class="price-holder">
              <div
                class="price onhover default-bg brdr-radius"
                [ngClass]="{ active: selcetedPositions.pos_5 }"
                (click)="slectePosionForAttr('five')"
              >
                <div class="spandv" id="{{ templateObj.pos_5 }}">
                  <span
                    style="cursor: pointer;"
                    [ngClass]="{ 'div-disable': !selcetedPositions.pos_5 }"
                    mat-icon-button
                    [matMenuTriggerFor]="attrMenu"
                    (click)="openAttributeList($event)"
                    >{{ templateObj.pos_5 }}</span
                  >
                  <span class="del" *ngIf="selcetedPositions.pos_5" (click)="deleteSelection($event, 'pos_5')"
                    >delete</span
                  >
                  <span
                    style="cursor: pointer;"
                    *ngIf="selcetedPositions.pos_5 && templateObj.pos_5 != 'Attribute 5'"
                    (click)="unMapAttribute($event, 'pos_5')"
                    >Unmap</span
                  >
                </div>
                <!-- &#8377; 850 -->
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="btns-wrap" *ngIf="templateType !== 'template_3'">
  <button mat-button type="button" (click)="saveCardViewDetails()">Done</button>
  <button mat-button type="button" (click)="cancelCardViewDetails()">Cancel</button>
</div>
