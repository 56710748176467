import { solutionLogicMapper } from '../solution-logic/solutionLogic-mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function dldGSIMapper(data: any) {
  var map = {
    /*
        node json mapper default item
        used to map one key to another
      */
    item: {
      agents: 'agents',
      onotology: 'ontology',
      Industry: 'Industry',
      solutionLogic: 'solutionLogic',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      dcd: 'dcd',
      cuType: 'cuType',
      name: 'name',
      displayName: 'displayName',
      description: 'description',
      dldId: 'dldId',
      layers: 'layers',
      status: 'status',
      version: 'version',
      source: 'source',
    },
    operate: [
      {
        run: function (val: any[]) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return solutionLogicMapper({ ...x, isDld: true });
          });
        },
        on: 'solutionLogic',
      },
    ],
  };
  const result = transform(data, map);

  return result;
}
