import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractionpipe',
})
export class ExtractionPipe implements PipeTransform {
  /* istanbul ignore next */
  transform(item?: any) {
    /* istanbul ignore next */
    if (item) {
      if (item?.split(' ')[1]?.length == 17) {
        return item?.split(' ')[0];
      } else {
        return item;
      }
    } else {
      return item;
    }
  }
}
