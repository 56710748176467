import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationExtras, Router } from '@angular/router';
import { AlertService, Attribute, CART_BUTTON, DraggableAttribute, GsiButton, isSearchAttributeVal, KanbanConfiguration, RuleSetCondition, TranslatorService } from '@common-services';
import { templateExpandMap } from '@common-services';
import { LoaderService } from '@common-services';
import { RolesFacadeService } from '@common-services';
import { EntityEndpointService } from '@common-services';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityBoardEndpointService } from '@common-services';
import { EntityBoardFacadeService } from '@common-services';
import { Condition } from '@common-services';
import { RuleSet, IStyle } from '@common-services';
import { TemplateTypeDialogComponent } from '../template-type-dialog/template-type-dialog.component';
import {
  componentExpandTemplateMapper,
  componentTemplateMapper,
  expandTemplates,
  IFonts,
  Templates,
} from '../constatnts/customize-templates.constants';
import {
  CHIP_SELECTION_SINGLE,
  CHIP_SELECTION_RANGE,
  SLIDER_SINGLE,
  SLIDER_RANGE,
  CHECK_BOX_SINGLE,
  CHECK_BOX_RANGE,
  LOCATE,
  DATES,
  DATE_AND_COST,
  TOGGLE_BUTTONS,
  RADIO_BUTTON,
  RATINGS,
  DATE_SINGLE,
  DATE_RANGE,
  Filter,
  IndividualSort,
  Search,
  LocationFilter,
  FilterDesignTemplate,
  Combination,
  attributeDetails,
  SearchFieldDetails,
  FilterConfiguration,
  SearchFieldsConfiguration,
  SortConfiguration,
  SearchConfiguration,
  UrlBindingData,
  Sort,
  entityFlipSet,
  advancedFeatures,
} from '../constants/customize-filter-templates.constants';
import { cartStyles } from '@common-services';
import { TabType, IAttributeStyle, ICardStyle } from '@common-services';
import { CommonDcdComponent } from '../common-dcd/common-dcd.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ColumnStyle } from '@common-services';

@Component({
  template: '',
})
export class DropBase implements OnInit {
  @Input() nslAttributes: any = [];
  @Input() nslAttributesExpand: any = [];
  @Output() bookSearchEvent = new EventEmitter();
  ngUnsubscribe = new Subject();
  selectedBookDetails: any;
  isSlectedRole: boolean = true;
  isSlectedEntity: boolean = true;
  isSlectedJoEntity: boolean = false;
  selectedRole: any;
  selectedRoleId: any;
  selectedEntity: any;
  selectGsiList: any = [];
  draggedGSI: GsiButton;

  //------------------------------------Template Related Variables Start --------------------------------
  templateType: string | number;
  childComponentName: any;
  expandComponentName: any;
  //------------------------------------Template Related Variables End --------------------------------
  //-----------------------Variable Related to Attribute Mapping start---------------------------------------
  draggableAttributes: DraggableAttribute[];
  draggedAttribute: DraggableAttribute;

  //-----------------------Variable Related to Attribute Mapping End-----------------------------------------
  operators: string[] = ['==', '>', '<'];

  hiddenConditions: Condition[] = [];
  @Input() hiddenRuleSet: RuleSetCondition;

  boxShadowData: string;
  boxShadowTemplate: any[];
  boxShadowToggle: boolean;
  borderRadiusToggle: boolean;
  borderSize: number = 0;
  borderRadius: number = 0;
  borderColorPickerToggler: boolean;
  selectedBorderColor: string = '';
  borderColorModel: string = '#1973c0';
  expandBoxShadow: any = '';
  attributeValue: string = '';

  // ------------------Child GSI variables --------------------------------
  childGsiList: GsiButton[] = [];
  hyperlinkCtaMap = new Map();
  expandGsiList: GsiButton[] = [];
  childGsiMapping: { [key: number]: GsiButton } = {};
  kanbanGsiConfiguration: { [key: number]: GsiButton } = {};
  childAttributePositionMap: { [key: number]: DraggableAttribute } = {};
  //----------------  Child GSI variables End --------

  //----------------Variable related Expand ----

  //----------------Variable related Expand End --------------------------------
  // gsiActList:

  // payloadData: EntityData;

  selectGsiListExpand: any = [];
  selectedEntityData: any;
  selectedSortEntityData: any;
  showEntitySection: boolean = false;
  showRolesSection: boolean = true;
  showGsiSection: boolean = false;
  title: any = 'Configure Card';
  dropActionBtsExpand: any;
  _fontIndex: number = -1;
  fontSize: number = 16;
  isBold: boolean = false;
  isItalic: boolean = false;
  isDesign: boolean = false;
  gsiActionButton: any = [{ name: 'Action Button' }];
  colorModel: string = '#1973c0';
  selectedFont: IFonts;
  selectedColor: string = '#1973c0';
  totalEntities: any;
  entpageNumber: any = 0;
  entpageSize: any = 20;
  allEntities: any = [];
  allgsi: any = [];
  searchedEntities: any = '';
  searchegsi: any = '';
  @Input() attributes: any[] = [];
  totalGsi: any;
  gsipageSize: any;
  gsipageNumber: any;
  changeObj: any;
  isSlectedGsi: boolean = true;
  isMultiAttribute: boolean = false;
  selectedGsi: any = [];
  selectedGsiData: any;
  showMapSolution: boolean = false;
  @Input() isCardConfig: boolean = true;
  tabSelected: TabType;
  @Input() isAdvancedFeature: boolean = false;
  isDesignTab: boolean = false;
  sort: any;
  selectedDirectiveId: string = '';
  colorPickerToggler: boolean = false;
  search: any;
  filter: any;
  // templateType: any;
  expandTemplateType: any;
  expandType: any;
  isSelectTemplate: boolean = false;
  @ViewChild('dropEntityData') dropEntityData: ElementRef;
  @ViewChild('gsiAction') gsiAction: any;
  dropAttributes: any = [];
  nestedRowDropAttributes: any = [];
  dropAttributesIndexes: any = [];
  dropActionBts: any = [];
  dropMappedAttr: boolean = false;
  cardColor: string = '';
  btnColors: string[] = [];
  droppedColors: any = [];
  droppedStyles: any = [];
  dropMappedActionBts: any;
  templateAttrCount: any;
  entityAttrCount: any;
  attributeTypes: any = [];
  nestedRowUiTypes: any = [];
  expandAttributeTypes: any = [];
  dragAttrType: string;
  sortOption: any = '1';
  currentGsi: number;
  isnext: boolean = false;
  dropEntityExpandData: any = {};
  dropEntityExpandDraggableAttributesData: any = {};
  @Input() isGrammer: any = false;
  @Output() cardConfigEvent = new EventEmitter();
  selectedSearchEntityData: any;
  selectedSearchFieldEntityData: any;
  searchFields: boolean = false;
  searchAttr: any = [];
  prevTemplate: any;
  temporaryTemplate: any;
  temporaryMultiAttribute: boolean = false;
  isMultiAttributeExpand: any = false;
  tenantName: string;
  applySameToExpand: boolean = false;
  rangeAttr: any = [];
  searchFieldAttr: any = [];
  selectedRangeEntityData: any;

  range: any;
  numberOfRows: number;
  tempNumberOfRows: number;
  addConditionVariable: boolean = false;
  addConditionVariableOperator: boolean = false;
  colorConditionSetter: any;
  conditionColor: any = '#fff';
  conditionAttribute: any = [];
  selectedConditonValue: any = '';
  selectedCondition: any;
  conditionArray: any = [];
  conditionArrayExpand: any = [];
  dropExpandColors: any = [];
  chatbotData: any = {
    isSelected: false,
    entity_name: '',
    entity_id: '',
    language: '',
    intents: new Array(),
  };
  languagesOptions: any = [];
  locationFilter: boolean = false;
  urlBinding: boolean = false;
  urlBindingGsi: any;
  urlBindingAttribute: any;
  locationFilterData: any;
  selectedLocationFilterData: any;
  locationFilterAttr: any = [];
  groupFilterData: any[] = [];
  groupFilterDataBy: any = {};
  editBoolean: boolean = true;
  conditionAttributeExpand: any = [];
  expandBackground: any = '';
  expandBtnColors: any = [];
  referenceEntitiesSearchFields: any = [];
  searchTextRefEntity: any = [];
  refEntTotalPages: any = [];
  refEntPageNumber: any = [];
  refEntTotalRecords: any = [];
  @ViewChildren('select') select: any;
  targetRefAttr: any = [];
  constrefEntTotalRecords: any;
  constrefEntTotalPages: any;
  constreferenceEntitiesSearchFields: any;
  dynamicTemplates: any = [];
  renderDynamiTemplate: boolean = false;
  selectedDynamicTemplate: any;
  customChipSelectionSingle = CHIP_SELECTION_SINGLE;
  customChipSelectionRange = CHIP_SELECTION_RANGE;
  customizeFilterTemplateSliderSingle = SLIDER_SINGLE;
  customizeFilterTemplateSliderRange = SLIDER_RANGE;
  customCheckBoxSingle = CHECK_BOX_SINGLE;
  customCheckBoxRange = CHECK_BOX_RANGE;
  customizeLocation = LOCATE;
  customizeDate = DATES;
  customizeDateAndCost = DATE_AND_COST;
  customizeToggleButtons = TOGGLE_BUTTONS;
  customizeRadioButton = RADIO_BUTTON;
  customizeRatings = RATINGS;
  customizeDateSingle = DATE_SINGLE;
  customizeDateRange = DATE_RANGE;
  // -----temporary variables---
  rangeValues: number[] = [20, 80];
  value: number;
  checked: boolean = false;
  ingredient: string = '';
  //---temporary variables end ---

  sortCreateFlag: boolean = false;
  sortCreateCombinationFlag: boolean = false;
  sortCreateIndividualFlag: boolean = false;
  nslSelectedCombiAttributes: any;
  combinationArray: any[] = [];
  temp: any;
  filterDefaultValue: any;
  searchFieldsDefaultValue: any;
  sortDefaultOption: any;
  sortDefaultAttr: any;
  individualSelectedAttr: any[] = [];
  sortDefaultValue: any;
  divisions: number;
  labels: any;
  advanceTableFeatures: {
    multipleSelection: boolean;
    columnHighlights: boolean;
    attributeWrap: boolean;
    serialNumbers: boolean;
  } = {
    multipleSelection: false,
    columnHighlights: false,
    attributeWrap: false,
    serialNumbers: false,
  };
  //sortCombinationSelect: boolean = false;

  alignmentOptions = [
    {
      class: 'nh-icon nh-icon-left-align',
      justify: 'left',
      viewClass: 'flex-start',
    },
    {
      class: 'nh-icon nh-icon-center-align',
      justify: 'center',
      viewClass: 'center',
    },
    {
      class: 'nh-icon nh-icon-right-align',
      justify: 'right',
      viewClass: 'flex-end',
    },
  ];
  alignment: any = {
    class: 'nh-icon nh-icon-left-align',
    justify: 'left',
    viewClass: 'flex-start',
  };
  fonts: IFonts[] = [
    {
      name: 'Times new Roman',
      font: "'Times New Roman', Times, serif",
    },
    { name: 'Couries new', font: "'Courier New', Courier, monospace" },
    {
      name: 'Roboto',
      font:
        "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
    { name: 'Arial', font: 'Arial, Helvetica, sans-serif' },
    { name: 'Verdana', font: 'Verdana, Geneva, Tahoma, sans-serif' },
    { name: 'Tahoma', font: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" },
    {
      name: 'Trebuchet MS ',
      font:
        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
    },
    {
      name: 'Impact',
      font: "Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    },
  ];
  entity: any;
  /// <summary> variables related to expand start
  droppedStylesExpand: any = [];

  isExpand: boolean = false;
  @ViewChild('expandDialog') expandDialog: TemplateRef<HTMLElement>;
  @ViewChild('cardNameDialog') cardNameDialog: TemplateRef<HTMLElement>;
  expandTemplates: Templates[];
  templates: Templates[];

  ///</summary>
  // * variables related to Cart --------------------------------
  cartButtonName: string = 'Add to Cart';
  showButtonNameEdit: boolean = false;
  cartEnabled: boolean = false;
  CART_BUTTON: GsiButton = {
    displayName: 'Add to Cart',
    name: 'Add to Cart',
    id: '-1',
    entityType: 'CART',
    isSelected: {
      child: {
        isSelected: false,
        position: -1,
      },
      expand: {
        isSelected: false,
        position: -1,
      },
    },
    masterId: '-1',
    status: 'cart',
    version: '0',
  };
  cartAttributes: DraggableAttribute[] = [];

  cartAccordionExpanded: boolean = false;
  cartView: any = {};
  advancedFilterOpenLabel: string = 'Sort';
  attributeOpen: string;
  attrDetails: any;
  attributeSelect: any;
  editBoxCheck: any;
  searchAttributeSelect: any;
  searchFieldsIndex: number = -1;
  canvasDesignPage: boolean = false;
  horizontalLayout: any;
  verticalLayout: any;
  layoutTemplateType: number;
  layoutStyle: any;
  searchFieldsData: any;
  userData: any;
  showUserData: boolean;
  dropMappedAttributes: any;
  dropMappedDragabbleAttributes: any;
  cartTemplateType: any;
  cartConfig: any;
  cartPricePosition: number;
  cartAttributeDropped: boolean = false;
  attributeDirectiveId: string;
  @ViewChildren('cartAttributeStyles') cartAttributeStyles: QueryList<
    ElementRef
  >;
  cartAttrStyleArray: any;
  cartAttributeStylesDuplicate: any;
  cartAttributesPositionMap: { [key: number]: DraggableAttribute } = {};
  cartViewConfig: any;
  cartAttrStyleArr: any = [];
  //!END of Cart Related Variables --------------------------------

  /* !table Parameters Start*/
  numberOfTableColumns: any;
  tableData: any = [];
  nestedTableData: any = [];
  nestedRowTableData: any = [];
  verticalTableData: any = [];
  numberOfTableRows: any = 8;
  @ViewChild('dropTableData') dropTableData: any;
  @ViewChild('dropTableActionData') dropTableActionData: any;
  @ViewChild('alertDialog') alertDialog: TemplateRef<HTMLElement>;
  headerBackground: string = '#f8f9fa';
  nestedRowHeaderBackground: string = '#f8f9fa';
  headerBackgroundSelected: boolean = false;
  nestedRowHeaderBackgroundSelected: boolean = false;
  evenRowColor: string = '#fcfcfc';
  evenRowSelected: boolean = false;
  oddRowColor: string = '#ffffff';
  oddRowSelected: boolean = false;
  widthOfTableArray: any;
  tableWidth: string;
  urlGsiList: any;
  dropAdvancedFeature: number = 0;
  advancedFeatures: any = {};
  // multi entity variables
  entityList: any = [];
  multiEntityDraggableAttributes: any = [];

  backGroundColor: string = '';
  gsiData: any = [];
  isExpandPopup: boolean = false;
  enableAnalytics: boolean = false;

  // --------------------vertical table attributes --------------------
  @Input() isVerticalTable: any = false;
  currentDragAttr: any;
  parentAttrSoureValues: any[];
  prevDragType: any;
  isVericalListRundown: boolean = false;
  mappedAttributesIdx: number[] = [];
  nestedmappedAttributesIdx: number[] = [];
  showLabels: boolean = false;
  verticalTableConfig: any;
  actionBtnSelection: boolean[] = [];

  @Output() emitVerticalTableInfo = new EventEmitter();
  nslSourceAttributesMap: Map<string, number> = new Map<string, number>();
  @Input() entityData: any = {};
  listRunDownAttributeSet: Set<string> = new Set<string>([
    'checkbox',
    'radioButton',
    'chipsingleselection',
    'chipmultipleselection',
  ]);
  allowExpand: boolean = false;
  hideDeleteIcon: boolean = false;
  @Input() isTable: boolean = false;
  hoverBackgroundPickerToggler: boolean = false;
  cardHoverColor: string = '1973c0';
  dcdList: any[] = [];
  //hoverColors: any = [];
  style: IStyle = {
    changedElementStyle: 'selectedColor',
    changedElementValue: '',
  };
  hoverColor: string = '#1973c0';
  styleAttribute: IAttributeStyle[] = new Array<IAttributeStyle>();
  expandStyleAttribute: IAttributeStyle[] = new Array<IAttributeStyle>();
  entityCardFlip: boolean = false;
  entityFlipSet = entityFlipSet;
  styleGsi: ICardStyle[] = new Array<ICardStyle>();
  expandStyleGsi: ICardStyle[] = new Array<ICardStyle>();
  styleCard: ICardStyle = {
    color: '',
    hoverColor: '',
  };
  expandCardStyle: ICardStyle = {
    color: '',
    hoverColor: '',
  };
  hoverColorsExpand: any = [];
  expandHoverBackground: any;
  hoverColors: any = [];
  childConfig: any;
  filterDesign: any;
  expandConfig: any;
  isEdit: boolean = false;
  legendSelected: boolean = false;

  conditioncColorToggler: boolean = false;
  isDynamicTeplates: boolean = false;

  /*table Parameters End*/
  @Input() isDeleteButton = false;
  /*table gsi configuration variables*/
  cuEventList: any = [];
  gsiButtons: any = [];
  containsOnSelectEvent: boolean = false;
  selectedEditCardID: string = '';
  cardConfigName: string = '';
  childMaxPosition: number = 0;
  expandMaxPosition: number = 0;
  viewNamesList: any[];
  expandKeysArray: any[] = Object.keys(this.dropEntityExpandData);
  cardGsiMap: any = {
    mapped: false,
    value: '',
    index: -1,
  };
  gsiAttrMap = {};
  hyperlinkMap = new Map();
  expandHyperlinkMap = new Map();
  gsiAttrMapExpand: any = {};
  hyperlinkCtaDropArray: any = [];
  buttonDroppedInAttribute: any = {};
  hyperLinkAttributes: any = [];
  expandHyperLinkAttributes: any = [];
  hyperlinkArray: any = [];
  expandHyperlinkArray: any = [];
  apiVersion: number = 1;
  cartDcdList: any = [];
  cartGsiList: any = [];
  btnHoverColors: any = [];
  expandBtnHoverColors: any = [];
  headerGsiMap: Map<string, any> = new Map<string, any>();
  urlBindingAttributeColor: string;
  conditionAttributes: any;
  enableLegend: boolean = false;

  /* Kanban Variables */
  isKanbanBoard: boolean = false;
  singleSelection: any = [
    'radioButton',
    'dropdown',
    'date',
    'datetime',
    'chipsingleselection',
    'clickable',
    'dateRange',
    'time',
    'year',
  ];
  showKanbanCardConfiguration: boolean = false;
  kanbanStatus: any[];
  constraintsForm: FormGroup;
  dataMap = {
    string: 'text',
    number: 'number',
  };
  selectedConstraintProperties: any;
  allEntityValidations: any;
  validatorsList: any;
  selectedAttribute: any;
  selectedConstraint: any;
  kanbanConstraintsPayload: any;
  constraintsArray:any = [];
  constraintId: any;

  isDynamicExpandCardSelected: boolean = false;
  @ViewChild('dropDynamicEntityData') dropDynamicEntityData: ElementRef;
  selectedExpandDynamicTemplate: any;
  expandDynamicCard: boolean = false ;
  isAttributeLevelCustomizationActive: boolean = false;
  selectedColumnAttribute = -1;
  selectedColumn = -1;
  columnStyles: ColumnStyle[] = [];
  attributeList: string[] = [];
  COLUMN_STYLES: ColumnStyle = {
    sizes: [12],
    colors: ['#000000'],
    fontSizes: [18],
    Weight: ['normal'],
    fontFamily: [this.fonts[0]],
    displayName: 'Attribute',
    attributes: '',
    attributeList: this.attributeList,
    fontStyles: ['normal'],
    seperators: [''],
    isChip: [false],
  };
  selectedDisplayName: number = -1;
  isEntityMccTable: boolean = false;
  gsiBtnSelected: boolean[] = [];
  isUrlbindingConfigName: boolean = false;
  constructor(
    protected router: Router,
    protected rolesFacadeService: RolesFacadeService,
    protected alertService: AlertService,
    protected entityBoardService: EntityBoardEndpointService,
    public dialog: MatDialog,
    protected loader: LoaderService,
    protected el: ElementRef,
    protected entityEndpointService: EntityEndpointService,
    protected entityBoardFacade: EntityBoardFacadeService,
    protected translator: TranslatorService,
    protected resolver: ComponentFactoryResolver
  ) {
    this.detectRecieveEntityExpandData();
    this.detectLanguageChange();
    this.expandTemplates = expandTemplates;
  }

  /**
   * The function prepares a list of GsiButton objects by iterating through a given list of objects and
   * adding default values for position and isSelected properties.
   * @param {any[]} gsiList - gsiList obtained from API or selected from it
   * @returns The function `prepareGsiList` is returning an array of `GsiButton` objects.
   *
   */

  getEntityCardEditConfiguration() {
    let config = this.entityBoardFacade.cardConfiguration;
    if (config) {
      if (config?.configurationName) {
        this.cardConfigName = config?.configurationName;
      }
      this.selectedEditCardID = config?.id;
      this.childConfig = config?.childView;
      this.expandConfig = config?.expandedView;
      this.cartViewConfig = config?.cartView;
      this.filterDesign = config?.filterDesign;
      this.childComponentName =
        componentTemplateMapper[config?.childView?.templateType];
      this.templateType = config?.childView?.templateType;
      if (this.templateType == 'default') {
        this.isSelectTemplate = false;
      } else {
        this.isSelectTemplate = true;
      }
      if(config?.childView?.style?.cardBorderRadius && config?.childView?.style?.cardBorderSize && config?.childView?.style?.cardBorderColor){
        this.borderSizeToggleChange();
      }
      if (config?.expandedView?.style?.templateType) {
        this.isExpand = true;
        this.expandTemplateType = config?.expandedView?.style?.templateType;
        this.expandComponentName =
          componentExpandTemplateMapper[
            config?.expandedView?.style?.templateType
          ];
      }
      this.setEditKanbanConfiguration(config?.childView?.style?.kanbanConfiguration)
      this.setGsiList(config?.gsiList);
      this.setCondition(this.childConfig);
      if(config?.gsiList?.length > 0){
        config.gsiList.forEach((attr: any)=>{
          attr?.dcds?.forEach((dcd:any) => { this.dcdList.push(dcd);});
          this.selectGsiList = config.gsiList;
        });
      }
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  }

  setEditKanbanConfiguration(kanbanConfiguration: KanbanConfiguration) {
    this.kanbanStatus = kanbanConfiguration?.kanbanStatus;
    this.kanbanGsiConfiguration = kanbanConfiguration?.kanbanGsis;
    kanbanConfiguration?.statusAttribute?.forEach((statusAttr: DraggableAttribute) => {
      const ind = this.draggableAttributes.findIndex((attr: DraggableAttribute) => attr.name === statusAttr.name);
      if(ind!=-1) {
        this.draggableAttributes[ind] = statusAttr;
      }
    })
  }
//conditions
  setCondition(arr: any) {
    if (arr?.style?.conditionArray?.length > 0)
      this.conditionArray = arr?.style?.conditionArray;
    if (this.expandConfig?.style?.conditionArrayExpand?.length > 0)
      this.conditionArrayExpand = this.expandConfig?.style?.conditionArrayExpand;
  }
  setGsiList(arr: any) {
    if (arr) {
      arr.forEach((editGsi: any) => {
        let index = this.childGsiList.findIndex(
          (gsi: any) => gsi.name == editGsi.name
        );
        if (index == -1) {
          if (editGsi?.id != -1) {
            this.selectedGsi.push(editGsi?.name);
            this.childGsiList.push({
              id: editGsi.id,
              masterId: editGsi.masterId,
              name: editGsi.name,
              version: editGsi.version,
              displayName: editGsi.displayName,
              position: -1,
              entityType: 'GSI',
              isSelected: false,
            });
            this.expandGsiList.push({
              id: editGsi.id,
              masterId: editGsi.masterId,
              name: editGsi.name,
              version: editGsi.version,
              displayName: editGsi.displayName,
              position: -1,
              entityType: 'GSI',
              isSelected: false,
            });
          }
        }
      });
    }
  }

  prepareGsiList(gsiList: any[]): GsiButton[] {
    let gsiButtonList: GsiButton[] = [];
    gsiList.forEach((gsi: any) => {
      let gsiButton: GsiButton = {
        ...gsi,
        position: -1,
        entityType: 'GSI',
        isSelected: false,
      };
      gsiButtonList.push(gsiButton);
    });
    return [...gsiButtonList];
  }

  appendViewList(gsiButtonList: GsiButton[]): GsiButton[] {
    let viewList: GsiButton[] = [];
    this.viewNamesList.forEach((view: any) => {
      let gsiButton: GsiButton = {
        name: view.viewName,
        displayName: view.viewName,
        id: '0',
        masterId: '0',
        status: '1.0',
        version: '1.0',
        position: -1,
        entityType: 'PAGE',
        isSelected: false,
      };
      viewList.push(gsiButton);
    });
    return [...gsiButtonList, ...viewList];
  }

  getTableAttributes(droppedAttr?: any) {
    this.numberOfTableColumns = this.nslAttributes.length;
    let tableColumns = [];
    let nestedTableColumns = [];
    const width = 186 * this.numberOfTableColumns;
    this.tableWidth = width.toString() + 'px';
    for (let i = 0; i < this.numberOfTableColumns; i++) {
      tableColumns.push({ attributeHeading: 'Attribute', attributeValue: '' });
      this.columnStyles.push({ ...JSON.parse(JSON.stringify(this.COLUMN_STYLES)), displayName: 'Attribute' });

      nestedTableColumns.push({
        attributeHeading: 'Attribute',
        attributeValue: '',
      });
    }
    this.columnStyles = this.entityBoardFacade.cardConfiguration?.childView?.style?.columnStyles ?this.entityBoardFacade.cardConfiguration?.childView?.style?.columnStyles : this.columnStyles;
    for (let i = 0; i < this.numberOfTableRows; i++) {
      this.tableData.push(tableColumns);
      this.nestedTableData.push(nestedTableColumns);
    }
  }
  getVerticalTableAttributes(sourceValues?: any, attributeName?: string) {
    this.numberOfTableColumns = sourceValues?.length
      ? sourceValues.length + 1
      : 4;
    let tableColumns = [];
    // const width = 175 * this.numberOfTableColumns;
    // this.tableWidth = width.toString() + 'px';
    for (let i = 0; i < this.numberOfTableColumns; i++) {
      if (i == 0)
        tableColumns.push({
          attributeHeading: attributeName ? attributeName : 'AttributeName',
          attributeValue: 'Entity Values',
        });
      else {
        tableColumns.push({
          attributeHeading: 'Value' + i,
          attributeValue: sourceValues
            ? sourceValues?.[i - 1]?.DATA?.actualValue
            : '',
        });
      }
    }
    if (sourceValues) {
      if (this.verticalTableData?.[0]?.[1]?.attributeValue === '') {
        this.verticalTableData[0].splice(
          1,
          this.verticalTableData[0].length - 1
        );
        for (let i = 1; i < this.numberOfTableColumns; i++) {
          this.verticalTableData[0].push({
            attributeHeading: 'Value' + i,
            attributeValue: sourceValues
              ? sourceValues?.[i - 1]?.DATA?.actualValue
              : '',
          });
        }
        this.verticalTableData[1] = tableColumns;
      } else {
        this.verticalTableData.push(tableColumns);
      }
    } else {
      this.verticalTableData.push(tableColumns);
    }
  }
  ngOnInit(): void {
    if (this.router.url.includes('kanban')) {
      this.isKanbanBoard = true;
      this.templateType = '332';
      this.isSelectTemplate = true;
      this.childComponentName = 'AttributeTemplatesComponent';
    }
    this.tabSelected = 'child';
    if (!this.isGrammer) {
      this.loadWithData();
    } else {
      if (this.nslAttributes.length > 0) {
        this.draggableAttributes = this.prepareDraggableAttributes(
          this.nslAttributes
        );
      }
    }
    this.tenantName = localStorage.getItem('TenantName');
    this.templates = Templates;
    this.getCartConfiguration();
    this.getEntityValidations();
  }
  //   onlyDigits(event: { charCode: any; }) {
  //     let code = event.charCode;
  //     return (code >= 48 && code <= 57);
  //  }

  /**
   * The function loads data from a JSON object and assigns values to various variables.
   */
  loadWithData() {
    if (Object?.keys(this.entityBoardFacade.entity)?.length > 0) {
      this.entity = JSON.parse(JSON.stringify(this.entityBoardFacade.entity));
      let selectGsiList = JSON.parse(
        JSON.stringify(this.entityBoardFacade.gsiList)
      );
      this.selectGsiList = JSON.parse(JSON.stringify(selectGsiList));
      this.childGsiList = this.prepareGsiList(selectGsiList);
      this.selectGsiListExpand = JSON.parse(
        JSON.stringify(this.entityBoardFacade.gsiList)
      );
      this.expandGsiList = this.prepareGsiList(selectGsiList);
      this.selectedEntity = this.entity.name;
      this.selectedRole = this.entityBoardFacade.role.name;
      this.selectedRoleId = this.entityBoardFacade.role.id;
      this.detectAllViewNames();
      this.getEntityDetails(this.entity);
    } else {
      this.router.navigate(['/entityBoard']);
    }
  }
  getMultiEntityDetails() {
    const obsArray: any[] = [];
    this.entityList.forEach((entity: any) => {
      obsArray.push(
        this.entityEndpointService.loadAttributesByEntId(
          entity.id,
          false,
          false
        )
      );
    });
    const obs = forkJoin(obsArray);
    obs.subscribe((res: any) => {
      res.forEach((data: any, index: number) => {
        this.multiEntityDraggableAttributes[
          index
        ] = this.prepareDraggableAttributes(data.result.nslAttributes);
      });
    });
  }

  detectAllViewNames() {
    this.entityBoardService
      .getAllViewsByEntityName(this.selectedEntity)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res.result && res.status === 200) {
          this.viewNamesList = res.result ? res.result : [];
          this.selectGsiList = this.appendViewList(this.selectGsiList);
          this.childGsiList = this.appendViewList(this.childGsiList);
          this.expandGsiList = this.appendViewList(this.expandGsiList);
          this.selectGsiList.forEach((gsi: any) => {
            this.selectedGsi.push(gsi.name);
            this.gsiBtnSelected.push(false);
          });
        }
      });
  }
  getEntityDetails(entity: any) {
    /*istanbul ignore next*/
    this.entityEndpointService
      .loadAttributesByEntId(entity.id, false, false)
      .subscribe((data: any) => {
        this.nslAttributes = data.result.nslAttributes;
        this.conditionAttributes = [
          ...this.nslAttributes,
          ...this.dropAttributes,
        ];
        this.nslAttributes.forEach((attr: any) => {
          attr['isSearched'] = true;
        });
        this.nslAttributesExpand = [...this.nslAttributes];
        this.entityData = data.result;
        this.attributes = [];
        this.draggableAttributes = this.prepareDraggableAttributes(
          data.result.nslAttributes
        );
        this.cartAttributes = this.prepareDraggableAttributes(
          data.result.nslAttributes
        );
        this.entityAttrCount = data.result.nslAttributes.length;
        this.selectedEntityData = JSON.parse(JSON.stringify(data.result));
        this.selectedEntityData.nslAttributes.forEach((attr: any) => {
          attr['checked'] = false;
          if (attr.attributeType.type === 'image') {
            this.addNewFieldToAttributesArray(
              attr,
              'filter',
              false,
              true,
              'Image cannot be filtered.'
            );
            this.addNewFieldToAttributesArray(
              attr,
              'sort',
              false,
              false,
              'Image cannot be filtered.'
            );
            this.addNewFieldToAttributesArray(
              attr,
              'searchFields',
              false,
              true,
              'Image cannot be filtered.'
            );
            this.addNewFieldToAttributesArray(
              attr,
              'search',
              false,
              true,
              'Image cannot be filtered.'
            );
            this.addNewFieldToAttributesArray(
              attr,
              'locationFilter',
              false,
              true,
              'Image cannot be filtered.'
            );
            attr['disabled'] = true;
            attr['tooltip'] = 'Image cannot be filtered.';
          } else {
            this.addNewFieldToAttributesArray(attr, 'filter', false, false, '');
            this.addNewFieldToAttributesArray(attr, 'sort', false, false, '');
            this.addNewFieldToAttributesArray(
              attr,
              'searchFields',
              false,
              false,
              ''
            );
            this.addNewFieldToAttributesArray(attr, 'search', false, false, '');
            this.addNewFieldToAttributesArray(
              attr,
              'locationFilter',
              false,
              false,
              ''
            );
            attr['disabled'] = false;
            attr['tooltip'] = '';
          }
          this.attributes.push({ name: 'Attribute' });
        });
        if(!this.isEntityMccTable){
          for (let i = 0; i < this.nslAttributes.length; i++) {
            const attr = this.nslAttributes[i];
            if (attr?.attributeType?.uiElement?.uiElement == 'hyperlink') {
              this.hyperLinkAttributes.push(attr);
              const index = this.nslAttributes.indexOf(attr);
              this.nslAttributes.splice(index, 1);
              i--;
            }
          }
          for (let i = 0; i < this.nslAttributesExpand.length; i++) {
            const attr = this.nslAttributesExpand[i];
            if (attr?.attributeType?.uiElement?.uiElement == 'hyperlink') {
              this.expandHyperLinkAttributes.push(attr);
              const index = this.nslAttributesExpand.indexOf(attr);
              this.nslAttributesExpand.splice(index, 1);
              i--;
            }
          }
        }
        this.combinationArray = [
          {
            id: undefined,
            name: 'Combination Name',
            nslAttributes: JSON.parse(
              JSON.stringify(this.selectedEntityData.nslAttributes)
            ),
            checked: false,
          },
        ];
        this.selectedSortEntityData = JSON.parse(
          JSON.stringify(this.selectedEntityData)
        );
        this.selectedSortEntityData.nslAttributes.forEach((attr: any) => {
          if (attr.attributeType.type === 'image') {
            attr['disabled'] = true;
            attr['tooltip'] = 'Image cannot be filtered.';
          } else {
            attr['disabled'] = false;
            attr['tooltip'] = '';
          }
        });
        this.selectedSearchEntityData = JSON.parse(
          JSON.stringify(this.selectedEntityData)
        );
        this.selectedSearchFieldEntityData = JSON.parse(
          JSON.stringify(this.selectedEntityData)
        );
        this.selectedSearchFieldEntityData.nslAttributes.forEach(
          (attr: any) => {
            if (attr.attributeType.type === 'image') {
              attr['disabled'] = true;
              attr['tooltip'] = 'Image cannot be filtered.';
            } else {
              attr['disabled'] = false;
              attr['tooltip'] = '';
            }
          }
        );
        this.selectedSearchEntityData.nslAttributes.forEach((attr: any) => {
          if (attr.attributeType.type === 'image') {
            attr['disabled'] = true;
            attr['tooltip'] = 'Image cannot be filtered.';
          } else {
            attr['disabled'] = false;
            attr['tooltip'] = '';
          }
        });
        this.selectedLocationFilterData = JSON.parse(
          JSON.stringify(this.selectedEntityData)
        );
        this.selectedLocationFilterData.nslAttributes = this.selectedLocationFilterData?.nslAttributes?.filter(
          (attr: any) => {
            return attr?.attributeType?.uiElement?.uiElement === 'location';
          }
        );
        this.selectedLocationFilterData.nslAttributes.forEach((attr: any) => {
          attr['locationData'] = '';
          attr['tempLocationData'] = '';
          attr['disabled'] = false;
          attr['tooltip'] = '';
        });
        this.selectedRangeEntityData = JSON.parse(
          JSON.stringify(this.selectedEntityData)
        );
        for (let i = 0; i < 50; i++) {
          this.styleAttribute.push({
            attrColor: '#000000',
            attHoverColor: '',
            fontFamily: '',
            fontSize: '16px',
            alignment: 'left',
            isBold: false,
            isItalic: false,
          });
          this.expandStyleAttribute.push({
            attrColor: '',
            attHoverColor: '',
            fontFamily: '',
            fontSize: '16px',
            alignment: 'left',
            isBold: false,
            isItalic: false,
          });
        }
        for (let i = 0; i < 10; i++) {
          this.styleGsi.push({
            color: '',
            hoverColor: '',
          });
          this.expandStyleGsi.push({
            color: '',
            hoverColor: '',
          });
        }
        this.styleCard = {
          color: '',
          hoverColor: '',
        };
        this.expandCardStyle = {
          color: '',
          hoverColor: '',
        };
        this.passNumericDataToRangeEntityData();
        this.getTableAttributes();
        this.getEntityCardEditConfiguration();
        this.getAttributeTypes();
        this.fetchKanbanConstraints();
      });
  }
  // ngAfterViewInit(): void {  }

  passNumericDataToRangeEntityData() {
    this.selectedRangeEntityData.nslAttributes = this.selectedRangeEntityData?.nslAttributes?.filter(
      (attr: any) => {
        return attr?.attributeType?.type === 'number';
      }
    );
  }

  gotoMylibrary() {
    this.router.navigate(['/cdui/mylibrary-dashboard']);
  }

  backtoGsiPage() {
    const navigationExtras: NavigationExtras = {
      state: {
        type: 'gsi',
        value: {
          role: this.entityBoardFacade.role,
          entity: this.entityBoardFacade.entity,
          configuration: this.entityBoardFacade.cardConfiguration,
        },
      },
    };
    this.router.navigate(['/entityBoard'], navigationExtras);
  }

  backtoRolePage() {
    this.router.navigate(['/entityBoard']);
  }

  backtoEntityPage() {
    const navigationExtras: NavigationExtras = {
      state: {
        type: 'entity',
        value: {
          role: this.entityBoardFacade.role,
        },
      },
    };
    this.router.navigate(['/entityBoard'], navigationExtras);
  }

  /**
   * This function prevents the default behavior of an event.
   * @param {any} ev - The parameter "ev" is an event object that is passed as an argument to the
   * function "allowDrop". It is typically used in drag and drop functionality to handle events such as
   * dragover, dragenter, and dragleave. In this specific code snippet, the "ev" parameter is used to
   */
  allowDrop(ev: any) {
    ev.preventDefault();
  }

  /**
   * The function sets data for drag and drop functionality based on the type of attribute or element
   * being dragged.
   * @param {any} event - The event object that triggered the drag function, which contains information
   * about the drag operation.
   * @param {any} attr - The `attr` parameter is a variable that represents an attribute object.
   * @param {boolean} isAttr - to check whether it is attribute
   * @param {boolean} [isCart] - The parameter `isCart` is a boolean value that is optional. It is used
   * to determine whether the drag operation is related to a cart
   */
  drag(
    event: any,
    attr: DraggableAttribute,
    isAttr: boolean,
    isCart?: boolean,
    isHyperlink?: boolean
  ) {
    this.draggedAttribute = attr;
    /* istanbul ignore next */
    event.dataTransfer.setData('text', event.target.id);
    /* istanbul ignore else */
    if (isAttr) {
      this.currentDragAttr = attr;
      this.dragAttrType =
        attr.attributeType.type == 'image'
          ? 'image'
          : attr?.attributeType?.uiElement?.uiElement;
    } else {
      this.currentDragAttr = undefined;
      this.dragAttrType = isCart ? 'cart' : 'button';
      if (isHyperlink) {
        this.dragAttrType = 'hyperlink';
      }
    }
  }

  dragButton(event: any, gsi: GsiButton) {
    this.dragAttrType = 'button';
    this.draggedGSI = gsi;
  }

  dragCartType(event: any, isCart: boolean) {
    /* istanbul ignore next */
    event.dataTransfer.setData('text', event.target.id);
    this.dragAttrType = 'cart';
  }

  dropAttributeInTemplates(ev: any, index?: any) {
    /* istanbul ignore next */
    if (this.dropValidation()) {
      let target: HTMLElement = ev.target as HTMLElement;
      let childElement: HTMLElement = target.childNodes[1] as HTMLElement;
      if (
        target.innerHTML.trim() == this.labels?.Attribute ||
        childElement.innerHTML?.trim() == this.labels?.Attribute ||
        target.innerHTML.trim() === 'AttributeName'
      ) {
        ev.preventDefault();
        const data = ev.dataTransfer.getData('text');
        if (target.innerHTML.trim() == this.labels?.Attribute || target?.innerHTML.trim() === 'AttributeName') {
          target.innerHTML = data;
          if (index != undefined && index > -1 && this.columnStyles?.[index]) this.columnStyles[index].displayName = data;
        } else if (childElement?.innerHTML?.trim() == this.labels?.Attribute) {
          childElement.innerHTML = data;
        }
        const foundIndex = this.nslAttributes.findIndex((x: any) => x.name === data);
        if (foundIndex != -1) {
          this.dropAttributes.push(this.nslAttributes[foundIndex]);
          this.parentAttrSoureValues = this.parentAttrSoureValues
            ? this.parentAttrSoureValues
            : this.nslAttributes?.[foundIndex]?.attributeType?.extendedProperties?.sourceValues;
          this.parentAttrSoureValues && this.isVericalListRundown
            ? this.getVerticalTableAttributes(this.parentAttrSoureValues)
            : this.getVerticalTableAttributes();
          if (index != undefined) this.attributes[index] = this.nslAttributes[foundIndex];
          this.mappedAttributesIdx.push(this.nslSourceAttributesMap.get(data));
          this.nslAttributes.splice(foundIndex, 1);
        }
      }
    } else {
      this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
    }

    this.dragAttrType = undefined;
  }
  nestedRowDropAttributeInTemplates(ev: any, index?: any) {
    /* istanbul ignore next */
    if (this.dragAttrType !== 'button' && this.dragAttrType !== 'cart') {
      if (
        ev?.target?.innerHTML.trim() == 'Attribute' ||
        ev?.target?.childNodes?.[1]?.innerHTML?.trim() == 'Attribute'
      ) {
        ev.preventDefault();
        const data = ev.dataTransfer.getData('text');
        if (ev?.target?.innerHTML.trim() == 'Attribute') {
          ev.target.innerHTML = data;
        } else if (
          ev?.target?.childNodes?.[1]?.innerHTML?.trim() == 'Attribute'
        ) {
          ev.target.childNodes[1].innerHTML = data;
        }
        const foundIndex = this.nslAttributes.findIndex(
          (x: any) => x.name === data
        );
        if (foundIndex != -1) {
          this.nestedRowDropAttributes.push(
            this.nslAttributes[foundIndex]?.name
          );
          if (index != undefined)
            this.attributes[index] = this.nslAttributes[foundIndex];
          this.nslAttributes.splice(foundIndex, 1);
          this.nestedmappedAttributesIdx.push(
            this.nslSourceAttributesMap.get(data)
          );
        }
        if (index>=0) {
          this.nestedTableData[0][index].attributeHeading = data;
        }
      }
    } else {
      this.alertService.showToaster(
        'Dragged attribute type is not matched to the dropped attribute.',
        '',
        'Error'
      );
    }
    this.dragAttrType = undefined;
  }
  dropValidation(): boolean {
    if (this.dragAttrType !== 'cart' && !this.isVerticalTable) return true;
    if (this.isVerticalTable) {
      if (!this.isVericalListRundown && this.isVerticalTable) return true;

      if (
        (this.prevDragType === undefined &&
          this.listRunDownAttributeSet.has(this.dragAttrType)) ||
        this.currentDragAttr?.conditionalPotentiality
      ) {
        this.prevDragType =
          this.listRunDownAttributeSet.has(this.dragAttrType) &&
          !this.currentDragAttr?.conditionalPotentiality
            ? this.dragAttrType
            : this.prevDragType;
        return true;
      }
      if (this.dragAttrType == this.prevDragType) {
        this.parentAttrSoureValues?.forEach((value: any, index: number) => {
          if (
            value[index]?.Data?.actualValue !==
            this.currentDragAttr?.attributeType?.extendedProperties
              ?.sourceValue?.[index]?.Data?.actualValue
          )
            this.alertService.showToaster(
              'Dragged attribute type Source values are not matched to the dropped attribute Source values.',
              '',
              'Error'
            );
          return false;
        });
        return true;
      } else {
        this.alertService.showToaster(
          'Dragged attribute type Source values are not matched to the previous attribute Source Type.',
          '',
          'Error'
        );
      }
    }
    return false;
  }

  /**
   * The function handles the drop action of a button or cart attribute and assigns it to a specific
   * position, while also checking if the dragged attribute type matches the dropped attribute type.
   * @param {any} event - The event parameter is an object that represents DragEvent the
   * function. It contains information about the event, such as the target element, the type of event,
   * and any data that was transferred during a drag and drop operation.
   * * using the data we get buttonID and the gsiId and mapping is done accordingly
   * *
   */
  dropActionBtn(event: any, childGsiMapping?: { [key: number]: GsiButton }) {
    /* istanbul ignore next */
    if (this.dragAttrType === 'button' || this.dragAttrType === 'cart' || this.dragAttrType === 'hyperlink') {
      if (this.checkGSIMappingCondition(childGsiMapping)) {
        // this.alertGSIMappedToCart();
        return;
      }
      let buttonId = parseInt(event.target.id.split('-')[1]);

      const data = event.dataTransfer.getData('text');
      const gsiIndex = parseInt(data.split('-')[1]);
      if (this.renderDynamiTemplate) {
      
        if (event.target.innerText == 'Action Button' ||event.target.innerText == 'CTA' ) {
          if (this.dragAttrType == 'button' || this.dragAttrType == 'hyperlink') {
            this.childGsiMapping[buttonId] = this.draggedGSI;
            event.target.innerHTML = this.draggedGSI?.displayName;
            this.draggedGSI.isSelected = true;
            this.draggedGSI.position = buttonId;
          }
          else {    
            event.target.innerHTML = this.CART_BUTTON.displayName;
            this.makeCartButtonFrom(buttonId);
          }
          
          return;
        }
      }
      if (!childGsiMapping?.[buttonId] && buttonId >= 0) {
        event.preventDefault();
        if (this.dragAttrType == 'button' || this.dragAttrType == 'hyperlink') {
          this.makeGsiButtonFromId(buttonId, gsiIndex, childGsiMapping);
        } else {
          this.makeCartButtonFrom(buttonId);
        }
      } else {
        if (
          (childGsiMapping[buttonId].entityType == 'GSI' || childGsiMapping[buttonId].entityType == 'PAGE') &&
          this.dragAttrType == 'cart'
        )
          // this.alertGSIMappedToCart();
          this.alertService.showToaster(
            'Dragged attribute type is not matched to the dropped attribute.',
            '',
            'Error'
          );
      }
    }
    event.stopPropagation();
  }
  checkGSIMappingCondition(childGsiMapping: { [key: number]: GsiButton }): boolean {
    let keysLength = Object.keys(childGsiMapping).length;
    if (keysLength > 0 && this.dragAttrType == 'cart') {
      return false;
    } else if (
      this.dragAttrType == 'button' &&
      (this.CART_BUTTON.isSelected['child'].isSelected ||
        this.CART_BUTTON.isSelected['expand'].isSelected)
    ) {
      return false;
    }
    return false;
  }

  /**
   * This function maps a GSI object to a button ID and sets its position while marking it as selected.
   * @param {number} buttonId - The ID of the button that the GSI (Game State Integration) will be
   * mapped to.
   * @param {number} gsiId - The `gsiId` parameter is a number that represents the index of a specific
   * item in the `childGsiList` array. This item is then selected and its position is set to the
   * `buttonId` parameter value.
   */
  makeGsiButtonFromId(
    buttonId: number,
    gsiId: number,
    childGsiMapping: { [key: number]: GsiButton },
    hyperlinkIndex?: number
  ) {
    if (this.dragAttrType != 'hyperlink') {
      childGsiMapping[buttonId] = this.childGsiList[gsiId];
      this.childGsiList[gsiId].isSelected = true;
      this.childGsiList[gsiId].position = buttonId;
    } else {
      // childGsiMapping[buttonId] = this.hyperlinkCtaDropArray[hyperlinkIndex];
      childGsiMapping[hyperlinkIndex].isSelected = true;
    }
  }

  /**
   * This function sets the position and selection status of a cart button and adds it to a child
   * mapping object.
   * @param {number} buttonId - The parameter `buttonId` is a number that represents the position of
   * the cart button in the childGsiMapping array. It is used to set the position property of the
   * CART_BUTTON object and to assign the CART_BUTTON object to the childGsiMapping array at the
   * specified position.
   */
  makeCartButtonFrom(buttonId: number) {
    this.CART_BUTTON.isSelected['child'].isSelected = true;
    this.CART_BUTTON.isSelected['child'].position = buttonId;
    this.childGsiMapping[buttonId] = this.CART_BUTTON;
  }
  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.labels = res;
      });
  }

  // temaplateInfo: any,
  // count?: number,
  // isMulti?: boolean,
  // isMultiAttributeExpand?: boolean,
  // isDynamicTemplate?: boolean
  templateSelection(template: any, isDynamicTemplate?: boolean) {
    /* istanbul ignore next */
    if (template.thirdVariable || isDynamicTemplate) {
      this.temporaryTemplate = template.id;
      if (this.tabSelected == 'expand') {
        this.selectedExpandDynamicTemplate = template
      } else if (this.tabSelected == 'child') {
        this.selectedDynamicTemplate = template;
      }
      this.expandTemplateType = null;
    } else {
      this.renderDynamiTemplate = false
      if (this.tabSelected == 'expand') {
        this.expandTemplateType = template.templateNumber;
        this.temporaryTemplate = template.templateNumber;
        this.expandComponentName = template.componentName;
      } else if (this.tabSelected == 'child') {
        this.temporaryTemplate = template.templateNumber;
        this.childComponentName = template.componentName;
      }
      this.selectedDynamicTemplate = false;
    }
    /* istanbul ignore next */
    this.temporaryMultiAttribute = template.firstVariable ? true : false;
    /* istanbul ignore next */
    this.isMultiAttributeExpand = template.secondVariable ? true : false;
    this.numberOfRows = this.isMultiAttributeExpand ? 0 : this.numberOfRows;
    // ! Comment and need to test it out why it is added.
    // this.onTabSelect();
  }

  templateExpandSelection(type: any, count: any) {
    if (this.entityAttrCount == count) {
      this.expandType = type;
    } else {
      this.alertService.showToaster(
        'Attribute count is not matched to the selected template.',
        '',
        'Error'
      );
    }
  }

  onDeletingAttr(element: any, attrIdx?: number): void {
    let attrName: any;
    if (element?.innerHTML) {
      attrName = element.innerHTML?.trim();
      element.innerHTML = 'Attribute';
    } else attrName = element;
    const foundIndex = this.dropAttributes.findIndex(
      (x: any) => x?.name === attrName
    );
    this.gsiAttrMap[attrName] = '';
    if (this.childGsiList[foundIndex])
      this.childGsiList[foundIndex].isSelected = false;
    this.buttonDroppedInAttribute[attrName] = false;
    /* istanbul ignore else */
    if (foundIndex != -1) {
      this.nslAttributes.push(this.dropAttributes[foundIndex]);
      const index = this.attributes.findIndex((x: any) => x.name === attrName);
      // index ? index : this.dropAttributes[attrIdx]
      if (index != -1) {
        this.attributes[index] = { name: 'Attribute' };
      }
      this.dropAttributes.splice(foundIndex, 1);
      // this.removeDropHyperlink(element);
      let attrIdx = this.nslSourceAttributesMap.get(attrName);
      attrIdx = this.mappedAttributesIdx.indexOf(attrIdx);
      if (attrIdx !== -1) this.mappedAttributesIdx?.splice(attrIdx, 1);
      if (this.mappedAttributesIdx.length === 0) this.prevDragType = undefined;
    }
    if (this.headerGsiMap.has(attrName)) {
      this.selectGsiList.push(this.headerGsiMap.get(attrName));
      this.headerGsiMap.delete(attrName);
    }
    if (attrIdx != -1 && this.isTable) {
      this.tableData[0][attrIdx].attributeHeading = 'Attribute';
    }
    if (attrIdx && foundIndex != -1 && this.isTable) {
      this.tableData.splice(attrIdx, 1);
    }
    if (attrIdx && foundIndex != -1) {
      this.verticalTableData.splice(attrIdx, 1);
    }
  }
  onDeletingNestedAttr(element: any, attrIdx?: number,nestedRowToggle?:boolean) {
    if(nestedRowToggle){
      this.nestedRowDropAttributes?.forEach((attr:any) => {
        const ind = this.selectedEntityData?.nslAttributes?.findIndex(
          (x: any) => x.name === attr
        );
        this.nslAttributes.push(this.selectedEntityData?.nslAttributes[ind]);
      });
      this.nestedRowDropAttributes=[];
      return;
    }
    let attrName: any;
    if (element?.innerHTML) {
      attrName = element.innerHTML?.trim();
      element.innerHTML = 'Attribute';
    } else attrName = element;
    const foundIndex = this.nestedRowDropAttributes.findIndex(
      (x: any) => x === attrName
    );
    const ind = this.selectedEntityData?.nslAttributes?.findIndex(
      (x: any) => x.name === attrName
    );
    /* istanbul ignore else */
    if (foundIndex != -1) {
      this.nslAttributes.push(this.selectedEntityData?.nslAttributes[ind]);
      const index = this.attributes.findIndex((x: any) => x === attrName);
      if (index != -1) {
        this.attributes[index] = { name: 'Attribute' };
      }
      this.nestedRowDropAttributes.splice(foundIndex, 1);
      let attrIdx = this.nslSourceAttributesMap.get(attrName);
      attrIdx = this.nestedmappedAttributesIdx.indexOf(attrIdx);
      if (attrIdx !== -1) this.nestedmappedAttributesIdx?.splice(attrIdx, 1);
      if (this.nestedmappedAttributesIdx.length === 0)
        this.prevDragType = undefined;
    }
    if (attrIdx != -1) {
      this.nestedTableData[0][attrIdx].attributeHeading = 'Attribute';
    }
  }
  clearStyles(Arr: any) {
    Arr?.forEach((attr: any) => {
      attr.attrColor = '';
      attr.attHoverColor = '';
      attr.fontFamily = '';
      attr.fontSize = '16px';
      attr.alignment = 'left';
      attr.isBold = false;
      attr.isItalic = false;
    });
  }
  clearCardStyles(Arr: any) {
    if (Arr) {
      Arr.color = '';
      Arr.hoverColor = '';
    }
  }
  selectedTemplateExpand(){
    if(this.expandDynamicCard){
      this.isDynamicExpandCardSelected = true;
      this.expandTemplateType = this.selectedExpandDynamicTemplate;
    }
    else{
      this.isDynamicExpandCardSelected = false;
      this.expandTemplateType = this.expandTemplateType;
      this.clearStyles(this.expandStyleAttribute);
      this.clearCardStyles(this.expandCardStyle);
      this.clearStyles(this.expandStyleGsi);
      this.isMultiAttribute = this.temporaryMultiAttribute;
      this.isSelectTemplate = true;
      this.dropAttributes = [];
    }
  }
  selectedTemplateChild(){
    if(this.renderDynamiTemplate) {
     this.selectDynamicTemplate();
    }
    else{
      this.templateType = this.temporaryTemplate;
      this.clearStyles(this.styleAttribute);
      this.clearCardStyles(this.styleCard);
      this.clearStyles(this.styleGsi);
      this.isMultiAttribute = this.temporaryMultiAttribute;
      this.isSelectTemplate = true;
      this.dropAttributes = [];
    }
  }
  selectedTemplate() {
    if(this.tabSelected == "child"){
      this.selectedTemplateChild();
      this.openExpandDialog();
    }
    else if(this.tabSelected == "expand"){
      this.selectedTemplateExpand();
    }
  }
  private selectDynamicTemplate(): void {
    this.el.nativeElement.querySelector(
      '.dynamic-template-placeholder'
    ).innerHTML = ``;
    /* istanbul ignore next */
    this.el.nativeElement.querySelector(
      '.dynamic-template-placeholder'
    ).innerHTML = this.selectedDynamicTemplate.template;
    /* istanbul ignore next */
    this.el.nativeElement
      .querySelectorAll('.dynamic-template-placeholder li')
      .forEach((e: any) => {
        e.addEventListener('drop', (event: any) =>
          this.dropAttribute(event, 'any')
        );
        e.addEventListener('dragover', (event: any) => this.allowDrop(event));
      });
    /* istanbul ignore next */
    this.el.nativeElement
      .querySelectorAll('.dynamic-template-placeholder .card-logo')
      .forEach((e: any) => {
        e.addEventListener('drop', (event: any) =>
          this.dropAttribute(event, 'image')
        );
        e.addEventListener('dragover', (event: any) => this.allowDrop(event));
      });
    this.el.nativeElement
      .querySelectorAll('.dynamic-template-placeholder .card-video')
      .forEach((e: any) => {
        e.addEventListener('drop', (event: any) =>
          this.dropAttribute(event, 'video')
        );
        e.addEventListener('dragover', (event: any) => this.allowDrop(event));
      });
    /* istanbul ignore next */
    this.el.nativeElement.querySelectorAll('.dynamic-template-placeholder button').forEach((e: any) => {
      e.addEventListener('drop', (event: any) => this.dropActionBtn(event, this.childGsiMapping));
      e.addEventListener('dragover', (event: any) => this.allowDrop(event));
    });
  }

  // dropActionBtn(event: any) {}

  dropAttribute(ev: any, attrType?: any, index?: any) {
    if (this.isSelectTemplate) {
      /* istanbul ignore else */
      if (
        this.dragAttrType !== 'button' &&
        this.dragAttrType !== 'cart' &&
        ((attrType == 'image' && this.dragAttrType == 'image') ||
          (attrType == 'any' && this.dragAttrType !== 'image'))
      ) {
        this.dropAttributeInTemplates(ev, index);
      } else {
        this.alertService.showToaster(
          'Dragged attribute type is not matched to the dropped attribute.',
          '',
          'Error'
        );
      }
    } else {
      /*istanbul ignore next*/
      if (this.dragAttrType) {
        this.dropAttributeInTemplates(ev, index);
      } else {
        this.alertService.showToaster(
          'Dragged attribute type is not matched to the dropped attribute.',
          '',
          'Error'
        );
      }
    }
  }

  recieveDropEntityData(dropEntityData: ElementRef): void {
    /* istanbul ignore next */
    if (
      dropEntityData &&
      dropEntityData?.nativeElement?.getElementsByTagName('li').length > 0
    ) {
      this.dropEntityData = dropEntityData;
    }
  }

  detectMultiAttribute(event: boolean): void {
    this.isMultiAttribute = event;
  }
  onLinkClick(event: any) {
    /*istanbul ignore next */
    if (event?.tab?.textLabel == 'Expand') {
      this.isMultiAttributeExpand = true;
    } else if (event?.tab?.textLabel == 'Child') {
      this.isMultiAttributeExpand = false;
    }
  }
  tabChanged(event: any): void {
    this.isDesignTab = event.tab.textLabel == 'Design' ? true : false;
  }
  changeColor(event: any) {
    this.selectedColor = event;
    this.style = {
      ...this.style,
      changedElementStyle: 'selectedColor',
      changedElementValue: event,
    };
  }
  closeColorPicker(event: string): void {
    this.colorPickerToggler = !this.colorPickerToggler;
    this.unCheckBorderandUnselectTheAttribute();
  }

  // rowSelectionInPage(event: any, value: number) {
  //   this.numberOfRows = value;
  // }

  addCondition() {
    this.addConditionVariable = !this.addConditionVariable;
    this.addConditionVariableOperator = false;
  }

  addConditionOperator() {
    this.addConditionVariableOperator = !this.addConditionVariableOperator;
  }

  changeColorCondition(event: any) {
    this.colorConditionSetter = event;
    this.conditionAttribute = [];
    this.conditionAttributeExpand = [];
  }
  conditionColorEmit(event: any, expand?: string) {
    const value = event?.value?.innerText
      ? event.value?.innerText
      : event?.value?.innerHTML;
    if (value?.trim() !== 'Attribute') {
      this.setConditionAttribute(event, value, expand);
    }
  }
  setConditionAttribute(event: any, value: any, expand?: string) {
    if (expand) {
      this.conditionColor = event.color;
      this.conditionAttributeExpand.push({ name: value });
    } else {
      this.conditionColor = event.color;
      this.conditionAttribute.push({ name: value });
    }
  }

  createCondition() {
    if (
      this.conditionColor &&
      (this.conditionAttribute.length > 0 ||
        this.conditionAttributeExpand.length > 0) &&
      this.selectedConditonValue &&
      this.selectedCondition
    ) {
      this.conditionAttribute.forEach((attr: any) => {
        this.selectedEntityData?.nslAttributes?.forEach((attr1: any) => {
          if (attr.name === attr1.name) {
            attr['type'] = attr1.attributeType?.type;
          }
        });
      });
      this.conditionAttribute.forEach((attr: any) => {
        this.conditionArray.push({
          color: this.conditionColor,
          attribute: attr.name,
          value:
            attr['type'] === 'number'
              ? parseInt(this.selectedConditonValue)
              : this.selectedConditonValue,
          condition: this.selectedCondition,
        });
      });
      this.conditionAttributeExpand.forEach((attr: any) => {
        this.conditionArrayExpand.push({
          color: this.conditionColor,
          attribute: attr.name,
          value:
            attr['type'] === 'number'
              ? parseInt(this.selectedConditonValue)
              : this.selectedConditonValue,
          condition: this.selectedCondition,
        });
      });
      this.legendSelected = true;
      this.conditionColor = '#fff';
      this.conditionAttribute = [];
      this.conditionAttributeExpand = [];
      this.selectedConditonValue = '';
      this.selectedCondition = '';
      this.unCheckBorderandUnselectTheAttribute();
      this.addCondition();
    }
  }
  deleteSelectedCondition(index: any, isChildOrExpand: string) {
    if (isChildOrExpand === 'child') {
      this.conditionArray.splice(index, 1);
    } else if (isChildOrExpand === 'expand') {
      this.conditionArrayExpand.splice(index, 1);
    }
  }
  setSelectedCondition(condition: string) {
    this.selectedCondition = condition;
  }
  toggleColorPicker() {
    this.colorPickerToggler = !this.colorPickerToggler;
  }
  unCheckBorderandUnselectTheAttribute() {
    this.selectedDirectiveId = '1' + this.selectedDirectiveId;
  }
  chatbotSelection() {
    /* istanbul ignore next */
    if (this.chatbotData?.isSelected) {
      this.chatbotData.entity_name = this.entityData.name;
      this.chatbotData.entity_id = this.entityData.id;
    } else {
      this.chatbotData = {
        isSelected: false,
        entity_name: '',
        entity_id: '',
        language: '',
        intents: new Array(),
      };
    }
    this.getLanguages();
  }

  addIntent() {
    /* istanbul ignore next */
    this.chatbotData?.intents?.push({
      intent: '',
      filters: new Array(),
      filter_range_fixed: new Array(),
      attributes: new Array(),
    });

    /* istanbul ignore next */
    this.selectedSearchEntityData?.nslAttributes?.forEach((attr: any) => {
      /* istanbul ignore next */
      let attrData = {
        attributeId: attr?.id,
        attributeName: attr?.name,
        checked: false,
        range: 'MIN',
      };
      /* istanbul ignore next */
      this.chatbotData?.intents?.[
        this.chatbotData?.intents?.length - 1
      ]?.attributes?.push(attrData);
    });
  }

  removeIntent(intentIndex: number) {
    /* istanbul ignore next */
    if (this.chatbotData?.intents?.[intentIndex]) {
      this.chatbotData.intents.splice(intentIndex, 1);
    }
  }

  getLanguages() {
    /* istanbul ignore next */
    // if (this.languagesOptions?.length === 0) {
    //   /* istanbul ignore next */
    //   this.livechatFacade
    //     .getLanguages(true)
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe((res: any) => {
    //       /* istanbul ignore next */
    //       if (res && res?.language) {
    //         this.languagesOptions = [];
    //         /* istanbul ignore next */
    //         Object.entries(res?.language).forEach((data: any) => {
    //           /* istanbul ignore next */
    //           this.languagesOptions.push({ name: data?.[0], value: data?.[1] });
    //         });
    //       }
    //     });
    // }
  }
  locationFilterSave(attr: any) {
    attr.locationData = attr.tempLocationData;
    this.alertService.showToaster(
      'The radius for ' +
        attr.displayName +
        ' attribute is ' +
        attr.locationData,
      '',
      'success'
    );
  }

  additionOfGroup() {
    if (this.editBoolean === true) {
      const obj = JSON.parse(
        JSON.stringify({
          selectedEntityData: this.selectedEntityData,
          name: '',
          save: false,
        })
      );
      this.groupFilterData.push(obj);
      this.disableTheRemainingButtons();
      this.editBoolean = false;
    }
  }
  saveGroupName(index: any, type: string) {
    let saveBoolean: boolean = false;
    if (type === 'save') {
      this.groupFilterData[index].selectedEntityData?.nslAttributes?.forEach(
        (data: any) => {
          if (data.checked === true) {
            saveBoolean = true;
          }
        }
      );
      /* istanbu ignore else*/
      if (saveBoolean && this.groupFilterData[index].name != '') {
        this.groupFilterData[index].save = true;
        this.editBoolean = true;
        this.disableTheRemainingButtons();
      }
    } else if (type === 'edit') {
      this.editBoolean = false;
      this.groupFilterData.forEach((data: any, ind) => {
        if (data.save === false) {
          this.editBoolean = true;
        }
      });
      if (!this.editBoolean) {
        this.groupFilterData[index].save = false;
      }
    }
  }
  deleteGroup(index: any, edit?: any) {
    if (edit && edit === 'saveDelete') {
      this.editBoolean = true;
    }
    this.groupFilterData.splice(index, 1);
    this.disableTheRemainingButtons();
  }

  disableTheRemainingButtons() {
    this.groupFilterData.forEach((group: any, index1) => {
      group.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
        if (attr.attributeType.type === 'image') {
          attr['disabled'] = true;
          attr['tooltip'] = 'Image cannot be filtered.';
        } else {
          attr['disabled'] = false;
          attr['tooltip'] = '';
        }
      });
    });
    this.groupFilterData.forEach((group1: any, index1) => {
      this.groupFilterData.forEach((group2: any, index2) => {
        if (index1 !== index2) {
          group1?.selectedEntityData?.nslAttributes?.forEach((attr1: any) => {
            group2.selectedEntityData.nslAttributes.forEach((attr2: any) => {
              if (attr1.checked === true && attr2.name === attr1.name) {
                attr2.disabled = true;
                attr2['tooltip'] =
                  'It is already selected in ' + group1.name + ' group.';
              }
            });
          });
        }
      });
    });
  }

  searchFieldsOpen(event: any, attr: any, index: any) {
    attr.refEntityOpen = true;
    this.refEntPageNumber[index] = 1;
    this.refEntTotalRecords[index] = this.constrefEntTotalRecords;
    this.refEntTotalPages[index] = this.constrefEntTotalPages;
    this.referenceEntitiesSearchFields[
      index
    ] = this.constreferenceEntitiesSearchFields;
    this.searchTextRefEntity[index] = '';
  }

  getAllreferenceEntities(searchFieldsBool: any) {
    if (searchFieldsBool == false) {
      this.refrenceEntitiesApiCall('', 1, 10, -1);
    }
  }
  /*istanbul ignore next*/
  onSearchRefEnt(evt: any, index: any, searchAttr: any) {
    let counter: number = 0;
    let ansCounter: number = 0;
    this.selectedSearchFieldEntityData?.nslAttributes?.forEach((attr: any) => {
      if (attr.checked) {
        counter = counter + 1;
      } else if (attr == searchAttr) {
        ansCounter = counter;
      }
    });
    this.select.toArray()[ansCounter]._handleKeydown = (
      event: KeyboardEvent
    ) => {
      if (event.keyCode == SPACE) return;
      if (!this.select.toArray()[ansCounter].disabled) {
        this.select.toArray()[ansCounter].panelOpen
          ? this.select.toArray()[ansCounter]._handleOpenKeydown(event)
          : this.select.toArray()[ansCounter]._handleClosedKeydown(event);
      }
    };
    this.refEntPageNumber[index] = 1;
    this.searchTextRefEntity[index] = evt;
    this.refrenceEntitiesApiCall(evt, this.refEntPageNumber[index], 10, index);
  }
  refrenceEntitiesApiCall(
    searchCriteria: string,
    pageNum: number,
    pageSize: number,
    index: any
  ) {
    if (index === -1) {
      this.loader.show();
    }
    this.entityBoardService
      .getAllreferenceEntities(
        searchCriteria,
        pageNum,
        pageSize,
        this.selectedRoleId
      )
      .subscribe((res: any) => {
        if (index === -1) {
          this.constreferenceEntitiesSearchFields = res.result.data;
          this.constrefEntTotalPages = res.result.totalPages;
          this.constrefEntTotalRecords = res.result.totalResults;
          this.loader.hide();
        } else {
          this.referenceEntitiesSearchFields[index] = res.result.data;
          this.refEntTotalPages[index] = res.result.totalPages;
          this.refEntTotalRecords[index] = res.result.totalResults;
        }
      });
  }
  onRefEntityPageChange(event: any, index: any) {
    this.refEntPageNumber[index] = event.pageIndex + 1;
    this.refrenceEntitiesApiCall(
      this.searchTextRefEntity[index],
      this.refEntPageNumber[index],
      10,
      index
    );
  }
  getAllattributesById(id: any, index: any) {
    this.entityBoardService
      .getAllattributesById(id.value.id)
      .subscribe((res: any) => {
        this.targetRefAttr[index] = res?.result?.nslAttributes;
      });
  }
  renderDynamicTemplates() {
    if (this.isDynamicTeplates) {
      if (this.dynamicTemplates.length === 0) {
        /* istanbul ignore next */
        this.entityBoardService
          .getTemplatesByLayout('entity-board')
          .subscribe((resp: any) => {
            /* istanbul ignore next */
            if (resp.result) {
              this.dynamicTemplates = resp.result;
            }
          });
      }
      this.renderDynamiTemplate = true;
    } else {
      /* istanbul ignore next */
      this.selectedDynamicTemplate = null;
      this.renderDynamiTemplate = false;
    }
  }
  customizeTabChange(tabChangeEvent: MatTabChangeEvent): void {
    /* istanbul ignore next */
    if (tabChangeEvent?.tab?.textLabel === 'Dynamic Templates') {
      /* istanbul ignore next */
      if (this.dynamicTemplates.length === 0) {
        /* istanbul ignore next */
        this.entityBoardService
          .getTemplatesByLayout('entity-board')
          .subscribe((resp: any) => {
            /* istanbul ignore next */
            if (resp.result) {
              this.dynamicTemplates = resp.result;
            }
          });
      }
      /* istanbul ignore next */
      this.expandDynamicCard = true;
    } else {
      /* istanbul ignore next */
      this.selectedDynamicTemplate = null;
      this.renderDynamiTemplate = false;
    }
  }

  openDialog(templateRef: TemplateRef<any>) {
    /* istanbul ignore next */
    this.dialog.closeAll();
    /*istanbul ignore next*/
    this.dialog.open(templateRef, {
      width: '1100px',
      panelClass: 'ge-choosecard',
    });
  }

  closeDialog() {
    this.dialog.closeAll();
    this.isExpand = false;
  }
  /*istanbul ignore next*/
  gotoAdvancedFeature() {
    this.cartAttributeStylesDuplicate = this.cartAttributeStyles;
    this.getCartDetails();
    this.getMappedAttributes();
    this.getDropMappedAttributes();
    this.isAdvancedFeature = true;
    this.isCardConfig = false;
    this.title = 'Advance Features';
  }

  goToFilterDesignPage() {
    // this.isAdvancedFeature = false;
    this.getMappedAttributes();
    // this.canvasDesignPage = true;
    this.dropAdvancedFeature += 1;
  }

  changeBold() {
    this.isBold = !this.isBold;
    this.style = {
      ...this.style,
      changedElementStyle: 'isBold',
      changedElementValue: this.isBold,
    };
  }

  changeItalic() {
    this.isItalic = !this.isItalic;
    this.style = {
      ...this.style,
      changedElementStyle: 'isItalic',
      changedElementValue: this.isItalic,
    };
  }
  changeFont(event: any) {
    if (event != -1) {
      this.selectedFont = this.fonts[event];
      this.style = {
        ...this.style,
        changedElementStyle: 'fontFamily',
        changedElementValue: this.selectedFont,
      };
    }
  }

  getCartDetails() {
    if (
      this.CART_BUTTON.isSelected['child'].isSelected ||
      this.CART_BUTTON.isSelected['expand'].isSelected
    ) {
      this.cartView = this.extractCartAttributes();
      this.cartView.style.cartButton = this.CART_BUTTON;
      this.cartView.style.attrStyle = this.saveCartAttributesStyle(
        this.cartAttributeStylesDuplicate
      );
      //price position is used to calculate the total price
      if (this.cartView?.style?.attrStyle?.length == 0 && this.cartAttrStyleArr)
        this.cartView.style.attrStyle = this.cartAttrStyleArr;
      this.cartView.style.pricePosition = this.cartPricePosition;
    }
  }

  getMappedAttributes() {
    /*istanbul ignore next*/
    if (this.dropEntityData && this.renderDynamiTemplate) {
      let lis = [];
      if (this.renderDynamiTemplate) lis = [...this.dropEntityData?.nativeElement?.getElementsByTagName('li')];
      else lis = [...this.dropEntityData?.nativeElement?.getElementsByTagName('li')];
      this.extractCardDetails(lis);
      /*istanbul ignore next*/
      this.getCartDetails();
      lis.forEach((attr: any, index: any) => {
        this.conditionArray.forEach((condition: any) => {
          if (
            attr?.innerText === condition?.attribute ||
            attr?.innerHTML === condition?.attribute
          ) {
            condition['index'] = index;
          }
        });
      });
      /*istanbul ignore next*/
      for (let i = 0; i < this.dropMappedAttributes.length; i++) {
        if (this.dropMappedAttributes[i].trim() === 'Attribute') {
          this.dropMappedAttributes[i] = false;
        }
      }
      this.getAttributeTypes();
      /*istanbul ignore next*/
      let bts = [
        ...this.dropEntityData?.nativeElement?.getElementsByTagName('button'),
      ];
      if (bts) {
        bts.forEach((element: any) => {
          let buttonStyle: string[] = [];
          /*istanbul ignore next*/
          if (element?.attributes?.style?.value)
            buttonStyle = element.attributes.style.value?.split(';');
          /*istanbul ignore next*/
          let bbgColor = buttonStyle.find((str: string) => {
            return str.includes('background');
          });
          let btnColor: string = bbgColor ? bbgColor?.split(':')[1] : 'blue';
          if (element?.innerHTML.trim() != 'Action Button') {
            this.btnColors.push(btnColor);
          }
        });
      }
      this.dropMappedActionBts = bts?.map((x) => {
        return x.innerText.trim();
      });
      this.dropMappedActionBts = [
        this.gsiAction?.nativeElement?.getInnerHTML(),
      ];
    }
  }
  sample: any = [];
  /**
   * This function saves style properties of each attribute in cart
   * @param cartAttributeStyles
   * @returns styledata of each attribute
   */
  saveCartAttributesStyle(cartAttributeStyles: QueryList<ElementRef>) {
    let data: any[] = [];
    const cartAttrStyles: { [key: number]: cartStyles } = {};
    cartAttributeStyles?.forEach((attr: any) => {
      let attributeId = attr?.nativeElement.id;
      const position = parseInt(attributeId.split('-')[1]);
      cartAttrStyles[position] = {
        color: '',
        font: '',
        fontSize: '',
        fontStyle: '',
        textAlign: '',
      };
      cartAttrStyles[position].color = attr?.nativeElement?.style?.color;
      cartAttrStyles[position].font = attr?.nativeElement?.style?.fontFamily;
      cartAttrStyles[position].fontSize = attr?.nativeElement?.style?.fontSize;
      cartAttrStyles[position].fontStyle =
        attr?.nativeElement?.style?.fontStyle;
      cartAttrStyles[position].textAlign =
        attr?.nativeElement?.style?.textAlign;
      data?.push(cartAttrStyles[position]);
    });
    return data;
  }

  extractCartAttributes(): any {
    let data: any[] = [];
    let style: any = {};
    style.attr = {};
    this.cartAttributes.forEach((attr: DraggableAttribute) => {
      if (attr.isSelected) {
        let attribute = {
          attributeId: attr.id,
          attributeName: attr.name,
          displayName: attr.displayName,
          id: attr.id,
          masterId: attr.masterId,
          name: attr.name,
          version: '0.0',
        };
        style.attr[attr.name] = attr.position;
        data.push(attribute);
      }
    });
    let cartView: any = {};
    cartView.attributeList = data;
    cartView.style = style;
    cartView.templateType = 1;
    cartView.gsiList = this.prepareCartGsiList();
    cartView.templateType = this.cartTemplateType;
    return cartView;
  }
  prepareCartGsiList(): any[] {
    let gsiList: any = [];
    this.cartGsiList.forEach((gsi: any) => {
      gsiList.push({
        id: gsi.id,
        masterId: gsi.masterId,
        name: gsi.name,
        dcds: this.cartDcdList?.filter(
          (dcd: any) => dcd?.targetContextualName?.split('.')?.[0] == gsi?.name
        ),
      });
    });
    return gsiList;
  }
  extractCardDetails(lis: any) {
    let cardStyle: string[] = this.dropEntityData?.nativeElement
      ?.getElementsByClassName('entity-template-type')?.[0]
      ?.attributes?.style?.value.split(';');
    /*istanbul ignore next*/
    let cardBackground: string = cardStyle
      ?.find((style: string) => style.includes('background'))
      ?.split(':')[1];
    this.cardColor = cardBackground;
    lis = lis.filter((el: any) => el.className !== 'linebar');
    this.droppedColors = [];
    this.droppedStyles = [];
    /*istanbul ignore next*/
    lis.forEach((x: any) => {
      let attributeStyle: string[] = [];
      let styleObj: any = {};
      if (x?.attributes?.style?.value)
        attributeStyle = x.attributes.style.value?.split(';');
      attributeStyle.forEach((style: any) => {
        let arr: string[] = style.split(':');
        styleObj[arr[0].trim()] = arr[1]?.trim();
      });
      let color: string = styleObj?.color ? styleObj.color : '';
      this.droppedStyles.push(styleObj);
      this.droppedColors.push(color);
    });
    /*istanbul ignore next*/
    if (!this.renderDynamiTemplate) {
      this.dropMappedAttributes = lis.map((x: any) => {
        if (x?.innerText?.trim()) {
          return x.innerText.trim();
        } else {
          return x;
        }
      });
    } else {
      /*istanbul ignore next*/
      this.dropMappedAttributes = lis.map((x: any) => {
        return x.innerHTML;
      });
    }
  }

  getAttributeTypes(): void {
    this.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
      this.attributeTypes.push({
        name: attr?.name,
        uiElement: attr?.attributeType?.type,
        uiDataType: attr?.attributeType?.uiElement?.uiElement,
      });
    });
  }
  gotoEntityConfigpage() {
    this.getMappedAttributes();
    this.getAttributeTypes();
    this.getDropEntityExpandData();
    this.getDropMappedAttributes();
    /*istanbul ignore next*/
    const tempTypeObj = this.getTemplateType(
      this.templateType,
      this.expandType
    );

    let obj = {
      attr: this.dropMappedAttributes?.length>0 ? this.dropMappedAttributes : this.dropMappedDragabbleAttributes,
      color: this.droppedColors,
      btnColors: this.btnColors,
      cardBackground: this.cardColor,
      attributeTypes: this.attributeTypes,
      defaultViewType: this.tabSelected,
      expandTemplateTypeData: Object.keys(this.dropEntityExpandData).length>0? this.dropEntityExpandData: this.dropEntityExpandDraggableAttributesData,
      cardName: tempTypeObj.cardName,
      templateType: !this.renderDynamiTemplate
        ? tempTypeObj.templateType
        : 'dynamic-' + this.selectedDynamicTemplate.id,
      expandTemplateType: this.expandTemplateType,
      isDeleteButton: this.isDeleteButton,
    };
    this.changeObj = { ...obj };
    if (Object.keys(this.dropEntityExpandData).length == 0) {
      this.cardConfigEvent.emit(obj);
    }
  }

  getTemplateType(templateType: any, expandType: any) {
    let temp = {
      cardName: 'default',
      templateType: 'default',
      childView: true,
      expandedView: false,
    };
    /*istanbul ignore next*/
    if (templateType || expandType) {
      temp.cardName = templateType ? 'child' : 'expand';
      temp.templateType = templateType ? this.templateType : this.expandType;
      temp.childView = templateType ? true : false;
      temp.expandedView = expandType ? true : false;
    }
    return temp;
  }

  chatBotDataEmit(event: any) {
    this.chatbotData = event;
  }
  /* istanbul ignore next */
  saveEntityCard() {
    /* istanbul ignore next */
    if (this.chatbotData?.isSelected) {
      let flag: boolean = false;
      flag = this.chatBotData();
      if (flag) {
        return;
      }
    }

    this.rangeAttr = [];
    this.selectedRangeEntityData?.nslAttributes?.forEach((attr: any) => {
      if (attr.checked) {
        if (this.range) {
          this.rangeAttr.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
            version: this.entityData.version,
          });
        }
      }
    });
    this.searchFieldAttr = [];
    this.selectedSearchFieldEntityData?.nslAttributes?.forEach((attr: any) => {
      /*istanbul ignore next*/
      if (attr?.checked) {
        /* istanbul ignore next */
        if (this.searchFields) {
          /* istanbul ignore next */
          if (attr?.selectedRefValue && attr?.selectedRefAttrValue) {
            this.searchFieldAttr.push({
              attributeId: attr.id,
              selectedRefValue: {
                displayName: attr.selectedRefValue.displayName,
                id: attr.selectedRefValue.id,
                name: attr.selectedRefValue.name,
              },
              selectedRefAttrValue: {
                displayName: attr.selectedRefAttrValue.displayName,
                id: attr.selectedRefAttrValue.id,
                name: attr.selectedRefAttrValue.name,
              },
              attributeDisplayName: attr.displayName,
              attributeName: attr.name,
              id: this.entityData.id,
              masterId: this.entityData.masterId,
              name: this.entityData.name,
              version: this.entityData.version,
              uielement: attr.attributeType.uiElement.uiElement,
            });
          } else {
            this.searchFieldAttr.push({
              attributeId: attr.id,
              attributeName: attr.name,
              id: this.entityData.id,
              masterId: this.entityData.masterId,
              name: this.entityData.name,
              version: this.entityData.version,
              selectedRefAttrValue: null,
              selectedRefValue: null,
              uielement: attr.attributeType.uiElement.uiElement,
            });
          }
        }
      }
    });
    this.searchAttr = [];
    /* istanbul ignore next */
    this.selectedSearchEntityData?.nslAttributes?.forEach((attr: any) => {
      /*istanbul ignore next*/
      if (attr?.checked) {
        /* istanbul ignore next */
        if (this.search) {
          /* istanbul ignore next */
          this.searchAttr.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
            version: this.entityData.version,
          });
        }
      }
    });

    if (this.locationFilter) {
      this.locationFilterAttr = [];
      this.selectedLocationFilterData?.nslAttributes?.forEach((attr: any) => {
        if (this.locationFilterData === attr.name && attr?.locationData) {
          this.locationFilterAttr.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
            version: this.entityData.version,
            radius: attr.locationData,
          });
        }
      });
    }
    /*istanbul ignore next*/
    if (this.sort) {
      let flagSort = false;
      this.selectedSortEntityData.nslAttributes.forEach((attr: any) => {
        if (attr.checked) {
          flagSort = true;
        }
      });
      if (!flagSort) {
        this.alertService.showToaster(
          'Please Select Attributes for Sort',
          '',
          'error'
        );
        return;
      }
    }
    /*istanbul ignore next*/
    if (this.filter) {
      let filterSort = true;
      this.groupFilterData.forEach((group: any) => {
        if (group.save === false) {
          filterSort = false;
        }
      });
      if (!filterSort || this.groupFilterData.length == 0) {
        this.alertService.showToaster(
          'Please Select Attributes for Filter',
          '',
          'error'
        );
        return;
      }
    }
    /*istanbul ignore next*/
    if (this.range) {
      let flagRange = false;
      this.selectedRangeEntityData.nslAttributes.forEach((attr: any) => {
        if (attr.checked) {
          flagRange = true;
        }
      });
      if (!flagRange) {
        this.alertService.showToaster(
          'Please Select Attributes for Range',
          '',
          'error'
        );
        return;
      }
    }

    if (
      this.dropActionBtsExpand &&
      this.dropMappedActionBts &&
      this.applySameToExpand
    ) {
      this.dropMappedActionBts.forEach((btnName: string, childIndex: any) => {
        Object.values(this.dropActionBtsExpand).forEach(
          (actionButton: any, expandIndex: any) => {
            if (btnName == actionButton.name) {
              if (actionButton?.position) {
                this.expandBtnColors[
                  actionButton?.position - 1
                ] = this.btnColors[childIndex];
              } else {
                this.expandBtnColors[expandIndex] = this.btnColors[childIndex];
              }
            }
          }
        );
      });
    }
    if (this.dropEntityExpandData && this.applySameToExpand) {
      for (let i = 0; i < 49; i++) {
        this.dropExpandColors[i] = 'black';
        this.droppedStylesExpand[i] = {};
      }
      Object.keys(this.dropEntityExpandData)?.forEach((key: any) => {
        this.dropMappedAttributes.forEach((attr: any, index: any) => {
          if (attr === this.dropEntityExpandData[key]) {
            const num = typeof key === 'string' ? parseInt(key) - 1 : key - 1;
            this.dropExpandColors[num] = this.droppedColors[index];
            this.droppedStylesExpand[num] = this.droppedStyles[index];
          }
        });
      });
    }

    this.expandKeysArray?.forEach((key: any) => {
      this.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
        if (attr.name === this.dropEntityExpandData[key]) {
          attr['isDisplayed'] = true;
        }
      });
    });
    this.groupFilterData.forEach((group: any, index: number) => {
      const name = group.name + 'slot:-' + index;
      this.groupFilterDataBy[name] = [];
      group?.selectedEntityData?.nslAttributes?.forEach((groupAttr: any) => {
        if (groupAttr.checked == true) {
          this.groupFilterDataBy[name].push({
            attributeId: groupAttr.id,
            attributeName: groupAttr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
            version: this.entityData.version,
          });
        }
      });
    });

    this.getAttributeTypes();
    this.hyperlinkMap.forEach((value: any, key: any) => {
      this.hyperlinkArray.push({
        attrName: key,
        link: value,
      });
    });
    this.expandHyperlinkMap.forEach((value: any, key: any) => {
      this.expandHyperlinkArray.push({
        attrName: key,
        link: value,
      });
    });
    if(!this.expandDynamicCard) this.getDropEntityExpandData();
    this.getDropMappedAttributes();

    this.getAddOnAttributes();
    this.dropMappedActionBts = [];
    if (this.childGsiMapping) {
      Object.values(this.childGsiMapping).forEach((gsi: any) => {
        this.dropActionBts.push(gsi);
        this.dropMappedActionBts.push(gsi?.name);
      });
    }
    this.getCartDetails();
    this.setStyles();

    const tempTypeObj = this.getTemplateType(
      this.templateType,
      this.expandType
    );
    if (this.renderDynamiTemplate) {
      this.getMappedAttributes();
    }
    if (this.dropEntityExpandDraggableAttributesData) {
      Object.keys(this.dropEntityExpandDraggableAttributesData)?.forEach((key: any) => {
        this.conditionArrayExpand.forEach((attr: any, index: any) => {
          if (attr.attribute === this.dropEntityExpandDraggableAttributesData[key]) {
            attr['index'] = typeof key === 'string' ? parseInt(key) : key;
          }
        });
      });
    }
    if (this.dropEntityExpandDraggableAttributesData && this.applySameToExpand) {
      this.conditionArrayExpand = [];
      Object.keys(this.dropEntityExpandDraggableAttributesData)?.forEach((key: any) => {
        this.conditionArray.forEach((attr: any, index: any) => {
          if (attr.attribute === this.dropEntityExpandDraggableAttributesData[key]) {
            this.conditionArrayExpand.push({
              attribute: attr.attribute,
              color: attr.color,
              condition: attr.condition,
              index: typeof key === 'string' ? parseInt(key) : key,
              value: attr.value,
            });
          }
        });
      });
    }
    /*istanbul ignore next*/
    let gsiactType: any[] = [];
    /*istanbul ignore next*/
    let req: any = {
      apiVersion: this.apiVersion,
      cardName: tempTypeObj.cardName,
      configurationName: this.cardConfigName,
      cartView: this.cartView,
      childView: {
        attributeList: [],
        gsiList: [],
        isMultiAttributeExpan: this.isMultiAttributeExpand,
        numberOfRows: this.numberOfRows,
        style: {
          attr:
            this.dropMappedDragabbleAttributes?.length > 0
              ? this.dropMappedDragabbleAttributes
              : this.dropMappedAttributes,
          attributeTypes: this.attributeTypes,
          nestedRowUiTypes: this.nestedRowUiTypes,
          otherStyles: this.droppedStyles,
          btnColors: this.btnColors,
          cardBackground: this.cardColor,
          color: this.droppedColors,
          conditionArray: this.conditionArray,
          gsiact: this.dropMappedActionBts,
          gsiactType: gsiactType,
          defaultViewType: this.tabSelected,
          cardBoxShadow: this.boxShadowData,
          cardBorderSize: this.borderSize,
          cardBorderRadius: this.borderRadius,
          cardBorderColor: this.selectedBorderColor,
          hoveredColors: this.hoverColors,
          cardHoverColor: this.cardHoverColor,
          cardGsiMap: this.cardGsiMap,
          gsiAttrMap: this.gsiAttrMap,
          hyperlinkAttribute: this.hyperlinkArray,
          hyperlinkCtaDropArray: this.hyperlinkCtaDropArray,
          kanbanConfiguration: this.setKanbanConfiguration(),
          entityCardFlip: this.entityCardFlip,
          btnHoverColors: this.btnHoverColors,
        },
        templateType: !this.renderDynamiTemplate
          ? tempTypeObj.templateType
          : 'dynamic-' + this.selectedDynamicTemplate.id,
      },
      enableAnalytics: this.enableAnalytics,
      expandedView: {
        attributeList: [],
        gsiList: [],
        style: {
          templateType: this.expandTemplateType,
          conditionArrayExpand: this.conditionArrayExpand,
          dropExpandColors: this.dropExpandColors,
          expandBackground: this.applySameToExpand
            ? this.cardColor
            : this.expandBackground
            ? this.expandBackground
            : '',
          expandBtnColors: this.expandBtnColors,
          expandBoxShadow: this.boxShadowData,
          droppedStylesExpand: this.droppedStylesExpand,
          expandTemplateTypeData:Object.keys(this.dropEntityExpandData).length>0? this.dropEntityExpandData: this.dropEntityExpandDraggableAttributesData,
          gsiExpandData: this.dropActionBtsExpand
            ? this.dropActionBtsExpand
            : {},
          gsiAttrMapExpand: this.gsiAttrMapExpand,
          expandPopup: this.isExpandPopup,
          expandHoveredColors: this.hoverColorsExpand,
          expandCardHoveredColor: this.expandHoverBackground,
          expandHyperlinkAttributes: this.expandHyperlinkArray,
          expandBtnHoverColors: this.expandBtnHoverColors,
        },
      },
      gsiList: [],
      locationFilter: this.locationFilterAttrData(),
      range: [],
      search: this.searchAttrData(),
      searchFields: this.searchFieldsAttrData(),
      filters: this.filterAttrData(),
      joinCriteria: {},
      joinedEntities: [],
      primaryEntity: {
        id: this.entityData.id,
        masterId: this.entityData.masterId,
        name: this.entityData.name,
        version: this.entityData.version,
      },
      roleId: this.selectedRoleId,
      sort: {
        sortOption: this.sortOption == '1' ? 'Ascending' : 'Descending',
        sortBy: this.sortAttrData(),
      },
      expandTemplateType: this.expandTemplateType,
      filterDesign: this.preparePayLoadForFilterSearch(),
      defaultFilter: this.hiddenRuleSet?.[0],
      display: this.displayFieldsAttrData(),
      id: this.selectedEditCardID ? this.selectedEditCardID : '',
    };
    this.dcdList.forEach((dcd) => {
      dcd.sourceContextualName = dcd.sourceContextualName.split(
        'undefined.'
      )[1];
      dcd.sourceContextualId = dcd.sourceContextualId.split('undefined.')[1];
    });
    this.cartDcdList.forEach((dcd: any) => {
      dcd.sourceContextualName = dcd.sourceContextualName.split(
        'undefined.'
      )[1];
      dcd.sourceContextualId = dcd.sourceContextualId.split('undefined.')[1];
    });

    /*istanbul ignore next*/
    if(this.isKanbanBoard){
      this.nslAttributes.forEach((attr: any) => {
          req.childView.attributeList.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
          });
      });
    } else {
      /*istanbul ignore next*/
      this.draggableAttributes.forEach((attr: any) => {
        if (attr.selectedInChild) {
          req.childView.attributeList.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
          });
        }
      });
    }
    this.expandKeysArray?.forEach((key: any) => {
      this.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
        if (attr.name === this.dropEntityExpandData[key]) {
          req.childView.attributeList.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
            version: this.entityData.version,
          });
        }
      });
    });
    if (
      this.templateType == 141 ||
      this.templateType == 300 ||
      this.templateType == 330
    ) {
      let gsi = this.selectGsiList[0];
      req.gsiList.push({
        id: gsi.id,
        masterId: gsi.masterId,
        name: gsi.name,
        version: gsi.version,
        displayName: gsi.displayName,
        dcds: this.dcdList?.filter((d) => d?.name == gsi?.name),
        popUp: gsi.enablePopup,
      });
    }

    /*istanbul ignore next*/
    if (this.dropActionBts) {
      let findGsi: any[] = this.dropActionBts?.filter(
        (gsi: any) => gsi?.name !== 'Action Button'
      );
      if (findGsi && findGsi.length > 0) {
        findGsi.forEach((gsi: any) => {
          req.childView.gsiList.push({
            id: gsi.id,
            masterId: gsi.masterId,
            name: gsi.name,
            version: gsi.version,
            displayName: gsi.displayName,
            dcds: this.dcdList?.filter((d) => d?.name == gsi?.name),
            popUp: gsi.enablePopup,
          });
          gsiactType.push({
            entityType: gsi.entityType,
          });
        });
      }

      if (findGsi && findGsi.length > 0) {
        findGsi.forEach((gsi: any) => {
          req.gsiList.push({
            id: gsi.id,
            masterId: gsi.masterId,
            name: gsi.name,
            version: gsi.version,
            displayName: gsi.displayName,
            dcds: this.dcdList?.filter(
              (d) => d?.targetContextualName?.split('.')?.[0] == gsi?.name
            ),
            popUp: gsi.enablePopup,
          });
        });
        req.childView.style.gsiact = req.gsiList;
      }
    }
    /*istanbul ignore next*/
    this.groupFilterData.forEach((group: any) => {
      group.selectedEntityData.nslAttributes.forEach((groupAttr: any) => {
        this.selectedEntityData.nslAttributes.forEach((attr: any) => {
          if (attr.name === groupAttr.name && attr.checked == false) {
            attr.checked = groupAttr.checked;
          }
        });
      });
    });

    /*istanbul ignore next*/

    this.selectedEntityData.nslAttributes.forEach((attr: any) => {
      if (attr.sort.checked) {
        if (this.sort) {
          req.sort.sortBy.push({
            attributeId: attr.id,
            attributeName: attr.name,
            id: this.entityData.id,
            masterId: this.entityData.masterId,
            name: this.entityData.name,
            version: this.entityData.version,
          });
        }
      }
    });
    /*istanbul ignore next*/
    if(this.isKanbanBoard){
      this.finalSaveConstraints(req);
    } else {
      /*istanbul ignore next*/
        this.entityBoardService.saveEntityCard(req).subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.router.navigate(['/cdui/mylibrary-dashboard']);
      });
    }
  }
  getAddOnAttributes() {
    this.hyperlinkArray = [];
    this.draggableAttributes.forEach((attr, ind) => {
      if (attr?.addOnChild) {
        if (attr?.addOnChild?.type == 'GSI') {
          let index = this.childGsiList.findIndex(
            (gsi) => gsi.name == attr?.addOnChild?.value
          );
          if (index != -1) {
            this.gsiAttrMap[attr?.childPosition] = this.childGsiList[index];
          }
        } else if (attr?.addOnChild.type == 'hyperlink') {
          let index = this.draggableAttributes.findIndex(
            (drag) => drag?.name == attr?.addOnChild?.value
          );
          if (index != -1) {
            this.hyperlinkMap.set(
              attr?.childPosition,
              this.draggableAttributes[index]?.name
            );
          }
        }
      }
    });
    for (let i = 0; i <= this.childMaxPosition; i++) {
      if (this.hyperlinkMap.has(i))
        this.hyperlinkArray.push(this.hyperlinkMap.get(i));
      else this.hyperlinkArray.push(false);
    }
  }
  setStyles() {
    for (let i = 0; i <= this.childMaxPosition; i++) {
      let attr = this.styleAttribute?.[i];
      let otherStyle = {};

      if (this.dropMappedDragabbleAttributes[i] && attr) {
        if (attr.fontFamily) {
          otherStyle['font-family'] = attr.fontFamily;
        }
        if (attr.fontSize) {
          otherStyle['font-size'] = attr.fontSize;
        }
        if (attr.isItalic) {
          otherStyle['font-style'] = 'italic';
        }
        if (attr.isBold) {
          otherStyle['font-weight'] = 'bold';
        }
        if (attr.alignment) {
          otherStyle['text-align'] = attr.alignment;
        }
        if (attr.attrColor) {
          otherStyle['color'] = attr.attrColor;
        }
      } else if (attr) {
        attr.attrColor = '';
        attr.attHoverColor = '';
      }
      this.droppedColors?.push(attr?.attrColor);
      this.hoverColors?.push(attr?.attHoverColor);
      this.droppedStyles?.push(otherStyle);
    }

    this.styleGsi?.forEach((style: any, index: number) => {
      if (this.dropMappedActionBts?.[index]) {
        this.btnColors?.push(style?.color);
        this.btnHoverColors.push(style?.hoverColor);
      } else {
        this.btnColors?.push('');
        this.btnHoverColors.push('');
      }
    });
    this.expandStyleGsi?.forEach((style: any, index: number) => {
      if (this.dropActionBtsExpand?.['action-' + index]) {
        this.expandBtnColors?.push(style?.color);
        this.expandBtnHoverColors?.push(style?.hoverColor);
      } else {
        this.expandBtnColors?.push('');
        this.expandBtnHoverColors?.push(style?.color);
      }
    });
    this.cardColor = this.styleCard.color;
    this.cardHoverColor = this.styleCard.hoverColor;

    for (let i = 0; i <= this.expandMaxPosition + 1; i++) {
      let attr = this.expandStyleAttribute[i];
      let otherStyle = {};
      if (this.dropEntityExpandDraggableAttributesData[i] && attr) {
        if (attr.fontFamily) {
          otherStyle['font-family'] = attr.fontFamily;
        }
        if (attr.fontSize) {
          otherStyle['font-size'] = attr.fontSize;
        }
        if (attr.isItalic) {
          otherStyle['font-style'] = 'italic';
        }
        if (attr.isBold) {
          otherStyle['font-weight'] = 'bold';
        }
        if (attr.alignment) {
          otherStyle['text-align'] = attr.alignment;
        }
        if (attr.attrColor) {
          otherStyle['color'] = attr.attrColor;
        }
      } else if (attr) {
        attr.attrColor = '';
        attr.attHoverColor = '';
      }
      this.dropExpandColors?.push(attr?.attrColor);
      this.hoverColorsExpand?.push(attr?.attHoverColor);
      this.droppedStylesExpand?.push(otherStyle);
    }
    this.expandBackground = this.expandCardStyle?.color;
    this.expandHoverBackground = this.expandCardStyle?.hoverColor;
    this.borderSize = this.borderSize
      ? this.borderSize
      : this.styleCard?.borderWidth;
    this.selectedBorderColor = this.selectedBorderColor
      ? this.selectedBorderColor
      : this.styleCard?.borderColor;
    this.borderRadius = this.borderRadius
      ? this.borderRadius
      : this.styleCard?.borderRadius;
    this.boxShadowData = this.boxShadowData
      ? this.boxShadowData
      : this.styleCard?.boxShadow;
  }
  private getDropMappedAttributes(): any {
    this.dropMappedDragabbleAttributes = [];
    let maxPostion: number = 0;
    this.draggableAttributes?.forEach((attr: any) => {
      if (maxPostion < attr.childPosition) {
        maxPostion = attr.childPosition;
      }
    });
    this.childMaxPosition = maxPostion;
    for (let i = 0; i < maxPostion; i++) {
      this.dropMappedDragabbleAttributes[i] = false;
    }
    this.draggableAttributes?.forEach((attr: any) => {
      if (attr.childPosition != -1) {
        this.dropMappedDragabbleAttributes[attr.childPosition] = attr.name;
      }
    });

    return this.dropMappedDragabbleAttributes;
  }

  private getDropEntityExpandData(): any {
    this.dropEntityExpandDraggableAttributesData = {};
    this.dropActionBtsExpand = {};
    let maxPostion: number = 0;
    this.draggableAttributes?.forEach((attr: any) => {
      if (attr.expandPosition != -1) {
        if (maxPostion < attr.expandPosition) {
          maxPostion = attr.expandPosition;
        }
        this.dropEntityExpandDraggableAttributesData[attr.expandPosition + 1] =
          attr.name;
      }
    });
    this.expandGsiList.forEach((attr: any) => {
      if (attr?.position != -1) {
        this.dropActionBtsExpand['action-' + attr.position] = attr;
      }
    });
    this.expandMaxPosition = maxPostion;
    return this.dropEntityExpandDraggableAttributesData;
  }
  private chatBotData(): boolean {
    let isValid = true;
    /* istanbul ignore next */
    if (!this.chatbotData?.language) {
      this.alertService.showToaster('Please select language', '', 'error');
      isValid = false;
      return true;
    }
    /* istanbul ignore next */
    if (this.chatbotData?.intents?.length === 0) {
      this.alertService.showToaster(
        'Please add atleast one intent',
        '',
        'error'
      );
      isValid = false;
      return true;
    }
    /* istanbul ignore next */
    this.chatbotData?.intents?.forEach((intent: any, intentIndex: any) => {
      /* istanbul ignore next */
      if (!intent?.intent) {
        this.alertService.showToaster(
          `Please write intent for Intent ${intentIndex + 1}`,
          '',
          'error'
        );
        isValid = false;
        return true;
      }
      intent.filters = [];
      intent.filter_range_fixed = [];
      let anyAttrSelected = true;
      /* istanbul ignore next */
      intent?.attributes?.forEach((attr: any) => {
        /* istanbul ignore next */
        if (attr?.checked) {
          anyAttrSelected = true;
          intent.filters.push(attr.attributeName);
          intent.filter_range_fixed.push(attr.range);
        }
      });
      /* istanbul ignore next */
      if (!anyAttrSelected) {
        this.alertService.showToaster(
          `Please select atleast one attribute for Intent ${intentIndex + 1}`,
          '',
          'error'
        );
        isValid = false;
        return true;
      }
      /* istanbul ignore next */
      if (intent?.attributes) {
        delete intent.attributes;
      }
    });
    /* istanbul ignore next */
    if (isValid) {
      delete this.chatbotData.isSelected;
      let TenantName = localStorage.getItem('TenantName');
      /* istanbul ignore next */
      this.rolesFacadeService
        .saveEntityCardConfigureChatbot(TenantName, this.chatbotData)
        .subscribe((res: any) => {
          /* istanbul ignore next */
          if (res) {
            this.alertService.showToaster(res?.message, '', 'success');
          }
        });
    } else {
      return true;
    }

    return false;
  }
  /*istanbul ignore next*/
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedSearchFieldEntityData.nslAttributes,
      event.previousIndex,
      event.currentIndex
    );
  }

  filterAttrData() {
    let filterData: any[] = [];
    this.selectedEntityData.nslAttributes.forEach((groupAttr: any) => {
      if (groupAttr['filter'].checked == true) {
        filterData.push({
          attributeId: groupAttr.id,
          attributeName: groupAttr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });
    const name = 'filterslot:-0';
    const filetrAttrData = {
      'filterslot:-0': filterData,
    };
    /*istanbul ignore else*/
    if (filterData.length > 0) {
      return filetrAttrData;
    } else {
      return {};
    }
  }

  searchFieldsAttrData() {
    let serachFieldsAttrData: any[] = [];
    this.selectedEntityData.nslAttributes.forEach((groupAttr: any) => {
      if (groupAttr['searchFields'].checked == true) {
        serachFieldsAttrData.push({
          attributeId: groupAttr.id,
          attributeName: groupAttr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });
    return serachFieldsAttrData;
  }
  displayFieldsAttrData() {
    let displayFieldsAttrData: any[] = [];
    this.selectedEntityData?.nslAttributes?.forEach((groupAttr: any) => {
      if (
        groupAttr?.isDisplayed &&
        !groupAttr['searchFields'].checked &&
        !groupAttr['filter'].checked &&
        !groupAttr['search'].checked &&
        !groupAttr['sort'].checked
      ) {
        displayFieldsAttrData.push({
          attributeId: groupAttr.id,
          attributeName: groupAttr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });

    return displayFieldsAttrData;
  }
  searchAttrData() {
    let serachAttrData: any[] = [];
    this.selectedEntityData.nslAttributes.forEach((groupAttr: any) => {
      if (groupAttr['search'].checked == true) {
        serachAttrData.push({
          attributeId: groupAttr.id,
          attributeName: groupAttr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });
    return serachAttrData;
  }
  sortAttrData() {
    let sortAttrData: any[] = [];
    this.selectedEntityData.nslAttributes.forEach((groupAttr: any) => {
      if (groupAttr['sort'].checked == true) {
        sortAttrData.push({
          attributeId: groupAttr.id,
          attributeName: groupAttr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });
    return sortAttrData;
  }
  locationFilterAttrData() {
    let locationFilterAttrData: any[] = [];
    this.selectedEntityData?.nslAttributes.forEach((groupAttr: any) => {
      if (groupAttr['locationFilter'].checked == true) {
        locationFilterAttrData.push({
          attributeId: groupAttr.id,
          attributeName: groupAttr.name,
          id: this.entityData.id,
          masterId: this.entityData.masterId,
          name: this.entityData.name,
          version: this.entityData.version,
        });
      }
    });
    return locationFilterAttrData;
  }

  /* ---- cart related Functionality ------ */
  /**
   * on double click the CartButton input will be shown to edit the Button Name.
   */
  editButtonName() {
    this.showButtonNameEdit = true;
  }
  closeEditButtonName() {
    this.showButtonNameEdit = false;
  }

  public changeTab(tab: 'child' | 'expand' | 'cart') {
    this.tabSelected = tab;
  }
  dblClickButtonInCard(event: MouseEvent) {
    console.log(event);
  }

  /**
   * The function removes particular GSI from action BUtton.
   * @param {number} index - The index parameter is a number that represents the button position
   */
  removeGsiFromCard(index: number, childGsiMapping?: { [key: number]: GsiButton }) {
    /*istanbul ignore else */
    if (
      childGsiMapping[index].entityType === 'GSI' ||
      childGsiMapping[index].entityType === 'PAGE'
    ) {
      childGsiMapping[index].isSelected = false;
      delete childGsiMapping[index];
    } else if (this.dragAttrType == 'hyperlink') {
      this.hyperLinkAttributes.push(childGsiMapping[index]);
      childGsiMapping[index].isSelected = false;
      delete childGsiMapping[index];
    } else {
      childGsiMapping[index].isSelected['child'].isSelected = false;
      delete childGsiMapping[index];
    }
  }
  /**
   * This function enables dropped attributes on a template and updates their position and selection
   * status.
   * @param {any} event - The event parameter is an object that represents  a drag and drop event.
   * TODO: add support for child and expand templates attributes
   */
  enableDroppedAttributeOnTemplate(event: any, type?: any) {
    let attributeList: DraggableAttribute[] = [];
    /*istanbul ignore else */
    if (this.tabSelected == 'cart') {
      attributeList = this.cartAttributes;
      const data: string = event.dataTransfer.getData('text');
      const i: number = parseInt(data.split('-')[1]);
      let draggedAttribute: DraggableAttribute = attributeList[i];
      /*istanbul ignore next */

      if (
        type == 'currency' &&
        draggedAttribute?.attributeType?.type != 'currency' &&
        draggedAttribute?.attributeType?.type != 'number' &&
        draggedAttribute?.attributeType?.type != 'decimalnumber'
      ) {
        this.alertService?.showToaster(
          `attribute of type currency,decimalnumber,number only can be dropped `,
          'error',
          'error'
        );
        return;
      } else if (
        !(draggedAttribute.attributeType.type == type) &&
        type != 'any' &&
        type != 'currency'
      ) {
        /*istanbul ignore next */
        this.alertService.showToaster(
          `attribute of type ${type} only can be dropped `,
          'error',
          'error'
        );
        return;
      }
      draggedAttribute.isSelected = true;
      /* istanbul ignore next*/
      const droppedPosition: number = parseInt(event.target.id.split('-')[1]);
      if (type == 'currency') {
        this.cartPricePosition = droppedPosition;
      }
      draggedAttribute.position = droppedPosition;
      this.cartAttributesPositionMap[droppedPosition] = draggedAttribute;
    }
  }

  /**
   * The function prepares draggable attributes from nslattributes.
   * @param {Attribute[]} nslAttributes - An array of Attribute objects that contain information about
   * attributes in a system.
   * @returns an array of `DraggableAttribute` objects.
   */
  prepareDraggableAttributes(nslAttributes: Attribute[]): DraggableAttribute[] {
    // throw new Error('Function not implemented.');
    let attributes: DraggableAttribute[] = [];
    nslAttributes.forEach((attribute: Attribute) => {
      let draggableAttribute: DraggableAttribute = this.mapAttributeToDraggableAttribute(
        attribute
      );
      attributes.push(draggableAttribute);
    });
    return attributes;
  }
  /**
   * The function maps an Attribute object to a DraggableAttribute object.
   * @param {Attribute} attribute - The parameter "attribute" is of type "Attribute".
   * @returns a DraggableAttribute object.
   */
  mapAttributeToDraggableAttribute(attribute: Attribute): DraggableAttribute {
    let draggableAttribute: DraggableAttribute = {
      name: attribute.name,
      displayName: attribute.name,
      id: attribute.id,
      isSelected: false,
      attributeType: attribute.attributeType,
      uiElement: attribute.attributeType.type,
      position: -1,
      masterId: attribute.id,
      childPosition: -1,
      expandPosition: -1,
      selectedInChild: false,
      selectedInExpand: false,
    };
    return draggableAttribute;
  }

  /**
   * *toggles the expansion state of a cart accordion.
   */
  expandEditCartButton() {
    this.cartAccordionExpanded = !this.cartAccordionExpanded;
  }
  setExpandAttributeDataAfterDeletion(event: any) {
     this.nslAttributesExpand = event;
  }
  fontSizeChange(event: any) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
  }

  openExpandDialog() {
    this.dialog.open(this.expandDialog);
  }
  showExpand() {
    this.isExpand = true;
    this.dialog.closeAll();
  }

  addNewFieldToAttributesArray(
    attr: any,
    type: string,
    checked: boolean,
    disabled: boolean,
    tooltip: string
  ) {
    attr[type] = {
      modelVariable: undefined,
      id: undefined,
      order: undefined,
      checked: checked,
      disabled: disabled,
      tooltip: tooltip,
      attributeRadius: undefined,
      refEntityOpen: false,
      selectedRefValue: undefined,
      selectedRefAttrValue: undefined,
      defaultValue: undefined,
    };
  }

  /**
   * The function prepares a payload for filter search by populating various data structures with the
   * selected entity data.
   * @returns The function `preparePayLoadForFilterSearch()` returns a variable named `filterDesign`,
   * which is an object of type `FilterDesignTemplate`.
   */
  preparePayLoadForFilterSearch() {
    let filterData: Filter = {
      attributeDetails: [],
      filterConfiguration: this.filterConfiguration(),
    };
    let sortData: IndividualSort = {
      attributeDetails: [],
      sortConfigurations: this.sortConfiguration(),
    };
    let searchData: Search = {
      attributeDetails: [],
      filterTemplate: undefined,
      searchConfiguration: this.searchConfiguration(),
    };
    let locationFilterData: LocationFilter = {
      attributeDetails: [],
    };
    this.setDataInNSLAttributes(
      this.selectedEntityData.nslAttributes,
      filterData,
      sortData,
      searchData,
      locationFilterData
    );
    const filterDesign: FilterDesignTemplate = {
      hiddenFilterRule: this.hiddenRuleSet,
      filter: filterData,
      sort: this.setSortDataInPayload(this.combinationArray, sortData),
      searchFields: this.setSearchFieldsData(),
      search: searchData,
      locationFilter: locationFilterData,
      // layout: {
      //   horizontalLayout: this.horizontalLayout,
      //   verticalLayout: this.verticalLayout,
      //   templateType: this.layoutTemplateType,
      //   style: this.layoutStyle,
      // },
      export: {
        pdf: this.checkToggleableFeatures('export', 'checkBoxValues', 'pdf'),
        excel: this.checkToggleableFeatures(
          'export',
          'checkBoxValues',
          'excel'
        ),
        csv: this.checkToggleableFeatures('export', 'checkBoxValues', 'csv'),
      },
      urlBindingData: this.urlBindingDetails(),
    };
    return filterDesign;
  }

  /**
   * The function takes in various data attributes and populates different arrays based on the checked
   * properties of each attribute.
   * @param {any} attributes - An array of objects representing attributes. Each object has the
   * following properties:
   * @param {Filter[]} filterData - An array of objects representing filter data. Each object should
   * have the following properties:
   * @param {attributeDetails[]} sortData - An array of objects containing attribute details for
   * sorting. Each object should have the following properties:
   * @param {Search} searchData - An object that contains the search data. It has the following
   * properties:
   * @param {LocationFilter[]} locationFilterData - An array of objects containing location filter
   * data. Each object should have the following properties:
   */
  setDataInNSLAttributes(
    attributes: any,
    filterData: Filter,
    sortData: IndividualSort,
    searchData: Search,
    locationFilterData: LocationFilter
  ) {
    attributes.forEach((attr: any) => {
      const attrData = {
        attributeName: attr?.name,
        attributeDisplayName: attr?.displayName,
      };
      /*istanbul ignore else*/
      if (attr.filter.checked && this.advancedFeatures['filter'].checked) {
        const filterDataConst = {
          ...attrData,
          filterTemplate: attr['filter']?.id,
          defaultValue: attr['filter']?.defaultValue,
          divisions: attr['filter']?.divisions,
        };
        filterData.attributeDetails.push(filterDataConst);
      }
      /*istanbul ignore else*/
      if (attr.search.checked && this.advancedFeatures['search'].checked) {
        const searchFieldsDataConst = { ...attrData };
        searchData.attributeDetails.push(searchFieldsDataConst);
        searchData.filterTemplate = attr['search']?.id;
      }
      /*istanbul ignore else*/
      if (attr.sort.checked && this.advancedFeatures['sort'].checked) {
        const sortDataConst = { ...attrData };
        sortData.attributeDetails.push(sortDataConst);
      }
      /*istanbul ignore else*/
      if (attr.locationFilter.checked && this.advancedFeatures['locationFilter'].checked) {
        const locationFilterDataConst = {
          ...attrData,
          radius: attr['locationFilter']?.attributeRadius,
        };
        locationFilterData.attributeDetails.push(locationFilterDataConst);
      }
    });
  }

  /**
   * The function takes an array of combinations and an array of sort data, and creates a payload
   * object with the combination and sort data.
   * @param {any} combinationArray - An array of combinations, where each combination has the following
   * properties:
   * @param {attributeDetails[]} sortData - The `sortData` parameter is an array of `attributeDetails`
   * objects. Each `attributeDetails` object represents an attribute that needs to be sorted. It has
   * the following structure:
   * @returns a payload object of type Sort.
   */
  setSortDataInPayload(combinationArray: any, sortData: IndividualSort): Sort {
    const combinationPayload: Combination[] = [];
    combinationArray.forEach((combination: any) => {
      const attributesArray: attributeDetails[] = [];
      combination.nslAttributes.forEach((attr: any) => {
        if (attr.sort.checked) {
          const attributeDetails: attributeDetails = {
            attributeName: attr.name,
          };
          attributesArray.push(attributeDetails);
        }
      });
      const combi: Combination = {
        attributes: attributesArray,
        filterTemplate: combination.id,
        combinationName: combination.name,
      };
      combinationPayload.push(combi);
    });
    const sortPayload: Sort = {
      combination: combinationPayload,
      individual: sortData,
    };
    return sortPayload;
  }

  /**
   * The function "checkForSaveEntity" checks if a boolean value is true, and if so, assigns various
   * properties from an event object and calls the "saveEntityCard" function; otherwise, it sets some
   * other properties.
   * @param {any} event - The "event" parameter is of type "any", which means it can be any type of
   * data. It is likely an object that contains various properties and values.
   */
  checkForSaveEntity(event: any) {
    if (event.boolean) {
      this.horizontalLayout = event.horizontalLayout;
      this.verticalLayout = event.verticalLayout;
      this.layoutTemplateType = event.layoutTemplateType;
      this.layoutStyle = event.style;
      this.searchFieldsData = event.searchFieldsData;
      this.saveEntityCard();
    } else {
      this.isAdvancedFeature = true;
      this.canvasDesignPage = false;
    }
  }

  /**
   * The function setSearchFieldsData() returns an array of search field details based on the checked
   * attributes in the searchFieldsData array.
   * where Search Fields filter ID and Reference Entity Details are Stored in that.
   * @returns The function `setSearchFieldsData()` returns an array of `SearchFieldDetails` objects.
   */
  setSearchFieldsData(): SearchFieldDetails {
    let searchFieldsData: SearchFieldDetails = {
      attributeDetails: [],
      searchFieldsConfiguration: this.searchFieldsConfiguration(),
    };
    this.selectedEntityData.nslAttributes.forEach((attr: any) => {
      if (attr.searchFields.checked) {
        const searchFieldsDataConst = {
          attributeDisplayName: attr?.displayName,
          attributeName: attr?.name,
          filterTemplate: attr['searchFields'].id,
          selectedRefAttrValue: this.selectedRefAttrValueFunc(attr),
          selectedRefValue: this.selectedRefValueFunc(attr),
          selectedNestedForDate: this.nestedDateValue(attr?.['searchFields']),
          uiElement: attr?.attributeType?.uiElement?.uiElement,
        };
        searchFieldsData.attributeDetails.push(searchFieldsDataConst);
      }
    });
    return searchFieldsData;
  }

  private selectedRefAttrValueFunc = (searchField: any) => {
    let selectedRefAttrDisplayName = this.defaultReferenceValue(
      searchField,
      'selectedRefAttrValue',
      'displayName',
      false
    );
    let selectedRefAttrName = this.defaultReferenceValue(
      searchField,
      'selectedRefAttrValue',
      'name',
      false
    );
    let selectedRefAttrId = this.defaultReferenceValue(
      searchField,
      'selectedRefAttrValue',
      'id',
      false
    );
    let selectedRefAttrdefaultValue = searchField['searchFields']?.defaultValue;
    return {
      displayName: selectedRefAttrDisplayName,
      name: selectedRefAttrName,
      id: selectedRefAttrId,
      defaultValue: selectedRefAttrdefaultValue,
    };
  };
  private selectedRefValueFunc = (searchField: any) => {
    let selectedRefDisplayName = this.defaultReferenceValue(
      searchField,
      'selectedRefValue',
      'displayName',
      true
    );
    let selectedRefName = this.defaultReferenceValue(
      searchField,
      'selectedRefValue',
      'name',
      true
    );
    let selectedRefId = this.defaultReferenceValue(
      searchField,
      'selectedRefValue',
      'id',
      true
    );
    return {
      displayName: selectedRefDisplayName,
      name: selectedRefName,
      id: selectedRefId,
    };
  };

  private defaultReferenceValue = (
    searchField: any,
    refValue: string,
    value: string,
    isEntity: boolean
  ) => {
    if (searchField?.['searchFields']?.[refValue]?.[value]?.length > 0) {
      return searchField?.['searchFields']?.[refValue]?.[value];
    } else {
      if (isEntity) {
        return this.entity?.[value];
      } else {
        return searchField?.[value];
      }
    }
  };

  private nestedDateValue = (searchField: any) => {
    return searchField?.selectNestedAttribute?.length > 0 &&
      searchField?.selectNestedAttributeFunction?.length > 0 &&
      searchField?.selectNestedAttributeNoSuffixValue?.length > 0
      ? {
          // !Added select Nested Attribute as Array it supports Array in future we can change the HTML to Multi Select.
          selectNestedAttribute: [searchField?.selectNestedAttribute],
          selectNestedAttributeFunction:
            searchField?.selectNestedAttributeFunction,
          selectedNestedAttributeNoSuffixValue:
            searchField?.selectNestedAttributeNoSuffixValue,
        }
      : undefined;
  };

  /**
   * The function assigns the value of the "event" parameter to the "hiddenRuleSet" property.
   * @param {RuleSet[]} event - The event parameter is an array of RuleSet objects.
   */
  hiddenRuleSetEmit(event: RuleSetCondition) {
    this.hiddenRuleSet = event;
  }

  /**
   * The function assigns the value of the event parameter to the selectedEntityData property.
   * @param {any} event - The parameter "event" is of type "any", which means it can be any type of
   * data. It is used to pass the data emitted by an event to the function.
   */
  selectedEntityDataEmit(event: any) {
    this.selectedEntityData = event;
  }

  /**
   * The function assigns the value of the event parameter to the combinationArray property.
   * @param {any} event - The "event" parameter is of type "any", which means it can be any type of
   * data. It is used to pass the value of an event that is emitted from another component or service.
   * In this case, the value of the event is assigned to the "combinationArray" property of the
   */
  combinationArrayEmit(event: any) {
    this.combinationArray = event;
  }

  /**
   * The function "urlBindingEmit" assigns values from the "event" object to the "urlBindingGsi" and
   * "urlBindingAttribute" variables.
   * @param {any} event - The "event" parameter is an object that contains information about the event
   * that triggered the function. It could be an event object from a user action, such as a button
   * click or a form submission. In this case, it seems like the event object contains two properties:
   * "urlBindingGsi"
   */
  urlBindingEmit(event: any) {
    this.urlBindingGsi = event.urlBindingGsi;
    this.urlBindingAttribute = event.urlBindingAttribute;
    this.urlBindingAttributeColor = event.attributeColor;
    this.isUrlbindingConfigName = event.isConfigName;
  }

  /**
   * The function `advancedFeaturesEmit` updates the `advancedFeatures` property with the value of the
   * `event` parameter and then calls the `saveEntityCard` function.
   * @param {any} event - The "event" parameter is of type "any", which means it can accept any type of
   * value. It is used to pass data or information to the "advancedFeaturesEmit" function.
   * This emmitted at last because All the properties are emitted and assigned to the values
   */
  advancedFeaturesEmit(event: any) {
    this.advancedFeatures = event;
    // Put this in Last Emit of the Drop Advanced Component.

    this.OpenCardName();
  }
  OpenCardName() {
    this.dialog.closeAll();
    this.dialog.open(this.cardNameDialog, {
      panelClass: 'filter-dialog-pop-up',
    });
  }

  /**
   * The function "filterConfiguration" returns a FilterConfiguration object with various properties
   * that are checked and assigned values based on certain conditions.
   * @returns a FilterConfiguration object.
   */
  private filterConfiguration(): FilterConfiguration {
    const filterConfiguration: FilterConfiguration = {
      position: this.checkPosition('filter'),
      toggleView: this.checkToggleableFeatures(
        'filter',
        'checkBoxValues',
        'toggleView'
      ),
      sidePanelBehaviour: this.checkTabValues(
        'filter',
        'tabValues',
        'sidePanelBehaviour'
      ),
      showFiltered: this.checkToggleableFeatures(
        'filter',
        'checkBoxValues',
        'showFiltered'
      ),
      showAggregation: this.checkToggleableFeatures(
        'filter',
        'checkBoxValues',
        'showAggregation'
      ),
      expandView: this.checkToggleableFeatures(
        'filter',
        'checkBoxValues',
        'expandView'
      ),
      uploadIcon: this.checkUploadIcon('filter','icon'),
      uploadHoverIcon : this.checkUploadIcon('filter','hoverIcon')

    };

    return filterConfiguration;
  }
  checkUploadIcon(filter: string,icon:string): string{
    const featureCheck = this.advancedFeatures[filter];
    if (featureCheck.checked) {
      let booleanFlag: string = '';
      featureCheck?.icon.forEach((i: any) => {
        if (i.actualValue === icon && i.checked) {
          booleanFlag = i.value;
        }
      });
      return booleanFlag;
    } 
    return ''
  }

  /**
   * The function `searchFieldsConfiguration` returns an object containing various configuration
   * options for search fields.
   * @returns a variable named `searchFieldsConfiguration` of type `SearchFieldsConfiguration`.
   */
  private searchFieldsConfiguration(): SearchFieldsConfiguration {
    const searchFieldsConfiguration: SearchFieldsConfiguration = {
      position: this.checkPosition('searchFields'),
      showFilters: this.checkToggleableFeatures(
        'searchFields',
        'checkBoxValues',
        'showFilters'
      ),
      showAggregation: this.checkToggleableFeatures(
        'searchFields',
        'checkBoxValues',
        'showAggregation'
      ),
      fieldsWrap: this.checkToggleableFeatures(
        'searchFields',
        'checkBoxValues',
        'fieldsWrap'
      ),
      saveUserFilters: this.checkToggleableFeatures(
        'searchFields',
        'toggleValues',
        'saveUserFilters'
      ),
      accordionView: this.checkToggleableFeatures(
        'searchFields',
        'toggleValues',
        'accordionView'
      ),
      additionalSearchFields: this.checkToggleableFeatures(
        'searchFields',
        'toggleValues',
        'additionalSearchFields'
      ),
      saveSharableFilters: this.checkToggleableFeatures(
        'searchFields',
        'toggleValues',
        'saveSharableFilters'
      ),
      alignment: this.checkInputFeatures('searchFields', 'styles', 'alignment'),
    };

    return searchFieldsConfiguration;
  }

  /**
   * The function "sortConfiguration" returns a SortConfiguration object with properties "position" and
   * "expandView" based on the results of the "checkPosition" and "checkToggleableFeatures" functions.
   * @returns a SortConfiguration object.
   */
  private sortConfiguration(): SortConfiguration {
    const sortConfiguration: SortConfiguration = {
      position: this.checkPosition('sort'),
      expandView: this.checkToggleableFeatures(
        'sort',
        'checkBoxValues',
        'expandView'
      ),
      uploadIcon: this.checkUploadIcon('sort','icon'),
      uploadHoverIcon : this.checkUploadIcon('sort','hoverIcon')

    };

    return sortConfiguration;
  }

  /**
   * The function "searchConfiguration" returns an object containing various configuration options for
   * a search feature.
   * @returns a SearchConfiguration object.
   */
  private searchConfiguration(): SearchConfiguration {
    const searchConfiguration: SearchConfiguration = {
      position: this.checkPosition('search'),
      showHeaderSearch: this.checkToggleableFeatures(
        'search',
        'toggleValues',
        'showHeaderSearch'
      ),
      placeHolderText: this.checkInputFeatures(
        'search',
        'inputValues',
        'placeHolderText'
      ),
      showSavedSearch: this.checkToggleableFeatures(
        'search',
        'toggleValues',
        'showSavedSearch'
      ),
      uploadIcon: this.checkUploadIcon('search','icon'),
      uploadHoverIcon : this.checkUploadIcon('search','hoverIcon')
    };

    return searchConfiguration;
  }

  /**
   * The function checks the position of a given feature and returns the corresponding position if it
   * is checked.
   * @param {string} feature - The parameter "feature" is a string that represents a specific feature.
   * @returns a string value indicating the position of the feature. The possible return values are
   * 'side', 'header', or 'top'.
   * This function returns which side the Advanced Feature should be.
   */
  private checkPosition(feature: string): string {
    const featureCheck = this.advancedFeatures[feature];
    if (featureCheck.checked) {
      if (featureCheck.side.checked) {
        return 'side';
      } else if (featureCheck.header.checked) {
        return 'header';
      } else if (featureCheck.top.checked) {
        return 'top';
      }
    }
  }

  /**
   * The function checks if a specific feature is toggleable and returns the boolean value of the
   * toggle control for a given actual value.
   * @param {string} feature - The feature parameter is a string that represents the name of a feature.
   * @param {string} toggleControl - The `toggleControl` parameter is a string that specifies which
   * toggle control to check within the `featureCheck` object.
   * @param {string} actualValue - The `actualValue` parameter is a string that represents the value
   * you want to check against in the `toggleControl` array.
   * @returns a boolean value.
   */
  private checkToggleableFeatures(
    feature: string,
    toggleControl: string,
    actualValue: string
  ): boolean {
    const featureCheck = this.advancedFeatures[feature];
    if (featureCheck.checked) {
      let booleanFlag: boolean;
      featureCheck[toggleControl].forEach((toggle: any) => {
        if (toggle.actualValue === actualValue) {
          booleanFlag = toggle.checked;
        }
      });
      return booleanFlag;
    }
  }

  /**
   * The function checks the input features and returns a string flag based on the toggle control and
   * actual value.
   * @param {string} feature - The feature parameter is a string that represents the name of a feature.
   * @param {string} toggleControl - The `toggleControl` parameter is a string that specifies which
   * control to toggle.
   * @param {string} actualValue - The `actualValue` parameter is a string that represents the value
   * you want to check against in the `toggleControl` array.
   * @returns the value of the variable "stringFlag".
   */
  private checkInputFeatures(
    feature: string,
    toggleControl: string,
    actualValue: string
  ): string {
    const featureCheck = this.advancedFeatures[feature];
    if (featureCheck.checked) {
      let stringFlag: string;
      featureCheck[toggleControl].forEach((toggle: any) => {
        if (toggle.actualValue === actualValue) {
          stringFlag = toggle.value;
        }
      });
      return stringFlag;
    }
  }

  /**
   * The function checks the values of a specific feature and returns a string flag based on the toggle
   * control and actual name provided.
   * @param {string} feature - The `feature` parameter is a string that represents the name of a
   * feature.
   * @param {string} toggleControl - The `toggleControl` parameter is a string that represents the
   * control for which you want to check the values. It is used to access the corresponding property in
   * the particular `advanced feature` object.
   * @param {string} actualName - The `actualName` parameter is a string that represents the name of a
   * toggle control.
   * @returns a string value.
   */
  private checkTabValues(
    feature: string,
    toggleControl: string,
    actualName: string
  ): string {
    const featureCheck = this.advancedFeatures[feature];
    if (featureCheck.checked) {
      let stringFlag: string;
      featureCheck[toggleControl].forEach((toggle: any) => {
        if (toggle.actualName === actualName) {
          toggle.values.forEach((value: any) => {
            if (value.checked) {
              stringFlag = value.actualValue;
            }
          });
        }
      });
      return stringFlag;
    }
  }

  /**
   * The function returns an object containing details about URL binding.
   * @returns The function `urlBindingDetails` is returning an object of type `UrlBindingData`.
   */
  private urlBindingDetails(): UrlBindingData {
    return {
      gsiId: this.urlBindingGsi?.id,
      masterId: this.urlBindingGsi?.masterId,
      attributeId: this.urlBindingAttribute?.id,
      attributeName: this.urlBindingAttribute?.name,
      attributeColor: this.urlBindingAttributeColor,
      isConfigName: this.isUrlbindingConfigName,
    };
  }
  /**
   * 'boxShadowData' consits of only the box-shadow style data
   */
  openTemplatesDialog() {
    const dialogRef = this.dialog.open(TemplateTypeDialogComponent, {
      height: '700px',
      width: '900px',
      data: {
        data: this.boxShadowData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.boxShadowTemplate = result?.boxshadow?.split(':');
      this.boxShadowData = this.boxShadowTemplate?.[1];
      if (result === 'closed') this.boxShadowToggle = false;
    });
  }

  toggleBorderColorPicker() {
    this.borderColorPickerToggler = !this.borderColorPickerToggler;
  }
  changeBorderColor(event: any) {
    this.selectedBorderColor = event;
    this.style = {
      ...this.style,
      changedElementStyle: 'selectedBorderColor',
      changedElementValue: this.selectedBorderColor,
    };
  }
  closeBorderColorPicker(event: string): void {
    this.borderColorPickerToggler = !this.borderColorPickerToggler;
  }
  detectRecieveEntityExpandData() {
    /* istanbul ignore next */
    this.entityBoardService.droppedAttrExpand$.subscribe((res: any) => {
      this.setExpandData(res);
    });
  }
  setExpandData(res: any) {
    this.dropEntityExpandData = res?.attrData;
    this.dropActionBtsExpand = res?.gsiData;
    this.dropExpandColors = res?.dropExpandColors ? res?.dropExpandColors : [];
    this.expandBackground = res?.expandBackground;
    this.expandHoverBackground = res?.expandHoverBackground;
    this.expandBtnColors = res?.expandBtnColors;
    this.expandBoxShadow = res?.expandBoxShadow;
    this.droppedStylesExpand = res?.droppedStylesExpand
      ? res?.droppedStylesExpand
      : [];
    this.hoverColorsExpand = res?.expandHoverColors
      ? res?.expandHoverColors
      : [];
    this.expandHyperlinkArray = res?.expandHyperlinkArray;
    this.gsiAttrMapExpand = res?.gsiAttrMapExpand;
  }
  /**
   * This function is used to get selected cartTemplateNumber
   */
  getCartConfiguration(): void {
    this.entityBoardFacade
      .getCartConfiguration()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          this.cartConfig = response?.result;
          this.cartTemplateType = parseInt(this.cartConfig?.templateType);
          this.cartConfig?.gsis.forEach((gsi: any) => {
            this.cartGsiList.push({
              id: gsi.gsiId,
              masterId: gsi.gsiMasterId,
              name: gsi.gsiName,
            });
          });
        },
        (error: any) => {
          this.alertService.showToaster(error?.message, error?.name, 'error');
        }
      );
  }
  /**
   * @param {number} index - The index of the attribute need to delete
   * @description This function is used to delete the attributes from cartAttributesPositionMap which have been dragged
   * dropped in selected cart template atrribute position i.e from cartAttributes by assigning isSelected as false and
   * making position of attribute to -1
   */
  onDeletingCartAttr(index: number) {
    const foundIndex = this.cartAttributes.findIndex(
      (x: any) => x?.name === this.cartAttributesPositionMap[index]?.name
    );
    /*istanbul ignore else*/
    if (foundIndex != -1) {
      this.cartAttributes[foundIndex].isSelected = false;
      this.cartAttributes[foundIndex].position = -1;
    }
    delete this.cartAttributesPositionMap[index];
  }
  decreaseBorderProperty(property: string): void {
    if (property === 'borderSize' && this.borderSize > 0) {
      this.borderSize -= 1;
      this.style = {
        ...this.style,
        changedElementStyle: 'borderSize',
        changedElementValue: this.borderSize,
      };
    } else if (property === 'borderRadius' && this.borderRadius > 0) {
      /*istanbul ignore else*/
      this.borderRadius -= 1;
      this.style = {
        ...this.style,
        changedElementStyle: 'borderRadius',
        changedElementValue: this.borderRadius,
      };
    }
  }

  increaseBorderProperty(property: string): void {
    if (property === 'borderSize') {
      this.borderSize += 1;
      this.style = {
        ...this.style,
        changedElementStyle: 'borderSize',
        changedElementValue: this.borderSize,
      };
    } else if (property === 'borderRadius') {
      /*istanbul ignore else*/
      this.borderRadius += 1;
      this.style = {
        ...this.style,
        changedElementStyle: 'borderRadius',
        changedElementValue: this.borderRadius,
      };
    }
  }
  recieveEmittedDirectiveId(event: string): void {
    this.attributeDirectiveId = event;
    this.cartView.attributeDirectiveId = this.attributeDirectiveId;
  }

  checkAlign(align: any) {
    this.alignment = align;
    this.style = {
      ...this.style,
      changedElementStyle: 'alignment',
      changedElementValue: this.alignment,
    };
  }
  decreaseFontSize() {
    if (this.fontSize) {
      this.fontSize = this.fontSize - 1;
      this.style = {
        ...this.style,
        changedElementStyle: 'fontSize',
        changedElementValue: this.fontSize,
      };
    }
  }
  increaseFontSize() {
    this.fontSize = this.fontSize + 1;
    this.style = {
      ...this.style,
      changedElementStyle: 'fontSize',
      changedElementValue: this.fontSize,
    };
  }
  hoverBackgroundColor(event: any) {
    this.hoverColor = event;
    this.style = {
      ...this.style,
      changedElementStyle: 'hoverBackgroundColor',
      changedElementValue: this.hoverColor,
    };
  }
  closehoverBackgroundColorPicker(event: any) {
    this.hoverBackgroundPickerToggler = !this.hoverBackgroundPickerToggler;
  }
  toggleHoverBackgroundColor() {
    this.hoverBackgroundPickerToggler = !this.hoverBackgroundPickerToggler;
  }
  recieveCardHoverColor(event: any) {
    this.cardHoverColor = event;
  }

  ChangeConditionColorToggle() {
    this.conditioncColorToggler = !this.conditioncColorToggler;
  }
  withoutAdvancedFeatures() {
    this.getMappedAttributes();
    this.advancedFeatures = JSON.parse(JSON.stringify(advancedFeatures));
    this.OpenCardName();
  }
  searchAttributesCard() {
    isSearchAttributeVal(this.attributeValue, this.nslAttributes);
  }

  dropStatusAttribute(ev: any, index: any) {
    if (ev?.target?.innerHTML.trim() === 'Status Attribute' && this.singleSelection.contains(this.dragAttrType)) {
      ev.preventDefault();
      const data = ev.dataTransfer.getData('text');
      ev.target.innerHTML = data;
      const foundIndex = this.selectGsiList.findIndex((x: any) => x.name === data);
      if (foundIndex != -1) {
        this.dropActionBts[index] = this.selectGsiList[foundIndex];
        this.selectGsiList.splice(foundIndex, 1);
      }
    }
  }

  dropKanbanCardAttribute(ev: any, index: any) {}

  enableDroppedAttributeOnCartTemplate(event: any) {
    this.enableDroppedAttributeOnTemplate(event.event, event.type);
  }
  closeCardNameDialog() {
    this.closeDialog();
    this.saveEntityCard();
  }
  receiveCartAttributeStyles(event: any) {
    this.cartAttributeStyles = event;
  }

  private setKanbanConfiguration = (): KanbanConfiguration => {
    if (this.isKanbanBoard) {
      const obj: KanbanConfiguration = {
        statusAttribute: this.updateStatusAttribute(this.draggableAttributes),
        kanbanGsis: this.kanbanGsiConfiguration,
        kanbanStatus: this.kanbanStatus,
        allAttributes: this.draggableAttributes,
      };
      return obj;
    }
    return null;
  };

  private updateStatusAttribute = (draggableAttributes: DraggableAttribute[]): DraggableAttribute[] => {
    const status: DraggableAttribute[] = [];
    draggableAttributes.forEach((attr: DraggableAttribute) => {
      if (attr.selectedInKanban) {
        status.push(attr);
      }
    });
    return status;
  };
  getKanbanStatus(event: any) {
    this.kanbanStatus = event;
  }

  OpenConnectors(label: string, gsiList: any) {
    let dialogRef = this.dialog
      .open(CommonDcdComponent, {
        width: '90%',
        height: '80%',
        data: {
          gsiList: gsiList,
          entity: this.entityData,
          isEntityCard: true,
          dcds: this.dcdList,
        },
      })
      .afterClosed()
      .subscribe((data: any) => {
        if (label == 'card') {
          this.dcdList = data?.dcds;
        } else if (label == 'cart') {
          this.cartDcdList = data?.dcds;
        }
      });
  }
  callConnectors() {
    if (this.tabSelected == 'child') {
      this.OpenConnectors('card', this.selectGsiList);
    } else if (this.tabSelected == 'cart') {
      this.OpenConnectors('cart', this.cartGsiList);
    }
  }
  borderSizeToggleChange() {
    this.borderRadiusToggle = !this.borderRadiusToggle;
    if (this.borderRadiusToggle) {
      this.style = { ...this.style, isBorder: true };
    } else {
      this.borderRadius = 0;
      this.style = {
        ...this.style,
        changedElementStyle: 'borderRadius',
        changedElementValue: this.borderRadius,
      };
      this.borderSize = 0;
      this.style = {
        ...this.style,
        changedElementStyle: 'borderSize',
        changedElementValue: this.borderSize,
      };
      this.style = { ...this.style, isBorder: false };
    }
  }

  getApiVersion(event: any) {
    this.apiVersion = event;
  }
  getEntityValidations() {
    this.entityEndpointService.getValidations()
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log(res);
        this.allEntityValidations = res;
      }
    );
  }

  selectAttributeForValidator(attribute: any){
    this.validatorsList = this.allEntityValidations.find((datatype: any) => {
      return attribute.uiElement === datatype.name;
    })?.constraints;

  }

  validatorsChange(constraint: any) {
    /* istanbul ignore next */
    this.selectedConstraintProperties = constraint?.properties;
    this.createConstraintFormGroup();
  }

  createConstraintFormGroup() {
    let formgroup: any = {};
    this.selectedConstraintProperties?.forEach((property: any) => {
      formgroup[property?.name] = new FormControl('');
    });

    this.constraintsForm = new FormGroup(formgroup);
    this.loadExisitingPayload();
  }

  addConstraint(){
    let constraintObj = {
      attributeName: this.selectedAttribute,
      name: this.selectedConstraint,
      properties: this.constraintsForm.value,
    }
    this.constraintsArray.push(constraintObj);
    this.selectedAttribute = '';
    this.selectedConstraint = '';
    this.constraintsForm.reset();
  }

  deleteConstraint(index: any){
    this.constraintsArray.splice(index, 1);
  }

  editConstraint(constraint: any, index: any){
    this.selectedAttribute = constraint.attributeName;
    this.selectedConstraint = constraint.name;
    this.constraintsForm.patchValue(constraint.properties);
    this.deleteConstraint(index);
  }

  finalSaveConstraints(entityCardPayload: any){
    let payload: any = {
      entityMasterId: this.entityData.masterId,
      constraints:{},
      id: this.constraintId ? this.constraintId: null,
    }
    this.constraintsArray.forEach((constraint: any) => {
      let entityAttributeKey = this.entityData.name + '.' + constraint.attributeName;
      delete constraint.attributeName;
      if (!payload.constraints.hasOwnProperty(entityAttributeKey)) {
        payload.constraints[entityAttributeKey] = [];
      }
      payload.constraints[entityAttributeKey].push(constraint);
    });
    this.entityBoardService.saveKanbanConstraints(payload).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      if (res.status === 200) {
        console.log(res);
        /*istanbul ignore next*/
        this.entityBoardService.saveEntityCard(entityCardPayload).subscribe((res: any) => {
          this.alertService.showToaster(res.message, '', 'success');
          this.router.navigate(['/cdui/mylibrary-dashboard']);
        });
      }
    });
  }

  loadExisitingPayload() {}

  deleteAllConstraints(){
    this.entityBoardService.deleteKanbanConstraints(this.entityData.masterId).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      if (res.status === 200) {
        console.log(res);
      }
    });
  }

  fetchKanbanConstraints(){
    this.entityBoardService.getKanbanConstraints(this.entityData.masterId).pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res: any) => {
      if (res.status === 200 && res?.result?.constraints) {
        this.constraintId =res.result.id;
        Object.keys(res.result.constraints).forEach((key: any) => {
          res.result.constraints[key].forEach((constraint: any) => {
            let constraintObj = {
              attributeName: key.split('.')[1],
              name: constraint.name,
              properties: constraint.properties,
            }
            this.constraintsArray.push(constraintObj);
          });
        });
      }
    });
  }

  deleteHeaderGsi(index: number) {
    this.selectGsiList.push(
      this.headerGsiMap.get(this.tableData?.[0]?.[index].attributeHeading)
    );
    this.headerGsiMap.delete(this.tableData?.[0]?.[index].attributeHeading);
  }
  droppedAttr(ev: any) {
    this.dropAttributeInTemplates(ev);
  }

  droppedActionButton(ev: any) {
    this.dropActionBtn(ev,this.childGsiMapping);
  }

  recieveDropEntityExpandData(event: any) {
    this.dropEntityExpandData = event.droppedAttrExpand;
    /* istanbul ignore else*/
    if (
      this.changeObj &&
      this.dropEntityExpandData &&
      this.changeObj?.expandTemplateTypeData !== this.dropEntityExpandData
    ) {
      this.changeObj['expandTemplateTypeData'] = this.dropEntityExpandData;
    }
    /* istanbul ignore else*/
    if (this.changeObj && event.event) {
      this.cardConfigEvent.emit(this.changeObj);
    } else if (this.changeObj && !event.event) {
      this.isCardConfig = false;
    }
  }
  expandGsiListemit(event: GsiButton[]) {
    this.expandGsiList = event;
  }
  cartButtonEmit(event: GsiButton) {
    this.CART_BUTTON = event;
  }
}
