import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-signature',
  styleUrls: ['./signature.component.scss'],
  template: `
    <!--<div class="demo-full-width" [formGroup]="group">
      <mat-label
        >{{ field.label }}: <span *ngIf="field?.isRequired" class="mat-form-field-required-marker">*</span></mat-label
      >
      <app-digital-signature
        [formControlName]="field.attribute.name"
        [field]="field"
        [required]="field.isRequired"
        id="{{ field.attribute.name }}"
        ngDefaultControl
      ></app-digital-signature>
      <mat-icon
        matTooltip="Sub-Transactional CU"
        matSuffix
        *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
        ><span class="sub-cu" (click)="openSubPopUp()"></span>
      </mat-icon>
    </div>-->
    <div class="" [formGroup]="group">
      <div class="d-flex align-items-center nh-mb-8">
        <label
          class="main-label nh-mb-0"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
          >{{ field.label }} :
          <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></label
        >
        <p
          class="nh-ml-6 d-flex"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
          [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            class="material-icons-outlined helperText-infoIcon"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
      </div>
      <div
        *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])"
        class="ui-digital-signature"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
      >
        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <!--<span class="close-signature"><i class="bi bi-x"></i></span>-->
        <app-digital-signature
          class="ui-digital-signature-inner"
          [formControlName]="field.attribute.name"
          [field]="field"
          [required]="field?.isRequired && !field?.isInfo"
          id="{{ field.attribute.name }}"
          ngDefaultControl
        ></app-digital-signature>
        <!--<button class="btn signature-accept">Accept</button>-->
        <mat-icon
          matTooltip="Sub-Transactional CU"
          matSuffix
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          ><span class="sub-cu" (click)="openSubPopUp()"></span>
        </mat-icon>
        <p
          class="bottom-text"
          *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
      </div>
      <!--signature text in table-->
      <div
        *ngIf="(this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle']"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
      >
        <p-overlayPanel #op styleClass="ui-control-dropdown">
          <ng-template pTemplate="content">
            <app-digital-signature
              class=""
              [formControlName]="field.attribute.name"
              [field]="field"
              [required]="field?.isRequired && !field?.isInfo"
              id="{{ field.attribute.name }}"
              ngDefaultControl
            ></app-digital-signature>
          </ng-template>
        </p-overlayPanel>
        <!-- <p-overlayPanel #ophover styleClass="ui-control-dropdown-hover" [style]="marginStyle">
          <img [src]="group?.value?.value?.contentUrl" />
        </p-overlayPanel> -->
        <figure (click)="op.toggle($event)" class="thumbnail-icon">
          <img *ngIf="!group?.value?.value?.contentUrl" src="../../../assets/images/ui-controls/icon-signature.svg" />
          <img [src]="group?.value?.value?.contentUrl" />
        </figure>
      </div>
    </div>
  `,
  styles: [],
})
export class SignatureComponent implements OnInit {
  positionLeft = '-52px';
  marginStyle = { 'margin-left': this.positionLeft };

  field: FieldConfig;
  group: FormGroup;
  isOpened: boolean = false;

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void { }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
}
