import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ViewEncapsulation,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { UploadFacadeService } from '../upload/upload-facade.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadPreviewComponent } from '../file-upload-preview/file-upload-preview.component';
import { EventsFacadeService } from '../events/events-facade.service';
import { TranslatorService } from '../translator/translator.service';
import { LibraryFacadeService } from '../library/library-facade.service';
import { AlertService } from '../toaster/alert.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DicomIntegrationComponent } from '../dicom-integration/dicom-integration.component';
import { getUiControlCustomization } from '../../generic-folder/generic-functions';
import { LoaderService } from '../loader/loader.service';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @ViewChild('editImage') editImage: TemplateRef<any>;
  _files: any = [];
  labels: any;
  rawSheetData: any;
  isValidFile: any = true;
  // private reqPayload:FormControl =new FormControl()
  infoWarnMessage = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper: any;
  viewFileSize: any;
  height: any;
  notSupportedFormat: boolean;
  width: any;
  canvasRotation = 0;
  imageMimeTypes = ['image/png', 'image/gif', 'image/pjpeg', 'image/jpeg', 'image/svg+xml', 'image/webp'];
  @Input() fileUploadPath: string;
  @Input() field: any;
  @Input() fileUploadFromEntity = false;
  @Input() editFileUrl: any;
  @Input() isMulti = false;
  @Input() fileType = 'file';
  @Input() isFolder = false;
  @Input() isRedac: boolean;
  @Output() openDeIdentifyModal: EventEmitter<any> = new EventEmitter();
  @Output() fileUploadPayload: EventEmitter<any> = new EventEmitter();
  @Output() fileUploadResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedFileResponse: EventEmitter<any> = new EventEmitter<any>();
  @Output() sheetData: EventEmitter<any> = new EventEmitter();
  @Output() filestoSend: EventEmitter<any> = new EventEmitter();
  @Input() toggle: boolean = false;
  @Input() isInfoOpen: boolean = true;
  @Output() hideFileInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() isMultiUpload: EventEmitter<any> = new EventEmitter();
  @Input() isHideFileInfo: boolean = false;
  @Input() attrval:any;
  @Input() isDesignTime: boolean = false;
  instanceNumber: number = -1;
  foundObject: any;
  appliedClass: string = 'ui-custom-upload';
  static componentCounter: number = 0;
  toggledEditFileUrl: any;
  get files() {
    return this._files;
  }

  set files(value) {
    /* istanbul ignore else */
    if (value != '') {
      /* istanbul ignore next */
      if (!this.isRemoveAll) {
        this._files = value;
        this.filestoUpload = value;
        this.setInputFilesStatus();
        this.filestoSend.emit(this._files);
      }
    }
  }

  folderPath = '';
  filestoUpload: any = [];
  @ViewChild('fileInput') fileInput: any;
  unSubscribe = new Subject();
  isRemoveAll = false;
  imageTypes: Set<String> = new Set([
    'image/png',
    'image/gif',
    'image/pjpeg',
    'image/jpeg',
    'image/svg+xml',
    'image/webp',
  ]);
  filesPath: any = [];
  folderFileMap: Map<string, File[]> = new Map<string, File[]>();
  @Input() isFolderUpload: boolean = false;
  filesInFolderPath: string[] = [];

  ngOnChanges() {
    /* istanbul ignore else */
    if (this.fileUploadPath) {
      this.folderPath = this.fileUploadPath;
    }
    /* istanbul ignore else */
    if (!this.isMulti) {
      this.onRemoveAllClick();
    }
    /* istanbul ignore else */
    // if (this.deleteFiles?.length === 0) {
    //   this.deleteFile(0);
    // }
     /* istanbul ignore else */
     if (this.editFileUrl) {
      if (Array.isArray(this.editFileUrl)) {
        this._files = this.editFileUrl;
      } else {
        this._files = [this.editFileUrl];
      }
      this.setInputFilesStatus();
    }
  }

  constructor(
    private uploadFacadeService: UploadFacadeService,
    public dialogService: MatDialog,
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private libraryfacadeservice: LibraryFacadeService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {
    FileUploadComponent.componentCounter++;
    this.instanceNumber = FileUploadComponent.componentCounter;
    this.detectLanguageChange();
    this.getToggledFileUrl();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  async folderDropHandler(event: any) {
    const items = event;
    let entries: any = [];
    this.filesInFolderPath = [];

    if (!items) {
      this.alertService.showToaster(
        `No items found in the drop event`,
        '',
        'error'
      );
      return;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item.isDirectory) {
        await this.listDirectory(item, entries);
      } else if (item.isFile) {
        let temp = await this.convertToFile(item);
        entries.push(temp);
      }
    }
    if (entries.length > 0) {
      this.prepareFilesList(entries);
    }
  }

  async listDirectory(directoryEntry: any, entries: any) {
    const dirReader = directoryEntry.createReader();

    try {
      const entriesBatch = await new Promise<any[]>((resolve, reject) => {
        dirReader.readEntries(resolve, reject);
      });
      for (let i = 0; i < entriesBatch.length; i++) {
        const entry = entriesBatch[i];
        if (entry.isFile) {
          this.filesInFolderPath.push(entry?.fullPath);
          let temp = await this.convertToFile(entry);
          entries.push(temp);
        } else if (entry.isDirectory) {
          await this.listDirectory(entry, entries);
        }
      }
    } catch (error) {
      
      this.alertService.showToaster(
        `Error reading directory:`,
        '',
        'error'
      );
    }
  }
  async convertToFile(fileSystemEntry: any): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      fileSystemEntry.file(
        (file: File) => {
          resolve(file);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }


  ngOnInit(): void {
    if (this?.field?.value) {
      /* istanbul ignore next */
      let file;
      if (typeof this.field.value != 'object') {
        file = JSON.parse(JSON.stringify(JSON.parse(this.field?.value)));
      } else {
        file = this.field.value;
      }
      file.status = 'success';
      /* istanbul ignore else */
      if (Array.isArray(file) && file?.length > 0) {
        file.forEach((x: any) => {
          if (x?.mimeType) this.files.push(x);
        });
      } else if (file?.mimeType) {
        this.files.push(file);
      }
    }
    if (this.toggle) this.editFileUrl = this.toggledEditFileUrl;
    /* istanbul ignore else */
    if (this.editFileUrl) {
      if (Array.isArray(this.editFileUrl)) {
        this._files = this.editFileUrl;
      } else {
        this._files = [this.editFileUrl];
      }
      this.setInputFilesStatus();
    }
    let fileUploadOption = getUiControlCustomization('FileUpload');
    if (fileUploadOption && !this.fileUploadFromEntity) {
      this.foundObject = { event: fileUploadOption };
      let op = fileUploadOption.slice(-1);
      this.appliedClass = `form-custom-upload form-custom-upload-opt${op}`;
    }
  }

  ngAfterViewInit() {
    this.libraryfacadeservice.clearallfiles$.subscribe((res: any) => {
      if (res) {
        this.onRemoveAllClick();
      }
    });
    this.libraryfacadeservice.clearfiles(false);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    let files = $event;
    /* istanbul ignore next */
    if (this.field?.type == 'redact' && !this.supportedFormatRedact(files)) {
      this.alertService.showToaster(` ${files[0]?.type} Files Not Allowed in Redact`, '', 'error');
      return;
    }
    /* istanbul ignore next */
    if (this.files?.length === 0) {
      if (this.field?.isMulti) {
        //Now by this logic it will accept only first file from choosed multiple files
        //If selected multi value option as 'yes' then it chooses multiple
        this.prepareFilesList($event);
      } else this.prepareFilesList([$event[0]]);
    }
  }

   /**
   * @Author : Sunil
   * @date : 11/21/2023
   * @description : This provide file support for redact ui control and excluding some of the file
   * @param : File type
   */
    supportedFormatRedact(files: any) {
      let supportedFormat = true;
      const notSupported = ['.xls', '.xlsx', '.docx', '.doc'];
      notSupported?.forEach((ele: any) => {
        if (files?.[0]?.type?.toLowerCase()?.includes(ele) || files?.[0]?.name?.endsWith(ele)) {
          supportedFormat = false;
        }
      });
      return supportedFormat;
    }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    /* istanbul ignore next */
    let files = event?.target?.files;
    if (this.field?.type == 'redact' && !this.supportedFormatRedact(files)) {
      this.alertService.showToaster(` ${files[0]?.type} Files Not Allowed in Redact`, '', 'error');
    } else if (this.fileType == 'video' && !files?.[0]?.type?.includes('video/')) {
      /* istanbul ignore else */
      this.alertService.showToaster(` ${files[0]?.type} files not allowed in video`, '', 'error');
    } else if (this.fileType == 'audio' && files[0]?.type != 'audio/mpeg') {
      this.alertService.showToaster(` ${files[0]?.type} files not allowed in ${this.fileType}`, '', 'error');
    } else {
      if (this.imageMimeTypes.indexOf(files[0]?.type) !== -1) {
        this.imageChangedEvent = event;
        if (!this.isFolder  && files.length == 1) this.openDialog();
        else {
          this.prepareFilesList(files);
        }
      } else {
        /* istanbul ignore next */
        if (this.files?.length === 0 || this.isMulti) {
          this.prepareFilesList(files);
        }
        /* istanbul ignore next */
        if (
          files[0]?.type == 'application/vnd.ms-excel' ||
          files[0]?.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          files[0]?.type == 'text/csv' ||
          files[0]?.name.substr(files[0].name.lastIndexOf('.') + 1) == 'ods'
        ) {
          this.rawSheetData = event;
          this.sheetData.emit(event);
        } else {
          this.rawSheetData = undefined;
        }
      }
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    /* istanbul ignore next */
    if (!this.field?.readonly) {
      this.files.splice(index, 1);
      this.propagateChange(this.files);
      /* istanbul ignore next */
      if (this.files.length == 0 && this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }
    }
    this.deletedFileResponse.emit(this.files);
    /* istanbul ignore next */
    if (!this.fileUploadFromEntity) {
      this.onChange();
    }
  }

  getAttributeData() {
    /* istanbul ignore next */
    return {
      name: this.field?.attribute?.name,
      attributeType: this.field?.attribute?.attributeType,
      constraints: this.field?.attribute?.constraints,
    };
  }

  cancel() {
    this.deleteFile(0);
    this.dialogService.closeAll();
  }

  // close dialog
  onCloseDialog() {
    this.canvasRotation = 0;
    this.dialogService.closeAll();
  }

  openDialog() {
    /* istanbul ignore next */
    // this.dialogService.closeAll();
    setTimeout(() => {
      this.dialogService.open(this.editImage, {
        minHeight: '400px',
        minWidth: '700px',
        maxWidth: '1000px',
        disableClose: true,
        panelClass: 'uploadFileDialog',
      });
    }, 100);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.height = event.height;
    this.width = event.width;
    /* istanbul ignore next */
    this.viewFileSize = this.base64ToFile(this.croppedImage, this.imageChangedEvent?.target?.files[0]?.name);
  }

  rotateLeft() {
    this.canvasRotation--;
  }

  rotateRight() {
    this.canvasRotation++;
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    /* istanbul ignore next */
    const mime = arr[0]?.match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  imageLoaded() {
    this.showCropper = true;
  }

  uploadFile() {
    let files = [];
    /* istanbul ignore next */
    files.push(this.base64ToFile(this.croppedImage, this.imageChangedEvent?.target?.files[0]?.name));
    /* istanbul ignore next */
    if (this.files?.length === 0 || this.isMulti) {
      this.prepareFilesList(files);
    }else{
      this.onCloseDialog();
    }

  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator() {
    if (this.fileUploadFromEntity) {
      let fileLength = this.filestoUpload.length;
      const formData = new FormData();
      if (fileLength === 1) {
        formData.append('file', this.filestoUpload[0]);
      } else {
        for (var i = 0; i < fileLength; i++) {
          formData.append('files', this.filestoUpload[i]);
        }
      }
      /* istanbul ignore next */
      this.uploadFacadeService
        .dynamicFormUploadFiles(formData, fileLength)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            if (res) {
              if (!this.validateFiles(fileLength, res)) {
                this.alertService.showToaster(
                  `All upload files are not ${this.fileType} files! Please upload only ${this.fileType} files.`,
                  '',
                  'error'
                );
                this.removeInvalidFiles(fileLength, res);
                this.deletedFileResponse.emit(this.files);
              }
              this.fileUploadResponse.emit(res);
              this.updateFileData(res);
              this.propagateChange(this.files);
            }
            this.setInProgressState('success');
            if (this.field) this.field.value = JSON.stringify(this.files);
            this.onCloseDialog();
          },
          (err) => {
            this.setInProgressState('failed');
          }
        );
    } else {
      let activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
      if (!this.isFolder) {
        /* istanbul ignore next */
        this.folderPath =
          'txn,' +
          this.eventsService?.gsiName +
          ',' +
          this.eventsService?.selectedCuData?.currentCU?.name +
          ',' +
          activeUser?.id +
          ',' +
          this.eventsService?.selectedCuData?.transId;
      } else {
        this.folderPath = this.fileUploadPath;
      }

      let reqPayload = new FormData();
      reqPayload.append('description', '');
      reqPayload.append('displayName', '');
      let fileLength = this.filestoUpload.length;
      if (fileLength === 1) {
        reqPayload.append('file', this.filestoUpload[0]);
      } else {
        for (var j = 0; j < fileLength; j++) {
          reqPayload.append('files', this.filestoUpload[j]);
        }
      }
      reqPayload.append('folders', this.folderPath);
      if (this.field?.isOptional) {
        this.setOptionalValidators();
      }
      this.field?.validations?.forEach((obj: any) => {
        /* istanbul ignore next */
        if (obj.name) {
          this.setValidators(obj.name, obj);
        }
      });
      if (this.isValidFile) {
        if (!this.isFolder && !this.isFolderUpload) {
          /* istanbul ignore next */
          this.uploadFacadeService
            .dynamicFormUploadFiles(reqPayload, fileLength)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(
              (data: any) => {
                this.loader.hide();
                if (data) {
                  this.infoWarnMessage = false;
                  if (!this.validateFiles(fileLength, data)) {
                    this.alertService.showToaster(
                      `All upload files are not ${this.fileType} files! Please upload only ${this.fileType} files.`,
                      '',
                      'error'
                    );
                    this.removeInvalidFiles(fileLength, data);
                    this.deletedFileResponse.emit(this.files);
                  }
                  this.fileUploadResponse.emit(data);
                  this.updateFileData(data);
                  this.propagateChange(this.files);
                }
                this.setInProgressState('success');
                if (this.field) this.field.value = JSON.stringify(this.files);
                this.onChange();
                if (this.files.length > 1) {
                  this.isMultiUpload.emit(true);
                }
              },
              (err) => {
                this.setInProgressState('failed');
              }
            );
        } else if (this.isFolder && !this.isFolderUpload) {
          let folderPayload = {
            description: '',
            displayName: '',
            file: this.filestoUpload[0],
            folders: this.folderPath,
          };
          this.fileUploadPayload.emit(folderPayload);

          this.setInProgressState('success');
        }
        else if (this.isFolderUpload) {
        let folderPayload = {
          description: '',
          displayName: '',
          file: this.filestoUpload,
          folders: this.filesInFolderPath,
        };
        this.fileUploadPayload.emit(folderPayload);
        this.loader.hide();
        this.setInProgressState('success');
      }else {
        this.infoWarnMessage = true;
        this.isValidFile = true;
        this.onRemoveAllClick();
      }
    }
  }
  }

  /**
   * Updates file data
   * @param data file/files uploaded
   */
  updateFileData(data: any) {
    /* istanbul ignore next */
    if (data && Array.isArray(data) && data.length > 0) {
      const filesToUploadLength = data.length;
      for (let i = 0; i < filesToUploadLength; i++) {
        let index = this.files.findIndex((ele: any) => ele.name === data[i].originalFileName);
        /* istanbul ignore next */
        if (index !== -1) {
          this.files[index] = { ...data[i], ...this.files[index] };
        }
      }
    } else if (data && data.name) {
      let index = this.files.findIndex((ele: any) => ele.name === data.originalFileName);
      if (index !== -1) {
        this.files[index] = { ...data, ...this.files[index] };
      }
    }
  }

  /**
   * Sets in progress state of each uploaded file
   * @param status success or failed status
   */
  setInProgressState(status: string) {
    const filesToUploadLength = this.filestoUpload.length;
    for (let i = 0; i < filesToUploadLength; i++) {
      for (let j = 0; j < this.files.length; j++) {
        /* istanbul ignore next */
        if (this.files[j].originalFileName === this.filestoUpload[i].name) this.files[j].status = status;
      }
    }
  }

  /**
   * Assign files
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      if (!this.isFolder) item.status = 'inprogress';
      else item.status = 'success';
      this.files.push(item);
    }
    this.filestoUpload = files;
    this.uploadFilesSimulator();
  }

  /*
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals?: number) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  writeValue(value: any) {
    /* istanbul ignore next */
    if (value && typeof value == 'object') {
      const file = JSON.parse(value);
      this.files = [file];
    }
  }

  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
      }
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate({ value }: FormControl) {
    let inNotValid;
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      /* istanbul ignore next */
      inNotValid = value && value?.length > 0 ? false : true;
    } else {
      inNotValid = false;
    }
    /* istanbul ignore next */
    return !inNotValid ? null : { valid: false };
  }

  removeInvalidFiles(fileLength: any, res: any) {
    /* istanbul ignore next */
    if (!this.field?.readonly) {
      if (fileLength === 1 && !res?.mimeType?.includes(this.fileType)) {
        res = null;
      }

      if (fileLength > 1) {
        for (let i = 0; i < res.length; i++) {
          if (!res[i]?.mimeType?.includes(this.fileType)) {
            res?.splice(i, 1);
            i--;
          }
        }
      }

      for (let j = 0; j < this.files.length; j++) {
        if (
          (this.files[j]?.status === 'success' && !this.files[j]?.mimeType?.includes(this.fileType)) ||
          (this.files[j]?.status === 'inprogress' && !this.files[j]?.type?.includes(this.fileType))
        ) {
          this.files?.splice(j, 1);
          j--;
        }
      }

      this.propagateChange(this.files);
      if (this.files.length === 0 && this.fileInput && this.fileInput.nativeElement) {
        this.fileInput.nativeElement.value = '';
      }
    }
    if (this.field) this.field.value = JSON.stringify(this.files);
  }

  onRemoveAllClick() {
    /* istanbul ignore next */
    if (!this.field?.readonly) {
      this.isRemoveAll = true;
      this.files = [];
      this._files = [];
      this.files = this.files?.slice(0);
      this.propagateChange(this.files);
      if (this.files.length === 0 && this.fileInput && this.fileInput.nativeElement) {
        this.fileInput.nativeElement.value = '';
      }
    }
  }

  /**
   * Determines whether preview click on
   * @param file file which is to be previewed
   * @param index index of the file in files
   */
  onPreviewClick(file: any, index: number) {
    if (this.isRedac) {
      this.openDeIdentifyModal.emit(true);
    } else {
      this.files[index]['canDownload'] = this.field.canDownload;
      let data = { rawSheetData: this.rawSheetData, canDownload: this.field.canDownload };
      if (this.rawSheetData) {
        this.dialogService.open(FileUploadPreviewComponent, {
          data: data,
          panelClass: 'file-upload-preview-dialog',
        });
      } else {
        /*istanbul ignore next*/
        if (file?.mimeType && file?.mimeType != 'application/dicom') {
          this.dialogService.open(FileUploadPreviewComponent, {
            data: this.files[index],
            panelClass: 'file-upload-preview-dialog',
          });
        } else {
          this.dialogService.open(DicomIntegrationComponent, {
            data: {
              file: this.files?.[index],
              attributeId: this.field?.attribute?.['id'],
              attributeName: this.field?.attribute?.['name'],
              entityName: this.field?.entityName,
              entityId: this.field?.entityId,
              uiCtrl: 'file',
            },
            panelClass: 'dicom-preview-dialog',
          });
        }
      }
    }
  }

  openInNewTab(file: any, index: number) {
    if (file[0]?.type == 'application/vnd.ms-excel' || file[0]?.type == 'text/csv') {
      this.notSupportedFormat = true;
    }
    window.open(this.files[index].contentUrl, '_blank');
  }

  /**
   * Sets input files status
   */
  setInputFilesStatus() {
    /* istanbul ignore next */
    this._files = this.files?.map((file: any) => {
      file['status'] = 'success';
      return file;
    });
  }

  validateFiles(fileLength: any, res: any) {
    let status = true;
    /* istanbul ignore next */
    if (this.fileType !== 'file' && this.fileType !== 'previewdocument') {
      if (fileLength === 1 && !res?.mimeType?.includes(this.fileType)) {
        status = false;
      }
      // if (fileLength === 1 && !res?.mimeType?.includes('imageavatar')) {
      //   status = true;
      // }

      if (fileLength > 1) {
        res?.forEach((file: any) => {
          if (!file.mimeType?.includes(this.fileType)) {
            status = false;
          }
        });
      }
    }
    return status;
  }

  setValidators(type: any, obj: any) {
    switch (type) {
      case 'filenamelengthtype':
        /* istanbul ignore next */
        this.isValidFile =
          this.files[0]?.name?.length <= obj?.maxLength ? this.isValidFile && true : this.isValidFile && false;
        obj.isValidFile = this.files[0]?.name?.length <= obj?.maxLength;
        break;
      case 'required':
        /* istanbul ignore next */
        this.isValidFile = this.files.length > 0 ? this.isValidFile && true : this.isValidFile && false;
        obj.isValidFile = this.files.length > 0;
        break;
      case 'maxfilesizetype':
        let size = 1;
        /* istanbul ignore next */
        if (obj?.unit == 'KB') {
          size = 1024;
        } else if (obj?.unit == 'MB') {
          size = 1024 * 1024;
        }
        /* istanbul ignore next */
        this.isValidFile =
          this.files[0]?.size <= obj?.value * size ? this.isValidFile && true : this.isValidFile && false;
        obj.isValidFile = this.files[0]?.size <= obj?.value * size;
        break;
      case 'minfilesizetype':
        let minSize = 1;
        /* istanbul ignore next */
        if (obj?.unit == 'KB') {
          minSize = 1024;
        } else if (obj?.unit == 'MB') {
          minSize = 1024 * 1024;
        }
        /* istanbul ignore next */
        this.isValidFile =
          this.files[0]?.size >= obj?.value * minSize ? this.isValidFile && true : this.isValidFile && false;
        obj.isValidFile = this.files[0]?.size >= obj?.value * minSize;
        break;
      case 'assertextensiontype':
        /* istanbul ignore next */
        let fileName = this.files[0]?.name;
        /* istanbul ignore next */
        let ext = fileName?.substring(fileName.lastIndexOf('.') + 1);
        /* istanbul ignore next */
        this.isValidFile = obj?.extension?.toLowerCase().includes(ext)
          ? this.isValidFile && true
          : this.isValidFile && false;
        obj.isValidFile = obj?.extension?.toLowerCase().includes(ext);
        break;
      case 'assertnotextensiontype':
        /* istanbul ignore next */
        let fileName1 = this.files[0]?.name;
        /* istanbul ignore next */
        let ext1 = fileName1?.substring(fileName1.lastIndexOf('.') + 1);
        /* istanbul ignore next */
        this.isValidFile = !obj?.extension?.toLowerCase().includes(ext1)
          ? this.isValidFile && true
          : this.isValidFile && false;
        obj.isValidFile = !obj?.extension?.toLowerCase().includes(ext1);
        break;
      case 'filenamepatterntype':
        /* istanbul ignore next */
        this.isValidFile = this.files[0]?.name?.match(obj?.requiredPattern)
          ? this.isValidFile && true
          : this.isValidFile && false;
        obj.isValidFile = this.files[0]?.name?.match(obj?.requiredPattern);
        break;
    }
  }

  onChange() {
    /* istanbul ignore next */
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    /* conditional potentiality check  */
    /* istanbul ignore next */
    if (this.field?.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent(data);
    } else {
      this.eventsService.setEvent(data);
    }
  }

  getToggledFileUrl() {
    this.libraryfacadeservice.editFileUrl$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) this.toggledEditFileUrl = res;
    });
  }

  hideFileChange(event: any) {
    this.hideFileInfo.emit(event.checked);
  }

  openImageZoom(file: any) {
    // this.dialogService.open(ImageZoomInoutComponent, {
    //   panelClass: 'openImageZoom',
    //   data: { file: file, field: this.field },
    // });
  }

  ngOnDestroy() {
    this.files = this._files = [];
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
