/// <reference types="@types/google.maps" />
import { Component, OnInit, Input, ViewEncapsulation, forwardRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, TranslatorService, GmapsData, getUiControlCustomization} from '@common-services';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleMapsComponent),
      multi: true,
    },
  ],
})
export class GoogleMapsComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  field: FieldConfig;
  group: FormGroup;

  currentlat: any;
  currentlng: any;

  clickedLat: any;
  clickedLng: any;

  origin: any;
  destination: any;

  startPoint: any;
  endPoint: any;

  previousInfoWindow: any;
  wayPoints: any[] = [];

  @Input() config: any;
  @Input() _mapData: GmapsData;
  dateChanged: Subject<any> = new Subject();
  isDisabledButton: Subject<any> = new Subject();
  latitude: number;
  longitude: number;
  radiusLong: any;
  radius = 5000;
  zoom: number;
  markers: any = [];
  filteredMarkers: any;
  addressByCoord: any;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('iconsvg') public iconSvg: ElementRef<HTMLElement>;
  geoCoder: google.maps.Geocoder;

  mapData = {
    lat: 51.673858,
    lng: 7.815982,
    zoomControl: false,
    disableDefaultUI: false,
    zoom: 12,
    markers: [] as any,
  };
  currentCu: any;
  selectedMarker: { lat: any; lng: any }[];
  searchControl: FormControl;
  iconUrl = '../../../../../assets/img/save.svg';
  showMarkers: boolean;
  currentLocation: boolean;
  currentLocationData: any;
  showNavigation: boolean;
  displayLocationstatus: boolean = false;
  labels: any;
  calllocationData: any;
  locationInterval: NodeJS.Timeout;
  toggleInterval: NodeJS.Timeout;
  unsubscribe$: Subject<any> = new Subject<any>();
  callLocation: boolean = false;
  routeArr: any = [];
  wayPointsLocationData: Subject<any> = new Subject();
  hideSearchLocation: boolean = false;
  foundObject: any;
  appliedClass: string = 'custom-input-mat custom-input-icon';
  parentClass: string = 'form-group';

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private eventsService: EventsFacadeService,
    private http: HttpClient,
    private translator: TranslatorService,
    private transactionFacadeService: TransactionFacadeService
  ) {
    this.detectLanguageChange();
    this.getLocationData();
    this.getSubmiTransaction();
  }

  ngOnChanges() {
    this.mapData = this.config;
  }
  mapssAPIcalled(data: any) {
    this.mapsAPILoader = data;
  }

  ngOnInit() {
    this.zoom = 15;
    this.searchControl = new FormControl();
    this.getSearchValue();
    /* istanbul ignore next */
    if (this.field?.configuration?.hideSearchLocation) {
      this.hideSearchLocation = this.field?.configuration?.hideSearchLocation;
    }
    this.currentLocationData = this.field?.value[0] ? JSON.parse(this.field.value[0]) : undefined;
    if (this.field.value?.length === 1 && this.currentLocationData?.origin && this.currentLocationData?.destination) {
      this.showNavigation = true;
      this.origin = {
        lat: this.currentLocationData.origin.latitude,
        lng: this.currentLocationData.origin.longitude,
      };
      this.destination = {
        lat: this.currentLocationData.destination.latitude,
        lng: this.currentLocationData.destination.longitude,
      };
      this.wayPoints = this.currentLocationData?.waypoints ? this.currentLocationData?.waypoints : [];

      /* istanbul ignore next */
      if (this.currentLocationData?.status && this.currentLocationData?.status === 'InProgress') {
        this.isDisabledButton.next(true);
      }
    } /* istanbul ignore next */ else if (this.field.value?.length > 1) {
      let loc = JSON.parse(this.field.value[0]);
      this.currentLocationData = {
        latitude: loc.latitude,
        longitude: loc.longitude,
      };
      this.mapData = {
        lat: JSON.parse(this.field.value[0]).latitude,
        lng: JSON.parse(this.field.value[0]).longitude,
        zoomControl: false,
        disableDefaultUI: false,
        zoom: 15,
        markers: [],
      };
      this.field.value?.forEach((location: any, index: number) => {
        // if (index > 0) {
        let locationObj = JSON.parse(location);
        const marker: any = {
          lat: locationObj.latitude,
          lng: locationObj.longitude,
          label: locationObj?.label ? locationObj.label : '',
          draggable: this.field.value.length > 1 ? false : true,
        };
        this.mapData.markers.push(marker);
        // }
      });
      this.showMarkers = true;
      /* istanbul ignore next */
      this.mapsAPILoader?.load()?.then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.mapData.lat = place.geometry.location.lat();
            this.mapData.lng = place.geometry.location.lng();
            const marker: any = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              label: '',
              draggable: true,
            };
            this.mapData.markers = [];
            this.mapData.markers.push(marker);
            this.zoom = 15;
            this.storSearchValue(place?.formatted_address);
            this.markerClick(this.latitude, this.longitude, true);
          });
        });
      });
    } else if (this.field.value?.length > 0) {
      /* istanbul ignore next */
      this.mapData = {
        lat: JSON.parse(this.field.value[0]).latitude,
        lng: JSON.parse(this.field.value[0]).longitude,
        zoomControl: false,
        disableDefaultUI: false,
        zoom: 12,
        markers: [],
      };
      this.field.value?.forEach((location: any, index: number) => {
        if (index > 0) {
          let locationObj = JSON.parse(location);
          const marker: any = {
            lat: locationObj.latitude,
            lng: locationObj.longitude,
            label: locationObj.label,
            draggable: false,
          };
          this.mapData.markers.push(marker);
        }
      });
      this.latitude = JSON.parse(this.field.value[0]).latitude;
      this.longitude = JSON.parse(this.field.value[0]).longitude;
      this.currentLocation = true;
      this.apiLocator();
    } else {
      this.currentLocation = true;
      /* istanbul ignore next */
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.displayLocationstatus = false;
          this.currentlat = +pos.coords.latitude;
          this.currentlng = +pos.coords.longitude;

          if (this.mapData) {
            this.mapData.lat = this.currentlat;
            this.mapData.lng = this.currentlng;
          }

          this.markers = this.mapData.markers;

          if (this.markers.length === 0) {
            this.markers.push({
              lat: this.currentlat,
              lng: this.currentlng,
              label: '',
              draggable:
                this.field?.configuration?.locationType == 'Current Location' &&
                this.field?.configuration?.readCurrentLocation
                  ? false
                  : true,
            });
          }

          this.latitude = this.markers[0].lat;
          this.longitude = this.markers[0].lng;
          this.markerClick(this.latitude, this.longitude, false);
        },
        /* istanbul ignore next */
        (err) => {
          this.displayLocationstatus = true;
        }
      );
      this.apiLocator();
    }
    let locationOptions = getUiControlCustomization('Location');
    if (locationOptions) {
      this.foundObject = { event: locationOptions };
      let i: number;
      let op = locationOptions.slice(-1);
      switch (locationOptions) {
        case 'Option 1':
        case 'Option 2':
          i = op;
          break;
        case 'Option 3':
          i = 4;
          break;
        case 'Option 4':
          i = 6;
          break;
        case 'Option 5':
          i = 1;
          this.parentClass = 'maps-parent-class-styles';
      }
      this.appliedClass = `form-input-var form-input-var-opt${i} placeholder-icon`;
    }
  }

  calRoute(directionsService: google.maps.DirectionsService, directionsRenderer: google.maps.DirectionsRenderer) {
    let wayPointArr: any = [];
    this.wayPoints?.forEach((wayPoint: any) => {
      let waypts = {
        location: new google.maps.LatLng(wayPoint?.location?.lat, wayPoint?.location?.lng),
        stopover: true,
      };
      wayPointArr.push(waypts);
    });
    directionsService.route(
      {
        origin: new google.maps.LatLng(this.origin?.lat, this.origin?.lng),
        destination: new google.maps.LatLng(this.destination?.lat, this.destination?.lng),
        waypoints: wayPointArr,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (res, status) => {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(res);
          let route = res?.routes[0]?.legs;
          route?.forEach((arr: any, i: number) => {
            this.routeArr.push(arr.start_address);
            if (i == route?.length - 1) {
              this.routeArr.push(arr.end_address);
            }
          });
          if (this.routeArr?.length > 0) {
            this.wayPointsLocationData.next(this.routeArr);
          }
        }
      }
    );
  }

  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 6,
        center: { lat: this.origin?.lat, lng: this.origin?.lng },
      });
      directionsRenderer.setMap(map);
      this.calRoute(directionsService, directionsRenderer);
    });
  }

  getValue() {
    let attrList: any = sessionStorage.getItem('attrList');
    let currAttr = this.field?.attribute;

    let attrValue = '';

    /*istanbul ignore else*/
    if (!attrList) return attrValue;
    /*istanbul ignore else*/
    if (currAttr) {
      attrList = JSON.parse(attrList);
      for (let i = 0; i < attrList.length; i++) {
        let attr = attrList[i];
        /*istanbul ignore else*/
        if (attr.id == currAttr.id) {
          attrValue = attr.value;
        }
      }

      return attrValue;
    }
    return attrValue;
  }

  getSearchValue() {
    let searchValue = this.getValue();
    this.searchControl.setValue(searchValue);
  }
  storSearchValue(attrVal: any) {
    let attrList: any = sessionStorage.getItem('attrList');
    if (!attrList) {
      sessionStorage.setItem('attrList', JSON.stringify([]));
      attrList = [];
    } else attrList = JSON.parse(attrList);

    let currAttr = this.field?.attribute;

    /*istanbul ignore else*/
    if (currAttr) {
      let found = false;
      attrList.forEach((attr: any) => {
        /*istanbul ignore else*/
        if (attr.id == currAttr.id) {
          attr.value = attrVal;
          sessionStorage.setItem('attrList', JSON.stringify(attrList));
          found = true;
        }
      });

      /*istanbul ignore else*/
      if (!found) {
        attrList.push({ id: currAttr.id, value: attrVal });
        sessionStorage.setItem('attrList', JSON.stringify(attrList));
      }
    }
  }

  apiLocator() {
    /* istanbul ignore next */
    this.mapsAPILoader?.load()?.then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.markerClick(this.latitude, this.longitude, true);
        });
      });
    });
  }
  /**whenever marker drags lat, lng values will update */
  markerDragEnd($event: any) {
    this.clickedLat = $event.coords.lat;
    this.clickedLng = $event.coords.lng;
    this.markerClick(this.clickedLat, this.clickedLng, $event);
    this.storSearchValue('');
  }

  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange(event: any) {
    if (!this.field.isHidden) {
      this.dateChanged.next(true);
      const data = {
        attributeId: this.field?.attribute?.id,
        isTableConfig: this.field?.attribute?.isTableConfig,
        attrName: this.field?.attribute?.name,
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        isInfo: this.field?.isInfo,
        slotNumber: this.field?.slotNumber,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
      /* conditional potentiality check  */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent('');
      }
    }
  }
  onFocousOut() {
    this.iconSvg?.nativeElement?.classList?.remove('focus-color');
  }
  /**
   * Determines whether input field is focused
   * @param event Contains the input value
   */
  onFocus(event: any) {
    if (!this.field.isHidden) {
      this.iconSvg?.nativeElement?.classList?.add('focus-color');
      const data = {
        attributeId: this.field?.attribute?.id,
        isTableConfig: this.field?.attribute?.isTableConfig,
        attrName: this.field?.attribute?.name,
        eventType: 'ON_FOCUS',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        isInfo: this.field?.isInfo,
        slotNumber: this.field?.slotNumber,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
    }
  }

  /**Whenver we click on marker lat, lng values */
  markerClick(latitude: any, longitude: any, event: any) {
    let currentValue;
    if (this.showNavigation) {
      /* istanbul ignore next */
      currentValue = {
        origin: this.currentLocationData,
        destination: {
          latitude: latitude ? latitude : '',
          longitude: longitude ? longitude : '',
        },
      };
    } else {
      /* istanbul ignore next */
      currentValue = {
        latitude: latitude ? latitude : '',
        longitude: longitude ? longitude : '',
        label: event?.label ? event?.label : this.field?.attribute?.name,
      };
    }
    // this.field.value = JSON.stringify(currentValue);
    /* istanbul ignore next */
    this.group.controls[this.field.attribute?.name]?.setValue(currentValue);
    if (event) {
      this.onChange(event);
    }
    if (this.field?.configuration?.locationType === 'Current Location') {
      this.dateChanged.next(true);
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  callLocationdata() {
    /* istanbul ignore else */
    if (this.callLocation) {
      this.transactionFacadeService
        .getLocationData(this.calllocationData?.transId, this.calllocationData?.cu?.contextualId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          /* istanbul ignore next */
          let data = JSON.parse(
            res?.result?.txnCULayer[0]?.txnSlotItems?.[0]?.item?.DATA?.transEntityRecords[0]?.txnNslAttribute[0]
              ?.values[0]
          );
          // this.mapData.lat = data?.latitude;
          // this.mapData.lng = data?.longitude;
          // this.mapData.markers = [{ lat: data?.latitude, lng: data?.longitude }];
          this.currentLocationData = { origin: { data } };
        });
      /* istanbul ignore next */
      this.locationInterval = setInterval(() => {
        this.transactionFacadeService
          .getLocationData(this.calllocationData?.transId, this.calllocationData?.cu?.contextualId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            let data = JSON.parse(
              res?.result?.txnCULayer[0]?.txnSlotItems?.[0]?.item?.DATA?.transEntityRecords[0]?.txnNslAttribute[0]
                ?.values[0]
            );
            // this.mapData.lat = data?.latitude;
            // this.mapData.lng = data?.longitude;
            // this.mapData.markers = [{ lat: data?.latitude, lng: data?.longitude }];
            this.currentLocationData = { origin: { data } };
          });
      }, 5000);
    }
  }

  getLocationData() {
    this.transactionFacadeService.locationData$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        if (res.owner) {
          this.getCurrentLocationForOwner();
        } else {
          this.showNavigation = true;
          this.callLocation = true;
          this.calllocationData = res;
          this.callLocationdata();
        }
      }
    });
  }

  getCurrentLocationForOwner() {
    navigator.geolocation.getCurrentPosition((pos: any) => {
      /* istanbul ignore next */
      this.currentLocationData = {
        origin: {
          latitude: pos?.coords?.latitude,
          longitude: pos?.coords?.longitude,
        },
      };
    });
    /* istanbul ignore next */
    this.locationInterval = setInterval(() => {
      /* istanbul ignore next */
      navigator.geolocation.getCurrentPosition((pos: any) => {
        this.currentLocationData = {
          origin: {
            latitude: pos?.coords?.latitude,
            longitude: pos?.coords?.longitude,
          },
        };
      });
    }, 5000);
  }

  getSubmiTransaction() {
    this.transactionFacadeService.submitForlocation$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res) {
        clearInterval(this.locationInterval);
        clearInterval(this.toggleInterval);
      }
    });
  }
  ngOnDestroy() {
    clearInterval(this.locationInterval);
    clearInterval(this.toggleInterval);
  }

  openGoogleMaps(origin: any, destination: any, waypoints: any) {
    const originLatitude = origin.lat;
    const originLongitude = origin.lng;
    const destinationLatitude = destination.lat;
    const destinationLongitude = destination.lng;
    let url = '';
    if (waypoints.length > 0) {
      const waypointsString = waypoints
        .map((waypoint: any) => `${waypoint.location.lat},${waypoint.location.lng}`)
        .join('/');
      url =
        `https://www.google.com/maps/dir/${originLatitude},${originLongitude}/${destinationLatitude},${destinationLongitude}` +
        `/${waypointsString}`;
      window.open(url, '_blank');
    } else {
      url = `https://www.google.com/maps/dir/${origin},${destination}`;
      window.open(url, '_blank');
    }
  }
}
