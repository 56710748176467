import { ChangeUnit, Entity, Gsi,Attribute,AttributeType} from "@common-services";


export class DcdValue {
  constructor(
    public layerType: string = '',
    public participatingItems: any[] = [],
    public nslAttributes: any[] = [],
    public selectedEntity: any = new Entity(),
    public attrIndex: number = -1,
    public selectedAttribute: any = new Attribute(),
    public entityIndex: number = -1,
    public selectedChangeUnit: ChangeUnit = new ChangeUnit(),
    public changeUnitIndex: number = -1,
    public selectedGsi: Gsi = new Gsi(),
    public gsiList: Gsi[] = [],
    public gsiIndex: number = -1,
    public solutionLogic: any[] = [],
    public attrPath: any = '',
    public nestedGsi: any = new Gsi(),
    public selectedNestedCU: any = DcdValue,
    public embeddedGsi: any =  DcdValue

  ) {}
}
export class Connector {
  public cuType: string = 'dcd';
  public expressionType: string = 'dcd';
  public formulaId: string;
  public formulaIdWithId: string;
  public isDld: boolean;
  public targetContextualName: string;
  public attributeType: AttributeType;
  public isInDCDGroup: boolean;
  public dcds: any[];
  public condition: string;
  public addSource: any;

  constructor(
    public contextualId?: string,
    public contextualIdType?: string,
    public contextualIdWithId?: string,
    public label?: string,
    public name?: string
  ) {}
}
export interface Operator {
  operator: string;
  name: string;
}
