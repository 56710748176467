import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TreeNode } from 'primeng/api';
import { EventsFacadeService,FieldConfig,Folder,FolderData,LoaderService, Permission, UploadFacadeService, } from '@common-services';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-folderui',
  templateUrl: './folderui.component.html',
  styleUrls: ['./folderui.component.scss'],
})
export class FolderuiComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  changeInPayload: any;
  folders: TreeNode[] = [];
  payload: FormData = new FormData();
  selectedFolders: string = '';
  folderpath: string = '';
  accessType: '' | 'USER' | 'ROLE' = '';
  selectedFolderIndexes: number[] = [];
  permissionList: Permission[] = [];
  selectedPermissions: Permission[] = [];
  selectedPermission: Permission = {} as Permission;
  readWriteAccess: '' | 'READ' | 'WRITE' = '';
  isWrite: boolean = false;
  isRead: boolean = false;
  isWriteAll: boolean = false;
  isReadAll: boolean = false;
  searchString: string = '';
  @ViewChild('ptree') tree: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: {
        field: FieldConfig;
        group: FormGroup;
      };
    },
    private dialogRef: MatDialogRef<FolderuiComponent>,
    private eventService: EventsFacadeService,
    private loaderService: LoaderService,
    private uploadFacadeService: UploadFacadeService,
  ) {
    this.field = this.data?.data?.field;
    this.group = this.data?.data?.group;
    this.detectFolders();
    this.detectRoles();
    this.detectUsers();
  }

  ngOnInit(): void {}

  private detectFolders(): void {
    this.eventService.foldersData$.pipe().subscribe((folderData: FolderData) => {
      if (this.selectedFolders.length == 0) {
        this.folders = this.convertFoldersDataToTreeNode(folderData);
      } else {
        this.loadChildren(folderData);
        this.loaderService.hide();
      }
    });
  }

  private loadChildren(folderData: FolderData): void {
    let folder: TreeNode;
    folder = this.folders[this.selectedFolderIndexes[0]];
    this.selectedFolderIndexes.forEach((index: number, i: number) => {
      if (i != 0) {
        folder = folder.children[index];
      }
    });
    folder.children = this.convertFoldersDataToTreeNode(folderData);
    folder.expanded = true;
  }

  private convertFoldersDataToTreeNode(folderData: FolderData): TreeNode[] {
    let treeNodes: TreeNode[] = [];
    folderData.data.forEach((data: Folder, index: number) => {
      let node: TreeNode = {
        label: data.displayName,
        data: {
          name: data.name,
          dsdId: data.dsdId,
          index: index,
        },
        expandedIcon: data.isFolder ? 'pi pi-folder-open' : 'pi pi-file',
        collapsedIcon: data.isFolder ? 'pi pi-folder-open' : 'pi pi-file',
      };
      treeNodes.push(node);
    });
    return treeNodes;
  }

  nodeSelect(event: any): void {
    if (!(event.node?.children?.length > 0)) {
      this.loaderService.show();
      this.selectedFolderIndexes = [];
      this.selectedFolders = '';
      this.folderpath = '';
      this.collectParent(event.node);
      this.selectedFolderIndexes.reverse();
      this.eventService.getFolders(this.selectedFolders);
    } else {
      event.node.expanded = !event.node.expanded;
    }
  }

  private collectParent(node: any): void {
    if (this.folderpath) {
      this.selectedFolders = node.data.name + '/' + this.selectedFolders;
      this.folderpath = node.data.name + ',' + this.folderpath;
    } else {
      this.selectedFolders = node.data.name;
      this.folderpath = node.data.name;
    }
    this.selectedFolderIndexes.push(node.data.index);
    if (node?.parent) {
      this.collectParent(node.parent);
    }
  }

  private getCurrentFolder(index: number, node: TreeNode): TreeNode {
    if (index >= this.selectedFolderIndexes.length - 1) {
      return node;
    } else {
      return this.getCurrentFolder(index + 1, node.children[this.selectedFolderIndexes[index]]);
    }
  }

  createFolder(): void {
    let folderName: string = this.selectedFolders.split('/').slice(-1)[0];
    let currentFolder: TreeNode = this.getCurrentFolder(0, this.folders[this.selectedFolderIndexes[0]]);
    let node: TreeNode = {
      label: folderName,
      data: {
        name: folderName,
        dsdId: '',
        index: currentFolder?.children?.length ? currentFolder.children.length : 0,
      },
      expandedIcon: 'pi pi-folder-open',
      collapsedIcon: 'pi pi-folder-open',
    };
    if (currentFolder && !currentFolder?.children) {
      currentFolder.children = [];
    }
    if (!currentFolder) {
      this.folders.push(node);
    } else {
      currentFolder.children.push(node);
    }
  }

  recieveFileUploadPayload(event: any): void {
    this.payload.append('displaName', event.displayName);
    this.payload.append('file', event.file);
    this.payload.append('description', event.description);
    this.payload.append('folders', event.folders);
  }

  private convertDataToPermission(data: any, type: 'USER' | 'ROLE' | 'ORGUNIT' | 'ALL') {
    this.permissionList.push({
      id: data.id,
      name: data.name,
      type: type,
      allowedAccess: [],
      owner: false,
      selected: false,
    });
  }

  private detectUsers(): void {
    this.eventService.usersData$.subscribe(
      (data: any) => {
        this.permissionList = [];
        this.loaderService.hide();
        /*istanbul ignore next*/
        if (data && data?.content) {
          data.content.forEach((user: any) => {
            this.convertDataToPermission(user, 'USER');
          });
        } else if (data) {
          data.forEach((user: any) => {
            this.convertDataToPermission(user, 'USER');
          });
        }
      },
      (error: any) => {
        this.loaderService.hide();
        console.log(error);
      }
    );
  }

  private detectRoles(): void {
    this.eventService.rolesData$.subscribe((data: any) => {
      this.permissionList = [];
      this.loaderService.hide();
      /*istanbul ignore next*/
      if (data && data?.rolesList) {
        data.rolesList.forEach(
          (role: any) => {
            this.convertDataToPermission(role, 'ROLE');
          },
          (error: any) => {
            this.loaderService.hide();
            console.log(error);
          }
        );
      } else if (data) {
        data.forEach(
          (role: any) => {
            this.convertDataToPermission(role, 'ROLE');
          },
          (error: any) => {
            this.loaderService.hide();
            console.log(error);
          }
        );
      }
    });
  }

  changeAccess(event: any, access?: string): void {
    /*istanbul ignore next*/
    if (access === 'access') {
      this.searchString = '';
    }
    if (this.accessType === 'USER') {
      this.loaderService.show();
      this.eventService.getUsers(this.searchString);
    } else if (this.accessType === 'ROLE') {
      this.loaderService.show();
      this.eventService.getRoles(this.searchString);
    } else {
      this.permissionList = [];
    }
  }

  selectPermission(event: any): void {
    this.selectedPermission = this.permissionList.find((permission: Permission) => {
      return permission.name === event.option.value;
    });
    let permission = JSON.parse(JSON.stringify(this.selectedPermission));
    this.selectedPermissions.push(permission);
    this.selectedPermission = {} as Permission;
  }

  submit(): void {
    this.permissionList.forEach((permission: Permission) => {
      if (this.isWrite) permission.allowedAccess.push('WRITE');
      if (this.isRead) permission.allowedAccess.push('READ');
    });
    /* istanbul ignore else */
    if (this.accessType == 'ROLE' && !(this.isWriteAll || this.isReadAll)) {
      this.payload.append('rolePermissions', JSON.stringify(this.selectedPermissions));
      this.payload.append('isSecuredFile', 'true');
    } else if (this.accessType == 'USER' && !(this.isWriteAll || this.isReadAll)) {
      this.payload.append('userPermissions', JSON.stringify(this.selectedPermissions));
      this.payload.append('isSecuredFile', 'true');
    } else if (this.isWriteAll || this.isReadAll) {
      let permission: Permission = {
        id: -1,
        type: 'ALL',
        allowedAccess: [],
        owner: false,
      };
      if (this.isWriteAll) permission.allowedAccess.push('WRITE');
      if (this.isReadAll) permission.allowedAccess.push('READ');
      this.payload.append('rolePermissions', JSON.stringify([permission]));
    }
    this.sendRequest();
  }
  removePermission(index: number): void {
    this.selectedPermissions.splice(index, 1);
  }

  private sendRequest(): void {
    this.uploadFacadeService.dynamicFormUploadFiles(this.payload, 1).subscribe(
      (data: any) => {
        this.loaderService.hide();
        this.dialogRef.close({ value: JSON.stringify(data) });
      },
      (err: any) => {
        this.loaderService.hide();
        this.dialogRef.close();
      }
    );
  }
  // removePermission(index: number): void {
  //   this.selectedPermissions.splice(index, 1);
  // }
}
