import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgScormPlayerService } from 'ng-scorm-player';
import { EventsFacadeService, FieldConfig, TransactionFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { distinct, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-scormPlayer',
  template: `
    <div>
      <div class="row">
        <span class="col-auto"
          ><a
            class="inactive thumbnail-icon"
            (click)="fsEvent()"
            [ngClass]="fsscorm ? 'active' : 'inactive'"
          >
            <!-- <img class="file-irdr-icon" src="../../../assets/images/ui-controls/icon-scrom-video.svg" alt="" /> -->

            <img src="./../../../../../assets/img/zoom.PNG" alt="" />
          </a></span
        >
      </div>
      <div class="scorm-wrapper" [ngClass]="fsscorm ? 'active' : 'inactive'">
        <a (click)="fsEvent()" class="close-fs" [ngClass]="fsscorm ? 'active' : 'inactive'">x</a>
        <ng-scorm-player class="ng-scorm scorm-player-vedio-panel" [urlLaunchPage]="field.value"> </ng-scorm-player>
      </div>
    </div>
  `,
  styles: [],
})
export class ScormPlayerComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  ngUnsubscribe = new Subject();
  fsscorm = false;
  fsEvent() {
    this.fsscorm = !this.fsscorm;
  }
  constructor(
    private player: NgScormPlayerService,
    private eventsService: EventsFacadeService,
    private transacationFacadeService: TransactionFacadeService
  ) {}

  ngOnInit(): void {
    this.transacationFacadeService.disableSubmitButton(true);
    this.player.initializeEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {});
    /* istanbul ignore next */
    this.player.setValueEvent
      .pipe(
        distinct((e) => e['runtimeData']['cmi.score.raw']),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((val) => {
        this.eventsService.updateScormData(val);
        if (!this.field.isInfo && !this.field.isOptional) {
          this.eventsService.isPhysicalLayerVideos(true);
          if (this.getScormValueCalculate(val.runtimeData?.['cmi.score.raw']) == 10) {
            this.transacationFacadeService.disableSubmitButton(false);
          }
        }
      });
    this.player.getValueEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {});
    this.player.finishEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {});
    this.player.commitEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {});
  }

  getScormValueCalculate(currentScore: any) {
    let score: any;
    currentScore = currentScore == '' || currentScore === undefined ? 0 : parseInt(currentScore);
    score = currentScore === 100 ? 10 : 0;
    return score;
  }

  ngOnDestroy() {
    this.eventsService.updateScormData('');

    this.player.LMSFinish(true);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
