import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
//@ts-ignore
import domtoimage from 'dom-to-image';
//@ts-ignore
import * as cornerstone from 'cornerstone-core';
//@ts-ignore
import * as cornerstoneTools from 'cornerstone-tools';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { A2aFacadeService, AlertService, ChangeUnitFacadeService, FieldConfig, LoaderService, TransactionFacadeService } from '@common-services';
import { RedacMappingComponent } from '../../redac-mapping/redac-mapping.component';
@Component({
  selector: 'app-dicom-integration',
  templateUrl: './dicom-integration.component.html',
  styleUrls: ['./dicom-integration.component.scss'],
})
export class DicomIntegrationComponent implements OnInit {
  // @Input() src:any;
  config = {
    fileUrl: '',
    tools: [
      {
        name: 'DragProbe',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Eraser',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'StackScrollMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Active,
      },
      {
        name: 'Rotate',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Pan',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'ZoomMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Length',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Angle',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'FreehandRoi',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
      {
        name: 'Wwwc',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Passive,
      },
    ],
    classList: '',
  };
  enableTools: boolean = false;
  viewerProvider: IViewerProvider = {
    element: null,
    cornerstoneTools: cornerstoneTools,
    cornerstone: cornerstone,
    playClip: {
      play(frameRate: number) {},
      stop() {},
    },
  };
  tenantId: any;
  currentCu: any;
  ontology: any;
  fileSave: any;
  private unsubscribe$ = new Subject<any>();
  field: FieldConfig;
  redactedResult: any;
  constructor(
    public transactionfacadeService: TransactionFacadeService,
    private changeunitFacade: ChangeUnitFacadeService,
    private a2afacade: A2aFacadeService,
    private loader: LoaderService,
    private alertservice: AlertService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tenantId = localStorage.getItem('envClientId')
      ? localStorage.getItem('envClientId')
      : sessionStorage.getItem('TenantId');
  }

  ngOnInit(): void {
    /*istanbul ignore else */
    if (this.data) {
      /*istanbul ignore next */
      this.loadDICOMImages(this.data?.file?.contentUrl);
    }
  }
  /**
   * Load selected DICOM images
   *
   * @param files list of selected dicom files
   */
  loadDICOMImages(event: any) {
    this.config.fileUrl = event;
    this.enableTools = true;
  }

  // exportStateToJson(): void {
  //   this.download(
  //     JSON.stringify(this.viewerProvider?.cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState()),
  //     'toolsState.json',
  //     'application/json'
  //   )
  // }

  // saveAs(): void {
  //   this.viewerProvider?.cornerstoneTools.SaveAs(this.viewerProvider?.element, 'screenshot.png');
  // }

  activateTool(name: string): void {
    /*istanbul ignore next*/
    const foundTool = this.config.tools?.find((tool) => tool.name === name);
    /*istanbul ignore else */
    if (foundTool && this.viewerProvider) {
      /*istanbul ignore next */
      this.viewerProvider?.cornerstoneTools['setToolActive'](name, foundTool.options);
    }
  }

  isActive(tool: any): boolean {
    /*istanbul ignore next*/
    return this.viewerProvider?.cornerstoneTools?.isToolActiveForElement(this.viewerProvider?.element, tool.name);
  }

  // private download(content: any, fileName: string, contentType: string) {
  //   const a = document.createElement("a");
  //   const file = new Blob([content], {type: contentType});
  //   a.href = URL.createObjectURL(file);
  //   a.download = fileName;
  //   a.click();
  // }

  initializeProvider(event: any) {
    this.viewerProvider = event;
  }

  stop() {
    /*istanbul ignore next */
    this.viewerProvider?.playClip?.stop();
  }
  play(frame: any) {
    /*istanbul ignore next */
    this.viewerProvider?.playClip?.play(frame);
  }

  redactionSelect() {
    console.log(this.viewerProvider.element);
    /*istanbul ignore next */
    let payload = {
      file_link: this.data?.file?.contentUrl,
    };
    this.loader.show();
    this.transactionfacadeService
      .dicomFiletoJpg(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        console.log(res);
        this.fileSave = res;
        /*istanbul ignore next */
        let redacmapdata = {
          src: res.file_link, //jpg file send for draawing boxes and all
          originalDocumentSize: res?.ref_dimensions,
          coordinates: res?.redacted_boxes ? res?.redacted_boxes : [],
          expanded: true,
          fileType: 'image',
          editable: true,
          attributeId: this.data?.attributeId,
          entityName: this.data?.entityName,
          attributeName: this.data?.attributeName,
          uiCtrl: this.data?.uiCtrl, // sent input from fileupload and redact comp useful to distinguish in redac-mapping component,
          dicomfile: this.data?.file?.contentUrl, // dicom file also need to be sent for final submit
        };
        this.dialog.open(RedacMappingComponent, {
          height: '100%',
          width: '1000px',
          panelClass: 'dialogue-pop',
          data: redacmapdata,
        });
      });
  }
}

interface IViewerProvider {
  element: any;
  cornerstoneTools: any;
  cornerstone: any;
  playClip?: {
    play(frameRate: number): void;
    stop(): void;
  };
}
enum ToolModeEnum {
  Active = 'Active',
  Passive = 'Passive',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}
/**
 * Active tools will render and respond to user input. Active tools are able to create new annotations and/or measurements.
 * Passive tools will render and passively respond to user input. Their data can be manipulated, but not created.
 * Enabled tools will render, but will not respond to input. The "enabled" tool state is essentially a "read-only" state.
 * The default state for a tool. Tools that are disabled cannot be interacted with and are not rendered on the enabled element.
 */
/**
 * // Set the tool font and font size
const fontFamily =
  'Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';

cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);

// Set the tool width
cornerstoneTools.toolStyle.setToolWidth(2);

// Set color for inactive tools
cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');

// Set color for active tools
cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');

// and so on...
 */
// { let element = this.viewerProvider.element;
// const formdata = new FormData();

// domtoimage
//   .toPng(element)
//   .then((dataUrl: any) => {
//     let file = this.dataURLtoBlob(dataUrl);
//     formdata.append('file', file);
//     this.fileUpload(formdata);
//   })
//   .catch((error: any) => {
//     console.error('oops, something went wrong! try again', error);
//   });
// }
// dataURLtoBlob(dataurl: any) {
//   const arr = dataurl.split(',');
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   let u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new Blob([u8arr], { type: mime });
// }

// fileUpload(formData: any) {
//   this.loader.show();
//   /* istanbul ignore next */
//   this.transactionfacadeService
//     .dynamicFormUploadFiles(formData)
//     .pipe(takeUntil(this.unsubscribe$))
//     .subscribe(
//       (res: any) => {
//         if (res) {
//           this.loader.hide();
//           console.log(res);
//           this.fileSave = res;
//           if (this.ontology && this.fileSave) {
//             this.redact();
//           } else {
//             this.alertservice.showToaster('Unable to find ontology', '', 'warning');
//           }
//         }
//       },
//       (err: any) => {
//         this.alertservice.showToaster('Unable to find ontology', '', 'warning');
//         console.log(err);
//       }
//     );
// }
// redact() {
//   /*istanbul ignore next*/
//   let payLoad = {
//     file_link: this.fileSave.contentUrl,
//     ontology: this.ontology,
//   };
//   this.loader.show();
//   this.a2afacade
//     .redactData(payLoad)
//     .pipe(takeUntil(this.unsubscribe$))
//     .subscribe(
//       (res: any) => {
//         /*istanbul ignore next*/
//         if (res) {
//           this.redactedResult = res;
//           let data = {
//             src: res.file_url,
//             originalDocumentSize: res?.ref_dimensions,
//             coordinates: res?.redacted_boxes,
//             expanded: true,
//             fileType: 'image',
//             editable: true,
//             attributeId: this.data?.attributeId,
//             entityName: this.data?.entityName,
//             attributeName: this.data?.attributeName,
//           };
//           this.loader.hide();
//           this.dialog.open(RedacMappingComponent, {
//             height: '100%',
//             width: '1000px',
//             panelClass: 'dialogue-pop',
//             data: data,
//           });
//         }
//       },
//       (error: any) => {
//         this.loader.hide();
//         this.alertservice.showToaster(error.message, '', 'error');
//       }
//     );
// }
// detectCurrentCu() {
//   this.transactionfacadeService.currentCuDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
//     console.log(res);
//     this.currentCu = res?.result?.currentCU;
//   });
// }
// getMetaData() {
//   this.changeunitFacade.metaDataDetails$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
//     if (res) {
//       console.log(res);
//       let cuData = res?.result?.solutionLogic;
//       cuData.forEach((data: any) => {
//         if (data.DATA?.id == this.currentCu?.id) {
//           this.a2afacade.getOntologyInfo(data.DATA.dsdMetadataId, this.tenantId);
//         }
//       });
//     }
//   });
// }
// getOntologyDetails() {
//   this.a2afacade.ontologySubject$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
//     console.log(res);
//     this.ontology = res.result.ontology[res.result.ontology.length - 1]?.id;
//   });
// }
