import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../materail/material.module';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { CardViewControlComponent } from './card-view-control.component';

@NgModule({
  declarations: [CardViewControlComponent],
  imports: [MaterialModule, CommonModule, FormsModule, ReactiveFormsModule, ApplicationPipesModule],
  exports: [CardViewControlComponent],
})
export class CardViewControlModule {}
