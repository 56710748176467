import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  displayDateDefaultValue,
  EventsFacadeService,
  IsJsonString,
  TranslatorService,
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-datetime-type-widgets',
  templateUrl: './datetime-type-widgets.component.html',
  styleUrls: ['./datetime-type-widgets.component.scss'],
})
export class DatetimeTypeWidgetsComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() type: any;
  @Input() label: any;
  @Input() dateTypeValue: any; // For date, datetime, dateRange
  @Input() dateFormatType?: string = 'MM/dd/yyyy';
  @Input() CCD : boolean = false;
  primeDateFormatType: string = 'mm/dd/yy';
  primePlaceholder: string = '';
  @Input() timeFormatType?: string;
  @Input() yearMonthFormatType?: string = 'yyyy-MM';
  @Input() customDateFormat?: string = '';
  @Output() outputValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabledDays : any =[];
  displayDateTypeValue: any; // For display purpose
  yearRange: string;
  labels: any;
  tempValue: any = null;
  private ngUnsubscribe = new Subject();

  constructor(
    private eventsService: EventsFacadeService,
    private dateAdapter: DateAdapter<any>,
    private translator: TranslatorService
  ) {
    const year = new Date().getFullYear();
    this.yearRange = year - 100 + ':' + (year + 100);
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    if(this.CCD  && this.dateTypeValue) this.dateTypeValue = new Date(this.dateTypeValue);
  }

  onBlur(event?: any) {
    if (this.dateTypeValue != this.tempValue) {
      this.emitValue(this.dateTypeValue);
    }
  }

  emitValue(event?: any) {
    this.tempValue = this.dateTypeValue;
    /* istanbul ignore next */
    if (
      this.type === 'dateRange' &&
      (!this.dateTypeValue?.[0] || !this.dateTypeValue?.[1])
    ) {
      return;
    }
    /* istanbul ignore next */
    if (this.type === 'time') {
      this.dateTypeValue = event;
      this.displayDateTypeValue = event;
    }
    /* istanbul ignore next */
    if (this.type === 'dateRange') {
      this.dateTypeValue = JSON.stringify(this.dateTypeValue);
    }

    if (
      this.type === 'date' ||
      this.type === 'datetime' ||
      this.type === 'dateonly' ||
      this.type === 'newdate' ||
      this.type === 'newdatetime' ||
      this.type === 'newtime' ||
      this.type === 'time' ||
      this.type === 'timeonly' ||
      this.type === 'timer'
    ) {
      this.dateTypeValue = event;
    }
    if (this.type !== 'time') {
      this.primeDateFormatType = this.eventsService.convertDateFormat(
        this.dateFormatType
      );
      if (this.type === 'newdate' || this.type === 'date' || this.type === 'dateonly') {
        this.primePlaceholder = this.dateFormatType;
      } else if (this.type === 'newdatetime' || this.type === 'datetime') {
        this.primePlaceholder = this.dateFormatType + ' ' + this.timeFormatType;
      } else if (
        this.type === 'newtime' ||
        this.type === 'time' ||
        this.type === 'timeonly' ||
        this.type === 'timer'
      ) {
        this.primePlaceholder = this.timeFormatType;
      }
      this.displayDateTypeValue = this.displayDateDefaultValue(
        this.dateTypeValue,
        this.type
      );
    }
    this.outputValue.emit({
      actualValue: this.dateTypeValue,
      displayValue: this.displayDateTypeValue,
    });
  }

  nowClicked() {
    this.emitValue(new Date());
  }

  displayDateDefaultValue(value: any, attributeUiElement: any) {
    return displayDateDefaultValue(
      value,
      attributeUiElement,
      this.dateFormatType,
      this.timeFormatType,
      this.yearMonthFormatType,
      this.customDateFormat
    );
  }

  // For yearMonth UI control
  /* istanbul ignore next */
  chosenYearHandler(normalizedYear: any) {
    this.dateTypeValue = new Date(normalizedYear);
  }

  // For yearMonth UI control
  /* istanbul ignore next */
  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.dateTypeValue = new Date(normalizedMonth);
    /* istanbul ignore next */
    datepicker?.close();
    this.emitValue();
  }

  /* istanbul ignore next */
  chosenYearForYear(normalizedYear: any, yearPicker: MatDatepicker<any>) {
    this.dateTypeValue = new Date(normalizedYear).getFullYear();
    /* istanbul ignore next */
    yearPicker?.close();
    this.emitValue();
  }

  ngOnChanges() {
    /* istanbul ignore next */
    if (
      (this.type === 'date' || this.type === 'datetime') &&
      typeof this.dateTypeValue === 'string' &&
      this.type !== 'time'
    ) {
      /* istanbul ignore next */
      if (!this.dateTypeValue) {
        this.dateTypeValue = null;
      } else {
        /* istanbul ignore next */
        if (this.dateTypeValue?.length > 24) {
          /* istanbul ignore next */
          this.dateTypeValue = this.dateTypeValue?.substring(
            this.dateTypeValue?.length - 24,
            this.dateTypeValue?.length
          );
        }
        this.dateTypeValue = new Date(this.dateTypeValue);
      }
    }

    if (
      this.type === 'dateRange' &&
      this.dateTypeValue &&
      IsJsonString(this.dateTypeValue)
    ) {
      this.displayDateTypeValue = JSON.parse(this.dateTypeValue);
      this.displayDateTypeValue[0] = new Date(this.displayDateTypeValue[0]);
      this.displayDateTypeValue[1] = new Date(this.displayDateTypeValue[1]);
    } else {
      this.displayDateTypeValue = undefined;
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.labels = res;
        this.dateAdapter.setLocale(localStorage.locale);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
