<table>
  <tr>
    <td>
      <input
        type="number"
        id="duration_days"
        (keypress)="onKeyPress($event)"
        min="0"
        placeholder="00"
        [(ngModel)]="durationValue.days"
        (ngModelChange)="onDurationChange()"
      />
      {{ labels?.Days }}
    </td>
    <td>
      <input
        type="number"
        id="duration_hours"
        placeholder="00"
        (keypress)="onKeyPress($event)"
        min="0"
        max="23"
        [(ngModel)]="durationValue.hours"
        (ngModelChange)="onDurationChange()"
      />
      {{ labels?.Hours }}
    </td>
    <td>
      <input
        type="number"
        id="duration_minutes"
        placeholder="00"
        (keypress)="onKeyPress($event)"
        min="0"
        max="59"
        [(ngModel)]="durationValue.minutes"
        (ngModelChange)="onDurationChange()"
      />
      {{ labels?.Minutes }}
    </td>
  </tr>
</table>
