import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService, LoaderService, setAdvSearchConfig } from '@common-services';
import { advSearchAdminConfigMapper } from '../mappers/advsearch-admin-config/advsearch-admin-config-mapper';
@Injectable({
  providedIn: 'root',
})
export class ManagerEnironmentService extends CduiBaseService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  getAdvSearchConfigSubject = new Subject();
  getAdvSearchConfig$ = this.getAdvSearchConfigSubject.asObservable();

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private loader: LoaderService,
    private alertService: AlertService
  ) {
    super(injector);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getEnvironmentsForTenant(tenantName: any) {
    let tenantId = localStorage.getItem('TenantId');
    if (tenantName) {
      return this.http.get(`${this.api?.environmentsForTenant}${tenantName}`);
    } else {
      return this.http.get(`${this.api?.environmentsForTenant}${tenantId}`);
    }
  }
  userCustomAttributes(clientId: any) {
    return this.http.get(this.api?.userCustomAttributes + `${clientId}`);
  }
  getRemainingEnv(tenantName: any) {
    return this.http.get(`${this.api?.remainingEnv}${tenantName}`);
  }

  createEnvironment(data: any) {
    return this.http.post(this.api?.createEnvironment, data);
  }

  publishGsiToEnv(env: any, gsiId: any, promoteWithDTRT: boolean) {
    return this.http.post(
      this.api?.importexport + `/Promote/GSI?id=${gsiId}&envType=${env}&promoteDtrt=${promoteWithDTRT}`,
      ''
    );
  }

  getEnvCount(envName: any) {
    return this.http.get(this.api?.cdmTenantUrl + `/allEnvMetrics/`);
  }

  updateEnvironmentDetails(envData: any, envType: any) {
    return this.http.put(`${this.api?.createEnvironment}/${envType}`, envData);
  }

  fetchUsersList(envType: any, searchText: any, pageNum: any, pageSize: any) {
    return this.http.get(
      `${this.api?.getUsersList}/${envType}?column=name&isAsc=true&keyword=${searchText}&pageNo=${pageNum}&pageSize=${pageSize}`
    );
  }

  fetchUnassignedUsersList(envType: any, searchText: any, pageNum: any, pageSize: any) {
    return this.http.get(
      `${this.api?.getUnassignedUsersList}/${envType}?column=name&isAsc=true&keyword=${searchText}&pageNo=${pageNum}&pageSize=${pageSize}`
    );
  }

  unassignUsers(list: any, envType: any) {
    return this.http.put(`${this.api?.unassignUsers}/${envType}`, list);
  }

  assignUsers(list: any, envType: any) {
    return this.http.post(`${this.api?.assignUsers}?environmentType=${envType}`, list);
  }
  saveEntityConfiguration(data: any) {
    return this.http.post(`${this.api?.saveEntityConfig}`, data);
  }

  getEntityConfig(tenantId: string) {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //   }),
    // };
    let tenantName = tenantId ? tenantId : localStorage.getItem('TenantName');
    return this.http.get(`${this.api?.getEntityConfig}${tenantName}`);
  }

  getAdvanceSearchConfigEndPt(tenantName: string) {
    let tenantId = tenantName ? tenantName : localStorage.getItem('TenantName');
    return this.http.get(this.api?.getAdvSearchConfig + tenantId);
  }

  getAdvanceSearchConfig(label?: string, tenantName?: string) {
    this.loader.show();
    this.getAdvanceSearchConfigEndPt(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res) {
          if (label == 'admin') {
            const data: any = advSearchAdminConfigMapper(res?.result);
            this.getAdvSearchConfigSubject.next(data);
            this.alertService.showToaster(res?.message, '', 'success');
          } else {
            setAdvSearchConfig(res?.result);
          }
        }
      });
  }

  saveAdvanceSearchConfigEndPt(data: any) {
    return this.http.post(`${this.api?.saveAdvSearchConfig}`, data);
  }

  saveAdvanceSearchConfig(data: any) {
    this.loader.show();
    this.saveAdvanceSearchConfigEndPt(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res) {
            this.alertService.showToaster(res?.message, '', 'success');
          }
        },
        (error: any) => {
          this.alertService.showToaster(error?.message, '', 'error');
        }
      );
  }
}
