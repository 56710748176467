import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SHADOWTEMPLATES } from '../constants/shadow-templates.constants';
@Component({
  selector: 'app-template-type-dialog',
  templateUrl: './template-type-dialog.component.html',
  styleUrls: ['./template-type-dialog.component.scss'],
})
export class TemplateTypeDialogComponent implements OnInit {
  shadowTemplatesArray: any[];
  selectedShadow: shadowTemplateData;
  selectedShadowNumber: any;
  constructor(
    public dialogRef: MatDialogRef<TemplateTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: shadowTemplateData
  ) {}

  ngOnInit(): void {
    this.shadowTemplatesArray = SHADOWTEMPLATES;
  }

  /**
   *
   * @param shadowTemplate - the shadow template consists of the selected shadow template's id and boxshadow
   * @description This function is responsible to assign id of selected shadow template to the selectedShadowNumber
   */
  shadowTemplateSelection(shadowTemplate: any) {
    this.selectedShadow = shadowTemplate;
    this.selectedShadowNumber = this.selectedShadow?.id;
  }

  /**
   * @description This function closes the opened dialog box of shadow templates
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}

interface shadowTemplateData {
  id: string;
  boxshadow: string;
}
