import { Injectable, Injector, OnDestroy } from '@angular/core';
import { CduiBaseService } from '@common-services';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class NodeGrammarService extends CduiBaseService implements OnDestroy {
  private nodeGSI = new BehaviorSubject('');
  nodeGSI$ = this.nodeGSI.asObservable();

  constructor(private injector: Injector) {
    super(injector);
  }
  ngOnDestroy(): void {}

  sendData(data: any) {
    if(data) window.nodeGSI = data;
    else {
      if(window.nodeGSI) delete window.nodeGSI;
    }
    this.nodeGSI.next(data);
  }
}
declare global {
  interface Window { nodeGSI: any; }
}
