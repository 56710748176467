import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    const irdrFlag = localStorage.getItem('bookIrdrFlag');
    /* istanbul ignore next */
    const adminInfo = next?.data.adminInfo;
    /* istanbul ignore next */
    if (
      (adminInfo == 'tenantAdmin' || adminInfo === 'tenantPlatformAdmin') &&
      (activeUserFlag == 'Tenant_Admin' || activeUserFlag == 'Environment_Admin')
    ) {
      return true;
    } /* istanbul ignore next */ else if (
      (adminInfo == 'platformAdmin' || adminInfo === 'tenantPlatformAdmin' || adminInfo == 'TnCAdmin') &&
      (activeUserFlag == 'Platform_Admin' || irdrFlag == 'irdr_book' || activeUserFlag == 'TnC_Admin')
    ) {
      return true;
    } /* istanbul ignore next */ else if (
      adminInfo == 'admin' &&
      (activeUserFlag == 'Tenant_Admin' ||
        activeUserFlag == 'Platform_Admin' ||
        irdrFlag == 'irdr_book' ||
        activeUserFlag == 'Environment_Admin' ||
        activeUserFlag == 'FeatureFlag_Admin' ||
        activeUserFlag == 'TnC_Admin')
    ) {
      return true;
    } /* istanbul ignore next */ else if (
      activeUserFlag == 'selfonboard_Admin' ||
      activeUserFlag == 'systemintegrator_Admin' ||
      activeUserFlag == 'b2b_Admin' ||
      activeUserFlag == 'sdp_Admin' ||
      activeUserFlag == 'bdp_Admin' ||
      activeUserFlag == 'freelancer_Admin'
    ) {
      return true;
    } else {
      this.router.navigateByUrl('/mylibrary-dashboard');
    }
  }
}
