<div class="comments-ratings-wrapper">
  <div class="nh-mt-30">
    <div class="row">
      <div class="col-6">
        <p>Customer reviews</p>
        <div class="rating-box nh-mb-16">
          <i *ngFor="let star of globalRatingStars; let i = index" attr.dataStar="{{ star.width }}" [id]="i"></i>
        </div>
        <p class="nh-mb-10">{{ ratingStats?.averageProductRating }} out of 5</p>
        <p>{{ ratingStats?.userCount }} global ratings</p>
      </div>
      <div class="col-6">
        <div class="d-flex bar-container" (click)="_selectedRating = '5'">
          <p>5 Star</p>
          <div [ngClass]="{ active: selectedRating == '5' }" class="bar">
            <div [ngStyle]="{ width: ratingPercentages?.fiveStarPercentage }" class="bar-length"></div>
          </div>
          <div>{{ ratingPercentages?.fiveStarPercentage }}</div>
        </div>
        <div class="d-flex bar-container" (click)="_selectedRating = '4'">
          <p>4 Star</p>
          <div [ngClass]="{ active: selectedRating == '4' }" class="bar">
            <div [ngStyle]="{ width: ratingPercentages?.fourStarPercentage }" class="bar-length"></div>
          </div>
          <div>{{ ratingPercentages?.fourStarPercentage }}</div>
        </div>
        <div class="d-flex bar-container" (click)="_selectedRating = '3'">
          <p>3 Star</p>
          <div [ngClass]="{ active: selectedRating == '3' }" class="bar">
            <div [ngStyle]="{ width: ratingPercentages?.threeStarPercentage }" class="bar-length"></div>
          </div>
          <div>{{ ratingPercentages?.threeStarPercentage }}</div>
        </div>
        <div class="d-flex bar-container" (click)="_selectedRating = '2'">
          <p>2 Star</p>
          <div [ngClass]="{ active: selectedRating == '2' }" class="bar">
            <div [ngStyle]="{ width: ratingPercentages?.twoStarPercentage }" class="bar-length"></div>
          </div>
          <div>{{ ratingPercentages?.twoStarPercentage }}</div>
        </div>
        <div class="d-flex bar-container" (click)="_selectedRating = '1'">
          <p>1 Star</p>
          <div [ngClass]="{ active: selectedRating == '1' }" class="bar">
            <div [ngStyle]="{ width: ratingPercentages?.oneStarPercentage }" class="bar-length"></div>
          </div>
          <div>{{ ratingPercentages?.oneStarPercentage }}</div>
        </div>
      </div>
    </div>
    <div class="nh-mt-30">
      <p class="nh-mb-10">Review this product</p>
      <p class="nh-mb-20">Share your thoughts with other customers</p>
      <div class="review-btn" *ngIf="!showUserReview">
        <button class="review-btn-secondary" (click)="toggleUserReview(true)">Write a product review</button>
      </div>
      <div *ngIf="showUserReview">
        <div class="rating-box nh-mb-16">
          <i
            class="cursor-pointer"
            *ngFor="let star of stars; let i = index"
            attr.dataStar="{{ star.width }}"
            [id]="i"
            (click)="findAndSetRating($event, i)"
          ></i>
        </div>
        <div>
          <textarea class="reveiw-form-control" [(ngModel)]="review"></textarea>
        </div>
        <div class="d-flex justify-content-end">
          <button class="rating-btn-submit" (click)="addCommentToTheUser()">Submit</button>
          <button class="rating-btn-submit" (click)="toggleUserReview(false)">Cancel</button>
        </div>
      </div>
    </div>
    <div class="nh-mt-30">
      <p class="nh-mb-16">Reviews</p>
      <div class="reviews-wrapper">
        <p-dropdown
          class="reviews-age-dropdown"
          [options]="sortDropDownOptions"
          [(ngModel)]="selectedSortOption"
          optionLabel="displayValue"
          (onChange)="getAllComments()"
        ></p-dropdown>
        <div class="review-keyword-wrapper">
          <input
            class="review-keyword-input"
            type="text"
            placeholder="Search KeyWord"
            [(ngModel)]="keyword"
            (ngModelChange)="this.keyWordSubject.next($event)"
          />
          <em class="nh-icon nh-icon-search review-search-icon"></em>
        </div>
      </div>
      <div [id]="comment?.userName" class="review-box" *ngFor="let comment of commentsAndRatings; let i = index">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <img *ngIf="comment?.imageURL" [src]="comment?.imageURL" alt="no-image" class="review-profile" />
              <div *ngIf="!comment?.imageURL" class="review-profile review-profile-logo">
                {{ logo[i] }}
              </div>
            </div>
            <div>
              <p class="nh-pb-8">{{ comment?.userName }}</p>
              <p class="nh-pb-16">{{ comment?.updatedAt }}</p>
              <p class="text-break">
                {{ comment?.reviewDescription }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-start">
            <div class="review-rating-star full-star"></div>
            <p>{{ comment?.productRating }}/5</p>
          </div>
        </div>
      </div>
      <div *ngIf="showPagination" class="view-more-rev-wrapper">
        <p class="text-truncate" (click)="addAdditionalReviews()">View More</p>
        <em class="nh-icon nh-icon-triangle-fill viewmore-triangle-icon"></em>
      </div>
    </div>
  </div>
</div>
