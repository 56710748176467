import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'OperatorsPipe',
  pure: true,
})
export class OperatorsPipe implements PipeTransform {
  /* istanbul ignore next */
  transform(item?: any, action?: any): any {
    /* istanbul ignore next */
    if (item && item == '==' && action == 'operator') {
      return '=';
    } else if (item && action == 'display') {
      return item?.replaceAll('==', '=');
    } else {
      return item;
    }
  }
  isNum(val: any) {
    return !isNaN(val);
  }
}
