import { Component, OnInit } from '@angular/core';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-progressbar',
  styleUrls: ['./progressbar.component.scss'],
  template: `<form class="demo-full-width">
    <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
      <label
        class="main-label nh-mb-0"
        pTooltip="{{ field?.label }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >{{ field.label }} <span class="main-label-colon">:</span>
        <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
      </label>
      <p
        class="nh-ml-6 d-flex"
        *ngIf="field?.configuration?.showHelperTextIcon"
        [ngStyle]="{
          color: field?.configuration?.helperTextColor,
          'font-size': field?.configuration?.helperTextFont
        }"
      >
        <mat-icon
        [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
          class="material-icons-outlined helperText-infoIcon"
          matTooltip="{{ field?.configuration?.helpertext }}"
          matTooltipPosition="above"
          *ngIf="!field?.hideLabels"
          >info_outline</mat-icon
        >
      </p>
    </div>
    <p
      class="top-text"
      *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <mat-progress-bar mode="determinate" [value]="field.value" class="custom-progress-bar"></mat-progress-bar>    <mat-icon
      matTooltip="Sub-Transactional CU"
      matSuffix
      *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
      ><span class="sub-cu" (click)="openSubPopUp()"></span>
    </mat-icon>
    <p
      class="bottom-text"
      *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
  </form>`,
  styles: [],
})
export class ProgressbarComponent implements OnInit {
  field: FieldConfig;
  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void { }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
}
