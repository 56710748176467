import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { ListComponent } from './list.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [ListComponent],
  imports: [ControlsMaterialModule,TooltipModule],
  exports: [ListComponent],
})
export class ListModule {
  static entry = {
    list: ListComponent,
  };
}
