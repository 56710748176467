import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService, TransactionFacadeService, getCurrentEventDataArray, getEventData } from '@common-services';

@Component({
  selector: 'app-template-two-card',
  templateUrl: './template-two-card.component.html',
  styleUrls: ['./template-two-card.component.scss'],
})
export class TemplateTwoCardComponent implements OnInit, OnDestroy {
  @Input() entity: any;
  @Input() attributePositions: any;
  @Input() settings: any;
  @Input() eventCUList: any;
  @Output() cardselected: EventEmitter<any> = new EventEmitter<any>();

  attr1: any;
  attr2: any;
  attr3: any;
  attr4: any;
  attr5: any;
  attr6: any;
  attr7: any;
  labels: any;
  isChecked: boolean = false;
  cardData: Array<any> = new Array(5);
  currentEventDataArray: any = [[], [], [], [], [], [], []];

  unSubscribe = new Subject();

  constructor(private translator: TranslatorService, private transactionFacadeService: TransactionFacadeService) {
    this.detectLanguageChange();
  }

  /* preparing the cards data for displying*/
  ngOnInit(): void {
    this.entity?.forEach((ele: any) => {
      if (this.attributePositions && this.attributePositions[ele?.attributeName]) {
        const index = this.attributePositions[ele?.attributeName];
        // this.cardData[index-1] = ele;
        this.mapingAttributeValues(index, ele);
      }
    });
  }

  /* get the translation lables based on selected language*/
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  mapingAttributeValues(index: any, ele: any) {
    switch (index) {
      case '1':
        this.attr1 = ele;
        break;

      case '2':
        this.attr2 = ele;
        break;

      case '3':
        this.attr3 = ele;
        break;

      case '4':
        this.attr4 = ele;
        break;

      case '5':
        this.attr5 = ele;
        break;

      case '6':
        this.attr6 = ele;
        break;

      case '7':
        this.attr7 = ele;
        break;
    }
  }

  /* emit the selected cards to dynamic form*/
  selectedCard() {
    this.isChecked = !this.isChecked;
    this.cardselected.emit({ checked: this.isChecked });
  }

  // Check event data based on attribute id
  checkEventGSI(attr: any, index: number) {
    /*istanbul ignore next*/
    this.currentEventDataArray[index] = getCurrentEventDataArray(this.eventCUList, attr);
    if (this.currentEventDataArray[index].length > 0) {
      return true;
    }
    return false;
  }

  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any) {
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}