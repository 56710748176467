import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, dependentAttributeValue, getAttributeValue, setAttributeValue, getUiControlCustomization, validateDependentExpression } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
@Component({
  selector: 'app-input',
  styleUrls: ['./decimal.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <div [formGroup]="group" class="{{ parentClass }}">
      <div class="d-flex align-items-center nh-mb-8 nh-column-gap-5 sub-left-class main-label-wrapper">
        <label
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          class="main-label nh-mb-0"
          [style.color]="field.color"
          [style.font-size.px]="field.fontSize"
          *ngIf="
            (field?.sentence == null || field?.sentence === '') &&
            !field?.hideLabels &&
            !field?.configuration?.hideLabel &&
            !field?.hideLabelMultiAttr
          "
          ><span class="main-label-inner"
            ><span class="main-label-text text-truncate">{{ field?.label }} <span class="main-label-colon">:</span></span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          ></label
        >
        <p
          class="nh-ml-6 d-flex main-label-helperIcon"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined helperText-infoIcon"
            [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            *ngIf="!field?.hideLabels"
            >info_outline</mat-icon
          >
        </p>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2 multivalue-btns"
          *ngIf="
            field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
            (field?.isMulti && !(field?.attribute)['isTableConfig'])
          "
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            matSuffix
            class="d-flex align-items-center justify-content-center"
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
          <mat-icon
            *ngIf="field?.isMulti && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field?.readonly }"
            matSuffix
            (click)="addAttribute.next()"
            >add</mat-icon
          >
          <mat-icon
            *ngIf="field?.isMulti && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field?.readonly }"
            matSuffix
            (click)="removeAttribute.next()"
            >remove</mat-icon
          >
        </div>
      </div>
      <mat-form-field
        [floatLabel]="field?.type == 'currency' && field?.options?.length > 0 ? 'never' : ''"
        appearance="standard"
        class="{{ appliedClass }} sub-right-class"
      >
        <span
          class="currency-label"
          #currencyLabel
          matPrefix
          *ngIf="
            !(field?.type == 'currency' && field?.options?.length > 1) &&
            field?.labelType &&
            (!foundObject?.event || foundObject?.event == '')
          "
          [ngClass]="{
            strike: field?.configuration?.strikeOff,
            'metric-right': field?.type == 'metric' && field?.configuration?.metricLabelSuffix
          }"
        >
          {{ field.labelType }}
          <sub *ngIf="field?.type == 'metric' && field?.configuration?.metricDimensionPosition == 'subscript'">
            {{ field?.configuration?.metricDimension }}
          </sub>
          <sup *ngIf="field?.type == 'metric' && field?.configuration?.metricDimensionPosition == 'superscript'">
            {{ field?.configuration?.metricDimension }}
          </sup>
        </span>

        <p
          class="top-text"
          *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <div
          [ngClass]="
            (field?.type == 'currency' && field?.options?.length > 0) || field?.type == 'percentage'
              ? 'currency-options'
              : ''
          "
        >
          <!--<span
            *ngIf="showPlaceholder"
            class="label-flex-row-placeholder"
            [ngClass]="field?.type == 'currency' && field?.options?.length > 1 ? 'ltor-currency-dropdown' : ''"
            >99999</span
          >-->
          <div class="txn-currency-dropdown" *ngIf="field?.type == 'currency' && field?.options?.length > 1">
            <mat-select placeholder="Search" formControlName="labelType" (selectionChange)="onValueChange($event)">
              <mat-option *ngFor="let op of field?.options" [value]="op?.displayValue" (click)="currencyValue(op)">
                {{ op.displayValue }} &nbsp; : {{ op.actualValue }}
              </mat-option>
            </mat-select>
          </div>
          <input
            #currencyInput
            appCurrencyMask
            class="{{ appliedClass }}"
            [ngClass]="{
              'currency-input': field?.labelType,
              strike: field?.configuration?.strikeOff,
              inValidClass:
                !field?.isInfo &&
                (group?.controls)[field?.attribute?.name]?.invalid &&
                (group?.controls)[field?.attribute?.name]?.touched
            }"
            matInput
            [value]="field?.value"
            [_labelType]="selectedCurrency"
            [formControlName]="field?.attribute.name"
            (keypress)="onlyNumberKey($event, field.inputType)"
            (keyup)="onlyNumberKey($event, field.inputType)"
            [type]="field?.type"
            [readonly]="field?.readonly"
            [autocomplete]="autoOff"
            [required]="field?.isRequired && !field?.isInfo"
            (change)="onChange(field)"
            (focus)="onFocus()"
            (focusout)="onFocusOut()"
            id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
            (click)="onClick($event)"
            (input)="onInput($event, false)"
            (paste)="onPaste($event)"
          />
          <div class="txn-currency-dropdown" *ngIf="field?.type == 'percentage'" (click)="$event.stopPropagation()">
            <mat-select
              placeholder="Search"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="field.configuration.percentageType"
            >
              <mat-option value="percentage"> % </mat-option>
              <mat-option value="decimal"> 123 </mat-option>
            </mat-select>
          </div>
          <div *ngIf="foundObject?.event && foundObject?.event !== ''" class="currency">{{ field.labelType }}</div>
        </div>
      </mat-form-field>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <!-- <ng-container ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
          "
          >{{ field?.displayName ? field?.displayName : field?.name }} is invalid</mat-error
        >
      </ng-container> -->
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          class="error-msg-table"
          matTooltip="{{ validation.message }}"
          matTooltipPosition="above"
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </div>
  `,
  styles: [
    `
      .unit-type {
        margin-right: 5px;
      }
    `,
  ],
})
export class DecimalComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  autoOff = 'on';
  @ViewChild('currencyLabel') currencyLabel: ElementRef<HTMLElement>;
  @ViewChild('currencyInput') currencyInput: ElementRef<HTMLElement>;
  pastedText: string;
  tenant: string;
  validationList: any[] = [
    'greaterThan',
    'greaterThanOrEqual',
    'smallerThan',
    'smallerThanOrEqual',
    'equalTo',
    'notequalTo',
    'valuesSelect',
    'min',
    'max',
    'positiveorzero',
    'positive'
  ];
  foundObject: any;
  appliedClass: string = 'custom-input-mat currencyField';
  parentClass: string = 'decimal-input';
  showPlaceholder: boolean = false;
  selectedCurrency: { curr: string; prev: string };
  tempCurrency: string;

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacadeService: TransactionFacadeService
  ) {}

  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    this.pastedText = clipboardData.getData('text');
    /* istanbul ignore else */
    if (isNaN(Number(this.pastedText))) {
      event.preventDefault();
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInput(event, true);
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.autoOff = this.field?.readonly ? 'off' : 'on';
    this.createForm();
    if (this.field?.value && this.field?.isOptional && this.field?.isInPotentiality) {
      this.setOptionalValidators();
    }
    let currencyOption = getUiControlCustomization('Currency');
    if (currencyOption) {
      this.foundObject = { event: currencyOption };
      this.appliedClass = 'form-input-var currency-icon form-input-var-opt';
      let op = currencyOption.slice(-1);
      switch (op) {
        case '1':
        case '2':
          this.appliedClass += '1';
          break;
        case '3':
        case '4':
          this.appliedClass += '2';
          break;
        case '5':
        case '6':
          this.appliedClass += '4';
          break;
        case '7':
          if (!this.field.value) this.showPlaceholder = true;
          this.appliedClass += '1';
          this.parentClass = 'currency-parent-class-styles';
      }
      if (op % 2 == 0) this.appliedClass += ' left-side-placeholder';
    }
    // if (this.field.labelType) {
    //   let a = currency.find((cu: any) => cu.symbol_native === this.field.labelType);
    //   this.currencyValue(a);
    // }
    if (
      this.field?.value &&
      this.field?.type == 'percentage' &&
      this.field?.configuration?.percentageType == 'percentage'
    ) {
      this.field.value = this.field.value / 100;
      this.group.controls[this.field.attribute.name].setValue(this.field.value);
    }
    if (this.field?.value && this.field?.isOptional) {
      this.setOptionalValidators();
    }
  }
  ngAfterViewInit() {
    /*istanbul ignore next */
    if (this.currencyLabel?.nativeElement?.clientWidth) {
      let clientWidth = this.currencyLabel.nativeElement.clientWidth + 20;
      if (clientWidth) {
        this.field?.configuration?.metricLabelSuffix
          ? (this.currencyInput.nativeElement.style.paddingRight = clientWidth + 'px')
          : (this.currencyInput.nativeElement.style.paddingLeft = clientWidth + 'px');
      }
    }
  }

  createForm() {
    const validList: any = [];
    if (this.field && this.field.inputType === 'currency') {
      let control = new FormControl();
      this.group.addControl(this.field?.attribute?.name, control);
      validList.push(Validators.pattern('^[+-]?[0-9]+([,][0-9]+)*((\\.){0,1}[0-9]+)?$'));
      // this.group.controls[this.field?.attribute?.name].setValidators([
      //   Validators.pattern('^[+-]?[0-9]+([,][0-9]+)*((\\.){0,1}[0-9]+)?$'),
      // ]);
    }
    if (this.field?.validations?.length > 0) {
      /* istanbul ignore next */
      this.field?.validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      /* istanbul ignore next */
      this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
    }
  }

  onlyNumberKey(event: any, fieldType: any) {
    if (this.field.type === 'currency') {
      this.showPlaceholder = event?.target?.value == '' ? true : false;
    }
    /* istanbul ignore next */
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }
    if (this.group?.value[String(this.field?.attribute.name)]?.toString().indexOf('.') > -1 && event?.charCode == 46) {
      event?.preventDefault();
    }
    /* istanbul ignore next */
    let value = this.group?.get(this.field?.attribute?.name)?.value || '';
    if (fieldType === 'currency') {
      if (value?.length == 0 || value[value?.length - 1] == '.' || value[value?.length - 1] == ',') {
        return (event?.charCode >= 48 && event?.charCode <= 57) || (value?.length === 0 && event?.charCode === 45);
      } else if (value?.includes('.')) {
        return event?.charCode == 44 || (event?.charCode >= 48 && event?.charCode <= 57);
      } else {
        return event?.charCode == 44 || event?.charCode == 46 || (event?.charCode >= 48 && event?.charCode <= 57);
      }
    } else {
      /* istanbul ignore next */
      if (event?.charCode == 8 || event?.charCode == 0) return null;
      else if (value && value?.includes('-'))
        return event?.charCode == 46 || (event?.charCode >= 48 && event?.charCode <= 57);
      else return event?.charCode == 45 || event?.charCode == 46 || (event?.charCode >= 48 && event?.charCode <= 57);
    }
  }

  /**
   * Determines whether input value is changed
   */
  onChange(field: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore else */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
  }

  /**
   * Determines whether input field is focused
   */
  onFocus() {
    if (!this.field.isHidden) {
      if (this.currencyLabel) {
        this.currencyLabel.nativeElement.classList.add('focus-color');
      }
      /* istanbul ignore next */
      const data = {
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_FOCUS',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
    }
  }

  str: string = '';
  isbackspaceChange = false;
  onInput(event: any, backspace: boolean) {
    /* istanbul ignore else */
    if (this.field?.type == 'percentage' && this.field?.configuration?.percentageType == 'percentage') {
      /* istanbul ignore else */
      if (event?.target?.value) {
        let value = this.group.controls[this.field.attribute.name].value;
        /* istanbul ignore else */
        if (backspace) {
          this.str = this.str.slice(0, -1);
          this.isbackspaceChange = true;
        } else {
          const lastDigit = value.charAt(value.length - 1);
          if (!this.isbackspaceChange) {
            this.str += lastDigit;
          } else {
            this.isbackspaceChange = false;
          }
          if (this.str && !isNaN(parseFloat(this.str))) {
            let formattedValue = parseFloat(this.str) / 100;
            this.group.controls[this.field.attribute.name].setValue(formattedValue);
          }
        }
      } else {
        this.str = '';
        this.isbackspaceChange = false;
      }
    }
  }

  onFocusOut() {
    if (this.currencyLabel) {
      this.currencyLabel.nativeElement.classList.remove('focus-color');
    }
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    return this.group?.get(String(this.field?.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
        if (this.validationList?.includes(validation?.name)) {
          return validateDependentExpression(
            validation,
            this.eventsService,
            this.field,
            this.group,
            this.transactionFacadeService
          );
        }
      return this.group?.controls[field?.attribute?.name].value?.match(validation?.requiredPattern) ? false : true;
  }

  setOptionalValidators() {
    if (this.group?.value?.value && this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    } else if (!this.group?.value?.value && this.field?.optionalValidation) {
      this.field.validations = null;
      this.group?.controls[this.field?.attribute?.name]?.setValidators(null);
      this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  onValueChange(event: any) {
    this.field.labelType = event?.value;
    this.field.attributeType.properties.currencyType = event?.value;
  }

  currencyValue(option: any) {
    this.selectedCurrency = JSON.parse(
      JSON.stringify({
        prev: this.selectedCurrency?.curr || option?.actualValue || option?.name || '',
        curr: option?.actualValue || option?.name || '',
      })
    );

    if (this.transactionFacadeService.savedCurrencyType.has(this.field?.entityName)) {
      let atttributeData: Map<string, any> = this.transactionFacadeService.savedCurrencyType.get(
        this.field?.entityName
      );
      let result = atttributeData?.get(this.field?.attrTxnRecordId);
      this.transactionFacadeService.savedCurrencyType.get(this.field?.entityName).set(this.field?.attrTxnRecordId, {
        selectedCurrency: this.selectedCurrency.curr,
      });
    } else {
      this.transactionFacadeService.savedCurrencyType.set(this.field?.entityName, new Map<string, any>());
      this.transactionFacadeService.savedCurrencyType.get(this.field?.entityName).set(this.field?.attrTxnRecordId, {
        selectedCurrency: this.selectedCurrency.curr,
      });
    }
  }

  onClick(event: any) {
    if (this.field?.type === 'currency' && this.field?.options) {
      event.stopPropagation();
    }
  }
  onPaste(event: any) {
    if (event.type === 'paste') {
         let pastedText = event.clipboardData.getData('text/plain');
        // Adjusted regular expression to accept both whole numbers and decimals
        if (!/^\d+(\.\d+)?$/.test(pastedText)) {
            // Prevent pasting if it contains non-digit characters or invalid decimal format
            event.preventDefault();
            return;
        }else{
          event.target.value = pastedText;
          this.field.value = pastedText;
          this.group.controls[this.field?.attribute?.name].setValue(pastedText);
        }
    }
}
}
