import { Attribute, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertService, AttributeType,
  ChangeUnitFacadeService,
  EntityFacadeService,
  LibraryFacadeService, NodeGsiFacadeService,
  TranslatorService,
  callfat2flat,
  compositeExtractionMapper,
  formatCuRights, getAttributeObj,isReservedCU
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import { NodeSpecialFeaturesComponent } from '../node-special-features/node-special-features.component';
import { sfPostFactMapper } from './sf-post-fact-mapper';
@Component({
  selector: 'app-nsl-special-features',
  templateUrl: './nsl-special-features.component.html',
  styleUrls: ['./nsl-special-features.component.scss'],
})
export class NslSpecialFeaturesComponent implements OnInit {
  @ViewChild('addmeasures') addmeasures: TemplateRef<any>;
  // @ViewChild('sfInNode', {static : true}) sfInNode : NodeSpecialFeaturesComponent;
  @ViewChild(NodeSpecialFeaturesComponent) sfInNode: NodeSpecialFeaturesComponent;
  @Output() fromSpecialFeatures: EventEmitter<any> = new EventEmitter<any>();
  ngUnsubscribe = new Subject();
  currentCuData: any;
  selectedSf: any;
  vantagePoint: any;
  allSpecialFeatures: any = [];
  selectedEntity: any;
  selectedAttribute: any;
  createNewEntity: any;
  createNewAttribute: any;
  selectedLayer: any;
  entityArr: any = [];
  tempGsiObj: any;
  slotIndex: any;
  measuresSf: any = [{ rating: [] }];
  operators: any = ['=', '<', '>', '<=', '>='];
  selectedOperator: any;
  measureOperator: any = [];
  measureValue: any = [];
  measureExpression: any = [];
  measureName: any;
  attributeIndex: number;
  openSfForm: boolean = false;
  activeCuIndex: any;
  currentCuSfProps: any;
  featureTag: any;
  incomplete: boolean = false;
  entryVantageData: any = {
    data: {},
  };
  renderSfForm: boolean = false;
  selectedEventType: string = '';
  postFactSentence: any;
  isFinalCuSave: boolean = false;
  existingConfig: any = {};
  openMeasuresFlag: boolean = false;
  isnode: any = '';
  reserveEntity: any = '';
  tenantName: any;
  // triggerSubmitForm: boolean = false;
  labels: any;

  constructor(
    public dialog: MatDialog,
    public gsifacade: NodeGsiFacadeService,
    public nodeChangeUnitFacadeService: NodeChangeUnitFacadeService,
    public nodeEntityFacadeService: NodeEntityFacadeService,
    public changeUnitFacadeService: ChangeUnitFacadeService,
    public alertService: AlertService,
    public entityfacade: EntityFacadeService,
    private libraryfacadeservice: LibraryFacadeService,
    private translator: TranslatorService,

  ) {
    this.detectLanguageChange();
    this.getSelectedBetData();
    this.selectDataHybridCanavas();
    this.getSelectedBetData();
    this.detectCUDataSF();
    this.fetchFat2flatData();
    this.detectEntitySave();
    this.getGsiSaveData();
    this.getEntityFromCollectionsSF();
    this.tenantName = localStorage.getItem('TenantName');
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  selectedBetData(res: any) {
    /*istanbul ignore next */
    this.isnode = res.isnode;
    this.reserveEntity = res.reserveEntity;
    if (res?.existingConfig) {
      this.openExistingConfiguration(res);
    } else {
      this.tempGsiObj = res.currentGsi;
      this.currentCuData = res?.activeBet;
      //get exisiting sf data
      /* istanbul ignore next */
      this.activeCuIndex = this.tempGsiObj?.solutionLogic?.findIndex(
        (cu: any) => cu?.referencedChangeUnit === this.currentCuData?.referencedChangeUnit
      );
      this.currentCuData = this.tempGsiObj?.solutionLogic[this.activeCuIndex];
      if (this.currentCuData != null) {
        this.currentCuData.specialFeatureProperties = this.tempGsiObj?.solutionLogic[
          this.activeCuIndex
        ]?.specialFeatureProperties;
      }
      this.selectedSf = res?.specialFeature;
      this.vantagePoint = res?.vantagePoint;
      /* istanbul ignore next */
      if (!(res?.vantagePoint == 'GSI')) {
        this.entryVantageData.vantagePoint = 'CU';
        this.entryVantageData.data.cu = this.currentCuData;
        this.selectedLayer = this.currentCuData?.layers?.find((layer: any) => layer?.type === 'information');
        this.selectedEventType = res?.eventType;
        /* specail-features-filter 11/08 start here*/
        /* istanbul ignore next*/
        if (this.vantagePoint?.tag === 'Int.') {
          this.featureTag = 'CU';
        } else if (this.vantagePoint?.tag === 'Obj.') {
          /* istanbul ignore next*/
          this.featureTag = 'Entity';
        } else if (this.vantagePoint?.tag === 'Att.') {
          /* istanbul ignore next*/
          this.featureTag = 'Attribute';
        }
        /* istanbul ignore next*/
        if (this.reserveEntity != 'reserveEntity') {
          res?.allSpecialFeatures?.forEach((feature: any) => {
            feature?.vantagePoint?.forEach((vp: any) => {
              if (vp?.name == this.featureTag) {
                this.allSpecialFeatures?.push(feature);
              }
            });
          });
        } else if (this.reserveEntity == 'reserveEntity') {
          this.allSpecialFeatures?.push(this.selectedSf);
        }
        /* end here*/

        /* istanbul ignore next*/
        this.allSpecialFeatures?.forEach((sf: any, index: number) => {
          sf.index = index;
        });
      }

      /* istanbul ignore next */
      if (this.vantagePoint?.tag === 'Obj.') {
        this.currentCuData?.layers?.forEach((layer: any) => {
          layer?.participatingItems?.forEach((entity: any, index: number) => {
            if (layer?.type == 'information') {
              this.selectedLayer = layer;
            }
            if (entity?.displayName == this.vantagePoint?.token || entity?.name == this.vantagePoint?.token) {
              this.entryVantageData.vantagePoint = 'EN';
              this.entryVantageData.data.en = entity;
              this.entryVantageData.layer = layer;
              this.entryVantageData.slotIndex = index;
              this.slotIndex = index;
              if (this.reserveEntity == 'reserveEntity') {
                this.entryVantageData = {
                  data: {},
                };
                this.selectedEntity = entity;
              }
            }
          });
        });
      } else if (this.vantagePoint?.tag === 'Att.') {
        /* istanbul ignore next */
        this.currentCuData?.layers?.forEach((layer: any) => {
          layer?.participatingItems?.forEach((entity: any, index: number) => {
            entity?.nslAttributes?.forEach((attribute: any, attIndex: number) => {
              if (layer?.type == 'information') {
                this.selectedLayer = layer;
              }
              if (attribute?.displayName == this.vantagePoint?.token || attribute?.name == this.vantagePoint?.token) {
                this.entryVantageData.vantagePoint = 'AT';
                this.entryVantageData.data.att = attribute;
                this.entryVantageData.data.en = entity;
                this.entryVantageData.layer = layer;
                this.entryVantageData.slotIndex = index;
                this.slotIndex = index;
                this.attributeIndex = attIndex;
              }
            });
          });
        });
      } else if (this.vantagePoint == 'GSI') {
        this.featureTag = 'GSI';
        res?.allSpecialFeatures?.forEach((feature: any) => {
          feature?.vantagePoint?.forEach((vp: any) => {
            if (vp?.name == this.featureTag) {
              this.allSpecialFeatures?.push(feature);
            }
          });
        });
        /* istanbul ignore next*/
        this.allSpecialFeatures?.forEach((sf: any, index: number) => {
          sf.index = index;
        });
        this.entryVantageData.vantagePoint = 'GSI';
        this.entryVantageData.data.gsi = this.tempGsiObj;
      }
      if (!this.selectedLayer) {
        this.selectedLayer = {
          type: 'information',
          participatingItems: [],
        };
        /*istanbul ignore next */
        this.currentCuData?.layers?.push(this.selectedLayer);
      }
      this.renderSfForm = true;
    }
    if (this.isnode == 'ishyb') {
      this.tempGsiObj?.solutionLogic?.forEach((cu: any, index: any) => {
        this.tempGsiObj.solutionLogic[index] = formatCuRights(cu);
      });
      this.selectedLayer?.participatingItems.forEach((ent:any)=>{
        if(ent.id == res.entity.id)
        this.selectedEntity = ent;
       });
       this.selectedEntity?.nslAttributes.forEach((attr:any)=>{
        if(attr.id == res.attr.id)
        this.selectedAttribute = attr;
      })
      //  this.selectedAttribute = res.attr;
      // this.selectedEntity = res.entity;
    }
  }
  getSelectedBetData() {
    this.gsifacade.sendCuDataToSf$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.selectedBetData(res);
    });
  }
  selectDataHybridCanavas() {
    this.libraryfacadeservice.sendDataToSF$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.selectedBetData(res);
    });
  }
  getEntityFromCollectionsSF() {
    this.nodeEntityFacadeService.entityAttributes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      let index = this.selectedLayer.participatingItems.findIndex((item: any) => item.displayName === res.displayName);
      if (index === -1) {
        this.selectedLayer.participatingItems.push(res);
        this.selectedEntity = res;
      } else {
        this.selectedEntity = this.selectedLayer.participatingItems[index];
      }
    });
  }

  /* istanbul ignore next */
  openExistingConfiguration(res: any) {
    this.tempGsiObj = res.currentGsi;
    this.currentCuData = res.activeBet;
    //get exisiting sf data
    /* istanbul ignore next */
    this.activeCuIndex = this.tempGsiObj?.solutionLogic?.findIndex(
      (cu: any) => cu.referencedChangeUnit === this.currentCuData?.referencedChangeUnit
    );
    this.selectedLayer = this.currentCuData?.layers?.find((layer: any) => layer?.type === 'information');
    this.currentCuData.specialFeatureProperties = this.tempGsiObj?.solutionLogic[
      this.activeCuIndex
    ]?.specialFeatureProperties;
    this.allSpecialFeatures = res?.allSpecialFeatures;
    this.selectedSf = res?.specialFeature;
    this.selectedEntity = this.selectedLayer?.participatingItems.find((ent: any) => {
      if (ent.name === res.existingConfig?.entityAttributeKey?.split('.')?.[0]) {
        return ent;
      }
    });
    this.selectedAttribute = this.selectedEntity?.nslAttributes.find((att: any) => {
      if (att.name === res.existingConfig?.entityAttributeKey?.split('.')?.[1]) {
        return att;
      }
    });
    let sfKey = this.selectedLayer?.type + this.selectedEntity?.name + this.selectedAttribute?.name;
    this.existingConfig[sfKey] = res?.existingConfig;
    this.postFactSentence = this.existingConfig?.[sfKey]?.props?.postFactSentence;
    this.slotIndex = this.existingConfig?.[sfKey]?.slotIndex;
    this.allSpecialFeatures?.forEach((sf: any, index: number) => {
      sf.index = index;
    });

    this.renderSfForm = true;
  }

  clickedAddMeasures() {
    this.openMeasuresFlag = true;
    this.saveChangeUnit();
  }

  openMeasures() {
    if (this.isnode == 'node') {
      this.gsifacade.changeNodeComponent('Measures');
    } else {
      this.gsifacade.changeNSLComponent('Measures');
    }
    let measuresIdForCurrentAttribute: any;
    let existingMeasuresConfig: any;
    let existingconfig: boolean = false;
    measuresIdForCurrentAttribute =
      'IL' + '.SL001.' + 'EN' + String(this.selectedEntity?.id) + '.' + 'AT' + String(this.selectedAttribute?.id);
    /*istanbul ignore next */
    if (this.tempGsiObj?.solutionLogic[this.activeCuIndex]?.measures) {
      Object.keys(this.tempGsiObj?.solutionLogic[this.activeCuIndex]?.measures)?.forEach((key: any) => {
        // let props : any = Object.values(prop)?.[0];
        if (measuresIdForCurrentAttribute.includes(key)) {
          existingMeasuresConfig = this.tempGsiObj?.solutionLogic[this.activeCuIndex]?.measures[key];
          existingconfig = true;
        }
      });
    }
    if (existingconfig) {
      this.gsifacade.sendCuDataToMeasures(
        this.tempGsiObj,
        this.currentCuData,
        { token: this.selectedAttribute?.name },
        existingMeasuresConfig,
        this.isnode
      );
    } else {
      this.gsifacade.sendCuDataToMeasures(
        this.tempGsiObj,
        this.currentCuData,
        { token: this.selectedAttribute?.name },
        null,
        this.isnode
      );
    }
  }
  onCloseDialog() {
    if (this.isnode == 'node') {
      this.gsifacade.changeNodeComponent('Graph');
    } else {
      this.gsifacade.changeNSLComponent('nslGsi');
    }

    this.dialog.closeAll();
  }

  selectSfTab(sf: any): void {
    this.selectedSf = sf;
    /* istanbul ignore next */
    this.sfInNode?.triggerSfInGrammar(sf);
  }
  /*istanbul ignore next */
  createEntity() {
    let dataType: any = {
      name: this.selectedSf.name,
      dataType: this.selectedSf.name,
      uiElement: this.selectedSf.name,
      isMulti: false,
      esDataType: 'string',
      properties: [],
    };
    /* istanbul ignore next */
    let attName = this.createNewAttribute ? this.createNewAttribute : this.selectedAttribute?.name;
    let attributeObj = getAttributeObj(attName, dataType);
    /* istanbul ignore next */
    if (this.selectedLayer?.type === 'information') {
      if (this.selectedEntity?.id && !this.selectedAttribute?.id) {
        this.slotIndex = this.selectedLayer.participatingItems?.findIndex((x: any) => x.id === this.selectedEntity.id);
        this.attributeIndex = this.selectedEntity.nslAttributes.length;
        this.selectedEntity.nslAttributes?.push(attributeObj);
        let attrindex = this.selectedEntity.nslAttributes?.findIndex((x: any) => x.name === attributeObj.name);
        if (attrindex === -1) {
          this.selectedEntity.nslAttributes?.push(attributeObj);
        }
        this.nodeEntityFacadeService.saveEntityForSF(this.selectedEntity);
      } else if (!this.selectedEntity?.id && !this.selectedAttribute?.id) {
        this.slotIndex = this.selectedLayer?.participatingItems?.length;
        this.attributeIndex = 0;
        let createEntityObj: any = {
          isMultiValue: false,
          layerType: this.selectedLayer.type,
          description: '',
          nslAttributes: [attributeObj],
          name: this.createNewEntity,
        };
        // this.entityArr.push(createEntityObj);
        this.nodeEntityFacadeService.saveEntityForSF(createEntityObj);
      } else {
        this.slotIndex = this.selectedLayer.participatingItems?.findIndex((x: any) => x.id === this.selectedEntity.id);
        this.attributeIndex = this.selectedEntity.nslAttributes?.findIndex(
          (att: any) => att.name === this.selectedAttribute.name
        );
        this.selectedEntity.nslAttributes[this.attributeIndex].attributeType = attributeObj.attributeType;
        this.nodeEntityFacadeService.saveEntityForSF(this.selectedEntity);
      }
    }

    this.alertService.showToaster('Special Feature Validated', '', 'success');
    this.fromSpecialFeatures.emit(true);
  }
  /*istanbul ignore next*/
  detectEntitySave() {
    this.nodeEntityFacadeService.sendEntityResponseToSf$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result && res != '') {
        this.selectedLayer.participatingItems[this.slotIndex] = res.result;
        this.selectedEntity = res.result;

        /* istanbul ignore next */
        this.currentCuData?.layers?.forEach((layer: any, index: number) => {
          if (layer.type === this.selectedLayer.type) {
            this.currentCuData.layers[index] = this.selectedLayer;
          }
        });
        this.saveChangeUnit();
        this.nodeEntityFacadeService.clearEntityData();
      }
    });
  }

  saveChangeUnit() {
    /* istanbul ignore next */
    this.currentCuData.tfId = this.currentCuData?.tfReferencedChangeUnit
      ? this.currentCuData?.tfReferencedChangeUnit
      : this.currentCuData?.tfId;
    /* istanbul ignore next */
    this.currentCuData.id = this.currentCuData.referencedChangeUnit
      ? this.currentCuData.referencedChangeUnit
      : this.currentCuData.id;
    this.currentCuData.tfReferencedChangeUnit = undefined;
    this.currentCuData.referencedChangeUnit = undefined;
    /* storing sf props */
    /*istanbul ignore next*/
    this.currentCuSfProps = this.currentCuData?.specialFeatureProperties;
    if (this.isnode == 'ishyb') {
      this.currentCuData = formatCuRights(this.currentCuData);
    }

    this.nodeChangeUnitFacadeService.updateChangeUnit(this.currentCuData, false);
  }

  detectCUDataSF() {
    this.nodeChangeUnitFacadeService.cuUpdatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.data) {
        this.currentCuData = { ...res.data, nextTriggerSet: this.currentCuData?.nextTriggerSet };
        this.currentCuData.specialFeatureProperties = this.currentCuSfProps;
        this.currentCuData.referencedChangeUnit = this.currentCuData?.id;
        this.currentCuData.tfReferencedChangeUnit = this.currentCuData?.tfId;
        // this.currentCuData.id = this.tempGsiObj?.solutionLogic?.[this.activeCuIndex]?.id;
        // this.currentCuData.tfId = this.tempGsiObj?.solutionLogic?.[this.activeCuIndex]?.tfId;
        this.selectedLayer = res?.data?.layers?.find((layer: any) => layer?.type === 'information');
        this.selectedEntity = this.selectedLayer?.participatingItems?.[this.slotIndex];
        this.selectedAttribute = this.selectedEntity?.nslAttributes?.[this.attributeIndex];
        this.openSfForm = true;
        this.tempGsiObj.solutionLogic[this.activeCuIndex] = this.currentCuData;
        this.getFat2FlatData();
      }
    });
  }

  saveGSI() {
    /* istanbul ignore next */
    this.gsifacade.saveGsi(this.tempGsiObj);
  }

  /* istanbul ignore next */
  getGsiSaveData() {
    this.gsifacade.gsiDraftResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.tempGsiObj = { ...res };
        this.currentCuData = this.tempGsiObj?.solutionLogic[this.activeCuIndex];
        if (this.isFinalCuSave) {
          this.onCloseDialog();
        } else if (this.openMeasuresFlag) {
          this.openMeasuresFlag = false;
          this.openMeasures();
        }
      }
    });
  }

  addReserveEnt(event: any) {
    this.entityfacade
      .fetchEntityByName(this.tenantName, event)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res) {
            this.slotIndex = this.selectedLayer?.participatingItems?.findIndex(
              (x: any) => x.id === this.selectedEntity.id
            );
            this.attributeIndex = this.selectedEntity?.nslAttributes?.findIndex(
              (att: any) => att.name === this.selectedAttribute?.name
            );
            let attribute = {} as Attribute;
            attribute['name'] = res.result.name;
            attribute['isReserved'] = false;
            attribute['attributeClassification'] = 'ESSENTIAL';
            let attributeType = {} as AttributeType;
            attributeType.properties = {};
            attributeType.properties.referencingType = res.result.name;
            attributeType.type = 'entity';
            attribute['generalEntity'] = {
              dsdId: res.result.dsdId,
              id: res.result.id,
              name: res.result.name,
            };
            attribute['attributeType'] = attributeType;
            this.selectedEntity.nslAttributes[this.attributeIndex] = attribute;
            this.nodeEntityFacadeService.saveEntityForSF(this.selectedEntity);
            this.selectedAttribute = res.result.name;
          }
        },
        (error: any) => {
          this.alertService.showToaster('Unable to fetch entity', '', 'error');
        }
      );
  }

  findSpecialFeaturePayload(event: any) {
    let props: any = Object.values(event)?.[0];
    if (this.reserveEntity == 'reserveEntity') {
      props['entityType'] = this.selectedSf.name;
    } else if (Object.keys(event)?.[0].includes('NSL_')|| isReservedCU(Object.keys(event)?.[0])) {
      props['entityType'] = this.selectedSf.name;
      delete props['attributeType'];
    }
    let payload = sfPostFactMapper(props.props);
    if (payload.eventType?.event == '') {
      payload.eventType.event = props?.attributeType ? props.attributeType : this.selectedSf.name;
    }

    this.nodeChangeUnitFacadeService
      .getSfPostfactSentence(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.postFactSentence = res.post_fact_sentence;
          let sfKey: any = Object.values(event)?.[0];
          let entAttKey = 'SF.' + sfKey?.entityAttributeKey;
          // this.currentCuData.tCUsentenceName[entAttKey] = this.postFactSentence;
          event[Object.keys(event)?.[0]].props.postFactSentence = this.postFactSentence;
          this.currentCuData.specialFeatureProperties = {
            ...this.currentCuData.specialFeatureProperties,
            ...event,
          };
        }
      });
  }

  saveSpecialFeaturePayload() {
    // this.nodeEntityFacadeService.saveEntityForSF(this.selectedEntity);
    this.isFinalCuSave = true;
    this.saveChangeUnit();
  }

  getFat2FlatData() {
    let flatData = callfat2flat(this.tempGsiObj);
    this.changeUnitFacadeService.getSolutionDetails(flatData);
  }

  fetchFat2flatData() {
    this.changeUnitFacadeService.solutionDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next*/
      if (res && this.selectedSf) {
        let querySentence: any[] = [];
        /*istanbul ignore next */
        if (res?.flatApiFail == undefined) {
          querySentence = [...res?.res];
          querySentence = compositeExtractionMapper(querySentence);
        }
        /*istanbul ignore next */
        this.tempGsiObj?.solutionLogic?.forEach((cu: any) => {
          querySentence?.forEach((ele: any) => {
            ele.physical?.forEach((result: any) => {
              if ((result?.tag == 'cu' || result?.tag == 'Int.') && result?.dsd_id == cu.referencedChangeUnit) {
                cu['sentenceTags'] = ele;
                cu['tCUsentenceName'] = ele;
              }
            });
          });
        });
        this.saveGSI();
      }
    });
  }

  triggerSfNodeSubmit() {
    /* istanbul ignore next */
    this.sfInNode?.generatePayload();
    // this.nodeEntityFacadeService.tabSubmit.next();
  }

  updateData(event: any) {
    this.selectedEntity = '';
    this.selectedAttribute = '';
    this.createNewEntity = '';
    this.createNewAttribute = '';
    this.activeCuIndex = this.tempGsiObj?.solutionLogic?.findIndex(
      (cu: any) => cu?.referencedChangeUnit === this.currentCuData?.referencedChangeUnit
    );
    this.selectedLayer = this.currentCuData?.layers?.find((layer: any) => layer?.type === 'information');
    if (!this.selectedLayer) {
      this.selectedLayer = {
        type: 'information',
        participatingItems: [],
      };
      /*istanbul ignore next */
      this.currentCuData?.layers?.push(this.selectedLayer);
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}


