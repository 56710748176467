import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { pluck } from 'rxjs/internal/operators/pluck';
import { map } from 'rxjs/internal/operators/map';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Observable } from 'rxjs';
import { getEnvClientId, getTenantId, getTenantName, getUserId } from '../../generic-folder/generic-functions';
@Injectable({
  providedIn: 'root',
})
export class GsiEndpointService extends CduiBaseService {
  filter: FormControl;
  bookid: any;
  menuItem: string;
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  addSeqCu(gsiId: number, index: number, body: any) {
    const params = new HttpParams().set('index', index + '');
    return this.http.put(`${this.api?.addSeqCu}/${gsiId}/triggerCU-Draft`, body, { params });
  }

  getAllEntities(page: number, limit: number, searchString: string) {
    const ontology = '';
    const publisherId = '';
    return this.http.get(
      this.api?.entityList +
        `query=${searchString}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${page}&limit=${limit}&isPublished=true&userRight=TXN_DECISION_RIGHTS&cardView=true`
    );
  }

  getAllEntitesWithRole(roleId: any, pagenum: number, pageSize: number) {
    return this.http.get<any>(`${this.api?.getAllEntitiesForRole}${roleId}?limit=${pageSize}&page=${pagenum}`);
  }

  getEntityById(roleId: any) {
    return this.http.get<any>(`${this.api?.getAllEntities}/${roleId}?isReserved=false`);
  }

  getEntityCardDetails(id: any) {
    return this.http.get<any>(`${this.api?.getEntityCardData}${id}`);
  }

  getEntityDataDetails(req: any, pageNum: any, pageSize: any, v?: string) {
    let version : string = v? v : 'v1';
    return this.http.post(
      this.api?.getEntityData + version + '/facet-search?' + `pageNumber=${pageNum}&pageSize=${pageSize}`,
      req
    );
  }
  getSearchRecords(req: any) {
    return this.http.post(this.api?.getSearchRecords, req);
  }
  addNestedCu() {
    return this.http.post(`dsd-bets-store/tenant/triggerCU`, {});
  }

  getAllGsis(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean,
    isTXNRights: boolean,
    isDesignMode = true,
    selectedWeek?: number
  ) {
    const ontology = '';
    const publisherId = '';
    const type = isTXNRights ? 'EXECUTION_RIGHTS' : 'SOLUTIONS_INFO_RIGHTS';
    let headers: any;
    if (this.bookid) {
      headers = {
        headers: new HttpHeaders({
          bookId: this.bookid,
        }),
      };
    }

    if (selectedWeek) {
      const reqTime = moment()
        .utc()
        .subtract(7 * selectedWeek, 'days')
        .format();
      let epochTime = new Date(reqTime).getTime();

      return this.http.get(
        `${this.api?.getAllGsis}?isPublished=${isPublished}&limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrName=${publisherId}&query=${searchCriteria}&userRights=${type}&designMode=${isDesignMode}&fromUpdatedTime=${epochTime}`,
        headers
      );
    } else {
      return this.http.get(
        `${this.api?.getAllGsis}?isPublished=${isPublished}&limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrName=${publisherId}&query=${searchCriteria}&userRights=${type}&designMode=${isDesignMode}`,
        headers
      );
    }
  }

  getAllBookStatus(pagenum: number, pageSize: number, searchCriteria: string, approvalStatuses: any) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        this.api?.getBookStatus +
          'pageNo=' +
          pagenum +
          '&pageSize=' +
          pageSize +
          '&searchCriteria=' +
          searchCriteria +
          '&approvalStatuses=' +
          approvalStatuses
      )
      .pipe(pluck('result'));
  }

  // getAllDraftGSIs(pagenum: number, pageSize: number, searchCriteria: string) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl +
  //         '/GSI/matching?isPublished=' +
  //         false +
  //         'pageNo=' +
  //         pagenum +
  //         '&pageSize=' +
  //         pageSize +
  //         '&searchCriteria=' +
  //         searchCriteria
  //     )
  //     .pipe(pluck('result'));
  // }

  // getGsiCount(pagenum: number, pageSize: number, searchCriteria: string) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl +
  //       '/GSI/matching?pageNo=' +
  //       pagenum +
  //       '&pageSize=' +
  //       pageSize +
  //       '&searchCriteria=' +
  //       searchCriteria
  //     )
  //     .pipe(pluck('result', 'total'));
  // }

  getAllRoles(searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(`${this.api?.getAllRoles}`)
      .pipe(
        map((agents: any) =>
          agents.filter((agent: any) => agent.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1)
        )
      );
  }

  // Get all users list
  getAllUsers(id: any) {
    return this.http.get<any>(`${this.api?.loadAssignedGroupUsers}type=graph&role=${id}`);
  }

  // Save single milestone
  saveMilestone(payload: any) {
    return this.http.post(this.api?.saveMilestone, payload);
  }

  // Update milestone
  editMilestone(payload: any) {
    return this.http.put(this.api?.updateMilestone, payload);
  }

  // Fetch Milestone
  getMilestone(id: any) {
    return this.http.get(this.api?.getMilestone + id);
  }

  // Delete Milestone
  deleteMilestone(id: any) {
    return this.http.delete(this.api?.deleteMilestone + id);
  }

  // List of all milestone accroding to GsiID and TenantID
  getAllMilestone(gsiId: any) {
    return this.http.get(this.api?.getAllMilestone + `gsiId=${gsiId}`);
  }

  getBookDetails(bookId: any) {
    this.bookid = bookId;
    localStorage.setItem('bookId', bookId);
    const headers = {
      headers: new HttpHeaders({
        bookId: bookId,
      }),
    };
    return this.http.get(`${this.api?.saveBook}/${bookId}`, headers);
  }

  getAllOrgUnits(searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(`${this.api?.getAllOrgUnits}`)
      .pipe(
        map((agents: any) =>
          agents.filter((agent: any) => agent.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1)
        )
      );
  }

  getAllUsersInOrgUnit(id: any) {
    return this.http.get<any>(`${this.api?.getUsersInOrgUnit}orgUnitId=${id}`);
  }

  getAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        `${this.api?.getAllTenantBooks}?limit=${pageSize}&ontology=&page=${pagenum}&publisherIdOrName=&query=${searchCriteria}&designMode=true&userRight=SOLUTIONS_INFO_RIGHTS`
        // `${this.api?.getAllTenantBooks}?pageNo=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchCriteria}&designMode=true&userRight=SOLUTIONS_INFO_RIGHTS`
      )
      .pipe(pluck('result'));
    // pageNo=0&pageSize=30&searchCriteria=&designMode=true&userRights=SOLUTIONS_INFO_RIGHTS
    // return this.http
    //   .get(
    //     environment.tfCoreUrl +
    //       '/CU/books?pageNo=' +
    //       pagenum +
    //       '&pageSize=' +
    //       pageSize +
    //       '&searchCriteria=' +
    //       searchCriteria
    //   )
    //   .pipe(
    //     pluck('result')
    //     // map((response: any) => {
    //     //   if (response && response.result && response.result.content && response.result.content.length > 0) {
    //     //     return response.result.content.filter(
    //     //       (agent: any) => agent.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1
    //     //     );
    //     //   }
    //     // })
    //   );
  }

  // getBooksCount(pagenum: number, pageSize: number, search: any) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl + '/CU/books?pageNo=' + pagenum + '&pageSize=' + pageSize + '&searchCriteria=' + search
  //     )
  //     .pipe(pluck('result', 'total'));
  // }

  dashBoardgetAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        this.api?.getBooksWithDesignData +
          'pageNo=' +
          pagenum +
          '&pageSize=' +
          pageSize +
          '&searchCriteria=' +
          searchCriteria
      )
      .pipe(pluck('result'));
  }

  saveGsi(body: any) {
    return this.http.post(`${this.api?.saveGsi}`, body);
  }
  //save conditional potentiality gsi
  saveCPInGsi(body: any) {
    return this.http.post(`${this.api?.saveGsi}?condetionalPotentiality=true`, body);
  }

  saveBook(book: any, saveCpInBook?: any) {
    if (saveCpInBook) {
      return this.http.post(`${this.api?.saveBook}?condetionalPotentiality=true`, book);
    } else {
      return this.http.post(`${this.api?.saveBook}`, book);
    }
  }
  //gets data of impacted bet
  loadSingleLevelDetailsById(id: any, type: string) {
    return this.http.get<any>(this.api?.fetchsingleLevelDependency + `${id}/${type}`);
  }

  saveGsiInBook(bookId: number, gsi: any) {
    return this.http.post(`${this.api?.saveGsiInBook}${bookId}/gsi`, {
      id: gsi.tfId,
      name: gsi.name,
      dsdId: gsi.dsdId,
    });
  }

  getBookGsis(bookId: number, pageSize: number, pageNumber: number, search?: string, isFromLibraryBook?: boolean) {
    const options = {
      headers: new HttpHeaders({ bookId: `${bookId}` }),
    };
    const url = isFromLibraryBook
      ? `${this.api?.saveBook}/${bookId}/gsi?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${search}&designMode=false`
      : `${this.api?.saveBook}/${bookId}/gsi?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${search}`;
    return this.http.get(url, options).pipe(pluck('result'));
  }

  getBookDesign(bookId: number) {
    return this.http.get(`${this.api?.getBookDesign}/${bookId}`).pipe(pluck('result'));
  }

  updateGSI(id: any, index: any, data: any) {
    return this.http.put(`${this.api?.addSeqCu}/` + id + '/solution/sequential?index=' + index, data);
  }
  deleteCuFromGsi(gsiId: any, cuId: any) {
    return this.http.delete(`${this.api?.addSeqCu}/` + gsiId + '/' + cuId + '/solution/sequential');
  }

  // Service this.api Calls for Versioning Draft this.api Calls //

  saveDraftVersion(body: any, type: any) {
    return this.http.post(`${this.api?.gsiVersion}` + type, body);
  }

  saveDraftEditVersion(body: any, type: any) {
    const options = { headers: this.getApplicateJsonHeaders() };
    return this.http.post(`${this.api?.gsiVersion}` + type + '/' + body, options);
  }

  saveApproveReject(body: any, type: any) {
    const options = { headers: this.getApplicateJsonHeaders() };
    return this.http.post(`${this.api?.gsiVersion}` + type + '/' + body, options);
  }

  saveDraftGSI(body: any) {
    return this.http.post(`${this.api?.gsiDraft}`, body);
  }

  updateGsiRights(gsi: any, body: any) {
    return this.http.put(`${this.api?.updateGsiRights}` + gsi.id + '/irdr?isDesignRights=' + gsi.flag, body);
  }

  getApplicateJsonHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
  exportGsis(gsiId: number) {
    return this.http.get(`${this.api?.exportGsi}/${gsiId}/File`);
  }

  exportGsiApp(data: any) {
    return this.http.post(`${this.api?.exportApplication}`, data);
  }

  exportApplicationToEnv(data: any, envData: any, promoteWithDTRT: any) {
    return this.http.post(
      `${this.api?.exportApplicationToEnv}${envData?.promotionEnv}&mergeIfExists=${envData?.bookMerge}&&promoteDtrt=${promoteWithDTRT}`,
      data
    );
  }

  exportHistory(searchText: any, pageSize: number, pageNumber: number) {
    let flag = false;
    return this.http.get(
      `${this.api?.importExportHistory}?imported=${flag}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchText}`
    );
  }
  importHistory(searchText: any, pageSize: number, pageNumber: number) {
    let flag = true;
    return this.http.get(
      `${this.api?.importExportHistory}?imported=${flag}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchText}`
    );
  }

  importGSIs(req: any) {
    return this.http.post(`${this.api?.importGsi}`, req);
  }
  validateGsiFile(req: any) {
    return this.http.post(`${this.api?.validateGsiFile}`, req);
  }
  saveDraftCu(cu: any) {
    return this.http.post(`${this.api?.saveDraftCu}`, cu);
  }
  saveCompositeGsi(gsiData: any) {
    return this.http.post(`${this.api?.saveCompositeGsi}`, gsiData);
  }

  convertGsiToJsonToXml(payload: any) {
    return this.http.post(`${this.api?.convertGsiToJsonToXml}`, payload, {
      responseType: 'text',
    });
  }
  viewExportSolution(Id: any) {
    return this.http.get(`${this.api?.importExportHistory}/${Id}`);
  }

  getGSIDetailsById(id: any) {
    return this.http.get(`${this.api?.saveGsi}/${id}`);
  }
  getTracableGSIDetailsById(id: any) {
    return this.http.get(`${this.api?.saveTracableGsi}/${id}`);
  }
  editGsiDtrt(data: any) {
    return this.http.post(`${this.api?.editGsiDtrtApi}`, data);
  }
  getIdDtrt(id: any) {
    return this.http.post(`${this.api?.getIdDtrtApi}`, id);
  }
  getReviewOfDtrt() {
    return this.http.get(`${this.api?.getReviewDtrtApi}`);
  }
  getRevertChangesDtrt(ids: any) {
    return this.http.post(`${this.api?.revertChangesDtrtApi}?customizationIds=${ids}`, {});
  }
  getApplyChangesDtrt(ids: any) {
    return this.http.post(`${this.api?.applyChangesDtrtApi}?customizationIds=${ids}`, {});
  }

  getReviewMileStoneDtrt() {
    return this.http.get(`${this.api?.getReviewMileStoneDtrtApi}`);
  }
  getRevertMileStoneDtrt(ids: any) {
    return this.http.post(`${this.api?.revertMileStoneDtrtApi}?customizationIds=${ids}`, {});
  }
  getApplyMileStoneDtrt(ids: any) {
    return this.http.post(`${this.api?.applyMileStoneDtrtApi}?customizationIds=${ids}`, {});
  }

  fetchDtrtIRDR(id: any) {
    return this.http.get(`${this.api?.fetchDtrtIRDR}?gsiId=${id}`);
  }

  getReviewDtrtIRDR() {
    return this.http.get(`${this.api?.getReviewDtrtIRDR}`);
  }
  revertChangesDtrtIRDR(ids: any) {
    return this.http.post(`${this.api?.revertChangesDtrtIRDR}?customizationIds=${ids}`, {});
  }
  applyChangesDtrtIRDR(ids: any) {
    return this.http.post(`${this.api?.applyChangesDtrtIRDR}?customizationIds=${ids}`, {});
  }

  getReviewDtrtgeIRDR() {
    return this.http.get(`${this.api?.getReviewDtrtgeIRDR}`);
  }
  revertChangesDtrtgeIRDR(ids: any) {
    return this.http.post(`${this.api?.revertChangesDtrtgeIRDR}?customizationIds=${ids}`, {});
  }
  applyChangesDtrtgeIRDR(ids: any) {
    return this.http.post(`${this.api?.applyChangesDtrtgeIRDR}?customizationIds=${ids}`, {});
  }

  getReviewDtrtReservedAction() {
    return this.http.get(`${this.api?.getReviewDtrtReservedAction}`);
  }
  getRevertChangesDtrtReservedAction(ids: any) {
    return this.http.post(`${this.api?.revertChangesDtrtReservedAction}?customizationIds=${ids}`, {});
  }

  applyChangesDtrtReservedAction(ids: any) {
    return this.http.post(`${this.api?.applyChangesReservedActionApi}?customizationIds=${ids}`, {});
  }

  getCuDetailsById(id: any) {
    return this.http.get(`${this.api?.getBasicCu}/${id}`);
  }

  getpublishGsiById(id: any) {
    return this.http.get(`${this.api?.publishGsiById}/${id}`);
  }
  publishEditGsi(pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      `${this.api?.publishedgsi}?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  getGsiCuList(selectedGsiId: any) {
    return this.http.get(`${this.api?.txnData}cuList?gsiId=${selectedGsiId}`);
  }

  getPathMetrics(endDate: any, startDate: any, gsiId: any, tenantId: any) {
    return this.http.get(
      `${this.api?.txnData}getAllPaths?startDate=${startDate}&endDate=${endDate}&gsiId=${gsiId}&tenantId=${tenantId}`
    );
  }
  gsiTxnMetrics(endDate: any, startDate: any, gsiId: any, tenantId: any) {
    return this.http.get(
      `${this.api?.txnData}metrics?startDate=${startDate}&endDate=${endDate}&gsiId=${gsiId}&tenantId=${tenantId}`
    );
  }
  saveEntityMapping(entityMapPayload: any) {
    return this.http.post(this.api?.entityData + 'save/generlentitymapping', entityMapPayload);
  }

  saveProcess(processPayload: any) {
    return this.http.post(this.api?.entityData + 'save/Process', processPayload);
  }

  getProcessList(processSearchText: any = '') {
    let tenant = localStorage.getItem('TenantName');
    return this.http.get(`${this.api?.entityData}process/list?tenantName=${tenant}&searchName=${processSearchText}`);
  }

  getProcessMap(id: any, type: string, activitySliderValue: any = 1) {
    activitySliderValue = activitySliderValue < 0.3 ? 0.3 : activitySliderValue;
    if (type == 'process')
      return this.http.get(
        `${this.api?.entityData}processmap?processId=${id}&activitySliderValue=${activitySliderValue}&connectionSliderValue=1`
      );
    if (type == 'entity')
      return this.http.get(
        `${this.api?.entityData}processmap?entityId=${id}&activitySliderValue=${activitySliderValue}&connectionSliderValue=1`
      );
  }

  getCSVProcessMap(payload: any) {
    return this.http.post(`${this.api?.entityData}csv/getmap`, payload);
  }

  getFileHeaders(file: any) {
    return this.http.post(`${this.api?.entityData}csv/getHeaders`, file);
  }

  executeCustomKPIQuery(queryPayload: any) {
    // SELECT distinct(activity), gsiname,entityname FROM $tableName where processname='Candidate Process'
    return this.http.post(`${this.api?.entityData}custom/kpi`, queryPayload);
  }

  getDefaultKPI(id: any, type: any, kpiType: any) {
    if (type == 'process')
      return this.http.get(`${this.api?.entityData}default/kpis?processId=${id}&kpiType=${kpiType}`);
    if (type == 'entity') return this.http.get(`${this.api?.entityData}default/kpis?entityId=${id}&kpiType=${kpiType}`);
  }

  getAllPorcessMapPaths(id: any, type: any) {
    if (type == 'process') return this.http.get(`${this.api?.entityData}paths?processId=${id}`);
    if (type == 'entity') return this.http.get(`${this.api?.entityData}paths?entityId=${id}`);
  }

  getCSVPorcessMapPaths(payload: any) {
    return this.http.post(`${this.api?.entityData}csv/getpaths`, payload);
  }

  getConformance(confPayload: any) {
    return this.http.post(`${this.api?.entityData}conformance`, confPayload);
  }

  getCSVConformance(confPayload: any) {
    return this.http.post(`${this.api?.entityData}csv/getconformance`, confPayload);
  }

  getExceptionCategory() {
    return this.http.get(`${this.api?.getExceptionalCategory}`);
  }

  // milestone transaction this.apis
  getMilestoneList(id: any) {
    return this.http.get(this.api?.getMilestoneList + id);
  }
  getpercentMilestoneList(id: any) {
    return this.http.get(this.api?.getpercentMilestoneList + id);
  }
  getParticularMilestone(gsiId: number, milestoneid: number) {
    return this.http.get(this.api?.getParticularMilestone + `${gsiId}&milestoneId=${milestoneid}`);
  }
  notifyMilestone(element: any) {
    return this.http.post(this.api?.notifyMilestone, element);
  }

  /* -------------------------- Pushback - FE757 - START -------------------------- */

  // Get all pushbacks for GSI
  getPushbacks(data: any) {
    return this.http.get(this.api?.corePushbacks + `/gsi/${data.masterId}/${data.tfId}`);
  }

  // Get all target CUs for any particular CU
  getTargetCUsForPushbacks(data: any) {
    return this.http.get(
      this.api?.corePushbacks +
        `/cus?rootGSIId=${data.gsiId}&currentTriggerCUId=${data.CUId}&currentTriggerCUContextualId=${data.CUContextualId}`
    );
  }

  // Save/Update pushback
  postPushback(data: any) {
    if (data.id) {
      return this.http.put(this.api?.corePushbacks + '/', data);
    } else {
      return this.http.post(this.api?.corePushbacks + '/', data);
    }
  }

  // Delete Pushback
  deletePushback(data: any) {
    return this.http.delete(this.api?.corePushbacks + `/${data.id}/${data.gsiMasterId}/${data.gsiId}`);
  }

  getCUsInTransForPushback(data: any) {
    return this.http.get(
      this.api?.transPushbacks +
        `/cu?txnId=${data.txnId}&rootGSIId=${data.gsiId}&currentTriggerCUId=${data.tfId}&currentTriggerCUContextualId=${data.contextualId}`
    );
  }

  /* -------------------------- Pushback - FE757 - END-------------------------- */

  //Save subtransactional GSi
  saveSubtransactionalGSI(data: any) {
    return this.http.post(this.api?.saveSubtransactionalGsi, data);
  }

  //Dymamic schedule job
  getAllscheduledjobs(gsiId: any, gsiMaterId: any, gsiVersion: any) {
    return this.http.get(this.api?.scheduleJob + `/${gsiId}?masterId=${gsiMaterId}&version=${gsiVersion}`);
  }

  saveSchedulerJob(data: any) {
    return this.http.post(this.api?.saveScheduleJob, data);
  }

  // Delete schedule job
  deleteSchedulerJob(gsiId: any, jobId: any) {
    return this.http.delete(this.api?.saveScheduleJob + `?gsiId=${gsiId}&jobId=${jobId}`);
  }
  rearrangeEntity(cuId: any, layerType: any, currentSlotIndex: any, newSlotIndex: any, gsiId: any, Data: any) {
    return this.http.put(
      this.api?.reArrangeEntity +
        `/${cuId}/${layerType}?currentSlotIndex=${currentSlotIndex}&newSlotIndex=${newSlotIndex}&gsiId=${gsiId}`,
      Data
    );
  }
  getVersionList(id: any) {
    return this.http.get(this.api?.versionList + id);
  }

  validateGSIName(tenantName: any, gsiName: string) {
    return this.http.get(this.api?.validateGSIName + gsiName + '?tenantId=' + tenantName);
  }
  getSourceCuList(id: any) {
    return this.http.get(`${this.api?.corePushbacks}/cus/${id}`);
  }

  deleteGsiDraft(gsiId: any) {
    return this.http.delete(this.api?.deleteGsiDraft + gsiId);
  }

  importGsiFromNslLibrary(data: any, dsdId: any) {
    return this.http.post(`${this.api?.betStoreSolutionImport}/${dsdId}`, data);
  }

  loadUIControls(cuId: any) {
    return this.http.get(`${this.api?.uiControls}?cuId=${cuId}&cuType=CHANGEUNIT`).pipe(pluck('result'));
  }
  /*istanbul ignore next*/

  getGsiIdFromMasterId(masterId: number) {
    return this.http.get(`${this.api?.getGsiIdFromMasterId}/${masterId}`);
  }
  getTranslationGsiById(gsiId: any) {
    return this.http.get(this.api?.importExportTranslation + `/${gsiId}`);
  }
  importTranslation(data: any, ischecked: boolean) {
    return this.http.post(`${this.api?.importTranslation}?replace=${ischecked}`, data);
  }


  /*istanbul ignore next*/
  addToCart(data: any): Observable<any> {
    return this.http.post(`${this.api?.addToCart}`, data);
  }

  /*istanbul ignore next*/
  viewCart(): Observable<any> {
    return this.http.get(`${this.api?.viewCart}`);
  }

  /*istanbul ignore next*/
  getCartConfiguration(): Observable<any> {
    let client_id: string = getEnvClientId();
    return this.http.get(`${this.api?.getCartConfig + client_id}`);
  }
  updateCartQuantity(id: string, isReduce: boolean): Observable<any> {
    return this.http.put(`${this.api?.updateCartQuantity}${id}?quantity=1&isToReduce=${isReduce}`, {});
  }

  deleteCartItem(id: number) {
    return this.http.delete(`${this.api?.deleteCartItem}${id}`);
  }

  getSourceAndTargetEnv(body: any, selectedEnv: any) {
    return this.http.post(`${this.api?.getSourceAndTargetEnvApi}${selectedEnv}`, body);
  }

  dtrtPromoteCustomization(body: any, selectedEnv: any) {
    return this.http.post(`${this.api?.dtrtPromoteCustomizationApi}${selectedEnv}`, body);
  }

  getExludedLanguageForFat2Flat() {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.excludedLanguageForFat2Flat}`);
  }

  getEnvironmentDetailsEndPt(tenantName?: any) {
    let tenantId = localStorage.getItem('TenantId');
    if (tenantName) {
      return this.http.get(`${this.api?.environmentsForTenant}${tenantName}`);
    } else {
      return this.http.get(`${this.api?.environmentsForTenant}${tenantId}`);
    }
  }
  selectedMenuIndex(menuItem: string) {
    this.menuItem = menuItem;
  }

  getEnvironmentDetails() {
    return this.getEnvironmentDetailsEndPt();
  }
  getAllReservedCUs(id: any) {
    return this.http.get(`${this.api?.getReservedCus}/${id}?reservedCUType=`);
  }
  saveRuleEditor(data: any) {
    const headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data; boundary=14');

    return this.http.post(`${this.api?.ruleEditor}`, data);
  }
  fetchRules() {
    return this.http.get(`${this.api?.ruleEditor}/get-all`);
  }
  updateRule(data: any, ruleKey: any, ruleId: any) {
    let data1 = { ...data, tenantId: ruleId };
    return this.http.put(`${this.api?.ruleEditor}?ruleKey=${ruleKey}`, data);
  }
  fetchRule(id: any) {
    return this.http.get(`${this.api?.ruleEditor}?ruleKey=${id}`);
  }
  testRules(ruleKey: any) {
    let data: any | null;
    return this.http.patch(`${this.api?.ruleEditor}/${ruleKey}/test`, data);
  }
  deployRules(ruleKey: any) {
    let data: any | null;
    return this.http.patch(`${this.api?.ruleEditor}/${ruleKey}/deploy`, data);
  }
  applyRules(data: any) {
    return this.http.post(`${this.api?.applyRules}?isAttributeDataRequired=true`, data);
  }
  getCommits(data: any) {
    return this.http.get(`${this.api?.ruleEditor2}/rule/${data}/versions/all`);
  }
  viewSelectedVersion(ruleKey: any, version: number) {
    return this.http.get(`${this.api?.ruleEditor}/${ruleKey}/version/${version}`);
  }
  versionPagination(pageIndex: number, pageSize: number, ruleId: any) {
    return this.http.get(
      `${this.api?.ruleEditor2}/rule/id/${ruleId}/commits/paged?pageNo=${pageIndex}&pageSize=${pageSize}&ordeByVersionDesc=false`
    );
  }
  revertVersion(version: number, ruleId: any, formData: any) {
    return this.http.put(`${this.api?.ruleEditor2}/revert/${ruleId}?version=${version}`, formData);
  }
  ruleOperatorsAPI() {
    return this.http.get(`${this.api?.ruleEditor}/operators`);
  }
  sampleExcelFile(){
    return this.http.get(`${this.api?.ruleEditor}/sample/excel`);
  }
  getGsiVersionsList(masterId: any, data: any) {
    return this.http.get(
      `${this.api?.gsiVersionsList}${masterId}?limit=${data?.limit}&page=${data?.pageNo}&versionSearchCriteria=${data?.searchCriteria}&statusList=PUBLISHED,ARCHIVED&userRights=EXECUTION_RIGHTS&designMode=false`
    );
  }
  validateVantagePoints(param: any) {
    let path = `context=${param['path params']}`;
    if (param?.index != undefined) {
      path = `context=${param['path params']}?index=${param['index']}`;
    }
    return this.http.post(`${this.api?.dcdValidate}?${path}`, param['Request Body']);
  }

  //Kanban Entity Data Update
  updateEntityData(payload: any) {
    return this.http.post<any>(this.api?.entityRecordUpdate, payload);
  }

}
