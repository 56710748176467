import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
// import {} from './../../../../modules/node-shared/components/connectors/connectors.component.scss'

@Component({
  selector: 'app-tbl-configuration',
  templateUrl: './tbl-configuration.component.html',
  styleUrls: [
    './tbl-configuration.component.scss',
     '../../components/connectors/connectors.component.scss'
  ],
})
export class TblConfigurationComponent implements OnInit {
  @Input() configdata: any;
  formGroup: FormGroup;
  ngUnsubscribe = new Subject();
  labels: any;
  TableColorPickr: boolean;
  cardformGroup: FormGroup;
  entityConfig: any;
  tenantName: string;
  submitOnSelection: false;
  constructor(
    private entityFacade: NodeEntityFacadeService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService
  ) {
    this.tenantName = localStorage.getItem('TenantName');
    this.formGroup = formBuilder.group({
      showTitle: false,
      pagination: [false, Validators.requiredTrue],
      allowColumnsort: false,
      allowColumnFilter: false,
      allowColumnSearch: false,
      allowDownload: false,
      allowExpand: false,
      allowSelection: false,
      toggleColumn: false,
      allowUpload: false,
      hideSerialNumber: false,
      hideSelectButton: false,
      hideLabels: false,
      freezeColumns: '',
      filterFields: [],
      searchFields: [],
      sortFields: [],
      deleteRecord: this.tenantName == 'callhealth' ? false : true,
      allowDuplication: false,
      viewAsGridTable: false,
    });
    this.cardformGroup = formBuilder.group({
      minimumselection: [0],
      maximumselection: [0],
      minimumselectionAll: [false],
    });
    this.detectLanguageChange();
  }
  ngOnInit(): void {
    this.setEntityConfig();
    /* istanbul ignore next */
    if (this.configdata.data?.entityData?.configuration) {
      this.entityConfig.type = JSON.parse(this.configdata.data?.entityData?.configuration).type;
      /* istanbul ignore next */
      if (this.entityConfig.type == 'table') {
        if (this.formGroup?.value?.deleteRecord === null) {
          if (this.tenantName === 'callhealth') {
            this.formGroup.value.deleteRecord = false;
            this.formGroup?.controls?.['deleteRecord']?.setValue(false);
          } else {
            this.formGroup.value.deleteRecord = true;
            this.formGroup?.controls?.['deleteRecord']?.setValue(true);
          }
        }
        this.entityConfig = JSON.parse(this.configdata.data?.entityData?.configuration);
        if (!this.entityConfig.colouring) {
          this.entityConfig.colouring = {};
          this.entityConfig.tableSettings = {};
          this.entityConfig.pagination = {
            rows: 10,
            placementOfPagination: '',
          };
          this.entityConfig.export = [
            {
              label: 'Pdf',
              isChecked: false,
            },
            {
              label: 'CSV',
              isChecked: false,
            },
            {
              label: 'Excel',
              isChecked: false,
            },
          ];
          this.entityConfig.tableDescription = {};
          this.entityConfig.newTableConfiguration = true;
        }
      } else {
        this.entityConfig.type = 'table';
      }
      this.submitOnSelection = this.entityConfig?.tableSettings?.submitOnSelection || false;
    }
    /* istanbul ignore next */
    if (this.entityConfig?.setting && Object.keys(this.entityConfig?.setting).length !== 0) {
      this.formGroup.patchValue(this.entityConfig.setting);
    }
    /* istanbul ignore next */
    if (this.entityConfig?.tableSettings && Object.keys(this.entityConfig?.tableSettings).length !== 0) {
      this.cardformGroup.patchValue(this.entityConfig.tableSettings);
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  saveconf() {
    this.entityConfig.setting = this.formGroup.value;
    /*istanbul ignore next*/
    if (this.configdata?.data?.entityData?.nslAttributes) {
      this.configdata.data.entityData.nslAttributes.forEach((attr: any) => {
        if (typeof attr['configuration'] !== 'string') {
          attr['configuration'] = JSON.stringify(attr['configuration']);
        }
      });
    }
    if (this.entityConfig.tableSelection == 'single') {
      this.entityConfig.hideSelectAll = true;
      this.entityConfig.tableSettings = {
        maximumselection: 1,
        minimumselection: 1,
        minimumselectionAll: false,
        submitOnSelection: this.submitOnSelection,
      };
    } /* istanbul ignore next */ else if (
      this.entityConfig.tableSelection == 'multiple' &&
      !this.formGroup.value.hideSelectButton
    ) {
      this.entityConfig.tableSettings = this.cardformGroup.value;
    } else {
      this.entityConfig.tableSettings = {};
    }

    this.configdata['configuration'] = JSON.stringify(this.entityConfig);
    /* istanbul ignore next */
    this.entityFacade.sendupdateEntityTblConfig(this.entityConfig);
    // this.entityFacade.saveEntity(
    //   this.configdata?.data?.entityData,
    //   false,
    //   this.configdata?.data?.cu,
    //   [],
    //   this.configdata.data?.layerType,
    //   false,
    //   this.configdata.data?.layerType,
    //   this.configdata?.configuration
    // );
    this.closeTableUiConfiguration();
  }

  changeSelection(event: any) {
    this.entityConfig.pagination.placementOfPagination = event;
  }

  closeTableUiConfiguration() {
    this.entityFacade.tableUIConfiguration(false, this.configdata);
  }
  setEntityConfig() {
    this.entityConfig = {
      setting: {},
      export: [
        {
          label: 'Pdf',
          isChecked: false,
        },
        {
          label: 'CSV',
          isChecked: false,
        },
        {
          label: 'Excel',
          isChecked: false,
        },
      ],
      colouring: {},
      tableDescription: {},
      pagination: {
        rows: 10,
        placementOfPagination: '',
      },
      display: '',
      newTableConfiguration: true,
      type: 'table',
      hideSelectAll: false,
      cardSelection: '',
      cardSettings: {},
    };
  }
}
