import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ManagerEnironmentService } from './manager-enironment.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class ManageEnvDataFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  getEnvironmentsforTenant = new Subject();
  getEnvironmentsforTenant$ = this.getEnvironmentsforTenant.asObservable();

  private getRemainingEnvs = new Subject();
  getRemainingEnv$ = this.getRemainingEnvs.asObservable();

  private envCreated = new Subject();
  envCreated$ = this.envCreated.asObservable();

  private envCount = new Subject();
  envCount$ = this.envCount.asObservable();

  getEnvDetailsandCount = new Subject();
  getEnvDetailsandCount$ = this.getEnvDetailsandCount.asObservable();

  sendEnvBulkUserData = new BehaviorSubject('');
  sendEnvBulkUserData$ = this.sendEnvBulkUserData.asObservable();

  adduserpageToenv = new BehaviorSubject('');
  adduserpageToenv$ = this.adduserpageToenv.asObservable();

  getConfig = new Subject();
  getConfig$ = this.getConfig.asObservable();

  promoteGsi = new Subject();
  promoteGsi$ = this.promoteGsi.asObservable();

  constructor(
    private loader: LoaderService,
    private alertService: AlertService,
    private manageEnv: ManagerEnironmentService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  envToadduser(msg: any) {
    this.adduserpageToenv.next(msg);
  }
  getEnvList(tenantId: any) {
    return this.manageEnv.getEnvironmentsForTenant(tenantId);
  }
  userCustomAttributes(clientId: any) {
    if (clientId) {
      return this.manageEnv.userCustomAttributes(clientId);
    }
  }
  getClientIdFromStorage(): string | null {
    return localStorage.getItem('clientId');
  }
  loadEnvData() {
    const env = localStorage.getItem('environmentName');
    const tenantId = localStorage.getItem('TenantId');
    /*istanbul ignore else*/
    if (tenantId) {
      let environments = this.manageEnv.getEnvironmentsForTenant(tenantId);
      let envcount = this.manageEnv.getEnvCount(env);
      forkJoin([environments, envcount])
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          /*istanbul ignore else*/
          if (res && res[0] && res[1]) {
            /*istanbul ignore next*/
            res[0]?.result?.forEach((environment: any) => {
              Object.keys(res[1]?.result)?.forEach((key: any) => {
                if (environment.name == key) {
                  environment['count'] = res[1]?.result[key];
                }
              });
            });
            this.getEnvDetailsandCount.next(res[0]);
          }
        });
    }
  }

  getEnvironmentsForTenant(tenantName: any) {
    this.loader.show();
    this.manageEnv
      .getEnvironmentsForTenant(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEnvironmentsforTenant.next(res);
      });
  }

  getRemainingEnv(tenantName: any) {
    this.loader.show();
    this.manageEnv
      .getRemainingEnv(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getRemainingEnvs.next(res);
      });
  }

  /** API Call for creating environemts */

  createEnvironment(details: any) {
    this.loader.show();
    this.manageEnv
      .createEnvironment(details)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.status == 'CREATED') {
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
        this.envCreated.next(res);
      });
  }

  // publish environment to gsi //
  publishGsiToEnv(env: any, gsiId: any, promoteWithDTRT?: boolean) {
    this.loader.show();
    this.manageEnv
      .publishGsiToEnv(env, gsiId, promoteWithDTRT)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.promoteGsi.next(res);
          this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
        },
        (err: any) => {
          const error = err?.error?.result?.cuExceptions?.BASIC[0]?.errorDetails[0];
          this.promoteGsi.next(null);
          this.alertService.showToaster(error?.message ? error?.message : err.message, '', 'error');
        }
      );
  }
  // get environment counts //
  getEnvCount(envName: any) {
    this.manageEnv
      .getEnvCount(envName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.envCount.next(res);
      });
  }

  getEntityConfig(tenantId?: string) {
    this.manageEnv
      .getEntityConfig(tenantId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getConfig.next(res);
      });
  }

  saveEntityConfiguration(data: any) {
    this.manageEnv
      .saveEntityConfiguration(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log('done');
      });
  }

  envBulkUserData(tenant: any) {
    this.sendEnvBulkUserData.next(tenant);
  }

  updateEnvironmentDetails(envData: any, envType: any) {
    return this.manageEnv.updateEnvironmentDetails(envData, envType);
  }

  fetchUsersList(envType: any, searchText: any, pageNum: any, pageSize: any) {
    return this.manageEnv.fetchUsersList(envType, searchText, pageNum, pageSize);
  }
  fetchUnassignedUsersList(envType: any, searchText: any, pageNum: any, pageSize: any) {
    return this.manageEnv.fetchUnassignedUsersList(envType, searchText, pageNum, pageSize);
  }

  unassignUsers(list: any, envType: any) {
    return this.manageEnv.unassignUsers(list, envType);
  }

  assignUsers(list: any, envType: any) {
    return this.manageEnv.assignUsers(list, envType);
  }
}
