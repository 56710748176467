import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  NgZone,
  PipeTransform,
  Pipe,
  ViewChildren,
  TemplateRef,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  addValueToAlreadyAddedSearchQuery,
  AlertService,
  LoaderService,
  displayDateDefaultValue,
  EntityBoardEndpointService,
  EventsEndpointService,
  FilterTable,
  iterateSearchQueryToAppendCurrentData,
  LibraryFacadeService,
  primeNgConfigs,
  RuleSetCondition,
  ColumnStyle
} from '@common-services';
import { GsiFacadeService } from '@common-services';
import { TranslatorService } from '@common-services';
import { Observable, Subject, forkJoin } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import Swiper, { Navigation } from 'swiper';
import { templateExpandMap } from '@common-services';
import { SharedFacadeService } from '@common-services';
import { SwiperComponent } from 'swiper/angular';
import * as XLSX from 'xlsx';
import * as fileSaver from 'file-saver';
import * as saveAs from 'file-saver';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
Swiper.use([Navigation]);
import { entityCardMapper } from '@common-services';
import { GsiButton } from '@common-services';
import { EntityBoardFacadeService } from '@common-services';
import {
  appendValue,
  attributeDetails,
  attributeLayoutDetails,
  CardRecord,
  Combination,
  encodeString,
  facetMapper,
  FilterConfiguration,
  FilterData,
  FilterDesignTemplate,
  formatDate,
  SearchConfiguration,
  SearchFieldsConfiguration,
  SortConfiguration,
  templateMapper,
  getNoValue
} from '../../constants/customize-filter-templates.constants';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MatDialog } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { facetSearchMapper } from '@common-services';
import { CreateEqlComponent } from './create-eql/create-eql.component';
import { KanbanConfiguration } from '@common-services';
import { TemplateDialog } from './template-dialog/template-dialog.component';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-entity-grid-card',
  templateUrl: './entity-grid-card.component.html',
  styleUrls: ['./entity-grid-card.component.scss'],
})
export class EntityGridCardComponent implements OnInit, OnChanges {
  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('swiperDiv', { static: false }) swiperDiv: ElementRef;
  @ViewChild('noSwiperDiv', { static: false }) noSwiperDiv: ElementRef;
  @Input() entityList: any = [];
  @Input() cardDataId: string = '';
  @Input() isMccLayout: boolean = false;
  @Input() isExpandedVersion: boolean = false;
  @Input() cardId: any;
  @Input() isShowCardDetails: boolean = true;
  @Input() fromMCC: boolean = false;
  @Input() chatbot: boolean = false;
  @Input() chatbotCardData: any = [];
  @Input() tempIsMCC: boolean = false;
  @Input() hideOptions: boolean = false;
  @Input() EntityTemplateType: string = '';
  @Input() isDefaultWrapperTemplate: boolean = false;
  @Output() openEntityCard = new EventEmitter();
  @Output() backFromCard = new EventEmitter();
  @Output() scrollEntityCards = new EventEmitter();
  showSwiper: boolean = true;
  @Output() addFilterData = new EventEmitter();
  @Input() mccTitle: string;
  @Output() emitSortData = new EventEmitter();
  hyperlinkAttribute: any = [];
  expandHyperlinkAttributes: any = [];
  entityCardFlip: boolean = false;
  nestedRowTableData: any[] = [];
  longestRecordPos: number = 0;
  serachFieldScrolled: boolean = true;
  searchFieldPageCount: any = 0;
  paginatorTableWidth: string = '100%';
  apiVersion: any;
  savedSearchQuery: any;
  @Input() sharableFilterSearchQuery: any;
  inputSearchFilter: any = new Subject();
  swiperCards: number = 1;
  swiperPage: number = 0;
  mouseDown: any;
  startX: number;
  swiperDivWidth: number;
  swiperCardWidth: number;
  differnceInDrag: number;
  numberOfPages: number = 0;
  swiperGap: number;
  moveLeft: boolean;
  moveRight: boolean;
  previousPageNumber: number = 0;
  afterKey: string="";
  @Input() set setEntitySearchFields(value: {
    isEmitted: boolean;
    searchFields: any[];
    searchAttribute: string;
  }) {
    if (value.isEmitted) {
      this.attributeValue = value.searchAttribute;
      this.applyFilterAndSort(true, true);
    }
  }
  @Input() showViewAllButton: boolean = true;
  private ngUnsubscribe = new Subject();
  paginatorNumber: number = 0;
  expandType: number = 1;
  ngSubscribe = new Subject();
  labels: any = [];
  showAADS: boolean = false;
  environmentType: string = '';
  scenario: any = '';
  cardDetails: any = { filterBy: [], sortBy: [] };
  transactionData: any = {};
  tabSelected: any = 'child';
  cardsData: any = [];
  isCardMultiExpand: boolean = false;
  isMore: boolean = false;
  mappingFields: any;
  cardColor: string = '';
  colors: string[] = [];
  numberOfRows: number;
  conditionArray: any;
  expandColors: any;
  expandBackground: any;
  conditionArrayExpand: any;
  locationFilter: any = [];
  displayLocationstatus: boolean = false;
  currentlat: number;
  currentlng: number;
  groupFilterDataBy: any;
  expandBtnColors: any;
  oddRowColor: any;
  evenRowColor: any;
  widthArray: any;
  headerBackground: any;
  nestedRowHeaderBackground: any;
  pagination: any;
  headerFreeze: any;
  gsiConfigurations: any;
  description: any;
  gridLines: any;
  header: any;
  nestedRowHeader: any;
  evenRow: any;
  oddRow: any;
  advanceTableFeatures: any;
  isNestedRow1: any;
  tableActionButtons: any;
  showCards: boolean = true;
  otherStyles: any;
  actionButton: any = 'SEARCH';
  activeUserInfo: any;
  stylesExpand: any;
  openSaveSearchOrFilterBox: boolean = false;
  openSaveFilterBox: boolean = false;
  openSavedSearch: boolean = false;
  ingredient: string = '';
  searchHistoryArray: string[];
  filterName: string = '';
  cartView: any;
  pageSize: number;
  expandTxnDataIndex: any = 0;
  hiddenFilterRule: RuleSetCondition;
  filterDesign: FilterDesignTemplate;
  verticalLayout: attributeLayoutDetails[];
  individual: attributeDetails[];
  combination: Combination[];
  set _applyColor(value: string[]) {
    this.colors = value;
  }
  btnColors: string[] = [];
  expandMappingFields: any[] = [];
  filterCheckList: any;
  filterValues: any[] = [];
  sortValues: any[] = [];
  isSearch: boolean = false;
  sortOption: any = '';
  sortOptionAttr: any = '';
  isExpanded: boolean = false;
  expandRecordId: string;
  expandCardDetails: any[] = [];
  gsiExpanddata: any;
  hyperlinkMap = new Map();
  hyperlinkCtaDropArray: any[] = [];
  attributeUIType: Map<string, string> = new Map<string, string>();
  attributeUIElementMap: Map<string, string> = new Map<string, string>();
  attributeIndexMap: Map<string, number> = new Map<string, number>();
  attributeProperties: Map<string, any> = new Map<string, any>();
  nestedRowAttributeUIType: Map<string, string> = new Map<string, string>();
  nestedRowAttributeUIElementMap: Map<string, string> = new Map<string, string>();
  nestedRowAttributeIndexMap: Map<string, number> = new Map<string, number>();
  //--- varaibles relate to SEARCHFIELDS --------------------------------
  entitySearchFields: any[] = [];
  searchFieldIdGenerator: number = 1;
  searchFieldTemplate: any;
  dynamicEntitySearchFields: any[] = [];
  showEntitySearchFields: any = [];
  entitySearchFieldMap: Map<string, Array<string>> = new Map<string, Array<string>>();
  selectedSearchFieldIds: string[] = [];
  searchConfigurationData: any[] = [];
  attributeVal: string = '';
  totalLength: number;
  @Output() entitySearchFieldsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() showSearchFields: boolean = true;

  @ViewChildren(OverlayPanel) overLayPanels: OverlayPanel[];
  /** @var nonSearchFields  is a variable that is ment for the variable which doesnt have configuration as search fields*/
  nonSearchFields: any[] = [];
  // --- SEARCHFIELDS  END--------------------------------
  @Input() showSearch: boolean = true;
  tbl_conf_collapsebtn: boolean = false;

  selectedAttribute: string = '';
  attributeValue: string = '';
  CART_BUTTON: GsiButton;
  gsiMapping: { [key: number]: GsiButton } = {};
  cartEnabled: boolean = false;
  isGeBoard: boolean = false;
  showfromMcc: boolean = true;
  @Input() isExtended: boolean = false;
  entityGrid_config: SwiperOptions = {
    loop: false,
    autoHeight: true,
    allowTouchMove: true,
    spaceBetween: 24,
    breakpoints: {
      1920: {
        slidesPerView: 'auto',
        grid: {
          fill: 'row',
          rows: 1,
        },
      },
      1200: {
        slidesPerView: 'auto',
        grid: {
          fill: 'row',
          rows: 1,
        },
      },
      1000: {
        slidesPerView: 'auto',
        grid: {
          fill: 'row',
          rows: 1,
        },
      },
      500: {
        slidesPerView: 1,
      },
      400: {
        slidesPerView: 1,
      },
      300: {
        slidesPerView: 1,
      },
    },
    pagination: { el: '.swiper-pager', clickable: true },
    navigation: {
      nextEl: '.swiper-outer-1 .btn-next',
      prevEl: '.swiper-outer-1 .btn-prev',
    },
  };
  inputOutputMapping: any = {};
  length: any;

  @Input() tableData: any = [];
  @Input() isTable: boolean = false;
  range: any = [];
  rangeData: any = [];
  urlBindingDataConfiguration: any;
  @Input() hideAllOptions: boolean = false;
  @Input() set multitEntityRecordTemaplate(data: string) {
    this.mccTemplateType = data;
    if (data === 'twoRowTemplate') {
      this.entityGrid_config.breakpoints['1920'].grid.rows = 2;
      this.entityGrid_config.breakpoints['1200'].grid.rows = 2;
      this.multiEntityRecordsStructure = 'carousel';
    } else if (data === 'threeRowTemplate') {
      this.entityGrid_config.breakpoints['1920'].grid.rows = 3;
      this.entityGrid_config.breakpoints['1200'].grid.rows = 3;
      this.multiEntityRecordsStructure = 'carousel';
    } else if (data === 'fourRowTemplate') {
      this.entityGrid_config.breakpoints['1920'].grid.rows = 4;
      this.entityGrid_config.breakpoints['1200'].grid.rows = 4;
      this.multiEntityRecordsStructure = 'carousel';
    } else if (data === 'wrapTemplate') {
      this.multiEntityRecordsStructure = 'wrap';
    }
  }
  multiEntityRecordsStructure: string = 'carousel';
  mccTemplateType: string = '';
  @ViewChild('cardColorConfiguration') cardHtmlConfig: ElementRef;
  filterDatatoFilter: any;
  // expand pop up variables
  @ViewChild('expandTemplate') expandTemplate: TemplateRef<HTMLElement>;
  isExpandPopup: boolean = false;
  enableAnalytics: boolean = false;
  legendSelected: boolean = false;
  enableCompleteRecord: boolean = false;
  legendAlignment: any;
  @Input() set filterData(data: any) {
    this.rangeData = data?.rangeData ? data?.rangeData : [];
    this.filterValues = data?.filterValues ? data?.filterValues : [];
    this.sortOptionAttr = data?.sortOptionAttr ? data?.sortOptionAttr : '';
    this.sortOption = data?.sortOption ? data?.sortOption : '';
    /* istanbul ignore else */
    if (data?.eventPassed && data?.eventPassed === true) {
      if (data?.saveFilter && data?.saveFilter === true) {
        this.applyFilterAndSort(false, false, true, 'filter', data?.id);
      } else {
        this.applyFilterAndSort();
      }
    }
  }
  @Input() set searchData(data: any) {
    this.attributeValue = data?.attributeValue ? data?.attributeValue : '';
    this.selectedAttribute = data?.selectedAttribute ? data?.selectedAttribute : '';
    /* istanbul ignore else */
    if (data?.eventPassed && data?.eventPassed === true) {
      this.noSpancing();
    }
  }

  @Input() set showSearchPage(value: boolean) {
    if (value) {
      this.applyFilterAndSort(true, true);
    }
  }
  isDynamicTemplate: boolean = false;

  isExpandDynamicTemplate: boolean = false;
  dynamicCard: string = '';
  dynamicStyles: string = '';

  selectedDynamicTemplate: any;
  selectedSearchFeilds: any = [];
  // for all attrubites in child
  childAttributeList: any[] = [];
  aggregateData: any[] = [];
  sideMenu_swiper_config: SwiperOptions = {
    loop: false,
    autoHeight: true,
    allowTouchMove: true,
    spaceBetween: 1,
    autoplay: false,
    slidesPerView: 'auto',
    pagination: { el: '.filter-swiper .swiper-pager', clickable: true },
    navigation: {
      nextEl: '.filter-swiper .swiper-next',
      prevEl: '.filter-swiper .swiper-prev',
    },
    roundLengths: true,
    slidesOffsetAfter: 0,
    centeredSlides: false,
  };
  swiperNavigationEnabled: boolean = false;
  kanbanConfiguration: KanbanConfiguration;
  @ViewChild('savedFilterName') alertDialog: TemplateRef<HTMLElement>;
  static variable: number = 0;
  mcc_entity_card_template = new Set<string>([
    '138',
    '200',
    '322',
    '315',
    '317',
    '18',
    '134',
    '22',
    '46',
    '50',
    '12',
    '206',
    '8',
    '121',
    '329'
  ]);
  // @Output() openSavedFilterEmit = new EventEmitter();
  id: number;
  // showFilters: boolean = false;
  savedFilters: FilterTable[];
  selectedIndex: any = -1;
  cardIdSF: string;
  cardBorderSize: number;
  cardBorderRadius: number;
  cardBorderColor: string = '';
  expandBoxShadow: any;
  cardShadow: string;
  cardHoveredColor: string;
  attrHoveredColors: any;
  expandCardHoveredColor: string;
  expandHoveredColors: any;
  //--- varaibles relate to ADDTOCART --------------------------------
  quantity: number[];
  //--- ADDTOCART END ------------------------------------------------
  exportConfigurations: any;
  activeIndex: any;
  clearAllFlag: boolean = false;
  previousMap: { [key: number]: any } = {};
  previousFilter: string;
  headerArray: any[] = [];
  headerFlag: number = 0;
  displayNameMap: Map<string, string> = new Map<string, string>();
  dateSearchFieldValue: { [x: string]: Date } = {};
  gsiRequestArray: Observable<any>[] = [];
  gsiExpandRequesArray: Observable<any>[] = [];
  gsiTableRequestArray: Observable<any>[] = [];
  appliedSavedFilters: string;
  savedFilterSort: any;
  @Input() matTabMccTemplate: boolean = false;
  @Input() dynamicCardsAlignment: string;
  @Input() hideTitle: string = '';
  @Input() titleColor: string = '';
  isRamcoTenant: boolean = false;
  cardGsiMap: any;
  gsiAttrMap = {};
  gsiAttrMapExpand: any;
  timeStampVariable: any;
  browserName: string = '';
  pageGsi: any;
  expandBtnHoverColors: any = [];
  btnHoverColors: any = [];
  headerGsi: any = {};
  entityDataRaw: any;
  dynamicExpandRecords: any[] = [];
  expandDisplayDetails: any = {};
  columnStyles: ColumnStyle[] = [];
  filteractions: boolean = false;
  accordianfilters: boolean = false;
  isfilteraccordian: boolean = false;
  tenantName: string;
  @Input() myLibrary: boolean = false;
  constructor(
    private router: Router,
    private gsiFacadeService: GsiFacadeService,
    private translator: TranslatorService,
    private alertService: AlertService,
    private loader: LoaderService,
    private sharedFacadeService: SharedFacadeService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private el: ElementRef,
    private entityBoardEndpointService: EntityBoardEndpointService,
    public dialog: MatDialog,
    private rendrer: Renderer2
  ) {
    this.detectLanguageChange();
    this.isExpanded = false;
    this.dropdownInputSearchField();
  }
  horizontalLayout: attributeLayoutDetails[] = [];
  filterConfigurations: FilterConfiguration;
  sortConfigurations: SortConfiguration;
  searchFieldsConfigurations: SearchFieldsConfiguration;
  searchConfigurations: SearchConfiguration;

  ngOnChanges(changes: SimpleChanges): void {
    /* istanbul ignore next */
    if (changes?.isShowCardDetails || changes?.cardDataId) {
      this.openEntity(null);
      if (this.cardDataId) {
        this.entityGrid_config.navigation['nextEl'] =
          '#carousel_' + this.cardDataId + ' .swiper-next';
        this.entityGrid_config.navigation['prevEl'] =
          '#carousel_' + this.cardDataId + ' .swiper-prev';
      }
    }
    if(changes?.isMccLayout || this.isMccLayout) {
      this.swiperPage = 0;
      setTimeout(() => {
        this.createSwiper(this.swiperDiv);
      }, 3000);
    }
  }
  mapSearchDataToRecordData(data: any) {
    let result = {
      status: 200,
      message: 'Facet Results fetched successfully',
      result: {
        total: 1,
        content: [
          {
            data: [
              {
                ...this.facetSearchMapper(data),
                isFavorite: false,
                itemCountInCart: 0,
              },
            ],
          },
        ],
        pageable: {
          sort: {},
          page: 0,
          size: 15,
        },
      },
    };
    return result;
  }

  facetSearchMapper(data: any) {
    let keys = Object.keys(data);
    let entityName = keys[0].split('.')[1];
    let attributeData: any[] = [];
    keys.forEach((key) => {
      if (key != '_loc') {
        let attributeName = key.split('.')[2];
        attributeData.push({
          name: attributeName,
          nslAttributeID: 123,
          values: data[key],
          displayName: attributeName,
        });
      }
    });
    let res = {
      name: entityName,
      displayName: entityName,
      id: 1612348553317,
      generalEntityID: 163468853714,
      isLastBatch: false,
      isMasterData: true,
      isBulkImport: false,
      transEntityRecords: [
        {
          txnNslAttribute: attributeData,
        },
      ],
    };
    return res;
  }

  // static variable: number = 0;
  // id: number;
  ngOnInit(): void {
    const activeUser = localStorage.getItem('ActiveUserInfo');
    this.isRamcoTenant = localStorage.getItem('TenantName')?.includes('ramco');
    this.tenantName = localStorage.getItem('TenantName');
    if (activeUser) {
      this.activeUserInfo = JSON.parse(activeUser);
    }
    if (this.EntityTemplateType == 'multiEntityRecordsBannerTemplate') {
      this.hideAllOptions = true;
      this.isExtended = true;
    } else if (this.EntityTemplateType == 'multiEntityRecordsTemplatedefaultwrapExtended') {
      this.isExtended = true;
    }
    EntityGridCardComponent.variable = EntityGridCardComponent.variable + 1;
    this.id = EntityGridCardComponent.variable;
    //@ts-ignore
    this.browserName = navigator?.userAgentData?.brands[1]?.brand;
    this.setBrowserName();
  }

  @ViewChild('childHeight') childHeight: any;
  ngAfterViewInit() {
    if (this.EntityTemplateType == 'entityChildExpandTemplate') {
      this.childHeight.nativeElement.style.setProperty('--Reldan-card-height', window.innerHeight - 180 + 'px');
    }
  }

  slideNext() {
    this.swiper?.swiperRef?.slideNext(290);
  }
  slidePrev() {
    this.swiper?.swiperRef?.slidePrev(290);
  }
  detectLanguageChange() {
    /* istanbul ignore next */
    this.translator.languageLables$
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }

  openEntity(entity: any) {
    /* istanbul ignore next */
    this.actionButton = 'OPENED';
    if (entity) {
      if (entity?.cardIdList) {
        this.openEntityCard.emit();
        const cardId = entity.cardIdList[entity.cardIdList.length - 1];
        this.detectEntityCardData(cardId);
      } else {
        this.alertService.showToaster(
          'This entity is not a configured as a card',
          '',
          'error'
        );
      }
    } else {
      /* istanbul ignore next */
      const cardId = this.cardId ? this.cardId : this.cardDataId;
      this.detectEntityCardData(cardId);
    }
  }

  private setFilterToLayout() {
    /* istanbul ignore else */
    if (this.filterDesign?.filter?.filterConfiguration?.position === 'side') {
      this.filterDesign?.filter?.attributeDetails?.forEach(
        (attribute: FilterData) => {
          this.horizontalLayout?.push({
            name: attribute.attributeName,
            id: attribute.filterTemplate,
            defaultValue: attribute?.defaultValue,
            divisions: attribute?.divisions,
            displayName: attribute?.attributeDisplayName
              ? attribute?.attributeDisplayName
              : attribute.attributeName,
          });
        }
      );
    }
    this.filterConfigurations = this.filterDesign?.filter?.filterConfiguration;
  }

  private setConfigurations() {
    this.filterConfigurations = this.filterDesign?.filter?.filterConfiguration;
    this.sortConfigurations = this.filterDesign?.sort?.individual?.sortConfigurations;
    this.searchFieldsConfigurations = this.filterDesign?.searchFields?.searchFieldsConfiguration;
    this.searchConfigurations = this.filterDesign?.search?.searchConfiguration;
    this.tbl_conf_collapsebtn =
      this.filterConfigurations?.sidePanelBehaviour == 'collapsible';
  }

  private setSavedFilterData = () => {
    if (this.searchFieldsConfigurations?.saveUserFilters) {
      this.getAllFilters(this.cardDetails?.id);
    }
  };

  detectEntityCardData(cardId: any) {
    this.cardsData = [];
    this.headerFlag = 0;
    this.gsiFacadeService
      .getEntityCardDetails(cardId)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res?.result) {
          this.apiVersion = res?.result?.apiVersion;
          /* istanbul ignore next */
          this.cardDetails = entityCardMapper(res?.result);
          this.kanbanConfiguration = this.cardDetails?.kanbanConfiguration;
          this.childAttributeList = res?.result?.childView?.attributeList;
          this.entityCardFlip = res?.result?.childView?.style?.entityCardFlip;
          this.hiddenFilterRule = res?.result?.filterDesign?.hiddenFilterRule;
          this.enableAnalytics = res?.result?.enableAnalytics;
          this.urlBindingDataConfiguration =
            res?.result?.filterDesign?.urlBindingData;
          this.mappingFields = JSON.parse(
            this.cardDetails?.templateMappingFields
          );
          this.filterDesign = this.cardDetails?.filterDesign;
          if (this.filterDesign?.locationFilter?.attributeDetails?.length > 0) this.getCurrentLocation(false);
          this.setAttributeDisplayNameMap(this.cardDetails?.childView?.attributeList);
          this.setFilterToLayout();
          this.setConfigurations();
          this.setSavedFilterData();
          // this.horizontalLayout = this.filterDesign?.layout?.horizontalLayout;
          this.combination = this.filterDesign?.sort?.combination;
          this.individual = this.filterDesign?.sort?.individual?.attributeDetails;
          // this.verticalLayout = this.filterDesign?.layout?.verticalLayout;
          this.mappingFields.search =
            this.filterDesign?.search?.attributeDetails?.length > 0
              ? this.filterDesign?.search?.attributeDetails
              : this.mappingFields?.search;
          this.cartView = this.cardDetails?.cartView;
          if (this.cartView && Object.keys(this.cartView).length != 0)
            this.CART_BUTTON = this.cardDetails.cartView?.style?.cartButton;
          if (this.CART_BUTTON) {
            if (typeof this.CART_BUTTON.isSelected == 'boolean') {
              this.CART_BUTTON.isSelected = {
                child: {
                  isSelected: true,
                  position: this.CART_BUTTON?.position,
                },
                expand: {
                  isSelected: false,
                  position: -1,
                },
              };
            }
            this.cartEnabled = true;
          }
          this.mappingFields?.attributeTypes?.forEach(
            (attr: any, index: number) => {
              this.attributeUIType.set(attr.name, attr.uiElement);
              this.attributeIndexMap.set(attr.name, index);
              this.attributeUIElementMap.set(attr.name, attr.uiDataType);
              this.attributeProperties.set(attr.name, attr.properties);
            }
          );
          this.mappingFields?.nestedRowUiTypes?.forEach(
            (attr: any, index: number) => {
              this.nestedRowAttributeUIType.set(attr.name, attr.uiElement);
              this.nestedRowAttributeIndexMap.set(attr.name, index);
              this.nestedRowAttributeUIElementMap.set(attr.name, attr.uiDataType);
              this.attributeProperties.set(attr.name, attr.properties);
            }
          );
          // this.multipleMappingFields.push
          this.isDynamicTemplate = this.cardDetails.templateType.includes('dynamic');
          if (this.isDynamicTemplate) {
            this.entityBoardEndpointService
              .getTemplate(this.cardDetails.templateType.split('-')[1])
              .subscribe((res) => {
                /* istanbul ignore next */
                //  let style = res.result.template.split('<style>').pop().split('</style>')[0];
                this.dynamicStyles = res.result.template.split('<style>').pop().split('</style>')[0];
                document.head.insertAdjacentHTML('beforeend', `<style>${this.dynamicStyles}</style>`);

                this.dynamicCard = res.result.template.split('<style>')[0];
              });
          }
          this._applyColor = this.mappingFields?.color;
          this.cardBorderSize = this.mappingFields?.cardBorderSize;
          this.cardBorderRadius = this.mappingFields?.cardBorderRadius;
          this.cardBorderColor = this.mappingFields?.cardBorderColor;
          this.cardShadow = this.mappingFields?.cardBoxShadow;
          this.exportConfigurations = this.filterDesign?.export;
          this.cardGsiMap = this.mappingFields?.cardGsiMap;
          this.gsiAttrMap = this.mappingFields?.gsiAttrMap;
          this.gsiAttrMapExpand = this.mappingFields?.gsiAttrMapExpand;
          this.hyperlinkAttribute = this.mappingFields?.hyperlinkAttribute;
          this.expandHyperlinkAttributes = this.mappingFields?.expandHyperlinkAttributes;
          this.hyperlinkCtaDropArray = this.mappingFields?.hyperlinkCtaDropArray;
          this.cardColor = this.mappingFields?.cardBackground;
          this.btnColors = this.mappingFields?.btnColors
            ? this.mappingFields?.btnColors
            : [];
          this.numberOfRows = this.mappingFields?.numberOfRows;
          this.conditionArray = this.mappingFields?.conditionArray;
          this.otherStyles = this.mappingFields?.styles;
          this.expandColors = this.mappingFields?.dropExpandColors;
          this.expandBackground = this.mappingFields?.expandBackground;
          this.expandBoxShadow = this.mappingFields?.expandBoxShadow;
          this.conditionArrayExpand = this.mappingFields?.conditionArrayExpand;
          this.locationFilter =
            this.filterDesign?.locationFilter?.attributeDetails?.length > 0
              ? this.filterDesign?.locationFilter?.attributeDetails
              : this.mappingFields?.locationFilter;
          this.entitySearchFields =
            this.filterDesign?.searchFields?.attributeDetails?.length > 0
              ? this.filterDesign?.searchFields?.attributeDetails
              : this.mappingFields?.searchFieldAttr;

          this.entitySearchFields?.forEach((field) => (field.value = ''));
          this.setShowEntitySearchFields();
          this.entitySearchFieldsEmitter.emit(this.entitySearchFields);
          this.prepareDynamicSearchFieldElgibleAttribute();
          this.groupFilterDataBy = this.filterDesign
            ? {}
            : this.mappingFields?.groupFilterDataBy;
          this.expandBtnColors = this.mappingFields?.expandBtnColors;
          this.oddRowColor = this.mappingFields?.oddRowColor;
          this.evenRowColor = this.mappingFields?.evenRowColor;
          this.widthArray = this.mappingFields?.widthArray;
          this.headerBackground = this.mappingFields?.headerBackground;
          this.nestedRowHeaderBackground = this.mappingFields?.nestedRowHeaderBackground;
          this.headerFreeze = this.mappingFields?.headerFreeze;
          this.pagination = this.mappingFields?.pagination;
          this.gsiConfigurations = this.mappingFields?.gsiConfigurations;
          this.header = this.mappingFields?.header;
          this.nestedRowHeader = this.mappingFields?.nestedRowHeader;
          this.evenRow = this.mappingFields?.evenRow;
          this.oddRow = this.mappingFields?.oddRow;
          this.description = this.mappingFields?.description;
          this.gridLines = this.mappingFields?.gridLines;
          this.advanceTableFeatures = this.mappingFields?.advanceTableFeatures;
          this.isNestedRow1 = this.mappingFields?.isNestedRow1;
          this.isExpandPopup = this.mappingFields?.expandPopup;
          this.legendSelected = this.mappingFields?.legendSelected;
          this.legendAlignment = this.mappingFields?.legendAlignment;
          this.enableCompleteRecord = this.mappingFields?.enableCompleteRecord;
          this.stylesExpand = this.mappingFields?.stylesExpand;
          this.cardHoveredColor = this.mappingFields?.cardHoveredColor;
          this.attrHoveredColors = this.mappingFields?.attrHoveredColors;
          this.expandCardHoveredColor = this.mappingFields?.expandCardHoveredColor;
          this.expandHoveredColors = this.mappingFields?.expandHoveredColors;
          this.expandBtnHoverColors = this.mappingFields?.expandBtnHoverColors;
          this.btnHoverColors = this.mappingFields?.btnHoverColors;
          this.headerGsi = this.mappingFields?.headerGsi;
          this.isCardMultiExpand = this.mappingFields?.isMultiAttributeExpan
            ? this.mappingFields?.isMultiAttributeExpan
            : false;
          if (
            this.cardDetails?.childView?.style?.gsiact?.length > 0 &&
            this.cardDetails?.childView?.templateType == 'table'
          ) {
            this.gsiTableRequestArray = [];
            this.pageGsi = [];
            this.tableActionButtons = JSON.parse(
              JSON.stringify(this.cardDetails.childView.style.gsiact)
            );
            this.tableActionButtons.forEach((action: any) => {
              if (action && 'masterId' in action && action.masterId != 0) {
                this.gsiTableRequestArray.push(
                  this.gsiFacadeService.getGsiIdFromMasterId(action?.masterId)
                );
              } else {
                this.pageGsi.push(action);
              }
            });
            this.updateGsiVersion(this.gsiTableRequestArray, 'table');
          } else if (this.cardDetails?.childView?.gsiList) {
            this.tableActionButtons = JSON.parse(
              JSON.stringify(this.cardDetails.childView.gsiList)
            );
          }

          /* istanbul ignore next */
          if (this.mappingFields?.expandTemplateTypeData) {
            if (this.cardDetails?.expandedView?.style?.templateType) {
              this.expandType = parseInt(
                this.cardDetails?.expandedView?.style?.templateType
              );
            } else {
              this.expandType =
                templateExpandMap[parseInt(this.cardDetails?.templateType)];
            }
          }
          if (this.mappingFields?.range && !this.filterDesign) {
            this.range = [];
            this.mappingFields.range.forEach((key: any) => {
              const obj = {
                fieldName: key.name + '.' + key.attributeName,
              };
              this.range.push(obj);
            });
          }
          this.prepareGSIMapping();
          this.filterExpandedView();
          /* istanbul ignore next */
          if (this.chatbot) {
            /* istanbul ignore next */
            let chatbotCardDataRes = {
              message: 'Transaction results have been fetched successfully.',
              result: {
                total: this.chatbotCardData?.length,
                content: [
                  {
                    data: this.chatbotCardData,
                  },
                ],
                pageable: {
                  sort: {
                    orders: new Array(),
                  },
                  page: 0,
                  size: this.chatbotCardData?.length,
                },
              },
              status: 200,
            };
            this.prepareCardData(chatbotCardDataRes);
          } else {
            this.getEntityData(0);
          }
        }
      });
  }

  prepareGSIMapping() {
    this.gsiRequestArray = [];
    this.pageGsi = [];
    if (this.cartEnabled) {
      this.gsiMapping[
        this.CART_BUTTON.isSelected['child'].position
      ] = this.CART_BUTTON;
      this.cardDetails?.childView?.gsiList.forEach(
        (gsi: any, index: number) => {
          /* istanbul ignore else */
          if (
            gsi?.id == -1 &&
            gsi?.masterId == -1 &&
            gsi?.['entityType'] != 'CART'
          ) {
            gsi['entityType'] = 'CART';
          } else if (
            gsi?.id != -1 &&
            gsi?.masterId != -1 &&
            gsi?.['entityType'] != 'GSI'
          ) {
            gsi['entityType'] = 'GSI';
          }
          this.gsiMapping[index] = gsi;
          if (this.cardDetails?.childView?.style?.gsiact?.[index]?.popUp) {
            this.gsiMapping[index]['popUp'] = true;
          }
        }
      );
      this.cardDetails?.gsiList?.forEach((gsi: any, index: number) => {
        /* istanbul ignore else */
        if (
          gsi?.id == -1 &&
          gsi?.masterId == -1 &&
          gsi?.['entityType'] != 'CART'
        ) {
          gsi['entityType'] = 'CART';
        } else if (
          gsi?.id != -1 &&
          gsi?.masterId != -1 &&
          gsi?.['entityType'] != 'GSI'
        ) {
          gsi['entityType'] = 'GSI';
        }
        this.gsiMapping[index] = gsi;
        if (this.cardDetails?.childView?.style?.gsiact?.[index]?.popUp) {
          this.gsiMapping[index]['popUp'] = true;
        }
      });
      this.cardDetails?.childView?.gsiList?.forEach(
        (gsi: any, index: number) => {
          /* istanbul ignore else */
          if (
            this.cardDetails?.childView?.style?.gsiact?.length > 0 &&
            this.cardDetails?.childView?.templateType != 'table'
          ) {
            if (gsi?.masterId && gsi?.masterId != -1) {
              this.gsiRequestArray.push(
                this.gsiFacadeService.getGsiIdFromMasterId(gsi.masterId)
              );
            } else if (
              this.cardDetails?.childView?.style?.gsiactType?.[index]
                ?.entityType === 'PAGE'
            ) {
              gsi['entityType'] = 'PAGE';
              this.pageGsi.push(gsi);
            }
          }
        }
      );
      if (this.gsiRequestArray?.length > 0) {
        this.updateGsiVersion(this.gsiRequestArray, 'child');
      }
    } else {
      this.cardDetails?.childView?.gsiList?.forEach(
        (gsi: any, index: number) => {
          /* istanbul ignore else */
          if (
            this.cardDetails?.childView?.style?.gsiact?.length > 0 &&
            this.cardDetails?.childView?.templateType != 'table'
          ) {
            if (gsi?.masterId) {
              this.gsiRequestArray.push(
                this.gsiFacadeService.getGsiIdFromMasterId(gsi.masterId)
              );
            } else if (
              this.cardDetails?.childView?.style?.gsiactType?.[index]
                ?.entityType === 'PAGE'
            ) {
              gsi['entityType'] = 'PAGE';
              this.pageGsi.push(gsi);
            }
          }
        }
      );
      this.cardDetails?.gsiList?.forEach((gsi: any, index: number) => {
        /* istanbul ignore else */
        if (
          this.cardDetails?.childView?.style?.gsiactType?.[index]
            ?.entityType === 'PAGE'
        ) {
          gsi['entityType'] = 'PAGE';
        } else {
          gsi['entityType'] = 'GSI';
        }
        this.gsiMapping[index] = gsi;
      });
      if (this.gsiRequestArray?.length > 0) {
        this.updateGsiVersion(this.gsiRequestArray, 'child');
      }
    }
  }
  updateGsiVersionExpand() {
    this.gsiExpandRequesArray = [];
    Object.keys(this.gsiExpanddata).forEach((key: any) => {
      if (this.gsiExpanddata[key]?.masterId != 0) {
        this.gsiExpandRequesArray.push(
          this.gsiFacadeService.getGsiIdFromMasterId(
            this.gsiExpanddata[key]?.masterId
          )
        );
      }
    });
    this.updateGsiVersion(this.gsiExpandRequesArray, 'expand');
  }
  updateGsiVersion(requestArray: any, label: any) {
    forkJoin(requestArray)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res.length > 0) {
          if (label == 'child') {
            this.setChildMap(res);
          } else if (label == 'expand') {
            this.setGsiMap(res);
          } else if (label == 'table') {
            this.setTableMap(res);
          }
        }
      });
  }
  setTableMap(res: any) {
    let tempArray: any = [];
    res.forEach((gsi: any, index: number) => {
      if (this.tableActionButtons[index]?.popUp) {
        gsi.result['popUp'] = true;
      }
      if (this.tableActionButtons[index]?.isUnrelated)
        gsi.result['isUnrelated'] = true;
      tempArray.push(gsi?.result);
    });
    if (this.pageGsi.length > 0) {
      this.tableActionButtons = [...tempArray, ...this.pageGsi];
    } else {
      this.tableActionButtons = tempArray;
    }
  }
  setChildMap(res: any) {
    let tempArray: any = [];
    res.forEach((gsi: any, index: number) => {
      if (gsi?.result?.['entityType'] != 'GSI') {
        gsi.result['entityType'] = 'GSI';
      }
      tempArray.push(gsi?.result);
    });
    this.cardDetails?.childView?.style?.gsiact.forEach(
      (gsi: any, i: number) => {
        if (gsi?.masterId != -1 && typeof gsi != 'string') {
          this.gsiMapping[i] = tempArray.find(
            (tempgsi: any) => tempgsi.name == gsi?.name || tempgsi.masterId == gsi?.masterId 
          );
          if (gsi?.popUp) {
            this.gsiMapping[i]['popUp'] = true;
          }
        } else if (typeof gsi == 'string'){
          this.gsiMapping[i] = tempArray.find(
            (tempgsi: any) => tempgsi.name == gsi 
          );
        }
        else if (
          this.cardDetails?.childView?.style?.gsiactType?.[i]?.entityType ===
          'CART'
        ) {
          this.gsiMapping[i] = gsi;
        }
  
         else if (
          this.cardDetails?.childView?.style?.gsiactType?.[i]?.entityType ===
          'PAGE'
        ) {
          this.gsiMapping[i] = this.pageGsi.find(
            (tempgsi: any) => tempgsi.name == gsi?.name
          );
        }
      }
    );
  }
  setGsiMap(res: any) {
    Object.keys(this.gsiExpanddata || {}).forEach((key: any, index: number) => {
      if (res[index]?.result?.['entityType'] != 'GSI') {
        res[index].result['entityType'] = 'GSI';
      }
      const popUp = this.gsiExpanddata?.[key]?.enablePopup;
      const isUnrelated = this.gsiExpanddata?.[key]?.isUnrelated ? this.gsiExpanddata?.[key]?.isUnrelated : false;
      this.gsiExpanddata[key] = { ...res[index].result, popUp, isUnrelated };
    });
  }
  getEntityData(pageNumber?: any, entityRecordId?: any) {
    const now = new Date();
    const utcDate = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    ).getTime();
    this.timeStampVariable = utcDate;
    /* istanbul ignore next*/
    const req: any = {
      groupBy: [],
      searchQuery: {
        searchQueryDepth: 0,
        operationType: 'AND',
        queries: [],
      },
      ebAnalyticsUserDetails: {
        gender: localStorage.getItem('gender'),
        age: localStorage.getItem('age'),
        userLocation: JSON.stringify({
          latitude: this.currentlat,
          longitude: this.currentlng,
        }),
        ipAddress: '',
        deviceInfo: navigator.platform + ' ' + this.browserName,
      },
      functions: this.range,
      entityCardID: this.cardDetails?.id,
      action: this.actionButton,
      sessionId:
        this.timeStampVariable +
        '_' +
        this.cardDetails?.id +
        '_' +
        this.activeUserInfo?.id,
    };
    if (this.sharableFilterSearchQuery?.queries?.length > 0) {
      req.searchQuery.queries = [
        ...req?.searchQuery?.queries,
        this.sharableFilterSearchQuery,
      ];
    }
    this.addValueToAlreadyAddedSearchQuery(req);
    /* istanbul ignore next */
    this.entitySearchFields?.forEach((attr: any) => {
      if (
        attr?.selectedNestedForDate &&
        (attr?.uiElement == 'date' || attr?.uiElement == 'datetime')
      ) {
        this.addToGroupByNestedFunction(
          req,
          attr,
          attr?.selectedNestedForDate?.selectNestedAttribute,
          attr?.selectedNestedForDate?.selectNestedAttributeFunction
        );
      }
    });
    /* istanbul ignore else */
    if (
      (this.horizontalLayout && this.horizontalLayout?.length > 0) ||
      (this.verticalLayout && this.verticalLayout?.length > 0)
    ) {
      req.functions = [];
      this.horizontalLayout?.forEach((attr: any) => {
        this.addToGroupBy(req, attr);
      });
      this.verticalLayout?.forEach((attr: any) => {
        this.addToGroupBy(req, attr);
      });
    }
    /* istanbul ignore else */
    if (this.filterConfigurations?.position == 'header') {
      this.addGroupByData(req,this.cardDetails?.childView?.attributeList);
    }
    if (!this.filterDesign) {
      this.cardDetails?.filterBy?.forEach((attr: any) => {
        req.groupBy.push({
          fieldName:
            (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            attr.attributeName,
        });
      });
    }

    /* istanbul ignore next */
    if (this.sortOption && this.sortOptionAttr) {
      req.sort = [
        {
          fieldName:
            this.cardDetails?.primaryEntity?.name + '.' + this.sortOptionAttr,
          order: this.sortOption == 1 ? 'ASC' : 'DESC',
        },
      ];
    }

    /* istanbul ignore next */
    if (this.filterValues) {
      Object.keys(this.filterValues)?.forEach((key: any, value: any) => {
        if (this.filterValues[key].length > 0) {
          req.searchQuery.queries.push({
            searchQueryDepth: 1,
            fieldName: this.cardDetails?.primaryEntity?.name + '.' + key,
            searchOperatorType: 'IN',
            values: this.filterValues[key],
          });
        }
      });
    }
    /* istanbul ignore next */
    req['entitiesList'] = [this.cardDetails?.primaryEntity?.name];
    /* istanbul ignore next */
    req['entityIdsList'] = [this.cardDetails?.primaryEntity?.id];

    /* istanbul ignore next */
    if (this.actionButton == 'EXECUTE') {
      req['recordIdList'] = [];
      req['recordIdList'].push(entityRecordId);
    }
    if (this.currentlat && this.currentlng) {
      this.locationFilter?.forEach((attr: any) => {
        req.searchQuery.queries.push({
          searchQueryDepth: 1,
          fieldName:
            (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            attr.attributeName,
          searchOperatorType: 'GEO_DISTANCE',
          values: [
            this.currentlat.toString(),
            this.currentlng.toString(),
            attr.radius + 'km',
          ],
        });
      });
    }
    req.searchQueryValue = this.attributeValue;
    const pageNum = pageNumber ? pageNumber : 0;
    let pageSize = this.pagination?.numberOfRows ? this.pagination?.numberOfRows : this.pagination?.rows;
    if (this.cardDetails?.templateType == '331' || this.kanbanConfiguration?.statusAttribute?.length > 0) {
      pageSize = 10000;
    }
    pageSize = pageSize ? pageSize : this.hideOptions ? 8 : 15;
    this.pageSize = pageSize;
    if (this.EntityTemplateType == 'entityChildExpandTemplate') {
      this.pageSize = 500;
      pageSize = 500;
    }
    this.isSearch = false;
    this.detectEntityData(req, pageNum, pageSize);
  }

  applyDateFilter(event: Date[], searchField: any) {
    searchField.value = event;
    this.dateSearchFieldValue[searchField.attributeName] = event[0];
    this.applyFilterAndSort(true, true);
  }

  searchAllFields() {
    this.applyFilterAndSort(true, true);
  }
  addGroupByData(req: any, array: any): void {
    array?.forEach((attr: any) => {
      req.groupBy.push({
        fieldName:
          (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) + '.' + attr?.attributeName,
      });
    });
  }

  /* The above code is defining a function called `applyFilterAndSort` with multiple optional parameters.
The function takes in various boolean and string parameters, as well as an object parameter
`isHeaderSearch` with properties `checked`, `searchAttribute`, and `searchValue`. The function does
not have a return type specified.

*/
  applyFilterAndSort(
    isSearch?: boolean,
    isSearchFields?: boolean,
    isSendPayload?: boolean,
    searchOrFilter?: string,
    idForCard?: any,
    page?: any,
    isHeaderSearch?: {
      checked: boolean;
      searchAttribute: string;
      searchValue: string;
    }
  ) {
    const now = new Date();
    /* istanbul ignore next*/
    const req: any = {
      groupBy: [],
      searchQuery: {
        searchQueryDepth: 0,
        operationType: 'AND',
        queries: [],
      },
      functions: this.range,
      entityCardID: this.cardDetails?.id,
      action: this.actionButton,
      sessionId:
        this.timeStampVariable +
        '_' +
        this.cardDetails?.id +
        '_' +
        this.activeUserInfo?.id,
    };
    /* istanbul ignore next*/
    this.entitySearchFields?.forEach((attr: any) => {
      if (
        attr?.selectedNestedForDate &&
        (attr?.uiElement == 'date' || attr?.uiElement == 'datetime')
      ) {
        this.addToGroupByNestedFunction(
          req,
          attr,
          attr?.selectedNestedForDate?.selectNestedAttribute,
          attr?.selectedNestedForDate?.selectNestedAttributeFunction
        );
      }
    });
    if (
      (this.horizontalLayout && this.horizontalLayout?.length > 0) ||
      (this.verticalLayout && this.verticalLayout?.length > 0)
    ) {
      req.functions = [];
      this.horizontalLayout?.forEach((attr: any) => {
        this.addToGroupBy(req, attr);
      });
      this.verticalLayout?.forEach((attr: any) => {
        this.addToGroupBy(req, attr);
      });
    }
    /* istanbul ignore else*/
    if (!this.filterDesign) {
      this.cardDetails?.filterBy?.forEach((attr: any) => {
        req.groupBy.push({
          fieldName:
            (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            attr.attributeName,
        });
      });
    }
    /* istanbul ignore else*/
    if (this.filterConfigurations?.position == 'header') {
      this.addGroupByData(req, this.cardDetails?.childView?.attributeList);
    }
    /* istanbul ignore next */
    if (this.sortOption && this.sortOptionAttr) {
      req.sort = [
        {
          fieldName:
            (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            this.sortOptionAttr,
          order: this.sortOption == 1 ? 'ASC' : 'DESC',
        },
      ];
    }

    /* istanbul ignore next */
    if (this.filterValues) {
      Object.keys(this.filterValues)?.forEach((key: any, value: any) => {
        if (this.filterValues[key].length > 0) {
          req.searchQuery.queries.push({
            searchQueryDepth: 1,
            fieldName:
              (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) + '.' + key,
            searchOperatorType: 'IN',
            values: this.filterValues[key],
          });
        }
      });
    }

    if (this.rangeData.length > 0) {
      this.rangeData?.forEach((key: any) => {
        const diff = key.maximumValue - key.minimumValue;
        const minData =
          parseInt(key.minimumValue) + (diff * key?.rangeArray[0]) / 100;
        const maxData =
          parseInt(key.minimumValue) + (diff * key?.rangeArray[1]) / 100;
        if (!(key?.rangeArray?.[0] == 0 && key?.rangeArray?.[1] == 100)) {
          req.searchQuery.queries.push({
            searchQueryDepth: 1,
            fieldName:
              this.cardDetails?.childView?.attributeList?.[0]?.name +
              '.' +
              key?.attributeName,
            searchOperatorType: 'RANGE',
            values: [minData, maxData],
          });
        }
      });
    }

    /* istanbul ignore next */
    req['entitiesList'] = [this.cardDetails?.primaryEntity?.name];
    /* istanbul ignore next */
    req['entityIdsList'] = [this.cardDetails?.primaryEntity?.id];
    // } else {
    //   delete req.entitiesList;
    // }

    if (
      (this.horizontalLayout && this.horizontalLayout?.length > 0) ||
      (this.verticalLayout && this.verticalLayout?.length > 0)
    ) {
      this.horizontalLayout?.forEach((key: attributeLayoutDetails) => {
        this.addToSearchQuery(req, key);
      });
      this.verticalLayout?.forEach((key: attributeLayoutDetails) => {
        this.addToSearchQuery(req, key);
      });
    }
    const pageNum = page ? page : 0;
    const pageSize = this.pageSize ? this.pageSize : 15;
    if (!this.pageSize) this.pageSize = 15;
    this.isSearch = true;
    req['attributesList'] = [];
    if (isSearch) {
      this.mappingFields?.search?.forEach((attr: any) => {
        req.attributesList.push(
          (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            attr.attributeName
        );
      });
      req.searchQueryValue = this.attributeValue;
    }

    if (isHeaderSearch?.checked) {
      req.attributesList.push(
        (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
          '.' +
          isHeaderSearch?.searchAttribute
      );
      req.searchQueryValue = isHeaderSearch?.searchValue;
    }
    /*istanbul ignore next*/

    this.applySearchFields(req, isSearchFields);
    if (this.currentlat && this.currentlng) {
      this.locationFilter?.forEach((attr: any) => {
        req.searchQuery.queries.push({
          searchQueryDepth: 1,
          fieldName:
            (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            attr.attributeName,
          searchOperatorType: 'GEO_DISTANCE',
          values: [
            this.currentlat.toString(),
            this.currentlng.toString(),
            attr.radius + 'km',
          ],
        });
      });
    }

    this.addValueToAlreadyAddedSearchQuery(req);
    /* istanbul ignore else*/
    if (!isSendPayload) {
      // ! Remove Comment this in Dev.
      // this.alertService.showToaster('Given Filters are applied', '', 'success');
      /*istanbul ignore next */
      if (this.savedSearchQuery?.queries?.length > 0) {
        req.searchQuery.queries = [
          ...req?.searchQuery?.queries,
          ...this.savedSearchQuery?.queries,
        ];
      }
      if (this.sharableFilterSearchQuery?.queries?.length > 0) {
        req.searchQuery.queries = [
          ...req?.searchQuery?.queries,
          this.sharableFilterSearchQuery,
        ];
      }
      this.detectEntityData(req, pageNum, pageSize, false);
    } else {
      if (this.savedSearchQuery?.queries?.length > 0) {
        req.searchQuery.queries = [
          ...req?.searchQuery?.queries,
          ...this.savedSearchQuery?.queries,
        ];
      }
      this.sendFilterSearchData(req, searchOrFilter);
    }
  }

  applySearchFields(req: any, isSearchFields: boolean) {
    if (isSearchFields) {
      this.entitySearchFields?.forEach((attr: any) => {
        this.makeSearchFieldPayload(attr, req);
      });
      this.dynamicEntitySearchFields.forEach((attr: any) => {
        this.makeSearchFieldPayload(attr, req);
      });
      this.searchConfigurationData.forEach((attr: any) => {
        this.makeSearchFieldPayload(attr, req);
      });
    } else {
      this.entitySearchFields?.forEach((attr: any) => {
        attr.value = '';
      });
      this.dynamicEntitySearchFields?.forEach((attr: any) => {
        attr.value = '';
      });
    }
    this.setShowEntitySearchFields();
  }

  setAttrValue(value: any) {
    const val = JSON.parse(JSON.stringify(value));
    if (typeof val === 'string') {
      return [val];
    } else {
      return val;
    }
  }
  makeSearchFieldPayload(attr: any, req: any) {
    /* istanbul ignore else*/
    if (
      attr?.value &&
      attr?.value !== '' &&
      attr?.value?.length > 0 &&
      !this.xorOperator(
        attr?.uiElement != 'date' &&
          attr?.uiElement != 'datetime' &&
          attr?.uiElement != 'dateonly',
        attr?.uielement != 'date' &&
          attr?.uielement != 'datetime' &&
          attr?.uielement != 'dateonly'
      )
    ) {
      req.searchQuery.queries.push({
        searchQueryDepth: 1,
        fieldName:
          (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
          '.' +
          attr.attributeName,
        searchOperatorType: 'IN',
        values: this.setAttrValue(attr.value),
      });
    } else if (
      /* istanbul ignore else if */
      attr?.value &&
      attr?.value !== '' &&
      attr?.value?.length > 0 &&
      (attr?.uielement == 'date' ||
        attr?.uielement == 'datetime' ||
        attr?.uiElement == 'date' ||
        attr?.uiElement == 'datetime')
    ) {
      attr?.value.forEach((attr1)=>{
        const startDate = typeof attr1 == 'string' ?attr1: this.convertToDateFormat(attr1,'START');
        const endDate =typeof attr1 == 'string' ?attr1: this.convertToDateFormat(attr1,'END');
        req.searchQuery.queries.push({
          searchQueryDepth: 1,
          fieldName:
            (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
            '.' +
            attr.attributeName,
          searchOperatorType: 'RANGE',
          values: [startDate, endDate],
        });
      })
    
    } else if (
      attr?.value &&
      attr?.value !== '' &&
      attr?.value?.length > 0 &&
      (attr?.uielement == 'dateonly' || attr?.uiElement == 'dateonly')
    ) {
      const date = displayDateDefaultValue(
        attr?.value?.[0],
        'dateonly',
        'yyyy-MM-dd',
        '12-hr',
        'yyyy-MM'
      );
      req.searchQuery.queries.push({
        searchQueryDepth: 1,
        fieldName:
          (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name) +
          '.' +
          attr.attributeName,
        searchOperatorType: 'RANGE',
        values: [date, date],
      });
    }
  }

  xorOperator(a: boolean, b: boolean): boolean {
    return (a || b) && !(a && b);
  }

  searchRecord() {
    /* istanbul ignore next */
    this.applyFilterAndSort(true);
  }

  noSpancing() {
    this.searchRecord();
  }
  setAttributeDisplayNameMap(record: any) {
    record?.forEach((rec: any) => {
      if (rec?.attributeDisplayName) {
        this.displayNameMap.set(rec?.attributeName, rec?.attributeDisplayName);
      }
    });
  }

  setDisplayNameMap(record: any) {
    record.forEach((rec: any) => {
      if (rec?.displayName && rec?.name) {
        this.displayNameMap.set(rec.name, rec.displayName);
      } else if (rec?.name && !rec?.displayName) {
        this.displayNameMap.set(rec.name, rec.name);
      }
    });
  }
  detectEntityData(
    req: any,
    pageNum: number,
    pageSize: number,
    fromApplyFilterAndSort: boolean = false
  ) {
    // if (pageNum === 0) {
    //   this.length = 0;
    // }
    this.loader?.show();
    this.quantity = [];
    this.paginatorNumber = pageNum;
    let version = 'v1';
    if (this.apiVersion == '2') {
      version = 'v2';
    }
    iterateSearchQueryToAppendCurrentData(req?.searchQuery);
    this.gsiFacadeService
      .getEntityDataDetails(req, pageNum, pageSize, version)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        this.loader?.hide();
        this.entityDataRaw = res;
        this.cardsData = [];
        const temp: any = [];
        /* istanbul ignore next */
        if (res && res.result) {
            this.totalLength = res.result.total;
            /* istanbul ignore next */
            if (version == 'v2') {
              res = facetSearchMapper(res.result);
            }
            if (res.result.total) {
              this.length = res.result.total;
            }
            this.transactionData = res?.result?.content[0]?.data;
            this.tableViewData(res?.result?.content[0]?.data, false);
            let cardData = JSON.parse(this.cardDetails.templateMappingFields);
            this.aggregateData = res?.result?.content?.[0]?.aggregates;
            if (this.aggregateData) {
              this.prepareSearchFieldConfigForTable(this.aggregateData);
            }
            let expandSet: Set<string> = new Set(
              Object.values(
                cardData?.expandTemplateTypeData
                  ? cardData?.expandTemplateTypeData
                  : {}
              )
            );
            this.isCardMultiExpand = cardData.isMultiAttributeExpan;
            this.gsiExpanddata = cardData.gsiExpandData;
            if (this.gsiExpanddata) {
              this.updateGsiVersionExpand();
            }
            this.longestRecordPos = 0;
            let longestRecordLength = 0;
            this.dynamicExpandRecords = [];
            this.nestedRowTableData = [];
            /* istanbul ignore next */
            res?.result?.content[0]?.data?.forEach((txn: any, i: number) => {
              if (this.cartEnabled) {
                this.quantity[i] = txn?.itemCountInCart;
              }
              txn?.transEntityRecords?.forEach((obj: any, inx: number) => {
                this.dynamicExpandRecords.push(obj.txnNslAttribute);
                let record: any = {
                  recordData: [],
                  recordId: txn.id,
                  entityName: txn?.displayName ? txn.displayName : txn.name,
                  isMore: false,
                };
                this.setDisplayNameMap(obj?.txnNslAttribute);
                if (this.cardDetails?.childView?.style?.columnStyles) {
                  this.columnStyles = this.cardDetails?.childView?.style?.columnStyles;
                  let attr: any[] = [];
                  let l = this.mappingFields.attr.length;
                  //TODO: optimize the below code
                  if (this.columnStyles?.[0]?.attributes) {
                    // this.columnStyles.forEach((x: ColumnStyle) => {
                    //   const len = x.attributeList.length;

                    //   x.sizes = x.sizes.splice(0, len);
                    //   if (x.attributes == x.displayName) {
                    //     const value = obj?.txnNslAttribute.find((txnattr: any) => txnattr.name == x.displayName);
                    //     if (value) {
                    //       x.displayName = value?.displayName ? value?.displayName : value?.name;
                    //     }
                    //   }
                    // });
                    attr = this.columnStyles.map((x) => x.attributes);
                    //TODO:impliment at design time properly in new implimentation

                    attr = attr.splice(0, l);
                  } else {
                    attr = this.mappingFields.attr;
                  }

                  attr.forEach((attribute: string | false, ind: number) => {
                    if (typeof attribute == 'string' && attribute) {
                      let attributeArray = attribute.split('.');
                      let tempRecord: any = {
                        displayName: attribute,
                        attributeName: attribute,
                        value: [],
                        type: [],
                      };
                      attributeArray.forEach((attr: string) => {
                        const value = obj?.txnNslAttribute.find((txnattr: any) => txnattr.name == attr);
                        tempRecord.displayName = value?.displayName ? value?.displayName : value?.name;
                        tempRecord.attributeName = value?.name;
                        /* istanbul ignore else */
                        const type = this.attributeUIType.get(value?.name);
                        const uiElementType = this.attributeUIElementMap.get(value?.name);
                        const timeFormat = this.attributeProperties.get(value?.name)?.timeFormat;
                        if (uiElementType == 'image') {
                          tempRecord.value.push(appendValue(value, 'image', timeFormat));
                          tempRecord.type.push('image');
                        } else {
                          tempRecord.value.push(appendValue(value, type, timeFormat,this.mappingFields.customNoValue));
                          tempRecord.type.push(type);
                        }
                      });
                      record.recordData.push(tempRecord);
                    }
                  });
                } else {
                  this.mappingFields?.attr?.forEach((attribute: string | boolean, ind: number) => {
                    const value = obj?.txnNslAttribute.find((txnattr: any) => txnattr.name == attribute);

                    /* istanbul ignore else */
                    const type = this.attributeUIType.get(value?.name);
                    const uiElementType = this.attributeUIElementMap.get(value?.name);
                    const timeFormat = this.attributeProperties.get(value?.name)?.timeFormat;
                    if (value) {
                      let url: any = '';
                      if (this.cardDetails?.cardName == 'child' && this.cardDetails?.templateType == '1' && ind == 4) {
                        url = JSON.parse(value.values[0]).contentUrl;
                      } else if (
                        this.cardDetails?.cardName == 'child' &&
                        this.cardDetails?.templateType == '2' &&
                        ind == 0
                      ) {
                        url = JSON.parse(value.values[0]).contentUrl;
                      } else if (
                        this.cardDetails?.cardName == 'expand' &&
                        this.cardDetails?.templateType == '1' &&
                        ind == 0
                      ) {
                        url = JSON.parse(value.values[0]).contentUrl;
                      }
                      /* istanbul ignore else */
                      //Inside the try block attempt to parse value.values[0] as JSON data.
                      //If there are issues with the JSON format or the contentUrl property is missing, you can handle

                      if (type == 'image' && value.values.length > 0) {
                        // url = JSON.parse(value.values[0]).contentUrl;
                        try {
                          const parsedData = JSON.parse(value.values[0]);
                          if (parsedData && parsedData.contentUrl) {
                            url = parsedData.contentUrl;
                          }
                        } catch (error) {
                          url = value.values[0];
                        }
                      }
                      this.showCards = true;
                      record.recordData.push({
                        displayName: value?.displayName ? value?.displayName : value?.name,
                        attributeName: value.name,
                        value: appendValue(value, type, timeFormat),
                        id: value.nslAttributeID,
                        contentUrl: url,
                        type: type,
                      });
                    } else if (obj?.txnNslAttribute.length === 0) {
                      this.showCards = false;
                    } else if (attribute) {
                      this.showCards = true;
                      if (typeof attribute == 'string' && attribute.trim() != this.gsiConfigurations?.gsiInlineTitle) {
                        record.recordData.push({
                          attributeName: value?.name,
                          displayName: value?.displayName ? value?.displayName : value?.name,
                          value: getNoValue(type,this.mappingFields?.customNoValue),
                          contentUrl: '',
                          type: type,
                        });
                      }
                    } else {
                      this.showCards = true;
                      record.recordData.push({
                        value: undefined,
                      });
                    }
                  });
                }
                if (record.recordId) temp.push(record);
                if (obj?.txnNslAttribute?.length > longestRecordLength) {
                  longestRecordLength = obj?.txnNslAttribute?.length;
                  this.longestRecordPos = temp.length - 1;
                }
                this.nestedRowData(txn, obj);
              });
              txn.transEntityRecords.forEach((attr: any) => {
                let val: any = {};
                
              
                attr.txnNslAttribute.forEach((at: any) => {
                  /* istanbul ignore else */
                  const type = this.attributeUIElementMap.get(at.name);
                  const timeFormat = this.attributeProperties.get(at?.name)?.timeFormat;
                  if (expandSet.has(at.name)) {
                    val[at.name] = appendValue(at, type, timeFormat);
                    this.expandDisplayDetails[at.name] = at.displayName ? at.displayName : at.name;
                  }
                });
                val.recordId = txn.id;
                val.entityName = txn?.displayName ? txn.displayName : txn.name;
                val.entitySendGsiName = txn.name;
                this.expandCardDetails.push(val);
                //
              });
            });
            this.cardsData = JSON.parse(JSON.stringify(temp));
          if(this.quantity?.length == 0 || !this.quantity) this.quantity = new Array(this.cardsData.length).fill(0);
            if (this.cardsData?.[this.longestRecordPos]?.recordData.length > 0 && this.headerFlag == 0) {
              this.setTableHeaderData();
              this.headerFlag += 1;
            } else if (temp.length === 0 && this.headerArray.length === 0) {
              this.setTableHeaderData();
            }

            if (this.cardsData?.[0]?.entityName == 'Resources') {
              this.cardsData.forEach((card: any) => {
                card.recordData.unshift({
                  attributeName: 'Email',
                  contentUrl: '',
                  value: '',
                });
              });
            }
            /* istanbul ignore next */
            if (this.isDynamicTemplate && this.cardsData.length >= 0 && !this.isMccLayout) {
              this.bindHTML(this.cardDetails.templateType);
            }
            /* istanbul ignore next */
            if (!this.isSearch) {
              if (this.cardDetails.filterBy.length > 0) {
                this.cardDetails.filterBy?.forEach((attr: any) => {
                  attr['filtervalues'] = this.filtersortValues(
                    attr.attributeName,
                    (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name),
                    res?.result?.content[0]
                  );
                });
                Object.keys(this.groupFilterDataBy).forEach((key) => {
                  this.groupFilterDataBy[key].forEach((attr: any) => {
                    attr['filtervalues'] = this.filtersortValues(
                      attr?.attributeName,
                      (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name),
                      res?.result?.content[0]
                    );
                  });
                });
              }
            }
            /* istanbul ignore next */
            if (this.sortOption) {
            } else {
              this.sortOption = this.mappingFields?.sortOption == 'Ascending' ? 1 : 2;
            }
            /* istanbul ignore next */
            this.isShowCardDetails = false;
            /* istanbul ignore next */

            /* istanbul ignore next */
            if (res?.result?.content[0]?.range && this.range && this.range.length > 0) {
              this.rangeData = [];
              Object.entries(res?.result?.content[0]?.range).forEach((obj: any) => {
                const str = obj[0].split('.');
                let attributeDisplayName;
                this.cardDetails.range.filter((attr: any) => {
                  if (attr.attributeName == str[1]) {
                    attributeDisplayName = attr.attributeDisplayName;
                  }
                });
                const range = {
                  attributeName: str[1],
                  ...obj[1],
                  rangeArray: [0, 100],
                  entityName: str[0],
                  attributeDisplayName: attributeDisplayName,
                };
                this.rangeData.push(range);
              }
            );
          }
          this.expandRecordId = this.cardsData?.[0]?.recordId;
          let aggregateData = res?.result?.content[0]?.aggregates;
          if (
            ((this.horizontalLayout && this.horizontalLayout?.length > 0) ||
              (this.verticalLayout && this.verticalLayout?.length > 0)) &&
            !fromApplyFilterAndSort
          ) {
            Object.entries(res?.result?.content[0]?.range).forEach(
              (obj: any) => {
                const str = obj[0].split('.');
                let attributeDisplayName;
                this.horizontalLayout?.forEach(
                  (attr: attributeLayoutDetails) => {
                    this.addDataFromRange(str, obj, attr);
                  }
                );
                this.verticalLayout?.forEach((attr: attributeLayoutDetails) => {
                  this.addDataFromRange(str, obj, attr);
                });
              }
            );
            this.horizontalLayout?.forEach((attr: attributeLayoutDetails) => {
              this.addDataFromAggregates(aggregateData, attr);
            });
            this.verticalLayout?.forEach((attr: attributeLayoutDetails) => {
              this.addDataFromAggregates(aggregateData, attr);
            });
          }

          this.entitySearchFields?.forEach((attr: any) => {
            if (
              attr?.selectedNestedForDate &&
              (attr?.uiElement == 'date' || attr?.uiElement == 'datetime')
            ) {
              this.addDataForNestedAggregates(aggregateData, attr);
            }
          });
          this.setswiperConfigurations();
        }

        if (!this.filterDesign) {
          this.filterDatatoFilter = {
            cardDetails: this.cardDetails,
            tempIsMCC: this.tempIsMCC,
            showfromMcc: this.showfromMcc,
            isMccLayout: this.isMccLayout,
            filterValues: this.filterValues,
            mappingFields: this.mappingFields,
            selectedAttribute: this.selectedAttribute,
            attributeValue: this.attributeValue,
            sortOptionAttr: this.sortOptionAttr,
            sortOption: this.sortOption,
            rangeData: this.rangeData,
            groupFilterDataBy: this.groupFilterDataBy,
          };
        }

        /* istanbul ignore next */
        if (this.locationFilter && this.locationFilter.length > 0) {
          this.mapsAPILoader?.load()?.then(() => {
            let autocomplete = new google.maps.places.Autocomplete(
              this.searchElementRef && this.searchElementRef?.nativeElement ? this.searchElementRef?.nativeElement : ''
            );
            autocomplete.addListener('place_changed', () => {
              this.ngZone.run(() => {
                //get the place result
                let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                //verify result
                if (place.geometry === undefined || place.geometry === null) {
                  return;
                }

                //set latitude, longitude and zoom
                this.currentlat = place.geometry.location.lat();
                this.currentlng = place.geometry.location.lng();
                this.applyFilterAndSort(true,true);
              });
            });
          });
        }
      },
      (error: any) => {
        this.loader?.hide();
        /* istanbul ignore next */
        this.alertService.showToaster(this.labels?.Entity_Data_is_not_fetched_successfully, '', 'error');
      }
    );
  }

  private setswiperConfigurations() {
    if (this.chatbot) {
      this.entityGrid_config.breakpoints['1920'].slidesPerView = 1;
      this.entityGrid_config.breakpoints['1200'].slidesPerView = 1;
      this.entityGrid_config.breakpoints['1000'].slidesPerView = 1;
    }
    if (this.cardDetails?.cardName == 'expand') {
      this.entityGrid_config.breakpoints['1200'].slidesPerView = 1;
    } else if (this.isCardMultiExpand) {
      this.entityGrid_config.breakpoints['1920'].slidesPerView = 1;
      this.entityGrid_config.breakpoints['1200'].slidesPerView = 1;
      this.entityGrid_config.breakpoints['1000'].slidesPerView = 1;
    } else {
      this.entityGrid_config.breakpoints['1920'].slidesPerView = 3;
      this.entityGrid_config.breakpoints['1200'].slidesPerView = 3;
      this.entityGrid_config.breakpoints['1000'].slidesPerView = 2;
    }
    if (
      this.chatbot ||
      this.EntityTemplateType == 'multiEntityRecordsBannerTemplate'
    ) {
      this.entityGrid_config.breakpoints['1920'].slidesPerView = 1;
      this.entityGrid_config.breakpoints['1200'].slidesPerView = 1;
      this.entityGrid_config.breakpoints['1000'].slidesPerView = 1;
    }
    if (this.cardDetails.templateType == 'dynamic-65f99bc18af37f20eeb81fc1') {
      this.entityGrid_config.breakpoints['1920'].slidesPerView = this.cardsData.length >= 6 ? 6 : this.cardsData.length;
      this.entityGrid_config.breakpoints['1200'].slidesPerView = this.cardsData.length >= 6 ? 6 : this.cardsData.length;
      this.entityGrid_config.breakpoints['1000'].slidesPerView = this.cardsData.length >= 5 ? 5 : this.cardsData.length;
    }
  }
  setTableHeaderData() {
    const headerArray: any[] = [];
    this.mappingFields?.attr?.forEach((attribute: any) => {
      if (attribute && attribute?.trim() != this.gsiConfigurations?.gsiInlineTitle) {
        const record = {
          displayName: this.displayNameMap?.has(attribute) ? this.displayNameMap?.get(attribute) : attribute,
          attributeName: attribute,
          type: this.attributeUIType.get(attribute),
        };
        headerArray.push(record);
      }
    });
    this.columnStyles.forEach((x: ColumnStyle) => {
      const len = x.attributeList.length;

      x.sizes = x.sizes.splice(0, len);
      if (x.attributes == x.displayName) {
          x.displayName = this.displayNameMap?.has(x.attributes) ? this.displayNameMap?.get(x.attributes) : x.attributes;
      }
    });
    this.headerArray = headerArray;
  }
  nestedRowData(txn: any, obj: any) {
    let nestedRowRecord: any = {
      recordData: [],
      recordId: txn?.id,
      entityName: txn?.displayName ? txn?.displayName : txn?.name,
      isMore: false,
    };
    const nestedRowTableData = this.setNestedRowData(obj, nestedRowRecord);
    this.nestedRowTableData.push(nestedRowTableData);
  }

  setNestedRowData(obj: any, record: any) {
    this.mappingFields?.nestedRowDropAttr?.forEach(
      (attribute: any, ind: number) => {
        const value = obj?.txnNslAttribute.find(
          (txnattr: any) => txnattr?.name == attribute
        );
        /* istanbul ignore else */
        const type = this.nestedRowAttributeUIType.get(value?.name);
        const timeFormat = this.attributeProperties.get(value?.name)?.timeFormat;
        /* istanbul ignore else */
        if (value) {
          let url: any = '';
          if (type == 'image' && value?.values?.length > 0) {
            // url = JSON.parse(value.values[0]).contentUrl;
            try {
              const parsedData = JSON.parse(value?.values[0]);
              /* istanbul ignore else */
              if (parsedData && parsedData.contentUrl) {
                url = parsedData.contentUrl;
              }
            } catch (error) {
              url = value?.values[0];
            }
          }
          record.recordData.push({
            displayName: value?.displayName ? value?.displayName : value?.name,
            attributeName: value?.name,
            value: appendValue(value, type, timeFormat),
            contentUrl: url,
            type: type,
          });
        } else if (attribute) {
          this.showCards = true;
          record.recordData.push({
            attributeName: value?.name,
            displayName: value?.displayName ? value?.displayName : value?.name,
            value:
              type == 'number' ||
              type == 'decimalnumber' ||
              type == 'mobilenumber'
                ? '0'
                : 'none',
            contentUrl: '',
            type: type,
          });
        }
      }
    );
    return record;
  }
  prepareCardData(res: any) {
    this.cardsData = [];
    const temp: any = [];
    /* istanbul ignore next */
    if (res && res?.result) {
      /* istanbul ignore next */
      if (res?.result?.total) {
        this.length = res.result.total;
      }
      /* istanbul ignore next */
      this.tableViewData(res?.result?.content?.[0]?.data, false);
      /* istanbul ignore next */
      let cardData = JSON.parse(this.cardDetails?.templateMappingFields);
      /* istanbul ignore next */
      let expandSet: Set<string> = new Set(
        Object.values(
          cardData?.expandTemplateTypeData
            ? cardData?.expandTemplateTypeData
            : {}
        )
      );
      /* istanbul ignore next */
      this.isCardMultiExpand = cardData?.isMultiAttributeExpan;
      /* istanbul ignore next */
      this.gsiExpanddata = cardData?.gsiExpandData;
      /* istanbul ignore next */
      res?.result?.content[0]?.data?.forEach((txn: any) => {
        /* istanbul ignore next */
        txn?.transEntityRecords?.forEach((obj: any, inx: number) => {
          let record: any = {
            recordData: [],
            recordId: txn.id,
            entityName: txn?.displayName ? txn.displayName : txn.name,
            isMore: false,
          };
          this.mappingFields?.attr?.forEach((attribute: any, ind: number) => {
            const value = obj?.txnNslAttribute.find((txnattr: any) => txnattr.name == attribute);
            let type;
            if (value) type = this.attributeUIType.get(value.name);
            /* istanbul ignore else */
            if (value?.values?.length > 0) {
              let url: any = '';
              if (this.cardDetails?.cardName == 'child' && this.cardDetails?.templateType == '1' && ind == 4) {
                url = JSON.parse(value.values[0]).contentUrl;
              } else if (this.cardDetails?.cardName == 'child' && this.cardDetails?.templateType == '2' && ind == 0) {
                url = JSON.parse(value.values[0]).contentUrl;
              } else if (this.cardDetails?.cardName == 'expand' && this.cardDetails?.templateType == '1' && ind == 0) {
                url = JSON.parse(value.values[0]).contentUrl;
              }
              /* istanbul ignore else */
              if (type == 'image' && value.values.length > 0) {
                url = JSON.parse(value.values[0]).contentUrl;
              }
              record.recordData.push({
                attributeName: value.name,
                displayName: value.displayName,
                value: value.values.join(),
                contentUrl: url,
                type: type,
              });
            } else if (obj?.txnNslAttribute.length === 0) {
              this.showCards = false;
            } else if (attribute) {
              record.recordData.push({
                attributeName: value?.name,
                displayName: value?.displayName ? value?.displayName : value?.name,
                value: getNoValue(type,this.mappingFields?.customNoValue),
                contentUrl: '',
                type: type,
              });
            } else {
              record.recordData.push({
                value: undefined,
              });
            }
          });
          if (record.recordId) temp.push(record);
        });
        txn.transEntityRecords.forEach((attr: any) => {
          let val: any = {};
          attr.txnNslAttribute.forEach((at: any) => {
            /* istanbul ignore else */
            if (expandSet.has(at.name)) {
              val[at.name] = at?.values.join();
            }
          });
          val.recordId = txn.id;
          val.entityName = txn?.displayName ? txn.displayName : txn.name;
          val.entitySendGsiName = txn.name;
          this.expandCardDetails.push(val);
          //
          this.isShowCardDetails = false;
          /* istanbul ignore next */
        });
      });
      /* istanbul ignore next */
      this.cardsData = JSON.parse(JSON.stringify(temp));
      /* istanbul ignore next */
      if (this.cardsData?.[0]?.entityName == 'Resources') {
        /* istanbul ignore next */
        this.cardsData?.forEach((card: any) => {
          card.recordData.unshift({
            attributeName: 'Email',
            contentUrl: '',
            value: '',
          });
        });
      }
      /* istanbul ignore next */
      if (!this.isSearch) {
        if (this.cardDetails.filterBy.length > 0) {
          this.cardDetails.filterBy?.forEach((attr: any) => {
            attr['filtervalues'] = this.filtersortValues(
              attr.attributeName,
              (this.cardDetails?.childView?.attributeList[0]?.name ? this.cardDetails?.childView?.attributeList[0]?.name : this.cardDetails?.primaryEntity?.name),
              res?.result?.content[0]
            );
          });
        }
      }
      /* istanbul ignore next */
      if (this.sortOption) {
      } else {
        this.sortOption = this.mappingFields?.sortOption == 'Ascending' ? 1 : 2;
      }
      /* istanbul ignore next */
      this.isShowCardDetails = false;
    }
  }

  filtersortValues(name: any, entityName: string, data: any) {
    let filtervalues: any = [];
    let key = entityName + '.' + name;
    let aggregateData = data?.aggregates?.[key];
    aggregateData?.forEach((val: any) => {
      filtervalues.push({
        name: val.v1,
        checked: false,
        count: val.v2,
        displayValue: val.displayValue,
      });
    });
    /* istanbul ignore next */
    filtervalues = [
      ...new Map(filtervalues.map((item: any) => [item?.name, item]))?.values(),
    ];
    return filtervalues;
  }
  executeCardEmit(event: any) {
    this.executeCard(event.gsi, event.card, event.txnDataIndex, event?.event);
  }
  executeCard(
    buttonData: GsiButton,
    card?: any,
    index?: number,
    event?: Event
  ) {
    // if (event) event.preventDefault();
    if (buttonData.entityType == 'GSI' || buttonData.entityType == 'PAGE') {
      /** this if block detects if it has url binding  */
      const name = this.hyperlinkCtaDropArray?.find(
        (attr: any) => attr.name == buttonData?.name
      );
      if (
        this.urlBindingDataConfiguration?.masterId == buttonData.masterId &&
        this.urlBindingDataConfiguration?.attributeId
      ) {
        const index = card.recordData.findIndex(
          (attr: any) =>
            attr.attributeName ==
            this.urlBindingDataConfiguration?.attributeName
        );
        if (index != -1) {
          this.router.navigateByUrl(
            '/browse/' +
              this.cardDetails?.primaryEntity?.name +
              '/' +
              card?.recordData[index]?.value
          );
        }
      } else if (this.gsiMapping && name) {
        if (buttonData.name == name.name) {
          window.open(name.defaultValue, '_blank');
        }
      } else {
        this.executeGsi(buttonData, card);
      }
    } else {
      // cart execution
      if (this.quantity[index] == 0) {
        this.quantity[index] = 1;
      }
      this.addToCart(this.transactionData[index], this.quantity[index]);
    }
  }

  addToCart(transactionEntityRecord: any, quantity?: number) {
    let requestData: any = {
      txnGeneralEntity: transactionEntityRecord,
      cartView: this.cartView,
      quantity: quantity,
    };
    let tempArray = [];
    if (localStorage.getItem('isGuestUser') && !localStorage.getItem('initiatedNonGuest')) {
      if (localStorage.getItem('cartConfig')) {
        tempArray = JSON.parse(localStorage.getItem('cartConfig'));
      }
      let index = tempArray.findIndex(
        (item: any) =>
          item?.txnGeneralEntity?.id == requestData?.txnGeneralEntity?.id
      );
      if (index != -1) {
        tempArray[index] = requestData;
      } else {
        tempArray.push(requestData);
      }
      localStorage.setItem('cartConfig', JSON.stringify(tempArray));
      return;
    }
    this.gsiFacadeService.addToCart(requestData).subscribe((response) => {
      if (response.status === 200) {
        this.alertService.showToaster(response.message, 'success', 'success');
      }
      this.actionButton = 'CART';
      this.getEntityData(0);
    });
  }
  recieveTransEmit(data: CardRecord) {
    this.executeGsi(this.gsiMapping[0], data);
  }
  executeGsi(gsi: any, card?: any) {
    /*istanbul ignore next*/
    let dcdsList = this.cardDetails?.gsiList?.find((g: any) => gsi.masterId == g.masterId)
    ?.dcds;

    if (gsi?.entityType === 'GSI') {
      const txnData = gsi?.isUnrelated
      ? btoa(
          JSON.stringify({
            gsiId: gsi.id,
            gsiMasterId: gsi.masterId,
          })
        )
      : btoa(
        JSON.stringify({
          gsiId: gsi?.id,
          gsiMasterId: gsi.masterId,
          dcdsList: [dcdsList ? dcdsList : []],
          recordIdsList: [[card.recordId]],
          quantitiesList: [[1]],
          entities: [
            card.entitySendGsiName
              ? card.entitySendGsiName
              : this.cardDetails?.primaryEntity?.name,
          ],
        })
      );
      /* istanbul ignore next */
      localStorage.setItem('gsi-masterId', gsi?.masterId);
      this.actionButton = 'EXECUTE';
      this.getEntityData(0, card?.recordId);
      if (gsi.popUp) {
        this.openDialog(txnData);
      } else {
        this.router.navigate(['transaction/view/' + txnData]);
      }
    } else if (gsi.entityType === 'PAGE') {
      this.router.navigate(
        [
          `view/${encodeString(
            card?.entitySendGsiName
              ? card?.entitySendGsiName
              : this.cardDetails?.primaryEntity?.name
          )}/${encodeString(card?.recordId.toString())}`,
        ],
        {
          queryParams: { viewname: encodeString(gsi?.name) },
        }
      );
    }
  }
  openDialog(solutionData: any): void {
    const dialogRef = this.dialog.open(TemplateDialog, {
      panelClass: 'mccGsiModal',
      data: { solutionData },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.applyFilterAndSort(true, true);
    });
  }
  gotoTxnPage(gsi: any, card?: any, event?: MouseEvent) {
    /* istanbul ignore next */
    if (event) {
      event.preventDefault();
    }
    this.executeGsi(gsi, card);
  }

  backToEntityPage() {
    if (this.fromMCC) {
      this.isMccLayout = true;
      this.tempIsMCC = false;
      this.rangeData = [];
      return;
    }
    this.backFromCard.emit(true);
    this.addFilterData.emit(false);
    this.isShowCardDetails = true;
    this.rangeData = [];
  }

  closeFilter() {
    // this.showfromMcc = false;
    this.swiperCardWidth = this.noSwiperDiv.nativeElement.querySelector(
      '#Template-' + this.cardDetails.templateType
    ).offsetWidth;
    this.isMccLayout = true;
    this.swiperPage = 0;
    setTimeout(() => {
      this.createSwiper(this.swiperDiv);
    }, 100);
  }

  private createSwiper(swiperDiv: ElementRef) {
    try {
      if(!this.swiperCardWidth) {
        this.swiperCardWidth = swiperDiv?.nativeElement?.querySelector(
          '#Template-' + this.cardDetails.templateType
        ).offsetWidth;
      }
      this.swiperDivWidth = swiperDiv?.nativeElement?.offsetWidth;
      this.swiperCards = Math.floor(this.swiperDivWidth / this.swiperCardWidth);
      if (this.swiperCards > 1) {
        this.swiperGap = (this.swiperDivWidth - this.swiperCards * this.swiperCardWidth) / (this.swiperCards - 1);
        if (this.swiperGap > 50) {
          this.swiperGap = 50;
        }
        this.swiperCardWidth = (this.swiperDivWidth - (this.swiperCards - 1) * this.swiperGap) / this.swiperCards;
        swiperDiv.nativeElement
          .querySelectorAll('#Template-' + this.cardDetails.templateType)
          .forEach((card: HTMLElement) => {
            card.style.maxWidth =
              this.swiperCardWidth.toString() + 'px';
            card.style.width =
              this.swiperCardWidth.toString() + 'px';
          });
      swiperDiv.nativeElement.style.gap = this.swiperGap.toString() + 'px';
      } else {
        if ((this.swiperCards = 0)) {
          this.swiperDivWidth = this.swiperCardWidth;
          this.swiperCards = 1;
        }
        this.swiperGap = 4;
        swiperDiv?.nativeElement
          .querySelectorAll('#Template-' + this.cardDetails.templateType)
          .forEach((card: HTMLElement) => {
            card.style.maxWidth = (this.swiperDivWidth - 4).toString() + 'px';
            card.style.width = (this.swiperDivWidth - 4).toString() + 'px';
          });
          this.swiperCardWidth = this.swiperDivWidth - 4;
        swiperDiv.nativeElement.style.gap = '4px';
      }
      swiperDiv.nativeElement.style.width = this.swiperDivWidth.toString() + 'px';
    } catch (error) {
      console.log(error);
    }
  }

  moveToThatPage(pageNumber: number): void {
    this.moveLeft = false;
    this.moveRight = false;
    setTimeout(() => {
      if (this.swiperPage > pageNumber) {
        this.moveLeft = true;
      } else if (this.swiperPage < pageNumber) {
        this.moveRight = true;
      }
      this.updateSwiperPage(pageNumber);
    }, 40);
  }

  updateSwiperPage(swiperPage: number) {
    this.previousPageNumber = this.swiperPage;
    this.swiperPage = swiperPage;
    this.swiperDiv.nativeElement.scrollLeft = this.swiperPage * (this.swiperCardWidth + this.swiperGap);
  }

  startDragging(e: MouseEvent, flag: boolean) {
    this.mouseDown = flag;
    this.startX = e.pageX;
  }

  stopDragging(e: MouseEvent, flag: boolean) {
    this.mouseDown = flag;
    const pageNum = Math.round(this.swiperDiv.nativeElement.scrollLeft / (this.swiperCardWidth + this.swiperGap));
    this.updateSwiperPage(pageNum);
    this.differnceInDrag = 0;
  }

  moveEvent(e: MouseEvent) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    // if((this.differnceInDrag > 0 && this.differnceInDrag > this.startX - e.pageX) || (this.differnceInDrag < 0 && this.differnceInDrag < this.startX - e.pageX)) {
    //   this.startX=e.pageX;
    // }
    this.swiperDiv.nativeElement.scrollLeft += this.startX - e.pageX - this.differnceInDrag;
    this.differnceInDrag = this.startX - e.pageX;
  }

  gotoEntityDetailsPage() {
    this.isMccLayout = false;
    if (this.isDynamicTemplate) {
      this.bindHTML(this.cardDetails.templateType);
    }
  }

  openExpandDialog() {
    this.dialog.closeAll();
    this.dialog.open(this.expandTemplate, { width: '800px' });
  }
  expandCard(card: any, event?: MouseEvent, txnDataIndex?: any) {
    if (event?.defaultPrevented) return;
    if (
      this.mappingFields?.expandTemplateTypeData &&
      Object.keys(this.mappingFields?.expandTemplateTypeData).length > 0
    ) {
      this.isExpanded = true;
      this.expandTxnDataIndex = txnDataIndex;
      if(this.isExpandDynamicTemplate) {
        this.dynamicCardOpenExpand(event,txnDataIndex);
      }
      this.scrollEntityCards.emit(event);
    } else {
      if (this.cardGsiMap?.mapped)
        this.gotoTxnPage(this.cardGsiMap?.value, card);
    }
    /* istanbul ignore next */
    this.expandRecordId = card?.recordId;
    if (this.isExpandPopup) {
      this.openExpandDialog();
    }
  }
  closeCard(event: boolean): void {
    this.isExpanded = !event;
    this.dialog.closeAll();
  }

  publishtonsl(entity: any) {
    /*istanbul ignore next*/

    this.sharedFacadeService.onPublishToNSL({ ...entity, type: 'entity' });

    const nslent = btoa(
      JSON.stringify({
        entity: entity,

        type: 'entity',
      })
    );

    this.router.navigate([`mylibrary-dashboard/publishtonsl/` + nslent]);
  }
  handlePageEvent(event: any) {
    this.getEntityData(event.pageIndex);
  }

  tableViewData(data: any, search: boolean) {
    if (!search) {
      this.tableData = [];
      /* istanbul ignore next */
      data?.forEach((transEnt: any) => {
        /* istanbul ignore next */
        let record: any = {
          recordData: [],
          recordId: transEnt?.id,
          entityName: transEnt?.name,
        };
        /* istanbul ignore next */
        transEnt?.transEntityRecords?.forEach((txnEnt: any) => {
          /* istanbul ignore next */
          txnEnt?.txnNslAttribute?.forEach((txnAttr: any) => {
            this.mappingFields?.attr?.forEach((attribute: any, ind: number) => {
              if (attribute == txnAttr.name) {
                let url: any = null;
                /* istanbul ignore next */
                if (this.isJsonString(txnAttr?.values?.[0])) {
                  const val = JSON.parse(txnAttr.values[0]);
                  if (val && val?.contentUrl && val?.contentUrl != '') {
                    url = JSON.parse(txnAttr.values[0]).contentUrl;
                  }
                }
                /* istanbul ignore next */
                record?.recordData?.push({
                  displayName: txnAttr?.displayName,
                  attributeName: txnAttr?.name,
                  value: txnAttr?.values?.join(),
                  contentUrl: url,
                  type: 'attribute',
                });
              }
            });
          });
        });
        /* istanbul ignore next */
        if (this.cardDetails?.gsiList && this.cardDetails?.gsiList?.length > 0) {
          let value: any = [];
          /* istanbul ignore next */
          this.cardDetails?.gsiList?.forEach((gsi: any, index: any) => {
            value.push({ value: { id: gsi.id, masterId: gsi.masterId, name: gsi.name } });
          });
          /* istanbul ignore next */
          record?.recordData?.push({
            attributeName: value.length == 1 ? 'Action Button' : 'Action Buttons',
            value: value,
            contentUrl: null,
            type: 'button',
          });
        }
        /* istanbul ignore next */
        this.tableData.push(record);
      });
    } else {
      this.tableData = [];
      /* istanbul ignore next */
      data?.forEach((txnEnt: any) => {
        /* istanbul ignore next */
        let record: any = { recordData: [], recordId: txnEnt?.id, entityName: txnEnt?.name };
        /* istanbul ignore next */
        txnEnt?.txnNslAttribute?.forEach((txnAttr: any) => {
          let url: any = null;
          /* istanbul ignore next */
          if (this.isJsonString(txnAttr?.values?.[0])) {
            const val = JSON.parse(txnAttr.values[0]);
            if (val && val?.contentUrl && val?.contentUrl != '') {
              url = JSON.parse(txnAttr.values[0]).contentUrl;
            }
          }
          /* istanbul ignore next */
          record?.recordData?.push({
            attributeName: txnAttr.name,
            value: txnAttr.values.join(),
            contentUrl: url,
            type: 'attribute',
          });
        });
        /* istanbul ignore next */
        if (this.cardDetails?.gsiList && this.cardDetails?.gsiList?.length > 0) {
          let value: any = [];
          /* istanbul ignore next */
          this.cardDetails?.gsiList?.forEach((gsi: any, index: any) => {
            value.push({ value: { id: gsi.id, masterId: gsi.masterId, name: gsi.name } });
          });
          /* istanbul ignore next */
          record?.recordData?.push({
            attributeName: value.length == 1 ? 'Action Button' : 'Action Buttons',
            value: value,
            contentUrl: null,
            type: 'button',
          });
        }
        this.tableData.push(record);
      });
    }
  }

  isJsonString(jsonString: string): boolean {
    try {
      var json = JSON.parse(jsonString);
      /* istanbul ignore next */
      if (json && typeof json === 'object') {
        return true;
      }
    } catch (e) {}

    return false;
  }
  showTable() {
    this.isTable = true;
  }

  showCard() {
    this.isTable = false;
    /* istanbul ignore next */
    if (this.isDynamicTemplate && this.cardsData.length >= 0) {
      this.bindHTML(this.cardDetails.templateType);
    }
  }
  parseIntDuplicate(number: string): number {
    return parseInt(number);
  }
  getsearchDataEmitter(event: any) {
    this.searchData = event;
  }
  getFilterDataEmitter(event: any) {
    this.filterData = event;
  }
  fetchCurrentPageDetails(pageNumber: any) {
    this.applyFilterAndSort(true, true, false, '', '', pageNumber);
    this.paginatorNumber = pageNumber;
  }

  fetchPageSizeDetails(pageSize: any) {
    this.pageSize = pageSize;
    this.applyFilterAndSort(true, true, false, '', '', 0);
  }

  getCurrentLocation(currentLocation: boolean) {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.displayLocationstatus = false;
        this.currentlat = +pos.coords.latitude;
        this.currentlng = +pos.coords.longitude;
        if (currentLocation) {
          /*istanbul ignore next*/
          if (this.searchElementRef?.nativeElement?.value) {
            this.searchElementRef.nativeElement.value = '';
          }
          this.applyFilterAndSort(true,true);
        }
      },
      /* istanbul ignore next */
      (err) => {
        this.displayLocationstatus = true;
        console.log(err);
      }
    );
  }
  removeLocationFilter() {
    this.currentlat = undefined;
    this.currentlng = undefined;
    this.applyFilterAndSort(true,true);
  }
  isValidSelectedRefAttrValue(attr: any): boolean {
    return (
      attr.selectedRefAttrValue &&
      attr.selectedRefValue &&
      Object.keys(attr.selectedRefAttrValue).length >= 2 &&
      Object.keys(attr.selectedRefValue).length >= 2
    );
  }
  keyUpSearchField(attr: any, afterKey?: string) {
    if (this.isValidSelectedRefAttrValue(attr)) {
      const payload = {
        entityName: attr.selectedRefValue.name,
        attrName: attr.selectedRefAttrValue.name,
        attrValue: attr.searchValue ? attr.searchValue : '',
      };
      afterKey =  afterKey ? afterKey :"";
     
      
      this.fetchSearchData(attr, afterKey, payload);
    }
  }
  dropdownInputSearchField() {
    this.inputSearchFilter
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data: any) => {
        this.keyUpSearchField(data, "");
      });
  }

  private fetchSearchData(
    attr: any,
    afterKey: string,
    payload: any
  ): void {
    this.entityBoardEndpointService
      .fetchAttrSearchData(payload, afterKey)
      .subscribe((res: any) => {
        if (res && res?.result && res?.result?.attrValues?.length > 0) {
          if (res.result.attrName === payload.attrName) {
            if(afterKey!="" )
             attr.options = [...attr.options, ...res.result.attrValues];
            else{
              attr.options = res.result.attrValues;
            }
            if(res.result.afterKey)
            this.afterKey = res.result.afterKey;
            let checkedValues: Set<string> = new Set();
            this.mangePreviouslySelectedSearchValues(attr, checkedValues);
            attr.options.forEach((option: string) => {
              //already checked values also can come through the api response since the API doesnt know what values are checked
              // so only the values which are not checked are considered
              //TODO: not to be called for existing values
              if (!checkedValues.has(option) ) {
                attr.selectedSearchValues.push({
                  value: option,
                  selected: false,
                });
              }
            });
            this.serachFieldScrolled = res?.result?.attrValues?.length === 10;
          }
        }
      });
  }

  private mangePreviouslySelectedSearchValues(
    attr: any,
    checkedValues: Set<string>
  ) {
    if (attr.selectedSearchValues) {
      attr.selectedSearchValues = attr.selectedSearchValues.filter(
        (option: SearchValue) => {
          if (option.selected) {
            checkedValues.add(option.value);
          }
          return option.selected;
        }
      );
    } else attr.selectedSearchValues = [];
  }
  updateSelectedSearchField(event: any, option: any, searchField: any) {
    option.selected = !option?.selected;
    searchField.selectedValues = searchField.selectedValues
      ? searchField.selectedValues
      : [];
    if (option?.selected) {
      searchField?.selectedValues.push(option?.value);
      searchField.value = searchField?.selectedValues;
      this.searchAllFields();
    } else {
      if (searchField.selectedValues.includes(option.value)) {
        // * Added this to prevent the duplicate Option Return in the Search Fields
        searchField.selectedValues = searchField?.selectedValues.filter(
          (item: any) => {
            return item != option?.value && !option?.selected;
          }
        );
        searchField.value = searchField?.selectedValues;
        /**
         * !Changed This Line Because This Search Field Might be Empty But not the other Search Fields.
         * ?What if Other Search Fields are also Empty?
         * *This is handled at the Time of hitting API checked whether it is array of length 0.
         */
        this.searchAllFields();
      }
    }
    this.updateselectedSearchFieldMap(option, searchField);
  }

  private updateselectedSearchFieldMap(option: any, searchField: any) {
    if (option?.selected) {
      if (!this.entitySearchFieldMap.has(searchField.id)) {
        this.entitySearchFieldMap.set(searchField.id, []);
        this.selectedSearchFieldIds.push(searchField.id);
      }
      this.entitySearchFieldMap.get(searchField.id).push(option.value);
    } else {
      this.deleteSearchFieldFromMap(searchField?.id, option?.value);
    }
  }

  clearThisSearchField(searchFieldId: string, search: string) {
    this.deleteSearchFieldFromMap(searchFieldId, search);
    let searchField = this.entitySearchFields?.find(
      (searchField: any) => searchField.id === searchFieldId
    );
    let dynamicSearchField = this.dynamicEntitySearchFields.find(
      (dynamicSearchField: any) => dynamicSearchField.id == searchFieldId
    );
    if (searchField) {
      this.removeFromSearchField(searchField, search);
    }
    if (dynamicSearchField) {
      this.removeFromSearchField(dynamicSearchField, search);
    }
    this.setShowEntitySearchFields();
  }
  removeFromSearchField(searchField: any, search: string) {
    let i = searchField?.selectedValues.findIndex((val: any) => val == search);
    searchField?.selectedValues.splice(i, 1);
    let index = searchField?.selectedSearchValues.findIndex(
      (val: any) => val.value == search && val.selected
    );
    searchField.selectedSearchValues[index].selected = false;
    /**
     * !Changed This Line Because This Search Field Might be Empty But not the other Search Fields.
     * ?What if Other Search Fields are also Empty?
     * *This is handled at the Time of hitting API checked whether it is array of length 0.
     */
    this.searchAllFields();
  }

  private deleteSearchFieldFromMap(searchFieldId: string, search: string) {
    this.entitySearchFieldMap.set(
      searchFieldId,
      this.entitySearchFieldMap
        .get(searchFieldId)
        ?.filter((val: string) => val != search)
    );
    /* istanbul ignore next */
    if (this.entitySearchFieldMap.get(searchFieldId)?.length <= 0) {
      this.entitySearchFieldMap?.delete(searchFieldId);
      this.selectedSearchFieldIds = this.selectedSearchFieldIds?.filter(
        (val: any) => val != searchFieldId
      );
    }
  }
  onSearchFieldOptionChange(event: any, attr: any) {
    attr.value = event.option._element.nativeElement
      .getElementsByClassName('mat-option-text')[0]
      .innerHTML.trim();
  }
  /* istanbul ignore next */
  getDynamicCardTemplate(card: any, index: any) {
    /* istanbul ignore next */
    if (
      this.el.nativeElement.querySelector('.card_' + index).innerHTML === ''
    ) {
      /* istanbul ignore next */
      this.el.nativeElement.querySelector(
        '.card_' + index
      ).innerHTML = this.dynamicCard;
      /* istanbul ignore next */
      this.el.nativeElement
        .querySelectorAll('.card_' + index)
        .forEach((el: any, index: number) => {
          card?.recordData.forEach((rec: any, ind: number) => {
            el.id = 'Template-' + this.cardDetails.templateType;
            /* istanbul ignore next */
            if (
              el
                .querySelectorAll('li')
                [ind]?.classList.value.includes('only-value')
            ) {
              el.querySelectorAll('li')[
                ind
              ].innerHTML = `<span class='value-placeholer'>${rec?.value}</span>`;
            }
            /* istanbul ignore next */
            if (
              el
                .querySelectorAll('li')
                [ind]?.classList.value.includes('attribute-value')
            ) {
              if (rec?.displayName) {
                el.querySelectorAll('li')[
                  ind
                ].innerHTML = `<span class='attr-placeholer'>${rec?.displayName}</span>`;
              } else {
                el.querySelectorAll('li')[
                  ind
                ].innerHTML = `<span class='attr-placeholer'>${rec?.attributeName}</span>`;
              }
              el.querySelectorAll('li')[
                ind
              ].innerHTML += `<span class='value-placeholer'>${rec?.value}</span>`;
            }
            /* istanbul ignore next */
            if (
              el
                .querySelectorAll('li')
                [ind]?.classList.value.includes('hyper-link')
            ) {
              el.querySelectorAll('li')[
                ind
              ].innerHTML = `<a class='link-value' href=${rec?.value} target='_blank'>${rec?.value}</a>`;
            }
            /* istanbul ignore next */
            if (
              el
                .querySelectorAll('li')
                [ind]?.classList.value.includes('card-logo')
            ) {
              /* istanbul ignore next */
              if (rec?.contentUrl) {
                el.querySelectorAll('li')[
                  ind
                ].innerHTML = `<img src='${rec?.contentUrl}' class='image-class' alt='' />`;
              } else {
                el.querySelectorAll('li')[
                  ind
                ].innerHTML = `<img src='' class='image-class' alt='' />`;
              }
            }
            /* istanbul ignore next */
            if (
              this.cardDetails?.gsiList.length > 0 &&
              el.querySelectorAll('.gsi-button')?.length > 0
            ) {
              el.querySelectorAll('.gsi-button')?.forEach(
                (btn: any, i: number) => {
                  // if (this.cardDetails?.gsiList[index].id == -1) index++;
                  if (this.cardDetails?.gsiList[i]?.displayName)
                    btn.innerHTML = this.cardDetails?.gsiList[i]?.displayName;
                  else {
                    btn.innerHTML = '';
                    this.rendrer.removeChild(btn.parentElement, btn);
                  }
                  btn.addEventListener(
                    'mousedown',
                    () => {
                      /* istanbul ignore next */
                      if (this.cardDetails?.gsiList[i]?.id != -1)
                        this.gotoTxnPage(
                          this.gsiMapping?.[i],
                          card
                        );
                      else if (this.cardDetails?.gsiList[i]?.id == -1)
                        this.addToCart(this.transactionData[index]);
                    },
                    false
                  );
                }
              );
            }
            /* istanbul ignore next */
          });
        });
    }
  }

  dynamicCardOpenExpand(event: Event, index: number): void {
    event.stopPropagation();
    if (this.cardDetails?.expandedView?.style?.templateType) {
      this.isExpanded = true;
      if (this.cardDetails.expandedView.style.templateType.id) {
        this.bindExpandTemplate(
          this.cardDetails.expandedView.style?.templateType?.template,
          this.dynamicExpandRecords[index],
          this.cardDetails.expandedView.style?.expandTemplateTypeData,
          this.gsiExpanddata,
          index
        );
      }
    }
  }
  bindExpandTemplate(
    template: string,
    record: any[],
    mapping: { [key: number]: string },
    gsiExpanddata: { [key: string]: any },
    expandIndex: number
  ) {
    let style = template.split('<style>').pop().split('</style>')[0];
    let content = template.split('<style>')[0];
    let htmlElement: HTMLElement = this.el.nativeElement.querySelectorAll('.dynamic-template-placeholder')[1];
    htmlElement.innerHTML = '<style>' + style + '</style>' + ' <div class="closeexpand">x</div>' + content;
    let card: HTMLElement = htmlElement.querySelector('.dynamic-card-block');
    htmlElement.querySelector('.closeexpand').addEventListener('click', () => {
      this.isExpanded = false;
    });
    card.querySelectorAll('li').forEach((element: HTMLElement, index: number) => {
      let attributeRecord = record.find((x) => x.name == mapping[index + 1]);
      if (element.classList.value.includes('only-value')) {
        element.innerHTML = `<span class='value-placeholer'><p>${attributeRecord?.values[0]}</p></span>`;
        if (element?.classList?.value?.includes('dynamic-tooltip')) {
          element.children[0].innerHTML += `<div class="dynamic-tooltiptext">${attributeRecord?.values[0]}</div>`;
        }
      }
      if (element?.classList.value.includes('attribute-value')) {
        if (attributeRecord.displayName) {
          element.innerHTML = `<span class='attr-placeholer'><p>${attributeRecord.displayName}</p></span>`;
          if (element?.classList?.value?.includes('dynamic-tooltip')) {
            element.children[0].innerHTML += `<div class="dynamic-tooltiptext">${attributeRecord.displayName}</div>`;
          }
        } else {
          element.innerHTML = `<span class='attr-placeholer'><p>${attributeRecord.attributeName}</p></span>`;
          if (element?.classList?.value?.includes('dynamic-tooltip')) {
            element.children[0].innerHTML += `<div class="dynamic-tooltiptext">${attributeRecord.attributeName}</div>`;
          }
        }
        element.innerHTML += `<span class='value-placeholer'><p>${attributeRecord.values[0]}</p></span>`;
        if (element?.classList?.value?.includes('dynamic-tooltip')) {
          element.children[1].innerHTML += `<div class="dynamic-tooltiptext">${attributeRecord.values[0]}</div>`;
        }
      }
      /* istanbul ignore next */
      if (element?.classList.value.includes('card-logo')) {
        try {
          const parsedData = JSON.parse(attributeRecord?.values?.[0]);
          if (parsedData && parsedData.contentUrl) {
            attributeRecord.contentUrl = parsedData.contentUrl;
          }
        } catch (error) {
          attributeRecord.contentUrl = attributeRecord?.values?.[0];
        }
        /* istanbul ignore next */
        if (attributeRecord?.contentUrl) {
          element.innerHTML = `<img src='${attributeRecord.contentUrl}' class='image-class' alt='' />`;
        } else {
          element.innerHTML = `<img src='' class='image-class' alt='' />`;
        }
      }
    });
    if (card.querySelectorAll('.gsi-button')?.length > 0) {
      card.querySelectorAll('.gsi-button')?.forEach((btn: any) => {
        const key = btn.id;
        if (gsiExpanddata?.[key]?.displayName) btn.innerHTML = gsiExpanddata?.[key]?.displayName;
        else {
          btn.innerHTML = '';
          this.rendrer.removeChild(btn.parentElement, btn);
        }
        btn.addEventListener(
          'click',
          (event: Event) => {
            event.stopPropagation();
            /* istanbul ignore next */
            if (gsiExpanddata?.[key]?.id != -1) this.gotoTxnPage(gsiExpanddata?.[key], this.cardsData[expandIndex]);
            else if (gsiExpanddata?.[key]?.id == -1) this.addToCart(this.transactionData?.[expandIndex]);
          },
          false
        );
      });
    }
  }
  /* istanbul ignore next */
  bindHTML(templateId: string) {
    /* istanbul ignore next */
    const tempId = templateId.split('-')[1];
    /* istanbul ignore next */
    if (tempId) {
      /* istanbul ignore next */
      this.entityBoardEndpointService.getTemplate(tempId).subscribe((res) => {
        /* istanbul ignore next */
        let style = res.result.template.split('<style>').pop().split('</style>')[0];
        let content = res.result.template.split('<style>')[0];
        let elems = new Array(this.cardsData.length);
        elems.fill(content);
        this.el.nativeElement.querySelector('.dynamic-template-placeholder').innerHTML = ``;
        this.el.nativeElement.querySelector('.dynamic-template-placeholder').innerHTML =
          '<style>' + style + '</style>' + ' ' + elems.toString().split(',').join('');
        /* istanbul ignore next */
        this.el.nativeElement.querySelectorAll('.dynamic-card-block').forEach((el: any, index: number) => {
          el.id = 'Template-' + this.cardDetails.templateType;
          el.addEventListener('click', (event: Event) => {
            this.dynamicCardOpenExpand(event, index);
          });
          const elementArray = el.querySelectorAll('li');
          this.cardsData[index]?.recordData.forEach((rec: any, ind: number) => {
            /* istanbul ignore next */
            if (elementArray[ind]?.classList.value.includes('only-value')) {
              elementArray[ind].innerHTML = `<span class='value-placeholer'><p>${rec?.value}</p></span>`;
              if (elementArray[ind]?.classList?.value?.includes('dynamic-tooltip')) {
                elementArray[ind].children[0].innerHTML += `<div class="dynamic-tooltiptext">${rec?.value}</div>`;
              }
              if (rec?.attributeName == this.urlBindingDataConfiguration?.attributeName)
                elementArray[ind].addEventListener(
                  'click',
                  (event: Event) => {
                    event.stopPropagation();
                    /* istanbul ignore next */

                    this.router.navigateByUrl('/browse/' + this.cardDetails?.primaryEntity?.name + '/' + rec?.value);
                  },
                  false
                );
            }
            /* istanbul ignore next */
            if (elementArray[ind]?.classList.value.includes('attribute-value')) {
              if (rec?.displayName) {
                elementArray[ind].innerHTML = `<span class='attr-placeholer'><p>${rec?.displayName}</p></span>`;
                if (elementArray[ind]?.classList?.value?.includes('dynamic-tooltip')) {
                  elementArray[
                    ind
                  ].children[0].innerHTML += `<div class="dynamic-tooltiptext">${rec?.displayName}</div>`;
                }
              } else {
                elementArray[ind].innerHTML = `<span class='attr-placeholer'><p>${rec?.attributeName}</p></span>`;
                if (elementArray[ind]?.classList?.value?.includes('dynamic-tooltip')) {
                  elementArray[
                    ind
                  ].children[0].innerHTML += `<div class="dynamic-tooltiptext">${rec?.attributeName}</div>`;
                }
              }
              elementArray[ind].innerHTML += `<span class='value-placeholer'><p>${rec?.value}</p></span>`;
              if (elementArray[ind]?.classList?.value?.includes('dynamic-tooltip')) {
                elementArray[ind].children[1].innerHTML += `<div class="dynamic-tooltiptext">${rec?.value}</div>`;
              }
              if (rec?.attributeName == this.urlBindingDataConfiguration?.attributeName)
                elementArray[ind].addEventListener(
                  'click',
                  (event: Event) => {
                    event.stopPropagation();
                    /* istanbul ignore next */
                    this.router.navigateByUrl('/browse/' + this.cardDetails?.primaryEntity?.name + '/' + rec?.value);
                  },
                  false
                );
            }
            /* istanbul ignore next */
            if (elementArray[ind]?.classList.value.includes('card-logo')) {
              /* istanbul ignore next */
              if (rec?.contentUrl) {
                elementArray[ind].innerHTML = `<img src='${rec?.contentUrl}' class='image-class' alt='' />`;
              } else {
                elementArray[ind].innerHTML = `<img src='' class='image-class' alt='' />`;
              }
            }
          });
          /* istanbul ignore next */
          if (this.cardDetails?.gsiList.length > 0 && el.querySelectorAll('.gsi-button')?.length > 0) {
            el.querySelectorAll('.gsi-button')?.forEach((btn: any, i: number) => {
              // if (this.cardDetails?.gsiList[index].id == -1) index++;
              if (this.gsiMapping?.[i]?.displayName) btn.innerHTML = this.gsiMapping?.[i]?.displayName;
              else {
                btn.innerHTML = '';
                this.rendrer.removeChild(btn.parentElement, btn);
              }
              btn.addEventListener(
                'click',
                (event: Event) => {
                  event.stopPropagation();
                  /* istanbul ignore next */
                  if (this.cardDetails?.gsiList[i]?.id != -1)
                    this.gotoTxnPage(this.gsiMapping?.[i], this.cardsData[index]);
                  else if (this.cardDetails?.gsiList[i]?.id == -1) this.addToCart(this.transactionData[index]);
                },
                false
              );
            });
          }
        });
      });
    }
  }
  // appendValue(value: any, type: any): any {
  //   let finalValue = '';
  //   if (type === 'datetime') {
  //     finalValue = formatDateTime(value?.values[0]);
  //   } else if (type === 'date') {
  //     finalValue = formatDate(value?.values[0]);
  //   } else if (type == 'dateonly') {
  //     finalValue = displayDateDefaultValue(value?.values[0], 'date', 'yyyy-MM-dd', '12-hr', 'yyyy-MM');
  //   } else if (type === 'currentDate') {
  //     finalValue = formatDate(value?.values[0]);
  //   } else if (type == 'image') {
  //     if (this.isJsonString(value?.values[0])) {
  //       finalValue = JSON.parse(value?.values[0]).contentUrl;
  //     } else {
  //       finalValue = value?.values[0];
  //       if (!finalValue) {
  //         finalValue = '';
  //       }
  //     }
  //   } else {
  //     finalValue = value?.values[0] ? value?.values.join() : getNoValue(type,this.mappingFields?.customNoValue);
  //   }
  //   return finalValue;
  // }

  /**
   * The function sets the value of a boolean variable to determine whether to open a save search or filter box.
   * @param {boolean} boolean - The parameter "boolean" is a boolean data type, which means it can only
   * have two possible values: true or false. It is used to determine whether to open or close a save
   * search or filter box.
   */
  openSaveSearchOrFilter(boolean: boolean) {
    this.openSaveSearchOrFilterBox = boolean;
    if (boolean === false) {
      this.filterName = '';
    }
  }

  /**
   * The function sets the value of a boolean variable to control the visibility of a filter box.
   * @param {boolean} boolean - The parameter "boolean" is a boolean data type, which means it can only
   * have two possible values: true or false. It is used to determine whether to open or close a filter
   * box.
   */
  openSaveFilter(boolean: boolean) {
    this.filterName = '';
  }

  /**
   * The function sets a boolean value and retrieves search history data if the boolean is true.
   * @param {boolean} boolean - The parameter `boolean` is a boolean value that determines whether to
   * focus on an input field or not. If it is `true`, the input field will be focused, and if it is
   * `false`, the input field will not be focused.
   */
  focusInputField(boolean: boolean) {
    boolean = !boolean;
    this.openSavedSearch = boolean;
    if (boolean) {
      this.getAllrecordsForSearch(this.cardDetails?.id);
    }
  }

  /**
   * This function saves search or filter data based on the input parameter.
   * @param {any} req - The `req` parameter is an object that contains the data to be saved for either
   * search or filter. The req being the request object of Facet Search API.
   * @param {string} searchOrFilter - A string parameter that indicates whether the data being sent is
   * for a search or a filter. It can have two possible values: 'search' or 'filter'.
   */
  sendFilterSearchData(req: any, searchOrFilter: string) {
    if (
      searchOrFilter === 'search' &&
      this.attributeValue &&
      this.attributeValue !== ''
    ) {
      this.entityBoardEndpointService
        .saveSearch(req)
        ?.pipe(takeUntil(this.ngSubscribe))
        .subscribe((res: any) => {
          if (res) {
            console.log(res.message);
          }
        });
    } else if (searchOrFilter === 'filter') {
      if(this.validateName(this.filterName)) this.saveOrUpdateFilter(req);
      else {
        this.alertService.showToaster(
          'Enter proper saved filter name',
          '',
          'error'
        );
      }
    }
  }

validateName(name: string): boolean {
    const pattern = /^[A-Za-z0-9!@#$%^&*()_+][A-Za-z0-9!@#$%^&*()_+\s]*$/;
    return pattern.test(name);
}

  /**
   * The function saves a search by applying filters and sorting, and then closes the relevant boxes.
   */
  saveSearch() {
    this.actionButton = 'SAVEDSEARCH';
    this.applyFilterAndSort(true, false, true, 'search');
    // this.openSaveFilterBox = false;
    // this.openSaveSearchOrFilterBox = false;
  }

  saveFilter() {
    this.actionButton = 'SAVEDFILTER';
    this.applyFilterAndSort(true, true, true, 'filter');
  }

  /**
   * This function retrieves all search records for a given card ID and stores them in an array.
   * @param {any} cardId - The parameter `cardId` is of type `any` and is used as an input to the
   * `getAllrecordsForSearch` function. It is likely used to retrieve search history records for a
   * specific card identified by its `cardId`.
   */
  getAllrecordsForSearch(cardId: any) {
    this.entityBoardEndpointService
      .getAllSearches(cardId)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: searchResultApi) => {
        if (res && res?.result && res?.result?.length > 0) {
          this.searchHistoryArray = res.result;
        } else if (res && res.status == 200) {
          this.searchHistoryArray = [];
        }
      });
  }

  /**
   * This function clears a particular search value and retrieves all records for the specified card
   * ID.
   * @param {string} searchValue - The `searchValue` parameter is a string that represents the value
   * that the user has entered in the search field. It is used to clear the search results for a
   * particular search value on the entity board.
   */
  clearParticularSearch(searchValue: string) {
    this.entityBoardEndpointService
      .clearParticularSearch(this.cardDetails?.id, searchValue)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
          this.getAllrecordsForSearch(this.cardDetails?.id);
        }
      });
  }

  /**
   * This function clears all search records for a given card and retrieves all records for the same
   * card.
   */
  clearAllSearchRecords() {
    this.entityBoardEndpointService
      .clearAllSearchRecords(this.cardDetails?.id)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
          this.getAllrecordsForSearch(this.cardDetails?.id);
        }
      });
  }

  /**
   * The function saves or updates a filter using a service and logs the response.
   * @param {any} req - The parameter "req" is of type "any" and is likely an object containing data
   * that needs to be saved or updated in the filter. The specific properties and structure of the
   * object would depend on the requirements of the application.
   */
  saveOrUpdateFilter(req: any) {
    this.entityBoardEndpointService
      .saveSharableFilter(req, this.filterName, false)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res) {
          console.log(res);
          this.filterName = '';
          this.getAllFilters(this.cardDetails?.id);
        }
      });
  }

  getAllFilters(cardId: string) {
    this.entityBoardEndpointService
      .getSharableFilters(cardId, true, false, false, '', '', 0, 50)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (
          res &&
          'result' in res &&
          'filters' in res.result &&
          Array.isArray(res.result.filters) &&
          res.result.filters.length > 0
        ) {
          this.savedFilters = [...res.result.filters];
        } else if(res && 'result' in res && 'filters' in res.result && Array.isArray(res.result.filters) && res.result.filters.length == 0) {
          this.savedFilters = [];
        } else if(res && 'result' in res && Array.isArray(res.result) && res.result.length == 0) {
          this.savedFilters = [];
        }
      });
  }

  saveSearchCall(search: any) {
    this.actionButton = 'SAVEDSEARCH';
    this.attributeValue = search;
    this.noSpancing();
  }

  ngOnDestroy() {
    this.addFilterData.emit(false);
    this.ngSubscribe.next();
  }

  addToGroupByNestedFunction(
    req: any,
    attr: any,
    nestedAttributes: any,
    operator: string
  ) {
    if (req && attr && operator && operator?.length > 0) {
      const fieldName =
        this.cardDetails?.primaryEntity?.name + '.' + attr?.attributeName;
      const nestedFunctions = nestedAttributes?.map((nestAttr: any) => ({
        fieldName: this.cardDetails?.primaryEntity?.name + '.' + nestAttr,
        functionType: operator,
      }));

      if (nestedFunctions?.length > 0) {
        req?.groupBy?.push({
          fieldName,
          nestedFunctions,
        });
      }
    }
  }

  /**
   * The function `addToGroupBy` adds a field name to the `groupBy` array in the `req` object based on
   * the `attr` parameter and the `facetMapper` mapping.
   * @param {any} req - The `req` parameter is an object that represents a request. It likely contains
   * various properties and methods related to the request.
   * @param {attributeLayoutDetails} attr - The `attr` parameter is an object that represents the
   * attribute layout details. It contains properties such as `id` and `name`.
   */
  addToGroupBy(req: any, attr: attributeLayoutDetails) {
    /* istanbul ignore else*/
    if (
      facetMapper?.[attr?.id] === 'RANGE' ||
      facetMapper?.[attr?.id] === 'RANGE_SINGLE'
    ) {
      const obj = {
        fieldName: this.cardDetails?.primaryEntity?.name + '.' + attr?.name,
      };
      req?.functions?.push(obj);
    } else if (facetMapper?.[attr?.id]?.includes('HISTOGRAM')) {
      const obj = {
        fieldName: this.cardDetails?.primaryEntity?.name + '.' + attr?.name,
      };
      req?.functions?.push(obj);
    } else if (facetMapper?.[attr?.id] === 'IN') {
      req.groupBy.push({
        fieldName:
          this.cardDetails?.childView?.attributeList?.[0]?.name +
          '.' +
          attr?.name,
      });
    }
  }

  /**
   * The function `rangeSearchQuery` calculates the minimum and maximum values based on a given range
   * and adds a search query to the request object.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * information.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains details about
   * an attribute layout. It has the following properties:
   * *For the Range If once Range Filter is Changed we are considering it as changed and Fixing the Range Filter.
   */
  rangeSearchQuery(req: any, key: attributeLayoutDetails) {
    const diff = key?.data?.['maxValue'] - key?.data?.['minValue'];
    const minData =
      parseInt(key?.data?.['minValue']) +
      (diff * key?.data?.['rangeValues']?.[0]) / 100;
    const maxData =
      parseInt(key?.data?.['minValue']) +
      (diff * key?.data?.['rangeValues']?.[1]) / 100;
    if (this.previousMap[key?.name]) {
      req.searchQuery.queries.push({
        searchQueryDepth: 1,
        fieldName:
          this.cardDetails?.childView?.attributeList?.[0]?.name +
          '.' +
          key?.name,
        searchOperatorType: 'RANGE',
        values: [minData, maxData],
      });
    }
  }

  /**
   * The function `rangeSingleSearchQuery` calculates the minimum and maximum values for a range search
   * query and adds it to the `searchQuery` object.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * details.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains the details
   * of an attribute layout. It has the following properties:
   * *For the Range If once Range Filter is Changed we are considering it as changed and Fixing the Range Filter.
   */
  rangeSingleSearchQuery(req: any, key: attributeLayoutDetails) {
    const diff = key?.data?.['maxValue'] - key?.data?.['minValue'];
    const minData = parseInt(key?.data?.['minValue']);
    const maxData =
      parseInt(key?.data?.['minValue']) +
      (diff * key?.data?.['rangeValues']) / 100;
    if (this.previousMap[key?.name]) {
      req?.searchQuery?.queries?.push({
        searchQueryDepth: 1,
        fieldName:
          this.cardDetails?.childView?.attributeList?.[0]?.name +
          '.' +
          key?.name,
        searchOperatorType: 'RANGE',
        values: [minData, maxData],
      });
    }
  }

  /**
   * The function `ratingSearchQuery` searches for a specific rating value within a given range and
   * adds it to the search query.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * information related to the search.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains details about
   * the attribute layout. It includes properties such as `name`, `data`, and `checked`.
   */
  ratingSearchQuery(req: any, key: attributeLayoutDetails) {
    const searchQuery: any = {
      searchQueryDepth: 1,
      fieldName:
        this.cardDetails?.childView?.attributeList?.[0]?.name + '.' + key?.name,
      searchOperatorType: 'RANGE',
      values: [],
    };
    let checkedFlag = false;
    let min = 5;
    key?.data?.forEach((data: any) => {
      /* istanbul ignore else*/
      if (data?.checked && data?.value <= min) {
        checkedFlag = true;
        min = data?.value;
      }
    });
    /* istanbul ignore else*/
    if (checkedFlag) {
      searchQuery.values = [min, 5];
      req?.searchQuery?.queries.push(searchQuery);
    }
  }

  /**
   * The function `inSearchQuery` takes a request object and an attributeLayoutDetails key, and
   * constructs a search query based on the checked values of the key's data.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * information.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that represents the
   * attribute layout details. It contains the following properties:
   */
  inSearchQuery(req: any, key: attributeLayoutDetails) {
    const searchQuery: any = {
      searchQueryDepth: 1,
      fieldName:
        this.cardDetails?.childView?.attributeList?.[0]?.name + '.' + key?.name,
      searchOperatorType: 'IN',
      values: [],
    };
    key.data.forEach((data: any) => {
      /* istanbul ignore else*/
      if (data.checked) {
        searchQuery.values.push(data?.value);
      }
    });
    /* istanbul ignore else*/
    if (searchQuery.values.length > 0) {
      req.searchQuery.queries.push(searchQuery);
    }
  }

  /**
   * The function `dateRangeSearchQuery` takes a request object and an attribute layout details object,
   * converts the values in the attribute layout details object to date format, and adds a search query
   * to the request object with the field name, search operator type, and values.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * details.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains the details
   * of an attribute layout. It has the following properties:
   */
  dateRangeSearchQuery(req: any, key: attributeLayoutDetails) {
    const date1 = this.convertToDateFormat(key?.data?.value?.[0],'START');
    const date2 = this.convertToDateFormat(key?.data?.value?.[1],'END');
    req?.searchQuery?.queries?.push({
      searchQueryDepth: 1,
      fieldName:
        this.cardDetails?.childView?.attributeList?.[0]?.name + '.' + key?.name,
      searchOperatorType: 'RANGE',
      values: [date1, date2],
    });
  }

  /**
   * The function `dateSingleSearchQuery` adds a search query to the `req` object for a given date
   * attribute.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * details.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains details about
   * an attribute layout. It likely has properties such as `name`, `data`, and `value`.
   */
  dateSingleSearchQuery(req: any, key: attributeLayoutDetails) {
    const startDate = this.convertToDateFormat(key.data.value,'START');
    const endDate = this.convertToDateFormat(key.data.value,'END');
    req?.searchQuery?.queries.push({
      searchQueryDepth: 1,
      fieldName:
        this.cardDetails?.childView?.attributeList?.[0]?.name + '.' + key?.name,
      searchOperatorType: 'RANGE',
      values: [startDate, endDate],
    });
  }

  /**
   * The function `histogramSearchQuery` creates search queries based on the checked data values and
   * adds them to the existing search queries in the request object.
   * @param {any} req - The `req` parameter is an object that contains the search query and other
   * information related to the search.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains the layout
   * details of an attribute. It has the following properties:
   */
  histogramSearchQuery(req: any, key: attributeLayoutDetails) {
    const queries: any[] = [];
    key?.data?.forEach((data: any) => {
      /*istanbul ignore else*/
      if (data?.checked) {
        queries?.push({
          searchQueryDepth: 1,
          fieldName:
            this.cardDetails?.childView?.attributeList?.[0]?.name +
            '.' +
            key?.name,
          searchOperatorType: 'RANGE',
          values: [data?.minValue, data?.maxValue],
        });
      }
    });
    /*istanbul ignore else*/
    if (queries?.length > 0) {
      req?.searchQuery?.queries?.push({
        searchQueryDepth: 0,
        operationType: 'OR',
        queries: queries,
      });
    }
  }

  /**
   * The function `addToSearchQuery` determines the type of search query to be performed based on the
   * provided key and calls the corresponding search query function.
   * @param {any} req - The `req` parameter is of type `any`, which means it can be any type of object.
   * @param {attributeLayoutDetails} key - The `key` parameter is an object that contains attribute
   * layout details. It is used to determine the type of search query to be added to the `req` object.
   */
  /* istanbul ignore next */
  addToSearchQuery(req: any, key: attributeLayoutDetails) {
    if (facetMapper?.[key?.id] === 'RANGE') {
      this.rangeSearchQuery(req, key);
    } else if (facetMapper?.[key?.id] === 'RANGE_SINGLE') {
      this.rangeSingleSearchQuery(req, key);
    } else if (
      facetMapper?.[key?.id] === 'IN' &&
      templateMapper?.[key?.id] === 'RATINGS'
    ) {
      this.ratingSearchQuery(req, key);
    } else if (facetMapper?.[key?.id] === 'IN') {
      this.inSearchQuery(req, key);
    } else if (
      facetMapper?.[key?.id] === 'DATE_RANGE' &&
      key?.data?.value &&
      key?.data?.value?.length > 0
    ) {
      this.dateRangeSearchQuery(req, key);
    } else if (facetMapper?.[key?.id] === 'DATE_SINGLE' && key?.data?.value) {
      this.dateSingleSearchQuery(req, key);
    } else if (facetMapper?.[key?.id] === 'HISTOGRAM') {
      this.histogramSearchQuery(req, key);
    } else if (facetMapper?.[key?.id] === 'HISTOGRAM_PERCENTAGE') {
      this.histogramSearchQuery(req, key);
    }
  }

  /**
   * The function `addDataFromRange` takes in three parameters (`str`, `obj`, `attr`) and performs
   * different actions based on the values of `str`, `obj`, and `attr`.
   * @param {any} str - The `str` parameter is a string value.
   * @param {any} obj - The `obj` parameter is an object that contains data for a specific attribute.
   * It has the following structure:
   * @param {any} attr - The `attr` parameter is an object that represents an attribute. It has
   * properties such as `name` and `id`.
   */

  addDataFromRange(str: any, obj: any, attr: any) {
    if (attr?.name === str[1] && facetMapper?.[attr?.id] === 'RANGE' && this.previousFilter != attr?.name) {
      attr.data = {
        minValue: obj?.[1]?.minimumValue,
        maxValue: obj?.[1]?.maximumValue,
        rangeValues: [0, 100],
      };
    } else if (
      attr?.name === str[1] &&
      facetMapper?.[attr?.id] === 'RANGE_SINGLE' &&
      this.previousFilter != attr?.name
    ) {
      attr.data = {
        minValue: obj?.[1]?.minimumValue,
        maxValue: obj?.[1]?.maximumValue,
        rangeValues: 100,
      };
    } else if (
      attr?.name === str[1] &&
      facetMapper?.[attr?.id] === 'HISTOGRAM' &&
      this.checkForCheckedInPreviousMap(attr)
    ) {
      this.addToHistoGram(attr, obj);
    } else if (
      attr?.name === str[1] &&
      facetMapper?.[attr?.id] === 'HISTOGRAM_PERCENTAGE' &&
      this.checkForCheckedInPreviousMap(attr)
    ) {
      this.addToHistoGramPercentage(attr, obj);
    }
  }

  checkForCheckedInPreviousMap(attr: any): boolean {
    const ind = this.previousMap?.[attr?.name]?.findIndex((item: any) => item?.checked);
    if (ind == -1 || ind == undefined) {
      return true;
    }
    return false;
  }
  /**
   * The function addToHistoGramPercentage calculates the range values and creates objects with the
   * calculated values and other properties, and adds them to an array.
   * @param {any} attr - The `attr` parameter is an object that contains a `data` property, which is an
   * array. This array will be populated with objects that represent histogram data.
   * @param {any} obj - The `obj` parameter is an object that contains information about the minimum
   * and maximum values for a range. It is expected to have a property named `1` which is an object
   * with properties `minimumValue` and `maximumValue`.
   */
  addToHistoGramPercentage(attr: any, obj: any) {
    attr.data = [];
    let min = obj?.[1]?.minimumValue;
    let max = obj?.[1]?.maximumValue;
    const diff = max - min;
    const percent = 20;
    for (let i = 1; i <= 5; i++) {
      obj = {
        minValue: parseInt(min) + ((i - 1) * diff) / 5,
        maxValue: parseInt(min) + (i * diff) / 5,
        rangeValues: [(i - 1) * percent, i * percent],
        checked: false,
      };
      attr.data.push(obj);
    }
  }

  /**
   * The function `addToHistoGram` creates a histogram by dividing a range into equal divisions and
   * populating an array with objects representing each division.
   * @param {any} attr - The `attr` parameter is an object that contains the data and divisions
   * properties. The `data` property is an array that will be populated with objects, and the
   * `divisions` property is the number of divisions to create in the histogram.
   * @param {any} obj - The `obj` parameter is an object that contains information about the minimum
   * and maximum values. It is expected to have a nested object at index 1 with properties
   * `minimumValue` and `maximumValue`.
   */
  addToHistoGram(attr: any, obj: any) {
    attr.data = [];
    let min = obj?.[1]?.minimumValue;
    let max = obj?.[1]?.maximumValue;
    const diff = max - min;
    for (let i = 1; i <= attr?.divisions; i++) {
      obj = {
        minValue: parseInt(min) + ((i - 1) * diff) / attr?.divisions,
        maxValue: parseInt(min) + (i * diff) / attr?.divisions,
        checked: false,
      };
      attr.data.push(obj);
    }
  }

  addDataForNestedAggregates = (aggregateData: any, attr: any) => {
    const key =
      this.cardDetails?.primaryEntity?.name + '.' + attr?.attributeName;
    const nestedKey =
      this.cardDetails?.primaryEntity?.name +
      '.' +
      attr?.selectedNestedForDate?.selectNestedAttribute[0];
    let data: Map<string, string> = new Map<string, string>();
    aggregateData?.[key]?.forEach((value: any) => {
      if(value?.[nestedKey] != '-Infinity' && value?.[nestedKey] != '+Infinity')
            data?.set(this.formatToLitrealString(value?.v1), value?.[nestedKey]);
    });
    attr.dateData = data;
  };

  formatToLitrealString = (date: any): string => {
    const data = formatDate(date)?.split('/');
    return (
      parseInt(data[1]) +
      '/' +
      (parseInt(data[0]) - 1) +
      '/' +
      parseInt(data[2])
    );
  };

  /**
   * The function `addDataFromAggregates` takes in aggregate data and an attribute, and if the
   * attribute is of type 'IN', it extracts specific values from the aggregate data and assigns them to
   * the attribute's data property.
   * @param {any} aggregateData - The `aggregateData` parameter is an object that contains aggregated
   * data. It likely has a structure where the keys represent different attributes and the values
   * represent the aggregated data for those attributes.
   * @param {any} attr - The `attr` parameter is an object that represents an attribute. It has
   * properties such as `id`, `name`, and `data`.
   */
  addDataFromAggregates(aggregateData: any, attr: any) {
    /* istanbul ignore else*/
    if (facetMapper[attr.id] === 'IN') {
      let key = this.cardDetails?.primaryEntity?.name + '.' + attr?.name;
      let data: any[] = [];
      if (this.previousFilter != attr?.name) {
        aggregateData?.[key]?.forEach((value: any) => {
          const obj = {
            value: value?.v1,
            count: value?.v2,
            checked: this.updateChecked(attr?.name, value?.v1),
          };
          data.push(obj);
        });
        attr.data = data;
      }
    }
  }

  updateChecked(name: string, value: any): boolean {
    const attrValue = this.previousMap?.[name]?.find(
      (map: any) => map.value == value
    );
    return attrValue?.checked ? true : false;
  }

  /**
   *
   * @param req is the Request Object for the Facet API.
   * @param hiddenFilterRule is the variable where conditions are Applied.
   * ? This Function basically adds the hidden Filters to the Facet Api.
   */
  addValueToAlreadyAddedSearchQuery(req: any) {
    const searchQuery: any = addValueToAlreadyAddedSearchQuery(
      this.hiddenFilterRule,
      this.cardDetails?.primaryEntity
    );
    // * Setting Query Here
    if (searchQuery.queries?.length > 0) {
      req?.searchQuery?.queries?.push(searchQuery);
    }
  }

  /**
   * The function sets the data attribute of an item and then applies a filter and sort operation.
   * @param {any} event - The event parameter is of type any, which means it can be any data type. It is
   * likely an event object that contains information about the event that triggered the function.
   * @param {any} item - The "item" parameter is an object that represents an item in your application.
   * It likely has properties such as "data" that store information about the item.
   */
  setDataToAttr(event: any, item: any) {
    item.data = event;
    this.updatePrevious(item.data);
    this.setCountToAggregates(item);
    this.applyFilterAndSort();
  }

  setPropertyName(event: any) {
    this.previousFilter = event;
  }

  updatePrevious(item: any) {
    this.previousMap[this.previousFilter] = item;
  }

  private setCountToAggregates(item: any) {
    if (templateMapper[item?.id] == 'RATINGS') {
      this.ratingsCountAggregates(item);
    } else if (facetMapper[item?.id] == 'IN') {
      this.inAggregates(item);
    }
  }

  private inAggregates(item: any) {
    item.count = 0;
    item.data.forEach((data: any) => {
      if (data.checked) {
        item.count += data.count;
      }
    });
  }

  private ratingsCountAggregates(item: any) {
    item.count = 0;
    item.data.forEach((data: any) => {
      if (data.checked) {
        item.count = data.count;
      }
    });
  }

  /**
   * The function sets the sort option and sort option attribute based on the event, and then applies
   * filter and sort operations.
   * @param {any} event - The `event` parameter is an object that contains the sort option and sort
   * option attribute.
   */
  setDataToSort(event: any) {
    this.sortOption = event?.sortOption;
    this.sortOptionAttr = event?.sortOptionAttr;
    this.applyFilterAndSort();
  }


  /**
   * The function converts a given date object into a formatted date string in the format "YYYY-MM-DD".
   * @param {any} date - The `date` parameter is of type `any`, which means it can accept any data
   * type. However, it is expected to be a valid date object.
   * @returns a formatted date string in the format "YYYY-MM-DD".
   */
  convertToDateFormat(date: Date,startOrEnd: 'START' | 'END'): string{
    if(startOrEnd === 'START') {
      date?.setHours(0,0,0,0);
    } else if(startOrEnd === 'END') {
      date?.setHours(23,59,59,999);
    }
    return date?.toISOString();
  }
  public downloadDataInExcel(json: any[]): void {
    let entityName = json?.[0]?.entityName;
    json = this.formatTableDataInJSON(json)[1];
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer,entityName);
  }
  private saveAsExcelFile(buffer: any , entityName:string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    fileSaver.saveAs(data, entityName + EXCEL_EXTENSION);
  }
  downloadData(type: string, total: any, data: any[] = this.cardsData): void {
    // this.getEntityData(0,this.length);
    if (type === 'pdf') {
      this.downloadDataInPdf(data, total);
    } else if (type === 'excel') {
      this.downloadDataInExcel(data);
    } else {
      this.exportToCsv(data);
    }
  }
  downloadDataInPdf(table: any[], total: number): void {
    import('jspdf').then(({ jsPDF }) => {
      import('jspdf-autotable').then((autoTable) => {
        const doc = new jsPDF('l');
        var col: any = [];
        var rows: any = [[]];
        this.cardsData?.[0]?.recordData.forEach((data: any) => {
          if (data?.type !== 'button') {
            rows?.[0]?.push(data?.displayName);
          }
        });
        col = this.formatTableDataInJSON(table)[0];
        (doc as any).autoTable({
          head: rows,
          body: col,
        });
        doc.save(this.tableData?.[0]?.entityName + '.pdf');
      });
    });
  }

  exportToCsv(data: any[], filename: string = 'sample') {
    data = this.formatTableDataInJSON(this.tableData)[1];
    if (data.length === 0) {
      return;
    }
    const csvHeader = Object.keys(data[0]).join(',');
    const csvData = data.map((item) => Object.values(item).join(',')).join('\n');
    const csvContent = `${csvHeader}\n${csvData}`;
    const blob = new Blob([csvContent], { type: 'text/csv' });
    saveAs(blob, filename);
  }
  private clearSearchFields() {
    this.entitySearchFields?.forEach((attr: any) => {
      attr.selectedSearchValues = [];
      attr.selectedValues = [];
      attr.value = [];
    });
    this.dynamicEntitySearchFields.forEach((attr: any) => {
      attr.selectedSearchValues = [];
      attr.selectedValues = [];
      attr.value = [];
    });
    this.selectedSearchFieldIds.length = 0;
    this.entitySearchFieldMap.clear();
    this.setShowEntitySearchFields();
    if(this.searchConfigurationData.length > 0) {
      this.searchConfigurationData.forEach((attr: any) => {
       attr.selected = false
        attr.selectedValues = [];
        attr.value = '';
      })
    
    }
  }

  resetEntitySearchFields() {
    this.clearSearchFields();
    this.applyFilterAndSort();
  }

  emitSortRadioButton(event: any, sortOption: string) {
    /*istanbul ignore else */
    if (sortOption === 'sortOption') {
      this.sortOption = event.value;
    } else if (sortOption === 'sortOptionAttr') {
      this.sortOptionAttr = event.value;
    }
    this.applyFilterAndSort(true, true);
  }
  // changeExistingSearchFields() {}

  prepareDynamicSearchFieldElgibleAttribute() {
    let attributes: Set<string> = new Set<string>();
    this.entitySearchFields?.forEach((searchField: any) => {
      attributes.add(searchField?.attributeName);
      searchField.id = searchField.selectedRefAttrValue?.id;
      this.searchFieldTemplate = searchField?.filterTemplate;
    });
    this.cardDetails?.childView?.attributeList?.forEach((attr: any) => {
      attr['isSearchField'] = attributes.has(attr?.attributeName) ? true : false;
      attr['canBecomeSearchField'] = attributes.has(attr?.attributeName) ? false : true;
      attr['isSearched'] = true;
    });
    this.setShowEntitySearchFields();
  }
  addSearchField(index: number) {
    let attribute = this.cardDetails?.childView?.attributeList[index];
    if (attribute.isSearchField) {
      this.dynamicEntitySearchFields.push(this.prepareSearchFieldConfig(attribute));
      this.setShowEntitySearchFields();
    } else {
      let index = this.dynamicEntitySearchFields.findIndex(
        (searchField: any) => searchField.attributeName == attribute.attributeName
      );
      this.dynamicEntitySearchFields.splice(index, 1);
      this.setShowEntitySearchFields();
    }
  }
  prepareSearchFieldConfigForTable(data: any) {
    this.cardDetails?.childView?.attributeList.forEach((attribute: any) => {
      this.searchConfigurationData.push(
        this.prepareSearchFieldConfig(attribute, data[attribute.name + '.' + attribute.attributeName])
      );
    });
  }

  private prepareSearchFieldConfig(attribute: any, values?: any): any {
    values?.forEach((value: any) => {
      value['value'] = value.displayValue;
      if(this.attributeUIElementMap?.get(attribute?.attributeName).includes('date')) {
        value['modifiedValue'] = formatDate(value.displayValue)
      }
    });
    return {
      attributeName: attribute.attributeName,
      filterTemplate: this.searchFieldTemplate ? this.searchFieldTemplate : 26,
      searchValue: '',
      selectedRefAttrValue: {
        displayName: attribute.displayName,
        id: attribute.id,
        name: attribute.attributeName,
      },
      selectedRefValue: {
        //not required need to remove
        displayName: this.cardDetails.primaryEntity.displayName,
        name: this.cardDetails.primaryEntity.displayName,
        id: this.cardDetails.primaryEntity.id,
      },
      id: attribute.id,
      //!TODO: need to change ui element dynamically
      uiElement: this.attributeUIElementMap?.get(attribute?.attributeName),
      value: '',
      selected: false,
      selectedSearchValues: values ? values : [],
      selectedValues: [],
    };
  }

  formatTableDataInJSON(table: any[] = this.tableData): any[2] {
    let excelData: any[] = [table.length];
    let pdfData: any[] = [table.length];
    table?.forEach((data: any, index: number) => {
      let obj: any = {};
      let pdf: any = [];
      data?.recordData?.forEach((data: any) => {
        if (data?.type !== 'button') {
          obj[data?.displayName] = Array.isArray(data.value) ? data.value.join(',') : data.value;
          if(Array.isArray(data.value)){
            pdf = [...pdf,...data.value]
          } else {
            pdf.push(data?.value);
          }
        }
      });
      excelData[table.length - index - 1] = obj;
      pdfData[table.length - index - 1] = pdf;
    });
    return [pdfData, excelData];
  }

  updateTableData(event: any): void {
    this.updateSelectedSearchField(event.event, event.option, event.searchField);
  }

  openSavedFiltersDialog(templateRef: TemplateRef<any>): void {
    this.dialog.closeAll();
    this.dialog.open(templateRef, { panelClass: 'filter-dialog-pop-up' });
    this.openSaveSearchOrFilter(true);
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  deleteFilter(cardId: string, filterId: string) {
    this.entityBoardEndpointService
      .deleteFilterByFilterId(cardId, filterId)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.getAllFilters(cardId);
        }
      });
  }




  clickFilter(cardId: string, filter: FilterTable, index?: any) {
    this.entityBoardEndpointService
      .getFilterById(cardId, filter?.id, 'sharable')
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res?.result?.savedQuery?.searchQuery) {
          this.selectedIndex = index;
          this.savedSearchQuery = res?.result?.savedQuery?.searchQuery;
          this.alertService.showToaster(
            'Given Filters are applied',
            '',
            'success'
          );
          this.savedFilterSort = res?.result?.savedQuery?.sort;
          this.appliedSavedFilters = filter?.name;
          if (this.pageSize) {
            if (this.savedFilterSort) {
              this.sortOptionAttr = this.savedFilterSort?.[0]?.fieldName?.split(
                '.'
              )?.[1];
              if (this.savedFilterSort?.[0]?.order == 'ASC') {
                this.sortOption = 1;
              } else if (this.savedFilterSort?.[0]?.order == 'DESC') {
                this.sortOption = 2;
              }
            }
            this.applyFilterAndSort(true, true);
          } else {
            this.detectEntityData(res?.result?.savedQuery, 0, 15);
          }
        }
      });
  }

  closeSavedFilterPanel(event: any, element: any) {
    element.hide(event);
  }
  applyFilterAndSortEmitFromTable(event: any) {
    this.applyFilterAndSort(
      event?.isSearch,
      event?.isSearchFields,
      event?.isSendPayload,
      event?.searchOrFilter,
      event?.idForCard,
      event?.page,
      event?.isHeaderSearch
    );
  }

  sortEmitFilter(event: any) {
    this.sortOption = event.sortOption ? 1 : 2;
    this.sortOptionAttr = event.sortOptionAttr;
    this.applyFilterAndSort(true, true);
  }

  cancelSearch() {
    this.attributeValue = '';
    this.applyFilterAndSort(true);
  }

  resetAggregateCount() {
    this.horizontalLayout.forEach((attr: any) => {
      attr.count = 0;
    });
  }

  /**
   * The function avoids calling getEntityData() twice which is called by applyFilterAndSort() and getEntityData(),
   * which results in two api calls
   */
  clearAllFeatures() {
    this.previousMap = {};
    this.previousFilter = '';
    this.attributeValue = '';
    this.clearAllFlag = !this.clearAllFlag;
    this.savedSearchQuery = [];
    this.dateSearchFieldValue = {};
    this.appliedSavedFilters = '';
    this.clearSort();
    this.clearSearchFields();
    this.resetAggregateCount();
    this.getEntityData();
  }

  setShowEntitySearchFields(): void {
    if (this.entitySearchFields) {
      this.showEntitySearchFields = [...this.entitySearchFields, ...this.dynamicEntitySearchFields];
    }
  }
  filterExpandedView() {
    if (this.filterConfigurations?.expandView && this.horizontalLayout?.length > 0) {
      this.activeIndex = Array.from({ length: this.horizontalLayout?.length }, (v, i) => i);
    } else {
      this.activeIndex = undefined;
    }
  }

  onScrollSearchField(event: any, attr: any) {
    if (event.srcElement.scrollTop / event.srcElement.offsetHeight > 0.9 && this.serachFieldScrolled) {
      this.serachFieldScrolled = false;
      this.keyUpSearchField(attr, this.afterKey);
    }
  }
  searchAttributes() {
    this.cardDetails?.childView?.attributeList?.forEach((attr: any) => {
      if (this.attributeVal != '') {
        attr.isSearched = false;
        if (attr?.canBecomeSearchField) {
          if (attr?.attributeName.includes(this.attributeVal)) {
            attr.isSearched = true;
          }
        }
      } else {
        if (attr?.canBecomeSearchField) {
          attr.isSearched = true;
        }
      }
    });
  }

  // ngOnDestroy() {
  //   this.ngSubscribe.next();
  // }
  /**
   * This function decreases and increase the quantity
   * @param property
   */
  changeQuantity(property: string, index: number) {
    if (property == 'decreaseQuantity' && this.quantity[index] > 1)
      this.quantity[index] -= 1;
    if (property == 'increaseQuantity') this.quantity[index] += 1;
    this.addToCart(this.transactionData[index], this.quantity[index]);
  }
  clearSort() {
    this.sortOption = 3;
    this.sortOptionAttr = '';
  }
  removeAppliedSavedFilter() {
    this.savedSearchQuery = [];
    this.appliedSavedFilters = '';
    if (this.savedFilterSort) {
      this.clearSort();
      this.savedFilterSort = undefined;
    }
    this.applyFilterAndSort(true, true);
  }
  clearLocationFilter() {
    if (this.searchElementRef?.nativeElement?.value) {
      this.searchElementRef.nativeElement.value = '';
    }
  }
  @ViewChild('filterPopUp') filterPopUp: TemplateRef<HTMLElement>;
  openFilters() {
    this.dialog.open(this.filterPopUp, { panelClass: 'filterPopUp' });
    this.filteractions = !this.filteractions;
  }
  searchaccordian() {
    this.accordianfilters = !this.accordianfilters;
    if (this.accordianfilters) {
      this.rendrer.addClass(document.body, 'search-accordian-appentobody');
    }
    else {
      this.rendrer.removeClass(document.body, 'search-accordian-appentobody');
    }
  }

  openLink(
    event?: any,
    name?: any,
    attIndex?: any,
    hyperlinkAttribute?: any,
    txnIndex?: any
  ) {
    const index = hyperlinkAttribute?.findIndex(
      (attr: any) => attr.attrName == name
    );
    if (index != -1) {
      event.stopPropagation();
      let value: any;
      this.transactionData[
        txnIndex
      ]?.transEntityRecords[0]?.txnNslAttribute?.forEach((attr: any) => {
        if (attr.name == hyperlinkAttribute?.[index]?.link) {
          value = attr.values;
        }
      });
      window.open(value, '_blank');
    }
  }
  openLinkEmit(event: any, hyperlinkAttribute?: any, txnDataIndex?: any) {
    this.openLink(
      event?.event,
      event?.name,
      event?.index,
      hyperlinkAttribute,
      txnDataIndex
    );
  }
  setBrowserName() {
    let browserName1: any;
    if (!this.browserName) {
      browserName1 = navigator.userAgent;
      /* istanbul ignore else */
      if (browserName1?.includes('Firefox')) {
        this.browserName = 'Firefox';
      } else if (browserName1?.includes('OPR')) {
        this.browserName = 'OPR';
      } else if (browserName1?.includes('Safari')) {
        this.browserName = 'Safari';
      }
    }
  }
  openHyperLink(position: number, txnIndex: number) {
    let value: any;
    this.transactionData[
      txnIndex
    ]?.transEntityRecords[0]?.txnNslAttribute?.forEach((attr: any) => {
      if (attr.name == this.hyperlinkAttribute?.[position]) {
        value = attr.values;
      }
    });
    window.open(value, '_blank');
  }
  addOnClick(event: any) {
    if (this.hyperlinkAttribute?.[event?.position]) {
      this.openHyperLink(event?.position, event?.txnDataIndex);
       event?.event?.stopPropagation();
    } else if (this.gsiAttrMap?.[event?.position]) {
      this.executeCard(
        this.gsiAttrMap[event?.position],
        event?.card,
        event?.txnDataIndex,
        event?.event
      );
       event?.event?.stopPropagation();
    }
  }

  openCreateEqlModal() {
    const dialogRef = this.dialog.open(CreateEqlComponent, {
      width: 'auto',
      maxHeight: '80vh',
      position: {
        top: '50px',
      },
      panelClass: 'ruleset-model',
    });
    dialogRef.componentInstance._primaryEntity = this.cardDetails?.primaryEntity;
    dialogRef.componentInstance.cardId = this.cardDetails?.id;
  }

  editFilter(cardId: string, filter: FilterTable) {
    this.entityBoardEndpointService
      .getFilterById(cardId, filter?.id, 'sharable')
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        if (res?.result?.savedQuery?.searchQuery) {
          const dialogRef = this.dialog.open(CreateEqlComponent, { panelClass: 'rulesetpopup' });
          dialogRef.componentInstance.cardId = this.cardDetails?.id;
          dialogRef.componentInstance.filterName = filter?.name;
          dialogRef.componentInstance.onlyEditRuleSet = true;
          dialogRef.componentInstance.screen = 1;
          dialogRef.componentInstance.filterIds = [filter?.id];
          dialogRef.componentInstance._searchQuery = {
            primaryEntity: this.cardDetails?.primaryEntity,
            searchQuery: res?.result?.savedQuery?.searchQuery,
          };
        }
      });
  }

  shareFilter(filter: FilterTable) {
    const dialogRef = this.dialog.open(CreateEqlComponent, {
      width: 'auto',
      position: {
        top: '50px',
      },
    });
    dialogRef.componentInstance._primaryEntity = this.cardDetails?.primaryEntity;
    dialogRef.componentInstance.cardId = this.cardDetails?.id;
    dialogRef.componentInstance._filterIds = [filter.id];
  }
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
@Pipe({
  name: 'primeNgPipe',
})
export class PrimeNgPipe implements PipeTransform {
  transform(value: string) {
    // for(let config of primeNgConfigs.default) {
    //   if(config.name === value)
    //     return config;
    // }
    return primeNgConfigs[value];
  }
}

@Pipe({
  name: 'keyName',
})
export class KeyNamePipe implements PipeTransform {
  transform(config: any, key: string) {
    return config.config[key];
  }
}
interface searchResultApi {
  message: string;
  result: string[];
  status: number;
}
type SearchValue = {
  selected: boolean;
  value: string;
};
