import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter, Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService, EntityBoardEndpointService, EntityBoardFacadeService, EntityEndpointService, LoaderService, RolesEndpointService, RolesFacadeService, TranslatorService } from '@common-services';
import { CardDropComponent } from '../card-drop/card-drop.component';
import { Templates1Component } from './templates1/templates1.component';

@Component({
  selector: 'app-kanban-configuration',
  templateUrl: './kanban-configuration.component.html',
  styleUrls: ['./kanban-configuration.component.scss'],
})
export class KanbanConfigurationComponent extends CardDropComponent {
  @ViewChild('kanbanBoardConfiguration', { read: ViewContainerRef }) kanbanBoardConfiguration: ViewContainerRef;
  @Output() kanbanStatusEmitter: any = new EventEmitter();
  constructor(
    protected router: Router,
    protected rolesFacadeService: RolesFacadeService,
    protected alertService: AlertService,
    protected entityBoardService: EntityBoardEndpointService,
    public dialog: MatDialog,
    protected loader: LoaderService,
    protected el: ElementRef,
    protected entityEndpointService: EntityEndpointService,
    protected entityBoardFacade: EntityBoardFacadeService,
    protected translator: TranslatorService,
    protected resolver: ComponentFactoryResolver
  ) {
    super(
      router,
      rolesFacadeService,
      alertService,
      entityBoardService,
      dialog,
      loader,
      el,
      entityEndpointService,
      entityBoardFacade,
      translator,
      resolver
    );
  }

  ngAfterViewInit(): void {
    const component = Templates1Component;
    const factory = this.resolver?.resolveComponentFactory(component);
    this.componentRef = this.kanbanBoardConfiguration?.createComponent(factory);
    this.attachVariablesToComponent(this.componentRef);
    this.subscribeToOutputVariables(this.componentRef);
    this.componentRef.instance?.kanbanStatusEmitter.subscribe((event: any) => {
      this.emitKanbanStatusEmitter(event);
    });
  }

  emitKanbanStatusEmitter(event: any) {
    this.kanbanStatusEmitter.emit(event);
  }
}
