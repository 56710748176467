<div class="nhrowInner">
  <div class="nhrowInner_col nhrowInner_left">
    <div class="nhCard_box">
      <div class="nhCard_box-header">
        <h3>Advance Features</h3>
        <!-- add dynamic label -->
      </div>
      <div class="nhCard_box-content">
        <div class="advancedFeatures-Accordian">
          <div class="advancedFeatures-Accordian-inner">
            <ng-template [ngTemplateOutlet]="fieldNames" ,
              [ngTemplateOutletContext]="{ data: { type: 'Sort', value: 'sort' } }">
            </ng-template>
            <ng-template *ngIf="showFacetFilter" [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'SearchFields', value: 'searchFields' } }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'Filter', value: 'filter' } }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'Search', value: 'search' } }">
            </ng-template>
            <ng-template *ngIf="!isEntityTable" [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'LocationFilter', value: 'locationFilter' } }">
            </ng-template>
            <ng-template *ngIf="!isEntityTable" [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'UrlBinding', value: 'urlBinding' } }">
            </ng-template>
            <ng-template *ngIf="!isEntityCard" [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'Export', value: 'export' } }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="fieldNames" *ngIf="isEntityTable"
              [ngTemplateOutletContext]="{ data: { type: 'Import', value: 'import' } }">
            </ng-template>
            <ng-template *ngIf="isEntityTable !== true" [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'HiddenFilters', value: 'hiddenFilter' } }">
            </ng-template>
            <ng-template *ngIf="isEntityTable !== true" [ngTemplateOutlet]="fieldNames"
              [ngTemplateOutletContext]="{ data: { type: 'Chatbot', value: 'chatBot' } }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="fieldNames" *ngIf="isEntityTable"
              [ngTemplateOutletContext]="{ data: { type: 'ChooseColumns', value: 'chooseColumns' } }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="fieldNames" *ngIf="isEntityTable"
              [ngTemplateOutletContext]="{ data: { type: 'Interactions', value: 'filtersConfig' } }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="fieldNames" *ngIf="!isEntityTable"
              [ngTemplateOutletContext]="{ data: { type: 'Card Version', value: 'cardVersion' } }">
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="nhrowInner_col nhrowInner_right">
    <div class="advanced-features">
      <div class="sort-option adfeatures"
        *ngIf="advancedFilterOpenLabel === 'Sort' && advancedFeatures.sort.checked && !isEntityTable">
        <div class="sort-option adfeatures sort-combination">
          <!-- Above Add sort-combination class for combination sort -->
          <h3 class="heading" id="sortFilter1">{{ labels?.Entity_Sort }}</h3>
          <p class="text" id="sortAttributes1">{{ labels?.Select_the_attributes_for_sort }}</p>

          <div class="btns">
            <button class="createbtn" (click)="sortCreate()">+ {{ labels?.Create }}</button>
            <!-- Will Add Combination Once API is Ready -->
            <!-- <button
          class="Individual"
          [ngStyle]="sortCreateFlag && sortCreateCombinationFlag ? { outline: '1px solid #1e96ff' } : {}"
          (click)="sortIndividualCombination(true, false)"
          *ngIf="sortCreateFlag"
        >
          Combination
        </button> -->
            <button class="Individual"
              [ngStyle]="sortCreateFlag && sortCreateIndividualFlag ? { outline: '1px solid #1e96ff' } : {}"
              (click)="sortIndividualCombination(false, true)" *ngIf="sortCreateFlag">
              {{ labels?.Individual }}
            </button>
          </div>

          <ul class="select-sort" *ngIf="sortCreateFlag && sortCreateIndividualFlag">
            <span *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index">
              <li [ngClass]="{
                  active: attr?.sort?.checked,
                  disabled: excludeUiControlList.includes(attr?.attributeType?.uiElement?.uiElement)
                }" (click)="openAttributePanel(attr, 'Sort', i)">
                {{ labels[attr?.name.replace(' ', '_')] ? labels[attr?.name.replace(' ', '_')] : attr?.name }}
                <button *ngIf="attr?.sort?.checked" class="cancel" (click)="deleteSortConfiguration(attr, $event)">
                  -
                </button>
              </li>
            </span>
          </ul>
          <!-- [ngClass]="{ active: attr?.sort?.checked, 'active-select': editBoxCheck == i }" (click)=""  (click)="deleteSortConfiguration(attr, $event)" -->
          <!-- Sort default Values -->
          <!-- <span *ngIf="sortCreateFlag && sortCreateIndividualFlag" (click)="openSortDialog(sortDefaultDialogBox)">{{
            labels?.default_values
          }}</span> -->

          <div class="combination" *ngIf="sortCreateFlag && sortCreateCombinationFlag">
            <p-panel pTooltip="{{ combi?.name }}" tooltipPosition="top" header="{{ combi?.name }}" [toggleable]="true"
              [collapsed]="true" *ngFor="let combi of combinationArray; let i = index"
              [ngClass]="{ active: combi?.checked, 'active-select': editBoxCheck == i }"
              (click)="openAttributePanel(combi, 'Sort', i)">
              <button class="save-delete del" (click)="deleteCombination(combi, i)">
                <i class="bi bi-trash"></i>
              </button>

              <!-- <button class="save-delete">save</button> -->
              <input type="text" class="searchbar" placeholder="Enter Combination Name" [value]="combi?.name"
                [(ngModel)]="combi.name" name="combinationName" />
              <!-- <input type="text" class="searchbar" placeholder="Search" />  value="Combination Name" [disabled]="attr.sort.disabled"   (checked)="sortCombiCheckedFunction(attr, $event)" [(ngModel)]="sortCombinationSelect" -->
              <div class="select-combination" *ngFor="let attr of combi?.nslAttributes">
                <div class="select-option">
                  <p-checkbox class="check-box" name="checkbox" [inputId]="attr.id" [value]="attr?.id"
                    [disabled]="attr?.sort?.disabled" (onChange)="sortCombiCheckedFunction(combi, attr, $event)"
                    [(ngModel)]="attr.sort.modelVariable">
                  </p-checkbox>
                  <span>
                    {{ labels[attr?.name.replace(' ', '_')] ? labels[attr?.name.replace(' ', '_')] : attr?.name }}
                  </span>
                </div>
              </div>
            </p-panel>
            <button class="add-more" id="addMore1" (click)="addNewCombination($event)">
              {{ labels?.Add_More }}
            </button>
          </div>
        </div>
      </div>
      <div class="attribute-sort-wrapper d-flex w-100"
        *ngIf="advancedFeatures.sort.checked && advancedFilterOpenLabel === 'Sort' && isEntityTable">
        <div class="d-flex align-items-center justify-content-between nh-mb-20 attribute-header">
          <p class="nh-fs-14 nh-lh-17 nh-fw-600 text-dark">Hide Sort for Attributes</p>
          <button (click)="getSortDetails()" class="btn-save">Save</button>
        </div>
        <!-- <div *ngFor="let attr of selectedEntityData?.nslAttributes"></div>
        <mat-checkbox [(ngModel)]="attr.checked" (change)="onCheckboxChange(item)">
          {{ attr.displayName ? attr.displayName : attr.name }}
        </mat-checkbox>
        </div> -->
        <form [formGroup]="formGroup"
          class="checkbox-group justify-content-space-between flex-wrap nh-column-gap-20 nh-row-gap-20">
          <ng-container *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            formArrayName="sortFields">
            <div class="attribute-sort">
              <mat-checkbox type="checkbox" [formControlName]="i" class="cstm-check">
                &nbsp;{{ attr.displayName ? attr.displayName : attr.name }}</mat-checkbox>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="sort-option adfeatures"
        *ngIf="advancedFilterOpenLabel === 'Filter' && advancedFeatures.filter.checked && !isEntityTable">
        <h3 class="heading" id="filterEntity2">{{ labels?.Entity_Filter }}</h3>
        <p class="text" id="filterAttributes2">{{ labels?.Select_the_attributes_for_Filter }}</p>
        <ul class="select-sort">
          <li [ngClass]="{ active: attr?.filter?.checked, 'active-select': editBoxCheck == i }"
            *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            (click)="openAttributePanel(attr, 'Filter', i)">
            {{ attr?.name }}
            <!-- Filter Default Values -->
            <!-- <span (click)="openRefEntDialog(filterDialogBox); $event.stopPropagation()" *ngIf="attr?.filter?.checked"
              >defualtValue</span
            > -->
            <button *ngIf="attr?.filter?.checked" class="cancel" (click)="deleteFilterConfiguration(attr, $event)">
              -
            </button>
          </li>
        </ul>
      </div>
      <div *ngIf="advancedFilterOpenLabel === 'Filter' && advancedFeatures.filter.checked && isEntityTable"
        class="attribute-sort-wrapper d-flex w-100">
        <div class="d-flex align-items-center justify-content-between nh-mb-20 attribute-header">
          <p class="nh-fs-14 nh-lh-17 nh-fw-600 text-dark">Hide Filter for Attributes</p>
          <button (click)="getFilterDetails()" class="btn-save">Save</button>
        </div>
        <form [formGroup]="formGroup"
          class="checkbox-group justify-content-space-between flex-wrap nh-column-gap-20 nh-row-gap-20">
          <ng-container class="mr-2" *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            formArrayName="filterFields">
            <div class="attribute-sort">
              <mat-checkbox type="checkbox" [formControlName]="i" class="cstm-check">
                &nbsp;{{ attr.displayName ? attr.displayName : attr.name }}</mat-checkbox>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="sort-option adfeatures"
        *ngIf="advancedFilterOpenLabel === 'LocationFilter' && advancedFeatures.locationFilter.checked">
        <h3 class="heading" id="locationFilterEntity1">{{ labels?.Location_Filter }}</h3>
        <p class="text" id="locationFilterAttr1">{{ labels?.Select_the_attributes_for_Location_Filter }}</p>
        <ul class="select-sort">
          <li [ngClass]="{
              active: attr?.locationFilter?.checked,
              'active-select': editBoxCheck == i,
              disabled: attr?.attributeType?.uiElement?.uiElement !== 'location'
            }" *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            (click)="openAttributePanel(attr, 'LocationFilter', i)">
            {{ attr?.name }}
            <button *ngIf="attr?.locationFilter?.checked" class="cancel"
              (click)="deleteLocationFilterConfiguration(attr, $event)">
              -
            </button>
          </li>
        </ul>
      </div>
      <div class="sort-option adfeatures"
        *ngIf="advancedFilterOpenLabel === 'SearchFields' && advancedFeatures.searchFields.checked && !isEntityTable">
        <h3 class="heading" id="searchFields_1">{{ labels?.Entity_Search_Fields }}</h3>
        <p class="text" id="searchFieldsAttr_1">{{ labels?.Select_the_attributes_for_Search_Fields }}</p>
        <ul class="select-sort">
          <li [ngClass]="{ active: attr?.searchFields?.checked, 'active-select': editBoxCheck == index }"
            *ngFor="let attr of selectedEntityData?.nslAttributes; let index = index" (click)="
              openAttributePanel(attr, 'SearchFields', index);
              openRefDateDialog(myrefEntDialog, myrefEntDialogForDate, attr?.attributeType?.uiElement?.uiElement)
            ">
            {{ attr?.name }}
            <!-- Search Fields Default Values -->
            <!-- <span (click)="openRefEntDialog(searchFieldsDialogBox, $event)" *ngIf="attr?.searchFields?.checked"
              >default Value</span
            > -->
            <button *ngIf="attr?.searchFields?.checked" class="cancel"
              (click)="deleteSearchFieldsConfiguration(attr, $event)">
              -
            </button>
          </li>
        </ul>
      </div>
      <div class="attribute-sort-wrapper d-flex w-100"
        *ngIf="advancedFilterOpenLabel === 'SearchFields' && advancedFeatures.searchFields.checked && isEntityTable">
        <div class="d-flex align-items-center justify-content-between nh-mb-20 attribute-header">
          <p class="nh-fs-14 nh-lh-17 nh-fw-600 text-dark">Hide Facet Fields for attributes</p>
          <button (click)="getFacetFieldDetails()" class="btn-save">Save</button>
        </div>
        <form [formGroup]="formGroup"
          class="checkbox-group justify-content-space-between flex-wrap nh-column-gap-20 nh-row-gap-20">
          <ng-container *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            formArrayName="facetFields">
            <div class="attribute-sort">
              <mat-checkbox type="checkbox" [formControlName]="i" class="cstm-check">
                &nbsp;{{ attr.displayName ? attr.displayName : attr.name }}</mat-checkbox>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="joined-entity-info joined-entity-line" *ngIf="advancedFilterOpenLabel === 'Chatbot'">
        <h5 class="joined-entity-title" id="Chatbot1">{{ labels?.Entity_Chatbot }}</h5>
        <div class="joined-entity-fileds" *ngIf="advancedFeatures.chatBot.checked">
          <mat-form-field appearance="standard">
            <mat-label>{{ labels?.Language }}</mat-label>
            <mat-select [(ngModel)]="chatbotData.language">
              <mat-option *ngFor="let language of languagesOptions" [value]="language.value">
                {{
                labels[language?.name.replace(' ', '_')] ? labels[language?.name.replace(' ', '_')] : language?.name
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ul class="intentsAdd">
            <li id="intent1">{{ labels?.Intents }}</li>
            <li>
              <figure class="plus-line" id="btnPlus" (click)="addIntent()">
                <img [src]="'assets/images/plus-icon.png' | CDNUrlRewritePipe" alt="" />
              </figure>
            </li>
          </ul>
          <div *ngFor="let intent of chatbotData.intents; let intentIndex = index" class="intent-info">
            <ul class="intentName">
              <li id="intent2">{{ labels?.Intent }} {{ intentIndex + 1 }}</li>
              <li>
                <img id="attrremoveimg" (click)="removeIntent(intentIndex)"
                  [src]="'assets/img/delete-icon.svg' | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
            <div class="intent-textarea">
              <textarea [(ngModel)]="intent.intent" placeholder="{{ labels?.Write_your_intent_here }}"
                rows="3"></textarea>
            </div>
            <b>{{ labels?.Filters }}</b>
            <ul class="filter-checkbox">
              <li *ngFor="let attr of intent.attributes">
                <div>
                  <span><mat-checkbox [(ngModel)]="attr.checked"></mat-checkbox></span>
                  <span class="attr-name">{{
                    labels[attr?.attributeName.replace(' ', '_')]
                    ? labels[attr?.attributeName.replace(' ', '_')]
                    : attr?.attributeName
                    }}</span>
                </div>
                <div>
                  <mat-radio-group class="min-max-radio" [(ngModel)]="attr.range">
                    <mat-radio-button value="MIN">{{ labels?.Min }}</mat-radio-button>
                    <mat-radio-button value="MAX">{{ labels?.Max }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sort-order adfeatures" *ngIf="attributeOpen === 'Filter' && advancedFeatures.filter.checked">
        <div class="select-range-type">
          <div class="range-select" *ngFor="let slider of customizeFilterTemplateSliderRange">
            <span class="range-name">{{ slider?.type }} {{ labels?.Point_Slider }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="slider?.id" [(ngModel)]="attributeSelect"
                [inputId]="slider?.templateId" [disabled]="attrDetails?.attributeType?.type !== 'number'"
                (click)="saveFilterAttrConfiguration()"></p-radioButton>
              <app-filter-templates [id]="slider?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let slider of customizeFilterTemplateSliderSingle">
            <span class="range-name">{{ slider?.type }} {{ labels?.Point_Slider }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="slider?.id" [(ngModel)]="attributeSelect"
                [inputId]="slider?.templateId" [disabled]="attrDetails?.attributeType?.type !== 'number'"
                (click)="saveFilterAttrConfiguration()"></p-radioButton>
              <app-filter-templates [id]="slider?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let chip of customChipSelectionSingle">
            <span class="range-name">{{ labels?.Chip }}
              {{ labels[chip?.dataType.replace(' ', '_')] ? labels[chip?.dataType.replace(' ', '_')] : chip?.dataType }}
              {{ labels?.Select }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="chip?.id" [(ngModel)]="attributeSelect"
                [inputId]="chip?.templateId" (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="chip?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let chip of customChipSelectionRange">
            <span class="range-name"
              *ngIf="chip?.borderRadius === 6 || chip?.borderRadius === 30 || chip?.borderRadius === 18">{{ labels?.Chip
              }}-{{
              labels[chip?.dataType.replace(' ', '_')] ? labels[chip?.dataType.replace(' ', '_')] : chip?.dataType
              }}
              {{ labels?.Select }}</span>
            <span class="range-name" *ngIf="chip?.borderRadius === 4">{{ labels?.Input_Field }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="chip?.id" [(ngModel)]="attributeSelect"
                [inputId]="chip?.templateId" [disabled]="attrDetails?.attributeType?.type !== 'number'"
                (click)="openNumberOfDivisions(divisionsDialogBox, $event)"></p-radioButton>

              <app-filter-templates [id]="chip?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let checkbox of customCheckBoxSingle">
            <span class="range-name">{{ labels?.Checkbox }}
              {{
              labels[checkbox?.dataType.replace(' ', '_')]
              ? labels[checkbox?.dataType.replace(' ', '_')]
              : checkbox?.dataType
              }}
              {{ labels?.Select }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="checkbox?.id" [(ngModel)]="attributeSelect"
                [inputId]="checkbox?.templateId" (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="checkbox?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let checkbox of customCheckBoxRange">
            <span class="range-name">{{ labels?.Checkbox }}
              {{
              labels[checkbox?.dataType.replace(' ', '_')]
              ? labels[checkbox?.dataType.replace(' ', '_')]
              : checkbox?.dataType
              }}
              {{ labels?.Select }}
            </span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="checkbox?.id" [(ngModel)]="attributeSelect"
                [inputId]="checkbox?.templateId" [disabled]="attrDetails?.attributeType?.type !== 'number'"
                (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="checkbox?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let toggleButtons of customizeToggleButtons">
            <span class="range-name">{{ labels?.Type }} {{ toggleButtons?.type }} {{ labels?.ToggleButton }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="toggleButtons?.id" [(ngModel)]="attributeSelect"
                [inputId]="toggleButtons?.templateId" (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="toggleButtons?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let radioButton of customizeRadioButton">
            <span class="range-name">{{ labels?.RadioButtons }} {{ labels?.Select }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="radioButton?.id" [(ngModel)]="attributeSelect"
                [inputId]="radioButton?.templateId" (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="radioButton?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let rating of customizeRatings">
            <span class="range-name">{{ labels?.Ratings }} {{ rating?.type }} {{ labels?.Select }} </span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="rating?.id" [(ngModel)]="attributeSelect"
                [inputId]="rating?.templateId"
                [disabled]="attrDetails?.attributeType?.uiElement?.uiElement !== 'rating'"
                (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="rating?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let date4 of customizeDateSingle">
            <span class="range-name">{{ labels?.Date }} {{ date4?.type }} {{ labels?.Select }} </span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="date4?.id" [(ngModel)]="attributeSelect"
                [inputId]="date4?.templateId" [disabled]="attrDetails?.attributeType?.uiElement?.uiElement !== 'date'"
                (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="date4?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let date4 of customizeDateRange">
            <span class="range-name">{{ labels?.Date }} {{ date4?.type }} {{ labels?.Select }} </span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="date4?.id" [(ngModel)]="attributeSelect"
                [inputId]="date4?.templateId" [disabled]="attrDetails?.attributeType?.uiElement?.uiElement !== 'date'"
                (click)="saveFilterAttrConfiguration()"></p-radioButton>

              <app-filter-templates [id]="date4?.id"></app-filter-templates>
            </div>
          </div>
        </div>
      </div>
      <div class="sort-option adfeatures"
        *ngIf="advancedFilterOpenLabel === 'Search' && advancedFeatures.search.checked && !isEntityTable">
        <h3 class="heading">{{ labels?.Entity_Search }}</h3>
        <p class="text">{{ labels?.Select_the_attributes_for_Search }}</p>
        <ul class="select-sort">
          <li [ngClass]="{ active: attr?.search?.checked }"
            *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            (click)="openAttributePanel(attr, 'Search', i)">
            {{ labels[attr?.name.replace(' ', '_')] ? labels[attr?.name.replace(' ', '_')] : attr?.name }}
            <button *ngIf="attr.search.checked" class="cancel" (click)="deleteSaveConfiguration(attr, $event)">
              -
            </button>
          </li>
        </ul>
      </div>
      <div class="sort-order adfeatures" *ngIf="attributeOpen === 'Sort' && advancedFeatures.sort.checked">
        <div class="select-range-type">
          <div class="range-select" *ngFor="let dateAndCost of customizeDateAndCost">
            <span class="range-name">{{ dateAndCost?.type }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="radiobox" [value]="dateAndCost?.id" [(ngModel)]="attributeSelect"
                [inputId]="dateAndCost?.templateId" (click)="saveSortCombinationAttrConfiguration()"></p-radioButton>
              <app-filter-templates [id]="dateAndCost?.id"></app-filter-templates>
            </div>
          </div>
        </div>
      </div>
      <div class="sort-order adfeatures"
        *ngIf="attributeOpen === 'SearchFields' && advancedFeatures.searchFields.checked">
        <div class="select-range-type">
          <div class="range-select" *ngFor="let location of customizeLocation">
            <span class="range-name">{{
              labels[location?.type.replace(' ', '_')] ? labels[location?.type.replace(' ', '_')] : location?.type
              }}
            </span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="location?.id" [(ngModel)]="attributeSelect"
                [inputId]="location?.templateId" (click)="saveSearchFieldsConfiguration()"></p-radioButton>
              <app-filter-templates [id]="location?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let date2 of customizeDate">
            <span class="range-name">{{ labels[date2?.type.replace(' ', '_')] ? labels[date2?.type.replace(' ', '_')] :
              date2?.type }}
            </span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="date2?.id" [(ngModel)]="attributeSelect"
                [inputId]="date2?.templateId" [disabled]="attrDetails?.attributeType?.uiElement?.uiElement !== 'date'"
                (click)="saveSearchFieldsConfiguration()"></p-radioButton>
              <app-filter-templates [id]="date2?.id"></app-filter-templates>
            </div>
          </div>
          <div class="range-select" *ngFor="let filter1 of customizefilter">
            <span class="range-name">{{ filter1?.type }}</span>
            <div class="choose-range">
              <p-radioButton class="radio-box" name="" [value]="filter1?.id" [(ngModel)]="attributeSelect"
                [inputId]="filter1?.templateId" (click)="saveSearchFieldsConfiguration()"></p-radioButton>
              <app-filter-templates [id]="filter1?.id"></app-filter-templates>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="advancedFeatures.search.checked && isEntityTable && attributeOpen === 'Search'"
        class="attribute-sort-wrapper d-flex w-100">
        <div class="d-flex align-items-center justify-content-between nh-mb-20 attribute-header">
          <p class="nh-fs-14 nh-lh-17 nh-fw-600 text-dark">Hide Search for attributes</p>
          <button (click)="getSearchDetails()" class="btn-save">Save</button>
        </div>
        <form [formGroup]="formGroup"
          class="checkbox-group justify-content-space-between flex-wrap nh-column-gap-20 nh-row-gap-20">
          <ng-container class="mr-2" *ngFor="let attr of selectedEntityData?.nslAttributes; let i = index"
            formArrayName="searchFields">
            <div class="attribute-sort">
              <mat-checkbox type="checkbox" [formControlName]="i" class="cstm-check">
                &nbsp;{{ attr.displayName ? attr.displayName : attr.name }}</mat-checkbox>
            </div>
          </ng-container>
        </form>
      </div>
      <div class="sort-order adfeatures"
        *ngIf="attributeOpen === 'Search' && advancedFeatures.search.checked && !isEntityTable">
        <div class="select-range-type">
          <div class="range-select" *ngFor="let location of customizeLocation">
            <span class="range-name" *ngIf="location?.type === 'Search Template'">{{
              labels[location?.type.replace(' ', '_')] ? labels[location?.type.replace(' ', '_')] : location?.type
              }}
            </span>
            <div class="choose-range" *ngIf="location?.type === 'Search Template'">
              <p-radioButton class="radio-box" name="" [value]="location?.id" [(ngModel)]="attributeSelect"
                [inputId]="location?.templateId" (onClick)="saveSearchAttrConfiguration()"></p-radioButton>
              <app-filter-templates [id]="location?.id"></app-filter-templates>
            </div>
          </div>
        </div>
      </div>
      <div class="sort-order adfeatures"
        *ngIf="attributeOpen === 'LocationFilter' && advancedFeatures.locationFilter.checked">
        <div class="select-range-type entity-location-filter">
          <div class="range-select">
            <span class="range-name">{{ labels?.Enter_Location_Radius }}</span>
            <div class="entity-location-filter">
              <input type="number" class="input-search" autocomplete="off"
                [(ngModel)]="attrDetails.locationFilter.attributeRadius"
                [value]="attrDetails.locationFilter.attributeRadius" (keydown)="onKeydown($event)"
                placeholder="Enter Radius (km)" />
              <button class="save-btn" (click)="saveLocationFilterAttrConfiguration()">save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="joined-entity-info joined-entity-line"
        *ngIf="attributeOpen === 'UrlBinding' && advancedFeatures.urlBinding.checked">
        <h5 class="joined-entity-title">{{ labels?.Entity_Url_Binding }}</h5>
        <div class="urlBinding">
          <mat-form-field>
            <mat-label>{{ labels?.Select_Gsi }}</mat-label>
            <mat-select id="selectedgsi" [(ngModel)]="urlBindingGsi" name="selected gsi">
              <mat-option id="gsilist" class="overflow-auto" *ngFor="let gsi of childGsiList" [value]="gsi">
                {{ gsi.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ labels?.Select_Attribute }}</mat-label>
            <mat-select [(ngModel)]="urlBindingAttribute" name="selected attribute">
              <mat-option class="overflow-auto" *ngFor="let attr of dropMappedAttributes" [value]="attr">
                {{ attr?.name ? attr?.name : attr }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="color-customisation" *ngIf="!isEntityCard">
            <ul>
              <li (click)="toggleColorPicker()">
                <p>{{ labels?.Attribute_Color || 'Attribute Color' }}</p>
                <p class="box-color">
                  <span class="color-fill" [ngStyle]="{ 'background-color': colorModel }"></span>
                  <i class="bi bi-eyedropper"></i>
                </p>
              </li>
              <span *ngIf="colorPickerToggler" [cpPositionRelativeToArrow]="true" [cpDialogDisplay]="'inline'"
                (colorPickerChange)="changeColor($event)" [(colorPicker)]="colorModel" [cpOKButton]="true"
                (colorPickerSelect)="toggleColorPicker()" [cpToggle]="true" [cpOKButtonText]="'Done'">
              </span>
            </ul>
          </div>
          Through next build push url binding will be aviable with respective to configuration name onlyy 
          <mat-slide-toggle [(ngModel)]="isConfigName">Route through Configuration</mat-slide-toggle>
        </div>
      </div>
      <!--Hidden Filters Start-->
      <div class="sort-order adfeatures"
        *ngIf="attributeOpen === 'HiddenFilters' && advancedFeatures.hiddenFilter.checked">
        <div class="default-search">
          <h3 class="heading">{{ labels?.Hidden_Filters }}</h3>
          <app-rule-set [(hiddenRuleSet)]="hiddenRuleSet" [selectedEntityData]="selectedEntityData"> </app-rule-set>
        </div>
      </div>
      <!--Hidden Filters End-->
    </div>
  </div>
</div>

<ng-template #myrefEntDialogForDate>
  <div mat-dialog-title class="wrapper-top">
    <h1 class="pop-heading">
      {{ labels?.Select_Nested_Attribute ? labels?.Select_Nested_Attribute : 'Select Nested Attribute' }}
    </h1>
    <div class="pop-btns">
      <i class="bi bi-x-lg" (click)="saveSearchFields(false)"></i>
    </div>
  </div>
  <div class="reference-content" mat-dialog-content>
    <div class="wrapper-bottom">
      <div class="wrapper-bottom-left">
        <h3 class="select-entity-heading">{{ labels?.Attribute }}</h3>
        <div class="dropdown-wrapper">
          <span class="select-box select-entity-dropdown" *ngIf="attrDetails?.['searchFields']?.refEntityOpen">
            <mat-form-field appearance="outline">
              <mat-label>{{
                labels?.labels?.Select_Nested_Attribute ? labels?.Select_Nested_Attribute : 'Select Nested Attribute'
                }}</mat-label>
              <mat-select #select="matSelect" [(ngModel)]="attrDetails['searchFields'].selectNestedAttribute"
                name="reference entity">
                <mat-option class="overflow-auto" *ngFor="let attr of selectedEntityData?.nslAttributes"
                  [value]="attr?.name"
                  [ngClass]="{'custom-disabled-option' : attr?.attributeType?.uiElement?.uiElement != 'number' && attr?.attributeType?.uiElement?.uiElement != 'decimal' && attr?.attributeType?.uiElement?.uiElement != 'currency'}">
                  {{ attr?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="wrapper-bottom-right">
        <h3 class="select-attr-heading" *ngIf="attrDetails['searchFields']?.selectNestedAttribute">Select Function</h3>
        <div class="dropdown-wrapper">
          <span class="select-box select-attr" *ngIf="
              attrDetails?.['searchFields']?.refEntityOpen &&
              attrDetails?.['searchFields']?.selectNestedAttribute
            ">
            <mat-form-field appearance="outline">
              <mat-label>Select Function</mat-label>
              <mat-select #select1="matSelect" [(ngModel)]="attrDetails['searchFields'].selectNestedAttributeFunction"
                name="reference entity">
                <mat-option *ngFor="let refAttr of ['MIN', 'MAX']" [value]="refAttr">
                  {{ refAttr }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
      </div>
    </div>
    <div class="wrapper-bottom"
      *ngIf="attrDetails?.['searchFields']?.refEntityOpen && attrDetails?.['searchFields']?.selectNestedAttribute">
      <div class="wrapper-bottom-left">
        <h3 class="select-entity-heading mt-3">
          {{ labels?.labels?.No_Suffix_Value ? labels?.Suffix_Value : 'Suffix Value' }}
        </h3>
        <input class="suffix-value" [(ngModel)]="attrDetails['searchFields'].selectNestedAttributeNoSuffixValue" />
      </div>
    </div>
  </div>
  <mat-dialog-actions align="center" class="advfeatures-btn-grp">
    <button mat-button class="popup-save-btn" (click)="deleteSearchFieldsEntity()">Reset</button>
    <button mat-button mat-dialog-close class="popup-save-btn" (click)="saveSearchFields(true)">
      {{ labels?.Save }}
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #myrefEntDialog>
  <div mat-dialog-title class="wrapper-top">
    <h1 class="pop-heading">{{ labels?.Reference_Entity }}</h1>
    <div class="pop-btns">
      <i class="bi bi-x-lg" (click)="saveSearchFields(false)"></i>
    </div>
  </div>
  <div class="reference-content" mat-dialog-content>
    <div class="wrapper-bottom">
      <div class="wrapper-bottom-left">
        <h3 class="select-entity-heading nh-fs-22">{{ labels?.Select_Entity }}</h3>
        <div class="dropdown-wrapper">
          <span class="select-box select-entity-dropdown" *ngIf="attrDetails?.['searchFields']?.refEntityOpen">
            <mat-form-field appearance="outline">
              <mat-label>{{ labels?.Select_Reference_Entity }}</mat-label>
              <mat-select #select="matSelect" [(ngModel)]="attrDetails['searchFields'].selectedRefValue"
                name="reference entity" (selectionChange)="getAllattributesById($event, searchFieldsIndex)" placeholder="{{
                  attrDetails['searchFields'].selectedRefValue ? attrDetails['searchFields'].selectedRefValue.name : ''
                }}" panelClass="sel-ref-entity-popup">
                <span class="searchbar">
                  <input type="text" class="input-search" [(ngModel)]="searchTextRefEntity[searchFieldsIndex]"
                    autocomplete="off" [ngModelOptions]="{ standalone: true }" placeholder="{{ labels?.Search }}"
                    (ngModelChange)="onSearchRefEnt($event, searchFieldsIndex, attrDetails)" id="searchText" />
                  <em class="nh-icon nh-icon-search-line"></em>
                </span>
                <div class="dropdown-options" infinite-scroll (scrolled)="onScrollDown($event, searchFieldsIndex)">
                  <mat-option class="overflow-auto"
                    *ngFor="let refEnt of referenceEntitiesSearchFields?.[searchFieldsIndex]" [value]="refEnt">
                    {{ refEnt.name }}
                  </mat-option>
                  <!-- <app-paginator
                    [pageIndex]="refEntPageNumber?.[searchFieldsIndex] - 1"
                    [pageSize]="10"
                    [totalRecords]="refEntTotalRecords?.[searchFieldsIndex]"
                    (onPageChange)="onRefEntityPageChange($event, searchFieldsIndex)"
                  >
                  </app-paginator> -->
                </div>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
      </div>
      <div class="wrapper-bottom-right">
        <h3 class="select-attr-heading nh-fs-22" *ngIf="attrDetails?.['searchFields']?.selectedRefValue">Select
          Attribute</h3>
        <div class="dropdown-wrapper">
          <span class="select-box select-attr" *ngIf="
              attrDetails?.['searchFields']?.refEntityOpen &&
              attrDetails?.['searchFields']?.selectedRefValue
            ">
            <mat-form-field appearance="outline">
              <mat-label>Select Attribute Value</mat-label>
              <mat-select #select1="matSelect" [(ngModel)]="attrDetails['searchFields'].selectedRefAttrValue"
                name="reference entity" panelClass="sel-ref-entity-popup">
                <span class="searchbar">
                  <input type="text" placeholder="Search Attributes" [(ngModel)]="attributeValue"
                    (keyup)="searchAttributes()" />
                  <em class="nh-icon nh-icon-search-line"></em>
                </span>
                <div class="dropdown-options">
                  <mat-option *ngFor="let refAttr of targetRefAttr?.[searchFieldsIndex]" [value]="refAttr">
                    <ng-container *ngIf="refAttr?.isSearched">
                      {{ refAttr?.displayName ? refAttr?.displayName : refAttr?.name }}
                    </ng-container>
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </span>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="center" class="advfeatures-btn-grp">
    <button mat-button class="popup-save-btn" (click)="deleteSearchFieldsEntity()">Reset</button>
    <button mat-button mat-dialog-close class="popup-save-btn" (click)="saveSearchFields(true)">
      {{ labels?.Save }}
    </button>
  </mat-dialog-actions>
</ng-template>
<ng-template #filterDialogBox>
  <div mat-dialog-content>
    <div class="wrapper-top">
      <h1 class="pop-heading">{{ labels?.Default_Value }}</h1>
      <div class="pop-btns">
        <button class="popup-save-btn" (click)="saveFilterDefaultValue()">{{ labels?.Save }}</button>
        <i class="bi bi-x-lg" (click)="closeDialog()"></i>
      </div>
    </div>
    <div class="wrapper-bottom">
      <input type="text" placeholder="Enter Default Value" [value]="filterDefaultValue"
        [(ngModel)]="filterDefaultValue" />
    </div>
  </div>
</ng-template>
<ng-template #searchFieldsDialogBox>
  <div mat-dialog-content>
    <div class="wrapper-top">
      <h1 class="pop-heading">{{ labels?.Default_Value }}</h1>
      <div class="pop-btns">
        <button class="popup-save-btn" (click)="saveSearchFieldsDefaultValue()">{{ labels?.Save }}</button>
        <i class="bi bi-x-lg" (click)="closeDialog()"></i>
      </div>
    </div>
    <div class="wrapper-bottom">
      <input type="text" placeholder="Enter Default Value" [value]="searchFieldsDefaultValue"
        [(ngModel)]="searchFieldsDefaultValue" />
    </div>
  </div>
</ng-template>
<ng-template #sortDefaultDialogBox>
  <div mat-dialog-content>
    <div class="wrapper-top">
      <h1 class="pop-heading">{{ labels?.Default_Value }}</h1>
      <div class="pop-btns">
        <button class="popup-save-btn" (click)="saveSortDefaultValue()">{{ labels?.Save }}</button>
        <i class="bi bi-x-lg" (click)="closeDialog()"></i>
      </div>
    </div>
    <div class="wrapper-bottom">
      <ul class="sortulentity sort-section">
        <li>
          <mat-radio-group aria-label="Select sort option" [(ngModel)]="sortDefaultOption">
            <mat-radio-button [value]="1">{{ labels?.Ascending }}</mat-radio-button>
            <mat-radio-button [value]="2">{{ labels?.Descending }}</mat-radio-button>
          </mat-radio-group>
        </li>
      </ul>

      <div class="attr-list-sort sort-common">
        <mat-radio-group aria-label="Select sort option" [(ngModel)]="sortDefaultAttr">
          <mat-radio-button [value]="attr" *ngFor="let attr of individualSelectedAttr; let i = index">{{
            attr
            }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</ng-template>
<!--
  * This ng-template decides the common Controls for all Advanced Features
  * like inputs, checkboxes.
-->
<ng-template #fieldNames let-data="data">
  <div class="advancedFeatures-Accordian-item"
    [ngClass]="{ 'advancedFeatures-Accordian-item-active': advancedFilterOpenLabel === data?.type }"
    (click)="selectAdvancedFilter(data?.type)">
    <div class="advancedFeatures-Accordian-item-header">
      <label>{{ labels[data?.type.replace(' ', '_')] ? labels[data?.type.replace(' ', '_')] : data?.type }}</label>
    </div>
    <div class="advancedFeatures-Accordian-item-content"
      *ngIf="advancedFilterOpenLabel === data?.type && data?.type != 'Card Version'" (click)="$event.stopPropagation()">
      <div class="advancedFeatures-toggle d-flex align-items-center justify-content-between"
        *ngIf="data?.type !== 'Interactions'">
        <label class="advancedFeatures-toggle-label">Enable</label>
        <label class="switch">
          <input type="checkbox" [(ngModel)]="advancedFeatures[data.value].checked" />
          <span class="switch-slider"></span>
        </label>
        <!-- <mat-slide-toggle
              [(ngModel)]="advancedFeatures[data.value].checked"
              disableRipple
              ></mat-slide-toggle
            > -->
      </div>
      <div class="innercontent"
        *ngIf="(advancedFilterOpenLabel === data?.type && advancedFeatures?.[data?.value]?.checked) || data?.type === 'Interactions'">
        <div class="chooseplacement"
          *ngIf="advancedFeatures?.[data?.value]?.top?.position || advancedFeatures?.[data?.value]?.header?.position || advancedFeatures?.[data?.value]?.side?.position">
          <h5>Choose Placement</h5>
          <div class="chooseplacement_inner d-flex nh-text-center">
            <div class="chooseplacement-item" *ngIf="advancedFeatures?.[data?.value]?.top?.position && !isEntityTable">
              <button class="cursor-pointer d-block"
                [ngClass]="{ active: advancedFeatures?.[data?.value]?.top?.checked }"
                (click)="checkPosition(advancedFeatures?.[data?.value],'top')">
                <em class="nh-icon nh-icon-position-top"></em>
              </button>
              <span class="d-block">Top</span>
            </div>
            <div class="chooseplacement-item" *ngIf="!isEntityCard">
              <button class="cursor-pointer d-block"
                [ngClass]="{ active: advancedFeatures?.[data?.value]?.header?.checked }"
                (click)="checkPosition(advancedFeatures?.[data?.value],'header')">
                <em class="nh-icon nh-icon-position-header"></em>
              </button>
              <span class="d-block">Header</span>
            </div>
            <div class="chooseplacement-item" *ngIf="advancedFeatures?.[data?.value]?.side?.position&&!isEntityTable">
              <button class="cursor-pointer d-block"
                [ngClass]="{ active: advancedFeatures?.[data?.value]?.side?.checked }"
                (click)="checkPosition(advancedFeatures?.[data?.value],'side')">
                <em class="nh-icon nh-icon-position-side"></em>
              </button>
              <span class="d-block">Side</span>
            </div>
          </div>
          <div></div>
        </div>
        <div class="advancedFeatures-tabs">
          <div *ngFor="let tabValue of advancedFeatures?.[data?.value]?.tabValues">
            <h4>
              {{ tabValue.displayName }}
            </h4>
            <div class="advancedFeatures-tabs-inner">
              <button [ngClass]="{ active: value.checked }" class="cursor-pointer"
                (click)="selectTab($event, tabValue.values, i)" *ngFor="let value of tabValue.values; let i = index">
                {{ value.displayValue }}
              </button>
            </div>
          </div>
        </div>

        <div class="advancedFeatures-field-alignment" *ngFor="let style of advancedFeatures?.[data?.value]?.styles">
          <div class="advancedFeatures-field-alignment_inner d-flex align-items-center justify-content-between"
            *ngIf="style.actualValue === 'alignment'">
            <label>{{ style.displayValue }}</label>
            <div class="field-alignment-btns d-flex" *ngIf="!isEntityTable">
              <button class="cursor-pointer" (click)="alignmentCheck($event, style, 'left')"
                [ngClass]="{ active: style?.value === 'left' }">
                <em class="nh-icon nh-icon-text-left"></em>
              </button>
              <button class="cursor-pointer" (click)="alignmentCheck($event, style, 'right')"
                [ngClass]="{ active: style?.value === 'right' }">
                <em class="nh-icon nh-icon-text-right"></em>
              </button>
            </div>
            <div class="field-alignment-btns d-flex" *ngIf="isEntityTable">
              <button class="cursor-pointer" (click)="alignmentCheck($event, style, 'left')"
                [ngClass]="{ active: style?.value === 'left' }">
                <em class="nh-icon nh-icon-position-top"></em>
              </button>
              <button class="cursor-pointer" (click)="alignmentCheck($event, style, 'right')"
                [ngClass]="{ active: style?.value === 'right' }">
                <em class="nh-icon nh-icon-position-side"></em>
              </button>
            </div>
          </div>
        </div>
        <div class="checkoxlist nh-mb-16" *ngIf="advancedFeatures?.[data?.value]?.checkBoxValues">
          <div class="advancedFeatures-checkbox"
            *ngFor="let checkBoxValue of advancedFeatures?.[data?.value]?.checkBoxValues">
            <label class="advancedFeatures-checkbox-label">
              <input type="checkbox" [(ngModel)]="checkBoxValue.checked" />
              <span class="advancedFeatures-checkbox-mark"></span>
              {{ checkBoxValue?.displayValue }}
            </label>
            <!-- <mat-checkbox
                *ngFor="let checkBoxValue of advancedFeatures?.[data?.value]?.checkBoxValues"
                [(ngModel)]="checkBoxValue.checked"
                disableRipple
                >{{ checkBoxValue?.displayValue }}</mat-checkbox
              > -->
          </div>
        </div>
        <div class="advancedFeatures-toggle-listing" *ngIf="advancedFeatures?.[data?.value]?.toggleValues">
          <div
            class="advancedFeatures-toggle advancedFeatures-toggle-inner d-flex align-items-center justify-content-between"
            *ngFor="let toggleValue of advancedFeatures?.[data?.value]?.toggleValues">
            <label class="advancedFeatures-toggle-label">{{ toggleValue?.displayValue }}</label>
            <label class="switch">
              <input type="checkbox" [(ngModel)]="toggleValue.checked" />
              <span class="switch-slider"></span>
            </label>
            <!-- <mat-slide-toggle
                  [(ngModel)]="advancedFeatures[data.value].checked"
                  disableRipple
                  ></mat-slide-toggle
                > -->
          </div>
          <!-- <mat-slide-toggle
              *ngFor="let toggleValue of advancedFeatures?.[data?.value]?.toggleValues"
              [(ngModel)]="toggleValue.checked"
              disableRipple
              >{{ toggleValue?.displayValue }}</mat-slide-toggle
            > -->
        </div>
        <ng-container *ngIf="advancedFeatures?.[data?.value]?.header?.checked">
          <ng-container *ngFor="let icon of advancedFeatures?.[data?.value]?.icon">
            <div class="advancedFeatures-toggle advancedFeatures-toggle-inner d-flex align-items-center justify-content-between">
              <label class="advancedFeatures-toggle-label"> Upload {{ icon?.displayValue }}</label>
              <label class="switch">
                <input type="checkbox" [(ngModel)]="icon.checked" />
                <span class="switch-slider"></span>
              </label>
            </div>
            <div class="upload-section">
              <ul>
              <li class="items">
              <article class="box"  >
                <ng-container *ngIf="icon.checked">
                  <div class="box-left">
                    <!-- <p class="box-title text-color">{{icon.displayValue}} </p> -->
                    <small class="text-color">file format: png, svg</small>
                    <div class="btn-upload" (change)="uplaodIcon($event,icon)">
                      <input type="file" placeholder="Attachment" />
                      <button class="btn-acc btn-dark" type="button">
                        Upload
                      </button>
                    </div>
                  </div>
                  <div class="box-right">
                    <div class="close-icon">
                      <em class="nh-icon nh-icon-x-circle" (click)="deleteIcon(icon)"></em>
                    </div>
                    <div class="box-icon">
                      <img
                      [src]="icon.value"
                        alt="calender"
                      />
                    </div>
                  </div>
                </ng-container>
              </article>
              </li>
              </ul>
            </div>
          </ng-container>

        </ng-container>
        <div class="advancedFeatures-input-field"
          *ngFor="let inputValue of advancedFeatures?.[data?.value]?.inputValues">
          <div *ngIf="inputValue?.type === 'text'">
            <label class="advancedFeatures-input-field-label nh-display-block">{{ inputValue?.displayValue }}</label>
            <input class="advancedFeatures-input-field-control nh-mb-8" [(ngModel)]="inputValue.value"
              [type]="inputValue?.type" />
            <span class="helper">06 /30</span>
          </div>
          <div *ngIf="inputValue?.type === 'number'">
            <label class="advancedFeatures-input-field-label nh-display-block">{{ inputValue?.displayValue }}</label>
            <div class="position-relative numberField">
              <button class="numberField-btn numberField-btn-minus position-absolute">
                <em class="nh-icon nh-icon-dash-lg"></em>
              </button>
              <input class="advancedFeatures-input-field-control nh-text-center" [(ngModel)]="inputValue.value"
                [type]="inputValue?.type" />
              <button class="numberField-btn numberField-btn-plus position-absolute">
                <em class="nh-icon nh-icon-plus-lg"></em>
              </button>
            </div>
          </div>
        </div>
        <div class="checkoxlist nh-mb-16" *ngIf="data?.type === 'Interactions'">
          <div class="advancedFeatures-checkbox">
            <label class="advancedFeatures-checkbox-label">
              <input type="checkbox" [(ngModel)]="advancedFeatures[data.value].hover" />
              <span class="advancedFeatures-checkbox-mark"></span>
              Show Icons on Hover
            </label>
          </div>
          <div class="advancedFeatures-checkbox">
            <label class="advancedFeatures-checkbox-label">
              <input type="checkbox" [(ngModel)]="advancedFeatures[data.value].nextRow" />
              <span class="advancedFeatures-checkbox-mark"></span>
              Show Icons on Next Row
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="advancedFeatures-Accordian-item-content"
      *ngIf="advancedFilterOpenLabel === data?.type && data?.type == 'Card Version'">
      <div
        class="advancedFeatures-toggle advancedFeatures-toggle-inner d-flex align-items-center justify-content-between"
        *ngFor="let toggleValue of advancedFeatures?.[data?.value]?.toggleValues">
        <label class="advancedFeatures-toggle-label">{{ toggleValue?.displayValue }}</label>
        <label class="switch" (click)="versionChange(toggleValue.actualValue)">
          <input type="radio" [checked]="apiVersion == toggleValue?.actualValue ? true : false"
            [name]="toggleValue?.actualValue" />
          <span class="switch-slider"></span>
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #divisionsDialogBox>
  <div mat-dialog-content>
    <div class="wrapper-top">
      <h1 class="pop-heading">Number Of Divisions</h1>
      <div class="pop-btns">
        <button class="popup-save-btn" (click)="saveFilterDivisions()">Save</button>
        <i class="bi bi-x-lg" (click)="closeDialog()"></i>
      </div>
    </div>
    <div class="wrapper-bottom">
      <input type="number" placeholder="Enter Division Value" [value]="divisions" [(ngModel)]="divisions" />
    </div>
  </div>
</ng-template>