<form [formGroup]="group" *ngIf="group  && !transInfoId">
  <div class="d-flex justify-content-between nh-column-gap-10">
    <div class="d-flex nh-mw-0">
      <label
        pTooltip="{{ field?.label }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        class="main-label"
        *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel"
      >
        <span class="main-label-inner">
          <span class="main-label-text text-truncate">{{ field.label }} :</span>
          <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span>
        </span>
      </label>
    </div>
    <div
      class="d-flex align-items-center nh-column-gap-5 pb-2"
      *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
    >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="addAttribute.next()"
        >add</mat-icon
      >
      <mat-icon
        *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
        [ngClass]="{ 'mat-icon-disable': field.readonly }"
        matSuffix
        (click)="removeAttribute.next()"
        >remove</mat-icon
      >
    </div>
  </div>
  <app-file-upload
    [formControlName]="field.attribute.name"
    id="{{ field.attribute.name }}"
    [fileUploadPath]="field.fileUploadPath"
    (fileUploadResponse)="fileUpload($event)"
    (deletedFileResponse)="deleteFileResponse($event)"
    [field]="field"
    [isRedac]="true"
    [isInfoOpen]="false"
    (openDeIdentifyModal)="openPopUp($event)"
  ></app-file-upload>
  <div *ngIf="data && fileType != 'dicom'" class="upload-style">uploaded & deidentified!</div>
  <button
    *ngIf="showDeidentify && fileType != 'dicom'"
    class="verifyDeidentifiedBtn"
    mat-button
    color="primary"
    (click)="getCordinatesResponse()"
  >
    Verify Deidentified File
  </button>
  <!-- <ng-template #popup>
    <div>

      <app-redac-mapping
        [src]="src"
        [originalDocumentSize]="originalDocumentSize"
        [coordinates]="coordinates"
        (onClickBox)="selectedField($event)"
        (manualEdit)="selectedArea($event)"
      >
      </app-redac-mapping>
    </div>
    <button (click)="submitChange()">submit</button>
  </ng-template> -->
</form>

<!-- <form *ngIf="transInfoId" action="" [formGroup]="group">
  <mat-form-field appearance="standard" class="custom-input-mat">
    <div class="d-flex justify-content-between nh-column-gap-10">
      <div class="d-flex nh-mw-0">
        <label
          id="{{ field?.attribute?.name }}"
          pTooltip="{{ field?.label }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
          class="main-label"
          *ngIf="
            (field?.sentence == null || field?.sentence === '') &&
            !field?.hideLabels &&
            !field?.configuration?.hideLabel &&
            !field?.hideLabelMultiAttr
          "
          ><span class="main-label-inner" [style.color]="field.color" [style.font-size.px]="field.fontSize"
            ><span class="main-label-text text-truncate">{{ field?.label }} :</span>
            <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
          ></label
        >
        <p
          class="ml-2"
          *ngIf="field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          <mat-icon
            class="material-icons-outlined"
            matTooltip="{{ field?.configuration?.helpertext }}"
            matTooltipPosition="above"
            >help_outline</mat-icon
          >
        </p>
      </div>
    </div>
    <p
      class="top-text"
      *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
      [ngStyle]="{
        color: field?.configuration?.helperTextColor,
        'font-size': field?.configuration?.helperTextFont
      }"
    >
      {{ field?.configuration?.helpertext }}
    </p>
    <div class="d-flex position-relative mobilenumber-wrapper">
      <input
        matInput
        name="transInfoFieldValue"
        [(ngModel)]="transInfoFieldValue"
        [formControlName]="field?.attribute?.name"
        [type]="field.type"
        [readonly]="field.readonly"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
      />
    </div>

    <button class="verifyDeidentifiedBtn" mat-button color="primary" (click)="redact()">
      redac
    </button>
  </mat-form-field>
</form> -->

<!-- <div *ngIf="!field?.value" class="mat-toggle-gp">
  <mat-button-toggle-group [(ngModel)]="transInfoId" (change)="transIdToggleChange($event.value)" name="transIdToggle">
    <mat-button-toggle [value]="true">TransID</mat-button-toggle>
    <mat-button-toggle [value]="false">File Upload</mat-button-toggle>
  </mat-button-toggle-group>
</div> -->
