import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SoltuionDictionaryEndpointService } from './solution-dictionary-endpoint.service';
import { LoaderService } from '../loader/loader.service';
import { AlertService } from '../toaster/alert.service';

@Injectable({
  providedIn: 'root',
})
export class SolutionDictionaryFacadeService implements OnDestroy {
  // currentTab: String = 'myCollection';
  // private changeOfTabSubject = new BehaviorSubject(this.currentTab);
  // currentTab$ = this.changeOfTabSubject.asObservable();
  private ngUnsubscribe = new Subject();

  selectedCartItem: any;
  cartId: any;

  public ontologyMasterListSubject = new Subject();
  ontologyMasterList$ = this.ontologyMasterListSubject.asObservable();

  // private relatedBETListSubject = new BehaviorSubject(null);
  // relatedBETList$ = this.relatedBETListSubject.asObservable();

  // private browsedBETListSubject = new BehaviorSubject(null);
  // browsedBETList$ = this.browsedBETListSubject.asObservable();

  // private popularBETListSubject = new BehaviorSubject(null);
  // popularBETList$ = this.popularBETListSubject.asObservable();

  public betListSubject = new BehaviorSubject(null);
  betList$ = this.betListSubject.asObservable();

  public dsdSearchCriteriaSubject = new Subject();
  dsdSearchCriteria$ = this.dsdSearchCriteriaSubject.asObservable();

  public specificBETNameSubject = new Subject();
  specificBETName$ = this.specificBETNameSubject.asObservable();

  public detailedPage = new BehaviorSubject('solution');
  detailedPageData$ = this.detailedPage.asObservable();

  public bookmark = new BehaviorSubject({ color: '#323232', bookmark: false });
  bookmarkData$ = this.bookmark.asObservable();

  public overviewDetailsSubject = new Subject();
  overviewDetails$ = this.overviewDetailsSubject.asObservable();

  public bookDetailsSubject = new Subject();
  bookDetails$ = this.bookDetailsSubject.asObservable();

  public resetBETFilterSubject = new BehaviorSubject(null);
  resetBETFilter$ = this.resetBETFilterSubject.asObservable();

  public sideMenuMyLibrary = new BehaviorSubject(null);
  sideMenuMyLibrary$ = this.sideMenuMyLibrary.asObservable();

  validateBookDetails = new BehaviorSubject(null);
  validateBookDetails$ = this.validateBookDetails.asObservable();

  public bookEntitiesList = new Subject();
  bookEntitiesList$ = this.bookEntitiesList.asObservable();

  public bookDashboardsList = new Subject();
  bookDashboardsList$ = this.bookDashboardsList.asObservable();

  public bookDetailsList = new Subject();
  bookDetailsList$ = this.bookDetailsList.asObservable();

  nslSolutionReviews = new Subject();
  nslSolutionReviews$ = this.nslSolutionReviews.asObservable();

  private nslBookDetails = new BehaviorSubject(null);
  nslBookDetails$ = this.nslBookDetails.asObservable();

  private marketPlaceId = new Subject();
  marketPlaceId$ = this.marketPlaceId.asObservable();

  private notepadList = new Subject();
  notepadList$ = this.notepadList.asObservable();

  public searchData = new Subject();
  searchData$ = this.searchData.asObservable();

  public htmlData = new Subject();
  htmlData$ = this.htmlData.asObservable();

  public payloadForNotedPad = new BehaviorSubject('');
  payloadForNotedPad$ = this.payloadForNotedPad.asObservable();

  public prevPayloadForNotedPad = new BehaviorSubject('');
  prevPayloadForNotedPad$ = this.prevPayloadForNotedPad.asObservable();

  public minimizeWindow = new BehaviorSubject('');
  minimizeWindow$ = this.minimizeWindow.asObservable();

  public notepadHtmldata = new BehaviorSubject('');
  notepadHtmldata$ = this.notepadHtmldata.asObservable();

  public notepadName = new BehaviorSubject('');
  notepadName$ = this.notepadName.asObservable();

  public notepadBookId = new BehaviorSubject('');
  notepadBookId$ = this.notepadBookId.asObservable();

  public uploadedFile = new Subject();
  uploadedFile$ = this.uploadedFile.asObservable();

  private betType = new BehaviorSubject('');
  betType$ = this.betType.asObservable();

  public bookSearchResultSubject = new Subject();
  bookSearchResultSubject$ = this.bookSearchResultSubject.asObservable();

  public gsiSearchResultSubject = new Subject();
  gsiSearchResultSubject$ = this.gsiSearchResultSubject.asObservable();

  public entitySearchResultSubject = new Subject();
  entitySearchResultSubject$ = this.entitySearchResultSubject.asObservable();

  public onboarding_select_solutions_selectedBooksAndSolutions = new Subject();
  onboarding_select_solutions_selectedBooksAndSolutions$ = this.onboarding_select_solutions_selectedBooksAndSolutions.asObservable();

  public myCartData = new Subject();
  myCartData$ = this.myCartData.asObservable();

  validatedBookName = new Subject();
  validatedBookName$ = this.validatedBookName.asObservable();

  validatedConnectionName = new Subject();
  validatedConnectionName$ = this.validatedConnectionName.asObservable();

  connectionsList = new BehaviorSubject([]);
  connectionsList$ = this.connectionsList.asObservable();
  failedEntityFetch = new Subject();
  failedEntityFetch$ = this.failedEntityFetch.asObservable();

  showBookmark = new Subject();
  showBookmark$ = this.showBookmark.asObservable();

  showPopup = new Subject();
  showPopup$ = this.showPopup.asObservable();

  betImportStatus = new BehaviorSubject(false);
  betImportStatus$ = this.betImportStatus.asObservable();

  notepadBetName = new Subject();
  notepadBetName$ = this.notepadBetName.asObservable();

  notepadFullScreen = new Subject();
  notepadFullScreen$ = this.notepadFullScreen.asObservable();

  fileNameToImport = new Subject();
  fileNameToImport$ = this.fileNameToImport.asObservable();

  changeTabIndex = new Subject();
  changeTabIndex$ = this.changeTabIndex.asObservable();

  toggleOption = new Subject();
  toggleOption$ = this.toggleOption.asObservable();

  solutionDictionaryClosePopup = new Subject();
  solutionDictionaryClosePopup$ = this.solutionDictionaryClosePopup.asObservable();

  saasapplicationBetSubect = new BehaviorSubject('');
  saasapplicationBet$ = this.saasapplicationBetSubect.asObservable();

  saasImportSubject = new Subject();
  saasImport$ = this.saasImportSubject.asObservable();

  constructor(
    private soltuionDictionaryEndpointService: SoltuionDictionaryEndpointService,
    private loader: LoaderService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setShowPopup(i: number) {
    this.showPopup.next(i);
  }

  pageInfo(data: any) {
    this.detailedPage.next(data);
  }
  bookmarkInfo(color: any, data: any) {
    this.bookmark.next({ color: color, bookmark: data });
  }

  loadOntologyMasterList() {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getOntologyMasterList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.ontologyMasterListSubject.next(res);
      });
  }

  loadSpecificBETByName(filterData: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getSpecificBETByName(filterData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.betListSubject.next(res?.result);
        /* istanbul ignore next */
        if (filterData.betType == 'BOOK') {
          this.bookSearchResultSubject.next(res?.result);
        } else if (filterData.betType == 'GSI') {
          this.gsiSearchResultSubject.next(res?.result);
        } else if (filterData.betType == 'ENTITY') {
          this.entitySearchResultSubject.next(res?.result);
        }
      });
  }

  getDSDSearchCriteria(searchTerm: any) {
    this.dsdSearchCriteriaSubject.next(searchTerm);
  }

  resetBETFilter(data: any) {
    this.resetBETFilterSubject.next(data);
  }

  loadSpecificBETName(data: any) {
    this.specificBETNameSubject.next(data);
  }

  addBETToMylibrary(betType: any, betDetails: any, currentComponent?: any, isDdsdRhs?: boolean) {
    //let res1: any;
    //let res2: any;
    this.loader.show();
    /*this.soltuionDictionaryEndpointService
      .addBETToMylibrary(betType, betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        res1 = res;
      });*/
    this.soltuionDictionaryEndpointService
      .importAndValidateFromBetStore(betDetails, currentComponent)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();

          this.alertService.showToaster(res.message, '', 'success');
          this.validateBookDetails.next(res.result);
        },
        /* istanbul ignore next */
        (err: any) => {
          this.loader.hide();
          this.alertService.showToaster(err.message, '', 'error');
          if (!isDdsdRhs) {
            this.router.navigateByUrl('/solution-dictionary/book');
          }
        }
      );
    //return [res1, res2];
  }

  importValidate(betType: any, betDetails: any, systemUserDetails: string) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .importValidate(betType, betDetails, systemUserDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.validateBookDetails.next(res.result);
        },
        /* istanbul ignore next */
        (err: any) => {
          this.loader.hide();
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  betStoreBookImport(bookDetails: any, dsdId: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.soltuionDictionaryEndpointService
      .betStoreBookImport(bookDetails, dsdId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'PUBLISHED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {});
          }
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error.message, '', 'error');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'FAILED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {});
          }
        }
      );
  }

  //betImport to Saas Tenant
  betstoreSaas(bookDetails: any, dsdId: any, targetTenant: string, systemUserDetails: string, betType: string) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .betstoreSaas(bookDetails, dsdId, targetTenant, systemUserDetails, betType)
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.saasImportSubject.next(res);
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error.message, '', 'error');
        }
      );
  }
  betStoreImport(bookDetails: any, dsdId: any, betType: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.soltuionDictionaryEndpointService
      .betStoreImport(bookDetails, dsdId, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'PUBLISHED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {});
          }
          this.betImportStatus.next(true);
        },
        /* istanbul ignore next */
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error.message, '', 'error');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'FAILED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {});
          }
        }
      );
  }

  publishBETToNSLlibrary(betType: any, betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .publishBETToNSLlibrary(betType, betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  publishBETToNSL(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .publishBETToNSL(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getGSIImportDetails(res: any) {
    this.validateBookDetails.next(res);
  }

  getBookEntity(bookID: any, betType: string) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getBookEntity(bookID, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.bookEntitiesList.next(res);
        },
        (error: any) => {
          this.loader.hide();
          const err = error?.error?.result?.cuExceptions?.BASIC[0]?.errorDetails[0];
          this.alertService.showToaster(err?.message ? err?.message : error?.message, '', 'error');
          this.failedEntityFetch.next('failed');
        }
      );
  }

  getBookDashboard(bookID: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getBookDashboard(bookID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.bookDashboardsList.next(res);
      });
  }

  loadBookDetails(bookDetails: any, isPublic?: boolean) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .loadBookDetails(bookDetails, isPublic)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.bookDetailsList.next(res);
          this.alertService.showToaster(res.message, '', 'success');
        },
        /* istanbul ignore next */
        (error: any) => {
          this.bookDetailsList.next(error);
        }
      );
  }

  getOverviewDetails(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getOverviewDetails(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          // this.alertService.showToaster(res.message, '', 'success');
          this.overviewDetailsSubject.next(res.result);
        },
        (error: any) => {
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }

  getBookDetails(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getBookDetails(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.bookDetailsSubject.next(res.result);
      });
  }

  /* nsl reviews */
  addNslReview(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .addNslReview(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          this.alertService.showToaster(res.message, '', 'success');
          /* istanbul ignore next */
          this.getNslReview(betDetails?.marketplaceId);
        }
      });
  }

  getNslReview(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getNslReview(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.nslSolutionReviews.next(res.result);
      });
  }
  /* ./nsl reviews */

  getBookmark(val: boolean) {
    this.showBookmark.next(val);
  }

  /* my library overview */
  getMyLibraryOverview(betType: any, id: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getMyLibraryOverview(betType, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.loader.hide();
        this.alertService.showToaster(response.message, '', 'success');
        this.overviewDetailsSubject.next(response.result);
      });
  }

  setBookDetailsSubject(data: any) {
    this.bookDetailsSubject.next(data);
  }

  getMyLibraryDetailedOverview(betType: any, id: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getMyLibraryDetailedOverview(betType, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: any) => {
        this.loader.hide();
        this.alertService.showToaster(r.message, '', 'success');
        this.bookDetailsSubject.next(r.result);
      });
  }
  /* ./my library overview */

  // side Menu MyLibrary
  getIsSideMenuForMyLibrary(data: any) {
    this.sideMenuMyLibrary.next(data);
  }

  //notepad
  getNotepadList(
    betType: string,
    consolidated: boolean,
    pageSize: number,
    pagenum: number,
    searchCriteria: string,
    sortBy: string
  ) {
    return this.soltuionDictionaryEndpointService.getNotepadList(
      betType,
      consolidated,
      pageSize,
      pagenum,
      searchCriteria,
      sortBy
    );
  }

  getGlobalSearch(searchText: string, betType: string, pageNumber: number, pageSize: number) {
    this.soltuionDictionaryEndpointService
      .getGlobalSearch(searchText, betType, pageNumber, pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.searchData.next(res);
      });
  }

  setNslBookDetails(data: any) {
    this.nslBookDetails.next(data);
  }

  // changeOfTab(nextTab: string) {
  //   this.changeOfTabSubject.next(nextTab);
  // }

  saveHtmlData(data: any) {
    this.htmlData.next(data);
  }

  payloadForSaveNotedPad(data: any) {
    this.payloadForNotedPad.next(data);
  }

  prevPayloadForSaveNotedPad(data: any) {
    this.prevPayloadForNotedPad.next(data);
  }

  minimizeNotepadWindow(data: any) {
    this.minimizeWindow.next(data);
  }

  saveNotepad(payload: any) {
    // create new roles
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .saveNotepad(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  uploadFile(payload: any) {
    // create new roles
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .uploadFile(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.uploadedFile.next(res);
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getNotepadContent(contentId: string) {
    this.soltuionDictionaryEndpointService
      .getNotepadContent(contentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result?.content) {
          this.notepadHtmldata.next(JSON.parse(res.result.content));
          this.notepadBetName.next(res.result.betName);
        }
      });
  }

  sendBetType(type: any) {
    this.betType.next(type);
  }

  send_onboarding_select_solutions_selectedBooksAndSolutions(data: any) {
    this.onboarding_select_solutions_selectedBooksAndSolutions.next(data);
  }

  getMyCartData() {
    this.soltuionDictionaryEndpointService
      .getMyCartData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.myCartData.next(res?.result);
        /* istanbul ignore next */
        this.cartId = res?.result?.dsdId;
      });
  }

  selectedItemFromCart(data: any) {
    this.selectedCartItem = data;
  }

  setNotepadFullScreen(bool: boolean) {
    this.notepadFullScreen.next(bool);
  }

  validateBookName(tenantId: any, bookName: string) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .validateBookName(tenantId, bookName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res) {
            this.alertService.showToaster('Book Name already exists', '', 'error');
          }
        },
        (error: any) => {
          this.validatedBookName.next(true);
        }
      );
  }

  saveConnectionsList(connections: any) {
    this.connectionsList.next(connections);
  }

  validateConnectionName(tenantId: any, gsiName: string) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .validateConnectionName(tenantId, gsiName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res) {
            this.alertService.showToaster('Connection already exists', '', 'error');
          }
        },
        (error: any) => {
          this.validatedConnectionName.next(true);
        }
      );
  }

  sendFileNameToImport(fileName: string) {
    this.fileNameToImport.next(fileName);
  }

  setChangeTabIndex(num: number) {
    this.changeTabIndex.next(num);
  }

  setToggleOptions(option: string) {
    this.toggleOption.next(option);
  }
  closepopupDataService() {
    this.solutionDictionaryClosePopup.next({ data: true });
    // this.solutionDictionaryClosePopup.next(true);
  }
  saasApplicationImportedCdm(targetTenant: string, payload: any) {
    return this.soltuionDictionaryEndpointService.saasApplicationImportedCdm(targetTenant, payload);
  }
}
