import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  FetchPlans() {
    return this.http.get(this.api?.getAllPlans);
  }

  unsubscribePlan(postData: any) {
    return this.http.post(this.api?.unsubscribePlan, postData);
  }

  inviteClient(postData: any) {
    return this.http.post(this.api?.extsend, postData);
  }

  createUser(postData: any) {
    return this.http.post(this.api?.createUser, postData);
  }

  getAllEnvoironments(id: any) {
    let tenantId = localStorage.getItem('TenantId');
    return this.http.get(this.api?.environmentsForTenant + tenantId);
  }

  getRoleList(pageNumber: any, pageSize: any, data: any) {
    return this.http.post(`${this.api?.getAllRoles}?pageNumber=${pageNumber}&pageSize=${pageSize}`, data);
  }

  getdeptList() {
    return this.http.get(this.api?.getAllLabels);
  }

  updateUser(updateData: any, userId: number) {
    return this.http.post(`${this.api?.updateUser}type=graph&userId=${userId}`, updateData);
  }

  getPaginateUsersTenantAdmin(pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      `${this.api?.getPaginateUsersTenantAdmin}?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`,
      {}
    );
  }

  deleteUser(deleteUserData: any, userId: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deleteUserData,
    };
    return this.http.delete(`${this.api?.deleteUser}type=graph&userId=${userId}`, httpOptions);
  }

  loadUsersCount(selectedEnv: any) {
    if (selectedEnv) {
      return this.http.get(this.api?.loadUsersCount + `?env=${selectedEnv}`);
    } else {
      return this.http.get(this.api?.loadUsersCount);
    }
  }

  updateUserAsTenantAdmin(updateData: any, userId: number) {
    return this.http.post(`${this.api?.updateUserAsTenantAdmin}?userId=${userId}`, updateData);
  }

  updateUserPassword(updateData: any) {
    return this.http.post(`${this.api?.updateUserPassword}type=graph`, updateData);
  }

  getPaginateRoles(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    /*istanbul ignore next*/
    const payload = {
      isActive: isActive ? isActive : 'all',
      roleName: searchString || '',
    };
    return this.http.post(this.api?.getPaginateRoles + `pageNumber=${pageNumber}&pageSize=${pageSize}`, payload);
  }

  importUserFromCSV(postData: any, env?: any) {
    if (env) {
      return this.http.post(`${this.api?.importUserFromCSV}?environments=${env}`, postData);
    }
  }

  collaboratorAssign(email: any) {
    return this.http.post(`${this.api?.collaboratorAssign}${email}`, {});
  }
  startOnboardWithRegistrationNumber(registrationNumber: any) {
    return this.http.get(this.api?.cdmCompanyProfile + `/${registrationNumber}`);
  }

  startOnboardwithCompanyDetails(data: any) {
    return this.http.post(`${this.api?.cdmCompanyProfile}`, data);
  }

  postSelectedBooksAndSolutions(data: any) {
    return this.http.post(`${this.api?.saveBooksAndSolutionsInCart}`, data);
  }

  getRolesByLabels(label: any) {
    return this.http.get(`${this.api?.getRolesByLabels}` + label);
  }

  saveBDPInfo(data: any) {
    return this.http.post(`${this.api?.bdpInfo}`, data);
  }

  saveFreelancerDetails(data: any) {
    return this.http.post(`${this.api?.freelancerInfo}`, data);
  }

  onSaveTenantConfiguration(data: any) {
    return this.http.post(`${this.api?.tenantconfiguration}`, data);
  }

  getGsiMappingsByRoleName(roleName: any, purpose: string) {
    return this.http.get(`${this.api?.gsiMappingsByRole}${roleName}?purpose=${purpose}`);
  }

  getCountryCodes() {
    return this.http.get(`${this.api?.countryCode}`);
  }
  sendOptRequestNew(payload) {
    return this.http.post(`${this.api?.generateSignUpOtpNew}`,payload);

    // return this.http.get(`${this.api?.generateSignUpOtp}${mobileNumber}&email=${email}&tenantName=${tenantName}`);

  }
  sendOptRequest(payload:any) {
    return this.http.post(`${this.api?.generateOTP}`,payload);
  }

  validateOTP(payload:any,skipUserCreation:boolean=false) {
    return this.http.post(
      `${this.api?.validateSignUpOTP}?skipUserCreation=${skipUserCreation}`,payload
    );
  }

}
