import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  A2aFacadeService,
  AlertService,
  ChangeUnitFacadeService,
  emptyObject,
  FieldConfig,
  LoaderService,
  TransactionFacadeService,
} from '@common-services';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { DicomIntegrationComponent } from '../dicom-integration/dicom-integration/dicom-integration.component';
import { RedacMappingComponent } from '../redac-mapping/redac-mapping.component';
@Component({
  selector: 'app-redac',
  templateUrl: './redac.component.html',
  styleUrls: ['./redac.component.scss'],
})
export class RedacComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  fileType: any;
  group: FormGroup;

  coordinates: any;
  private unsubscribe: Subject<any> = new Subject<any>();
  domHeight: any;
  domWidth: any;
  src: any;
  originalDocumentSize: any;
  data: any;
  tenantId: any;
  currentCu: any;
  addAttribute: Subject<any> = new Subject();
  addAttribute$ = this.addAttribute.asObservable();
  removeAttribute: Subject<any> = new Subject();
  ontology: any;
  fileSize: any;
  fileName: any;
  file: any;
  showDeidentify: boolean = false;
  isredacted: boolean;
  transInfoId: boolean = false;
  transInfoFieldValue: any = '';
  imageMimeTypes = ['png', 'gif', 'pjpeg', 'jpeg', 'svg+xml', 'webp', 'jpg', 'svg', 'heif', 'heic'];
  constructor(
    private a2afacade: A2aFacadeService,
    private loader: LoaderService,
    private transactionFacadeService: TransactionFacadeService,
    public dialog: MatDialog,
    private changeunitFacade: ChangeUnitFacadeService,
    private alertService: AlertService
  ) {
    this.tenantId = localStorage.getItem('envClientId')
      ? localStorage.getItem('envClientId')
      : sessionStorage.getItem('TenantId');

    this.getOntologyDetails();
    this.detectOnSubmit();
  }
  ngOnInit(): void {
    if (
      this.group?.controls?.[this.field.attribute.name]?.value &&
      this.group?.controls?.[this.field.attribute.name]?.value != ''
    ) {
      this.field.value = this.group?.controls[this.field.attribute.name].value;
    }
    if (this.field?.value != '') {
      let files;
      if (this.field.value && this.checkJSONValidity(this.field.value)) {
        files = JSON.parse(this.field?.value);
        this.fileSize = files?.size;
        this.fileName = files?.name;
        this.src = files?.contentUrl;
        if (!files?.redacted_file) {
          files.redacted_file = files?.contentUrl;
        }
        this.setRedactData(files);
        /*istanbul ignore next*/
        if (files?.mimeType == 'application/pdf') {
          this.fileType = 'pdf';
        } /*istanbul ignore next*/ else if (this.supportedImagesTypes(files?.mimeType)) {
          this.fileType = 'image';
        } else if (files?.mimeType == 'application/dicom') {
          this.fileType = 'dicom';
          /*istanbul ignore next*/
          // this.redact();
        }
        this.showDeidentify = true;
      } else {
        /*istanbul ignore next*/
        this.transInfoId = true;
        files = this.field?.value;
        this.transInfoFieldValue = this.field?.value;
        this.redact();
      }
      this.fileSize = files?.size;
      this.fileName = files?.name;
      this.src = files?.contentUrl;
      this.setRedactData(files);
      /*istanbul ignore next*/
      if (files?.mimeType == 'application/pdf') {
        this.fileType = 'pdf';
      } /*istanbul ignore next*/ else if (this.supportedImagesTypes(files?.mimeType)) {
        this.fileType = 'image';
      } else if (files?.mimeType == 'application/dicom') {
        this.fileType = 'dicom';
        /*istanbul ignore next*/
        // this.redact();
      }
      this.showDeidentify = true;
    }
  }

  supportedImagesTypes(fileType: any) {
    let flag = false;
    this.imageMimeTypes?.forEach((ele: any) => {
      if (fileType && fileType?.toLowerCase()?.includes(ele)) {
        flag = true;
      }
    });
    return flag;
  }

  redact(files?: any) {
    /*istanbul ignore next*/
    this.loader.show();
    if (!this.ontology) {
      this.ontology = 'common';
    }
    let payLoad: any;
    payLoad = this.transInfoId
      ? {
          transinfoid: this.transInfoFieldValue,
        }
      : {
          file_link: this.src,
          ontology: this.ontology,
          filestore_obj: this.file,
        };
    this.a2afacade
      .redactData(payLoad, this.transInfoId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: any) => {
          /*istanbul ignore next*/
          if (res) {
            if (this.transInfoId) {
              this.checkFileType(res?.filestore_obj);
            }
            this.transInfoId = false;
            this.setRedactData(res);
            /*istanbul ignore next*/
            this.setValueRedact(res);
            this.loader.hide();
            // this.alertService.showToaster(`File Sucessfully uploaded`, '', 'success');
          }
          // this.redacTheMappings();
        },
        /*istanbul ignore next*/
        (error) => {
          const { err } = error.message;
          this.loader.hide();
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }
  setValueRedact(res: any) {
    let filestoreData = JSON.stringify({
      contentUrl: res?.redacted_file,
      mimeType: this.getFileData(res),
      size: this.fileSize,
      name: this.fileName,
      displayName: this.fileName,
      originalFileName: this.fileName,
      ref_dimensions: res?.ref_dimensions,
    });
    this.group?.controls[this.field?.attribute?.name].setValue(filestoreData);
    if (!this.transInfoId) {
      this.field.value = filestoreData;
    }
  }
  setRedactData(res: any) {
    this.showDeidentify = true;
    this.src = res?.file_url ? res?.file_url : res?.contentUrl;
    this.data = res;
    this.coordinates = res?.redacted_boxes;
    this.originalDocumentSize = res?.ref_dimensions;
    if (this.field.isMulti) {
      if (!this.a2afacade.savedRedac.has(this.field.entityName)) {
        this.a2afacade.savedRedac.set(this.field.entityName, new Map<string, string>());
      }
      if (typeof this.field?.attrTxnRecordId != 'string') {
        let ind = Number(this.field.entityName.split('$')[1]) - 1;
        this.field.attrTxnRecordId = `${ind}` + '$' + this.field?.attrTxnRecordId;
      }

      this.a2afacade.savedRedac.get(this.field.entityName).set(this.field.attrTxnRecordId, {
        src: this.src,
        data: this.data,
        coordinates: this.coordinates,
        originalDocumentSize: this.originalDocumentSize,
        ontology: this.ontology,
        showDeidentify: true,
        previewFile: res?.redacted_file,
      });
    } else {
      this.a2afacade.savedRedac.set(this.field.entityName, {
        src: this.src,
        data: this.data,
        coordinates: this.coordinates,
        originalDocumentSize: this.originalDocumentSize,
        ontology: this.ontology,
        showDeidentify: true,
        previewFile: res?.redacted_file,
      });
    }
  }
  getOntologyDetails() {
    this.a2afacade.ontologySubject$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      console.log(res);
      this.ontology = res?.result?.ontology[res?.result?.ontology?.length - 1]?.id;
    });
  }
  fileUpload(files: any) {
    this.fileSize = files?.size;
    this.fileName = files?.name;
    this.src = files?.contentUrl;
    this.file = files;
    /*istanbul ignore next*/
    if (files?.mimeType == 'application/pdf') {
      this.fileType = 'pdf';
    } else if (this.supportedImagesTypes(files?.mimeType)) {
      /*istanbul ignore next*/
      this.fileType = 'image';
    } else if (files?.mimeType == 'application/dicom') {
      /*istanbul ignore next*/
      this.fileType = 'dicom';
    }
    this.redact(files);
    console.log(files);
  }
  deleteFileResponse(file: any) {
    /* istanbul ignore else */
    if (file.length == 0) {
      this.data = false;
      this.showDeidentify = false;
      /*istanbul ignore next*/
      this.field.value = '';
      if (this.field.isMulti) {
        this.a2afacade.savedRedac.get(this.field.entityName).set(this.field.attrTxnRecordId, {
          src: null,
          data: null,
          coordinates: null,
          originalDocumentSize: null,
          ontology: null,
          previewFile: null,
        });
      } else {
        this.a2afacade.savedRedac.set(this.field.entityName, {
          src: null,
          data: null,
          coordinates: null,
          originalDocumentSize: null,
          ontology: null,
          previewFile: null,
        });
      }
    }
  }
  getCordinatesResponse() {
    if (this.a2afacade.savedRedac.has(this.field.entityName)) {
      let attributeData: Map<string, any> = this.a2afacade.savedRedac.get(this.field.entityName);
      let result = this.field.isMulti ? attributeData?.get(this.field?.attrTxnRecordId) : attributeData;
      if (result) {
        this.src = result?.src;
        this.data = result?.data;
        this.coordinates = result?.coordinates;
        //this.ontology = result.ontology;
        this.originalDocumentSize = result?.originalDocumentSize;
      }
    }
    if (this.originalDocumentSize && !emptyObject(this.originalDocumentSize)) {
      this.openPopUp();
    } else {
      let payLoad = {
        file_link: this.src,
        ontology: this.ontology,
      };
      this.loader.show();
      this.a2afacade
        .redactData(payLoad)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          if (res) {
            this.setRedactData(res);
            this.openPopUp();
          }
        });
    }
  }
  openPopUp(preview?: any) {
    console.log(this.src);
    /*istanbul ignore next*/
    let attributeData: Map<string, any> = this.a2afacade.savedRedac.get(this.field.entityName);
    let result = this.field.isMulti ? attributeData?.get(this.field?.attrTxnRecordId) : attributeData;
    if (this.fileType == 'dicom') {
      /*istanbul ignore next*/
      if (!this.file) {
        if (this.a2afacade.savedRedac.has(this.field?.entityName)) {
          if (result) {
            this.file = result?.data;
          }
        }
      }
      this.dialog.open(DicomIntegrationComponent, {
        height: '1000px',
        width: '1000px',
        data: {
          attributeId: this.field?.attrTxnRecordId,
          attributeName: this.field?.attribute['name'],
          entityName: this.field?.entityName,
          entityId: this.field?.entityId,
          file: this.file,
          uiCtrl: 'redact',
        },
        panelClass: 'dicom-preview-dialog',
      });
      this.field.value = this.file;
    } else {
      /*istanbul ignore next*/
      if (this.a2afacade.savedRedac.has(this.field.entityName)) {
        if (result) {
          this.src = result?.src;
          this.data = result?.data;
          this.coordinates = result?.coordinates;
          //this.ontology = result.ontology;
          this.originalDocumentSize = result?.originalDocumentSize;
        }
      }
      if (this.src.includes('.pdf')) {
        this.fileType = 'pdf';
      } else if (this.src.includes('.png')) {
        this.fileType = 'image';
      }
      let data = {
        src: preview ? result?.previewFile : this.src,
        originalDocumentSize: this.originalDocumentSize,
        coordinates: this.coordinates,
        expanded: true,
        fileType: this.fileType,
        attributeId: this.field?.attrTxnRecordId,
        entityName: this.field?.entityName,
        attributeName: this.field?.attribute?.name,
        preview: preview,
      };
      this.dialog.open(RedacMappingComponent, {
        height: '100%',
        width: '1000px',
        panelClass: 'dialogue-pop',
        data: data,
      });
    }
  }

  detectOnSubmit() {
    this.a2afacade.onSubmitRedact$.pipe(takeUntil(this.unsubscribe)).subscribe((res: any) => {
      console.log(res);
      /*istanbul ignore next*/
      if (
        res &&
        res.type == 'redacted' &&
        res?.attributeId == this.field?.attrTxnRecordId &&
        res.entityName == this.field?.entityName &&
        res.attributeName == this.field?.attribute?.name
      ) {
        if (this.a2afacade.savedRedac.has(this.field.entityName)) {
          if (this.field.isMulti) {
            let attributeData: Map<string, any> = this.a2afacade.savedRedac.get(this.field.entityName);
            let result = attributeData?.get(this.field?.attrTxnRecordId);
            this.a2afacade.savedRedac.get(this.field.entityName).set(this.field.attrTxnRecordId, {
              src: res?.file_url,
              originalDocumentSize: result.originalDocumentSize,
              data: result.data,
              showDeidentify: true,
              coordinates: res?.redacted_boxes,
              previewFile: res?.redacted_file,
            });
          } else {
            let result = this.a2afacade.savedRedac.get(this.field.entityName);
            this.a2afacade.savedRedac.set(this.field.entityName, {
              src: res?.file_url,
              originalDocumentSize: result.originalDocumentSize,
              data: result.data,
              showDeidentify: true,
              coordinates: res?.redacted_boxes,
              previewFile: res?.redacted_file,
            });
          }
        }

        /*istanbul ignore next*/
        // this.field.value = res?.redacted_file;

        /*istanbul ignore next*/
        this.setValueRedact(res);
        //this.dialog.closeAll();
      } else if (this.fileType == 'dicom') {
        /* istanbul ignore next */
        if (res?.redacted_file) {
          this.file = { contentUrl: res.redacted_file };
          this.field.value = res?.redacted_file;
          this.setValueRedact(res);
        }
      }
    });
  }
  getFileData(res: any) {
    let fileData;
    /*istanbul ignore next*/
    if (this.fileType == 'pdf') {
      fileData = 'application/pdf';
    } else if (this.fileType == 'image') {
      /*istanbul ignore next*/
      fileData = res?.filestore_obj?.mimeType ? res.filestore_obj.mimeType : 'image/png';
    } else if (this.fileType == 'dicom') {
      fileData = 'application/dicom';
    }
    return fileData;
  }
  checkJSONValidity(jsonString: string): boolean {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }
  checkFileType(files: any) {
    this.fileSize = files?.size;
    this.fileName = files?.name;
    this.file = files;
    /*istanbul ignore next*/
    if (files?.mimeType == 'application/pdf') {
      this.fileType = 'pdf';
    } else if (files?.mimeType == 'image/png') {
      /*istanbul ignore next*/
      this.fileType = 'image';
    } else if (files?.mimeType == 'application/dicom') {
      /*istanbul ignore next*/
      this.fileType = 'dicom';
    }
  }
  transIdToggleChange(event: any) {
    if (event) {
      this.transInfoId = true;
      if (this.checkJSONValidity(this.transInfoFieldValue)) {
        this.transInfoFieldValue = '';
      }
    } else {
      this.transInfoId = false;
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
