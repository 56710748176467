import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, ChangeUnit, constructRightsObj, fillRights, getAttributeObj, getFlatData, LoaderService, TranslatorService,getParticipatingItems,handleSelectAgent, NodeGsiFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-separate-cu',
  templateUrl: './node-separate-cu.component.html',
  styleUrls: ['./node-separate-cu.component.scss'],
})
export class NodeSeparateCuComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Input() currentPath: any;
  @Input() forParallel: boolean = false;
  @Output() emitFinalCUForParallel = new EventEmitter();
  @Input() getCUForDisplay: any;
  @Input() isParagraph: any;
  changeUnitList: any = [{ ...new ChangeUnit(), index: 1, nextTriggerSet: [] }];
  errorlist: any = [];
  errorAlertModal: boolean;
  editedCuinput: number = -1;
  cuName: string = '';
  isIndcu: boolean = true;
  isCuInputFocus = true;
  indcudata: any = { ...new ChangeUnit() };
  cuIndex: number;
  cuSentence: string = '';
  cuInfoSentence: string = '';
  isSelectedFromDropDown: boolean;
  moreLableTypes: any = ['Edit', 'Information layer'];
  isInfoOpen: boolean = false;
  isInfo: boolean = false;
  physicalLayerSentence: any[];
  informationLayerSentence: any[];
  CUObj: any;
  sentenceString: string;
  invalidSentence: any;
  labels: any;
  currentEntity: any;
  alertModal = false;
  alertTooltip = '';
  entityIndex: number;
  showConfigDropdown = false;
  newAttributeList: any = [];
  entityRename: string;
  layerType: string;
  tempEntityIndex: number;
  entityArr: any;
  tempExistingEntityList: any[];
  showILTagsView: boolean;
  isValidCuDisplay = false;
  clickThreeDot: boolean = false;
  isReserved: boolean = false;
  reservedCu: any;
  isTrigger: boolean = false;
  extractedPhysicalLayer: any;
  triggerSentence: string = '';
  triggerCESLayerSentence: any[];
  showTriggerCES: boolean = false;
  clickThreeDotInfo: boolean = false;
  clickThreeDotTrigger: boolean = false;
  verifyComponent = (res: any) => this.forParallel && this.currentPath.isFocused;
  isParallel: boolean;
  agent: any;
  dataSend: any;
  currentEmptyEntityNSLAttributes: any;
  querySentence: any;
  constructor(
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private loader: LoaderService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private nodeGsiFacadeService: NodeGsiFacadeService,
    private alertService: AlertService,
    private router: Router,
    public translator: TranslatorService
  ) {
    this.detectCUListValidation();
    this.getSavedChangeUnitData();
    this.getCompositeExtraction();
    this.detectLastEntitytoSave();
    this.detectEntResponse();
    this.getInformationExtraction();
    // this.detectPublishedCU();
    this.detectSaveIndEntityconf();
    this.detectSavedCu();
    this.detectEntityData();
    this.detectLanguageChange();
    this.triggerCesExtraction();
    this.detectAddedAttributeInEntity();
    this.detectCuFromLibrary();
    this.detectSolutionDetails();
    this.getEntitydata();
    this.separateCuOpen();
    this.addAgentFromLib();
    // this.detectEntityToAddFromLibrary();
    this.detectCancelReview();
  }

  ngOnInit(): void {
    /* istanbul ignore else */
    if (this.forParallel) {
      this.isParallel = true;
    }
    /* istanbul ignore else */
    if (this.getCUForDisplay) {
      this.indcudata = { ...this.getCUForDisplay, showTagsView: true };
      /* istanbul ignore next */
      this.physicalLayerSentence = this.indcudata?.sentenceTags?.physical;
      this.cuIndex = this.indcudata.index;
      this.isValidCuDisplay = true;
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getEntitydata() {
    this.nodeEntityFacadeService.indCuEntityData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (!this.isInfo && !this.isTrigger) {
        this.addEntityFromLibrary(res, this.indcudata, 'physical');
      } else if (this.isTrigger) {
        this.addEntityFromLibrary(res, this.indcudata, 'triggerCES');
      } else {
        this.addEntityFromLibrary(res, this.indcudata, 'information');
      }
      this.callfat2Flatt(this.indcudata);
    });
  }

  addEntityFromLibrary(entity: any, cu: any, layerType: any) {
    let layerIndex: number;
    /*istanbul ignore next */
    layerIndex = this.indcudata?.layers?.findIndex((layer: any) => {
      return layer?.type == layerType;
    });
    if (layerIndex == -1) {
      let participatingItems: any[] = [];
      let layer = {
        type: layerType,
        participatingItems: participatingItems,
      };
      layer?.participatingItems.push(JSON.parse(JSON.stringify(entity)));
      this.indcudata?.layers.push(layer);
    } else {
      this.indcudata?.layers[layerIndex]?.participatingItems.push(JSON.parse(JSON.stringify(entity)));
    }
  }
  separateCuOpen() {
    this.nodeEntityFacadeService.setIndividualCudata('IndCu');
    this.changeUnitFacade.setIndividualCudata('IndCu');
  }

  validateCU(event: any) {
    this.cuSentence = event.trim();
    this.changeUnitList[0].name = this.cuName;
    let formData: FormData = new FormData();
    formData.append('text', event);
    this.changeUnitFacade.validateCuList(formData, -1, true);
  }

  detectCUListValidation() {
    this.changeUnitFacade.indCuValidate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.separateCuOpen();
        this.errorlist = [];
        this.loader.hide();
        let invalidCU = false;
        res.output?.forEach((cu: any) => {
          if (!cu.status) {
            invalidCU = true;
            this.errorlist.push({ name: cu.sentence, message: cu.error_code });
          }
        });
        if (!invalidCU) {
          let cuList = [...this.changeUnitList];
          cuList?.forEach((cu: any, index: any) => {
            if (cu.referencedChangeUnit) {
              cu.id = cu.referencedChangeUnit;
              cu.tfId = cu.tfReferencedChangeUnit;
            } else {
              let cuFound = res.output.find((x: any) => x.sentence === cu.name);
              /* istanbul ignore else */
              if (cuFound && cuFound?.dsdId !== 'NA') {
                cu.id = cuFound?.dsdId;
                cu.tfId = cuFound?.dsdId;
              }
            }
            cu.referencedChangeUnit = undefined;
            cu.tfReferencedChangeUnit = undefined;
          });

          this.isValidCuDisplay = true;
          this.changeUnitFacade.saveCU(this.changeUnitList[0]);
        } else {
          this.alertService.showToaster(
            this.labels
              ?.A_CU_is_always_described_with_a_verb_Noun_phrase_Enter_a_valid_CU_name_such_as_Register_Customer,
            'Error',
            'error'
          );
        }
      }
    });
  }
  getSavedChangeUnitData() {
    this.changeUnitFacade.savedIndCuSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.changeUnitList[0] = res;
      this.indcudata = res;
      this.cuIndex = this.indcudata.index;
      /* istanbul ignore else */
      if (this.isValidCuDisplay) {
        this.indcudata.isEditable = false;
      }
      // this.callfat2Flatt(res);
    });
  }
  validateCu(name: string) {
    if (!name) {
      this.alertService.showToaster(
        this.labels
          ?.Agent_Object_Attributes_missing_Complete_the_sentence_using_4_building_blocks_After_the_CU_name_add_the_Agent_and_the_object_with_its_attributes,
        '',
        'error'
      );
    } else {
      /* istanbul ignore else */
      if (!this.isSelectedFromDropDown && this.isSelectedFromDropDown !== undefined) {
        this.indcudata.showTagsView = true;
      }
      let formData: FormData = new FormData();
      formData.append('text', name);
      let res = getParticipatingItems(this.indcudata, 'physical', name);
      // formData.append('participatingItems',getParticipatingItems(this.indcudata,'physical'));
      this.changeUnitFacade.cuValidation(res, this.cuIndex);
    }
  }

  validateIL() {
    this.showILTagsView = true;
    this.indcudata.showInfoTagsView = true;
    let formData: FormData = new FormData();
    formData.append('text', this.cuInfoSentence);
    // let res = getParticipatingItems(this.indcudata, 'information', this.cuInfoSentence);
    this.changeUnitFacade.validateIL(formData);
  }

  getCompositeExtraction() {
    this.changeUnitFacade.compositeExtraction$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.loader.hide();
        this.extractedPhysicalLayer = res;
        /* istanbul ignore next */
        this.agent = res?.extraction.CU_list[0]?.agent[0]?.type;
        this.physicalLayerSentence = res.sentence_tags;
        this.invalidSentence = false;
        this.sentenceString = '';
        this.currentEntity = undefined;
        /* istanbul ignore next */
        this.CUObj = res?.extraction?.CU_list[0];
        this.reservedCu = this.CUObj?.nsl_res;
        this.isReserved = this.CUObj?.isReserved;
        if (!this.isReserved) {
          this.formatTags(res);
        }
      }
    });
  }
  formatTags(res: any) {
    this.indcudata.showTagsView = true;
    /* istanbul ignore next */
    this.CUObj?.entities?.forEach((entity: any) => {
      entity.attributes_list?.forEach((attr: any) => {
        /* istanbul ignore else */
        if (attr?.name === '$missing') {
          this.invalidSentence = true;
        }
      });
    });
    /* istanbul ignore next */
    this.physicalLayerSentence?.forEach((sentence) => {
      this.sentenceString += ' ' + sentence.token;
      switch (sentence['tag']) {
        case 'cu':
          sentence['tag'] = 'Int.';
          break;
        case 'agent':
          sentence['tag'] = 'Ag.';
          break;
        case 'entity':
          sentence['tag'] = 'Obj.';
          break;
        case 'attributes':
          sentence['tag'] = 'Att.';
          break;
        case 'human agent':
          sentence['tag'] = 'Hum.';
          break;
        case 'machine agent':
          sentence['tag'] = 'Mch.';
          break;
      }
    });

    if (!this.indcudata?.sentenceTags) {
      this.indcudata['sentenceTags'] = {};
      this.indcudata.sentenceTags['physical'] = res.sentence_tags;
    } else {
      this.indcudata.sentenceTags.physical = res.sentence_tags;
    }

    this.layerType = 'physical';

    /*istanbul ignore else */
    if (this.indcudata?.layers && this.indcudata.layers?.length > 0) {
      this.resetLayerEntity();
    }
    /* istanbul ignore else */
    if (this.CUObj) {
      /* istanbul ignore next */
      if (
        this.CUObj?.name === '$missing' ||
        this.CUObj.agent?.name === '$missing' ||
        this.CUObj.entities?.length === 0
      ) {
        this.invalidSentence = true;
      }
      this.indcudata = fillRights(res, this.indcudata);
      /* istanbul ignore next */
      if (this.agent === '$missing' || res?.extraction?.CU_list[0]?.agent?.[0]?.name === '$missing') {
        this.agent = '';
      } else if (this.agent === 'machine') {
        this.indcudata.agents[0].agentType = this.agent;
      } else {
        /* istanbul ignore next */
        res?.extraction?.CU_list?.[0]?.agent?.forEach((agent: any) => {
          let resp = handleSelectAgent({ name: agent?.name, id: agent?.id }, false, this.indcudata, {}, this.agent);
          this.indcudata = resp.index;
        });
      }
      this.alertModal = true;
      this.alertTooltip = this.invalidSentence ? 'Sentence is Incomplete' : 'Sentence is Completed';

      if (!this.invalidSentence) {
        this.entityIndex = 0;
        /* istanbul ignore next */
        if (this.CUObj?.agent?.type) {
          this.indcudata.agents[0].agentType = this.CUObj?.agent?.type;
        }
      }
      this.checkForUnSavedAttributes();
      this.createMultipleEntities();

      setTimeout(() => {
        this.alertModal = false;
      }, 2000);
    }
  }

  getInformationExtraction() {
    this.changeUnitFacade.compositeInfoExtraction$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      res.sentence_tags?.forEach((sentence: any) => {
        // sentence['displayTag'] = sentence['tag'];
        switch (sentence['tag']) {
          case 'cu':
            sentence['tag'] = 'Intent';
            break;
          case 'entity':
            sentence['tag'] = 'Obj.';
            break;
          case 'attributes':
            sentence['tag'] = 'Att.';
            break;
          case 'human agent':
            sentence['tag'] = 'Hum.';
            break;
          case 'machine agent':
            sentence['tag'] = 'Mch.';
            break;
        }
      });
      this.layerType = 'information';
      this.entityIndex = 0;
      /* istanbul ignore next */
      this.CUObj = res?.extraction?.CU_list[0];
      this.informationLayerSentence = res.sentence_tags;
      /* istanbul ignore next */
      if (this.indcudata?.sentenceTags) {
        this.indcudata.sentenceTags.information = res.sentence_tags;
      }
      /* istanbul ignore next */
      if (this.indcudata?.layers && this.indcudata.layers?.length > 0) {
        this.resetLayerEntity();
      }
      this.invalidSentence = false;

      this.CUObj.entities?.forEach((entity: any) => {
        /* istanbul ignore else */
        if (entity.name === '$missing') {
          this.invalidSentence = true;
        }
      });
      if (!this.invalidSentence) {
        this.checkForUnSavedAttributes();
        this.createMultipleEntities();
      }
    });
  }

  getCuInfo(event: ChangeUnit) {
    event.isEditable = true;
    this.isValidCuDisplay = !this.isValidCuDisplay;
  }

  openInfoLayers() {
    this.isInfoOpen = true;
    this.isInfo = true;
    this.clickThreeDotInfo = false;
  }

  resetLayerEntity() {
    let currentCULayers = this.indcudata.layers.find((x: any) => x.type === this.layerType);
    /* istanbul ignore else */
    if (currentCULayers) {
      currentCULayers.participatingItems = [];
    }
    this.changeUnitFacade.resetLayers(this.indcudata.id, this.layerType);
  }

  createMultipleEntities() {
    this.tempEntityIndex = 0;
    /* istanbul ignore next */
    if (this.CUObj?.entities?.length > 0) {
      this.entityArr = [];
      this.tempExistingEntityList = [];
      this.CUObj?.entities?.forEach((entity: any, index: number) => {
        if (!entity?.dsdId) {
          let createEntityObj: any = {
            isMultiValue: false,
            layerType: this.layerType,
            description: '',
            nslAttributes: [],
            name: entity?.name?.trim(),
          };
          entity.attributes_list?.forEach((attr: any) => {
            let attribute = this.getAttributeObj(attr?.name);
            createEntityObj.nslAttributes.push(attribute);
          });

          if (createEntityObj.nslAttributes.length > 0) {
            this.entityArr.push(createEntityObj);
          }
        } else {
          this.tempExistingEntityList.push(entity);
        }
      });
      this.getExistingEntityData();
    }
  }
  getAttributeObj(attr: string) {
    let uiElement: uiElementObj = {
      name: 'text',
      dataType: 'string',
      uiElement: 'text',
      isMulti: false,
      properties: [],
      esDataType: 'string',
    };
    let attributeType: AttributeType = {
      uiElement,
      type: 'string',
      properties: {},
      nestedNslDataTypeProperties: {
        item_type: {},
      },
      extendedProperties: {},
    };
    /* istanbul ignore next */
    let attribute: nslAttribute = {
      name: attr?.trim(),
      displayName: attr?.trim(),
      attributeType,
    };
    return attribute;
  }

  getExistingEntityData() {
    /* istanbul ignore next */
    if (this.tempExistingEntityList?.length > 0) {
      if (this.tempExistingEntityList[this.tempEntityIndex]) {
        let foundUnSavedAttribute = this.tempExistingEntityList[this.tempEntityIndex].attributes_list?.find(
          (x: any) => !x?.dsdId
        );

        if (!foundUnSavedAttribute) {
          this.nodeEntityFacadeService.loadEntityDataById(
            this.tempExistingEntityList[this.tempEntityIndex]?.dsdId,
            this.layerType,
            /* istanbul ignore next */
            this.CUObj.entities?.length - 1 === this.tempEntityIndex
          );
        } else {
          this.updateEntity();
        }
      } else {
        this.changeUnitFacade.saveEntityEvent();
      }
    } else {
      this.changeUnitFacade.saveEntityEvent();
    }
  }

  detectLastEntitytoSave() {
    this.changeUnitFacade.saveEntity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (this.entityArr?.length > 0 && res) {
        this.indcudata.description = this.sentenceString;
        this.nodeEntityFacadeService.saveMultipleEntities(
          this.entityArr,
          this.indcudata,
          false,
          this.layerType,
          true,
          false,
          -1,
          true
        );
      } else {
        let currentCU = {
          ...this.indcudata,
        };
        this.changeUnitFacade.updateChangeUnit(currentCU, false, true);
      }
    });
  }
  detectEntResponse() {
    this.nodeEntityFacadeService.separateEntityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        let foundCu = this.indcudata;
        /* istanbul ignore else */
        if (foundCu) {
          if (this.isSelectedFromDropDown != undefined && !this.isSelectedFromDropDown) {
            foundCu.isReserved = false;
          } else if (this.isSelectedFromDropDown != undefined && this.isSelectedFromDropDown) {
            foundCu.isReserved = true;
          }
          /* istanbul ignore next */
          if (res?.agent) {
            foundCu.agents[0].agentType = res?.agent;
          }

          let foundLayerIndex = foundCu.layers.findIndex((x: any) => x.type === res.layer);
          foundCu.sentenceTags = res.sentenceTags;
          /* istanbul ignore next */
          if (foundCu.layers?.length === 0 || foundLayerIndex === -1) {
            let physicalLayerEntity: any = [];
            res.result?.forEach((entity: any) => {
              let entObj = JSON.parse(JSON.stringify(entity));
              physicalLayerEntity.push(entObj);
            });
            if (physicalLayerEntity?.length > 0) {
              foundCu.layers.push({ type: res.layer, participatingItems: physicalLayerEntity });
            }
          } else if (foundLayerIndex !== -1) {
            res.result?.forEach((entity: any) => {
              let foundEntityIndex = foundCu.layers[foundLayerIndex].participatingItems.findIndex(
                (x: any) => x.id === entity.id
              );
              let entObj = JSON.parse(JSON.stringify(entity));
              if (foundEntityIndex !== -1) {
                foundCu.layers[foundLayerIndex].participatingItems[foundEntityIndex] = entObj;
              } else {
                // let slotItem: any = { item: { DATA: entity, TYPE: 'GeneralEntity' } };
                foundCu.layers[foundLayerIndex].participatingItems.push(entObj);
              }
            });
          }
          /* istanbul ignore next */
          if (res.lastEntity) {
            this.changeUnitFacade.updateChangeUnit(foundCu, false, true);
          }
        }
      }
    });
  }

  checkForUnSavedAttributes() {
    let foundEntity = false;
    /* istanbul ignore next */
    this.CUObj.entities?.forEach((x: any, index: number) => {
      if (x?.dsdId && index >= this.entityIndex && !foundEntity) {
        this.entityIndex = index;
        foundEntity = true;
      }
    });
    /* istanbul ignore else */
    if (foundEntity) {
      this.showConfigDropdown = false;
      this.currentEntity = this.CUObj.entities[this.entityIndex];
      this.newAttributeList = [];
      /* istanbul ignore next */
      this.currentEntity.attributes_list?.forEach((attr: any) => {
        /* istanbul ignore else */
        if (!attr?.dsdId) {
          this.showConfigDropdown = true;
          this.newAttributeList.push(attr);
        }
        /* istanbul ignore else */
        if (attr.name === '$missing') {
          this.invalidSentence = true;
        }
      });
      /* istanbul ignore next */
      this.entityRename = this.currentEntity?.name;
    } else {
    }
  }
  closeTagsView(type: any) {
    if (type == 'physical') {
      this.indcudata.showTagsView = false;
    } else if (type == 'information') {
      this.indcudata.showInfoTagsView = false;
      this.isInfo = true;
    } else if (type == 'triggerCES') {
      this.indcudata.showTriggerTagsView = false;
    }
    this.clickThreeDot = false;
  }
  onNextClick() {
    this.indcudata.isEditable = false;
    this.isValidCuDisplay = false;
  }

  openPopUp(type: any) {
    if (type === 'physical') {
      this.clickThreeDot = true;
    } else if (type === 'information') {
      this.clickThreeDotInfo = true;
      this.isInfo = true;
    } else {
      this.clickThreeDotTrigger = true;
      this.isTrigger = true;
    }
  }
  closePopup(type: any) {
    if (type === 'physical') {
      this.clickThreeDot = false;
    } else if (type === 'information') {
      this.clickThreeDotInfo = false;
    } else {
      this.clickThreeDotTrigger = false;
    }
  }
  // detectPublishedCU() {
  //   this.changeUnitFacade.cuFinishResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
  //     /* istanbul ignore else */
  //     if (res) {
  //       this.changeUnitFacade.publishCU(res);
  //       this.router.navigate(['/mylibrary-dashboard']);
  //     }
  //   });
  // }
  detectSaveIndEntityconf() {
    this.nodeEntityFacadeService.saveIndentityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res?.result) {
        let entityObj: any = {};
        entityObj = JSON.parse(JSON.stringify(res.result));
        if (res.layerChanged) {
          /* istanbul ignore else */
          if (res.oldLayer != res.layer) {
            /* istanbul ignore next */
            let oldLayerIndex = this.indcudata?.layers?.findIndex((x: any) => x.type === res.oldLayer);
            /* istanbul ignore next */
            if (oldLayerIndex !== -1) {
              let foundEntityIndex = this.indcudata?.layers?.[oldLayerIndex]?.participatingItems?.findIndex(
                (x: any) => x?.id === entityObj?.id
              );
              this.indcudata.layers[oldLayerIndex].participatingItems.splice(foundEntityIndex, 1);
            }
          }
        }
        let foundLayerIndex = this.indcudata.layers.findIndex((x: any) => x.type === res.layer);
        /* istanbul ignore else */
        if (this.indcudata?.layers?.length === 0 || foundLayerIndex === -1) {
          let physicalLayerEntity: any = [];
          physicalLayerEntity.push(entityObj);
          this.indcudata.layers.push({
            type: res.layer ? res.layer : 'physical',
            participatingItems: physicalLayerEntity,
          });
        } else {
          if (foundLayerIndex !== -1) {
            let foundEntityIndex = this.indcudata?.layers?.[foundLayerIndex]?.participatingItems?.findIndex(
              (x: any) => x?.id === entityObj?.id
            );
            /* istanbul ignore next */
            if (foundEntityIndex !== -1) {
              this.indcudata.layers[foundLayerIndex].participatingItems[foundEntityIndex] = entityObj;
            } else {
              this.indcudata.layers[foundLayerIndex].participatingItems.push(entityObj);
            }
          }
        }
        this.changeUnitFacade.updateChangeUnit(this.indcudata, false, true);
      }
    });
  }
  detectSavedCu() {
    this.changeUnitFacade.indCuUpdatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        res = res.data;
        res.showInfoTagsView = this.indcudata?.showInfoTagsView;
        if (res.sentenceTags?.physical) this.physicalLayerSentence = res.sentenceTags?.physical;
        if (res.sentenceTags?.information) this.informationLayerSentence = res.sentenceTags?.information;
        if (res.sentenceTags?.triggerCES) this.triggerCESLayerSentence = res.sentenceTags?.triggerCES;
        res.showTagsView = this.indcudata?.showTagsView;
        res.isEditable = this.indcudata?.isEditable;
        res.showTriggerTagsView = this.indcudata?.showTriggerTagsView;
        this.indcudata = res;
        if (this.cuSentence.startsWith(res.name) || this.indcudata?.designTimeRights?.length != 0) {
          this.cuSentence = '';
        } else {
          let userArr = this.cuSentence.split(res.name);
          userArr = userArr[0].split(' ');
          this.cuSentence = '';
          userArr.forEach((user: any) => {
            this.cuSentence += user + ' ';
          });
        }
        this.indcudata.sentenceTags?.physical?.forEach((sentence: any) => {
          this.cuSentence += sentence.token + ' ';
        });
        this.cuSentence = this.cuSentence.trim() === '' ? this.indcudata?.description : this.cuSentence.trim();
        /* istanbul ignore else */
        if (this.indcudata.showInfoTagsView || this.indcudata.sentenceTags?.information) {
          this.cuInfoSentence = '';
          this.informationLayerSentence = [];
          this.indcudata.sentenceTags?.information?.forEach((sentence: any) => {
            this.cuInfoSentence += sentence.token + ' ';
          });
          this.indcudata.showInfoTagsView = true;
          this.informationLayerSentence = this.indcudata.sentenceTags?.information;
          if (this.indcudata.sentenceTags?.information && this.indcudata?.sentenceTags.information?.length > 0) {
            this.isInfoOpen = true;
          }
        }
        /* istanbul ignore else */
        if (this.indcudata.showTriggerTagsView || this.isTrigger) {
          this.triggerSentence = '';
          this.indcudata.sentenceTags?.triggerCES?.forEach((sentence: any) => {
            this.triggerSentence += sentence.token + ' ';
            this.isTrigger = false;
          });
        }
        /* istanbul ignore else */
        this.emitFinalCUForParallel.emit(this.indcudata);
        this.currentPath.name = this.indcudata.name;
      }
    });
  }
  detectEntityData() {
    this.nodeEntityFacadeService.entityData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        let entityObj: any = {};
        let currentEntity = this.CUObj?.entities.find((x: any) => x?.dsdId === res.id);
        /* istanbul ignore else */
        if (currentEntity) {
          entityObj = JSON.parse(JSON.stringify(res));
          entityObj.nslAttributes = [];

          currentEntity?.attributes_list?.forEach((attr: any) => {
            let foundAttr = res.nslAttributes.find((x: any) => x.name === attr.name);
            /* istanbul ignore else */
            if (foundAttr) {
              entityObj.nslAttributes.push(foundAttr);
            }
          });

          let foundLayerIndex = this.indcudata?.layers.findIndex((x: any) => x.type === res.layer);

          if (this.indcudata?.layers?.length === 0 || foundLayerIndex === -1) {
            let physicalLayerEntity: any = [];
            physicalLayerEntity.push(entityObj);
            this.indcudata.layers.push({ type: res.layer, participatingItems: physicalLayerEntity });
          } else {
            /* istanbul ignore else */
            if (foundLayerIndex !== -1) {
              let foundEntityIndex = this.indcudata.layers[foundLayerIndex].participatingItems.findIndex(
                (x: any) => x.id === entityObj.id
              );
              if (foundEntityIndex !== -1) {
                entityObj.nslAttributes?.forEach((attribute: any) => {
                  const foundAttribute = this.indcudata.layers[foundLayerIndex].participatingItems[
                    foundEntityIndex
                  ].nslAttributes.find((x: any) => x.id === attribute.id);
                  attribute.nslAttributeMetaData = foundAttribute
                    ? foundAttribute.nslAttributeMetaData
                    : attribute.nslAttributeMetaData;
                });
                this.indcudata.layers[foundLayerIndex].participatingItems[foundEntityIndex] = entityObj;
              } else {
                this.indcudata.layers[foundLayerIndex].participatingItems.push(entityObj);
              }
            }
          }
          this.tempEntityIndex = this.tempEntityIndex + 1;
          this.getExistingEntityData();
        }
      }
    });
  }
  updateEntity() {
    this.showConfigDropdown = false;
    this.nodeEntityFacadeService
      .getEntityDeatils(this.currentEntity?.dsdId, false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          let fetchedEntity = res.result;
          fetchedEntity.isSeperateEntity = true;
          this.currentEntity.attributes_list?.forEach((attr: any) => {
            /* istanbul ignore else */
            if (!attr?.dsdId) {
              let attribute = getAttributeObj(attr?.name);
              fetchedEntity.nslAttributes.push(attribute);
            }
          });
          this.nodeEntityFacadeService.saveEntity(
            fetchedEntity,
            false,
            this.indcudata,
            this.currentEntity.attributes_list,
            this.layerType
          );
        }
      });
  }

  addConnector() {}

  reservedCuSelection(selection: any, extraction?: any) {
    this.indcudata.isReserved = true;
    let rcuType = extraction?.tf_details?.reservedCUType;
    let reserveCuName = extraction?.reserved_cu_name;
    if (reserveCuName?.includes('NSL_Template_Search')) {
      this.indcudata.reservedCUType = 'search';
    } else if (reserveCuName?.includes('NSL_Template_Update')) {
      this.indcudata.reservedCUType = 'update';
    } else if (reserveCuName?.includes('NSL_Template_Create')) {
      this.indcudata.reservedCUType = 'create';
    } else if (rcuType) {
      this.indcudata.reservedCUType = rcuType;
    }
    this.isReserved = false;
    this.formatTags(this.extractedPhysicalLayer);
  }
  Cancel() {
    this.indcudata.isReserved = false;
    this.isReserved = false;
    this.indcudata.reservedCUType = '';
    let foundEntityIndex = this.indcudata.layers.findIndex((x: any) => x.type == 'triggerCES');
    /* istanbul ignore else */
    if (foundEntityIndex !== -1) {
      this.indcudata.layers.splice(foundEntityIndex, 1);
    }
    /* istanbul ignore next */
    if (this.indcudata?.sentenceTags?.triggerCES) {
      delete this.indcudata.sentenceTags['triggerCES'];
      this.showTriggerCES = false;
      this.indcudata.showTriggerTagsView = false;
      this.triggerSentence = '';
    }
    this.formatTags(this.extractedPhysicalLayer);
  }

  addTriggerCesLayer() {
    this.showTriggerCES = true;
    this.isTrigger = true;
    this.clickThreeDotTrigger = false;
  }
  validateTC() {
    this.indcudata.showTriggerTagsView = true;
    let formData: FormData = new FormData();
    formData.append('text', this.triggerSentence);
    // let res = getParticipatingItems(this.indcudata, 'triggerCES', this.triggerSentence);
    this.changeUnitFacade.validateTC(formData, true);
  }

  triggerCesExtraction() {
    this.changeUnitFacade.indCompositeTriggerExtraction$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.layerType = 'triggerCES';
      res.sentence_tags?.forEach((sentence: any) => {
        switch (sentence['tag']) {
          case 'cu':
            sentence['tag'] = 'Intent';
            break;
          case 'entity':
            sentence['tag'] = 'Obj.';
            break;
          case 'attributes':
            sentence['tag'] = 'Att.';
            break;
          case 'human agent':
            sentence['tag'] = 'Hum.';
            break;
          case 'machine agent':
            sentence['tag'] = 'Mch.';
            break;
        }
      });

      this.entityIndex = 0;

      this.CUObj = res?.extraction?.CU_list[0];
      this.triggerCESLayerSentence = res.sentence_tags;

      if (this.indcudata?.sentenceTags) {
        this.indcudata.sentenceTags[this.layerType] = res.sentence_tags;
      } else {
        this.indcudata.sentenceTags = {
          information: res?.sentence_tags,
        };
      }
      /* istanbul ignore next */
      if (this.indcudata?.layers && this.indcudata.layers?.length > 0) {
        this.resetLayerEntity();
      }
      this.invalidSentence = false;
      /* istanbul ignore next */
      this.CUObj.entities?.forEach((entity: any) => {
        if (entity.name === '$missing') {
          this.invalidSentence = true;
        }
      });
      /* istanbul ignore else */
      if (!this.invalidSentence) {
        this.checkForUnSavedAttributes();
        this.createMultipleEntities();
      }
    });
    this.indcudata.showTriggerTagsView = true;
  }

  detectAddedAttributeInEntity() {
    this.nodeEntityFacadeService.saveentityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next */
      if (res) {
        /*istanbul ignore next*/
        let foundCu = this.indcudata;
        if (foundCu) {
          /*istanbul ignore next*/
          foundCu.layers.forEach((layer: any) => {
            if (this.layerType === layer.type) {
              layer.participatingItems.push(res.result);
            }
          });
          this.changeUnitFacade.updateChangeUnit(foundCu, false, true);
        }
      }
    });
  }
  detectCuFromLibrary() {
    this.changeUnitFacade.cuDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.indcudata = res;
        /* istanbul ignore else */
        if (this.indcudata?.isReserved) {
          this.showTriggerCES = true;
        }
        if (this.indcudata?.sentenceTags) {
          /* istanbul ignore next */
          if (this.indcudata?.sentenceTags?.physical?.length > 0) {
            this.indcudata.sentenceTags.physical = this.formatSentenceTags(this.indcudata?.sentenceTags?.physical);
            this.physicalLayerSentence = this.indcudata?.sentenceTags?.physical;
            this.indcudata.sentenceTags?.physical?.forEach((sentence: any) => {
              this.cuSentence += sentence.token + ' ';
            });
            this.indcudata.showTagsView = true;
          }
          /* istanbul ignore next */
          if (this.indcudata?.sentenceTags?.information?.length > 0) {
            this.indcudata.sentenceTags.information = this.formatSentenceTags(
              this.indcudata?.sentenceTags?.information
            );
            this.informationLayerSentence = this.indcudata?.sentenceTags?.information;
            this.indcudata.sentenceTags?.information?.forEach((sentence: any) => {
              this.cuInfoSentence += sentence.token + ' ';
            });
            this.isInfoOpen = true;
            this.indcudata.showInfoTagsView = true;
          }
          /* istanbul ignore next */
          if (this.indcudata?.sentenceTags?.triggerCES?.length > 0) {
            this.indcudata.sentenceTags.triggerCES = this.formatSentenceTags(this.indcudata?.sentenceTags?.triggerCES);
            this.triggerCESLayerSentence = this.indcudata?.sentenceTags?.triggerCES;
            this.indcudata.sentenceTags?.triggerCES?.forEach((sentence: any) => {
              this.triggerSentence += sentence.token + ' ';
            });
            this.indcudata.showTriggerTagsView = true;
          }
        }
        if (!this.indcudata?.sentenceTags) {
          this.cuSentence = '';
          this.cuSentence = this.indcudata?.name;
          this.indcudata.isEditable = true;
          this.indcudata.showTagsView = false;
        }
        this.emitFinalCUForParallel.emit(this.indcudata);
      }
    });
  }
  formatSentenceTags(res: any) {
    /* istanbul ignore next */
    res.sentence_tags?.forEach((sentence: any) => {
      switch (sentence['tag']) {
        case 'cu':
          sentence['tag'] = 'Intent';
          break;
        case 'entity':
          sentence['tag'] = 'Obj.';
          break;
        case 'attributes':
          sentence['tag'] = 'Att.';
          break;
        case 'human agent':
          sentence['tag'] = 'Hum.';
          break;
        case 'machine agent':
          sentence['tag'] = 'Mch.';
          break;
      }
    });
    return res;
  }

  detectSolutionDetails() {
    this.changeUnitFacade.flatIndividualSolutionDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.indcudata['querySentence'] = res.res;
        /* istanbul ignore next */
        res[0].physical.forEach((result: any) => {
          /* istanbul ignore next */
          if (result?.tag == 'cu' && result?.dsd_id) {
            this.indcudata['sentenceTags'] = res[0];
            this.indcudata['tCUsentenceName'] = res[0];
          }
          res[0].information?.forEach((result: any) => {
            /* istanbul ignore next */
            if (result?.tag == 'cu' && result?.dsd_id) {
              this.indcudata['sentenceTags'] = res[0];
              this.indcudata['tCUsentenceName'] = res[0];
            }
          });
          res[0].triggerCES?.forEach((result: any) => {
            /* istanbul ignore next */
            if (result?.tag == 'cu' && result?.dsd_id) {
              this.indcudata['sentenceTags'] = res[0];
              this.indcudata['tCUsentenceName'] = res[0];
            }
          });
        });
        this.changeUnitFacade.updateChangeUnit(this.indcudata, false, true);
      }
    });
  }
  callfat2Flatt(gsiData: any) {
    let activeLayer = 'physical';
    if (this.isTrigger) activeLayer = 'triggerCES';
    else if (this.isInfo) activeLayer = 'information';
    this.dataSend = getFlatData(gsiData, false, activeLayer);
    this.changeUnitFacade.getIndividualSolutionDetails(this.dataSend);
    this.isInfo = false;
    this.isTrigger = false;
  }

  showInfo() {
    this.indcudata.showInfo = true;
  }
  hideInfo() {
    this.indcudata.showInfo = false;
  }
  addAgentFromLib() {
    this.nodeGsiFacadeService.selectedAgentinCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      console.log(res);
      /* istanbul ignore else */
      if (res && this.indcudata.isEditable) {
        let designInfo = constructRightsObj(res, 'design');
        let txnInfo = constructRightsObj(res, 'txn');
        /* istanbul ignore next */
        if (this.indcudata?.designTimeRights?.length > 0) {
          this.cuSentence = ',' + this.cuSentence;
        }
        this.indcudata.designTimeRights.push(designInfo);
        this.indcudata.txnTimeRights.push(txnInfo);
        /* istanbul ignore next */
        this.cuSentence = res?.name + ' ' + this.cuSentence;
        this.indcudata.description = this.cuSentence;
        this.validateCu(this.indcudata.description);
        this.nodeGsiFacadeService.CuIdForAgent = undefined;
      }
    });
  }

  detectCancelReview() {
    this.nodeGsiFacadeService.cuDetailsByGsiId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.indcudata = res;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.nodeEntityFacadeService.setIndividualCudata('');
  }
}

export interface Entity {
  isMultiValue: boolean;
  layerType: string;
  description: string;
  nslAttributes: nslAttribute[];
  name: string;
}

export interface nslAttribute {
  name: string;
  displayName: string;
  attributeType: AttributeType;
}

export interface AttributeType {
  uiElement: uiElementObj;
  type: string;
  properties: {};
  nestedNslDataTypeProperties: {
    item_type: {};
  };
  extendedProperties: {};
}

export interface uiElementObj {
  name: string;
  dataType: string;
  uiElement: string;
  isMulti: boolean;
  properties: any;
  esDataType: string;
}
