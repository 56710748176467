<h3 mat-dialog-title>Select your Role</h3>
<div mat-dialog-content>
  <!-- <mat-form-field class="roleSelectedForGSIExecutionName">
    <mat-select
      name="roleSelectedForGSIExecutionName"
      id="roleSelectedForGSIExecutionName"
      [(ngModel)]="roleSelectedForGSIExecution"
   [compareWith]="objectComparisonFunction"
    >
      <mat-option *ngFor="let role of data.roleSelectionList" [value]="role"> {{ role.name }}</mat-option>
    </mat-select>
  </mat-form-field> -->
  <label for="roles">Select Role</label>
  <mat-select
   matNativeControl
   name="roles"
    id="roles" 
  [(ngModel)]="roleSelectedForGSIExecution"
  >
    <mat-option 
    *ngFor="let role of data.roleSelectionList" 
    [value]="role" 
    > {{ role.name }} </mat-option>
  </mat-select>
</div>
<div mat-dialog-actions>
  <div class="proceed_div">
    <button
      *ngIf="!data.roleSelectionMandatoryOnLogin"
      class="proceed_btn"
      mat-button
      name="skip_btn"
      id="skip_btn"
      (click)="skip()"
    >
      Skip
    </button>
    <button
      class="proceed_btn"
      mat-button
      name="proceed_btn"
      id="proceed_btn"
      [disabled]="!roleSelectedForGSIExecution"
      (click)="submit()"
    >
      <!-- [disabled]="data.roleSelectionMandatoryOnLogin && !roleSelectedForGSIExecution" -->
      Proceed
    </button>
    <!-- roleSelectionMandatoryOnLogin is false then we can proceed further witout selecting role, if true then select role - roleSelectedForGSIExecution should have data -->
  </div>
</div>
