import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeUnitFacadeService } from '../change-unit/change-unit-facade.service';
import { EventsFacadeService } from '../events/events-facade.service';
import { LoaderService } from '../loader/loader.service';
import { transactionChangeUnitDeMapper } from '../mappers/transaction/transaction-cu-demapper';
import { transactionChangeUnitMapper } from '../mappers/transaction/transaction-cu-mapper';
import { Gsi } from '../modal/gsi';
import { paginationDetails } from '../modal/notepad.modal';
import { AlertService } from '../toaster/alert.service';
import { TransactionEndpointService } from './transaction-endpoint.service';
import { EventsEndpointService } from '../events/events-endpoint.service';
import { UsersFacadeService } from '../users/users-facade.service';
import { AuthenticationService } from '../cdui-base/authentication.service';

type EventStateStore = {
  parentCuMasterId: any;
  isPopupEvent: any;
  EventGsiId: any;
  parentDesign: any;
  parentGsiId: any;
  eventGsiMasterId: any;
};

type parentGsiId = any;

@Injectable({
  providedIn: 'root',
})
export class TransactionFacadeService implements OnDestroy {
  isOnChangeEventTriggered: any = {};
  gsi: Gsi;
  tablerow = 0;
  changeUnitData: any;
  currentTab = 'myCollection';
  targetEnt: any = [];
  nextChangeUnitData = {};
  eventGsiData: any;
  pendingGsiData = new Array();
  nextEventData: any;
  eventCompletedData: any;
  isCarnival = false;
  paginationDetails = {} as paginationDetails;
  selectedTableRecords: any = [];
  previousPageNumber: any;
  selectedTableAtrributeRecords: any = [];
  presentPageNumber: any = 0;
  resumeApiData: any = null;
  dataForCheckForEvents: any = null;
  selectedCUForEvent: any = null;
  tempPostData: any;
  selectedTransactions: any = [];
  selectedCradsArray: any = {};
  labels: any;
  MessageData: any;
  depDropdownPayloadOnLoad: any;
  depDropdownPayloadOnChange: any;
  cuData: any;
  isDebug: boolean = false;
  facetedData: any;
  isLogoutConfigGsi = false;
  // for optional card and table submit
  selectedCardsRecordList: any[] = [];
  selectedTableRecordList: any[] = [];
  public logoutConfigEnableSbuject = new Subject();
  logoutConfigEnableSbuject$ = this.logoutConfigEnableSbuject.asObservable();
  public logoutSubject = new Subject();
  logoutSubject$ = this.logoutSubject.asObservable();
  public logoutConfigGsiSbuject = new BehaviorSubject(false);
  logoutConfigGsiSbuject$ = this.logoutConfigGsiSbuject.asObservable();
  gsiPopup = new Subject();
  gsiPopup$ = this.gsiPopup.asObservable();
  openPopup = new Subject();
  openPopup$ = this.openPopup.asObservable();
  isMccPopup: boolean = false;
  mccPopup = new Subject();
  mccPopup$ = this.mccPopup.asObservable();
  /*private gsiSubject = new BehaviorSubject(this.gsi);
  gsiData$ = this.gsiSubject.asObservable();*/
  carnivalScreen = new BehaviorSubject(false);
  carnivalScreen$ = this.carnivalScreen.asObservable();
  bookSubject = new BehaviorSubject('');
  bookData$ = this.bookSubject.asObservable();

  layerType = new Subject();
  layerType$ = this.layerType.asObservable();

  changeOfTabSubject = new BehaviorSubject(this.currentTab);
  currentTab$ = this.changeOfTabSubject.asObservable();
  nextChangeUnitSubject = new Subject();
  nextChangeUnit$ = this.nextChangeUnitSubject.asObservable();
  resumeTransactionSubject = new BehaviorSubject('');
  resumeTransactionData$ = this.resumeTransactionSubject.asObservable();

  public pendingTransactions = new BehaviorSubject('');
  pendingTransactions$ = this.pendingTransactions.asObservable();

  createdTransactions = new BehaviorSubject('');
  createdTransactions$ = this.createdTransactions.asObservable();
  assignedTransactions = new BehaviorSubject('');
  assignedTransactions$ = this.assignedTransactions.asObservable();

  escalatedTransactions = new BehaviorSubject('');
  escalatedTransactions$ = this.escalatedTransactions.asObservable();

  currentCuDetails = new BehaviorSubject('');
  currentCuDetails$ = this.currentCuDetails.asObservable();

  listChangeUnitSubject = new Subject();
  listChangeUnit$ = this.listChangeUnitSubject.asObservable();

  // conditional potentiality
  ConditionalPotentialitySubject = new Subject();
  ConditionalPotentialityAttr$ = this.ConditionalPotentialitySubject.asObservable();

  facetSearchResult = new Subject();
  facetSearchResult$ = this.facetSearchResult.asObservable();

  transactionHistoryCuList = new Subject();
  transactionHistoryCuList$ = this.transactionHistoryCuList.asObservable();

  transactionHistoryEmbeddedCuList = new Subject();
  transactionHistoryEmbeddedCuList$ = this.transactionHistoryEmbeddedCuList.asObservable();

  transactionHistoryNestedCuList = new Subject();
  transactionHistoryNestedCuList$ = this.transactionHistoryNestedCuList.asObservable();

  transactionHistoryEntityList = new Subject();
  transactionHistoryEntityList$ = this.transactionHistoryEntityList.asObservable();

  transactionHistoryEmbeddedEntityList = new Subject();
  transactionHistoryEmbeddedEntityList$ = this.transactionHistoryEmbeddedEntityList.asObservable();

  private reservedActionsList = new Subject();
  reservedActionsList$ = this.reservedActionsList.asObservable();
  // advance search in pending txn
  private advanceSearchSubject = new Subject();
  advanceSearchTxn$ = this.advanceSearchSubject.asObservable();

  private allTransactions = new Subject();
  allTransactions$ = this.allTransactions.asObservable();

  transactionAds = new BehaviorSubject('');
  transactionAds$ = this.transactionAds.asObservable();

  public preventAlert: Subject<boolean> = new Subject<boolean>();
  preventAlert$ = this.preventAlert.asObservable();

  addMultiEntities = new Subject<any>();
  addMultiEntities$ = this.addMultiEntities.asObservable();

  transactionBottomAds = new BehaviorSubject('');
  transactionBottomAds$ = this.transactionBottomAds.asObservable();

  private filteredTransactions = new Subject();
  filteredTransactions$ = this.filteredTransactions.asObservable();

  dynamicFormSubmit = new Subject();
  dynamicFormSubmit$ = this.dynamicFormSubmit.asObservable();

  previousCUlist = new Subject();
  previousCUlist$ = this.previousCUlist.asObservable();

  //Submit button disable
  disbaleSubmitButton = new BehaviorSubject(false);
  disbaleSubmitButton$ = this.disbaleSubmitButton.asObservable();

  //disable submit button
  disableSubmitButtonFlag = new BehaviorSubject(false);
  disableSubmitButtonFlag$ = this.disableSubmitButtonFlag.asObservable();

  //Submit button disable integer
  disbaleSubmitButtonInteger = new BehaviorSubject(false);
  disbaleSubmitButtonInteger$ = this.disbaleSubmitButtonInteger.asObservable();

  disbaleSubmitButtonTimeOnly = new BehaviorSubject(false);
  disbaleSubmitButtonTimeOnly$ = this.disbaleSubmitButtonTimeOnly.asObservable();

  //Submit button disable
  recordVideoDisable = new BehaviorSubject(false);
  recordVideoDisable$ = this.recordVideoDisable.asObservable();

  //submit button disable
  disableFormEwa = new BehaviorSubject(false);
  disableFormEwa$ = this.disableFormEwa.asObservable();

  //Submit button disable
  recordAudioDisable = new BehaviorSubject(false);
  recordAudioDisable$ = this.recordAudioDisable.asObservable();

  imageCaptureDisable = new BehaviorSubject(false);
  imageCaptureDisable$ = this.imageCaptureDisable.asObservable();

  locationDetails = new Subject();
  locationDetails$ = this.locationDetails.asObservable();

  redirectWithoutErrorPopup = new Subject();
  redirectWithoutErrorPopup$ = this.redirectWithoutErrorPopup.asObservable();

  // subtransaction entity
  private SubtransactionalEntityList = new Subject();
  SubtransactionalEntityList$ = this.SubtransactionalEntityList.asObservable();

  pendingTransaction = new BehaviorSubject('');
  pendingTransaction$ = this.pendingTransaction.asObservable();

  waitForTransaction = new Subject();
  waitForTransaction$ = this.waitForTransaction.asObservable();

  transactionViewType = new BehaviorSubject(false);
  transactionViewType$ = this.transactionViewType.asObservable();
  //scheduled jobs
  scheduledJobs = new Subject();
  scheduledJobs$ = this.scheduledJobs.asObservable();

  jobTriggers = new Subject();
  jobTriggers$ = this.jobTriggers.asObservable();

  jobExecutions = new Subject();
  jobExecutions$ = this.jobExecutions.asObservable();

  minSelection = new Subject();
  minSelection$ = this.minSelection.asObservable();

  allTriggers = new Subject();
  allTriggers$ = this.allTriggers.asObservable();

  unscheduleJobs = new Subject();
  unscheduleJobs$ = this.unscheduleJobs.asObservable();

  unscheduleTriggers = new Subject();
  unscheduleTriggers$ = this.unscheduleTriggers.asObservable();

  pauseTriggerSubject = new Subject();
  pauseTriggerSubject$ = this.pauseTriggerSubject.asObservable();
  resumeTriggerSubject = new Subject();
  resumeTriggerSubject$ = this.resumeTriggerSubject.asObservable();

  pauseJobSubject = new Subject();
  pauseJobSubject$ = this.pauseJobSubject.asObservable();
  resumeJobSubject = new Subject();
  resumeJobSubject$ = this.resumeJobSubject.asObservable();

  eventGsiDataSubject = new BehaviorSubject('');
  eventGsiData$ = this.eventGsiDataSubject.asObservable();

  stopResumeTransactionSubject = new BehaviorSubject(false);
  stopResumeTransaction$ = this.stopResumeTransactionSubject.asObservable();

  pendingGsiArraySubject = new BehaviorSubject('');
  pendingGsiArray$ = this.pendingGsiArraySubject.asObservable();

  updateTransactionIdData = new BehaviorSubject('');
  updateTransactionIdData$ = this.updateTransactionIdData.asObservable();

  storeARFile = new BehaviorSubject('');
  storeARFile$ = this.storeARFile.asObservable();

  debug = new Subject();
  debug$ = this.debug.asObservable();

  debugValues = new Subject();
  debugValues$ = this.debugValues.asObservable();
  isTransactionCompleted = new BehaviorSubject(false);
  isTransactionCompleted$ = this.isTransactionCompleted.asObservable();

  private unsubscribe$: Subject<any> = new Subject<any>();
  eventAttributeData: any;

  listOfAllComments = new BehaviorSubject([]);
  listOfAllComments$ = this.listOfAllComments.asObservable();

  saveSubtransactionalData = new BehaviorSubject('');
  saveSubtransactionalData$ = this.saveSubtransactionalData.asObservable();

  milestoneToPendingTxn = new BehaviorSubject('');
  milestoneToPendingTxn$ = this.milestoneToPendingTxn.asObservable();

  submitButtonEmit = new Subject();
  submitButtonEmit$ = this.submitButtonEmit.asObservable();

  submitForlocation = new BehaviorSubject(false);
  submitForlocation$ = this.submitForlocation.asObservable();

  submitButtonDisabled = new Subject();
  submitButtonDisabled$ = this.submitButtonDisabled.asObservable();

  transactionSmartSolutionData = new Subject();
  transactionSmartSolutionData$ = this.transactionSmartSolutionData.asObservable();

  closeLoopOfAr = new BehaviorSubject('');
  closeLoopOfAr$ = this.closeLoopOfAr.asObservable();

  saveAsDraftreturnData = new Subject();
  saveAsDraftreturnData$ = this.saveAsDraftreturnData.asObservable();

  currentSelectedImages = new Subject();
  currentSelectedImages$ = this.currentSelectedImages.asObservable();

  locationData = new Subject();
  locationData$ = this.locationData.asObservable();

  cardsData = new BehaviorSubject([]);
  cardsData$ = this.cardsData.asObservable();

  pdfResize = new Subject();
  pdfResize$ = this.pdfResize.asObservable();
  debugPreviousCu = new Subject();
  debugPreviousCu$ = this.debugPreviousCu.asObservable();

  exitDebug = new Subject();
  exitDebug$ = this.exitDebug.asObservable();

  public savedCurrencyType: Map<string, any> = new Map<string, any>();

  cancelledTransactionData = new Subject();
  cancelledTransactionData$ = this.cancelledTransactionData.asObservable();

  filepreviewData = new BehaviorSubject({});
  filepreviewData$ = this.filepreviewData.asObservable();

  filepreviewDataTransaction = new Subject();
  filepreviewDataTransaction$ = this.filepreviewDataTransaction.asObservable();

  transactionTopAds = new Subject();
  transactionTopAds$ = this.transactionTopAds.asObservable();

  gsiObj = new Subject();
  gsiObj$ = this.gsiObj.asObservable();
  // public myEventEmitter: EventEmitter<any> = new EventEmitter();

  gsiMasterId: any;
  constructor(
    private router: Router,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private transactionEndpointService: TransactionEndpointService,
    private alertService: AlertService,
    private loader: LoaderService,
    private eventEndpointService: EventsEndpointService,
    private eventsFacadeService: EventsFacadeService,
    private userFacade: UsersFacadeService,
    private authenticationService: AuthenticationService,
  ) {
    this.getEventApiResponse();
    this.getDesignedBookData();
    this.detectLogoutConfigGsiFlag();
    this.eventApiFailed();
  }

  setLogoutConfEnableFlag(value: boolean) {
    localStorage.setItem('isLogoutConfigurationEnabled', value.toString());
  }

  logoutApplication() {
    this.logoutSubject.next(true);
  }

  setLogoutConfigGsiFlag(value: boolean) {
    this.logoutConfigGsiSbuject.next(value);
  }

  detectLogoutConfigGsiFlag() {
    this.logoutConfigGsiSbuject$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (typeof res == 'boolean') {
        this.isLogoutConfigGsi = res;
      }
    });
  }

  updateCarnivalScreen(data: boolean) {
    this.carnivalScreen.next(data);
    this.isCarnival = data;
  }

  //submit button disable
  disableSubmitButton(flage: boolean) {
    this.disbaleSubmitButton.next(flage);
  }
  //submit button disable
  disableSubmitButtonRecordVideo(flage: boolean) {
    this.recordVideoDisable.next(flage);
  }
  disableSubmitButtonInteger(data: any) {
    this.disbaleSubmitButtonInteger.next(data);
  }
  disableSubmitButtonTimeOnly(data: any) {
    this.disbaleSubmitButtonTimeOnly.next(data);
  }
  //Submit button disable
  disableSubmitButtonEwa(flag: boolean) {
    this.disableFormEwa.next(flag);
  }
  //submit button disable
  disableSubmitButtonRecordAudio(flage: boolean) {
    this.recordAudioDisable.next(flage);
  }
  //submit button disable
  disableSubmitButtonImageCapture(flage: boolean) {
    this.imageCaptureDisable.next(flage);
  }
  //close loop of ar
  setArData(data: any) {
    this.closeLoopOfAr.next(data);
  }
  changeOfTab(nextTab: string) {
    this.changeOfTabSubject.next(nextTab);
  }
  getDesignedBookData() {
    const designedBookData = JSON.parse(localStorage.getItem('layout-designSystem'));
    /* istanbul ignore next */
    if (designedBookData != null) {
      this.bookSubject.next(designedBookData);
    }
  }

  changeTransactionViewType(flag: boolean) {
    this.transactionViewType.next(flag);
  }

  saveSubtransaction(data: any) {
    this.saveSubtransactionalData.next(data);
  }

  /*UpdateGsiData(gsiData: Gsi) {
    this.gsiSubject.next(gsiData);
  }*/

  getPreviousCUData(queryParamsData: any, isDebugMode: boolean) {
    return this.transactionEndpointService.getPreviousCUData(queryParamsData, isDebugMode);
  }
  /* istanbul ignore next */
  updateLayersEntity(value: any) {
    this.layerType.next(value);
  }
  UpdateBookData(bookData: any) {
    this.bookSubject.next(bookData);
  }

  getChangeUnitByGsiId(gsiId: number, triggerCuId?: any, bookid?: any, isDebug?: any) {
    this.changeUnitFacadeService.getCusByGsiId(gsiId, false, triggerCuId, bookid, isDebug);
  }
  downloadFile(url: string, fileToBeDownloaded?: string) {
    /* istanbul ignore next */
    const download_index = url.indexOf('download=true');
    let downloadUrl = url;
    /* istanbul ignore next */
    if (download_index === -1) {
      downloadUrl = `${downloadUrl}`;
    }
    this.transactionEndpointService.downloadFile(downloadUrl, fileToBeDownloaded);
  }

  reAssignTxn(txnid: any, userid: any, assignComment: any, payload?: any) {
    this.transactionEndpointService
      .reAssign(txnid, userid, assignComment, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res && res.status && res.status == 200) {
            this.alertService.showToaster(`${res.message}`, '', 'success');
            this.isMccPopup = false;
            this.router.navigate(['/dashboard/pending-transactions']);
          }
          return res;
        },
        (error) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }

  showSuccessMessage(res: any) {
    this.alertService.showToaster(`${res.message}`, '', 'success');
    this.isMccPopup = false;
    this.router.navigate(['/dashboard/pending-transactions']);
  }

  escalateTxn(txnid: any, escalateComment?: any, payload?: any) {
    this.transactionEndpointService
      .escalateTxn(txnid, escalateComment, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res && res.status && res.status == 200) {
            this.showSuccessMessage(res);
          }
          return res;
        },
        (error) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }
  cancelAutoSubmitTxn(txnId: any, payload?: any) {
    this.transactionEndpointService
      .cancelAutoSubmit(txnId, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.getPendingTransactions(0, 10, '');
        this.alertService.showToaster(res.message, '', 'success');
      });
  }
  pendingPopupGsiData: EventStateStore[] = [];
  pushPopUpEventGsiData(postData: any, eventGsiData: any, layoutConfig: any, res?: any) {
    if (!res && eventGsiData && eventGsiData?.isPopupEvent) {
      let record: EventStateStore = {
        parentCuMasterId: postData.currentCU.masterId,
        isPopupEvent: eventGsiData.isPopupEvent,
        EventGsiId: eventGsiData.gsiId,
        parentDesign: layoutConfig,
        parentGsiId: postData.gsiId,
        eventGsiMasterId: eventGsiData?.gsiMasterId,
      };
      /* istanbul ignore next */
      if (
        this.pendingPopupGsiData.length == 0 ||
        (this.pendingPopupGsiData.length > 0 &&
          this.pendingPopupGsiData.findIndex((item) => item.parentGsiId == postData.gsiId) == -1)
      ) {
        this.pendingPopupGsiData.push(record);
        // this.flag = false;
      }
    }

    if (res && !this.eventGsiData) {
      const lastGSIContext =
        this.pendingPopupGsiData.length > 1 ? this.pendingPopupGsiData[this.pendingPopupGsiData.length - 1] : null;
      if (lastGSIContext && lastGSIContext.parentGsiId == postData.gsiId) {
        if (lastGSIContext && lastGSIContext.isPopupEvent) {
          this.openPopup.next('gsiPopupOpen');
          this.gsiPopup.next(true);
        }
        this.pendingPopupGsiData.pop();
      }
      else if(lastGSIContext && !lastGSIContext.isPopupEvent) {
        this.openPopup.next('gsiPopupClose');
        this.gsiPopup.next(lastGSIContext ? lastGSIContext.isPopupEvent : false);
      }
    }
  }

  getNextChangeUnitByGSIId(postData: object) {
    this.loader.show();
    this.transactionEndpointService
      .getNextChangeUnitByGSIId(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loader.hide();
        this.nextChangeUnitSubject.next(res);
      });
  }
  // Remove '$' from entity name in layers
  removeEntityName(postData: any) {
    /* istanbul ignore next */
    postData?.currentCU?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      layer?.entityList?.forEach((ent: any) => {
        /* istanbul ignore next */
        for (let value of ent?.name) {
          if (value == '$') {
            /* istanbul ignore next */
            ent.name = ent?.name?.substring(0, ent?.name.indexOf('$'));
          }
        }
      });
    });
  }

  getEventApiResponse() {
    /* istanbul ignore next */
    this.eventsFacadeService.eventResponseData$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.res) {
        this.setNextEventGSIdata(res.res, res.entityDetails);
      }
    });
  }

  getListChangeUnitByGSIId(
    postData: any,
    isFromSubmit?: boolean,
    isDebug?: boolean,
    isSubtransaction?: any,
    paginationDetails?: paginationDetails,
    layoutConfig?: any
  ) {
    this.tempPostData = postData;
    /* istanbul ignore next */
    if (this.eventGsiData && this.nextEventData) {
      this.eventsFacadeService.setEvent(this.dataForCheckForEvents);
      /* istanbul ignore next */
      this.setGsiDraftData(null);
      this.nextEventData = null;
    } else if (paginationDetails?.pageSize) {
      this.loader.show();
      /* istanbul ignore next */
      if (
        this.selectedTableAtrributeRecords[this.previousPageNumber] == undefined ||
        this.selectedTableAtrributeRecords[this.previousPageNumber]?.length == 0
      ) {
        const physical = postData?.currentCU?.layers?.findIndex((key: any) => {
          return key.type === 'physical';
        });
        const triggerCES = postData?.currentCU?.layers?.findIndex((key: any) => {
          return key.type === 'triggerCES';
        });
        const slot1 = postData?.currentCU?.layers?.[triggerCES]?.entityList?.findIndex((key: any) => {
          return key.slot === 1;
        });
        const slot2 = postData?.currentCU?.layers?.[triggerCES]?.entityList?.findIndex((key: any) => {
          return key.slot === 2;
        });
        if (postData?.currentCU && postData?.currentCU?.layers?.[physical]?.entityList) {
          if (postData?.currentCU?.reservedCUType == 'NSL_Template_SearchResult') {
            postData.currentCU.layers[physical].entityList = postData.currentCU.layers[physical].entityList.filter(
              (ent: any) => ent.slot !== 2
            );
            postData.currentCU.layers[physical].entityList.splice(
              1,
              0,
              postData.currentCU.layers[triggerCES].entityList[slot2]
            );
          } else if (postData?.currentCU?.reservedCUType == 'NSL_Template_PaginatedEQLSearchResult') {
            postData.currentCU.layers[triggerCES].entityList[slot2].slot = 1;
            postData.currentCU.layers[physical].entityList = [postData.currentCU.layers[triggerCES].entityList[slot2]];
          }
        }
      }
      if (postData.currentCU && postData?.currentCU?.isFaceted && postData?.currentCU?.layers[1]?.entityList[1]) {
        postData.currentCU.layers[1].entityList[1] = this.facetedData;
      }
      if (this.eventGsiData) {
        this.pushPopUpEventGsiData(postData, this.eventGsiData, layoutConfig);
      }

      this.transactionEndpointService
        .getPaginatedRecords(postData, paginationDetails.pageNumber, paginationDetails.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            this.openPopup.next('gsiPopupClose');
            /* istanbul ignore else */
            if (res) {
              this.updateTransactionId(res);
              // For event gsi
              if (this.eventGsiData) {
                this.pushPendingGsiData(postData, res);
              } else {
                this.checkFormSubmission(isFromSubmit, postData, res);
              }
              this.getReservedSearchResult(res);
              /* istanbul ignore next */
              this.fetchAndExecuteMainGSI(res, postData?.transId);
            }
          },
          (error) => {
            this.loader.hide();
            this.listChangeUnitSubject.next(false);
          }
        );
    } else {
      this.loader.show();
      this.paginationDetails = {} as paginationDetails;
      /* istanbul ignore next */
      if (isSubtransaction) {
        this.removeEntityName(postData);
      }
      /* istanbul ignore next */
      this.pushPopUpEventGsiData(postData, this.eventGsiData, layoutConfig);
      this.transactionEndpointService
        .getListChangeUnitByGSIId(postData, this.eventGsiData, isDebug, isSubtransaction)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            // this.openPopup.next('gsiPopupClose');
            this.tempPostData = { ...this.tempPostData, transId: res?.result?.transId };
            /* istanbul ignore else */
            if (res) {
              if(res?.message  && (res?.message !== this.MessageData?.Txn_Draft_is_saved_successfully)) {
                this.alertService.showToaster(res?.message, '', 'info');
              }
              if (res?.result?.triggerState == 'COMPLETED' || res?.result?.triggerState == 'SCHEDULED' || this.tempPostData.gsiId !== res?.result?.gsiId) {
                this.preventAlert.next(true);
                this.openPopup.next('gsiPopupClose');
              }
              /* istanbul ignore next */
              if(res?.result?.triggerState == 'COMPLETED' || res?.result?.triggerState == 'SCHEDULED') {
                localStorage.setItem('isDisableHeader', 'false');
                this.userFacade.disableSideMenu();
              }
              /* istanbul ignore next */
              this.gsiMasterId = res?.result?.gsiMasterId;
              this.updateTransactionId(res);
              // For event gsi
              if (this.eventGsiData) {
                // this.resetResumeTransactionData();
                this.pushPendingGsiData(postData);
                this.pushPopUpEventGsiData(postData, this.eventGsiData, layoutConfig, res);
              } else {
                this.checkFormSubmission(isFromSubmit, postData, res);
              }
              if (!this.eventGsiData && res.result) {
                // this.gsiPopup.next(false);
                 /* istanbul ignore next */
                 if(!this.eventGsiData)
                 {
                   const lastGSIContext : any =
                   this.pendingPopupGsiData?.length > 1 ? this.pendingPopupGsiData[this.pendingPopupGsiData?.length - 1] : null;
                   if (lastGSIContext?.isPopupEvent) {
                     res['isPopupEvent'] = true;
                   }
                 }
                this.pushPopUpEventGsiData(res.result, this.eventGsiData, null, true);
              }
              this.getReservedSearchResult(res);
              /* istanbul ignore next */
              this.fetchAndExecuteMainGSI(res, postData?.transId);

              this.redirectBasedonResMsg(res);
            }
          },
          (error) => {
            this.loader.hide();
            this.eventGsiData = null;
            this.listChangeUnitSubject.next(this.selectedCUForEvent);
          }
        );
    }
  }

  navigateToCu(postData: any, isFromSubmit?: boolean) {
    /* istanbul ignore next */
    this.transactionEndpointService
      .navigateToCu(postData, isFromSubmit)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.paginationDetails = {} as paginationDetails;
          /* istanbul ignore else */
          if (res) {
            if (res?.result?.triggerState == 'COMPLETED' || res?.result?.triggerState == 'SCHEDULED') {
              this.preventAlert.next(true);
            }
            /* istanbul ignore next */
            this.gsiMasterId = res?.result?.gsiMasterId;
            this.updateTransactionId(res);
            // For event gsi
            if (this.eventGsiData) {
              // this.resetResumeTransactionData();
              this.pushPendingGsiData(postData);
            } else {
              this.checkFormSubmission(isFromSubmit, postData, res);
            }
            this.getReservedSearchResult(res);
            /* istanbul ignore next */
            this.fetchAndExecuteMainGSI(res, postData?.transId);
            this.redirectBasedonResMsg(res);
          }
        },
        (error) => {
          this.eventGsiData = null;
          this.loader.hide();
          this.listChangeUnitSubject.next(false);
        }
      );
  }

  saveAsDraft(postData: any) {
    this.loader.show();
    this.transactionEndpointService
      .saveAsDraft(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.loader.hide();
          this.saveAsDraftreturnData.next(res);
        }
      });
  }
  // Resume pending transaction after completing event GSI data
  fetchAndExecuteMainGSI(res: any, transId?: any) {
    /* istanbul ignore next */
    if (res?.result?.triggerState && (res?.result?.triggerState === 'COMPLETED' || res?.result?.triggerState == 'SCHEDULED')) {
      localStorage.setItem('isDisableHeader', 'false');
      this.userFacade.disableSideMenu();
      this.updateSignUpGSIExecutedSuccessfullyCheck();
    }
    this.resumePreviousTrans(res, transId, res?.isPopupEvent );
    // if(!res?.isPopupEvent)
    // {
    //   this.resumePreviousTrans(res, transId);
    // }
  }

  resumePreviousTrans(res?: any, transId?: any, isPopUp?:any) {
    if (
      (this.pendingGsiData?.length > 0 &&
        ((transId && res?.result?.transId && res?.result?.transId !== transId) || transId == 'machineCu')) ||
      res === null
    ) {
      /* istanbul ignore next */
      let transObj = this.pendingGsiData?.pop();
      if (transId != 'machineCu' && !isPopUp) this.openPopup.next('gsiPopupClose');
      this.updatePendingGsiArray(this.pendingGsiData);
      if (res) {
        transObj.gsiId = res.result?.gsiId;
        transObj = { ...transObj, currentCU: res.result?.currentCU };
      }
      this.resumeTransaction(transObj);

      /* istanbul ignore next */
      let crypto: Crypto = window.crypto;
      /* istanbul ignore next */
      let array = new Uint32Array(1);
      /* istanbul ignore next */
      let randomNumber = crypto.getRandomValues(array)[0];
      /* istanbul ignore next */
      randomNumber = Number('0.' + randomNumber);

      /* istanbul ignore next */
      const solutionData = btoa(
        JSON.stringify({
          gsiId: transObj?.gsiId,
          bookId: transObj?.bookId,
          isResumeEventTxn: res ? transId != 'machineCu' : false,
          isResumeTxn: transId == 'machineCu' || !res,
          txnId: transObj?.transId,
          triggerCuId: transObj?.triggerCuId,
          contextualId: transObj?.gsiContextualId,
          randNum: Math.floor(randomNumber * 1000),
        })
      );
      /* istanbul ignore next */
      localStorage.setItem('gsi-masterId', transObj?.gsiMasterId);
      this.preventAlert.next(true);
      if (this.isMccPopup) {
        this.mccPopup.next(solutionData);
      } else {
        this.router.navigateByUrl('transaction/view/' + solutionData);
      }
    } else if (res?.result?.triggerState && (res?.result?.triggerState === 'COMPLETED' || res?.result?.triggerState == 'SCHEDULED')) {
      localStorage.setItem('isDisableHeader', 'false');
      this.userFacade.disableSideMenu();
      if (this.isLogoutConfigGsi) {
        this.logoutApplication();
        return;
      }
      if (!this.isCarnival) {
        let campaignUrl = localStorage.getItem('campaignUrl');
        if (campaignUrl && campaignUrl != 'undefined') {
          this.campaignGsiLogout();
        } else if (this.isDebug) {
          this.isDebug = false;
          this.exitDebug.next(true);
        } else {
          this.openPopup.next('closeAll');
          let siteLayoutId = localStorage.getItem('customPageId');
          this.isMccPopup = false;
          if (siteLayoutId && siteLayoutId.length > 0) {
            this.router.navigateByUrl('custom-mylibrary?siteLayoutId=' + siteLayoutId);
          } else {
            this.userFacade.validateMCCDetails('/mylibrary-dashboard');
          }
        }
      }
    }
  }

  eventApiFailed() {
    /* istanbul ignore next */
    this.eventsFacadeService.eventApiFailed$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && this.dataForCheckForEvents?.eventType === 'ON_SELECT') {
        this.resumePreviousTrans(res);
      }
    });
  }

  getReservedSearchResult(res: any) {
    /* istanbul ignore next */
    if (res?.result?.currentCU?.reservedCUType === 'NSL_Template_SearchResult') {
      let layerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'physical');
      /* istanbul ignore next */
      let infoLayerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'information');
      if (layerIndex !== -1) {
        let reservedEntityFound = res?.result?.currentCU?.layers[layerIndex].entityList.find(
          (res: any) => res.name == 'NSL_GERecordsSize'
        );
        /* istanbul ignore next */
        if (reservedEntityFound) {
          this.paginationDetails.totalNumberOfPage = parseInt(reservedEntityFound?.nslAttributes[0].values[0]);
          this.paginationDetails.totalNumberOfRecords = parseInt(reservedEntityFound?.nslAttributes[1].values[0]);
          /* istanbul ignore next */
          if (infoLayerIndex !== -1) {
            let reservedInfoEntityFound = res?.result?.currentCU?.layers[infoLayerIndex].entityList.find(
              (res: any) => res.name == 'NSL_PageMetadata_Input'
            );
            this.paginationDetails.pageNumber = parseInt(reservedInfoEntityFound?.nslAttributes[0].values[0]);
            this.paginationDetails.pageSize = parseInt(reservedInfoEntityFound?.nslAttributes[1].values[0]);
          }
        }
      }
    }
    if (res?.result?.currentCU?.reservedCUType === 'NSL_Template_PaginatedEQLSearchResult') {
      let infoLayer = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'information');
      if (infoLayer !== -1) {
        let reservedEntityFound = res?.result?.currentCU?.layers[infoLayer].entityList.find(
          (res: any) => res.name == 'NSL_GERecordsSize'
        );
        this.paginationDetails.totalNumberOfPage = parseInt(reservedEntityFound?.nslAttributes[0].values[0]);
        this.paginationDetails.totalNumberOfRecords = parseInt(reservedEntityFound?.nslAttributes[1].values[0]);
        let reservedInfoEntityFound = res?.result?.currentCU?.layers[infoLayer].entityList.find(
          (res: any) => res.name == 'NSL_PageMetadata_Input'
        );
        this.paginationDetails.pageNumber = parseInt(reservedInfoEntityFound?.nslAttributes[0].values[0]);
        this.paginationDetails.pageSize = parseInt(reservedInfoEntityFound?.nslAttributes[1].values[0]);
      }
    }
    if (res?.result?.currentCU?.reservedCUType === 'NSL_Template_FacetedSearch') {
      let layerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'physical');
      /* istanbul ignore next */
      let infoLayerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'information');
      if (layerIndex !== -1) {
        let reservedEntityFound = res?.result?.currentCU?.layers[layerIndex].entityList.find(
          (res: any) => res.name == 'NSL_PageMetadata_Input'
        );
        /* istanbul ignore next */
        if (reservedEntityFound) {
          this.paginationDetails.totalNumberOfPage = parseInt(reservedEntityFound?.nslAttributes[0].values[0]);
          this.paginationDetails.totalNumberOfRecords = parseInt(reservedEntityFound?.nslAttributes[1].values[0]);
          /* istanbul ignore next */
          if (infoLayerIndex !== -1) {
            let reservedInfoEntityFound = res?.result?.currentCU?.layers[infoLayerIndex].entityList.find(
              (res: any) => res.name == 'NSL_GERecordsSize'
            );
            this.paginationDetails.pageNumber = parseInt(reservedInfoEntityFound?.nslAttributes[0].values[0]);
            this.paginationDetails.pageSize = parseInt(reservedInfoEntityFound?.nslAttributes[1].values[0]);
          }
        }
      }
    }
  }

  setNextEventGSIdata(nextEventGSIdata: any, entityDetails: any) {
    this.gsiPopup.next(false);
    /* istanbul ignore next */
    if (
      (nextEventGSIdata?.result?.triggerState && (nextEventGSIdata?.result?.triggerState === 'COMPLETED' || nextEventGSIdata?.result?.triggerState == 'SCHEDULED')) ||
      (this.pendingGsiData &&
        this.pendingGsiData.length > 0 &&
        nextEventGSIdata?.result?.currentCU?.id === this.pendingGsiData?.[this.pendingGsiData.length - 1]?.triggerCuId)
    ) {
      /* istanbul ignore next */
      this.eventCompletedData = nextEventGSIdata;
      if (
        this.pendingGsiData &&
        this.pendingGsiData.length > 0 &&
        nextEventGSIdata?.result?.currentCU?.id === this.pendingGsiData?.[this.pendingGsiData.length - 1]?.triggerCuId
      ) {
        this.resumeApiData = nextEventGSIdata;
        if (this.resumeApiData?.result) {
          if (!this.resumeApiData?.result?.gsiId) {
            if (this.resumeApiData?.result?.currentCU?.contextualId) {
              let CuContextualId = this.resumeApiData?.result?.currentCU?.contextualId;
              CuContextualId = CuContextualId?.split('.')?.filter((x: any) => x?.includes('GS'));
              let lastGSIContextualId = CuContextualId[CuContextualId.length - 1];
              this.resumeApiData.result.gsiId = lastGSIContextualId.includes('_')
                ? lastGSIContextualId.split('_')[1]
                : lastGSIContextualId.replace('GS', '');
            } else {
              this.resumeApiData.result.gsiId = this.pendingGsiData?.[this.pendingGsiData.length - 1]?.gsiId;
            }
          }
          this.resumeApiData.result.transId = this.pendingGsiData?.[this.pendingGsiData.length - 1]?.transId;
        }
      }
      this.openPopup.next(entityDetails.isPopupEvent ? 'gsiPopupOpen' : 'gsiPopupClose');
      this.gsiPopup.next(entityDetails.isPopupEvent);
      this.fetchAndExecuteMainGSI(nextEventGSIdata, 'machineCu');
    } else {
      this.openPopup.next(entityDetails.isPopupEvent ? 'gsiPopupOpen' : 'gsiPopupClose');
      this.gsiPopup.next(entityDetails.isPopupEvent);
      this.tempPostData = { ...this.tempPostData, transId: nextEventGSIdata?.result?.transId };
      this.listChangeUnitSubject.next(nextEventGSIdata);
    }
  }

  pushPendingGsiData(postData: any, res?: any) {
    // Store event gsi data in pendingGsiData array
    /* istanbul ignore next */
    if (
      this.pendingGsiData?.length === 0 ||
      this.pendingGsiData?.findIndex((data: any) => data?.gsiId === postData?.gsiId) < 0
    ) {
      let mainGSIid = null;
      /* istanbul ignore next */
      if (postData?.currentCU?.contextualId) {
        mainGSIid = postData?.currentCU?.contextualId?.split('.')?.[0]?.substring(2);
      }
      /* istanbul ignore next */
      this.pendingGsiData?.push({
        transId: postData?.transId,
        gsiId: mainGSIid ? mainGSIid : postData?.gsiId,
        gsiMasterId: postData?.gsiMasterId,
        gsiContextualId: null,
        triggerCuId: postData?.currentCU?.id,
        bookId: postData?.bookId,
      });
      this.updatePendingGsiArray(this.pendingGsiData);
    }

    let eventData = postData;
    /* istanbul ignore next */
    eventData.gsiId = this.eventGsiData?.gsiId;
    this.nextEventData = eventData;
    /* istanbul ignore next */
    this.transactionSmartSolutionData.next({ gsiId: this.eventGsiData?.gsiId });
    /* istanbul ignore next */
    this.getChangeUnitByGsiId(
      this.eventGsiData?.gsiId,
      null,
      this.eventGsiData?.bookId ? this.eventGsiData?.bookId : postData?.bookId
    );
  }

  // attribute conditional potentiality
  attrConditionalPotentiality(postData: any, entityDetails: any, originalData?: any) {
    /* istanbul ignore next */
    originalData.forEach((data: any) => {
      this.eventsFacadeService.entityAttributesOrder(data.entityList);
    });
    this.transactionEndpointService
      .attrConditionalPotentiality(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        let index: any;
        /*istanbul ignore next*/
        if (entityDetails?.isInfo) {
          index = originalData?.findIndex((i: any) => i.type == 'information');
        } else {
          index = originalData?.findIndex((i: any) => i.type == 'physical');
        }
        if (index !== -1 && (entityDetails?.isTableConfig || entityDetails?.isMulti)) {
          this.findTargetEnt(res.currentCU.layers[index], entityDetails, index, entityDetails?.isTableConfig);
          if (entityDetails?.isTableConfig) {
            let attributeIndex: any = parseInt(entityDetails?.attrName?.split('$')[1]);
            let indexNumber = Math.floor(attributeIndex / this.targetEnt[0]?.nslAttributes.length);
            console.log(indexNumber);
            this.tablerow = 0;
            let i = 0;
            for (let i = 0; i < originalData[index]?.entityList?.length; i++) {
              let entity = originalData[index]?.entityList[i];
              if (entity.name == entityDetails.entityName && this.tablerow == indexNumber) {
                originalData[index].entityList[i] = this.targetEnt;
                originalData[index].entityList = originalData[index].entityList.flat();
                break;
              } else if (entity.name == entityDetails.entityName) {
                this.tablerow = this.tablerow + 1;
              } else {
                entity.nslAttributes?.forEach((attribute: any, ind: number) => {
                  if (attribute.type == 'entity') {
                    this.replaceTargetEntityTable(attribute, entityDetails.entityName, ind, entity, indexNumber);
                  }
                });
              }
            }
          } else {
            originalData[index].entityList.forEach((entity: any, i: number) => {
              if (entity.name == entityDetails.entityName) {
                if (entity.txnRecordId == entityDetails.txnRecordId) {
                  originalData[index].entityList[i] = this.targetEnt;
                  originalData[index].entityList = originalData[index].entityList.flat();
                }
                return;
              } else {
                entity.nslAttributes?.forEach((attribute: any, ind: number) => {
                  if (attribute.type == 'entity') {
                    this.replaceTargetEntity(attribute, entityDetails.entityName, ind, entity, entityDetails, false);
                  }
                });
              }
            });
          }
          this.targetEnt = [];
          this.findTargetEnt(originalData[index], entityDetails, index, true);
          let response = res.currentCU.layers[index].entityList;
          for (let i = 0; i < response?.length; i++) {
            if (
              response[i].name == entityDetails.entityName.split('$')[0] &&
              (response[i].txnRecordId == entityDetails.txnRecordId || entityDetails?.isTableConfig)
            ) {
              res.currentCU.layers[index].entityList[i] = this.targetEnt;
              res.currentCU.layers[index].entityList = res.currentCU.layers[index].entityList.flat();
              break;
            } else {
              response[i].nslAttributes?.forEach((attribute: any, ind: number) => {
                if (attribute.type == 'entity') {
                  this.replaceTargetEntity(
                    attribute,
                    entityDetails.entityName.split('$')[0],
                    ind,
                    response[i],
                    entityDetails,
                    true
                  );
                }
              });
            }
          }
          this.targetEnt = [];
        }
        res.transId = postData.transId;
        this.appendMissingOptions(postData, res);
        this.ConditionalPotentialitySubject.next({ res: res, entityDetails: entityDetails });
      });
  }

  replaceTargetEntity(
    res: any,
    entityName: any,
    index: number,
    entity: any,
    entityDetails: any,
    originalData: boolean
  ) {
    /* istanbul ignore else */
    if (res.name == entityName) {
      if (res.txnRecordId == entityDetails.txnRecordId || originalData) {
        entity.nslAttributes[index] = this.targetEnt;
        entity.nslAttributes = entity.nslAttributes.flat();
      }
      return;
    } else {
      res.nslAttributes?.forEach((attribute: any, inde: any) => {
        if (attribute.type == 'entity') {
          this.replaceTargetEntity(attribute, entityName, inde, res, entityDetails, originalData);
        }
      });
    }
  }

  replaceTargetEntityTable(res: any, entityName: any, index: number, entity: any, indexNumber: number) {
    /* istanbul ignore else */
    if (res.name == entityName && this.tablerow == indexNumber) {
      entity.nslAttributes[index] = this.targetEnt;
      entity.nslAttributes = entity.nslAttributes.flat();
      return;
    } else if (res.name == entityName) {
      this.tablerow = this.tablerow + 1;
    } else {
      res.nslAttributes?.forEach((attribute: any, inde: any) => {
        if (attribute.type == 'entity') {
          this.replaceTargetEntityTable(attribute, entityName, inde, res, indexNumber);
        }
      });
    }
  }

  findTargetEnt(res: any, entityDetails: any, index: number, originalData: boolean) {
    res.entityList.forEach((ent: any) => {
      if (ent.name?.split('$')[0] == entityDetails.entityName?.split('$')[0]) {
        if (ent.txnRecordId == entityDetails.txnRecordId || originalData) {
          this.targetEnt.push(ent);
        }
      }
      /* istanbul ignore next */
      if (this.targetEnt.length == 0) {
        ent.nslAttributes?.forEach((attribute: any) => {
          if (attribute.type == 'entity') {
            this.findNestedTargetEnt(attribute, entityDetails, index, originalData);
          }
        });
      }
    });
  }

  findNestedTargetEnt(res: any, entityDetails: any, index: number, originalData: boolean) {
    /* istanbul ignore else */
    if (res.name.split('$')[0] == entityDetails.entityName.split('$')[0]) {
      if (res.txnRecordId == entityDetails.txnRecordId || originalData) {
        this.targetEnt.push(res);
      }
    }
    /* istanbul ignore next */
    if (this.targetEnt.length == 0) {
      res.nslAttributes?.forEach((attribute: any) => {
        if (attribute.type == 'entity') {
          this.findNestedTargetEnt(attribute, entityDetails, index, originalData);
        }
      });
    }
  }
  redirectBasedonResMsg(res: any) {
    /* istanbul ignore next */
    if (res?.message && res?.message !== this.MessageData?.Txn_Draft_is_saved_successfully) {
      setTimeout(() => {
        this.isMccPopup = false;
        this.router.navigateByUrl(`mylibrary-dashboard`);
        // this.router.navigateByUrl('/mylibrary-dashboard');
      }, 5000);
    }
  }

  appendMissingOptions(sourceData: any, targetData: any) {
    /* istanbul ignore next */
    if (
      sourceData &&
      targetData &&
      sourceData?.gsiId === targetData?.gsiId &&
      sourceData?.transId === targetData?.transId &&
      sourceData?.currentCU?.id === targetData?.currentCU?.id
    ) {
      /* istanbul ignore next */
      sourceData?.currentCU?.layers?.forEach((sourceDataLayer: any) => {
        /* istanbul ignore next */
        targetData?.currentCU?.layers?.forEach((targetDataLayer: any) => {
          /* istanbul ignore next */
          if (sourceDataLayer?.type === targetDataLayer?.type) {
            this.appendMissingOptionsAtLayerLevel(sourceDataLayer, targetDataLayer);
          }
        });
      });
    }
  }

  appendMissingOptionsAtLayerLevel(sourceLayer: any, targetLayer: any) {
    /* istanbul ignore next */
    if (sourceLayer && targetLayer) {
      /* istanbul ignore next */
      sourceLayer?.entityList?.forEach((sourceEntity: any) => {
        /* istanbul ignore next */
        targetLayer?.entityList?.forEach((targetEntity: any) => {
          /* istanbul ignore next */
          if (sourceEntity?.id === targetEntity?.id) {
            this.appendMissingOptionsAtEntityLevel(sourceEntity, targetEntity);
          }
        });
      });
    }
  }

  checkFormSubmission(isFromSubmit: any, postData: any, res: any) {
    /* istanbul ignore next */
    if (isFromSubmit && this.pendingGsiData?.length === 0) {
      this.isTransactionCompleted.next(true);
      /* istanbul ignore next */
      const data = {
        gsiId: postData?.gsiId,
        transId: res?.result?.transId,
        triggerCuId: res?.result?.currentCU?.id,
        gsiMasterId: postData?.gsiMasterId,
        gsiContextualId: postData?.gsiContextualId,
        bookId: postData?.bookId,
      };
      localStorage.setItem('currentCuData', JSON.stringify(data));
    }

    /* istanbul ignore next */
    if (res?.result?.triggerState !== 'COMPLETED') {
      this.listChangeUnitSubject.next(res);
    }
  }

  appendMissingOptionsAtEntityLevel(sourceEntity: any, targetEntity: any) {
    /* istanbul ignore next */
    if (sourceEntity?.nslAttributes && targetEntity?.nslAttributes) {
      /* istanbul ignore next */
      sourceEntity?.nslAttributes?.forEach((sourceAttr: any) => {
        /* istanbul ignore next */
        targetEntity?.nslAttributes?.forEach((targetAttr: any) => {
          /* istanbul ignore next */
          if (sourceAttr?.id === targetAttr?.id) {
            this.appendMissingOptionsAtAttrLevel(sourceAttr, targetAttr);
          }
        });
      });
    }
  }

  appendMissingOptionsAtAttrLevel(sourceAttr: any, targetAttr: any) {
    /* istanbul ignore next */
    // if (sourceAttr?.options?.length) {
    //   sourceAttr.options = targetAttr.options;
    // }
    /* istanbul ignore next */
    if (sourceAttr?.generalEntity && targetAttr?.generalEntity) {
      this.appendMissingOptionsAtEntityLevel(sourceAttr.generalEntity, targetAttr.generalEntity);
    }
  }

  getResumeChangeUnitByGSIId(postData: object) {
    /* istanbul ignore next */
    if (this.resumeApiData) {
      /* istanbul ignore next */
      this.updateResumeApiData(JSON.parse(JSON.stringify(this.resumeApiData)));
      this.resumeApiData = null;
    } else {
      this.tempPostData = postData;
      this.loader.show();
      /* istanbul ignore next */
      this.transactionEndpointService
        .getResumeChangeUnitByGSIId(postData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.loader.hide();
          this.updateResumeApiData(res);
        });
    }
  }

  updateResumeApiData(res: any) {
    this.listChangeUnitSubject.next(res);
    this.getReservedSearchResult(res);
    /* istanbul ignore next */
    if (this.eventCompletedData) {
      this.loader.show();
      /* istanbul ignore next */
      setTimeout(() => {
        /* istanbul ignore next */
        let layerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'physical');
        /* istanbul ignore next */
        let entityDetails = res?.result?.currentCU?.layers?.[layerIndex]?.entityList?.find(
          (entity: any) => entity?.id === this.eventCompletedData?.result?.currentCU?.layers?.[0]?.entityList?.[0]?.id
        );
        /* istanbul ignore next */
        if (entityDetails) {
          /* istanbul ignore next */
          entityDetails.entityName = entityDetails?.name;
          // nested required for nested entity
          /* istanbul ignore next */
          this.eventsFacadeService?.cuEvents.next({
            res: this.eventCompletedData,
            entityDetails: entityDetails,
          });
        }
        this.eventCompletedData = null;
        this.loader.hide();
      }, 3000);
    }
  }

  getPendingTransactions(pageIndex: any, pageSize: any, searchText: any) {
    this.loader.show();
    this.transactionEndpointService
      .getPendingTransactions(pageIndex, pageSize, searchText)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.pendingTransactions.next(res);
        },
        (error) => {
          this.loader.hide();
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }
  getCreatedTxn(pageIndex: any, pageSize: any, searchText: any, originatingBookId?: any) {
    this.loader.show();
    this.transactionEndpointService.getCreatedTxn(pageIndex, pageSize, searchText, originatingBookId).subscribe(
      (res: any) => {
        this.loader.hide();
        this.createdTransactions.next(res);
      },
      (error) => {
        this.loader.hide();
        const { err } = error;
        this.alertService.showToaster(`${err}`, '', 'error');
      }
    );
  }
  assignedTransactionsNext(res: any) {
    this.assignedTransactions.next(res);
  }
  getAssignedTxn(pageIndex: any, pageSize: any, searchText: any, originatingBookId?: any) {
    this.loader.show();
    this.transactionEndpointService.getAssignedTxn(pageIndex, pageSize, searchText, originatingBookId).subscribe(
      (res: any) => {
        this.loader.hide();
        this.assignedTransactions.next(res);
      },
      (error) => {
        this.loader.hide();
        const { err } = error;
        this.alertService.showToaster(`${err}`, '', 'error');
      }
    );
  }
  escalatedTransactionsNext(res: any) {
    this.escalatedTransactions.next(res);
  }
  getEscalatedTxn(pageIndex: any, pageSize: any, searchText: any, originatingBookId?: any) {
    this.loader.show();
    this.transactionEndpointService.getEscalatedTxn(pageIndex, pageSize, searchText, originatingBookId).subscribe(
      (res: any) => {
        this.loader.hide();
        this.escalatedTransactions.next(res);
      },
      (error) => {
        this.loader.hide();
        const { err } = error;
        this.alertService.showToaster(`${err}`, '', 'error');
      }
    );
  }

  resumeTransaction(data: any) {
    this.resumeTransactionSubject.next(data);
  }

  getTransactioHistoryCuList(transactionId: string, gsiId: string) {
    this.loader.show();
    this.transactionEndpointService
      .getTransactionHistoryCuList(transactionId, gsiId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.transactionHistoryCuList.next(res);
      });
  }
  getTransactionFullHistoryEmbeddedCuList(transactionId: string, gsiId: string) {
    this.loader.show();
    this.transactionEndpointService
      .getTransactionFullHistoryEmbeddedCuList(transactionId, gsiId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.transactionHistoryCuList.next(res);
      });
  }

  getTransactioHistoryEmbeddedCuList(transactionId: string, gsiId: string, triggerCuId: string) {
    this.loader.show();
    this.transactionEndpointService
      .getTransactionHistoryEmbeddedCuList(transactionId, gsiId, triggerCuId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.transactionHistoryEmbeddedCuList.next(res);
      });
  }

  getTransactionHistoryNestedCuList(transactionId: string, gsiId: string) {
    this.loader.show();
    this.transactionEndpointService
      .getTransactionHistoryNestedCuList(transactionId, gsiId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.transactionHistoryNestedCuList.next(res);
      });
  }

  transactionHistoryEntityListNext(res: any) {
    this.transactionHistoryEntityList.next(res);
  }
  getCuTransactionHistory(transactionId: string, cuContextualId: string) {
    this.transactionEndpointService
      .getCuTransactionHistory(transactionId, cuContextualId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.transactionHistoryEntityList.next(res);
      });
  }

  getEmbeddedCuTransactionHistory(transactionId: string, cuContextualId: string) {
    this.loader.show();
    this.transactionEndpointService
      .getCuTransactionHistory(transactionId, cuContextualId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.transactionHistoryEmbeddedEntityList.next(res);
      });
  }

  ngOnDestroy() {
    this.pendingTransaction.next('');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  reservedActionsListNext(res: any) {
    this.reservedActionsList.next(res);
  }
  ///reserved actions get call in transaction time ///
  getReservedActionsList(cuid: any) {
    this.transactionEndpointService
      .getReservedActionsList(cuid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.reservedActionsList.next(res);
      });
  }
  advanceSearchSubjectNext(res: any) {
    this.advanceSearchSubject.next(res);
  }
  // advanced search in pending txn
  advancedSearchTxn(postData: object) {
    this.loader.show();
    this.transactionEndpointService
      .advancedSearchTxn(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res) => {
          this.loader.hide();
          this.advanceSearchSubject.next(res);
        },
        (error) => {
          this.loader.hide();
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }
  allTransactionsNext(res: any) {
    this.allTransactions.next(res);
  }
  // getAllTransactions(pageIndex: any, pageSize: any, searchText: any) {
  //   this.transactionEndpointService.getAllTransactions(pageIndex, pageSize, searchText).subscribe((res: any) => {
  //     this.allTransactions.next(res);
  //   });
  // }
  txnStatusChange(status: any, txnId: any, payload?: any) {
    this.transactionEndpointService
      .txnStatusChange(status, txnId, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.getPendingTransactions(0, 10, '');
        this.alertService.showToaster(res.message, '', 'success');
      });
  }
  filteredTransactionsNext(res: any) {
    this.filteredTransactions.next(res);
  }
  getFilteredTransactions(body: any) {
    this.loader.show();
    this.transactionEndpointService
      .getFilteredTransactions(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.filteredTransactions.next(res);
        },
        (error) => {
          this.loader.hide();
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }

  /**
   * get transaction ads
   * @param gsiData
   */
  getTransactionAds(gsiData: any) {
    this.transactionEndpointService
      .getTransactionAds(gsiData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && typeof res !== 'string') {
          res = {
            ...res,
            gsiData: gsiData,
          };
          this.transactionAds.next(res);
        }
      });
  }
  /* istanbul ignore next */
  getTransactionBottomAds(gsiData: any) {
    this.transactionEndpointService
      .getTransactionAds(gsiData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && typeof res !== 'string') {
          res = {
            ...res,
            gsiData: gsiData,
          };
          this.transactionBottomAds.next(res);
        }
      });
  }
  /**
   * make Impression API call
   * @param impressionData
   */
  makeImpression(impressionData: any) {
    this.transactionEndpointService.makeImpression(impressionData).subscribe();
  }

  /**
   * make click Ad API call
   * @param clickedData
   */
  adClick(clickedData: any) {
    this.transactionEndpointService.adClick(clickedData).subscribe();
  }

  //api call to get reserved actions hostory
  getReservedActionHistory(transactionId: string, cuContextualId: string) {
    return this.transactionEndpointService.getReservedActionsHistory(transactionId, cuContextualId);
  }
  /**
   * to get location details based on IP
   */
  geolocation() {
    this.transactionEndpointService.getLocation().subscribe((res: any) => {
      this.locationDetails.next(res);
    });
  }
  //Subtransactional Entity
  getSubtransactionalEntity(cu: object) {
    this.loader.show();
    const cuInfo = transactionChangeUnitDeMapper(cu);
    this.transactionEndpointService
      .getSubtransactionalEntity(cuInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        const cuRes = transactionChangeUnitMapper(res?.result);
        this.SubtransactionalEntityList.next(cuRes);
      });
  }

  openPendingTransactionModal(txnData: any) {
    this.pendingTransaction.next(txnData);
  }

  triggerWaitForTransaction(transactionData: any) {
    this.waitForTransaction.next(transactionData);
  }
  googleMapSubject = new Subject();
  googleMapSubject$ = this.googleMapSubject.asObservable();

  getLocation(latitude: any, longitude: any) {
    this.transactionEndpointService
      .getGmapLocation(latitude, longitude)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.googleMapSubject.next(res);
      });
  }

  // Pushback transaction API - FE757
  saveTransPushbackData(data: any) {
    this.loader.show();
    this.transactionEndpointService
      .postTransPushbackData(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.listChangeUnitSubject.next(res);
      });
  }
  /*gets all scheduled jobs list */
  getJobdetails(pageIndex: any, pageSize: any, searchText: any) {
    this.loader.show();
    this.transactionEndpointService.getJobdetails(pageIndex, pageSize, searchText).subscribe((res: any) => {
      this.loader.hide();
      this.scheduledJobs.next(res);
    });
  }
  /*gets list of all triggers based on gsiname and gsiId */
  getJobtriggers(gsiId: number, gsiName: any, pageIndex: any, pageSize: any) {
    this.loader.show();
    this.transactionEndpointService.getJobtriggers(gsiId, gsiName, pageIndex, pageSize).subscribe((res: any) => {
      this.loader.hide();
      this.jobTriggers.next(res);
    });
  }

  /*gets list of all triggers based on gsiname and gsiId */
  gsiExecutions(pageIndex: any, pageSize: any, data: any) {
    this.loader.show();
    this.transactionEndpointService.gsiExecutions(pageIndex, pageSize, data).subscribe((res: any) => {
      this.loader.hide();
      this.jobExecutions.next(res);
    });
  }

  /*gets list of all paginated triggers */
  getAlltriggers(pageIndex: any, pageSize: any) {
    this.loader.show();
    this.transactionEndpointService.getAlltriggers(pageIndex, pageSize).subscribe((res: any) => {
      this.loader.hide();
      this.allTriggers.next(res);
    });
  }

  /*unschedules a job */
  getUnscheduleJobs(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .getUnscheduleJobs(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unscheduleJobs.next(res);
      });
  }
  /*unschedules a trigger */
  getUnscheduleTriggers(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .getUnscheduleTriggers(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unscheduleTriggers.next(res);
      });
  }

  /*pauses trigger */
  pauseTrigger(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .pauseTrigger(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.pauseTriggerSubject.next(res);
      });
  }

  // Store Event gsi data
  setGsiDraftData(data: any) {
    this.eventGsiData = data;
    this.eventGsiDataSubject.next(data);
  }

  // Reset resume transaction data
  resetResumeTransactionData() {
    this.stopResumeTransactionSubject.next(true);
  }

  // Update pending gsi array
  updatePendingGsiArray(data: any) {
    this.pendingGsiArraySubject.next(data);
  }

  // Update transaction id
  updateTransactionId(data: any) {
    /* istanbul ignore next */
    this.changeUnitData = data?.result;
    this.updateTransactionIdData.next(data);
  }
  /*resume trigger */
  resumeTrigger(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .resumeTrigger(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.resumeTriggerSubject.next(res);
      });
  }

  savePrevCUs(data: any) {
    this.previousCUlist.next(data);
  }

  /*pauses job */
  pauseJob(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .pauseJob(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.pauseJobSubject.next(res);
      });
  }
  /* resumes job */
  resumeJob(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .resumeJob(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.resumeJobSubject.next(res);
      });
  }
  /**

   * Sets attribute data for events

   * @param transactionData

   */

  setAttributeDataForEvents(transactionData: any) {
    this.eventAttributeData = transactionData;
  }

  /**

   * Gets attribute data for events

   * @returns

   */

  getAttributeDataForEvents() {
    return this.eventAttributeData;
  }
  debugData(payload: any) {
    /* istanbul ignore next */
    this.transactionEndpointService
      .debugData(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.debug.next(res);
      });
  }
  debugValue(payload: any) {
    /* istanbul ignore next */
    this.transactionEndpointService
      .debugValue(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.debugValues.next(res);
      });
  }

  saveTransPushbackCommentData(transId: any, data: any) {
    this.loader.show();
    this.transactionEndpointService
      .postTransPushbackCommentData(transId, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res?.result && res?.result?.length > 0) {
          this.getAllComments(res.result);
        }
      });
  }

  facetSearch(data: any, paginationDetails: any) {
    this.loader.show();
    const postData = {
      currentCU: data,
      gsiId: this.tempPostData?.gsiId,
      bookId: this.tempPostData?.bookId,
      transId: this.tempPostData?.transId,
      gsiMasterId: this.tempPostData?.gsiMasterId,
      enableComments: this.tempPostData?.enableComments,
      enablePushback: this.tempPostData?.enablePushback,
    };

    this.transactionEndpointService
      .facetSearch(postData, paginationDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res?.result) {
          this.facetSearchResult.next({ res: res, paginationDetails: paginationDetails });
        }
      });
  }

  debugCu(cu: any) {
    this.debugPreviousCu.next(cu);
  }

  getAllComments(data: any) {
    this.listOfAllComments.next(data);
  }
  goToPendingTransaction(txndata: any) {
    this.milestoneToPendingTxn.next(txndata);
  }

  onSubmitting(data: any) {
    this.submitButtonEmit.next(data);
    this.submitForlocation.next(true);
  }

  submitButtonDisabling(data: any) {
    this.submitButtonDisabled.next(data);
  }

  getTriggerCu(id: any) {
    this.changeUnitFacadeService.fetchTriggerCu(id);
  }
  publishtoBlockChain(payload: any) {
    return this.transactionEndpointService.publishtoBlockChain(payload);
  }

  sendCurrentSelectedImages(images: any) {
    this.currentSelectedImages.next(images);
  }
  getTransactionOwner(id: any) {
    return this.transactionEndpointService.getTransactionOwner(id);
  }
  sendLocationData(data: any) {
    return this.transactionEndpointService.sendLocationdata(data);
  }
  getLocationData(transId: any, contextualId: any) {
    return this.transactionEndpointService.getLocationData(transId, contextualId);
  }
  getPdfResize(data: any) {
    this.pdfResize.next(data);
  }
  updateARFile(file: any) {
    this.storeARFile.next(file);
  }
  campaignGsiLogout() {
    this.authenticationService
      .logout()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res) {
          this.router.navigate(['/']);
        }
      });
  }

  addMultipleEntities(postData: any, entDetails: any, multiEntity: boolean, originalData: any) {
    this.loader.show();
    this.transactionEndpointService
      .addMultipleEntities(postData, entDetails)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res.result) {
          /* istanbul ignore next */
          if (multiEntity) {
            let index: any;
            index = originalData?.findIndex((i: any) => i.type == 'physical');
            this.findTargetEnt(res.result.currentCU.layers[index], entDetails.data, index, false);
            originalData[index].entityList.forEach((entity: any, i: number) => {
              if (
                entity.name?.split('$')[0] == entDetails.data.entityName &&
                entity.txnRecordId == entDetails.data.txnRecordId
              ) {
                originalData[index].entityList[i] = this.targetEnt;
                originalData[index].entityList = originalData[index].entityList.flat();
                return;
              } else {
                entity.nslAttributes?.forEach((attribute: any, ind: number) => {
                  if (attribute.type == 'entity') {
                    this.addMultiNestedEntities(attribute, entDetails, ind, entity);
                  }
                });
              }
            });
            this.targetEnt = [];
            res.result.currentCU.layers[index].entityList = originalData[index].entityList;
          }
          this.addMultiEntities.next(res.result);
        }
      });
  }
  updateSignUpGSIExecutedSuccessfully(assign: boolean) {
    this.loader.show();
    this.transactionEndpointService
      .updateSignUpGSIExecutedSuccessfully(assign)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        // this.alertService.showToaster(res.message, '', 'success');
      });
  }
  updateSignUpGSIExecutedSuccessfullyCheck() {
    let signUpGsiExecutionMandatory = sessionStorage.getItem('signUpGsiExecutionMandatory');
    let signUpGSIExecutedSuccessfully = JSON.parse(localStorage.getItem('ActiveUserInfo'))
      .signUpGSIExecutedSuccessfully; // from userInfo
    let enableLoginGSIConfiguration = sessionStorage.getItem('enableLoginGSIConfiguration'); // from envConfigInfo
    if (!signUpGSIExecutedSuccessfully && (signUpGsiExecutionMandatory || enableLoginGSIConfiguration)) {
      this.updateSignUpGSIExecutedSuccessfully(true);
    }
  }

  addMultiNestedEntities(res: any, entDetails: any, index: any, entity: any) {
    /* istanbul ignore next */
    if (res.name?.split('$')[0] == entDetails.data.entityName && res.txnRecordId == entDetails.data.txnRecordId) {
      entity.nslAttributes[index] = this.targetEnt;
      entity.nslAttributes = entity.nslAttributes.flat();
      return;
    } else {
      res.nslAttributes?.forEach((attribute: any, inde: any) => {
        if (attribute.type == 'entity') {
          this.addMultiNestedEntities(attribute, entDetails, inde, res);
        }
      });
    }
  }
  setDebug(value: boolean) {
    this.isDebug = value;
  }
  /* istanbul ignore next */
  setCardData(res: any) {
    this.cardsData.next(res);
  }
  /* istanbul ignore next */
  getCurrentCu(data: any) {
    this.currentCuDetails.next(data);
  }
  dicomFiletoJpg(payload: any) {
    return this.transactionEndpointService.dicomFiletoJpg(payload);
  }
  notificationApproved(data: any, btn: any) {
    return this.transactionEndpointService.notificationApproved(data, btn);
  }
  // dynamicFormUploadFiles(files: any) {
  //   return this.transactionEndpointService.dynamicFormUploadFiles(files);
  // }
  assignedData(status: any, containercuname: any, pageNumber: any, pagesize: any) {
    return this.transactionEndpointService.assignedData(status, containercuname, pageNumber, pagesize);
  }
  getcustomizedDialogWarning(payload: any) {
    return this.transactionEndpointService.getcustomizedDialogWarning(payload);
  }

  cancelTransaction(payload: any) {
    this.loader.show();
    this.transactionEndpointService
      .cancelTransaction(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.cancelledTransactionData.next(res);
          this.alertService.showToaster(res.message, '', 'success');
          this.cancelledTransactionData.next(res);
        },
        (error) => {
          this.alertService.showToaster(error.message, '', 'error');
        }
      );
  }
  /*istanbul ignore next*/
  //for Calender slot execution
  executeCalnderSlot(payload: any) {
    this.loader.show();
    this.transactionEndpointService
      .executeCalnderSlot(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.listChangeUnitSubject.next(res);
      });
  }

   /* istanbul ignore next */
   getTopBannerAds(gsiData: any) {
    this.transactionEndpointService
      .getTopBannerAds(gsiData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          res = { ...res, gsiData: gsiData };
          this.transactionTopAds.next(res);
        }
    });
  }
}
