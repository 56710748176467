import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class TNCEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getTNCforAllRoles() {
    return this.http.get(this.api.getTNCforAllRoles);
  }
  saveTnCforaRole(payload: any, termsAndConditions: string) {
    if (termsAndConditions) {
      return this.http.put(this.api.saveTnCforaRole, payload);
    }
    return this.http.post(this.api.saveTnCforaRole, payload);
  }
  deleteTnCForaRole(id: any) {
    return this.http.delete(`${this.api.deleteTnCforaRole}/${id}`);
  }
  getTnCbyClientId(clientId: string) {
    return this.http.get(`${this.api.getTnCbyClientId}/${clientId}`);
  }
  getDefaultTnCForTenant() {
    return this.http.get(`${this.api.getDefaultTnCForTenant}`);
  }
  updateDefaultTnCForTenant(termsAndConditionsURL: string, update: boolean) {
    if (update) {
      return this.http.put(`${this.api.updateDefaultTnCForTenant}?defaultTnc=${termsAndConditionsURL}`, {});
    }
    return this.http.post(`${this.api.updateDefaultTnCForTenant}?defaultTnc=${termsAndConditionsURL}`, {});
  }
  updateStatus(clientId: string, roleName: string, status: string) {
    return this.http.post(`${this.api.updateStatus}/${clientId}/${roleName}/${status}`, {});
  }
}
