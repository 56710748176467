import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class CarnivalEndpointService extends CduiBaseService {
  constructor(private http: HttpClient, public injector: Injector) {
    super(injector);
  }

  addUserProfile(data: any) {
    return this.http.post(this.api?.addUserProfile, data);
  }

  getUserProfile() {
    return this.http.get(this.api?.addUserProfile);
  }

  getMatchingFeatureflag(flag: any) {
    return this.http.get(`${this.api?.getCarnivalMatchingFeatureFlag}${flag}`);
  }

  getFeatureflag(flag: any) {
    return this.http.get(`${this.api?.getCarnivalFeatureFlag}${flag}`);
  }

  getBookDetails(searchCriteria?: string) {
    const query = searchCriteria ? searchCriteria : 'One Hour Learning';
    return this.http.get(`${this.api?.getBooksWithDesignData}pageNo=0&pageSize=30&searchCriteria=${query}`);
  }

  getGSIsWithExecutionStatus(bookId: any) {
    /* istanbul ignore else */
    if (bookId) {
      return this.http
        .get(`${this.api?.getGSIsWithExecutionStatus}${bookId}?pageNo=0&pageSize=10&searchCriteria=`)
        .pipe(pluck('result', 'content'));
    }
  }

  getAllAvatars() {
    return this.http.get(`${this.api?.customTenantUI}/user/avatars`);
  }

  generateOtp(mobilenumber: number) {
    return this.http.get(`${this.api?.generateOtpForLogin}${mobilenumber}`);
  }

  verifyOTP(mobilenumber: number, otp: number) {
    return this.http.get(`${this.api?.validateGivenOtp}${mobilenumber}&otpNumber=${otp}`);
  }
}
