import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { IRecommendations, RecommendationTypes, gsiMyLibrarySuggestionList } from '../../models/node-canvas.model';
import { NslBaseComponent } from '../nsl-base.component';
import { Librarytypes, NodeGsiFacadeService, TranslatorService } from '@common-services';

@Component({
  selector: 'node-recommendations-list',
  templateUrl: './node-recommendations-list.component.html',
  styleUrls: ['./node-recommendations-list.component.scss'],
})
export class NodeRecommendationsListComponent extends NslBaseComponent implements OnInit, OnDestroy {
  recommendationsList: IRecommendations[] = [];
  gsiMyLibrarySuggestionList: gsiMyLibrarySuggestionList[] = [];
  recommendationTypes: typeof RecommendationTypes = RecommendationTypes;
  fullSuggestionList: any = [];
  @Input() _type: string = 'ChangeUnit';
  @Input() _label = 'My Collection';
  @Input() reserveCURecommendation: any;
  @Input() _isParagraph: boolean;
  /* istanbul ignore next */
  @Input()
  set _recommendationsList(values: IRecommendations[]) {
    if (values?.length > 0) {
      this.recommendationsList = values;
    }
  }
  @Input() set _gsiLibrarySuggestion(values: gsiMyLibrarySuggestionList[]) {
    if (values?.length >= 0 && this._isParagraph) {
      if (values?.length > 3) {
        this.gsiMyLibrarySuggestionList = values.slice(0, 3);
      } else {
        this.gsiMyLibrarySuggestionList = values;
      }
      this.fullSuggestionList = values;
    }
  }
  @Output() onSelect = new EventEmitter();
  @Output() reserveCUSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() gsiSuggestions: EventEmitter<any> = new EventEmitter<any>();

  cuDropdown = true;
  ngUnsubscribe = new Subject();
  labels: any;

  constructor(
    private injector: Injector,
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
  ) {
    super(injector);
    this.detectLanguageChange();
  }

  ngOnInit() {}
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  handleSelect(name: string) {
    const index = this.gsiMyLibrarySuggestionList?.find((m) => m?.name === name || m?.gsi_name === name);
    if (index) {
      this.gsiSuggestions.emit(index);
    }
  }

  clickOutside() {
    this.recommendationsList = [];
  }
  selectReserveCU(id: string) {
    this.reserveCUSelected.emit(id);
  }

  openCULibraryPanel() {
    if (this._type == 'Gsi' && !this._isParagraph) {
      this.gsiFacade.openLibrary(Librarytypes.GSI);
    }
    if (this._type == 'ChangeUnit') {
      this.gsiFacade.openLibrary(Librarytypes.ChangeUnit);
    }
    if (this._type == 'Gsi' && this._isParagraph) {
      // this.paraGsiFacade.openLibraryPara(Librarytypes.GSI);
    }
  }

  handleGsiSelect(id: number, gsiName: string) {
    this.onSelect.emit({
      id: id,
      gsi_name: gsiName,
    });
  }
  showFullList() {
    if (this.gsiMyLibrarySuggestionList.length <= 3) {
      this.gsiMyLibrarySuggestionList = this.fullSuggestionList;
    } else {
      this.gsiMyLibrarySuggestionList = this.gsiMyLibrarySuggestionList.slice(0, 3);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
