import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  LoginComponent,
  LoginDesign,
  AuthenticationService,
  UsersFacadeService,
  AlertService,
  TranslatorService,
  LoaderService,
  CredentialsService,
  TenantCustomUiFacadeService,
  GsiFacadeService,
  ManageConfigurationFacadeService,
  SsoLoginService,
  OnboardingFacadeService,
  setPropertyInDivLayout,
  TransactionFacadeService,
  SharedFacadeService,
} from '@common-services';
import { KeycloakService } from 'keycloak-angular';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-template-fifteen',
  templateUrl: './template-fifteen.component.html',
  styleUrls: ['./template-fifteen.component.scss'],
})
export class TemplateFifteenComponent extends LoginComponent
  implements OnInit, OnChanges {
  @Input() loginDesign: LoginDesign;

  constructor(
    injector: Injector,
    router: Router,
    route: ActivatedRoute,
    formBuilder: FormBuilder,
    authenticationService: AuthenticationService,
    userFacade: UsersFacadeService,
    alertService: AlertService,
    translator: TranslatorService,
    loader: LoaderService,
    keycloak: KeycloakService,
    credentialsService: CredentialsService,
    tenantCustomUiFacadeService: TenantCustomUiFacadeService,
    gsiFacade: GsiFacadeService,
    manageConfigurationFacade: ManageConfigurationFacadeService,
    ssoService: SsoLoginService,
    onboardingFacadeService: OnboardingFacadeService,
    private elRef: ElementRef,
    dialog: MatDialog,
    domsanitizer: DomSanitizer,
    public transactionFacadeService: TransactionFacadeService,
    sharedfacade: SharedFacadeService
  ) {
    super(
      injector,
      router,
      route,
      formBuilder,
      authenticationService,
      userFacade,
      alertService,
      translator,
      loader,
      keycloak,
      credentialsService,
      tenantCustomUiFacadeService,
      gsiFacade,
      manageConfigurationFacade,
      ssoService,
      onboardingFacadeService,
      dialog,
      domsanitizer,
      transactionFacadeService,
      sharedfacade
    );
  }
  ngOnChanges(changes: SimpleChanges): void {
    setPropertyInDivLayout(
      this.elRef,
      '--main-background-color',
      this.loginDesign?.backGround?.color
    );
  }

  ngOnInit(): void {
    setPropertyInDivLayout(
      this.elRef,
      '--main-background-color',
      this.loginDesign?.backGround?.color
    );
    setPropertyInDivLayout(
      this.elRef,
      '--chip-color',
      this.loginDesign?.chip?.color
    );
    if (this.loginDesign?.headingText?.font?.length > 0) {
      setPropertyInDivLayout(
        this.elRef,
        '--heading-font',
        this.loginDesign?.headingText?.font
      );
    }
    if (this.loginDesign?.caption?.font?.length > 0) {
      setPropertyInDivLayout(
        this.elRef,
        '--caption-font',
        this.loginDesign?.caption?.font
      );
    }
    if (this.loginDesign?.actionButton?.fontColor?.length > 0) {
      setPropertyInDivLayout(
        this.elRef,
        '--action-font-color',
        this.loginDesign?.actionButton?.fontColor
      );
    }
    if (this.loginDesign?.actionButton?.buttonColor?.length > 0) {
      setPropertyInDivLayout(
        this.elRef,
        '--action-button-color',
        this.loginDesign?.actionButton?.buttonColor
      );
    }
    if (this.loginDesign?.caption?.headingFontColor?.length > 0) {
      setPropertyInDivLayout(
        this.elRef,
        '--caption-heading-color',
        this.loginDesign?.caption?.headingFontColor
      );
    }
    if (this.loginDesign?.caption?.textFontColor?.length > 0) {
      setPropertyInDivLayout(
        this.elRef,
        '--caption-description-color',
        this.loginDesign?.caption?.textFontColor
      );
    }
  }
  navigateToUrl(
    link: string = 'https://play.google.com/store/apps/details?id=com.nslhub.brane.efundzz'
  ) {
    window.open(link);
  }
}
