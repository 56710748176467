import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LibraryFacadeService, ChangeUnitFacadeService, AlertService,TransactionFacadeService,TranslatorService, EventsFacadeService,keyIdentifierGetter, EntityFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-add-attr-to-entity',
  templateUrl: './add-attr-to-entity.component.html',
  styleUrls: ['./add-attr-to-entity.component.scss'],
})
export class AddAttrToEntityComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Input() gsiMasterId: any;
  @Input() triggerCuIndex: any;
  @Input() masterCuId: any;
  @Input() gsiName: any;
  @Input() cuName: any;
  @Input() entityListInfo: any = [];
  @Input() entityListPhysical: any = [];
  entityList: any = [];
  //for pagination
  pageIndex = 0;
  pageSize = 10;
  entityIndex: any;
  attrIndex: any;
  searchText = '';
  uiControls: any;
  openDefaultVlaue = false;
  //Storing attribute details in formgroup
  attrDetails: FormGroup = this.formBuilder.group({
    defaultValue: [''],
    defaultOption: [''],
    isMultiValue: [''],
    attributeName: [''],
    attributeUiElement: [''],
    attributeType: [''],
    unitType: [''],
    maxValue: [''],
    format: [''],
    dateFormat: [''],
    isRadioChecked: [''],
    countReset: [''],
    locationType: [''],
    constantChangeDriver: [''],
    constantChangeDriverValue: [''],
    financialMonth: [''],
    countStartParameter: [''],
    incIdLeadingZeroes: [''],
    formatFields: this.formBuilder.array([]),
  });
  dateFormats: any = [
    'MM',
    'dd',
    'yy',
    'yyyy',
    'dd/MM/yyyy',
    'yyyy-MM-dd',
    'dd-MMM-yyyy',
    'E, MMM dd yyyy',
    'yy-yy',
    'yyyy-yyyy',
  ];
  countResetParams: any = ['None', 'MM', 'dd', 'yyyy'];
  separators: any = ['+', '-', '*', '%', '/', '(', ')', '_'];
  locationArray: any = [];
  separatingOperatorSelected = false;
  selectorSeparated: string;
  separator: string;
  // location-ui-control variables starts //
  locationType: any;
  // Audio/video/file-ui-control variables starts//
  attrConfiguration: { locationType: any };
  editFileUrl: any;
  fileUrl: any;
  // scheduler-ui-control variables starts//
  isDay = false;
  isTime = false;
  timeFormat: any;
  schedulerDetails = this.formBuilder.group({
    incrementValue: [''],
    day: [''],
    time: [''],
    interval: [''],
    recurrenceExpression: [''],
    startDate: [''],
    endDate: [''],
  });
  //for changeDriver property
  intervalOptions: any[];
  isEditAttrFromEntityPanel = true;
  isInEventSelection = false;
  selectedUiValidator: any;
  UiValidatorDropdown: any;
  constraints: any = [];
  selectedValidatorsList: any = [];
  selectedChangedriverOpt: any;
  optChangeDrivers: any[];
  selectedAlternateAttr: any;
  attributeValidations: any;
  editEntityAttrData: any = {};
  iseditAttr = false;
  constantChangeDriverObj: any = {};
  onDialogBox = false;
  onShowUI = false;
  dataType: any;
  nextIcon = true;
  currentAttrName: any;
  formatFields: FormArray;
  isIncrementValue = false;
  attr: any;
  currentEntity: any;
  negEntity = false;
  negEntMsg: string;
  createFormat(type: string): FormGroup {
    return this.formBuilder.group({
      type: type,
      format: '',
      entAttributeName: '',
      dateFormat: '',
      entAttributeString: '',
      entAttributeStringValue: '',
    });
  }
  //variables for Attribue data type (Autoidentifier).
  getFinancialMonth: boolean;
  financialMonth: any;
  incIdLeadingZeroes: any;
  countStartParameter: any;
  allDefaultValues: any = [];
  multiDefaultValue = false;
  entityAttributeSelection: any = [];
  // Need to use static because it requires in Autoidentifier
  Months: any = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
  attributeNameOptions: any = [
    { name: 'Full', value: '' },
    { name: 'Suffix', value: 'suf' },
    { name: 'Prefix', value: 'pre' },
  ];

  attrName: any;
  nestedEntities: any = [];
  alertFlag = false;
  msg: string;
  attrType: string;
  attrDataTypeName: any;
  //response format
  layers: any = [
    { type: 'physical', label: 'physical', participatingItems: [] },
    { type: 'information', label: 'information', participatingItems: [] },
  ];
  layerType: any = [
    { name: 'physical', completed: true },
    { name: 'information', completed: false },
  ];
  layer: any = [];
  masterId: any;
  selectUI = false;
  selectedStatus: string;
  layerflag = false;
  isEditable = true;
  attrDefault: any;
  entityListNew: any = [];
  AtIndex = 0;
  newAttr: any;
  sourceValues: any = [];
  selecteduiElement: any;
  selectedtype: any;
  searchAttrText: string;
  multiValue: boolean;
  addEntityBtn: boolean = true;
  normalEntities: any;
  labels: any;

  constructor(
    private libraryFacade: LibraryFacadeService,
    private formBuilder: FormBuilder,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private transactionFacadeService: TransactionFacadeService,
    private alertService: AlertService,
    private translator: TranslatorService,
    private entityFacadeService: EntityFacadeService,
    private eventsFacadeService: EventsFacadeService
  ) {
    this.detectLibraryDataTypes();
    this.detectLanguageChange();
    this.getValidations();
  }
  ngOnInit(): void {
    this.assignEntitylist();
    this.entityList?.forEach((ent: any) => {
      for (let value of ent.name) {
        if (value == '$') {
          ent.name = ent.name.substring(0, ent.name.indexOf('$'));
        }
      }
      this.nestedEntities = ent.nslAttributes.filter((attr: any) => {
        if (attr.nslAttributes) {
          return attr;
        }
      });
      this.normalEntities = ent.nslAttributes.filter((attr: any) => {
        if (!attr.nslAttributes) {
          return attr;
        }
      });
      ent.nslAttributes = [];
      ent.nslAttributes = this.normalEntities;
    });
  }
  //Language Translator API call
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.locationArray = [
        this.labels?.Current_Location,
        this.labels?.Location_Markers,
        this.labels?.Location_Tracker,
      ];

      /* istanbul ignore next  */
      this.optChangeDrivers = [this.labels?.OPTIONAL, this.labels?.NEGATIVE, this.labels?.ALTERNATIVE, 'none'];
      /* istanbul ignore next  */
      this.intervalOptions = [
        this.labels?.SECOND,
        this.labels?.MINUTE,
        this.labels?.HOUR,
        this.labels?.DAY,
        this.labels?.MONTH,
        this.labels?.WEEK,
      ];
    });
  }
  //for checking duplicate attribute
  checkAttrName(Attrindex: any, Entityindex: any) {
    this.attrIndex = Attrindex;
    /* istanbul ignore next  */
    this.attrName = this.entityList[Entityindex]?.nslAttributes[this.attrIndex].name;
    /* istanbul ignore next  */
    if (this.attrName) {
      /* istanbul ignore next  */
      if (this.entityList[Entityindex]?.nslAttributes.length > 0) {
        for (let i = 0; i < this.entityList[Entityindex]?.nslAttributes.length - 1; i++) {
          /* istanbul ignore next  */
          if (this.attrName === this.entityList[Entityindex]?.nslAttributes[i].name) {
            this.alertFlag = true;
            this.msg = 'Attribute name Already Exists';
            setTimeout(() => {
              this.msg = '';
            }, 1000);
            this.entityList[Entityindex].nslAttributes[this.attrIndex].name = '';
          }
        }
      }
    }
  }
  //adding new attribute to entity
  addAttribute(entityindex: any) {
    if (this.entityList[entityindex].isNegative) {
      this.negEntity = true;
      this.negEntMsg = 'Can Not Add Attribute To Negative Entity';
      setTimeout(() => {
        this.negEntity = false;
        this.negEntMsg = '';
      }, 1000);
    } else {
      this.negEntity = false;
      this.entityIndex = entityindex;
      this.entityList[this.entityIndex].nslAttributes.push({
        name: '',
        uiElement: '',
        editable: false,
        attributeType: {
          uiElement: { name: '', dataType: '', uiElement: '', isMulti: false, esDataType: '' },
          type: '',
          properties: [],
          nestedNslDataTypeProperties: {},
          extendedProperties: {},
        },
      });
    }
  }
  //Calling attribute data type APi
  showDataTypes(index: any, Entityindex: any, attr: any) {
    this.nextIcon = false;
    this.attrIndex = index;
    this.entityIndex = Entityindex;
    /* istanbul ignore next  */
    this.currentAttrName = attr?.name;
    this.searchText = '';
    this.attrDataTypeName = '';
    /* istanbul ignore next  */
    this.entityList[Entityindex].nslAttributes[this.attrIndex].displayName = attr?.name;
    if (this.alertFlag) {
      this.alertFlag = false;
    } else {
      this.selectUI = true;
      this.detectLibraryDataTypes();
    }
  }
  //Calling attribute data type APi
  detectLibraryDataTypes() {
    this.libraryFacade.getAllAttributeTypes(this.searchText, this.pageIndex, this.pageSize);
    this.libraryFacade.libraryData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next  */
      this.uiControls = res?.content;
    });
  }
  // for searching attribute datatype by text
  searchTextAttrData(value: any) {
    this.searchText = this.attrType;
    this.detectLibraryDataTypes();
  }
  //selecting Atribute datatype
  selectUiControl(dataType: any) {
    /* istanbul ignore next  */
    this.dataType = dataType?.dataType;
    this.openDefaultVlaue = true;
    this.searchAttrText = '';
    this.openDefaultVlaue = true;
    this.searchText = '';
    /* istanbul ignore next  */
    this.attr = this.entityList[this.entityIndex]?.nslAttributes[this.attrIndex];
    /* istanbul ignore next  */
    this.currentEntity = this.entityList[this.entityIndex];
    let attrData = this.entityList[this.entityIndex]?.nslAttributes[this.attrIndex]?.attributeType;
    /* istanbul ignore next  */
    let Attr = this.entityList[this.entityIndex]?.nslAttributes[this.attrIndex];
    /* istanbul ignore next  */
    this.multiValue = dataType?.isMulti;
    /* istanbul ignore next  */
    if (dataType.isMulti === true) {
      if (dataType.isMulti === true && dataType.uiElement != 'typeahead') {
        this.addEntityBtn = false;
      }
    } else {
      this.addEntityBtn = true;
    }
    if (attrData.uiElement.uiElement === dataType?.uiElement) {
      /* istanbul ignore next  */
      this.attrDetails.patchValue({
        defaultValue: Attr?.defaultValue,
        attributeUiElement: dataType?.uiElement,
        attributeType: dataType?.uiElement,
        defaultOption: [''],
        isMultiValue: attrData.type == 'list',
        attributeName: Attr?.name,
        constantChangeDriver: false,
        constantChangeDriverValue: '',
        countReset: attrData?.properties?.countResetParameter,
        maxValue: attrData?.properties?.incIdMaxValue,
        countStartParameter: attrData?.properties?.countStartParameter,
        incIdLeadingZeroes: attrData?.properties?.incIdLeadingZeroes,
        financialMonth: attrData?.properties?.financialMonth,
      });
      /* istanbul ignore next  */
      this.sourceValues = attrData?.extendedProperties?.sourceValues;
      /* istanbul ignore next  */
      this.selectedChangedriverOpt = Attr['nslAttributeMetaData']?.changeDriverTypes;
      /* istanbul ignore next  */
      this.selectedAlternateAttr = Attr['nslAttributeMetaData']?.alternativeAttribute;
    } else {
      this.sourceValues = [];
      /* istanbul ignore next  */
      this.attrDetails.patchValue({
        defaultValue: '',
        attributeUiElement: dataType?.uiElement,
        attributeType: dataType?.uiElement,
        defaultOption: [''],
        isMultiValue: false,
        attributeName: Attr?.name,
      });
      this.selectedValidatorsList = [];
      this.selectedChangedriverOpt = '';
      this.selectedAlternateAttr = '';
      this.selectedUiValidator = null;
      attrData.properties = {};
      attrData.extendedProperties = {};
    }
    /* istanbul ignore next  */
    let autoIdFormat = attrData?.properties?.format;
    if (autoIdFormat && autoIdFormat !== '') {
      /* istanbul ignore next  */
      this.attrDetails.controls.formatFields['controls'] = [];
      let replacedFormat = autoIdFormat?.slice(1, autoIdFormat.length - 1);
      let splitFormat = replacedFormat.split('}{');
      let formFields = [];
      splitFormat?.forEach((field: any, fieldIndex: number) => {
        this.formatFields = this.attrDetails.get('formatFields') as FormArray;
        if (field.includes('${')) {
          this.formatFields.push(this.createFormat('attribute'));
          this.attrDetails.value.formatFields[fieldIndex].type = 'attribute';
          let attributeSplit = field.split('}');
          this.attrDetails.value.formatFields[fieldIndex].entAttributeName = attributeSplit[0].replace('${', '');
          if (attributeSplit[1]) {
            let stringTypeSplit = attributeSplit[1].split('.');
            this.attrDetails.value.formatFields[fieldIndex].entAttributeString = stringTypeSplit[1];
            this.attrDetails.value.formatFields[fieldIndex].entAttributeStringValue = stringTypeSplit[2];
          }
        } else if (field[0] === '$') {
          if (field === '$SEP') {
            this.formatFields.push(this.createFormat('separator'));
            this.separatingOperatorSelected = true;
            /* istanbul ignore next  */
            this.selectorSeparated = Attr?.attributeType?.properties?.separator;
            this.attrDetails.value.formatFields[fieldIndex].type = 'separator';
          } else if (field === '$INC_ID') {
            this.formatFields.push(this.createFormat('increment'));
            this.attrDetails.value.formatFields[fieldIndex].type = 'increment';
          } else {
            this.formatFields.push(this.createFormat('date'));
            this.attrDetails.value.formatFields[fieldIndex].dateFormat = field.replace('$', '');
            this.attrDetails.value.formatFields[fieldIndex].type = 'date';
          }
        } else {
          this.formatFields.push(this.createFormat('format'));
          this.attrDetails.value.formatFields[fieldIndex].format = field;
          this.attrDetails.value.formatFields[fieldIndex].type = 'format';
        }
      });
      this.attrDetails.patchValue({
        formatFields: this.attrDetails.value.formatFields,
      });
      this.checkIncrementIDPresence();
    }
    /* istanbul ignore else  */
    if (this.isEditAttrFromEntityPanel) {
      this.getvalidationsList();
    }
    this.selecteduiElement = dataType;
    this.selectedtype = dataType.dataType;
    /* istanbul ignore next  */
    this.entityList[this.entityIndex].nslAttributes[this.attrIndex].uiElement = dataType?.name;
    Attr.editable = true;
    Attr.attributeType.uiElement.isMulti = dataType.isMulti;
    this.editEntityAttrData = { ...this.entityList[this.entityIndex].nslAttributes[this.attrIndex] };
    /* istanbul ignore next  */
    if (Attr?.constantChangedriverEntity || Attr?.constantChangeDriverValue) {
      /* istanbul ignore next  */
      this.attrDetails.patchValue({
        constantChangeDriverValue: Attr?.constantChangeDriverValue ? Attr?.constantChangeDriverValue[0] : '',
        constantChangeDriver: true,
      });
      this.editFileUrl = this.IsJsonString(Attr.constantChangeDriverValue)
        ? JSON.parse(Attr.constantChangeDriverValue)
        : null;
    }
    /// location-ui-control patching values starts///
    /* istanbul ignore next  */
    if (Attr?.configuration) {
      /* istanbul ignore next  */
      let foundAttr = Attr?.configuration;
      this.attrConfiguration = JSON.parse(foundAttr);
      this.attrDetails.patchValue({
        locationType: this.attrConfiguration?.locationType,
      });
    }
    /* istanbul ignore next  */
    if (
      Attr?.defaultValue &&
      (attrData.type == 'file' || attrData.type == 'audio' || attrData.type == 'video' || attrData.type == 'image')
    ) {
      this.editFileUrl = Attr.defaultValue ? JSON.parse(Attr.defaultValue) : null;
    }
    this.onShowUI = false;
    this.onDialogBox = true;
  }
  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  //APi call for getting validator list
  getValidations() {
    this.entityFacadeService.getUIValidations();
    this.entityFacadeService.attributeValidation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else  */
      if (res) {
        this.attributeValidations = res;
      }
    });
  }
  getvalidationsList() {
    /* istanbul ignore next  */
    this.editEntityAttrData = this.entityList[this.entityIndex]?.nslAttributes[this.attrIndex];
    /* istanbul ignore else  */
    if (this.dataType) {
      const constraintsData =
        this.attributeValidations?.length > 0 ? JSON.parse(JSON.stringify(this.attributeValidations)) : [];
      /* istanbul ignore next  */
      this.UiValidatorDropdown = constraintsData?.find((value: any) => {
        return value.name === this.dataType;
      });

      /* istanbul ignore next  */
      this.UiValidatorDropdown?.constraints.map((val: any) => {
        /* istanbul ignore else  */
        if (val.name == 'optional' || val.name == 'empty') {
          val.disabled = true;
        }
      });
      /* istanbul ignore next  */
      if (this.attributeValidations && this.attributeValidations?.length) {
        this.selectedValidatorsList = [];
        /* istanbul ignore else  */
        if (
          this.editEntityAttrData &&
          this.editEntityAttrData['constraints'] &&
          this.editEntityAttrData['constraints'].length
        ) {
          this.editEntityAttrData['constraints']?.forEach((value: any) => {
            /* istanbul ignore next  */
            const option = this.UiValidatorDropdown?.constraints?.find((val: any) => {
              return val.name == value.name;
            });
            const obj = {
              name: value.name,
              displayName: option?.displayName ? option.displayName : value.name,
            };
            obj['properties'] = [];
            /* istanbul ignore else  */
            if (value.properties && Object.keys(value.properties).length) {
              let editConstraintsArrKeys = Object.keys(value.properties);
              let editConstraintsArrValues = Object.values(value.properties);

              /* istanbul ignore next  */
              editConstraintsArrKeys?.forEach((val, i) => {
                obj['properties'].push({
                  name: val,
                  message: editConstraintsArrValues[i],
                });
              });
            }
            /* istanbul ignore else  */
            if (this.iseditAttr) {
              this.selectedValidatorsList.push(obj);
              this.selectedValidatorsList.filter((el: any) => {
                /* istanbul ignore next  */
                this.UiValidatorDropdown.constraints = this.UiValidatorDropdown?.constraints?.filter((el1: any) => {
                  return el1.name !== el.name;
                });
              });
            }
          });
        }
      }
    }
  }
  //selecting separator for autoidentifier
  selectSeparator(res: string) {
    this.selectorSeparated = res;
    this.separatingOperatorSelected = true;
  }
  //adding attribute data to entity
  addDefaultValue() {
    /* istanbul ignore next  */
    let attrData = this.entityList[this.entityIndex]?.nslAttributes[this.attrIndex];
    /* istanbul ignore next  */
    attrData.defaultValue = this.attrDetails?.value?.defaultValue;
    attrData.attributeType.uiElement = this.selecteduiElement;
    /* istanbul ignore next  */
    if (!this.schedulerDetails?.value?.endDate && this.attrDetails?.value?.attributeType == 'scheduler') {
      this.alertService.showToaster(this.labels?.Please_provide_endDate, '', 'info');
      return false;
    }
    /* istanbul ignore next  */
    if (!this.addEntityBtn && this.sourceValues.length == 0 && this.attrDetails?.value?.attributeType !== 'scheduler') {
      this.alertService.showToaster(this.labels?.Please_add_atleast_one_option, '', 'info');
      return false;
    }
    if (this.entityList && this.entityList[this.entityIndex].nslAttributes == undefined) {
      this.entityList[this.entityIndex].nslAttributes = [];
    }
    /* istanbul ignore next  */
    if (this.attrDetails.valid) {
      let AttributeType = this.entityList[this.entityIndex]?.nslAttributes[this.attrIndex].attributeType;
      if (this.fileUrl) {
        this.entityList.nslAttributes[this.attrIndex].defaultValue = JSON.stringify(this.fileUrl);
      }
      if (this.attrDetails.value.isMultiValue) {
        AttributeType.type = 'list';
        AttributeType.nestedNslDataTypeProperties = {
          item_type: { name: this.selectedtype },
        };
      } else {
        AttributeType.type = this.selectedtype;
      }
      AttributeType.properties = {};
      AttributeType.extendedProperties = {};
      AttributeType.extendedProperties.sourceValues = [...this.sourceValues];
      /* istanbul ignore next  */
      if (this.attrDetails?.value?.attributeUiElement === 'currency') {
        AttributeType.properties.currencyType = this.attrDetails.value.unitType;
      }
      if (this.attrDetails?.value?.attributeUiElement === 'metric') {
        AttributeType.properties.unit = this.attrDetails.value.unitType;
      }
      /* istanbul ignore next  */
      if (this.attrDetails?.value?.attributeUiElement === 'autoIdentifier') {
        AttributeType.properties = {};
        let keyIdentifier = '';
        /* istanbul ignore next  */
        this.attrDetails?.controls?.formatFields['controls']?.forEach((fieldObj: any) => {
          keyIdentifier += keyIdentifierGetter(fieldObj, '');
        });
        AttributeType.properties.format = keyIdentifier;
        AttributeType.properties.separator = this.selectorSeparated;
        if (this.isIncrementValue) {
          if (this.attrDetails?.value?.maxValue) {
            AttributeType.properties.incIdMaxValue = this.attrDetails?.value?.maxValue;
          }
          if (this.attrDetails?.value?.countReset && this.attrDetails?.value?.countReset !== 'None') {
            AttributeType.properties.countResetParameter = this.attrDetails?.value?.countReset;
          }
          if (this.attrDetails?.value?.countStartParameter) {
            AttributeType.properties.countStartParameter = this.attrDetails?.value?.countStartParameter;
          }
          if (this.attrDetails?.value?.incIdLeadingZeroes) {
            AttributeType.properties.incIdLeadingZeroes = this.attrDetails?.value?.incIdLeadingZeroes;
          }
        }
        if (this.getFinancialMonth) {
          AttributeType.properties.financialMonth = this.attrDetails?.value?.financialMonth;
        }
      }
      //changing the time format
      if (this.attrDetails?.value?.attributeType === 'scheduler') {
        AttributeType.properties = {};
        if (this.schedulerDetails?.value?.time) {
          let time = new Date(this.schedulerDetails.value.time);
          this.schedulerDetails.value.time =
            ('0' + time.getUTCHours())?.slice(-2) +
            ':' +
            ('0' + time.getUTCMinutes())?.slice(-2) +
            ':' +
            ('0' + time.getUTCSeconds())?.slice(-2);
        }
        if (this.schedulerDetails?.value?.recurrenceExpression == null) {
          this.schedulerDetails.value.recurrenceExpression = '';
        }
        if (this.schedulerDetails?.value?.startDate) {
          this.schedulerDetails.value.startDate = this.schedulerDetails?.value?.startDate.toISOString();
        }
        if (this.schedulerDetails?.value?.endDate) {
          this.schedulerDetails.value.endDate = this.schedulerDetails?.value?.endDate.toISOString();
        }
        AttributeType.properties = this.schedulerDetails.value;
      }
      if (this.attrDetails.value.attributeUiElement == 'location') {
        this.attrConfiguration = { locationType: this.attrDetails?.value?.locationType };
        this.entityList.nslAttributes[this.attrIndex]['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      this.appendUiAttributes();
      if (this.selectedChangedriverOpt) {
        let nslAttributeMetaData = {};
        if (!this.selectedAlternateAttr) {
          nslAttributeMetaData = { changeDriverTypes: this.selectedChangedriverOpt };
        } else {
          nslAttributeMetaData = {
            changeDriverTypes: this.selectedChangedriverOpt,
            alternativeAttribute: this.selectedAlternateAttr,
          };
        }

        attrData['nslAttributeMetaData'] = nslAttributeMetaData;
      }
      if (this.selectedChangedriverOpt == 'none') {
        delete attrData['nslAttributeMetaData'];
      }
      attrData.constraints = this.constraints;
      AttributeType.extendedProperties.sourceValues = [...this.sourceValues];
    }
    /* istanbul ignore next  */
    this.constantChangeDriverObj.selected = this.attrDetails?.value?.constantChangeDriver;
    if (this.attrDetails?.value?.constantChangeDriverValue.trim() !== '' && this.constantChangeDriverObj.selected) {
      /* istanbul ignore next  */
      this.constantChangeDriverObj.values = [this.attrDetails?.value?.constantChangeDriverValue];
    }
    /* istanbul ignore next  */
    if (this.constantChangeDriverObj.selected && this.fileUrl) {
      this.constantChangeDriverObj.values = [JSON.stringify(this.fileUrl)];
    }
    this.changeUnitFacadeService.addConstantChangeDriverToSlot(this.constantChangeDriverObj);

    this.uiControls = [];
    this.openDefaultVlaue = false;
    this.selectUI = false;
    this.attrDetails.reset();
    this.schedulerDetails.reset();
    this.selectedChangedriverOpt = '';
    this.selectedAlternateAttr = '';
    this.editFileUrl = '';
    this.attrDetails.patchValue({
      isMultiValue: false,
      constantChangeDriver: false,
      constantChangeDriverValue: '',
    });
    this.sourceValues = [];
    this.iseditAttr = false;
    this.editEntityAttrData = [];
    this.fileUrl = undefined;
    this.selectedValidatorsList = [];
    this.selectedUiValidator = null;
    this.nextIcon = true;
    this.editFileUrl = '';
    this.onDialogBox = false;
    this.AtIndex = this.AtIndex + 1;
  }
  //changing validation
  validatorsChange(event: any, index: any) {
    event.properties.map((val: any) => {
      val.message = '';
    });
    this.selectedUiValidator = event.name;
    this.selectedValidatorsList.push(event);
    this.UiValidatorDropdown.constraints.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });

    this.UiValidatorDropdown.constraints.splice(index, 1);
    this.constraints = [];
    const constraintsArr = this.eventsFacadeService.getDisableValidatorsList(event.name);
    /* istanbul ignore next  */
    if (constraintsArr && constraintsArr.length) {
      constraintsArr?.forEach((element: any, i: any) => {
        this.UiValidatorDropdown.constraints?.forEach((value: any, j: any) => {
          /* istanbul ignore else  */
          if (element === value.name) {
            value.disabled = true;
          }
        });
      });
    }
  }
  //removing validator
  removeValidator(row: any, index: any) {
    this.selectedValidatorsList.splice(index, 1);
    this.UiValidatorDropdown.constraints.map((value: any) => {
      value.disabled = false;
      /* istanbul ignore else  */
      if (value.name == 'optional' || value.name == 'empty') {
        value.disabled = true;
      }
    });
    this.UiValidatorDropdown.constraints.push(row);
    this.UiValidatorDropdown.constraints = this.UiValidatorDropdown.constraints.filter(
      (v: any, i: any, a: any) => a.findIndex((t: any) => t.name === v.name) === i
    );
  }
  //adding validator to attribute
  appendUiAttributes() {
    this.constraints = [];
    this.selectedValidatorsList?.forEach((element: any, i: any) => {
      /* istanbul ignore else  */
      if (element) {
        this.constraints.push({
          name: element.name,
          displayName: element.displayName,
          properties: {},
        });
        /* istanbul ignore else  */
        if (element.properties && element.properties.length) {
          element.properties?.forEach((value: any) => {
            this.constraints[i].properties[value.name] = value?.message;
          });
        }
      }
    });
  }
  //use for scheduler datatype
  onSelectionChange(event: any) {
    if (event.value === 'MONTH' || event.value === 'WEEK') {
      this.schedulerDetails.get('day').patchValue('');
      this.schedulerDetails.get('time').patchValue('');
      this.isDay = true;
      this.isTime = true;
    } else if (event.value === 'DAY') {
      this.schedulerDetails.get('day').patchValue('');
      this.schedulerDetails.get('time').patchValue('');
      this.isDay = false;
      this.isTime = true;
    } else {
      this.schedulerDetails.get('day').patchValue('');
      this.schedulerDetails.get('time').patchValue('');
      this.isDay = false;
      this.isTime = false;
    }
  }
  updateNewTimeFormat(event: any) {
    /* istanbul ignore next  */
    this.timeFormat = event?.value;
  }
  fileUploadUrl(event: any) {
    this.fileUrl = event;
  }
  //editing Attribute
  editAttribute(Attrindex: any, Entityindex: any, attr: any) {
    /* istanbul ignore next  */
    this.currentAttrName = attr?.name;
    this.nextIcon = false;
    this.entityIndex = Entityindex;
    this.attrIndex = Attrindex;
    /* istanbul ignore next  */
    this.attrDataTypeName = attr?.uiElement;
    /* istanbul ignore next  */
    let attrData = this.entityList[Entityindex]?.nslAttributes[this.attrIndex]?.attributeType;
    /* istanbul ignore else  */
    if (Attrindex !== undefined || Attrindex !== null || Attrindex !== '') {
      this.iseditAttr = true;
    } else {
      this.iseditAttr = false;
    }
    this.entityAttributeSelection = [];
    /* istanbul ignore else  */
    if (!Attrindex) {
      /* istanbul ignore next  */
      this.entityAttributeSelection.push({
        displayName: attr?.attributes?.value?.name,
        name: attr?.attributes?.value?.name,
      });
    }
    /* istanbul ignore next  */
    this.constantChangeDriverObj = {
      slotId: attr?.slotId,
      slotIndex: attr?.slotIndex,
      entity: this.entityList[Entityindex],
    };
    /* istanbul ignore next  */
    if (attrData?.properties?.time) {
      this.isTime = true;
      this.timeFormat = new Date('2/16/2021, ' + attrData.properties.time + ' UTC');
      attrData.properties.time = new Date('2/16/2021, ' + attrData.properties.time + ' UTC');
    }
    /* istanbul ignore next  */
    if (attrData?.properties?.day) {
      this.isDay = true;
      this.isTime = false;
    }
    /* istanbul ignore next  */
    this.schedulerDetails.patchValue({
      incrementValue: attrData?.properties?.incrementValue,
      day: attrData?.properties?.day,
      time: attrData?.properties?.time,
      interval: attrData?.properties?.interval,
      recurrenceExpression: attrData.properties?.recurrenceExpression,
      startDate: attrData.properties?.startDate,
      endDate: attrData.properties?.endDate,
    });
    /* istanbul ignore next  */
    let sourceValuesData = attrData?.properties?.sourceValues ? JSON.parse(attrData?.properties?.sourceValues) : [];
    /* istanbul ignore next  */
    if (attrData?.extendedProperties?.sourceValues) {
      this.sourceValues = attrData?.extendedProperties?.sourceValues;
    } else if (sourceValuesData.length > 0) {
      /* istanbul ignore next  */
      sourceValuesData?.forEach((value: any) => {
        this.sourceValues.push({
          TYPE: 'OptionValue',
          DATA: {
            actualValue: value,
            displayValue: value,
          },
        });
      });
    }
    this.selectUI = true;
    this.searchText = '';
    this.detectLibraryDataTypes();
    this.onDialogBox = true;
  }
  //adding changeunit driver property to attribute
  addDefaultValuetoAttibute() {
    /* istanbul ignore else  */
    if (this.sourceValues.length >= 0) {
      this.addEntityBtn = true;
    }
    /* istanbul ignore else  */
    if (this.attrDetails.value.defaultOption.trim() !== '') {
      /* istanbul ignore next  */
      this.sourceValues.push({
        TYPE: 'OptionValue',
        DATA: {
          actualValue: this.attrDetails?.value?.defaultOption,
          displayValue: this.attrDetails?.value?.defaultOption,
        },
      });
    }
    this.attrDetails.patchValue({
      defaultOption: '',
    });
  }
  //removing changeunit driver property
  removeScourceValue(i: any) {
    /* istanbul ignore else  */
    if (this.sourceValues.length <= 1) {
      this.addEntityBtn = false;
    }
    this.sourceValues.splice(i, 1);
  }
  deleteAttribute(Attrindex: any, Entityindex: any) {
    this.entityIndex = Entityindex;
    this.entityList[Entityindex].nslAttributes.splice(Attrindex, 1);
  }
  //use for attribute type autoidentifier starts
  addField(fieldType: string) {
    this.formatFields = this.attrDetails.get('formatFields') as FormArray;
    this.formatFields.push(this.createFormat(fieldType));
    this.checkIncrementIDPresence();
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
  checkIncrementIDPresence() {
    this.isIncrementValue = false;
    /* istanbul ignore next  */
    this.attrDetails?.controls?.formatFields['controls']?.forEach((field: any) => {
      /* istanbul ignore else  */
      if (field.value.type === 'increment') {
        this.isIncrementValue = true;
      }
    });
  }
  removeField(fieldIndex: number) {
    /* istanbul ignore next  */
    this.attrDetails?.controls?.formatFields['controls']?.splice(fieldIndex, 1);
    this.checkIncrementIDPresence();
  }
  checkYearFormat() {
    this.getFinancialMonth = false;
    this.attrDetails.value.formatFields?.forEach((field: any) => {
      /* istanbul ignore else  */
      if (field.type === 'date' && (field.dateFormat === 'yy-yy' || field.dateFormat === 'yyyy-yyyy')) {
        this.getFinancialMonth = true;
      }
    });
  }
  removeAttributeSelection(field: any) {
    field.controls.entAttributeName.value = '';
  }
  selectedAttribute(attr: any, field: any) {
    field.controls.entAttributeName.value = attr;
    field.value.entAttributeName = attr;
  }
  //submitting data and  calling subtransactional API call
  submitDefaultValues() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.entityListNew = JSON.parse(JSON.stringify(this.entityList));

    this.entityListNew?.forEach((ent: any) => {
      this.newAttr = ent.nslAttributes.filter((attr: any) => {
        /* istanbul ignore else  */
        if (attr.editable) {
          return attr;
        }
      });
      ent.nslAttributes = [];
      /* istanbul ignore next  */
      ent.nslAttributes = this?.newAttr;
    });

    if (this.layerType[0].completed && this.layerType[1].completed) {
      this.layers[0].participatingItems = this.entityListNew;
      this.layers[1].participatingItems = this.entityListNew;
      this.layer = this.layers;
    } else if (this.layerType[0].completed) {
      this.layers[0].participatingItems = this.entityListNew;
      this.layer.push(this.layers[0]);
    } else if (this.layerType[1].completed) {
      /* istanbul ignore else  */
      this.layers[1].participatingItems = this.entityListNew;
      this.layer.push(this.layers[1]);
    }
    /* istanbul ignore next  */
    let cuInfo = {
      cuMasterId: this.masterCuId,
      triggerCUIndex: this.triggerCuIndex,
      gsiMasterId: this.gsiMasterId,
      gsiName: this.gsiName,
      cuName: this.cuName,
      layers: this.layer,
      designTimeRights: [
        {
          informationRight: false,
          decisionRight: true,
          executionRight: false,
          rightHolderId: userInfo?.id,
          rightHolderType: userInfo?.name,
        },
      ],
      txnTimeRights: [
        {
          informationRight: false,
          decisionRight: false,
          executionRight: true,
          rightHolderId: userInfo.id,
          rightHolderType: userInfo.name,
        },
      ],
    };
    this.transactionFacadeService.getSubtransactionalEntity(cuInfo);
  }
  assignEntitylist() {
    /* istanbul ignore next  */
    if (this.layerType[0]?.completed) {
      this.entityList = JSON.parse(JSON.stringify(this.entityListPhysical));
    } else {
      this.entityList = JSON.parse(JSON.stringify(this.entityListInfo));
    }
  }
  onLayerChange(type: any) {
    /* istanbul ignore next  */
    if (type == 'physical') {
      this.layerType[0].completed = true;
      this.layerType[1].completed = false;
    } else {
      this.layerType[0].completed = false;
      this.layerType[1].completed = true;
    }
    this.assignEntitylist();
  }
  clearSearchText() {
    this.attrType = '';
  }
}
