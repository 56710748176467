import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dcdLabel',
  pure: true,
})
export class DcdLabelPipe implements PipeTransform {
  //this pipe separates a contextual id with sach for dcd label display.
  transform(item: any, onlyGsi: boolean, isNsl?: any): string {
    let splitItems = [];
    let output = '';

    if (item) {
      return this.createDcdPath(item);
    }
    return output;
  }

  createDcdPath(val: any) {
    const criteria = ['PL.SL', 'IL.SL', 'TL.SL', 'triggerCES.SL'];
    const filteredParts = val?.split('.')?.filter((part: any) => !part?.startsWith('slot:'));
    if (criteria?.some((data) => val?.startsWith(data))) {
      filteredParts.splice(0, 2);
    }
    return filteredParts.join('/');
  }
}
