import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import { ControlsMaterialModule } from '../material.module';
import { PhoneMaskDirective } from './phone-mask.directive';

@NgModule({
  declarations: [InputComponent, PhoneMaskDirective],
  imports: [ControlsMaterialModule],
  exports: [InputComponent, PhoneMaskDirective],
})
export class InputModule {
  static entry = {
    input: InputComponent,
    text: InputComponent,
    email: InputComponent,
    number: InputComponent,
    scormprogress: InputComponent,
    mobilenumber: InputComponent,
    password: InputComponent,
    encrypttext: InputComponent,
    textavatar: InputComponent,
  };
}
