import { NgModule } from '@angular/core';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { TimerComponent } from './timer.component';
import { ControlsMaterialModule } from '../material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
@NgModule({
  declarations: [TimerComponent],
  imports: [NgxMatDatetimePickerModule, ControlsMaterialModule, MatInputModule,
    MatFormFieldModule,],
  exports: [TimerComponent],
})
export class TimerModule {
  static entry = {
    timer: TimerComponent,
  };
}
