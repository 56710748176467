import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { currency, updateBetSelectedItems } from '@common-services';
import {
  AlertService,
  AttributeEndpointService,
  EntityFacadeService,
  EventsFacadeService,
  NodeGsiFacadeService,
  TranslatorService,
  defaultEditorConfig,
  displayDateDefaultValue,
  emptyObject,
  getSchedulerDetails,
  keyIdentifierGetter,
  validateDefaultValue,
  wrapperDrop,
  IsJsonString
} from '@common-services';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';

@Component({
  selector: 'app-node-independent-attribute-edit',
  templateUrl: './node-independent-attribute-edit.component.html',
  styleUrls: ['./node-independent-attribute-edit.component.scss'],
})
export class NodeIndependentAttributeEditComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  step: number = 0;
  @Input() tempGsiObj: any;
  @Input() singleEntity: boolean;
  @Input() configurationData: any;
  @Input() currentEntity: any;
  @Output() closeattribute: EventEmitter<any> = new EventEmitter<any>();

  entityOpen: boolean = true;
  addButtonClicked: boolean;
  disableAddButton: boolean;
  showPopup: boolean;
  viewLink: boolean;
  searchText: string = '';
  attributeTypes: any;
  uploadedFilesLength: number;
  selectedUiValidator: any;
  selectedValidatorsList: any;
  UiValidatorDropdown: any;
  canDownload: boolean = false;
  constraints: any[];
  dataType: any;
  unitType: boolean;
  sourceValues: any = [];
  attributeValidations: any;
  iseditAttr: any = true;
  attrDetails = this.formBuilder.group({
    defaultOption: [null],
    isMultiValue: [false],
    hideLabelMultiAttr: [false],
    hideMultiValueIcon: [false],
    unitType: [''],
    defaultValue: [''],
    constantChangeDriver: [''],
    constantChangeDriverValue: [''],
    attributeType: ['', Validators.required],
    attributeUiElement: ['', Validators.required],
    locationType: [''],
  });
  addEntityBtn: boolean = true;
  flagsave: boolean = true;
  fileDetails: any;
  fileUrl: any = [];
  eachImageUrl: any = [];
  chipImageUrl: any = [];
  labels: any;
  editFileUrl: any;
  changeUnit: any;
  editChipImageUrl: any;
  constantChangeDriverObj: any = {};
  constantChangeDrivers: any = {};
  selectorSeparated: string;
  formatFields: FormArray;
  autoIdForm: FormGroup = this.formBuilder.group({
    maxValue: [''],
    formatFields: this.formBuilder.array([]),
    countReset: [''],
    financialMonth: [''],
    countStartParameter: [''],
    incIdLeadingZeroes: [''],
  });
  Months: any = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];
  getFinancialMonth: boolean;
  attributeNameOptions: any = [
    { name: 'Full', value: '' },
    { name: 'Suffix', value: 'suf' },
    { name: 'Prefix', value: 'pre' },
  ];
  separators: any = ['+', '-', '*', '%', '/', '(', ')', '_'];
  dateFormats: any = [
    'MM',
    'dd',
    'yy',
    'yyyy',
    'dd/MM/yyyy',
    'yyyy-MM-dd',
    'dd-MMM-yyyy',
    'E, MMM dd yyyy',
    'yy-yy',
    'yyyy-yyyy',
  ];
  attributeTypeArr: string[] = [
    'date',
    'datetime',
    'newdate',
    'newdatetime',
    'currentdate',
    'currentdatetime',
    'dateRange',
    'dateonly',
    'time',
    'newtime',
    'timeonly',
  ];
  dateFormatsForShow: any = ['dd/mm/yy', 'mm/dd/yy', 'yy/mm/dd'];
  yearMonthFormatsForShow: any = ['MM-yyyy', 'MM-yy', 'yy-MM', 'yyyy-MM'];
  timeFormatsForShow: any = ['12-hr', '24-hr'];
  countResetParams: any = ['None', 'MM', 'dd', 'yyyy'];
  separatingOperatorSelected: boolean = false;
  isIncrementValue: boolean;
  entityAttributeSelection: any = [];
  financialMonth: any;
  incIdLeadingZeroes: any;
  countStartParameter: any;
  isReadOnly: boolean = false;
  hideSearchBar: boolean = false;
  readCurrentLocation: boolean = false;
  copyLink: boolean = false;
  attributeMapping: boolean = false;
  attrPath: string = '';
  displayText: string = '';
  isEditable: boolean = false;
  attrValueIndex: number = -1;
  showHelperTextIcon: boolean = false;
  hideDeletion: boolean = false;
  hideOpenInNewTab: boolean = false;
  hidePreview: boolean = false;
  newDateValue: Date;
  isInputDisabled: boolean = true;
  isDefaultCurrency: boolean = false;
  percentageType: string = 'percentage';

  /* ----------For Default value START -------- */
  allDefaultValues: any = [];
  multiDefaultValue: boolean = false;
  excludeUiControlList = [
    'file',
    'image',
    'audio',
    'video',
    'password',
    'encrypttext',
    'currentdate',
    'currentdatetime',
    'qrScanner',
    'qrDecoder',
    'scheduler',
    'signature',
    'scormProgress',
    'webex',
    'location',
    'videoRecording',
    'audioRecording',
    'capturingImage',
    'imageavatar',
    'streamingVideo',
    'list',
    'previewdocument'
  ]; // Exclude UI controls list
  uiControlList = [
    'boolean',
    'date',
    'datetime',
    'dateRange',
    'duration',
    'editor',
    'time',
    'yearMonth',
    'year',
    'basicSlider',
    'inputSlider',
    'rangeSlider',
    'stepSlider',
    'verticalSlider',
    'MMILocation',
    'dateonly'
  ]; // For seperate UI controls list apart from normat text value
  defaultValue: any = ''; // Common default value
  booleanDefaultValue = false; // For Boolean
  dateTypeDefaultValue: any; // For date, datetime, dateRange
  displayDateTypeDefaultValue: any; // For display purpose
  dateTypeUiControls: any = [
    'date',
    'datetime',
    'dateRange',
    'time',
    'yearMonth',
    'year',
    'dateonly',
    'newdate',
    'newdatetime',
    'newtime',
    'timeonly',
    'timer',
  ]; // For date type UI controls
  durationDefaultValue: any = {
    days: null,
    hours: null,
    minutes: null,
  }; // For duration
  durationDisplayValue = ''; // For duration
  EditorConfig: AngularEditorConfig = JSON.parse(JSON.stringify(defaultEditorConfig));
  dateFormatType: string = 'mm/dd/yy';
  timeFormatType: string = '24-hr';
  yearMonthFormatType: string = 'yyyy-MM';
  customDateFormat: string = '';
  uiControlListForDateTimeFormat: any = [
    'date',
    'datetime',
    'newdate',
    'newdatetime',
    'currentDate',
    'currentDateTime',
    'dateRange',
    'dateOnly',
    'timeonly',
    'time',
    'timer',
    'yearMonth'
  ];
  /* ----------For Default value END -------- */

  // locationType: any;
  attrConfiguration: any = {};
  locationArray: any = [];
  selectedChangedriverOptAttr: any;
  SelectedalternativeAttr: any;
  optChangeDrivers: any = ['OPTIONAL', 'NEGATIVE', 'ALTERNATIVE', 'HIDDEN', 'NONE'];
  altAttr: any = [];

  /*-------------- Scheduler UI Control - START --------------------*/
  schedulerDetails = this.formBuilder.group({
    incrementValue: [''],
    day: [''],
    time: [''],
    interval: [''],
    recurrenceExpression: [''],
    startDate: [''],
    endDate: ['', Validators.required],
  });
  isDay: boolean = false;
  isTime: boolean = false;
  intervalOptions: any[];
  timeFormat: any;
  targetValue = 100;
  sliderStepValue: number;
  sliderRangeValue: any;
  selectedType: any;
  ratingColor: any;
  imgUrlWithLabel: string;
  /*-------------- Scheduler UI Control - END --------------------*/

  imgSize: string = 'small1';
  imgAsChip: boolean = false;
  helperTextString: string = '';
  helperTextPostion: string = '';
  helperTextColor: string = '';
  helperTextIconColor: string = '';
  imageUrlwithChip: string;
  helperTextFont: string = '';
  hideTooltip: boolean = false;
  uppercase: boolean;
  lowercase: boolean;
  alphanumeric: boolean;
  strikeOff: boolean;
  translationType: string = 'NONE';
  translationTypeArray: any = ['TRANSLATE', 'TRANSLITERATE', 'NONE'];
  singleSelection: any = [
    'radiobutton',
    'dropdown',
    'date',
    'datetime',
    'chipsingleselection',
    'clickable',
    'daterange',
    'time',
    'year',
  ];
  toggleCUSubmit: boolean = false;
  fileUrlDefaultValueFlag = false;
  UrlDefaultValue = '';
  fileTypeUiControl: any = ['file', 'audio', 'video', 'streamingVideo', 'image', 'qrDecoder', 'imageavatar', 'previewdocument'];
  showHorizontalOptions: boolean = false;
  mmiDestination: string = '';
  mmiDestinationLon: string = '';
  mmiDestinationLat: string = '';
  mmiDefaultLon: string = '';
  mmiDefaultLat: string = '';
  dateTabConfig = {
    maxNumberOfTabs: 3,
    style: 3,
    hideBlanks: false,
    showPredates: false,
    maxNumberOfPreDates: 0,
  };
  showDataAsTab: boolean = false;
  designStyleList: any = [
    { displayValue: '3 Days ', actualValue: 3 },
    { displayValue: '5 Days ', actualValue: 5 },
    { displayValue: '7 Days ', actualValue: 7 },
    { displayValue: '14 Days ', actualValue: 14 },
    { displayValue: '30 Days ', actualValue: 30 },
  ];
  currencyOptions: any[] = [];
  filteredOptions: any[] | any = [];
  searchCurrency: string = '';
  def_ault: number;
  timeLimit: any = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  displayDateDFValue: Date;
  minDateCustomFormatDate: Date = new Date();
  currentDateForCustomDateFormatEx: Date = new Date();
  constructor(
    private entityFacadeService: EntityFacadeService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private attributeEndpointService: AttributeEndpointService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private eventsFacadeService: EventsFacadeService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService,
    private alertService: AlertService,
    private gsiFacade: NodeGsiFacadeService,
    public dialog: MatDialog
  ) {
    this.filteredOptions = currency;
    this.getValidations();
    this.detectLanguageChange();
    this.detectEntityData();
    this.getFetchedNestedEntity();
  }

  ngOnInit(): void {
    /*istanbul ignore next*/
    this.getUiControlDataType();
    if(!this.currentEntity){
    this.fetchCurrentEntity(this.configurationData.data.entity?.id);}
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.type == 'list') {
      this.multiDefaultValue = true;
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.canDownload) {
      this.canDownload = true;
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.nslAttributeMetaData) {
      /*istanbul ignore next*/
      this.selectedChangedriverOptAttr = this.configurationData?.data?.attr?.nslAttributeMetaData?.changeDriverTypes;
      /*istanbul ignore next*/
      this.SelectedalternativeAttr = this.configurationData?.data?.attr?.nslAttributeMetaData?.alternativeAttribute;
      /*istanbul ignore next*/
      if (this.configurationData?.data?.attr?.nslAttributeMetaData?.translationType == 'TRANSLATE') {
        this.translationType = this.labels?.TRANSLATE;
      } else if (this.configurationData?.data?.attr?.nslAttributeMetaData?.translationType == 'TRANSLITERATE') {
        this.translationType = this.labels?.TRANSLITERATE;
      } else {
        this.translationType = this.labels?.NONE;
      }
    }
    /*istanbul ignore next*/
    this.altAttr = this.configurationData?.data?.entity?.nslAttributes;
    this.entityFacadeService.getUIValidations();
    /* istanbul ignore next*/
    const currentCUReferencedChangeUnit = this.configurationData?.data?.currentcu?.data?.dsdRefId
      ? this.configurationData?.data?.currentcu?.data?.dsdRefId
      : this.configurationData?.data?.currentcu?.referencedChangeUnit;
    /*istanbul ignore next*/
    if (currentCUReferencedChangeUnit) {
      /*istanbul ignore next*/
      this.changeUnit = this.tempGsiObj?.solutionLogic?.find(
        (x: any) => x?.referencedChangeUnit == currentCUReferencedChangeUnit
      );
    }
    /*istanbul ignore next*/
    let foundAttr = this.configurationData?.data?.attr?.configuration;
    /*istanbul ignore next*/
    this.attrConfiguration = IsJsonString(foundAttr) ? JSON.parse(foundAttr) : [];
    /*istanbul ignore next*/
    this.isReadOnly = this.attrConfiguration?.isReadOnly ? true : false;
    this.showDataAsTab = this.attrConfiguration?.showDataAsTab ? true : false;
    this.dateTabConfig = this.attrConfiguration?.dateTabConfig
      ? this.attrConfiguration?.dateTabConfig
      : this.dateTabConfig;
    /*istanbul ignore next*/
    this.hideSearchBar = this.attrConfiguration?.hideSearchBar ? true : false;
    this.readCurrentLocation = this.attrConfiguration?.readCurrentLocation ? true : false;
    /*istanbul ignore next*/
    this.copyLink = this.attrConfiguration?.copyLink ? true : false;
    /*istanbul ignore next*/
    this.attributeMapping = this.attrConfiguration?.attributeMapping ? true : false;
    this.attrPath = this.attrConfiguration?.attrPath;
    this.displayText = this.attrConfiguration?.displayText;
    /*istanbul ignore next*/
    this.hideDeletion = this.attrConfiguration?.hideDeletion;
    /*istanbul ignore next*/
    this.hidePreview = this.attrConfiguration?.hidePreview;
    /*istanbul ignore next*/
    this.hideOpenInNewTab = this.attrConfiguration?.hideOpenInNewTab;
    /*istanbul ignore next*/
    this.hideTooltip = this.attrConfiguration?.hideTooltip;
    /*istanbul ignore next*/
    this.showHorizontalOptions = this.attrConfiguration?.showHorizontalOptions;
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation') {
      /*istanbul ignore next*/
      this.mmiDestination = this.attrConfiguration?.mmiDestination ? this.attrConfiguration?.mmiDestination : '';
      /*istanbul ignore next*/
      if (this.mmiDestination && this.mmiDestination !== '') {
        /*istanbul ignore next*/
        this.mmiDestinationLat = JSON.parse(this.mmiDestination)?.latitude;
        /*istanbul ignore next*/
        this.mmiDestinationLon = JSON.parse(this.mmiDestination)?.longitude;
      }
    }

    /*istanbul ignore next*/
    if (
      this.configurationData?.data?.attr?.configuration &&
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location'
    ) {
      /*istanbul ignore next*/
      let foundAttr = this.configurationData?.data?.attr?.configuration;
      /*istanbul ignore next*/
      this.attrConfiguration = IsJsonString(foundAttr) ? JSON.parse(foundAttr) : [];
      /*istanbul ignore next*/
      this.attrDetails.patchValue({
        locationType: this.attrConfiguration?.locationType,
        readCurrentLocation: this.readCurrentLocation,
      });
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.nslAttributeProperties) {
      /*istanbul ignore next*/
      this.configurationData.data.attr.nslAttributeProperties.isTraceable =
        this.configurationData.data.attr.nslAttributeProperties.isTraceable == 'true' ||
        this.configurationData.data.attr.nslAttributeProperties.isTraceable == true;
    } else {
      this.configurationData.data.attr['nslAttributeProperties'] = { isTraceable: false };
    }

    /*istanbul ignore next*/
    this.attrDetails?.patchValue({
      helpertext: this.attrConfiguration?.helperTextString,
      helperTextPosition: this.attrConfiguration?.helperTextPosition,
      helperTextFont: this.attrConfiguration?.helperTextFont,
      helperTextColor: this.attrConfiguration?.helperTextColor,
      helperTextIconColor: this.attrConfiguration.helperTextIconColor,
      showHelperTextIcon: this.attrConfiguration?.showHelperTextIcon,
    });
    /*istanbul ignore next*/
    this.attrDetails?.patchValue({
      imgUrlWithLabel: this.attrConfiguration?.imgUrlWithLabel,
    });
    /*istanbul ignore next*/
    this.attrDetails?.patchValue({
      imageUrlwithChip: this.attrConfiguration?.imageUrlwithChip,
    });
    /*istanbul ignore next*/
    this.attrDetails.patchValue({
      showPopup: this.attrConfiguration?.showPopup,
    });
    /* istanbul ignore next */
    if (this.fileTypeUiControl?.includes(this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement)) {
      /*istanbul ignore next*/
      let defaultValue = this.configurationData?.data?.attr?.defaultValue;
      /* istanbul ignore next */
      this.editFileUrl = IsJsonString(defaultValue) ? JSON.parse(defaultValue) : null;
      /* istanbul ignore next */
      try {
        /*istanbul ignore next*/
        if (JSON.parse(defaultValue)) {
          this.fileUrl = JSON.parse(defaultValue);
        }
      } catch {
        /*istanbul ignore next*/
        if (defaultValue != null || defaultValue != undefined) {
          this.fileUrl[0] = defaultValue;
          this.fileUrlDefaultValueFlag = true;
        }
      }
      /*istanbul ignore next*/
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'image') {
        /*istanbul ignore next*/
        this.imgAsChip = this.attrConfiguration?.imgConfig?.imgAsChip;
        /*istanbul ignore next*/
        this.imgSize = this.attrConfiguration?.imgConfig?.imgSize;
      }
    }

    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'labelwithimage') {
      /*istanbul ignore next*/
      let attrConfig = JSON.parse(this.configurationData?.data?.attr?.configuration);
      /*istanbul ignore next*/
      let defaultValue = attrConfig?.imgUrlWithLabel;
      /* istanbul ignore next */
      this.editFileUrl = IsJsonString(defaultValue) ? JSON.parse(defaultValue) : null;
    }

    /* istanbul ignore next */
    if (this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues?.length > 0) {
      /*istanbul ignore next*/
      this.sourceValues = this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues;
    }
    this.editAttributes();
    /* istanbul ignore next */
    let autoIdFormat = this.configurationData?.data?.attr?.attributeType?.properties?.format;
    /* istanbul ignore next */
    if (autoIdFormat && autoIdFormat !== '') {
      this.autoIdForm.controls.formatFields['controls'] = [];
      /*istanbul ignore next*/
      let replacedFormat = autoIdFormat?.slice(1, autoIdFormat?.length - 1);
      /*istanbul ignore next*/
      let splitFormat = replacedFormat?.split('}{');
      let formFields = [];
      /* istanbul ignore next */
      splitFormat?.forEach((field: any, fieldIndex: number) => {
        this.formatFields = this.autoIdForm.get('formatFields') as FormArray;
        /* istanbul ignore next */
        if (field?.includes('${')) {
          this.formatFields?.push(this.createFormat('attribute'));
          this.autoIdForm.value.formatFields[fieldIndex].type = 'attribute';
          /*istanbul ignore next*/
          let attributeSplit = field?.split('}');
          /*istanbul ignore next*/
          this.autoIdForm.value.formatFields[fieldIndex].entAttributeName = attributeSplit?.[0]?.replace('${', '');
          /* istanbul ignore next */
          if (attributeSplit[1]) {
            /*istanbul ignore next*/
            let stringTypeSplit = attributeSplit[1]?.split('.');
            /*istanbul ignore next*/
            this.autoIdForm.value.formatFields[fieldIndex].entAttributeString = stringTypeSplit[1];
            /*istanbul ignore next*/
            this.autoIdForm.value.formatFields[fieldIndex].entAttributeStringValue = stringTypeSplit[2];
          }
        } else if (field[0] === '$') {
          /*istanbul ignore next*/
          if (
            field === '$+' ||
            field === '$-' ||
            field === '$*' ||
            field === '$%' ||
            field === '$/' ||
            field === '$(' ||
            field === '$)' ||
            field === '$_'
          ) {
            /*istanbul ignore next*/
            this.formatFields?.push(this.createFormat('separator'));
            this.separatingOperatorSelected = true;
            this.selectorSeparated = field.replace('$', '');
            this.autoIdForm.value.formatFields[fieldIndex].type = 'separator';
          } else if (field === '$INC_ID') {
            /*istanbul ignore next*/
            this.formatFields?.push(this.createFormat('increment'));
            this.autoIdForm.value.formatFields[fieldIndex].type = 'increment';
          } else {
            /*istanbul ignore next*/
            this.formatFields?.push(this.createFormat('date'));
            this.autoIdForm.value.formatFields[fieldIndex].dateFormat = field.replace('$', '');
            this.autoIdForm.value.formatFields[fieldIndex].type = 'date';
          }
        } else {
          /*istanbul ignore next*/
          this.formatFields?.push(this.createFormat('format'));
          this.autoIdForm.value.formatFields[fieldIndex].format = field;
          this.autoIdForm.value.formatFields[fieldIndex].type = 'format';
        }
      });
      /*istanbul ignore next*/
      this.autoIdForm?.patchValue({
        formatFields: this.autoIdForm.value.formatFields,
        maxValue: this.configurationData.data.attr.attributeType.properties.incIdMaxValue,
        countStartParameter: this.configurationData.data.attr.attributeType.properties.countStartParameter,
        incIdLeadingZeroes: this.configurationData.data.attr.attributeType.properties.incIdLeadingZeroes,
        countReset: this.configurationData.data.attr.attributeType.properties.countResetParameter,
      });
      this.checkIncrementIDPresence();
    }
    /* istanbul ignore next */
    if (this.singleEntity) {
      this.flagsave = false;
    }

    /* --------- For Scheduler UI control - START ------------ */
    /* istanbul ignore next */
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'scheduler') {
      /* istanbul ignore next */
      let attrData = this.configurationData?.data?.attr?.attributeType;
      /* istanbul ignore next */
      let interval = attrData?.properties?.interval;
      /* istanbul ignore next */
      if (interval === 'MONTH' || interval === 'WEEK') {
        this.isDay = true;
        this.isTime = true;
      } else if (interval === 'DAY') {
        this.isDay = false;
        this.isTime = true;
      } else {
        this.isDay = false;
        this.isTime = false;
      }

      /* conversion of time */
      /* istanbul ignore next */
      if (attrData?.properties?.time) {
        this.timeFormat = new Date('2/16/2021, ' + attrData.properties.time + ' UTC');
        attrData.properties.time = new Date('2/16/2021, ' + attrData.properties.time + ' UTC');
      }

      /* patching the values for scheduler ui-control on edit */
      /* istanbul ignore next */
      this.schedulerDetails?.patchValue(getSchedulerDetails(attrData));
    }
    /*istanbul ignore next*/
    if (
      this.configurationData?.data?.attr?.attributeType &&
      !this.attributeTypes?.includes(this.configurationData?.data?.attr?.attributeType)
    ) {
      this.attributeTypes?.push(this.configurationData.data.attr?.attributeType);
    }
    /* --------- For Scheduler UI control - START ------------ */
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.helpertext) {
      this.helperTextString = this.attrConfiguration.helpertext;
    }
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.helperTextPosition) {
      this.helperTextPostion = this.attrConfiguration.helperTextPosition;
    }
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.helperTextColor) {
      this.helperTextColor = this.attrConfiguration.helperTextColor;
    }
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.helperTextIconColor) {
      this.helperTextIconColor = this.attrConfiguration.helperTextIconColor;
    }
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.helperTextFont) {
      this.helperTextFont = this.attrConfiguration.helperTextFont;
    }
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.showHelperTextIcon) {
      this.showHelperTextIcon = this.attrConfiguration?.showHelperTextIcon;
    }
    /*istanbul ignore next*/
    if (this.attrConfiguration && this.attrConfiguration?.showPopup) {
      this.showPopup = this.attrConfiguration?.showPopup;
    }
    this.minDateCustomFormatDate = new Date();
  }

  // to check of the entity is version is 1.0 and draft
  getUiControlDataType() {
    let val = this.configurationData;
    /* istanbul ignore next */
    let attr = val?.data?.attr;
    /* istanbul ignore next */
    let entity = val?.data?.entity;
    /* istanbul ignore next */
    if ((entity?.status === 'DRAFT' && entity?.version === '1.0') || !attr?.id) {
      this.getAttributeTypes('', true);
    } else {
      this.checkAttributeUiControl(attr);
    }
  }
  onEventLog(event: string, data: any): void {
    /* istanbul ignore next */
    if (event == 'colorPickerClose' && data) {
      this.ratingColor = data;
    }
  }
  checkAttributeUiControl(attr: any) {
    /* istanbul ignore next */
    if (attr?.attributeType?.uiElement?.esDataType || attr?.attributeType?.uiElement?.dataType) {
      /* istanbul ignore next */
      this.getAttributeTypes(attr?.attributeType?.uiElement?.esDataType);
    }
  }
  /*istanbul ignore next*/
  createFormat(type: string): FormGroup {
    /*istanbul ignore next*/
    return this.formBuilder?.group({
      type: type,
      format: '',
      entAttributeName: '',
      dateFormat: '',
      entAttributeString: '',
      entAttributeStringValue: '',
    });
  }

  typeChange(selectType: any, ind: number) {
    this.selectedValidatorsList[ind].selectType = selectType;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      /*istanbul ignore next*/
      this.locationArray = [
        this.labels?.Current_Location,
        this.labels?.Location_Markers,
        this.labels?.Location_Tracker,
      ];
      /*istanbul ignore next*/
      this.intervalOptions = [
        this.labels?.SECOND,
        this.labels?.MINUTE,
        this.labels?.HOUR,
        this.labels?.DAY,
        this.labels?.MONTH,
        this.labels?.WEEK,
      ];
    });
  }
  /* autoIdentifier code start */
  checkIncrementIDPresence() {
    this.isIncrementValue = false;
    /* istanbul ignore next */
    this.autoIdForm?.controls?.formatFields['controls']?.forEach((field: any) => {
      if (field.value.type === 'increment') {
        this.isIncrementValue = true;
      }
    });
  }
  addField(fieldType: string) {
    /* istanbul ignore next */
    if (fieldType === 'attribute' && this.configurationData?.data?.entity?.nslAttributes?.length > 0) {
      this.entityAttributeSelection = [];
      /* istanbul ignore next */
      this.configurationData?.data?.entity?.nslAttributes?.forEach((attr: any) => {
        if (attr?.name != this.configurationData?.data?.attr?.name) {
          /*istanbul ignore next*/
          this.entityAttributeSelection?.push(attr);
        }
      });
    }
    /* istanbul ignore next */
    this.formatFields = this.autoIdForm?.get('formatFields') as FormArray;
    /* istanbul ignore next */
    this.formatFields?.push(this.createFormat(fieldType));
    this.checkIncrementIDPresence();
  }
  removeField(fieldIndex: number) {
    /* istanbul ignore next */
    this.autoIdForm?.controls?.formatFields['controls']?.splice(fieldIndex, 1);
    this.checkIncrementIDPresence();
  }

  selectSeparator(res: string) {
    this.selectorSeparated = res;
    this.separatingOperatorSelected = true;
  }

  removeAttributeSelection(field: any) {
    field.controls.entAttributeName.value = '';
  }

  checkYearFormat() {
    this.getFinancialMonth = false;
    /* istanbul ignore next */
    this.autoIdForm.value.formatFields?.forEach((field: any) => {
      /* istanbul ignore next */
      if (field?.type === 'date' && (field.dateFormat === 'yy-yy' || field.dateFormat === 'yyyy-yyyy')) {
        this.getFinancialMonth = true;
      }
    });
  }

  /**
   * re-arrange Auto id fields
   * @param event
   */
  /*istanbul ignore next*/
  drop(event: CdkDragDrop<string[]>) {
    wrapperDrop(event);
  }

  onChangeOption(event: any, index: any) {
    /*istanbul ignore next*/
    if (this.selectedChangedriverOptAttr !== 'none') {
      this.configurationData['nslAttributeMetaData'] = { changeDriverTypes: this.selectedChangedriverOptAttr };
    }
  }
  /* optional change drivers attribute level */
  onChangeOptionAttr(event: any, index: any) {
    /* istanbul ignore if */
    const selectedItems = updateBetSelectedItems(event, this.selectedChangedriverOptAttr);
    this.selectedChangedriverOptAttr = selectedItems;
    this.configurationData.data.attr['nslAttributeMetaData'] = { changeDriverTypes: selectedItems };
    if (selectedItems?.includes('ALTERNATIVE')) {
      this.configurationData.data.attr.nslAttributeMetaData = {
        changeDriverTypes: selectedItems,
        alternativeAttribute: this.SelectedalternativeAttr,
      };
    }
  }
  /*istanbul ignore next*/
  ChangeTranslationType(value: string) {
    this.translationType = value;
  }
  selectedAttribute(attr: any, field: any) {
    field.controls.entAttributeName.value = attr;
    field.value.entAttributeName = attr;
  }
  /* istanbul ignore next */
  inclLeadingZeroesGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.incIdLeadingZeroes = this.autoIdForm?.value?.incIdLeadingZeroes;
  }
  /* istanbul ignore next */
  maxValueGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.incIdMaxValue = this.autoIdForm?.value?.maxValue;
  }
  /* istanbul ignore next */
  financialMonthGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.financialMonth = this.autoIdForm?.value?.financialMonth;
  }
  countResetParameterGetter() {
    /*istanbul ignore next*/
    this.configurationData.data.attr.attributeType.properties.countResetParameter = this.autoIdForm?.value?.countReset;
  }
  countStartParameterGetter() {
    /* istanbul ignore next */
    this.configurationData.data.attr.attributeType.properties.countStartParameter = this.autoIdForm?.value?.countStartParameter;
  }
  /* autoIdentifier code end*/

  addDataToEntity() {
    /*istanbul ignore next*/
    if (this.attrDetails?.valid) {
      if (!this.configurationData.data.attr.attributeType.properties) {
        this.configurationData.data.attr.attributeType.properties = {};
      }
      // default value
      if (this.canDownload) {
        this.configurationData.data.attr.canDownload = true;
      } else {
        this.configurationData.data.attr.canDownload = false;
      }
      if (this.allDefaultValues?.length > 0) {
        /* istanbul ignore next */
        if (this.configurationData.data.attr.attributeType.type == 'list') {
          this.attrDetails.patchValue({
            defaultValue: JSON.stringify(this.allDefaultValues),
          });
        } else {
          this.attrDetails.patchValue({
            defaultValue: this.allDefaultValues[0],
          });
        }
      }
      /* istanbul ignore next */
      if (this.attrDetails?.value?.defaultValue || this.attrDetails?.value?.defaultValue == '') {
        this.configurationData.data.attr.defaultValue = this.attrDetails.value.defaultValue;
      }
      if (this.configurationData?.data?.attr?.defaultValue === '') {
        delete this.configurationData.data.attr.defaultValue;
      }
      /*istanbul ignore else*/
      // For multiple files
      if (this.fileUrl && this.fileUrl?.length > 1) {
        this.configurationData.data.attr.defaultValue = JSON.stringify(this.fileUrl);
      }

      // For single file
      else if (this.fileUrl && this.fileUrl.length === 1) {
        this.configurationData.data.attr.defaultValue = this.fileUrl[0];
      }
      /*istanbul ignore else*/
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'text') {
        if (this.uppercase === true) {
          this.configurationData.data.attr.attributeType.properties.uppercase = true;
        }
        if (this.lowercase === true) {
          this.configurationData.data.attr.attributeType.properties.lowercase = true;
        }
        if (this.alphanumeric === true) {
          this.configurationData.data.attr.attributeType.properties.alphanumeric = true;
        }

        this.uppercase = false;
        this.lowercase = false;
        this.alphanumeric = false;
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'html') {
        if (this.showPopup) {
          this.configurationData.data.attr.attributeType.properties.showPopup = true;
        } else {
          this.configurationData.data.attr.attributeType.properties.viewLink = true;
        }
        this.showPopup = false;
        this.viewLink = false;
      }
      /*istanbul ignore next*/
      if (this.configurationData.data.attr.attributeType?.uiElement?.uiElement === 'currency') {
        this.configurationData.data.attr.attributeType.properties.currencyType = this.attrDetails.value.unitType;
      }
      /*istanbul ignore next*/
      if (this.configurationData.data.attr.attributeType?.uiElement?.uiElement === 'metric') {
        this.configurationData.data.attr.attributeType.properties.unit = this.attrDetails.value.unitType;
      }
      if (this.configurationData.data.attr.attributeType?.uiElement?.dataType == 'autoIdentifier') {
        this.configurationData.data.attr.attributeType.properties = {};
        let keyIdentifier = '';
        /* istanbul ignore next*/
        this.autoIdForm?.controls?.formatFields['controls']?.forEach((fieldObj: any) => {
          keyIdentifier += keyIdentifierGetter(fieldObj, this.selectorSeparated);
        });
        this.configurationData.data.attr.attributeType.properties.format = keyIdentifier;
        this.configurationData.data.attr.attributeType.properties.separator = this.selectorSeparated;
        if (this.isIncrementValue) {
          /* istanbul ignore next */
          if (this.autoIdForm?.value?.maxValue) {
            this.maxValueGetter();
          }
          /* istanbul ignore next */
          if (this.autoIdForm?.value?.countReset && this.autoIdForm?.value?.countReset !== 'None') {
            this.countResetParameterGetter();
          }
          /* istanbul ignore next */
          if (this.autoIdForm?.value?.countStartParameter) {
            this.countStartParameterGetter();
          }
          /* istanbul ignore next */
          if (this.autoIdForm?.value?.incIdLeadingZeroes) {
            this.inclLeadingZeroesGetter();
          }
          if (this.getFinancialMonth) {
            /* istanbul ignore next */
            this.financialMonthGetter();
          }
        }
      }
      // slider value update
      /* istanbul ignore next */
      if (
        this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'basicSlider' ||
        this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'inputSlider' ||
        this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
        this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'stepSlider' ||
        this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'verticalSlider'
      ) {
        this.attrConfiguration = { ...this.attrConfiguration, targetValue: this.targetValue };
        if (this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'stepSlider') {
          this.attrConfiguration = { ...this.attrConfiguration, sliderStepValue: this.sliderStepValue };
        } else if (this.configurationData.data.attr.attributeType?.uiElement?.uiElement == 'rangeSlider') {
          this.attrConfiguration = { ...this.attrConfiguration, sliderRangeValue: this.sliderRangeValue };
        }
      }
      //changing the time format
      /* istanbul ignore next */
      if (this.configurationData.data.attr.attributeType?.uiElement?.dataType === 'scheduler') {
        if (!this.configurationData.data.attr.attributeType.properties) {
          this.configurationData.data.attr.attributeType.properties = {};
        }
        if (this.schedulerDetails?.value?.time) {
          let time = new Date(this.schedulerDetails.value.time);
          this.schedulerDetails.value.time =
            ('0' + time.getUTCHours())?.slice(-2) +
            ':' +
            ('0' + time.getUTCMinutes())?.slice(-2) +
            ':' +
            ('0' + time.getUTCSeconds())?.slice(-2);
        }
        if (this.schedulerDetails?.value?.recurrenceExpression == null) {
          this.schedulerDetails.value.recurrenceExpression = '';
        }
        /* istanbul ignore next */
        if (this.schedulerDetails?.value?.startDate && typeof this.schedulerDetails?.value?.startDate === 'object') {
          this.schedulerDetails.value.startDate = this.schedulerDetails?.value?.startDate.toISOString();
        }
        /* istanbul ignore next */
        if (this.schedulerDetails?.value?.endDate && typeof this.schedulerDetails?.value?.endDate === 'object') {
          this.schedulerDetails.value.endDate = this.schedulerDetails?.value?.endDate.toISOString();
        }
        this.configurationData.data.attr.attributeType.properties = this.schedulerDetails.value;
      }

      if (this.isReadOnly) {
        this.attrConfiguration = { ...this.attrConfiguration, isReadOnly: this.isReadOnly };
      } else if (!this.isReadOnly && this.attrConfiguration?.isReadOnly) {
        this.attrConfiguration.isReadOnly = undefined;
      }
      if (
        this.attrDetails?.value?.attributeUiElement === 'dropdown' ||
        this.attrDetails?.value?.attributeUiElement === 'multiselectdropdown'
      ) {
        this.attrConfiguration = { ...this.attrConfiguration, hideSearchBar: this.hideSearchBar };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails?.value?.attributeUiElement === 'date' && this.showDataAsTab) {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          showDataAsTab: this.showDataAsTab,
          dateTabConfig: this.dateTabConfig,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }

      if (this.attributeTypeArr.includes(this.attrDetails?.value?.attributeType)) {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          dateTimeFormat: {
            format: this.customDateFormat ? this.customDateFormat : this.dateFormatType,
            timeFormat: this.customDateFormat ? '' : this.timeFormatType,
          },
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }

      if (this.attrDetails?.value?.attributeUiElement === 'hyperLink') {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          copyLink: this.copyLink,
          displayText: this.displayText,
          attributeMapping: this.attributeMapping,
          attrPath: this.attrPath,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }

      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation') {
        this.attrConfiguration = { ...this.attrConfiguration, mmiDestination: this.mmiDestination };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }

      if (
        this.attrDetails?.value?.attributeUiElement === 'label' ||
        this.attrDetails?.value?.attributeUiElement === 'chipsingleselection' ||
        this.attrDetails?.value?.attributeUiElement === 'chipmultipleselection' ||
        this.attrDetails?.value?.attributeUiElement === 'multiselectdropdown'
      ) {
        this.attrConfiguration = { ...this.attrConfiguration, hideTooltip: this.hideTooltip };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'file') {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          hidePreview: this.hidePreview,
          hideDeletion: this.hideDeletion,
          hideOpenInNewTab: this.hideOpenInNewTab,
        };
      }

      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'radioButton') {
        this.attrConfiguration = { ...this.attrConfiguration, showHorizontalOptions: this.showHorizontalOptions };
      }

      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'percentage') {
        this.attrConfiguration = { ...this.attrConfiguration, percentageType: this.percentageType };
      }
      if (this.attrDetails.value.isMultiValue || this.multiDefaultValue) {
        this.configurationData.data.attr.attributeType.type = 'list';
        this.configurationData.data.attr.attributeType.nestedNslDataTypeProperties = {
          item_type: { type: this.dataType },
        };
      } else {
        this.configurationData.data.attr.attributeType.type = this.configurationData.data.attr.attributeType?.uiElement?.dataType;
      }
      if (!this.configurationData.data.attr.attributeType?.extendedProperties)
        this.configurationData.data.attr.attributeType.extendedProperties = { sourceValues: [] };
      /* istanbul ignore next */
      this.configurationData.data.attr.attributeType.extendedProperties.sourceValues = [...this.sourceValues];
      /* istanbul ignore next */
      if (this.currentEntity) {
        /* istanbul ignore next */
        this.currentEntity.uniqueConstraints = this.currentEntity?.uniqueConstraints?.map((key: any) => {
          key = key.map((attr: any) => {
            let attrObj = {
              name: attr.name,
              id: attr.id,
              dsdId: attr.dsdId,
              attributeType: attr.attributeType,
              tfId: attr.tfId,
            };
            return attrObj;
          });
          return key;
        });
      }
    }
  }
  addDefaultValuetoAttibute() {
    /* istanbul ignore next */
    if (this.sourceValues?.length >= 0) {
      this.addEntityBtn = true;
    }
    /* istanbul ignore next */
    if (this.attrDetails?.value?.defaultOption?.length > 0 || this.attrDetails?.value?.defaultOption?.trim() !== '') {
      if (this.attrDetails?.value?.attributeUiElement == 'currency') {
        this.sourceValues = [];
        this.currencyOptions = this.attrDetails?.value?.defaultOption;
        /*istanbul ignore next*/
        this.attrDetails?.value?.defaultOption.forEach((option: any) => {
          this.sourceValues?.push({
            TYPE: 'OptionValue',
            DATA: {
              actualValue: option?.name,
              displayValue: option?.symbol_native,
            },
          });
        });
      } else {
        let index = this.sourceValues.findIndex(
          (ele: any) => ele?.DATA?.actualValue.toLowerCase() === this.attrDetails?.value?.defaultOption.toLowerCase()
        );
        if(index == -1){
          this.sourceValues?.push({
            TYPE: 'OptionValue',
            DATA: {
              actualValue: this.attrDetails.value.defaultOption,
              displayValue: this.attrDetails.value.defaultOption,
            },
          });
        }
        this.attrDetails.patchValue({
          defaultOption: '',
        });
      }
    }
  }

  getHideLabelMutiAttr() {
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.configuration) {
      /*istanbul ignore next*/
      return JSON.parse(this.configurationData.data.attr?.configuration)?.hideLabelMultiAttr ? true : false;
    }
    /*istanbul ignore next*/
    return false;
  }

  getHideMultiValueIcon() {
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.configuration) {
      /*istanbul ignore next*/
      return JSON.parse(this.configurationData.data.attr?.configuration)?.hideMultiValueIcon ? true : false;
    }
    /*istanbul ignore next*/
    return false;
  }

  setLimit(val: any, action: any) {
    /* istanbul ignore next */
    if ((action == 'minutes' || action == 'seconds') && val.target.value > 59) {
      this.timeLimit[action] = 59;
    } else if ((action == 'minutes' || action == 'seconds') && val.target.value < 0) {
      this.timeLimit[action] = 0;
    }
  }


  editAttributes() {
    // For default value
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr) {
      this.attrDetails.patchValue({
        defaultValue: '',
        attributeUiElement: this.configurationData.data.attr?.attributeType?.uiElement?.uiElement,
        attributeType: this.configurationData.data.attr?.attributeType?.uiElement?.dataType,
        hideLabelMultiAttr: this.getHideLabelMutiAttr(),
        hideMultiValueIcon: this.getHideMultiValueIcon(),
        isMultiValue: this.configurationData.data.attr?.attributeType?.type == 'list',
      });

      if (
        this.configurationData?.data?.attr?.configuration &&
        IsJsonString(this.configurationData?.data?.attr?.configuration) &&
        JSON.parse(this.configurationData?.data?.attr?.configuration)?.dateTimeFormat
      ) {
        let dateTimeFormat = JSON.parse(this.configurationData?.data?.attr?.configuration)?.dateTimeFormat;
        this.timeFormatType = dateTimeFormat?.timeFormat ? dateTimeFormat?.timeFormat : '24-hr';
        this.dateFormatType = dateTimeFormat?.format ? dateTimeFormat?.format : 'MM/dd/yyyy';
      }

      if (
        this.configurationData?.data?.attr?.attributeType?.type === 'currency' &&
        this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues?.length > 0
      ) {
        /*istanbul ignore next*/
        this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues.forEach((op: any) => {
          let ind = this.filteredOptions?.findIndex((x: any) => x.name == op?.DATA?.actualValue);
          if (ind !== -1) {
            /*istanbul ignore next*/
            this.currencyOptions?.push(this.filteredOptions?.[ind]);
          }
        });

        this.attrDetails.patchValue({
          defaultOption: this.currencyOptions,
        });
      }
      if (
        this.configurationData?.data?.attr?.attributeType?.properties?.currencyType ||
        this.configurationData?.data?.attr?.attributeType?.properties?.unit
      ) {
        this.attrDetails.patchValue({
          unitType: this.configurationData?.data?.attr?.attributeType?.properties?.currencyType
            ? this.configurationData?.data?.attr?.attributeType?.properties?.currencyType
            : this.configurationData?.data?.attr?.attributeType?.properties?.unit,
        });
      }

      /*istanbul ignore next*/
      if (this.configurationData?.data?.attr?.attributeType?.properties?.alphanumeric == 'true') {
        this.alphanumeric = true;
      }
      /*istanbul ignore next*/
      if (this.configurationData?.data?.attr?.attributeType?.properties?.lowercase == 'true') {
        this.lowercase = true;
      }
      /*istanbul ignore next*/
      if (this.configurationData?.data?.attr?.attributeType?.properties?.uppercase == 'true') {
        this.uppercase = true;
      }
      if (this.configurationData?.data?.attr?.attributeType?.properties?.showPopup == 'true') {
        this.showPopup = true;
      } else {
        this.viewLink = true;
      }
      this.allDefaultValues = [];
      if (this.configurationData?.data?.attr?.defaultValue) {
        let attrDefaultValue = this.configurationData.data.attr.defaultValue;
        if (
          (this.configurationData.data.attr?.attributeType?.uiElement?.dataType === 'list' ||
            attrDefaultValue[0] == '[') &&
          this.configurationData.data.attr?.attributeType?.uiElement?.uiElement !== 'dateRange'
        ) {
          /*istanbul ignore next*/
          this.allDefaultValues = IsJsonString(attrDefaultValue) ? JSON.parse(attrDefaultValue) : [];
          this.multiDefaultValue = true;
        } else {
          this.allDefaultValues = [attrDefaultValue];
        }
      }

      /* istanbul ignore next */
      if (
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'boolean' &&
        this.allDefaultValues[0] === 'true'
      ) {
        this.booleanDefaultValue = true;
      }
      if (this.configurationData?.data?.attr?.configuration && this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'rating') {
        this.ratingColor = JSON.parse(this.configurationData?.data?.attr?.configuration)?.color;
      }
      if (this.configurationData?.data?.attr?.configuration && this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'html') {
        this.showPopup = JSON.parse(this.configurationData?.data?.attr?.configuration)?.showPopup;
      }

      /* istanbul ignore next */
      if (
        (this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'videoRecording' ||
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'audioRecording') &&
        this.configurationData?.data?.attr?.configuration
      ) {
        let timeLimitConfiguration = JSON.parse(this.configurationData?.data?.attr?.configuration);
        this.timeLimit = {
          hours: timeLimitConfiguration?.hours || this.timeLimit.hours,
          minutes: timeLimitConfiguration?.minutes || this.timeLimit.minutes,
          seconds: timeLimitConfiguration?.seconds || this.timeLimit.seconds,
        }
      }

      if (this.configurationData?.data?.attr?.configuration &&
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'basicSlider' ||
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'inputSlider' ||
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'rangeSlider' ||
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'stepSlider' ||
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement == 'verticalSlider'
      ) {
        let sliderAttrConfiguration = JSON.parse(this.configurationData?.data?.attr?.configuration);
        this.sliderStepValue = sliderAttrConfiguration?.sliderStepValue;
        this.sliderRangeValue = sliderAttrConfiguration?.sliderRangeValue;
        this.targetValue = sliderAttrConfiguration?.targetValue;
      }

      /* istanbul ignore next */
      if (
        this.configurationData?.data?.attr?.configuration &&
        IsJsonString(this.configurationData?.data?.attr?.configuration) &&
        JSON.parse(this.configurationData?.data?.attr?.configuration)?.dateTimeFormat
      ) {
        /* istanbul ignore next */
        let dateTimeFormat = JSON.parse(this.configurationData?.data?.attr?.configuration)?.dateTimeFormat;

        /* istanbul ignore next */
        if(
          dateTimeFormat?.format &&
          !this.dateFormatsForShow?.includes(dateTimeFormat?.format) &&
          this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'yearMonth'
        ){
          this.customDateFormat = dateTimeFormat.format;
          this.dateFormatType = 'mm/dd/yy';
          this.timeFormatType = '24-hr';
        }
        else if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement !== 'yearMonth') {
          this.customDateFormat = "";
          /* istanbul ignore next */
          this.dateFormatType = dateTimeFormat?.format ? dateTimeFormat?.format : 'MM/dd/yyyy';
          /* istanbul ignore next */
          this.timeFormatType = dateTimeFormat?.timeFormat ? dateTimeFormat?.timeFormat : '24-hr';
        }

        /* istanbul ignore next */
        if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'yearMonth') {
          this.customDateFormat = "";
          /* istanbul ignore next */
          this.yearMonthFormatType =
            dateTimeFormat?.format && this.yearMonthFormatsForShow?.includes(dateTimeFormat?.format) ? dateTimeFormat?.format : 'yyyy-MM';
        }
      }
      else if (
        this.configurationData?.data?.attr?.attributeType?.properties &&
        this.uiControlListForDateTimeFormat?.includes(this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement)
      ) {
        /* istanbul ignore next */
        let attrType = this.configurationData?.data?.attr?.attributeType?.properties;

        /* istanbul ignore next */
        if (attrType?.format && !this.dateFormatsForShow?.includes(attrType?.format)) {
          this.customDateFormat = attrType.format;
          this.dateFormatType = 'mm/dd/yy';
          this.timeFormatType = '24-hr';
        } else {
          this.customDateFormat = "";
          /* istanbul ignore next */
          this.dateFormatType = attrType?.format ? attrType?.format : 'mm/dd/yy';
          /* istanbul ignore next */
          this.timeFormatType = attrType?.timeFormat ? attrType?.timeFormat : '24-hr';
        }

        /* istanbul ignore next */
        if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'yearMonth') {
          /* istanbul ignore next */
          this.yearMonthFormatType =
            attrType?.format && this.yearMonthFormatsForShow?.includes(attrType?.format) ? attrType.format : 'yyyy-MM';
        }
      }
    }

    /*istanbul ignore next*/
    this.constantChangeDriverObj = {
      slotId: this.configurationData.data?.entity?.id,
      slotIndex: this.configurationData?.data?.entityIndex,
      entity: this.configurationData.data.entity,
      attributeId: this.configurationData.data?.attr?.id,
      layerType: this.configurationData.data.entity?.layerType,
    };
    // let layerShortCut = this.getLayerId(this.constantChangeDriverObj);
    // // this.constantChangeDrivers = [];
    // // DATA[i].constantChangeDriverEntity = false;
    // /*istanbul ignore next*/
    // if (this.changeUnit?.slotItemProperties && this.changeUnit?.slotItemProperties[layerShortCut]) {
    //   this.constantChangeDrivers = this.changeUnit.slotItemProperties[
    //     layerShortCut
    //   ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute;
    //   let changeDriverCCDValue = this.constantChangeDrivers?.find(
    //     (x: any) => x.dsdId === this.configurationData.data.attr.dsdId
    //   );
    //   /*istanbul ignore next*/
    //   if (changeDriverCCDValue) {
    //     this.attrDetails.patchValue({
    //       constantChangeDriverValue: changeDriverCCDValue?.values ? changeDriverCCDValue.values : false,
    //       constantChangeDriver: true,
    //     });
    //   }
    // }
  }
  removeScourceValue(i: any) {
    if (this.sourceValues.length <= 1) {
      this.addEntityBtn = false;
    }
    /*istanbul ignore next*/
    if (this.allDefaultValues?.length > 0) {
      /*istanbul ignore next*/
      let value = this.sourceValues?.[i]?.DATA?.actualValue;
      /*istanbul ignore next*/
      let index = this.allDefaultValues?.findIndex((ele: any) => ele === value);
      /*istanbul ignore next*/
      if (index >= 0) {
        this.allDefaultValues.splice(index, 1);
      }
    }
    this.sourceValues.splice(i, 1);
  }
  checkOfMultiDefaultValues(value: boolean) {
    this.multiDefaultValue = value;
    this.configurationData.data.attr.attributeType.type = 'list';
    this.attrDetails.patchValue({
      isMultiValue: value,
    });
  }
  fileUploadUrl(event: any) {
    /*istanbul ignore next*/
    this.fileUrl?.push(JSON.stringify(event));
  }
  fileUploadUrl2(event: any) {
    /*istanbul ignore next*/
    this.eachImageUrl?.push(event);
    this.imgUrlWithLabel = JSON.stringify(this.eachImageUrl);
  }
  chipImageUpload(event: any) {
    /*istanbul ignore next*/
    this.chipImageUrl?.push(event?.contentUrl);
    this.fileDetails = event;
    this.imageUrlwithChip = JSON.stringify(this.chipImageUrl);
    this.addButtonClicked = false;
    /*istanbul ignore next*/
    if (this.chipImageUrl?.length == this.sourceValues?.length) {
      /*istanbul ignore next*/
      this.disableAddButton = true;
    }
  }
  uploadMultipleFiles(event: any) {
    this.addButtonClicked = true;
  }
  saveEntitywithAttr() {
    /* istanbul ignore next */
    if (this.attrConfiguration?.showHelperTextIcon && this.helperTextString == '') {
      this.alertService.showToaster('Please provide helper text', '', 'info');
      return false;
    }
    /*istanbul ignore next*/
    if (
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'scheduler' &&
      !this.schedulerDetails?.value?.endDate
    ) {
      /*istanbul ignore next*/
      this.alertService.showToaster('Please provide end date and time for scheduler', '', 'info');
      return false;
    }
    /*istanbul ignore next*/
    //this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
    this.onChangeOptionAttr(event, 1);
    this.appendUiAttributes();
    this.addDataToEntity();
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.nslAttributeMetaData) {
      this.configurationData.data.attr.nslAttributeMetaData['translationType'] = this.translationType;
    } else {
      this.configurationData.data.attr.nslAttributeMetaData = { translationType: this.translationType };
    }
    this.translationType = 'NONE';
    /*istanbul ignore next*/
    if (this.configurationData.data.attr?.attributeType?.type == undefined) {
      this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
    }
    this.configurationData.data.attr.constraints = this.constraints;
    /*istanbul ignore next*/
    const findAttrIndex = this.configurationData.data.entity?.nslAttributes?.findIndex(
      (attr: any) => attr.name == this.configurationData.data.attr.name
    );
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location') {
      this.attrConfiguration = { ...this.attrConfiguration, locationType: this.attrDetails?.value?.locationType ,readCurrentLocation: this.readCurrentLocation,};
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'image') {
      /*istanbul ignore next*/
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imgConfig: { imgAsChip: this.imgAsChip, imgSize: this.imgSize },
      };
    }

    /* istanbul ignore next */
    if (
      (this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'videoRecording' ||
      this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'audioRecording')
    ) {
      if(this.timeLimit?.hours == 0 && this.timeLimit?.minutes == 0 && this.timeLimit?.seconds == 0){
        this.attrConfiguration.hours = this.attrConfiguration.minutes = this.attrConfiguration.seconds = undefined;
      }else{
        this.attrConfiguration = {
          ...this.attrConfiguration,
          hours: this.timeLimit?.hours,
          minutes: this.timeLimit?.minutes,
          seconds: this.timeLimit?.seconds
        }
      }
    }

    /* istanbul ignore next */
    if (this.uiControlListForDateTimeFormat?.includes(this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement)) {
      /* istanbul ignore next */
      if (this.customDateFormat) {
        /* istanbul ignore next */
        this.configurationData.data.attr.attributeType.properties = {
          timeFormat: '',
          format: this.customDateFormat,
        };
      } else {
        /* istanbul ignore next */
        this.configurationData.data.attr.attributeType.properties = {
          timeFormat: this.timeFormatType,
          format:
            this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'yearMonth'
              ? this.yearMonthFormatType
              : this.dateFormatType,
        };
      }
      this.attrConfiguration = {
        ...this.attrConfiguration,
        dateTimeFormat: this.configurationData.data.attr.attributeType.properties,
      };
    }

    /*istanbul ignore next*/
    if (
      this.helperTextPostion != '' ||
      this.helperTextString != '' ||
      this.helperTextFont != '' ||
      this.helperTextColor != '' ||
      this.helperTextIconColor != '' ||
      this.showHelperTextIcon != true
    ) {
      /*istanbul ignore next*/
      this.attrConfiguration = {
        ...this.attrConfiguration,
        helpertext: this.helperTextString,
        helperTextPosition: this.helperTextPostion,
        helperTextFont: this.helperTextFont,
        helperTextColor: this.helperTextColor,
        helperTextIconColor: this.helperTextIconColor,
      };
    }
    /*istanbul ignore next*/
    if (this.imgUrlWithLabel != '') {
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imgUrlWithLabel: this.imgUrlWithLabel,
      };
    }
    /*istanbul ignore next*/
    if (this.imageUrlwithChip != '') {
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imageUrlwithChip: this.imageUrlwithChip,
      };
    }
    this.configurationData.data.attr.configuration = JSON.stringify(this.attrConfiguration);
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'rating') {
      this.configurationData.data.attr.configuration = JSON.stringify({
        ...this.attrConfiguration,
        color: this.ratingColor,
      });
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'html') {
      this.configurationData.data.attr.configuration = JSON.stringify({
        ...this.attrConfiguration,
        showPopup: this.showPopup,
      });
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.entity?.nslAttributes) {
      this.configurationData.data.entity.nslAttributes[findAttrIndex] = this.configurationData.data.attr;
    }

    /*istanbul ignore next*/
    if (this.attrDetails.value.isMultiValue || this.multiDefaultValue) {
      /*istanbul ignore next*/
      this.configurationData.data.attr.configuration = this.configurationData?.data?.attr?.configuration
        ? this.configurationData.data.attr.configuration
        : '{}';
      /*istanbul ignore next*/
      this.configurationData.data.attr.configuration = JSON.stringify({
        ...this.attrConfiguration,
        ...JSON.parse(this.configurationData.data.attr.configuration),
        hideLabelMultiAttr: this.attrDetails.value.hideLabelMultiAttr,
        hideMultiValueIcon: this.attrDetails.value.hideMultiValueIcon,
      });
    }

    /*istanbul ignore next*/
    if (this.configurationData?.isGSI) {
      /* istanbul ignore next */
      const currentAttrIndex = this.currentEntity?.nslAttributes?.findIndex(
        (attr: any) => attr?.name == this.configurationData?.data?.attr?.name
      );
      /* istanbul ignore next */
      if (this.currentEntity?.nslAttributes) {
        this.currentEntity.nslAttributes[currentAttrIndex] = this.configurationData?.data?.attr;
      }
      /* istanbul ignore next */
      this.nodeEntityFacadeService.saveEntity(
        this.currentEntity,
        false,
        this.configurationData?.data?.cu,
        this.configurationData?.data?.entity?.nslAttributes,
        this.configurationData?.data?.layerType,
        false,
        this.configurationData?.configuration
      );

      /* istanbul ignore next */
      this.tempGsiObj?.layers?.forEach((layer: any) => {
        layer?.participatingItems?.forEach((item: any) => {
          if (item?.name === this.configurationData?.data?.entity?.name) {
            item.nslAttributes = this.configurationData?.data?.entity?.nslAttributes;
          }
        });
      });
      /*istanbul ignore next*/
      this.gsiFacade.saveGsi(this.tempGsiObj);
    } else if (!this.addEntityBtn) {
      this.alertService.showToaster('Please add atleast one option', '', 'info');
      return false;
    } else {
      /*istanbul ignore next*/
      const currentAttrIndex = this.currentEntity?.nslAttributes?.findIndex(
        (attr: any) => attr.name == this.configurationData.data.attr.name
      );
      /*istanbul ignore next*/
      this.currentEntity.nslAttributes[currentAttrIndex] = this.configurationData?.data?.attr;
      /*istanbul ignore next*/
      this.nodeEntityFacadeService.saveEntity(
        this.currentEntity,
        false,
        this.configurationData?.data?.cu,
        this.configurationData.data.entity?.nslAttributes,
        this.configurationData.data?.layerType,
        false,
        this.configurationData?.configuration
      );
    }

    this.schedulerDetails.reset();
    this.closeAttribute();
  }
  closeAttribute() {
    this.closeattribute.emit(true);
    this.changeunitFacade.closeActionpanel();
  }
  getValidations() {
    this.entityFacadeService.attributeValidation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.attributeValidations = res;
        this.getvalidationsList();
      }
    });
  }
  getAttributeTypes(dataType?: any, firstVersion?: boolean) {
    /*istanbul ignore next*/
    this.attributeEndpointService
      .getAllAttributeTypes(this.searchText, 1, 100, dataType, !firstVersion ? true : false)
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res?.data) {
          this.attributeTypes = res.data;
        }
      });
  }

  appendUiAttributes() {
    this.constraints = [];
    /*istanbul ignore next*/
    this.selectedValidatorsList?.forEach((element: any, i: any) => {
      /*istanbul ignore next*/
      if (element) {
        /*istanbul ignore next*/
        this.constraints?.push({
          name: element.name,
          properties: {},
          type: element.type,
        });
        /*istanbul ignore next*/
        if (element.properties && element.properties?.length) {
          element.properties?.forEach((value: any) => {
            this.constraints[i].properties[value.name] = value.message;
          });
        }
        if (element?.selectType) {
          this.constraints[i].properties['type'] = element?.selectType;
        }
      }
    });
  }
  validatorsChange(event: any, index: any) {
    event.properties.map((val: any) => {
      val.message = '';
    });
    this.selectedUiValidator = event.name;
    /*istanbul ignore next*/
    this.selectedValidatorsList?.push(event);
    this.UiValidatorDropdown.constraints.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });

    this.UiValidatorDropdown.constraints.splice(index, 1);
    this.constraints = [];
    const constraintsArr = this.eventsFacadeService.getDisableValidatorsList(event.name);
    /* istanbul ignore next */
    if (constraintsArr && constraintsArr?.length) {
      /*istanbul ignore next*/
      constraintsArr?.forEach((element: any, i: any) => {
        this.UiValidatorDropdown.constraints?.forEach((value: any, j: any) => {
          if (element === value.name) {
            value.disabled = true;
          }
        });
      });
    }
  }
  removeValidator(row: any, index: any) {
    this.selectedValidatorsList.splice(index, 1);
    this.UiValidatorDropdown.constraints.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });
    /*istanbul ignore next*/
    this.UiValidatorDropdown.constraints?.push(row);
    /*istanbul ignore next*/
    this.UiValidatorDropdown.constraints = this.UiValidatorDropdown.constraints.filter(
      (v: any, i: any, a: any) => a?.findIndex((t: any) => t.name === v.name) === i
    );
  }
  validationMultiValue(attrType: any) {
    /*istanbul ignore next*/
    this.attributeEndpointService.getAllAttributeTypes(this.searchText, 1, 100, true).subscribe((result: any) => {
      /*istanbul ignore next*/
      if (result?.data) {
        let res: any;
        this.addEntityBtn = true;
        /*istanbul ignore next*/
        result?.data.forEach((type: any, i: any) => {
          if (attrType?.value == type?.uiElement) {
            res = type;
          }
        });
        /*istanbul ignore next*/
        if (res && res?.isMulti === true) {
          /* istanbul ignore next */
          if (res.isMulti === true && res?.uiElement != 'typeahead') {
            this.addEntityBtn = false;
          } else {
            this.addEntityBtn = true;
          }
        }
      }
    });
  }
  selectAttrType(attrType: any, attr: any) {
    /*istanbul ignore next*/
    if (attrType?.value !== 'checkbox' && attrType?.value !== 'dropdown' && attrType?.value !== 'radioButton') {
      this.sourceValues = [];
    }
    this.validationMultiValue(attrType);
    attr['uiElement'] = {};
    /*istanbul ignore next*/
    let uiElement = this.attributeTypes?.find((x: any) => x?.name?.toLowerCase() === attrType.value.toLowerCase());
    /*istanbul ignore next*/
    if (uiElement && attr?.attributeType) {
      attr.attributeType.uiElement = uiElement;
      attr.attributeType.type = uiElement.dataType;
      this.dataType = uiElement.dataType;
      this.attrDetails?.patchValue({
        attributeUiElement: uiElement.uiElement,
        attributeType: uiElement.dataType,
      });
    }
    this.getAttributeTypes();
    this.getvalidationsList();
    /*istanbul ignore next*/
    if (uiElement?.esDataType && uiElement?.esDataType == 'entity') {
      /*istanbul ignore next*/
      this.nodeEntityFacadeService.loadAttributesByEntId(
        uiElement?.properties[1]?.defaultValue,
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );
    }
  }
  getvalidationsList() {
    /*istanbul ignore next*/
    if (!this.dataType) {
      /*istanbul ignore next*/
      this.dataType = this.configurationData?.data?.attr?.attributeType?.type;
    }

    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr && this.configurationData?.data?.attr?.configuration) {
      let res: any;
      /*istanbul ignore next*/
      res = JSON.parse(this.configurationData?.data?.attr?.configuration);
      /*istanbul ignore next*/
      if (res?.submitCU == true) {
        this.toggleCUSubmit = true;
      }
    }

    /*istanbul ignore next*/
    if (this.dataType) {
      /*istanbul ignore next*/
      const constraintsData =
        this.attributeValidations?.length > 0 ? JSON.parse(JSON.stringify(this.attributeValidations)) : [];
      /*istanbul ignore next*/
      this.UiValidatorDropdown = constraintsData?.find((value: any) => {
        return value.name === this.dataType;
      });
      /*istanbul ignore next*/
      this.UiValidatorDropdown?.constraints.map((val: any) => {
        /*istanbul ignore next*/
        if (val.name == 'optional' || val.name == 'empty') {
          val.disabled = true;
        }
      });
      /*istanbul ignore next*/
      if (this.attributeValidations && this.attributeValidations.length) {
        this.selectedValidatorsList = [];
        /*istanbul ignore next*/
        if (
          this.configurationData?.data?.attr &&
          this.configurationData.data.attr['constraints'] &&
          this.configurationData.data.attr['constraints'].length
        ) {
          this.configurationData.data.attr['constraints']?.forEach((value: any) => {
            const option = this.UiValidatorDropdown?.constraints?.find((val: any) => {
              return val.name == value.name;
            });
            const obj = {
              name: value.name,
              displayName: option?.displayName ? option.displayName : value.name,
            };
            obj['properties'] = [];
            /*istanbul ignore next*/
            if (value.properties && Object.keys(value.properties).length) {
              let editConstraintsArrKeys = Object.keys(value.properties);
              let editConstraintsArrValues = Object.values(value.properties);
              /*istanbul ignore next*/
              editConstraintsArrKeys?.forEach((val, i) => {
                obj['properties']?.push({
                  name: val,
                  message: editConstraintsArrValues[i],
                });
              });
            }
            /*istanbul ignore next*/
            if (this.iseditAttr) {
              /*istanbul ignore next*/
              this.selectedValidatorsList?.push(obj);
              this.selectedValidatorsList.filter((el: any) => {
                this.UiValidatorDropdown.constraints = this.UiValidatorDropdown?.constraints?.filter((el1: any) => {
                  return el1.name !== el.name;
                });
              });
            }
          });
        }
        if (this.selectedValidatorsList) {
          this.selectedValidatorsList.forEach((selected: any) => {
            selected.properties.forEach((value: any) => {
              if (value.name === 'type') {
                selected.selectType = value.message;
              }
            });
          });
        }
      }
    }
  }

  // Function to add default values
  addDefaultValue() {
    this.displayDateDFValue = this.defaultValue;
    this.dateTypeDefaultValue = null;
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'duration') {
      /* istanbul ignore next */
      if (
        this.durationDefaultValue?.days !== null ||
        this.durationDefaultValue?.hours !== null ||
        this.durationDefaultValue?.minutes !== null
      ) {
        let durationFormat = '';

        /* istanbul ignore next */
        let days: any = this.durationDefaultValue?.days;
        /* istanbul ignore next */
        let hours: any = this.durationDefaultValue?.hours;
        /* istanbul ignore next */
        let minutes: any = this.durationDefaultValue?.minutes;
        if (!days && !hours) {
          if (minutes) durationFormat = `PT${minutes}M`;
          else durationFormat = `P`;
        } else if (!days) {
          if (minutes) durationFormat = `PT${hours}H${minutes}M`;
          else durationFormat = `PT${hours}H`;
        } else if (!hours) {
          if (minutes) durationFormat = `P${days}DT${minutes}M`;
          else durationFormat = `P${days}D`;
        } else {
          if (minutes) durationFormat = `P${days}DT${hours}H${minutes}M`;
          else durationFormat = `P${days}DT${hours}H`;
        }

        this.defaultValue = durationFormat;

        this.durationDefaultValue.days = null;
        this.durationDefaultValue.hours = null;
        this.durationDefaultValue.minutes = null;
      } else {
        this.alertService.showToaster('Please enter duration!', '', 'error');
        return;
      }
    }
    /*istanbul ignore next*/
    if (this.attrDetails?.value?.attributeUiElement === 'MMILocation') {
      this.defaultValue = JSON.stringify({ latitude: this.mmiDefaultLat, longitude: this.mmiDefaultLon });
      this.mmiDefaultLat = '';
      this.mmiDefaultLon = '';
    }
    /* istanbul ignore next */
    if (this.defaultValue === '') {
      return;
    }
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'rating' && Number(this.defaultValue) > 5) {
      this.alertService.showToaster('Provide default value in range of 0 to 5', '', 'info');
      return;
    }

    /* istanbul ignore next */
    this.attrDetails?.patchValue({ defaultValue: this.defaultValue });
    /* istanbul ignore next */
    if (this.validateDefaultValue()) {
      /* istanbul ignore next */
      if (this.multiDefaultValue || this.allDefaultValues?.length === 0 || this.isEditable) {
        /* istanbul ignore next */
        if (
          this.attrDetails?.value?.defaultValue &&
          this.dateTypeUiControls?.includes(this.attrDetails?.value?.attributeUiElement)
        ) {
          if (this.isEditable) {
            this.allDefaultValues[this.attrValueIndex] = JSON.parse(
              JSON.stringify(this.attrDetails?.value?.defaultValue)
            );
            this.isEditable = false;
          } else {
            /* istanbul ignore next */
            this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
          }
          /* istanbul ignore next */
          this.attrDetails?.patchValue({ defaultValue: '' });
          this.defaultValue = '';
        } else {
          /* istanbul ignore next */
          if (this.attrDetails?.value?.defaultValue?.trim() !== '') {
            if (this.isEditable) {
              this.allDefaultValues[this.attrValueIndex] = JSON.parse(
                JSON.stringify(this.attrDetails?.value?.defaultValue)
              );
              this.isEditable = false;
            } else {
              /* istanbul ignore next */
              this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
            }

            /* istanbul ignore next */
            this.attrDetails?.patchValue({ defaultValue: '' });
            this.defaultValue = '';
          } else {
            this.alertService.showToaster(
              'Default value is already exist and multiple values are not allowed',
              '',
              'error'
            );
          }
        }
      } else if (!this.multiDefaultValue && this.allDefaultValues.length > 0) {
        this.alertService.showToaster(
          'Default value is already present and multiple values are not allowed',
          '',
          'error'
        );
      } else {
        this.alertService.showToaster('Please enter valid default value!', '', 'error');
      }
    }
  }

  // Validate default value based on datatype and UI control
  validateDefaultValue() {
    /* istanbul ignore next */
    let uiControl = this.attrDetails?.value?.attributeUiElement;
    /* istanbul ignore next */
    let dataType = this.attrDetails?.value?.attributeType;
    /* istanbul ignore next */
    let value = this.attrDetails?.value?.defaultValue;

    let res = validateDefaultValue(
      this.dateTypeUiControls,
      uiControl,
      dataType,
      value,
      this.sourceValues,
      this.allDefaultValues
    );
    if (res.message.length > 0) {
      res.message.forEach((msg: any) => {
        this.alertService.showToaster(msg.msg, '', msg.type);
      });
    }
    return res.isValid;
  }

  // Function to remove default values
  removeDefaultValue(i: any) {
    /*istanbul ignore next*/
    if (this.attrDetails?.value?.attributeUiElement == 'MMILocation') {
      this.mmiDefaultLat = '';
      this.mmiDefaultLon = '';
    }
    this.allDefaultValues.splice(i, 1);
  }

  booleanValue() {
    this.allDefaultValues = [];
    /*istanbul ignore next*/
    if (this.booleanDefaultValue) {
      /*istanbul ignore next*/
      this.allDefaultValues?.push('true');
    }
  }

  formatDate() {
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'time' && !isNaN(Date.parse(this.dateTypeDefaultValue))) {
      /*istanbul ignore next*/
      let updateTime = this.dateTypeDefaultValue?.setSeconds(0);
      this.dateTypeDefaultValue = new Date(updateTime);
    }
    this.defaultValue = this.dateTypeDefaultValue;
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'dateRange') {
      this.defaultValue = JSON.stringify(this.defaultValue);
    }
  }

  displayDateDefaultValue(value: any, attributeUiElement: any) {
    return displayDateDefaultValue(
      value,
      attributeUiElement,
      this.dateFormatType,
      this.timeFormatType,
      this.yearMonthFormatType,
      this.customDateFormat
    );
  }

  // For yearMonth UI control
  /* istanbul ignore next */
  chosenYearHandler(normalizedYear: any) {
    this.dateTypeDefaultValue = new Date(normalizedYear);
  }

  // For yearMonth UI control
  /* istanbul ignore next */
  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.dateTypeDefaultValue = new Date(normalizedMonth);
    /* istanbul ignore next */
    datepicker?.close();
    this.formatDate();
  }

  /* istanbul ignore next */
  chosenYearForYear(normalizedYear: any, yearPicker: MatDatepicker<any>) {
    this.defaultValue = new Date(normalizedYear).getFullYear();
    /* istanbul ignore next */
    yearPicker?.close();
  }

  checkDuration() {
    if (this.durationDefaultValue.hours < 0 || this.durationDefaultValue.hours > 23) {
      this.durationDefaultValue.hours = null;
    }
    if (this.durationDefaultValue.minutes < 0 || this.durationDefaultValue.minutes > 59) {
      this.durationDefaultValue.minutes = null;
    }
  }

  // validations for a schedular-ui-control form values
  onSelectionChange(event: any) {
    /* istanbul ignore next */
    if (event?.value === 'MONTH' || event?.value === 'WEEK') {
      /* istanbul ignore next */
      this.schedulerDetails?.get('day')?.patchValue('');
      /* istanbul ignore next */
      this.schedulerDetails?.get('time')?.patchValue('');
      this.isDay = true;
      this.isTime = true;
    } else if (event?.value === 'DAY') {
      /* istanbul ignore next */
      this.schedulerDetails?.get('day')?.patchValue('');
      /* istanbul ignore next */
      this.schedulerDetails?.get('time')?.patchValue('');
      this.isDay = false;
      this.isTime = true;
    } else {
      /* istanbul ignore next */
      this.schedulerDetails?.get('day')?.patchValue('');
      /* istanbul ignore next */
      this.schedulerDetails?.get('time')?.patchValue('');
      this.isDay = false;
      this.isTime = false;
    }
  }

  updateNewTimeFormat(event: any) {
    /* istanbul ignore next */
    this.timeFormat = event?.value;
  }
  detectEntityData() {
    /*istanbul ignore next*/
    this.nodeEntityFacadeService.editAttributeEntity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      /*istanbul ignore next*/
      this.configurationData.data.entity = response;
    });
  }

  deletedFileResponse(event: any) {
    this.fileUrl = event;
    this.chipImageUrl.length = this.chipImageUrl.length - 1;
  }

  fetchCurrentEntity(id: number) {
    this.nodeEntityFacadeService
      .getEntityDeatils(id, false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((entity: any) => {
        this.currentEntity = entity?.result;
      });
  }

  saveAttr() {
    /*istanbul ignore next*/
    if (
      this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'scheduler' &&
      !this.schedulerDetails?.value?.endDate
    ) {
      /*istanbul ignore next*/
      this.alertService.showToaster('Please provide end date and time for scheduler', '', 'info');
      return false;
    }
    /* istanbul ignore next */
    if (!this.currentEntity) {
      /* istanbul ignore next */
      this.currentEntity = this.configurationData?.data?.entity;
    }
    /*istanbul ignore next*/
    this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
    this.onChangeOptionAttr(event, 1);
    this.appendUiAttributes();
    this.addDataToEntity();
    /*istanbul ignore next*/
    if (this.configurationData.data.attr?.attributeType?.type == undefined) {
      this.configurationData.data.attr.attributeType.type = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
    }
    this.configurationData.data.attr.constraints = this.constraints;
    /*istanbul ignore next*/
    const findAttrIndex = this.configurationData.data.entity?.nslAttributes?.findIndex(
      (attr: any) => attr.name == this.configurationData.data.attr.name
    );
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'location') {
      this.attrConfiguration = { locationType: this.attrDetails?.value?.locationType,readCurrentLocation: this.readCurrentLocation };
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'image') {
      /*istanbul ignore next*/
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imgConfig: { imgAsChip: this.imgAsChip, imgSize: this.imgSize },
      };
    }

    /* istanbul ignore next */
    if (this.uiControlListForDateTimeFormat?.includes(this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement)) {
      /* istanbul ignore next */
      if (this.customDateFormat) {
        /* istanbul ignore next */
        this.configurationData.data.attr.attributeType.properties = {
          timeFormat: '',
          format: this.customDateFormat,
        };
      } else {
        /* istanbul ignore next */
        this.configurationData.data.attr.attributeType.properties = {
          timeFormat: this.timeFormatType,
          format:
            this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'yearMonth'
              ? this.yearMonthFormatType
              : this.dateFormatType,
        };
      }
    }

    /*istanbul ignore next*/
    if (
      this.helperTextPostion != '' ||
      this.helperTextString != '' ||
      this.helperTextFont != '' ||
      this.helperTextColor != '' ||
      this.helperTextIconColor != ''
    ) {
      /*istanbul ignore next*/
      this.attrConfiguration = {
        ...this.attrConfiguration,
        helpertext: this.helperTextString,
        helperTextPosition: this.helperTextPostion,
        helperTextFont: this.helperTextFont,
        helperTextColor: this.helperTextColor,
        helperTextIconColor: this.helperTextIconColor,
      };
    }
    /*istanbul ignore next*/
    if ((this.imgUrlWithLabel! = '')) {
      /*istanbul ignore next*/
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imgUrlWithLabel: this.imgUrlWithLabel,
      };
    }
    this.configurationData.data.attr.configuration = JSON.stringify(this.attrConfiguration);
    this.configurationData.data.entity.nslAttributes[findAttrIndex] = this.configurationData.data.attr;
    /*istanbul ignore next*/
    if (this.configurationData?.isGSI) {
      /*istanbul ignore next*/
      this.tempGsiObj.layers.forEach((layer: any) => {
        layer.participatingItems.forEach((item: any) => {
          if (item.name === this.configurationData.data.entity.name) {
            item.nslAttributes = this.configurationData.data.entity?.nslAttributes;
          }
        });
      });
    } else if (!this.addEntityBtn) {
      /*istanbul ignore next*/
      this.alertService.showToaster('Please add atleast one option', '', 'info');
      return false;
    } else {
      /*istanbul ignore next*/
      const currentAttrIndex = this.currentEntity?.nslAttributes?.findIndex(
        (attr: any) => attr.name == this.configurationData.data.attr.name
      );
      /* istanbul ignore next */
      if (currentAttrIndex == -1) {
        /*istanbul ignore next*/
        this.currentEntity?.nslAttributes?.push(this.configurationData?.data?.attr);
      } else {
        /* istanbul ignore next */
        this.currentEntity.nslAttributes[currentAttrIndex] = this.configurationData?.data?.attr;
      }
      /*istanbul ignore next*/
      this.nodeEntityFacadeService.saveEntity(
        this.currentEntity,
        false,
        this.configurationData?.data?.cu,
        this.configurationData.data.entity?.nslAttributes,
        this.configurationData.data?.layerType,
        false,
        this.configurationData.data?.layerType,
        this.configurationData?.configuration
      );
    }

    this.schedulerDetails.reset();
    this.closeAttribute();
  }
  onlyNumberKey(event: any) {
    /* istanbul ignore next */
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode == 45 || (event.charCode > 46 && event.charCode <= 57);
  }
  sliderDefaultValue() {
    /* istanbul ignore next */
    if (this.defaultValue > this.targetValue) {
      this.alertService.showToaster(`Please provide Default value in range of 0 to ${this.targetValue}`, '', 'info');
    } else {
      this.attrDetails?.patchValue({ defaultValue: this.defaultValue });
      this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
      this.attrDetails?.patchValue({ defaultValue: '' });
      this.defaultValue = '';
    }
  }

  formatNewDate(event: any) {
    let dispalyValue;
    dispalyValue = event.split(' ');
    const merdien = dispalyValue[1];
    const time = dispalyValue[0].split(':');
    const hr = time[0];
    const min = time[1];
    dispalyValue =
      (hr.length == 1 ? '0' + hr : parseInt(hr) == 24 ? '12' : hr) + ':' + (min.length == 1 ? '0' + min : min);
    if (this.timeFormatType === '24-hr') {
      this.dateTypeDefaultValue = dispalyValue;
    } else {
      if (parseInt(hr) > 12) {
        const disp = parseInt(hr) - 12 + ':' + (min.length == 1 ? '0' + min : min);
        this.dateTypeDefaultValue = disp + ' ' + merdien;
      } else if (parseInt(hr) == 0 && merdien == 'am') {
        const disp = parseInt(hr) + 12 + ':' + (min.length == 1 ? '0' + min : min);
        this.dateTypeDefaultValue = disp + ' ' + merdien;
      } else {
        this.dateTypeDefaultValue = dispalyValue + ' ' + merdien;
      }
    }
    dispalyValue = dispalyValue + ':00';
    this.defaultValue = dispalyValue;
  }

  formatNewDate1(event: any) {
    /*istanbul ignore next*/
    if (this.attrDetails?.value?.attributeUiElement === 'datetime') {
      /*istanbul ignore next*/
      if (this.timeFormatType === '24-hr') {
        this.dateTypeDefaultValue = formatDate(event, `${this.dateFormatType} HH:mm`, 'en-US');
      } else {
        this.dateTypeDefaultValue = formatDate(event, `${this.dateFormatType} hh:mm a`, 'en-US');
      }
    } else if (this.attrDetails?.value?.attributeUiElement === 'date') {
      this.dateTypeDefaultValue = formatDate(event, this.dateFormatType, 'en-US');
    }
    this.defaultValue = event;
  }

  selectedPosition(type: string) {
    /*istanbul ignore next*/
    if (this.attrConfiguration?.showHelperTextIcon) {
      this.helperTextPostion = '';
    }
    this.helperTextPostion = type;
  }

  selectedColor(type: string) {
    this.helperTextColor = type;
  }
  iconSelectedColor(type: string) {
    this.helperTextIconColor = type;
  }

  selectedFont(type: string) {
    this.helperTextFont = type;
  }

  getFetchedNestedEntity() {
    this.nodeEntityFacadeService.nestedEntity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((ent: any) => {
      /*istanbul ignore next*/
      if (!emptyObject(ent)) {
        /* istanbul ignore next */
        let attr = this.configurationData?.data?.attr;
        /* istanbul ignore next */
        let entity = this.configurationData?.data?.entity;
        /* istanbul ignore next */
        if (attr?.attributeType?.type == 'entity') {
          attr.generalEntity = ent;
        }
      }
    });
  }

  // changeSubmitToggle(event: any, template: any) {
  //   /* istanbul ignore next */
  //   if (event?.checked === true) {
  //     this.openWarnningDialog(template);
  //   } else {
  //     this.toggleCUSubmit = false;
  //   }
  // }

  // openWarnningDialog(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef, {
  //     panelClass: 'onboardingtype-dialog',
  //     width: '40%',
  //     height: '15%',
  //     disableClose: true,
  //   });
  // }

  // onSubmitSelected() {
  //   this.attrConfiguration = {
  //     ...this.attrConfiguration,
  //     submitCU: true,
  //   };
  //   this.changeUnit?.layers?.forEach((layer: any) => {
  //     layer?.participatingItems?.forEach((entity: any) => {
  //       entity?.nslAttributes?.forEach((attribute: any) => {
  //         let config = attribute?.configuration ? JSON.parse(attribute?.configuration) : null;
  //         if (config?.submitCU) {
  //           delete config.submitCU;
  //         }
  //       });
  //     });
  //   });
  //   this.dialog.closeAll();
  // }

  configurationKeys(event: any): void {
    this.attrConfiguration.strikeOff = event.checked;
  }
  htmlToggle(option: boolean) {
    /* istanbul ignore next */
    if (option) {
      this.attrConfiguration.showPopup = true;
    }
  }
  editDefaultValue(i: any) {
    this.isEditable = true;
    this.attrValueIndex = i;
    this.defaultValue = this.allDefaultValues[i];
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'datetime') {
      this.newDateValue = new Date(this.defaultValue);
    }
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement == 'MMILocation') {
      this.mmiDefaultLat = JSON.parse(this.allDefaultValues[i])?.latitude;
      this.mmiDefaultLon = JSON.parse(this.allDefaultValues[i])?.longitude;
    }
  }

  // onSubmitCancel() {
  //   this.toggleCUSubmit = false;
  //   this.dialog.closeAll();
  // }

  /**
   * Description : This method set the default value for stream Ui control in file
   * Author : Sunil Goyal
   * Date : 13/9/2023
   */
  setUrlDefaultValue() {
    /* istanbul ignore next */
    this.multiDefaultValue ? this.fileUrl?.push(this.UrlDefaultValue) : (this.fileUrl = [this.UrlDefaultValue]);
    this.UrlDefaultValue = '';
  }
  // Function to remove default values of file types
  removefileUrlValue(i: any) {
    this.fileUrl.splice(i, 1);
  }
  // Function to toggle default values of file types
  toggleDefaultValueURL() {
    this.fileUrlDefaultValueFlag = !this.fileUrlDefaultValueFlag;
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  addMMIDestination() {
    this.mmiDestination = JSON.stringify({ latitude: this.mmiDestinationLat, longitude: this.mmiDestinationLon });
    this.mmiDestinationLat = '';
    this.mmiDestinationLon = '';
  }
  removeMMIDestination() {
    this.mmiDestinationLat = '';
    this.mmiDestinationLon = '';
    this.mmiDestination = '';
  }
  editMMIDestination() {
    /* istanbul ignore next */
    this.mmiDestinationLat = JSON.parse(this.mmiDestination)?.latitude;
    /* istanbul ignore next */
    this.mmiDestinationLon = JSON.parse(this.mmiDestination)?.longitude;
    this.mmiDestination = '';
  }
  triggerOnchange(event: any, type: string, tabs: any) {
    this.dateTabConfig[tabs] = type == 'plus' ? this.dateTabConfig[tabs] + 1 : this.dateTabConfig[tabs] - 1;
  }
  deleteCurrencyOption(index: number) {
    /* istanbul ignore next */
    if (this.attrDetails?.value?.unitType == this.currencyOptions?.[index]?.symbol_native) {
      this.attrDetails.value.unitType = '';
      this.isDefaultCurrency = false;
      this.attrDetails?.controls?.['unitType']?.setValue('');
    }
    /* istanbul ignore next */
    let ind = this.sourceValues?.findIndex((x: any) => x?.DATA?.actualValue === this.currencyOptions?.[index]?.name);
    /* istanbul ignore next */
    if (ind !== -1) {
      this.sourceValues.splice(ind, 1);
    }
    /* istanbul ignore next */
    if (this.attrDetails?.value?.defaultOption?.length > 0) {
      /* istanbul ignore next */
      let options = this.attrDetails?.value?.defaultOption;
      options.splice(index, 1);
      /* istanbul ignore next */
      this.attrDetails.patchValue({
        defaultOption: options,
      });
    }
    /* istanbul ignore next */
    if (this.filteredOptions?.length == 0) {
      this.entityFacadeService.getCurrencyJson().subscribe((data: any) => {
        this.filteredOptions = data;
      });
    }
  }
  makeDefaultCurrency(ind: number) {
    this.def_ault = ind;
    this.isDefaultCurrency = true;
    /* istanbul ignore next */
    this.attrDetails.value.unitType = this.currencyOptions?.[ind]?.symbol_native;
    /* istanbul ignore next */
    this.attrDetails?.controls?.['unitType']?.setValue(this.currencyOptions?.[ind]?.symbol_native);
    /* istanbul ignore next */
    let index = this.filteredOptions?.findIndex((x: any) => x?.symbol === this.currencyOptions?.[ind]?.symbol);
    /* istanbul ignore next */
    if (index !== -1) {
      /* istanbul ignore next */
      this.filteredOptions?.splice(index, 1);
      /* istanbul ignore next */
      this.filteredOptions?.unshift(this.currencyOptions[ind]);
    }
  }
  downloadFile(value: boolean) {
    this.canDownload = value;
  }

  // For date type UI controls
  selectedDateFormat(i: number) {
    this.dateFormatType = this.dateFormatsForShow[i];
  }

  // For time type UI controls
  selectedTimeFormat(i: number) {
    this.timeFormatType = this.timeFormatsForShow[i];
  }

  showDateInCustomDateFormat() {
    return formatDate(new Date(), this.customDateFormat, 'en-US');
  }

  // For yearMonth UI control
  selectedYearMonthFormat(i: number) {
    this.yearMonthFormatType = this.yearMonthFormatsForShow[i];
  }

  updateValue(value: any, attr: any, type: any) {
    /* istanbul ignore next */
    if (type === 'default' && value) {
      this.dateTypeDefaultValue = value.actualValue;
      this.defaultValue = this.dateTypeDefaultValue;
    }
  }
  getPlaceholder(uiElement : any): string {
    if (['time', 'timeonly', 'newtime']?.includes(uiElement)) {
        return 'hh:mm:ss';
    }
    return 'dd/MM/yyyy';
}
changeFormat() {
    this.currentDateForCustomDateFormatEx = new Date(this.currentDateForCustomDateFormatEx);
  }
}
