<div class="confirm-des">
  <ul *ngIf="changeUnits && changeUnits.length > 0">
    <button mat-button class="display-name-btn" *ngIf="subCuFlag">
      {{ selectedCu?.displayName ? selectedCu?.displayName : selectedCu?.name }}.
    </button>
    <ng-container *ngFor="let cu of changeUnits; let i = index">
      <li
        *ngIf="cu.show"
        id="txnCU_{{ cu.DATA.name }}"
        [ngClass]="{ active: isCurrentCU(selectedCu, cu) }"
        [class]="cu.hilighter"
      >
        <!-- <span class="ocr-mapped"><img src="../../../../../assets/images/ocr-zoom.png" alt="" /></span> -->
        <span>
          <img
            [ngClass]="isCurrentCU(selectedCu, cu) ? 'img-size' : 'd-none'"
            [src]="cu.icon"
            alt=""
            id="cuIcon_{{ cu?.DATA?.displayName ? cu?.DATA?.displayName : cu?.DATA?.name }}_{{
              getFileNameFromImgUrl(cu.icon)
            }}"
          />
          <span *ngIf="isCurrentCU(selectedCu, cu)" id="txnCU_{{ cu?.DATA?.name }}" class="gdot"></span>
          <button mat-button class="display-name-btn" (click)="viewPreviousCus(cu)">
            {{ cu?.DATA?.displayName ? cu?.DATA?.displayName : cu?.DATA?.name }}.
          </button>
          <!-- <span
            class="material-icons"
            *ngIf="selectedCu?.uiProperties?.ar_id && cu?.DATA?.id == selectedCu.id"
            (click)="openArCamera(selectedCu)"
            >photo_camera</span
          > -->
        </span>
      </li>
    </ng-container>
  </ul>
</div>
