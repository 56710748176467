import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { ClickableComponent } from './clickable.component';

@NgModule({
  declarations: [ClickableComponent],
  imports: [ControlsMaterialModule],
  exports: [ClickableComponent],
})
export class ClickableModule {
  static entry = {
    clickable: ClickableComponent,
  };
}
