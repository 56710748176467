import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AlertService
} from '@common-services';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeOcrFacadeService } from '../../node-services/node-ocr-facade.service';
@Component({
  selector: 'app-node-ocr-upload',
  templateUrl: './node-ocr-upload.component.html',
  styleUrls: ['./node-ocr-upload.component.scss'],
})
export class NodeOcrUploadComponent implements OnInit, OnDestroy {
  @Input() tempGSIData: any;
  @Input() ocrData: any;
  @Input() initComponentData: any;
  @Output() emitOpenPDF: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeOCRPanel = new EventEmitter<any>();

  public ngUnsubscribe = new Subject();
  showFile: boolean = false;
  showProgress: boolean = false;
  progress: number = 40;
  coordinates: any;
  originalDocumentSize: any;
  image: any;
  filename: any;
  fileType: any;
  changeUnit: any;
  entity: any;
  gsi: any;

  constructor(
    private nodeOcrFacadeService: NodeOcrFacadeService,
    private alertService: AlertService,
    private changeunitFacade: NodeChangeUnitFacadeService
  ) {
    this.getCuDetails();
    this.detectLastUploadFileURL();
    this.detectSaveMappedFileDetails();
  }

  ngOnInit(): void {
    this.changeUnit = this.initComponentData.changeUnit;
    this.entity = this.initComponentData.entity;
    this.gsi = this.initComponentData.gsiData;
    this.changeunitFacade.getCuDetails(this.changeUnit?.referencedChangeUnit);
  }
  /* istanbul ignore next */
  onFileSelected(event: any) {
    let fileList: any = event?.target?.files ? event?.target?.files : event;
    /* istanbul ignore next */
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0]);

      this.nodeOcrFacadeService.fileUpload(formData, this.changeUnit);
    }
  }
  /* istanbul ignore next */
  detectLastUploadFileURL() {
    this.nodeOcrFacadeService.nodeOcrFileUpload$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.progress = 100;
      this.showFile = true;

      this.saveOCRGSIandFileMapping(res);
    });
  }
  /* istanbul ignore next */
  getOCRGSIandFileMappingInTransactionView(res: any) {}
  /**
   * map the file url and gsi id and cu id and save the ocr
   *
   */
  /* istanbul ignore next */
  saveOCRGSIandFileMapping(data: any) {
    let ontology = data.changeUnit.ontology?.[0]?.id;
    /*istanbul ignore else */
    if (ontology == 'common') {
      let lastIndex = this.changeUnit.ontology.length;
      ontology = lastIndex >= 2 ? this.changeUnit.ontology[2]?.id : this.changeUnit.ontology[1]?.id;
    }
    /* istanbul ignore next */
    const postData = {
      file: data.contentUrl,
      gsi_ID: this.gsi?.id,
      cu_ID: this.changeUnit.dsdRefId ? this.changeUnit.dsdRefId : this.changeUnit.id,
      // ontology: 'financial',
      ontology: ontology ? ontology : 'common.financeCommon',
      trans_call: false,
    };
    this.nodeOcrFacadeService.saveOCRGSIandFileMapping(postData);
  }
  /* istanbul ignore next */
  detectSaveMappedFileDetails() {
    this.nodeOcrFacadeService.nodeFileMappedDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.ocrData = res;
        this.coordinates = this.ocrData?.mappings;
        this.originalDocumentSize = this.ocrData?.ref_file_dimenstions;
        this.image = this.ocrData?.ref_doc;
        this.filename = this.ocrData?.file_name;
        this.fileType = this.ocrData?.file_type;
        this.emitOpenPDF.emit(this.ocrData);
      }
    });
  }
  getCuDetails() {
    this.changeunitFacade.cuDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      this.changeUnit = res;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
