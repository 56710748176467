import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeUnitFacadeService } from '../change-unit/change-unit-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { TranslatorService } from '../translator/translator.service';


@Component({
  selector: 'app-shared-error-handler',
  templateUrl: './shared-error-handler.component.html',
  styleUrls: ['./shared-error-handler.component.scss'],
})
export class SharedErrorHandlerComponent implements OnInit, OnDestroy {
  labels: any;
  private ngUnsubscribe = new Subject();
  step: number = 0;
  totalErrorsCount: number = 0;
  dcdErrors: any = [];
  logicalErrorCount: number = 0;
  recursiveErrors: any = [];
  alternateErrors: any = [];
  basicErrors: any = [];
  slotErrors: any = [];
  cuValidateError: any = [];
  errorCount = 0;
  warningCount = 0;
  infoCount = 0;
  @ViewChild('myDialog') myDialog = {} as TemplateRef<any>;
  @Input() errorData: any;
  close: boolean;
  @Output() closeErrorHandler = new EventEmitter();
  allErrorData: any = [[], [], []];
  constructor(
    private translator: TranslatorService,
    private changeUnitFacade: ChangeUnitFacadeService,
    private gsiFacade: GsiFacadeService,
    public dialog: MatDialog
  ) {
    this.detectLanguageChange();
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    if (this.errorData?.output) {
      this.errorData = this.errorData.output[0].result;
    }
    this.parseErrorData();
    if (this.errorData) {
      Object.keys(this.errorData).forEach((errorKey: string) => {
        Object.keys(this.errorData[errorKey]).forEach((cuNameKey: string) => {
          Object.keys(this.errorData[errorKey][cuNameKey]).forEach((exceptionType: string) => {
            Object.keys(this.errorData[errorKey][cuNameKey][exceptionType]).forEach(
              (errorType: string, errorIndex: number) => {
                if (errorKey === 'exceptionResponseMapForSolLogic') {
                  if (errorType === 'DCD') {
                    this.dcdErrors.push({
                      cu: cuNameKey,
                      data: this.errorData[errorKey][cuNameKey][exceptionType][errorType],
                      message: this.errorData[errorKey][cuNameKey][exceptionType][errorType][0].errorDetails[0].message,
                      errorType: errorType,
                    });
                    this.changeUnitFacade.errorData(this.dcdErrors, errorType);
                  } else if (errorType === 'BASIC') {
                    this.basicErrors.push({
                      cu: cuNameKey,
                      data: this.errorData[errorKey][cuNameKey][exceptionType][errorType],
                      message: this.errorData[errorKey][cuNameKey][exceptionType][errorType][0].errorDetails[0].message,
                      errorType: errorType,
                    });
                    this.changeUnitFacade.errorData(this.basicErrors, errorType);
                  } else if (errorType === 'RECURSIVE_CONSTRAINT') {
                    this.recursiveErrors.push({
                      cu: cuNameKey,
                      data: this.errorData[errorKey][cuNameKey][exceptionType][errorType],
                      message: this.errorData[errorKey][cuNameKey][exceptionType][errorType][0].errorDetails[0].message,
                      errorType: errorType,
                    });
                    this.changeUnitFacade.errorData(this.recursiveErrors, errorType);
                  } else if (errorType === 'ALTERNATE_CONSTRAINT') {
                    this.alternateErrors.push({
                      cu: cuNameKey,
                      data: this.errorData[errorKey][cuNameKey][exceptionType][errorType],
                      message: this.errorData[errorKey][cuNameKey][exceptionType][errorType][0].errorDetails[0].message,
                      errorType: errorType,
                    });
                    this.changeUnitFacade.errorData(this.alternateErrors, errorType);
                  } else if (errorType === 'SLOT') {
                    this.slotErrors.push({
                      cu: cuNameKey,
                      data: this.errorData[errorKey][cuNameKey][exceptionType][errorType],
                      message: this.errorData[errorKey][cuNameKey][exceptionType][errorType][0].errorDetails[0].message,
                      errorType: errorType,
                    });
                    this.changeUnitFacade.errorData(this.slotErrors, errorType);
                  }
                  this.logicalErrorCount =
                    this.logicalErrorCount + this.errorData[errorKey][cuNameKey][exceptionType][errorType].length;
                }
                this.totalErrorsCount =
                  this.totalErrorsCount + this.errorData[errorKey][cuNameKey][exceptionType][errorType].length;
                this.gsiFacade.setTotalErrorsCount(this.totalErrorsCount);
              }
            );
          });
        });
      });
    }
  }

  /**
   * @author Ajit Dhayal
   * @returns void , will segregate errors[errors, warnings and informations] and store them in an 3D array
   * [allErrorData] which will be shown as accordion
   */
  parseErrorData() {
    if (!this.errorData) return;

    const errorTypes = ['exceptionResponseMapForSolLogic', 'cuExceptions'];
    const levelMappings = {
      [this.labels?.['Exception_shared'] || 'exception']: 0,
      [this.labels?.['Warning_shared'] || 'warning']: 1,
      [this.labels?.['Info_shared'] || 'info']: 2,
    };
    /*istanbul ignore next*/
    errorTypes?.forEach((errorKey) =>
      Object.entries(this.errorData[errorKey] || {})?.forEach(([cuNameKey, cuData]) =>
        Object.entries(cuData || {})?.forEach(([exceptionType, exceptionData]) =>
          Object.entries(exceptionData || {})?.forEach(([errorType, errDataList]) => {
            const errDataArray = Array.isArray(errDataList) ? errDataList : [];
            /*istanbul ignore next*/
            errDataArray?.forEach((errData: any) => {
              const errorLevel = errData?.errorLevel || errData?.errorDetails?.[0]?.errorLevel;
              if (!errorLevel) return;
              /*istanbul ignore next*/
              const levelIndex = levelMappings?.[errorLevel];
              /*istanbul ignore next*/
              if (levelIndex !== undefined) {
                const cuIndex = this.allErrorData?.[levelIndex]?.findIndex((cu: any) => cu?.cuName === cuNameKey);

                const errorMessages =
                  errorKey === 'cuExceptions' ? [errData?.message] : [errData?.errorDetails?.[0]?.message];
                /*istanbul ignore next*/
                if (cuIndex === -1) {
                  this.allErrorData[levelIndex].push({
                    errorMessages: [{ [errorType]: errorMessages, errorType }],
                    cuName: cuNameKey,
                    errorType: '',
                  });
                } else {
                  const ind = this.allErrorData?.[levelIndex]?.[cuIndex]?.errorMessages.findIndex((e: any) =>
                    e?.hasOwnProperty(errorType)
                  );

                  if (ind === -1) {
                    this.allErrorData?.[levelIndex]?.[cuIndex]?.errorMessages?.push({
                      [errorType]: errorMessages,
                      errorType,
                    });
                  } else {
                    this.allErrorData[levelIndex][cuIndex].errorMessages[ind][errorType].push(...errorMessages);
                  }
                }
                /*istanbul ignore next*/
                if (errorLevel === (this.labels?.['Exception_shared'] || 'exception')) this.errorCount++;
                else if (errorLevel === (this.labels?.['Warning_shared'] || 'warning')) this.warningCount++;
                else if (errorLevel === (this.labels?.['Info_shared'] || 'info')) this.infoCount++;
              }
            });
          })
        )
      )
    );
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  setStep(index: number) {
    this.step = index;
  }

  onDialogYesClick() {
    this.dialog?.closeAll();
    localStorage.setItem('skipWarnings', 'true');
    this.gsiFacade.skipWarnings(this.gsiFacade?.safeGuardBetName);
  }

  warnButtonClicked() {
    this.dialog.open(this.myDialog, { width: '430px', disableClose: true });
  }
  closeError() {
    this.close = true;
    this.closeErrorHandler.emit(this.close);
  }
}

