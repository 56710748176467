import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root',
})
export class SttEndpointService {
  speech: any;
  finalTranscript: string = '';
  results: string = '';

  interimResult = new Subject();
  interimResultObservable = this.interimResult.asObservable();

  finalResult = new Subject();
  finalResultObservable = this.finalResult.asObservable();

  finalResultString: string = '';
  interimResultString: string = '';

  constructor() {
    this.speech = new webkitSpeechRecognition();
    this.getResults();
  }

  getResults() {
    this.speech.onresult = (event: any) => {
      for (let i = event?.resultIndex; i < event?.results.length; ++i) {
        if (event?.results[i].isFinal) {
          this.finalResultString += event?.results[i][0].transcript;
          this.finalResult.next(this.finalResultString);
        } else {
          this.interimResultString = event?.results[i][0].transcript;
          this.interimResult.next(this.interimResultString);
        }
      }
    };
  }

  initSpeech(isContinuous: boolean, interimResult: boolean, language?: string) {
    this.speech.continuous = isContinuous;
    this.speech.interimResults = interimResult;
    if (language) {
      this.speech.language = language;
    }
  }

  start() {
    this.speech.start();
  }

  stop() {
    this.speech.stop();
    this.interimResultString = '';
    this.finalResultString = '';
  }

  abort() {
    this.speech.abort();
    this.interimResultString = '';
    this.finalResultString = '';
  }
  startListening() {
    const vSearch = new webkitSpeechRecognition();
    vSearch.continuous = false;
    vSearch.interimresults = false;
    vSearch.lang = 'en-US';
    vSearch.start();
    vSearch.onresult = (e: any) => {
      console.log(e);
      this.results = e.results[0][0].transcript;
      this.interimResult.next(this.results);
      vSearch.stop();
    };
  }
}
