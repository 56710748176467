import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials, CredentialsService } from './credentials.service';
import { getLocalStorageValue, getEnvClientId, getTenantName } from './../../generic-folder/generic-functions';
import { map, catchError, pluck } from 'rxjs/operators';
import { LoaderService } from './../loader/loader.service';
import { CduiBaseService } from './cdui-base.service';
import { O } from '@angular/cdk/keycodes';
import { AlertService } from '../toaster/alert.service';

export interface LoginContext {
  tenantName: string;
  userName: string;
  encryptedPassword: string;
  remember?: boolean;
  clientId?: string;
  guestUserId?: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends CduiBaseService implements OnDestroy {
  isBrfLogin: boolean;
  isB2b2cLogin: boolean;
  campaignGsiLoginToken = new Subject();
  campaignGsiLoginToken$ = this.campaignGsiLoginToken.asObservable();
  identityProvidersList = new Subject();
  identityProvidersList$ = this.identityProvidersList.asObservable();
  accessTokenLifespan ?: number;
  refreshTokenLifespan ?: number;


  onboardingTenant: any[] = ['selfonboard', 'systemintegrator', 'b2b', 'sdp', 'bdp', 'freelancer'];
  constructor(
    public injector: Injector,
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private alertService: AlertService,
    private loaderService: LoaderService
  ) {
    super(injector);
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext, remember?: any, isOnboarding?: boolean): Observable<Object> {
    /* istanbul ignore next */
    this.loaderService.show();
    return this.http.post(this.api?.tokenGenerateV2, context).pipe(
      map((res: any) => {
        this.loaderService.hide();
        let signUpProperties: any = localStorage.getItem('2fa');
        if (res?.result && res?.status === 200 && signUpProperties != 'true') {
          if (context.userName.substring(0, 9) != 'nslguest-') {
            localStorage.removeItem('guestCredentials');
          }
          const data: Credentials = {
            username: context.userName,
            token: res.result?.access_token,
            client_id: context?.clientId,
            refresh_token: res.result?.refresh_token,
            expires_in: res.result?.expires_in,
            last_loginTime: new Date(),
            tenant: context.tenantName,
          };
          if (isOnboarding) {
            this.credentialsService.setOnboardingCredentials(data, remember);
          } else if(res?.message != 'Please update your password to activate your account') { // Temporary passowrd detect time credentials should not be set causing session storage clear issue from authinterceptor
            this.credentialsService.setCredentials(data, remember);
          }
        } else {
          localStorage.setItem('loginContext', JSON.stringify(context));
        }
        return res;
        // return of(true);
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): any {
    const envClientId = getEnvClientId();
    const tenantName = getTenantName();
    /* istanbul ignore next */
    if (getLocalStorageValue('credentials')) {
      const token = JSON.parse(getLocalStorageValue('credentials'));
      const refresh_token: any = token.refresh_token;
      const payload = {
        tenantName: tenantName,
        clientId: envClientId,
        refreshToken: refresh_token,
      };
      this.loaderService.show();
      /* istanbul ignore next */
      return this.http.post(this.api?.logOut, payload).pipe(
        map(
          (res: any) => {
            this.loaderService.hide();
            this.credentialsService.setCredentials();
            return res;
          },
          catchError((error: any) => {
            this.alertService.showToaster('Logout Failed', '', 'error');
            return throwError(error);
          })
        )
      );
    }
  }

  updateTemperoryPassword(payload: any, sessionId: any) {
    return this.http.post(this.api?.updateTempPassword + `?sessionId=${sessionId}`, payload);
  }

  getTenantList() {
    return this.http.get(this.api?.getAllTenants);
  }

  getTenantInfo(tenantName: any) {
    return this.http.get(this.api?.tenantInfo + `/${tenantName}`).pipe(pluck('result'));
  }

  getEvnByName(tenantName: any) {
    return this.http.get(`${this.api?.envByName}${tenantName}`).pipe(pluck('result'));
  }

  getIdentityProvidersList(tenantName: string) {
    this.http.get(`${this.api?.getIdentityProvidersList}${tenantName}`).subscribe((res: any) => {
      this.identityProvidersList.next(res);
    });
  }

  /** API Call for Forgot Password Serice  */

  forgotPassword(urlParamsTenantName: any) {
    urlParamsTenantName = { ...urlParamsTenantName, environments: [getLocalStorageValue('environmentType')] };
    return this.http.put(this.api?.forgotPassword, urlParamsTenantName);
  }

  forgotCustomerId(payload: any) {
    return this.http.post(this.api?.forgotCustomerId, payload);
  }

  /** API Call for Generating OTP */

  generateOtp(urlParamsTenantName: any) {
    return this.http.post( this.api?.generateOTP,urlParamsTenantName  );
  }

  validateOTP(urlParams: any){
    return this.http.post(this.api?.validateOTP, urlParams);
  }



  public getTestJSONData(): Observable<any> {
    return this.http.get('assets/testData.json');
  }

  /**
   * Sets b2b2c state
   * @param value
   */
  setB2B2Cstate(value: boolean) {
    this.isB2b2cLogin = value;
  }

  /**
   * Gets b2b2c state
   * @returns
   */
  getB2B2CState() {
    return this.isB2b2cLogin;
  }

  setBrfLoginState(value: boolean) {
    this.isBrfLogin = value;
  }

  getBrfLoginState() {
    return this.isBrfLogin;
  }

  selfSignup(payload: any) {
    if (this.onboardingTenant.includes(payload?.realm)) {
      return this.http.post(this.api?.selfonboardSignup, payload);
    } else {
      return this.http.post(this.api?.selfSignup, payload);
    }
  }
  fileOtpValidation(payload: any) {
    /*istanbul ignore next*/
    const otp = payload.otp ? payload.otp : '';
    /*istanbul ignore next*/
    const fileId = payload.fileId ? payload.fileId : '';
    return this.http.get(this.api?.fileOtpValidation + `?fileId=${fileId}&otp=${otp}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  otpSignup(payload:any) {
    return this.http.post(this.api?.selfSignupOtp ,payload);
  }


  verifySignupOtp(payload:any) {
    return this.http.post(
      this.api?.verifySignupOtp,payload
    );
  }

  createGuestUser(tenantName: string): Observable<Object> {
    const headers = new HttpHeaders().set('TenantName', tenantName);
    return this.http.post(this.api?.createGuestUser, {}, { headers: headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  validate2fa(otp: any, userName: any, tenantId: any, guestUserId?: any) {
    return this.http.get(
      this.api?.validate2FaOtp +
        `?otpNumber=${otp}&tenantName=${tenantId}&userName=${userName}&guestUserId=${guestUserId}`
    );
  }

  resend2fa(payload: any) {
    return this.http.post(this.api?.resend2faOtp ,payload);
  }
  revokeRefreshToken(tname: any, flag: any) {
    return this.http.post(this.api.refreshToken + `${tname}&refreshFlag=${flag}`, '');
  }
  getSystemUserToken() {
    return this.http.get(this.api.systemUserToken);
  }

  getcampaignGsiLogin(encryptedData: string) {
    this.loaderService.show();
    this.http.post(this.api.campaignGsiLogin, encryptedData).subscribe((res: any) => {
      if (res) {
        this.loaderService.hide();
        this.campaignGsiLoginToken.next(res);
      }
    });
  }

  generateCaptcha(key: any, tenant: any) {
    return this.api.generateCaptcha + `?key=${key}&tenantName=${tenant}`;
  }

  verifyCaptcha(captcha: any, key: any, tenant: any) {
    return this.http.get(this.api.verifyCaptcha + `?captcha=${captcha}&key=${key}&tenantName=${tenant}`);
  }

  // fetch multisession policy detail
  fetchMultiSessionPolicy() {
    return this.http.get(this.api?.multisessionPolicy + `/fetch`);
  }

  // fetch multisession policy detail
  saveMultiSessionPolicy(payload: any) {
    return this.http.post(this.api?.multisessionPolicy + `/create`, payload);
  }

  // fetch multisession policy detail
  disableMultiSessionPolicy() {
    return this.http.post(this.api?.multisessionPolicy + `/disable`, {});
  }
  setTokensExpiry(accessTokenLifespan: number, refreshTokenLifespan: number) {
    const data = {
      accessTokenLifespan: accessTokenLifespan,
      refreshTokenLifespan: refreshTokenLifespan,
    };

    return this.http.post(this.api?.setTokenExpiry+`?accessTokenLifespan=${accessTokenLifespan}&refreshTokenLifespan=${refreshTokenLifespan}`,data);
  }
  getTokenExpiry(){
    return this.http.get(this.api?.getTokenExpiry)
  }
  resetTokenExpiry(){
  return this.http.post(this.api?.resetTokenExpiry,null)
  }


  ngOnDestroy() {}
}
