import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { EditorComponent } from './editor.component';

@NgModule({
  declarations: [EditorComponent],
  imports: [ControlsMaterialModule],
  exports: [EditorComponent],
})
export class EditorModule {
  static entry = {
    editor: EditorComponent,
  };
}
