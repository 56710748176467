<div class="vertical-table" [ngClass]="{ 'tbl-comp-section': isFullScreen, 'custom-table-medium': cofiguration.tableComponentScale == 'medium',
'custom-table-small': cofiguration.tableComponentScale == 'small' }">
  <div *ngIf="!cofiguration?.isListRunDown">
    <p-table
      #vsTable
      [ngStyle]="{
        'background-color': cofiguration?.header?.style?.backgroundColor,
        color: cofiguration?.header?.style?.fontColor
      }"
      [tableStyle]="{
        'border-color': cofiguration?.gridLines?.outLine?.selected
          ? cofiguration?.gridLines?.outLine?.borderColor
          : 'none',
        'border-width': cofiguration?.gridLines?.outLine?.selected
          ? cofiguration?.gridLines?.outLine?.borderThickness + 'px'
          : '0px',
        'border-radius': cofiguration?.gridLines?.outLine?.selected
          ? cofiguration?.gridLines?.outLine?.borderRadius + 'px'
          : '0px'
      }"
      [value]="listValues"
      [columns]="selectedColumns"
      [scrollable]="true"
      [ngClass]="{
        'table-outline': cofiguration?.gridLines?.outLine?.selected
      }"
    >
      <ng-template
        pTemplate="caption"
        *ngIf="cofiguration?.description?.header?.message || cofiguration?.advanceTableFeatures?.allowExpand"
      >
        <div class="tbl-hder" *ngIf="cofiguration?.description?.header?.message">
          {{ cofiguration?.description?.header?.message }}
        </div>
        <div class="p-d-flex w-100 d-flex align-items-center justify-content-end">
          <div *ngIf="!isFullScreen && cofiguration?.advanceTableFeatures?.allowExpand">
            <a href="javascript:;" class="" (click)="zoomTbl()">
              <img src="../../../../../assets/img/ui-kits/expand-icon.svg" />
            </a>
          </div>
          <div *ngIf="isFullScreen && cofiguration?.advanceTableFeatures?.allowExpand">
            <a href="javascript:;" class="" (click)="minimizeTbl()">
              <img src="../../../../../assets/img/ui-kits/collapse-icon.svg" />
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" *ngIf="cofiguration?.advanceTableFeatures?.showLabels">
        <tr
          [ngClass]="{ 'row-outline': cofiguration?.gridLines?.rows?.selected }"
          [ngStyle]="{
            'border-top-color': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderThickness + 'px'
              : '0px',
            'border-radius': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderRadius + 'px'
              : '0px'
          }"
        >
          <th
            [ngStyle]="{
              'background-color': cofiguration?.headerBackground,
              color: cofiguration?.header?.text?.textColour,
              'font-weight': cofiguration?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': cofiguration?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': cofiguration?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': cofiguration?.header?.text?.fontSize + 'px',
              'hover:background-color': cofiguration?.header?.colorHover,
              'hover:color': cofiguration?.header?.fontHover,
              'font-family': cofiguration?.header?.text?.fontFamily,
              'text-align': cofiguration?.header?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            *ngIf="cofiguration?.advanceTableFeatures?.serialNumbers"
            class="v-table-serial f-70 cstm-z-index"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'top' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'left',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            S. No.
          </th>
          <th
            class="cstm-z-index v-table-attr f-252"
            [ngStyle]="{
              'background-color': cofiguration?.headerBackground,
              color: cofiguration?.header?.text?.textColour,
              'font-weight': cofiguration?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': cofiguration?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': cofiguration?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': cofiguration?.header?.text?.fontSize + 'px',
              'hover:background-color': cofiguration?.header?.colorHover,
              'hover:color': cofiguration?.header?.fontHover,
              'font-family': cofiguration?.header?.text?.fontFamily,
              'text-align': cofiguration?.header?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'top' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'left',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            {{ title?.length > 15 ? title.slice(0, 15) : title }}
          </th>
          <th
            [ngStyle]="{
              'background-color': cofiguration?.headerBackground,
              color: cofiguration?.header?.text?.textColour,
              'font-weight': cofiguration?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': cofiguration?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': cofiguration?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': cofiguration?.header?.text?.fontSize + 'px',
              'hover:background-color': cofiguration?.header?.colorHover,
              'hover:color': cofiguration?.header?.fontHover,
              'font-family': cofiguration?.header?.text?.fontFamily,
              'text-align': cofiguration?.header?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap
            }"
            *ngFor="let rowData of listValues; let i = index"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'top' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            <span
              class="span-minus"
              *ngIf="listValues?.length > 1 && cofiguration?.advanceTableFeatures?.hideDeleteIcon"
              (click)="deleteEntityControl(i, rowData)"
            >
              <mat-icon>delete</mat-icon>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-columns>
        <tr
          [ngClass]="{
            hide: col?.form?.value?.isHidden,
            'row-outline': cofiguration?.gridLines?.rows?.selected,
            'even-color': index % 2 != 0,
            'odd-color': index % 2 == 0
          }"
          [ngStyle]="{
            'border-top-color': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderThickness + 'px'
              : '0px',
            'border-radius': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderRadius + 'px'
              : '0px'
          }"
          *ngFor="
            let col of columns
              | paginate
                : {
                    itemsPerPage: cofiguration?.pagination?.showPagination
                      ? cofiguration?.pagination?.numberOfRows
                      : columns?.length,
                    currentPage: pageIndex,
                    totalItems: columns?.length
                  };
            let index = index
          "
        >
          <td
            [ngStyle]="{
              'background-color': index % 2 == 0 ? cofiguration?.oddRowColor : cofiguration?.evenRowColor,
              color: index % 2 == 0 ? cofiguration?.oddRow?.fontColor : cofiguration?.evenRow?.fontColor,
              'font-weight': cofiguration?.firstColumn?.text?.bold
                ? 'bold'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.bold && index % 2 != 0)
                ? 'bold'
                : 'normal',
              'font-style': cofiguration?.firstColumn?.text?.italic
                ? 'italic'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.italic && index % 2 != 0)
                ? 'italic'
                : 'normal',
              'text-decoration-line': cofiguration?.firstColumn?.text?.underLine
                ? 'underline'
                : (cofiguration?.oddRow?.text?.underLine && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.underLine && index % 2 != 0)
                ? 'underline'
                : 'none',
              'font-size':
                index % 2 == 0
                  ? cofiguration?.oddRow?.text?.fontSize + 'px'
                  : cofiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                index % 2 == 0 ? cofiguration?.oddRow?.colorHover : cofiguration?.evenRow?.colorHover,
              'hover:color': index % 2 == 0 ? cofiguration?.oddRow?.fontHover : cofiguration?.evenRow?.fontHover,
              'font-family':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.fontFamily : cofiguration?.evenRow?.text?.fontFamily,
              'text-align':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.alignment : cofiguration?.evenRow?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            *ngIf="cofiguration?.advanceTableFeatures?.serialNumbers"
            class="cstm-z-index f-70"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'left' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            {{ index + 1 }}
          </td>
          <td
            class="cstm-z-index v-table-attr f-252"
            [ngStyle]="{
              'background-color': index % 2 == 0 ? cofiguration?.oddRowColor : cofiguration?.evenRowColor,
              color: cofiguration?.firstColumn?.fontColor
                ? cofiguration?.firstColumn?.fontColor
                : index % 2 == 0
                ? cofiguration?.oddRow?.fontColor
                : cofiguration?.evenRow?.fontColor,
              'font-weight': cofiguration?.firstColumn?.text?.bold
                ? 'bold'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.bold && index % 2 != 0)
                ? 'bold'
                : 'normal',
              'font-style': cofiguration?.firstColumn?.text?.italic
                ? 'italic'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.italic && index % 2 != 0)
                ? 'italic'
                : 'normal',
              'text-decoration-line': cofiguration?.firstColumn?.text?.underLine
                ? 'underline'
                : (cofiguration?.oddRow?.text?.underLine && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.underLine && index % 2 != 0)
                ? 'underline'
                : 'none',
              'font-size':
                index % 2 == 0
                  ? cofiguration?.oddRow?.text?.fontSize + 'px'
                  : cofiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                index % 2 == 0 ? cofiguration?.oddRow?.colorHover : cofiguration?.evenRow?.colorHover,
              'hover:color': index % 2 == 0 ? cofiguration?.oddRow?.fontHover : cofiguration?.evenRow?.fontHover,
              'font-family':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.fontFamily : cofiguration?.evenRow?.text?.fontFamily,
              'text-align':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.alignment : cofiguration?.evenRow?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'left' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft',
              'column-outline': cofiguration?.gridLines?.column?.selected,
              'hide' : hidecols(col) == true
            }"
          >
            <div>
              <div>
                {{ col?.displayValue ? col?.displayValue : col?.header }}
                <span *ngIf="!col?.isOptional" class="mat-form-field-required-marker">*</span>
              </div>
              <!-- {{ col.displayValue ? (col.displayValue?.length > 3 ? col.displayValue?.slice(0, 3) + '...' : col.displayValue)
              : (col.header?.length > 3 ? col.header?.slice(0, 3) + '...' : col.header) }} -->
              <!-- <span class="tooltiptext">{{col?.displayValue ? col.displayValue : col.header}}</span> -->
            </div>
          </td>
          <td
            [ngStyle]="{
              'background-color': index % 2 == 0 ? cofiguration?.oddRowColor : cofiguration?.evenRowColor,
              color: index % 2 == 0 ? cofiguration?.oddRow?.fontColor : cofiguration?.evenRow?.fontColor,
              'font-weight': cofiguration?.firstColumn?.text?.bold
                ? 'bold'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.bold && index % 2 != 0)
                ? 'bold'
                : 'normal',
              'font-style': cofiguration?.firstColumn?.text?.italic
                ? 'italic'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.italic && index % 2 != 0)
                ? 'italic'
                : 'normal',
              'text-decoration-line': cofiguration?.firstColumn?.text?.underLine
                ? 'underline'
                : (cofiguration?.oddRow?.text?.underLine && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.underLine && index % 2 != 0)
                ? 'underline'
                : 'none',
              'font-size':
                index % 2 == 0
                  ? cofiguration?.oddRow?.text?.fontSize + 'px'
                  : cofiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                index % 2 == 0 ? cofiguration?.oddRow?.colorHover : cofiguration?.evenRow?.colorHover,
              'hover:color': index % 2 == 0 ? cofiguration?.oddRow?.fontHover : cofiguration?.evenRow?.fontHover,
              'font-family':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.fontFamily : cofiguration?.evenRow?.text?.fontFamily,
              'text-align':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.alignment : cofiguration?.evenRow?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap
            }"
            *ngFor="let rowData of listValues"
            [ngClass]="{ 'column-outline': cofiguration?.gridLines?.column?.selected ,
                          'hide' : hidecols(col) == true
                       }"
            class="list-rundown-value"
          >
            <div class="listValue-design" *ngIf="rowData[col?.header]?.attrtype == 'attribute'">
              <div
                [ngClass]="{
                  'attribte-opacity': rowData[col.header]?.isDisabled
                    ? rowData[col.header]?.isDisabled
                    : rowData[col.header]?.isNegative,
                  hide: rowData[col.header]?.isInPotentiality === false
                }"
              >
                <ng-container
                  dynamicField
                  [horizontalOptions]="true"
                  [formData]="rowData?.[col?.header]"
                  [group]="rowData?.[col?.header + 'form']"
                  [isTableConfig]="true"
                  [hideOptionLabels]="false"
                  [hideLabels]="true"
                  (dateChanged)="dateChnagedEvent($event)"
                >
                </ng-container>
              </div>
            </div>
            <!-- <ng-template #nested_entity>
              <app-nested-form
                [control]="rowData[col?.header]"
                [group]="rowData[col?.header + 'form']"
                [isInfo]="isInfo"
                [eventCUList]="eventCUList"
                (dateChangedEmit)="dateChnagedEvent($event)"
                class="inner-accordian"
              >
              </app-nested-form>
            </ng-template> -->
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" *ngIf="cofiguration?.description?.footer?.message">
        <div class="tbl-hder">
          {{ cofiguration?.description?.footer?.message }}
        </div>
      </ng-template>
    </p-table>
    <pagination-controls
      [ngClass]="
        cofiguration?.pagination?.alignment?.justify ? cofiguration?.pagination?.alignment?.justify + '-paginator' : ''
      "
      *ngIf="cofiguration?.pagination?.showPagination && pageIndex > 1"
      (pageChange)="onPageChange($event)"
    ></pagination-controls>
  </div>
  <div *ngIf="cofiguration?.isListRunDown">
    <p-table
      #listRundown
      [value]="listValues"
      [columns]="selectedColumns"
      [scrollable]="true"
      class="vertical-table"
      [ngClass]="{
        'table-outline': cofiguration?.gridLines?.outLine?.selected
      }"
      [tableStyle]="{
        'border-color': cofiguration?.gridLines?.outLine?.selected
          ? cofiguration?.gridLines?.outLine?.borderColor
          : 'none',
        'border-width': cofiguration?.gridLines?.outLine?.selected
          ? cofiguration?.gridLines?.outLine?.borderThickness + 'px'
          : '0px',
        'border-radius': cofiguration?.gridLines?.outLine?.selected
          ? cofiguration?.gridLines?.outLine?.borderRadius + 'px'
          : '0px'
      }"
    >
      <ng-template
        pTemplate="caption"
        *ngIf="cofiguration?.description?.header?.message || cofiguration?.advanceTableFeatures?.allowExpand"
      >
        <div class="tbl-hder" *ngIf="cofiguration?.description?.header?.message">
          {{ cofiguration?.description?.header?.message }}
        </div>
        <div class="p-d-flex w-100 d-flex align-items-center justify-content-end">
          <div *ngIf="!isFullScreen && cofiguration?.advanceTableFeatures?.allowExpand">
            <a href="javascript:;" class="" (click)="zoomTbl()">
              <img src="../../../../../assets/img/ui-kits/expand-icon.svg" />
            </a>
          </div>
          <div *ngIf="isFullScreen && cofiguration?.advanceTableFeatures?.allowExpand">
            <a href="javascript:;" class="" (click)="minimizeTbl()">
              <img src="../../../../../assets/img/ui-kits/collapse-icon.svg" />
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" *ngIf="!cofiguration?.advanceTableFeatures?.showLabels">
        <tr
          [ngClass]="{ 'row-outline': cofiguration?.gridLines?.rows?.selected }"
          [ngStyle]="{
            'border-top-color': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderThickness + 'px'
              : '0px',
            'border-radius': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderRadius + 'px'
              : '0px'
          }"
        >
          <th
            [ngStyle]="{
              'background-color': cofiguration?.headerBackground,
              color: cofiguration?.header?.text?.textColour,
              'font-weight': cofiguration?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': cofiguration?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': cofiguration?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': cofiguration?.header?.text?.fontSize + 'px',
              'hover:background-color': cofiguration?.header?.colorHover,
              'hover:color': cofiguration?.header?.fontHover,
              'font-family': cofiguration?.header?.text?.fontFamily,
              'text-align': cofiguration?.header?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            *ngIf="cofiguration?.advanceTableFeatures?.serialNumbers"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'top' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'left',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
            class="f-70"
          >
            S. No.
          </th>
          <th
            class="cstm-z-index v-table-attr f-252"
            [ngStyle]="{
              'background-color': cofiguration?.headerBackground,
              color: cofiguration?.header?.text?.textColour,
              'font-weight': cofiguration?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': cofiguration?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': cofiguration?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': cofiguration?.header?.text?.fontSize + 'px',
              'hover:background-color': cofiguration?.header?.colorHover,
              'hover:color': cofiguration?.header?.fontHover,
              'font-family': cofiguration?.header?.text?.fontFamily,
              'text-align': cofiguration?.header?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'top' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'left',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            {{ title }}
          </th>
          <th
            [ngStyle]="{
              'background-color': cofiguration?.headerBackground,
              color: cofiguration?.header?.text?.textColour,
              'font-weight': cofiguration?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': cofiguration?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': cofiguration?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': cofiguration?.header?.text?.fontSize + 'px',
              'hover:background-color': cofiguration?.header?.colorHover,
              'hover:color': cofiguration?.header?.fontHover,
              'font-family': cofiguration?.header?.text?.fontFamily,
              'text-align': cofiguration?.header?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            class="list-rundown-header"
            *ngFor="let rowData of listValues; let i = index"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'top' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            <span *ngFor="let value of listRundownCols" class="span-minus">
              <div class="v-table-header-customisation" *ngIf="!cofiguration?.advanceTableFeatures?.showLabels">
                {{ value?.displayValue }}
              </div>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-columns>
        <tr
          [ngStyle]="{
            'border-top-color': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderThickness + 'px'
              : '0px',
            'border-radius': cofiguration?.gridLines?.rows?.selected
              ? cofiguration?.gridLines?.rows?.borderRadius + 'px'
              : '0px'
          }"
          [ngClass]="{
            hide: col?.form?.value?.isHidden,
            'row-outline': cofiguration?.gridLines?.rows?.selected,
            'even-color': index % 2 != 0,
            'odd-color': index % 2 == 0
          }"
          *ngFor="
            let col of columns
              | paginate
                : {
                    itemsPerPage: cofiguration?.pagination?.showPagination
                      ? cofiguration?.pagination?.numberOfRows
                      : columns?.length,
                    currentPage: pageIndex,
                    totalItems: columns?.length
                  };
            let index = index
          "
        >
          <td
            [ngStyle]="{
              'background-color': index % 2 == 0 ? cofiguration?.oddRowColor : cofiguration?.evenRowColor,
              color: index % 2 == 0 ? cofiguration?.oddRow?.fontColor : cofiguration?.evenRow?.fontColor,
              'font-weight': cofiguration?.firstColumn?.text?.bold
                ? 'bold'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.bold && index % 2 != 0)
                ? 'bold'
                : 'normal',
              'font-style': cofiguration?.firstColumn?.text?.italic
                ? 'italic'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.italic && index % 2 != 0)
                ? 'italic'
                : 'normal',
              'text-decoration-line': cofiguration?.firstColumn?.text?.underLine
                ? 'underline'
                : (cofiguration?.oddRow?.text?.underLine && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.underLine && index % 2 != 0)
                ? 'underline'
                : 'none',
              'font-size':
                index % 2 == 0
                  ? cofiguration?.oddRow?.text?.fontSize + 'px'
                  : cofiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                index % 2 == 0 ? cofiguration?.oddRow?.colorHover : cofiguration?.evenRow?.colorHover,
              'hover:color': index % 2 == 0 ? cofiguration?.oddRow?.fontHover : cofiguration?.evenRow?.fontHover,
              'font-family':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.fontFamily : cofiguration?.evenRow?.text?.fontFamily,
              'text-align':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.alignment : cofiguration?.evenRow?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap
            }"
            *ngIf="cofiguration?.advanceTableFeatures?.serialNumbers"
            class="f-70"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'left' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            {{ index + 1 }}
          </td>
          <td
            class="cstm-z-index v-table-attr f-252"
            [ngStyle]="{
              'background-color': index % 2 == 0 ? cofiguration?.oddRowColor : cofiguration?.evenRowColor,
              color: index % 2 == 0 ? cofiguration?.oddRow?.fontColor : cofiguration?.evenRow?.fontColor,
              'font-weight': cofiguration?.firstColumn?.text?.bold
                ? 'bold'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.bold && index % 2 != 0)
                ? 'bold'
                : 'normal',
              'font-style': cofiguration?.firstColumn?.text?.italic
                ? 'italic'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.italic && index % 2 != 0)
                ? 'italic'
                : 'normal',
              'text-decoration-line': cofiguration?.firstColumn?.text?.underLine
                ? 'underline'
                : (cofiguration?.oddRow?.text?.underLine && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.underLine && index % 2 != 0)
                ? 'underline'
                : 'none',
              'font-size':
                index % 2 == 0
                  ? cofiguration?.oddRow?.text?.fontSize + 'px'
                  : cofiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                index % 2 == 0 ? cofiguration?.oddRow?.colorHover : cofiguration?.evenRow?.colorHover,
              'hover:color': index % 2 == 0 ? cofiguration?.oddRow?.fontHover : cofiguration?.evenRow?.fontHover,
              'font-family':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.fontFamily : cofiguration?.evenRow?.text?.fontFamily,
              'text-align':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.alignment : cofiguration?.evenRow?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap,
              'border-right-color': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderColor
                : 'None',
              'border-right-width': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderThickness + 'px'
                : '0px',
              'border-radius': cofiguration?.gridLines?.column?.selected
                ? cofiguration?.gridLines?.column?.borderRadius + 'px'
                : '0px'
            }"
            [ngClass]="{
              'freeze-element':
                cofiguration?.headerFreeze?.tableFreezePosition == 'left' ||
                cofiguration?.headerFreeze?.tableFreezePosition == 'topLeft',
              'column-outline': cofiguration?.gridLines?.column?.selected
            }"
          >
            <div>
              {{ col?.displayValue ? col?.displayValue : col?.header }}
              <span *ngIf="!col.isOptional" class="mat-form-field-required-marker">*</span>
              <!-- <span class="tooltiptext">{{col?.displayValue ? col.displayValue : col.header}}</span> -->
            </div>
          </td>
          <td
            [ngStyle]="{
              'background-color': index % 2 == 0 ? cofiguration?.oddRowColor : cofiguration?.evenRowColor,
              color: index % 2 == 0 ? cofiguration?.oddRow?.fontColor : cofiguration?.evenRow?.fontColor,
              'font-weight': cofiguration?.firstColumn?.text?.bold
                ? 'bold'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.bold && index % 2 != 0)
                ? 'bold'
                : 'normal',
              'font-style': cofiguration?.firstColumn?.text?.italic
                ? 'italic'
                : (cofiguration?.oddRow?.text?.bold && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.italic && index % 2 != 0)
                ? 'italic'
                : 'normal',
              'text-decoration-line': cofiguration?.firstColumn?.text?.underLine
                ? 'underline'
                : (cofiguration?.oddRow?.text?.underLine && index % 2 == 0) ||
                  (cofiguration?.evenRow?.text?.underLine && index % 2 != 0)
                ? 'underline'
                : 'none',
              'font-size':
                index % 2 == 0
                  ? cofiguration?.oddRow?.text?.fontSize + 'px'
                  : cofiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                index % 2 == 0 ? cofiguration?.oddRow?.colorHover : cofiguration?.evenRow?.colorHover,
              'hover:color': index % 2 == 0 ? cofiguration?.oddRow?.fontHover : cofiguration?.evenRow?.fontHover,
              'font-family':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.fontFamily : cofiguration?.evenRow?.text?.fontFamily,
              'text-align':
                index % 2 == 0 ? cofiguration?.oddRow?.text?.alignment : cofiguration?.evenRow?.text?.alignment,
              'text-wrap': cofiguration?.advanceTableFeatures?.attributeWrap
            }"
            [ngClass]="{ 'column-outline': cofiguration?.gridLines?.column?.selected }"
            *ngFor="let rowData of listValues"
          >
            <div class="listValue-design" *ngIf="rowData[col?.header]?.attrtype == 'attribute'">
              <div
                [ngClass]="{
                  'attribte-opacity': rowData[col.header]?.isDisabled
                    ? rowData[col.header]?.isDisabled
                    : rowData[col.header]?.isNegative
                }"
              >
                <ng-container
                  dynamicField
                  [horizontalOptions]="true"
                  [formData]="rowData[col?.header]"
                  [group]="rowData[col?.header + 'form']"
                  [isTableConfig]="true"
                  [hideOptionLabels]="!cofiguration?.advanceTableFeatures?.showLabels"
                  [hideLabels]="true"
                  (dateChanged)="dateChnagedEvent($event)"
                >
                </ng-container>
              </div>
            </div>
            <!-- <ng-template #nested_entity>
              <app-nested-form
                [control]="rowData?.[col?.header]"
                [group]="rowData?.[col?.header + 'form']"
                [isInfo]="isInfo"
                [eventCUList]="eventCUList"
                (dateChangedEmit)="dateChnagedEvent($event)"
                class="inner-accordian"
              >
              </app-nested-form>
            </ng-template> -->
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" *ngIf="cofiguration?.description?.footer?.message">
        <div class="tbl-hder">
          {{ cofiguration?.description?.footer?.message }}
        </div>
      </ng-template>
    </p-table>
    <pagination-controls
      [ngClass]="
        cofiguration?.pagination?.alignment?.justify ? cofiguration?.pagination?.alignment?.justify + '-paginator' : ''
      "
      *ngIf="cofiguration?.pagination?.showPagination && pageIndex > 1"
      (pageChange)="onPageChange($event)"
    ></pagination-controls>
  </div>
</div>
