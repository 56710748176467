import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TemplatesBaseComponent } from '../templates-base/templates-base.component';
import { GsiFacadeService, AlertService, TransactionFacadeService, EntityBoardEndpointService } from '@common-services';

@Component({
  selector: 'app-circular-template',
  templateUrl: './circular-template.component.html',
  styleUrls: ['./circular-template.component.scss']
})
export class CircularTemplateComponent extends TemplatesBaseComponent {
  constructor(    
    alertService: AlertService,
  ) {
    super(    
      alertService, 
    );
  }
    @Input() set circularData(Value:any){
      if(Value.length>0){
        this.createData(Value);
      }
    };
    @ViewChild('mainCircle') mainCircle:ElementRef;
    @ViewChild('attributeCircle') attributeCircle:ElementRef;
    i = 1;
    timer: any;
    mainCircleAngle: any;
    attributeCircleAngle:any;
    showExpandedTree: boolean = false;
    dummyArray :any  = []; 
    attributeValues: any = [];
    level1: any = [];
    timeBoolean: boolean = false;
    /* istanbul ignore next */
    createAnglesAndWidth(){     
      // todo need to remove set timeout
      setTimeout(() => {       
        this.mainCircleAngle = this.returnAngle(this.mainCircle,'.itemDot1',2);
        this.attributeCircleAngle = this.returnAngle(this.attributeCircle,'.itemDot2',1.5);
        if(this.mainCircleAngle && this.attributeCircleAngle ){
          this.resumeRotation();
        }},200);     
      
    }
  createData(Value: any) {
    Value.forEach((attr: any) => {
      let temp = this.attributeValues;
      attr.recordData.forEach((record: any) => {
        let flag = false;
        if (temp.length > 0) {
          temp.forEach((re: any) => {
            if (re.value == record.value) {
              re.count += 1;
              temp = re.relatedRecords;
              flag = true
            }
          });
          if (!flag && record.attributeName == temp?.[0]?.name && record.value != 'none') {

            let temp1 = this.pushRelatedRecord(temp, record.attributeName, record.value);
            temp.push(temp1);
            temp = temp1.relatedRecords
          }

        } else if (temp.length == 0 && record.value != 'none') {
          let temp1 = this.pushRelatedRecord(temp, record.attributeName, record.value);
          if (temp.relatedRecords) temp.relatedRecords.push(temp1)
          else temp.push(temp1);
          temp = temp1.relatedRecords
        }

      });
    });
    this.createAnglesAndWidth();
  }
    returnAngle(elementRef: ElementRef,className: string,number:any){
      let fields = elementRef?.nativeElement?.querySelectorAll(className);
      let width =  elementRef?.nativeElement.offsetWidth;
      let height =  elementRef?.nativeElement.offsetHeight;
      let radius = width / number;  
      let tempAngle = 0;
      let  step = (2 * Math.PI) / fields?.length;
      fields?.forEach((attr:any)=>{
        var x = Math.round(width / 2 + radius * Math.cos(tempAngle) - attr.offsetWidth / 2);
        var y = Math.round(height / 2 + radius * Math.sin(tempAngle) - attr.offsetHeight / 2);
       attr.style.left = y + 'px';
       attr.style.top = x + 'px';
       tempAngle += step;
      });
      return tempAngle;
    }
    rotateCircles() {
      if(this.mainCircle && this.attributeCircle){
        this.i++;
        this.mainCircle.nativeElement.style.transform =  "rotate(" + this.i + "deg)";
        this.mainCircle.nativeElement.style.transition = "300ms all linear";
        this.attributeCircle.nativeElement.style.transform =  "rotate(" + this.i + "deg)";
        this.attributeCircle.nativeElement.style.transition = "300ms all linear";
        this.attributeCircle.nativeElement.querySelectorAll('.itemDot2').forEach((attr:any)=>{
          attr.style.transform = "rotate(-" + this.i + "deg)";
         attr.style.transition = "300ms all linear";
        });
       
      }
     
    }
  
  stopRotation() {
    if (this.timeBoolean) {
      clearInterval(this.timer);
      this.timeBoolean = false;
    }

  }

    resumeRotation() {
      if(!this.timeBoolean){
        this.timer = setInterval(() => {
          this.rotateCircles();
        }, 100);
        this.timeBoolean = true
      }
    }

  pushRelatedRecord(map: any, name: string, value: any) {
    let req: { name: string; value: any, count: number, relatedRecords: any[] } = {
      name: name,
      value: value,
      count: 1,
      relatedRecords: [],
    }
    return req;
  }
  toogleCircle(isCircle:any){
    this.showExpandedTree = true;
    this.level1=isCircle;
    this.createAnglesAndWidth();
    this.stopRotation(); 
  }
 
}

  