import { Component } from '@angular/core';

export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: Element[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
];

@Component({
  selector: 'app-table',
  template: `
    <div class="datatypes-table">
      <div class="example-container">
        <mat-table [dataSource]="dataSource">
          <!-- Position Column -->
          <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
            <mat-header-cell *cdkHeaderCellDef>{{ column.header }}</mat-header-cell>
            <mat-cell *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  `,
  styles: [
    'example-container { display: flex; flex-direction: column; max-height: 500px; min-width: 300px; }',

    'mat-table { overflow: auto; max-height: 300px; }',
  ],
})
export class TableComponent {
  /* istanbul ignore next */
  columns = [
    { columnDef: 'position', header: 'No.', cell: (element: Element) => `${element.position}` },
    { columnDef: 'name', header: 'Name', cell: (element: Element) => `${element.name}` },
    { columnDef: 'weight', header: 'Weight', cell: (element: Element) => `${element.weight}` },
    { columnDef: 'symbol', header: 'Symbol', cell: (element: Element) => `${element.symbol}` },
  ];

  displayedColumns = this.columns.map((c) => c.columnDef);
  dataSource = ELEMENT_DATA;
}
