import { Component, OnInit } from '@angular/core';
import { InsuranceEcommerceExpandBase } from '../insurance-ecommerce-expand-base-component';
import { AlertService, EntityBoardEndpointService} from '@common-services';

@Component({
  selector: 'app-expand-insurance-templates',
  templateUrl: './expand-insurance-templates.component.html',
  styleUrls: ['./expand-insurance-templates.component.scss'],
})
export class ExpandInsuranceTemplatesComponent extends InsuranceEcommerceExpandBase {
  constructor(alertService: AlertService, entityBoardService: EntityBoardEndpointService) {
    super(alertService, entityBoardService);
  }
}
