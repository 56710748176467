import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'app-fusion-charts',
  templateUrl: './fusion-charts.component.html',
  styleUrls: ['./fusion-charts.component.scss'],
})
export class FusionChartsComponent implements OnInit {
  @ViewChild('chart-container', { read: ViewContainerRef, static: true }) container1: ViewContainerRef;
  @Input() chartType: any;
  @Input() data: any;
  @Input() width: any = 540;
  @Input() height: any = 240;

  constructor(public cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
