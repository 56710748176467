<div mat-dialog-title>
  <button class="nh-unsetAll cursor-pointer close-btn-modal" [mat-dialog-close]="true">
    <em class="nh-icon nh-icon-x-lg nh-fw-700"></em>
  </button>
</div>
<mat-dialog-content>
  <!-- Hi Guys welcome to my channel -->
  <div *ngIf="enableTools">
    <ng-container *ngIf="viewerProvider">
      <div *ngIf="viewerProvider?.playClip" style="margin-bottom: 16px; display: flex; justify-content: center;">
        <button style="margin-right: 16px;" mat-mini-fab color="primary" (click)="play(10)">
          <mat-icon>play_arrow</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="stop()">
          <mat-icon>stop</mat-icon>
        </button>
      </div>
      <div class="dicom-buttons-div" *ngIf="viewerProvider?.element">
        <div *ngFor="let tool of config.tools">
          <button
            class="dicom-buttons"
            mat-raised-button
            (click)="activateTool(tool.name)"
            [color]="isActive(tool) ? 'primary' : 'black'"
          >
            {{ tool.name }}
          </button>
        </div>
      </div>
      <!-- <div style="display: flex; justify-content: center; margin-bottom: 16px">
              <button style="margin-right: 8px"
                      mat-raised-button
                      color="accent"
                      (click)="exportStateToJson()"
              >Export tools state to JSON file</button>
              <button mat-raised-button
                      color="accent"
                      (click)="saveAs()"
              >Save as PNG</button>
          </div> -->
    </ng-container>
    <div style="display: flex; justify-content: center;">
      <app-nsl-dicom-operation [config]="config" (initialized)="initializeProvider($event)"></app-nsl-dicom-operation>
    </div>
  </div>
</mat-dialog-content>
