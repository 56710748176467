import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-special-features',
  templateUrl: './node-special-features.component.html',
  styleUrls: ['./node-special-features.component.scss'],
})
export class NodeSpecialFeaturesComponent implements OnInit, OnDestroy {
  @Input() selectedSf: any;
  @Input() specialFeatureIndex: number = -1;
  @Input() participatingLayer: string;
  @Input() attribute: any;
  @Input() gsiData: any;
  @Input() cuIndex: number;
  @Input() entity: any;
  @Input() slotIndex: number;
  @Input() submitSfForm: boolean;
  @Input() sourceData: any;
  @Input() selectedEventType: any;

  @Output() payLoadEmitter = new EventEmitter<any>();
  @Output() sendReserveEnt = new EventEmitter<any>();
  @Input() initialPayLoad: any;
  changeUnit: any;
  specialFeature: any;
  specialFeaturesFormGroup: FormGroup;
  vantagePoints: string[] = [];
  properties: any[] = [];
  isMulti: boolean = false;
  ngUnsubscribe = new Subject();
  targetRequired: boolean = false;
  autoProp: string[] = ['targetContextualId', 'targetContextualName', 'sourceContextualId', 'sourceContextualName'];
  dataMap = {
    string: 'text',
    number: 'number',
  };
  queryFields = [
    { name: 'attributeName', display: 'Attribute_Name' },
    { name: 'queryOperator', display: 'Query_Operator' },
    { name: 'attributeValue', display: 'Attribute_Value' },
    { name: 'nslQuery', display: 'NSL_Query' },
    { name: 'queryCondition', display: 'NSL_Query_Condition' },
    { name: 'predicate', display: 'Predicate' },
  ];
  sourceContextualId: string;
  targetContextualId: string;
  targetContextualName: string;
  sourceContextualName: string;
  payLoad: any;
  displaySourceEdit: boolean = true;
  displayTargetEdit: boolean = false;
  showTarget: boolean = false;
  query1 = {
    attributeName: '',
    queryOperator: '',
    attributeValue: '',
    nslQuery: '',
    queryCondition: '',
    predicate: '',
  };
  ccdRequest: any = {
    name: 'NSL_Query',
    dsdId: '',
    transEntityRecords: [
      {
        txnNslAttribute: [],
      },
    ],
  };
  transEntityRecords: any = [];
  otherInfo: any;
  labels: any;
  isGrammar: boolean = false;
  noOfTransactionFlag: boolean = false;
  valueChangesSubscription: any;

  sourceValueNameMlr: any = [];
  sourceValueIdMlr: any = [];
  sourceValueMlr: any = [];
  sourceValue: string;
  durationMinValue: number = 0;
  inPotentialityEnt: any;
  inPotentialityAtt: any;
  inRealityEnt: any;
  inRealityAtt: any;
  triggerCuEntities: any = [];
  triggerCuAttributes: any = [];
  potentialslotIds: any = '';
  realityslotIds: any = '';
  showRealitySlotItems: boolean = false;
  showPotentialSlotItems: boolean = false;
  exceptQueryCondSFs: any = ['cuclock', 'quantification', 'distance'];
  dialogRef: MatDialogRef<any, any>;
  constructor(
    private specialfeatureFacade: NodeEntityFacadeService,
    private translator: TranslatorService,
    public dialog: MatDialog,
    public alertService: AlertService,
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    let varname = this.participatingLayer + this.entity?.name + this.attribute?.name;
    /* istanbul ignore next */
    if (this.initialPayLoad && this.initialPayLoad[varname]) {
      this.payLoad = {
        [varname]: {
          ...this.initialPayLoad[varname],
        },
      };
    }
    this.findtheSpecialFeature();
    this.changeUnit = this.gsiData.solutionLogic[this.cuIndex];
    this.triggerSfInGrammar(this.selectedSf);
    this.changeDetectForForm();
    this.detectTriggerCuData();

  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  changeDetectForForm() {
    this.valueChangesSubscription = this.specialFeaturesFormGroup?.controls['calculationBasedOn']?.valueChanges
      .pipe(debounceTime(500))
      .subscribe((newValue: any) => {
        if (this.specialFeaturesFormGroup.controls['calculationBasedOn'].value == 'Based on number of transactions') {
          this.noOfTransactionFlag = true;
          this.specialFeaturesFormGroup.patchValue({
            duration: '',
            durationFactor: '',
            tillNow: '',
          });
        } else {
          this.noOfTransactionFlag = false;
          this.specialFeaturesFormGroup.patchValue({
            maxLimit: null,
          });
        }
      });
  }

  triggerSfInGrammar(sf: any) {
    this.selectedSf = sf;
    this.sfInGrammar(sf);
    this.isGrammar = true;
    this.changeDetectForForm();
    // if (this.submitSfForm) {
    //   this.triggerGeneratePayloadGrammar();
    // }
  }
  sfInGrammar(selectSf: any) {
    this.specialFeature = selectSf;
    /* istanbul ignore next */
    this.properties = this.specialFeature?.properties;
    this.properties.forEach((property: any) => {
      if (property.name == 'potentialSlotItems') {
        this.showPotentialSlotItems = true;
      }
      if (property.name == 'realitySlotItems') {
        this.showRealitySlotItems = true;
      }
    });
    /* istanbul ignore next */
    this.vantagePoints = this.vantagePointsArray(this.specialFeature?.vantagePoint);
    /* istanbul ignore next */

    this.otherInfo = {
      entityId: this.entity?.id,
      tfentityId: this.entity?.tfId,
      attribute: this.attribute,
      layer: this.participatingLayer,
      entityAttributeKey: this.entity?.name + '.' + this.attribute?.name,
    };
    this.createFormGroup();
  }

  findtheSpecialFeature() {
    this.specialfeatureFacade.specialFeature$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res.result) {
        this.specialFeature = res.result[this.specialFeatureIndex];
        this.properties = this.specialFeature.properties;
        this.vantagePoints = this.vantagePointsArray(this.specialFeature?.vantagePoint);
        this.otherInfo = {
          entityId: this.entity.id,
          tfentityId: this.entity.tfId,
          attribute: this.attribute,
          layer: this.participatingLayer,
          entityAttributeKey: this.entity?.name + '.' + this.attribute?.name,
        };
        this.createFormGroup();
      }
    });
  }

  createFormGroup() {
    let formgroup: any = {};
    /* istanbul ignore next */
    this.properties?.forEach((property: any) => {
      if (property?.name === 'isMultipleSource') {
        this.isMulti = true;
      }
      if (property?.name != 'targetContextualName') {
        this.targetRequired = true;
      }
      if (property?.name != 'sourceContextualName' && property?.name != 'targetContextualName') {
        formgroup[property?.name] = new FormControl();
        if (property?.name == 'eventType') {
          formgroup[property?.name].setValue(this.selectedEventType);
        } else {
          formgroup[property?.name].setValue('');
        }
      }
      if (property?.name == 'sourceContextualName') {
        // this.showTarget = true;
        this.displaySource();
      }
      if (property?.name == 'targetContextualName') {
        this.showTarget = true;
        this.displayTarget();
      }
    });

    formgroup = {
      ...formgroup,
      gsiId: new FormControl(),
      masterId: new FormControl(),
      specialFeatureType: new FormControl(),
      slotIndex: new FormControl(),
    };
    this.specialFeaturesFormGroup = new FormGroup(formgroup);
    // this.populateSource();
    this.loadExisitingPayload();
  }

  populateSource() {
    this.generateSourceContextualInfo(
      this.sourceData?.vantagePoint,
      this.sourceData?.data,
      this.sourceData?.layer,
      this.sourceData?.slotIndex
    );
  }

  loadExisitingPayload() {
    let varname = this.participatingLayer + this.entity?.name + this.attribute?.name;
    /* istanbul ignore next */
    if (this.payLoad && this.payLoad[varname]) {
      let formgroup: any = {};
      this.properties?.forEach((property: any) => {
        if (property?.name != 'targetContextualName') {
          this.targetRequired = true;
        }
        if (property?.name != 'sourceContextualName' && property?.name != 'targetContextualName') {
          formgroup[property?.name] = this.payLoad[varname].props[property?.name];
        }
      });
      this.sourceContextualId = this.payLoad[varname]?.props?.sourceContextualId;
      this.sourceContextualName = this.payLoad[varname]?.props?.sourceContextualName;
      this.targetContextualId = this.payLoad[varname]?.props?.targetContextualId;
      this.targetContextualName = this.payLoad[varname]?.props?.targetContextualName;
      this.sourceValue = this.payLoad[varname].props?.sourceValue;
      if (this.targetContextualName) {
        this.displayTargetEdit = false;
      }
      this.specialFeaturesFormGroup.patchValue(formgroup);
    }
    if (this.specialFeaturesFormGroup?.controls['calculationBasedOn']?.value == 'Based on number of transactions') {
      this.noOfTransactionFlag = true;
    }
    if (this.specialFeature.name === 'multiLinearRegression') {
      let source = this.sourceValue?.split(',');
      let sourceName = this.sourceContextualName?.split(',');
      let sourceId = this.sourceContextualId?.split(',');
      if (source) {
        let temp = [];
        for (let sourveValuePair of source) {
          this.sourceValueMlr.push(sourveValuePair?.split('=')[1]);
          this.sourceValueIdMlr.push(sourveValuePair?.split('=')[0]);
        }
        for (let i = 0; i < this.sourceValueIdMlr?.length; i++) {
          if (sourceName[i]&&sourceId[i]) {
            this.sourceValueNameMlr?.push(sourceName[i]);
          }
        }
      }
    }
  }

  // triggerGeneratePayloadGrammar() {
  //   this.specialfeatureFacade.sfGrammarSave$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
  //     if (res) {
  //       this.generatePayload();
  //     }
  //   });
  // }

  generatePayload() {
    /*ignore istanbul next*/
    if (!this.specialFeaturesFormGroup?.valid) {
      this.alertService.showToaster("Special Feature Configuration can't be Created", '', 'error');
      return;
    } else {
      this.alertService.showToaster('Special Feature Configuration Created', '', 'success');
    }
    let varname = this.participatingLayer + this.entity?.name + (this.attribute ? this.attribute?.name : '');
    this.specialFeaturesFormGroup.patchValue({
      sourceContextualId: this.sourceContextualId,
      targetContextualId: this.targetContextualId,
      // specialFeatureType: this.specialFeature.name,
      // gsiId: this.gsiData.id,
      // masterId: this.gsiData.masterId,
      // slotIndex: this.slotIndex,
    });
    if (!this.exceptQueryCondSFs.includes(this.selectedSf.name) && this.query1.attributeName != '') {
      this.exceptQueryCondSFs.includes(this.selectedSf.name);
      this.createNslQueryJson();
    }
    this.payLoad = {
      [varname]: {
        participatingLayer: this.participatingLayer,
        entityAttributeKey: this.entity?.name + (this.attribute ? '.' + this.attribute?.name : ''),
        slotIndex: this.slotIndex,
        attributeType: this.attribute?.attributeType?.type,
        props: {
          // gsiId: this.gsiData.tfId,
          // masterId: this.gsiData.masterId,
          ...this.specialFeaturesFormGroup.value,
          targetContextualName: this.targetContextualName,
          sourceContextualName: this.sourceContextualName,
          sourceValue: this.specialFeaturesFormGroup.value.sourceValue
            ? this.specialFeaturesFormGroup.value.sourceValue
            : this.sourceValue,
            potentialSlotItems: [],
            realitySlotItems: [],
        },
      },
    };
     /*istanbul ignore if */
    if (!this.exceptQueryCondSFs.includes(this.selectedSf.name)) {
      let value :any;
      if(this.ccdRequest?.transEntityRecords[0]?.txnNslAttribute.length == 0)
        value = null ;
      else
      value = JSON.stringify(this.ccdRequest)
      this.payLoad[varname].props['queryCondition'] = value;
    }
     /*istanbul ignore else */
     if (this.showPotentialSlotItems) {
      this.payLoad[varname].props['potentialSlotItems'] = this.potentialslotIds;
    }
    else {
      delete this.payLoad[varname].props['potentialSlotItems'];
    }
    /*istanbul ignore else */
    if (this.showRealitySlotItems) {
      this.payLoad[varname].props['realitySlotItems'] = this.realityslotIds;
    }
    else{
      delete this.payLoad[varname].props['realitySlotItems'];
    }
    this.payLoadEmitter.emit(this.payLoad);
    this.submitSfForm = false;
  }

  generateSourceContextualInfo(vantagePointType: string, data?: any, layer?: any, slotIndex?: number): void {
    let srcCtId: string = 'GS' + this.gsiData.tfId;
    /* istanbul ignore else */
    if (!this.isMulti) {
      this.sourceContextualName = '';
      this.sourceContextualId = '';
    }
    let layerType:any='.PL';
    if(layer?.type){
    layerType = layer.type=='physical'? '.PL':'.IL';
    }
    if (vantagePointType == 'GSI') {
      /* istanbul ignore next */
      this.sourceContextualName = this.sourceContextualName
        ? this.sourceContextualName + ',' + this.gsiData?.name
        : this.gsiData?.name;
      let sourcectxId = 'GS' + this.gsiData?.id;
      this.sourceContextualId = this.sourceContextualId ? this.sourceContextualId + ',' + sourcectxId : sourcectxId;
    }
    if (vantagePointType == 'CU') {
      /* istanbul ignore next */
      this.sourceContextualName = this.sourceContextualName
        ? this.sourceContextualName + ',' + this.gsiData?.name + '.' + data?.cu?.name + ':' + data?.cu?.index
        : this.gsiData?.name + '.' + data?.cu?.name + ':' + data?.cu?.index;
      let sourcectxId = 'GS' + this.gsiData?.id + '.CU' + data?.cu?.tfId + '_' + data?.cu?.tfReferencedChangeUnit;
      this.sourceContextualId = this.sourceContextualId ? this.sourceContextualId + ',' + sourcectxId : sourcectxId;
    }
    if (vantagePointType == 'EN') {
      /* istanbul ignore next */
      let sourcetxName =
        this.gsiData?.name +
        '.' +
        data?.cu?.name +
        ':' +
        data?.cu?.index +
        '.' +
        layer?.type +
        '.' +
        'slot:' +
        (slotIndex + 1) +
        '.' +
        data?.en?.name;
      this.sourceContextualName = this.sourceContextualName
        ? this.sourceContextualName + ',' + sourcetxName
        : sourcetxName;
      let sourcectxId =
        'GS' +
        this.gsiData.id +
        '.CU' +
        data?.cu?.tfId +
        '_' +
        data?.cu?.tfReferencedChangeUnit +
        layerType +
        '.SL00' +
        (slotIndex + 1) +
        '.EN' +
        data?.en?.tfId;
      this.sourceContextualId = this.sourceContextualId ? this.sourceContextualId + ',' + sourcectxId : sourcectxId;
      if (this.specialFeature.name === 'multiLinearRegression')
         this.generateSourceValue(data,layer,slotIndex,layerType);
    } else if (vantagePointType == 'AT') {
      /* istanbul ignore next */
      let sourcetxName = this.generateSourceTxName(data,layer,slotIndex);
      this.sourceContextualName = this.sourceContextualName
        ? this.sourceContextualName + ',' + sourcetxName
        : sourcetxName;
      let sourcectxId = this.generateSourceTxId(data,layer,slotIndex,layerType);
      this.sourceContextualId = this.sourceContextualId ? this.sourceContextualId + ',' + sourcectxId : sourcectxId;
      if (this.specialFeature.name === 'multiLinearRegression') {
        this.generateMLR(sourcetxName,sourcectxId);
      }
    }
    this.displaySourceEdit = false;
  }
  generateTargetContextualInfo(vantagePointType: string, data: any, layer?: any, slotIndex?: number): void {
    let tarId: string = 'GS' + this.gsiData.tfId;
    /* istanbul ignore else */
    if (!this.isMulti) {
      this.targetContextualName = '';
      this.targetContextualId = '';
    }
    if (vantagePointType == 'CU') {
      /* istanbul ignore next */
      this.targetContextualName = this.gsiData?.name + '.' + data?.cu.name + ':' + data?.cu?.index;
      this.targetContextualId =
        'GS' + this.gsiData?.id + '.CU' + data?.cu?.tfId + '_' + data?.cu?.tfReferencedChangeUnit;
    } else if (vantagePointType == 'EN') {
      /* istanbul ignore next */
      let targettxName =
        this.gsiData.name +
        '.' +
        data?.cu?.name +
        ':' +
        data?.cu?.index +
        '.' +
        layer?.type +
        '.' +
        'slot:' +
        (slotIndex + 1) +
        '.' +
        data?.en?.name;
        this.targetContextualName = this.targetContextualName ? this.targetContextualName + ',' + targettxName : targettxName;
     let targettxId =
        'GS' +
        this.gsiData?.id +
        '.CU' +
        data?.cu?.tfId +
        '_' +
        data?.cu?.tfReferencedChangeUnit +
        '.PL.SL00' +
        (slotIndex + 1) +
        '.EN' +
        data?.en?.tfId;
        this.targetContextualId =  this.targetContextualId ? this.targetContextualId + ',' + targettxId : targettxId;

    } else if (vantagePointType == 'AT') {
      /* istanbul ignore next */
      let targettxName =
        this.gsiData.name +
        '.' +
        data?.cu?.name +
        ':' +
        data?.cu?.index +
        '.' +
        layer?.type +
        '.' +
        'slot:' +
        (slotIndex + 1) +
        '.' +
        data?.en?.name +
        '.' +
        data?.att?.name;
        this.targetContextualName = this.targetContextualName ? this.targetContextualName + ',' + targettxName : targettxName;
        let targettxId  =
        'GS' +
        this.gsiData?.id +
        '.CU' +
        data?.cu?.tfId +
        '_' +
        data?.cu?.tfReferencedChangeUnit +
        '.PL.SL00' +
        (slotIndex + 1) +
        '.EN' +
        data?.en?.tfId +
        '.AT' +
        data?.att?.tfId;
        this.targetContextualId =  this.targetContextualId ? this.targetContextualId + ',' + targettxId : targettxId;
    }
    this.displayTargetEdit = false;
  }
  displaySource() {
    this.displaySourceEdit = true;
  }
  displayTarget() {
    this.displayTargetEdit = true;
  }
  getQueryCondition($event: any) {
    this.query1 = $event.query;
  }
  createNslQueryJson() {
    this.transEntityRecords = this.query1;
    this.convertFromToTransEntity();
  }
  convertFromToTransEntity() {
    this.setEntityId();
    var query = this.transformQueryRecords(this.transEntityRecords, this.queryFields, 0);
    this.ccdRequest.transEntityRecords[0].txnNslAttribute.push(this.extractSubEntityId('Query_Condition', query));
  }

  extractSubEntityId(name: string, input: any) {
    return {
      name: name,
      dsdId: '60a39612cb515dbbdeb8eafa',
      values: [null] as any[],
      properties: ['CONSTANT'],
      txnGeneralEntity: { name: name, dsdId: '60a39612cb515dbbdeb8eae9', transEntityRecords: input },
    };
  }
  setEntityId() {
    let entityId = this.changeUnit?.layers[0]?.participatingItems[0]?.id;
    this.ccdRequest.dsdId = entityId;
  }
  transformQueryRecords(transEntityRecords: any, fields: any, entityIndex: number) {
    let main: any = [];
    /* istanbul ignore next */
    transEntityRecords?.forEach((record: any) => {
      let tempEnt: any = { txnNslAttribute: [] };
      fields?.forEach((field: any, i: any) => {
        let rec = this.generateTransEntityRecord(field, i, record[field.name], entityIndex);
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    });
    return main;
  }
  generateTransEntityRecord(field: any, index: number, value: string, entityIndex: number) {
    let physicalLayer = this.findLayer('physical');

    let rec: any = {
      name: field.display,
      dsdId: physicalLayer?.participatingItems[0]?.nslAttributes[entityIndex]?.dsdId,
      values: [],
    };
    /* istanbul ignore next */
    if (value) {
      rec.values.push(value);
    }
    return rec;
  }
  findLayer(type: string) {
    return this.changeUnit?.layers?.find((layer: any) => layer.type == type);
  }
  // to reset source and target
  resetSourceTarget() {
    this.sourceContextualName = null;
    this.sourceContextualId = null;
    this.targetContextualName = null;
    this.targetContextualId = null;
    this.displaySourceEdit = true;
    this.displayTargetEdit = true;
    this.specialFeaturesFormGroup.reset();
  }
  isReservedCheck(name: any, showinfoReserve: TemplateRef<any>) {
    /*istanbul ignore else */
    if (name == 'reservedEntityName') {
      this.dialogRef = this.dialog.open(showinfoReserve, { width: '550px' });
    }
  }
  closeReserveEntPanel() {
    this.dialogRef.close();
  }
  addReserveEnt() {
    /*istanbul ignore next */
    this.sendReserveEnt.emit(this.specialFeaturesFormGroup.value.reservedEntityName);
    this.dialogRef.close();
  }

  resetAttributeContextualData() {
    this.sourceValueIdMlr = [];
    this.sourceValueNameMlr = [];
    this.sourceValueMlr = [];
    this.sourceContextualName = '';
    this.sourceContextualId = '';
  }
  saveAttributeContextualData() {
    if (this.specialFeature.name == 'multiLinearRegression') {
      let sourceMlr = '';
      for (let i = 0; i < this.sourceValueIdMlr?.length; i++) {
        if (this.sourceValueMlr?.[i]) {
          sourceMlr =
            this.sourceValueMlr?.length > 1 && i != this.sourceValueMlr?.length - 1
              ? sourceMlr + this.sourceValueIdMlr[i] + '=' + this.sourceValueMlr[i] + ','
              : sourceMlr + this.sourceValueIdMlr[i] + '=' + this.sourceValueMlr[i];
        }
      }
      this.sourceValue = sourceMlr;
    }
  }
  onKeydown(event: KeyboardEvent, property: string) {
    /*istanbul ignore if*/
    if (event.key === '-' && (property === 'duration' || property === 'maxLimit')) {
      event.preventDefault();
    }
  }
  validateValue(event: any, property: string) {
    /*istanbul ignore if*/
    if (event <= 0 && (property === 'duration' || property === 'maxLimit')) {
      this.durationMinValue = 0;
    } else {
      this.durationMinValue = Number.MIN_SAFE_INTEGER;
    }
  }



  /* this function is to show all entities in physical and information layer in relaity and potential ent list
   */
  detectTriggerCuData() {
    this.gsiData.solutionLogic.forEach((cu: any, index: any) => {
      if (cu.index == this.cuIndex) {
        cu.layers.forEach((layer: any) => {
          layer.participatingItems.forEach((ent: any) => {
            let entitywithLayer = { ...ent, layer: layer.type };
            this.triggerCuEntities.push(entitywithLayer);
          });
        });
      }
    });
  }

  /*this function is to generate reality slot id based on selected attr arr */
  generateRealitySlot(entData: any, attrData: any) {
    this.realityslotIds = '';
    attrData.forEach((attr: any, index: number) => {
      if (index != 0) {
        this.realityslotIds += ',';
      }
      let realityslotId = entData?.layer == 'physical' ? 'PL.' : 'IL.';
      realityslotId = realityslotId + 'SL00' + (this.slotIndex + 1) + '.' + 'EN' + entData?.id + '.' + 'AT' + attr?.id;
      this.realityslotIds += realityslotId;
    });
  }

  /*this function is to generate potential slot id based on selected attr arr */
  generatePotentialSlot(entData: any, attrData: any) {
    this.potentialslotIds = '';
    attrData.forEach((attr: any, index: number) => {
      if (index != 0) {
        this.potentialslotIds += ',';
      }
      let potentialslotId = entData?.layer == 'physical' ? 'PL.' : 'IL.';
      potentialslotId =
        potentialslotId + 'SL00' + (this.slotIndex + 1) + '.' + 'EN' + entData?.id + '.' + 'AT' + attr?.id;
      this.potentialslotIds += potentialslotId;
    });
  }

  vantagePointsArray(vantagePoint: any) {
    let array: any = [];
    vantagePoint?.forEach((point: any) => {
      array?.push(point.name);
    });
    return array;
  }
 generateMLR(sourcetxName:any,sourcectxId:any){
  if(!(this.sourceValueNameMlr.includes(sourcetxName)&& this.sourceValueIdMlr.includes(sourcectxId)))
  this.sourceValueNameMlr.push(sourcetxName);
  this.sourceValueIdMlr.push(sourcectxId);
  this.sourceValueMlr.push('');
 }
 generateSourceValue(data:any,layer:any,slotIndex:any,layerType:any){
  data?.en?.nslAttributes.forEach((att:any)=>{
  let sourcetxName = this.generateSourceTxName(data,layer,slotIndex,att);
  let sourcectxId = this.generateSourceTxId(data,layer,slotIndex,layerType,att);
  this.generateMLR(sourcetxName,sourcectxId);
  });
 }
 generateSourceTxName(data:any,layer:any,slotIndex:any,att?:any){
  let attrname :any;
    attrname =  att ?att.name :  data?.att?.name;
   return   this.gsiData?.name + '.' +
   data?.cu?.name +
   ':' +
   data?.cu?.index +
   '.' +
   layer?.type +
   '.' +
   'slot:' +
   (slotIndex + 1) +
   '.' +
   data?.en?.name +
   '.' +
   attrname
 }
 generateSourceTxId(data:any,layer:any,slotIndex:any,layerType:any ,att?:any){
  let attrId :any;
  attrId = att ? att?.tfId : data?.att?.tfId ;
  return   'GS' +
  this.gsiData.id +
  '.CU' +
  data?.cu?.tfId +
  '_' +
  data?.cu?.tfReferencedChangeUnit +
  layerType +
  '.SL00' +
  (slotIndex + 1) +
  '.EN' +
  data?.en?.tfId +
  '.AT' +
  attrId;
 }
 removeChip(chipToRemove: any, targetArray: any[]): void {
  const index = targetArray?.findIndex((el:any)=>{
    return chipToRemove.name == el.name;
  });
  if (index >= 0) {
    targetArray.splice(index, 1);
  }
}
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}


