import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { getValueFromCUObjectfromGSI } from '../../generic-folder/generic-functions';
import { EventsFacadeService } from '../events/events-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
import { ImageMapComponent, ImageMapCoordinate } from '../image-map/image-map.component';
import { LibraryFacadeService } from '../library/library-facade.service';
import { Attribute } from '../modal/attribute';
import { OCRFacadeService } from '../ocr/ocr-facade.service';
import { AlertService } from '../toaster/alert.service';
import { TranslatorService } from '../translator/translator.service';
@Component({
  selector: 'app-ocr-modal',
  templateUrl: './ocr-modal.component.html',
  styleUrls: ['./ocr-modal.component.scss'],
})
export class OCRModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('templateDialog') templateRef: TemplateRef<any>;
  @ViewChild('promptBox') promptRef: TemplateRef<any>;
  @Input() transactionCU: any;
  @Input() transactionViewOCRFlag: boolean;
  @Input() gsi: any;
  @Input() changeUnit: any;
  @Input() isReadOnly: any;

  filename: string;
  showFile = false;
  private ngUnsubscribe = new Subject();
  image: any;

  coordinates: any = [];
  originalDocumentSize: any;
  dataSelectedFromImage: any = '';
  nslAttributes: any = [];
  dataSelectedFromImageForMapping: any = '';
  entityData: any;
  tempGSIData: any;
  ocrData: any;
  fileType: any;
  layerType: any;
  expandOcr: boolean;
  showOcrImage: boolean;
  enableMapping: boolean = false;
  ontology: string;
  manualMappedAttributedata: any;
  showAttr: boolean = false;
  showAttrList: boolean = false;
  showInputBox: boolean = false;
  nameManualMappedAttr: string = '';
  labels: any;
  @ViewChild('componentName') componentName: any;
  isEdit: boolean = false;
  selectedAttributeData: any;
  attribute_Details: any;
  currentAttribute: any;
  // templateRef: any;
  isTemplate: boolean = false;
  fileResponse: any;
  showBrowse: boolean;
  showDialog: boolean;
  currentCu: any;
  // templateRef: TemplateRef<any>;

  constructor(
    private ocrFacadeService: OCRFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private libraryFacadeService: LibraryFacadeService,
    private alertService: AlertService,
    public dialogService: MatDialog,
    private translator: TranslatorService,
    private eventsService: EventsFacadeService
  ) {
    this.detectLastUploadFileURL();
    this.detectChangeOfCuState();
    this.detectChangeUnitData();
    this.detectTempGSIData();
    this.detectSaveMappedFileDetails();
    this.detectTransactionMappedDetails();
    this.detectlatestCreatedAttribute();
    this.detectResetOCRData();
    this.detectManuallyMappedAttribute();
    this.detectLanguageChange();
    this.detectcloseOCRModal();
    this.detectLastMappedSolutionAttribute();
    this.detectUiControl();
    this.detectHideShowFile();
    this.detectOpenConfigurationBox();
    // this.detectTempGSIObjectWithReservedCU();
    // this.setMappingCall();
    this.detectSetMappingsFailed();
    this.detectOpenConfigurationBox();
    this.detectManualTransact();
    this.detectSaveOcr();
  }

  ngOnInit() {
    this.detectUnlinkedData();
  }

  ngAfterViewChecked() {
    /* istanbul ignore next */
    if (this.nameManualMappedAttr == '') {
      /* istanbul ignore next */
      this.componentName?.nativeElement?.focus();
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectResetOCRData() {
    this.ocrFacadeService.resetData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((flag: any) => {
      /* istanbul ignore next */
      if (flag) {
        this.resetData();
      }
    });
  }

  /**
   * on file drang and drop
   */

  onFileDropped($event: any) {
    // for file uplaod
    this.fileChange($event);
  }
  /**
   * getting data of manual mapped image
   */

  detectManuallyMappedAttribute() {
    this.ocrFacadeService.manualMappedAttribute$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res != undefined && res != '') {
        this.manualMappedAttributedata = res?.mappings[0];
        this.showAttr = true;
        this.manualMappedAttributedata['solution_attribute'] = [];
      }
    });
  }

  /**
   * accessing the tempgsi data from the stream
   */

  detectTempGSIData() {
    this.gsiFacadeService.tempGsiObj$
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.tempGSIData = res;
        }
      });
  }

  detectChangeOfCuState() {
    this.ocrFacadeService.cuDetailsForOCR$
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((changeUnit: any) => {
        /* istanbul ignore next */
        if (changeUnit) {
          this.changeUnit = changeUnit;
          if (this.changeUnit?.isEdit) {
            this.editOCRFileDetails();
            this.changeUnit.isEdit = false;
          }
        }
      });
  }
  renameEntity(data: any, isFormValueChange: boolean) {
    /* istanbul ignore next */
    data?.forEach((element: any) => {
      /* istanbul ignore next */
      element.name = element?.name?.split('$')?.[0];
      /* istanbul ignore next */
      element?.nslAttributes?.forEach((el1: any) => {
        /* istanbul ignore else */
        if (el1?.type == 'entity') {
          this.renameNestedentity(el1, isFormValueChange);
        }
        // sending empty array if there is no value //
        /* istanbul ignore next */
        if (el1?.values && el1?.values?.[0] === '') {
          el1.values = [];
        }
        /* istanbul ignore next */
        if (el1?.options && el1?.options.length > 0 && !isFormValueChange) {
          el1.options = null;
        }
      });
    });

    return data;
  }
  renameNestedentity(element: any, isFormValueChange: boolean) {
    /* istanbul ignore next */
    element.name = element?.name?.split('$')?.[0];
    /* istanbul ignore next */
    element.nslAttributes?.forEach((el1: any) => {
      /* istanbul ignore else */
      if (el1.type == 'entity') {
        this.renameNestedentity(el1, isFormValueChange);
      }
      // sending empty array if there is no value //
      /* istanbul ignore next */
      if (el1?.values && el1?.values?.[0] === '') {
        el1.values = [];
      }
      /* istanbul ignore next */
      if (el1?.options && el1?.options.length > 0 && !isFormValueChange) {
        el1.options = null;
      }
    });
  }

  /**
   * get OCR Edit file Details
   */

  editOCRFileDetails() {
    // const cdecId = this.tempGSIData?.cuList[this.tempGSIData?.activeCuIndex]?.uiProperties?.cdecId;
    /* istanbul ignore next */
    const cdecId = this.changeUnit?.uiProperties?.cdecId;
    // this.isEdit = true;
    /* istanbul ignore next */
    if (cdecId) {
      const postData = {
        file: '',
        gsi_ID: '',
        cu_ID: '',
        cdecId: cdecId,
        trans_call: false,
      };
      this.ocrFacadeService.extractMappingInTransactionView(postData);
      this.ocrFacadeService.editCaseOfOcr = true;
    }
  }

  /**
   * get Active Change Selected entity and attribute data
   */
  detectChangeUnitData() {
    this.ocrFacadeService.entityData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && res?.showAttributes) {
        this.entityData = res;
        /* istanbul ignore next */
        this.nslAttributes = res?.nslAttributes;
        this.layerType = res.layerType;
      } else {
        this.nslAttributes = [];
      }
    });
  }

  /**
   *
   * @param event
   * file change detect
   * convert file to formdata
   * save the file in cloud
   */

  fileChange(event: any) {
    // this.templateRef = ref;
    let fileList: any = event;
    /* istanbul ignore next */
    if (fileList.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0]);
      this.ocrFacadeService.fileUpload(formData);
    }
  }

  /**
   * recently saved file cloud url
   * save the ocr with file url
   */

  detectLastUploadFileURL() {
    this.ocrFacadeService.srcfileUrl$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.showFile = true;

      if (this.transactionViewOCRFlag) {
        this.getOCRGSIandFileMappingInTransactionView(res);
      } else {
        this.saveOCRGSIandFileMapping(res);
      }
    });
  }

  /**
   * send the required data for OCR CDEC flow
   *
   */
  getOCRGSIandFileMappingInTransactionView(data: any) {
    /* istanbul ignore next */
    const file = data?.contentUrl;
    const trans_call = true;
    const isEdit = false;
    //this.attribute_Details = data.fieldDetails;
    /* istanbul ignore next */
    this.transactionCU?.currentCU?.layers?.forEach((layer: any) => {
      layer.entityList = this.renameEntity(layer.entityList, true);
    });
    this.transactionCU = { ...this.transactionCU, cdec: { file, trans_call, isEdit, original_file_name: data.name } };
    /* istanbul ignore next */
    this.transactionCU = {
      ...this.transactionCU,
      cuId: this.transactionCU?.currentCU?.id,
      cuMasterId: this.transactionCU?.currentCU?.masterId,
    };
    this.ocrFacadeService.extractMappingInTransactionView(this.transactionCU);
  }

  /**
   * map the file url and gsi id and cu id and save the ocr
   *
   */
  saveOCRGSIandFileMapping(data: any) {
    /* istanbul ignore next */

    /* istanbul ignore next */
    let ontologyAll = getValueFromCUObjectfromGSI(
      this.tempGSIData?.activeCuIndex,
      'ontology',
      'cuList',
      this.tempGSIData
    );
    /* istanbul ignore next */
    if (ontologyAll.length <= 1) {
      ontologyAll = this.changeUnit?.ontology;
      // ontologyAll[1] = ontologyAll[0];
    }
    /* istanbul ignore next */
    const postData = {
      file: data.contentUrl,
      gsi_ID: this.tempGSIData?.id,
      cu_ID: this.changeUnit?.isReserved
        ? this.changeUnit?.id
        : this.tempGSIData?.cuList[this.tempGSIData?.activeCuIndex]?.referencedChangeUnit,
      gsiMasterId: this.tempGSIData?.appDetails?.masterId?.toString(),
      cuMasterId: this.changeUnit?.isReserved
        ? this.changeUnit?.masterId?.toString()
        : this.tempGSIData?.cuList[this.tempGSIData?.activeCuIndex]?.masterId,
      // ontology: 'financial',
      ontology: ontologyAll[ontologyAll?.length - 1]?.id,
      trans_call: false,
      isTemplate: this.isTemplate,
      original_file_name: data.name,
    };
    /* istanbul ignore next */
    if (postData?.ontology !== undefined && postData?.ontology !== '') {
      this.ontology = postData?.ontology;
      this.ocrFacadeService.saveOCRGSIandFileMapping(postData);
    } else {
      this.alertService.showToaster('Ontology is Mandqatory for OCR', '', 'info');
    }
  }

  saveCUWithOCR() {
    const { cdecId } = this.ocrData;
    this.ocrFacadeService.updateCUStateWithOCR({ cdecId });
  }

  /**
   * get the coordinate after mapping
   * such as file url,coordinates, original doc size
   *
   */
  detectSaveMappedFileDetails() {
    this.ocrFacadeService.fileMappedDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && this.transactionViewOCRFlag != true) {
        const tenantName = localStorage.getItem('TenantName');
        /* istanbul ignore next */
        if (!res.ref_doc.slice(8).includes(tenantName)) {
          const ref_url = res.ref_doc.slice(0, 8) + tenantName + '.' + res.ref_doc.slice(8);
          res.ref_doc = ref_url;
        }
        this.ocrData = res;
        this.setMappingCall();
        /* istanbul ignore next */
        this.coordinates = this.ocrData?.mappings;
        this.originalDocumentSize = this.ocrData?.ref_file_dimenstions;
        this.image = this.ocrData?.ref_doc;
        this.filename = this.ocrData?.file_name;
        this.fileType = this.ocrData?.file_type;
        this.isTemplate = this.ocrData?.isTemplate;
        this.showFile = true;
        /* istanbul ignore next */
        if (res?.isEdit) {
          this.showFile = true;
        } else {
          this.saveCUWithOCR();
        }
      }
    });
  }

  setMappingCall() {
    this.ocrFacadeService.setMappingCall$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.ocrData.cdecId = res;
        this.ocrFacadeService.saveOCRMappingData(this.ocrData);
      }
    });
  }

  /**
   *
   * @param coordinate
   *
   */
  detectTransactionMappedDetails() {
    this.ocrFacadeService.transactionfileMappedDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.ocrData = res;
        this.coordinates = this.ocrData?.mappings;
        this.originalDocumentSize = this.ocrData?.file_dimensions;
        const ref_url = this.ocrData?.ref_doc.split('/');
        this.ocrData.ref_doc = ref_url[0] + '//' + window.location.host + '/' + ref_url[3];
        this.image = this.ocrData?.ref_doc;
        this.filename = this.ocrData?.file_name;
        this.fileType = this.ocrData?.file_type;
        this.currentCu = res.currentCU;
      }
    });
  }

  detectManualTransact() {
    this.ocrFacadeService.manualTransact$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.currentCu = res.currentCU;
      }
    });
  }
  /**
   *
   * @param coordinate
   * selected coordinate data
   */
  selectedField(coordinate: ImageMapCoordinate) {
    this.dataSelectedFromImage = coordinate;
    this.dataSelectedFromImageForMapping = '';
  }
  /**
   * * enable the map icon only if coordinate is selected
   */
  selectedFieldFromImage() {
    this.dataSelectedFromImageForMapping = this.dataSelectedFromImage;
  }
  /**
   * * enter Name manually to attribute if api is unable to recognised
   */
  showInput() {
    this.showInputBox = true;
  }
  /**
   * * enter Name manually to attribute if api is unable to recognised
   */
  saveName() {
    if (this.nameManualMappedAttr != 'other' && this.nameManualMappedAttr != '') {
      this.manualMappedAttributedata.attribute_name = this.nameManualMappedAttr;
      this.showInputBox = false;
      this.nameManualMappedAttr = '';
      this.ocrData.mappings.push(this.manualMappedAttributedata);
    } else {
      this.alertService.showToaster('You cannot give this name', '', 'error');
    }
  }
  /**
   * * enable the map icon only if coordinate is selected
   */
  selectedFieldFromManualImage() {
    this.showAttrList = true;
    this.dataSelectedFromImageForMapping = this.manualMappedAttributedata;
  }
  /**
   *
   * @param attr
   * map
   * cu , entity , attr to update the entity data for the mapped with
   * reset the selected mapped data
   */
  selectedAttributeForMapping(attr: any) {
    /* istanbul ignore next */
    const data = {
      activeCuIndex: this.entityData?.activeCuIndex,
      selectedEntityId: this.entityData?.id?.toString(),
      selectedEntityMasterId: this.entityData?.masterId?.toString(),
      selectedAttributeId: attr?.id?.toString(),
      selectedAttributeName: attr?.name,
      mapField: this.dataSelectedFromImageForMapping?.attribute_name,
      layerType: this.entityData?.layerType,
      selectedAttributeType: attr?.attributeType?.uiElement?.dataType,
      selectedAttributeDataName: attr?.attributeType?.uiElement?.name,
    };
    this.selectedAttributeData = data;
    this.ocrFacadeService.updateLastedMappedFields(data);
  }

  detectLastMappedSolutionAttribute() {
    this.ocrFacadeService.lastMappedSolutionAttribute$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.coordinates = this.coordinates.map((x: any) => {
          if (x.attribute_name === res) {
            x.solution_attribute = x.solution_attribute?.filter(
              (att: any) =>
                att?.attributes?._id !== this.selectedAttributeData?.selectedAttributeId ||
                att?.entityid !== this.selectedAttributeData?.selectedEntityId
            );
          }
          return x;
        });
      }
      this.updatedMappedDataSourceAttribute(this.selectedAttributeData);
      this.dataSelectedFromImage = '';
      this.dataSelectedFromImageForMapping = '';
      this.showAttr = false;
      this.showAttrList = false;
    });
  }
  /**
   * Create a new attribute in the active entity
   */
  createAttribute() {
    let attribute: Attribute = {} as Attribute;
    /* istanbul ignore next */
    attribute.name = this.dataSelectedFromImage?.attribute_name;
    attribute.isReserved = false;
    attribute.attributeType = {
      type: 'string',
      uiElement: { name: 'Text', dataType: 'string', uiElement: 'text', isMulti: false, properties: [] },
      properties: {},
      nestedNslDataTypeProperties: { item_type: null },
    };

    this.libraryFacadeService.importAttributeForOCR(attribute, true);
  }
  /**
   * Create a new manually mapped attribute in the active entity
   */
  createManualMappedAttribute() {
    let attribute: Attribute = {} as Attribute;
    /* istanbul ignore next */
    attribute.name = this.manualMappedAttributedata?.attribute_name;
    attribute.isReserved = false;
    attribute.attributeType = {
      type: 'string',
      uiElement: { name: 'Text', dataType: 'string', uiElement: 'text', isMulti: false, properties: [] },
      properties: {},
      nestedNslDataTypeProperties: { item_type: null },
    };
    this.showAttr = false;
    this.dataSelectedFromImage = this.manualMappedAttributedata;
    this.libraryFacadeService.importAttributeForOCR(attribute, true);
  }

  /**
   * will detect Unlinked mapping
   */
  detectUnlinkedData() {
    this.ocrFacadeService.unlinkedDataForOCR$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /* istanbul ignore next */
      if (data != '' && this.coordinates?.length != 0) {
        this.currentAttribute = data[0];
        const mappingData = this.coordinates;

        mappingData.map((x: any) => {
          /* istanbul ignore next */
          if (x.solution_attribute?.length != 0) {
            for (let i = 0; i < x.solution_attribute?.length; i++) {
              for (let j = 0; j < data.length; j++) {
                if (
                  x.solution_attribute[i]?.attributes?._id == data[j]?.tf_id &&
                  x.solution_attribute[i]?.entityid == data[j]?.tf_entityid
                ) {
                  x.solution_attribute?.splice(i, 1);
                }
              }
            }
          } else {
            return x;
          }
        });
        this.coordinates = mappingData;
        this.ocrData.mappings = mappingData;
        // this.ocrFacadeService.saveOCRMappingData(this.ocrData, 'unlink');
      }
    });
  }

  /**
   * used to update the OCR with the newly created attribute from image
   */
  detectlatestCreatedAttribute() {
    this.ocrFacadeService.latestCreatedAttribute$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      const mappingData = this.coordinates;
      this.currentAttribute = data;
      mappingData.map((x: any) => {
        /* istanbul ignore next */
        if (JSON.stringify(x) === JSON.stringify(this.dataSelectedFromImage)) {
          x.solution_attribute.push({
            layer: this.layerType,
            entityid: data?.selectedEntityId,
            entityMasterId: data?.selectedEntityMasterId?.toString(),
            attributes: {
              _id: data?.selectedAttributeId,
              name: data?.selectedAttributeName,
              dataType: data?.selectedAttributeType,
              type: data?.selectedAttributeDataName,
            },
          });
        } else {
          return x;
        }
      });

      this.coordinates = mappingData;
      this.ocrData.mappings = mappingData;
      // this.ocrFacadeService.saveOCRMappingData(this.ocrData);
      this.dataSelectedFromImage = '';
    });
  }

  /**
   *
   * @param data activeCuIndex, SelectedentityId,  SelectedAttributeId, MapField, LayerType
   */
  updatedMappedDataSourceAttribute(data: any) {
    const mappingData = this.coordinates;
    this.currentAttribute = data;
    /* istanbul ignore next */
    mappingData?.map((x: any) => {
      /* istanbul ignore next */
      if (JSON.stringify(x) === JSON.stringify(this.dataSelectedFromImageForMapping)) {
        /* istanbul ignore next */
        let checkIfPresent = x.solution_attribute?.find(
          (attObj: any) =>
            attObj?.attributes?._id === data?.selectedAttributeId && attObj?.entityid === data?.selectedEntityId
        );
        /* istanbul ignore next */
        if (!checkIfPresent) {
          /* istanbul ignore next */
          x.solution_attribute?.push({
            layer: data?.layerType,
            entityid: data?.selectedEntityId,
            entityMasterId: data?.selectedEntityMasterId?.toString(),
            attributes: {
              _id: data?.selectedAttributeId,
              name: data?.selectedAttributeName,
              dataType: data?.selectedAttributeType,
              type: data?.selectedAttributeDataName,
            },
          });
        }
      } else {
        /* istanbul ignore else */
        if (
          this.ocrFacadeService.editCaseOfOcr &&
          x?.attribute_name !== this.dataSelectedFromImageForMapping?.attribute_name &&
          x?.attribute_value !== this.dataSelectedFromImageForMapping?.attribute_value &&
          x?.solution_attribute?.length > 0
        ) {
          for (let i = 0; i < x?.solution_attribute?.length; i++) {
            /* istanbul ignore next */
            if (
              x?.solution_attribute[i]?.attributes?._id == data?.selectedAttributeId &&
              x?.solution_attribute[i]?.attributes?.name == data?.selectedAttributeName
            ) {
              x?.solution_attribute?.splice(i, 1);
            }
          }
        }
        return x;
      }
    });

    this.coordinates = mappingData;
    this.ocrData.mappings = mappingData;
    // this.ocrFacadeService.saveOCRMappingData(this.ocrData);
    this.dataSelectedFromImage = '';
    this.dataSelectedFromImageForMapping = '';
  }

  startMapping() {
    this.enableMapping = true;
  }
  stopMapping($event: any) {
    this.enableMapping = false;
  }

  editManualMappedAttribute() {
    this.enableMapping = true;
    this.showAttr = false;
    this.manualMappedAttributedata = undefined;
  }

  @Output() emitter = new EventEmitter();
  detectcloseOCRModal() {
    this.ocrFacadeService.ocrData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.showFile = false;
        this.showBrowse = true;
        /*istanbul ignore next*/
        this.emitter.emit(this.showFile);
        this.resetData();
        this.dialogService.closeAll();
        /*istanbul ignore next*/
        if (this.isTemplate) this.ocrFacadeService.setNewConfigurations(true);
      }
    });
  }

  resetData() {
    this.coordinates = [];
    this.originalDocumentSize = undefined;
    this.dataSelectedFromImage = '';
    this.nslAttributes = [];
    this.dataSelectedFromImageForMapping = '';
    this.entityData = undefined;
    this.ocrData = undefined;
    this.image = '';
    this.filename = '';
    this.fileType = '';
  }
  detectUiControl() {
    this.eventsService.eventTrigger$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
       /*istanbul ignore else*/
      if (res) {
        this.attribute_Details = res;
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.eventsService.setEvent('');
    this.ocrFacadeService.cuDetailsForOCR.next('');
  }

  ExpandTransactionViewOCR() {
    console.log(this.attribute_Details);
    /*istanbul ignore next*/
    let data = {
      src: this.image,
      originalDocumentSize: this.originalDocumentSize,
      coordinates: this.coordinates,
      fileType: this.fileType,
      canEdit: true,
      attribute_details: this.attribute_Details,
      cdecId: this.transactionCU?.currentCU?.uiProperties?.cdecId,
      currentCu: this.currentCu,
      expanded: true,
      isTemplate: this.isTemplate,
    };
    this.dialogService.open(ImageMapComponent, {
      height: '100%',
      width: '1000px',
      panelClass: 'dialogue-pop',
      data: data,
    });
  }

  detectHideShowFile() {
    this.ocrFacadeService.hideShowFile$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.showFile = false;
      }
    });
  }

  detectSetMappingsFailed() {
    this.ocrFacadeService.setMappingsFailed$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        let body = {};
        if (res.unlink) {
          body = {
            unlink: res.unlink,
            activeCuIndex: this.entityData?.activeCuIndex,
            selectedEntityId: this.entityData?.id?.toString(),
            selectedEntityMasterId: this.entityData?.masterId?.toString(),
            selectedAttributeId: this.currentAttribute?.tf_id?.toString(),
            selectedAttributeName: this.currentAttribute?.name,
            mapField: this.currentAttribute?.mappedWith,
            layerType: this.entityData?.layerType,
          };
          this.ocrFacadeService.updateLastedMappedFields(body);
        } else {
          body = { ...body, entityDetails: this.entityData, attrDetails: this.currentAttribute };
          this.ocrFacadeService.setUnlinkSetMappingsFailed(body);
        }
      }
    });
  }
  openPromptBox() {
    this.dialogService.closeAll();
    if (this.image) this.dialogService.open(this.promptRef);
    else this.dialogService.open(this.templateRef);
  }

  openDialogBox() {
    this.dialogService.closeAll();
    this.dialogService.open(this.templateRef);
  }

  detectOpenConfigurationBox() {
    this.ocrFacadeService.openConfigurationBox$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.showDialog = true;
      }
    });
  }

  saveConfigurations() {
    this.showFile = false;
    this.emitter.emit(this.showFile);
    this.dialogService.closeAll();
    this.showBrowse = true;
    /*istanbul ignore next*/
    if (this.isTemplate !== this.ocrData?.isTemplate) {
      this.resetData();
      this.ocrFacadeService.setNewConfigurations(true);
    }
  }

  closeDialog() {
    this.dialogService.closeAll();
  }

  detectTempGSIObjectWithReservedCU() {
    this.ocrFacadeService.tempGSIObjectWithReservedCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.tempGSIData = res;
      }
    });
  }

  ngAfterViewInit() {
    /*istanbul ignore next*/
    if (this.showDialog && !this.transactionViewOCRFlag) this.openDialogBox();
  }

  detectSaveOcr() {
    this.ocrFacadeService.saveOcr$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.ocrFacadeService.saveOCRMappingData(this.ocrData);
      }
    });
  }

}
