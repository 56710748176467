<button class="filter-fixed" (click)="showFilters()" [ngClass]="isNslLibrary ? 'filter-fixed-nsl' : ''">
    <span class="row no-gutters align-items-center justify-content-center">
      <span class="col-auto">
        <img src="../../../../../assets/images/library/filters-icon.svg" />
      </span>
      <span class="col-auto">{{ labels?.Filter }}</span>
    </span>
  </button>
  <section class="filters-overlay" (click)="showFilters()"></section>
  <aside class="filters-popup">
    <div class="row no-gutters flex-column h-100">
      <div class="col-auto">
        <div class="filter-head">
          <div class="row mb-4">
            <div class="col-auto ml-auto"><button class="close-filter-btn" (click)="showFilters()"></button></div>
          </div>
          <div class="row filter-names">
            <div class="col-auto pr-0">
              <a class="all-btn"
                ><strong>{{ labels?.All }}</strong></a
              >
            </div>
            <div class="col-auto pr-0">
              <button
                class="edit-btn"
                [ngClass]="isEditableLabel == 'editable' ? 'active' : ''"
                (click)="onClickEditable('editable')"
              ></button>
            </div>
            <div class="col-auto">
              <button
                class="doument-btn"
                [ngClass]="isEditableLabel == 'transactable' ? 'active' : ''"
                (click)="onClickEditable()"
              ></button>
            </div>
            <div class="col-auto side-btn">
              <span
                ><button class="apply-btn" (click)="onFilter('apply')">{{ labels?.Apply }}</button></span
              >
              <span
                ><button class="reset-btn" (click)="onFilter()">{{ labels?.Reset }}</button></span
              >
            </div>
            <!-- <div class="col-12 pt-4">
                <div class="filter-search">
                  <input type="text" placeholder="Search Your Solution" />
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div class="col">
        <div class="filter-body">
          <div class="filter-catgory" *ngIf="!enableDetailedView">
            <ul class="filter-category-list">
              <li (click)="onClickFilterOptions('categories')">
                <span>Categories</span>
                <span>
                  <mat-icon>chevron_right</mat-icon>
                </span>
              </li>
              <!-- <li>
                <span>{{ labels?.Author }}</span>
                <span>
                  <mat-icon>chevron_right</mat-icon>
                </span>
              </li> -->
              <li (click)="onClickFilterOptions('rating')">
                <span>{{ labels?.Rating }}</span>
                <span>
                  <mat-icon>chevron_right</mat-icon>
                </span>
              </li>
            </ul>
          </div>
          <div *ngIf="enableDetailedView">
            <div class="filter-category-content category-one" *ngIf="enableCategories">
              <div class="filter-category-top">
                <div class="backto" (click)="onClickEndForDetailedView()">
                  <mat-icon>chevron_left</mat-icon>
                </div>
                <div class="form-group" *ngIf="!parentOntology">
                  <input type="text" placeholder="Search Your Category" [(ngModel)]="searchText" />
                  <mat-icon>search</mat-icon>
                </div>
                <div class="catg-title" *ngIf="parentOntology">
                  <h3>{{ parentOntology?.displayName }}</h3>
                </div>
              </div>
              <div class="first-values catgory-values" *ngIf="!parentOntology">
                <ul>
                  <li
                    *ngFor="let ontology of ontologyMasterList | filter: searchText"
                    (click)="onClickParentOntology(ontology)"
                  >
                    <mat-checkbox
                      class="catgory-choose"
                      *ngIf="ontology?.children?.length == 0"
                      [(ngModel)]="ontology.isActive"
                      >{{ ontology?.displayName }}</mat-checkbox
                    >
                    <span *ngIf="ontology?.children?.length > 0">
                      <h4>{{ ontology?.displayName }}</h4>
                      <mat-icon class="islist-values">chevron_right</mat-icon>
                    </span>
                  </li>
                </ul>
              </div>
              <div class="second-values catgory-values" *ngIf="parentOntology">
                <ul>
                  <li>
                    <mat-checkbox
                      class="catgory-choose"
                      [checked]="parentOntology.isActive"
                      (change)="onSelectAll($event)"
                      ><strong>Select All</strong></mat-checkbox
                    >
                  </li>
                  <li *ngFor="let childOntology of parentOntology?.children">
                    <mat-checkbox
                      class="catgory-choose"
                      [(ngModel)]="childOntology.isActive"
                      (change)="onClickChildOntology(childOntology, $event)"
                      >{{ childOntology?.displayName }}</mat-checkbox
                    >
                  </li>
                </ul>
              </div>
            </div>
  
            <div class="filter-category-content category-three" *ngIf="enableRating">
              <div class="filter-category-top">
                <div class="backto" (click)="onClickEndForDetailedView()">
                  <mat-icon>chevron_left</mat-icon>
                </div>
                <!-- <div class="form-group">
                  <input type="text" placeholder="Search Your Category">
                  <mat-icon>search</mat-icon>
                </div> -->
              </div>
              <div class="rating-info">
                <div class="rating-list">
                  <mat-radio-group aria-label="Select rating" [(ngModel)]="selectedRating">
                    <ul (click)="onChangeRating('5')">
                      <li>
                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                        ><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                      </li>
                      <li>
                        <mat-radio-button value="5"></mat-radio-button>
                      </li>
                    </ul>
                    <ul (click)="onChangeRating('4')">
                      <li>
                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                        ><i class="bi bi-star-fill"></i><i class="bi bi-star"></i>
                      </li>
                      <li>
                        <mat-radio-button value="4"></mat-radio-button>
                      </li>
                    </ul>
                    <ul (click)="onChangeRating('3')">
                      <li>
                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i
                        ><i class="bi bi-star"></i><i class="bi bi-star"></i>
                      </li>
                      <li>
                        <mat-radio-button value="3"></mat-radio-button>
                      </li>
                    </ul>
                    <ul (click)="onChangeRating('2')">
                      <li>
                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star"></i
                        ><i class="bi bi-star"></i><i class="bi bi-star"></i>
                      </li>
                      <li>
                        <mat-radio-button value="2"></mat-radio-button>
                      </li>
                    </ul>
                    <ul (click)="onChangeRating('1')">
                      <li>
                        <i class="bi bi-star-fill"></i><i class="bi bi-star"></i><i class="bi bi-star"></i
                        ><i class="bi bi-star"></i><i class="bi bi-star"></i>
                      </li>
                      <li>
                        <mat-radio-button value="1"></mat-radio-button>
                      </li>
                    </ul>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aside>
  