import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { AlertService, isReservedCU, LoaderService, NodeGsiFacadeService, TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';

@Component({
  selector: 'node-separateCu-display-tags',
  templateUrl: './node-separateCu-display-tags.component.html',
  styleUrls: ['./node-separateCu-display-tags.component.scss'],
})
export class NodeSeparateCuDisplayTagsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Input() changeUnitIndex: number;
  @Input() layerSentence: any;
  @Input() layerType: string;
  @Input() indcudata: any;
  @Input() isParagraph: boolean;
  configDropdown: number = -1;
  isAgentTag: boolean = false;
  showConfigDropdown = false;
  tagIndex: number;
  cuData: any;
  labels: any;
  isMasterEntityConfig = false;

  constructor(
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private loader: LoaderService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private alertService: AlertService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
    this.detectRecalledCU();
  }
  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  cuConfigSettings(event: any, tagInd: number, tag: string) {
    /* istanbul ignore next */
    if (tag == 'human agent' || tag == 'machine agent' || tag == 'Ag.') {
      this.isAgentTag = true;
    } else {
      this.isAgentTag = false;
    }

    this.configDropdown = !this.showConfigDropdown ? tagInd : -1;
    if (tag == 'nlg') {
      this.configDropdown = -1;
    }
    this.tagIndex = tagInd;
  }
  configuration(event?: any) {
    this.isMasterEntityConfig = false;
    /* istanbul ignore next */
    if (!isReservedCU(this.indcudata) && this.indcudata && this.indcudata?.tfId) {
      this.changeUnitFacade.getCuDetailsById(this.indcudata?.tfId);
    }
    this.changeUnitFacade.openTriggerEdit.next('basic');
  }
  configureMasterAttribute(event?: any) {
    this.isMasterEntityConfig = true;
    /* istanbul ignore next */
    if (!isReservedCU(this.indcudata)) {
      this.changeUnitFacade.getCuDetailsById(this.indcudata?.tfId);
    }
    this.changeUnitFacade.openTriggerEdit.next('entity');
  }
  detectRecalledCU() {
    this.changeUnitFacade.fetchedCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((recalledCU: any) => {
      if (this.indcudata) {
        /* istanbul ignore next */
        this.indcudata.layers = recalledCU.cudata?.layers;
      }
      this.cuData = {
        ...(this.indcudata ? this.indcudata : recalledCU),
        data: {
          ...(this.indcudata ? this.indcudata : recalledCU),
          cuLayers: this.indcudata?.layers,
        },
        isSeperateCU: true,
      };
      this.configDropdown = -1;
      if (this.layerSentence && this.tagIndex >= 0) {
        let tag = this.layerSentence[this.tagIndex].tag;
        /* istanbul ignore next */
        let index = this.indcudata?.index;
        /* istanbul ignore next */
        let res = {
          masterEntityConfig: this.isMasterEntityConfig,
          tag: tag,
          index: 1,
          entityIndex:
            this.layerSentence[this.tagIndex].entity_counter !== undefined
              ? this.layerSentence[this.tagIndex].entity_counter
              : 0,
          sentenceTag: this.layerSentence[this.tagIndex],
          cu: this.cuData,
          module: 'fat2Flat',
          layerType: this.layerType ? this.layerType : '',
          isSeperateCU: true,
        };
        this.changeUnitFacade.sendTagIndex({
          token: this.layerSentence[this.tagIndex].token,
          index: this.tagIndex,
        });
        this.changeUnitFacade.setConfiguration(res);
      }
    });
  }

  clickOutside() {
    this.configDropdown = -1;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
