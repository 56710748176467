<!-- <ng-container *ngIf="crmTemplate">
  <mat-tab-group class="dynamic-entity-tab-classes nestedTabs nh-wp-100" [(selectedIndex)]="selectedIndex">
    <mat-tab *ngFor="
        let nestedForm of control?.value?.controls | keyvalue: originalOrder;
        let ind = index;
        let first = first
      " [aria-label]="nestedForm?.value?.value?.isHidden ? 'thide' : ''">
      <ng-template mat-tab-label>
        <span [ngClass]="{ 'hide-this-tab': !getControlsName(nestedForm) }">{{
          (control?.value?.value)[nestedForm?.key]?.entityHideLabel
          ? ""
          : integDisplayEnt?.includes(
          (control?.value?.value)[nestedForm?.key]?.displayName
          )
          ? (nestedForm?.key | split: "$")
          : (control?.value?.value)[nestedForm?.key]?.displayName
          ? (control?.value?.value)[nestedForm?.key]?.displayName
          : (nestedForm?.key | split: "$")
          }}
        </span>
        <span *ngIf="(control?.value?.value)[nestedForm.key]?.isMulti"
          style="cursor: pointer; padding-left: 8px; margin-left: auto;" (click)="
            addMultipleEntityControl(
              nestedForm?.key,
              nestedForm?.value?.controls,
              control?.value?.controls,
              control,
              ind,
              $event
            )
          ">
          <mat-icon>add</mat-icon>
          <img src="../../../../../assets/images/plus-icon-exp.svg" />
        </span>
        <span *ngIf="
            (control?.value?.value)[nestedForm?.key]?.isMulti &&
            !(
              getConfigurationTableOrCard(nestedForm) ||
              getConfigurationCard(nestedForm) ||
              getConfigurationVerticalTable(nestedForm)
            ) &&
            getNestedFormValueLength(
              nestedForm?.key,
              control?.value?.controls
            ) > 1
          " style="cursor: pointer; padding-left: 8px;" (click)="
            deleteEntityControl(
              nestedForm?.key,
              nestedForm?.value?.controls,
              control?.value?.controls,
              control,
              ind,
              $event
            )
          ">
          <mat-icon>remove</mat-icon>
          <img src="../../../../../assets/images/minus-icon-exp.svg" />
        </span>
      </ng-template>
      <ng-container *ngTemplateOutlet="
          nestedEntity;
          context: {
            nestedForm: nestedForm,
            ind: ind,
            first: first
          }
        "></ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-container> -->
<ng-container *ngFor="
    let nestedForm of control?.value?.controls | keyvalue: originalOrder;
    let ind = index;
    let first = first
  ">
  <ng-container *ngIf="getControlsName(nestedForm)">
    <div *ngIf="!getShowType(nestedForm)" [ngClass]="{ hide: nestedForm?.value?.value?.isHidden === true }"
      class="nestedEntitity list-accordian" class="{{ (control?.value?.value)[nestedForm?.key]?.entityClass }}">
      <mat-accordion multi class="custom-mat-expansion">
        <mat-expansion-panel (opened)="onOpened(ind, nestedForm.key)" [expanded]="accordianIndex == ind"
          id="txnEntityExpan_{{ nestedForm.key | split: '$' }}_{{ ind }}">
          <mat-expansion-panel-header>
            <mat-panel-title [ngClass]="{
                inValidClass:
                  !nestedForm.value.value?.isInfo &&
                  nestedForm.value.invalid &&
                  nestedForm.value.touched &&
                  isSubmit
              }">
              <span class="mat-expansion-panel-header-title-entityIcon">
                <img height="34" [src]="(control?.value?.value)[nestedForm?.key]?.entityicon" alt="" />
              </span>
              {{
              (control?.value?.value)[nestedForm?.key]?.entityHideLabel
              ? ""
              : integDisplayEnt?.includes(
              (control?.value?.value)[nestedForm?.key]?.displayName
              )
              ? (nestedForm?.key | split: "$")
              : (control?.value?.value)[nestedForm?.key]?.displayName
              ? (control?.value?.value)[nestedForm?.key]?.displayName
              : (nestedForm?.key | split: "$")
              }}
              <div class="mat-expansion-panel-header-multiple">
                <button type="button" *ngIf="(control?.value?.value)[nestedForm.key]?.isMulti"
                  class="nh-unsetAll nh-cursor-pointer" (click)="
                    addMultipleEntityControl(
                      nestedForm?.key,
                      nestedForm?.value?.controls,
                      control?.value?.controls,
                      control,
                      ind,
                      $event
                    )
                  ">
                  <div *ngIf="
                      getMultiEntityButton(
                        nestedForm?.value?.value?.configuration
                      );
                      else icon
                    ">
                    <button type="button" [ngClass]="{
                        square: addButtonShape == 'Square',
                        circle: addButtonShape == 'Circle'
                      }">
                      {{
                      displayAddButton(
                      nestedForm?.value?.value?.configuration
                      )
                      ? displayAddButton(
                      nestedForm?.value?.value?.configuration
                      )
                      : "Add"
                      }}
                    </button>
                  </div>
                  <ng-template #icon>
                    <em class="nh-icon nh-icon-plus-lg nh-icon-bold"></em>
                    <mat-icon class="nh-display-none">add</mat-icon>
                  </ng-template>
                </button>
                <button type="button" *ngIf="
                    (control?.value?.value)[nestedForm?.key]?.isMulti &&
                    !(
                      getConfigurationTableOrCard(nestedForm) ||
                      getConfigurationCard(nestedForm) ||
                      getConfigurationVerticalTable(nestedForm)
                    ) &&
                    getNestedFormValueLength(
                      nestedForm?.key,
                      control?.value?.controls
                    ) > 1
                  " class="nh-unsetAll nh-cursor-pointer" (click)="
                    deleteEntityControl(
                      nestedForm?.key,
                      nestedForm?.value?.controls,
                      control?.value?.controls,
                      control,
                      ind,
                      $event
                    )
                  ">
                  <div *ngIf="
                      getMultiEntityButton(
                        nestedForm?.value?.value?.configuration
                      );
                      else icon
                    ">
                    <button type="button" [ngClass]="{
                        square: removeButtonShape == 'Square',
                        circle: removeButtonShape == 'Circle'
                      }">
                      {{
                      displayRemoveButton(
                      nestedForm?.value?.value?.configuration
                      )
                      ? displayRemoveButton(
                      nestedForm?.value?.value?.configuration
                      )
                      : "Remove"
                      }}
                    </button>
                  </div>
                  <ng-template #icon>
                    <em class="nh-icon nh-icon-dash-lg nh-icon-bold"></em>
                    <mat-icon class="nh-display-none">remove</mat-icon>
                  </ng-template>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngTemplateOutlet="
              nestedEntity;
              context: {
                nestedForm: nestedForm,
                ind: ind,
                first: first
              }
            "></ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div *ngIf="getShowType(nestedForm)" [ngClass]="{ hide: nestedForm?.value?.value?.isHidden === true }"
      class="nestedEntitity list-accordian" class="{{ (control?.value?.value)[nestedForm?.key]?.entityClass }}">
      <div multi class="custom-mat-expansion">
        <div id="txnEntityExpan_{{ nestedForm.key | split: '$' }}_{{ ind }}">
          <div class="nestedEntitity__header" [ngClass]="{
              inValidClass:
                !nestedForm.value.value?.isInfo &&
                nestedForm.value.invalid &&
                nestedForm.value.touched &&
                isSubmit
            }" *ngIf="!(control?.value?.value)[nestedForm?.key]?.entityHideLabel">
            <h4 class="nestedEntitity__header__entity">
              {{
              (control?.value?.value)[nestedForm?.key]?.entityHideLabel
              ? ""
              : integDisplayEnt?.includes(
              (control?.value?.value)[nestedForm?.key]?.displayName
              )
              ? (nestedForm?.key | split: "$")
              : (control?.value?.value)[nestedForm?.key]?.displayName
              ? (control?.value?.value)[nestedForm?.key]?.displayName
              : (nestedForm?.key | split: "$")
              }}
            </h4>
            <div class="nestedEntitity__header__multiple" *ngIf="(control?.value?.value)[nestedForm?.key]?.isMulti">
              <button type="button" *ngIf="(control?.value?.value)[nestedForm.key]?.isMulti"
                class="nh-unsetAll nh-cursor-pointer" (click)="
                  addMultipleEntityControl(
                    nestedForm?.key,
                    nestedForm?.value?.controls,
                    control?.value?.controls,
                    control,
                    ind,
                    $event
                  )
                ">
                <div *ngIf="
                    getMultiEntityButton(
                      nestedForm?.value?.value?.configuration
                    );
                    else icon
                  ">
                  <button type="button" class="nh-unsetAll nh-cursor-pointer" [ngClass]="{
                      square: addButtonShape == 'Square',
                      circle: addButtonShape == 'Circle'
                    }">
                    {{
                    displayAddButton(nestedForm?.value?.value?.configuration)
                    ? displayAddButton(
                    nestedForm?.value?.value?.configuration
                    )
                    : "Add"
                    }}
                  </button>
                </div>
                <ng-template #icon>
                  <em class="nh-icon nh-icon-plus-lg nh-icon-bold"></em>
                  <mat-icon class="nh-display-none">add</mat-icon>
                </ng-template>
              </button>
              <button type="button" *ngIf="
                  (control?.value?.value)[nestedForm?.key]?.isMulti &&
                  !(
                    getConfigurationTableOrCard(nestedForm) ||
                    getConfigurationCard(nestedForm) ||
                    getConfigurationVerticalTable(nestedForm)
                  ) &&
                  getNestedFormValueLength(
                    nestedForm?.key,
                    control?.value?.controls
                  ) > 1
                " class="nh-unsetAll nh-cursor-pointer" (click)="
                  deleteEntityControl(
                    nestedForm?.key,
                    nestedForm?.value?.controls,
                    control?.value?.controls,
                    control,
                    ind,
                    $event
                  )
                ">
                <div *ngIf="
                    getMultiEntityButton(
                      nestedForm?.value?.value?.configuration
                    );
                    else icon
                  ">
                  <button type="button" class="nh-unsetAll nh-cursor-pointer" [ngClass]="{
                      square: removeButtonShape == 'Square',
                      circle: removeButtonShape == 'Circle'
                    }">
                    {{
                    displayRemoveButton(
                    nestedForm?.value?.value?.configuration
                    )
                    ? displayRemoveButton(
                    nestedForm?.value?.value?.configuration
                    )
                    : "Remove"
                    }}
                  </button>
                </div>
                <ng-template #icon>
                  <em class="nh-icon nh-icon-dash-lg nh-icon-bold"></em>
                  <mat-icon class="nh-display-none">remove</mat-icon>
                </ng-template>
              </button>
            </div>
          </div>
          <ng-container *ngTemplateOutlet="
              nestedEntity;
              context: {
                nestedForm: nestedForm,
                ind: ind,
                first: first
              }
            "></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!getControlsName(nestedForm)">
    <ng-container *ngTemplateOutlet="
        other_form;
        context: {
          nestedForm: nestedForm,
          ind: ind,
          first: first
        }
      "></ng-container>
  </ng-container>
</ng-container>

<ng-template #nestedEntity let-nestedForm="nestedForm" let-first="first" let-ind="ind">
  <div *ngIf="getControlsName(nestedForm)">
    <div *ngIf="getConfigurationType(nestedForm); else nest_not_table">
      <div *ngIf="getConfigurationTableOrCard(nestedForm); else nest_is_card">
        <div *ngIf="getConfigurationTable(nestedForm)">
          <app-dynamic-ui-table [control]="nestedForm" [eventCUList]="eventCUList"
            (selectedTransactionsEmit)="getSelectedTransactions($event)"
            [rowNumberChanged]="rowNumberChangedAfterReload" (rowNumber)="rowNumberChangeAfterReload($event)"
            (removeEntity)="removeEntityfromTable($event)" (selectionEvent)="isSubmitbuttonenabled($event)"
            (fileUploadEvent)="
              fileUploadData($event, nestedForm.key, nestedForm?.value?.controls, control.value.controls, control, ind)
            ">
          </app-dynamic-ui-table>
        </div>
        <div *ngIf="getConfigurationVerticalTable(nestedForm)">
          <app-vertical-dynamic-ui-table [control]="nestedForm" [isInfo]="isInfo"> </app-vertical-dynamic-ui-table>
        </div>
      </div>
      <ng-template #nest_is_card>
        <div *ngIf="getConfigurationCard(nestedForm)">
          <app-dynamic-ui-card [isInfo]="isInfo" [control]="nestedForm" [eventCUList]="eventCUList"
            [selectedCardsList]="selectedCards" (selectedCardsEmit)="getSelectedCards($event)"
            (removeEntity)="removeCards($event)" (selectionEvent)="isSubmitbuttonenabled($event)">
          </app-dynamic-ui-card>
        </div>
      </ng-template>
    </div>
    <ng-template #nest_not_table>
      <app-nested-form [control]="nestedForm" [submitted]="submitted" [group]="group" [isInfo]="isInfo"
        [eventCUList]="eventCUList" (dateChangedEmit)="dateChnagedEvent($event)"
        (formValueChange)="addDynamicAttributes($event)" (nestedEntityTableSelections)="getSelectedTransactions($event)"
        (removeNestedEntity)="removeEntityfromTable($event)"
        (nestedEntityRemoveTableSelections)="removeEntityfromTable($event)" (dateChangedEmit)="dateChnagedEvent($event)"
        (nestedEntityAdded)="nestedEntitiesAdded($event)" class="inner-accordian">
      </app-nested-form>
    </ng-template>
  </div>

  <ng-container *ngIf="!getControlsName(nestedForm)">
    <ng-container *ngTemplateOutlet="
        nest_other_form;
        context: {
          nestedForm: nestedForm,
          ind: ind,
          first: first
        }
      "></ng-container>
  </ng-container>
</ng-template>

<ng-template #nest_other_form let-nestedForm="nestedForm" let-first="first" let-ind="ind">
  <ng-container *ngFor="
      let nestedForm1 of nestedForm?.value?.controls | keyvalue: originalOrder;
      let index = index
    ">
    <div *ngIf="getControlsName(nestedForm1); else other_form1">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{
            (control?.value?.value)[nestedForm?.key]?.entityHideLabel
            ? ""
            : (nestedForm?.value?.value)[nestedForm1?.key]?.displayName
            ? (nestedForm?.value?.value)[nestedForm1?.key]?.displayName
            : (nestedForm1?.key | split: "$")
            }}
            <span *ngIf="(nestedForm?.value?.value)[nestedForm1?.key]?.isMulti"
              style="cursor: pointer; padding-left: 5px;" (click)="
                addMultipleEntityControl(
                  nestedForm1?.key,
                  nestedForm1?.value?.controls,
                  nestedForm?.value?.controls,
                  nestedForm,
                  index,
                  $event
                )
              ">
              <div *ngIf="
                  getMultiEntityButton(nestedForm?.value?.value?.configuration);
                  else icon
                ">
                <button type="button" [ngClass]="{
                    square: addButtonShape == 'Square',
                    circle: addButtonShape == 'Circle'
                  }">
                  {{
                  displayAddButton(nestedForm?.value?.value?.configuration)
                  ? displayAddButton(
                  nestedForm?.value?.value?.configuration
                  )
                  : "Add"
                  }}
                </button>
              </div>
              <ng-template #icon>
                <mat-icon>add</mat-icon>
              </ng-template>
            </span>
            <span *ngIf="
                nestedForm.value.value[nestedForm1.key]?.isMulti &&
                !getConfigurationTableOrCard(nestedForm) &&
                getNestedFormValueLength(
                  nestedForm1?.key,
                  nestedForm?.value?.controls
                ) > 1
              " style="cursor: pointer; padding-left: 5px;" (click)="
                deleteEntityControl(
                  nestedForm1.key,
                  nestedForm1.value.controls,
                  nestedForm.value.controls,
                  nestedForm,
                  index,
                  $event
                )
              ">
              <div *ngIf="
                  getMultiEntityButton(nestedForm?.value?.value?.configuration);
                  else icon
                ">
                <button type="button" [ngClass]="{
                    square: removeButtonShape == 'Square',
                    circle: removeButtonShape == 'Circle'
                  }">
                  {{
                  displayRemoveButton(nestedForm?.value?.value?.configuration)
                  ? displayRemoveButton(
                  nestedForm?.value?.value?.configuration
                  )
                  : "Remove"
                  }}
                </button>
              </div>
              <ng-template #icon>
                <mat-icon>remove</mat-icon>
              </ng-template>
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-nested-form [control]="nestedForm1" [submitted]="submitted" [group]="group" [isInfo]="isInfo"
          (dateChangedEmit)="dateChnagedEvent($event)" (formValueChange)="addDynamicAttributes($event)"
          (nestedEntityTableSelections)="getSelectedTransactions($event)"
          (removeNestedEntity)="removeEntityfromTable($event)"
          (nestedEntityRemoveTableSelections)="removeEntityfromTable($event)"
          (dateChangedEmit)="dateChnagedEvent($event)" (nestedEntityAdded)="nestedEntitiesAdded($event)">
        </app-nested-form>
      </mat-expansion-panel>
    </div>
    <ng-template #other_form1>
      <div *ngFor="
          let nestedControl of nestedForm1?.value?.controls;
          let i = index;
          let last = last
        " [ngClass]="{ hide: hideattribute(nestedForm1) }">
        <div [formGroup]="nestedControl">
          <ng-container dynamicField [formData]="nestedControl" [group]="nestedControl" (addmoreAttributes)="
              addMultipleNestedControl(
                nestedForm1.key,
                nestedForm1.value.controls,
                nestedForm1,
                nestedControl,
                i,
                $event
              )
            " (dateChanged)="dateChnagedEvent($event)" (disableSubmitEmit)="disableSubmitEvent($event)"
            (wayPointsLocationData)="getWaypointsData($event)">
          </ng-container>
        </div>
      </div>
      <!-- </ng-template> -->
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #other_form let-nestedForm="nestedForm" let-first="first" let-ind="ind">
  <div *ngIf="
       (nestedForm?.value?.value &&
        (nestedForm?.value?.value)[0]?.inputType &&
        (nestedForm?.value?.value)[0]?.inputType !== 'autoIdentifier') &&
        (first || !integNestedEnt?.includes(nestedForm?.value?.value?.[0]?.nestedEntityName))
      " class="video-player-cont" [ngClass]="{
      'nh-display-none': (nestedForm?.value?.value)[0]?.isHidden === true,
      alternateclass: (nestedForm?.value?.value)[0]?.isAlternate
    }" class="{{ (nestedForm?.value?.value)[0]?.attrClass }}">
    <div class="video-player-cont-wrapper">
      <div class="video-player-cont-inner" [ngClass]="{
          'nh-display-none':
            ((nestedForm?.value?.value)?.[0]?.inputType === 'label' || getShowAsLabelConfig((nestedForm?.value?.value)?.[0]?.attr_configuration)) &&
            (nestedForm?.value?.value?.[0]?.value || '').toString().trim() === '' &&
            getHideLabelStatus((nestedForm?.value?.value)?.[0]?.attr_configuration)
        }">
        <app-basic-form [control]="nestedForm" [submitted]="submitted" [group]="group"
          (formValueChange)="addDynamicAttributes($event)" (dateChangedEmit)="dateChnagedEvent($event)"
          (disableSubmitEmit)="disableSubmitEvent($event)" (waypointsData)="getWaypointsData($event)"
          (clickableOnSelect)="getClickableOnSelectData($event)"></app-basic-form>
      </div>

      <!-- Detect event gsi -->
      <div class="onSelectGsiButton" *ngIf="checkEventGSI(nestedForm) && !isInfo" [ngClass]="{
          healthcaremx: tenantName === 'healthcaremx',
          onSelectGsiButton01: !appliedClass
        }">
        <div *ngFor="let currEvent of currentEventData; let evtIndex = index">
          <ng-container *ngIf="
              !parse((nestedForm?.value?.value)[0]?.attr_configuration)
                ?.clickableConfig?.enableClickableCustomization
            ">
            <div [ngClass]="{ 'ramco-dev': tenantName === 'ramcodev' }" (click)="saveEventGSI(currEvent, nestedForm)"
              *ngIf="allCustomizations.length > 0">
              <button class="nh-unsetAll  {{ appliedClass }}" type="button" mat-button
                *ngIf="allCustomizations?.[evtIndex]?.displayType == 'TEXT'">
                <img *ngIf="
                    selectedOption &&
                    selectedOption !== 'Option 17' &&
                    selectedOption !== 'Option 14' &&
                    imgSrc
                  " [src]="imgSrc" />

                {{
                isTextActionButton
                ? "" + allCustomizations[evtIndex].displayText + ""
                : null
                }}
                <img *ngIf="
                    selectedOption &&
                    (selectedOption === 'Option 17' ||
                      selectedOption === 'Option 14') &&
                    imgSrc
                  " [src]="imgSrc" />
              </button>
              <div class="onlyimage cursor-pointer" *ngIf="allCustomizations[evtIndex]?.displayType == 'IMAGE'">
                <img width="24" height="24" [src]="allCustomizations[evtIndex].imageUrl" alt="" />
              </div>
              <div class="imagewithtext d-flex align-items-center cursor-pointer" *ngIf="
                  allCustomizations[evtIndex]?.displayType == 'IMAGE_AND_TEXT'
                ">
                <img width="20" height="20" [src]="allCustomizations[evtIndex].imageUrl" alt="" />
                <span>{{ allCustomizations[evtIndex].displayText }}</span>
              </div>
            </div>
            <div *ngIf="allCustomizations.length == 0">
              <button class="nh-unsetAll nh-cursor-pointer" type="button" *ngIf="
                  !parse((nestedForm?.value?.value)[0]?.attr_configuration)
                    ?.clickableConfig?.enableClickableCustomization
                " (click)="saveEventGSI(currEvent, nestedForm)">
                <img *ngIf="
                    selectedOption &&
                    selectedOption !== 'Option 17' &&
                    selectedOption !== 'Option 14' &&
                    imgSrc
                  " [src]="imgSrc" />{{
                isTextActionButton
                ? "" +
                (currEvent?.gsiDisplayName
                ? currEvent?.gsiDisplayName
                : currEvent?.gsiName) +
                ""
                : null
                }}<img *ngIf="
                    selectedOption &&
                    (selectedOption === 'Option 17' ||
                      selectedOption === 'Option 14') &&
                    imgSrc
                  " [src]="imgSrc" />
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>