<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeError()">
        {{ labels?.close }}
      </button>
      <label class="status">{{ labels?.Status }}: {{ labels?.Draft }} ({{ labels?.Ver }} 1.1)</label>
    </div>

    <div class="errors-list" *ngIf="errorData">
      <!-- <ul *ngFor="let errorType of errorData | keyvalue"> -->
      <!-- <li>
          <h2>{{errorType.key}}</h2>
          <ul *ngFor="let errorDetails of errorType.value | keyvalue">
            <li>
              <h2>{{errorDetails.key}}</h2>
              <ul *ngFor="let error of errorDetails.value | keyvalue">
                <li>
                  <h2>{{error.key}}</h2>
                  <ul *ngFor="let errDetail of error.value | keyvalue">
                    <li>
                      <h2>{{errDetail.key}}</h2>
                      <p><span>{{errDetail.value.length}}</span> Errors</p>
                      <ul *ngFor="let errval of errDetail.value">
                        <li>
                          <h2 *ngFor="let errMsg of errval.errorDetails">{{errMsg.message}}</h2>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li> -->
      <ul>
        <li>
          <h2>{{ labels?.Overall }}</h2>
          <p class="nh-color-301">
            <span>{{ errorCount }}</span> {{ labels?.Errors }}
          </p>
          <p class="nh-color-951">
            <span>{{ warningCount }}</span> {{ labels?.Warning }}
          </p>
          <p class="nh-color-251">
            <span>{{ infoCount }}</span> {{ labels?.Information }}
          </p>
        </li>
        <li>
          <h2>{{ labels?.Logical_Errors }}</h2>
          <p class="nh-color-301">
            <span>{{ errorCount }}</span> {{ labels?.Errors }}
          </p>
        </li>
      </ul>
    </div>

    <!-- allErrorData = {{allErrorData| json}} -->
    <div class="accordain-list" *ngFor="let errData of allErrorData; let ind = index">
      <mat-accordion *ngIf="errData?.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title class="active-bold">
              {{ labels?.Error_Type }}:
              {{ ind === 0 ? labels?.['Error'] || 'Error' : ind == 1 ?  labels?.['Warning'] || 'Warning' : labels?.['Information'] || 'Information' }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- errData[ind] = {{errData[ind] | json}} -->
          <div class="accordain-list" *ngFor="let accordion of errData">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title class="active-bold"> {{ accordion?.cuName }} </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- accordion.errorMessages = {{accordion.errorMessages | json}} -->
                <div class="accordain-list" *ngFor="let errDetails of accordion?.errorMessages; let errInd = index">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title class="active-bold"> {{ errDetails?.errorType }} </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="cstm-errMsg-design">
                        <ul>
                          <li>
                            <!-- <span> <strong>CU: {{ accordion?.cuName }}</strong></span> -->
                            <ol>
                              <li *ngFor="let errMsg of errDetails?.[errDetails.errorType]; let i = index">
                                <span class="cstm-errMsg-design-content"
                                  ><strong>{{ i + 1 }}: </strong> {{ errMsg }}</span
                                >
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <button *ngIf="errorCount == 0 && warningCount > 0" (click)="warnButtonClicked()" class="skip-warnings-btn">
      {{ labels.Skip_Warnings }}
    </button>
  </div>
</div>

<!-- to show a popup when warnings are present -->
<ng-template #myDialog>
  <div mat-dialog-content class="mypopup">
    <div class="add-additional1">
      <h2>{{ labels?.Do_you_want_to_skip_all_the_warnings }}?</h2>
      <p>{{ labels?.Note }}:</p>
      <p>1. {{ labels?.On_click_of_Yes_all_the_warnings_can_be_skipped }}.</p>
      <p>2. {{ labels?.On_click_on_No_the_solution_can_be_edited }}.</p>
      <div class="yn-btn" mat-dialog-actions>
        <button id="btnYes" (click)="onDialogYesClick()">{{ labels?.Yes }}</button>
        <button id="btnNo" (click)="this.dialog.closeAll()">{{ labels?.No }}</button>
      </div>
    </div>
  </div>
</ng-template>
