<div *ngIf="configType == 'progressBar'; else listType">
  <mat-stepper [orientation]="orientation" labelPosition="bottom" #stepper class="entity-stepper">
    <mat-step
      *ngFor="let formValue of attributeList; let ind = index"
      [completed]="formValue.value?.[0]?.value?.length > 0"
    >
      <ng-container *ngIf="orientation == 'horizontal'; else vertical">
        <ng-template matStepperIcon="done" matStepLabel>
          <div class="horizantal-stepper">
            <span>{{ formValue?.displayValue }}</span>
            <div *ngIf="formValue?.key; else attribute">
              <app-progress-bar-configuration
                [control]="formValue"
                [configuration]="configuration"
                [orientation]="orientation"
                class="entity-col"
              >
              </app-progress-bar-configuration>
            </div>
            <ng-template style="display: inline-grid;" #attribute>
              <span
                [ngStyle]="{
                  color: configuration?.color,
                  'font-size': configuration?.size + 'px'
                }"
                *ngFor="let val of formValue.value"
                class="sub-title"
              >
                <span *ngIf="!hideDefaultValue">{{ val?.value }}</span>
              </span>
            </ng-template>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #vertical>
        <ng-template matStepperIcon="done" matStepLabel>{{ formValue?.displayValue }} </ng-template>
        <div *ngIf="formValue?.key; else attribute">
          <app-progress-bar-configuration
            [control]="formValue"
            [configuration]="configuration"
            [orientation]="orientation"
            class="entity-col"
          >
          </app-progress-bar-configuration>
        </div>
        <div style="display: inline-grid;" #attribute>
          <span
            [ngStyle]="{
              color: configuration?.color,
              'font-size': configuration?.size + 'px'
            }"
            *ngFor="let val of formValue.value"
          >
            <span *ngIf="!hideDefaultValue">{{ val?.value }}</span>
          </span>
        </div>
      </ng-template>
    </mat-step>
  </mat-stepper>
</div>
<ng-template #listType>
  <div *ngFor="let formValue of attributeList">
    <span class="title" [ngClass]="{ nestedtitle: formValue?.key }">{{ formValue?.displayValue }} </span>
    <div *ngIf="formValue?.key; else attribute" style="display: inline-grid;">
      <app-progress-bar-configuration [control]="formValue" [configuration]="configuration" [orientation]="orientation">
      </app-progress-bar-configuration>
    </div>
    <ng-template style="display: inline-grid;" #attribute>
      <span
        [ngStyle]="{
          color: configuration?.color,
          'font-size': configuration?.size + 'px'
        }"
        *ngFor="let val of formValue.value"
        class="sub-title"
      >
        <span *ngIf="!hideDefaultValue">{{ val?.value }}</span>
      </span>
    </ng-template>
  </div>
</ng-template>
