import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirection',
  template: `
    <form class="example-form" [formGroup]="group">
      <label
        class="main-label"
        pTooltip="{{ field?.label }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize"
        *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
        >{{ field.label }}</label
      >
      <a [href]="field.value" class="text-break" (click)="resumeTransaction()">{{ field.value }}</a>
    </form>
  `,
  styles: [],
})
export class RedirectionComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(private eventsService: EventsFacadeService, public actRoute: ActivatedRoute) {}
  ngOnInit(): void {}

  resumeTransaction() {
    const tenantName = localStorage.getItem('TenantName');
    /* istanbul ignore else */
    if (tenantName === 'b2c') {
      /* istanbul ignore next */
      const solutionData: any = atob(this.actRoute.snapshot.params?.solutionData);
      let data: any = JSON.parse(solutionData);
      /* istanbul ignore next */
      const cuData = this.eventsService?.selectedCuData;
      /* istanbul ignore next */
      if (cuData) {
        data = {
          ...data,
          isResumeTxn: true,
          triggerCuId: cuData?.currentCU?.id,
          txnId: cuData?.transId,
        };
        /* istanbul ignore next */
        const finalInfo = btoa(JSON.stringify(data));
        localStorage.setItem('resumeTransactionUrl', finalInfo);
      }
    }
  }
}
