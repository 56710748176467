import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig } from '@common-services';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-qr-scanner-control',
  templateUrl: './qr-scanner-control.component.html',
  styleUrls: ['./qr-scanner-control.component.scss'],
})
export class QrScannerControlComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) { }

  ngOnInit(): void { }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
}
