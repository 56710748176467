import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService } from '../translator/translator.service';
import { AlertService } from '@common-services';

@Component({
  selector: 'app-card-view-control',
  templateUrl: './card-view-control.component.html',
  styleUrls: ['./card-view-control.component.scss'],
})
export class CardViewControlComponent implements OnInit, OnChanges, OnDestroy {
  ngUnsubscribe: any = new Subject();
  labels: any;
  templateType: any;
  isSelectTemplate: boolean = false;
  isChecked: boolean = false;
  isTitleSlect: boolean = false;
  selectionType: any;
  lableName: any;
  templateObj = {
    pos_1: 'Attribute_1',
    pos_2: 'Attribute_2',
    pos_3: 'Attribute_3',
    pos_4: 'Attribute_4',
    pos_5: 'Attribute_5',
    pos_6: 'Attribute_6',
    pos_7: 'Attribute_7',
  };
  selcetedPositions = {
    pos_1: false,
    pos_2: false,
    pos_3: false,
    pos_4: false,
    pos_5: false,
    pos_6: false,
    pos_7: false,
  };
  @Input() isGrammer: boolean;
  @Input() cardEntityData: any;
  @Input() editObject: boolean = true;
  @Output() cardViewEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() cardcustomizeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translator: TranslatorService, private alertService: AlertService) {
    this.detectLanguageChange();
  }

  ngOnChanges(simple: SimpleChanges) {
    /* istanbul ignore next  */
    this.cardEntityData?.nslAttributes?.forEach((attr: any) => {
      attr['isSelected'] = false;
    });
    /*istanbul ignore next */
    if (typeof this.cardEntityData?.configuration == 'string') {
      this.cardEntityData.configuration = JSON.parse(this.cardEntityData?.configuration);
    }
    if (
      this.cardEntityData?.configuration &&
      typeof this.cardEntityData?.configuration === 'string' &&
      this.IsJsonString(this.cardEntityData?.configuration)
    ) {
      this.cardEntityData.configuration = JSON.parse(this.cardEntityData?.configuration);
    }
    /*istanbul ignore next */
    if (
      this.cardEntityData?.configuration?.setting &&
      Object.keys(this.cardEntityData?.configuration?.setting).length > 0
    ) {
      this.selectionType = this.cardEntityData?.configuration?.setting?.selectionType;
      this.templateType = 'template_' + this.cardEntityData?.configuration?.setting?.templateType;
      /* istanbul ignore next */
      if (this.cardEntityData?.configuration?.type == 'cardWithTemplate') {
        this.isSelectTemplate = true;
      }
      /* istanbul ignore next */
      this.lableName = this.cardEntityData?.configuration?.setting?.lableName;
    }

    /*istanbul ignore next */
    if (this.cardEntityData?.configuration?.setting?.attributePositions) {
      this.isChecked = true;
    }
    /*istanbul ignore next */
    if (this.cardEntityData?.configuration?.setting?.attributePositions) {
      for (const [key, value] of Object.entries(this.cardEntityData?.configuration?.setting?.attributePositions)) {
        /*istanbul ignore next */
        if (value) {
          this.templateObj['pos_' + value] = key;
          this.selcetedPositions['pos_' + value] = false;
        }
      }
      this.selectTemaplate(this.templateType);
    }
  }

  ngOnInit() {}

  /** select the attribute name */
  slectAttribute(attr: any) {
    attr.isSelected = true;
    this.addAttributesToPositions(attr);
  }

  /** Add the attribute names and filtered remaining attributes */
  /*istanbul ignore next */
  addAttributesToPositions(attr: any) {
    for (const [key, value] of Object.entries(this.selcetedPositions)) {
      if (value) {
        this.templateObj[key] = attr.name;
        this.selcetedPositions[key] = false;
      }
    }
  }

  /** Stop the event propagation for parent when we open the menu */
  openAttributeList(event: any) {
    event.stopPropagation();
  }

  /** select the selection mode for the attributes */
  slectePosionForAttr(attrType: any) {
    this.selcetedPositions = {
      pos_1: false,
      pos_2: false,
      pos_3: false,
      pos_4: false,
      pos_5: false,
      pos_6: false,
      pos_7: false,
    };
    switch (attrType) {
      case 'one':
        this.selcetedPositions.pos_1 = true;
        break;

      case 'two':
        this.selcetedPositions.pos_2 = true;
        break;

      case 'three':
        this.selcetedPositions.pos_3 = true;
        break;

      case 'four':
        this.selcetedPositions.pos_4 = true;
        break;

      case 'five':
        this.selcetedPositions.pos_5 = true;
        break;

      case 'six':
        this.selcetedPositions.pos_6 = true;
        break;

      case 'seven':
        this.selcetedPositions.pos_7 = true;
        break;
    }
  }

  /** remove the selection mode for the attribute */
  deleteSelection(event: any, position: any) {
    event.stopPropagation();
    this.selcetedPositions[position] = false;
  }

  /**unmap attributes and reset the attribute position */
  unMapAttribute(event: any, key: any) {
    event.stopPropagation();
    this.selcetedPositions[key] = false;
    /*istanbul ignore next */
    this.cardEntityData.nslAttributes?.forEach((attr: any) => {
      if (this.templateObj[key] == attr.name) {
        attr.isSelected = false;
      }
    });
    this.templateObj[key] = 'Attribute ' + key?.split('_')[1];
  }

  /* get the translation lables based on selected language*/
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  /* saving tuples data for card view entity */
  saveCardViewDetails() {
    let obj = {};
    let isValid = false;
    for (const [key, value] of Object.entries(this.templateObj)) {
      if (!value.includes('Attribute_')) {
        obj[value] = key?.split('_')[1];
        isValid = true;
      }
    }
    if (isValid) {
      const settings = {
        templateType: (this.templateType + '')?.split('_')[1],
        attributePositions: obj,
        selectionType: this.selectionType,
        lableName: this.lableName,
      };
      this.cardViewEvent.emit(settings);
    } else {
      this.alertService.showToaster('Please map the attribute positions to continue', '', 'error');
    }
  }

  /* cancel tuples data for card view entity */
  cancelCardViewDetails() {
    this.cancelEvent.emit();
  }

  // select selection type //
  onSelectionChange(event: any) {}

  selectTemaplate(type: any, event?: MouseEvent) {
    if (event) {
      if (event.defaultPrevented) {
        return;
      }
      event.preventDefault();
    }
    this.templateType = type;
    if (type == 'template_3') {
      this.isSelectTemplate = true;
      //this.alertService.showToaster('Not implimented Yet', '', 'info');
      const settings = {
        selectionType: this.selectionType,
        lableName: this.lableName,
      };
      this.cardcustomizeEvent.emit(settings);
    } else {
      this.isSelectTemplate = true;
    }
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /* destroy the subscribes */
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
