import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileExplorerEndpointService } from './file-explorer-endpoint.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class FileExplorerFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  //all icons
  public iconSubject = new Subject();
  allIcons$ = this.iconSubject.asObservable();

  //all books
  public allBooksSubject = new Subject();
  allBooks$ = this.allBooksSubject.asObservable();

  //all cards
  public allCardsSubject = new Subject();
  allCards$ = this.allCardsSubject.asObservable();

  //added new book
  public addedBookSubject = new Subject();
  addedBook$ = this.addedBookSubject.asObservable();

  //added new card
  public addedCardSubject = new Subject();
  addedCard$ = this.addedCardSubject.asObservable();

  //added new icon
  public addedIconSubject = new Subject();
  addedIcon$ = this.addedIconSubject.asObservable();

  //get all folder
  allFoldersSubject = new Subject();
  allFolders$ = this.allFoldersSubject.asObservable();

  private myLibrarayWallPapers = new Subject();
  myLibrarayWallPapers$ = this.myLibrarayWallPapers.asObservable();

  //get searched files
  private searchFilesSubject = new Subject();
  searchFiles$ = this.searchFilesSubject.asObservable();

  constructor(private fileExplorerEndpointService: FileExplorerEndpointService, private loader: LoaderService) {}

  //get cu icons
  getCuIcons(pageNumber: number) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getCuIcons(pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.iconSubject.next(res);
      });
  }

  //get solutions icons
  getSolutionIcons(pageNumber: number) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getSolutionIcons(pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.iconSubject.next(res);
      });
  }

  //get entity icons
  getEntityIcons(pageNumber: number) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getEntityIcons(pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.iconSubject.next(res);
      });
  }

  //get all books
  getAllBooks(pageNumber: number) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getAllBooks(pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allBooksSubject.next(res);
      });
  }

  //get all cards
  getAllCards(pageNumber: number) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getCardIcons(pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allCardsSubject.next(res);
      });
  }

  //add book cover
  addBookCover(bookData: any) {
    this.loader.show();
    this.fileExplorerEndpointService
      .addBookCover(bookData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.addedBookSubject.next(res);
      });
  }

  //add card cover
  addCardCover(bookData: any) {
    this.loader.show();
    this.fileExplorerEndpointService
      .addCardCover(bookData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.addedCardSubject.next(res);
      });
  }

  //add icons
  addIcon(iconData: any, iconType: any) {
    this.loader.show();
    this.fileExplorerEndpointService
      .addIcon(iconData, iconType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.addedIconSubject.next(res);
      });
  }

  //get all folders
  getAllFolders(folderName: string, query?: string, isExclusive?: boolean, limit?: any, pageNumber?: any) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getAllFolders(folderName, query, isExclusive, limit, pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allFoldersSubject.next(res);
        this.myLibrarayWallPapers.next(res);
      });
  }

  //get files on scrolls
  getNextFiles(folderName: string, pageNumber: any) {
    this.loader.show();
    this.fileExplorerEndpointService
      .getNextFiles(folderName, pageNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allFoldersSubject.next(res);
      });
  }

  //get files on search
  browseFiles(query: any, pageNumber: any) {
    var folderName = sessionStorage.getItem('folderName');
    this.loader.show();
    this.fileExplorerEndpointService
      .browseFiles(query, pageNumber, folderName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.searchFilesSubject.next(res);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
