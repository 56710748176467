<ul [ngClass]="!graphComponent ? 'node-static' : ''">
  <li>
    <div [ngClass]="!graphComponent ? 'node-block sol' : ''">
      <span class="node-label" *ngIf="cuName">
        {{ cuName }}
      </span>
      <section class="pop-outer" *ngIf="!cuName">
        <div class="pop-inner">
          <header class="pop-header">
            <div class="row no-gutters flex-nowrap align-items-center range-control agent-control">
              <div class="col">
                <h2 class="pop-title">
                  <span class="clickbk" (click)="GotoPrevious()"
                    ><img [src]="'../../../../assets/node/back.svg'" alt=""
                  /></span>
                  <a>{{ labels?.Change_Unit }}</a>
                </h2>
              </div>
            </div>
          </header>
          <div class="pop-body">
            <div>
              <div class="mb-4" *ngFor="let cu of changeUnitList; let i = index">
                <div class="row mb-2">
                  <div class="col-auto ml-auto">
                    <button class="delete-btn-icon" (click)="deleteNestedCu()">{{ labels?.Delete }}</button>
                  </div>
                </div>
                <div class="mb-2">
                  <div class="no-label no-space three">
                    <mat-form-field class="d-block" appearance="outline">
                      <mat-label *ngIf="cuLabel">{{ cuLabel }}</mat-label>
                      <input
                        matInput
                        placeholder="Name Your Change Unit"
                        [(ngModel)]="changeUnitList[i].name"
                        [disabled]="isDisabled"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="new-addLibrary" id="openSingleCULibrary">
              <ul>
                <li>
                  <div class="library-open" (click)="selectNestedGsiFromLibrary()" matTooltip="Add_From_Library"></div>
                </li>
                <li>
                  <div class="nsl-open"></div>
                </li>
              </ul>
            </div>
          </div>
          <footer class="pop-footer">
            <div class="row mb-3">
              <div class="col-auto mx-auto">
                <button class="primary-btn" (click)="saveNestedCu()">{{ labels?.Done }}</button>
              </div>
            </div>
          </footer>
        </div>
      </section>
    </div>
  </li>
</ul>
