import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { CHECK_BOX_RANGE, CHECK_BOX_SINGLE, CHIP_SELECTION_RANGE, CHIP_SELECTION_SINGLE, DATES, DATE_AND_COST, DATE_RANGE, DATE_SINGLE, LOCATE, RADIO_BUTTON, RATINGS, SLIDER_RANGE, SLIDER_SINGLE, templateMapper, TOGGLE_BUTTONS, CSTM_FILTER } from '../../../constants/customize-filter-templates.constants';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-filter-templates',
  templateUrl: './filter-templates.component.html',
  styleUrls: ['./filter-templates.component.scss'],
})
export class FilterTemplatesComponent implements OnInit, OnChanges {
  @Input() id: any;
  @Input() data: any;
  @Input() count: any;
  @Input() individual: any[] = [];
  @Input() propertyName: string;
  filterType: any;
  rangeDates: Date[];
  rangeValues: number[] = [0, 100];
  sliderValues: number[] = [0, 100];
  value: number = 30;
  sortOptionAttr: string;
  sortOption: number;
  sort: boolean;
  date14: Date | undefined;
  dummyVariable: any;
  radioButtonValue: any;
  labels: any;
  templateMap = { ...templateMapper };
  @Output() emitData = new EventEmitter();
  @Output() emitSortData = new EventEmitter();
  @Input() set _clearAllFlag(value: boolean) {
    this.clearAll();
  }
  @Output() emitPropertyName = new EventEmitter();
  static dynamicName = 0;
  name: number;
  tempRangeData: Date[];
  ngUnsubscribe = new Subject();
  constructor(private translator: TranslatorService) {
    this.detectLanguageChange();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  ngOnChanges(): void {
    this.templateMapperFunction();
  }

  ngOnInit(): void {
    FilterTemplatesComponent.dynamicName = FilterTemplatesComponent.dynamicName + 1;
    this.name = FilterTemplatesComponent.dynamicName;
  }

  /**
   * The function `templateMapperData` performs various operations on data based on the template
   * mapping.
   * @param {any} map - The `map` parameter is an object that contains key-value pairs. It is used to
   * map template IDs to data constants.
   * @returns The function does not have a return statement, so it does not explicitly return anything.
   */
  templateMapperData(map: any) {
    let dataConstant = map[templateMapper[this.id]];
    const dummyData = dataConstant?.find((id: any) => {
      return this.id === id?.id;
    });
    this.dummyVariable = JSON.parse(JSON.stringify(dummyData));
    /*istanbul ignore else */
    if (templateMapper[this.id]?.includes?.('DATE')) {
      return;
    }
    /*istanbul ignore else */
    if (templateMapper[this.id]?.includes?.('RATINGS') && this.data) {
      this.data?.sort((a: any, b: any) => b.value - a.value);
      let count = 0;
      this.data = this.updateFloatAndIntPossibleValues(this.data);
      this.data?.map((data: any) => {
        count += data.count;
        data.count = count;
        return data;
      });
    }
    /*istanbul ignore else */
    if (this.data) {
      this.dummyVariable.data = this.data;
    }
    /*istanbul ignore else */
    if (templateMapper[this.id]?.includes?.('SLIDER') && this.data) {
      this.sliderValues = JSON.parse(JSON.stringify([this.data.minValue, this.data.maxValue]));
    }
  }

  updateFloatAndIntPossibleValues(data: any[]): any[] {
    var output: any[] = [];

    data.forEach(function (item) {
      var existing = [];
      if(item.name){
       existing = output.filter(function (v, i) {
        return v.name == item.name;
      });
      }
      if (existing.length) {
        var existingIndex = output.findIndex((val: any) => parseInt(val?.value) == parseInt(existing?.[0]?.value));
        output[existingIndex].count += item.count;
      } else {
        output.push(item);
      }
    });
    return output;
  }

  /**
   * The function maps template names to their corresponding values based on the provided map.
   */
  templateMapperFunction(): void {
    let map = {
      SLIDER_RANGE: SLIDER_RANGE,
      SLIDER_SINGLE: SLIDER_SINGLE,
      CHIP_SELECTION_SINGLE: CHIP_SELECTION_SINGLE,
      CHIP_SELECTION_RANGE: CHIP_SELECTION_RANGE,
      CHECK_BOX_RANGE: CHECK_BOX_RANGE,
      CHECK_BOX_SINGLE: CHECK_BOX_SINGLE,
      LOCATE: LOCATE,
      RATINGS: RATINGS,
      DATE_SINGLE: DATE_SINGLE,
      DATE_RANGE: DATE_RANGE,
      RADIO_BUTTON: RADIO_BUTTON,
      TOGGLE_BUTTONS: TOGGLE_BUTTONS,
      DATES: DATES,
      DATE_AND_COST: DATE_AND_COST,
      CSTM_FILTER: CSTM_FILTER,
    };
    if (this.id == 'sortBy') {
      this.sort = true;
    } else {
      this.templateMapperData(map);
    }
  }

  /**
   * The function "changeSliderEvent" updates the slider values based on the event values and emits the
   * updated data.
   * @param {any} event - The event parameter is of type any, which means it can be any type of object.
   * It is likely an event object that is triggered when the slider value changes.
   */
  changeSliderEvent(event: any) {
    const diff: number = this.dummyVariable?.data?.['maxValue'] - this.dummyVariable?.data?.['minValue'];
    this.sliderValues[0] = parseInt(this.dummyVariable?.data?.['minValue']) + (diff * event?.values?.[0]) / 100;
    this.sliderValues[1] = parseInt(this.dummyVariable?.data?.['minValue']) + (diff * event?.values?.[1]) / 100;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }

  /**
   * The function "changeSingleSliderValue" updates the value of a slider based on a user input event
   * and emits the updated data.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the value of the slider that was
   * changed.
   */
  changeSingleSliderValue(event: any) {
    const diff: number = this.dummyVariable?.data?.['maxValue'] - this.dummyVariable?.data?.['minValue'];
    this.sliderValues[1] = parseInt(this.dummyVariable?.data?.['minValue']) + (diff * event?.value) / 100;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }

  /**
   * The emitCheckBox function updates the checked attribute based on the event's checked property and
   * emits the dummyVariable's data through the emitData event.
   * @param {any} event - The event parameter is of type "any", which means it can be any type of
   * object. It is likely an event object that is triggered when the checkbox is clicked or its state
   * changes.
   * @param {any} attr - The `attr` parameter is an object that represents the checkbox element. It is
   * used to access and modify the properties of the checkbox, such as the `checked` property.
   */
  emitCheckBox(event: any, attr: any) {
    attr.checked = event?.checked;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }

  /**
   * The function `emitChipData` toggles the `checked` attribute of an object and emits the
   * `dummyVariable.data` through an event.
   * @param {any} attr - The `attr` parameter is of type `any`, which means it can accept any data
   * type.
   */
  emitChipData(attr: any) {
    attr.checked = !attr.checked;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable.data);
  }

  /**
   * The function `emitToggleData` updates the `checked` attribute based on the `event.checked` value
   * and emits the `dummyVariable.data` through the `emitData` event.
   * @param {any} event - The `event` parameter is of type `any`, which means it can be any type of
   * value. It is likely an event object that is passed to the function when an event is triggered.
   * @param {any} attr - The `attr` parameter is used to store the value of the `checked` property of
   * an element.
   */
  emitToggleData(event: any, attr: any) {
    attr.checked = event?.checked;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }

  /**
   * The function `emitRadioButton` updates the `checked` property of an array of objects based on the
   * selected value and emits the updated data.
   * @param {any} event - The event parameter is of type any and represents the event object that
   * triggered the emitRadioButton function.
   */
  emitRadioButton(event: any) {
    this.dummyVariable?.data?.forEach((attr: any) => {
      if (attr?.value === event?.value) {
        attr.checked = true;
      } else {
        attr.checked = false;
      }
    });
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }
  /**
   * The `emitDateRange` function checks if a date range is selected and emits the selected range
   * through an event.
   * @param {any} event - The `event` parameter is of type `any`, which means it can accept any type of
   * value. It is likely used to capture the event that triggers the `emitDateRange` function.
   */
  emitDateRange(event: any) {
    /*istanbul ignore else */
    if (this.rangeDates && this.rangeDates?.length > 0 && this.rangeDates?.[0] && this.rangeDates?.[1]) {
      this.dummyVariable.data.value = this.rangeDates;
      this.emitData.emit(this.dummyVariable.data);
    }
  }

  /**
   * The function `emitDateSingle` assigns the value of the `event` parameter to a property called
   * `value` in the `dummyVariable.data` object, and then emits the `dummyVariable.data` object using
   * the `emitData` event emitter.
   * @param {any} event - The event parameter is of type any, which means it can accept any data type.
   */
  emitDateSingle(event: any) {
    this.dummyVariable.data.value = event;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }

  /**
   * The function `emitRatingCheckBox` updates the `checked` property of an attribute based on an event
   * and emits data.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It could be an event like a button click or a checkbox change.
   * @param {any} attr - The `attr` parameter is the attribute or property that you want to update
   * based on the checkbox event.
   */
  emitRatingCheckBox(event: any, attr: any) {
    attr.checked = event?.checked;
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }

  /**
   * The function `emitRatingRadioButton` updates the `checked` property of an attribute based on the
   * value of an event, and then emits the updated data.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It could be an event object from a user interaction, such as a button click or a radio
   * button selection.
   */
  emitRatingRadioButton(event: any) {
    this.dummyVariable?.data.forEach((attr: any) => {
      if (attr?.value === event?.value) {
        attr.checked = true;
      } else {
        attr.checked = false;
      }
    });
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable?.data);
  }
  /**
   * The function emits a rating click event and updates the checked status of attributes based on the
   * clicked attribute.
   * @param {any} attr - The attr parameter is an object that represents a rating attribute. It may
   * have properties such as value and checked.
   */
  emitRatingClick(attr: any) {
    attr.checked = true;
    this.dummyVariable?.data?.forEach((attr1: any) => {
      if (attr1?.value != attr?.value) {
        attr1.checked = false;
      }
    });
    this.emitPropertyName.emit(this.propertyName);
    this.emitData.emit(this.dummyVariable.data);
  }
  /**
   * The function `emitSortRadioButton` updates the sort options and emits an event with the updated
   * sort data.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It could be an event object from a user interaction, such as a button click or a radio
   * button selection. The specific properties and values of the event object would depend on the type
   * of event being handled.
   * @param {string} sortOption - A string representing the selected sorting option.
   */
  emitSortRadioButton(event: any, sortOption: string) {
    /*istanbul ignore else */
    if (sortOption === 'sortOption') {
      this.sortOption = event.value;
    } else if (sortOption === 'sortOptionAttr') {
      this.sortOptionAttr = event.value;
    }
    /*istanbul ignore else */
    if (this.sortOption && this.sortOptionAttr) {
      this.emitSortData.emit({ sortOption: this.sortOption, sortOptionAttr: this.sortOptionAttr });
    }
  }
  clearAll() {
    if (this.templateMap[this.id] == 'RADIO_BUTTON') {
      this.radioButtonValue = '';
    } else if (this.templateMap[this.id].includes('CHIP') ||this.templateMap[this.id].includes('RATINGS') ||  this.templateMap[this.id].includes('TOGGLE') ) {
      this.dummyVariable?.data.forEach((attr: any) => {
        if (attr.checked) {
          attr.checked = false;
        }
      });
    } else if (this.templateMap[this.id] == 'DATE_RANGE') {
      this.rangeDates = this.tempRangeData;
      this.emitData.emit(this.rangeDates);
    } else if (this.templateMap[this.id] == 'DATE_SINGLE' && this.dummyVariable?.data) {
      this.dummyVariable.data.value = '';
      this.date14 = undefined;
      // this.emitData.emit(this.dummyVariable.data);
    }
  }
}
