<div class="top_div">
  <h2>{{ labels?.Contextual_ID_Generation }}</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="threshold_input">
  <input
    [(ngModel)]="listRundownErrorInfo.failedRecordsThreshold"
    placeholder="{{ labels?.Enter_Failed_Records_Threshold || 'Enter Failed Records Threshold' }}"
    type="number"
    matInput
  />
</div>

<div>
  <h4>{{ labels?.Select_Layer }}</h4>
  <mat-form-field>
    <mat-label>{{ labels?.Select_Layer }}</mat-label>
    <mat-select [(ngModel)]="selectedLayer">
      {{ labels?.Select_Layer }}
      <mat-option *ngFor="let layer of layers" [value]="layer">{{ layer?.type }} </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div>
  <h4>Select Entity</h4>
  <mat-form-field>
    <mat-label>Select Entity</mat-label>
    <mat-select [(ngModel)]="selectedEntityIndex">
      <mat-option *ngFor="let entity of selectedLayer?.participatingItems; let i = index" [value]="i">
        {{ entity?.displayName ? entity?.displayName : entity?.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="selectedLayer?.participatingItems?.[selectedEntityIndex]">
  {{selectedLayer?.participatingItems?.[selectedEntityIndex]?.name}}
  <button mat-icon-button (click)="deleteSelectedEntity()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
<div>
  <button (click)="generateContextualId()" class="generateID_button">
    {{ labels?.Generate_Contextual_ID }}
  </button>
</div>
