<ul class="common-list" *ngIf="displayType == 'Cu'">
  <li *ngFor="let suggestion of suggestions?.result">
    <div class="d-flex item align-items-center">
      <div class="details">
        <p class="sub">{{ suggestion?.layers ? 'Change Unit' : 'Entity' }}</p>
        <p
          class="title"
          pTooltip="{{ suggestion?.displayName }}"
          tooltipPosition="top"
          tooltipStyleClass="transcustomtolltipbook"
        >
          {{ suggestion?.name }}
        </p>
      </div>
      <a class="add-icon"><span></span></a>
      <a class="copy-icon"><span></span></a>
      <a class="more-icon"><span></span></a>
    </div>
  </li>
</ul>
<!-- NSL Suggestions -->
<ul class="common-list" *ngIf="displayType == 'NSL_Library'">
  <li *ngFor="let suggestion of nslSuggestions?.data">
    <div class="d-flex item align-items-center">
      <div class="details">
        <p class="title" matTooltip="{{ suggestion?.displayName }}">
          {{ suggestion?.displayName }}
        </p>
      </div>
      <a class="add-icon" (click)="saveNslLibrary(suggestion)"><span></span></a>
      <a class="copy-icon"><span></span></a>
      <a class="more-icon"><span></span></a>
    </div>
    <div class="conflicts-canvas" *ngIf="hasConflict && suggestion?.dsdId == currentBookDetails?.dsdId">
      <div class="conflicts-redbg">
        <span><mat-icon>warning</mat-icon></span>
        <span>{{ labels?.Book_With_This_Name_Present_In_Your_Library }}</span>
        <button class="btn btn-conflict" (click)="resolveConflicts()">
          {{ labels?.Resolve_Conflict }}
        </button>
      </div>
    </div>
  </li>
</ul>
<div class="tab-footer">
  <app-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [totalRecords]="total"
    (onPageChange)="onPageChange($event)"
  >
  </app-paginator>
</div>
<!-- Entity Suggestion -->
<ul class="common-list" *ngIf="displayType == 'Entity'">
  <li *ngFor="let suggestion of suggestions?.result">
    <div class="d-flex item align-items-center">
      <div class="details">
        <p class="sub">{{ suggestion?.layers ? 'Change Unit' : 'Entity' }}</p>
        <p class="title">{{ suggestion?.name }}</p>
      </div>
      <a class="add-icon" (click)="importEntity(suggestion)"><span></span></a>
      <a class="copy-icon"><span></span></a>
      <a class="more-icon"><span></span></a>
    </div>
  </li>
</ul>
<!-- Attribute recommendation -->
<ul class="common-list" *ngIf="displayType == 'Attribute'">
  <li *ngFor="let suggestion of attributeSuggestions?.result">
    <div class="d-flex item align-items-center">
      <div class="details">
        <p class="sub">{{ labels?.Attributes }}</p>
        <p class="title">{{ suggestion?.name }}</p>
      </div>
      <a class="add-icon" (click)="importAttribute(suggestion)"><span></span></a>
      <a class="copy-icon"><span></span></a>
      <a class="more-icon"><span></span></a>
    </div>
  </li>
</ul>

<!-- Agent Reccomendation -->
<ul class="common-list" *ngIf="displayType == 'Agent'">
  <li *ngFor="let suggestion of agentSuggestions?.roles">
    <div class="d-flex item align-items-center">
      <div class="details">
        <p class="sub">{{ labels?.Agents }}</p>
        <p class="title">{{ suggestion?.rightHolderName }}</p>
      </div>
      <a class="add-icon" (click)="importAgent(suggestion)"><span></span></a>
      <a class="copy-icon"><span></span></a>
      <a class="more-icon"><span></span></a>
    </div>
  </li>
</ul>

<!--Nested Recomendations-->
<ul class="common-list" *ngIf="nestedSuggestions?.length > 0">
  <li *ngFor="let suggestion of nestedSuggestions">
    <div class="d-flex item align-items-center">
      <div class="details">
        <p class="sub">{{ labels?.Nested }}</p>
        <p class="title">{{ suggestion?.name }}</p>
      </div>
      <a class="add-icon" (click)="saveGSIWithCompositeAPI(suggestion)"><span></span></a>
      <a class="copy-icon"><span></span></a>
      <a class="more-icon"><span></span></a>
    </div>
  </li>
</ul>

<!-- Feedback Form for DLD -->
<div
  class="feedback-form"
  *ngIf="
    (displayType == 'Entity' && suggestions?.result?.length > 0) ||
    (displayType == 'Attribute' && attributeSuggestions?.result?.length > 0) ||
    (displayType == 'Cu' && suggestions?.result?.length > 0)
  "
>
  <div class="feedback-content" *ngIf="isShown" id="divshow">
    <span class="material-icons feedback-close" (click)="toggleShow()">
      {{ labels?.close }}
    </span>
    <div class="rating-stars">
      <mat-icon
        [ngClass]="{ 'rating-active': dldFeedbackRating >= 1 }"
        class="material-icons-outlined star"
        (click)="getRating(1)"
        >{{ labels?.star_outline }}
      </mat-icon>
      <mat-icon
        [ngClass]="{ 'rating-active': dldFeedbackRating >= 2 }"
        class="material-icons-outlined star"
        (click)="getRating(2)"
        >{{ labels?.star_outline }}</mat-icon
      >
      <mat-icon
        [ngClass]="{ 'rating-active': dldFeedbackRating >= 3 }"
        class="material-icons-outlined star"
        (click)="getRating(3)"
        >{{ labels?.star_outline }}</mat-icon
      >
      <mat-icon
        [ngClass]="{ 'rating-active': dldFeedbackRating >= 4 }"
        class="material-icons-outlined star"
        (click)="getRating(4)"
        >{{ labels?.star_outline }}</mat-icon
      >
      <mat-icon
        [ngClass]="{ 'rating-active': dldFeedbackRating === 5 }"
        class="material-icons-outlined star"
        (click)="getRating(5)"
        >{{ labels?.star_outline }}</mat-icon
      >
    </div>
    <form>
      <textarea class="text-feedback" placeholder="Please provide feedback" #feedbackFormComments></textarea>
      <button
        class="btn submit"
        [disabled]="!feedbackFormComments.value && dldFeedbackRating === 0"
        (click)="submitFeedbackForm(feedbackFormComments.value)"
      >
        {{ labels?.submit }}
      </button>
    </form>
  </div>
  <button class="feedback" (click)="toggleShow()">
    <span class="material-icons">
      {{ labels?.chat_bubble_outline }}
    </span>
    {{ labels?.Feedback }}
  </button>
</div>
