import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  GsiFacadeService, LibraryFacadeService,
  NodeGsiFacadeService,
  TranslatorService,
  pagination
} from '@common-services';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-node-system-agent',
  templateUrl: './node-system-agent.component.html',
  styleUrls: ['./node-system-agent.component.scss'],
})
export class NodeSystemAgentComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @Output() betCountEmitter = new EventEmitter();
  pageIndex: number = 0;
  pageSize: number = 15;
  userListByRoles: any[] = [];
  searchText: string = '';
  agentsList: any = {
    totalRoles: [],
    rolesList: [],
  };
  displayNodesearch: any;
  labels: any;
  searchInput = new Subject();
  openAgentsPanel: boolean = false;
  currentAgentId: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private translator: TranslatorService,
    private libraryFacadeService: LibraryFacadeService,
    private gsiFacadeservice: GsiFacadeService
  ) {
    this.detectAgents();
    this.detectLanguageChange();
    this.detectSearchInput();
  }

  ngOnInit(): void {
    this.getAllAgents();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  getAllAgents() {
    /* istanbul ignore next */
    this.gsiFacade.getAllAgents(this.pageIndex, this.pageSize, this.searchText, true, true);
  }
  detectAgents() {
    this.gsiFacade.getAgents$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.agentsList = res;
      this.betCountEmitter.next(res?.totalRoles);
    });
  }
  onPageChange(pageData: pagination) {
    this.pageSize = pageData.pageSize;
    this.pageIndex = pageData.pageIndex;
    this.getAllAgents();
  }
  addAgent(agent: any) {
    this.gsiFacade.selectedAgent(agent);
  }

  moreAgent(agent: any) {
    this.currentAgentId = agent.id;
    if (this.openAgentsPanel) {
      this.openAgentsPanel = false;
    } else {
      this.openAgentsPanel = true;
      this.getUserByRole(agent.id);
    }
  }

  getUserByRole(id: any) {
    this.userListByRoles = [];
    this.libraryFacadeService.getAllUsers(id);
    this.gsiFacadeservice.usersSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.userListByRoles = res;
      }
    });
  }

  detectSearchInput() {
    /* istanbul ignore next */
    this.searchInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        /* istanbul ignore next */
        this.getAllAgents();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

