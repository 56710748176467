import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { LabelWithImageComponent } from './label-with-image.component';

@NgModule({
  declarations: [LabelWithImageComponent],
  imports: [ControlsMaterialModule],
  exports: [LabelWithImageComponent],
})
export class LabelWithImageModule {
  static entry = {
    labelwithimage: LabelWithImageComponent,
  };
}
