import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { updateBetSelectedItems } from '@common-services';
import { AlertService, ChangeUnit, GsiFacadeService, TranslatorService, isReservedCU } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-entity-edit',
  templateUrl: './node-entity-edit.component.html',
  styleUrls: ['./node-entity-edit.component.scss'],
})
export class NodeEntityEditComponent implements OnInit {
  @Input() tempGsiObj: any;
  @Input() configurationData: any;
  @Output() closeentity: EventEmitter<any> = new EventEmitter<any>();

  close: boolean = false;

  entityConfig: any;
  roles: any = [];
  entity: any;
  selectedindex: any;
  formGroup: FormGroup;
  TxnRights: any = [];
  DesignRights: any = [];
  ngUnsubscribe = new Subject();
  selectedChangedriverOptCu: any;
  SelectedalternativeEntity: any;
  optChangeDrivers: any = ['OPTIONAL', 'NEGATIVE', 'ALTERNATIVE', 'HIDDEN', 'NONE'];
  layerType: any;
  altEntities: any = [];
  changeUnit: any;
  layerChange: Subject<Boolean> = new Subject<Boolean>();
  layerchanged: boolean = false;
  oldLayer: string = '';
  cardformGroup: FormGroup;
  updatedtagToken: any;
  labels: any;
  step = 0;
  isConditionalPotentiality: any;
  cardselectionData: any;
  verticalTableSelectionData: any;
  entitylabel: boolean = false;
  downloadAll: boolean;
  attributelabel: any;
  submitOnSelection: false;
  duplicateRows: boolean = false;
  showMultiEntConf = {
    showMultiEntText: false,
    addButton: 'Add',
    removeButton: 'Remove',
    addButtonShape: 'Square',
    removeButtonShape: 'Square',
  };
  showNestedEntityConf: boolean = false;
  TableColorPickr: boolean;
  isDeleteButton: boolean = false;
  options: any[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

  constructor(
    private formBuilder: FormBuilder,
    public entityFacadeService: NodeEntityFacadeService,
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private translator: TranslatorService,
    private alertService: AlertService
  ) {
    this.formGroup = formBuilder.group({
      pagination: [false, Validators.requiredTrue],
      allowColumnsort: false,
      allowColumnFilter: false,
      allowDownload: false,
    });
    this.cardformGroup = formBuilder.group({
      minimumselection: [0],
      maximumselection: [0],
      minimumselectionAll: [false],
      submitOnSelection: this.submitOnSelection,
      // maximumselectionAll: [false],
    });
    this.detectEntityWidgetData();
    this.detectLanguageChange();
    this.detectCardTemplateData();
    this.detectTableTemplateData();
  }

  ngOnInit(): void {
    this.setEntityConfig(); // For Entity Configuration
    this.checkConfigEnt(); // For Entity Configuration
    this.detectVerticalTableTemplateData();
    this.saveVerticalEntityData();
    this.getCurrentCU();
    this.detectLayerChange();
    this.getUpdatedTagToken();
    this.detectAttributeConfig(); //For Attribute Configuration
    /*istanbul ignore next*/
    this.checkMultiValue(this.configurationData?.data?.entityData);
    this.layerchanged = false;
    console.log('selectedindex', this.selectedindex);
    /*istanbul ignore next*/
    this.oldLayer = this.configurationData?.data?.layerType;
    console.log(this.configurationData);
    /*istanbul ignore next*/
    if (!this.configurationData?.data?.cu?.cuLayers && this.configurationData?.data?.cu) {
      this.configurationData.data.cu.cuLayers = this.configurationData?.data?.cu?.layers;
    }
    /*istanbul ignore next*/
    this.layerType = this.configurationData.data?.cu?.data?.cuLayers?.find((x: any) => {
      return x.type === 'physical';
    });
    /*istanbul ignore next*/
    if (!this.layerType) {
      /*istanbul ignore next*/
      this.layerType = this.configurationData.data?.cu?.cuLayers?.find((x: any) => {
        return x.type === 'physical';
      });
    }
    /*istanbul ignore next*/
    if (this.layerType) {
      this.layerType?.participatingItems?.forEach((ent: any) => {
        this.altEntities.push(ent);
      });
    }
    /*istanbul ignore next*/
    if (this.configurationData?.data?.entityData?.changeDriverMetaData?.alternativeChangeDriver) {
      this.SelectedalternativeEntity = this.configurationData?.data?.entityData?.changeDriverMetaData?.alternativeChangeDriver
        .split('.')
        .pop();
    }
    /*istanbul ignore next*/
    this.selectedChangedriverOptCu = this.configurationData?.data?.entityData?.changeDriverMetaData?.changeDriverTypes;
    /*istanbul ignore next*/
    this.DesignRights = this.configurationData?.data?.entityData?.designTimeRights;
    /*istanbul ignore next*/
    this.TxnRights = this.configurationData?.data?.entityData?.txnTimeRights;
    /*istanbul ignore next*/
    this.configurationData?.data?.entityData?.designTimeRights?.forEach((role: any) => {
      this.roles.push({
        name: role.name ? role.name : role.rightHolderName,
        id: role.rightHolderId,
        rightHolderId: role.rightHolderId,
      });
    });
    this.getDesignRightsIndex(this.DesignRights);
    this.getTxnRightsIndex(this.TxnRights);

    // For Entity Configuration
    /* istanbul ignore next */
    if (this.configurationData.data?.entityData?.configuration) {
      this.entityConfig.type = JSON.parse(this.configurationData.data?.entityData?.configuration).type;
      if (this.entityConfig?.type == 'table') {
        this.entityFacadeService.entityTableUIConfiguration(true, this.configurationData);
      } else if (this.entityConfig?.type == 'widgets') {
        this.entityFacadeService.widgetUIConfiguration(true, this.configurationData);
      } else if (this.entityConfig?.type == 'verticalTable') {
        this.entityFacadeService.verticalTableUIConfiguration(true, this.configurationData);
      }
      /* istanbul ignore next */
      this.entityConfig = JSON.parse(this.configurationData.data?.entityData?.configuration);
      if (this.entityConfig?.hideEntityLabel) {
        this.entitylabel = this.entityConfig.hideEntityLabel;
      }
      this.showNestedEntityConf = this.entityConfig?.showType ? this.entityConfig?.showType : false;
      this.duplicateRows = this.entityConfig?.allowDuplication;
      this.showMultiEntConf = this.entityConfig?.showMultiEntConf ? this.entityConfig?.showMultiEntConf : this.showMultiEntConf;
      /* istanbul ignore next */
      if (this.entityConfig?.cardSettings && Object.keys(this.entityConfig?.cardSettings).length !== 0) {
        this.cardformGroup.setValue(this.entityConfig.cardSettings);
      }
      this.submitOnSelection = this.entityConfig?.cardSettings?.submitOnSelection || false;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  getLayerId(slotIndex: number) {
    let indexPadding = this.getIndexPadding(slotIndex + 1);
    let layerShortCut = 'PL_SL' + indexPadding;
    if (this.layerType == 'information') {
      layerShortCut = 'IL_SL' + indexPadding;
    } else if (this.layerType == 'triggerCES') {
      layerShortCut = 'triggerCES_SL' + indexPadding;
    } /*istanbul ignore next*/ else if (this.layerType === 'reservedActions') {
      layerShortCut = 'reservedActions_SL' + indexPadding;
    }
    return layerShortCut;
  }

  getCurrentCU() {
    /*istanbul ignore next*/
    const currentCUReferencedChangeUnit = this.configurationData?.data?.cu?.data?.dsdRefId
      ? this.configurationData.data.cu.data.dsdRefId
      : this.configurationData?.data?.cu?.referencedChangeUnit;
    let foundCu;
    if (currentCUReferencedChangeUnit) {
      /*istanbul ignore next*/
      foundCu = this.tempGsiObj.solutionLogic?.find(
        (x: any) => x.referencedChangeUnit == currentCUReferencedChangeUnit
      );
    }
    if (foundCu) {
      this.changeUnit = foundCu;
    }
  }

  /* optional change drivers entity level */
  onChangeOption(event: any, index: any) {
    /*istanbul ignore next*/
    const selectedItems = updateBetSelectedItems(event, this.selectedChangedriverOptCu);
    this.selectedChangedriverOptCu = selectedItems;
    this.configurationData.data.entityData.changeDriverMetaData = {
      changeDriverTypes: this.selectedChangedriverOptCu,
    };
  }

  alternateEntities(event: any, index: any) {
    /*istanbul ignore next*/
    if (this.layerType?.type == 'physical') {
      /*istanbul ignore next*/
      const slot = this.layerType?.participatingItems?.findIndex((x: any) => {
        return x.name == event;
      });
      let indexPadding = this.getIndexPadding(slot + 1);
      let dot = '.';
      let layerShortCut = 'PL.SL' + indexPadding;
      let formulaId = layerShortCut + dot + event;

      this.configurationData.data.entityData.changeDriverMetaData = {
        changeDriverTypes: this.selectedChangedriverOptCu,
        alternativeChangeDriver: formulaId,
      };
    }
  }

  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  closeEntity() {
    this.close = true;
    this.closeentity.emit(this.close);
    this.changeUnitFacade.closeActionpanel();
  }

  showRights(i: any) {
    this.selectedindex = i;
  }

  getDesignRightsIndex(role: any) {
    if (this.DesignRights && this.DesignRights.length > 0 && role) {
      /*istanbul ignore next*/
      return this.DesignRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.rightHolderId === role.id
      );
    }
    return -1;
  }

  autoCheckRights(rights: any) {
    /*istanbul ignore next*/
    if (rights && (rights.decisionRight || rights.executionRight)) {
      rights.informationRight = true;
    }
  }

  getTxnRightsIndex(role: any) {
    if (this.TxnRights && this.TxnRights.length > 0 && role) {
      /*istanbul ignore next*/
      return this.TxnRights.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.rightHolderId === role.id
      );
    }
    return -1;
  }

  containsNestedEntity(entityInfo: any) {
    let isContainsNestedEntity = false;

    /*istanbul ignore next */
    entityInfo?.nslAttributes?.forEach((item: any) => {
      if (item?.generalEntity) {
        isContainsNestedEntity = true;
      }
    });

    return isContainsNestedEntity;
  }

  checkAttrConditionalPotentiality(){
    let canConfigure: boolean = true;
    this.configurationData?.data?.entityData.nslAttributes.forEach((ele: any) => {
      if (ele?.conditionalPotentiality?.length > 0) {
        canConfigure = false;
      }
    });
    if (!canConfigure && localStorage.TenantName != 'callhealth') {
      return true;
    } else {
      return false;
    }
  }
  saveconf() {
    if (this.configurationData.id) {
      let status = false;
      if (this.entityConfig?.type != '' && this.entityConfig?.type !== 'progressBar' && this.containsNestedEntity(this.configurationData?.data?.entityData)) {
        this.alertService.showToaster('Can not configure entity as it contains nested entity', '', 'warning');
        status = true;
      }
      if (status) {
        return;
      }
      /* istanbul ignore else */
      if (this.entityConfig?.cardSelection == 'multiple') {
        /* istanbul ignore else */
        if (this.entityConfig?.cardSettings?.maximumselection < this.entityConfig?.cardSettings?.minimumselection) {
          this.alertService.showToaster(
            'maximum selection value should be less than or equal to Minimumselection value',
            '',
            'error'
          );
          return;
        }
      }

      /*istanbul ignore next*/
      if (this.configurationData?.data?.entityData?.nslAttributes) {
        this.configurationData.data.entityData.nslAttributes.forEach((attr: any) => {
          /*istanbul ignore next*/
          if (typeof attr['configuration'] !== 'string' && attr?.configuration) {
            let configExist: boolean = false;
            Object.keys(attr?.configuration).forEach((key: any) => {
              if (attr?.configuration[key]) {
                configExist = true;
              }
            });
            if (!configExist) {
              delete attr.configuration;
            }
          }
          /*istanbul ignore next*/
          if (attr?.['configuration'] && typeof attr['configuration'] !== 'string') {
            attr['configuration'] = JSON.stringify(attr['configuration']);
          }
        });
      }
      /*istanbul ignore next*/
      if (this.configurationData?.data?.entityData?.name == ' ') {
        this.configurationData.data.entityData.name = this.configurationData?.data?.name;
      }
      /*istanbul ignore next*/
      if (this.configurationData?.data?.entityData?.displayName == ' ') {
        this.configurationData.data.entityData.displayName = this.configurationData.data?.displayName;
      }
      /*istanbul ignore next*/
      if (this.configurationData.currentComp == 'GSI') {
        this.tempGsiObj = this.updatedCU(
          this.tempGsiObj,
          this.configurationData.data.entityIndex,
          this.configurationData.data.layerType
        );
      } else {
        this.configurationData.data.cu = this.updatedCU(
          this.configurationData.data.cu,
          this.configurationData.data.entityIndex,
          this.configurationData.data.layerType
        );
      }
      if (this.configurationData?.data?.nestedEntity) {
        this.entityConfig = { ...this.entityConfig, showType: this.showNestedEntityConf };
      }
      /* istanbul ignore next */
      if (this.entityConfig?.type === 'card' || this.entityConfig?.type === 'cardWithTemplate') {
        if (this.entityConfig.cardSelection == 'single') {
          this.entityConfig.hideSelectAll = true;
          this.entityConfig.cardSettings = {
            maximumselection: 1,
            minimumselection: 1,
            minimumselectionAll: false,
            submitOnSelection: this.submitOnSelection,
          };
        } else if (this.entityConfig.cardSelection == 'multiple') {
          /* istanbul ignore next */
          this.entityConfig.cardSettings = this.cardformGroup?.value;
        } else {
          this.entityConfig.cardSettings = {};
        }
        if (this.entityConfig?.type === 'card') {
          this.entityConfig.cardSettings['isDeleteButton'] = this.isDeleteButton;
        }
        this.configurationData.data.entityData['configuration'] = JSON.stringify({
          ...this.entityConfig,
          hideEntityLabel: this.entitylabel,
          allowDuplication: this.duplicateRows,
          showMultiEntConf: this.showMultiEntConf,
        });
      } else {
        this.configurationData['configuration'] = JSON.stringify({
          ...this.entityConfig,
          hideEntityLabel: this.entitylabel,
          allowDuplication: this.duplicateRows,
          showMultiEntConf: this.showMultiEntConf,
        });
      }

      /* istanbul ignore next */
      if (this.isConditionalPotentiality) {
        this.configurationData.data.entityData.entityProperties = {
          isConditionalPotentialityEntity: this.isConditionalPotentiality,
        };
      }

      /* istanbul ignore next */
      if (this.entityConfig?.type === '') {
        this.configurationData.data.entityData.configuration = JSON.stringify(this.entityConfig);
      }

      /*istanbul ignore next*/
      if (this.configurationData.currentComp == 'GSI') {
        this.entityFacadeService.saveGsiEntity(
          this.configurationData?.data?.entityData,
          this.configurationData?.data?.layerType,
          this.configurationData?.id
        );
      } else {
        // since it is not master configure, we only have to make saveCU call with updated entity data
        this.entityFacadeService.getSaveentityResponseForConfigure(
          this.configurationData?.data?.entityData,
          false,
          this.configurationData?.data?.cu,
          [],
          this.configurationData.data?.layerType,
          this.layerchanged,
          this.oldLayer,
          this.configurationData?.configuration,
          this.configurationData.data
        );
      }
    }
  }

  menuLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }

  buttonEnter(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }

  buttonLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }

  menuenter() {
    this.gsiFacadeService.menuenter();
  }

  //detect the layer change
  detectLayerChange() {
    this.layerChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: boolean) => {
      /*istanbul ignore next*/
      if (res) {
        if (this.configurationData?.data?.entityData) {
          this.configurationData.data.entityData.layerType = this.configurationData?.data?.layerType;
        }
        if (this.oldLayer == this.configurationData?.data?.layerType) {
          this.layerchanged = false;
        } else {
          this.layerchanged = true;
        }
      }
    });
  }

  getUpdatedTagToken() {
    this.changeUnitFacade.receiveTag$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.updatedtagToken = res;
      }
    });
  }

  updatedCU(cu: ChangeUnit, entityIndex: any, layerType: any) {
    /*istanbul ignore next*/
    cu?.layers?.forEach((entity: any) => {
      /*istanbul ignore next*/
      entity.participatingItems.forEach((ent: any) => {
        /*istanbul ignore next*/
        if (ent.id == this.configurationData?.data?.entityData?.id) {
          /*istanbul ignore next*/
          if (this.layerchanged) {
            if (layerType == 'physical') {
              /*istanbul ignore next*/
              if (!cu.sentenceTags.physical) {
                cu.sentenceTags['physical'] = [];
              }
              /*istanbul ignore next*/
              if (cu.sentenceTags.information) {
                let curr_counter: any = null;
                let startIdx: any = null;
                let endIdx: any = null;
                let flag: boolean = false;
                cu.sentenceTags.information.forEach((tags: any, idx: number) => {
                  if (flag && (tags.tag == 'entity' || tags.tag == 'Obj.')) {
                    flag = false;
                    endIdx = idx;
                  }
                  if (
                    (tags.tag == 'entity' || tags.tag == 'Obj.') &&
                    tags.entity_counter == this.configurationData.data.entityIndex
                  ) {
                    flag = true;
                    startIdx = idx;
                  }
                  if (flag) {
                    cu.sentenceTags.physical.push(tags);
                  }
                });
                if (endIdx != null) {
                  cu.sentenceTags.information.splice(startIdx, endIdx - startIdx);
                } else {
                  cu.sentenceTags.information.splice(startIdx);
                }
              }
            }
            /*istanbul ignore next*/
            if (layerType == 'information') {
              /*istanbul ignore next*/
              if (!cu.sentenceTags.information) {
                cu.sentenceTags['information'] = [];
              }
              /*istanbul ignore next*/
              if (cu.sentenceTags.physical) {
                let currentCounter: any = null;
                let startIdx: any = null;
                let endIdx: any = null;
                let flag = false;
                /*istanbul ignore next*/
                cu?.sentenceTags?.physical.forEach((tags: any, idx: any) => {
                  if (flag && (tags.tag == 'entity' || tags.tag == 'Obj.')) {
                    flag = false;
                    endIdx = idx;
                  }
                  if (
                    (tags.tag == 'entity' || tags.tag == 'Obj.') &&
                    tags.entity_counter == this.configurationData.data.entityIndex
                  ) {
                    flag = true;
                    startIdx = idx;
                  }
                  if (flag) {
                    cu.sentenceTags.information.push(tags);
                  }
                });
                if (endIdx != null) {
                  cu.sentenceTags.physical.splice(startIdx, endIdx - startIdx);
                } else {
                  cu.sentenceTags.physical.splice(startIdx);
                }
              }
            }
          }

          /*istanbul ignore next*/

          if (
            ent.displayName != this.configurationData?.data?.entityData?.displayName ||
            this.configurationData?.data?.cu?.isSeperateCU
          ) {
            cu.description = cu?.description?.replace(
              this.updatedtagToken?.token,
              this.configurationData?.data?.entityData?.displayName
            );
            /*istanbul ignore next*/
            if (!this.layerchanged) {
              cu.sentenceTags.physical[
                this.updatedtagToken?.index
              ].token = this.configurationData?.data?.entityData?.displayName;
            }
          } /*istanbul ignore next*/ else if (ent.name != this.configurationData?.data?.entityData?.name) {
            cu.description = cu?.description?.replace(
              this.updatedtagToken?.token,
              this.configurationData?.data?.entityData?.name
            );
            cu.sentenceTags.physical[this.updatedtagToken.index].token = this.configurationData?.data?.entityData?.name;
          } /*istanbul ignore next*/ else if (this.configurationData.currentComp == 'GSI') {
            cu.sentenceTags.physical[
              this.updatedtagToken.index
            ].token = this.configurationData?.data?.entityData?.displayName;
            cu.description = cu?.description?.replace(
              this.updatedtagToken?.token,
              this.configurationData?.data?.entityData?.displayName
            );
          }
        }
      });
    });

    return cu;
  }

  checkMultiValue(ent: any) {
    /*istanbul ignore next*/
    if (ent) {
      /*istanbul ignore next*/
      if (!ent.isMultiValue) {
        ent.isMultiValue = false;
      }
    }
  }

  /* ------------- For Entity Configuration - START ---------------- */
  // For initializing entity config
  setEntityConfig() {
    this.entityConfig = {
      setting: {},
      export: [
        {
          label: 'Pdf',
          isChecked: false,
        },
        {
          label: 'CSV',
          isChecked: false,
        },
        {
          label: 'Excel',
          isChecked: false,
        },
      ],
      colouring: {},
      tableDescription: {},
      pagination: {
        rows: 10,
        placementOfPagination: '',
      },
      display: '',
      newTableConfiguration: true,
      type: '',
      hideSelectAll: false,
      cardSelection: '',
      cardSettings: {},
      verticalTableSettings: {},
    };
  }

  // To get entity configuration
  checkConfigEnt() {
    /* istanbul ignore next */
    let entityConfig = this.configurationData?.data?.entityData?.entityProperties;
    /* istanbul ignore next */
    if (entityConfig) {
      this.isConditionalPotentiality = entityConfig?.isConditionalPotentialityEntity;
    } else {
      this.isConditionalPotentiality = false;
    }
  }

  // To change entity configuration
  entityConfigChange() {
    /*istanbul ignore next*/
    if (this.entityConfig.type === '') {
      this.setEntityConfig();
    } else if (this.entityConfig?.type === 'table') {
      this.entityFacadeService.entityTableUIConfiguration(true, this.configurationData);
      // this.entityFacadeService.tableUIConfiguration(true, this.configurationData);
    } else if (this.entityConfig?.type === 'verticalTable') {
      this.entityFacadeService.verticalTableUIConfiguration(true, this.configurationData);
    } else if (this.entityConfig?.type === 'accordion') {
      this.configurationData.data.entityData.configuration = this.entityConfig.type;
    } else if (this.entityConfig?.type == 'widgets') {
      this.entityFacadeService.widgetUIConfiguration(true, this.configurationData);
    }
  }
  detectEntityWidgetData() {
    this.entityFacadeService.widgetConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.entityConfig.setting = data;
      this.saveconf();
    });
  }

  cardCustomizeHandler(event: any) {
    this.cardselectionData = event;
    this.entityFacadeService.cardUIConfiguration(true, this.configurationData);
  }

  verticalTableCustomizeHandler(event: any) {
    this.verticalTableSelectionData = event;
    this.entityFacadeService.verticalTableUIConfiguration(true, this.configurationData);
  }

  /* saving tuples data for card view entity */
  saveCardViewDetails(event: any) {
    this.entityConfig.setting = event;
    /*istanbul ignore next*/
    this.entityConfig.cardSettings = {
      ...this.cardformGroup?.value,
      submitOnSelection: this.submitOnSelection,
      isDeleteButton: this.isDeleteButton,
    };
    this.saveconf();
  }

  saveVerticalTableDetails(event: any) {
    this.entityConfig.settings = event;
    this.entityConfig.verticalTableSettings = {
      ...this.cardformGroup?.value,
      submitOnSelection: this.submitOnSelection,
    };
    this.saveconf();
  }

  detectCardTemplateData() {
    this.entityFacadeService.cardConfigData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      const settings = {
        templateType: 'customization',
        customizeCardconfigData: res?.configurationData?.event,
        selectionType: res?.configurationData?.selectionData?.selectionType,
        lableName: res?.configurationData?.selectionData?.lableName,
      };
      this.saveCardViewDetails(settings);
    });
  }
  detectTableTemplateData() {
    this.entityFacadeService.updateEntityTblConfig$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.entityConfig = res;
      this.submitOnSelection = this.entityConfig?.cardSettings?.submitOnSelection || false;
      this.saveconf();
    });
  }
  removeAgent(i: any) {
    this.roles.splice(i, 1);
    /*istanbul ignore next*/
    this.configurationData?.data?.entityData?.designTimeRights.splice(i, 1);
    /*istanbul ignore next*/
    this.configurationData?.data?.entityData?.txnTimeRights.splice(i, 1);
  }

  saveVerticalEntityData() {
    this.entityFacadeService.verticalTableEntitySaveDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.entityConfig = res?.configurationData;
          this.submitOnSelection = this.entityConfig?.cardSettings?.submitOnSelection || false;
          if (this.entityConfig?.type === 'verticalTable') this.saveconf();
        }
      });
  }
  detectVerticalTableTemplateData() {
    this.entityFacadeService.verticalTableConfigData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      const settings = {
        templateType: 'verticalTable',
        customizeVerticalTableData: res?.configurationData?.event,
        selectionType: res?.configurationData?.selectionData?.selectionType,
        lableName: res?.configurationData?.selectionData?.lableName,
      };
      this.saveVerticalTableDetails(settings);
    });
  }

  detectAttributeConfig() {
    this.configurationData?.data?.entityData?.nslAttributes?.forEach((attr: any) => {
      /* istanbul ignore else */
      if (attr['configuration'] && typeof(attr['configuration']) == 'string') {
        attr['configuration'] = JSON.parse(attr['configuration']);
      } else {
        attr['configuration'] = {
          hideLabel: false,
          autoRedirect: false,
        };
      }
      /* istanbul ignore else */
      if (!attr['configuration']?.hideLabel) {
        attr['configuration']['hideLabel'] = false;
      }
    });
  }

  selectAllAttributes() {
    this.configurationData?.data?.entityData?.nslAttributes?.forEach((attr: any) => {
      attr['configuration'].hideLabel = true;
    });
  }
  selectedAttribute(attr: any) {
    this.entityConfig.selectedAttributeFormat = attr;
  }

  deselectAllAttributes() {
    this.configurationData?.data?.entityData?.nslAttributes?.forEach((attr: any) => {
      attr['configuration'].hideLabel = false;
    });
  }

  /* ------------- For Entity Configuration - END ---------------- */

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}


