import { NgModule } from '@angular/core';
import { TransactionLibraryComponent } from './transaction-library.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationPipesModule, CommonServicesModule } from '@common-services';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { TransactionSmartComponent } from './transaction/transaction-smart/transaction-smart.component';
import { TransactionMaterialModule } from './transaction-material/transaction-material.module';
import { TransactionViewModule } from './transaction-view/transaction-view-module';
import { GsiComponent } from './transaction/gsi/gsi.component';
import { RouterModule } from '@angular/router';
import { TransactionModule } from './transaction/transaction.module';
import { PostExceptionsViewComponent } from './transaction/post-exceptions-view/post-exceptions-view.component';
// import { BasicTransactionViewComponent } from './transaction-view/basic-transaction-view/basic-transaction-view.component';
// import { OpencuTransactionViewComponent } from './transaction-view/opencu-transaction-view/opencu-transaction-view.component';
// import { AssetTransactionViewComponent } from './transaction-view/asset-transaction-view/asset-transaction-view.component';
// import { CuhorizantalTransactionViewComponent } from './transaction-view/cuhorizontal-transaction-view/cuhorizontal-transaction-view.component';
// import { ExtendedTransactionViewComponent } from './transaction-view/extended-transaction-view/extended-transaction-view.component';
// import { AssethorizontalTransactionViewComponent } from './transaction-view/assethorizontal-transaction-view/assethorizontal-transaction-view.component';
// import { LuxuryTransactionViewComponent } from './transaction-view/luxury-transaction-view/luxury-transaction-view.component';



@NgModule({
  declarations: [
    TransactionLibraryComponent,
    GsiComponent,
    TransactionSmartComponent,
    PostExceptionsViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ApplicationPipesModule,
    TransactionViewModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    TransactionMaterialModule,
    ToastrModule.forRoot(),
    CommonServicesModule,
    RouterModule,
    TransactionModule,
  ],
  exports: [
    TransactionLibraryComponent,
    TransactionModule,
    GsiComponent,
    TransactionSmartComponent,
    PostExceptionsViewComponent,
    TransactionViewModule
  ]
})
export class TransactionLibraryModule { }
