import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CardRecord } from '../../../constants/customize-filter-templates.constants';

@Component({
  selector: 'app-dashboard-ambulance-tracking',
  templateUrl: './dashboard-ambulance-tracking.component.html',
  styleUrls: ['./dashboard-ambulance-tracking.component.scss'],
})
export class DashboardAmbulanceTrackingComponent implements OnInit {
  ambulanceDetails = [
    {
      registrationNumber: 'TS087654',
      offRoadDateTime: '12/7/23 09:11 AM',
      offRoadDuration: '32h 41m',
      events: [
        {
          eventName: 'Off Road',
          seriesOfEventsHappening: [
            { name: 'Back up Ambulance Not Assigned', status: 'blue' },
            { name: 'Delayed Start of Back Up Ambulance', status: '' },
            { name: 'ETA Missing-Back up base to location', status: '' },
            { name: 'Ambulance Exchange Delay', status: '' },
            { name: 'Delay in Off-Road Vehicle Start', status: 'blue' },
            { name: 'ETA Missing-Location to Workshop', status: '' },
          ],
        },
        {
          eventName: 'Workshop',
          seriesOfEventsHappening: [
            { name: 'Delay in Job Card Creation', status: '' },
            { name: 'Delay in Job Start', status: '' },
            { name: 'Scheduled Delay Expected', status: '' },
            { name: 'Delay in Ambulance Starting fr...', status: '' },
          ],
        },
        {
          eventName: 'Back Redeployment',
          seriesOfEventsHappening: [
            { name: 'ETA Missing- Workshop to Hotspot', status: '' },
            { name: 'Backup Redeployment Delay', status: '' },
            { name: 'Delay in Start of Back up vehicle', status: '' },
            { name: 'Back Redeployment', status: 'red' },
            { name: 'ETA Missing- Hotspot to Back Up..', status: '' },
          ],
        },
      ],
    },
    {
      registrationNumber: 'TS087654',
      offRoadDateTime: '12/7/23 09:11 AM',
      offRoadDuration: '32h 41m',
      events: [
        {
          eventName: 'Off Road',
          seriesOfEventsHappening: [
            { name: 'Back up Ambulance Not Assigned', status: 'blue' },
            { name: 'Delayed Start of Back Up Ambulance', status: 'yellow' },
            { name: 'ETA Missing-Back up base to location', status: '' },
            { name: 'Ambulance Exchange Delay', status: 'red' },
            { name: 'Delay in Off-Road Vehicle Start', status: 'blue' },
            { name: 'ETA Missing-Location to Workshop', status: '' },
          ],
        },
        {
          eventName: 'Workshop',
          seriesOfEventsHappening: [
            { name: 'Delay in Job Card Creation', status: 'red' },
            { name: 'Delay in Job Start', status: '' },
            { name: 'Scheduled Delay Expected', status: '' },
            { name: 'Delay in Ambulance Starting fr...', status: '' },
          ],
        },
        {
          eventName: 'Back Redeployment',
          seriesOfEventsHappening: [
            { name: 'ETA Missing- Workshop to Hotspot', status: '' },
            { name: 'Backup Redeployment Delay', status: '' },
            { name: 'Delay in Start of Back up vehicle', status: '' },
            { name: 'Back Redeployment', status: 'red' },
            { name: 'ETA Missing- Hotspot to Back Up..', status: '' },
          ],
        },
      ],
    },
    {
      registrationNumber: 'TS087654',
      offRoadDateTime: '12/7/23 09:11 AM',
      offRoadDuration: '32h 41m',
      events: [
        {
          eventName: 'Off Road',
          seriesOfEventsHappening: [
            { name: 'Back up Ambulance Not Assigned', status: 'blue' },
            { name: 'Delayed Start of Back Up Ambulance', status: '' },
            { name: 'ETA Missing-Back up base to location', status: '' },
            { name: 'Ambulance Exchange Delay', status: '' },
            { name: 'Delay in Off-Road Vehicle Start', status: 'blue' },
            { name: 'ETA Missing-Location to Workshop', status: '' },
          ],
        },
        {
          eventName: 'Workshop',
          seriesOfEventsHappening: [
            { name: 'Delay in Job Card Creation', status: '' },
            { name: 'Delay in Job Start', status: '' },
            { name: 'Scheduled Delay Expected', status: '' },
            { name: 'Delay in Ambulance Starting fr...', status: '' },
          ],
        },
        {
          eventName: 'Back Redeployment',
          seriesOfEventsHappening: [
            { name: 'ETA Missing- Workshop to Hotspot', status: '' },
            { name: 'Backup Redeployment Delay', status: '' },
            { name: 'Delay in Start of Back up vehicle', status: '' },
            { name: 'Back Redeployment', status: 'red' },
            { name: 'ETA Missing- Hotspot to Back Up..', status: '' },
          ],
        },
      ],
    },
    {
      registrationNumber: 'TS087654',
      offRoadDateTime: '12/7/23 09:11 AM',
      offRoadDuration: '32h 41m',
      events: [
        {
          eventName: 'Off Road',
          seriesOfEventsHappening: [
            { name: 'Back up Ambulance Not Assigned', status: 'blue' },
            { name: 'Delayed Start of Back Up Ambulance', status: 'yellow' },
            { name: 'ETA Missing-Back up base to location', status: '' },
            { name: 'Ambulance Exchange Delay', status: '' },
            { name: 'Delay in Off-Road Vehicle Start', status: 'blue' },
            { name: 'ETA Missing-Location to Workshop', status: '' },
          ],
        },
        {
          eventName: 'Workshop',
          seriesOfEventsHappening: [
            { name: 'Delay in Job Card Creation', status: '' },
            { name: 'Delay in Job Start', status: 'red' },
            { name: 'Scheduled Delay Expected', status: '' },
            { name: 'Delay in Ambulance Starting fr...', status: '' },
          ],
        },
        {
          eventName: 'Back Redeployment',
          seriesOfEventsHappening: [
            { name: 'ETA Missing- Workshop to Hotspot', status: '' },
            { name: 'Backup Redeployment Delay', status: '' },
            { name: 'Delay in Start of Back up vehicle', status: 'yellow' },
            { name: 'Back Redeployment', status: 'red' },
            { name: 'ETA Missing- Hotspot to Back Up..', status: '' },
          ],
        },
      ],
    },
  ];
  @Output() emitTransEvent: EventEmitter<CardRecord> = new EventEmitter<CardRecord>();
  @Input() cardsData: CardRecord[] = [];
  entityName: string = '';

  constructor() {}

  ngOnInit(): void {}

  navigateToTransaction(data: CardRecord) {
    this.emitTransEvent.emit(data);
  }
}
