<div [ngClass]="{ 'setting-sentences': layerSentence }">
  <div
    class="w-100 gsiTitle no-gutters fw-bolder gsilink isSelected creatingField wishfulfilment finishing-cu config-wishfulfilment"
  >
    <div class="col">
      <div class="sentences-wrapper">
        <span class="symbol" *ngIf="indcudata?.isReserved && layerType == 'physical'">R</span>
        <div class="sentences-container">
          <span
            *ngFor="let tag of layerSentence; let tagInd = index"
            id="sentences-tag-container"
            class="sentence-word"
          >
            <span
              class="fatElement"
              [ngClass]="{
                ChangeUnit_ele: tag.tag == 'Int.',
                entity_ele: tag.tag == 'Obj.',
                agent_ele: tag.tag == 'Ag.',
                attribute_ele: tag.tag == 'Att.',
                human_ele: tag.tag == 'Hum.',
                machine_ele: tag.tag == 'Mch.'
              }"
            >
              <span class="flatTxt" (click)="cuConfigSettings($event, tagInd, tag.tag)">
                {{ tag.token }}
              </span>
              <small
                class="{{ tag.tag }}"
                [ngClass]="{
                  ChangeUnit: tag.tag == 'Int.',
                  entity: tag.tag == 'Obj.',
                  agent: tag.tag == 'Ag.',
                  attribute: tag.tag == 'Att.',
                  human: tag.tag == 'Hum.',
                  machine: tag.tag == 'Mch.'
                }"
                *ngIf="tag.tag !== 'nlg'"
              >
                {{ tag.tag }}
              </small>
            </span>
            <div
              id="status-dropdown"
              class="status-dropdown"
              *ngIf="configDropdown == tagInd && !isAgentTag && tag.tag != 'Hum.'"
              (clickOutside)="clickOutside()"
            >
              <ul>
                <li id="Configure" *ngIf="tag.tag != 'Hum.'" (click)="configuration($event)">
                  <span class="configure-icon"></span> Configure
                </li>
                <li id="Edit_Attribute" *ngIf="tag.tag == 'Att.'" (click)="configureMasterAttribute($event)">
                  <span class="edit-icon"></span> {{ labels?.Edit_Attribute }}
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
