import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTabGroup } from '@angular/material/tabs';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import { DeveloperLessDevelopmentFacadeService, Librarytypes, pagination, SuggestionFacadeService, TranslatorService, NodeGsiFacadeService,isReservedCU } from '@common-services';


@Component({
  selector: 'app-node-menu-panel',
  templateUrl: './node-menu-panel.component.html',
  styleUrls: ['./node-menu-panel.component.scss'],
})
export class NodeMenuPanelComponent implements OnInit, OnDestroy, OnChanges {
  @Input() openPanel: any;
  @Input() isParagraph: boolean;
  @Output() closeParaMenuPanel = new EventEmitter();
  @Output() closeRightPanel = new EventEmitter();
  libraryType: any;
  tabbingModal: boolean;
  tabID: string = '';
  ngUnsubscribe = new Subject();

  selectedTabIndex: number = 2;
  pgIndex: number = 0;
  pageIndex: number = 0;
  pageSize: number = 15;
  pgSize: number = 15;
  searchText: string = '';
  srchText: string = '';
  private unsubscribe$: Subject<any> = new Subject<any>();
  reservedCuList: any = [];
  totalRecords: any;
  labels: any;
  displayNodesearch: boolean;
  @Input() nodeSection: boolean = false;
  reservedCuGrammarList: any;
  isFromGsi: any;
  toolTipIndex: number = -1;
  reservedEntityList: any;
  nestedNumber: number;
  dsdSuggestion: string = '';
  checkedAttributeList: any = [];
  searchInput = new Subject();
  betCounts: any;
  bookCount:any;
  agentCount:any;
  solutionCount:any;
  ChangeUnitCount:any;
  entityCount:any;
  cuTotalRecords: any;
  constructor(
    private gsiFacade: NodeGsiFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private entityFacadeService: NodeEntityFacadeService,
    private suggestionFacadeService: SuggestionFacadeService,
    private translator: TranslatorService,
    private dldFacade: DeveloperLessDevelopmentFacadeService
  ) {
    this.openLibrary();
    this.detectTabChange();
    this.detectReservedCus();
    this.detectLanguageChange();
    this.detectGrammarReservedCus();
    //this.detectAddEntInGso();
    this.detectTabSelection();
    this.detectReservedEntitiess();
    this.getLibraryData();
    this.getNestedNumber();
    this.detectSearchInput();
    this.getExpandCuData();
    this.openNestedPara();
    this.detectAllBetCount();
  }

  ngOnInit(): void {
    /* istanbul ignore else */
    if (this.isParagraph) {
      this.changeTab(this.openPanel);
    }
    this.searchText = '';
    this.gsiFacade.getBetCounts();
  }

  ngOnChanges() {}

  getNestedNumber() {
    this.gsiFacade.nestedNumber$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.nestedNumber = res;
    });
  }
  detectTabChange() {
    this.suggestionFacadeService.tabChange$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res == 'discard') this.clickOutside();
      else this.changeTab(res);
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  searchDsdDetails(pageNumber?: any, limit?: any) {
    let searchType = 'gsi';
    this.dldFacade.getDSDSearchQueries(searchType, this.dsdSuggestion, pageNumber, limit);
  }
  changeTab(id: any) {
    this.searchText = '';
    this.tabID = id;
    this.tabbingModal = true;
    if (location.pathname.includes('nsl-eql')) {
      this.selectedTabIndex = 4;
    }
    /* istanbul ignore else */
    if (id == 'KT') {
      this.getReservedCuList();
      this.pgIndex = 0;
      this.getReservedEntitiesList();
    }
    if (id == 'SG') {
      this.searchDsdDetails(null, 20);
    }
  }
  toggleTabModal() {
    this.tabID = 'MC';
    this.tabbingModal = !this.tabbingModal;
    /* istanbul ignore else */
    if (this.tabbingModal == false) {
      this.tabID = '';
    }
  }
  closePanel() {
    this.tabbingModal = false;
    this.closeParaMenuPanel.next(true);
  }

  openAgentLibrary(event: any) {}

  detectSearchInput() {
    /* istanbul ignore next */
    this.searchInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        /* istanbul ignore next */
        this.pageIndex = 0;
        this.getReservedCuList();
      });
  }

  getReservedCuList() {
    if (this.searchText == '') {
      this.displayNodesearch = false;
    }
    // if (!this.nodeSection) {
    //   this.changeunitFacade.grammarReservedCU(this.pageIndex + 1, this.pageSize, this.searchText);
    // } else {
    //   this.changeunitFacade.getReservedCuList(this.pageIndex, this.pageSize, this.searchText);
    // }
    this.changeunitFacade.grammarReservedCU(this.pageIndex + 1, this.pageSize, this.searchText);

    // this.changeunitFacade.getReservedCuList(this.pageIndex, this.pageSize, this.searchText);
  }
  addReservedEntity(entity: any) {
    this.entityFacadeService.loadAttributesByEntId(entity?.dsdId, null, null, entity.isReserved);
  }
  addReservedAttribute(entity: any, ind?: any) {
    this.entityFacadeService.loadAttributesByEntId(
      entity.id,
      this.checkedAttributeList,
      null,
      true,
      null,
      null,
      null,
      null
    );
    this.checkedAttributeList = [];
  }
  addChangeunit(cu: any) {
    /* istanbul ignore else */
    if (cu.name.includes('NSL_')|| isReservedCU(cu)) {
      this.searchText = '';
      this.changeunitFacade.getReservedCuDetailsById(cu?.dsdId);
    }
  }
  onPageChange(pageData: pagination, type?: string) {
    if (type == 'entity') {
      this.pgSize = pageData.pageSize;
      this.pgIndex = pageData.pageIndex;
      this.getReservedEntitiesList();
    } else if (type == 'cu') {
      this.pageSize = pageData.pageSize;
      this.pageIndex = pageData.pageIndex;
      this.getReservedCuList();
    }
  }
  addReservedCUFromLibrabry(methodName: any, displayName: any, moreView?: boolean) {
    let formData: FormData = new FormData();
    if (!moreView) moreView = false;
    // formData.append('text', this.searchText);
    formData.append('rcu_selection', methodName);
    this.changeunitFacade.populateRCU(formData, null, true, false, true, displayName, moreView);
  }
  detectGrammarReservedCus() {
    this.changeunitFacade.reservedCuListGrammar$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result?.length > 0) {
        this.reservedCuGrammarList = res?.result[0]?.recommendations;
        this.reservedCuGrammarList?.forEach((cu: any) => {
          if (cu?.reserved_cu_name?.includes('NSL_')|| isReservedCU(cu)) {
            cu.reserved_cu_name = cu.reserved_cu_name.substring(4);
          }
        });
        this.cuTotalRecords = res?.result[0]['total recommendation length'];
      }
    });
  }

  detectReservedCus() {
    this.changeunitFacade.reservedCuList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.reservedCuList = res.result.data;
      this.totalRecords = res.result.totalResults;
    });
  }
  detectReservedEntitiess() {
    this.changeunitFacade.reservedEntityList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.reservedEntityList = res.content;
      this.totalRecords = res.total;
    });
  }
  clickOutside() {
    this.tabbingModal = false;
    this.tabID = '';
  }
  demo2BtnClick(tabGroup: MatTabGroup) {
    /* istanbul ignore else */
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;
    /* istanbul ignore next */
    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex + 1) % tabCount;
  }

  openLibrary() {
    this.gsiFacade.openMyCollection$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.libraryType = res;
        this.closeRightPanel.next(true);
        setTimeout(() => {
          this.changeTab('MC');
        }, 100);
        switch (res) {
          case Librarytypes.Book:
            this.selectedTabIndex = 0;
            break;
          case Librarytypes.Agent:
            this.selectedTabIndex = 1;
            break;
          case Librarytypes.GSI:
            this.selectedTabIndex = 2;
            break;
          case Librarytypes.ChangeUnit:
            this.selectedTabIndex = 3;
            break;
          case Librarytypes.Entity:
            this.selectedTabIndex = 4;
            break;
          case Librarytypes.GSInested:
            this.selectedTabIndex = 2;
            break;
          case Librarytypes.GSIexception:
            this.selectedTabIndex = 2;
            break;
          case Librarytypes.GSIEmbedded:
            this.selectedTabIndex = 2;
            break;
        }
      }
    });
  }

  detectTabSelection() {
    this.gsiFacade.menuPanelSelection.subscribe((res: any) => {
      if (Number(res) > -1) {
        this.selectedTabIndex = res;
      }
    });
  }

  // detectAddEntInGso() {
  //   this.gsiFacade.entityinGsi$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
  //     if(res!=undefined){
  //       this.isFromGsi = res;
  //     }
  //   });
  // }
  // ngOnChnages(){
  //   this.detectAddEntInGso()
  // }
  getReservedEntitiesList() {
    this.changeunitFacade.getReservedEntitiesList(this.pgIndex + 1, this.pgSize, this.srchText);
  }

  closeDescription() {
    this.toolTipIndex = -1;
  }
  expandEntity(entity: any) {
    this.gsiFacade.viewBetDetails(entity.id, Librarytypes.Entity, true);
  }

  getLibraryData() {
    this.gsiFacade.viewLibraryData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        const foundIndex = this.reservedEntityList?.findIndex((entity: any) => entity?.masterId == res?.masterId);
        /* istanbul ignore else */
        if (foundIndex != -1 && this.reservedEntityList?.length > 0) {
          this.reservedEntityList[foundIndex].displayList = res.nslAttributes;
        }
      }
    });
  }
  Checkboxchanged(isChecked: any, attr: any) {
    if (isChecked) {
      this.checkedAttributeList?.push(attr);
    } else {
      this.checkedAttributeList?.forEach((data: any, ind: any) => {
        if (data === attr) {
          this.checkedAttributeList?.splice(ind, 1);
        }
      });
    }
  }

  getExpandCuData() {
    this.changeunitFacade.viewMoreCUDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        let cu = this.reservedCuGrammarList?.find(
          (changeUnit: any) => changeUnit.nsl_method_name == res?.response?.cu_id?.CU_list[0]?.name
        );
        cu.layers = res.response?.tf_details?.layers?.length > 0 ? res.response?.tf_details?.layers : [];
        console.log(cu);
      }
    });
  }

  openNestedPara() {
    this.gsiFacade.openMyCollectionPara$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.libraryType = res;
      }
    });
  }
  detectAllBetCount() {
    this.gsiFacade.getAllBetCount$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.betCounts = { ...res };
        this.betCounts.cuCount = this.countFormatter(this.betCounts.cuCount);
        this.betCounts.entityCount = this.countFormatter(this.betCounts.entityCount);
        this.betCounts.roleCount = this.countFormatter(this.betCounts.roleCount);
        this.betCounts.gsiCount = this.countFormatter(this.betCounts.gsiCount);
        this.betCounts.bookCount = this.countFormatter(this.betCounts.bookCount);
        this.betCounts.customAttributeCount = this.countFormatter(this.betCounts.customAttributeCount);
      }
    });
  }
  countFormatter(num: number) {
    /* istanbul ignore next */
    return Math.abs(num) > 999 ? (num / 1000).toFixed(1) + 'K' : Math.sign(num) * Math.abs(num);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
