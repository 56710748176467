import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { RatingComponent } from './rating.component';

@NgModule({
  declarations: [RatingComponent],
  imports: [
    ControlsMaterialModule,
  ],
  exports: [RatingComponent],
})
export class RatingModule {
  static entry = {
    rating: RatingComponent,
  };
}
