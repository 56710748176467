<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeEntity()">
        {{ labels?.close }}
      </button>
      <label class="status">Status: Draft ( Ver 1.1 )</label>
    </div>
    <div class="heading-area">
      <h2>{{ labels?.Object }}</h2>
      <button class="save" (click)="saveconf()">{{labels?.Save}}</button>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <!-- For Object Details -->
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Object_Details }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field class="mb-4 mr-3 w-100">
            <mat-label>{{ labels?.Change_Driver_Type }}</mat-label>
            <mat-select multiple [(ngModel)]="selectedChangedriverOptCu">
              <mat-option
                *ngFor="let opt of optChangeDrivers; let i = index"
                [value]="opt"
                (click)="onChangeOption(opt, i)"
              >
                {{ opt }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mb-4 mr-3 w-100" *ngIf="selectedChangedriverOptCu?.includes('ALTERNATIVE')">
            <mat-label>{{ labels?.Alternative_Entities }}</mat-label>
            <mat-select [(ngModel)]="SelectedalternativeEntity">
              <mat-option
                *ngFor="let entity of altEntities; let i = index"
                [value]="entity.name"
                (click)="alternateEntities(entity.name, i)"
              >
                {{ entity.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <div class="mb-3">
            <mat-label class="label-txt">Entity Constant Change Driver </mat-label>
            <mat-radio-group fxLayout="column" [(ngModel)]="configurationData.data.constantChangeDriverEntity">
              <mat-radio-button name="isconstantChangeDriverEntity" id="constantChangeDriverEntityYes" [value]="true"
                >Yes
              </mat-radio-button>
              <mat-radio-button name="isconstantChangeDriverEntity" id="constantChangeDriverEntityNo" [value]="false"
                >No
              </mat-radio-button>
            </mat-radio-group>
          </div> -->
          <div class="mb-2">
            <mat-label class="label-txt mr-2">{{ labels?.Multi_Value }}</mat-label>
            <mat-radio-group
              fxLayout="column"
              [disabled]="configurationData?.data?.nestedEntity"
              [(ngModel)]="configurationData.data.entityData.isMultiValue"
            >
              <mat-radio-button name="isMultiValue" id="attribute_multiYes_" [value]="true">{{
                labels?.Yes
              }}</mat-radio-button>
              <mat-radio-button name="isMultiValue" id="attribute_multiNo_" [value]="false">{{
                labels?.No
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="entity-plus-minus-container" *ngIf="configurationData.data.entityData.isMultiValue">
            <mat-radio-group class="entity-plus-minus-wrapper" fxLayout="column" [(ngModel)]="showMultiEntConf.showMultiEntText">
              <mat-radio-button
                class="entity-show-plus-minus-btns"
                name="showMultiEntityText"
                id="showMultiEntityText_no"
                [value]="false"
              >
                {{ labels?.Show }}(+/-)
              </mat-radio-button>
              <mat-radio-button
                class="entity-show-btns"
                name="showMultiEntityText"
                id="showMultiEntityText_yes"
                [value]="true"
              >
                {{ labels?.Show_Buttons }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="ent-add-remove-btns-container" *ngIf="showMultiEntConf.showMultiEntText">
              <div class="ent-add-input-wrapper">
                <input type="text" id="addButton" placeholder="Add" [(ngModel)]="showMultiEntConf.addButton" />
                <mat-radio-group [(ngModel)]="showMultiEntConf.addButtonShape">
                  <mat-radio-button name="shape" value="Square">
                    {{ labels?.Square_Outline }}
                  </mat-radio-button>
                  <mat-radio-button name="shape" value="Circle">
                    {{ labels?.Circle_Outline }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="ent-remove-input-wrapper">
                <input type="text" id="removeButton" placeholder="Remove" [(ngModel)]="showMultiEntConf.removeButton" />
                <mat-radio-group [(ngModel)]="showMultiEntConf.removeButtonShape">
                  <mat-radio-button name="shape" value="Square">
                    {{ labels?.Square_Outline }}
                  </mat-radio-button>
                  <mat-radio-button name="shape" value="Circle">
                    {{ labels?.Circle_Outline }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="mb-2" *ngIf="configurationData.data.entityData.isMultiValue">
            <mat-label class="label-txt mr-2">{{ labels?.Allow_Duplication_Of_Records }}</mat-label>
            <mat-radio-group fxLayout="column" [(ngModel)]="duplicateRows">
              <mat-radio-button name="duplicaterows" id="duplicaterows_Yes_" [value]="true">{{
                labels?.Yes
              }}</mat-radio-button>
              <mat-radio-button name="duplicaterows" id="duplicaterows_No_" [value]="false">{{
                labels?.No
              }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field>
            <mat-label>{{ labels?.Object_name }}</mat-label>
            <input
              matInput
              placeholder="Object name mentioned earlier"
              [(ngModel)]="configurationData.data.entityData.name"
              readonly
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ labels?.Display_name }}</mat-label>
            <input
              readonly
              matInput
              placeholder="Object name"
              [(ngModel)]="configurationData.data.entityData.displayName"
            />
          </mat-form-field>

          <mat-expansion-panel class="mat-elevation-z0 cstum-expansion">
            <mat-expansion-panel-header class="cstum-header">
              <mat-panel-title>
                {{labels?.Hide_Labels}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="">
              <div class="d-flex-sb">
                <mat-label>{{labels?.Entity_Label}}</mat-label>
                <mat-checkbox [(ngModel)]="entitylabel" id="entitylabel"></mat-checkbox>
              </div>

              <mat-expansion-panel class="mat-elevation-z0 cstm-expansion">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{labels?.Attribute_Labels}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="">
                  <div class="d-flex justify-content-end labelSelect">
                    <label class="d-flex-end" (click)="selectAllAttributes()">
                      {{labels?.Select_All}}
                    </label>
                    <label class="d-flex-end" (click)="deselectAllAttributes()">
                      {{labels?.Deselect_All}}
                    </label>
                  </div>
                  <div
                    class="d-flex-sb"
                    *ngFor="let attr of configurationData.data.entityData.nslAttributes; let i = index"
                  >
                    <mat-label>{{ attr?.name }}</mat-label>
                    <mat-checkbox
                      [(ngModel)]="attr.configuration.hideLabel"
                      id="entity-attribute-label-{{ i }}"
                    ></mat-checkbox>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </mat-expansion-panel>

          <!-- <div class="form-group">
            <mat-radio-group
              aria-label="Select an option"
              (change)="layerChange.next(true)"
              [(ngModel)]="configurationData.data.layerType"
            >
              <mat-radio-button value="information">{{ labels?.Information }}</mat-radio-button>
              <mat-radio-button value="physical">{{ labels?.Physical }}</mat-radio-button>
            </mat-radio-group>
          </div> -->
        </mat-expansion-panel>

        <!-- For Entity UI control -->
        <mat-expansion-panel class="overflow-visible">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.UI_Controls }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="configurationData?.data?.nestedEntity" class="mb-3">
            <mat-label class="label-txt">{{ labels?.Show_Nested_Entity_Configuration }} </mat-label>
            <mat-radio-group fxLayout="column" [(ngModel)]="showNestedEntityConf">
              <mat-radio-button name="showNestedEntityConf" id="showNestedEntityConfYes" [value]="true"
                >{{ labels?.Yes }}
              </mat-radio-button>
              <mat-radio-button name="showNestedEntityConf" id="showNestedEntityConfNo" [value]="false"
                >{{ labels?.No }}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- For selecting entity UI control -->
          <div class="w-75">
            <mat-label>{{ labels?.Select_Ui_Control }}</mat-label>
            <mat-form-field appearance="outline">
              <mat-select
                id="entityConfiguration"
                [(ngModel)]="entityConfig.type"
                (selectionChange)="entityConfigChange()"
              >
                <mat-option value="" id="none">{{ labels?.None }}</mat-option>
                <mat-option value="table" id="entityConfigurationTable">{{ labels?.Table }}</mat-option>
                <mat-option value="verticalTable" id="entityConfigurationVerticalTable">{{labels?.Vertical_Table}}</mat-option>
                <mat-option value="card" id="entityConfigurationCard">{{ labels?.Default_Card }}</mat-option>
                <mat-option value="cardWithTemplate" id="entityConfigurationCardWithTemplate">
                  {{ labels?.Card_With_Templates }}
                </mat-option>
                <mat-option
                  *ngIf="!configurationData?.data?.nestedEntity"
                  value="accordion"
                  id="entityConfigurationCardWithTemplate"
                >
                  {{ labels?.Accordion }}
                </mat-option>
                <mat-option value="widgets" id="entityConfigurationWidget">
                  {{labels?.Widgets}}
                </mat-option>
                <mat-option value="progressBar" id="entityConfigurationProgressBar">
                  {{ labels?.Progress_Bar ? labels.Progress_Bar : 'Progress Bar' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- For Conditional Potentiality -->
          <!-- <div>
            <mat-label class="label-txt">{{ labels?.Conditional_Potentiality }}</mat-label>
            <mat-radio-group fxLayout="column" [(ngModel)]="isConditionalPotentiality">
              <mat-radio-button id="conditionalPotentialityYes" [value]="true">{{ labels?.Yes }}</mat-radio-button>
              <mat-radio-button id="conditionalPotentialityNo" [value]="false">{{ labels?.No }} </mat-radio-button>
            </mat-radio-group>
          </div> -->

          <!-- For Display -->
          <div
            class="ui-controls-content"
            *ngIf="
              entityConfig.type == 'cardWithTemplate' ||
              entityConfig.type == 'card' ||
              entityConfig.type == 'verticalTable'
            "
          >
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ labels?.Display }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- Entity Configuration for Card and Card with template -->
                <div *ngIf="entityConfig.type == 'cardWithTemplate' || entityConfig.type == 'card'">
                  <mat-radio-group fxLayout="column" [(ngModel)]="entityConfig.cardSelection" name="isConfigureAsCard">
                    <mat-radio-button id="configureSingle" class="px-2" value="single">{{
                      labels?.Single_Select
                    }}</mat-radio-button>
                    <mat-radio-button id="configureMulti" class="px-2" value="multiple">{{
                      labels?.Multi_Select
                    }}</mat-radio-button>
                    <mat-slide-toggle id="deleteButton" pTooltip="Do you need Delete Button?" tooltipPosition="top" [(ngModel)]="isDeleteButton"
                      >Delete Button</mat-slide-toggle
                    >
                  </mat-radio-group>
                  <div *ngIf="entityConfig.cardSelection == 'single'">
                    Do you want to submit on selection ?
                    <mat-slide-toggle id="submitOnSelection" [(ngModel)]="submitOnSelection"></mat-slide-toggle>
                  </div>
                  <div *ngIf="entityConfig.cardSelection == 'multiple'">
                    <form class="example-form d-flex" [formGroup]="cardformGroup" ngNativeValidate>
                      <div class="w-50">
                        <mat-form-field floatLabel="never">
                          <label class="mb-2">{{ labels?.Minimum_Selection }}</label>
                          <input
                            type="number"
                            class=""
                            [min]="1"
                            [max]="cardformGroup.get('maximumselection').value"
                            [readonly]="cardformGroup.get('minimumselectionAll').value"
                            matInput
                            formControlName="minimumselection"
                          />
                        </mat-form-field>
                        <mat-checkbox formControlName="minimumselectionAll">
                          {{ labels?.All }}
                        </mat-checkbox>
                      </div>

                      <div class="w-50">
                        <mat-form-field floatLabel="never">
                          <label class="mb-2">{{ labels?.Maximum_Selection }}</label>
                          <input
                            type="number"
                            class=""
                            [min]="cardformGroup.get('minimumselection').value"
                            [readonly]="cardformGroup.get('minimumselectionAll').value"
                            matInput
                            formControlName="maximumselection"
                          />
                        </mat-form-field>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- Entity Configuration only for Card with template -->
                <div class="mt-3" *ngIf="entityConfig.type == 'cardWithTemplate'">
                  <app-card-view-control
                    [isGrammer]="true"
                    (cardViewEvent)="saveCardViewDetails($event)"
                    (cancelEvent)="closeEntity()"
                    (cardcustomizeEvent)="cardCustomizeHandler($event)"
                    [cardEntityData]="configurationData.data.entityData"
                  ></app-card-view-control>
                </div>
                <div class="mt-3" *ngIf="entityConfig.type == 'verticalTable'">
                  <app-vertical-table-view-control
                    [isGrammer]="true"
                    (verticalTableViewEvent)="saveVerticalTableDetails($event)"
                    (cancelEvent)="closeEntity()"
                    (verticalTableCustomizeEvent)="verticalTableCustomizeHandler($event)"
                    [vTableEntityData]="configurationData.data.entityData"
                  >
                  </app-vertical-table-view-control>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- Entity Configuration for Progress Bar -->
          <div *ngIf="entityConfig.type == 'progressBar'" class="progressbar-config-panel">
            <div class="title-bar">
              <h2>{{ labels?.Progress_Bar ? labels.Progress_Bar : 'Progress Bar' }}</h2>
              <div class="progressbar-actions">
                <button class="btn done" (click)="saveconf()">{{ labels?.Done }}</button>
                <!-- <i class="bi bi-x-circle"></i> -->
              </div>
            </div>
            <div class="mb-3">
              <h3 class="pb-title">{{ labels?.Orientation ? labels.Orientation : 'Orientation' }}</h3>
              <mat-radio-group fxLayout="column" [(ngModel)]="entityConfig.orientation" name="isConfigureAsProgressBar">
                <mat-radio-button id="configureHorizontal" class="" value="horizontal">{{
                  labels?.Horizontal ? labels.Horizontal : 'Horizontal'
                }}</mat-radio-button>
                <mat-radio-button id="configureVertical" class="px-2" value="vertical">{{
                  labels?.Vertical ? labels.Vertical : 'Vertical'
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mb-3 d-flex justify-content-between align-items-center">
              <h3 class="hideDefaultVal-heading">
                {{ labels?.Hide_Default_Value ? labels.Hide_Default_Value : 'Hide Default Value' }}
              </h3>
              <mat-slide-toggle
                id="slide_toggleBtn_hideDefaultValue"
                [(ngModel)]="entityConfig.hideDefaultValue"
                [ngModelOptions]="{ standalone: true }"
                value="true"
              ></mat-slide-toggle>
            </div>
            <div class="mb-3">
              <h3 class="pb-title">{{ labels?.Color ? labels.Color : 'Color' }}</h3>
              <div class="color-customisation">
                <ul>
                  <li>
                    <label class="pb-label">{{ labels?.Active ? labels.Active : 'Active' }}</label>
                    <div class="color-items">
                      <input
                        type="text"
                        class="inputwidth"
                        [ngStyle]="{ background: entityConfig.colouring.active, color: 'transparent' }"
                        [(ngModel)]="entityConfig.colouring.active"
                      />
                      <i
                        class="bi bi-eyedropper"
                        (click)="TableColorPickr = !TableColorPickr"
                        [(colorPicker)]="entityConfig.colouring.active"
                      ></i>
                    </div>
                  </li>
                  <li>
                    <label class="pb-label">{{ labels?.Inactive ? labels.Inactive : 'InActive' }}</label>
                    <div class="color-items">
                      <input
                        type="text"
                        class="inputwidth"
                        [ngStyle]="{ background: entityConfig.colouring.Inactive, color: 'transparent' }"
                        [(ngModel)]="entityConfig.colouring.Inactive"
                      />
                      <i
                        class="bi bi-eyedropper"
                        (click)="TableColorPickr = !TableColorPickr"
                        [(colorPicker)]="entityConfig.colouring.Inactive"
                      ></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mb-3">
              <h3 class="pb-title">{{ labels?.Font ? labels.Font : 'Font' }}</h3>
              <div class="color-customisation">
                <ul>
                  <li>
                    <label class="pb-label">{{ labels?.color }}</label>
                    <div class="color-items">
                      <input
                        type="text"
                        class="inputwidth"
                        [ngStyle]="{ background: entityConfig.colouring.color, color: 'transparent' }"
                        [(ngModel)]="entityConfig.colouring.color"
                      />
                      <i
                        class="bi bi-eyedropper"
                        (click)="TableColorPickr = !TableColorPickr"
                        [(colorPicker)]="entityConfig.colouring.color"
                      ></i>
                    </div>
                  </li>
                  <li>
                    <label class="pb-label">{{ labels?.Size ? labels.Size : 'Size' }}</label>
                    <mat-select [(ngModel)]="entityConfig.colouring.size" id="dropdown_size" class="size-input">
                      <div class="select-dropdown-list">
                        <mat-option *ngFor="let option of options" id="dropdownselect_{{ option }}" [value]="option">
                          {{ option }}
                        </mat-option>
                      </div>
                    </mat-select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- For Advanced settings -->
        <mat-expansion-panel class="permission-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel class="permission-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Permissions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="assign-rights">
                <ul>
                  <li class="roles-li" *ngFor="let role of roles; let i = index">
                    <div class="actions-item">
                      <button
                      >
                        {{ role.name }}
                      </button>
                      <div id="cuRoleshovered" class="actions-item-hover">
                        <div class="hover-icons">
                          <button (click)="showRights(i)">
                            <figure>
                              <img [src]="'/assets/img/edit-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Edit }}</span>
                          </button>
                          <!-- <button mat-menu-item>
                            <figure>
                              <img [src]="'/assets/img/info-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Information }}</span>
                          </button> -->
                          <button mat-menu-item (click)="removeAgent(i)">
                            <figure>
                              <img [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Delete }} </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="selectedindex == i" class="read-right-actions">
                      <div>
                        <label>{{ labels?.Solution_Design }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                DesignRights &&
                                DesignRights.length > 0 &&
                                DesignRights[getDesignRightsIndex(role)] &&
                                DesignRights[getDesignRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                DesignRights &&
                                DesignRights.length > 0 &&
                                DesignRights[getDesignRightsIndex(role)] &&
                                DesignRights[getDesignRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(DesignRights[getDesignRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(TxnRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].executionRight
                              "
                              (change)="autoCheckRights(TxnRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Execute }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ labels?.download_all }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <mat-slide-toggle [(ngModel)]="entityConfig.downloadAll">{{
                    labels?.enable_download
                  }}</mat-slide-toggle>
                </div>
                <mat-form-field>
                  <mat-label>{{ labels?.select_attribute }}</mat-label>
                  <mat-select>
                    <mat-option
                      *ngFor="let attr of configurationData?.data?.entityData?.nslAttributes; let i = index"
                      [value]="attr.name"
                      (click)="selectedAttribute(attr.name)"
                    >
                      {{ attr.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div></div>
  </div>
</div>
