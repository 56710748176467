let transform = require('node-json-transform').transform;

export function facetSearchMapper(data1: any) {
  var map = {
    item: {
      result: {
        content: [
          {
            aggregates: 'content.defaultFilters',
            data: 'content.cards',
            dateInterval: 'content.dateIntervalFilters',
            nestedEntities: 'content.nestedEntities',
            numberInterval: 'content.numberIntervalFilters',
            range: 'content.rangeFilters',
          },
        ],
        pageable: {
          page: 'pageable.pageNumber',
          size: 'pageable.pageSize',
          sort: {
            orders: [{}],
          },
        },
        total: 'total',
      },
    },
    operate: [
      {
        run: function (val1: any) {
          let arr: any = [];
          if (data1.content?.cards) {
            val1.forEach((val: any) => {
              arr.push({
                displayName: val.displayName,
                id: val.id,
                isFavorite: val.favorite,
                name: val.name,
                itemCountInCart: val.itemCountInCart,
                generalEntityId: val.generalEntityId,
                transEntityRecords: [
                  {
                    txnNslAttribute: val.data,
                  },
                ],
              });
            });
            return arr;
          } else {
            return val1;
          }
        },
        on: 'result.content.0.data',
      },
      {
        run: function (val: any) {
          if (data1.content?.defaultFilters) {
            Object.keys(val).forEach((key: any) => {
              val[key].forEach((value1: any) => {
                value1['v1'] = value1.value;
                value1['v2'] = value1.count;
                delete value1.value;
                delete value1.count;
              });
            });
            return val;
          } else {
            return val;
          }
        },
        on: 'result.content.0.aggregates',
      },
      {
        run: function (val: any) {
          if (data1.content?.numberIntervalFilters) {
            Object.keys(val).forEach((key1: any) => {
              val[key1].forEach((value1: any) => {
                const version = Object.keys(value1)[0];
                value1['v1'] = version;
                value1['v2'] = value1[version];
                delete value1[version];
              });
            });
            return val;
          } else {
            return val;
          }
        },
        on: 'result.content.0.numberInterval',
      },
    ],
  };

  const result = transform(data1, map);

  return result;
}
