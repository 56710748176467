<div class="table-main-wrapper">
  <!--Header area-->
  <div class="table-header">
    <div class="con-header-left">
      <h3>
        {{ labels?.Table_Configuration }}
      </h3>
    </div>
    <div class="con-header-right">
      <button class="btn save" (click)="saveconf()">{{ labels?.Save }}</button>
      <button class="btn-close" (click)="closeTableUiConfiguration()"><i class="bi bi-x-square"></i></button>
    </div>
  </div>
  <!--Content area-->
  <div class="table-content">
    <div class="con-left-content">
      <!-- Source Area -->
      <div class="commonbar source-bar">
        <h5 class="pt-0">{{ configdata?.data?.displayName }}</h5>
        <div class="con-right-topbar one">
          <h5 class="mb-0 mt-4 pt-0">Export</h5>
        </div>

        <div class="fileformat">
          <div class="fileformat1" *ngFor="let option of entityConfig?.export">
            <input type="checkbox" [(ngModel)]="option.isChecked" id="{{ option.label }}" />
            <label for="{{ option.label }}">{{ option.label }}</label>
          </div>
        </div>

        <div class="con-right-topbar two">
          <h5 class="mb-0">{{ labels?.Colouring }}</h5>
        </div>
        <div>
          <div class="colors-wrap tbl-btn-group">
            <ul>
              <li>
                <div class="color-items">
                  <p>{{ labels?.Table_Background }}</p>
                </div>
                <div class="color-items">
                  <figure>
                    <img
                      [src]="'../../../../../../assets/img/icon-color.svg' | CDNUrlRewritePipe"
                      alt=""
                      (click)="TableColorPickr = !TableColorPickr"
                      [(colorPicker)]="entityConfig.colouring.tableBackground"
                    />
                  </figure>
                  <input type="text" class="inputwidth" [(ngModel)]="entityConfig.colouring.tableBackground" />
                </div>
              </li>
              <li>
                <div class="color-items">
                  <p>{{ labels?.Table_Header }}</p>
                </div>
                <div class="color-items">
                  <figure>
                    <img
                      [src]="'../../../../../../assets/img/icon-color.svg' | CDNUrlRewritePipe"
                      alt=""
                      (click)="TableColorPickr = !TableColorPickr"
                      [(colorPicker)]="entityConfig.colouring.tableHeader"
                    />
                  </figure>
                  <input type="text" class="inputwidth" [(ngModel)]="entityConfig.colouring.tableHeader" />
                </div>
              </li>
              <li>
                <div class="color-items">
                  <p>{{ labels?.Table_Row_Alternate_Background }}</p>
                </div>
                <div class="color-items">
                  <mat-slide-toggle [(ngModel)]="entityConfig.colouring.alternateBackground"></mat-slide-toggle>
                </div>
              </li>
              <li>
                <div class="color-items">
                  <p>{{ labels?.Select_Alternate_Pattern }}</p>
                </div>
                <div class="color-items">
                  <figure>
                    <img
                      [src]="'../../../../../../assets/img/icon-color.svg' | CDNUrlRewritePipe"
                      alt=""
                      (click)="TableColorPickr = !TableColorPickr"
                      [(colorPicker)]="entityConfig.colouring.selectAlternatePattern"
                    />
                  </figure>
                  <input type="text" class="inputwidth" [(ngModel)]="entityConfig.colouring.selectAlternatePattern" />
                </div>
              </li>
              <li>
                <div class="color-items">
                  <p>{{ labels?.Table_Border }}</p>
                </div>
                <div class="color-items">
                  <figure>
                    <img
                      [src]="'../../../../../../assets/img/icon-color.svg' | CDNUrlRewritePipe"
                      alt=""
                      (click)="TableColorPickr = !TableColorPickr"
                      [(colorPicker)]="entityConfig.colouring.tableBorder"
                    />
                  </figure>
                  <input type="text" class="inputwidth" [(ngModel)]="entityConfig.colouring.tableBorder" />
                </div>
              </li>
              <li>
                <div class="color-items">
                  <p>{{ labels?.Cell_Hover_Color }}</p>
                </div>
                <div class="color-items">
                  <figure>
                    <img
                      [src]="'../../../../../../assets/img/icon-color.svg' | CDNUrlRewritePipe"
                      alt=""
                      (click)="TableColorPickr = !TableColorPickr"
                      [(colorPicker)]="entityConfig.colouring.cellHoverColor"
                    />
                  </figure>
                  <input type="text" class="inputwidth" [(ngModel)]="entityConfig.colouring.cellHoverColor" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Target Area -->
      <div class="commonbar target-bar middle-bar middlebar1">
        <div class="con-right-topbar four">
          <h5 class="mb-0 mt-4">{{ labels?.Pagination }}</h5>
        </div>
        <div>
          <div class="sub-ttle">{{ labels?.Number_of_rows_per_page }}</div>
          <div class="w-25">
            <select id="Number_of_rows_per_page" class="form-control form-control-sm mb-3" [(ngModel)]="entityConfig.pagination.rows">
              <option id="row_per_page_10" [value]="10">10</option>
              <option id="row_per_page_25" [value]="25">25</option>
              <option id="row_per_page_50" [value]="50">50</option>
            </select>
          </div>
        </div>

        <div class="fileformat1">
          <div class="sub-ttle">{{ labels?.Placement_of_pagination }}</div>
          <div class="tbl-btn-group">
            <input
              type="radio"
              id="myCheckboxi"
              name="pagination"
              (change)="changeSelection('left')"
              [checked]="entityConfig.pagination.placementOfPagination == 'left'"
            />
            <label for="myCheckboxi">{{ labels?.Left }}</label>

            <input
              type="radio"
              id="myCheckboxi2"
              name="pagination"
              (change)="changeSelection('right')"
              [checked]="entityConfig.pagination.placementOfPagination == 'right'"
            />
            <label for="myCheckboxi2">{{ labels?.Right }}</label>

            <input
              type="radio"
              id="myCheckboxi3"
              name="pagination"
              (change)="changeSelection('center')"
              [checked]="entityConfig.pagination.placementOfPagination == 'center'"
            />
            <label for="myCheckboxi3">{{ labels?.Center }}</label>
          </div>
        </div>
        <div class="con-right-topbar five">
          <h5 class="mb-0 mt-4">{{ labels?.Advanced_Features }}</h5>
        </div>

        <div class="advd-featur">
          <form class="example-form" [formGroup]="formGroup" ngNativeValidate>
            <mat-slide-toggle formControlName="showTitle" id="configshowTitle" labelPosition="before">{{
              labels?.Show_Title
            }}</mat-slide-toggle>
            <mat-slide-toggle formControlName="pagination" id="configurepagination" labelPosition="before">{{
              labels?.Pagination
            }}</mat-slide-toggle>
            <mat-slide-toggle id="configallowColumnFilter" formControlName="allowColumnFilter" labelPosition="before">{{
              labels?.Allow_Column_Filter
            }}</mat-slide-toggle>
            <mat-form-field appearance="fill" floatLabel="never" *ngIf="formGroup.value.allowColumnFilter">
              <mat-label>Hide attribute filters for</mat-label>
              <mat-select
                #select
                formControlName="filterFields"
                multiple
                class="ui-custom-multi-select-dropdown"
                panelClass="ui-custom-select-options ui-custom-select-multiple"
              >
                <mat-option
                  *ngFor="let attr of configdata?.data?.attributeList"
                  [value]="attr.displayName ? attr.displayName : attr.name"
                >
                  {{ attr.displayName ? attr.displayName : attr.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle id="configallowColumnSearch" formControlName="allowColumnSearch" labelPosition="before">{{
              labels?.Allow_Column_Search
            }}</mat-slide-toggle>
            <mat-form-field appearance="fill" floatLabel="never" *ngIf="formGroup.value.allowColumnSearch">
              <mat-label>Hide attribute search for</mat-label>
              <mat-select
                #select
                formControlName="searchFields"
                multiple
                class="ui-custom-multi-select-dropdown"
                panelClass="ui-custom-select-options ui-custom-select-multiple"
              >
                <mat-option
                  *ngFor="let attr of configdata?.data?.attributeList"
                  [value]="attr.displayName ? attr.displayName : attr.name"
                >
                  {{ attr.displayName ? attr.displayName : attr.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle formControlName="allowColumnsort" id="configallowColumnsort" labelPosition="before">{{
              labels?.Allow_Column_Sort
            }}</mat-slide-toggle>
            <mat-form-field appearance="fill" floatLabel="never" *ngIf="formGroup.value.allowColumnsort">
              <mat-label>Hide attribute sort for</mat-label>
              <mat-select
                #select
                formControlName="sortFields"
                multiple
                class="ui-custom-multi-select-dropdown"
                panelClass="ui-custom-select-options ui-custom-select-multiple"
              >
                <mat-option
                  *ngFor="let attr of configdata?.data?.attributeList"
                  [value]="attr.displayName ? attr.displayName : attr.name"
                >
                  {{ attr.displayName ? attr.displayName : attr.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle id="configallowDownload" formControlName="allowDownload" labelPosition="before">{{
              labels?.Allow_To_Download
            }}</mat-slide-toggle>
            <mat-slide-toggle id="allowExpand" formControlName="allowExpand" labelPosition="before">
              {{ labels?.Table_expand_and_close }}</mat-slide-toggle
            >
            <mat-slide-toggle id="allowToggleColumn" formControlName="toggleColumn" labelPosition="before">
              {{ labels?.Toggle_Column }}
            </mat-slide-toggle>
            <mat-slide-toggle id="allowSelection" formControlName="allowSelection" labelPosition="before">
              {{ labels?.Row_Selection }}</mat-slide-toggle
            >
            <mat-slide-toggle id="configallowUpload" formControlName="allowUpload" labelPosition="before">{{
              labels?.Allow_Upload
            }}</mat-slide-toggle>
            <mat-slide-toggle id="confighideserialnumber" formControlName="hideSerialNumber" labelPosition="before">{{
              labels?.Hide_Serial_Number
            }}</mat-slide-toggle>
            <mat-slide-toggle id="confighideselectbutton" formControlName="hideSelectButton" labelPosition="before">{{
              labels?.Hide_Select_Button
            }}</mat-slide-toggle>
            <mat-slide-toggle id="confighideLabels" formControlName="hideLabels" labelPosition="before">{{
              labels?.Hide_Labels
            }}</mat-slide-toggle>
            <mat-slide-toggle
              id="configdeleterecord"
              formControlName="deleteRecord"
              labelPosition="before"
              [(ngModel)]="formGroup.value.deleteRecord"
            >
              {{ labels?.Delete_Record }}
            </mat-slide-toggle>
            <mat-slide-toggle id="allowDuplication" formControlName="allowDuplication" labelPosition="before">{{
              labels?.Allow_Duplication_Of_Records
            }}</mat-slide-toggle>
            <mat-slide-toggle id="configGridTable" formControlName="viewAsGridTable" labelPosition="before">
              {{ labels?.View_as_Grid_Table }}
            </mat-slide-toggle>

            <mat-form-field>
              <mat-label>{{ labels?.Freeze_Columns }}</mat-label>
              <mat-select
                formControlName="freezeColumns"
                id="configFreezeColumns"
                panelClass="ui-custom-select-options tbl-custom-select-options"
              >
                <mat-option *ngIf="!formGroup.value.hideSelectButton" value="selectionColumn"
                  >Selection Column</mat-option
                >
                <mat-option *ngIf="!formGroup.value.hideSerialNumber" value="SrNo">{{ labels?.Sr_No }}</mat-option>
                <mat-option
                  *ngFor="let attr of configdata?.data?.attributeList"
                  [value]="attr.displayName ? attr.displayName : attr.name"
                >
                  {{ attr.displayName ? attr.displayName : attr.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="table-right-content">
      <div class="tble-colmns">
        <div class="con-right-topbar six">
          <h5 class="mb-0 mt-4">{{ labels?.Table_Description }}</h5>
        </div>
        <div class="con-list">
          <div class="sub-ttle">
            {{ labels?.Header_Description }}
          </div>
          <div class="w-100">
            <textarea
              name=""
              id=""
              rows="3"
              class="form-control"
              [(ngModel)]="entityConfig.tableDescription.headerDescription"
              id="headerDescriptionTextArea"
            ></textarea>
          </div>
          <div class="sub-ttle mt-4">
            {{ labels?.Footer_Description }}
          </div>
          <div class="w-100">
            <textarea
              name=""
              id=""
              rows="3"
              class="form-control"
              id="footerDescriptionTextArea"
              [(ngModel)]="entityConfig.tableDescription.footerDescription"
            ></textarea>
          </div>
        </div>
        <div class="multi-value-list" class="disply-wrap">
          <div class="display-items">
            <span>{{ labels?.Display }} </span>
          </div>
          <div class="table-section">
            <mat-radio-group
              class="radio-section tableradion-btns"
              fxLayout="column"
              [(ngModel)]="entityConfig.tableSelection"
              name="configureTableSelection"
              [disabled]="formGroup.value.hideSelectButton"
            >
              <mat-radio-button id="configureSingle" class="px-2" value="single">{{
                labels?.Single_Select
              }}</mat-radio-button>
              <mat-radio-button id="configureMulti" class="px-2" value="multiple">{{
                labels?.Multi_Select
              }}</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="entityConfig.tableSelection == 'single'">
              {{ labels?.Do_you_want_to_submit_on_selection }}?
              <mat-slide-toggle id="submitOnSelection" [(ngModel)]="submitOnSelection"></mat-slide-toggle>
            </div>
            <div *ngIf="entityConfig.tableSelection == 'multiple'">
              <form class="example-form" [formGroup]="cardformGroup" ngNativeValidate>
                <div class="d-flex">
                  <div class="w-50">
                    <mat-form-field floatLabel="never">
                      <label class="mb-2">{{ labels?.Minimum_Selection }}</label>
                      <input
                        type="number"
                        class=""
                        [min]="1"
                        [max]="cardformGroup.get('maximumselection').value"
                        [ngModel]="entityConfig?.tableSettings?.minimumselection"
                        [readonly]="cardformGroup.get('minimumselectionAll').value || formGroup.value.hideSelectButton"
                        matInput
                        formControlName="minimumselection"
                      />
                    </mat-form-field>
                  </div>

                  <div class="w-50">
                    <mat-form-field floatLabel="never">
                      <label class="mb-2">{{ labels?.Maximum_Selection }}</label>
                      <input
                        type="number"
                        class=""
                        [min]="cardformGroup.get('minimumselection').value"
                        [ngModel]="entityConfig?.tableSettings?.maximumselection"
                        [readonly]="cardformGroup.get('minimumselectionAll').value || formGroup.value.hideSelectButton"
                        matInput
                        formControlName="maximumselection"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="w-100">
                  <mat-checkbox formControlName="minimumselectionAll" [disabled]="formGroup.value.hideSelectButton">
                    {{ labels?.All }}
                  </mat-checkbox>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="tble-colmns">
        colomns
      </div> -->
    </div>
  </div>
</div>
