import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService, TransactionFacadeService, getCurrentEventDataArray, getEventData } from '@common-services';

@Component({
  selector: 'app-template-one-card',
  templateUrl: './template-one-card.component.html',
  styleUrls: ['./template-one-card.component.scss'],
})
export class TemplateOneCardComponent implements OnInit, OnDestroy {
  @Input() entity: any;
  @Input() attributePositions: any;
  @Input() settings: any;
  @Input() eventCUList: any;
  @Output() cardselected: EventEmitter<any> = new EventEmitter<any>();

  labels: any;
  isChecked: boolean = false;
  cardData: Array<any> = new Array(5);
  currentEventDataArray: any = [[], [], [], [], []];

  unSubscribe = new Subject();

  constructor(private translator: TranslatorService, private transactionFacadeService: TransactionFacadeService) {
    this.detectLanguageChange();
  }

  /* preparing the cards data for displying*/
  ngOnInit(): void {
    this.entity?.forEach((ele: any) => {
      if (this.attributePositions[ele.attributeName]) {
        const index = this.attributePositions[ele.attributeName];
        this.cardData[index - 1] = ele;
      }
    });
  }

  /* get the translation lables based on selected language*/
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  /* emit the selected cards to dynamic form*/
  selectedCard() {
    this.isChecked = !this.isChecked;
    this.cardselected.emit({ checked: this.isChecked });
  }

  // Check event data based on attribute id
  checkEventGSI(attr: any, index: number) {
    /*istanbul ignore next*/
    this.currentEventDataArray[index] = getCurrentEventDataArray(this.eventCUList, attr);
    if (this.currentEventDataArray[index].length > 0) {
      return true;
    }
    return false;
  }

  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any) {
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
