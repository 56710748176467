export interface betInfo {
  betDsdId: number;
  betType: string;
  dsdId?: string;
  betName?: string;
}

export interface Notepad {
  betDsdId: number;
  betType: string;
  dsdId?: string;
  content: string;
  mimeType?: string;
  fileName: string;
  betName: string;
  betDisplayName: string;
}

export interface paginationDetails {
  totalNumberOfPage: number;
  totalNumberOfRecords: number;
  pageSize: number;
  pageNumber: number;
}

export interface ReviewDetails {
  marketplaceId: any;
  rating: any;
  title: string;
  reviewComment?: string;
  reviewTime?: number;
}

export class ReviewDetails {
  constructor() {
    this.marketplaceId = {};
    this.rating = 0;
    this.title = '';
    this.reviewComment = '';
    this.reviewTime = 0;
  }
}
