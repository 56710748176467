<ng-template #actionButton let-data="data">
  <span class="btn-close" *ngIf="data?.cardDisplay == 'design'">
    <i class="bi bi-x" *ngIf="childGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
    <button
      *ngIf="!isGrammer"
      #gsiAction
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [_style]="style"
      [tabSelected]="tabSelected"
      [isDesign]="isDesign"
      [isButton]="true"
      [_selectedDirectiveId]="selectedDirectiveId"
      (drop)="dropActionBtn($event)"
      (dragover)="allowDrop($event)"
      class="btn template-btn action-button btnaction text-truncate"
      pTooltip="Action Button"
      [id]="'action-' + data?.position"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      [(styleGsi)]="styleGsi[data?.position]"
      [ngClass]="data?.class"
    >
      {{childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'Action Button'}}
    </button>
  </span>
  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.matIcon">
      <button
        [ngStyle]="{ background: btnColors?.[data?.position] }"
        class="btn btn-preview"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        pTooltip=" {{ gsiMapping[data?.index]?.displayName }}"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
        <mat-icon *ngIf="data?.icon">{{ data?.icon }}</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="data?.matIcon">
      <mat-icon
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        pTooltip=" {{ gsiMapping?.[data?.position]?.name }}"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ data?.matIcon }}
      </mat-icon>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <li
      *ngIf="!data.isImage"
      [id]="attributeItem?.innerHTML"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      [_style]="style"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      [tabSelected]="tabSelected"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="styleAttribute[data?.position]"
    >
      <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
      /></span>
    </li>
    <li
      *ngIf="data?.isImage"
      id="attr-{{ data?.position }}"
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      [ngClass]="data?.class"
    >
      {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
    </li>
  </ng-container>

  <ng-container *ngIf="data.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.isImage">
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[conditionArray]"
        *ngIf="!data?.isSingleValue && !data?.isSingleDisplay && !data?.isSingleValueWOC"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        <strong class="passcolor">{{ card?.recordData?.[data.position]?.displayName }}</strong>
        <span>{{ card?.recordData?.[data.position]?.value }}</span>
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[conditionArray]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[conditionArray]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleDisplay"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.displayName }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[conditionArray]"
        *ngIf="data?.isSingleValueWOC"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
    </ng-container>

    <ng-container *ngIf="data?.isImage">
      <img
        [ngClass]="data?.class"
        *ngIf="(card?.recordData)[data.position]?.contentUrl"
        [src]="(card?.recordData)[data.position]?.contentUrl | CDNUrlRewritePipe"
        alt="general-entity"
      />
    </ng-container>
  </ng-container>
</ng-template>

<div class="insurance-child-preview-wrap">
  <div
    class="insurance-child-preview-1 insurance-child-preview Attributes-4"
    *ngIf="templateType == '34'"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <!-- <img [src]="card?.recordData?.[0]?.contentUrl | CDNUrlRewritePipe" class="card-img-top" alt="card-img-top" /> -->
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
        }"
      ></ng-template>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <ul class="card-ul">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </ul>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-2 insurance-child-preview Attributes-4"
    *ngIf="templateType == '35'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <h5 class="card-subtitle">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
        ></ng-template>
      </h5>
      <h1 class="card-title">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
        ></ng-template>
      </h1>
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h4 class="card-body-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-3 insurance-child-preview Attributes-2"
    *ngIf="templateType == '36'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h4 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, cardDisplay: cardDisplay, matIcon: 'arrow_right_alt', class: 'btn btn-preview' }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-4 insurance-child-preview Attributes-2"
    *ngIf="templateType == '37'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <h4 class="card-title">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
        ></ng-template>
      </h4>
    </div>
  </div>
  <div
    class="insurance-child-preview-5 insurance-child-preview Attributes-3"
    *ngIf="templateType == '38'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <h2 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h2>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-6 insurance-child-preview Attributes-2"
    *ngIf="templateType == '39'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-7 insurance-child-preview Attributes-6"
    *ngIf="templateType == '40'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <figure>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
        }"
      ></ng-template>
    </figure>
    <div class="card">
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <h3 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h3>
        <p class="card-para">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </p>
        <div class="line-wrap">
          <h4 class="line-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h4>
        </div>
        <div class="line-wrap">
          <h4 class="line-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 5, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h4>
        </div>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, icon: 'chevron_right', class: 'btn btn-preview', cardDisplay: cardDisplay }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 1, icon: 'chevron_right', class: 'btn btn-preview', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-8 insurance-child-preview Attributes-6"
    *ngIf="templateType == '41'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
        }"
      ></ng-template>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <ul class="card-ul">
          <li>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </li>
          <li class="clr-labels">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 5, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </li>
        </ul>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-9 insurance-child-preview Attributes-2"
    *ngIf="templateType == '42'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h4 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, cardDisplay: cardDisplay, matIcon: 'arrow_right_alt', class: 'btn btn-preview' }
            }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-10 insurance-child-preview Attributes-2"
    *ngIf="templateType == '43'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <h4 class="card-title">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
        ></ng-template>
      </h4>
    </div>
  </div>
  <div
    class="insurance-child-preview-11 insurance-child-preview Attributes-10"
    *ngIf="templateType == '44'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <div class="card-header">
        <figure>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
            }"
          ></ng-template>
        </figure>
        <h4 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
      </div>
      <div class="card-body">
        <h4 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <h5 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h5>
        <ul class="list-group">
          <li>
            <h3 class="listgroup-title">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
              ></ng-template>
            </h3>
            <p class="listgroup-para">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{ data: { position: 5, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
              ></ng-template>
            </p>
          </li>
          <li>
            <h3 class="listgroup-title">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{ data: { position: 6, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
              ></ng-template>
            </h3>
            <p class="listgroup-para">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{ data: { position: 7, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
              ></ng-template>
            </p>
          </li>
        </ul>
        <div class="card-ftr">
          <h4 class="card-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 8, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h4>
          <h5 class="card-smalltitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 9, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h5>
        </div>

        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-12 insurance-child-preview Attributes-13"
    *ngIf="templateType == '45'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <p class="card-para">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </p>
        <div class="cards-features">
          <h3 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h3>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
          </ul>
        </div>
        <div class="cards-extrafeatures">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 5, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 6, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 7, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 8, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
          </ul>
        </div>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-13 insurance-child-preview Attributes-3"
    *ngIf="templateType == '46'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h1 class="card-title text-truncate" pTooltip="{{ card?.recordData?.[1]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <h2 class="card-subtitle text-truncate" pTooltip="{{ card?.recordData?.[2]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h2>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-14 insurance-child-preview Attributes-5"
    *ngIf="templateType == '47'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <h2 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h2>
        <ul class="card-list">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValue: true } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValue: true } }"
          ></ng-template>
        </ul>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-15 insurance-child-preview Attributes-4"
    *ngIf="templateType == '48'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <h5 class="card-subtitle">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
        ></ng-template>
      </h5>
      <h1 class="card-title">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
        ></ng-template>
      </h1>
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h4 class="card-body-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-16 insurance-child-preview Attributes-2"
    *ngIf="templateType == '49'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-17 insurance-child-preview Attributes-5"
    *ngIf="templateType == '50'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <figure>
      <ng-template
        [ngTemplateOutlet]="attributeTemplate"
        [ngTemplateOutletContext]="{
          data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
        }"
      ></ng-template>
    </figure>
    <div class="card">
      <div class="card-body">
        <h1 class="card-title text-truncate" pTooltip="{{ card?.recordData?.[1]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h1>
        <h3 class="card-subtitle text-truncate" pTooltip="{{ card?.recordData?.[2]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h3>
        <p class="card-para lineclamp" pTooltip="{{ card?.recordData?.[3]?.value }}" tooltipPosition="top">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </p>
        <div class="line-wrap">
          <h4 class="line-title lineclamp" pTooltip="{{ card?.recordData?.[4]?.value }}" tooltipPosition="top">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h4>
        </div>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-18 insurance-child-preview Attributes-8"
    *ngIf="templateType == '51'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <div class="card-header">
        <figure>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
            }"
          ></ng-template>
        </figure>
        <button class="btn btn-top">Individual</button>
      </div>
      <div class="card-body">
        <h4 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <h5 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h5>
        <ul class="list-group">
          <li>
            <h3 class="listgroup-title">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
              ></ng-template>
            </h3>
            <p class="listgroup-para">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
              ></ng-template>
            </p>
          </li>
        </ul>
        <div class="card-ftr">
          <h4 class="card-small">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 5, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h4>
          <h5 class="card-smalltitle">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 6, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h5>
        </div>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-19 insurance-child-preview Attributes-13"
    *ngIf="templateType == '52'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h4 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <h4 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </h4>
        <p class="card-para">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
          ></ng-template>
        </p>
        <div class="cards-features">
          <h3 class="card-title">
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValueWOC: true } }"
            ></ng-template>
          </h3>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 5, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 6, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
          </ul>
        </div>
        <div class="cards-extrafeatures">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 7, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 8, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 9, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{ data: { position: 10, cardDisplay: cardDisplay, isSingleValue: true } }"
            ></ng-template>
          </ul>
        </div>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
  <div
    class="insurance-child-preview-20 insurance-child-preview Attributes-5"
    *ngIf="templateType == '53'"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [_otherStyle]="_otherStyle"
    [cardColor]="cardColor"
    [attributeColors]="attributeColors"
    [conditionArray]="conditionArray"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="card">
      <figure>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, class: 'card-img-top' }
          }"
        ></ng-template>
      </figure>
      <div class="card-body">
        <h1 class="card-title">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isSingleValue: true } }"
          ></ng-template>
        </h1>
        <h2 class="card-subtitle">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isSingleValue: true } }"
          ></ng-template>
        </h2>
        <ul class="card-list">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, isSingleValue: true } }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, isSingleValue: true } }"
          ></ng-template>
        </ul>
        <div class="previewbtns">
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-preview' } }"
          ></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
