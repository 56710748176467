import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AnyKindOfDictionary } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MMIMapService {
  searchText: string = '';
  private grantType: string = 'client_credentials';
  private licenceKey: string = '09f39ba42e32ee0983f64f029f987c4a';
  private clientId: string = '33OkryzDZsIsRSX6fySd_nE7ticYYF1MB42CjM-4qeIooM7XRCaaGjrbj87yQXG1gC7mLUcc-NVGMLAoztPgjA==';
  private clientSecret: string =
    'lrFxI-iSEg8vMLsZm6bFq3LYn3VPCSewOCgf67k5eBe5xrFIlybq8x3-JIjjzi_YEoLgCi-twm5Hx5JrpX0ftP7TnmddXnv6';

  private securityURL: string =
    'https://outpost.mappls.com/api/security/oauth/token?grant_type=' +
    this.grantType +
    '&client_id=' +
    this.clientId +
    '&client_secret=' +
    this.clientSecret;
  private autoSuggestURL: string = 'https://atlas.mappls.com/api/places/search/json?query=';
  private nearbyURL: string =
    'https://atlas.mapmyindia.com/api/places/nearby/json?explain&richData&username=balmukand&refLocation=28.467470,77.077518&keywords=FINATM';
  private geocodeURL: string = 'https://atlas.mapmyindia.com/api/places/geocode?address=mapmyindia 237 okhla phase 3';
  private textsearchURL: string =
    'https://atlas.mapmyindia.com/api/places/textsearch/json?query=okhla phase 3&region=ind';
  private reverseGeoCode: string = 'https://apis.mappls.com/advancedmaps/v1/' + this.licenceKey + '/rev_geocode?';
  private routeURL: string = 'https://apis.mappls.com/advancedmaps/v1/' + this.licenceKey + '/route_adv/driving/';

  constructor(private httpClient: HttpClient) {}

  getToken() {
    let apiURL = this.securityURL;
    return this.httpClient.post(apiURL, null);
  }

  autoSuggest(token: string, searchText: string) {
    const _url = this.autoSuggestURL + searchText + '&access_token=' + token;
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(_url)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        });
    });
    return promise;
  }

  nearby(token: string) {
    const _url = this.nearbyURL + '&access_token=' + token;
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(_url)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        });
    });
    return promise;
  }

  geocode(token: string) {
    const _url = this.geocodeURL + '&access_token=' + token;
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(_url)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        });
    });
    return promise;
  }

  geoRoute(source: any, data: any) {
    const _url =
    this.routeURL +
    source.lng +
    ',' +
    source.lat +
    ';' +
    data.lng +
    ',' +
    data.lat +
    '?alternatives=true&rtype=0&geometries=polyline&overview=full&exclude=&region=ind&steps=false&rtype=1';    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(_url)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        });
    });
    return promise;
  }

  reverseGeocode(token: string, position: any) {
    const _url = this.reverseGeoCode + 'lat=' + position.lat + '&lng=' + position.lng;
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(_url)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        });
    });
    return promise;
  }

  textsearch(token: string) {
    const _url = this.textsearchURL + '&access_token=' + token;
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(_url)
        .toPromise()
        .then((res) => {
          // Success
          resolve(res);
        });
    });
    return promise;
  }

  decode(encoded: any) {
    var points = [],
      index = 0,
      len = encoded.length;
    var lat = 0,
      lng = 0;
    while (index < len) {
      var b,
        shift = 0,
        result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      var dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;
      shift = 0;
      result = 0;
      do {
        b = encoded.charAt(index++).charCodeAt(0) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      var dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;
      points.push([lat / 1e5, lng / 1e5]);
    }
    return points;
  }

  jsonp(uri: AnyKindOfDictionary) {
    return new Promise(function (resolve, reject) {
      const id = '_' + Math.round(10000 * Math.random());
      const callbackName = 'jsonp_callback_' + id;
      window[callbackName] = function (data: any) {
        delete window[callbackName];
        const ele = document.getElementById(id);
        ele.parentNode.removeChild(ele);
        resolve(data);
      };
      const src = uri + '&callback=' + callbackName;
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.addEventListener('error', reject);
      (document.getElementsByTagName('head')[0] || document.body || document.documentElement).appendChild(script);
    });
  }
}
