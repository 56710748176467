import { SwiperOptions } from "swiper";

export interface splitShelf {
  sizes: number[];
  direction: 'vertical' | 'horizontal' | undefined;
  splitShelfs?: splitShelf[] | undefined[];
  shelves?: any[];
}
export interface Direction {
  direction: 'vertical' | 'horizontal' | undefined;
}

export interface Shelf {
  sectionName?: any;
  betType?: string;
  contentType?: string;
  templateType?: string;
  betData?: BetData[];
  hideTitle?: boolean;
  index?: number;
  addMore?: boolean;
  properties?: string;
  alignment?: string;
  titleAlignment?: string;
  // properties?: string;
  allowedBets?: string[];
  shelfConfig?: SwiperOptions;
  length?: number;
  betId?: any;
  mapBet?: boolean;
  betMasterId?: number;
  expanded?: boolean;
  backgroundColor?: string;
  containerColor?: string;
  containerHoverColor?: string;
  titleColor?: string;
  containerHeadingColor?: string;
  containerHeadingHoverColor?: string;
  description?: ContentType[];
  collectionsGsiData?: BetData[];
  roleId?: any;
  sectionImage?: string;
  template?: any;
  fontFamily?: string;
  splits?: number;
  splitShelves?: Shelf[];
  splitWidth?: number[];
  fullColor?: boolean;
  colors?: {};
  aggregateTotal?: number;
  hover?: boolean;
  splitShelf?: splitShelf;
  style?:{};

}

export interface PageData {
  id?: string;
  name?: string;
  roleId?: string;
  roleName?: string;
  div?: Shelf;
  isEdit?: boolean;
  expanded?: boolean;
  background?: PageBackGround;
  device: DeviceType;
}

export interface BetData {
  betId: string;
  betName: string;
  betImage?: any;
  betType: string;
  betList?: BetData[];
  betData?: BetData[];
  displayName?: string;
  properties?: string;
  cardIdList?: string[];
  betMasterId?: number;
  nslAttributes?: any[];
  description?: any[];
  shelfType?: string;
  collectionsGsiData?: any[];
  dashboardName?: string;
  supersetId?: any;
  betFileType?: string;
  betFile?: any;
  isInBook?: boolean;
  enableGsiSelectedVersion?: boolean;
}
export interface ContentType {
  type?: any;
  content?: any;
  limit?: number;
}
export interface PageBackGround {
  colour?: string;
  showBackGroundImage?: boolean;
  image?: {
    desktop?: string;
    tab?: string;
    mobile?: string;
    any?: string;
  };
}
export type DeviceType = 'DESKTOP' | 'MOBILE' | 'TABLET';
export const randText: string = `There are a number of reasons you may need a block of text and when you do, a random paragraph can be the perfect solution.
                               you can edit this description`;
export const randName: string = 'template Owner Name';

export const randDetails: string = 'Template Owner Details';

export interface Collections {
  displayName: string;
  collectionItemDetailList: any[];
  name: string;
  checked?: boolean;
}

export interface ShelfColors {
  color: any;
  containerColor: any;
  containerHoverColor: any;
  titleColor: any;
  containerFontDefaultColor: any;
  containerFontHoverColor: any;
  splitShelfColors?: ShelfColors[];
  fullColor?: boolean;
  isInvertPage?: boolean;
  isInvertBackground?: boolean;
  aggregateColor?: any;
  aggregateHoverColor?: any,
  aggregateFontColor?: any,
  aggregateFontHoverColor?: any,
}
export interface Colors {
  backgroundColor?: string;
  containerColor?: string;
  containerHoverColor?: string;
  titleColor?: string;
  containerHeadingColor?: string;
  containerHeadingHoverColor?: string;
}


export interface Gradient {
  angle: any;
  colors: GradientColor[];
}

export interface GradientColor {
  color: string;
  percentage: number;
}

export interface RelativeDragPosition {
  x: number;
  y: number;
}
