declare var require: any;
let transform = require('node-json-transform').transform;

export function dldEntityMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      isMultiValue: 'isMultiValue',
      isReserved: 'isReserved',
      name: 'name',
      nslAttributes: 'nslAttributes',
      dldId: 'dldId',
      confidence: 'confidence',
      displayName: 'displayName',
      source: 'source',
    },

    operate: [
      {
        run: function (val: any[]) {
          return val?.map((x: any) => {
            return {
              ...x,
              // name: x.name,
              // displayName: x.displayName,
              // constraints: x.constraints,
              // attributeType: {
              //   type: x.attributeType?.type,
              //   uiElement: {
              //     name: x.attributeType?.uiElement?.name,
              //     dataType: x.attributeType?.uiElement?.dataType,
              //     uiElement: x.attributeType?.uiElement?.uiElement,
              //     isMulti: x.attributeType?.uiElement?.isMulti,
              //     properties: x.attributeType?.uiElement?.properties,
              //   },
              //   // x.attributeType?.properties
              //   properties: {
              //     source: '',
              //   },
              // },
            };
          });
        },
        on: 'nslAttributes',
      },
    ],
  };

  return transform(data, map);
}
