import { SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Attribute,
  isSearchAttributeVal,
  RuleSetCondition,
} from '@common-services';
import { RuleSet, Condition } from '@common-services';
import { LoaderService } from '@common-services';
import { TranslatorService } from '@common-services';
import { EntityBoardEndpointService } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  CHIP_SELECTION_SINGLE,
  CHIP_SELECTION_RANGE,
  SLIDER_SINGLE,
  SLIDER_RANGE,
  CHECK_BOX_SINGLE,
  CHECK_BOX_RANGE,
  LOCATE,
  DATES,
  DATE_AND_COST,
  TOGGLE_BUTTONS,
  RADIO_BUTTON,
  RATINGS,
  DATE_SINGLE,
  DATE_RANGE,
  advancedFeatures,
  tableAdvancedFeatures,
  CSTM_FILTER,
} from '../constants/customize-filter-templates.constants';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-drop-advanced-features',
  templateUrl: './drop-advanced-features.component.html',
  styleUrls: ['./drop-advanced-features.component.scss'],
})
export class DropAdvancedFeaturesComponent implements OnInit {
  filterDesignConfig: any;
  @Input() set editFilterDesign(value: any) {
    if (value) {
      this.filterDesignConfig = value;
      // this.setAdvancedFeaturesEdit();
    }
  }
  selectedEntityData: any;
  @Input() set _selectedEntityData(value: any) {
    if (value) {
      this.selectedEntityData = value;
      this.setAdvancedFeaturesEdit();
    }
  }
  @Input() selectedRoleId: any;
  @Input() entityData: any;
  childGsiList: any;
  @Input() combinationArray: any[] = [];
  @Input() hiddenRuleSet: RuleSetCondition = {
    ruleSet: [],
    ruleSetCondition: 'AND',
  };
  @Input() urlBindingAttribute: any;
  @Input() urlBindingGsi: any;
  @Input() settings: any;
  @Input() isEntityCard: boolean;
  isEntityTable: boolean;
  formGroup: FormGroup;
  showFacetFilter: boolean = true;

  @Input() set setIsEntityTable(value: any) {
    this.isEntityTable = value;
  }
  @Input() set dropAdvancedFeature(value: any) {
    if (value) {
      this.combinationArrayEmit.emit(this.combinationArray);
      this.selectedEntityDataEmit.emit(this.selectedEntityData);
      this.hiddenRuleSetEmit.emit(this.hiddenRuleSet);
      if (!this.urlBindingAttribute?.name) {
        let index = this.selectedEntityData?.nslAttributes?.find(
          (attribute: any) => attribute.name == this.urlBindingAttribute
        );
        if (index != -1) {
          this.urlBindingAttribute = index;
        }
      }
      this.urlBindingEmit.emit({
        urlBindingAttribute: this.urlBindingAttribute,
        urlBindingGsi: this.urlBindingGsi,
        attributeColor: this.colorModel,
        isConfigName: this.isConfigName
      });
      // Make Sure Advanced Features Emit is Last.
      this.advancedFeaturesEmit.emit(this.advancedFeatures);
    }
  }
  @Input()set _dropMappedAttributes(value: any) {
    if (value  ) {
      this.dropMappedAttributes = value;
      if(this.childGsiList && this.filterDesignConfig?.urlBindingData){
        this.setAdvancedFeaturesEdit();
      }
    }
  }
  @Input() set _childGsiList(value: any) {
    if (value) {
      this.childGsiList = value;
    }
  }
  dropMappedAttributes:any ;
  @Output() combinationArrayEmit: any = new EventEmitter();
  @Output() selectedEntityDataEmit: any = new EventEmitter();
  @Output() hiddenRuleSetEmit: any = new EventEmitter();
  @Output() urlBindingEmit: any = new EventEmitter();
  @Output() advancedFeaturesEmit: any = new EventEmitter();
  @Output() chatBotDataEmit: any = new EventEmitter();
  @Output() sortCheckedFieldsEmit: any = new EventEmitter();
  @Output() searchCheckedFieldsEmit: any = new EventEmitter();
  @Output() filterCheckedFieldsEmit: any = new EventEmitter();
  @Output() facetCheckedFieldsEmit: any = new EventEmitter();
  @Output() tableadvancedFeaturesEmit: any = new EventEmitter();

  ngUnsubscribe = new Subject();
  advancedFilterOpenLabel: string = 'Sort';
  attributeOpen: string;
  attributeSelect: any;
  editBoxCheck: any;
  searchAttributeSelect: any;
  attrDetails: any;
  searchFieldsIndex: number = -1;
  sortCreateFlag: boolean = false;
  sortCreateCombinationFlag: boolean = false;
  sortCreateIndividualFlag: boolean = false;
  nslSelectedCombiAttributes: any;
  filterDefaultValue: any;
  searchFieldsDefaultValue: any;
  @Input() sortDefaultOption: any;
  sortDefaultAttr: any;
  individualSelectedAttr: any[] = [];
  sortDefaultValue: any;
  divisions: number;
  operators: string[] = ['==', '>', '<', '!=', '>=', '<='];
  attributeValue: string = '';

  hiddenConditions: Condition[] = [];

  referenceEntitiesSearchFields: any = [];
  searchTextRefEntity: any = [];
  refEntTotalPages: any = [];
  refEntPageNumber: any = [];
  refEntTotalRecords: any = [];
  @ViewChildren('select') select: any;
  targetRefAttr: any = [];
  constrefEntTotalRecords: any;
  constrefEntTotalPages: any;
  constreferenceEntitiesSearchFields: any;
  showUserData: boolean;
  userData: any;
  labels: any;

  chatbotData: any = {
    isSelected: false,
    entity_name: '',
    entity_id: '',
    language: '',
    intents: new Array(),
  };

  languagesOptions: any = [];

  @Output() apiVersionDataEmit: any = new EventEmitter();
  apiVersion: number = 1;
  customChipSelectionSingle = CHIP_SELECTION_SINGLE;
  customChipSelectionRange = CHIP_SELECTION_RANGE;
  customizeFilterTemplateSliderSingle = SLIDER_SINGLE;
  customizeFilterTemplateSliderRange = SLIDER_RANGE;
  customCheckBoxSingle = CHECK_BOX_SINGLE;
  customCheckBoxRange = CHECK_BOX_RANGE;
  customizeLocation = LOCATE;
  customizeDate = DATES;
  customizeDateAndCost = DATE_AND_COST;
  customizeToggleButtons = TOGGLE_BUTTONS;
  customizeRadioButton = RADIO_BUTTON;
  customizeRatings = RATINGS;
  customizeDateSingle = DATE_SINGLE;
  customizeDateRange = DATE_RANGE;
  customizefilter = CSTM_FILTER;
  // -----temporary variables---
  rangeValues: number[] = [20, 80];
  value: number;
  checked: boolean = false;
  ingredient: string = '';
  //---temporary variables end ---

  advancedFeatures: any;
  editSearchFieldsMap: any = new Map();
  editSearchFieldsAttrMap: any = new Map();
  excludeUiControlList = [
    'file',
    'image',
    'audio',
    'video',
    'qrScanner',
    'qrDecoder',
    'scheduler',
    'signature',
    'scormProgress',
    'webex',
    'location',
    'videoRecording',
    'audioRecording',
    'capturingImage',
    'list',
    'clickable',
    'geolocation',
    'streamingVideo',
  ];
  colorPickerToggler: boolean = false;
  colorModel: string = '';
  isConfigName: boolean = false;
  constructor(
    protected entityBoardService: EntityBoardEndpointService,
    public dialog: MatDialog,
    protected loader: LoaderService,
    protected translator: TranslatorService,
    private formBuilder: FormBuilder
  ) {
    this.detectLanguageChange();
    this.setAdvanceFeatures();
  }

  ngOnInit(): void {
    if (this.selectedEntityData && this.selectedEntityData?.nslAttributes) {
      this.formGroup = this.formBuilder.group({
        filterFields: this.formBuilder.array(
          Object.keys(this.selectedEntityData?.nslAttributes || {}).map(
            (key) => false
          )
        ),
        searchFields: this.formBuilder.array(
          Object.keys(this.selectedEntityData?.nslAttributes || {}).map(
            (key) => false
          )
        ),
        sortFields: this.formBuilder.array(
          Object.keys(this.selectedEntityData?.nslAttributes || {}).map(
            (key) => false
          )
        ),
        facetFields: this.formBuilder.array(
          Object.keys(this.selectedEntityData?.nslAttributes || {}).map((key) => false)
        ),
      });
    }
  this.showFacetFilter = this.entityData.reservedCUType == 'NSL_Template_FacetedSearch' || this.entityData?.data?.cu?.reservedCUType == 'NSL_Template_FacetedSearch' || !this.isEntityTable;
    if (this.isEntityTable){ this.setAdvanceFeatures();
      this.advancedFilterOpenLabel = '';
    }

    this.emit();
  }
  tableMapper(event: string, fields?: any) {
    let sortFields: any[] = [];
    (this.formGroup.get(event) as FormArray).value.forEach(
      (flag: any, index: number) => {
        if (flag) {
          sortFields.push(this.selectedEntityData?.nslAttributes[index]?.name);
        }
      }
    );
    return sortFields;
  }
  getSortDetails() {
    this.sortCheckedFieldsEmit.emit(this.tableMapper('sortFields'));
  }
  getSearchDetails() {
    this.searchCheckedFieldsEmit.emit(this.tableMapper('searchFields'));
  }
  getFilterDetails() {
    this.filterCheckedFieldsEmit.emit(this.tableMapper('filterFields'));
  }
  getFacetFieldDetails() {
    this.facetCheckedFieldsEmit.emit(this.tableMapper('facetFields'));
  }

  emit() {
    setTimeout(() => {
      this.tableadvancedFeaturesEmit.emit(this.advancedFeatures);
    }, 10);
  }
  openDialog(templateRef: TemplateRef<any>) {
    /* istanbul ignore next */
    this.dialog.closeAll();
    /*istanbul ignore next*/
    this.dialog.open(templateRef, {
      width: '1100px',
      panelClass: 'ge-choosecard',
    });
  }

  openRefDateDialog(
    refEnt: TemplateRef<any>,
    dateDialog: TemplateRef<any>,
    type: any
  ) {
    const dialogArg =
      type === 'date' || type === 'datetime' ? dateDialog : refEnt;
    this.openRefEntDialog(dialogArg);
  }
  openRefEntDialog(templateRef: TemplateRef<any>, event?: any) {
    /* istanbul ignore else */
    if (event) {
      event.stopPropagation();
    }
    /* istanbul ignore next */
    this.dialog.closeAll();
    /*istanbul ignore next*/
    this.dialog.open(templateRef, {
      width: '746px',
      panelClass: 'popup-wrapper',
    });
    if (this.filterDesignConfig) {
      this.setSearchFieldsData();
    }
  }
  setSearchFieldsData() {
    if (this.editSearchFieldsMap.has(this.attrDetails?.name)) {
      let refVale = this.editSearchFieldsMap.get(this.attrDetails?.name);
      if (refVale) {
        this.attrDetails['searchFields'].selectedRefValue = {
          name: refVale.name,
          displayName: refVale.displayName,
          id: refVale.id,
        };
        this.getAllattributesById({ value: refVale }, this.searchFieldsIndex);
      }
    }
  }

  openSortDialog(templateRef: TemplateRef<any>) {
    /* istanbul ignore next */
    this.dialog.closeAll();
    /*istanbul ignore next*/
    this.dialog.open(templateRef, {
      width: '746px',
      panelClass: 'popup-wrapper',
    });
    this.getSortDefaultValues();
  }

  getSortDefaultValues() {
    this.individualSelectedAttr = [];
    this.selectedEntityData.nslAttributes.forEach((attr: any) => {
      /*istanbul ignore else*/
      if (attr.sort.checked) {
        this.individualSelectedAttr.push(attr.displayName);
      }
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.labels = res;
      });
  }

  /**
   * The function `selectAdvancedFilter` sets the values of `sort`, `searchFields`, and `filter` based
   * on the value of the `label` parameter.
   * @param {string} label - The `label` parameter is a string that represents the type of advanced
   * filter to select. It can have one of the following values: "Sort", "SearchFields", or "Filter".
   * This function basically opens the second Block in the Advanced Filter
   */
  selectAdvancedFilter(label: string) {
    if (this.advancedFilterOpenLabel === label) {
      this.advancedFilterOpenLabel = '';
    } else {
      this.checked = false;
      this.advancedFilterOpenLabel = label;
      this.attributeOpen = '';
      if (label === 'Search') {
        this.attributeOpen = label;
        this.attributeSelect = this.searchAttributeSelect;
        this.openParticularPanel('search');
      } else if (label === 'SearchFields' && !this.isEntityTable) {
        this.getAllreferenceEntities(false);
      } else if (label === 'Chatbot') {
        this.chatbotData.isSelected = true;
        this.chatbotSelection();
      } else if (label == 'HiddenFilters') {
        this.attributeOpen = 'HiddenFilters';
      } else if (label == 'UrlBinding') {
        this.attributeOpen = label;
      } else if (label == 'ChooseColumns') {
        this.attributeOpen = label;
      }
      this.editBoxCheck = undefined;
    }
  }

  /**
   * The function `openAttributePanel` takes in an attribute, a label, and an index, and based on the
   * label, it performs different actions such as opening a filter panel, setting a search checkbox to
   * true, opening a sort panel, opening a search fields panel, or opening a location filter panel.
   * @param {any} attr - The `attr` parameter is of type `any`, which means it can be any type of
   * value.
   * @param {string} label - The label parameter is a string that represents the type of attribute
   * panel to be opened. It can have values such as "Filter", "Search", "Sort", "SearchFields", or
   * "LocationFilter".
   * @param {any} index - The index parameter is used to identify the position of the attribute in an
   * array or list. It is used to determine which attribute is being opened in the attribute panel.
   * This Function Opens the third Panel where we select the Templates
   */
  openAttributePanel(attr: any, label: string, index: any) {
    this.attrDetails = attr;
    this.editBoxCheck = index;
    this.attributeOpen = label;
    // this.sorteditBoxCheck = index;
    if (label === 'Filter') {
      this.openParticularPanel('filter');
    } else if (label === 'Search') {
      this.attributeOpen = label;
      this.attrDetails.search.checked = true;
    } else if (label === 'Sort') {
      this.openSortPanel(label);
    } else if (label === 'SearchFields') {
      this.attrDetails.searchFields.checked = true;
      this.searchFieldsIndex = index;
      this.attrDetails.searchFields.selectNestedAttributeNoSuffixValue = 'NA';
      this.openParticularPanel('searchFields');
      this.searchFieldsOpen({}, this.attrDetails.searchFields, index);
    } else if (label === 'LocationFilter') {
      this.openLocationFilterPanel('locationFilter');
    }
  }
  /**
   * The function "openSortPanel" checks flags and opens either the sort details panel or the sort
   * combination panel based on the flags.
   * @param {string} label - The "label" parameter is a string that represents the label of the sort
   * panel.
   * This Function Opens the Sort Panel.
   */
  openSortPanel(label: string) {
    if (this.sortCreateIndividualFlag && !this.sortCreateCombinationFlag) {
      this.attrDetails.sort.checked = true;
      this.attributeOpen = '';
    } else if (
      !this.sortCreateIndividualFlag &&
      this.sortCreateCombinationFlag
    ) {
      this.openSortCombinationPanel(label);
    }
  }
  /**
   * The function "openSortCombinationPanel" sets the attributeSelect variable based on the
   * attrDetails.id and attrDetails.checked values, and also updates the attrDetails.checked value if
   * necessary.
   * @param {string} label - The `label` parameter is a string that represents the label for the sort
   * combination panel.
   * This Function Opens Sort Combination Panel
   */
  openSortCombinationPanel(label: string) {
    const dummyLabel: string = label.toLowerCase();
    if (this.attrDetails.id && this.attrDetails.checked) {
      this.attributeSelect = this.attrDetails.id;
    } else {
      this.attributeSelect = undefined;
      this.attrDetails.checked = false;
    }
  }
  /**
   * The function "openLocationFilterPanel" sets the attributeSelect variable based on the value of the
   * attrDetails.checked property.
   * @param {string} label - The label parameter is a string that represents the label for the location
   * filter panel.
   * This Function opens the Location Filoter panel
   */
  openLocationFilterPanel(label: string) {
    const dummyLabel: string = label.toLowerCase();
    if (this.attrDetails.checked) {
      this.attributeSelect = this.attrDetails.locationFilter.attributeRadius;
    } else {
      this.attributeSelect = undefined;
      this.attrDetails.checked = false;
    }
  }

  /**
   * The function `openParticularPanel` takes a label as input and updates the `attributeSelect`
   * variable based on the value of the label.
   * @param {string} label - The "label" parameter is a string that represents the label of a
   * particular panel.
   * This function also opens third Panel
   */
  openParticularPanel(label: string) {
    const dummyLabel: string = label;
    if (
      this.attrDetails?.[dummyLabel]?.id &&
      this.attrDetails?.[dummyLabel]?.checked
    ) {
      this.attributeSelect =
        label === 'search'
          ? this.searchAttributeSelect
          : this.attrDetails[dummyLabel].id;
    } else {
      this.attributeSelect =
        label === 'search' ? this.searchAttributeSelect : undefined;
      if (label != 'search') {
        this.attrDetails[dummyLabel].checked = false;
      }
    }
  }

  /**
   * The function sets flags for sorting individual combinations and individuals, and updates the
   * attribute open value accordingly.
   * @param {boolean} attr1 - A boolean value representing the flag for sorting and creating
   * combinations.
   * @param {boolean} attr2 - A boolean value representing the attribute 2.
   */
  sortIndividualCombination(attr1: boolean, attr2: boolean) {
    this.sortCreateCombinationFlag = attr1;
    this.sortCreateIndividualFlag = attr2;
    if (this.sortCreateIndividualFlag && !this.sortCreateCombinationFlag) {
      this.attributeOpen = '';
    }
  }
  /**
   * The function toggles the sortCreateFlag and sets other flags and attributes to their default
   * values.
   */
  sortCreate() {
    this.sortCreateFlag = !this.sortCreateFlag;
    this.sortCreateCombinationFlag = false;
    this.sortCreateIndividualFlag = false;
    this.attributeOpen = '';
  }

  /**
   * The function saves filter attribute configurations if an attribute select is present.
   * @returns If the `attributeSelect` is falsy (e.g. undefined, null, false), then nothing is being
   * returned. If `attributeSelect` is truthy, then the result of `this.saveConfigurations('filter')`
   * is being returned.
   * This function saves the Filter Configurations.
   */
  saveFilterAttrConfiguration() {
    if (!this.attributeSelect) {
      return;
    }
    this.saveConfigurations('filter');
  }

  /**
   * The function saves the search attribute configuration by checking if any attributes have been
   * selected for search and assigning an ID to them.
   * ZThis function saves search configuration.
   */
  saveSearchAttrConfiguration() {
    let boolean: boolean = false;
    this.selectedEntityData.nslAttributes.forEach((attr: any) => {
      if (attr.search.checked) {
        boolean = true;
        attr.search['id'] = this.attributeSelect;
      }
    });
    if (!boolean) {
      this.selectedEntityData.nslAttributes.forEach((attr: any) => {
        attr.search.checked = true;
        boolean = true;
        attr.search['id'] = this.attributeSelect;
      });
    }
    this.searchAttributeSelect = this.attributeSelect;
    this.attributeSelect = undefined;
    // this.attributeOpen = '';
    //this.advancedFilterOpenLabel = '';
  }

  /**
   * The function saves the sort attribute configuration if the attribute select element exists.
   * @returns If the `attributeSelect` is falsy (e.g. undefined, null, false), then nothing is being
   * returned. Otherwise, the `saveConfigurations('sort')` function is being called.
   * This Function saves Sort
   */
  saveSortAttrConfiguration() {
    if (!this.attributeSelect) {
      return;
    }
    this.saveConfigurations('sort');
  }

  /**
   * The function "saveSearchFieldsConfiguration" saves the configurations for search fields.
   */
  saveSearchFieldsConfiguration() {
    this.saveConfigurations('searchFields');
  }
  /**
   * The function saves the location filter attribute configuration by setting the checked property to
   * true, assigning the attribute radius value, and resetting some variables.
   * @returns nothing (undefined).
   * This function saves Location Filter.
   */
  saveLocationFilterAttrConfiguration() {
    this.attrDetails.locationFilter.checked = true;
    this.editBoxCheck = undefined;
    this.attributeOpen = '';
  }

  /**
   * The function saves configurations by updating the checked status, id, and other variables.
   * @param {string} label - The label parameter is a string that represents the label of the
   * configuration.
   */
  saveConfigurations(label: string) {
    this.attrDetails[label].checked = true;
    this.attrDetails[label]['id'] = this.attributeSelect;
    this.attributeSelect = undefined;
    this.editBoxCheck = undefined;
    this.attributeOpen = '';
  }
  /**
   * The function "saveSortCombinationAttrConfiguration" updates the attribute details and clears the
   * attribute select, edit box check, and attribute open values.
   * @returns nothing (undefined).
   */
  saveSortCombinationAttrConfiguration() {
    if (!this.attributeSelect) {
      return;
    }
    this.attrDetails.checked = true;
    this.attrDetails.id = this.attributeSelect;
    this.attributeSelect = undefined;
    this.editBoxCheck = undefined;
    this.attributeOpen = '';
  }
  /**
   * The function saves the selected attribute for search fields configuration.
   * @returns nothing (undefined).
   */
  saveSearchFieldsAttrConfiguration() {
    if (!this.attributeSelect) {
      return;
    }
    this.attrDetails.searchFields.checked = true;
    this.attrDetails.searchFields['id'] = this.attributeSelect;
    this.attributeSelect = undefined;
    this.editBoxCheck = undefined;
    this.attributeOpen = '';
  }

  /**
   * The function deletes a filter configuration and stops the event from propagating.
   * @param {any} attr - The `attr` parameter is the attribute or property that is associated with the
   * filter configuration that needs to be deleted.
   * @param {any} event - The `event` parameter is an object that represents the event that triggered
   * the function. It is typically used to access information about the event, such as the target
   * element or any additional data associated with the event. In this case, the `event` parameter is
   * used to call the `stopPropagation()`
   */
  deleteFilterConfiguration(attr: any, event: any) {
    this.deleteConfiguration(attr, 'filter');
    event.stopPropagation();
  }
  /**
   * The function `deleteLocationFilterConfiguration` is used to reset the location filter
   * configuration by unchecking the checkbox, clearing the attribute radius, and resetting some
   * variables.
   * @param {any} attr - The `attr` parameter is an object that contains properties related to the
   * location filter configuration. It likely includes properties such as `locationFilter.checked`,
   * `locationFilter.attributeRadius`, `editBoxCheck`, and `attributeOpen`.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event and any additional
   * data associated with it.
   */
  deleteLocationFilterConfiguration(attr: any, event: any) {
    // this.deleteConfiguration(attr, 'locationFilter');
    attr.locationFilter.checked = false;
    attr.locationFilter.attributeRadius = undefined;
    this.editBoxCheck = undefined;
    this.attributeOpen = '';
    event.stopPropagation();
  }

  /**
   * The function deletes a save configuration and stops the event propagation.
   * @param {any} attr - The `attr` parameter is a variable that represents the attribute or
   * configuration that needs to be deleted.
   * @param {any} event - The `event` parameter is an object that represents the event that triggered
   * the function. It contains information about the event, such as the type of event, the target
   * element, and any additional data associated with the event.
   */
  deleteSaveConfiguration(attr: any, event: any) {
    this.deleteConfiguration(attr, 'search');
    event.stopPropagation();
  }
  /**
   * The function deletes a sort configuration and stops the event from propagating.
   * @param {any} attr - The `attr` parameter is the attribute or property that you want to delete from
   * the configuration. It specifies which sort configuration you want to delete.
   * @param {any} event - The `event` parameter is an object that represents the event that triggered
   * the function. It contains information about the event, such as the type of event, the target
   * element, and any additional data associated with the event.
   */
  deleteSortConfiguration(attr: any, event: any) {
    this.deleteConfiguration(attr, 'sort');
    event.stopPropagation();
  }
  /**
   * The function deletes a sort combination configuration and resets some variables.
   * @param {any} attr - The `attr` parameter is an object that represents an attribute. It likely has
   * properties such as `checked` and `id`.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event, the target element,
   * and any additional data associated with the event.
   */
  deleteSortCombinationConfiguration(attr: any, event: any) {
    attr.checked = false;
    attr.id = undefined;
    this.editBoxCheck = undefined;
    this.attributeOpen = '';
    event.stopPropagation();
  }

  /**
   * The function "addNewCombination" adds a new combination to an array, with a default name and
   * attributes, and stops the event from propagating.
   * @param {any} event - The "event" parameter is an object that represents the event that triggered
   * the function. It could be a mouse click event, a keyboard event, or any other type of event.
   */
  addNewCombination(event: any) {
    this.combinationArray.push({
      id: undefined,
      name: 'Combination Name',
      nslAttributes: JSON.parse(
        JSON.stringify(this.selectedEntityData.nslAttributes)
      ),
      checked: false,
    });
    this.attributeOpen = '';
    event.stopPropagation();
  }

  /**
   * The function "deleteCombination" removes a specific combination from an array.
   * @param {any} combi - The `combi` parameter is the combination that you want to delete from the
   * `combinationArray`.
   * @param {number} i - The parameter `i` is of type `number` and represents the index of the
   * combination to be deleted from the `combinationArray`.
   */
  deleteCombination(combi: any, i: number) {
    this.combinationArray = this.combinationArray.filter(
      (attr) => attr != combi
    );
  }

  /**
   * The function updates the "checked" property of an attribute based on the value of a checkbox
   * event.
   * @param {any} combi - The `combi` parameter is of type `any`, which means it can be any data type.
   * It is not specified what kind of data `combi` represents.
   * @param {any} attr - The `attr` parameter is an object that contains properties related to sorting.
   * @param {any}  - The  parameter is an object that represents the event that triggered
   * the function. It typically contains information about the event, such as the target element and
   * any additional data associated with the event. In this case, it is used to check if the event has
   * a checked property and if its first element is
   */
  sortCombiCheckedFunction(combi: any, attr: any, $event: any) {
    if ($event?.checked?.[0]) {
      attr.sort.checked = true;
    } else {
      attr.sort.checked = false;
    }
  }

  /**
   * The function deletes a search field configuration and stops the event from propagating.
   * @param {any} attr - The `attr` parameter is the attribute or field that you want to delete from
   * the search fields configuration.
   * @param {any} event - The `event` parameter is an object that represents the event that triggered
   * the function. It contains information about the event, such as the type of event, the target
   * element, and any additional data associated with the event.
   */
  deleteSearchFieldsConfiguration(attr: any, event: any) {
    this.deleteConfiguration(attr, 'searchFields');
    event.stopPropagation();
  }

  /**
   * The function `deleteConfiguration` updates varioureferenceEntitiesSearchFieldss properties and variables based on the provided
   * `attr` and `label` values.
   * @param {any} attr - The `attr` parameter is an object that contains various attributes and their
   * values.
   * @param {any} label - The label parameter is a string that represents the label of the configuration
   * attribute.
   */
  deleteConfiguration(attr: any, label: any) {
    attr[label].checked = false;
    attr[label]['id'] = undefined;
    attr[label].selectedRefValue = undefined;
    attr[label].selectedRefAttrValue = undefined;
    this.editBoxCheck = undefined;
    this.attributeOpen = label === 'search' ? 'Search' : '';
  }

  /**
   * The function "deleteSearchFieldsEntity" clears the selected reference value and attribute value in
   * the "searchFields" property of the "attrDetails" object.
   */
  deleteSearchFieldsEntity() {
    this.attrDetails['searchFields'].selectedRefValue = undefined;
    this.attrDetails['searchFields'].selectedRefAttrValue = undefined;
    this.attrDetails['searchFields'].selectNestedAttribute = undefined;
    this.attrDetails['searchFields'].selectNestedAttributeFunction = undefined;
    this.attrDetails['searchFields'].selectNestedAttributeNoSuffixValue = 'NA';
  }
  /**
   * The function saves the default value of a filter, clears the input field, and closes a dialog.
   */
  saveFilterDefaultValue() {
    this.attrDetails.filter.defaultValue = this.filterDefaultValue;
    this.filterDefaultValue = undefined;
    this.closeDialog();
  }
  /**
   * The function saves the default value of search fields, clears the value, and closes a dialog.
   */
  saveSearchFieldsDefaultValue() {
    this.attrDetails.searchFields.defaultValue = this.searchFieldsDefaultValue;
    this.searchFieldsDefaultValue = undefined;
    this.closeDialog();
  }

  /**
   * The function "saveSearchFields" clears selected reference values and closes all dialogs if a
   * boolean parameter is false.
   * @param {boolean} boolean - A boolean value that determines whether to save the search fields or
   * not.
   */
  saveSearchFields(boolean: boolean) {
    if (!boolean) {
      this.attrDetails['searchFields'].selectedRefValue = undefined;
      this.attrDetails['searchFields'].selectedRefAttrValue = undefined;
      this.attrDetails['searchFields'].selectNestedAttribute = undefined;
      this.attrDetails[
        'searchFields'
      ].selectNestedAttributeFunction = undefined;
      this.attrDetails['searchFields'].selectNestedAttributeNoSuffixValue =
        'NA';
    }
    this.dialog.closeAll();
  }
  addRule(i: number) {
    if (!this.hiddenRuleSet?.ruleSet?.[i]) {
      this.hiddenRuleSet?.ruleSet.push({
        hiddenConditions: [],
        inConditions: 'AND',
      });
    }
    this.hiddenRuleSet?.ruleSet[i].hiddenConditions.push({
      conditionName: '',
      attriubteName: {} as Attribute,
      value: '',
    });
  }

  addRuleSet() {
    this.hiddenRuleSet?.ruleSet.push({
      hiddenConditions: [],
      inConditions: 'AND',
    });
    const len = this.hiddenRuleSet?.ruleSet.length - 1;
    this.hiddenRuleSet?.ruleSet[len].hiddenConditions.push({
      conditionName: '',
      attriubteName: {} as Attribute,
      value: '',
    });
  }

  deleteRuleSet(j: number) {
    this.hiddenRuleSet?.ruleSet.splice(j, 1);
  }
  CheckAnd(i: number) {
    this.hiddenRuleSet.ruleSet[i].inConditions = 'AND';
  }
  /**
   * The function updates the condition at a specific index in the hiddenRuleSet array to 'OR'.
   * @param {number} i - The parameter "i" in the function "CheckOr" is a number.
   */
  CheckOr(i: number) {
    this.hiddenRuleSet.ruleSet[i].inConditions = 'OR';
  }

  /**
   * The function deletes a condition from a hidden rule set at a specific index.
   * @param {number} index - The index parameter is a number that represents the position of the
   * condition to be deleted in the hiddenConditions array.
   * *This will delete the Condition from the Array.
   */
  deleteCondition(index: number, ruleIndex: number) {
    this.hiddenRuleSet?.ruleSet[ruleIndex].hiddenConditions.splice(index, 1);
  }
  saveSortDefaultValue() {
    this.sortDefaultValue = {
      selectedOption: this.sortDefaultOption,
      selectedAttribute: this.sortDefaultAttr,
    };
  }

  CheckOrRule() {
    this.hiddenRuleSet.ruleSetCondition = 'OR';
  }

  CheckAndRule() {
    this.hiddenRuleSet.ruleSetCondition = 'AND';
  }

  /**
   * The function opens a dialog box with a specified template and closes any existing dialog boxes.
   * @param templateRef - The templateRef parameter is of type TemplateRef<any> and represents a
   * reference to a template that will be used to create a view. It is typically used in conjunction
   * with the Angular Material Dialog component to display a dialog box with the specified template.
   * @param {any} [event] - The `event` parameter is an optional parameter that represents the event
   * that triggered the function. It is used to stop the propagation of the event, preventing it from
   * bubbling up the DOM tree.
   */
  openNumberOfDivisions(templateRef: TemplateRef<any>, event?: any) {
    /* istanbul ignore else */
    if (event) {
      event?.stopPropagation();
    }
    /* istanbul ignore next */
    this.dialog.closeAll();
    /*istanbul ignore next*/
    this.dialog.open(templateRef, {
      width: '746px',
      panelClass: 'popup-wrapper',
    });
  }

  /**
   * The function saves the number divisions that the range type attribute Make, closes any open dialog boxes, and then saves the filter
   * attribute configuration.
   */
  saveFilterDivisions() {
    this.attrDetails.filter.divisions = this.divisions;
    this.divisions = undefined;
    this.dialog.closeAll();
    this.saveFilterAttrConfiguration();
  }

  currentUser(event: any) {
    this.showUserData = true;
    this.userData = JSON.parse(localStorage.getItem('userInfo'));
  }

  /**
   * The function sets a value to a condition and hides user data.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It could be a mouse click event, a keyboard event, or any other type of event.
   * @param {any} value - The `value` parameter is an object that contains a property called `value`.
   * @param {string} userValue - The `userValue` parameter is a string that represents the value of the
   * user.
   */
  setUserToCondition(event: any, value: any, userValue: string) {
    value.value = '@' + userValue;
    this.showUserData = false;
  }

  searchFieldsOpen(event: any, attr: any, index: any) {
    attr.refEntityOpen = true;
    this.refEntPageNumber[index] = 1;
    this.refEntTotalRecords[index] = this.constrefEntTotalRecords;
    this.refEntTotalPages[index] = this.constrefEntTotalPages;
    this.referenceEntitiesSearchFields[
      index
    ] = this.constreferenceEntitiesSearchFields;
    this.searchTextRefEntity[index] = '';
  }

  getAllreferenceEntities(searchFieldsBool: any) {
    if (searchFieldsBool == false) {
      this.refrenceEntitiesApiCall('', 1, 10, -1);
    }
  }
  /*istanbul ignore next*/
  onSearchRefEnt(evt: any, index: any, searchAttr: any) {
    let counter: number = 0;
    let ansCounter: number = 0;
    this.select.toArray()[ansCounter]._handleKeydown = (
      event: KeyboardEvent
    ) => {
      if (event.keyCode == SPACE) return;
      if (!this.select.toArray()[ansCounter].disabled) {
        this.select.toArray()[ansCounter].panelOpen
          ? this.select.toArray()[ansCounter]._handleOpenKeydown(event)
          : this.select.toArray()[ansCounter]._handleClosedKeydown(event);
      }
    };
    this.refEntPageNumber[index] = 1;
    this.searchTextRefEntity[index] = evt;
    this.refrenceEntitiesApiCall(evt, this.refEntPageNumber[index], 10, index);
  }
  refrenceEntitiesApiCall(
    searchCriteria: string,
    pageNum: number,
    pageSize: number,
    index: any,
    isScroll?: boolean,
  ) {
    if (index === -1) {
      this.loader.show();
    }
    this.entityBoardService
      .getAllreferenceEntities(
        searchCriteria,
        pageNum,
        pageSize,
        this.selectedRoleId
      )
      .subscribe((res: any) => {
        if (index === -1) {
          this.constreferenceEntitiesSearchFields = res.result.data;
          this.constrefEntTotalPages = res.result.totalPages;
          this.constrefEntTotalRecords = res.result.totalResults;
          this.loader.hide();
        } else {
          if(isScroll) {
            this.referenceEntitiesSearchFields[index] = [
              ...this.referenceEntitiesSearchFields[index],
              ...res.result.data,
            ];
          } else{
            this.referenceEntitiesSearchFields[index] = res.result.data
          }
         
          this.refEntTotalPages[index] = res.result.totalPages;
          this.refEntTotalRecords[index] = res.result.totalResults;
        }
      });
  }
  onRefEntityPageChange(event: any, index: number) {
    this.refEntPageNumber[index] = event.pageIndex + 1;
    this.refrenceEntitiesApiCall(
      this.searchTextRefEntity[index],
      this.refEntPageNumber[index],
      10,
      index
    );
  }
  getAllattributesById(id: any, index: number) {
    this.entityBoardService
      .getAllattributesById(id.value.id)
      .subscribe((res: any) => {
        this.targetRefAttr[index] = res.result.nslAttributes;
        if (this.editSearchFieldsAttrMap.has(this.attrDetails?.name)) {
          let attrvalue = this.editSearchFieldsAttrMap.get(
            this.attrDetails?.name
          );
          let findIndex = this.targetRefAttr[this.searchFieldsIndex].findIndex(
            (attr: any) => attr.name == attrvalue.name
          );
          if (findIndex != -1) {
            this.attrDetails[
              'searchFields'
            ].selectedRefAttrValue = this.targetRefAttr[this.searchFieldsIndex][
              findIndex
            ];
          }
        }
        if (this.targetRefAttr[index]) {
          this.addSearch(this.targetRefAttr[index]);
        }
      });
  }
  addSearch(attr: any) {
    attr.forEach((nslAttr: any) => {
      nslAttr['isSearched'] = true;
    });
  }
  closeDialog() {
    this.dialog.closeAll();
  }

  chatbotSelection() {
    /* istanbul ignore next */
    if (this.chatbotData?.isSelected) {
      this.chatbotData.entity_name = this.entityData.name;
      this.chatbotData.entity_id = this.entityData.id;
    } else {
      this.chatbotData = {
        isSelected: false,
        entity_name: '',
        entity_id: '',
        language: '',
        intents: new Array(),
      };
    }
    this.getLanguages();
  }

  getLanguages() {
    /* istanbul ignore next */
    if (this.languagesOptions?.length === 0) {
      /* istanbul ignore next */
      this.entityBoardService
        .getLanguages(true)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          /* istanbul ignore next */
          if (res && res?.language) {
            this.languagesOptions = [];
            /* istanbul ignore next */
            Object.entries(res?.language).forEach((data: any) => {
              /* istanbul ignore next */
              this.languagesOptions.push({ name: data?.[0], value: data?.[1] });
            });
          }
        });
    }
  }

  addIntent() {
    /* istanbul ignore next */
    this.chatbotData?.intents?.push({
      intent: '',
      filters: new Array(),
      filter_range_fixed: new Array(),
      attributes: new Array(),
    });

    /* istanbul ignore next */
    this.selectedEntityData?.nslAttributes?.forEach((attr: any) => {
      /* istanbul ignore next */
      let attrData = {
        attributeId: attr?.id,
        attributeName: attr?.name,
        checked: false,
        range: 'MIN',
      };
      /* istanbul ignore next */
      this.chatbotData?.intents?.[
        this.chatbotData?.intents?.length - 1
      ]?.attributes?.push(attrData);
    });
    this.chatBotDataEmit.emit(this.chatbotData);
  }

  removeIntent(intentIndex: number) {
    /* istanbul ignore next */
    if (this.chatbotData?.intents?.[intentIndex]) {
      this.chatbotData.intents.splice(intentIndex, 1);
    }
  }

  /**
   * The alignmentCheck function stops the propagation of an event and assigns a style to a variable.
   * @param {any} event - The event parameter is of type "Mouse Click Event", which means it can be any type of
   * object. It is used to capture the event that triggered the function, such as a button click or a
   * key press.
   * @param {string} styleToGive - The `styleToGive` parameter is a string that represents the style
   * that will be given to an element.
   * @param {string} styleToEffect - The `styleToEffect` parameter is a string that represents the
   * style that you want to apply to an element.
   * *Here we will assign one Particular Alignment to the element
   */
  alignmentCheck(event: any, styleToGive: any, styleToEffect: string) {
    if (event) {
      event?.stopPropagation();
    }
    styleToGive.value = styleToEffect;
  }

  /**
   * The function `selectTab` is used to select a specific tab by updating the `checked` property of
   * the corresponding tab value in an array.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It could be a mouse click event, keyboard event, or any other type of event.
   * @param {any} tabValues - An array of tab values. Each tab value is an object with a "checked"
   * property that indicates whether the tab is currently selected or not.
   * @param {number} index - The index parameter is a number that represents the index of the tab that
   * needs to be selected.
   * *Here We will select one Tab and Other Tabs will be made false.
   */
  selectTab(event: any, tabValues: any, index: number) {
    if (event) {
      event.stopPropagation();
    }
    tabValues?.forEach((value: any, i: any) => {
      value.checked = i == index;
    });
  }

  /**
   * The function checks the position of a feature and updates the corresponding checkboxes
   * accordingly.
   * @param {any} feature - The `feature` parameter is of type `any`, which means it can be any type of
   * object. It is assumed to have properties `side`, `top`, and `header`, which are assumed to be
   * objects with a `checked` property.
   * @param {string} position - The `position` parameter is a string that represents the desired
   * position of a feature. It can have one of the following values: 'side', 'top', or 'header'.
   */
  checkPosition(feature: any, position: string) {
    feature.side.checked = position === 'side' ? true : false;
    feature.top.checked = position === 'top' ? true : false;
    feature.header.checked = position === 'header' ? true : false;
  }

  /*
    * This Variable describes the configurations of all the Advanced Features.
    ! If any Variables are added here, Add them in the Configurations Type and And Attach in the API.
    ? In this variable checkboxes, inputs, toggles etc have a common ng-template so, avoid duplications of the code
  */
  private setAdvanceFeatures() {
    if (this.isEntityTable == true) {
      this.advancedFeatures = JSON.parse(JSON.stringify(tableAdvancedFeatures));
      if (this.settings) {
        this.filteringForEdit(this.settings);
      }
    } else this.advancedFeatures = JSON.parse(JSON.stringify(advancedFeatures));
  }
  filteringForEdit(settings: any) {
    let settingsValue = settings?.advancedFeatures;
    if (settingsValue) {
      this.advancedFeatures.sort.checked = settingsValue?.allowColumnsort;
      this.advancedFeatures.search.checked = settingsValue?.allowColumnSearch;
      this.advancedFeatures.filter.checked = settingsValue?.allowColumnFilter;
      this.advancedFeatures.export.checked = settingsValue?.allowDownload;
      this.advancedFeatures.chooseColumns.checked = settingsValue?.toggleColumn;
      this.advancedFeatures.import.checked = settingsValue?.allowUpload;
      this.advancedFeatures.searchFields.checked =
        settingsValue?.allowSearchFields;
      this.advancedFeatures.filtersConfig = settingsValue?.filtersConfig
        ? settingsValue?.filtersConfig
        : this.advancedFeatures.filtersConfig;
        this.advancedFeatures.searchFields.checkBoxValues = settingsValue?.facet?.configs;
      if (settingsValue?.searchFields === 'top')
        this.advancedFeatures.searchFields.styles[0].value = 'left';
      else if (settingsValue?.searchFields === 'side')
        this.advancedFeatures.searchFields.styles[0].value = 'right';

      this.formGroup
        .get('searchFields')
        .patchValue(
          this.fieldsMapper('searchFields', settingsValue?.search?.searchFields)
        );
      this.formGroup
        .get('sortFields')
        .patchValue(
          this.fieldsMapper('sortFields', settingsValue?.sort?.sortFields)
        );
      this.formGroup.get('facetFields').patchValue(this.fieldsMapper('facetFields', settingsValue?.facet?.facetFields));
      this.formGroup
        .get('filterFields')
        .patchValue(
          this.fieldsMapper('filterFields', settingsValue?.filter?.filterFields)
        );
      if (settingsValue?.sort?.sortPlacement === 'header') {
        this.advancedFeatures.sort.header.checked = true;
        this.advancedFeatures.sort.top.checked = false;
      } else if (settingsValue?.sort?.sortPlacement === 'top') {
        this.advancedFeatures.sort.top.checked = true;
        this.advancedFeatures.sort.header.checked = false;
      }
      if (settingsValue?.search?.searchPlacement === 'top') {
        this.advancedFeatures.search.top.checked = true;
        this.advancedFeatures.search.header.checked = false;
      } else {
        this.advancedFeatures.search.header.checked = true;
        this.advancedFeatures.search.top.checked = false;
      }
      // if (settingsValue?.filter?.filterPlacement === 'header') this.advancedFeatures.filter.header.checked = true;
      for (let i = 0; i < 3; i++) {
        this.advancedFeatures.export.checkBoxValues[i].checked =
          settings?.export[i]?.isChecked;
      }
    }
  }
  fieldsMapper(event: any, fields: any) {
    let fieldValues: boolean[] = new Array(
      this.selectedEntityData?.nslAttributes.length
    ).fill(false);
    if (fieldValues.length) {
      fields?.forEach((attr: any) => {
        this.selectedEntityData?.nslAttributes.forEach(
          (attrname: any, index: number) => {
            if (attr === attrname.name) {
              fieldValues[index] = true;
            }
          }
        );
      });
    }
    return fieldValues;
  }
  searchAttributes() {
    isSearchAttributeVal(
      this.attributeValue,
      this.targetRefAttr?.[this.searchFieldsIndex]
    );
  }
  setPosition(label: string, position: string) {
    this.advancedFeatures[label].checked = true;
    this.advancedFeatures[label][position].checked = true;
  }
  setConfigurationValues(label: string, config: string, label1: string) {
    if (this.filterDesignConfig?.[label]?.[config]) {
      Object.entries(this.filterDesignConfig[label][config] || {}).forEach(
        ([key, value]) => {
          let index = this.advancedFeatures?.[label]?.[label1].findIndex(
            (attr: any) => attr.actualValue == key
          );
          if (index != -1) {
            if (this.advancedFeatures[label][label1][index]?.checked == false)
              this.advancedFeatures[label][label1][index].checked = value;
            else if (
              this.advancedFeatures[label][label1][index]?.value ||
              this.advancedFeatures[label][label1][index]?.value == ''
            )
              this.advancedFeatures[label][label1][index].value = value;
          }
        }
      );
    }
  }

  setIconUrls(label:string,config:string){
    if (this.filterDesignConfig?.[label]?.[config]) {
      if(this.filterDesignConfig?.[label]?.[config].uplaodIcon) {
        this.advancedFeatures[label].icon[0].checked = true
        this.advancedFeatures[label].icon[0].value = this.filterDesignConfig?.[label]?.[config].uplaodIcon

      }
      if(this.filterDesignConfig?.[label]?.[config].uploadHoverIcon){
        this.advancedFeatures[label].icon[1].checked = true
        this.advancedFeatures[label].icon[1].value = this.filterDesignConfig?.[label]?.[config].uplaodIcon
      }
    }
  }
  setAttributeDetails(key: string) {
    this.filterDesignConfig[key].attributeDetails.forEach((attr: any) => {
      let index = this.selectedEntityData.nslAttributes.findIndex(
        (nslAttr: any) => attr.attributeName == nslAttr.name
      );
      if (index != -1) {
        this.selectedEntityData.nslAttributes[index][key].checked = true;
        if (attr?.filterTemplate) {
          this.selectedEntityData.nslAttributes[index][key].id =
            attr?.filterTemplate;
        }
      }
      if (key == 'searchFields') {
        this.editSearchFieldsMap.set(attr.attributeName, attr.selectedRefValue);
        this.editSearchFieldsAttrMap.set(
          attr.attributeName,
          attr.selectedRefAttrValue
        );
      }
    });
  }
  setHiddenFilters() {
    if (this.filterDesignConfig['hiddenFilterRule'].ruleSet.length > 0) {
      this.advancedFeatures.hiddenFilter.checked = true;
      this.hiddenRuleSet.ruleSet = this.filterDesignConfig['hiddenFilterRule'].ruleSet;
      this.hiddenRuleSet.ruleSetCondition = this.filterDesignConfig['hiddenFilterRule'].ruleSetCondition
    }
  }

  seturlbindingData() {
    let obj = this.filterDesignConfig?.urlBindingData;
    if(Object.keys(obj).length>0){
      this.advancedFeatures['urlBinding'].checked = true;
          if(obj?.['masterId']) {
            const tempGsi = this.childGsiList?.find((gsi:any) => gsi.masterId == obj?.['masterId']);
            if(tempGsi) this.urlBindingGsi = tempGsi;
          }
          if(obj?.['attributeName']) {
            const tempGsi = this.dropMappedAttributes?.find((gsi:any) => gsi.name == obj?.['attributeName']);
            if(tempGsi) this.urlBindingAttribute = tempGsi;
          }
          if(obj?.['attributeColor']) {
            this.colorModel = obj?.['attributeColor'];
          }
    }
  }

  checkExportData() {
    if (this.filterDesignConfig?.export) {
      let exportObj = this.filterDesignConfig?.export;
      let flag = false;
      this.advancedFeatures?.export?.checkBoxValues?.forEach((key: any) => {
        if (exportObj[key?.actualValue]) {
          key.checked = true;
          flag = true;
        }
      });
      if (flag) {
        this.advancedFeatures.export.checked = true;
      }
    }
  }

  setAdvancedFeaturesEdit() {
    if (this.advancedFeatures) {
      Object.keys(this.filterDesignConfig || {}).forEach((key: any) => {
        if (key == 'hiddenFilterRule') {
          this.setHiddenFilters();
          return;
        } else if (key == 'urlBindingData') {
          this.seturlbindingData();
        } else {
          // need to see why sort is not functioning properly
          if (key == 'sort' && this.filterDesignConfig?.sort?.individual) {
            this.filterDesignConfig.sort = this.filterDesignConfig?.sort?.individual;
            this.filterDesignConfig.sort[
              'sortConfiguration'
            ] = this.filterDesignConfig?.sort?.sortConfigurations;
          }
          if (this.filterDesignConfig?.[key]?.attributeDetails?.length > 0) {
            this.advancedFeatures[key].checked = true;
            if (key == 'sort') {
              this.sortCreateFlag = true;
              this.sortCreateIndividualFlag = true;
            }
            this.setAttributeDetails(key);
            if (
              this.filterDesignConfig?.[key]?.[key + 'Configuration']?.position
            ) {
              this.setPosition(
                key,
                this.filterDesignConfig?.[key]?.[key + 'Configuration']
                  ?.position
              );
            }
          }
          if (this.advancedFeatures?.[key]?.checkBoxValues) {
            this.setConfigurationValues(
              key,
              key + 'Configuration',
              'checkBoxValues'
            );
          }
          if (this.advancedFeatures?.[key]?.toggleValues) {
            this.setConfigurationValues(
              key,
              key + 'Configuration',
              'toggleValues'
            );
          }
          if (this.advancedFeatures?.[key]?.tabValues) {
            this.setConfigurationValues(
              key,
              key + 'Configuration',
              'tabValues'
            );
          }
          if (this.advancedFeatures?.[key]?.inputValues) {
            this.setConfigurationValues(
              key,
              key + 'Configuration',
              'inputValues'
            );
          }
          if (this.advancedFeatures?.[key]?.icon) {
            this.setIconUrls(
              key,
              key + 'Configuration'
            );
          }
          if (this.advancedFeatures?.[key]?.styles) {
            this.setConfigurationValues(key, key + 'Configuration', 'styles');
          }
        }
      });
      this.checkExportData();
    }
  }
  onKeydown(event: KeyboardEvent) {
    /*istanbul ignore if*/
    if (event?.key === '-') {
      event?.preventDefault();
    }
  }
  versionChange(ver: number) {
    this.apiVersion = ver;
    this.apiVersionDataEmit.emit(this.apiVersion);
  }
  onScrollDown(event: any, index: number) {
    this.refEntPageNumber[index] += 1;
    this.refrenceEntitiesApiCall(this.searchTextRefEntity[index], this.refEntPageNumber[index], 10, index,true);
  }
  toggleColorPicker() {
    this.colorPickerToggler = !this.colorPickerToggler;
  }
  changeColor(event: any) {
    this.colorModel = event;
  }

  uplaodIcon(event: any , icon: any){
    let newFormData = new FormData();
    let file = event?.target?.files[0];
    /* istanbul ignore next*/
    if (event?.target?.files && event?.target?.files[0]) {
      newFormData.append('file', file);
    }
    this.entityBoardService
      .uploadData(newFormData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
      
          icon.value = res?.contentUrl;
         
          
        }
      },(err)=>{
    console.log(err)
      });

  }
  deleteIcon(icon:any){
    icon.value = '../../../../../assets/img/general-entity/Advance-features/heart.svg';
  }
}
