import { Directive, HostListener, Renderer2, ElementRef, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { IStyle } from '@common-services';

@Directive({
  selector: '[appConfigureAttribute]',
})
export class ConfigureAttributeDirective implements OnChanges {
  htmlElement: HTMLElement;
  hoverColor: string;
  color: string = '';
  @Input() cartAttrStyleArr: any = [];
  @Input() hoveredColors: any;
  @Input() index: any;
  @Input() isCard: boolean = false;
  @Input() isButton: boolean = false;
  @Input() hoverColorsExpand: any;
  @Input() tabSelected: string;
  @Input() hoverColorsButton: string;
  @Input() cartAttrStyleArray: any = [];
  @Input() set isDesign(value: boolean) {
    /*istanbul ignore next*/
    if (value) {
      if (!this.isButton) {
        this.htmlElement.style.background = 'none';
      }
    }
  }
  @Input() set _selectedDirectiveId(value: string) {
    /*istanbul ignore next*/
    if (value != this.directiveName) {
      this.isSelectedElement = false;
      this.htmlElement.style.border = 'none';
      this.htmlElement.style.outline = 'none';
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?._style) {
      if (this.isSelectedElement) {
        this.changeStyle(this._style);
      }
    }
    if (changes?.cartAttrStyleArray) {
      this.editCartStyles();
    }
    this.cartAttrStyleArr.push(this.cartAttrStyleArray);
  }
  changeStyle(style: any) {
    switch (style.changedElementStyle) {
      case 'fontFamily':
        this.htmlElement.style.fontFamily = style?.changedElementValue?.font;
        break;
      case 'fontSize':
        this.htmlElement.style.fontSize = style?.changedElementValue + 'px';
        break;
      case 'isBold':
        this.htmlElement.style.fontWeight = style?.changedElementValue ? 'bold' : 'normal';
        break;
      case 'isItalic':
        this.htmlElement.style.fontStyle = style?.changedElementValue ? 'italic' : 'normal';
        break;
      case 'alignment':
        this.htmlElement.style.textAlign = style?.changedElementValue?.justify;
        break;
      case 'selectedColor':
        if (!this.isButton) {
          this.htmlElement.style.color = style?.changedElementValue;
        } else {
          this.htmlElement.style.backgroundColor = style?.changedElementValue;
        }
        break;
      case 'hoverBackgroundColor':
        if (this.tabSelected == 'expand') {
          this.updateHoverColor(style, this.hoverColorsExpand);
        } else if (this.tabSelected == 'child') {
          this.updateHoverColor(style, this.hoveredColors);
        }
        break;
      case 'borderSize':
        this.htmlElement.style.borderWidth = style.changedElementValue + 'px';
        break;
      case 'borderRadius':
        this.htmlElement.style.borderRadius = style.changedElementValue + 'px';
        break;
      case 'selectedBorderColor':
        this.htmlElement.style.borderColor = style.changedElementValue;
        break;
    }
  }
  editCartStyles() {
    this.htmlElement.style.color = this.cartAttrStyleArray?.color;
    this.htmlElement.style.fontFamily = this.cartAttrStyleArray?.font;
    this.htmlElement.style.fontSize = this.cartAttrStyleArray?.fontSize;
    this.htmlElement.style.fontStyle = this.cartAttrStyleArray?.fontStyle;
    this.htmlElement.style.textAlign = this.cartAttrStyleArray?.textAlign;
  }
  updateHoverColor(style: any, arr: any) {
    const index = arr?.findIndex((attr: any) => attr.name == this.htmlElement.innerText.trim());
    if (index != -1) {
      arr[index].value = style.changedElementValue;
    } else {
      arr.push({ name: this.htmlElement.innerText.trim(), value: style.changedElementValue });
    }
  }
  changeHoverColor(arr: any) {
    const index = arr?.findIndex((attr: any) => attr.name == this.htmlElement.innerText.trim());
    if (!this.isButton) {
      this.color = this.htmlElement.style.color;
      if (index != -1) {
        this.htmlElement.style.color = arr?.[index]?.value;
      }
    } else {
      this.color = this.htmlElement.style.backgroundColor;
      if (index != -1) {
        this.htmlElement.style.backgroundColor = arr?.[index]?.value;
      }
    }
  }

  @Input() _style: IStyle;
  isSelectedElement: boolean = false;
  @Output() emitSelectedElement: EventEmitter<string> = new EventEmitter<string>();
  @Output() emitSelectedConditionElement: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitInnerHtml = new EventEmitter();
  @Input() directiveId: number = -1;
  @Input() directiveName: 'attribute' | 'card' = 'attribute';
  static directiveConstant: number = 0;

  constructor(private rendrer: Renderer2, private element: ElementRef) {
    this.htmlElement = element.nativeElement;
  }
  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    event.stopPropagation();
    /*istanbul ignore next*/
    if (this.isSelectedElement === false) {
      this.htmlElement.style.border = '1px solid white';
      this.htmlElement.style.outline = '2px solid black';
      if (this.isCard) {
        this.htmlElement.style.border = '2px solid green';
      }
      this.isSelectedElement = true;
      this.emitSelecteddirectiveId();
    } else {
      this.isSelectedElement = false;
      this.htmlElement.style.border = 'none';
      this.htmlElement.style.outline = 'none';
    }
  }
  emitSelecteddirectiveId(): void {
    this.emitSelectedElement.emit('attribute');
  }

  @Input() set _isCondition(value: any) {
    this.emitConditionValue(value);
  }

  @Input() set _htmlValue(value: string) {
    if (this.isSelectedElement) {
      this.htmlElement.innerHTML = value;
      this.emitInnerHtml.emit({ value: value, index: this.index });
    }
  }

  emitConditionValue(value: any) {
    if (this.isSelectedElement) {
      this.emitSelectedConditionElement.emit({ color: value, value: this.htmlElement });
    }
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: any): void {
    event.stopPropagation();
    if (this.tabSelected == 'expand') {
      this.changeHoverColor(this.hoverColorsExpand);
    } else {
      this.changeHoverColor(this.hoveredColors);
    }
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: any): void {
    event.stopPropagation();
    if (!this.isButton) {
      this.htmlElement.style.color = this.color;
    } else {
      this.htmlElement.style.backgroundColor = this.color;
    }
  }

  /**
   * @param {string}value - string value
   * '_changeShadowStyle' assigns box-shadow style to the selected template in entity board
   */
  @Input() set _changeShadowStyle(value: string) {
    if (this.isSelectedElement) {
      this.htmlElement.style.boxShadow = value;
    }
  }
}

