import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';

import { TranslatorService } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-charts',
  template: `
    <div>
      <div *ngIf="chartType && chartType !== 'pie' && hideFilter && !isSelectXandY" class="value-select">
        <mat-form-field floatLabel="never">
          <mat-label>Value Type</mat-label>
          <mat-select [(ngModel)]="valueType" (click)="$event.stopPropagation()">
            <mat-option
              *ngFor="let val of valueTypeData"
              [value]="val.value"
              (click)="$event.stopPropagation(); selectedValueType.emit({ value: val.value })"
            >
              {{ val.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="chartType && chartType !== 'pie' && !hideFilter" class="time-select">
        <mat-form-field *ngIf="selectedChartType !== 'specialFeatures'" floatLabel="never">
          <mat-label>Time Wise</mat-label>
          <mat-select [(ngModel)]="timeSeries" (click)="$event.stopPropagation()">
            <mat-option
              *ngFor="let time of timeWiseData"
              [value]="time.value"
              (click)="$event.stopPropagation(); selectedTime.emit({ value: time.value })"
            >
              {{ time.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field
        *ngIf="selectedChartType === 'specialFeatures' && dashboardId"
        appearance="standard"
        class="datePicker"
        class="time-select"
      >
        <mat-label>{{ labels?.Start_Date }} & {{ labels?.End_Date }}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [(ngModel)]="requestStartDate" placeholder="StartDate" />
          <input
            [(ngModel)]="requestEndDate"
            matEndDate
            placeholder="EndDate"
            (ngModelChange)="selectedDateRange.emit({ startDate: requestStartDate, endDate: requestEndDate })"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (opened)="clearStartEndDate()"></mat-date-range-picker> </mat-form-field
      ><br /><br />

      <div *ngIf="chartType == 'pie'" class="time-select">
        <section class="pie-section">
          <mat-checkbox class="pie-margin" [(ngModel)]="value" (click)="valueChecked($event)">Value</mat-checkbox>
          <mat-checkbox
            *ngIf="isTxn || chartType == 'pie'"
            class="pie-margin"
            [(ngModel)]="percentage"
            (click)="percentageChecked($event)"
            >Percentage</mat-checkbox
          >
        </section>
      </div>
    </div>
    <div
      *ngIf="dashboardtype != 'custom'"
      [id]="containerId"
      [ngClass]="containerId == 'containerId3' ? 'chartStyle2' : 'chartStyle1'"
    ></div>
    <div *ngIf="dashboardtype == 'custom'" [id]="containerId"></div>
  `,
  styles: [
    `
      .widgets_1chart {
        height: 300px;
        width: 70%;
      }
      .widgets_2chart {
        height: 300px;
        width: 70%;
      }
      .widgets_3chart {
        height: 300px;
        width: 70%;
      }
      .widgets_4chart {
        height: 300px;
        width: 70%;
      }
      .chartStyle2 {
        height: 430px;
        width: 1000px;
        margin: auto;
        margin-top: 25px;
      }
      .chartStyle1 {
        height: 250px;
        width: 550px;
      }
      .time-select {
        z-index: 99;
        float: right;
        right: 0px;
        position: relative;
      }
      .value-select {
        position: relative;
        float: right;
        right: 0;
        z-index: 99;
      }
      ::ng-deep.mat-form-field-infix {
        border-top: 0px;
        width: 95px;
      }
      ::ng-deep.mat-form-field-appearance-fill .mat-form-field-flex {
        background: none;
        padding: 0px;
      }

      .pie-section {
        align-content: center;
        align-items: center;
        height: 60px;
        /*position: relative;
        margin-top: 55px;
        margin-left: -315px;*/
      }

      .pie-margin {
        margin: 0 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: any;
  @Input() data: any;
  @Input() width: any;
  @Input() height: any;
  @Input() containerId: any;
  @Input() containerClass: any;
  @Input() chartStyle: any;
  @Input() selectedColor: any = [];
  @Input() hideFilter: boolean;
  @Input() dashboardtype: string;
  @Input() selectedChartType: string;
  @Input() x_axis_label: any;
  @Input() y_axis_label: any;
  @Output() selectedTime: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedValueType: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedPieValueType: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDateRange: EventEmitter<any> = new EventEmitter<any>();
  today: any;
  @Input() requestStartDate: any;
  @Input() requestEndDate: any;
  ngUnsubscribe = new Subject();
  chartType: any;
  @Input() timeSeries: any;
  @Input() valueType: any;
  @Input() isTxn: boolean;
  labels: any;
  timeWiseData: any = [];
  valueTypeData: any = [];
  @Input() value = true;
  @Input() percentage: boolean;
  @Input() isSelectXandY: boolean;
  chart: any;
  @Input() dashboardId: any;
  colorSet: any = ['#c7b297', '#a81a40', '#a81aa3', '#f88c8c', '#4336e4', '#17a18e', '#8bcb00'];

  constructor(public cdr: ChangeDetectorRef, private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    let change = simpleChanges.data;
    /* istanbul ignore next  */
    if (!change.firstChange) {
      setTimeout(() => {
        this.renderChart();
      }, 500);
    }
  }

  ngOnInit() {
    this.today = new Date();
  }

  clearStartEndDate() {
    this.requestStartDate = undefined;
    this.requestEndDate = undefined;
  }

  valueChecked(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.value = !this.value;
    this.selectedPieValueType.emit({ value: this.value, percentage: this.percentage, event: event });
  }

  percentageChecked(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.percentage = !this.percentage;
    this.selectedPieValueType.emit({ value: this.value, percentage: this.percentage, event: event });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderChart();
    }, 500);
  }

  renderChart() {
    /* istanbul ignore next  */
    const pretoType = this.data[this.data.length - 1]?.type;
    /* istanbul ignore next  */
    this.chartType = this.data[0]?.type == 'pareto' ? 'column' : this.data[0]?.type;
    /* istanbul ignore next  */
    this.data?.forEach((el: any) => {
      el.type = el?.type == 'pareto' ? 'column' : el?.type;
    });
    this.cdr.detectChanges();
    let canvasObj: any = {
      animationEnabled: true,
      exportEnabled: false,
      backgroundColor: 'transparent',
      title: {
        text: this.title,
        horizontalAlign: 'left',
        fontSize: 28,
        fontColor: '#323232',
        fontFamily: 'inter',
        fontWeight: '500',
      },
      legend: {
        horizontalAlign: 'right',
        verticalAlign: 'top',
        fontSize: 11,
        fontFamily: 'inter',
      },
      data: this.data,
    };
    /* istanbul ignore else  */
    if (this.dashboardtype == 'custom') {
      canvasObj = {
        ...canvasObj,
        height: this.height - 50,
        width: this.width - 50,
      };
    } else {
      let axisXobj;
      if (pretoType === 'multiSeriesLine') {
        axisXobj = [];
        this.data.forEach((dataObj: any, index: any) => {
          axisXobj.push({
            title: dataObj.name,
            labelAngle: -30,
            labelFontSize: 10,
            lineColor: this.colorSet[index % 7],
          });
          dataObj.type = 'line';
          dataObj.color = this.colorSet[index % 7];
        });
        canvasObj = {
          ...canvasObj,
          toolTip: {
            shared: true,
          },
        };
      } else {
        /*istanbul ignore next*/
        if (this.data[0]?.dataPoints?.length === 1)
          axisXobj = { title: this.x_axis_label, labelFontSize: 10, labelAngle: -30 };
        else axisXobj = { title: this.x_axis_label, interval: 1, labelFontSize: 10, labelAngle: -30 };
        /*istanbul ignore else*/
        if (pretoType === 'scatter') {
          axisXobj = {
            ...axisXobj,
            minimum: Math.min(...this.data[0]?.dataPoints?.map((item: any) => item.x)) - 1,
            maximum: Math.max(...this.data[0]?.dataPoints?.map((item: any) => item.x)) + 1,
          };
        }
      }

      canvasObj = {
        ...canvasObj,
        axisX: axisXobj,
        axisY: { title: this.y_axis_label },
      };
      /*istanbul ignore else*/
      if (pretoType === 'pareto') {
        canvasObj = {
          ...canvasObj,
          axisY2: {
            title: 'Percent',
            suffix: '%',
            lineColor: '#C0504E',
            tickColor: '#C0504E',
          },
        };
      }
    }
    // this.chart = new CanvasJS.Chart(this.containerId, canvasObj);
    this.chartInit(pretoType);
  }

  /**
   * Charts init
   */
  chartInit(pretoType: any) {
    this.chart.render();
    /*istanbul ignore else */
    if (pretoType == 'pareto') {
      this.createPareto();
    }
  }

  createPareto() {
    var dps = [];
    var yValue,
      yTotal = 0,
      yPercent = 0;
    /* istanbul ignore next  */
    for (var i = 0; i < this.chart?.data[0]?.dataPoints.length; i++) yTotal += this.chart?.data[0]?.dataPoints[i].y;
    /* istanbul ignore next  */
    for (var i = 0; i < this.chart?.data[0]?.dataPoints.length; i++) {
      yValue = this.chart?.data[0]?.dataPoints[i].y;
      yPercent += (yValue / yTotal) * 100;
      dps.push({ label: this.chart?.data[0]?.dataPoints[i].label, y: yPercent });
    }
    /* istanbul ignore next  */
    this.chart?.addTo('data', { type: 'line', yValueFormatString: '0.##"%"', dataPoints: dps });
    /* istanbul ignore next  */
    //this.chart?.data[1].set('axisYType', 'secondary', false);
    /* istanbul ignore next  */
    this.chart?.axisY[0].set('maximum', yTotal);
    /* istanbul ignore next  */
    this.chart?.axisY2[0].set('maximum', 100);
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      /* istanbul ignore next  */
      this.timeWiseData = [
        { key: this.labels?.DAILY, value: 'DAILY' },
        { key: this.labels?.WEEKLY, value: 'WEEKLY' },
        { key: this.labels?.MONTHLY, value: 'MONTHLY' },
        { key: this.labels?.QUARTERLY, value: 'QUARTERLY' },
        { key: this.labels?.YEARLY, value: 'YEARLY' },
        { key: this.labels?.TENDAYS, value: '10DAYS' },
      ];

      this.valueTypeData = [
        { key: 'Value', value: 'value' },
        { key: 'Percentage', value: 'percentage' },
      ];
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
