import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { EventsFacadeService, FieldConfig, getUiControlCustomization, TransactionFacadeService, validateDependentExpression } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

const REGEX = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
@Component({
  selector: 'app-duration',
  styleUrls: ['./duration.component.scss'],
  template: `
    <div [formGroup]="group">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
          <input
            matInput
            style="display: none;"
            [value]="field.value"
            [formControlName]="field.attribute.name"
            [readonly]="true"
            [required]="field?.isRequired && !field?.isInfo"
            id="dateSelect_{{ field.attribute.name }}_{{ field.id }}"
          />
          <p
            class="nh-ml-6 d-flex"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true' || field.isMulti"
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            matSuffix
            class="d-flex align-items-center justify-content-center"
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.readonly"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            (click)="addAttribute.next()"
            >add</mat-icon
          >
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !field?.readonly"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            (click)="removeAttribute.next()"
            >remove</mat-icon
          >
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>

      <div
        class="{{ appliedClass }}"
        [ngClass]="{
          inValidClass:
            !field?.isInfo &&
            (group?.controls)[field?.attribute?.name]?.invalid &&
            (group?.controls)[field?.attribute?.name]?.touched
        }"
      >
        <table
          [ngClass]="
            !foundObject?.event || foundObject?.event == '' ? 'example-full-width duration-table' : 'duration-table'
          "
        >
          <tr *ngIf="foundObject?.event !== 'Option 4'">
            <td>
              <div
                [ngClass]="
                  !foundObject
                    ? 'number-input number-input-arrow'
                    : foundObject?.event == 'Option 3'
                    ? 'duration-number-input'
                    : foundObject?.event !== 'Option 4'
                    ? 'duration-number-input duration-number-input-arrow'
                    : ''
                "
              >
                <button
                  id="down_button_{{ field.attribute.name }}"
                  (click)="logNewValue('days', 'down')"
                  *ngIf="foundObject?.event !== 'Option 4' && foundObject?.event !== 'Option 3'"
                  onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                  class="minus"
                >
                  <mat-icon *ngIf="foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'" class="minus"
                    >keyboard_arrow_down</mat-icon
                  >
                </button>
                <input
                  class="quantity duration-inner-height"
                  min="0"
                  name=""
                  placeholder="DD"
                  [(ngModel)]="duration.days"
                  (keypress)="onlyNumberKey($event, field.inputType)"
                  [ngModelOptions]="{ standalone: true }"
                  type="number"
                  [readonly]="field.readonly"
                  [required]="field.isRequired && !field?.isInfo"
                  [autocomplete]="autoOff"
                  (change)="onChange($event)"
                  (focus)="onFocus($event)"
                  id="{{ field.attribute.name }}"
                />
                <label *ngIf="foundObject?.event == 'Option 3'">Days</label>
                <button
                  id="up_button_{{ field.attribute.name }}"
                  (click)="logNewValue('days', 'up')"
                  *ngIf="foundObject?.event !== 'Option 4' && foundObject?.event !== 'Option 3'"
                  onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                  class="plus"
                >
                  <mat-icon *ngIf="foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'" class="plus"
                    >keyboard_arrow_up</mat-icon
                  >
                </button>
              </div>
            </td>
            <td>
              <div
                [ngClass]="
                  !foundObject
                    ? 'number-input number-input-arrow'
                    : foundObject?.event == 'Option 3'
                    ? 'duration-number-input'
                    : foundObject?.event !== 'Option 4'
                    ? 'duration-number-input duration-number-input-arrow'
                    : ''
                "
              >
                <button
                  id="down_button_{{ field.attribute.name }}"
                  (click)="logNewValue('hours', 'down')"
                  *ngIf="
                    !foundObject?.event ||
                    foundObject?.event == '' ||
                    (foundObject?.event !== 'Option 4' && foundObject?.event !== 'Option 3')
                  "
                  onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                  class="minus"
                >
                  <mat-icon *ngIf="foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'" class="minus"
                    >keyboard_arrow_down</mat-icon
                  >
                </button>
                <input
                  class="quantity duration-inner-height"
                  min="0"
                  max="23"
                  name=""
                  placeholder="HH"
                  [(ngModel)]="duration.hours"
                  (ngModelChange)="checkDuration()"
                  [ngModelOptions]="{ standalone: true }"
                  (keypress)="onlyNumberKey($event, field.inputType)"
                  type="number"
                  [readonly]="field.readonly"
                  [required]="field.isRequired && !field?.isInfo"
                  [autocomplete]="autoOff"
                  (change)="onChange($event)"
                  (focus)="onFocus($event)"
                  id="{{ field.attribute.name }}"
                />
                <label *ngIf="foundObject?.event == 'Option 3'">Hours</label>
                <button
                  id="up_button_{{ field.attribute.name }}"
                  (click)="logNewValue('hours', 'up')"
                  *ngIf="
                    !foundObject?.event ||
                    foundObject?.event == '' ||
                    (foundObject?.event !== 'Option 4' && foundObject?.event !== 'Option 3')
                  "
                  onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                  class="plus"
                >
                  <mat-icon *ngIf="foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'" class="plus"
                    >keyboard_arrow_up</mat-icon
                  >
                </button>
              </div>
            </td>
            <td>
              <div
                [ngClass]="
                  !foundObject
                    ? 'number-input number-input-arrow'
                    : foundObject?.event == 'Option 3'
                    ? 'duration-number-input'
                    : foundObject?.event !== 'Option 4'
                    ? 'duration-number-input duration-number-input-arrow'
                    : ''
                "
              >
                <button
                  id="down_button_{{ field.attribute.name }}"
                  (click)="logNewValue('minutes', 'down')"
                  *ngIf="
                    !foundObject?.event ||
                    foundObject?.event == '' ||
                    (foundObject?.event !== 'Option 4' && foundObject?.event !== 'Option 3')
                  "
                  onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                  class="minus"
                >
                  <mat-icon *ngIf="foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'" class="minus"
                    >keyboard_arrow_down</mat-icon
                  >
                </button>
                <input
                  class="quantity duration-inner-height"
                  min="0"
                  max="59"
                  name=""
                  placeholder="MM"
                  [(ngModel)]="duration.minutes"
                  (ngModelChange)="checkDuration()"
                  [required]="field.isRequired && !field?.isInfo"
                  [ngModelOptions]="{ standalone: true }"
                  (keypress)="onlyNumberKey($event, field.inputType)"
                  type="number"
                  [readonly]="field.readonly"
                  [required]="field.isRequired && !field?.isInfo"
                  (change)="onChange($event)"
                  [autocomplete]="autoOff"
                  (focus)="onFocus($event)"
                  id="{{ field.attribute.name }}"
                />
                <label *ngIf="foundObject?.event == 'Option 3'">Min</label>
                <button
                  id="up_button_{{ field.attribute.name }}"
                  (click)="logNewValue('minutes', 'up')"
                  *ngIf="
                    !foundObject?.event ||
                    foundObject?.event == '' ||
                    (foundObject?.event !== 'Option 4' && foundObject?.event !== 'Option 3')
                  "
                  onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                  class="plus"
                >
                  <mat-icon *ngIf="foundObject?.event == 'Option 1' || foundObject?.event == 'Option 2'" class="plus"
                    >keyboard_arrow_up</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr *ngIf="foundObject?.event == 'Option 4'">
            <td>
              <mat-form-field appearance="fill">
                <mat-label>Days</mat-label>
                <input
                  matInput
                  min="0"
                  name=""
                  placeholder="DD"
                  [(ngModel)]="duration.days"
                  [required]="field.isRequired && !field?.isInfo"
                  [ngModelOptions]="{ standalone: true }"
                  (keypress)="onlyNumberKey($event, field.inputType)"
                  type="number"
                  [readonly]="field.readonly"
                  [autocomplete]="autoOff"
                  (change)="onChange($event)"
                  (focus)="onFocus($event)"
                  id="{{ field.attribute.name }}"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="fill">
                <mat-label>Hours</mat-label>
                <input
                  matInput
                  min="0"
                  max="23"
                  name=""
                  placeholder="HH"
                  [(ngModel)]="duration.hours"
                  [required]="field.isRequired && !field?.isInfo"
                  [ngModelOptions]="{ standalone: true }"
                  (keypress)="onlyNumberKey($event, field.inputType)"
                  type="number"
                  [readonly]="field.readonly"
                  [autocomplete]="autoOff"
                  (change)="onChange($event)"
                  (focus)="onFocus($event)"
                  id="{{ field.attribute.name }}"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="fill">
                <mat-label>Min</mat-label>
                <input
                  matInput
                  min="0"
                  max="59"
                  name=""
                  placeholder="MM"
                  [(ngModel)]="duration.minutes"
                  [ngModelOptions]="{ standalone: true }"
                  (ngModelChange)="checkDuration()"
                  (keypress)="onlyNumberKey($event, field.inputType)"
                  type="number"
                  [readonly]="field.readonly"
                  [required]="field.isRequired && !field?.isInfo"
                  (change)="onChange($event)"
                  [autocomplete]="autoOff"
                  (focus)="onFocus($event)"
                  id="{{ field.attribute.name }}"
                />
              </mat-form-field>
            </td>
          </tr>
        </table>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </div>
  `,
  styles: [
    `
      ::ng-deep.mat-form-field-infix {
        width: auto !important;
      }
      /*.duration-table input {
        height: 48px;
        background: #fff;
        border: 1px solid #cccccc;
        border-radius: 6px;
        padding: 14px 16px;
        font-size: 16px;
        color: #333333;
        transition: 300ms all ease-out;
        text-overflow: ellipsis;
        width: 100%;
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
      }
      .duration-table input:hover {
        border: 1px solid #ff657d;
      }
      .duration-table tr {
        display: flex;
        justify-content: space-between;
      }
      .duration-table tr td {
        width: calc(33.33% - 10px);
      }*/
    `,
  ],
})
export class DurationComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  duration: any = {};
  autoOff = 'on';
  foundObject: any;
  appliedClass: string = '';
  validationList: any[] = [
    'onorafterValidation',
    'afterValidation',
    'onorbeforeValidation',
    'beforeValidation',
    'equalTo',
    'notequalTo',
    'min',
    'max'
  ];

  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService ,  private transactionFacadeService: TransactionFacadeService) {}

  ngOnInit(): void {
    /* istanbul ignore next */
    this.autoOff = this.field?.readonly ? 'off' : 'on';
    this.durationTime(this.field.value);
    let durationOption = getUiControlCustomization('Duration');
    if (durationOption) {
      this.foundObject = { event: durationOption };
      let num: number;
      switch (durationOption) {
        case 'Option 1':
          num = 1;
          break;
        case 'Option 2':
          num = 3;
          break;
        case 'Option 3':
          num = 5;
          break;
        case 'Option 4':
          num = 7;
          break;
      }
      this.appliedClass = `duration-opt${num}`;
    }
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }
  }

  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  getErrorMessage(field: FieldConfig, validation: any) {
     /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
    return this.group?.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacadeService
      );
    }
     /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  onlyNumberKey(event: any, fieldType: string) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

  /**
   *
   * function logNewValue()
   * @param durationType ['days', 'hours', 'minutes']
   * @param arrow ['up', 'down']
   * @returns  void
   * @description used to step up and step down the duration component value i.e days, hours, minutes based on up/down button click
   * @author rahul kumar
   */

  logNewValue(durationType: string, arrow: string) {
    //This is for undefined cases
    this.duration.days = this.duration?.days || null;
    this.duration.hours = this.duration?.hours || null;
    this.duration.minutes = this.duration?.minutes || null;

    if (arrow === 'up') {
      switch (durationType) {
        case 'days': {
          this.duration.days = this.duration.days + 1;
          break;
        }
        case 'hours': {
          this.duration.hours = this.duration.hours + 1;
          break;
        }
        case 'minutes': {
          this.duration.minutes = this.duration.minutes + 1;
          break;
        }
      }
    } else if (arrow === 'down') {
      switch (durationType) {
        case 'days': {
          this.duration.days = Math.max(0, this.duration.days - 1);
          break;
        }
        case 'hours': {
          this.duration.hours = Math.max(0, this.duration.hours - 1);
          break;
        }
        case 'minutes': {
          this.duration.minutes = Math.max(0, this.duration.minutes - 1);
          break;
        }
      }
    }
    this.onChange();
  }

  /**
   * Determines whether input value is changed
   */
  onChange(event?: any) {
    if (!this.field.isHidden) {
      this.checkDuration();
      const data = {
        attributeId: this.field.attribute['id'],
        isTableConfig: this.field.attribute['isTableConfig'],
        attrName: this.field.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field.entityName,
        entityId: this.field?.entityId,
        isInfo: this.field?.isInfo,
        slotNumber: this.field?.slotNumber,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
      let durationFormat = '';

      let days: any = this.duration.days;
      let hours: any = this.duration.hours;
      let minutes: any = this.duration.minutes;
      if (!days && !hours) {
        if (minutes) durationFormat = `PT${minutes}M`;
        else durationFormat = `P`;
      } else if (!days) {
        if (minutes) durationFormat = `PT${hours}H${minutes}M`;
        else durationFormat = `PT${hours}H`;
      } else if (!hours) {
        if (minutes) durationFormat = `P${days}DT${minutes}M`;
        else durationFormat = `P${days}D`;
      } else {
        if (minutes) durationFormat = `P${days}DT${hours}H${minutes}M`;
        else durationFormat = `P${days}DT${hours}H`;
      }

      this.group?.get(`${this.field.attribute.name}`).setValue(durationFormat);
    }
  }

  durationTime(String: string) {
    const [, , , , day, hour, min] = String.match(REGEX) || [];
    this.duration.days = parseInt(day) || null;
    this.duration.hours = parseInt(hour) || null;
    this.duration.minutes = parseInt(min) || null;
  }

  /**
   * Determines whether input field is focused
   * @param event Contains the input value
   */
  onFocus(event?: any) {
    if (!this.field.isHidden) {
      const data = {
        attributeId: this.field.attribute['id'],
        isTableConfig: this.field.attribute['isTableConfig'],
        attrName: this.field.attribute['name'],
        eventType: 'ON_FOCUS',
        entityName: this.field.entityName,
        entityId: this.field?.entityId,
        isInfo: this.field?.isInfo,
        slotNumber: this.field?.slotNumber,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      this.eventsService.setEvent(data);
    }
  }

  checkDuration() {
    if (this.duration.hours < 0 || this.duration.hours > 23) {
      this.duration.hours = null;
    }
    if (this.duration.minutes < 0 || this.duration.minutes > 59) {
      this.duration.minutes = null;
    }
  }
}
