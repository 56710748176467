import { Injectable } from '@angular/core';
declare var webkitSpeechRecognition: any;
@Injectable({
  providedIn: 'root',
})
export class VoiceRecognitionService {
  recognition: any;
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords = '';
  transcript = '';
  constructor() {
    try {
      this.recognition = new webkitSpeechRecognition();
    } catch (error) {
      this.recognition = undefined;
    }
  }
  init() {
    if (this.recognition) {
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-IN';
    }
    /* istanbul ignore next */

    this.recognition?.addEventListener('result', (e: any) => {
      this.transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result?.transcript)
        .join('');
      this.tempWords = this.transcript;
      /* istanbul ignore next */

      if (this.tempWords == 'stop') {
        this.tempWords = '.';
      } else if (this.tempWords == 'comma' || this.tempWords == 'komma') {
        this.tempWords = ',';
      }
    });
  }
  start() {
    this.isStoppedSpeechRecog = false;
    // this.recognition?.stop();
    this.recognition?.start();
    this.recognition?.addEventListener('end', (condition: any) => {
      /* istanbul ignore next */
      if (this.isStoppedSpeechRecog) {
        this.recognition?.stop();
      } else {
        this.wordConcat();
        this.recognition?.start();
      }
    });
  }
  stop() {
    this.isStoppedSpeechRecog = true;
    this.wordConcat();
    this.recognition?.stop();
  }
  wordConcat() {
    this.text = this.text + ' ' + this.tempWords;
    this.tempWords = '';
  }
  clear() {
    this.recognition?.stop();
    this.isStoppedSpeechRecog = true;
    this.tempWords = '';
    this.transcript = '';
    this.text = '';
  }
}
