<mat-accordion>
  <mat-expansion-panel [ngClass]="{ 'cstm-expansion-panel-prime-key': isHybrid }">
    <mat-expansion-panel-header>
      <mat-panel-title [ngClass]="{ 'title-style': isHybrid }">
        {{ labels?.PRIMARY_KEY }}
        <mat-icon
          id="add"
          (click)="openDialog(dialog)"
          [hidden]="configurationData?.data?.entityData?.uniqueConstraints?.length"
          >add</mat-icon
        >
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="key-box" *ngFor="let key of configurationData?.data?.entityData?.uniqueConstraints; let iKey = index">
      <div class="show-key">
        <span *ngFor="let attrObj of key; let jAttr = index" class="attr-text"
          >{{ attrObj.name }}<span *ngIf="jAttr < key.length - 1">,&nbsp;&nbsp;</span></span
        >
      </div>
      <mat-icon id="deleteUniqueKey" class="del-icon" (click)="deleteUniqueKey(iKey)">delete</mat-icon>
    </div>
  </mat-expansion-panel>
  <ng-template #dialog>
    <div class="primary-object">
      <div class="heading">
        <h2>{{ labels?.Select_Attribute }}</h2>
      </div>
      <div class="hl"></div>
      <span class="cancel-icon" id="close_dialog" (click)="closeDialog()"> <i class="bi bi-x-lg"></i></span>
      <!-- <div class="list-display">
            <div class="boxes">
              <div *ngFor="let attr of attributesList; let i = index">
                <div *ngIf="attr.visible" class="box" >
                  <span>{{ attr.name }}</span>
                  <mat-icon
                  (click)="selectPrimaryAttribute({checked: true}, i)"
                  >add</mat-icon>
                </div>

              </div>
            </div>

            <div class="boxes">
              <div class="box" *ngFor="let key of uniqueKey; let i = index">
                <span>{{ key.name }}</span>
                <mat-icon
                (click)="selectPrimaryAttribute({checked: false}, i)"
                >delete</mat-icon>
              </div>
            </div>
          </div> -->

      <div mat-dialog-content class="dialog-align">
        <div>
          <div
            *ngFor="let attr of attributesList; let i = index"
            id="attributes_list"
            [hidden]="!attr.visible"
            class="boxes-display"
          >
            <div class="box-display" id="{{ 'attr_' + i }}">
              <span>{{ attr.name }}</span>
              <mat-icon id="{{ 'add' + i }}" (click)="selectPrimaryAttribute({ checked: true }, i)">add</mat-icon>
            </div>
          </div>
        </div>

        <div>
          <div *ngFor="let key of attributesList; let i = index" [hidden]="key.visible" class="boxes-display">
            <div class="box-display">
              <span>{{ key.name }}</span>
              <mat-icon id="{{ 'attr_key' + i }}" (click)="selectPrimaryAttribute({ checked: false }, i)"
                >delete</mat-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div class="add-btn" id="add_btn" (click)="saveUniqueKey()">
        <button mat-raised-button>{{ labels?.Add }}</button>
      </div>
    </div>

    <!-- <ul>
          <li *ngFor="let attr of configurationData?.data?.entityData?.nslAttributes">{{ attr.name }}</li>
          <br />
        </ul> -->
  </ng-template>
</mat-accordion>
