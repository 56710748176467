<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeGsi()">
        {{ labels?.close }}
      </button>
    </div>
    <div class="heading-area">
      <h2>Book</h2>
      <button class="save" (click)="saveBook()">Save</button>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Book Details
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>Book Name</mat-label>
            <input matInput placeholder="Book Name" [(ngModel)]="bookData.name" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Book Display Name</mat-label>
            <input matInput placeholder="Book Display Name" [(ngModel)]="bookData.displayName" />
          </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Permissions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="assign-rights">
                <ul>
                  <li class="roles-li" *ngFor="let role of roles; let i = index">
                    <div class="actions-item-wrap">
                      <button
                        style="z-index: 1050;"
                      >
                        {{ role.name }}
                      </button>
                      <div class="actions-item-btns nested-gsihover" >
                        <ul id="cuRoleshovered">
                          <li>
                            <button  (click)="showRights(i)">
                              <figure>
                                <img [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe" />
                              </figure>
                              <span>{{ labels?.Edit }}</span>
                            </button>
                          </li>
                          <!-- <li>
                            <button >
                              <figure>
                                <img [src]="'../../../../../assets/img/info-icon.svg' | CDNUrlRewritePipe" />
                              </figure>
                              <span>{{ labels?.Information }}</span>
                            </button>
                          </li> -->
                          <li>
                            <button  (click)="removeAgent(i)">
                              <figure>
                                <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                              </figure>
                              <span>{{ labels?.Delete }}</span>
                            </button>
                          </li>
                        </ul>                                           
                      </div>
                      <!-- <mat-menu #afterMenu="matMenu" xPosition="after" id="cuRoleshovered">
                        <span
                          class="menu-hover"
                          (mouseenter)="menuenter()"
                          (mouseleave)="menuLeave(createPlanmenuTrigger)"
                        >
                          <button mat-menu-item (click)="showRights(i)">
                            <figure>
                              <img [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Edit }}</span>
                          </button>
                          <button mat-menu-item>
                            <figure>
                              <img [src]="'../../../../../assets/img/info-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Information }}</span>
                          </button>
                          <button mat-menu-item (click)="removeAgent(i)">
                            <figure>
                              <img [src]="'../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Delete }}</span>
                          </button>
                        </span>
                      </mat-menu> -->
                    </div>
                    <div *ngIf="selectedindex == i" class="read-right-actions separateRights">
                      <div class="mr-3">
                        <label>{{ labels?.Solution_Design }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                bookData.designTimeRights &&
                                bookData.designTimeRights.length > 0 &&
                                bookData.designTimeRights[getDesignRightsIndex(role)] &&
                                bookData.designTimeRights[getDesignRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                bookData.designTimeRights &&
                                bookData.designTimeRights.length > 0 &&
                                bookData.designTimeRights[getDesignRightsIndex(role)] &&
                                bookData.designTimeRights[getDesignRightsIndex(role)].decisionRight
                              "
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                bookData.txnTimeRights &&
                                bookData.txnTimeRights.length > 0 &&
                                bookData.txnTimeRights[getTxnRightsIndex(role)] &&
                                bookData.txnTimeRights[getTxnRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              [(ngModel)]="
                                bookData.txnTimeRights &&
                                bookData.txnTimeRights.length > 0 &&
                                bookData.txnTimeRights[getTxnRightsIndex(role)] &&
                                bookData.txnTimeRights[getTxnRightsIndex(role)].decisionRight
                              "
                              >{{ labels?.Write }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                bookData.txnTimeRights &&
                                bookData.txnTimeRights.length > 0 &&
                                bookData.txnTimeRights[getTxnRightsIndex(role)] &&
                                bookData.txnTimeRights[getTxnRightsIndex(role)].executionRight
                              "
                              >{{ labels?.Execute }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
