import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attsymbol',
  pure: true,
})
export class AttSymbolPipe implements PipeTransform {
  //this pipe separates a contextual id with sach for dcd label display.
  transform(item: any): string {
    let out = '';
    let str = item;
    for (var i = 0; i < str.length; i++) {
      if (str[i] == '<' || str[i] == '>' || str[i] == '$') {
        continue;
      } else {
        out += str[i];
      }
    }
    return out;
  }
}
