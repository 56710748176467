import { Directive, Input, Renderer2, ElementRef, SimpleChanges, HostListener } from '@angular/core';

@Directive({
  selector: '[entityCardColor]',
})
export class CardColorConfigDirective {
  private htmlElement: HTMLElement;
  @Input() isAttributeLevel: boolean = false;
  @Input() isEntityLevel: boolean = true;
  @Input() attrHoveredColors: any;
  @Input() cardHoveredColor: string;
  @Input() cardColor: string;
  @Input() cardShadow: string;
  @Input() cardBorderSize: number;
  @Input() cardBorderRadius: number;
  @Input() cardBorderColor: string;
  @Input() attributeColors: string[];
  @Input() btnColors: string[];
  @Input() conditionArray: any = [];
  @Input() card: any;
  // Expand card details
  @Input() expandDetails: any;
  @Input() expandedCardDetail: any;
  otherStyles: any = {};
  @Input() set _otherStyle(value: any) {
    this.otherStyles = value;
  }
  @Input() expandNameValueMap: any;
  cardHovered: boolean = false;
  private leafNodes: HTMLElement[] = [];
  private buttonLeafNodes: HTMLElement[] = [];
  constructor(private render: Renderer2, private element: ElementRef) {
    this.htmlElement = element.nativeElement;
  }
  ngOnChanges(changes: SimpleChanges): void {
    /*istanbul ignore next*/
    if (changes?.attributeColors?.currentValue?.length > 0) {
      if (this.isEntityLevel && !this.isAttributeLevel) {
        this.appendColors();
      } else if (this.isAttributeLevel) {
        this.appendLeafNodes(this.htmlElement, false);
        this.changeColor();
      }
    }
    if (this.cardColor) {
      this.htmlElement.style.backgroundColor = this.cardColor ? this.cardColor : '';
    }
    /*istanbul ignore next*/
    if (this.cardShadow) {
      this.htmlElement.style.boxShadow = this.cardShadow ? this.cardShadow : '';
    }
    if (this.cardBorderColor || this.cardBorderSize || this.cardBorderRadius) {
      this.htmlElement.style.border = '1px';
    }
    /*istanbul ignore next*/
    if (this.cardBorderSize) {
      this.htmlElement.style.borderWidth = this.cardBorderSize ? this.cardBorderSize + 'px' : '';
    }
    /*istanbul ignore next*/
    if (this.cardBorderRadius) {
      this.htmlElement.style.borderRadius = this.cardBorderRadius ? this.cardBorderRadius + 'px' : '';
    }
    /*istanbul ignore next*/
    if (this.cardBorderColor) {
      this.htmlElement.style.borderColor = this.cardBorderColor ? this.cardBorderColor : '';
    }
  }

  appendColors(): void {
    let l: number = this.htmlElement.children.length;
    /*istanbul ignore else*/
    if (l != 0) {
      for (let i = 0; i < l; i++) {
        let childElement: HTMLElement = this.htmlElement.children[i] as HTMLElement;
        if (childElement?.classList.value.includes('template-btns')) {
          this.appendLeafNodes(childElement, true);
        } else {
          this.appendLeafNodes(childElement, false);
        }
      }
      this.changeColor();
      // this.changeButtonColors();
    }
  }
  changeHoverColor(leafNode: HTMLElement, label: string) {
    if (this.isAttributeLevel) {
      if (label == 'over' && this.attrHoveredColors?.[0]?.length > 0) {
        leafNode.style.color = this.attrHoveredColors[0];
      } else {
        leafNode.style.color = this.attributeColors[0];
      }
    } else {
      if (this.expandedCardDetail) {
        let index: number;
        Object.entries(this.expandDetails).forEach(([key, value]) => {
          if (
            leafNode.innerText.trim() == value ||
            leafNode.innerText.trim() == this.expandedCardDetail?.[0]?.[String(value)]
          ) {
            index = parseInt(key);
            console.log(key);
          }
        });
        if (label == 'over' && this.attrHoveredColors?.[index]?.length > 0) {
          leafNode.style.color = this.attrHoveredColors?.[index];
        } else {
          leafNode.style.color = this.attributeColors?.[index];
        }
      } else {
        const index = this.card?.recordData?.findIndex(
          (attr: any) => attr.attributeName == leafNode.innerText || attr.value == leafNode.innerText
        );
        if (index != -1) {
          if (label == 'over' && this.attrHoveredColors?.[index]?.length > 0) {
            leafNode.style.color = this.attrHoveredColors?.[index];
          } else {
            leafNode.style.color = this.attributeColors?.[index];
          }
        }
      }
    }
  }

  changeColor(): void {
    let index = 0;
    /*istanbul ignore next*/
    this.leafNodes.forEach((leafNode: HTMLElement) => {
      leafNode.style.color = this.attributeColors[index];
      leafNode.addEventListener('mouseover', () => {
        this.changeHoverColor(leafNode, 'over');
      });
      leafNode.addEventListener('mouseout', () => {
        this.changeHoverColor(leafNode, 'out');
      });
      this.applyOtherStyles(leafNode, index);
      this.conditionArray.forEach((condition: any) => {
        this.applyColorToCondition(condition, leafNode, index);
      });
      if (leafNode?.className?.includes('passcolor')) {
        index--;
      }
      index++;
    });
  }

  applyOtherStyles(leafNode: HTMLElement, index: number) {
    let styleKeys: string[] = Object.keys(this.otherStyles?.[index] ? this.otherStyles[index] : {});
    styleKeys.forEach((key: string) => {
      switch (key) {
        case 'font-size':
          leafNode.style.fontSize = this.otherStyles[index][key];
          break;
        case 'font-style':
          leafNode.style.fontStyle = this.otherStyles[index][key];
          break;
        case 'font-weight':
          leafNode.style.fontWeight = this.otherStyles[index][key];
          break;
        case 'font-family':
          leafNode.style.fontFamily = this.otherStyles[index][key];
          break;
        case 'text-align':
          leafNode.style.textAlign = this.otherStyles[index][key];

        default:
          break;
      }
    });
  }
  applyColorToCondition(condition: any, leafNode: HTMLElement, index: number): void {
    if (condition.index === index) {
      this.card?.recordData?.forEach((record: any) => {
        if (record.attributeName === condition.attribute) {
          record.value = typeof condition.value === 'number' ? parseInt(record.value) : record.value;
          if (condition.condition === '==') {
            if (condition.value == record.value) {
              leafNode.style.color = condition.color;
            }
          } else if (condition.condition === '!=') {
            if (condition.value != record.value) {
              leafNode.style.color = condition.color;
            }
          } else if (condition.condition === '<=') {
            if (condition.value >= record.value) {
              leafNode.style.color = condition.color;
            }
          } else if (condition.condition === '>=') {
            if (condition.value <= record.value) {
              leafNode.style.color = condition.color;
            }
          } else if (condition.condition === '<') {
            if (condition.value > record.value) {
              leafNode.style.color = condition.color;
            }
          } else if (condition.condition === '>') {
            if (condition.value < record.value) {
              leafNode.style.color = condition.color;
            }
          }
        }
      });
    }
  }
  appendLeafNodes(node: HTMLElement, isButton: boolean): void {
    if (node?.children?.length != 0) {
      let length = node.children.length;
      for (let i = 0; i < length; i++) {
        let childElement: HTMLElement = node.children[i] as HTMLElement;
        if (childElement.classList.contains('template-btns')) this.appendLeafNodes(childElement, true);
        else this.appendLeafNodes(childElement, isButton);
      }
    } else {
      /*istanbul ignore next*/
      if (node) {
        if (!isButton) this.leafNodes.push(node);
        else this.buttonLeafNodes.push(node);
      }
      return;
    }
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: MouseEvent) {
    if (this.isEntityLevel) {
      this.htmlElement.style.backgroundColor =
        this.cardHoveredColor?.length > 0 ? this.cardHoveredColor : this.cardColor;
      event.stopPropagation();
    }
  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event: MouseEvent) {
    if (this.isEntityLevel) {
      this.htmlElement.style.backgroundColor = this.cardColor ? this.cardColor : '';
      event.stopPropagation();
    }
  }
}
