import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-auto-id-nested-entity',
  templateUrl: './nested-entity.component.html',
  styleUrls: ['./nested-entity.component.scss'],
})
export class AutoIdNestedEntityComponent implements OnInit {
  @Input() attr: any;
  @Input() attrName: any;
  @Input() entityIndex: any;
  @Input() entity: any;
  @Output() attributeEmit = new EventEmitter();
  expanded: boolean = false;
  nestedAttrName: string;
  constructor() {}

  ngOnInit(): void {
    this.nestedAttrName = this.attrName;
  }

  addAttributetoDcd() {
    this.attributeEmit.emit(this.nestedAttrName);
  }

  nestedAttributeEmit(attribute: any) {
    this.attributeEmit.emit(attribute);
  }
}
