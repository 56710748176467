import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DmSttEndpointService {
  websocketUrl: string = 'ws://192.168.1.209:9000';
  websocket!: any;
  data = new Subject();
  dataObservable = this.data.asObservable();
  mediaStream!: MediaStream;
  audioContext!: AudioContext;
  sourceNode!: MediaStreamAudioSourceNode;
  script!: ScriptProcessorNode;
  buffer: any[] = [];
  intervel: any = null;
  returnString: string = '';
  stt = new Subject();
  sttObservable = this.stt.asObservable();
  audioBufferSourceNode!: AudioBufferSourceNode;
  stopRecording: boolean;

  constructor() {}

  startStt() {
    // this.websocket = new WebSocket(this.websocketUrl);

    navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream: MediaStream) => {
      this.audioContext = new AudioContext();
      this.mediaStream = stream;
      this.sourceNode = this.audioContext.createMediaStreamSource(this.mediaStream);
      this.script = this.audioContext.createScriptProcessor(4096, 1, 1);
      this.sourceNode.connect(this.script);
      this.script.connect(this.audioContext.destination);
      this.stopRecording = false;
      /* istanbul ignore next */
      this.script.onaudioprocess = (event: AudioProcessingEvent) => {
        let inputBuffer = event.inputBuffer;
        this.resampleAudio(inputBuffer, 16000, this.audioBufferSourceNode).then((resampledBuffer: AudioBuffer) => {
          const pcmBuffer = this.convertBufferToPCM(resampledBuffer.getChannelData(0));
          const pcmBytres = new Uint8Array(pcmBuffer);
          this.buffer.push(pcmBytres);
          if (this.intervel === null) {
            this.intervel = setInterval(() => {
              const chunk = new Blob(this.buffer, { type: 'audio/wav' });
              if (this.websocket.readyState === this.websocket.OPEN) {
                this.websocket.send(chunk);
              }
              this.buffer = [];
              if (this.stopRecording) {
                clearInterval(this.intervel);
              }
            }, 5000);
          }
        });
        this.websocket.onmessage = (data: any) => {
          this.data.next(data?.data);
        };
      };
    });
  }

  // stopSttServer(){
  //   if (this.websocket.readyState === this.websocket.OPEN) {
  //     // this.websocket.close();

  //     this.sourceNode.disconnect(this.script);
  //     this.script.disconnect(this.audioContext.destination);
  //     this.audioContext.close();
  //     this.stopRecording = true;
  //   }
  // }

  stopStt() {
    /* istanbul ignore else */
    if (this.websocket.readyState === this.websocket.OPEN) {
      this.websocket.close();
    }
  }

  // start() {
  //   this.audioBufferSourceNode.start();
  // }

  // stop() {
  //   this.audioBufferSourceNode.stop();
  // }

  // send(data: string | ArrayBufferLike | Blob | ArrayBufferView | any) {
  //   this.websocket.send(data);
  // }

  resampleAudio(
    audioBuffer: AudioBuffer,
    targetSampleRate: number,
    sourceNode: AudioBufferSourceNode
  ): Promise<AudioBuffer> {
    const offlineContext = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      audioBuffer.duration * targetSampleRate,
      targetSampleRate
    );

    sourceNode = offlineContext.createBufferSource();
    sourceNode.buffer = audioBuffer;

    const filterNode = offlineContext.createBiquadFilter();
    filterNode.type = 'lowpass';
    filterNode.frequency.value = 0.5 * targetSampleRate;

    sourceNode.connect(filterNode);
    filterNode.connect(offlineContext.destination);

    sourceNode.start();
    return offlineContext.startRendering().then((renderedBuffer) => {
      return renderedBuffer;
    });
  }

  convertBufferToPCM(buffer: Float32Array): ArrayBuffer {
    const pcm = new DataView(new ArrayBuffer(buffer.length * 2));
    let index = 0;
    for (let i = 0; i < buffer.length; i++) {
      const sample = Math.max(-1, Math.min(1, buffer[i]));
      pcm.setInt16(index, sample * 0x7fff, true);
      index += 2;
    }
    return pcm.buffer;
  }
  pause() {
    this.sourceNode.disconnect(this.script);
    this.script.disconnect(this.audioContext.destination);
    this.audioContext.close();
    this.stopRecording = true;
  }
  continue() {
    // // navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream: MediaStream) => {
    // //   this.mediaStream = stream;
    // // })
    // this.audioContext = new AudioContext();

    // this.sourceNode = this.audioContext.createMediaStreamSource(this.mediaStream);
    // this.script = this.audioContext.createScriptProcessor(4096, 1, 1);
    // this.sourceNode.connect(this.script);
    //   this.script.connect(this.audioContext.destination);
    //   this.stopRecording = false;
    this.intervel = null;
    this.startStt();
  }
  startSocket(url: string) {
    this.websocket = new WebSocket(url);
    this.startStt();
  }
}
