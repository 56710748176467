import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { solutionLogicDeMapper,Gsi } from '@common-services';
import { AlertService, ChangeUnit, ChangeUnitFacadeService, Dcd, Entity, GsiFacadeService, NodeGsiFacadeService, TranslatorService, changeUnitMapper, emptyObject, gsiMapper } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Connector, DcdValue, Operator } from '../../models/connectors';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { DcdConditionsComponent } from '../dcd-conditions/dcd-conditions.component';
import { EntityToEntityDCDComponent } from '../entity-to-entity-dcd/entity-to-entity-dcd.component';
import { RcuDcdComponent } from '../rcu-dcd/rcu-dcd.component';

export interface DialogData {
  isEntityCard?: boolean;
  isGsiDCD: any;
  gsiData: any;
  changeUnit: any;
  recursive?: any;
  sendTag?: any;
  isNested?: any;
  nestedKey?: any;
  mainCU?: any;
  gsiList?: any[];
  currentSubComp?: any;
  templateMapping?: boolean;
  mapData?: any;
  entity?: Entity;
  dcdList?: Dcd[];
}

@Component({
  selector: 'app-common-dcd',
  templateUrl: './common-dcd.component.html',
  styleUrls: ['./common-dcd.component.scss'],
})
export class CommonDcdComponent implements OnInit {
  changeUnit: ChangeUnit;
  entityCardDcd: any = [];
  gsiData: any;
  targetGsiList: any[] = [];
  targetGsiListWithSolutionLogic: Gsi[] = [];
  recursive: boolean;
  sendTag: any;
  isNested: any;
  nestedKey: any;
  mainCU: any;
  map = new Map<key, values[]>();
  totalDcds: number = 0;
  targetType: 'GSI' | 'CU' | 'Collection' | 'Nested' | 'GSI_Layers' = 'CU';
  formulaString: string;
  formulaStringArray: string[] = [];
  dcdsList: any[] = [];
  addConnector: boolean = false;
  sourceTarget: 'source' | 'target' = 'source'; //intially source is selected and Target is grayed out
  event: 'ARRIVAL' | 'DEPARTURE' = 'ARRIVAL';
  sourceDcdValue: DcdValue = new DcdValue();
  targetDcdValue: DcdValue = new DcdValue();
  contextualType: 'attribute' | 'entity' | 'method';
  sourceContextualId: string = '';
  isDcdComplete: boolean;
  targetCuList: ChangeUnit[] = [];
  dcd: Dcd;
  sourceArray: any[] = [];
  targetArray: any[] = [];
  dcdArray: Dcd[] = [];
  formulaName: string;
  formulaId: string;
  activateTargetDcd: boolean;
  showTarget: boolean = false;
  collectionGsi: any;
  isEvent: boolean;
  operators: Operator[] = [
    { operator: '+', name: 'sum' },
    { operator: '-', name: 'minus' },
    { operator: '*', name: 'multiply' },
    { operator: '%', name: 'average' },
    { operator: '/', name: 'divide' },
    { operator: '(', name: 'OpenBracket' },
    { operator: ')', name: 'CloseBracket' },
    { operator: '^', name: 'power' },
    { operator: '>', name: 'greater than' },
    { operator: '>=', name: 'greater than or equal to' },
    { operator: '<', name: 'less than' },
    { operator: '<=', name: 'less than or equal to' },
  ];
  myCollection: any[] = [];
  formulaFreeText: string;
  @Input() currentComp: string;
  showOPerator: boolean = false;
  targetContextualId: any;
  flagLinkLoop: any = [];
  targetGSIList: any[] = [];
  @Input() wishGsi: any;
  ngUnsubscribe: any = new Subject();
  dcdWithoutTarget: Dcd;
  dcdTargetArray: Connector[] = [];
  searchText: string;
  pagenum: number = 0;
  pageSize: number = 10;
  isPublished: any = '';
  errorDataInDCD: any = [];
  selectedAttributePath: any = '';
  attributeUsed: any = true;
  basicOrNested: string = 'Basic';
  fetchedNested: any = [];
  nestedCU: any;
  outGoingDcd = new Array(2);
  outGoindDCDIndex: number = -1;
  targetNestedCu: any[] = [];
  layerOrNested: string = '';
  openNested: boolean;
  istarget: boolean = false;
  nestedTargetCU: any = [];
  /**
   * TODO: Impliment Conditional potentiality
   */

  //multiValueMode
  multiValueMode: string = 'APPEND';
  targetListGsi: any = [];
  openGsiLayer: boolean;
  selectedTargetGsi: any;
  embedGSIstring: string;
  sourceType: string = 'CU';
  chooseGSILayers: boolean = false;
  isSingleTriggeredNested: any = false;
  ismeasures: boolean = false;
  showMeasures: boolean = false;
  measureEntity: any;
  selectedMeasureAttributeIndex: any;
  isChildEvent: boolean = false;
  sourceChangeDriverInt: any = {
    changeDriverContextualName: '',
    changeDriverContextualId: '',
  };
  transformationInfo: any = {
    sourceChangeDriver: [],
  };
  subtransactionalDCD: any = [];
  subtransactionalCUflag = false;
  subtransactionalCU: any;
  subtransactionalGsi: any;
  isSubtransaction: boolean = false;
  isSubtransactionCUSelected: boolean = false;
  sourceCU: ChangeUnit;
  selectedCu: any;
  currentSubComp: any;
  nonStackableOperators = ['+', '-', '*', '/', '%'];
  sourceEntity: any;
  targetEntity: any;
  uploadImage: any;
  labels: any;
  nestedGsiLayers: boolean = false;
  DCDcreated : boolean = false;
  selectedSourceBET: any = {};
  selectedTargetBET: any = {};
  dcdCreated: boolean = false;
  templateMapping: boolean = false;
  mappings: any = [];
  isGsiDCD: any = false;
  RCUsFlag: boolean = false;
  isEntityCard: boolean = false;
  constructor(
    private changeUnitFacade: NodeChangeUnitFacadeService,
    private translator: TranslatorService,
    private gsiFacade: NodeGsiFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    public Dialog: MatDialog,
    public dialogRef: MatDialogRef<CommonDcdComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData,
    public alertService: AlertService
  ) {
    this.getTargetGsiData();
    this.detectTriggercuDetails();
    this.fetchEMBGsi();
    this.detectDraftGSI();
    this.getGSIDataForMyCollectionGSI();
    this.fetchEMBGsiNew();
    this.appendSubtransactionalDCD();
    this.getSubtransactionCUCondition();
    this.detectLanguageChange();
    // this.getDCDvalidation();
  }

  prepareDCDDataForEntityCard(): void {
    this.isEntityCard = true;
    if (!(this.targetGsiList.length > 0)) {
      this.targetGsiList = this.dialog.gsiList;
      this.getGsiDataFromList();
    }
    this.sourceDcdValue.layerType = 'physical';
    let entity: Entity = this.dialog?.entity;
    this.sourceDcdValue.participatingItems = [entity];
    this.sourceDcdValue.entityIndex = 0;
    this.sourceDcdValue.nslAttributes = entity.nslAttributes;
    this.sourceDcdValue.selectedEntity = entity;
  }

  getGsiDataFromList() {
    this.targetGsiList.forEach((gsi: any) => {
      this.getGsiDetails(gsi);
    });
  }
  /* istanbul ignore next */
  updateDCDsData() {
    if (this.dialog?.isEntityCard) {
      this.prepareDCDDataForEntityCard();
      if (this.dialog.dcdList) this.entityCardDcd = this.dialog.dcdList;
      return;
    }
    this.changeUnit = this.dialog?.changeUnit;
    this.gsiData = this.dialog?.gsiData;
    this.recursive = this.dialog?.recursive;
    this.sendTag = this.dialog?.sendTag;
    this.isNested = this.dialog?.isNested;
    this.nestedKey = this.dialog?.nestedKey;
    this.mainCU = this.dialog?.mainCU;
    this.selectedCu = this.dialog?.changeUnit?.name;
    this.currentSubComp = this.dialog?.currentSubComp;
    this.templateMapping = this.dialog?.templateMapping ? this.dialog?.templateMapping : false;
    if (this.templateMapping) {
      this.sourceDcdValue.layerType = 'physical';
      this.sourceDcdValue.participatingItems = this.dialog.mapData?.sourceEntities;
      this.targetDcdValue.selectedChangeUnit.layers = [
        { type: 'triggerCES', participatingItems: this.dialog.mapData?.targetEntities },
      ];
      this.targetDcdValue.layerType = 'triggerCES';
      this.targetDcdValue.participatingItems = this.dialog.mapData?.targetEntities;
      this.mappings = this.dialog?.mapData?.map;
    }
  }
  ngOnInit(): void {
    this.updateDCDsData();
    if (!this.isEntityCard) {
      this.checkIfDraft();
      this.detectActiveGSI();

      if (this.nestedKey) {
        let obj = {
          sourceCUContextualId: this.nestedKey,
          sourceCUContextualName: this.nestedKey,
        };
        /*istanbul ignore next*/
        if (this.mainCU?.nestedOutgoingDCDs?.length > 0) {
          for (let i = 0; i < this.mainCU?.nestedOutgoingDCDs?.length; i++) {
            if (this.mainCU?.nestedOutgoingDCDs[i][0].sourceCUContextualId === this.nestedKey) {
              this.outGoindDCDIndex = i;
              break;
            }
          }
        }
        if (this.outGoindDCDIndex == -1) {
          this.outGoingDcd[0] = obj;
          this.outGoingDcd[1] = [];
        } else {
          /*istanbul ignore next*/
          this.outGoingDcd[0] = this.mainCU?.nestedOutgoingDCDs[this.outGoindDCDIndex][0];
          /* istanbul ignore next*/
          this.outGoingDcd[1] = this.mainCU?.nestedOutgoingDCDs[this.outGoindDCDIndex][1];
        }
      }
      if (this.nestedTargetCU[0] == undefined) {
        this.nestedTargetCU.push('');
      }
    }
    this.dcd = {} as Dcd;
    /*istanbul ignore else*/
    if (this.changeUnit?.dcd || this.outGoindDCDIndex >= 0 || this.isEntityCard) {
      this.generateDCDSourceArray();
    }
    /*istanbul ignore next*/
    if (this.gsiData?.solutionLogic && this.gsiData?.solutionLogic?.length > 0 && !this.isEntityCard) {
      this.gsiData?.solutionLogic?.forEach((cu: ChangeUnit) => {
        /*istanbul ignore else*/
        if (cu.mindCUList && cu.mindCUList.length > 0) {
          cu?.mindCUList?.forEach((mindcu: any) => {
            if (mindcu?.isSingleTrigger) {
              this.isSingleTriggeredNested = true;
            } else {
              this.getNestedGsiData(mindcu.dsdGsiId);
            }
          });
        }
        /*istanbul ignore next*/
        if (cu?.id !== this.changeUnit?.id || this.recursive) {
          /*istanbul ignore else*/
          if (cu?.solutionLogic) {
            this.targetGSIList.push(cu);
          }
        } else {
          this.targetCuList = [];
          this.flagLinkLoop = [];
          this.findNextTriggerSetCU(cu);
        }
      });
    }
    this.checkIfParallel();
    this.getWishGsi();
    /*istanbul ignore next*/
    if (this.changeUnit) {
      this.sourceCU = JSON.parse(JSON.stringify(this.changeUnit));
    }
    /*istanbul ignore next*/
    if (this.changeUnit?.cuType === 'GSI') this.getEmbeddedGSIDatanew();
  }

  getEmbeddedGSIDatanew() {
    this.gsiFacade.fetchEmbeddedGsi(this.changeUnit?.referencedChangeUnit);
  }
  fetchEMBGsiNew() {
    this.gsiFacade.embededGsiDCD$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.changeUnit.solutionLogic = res.solutionLogic;
        console.log(this.changeUnit);
      }
    });
  }
  getTargetEmbeddedGSIData() {
    if (this.targetDcdValue?.selectedGsi?.referencedChangeUnit) {
      this.gsiFacade.fetchTargetEmbeddedGsi(this.targetDcdValue?.selectedGsi?.referencedChangeUnit);
    }
  }
  detectActiveGSI() {
    this.gsiFacade.activeEmbGsiData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        if (this.gsiData == undefined && res != '') {
          this.gsiData = res;
        }
      }
    });
  }
  checkIfDraft() {
    /* istanbul ignore next */
    if (this.gsiData?.status === 'READY') {
      this.changeUnitFacade.cancelReviewEmbed('gsi', this.gsiData);
    }
  }
  detectDraftGSI() {
    this.changeUnitFacade.gsiDetailsDraft$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.gsiData = {
          ...this.gsiData,
          ...res,
        };
      }
    });
  }
  parseRecievedTag() {
    /* istanbul ignore next */
    let len = this.sendTag?.errorData?.length;
    let reqData;
    if (len >= 1) {
      let reqData = this.sendTag?.errorData[len - 1][0];

      /*istanbul ignore next*/
      if (reqData?.errorType === 'DCD') {
        reqData.data.forEach((errorData: any) => {
          this.errorDataInDCD.push({
            index: parseInt(errorData.contextualId),
            message: errorData.errorDetails[0].message,
          });
          let index = parseInt(errorData?.contextualId);
          this.dcdsList[index - 1]['hasError'] = true;
          this.dcdsList[index - 1]['errorMessage'] = errorData?.errorDetails[0]?.message;
        });
      }
    }
  }
  checkIfParallel() {
    /* istanbul ignore next */
    if (this.changeUnit?.isParallel || this.changeUnit?.parentParallelCu) {
      /* istanbul ignore next */
      this.gsiData?.solutionLogic?.forEach((cu: any) => {
        /* istanbul ignore next */
        cu?.nextTriggerSet?.forEach((item: any) => {
          /* istanbul ignore next */
          if (item?.nextCUId == this.changeUnit?.id || item?.nextCUId == this.changeUnit?.parentParallelCu?.id) {
            this.targetCuList.push(cu);
            this.findNextTriggerSetCU(cu);
          }
        });
      });
      /* istanbul ignore next */
      this.targetCuList = this.targetCuList?.filter((x: any) => !x?.isParallel); // to make sure none of the parallel CUs come in the target CU list when selected CU is parallel
    }
    /* istanbul ignore next */
    // let mainCUIndex = this.targetCuList?.findIndex((x: any) => x?.name == this.changeUnit?.name);
    // if (mainCUIndex != -1) {
    //   this.targetCuList.splice(mainCUIndex, 1);
    // }
  }
  getWishGsi(): void {
    /* istanbul ignore next */
    if (this.targetGSIList?.length <= 0 && this.currentComp == 'SOP') {
      this.targetGSIList.push(this.wishGsi);
    }
  }

  enableTarget(): void {
    /* istanbul ignore next */
    if ((this.dcd?.sourceContextualId || this.dcd?.transformationInfo) && !this.showTarget) {
      /* istanbul ignore next */
      if (this.changeUnit?.cuType == 'GSI' && this.changeUnit?.level == 1) {
        // generate Target Cu List of the current GSI for DCD from GSI Level layers to inside CUs
        /* istanbul ignore next */
        this.findNextTriggerSetCU(this.changeUnit?.solutionLogic?.[0]);
      } else if (this.isNested) {
        this.findNextTriggerSetCU(this.mainCU);
      } else {
        this.findNextTriggerSetCU(this.changeUnit);
      }
      if (this.isSubtransaction || this.isGsiDCD) {
        this.targetCuList.push(this.subtransactionalCU);
      }
      this.showTarget = true;
    }
  }

  getTargetGsiData(): void {
    this.gsiFacade.nestedCuDetailsByGsiId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      let gsiData = { ...res, nestedGsi: true };
      this.targetGSIList.push(gsiData);
    });
  }

  findNextTriggerSetCU(triggerCu: any): void {
    /* istanbul ignore next */
    let cuFoundInTarget = this.targetCuList?.findIndex((x: any) => x?.name === triggerCu?.name);
    /* istanbul ignore next */
    if (cuFoundInTarget === -1 && triggerCu?.cuType != 'GSI') {
      this.targetCuList.push(triggerCu);
    }
    /* istanbul ignore next */
    triggerCu?.nextTriggerSet?.forEach((cu: any) => {
      /* istanbul ignore next */
      let index = this.flagLinkLoop?.findIndex((x: any) => x === cu?.nextCUId);
      /* istanbul ignore else */
      if (index === -1) {
        /* istanbul ignore next */
        this.flagLinkLoop.push(cu?.nextCUId);
        this.findActualCu(cu);
      }
    });
  }

  findActualCu(ChangeUnit: any): void {
    /* istanbul ignore next */
    let cu: ChangeUnit = this.gsiData?.solutionLogic?.find((cu: ChangeUnit) => {
      return cu?.id === ChangeUnit?.nextCUId;
    });
    /* istanbul ignore next */
    if (cu && cu?.cuType != 'GSI') {
      /* istanbul ignore next */
      let cuFoundInTarget = this.targetCuList?.findIndex((x: any) => x?.id === cu?.id);
      /*istanbul ignore else*/
      if (cuFoundInTarget === -1) {
        this.targetCuList.push(cu);
      }
      this.findNextTriggerSetCU(cu);
    } else if (cu && cu?.cuType == 'GSI') {
      this.findNextTriggerSetCU(cu);
    }
  }

  generateDCDSourceArray(): void {
    this.totalDcds = 0;
    this.formulaStringArray = [];
    /* istanbul ignore next */
    let dcdList = this.isNested ? this.outGoingDcd?.[1] : this.isGsiDCD ? this.gsiData?.dcd : this.changeUnit?.dcd;
    dcdList = this.isEntityCard ? this.entityCardDcd : dcdList;
    dcdList?.forEach((dcd: any) => {
      dcd.isInDCDGroup = false;
      /* istanbul ignore next */
      if (dcd?.dcds) {
        dcd?.dcds.forEach((ndcd: any) => {
          this.formDCDData(ndcd);
        });
      } else {
        this.formDCDData(dcd);
      }
    });
    this.dcdsList = [...dcdList];
    if (this.dcdsList.length > 0 && this.sendTag != undefined) {
      this.parseRecievedTag();
    }
  }
  formDCDData(dcd: any) {
    dcd.isEntity = true;
    /*istanbul ignore next*/
    if (dcd?.formulaName?.split('.').length == 4) {
      dcd.isEntity = false;
    }
    dcd.sourceArray = [];
    let seperators = ['\\+', '\\(', '\\)', '\\*', '/','\\^','\\<=','\\>=','\\%'];
    if (localStorage.getItem('locale') != 'FR') seperators.push('-');
    /*istanbul ignore next*/
    if (dcd?.formula == '') {
      dcd.sourceArray.push({ name: dcd.sourceContextualName });
    } else if (dcd?.transformationInfo?.sourceChangeDriver) {
      /* istanbul ignore next */
      dcd.sourceArray.push({ name: dcd?.transformationInfo?.reservedCUName });
    } else {
      let tokens = dcd?.formulaName?.split(new RegExp(seperators.join('|'), 'g'));
      tokens?.forEach((token: any) => {
        /*istanbul ignore else*/
        if (token) {
          dcd.sourceArray.push({ name: token });
        }
      });
    }
    /*istanbul ignore next*/
    if (dcd?.formulaName) {
      dcd.formulaArray = this.generateFormulaArray(dcd.formulaName);
    }
    /*istanbul ignore else*/
    if (!dcd.isDeleted) {
      this.totalDcds++;
    }
  }
  generateFormulaArray(formulaName: string): any[] {
    const stringArray = Array.from(formulaName);
    let eachString = '';
    let splitArray = [];

    /*istanbul ignore next*/
    for (let index = 0; index < stringArray?.length; index++) {
      /*istanbul ignore next*/
      if (
        index + 1 < stringArray?.length &&
        this.operators?.some((opt) => opt?.operator === stringArray[index] + stringArray[index + 1])
      ) {
        splitArray.push(eachString);
        eachString = '';
        splitArray.push(stringArray[index] + stringArray[index + 1]);
        index = index + 1;
      } else if (this.operators?.some((opt) => opt?.operator === stringArray[index])) {
        splitArray.push(eachString);
        eachString = '';
        splitArray.push(stringArray[index]);
      } else {
        eachString += stringArray[index];
      }
    }

    /*istanbul ignore else*/
    if (/^[a-zA-Z0-9- ,_]*$/.test(formulaName) == false) {
      splitArray.push(eachString);
    }
    this.checkForSplit(splitArray);
    return (splitArray = splitArray.filter((entry) => entry.trim() != ''));
  }

  checkForSplit(formularray: any): void {
    this.formulaString = '';
    /*istanbul ignore next*/
    if (formularray) {
      /*istanbul ignore next*/
      formularray?.forEach((data: any) => {
        /*istanbul ignore next*/
        const label = data?.split('.');
        /*istanbul ignore next*/
        let temp = label?.[label?.length - 1];
        this.formulaString += temp;
      });
      this.formulaStringArray.push(this.formulaString);
    }
  }

  // delete connectors based on Index
  deleteConnector(index: number, subtransactionalCUflag?: any): void {
    /*istanbul ignore else*/
    if (index >= 0 && this.dcdsList && this.dcdsList.length > index && !subtransactionalCUflag) {
      if (this.isNested) {
        this.outGoingDcd[1][index].isDeleted = true;
        this.outGoingDcd[1].splice(index, 1);
      } else {
        /* istanbul ignore next */
        if (this.changeUnit?.dcd?.[index]) {
          this.changeUnit.dcd[index].isDeleted = true;
        }
        /* istanbul ignore next */
        this.changeUnit?.dcd?.splice(index, 1);
      }
      this.totalDcds--;
      this.dcdsList.splice(index, 1);
    } else if (
      index >= 0 &&
      this.subtransactionalDCD &&
      this.subtransactionalDCD?.length > index &&
      subtransactionalCUflag
    ) {
      /* istanbul ignore next */
      this.subtransactionalDCD.splice(index, 1);
    }
  }

  closeConnectors(): void {
    this.dialogRef?.close({});
  }
  //----------------------------------------------------------------save Gsi with updated Connectors --------------------------------
  saveConnectors(): void {
    if (this.isEntityCard) {
      this.dialogRef?.close({ dcds: this.entityCardDcd });
      return;
    }
    this.gsiFacadeService.subtransactionalDCDSave(this.subtransactionalDCD);

    this.dialogRef?.close({
      dcdsList: this.dcdsList,
      outGoindDCDIndex: this.outGoindDCDIndex,
      outGoingDcd: this.outGoingDcd,
    });
  }

  AddConnector(): void {
    this.addConnector = true;
  }

  //change layer type when mat-select is changed
  changeLayer(layerType: string, isMycollection?: boolean): void {
    /*istanbul ignore else*/
    if (layerType && isMycollection) {
      /*istanbul ignore next*/
      this.sourceDcdValue.selectedChangeUnit?.layers?.forEach((layer: any) => {
        /*istanbul ignore else*/
        if (layerType === layer.type) {
          this.sourceDcdValue.participatingItems = layer?.participatingItems;
        }
      });
    } else {
      /*istanbul ignore next*/
      const bet = this.isGsiDCD ? this.gsiData : this.sourceCU;
      bet?.layers?.forEach((layer: any) => {
        /*istanbul ignore else*/
        if (layerType === layer.type) {
          this.sourceDcdValue.participatingItems = layer?.participatingItems;
        }
      });
    }
  }

  changeEntity(index: number): void {
    /*istanbul ignore else*/
    if (index >= 0) {
      this.attributeUsed = false;
      /* istanbul ignore next */
      let entity: Entity = this.sourceDcdValue?.participatingItems[index]; //selects entity from the participatingItems
      this.sourceDcdValue.selectedAttribute = [];
      this.sourceDcdValue.selectedEntity = entity;
      this.selectedSourceBET = entity;
      this.sourceDcdValue.nslAttributes = [];
      /* istanbul ignore next*/
      this.sourceDcdValue.nslAttributes = entity?.nslAttributes;
      this.selectedSourceBET = entity;
    }
  }

  changeAttribute(entity: any, attrIndex: number) {
    this.setFalseAll(entity, attrIndex);
    this.selectedSourceBET = entity?.nslAttributes?.[attrIndex]?.generalEntity;
    /*istanbul ignore else*/
    if (!entity.nslAttributes[attrIndex].generalEntity) {
      this.attributeUsed = true;
      this.sourceDcdValue.selectedAttribute = entity.nslAttributes[attrIndex];
    }
    this.findMeasures(entity?.nslAttributes[attrIndex]?.id);
  }

  setFalseAll(entity: any, attrIndex: any) {
    entity?.nslAttributes.forEach((ele: any, index: any) => {
      ele.isSelected = false;
      /*istanbul ignore else*/
      if (ele?.id === entity?.nslAttributes?.[attrIndex]?.id) {
        ele.isSelected = true;
      }
    });
  }

  generateSourceAttrPath(entity: any) {
    entity?.nslAttributes?.forEach((ele: any, index: any) => {
      /*istanbul ignore else*/
      if (ele.isSelected) {
        if (this.sourceDcdValue.attrPath == '') {
          /* istanbul ignore next */
          this.sourceDcdValue.attrPath += ele?.name;
        } else {
          /* istanbul ignore next */
          this.sourceDcdValue.attrPath += '.' + ele?.name;
        }
      }
      /*istanbul ignore else*/
      if (ele.generalEntity && ele.isSelected) {
        this.generateSourceAttrPath(ele.generalEntity);
      }
    });
  }

  generateTargetAttrPath(entity: any) {
    entity?.nslAttributes?.forEach((ele: any, index: any) => {
      /*istanbul ignore else*/
      if (ele.isSelected) {
        if (this.targetType == 'Nested') {
          /* istanbul ignore next */
          if (this.targetDcdValue.selectedNestedCU.attrPath == '') {
            /* istanbul ignore next */
            this.targetDcdValue.selectedNestedCU.attrPath += ele?.name;
          } else {
            /* istanbul ignore next */
            this.targetDcdValue.selectedNestedCU.attrPath += '.' + ele?.name;
          }
        } else {
          if (this.targetDcdValue.attrPath == '') {
            /* istanbul ignore next */
            this.targetDcdValue.attrPath += ele?.name;
          } else {
            /* istanbul ignore next */
            this.targetDcdValue.attrPath += '.' + ele?.name;
          }
        }
      }
      /*istanbul ignore else*/
      if (ele.generalEntity) {
        this.generateTargetAttrPath(ele.generalEntity);
      }
    });
  }

  removeAllIsSelected(entity: any) {
    entity?.nslAttributes?.forEach((ele: any, index: any) => {
      /* istanbul ignore next */
      if (ele?.isSelected) {
        delete ele.isSelected;
      }
      /*istanbul ignore else*/
      if (ele.generalEntity) {
        this.removeAllIsSelected(ele.generalEntity);
      }
    });
  }

  addSource(): void {
    this.DCDcreated = false;
    /*istanbul ignore next*/
    let layerType: string = this.sourceDcdValue?.layerType;
    /*istanbul ignore next*/
    let entityIndex: number = parseInt(this.sourceDcdValue?.entityIndex?.toString()); //parseInt is necessary because sometimes at runtimethe entity index is string
    /*istanbul ignore next*/
    if (!this.dcd?.transformationInfo && entityIndex == -1) {
      this.alertService.showToaster('select Entity or Attribute', '', 'warning');
      return;
    }
    else if(!this.dcd?.transformationInfo && entityIndex != -1){
      /*istanbul ignore next*/
      let entityName: string = this.sourceDcdValue?.selectedEntity?.name;
      this.sourceEntity = JSON.parse(JSON.stringify(this.sourceDcdValue?.selectedEntity));
      /*istanbul ignore next*/
      // let attributeName = this.sourceDcdValue?.selectedAttribute?.name;
      /*istanbul ignore next*/
      let cuName: string = this.isEntityCard
        ? ''
        : this.sourceDcdValue?.selectedChangeUnit?.name
        ? this.sourceDcdValue?.selectedChangeUnit?.name
        : this.sourceCU?.name;
      /*istanbul ignore next*/
      let cuIndex: number = this.sourceDcdValue?.selectedChangeUnit?.index
        ? this.sourceDcdValue?.selectedChangeUnit?.index
        : this.sourceCU?.index;
      this.sourceDcdValue.attrPath = '';
      this.generateSourceAttrPath(this.sourceDcdValue);
      /* istanbul ignore next */
      let attributeName = this.sourceDcdValue?.attrPath;
      this.removeAllIsSelected(this.sourceDcdValue);
      /*istanbul ignore next*/
      this.contextualType = attributeName ? 'attribute' : 'entity';
      /*istanbul ignore next*/
      if (this.sourceCU?.cuType === 'GSI' && !this.chooseGSILayers) {
        this.embedGSIstring = this.createEmbedGSIId();
      }
      if (this.isGsiDCD) {
        cuName = '';
        cuIndex = null;
      }
      let sourceContextualId = this.isSubtransaction
        ? this.generateContextualId(
            cuName,
            cuIndex,
            layerType,
            entityIndex,
            entityName,
            attributeName,
            this.subtransactionalGsi,
            this.sourceCU
          )
        : this.generateContextualId(
            cuName,
            cuIndex,
            layerType,
            entityIndex,
            entityName,
            attributeName,
            this.gsiData,
            this.sourceCU
          );
      /* istanbul ignore next */
      this.sourceContextualId = this.isNested
        ? this.generateNestedSource(this.sourceCU?.name, layerType, entityIndex, entityName, attributeName)
        : sourceContextualId;
      let dcd: Connector = new Connector(
        this.sourceContextualId,
        this.contextualType,
        this.sourceContextualId,
        attributeName,
        attributeName
      );
      dcd.formulaId = this.generateFormulaId(layerType, entityIndex, entityName, attributeName);
      dcd.formulaIdWithId = this.generateFormulaId(layerType, entityIndex, entityName, attributeName);
      this.isDcdComplete = true;
      this.createDcd(dcd);
      /* istanbul ignore next */
    } else if (this.dcd?.transformationInfo) {
      this.isDcdComplete = true;
      this.createDcd(this.dcd);
    }
    /*istanbul ignore next*/
    let entityName: string = this.sourceDcdValue?.selectedEntity?.name;
    this.sourceEntity = JSON.parse(JSON.stringify(this.sourceDcdValue?.selectedEntity));
    /*istanbul ignore next*/
    // let attributeName = this.sourceDcdValue?.selectedAttribute?.name;
    /*istanbul ignore next*/
    let cuName: string = this.sourceDcdValue?.selectedChangeUnit?.name
      ? this.sourceDcdValue?.selectedChangeUnit?.name
      : this.sourceCU?.name;
    /*istanbul ignore next*/
    let cuIndex: number = this.sourceDcdValue?.selectedChangeUnit?.index
      ? this.sourceDcdValue?.selectedChangeUnit?.index
      : this.sourceCU?.index;
    this.sourceDcdValue.attrPath = '';
    this.generateSourceAttrPath(this.sourceDcdValue);
    /* istanbul ignore next */
    let attributeName = this.sourceDcdValue?.attrPath;
    this.removeAllIsSelected(this.sourceDcdValue);
    /*istanbul ignore next*/
    this.contextualType = this.sourceDcdValue?.selectedAttribute?.name ? 'attribute' : 'entity';
    /*istanbul ignore next*/
    if (this.sourceCU?.cuType === 'GSI' && !this.chooseGSILayers) {
      this.embedGSIstring = this.createEmbedGSIId();
    }
    /*istanbul ignore else*/
    if (!this.templateMapping) {
      this.sourceDcdValue = new DcdValue();
      this.targetDcdValue = new DcdValue();
      this.targetDcdValue.selectedNestedCU = new DcdValue();
    }
    this.enableTarget();
    this.sourceType = 'CU';
    //  this.isEvent = false;
  }

  generateNestedSource(
    cuName: string,
    layerType: string,
    entityIndex: number,
    entityName: string,
    attributeName: string
  ) {
    let contextualId = '';
    let dot = '.';
    let colon = ':';
    let slot = 'slot';
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    contextualId = cuName + dot + layerType + dot + slot + colon + (entityIndex + 1) + dot + entityName;
    /*istanbul ignore else*/
    if (attributeName) {
      contextualId = contextualId + dot + attributeName;
    }

    return contextualId;
  }

  createEmbedGSIId(): string {
    return this.changeUnit?.name + ':' + this.changeUnit?.index + '.';
  }
  generateContextualId(
    cuName: string,
    cuIndex: number,
    layerType: string,
    entityIndex: number,
    entityName: string,
    attributeName: string,
    tempGsiObj: any,
    currentCuData: ChangeUnit,
    targetgsiName?: any,
    nestedCUName?: any,
    nestedIndex?: any
  ) {
    let dot = '.';
    let colon = ':';
    let slot = 'slot';
    let gsiName;
    let nestedSSA = 'nestedSSA';
    if (this.isEntityCard) {
      cuName = cuName ? cuName : '';
    }
    /*istanbul ignore next*/
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    /*istanbul ignore next*/
    if (targetgsiName) {
      let foundGsiData = tempGsiObj?.solutionLogic?.find(
        (x: any) => x.referencedChangeUnit === targetgsiName?.referencedChangeUnit && x?.index == targetgsiName?.index
      );
      if (foundGsiData) {
        let gsiIndex = foundGsiData.index;
        /* istanbul ignore next */
        gsiName = tempGsiObj.name + dot + targetgsiName?.name + colon + gsiIndex;
      } else {
        /* istanbul ignore next */
        gsiName = targetgsiName?.name;
      }
    } else {
      /* istanbul ignore next */
      gsiName = this.sourceDcdValue?.selectedGsi?.name ? this.sourceDcdValue?.selectedGsi?.name : tempGsiObj?.name;
    }

    entityIndex = this.convertStrToNum(entityIndex);

    let contextualId = '';
    /* istanbul ignore next */
    if (cuIndex) {
      if (this.embedGSIstring) {
        contextualId =
          gsiName +
          dot +
          this.embedGSIstring +
          cuName +
          colon +
          cuIndex +
          dot +
          this.sourceDcdValue.layerType +
          dot +
          slot +
          colon +
          (entityIndex + 1) +
          dot +
          entityName;
      } else {
        contextualId =
          gsiName +
          dot +
          cuName +
          colon +
          cuIndex +
          dot +
          layerType +
          dot +
          slot +
          colon +
          (entityIndex + 1) +
          dot +
          entityName;
      }
      this.embedGSIstring = '';
    } else if (this.targetType != 'Nested') {
      contextualId = gsiName + dot + layerType + dot + slot + colon + (entityIndex + 1) + dot + entityName;
    }
    if (cuIndex && this.targetType == 'Nested' && this.isSingleTriggeredNested) {
      contextualId =
        gsiName +
        dot +
        cuName +
        colon +
        cuIndex +
        dot +
        nestedSSA +
        colon +
        nestedCUName +
        colon +
        1 +
        dot +
        layerType +
        dot +
        slot +
        colon +
        (entityIndex + 1) +
        dot +
        entityName;
    }
    if (
      (cuIndex && this.targetType == 'Nested' && !this.isSingleTriggeredNested) ||
      (cuIndex && this.targetDcdValue?.nestedGsi?.id && this.targetType == 'GSI_Layers')
    ) {
      contextualId =
        gsiName +
        dot +
        cuName +
        colon +
        cuIndex +
        dot +
        nestedSSA +
        colon +
        nestedCUName +
        // colon +
        // nestedIndex +
        dot +
        layerType +
        dot +
        slot +
        colon +
        (entityIndex + 1) +
        dot +
        entityName;
    }
    /*istanbul ignore else*/
    if (attributeName) {
      contextualId = contextualId + dot + attributeName;
    }
    /* istanbul ignore next*/
    if (targetgsiName?.nestedGsi) {
      /* istanbul ignore next */
      gsiName = tempGsiObj?.name;
      /* istanbul ignore next */
      let foundCu = tempGsiObj?.solutionLogic?.find(
        (x: any) => x?.referencedChangeUnit === currentCuData?.referencedChangeUnit
      );
      /*istanbul ignore else*/
      if (foundCu) {
        /* istanbul ignore next */
        cuName = foundCu?.name;
        /* istanbul ignore next */
        const actualIndex = foundCu?.index;
        contextualId = gsiName + dot + cuName + colon + actualIndex + dot + 'nestedSSA' + colon + contextualId;
      }
    }
    /*istanbul ignore next */
    if (this.showMeasures) {
      contextualId = contextualId + dot + 'ME' + dot + this.measureEntity?.name + dot + this.measureEntity.name;
      // this.showMeasures = false;
    }
    /*istanbul ignore next */
    if (this.ismeasures && this.selectedMeasureAttributeIndex != -1) {
      contextualId = contextualId + dot + this.measureEntity?.nslAttributes[this.selectedMeasureAttributeIndex]?.name;
      // this.ismeasures = false;
    }

    return contextualId;
  }
  generateFormulaId(
    layerType: string,
    entityIndex: any,
    entityName: string,
    attributeName: string,
    cuName?: string,
    gsiName?: string,
    isForDependent?: boolean,
    isEntityname?: boolean
  ): string {
    /* istanbul ignore next */
    if (typeof entityIndex == 'string') {
      entityIndex = parseInt(entityIndex);
    }
    let indexPadding: string = this.getIndexPadding(entityIndex + 1);
    let dot = '.';
    let layerShortCut: string = 'PL.SL' + indexPadding;
    if (layerType == 'information') {
      layerShortCut = 'IL.SL' + indexPadding;
    } else if (layerType == 'triggerCES') {
      /*istanbul ignore else*/
      layerShortCut = 'triggerCES.SL' + indexPadding;
    }
    /* istanbul ignore next*/
    let formulaId =
      isForDependent && !isEntityname ? layerShortCut + dot + 'EN' + entityName : layerShortCut + dot + entityName;
    /*istanbul ignore else*/
    if (cuName) {
      formulaId = 'CU' + dot + cuName + dot + formulaId;
    }
    /*istanbul ignore else*/
    if (attributeName) {
      formulaId = formulaId + dot + attributeName;
    }
    if (this.showMeasures) {
      formulaId = formulaId + dot + 'ME' + dot + this.measureEntity?.name + dot + this.measureEntity?.name;
      this.showMeasures = false;
    }
    if (this.ismeasures && this.selectedMeasureAttributeIndex != -1) {
      formulaId = formulaId + dot + this.measureEntity?.nslAttributes[this.selectedMeasureAttributeIndex]?.name;
      this.ismeasures = false;
    }
    return formulaId;
  }

  createDcd(res: any, diffEntDCD?: Dcd): void {
    if (this.isDcdComplete) {
      this.layerOrNested = 'Layers';
      this.dcd = {} as Dcd;
      this.dcd.sourceArray = [];
      this.dcd.formulaArray = [];
      this.dcd.sourceContextualId = '';
      this.dcd.targetContextualId = '';
      this.dcd.targetContextualName = '';
      this.dcd.sourceContextualName = '';
      this.dcd.isDeleted = false;
      this.dcd.event = this.event;
      this.dcd.multiValueMode = this.multiValueMode;
      /* istanbul ignore next */
      if (this.RCUsFlag || (res?.transformationInfo && !emptyObject(res.transformationInfo))) {
        this.dcd.transformationInfo = res?.transformationInfo;
        this.sourceArray.push({ formulaId: res?.transformationInfo.reservedCUName, formulaIdWithId: res?.transformationInfo.reservedCUName });
      }else{
        this.sourceArray.push({ formulaId: res?.formulaId, formulaIdWithId: res?.formulaIdWithId });
      }
      if (this.contextualType == 'attribute') {
        this.dcd.sourceArray.push({ name: res.formulaId, id: res.formulaIdWithId });
        this.dcd.sourceContextualName = res.contextualId;
        this.dcd.sourceContextualId = res.contextualIdWithId;
        this.formulaName = res.formulaId;
        this.formulaId = res.formulaIdWithId;
      } else if (this.contextualType == 'entity' || this.contextualType == 'method') {
        /*istanbul ignore else*/
        this.dcd.sourceContextualName = res.contextualId;
        this.dcd.sourceContextualId = res.contextualIdWithId;
        this.dcd.formulaName = res.formulaId;
        this.dcd.formula = res.formulaIdWithId;
        this.dcd.sourceArray.push({ name: res.formulaId, id: res.formulaIdWithId });
        this.formulaName = res.formulaId;
        this.formulaId = res.formulaIdWithId;
      }
      this.isDcdComplete = false;
    } else if (!this.isDcdComplete && this.activateTargetDcd) {
      if (diffEntDCD?.entityDCDMap && !emptyObject(diffEntDCD?.entityDCDMap)) {
        this.dcd.entityDCDMap = diffEntDCD?.entityDCDMap;
      }
      if (diffEntDCD?.isDifferentEntityDcd) {
        this.dcd.isDifferentEntityDcd = diffEntDCD?.isDifferentEntityDcd;
      }
      let tempDcd: Dcd = JSON.parse(JSON.stringify(this.dcd));
      if (this.isEvent && this.dcd && this.dcd?.sourceContextualId && !this.isChildEvent) {
        let sourceId;
        const phyIndex = this.dcd.sourceContextualId.indexOf('.physical');
        if (phyIndex !== -1) {
          [sourceId] = this.dcd.sourceContextualId.split('.physical');
        } else {
          const inforIndex = this.dcd.sourceContextualId.indexOf('.information');
          if (inforIndex !== -1) {
            [sourceId] = this.dcd.sourceContextualId.split('.information');
          } else {
            const triggerIndex = this.dcd.sourceContextualId.indexOf('.triggerCES');
            /*istanbul ignore else */
            if (triggerIndex !== -1) {
              [sourceId] = this.dcd.sourceContextualId.split('.triggerCES');
            }
          }
        }
        /* istanbul ignore next */
        if (tempDcd !== undefined && tempDcd !== null) {
          tempDcd.targetContextualId = `${sourceId}.NSLEVENT:${res.contextualIdWithId}`;
          tempDcd.targetContextualName = `${sourceId}.NSLEVENT:${res.contextualId}`;
        }
        this.isEvent = false;
      } else {
        /* istanbul ignore next */
        if (tempDcd !== undefined && tempDcd !== null) {
          tempDcd.targetContextualName = res.contextualId;
          tempDcd.targetContextualId = res.contextualIdWithId;
          if (this.isChildEvent && tempDcd?.sourceContextualId) {
            tempDcd.sourceContextualId = `NSLEVENT:${tempDcd.sourceContextualId}`;
            tempDcd.sourceContextualName = `NSLEVENT:${tempDcd.sourceContextualName}`;
          }
        }
        this.targetArray.push({ formulaId: res.formulaId, formulaIdWithId: res.formulaIdWithId });
      }
      this.dcdArray.push(tempDcd);
      this.DCDcreated = true;
    } else if (!this.isDcdComplete && !this.activateTargetDcd) {
      /*istanbul ignore else*/
      this.dcd.sourceArray.push({ name: res.formulaId, id: res.formulaIdWithId });
    }
  }

  validateDcd(): boolean {
    let flag = false;
    /* istanbul ignore next */
    if (!this.dcd?.targetContextualName) {
      return flag;
    }
    if (this.contextualType == 'entity') {
      this.dcd.formula = '';
      this.dcd.formulaName = '';
      flag = true;
    } else if (this.contextualType == 'attribute') {
      flag = true;
    } else {
      flag = true;
    }
    return flag;
  }

  containsObject(obj: string, list: string[]) {
    var x;
    /* istanbul ignore next */
    for (let i = 0; i < list?.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  }

  addToFormula(res: any, flag: boolean): void {
    if (this.contextualType == 'method' || res.isDld === true) {
      this.dcd.formulaName = res.formula;
      this.dcd.formula = res.formula;
      /* istanbul ignore next*/
      this.dcd.formulaArray = res?.formulaArray;
    } else {
      let formulaName = '';
      let formula = '';
      /*istanbul ignore else*/
      if (this.dcd.formula) {
        formulaName = this.dcd.formulaName;
        formula = this.dcd.formula;
      }
      if (flag) {
        if (
          this.containsObject(
            this.dcd?.formulaArray[this.dcd?.formulaArray?.length - 1]?.operator,
            this.nonStackableOperators
          ) &&
          this.containsObject(res, this.nonStackableOperators)
        ) {
          this.dcd.formulaArray.pop();
          formula = formula?.slice(0, -1);
          formulaName = formulaName?.slice(0, -1);
        }
        this.dcd.formulaName = formulaName + res;
        /*istanbul ignore next*/
        let formulaLabel = this.operators?.find((x: any) => x.operator === res);
        /* istanbul ignore next */
        if (formulaLabel && formulaLabel?.name) {
          /* istanbul ignore next */
          let label = { name: formulaLabel?.name, operator: formulaLabel?.operator };
          this.dcd.formulaArray.push(label);
        } else {
          this.dcd.formulaArray.push(res);
        }
        this.dcd.formula = formula + res;
        this.formulaName = this.dcd.formulaName;
        this.formulaId = this.dcd.formula;
      } else {
        this.dcd.formulaName = formulaName + res.formulaId;
        this.dcd.formula = formula + res.formulaIdWithId;
        this.formulaName = this.dcd.formulaName;
        this.formulaId = this.dcd.formula;
        this.dcd.formulaArray.push(res.formulaId);
      }
    }
  }

  onFormulaEnter(): void {
    /*istanbul ignore else*/
    if (this.formulaFreeText) {
      const textValue = this.formulaFreeText;
      this.addToFormula(textValue, true);
      this.formulaFreeText = '';
    }
  }

  completeDCD(): void {
    if (this.currentComp == 'SOP') {
      /*istanbul ignore else*/
      if (this.dcd.formula == undefined) {
        this.dcd.formulaName = this.formulaName;
        this.dcd.formula = this.formulaId;
      }
    } else {
      this.createDcds();
    }
  }

  // create dcd from the selected target and Sources
  createDcds(): void {
    /*istanbul ignore next*/
    // dcd.transformationInfo =
    /*istanbul ignore next*/
    if ((this.changeUnit && !this.changeUnit?.dcd) || this.changeUnit?.dcd?.length === 0) {
      this.changeUnit.dcd = [];
    }
    this.dcdArray.forEach((dcd: Dcd, i:number) => {
      /*istanbul ignore else*/      
      if(i==this.dcdArray?.length-1) dcd.event = this.event;
      this.isSubtransactionalDCD(dcd);
      if (dcd.formula == undefined) {
        dcd.formulaName = this.formulaName;
        dcd.formula = this.formulaId;
      }
      dcd.multiValueMode = this.multiValueMode;
      // if (this.attributeUsed) {
      //   delete dcd.sourceContextualId;
      //   delete dcd.sourceContextualName;
      // }
      if (this.isNested) {
        this.outGoingDcd[1].push(dcd);
      } else if (this.isSubtransaction && this.subtransactionalCUflag) {
        /* istanbul ignore next */
        const subDcd: any = dcd;
        subDcd.typeOfDCD = this.currentSubComp;
        this.subtransactionalDCD.push({ ...subDcd });
      } else if (this.isEntityCard) {
        this.entityCardDcd.push({ ...dcd });
      } else {
        /* istanbul ignore next */
        this.changeUnit?.dcd?.push(dcd);
      }
    });
    this.RCUsFlag = false;
    this.sourceType = 'CU';
    if (this.DCDcreated) this.resetConnectors();
    this.gsiFacade.dcdChange();

    if (this.isEntityCard) {
      this.targetCuList = [];
      this.prepareDCDDataForEntityCard();
    }
    this.generateDCDSourceArray();
  }

  clearFormula(dcd: Dcd, ind: number): void {
    dcd.formula = '';
    dcd.formulaName = '';
    dcd.formulaArray.splice(ind, 1);
    /* istanbul ignore next */
    dcd?.formulaArray?.forEach((ele: any) => {
      dcd.formula += ele?.name ? ele?.operator : ele;
      dcd.formulaName += ele?.name ? ele.operator : ele;
    });
  }

  getNestedGsiData(gsiId: string): void {
    this.gsiFacade.getCuDetails(gsiId, true);
  }

  showOperators(): void {
    this.showOPerator = !this.showOPerator;
  }

  sourceChangeUnitSelect(cuIndex: number, sourceCu?: any) {
    if (sourceCu) {
      this.sourceDcdValue = {
        ...this.sourceDcdValue,
        selectedChangeUnit: this.sourceDcdValue.selectedGsi.solutionLogic[cuIndex],
      };
    } else {
      this.sourceDcdValue = { ...this.sourceDcdValue, selectedChangeUnit: this.changeUnit.solutionLogic[cuIndex] };
    }
  }

  sourceLayerChange(layerType: any) {
    /*istanbul ignore else*/
    if (layerType) {
      /*istanbul ignore next*/
      this.sourceCU?.solutionLogic[this.sourceDcdValue.changeUnitIndex]?.layers?.forEach((layer: any) => {
        /*istanbul ignore else*/
        if (layerType === layer.type) {
          this.sourceDcdValue.participatingItems = layer?.participatingItems;
        }
      });
    }
  }

  targetChangeUnitChange(index: number, isGsi?: boolean, nested?: boolean): void {
    /*istanbul ignore else*/
    if (index >= 0) {
      if (nested) {
        this.targetDcdValue.selectedNestedCU.selectedChangeUnit = this.targetNestedCu[index];
        this.layerOrNested = 'Layers';
        /* istanbul ignore next */
        this.nestedTargetCU[0] = this.targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.name;
      } else if (isGsi) {
        this.targetDcdValue.selectedGsi = this.targetGSIList[index];
        /* istanbul ignore next*/
        this.targetDcdValue.solutionLogic = this.targetGSIList[index]?.solutionLogic;
        this.targetCuList = this.targetGSIList[index]?.solutionLogic;
      } else {
        if (this.targetType == 'GSI' || this.targetType == 'Collection') {
          /* istanbul ignore next */
          this.targetDcdValue.selectedChangeUnit = this.targetDcdValue?.solutionLogic[index];
        } else {
          this.targetDcdValue.selectedChangeUnit = this.targetCuList[index];
        }
        this.targetDcdValue.changeUnitIndex = index;
      }
    }
  }

  changeTargetLayer(layerType: string): void {
    /*istanbul ignore next*/
    if (layerType && this.targetType != 'GSI_Layers' && this.targetType != 'Nested') {
      this.targetDcdValue.selectedChangeUnit?.layers?.forEach((layer: any) => {
        /*istanbul ignore else*/
        if (layerType === layer.type) {
          /*istanbul ignore next*/
          this.targetDcdValue.participatingItems = layer?.participatingItems;
        }
      });
    } else if (this.targetType == 'GSI_Layers' && layerType) {
      this.selectedTargetGsi?.layers.forEach((layer: any) => {
        if (layerType == layer?.type) {
          this.targetDcdValue.participatingItems = layer?.participatingItems;
        }
      });
    } else if (this.targetType == 'Nested') {
      this.targetDcdValue.selectedNestedCU.selectedChangeUnit.layers.forEach((layer: any) => {
        if (layerType == layer.type) {
          this.targetDcdValue.selectedNestedCU.participatingItems = layer?.participatingItems;
        }
      });
    }
  }

  changeTargetEntity(index: number, isNested?: boolean): void {
    /*istanbul ignore else*/
    if (index >= 0) {
      /*istanbul ignore next*/
      if (isNested) {
        let entity: any = this.targetDcdValue?.selectedNestedCU.participatingItems[index]; //selects entity from the participatingItems
        this.targetDcdValue.selectedNestedCU.selectedEntity = entity;
        this.selectedTargetBET = entity;
        this.targetDcdValue.selectedNestedCU.selectedAttribute = [];
        this.targetDcdValue.selectedNestedCU.nslAttributes = [];
        this.targetDcdValue.selectedNestedCU.nslAttributes = entity.nslAttributes
          ? entity.nslAttributes
          : entity.item.DATA.nslAttributes;
      } else {
        let entity: any = this.targetDcdValue?.participatingItems[index];
        if (!entity?.name) {
          entity.name = entity?.item?.DATA?.name;
        } //selects entity from the participatingItems
        this.targetDcdValue.selectedEntity = entity;
        this.selectedTargetBET = entity;
        this.targetDcdValue.selectedAttribute = [];
        this.targetDcdValue.nslAttributes = [];
        this.targetDcdValue.nslAttributes = entity?.nslAttributes
          ? entity?.nslAttributes
          : entity?.item?.DATA?.nslAttributes;
      }
    }
  }
  changeTargetAttribute(entity: any, attrIndex: number) {
    this.setFalseAll(entity, attrIndex);
    this.selectedTargetBET = entity?.nslAttributes[attrIndex]?.generalEntity;
    /*istanbul ignore else*/
    if (!entity.nslAttributes[attrIndex].generalEntity) {
      if (this.targetType == 'Nested') {
        this.targetDcdValue.selectedNestedCU.selectedAttribute = entity.nslAttributes[attrIndex];
      } else {
        this.targetDcdValue.selectedAttribute = entity.nslAttributes[attrIndex];
        this.selectedTargetBET = entity?.nslAttributes[attrIndex];
      }
    }
  }

  addTarget(): void {
    /*istanbul ignore next*/
    let gsiName = this.targetDcdValue?.selectedGsi?.name ? this.targetDcdValue?.selectedGsi : '';
    /*istanbul ignore next*/
    let cuName = this.targetDcdValue.selectedChangeUnit?.name;
    /*istanbul ignore next*/
    let cuIndex = this.targetDcdValue.selectedChangeUnit?.index;
    /*istanbul ignore next*/
    let layerType = this.targetDcdValue.layerType;
    if (this.targetType == 'Nested') {
      layerType = this.targetDcdValue.selectedNestedCU.layerType;
    }
    /*istanbul ignore next*/
    let nestedCUName = this.targetDcdValue?.selectedNestedCU?.selectedChangeUnit?.name;
    if (this.targetDcdValue?.nestedGsi?.name) {
      nestedCUName = this.targetDcdValue?.nestedGsi?.name;
    }
    let nestedNumber = 1;
    let entityIndex;
    let entityName;
    if (this.targetType == 'Collection') {
      /* istanbul ignore next */
      entityIndex = this.targetDcdValue?.participatingItems?.findIndex(
        (entity) => entity?.id === this.targetDcdValue?.selectedEntity?.id
      );
      /* istanbul ignore next */
      let entity = this.targetDcdValue?.selectedEntity?.item?.DATA
        ? this.targetDcdValue?.selectedEntity?.item?.DATA
        : this.targetDcdValue?.selectedEntity;
      entityName = entity?.name;
      this.targetEntity = JSON.parse(JSON.stringify(entity));
    } else if (this.targetType == 'Nested') {
      /* istanbul ignore next */
      entityIndex = this.targetDcdValue?.selectedNestedCU?.participatingItems?.findIndex(
        (entity: any) => entity?.name === this.targetDcdValue?.selectedNestedCU?.selectedEntity?.name
      );
      /* istanbul ignore next */
      entityName = this.targetDcdValue?.selectedNestedCU?.selectedEntity?.name;
      this.targetEntity = JSON.parse(JSON.stringify(this.targetDcdValue?.selectedNestedCU?.selectedEntity));
    } else {
      /* istanbul ignore next */
      entityIndex = this.targetDcdValue?.entityIndex;
      /* istanbul ignore next */
      entityName = this.targetDcdValue?.selectedEntity?.name;
      this.targetEntity = JSON.parse(JSON.stringify(this.targetDcdValue?.selectedEntity));
    }
    // let entityIndex = this.targetDcdValue?.participatingItems?.findIndex(
    //   (entity) => entity.item.DATA.name === this.targetDcdValue.selectedEntity.item.DATA.name
    // );
    // let entityName = this.targetDcdValue.selectedEntity.item.DATA.name;
    /* istanbul ignore next*/
    this.targetDcdValue.attrPath = '';
    this.targetDcdValue.selectedNestedCU.attrPath = '';
    if (this.targetType == 'Nested') {
      this.generateTargetAttrPath(this.targetDcdValue.selectedNestedCU);
    } else {
      this.generateTargetAttrPath(this.targetDcdValue);
    }
    let attributeName: any;
    if (this.targetType == 'Nested') {
      attributeName = this.targetDcdValue.selectedNestedCU.attrPath;
    } else {
      attributeName = this.targetDcdValue.attrPath;
    }

    this.removeAllIsSelected(this.targetDcdValue);
    /* istanbul ignore next*/
    this.contextualType = this.targetDcdValue?.selectedAttribute?.name ? 'attribute' : 'entity';
    // if (this.selectedTargetGsi?.isEmbedded) {
    //   cuIndex = null;
    // }
    this.targetContextualId = this.isSubtransaction
      ? this.generateContextualId(
          cuName,
          cuIndex,
          layerType,
          entityIndex,
          entityName,
          attributeName,
          this.subtransactionalGsi,
          this.sourceCU
        )
      : this.generateContextualId(
          cuName,
          cuIndex,
          layerType,
          entityIndex,
          entityName,
          attributeName,
          this.gsiData,
          this.sourceCU,
          gsiName,
          nestedCUName,
          nestedNumber
        );
    let dcd: Connector = new Connector(
      this.targetContextualId,
      this.contextualType,
      this.targetContextualId,
      attributeName,
      attributeName
    );
    // this.isEvent=true;
    this.isDcdComplete = false;
    this.activateTargetDcd = true;
    dcd.formulaId = this.generateFormulaId(layerType, entityIndex, entityName, attributeName);
    dcd.formulaIdWithId = this.generateFormulaId(layerType, entityIndex, entityName, attributeName);
    let diffEntDCD: Dcd = this.dcd;
    if (
      (this.contextualType == 'entity' &&
      this.selectedSourceBET &&
      this.selectedSourceBET?.name != this.selectedTargetBET?.name) || this.templateMapping
    ) {
      diffEntDCD.isDifferentEntityDcd = true;
      this.openDiffEntityDCD(undefined, false, diffEntDCD, dcd);
    } else {
      this.createDcd(dcd);
    }
    this.targetDcdValue = new DcdValue();
    this.targetDcdValue.selectedNestedCU = new DcdValue();
    this.targetType = 'CU';
    this.istarget = true;
  }
  menuenter(): void {
    this.gsiFacadeService.menuenter();
  }
  menuLeave(trigger: any): void {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }
  buttonEnter(trigger: any): void {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }

  buttonLeave(trigger: any): void {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }
  deleteTarget(targetIndex: number): void {
    this.dcdArray.splice(targetIndex, 1);
    this.targetArray.splice(targetIndex, 1);
  }
  deleteSource(sourceIndex: number): void {
    this.sourceArray.splice(sourceIndex, 1);
  }
  eventClicked() {
    this.isEvent = true;
  }
  resetConnectors(): void {
    this.dcd = {} as Dcd;
    this.fetchedNested = [];
    this.basicOrNested = 'Basic  ';
    this.targetArray = [];
    this.sourceArray = [];
    this.showTarget = false;
    this.dcdArray = [];
    if (!this.isEntityCard) this.sourceDcdValue = new DcdValue();
    this.targetDcdValue = new DcdValue();
    this.targetDcdValue.selectedNestedCU = new DcdValue();
    this.selectedSourceBET = this.selectedTargetBET = {};
    this.event = 'ARRIVAL';
    this.transformationInfo = {
      sourceChangeDriver: [],
    };
  }

  getIndexPadding(index: number): string {
    let input: string = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }

  sourceTypeChange() {
    this.chooseGSILayers = false;
    this.RCUsFlag = false;
    this.sourceDcdValue = new DcdValue();
    /* istanbul ignore next */
    if (this.sourceType == 'GSI_Layers') {
      this.chooseGSILayers = true;
    } else if (this.sourceType == 'Collection') {
      this.searchText = '';
      this.pagenum = 0;
      this.gsiFacade.getAllGsis(this.pagenum, this.pageSize, this.searchText, this.isPublished, false);
      this.detectGsi();
    } else if (this.sourceType == 'RCU') {
      this.RCUsFlag = true;
      this.openRCUDCD();
    } else {
      this.chooseGSILayers = false;
    }
  }
  targetTypeChange(): void {
    if (this.targetType == 'Nested') {
      this.targetDcdValue.selectedChangeUnit = this.targetCuList.find((x: any) => {
        return x.index == this.changeUnit.index;
      });
      /* istanbul ignore next */
      if (this.targetNestedCu.length == 0) {
        this.targetMindCuList(this.changeUnit?.mindCUList[0].gsiId);
      }
      // this.nestedTarget = '';
      // this.
    } else {
      this.targetDcdValue = new DcdValue();
      /* istanbul ignore else */
      if (this.targetType == 'Collection') {
        this.searchText = '';
        this.pagenum = 0;
        this.gsiFacade.getAllGsis(this.pagenum, this.pageSize, this.searchText, this.isPublished, false);
        this.detectGsi();
      }
      if (this.targetType == 'GSI_Layers') {
        this.openGsiLayer = false;
        this.targetListGsi = [];
        this.targetListGsi.push(this.gsiData);
        if (this.gsiData?.solutionLogic?.length > 0) {
          this.gsiData?.solutionLogic.forEach((cu: any) => {
            if (cu?.cuType === 'GSI') {
              cu.isEmbedded = true;
              this.targetListGsi.push(cu);
            }
          });
        }
        if (this.targetGSIList?.length > 0) {
          this.targetGSIList?.forEach((gsi: any) => {
            if (gsi?.nestedGsi && gsi?.layers?.length > 0) {
              this.targetListGsi.push(gsi);
            }
          });
        }
      }
    }
  }
  ChangeTargetGsi(index: number) {
    this.targetDcdValue = new DcdValue();
    this.openGsiLayer = true;
    this.selectedTargetGsi = this.targetListGsi[index];
    if (this.selectedTargetGsi?.nestedGsi && !this.selectedTargetGsi?.isEmbedded) {
      this.targetDcdValue.nestedGsi = this.targetListGsi[index];
      this.targetDcdValue.selectedChangeUnit = this.targetCuList.find((x: any) => {
        return x.index == this.changeUnit.index;
      });
    }
    if (this.selectedTargetGsi?.isEmbedded) {
      this.targetDcdValue.selectedChangeUnit = this.selectedTargetGsi;
    }
  }
  detectGsi(): void {
    this.gsiFacade.gsiList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((gsiList: any) => {
      this.myCollection = gsiList.result.data;
    });
  }
  searchGsi(): void {
    this.gsiFacade.getAllGsis(this.pagenum, this.pageSize, this.searchText, this.isPublished, false);
  }
  getGsiDetails(gsi: any): void {
    this.gsiFacade.getGsiDetails(gsi.id);
    this.gsiFacade.gsiDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /* istanbul ignore next */
      data = data?.result;
      /* istanbul ignore next */
      if (data?.status === 'READY' && !this.isEntityCard) {
        this.changeUnitFacade.cancelReviewEmbed('gsi', data, 'connectors');
      } else if (!this.isEntityCard) {
        data = gsiMapper(data);
        if (this.sourceType === 'Collection' && this.targetType !== 'Collection') {
          this.sourceDcdValue.selectedGsi = data;
          this.sourceDcdValue.solutionLogic = data?.solutionLogic;
        } else {
          this.targetDcdValue.selectedGsi = data;
          this.targetDcdValue.solutionLogic = data?.solutionLogic;
        }
      } else if (this.isEntityCard) {
        if (gsi.id == data.id) this.targetGSIList.push(data);
      }
    });
  }
  getGSIDataForMyCollectionGSI() {
    this.changeUnitFacade.connectorsDraftStateGSI$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.targetDcdValue.selectedGsi = res;
      /* istanbul ignore next */
      this.targetDcdValue.solutionLogic = res?.solutionLogic;
    });
  }
  targetMindCuList(id: any) {
    // this.basicOrNested =value
    this.changeUnitFacade.fetchedNestedParentCU(id, -1, false, true);
  }
  detectTriggercuDetails() {
    this.changeUnitFacade.getTriggerCU$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res && res?.dcd == -1) {
        // this.targetDcdValue.selectedNestedCU.selectedChangeUnit =res;
        this.targetNestedCu.push(res);
      }
    });
  }
  selectionChange() {
    this.targetDcdValue.selectedNestedCU = this.fetchedNested[this.nestedCU];
  }
  /* istanbul ignore next */
  onNodeDCDRearrange(event: CdkDragDrop<String[]>) {
    /* istanbul ignore next */
    if (event?.previousContainer === event?.container) {
      /* istanbul ignore next */
      moveItemInArray(event?.container?.data, event?.previousIndex, event?.currentIndex);
    } else {
      /* istanbul ignore next */
      transferArrayItem(
        event?.previousContainer?.data,
        event?.container?.data,
        event?.previousIndex,
        event?.currentIndex
      );
    }
  }
  changeType() {
    /* istanbul ignore else */
    if (this.layerOrNested == 'Nested') {
      this.openNested = true;
    }
  }
  getTargetEmbGsiData() {
    if (this.isEntityCard) {
      return;
    }
    /*istanbul ignore else*/
    if (this.targetDcdValue.selectedGsi.cuType === 'GSI') this.getTargetEmbeddedGSIData();
  }
  fetchEMBGsi() {
    this.gsiFacade.targetEmbededGsiDCD$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.targetDcdValue.solutionLogic = res?.solutionLogic;
      }
    });
  }
  findMeasures(currentAttributeId: any) {
    this.showMeasures = false;
    /*istanbul ignore else */
    if (this.changeUnit?.measures) {
      Object?.entries(this.changeUnit?.measures)?.forEach((keyValuePair: any) => {
        let indexofAT = keyValuePair[0].indexOf('AT');
        let attributeId = keyValuePair[0].slice(indexofAT + 2);
        /*istanbul ignore else */
        if (currentAttributeId == attributeId) {
          this.showMeasures = true;
          Object.values(keyValuePair[1]).forEach((value: any) => {
            this.measureEntity = value?.measureEntity;
          });
          // this.measureEntity = keyValuePair[1]?.rating?.measureEntity;
        }
      });
    }
  }

  selectedMeasureAttribute(attrIndex: any) {
    this.ismeasures = true;
    this.selectedMeasureAttributeIndex = attrIndex;
  }

  onChildEvent(value: any) {
    /* istanbul ignore next */
    this.isChildEvent = Boolean(JSON.parse(value));
  }
  onSubTClick(value: any) {
    /* istanbul ignore next */
    this.isSubtransactionCUSelected = Boolean(JSON.parse(value));
    if (this.isSubtransactionCUSelected) {
      this.sourceCU = this.subtransactionalCU;
    } else {
      this.sourceCU = this.changeUnit;
    }
  }
  getUrlImg(dcd: any): any {
    if (dcd?.sourceContextualId?.includes('NSLEVENT')) {
      this.uploadImage = '../../../../../../assets/images/icons/childparent.svg';
    } else if (dcd?.targetContextualId?.includes('.NSLEVENT')) {
      this.uploadImage = '../../../../../../assets/images/icons/parentchild.svg';
    } else if (dcd.event == 'ARRIVAL') {
      this.uploadImage = '../../../../../../assets/images/icons/arrival.svg';
    } else {
      this.uploadImage = '../../../../../../assets/images/icons/departure.svg';
    }
    return this.uploadImage;
  }

  toggleVisibility(e: any, i: any) {
    // if(e.target.checked){
    let count: any;

    let groupingSatisfied = e.target.checked;
    let occurence = 0;
    this.dcdsList.forEach((dcd: any, index: any) => {
      /* istanbul ignore next */
      if (dcd.isInDCDGroup == true) {
        occurence++;
      }
      /* istanbul ignore next */
      if (occurence > 1) {
        if (count != undefined && index == count + 1) {
          if (dcd.isInDCDGroup == true) {
            count++;
          }
        } else if (dcd.isInDCDGroup == true && index != count + 1) {
          if (count <= this.dcdsList.length - 1) {
            groupingSatisfied = !e.target.checked;
          }
        }
      } else if (occurence == 1 && count == undefined) {
        count = index;
      } else {
        groupingSatisfied = e.target.checked;
      }
    });
    e.target.checked = groupingSatisfied;
    this.dcdsList[i].isInDCDGroup = groupingSatisfied;
    // }
  }
  openDcdConditions(index?: any) {
    let dcds: any = [];
    let firstIndex: number;
    let condition: any;
    /* istanbul ignore next */
    if (index == undefined) {
      let tempDcd = {} as Connector;
      this.dcdsList.forEach((ele: any, index: any) => {
        if (ele.isInDCDGroup && firstIndex == undefined) {
          firstIndex = index;
        }
      });
      for (let i = this.dcdsList?.length - 1; i >= 0; i--) {
        if (this.dcdsList[i]?.isInDCDGroup) {
          if (this.dcdsList[i]?.condition) {
            delete this.dcdsList[i]?.condition;
          }
          dcds.push(this.dcdsList[i]);
          this.dcdsList.splice(i, 1);
        }
      }
      tempDcd.dcds = dcds;
      this.dcdsList.splice(firstIndex, 0, tempDcd);
    } else {
      condition = this.dcdsList[index]?.condition;
      firstIndex = index;
    }

    /* istanbul ignore next */
    let dialogRef = this.Dialog?.open(DcdConditionsComponent, {
      width: '90%',
      height: '80%',
      data: {
        changeUnit: this.changeUnit,
        gsiData: this.gsiData,
        condition: condition,
      },
    });
    /* istanbul ignore next */
    dialogRef?.afterClosed()?.subscribe((result) => {
      /* istanbul ignore next */
      if (result?.condition) {
        this.dcdsList[firstIndex].condition = result.condition;
      }
    });
  }
  deleteGroupedConnector(index: any, subIndex: any) {
    this.totalDcds--;
    this.dcdsList[index].dcds.splice(subIndex, 1);
  }
  //TO get sub-transactional CU condition
  appendSubtransactionalDCD() {
    this.gsiFacadeService.subtransactionalDCD$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      /* istanbul ignore else */
      if (data) {
        this.subtransactionalDCD = data;
      }
    });
  }
  isSubtransactionalDCD(dcd: any) {
    let subtransactionlayer =
      this.subtransactionalGsi?.name + '.' + this.subtransactionalCU?.name + ':' + this.subtransactionalCU?.index;
    // let subtransactionlayerid =
    //   this.subtransactionalGsi?.tfId + '.' + this.subtransactionalCU?.id + ':' + this.subtransactionalCU?.index;
    if (this.isSubtransaction && this.subtransactionalCUflag && dcd && dcd?.sourceContextualId) {
      if (dcd.sourceContextualName.includes(this.subtransactionalCU?.name)) {
        dcd.targetContextualId = `${subtransactionlayer}.SUBTRANSACTION.${dcd?.targetContextualId}`;
        dcd.targetContextualName = `${subtransactionlayer}.SUBTRANSACTION.${dcd?.targetContextualName}`;
        let obj = this.getSourceTarget(dcd.sourceContextualName, dcd.sourceContextualId);
        dcd.sourceContextualName = `${obj?.condName}`;
        dcd.sourceContextualId = `${obj?.condName}`;
      } else if (dcd.targetContextualName.includes(this.subtransactionalCU?.name)) {
        let obj = this.getSourceTarget(dcd.targetContextualName, dcd.targetContextualId);
        dcd.targetContextualName = `${obj?.condName}`;
        dcd.targetContextualId = `${obj?.condName}`;
      }
    }
  }
  getSourceTarget(dcdName: any, dcdId: any) {
    const sourceNamearray = dcdName.split('.');
    const sourceIdarray = dcdId.split('.');
    sourceNamearray[0] = this.subtransactionalGsi?.name;
    sourceIdarray[0] = this.subtransactionalGsi?.name;
    // sourceIdarray[0]=this.subtransactionalGsi?.tfId;
    let condName = '';
    let condId = '';
    sourceNamearray.forEach((name: any, index: any) => {
      if (index == 0) {
        condName = condName + name;
        condId = condId + sourceIdarray[index];
      } else {
        condName = condName + '.' + name;
        condId = condId + '.' + sourceIdarray[index];
      }
    });
    return { condName: condName, condId: condId };
  }
  getSubtransactionCUCondition() {
    this.changeUnitFacadeService.subtransactionalCUCondition$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        if (data) {
          this.subtransactionalCUflag = true;
          this.subtransactionalCU = changeUnitMapper(data?.subtransactionalCU);
          this.subtransactionalGsi = data?.subtransactionalGsiData;
        }
      });
  }
  onChange(cu: any) {
    console.log(cu);
    this.closeConnectors();
    this.changeUnitFacade.openConnector(true, cu);
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
   //To delete Mapping RCU mappings
   deleteTemplateMapping(entMapIndex: number, attrMapIndex: number) {
    this.mappings[entMapIndex].attributes.pop(attrMapIndex);
  }
  /**
   * Opens the RCU dcd component to form rcu dcd
   */
  openRCUDCD(){
    /* istanbul ignore next */
    let dialogRef = this.Dialog?.open(RcuDcdComponent, {
      width: '90%',
      height: '80%',
     data: {
      entityList: { ...this.sourceDcdValue,
        selectedChangeUnit :this.changeUnit,
     },
     transformationInfo:{
      transformationInfo:this.dcd?.transformationInfo
     },
    }
   });
   /* istanbul ignore next */
   dialogRef?.afterClosed()?.subscribe((result) => {
     /* istanbul ignore next */
     if (result) {
      this.dcd.transformationInfo = result?.transformationInfo;
     }
     this.RCUsFlag = false;
     this.addSource();
   });
 }
 editTransformation(sourceIndex:any){
  this.sourceArray.splice(sourceIndex, 1);
  this.openRCUDCD();
 }

 openDiffEntityDCD(i?: any, viewOnly?: boolean, diffEntDCD?: Dcd, dcd?: any) {
  let dialogRef = this.Dialog?.open(EntityToEntityDCDComponent, {
    width: '896px',
    maxWidth: '85%',
    height: '100%',
    // height: '80%',
    data: {
      sourceEntity: this.selectedSourceBET,
      targetEntity: this.selectedTargetBET,
      entityDCDMap: viewOnly ? this.dcdsList?.[i]?.entityDCDMap : diffEntDCD?.entityDCDMap,
      templateMapping: this.templateMapping,
      mapData: this.templateMapping ? this.dialog?.mapData?.map : undefined,
      viewOnly: viewOnly,
    },
  });
  dialogRef?.afterClosed()?.subscribe((res) => {
    if (res && !emptyObject(res) && !viewOnly) {
      diffEntDCD.entityDCDMap = res;
      this.createDcd(dcd, diffEntDCD);
    }
  });
}
convertStrToNum(val: any) {
  if (typeof val == 'string') {
    val = parseInt(val);
  }
  return val;
}
deleteGsiDetailsCollection() {
  if (this.sourceDcdValue?.selectedGsi && !emptyObject(this.sourceDcdValue?.selectedGsi)) {
    this.sourceDcdValue.selectedGsi = new Gsi();
    this.sourceDcdValue.solutionLogic = [];
  }
}


 //for validating DCDs
 validateDCDs() {
  const payload: any = {
    'path params': 'RCU_FUNCTION_DCD_VALIDATION',
    'Request Body': solutionLogicDeMapper(this.changeUnit),
  };
  this.gsiFacadeService.validateVantagePoints(payload);
}
 checkGroupSelection(){
  let selectCount = 0;
  this.dcdsList.forEach((dcd:any)=>{
    if(dcd?.isInDCDGroup && dcd?.isInDCDGroup==true) selectCount+=1;
  });
  return selectCount>1;
 }

}
export interface key {
  sourceCUContextualId: string;
  sourceCUContextualName: string;
}
export interface values {
  sourceContextualId: string;
  targetContextualId: string;
  targetContextualName?: string;
  formula: string;
  event: string;
}

export interface key {
  sourceCUContextualId: string;
  sourceCUContextualName: string;
}
export interface values {
  sourceContextualId: string;
  targetContextualId: string;
  targetContextualName?: string;
  formula: string;
  event: string;
}

export interface key {
  sourceCUContextualId: string;
  sourceCUContextualName: string;
}
export interface values {
  sourceContextualId: string;
  targetContextualId: string;
  targetContextualName?: string;
  formula: string;
  event: string;
}

export interface key {
  sourceCUContextualId: string;
  sourceCUContextualName: string;
}
export interface values {
  sourceContextualId: string;
  targetContextualId: string;
  targetContextualName?: string;
  formula: string;
  event: string;
}
