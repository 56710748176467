import { Injectable } from '@angular/core';
import { AlertService, LoaderService, OCREndpointService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NodeOcrFacadeService {
  public ngUnsubscribe = new Subject();

  nodeOcrFileUpload = new Subject();
  nodeOcrFileUpload$ = this.nodeOcrFileUpload.asObservable();

  nodeFileMappedDetails = new Subject();
  nodeFileMappedDetails$ = this.nodeFileMappedDetails.asObservable();

  constructor(
    public ocrEndpoint: OCREndpointService,
    public loader: LoaderService,
    private alertService: AlertService
  ) {}

  fileUpload(file: any, changeUnit?: any) {
    this.loader.show();
    this.ocrEndpoint
      .fileUpload(file)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.nodeOcrFileUpload.next({ ...res, changeUnit: changeUnit });
      });
  }
  saveOCRGSIandFileMapping(data: any) {
    this.loader.show();
    this.ocrEndpoint
      .saveOCRGSIandFileMapping(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.loader.hide();
          this.nodeFileMappedDetails.next(res);
        }
      });
  }
}
