import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { RolesFacadeService } from '../../roles/roles-facade.service';
import { SolutionDictionaryFacadeService } from '../../solution-dictionary/solution-dictionary-facade.service';
import { AlertService } from '../../toaster/alert.service';
import { TranslatorService } from '../../translator/translator.service';

export interface conflictRoles {
  roleid: string;
  rolename: string;
}

@Component({
  selector: 'app-conflict-roles',
  templateUrl: './conflict-roles.component.html',
  styleUrls: ['./conflict-roles.component.scss'],
})
export class ConflictRoles implements OnInit {
  ngUnsubscribe = new Subject();
  private unsubscribe$: Subject<any> = new Subject<any>();
  // @Input() roles: any;
  @Input() rolesConflicted: any;
  @Input() userRolesList: any;
  @Output() changedroles: EventEmitter<any> = new EventEmitter();
  @Input() isDsdRhs: boolean;
  @Input() saasapplication: boolean;
  conflicted: boolean = false;
  displayedColumns: string[] = ['roleid', 'rolename', 'parentrole', 'createdon', 'action'];
  dataSource = new MatTableDataSource();
  selectedrole: boolean[] = [];
  isResolvedcon: boolean[] = [];
  selectedNamerole: boolean[] = [];
  userConroles: any[] = [];
  renameroles: any[] = [];
  indexdata: any[] = [];
  rolesList: any[] = [];
  userConRoles: any;
  conflictedMessage: any;
  message: any;
  labels: any;
  mergeMessage: any;
  mapMessage: any;
  pageNumber: number = 0;
  pageSize: number = 10;
  searchString: string = '';
  isActive?: boolean = true;
  config = {
    displayKey: 'name', //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    clearOnSelection: false,
    customComparator: /* istanbul ignore next */ () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  };
  selectAll: boolean = false;
  betType: string = '';
  allRolesFlag: any = [];

  constructor(
    private router: Router,
    private rolesFacadeService: RolesFacadeService,
    private translator: TranslatorService,
    private dialogRef: MatDialog,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService,
    private alertService: AlertService
  ) {
    this.detectLanguageChange();
    this.rolesFacadeService.rolesPaginateSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.userConRoles = res;
      this.userRolesList = this.userConRoles.rolesList;
    });
    this.detectMergeOrMapRoles();
    this.getBetType();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    var conflictindex = this.rolesConflicted?.findIndex(
      (x: any) => x.conflicting === true && x.isConflictResolved === false
    );
    /* istanbul ignore next */
    if (conflictindex === -1) {
      this.conflicted = true;
      this.conflictedMessage = 'No Conflicted Roles';
    }
    var Elementdata: conflictRoles[] = [];
    /* istanbul ignore next */
    this.rolesConflicted?.forEach((ele: any, ind: number) => {
      Elementdata.push({
        roleid: this.rolesConflicted[ind].id,
        rolename: this.rolesConflicted[ind].sourceValue,
      });
      this.renameroles.push(ele.sourceValue);
    });
    this.dataSource.data = Elementdata;
    this.allRolesFlag = this.rolesConflicted;
    /* istanbul ignore next */
    this.allRolesFlag = this.allRolesFlag?.map((x: any) => {
      return {
        ...x,
        checked: false,
      };
    });
  }

  checkIfAllSelected() {
    /* istanbul ignore next */
    this.selectAll = this.allRolesFlag?.every(
      (role: any) => !role?.isConflictResolved && role?.conflicting && role?.checked
    );
  }

  moveToNextTab() {
    // this.betConfiguration.changeTabIndex(1);
    this.solutionDictionaryFacade.setChangeTabIndex(1);
    this.changedroles.emit(this.rolesConflicted);
  }

  cancel() {
    if (!this.saasapplication) {
      if (this.isDsdRhs) {
        this.dialogRef.closeAll();
      } else {
        if (this.betType === 'gsiImport') {
          this.rolesFacadeService.closeDialog(true);
          this.router.navigateByUrl('/admin/importsolution');
        } else {
          this.router.navigateByUrl('/solution-dictionary');
        }
        this.dialogRef.closeAll();
      }
    } else {
      this.dialogRef.closeAll();
      this.solutionDictionaryFacade.saasapplicationBetSubect.next(null);
      this.router.navigateByUrl('admin/manage-saasapplications/addSaasApplication');
    }
    this.solutionDictionaryFacade.closepopupDataService();
  }

  getBetType() {
    this.solutionDictionaryFacade.betType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.betType = res;
      }
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  rolesconList(event: any) {
    /* istanbul ignore next */
    if (this.pageSize == 10) {
      /* istanbul ignore next */
      // this.pageSize = this.betConfiguration.userRolesList?.totalRoles;
      this.rolesFacadeService.loadPaginateRoles(this.pageNumber, this.pageSize, event, this.isActive);
    }
  }

  changeRole(eve: any, data: any, index: any) {
    /* istanbul ignore else */
    if (eve.checked) {
      this.indexdata.push(index);
    } /* istanbul ignore next */ else if (!eve.checked) {
      this.indexdata.splice(index, 1);
      data.selectedrole = false;
      data.selectedNamerole = false;
      this.selectAll = false;
    }
    this.checkIfAllSelected();
  }
  mapExistConRole() {
    /* istanbul ignore next */
    this.indexdata?.forEach((element: any, ind: number) => {
      this.rolesConflicted[element].selectedrole = true;
      this.rolesConflicted[element].selectedNamerole = false;
    });
    this.indexdata = [];
  }
  mergeExistRole() {
    for (var i = 0; i < this.indexdata.length; i++) {
      var data = this.indexdata[i];
      this.rolesConflicted[data].conflictAction = 'MERGE';
      this.rolesConflicted[data].isConflictResolved = true;
      this.rolesConflicted[data].conflicting = true;
      this.rolesConflicted[data].targetValue = this.rolesConflicted[data].sourceValue;
      this.rolesConflicted[data].isResolvedcon = true;
      this.mergeMessage = ' Merged with Existing Role';
      this.rolesConflicted[data].message = this.mergeMessage;
      this.rolesConflicted[data].selectedrole = false;
    }
    this.indexdata = [];
  }
  rolesConData(i: number) {
    // for (var i = 0; i < this.indexdata.length; i++) {
    var data = i;
    this.rolesConflicted[data].conflictAction = 'MAP';
    this.rolesConflicted[data].isConflictResolved = true;
    this.rolesConflicted[data].conflicting = true;
    /* istanbul ignore next */
    this.rolesConflicted[data].targetValue = this.rolesConflicted[data].userConroles?.name;
    this.rolesConflicted[data].selectedrole = false;
    this.rolesConflicted[data].isResolvedcon = true;
    this.mapMessage = ' Mapped with Existing Role';
    this.rolesConflicted[data].message = this.mapMessage;
    this.indexdata.splice(i, 1);
    // }
  }
  renameRole(index: any) {
    let element = index;
    /* istanbul ignore next */
    // this.indexdata?.forEach((element: any, ind: number) => {
    // if (!this.rolesConflicted[element].targetValue) {
    this.rolesConflicted[element].selectedNamerole = true;
    this.rolesConflicted[element].selectedrole = false;
    /* istanbul ignore next */
    // }
    // });
  }

  saveName(i: any) {
    var data = i;
    let posRename = this.indexdata.findIndex((j: any) => j === data);
    /* istanbul ignore else */
    if (posRename > -1) this.indexdata.splice(posRename, 1);
    // for (var i = 0; i < this.indexdata.length; i++) {
    if (this.renameroles[i] === this.rolesConflicted[data].sourceValue) {
      this.alertService.showToaster('Please rename the role', '', 'error');
    } else {
      this.rolesConflicted[data].targetValue = this.renameroles[i];
      this.rolesConflicted[data].conflictAction = 'RENAME';
      this.rolesConflicted[data].isConflictResolved = true;
      this.rolesConflicted[data].conflicting = true;
      this.rolesConflicted[data].isResolvedcon = true;
      this.rolesConflicted[data].selectedNamerole = false;
      this.message = 'Renamed from' + ' ' + this.rolesConflicted[data].sourceValue;
      this.rolesConflicted[data].message = this.message;
    }
    //this.indexdata.splice(i, 1);
    // }
  }
  checkConflicts() {
    let flag: boolean = false;
    let nextFlag = true;
    this.rolesConflicted?.forEach((role: any) => {
      /* istanbul ignore next */
      let latestStatus = role.conflicting && role.conflictAction === 'NEW' && !role.isConflictResolved;
      /* istanbul ignore else */
      if (latestStatus) {
        nextFlag = false;
      }
      /* istanbul ignore next */
      if (nextFlag) {
        flag = true;
      }
    });
    return !(flag && nextFlag);
  }

  detectMergeOrMapRoles() {
    this.rolesFacadeService.mergeOrMapRolesObs$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      /*istanbul ignore next */
      if (res === 'merge') {
        this.mergeExistRole();
      } else if (res === 'map') {
        this.mapExistConRole();
      }
    });
  }

  changeAllRolesSelection(eve: any) {
    this.indexdata = [];
    if (eve.checked) {
      /*istanbul ignore next */
      this.allRolesFlag?.forEach((role: any) => {
        if (role.conflicting && !role.isConflictResolved) {
          role.checked = true;
        }
      });
      /*istanbul ignore next */
      this.rolesConflicted?.forEach((role: any, i: number) => {
        if (role.conflictAction == 'NEW' && role.conflicting && !role.isConflictResolved) this.indexdata.push(i);
      });
    } else {
      /*istanbul ignore next */
      this.allRolesFlag?.forEach((role: any) => {
        if (role.conflicting && !role.isConflictResolved) {
          role.checked = false;
        }
      });
    }
  }
}
