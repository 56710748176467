import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeUnit, Layer, TranslatorService } from '@common-services';
import { NodeChangeUnitFacadeService } from '../node-services/node-change-unit-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-entity-mapper',
  templateUrl: './entity-mapper.component.html',
  styleUrls: ['./entity-mapper.component.scss'],
  
})
export class EntityMapperComponent implements OnInit {
  @Input() connectorCU: ChangeUnit;
  physicalLayer: Layer;
  infomationLayer: Layer;
  attributeMappedObj: any = {};
  sourceEntityIndex: number;
  @Output() closeEntityMapperEmit = new EventEmitter();
  @Output() closeEntityMapperAndSaveCUEmit = new EventEmitter();
  labels: any;
  ngUnsubscribe = new Subject();
  constructor(private changeUnitFacade: NodeChangeUnitFacadeService,private translator: TranslatorService,) {
    this.detectLanguageChange();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  ngOnInit(): void {
    this.resetLayers();
    /* istanbul ignore next */
    if (this.connectorCU?.attributeMapping) {
      this.attributeMappedObj = JSON.parse(JSON.stringify(this.connectorCU?.attributeMapping));
    }
  }

  resetLayers() {
    /* istanbul ignore next */
    if (this.connectorCU?.layers) {
      let physicalLayerData = this.connectorCU?.layers.find((x: any) => x?.type === 'physical');
      if (physicalLayerData) {
        this.physicalLayer = JSON.parse(JSON.stringify(physicalLayerData));
      }
      let infoLayerData = this.connectorCU?.layers.find((x: any) => x?.type === 'information');
      if (infoLayerData) {
        this.infomationLayer = JSON.parse(JSON.stringify(infoLayerData));
      }
    }
  }

  physicalLayerEntityIndex(sourceIndex: number) {
    this.sourceEntityIndex = sourceIndex;
  }

  closeEntityMapper() {
    this.closeEntityMapperEmit.emit(false);
    this.changeUnitFacade.closeActionpanel();
  }
  /* istanbul ignore next */
  mapAttributes() {
    let sourceAttributeIndex = this.physicalLayer.participatingItems[this.sourceEntityIndex].nslAttributes.findIndex(
      (x: any) => x.isSelected
    );
    let sourceAttribute =
      'PL.SL00' +
      (this.sourceEntityIndex + 1) +
      '.' +
      this.physicalLayer.participatingItems[this.sourceEntityIndex].name +
      '.' +
      this.physicalLayer.participatingItems[this.sourceEntityIndex].nslAttributes[sourceAttributeIndex].name;

    this.attributeMappedObj[sourceAttribute] = this.attributeMappedObj[sourceAttribute]
      ? this.attributeMappedObj[sourceAttribute]
      : [];
    this.infomationLayer.participatingItems.forEach((entity, entityIndex) => {
      entity.nslAttributes.forEach((attribute) => {
        /* istanbul ignore next */
        if (attribute.isSelected) {
          let targetAttribute = 'IL.SL00' + (entityIndex + 1) + '.' + entity.name + '.' + attribute.name;
          this.attributeMappedObj[sourceAttribute].push(targetAttribute);
        }
      });
    });
    this.resetLayers();
  }
  /* istanbul ignore next */
  updateCU() {
    /* istanbul ignore next */
    this.connectorCU.tfId = this.connectorCU?.tfReferencedChangeUnit;
    this.connectorCU.id = this.connectorCU?.referencedChangeUnit;
    this.connectorCU.attributeMapping = this.attributeMappedObj;
    this.changeUnitFacade.updateChangeUnit(this.connectorCU, false);
    this.closeEntityMapper();
    // this.closeEntityMapperAndSaveCUEmit.emit(this.attributeMappedArray);
  }

  deleteAttributeMap(key: string, index: number) {
    this.attributeMappedObj[key].splice(index, 1);
  }
}
