import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import {} from './../../components/connectors/connectors.component.scss'
import { TranslatorService } from '@common-services';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
;
@Component({
  selector: 'app-card-template-configuration',
  templateUrl: './card-template-configuration.component.html',
  styleUrls: [
    './card-template-configuration.component.scss',
    './../../components/connectors/connectors.component.scss',
  ],
})
export class CardTemplateConfigurationComponent implements OnInit {
  @Input() configdata: any;
  formGroup: FormGroup;
  ngUnsubscribe = new Subject();
  labels: any;
  TableColorPickr: boolean;
  cardformGroup: FormGroup;
  entityConfig: any;
  attributes: any = [];
  @Output() emitCardInfo = new EventEmitter();
  selectionType: any;
  lableName: any;
  nslattributes: any;
  nslattributesExpand: any;
  isDeleteButton: boolean = false;

  constructor(
    private entityFacade: NodeEntityFacadeService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService
  ) {
    /* this.formGroup = formBuilder.group({
      showTitle: false,
      pagination: [false, Validators.requiredTrue],
      allowColumnsort: false,
      allowColumnFilter: false,
      allowDownload: false,
      allowExpand: false,
      allowSelection: false,
      toggleColumn: false,
      allowUpload: false,
      hideSerialNumber: false,
      hideSelectButton: false,
      hideLabels: false,
    });
    this.cardformGroup = formBuilder.group({
      minimumselection: [0],
      maximumselection: [0],
      minimumselectionAll: [false],
    }); */
    this.detectLanguageChange();
  }
  ngOnInit(): void {
    this.setEntityConfig();
    /* istanbul ignore next */
    if (this.configdata.data?.entityData) {
      this.configdata.data?.entityData.nslAttributes.forEach((attr: any) => {
        this.attributes.push({ name: 'Attribute' });
        attr['isSearched'] = true;
      });
    }
    /* istanbul ignore next */
    if (this.configdata.data?.entityData?.configuration) {
      if (typeof this.configdata.data?.entityData?.configuration === 'string') {
        this.entityConfig.type = JSON.parse(this.configdata.data?.entityData?.configuration).type;
      } else {
        this.entityConfig.type = this.configdata.data?.entityData?.configuration.type;
      }
      /* istanbul ignore next */
      if (this.entityConfig.type == 'cardCustomiZation') {
      } else {
        this.entityConfig.type = 'cardCustomiZation';
      }
    }
    /* istanbul ignore next */
    /*  if (this.entityConfig?.setting && Object.keys(this.entityConfig?.setting).length !== 0) {
      this.formGroup.patchValue(this.entityConfig.setting);
    } */
    /* istanbul ignore next */
    /* if (this.entityConfig?.tableSettings && Object.keys(this.entityConfig?.tableSettings).length !== 0) {
      this.cardformGroup.patchValue(this.entityConfig.tableSettings);
    } */
    if (this.configdata?.data?.entityData.nslAttributes) {
      this.nslattributes = JSON.parse(JSON.stringify(this.configdata.data?.entityData.nslAttributes));
      this.nslattributesExpand = JSON.parse(JSON.stringify(this.configdata.data?.entityData.nslAttributes));
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  saveconf() {
    this.entityConfig.setting = this.formGroup.value;
    if (this.entityConfig.tableSelection == 'single' && !this.formGroup.value.hideSelectButton) {
      this.entityConfig.hideSelectAll = true;
      this.entityConfig.tableSettings = {
        maximumselection: 1,
        minimumselection: 1,
        minimumselectionAll: false,
      };
    } /* istanbul ignore next */ else if (
      this.entityConfig.tableSelection == 'multiple' &&
      !this.formGroup.value.hideSelectButton
    ) {
      this.entityConfig.tableSettings = this.cardformGroup.value;
    } else {
      this.entityConfig.tableSettings = {};
    }

    this.configdata['configuration'] = JSON.stringify(this.entityConfig);
    /* istanbul ignore next */
    this.entityFacade.saveEntity(
      this.configdata?.data?.entityData,
      false,
      this.configdata?.data?.cu,
      [],
      this.configdata.data?.layerType,
      false,
      this.configdata.data?.layerType,
      this.configdata?.configuration
    );
    this.closeCardUiConfiguration();
  }

  getCardConfigData(event: any) {
    if (!event) {
      this.emitCardInfo.emit(event);
      return;
    }
    const data = { event: event, selectionData: { lableName: this.lableName, selectionType: this.selectionType } };
    this.emitCardInfo.emit(data);
  }

  changeSelection(event: any) {
    this.entityConfig.pagination.placementOfPagination = event;
  }

  closeCardUiConfiguration() {
    this.entityFacade.cardUIConfiguration(false, this.configdata);
  }

  closeVerticalTableUiConfiguration() {
    this.entityFacade.verticalTableUIConfiguration(false, this.configdata);
  }

  setEntityConfig() {
    this.entityConfig = {
      setting: {},
      export: [
        {
          label: 'Pdf',
          isChecked: false,
        },
        {
          label: 'CSV',
          isChecked: false,
        },
        {
          label: 'Excel',
          isChecked: false,
        },
      ],
      colouring: {},
      tableDescription: {},
      pagination: {
        rows: 10,
        placementOfPagination: '',
      },
      display: '',
      newTableConfiguration: true,
      type: 'table',
      hideSelectAll: false,
      cardSelection: '',
      cardSettings: {},
      verticalTableSettings: {},
    };
  }
}
