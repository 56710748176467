import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class SolutionVersioningService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  solutionDiffApi(data: any, type?: any) {
    if (type === 'gsi') {
      return this.http.get(this.api?.solutionDiffGSI + data);
    } else if (type === 'cu') {
      return this.http.get(this.api?.solutionDiffCU + data);
    } else {
      return this.http.get(this.api?.solutionDiffEntity + data);
    }
  }

  versionsList(data: string) {
    return this.http.get(this.api?.versionsListGsi + data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  gsiPublished(data: any) {
    return this.http.post(this.api?.versionsListInitiate, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  gsiFinished(data: any) {
    return this.http.post(this.api?.versionsListInitiate, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  /* versioning */
  finish(res: any, finishType?: any) {
    if (finishType === 'gsi') {
      return this.http.post(
        this.api?.versionFinishGSI + `?disableWarnings=${localStorage?.getItem('skipWarnings') === 'true'}`,
        res
      )
    } else if (finishType === 'cu') {
      return this.http.post(
        this.api?.versionFinishCU + `?disableWarnings=${localStorage?.getItem('skipWarnings') === 'true'}`,
        res
      );
    } else {
      return this.http.post(
        this.api?.versionFinishEntity + `?disableWarnings=${localStorage?.getItem('skipWarnings') === 'true'}`,
        res
      );
    }
  }
  publish(res: any, finishType?: any, skipReview?: any) {
    if (skipReview) {
      if (finishType === 'gsi') {
        return this.http.post(this.api?.skipVersionPublishGSI, res);
      } else if (finishType === 'cu') {
        return this.http.post(this.api?.skipVersionPublishCU, res);
      } else {
        return this.http.post(this.api?.skipVersionPublishEntity, res);
      }
    } else {
      if (finishType === 'gsi') {
        return this.http.post(this.api?.versionPublishGSI, res);
      } else if (finishType === 'cu') {
        return this.http.post(this.api?.versionPublishCU, res);
      } else {
        return this.http.post(this.api?.versionPublishEntity, res);
      }
    }
  }
  getReviewers(id: any, finishType?: any, pageNo?: number, pageSize?: number, inputReviewer?: string) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    if (finishType === 'gsi') {
      if (pageNo === undefined && pageSize === undefined && inputReviewer === undefined) {
        return this.http.get(this.api?.versionReviewerGSI + id, headers);
      } else if (inputReviewer) {
        return this.http.get(
          this.api?.versionReviewerGSI +
            id +
            '?pageNo=' +
            pageNo +
            '&pageSize=' +
            pageSize +
            '&search=' +
            inputReviewer,
          headers
        );
      } else {
        return this.http.get(
          this.api?.versionReviewerGSI + id + '?pageNo=' + pageNo + '&pageSize=' + pageSize,
          headers
        );
      }
    } else if (finishType === 'cu') {
      if (inputReviewer) {
        return this.http.get(
          this.api?.versionReviewerCU + id + '?pageNo=' + pageNo + '&pageSize=' + pageSize + '&search=' + inputReviewer,
          headers
        );
      } else {
        return this.http.get(this.api?.versionReviewerCU + id + '?pageNo=' + pageNo + '&pageSize=' + pageSize, headers);
      }
    } else {
      if (inputReviewer) {
        return this.http.get(
          this.api?.versionReviewerEntity +
            id +
            '?pageNo=' +
            pageNo +
            '&pageSize=' +
            pageSize +
            '&search=' +
            inputReviewer
        );
      } else {
        return this.http.get(this.api?.versionReviewerEntity + id + '?pageNo=' + pageNo + '&pageSize=' + pageSize);
      }
    }
  }

  sendNotifications(data: any) {
    return this.http.post(this.api?.sendNotifications, data); // https://qa3.nslhub.com/dsd-orch
  }

  approveOrRejectBET(data: any, type: any, betType: any, comment: any) {
    return this.http.post(this.api?.betsStoreTenant + `${type}/${betType}/${data.id}?comment=${comment}`, data);
  }

  sendfilterNotification() {
    return this.http.post(this.api?.sendfilterNotifications, {});
  }

  editPublishedBET(id: any, betType: any) {
    return this.http.post(this.api?.editPublishedBET + `${betType}/${id}`, {});
  }

  detectReviewInfo(data: any) {
    return this.http.get(this.api?.detectReviewInfo + `${data.tfId}`);
  }
  showReviewStatus(data: any) {
    return this.http.get(this.api?.showReviewStatus + `${data.tfId}`);
  }
  cancelReview(data: any, betType: any) {
    return this.http.post(this.api?.cancelReview + `${betType}/` + `${data.id}` + '?cancelReview=true', data);
  }
  /* ./versioning */

  // commentsList(data: string) {
  //   return this.http.get(environment.versionFinishURL + '/gsi/comments/' + data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  // conversationList(data: string) {
  //   return this.http.get(environment.versionFinishURL + '/gsi/conversations' + data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  // addComments(data: any) {
  //   return this.http.post(environment.versionFinishURL + '/gsi/comments', data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }
    /**
   * @author Ajit Dhayal
   * @return observable , will check Gsi safeGuard validations
   */
     triggerGsiSafeguard(betId: any, betName: any) {
      return this.http.get(this.api?.gsiSafeGuard + `${betName}/` + `${betId}`);
    }
}
