import { Component, ElementRef, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService, AuthenticationService, CredentialsService, GsiFacadeService, LoaderService, LoginComponent, LoginDesign, ManageConfigurationFacadeService, OnboardingFacadeService, SharedFacadeService, SsoLoginService, TenantCustomUiFacadeService, TransactionFacadeService, TranslatorService, UsersFacadeService } from '@common-services';

@Component({
  selector: 'app-mobile-default-template',
  templateUrl: './mobile-default-template.component.html',
  styleUrls: ['./mobile-default-template.component.scss'],
})
export class MobileDefaultTemplateComponent extends LoginComponent implements OnInit {
  @Input() loginDesign: LoginDesign;
  constructor(
    injector: Injector,
    router: Router,
    route: ActivatedRoute,
    formBuilder: FormBuilder,
    authenticationService: AuthenticationService,
    userFacade: UsersFacadeService,
    alertService: AlertService,
    translator: TranslatorService,
    loader: LoaderService,
    keycloak: KeycloakService,
    credentialsService: CredentialsService,
    tenantCustomUiFacadeService: TenantCustomUiFacadeService,
    gsiFacade: GsiFacadeService,
    manageConfigurationFacade: ManageConfigurationFacadeService,
    ssoService: SsoLoginService,
    onboardingFacadeService: OnboardingFacadeService,
    dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public transactionFacadeService: TransactionFacadeService,
    sharedfacade: SharedFacadeService
  ) {
    super(
      injector,
      router,
      route,
      formBuilder,
      authenticationService,
      userFacade,
      alertService,
      translator,
      loader,
      keycloak,
      credentialsService,
      tenantCustomUiFacadeService,
      gsiFacade,
      manageConfigurationFacade,
      ssoService,
      onboardingFacadeService,
      dialog,
      sanitizer,
      transactionFacadeService,
      sharedfacade,
    );
  }

  ngOnInit(): void {}
}
