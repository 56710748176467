import { dldEntityMapper } from './dld-entity.mapper';
declare var require: any;
let transform = require('node-json-transform').transform;

export function dldLayerMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          return val?.map((x: any) => {
            return dldEntityMapper(x);
          });
        },
        on: 'participatingItems',
      },
    ],
  };
  return transform(data, map);
}
