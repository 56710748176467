import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { IntegerComponent } from './integer.component';

import { OnlyNumberDirective } from './only-number.directive';

@NgModule({
  declarations: [IntegerComponent, OnlyNumberDirective],
  imports: [ControlsMaterialModule],
  exports: [IntegerComponent],
})
export class IntegerModule {
  static entry = {
    integer: IntegerComponent,
    byte: IntegerComponent,
    short: IntegerComponent,
    char: IntegerComponent,
    period: IntegerComponent,
    float: IntegerComponent,
  };
}
