import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2,
  ElementRef,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService, AlertService, SharedFacadeService, TransactionFacadeService, getCurrentEventDataArray, getEventData, paginationDetails, EventsFacadeService, EntityBoardEndpointService } from '@common-services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dynamic-ui-card',
  templateUrl: './dynamic-ui-card.component.html',
  styleUrls: ['./dynamic-ui-card.component.scss'],
})
export class DynamicUiCardComponent implements OnInit, OnDestroy {
  @Input() control: any;
  @Input() selectedCardsList: any;
  @Input() isReadOnly: any;
  @Input() eventCUList: any;
  @Input() isInfo: any;
  @Output() selectedCardsEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangedEmit: EventEmitter<any> = new EventEmitter<any>();

  displayName: any;
  listValues: any[];
  configuration: any;
  cardSettings: any;
  cardSelection: any;
  cols: any[];
  title: any = 'Card UI Control';
  entityName: any = '';
  slot: any;
  cardType: any;
  labels: any;
  hideSelectAllButton: boolean = false;
  selectedCradsArray: any = [];
  isselectAllCards: boolean = false;
  currentCard: number = -1;
  selectedGridLaoyout: string = 'three';
  showMoreInfo: boolean = false;
  expandedIndex: any = [];
  expandIndex: number = -1;
  hideSelection = false;
  unsubscribe$ = new Subject();
  @ViewChild('transactionCard', { static: true }) elementView: ElementRef;
  @ViewChild('dynamicCardUIMain') element: any;
  cardSection: number;
  currentEventData: any = [];
  showExpand: any;
  entityListData: any = [];
  paginationDetails: paginationDetails;
  customPagination: boolean = false;
  @Input() selectedRowTxnRecordIds:any;
  @Input() tempEntityList: any;
  dynamicTemplate: String;

  constructor(
    private translator: TranslatorService,
    private alertService: AlertService,
    private sharedFacadeService: SharedFacadeService,
    private renderer: Renderer2,
    private transactionFacadeService: TransactionFacadeService,
    private router: Router,
    private eventService: EventsFacadeService,
    private entityBoardEndpointService: EntityBoardEndpointService
  ) {
    this.detectLanguageChange();
    this.detectGridlayout();
  }
  ngOnChanges(simple: SimpleChanges) {
    /* istanbul ignore next*/
    this.slot = this.control?.value?.value?.slot;
    this.entityListData = this.tempEntityList?.map((elem: any) => elem);
    if (this.entityListData?.length > 0) {
      this.entityListData = this.entityListData?.filter((ent: any) => {
        return ent?.name == this.control?.key?.split('$')[0] && ent?.slot == this.slot;
      });
    }
    if (this.transactionFacadeService.selectedCradsArray[this.control?.key?.split('$')[0]] && !this.customPagination) {
      this.selectedCradsArray = this.transactionFacadeService.selectedCradsArray[this.control?.key?.split('$')[0]];
    } else {
      this.selectedCradsArray = [];
    }
    if (this.cardSelection === 'single' && this.cardSettings?.submitOnSelection) {
      this.selectedCardsList = this.selectedCardsList.filter(
        (card: any) => card.entityName !== this.control.key.split('$')[0]
      );
    }
    if (Object.keys(this.transactionFacadeService.paginationDetails).length) {
      this.customPagination = true;
      this.paginationDetails = this.transactionFacadeService.paginationDetails;
    }
    /* istanbul ignore else */
    if (this.control) {
      this.prepareTableStructureData(this.control);
    }
    this.cardSection = this.elementView.nativeElement.offsetWidth;
    /*istanbul ignore next*/
    setTimeout(() => {
      this.getCardMinHeight();
    }, 2000);
  }

  ngOnInit(): void {
    this.currentCard = -1;
  }

  /* get the translation lables based on selected language*/
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  bindHTML(templateId: string) {
    if (templateId?.includes('dynamic')) {
      /* istanbul ignore next */
      const tempId = templateId.split('-')[1];
      /* istanbul ignore next */
      if (tempId) {
        /* istanbul ignore next */
        this.entityBoardEndpointService.getTemplate(tempId).subscribe((res) => {
          this.dynamicTemplate = new String(res.result.template);
        });
      }
    }
  }

  /* Preapre the table structure data from the form */
  prepareTableStructureData(control: any) {
    /* istanbul ignore else */
    if (
      (control.value.value.configurationType && control.value.value.configurationType == 'card') ||
      control.value.value.configurationType == 'cardWithTemplate'
    ) {
      this.cardType = control.value.value.configurationType;
      /* istanbul ignore else */
      if (control.value.value.configuration) {
        this.cardSettings = JSON.parse(control.value.value.configuration).cardSettings;
        this.cardSelection = JSON.parse(control.value.value.configuration).cardSelection;
        if (this.cardSettings?.submitOnSelection) {
          this.eventService.hideSubmitButton.next({
            currentCuId: this.eventService.currentCuId,
            hideSubmitButton: true,
          });
        }
      }
      this.title = control.key.split('$')[0];
      this.displayName = control?.value?.value?.displayName ? control?.value?.value?.displayName : this.title;
      this.entityName = control.key;
      this.slot = control.value.value.slot;
      this.cols = [];
      this.listValues = [];
      let obj = {};
      this.hideSelectAllButton = JSON.parse(control.value.value.configuration).hideSelectAll;
      this.hideSelection = this.cardSettings?.hideSelection;
      this.configuration = JSON.parse(control.value.value.configuration).setting;
      this.bindHTML(this.configuration?.customizeCardconfigData?.templateType);
      for (let [key, value] of Object.entries(control.value.value)) {
        /* istanbul ignore next */
        if (value?.constructor?.name === 'Array') {
          this.cols.push({ field: key, header: key.split('$')[0], form: control.value.controls[key].controls[0] });
          obj[key.split('$')[0]] = value[0];
        }
      }

      this.cols = this.cols.filter((v, i, a) => a.findIndex((t) => t.header === v.header) === i);
      let listArray: any = [];
      for (let [key, value] of Object.entries(control.value.value)) {
        /* istanbul ignore next */
        if (value?.constructor?.name === 'Array') {
          listArray.push({ element: value[0], form: control.value.controls[key].controls[0] });
        }
      }
      let rowObj = {};
      let arry: any = [];
      let cardData = {};
      /* istanbul ignore next */
      listArray?.forEach((x: any, index: any) => {
        rowObj['attributeName'] = x.element.label.split('$')[0];
        if (x.element.inputType == 'image' || x.element.inputType == 'audio' || x.element.inputType == 'video') {
          rowObj['attributeValue'] = this.getFileUrls(x.element[x.element.label][0]);
        } else if (x.element.inputType == 'file') {
          rowObj['attributeValue'] = this.getFileUrls([x.element[x.element.label]]);
        } else {
          /* istanbul ignore next */
          rowObj['attributeValue'] = x?.element[x.element.label];
        }
        /* istanbul ignore next */
        rowObj[x.element.label.split('$')[0] + 'form'] = x.form;
        rowObj[x.element.label.split('$')[0]] = x.element;

        cardData[x.element.label.split('$')[0] + 'form'] = x.form;
        cardData[x.element.label.split('$')[0]] = x.element;

        /* istanbul ignore next */
        rowObj['attr_displayName'] = x.element?.attr_displayName
          ? x.element?.attr_displayName
          : rowObj['attributeName'];
        rowObj['inputType'] = x.element?.inputType;
        if (x.element?.attributeType?.type) {
          rowObj['attributeType'] = x.element?.attributeType?.type;
        }
        /* istanbul ignore next */
        rowObj['isHidden'] = x.element?.isHidden;
        arry.push(rowObj);
        rowObj = {};
        /* istanbul ignore else */
        if ((index + 1) % this.cols.length === 0) {
          this.listValues.push({ cardArry: arry, isChecked: false, object: cardData });
          arry = [];
          cardData = {};
        }
      });
      /* istanbul ignore next */
      if (
        this.paginationDetails &&
        this.transactionFacadeService.selectedTableRecords[this.paginationDetails.pageNumber]
      ) {
        this.selectedCardsList = this.transactionFacadeService.selectedTableRecords[this.paginationDetails.pageNumber];
      }
      /* istanbul ignore else */
      this.selectedCardsList =
        this.selectedCardsList.length > 0
          ? this.selectedCardsList.filter((card: any) => card.entityName == this.title)
          : [];
      if (this.selectedCardsList.length > 0) {
        this.selectedCardsList[0].selectedCards?.forEach((ind: any) => {
          if (this.listValues?.length >= ind) {
            this.listValues[ind].isChecked = true;
            this.addToSelectedCardArray(ind);
          }
        });
        this.selectedCardsEmit.emit({
          entityName: this.title,
          selectedCards: this.selectedCradsArray,
          selectedRowTxnRecordIds: this.selectedRowTxnRecordIds,
          slot: this.slot,
        });
      }
      if (this.selectedRowTxnRecordIds?.length > 0) {
        if (this.selectedCradsArray?.length > 0)
          this.selectedCradsArray = this.selectedCradsArray.filter((i: any) => i == 0);
        if (this.selectedCradsArray?.length == 1) this.listValues[0].isChecked = true;
        this.selectedRowTxnRecordIds?.forEach((val: any) => {
          if (val) {
            let ind = this.entityListData.findIndex((x: any) => x?.txnRecordId === val);
            if (ind !== -1) {
              this.addToSelectedCardArray(ind);
              this.listValues[ind].isChecked = true;
            }
          }
        });
      }
      this.isselectAllCards = this.selectedCradsArray?.length == this.listValues?.length;
      if (this.selectedCradsArray?.length > 0) {
        this.selectedCradsArray.forEach((val: any) => {
          this.listValues[val].isChecked = true;
        });
      }
    }

    if (
      this.cardSettings &&
      Object.keys(this.cardSettings).length > 0 &&
      !this.isInfo &&
      !this.control?.value?.value?.isOptional
    ) {
      if (this.cardSettings.minimumselectionAll) {
        /* istanbul ignore next */
        this.isselectAllCards = true;
        this.selectedCradsArray = [];
        this.selectedRowTxnRecordIds = [];
        this.listValues?.forEach((obj: any, index: any) => {
          obj.isChecked = true;
          this.addToSelectedCardArray(index);
        });
        this.entityListData.forEach((x: any) => {
          this.addToSelectedCardRecordId(x?.txnRecordId);
        });
      } else {
        if (
          (this.selectedCradsArray?.length >= this.cardSettings.minimumselection &&
            this.selectedCradsArray?.length <= this.cardSettings.maximumselection) ||
          this.hideSelection
        ) {
          this.isselectAllCards = false;
          this.selectionEvent.emit({
            isSelected: false,
          });
        } else {
          /* istanbul ignore next */
          if (
            localStorage.environmentName !== 'flatworldgistaging' &&
            localStorage.TenantName !== 'healthcaremx' &&
            localStorage.TenantName !== 'telceldev' &&
            localStorage.TenantName !== 'callhealth' &&
            localStorage.TenantName !== 'sdiwms' &&
            !this.cardSettings?.submitOnSelection
          ) {
            this.alertService.showToaster(
              'You can select minimum ' +
                this.cardSettings.minimumselection +
                ' and maximum ' +
                this.cardSettings.maximumselection,
              '',
              'info'
            );
          }
          this.isselectAllCards = false;
          this.selectionEvent.emit({
            isSelected: true,
          });
        }
      }
    }

    /* istanbul ignore else */
    if (this.sharedFacadeService.moreInfocardIndex != null && this.sharedFacadeService.moreInfocardIndex != -1) {
      this.currentCard = this.sharedFacadeService.moreInfocardIndex;
    }
    this.transactionFacadeService.selectedCradsArray[this.title] = this.selectedCradsArray;
  }

  /** check response data is stringfy or not */
  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getFileUrls(data: any) {
    let fileUrl: any = [];
    /* istanbul ignore next */
    data?.forEach((obj: any, ind: any) => {
      /* istanbul ignore else */
      if (this.IsJsonString(obj)) {
        let jsonObj: any = JSON.parse(obj);
        /* istanbul ignore else */
        if (jsonObj.contentUrl) {
          fileUrl[ind] = jsonObj.contentUrl;
        }
      } else {
        fileUrl[ind] = obj;
      }
    });
    return fileUrl[0];
  }

  deleteEntityControl(rowIndex: any, rowData: any) {
    this.selectedCradsArray.forEach((value: any, index: any) => {
      if (value > rowIndex) {
        this.selectedCradsArray[index]--;
      } else if (value == rowIndex) {
        this.selectedCradsArray.splice(index, 1);
      }
    });
    if (
      this.selectedRowTxnRecordIds?.length > 0 &&
      this.selectedRowTxnRecordIds?.includes(this.entityListData?.[rowIndex]?.txnRecordId)
    ) {
      this.selectedRowTxnRecordIds = this.selectedRowTxnRecordIds?.filter(
        (rowId: any) => rowId !== this.entityListData?.[rowIndex]?.txnRecordId
      );
    }
    this.removeEntity.emit({
      entityName: this.entityName,
      index: rowIndex,
      slot: this.slot,
      selectedRows: this.selectedCradsArray,
      rowData: rowData,
    });
    //this.checkMinMaxSelection();
    this.transactionFacadeService.selectedCradsArray[this.title] = this.selectedCradsArray;
  }

  addToSelectedCardArray(index: any) {
    if (
      (this.selectedCradsArray && this.selectedCradsArray?.length == 0) ||
      !this.selectedCradsArray?.includes(index)
    ) {
      this.selectedCradsArray.push(index);
    }
  }

  addToSelectedCardRecordId(txnId: any) {
    if (
      (this.selectedRowTxnRecordIds && this.selectedRowTxnRecordIds?.length == 0) ||
      (txnId && !this.selectedRowTxnRecordIds?.includes(txnId))
    ) {
      this.selectedRowTxnRecordIds.push(txnId);
    }
   
  }

  /* Send the selected cards to dynamic-form submit*/
  selectedCards(event: any, index: any, type: string, direct: boolean = false) {
    const value = type === 'checkbox' ? event?.checked || direct : true;
    
    if (value) {
      /* istanbul ignore else */
      if (type === 'radiobutton') {
        this.selectedCradsArray = [];
        this.selectedRowTxnRecordIds = [];
        this.listValues.forEach((value, i) => {
          if (index == i ) {
            value.isChecked = true;
          } else {
            value.isChecked = false;
          }
        });
      }
      this.addToSelectedCardArray(index);
      if (!direct && !(this.cardSelection === 'single' && this.cardSettings?.submitOnSelection)) {
        this.addToSelectedCardRecordId(this.entityListData?.[index]?.txnRecordId);
      }
    } else {
      this.selectedCradsArray = this.selectedCradsArray.filter((el: any) => {
        return el != index;
      });
      this.selectedRowTxnRecordIds = this.selectedRowTxnRecordIds?.filter(
        (i: any) => i != this.entityListData?.[index]?.txnRecordId
      );
    }
    if (this.listValues.length == this.selectedCradsArray.length) {
      this.isselectAllCards = true;
    } else {
      this.isselectAllCards = false;
    }
    /*istanbul ignore next*/
    if (this.paginationDetails && value && this.selectedCardsList && this.selectedCardsList.length > 0) {
      const index_selectedList = this.selectedCardsList.findIndex((ele: any) => {
        return ele.entityName === this.title && ele.slot === this.slot;
      });
      this.selectedCardsList[index_selectedList].selectedCards.push(index);
    } else if (this.paginationDetails && !value && this.selectedCardsList && this.selectedCardsList.length > 0) {
      const index_selectedList = this.selectedCardsList.findIndex((ele: any) => {
        return ele.entityName === this.title && ele.slot === this.slot;
      });
      const index_selectedListIndex = this.selectedCardsList[index_selectedList].selectedCards.findIndex((ele: any) => {
        return ele === index;
      });
      this.selectedCardsList[index_selectedList].selectedCards.splice(index_selectedListIndex, 1);
    }
    if (type == 'radiobutton') {
    
        this.selectedCradsArray = [];
        this.selectedRowTxnRecordIds = [];
        this.addToSelectedCardArray(index);
      
      if (!direct && !(this.cardSelection === 'single' && this.cardSettings?.submitOnSelection)) {
        this.selectedRowTxnRecordIds?.push(this.entityListData?.[index]?.txnRecordId);
      }
      /*istanbul ignore next*/
      if (this.paginationDetails && value && this.selectedCardsList && this.selectedCardsList.length > 0) {
        const index_selectedList = this.selectedCardsList.findIndex((ele: any) => {
          return ele.entityName === this.title && ele.slot === this.slot;
        });
        this.selectedCardsList[index_selectedList].selectedCards = [];
         this.selectedCardsList[index_selectedList].selectedCards.push(index);
      } else if (this.paginationDetails && !value && this.selectedCardsList && this.selectedCardsList.length > 0) {
        const index_selectedList = this.selectedCardsList.findIndex((ele: any) => {
          return ele.entityName === this.title && ele.slot === this.slot;
        });
        this.selectedCardsList[index_selectedList].selectedCards = [];
      }
    }
    /*istanbul ignore next*/
    if (this.paginationDetails) {
      this.transactionFacadeService.selectedTableRecords[this.paginationDetails.pageNumber] = this.selectedCardsList;
      /* istanbul ignore next */
      if (this.paginationDetails.pageNumber == 0) {
        this.transactionFacadeService.selectedTableRecords[
          this.paginationDetails.pageNumber + 1
        ] = this.selectedCardsList;
      } else if (this.paginationDetails.pageNumber == 1) {
        this.transactionFacadeService.selectedTableRecords[
          this.paginationDetails.pageNumber - 1
        ] = this.selectedCardsList;
      }
    }
    this.selectedCardsEmit.emit({
      entityName: this.title,
      selectedCards: this.selectedCradsArray,
      selectedRowTxnRecordIds: this.selectedRowTxnRecordIds,
      slot: this.slot,
    });
    let arFile: any;
    this.listValues?.[this.selectedCradsArray[0]]?.cardArry.forEach((card: any) => {
      if (card.inputType == 'file' && (card.attributeValue.includes('.glb') || card.attributeValue.includes('.gltf'))) {
        arFile = card.attributeValue;
      }
    });
    if (arFile) {
      this.transactionFacadeService.updateARFile({
        objFile: arFile,
        type: 'entity_cards',
        selectedCardsList: this.selectedCardsList,
      });
      this.router.navigateByUrl(`AR/arfileload/194244745384`);
      // this.router.navigateByUrl(`AR/${this.selectedCu?.id}`);
    }

    if (
      this.cardSettings &&
      Object.keys(this.cardSettings).length > 0 &&
      !this.isInfo &&
      (!this.control?.value?.value?.isOptional || this.cardSettings?.submitOnSelection)
    ) {
      /* istanbul ignore next */
      if (
        this.selectedCradsArray.length >= this.cardSettings.minimumselection &&
        this.selectedCradsArray.length <= this.cardSettings.maximumselection
      ) {
        this.selectionEvent.emit({
          isSelected: false,
          submitOnSelection: this.cardSelection === 'single' && this.cardSettings?.submitOnSelection,
        });
      } else if (this.cardSettings.minimumselectionAll && this.selectedCradsArray.length !== this.listValues.length) {
        /* istanbul ignore next */
        if (
          localStorage.environmentName !== 'flatworldgistaging' &&
          localStorage.TenantName !== 'healthcaremx' &&
          localStorage.TenantName !== 'telceldev' &&
          localStorage.TenantName !== 'callhealth' &&
          localStorage.TenantName !== 'sdiwms'
        ) {
          this.alertService.showToaster('You can select all cards', '', 'info');
        }
        this.selectionEvent.emit({
          isSelected: true,
        });
      } else if (this.cardSettings.minimumselectionAll && this.selectedCradsArray.length == this.listValues.length) {
        this.selectionEvent.emit({
          isSelected: false,
          submitOnSelection: this.cardSelection === 'single' && this.cardSettings?.submitOnSelection,
        });
      } else if (!this.cardSettings.minimumselectionAll) {
        if (
          localStorage.environmentName !== 'flatworldgistaging' &&
          localStorage.TenantName !== 'healthcaremx' &&
          localStorage.TenantName !== 'telceldev' &&
          localStorage.TenantName !== 'callhealth' &&
          localStorage.TenantName !== 'sdiwms' &&
          !this.cardSettings?.submitOnSelection
        ) {
          /* istanbul ignore next */
          this.alertService.showToaster(
            'You can select minimum ' +
              this.cardSettings.minimumselection +
              ' and maximum ' +
              this.cardSettings.maximumselection,
            '',
            'info'
          );
        }
        this.selectionEvent.emit({
          isSelected: true,
        });
      }
    }
    if (!this.cardSettings?.submitOnSelection) {
      this.transactionFacadeService.selectedCradsArray[this.title] = this.selectedCradsArray;
    }
  }

  // to selcet/deselect all the cards
  selecteAllCards() {
    if (!this.isselectAllCards) {
      this.selectedCradsArray = [];
      this.selectedRowTxnRecordIds = [];
      /* istanbul ignore next */
      this.listValues?.forEach((obj: any, index: any) => {
        obj.isChecked = true;
        this.addToSelectedCardArray(index);
      });
      this.entityListData?.forEach((ent: any) => {
        this.addToSelectedCardRecordId(ent?.txnRecordId);
      });
      this.isselectAllCards = true;
    } else {
      /* istanbul ignore next */
      this.listValues?.forEach((obj: any) => {
        obj.isChecked = false;
        this.selectedCradsArray = [];
      });
      this.selectedRowTxnRecordIds = [];
      this.isselectAllCards = false;
    }
    /* istanbul ignore next */
    if (this?.cardSettings && Object.keys(this?.cardSettings)?.length > 0) {
      if (
        this.selectedCradsArray.length >= this.cardSettings.minimumselection &&
        this.selectedCradsArray.length <= this.cardSettings.maximumselection
      ) {
        this.selectionEvent.emit({ isSelected: false });
      } else if (this.cardSettings.minimumselectionAll && this.selectedCradsArray.length == 0) {
        /* istanbul ignore next */
        if (
          localStorage.environmentName !== 'flatworldgistaging' &&
          localStorage.TenantName !== 'healthcaremx' &&
          localStorage.TenantName !== 'telceldev' &&
          localStorage.TenantName !== 'callhealth' &&
          localStorage.TenantName !== 'sdiwms'
        ) {
          this.alertService.showToaster('You can select all cards', '', 'info');
        }
        this.selectionEvent.emit({
          isSelected: true,
        });
      } else if (!this.cardSettings.minimumselectionAll) {
        if (
          localStorage.environmentName !== 'flatworldgistaging' &&
          localStorage.TenantName !== 'healthcaremx' &&
          localStorage.TenantName !== 'telceldev' &&
          localStorage.TenantName !== 'callhealth' &&
          localStorage.TenantName !== 'sdiwms' &&
          !this.cardSettings?.submitOnSelection
        ) {
          /* istanbul ignore next */
          this.alertService.showToaster(
            'You can select minimum ' +
              this.cardSettings.minimumselection +
              ' and maximum ' +
              this.cardSettings.maximumselection,
            '',
            'info'
          );
        }
        this.selectionEvent.emit({
          isSelected: true,
        });
      }
    }
    this.transactionFacadeService.selectedCradsArray[this.title] = this.selectedCradsArray;
  }

  toggleCardHeight(i: number) {
    this.currentCard = i;
    this.sharedFacadeService.moreInfocardIndex = i;
    setTimeout(() => {
      const errorField = this.renderer.selectRootElement(`.card${i}`);
      errorField.scrollIntoView();
    }, 300);
  }

  detectGridlayout() {
    this.sharedFacadeService.gridLayout$.subscribe((data: string) => {
      this.selectedGridLaoyout = data;
    });
  }

  // Check event data based on attribute id
  checkEventGSI(attr: any) {
    /*istanbul ignore next*/
    this.currentEventData = getCurrentEventDataArray(this.eventCUList, attr);
    if (this.currentEventData.length > 0) {
      return true;
    }
    return false;
  }

  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any) {
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }

  getCardMinHeight() {
    // console.log(this.element.el.nativeElement)
    let cardHeight = this.element?.nativeElement?.clientHeight;
    /*istanbul ignore next*/
    if (cardHeight > 447) {
      this.showMoreInfo = true;
    } else {
      this.showMoreInfo = false;
    }
  }

  toggleClass(event: any, index: number) {
    this.expandedIndex[index] = event;
    this.expandIndex = index;
    this.showExpand = event;
  }

  onPageChange(pageData: any) {
    /*istanbul ignore else*/
    if (this.customPagination) {
      this.transactionFacadeService.previousPageNumber = this.paginationDetails.pageNumber;
      this.transactionFacadeService.presentPageNumber = pageData;
    }
    this.paginationDetails.pageNumber = pageData;
    this.pageChanged.emit(this.paginationDetails);
  }

  dateChnagedEvent(event: any) {
    this.dateChangedEmit.emit(event);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
