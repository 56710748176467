import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DatetimeComponent } from './datetime.component';
@NgModule({
  declarations: [DatetimeComponent],
  imports: [
    ControlsMaterialModule,
  ],

  exports: [DatetimeComponent],
})
export class DateTimeModule {
  static entry = {
    datetime: DatetimeComponent,
    currentdatetime: DatetimeComponent,
  };
}
