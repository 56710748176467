import { Component, ElementRef, Input, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AlertService, AuthenticationService, CredentialsService, FieldConfig, LoaderService, LoginDesign, ManageConfigurationEndpointService, ManageConfigurationFacadeService, OnboardingFacadeService, RolesFacadeService, SelfOnboardingSignupComponent, setPropertyInDivLayout, TranslatorService, UsersFacadeService } from "@common-services";


@Component({
  selector: 'app-signup-template-one',
  templateUrl: './signup-template-one.component.html',
  styleUrls: ['./signup-template-one.component.scss'],
})
export class SignupTemplateOneComponent extends SelfOnboardingSignupComponent implements OnInit {
  ngOnChanges(changes: SimpleChanges) {
    throw new Error('Method not implemented.');
  }
  @Input() loginDesign: LoginDesign;
  headingText: string = 'SignUp';
  constructor(
    authenticationService: AuthenticationService,
    alertService: AlertService,
    router: Router,
    loader: LoaderService,
    rolesFacade: RolesFacadeService,
    userFacade: UsersFacadeService,
    onboardingFacadeService: OnboardingFacadeService,
    translator: TranslatorService,
    credentialsService: CredentialsService,
    manageConfigurationFacade: ManageConfigurationFacadeService,
    sanitizer: DomSanitizer,
    dialog: MatDialog,
    public elRef: ElementRef,
    public manageConfigurationEndpoint: ManageConfigurationEndpointService,

  ) {
    super(
      authenticationService,
      alertService,
      router,
      loader,
      rolesFacade,
      userFacade,
      onboardingFacadeService,
      translator,
      credentialsService,
      manageConfigurationFacade,
      sanitizer,
      dialog,
      manageConfigurationEndpoint
    );
  }
  field: FieldConfig;
  onClickPasswordIcon() {
    if (this.field.type === 'password') {
      this.field.type = 'text';
      this.showPassword = true;
    } else {
      this.field.type = 'password';
      this.showPassword = false;
    }
  }
  ngOnInit(): void {
    setPropertyInDivLayout(this.elRef, '--main-background-color', this.loginDesign?.backGround?.color);
    setPropertyInDivLayout(this.elRef, '--chip-color', this.loginDesign?.chip?.color);
    setPropertyInDivLayout(this.elRef, '--main-background-color', this.loginDesign?.backGround?.color);
    setPropertyInDivLayout(this.elRef, '--chip-color', this.loginDesign?.chip?.color);
    if (this.loginDesign?.headingText?.font?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--heading-font', this.loginDesign?.headingText?.font);
    }
    if (this.loginDesign?.caption?.font?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-font', this.loginDesign?.caption?.font);
    }
    if (this.loginDesign?.actionButton?.fontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--action-font-color', this.loginDesign?.actionButton?.fontColor);
    }
    if (this.loginDesign?.actionButton?.buttonColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--action-button-color', this.loginDesign?.actionButton?.buttonColor);
    }
    if (this.loginDesign?.caption?.headingFontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-heading-color', this.loginDesign?.caption?.headingFontColor);
    }
    if (this.loginDesign?.caption?.textFontColor?.length > 0) {
      setPropertyInDivLayout(this.elRef, '--caption-description-color', this.loginDesign?.caption?.textFontColor);
    }
  }
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
