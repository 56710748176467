import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acronym',
})
export class AcronymPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    if (value) {
      var matches = value.match(/\b(\w)/g);
      var acronym = matches.join('');
      return acronym.toUpperCase();
    }
    return value;
  }
}
