<ng-template #actionButton let-data="data">

  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="data?.isSponser">
      <div
        [ngClass]="data?.class"
        *ngIf="kanbanConfiguration?.kanbanGsis?.[data?.position]?.displayName"
        (click)="openUnrelatedGsi(kanbanConfiguration?.kanbanGsis?.[data?.position])"
      ></div>
    </ng-container>
    <ng-container *ngIf="!data?.isSponser">
      <button
        [ngStyle]="{ background: btnColors?.[data?.position] }"
        [ngClass]="data?.class"
        (click)="openUnrelatedGsi(kanbanConfiguration?.kanbanGsis?.[data?.position])"
        tooltipPosition="top"
        *ngIf="kanbanConfiguration?.kanbanGsis?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ kanbanConfiguration?.kanbanGsis?.[data?.position]?.displayName }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>
<div class="kanban-btns">
  <ng-template
    [ngTemplateOutlet]="actionButton"
    [ngTemplateOutletContext]="{
      data: { position: 0, class: 'kanban-btn', cardDisplay: cardDisplay }
    }"
  ></ng-template>
  <ng-template
    [ngTemplateOutlet]="actionButton"
    [ngTemplateOutletContext]="{
      data: { position: 1, class: 'kanban-btn', cardDisplay: cardDisplay }
    }"
  ></ng-template>
</div>
<div class="kanban-board-wrapper">
  <div class="row no-gutters nh-column-gap-24 w-100-50" cdkDropListGroup>
    <div
      class="col stage nh-bg-accent-blue-light"
      *ngFor="let stage of KanbanData; let lastItem = last"
      [cdkDropListData]="stage.status"
      cdkDropList
      (cdkDropListDropped)="drop($event, stage)"
    >
      <div class="nh-radius-8 nh-p-16 d-flex flex-column nh-row-gap-16 nh-h-100-50">
        <div class="d-flex align-items-center">
          <p class="nh-mr-8 font-weight-500 text-uppercase">{{ stage.DATA.displayValue }}</p>
          <div class="rounded">{{ stage.status.length }}</div>
        </div>
        <div class="cards-list d-flex flex-column nh-row-gap-16">
          <ng-container *ngFor="let card of stage.status; let txnDataIndex = index">
            <div cdkDrag (cdkDragStarted)="dragStart(txnDataIndex)" (cdkDragDropped)="onDrop($event)" [cdkDragData]="card">
              <app-attribute-templates
                [cardDisplay]="'view'"
                [txnDataIndex]="txnDataIndex"
                [gsiMapping]="gsiMapping"
                [btnColors]="btnColors"
                [cardDetails]="cardDetails"
                [card]="card"
                [cardColor]="cardColor"
                [attributeColors]="attributeColors"
                [_otherStyle]="_otherStyle"
                [conditionArray]="conditionArray"
                [cardShadow]="cardShadow"
                [cardBorderSize]="cardBorderSize"
                [cardBorderRadius]="cardBorderRadius"
                [cardBorderColor]="cardBorderColor"
                [attrHoveredColors]="attrHoveredColors"
                [cardHoveredColor]="cardHoveredColor"
                [quantity]="quantity"
              >
              </app-attribute-templates>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
