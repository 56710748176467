<div class="select-shadow-templates">
  <div mat-dialog-title class="header">
    <div class="select-title">Select Shadow Template</div>
    <div mat-dialog-actions class="header-buttons">
      <button class="save-button" mat-button [mat-dialog-close]="selectedShadow">Save</button>
      <button class="cancel-button" mat-button (click)="closeDialog()">Cancel</button>
    </div>
  </div>
  <ul mat-dialog-content class="shadow-list">
    <li
      *ngFor="let shadowTemplates of shadowTemplatesArray"
      [style]="shadowTemplates.boxshadow"
      class="shadow-item"
      id="entity_card_{{ shadowTemplates?.id }}"
      (click)="shadowTemplateSelection(shadowTemplates)"
      [ngClass]="{ 'shadow-template-active': selectedShadowNumber === shadowTemplates?.id }"
    >
      <span>{{ shadowTemplates?.id }}</span>
    </li>
  </ul>
</div>
