import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if(request){
    //   let a:string = request.url;
    //   let t:string = "";
    //   if(!a.includes("app-config.json")){
    //     if(a.substring(0,4)=="/api"){
    //       a = a.substring(4,a.length);
    //     }
    //     else if(a.substring(0,'/livechat_paas'.length) == "/livechat_paas"){
    //       a = a.substring('/livechat_paas'.length,a.length);
    //     }
    //     else if(a.substring(0,'/dsd-orch/lms'.length) == "/dsd-orch/lms"){
    //       a = a.substring('/dsd-orch/lms'.length,a.length);
    //     }
    //     else if(a.substring(0,'/googlemap'.length) == "/googlemap"){
    //       a = a.substring('/googlemap'.length,a.length);
    //     }
    //     let environment:string = 'qa3';
    //     let tenantName:string = 'cduiqa';
    //     if(a[0] !== '/'){t = "/";}
    //     // @ts-ignore
    //     request.url = `https://${tenantName}.${environment}.nslhub.com`+t+a;
    //   }
    // }
    /* istanbul ignore next */
    let lang: any = localStorage.locale ? localStorage.locale : 'en';
    /* istanbul ignore next */
    let translatedLang = localStorage?.translatedLanguage ? localStorage?.translatedLanguage : lang;
    /* istanbul ignore next */
    lang =
      request.url.includes('/dsd-orch/translator/language') ||
      request.url.includes('/dsd-orch/translator/tags/Global/') ||
      request.url.includes('/dsd-orch/nsl-iam/api/user/update/user')
        ? 'en'
        : lang;
    /* istanbul ignore next */
    lang = request?.url?.includes('/dsd-orch/dsd-bets-store/tenant/gsi/') ? translatedLang : lang;
    request = request.clone({
      setHeaders: {
        'Accept-Language': lang,
      },
    });
    localStorage?.removeItem('translatedLanguage');
    return next.handle(request);
  }
}
