import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CduiBaseService,
  entityListMapper,
  gsiMapper,
  changeUnitMapper,
  LoaderService,
  changeUnitDeMapper,
  gsiDeMapper,
  compositeExtractionMapper,
  sentenceTagUpdate,
  ChangeUnit,
  AlertService,
  NodeGsiFacadeService,
} from '@common-services';

@Injectable({
  providedIn: 'root',
})
export class NodeChangeUnitFacadeService extends CduiBaseService {
  private ngUnsubscribe = new Subject();
  fetchedCUForActionLabel = new Subject();
  fetchedCUForActionLabel$ = this.fetchedCUForActionLabel.asObservable();

  public changeUnitSuggestions = new Subject();
  changeUnitSuggestions$ = this.changeUnitSuggestions.asObservable();

  public flatSolutionDetails = new Subject();
  flatSolutionDetails$ = this.flatSolutionDetails.asObservable();

  public flatIndividualSolutionDetails = new Subject();
  flatIndividualSolutionDetails$ = this.flatIndividualSolutionDetails.asObservable();

  private saveInBookButtonHitSubject = new Subject();
  saveInBookButtonHitSubject$ = this.saveInBookButtonHitSubject.asObservable();

  savedChangeUnitList = new Subject();
  savedChangeUnitList$ = this.savedChangeUnitList.asObservable();

  flatSolutionOcrDetails = new Subject();
  flatSolutionOcrDetails$ = this.flatSolutionOcrDetails.asObservable();

  savedNestedChangeUnitList = new Subject();
  savedNestedChangeUnitList$ = this.savedNestedChangeUnitList.asObservable();

  changeComponent = new Subject();
  changeComponent$ = this.changeComponent.asObservable();

  cuDraftData = new Subject();
  cuDraftData$ = this.cuDraftData.asObservable();
  flatSolutionCuDetails = new Subject();
  flatSolutionCuDetails$ = this.flatSolutionCuDetails.asObservable();

  nestedCuDraftData = new Subject();
  nestedCuDraftData$ = this.nestedCuDraftData.asObservable();

  validateNestedCu = new Subject();
  validateNestedCu$ = this.validateNestedCu.asObservable();

  cuData = new Subject();
  cuData$ = this.cuData.asObservable();

  changeUnitList = new Subject();
  changeUnitList$ = this.changeUnitList.asObservable();

  cuDetails = new Subject();
  cuDetails$ = this.cuDetails.asObservable();

  triggerCuDetails = new Subject();
  triggerCuDetails$ = this.triggerCuDetails.asObservable();
  gsiDetailsDraft = new Subject();
  gsiDetailsDraft$ = this.gsiDetailsDraft.asObservable();

  nonTemplateRCU = new Subject();
  nonTemplateRCU$ = this.nonTemplateRCU.asObservable();

  rCUFunctionName = new Subject();
  rCUFunctionName$ = this.rCUFunctionName.asObservable();

  rCUFunctionData = new Subject();
  rCUFunctionData$ = this.rCUFunctionData.asObservable();

  connectorsDraftStateGSI = new Subject();
  connectorsDraftStateGSI$ = this.connectorsDraftStateGSI.asObservable();
  cuUpdatedData = new Subject();
  cuUpdatedData$ = this.cuUpdatedData.asObservable();

  private cuRecommendation = new Subject();
  cuRecommendation$ = this.cuRecommendation.asObservable();

  private openCu = new Subject();
  openCu$ = this.openCu.asObservable();

  compositeExtraction = new Subject();
  compositeExtraction$ = this.compositeExtraction.asObservable();

  compositeExtractionError = new Subject();
  compositeExtractionError$ = this.compositeExtractionError.asObservable();

  nestedCompositeExtraction = new Subject();
  nestedCompositeExtraction$ = this.nestedCompositeExtraction.asObservable();

  compositeInfoExtraction = new Subject();
  compositeInfoExtraction$ = this.compositeInfoExtraction.asObservable();

  compositeTriggerExtraction = new Subject();
  compositeTriggerExtraction$ = this.compositeTriggerExtraction.asObservable();

  private configurationSideBar = new Subject();
  configurationSideBar$ = this.configurationSideBar.asObservable();

  shadeConnectorSideBar = new BehaviorSubject('');
  shadeConnectorSideBar$ = this.shadeConnectorSideBar.asObservable();

  private finishCus = new Subject();
  finishCus$ = this.finishCus.asObservable();

  cuList = new Subject();
  cuList$ = this.cuList.asObservable();

  private activeValidateChangeUnit = new Subject();
  activeValidateChangeUnit$ = this.activeValidateChangeUnit.asObservable();

  private GSIPublish = new Subject();
  GSIPublish$ = this.GSIPublish.asObservable();

  private publishBtnHitSubject = new Subject();
  publishBtn$ = this.publishBtnHitSubject.asObservable();

  selectedCUSubject = new Subject();
  private detectSaveGSI = new Subject();
  detectSaveGSI$ = this.detectSaveGSI.asObservable();

  selectedCU$ = this.selectedCUSubject.asObservable();

  validateCUIndex = new Subject();
  validateCUIndex$ = this.validateCUIndex.asObservable();

  private cuUpdate = new Subject();
  cuUpdate$ = this.cuUpdate.asObservable();

  editableCUSubject = new Subject();
  editableCU$ = this.editableCUSubject.asObservable();

  receiveTag = new BehaviorSubject('');
  receiveTag$ = this.receiveTag.asObservable();

  private cuValidate = new Subject();
  cuValidate$ = this.cuValidate.asObservable();

  agentSuggestions = new Subject();
  agentSuggestions$ = this.agentSuggestions.asObservable();

  reservedCuList = new Subject();
  reservedCuList$ = this.reservedCuList.asObservable();

  reservedCuData = new Subject();
  reservedCuData$ = this.reservedCuData.asObservable();

  getBasicCu = new Subject();
  getBasicCu$ = this.getBasicCu.asObservable();

  getTriggerCU = new Subject();
  getTriggerCU$ = this.getTriggerCU.asObservable();

  nestedCuUpdatedData = new Subject();
  nestedCuUpdatedData$ = this.nestedCuUpdatedData.asObservable();

  private agentCreated = new Subject();
  agentCreated$ = this.agentCreated.asObservable();
  private changeUnitLink = new Subject();
  changeUnitLink$ = this.changeUnitLink.asObservable();

  public changeUnitUnLink = new Subject();
  changeUnitUnLink$ = this.changeUnitUnLink.asObservable();

  public dcd = new Subject();
  dcd$ = this.dcd.asObservable();

  public ccd = new BehaviorSubject('');
  ccd$ = this.ccd.asObservable();

  public openrcuData = new BehaviorSubject('');
  openrcuData$ = this.openrcuData.asObservable();

  public currentAttributeForAlternateCU = new Subject();
  currentAttributeForAlternateCU$ = this.currentAttributeForAlternateCU.asObservable();

  public currentAttributeForNestedCU = new Subject();
  currentAttributeForNestedCU$ = this.currentAttributeForNestedCU.asObservable();

  private unLinkCuIndex = new Subject();
  unLinkCuIndex$ = this.unLinkCuIndex.asObservable();
  addNewCUSubject = new Subject();
  addNewCUSubject$ = this.addNewCUSubject.asObservable();

  private savedIndCuSubject = new Subject();
  savedIndCuSubject$ = this.savedIndCuSubject.asObservable();

  isShadeOfPotentialityEnabled = new BehaviorSubject(false);
  isShadeOfPotentialityEnabled$ = this.isShadeOfPotentialityEnabled.asObservable();

  private roleData = new Subject();
  roleData$ = this.roleData.asObservable();

  cuFinishResponse = new Subject();
  cuFinishResponse$ = this.cuFinishResponse.asObservable();

  cuPublishResponse = new Subject();
  cuPublishResponse$ = this.cuPublishResponse.asObservable();

  reservedList = new Subject();
  reservedList$ = this.reservedList.asObservable();

  selectedReservedCU = new Subject();
  selectedReservedCU$ = this.selectedReservedCU.asObservable();

  reservedCU = new Subject();
  reservedCU$ = this.reservedCU.asObservable();

  templateReservedCUSentence = new Subject();
  templateReservedCUSentence$ = this.templateReservedCUSentence.asObservable();

  failedCuList = new Subject();
  failedCuList$ = this.failedCuList.asObservable();

  private editSentence: Subject<boolean> = new Subject<boolean>();
  editSentence$ = this.editSentence.asObservable();

  private unLinkList = new BehaviorSubject({ containsData: false, data: {} });
  unLinkList$ = this.unLinkList.asObservable();

  private linkList = new BehaviorSubject({ containsData: false, data: {} });
  linkList$ = this.linkList.asObservable();

  private unLinkCu = new Subject();
  unLinkCu$ = this.unLinkCu.asObservable();

  private linkCu = new Subject();
  linkCu$ = this.linkCu.asObservable();

  isSelectedFromDropDown = new Subject();
  selectedFromDropDown$ = this.isSelectedFromDropDown.asObservable();

  fetchedCU = new BehaviorSubject({
    cudata: {},
    dataAvailable: false,
    setUIControl: false,
  });
  fetchedCU$ = this.fetchedCU.asObservable();

  fetchedCUForNested = new Subject();
  fetchedCUForNested$ = this.fetchedCUForNested.asObservable();

  getRearrangeGsi = new Subject();
  getRearrangeGsi$ = this.getRearrangeGsi.asObservable();

  deleteSaveGsi = new Subject();
  deleteSaveGsi$ = this.deleteSaveGsi.asObservable();

  embeddedCudelete = new Subject();
  embeddedCudelete$ = this.embeddedCudelete.asObservable();

  getPreviewRearrangeGsi = new Subject();
  getPreviewRearrangeGsi$ = this.getPreviewRearrangeGsi.asObservable();
  deleteNestedGsi = new Subject();
  deleteNestedGsi$ = this.deleteNestedGsi.asObservable();

  resetCULayers = new Subject();
  resetCULayers$ = this.resetCULayers.asObservable();

  saveEntity = new Subject();
  saveEntity$ = this.saveEntity.asObservable();

  CUupdateInGSI = new Subject();
  CUupdateInGSI$ = this.CUupdateInGSI.asObservable();
  connector = new Subject();
  connector$ = this.connector.asObservable();

  mapper = new Subject();
  mapper$ = this.mapper.asObservable();

  condition = new Subject();
  condition$ = this.condition.asObservable();

  savedIndChangeUnitList = new Subject();
  savedIndChangeUnitList$ = this.savedIndChangeUnitList.asObservable();

  embeddeCu = new Subject();
  embeddeCu$ = this.embeddeCu.asObservable();

  embeddeCuNode = new Subject();
  embeddeCuNode$ = this.embeddeCuNode.asObservable();

  private indCuValidate = new Subject();
  indCuValidate$ = this.indCuValidate.asObservable();

  indCuUpdatedData = new Subject();
  indCuUpdatedData$ = this.indCuUpdatedData.asObservable();

  showTriggerCESlayer = new Subject();
  showTriggerCESlayer$ = this.showTriggerCESlayer.asObservable();

  reservedCuListGrammar = new Subject();
  reservedCuListGrammar$ = this.reservedCuListGrammar.asObservable();
  configurationSideBarGsi = new Subject();
  configurationSideBarGsi$ = this.configurationSideBarGsi.asObservable();

  rcuDescription = new Subject();
  rcuDescription$ = this.rcuDescription.asObservable();

  getRelatedGsi = new Subject();
  getRelatedGsi$ = this.getRelatedGsi.asObservable();

  errorMessageDetails = new Subject();
  errorMessageDetails$ = this.errorMessageDetails.asObservable();
  getUpDatedGsi = new Subject();
  getUpDatedGsi$ = this.getUpDatedGsi.asObservable();

  getEntityRearrangeGsi = new Subject();
  getEntityRearrangeGsi$ = this.getEntityRearrangeGsi.asObservable();

  getRearrangedEntityCu = new Subject();
  getRearrangedEntityCu$ = this.getRearrangedEntityCu.asObservable();
  getCuAttrEdit = new Subject();
  getCuAttrEdit$ = this.getCuAttrEdit.asObservable();
  compositeExtractionSlotEntity = new Subject();
  compositeExtractionSlotEntity$ = this.compositeExtractionSlotEntity.asObservable();

  savedLayers = new Subject();
  savedLayers$ = this.savedLayers.asObservable();
  reservedEntityList = new Subject();
  reservedEntityList$ = this.reservedEntityList.asObservable();

  private updatedGsi = new Subject();
  updatedGsi$ = this.updatedGsi.asObservable();

  getNestedCu = new Subject();
  getNestedCu$ = this.getNestedCu.asObservable();
  getNestedTriggerCu = new Subject();
  getNestedTriggerCu$ = this.getNestedTriggerCu.asObservable();

  getMultiNestedTriggerCu = new Subject();
  getMultiNestedTriggerCu$ = this.getMultiNestedTriggerCu.asObservable();
  isMultiNestedAdded = new Subject();
  isMultiNestedAdded$ = this.isMultiNestedAdded.asObservable();
  getTriggerCu = new Subject();
  getTriggerCu$ = this.getTriggerCu.asObservable();

  private imageBoundaries = new BehaviorSubject('');
  imageBoundaries$ = this.imageBoundaries.asObservable();
  indCompositeTriggerExtraction = new Subject();
  indCompositeTriggerExtraction$ = this.indCompositeTriggerExtraction.asObservable();

  nestedCuDetails = new Subject();
  nestedCuDetails$ = this.nestedCuDetails.asObservable();

  nestedClass = new Subject();
  nestedClass$ = this.nestedClass.asObservable();

  flatARSolutionDetails = new Subject();
  flatARSolutionDetails$ = this.flatARSolutionDetails.asObservable();
  openAgentPopUp = new Subject();
  openAgentPopUp$ = this.openAgentPopUp.asObservable();

  // croppedImage = new BehaviorSubject('');
  // croppedImage$ = this.croppedImage.asObservable();

  fetchedCuDetails = new Subject();
  fetchedCuDetails$ = this.fetchedCuDetails.asObservable();

  flatSolutionCuDetailsNested = new Subject();
  flatSolutionCuDetailsNested$ = this.flatSolutionCuDetailsNested.asObservable();
  eqlCuUpdatedData = new Subject();
  eqlCuUpdatedData$ = this.eqlCuUpdatedData.asObservable();

  uiProperties = new Subject();
  uiProperties$ = this.uiProperties.asObservable();

  selectedCuType = new BehaviorSubject('');
  selectedCuType$ = this.selectedCuType.asObservable();
  changeShowTagsView = new Subject();
  changeShowTagsView$ = this.changeShowTagsView.asObservable();

  arEntity = new BehaviorSubject({});
  arEntity$ = this.arEntity.asObservable();
  openTriggerEdit = new Subject();
  openTriggerEdit$ = this.openTriggerEdit.asObservable();

  expandCollapse = new Subject();
  expandCollapse$ = this.expandCollapse.asObservable();
  nodeSeparateCu = new BehaviorSubject('');
  nodeSeparateCu$ = this.nodeSeparateCu.asObservable();

  public constantChangeDriver = new Subject();
  constantChangeDriver$ = this.constantChangeDriver.asObservable();

  public updateConstantChangeDriverData = new Subject();
  updateConstantChangeDriverData$ = this.updateConstantChangeDriverData.asObservable();

  viewMoreCUDetails = new Subject();
  viewMoreCUDetails$ = this.viewMoreCUDetails.asObservable();

  flatTlayerSentence = new Subject();
  flatTlayerSentence$ = this.flatTlayerSentence.asObservable();

  public libraryPanelEntityfilter = new BehaviorSubject({});
  libraryPanelEntityfilter$ = this.libraryPanelEntityfilter.asObservable();

  paraActionPanel = new Subject();
  paraActionPanel$ = this.paraActionPanel.asObservable();

  entityTags = new Subject();
  entityTags$ = this.entityTags.asObservable();

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private loader: LoaderService,
    private injector: Injector,
    private nodeGsiFacadeService: NodeGsiFacadeService
  ) {
    super(injector);
  }

  // setCroppedImage(image: any){
  //   this.croppedImage.next(image);
  // }

  assignCcdData(data: any) {
    this.ccd.next(data);
  }

  addExpression(exp: any) {
    this.dcd.next(exp);
  }

  enableShadeOfPotentiality(data: any) {
    this.isShadeOfPotentialityEnabled.next(data);
  }
  addAttributetoAlternateCU(data: any) {
    this.currentAttributeForAlternateCU.next(data);
  }
  addAttributetoNestedCU(data: any) {
    this.currentAttributeForNestedCU.next(data);
  }

  addNewCU(individualCU: boolean) {
    this.addNewCUSubject.next(individualCU);
  }

  notIndividualCu(iscreateIndCu: boolean) {
    this.addNewCUSubject.next(iscreateIndCu);
  }
  openCuLayer(cu: any) {
    this.openCu.next(cu);
  }
  shadeConnector(data: any) {
    this.shadeConnectorSideBar.next(data);
  }

  getCuDetails(id: string, nestedNumber?: number) {
    /*istanbul ignore next*/
    return this.http
      .get(`${this.api?.savecu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = changeUnitMapper(res?.result);
        if (data.displayName == '') {
          data.displayName = data.name;
        }
        /*istanbul ignore next*/
        if (
          res?.result?.status == 'PUBLISHED' ||
          res?.result?.status === 'ARCHIVED'
        ) {
          this.editPublishedBET(id, 'change-unit', nestedNumber);
        } else if (
          /*istanbul ignore next*/
          res?.result?.status == 'READY' ||
          res?.result?.status == 'APPROVED' ||
          res?.result?.status == 'REJECTED'
        ) {
          /*istanbul ignore next*/
          this.cancelReview('change-unit', res?.result, nestedNumber);
        } else if (nestedNumber == undefined || nestedNumber == -1) {
          this.cuDetails.next(data);
        } else {
          data.nestedNumber = nestedNumber;
          this.nestedCuDetails.next(data);
        }
      });
  }

  cancelReviewEmbed(betType: any, data: any, fromComponent?: String) {
    data = gsiDeMapper(data);
    this.http
      .post(
        this.api.cancelReview +
          `${betType}/` +
          `${data.id}` +
          '?cancelReview=true',
        data
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res.status == 200) {
          const data = gsiMapper(res?.result);
          if (fromComponent === 'connectors') {
            this.connectorsDraftStateGSI.next(data);
          } else {
            this.gsiDetailsDraft.next(data);
          }
        }
      });
  }

  addEmbeddedCu(gsi: any) {
    /*istanbul ignore next*/
    return this.http
      .get(`${this.api?.getGsiDetail}${gsi.id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        this.cuDetails.next({
          ...res.result,
          cuType: 'GSI',
          referencedChangeUnit: res.result?.dsdId,
        });
      });
  }

  editPublishedBET(id: any, betType: any, nestedNumber?: number) {
    /*istanbul ignore next*/
    this.http
      .post(this.api?.editPublishedBET + `${betType}/${id}`, {})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = changeUnitMapper(res?.result);
        if (nestedNumber == undefined || nestedNumber == -1) {
          this.cuDetails.next(data);
        } else {
          data.nestedNumber = nestedNumber;
          this.nestedCuDetails.next(data);
        }
      });
  }
  cancelReview(betType: any, data: any, nestedNumber?: number) {
    /*istanbul ignore next*/
    this.http
      .post(
        this.api?.cancelReview +
          `${betType}/` +
          `${data.id}` +
          '?cancelReview=true',
        data
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = changeUnitMapper(res?.result);
        if (nestedNumber == undefined || nestedNumber == -1) {
          this.cuDetails.next(data);
        } else {
          data.nestedNumber = nestedNumber;
          this.nestedCuDetails.next(data);
        }
      });
  }
  getCloneDetails(id: number, isPublished: boolean, type: any, data: any) {
    //this.loader.show()sub;
    /*istanbul ignore next*/
    this.http
      .post(
        this.api?.betsStoreTenant +
          `${type}/clone/${id}?isPublished=${isPublished}`,
        data
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res['message'], '', 'success');
        this.loader.hide();
        /*istanbul ignore next*/
        const cuRes = changeUnitMapper(res?.result);
        cuRes.isCloned = true;
        this.cuDetails.next(cuRes);
      });
  }
  getAllChangeUnits(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean
  ) {
    const ontology = '';
    const publisherId = '';
    /*istanbul ignore next*/
    this.http
      .get(
        `${this.api?.savecu}?limit=${pageSize}&ontology=${ontology}&page=${
          pagenum + 1
        }&publisherIdOrName=${publisherId}&query=${searchCriteria}&isPublished=${isPublished}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.changeUnitList.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  finishCU(res: any) {
    let cuData = changeUnitDeMapper(res);
    /*istanbul ignore next*/
    this.http
      .post(`${this.api?.versionFinishCU}`, cuData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res?.result) {
          /*istanbul ignore next*/
          this.alertService.showToaster(res?.message, '', 'success');
          /*istanbul ignore next*/
          let cures = changeUnitMapper(res?.result);
          this.cuFinishResponse.next(cures);
        }
      });
  }

  publishCU(res: any) {
    /*istanbul ignore next*/
    this.http
      .post(`${this.api?.skipVersionPublishCU}`, changeUnitDeMapper(res))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res?.result) {
          /*istanbul ignore next*/
          this.alertService.showToaster(res?.message, '', 'success');
          /*istanbul ignore next*/
          let cures = changeUnitMapper(res?.result);
          this.cuPublishResponse.next(cures);
        }
      });
  }

  cuDraftDataGetter(data: any) {
    this.cuDraftData.next(data);
  }

  expandCollapseData(data: any) {
    this.expandCollapse.next(data);
  }

  saveDraftCu(
    cu: any,
    isModified?: boolean,
    isAttributeDeleted?: boolean,
    updatedEntity?: any
  ) {
    /*istanbul ignore else*/
    if (cu && cu.name) {
      cu.layers = this.removeEmptyLayers(cu.layers);
      const cuInfo = changeUnitDeMapper(cu);
      /*istanbul ignore next*/
      this.http
        .post(`${this.api?.saveDraftCu}`, cuInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            /*istanbul ignore next*/
            if (res && res.status && res.status == 200) {
              this.alertService.showToaster(res.message, '', 'success');
              const cuRes = changeUnitMapper(res.result);
              if (isAttributeDeleted) {
                cuRes.iscuFinal = true;
                cuRes.updatedEntity = updatedEntity;
              }
              /*istanbul ignore next*/
              this.cuDraftData.next({
                ...cuRes,
                oldVersionId: cu?.oldVersionId,
                isEdit: cu?.isEdit,
                isModified: isModified,
                slotItemProperties: cu?.slotItemProperties,
                source: cu?.source,
                tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
                tCUConditionalPotentialityNames:
                  cu?.tCUConditionalPotentialityNames,
                constraint: cu?.constraint,
                constraintName: cu?.constraintName,
                estimatedTime: cu?.estimatedTime,
                isReservedTemplate: cu?.isReservedTemplate,
                tCUShadesOfPotentiality: cu?.tCUShadesOfPotentiality,
                specialFeatureProperties: cu?.specialFeatureProperties,
                measures: cu?.measures,
                disableSTEs: cu?.disableSTEs,
                txnDataSaveMode: cu?.txnDataSaveMode,
                triggerCUDialogList: cu?.triggerCUDialogList,
              });
            } else if (res && res.message) {
              /*istanbul ignore else*/
              this.alertService.showToaster(`${res.message}`, '', 'error');
            }
          },
          /*istanbul ignore next*/
          (err: any) => {
            this.alertService.showToaster(err.message, '', 'error');
          }
        );
    }
  }

  nestedCuSave(cu: any) {
    /*istanbul ignore else*/
    if (cu && cu.name) {
      cu.layers = this.removeEmptyLayers(cu.layers);
      const cuInfo = changeUnitDeMapper(cu);
      this.http
        .post(`${this.api.saveDraftCu}`, cuInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            /*istanbul ignore else*/
            if (res && res.status && res.status == 200) {
              this.alertService.showToaster(res.message, '', 'success');
              const cuRes = changeUnitMapper(res.result);

              /*istanbul ignore next*/
              this.nestedCuDraftData.next({
                ...cuRes,
                oldVersionId: cu?.oldVersionId,
                isEdit: cu?.isEdit,
                slotItemProperties: cu?.slotItemProperties,
                source: cu?.source,
                tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
                tCUConditionalPotentialityNames:
                  cu?.tCUConditionalPotentialityNames,
                constraint: cu?.constraint,
                constraintName: cu?.constraintName,
                estimatedTime: cu?.estimatedTime,
                isReservedTemplate: cu?.isReservedTemplate,
                tCUShadesOfPotentiality: cu?.tCUShadesOfPotentiality,
                txnDataSaveMode: cu?.txnDataSaveMode,
                disableSTEs:cu?.disableSTEs,
                triggerCUDialogList: cu?.triggerCUDialogList,
              });
            } else if (res && res.message) {
              /*istanbul ignore else*/
              this.alertService.showToaster(`${res.message}`, '', 'error');
            }
          },
          /*istanbul ignore next*/
          (err: any) => {
            this.alertService.showToaster(err.message, '', 'error');
          }
        );
    }
  }

  validateNestedCuSave(cuData: any, nestedNumber: number, isIndcu?: boolean) {
    this.http
      .post(this.api.cuValidate, cuData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.nestedNumber = nestedNumber;
          if (isIndcu) {
            // this.indCuValidate.next(res);
          } else {
            this.validateNestedCu.next(res);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  finalCuSave(cu: any, activeLayerFlag?: any) {
    let isFinal = true;
    /*istanbul ignore next*/
    if (cu && cu?.name) {
      /* istanbul ignore next */
      cu.layers = this.removeEmptyLayers(cu?.layers);
      /* istanbul ignore next */
      if (cu?.layers?.length === 0) {
        isFinal = false;
      }
      const cuInfo = changeUnitDeMapper(cu);
      this.http
        .post(`${this.api?.savecu}?isFinal=${isFinal}`, cuInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          if (res && res.status && res.status == 200) {
            this.alertService.showToaster(res.message, '', 'success');
            const cuRes = changeUnitMapper(res.result);
            /*istanbul ignore next*/
            this.cuDraftData.next({
              ...cuRes,
              oldVersionId: cu?.oldVersionId,
              iscuFinal: true,
              isEdit: cu?.isEdit,
              isModified: true,
              slotItemProperties: cu?.slotItemProperties,
              source: cu?.source,
              tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
              tCUConditionalPotentialityNames:
                cu?.tCUConditionalPotentialityNames,
              constraint: cu?.constraint,
              constraintName: cu?.constraintName,
              estimatedTime: cu?.estimatedTime,
              isReservedTemplate: cu?.isReservedTemplate,
              tCUShadesOfPotentiality: cu?.tCUShadesOfPotentiality,
              eventCUList: cu?.eventCUList,
              specialFeatureProperties: cu?.specialFeatureProperties,
              measures: cu?.measures,
              disableSTEs: cu?.disableSTEs,
              activeLayerFlag: activeLayerFlag,
              txnDataSaveMode: cu?.txnDataSaveMode,
              triggerCUDialogList: cu?.triggerCUDialogList,
            });
          } else if (res && res.message) {
            /*istanbul ignore next*/
            this.alertService.showToaster(`${res.message}`, '', 'error');
          }
        });
    }
  }
  savedChangeUnitListGetter(data: any) {
    this.savedChangeUnitList.next(data);
  }
  savedIndCuSubjectGetter(data: any) {
    this.savedIndCuSubject.next(data);
  }

  saveMultiCuList(cuList: any, instanceNumber?: number, isSeperateCU?: any) {
    let uniqueCuList: any = [];
    uniqueCuList.push(cuList[0]);
    cuList.forEach((x: any) => {
      if (x.isReserved) {
        let index = uniqueCuList.findIndex(
          (element: any) => element.sentenceTags === x.sentenceTags
        );
        if (index == -1) {
          uniqueCuList.push(x);
        }
      } else {
        let index = uniqueCuList.findIndex(
          (element: any) => element.name === x.name
        );
        if (index == -1) {
          uniqueCuList.push(x);
        }
      }
    });

    let body: any = [];
    uniqueCuList.forEach((cu: any) => {
      if (cu.id && cu.description) {
        cu.dsdId = cu.id;
        cu.description = cu.name;
      }
      /*istanbul ignore else*/
      if (cu && cu.cuType != 'GSI') {
        body.push(changeUnitDeMapper(cu));
      } else if (cu.cuType == 'GSI') {
        body.push(gsiDeMapper(cu));
      }
    });
    /*istanbul ignore next*/
    this.http
      .post(`${this.api?.saveMultipleCu}`, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          let data: any = [];
          /*istanbul ignore else*/
          if (res && res.result) {
            this.alertService.showToaster(res.message, '', 'success');
            /*istanbul ignore next*/
            cuList?.forEach((cu: any) => {
              cu.instanceNumber = instanceNumber;
              let responsecu = res.result.find(
                (response: any) => response.index === cu.index
              );
              if (!responsecu) {
                responsecu = res.result.find(
                  (response: any) => response.name === cu.name
                );
              }
              if (responsecu) {
                responsecu.index = cu.index;
                responsecu.nextTriggerSet = cu.nextTriggerSet;
              }
              if (cu.cuType !== 'GSI') {
                let index = cuList.findIndex((x: any) => x.index === cu.index);
                let cuData = {
                  ...changeUnitMapper(responsecu),
                  constraint: responsecu?.constraint,
                  constraintName: responsecu?.constraintName,
                  triggerCUDialogList: responsecu?.triggerCUDialogList,
                  slotItemProperties: responsecu?.slotItemProperties,
                  dcd: cu?.dcd,
                  specialFeatureProperties: cu?.specialFeatureProperties,
                  measures: cu?.measures,
                };
                cuData.instanceNumber = instanceNumber;
                data.push(cuData);
              } else {
                let cuGSIData = {
                  ...gsiMapper(responsecu),
                  constraint: responsecu?.constraint,
                  constraintName: responsecu?.constraintName,
                  triggerCUDialogList: responsecu?.triggerCUDialogList,
                  slotItemProperties: responsecu?.slotItemProperties,
                  dcd: cu?.dcd,
                };
                cuGSIData.instanceNumber = instanceNumber;
                data.push(cuGSIData);
              }
            });
            if (isSeperateCU) {
              this.savedIndChangeUnitList.next(data);
            } else {
              this.savedChangeUnitList.next(data);
            }
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.failedCuList.next(true);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  saveTriggerCu(
    cuList: any,
    instanceNumber?: number,
    isSeperateCU?: any,
    secondLevel?: any,
    isFinalNested?: any
  ) {
    this.http
      .post(`${this.api.saveTriggerCu}`, cuList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          let data: any = [];
          /*istanbul ignore else*/
          if (res && res.result) {
            this.alertService.showToaster(res.message, '', 'success');
            /*istanbul ignore next*/
            cuList.instanceNumber = instanceNumber;
            let responsecu = res.result;
            responsecu.index = cuList.index;
            responsecu.nextTriggerSet = cuList.nextTriggerSet;
            /*istanbul ignore next*/
            if (cuList.cuType !== 'GSI') {
              let cuData = {
                ...changeUnitMapper(responsecu),
                constraint: responsecu?.constraint,
                constraintName: responsecu?.constraintName,
                triggerCUDialogList: responsecu?.triggerCUDialogList,
                slotItemProperties: responsecu?.slotItemProperties,
                dcd: cuList?.dcd,
              };
              cuData.nestedNumber = instanceNumber;
              cuData.secondLevel = secondLevel;
              cuData.isFinalNested = isFinalNested;
              data.push(cuData);
            } else {
              let cuGSIData = {
                ...gsiMapper(responsecu),
                constraint: responsecu?.constraint,
                constraintName: responsecu?.constraintName,
                triggerCUDialogList: responsecu?.triggerCUDialogList,
                slotItemProperties: responsecu?.slotItemProperties,
                dcd: cuList?.dcd,
              };
              cuGSIData.instanceNumber = instanceNumber;
              data.push(cuGSIData);
            }
            if (isSeperateCU) {
              // this.savedIndNestedChangeUnitList.next(data);
            } else {
              this.savedNestedChangeUnitList.next(data);
            }
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.failedCuList.next(true);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  saveTriggerCuWithLayers(cu: any, nestedNumber: any) {
    cu.querySentence = JSON.stringify(cu.sentenceTags);
    cu.tCUsentenceName = JSON.stringify(cu.sentenceTags);
    delete cu.sentenceTags;
    cu.agents = cu.agentName;
    cu.agentNames = [];
    this.http
      .post(`${this.api.saveTriggerCu}`, cu)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          let data: any = [];
          /*istanbul ignore else*/
          if (res && res.result) {
            this.alertService.showToaster(res.message, '', 'success');
            /*istanbul ignore next*/
            let responsecu = res.result;
            responsecu.nestedNumber = nestedNumber;
            responsecu.index = cu.index;
            responsecu.nextTriggerSet = cu.nextTriggerSet;
            if (cu.cuType !== 'GSI') {
              let cuData = {
                ...changeUnitMapper(responsecu),
                constraint: responsecu?.constraint,
                constraintName: responsecu?.constraintName,
                slotItemProperties: responsecu?.slotItemProperties,
                dcd: cu?.dcd,
              };
              cuData.nestedNumber = nestedNumber;

              data.push(cuData);
            } else {
              let cuGSIData = {
                ...gsiMapper(responsecu),
                constraint: responsecu?.constraint,
                constraintName: responsecu?.constraintName,
                slotItemProperties: responsecu?.slotItemProperties,
                dcd: cu?.dcd,
              };
              cuGSIData.nestedNumber = nestedNumber;
              data.push(cuGSIData);
            }
            this.savedLayers.next(data);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.failedCuList.next(true);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  changeTheComponent(gsiData: any) {
    this.changeComponent.next(gsiData);
  }
  getCuSuggestions(
    page: any,
    pageSize: any,
    searchText: any,
    isPublished: boolean
  ) {
    /*istanbul ignore next*/
    this.http
      .get(
        `${this.api?.savecu}?limit=${pageSize}&ontology=&page=${page}&publisherIdOrName=&query=${searchText}&isPublished=`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /*istanbul ignore next*/
          if (res && res?.result?.data) {
            /*istanbul ignore next*/
            this.changeUnitSuggestions.next(res?.result?.data);
          }
        },
        (err: any) => {
          /*istanbul ignore next*/
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }

  getCuSuggestionsGetter(data: any) {
    this.changeUnitSuggestions.next(data);
  }

  getSolutionCuDetails(
    data: any,
    component?: any,
    gsires?: any,
    nestedNumber?: any,
    current?: boolean,
    isParaTlayer?: boolean
  ) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.component = component;
          /*istanbul ignore else*/
          if (res[0]) res[0] = compositeExtractionMapper(res[0]);
          /*istanbul ignore next*/
          if (nestedNumber >= 0) {
            res.nestedNumber = nestedNumber;
            res.current = current;
            this.flatSolutionCuDetailsNested.next(res);
          } else if (isParaTlayer) {
            this.flatTlayerSentence.next(res);
          } else {
            this.flatSolutionCuDetails.next(res);
          }

          // this.flatSolutionDetails.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          const obj = {
            flatApiFail: true,
          };
          this.flatSolutionCuDetails.next(obj);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  getSolutionDetails(data: any, component?: any, gsires?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.component = component;
          this.flatSolutionDetails.next({ res: res, gsires: gsires });
          // this.flatSolutionDetails.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          const obj = {
            flatApiFail: true,
          };
          this.flatSolutionDetails.next(obj);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  getSolutionOcrDetails(data: any, component?: any, gsires?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.component = component;
          this.flatSolutionOcrDetails.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          const obj = {
            flatApiFail: true,
          };
          this.flatSolutionDetails.next(obj);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  getIndividualSolutionDetails(data: any, component?: any, gsires?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.component = component;
          // this.flatSolutionDetails.next({ res: res, gsires: gsires });
          this.flatIndividualSolutionDetails.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          const obj = {
            flatApiFail: true,
          };
          this.flatIndividualSolutionDetails.next(obj);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  solutionDetails(data: any) {
    this.flatSolutionDetails.next(data);
  }
  /*istanbul ignore next*/
  getARSolutionDetails(data: any, component?: any, gsires?: any, cuData?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.component = component;
          this.flatARSolutionDetails.next({
            res: res,
            gsires: gsires,
            cuData: cuData,
          });
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  getSolDetails(data: any) {
    this.flatSolutionDetails.next(data);
  }

  getAgentList(pageNumber: any, pageSize: any, data: any) {
    this.http
      .post(
        `${this.api?.getAllRoles}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        data
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.agentSuggestions.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  grammarReservedCU(pageIndex: any, pageSize: any, data: any) {
    let formData = new FormData();
    formData.append('limit', pageSize);
    formData.append('text', data);
    formData.append('page_number', pageIndex);
    //this.loader.show()sub;
    this.http
      .post(`${this.api?.getMethodName}`, formData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.reservedCuListGrammar.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  // new
  sendDataToCuDetails(data: any) {
    data.reservedIsLibrary = true;
    this.cuDetails.next(data);
  }

  /*istanbul ignore next*/
  getReservedCuDetailsById(id: any) {
    //this.loader.show()sub;
    this.http
      .get(
        `${this.environment.dsdOrchUrl}/dsd-bets-store/change-unit/reserved/fetch/${id}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /*istanbul ignore next*/
          const data = changeUnitMapper(res?.result);
          this.cuDetails.next(data);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  /*istanbul ignore next*/
  getReservedCuList(pagenum: number, pageSize: number, searchCriteria: string) {
    //this.loader.show()sub;
    const publisher = '';
    const ontology = '';
    this.http
      .get(
        `${
          this.api?.ReservedCuList
        }?limit=${pageSize}&ontology=${ontology}&page=${
          pagenum + 1
        }&publisherIdOrAuthor=${publisher}&query=${searchCriteria}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.reservedCuList.next(res);
      });
  }

  agentCreatedGetter(data: any) {
    this.agentCreated.next(data);
  }
  getAgentCreated(data: any, appendAgentName: boolean) {
    return this.http
      .post(`${this.api?.createRole}`, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.appendAgentName = appendAgentName;
          this.agentCreated.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  cuRecommendationGetter(data: any) {
    this.cuRecommendation.next(data);
  }

  cuValidation(
    cuName: any,
    cuIndex: number,
    componentInstanceNumber?: number,
    cuType?: string,
    isSlotSaved?: boolean,
    isNestedValidation?: boolean,
    nestedNumber?: number
  ): void {
    //this.loader.show()sub;
    this.http
      .post(this.api?.compositeExtraction, cuName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /*istanbul ignore next*/
          if (res?.sentence_tags) {
            res.sentence_tags = sentenceTagUpdate(
              res?.sentence_tags,
              this.nodeGsiFacadeService?.labelMapData
            );
          }
          res.cuIndex = cuIndex;
          res.componentInstance = componentInstanceNumber;
          res.nestedNumber = nestedNumber;
          if (cuType && cuType == 'GSI') {
            res.cuType = 'GSI';
          }
          if (isSlotSaved) {
            this.compositeExtractionSlotEntity.next(res);
          } else if (isNestedValidation) {
            this.nestedCompositeExtraction.next(res);
          } else {
            this.compositeExtraction.next(res);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.loader.hide();
          this.alertService.showToaster(err.message, '', 'error');
          this.compositeExtractionError.next({ error: true });
        }
      );
  }

  getcompositeExtraction(data: any) {
    this.compositeExtraction.next(data);
  }

  updateCUName(cuData: any) {
    this.cuUpdate.next(cuData);
  }

  resetLayers(cuId: number, layer: string, currentcomponent?: any) {
    this.resetCULayers.next({ cuId, layer, currentcomponent });
  }

  setConfiguration(config: any, isEmbedded?: boolean, isNested?: boolean) {
    if (isEmbedded) {
      config.isEmbedded = isEmbedded;
    }
    if (isNested) {
      config.isNested = isNested;
    }
    this.configurationSideBar.next(config);
  }

  setConfigurationGsi(config: any, parallel?: any) {
    if (parallel) {
      this.configurationSideBarGsi.next({ config: config, parallel: parallel });
    } else {
      this.configurationSideBarGsi.next(config);
    }
  }

  doneCus(status: boolean) {
    this.finishCus.next(status);
  }

  cuListData(cu: any) {
    this.cuList.next(cu);
  }
  removeEmptyLayers(layers: any = []) {
    /*istanbul ignore next*/
    if (layers?.length > 0) {
      return layers.filter(
        (m: any) => m.participatingItems && m.participatingItems.length
      );
    }
    return layers;
  }
  updateGsi(cu: any) {
    //this.loader.show()sub;
    cu.layers = this.removeEmptyLayers(cu.layers);
    if (!cu?.name) {
      return;
    }
    const cuInfo = gsiDeMapper(cu);
    this.http
      .post(`${this.api?.saveGsi}`, cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuRes = gsiMapper(res.result);
        const tmp = {
          ...cuRes,
          index: cu.index,
          oldVersionId: cu?.oldVersionId,
          isEdit: cu?.isEdit,
          isModified: true,
          slotItemProperties: cu?.slotItemProperties,
          source: cu?.source,
          tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
          tCUConditionalPotentialityNames: cu?.tCUConditionalPotentialityNames,
          constraint: cu?.constraint,
          constraintName: cu?.constraintName,
          estimatedTime: cu?.estimatedTime,
          isReservedTemplate: cu?.isReservedTemplate,
          sentenceTags: cu?.sentenceTags,
          mindCUList: cu?.mindCUList,
          txnDataSaveMode: cu?.txnDataSaveMode,
          disableSTEs:cu?.disableSTEs,
          triggerCUDialogList: cu?.triggerCUDialogList,
        };
        this.alertService.showToaster(res.message, '', 'success');
        this.updatedGsi.next(tmp);
      });
  }

  updateChangeUnit(
    cu: ChangeUnit,
    gotoNextCU?: boolean,
    isIndcu?: boolean,
    isNested?: any,
    nestedNumber?: any,
    isEqlCu?: any
  ) {
    //this.loader.show();
    /* istanbul ignore next */
    cu.layers = this.removeEmptyLayers(cu?.layers);
    if (cu?.layers?.length === 0) {
      gotoNextCU = false;
    }
    if (!cu?.name) {
      return;
    }
    const cuInfo = changeUnitDeMapper(cu);
    this.http
      .post(`${this.api?.updateCu}?isFinal=${gotoNextCU}`, cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        const cuRes = changeUnitMapper(res.result);
        /*istanbul ignore next*/
        const tmp = {
          ...cuRes,
          index: cu.index,
          oldVersionId: cu?.oldVersionId,
          isParallel: cu?.isParallel,
          isEdit: cu?.isEdit,
          isModified: true,
          slotItemProperties: cu?.slotItemProperties,
          source: cu?.source,
          tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
          tCUConditionalPotentialityNames: cu?.tCUConditionalPotentialityNames,
          constraint: cu?.constraint,
          constraintName: cu?.constraintName,
          estimatedTime: cu?.estimatedTime,
          isReservedTemplate: cu?.isReservedTemplate,
          sentenceTags: cuRes?.sentenceTags
            ? cuRes?.sentenceTags
            : cu?.sentenceTags,
          mindCUList: cu?.mindCUList,
          nestedNumber: nestedNumber,
          eventCUList: cu?.eventCUList,
          specialFeatureProperties: cu?.specialFeatureProperties,
          measures: cu?.measures,
          disableSTEs: cu?.disableSTEs,
          txnDataSaveMode: cu?.txnDataSaveMode,
          triggerCUDialogList: cu?.triggerCUDialogList,
        };
        if (isIndcu) {
          this.indCuUpdatedData.next({ data: tmp, gotoNextCU });
        } else if (isNested) {
          this.nestedCuUpdatedData.next({ data: tmp, gotoNextCU });
        } else if (isEqlCu) {
          this.eqlCuUpdatedData.next({ data: tmp, gotoNextCU });
        } else {
          this.cuUpdatedData.next({ data: tmp, gotoNextCU });
        }
      });
  }
  goToNextCU(cu: any, gotoNextCU = true) {
    this.cuUpdatedData.next({ data: cu, gotoNextCU });
  }

  changeActiveValidateChangeUnit(cuData: any) {
    this.activeValidateChangeUnit.next(cuData);
  }

  getCuUpdatedData(data: any) {
    this.cuUpdatedData.next(data);
  }

  publishGSI(gsi: any) {
    let data = gsiDeMapper(gsi);
    if (gsi?.status == 'APPROVED') {
      this.http
        .post(this.api?.versionPublishGSI, data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            this.GSIPublish.next(res);
          },
          /*istanbul ignore next*/
          (err: any) => {
            this.alertService.showToaster(err.message, '', 'error');
          }
        );
    } else {
      this.http
        .post(this.api?.skipVersionPublishGSI, data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            this.GSIPublish.next(res);
          },
          /*istanbul ignore next*/
          (err: any) => {
            this.alertService.showToaster(err.message, '', 'error');
          }
        );
    }
  }

  getpublishGSI(data: any) {
    this.GSIPublish.next(data);
  }

  publishBtnHit(res: any) {
    this.publishBtnHitSubject.next(res);
  }

  saveGSiBtnHit(res: boolean) {
    this.detectSaveGSI.next(res);
  }

  saveInBookButtonHit(res: boolean) {
    this.saveInBookButtonHitSubject.next(res);
  }

  selectedCU(cuIndex: number) {
    this.selectedCUSubject.next(cuIndex);
  }

  editableCU(cuIndex: number) {
    this.editableCUSubject.next(cuIndex);
  }

  makeSenetenceTagsEnable(cuIndex: number) {
    this.validateCUIndex.next(cuIndex);
  }

  validateCuList(cuData: any, instanceNumber?: number, isIndcu?: any) {
    this.http
      .post(this.api?.cuValidate, cuData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.instanceNumber = instanceNumber;
          if (isIndcu) {
            this.indCuValidate.next(res);
          } else {
            this.cuValidate.next(res);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  getindCuValidate(data: any) {
    this.indCuValidate.next(data);
  }
  getCuValidate(data: any) {
    this.cuValidate.next(data);
  }
  addLink(body?: any) {
    this.changeUnitLink.next(body);
  }

  validateIL(cuName: any) {
    this.http
      .post(this.api?.informationLayerExtraction, cuName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.compositeInfoExtraction.next(res);
        },
        (err: any) => {
          this.compositeInfoExtraction.next(err);
        }
      );
  }
  validateTC(cuName: any, isSeperateCu?: boolean) {
    this.http
      .post(this.api?.triggerCESLayerExtraction, cuName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (isSeperateCu) {
          this.indCompositeTriggerExtraction.next(res);
        }
        this.compositeTriggerExtraction.next(res);
      });
  }
  UnLinkCu(body?: any) {
    this.changeUnitUnLink.next(body);
  }
  unLinkIndex(index: number, id: any) {
    this.unLinkCuIndex.next({ unlinkIndex: index, cuId: id });
  }

  saveCU(cu: any, nestedNumber?: number): any {
    const cuInfo = changeUnitDeMapper(cu);
    /* istanbul ignore next */
    cu.layers = this.removeEmptyLayers(cu?.layers);
    let isFinal = true;
    /* istanbul ignore next */
    if (cu?.layers?.length === 0) {
      isFinal = false;
    }
    if (!cu.isReserved) {
      delete cuInfo.reservedCUType;
    }

    return this.http
      .post(`${this.api?.savecu}?isFinal=${isFinal}`, cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /*istanbul ignore else*/
          if (res) {
            this.alertService.showToaster(res.message, '', 'success');
            let data = changeUnitMapper(res.result);
            if (nestedNumber >= 0) {
              data.nestedNumber = nestedNumber;
            }
            if (cu?.eventCUList?.length > 0) {
              data.eventCUList = cu?.eventCUList;
            }
            this.savedIndCuSubject.next(data);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  /*istanbul ignore next*/
  getCuDetailsForFat2Flat(id: string, cb: (n: any) => any) {
    return this.http
      .get(`${this.api?.savecu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          cb(res);

          const data = changeUnitMapper(res?.result);
          this.cuDetails.next(data);
        },
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  roleDataGetter(data: any) {
    this.roleData.next(data);
  }

  getCu(data: any) {
    this.cuData.next(data);
  }

  getAgentData(roleName: string) {
    return this.http
      .get(`${this.api?.getRolesByName}/${roleName}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.foundRole = true;
          this.roleData.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          const response: any = {
            foundRole: false,
            agentName: roleName,
          };
          /*istanbul ignore next*/
          this.roleData.next(response);
        }
      );
  }
  sendTagIndex(tag: any) {
    this.receiveTag.next(tag);
  }
  getrCuList(cuSentence: any) {
    return this.http
      .post(this.api?.reservedRecomendation, cuSentence)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.reservedList.next(res);
      });
  }
  populateRCU(
    payload: any,
    currentCUId: any,
    selectedFromDropDown: boolean,
    showDescription?: any,
    isLibrary?: any,
    displayName?: any,
    moreView?: boolean
  ) {
    //this.loader.show()sub;
    return this.http
      .post(this.api?.populateReservedCU, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next*/
        if (res?.sentence_tags) {
          res.sentence_tags = sentenceTagUpdate(
            res?.sentence_tags,
            this.nodeGsiFacadeService?.labelMapData
          );
        }
        if (showDescription) {
          this.rcuDescription.next(res);
        } else {
          if (displayName) {
            res.displayName = displayName;
          }
          if (moreView) {
            this.viewMoreCUDetails.next({
              response: res,
              currentCuId: currentCUId,
              fromDropDown: selectedFromDropDown,
              isLibrary: isLibrary,
            });
          } else {
            this.selectedReservedCU.next({
              response: res,
              currentCuId: currentCUId,
              fromDropDown: selectedFromDropDown,
              isLibrary: isLibrary,
            });
          }
        }
      });
  }
  /*istanbul ignore next*/
  getReservedCuDetails(id: any) {
    //this.loader.show()sub;
    this.http
      .get(
        `${this.environment.dsdOrchUrl}/dsd-bets-store/change-unit/reserved/fetch/${id}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /*istanbul ignore next*/
          const data = changeUnitMapper(res?.result);
          this.reservedCU.next(data);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  isTemplateReservedCU(sentence: any) {
    this.templateReservedCUSentence.next(sentence);
  }
  /*istanbul ignore next*/
  getReservedEntitiesList(
    pagenum: number,
    pageSize: number,
    searchCriteria: string
  ) {
    const publisher = '';
    const ontology = '';
    this.http
      .get(
        `${this.api?.ReservedEntityList}?limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrAuthor=${publisher}&query=${searchCriteria}`
      )
      .subscribe((res: any) => {
        this.loader.hide();
        const entList = entityListMapper(res.result);
        this.reservedEntityList.next(entList);
      });
  }
  getCuDetailsById(id: any, instanceNumber?: number, setUIControl?: any) {
    this.http
      .get(`${this.api?.getBasicCu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res?.result && res?.status === 200) {
          if (instanceNumber >= 0) {
            let mappedData = changeUnitMapper(res.result);
            this.fetchedCU.next({
              ...this.fetchedCU.value,
              dataAvailable: true,
              cudata: { ...mappedData, instanceNumber: instanceNumber },
              setUIControl: setUIControl,
            });
          } else
            this.fetchedCU.next({
              ...this.fetchedCU.value,
              dataAvailable: true,
              cudata: changeUnitMapper(res.result),
              setUIControl: setUIControl,
            });
        }
      });
  }
  getActionLabelData(id: any) {
    this.http
      .get(`${this.api.getBasicCu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.fetchedCUForActionLabel.next(changeUnitMapper(res.result));
      });
  }
  getCuDetailsByCUId(id: any) {
    return this.http.get(`${this.api?.getBasicCu}/${id}`);
  }
  removeDataFromFetchedCu() {
    this.fetchedCU.next({
      ...this.fetchedCU.value,
      dataAvailable: false,
    });
  }

  getCuDetailsByIdNested(id: any) {
    this.http
      .get(`${this.api?.getBasicCu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res?.result && res?.status === 200) {
          this.fetchedCUForNested.next({
            ...this.fetchedCU.value,
            cudata: changeUnitMapper(res.result),
          });
        }
      });
  }

  AddUnLinkList(nextTriggerSet: any) {
    /*istanbul ignore next*/
    if (nextTriggerSet && nextTriggerSet?.length > 0) {
      this.unLinkList.next({
        ...this.unLinkList.value,
        data: nextTriggerSet,
        containsData: true,
      });
    } else {
      this.unLinkList.next({
        ...this.unLinkList.value,
        data: {},
        containsData: false,
      });
    }
  }
  editValidateSentence() {
    this.editSentence.next(true);
  }
  unLinkCuFlat2Flat(nextCuId: any) {
    this.unLinkCu.next(nextCuId);
  }
  AddLinkList(cuLinkList: any[]) {
    if (cuLinkList.length > 0) {
      this.linkList.next({
        ...this.linkList.value,
        data: cuLinkList,
        containsData: true,
      });
    } else {
      this.linkList.next({
        ...this.linkList.value,
        data: cuLinkList,
        containsData: false,
      });
    }
  }
  linkCuFalt2Flat(data: any) {
    this.linkCu.next(data);
  }
  deleteNested(cu: any) {
    this.deleteNestedGsi.next(cu);
  }
  selectedFromDropDown(res: any) {
    this.isSelectedFromDropDown.next({ result: res });
  }
  showTriggerCES(res: boolean) {
    this.showTriggerCESlayer.next(res);
  }

  saveEntityEvent() {
    this.saveEntity.next(true);
  }

  updateChangeUnitInGSI(changeUnit: any) {
    this.CUupdateInGSI.next(changeUnit);
  }
  PreviewRearrageCU(
    CUId: any,
    gsiId: any,
    previousTriggerCUId: any,
    nextTriggerCUIds: any
  ) {
    this.http
      .put(
        this.api?.rearrangeCU +
          `/preview/CU/${gsiId}?currentCUId=${CUId}&previousTriggerCUIds=${previousTriggerCUId}&nextTriggerCUIds=${nextTriggerCUIds}`,
        {}
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getPreviewRearrangeGsi.next(res);
      });
  }
  rearrangeCU(
    gsiId: any,
    previousTriggerCUId: any,
    nextTriggerCUIds: any,
    Data: any
  ) {
    this.http
      .put(
        this.api?.rearrangeCU +
          `/insert/CU/${gsiId}?previousTriggerCUIds=${previousTriggerCUId}&nextTriggerCUIds=${nextTriggerCUIds}`,
        Data
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getRearrangeGsi.next(gsiMapper(res.result));
      });
  }

  rearrangeEntity(
    cuId: any,
    layerType: any,
    currentSlotIndex: any,
    newSlotIndex: any,
    gsiId: any,
    Data: any
  ) {
    this.http
      .put(
        this.api?.reArrangeEntity +
          `/${cuId}/${layerType}?currentSlotIndex=${currentSlotIndex}&newSlotIndex=${newSlotIndex}&gsiId=${gsiId}`,
        Data
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getEntityRearrangeGsi.next(gsiMapper(res.result));
      });
  }

  openConnector(
    flag: boolean,
    cu?: ChangeUnit,
    sendTag?: any,
    isNested?: any,
    nestedKey?: any,
    mainCU?: any,
    SOPGsi?: any
  ) {
    this.connector.next({
      flag: flag,
      cu: cu,
      sendTag: sendTag,
      isNested: isNested,
      nestedKey: nestedKey,
      mainCU: mainCU,
      SOPGsi: SOPGsi,
    });
  }
  openMapper(flag: boolean, cu?: ChangeUnit) {
    this.mapper.next({ flag: flag, cu: cu });
  }
  openCondition(
    flag: boolean,
    cu?: ChangeUnit,
    condition?: string,
    sendTag?: any,
    currentComp?: any
  ) {
    this.condition.next({
      flag: flag,
      cu: cu,
      condition: condition,
      sendTag: sendTag,
      currentComp: currentComp,
    });
  }

  openEmbeddedGsi(
    flag: boolean,
    level: number,
    embbededCu?: string,
    index?: any
  ) {
    this.embeddeCu.next({
      flag: flag,
      data: embbededCu,
      level: level,
      index: index,
    });
  }

  openNodeEmbeddedGsi(flag: boolean, level: number, embbededCu?: string) {
    this.embeddeCuNode.next({ flag: flag, data: embbededCu, level: level });
  }
  getRelatedGsis(gsiId: any) {
    //this.loader.show()sub;
    this.http
      .get(this.api?.relatedGsis + `/${gsiId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getRelatedGsi.next(res);
      });
  }
  errorData(errData: any, type: any) {
    this.errorMessageDetails.next({ errData: errData, type: type });
  }
  deleteCu(triggerCUId: any, gsiId: any, isEmbedded?: any, isUnlink?: boolean) {
    this.http
      .delete(this.api?.deleteCu + `/${gsiId}/solution/${triggerCUId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let gsiData = gsiMapper(res.result);
        if (isEmbedded) {
          gsiData.isEmbedded = isEmbedded;
          this.embeddedCudelete.next(gsiData);
        } else if (isUnlink) {
          this.deleteSaveGsi.next(gsiData);
        } else {
          this.getRearrangeGsi.next(gsiData);
          this.getUpDatedGsi.next(gsiData);
        }
      });
  }
  fetchCuDraft(cuId: any) {
    this.http
      .get(this.api?.saveDraftCu + `/${cuId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getRearrangedEntityCu.next(changeUnitMapper(res.result));
      });
  }

  getCuInAttrEdit(cuId: any) {
    this.http
      .get(this.api?.saveDraftCu + `/${cuId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getCuAttrEdit.next(changeUnitMapper(res.result));
      });
  }
  fetchedNestedParentCU(
    id: any,
    nestedNumber?: any,
    currentData?: any,
    dcd?: any,
    dcdNumber = -1
  ) {
    this.http
      .get(this.api.fetchTriggerCu + `/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let data = res.result;
        if (dcd) {
          data = changeUnitMapper(data);
          data.dcd = dcdNumber;
        }
        if (nestedNumber >= 0) {
          data.nestedNumber = nestedNumber;
        }
        if (currentData) {
          data.currentData = currentData;
        }
        if (data?.querySentence)
          data.querySentence = JSON.stringify(
            compositeExtractionMapper(JSON.parse(data.querySentence))
          );
        this.getTriggerCU.next(data);
      });
  }
  fetchedNestedCU(id: any, nestedNumber: any) {
    this.http
      .get(this.api.savecu + `/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let data = changeUnitMapper(res.result);
        data.nestedNumber = nestedNumber;

        this.getBasicCu.next(data);
      });
  }
  // hitCUToGSIConvert(cuId: any, cuToConvert: any) {
  //   let mappedGSI = gsiDeMapper(cuToConvert);
  //   //mappedGSI['solutionLogic'] = cuToConvert.solutionLogic;
  //   this.http
  //     .post(this.api?.CUToGSIConversion + `/${cuId}`, mappedGSI)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.alertService.showToaster(res['message'], '', 'success');
  //     });
  // }

  fetchNestedTriggerCu(cuId: any, isMulti?: any) {
    this.http
      .get(this.api.saveTriggerCu + `/${cuId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (isMulti) {
          this.getMultiNestedTriggerCu.next(res.result);
        } else {
          this.getNestedTriggerCu.next(res.result);
        }
      });
  }

  fetchNestedCU(cuId: any, parentNestedId?: any, currentTriggerCu?: any) {
    this.http
      .get(this.api.saveDraftCu + `/${cuId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let NestedCuData = changeUnitMapper(res.result);
        if (parentNestedId) {
          NestedCuData.parentNestedId = parentNestedId;
          NestedCuData.currentTriggerCu = currentTriggerCu;
        }
        this.getNestedCu.next(NestedCuData);
      });
  }
  multiNestedAdded(isMultilevel: any) {
    this.isMultiNestedAdded.next(isMultilevel);
  }

  triggerCuSave(cuList: any) {
    this.http
      .post(`${this.api.saveTriggerCu}`, cuList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          let data: any = [];
          /*istanbul ignore else*/
          if (res && res.result) {
            this.getTriggerCu.next(res);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.failedCuList.next(true);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  sendNestedFlag(flag: boolean) {
    this.nestedClass.next(flag);
  }
  /*istanbul ignore next*/
  detectImageAR(payload: any) {
    // this.loader.show();
    this.http
      .post(`${this.api.detectARImage}`, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res) {
            // this.loader.hide();
            this.imageBoundaries.next(res);
          }
        },
        /*istanbul ignore next*/
        (error: any) => {
          this.alertService.showToaster(error.message, '', 'error');
        }
      );
  }
  fetchCuDetailsById(id: any) {
    this.http
      .get(`${this.api?.getBasicCu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res?.result && res?.status === 200) {
          const data = changeUnitMapper(res?.result);
          this.fetchedCuDetails.next(data);
        }
      });
  }
  getSfPostfactSentence(payload: any) {
    return this.http.post(this.api?.sfPostFactSentence, payload);
  }
  addAgents(cuEntityDetails: any) {
    this.openAgentPopUp.next(cuEntityDetails);
  }
  getUiProperties(id: any) {
    return this.http
      .get(`${this.api?.savecu}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        const data = changeUnitMapper(res?.result);
        if (data) {
          this.uiProperties.next();
        }
      });
  }
  getRelatedBetsData(id: any, type: any) {
    return this.http.get(`${this.api?.getRelatedBetsData}/${id}/${type}`);
  }

  /*istanbul ignore next*/
  changeTagsView() {
    this.changeShowTagsView.next(true);
  }
  setArEntity(data: any) {
    this.arEntity.next(data);
  }
  setIndividualCudata(data: any) {
    this.nodeSeparateCu.next(data);
  }

  addConstantChangeDriverToSlot(changeDriver: any) {
    this.constantChangeDriver.next(changeDriver);
  }

  updateConstantChangeDriver(data: any) {
    this.updateConstantChangeDriverData.next(data);
  }
  /*istanbul ignore next*/
  updateBoundaries(res: any) {
    this.imageBoundaries.next(res);
  }
  getNonTemplateRCU() {
    this.http
      .get(`${this.api.getNonTemplateRCU}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          this.nonTemplateRCU.next(res);
        }
      });
  }
  getRCUFunctionName(reservedCUName: any) {
    this.http
      .get(`${this.api.getRCUFunctionName}?reservedCUName=${reservedCUName}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          this.rCUFunctionName.next(res);
        }
      });
  }
  getRCUFunctionData(reservedCUName: any, functionName: any) {
    this.http
      .get(
        `${this.api.getRCUFunctionName}?reservedCUName=${reservedCUName}&functionName=${functionName}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          this.rCUFunctionData.next(res);
        }
      });
  }
  closeActionpanel() {
    this.paraActionPanel.next(true);
  }

  getEntityTags(data: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          if (res[0]) res[0] = compositeExtractionMapper(res[0]);
          this.entityTags.next(res);
        }
      });
  }
}
