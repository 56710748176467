<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeCu()">
        {{ labels?.close }}
      </button>
      <label class="status">Status: Draft (Ver 1.1)</label>
    </div>
    <div class="heading-area">
      <h2>{{ labels?.Intent }}</h2>
      <button class="save" (click)="saveCu()">{{ labels?.Save }}</button>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Intent_Details }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{ labels?.Intent_name }}</mat-label>
            <input readonly matInput placeholder="Intent name" [(ngModel)]="changeUnit.name" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ labels?.Intent_DisplayName }}</mat-label>
            <input matInput placeholder="Intent DisplayName" [(ngModel)]="changeUnit.displayName" />
          </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel (closed)="this.closeAdvancedSettings = true" (opened)="this.closeAdvancedSettings = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion *ngIf="!closeAdvancedSettings">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Permissions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="assign-rights">
                <ul>
                  <li class="roles-li" *ngFor="let role of roles; let i = index">
                    <div class="actions-item">
                      <div class="actions-item-wrap">
                        <button class="role-btn">
                          {{ role.name }}
                        </button>
                        <div id="cuRoleshovered" class="actions-item-btns">
                          <span class="menu-hover">
                            <button mat-menu-item (click)="showRights(i)">
                              <figure>
                                <img [src]="'/assets/img/edit-icon.svg' | CDNUrlRewritePipe" alt="" />
                              </figure>
                              <span>{{ labels?.Edit }}</span>
                            </button>
                            <!-- <button mat-menu-item>
                              <figure>
                                <img [src]="'/assets/img/info-icon.svg' | CDNUrlRewritePipe" alt="" />
                              </figure>
                              <span>{{ labels?.Information }}</span>
                            </button> -->
                            <!-- <button mat-menu-item di disabled>
                              <figure>
                                <img src="/assets/img/delete-icon.svg" alt="" />
                              </figure>
                              <span>Delete </span>
                            </button> -->
                          </span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="selectedindex == i" class="read-right-actions">
                      <div class="cu-edit-sol-design">
                        <label>{{ labels?.Solution_Design }} </label>
                        <ul class="sub-assign customCheck">
                          <li id="design-rights">
                            <mat-checkbox
                              id="design-rights-read"
                              [(ngModel)]="
                                changeUnit.designTimeRights &&
                                changeUnit.designTimeRights?.length > 0 &&
                                changeUnit.designTimeRights[getDesignRightsIndex(role)] &&
                                changeUnit.designTimeRights[getDesignRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}
                            </mat-checkbox>
                            <mat-checkbox
                              id="design-rights-write"
                              [(ngModel)]="
                                changeUnit.designTimeRights &&
                                changeUnit.designTimeRights?.length > 0 &&
                                changeUnit.designTimeRights[getDesignRightsIndex(role)] &&
                                changeUnit.designTimeRights[getDesignRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(changeUnit.designTimeRights[getDesignRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div class="cu-edit-transaction">
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li id="transaction-rights">
                            <mat-checkbox
                              id="transaction-rights-read"
                              [(ngModel)]="
                                changeUnit.txnTimeRights &&
                                changeUnit.txnTimeRights?.length > 0 &&
                                changeUnit.txnTimeRights[getTxnRightsIndex(role)] &&
                                changeUnit.txnTimeRights[getTxnRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              id="transaction-rights-write"
                              [(ngModel)]="
                                changeUnit.txnTimeRights &&
                                changeUnit.txnTimeRights?.length > 0 &&
                                changeUnit.txnTimeRights[getTxnRightsIndex(role)] &&
                                changeUnit.txnTimeRights[getTxnRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(changeUnit.txnTimeRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                            <mat-checkbox
                              id="transaction-rights-execute"
                              [(ngModel)]="
                                changeUnit.txnTimeRights &&
                                changeUnit.txnTimeRights?.length > 0 &&
                                changeUnit.txnTimeRights[getTxnRightsIndex(role)] &&
                                changeUnit.txnTimeRights[getTxnRightsIndex(role)].executionRight
                              "
                              (change)="autoCheckRights(changeUnit.txnTimeRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Execute }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>

                      <div class="cu-edit-cond-rights">
                        <div>
                          <button class="cond-rights-label">
                            {{ labels?.Conditional_Rights }}
                          </button>
                          <!-- <label></label> -->
                          <div class="d-flex">
                            <div class="col-md-6 cstm-dateinput-style">
                              <div class="role-name-wrapp">
                                <div class="date-input">
                                  <input
                                    placeholder="{{ labels?.From_Date }}"
                                    (dateInput)="onKey1($event)"
                                    [matDatepicker]="fromDate"
                                    [(ngModel)]="inputValue1"
                                  />
                                  <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                  <mat-datepicker #fromDate></mat-datepicker>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 cstm-dateinput-style">
                              <div class="role-name-wrapp">
                                <div class="date-input">
                                  <input
                                    placeholder="{{ labels?.To_Date }}"
                                    (dateInput)="onKey2($event)"
                                    [matDatepicker]="toDate"
                                    [min]="inputValue1"
                                    [disabled]="!inputValue1"
                                    (dateChange)="enableCondition()"
                                    [(ngModel)]="inputValue2"
                                  />
                                  <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                  <mat-datepicker #toDate></mat-datepicker>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="potentiality-btn" *ngIf="enableConditionBtn">
                            <button (click)="openCondition()">Add Conditional Rights Condition</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div>
                  <mat-radio-group
                    name="agentsType"
                    [(ngModel)]="agentType"
                    (change)="agentTypeChanged($event?.value)"
                    fxLayout="column"
                  >
                    <mat-radio-button id="selectMemberHuman" name="agent" value="human">{{
                      labels?.Human
                    }}</mat-radio-button>
                    <mat-radio-button id="selectMemberMachine" name="agent" value="machine">{{
                      labels?.Machine
                    }}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- commenting Action button as it's no longer required as same functionality is implemented in design system by shubham -->
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Action Button
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <span>Availability</span><br />
                <mat-form-field floatLabel="never">
                  <label class="mb-3"><strong>Name Your Action Button</strong></label>
                  <input matInput placeholder="" type="text" [(ngModel)]="selected" />
                </mat-form-field>
                <button type="button" class="btn d-block ml-auto" (click)="actionSelected()">Done</button>
              </div>
            </mat-expansion-panel> -->
            <mat-expansion-panel *ngIf="changeUnit?.mindCUList?.length > 0" (opened)="setStep(0)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Nested_Conditions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <li *ngFor="let nestedgsi of changeUnit.mindCUList; let i = index">
                  <div
                    class="nested-posr"
                    
                  >
                    <div class="sub-heading add-more-subhead mb-2 ml-0">
                      <div class="sub-heading-inner">
                        <figure>
                          <img
                            [src]="'/assets/img/icon-nested-small-blue.svg' | CDNUrlRewritePipe"
                            alt=""
                          />
                        </figure>
                        <div class="assign-rights">
                          <div class="actions-item-wrap">
                            <div class="nested-gsi-btn">
                              <h2 *ngIf="!nestedgsi.triggerConditionName">
                                {{ nestedgsi.nestedCUName }}
                              </h2>
                              <h2 *ngIf="nestedgsi.triggerConditionName">
                                {{ nestedgsi.nestedCUName }} ({{ nestedgsi.triggerConditionName | dcd }})
                              </h2>
                            </div>
                            <div class="actions-item-btns nested-gsihover">
                              <ul id="rolesSubWrapHover">
                                <li> <button  id="alternateCuHover" (click)="editNested(i)">
                                  <figure>
                                    <img [src]="'/assets/img/edit-icon.svg' | CDNUrlRewritePipe" alt="" />
                                  </figure>
                                  <span>{{ labels?.Edit }}</span>
                                </button>
                                </li>
                                <li>
                                <button
                                 
                                  id="alternateCuHoverDelete"
                                  (click)="deleteNested(i)"
                                  *ngIf="nestedgsi?.triggerConditionName"
                                >
                                  <figure>
                                    <img [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe" alt="" />
                                  </figure>
                                  <span>{{ labels?.Delete }}</span>
                                </button></li>
                              </ul>
                            </div>
                          </div> 
                        </div>
                        
                       
                      </div>
                    </div>
                    <!-- <mat-menu #afterMenu="matMenu" xPosition="after" id="rolesSubWrapHover">
                      <span
                        class="menu-hover"
                        (mouseenter)="menuenter()"
                        (mouseleave)="menuLeave(createPlanmenuTrigger)"
                        
                      >
                        <button mat-menu-item id="alternateCuHover" (click)="editNested(i)">
                          <figure>
                            <img [src]="'/assets/img/edit-icon.svg' | CDNUrlRewritePipe" alt="" />
                          </figure>
                          <span>{{ labels?.Edit }}</span>
                        </button>
                        <button
                          mat-menu-item
                          id="alternateCuHoverDelete"
                          (click)="deleteNested(i)"
                          *ngIf="nestedgsi?.triggerConditionName"
                        >
                          <figure>
                            <img [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe" alt="" />
                          </figure>
                          <span>{{ labels?.Delete }}</span>
                        </button> -->
                        <!-- <button mat-menu-item>
                          <figure>
                            <img [src]="'/assets/img/info-icon.svg' | CDNUrlRewritePipe" alt="" />
                          </figure>
                          <span id="InforLayerCLick">{{ labels?.Information }}</span>
                        </button> -->
                      <!-- </span>  -->
                     <!-- </mat-menu>   -->
                  </div>
                  <div *ngIf="currentNestedIndex === i">
                    <div class="no-label w-100 mb-3">
                      <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                        <mat-expansion-panel
                          *ngIf="physicalLayer?.length > 0"
                          id="accordianExpansionPhysical_cuRecursive"
                        >
                          <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                            <mat-panel-title class="active-bold">
                              <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }} </span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <app-node-expand-layers
                            [layerData]="physicalLayer"
                            [tempGsiObj]="tempGsiObj"
                            [cuName]="configurationData.data.name"
                            [configurationData]="configurationData"
                            [layerLabel]="labelForPhyscialLayer"
                            [changeUnit]="changeUnit"
                            [setPrimaryKeySearch]="changeUnit"
                          >
                          </app-node-expand-layers>
                        </mat-expansion-panel>
                      </mat-accordion>
                      <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                        <mat-expansion-panel *ngIf="informationLayer?.length > 0" id="expansionPanel_GSIRecursive">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="active-bold">
                              <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <app-node-expand-layers
                            [layerData]="informationLayer"
                            [tempGsiObj]="tempGsiObj"
                            [cuName]="configurationData.data.name"
                            [configurationData]="configurationData"
                            [layerLabel]="labelForInformationLayer"
                            [changeUnit]="changeUnit"
                            [setPrimaryKeySearch]="changeUnit"
                          >
                          </app-node-expand-layers>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>

                    <div>
                      <span class="addmem">Always Trigger</span>
                      <mat-radio-group
                        name="nestedFlagRadio"
                        [(ngModel)]="nestedFlag"
                        fxLayout="column"
                        (change)="resetNestedCondition()"
                      >
                        <mat-radio-button name="nestedFlag_Yes" id="nestedFlag_Yes" [value]="true">
                          {{ labels?.Yes }}
                        </mat-radio-button>
                        <mat-radio-button name="nestedFlag_No" id="nestedFlag_No" [value]="false">
                          {{ labels?.No }}
                        </mat-radio-button>
                      </mat-radio-group>
                      <div *ngIf="!nestedFlag">
                        <div class="valuePluscount d-flex">
                          <span class="mr-2" *ngIf="nestedFormulaId">{{ nestedFormulaId | dcd }} </span>
                          <span>{{ nestedOperator | OperatorsPipe: 'display' }}</span>
                          <div class="add-value-alternate">
                            <input matInput placeholder="Enter Value" [(ngModel)]="nestedAttrValue" />
                          </div>
                        </div>
                        <ul class="operators" id="expressionOperators">
                          <div class="no-label w-75">
                            <mat-label>{{ labels?.Select_Nested_Conditions }}</mat-label>
                            <mat-form-field appearance="outline">
                              <mat-select [(ngModel)]="nestedOperator">
                                <mat-option *ngFor="let opt of operators" [value]="opt"
                                  >{{ opt | OperatorsPipe: 'operator' }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </ul>
                      </div>
                      <div class="add-more-condition">
                        <button class="nestedsave" (click)="saveNestedCondition()">Save</button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Metadata }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let item of ontologyList; let i = index">
                <mat-form-field appearance="fill">
                  <mat-label>{{labels?.Select_Ontology}}</mat-label>
                  <mat-select
                    name="item.name"
                    [(ngModel)]="item.id"
                    panelClass="select-panel"
                    id="choosecategory{{ i }}"
                    [placeholder]="i == 0 ? labels?.Choose_Category : labels?.Choose_SubCategory"
                    (selectionChange)="onChangeOntology(item.id, i)"
                  >
                    <mat-form-field class="search-select" floatLabel="never">
                      <input
                        matInput
                        type="text"
                        id="parentontologyInput"
                        class="search-input-cls"
                        [(ngModel)]="item.searchText"
                        [ngModelOptions]="{ standalone: true }"
                        [placeholder]="labels?.Search"
                      />
                      <button
                        mat-button
                        *ngIf="item.searchText"
                        matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="onClearClick(item)"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                    <mat-option>{{ labels?.None }}</mat-option>
                    <mat-option
                      *ngFor="let ontology of item.children | filter: item.searchText"
                      [value]="ontology"
                      id="{{ 'ontology_' + ontology.displayName }}"
                    >
                      {{ ontology.displayName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-auto" *ngIf = "(metaInfo?.ontology?.length > 0 || showSubCategory)">
                <button mat-icon-button (click)="onClickActionIcons()" id="addsubcategory">
                  <mat-icon>add</mat-icon>
                  {{ labels?.Add_Sub_Category }}
                </button>
              </div>
              <mat-form-field class="description-textarea">
                <textarea matInput rows="5" placeholder="Description Text" [(ngModel)]="metaInfo.description"></textarea>
              </mat-form-field>
              <mat-form-field class="description-textarea" id="author-ontology">
                <textarea
                  matInput
                  rows="1"
                  [placeholder]="labels?.Author"
                  [ngModel]="metaInfo.author && metaInfo?.author?.name"
                ></textarea>
              </mat-form-field>
              <mat-form-field class="description-textarea" id="keyword-ontology">
                <textarea matInput rows="1" [placeholder]="labels?.Keywords" [(ngModel)]="metaInfo.keywords"></textarea>
              </mat-form-field>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel *ngIf="!isNested">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Add_Conditional_Potentiality }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <div>
                  <div class="exptext">{{ labels?.Expression }}</div>
                  <span *ngIf="tCUConditionalPotentialityNames?.length > 0" class="m-l-20">
                    <p
                      class="display-flex"
                      *ngFor="let condition of tCUConditionalPotentialityNames; let i = index"
                      class="w-auto d-flex"
                    >
                      ({{ condition | dcd }})
                      <a href="javascript:;" id="hoveredDeleteIcons" (click)="removeConditionalCriteria(changeUnit, i)">
                        <figure>
                          <img
                            [src]="'/assets/img/delete-icon.svg' | CDNUrlRewritePipe"
                            class="condition-icon"
                            alt=""
                          />
                        </figure>
                      </a>
                    </p>
                  </span>

                  <div class="d-flex align-items-baseline expression-form">
                    <span class="mr-2" *ngIf="potentialityAttr">{{ potentialityAttr | dcd }}</span>
                    <span class="mr-2" *ngIf="currentOperator">{{ currentOperator }}</span>
                    <mat-form-field floatLabel="never">
                      <mat-label>{{ labels?.Enter_Value }}</mat-label>
                      <input
                        matInput
                        placeholder="Enter Value"
                        type="text"
                        autocomplete="off"
                        [(ngModel)]="cuAttributeValue"
                        (keypress)="checkTargetInput($event)"
                        autofocus
                      />
                    </mat-form-field>
                  </div>
                  <div class="operators-wrap flex-column">
                    <a href="javascript:;" (click)="changeOfAttributeValueatCu()" class="d-inline-block mb-3">
                      <img [src]="'assets/img/icon-green-plus.svg' | CDNUrlRewritePipe" class="condition-icon" alt="" />
                      {{ labels?.Add }}
                    </a>
                    <ul class="operators expression-form-operators">
                      <li (click)="currentOperator = op" *ngFor="let op of operators">
                        {{ op }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="no-label conditional-po">
                <mat-accordion class="playerdata header-0" id="accordianPhysical_GSIRecursive">
                  <mat-expansion-panel *ngIf="physicalLayer?.length > 0" id="accordianExpansionPhysical_cuRecursive">
                    <mat-expansion-panel-header id="physical_Layer_cuRecursive">
                      <mat-panel-title class="active-bold">
                        <span id="physcialLayer_cuRecursive">{{ labels?.Physical_Layer }} </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [conditionalPotentiality]="true"
                      [layerData]="physicalLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForPhyscialLayer"
                      [changeUnit]="changeUnit"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion class="ilayerdata header-0" id="accordian_GSIRecursive">
                  <mat-expansion-panel *ngIf="informationLayer?.length > 0" id="expansionPanel_GSIRecursive">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="active-bold">
                        <span id="infoLayr_GSIRecursive">{{ labels?.Information_Layer }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-node-expand-layers
                      [conditionalPotentiality]="true"
                      [layerData]="informationLayer"
                      [tempGsiObj]="tempGsiObj"
                      [cuName]="configurationData.data.name"
                      [configurationData]="configurationData"
                      [layerLabel]="labelForInformationLayer"
                      [changeUnit]="changeUnit"
                    >
                    </app-node-expand-layers>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-expansion-panel> -->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Time_and_Space }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="cal-height">
                <p-calendar
                  [(ngModel)]="estimatedTime"
                  name="estimatedTime"
                  showSeconds="true"
                  showTime="showTime"
                  [timeOnly]="true"
                  (onSelect)="onSelectEstimatedTime()"
                ></p-calendar>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="!isNested">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Shades_Of_Potentiality }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="create-btn">
                <button mat-stroked-button (click)="onSOPCreate(true)">{{ labels?.Create }}</button>
                <button mat-stroked-button (click)="onSOPCreate(false)">{{ labels?.Close }}</button>
              </div>
            </mat-expansion-panel>
            <div>
              <app-hide-record-additional-info
                [disableSTEs]="disableSTEs"
                [canvas]="'grammar'"
                (hideRecordAdditionalInfo)="onhideRecordAdditionalInfoChange($event)"
              ></app-hide-record-additional-info>
            </div>
            <mat-expansion-panel *ngIf="changeUnit?.reservedCUType == 'NSL_Template_SearchResult'">
              <mat-expansion-panel-header id="mat_panel_header_isFacet">
                <mat-panel-title id="mat_panel_titel_isFacet">
                  {{labels?.is_Facet}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="cal-height" id="mat_panel_toggle_isFacet">
                <span id="enable_isFacet">{{labels?.is_Facet}}</span>
                <mat-slide-toggle [(ngModel)]="changeUnit.isFaceted"></mat-slide-toggle>
                <!-- <span id="disable_isFacet">Disable</span> -->
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
