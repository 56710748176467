import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig, TransactionFacadeService } from '@common-services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-currentTime',
  templateUrl: './currentTime.component.html',
  styleUrls: ['./currentTime.component.scss'],
})
export class currentTimeComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  time: any;
  currentTime: any;
  addAttribute: Subject<any> = new Subject();
  addAttribute$ = this.addAttribute.asObservable();
  removeAttribute: Subject<any> = new Subject();
  constructor(private transacationFacadeService: TransactionFacadeService) {}
  ngOnInit() {
    // let timeDetails = new Date();

    // let timeArray = this.field?.value.split(':');

    // timeDetails.setHours(timeArray[0]);

    // timeDetails.setMinutes(timeArray[1]);

    // timeDetails.setSeconds(timeArray[2]);
    // let offset = timeDetails.getTimezoneOffset();
    // let curr = new Date(timeDetails.getTime() + offset * 60000);
    // this.currentTime = curr.getHours() + ':' + curr.getMinutes() + ':' + curr.getSeconds();
    // this.field.value = this.currentTime;
    this.group.controls[this.field?.attribute?.name].setValue(
      this.field?.value
    );
    this.transacationFacadeService.disableSubmitButtonInteger(false);
  }
}
