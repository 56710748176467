import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  TranslatorService,
  EventsFacadeService,
  AlertService,
  TransactionFacadeService,
  getCurrentEventDataArray,
  EventsEndpointService,
  getUiControlCustomization,
} from '@common-services';
import { KeyValue } from '@angular/common';
import * as _ from 'lodash';
interface onSelectCustomization {
  id: String;
  gsiName: String;
  displayType: String;
  displayText: String;
  imageUrl: String;
  eventType: String;
}

@Component({
  selector: 'app-nested-form',
  templateUrl: './nested-form.component.html',
  styleUrls: ['./nested-form.component.scss'],
})
export class NestedFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() entity: any;
  @Input() showNext: boolean;
  @Input() showSubmit: boolean;
  @Input() control: any;
  @Input() submitted: any;
  @Input() group: any;
  @Input() isReadOnly: any;
  @Input() eventCUList: any;
  @Input() isInfo: any;
  @Input() crmTemplate = false;
  @Input() selectedIndexChange: any;
  @Output() submitEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() formValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAttributes: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChangedEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() nestedEntityAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() disableSubmitEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeNestedEntity: EventEmitter<any> = new EventEmitter<any>();

  //netsed entities
  @Output() nestedEntitySelections: EventEmitter<any> = new EventEmitter<any>();
  @Output() nestedEntityTableSelections: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Output() nestedEntityRemoveTableSelections: EventEmitter<
    any
  > = new EventEmitter<any>();
  @Output() clickableOnSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedCards: any = [];
  selectedIndex = 1;
  template: any;
  unsubscribe$ = new Subject();
  form: FormGroup;
  addButtonShape: string;
  removeButtonShape: string;
  waypointsData: any;
  isSubmit: boolean = false;
  /*istanbul ignore next*/
  get value() {
    return this.form.value;
  }
  labels: any;
  filedData: any = [];
  currentEventData: any = [];
  accordianIndex: any = -1;
  tenantName: string;
  rowNumberChangedAfterReload: any = { rows: 10, first: 0 };
  integNestedEnt = ['NSL_Geolocation'];
  integDisplayEnt = ['NSL_Geolocation', 'NSL_DateRange', 'NSL_DateTimeRange'];
  parse: Function = JSON.parse;
  allCustomizations: onSelectCustomization[] = [];

  constructor(
    private fb: FormBuilder,
    private translator: TranslatorService,
    private eventsService: EventsFacadeService,
    private alertService: AlertService,
    private transactionFacadeService: TransactionFacadeService,
    private eventsEndPoint: EventsEndpointService
  ) {
    this.detectLanguageChange();
    this.detectSubmitting();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes?.selectedIndexChange?.previousValue &&
      changes?.selectedIndexChange?.currentValue >
        changes?.selectedIndexChange?.previousValue
    ) {
      this.selectedIndex = 0;
    } else if (changes?.selectedIndexChange?.currentValue == 1) {
      this.selectedIndex = 1;
    }
  }

  ngOnInit(): void {
    if (this.eventsService.accordianIndex != -1) {
      this.accordianIndex = this.eventsService.accordianIndex;
    }
    this.tenantName = localStorage.getItem('TenantName');
    this.template = sessionStorage.getItem('template');
    this.getActionButtonData();
  }
  flag = true;
  selectedOption: string;
  appliedClass: string;
  imgSrc: string = '';
  isTextActionButton: boolean = true;
  getActionButtonData() {
    let textOption = getUiControlCustomization('ActionButton');
    if (textOption) {
      this.appliedClass = 'btn on-select-btn ';
    }
    this.selectedOption = textOption;
    switch (textOption) {
      case 'Option 1':
        this.appliedClass += 'btn-rounded btn-dark';
        break;
      case 'Option 2':
        this.appliedClass += 'btn-radius btn-dark';
        break;
      case 'Option 3':
        this.appliedClass += 'btn-square btn-dark';
        break;
      case 'Option 4':
        this.appliedClass += 'btn-rounded btn-opacity';
        break;
      case 'Option 5':
        this.appliedClass += 'btn-radius btn-opacity';
        break;
      case 'Option 6':
        this.appliedClass += 'btn-square btn-opacity';
        break;
      case 'Option 7':
        this.appliedClass += 'btn-rounded btn-light';
        break;
      case 'Option 8':
        this.appliedClass += 'btn-radius btn-light';
        break;
      case 'Option 9':
        this.appliedClass += 'btn-square btn-light';
        break;
      case 'Option 10':
        this.appliedClass += 'btn-rounded btn-outline';
        break;
      case 'Option 11':
        this.appliedClass += 'btn-radius btn-outline';
        break;
      case 'Option 12':
        this.appliedClass += 'btn-square btn-outline';
        break;
      case 'Option 13':
        this.appliedClass += 'btn-line';
        break;
      case 'Option 14':
        this.appliedClass += 'btn-outline btn-square';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-small.svg';
        break;
      case 'Option 15':
        this.appliedClass += 'btn-outline btn-square';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-circle.svg';
        break;
      case 'Option 16':
        this.appliedClass += 'btn-noprop';
        break;
      case 'Option 17':
        this.appliedClass += 'btn-noprop';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-small.svg';
        break;
      case 'Option 18':
        this.appliedClass += 'btn-noprop';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-circle.svg';
        break;
      case 'Option 19':
        this.appliedClass += 'btn-dark btn-full-rounded';
        this.imgSrc =
          '../../../../../../assets/img/ui-kits/btn-arrow-md-light.svg';
        this.isTextActionButton = false;
        break;
      case 'Option 20':
        this.appliedClass += 'btn-dark btn-full-rounded btn-radius';
        this.imgSrc =
          '../../../../../../assets/img/ui-kits/btn-arrow-md-light.svg';
        this.isTextActionButton = false;
        break;
      case 'Option 21':
        this.appliedClass += 'btn-dark btn-full-rounded btn-square';
        this.imgSrc =
          '../../../../../../assets/img/ui-kits/btn-arrow-md-light.svg';
        this.isTextActionButton = false;
        break;
      case 'Option 22':
        this.appliedClass += 'btn-full-rounded btn-outline';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-lg.svg';
        this.isTextActionButton = false;
        break;
      case 'Option 23':
        this.appliedClass += 'btn-outline btn-full-rounded btn-radius';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-lg.svg';
        this.isTextActionButton = false;
        break;
      case 'Option 24':
        this.appliedClass += 'btn-outline btn-full-rounded btn-square';
        this.imgSrc = '../../../../../../assets/img/ui-kits/btn-arrow-lg.svg';
        this.isTextActionButton = false;
        break;
    }
  }
  getShowAsLabelConfig(attributeConfiguration: any) {
    if (isJSON(attributeConfiguration)) {
      let attrconf = JSON.parse(attributeConfiguration);
      return attrconf.isReadOnly ? true : false;
    } else {
      return false;
    }
  }

  hideattribute(nestedForm1){
    return (nestedForm1?.value?.value)[0]?.isHidden === true ;
  }
  expand(ind: any, key: any) {
    if (
      JSON.parse(key.value.value.attributeType).type == 'entity' &&
      key.key.split('$')[0] === 'Lead Details Overview'
    ) {
      // if (key.key.split('$')[0] === 'Lead Details Overview' && ind === 1 && this.flag)
      this.flag = false;
      return true;
    }
    return false;
  }

  detectLanguageChange() {
    this.translator.languageLables$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.labels = res;
      });
  }

  detectSubmitting() {
    this.transactionFacadeService.submitButtonEmit$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        this.isSubmit = true;
      });
  }

  onOpened(ind: any, key: any) {
    this.eventsService.accordianIndex = ind;
    this.accordianIndex = ind;
  }

  close(ind: any) {
    this.eventsService.accordianIndex = -1;
    this.accordianIndex = -1;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList: any = [];
      /*istanbul ignore next*/
      validations?.forEach((valid: any) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  //Uploading data from excel/csv
  fileUploadData(
    event: any,
    entity: any,
    entityControls: any,
    entityFom: any,
    formcontrol: any,
    index: number
  ) {
    this.filedData = event.fileData;
    this.filedData.forEach((obj: any, ind: any) => {
      this.addMultipleEntityControlNested(
        entity,
        entityControls,
        entityFom,
        formcontrol,
        index,
        obj
      );
      index++;
    });
    this.nestedEntityAdded.emit(this.group);
  }

  getMultiEntityButton(configuration: string) {
    if (configuration) {
      let confg = JSON.parse(configuration);
      if (
        confg?.showMultiEntConf &&
        confg?.showMultiEntConf?.showMultiEntText == true
      ) {
        this.addButtonShape = confg?.showMultiEntConf?.addButtonShape;
        this.removeButtonShape = confg?.showMultiEntConf?.removeButtonShape;
        return true;
      }
    }
    return false;
  }

  displayAddButton(configuration: string) {
    if (configuration) {
      let confg = JSON.parse(configuration);
      return confg?.showMultiEntConf?.addButton;
    }
  }

  displayRemoveButton(configuration: string) {
    if (configuration) {
      let confg = JSON.parse(configuration);
      return confg?.showMultiEntConf?.removeButton;
    }
  }

  addMultipleNestedControl(
    nestedForm: any,
    controls: any,
    nested: any,
    nestedControl: any,
    i: any,
    event?: any
  ) {
    if (event !== 'multiFileUpload') {
      const validations = this.eventsService.getValidations(
        controls[0].value.constraints,
        nestedControl.value.inputType
      );
      /*istanbul ignore next*/
      controls.push(
        this.fb.group({
          [nestedForm]: ['', this.bindValidations(validations || [])],
          // [nestedControl.value.id]: ['', this.bindValidations(validations || [])],
          attributeType: nestedControl.value.attributeType,
          attr_id: nestedControl.value.attr_id,
          constraints: [nestedControl.value.constraints],
          attrtype: nestedControl.value.attrtype,
          labelType: nestedControl.value.labelType,
          label: nestedControl.value.label,
          inputType: nestedControl.value.inputType,
          validations: [validations],
          isMulti: false,
          recordId: nestedControl.value.recordId
            ? nestedControl.value.recordId
            : '',
          value: nestedControl.value.value,
          attr_options: [nestedControl.value.attr_options],
          isInPotentiality: nestedControl.value.isInPotentiality,
          isDisabled: nestedControl.value.isDisabled,
          triggerConditionalPotentiality:
            nestedControl.value.triggerConditionalPotentiality,
          attr_configuration: nestedControl.value.attr_configuration,
          // optional change drivers related flags starts //
          alternativeChangeDriver: nestedControl.value?.alternativeChangeDriver
            ? nestedControl.value?.alternativeChangeDriver
            : '',
          isOptional: nestedControl.value?.isOptional,
          isNegative: nestedControl.value?.isNegative,
          // optional change drivers related flags ends //
        })
      );
      const obj = Object.assign({}, nested.value.value[0]);
      obj[obj.label] = '';
      obj.value = '';
      nested.value.value.push(obj);
    }
    this.formValueChange.emit(this.group);
  }

  dateChnagedEvent(event: any) {
    this.dateChangedEmit.emit(event);
  }

  addDynamicAttributes(event: any) {
    this.formValueChange.emit(this.group);
  }
  removeDynamicAttributes(event: any) {
    this.removeAttributes.emit(this.group);
  }

  addMultipleEntityControl(
    entity: any,
    entityControls: any,
    entityFom: any,
    form: any,
    index: number,
    event: any
  ) {
    /*istanbul ignore next*/
    let configuration = entityControls?.configuration?.value
      ? JSON.parse(entityControls.configuration.value)
      : {};
    if (
      localStorage.getItem('TenantName') == 'healthcaremx' ||
      (configuration?.type == 'table' &&
        configuration?.setting?.allowDuplication) ||
      (configuration?.type == 'card' &&
        configuration?.cardSettings?.allowDuplicationCard) ||
      configuration?.allowDuplication
    ) {
      event.stopPropagation();
      let tempform: any;
      /*istanbul ignore else*/
      if (entityControls?.tablecardAttrCount?.value > 0) {
        for (let [key, value] of Object.entries(entityControls)) {
          /*istanbul ignore next*/
          if (value['value'] && value['value']?.constructor.name === 'Array') {
            /*istanbul ignore next*/
            if (
              value['value'][0].label.split('$')[1] >=
              entityControls.tablecardAttrCount.value
            ) {
              delete entityControls[key];
            }
          }
        }
        let crypto: Crypto = window.crypto;
        let array = new Uint32Array(1);
        let randomNumber = crypto.getRandomValues(array)[0];
        randomNumber = Number('0.' + randomNumber);
        /*istanbul ignore next*/
        entityControls.txnRecordId.value = Math.floor(
          randomNumber * 100000000000 + 1
        );
        /*istanbul ignore next*/
        tempform = this.fb.group(entityControls);
      } else {
        let crypto: Crypto = window.crypto;
        let array = new Uint32Array(1);
        let randomNumber = crypto.getRandomValues(array)[0];
        randomNumber = Number('0.' + randomNumber);
        /*istanbul ignore next*/
        entityControls.txnRecordId.value = Math.floor(
          randomNumber * 100000000000 + 1
        );
        /*istanbul ignore next*/
        tempform = this.fb.group(entityControls);
      }

      const newFormGroup: any = _.cloneDeep(tempform);
      entityFom[entity + '$' + index] = newFormGroup;
      /*istanbul ignore next*/
      form.value.value[entity + '$' + index] = Object.assign(
        {},
        entityFom[entity + '$' + index].value
      );
      entityFom[entity + '$' + index].controls =
        entityFom[entity + '$' + index].controls;
      this.nestedEntityAdded.emit(this.group);
    } else {
      this.nestedEntityAdded.emit({
        id: entityControls?.enty_id?.value,
        slot: entityControls?.slot?.value,
        data: {
          txnRecordId: form?.value?.controls?.txnRecordId?.value,
          isParentMultiEntity: form?.value?.controls?.isMulti?.value,
          isMulti: entityControls?.isMulti?.value,
          entityName: form?.value?.controls?.displayName?.value,
        },
      });
    }
  }

  addMultipleEntityControlNested(
    entity: any,
    entityControls: any,
    entityFom: any,
    form: any,
    index: number,
    fileDataObj: any
  ) {
    //const control = Object.assign({}, entityControls);
    let tempform: any;
    /*istanbul ignore else*/
    if (entityControls?.tablecardAttrCount?.value > 0) {
      for (let [key, value] of Object.entries(entityControls)) {
        /*istanbul ignore else*/
        if (value['value'] && value['value'].constructor.name === 'Array') {
          /*istanbul ignore else*/
          if (
            value['value'][0].label.split('$')[1] >=
            entityControls.tablecardAttrCount.value
          ) {
            delete entityControls[key];
          }
        }
      }
      tempform = this.fb.group(entityControls);
    } else {
      tempform = this.fb.group(entityControls);
    }
    var newFormGroup: any = _.cloneDeep(tempform);
    for (let [key, value] of Object.entries(newFormGroup.value)) {
      if (value?.constructor.name === 'Array') {
        if (
          value[0].inputType == 'date' ||
          value[0].inputType == 'currentdate' ||
          value[0].inputType == 'datetime' ||
          value[0].inputType == 'currentdatetime'
        ) {
          const dd = new Date(fileDataObj[value[0].label.split('$')[0]]);
          dd.setDate(dd.getDate() + 1);
          value[0].value = dd;
          value[0][value[0].label] = dd;
        } else {
          value[0].value = fileDataObj[value[0].label.split('$')[0]];
          value[0][value[0].label] = fileDataObj[value[0].label.split('$')[0]];
        }
      }
    }

    entityFom[entity + '$' + index] = newFormGroup;
    var obj1 = JSON.parse(
      JSON.stringify(entityFom[entity + '$' + index].value)
    );
    /*istanbul ignore next*/
    form.value.value[entity + '$' + index] = Object.assign({}, obj1);
    /*istanbul ignore next*/
    entityFom[entity + '$' + index].controls =
      entityFom[entity + '$' + index].controls;
    // this.removeValues(entityFom[entity + '$' + index].value);
  }

  //Removing the nested entity from the list
  deleteEntityControl(
    entity: any,
    entityControls: any,
    entityFom: any,
    form: any,
    index: number,
    event: any
  ) {
    let count = 0;
    for (let [key, value] of Object.entries(entityFom)) {
      /*istanbul ignore else*/
      if (value['value'] && value['value'].constructor.name === 'Object') {
        /*istanbul ignore else*/
        if (key.split('$')[0] === entity.split('$')[0]) {
          count++;
        }
      }
    }
    /*istanbul ignore next*/
    if (count > 1) {
      delete form.value.value[entity];
      this.formValueChange.emit(this.group);
    } else {
      this.alertService.showToaster(
        'Cannot remove if only one Nested Entity is present',
        '',
        'warn'
      );
    }
  }

  nestedEntitiesAdded(event: any) {
    if (localStorage.getItem('TenantName') == 'healthcaremx') {
      this.nestedEntityAdded.emit(this.group);
    } else {
      this.nestedEntityAdded.emit(event);
    }
  }

  disableSubmitEvent(event: any) {
    this.disableSubmitEmit.emit(event);
  }

  getControlsName(control: any) {
    /*istanbul ignore next*/
    return control.value.value?.entType === 'entity';
  }

  getShowType(control: any) {
    if (control?.value?.value?.configuration) {
      let confg = JSON.parse(control?.value?.value?.configuration);
      if (confg?.showType) {
        return true;
      }
    }
    return false;
  }

  getConfigurationType(control: any) {
    /*istanbul ignore next*/
    return control.value.value?.configurationType !== 'listType';
  }

  getConfigurationTableOrCard(control: any) {
    /*istanbul ignore next*/
    return (
      control.value.value?.configurationType === 'table' || control.value.value?.configurationType === 'verticalTable'
    );
  }

  getConfigurationTable(control: any) {
    return control.value.value?.configurationType === 'table';
  }

  getConfigurationVerticalTable(control: any) {
    return control.value.value?.configurationType === 'verticalTable';
  }

  getConfigurationCard(control: any) {
    /*istanbul ignore next*/
    return (
      control.value.value?.configurationType === 'card' ||
      control.value.value?.configurationType === 'cardWithTemplate'
    );
  }

  // getting the dynamics attributes of table
  getSelectedTransactions(event: any) {
    this.nestedEntityTableSelections.emit(event);
  }

  removeEntityfromTable(event: any) {
    this.nestedEntityRemoveTableSelections.emit(event);
  }

  getSelectedCards(event: any) {
    this.nestedEntitySelections.emit(event);
  }

  removeCards(event: any) {
    this.removeNestedEntity.emit(event);
  }

  /*istanbul ignore next*/
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  // Save event GSI data
  saveEventGSI(currEvent: any, nestedForm: any) {
    if (this.isInfo) {
      return;
    }
    this.formValueChange.emit(this.group);
    /*istanbul ignore next*/
    let attrData = nestedForm?.value?.value?.[0];
    /*istanbul ignore next*/
    const data = {
      attributeId: attrData?.attr_id,
      isTableConfig: attrData?.isTableConfig ? attrData?.isTableConfig : false,
      attrName: attrData?.label,
      eventType: 'ON_SELECT',
      entityName: attrData?.entityName,
      entityId: attrData?.entityId,
      slotNumber: attrData?.slotNumber,
      isInfo: attrData?.isInfo,
      isMulti: attrData?.isMultiEntity,
      recordId: attrData?.recordId,
      attribute_type: attrData?.inputType,
      attribute_displayName: attrData?.attr_displayName,
      isPopupEvent: currEvent?.isPopupEvent,
    };
    data['gsiId'] = currEvent?.gsiId;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }

  saveAllCustomizations(nestedForm: any) {
    this.allCustomizations = [];
    let currAttrConf = JSON.parse(
      nestedForm?.value?.value[0]?.attr_configuration
    );
    if (currAttrConf && currAttrConf?.allCustomizations?.length > 0) {
      currAttrConf?.allCustomizations?.forEach((evt: any) => {
        // let eventCustomisation = currAttrConf?.allCustomizations;
        let customization: onSelectCustomization = {
          id: nestedForm?.value?.value[0].attr_id,
          gsiName: evt?.gsiName,
          displayType: evt?.displayType,
          displayText: evt?.displayText,
          imageUrl: evt?.imageUrl,
          eventType: evt?.eventType,
        };
        this.allCustomizations.push(customization);
      });
    }

    if(this.allCustomizations.length > 0 && (localStorage.getItem("TenantName") !== 'ramcodev')){
      this.allCustomizations = this.allCustomizations.filter((evt: any) => evt.eventType === "ON_SELECT") ;
    }
  }

  // Check event data based on attribute id
  checkEventGSI(nestedForm: any) {
    /*istanbul ignore next*/
    this.currentEventData = getCurrentEventDataArray(
      this.eventCUList,
      nestedForm?.value?.value[0]
    );
    if (this.currentEventData.length > 0) {
      if (
        JSON.parse(nestedForm?.value?.value[0].attr_configuration)
          ?.clickableConfig?.enableClickableCustomization
      ) {
        let res = {
          id:
            nestedForm?.value?.value[0]?.attr_id +
            '_' +
            nestedForm?.value?.value[0]?.slotNumber,
          event: this.currentEventData[0],
        };
        this.eventsEndPoint.emitEventClickable.next(res);
      }
      this.saveAllCustomizations(nestedForm);
      return true;
    }
    return false;
  }

  rowNumberChangeAfterReload(event: any) {
    this.rowNumberChangedAfterReload = event;
  }

  isSubmitbuttonenabled(event: any) {
    this.transactionFacadeService.submitButtonDisabling(event);
  }

  /**
   * @param entity Contains the entity name
   * @param entityFom Entity Form details including list of entities
   * @description give the total count of entities
   * @author Rahul Kumar
   */
  getNestedFormValueLength(entity: any, entityFom: any) {
    const entname = entity?.split('$')[0];
    let entities: any = [];
    Object.keys(entityFom)?.forEach((ent: any) => {
      /* istanbul ignore next */
      if (ent?.split('$')[0] === entname) {
        entities.push(ent?.split('$')[0]);
        if (entities.length > 1) return entities.length;
      }
    });
    return entities.length;
  }
  getHideLabelStatus(attributeConfiguration: string) {
    if (isJSON(attributeConfiguration)) {
      let attrconf = JSON.parse(attributeConfiguration);
      return attrconf.hideLabel ? true : false;
    } else {
      return false;
    }
  }
  getWaypointsData(event: any) {
    this.waypointsData.emit(event);
  }

  getClickableOnSelectData(res: any) {
    Object.keys(this.control?.value?.controls).forEach((each: any) => {
      let arr = each.split('$');
      if (arr.length > 1) {
        if (
          this.control?.value?.controls[each]?.value?.[0]?.attr_id == res.id &&
          res.slot ==
            this.control?.value?.controls[each]?.value?.[0]?.slotNumber
        ) {
          this.saveEventGSI(this.currentEventData[0], {
            value: this.control?.value?.controls[each],
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

export function isJSON(str: string): boolean {
  let x: any;
  try {
    x = JSON.parse(str);
  } catch (e) {
    return false;
  }
  if (typeof x == 'object' && x !== null) {
    return true;
  } else {
    return false;
  }
}
