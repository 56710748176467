import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-demo-pop-up',
  templateUrl: './demo-pop-up.component.html',
  styleUrls: ['./demo-pop-up.component.scss'],
})
export class DemoPopUpComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DemoPopUpComponent>, private rendrer: Renderer2) {}

  clock: string = '';
  clockHandle: any;
  ngOnInit() {
    this.clockHandle = setInterval(() => {
      this.clock = this.getTimeFormat();
    }, 1000);
  }
  ngAfterContentInit() {
    let element = document.getElementById('mat-dialog-0');
    this.rendrer.addClass(element, 'dialog-class');
  }
  ngAfterViewInit() {
    let element = document.getElementById('mat-dialog-0');
    this.rendrer.addClass(element, 'dialog-class');
  }
  getTimeFormat() {
    let hours = new Date().getHours() - 12 > 0 ? new Date().getHours() - 12 : new Date().getHours();
    let minutes = new Date().getMinutes();
    let seconds = new Date().getSeconds();
    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
