import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, EventsEndpointService, AlertService, getUiControlCustomization, validateDependentExpression } from '@common-services';

import { Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { SPACE } from '@angular/cdk/keycodes';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-multiselectdropdown',
  styleUrls: ['./multiselectdropdown.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <div>
      <form class="demo-full-width" [formGroup]="group">
        <div
          [ngClass]="
            !foundObject?.event || foundObject?.event == ''
              ? 'ui-custom-select'
              : foundObject?.event == 'Option 8'
              ? 'multiselect-parent-class-styles'
              : ''
          "
        >
          <div class="d-flex justify-content-between nh-column-gap-10 sub-left-class">
            <div class="d-flex align-items-center nh-mb-8 nh-mw-0">
              <label
                pTooltip="{{ field.label }}"
                id="{{ field?.attribute?.name }}"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
                class="main-label nh-mb-0"
                [style.color]="field.color"
                [style.font-size.px]="field.fontSize"
                *ngIf="
                  (field?.sentence == null || field?.sentence === '') &&
                  !field?.hideLabels &&
                  !field?.configuration?.hideLabel &&
                  !field?.hideLabelMultiAttr
                "
                ><span class="main-label-inner"
                  ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
                  <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker"
                    >*</span
                  ></span
                ></label
              >
              <p
                class="nh-ml-6 d-flex"
                *ngIf="field?.configuration?.showHelperTextIcon"
                [ngStyle]="{
                  color: field?.configuration?.helperTextColor,
                  'font-size': field?.configuration?.helperTextFont
                }"
              >
                <mat-icon
                  class="material-icons-outlined helperText-infoIcon"
                  [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
                  matTooltip="{{ field?.configuration?.helpertext }}"
                  matTooltipPosition="above"
                  *ngIf="!field?.hideLabels"
                  >info_outline</mat-icon
                >
              </p>
            </div>
            <div
              class="d-flex align-items-center nh-column-gap-5 pb-2"
              *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            >
              <mat-icon
                matTooltip="Sub-Transactional CU"
                matSuffix
                class="d-flex align-items-center justify-content-center"
                *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
              >
                <span class="sub-cu" (click)="openSubPopUp()">
                  <img class="d-block" src="../assets/img/transaction/trigger-att.svg" />
                </span>
              </mat-icon>
            </div>
          </div>
          <p
            class="top-text"
            *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            {{ field?.configuration?.helpertext }}
          </p>
          <div class="{{ appliedClass }} sub-right-class" [ngClass]="{
              inValidClass:
                !field?.isInfo &&
                (group?.controls)[field?.attribute?.name]?.invalid &&
                (group?.controls)[field?.attribute?.name]?.touched
            }">
            <mat-form-field appearance="fill" floatLabel="never">
              <span
                *ngIf="foundObject?.event == 'Option 8' && field?.value?.length == 0"
                class="label-flex-row-placeholder"
                >Select Items</span
              >
              <mat-select
                #select
                [(ngModel)]="field.value"
                [formControlName]="field?.attribute?.name"
                id="{{ field?.attribute?.name }}"
                [disabled]="field.readonly"
                [required]="field?.isRequired && !field?.isInfo"
                multiple
                (selectionChange)="onSelectionChange($event)"
                (focus)="onFocus($event)"
                [ngClass]="!foundObject?.event || foundObject?.event == '' ? 'ui-custom-multi-select-dropdown' : ''"
                panelClass="{{ appliedPanelClass }} {{ dropdownClass }} {{ tenantName === 'ramcodev' ? 'ramcodev-dropdown-options' : '' }} {{ options?.length == 0 ? 'ui-custom-multiSel-noOptions': ''}}"
              >
                <mat-select-trigger>
                  <mat-chip-list>
                    <mat-chip
                      *ngFor="let selectedOption of field.value"
                      [pTooltip]="field?.configuration?.hideTooltip ? '' : selectedOption"
                      tooltipPosition="top"
                      tooltipStyleClass="transcustomtolltipbook"
                      [disabled]="field.readonly"
                      [removable]="!field?.isInfo"
                      (removed)="removeSelectedOption(selectedOption)"
                    >
                      {{ selectedOption?.length > 5 ? selectedOption.slice(0, 5) + '...' : selectedOption }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </mat-select-trigger>
                <div class="dropdown-search" *ngIf="!field?.configuration?.hideSearchBar" >
                  <input
                    type="text"
                    class="input-search"
                    [(ngModel)]="searchText"
                    id="searchText"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Search"
                    (ngModelChange)="onSearch($event)"
                    *ngIf="options?.length >= 0"
                  />
                  <figure *ngIf="options?.length >= 0">
                    <img src="../../../../assets/img/icon-search.svg" />
                  </figure>
                </div>
                <div class="option-select-all" *ngIf="!field?.isInfo && options?.length > 0">
                  <mat-checkbox
                    [(ngModel)]="allSelect"
                    #selectAllCheckbox
                    [ngModelOptions]="{ standalone: true }"
                    (change)="toggleAllSelection()"
                    [value]="allSelect"
                    >{{ selectDeselectValue }} All</mat-checkbox
                  >
                </div>
                <div class="dropdown-scroll">
                  <mat-option
                    *ngFor="let option of options"
                    [pTooltip]="field?.configuration?.hideTooltip ? '' : option?.displayValue"
                    tooltipPosition="top"
                    id="{{ option?.displayValue }}"
                    [value]="option.actualValue"
                    [disabled]="field?.isInfo || (isDisabled && field?.value?.indexOf(option?.actualValue) == -1)"
                  >
                    {{ option?.displayValue }}
                  </mat-option>
                  <mat-option [disabled]="true" *ngIf="options?.length === 0">No items found </mat-option>
                </div>
                <div
                  *ngIf="
                    foundObject?.event == 'Option 1' ||
                    foundObject?.event == 'Option 2' ||
                    foundObject?.event == 'Option 3' ||
                    foundObject?.event == 'Option 4'
                  "
                  class="selected-items"
                >
                  Selected {{ field?.value?.length }} Item(s)
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-icon
          matTooltip="Sub-Transactional CU"
          matSuffix
          *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
          ><span class="sub-cu" (click)="openSubPopUp()"></span>
        </mat-icon>
        <p
          class="bottom-text"
          *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
          [ngStyle]="{
            color: field?.configuration?.helperTextColor,
            'font-size': field?.configuration?.helperTextFont
          }"
        >
          {{ field?.configuration?.helpertext }}
        </p>
        <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
          <mat-error
            *ngIf="
              validation.type &&
              (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
              getErrorMessage(field, validation)
            "
            >{{ validation.message }}</mat-error
          >
          <mat-error
            [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
            *ngIf="
              validation.type &&
              validation.type !== 'ERROR' &&
              validation.type !== 'BLOCK_WARN' &&
              getInfoWarnMessage(field, validation)
            "
            >{{ validation.message }}</mat-error
          >
        </ng-container>
      </form>
    </div>
  `,
  styles: [],
})
export class MultiselectdropdownComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  searchText: string;
  options: any[];
  previousSelectedValues: any = [];
  previousFieldLength: any;
  private _onDestroy = new Subject<void>();
  private scrollTopBeforeSelection: number;
  @ViewChild(MatSelect, { static: true }) multiSelect: MatSelect;
  @ViewChild(MatOption, { static: true }) matoption: MatOption;
  @ViewChild('select') select: any;
  @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;

  selectDeselectValue = 'Select';
  allSelect = false;
  clickedOnSingleItem = true;
  prevScroll: any = -1;
  ngUnsubscribe = new Subject();
  pageNumber: any = 2;
  refAttributes: any;
  stopScroll: boolean = true;
  showSelectAllCheckbox: boolean = true;
  scrolledDataGot: boolean = false;
  searchData: string;
  foundObject: any;
  appliedClass: string = '';
  appliedPanelClass: string = 'ui-custom-select-options ui-custom-select-multiple';
  tenantName: any;
  isDisabled: boolean = false;
  dropdownClass: string = '';

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacade: TransactionFacadeService,
    private eventEndpointService: EventsEndpointService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.tenantName = localStorage.getItem('TenantName');
    this.multiSelect?.openedChange.subscribe((open: any) => {
      /*istanbul ignore next*/ if (open) {
        // this.multiSelect.panel.nativeElement.addEventListener('blur',(event:any)=>{
        //
        //   event.stopImmediatePropagation()

        //   event.preventDefault('scroll');
        // })
        this.multiSelect.panel.nativeElement.addEventListener('scroll', (event: any) => {
          this.scrollTopBeforeSelection = event.target.scrollTop;
          //
          this.onInfiniteScroll(event);
          event.preventDefault();
        });
        this.multiSelect?.optionSelectionChanges.subscribe(() => {
          if (this.multiSelect?.panel?.nativeElement?.scrollTop >= 0) {
            this.multiSelect.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
          }
        });
        this.select._handleKeydown = (event: KeyboardEvent) => {
          if (event.keyCode == SPACE) return;
          if (!this.select.disabled) {
            this.select.panelOpen ? this.select._handleOpenKeydown(event) : this.select._handleClosedKeydown(event);
          }
        };
      }
    });
    /*istanbul ignore next*/
    this.multiSelect?.optionSelectionChanges.subscribe(() => {
      this.multiSelect.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
    });
    /*istanbul ignore next*/
    this.options = this.field?.options?.filter((x: any) => x?.displayValue && x?.actualValue);
    // this.sortSelectedOptionsList();

    if (this.field?.options?.length == this.field?.value?.length) {
      this.allSelect = true;
      this.selectDeselectValue = 'DeSelect';
    }
    // console.log(this.options);
    if (this.field?.attribute['isTableConfig'] && Array.isArray(this.field?.value)) {
      this.field.value = this.field?.value.flat();
    }
    this.previousSelectedValues = this.field.value;
    this.previousFieldLength = this.field?.value?.length;
    let multiSelectOption = getUiControlCustomization('MultiSelect');
    if (multiSelectOption) {
      this.foundObject = { event: multiSelectOption };
      if (multiSelectOption !== 'Option 8') {
        this.appliedClass = 'form-custom-select ';
        this.appliedPanelClass = 'form-custom-select-options form-custom-select-multiple ';
        switch (multiSelectOption) {
          case 'Option 1':
            this.appliedClass += 'selected-lable';
            break;
          case 'Option 2':
            this.appliedClass += 'form-custom-select-opt2';
            this.appliedPanelClass += 'form-custom-select-options22';
            break;
          case 'Option 3':
            this.appliedClass += 'form-custom-select-opt2';
            this.appliedPanelClass += 'form-custom-select-options2 form-custom-select-options22';
            break;
          case 'Option 4':
            this.appliedClass += 'selected-lable';
            this.appliedPanelClass += 'form-custom-select-options2';
            break;
          case 'Option 5':
            this.appliedClass += 'form-custom-select-opt3 selected-lable';
            this.appliedPanelClass += 'form-custom-select-options3';
            break;
          case 'Option 6':
            this.appliedClass += 'form-custom-select-opt3 selected-lable';
            this.appliedPanelClass += 'form-custom-select-options4';
            break;
          case 'Option 7':
            this.appliedClass += 'form-custom-select-opt4 selected-lable';
            this.appliedPanelClass += 'form-custom-select-options22 form-custom-select-options5';
            break;
        }
      }
    }
    let size = this.extractSizeSubstring(this.field?.attrClass);
    this.dropdownClass = `ui-custom-${size}-size-options`;
  }

  extractSizeSubstring(input: string): string | null {
    const regex  = /size-(.*?)-uicontrols/;
    const match = input.match(regex);
    return match ? match[1] : null;
  }
  removeSelectedOption(selectedOption: string) {
    if (!this.field?.readonly) {
      if (this.allSelect == true) {
        this.selectDeselectValue = 'Select';
        this.clickedOnSingleItem = true;
        this.allSelect = false;
      }

      /* istanbul ignore next */
      this.select?.options?.forEach((item: any) => {
        if (item.value === selectedOption) item.deselect();
      });
    }
  }
  // onSearch(searchData: string) {
  //   /*istanbul ignore next*/
  //   this.options = this.field?.options?.filter(
  //     (data: any) =>
  //       data?.displayValue &&
  //       data?.actualValue &&
  //       data?.displayValue?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
  //   );
  // }


  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  initMultipleHandling(values: any) {
    // store previously selected values and restore them when they are deselected
    // because the option is not available while we are currently filtering
    let restoreSelectedValues = false;
    /*istanbul ignore next*/
    if (
      this.searchText &&
      this.searchText?.length &&
      this.previousSelectedValues &&
      Array.isArray(this.previousSelectedValues)
    ) {
      /*istanbul ignore next*/
      if (!values || !Array.isArray(values)) {
        values = [];
      }
      const optionValues = this.options?.map((option: any) => option.value);
      /* istanbul ignore next */
      this.previousSelectedValues?.forEach((previousValue) => {
        if (values.indexOf(previousValue) === -1 && optionValues.indexOf(previousValue) === -1) {
          // if a value that was selected before is deselected and not found in the options, it was deselected
          // due to the filtering, so we restore it.
          values.push(previousValue);
          restoreSelectedValues = true;
        }
      });
    }
    /*istanbul ignore next*/
    if (restoreSelectedValues) {
      this.field.value = values;
    }
    this.previousSelectedValues = values;
  }

  onClearClick(value?: any) {
    let options: any[] = [];
    value?.forEach((val: any) => {
      let option = this.options.find((option: any) => {
        return option?.actualValue == val;
      });
      if(option && option?.actualValue){
        options.push(option);
      }
    });
    this.searchText = '';
    /*istanbul ignore next*/
    const tempOptions = this.field?.options?.filter((x: any) => x?.displayValue && x?.actualValue);
    tempOptions?.forEach((opt: any) => {
      let index = null;
      options?.forEach((opti: any, ind: any) => {
        if (opti?.actualValue === opt?.actualValue) {
          index = ind;
        }
      });
      if (index != null) {
        options.splice(index, 1);
        index = null;
      }
    });

    this.options = [...options, ...tempOptions];
    this.group.controls['attr_options'].setValue(this.options);
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (validation?.name == 'valuesSelect') {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacade
      );
    }
    return this.group.get(String(this.field?.attribute?.name))?.status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (validation?.name == 'valuesSelect') {
      return validateDependentExpression(
        validation,
        this.eventsService,
        this.field,
        this.group,
        this.transactionFacade
      );
    }
    if (this.group?.controls[field?.attribute?.name]?.value) {
      return (this.group?.controls[field?.attribute?.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  /**
   * Determines whether dropdown value is changed
   * @param event Contains the changed value
   */
  onSelectionChange(event: any) {
    if (this.field?.value?.length == this.field?.configuration?.minmaxRange?.maximumselection) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
     /* istanbul ignore next */
    if (event?.value?.length < this.field?.configuration?.minmaxRange?.minimumselection) {
      this.alertService.showToaster(
        'please select options in minimum ' +
          this.field?.configuration?.minmaxRange?.minimumselection +
          ' and maximum ' +
          this.field?.configuration?.minmaxRange?.maximumselection +
          ' range only',
        '',
        'info'
      );
       /* istanbul ignore next */
      if(!this.field.isOptional)
      {
        this.transactionFacade.minSelection.next({ name: this.field?.attribute?.['name'], flag: false });
      }
    } else {
       /* istanbul ignore next */
      if(!this.field.isOptional)
      {
        this.transactionFacade.minSelection.next({ name: this.field?.attribute?.['name'], flag: true });
      }
    }
    this.initMultipleHandling(event.value);
    this.onClearClick(event.value);
    const data = {
      attributeId: this.field?.attribute?.['id'],
      isTableConfig: this.field?.attribute?.['isTableConfig'],
      attrName: this.field?.attribute?.['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    let checkingTheCheckBox = false;
    if (this.previousFieldLength < event?.value?.length) checkingTheCheckBox = true;

    if (this.allSelect && this.selectDeselectValue == 'DeSelect' && !this.clickedOnSingleItem) {
      /* istanbul ignore else */
      if (event?.source?.options?.last?.value == event?.value[event?.value?.length - 1] && checkingTheCheckBox) {
        if (this.field.triggerConditionalPotentiality) {
          this.eventsService.setTriggerEvent(data);
        } else {
          this.eventsService.setEvent(data);
        }
      } else if (event?.value?.length == this.options?.length - 1 && !checkingTheCheckBox) {
        this.allSelect = false;
        this.selectDeselectValue = 'Select';
        this.clickedOnSingleItem = true;
        this.selectAllCheckbox.checked = false;
      }
    } else if (!this.allSelect && this.selectDeselectValue == 'Select' && !this.clickedOnSingleItem) {
      /* istanbul ignore else */
      if (event?.value?.length == 0) {
        if (this.field.triggerConditionalPotentiality) {
          this.eventsService.setTriggerEvent(data);
        } else {
          this.eventsService.setEvent(data);
        }
      }
    } else {
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
    }
    this.previousFieldLength = event?.value?.length;

    this.updateAllSelect();
    // this.sortSelectedOptionsList();
  }

  /**
   * Determines whether input field is focused
   * @param event Contains the input value
   */
  onFocus(event: any) {
    /* istanbul ignore next */
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute?.['name'],
      eventType: 'ON_FOCUS',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    /* istanbul ignore else */
    this.eventsService.setEvent(data);
    /* conditional potentiality check  */
    /* if (this.field.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent(data);
    } */
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  updateAllSelect() {
    if (Array.isArray(this?.field?.options) && Array.isArray(this.field?.value) && this.field.value.length > 0) {
      if (this.field.value.length === this.field.options.length) {
        this.allSelect = true;
        this.selectAllCheckbox.checked = true;
        this.selectDeselectValue = 'DeSelect';
      } else {
        this.allSelect = false;
        this.selectAllCheckbox.checked = false;
        this.selectDeselectValue = 'Select';
      }
    }
  }

  // function to toggle select all vs deselect all
  toggleAllSelection() {
    /* istanbul ignore else */
    if (this.allSelect) {
      if (this.select?.options?.length > this.field?.configuration?.minmaxRange?.maximumselection) {
        this.selectAllCheckbox.checked = false;
        this.alertService.showToaster(
          'you can select options in minimum ' +
          this.field?.configuration?.minmaxRange?.minimumselection +
          ' and maximum ' +
          this.field?.configuration?.minmaxRange?.maximumselection +
          ' range only',
          '',
          'warning'
        );
      } else {
        this.selectDeselectValue = 'DeSelect';
        this.clickedOnSingleItem = false;
        /* istanbul ignore next */
        this.select?.options?.forEach((item: MatOption) => item?.select());
      }
    } else {
      this.selectDeselectValue = 'Select';
      this.clickedOnSingleItem = false;
      /* istanbul ignore next */
      this.select.options?.forEach((item: MatOption) => item?.deselect());
    }
  }

  // sortSelectedOptionsList() {
  //   this.options?.forEach((option: any, index: number) => {
  //     let ind = this.field?.value?.indexOf(option.actualValue);
  //     if (ind !== -1) {
  //       this.options?.splice(index, 1);
  //       this.options?.unshift(option);
  //     }
  //   });
  //   console.log(this.options);
  // }
  /* istanbul ignore next */
  onInfiniteScroll(event: any) {
    if (
      event.target.scrollTop >= event.target.scrollHeight * 0.8 &&
      event.target.scrollTop > this.prevScroll &&
      (this.transactionFacade.depDropdownPayloadOnLoad || this.transactionFacade?.depDropdownPayloadOnChange) &&
      !this.scrolledDataGot
    ) {
      const cuDetails = this.transactionFacade.depDropdownPayloadOnLoad;
      /* istanbul ignore next */
      if (cuDetails?.currentCU?.eventCUList?.length > 0) {
        cuDetails.currentCU.eventCUList.sort(this.sortOrderEvent('eventType'));
        const referenceEntityInfo = cuDetails.currentCU.eventCUList.find((ele: any) => {
          const attributeReferences = ele.referenceEntityInfo.attributeReferences.find((refAtt: any) => {
            const tempArrayString = refAtt.targetAttributeId.split('.');
            return this.field.attribute.id == tempArrayString[tempArrayString?.length - 1].slice(2) &&
            refAtt?.referenceValueType == 'OPTIONS';
          });
          if (this.transactionFacade?.depDropdownPayloadOnChange && attributeReferences) {
            this.refAttributes = attributeReferences;
            return true;
          } else if (this.transactionFacade.depDropdownPayloadOnLoad && attributeReferences) {
            if (ele.eventType === 'ON_CHANGE') {
              return false;
            }
            this.refAttributes = attributeReferences;
            return true;
          }
        });
        if (
          referenceEntityInfo &&
          this.stopScroll &&
          (referenceEntityInfo.eventType === this.transactionFacade.depDropdownPayloadOnLoad.type ||
            referenceEntityInfo.eventType === this.transactionFacade?.depDropdownPayloadOnChange?.type)
        ) {
          this.scrolledDataGot = true;
          this.eventEndpointService
            .getDependentDropdownDetails(
              this.transactionFacade?.depDropdownPayloadOnChange?.currentCU?.id == cuDetails?.currentCU?.id
                ? this.transactionFacade?.depDropdownPayloadOnChange
                : cuDetails,
              referenceEntityInfo.eventType,
              referenceEntityInfo.changeDriverId,
              this.pageNumber,
              referenceEntityInfo.eventType === 'ON_LOAD' ? 500 : 100,
              this.searchData,
              this.refAttributes.refDisplayAttributeName
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: any) => {
              res.result.forEach((key: any) => {
                this.scrolledDataGot = false;
                if (
                  key.refActualAttributeId === this.refAttributes.refActualAttributeId &&
                  key.refDisplayAttributeId === this.refAttributes.refDisplayAttributeId &&
                  key.targetAttributeId === this.refAttributes.targetAttributeId
                ) {
                  if (key?.options?.length == 0) {
                    this.stopScroll = false;
                  }
                  key.options.forEach((ele: any) => {
                    this.options.push(ele);
                    this.field.options.push(ele);
                  });
                }
              });
              // if (res.result[0].options.length == 0) {
              //   this.stopScroll = false;
              // }
              // res.result[0].options.forEach((ele: any) => {
              //   this.options.push(ele);
              // });
            });
          this.prevScroll = event.target.scrollTop;
          this.pageNumber = this.pageNumber + 1;
        }
      }
    }
  }
  sortOrderEvent(prop: any) {
    /* istanbul ignore next */
    return function (a: any, b: any) {
      if (a[prop] >= b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }
  /* istanbul ignore next */
  onSearch(searchData: string) {
    /* istanbul ignore next */
    this.searchData = searchData;
    this.pageNumber = 1;
    this.scrolledDataGot = false;
    this.stopScroll = true;
    const cuDetails = this.transactionFacade?.depDropdownPayloadOnLoad;
    /* istanbul ignore next */
    if (cuDetails?.currentCU?.eventCUList?.length > 0) {
      cuDetails.currentCU.eventCUList.sort(this.sortOrderEvent('eventType'));
      const referenceEntityInfo = cuDetails.currentCU.eventCUList.find((ele: any) => {
        const attributeReferences = ele?.referenceEntityInfo?.attributeReferences.find((refAtt: any) => {
          const tempArrayString = refAtt.targetAttributeId.split('.');
          return this.field.attribute.id == tempArrayString[tempArrayString?.length - 1].slice(2) &&
          refAtt?.referenceValueType == 'OPTIONS';
        });
        if (this.transactionFacade?.depDropdownPayloadOnChange && attributeReferences) {
          this.refAttributes = attributeReferences;
          return true;
        } else if (this.transactionFacade.depDropdownPayloadOnLoad && attributeReferences) {
          if (ele.eventType === 'ON_CHANGE') {
            return false;
          }
          this.refAttributes = attributeReferences;
          return true;
        }
      });
      if (
        referenceEntityInfo &&
        this.stopScroll &&
        (referenceEntityInfo.eventType === this.transactionFacade.depDropdownPayloadOnLoad.type ||
          referenceEntityInfo.eventType === this.transactionFacade?.depDropdownPayloadOnChange?.type)
      ) {
        this.eventEndpointService
          .getDependentDropdownDetails(
            this.transactionFacade?.depDropdownPayloadOnChange?.currentCU?.id == cuDetails?.currentCU?.id
              ? this.transactionFacade?.depDropdownPayloadOnChange
              : cuDetails,
            referenceEntityInfo.eventType,
            referenceEntityInfo.changeDriverId,
            this.pageNumber,
            referenceEntityInfo.eventType === 'ON_LOAD' ? 500 : 100,
            searchData,
            this.refAttributes.refDisplayAttributeName
          )
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            res.result.forEach((key: any) => {
              if (
                key.refActualAttributeId === this.refAttributes.refActualAttributeId &&
                key.refDisplayAttributeId === this.refAttributes.refDisplayAttributeId &&
                key.targetAttributeId === this.refAttributes.targetAttributeId
              ) {
                if (key?.options?.length == 0) {
                  this.stopScroll = false;
                }
                let options: any[] = [];
                if (typeof this.field.value === 'string') {
                  this.field.value = [];
                }
                this.field?.value?.forEach((val: any) => {
                  let option = this.options?.find((option: any) => {
                    return option?.actualValue == val;
                  });
                  if (option && option?.actualValue){
                    options.push(option);
                  }
                });
                const tempOptions = key?.options ? key?.options?.filter((x: any) => x?.displayValue && x?.actualValue) : [];
                tempOptions?.forEach((opt: any) => {
                  let index = null;
                  options?.forEach((opti: any, ind: any) => {
                    if (opti?.actualValue === opt?.actualValue) {
                      index = ind;
                    }
                  });
                  if (index != null) {
                    options.splice(index, 1);
                    index = null;
                  }
                });
                this.options = [...tempOptions, ...options];
                this.group.controls['attr_options'].setValue(this.options);
              }
            });
          });
        this.pageNumber = this.pageNumber + 1;
      } else {
        this.options = this.field?.options?.filter(
          (data: any) =>
            data?.displayValue &&
            data?.actualValue &&
            data?.displayValue?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
        );
      }
    } else {
      this.options = this.field?.options?.filter(
        (data: any) =>
          data?.displayValue &&
          data?.actualValue &&
          data?.displayValue?.toLocaleLowerCase()?.includes(searchData?.toLocaleLowerCase())
      );
    }
    // Check if options length is greater than 0 and search text is empty
    this.showSelectAllCheckbox = this.options.length > 0 || searchData === '';
  }
}
