<div>
  <div
    #readMore
    class="content"
    [innerHTML]="text"
    [ngStyle]="{ height: heightAfterView ? heightAfterView : 'auto', overflow: 'hidden', opacity: '0' }"
  ></div>
  <a class="read-more-color" [ngStyle]="{ color: color }" *ngIf="isCollapsable" (click)="toggleCollapse()">{{
    isCollapsed ? 'Read Less' : '...Read More'
  }}</a>
</div>
