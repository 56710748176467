import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { UploadEndpointService ,EventsEndpointService,LoaderService,AlertService} from '@common-services';
import { TreeNode } from 'primeng/api';
import * as JSZip from 'jszip';
import { HttpEventType } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-folder-upload',
  templateUrl: './folder-upload.component.html',
  styleUrls: ['./folder-upload.component.scss'],
})
export class FolderUploadComponent implements OnInit {
  constructor(
    private eventService: EventsEndpointService,
    private uploadFacadeService: UploadEndpointService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private alertService: AlertService,
  ) {}
  uploadOptions: string[] = ['Folder', 'Document'];
  nodes: TreeNode[] = [];
  path: string = '';
  currentNode: TreeNode;
  isUpload: boolean = false;
  uploadPath: any = '';
  loading: boolean = false;
  selectedUploadOption: string = 'Folder';
  progress: number = 0;
  dummySelectedFiles: TreeNode[] = [];
  @ViewChild('statusPopUp') statusPopUp: TemplateRef<HTMLElement>;
  @ViewChild('deletePopUp') deletePopUp: TemplateRef<HTMLElement>;
  @Input() field: any;
  @Input() control: any;
  numberOfFiles: number = 0;
  get selectedFiles(): TreeNode[] {
    return this.dummySelectedFiles;
  }
  set selectedFiles(value: TreeNode[]) {
    this.dummySelectedFiles = value;
    if (this.folderPath && this.choosenFiles && value?.length > 0) {
      this.handleFolderSelection(value);
    }
  }
  choosenFiles: FormGroup;
  folderPath: FormGroup;
  intitalSelectedFiles: string[] = [];
  totalNumberOfFiles: number = 0;
  calledMainApi: boolean = false;
  IntialSelectedFolder: string = '';
  selectedMainRootFolder: string = '';
  selectedDeleteNode: TreeNode | undefined;
  setControlValues() {
    if (this.control) {
      Object.entries(this.control.value.controls).forEach((attr: any) => {
        if (attr[1]?.controls) {
          Object.entries(attr[1]?.controls).forEach((key1: any) => {
            if (key1[1]?.controls) {
              Object.entries(key1[1]?.controls).forEach((key2: any) => {
                if (key2[0]?.includes('Folder Path')) {
                  this.folderPath = key2[1];
                } else if (key2[0]?.includes('Chosen Files')) {
                  this.choosenFiles = key2[1];
                }
              });
            }
          });
        }
      });
      if (this.choosenFiles.value?.length > 0) {
        this.intitalSelectedFiles = this.choosenFiles.value.split(',');
      }
      if (!this.calledMainApi) {
        this.selectedMainRootFolder = this.folderPath.value ? this.folderPath.value : '';
        this.getFolders(this.selectedMainRootFolder);
        this.calledMainApi = true;
      }
    }
  }
  ngOnChanges() {
    this.setControlValues();
  }
  handleFolderSelection(event: TreeNode[]) {
    let dummyFolderPath: any = [];
    let dummyChoosenFiles: any = [];
    event.forEach((node: TreeNode) => {
      if (node?.leaf) {
        dummyFolderPath = node?.parent?.data?.fullPath
          ? node?.parent?.data?.fullPath.split('/')?.[0]
          : node?.parent?.label;
        dummyChoosenFiles.push(node?.data?.contentUrl);
      }
    });
    this.choosenFiles.patchValue(dummyChoosenFiles);
    this.folderPath.patchValue(this.selectedMainRootFolder? this.selectedMainRootFolder : dummyFolderPath);
  }
  detectFolder(folderData: any) {
    this.loading = false;
    if (folderData.data.length > 0) {
      folderData.data.forEach((folder: any) => {
        if (folder?.name) {
          let node = this.getNode(folder);
          if (!this.currentNode) {
            this.nodes.push(node);
          } else {
            let isSelected = this.selectedFiles.find((file: TreeNode) => file.label == this.currentNode.label);
            if (isSelected || this.intitalSelectedFiles.includes(node?.data?.contentUrl)) this.selectedFiles.push(node);
            if (this.currentNode?.children) {
              this.currentNode.children.push(node);
            } else {
              this.currentNode.children = [node];
            }
          }
        }
      });
    } else {
      this.alertService.showToaster(
        `no data to show`,
        '',
        'error'
      );
    
    }
  }

  getNode(folder: any): TreeNode {
    return {
      label: folder.name,
      data: folder,
      leaf: !folder.isFolder,
    };
  }

  findPath(node: TreeNode, tempArray: any) {
    tempArray.push(node.label);
    if (node.parent) {
      this.findPath(node.parent, tempArray);
    }
    return tempArray;
  }
  getFolders(node: any, isUrl?: boolean) {
    let path = '';
    this.uploadPath = '';
    if (node && typeof node != 'string') {
      if (!node?.data?.fullPath) {
        let tempPathArr: any = [];
        tempPathArr = this.findPath(node, tempPathArr);
        for (let i = tempPathArr.length - 1; i >= 0; i--) {
          path += tempPathArr[i];
          if (i != 0) path += '/';
        }
        if (this.selectedMainRootFolder) {
          path = this.selectedMainRootFolder + '/' + path;
        }
        node.data.fullPath = path;
      } else {
        path = node.data.fullPath;
      }
    } else if (typeof node == 'string') {
      path = node;
      this.nodes = [];
    }
    this.eventService.getAllFiles(path).subscribe((result: any) => {
      this.detectFolder(result);
    },(err)=>{
      this.loading = false;
      this.alertService.showToaster(`Error while fetching data`, '', 'error');
    });
  }

  getNodes(event: any) {
    if (!event?.node?.children) {
      this.currentNode = event.node;
      this.getFolders(event?.node);
      this.loading = true;
    }
  }
  showPopUp() {
    this.dialog.open(this.statusPopUp, { panelClass: 'FolderUploadPopUp' });
  }
  closePopUp() {
    this.progress = 0;
    this.selectedDeleteNode = undefined;
    this.dialog.closeAll();
  }
  async recievePayLoad(event: any) {
    this.closeSideBar();
    this.showPopUp();
    this.numberOfFiles = event.file.length;
    this.totalNumberOfFiles = event.file.length;
    if (!this.numberOfFiles && event.file) {
        let zip: any;
        let path: any;
        if(this.IntialSelectedFolder) path = this.IntialSelectedFolder + '/' + this.uploadPath
        else path = this.uploadPath;
        zip = await this.zipFile(event.file);
      this.uploadZipFiles(zip, path);
    } else if (this.numberOfFiles > 0) {
      let i =0;
      for (const file of event.file) {
        let filePath: any = '';
        if (event?.folders?.length > 0) {
          filePath = event.folders[i].split('/');
          if (filePath[0] == '') filePath.shift();
          filePath.pop();
        } else if (file?.webkitRelativePath) {
          filePath = file?.webkitRelativePath?.split('/');
          filePath.splice(-1, 1);
        }
        if (filePath.length > 0) {
          filePath = this.uploadPath + '/' + filePath?.join('/');
        } else {
          filePath = this.uploadPath;
        }
        let zip = await this.zipFile(file);
        this.uploadZipFiles(zip, filePath);
        i++;
      }
    }
  }
  uploadZipFiles(files: File, path: any) {
    let formDataPay = new FormData();
    formDataPay.append('file', files);
    formDataPay.append('folders', path.replaceAll('/', ',').trim());
    this.uploadFacadeService.uploadZipFiles(formDataPay).subscribe(
      (res) => {
        switch (res?.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress += Math.floor(((res.loaded / res.total) * (100 / this.totalNumberOfFiles)));
            break;
          case HttpEventType.Response:
            {
              this.numberOfFiles -= 1;
              if (this.numberOfFiles == 0) {
                this.progress = 0;
                this.currentNode.children = undefined;
                this.closePopUp();
                this.getFolders(this.currentNode);
                this.alertService.showToaster(
                  `Uploaded All files`,
                  '',
                  'success'
                );
              }
              break;
            }
        }
      },
      (err) => {
        this.numberOfFiles -= 1;
        this.alertService.showToaster(
          `Upload Failed`,
          '',
          'error'
        );
        this.closePopUp();
      }
    );
  }

  async zipFile(file: File): Promise<File> {
    const zip = new JSZip();
    zip.file(file?.name, file);
    let content = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
    const zipBlob = new File([content], 'zippedfiles.zip', { type: 'application/zip' });
    return zipBlob;
  }
  openSideBar(node: TreeNode) {
    this.isUpload = true;
    this.currentNode = node;
    this.uploadPath = node?.data?.fullPath ? node?.data?.fullPath : node?.label;
  }

  closeSideBar() {
    this.isUpload = false;
  }

  ngOnInit(): void {
    this.loading = true;
  }

  showDeletePopUp(node: TreeNode){
    this.selectedDeleteNode = node;
    this.dialog.open(this.deletePopUp, { panelClass: 'FolderUploadPopUp' });
  }

  deleteFile(url: string){
    const payLoad = {
      fileUrl: url,
      operationType: 'DELETE_FILE',
    };
    this.uploadFacadeService.deleteFile(payLoad).subscribe((res)=>{
      if(res){
        console.log('sucess')
      }
    },(err)=>{
      console.log('error')

    })
  }

  deleteFolder(node: TreeNode){
    this.deleteFile(node?.data.contentUrl) ;
    node.parent.children.splice(node.parent.children.indexOf(node), 1);
    this.dialog.closeAll();
  }
}
