import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { TranslatorService } from '../translator/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ManageIntegrationsFacadeService } from '../manage-integrations/manage-integrations-facade.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
})
export class BreadCrumbComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: any;
  @Output() resetCarnivalBets: any = new EventEmitter();
  labels: any;
  private ngUnsubscribe = new Subject();
  breadCrumbList: any = [];

  constructor(
    private router: Router,
    private translator: TranslatorService,
    public manageIntegration: ManageIntegrationsFacadeService
  ) {
    this.detectLanguageChange();
  }

  ngOnChanges(change: SimpleChanges) {
    setTimeout(() => {
      /* istanbul ignore next */
      this.loadBreadCrumbs(change?.type?.currentValue);
    }, 200);
  }

  ngOnInit(): void {}

  loadBreadCrumbs(type: any) {
    switch (type) {
      case 'configure':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Configure', value: 'admin/transactions' },
        ];
        break;

      case 'managetenant':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Tenants, value: 'admin/transactions' },
        ];
        break;

      case 'translationconfig':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Translation_Config, value: 'admin/transactions' },
        ];
        break;

      case 'uicustomization':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Admin, value: 'admin' }];
        break;

      case 'manageSelfOnboardtenant':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Tenants, value: 'admin/transactions' },
        ];
        break;

      case 'admin':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Admin, value: 'admin' }];
        break;

        case 'manageirdr':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_IRDR || 'ManageIRDR', value: 'admin/transactions' },
        ];
        break;

      case 'adminroles':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Roles, value: 'admin/transactions' },
        ];
        break;
        case 'managemcc':
          /* istanbul ignore next */
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: 'managemcc', value: 'admin/transactions' },
          ];
          break;

      case 'adminorgUnit':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Organisation_Unit, value: 'admin/transactions' },
        ];
        break;

      case 'adminusers':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Users, value: 'admin/transactions' },
        ];
        break;

      case 'adminconfigration':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Configuration, value: 'admin/transactions' },
        ];
        break;

      case 'manageDCEO':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_dceo, value: 'admin/transactions' },
        ];
        break;

      case 'admingroups':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Groups, value: 'admin/transactions' },
        ];
        break;

      case 'adminldap':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_ID_Providers, value: 'admin/transactions' },
        ];
        break;

      case 'sfadmindashboard':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Configurations, value: 'admin/transactions' },
        ];
        break;

      case 'addadapter':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Integrations, value: 'admin/transactions' },
          { label: 'Adapter', value: 'adapter' },
        ];
        break;
      case 'manage_personal_connections':
        /*istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Personal Connections', value: 'admin/transactions' },
        ];
        break;
      case 'manage_sub_tenant':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Sub_Tenants, value: 'admin/transactions' },
        ];
        break;
      case 'manage_solutions':
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
        ];
        break;
        case 'facerecognitionconfiguration':
          /* istanbul ignore next */
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: 'manageconfiguration', value: 'admin/transactions' },
          ];
          break;
          case 'hotconfiguration':
            /* istanbul ignore next */
            this.breadCrumbList = [
              { label: this.labels?.Admin, value: 'admin' },
              { label: 'manageconfiguration', value: 'admin/transactions' },
            ];
            break;
      case 'roledelegation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
        ];
        break;

      case 'userdelegation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
        ];
        break;

      case 'exportsolutions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
          { label: this.labels?.Manage_Solutions, value: 'preferences/manage_solutions' },
        ];
        break;

      case 'exporttranslation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
          { label: this.labels?.Manage_Solutions, value: 'preferences/manage_solutions' },
        ];
        break;

      case 'managedata':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Data, value: 'admin/transactions' },
        ];
        break;
      case 'manageconfiguration':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'manageconfiguration', value: 'admin/transactions' },
        ];
        break;
      case 'hotconfiguration':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.manage_configuration, value: 'admin/transactions' },
        ];
        break;
      case 'uicontrolcreation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.manage_configuration, value: 'admin/transactions' },
        ];
        break;
      case 'facerecognitionconfiguration':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'manageconfiguration', value: 'admin/transactions' },
        ];
        break
      case 'managesolutions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Solutions, value: 'admin/transactions' },
        ];
        break;

      case 'manageenv':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Environments, value: 'admin/transactions' },
        ];
        break;

      case 'manageaudit':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Trace_Audit, value: 'admin/transactions' },
        ];
        break;
        case 'usersconfiguration':
          /* istanbul ignore next */
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: 'manageconfiguration', value: 'admin/transactions' },
          ];
          break;
      case 'managegeirdr':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_GE_IRDR, value: 'admin/transactions' },
        ];
        break;
      case 'manageentityrelationship':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Entity_Relationship, value: 'admin/transactions' },
        ];
        break;
      case 'manageSubscription':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage Subscription', value: 'admin/transactions' },
        ];
        break;
      case 'manageBookRoles':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_IRDR_Book, value: 'admin/transactions' },
        ];
        break;
      case 'managePasswordPolicy':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Password_Policy, value: 'admin/transactions' },
        ];
        break;
      case 'manageEnvironments':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Environments, value: 'admin/transactions' },
        ];
        break;
      case 'managesaasapplication':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage Saas Applications', value: 'admin/transactions' },
        ];
        break;
      case 'carnivalroles':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Manage_Roles, value: 'carnival' }];
        break;
      case 'carnivalusers':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Manage_Users, value: 'carnival' }];
        break;
      case 'authenticationPolicy':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Authentication_Policy, value: 'admin/transactions' },
        ];
        break;
      case 'refreshtokenpolicy':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'refreshtokenpolicy', value: 'admin/transactions' },
        ];
        break;
      case 'addKey':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Add_Key, value: 'admin/transactions' },
        ];
        break;
      case 'viewKey':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.View_Key, value: 'admin/transactions' },
        ];
        break;
      case 'tenantsetupandcustomisation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Tenant_Setup_and_Customisation, value: 'admin/transactions' },
        ];
        break;
      case 'viewpermissions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage Permission Sets', value: 'admin/transactions' },
        ];
        break;
        case 'addpermissions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Permission_Sets, value: 'admin/transactions' },
        ];
        break;
      case 'bookownership':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Book Ownership', value: 'admin/transactions' },
        ];
        break;

      case 'viewfeatureflag':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'View Feature Flags', value: 'admin/transactions' },
        ];
        break;

      case 'createfeatureflag':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Create_Feature_Flag, value: 'admin/transactions' },
        ];
        break;

      case 'Bot_Configuration':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Configurations, value: 'admin/transactions' },
        ];
        break;
      case 'tracker':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Tracker, value: 'admin/transactions' },
        ];
      case 'adminSuperset':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Superset_Dashboard, value: 'admin/transactions' },
        ];
        break;
      case 'blockchain_manage':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Blockchain_Management, value: 'admin/transactions' },
        ];
        break;

      case 'AddGsiBlockingConfig':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.GSI_Blocking, value: 'admin/transactions' },
        ];
        break;

        case 'eqlEntityRelation':
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: this.labels?.Eql_Entity_Relation, value: 'admin/transactions' },
          ];
          break;
        case 'adminNotification':
          /* istanbul ignore next */
          this.breadCrumbList = [{ label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.admin_Notification, value: 'admin/transactions' },];
          break;

        case 'ManageLanguage':
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: this.labels?.Manage_Languages, value: 'admin/transactions' },
          ];
          break;
        case 'OTPLockPolicy':
          /* istanbul ignore next */
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: this.labels?.Manage_Configuration || 'Manage Configuration', value: 'admin/transactions' },
          ];
          break;
        case 'view_configuration':
          /* istanbul ignore next */
          this.breadCrumbList = [
            { label: this.labels?.Admin, value: 'admin' },
            { label: this.labels?.Manage_Integrations, value: 'admin/transactions' },
            { label: this.labels?.View_Configuration, value: 'View Configuration' },
          ];
          break;
          case 'resetLockedUsers':
            /* istanbul ignore next */
            this.breadCrumbList = [
              { label: this.labels?.Admin, value: 'admin' },
              { label: this.labels?.Manage_Password_Policy, value: 'admin/transactions' },
            ];
            break;
        case 'sub_admin_config':
            /* istanbul ignore next */
            this.breadCrumbList = [
              { label: this.labels?.Admin, value: 'admin' },
              { label: this.labels?.Sub_Admin_Creation_And_Configuration, value: 'admin/transactions' },
            ];
          break;

    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  openGsis(type: any) {
    if (type === 'mylibrary') {
      this.router.navigateByUrl(`mylibrary-dashboard`);
    } else if (type === 'admin') {
      this.router.navigateByUrl(`admin`);
    } else if (type === 'admin/transactions') {
      this.router.navigateByUrl(`admin/transactions`);
    } else if (type === 'preferences') {
      this.router.navigateByUrl(`preferences`);
    } else if (type === 'preferences/manage_solutions') {
      this.router.navigateByUrl(`preferences/manage_solutions`);
    } else if (type === 'carnival') {
      this.resetCarnivalBets.emit(true);
    } else if (type === 'transaction/my-collection') {
      this.router.navigateByUrl(`transaction/my-collection`);
    } else if (type === 'uicontrolcreation') {
      this.router.navigateByUrl(`admin/manageconfiguration`);
    }else if (type === 'adapter') {
      this.manageIntegration.isAdapterBook = true;
      this.router.navigateByUrl(`admin/transactions`);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
