import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class UploadEndpointService extends CduiBaseService {
  constructor(private http: HttpClient, private injector: Injector) {
    super(injector);
  }
  singleFileUpload(files: any) {
    return this.http.post(this.api?.singleFileUpload, files);
  }
  multipleFileUpload(files: any) {
    return this.http.post(this.api?.multipleFileUpload, files);
  }

  singleERDFileUpload(files: any) {
    // uploading file and taking entities
    return this.http.post(this.api?.uploadERDfile, files, { reportProgress: true, observe: 'events' });
  }

  saveImportedERDEntity(res: any) {
    // saving entities
    return this.http.post(this.api?.saveImportedERDEntity, res);
  }

  getAllExtracedEntity(pageNumber: any, pageSize: any, searchString: string) {
    searchString = searchString || '';
    return this.http.get(
      this.api?.allExtractedEntity + `limit=${pageSize}` + `&page=${pageNumber}` + `&searchCriteria=${searchString}`
    );
  }

  deleteExtractedEntity(extractionId: any) {
    return this.http.delete(this.api?.deleteExtracedEntity + `extractionId=${extractionId}`);
  }

  viewExtractedEntity(extractionId: any) {
    return this.http.get(this.api?.viewExtracedEntity + `extractionId=${extractionId}`);
  }
  /**
   * Uploads base64 file
   * @param payload
   * @returns
   */
  uploadBase64File(payload: any) {
    return this.http.post(this.api?.base64FileUpload, payload);
  }

  /**
   * Updates base64 file
   * @param url
   * @param file
   * @returns
   */
  updateBase64File(url: any, file: any) {
    return this.http.put(url, file);
  }
  uploadZipFiles(formData: FormData) {
    return this.http.post(this.api?.uploadZipFile, formData, { reportProgress: true, observe: 'events' });
  }
  deleteFile(payload: any) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.deleteFolderImage}/fileAccess`, payload);
  }
}
