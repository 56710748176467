import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService, TransactionFacadeService, AlertService, LoaderService, EventsFacadeService, FieldConfig, UploadFacadeService } from '@common-services';
import * as moment from 'moment';
declare var MediaRecorder: any;
@Component({
  selector: 'app-record-video',
  styleUrls: ['./record-video.component.scss'],
  template: `
    <div class="demo-full-width player-custom-video">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex nh-mw-0">
          <label
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            class="main-label"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field.label }} :</span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            >
          </label>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="
            field?.configuration?.hideMultiValueIcon ||
            (field?.isMulti && !field?.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
          "
        >
          <mat-icon
            *ngIf="field?.isMulti && !field?.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            (click)="addAttribute.next()"
            >add
          </mat-icon>
          <mat-icon
            *ngIf="field?.isMulti && !field?.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            (click)="removeAttribute.next()"
            >remove
          </mat-icon>
        </div>
      </div>
      <ng-container>
        <div class="notepad-record" id="video_record">
          <div class="record-actions" id="video_actions">
            <div class="actions-bts" id="video-actions_btn">
              <span
                class="actionbtn"
                id="video_start_btn"
                *ngIf="!openVideo && !recordingCompleted"
                (click)="openVideoRecording()"
              >
                <!-- <mat-icon>videocam</mat-icon> -->
                <figure class="video-icon">
                  <img src="../../../assets/images/ui-controls/icon-video-recording.svg" />
                </figure>
              </span>
              <!-- <button
                class="btn actionbtn"
                id="video_stop_btn"
                *ngIf="isRecordingEnabled"
                (click)="stopVideoRecording()"
              >
                <mat-icon>stop</mat-icon>
              </button> -->
              <!-- <button
                class="btn actionbtn"
                id="video_clear_btn"
                *ngIf="!isRecordingEnabled && recordedBlobs?.length"
                (click)="clearVideoRecordedData()"
              >
                <mat-icon>highlight_off</mat-icon>
              </button> -->
              <!-- <div class="record-time" *ngIf="isRecordingEnabled" id="video_reord_time">
                <span class="record-time-span">{{ videoRecordedTime }} sec</span>
              </div> -->
              <!-- <div *ngIf="timeLimit">
                <span>Time Limit : {{ timeLimit }}</span>
              </div> -->
            </div>
          </div>

          <div class="video-recording-panel" [ngClass]="{ 'd-none': !openVideo }">
            <div class="video-panel-close" (click)="clearVideoRecordedData()">
              <figure><img src="../../../assets/images/ui-controls/panel-close.svg" /></figure>
            </div>

            <div class="recordplayer">
              <video
                [ngClass]="recordingCompleted ? 'videoRec' : ''"
                style="width:100% !important;"
                class="video"
                #video
                autoplay
              ></video>
            </div>

            <div class="panel-footer">
              <div class="record-time" *ngIf="isRecordingEnabled" id="video_reord_time">
                <span class="record-time-span">{{ videoRecordedTime }} sec</span>
              </div>
              <div *ngIf="timeLimit">
                <span>Time Limit : {{ timeLimit }}</span>
              </div>
              <ng-container *ngIf="isRecordingEnabled">
                <figure class="w-auto" *ngIf="!pauseVideo" (click)="pauseAndResumeRecording()">
                  <img src="../../../assets/images/ui-controls/icon-video-pause.svg" />
                </figure>

                <figure class="video-icon" *ngIf="pauseVideo" (click)="pauseAndResumeRecording()">
                  <i class="bi bi-play-fill"></i>
                </figure>

                <figure class="w-auto" (click)="stopVideoRecording()">
                  <img src="../../../assets/images/ui-controls/icon-video-play.svg" />
                </figure>
              </ng-container>
              <ng-container *ngIf="!isRecordingEnabled">
                <figure class="video-icon" (click)="startVideoRecording()">
                  <img src="../../../assets/images/ui-controls/icon-video-recording.svg" />
                </figure>

                <figure class="w-auto" *ngIf="muteAudio" (click)="muteAudio = !muteAudio">
                  <img src="../../../assets/images/ui-controls/icon-video-mute.svg" />
                </figure>

                <figure class="video-icon" *ngIf="!muteAudio" (click)="muteAudio = !muteAudio">
                  <i class="bi bi-volume-up"></i>
                </figure>
              </ng-container>
            </div>
          </div>

          <div class="recordplayer">
            <video
              [ngClass]="recordingCompleted ? '' : 'videoRec'"
              style="width:100% !important; max-width: 290px;"
              controls
              #recordedVideo
              autoplay
            ></video>
          </div>

          <div
            class="record-title"
            id="video_record-title"
            *ngIf="!isRecordingEnabled && recordedBlobs && recordingCompleted"
          >
            <div class="record-btns" id="video_save_div">
              <button
                class="btn"
                id="video_clear_btn"
                *ngIf="!isRecordingEnabled && recordedBlobs?.length"
                (click)="clearVideoRecordedData()"
              >
                <figure class="w-auto"><img src="../../../assets/images/ui-controls/icon-trash.svg" /></figure>
              </button>
              <button class="btn" (click)="saveVideo()" id="video_save_btn">{{ labels?.Save }}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  `,
})
export class RecordVideoComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @ViewChild('recordedVideo') recordVideoElementRef: ElementRef;
  @ViewChild('video') videoElementRef: ElementRef;
  @Output() cancelFlag: EventEmitter<any> = new EventEmitter<any>();
  field: FieldConfig;
  group: FormGroup;
  videoElement: HTMLVideoElement;
  recordVideoElement: HTMLVideoElement;
  labels: any;
  iscarnivalscreen: boolean = false;
  isRecordingEnabled: boolean = false;
  openVideo: boolean = false;
  muteAudio: boolean = false;
  mirrorVideo: boolean = false;
  videoRecordedTime: string;
  videoToSave: FormData;
  mediaRecorder: any;
  recordedBlobs: Blob[];
  downloadUrl: string;
  stream: MediaStream;
  recordingCompleted: boolean = false;
  startVideoTime: any;
  videoInterval: any;
  timeLimit: string = '';
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  pauseVideo: boolean = false;
  pausedStartTime: moment.Moment;

  constructor(
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private transacationFacadeService: TransactionFacadeService,
    private alertService: AlertService,
    private loader: LoaderService,
    private uploadFacadeService: UploadFacadeService
  ) {
    this.detectLanguageChange();
    this.detectCarnivalScreen();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      this.transacationFacadeService.disableSubmitButtonRecordVideo(true);
    }
    if(this.field?.configuration?.hours || this.field?.configuration?.minutes || this.field?.configuration?.seconds){
      /* istanbul ignore next */
      if (this.field?.configuration?.hours) {
        this.timeLimit = this.timeLimit + `${this.field?.configuration?.hours} H :`;
      }else if(this.field?.configuration){
        this.field.configuration.hours = 0;
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.minutes) {
        this.timeLimit = this.timeLimit + `${this.field?.configuration?.minutes} M :`;
      }else if(this.field?.configuration){
        this.field.configuration.minutes = 0;
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.seconds) {
        this.timeLimit = this.timeLimit + `${this.field?.configuration?.seconds} S`;
      }else if(this.field?.configuration){
        this.field.configuration.seconds = 0;
      }
    }
    // this.initiliseVideo();
  }
  /* istanbul ignore next */
  async initiliseVideo(): Promise<void> {
    /* istanbul ignore next */
    const videoConstraints = await navigator?.mediaDevices?.getUserMedia({
      video: {
        width: 360,
      },
      audio: false,
    });
    const audioConstraints = await navigator?.mediaDevices?.getUserMedia({
      video: false,
      audio: true,
    });
    const combinedStream = new MediaStream([
      ...videoConstraints.getVideoTracks(),
      ...audioConstraints.getAudioTracks(),
    ]);
    this.stream = combinedStream;
    this.videoElement = this.videoElementRef?.nativeElement;
    this.recordVideoElement = this.recordVideoElementRef?.nativeElement;
    this.videoElement.srcObject = this.stream;
    this.videoElement.muted = true;
    // combinedStream.then((stream) => {
    //     this.videoElement = this.videoElementRef?.nativeElement;
    //     this.recordVideoElement = this.recordVideoElementRef?.nativeElement;
    //     this.stream = stream;
    //     this.videoElement.srcObject = this.stream;
    //   })
    //   .catch((error: any) => {});
  }

  openVideoRecording() {
    this.openVideo = true;
    this.initiliseVideo();
  }

  mirrorVideoRecording() {
    this.mirrorVideo = !this.mirrorVideo;
    this.videoElement.style.transform = this.mirrorVideo ? 'scaleX(-1)' : 'scaleX(1)';
  }

  startVideoRecording() {
    this.videoRecordedTime = '00:00';
    this.detectRecordingTime();
    this.recordedBlobs = [];
    let options: any = { mimeType: 'video/webm' };
    /* istanbul ignore next */
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
    } catch (err) {
      console.log(err);
    }

    const audioTracks = this.stream.getAudioTracks();
    audioTracks.forEach((track) => {
      track.enabled = !this.muteAudio;
    });
    /* istanbul ignore next */
    this.mediaRecorder?.start(); // collect 100ms of data
    this.isRecordingEnabled = !this.isRecordingEnabled;
    this.onDataAvailableEvent();
    this.onStopRecordingEvent();
  }

  pauseAndResumeRecording() {
    if (this.mediaRecorder?.state == 'recording') {
      this.mediaRecorder?.pause();
      this.pauseVideo = true;
      this.pausedStartTime = moment();
    } else if (this.mediaRecorder?.state == 'paused') {
      this.mediaRecorder?.resume();
      this.pauseVideo = false;
      const pauseDuration = moment.duration(moment().diff(this.pausedStartTime));
      this.startVideoTime.add(pauseDuration);
    }
  }


  stopVideoRecording() {
    this.mediaRecorder?.stop();
    this.isRecordingEnabled = !this.isRecordingEnabled;
    this.recordingCompleted = true;
    /* istanbul ignore next */
    if (this.videoInterval !== undefined) {
      clearInterval(this.videoInterval);
    }
    this.startVideoTime = null;
    this.recordVideoElement.currentTime = Number.MAX_SAFE_INTEGER;
    this.recordVideoElement.muted = false;
    this.openVideo = false;
    this.pauseVideo = false;
  }
  /* istanbul ignore next */
  playRecording() {
    if (!this.recordedBlobs || !this.recordedBlobs?.length) {
      console.log('cannot play.');
      return;
    }
    this.recordVideoElement.play();
  }
  clearVideoRecordedData() {
    this.videoToSave = new FormData();
    this.videoRecordedTime = '';
    this.recordedBlobs = [];
    this.recordingCompleted = false;
    this.isRecordingEnabled = false;
    /* istanbul ignore next */
    if (this.videoInterval !== undefined) {
      clearInterval(this.videoInterval);
    }
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      this.transacationFacadeService.disableSubmitButtonRecordVideo(true);
    }
    this.recordVideoElement.src = null;
    this.openVideo = !this.openVideo;
    this.muteAudio = false;
    this.pauseVideo = false;
    if (this.stream && !this.openVideo) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }
  /* istanbul ignore next */
  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event?.data && event?.data?.size > 0) {
          this.recordedBlobs.push(event?.data);
        }
      };
    } catch (error) {
      console.log(error);
    }
  }

  /* istanbul ignore next */
  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = (event: Event) => {
        const videoBuffer = new Blob(this.recordedBlobs, {
          type: 'video/webm',
        });
        this.downloadUrl = window?.URL?.createObjectURL(videoBuffer); // you can download with <a> tag
        this.recordVideoElement.src = this.downloadUrl;
        let formData = new FormData();
        let file = new File([videoBuffer], 'test.mp4');
        formData.append('file', file);
        this.videoToSave = formData;
      };
    } catch (error) {
      console.log(error);
    }
  }

  /* istanbul ignore next */
  detectRecordingTime() {
    this.startVideoTime = moment();
    this.videoInterval = setInterval(() => {
      if (!this.pauseVideo) {
        const currentTime = moment();
        const diffTime = moment?.duration(currentTime?.diff(this.startVideoTime));
        if (
          diffTime?.minutes() == this.field?.configuration?.minutes &&
          diffTime?.hours() == this.field?.configuration?.hours &&
          diffTime?.seconds() == this.field?.configuration?.seconds + 1
        ) {
          this.stopVideoRecording();
        }
        const time = this.toString(diffTime?.minutes()) + ':' + this.toString(diffTime?.seconds());
        this.videoRecordedTime = time;
      }
    }, 1000);
  }
  /* istanbul ignore next */
  private toString(value: any) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  saveFiles(formDataFAQ: any, fileLength?: any) {
    this.loader.show();
    this.uploadFacadeService
      .dynamicFormUploadFiles(formDataFAQ, fileLength)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data: any) => {
          /* istanbul ignore next */
          if (data && this.field.type === 'videorecording') {
            if (Array.isArray(this.field.value)) {
              /* istanbul ignore next */
              this.field.value[0] = JSON?.stringify(data);
            } else {
              /* istanbul ignore next */
              this.field.value = JSON?.stringify(data);
            }
            this.group.controls[this.field.attribute.name].setValue(JSON?.stringify(data));
            this.transacationFacadeService.disableSubmitButtonRecordVideo(false);
            this.loader.hide();
          }
        },
        /* istanbul ignore next */
        (err: any) => {
          this.alertService.showToaster(err?.message, '', 'error');
        }
      );
  }
  saveVideo() {
    /* istanbul ignore next */
    if (this.videoToSave && this.field.type === 'videorecording') {
      this.saveFiles(this.videoToSave, 1);
    }
  }

  detectCarnivalScreen() {
    this.transacationFacadeService.carnivalScreen$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        this.iscarnivalscreen = res;
      }
    });
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  ngOnDestroy() {
    this.eventsService.isPhysicalLayerVideos(false);
    /* istanbul ignore next */
    navigator?.mediaDevices
      ?.getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        var vidTrack = stream?.getTracks();
        vidTrack?.forEach((track) => {
          track.enabled = false;
          setTimeout(() => {
            track.stop();
          }, 1000);

        });
      });
    let tracks: any = this.stream?.getTracks();
    // now close each track by having forEach loop
    /* istanbul ignore next */
    tracks?.forEach((track: any) => {
      // stopping every track
      setTimeout(() => {
        track.stop();
      }, 1000);
    });
    // assign null to srcObject of video
    if (this.videoElement?.srcObject) {
      this.videoElement.srcObject = null;
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
