import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { CduiBaseService } from "@common-services";


@Injectable({
  providedIn: 'root',
})
export class BookPageCustomisationEndpointService extends CduiBaseService {
  constructor(private http: HttpClient, public injector: Injector) {
    super(injector);
  }

  saveBookPage(data: any) {
    return this.http.post(this.api?.designBookPage, data);
  }

  getBookPage(bookId: any, roleId: any) {
    return this.http.get(`${this.api?.designBookPage}?Device=DESKTOP&bookId=${bookId}&roleId=${roleId}`);
  }

  deleteBookPage(bookId: any) {
    return this.http.delete(`${this.api?.designBookPage}/id/${bookId}`);
  }

  getMCCPageCustomizationBooks(
    pageno: number,
    pageSize: number,
    searchString: string,
    roleId: number,
    isActive?: boolean
  ) {
    return this.http.get(
      this.api?.design +
        `/bookpage/role?device=DESKTOP&designMode=true&pageNo=${pageno}&pageSize=${pageSize}&rightHolderId=${roleId}&rightOwnerType=ROLE&searchCriteria=${searchString}`
    );
  }
}
