<div class="studioverse-login">
  <div class="banner">
    <video autoplay loop="loop">
      <source
        [src]="
          loginDesign?.backGround?.link?.desktop
            ? loginDesign.backGround.link.desktop
            : '../../../../../assets/images/login-screen/studio_video.mp4'
        "
        type="video/mp4"
      />
    </video>
    <div class="studioverse-content">
      <div class="container">
        <div class="form-wrapper">
          <div class="studioverse-left">
            <div class="">
              <div *ngIf="loginDesign?.asset?.desktop" class="logo-sec">
                <figure class="logo nh-mb-24">
                  <img
                    [src]="loginDesign?.asset?.desktop"
                    alt=""
                    onerror='this.src="../../../../../assets/images/login-screen/studioverse-logo.png"'
                  />
                </figure>
                <!-- <span class="nh-fw-300 nh-color-50">StudioVerse</span> -->
              </div>
              <h1
                class="form-title nh-fw-600 nh-mb-22"
                [ngStyle]="{
                  'font-family': loginDesign?.caption?.font,
                  color: loginDesign?.caption?.headingFontColor
                }"
              >
                <!-- <span>Welcome Back!</span><br />
                First things first… -->
                <span
                  ><app-change-detection
                    *ngIf="(loginDesign?.caption?.heading?.split('/n'))[0]"
                    [text]="(loginDesign?.caption?.heading?.split('/n'))[0]"
                    class="content-text"
                  >
                  </app-change-detection
                ></span>
              </h1>
              <p
                class="sub-title nh-fw-400"
                [ngStyle]="{
                  'font-family': loginDesign?.caption?.font,
                  color: loginDesign?.caption?.textFontColor
                }"
              >
                <app-change-detection
                  *ngIf="(loginDesign?.caption?.text?.split('/n'))[0]"
                  [text]="(loginDesign?.caption?.text?.split('/n'))[0]"
                  class="content-text"
                >
                </app-change-detection>
              </p>
            </div>
          </div>
          <div class="studioverse-right" *ngIf="!forgotPasswordScreen">
            <div class="studioverse-form text-center">
              <h5
                class="title nh-mb-8"
                [ngStyle]="{
                  'font-family': loginDesign?.headingText?.font,
                  color: loginDesign?.headingText?.fontColor
                }"
              >
                <app-change-detection
                  *ngIf="(loginDesign?.headingText?.text.split('/n'))[0]"
                  [text]="(loginDesign?.headingText?.text.split('/n'))[0]"
                  class="content-text"
                >
                </app-change-detection>
              </h5>
              <p class="desc">Enter your details to login</p>
              <div class="studioverse-input">
                <form
                  *ngIf="!otpLogin && !twofactorverification"
                  [formGroup]="loginForm"
                  (ngSubmit)="login()"
                  novalidate
                >
                  <ul class="items">
                    <li class="item">
                      <mat-form-field appearance="standard" class="custom-input-mat">
                        <label *ngIf="emailValidationNecessary" class="main-label">Email</label>
                        <input
                          *ngIf="emailValidationNecessary"
                          matInput
                          type="text"
                          placeholder="Enter Email"
                          formControlName="username"
                          (keyup)="ValidateEmail()"
                        />
                        <label *ngIf="!emailValidationNecessary" class="main-label">Email or Mobile Number</label>
                        <input
                          *ngIf="!emailValidationNecessary"
                          matInput
                          type="text"
                          placeholder="Enter Email or Mobile Number"
                          formControlName="username"
                        />
                      </mat-form-field>
                      <mat-error style="text-align: left;" *ngIf="!emailValidator && (loginForm?.value)['username']"
                        >Enter a valid Email</mat-error
                      >
                    </li>
                    <li class="item">
                      <mat-form-field appearance="standard" class="custom-input-mat custom-input-icon nh-mb-8">
                        <label class="main-label">Password</label>
                        <div class="d-flex align-items-center">
                          <input
                            matInput
                            [type]="showPassword ? 'text' : 'password'"
                            id="passWord"
                            formControlName="password"
                            autocomplete="current-password"
                            [placeholder]="labels?.Password"
                            required
                            [type]="showPassword ? 'text' : 'password'"
                          />
                          <mat-icon (click)="toggleShowPassword()" class="eye-icon">
                            {{ showPassword ? 'visibility' : 'visibility_off' }}
                          </mat-icon>
                        </div>
                      </mat-form-field>
                      <small class="nh-fs-12 nh-lh-17 nh-color-899 d-flex" (click)="forgotClick()"
                        ><a>Forgot Password?</a></small
                      >
                    </li>
                  </ul>
                </form>
                <div *ngIf="identityProvidersList?.length > 0" class="social-logins">
                  <div class="top-level">
                    <div class="login-via">
                      Or Login via
                    </div>
                    <div class="identity-providers">
                      <div class="signupwith" *ngFor="let provider of identityProvidersList">
                        <a href="javascript:;" *ngIf="provider.isImageExist">
                          <img
                            src="../../../../assets/img/login/icon-{{ provider?.alias }}.svg"
                            (click)="socialLogin(provider?.alias)"
                            alt=""
                          />
                        </a>
                        <a href="javascript:;" *ngIf="!provider.isImageExist" class="provider-btn">
                          <p (click)="socialLogin(provider?.alias)">{{ provider?.displayName }}</p>
                        </a>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <button
                class="btn btn-next nh-mb-24 nh-fs-16"
                [ngStyle]="{
                  background: loginDesign?.actionButton?.buttonColor,
                  color: loginDesign?.actionButton?.fontColor
                }"
                [disabled]="!emailValidator"
                (click)="checkCaptchEnabled()"
              >
                Login
              </button>
              <p class="signup-link nh-color-899">
                New User? <a class="nh-color-200 cursor-pointer" (click)="selfonboardSignUp()">Signup</a>
              </p>
            </div>
          </div>
          <div class="studioverse-right" *ngIf="forgotPasswordScreen">
            <div class="studioverse-form text-center">
              <h5
                class="title nh-mb-8"
                [ngStyle]="{
                  'font-family': loginDesign?.headingText?.font,
                  color: loginDesign?.headingText?.fontColor
                }"
              >
                Forgot Password ?
              </h5>
              <div class="studioverse-input">
                <ul class="items">
                  <li class="item">
                    <mat-form-field appearance="standard" class="custom-input-mat">
                      <input
                        matInput
                        type="text"
                        id="userName"
                        autocomplete="username"
                        placeholder="Enter Registered Email Address"
                        [(ngModel)]="forgotPasswordUserName"
                        [ngModelOptions]="{ standalone: true }"
                      />

                      <span class="form-icon login-email-icon"></span>
                    </mat-form-field>
                  </li>
                </ul>
              </div>
              <button
                class="btn btn-next nh-mb-24 nh-fs-16"
                [ngStyle]="{
                  background: loginDesign?.actionButton?.buttonColor,
                  color: loginDesign?.actionButton?.fontColor
                }"
                (click)="fpclickSubmit()"
              >
                Submit
              </button>
              <button
                class="btn btn-next nh-mb-24 nh-fs-16"
                [ngStyle]="{
                  background: loginDesign?.actionButton?.buttonColor,
                  color: loginDesign?.actionButton?.fontColor
                }"
                (click)="fpcancelClick()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
