<div class="menu-content" *ngIf="currentCuForLayout">
  <div class="feilds-select">
    <div class="select-header" id="select-layout-btn">
      <div>
        <p>
          <figure>
            <img
              [src]="'../../assets/img/icon-left-arrow.svg' | CDNUrlRewritePipe"
              *ngIf="!showLayoutList"
              (click)="openLayoutList()"
            />
          </figure>
          <figure>
            <img
              [src]="'../../../assets/img/right-arrow.svg' | CDNUrlRewritePipe"
              *ngIf="showLayoutList && currentLayout"
              (click)="closeLayoutList()"
            />
          </figure>
          <span class="text-dark font-weight-bold">
            {{ labels?.Select_Layouts }}
          </span>
        </p>
      </div>
      <!-- <div>
        <figure><img [src]="'../../../assets/img/icon-cross.svg' | CDNUrlRewritePipe" /></figure>
      </div> -->
    </div>

    <div class="layout-list">
      <div class="mb-2" *ngIf="currentLayout">
        <div class="btn-group currentlayout-btns">
          <button type="button" *ngIf="currentLayoutName" class="btn btn-outline-primary">
            {{ currentLayoutName }}
          </button>
          <hr />
          <button
            type="button"
            *ngFor="let layout of currentLayout?.subLayouts"
            class="btn btn-outline-primary"
            [ngClass]="{ disabled: layout.disabled }"
          >
            {{ layout.name }}
          </button>
        </div>
        <div class="subLayouts" *ngIf="!showLayoutList">
          <div *ngIf="!showEnhancements">
            <div
              class="form-check"
              *ngFor="let layout of currentLayout?.multiLayout; let layoutInd = index"
              (click)="selectedLayout(layout, layoutInd)"
            >
              <input
                class="form-check-input"
                type="radio"
                name="layouts"
                id="{{ layout.name }}"
                value="{{ layout.name }}"
                (change)="templateChecked(layout)"
              />
              <!-- <label class="form-check-label" for="{{ layout.name }}"> -->
              <!-- {{ layout.name }} -->
              <img
                class="form-check-label"
                [src]="layout.templateImg"
                [alt]="layout.name"
                (click)="onPreviewClick(layout)"
              />
              <!-- </label> -->
            </div>
          </div>
          <div class="back-btn" id="template-backbtn" (click)="gotoTemplate()" *ngIf="showEnhancements">
            <i class="bi bi-arrow-left-short"></i>
          </div>
          <div class="form-check p-0" *ngIf="showEnhancements">
            <img
              class="form-check p-0 form-check-label"
              [src]="checkedtemplate.templateImg"
              [alt]="checkedtemplate.name"
            />
          </div>
        </div>
      </div>

      <div *ngIf="enhancements && !showLayoutList && showEnhancements">
        <div class="mb-2 ml-1 font-weight-bold">{{ labels?.Enhancements }}</div>
        <div class="enhancement-list">
          <mat-accordion>
            <!-- ****************Add assets*********************-->
            <mat-expansion-panel *ngIf="isAsserts && !disableAssets">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Add_Assets }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <button
                type="button"
                class="btn btn-outline-secondary btn-block rounded-0 text-left"
                (click)="openAssertsModal()"
              >
                {{ labels?.Add_Assets }}
              </button>
            </mat-expansion-panel>
            <!-- ****************Background*********************-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Background }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="input-group">
                <div class="custom-radio-btns">
                  <div>
                    <mat-radio-group>
                      <mat-radio-button
                        required
                        id="Color"
                        type="radio"
                        value="Color"
                        name="background"
                        [checked]="!isBackgroundWallpaper"
                        (change)="toggleBackgroundType()"
                        >{{ labels?.Color }}
                      </mat-radio-button>
                      <mat-radio-button
                        required
                        id="Upload"
                        type="radio"
                        value="Upload"
                        name="background"
                        [checked]="isBackgroundWallpaper"
                        (change)="openBackgroundModal()"
                        >{{ labels?.Upload }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="uplodimg-del">
                  <input
                    *ngIf="isBackgroundWallpaper && backGroundWallpaper != ''"
                    matInput
                    type="text"
                    [(ngModel)]="backGroundWallpaper"
                    placeholder="walpaper_Name"
                    readonly
                  />
                  <button
                    type="button"
                    mat-icon-button
                    type="button"
                    id="deleteWalpaper"
                    (click)="deletedWallpaper()"
                    *ngIf="isBackgroundWallpaper && backGroundWallpaper != ''"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div class="color-pick-panel">
                  <input
                    id="background_color_picker"
                    *ngIf="!isBackgroundWallpaper"
                    type="text"
                    class="form-control"
                    [style.background]="backgroundColor"
                    [(colorPicker)]="backgroundColor"
                    (colorPickerOpen)="onEventLog('backgroundColorPickerOpen', $event)"
                    (colorPickerClose)="onEventLog('backgroundColorPickerClose', $event)"
                    (cpInputChange)="onEventLog('cpInputChange', $event)"
                    (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                    (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                    [cpPosition]="'bottom'"
                  />
                  <div
                    class="input-group-append"
                    *ngIf="!isBackgroundWallpaper"
                    id="background_color_{{ backgroundColor }}_div"
                  >
                    <input
                      matInput
                      id="background_color_{{ backgroundColor }}_input"
                      class="entered-data"
                      [(ngModel)]="backgroundColor"
                    />
                  </div>
                </div>

                <div class="upload-color">
                  <div class="image-opacity">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Opacity }}</label>
                      <input [(ngModel)]="backgroundOpacity" [value]="backgroundOpacity" />
                    </div>
                    <mat-slider [value]="backgroundOpacity" (input)="changeBackgroundOpacity($event)"></mat-slider>
                  </div>
                </div>

                <div class="select-footer colorpicker-btns">
                  <button
                    class="btn"
                    *ngIf="!isBackgroundWallpaper"
                    id="layouts-apply-background"
                    (click)="saveBackgroundColor()"
                  >
                    {{ labels?.Apply }}
                  </button>
                  <button class="btn btn-cancel" id="layouts-cancel-background" (click)="cancelBackgroundColor()">
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- ****************color*********************-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Form_Color }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="input-group">
                <div class="color-pick-panel">
                  <input
                    id="form_color_picker"
                    type="text"
                    class="form-control"
                    [style.background]="formColor"
                    [(colorPicker)]="formColor"
                    (colorPickerOpen)="onEventLog('formColorPickerOpen', $event)"
                    (colorPickerClose)="onEventLog('formColorcolorPickerClose', $event)"
                    (cpInputChange)="onEventLog('cpInputChange', $event)"
                    (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                    (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                    [cpPosition]="'bottom'"
                  />
                  <div class="input-group-append" id="form_color_{{ formColor }}_div">
                    <input
                      matInput
                      class="entered-data"
                      id="form_color_{{ formColor }}_input"
                      [(ngModel)]="formColor"
                    />
                  </div>
                </div>

                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-formcolor" (click)="saveFormColor()">
                    {{ labels?.Apply }}
                  </button>
                  <button class="btn btn-cancel" id="layouts-cancel-formcolor" (click)="cancelFormColor()">
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- ****************Outline*********************-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Outline }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-slide-toggle
                class="slide-toggle"
                id="outline-toggle"
                (change)="saveOutlineToggle($event)"
              ></mat-slide-toggle>
              <div class="input-group">
                <div class="color-pick-panel">
                  <input
                    id="outline_color_picker"
                    type="text"
                    class="form-control"
                    [style.background]="outlineColor"
                    [(colorPicker)]="outlineColor"
                    (colorPickerOpen)="onEventLog('outLineColorPickerOpen', $event)"
                    (colorPickerClose)="onEventLog('outLineColorPickerClose', $event)"
                    (cpInputChange)="onEventLog('cpInputChange', $event)"
                    (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                    (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                    [cpPosition]="'bottom'"
                  />
                  <div
                    class="input-group-append"
                    *ngIf="!isBackgroundWallpaper"
                    id="outline_color_{{ outlineColor }}_div"
                  >
                    <input matInput id="outline_color_input" class="entered-data" [(ngModel)]="outlineColor" />
                  </div>
                </div>
                <div class="upload-color">
                  <div class="image-opacity image-opacity-double">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Size }}</label>
                      <input id="outline-input-size" [(ngModel)]="outlineSize" [value]="outlineSize" />
                    </div>
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Corner }}</label>
                      <input id="outline-input-corner" [(ngModel)]="outlineCorner" [value]="outlineCorner" />
                    </div>
                  </div>
                </div>

                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-outline" (click)="saveoutline()">
                    {{ labels?.Apply }}
                  </button>
                  <button class="btn btn-cancel" id="layouts-cancel-outline" (click)="cancelCustomDesign('outline')">
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- ****************Drop Shadow*********************-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Drop_Shadow }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-slide-toggle
                class="slide-toggle"
                id="dropShadow-toggle"
                (change)="dropShadowToggle($event)"
              ></mat-slide-toggle>
              <div class="input-group">
                <div class="color-pick-panel">
                  <input
                    id="dropShadow_color_picker"
                    type="text"
                    class="form-control"
                    [style.background]="dropShadowColor"
                    [(colorPicker)]="dropShadowColor"
                    (colorPickerOpen)="onEventLog('dropShadowColorPickerOpen', $event)"
                    (colorPickerClose)="onEventLog('dropShadowColorPickerClose', $event)"
                    (cpInputChange)="onEventLog('cpInputChange', $event)"
                    (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                    (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                    [cpPosition]="'bottom'"
                  />
                  <div class="input-group-append" id="dropShadow_color_{{ dropShadowColor }}_div">
                    <input matInput id="dropShadow_color_input" class="entered-data" [(ngModel)]="dropShadowColor" />
                  </div>
                </div>
                <div class="upload-color">
                  <div class="image-opacity image-opacity-double">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.X_Axis }}</label>
                      <input id="dropShadow-input-xaxis" [(ngModel)]="dropShadowXAxis" [value]="dropShadowXAxis" />
                    </div>
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Y_Axis }}</label>
                      <input id="dropShadow-input-yaxis" [(ngModel)]="dropShadowYAxis" [value]="dropShadowYAxis" />
                    </div>
                  </div>
                </div>

                <div class="upload-color">
                  <div class="image-opacity">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Opacity }}</label>
                      <input [(ngModel)]="dropShadowOpacity" [value]="dropShadowOpacity" />
                    </div>
                    <mat-slider [value]="dropShadowOpacity" (input)="changedropShadowOpacity($event)"></mat-slider>
                  </div>
                </div>

                <div class="upload-color">
                  <div class="image-opacity">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Blur }}</label>
                      <input [(ngModel)]="dropShadowBlur" [value]="dropShadowBlur" />
                    </div>
                    <mat-slider [value]="dropShadowBlur" (input)="changeDropShadowBlur($event)"></mat-slider>
                  </div>
                </div>

                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-dropShadow" (click)="saveDropShadow()">
                    {{ labels?.Apply }}
                  </button>
                  <button
                    class="btn btn-cancel"
                    id="layouts-cancel-dropShadow"
                    (click)="cancelCustomDesign('dropShadow')"
                  >
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- ****************Background Blur*********************-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Background_Blur }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-slide-toggle
                class="slide-toggle"
                id="backgroundBlurBlur-toggle"
                (change)="toggleBackgroundBlur($event)"
              ></mat-slide-toggle>
              <div class="input-group">
                <div class="upload-color">
                  <div class="image-opacity">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Blur }}</label>
                      <input [(ngModel)]="backgroundBlurBlur" [value]="backgroundBlurBlur" />
                    </div>
                    <mat-slider [value]="backgroundBlurBlur" (input)="changeBackgroundBlurBlur($event)"></mat-slider>
                  </div>
                </div>

                <div class="upload-color">
                  <div class="image-opacity">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Brightness }}</label>
                      <input [(ngModel)]="backgroundBlurBrightness" [value]="backgroundBlurBrightness" />
                    </div>
                    <mat-slider [value]="backgroundBlurBrightness" (input)="changeBackgroundBlurBrightness($event)">
                    </mat-slider>
                  </div>
                </div>

                <div class="upload-color">
                  <div class="image-opacity">
                    <div class="opacity-valute">
                      <label class="heading-label">{{ labels?.Opacity }}</label>
                      <input [(ngModel)]="backgroundBlurOpacity" [value]="backgroundBlurOpacity" />
                    </div>
                    <mat-slider [value]="backgroundBlurOpacity" (input)="changeBackgroundBlurOpacity($event)">
                    </mat-slider>
                  </div>
                </div>

                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-backgroundBlur" (click)="saveBackgroundBlur()">
                    {{ labels?.Apply }}
                  </button>
                  <button
                    class="btn btn-cancel"
                    id="layouts-cancel-backgroundBlur"
                    (click)="cancelCustomDesign('backgroundBlur')"
                  >
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
            <!-- ****************Fonts*********************-->
            <mat-expansion-panel class="layout-fontselect">
              <mat-expansion-panel-header>
                <mat-panel-title (click)="updateChangeUnitFontSize(0)">
                  {{ labels?.Font }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <h2 class="small-title">{{ labels?.GSI }}</h2>

                <div>
                  <label class="heading-label">{{ labels?.Size }}</label>
                  <div class="number-input">
                    <button
                      [disabled]="GSIFontSize > 0 ? false : true"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="minus"
                      (click)="updateGSIFontSize(-1)"
                    ></button>
                    <input
                      class="quantity"
                      [(ngModel)]="GSIFontSize"
                      min="0"
                      placeholder="Member"
                      name="quantity"
                      [value]="GSIFontSize"
                      type="number"
                      (click)="updateGSIFontSize(0)"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="plus"
                      (click)="updateGSIFontSize(1)"
                    ></button>
                  </div>
                </div>
                <div>
                  <label class="heading-label">{{ labels?.Color }}</label>
                  <div class="color-pick-panel">
                    <input
                      id="GSI_Font_picker"
                      type="text"
                      class="form-control"
                      [style.background]="GSIFontColor"
                      [(colorPicker)]="GSIFontColor"
                      (colorPickerOpen)="onEventLog('GSIFontColorPickerOpen', $event)"
                      (colorPickerClose)="onEventLog('GSIFontColorPickerClose', $event)"
                      (cpInputChange)="onEventLog('cpInputChange', $event)"
                      (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                      (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                      [cpPosition]="'bottom'"
                    />
                    <div class="input-group-append" id="font_gsi_color_{{ GSIFontColor }}_div">
                      <input matInput class="entered-data" id="font_gsi_color_input" [(ngModel)]="GSIFontColor" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- for change unit font size and color  -->
              <div>
                <h2 class="small-title">{{ labels?.Change_Unit }}</h2>
                <div>
                  <label class="heading-label">{{ labels?.Size }}</label>
                  <div class="number-input">
                    <button
                      [disabled]="changeUnitFontSize > 0 ? false : true"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="minus"
                      (click)="updateChangeUnitFontSize(-1)"
                    ></button>
                    <input
                      class="quantity"
                      [(ngModel)]="changeUnitFontSize"
                      min="0"
                      placeholder="Member"
                      name="quantity"
                      [value]="changeUnitFontSize"
                      type="number"
                      (click)="updateChangeUnitFontSize(0)"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="plus"
                      (click)="updateChangeUnitFontSize(1)"
                    ></button>
                  </div>
                </div>

                <div>
                  <label class="heading-label">{{ labels?.Color }}</label>
                  <div class="color-pick-panel">
                    <input
                      id="ChangeUnit_Font_picker"
                      type="text"
                      class="form-control"
                      [style.background]="changeUnitFontColor"
                      [(colorPicker)]="changeUnitFontColor"
                      (colorPickerOpen)="onEventLog('ChangeUnitFontColorPickerOpen', $event)"
                      (colorPickerClose)="onEventLog('ChangeUnitFontColorPickerClose', $event)"
                      (cpInputChange)="onEventLog('cpInputChange', $event)"
                      (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                      (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                      [cpPosition]="'bottom'"
                    />
                    <div class="input-group-append" id="font_cu_color_{{ changeUnitFontColor }}_div">
                      <input matInput class="entered-data" id="font_cu_color_input" [(ngModel)]="changeUnitFontColor" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- for entity || object font size and color -->
              <div>
                <h2 class="small-title">{{ labels?.Object }}</h2>
                <mat-button-toggle-group class="button-group">
                  <mat-button-toggle value="Physical" (change)="toggleChangeforFont($event, 'physical')"
                    >{{ labels?.Physical }}
                  </mat-button-toggle>
                  <mat-button-toggle value="Information" (change)="toggleChangeforFont($event, 'info')"
                    >{{ labels?.Information }}
                  </mat-button-toggle>
                </mat-button-toggle-group>

                <div>
                  <label class="heading-label">{{ labels?.Size }}</label>
                  <div class="number-input">
                    <button
                      [disabled]="entityFontSize > 0 ? false : true"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="minus"
                      (click)="updateEntityFontSize(-1)"
                    ></button>
                    <input
                      class="quantity"
                      [(ngModel)]="entityFontSize"
                      min="0"
                      placeholder="Member"
                      name="quantity"
                      [value]="entityFontSize"
                      type="number"
                      (change)="updateEntityFontSize(0)"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="plus"
                      (click)="updateEntityFontSize(1)"
                    ></button>
                  </div>
                </div>

                <div>
                  <label class="heading-label">{{ labels?.Color }}</label>
                  <div class="color-pick-panel">
                    <input
                      id="EntityFont_color_picker"
                      type="text"
                      class="form-control"
                      [style.background]="entityFontColor"
                      [(colorPicker)]="entityFontColor"
                      (colorPickerOpen)="onEventLog('entityFonColorPickerOpen', $event)"
                      (colorPickerClose)="onEventLog('entityFontColorPickerClose', $event)"
                      (cpInputChange)="onEventLog('cpInputChange', $event)"
                      (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                      (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                      [cpPosition]="'top'"
                    />
                    <div class="input-group-append" id="entity_font_color_{{ entityFontColor }}_div">
                      <input matInput class="entered-data" id="entity_font_color_input" [(ngModel)]="entityFontColor" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- for attribute font zolor -->
              <div>
                <h2 class="small-title">{{ labels?.Attribute }}</h2>
                <div>
                  <label class="heading-label">{{ labels?.Size }}</label>
                  <div class="number-input">
                    <button
                      [disabled]="attrFontSize > 0 ? false : true"
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      class="minus"
                      (click)="updateAttrFontSize(-1)"
                    ></button>
                    <input
                      class="quantity"
                      [(ngModel)]="attrFontSize"
                      min="0"
                      placeholder="Member"
                      name="quantity"
                      [value]="attrFontSize"
                      type="number"
                      (click)="updateAttrFontSize(0)"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      class="plus"
                      (click)="updateAttrFontSize(1)"
                    ></button>
                  </div>
                </div>

                <div>
                  <label class="heading-label">{{ labels?.Color }}</label>
                  <div class="color-pick-panel">
                    <input
                      id="Attribute_Font_picker"
                      type="text"
                      class="form-control"
                      [style.background]="AttributeFontColor"
                      [(colorPicker)]="AttributeFontColor"
                      (colorPickerOpen)="onEventLog('AttributeFontColorPickerOpen', $event)"
                      (colorPickerClose)="onEventLog('AttributeFontColorPickerClose', $event)"
                      (cpInputChange)="onEventLog('cpInputChange', $event)"
                      (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                      (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                      [cpPosition]="'bottom'"
                    />
                    <div class="input-group-append" id="font_attribute_color_{{ AttributeFontColor }}_div">
                      <input
                        matInput
                        class="entered-data"
                        id="font_attribute_color_input"
                        [(ngModel)]="AttributeFontColor"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-font" (click)="saveFont()">
                    {{ labels?.Apply }}
                  </button>
                  <button class="btn btn-cancel" id="layouts-cancel-font" (click)="cancelCustomDesign('font')">
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </mat-expansion-panel>

            <!-- ************************* Action Button DYUI-3626 , 3336 , chalte chalte ye puch lena, code se kitni mohabbat hain haan :) *************************************** -->
            <mat-expansion-panel class="Action-button">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Action Button
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="action-btn-section">
                <small>Choose format</small>
                <div class="my-3">
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button
                      [checked]="actionLabelPayload[currentActionButton].format == 'icon'"
                      value="icon"
                      (change)="actionButtonRadios('icon')"
                      >Icon</mat-radio-button
                    >
                    <mat-radio-button
                      [checked]="actionLabelPayload[currentActionButton].format == 'button'"
                      value="button"
                      (change)="actionButtonRadios('button')"
                      >Button
                    </mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="">
                  <ul class="nav nav-tabs" (click)="selectButton($event)">
                    <li class="nav-item" [ngClass]="{ active: currentActionButton == 'Submit' }">
                      <a class="nav-link">Submit</a>
                    </li>
                    <li class="nav-item" [ngClass]="{ active: currentActionButton == 'Cancel' }">
                      <a class="nav-link">Cancel</a>
                    </li>
                    <li class="nav-item" [ngClass]="{ active: currentActionButton == 'Save' }">
                      <a class="nav-link">Save</a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active">
                      <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Action Name</mat-label>
                        <input
                          matInput
                          [placeholder]="currentActionButton"
                          value=""
                          [(ngModel)]="actionName"
                          (ngModelChange)="actionNameChange(actionName)"
                        />
                      </mat-form-field>
                      <div class="disable-btn" *ngIf="this.currentActionButton !== 'Submit'">
                        <span
                          class="disableText"
                          >{{ actionLabelPayload?.[currentActionButton]?.disableButton ? "Enable" : "Disable" }}</span
                        >
                        <span class="eye-btn" (click)="onClick()">
                          <mat-icon *ngIf="!actionLabelPayload?.[currentActionButton]?.disableButton">
                            visibility</mat-icon
                          >
                          <mat-icon *ngIf="actionLabelPayload?.[currentActionButton]?.disableButton">
                            visibility_off</mat-icon
                          >
                          <!-- <i class="fa fa-eye" aria-hidden="true" *ngIf="!DisableButton"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="DisableButton"></i> -->
                        </span>
                      </div>
                      <div *ngIf="toggleChooseStyle == true" class="input-group mb-3">
                        <!-- <input type="text" class="form-control" /> -->
                        <!-- <mat-form-field class="w-100" appearance="fill"> -->
                        <mat-label
                          >Action Name
                          <span class="nh-text-901" style="padding-left: 10px;"
                            >* <small class="nh-color-901">Mandatory</small></span
                          ></mat-label
                        >
                        <!-- <input matInput placeholder="Button" value="" /> -->
                        <!-- </mat-form-field> -->
                        <div class="input-group-append input-group-append-btn">
                          <mat-radio-button
                            [checked]="actionLabelPayload[currentActionButton].style == 'rounded'"
                            value="icon"
                            (change)="styleButtonRadios('rounded')"
                            >Rounded
                          </mat-radio-button>
                          <mat-radio-button
                            [checked]="actionLabelPayload[currentActionButton].style == 'rounded corner'"
                            value="icon"
                            (change)="styleButtonRadios('rounded corner')"
                            >Rounded Corner
                          </mat-radio-button>
                          <mat-radio-button
                            [checked]="actionLabelPayload[currentActionButton].style == 'solid'"
                            value="icon"
                            (change)="styleButtonRadios('solid')"
                            >Solid</mat-radio-button
                          >
                        </div>
                      </div>

                      <ul class="nav nav-tabs" (click)="toggleIconUpload($event)" *ngIf="!toggleChooseStyle">
                        <li class="nav-item" [ngClass]="{ active: svgUrl == true }">
                          <a class="nav-link">Primary</a>
                        </li>
                        <li class="nav-item" [ngClass]="{ active: hoverSvgUrl == true }">
                          <a class="nav-link">Hover</a>
                        </li>
                      </ul>

                      <div *ngIf="toggleChooseStyle == false" class="input-group mb-3" (change)="uploadIcon($event)">
                        <input type="file" placeholder="Attachment" class="form-control" type="file" />
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary upload-red-btn" type="button">Upload</button>
                        </div>
                      </div>
                      <div class="preview-wrapper">
                        <small>Preview</small>
                        <div
                          class="preview-section"
                          [ngClass]="{
                            rounded: actionLabelPayload[currentActionButton]?.style == 'rounded',
                            'rounded-corner': actionLabelPayload[currentActionButton]?.style == 'rounded corner',
                            solid: actionLabelPayload[currentActionButton]?.style == 'solid'
                          }"
                        >
                          <img
                            *ngIf="toggleChooseStyle == false && imgSrc"
                            class="preview-img"
                            [src]="imgSrc"
                            alt=""
                            (mouseenter)="displayHoverImg()"
                            (mouseleave)="displayNormalImg()"
                          />
                          <!-- <img
                            *ngIf="
                              toggleChooseStyle == false && hoverSvgUrl && actionLabelPayload[this.currentActionButton].hoverSvgUrl
                            "
                            class="preview-img"
                            [src]="actionLabelPayload[this.currentActionButton].hoverSvgUrl"
                            alt=""
                          /> -->
                          <button *ngIf="toggleChooseStyle == true" class="btn action-btn">{{ actionName }}</button>
                        </div>
                      </div>
                      <div>
                        <ul class="nav nav-tabs" (click)="changeOfState($event)">
                          <li class="nav-item" [ngClass]="{ active: formatStateDefaultOrHover == 'Default' }">
                            <a class="nav-link">Default</a>
                          </li>
                          <li class="nav-item" [ngClass]="{ active: formatStateDefaultOrHover == 'Hover' }">
                            <a class="nav-link">Hover</a>
                          </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active">
                            <div class="btn-color-selection">
                              <a href="javascript:;">
                                <input
                                  type="color"
                                  [value]="
                                    formatStateDefaultOrHover == 'Default'
                                      ? actionLabelPayload[currentActionButton].defaultButton
                                      : actionLabelPayload[currentActionButton].hoverButton
                                  "
                                  (change)="colorChange($event, 'Button')"
                                />
                                Button
                                <!-- <i class="bi bi-pencil-fill"></i> -->
                              </a>
                              <a href="javascript:;" *ngIf="actionLabelPayload[currentActionButton].format !== 'icon'">
                                <input
                                  type="color"
                                  [value]="
                                    formatStateDefaultOrHover == 'Default'
                                      ? actionLabelPayload[currentActionButton].defaultText
                                      : actionLabelPayload[currentActionButton].hoverText
                                  "
                                  (change)="colorChange($event, 'Text')"
                                />
                                Text
                                <!-- <i class="bi bi-pencil-fill"></i> -->
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade">...</div>
                    <div class="tab-pane fade">...</div>

                    <!-- <div class="my-2">
                        <mat-radio-group aria-label="Select an option">
                          <mat-radio-button
                            value="Apply at all GSI"
                            (change)="applyDesigntoActionButton('apply at all cu')"
                          >
                            Apply at all CU</mat-radio-button
                          >
                          <mat-radio-button
                            value="Apply at  current GSI"
                            (change)="applyDesigntoActionButton('apply at current cu')"
                            >Apply at current CU</mat-radio-button
                          >
                        </mat-radio-group>
                      </div> -->
                  </div>
                </div>

                <!-- <mat-form-field floatLabel="never">
                    <label class="mb-3"><strong>Name Your Action Button</strong></label>
                    <input matInput placeholder="" type="text" [(ngModel)]="selected" />
                  </mat-form-field> -->
                <!-- <button type="button" class="btn d-block ml-auto" (click)="actionSelected()">Done</button> -->
              </div>
              <!-- <mat-slide-toggle class="slide-toggle" (change)="toggleBackgroundBlur($event)"></mat-slide-toggle> -->
              <div class="input-group">
                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-actionButton" (click)="saveActionButton()">
                    {{ labels?.Apply }}
                  </button>
                  <button
                    class="btn btn-cancel"
                    id="layouts-cancel-actionButton"
                    (click)="cancelCustomDesign('actionButton')"
                  >
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
              <mat-radio-group class="alignment" aria-label="Select an option">
                <mat-radio-button
                  [checked]="actionLabelPayload?.buttonAlignment === 'left'"
                  value="left"
                  (change)="alignButtonRadios('left')"
                  >Left Align</mat-radio-button
                >
                <mat-radio-button
                  [checked]="actionLabelPayload?.buttonAlignment === 'right'"
                  value="right"
                  (change)="alignButtonRadios('right')"
                  >Right Align
                </mat-radio-button>
              </mat-radio-group>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="select-footer">
            <button class="btn" id="layouts-save-design" (click)="saveDesign()">{{ labels?.Done }}</button>
            <button class="btn btn-cancel" id="layouts-cancel-design" (click)="cancelDesign()">
              {{ labels?.Cancel }}
            </button>
          </div>
        </div>
        <!-- Shubham's code-->
        <!-- <div class="btn-group shadow-0 p-0">
          <div class="mb-2 w-100" *ngIf="isAsserts">
            <button
              type="button"
              class="btn btn-outline-secondary btn-block rounded-0 text-left"
              (click)="openAssertsModal()"
            >
              {{ labels?.Add_Assets }}
            </button>
          </div>
          <div class="mb-2 w-100">
            <div class="btn btn-outline-secondary rounded-0 text-left active-color">
              <a href="javascript:;" (click)="toggleBackgroundColor = !toggleBackgroundColor">{{
                labels?.Background
              }}</a>
              <div class="input-group mb-3" *ngIf="toggleBackgroundColor">
                <div class="radio-btnsgroup">
                  <label class="radio-inline mr-3">{{ labels?.Color }}</label>
                  <input
                    required
                    id="Color"
                    type="radio"
                    value="Color"
                    name="background"
                    [checked]="!isBackgroundWallpaper"
                    (change)="toggleBackgroundType()"
                  />
                  <label class="radio-inline ml-4 mr-3">{{ labels?.Upload }}</label>
                  <input
                    required
                    id="Upload"
                    type="radio"
                    value="Upload"
                    name="background"
                    [checked]="isBackgroundWallpaper"
                    (change)="openBackgroundModal()"
                  />
                </div>
                <div class="uplodimg-del">
                  <input
                    *ngIf="isBackgroundWallpaper && backGroundWallpaper != ''"
                    matInput
                    type="text"
                    [(ngModel)]="backGroundWallpaper"
                    placeholder="walpaper_Name"
                    readonly
                  />
                  <button
                    type="button"
                    mat-icon-button
                    type="button"
                    id="deleteWalpaper"
                    (click)="deletedWallpaper()"
                    *ngIf="isBackgroundWallpaper && backGroundWallpaper != ''"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <input
                  id="background_color_picker"
                  *ngIf="!isBackgroundWallpaper"
                  type="text"
                  class="form-control"
                  [style.background]="backgroundColor"
                  [(colorPicker)]="backgroundColor"
                  (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                  (colorPickerClose)="onEventLog('colorPickerClose', $event)"
                  (cpInputChange)="onEventLog('cpInputChange', $event)"
                  (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                  (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                  [cpPosition]="'bottom'"
                  (change)="updateBackgroundColor($event)"
                />
                <div
                  class="input-group-append"
                  *ngIf="!isBackgroundWallpaper"
                  id="background_color_{{ backgroundColor }}_div"
                >
                  <input
                    matInput
                    id="background_color_{{ backgroundColor }}_input"
                    class="color_picker_input"
                    [(ngModel)]="backgroundColor"
                  />
                  <button class="btn btn-outline-secondary" type="button" id="exampleAddon">HEX : {{ backgroundColor }}</button>
                </div>
                <div class="select-footer colorpicker-btns" *ngIf="!isBackgroundWallpaper">
                  <button class="btn" id="layouts-apply-background" (click)="saveBackgroundColor()">
                    {{ labels?.Apply }}
                  </button>
                  <button class="btn btn-cancel" id="layouts-cancel-background" (click)="cancelBackgroundColor()">
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-outline-secondary btn-block rounded-0 text-left"
              (click)="openBackgroundModal()"
            >
              {{ labels?.Background }}
            </button>
          </div>
          <div class="mb-2 w-100">
            <div class="btn btn-outline-secondary rounded-0 text-left active-color">
              <a href="javascript:;" (click)="toggleColor = !toggleColor">{{ labels?.Form_Color }}</a>
              <div class="input-group mb-3" *ngIf="toggleColor">
                <input
                  id="form_color_picker"
                  type="text"
                  class="form-control"
                  [style.background]="formColor"
                  [(colorPicker)]="formColor"
                  (colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
                  (colorPickerClose)="onEventLog('colorPickerClose', $event)"
                  (cpInputChange)="onEventLog('cpInputChange', $event)"
                  (cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
                  (cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
                  [cpPosition]="'bottom'"
                />
                <div class="input-group-append" id="form_color_{{ formColor }}_div">
                  <input matInput class="color_picker_input" id="form_color_{{ formColor }}_input" [(ngModel)]="formColor" />
                  <button class="btn btn-outline-secondary" type="button" id="exampleAddon">HEX : {{ formColor }}</button>
                </div>
                <div class="select-footer colorpicker-btns">
                  <button class="btn" id="layouts-apply-formcolor" (click)="saveFormColor()">
                    {{ labels?.Apply }}
                  </button>
                  <button class="btn btn-cancel" id="layouts-cancel-formcolor" (click)="cancelFormColor()">
                    {{ labels?.Cancel }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="select-footer">
            <button class="btn" id="layouts-save-design" (click)="saveDesign()">{{ labels?.Done }}</button>
            <button class="btn btn-cancel" id="layouts-cancel-design" (click)="cancelDesign()">
              {{ labels?.Cancel }}
            </button>
          </div>
        </div> -->
      </div>

      <div class="layouts-expansion" *ngIf="currentCuForLayout != '' && showLayoutList">
        <p-scrollPanel [style]="{ width: '100%', height: 'calc(100vh - 200px)' }">
          <div class="mb-2" *ngFor="let layoutName of layouts">
            <mat-accordion>
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                [expanded]="currentLayoutName == layoutName.name"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> {{ layoutName.name }} </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-panel-description
                  *ngFor="let layout of layoutName.list"
                  (click)="selectingLayout(layout, layoutName.name)"
                >
                  <div class="btn-group" id="custom_{{ layout.name }}">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      [ngClass]="{ disabled: clm.disabled }"
                      *ngFor="let clm of layout.subLayouts"
                    >
                      {{ clm.name }}
                    </button>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</div>

<div class="modal fade show d-block layout-modal" tabindex="-1" *ngIf="backgroundModal">
  <div class="modal-dialog" style="max-width: 1792px;">
    <div class="modal-content">
      <div class="modal-header" style="background: #ffe0e0;">
        <div class="row w-100">
          <div class="col">
            <div class="modal-title">{{ labels?.Choose_Background }}</div>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-light" id="cancel-background" (click)="cancelBackgroundModal()">
              {{ labels?.Cancel }}
            </button>
            <button type="button" class="btn btn-light" id="save-background" (click)="saveBackgroundModal()">
              {{ labels?.Done }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="chose-souce">
              <button
                type="button"
                class="btn btn-outline-secondary"
                id="open-nsl-library"
                (click)="openResourceType('nslLibrary')"
              >
                {{ labels?.NSL_Library }}
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                id="open-resources"
                (click)="openResourceType('myResouces')"
              >
                {{ labels?.My_Resources }}
              </button>
            </div>
          </div>
          <div class="col text-right">
            <a href="javascript:;" class="search-icon">
              <input type="text" placeholder="Search" [(ngModel)]="searchFolderName" (ngModelChange)="searchFolder()" />
              <i class="bi bi-search"></i>
            </a>
          </div>
          <div class="col-12 layout-controls" *ngIf="showLibrary">
            <nav aria-label="breadcrumb" class="layoutBread py-2 my-2" *ngIf="isBreadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let link of breadcrumb; let brdInd = index">
                  <a
                    href="javascript:;"
                    class="btn btn-outline-secondary"
                    id="{{ link?.link }}"
                    (click)="openImageType(link.link, brdInd)"
                    [ngClass]="{ active: currentBrdc == brdInd }"
                  >
                    {{ link.link }}
                  </a>
                  <div class="btn-group resorcesLinks" *ngIf="currentBrdc == brdInd">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      id="{{ subLink?.link }}"
                      *ngFor="let subLink of link?.subLinks; let subInd = index"
                      (click)="selectImageType(subLink.link, subInd)"
                    >
                      {{ subLink?.link }}
                    </button>
                  </div>
                </li>
              </ol>
            </nav>

            <div *ngIf="imageType">
              <nav class="layoutBread py-2 my-2">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:;"
                      id="background-image"
                      (click)="closeImageType()"
                      class="btn btn-link text-dark"
                      >{{ labels?.Image }}</a
                    >
                  </li>
                  <li class="breadcrumb-item">
                    <i class="bi">/</i>
                    <a
                      href="javascript:;"
                      id="background-jpg"
                      (click)="deSelectImageType()"
                      class="btn btn-link text-dark"
                      >{{ imageType }}
                    </a>
                  </li>
                  <li class="breadcrumb-item">
                    <i class="bi bi-chevron-left mr-3"></i>
                    <a
                      href="javascript:;"
                      id="background-wallpaper"
                      class="btn btn-outline-secondary"
                      (click)="showBackgroundType('wallpaper')"
                      >{{ labels?.wallpaper }}</a
                    >
                  </li>
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:;"
                      id="background-color"
                      class="btn btn-outline-secondary"
                      (click)="showBackgroundType('color')"
                      >{{ labels?.color }}</a
                    >
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div class="col-12 layout-controls resources-height-space" *ngIf="showResources">
            <section class="breadcrumb-panel">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item" *ngFor="let page of pageCrumb; let i = index">
                    <ng-container *ngIf="pageCrumb.length - 1 == i; else elseBlock">
                      {{ page.displayName }}
                    </ng-container>
                    <ng-template #elseBlock>
                      <a (click)="goToFolder(page.link, i)">{{ page.displayName }}</a>
                    </ng-template>
                  </li>
                </ol>
              </nav>
            </section>
            <ng-container>
              <ul class="folder-resorce">
                <ng-container *ngFor="let folder of myResorces; let i = index">
                  <li class="clickable" *ngIf="folder.name !== '' || folder.contentUrl">
                    {{ folder.contentUrl | json }}
                    <figure *ngIf="folder.isFolder == true" (click)="openFolder(folder)">
                      <img [src]="'../../../../../assets/img/icon-folder.png' | CDNUrlRewritePipe" alt="" />
                    </figure>
                    <figure
                      *ngIf="folder.isFolder !== true"
                      (click)="selectWallpaperForBackGroundFromMyresouces(folder?.contentUrl)"
                    >
                      <img [src]="folder.contentUrl" alt="" />
                    </figure>
                    <span
                      [matTooltip]="folderTooltip[i]"
                      matTooltipClass="my-custom-tooltip"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                      *ngIf="folder.name !== ''"
                    >
                      <span
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 13ch;
                          display: block;
                        "
                      >
                        {{ folder.name }}
                      </span>
                    </span>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </div>

        <div class="design-pallets" *ngIf="backgroundType == 'color'">
          <div
            class="pallet-block"
            style="background: #fccdcd;"
            id="background-#fccdcd"
            (click)="selctColorForBackGround('#fccdcd')"
          ></div>
          <div
            class="pallet-block"
            style="background: #e1ffcb;"
            id="background-#e1ffcb"
            (click)="selctColorForBackGround('#e1ffcb')"
          ></div>
          <div
            class="pallet-block"
            style="background: #daf8ec;"
            id="background-#daf8ec"
            (click)="selctColorForBackGround('#daf8ec')"
          ></div>
          <div
            class="pallet-block"
            style="background: #daf8ec;"
            id="background-#daf8ec"
            (click)="selctColorForBackGround('#daf8ec')"
          >
            <img [src]="'../../../../../assets/img/thumb-wp-2.png' | CDNUrlRewritePipe" alt="" class="w-100" />
          </div>
          <div
            class="pallet-block"
            style="background: #daf8ec;"
            id="background-#daf8ec"
            (click)="selctColorForBackGround('#daf8ec')"
          ></div>
        </div>

        <div
          class="design-pallets books-loading"
          *ngIf="backgroundType == 'wallpaper'"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [scrollWindow]="false"
          (scrolled)="onModalScrollDown()"
        >
          <div
            [ngClass]="selectedBackground?.url == wall?.link?.any ? 'pallet-block active' : 'pallet-block'"
            *ngFor="let wall of wallpapers; let i = index"
          >
            <img
              id="{{ getFileNameFromImgUrl(wall?.link?.any) }}"
              src="{{ wall.link.any }}"
              (click)="selectWallpaperForBackGround(i)"
              alt=""
              class="w-100"
            />
          </div>
        </div>

        <div class="bottom-selection-panel">
          <div
            class="icon-list"
            *ngIf="backgroundType == 'icon'"
            infinite-scroll
            [infiniteScrollDistance]="1"
            [infiniteScrollUpDistance]="2"
            [scrollWindow]="false"
            (scrolled)="onModalScrollDown()"
          >
            <ul>
              <li *ngFor="let gsi of availableIcons">
                <!-- <figure><i class="bi bi-trophy"></i></figure> -->
                <figure [ngClass]="{ highlight: selectedBackground?.url === gsi?.link?.any }">
                  <img
                    id="{{ getFileNameFromImgUrl(gsi?.link?.desktop) }}"
                    [src]="gsi?.link?.desktop | useHttpImgSrc | async"
                    alt=""
                    (click)="selectIconForBackground(gsi)"
                  />
                </figure>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="design-pallets videos-loading books-loading"
          *ngIf="backgroundType == 'video'"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [scrollWindow]="false"
          (scrolled)="onModalScrollDown()"
        >
          <div
            [ngClass]="selectedBackground?.url == video?.contentUrl ? 'pallet-block active' : 'pallet-block'"
            *ngFor="let video of availableVideos"
          >
            <div class="card border-0">
              <!--tips: add .text-center,.text-right to the .card to change card text alignment-->
              <video controls>
                <source [src]="video.contentUrl | safeResourceUrl" />
                <track label="English" kind="captions" srclang="en" src="" default />
                {{ labels?.Your_browser_does_not_support_HTML_video }}.
              </video>
              <div class="card-body">
                <a
                  href="javascript:;"
                  id="{{ getFileNameFromImgUrl(video.contentUrl) }}"
                  class="btn btn-primary"
                  (click)="selectVedioForBackground(video.contentUrl)"
                  >{{ labels?.Select_Video }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade show d-block layout-modal" tabindex="-1" *ngIf="assertsModal">
  <div class="modal-dialog" style="max-width: 1792px;">
    <div class="modal-content">
      <div class="modal-header" style="background: #ffe0e0;">
        <div class="row w-100">
          <div class="col">
            <div class="modal-title">{{ labels?.Choose_Asserts }}</div>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-light" id="cancel-assert" (click)="cancelAssertsModal()">
              {{ labels?.Cancel }}
            </button>
            <button type="button" class="btn btn-light" id="save-assert" (click)="saveAssertModal()">
              {{ labels?.Done }}
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">
            <div class="chose-souce">
              <button type="button" class="btn btn-outline-secondary" (click)="openResourceType('nslLibrary')">
                {{ labels?.NSL_Library }}
              </button>
              <button
                type="button"
                class="btn btn-outline-secondary"
                id="open-resources"
                (click)="openResourceType('myResouces')"
              >
                {{ labels?.My_Resources }}
              </button>
            </div>
          </div>
          <div class="col text-right">
            <a href="javascript:;" class="search-icon">
              <input type="text" placeholder="Search" [(ngModel)]="searchFolderName" (ngModelChange)="searchFolder()" />
              <i class="bi bi-search"></i>
            </a>
          </div>

          <div class="col-12 layout-controls" *ngIf="showLibrary">
            <nav aria-label="breadcrumb" class="layoutBread py-2 my-2" *ngIf="isBreadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let link of breadcrumb; let brdInd = index">
                  <a
                    href="javascript:;"
                    class="btn btn-outline-secondary"
                    (click)="openImageType(link.link, brdInd)"
                    [ngClass]="{ active: currentBrdc == brdInd }"
                  >
                    {{ link.link }}
                  </a>
                  <div class="btn-group" *ngIf="currentBrdc == brdInd">
                    <button
                      type="button"
                      class="btn btn-outline-secondary"
                      *ngFor="let subLink of link?.subLinks; let subInd = index"
                      (click)="selectImageType(subLink.link, subInd)"
                    >
                      {{ subLink?.link }}
                    </button>
                  </div>
                </li>
              </ol>
            </nav>

            <div *ngIf="imageType">
              <nav class="layoutBread py-2 my-2">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="javascript:;" id="assert-image" class="btn btn-link text-dark">{{ labels?.Image }} </a>
                  </li>
                  <li class="breadcrumb-item">
                    <i class="bi">/</i>
                    <a
                      href="javascript:;"
                      id="assert-{{ (breadcrumb[0]?.subLinks)[currentSubBrdc]?.link }}"
                      class="btn btn-link text-dark"
                    >
                      {{ (breadcrumb[0]?.subLinks)[currentSubBrdc]?.link }}</a
                    >
                  </li>
                  <li class="breadcrumb-item">
                    <i class="bi bi-chevron-left mr-3"></i>
                    <a
                      href="javascript:;"
                      id="assert-wallpaper"
                      class="btn btn-outline-secondary"
                      (click)="showAssertsType('wallpaper')"
                      >{{ labels?.wallpaper }}</a
                    >
                  </li>
                  <li class="breadcrumb-item">
                    <a
                      href="javascript:;"
                      id="assert-color"
                      class="btn btn-outline-secondary"
                      (click)="showAssertsType('color')"
                      >{{ labels?.color }}</a
                    >
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div class="col-12 layout-controls resources-height-space" *ngIf="showResources">
            <section class="breadcrumb-panel">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item" *ngFor="let page of pageCrumb; let i = index">
                    <ng-container *ngIf="pageCrumb.length - 1 == i; else elseBlock">
                      {{ page.displayName }}
                    </ng-container>
                    <ng-template #elseBlock>
                      <a (click)="goToFolder(page.link, i)">{{ page.displayName }}</a>
                    </ng-template>
                  </li>
                </ol>
              </nav>
            </section>
            <ng-container>
              <ul class="folder-resorce">
                <ng-container *ngFor="let folder of myResorces; let i = index">
                  <li class="clickable" *ngIf="folder.name !== '' || folder.contentUrl">
                    <figure *ngIf="folder.isFolder == true" (click)="openFolder(folder)">
                      <img [src]="'../../../../../assets/img/icon-folder.png' | CDNUrlRewritePipe" alt="" />
                    </figure>
                    <ng-container *ngIf="findExt(folder.contentUrl)">
                      <figure
                        *ngIf="folder.isFolder !== true"
                        (click)="selectWallpaperForAssertsFromMyresouces(folder?.contentUrl)"
                        [ngClass]="{ highlight: selectedAssert?.url === folder?.contentUrl }"
                      >
                        <img [src]="folder.contentUrl | useHttpImgSrc | async" alt="" />
                      </figure>
                    </ng-container>
                    <ng-container *ngIf="findExt(folder.contentUrl) == false">
                      <figure
                        *ngIf="folder.isFolder !== true"
                        (click)="selectWallpaperForAssertsFromMyresouces(folder.contentUrl)"
                        [ngClass]="{ highlight: selectedAssert?.url === folder?.contentUrl }"
                      >
                        <img [src]="folder.thumbnail | useHttpImgSrc | async" alt="" />
                      </figure>
                    </ng-container>

                    <span
                      [matTooltip]="folderTooltip[i]"
                      matTooltipClass="my-custom-tooltip"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                      *ngIf="folder.name !== ''"
                    >
                      <span
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 13ch;
                          display: block;
                        "
                      >
                        {{ folder.name }}
                      </span>
                    </span>
                  </li>
                </ng-container>
              </ul>
              <mat-paginator
                [length]="totalRecords"
                [pageSize]="5"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="changeImagesPage($event, folderNamePagination)"
                [hidden]="!(totalRecords > 0)"
              >
              </mat-paginator>
            </ng-container>
          </div>
        </div>

        <div class="design-pallets" *ngIf="assertsType == 'color'">
          <div
            class="pallet-block"
            style="background: #fccdcd;"
            id="assert-#fccdcd"
            (click)="selctColorForAsserts('#fccdcd')"
          ></div>
          <div
            class="pallet-block"
            style="background: #e1ffcb;"
            id="assert-#e1ffcb"
            (click)="selctColorForAsserts('#e1ffcb')"
          ></div>
          <div
            class="pallet-block"
            style="background: #daf8ec;"
            id="assert-#daf8ec"
            (click)="selctColorForAsserts('#daf8ec')"
          ></div>
          <div
            class="pallet-block"
            style="background: #daf8ec;"
            id="assert-#daf8ec"
            (click)="selctColorForAsserts('#daf8ec')"
          >
            <img [src]="'../../../../../assets/img/thumb-wp-2.png' | CDNUrlRewritePipe" alt="" class="w-100" />
          </div>
          <div
            class="pallet-block"
            style="background: #daf8ec;"
            id="assert-#daf8ec"
            (click)="selctColorForAsserts('#daf8ec')"
          ></div>
        </div>

        <div
          class="design-pallets books-loading"
          *ngIf="assertsType == 'wallpaper'"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [scrollWindow]="false"
          (scrolled)="onModalScrollDown()"
        >
          <div
            class="pallet-block"
            *ngFor="let wall of selectedImageTypeWallpapers; let i = index"
            [ngClass]="{ active: activeAssetImg === i }"
          >
            <img
              id="{{ getFileNameFromImgUrl(wall.link.any) }}"
              src="{{ wall.link.any }}"
              (click)="selectWallpaperForAsserts(i)"
              alt=""
              class="w-100"
            />
          </div>
        </div>

        <div class="bottom-selection-panel">
          <div
            class="icon-list books-loading"
            *ngIf="assertsType == 'icon'"
            infinite-scroll
            [infiniteScrollDistance]="1"
            [infiniteScrollUpDistance]="2"
            [scrollWindow]="false"
            (scrolled)="onModalScrollDown()"
          >
            <ul>
              <li *ngFor="let gsi of availableIcons">
                <!-- <figure><i class="bi bi-trophy"></i></figure> -->
                <figure [ngClass]="{ highlight: selectedAssert?.url === gsi?.link?.desktop }">
                  <img
                    id="{{ getFileNameFromImgUrl(gsi?.link?.desktop) }}"
                    [src]="gsi?.link?.desktop | useHttpImgSrc | async"
                    alt=""
                    (click)="selectIconForAsserts(gsi)"
                  />
                </figure>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="design-pallets videos-loading books-loading"
          *ngIf="assertsType == 'video'"
          infinite-scroll
          [infiniteScrollDistance]="1"
          [infiniteScrollUpDistance]="2"
          [scrollWindow]="false"
          (scrolled)="onModalScrollDown()"
        >
          <div class="pallet-block" *ngFor="let video of availableVideos">
            <div class="card border-0">
              <!--tips: add .text-center,.text-right to the .card to change card text alignment-->
              <video controls>
                <source [src]="video.contentUrl | safeResourceUrl" />
                <track label="English" kind="captions" srclang="en" src="" default />
                {{ labels?.Your_browser_does_not_support_HTML_video }}.
              </video>
              <div class="card-body">
                <a
                  href="javascript:;"
                  id="{{ getFileNameFromImgUrl(video.contentUrl) }}"
                  class="btn btn-primary"
                  (click)="selectVedioForAssert(video.contentUrl)"
                  >{{ labels?.Select_Video }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #selectAllpopup>
  <div mat-dialog-content class="layouts-upload-modal">
    <div class="prom-hdr">
      <h3>{{ labels?.Apply_at_All }}</h3>
      <mat-icon class="close-btn-modal" style="cursor: pointer;" (click)="closeSelectAllPopup()">close</mat-icon><br />
    </div>
    <div class="select-footer">
      <h2>
        Do you want to apply this appearance in all change units of selected solution or in this change unit only?
      </h2>
      <div class="upload-modal-btns">
        <button class="btn" id="layouts-save-design" (click)="changeDesignToAllCU()">{{ labels?.All_CU }}</button>
        <button class="btn btn-cancel" id="layouts-cancel-design" (click)="changeDesignToCurrentCU()">
          {{ labels?.This_CU }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
