import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AlertService,
  Librarytypes, NodeGsiFacadeService,
  callfat2flat, getAttributeObj
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-ocr-right',
  templateUrl: './node-ocr-right.component.html',
  styleUrls: ['./node-ocr-right.component.scss'],
})
export class NodeOcrRightComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject();
  clickedAttribute: any;
  panelType = 'files';
  @Input() ocrData: any;
  @Input() initComponentData: any;
  gsi: any;
  chosenAttribute: any;
  addedAttributes: any = [];
  mappedAttributes: any = [];
  entity: any;
  chosenAttributeToMap: any;
  entityObj: any;
  entityLayer: any;
  layerType: any;
  /* istanbul ignore next */
  @Input()
  set currentClickedAttribute(value: any) {
    if (value) {
      this.panelType = 'attributes';
      this.clickedAttribute = [{ ...value }];
    }
  }
  constructor(
    private nodeEntityfacadeService: NodeEntityFacadeService,
    private nodeChangeUnitFacadeService: NodeChangeUnitFacadeService,
    private nodeGsiFacadeService: NodeGsiFacadeService,
    private alertService: AlertService
  ) {
    this.detectSaveEntity();
    this.detectSavedCU();
    this.detectSolutionDetails();
    this.detectSavedGSI();
    this.detectOCRMappings();
    this.detectCurrentOCRMappings();
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.gsi = this.initComponentData.gsiData;
    this.entity = this.initComponentData.entity;
    this.layerType = this.initComponentData.layerType;
    /* istanbul ignore next */
    if (this.ocrData?.cdecId) {
      this.initComponentData.changeUnit['uiProperties'] = {
        cdecId: this.ocrData.cdecId,
      };
    }
    /* istanbul ignore next */
    if (this.ocrData?.isRecalledOCR) {
      this.entity?.nslAttributes?.forEach((attr: any) => {
        if (attr?.mappedWith) {
          this.mappedAttributes.push({ fromAttr: attr?.name, toAttr: attr?.mappedWith });
        }
      });
    }
  }
  /* istanbul ignore next */
  addAttribute(selectedAttribute: any) {
    this.chosenAttribute = selectedAttribute;
    let attr = getAttributeObj(selectedAttribute.attribute_name);
    let entityObj = this.initComponentData.entity;
    let alreadyExists = entityObj.nslAttributes.findIndex((at: any) => at.name === attr.name);
    if (alreadyExists == -1) {
      this.addedAttributes.push(selectedAttribute);
      attr['mappedWith'] = selectedAttribute.attribute_name;
      entityObj.nslAttributes.push(attr);
      this.initComponentData.entity = entityObj;
    } else {
      this.alertService.showToaster('Attribute already exists in entity', '', 'error');
    }
  }
  /* istanbul ignore next */
  detectSaveEntity() {
    this.nodeEntityfacadeService.saveentityResponseForIndEnt$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        res = res?.result;
        let changeunitObj = this.initComponentData.changeUnit;
        changeunitObj.tfId = changeunitObj.tfReferencedChangeUnit;
        changeunitObj.id = changeunitObj.referencedChangeUnit;
        changeunitObj.layers.forEach((layerObj: any) => {
          layerObj.participatingItems.forEach((itemObj: any) => {
            if (itemObj.name == res.name) {
              itemObj.nslAttributes = res.nslAttributes;
              this.entityObj = itemObj;
              this.entityLayer = layerObj.type;
              this.setMappings(itemObj, this.entityLayer, this.chosenAttribute);
            }
          });
        });
        this.nodeChangeUnitFacadeService.updateChangeUnit(changeunitObj, false, true);
      });
  }
  /* istanbul ignore next */
  callSetMappings() {
    this.nodeGsiFacadeService.setMappings(this.ocrData);
  }
  /* istanbul ignore next */
  setMappings(entity: any, layer: any, chosenAttribute: any, attrFromEntity?: any) {
    let mappings = this.ocrData.mappings;
    let attrToChangeIndex = mappings.findIndex((attr: any) => attr.attribute_name === chosenAttribute.attribute_name);
    if (attrFromEntity) {
      if (!layer) {
        layer = entity?.layerType;
      }
      mappings[attrToChangeIndex].solution_attribute.push({
        attributes: {
          name: attrFromEntity.name,
          _id: attrFromEntity.id,
        },
        layer: layer,
        entityid: entity.id,
      });
      this.mappedAttributes.push({ fromAttr: attrFromEntity.name, toAttr: chosenAttribute.attribute_name });
    } else {
      entity.nslAttributes.forEach((attr: any) => {
        if (attr.mappedWith) {
          let attrChangeIndex = mappings.findIndex(
            (mappedAttribute: any) => attr.mappedWith === mappedAttribute.attribute_name
          );
          mappings[attrChangeIndex].solution_attribute.push({
            attributes: {
              name: attr.name,
              _id: attr.id,
            },
            layer: layer,
            entityid: entity.id,
          });
        }
      });
    }
    this.ocrData.mappings = mappings;
    if (this.panelType == 'mapAttributes') {
      this.goBack('attributes');
    }
  }
  /* istanbul ignore next */
  detectCurrentOCRMappings() {
    this.nodeGsiFacadeService.currentOCRMappings$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.ocrData.mappings = res.mappings;
    });
  }
  /* istanbul ignore next */
  detectOCRMappings() {
    this.nodeGsiFacadeService.setMappingsSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result) {
        this.ocrData = res.result;
        if (this.panelType == 'mapAttributes') {
          this.goBack('attributes');
        }
      }
    });
  }
  /*istanbul ignore next */
  detectSolutionDetails() {
    this.nodeChangeUnitFacadeService.flatSolutionOcrDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let querySentence: any[] = [];
        if (res?.flatApiFail == undefined) {
          querySentence = [...res];
        }
        this.gsi?.solutionLogic?.forEach((cu: any) => {
          querySentence?.forEach((ele: any) => {
            ele.physical?.forEach((result: any) => {
              if (result?.tag == 'cu' && result?.dsd_id == cu.referencedChangeUnit) {
                cu['sentenceTags'] = ele;
                cu['tCUsentenceName'] = ele;
              }
            });
          });
        });
        this.nodeGsiFacadeService.saveGsi(this.gsi);
      });
  }
  /* istanbul ignore next */
  detectSavedCU() {
    this.nodeChangeUnitFacadeService.indCuUpdatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.callSetMappings();
      let cu = res.data;
      let cuInGsiIndex = this.gsi.solutionLogic.findIndex((x: any) => x.masterId == cu.masterId);
      cu.referencedChangeUnit = cu.id;
      cu.tfReferencedChangeUnit = cu.tfId;
      cu.id = this.gsi.solutionLogic[cuInGsiIndex].id;
      cu.tfId = this.gsi.solutionLogic[cuInGsiIndex].tfId;
      let nextTriggerSet = this.gsi?.solutionLogic[cuInGsiIndex]?.nextTriggerSet;
      this.gsi.solutionLogic[cuInGsiIndex] = cu;
      this.gsi.solutionLogic[cuInGsiIndex].nextTriggerSet = nextTriggerSet;
      this.nodeChangeUnitFacadeService.getSolutionOcrDetails(callfat2flat(this.gsi));
    });
  }

  /* istanbul ignore next */
  detectSavedGSI() {
    this.nodeGsiFacadeService.multigsiDraftResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.nodeGsiFacadeService.changeNSLComponent(Librarytypes.NSLGsi);
    });
  }
  /* istanbul ignore next */
  doneAndClose() {
    if (this.chosenAttribute) {
      this.nodeEntityfacadeService.saveEntityForIndEnt(this.initComponentData.entity);
    } else {
      let changeunitObj = this.initComponentData.changeUnit;
      changeunitObj.tfId = changeunitObj.tfReferencedChangeUnit;
      changeunitObj.id = changeunitObj.referencedChangeUnit;
      this.nodeChangeUnitFacadeService.updateChangeUnit(changeunitObj, false, true);
    }
  }
  /* istanbul ignore next */
  mapAttribute(selectedAttribute: any) {
    this.panelType = 'mapAttributes';
    this.chosenAttributeToMap = selectedAttribute;
  }
  /* istanbul ignore next */
  mapToAttribute(attrInEntity: any) {
    this.setMappings(this.entity, this.layerType, this.chosenAttributeToMap, attrInEntity);
    this.setMappings(this.entity, this.layerType, this.chosenAttributeToMap);
  }
  /* istanbul ignore next */
  goBack(pType: any) {
    this.panelType = pType;
  }
}

