<div pTooltip="{{ field.label }}" tooltipPosition="top" tooltipStyleClass="transcustomtolltipbook">
  <div [formGroup]="group" class="timer-main-wrapper">
    <label
        class="main-label"
        pTooltip="{{ field.label }}"
        tooltipPosition="top"
        tooltipStyleClass="transcustomtolltipbook"
        [style.color]="field.color"
        [style.font-size.px]="field.fontSize">
      <span class="main-label-inner"
        ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
        <span *ngIf="field?.isRequired && !field?.isInfo" class="text-danger">*</span></span
      >
    </label>

    <div [ngStyle]="{ 'background-color': timerColour }" class="timer-input-wrapper">
      <p class="increasing-timer-text" id="increasingTimer">{{ time }}</p>
      <!-- <mat-form-field appearance="standard">
        <p class="increasing-timer-text" id="increasingTimer">{{ time }}</p>
      </mat-form-field> -->
      <input
        matInput
        placeholder="Timer"
        [value]="field.value"
        id="{{ field?.attribute?.name }}_{{ field?.entityName }}"
        [formControlName]="field?.attribute?.name"
        style="display: none;"
        [readonly]="field.readonly"
        [required]="field.isRequired && !field?.isInfo"
      />
    </div>
  </div>
</div>
