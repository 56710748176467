<div class="row no-gutters flex-nowrap align-items-center range-control agent-control reserved-entity">
  <div>
    <h2 class="pop-title">
      <span class="clickbk" (click)="GotoPrevious()"><img [src]="'/assets/node/back.svg'" /></span>
      <a>{{ labels?.Reserved_Actions }}</a>
    </h2>
  </div>
  <div class="addremove">
    <div (click)="removeAction()">
      <button mat-icon-button>
        <mat-icon>remove</mat-icon>
      </button>
    </div>
    <div>
      <input [value]="selectedReservedAction?.length" />
    </div>
    <div (click)="addAction()">
      <button mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-auto mx-auto">
      <button class="primary-btn" (click)="saveReservedEntities()">{{ labels?.Done }}</button>
    </div>
  </div>
</div>
<div class="pop-body">
  <div class="mb-4 spacing-text">
    <div class="row mb-2" *ngFor="let resAction of selectedReservedAction; let i = index">
      <div class="col">
        <div class="no-label no-space">
          <mat-form-field appearance="outline" class="reservedinput">
            <mat-label>{{ resAction?.name ? resAction?.name : 'Select Reserved Entity' }}</mat-label>
            <mat-select
              panelClass="attribute-icons select-with-search"
              (selectionChange)="selectedReservedEntity($event, resAction, i)"
              placeholder="Select Reserved Entity"
            >
              <mat-label></mat-label>
              <input
                placeholder="Search Reserved Entity"
                id="search_entity_bar"
                matInput
                [(ngModel)]="filteredName"
                (keyup)="filterReservedActionList(filteredName)"
                (keydown)="filterReservedActionList(filteredName)"
              />
              <mat-option [value]="action" *ngFor="let action of filteredActionList">
                <div class="d-flex align-items-center"><span class="name-icon attr-icon"></span>{{ action?.name }}</div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-auto">
        <button class="delete-btn-icon" (click)="deleteAction(resAction, i)">{{ labels?.Delete }}</button>
      </div>
      <div *ngFor="let entity of layerData; let entityIndex = index">
        <div *ngIf="selectedEntityList[i]">
          <mat-form-field appearance="outline" class="reservedinput">
            <mat-select>
              <ng-container *ngFor="let attribute of selectedEntityList[i]?.nslAttributes; let attributeIndex = index">
                <mat-option
                  [value]="attribute"
                  *ngIf="
                    attribute?.nslAttributeProperties?.isHidden == 'false' ||
                    attribute?.nslAttributeProperties?.isHidden == undefined
                  "
                >
                  <div
                    class="d-flex align-items-center"
                    (click)="openReservedAttr(selectedEntityList[i], attribute, attributeIndex, i)"
                  >
                    <span class="name-icon attr-icon"></span>{{ attribute?.name }}
                  </div>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="selectedEntityList[i]?.selectedAttribute && selectedEntityList[i]?.entityProperties?.machineEntity"
          >
            <div class="timeduration-input">
              <form
                (ngSubmit)="
                  saveConstantChangedriverData(
                    i,
                    layerType,
                    selectedEntityList[i],
                    selectedEntityList[i]?.selectedAttribute
                  )
                "
              >
                <div
                  *ngIf="
                    selectedEntityList[i]?.name == 'NSL_Reminders' && selectedEntityList[i]?.name === 'targetTime';
                    else generalEntity
                  "
                >
                  <div>
                    <ul>
                      <li>
                        <input
                          *ngIf="
                            selectedEntityList[i]?.selectedAttribute &&
                            !(selectedEntityList[i]?.selectedAttribute?.nslAttributeProperties?.isDynamic == 'true')
                          "
                          matInput
                          type="text"
                          placeholder="Source_Attribute"
                          [(ngModel)]="selectedEntityList[i].selectedAttribute.defaultValue"
                        />
                        <div
                          *ngIf="selectedEntityList[i]?.selectedAttribute?.nslAttributeProperties?.isDynamic == 'true'"
                        >
                          <div
                            class="slide-toggle"
                            [class.active]="selectedEntityList[i]?.selectedAttribute?.isRtctBoolean"
                            [class.inactive]="!selectedEntityList[i]?.selectedAttribute?.isRtctBoolean"
                            (click)="rtctBooleanChange(selectedEntityList[i]?.selectedAttribute)"
                          >
                            <span
                              class="positive-text"
                              [hidden]="!selectedEntityList[i]?.selectedAttribute?.isRtctBoolean"
                              >dynamic</span
                            >
                            <span
                              class="negative-text"
                              [hidden]="selectedEntityList[i]?.selectedAttribute?.isRtctBoolean"
                              >static</span
                            >
                            <span
                              class="slide-toggle-button"
                              [class.active]="selectedEntityList[i]?.selectedAttribute?.isRtctBoolean"
                              [class.inactive]="!selectedEntityList[i]?.selectedAttribute?.isRtctBoolean"
                            ></span>
                          </div>
                          <div *ngIf="selectedEntityList[i].selectedAttribute?.isRtctBoolean != 'true'">
                            <input
                              class="add-value-alternate-input"
                              type="text"
                              name="ccdAttr"
                              [(ngModel)]="selectedEntityList[i].selectedAttribute.defaultValue"
                              [readonly]="selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <ng-template class="add-value-alternate" #generalEntity>
                  <div [ngSwitch]="selectedEntityList[i].selectedAttribute?.attributeType?.uiElement?.uiElement">
                    <div *ngSwitchCase="'boolean'">
                      <mat-radio-group
                        name="radio-attr"
                        [(ngModel)]="selectedEntityList[i].selectedAttribute.defaultValue"
                      >
                        <mat-radio-button [value]="'true'">{{ labels?.True }}</mat-radio-button>
                        <mat-radio-button [value]="'false'">{{ labels?.False }}</mat-radio-button>
                      </mat-radio-group>
                    </div>

                    <div *ngSwitchCase="'checkbox'">
                      <app-attribute-checkbox
                        [options]="
                          selectedEntityList[i].selectedAttribute?.attributeType?.extendedProperties?.sourceValues
                        "
                        [defaultValue]="selectedEntityList[i].selectedAttribute.defaultValue"
                        (check)="onCheckedAttrVal($event, selectedEntityList[i].selectedAttribute)"
                      >
                      </app-attribute-checkbox>
                    </div>
                    <div>
                      <div *ngSwitchDefault>
                        <ul class="tbl-view cul-tbl-view">
                          <li>
                            <input
                              *ngIf="
                                !selectedEntityList[i].selectedAttribute?.rtctData &&
                                selectedEntityList[i].selectedAttribute?.nslAttributeProperties?.isDynamic == 'true'
                              "
                              matInput
                              type="text"
                              placeholder="{{ labels?.Source_Attribute }}"
                              [disabled]="true"
                            />
                            <input
                              *ngIf="
                                !(selectedEntityList[i].selectedAttribute?.nslAttributeProperties?.isDynamic == 'true')
                              "
                              class="add-value-alternate-input"
                              type="text"
                              name="ccdAttr"
                              [(ngModel)]="selectedEntityList[i].selectedAttribute.defaultValue"
                            />

                            <span class="small" *ngIf="selectedEntityList[i].selectedAttribute?.rtctData">{{
                              selectedEntityList[i].selectedAttribute?.rtctData | dcd
                            }}</span>
                            <span
                              class="material-icons"
                              *ngIf="selectedEntityList[i].selectedAttribute?.rtctData"
                              aria-label="Clear"
                              (click)="selectedEntityList[i].selectedAttribute.rtctData = ''"
                            >
                              {{ labels?.Cancel }}
                            </span>
                          </li>
                        </ul>
                        <div
                          *ngIf="selectedEntityList[i].selectedAttribute?.nslAttributeProperties?.isDynamic == 'true'"
                        >
                          <div
                            class="slide-toggle"
                            [class.active]="selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                            [class.inactive]="!selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                            (click)="rtctBooleanChange(selectedEntityList[i].selectedAttribute)"
                          >
                            <span
                              class="positive-text"
                              [hidden]="!selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                              >dynamic</span
                            >
                            <span
                              class="negative-text"
                              [hidden]="selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                              >static</span
                            >
                            <span
                              class="slide-toggle-button"
                              [class.active]="selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                              [class.inactive]="!selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                            ></span>
                          </div>
                          <div
                            *ngIf="
                              selectedEntityList[i].selectedAttribute?.attributeType?.uiElement?.uiElement ==
                                'duration' && selectedEntityList[i].selectedAttribute?.isRtctBoolean != 'true'
                            "
                          >
                            <node-attribute-duration
                              [defaultValue]="selectedEntityList[i].selectedAttribute.defaultValue"
                              (durationChange)="onDurationChange($event, selectedEntityList[i].selectedAttribute)"
                            >
                            </node-attribute-duration>
                          </div>
                          <div
                            *ngIf="
                              selectedEntityList[i].selectedAttribute?.attributeType?.uiElement?.uiElement == 'file' &&
                              selectedEntityList[i].selectedAttribute?.isRtctBoolean != 'true'
                            "
                          >
                            <app-file-upload
                              [fileUploadFromEntity]="true"
                              [editFileUrl]="editFileUrl"
                              (fileUploadResponse)="fileUploadUrl($event, selectedEntityList[i].selectedAttribute)"
                            >
                            </app-file-upload>
                          </div>
                          <div
                            *ngIf="
                              selectedEntityList[i].selectedAttribute?.isRtctBoolean != 'true' &&
                              selectedEntityList[i].selectedAttribute?.attributeType?.uiElement?.uiElement !=
                                'duration' &&
                              selectedEntityList[i].selectedAttribute?.attributeType?.uiElement?.uiElement != 'file'
                            "
                          >
                            <input
                              class="add-value-alternate-input"
                              type="text"
                              name="ccdAttr"
                              [(ngModel)]="selectedEntityList[i].selectedAttribute.defaultValue"
                              [readonly]="selectedEntityList[i].selectedAttribute?.isRtctBoolean"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <button class="btn btn-default mt-3 p-0">
                  {{ labels?.Save }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <mat-accordion>
      <mat-expansion-panel hideToggle class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <div class="details">
            <!-- <p class="sub"></p> -->
            <p class="title">{{ changeUnit?.name }}</p>
          </div>
          <a class="more-icon" id="moreIntentDetails_{{ changeUnit?.name?.replaceAll(' ', '_') }}"><span></span></a>
        </mat-expansion-panel-header>
        <div class="item-body">
          <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of changeUnit?.layers">
            <mat-expansion-panel-header *ngIf="layer.type != 'reservedActions'">
              <div class="details">
                <!-- <p class="sub"></p> -->
                <p class="title">{{ layer.type }}</p>
              </div>
              <a class="more-icon" id="moreLayersDetails_{{ layer?.type }}"><span></span></a>
            </mat-expansion-panel-header>
            <mat-expansion-panel
              hideToggle
              class="mat-elevation-z0"
              *ngFor="let ent of layer.participatingItems; let entIndex = index"
            >
              <mat-expansion-panel-header>
                <div class="d-flex item level-three align-items-center">
                  <div class="details">
                    <!-- <p class="sub"></p> -->
                    <p class="title">{{ ent?.name }}</p>
                  </div>
                  <a class="more-icon" id="moreEntityDetails_{{ ent?.name?.replaceAll(' ', '_') }}"><span></span></a>
                </div>
              </mat-expansion-panel-header>
              <div class="mat-elevation-z0" *ngFor="let attr of ent.nslAttributes">
                <div class="d-flex item level-four align-items-center">
                  <div class="d-flex" *ngIf="!attr.generalEntity">
                    {{ attr?.name
                    }}<a
                      class="create-new-btn"
                      id="addAttr_{{ attr?.name }}"
                      (click)="addAttr(attr, entIndex, ent, layer.type)"
                    ></a>
                  </div>
                  <div class="d-flex" *ngIf="attr.generalEntity">
                    <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-expansion-panel>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
