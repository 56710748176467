<div class="ecommerce-expandcards ecommerce-expandcards-bg ecommerce-expand-wireframe">
  <div class="entitytemplates row">
    <div class="col-12" *ngIf="expandType == 94">
      <div class="ecommerce-expand-card-1 ecommerce-expand expandAttributes-14">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-1">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="col-5">
            <ul>
              <li class="card-main-logo">
                <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-brand">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-discount">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-quantity card-quantity-margin">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-quantity">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 95">
      <div class="ecommerce-expand-card-2 ecommerce-expand expandAttributes-9">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-image col-5">
            <ul>
              <li class="card-badge">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="card-img">
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-7">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-quantity">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 96">
      <div class="ecommerce-expand-card-3 ecommerce-expand expandAttributes-18">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-6">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[7] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-6 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="hr"></div>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-discount">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-small">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-type">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textbox">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-information">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 97">
      <div class="ecommerce-expand-card-4 ecommerce-expand expandAttributes-9">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-4">
            <div class="col-12 leftSide">
              <div class="col-12 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-8 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-badge">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="hr"></div>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 98">
      <div class="ecommerce-expand-card-5 ecommerce-expand expandAttributes-11">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-1">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="col-5 card-main-img">
            <ul>
              <li class="card-main-logo">
                <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 99">
      <div class="ecommerce-expand-card-6 ecommerce-expand expandAttributes-11">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-8">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-4 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-info">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-row">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-row">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-row">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 100">
      <div class="ecommerce-expand-card-7 ecommerce-expand expandAttributes-19">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-6 expandcard-imgcontent">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 images-card7">
              <div class="card-img col-3">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-9 card-main-img">
                <ul>
                  <li class="card-main-logo">
                    <img [src]="contentUrl[7] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-review-rating-txt">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="card-pin-check d-flex">
                <li class="card-pincode">
                  <ng-container
                    *ngIf="attributes[18]"
                    dynamicField
                    [formData]="attributes[18][attributes[18].attributeName]"
                    [group]="attributes[18][attributes[18].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-check-btn">
                  <ng-container
                    *ngIf="attributes[19]"
                    dynamicField
                    [formData]="attributes[19][attributes[19].attributeName]"
                    [group]="attributes[19][attributes[19].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <li class="linebar"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 101">
      <div class="ecommerce-expand-card-8 ecommerce-expand expandAttributes-15">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-4">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-12 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-8 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="rating-reviews">
                <li class="card-rating">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>

                <li class="card-reviews">
                  <ng-container
                    *ngIf="attributes[10]"
                    dynamicField
                    [formData]="attributes[10][attributes[10].attributeName]"
                    [group]="attributes[10][attributes[10].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-info">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-discount">
                <span class="discount">
                  <ng-container
                    *ngIf="attributes[13]"
                    dynamicField
                    [formData]="attributes[13][attributes[13].attributeName]"
                    [group]="attributes[13][attributes[13].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-pincode">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 102">
      <div class="ecommerce-expand-card-9 ecommerce-expand expandAttributes-16">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-4">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-12 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-8 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="price-discount">
                <li class="card-price">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>

                <li class="card-discount">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <li class="card-offers">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 103">
      <div class="ecommerce-expand-card-10 ecommerce-expand expandAttributes-14">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-8 expandcard-imgcontent">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 images-card10">
              <div class="card-img col-6">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-6 card-main-img">
                <ul>
                  <li class="card-main-logo">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-data col-4">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-type">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-list">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-list">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-list">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 104">
      <div class="ecommerce-expand-card-11 ecommerce-expand expandAttributes-18">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-1">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="col-5">
            <ul>
              <li class="card-main-logo">
                <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-brand">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-discount">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-small">
                Attribute
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-quantity card-quantity-margin">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-quantity">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar linebr"></li>
              <li class="card-Coupon">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 105">
      <div class="ecommerce-expand-card-12 ecommerce-expand expandAttributes-12">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-image col-5">
            <ul>
              <li class="card-badge">
                <ng-container
                  *ngIf="attributes[1]"
                  dynamicField
                  [formData]="attributes[1][attributes[1].attributeName]"
                  [group]="attributes[1][attributes[1].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="card-img">
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="card-data col-7">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[3]"
                  dynamicField
                  [formData]="attributes[3][attributes[3].attributeName]"
                  [group]="attributes[3][attributes[3].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[3] && checkEventGSI(attributes[3], 3)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[3]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[3][attributes[3].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-quantity">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-Notify">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-email">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 106">
      <div class="ecommerce-expand-card-13 ecommerce-expand expandAttributes-20">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-6">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[7] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-6 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="hr"></div>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-discount">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-small-text">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-type">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textbox">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-information">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-product-description">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-product-summary">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 107">
      <div class="ecommerce-expand-card-14 ecommerce-expand expandAttributes-12">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-4">
            <div class="col-12 leftSide">
              <div class="col-12 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-8 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-badge">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="hr"></div>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-textbox">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="icon">
                <li class="card-icon">
                  <ng-container
                    *ngIf="attributes[11]"
                    dynamicField
                    [formData]="attributes[11][attributes[11].attributeName]"
                    [group]="attributes[11][attributes[11].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-icon">
                  <ng-container
                    *ngIf="attributes[12]"
                    dynamicField
                    [formData]="attributes[12][attributes[12].attributeName]"
                    [group]="attributes[12][attributes[12].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 108">
      <div class="ecommerce-expand-card-15 ecommerce-expand expandAttributes-15">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="card-img col-1">
            <ul>
              <li class="card-logo">
                <img [src]="contentUrl[1] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-logo">
                <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
              </li>
            </ul>
          </div>
          <div class="col-5 card-main-img">
            <ul>
              <li class="card-main-logo">
                <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
              </li>
              <li class="card-img-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="linebar"></li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle-small">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-Notify">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-email">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 109">
      <div class="ecommerce-expand-card-16 ecommerce-expand expandAttributes-14">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-8">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-6 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-4 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[4]"
                  dynamicField
                  [formData]="attributes[4][attributes[4].attributeName]"
                  [group]="attributes[4][attributes[4].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[4] && checkEventGSI(attributes[4], 4)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[4]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[4][attributes[4].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-info">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-row">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-row">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-row">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-color">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-reviews">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 110">
      <div class="ecommerce-expand-card-17 ecommerce-expand expandAttributes-25">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-6 expandcard-imgcontent">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 images-card7">
              <div class="card-img col-3">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-9 card-main-img">
                <ul>
                  <li class="card-main-logo">
                    <img [src]="contentUrl[7] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-data col-6">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-details">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-review-rating-txt">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="card-pin-check d-flex">
                <li class="card-pincode">
                  <ng-container
                    *ngIf="attributes[17]"
                    dynamicField
                    [formData]="attributes[17][attributes[17].attributeName]"
                    [group]="attributes[17][attributes[17].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-check-btn">
                  <ng-container
                    *ngIf="attributes[18]"
                    dynamicField
                    [formData]="attributes[18][attributes[18].attributeName]"
                    [group]="attributes[18][attributes[18].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <li class="linebar"></li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers">
                <ng-container
                  *ngIf="attributes[20]"
                  dynamicField
                  [formData]="attributes[20][attributes[20].attributeName]"
                  [group]="attributes[20][attributes[20].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-details">
                <ng-container
                  *ngIf="attributes[21]"
                  dynamicField
                  [formData]="attributes[21][attributes[21].attributeName]"
                  [group]="attributes[21][attributes[21].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-details">
                <ng-container
                  *ngIf="attributes[22]"
                  dynamicField
                  [formData]="attributes[22][attributes[22].attributeName]"
                  [group]="attributes[22][attributes[22].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-details">
                <ng-container
                  *ngIf="attributes[23]"
                  dynamicField
                  [formData]="attributes[23][attributes[23].attributeName]"
                  [group]="attributes[23][attributes[23].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-details">
                <ng-container
                  *ngIf="attributes[24]"
                  dynamicField
                  [formData]="attributes[24][attributes[24].attributeName]"
                  [group]="attributes[24][attributes[24].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 111">
      <div class="ecommerce-expand-card-18 ecommerce-expand expandAttributes-17">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-4">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-12 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-3 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[6] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-8 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="rating-reviews">
                <li class="card-rating">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>

                <li class="card-reviews">
                  <ng-container
                    *ngIf="attributes[10]"
                    dynamicField
                    [formData]="attributes[10][attributes[10].attributeName]"
                    [group]="attributes[10][attributes[10].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-info">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-discount">
                <span class="discount">
                  <ng-container
                    *ngIf="attributes[13]"
                    dynamicField
                    [formData]="attributes[13][attributes[13].attributeName]"
                    [group]="attributes[13][attributes[13].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </span>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-pincode">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 112">
      <div class="ecommerce-expand-card-19 ecommerce-expand expandAttributes-25">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-4">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 leftSide">
              <div class="col-12 image">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 leftSide">
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-4 image">
                <ul>
                  <li class="small-card-image">
                    <img [src]="contentUrl[5] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-8 card-data">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <div class="price-discount">
                <li class="card-price">
                  <ng-container
                    *ngIf="attributes[8]"
                    dynamicField
                    [formData]="attributes[8][attributes[8].attributeName]"
                    [group]="attributes[8][attributes[8].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>

                <li class="card-discount">
                  <ng-container
                    *ngIf="attributes[9]"
                    dynamicField
                    [formData]="attributes[9][attributes[9].attributeName]"
                    [group]="attributes[9][attributes[9].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
              <li class="card-offers">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>

              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers-list">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-delivery">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div class="cta-btns">
              <ul class="col-5">
                <li class="card-color">
                  <ng-container
                    *ngIf="attributes[16]"
                    dynamicField
                    [formData]="attributes[16][attributes[16].attributeName]"
                    [group]="attributes[16][attributes[16].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="hr"></div>
            <div class="col-12 card-info-list">
              <div class="col-5 card-info-height">
                <ul>
                  <li class="card-info">
                    <ng-container
                      *ngIf="attributes[17]"
                      dynamicField
                      [formData]="attributes[17][attributes[17].attributeName]"
                      [group]="attributes[17][attributes[17].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 17)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                  <li class="card-info">
                    <ng-container
                      *ngIf="attributes[18]"
                      dynamicField
                      [formData]="attributes[18][attributes[18].attributeName]"
                      [group]="attributes[18][attributes[18].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                  <li class="card-info">
                    <ng-container
                      *ngIf="attributes[19]"
                      dynamicField
                      [formData]="attributes[19][attributes[19].attributeName]"
                      [group]="attributes[19][attributes[19].attributeName + 'form']"
                      [isTableConfig]="true"
                    >
                    </ng-container>
                    <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                      <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                        <button
                          type="button"
                          mat-button
                          color="primary"
                          (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                        >
                          {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-2"></div>
              <div class="col-5">
                <li class="card-info">
                  <ng-container
                    *ngIf="attributes[20]"
                    dynamicField
                    [formData]="attributes[20][attributes[20].attributeName]"
                    [group]="attributes[20][attributes[20].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[20] && checkEventGSI(attributes[20], 20)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[20]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[20][attributes[20].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-info">
                  <ng-container
                    *ngIf="attributes[21]"
                    dynamicField
                    [formData]="attributes[21][attributes[21].attributeName]"
                    [group]="attributes[21][attributes[21].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[21] && checkEventGSI(attributes[21], 21)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[21]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[21][attributes[21].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
                <li class="card-info">
                  <ng-container
                    *ngIf="attributes[22]"
                    dynamicField
                    [formData]="attributes[22][attributes[22].attributeName]"
                    [group]="attributes[22][attributes[22].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[22] && checkEventGSI(attributes[22], 22)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[22]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[22][attributes[22].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </div>
            </div>
            <div class="hr"></div>

            <ul>
              <li class="card-qa-title">
                <ng-container
                  *ngIf="attributes[23]"
                  dynamicField
                  [formData]="attributes[23][attributes[23].attributeName]"
                  [group]="attributes[23][attributes[23].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[23] && checkEventGSI(attributes[23], 23)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[23]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[23][attributes[23].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-qa">
                <ng-container
                  *ngIf="attributes[24]"
                  dynamicField
                  [formData]="attributes[24][attributes[24].attributeName]"
                  [group]="attributes[24][attributes[24].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[24] && checkEventGSI(attributes[24], 24)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[24]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[24][attributes[24].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-question">
                <ng-container
                  *ngIf="attributes[25]"
                  dynamicField
                  [formData]="attributes[25][attributes[25].attributeName]"
                  [group]="attributes[25][attributes[25].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[25] && checkEventGSI(attributes[25], 25)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[25]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[25][attributes[25].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == 113">
      <div class="ecommerce-expand-card-20 ecommerce-expand expandAttributes-19">
        <div class="top-header">
          <mat-icon (click)="closeExpand()">close</mat-icon>
        </div>
        <div class="expandcard row">
          <div class="col-8 expandcard-imgcontent">
            <div class="col-12 title">
              <ul>
                <li class="image-title">
                  <ng-container
                    *ngIf="attributes[1]"
                    dynamicField
                    [formData]="attributes[1][attributes[1].attributeName]"
                    [group]="attributes[1][attributes[1].attributeName + 'form']"
                    [isTableConfig]="true"
                  >
                  </ng-container>
                  <div *ngIf="attributes[1] && checkEventGSI(attributes[1], 1)">
                    <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[1]">
                      <button
                        type="button"
                        mat-button
                        color="primary"
                        (click)="saveEventGSI(currEvent, attributes[1][attributes[1].attributeName])"
                      >
                        {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-12 images-card10">
              <div class="card-img col-6">
                <ul>
                  <li class="card-logo">
                    <img [src]="contentUrl[2] | CDNUrlRewritePipe" alt="" />
                  </li>
                  <li class="card-logo">
                    <img [src]="contentUrl[3] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
              <div class="col-6 card-main-img">
                <ul>
                  <li class="card-main-logo">
                    <img [src]="contentUrl[4] | CDNUrlRewritePipe" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-data col-4">
            <ul>
              <li class="e-card-title">
                <ng-container
                  *ngIf="attributes[5]"
                  dynamicField
                  [formData]="attributes[5][attributes[5].attributeName]"
                  [group]="attributes[5][attributes[5].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[5] && checkEventGSI(attributes[5], 5)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[5]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[5][attributes[5].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-subtitle">
                <ng-container
                  *ngIf="attributes[6]"
                  dynamicField
                  [formData]="attributes[6][attributes[6].attributeName]"
                  [group]="attributes[6][attributes[6].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[6] && checkEventGSI(attributes[6], 6)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[6]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[6][attributes[6].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-price">
                <ng-container
                  *ngIf="attributes[7]"
                  dynamicField
                  [formData]="attributes[7][attributes[7].attributeName]"
                  [group]="attributes[7][attributes[7].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[7] && checkEventGSI(attributes[7], 7)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[7]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[7][attributes[7].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-description">
                <ng-container
                  *ngIf="attributes[8]"
                  dynamicField
                  [formData]="attributes[8][attributes[8].attributeName]"
                  [group]="attributes[8][attributes[8].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[8] && checkEventGSI(attributes[8], 8)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[8]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[8][attributes[8].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size">
                <ng-container
                  *ngIf="attributes[9]"
                  dynamicField
                  [formData]="attributes[9][attributes[9].attributeName]"
                  [group]="attributes[9][attributes[9].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[9] && checkEventGSI(attributes[9], 9)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[9]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[9][attributes[9].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-size-type">
                <ng-container
                  *ngIf="attributes[10]"
                  dynamicField
                  [formData]="attributes[10][attributes[10].attributeName]"
                  [group]="attributes[10][attributes[10].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[10] && checkEventGSI(attributes[10], 10)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[10]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[10][attributes[10].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-offers">
                <ng-container
                  *ngIf="attributes[11]"
                  dynamicField
                  [formData]="attributes[11][attributes[11].attributeName]"
                  [group]="attributes[11][attributes[11].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[11] && checkEventGSI(attributes[11], 11)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[11]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[11][attributes[11].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-list">
                <ng-container
                  *ngIf="attributes[12]"
                  dynamicField
                  [formData]="attributes[12][attributes[12].attributeName]"
                  [group]="attributes[12][attributes[12].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[12] && checkEventGSI(attributes[12], 12)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[12]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[12][attributes[12].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-list">
                <ng-container
                  *ngIf="attributes[13]"
                  dynamicField
                  [formData]="attributes[13][attributes[13].attributeName]"
                  [group]="attributes[13][attributes[13].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[13] && checkEventGSI(attributes[13], 13)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[13]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[13][attributes[13].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-list">
                <ng-container
                  *ngIf="attributes[14]"
                  dynamicField
                  [formData]="attributes[14][attributes[14].attributeName]"
                  [group]="attributes[14][attributes[14].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[14] && checkEventGSI(attributes[14], 14)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[14]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[14][attributes[14].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-info">
                <ng-container
                  *ngIf="attributes[15]"
                  dynamicField
                  [formData]="attributes[15][attributes[15].attributeName]"
                  [group]="attributes[15][attributes[15].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[15] && checkEventGSI(attributes[15], 15)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[15]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[15][attributes[15].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-reviews">
                <ng-container
                  *ngIf="attributes[16]"
                  dynamicField
                  [formData]="attributes[16][attributes[16].attributeName]"
                  [group]="attributes[16][attributes[16].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[16] && checkEventGSI(attributes[16], 16)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[16]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[16][attributes[16].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-rating">
                <ng-container
                  *ngIf="attributes[17]"
                  dynamicField
                  [formData]="attributes[17][attributes[17].attributeName]"
                  [group]="attributes[17][attributes[17].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[17] && checkEventGSI(attributes[17], 1)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[17]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[17][attributes[17].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-feedback">
                <ng-container
                  *ngIf="attributes[18]"
                  dynamicField
                  [formData]="attributes[18][attributes[18].attributeName]"
                  [group]="attributes[18][attributes[18].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[18] && checkEventGSI(attributes[18], 18)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[18]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[18][attributes[18].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
              <li class="card-more">
                <ng-container
                  *ngIf="attributes[19]"
                  dynamicField
                  [formData]="attributes[19][attributes[19].attributeName]"
                  [group]="attributes[19][attributes[19].attributeName + 'form']"
                  [isTableConfig]="true"
                >
                </ng-container>
                <div *ngIf="attributes[19] && checkEventGSI(attributes[19], 19)">
                  <div class="onselect-gsi" *ngFor="let currEvent of currentEventDataArray[19]">
                    <button
                      type="button"
                      mat-button
                      color="primary"
                      (click)="saveEventGSI(currEvent, attributes[19][attributes[19].attributeName])"
                    >
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
