import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatorService, AlertService, NodeGsiFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface DialogData {
  sourceEntity: any;
  targetEntity: any;
  entityDCDMap: any;
  templateMapping?: boolean;
  map?: any;
}

@Component({
  selector: 'app-entity-to-entity-dcd',
  templateUrl: './entity-to-entity-dcd.component.html',
  styleUrls: ['./entity-to-entity-dcd.component.scss'],
})
export class EntityToEntityDCDComponent implements OnInit {
  sourceEntity: any = {};
  targetEntity: any = {};
  entityDCDMap: any = {
    sourceGeneralEntityName: '',
    targetGeneralEntityName: '',
    matchedAttributesMap: [],
  };
  templateMapppings: any = [];
  templateAttributeMap: any = {
    name: '',
    source: '',
  };
  currentAttrMap: any = { name: '', attributes: [] };
  sourceAttrList: any = [];
  targetAttrList: any = [];
  sourceAttr: any = {};
  targetAttr: any = {};
  confirmation: boolean = true;
  private ngUnsubscribe = new Subject();
  labels: any = {};
  templateMapping: boolean = false;
  selectedFunction: string = '';
  selectedCategory: string = '';
  firstValue: any = '';
  secondValue: any = '';
  operators: any = [];
  functionCategories: any = [];
  functionDetails: any = [];
  currentMapIndex: number = 0;
  selectedOperator: string = '';
  currentEntityMap: any;
  mapIndex: number = -1;
  isFirstSourceAttr: boolean = false;
  isSecondSourceAttr: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: DialogData,
    private translator: TranslatorService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<EntityToEntityDCDComponent>,
    private gsiFacade: NodeGsiFacadeService
  ) {
    this.detectLanguageChange();
    this.getFunctionCategories();
    this.getOperators();
    this.getFunctionDetails();
  }

  ngOnInit(): void {
    this.sourceEntity = this.dialog?.sourceEntity;
    this.targetEntity = this.dialog?.targetEntity;
    this.templateMapping = this.dialog?.templateMapping;
    this.gsiFacade.getOperators();
    this.gsiFacade.getFunctionCategories();
    /*istanbul ignore else */
    if (this.dialog?.entityDCDMap?.matchedAttributesMap && this.dialog?.entityDCDMap?.matchedAttributesMap?.length > 0)
      this.entityDCDMap.matchedAttributesMap = this.dialog?.entityDCDMap?.matchedAttributesMap;
    /*istanbul ignore else */
    if (this.sourceEntity?.nslAttributes || this.sourceEntity?.attributes)
      this.sourceAttrList = JSON.parse(
        JSON.stringify(!this.templateMapping ? this.sourceEntity?.nslAttributes : this.sourceEntity?.attributes)
      );
    /*istanbul ignore else */
    if (this.targetEntity?.nslAttributes || this.targetEntity?.attributes)
      this.targetAttrList = JSON.parse(
        JSON.stringify(!this.templateMapping ? this.targetEntity?.nslAttributes : this.targetEntity?.attributes)
      );
    if (this.templateMapping) {
      this.templateMapppings = this.dialog?.map ? JSON.parse(this.dialog?.map) : [];
      this.currentEntityMap = this.templateMapppings?.find((map: any) => map?.name == this.targetEntity?.name);
      if (this.currentEntityMap) this.currentMapIndex = this.currentEntityMap?.attributes?.length;
      else {
        this.currentEntityMap = {
          name: this.targetEntity?.name,
          attributes: [],
          isArray: true,
        };
      }
    }
  }
  discardMapping() {
    this.sourceAttr = {};
    this.targetAttr = {};
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.labels = res;
      }
    });
  }
  mapAttr() {
    if (!this.templateMapping) {
      this.entityDCDMap.sourceGeneralEntityName = this.sourceEntity?.name;
      this.entityDCDMap.targetGeneralEntityName = this.targetEntity?.name;
      // if (!this.entityDCDMap?.matchedAttributesMap) this.entityDCDMap.matchedAttributesMap = [];
      let mapObj: any = {
        sourceAttribute: this.sourceAttr?.name,
        targetAttribute: this.targetAttr?.name,
      };
      if (this.entityDCDMap.matchedAttributesMap?.includes(mapObj)) {
        this.alertService.showToaster('Mapping alredy present', '', 'warning');
      } else this.entityDCDMap.matchedAttributesMap.push(mapObj);
      // let i = this.sourceAttrList?.indexOf(this.sourceAttr);
      // if (i != -1) this.sourceAttrList?.splice(i, 1);
    } else {
      let map: any;
      this.mapIndex = this.templateMapppings?.indexOf((map: any) => map?.name == this.targetEntity?.name);
      /*istanbul ignore else */
      if (this.mapIndex == -1) {
        map = {
          name: this.targetEntity?.name,
          attributes: [],
        };
      }
      /*istanbul ignore else */
      if (this.currentAttrMap?.arguments?.length) {
        this.currentAttrMap.expression = {
          function: this.currentAttrMap?.arguments?.[0]?.function,
          category: this.currentAttrMap?.arguments?.[0]?.category,
          arguments: this.currentAttrMap?.arguments?.[0]?.arguments,
        };
        delete this.currentAttrMap.arguments;
      }
      /*istanbul ignore else */
      if (this.currentAttrMap?.scope?.length) {
        this.currentAttrMap.expression = [this.currentAttrMap.scope];
        delete this.currentAttrMap.scope;
      }
      this.currentAttrMap.name = this.targetAttr?.name;
      map.attributes.push(this.currentAttrMap);
      if (this.mapIndex == -1) {
        this.templateMapppings.push(map);
        this.mapIndex = 0;
      } else this.templateMapppings[this.mapIndex] = map;
      this.currentAttrMap = {};
      // this.currentMapIndex += 1;
      console.log(this.templateMapppings, 'templateMappings');
    }
    this.firstValue = this.secondValue = '';
    this.selectedCategory = this.selectedFunction = this.selectedOperator = '';
    let i = this.targetAttrList?.indexOf(this.targetAttr);
    /*istanbul ignore else */
    if (i != -1) this.targetAttrList?.splice(i, 1);
    this.sourceAttr = {};
    this.targetAttr = {};
  }

  /**
   * @author Prakhar Kumar
   * @Description Initialise Map for Mapping Template RCU, setting initial variables
   * @Date 7/11/2023
   */

  initialiseMap() {
    if (this.templateMapping) {
      if (!this.firstValue) {
        this.currentAttrMap = this.currentEntityMap?.attributes?.[this.currentMapIndex];
        /*istanbul ignore else */
        if (!this.currentAttrMap) {
          this.currentAttrMap = this.currentEntityMap.attributes[this.currentMapIndex] = JSON.parse(
            JSON.stringify(this.templateAttributeMap)
          );
        }
        let key1 = this.isFirstSourceAttr ? 'source' : 'value';
        this.currentAttrMap[key1] = this.isFirstSourceAttr ? this.firstValue : `${this.firstValue}`;
        // this.currentAttrMap.value = this.firstValue;
        console.log(this.currentAttrMap);
      } else if (this.sourceAttr?.name && this.firstValue) {
        this.secondValue = this.sourceEntity?.name + '.' + this.sourceAttr?.name;
        this.isSecondSourceAttr = true;
      }
    }
  }

  /**
   * @author Prakhar Kumar
   * @Description Adding Function or expression to nested source selection
   * @Date 7/11/2023
   */
  addFunctionOrExpression(func?: any, exp?: any) {
    let key1 = this.isFirstSourceAttr ? 'source' : 'value';
    let key2 = this.isSecondSourceAttr ? 'source' : 'value';
    let temp: any = [];
    /*istanbul ignore else */
    if (this.currentAttrMap?.scope) temp = [{ scope: this.currentAttrMap?.scope }];
    /*istanbul ignore else */
    if (this.currentAttrMap?.arguments) temp = [...temp, { arguments: this.currentAttrMap?.arguments }];
    if (func) {
      let args: any = [];
      if (this.firstValue)
        args = [
          ...temp,
          { [key1]: this.isFirstSourceAttr ? this.firstValue : `"${this.firstValue}"` },
          { [key2]: this.isSecondSourceAttr ? this.secondValue : `"${this.secondValue}"` },
        ];
      else args = [{ temp }, { [key2]: this.isSecondSourceAttr ? this.secondValue : `"${this.secondValue}"` }];
      this.currentAttrMap = {
        arguments: [
          {
            function: this.selectedFunction,
            category: this.selectedCategory,
            arguments: args,
          },
        ],
      };
    } else {
      let scope: any = [];
      if (this.firstValue)
        scope = [
          ...temp,
          { [key1]: this.isFirstSourceAttr ? this.firstValue : `"${this.firstValue}"` },
          { operator: this.selectedOperator },
          { [key2]: this.isSecondSourceAttr ? this.secondValue : `"${this.secondValue}"` },
        ];
      else
        scope = [
          { temp },
          { operator: this.selectedOperator },
          { [key2]: this.isSecondSourceAttr ? this.secondValue : `"${this.secondValue}"` },
        ];
      this.currentAttrMap.scope = scope;
    }
    // this.selectedCategory = '';
    this.selectedFunction = '';
    this.selectedOperator = '';
    if (this.secondValue) {
      this.firstValue = '';
      this.secondValue = '';
      this.isFirstSourceAttr = this.isSecondSourceAttr = false;
    }
  }

  closeEntityMap() {
    this.dialogRef.close(!this.templateMapping ? this.entityDCDMap : this.templateMapppings);
  }

  deleteMapping(i: number) {
    let map = this.entityDCDMap?.matchedAttributesMap?.splice(i, 1);
    this.targetAttrList.push({ name: map?.[0]?.targetAttribute });
    // this.sourceAttrList.push({ name: map?.[0]?.sourceAttribute });
  }

  //To get Eligible operators
  getOperators() {
    this.gsiFacade.operators$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((operators: any) => {
      if (operators) {
        this.operators = operators;
      }
    });
  }

  //To get Function categories
  getFunctionCategories() {
    this.gsiFacade.functionCategories$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((functionCategories: any) => {
      if (functionCategories) {
        this.functionCategories = functionCategories;
      }
    });
  }

  //To get Categoric Function Details
  getFunctionDetails() {
    this.gsiFacade.functionDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((functionDetails: any) => {
      if (functionDetails) {
        this.functionDetails = functionDetails?.functions;
      }
    });
  }
  //To call function details API on the basis of categories selected
  fetchFunctionDetails(category: any) {
    if (category) this.gsiFacade.getFunctionDetails(category);
    else this.functionDetails = [];
  }

  //to set operator values
  setAttribute() {
    if (this.sourceAttr?.name && (this.selectedFunction || this.selectedOperator)) {
      this.firstValue = this.sourceEntity?.name + '.' + this.sourceAttr?.name;
      this.isFirstSourceAttr = true;
    } else if (this.sourceAttr?.name && this.firstValue) {
      this.secondValue = this.sourceEntity?.name + '.' + this.sourceAttr?.name;
      this.isSecondSourceAttr = true;
    }
    this.sourceAttr = {};
  }

  deleteTemplateMapping(i: number) {
    this.templateMapppings?.[this.mapIndex]?.attributes.pop(i);
  }
}
