import { NgModule } from '@angular/core';
import { WebexWidgetComponent } from './webex-widget.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [WebexWidgetComponent],
  imports: [ControlsMaterialModule],
  exports: [WebexWidgetComponent],
})
export class WebexWidgetModule {
  static entry = {
    webex: WebexWidgetComponent,
  };
}
