<div class="entityTable-configuration" *ngIf="!canvasDesignPage">
  <div class="nhCard" [ngClass]="{ 'show-table-preview': isPreview }">
    <div class="nhCard_header">
      <div class="nhCard_header_inner d-flex align-items-center justify-content-between">
        <div class="nhCard_header_left d-flex align-items-center">
          <button class="nh-unsetAll cursor-pointer nhCard_header_back">
            <em class="nh-icon nh-icon-chevron-left" (click)="goBackTable()"></em>
          </button>
          <h4 class="nh-fs-18">Configure Table</h4>
        </div>
        <div
          class="nhCard_header_right d-flex align-items-center"
          *ngIf="!isGrammer && (isCardConfig || isAdvancedFeature)"
        >
          <ul class="nhCard_header_right_list">
            <li class="eth-close" id="cancel_1" (click)="gotoMylibrary()"><i class="bi bi-x-lg"></i></li>
            <li class="eth-check" *ngIf="!isAdvancedFeature" id="next_1" (click)="gotoAdvancedFeature()">
              <i class="bi bi-check2"></i>
            </li>
            <!-- <li
              class="eth-check"
              *ngIf="isAdvancedFeature && !canvasDesignPage"
              id="next_2"
              (click)="goToFilterDesignPage()"
            >
              <i class="bi bi-check2"></i>
            </li> -->
            <li *ngIf="!isAdvancedFeature && !isPreview" (click)="setIsPreView(true)">
              <button class="btn publish">Preview</button>
            </li>
            <li *ngIf="isAdvancedFeature" (click)="goToFilterDesignPage()">
              <button class="btn publish">Publish</button>
            </li>
            <li *ngIf="!isAdvancedFeature" (click)="withoutAdvancedFeatures()">
              <button class="btn publish">Publish</button>
            </li>
            <li class="eth-close d-block" *ngIf="!isAdvancedFeature && isPreview" (click)="setIsPreView(false)">
              <i class="bi bi-x-lg"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="nhCard_maincontent">
      <div class="nhrow">
        <div class="nhrow_col nhrow_left" *ngIf="isCardConfig || !isAdvancedFeature">
          <div class="et-config-content-left">
            <!-- <i class="bi bi-grip-vertical"></i> -->
            <mat-tab-group class="form-tabs form-tabs-opt1">
              <mat-tab label="Attributes">
                <span class="search-filter">
                  <input
                    type="text"
                    placeholder="Search Attributes"
                    [(ngModel)]="attributeValue"
                    (keyup)="searchAttributesTable()"
                  />
                  <em class="nh-icon nh-icon-search-line"></em>
                </span>
                <div class="attributes-list">
                  <ul>
                    <ng-container *ngFor="let attr of nslAttributes">
                      <li
                        *ngIf="attr?.isSearched"
                        draggable="true"
                        (dragstart)="drag($event, attr, true)"
                        [id]="attr?.name"
                      >
                        <i class="bi bi-grip-vertical"></i>
                        <p>{{ attr?.name }}</p>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="GSI">
                <mat-accordion>
                  <ng-container *ngFor="let gsi of selectGsiList">
                    <mat-expansion-panel
                      *ngIf="gsi?.entityType != 'PAGE'"
                      draggable="true"
                      (dragstart)="drag($event, gsi, false)"
                      [id]="gsi.name"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex align-items-center justify-content-between">
                        <mat-slide-toggle
                          [(ngModel)]="gsi.enablePopup"
                          class="er-filter-toggle er-filter-toggle-a label-color"
                        >
                          <span>
                            Enable Popup
                          </span>
                        </mat-slide-toggle>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </mat-tab>
              <mat-tab label="PAGE">
                <mat-accordion>
                  <ng-container *ngFor="let gsi of selectGsiList">
                    <mat-expansion-panel
                      *ngIf="gsi?.entityType == 'PAGE'"
                      draggable="true"
                      (dragstart)="drag($event, gsi, false)"
                      [id]="gsi.name"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex align-items-center justify-content-between">
                        <mat-slide-toggle
                          [(ngModel)]="gsi.enablePopup"
                          class="er-filter-toggle er-filter-toggle-a label-color"
                        >
                          <span>
                            Enable Popup
                          </span>
                        </mat-slide-toggle>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <div class="nhrow_col nhrow_middle" [ngClass]="{ nhrow_middle_isAdvancedFeature: isAdvancedFeature }">
          <div class="entity-config-info" *ngIf="isCardConfig || !isAdvancedFeature">
            <div class="entity-config-info-header">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="entity-expand-wrap">
              <div class="entity-expand" *ngIf="!isAdvancedFeature && !isKanbanBoard">
                <div class="d-flex justify-content-end nh-mb-16">
                  <div class="gsibuttons" [ngClass]="{'nh-pr-3': dropActionBts?.length > 0}" *ngIf="!inTableGSIButtons">
                    <ng-container *ngFor="let btn of selectedGsi; let i = index">
                      <div class="icon-btn" 
                      [ngStyle]="{
                      'background-color': gsiBtnHovered?.[i] ? gsiBtnBackgroundHoverColor?.[i] : gsiBtnBackgroundColor?.[i],
                      'border-color': gsiBtnHovered?.[i] ? gsiBtnHoverBorderColor?.[i] : gsiBtnBorderColor?.[i],
                      'border-radius': gsiBtnborderRadius + 'px',
                      'border-width': gsiBtnborderWidth + 'px'}" [ngClass]="{ 'table-button-selected': gsiBtnSelected?.[i] }">
                        <span class="btn-close">
                          <i
                            class="bi bi-x"
                            *ngIf="dropActionBts?.[i]"
                            (click)="deleteGsiActionBtn(i); $event.stopPropagation()"
                          ></i>
                          <button
                            #gsiAction
                            id="{{ 'action_' + i }}"
                            pTooltip="{{ dropActionBts?.[i] ? dropActionBts?.[i]?.displayName : 'Action Button'}}"
                            tooltipPosition="top"
                            (drop)="dropActionBtn($event,childGsiMapping, i)"
                            (dragover)="allowDrop($event)"
                            (click)="selectGsiButton(i); gsiBtnSelected[i] = !gsiBtnSelected?.[i]"
                            [ngClass]="{'d-none':dropActionBts?.[i]?.gsiConfiguration?.gsiDisplay==='onlyIcon'}"
                            class="active action-button"
                            [ngStyle]="{
                            'color': gsiBtnHovered?.[i] ? gsiBtnFontHoverColor?.[i] : gsiBtnFontColor?.[i],
                            'font-family': gsiBtnFontFamily?.font,
                            'text-decoration-line': gsiBtnUnderline ? 'underline' : 'none',
                            'font-weight': gsiBtnBold ? 'bold' : 'normal',
                            'font-style': gsiBtnItalic ? 'italic' : 'normal',
                            'font-size': gsiBtnFontSize + 'px',
                            'justify-content': gsiBtnAlignment?.viewClass
                          }"
                          (mouseout)="gsiBtnHovered[i] = false"
                            (mouseover)="gsiBtnHovered[i] = true"
                          >
                            {{dropActionBts?.[i] ? dropActionBts?.[i]?.displayName : "Action Button"}}
                          </button>
                        </span>
                        <span *ngIf="dropActionBts?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'">
                          <img
                            [src]="dropActionBts?.[i]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[i]?.gsiConfiguration?.imageSrc: defaultGsiImages[dropActionBts?.[i]?.gsiConfiguration?.imageSrc]"
                          />
                        </span>
                        <span>
                          <img
                            class="icon"
                            *ngIf="dropActionBts?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                            [src]="dropActionBts?.[i]?.gsiConfiguration?.image === 'uploaded' ?  dropActionBts?.[i]?.gsiConfiguration?.imageSrc:defaultGsiImages[dropActionBts?.[i]?.gsiConfiguration?.imageSrc]"
                          />
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div
                  id="headerDescription"
                  class="table-description"
                  [ngClass]="headerDescriptionBackgroundSelected ? 'table-header-selected' : ''"
                  *ngIf="headerDescription && toggleHeaderChecked"
                  (click)="setHeaderDescriptionBackgroundSelected()"
                  (mouseover)="headerHovered = true"
                  (mouseout)="headerHovered = false"
                  [ngStyle]="{
                    'background-color': headerHovered ? headerDescriptionHoverColor : headerDescriptionBackground,
                    color: headerHovered ? headerDescriptionFontHoverColor : headerDescriptionFontColor
                  }"
                >
                  {{ headerDescription }}
                </div>
                <p-table
                  #dropTableData
                  class="entity-table-design"
                  [value]="tableData"
                  [resizableColumns]="true"
                  [styleClass]="gridLines?.selectedStyle?.class"
                  [scrollable]="true"
                  scrollHeight="100%"
                  columnResizeMode="expand"
                  scrollWidth="100%"
                  [ngClass]="{
                    'table-outline': gridLines?.outLine?.selected == true
                  }"
                  [tableStyle]="{
                    'min-width': tableWidth,
                    'border-color': gridLines?.outLine?.selected ? gridLines?.outLine?.borderColor : 'none',
                    'border-width': gridLines?.outLine?.selected ? gridLines?.outLine?.borderThickness + 'px' : '0px',
                    'border-radius': gridLines?.outLine?.selected ? gridLines?.outLine?.borderRadius + 'px' : '0px'
                  }"
                >
                  <ng-template pTemplate="header" let-expanded="expanded">
                    <tr>
                      <th
                        class="btn-tooltip d-flex"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'None',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        *ngIf="advanceTableFeatures?.checkboxSelection == true"
                        style="max-width: 50px; display: flex; justify-content: center;"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        pResizableColumn
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                      >
                        <p-tableHeaderCheckbox *ngIf="advanceTableFeatures?.multipleSelection"></p-tableHeaderCheckbox>
                        <ng-container *ngIf="!advanceTableFeatures?.multipleSelection">Select</ng-container>
                      </th>
                      <th *ngIf="isNestedRow1">
                        <button
                          type="button"
                          pButton
                          pRipple
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="isNestedRow ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                          (click)="isNestedRow = !isNestedRow"
                        ></button>
                      </th>
                      <th
                        class="btn-tooltip d-flex"
                        [ngStyle]="{
                          'background-color': hovered ? headerHoverColor : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'None',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        *ngIf="advanceTableFeatures.serialNumbers == true"
                        style="max-width: 50px; display: flex; justify-content: center;"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        pResizableColumn
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                      >
                        S. No
                      </th>
                      <th
                        class="btn-tooltip d-flex"
                        pTooltip="{{ row.attributeHeading }}"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        [ngStyle]="{
                          'background-color':
                            hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                              ? headerHoverColor
                              : headerBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'None',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        *ngFor="let row of tableData?.[0];let k = index"
                        class="table-row"
                        (drop)="dropAttributeInTemplates($event, k)"
                        (dragover)="allowDrop($event)"
                        (click)="setHeaderBackgroundSelected($event, k)"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        pResizableColumn
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                      >
                        <span
                        *ngIf="selectedDisplayName != k"
                          class="entity-table-header-span main-attr text-truncate"
                          [ngStyle]="{
                            color:
                              hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                                ? headerFontHoverColor
                                : headerFontColor,
                            'font-family': headerFontFamily?.font,
                            'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                            'font-weight': headerBold ? 'bold' : 'normal',
                            'font-style': headerItalic ? 'italic' : 'normal',
                            'font-size': headerFontSize + 'px',
                            'text-align': headerAlignment?.justify
                          }"
                          #attributeItem
                          >  {{ columnStyles[k].displayName }}
                        </span>
                        <input class="edit-name"  type="text" [(ngModel)]="columnStyles[k].displayName" *ngIf="selectedDisplayName == k" />
                        <span class="delete-attr" *ngIf="columnStyles[k].attributes && selectedDisplayName == k">
                          <em class="nh-icon nh-icon-check2 cursor-pointer" (click)="afterEditColumnName(); $event.stopPropagation()"></em>
                        </span>
                        <span class="delete-attr nh-r-35" *ngIf="columnStyles[k]?.attributes && selectedDisplayName != k">
                          <img
                            class="cursor-pointer"
                            (click)="onEditColumnName(k); $event.stopPropagation()"
                            [src]="'../../../../../assets/img/addmeasures/edit.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span>
                        <span class="delete-attr" *ngIf="columnStyles[k].attributes && selectedDisplayName != k">
                          <img
                            class="cursor-pointer"
                            (click)="onDeletingAttr($event, k); $event.stopPropagation()"
                            [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span>
                        <span>
                          <i
                            class="bi bi-x"
                            *ngIf="headerGsiMap.has(row.attributeHeading)"
                            (click)="deleteHeaderGsi(k)"
                          ></i>
                        </span>
                      
                    

                        
                        <!-- <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() != 'Attribute'"
                          ><img
                            class="cursor-pointer"
                            (click)="onDeletingAttr(attributeItem, k); $event.stopPropagation()"
                            [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span> -->
                      </th>
                      <th
                        *ngIf="inTableGSIButtons"
                        class="btn-tooltip d-flex"
                        class="table-row action-button"
                        pTooltip="{{ gsiConfigurations.gsiInlineTitle }}"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        [ngClass]="headerBackgroundSelected ? 'table-header-selected' : ''"
                        (click)="setHeaderBackgroundSelected($event, -1)"
                        [ngStyle]="{
                          'background-color': headerBackground
                        }"
                        pResizableColumn
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                      >
                        <span
                          class="entity-table-header-span main-attr text-truncate"
                          [ngStyle]="{
                            color: hovered ? headerFontHoverColor : headerFontColor,
                            'font-family': headerFontFamily?.font,
                            'text-decoration-line': headerUnderLine ? 'underline' : 'none',
                            'font-weight': headerBold ? 'bold' : 'normal',
                            'font-style': headerItalic ? 'italic' : 'normal',
                            'font-size': headerFontSize + 'px',
                            'text-align': headerAlignment?.justify
                          }"
                          >{{ gsiConfigurations?.gsiInlineTitle }}
                        </span>
                      </th>
                    </tr>
                    <tr [ngClass]="isNestedRow ? '' : 'd-none'">
                      <th
                        class="btn-tooltip d-flex"
                        pTooltip="{{ row.attributeHeading }}"
                        id="nestedRowHeader"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                        [ngStyle]="{
                          'background-color':
                            hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                              ? nestedRowHeaderHoverColor
                              : nestedRowHeaderBackground,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'None',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="nestedRowHeaderBackgroundSelected ? 'table-header-selected' : ''"
                        *ngFor="let row of nestedTableData?.[0];let k = index"
                        class="table-row"
                        (drop)="nestedRowDropAttributeInTemplates($event, k)"
                        (dragover)="allowDrop($event)"
                        (click)="setNestedRowHeaderBackgroundSelected()"
                        (mouseover)="hovered = true"
                        (mouseout)="hovered = false"
                        pResizableColumn
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                      >
                        <!-- [frozen]="detectHeaderFreeze(row.attributeHeading)" -->
                        <span
                          class="entity-table-header-span main-attr text-truncate"
                          [ngStyle]="{
                            color:
                              hovered || (k == columnHovered && advanceTableFeatures.columnHighlights)
                                ? nestedRowHeaderFontHoverColor
                                : nestedRowHeaderFontColor,
                            'font-family': nestedRowHeaderFontFamily?.font,
                            'text-decoration-line': nestedRowHeaderUnderLine ? 'underline' : 'none',
                            'font-weight': nestedRowHeaderBold ? 'bold' : 'normal',
                            'font-style': nestedRowHeaderItalic ? 'italic' : 'normal',
                            'font-size': nestedRowHeaderFontSize + 'px',
                            'text-align': nestedRowHeaderAlignment?.justify
                          }"
                          #attributeItem
                          >{{ row?.attributeHeading }}
                        </span>
                        <span class="delete-attr" *ngIf="attributeItem.innerHTML.trim() !== 'Attribute'"
                          ><img
                            class="cursor-pointer"
                            (click)="onDeletingNestedAttr(attributeItem, k); $event.stopPropagation()"
                            [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
                            alt=""
                        /></span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-cols let-i="rowIndex">
                    <tr
                    *ngIf="i == 0"
                    [ngClass]="{
                      'row-outline': gridLines?.rows?.selected == true
                    }"
                    [ngStyle]="{
                      'border-top-color': gridLines?.rows?.selected ? gridLines?.rows?.borderColor : 'none',
                      'border-top-width': gridLines?.rows?.selected ? gridLines?.rows?.borderThickness + 'px' : '0px',
                      'border-radius': gridLines?.rows?.selected ? gridLines?.rows?.borderRadius + 'px' : '0px'
                    }"
                  >
                    <td
                      class="table-row"
                      (click)="setRowBackGroundSelected(i)"
                      [ngClass]="{
                        'column-outline': gridLines?.column === true
                      }"
                      *ngIf="advanceTableFeatures?.checkboxSelection == true"
                      style="max-width: 50px; display: flex; justify-content: center;"
                    >
                      <p-tableCheckbox [value]="cols"> </p-tableCheckbox>
                    </td>

                    <td
                      class="table-row"
                      (click)="setRowBackGroundSelected(i)"
                      *ngIf="advanceTableFeatures.serialNumbers == true"
                      [ngClass]="{
                        'column-outline': gridLines?.column === true
                      }"
                    >
                      {{ i + 1 }}
                    </td>
                    <td
                      *ngFor="let col of cols; let j = index"
                      class="table-row"
                      [ngClass]="{
                        'column-outline': gridLines?.column?.selected == true
                      }"
                      [ngStyle]="{
                        'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                        'border-right-width': gridLines?.column?.selected
                          ? gridLines?.column?.borderThickness + 'px'
                          : '0px',
                        'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                      }"
                    >
                      <div
                        class="table-value btn-tooltip w-100"
                        pTooltip="{{ col.attributeValue }}"
                        tooltipPosition="top"
                        tooltipStyleClass="transcustomtolltipbook"
                      >
                        <div class="container-fluid">
                          <div class="row">
                            <ng-container *ngFor="let attribute of columnStyles[j].attributeList; let k = index">
                              <div
                                (click)="selectColumnAttribute(j, k)"
                                class="col-{{ columnStyles[j].sizes[k] }}"
                                [ngStyle]="{
                                  border: selectedColumn == j && selectedColumnAttribute == k ? '1px solid blue' : '',
                                  color: columnStyles[j].colors[k],
                                  'font-weight': columnStyles[j].Weight[k],
                                  'font-size': columnStyles[j].fontSizes[k] + 'px',
                                  'font-family': columnStyles[j].fontFamily[k].font
                                }"
                              >
                                {{ attribute }} {{ columnStyles[j].seperators[k] }}
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      class="table-row"
                      *ngIf="inTableGSIButtons"
                      class="table-row"
                      (click)="setRowBackGroundSelected(i)"
                      [ngStyle]="{
                        'background-color':
                          i % 2 == 0
                            ? evenHovered == i
                              ? evenRowHoverColor
                              : evenRowColor
                            : oddHovered == i
                            ? oddRowHoverColor
                            : oddRowColor
                      }"
                      [ngClass]="{
                        'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                        'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                      }"
                      (mouseover)="setHover(i)"
                      (mouseout)="setOutHover(i)"
                    >
                      <ng-container *ngIf="!isMore" class="table-value btn-tooltip">
                        <div class="gsibuttons" *ngIf="dropActionBts.length > 0">
                          <button
                            *ngFor="let btn of dropActionBts; index as ind"
                            (click)="$event.stopPropagation(); gsiBtnSelected[ind] = !gsiBtnSelected?.[ind]"
                            (mouseover)="gsiBtnHovered[ind] = true"
                            (mouseout)="gsiBtnHovered[ind] = false"
                            [ngClass]="{ 'table-button-selected': gsiBtnSelected?.[ind] }"
                            [ngStyle]="{
                              'background-color': gsiBtnHovered?.[ind] && ( i % 2 == 0
                            ? evenHovered == i :oddHovered == i ) ? gsiBtnBackgroundHoverColor?.[ind] : gsiBtnBackgroundColor?.[ind],
                              color: gsiBtnHovered?.[ind] && ( i % 2 == 0
                            ? evenHovered == i :oddHovered == i )? gsiBtnFontHoverColor?.[ind] : gsiBtnFontColor?.[ind],
                              'font-family': gsiBtnFontFamily?.font,
                              'text-decoration-line': gsiBtnUnderline ? 'underline' : 'none',
                              'font-weight': gsiBtnBold ? 'bold' : 'normal',
                              'font-style': gsiBtnItalic ? 'italic' : 'normal',
                              'font-size': gsiBtnFontSize + 'px',
                              'text-align': gsiBtnAlignment?.justify
                            }"
                          >
                            {{ btn?.displayName }}
                          </button>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isMore">
                        <div
                          class="tbl-accordion cursor-pointer"
                          *ngIf="dropActionBts.length > 0"
                          (click)="more.toggle($event)"
                        >
                          <p-overlayPanel styleClass="filterOverlay" #more>
                            <span class="search-filter">
                              <input type="text" placeholder="Search" />
                              <em class="nh-icon nh-icon-search-line"></em>
                              <em class="nh-icon nh-icon-x-lg d-none"></em>
                            </span>
                            <div class="checckbox" *ngFor="let btn of dropActionBts; index as ind">
                              <mat-option>
                                <div class="attr-name">{{ btn?.displayName }}</div>
                              </mat-option>
                            </div>
                          </p-overlayPanel>
                          <span
                            class="filter-name text-truncate saved-menu"
                            [ngClass]="{ active: false }"
                            [ngStyle]="{
                              color:
                                i % 2 == 0
                                  ? evenHovered == i
                                    ? evenRowFontHoverColor
                                    : evenRowFontColor
                                  : oddHovered == i
                                  ? oddRowFontHoverColor
                                  : oddRowFontColor,
                              'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                              'text-decoration-line':
                                i % 2 == 0
                                  ? evenRowUnderLine
                                    ? 'underline'
                                    : 'none'
                                  : oddRowUnderLine
                                  ? 'underline'
                                  : 'none',
                              'font-weight':
                                i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                              'font-style':
                                i % 2 == 0
                                  ? evenRowItalic
                                    ? 'italic'
                                    : 'normal'
                                  : oddRowItalic
                                  ? 'italic'
                                  : 'normal',
                              'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                              'text-align': i % 2 == 0 ? evenRowAlignment?.justify : oddRowAlignment?.justify
                            }"
                            >{{ gsiConfigurations?.combineName }}</span
                          >
                          <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
                            <ng-container *ngFor="let btn of dropActionBts; index as ind">
                              <mat-option>
                                <div class="attr-name">{{ btn?.displayName }}</div>
                              </mat-option>
                            </ng-container>
                          </mat-menu>
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                    <tr
                      [ngClass]="{
                        'row-outline': gridLines?.rows?.selected == true
                      }"
                      [ngStyle]="{
                        'border-top-color': gridLines?.rows?.selected ? gridLines?.rows?.borderColor : 'none',
                        'border-top-width': gridLines?.rows?.selected ? gridLines?.rows?.borderThickness + 'px' : '0px',
                        'border-radius': gridLines?.rows?.selected ? gridLines?.rows?.borderRadius + 'px' : '0px'
                      }"
                    >
                      <td
                        class="table-row"
                        (click)="setRowBackGroundSelected(i)"
                        [ngClass]="{
                          'column-outline': gridLines?.column === true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i || advanceTableFeatures.columnHighlights
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i || advanceTableFeatures.columnHighlights
                              ? oddRowHoverColor
                              : oddRowColor
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseout)="setOutHover(i)"
                        *ngIf="advanceTableFeatures?.checkboxSelection == true"
                        style="max-width: 50px; display: flex; justify-content: center;"
                      >
                        <p-tableCheckbox [value]="cols"></p-tableCheckbox>
                      </td>
                      <td *ngIf="isNestedRow1">
                        <button
                          type="button"
                          pButton
                          pRipple
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="isNestedRow ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                        ></button>
                      </td>
                      <td
                        class="table-row"
                        (click)="setRowBackGroundSelected(i)"
                        *ngIf="advanceTableFeatures.serialNumbers == true"
                        [ngClass]="{
                          'column-outline': gridLines?.column === true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i || advanceTableFeatures.columnHighlights
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i || advanceTableFeatures.columnHighlights
                              ? oddRowHoverColor
                              : oddRowColor
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseout)="setOutHover(i)"
                      >
                        {{ i + 1 }}
                      </td>
                      <td
                        *ngFor="let col of cols; let j = index"
                        class="table-row"
                        (click)="setRowBackGroundSelected(i)"
                        [ngClass]="{
                          'column-outline': gridLines?.column?.selected == true
                        }"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                              ? oddRowHoverColor
                              : oddRowColor,
                          'border-right-color': gridLines?.column?.selected ? gridLines?.column?.borderColor : 'none',
                          'border-right-width': gridLines?.column?.selected
                            ? gridLines?.column?.borderThickness + 'px'
                            : '0px',
                          'border-radius': gridLines?.column?.selected ? gridLines?.column?.borderRadius + 'px' : '0px'
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseover)="setHover(i, j)"
                        (mouseout)="setOutHover(i)"
                      >
                        <p
                          class="table-value btn-tooltip w-100"
                          pTooltip="{{ col.attributeValue }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                          [ngStyle]="{
                            color:
                              i % 2 == 0
                                ? evenHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                  ? evenRowFontHoverColor
                                  : evenRowFontColor
                                : oddHovered == i || (j == columnHovered && advanceTableFeatures.columnHighlights)
                                ? oddRowFontHoverColor
                                : oddRowFontColor,
                            'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                            'text-decoration-line':
                              i % 2 == 0
                                ? evenRowUnderLine
                                  ? 'underline'
                                  : 'none'
                                : oddRowUnderLine
                                ? 'underline'
                                : 'none',
                            'font-weight':
                              i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                            'font-style':
                              i % 2 == 0 ? (evenRowItalic ? 'italic' : 'normal') : oddRowItalic ? 'italic' : 'normal',
                            'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                            'text-align': i % 2 == 0 ? evenRowAlignment?.justify : oddRowAlignment?.justify
                          }"
                        >
                          {{ col.attributeValue }} dummy
                        </p>
                      </td>
                      <td
                        class="table-row"
                        *ngIf="inTableGSIButtons"
                        class="table-row"
                        (click)="setRowBackGroundSelected(i)"
                        [ngStyle]="{
                          'background-color':
                            i % 2 == 0
                              ? evenHovered == i
                                ? evenRowHoverColor
                                : evenRowColor
                              : oddHovered == i
                              ? oddRowHoverColor
                              : oddRowColor
                        }"
                        [ngClass]="{
                          'table-evenRow-selected': i % 2 == 0 && evenRowSelected,
                          'table-oddRow-selected': i % 2 == 1 && oddRowSelected
                        }"
                        (mouseover)="setHover(i)"
                        (mouseout)="setOutHover(i)"
                      >
                        <ng-container *ngIf="!isMore" class="table-value btn-tooltip">
                          <div class="gsibuttons" *ngIf="dropActionBts?.length > 0">
                            <button
                              *ngFor="let btn of dropActionBts; index as ind"
                              (click)="$event.stopPropagation(); gsiBtnSelected[ind] = !gsiBtnSelected?.[ind]"
                              (mouseover)="gsiBtnHovered[ind] = true"
                              (mouseout)="gsiBtnHovered[ind] = false"
                              [ngClass]="{ 'table-button-selected': gsiBtnSelected?.[ind] }"
                              [ngStyle]="{
                                'background-color': gsiBtnHovered?.[ind] && ( i % 2 == 0
                              ? evenHovered == i :oddHovered == i ) ? gsiBtnBackgroundHoverColor?.[ind] : gsiBtnBackgroundColor?.[ind],
                                color: gsiBtnHovered?.[ind] && ( i % 2 == 0
                              ? evenHovered == i :oddHovered == i )? gsiBtnFontHoverColor?.[ind] : gsiBtnFontColor?.[ind],
                                'font-family': gsiBtnFontFamily?.font,
                                'text-decoration-line': gsiBtnUnderline ? 'underline' : 'none',
                                'font-weight': gsiBtnBold ? 'bold' : 'normal',
                                'font-style': gsiBtnItalic ? 'italic' : 'normal',
                                'font-size': gsiBtnFontSize + 'px',
                                'text-align': gsiBtnAlignment?.justify
                              }"
                            >
                              {{ btn?.displayName }}
                            </button>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="isMore">
                          <div
                            class="tbl-accordion cursor-pointer"
                            *ngIf="dropActionBts?.length > 0"
                            (click)="more.toggle($event)"
                          >
                            <p-overlayPanel styleClass="filterOverlay" #more>
                              <span class="search-filter">
                                <input type="text" placeholder="Search" />
                                <em class="nh-icon nh-icon-search-line"></em>
                                <em class="nh-icon nh-icon-x-lg d-none"></em>
                              </span>
                              <div class="checckbox" *ngFor="let btn of dropActionBts; index as ind">
                                <mat-option>
                                  <div class="attr-name">{{ btn?.displayName }}</div>
                                </mat-option>
                              </div>
                            </p-overlayPanel>
                            <span
                              class="filter-name text-truncate saved-menu"
                              [ngClass]="{ active: false }"
                              [ngStyle]="{
                                color:
                                  i % 2 == 0
                                    ? evenHovered == i
                                      ? evenRowFontHoverColor
                                      : evenRowFontColor
                                    : oddHovered == i
                                    ? oddRowFontHoverColor
                                    : oddRowFontColor,
                                'font-family': i % 2 == 0 ? evenRowFontFamily?.font : oddRowFontFamily?.font,
                                'text-decoration-line':
                                  i % 2 == 0
                                    ? evenRowUnderLine
                                      ? 'underline'
                                      : 'none'
                                    : oddRowUnderLine
                                    ? 'underline'
                                    : 'none',
                                'font-weight':
                                  i % 2 == 0 ? (evenRowBold ? 'bold' : 'normal') : oddRowBold ? 'bold' : 'normal',
                                'font-style':
                                  i % 2 == 0
                                    ? evenRowItalic
                                      ? 'italic'
                                      : 'normal'
                                    : oddRowItalic
                                    ? 'italic'
                                    : 'normal',
                                'font-size': i % 2 == 0 ? evenRowFontSize + 'px' : oddRowFontSize + 'px',
                                'text-align': i % 2 == 0 ? evenRowAlignment?.justify : oddRowAlignment?.justify
                              }"
                              >{{ gsiConfigurations?.combineName }}</span
                            >
                            <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
                              <ng-container *ngFor="let btn of dropActionBts; index as ind">
                                <mat-option>
                                  <div class="attr-name">{{ btn?.displayName }}</div>
                                </mat-option>
                              </ng-container>
                            </mat-menu>
                          </div>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                <div
                  id="footerDescription"
                  class="table-description"
                  *ngIf="footerDescription && toggleFooterChecked"
                  [ngClass]="footerDescriptionBackgroundSelected ? 'table-header-selected' : ''"
                  (click)="setFooterDescriptionBackgroundSelected()"
                  (mouseover)="footerHovered = true"
                  (mouseout)="footerHovered = false"
                  [ngStyle]="{
                    'background-color': footerHovered ? footerDescriptionHoverColor : footerDescriptionBackground,
                    color: footerHovered ? footerDescriptionFontHoverColor : footerDescriptionFontColor
                  }"
                >
                  {{ footerDescription }}
                </div>
                <div class="table-pagination" (click)="paginationConfiguration()" *ngIf="showPagination" [ngClass]="{'selected': activePagination }">
                  <app-entity-grid-card-paginator [paginationStyle]="paginationStyle" [length]="5" [pageSize]="10" [paginationJustify]="paginatedAlignment">
                  </app-entity-grid-card-paginator>
                </div>
              </div>
            </div>
          </div>

          <div
            class="condition-box d-flex"
            *ngIf="enableLegend"
            [ngStyle]="{
              'justify-content': legendAlignment?.justify
            }"
          >
              <div class="d-flex nh-fs-14 justify-content-between">
                <div class="d-flex nh-column-gap-5 condition-attr">
                  <p
                    class="text-truncate"
                    [ngStyle]="{ color: this.selectedConditionTextColor, 'background-color': this.conditionColor }"
                    tooltipPosition="top"
                  >
                    {{ this.conditionAttribute }}
                  </p>
                  <p>
                    {{ this.selectedConditonValue }}
                  </p>
                </div>
              </div>
          </div>

          <app-drop-advanced-features
            *ngIf="isAdvancedFeature"
            [entityData]="entityData"
            [dropAdvancedFeature]="dropAdvancedFeature"
            [_childGsiList]="dropActionBts"
            [editFilterDesign]="filterDesign"
            [_selectedEntityData]="selectedEntityData"
            [selectedRoleId]="selectedRoleId"
            [combinationArray]="combinationArray"          
            [urlBindingGsi]="urlBindingGsi"
            [urlBindingAttribute]="urlBindingAttribute"
            [_dropMappedAttributes]="dropAttributes"
            (combinationArrayEmit)="combinationArrayEmit($event)"
            (hiddenRuleSetEmit)="hiddenRuleSetEmit($event)"
            (selectedEntityDataEmit)="selectedEntityDataEmit($event)"
            (urlBindingEmit)="urlBindingEmit($event)"
            (advancedFeaturesEmit)="advancedFeaturesEmit($event)"
          ></app-drop-advanced-features>
        </div>

        <div class="nhrow_col nhrow_right" *ngIf="isCardConfig">
          <div class="et-config-content-right">
            <mat-tab-group class="form-tabs form-tabs-opt1">
              <mat-tab label="{{ labels?.Configure }}">
                <div class="editfields-form nh-mb-16" *ngIf="isSlectedRole">
                  <label>{{ labels?.Roles }}</label>
                  <div class="editfields-info">
                    <h4 pTooltip="{{ selectedRole }}" tooltipPosition="top" tooltipStyleClass="transcustomtolltipbook">
                      {{ selectedRole }}
                    </h4>
                    <button class="btn btnedit">
                      <em class="nh-icon nh-icon-trash01" (click)="backtoRolePage()"></em>
                      <!-- <img
                        [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                        alt=""
                        (click)="backtoRolePage()"
                      /> -->
                    </button>
                  </div>
                </div>
                <div class="editfields-form nh-mb-16" *ngIf="isSlectedEntity">
                  <label>{{ labels?.ENTITY }}</label>
                  <div class="editfields-info">
                    <h4
                      pTooltip="{{ selectedEntity }}"
                      tooltipPosition="top"
                      tooltipStyleClass="transcustomtolltipbook"
                    >
                      {{ selectedEntity }}
                    </h4>
                    <button class="btn btnedit">
                      <em class="nh-icon nh-icon-trash01" (click)="backtoEntityPage()"></em>
                      <!-- <img
                        [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                        alt=""
                        (click)="backtoEntityPage()"
                      /> -->
                    </button>
                  </div>
                </div>
                <div class="editfields-form nh-mb-16" *ngIf="selectedGsi.length > 0">
                  <label>{{ labels?.Solution }}</label>
                  <div class="editfields-info">
                    <h4
                      pTooltip="{{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}  {{
                        selectedGsi[2] ? ',' + selectedGsi[2] : ''
                      }} "
                      tooltipPosition="top"
                      tooltipStyleClass="transcustomtolltipbook"
                    >
                      {{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}
                      {{ selectedGsi[2] ? ',' + selectedGsi[2] : '' }}
                    </h4>
                    <button class="btn btnedit">
                      <em class="nh-icon nh-icon-trash01" *ngIf="!isEdit" (click)="backtoGsiPage()"></em>
                      <em class="nh-icon nh-icon-edit02" *ngIf="isEdit" (click)="backtoGsiPage()"></em>
                      <!-- <img
                        [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                        alt=""
                        (click)="backtoGsiPage()"
                      /> -->
                    </button>
                  </div>
                </div>
                <!-- <div class="dcd-btn-container">
                  <button class="btn" (click)="callConnectors()">Add Connectors</button>
                </div> -->
              </mat-tab>
              <mat-tab label="Design">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Color
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="color-customisation">
                      <ul>
                        <li>
                          <p>Fill Color</p>
                          <p class="box-color">
                            <span
                              class="color-fill"
                              [ngStyle]="{ 'background-color': backgroundColor ? backgroundColor : 'white' }"
                            ></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('background')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Border Color</p>
                          <p class="box-color">
                            <span
                              class="color-fill"
                              [ngStyle]="{ 'background-color': borderColor ? borderColor : 'white' }"
                            ></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('border')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Border Hover Color</p>
                          <p class="box-color">
                            <span
                              class="color-fill"
                              [ngStyle]="{ 'background-color': borderHoverColor ? borderHoverColor : 'white' }"
                            ></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('borderHover')"></i>
                          </p>
                        </li>
                        <li>
                          <p>Text Color</p>
                          <p class="box-color">
                            <span
                              class="color-fill"
                              [ngStyle]="{ 'background-color': fontColor ? fontColor : 'white' }"
                            ></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('font')"></i>
                          </p>
                        </li>
                        <li *ngIf="!isAttributeLevelCustomizationActive">
                          <p>Text Hover Color</p>
                          <p class="box-color">
                            <span
                              class="color-fill"
                              [ngStyle]="{ 'background-color': textHoverColor ? textHoverColor : 'white' }"
                            ></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('textHover')"></i>
                          </p>
                        </li>
                        <li *ngIf="!isAttributeLevelCustomizationActive">
                          <p>Hover Color</p>
                          <p class="box-color">
                            <span
                              class="color-fill"
                              [ngStyle]="{ 'background-color': backgroundHoverColor ? backgroundHoverColor : 'white' }"
                            ></span>
                            <i class="bi bi-eyedropper" (click)="toggleColorPickerTable('backgroundHover')"></i>
                          </p>
                        </li>
                      </ul>
                      <span
                        *ngIf="colorPickerToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        (colorPickerChange)="changeColor($event)"
                        [(colorPicker)]="colorModel"
                        [cpOKButton]="true"
                        (colorPickerSelect)="closeColorPicker($event)"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Gridlines
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="grid-customisation">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.outLine.selected"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/outline.svg" />
                              <p>OutLine</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <div *ngIf="gridLines.outLine.selected">
                          <ng-template
                            [ngTemplateOutlet]="borderStyle"
                            [ngTemplateOutletContext]="{ data: { label: 'outLine' } }"
                          >
                          </ng-template>
                        </div>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.rows.selected"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/rows.svg" />
                              <p>Rows</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <div *ngIf="gridLines.rows.selected">
                          <ng-template
                            [ngTemplateOutlet]="borderStyle"
                            [ngTemplateOutletContext]="{ data: { label: 'rows' } }"
                          >
                          </ng-template>
                        </div>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="gridLines.column.selected"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <img src="../../../../../assets/img/general-entity/Advance-features/column.svg" />
                              <p>Columns</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <div *ngIf="gridLines.column.selected">
                          <ng-template
                            [ngTemplateOutlet]="borderStyle"
                            [ngTemplateOutletContext]="{ data: { label: 'column' } }"
                          >
                          </ng-template>
                        </div>
                        <li class="row-sizes flex-column align-items-start">
                          <p class="title text-color">Row Size</p>
                          <p-selectButton
                            [options]="sizes"
                            [(ngModel)]="gridLines.selectedStyle"
                            optionLabel="name"
                          ></p-selectButton>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Gsi Button Configuration
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="gsi-configuration">
                      <div class="gsi-sec">
                        <ul>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'onTop'; isMore = false; inTableGSIButtons = false
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'onTop' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-ontop"></em>
                            </button>
                            <p>On Top</p>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'inline'; isMore = false; inTableGSIButtons = true
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'inline' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-inline"></em>
                            </button>
                            <p>Inline</p>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'combine'; isMore = true; inTableGSIButtons = false
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'combine' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-combine"></em>
                            </button>
                            <p>Combine</p>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-gsi"
                              (click)="
                                gsiConfigurations.gsiPosition = 'combineInline'; isMore = true; inTableGSIButtons = true
                              "
                              [ngClass]="{ active: gsiConfigurations.gsiPosition == 'combineInline' }"
                            >
                              <em class="nh-icon nh-icon-gsi-button-combine-inline"></em>
                            </button>
                            <p>Combine Inline</p>
                          </li>
                        </ul>
                        <div class="font-section nh-mt-8">
                          <p class="title text-color nh-mb-12">Border Radius</p>
                          <div class="font-input">
                            <button class="font-btn" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                              <i class="bi bi-dash"></i>
                            </button>
                            <input type="number" [(ngModel)]="gsiBtnborderRadius" min="0" />
                            <button class="font-btn" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                              <i class="bi bi-plus"></i>
                            </button>
                          </div>
                          <p class="title text-color nh-mt-12 nh-mb-12">Border width</p>
                          <div class="font-input">
                            <button class="font-btn" onclick="this.parentNode.querySelector('input[type=number]').stepDown()">
                              <i class="bi bi-dash"></i>
                            </button>
                            <input type="number" [(ngModel)]="gsiBtnborderWidth" min="0" />
                            <button class="font-btn" onclick="this.parentNode.querySelector('input[type=number]').stepUp()">
                              <i class="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>           
                      </div>
                      <div
                        class="input-section"
                        *ngIf="
                          gsiConfigurations.gsiPosition == 'combineInline' || gsiConfigurations.gsiPosition == 'inline'
                        "
                      >
                        <p class="title text-color">Column Title</p>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Actions"
                          [(ngModel)]="gsiConfigurations.gsiInlineTitle"
                        />
                      </div>
                      <div
                        class="input-section"
                        *ngIf="
                          (gsiConfigurations.gsiPosition == 'combine' || gsiConfigurations.gsiPosition == 'combineInline')
                        "
                      >
                        <p class="title text-color">Combination Name</p>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Actions"
                          [(ngModel)]="gsiConfigurations.combineName"
                        />
                      </div>
                      <div class="add-icon"
                      *ngIf="gsiConfigurations.gsiPosition == 'combine' || gsiConfigurations.gsiPosition == 'combineInline'">
                        <mat-slide-toggle
                        [(ngModel)]="gsiConfigurations.isIconOnlyEnabled"
                        disableRipple
                        class="er-filter-toggle er-filter-toggle-a"
                      >
                        <span>
                          <p class="title">Icon Only</p>
                        </span>
                      </mat-slide-toggle>
                      <ng-container *ngIf="gsiConfigurations.isIconOnlyEnabled">
                        <div class="upload-section">
                          <ul>
                            <li class="items">
                              <article class="box">
                                <div class="box-left">
                                  <p class="box-title text-color">Default Icon upload</p>
                                  <small class="text-color">file format: png, svg</small>
                                  <div class="btn-upload" (change)="uploadIcon($event,true)">
                                    <input type="file" placeholder="Attachment" />
                                    <button class="btn-acc btn-dark" type="button">
                                      Upload
                                    </button>
                                  </div>
                                </div>
                                <div class="box-right">
                                  <div class="close-icon">
                                    <em class="nh-icon nh-icon-x-circle" (click)="removeIcon()"></em>
                                  </div>
                                  <div class="box-icon">
                                    <img
                                    [src]="gsiConfigurations.iconLink.length > 0 ? gsiConfigurations.iconLink : defaultGsiImages[selectGsiIcon]"
                                      alt="calender"
                                    />
                                  </div>
                                </div>
                              </article>
                            </li>
                          </ul>
                        </div>

                      </ng-container>
                      </div>
                      <div class="add-icon" *ngIf="isSelectGsi">
                        <p class="nh-mt-16">configure {{ gsiForConfigure }} :</p>
                        <mat-slide-toggle
                          [(ngModel)]="gsiNameWithIcon"
                          [disabled]="gsiOnlyIcon"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a nh-mt-16 nh-mb-16"
                        >
                          <span>
                            <p class="title">Icon with GSI Name</p>
                          </span>
                        </mat-slide-toggle>
                        <mat-slide-toggle
                          [(ngModel)]="gsiOnlyIcon"
                          [disabled]="gsiNameWithIcon"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a"
                        >
                          <span>
                            <p class="title">Only Icon</p>
                          </span>
                        </mat-slide-toggle>

                        <div class="icon-section">
                          <ul class="nh-mt-24">
                            <li>
                              <button class="btn btn-icon" (click)="selectGsiIcon = 'cart'; imgSrc = '../../../../../assets/img/general-entity/Advance-features/cart.svg'">
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/cart.svg"
                                  alt="cart"
                                />
                              </button>
                            </li>
                            <li>
                              <button class="btn btn-icon" (click)="selectGsiIcon = 'heart'; imgSrc = '../../../../../assets/img/general-entity/Advance-features/heart.svg'">
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/heart.svg"
                                  alt="heart"
                                />
                              </button>
                            </li>
                            <li>
                              <button class="btn btn-icon" (click)="selectGsiIcon = 'bookmark'; imgSrc = '../../../../../assets/img/general-entity/Advance-features/bookmark.svg'">
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/bookmark.svg"
                                  alt="bookmark"
                                />
                              </button>
                            </li>
                            <li>
                              <button class="btn btn-icon" (click)="selectGsiIcon = 'payment'; imgSrc = '../../../../../assets/img/general-entity/Advance-features/payment.svg'">
                                <img
                                  src="../../../../../assets/img/general-entity/Advance-features/payment.svg"
                                  alt="payment"
                                />
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div class="upload-section">
                          <ul>
                            <li class="items">
                              <article class="box">
                                <div class="box-left">
                                  <p class="box-title text-color">Default Icon upload</p>
                                  <small class="text-color">file format: png, svg</small>
                                  <div class="btn-upload" (change)="uploadIcon($event)">
                                    <input type="file" placeholder="Attachment" />
                                    <button class="btn-acc btn-dark" type="button">
                                      Upload
                                    </button>
                                  </div>
                                </div>
                                <div class="box-right">
                                  <div class="close-icon">
                                    <em class="nh-icon nh-icon-x-circle"></em>
                                  </div>
                                  <div class="box-icon">
                                    <img
                                      [src]="imgSrc.length > 0 ? imgSrc : defaultGsiImages[selectGsiIcon]"
                                      alt="calender"
                                    />
                                  </div>
                                </div>
                              </article>
                            </li>
                          </ul>
                        </div>
                        <button class="btn-acc btn-dark nh-mt-20 ml-auto" (click)="saveGsiConfigurations()">
                          Save
                        </button>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Text
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="test">
                      <div class="seperator" *ngIf="isAttributeLevelCustomizationActive">
                        Seperator:
                        <input
                          type="text"
                          [(ngModel)]="columnStyles[selectedColumn].seperators[selectedColumnAttribute]"
                        />
                      </div>
                      <p class="title text-color">Font Family</p>
                      <div class="entity-custom-select">
                        <mat-form-field appearance="fill" floatLabel="never">
                          <mat-select
                            (ngModelChange)="selectedeFontChange($event)"
                            [(ngModel)]="selectedFont"
                            placeholder="Inter"
                            panelClass="entity-custom-select-options"
                          >
                            <mat-option *ngFor="let font of fonts" [value]="font">{{ font.name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <p class="title text-color">Font Style</p>
                      <div class="entity-custom-select"></div>
                      <div class="font-style">
                        <ul>
                          <li (click)="checkIsbold()" [ngClass]="{ active: isBold }">
                            <span>B</span>
                          </li>
                          <li (click)="checkIsItalic()" [ngClass]="{ active: isItalic }">
                            <span>i</span>
                          </li>
                          <li *ngIf="!isAttributeLevelCustomizationActive"(click)="checkIsUnderLine()" [ngClass]="{ active: isUnderLine }">
                            <span>U</span>
                          </li>
                        </ul>
                      </div>
                      <div class="alignment">
                        <p class="title text-color nh-mb-0">Alignment</p>
                        <ul>
                          <li
                            *ngFor="let align of alignmentOptions"
                            (click)="checkAlign(align)"
                            [ngClass]="{ active: alignment == align }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                      <div class="font-section nh-mt-8">
                        <p class="title text-color">Font Size</p>
                        <div class="font-input">
                          <button class="font-btn">
                            <i class="bi bi-dash" (click)="decreaseFontSize()"></i>
                          </button>
                          <span>{{ fontSize }}</span>
                          <button class="font-btn">
                            <i class="bi bi-plus" (click)="increaseFontSize()"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Header Freeze
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="header-freeze">
                      <div class="header-btn">
                        <mat-slide-toggle
                          [(ngModel)]="tableFreezeToggele"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a"
                        >
                          <span>
                            <p>Enable</p>
                          </span>
                        </mat-slide-toggle>
                        <div class="input-section">
                          <p class="title text-color">Table Height in %</p>
                          <input type="number" min="1" step="1" max="100"   class="form-control" placeholder="" [(ngModel)]="tableHeight" />
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Pagination
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="pagination-sec">
                      <ul>
                        <li class="nh-mb-12">
                          <mat-slide-toggle
                            [(ngModel)]="showPagination"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Enable</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                      <div class="font-section">
                        <p class="title text-color">Rows Per Page</p>
                        <div class="font-input">
                          <button class="btn-acc font-btn">
                            <i (click)="decreasePaginateSize()" class="bi bi-dash"></i>
                          </button>
                          <span>{{ paginationRowSize }}</span>
                          <button class="btn-acc font-btn">
                            <i (click)="increasePaginateSize()" class="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div class="alignment">
                        <p class="title text-color">Alignment</p>
                        <ul>
                          <li
                            *ngFor="let align of alignmentOptions"
                            (click)="checkPaginatedAlign(align)"
                            [ngClass]="{ active: paginatedAlignment == align }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Entity Description
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="entity-description">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="toggleHeaderChecked"
                            class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                          >
                            <span>
                              Table Header Description
                            </span>
                          </mat-slide-toggle>
                          <textarea
                            id="desc"
                            name="desc"
                            maxlength="100"
                            class="text-field"
                            [(ngModel)]="headerTempDescription"
                            *ngIf="toggleHeaderChecked"
                          >
                            {{ headerTempDescription }}
                            </textarea
                          >

                          <div class="count-sec" *ngIf="toggleHeaderChecked">
                            <button class="btn-acc btn-dark" (click)="saveHeaderDescription(headerTempDescription)">
                              Save
                            </button>
                          </div>
                        </li>
                        <li class="nh-mb-12">
                          <mat-slide-toggle
                            [(ngModel)]="toggleFooterChecked"
                            class="er-filter-toggle er-filter-toggle-a label-color nh-mb-12"
                          >
                            <span>
                              Table Footer Description
                            </span>
                          </mat-slide-toggle>
                          <textarea
                            id="desc"
                            name="desc"
                            maxlength="100"
                            class="text-field"
                            [(ngModel)]="footerTempDescription"
                            *ngIf="toggleFooterChecked"
                          >
                            {{ footerTempDescription }}
                            </textarea
                          >

                          <div class="count-sec" *ngIf="toggleFooterChecked">
                            <button class="btn-acc btn-dark" (click)="saveFooterDescription(footerTempDescription)">
                              Save
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Advance Features
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.multipleSelection"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Multiple Selection</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.columnHighlights"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Column Highlights</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.headerNoWrap"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Header Text Nowrap</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.bodyNoWrap"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Body Text Nowrap</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.serialNumbers"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Serial Numbers</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                        <li>
                          <mat-slide-toggle
                            [(ngModel)]="advanceTableFeatures.checkboxSelection"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>{{ labels?.Checkbox_Selection || 'Checkbox Selection' }}</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Nested Row
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            name="expaned_Row"
                            [(ngModel)]="isNestedRow1"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>expand the row</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="isAttributeLevelCustomizationActive">
                    <mat-expansion-panel-header>
                      Attribute Style
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <div class="font-section">
                        <p class="title text-color">Size Of Attribute</p>
                        <div class="font-input">
                          <button class="btn-acc font-btn">
                            <i (click)="decreaseAttributeSize()" class="bi bi-dash"></i>
                          </button>
                          <span>{{ columnStyles[selectedColumn].sizes[selectedColumnAttribute] }}</span>
                          <button class="btn-acc font-btn">
                            <i (click)="increaseAttributeSize()" class="bi bi-plus"></i>
                          </button>
                        </div>
                        <mat-slide-toggle
                          [(ngModel)]="columnStyles[selectedColumn].isChip[selectedColumnAttribute]"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a"
                        >
                          <span>
                            <p>Enable Chip Mode</p>
                          </span>
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Advanced Settings
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="advance-features">
                      <ul>
                        <li>
                          <mat-slide-toggle
                            name="expaned_Row"
                            [(ngModel)]="enableAnalytics"
                            disableRipple
                            class="er-filter-toggle er-filter-toggle-a"
                          >
                            <span>
                              <p>Enable Analytics</p>
                            </span>
                          </mat-slide-toggle>
                        </li>
                      </ul>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>
              <mat-tab label="Condition">
                <div class="condition-wrapper">
                  <div
                    class="add-condition d-flex justify-content-center align-items-center cursor-pointer"
                    (click)="addCondition()"
                  >
                    <span><i class="bi bi-plus-lg"></i></span>
                    <span class="text">{{ labels?.Add_Condition }}</span>
                  </div>
                  <div class="condition-box d-flex">
                    <ng-container *ngFor="let condition of conditionArray; let index = index">
                      <div class="d-flex nh-fs-14 justify-content-between">
                        <div class="d-flex nh-column-gap-5 condition-attr">
                          <p class="text-truncate" pTooltip="{{ condition.conditionAttribute }}" tooltipPosition="top">
                            {{ condition.conditionAttribute }}
                          </p>
                          <p>{{ condition.condition }}</p>
                          <p
                            class="text-truncate"
                            [ngStyle]="{ color: condition.textColor, 'background-color': condition.fillColor }"
                            pTooltip="{{ condition.selectedConditonValue }}"
                            tooltipPosition="top"
                          >
                            {{ condition.selectedConditonValue }}
                          </p>
                        </div>
                        <div>
                          <i
                            class="bi bi-trash3 nh-fs-14 cursor-pointer"
                            (click)="deleteSelectedCondition(index, 'child')"
                          ></i>
                          <i
                            class="bi bi-pencil nh-fs-14 cursor-pointer"
                            (click)="editSelectedCondition(index, condition, 'child')"
                          ></i>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="condition-tab" *ngIf="addConditionVariable">
                  <div class="entity-custom-select">
                    <mat-form-field appearance="fill" floatLabel="never">
                      <mat-select
                        [(ngModel)]="conditionAttribute"
                        placeholder="Select Attributes"
                        panelClass="entity-custom-select-options entity-multi-select"
                        multiple
                      >
                        <mat-option *ngFor="let attr of conditionAttributes" [value]="attr.name">{{ attr.name }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="operator-list">
                    <ul (click)="addConditionOperator()">
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          [ngClass]="{ active: selectedCondition === '==' }"
                          (click)="setSelectedCondition('==')"
                        >
                          <span>==</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          [ngClass]="{ active: selectedCondition === '!=' }"
                          (click)="setSelectedCondition('!=')"
                        >
                          <span>!=</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('>')"
                          [ngClass]="{ active: selectedCondition === '>' }"
                        >
                          <span>></span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('\<')"
                          [ngClass]="{ active: selectedCondition === '\<' }"
                        >
                          <span><</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('>=')"
                          [ngClass]="{ active: selectedCondition === '>=' }"
                        >
                          <span>>=</span>
                        </button>
                      </li>
                      <li>
                        <button
                          class="btn-acc btn-icon"
                          (click)="setSelectedCondition('\<=')"
                          [ngClass]="{ active: selectedCondition === '\<=' }"
                        >
                          <span><=</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="input-section">
                    <p class="title text-color">Value</p>
                    <input type="text" class="form-control" placeholder="" [(ngModel)]="selectedConditonValue" />
                  </div>
                  <div class="color-customisation">
                    <ul>
                      <li (click)="ChangeConditionColorToggle()">
                        <p>{{ labels?.Fill_Color || 'Fill Color' }}</p>
                        <p class="box-color">
                          <span class="color-fill" [ngStyle]="{ 'background-color': conditionColor }"></span
                          ><i class="bi bi-eyedropper"></i>
                        </p>
                      </li>
                      <span
                        *ngIf="conditioncColorToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        [(colorPicker)]="conditionColor"
                        [cpOKButton]="true"
                        (colorPickerSelect)="ChangeConditionColorToggle()"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                      <li (click)="conditionTextColorToggler = !conditionTextColorToggler">
                        <p>{{ labels?.Text_Color || 'Text Color' }}</p>
                        <p class="box-color">
                          <span class="color-fill" [ngStyle]="{ 'background-color': selectedConditionTextColor }"></span
                          ><i class="bi bi-eyedropper"></i>
                        </p>
                      </li>
                      <span
                        *ngIf="conditionTextColorToggler"
                        [cpPositionRelativeToArrow]="true"
                        [cpDialogDisplay]="'inline'"
                        [(colorPicker)]="selectedConditionTextColor"
                        [cpOKButton]="true"
                        (colorPickerSelect)="conditionTextColorToggler = !conditionTextColorToggler"
                        [cpToggle]="true"
                        [cpOKButtonText]="'Done'"
                      >
                      </span>
                      <li>
                        <mat-checkbox
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a"
                          [(ngModel)]="enableCompleteRecord"
                        >
                          <span>
                            <p>{{ labels?.Apply_At_Complete_Record || 'Apply At Complete Record' }}</p>
                          </span>
                        </mat-checkbox>
                      </li>
                      <li *ngIf="this.selectedCondition === '=='">
                        <mat-checkbox
                          (click)="setLegendSelected()"
                          [(ngModel)]="enableLegend"
                          disableRipple
                          class="er-filter-toggle er-filter-toggle-a"
                        >
                          <span>
                            <p>{{ labels?.Enable_Legend || 'Enable Legend' }}</p>
                          </span>
                        </mat-checkbox>
                      </li>
                      <div class="alignment" *ngIf="enableLegend">
                        <p class="title text-color nh-mb-0">Position</p>
                        <ul>
                          <li
                            *ngFor="let align of legendAlignmentOptions"
                            (click)="checkAlign(align)"
                            [ngClass]="{ active: alignment?.justify === align?.justify }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  </div>
                  <button class="btn-acc btn-dark create-btn" (click)="createCondition()">
                    Create
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <div class="entity-right entity-right-config d-none" *ngIf="!isGrammer">
            <div class="entity-editfields">
              <div class="entitytab-one entitytab-fields">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                  <mat-tab label="Configure">
                    <div class="editfields-form" *ngIf="isSlectedRole">
                      <label>ROLES</label>
                      <div class="editfields-info">
                        <h4
                          pTooltip="{{ selectedRole }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{ selectedRole }}
                        </h4>
                        <button class="btn btnedit">
                          <img
                            [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                            (click)="backtoRolePage()"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="editfields-form" *ngIf="isSlectedEntity">
                      <label>ENTITY</label>
                      <div class="editfields-info">
                        <h4
                          pTooltip="{{ selectedEntity }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{ selectedEntity }}
                        </h4>
                        <button class="btn btnedit">
                          <img
                            [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                            (click)="backtoEntityPage()"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="editfields-form" *ngIf="isSlectedGsi">
                      <label>Solution</label>
                      <div class="editfields-info">
                        <h4
                          pTooltip="{{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }} {{
                            selectedGsi[2] ? ',' + selectedGsi[2] : ''
                          }}"
                          tooltipPosition="top"
                          tooltipStyleClass="transcustomtolltipbook"
                        >
                          {{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}
                          {{ selectedGsi[2] ? ',' + selectedGsi[2] : '' }}
                        </h4>
                        <button class="btn btnedit">
                          <img
                            [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                            alt=""
                            (click)="backtoGsiPage()"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="condition">
                      <span><i class="bi bi-plus-lg"></i></span>
                      <span class="text">Add More CTA Button</span>
                    </div>
                  </mat-tab>
                  <mat-tab label="Design" *ngIf="!isAdvancedFeature">
                    <div class="entitytab-two entitytab-fields">
                      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight>
                        <mat-tab label="Color">
                          <div class="color-picker1">
                            <div class="choose-color" *ngIf="tabSelected === 'child' || !applySameToExpand">
                              <div
                                class="color-picker-me btn btn-primary"
                                *ngIf="!colorPickerToggler"
                                (click)="toggleColorPicker()"
                              >
                                Choose Color
                              </div>
                              <span
                                *ngIf="colorPickerToggler"
                                [cpPositionRelativeToArrow]="true"
                                [cpDialogDisplay]="'inline'"
                                (colorPickerChange)="changeColor($event)"
                                [(colorPicker)]="colorModel"
                                [cpOKButton]="true"
                                (colorPickerSelect)="closeColorPicker($event)"
                                [cpToggle]="true"
                                [cpOKButtonText]="'Done'"
                              >
                              </span>
                            </div>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #borderStyle let-data="data">
  <div class="color-customisation">
    <ul>
      <li>
        <p>Border Color</p>
        <p class="box-color">
          <span class="color-fill" [ngStyle]="{ 'background-color': gridLines[data?.label].borderColor }"></span>
          <i class="bi bi-eyedropper" (click)="borderColorpickerToggler(data.label)"></i>
        </p>
      </li>
    </ul>
    <div class="font-section">
      <p class="title text-color">Border Thickness</p>
      <div class="font-input">
        <button class="btn-acc font-btn">
          <i (click)="decreaseBorderThickness(data.label)" class="bi bi-dash"></i>
        </button>
        <span>{{ gridLines[data.label]?.borderThickness }}</span>
        <button class="btn-acc font-btn">
          <i (click)="increaseBorderThickness(data.label)" class="bi bi-plus"></i>
        </button>
      </div>
      <div class="font-section">
        <p class="title text-color">Border Radius</p>
        <div class="font-input">
          <button class="btn-acc font-btn">
            <i (click)="decreaseBorderRadius(data?.label)" class="bi bi-dash"></i>
          </button>
          <span>{{ gridLines[data?.label]?.borderRadius }}</span>
          <button class="btn-acc font-btn">
            <i (click)="increaseBorderRadius(data?.label)" class="bi bi-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <span
    *ngIf="borderColorPicker[data?.label]"
    [cpPositionRelativeToArrow]="true"
    [cpDialogDisplay]="'inline'"
    (colorPickerChange)="choseBorderColor($event, data.label)"
    borderThickness
    [(colorPicker)]="colorModel"
    [cpOKButton]="true"
    (colorPickerSelect)="borderColorpickerToggler(data.label)"
    [cpToggle]="true"
    [cpOKButtonText]="'Done'"
  >
  </span>
</ng-template>
<ng-template #cardNameDialog>
  <div class="title">{{ labels?.Enter_Table_Name }}</div>
  <div class="content">
    <p class="name">{{ labels?.Enter_Name }}</p>
    <input placeholder="Write Filter Name" type="text" name="name" [(ngModel)]="cardConfigName" />
  </div>
  <div class="btns">
    <button (click)="closeDialog()">{{ labels?.Cancel }}</button>
    <button (click)="closeCardNameDialog()">{{ labels?.Save }}</button>
  </div>
</ng-template>
<app-loader></app-loader>
<app-toaster></app-toaster>
