import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertService, AttributeFacadeService, EntityFacadeService, NodeGsiFacadeService, getAttributeObj
} from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-nsl-measures',
  templateUrl: './nsl-measures.component.html',
  styleUrls: ['./nsl-measures.component.scss'],
})
export class NslMeasuresComponent implements OnInit {
  ngUnsubscribe = new Subject();
  attributes_Arr: any = [];
  conditions: any = [];
  operators: any = [];
  createNewEntity: any;
  openUiElementType: boolean = false;
  searchUiElement: any;
  allUiElements: any;
  openUiElementIndex: any;
  tempGsiObj: any;
  activeCuIndex: any;
  currentCuData: any;
  vantagePoint: any;
  selectedLayer: any;
  slotIndex: number;
  attributeIndex: number;
  selectedAttribute: any;
  currentEntity: any;
  contextualId: any;
  existingFileUrl: any = '';
  fileType: any;
  measureEntity: any;
  measures: any = {};
  enableConditions: boolean = false;
  currentAttributeindex: any;
  currentConditionindex: any;
  defaultUiElement: any;
  operator: any;
  operatorsDropdown: any = ['<', '>', '=', '>=', '<='];
  isnode: any;
  isEntity: boolean = false;
  saveFlag:boolean = false;

  // isattributeFile: boolean = false;
  constructor(
    public gsifacade: NodeGsiFacadeService,
    public nodeEntityFacadeService: NodeEntityFacadeService,
    public dialog: MatDialog,
    private attributefacadeservice: AttributeFacadeService,
    public nodeChangeUnitFacadeService: NodeChangeUnitFacadeService, // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    private alertService: AlertService,
    private entityFacadeService: EntityFacadeService
  ) {
    this.getSelectedBetData();
    this.getEntityFromCollections();
    this.detectEntitySave();
    this.fetchEntityData();

    this.defaultUiElement = {
      name: 'Text',
      dataType: 'string',
      uiElement: 'text',
      isMulti: false,
      properties: [],
    };
  }
  ngOnInit(): void {
    /*istanbul ignore else */
    if (this.enableConditions == false) {
      this.attributes_Arr.push({ key: '', uiElement: this.defaultUiElement });
    }
  }
  /*istanbul ignore next */
  getSelectedBetData() {
    this.gsifacade.sendCUDataToMeasures$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.isnode = res.isnode;
      // console.log(res);
      this.tempGsiObj = res.currentGsi;
      this.currentCuData = res.activeBet;
      /*istanbul ignore next */
      if (res?.existingConfig) {
        this.isEntity = true;
        Object.entries(res?.existingConfig).forEach((keyValuePair: any) => {
          keyValuePair[1]?.conditions.forEach((eachCondition: any) => {
            let condition = String(eachCondition?.condition);
            let operator;
            let operatorValue;
            operator = condition.indexOf('==');
            operatorValue = '==';
            /*istanbul ignore else */
            if (operator == -1) {
              operator = condition.indexOf('<=');
              operatorValue = '<=';
            }
            /*istanbul ignore else */
            if (operator == -1) {
              operator = condition.indexOf('>=');
              operatorValue = '>=';
            }
            /*istanbul ignore else */
            if (operator == -1) {
              operator = condition.indexOf('>');
              operatorValue = '>';
            }
            /*istanbul ignore else */
            if (operator == -1) {
              operator = condition.indexOf('<');
              operatorValue = '<';
            }

            this.operators.push({
              operator: operatorValue == '==' ? '=' : operatorValue,
              value: operatorValue.length == 1 ? condition.substring(operator + 1) : condition.substring(operator + 2),
            });
            let value_Map: any = [];
            // eachCondition?.valueMap?.forEach((attribute: any) => {
            Object.keys(eachCondition?.valueMap).forEach((key: any) => {
              let obj = {};
              obj['attributeName'] = key;
              obj['attributeValue'] = eachCondition.valueMap[key];
              value_Map.push(obj);
            });

            // });
            this.conditions.push({
              condition: condition,
              valueMap: value_Map,
            });
          });
          this.measureEntity = keyValuePair[1]?.measureEntity;

          this.enableConditions = true;
        });
      }
      /* istanbul ignore next */
      this.activeCuIndex = this.tempGsiObj?.solutionLogic?.findIndex(
        (cu: any) => cu.referencedChangeUnit === this.currentCuData.referencedChangeUnit
      );
      this.vantagePoint = res.vantagePoint;
      this.currentCuData?.layers?.forEach((layer: any) => {
        layer?.participatingItems?.forEach((entity: any, index: number) => {
          entity?.nslAttributes?.forEach((attribute: any, attIndex: number) => {
            if (attribute?.displayName == this.vantagePoint?.token || attribute?.name == this.vantagePoint?.token) {
              this.selectedLayer = layer;
              this.currentEntity = entity;
              this.selectedAttribute = attribute;
              this.slotIndex = index;
              this.attributeIndex = attIndex;
            }
          });
        });
      });
      let layer;
      if (this.selectedLayer?.type == 'physical') {
        layer = 'PL';
      } else if (this.selectedLayer?.type == 'information') {
        layer = 'IL';
      } else {
        layer = this.selectedLayer?.type;
      }
      // layer = this.selectedLayer?.type == 'physical' ? 'PL' : 'IL';
      this.contextualId =
        layer + '.SL001.' + 'EN' + String(this.currentEntity?.id) + '.' + 'AT' + String(this.selectedAttribute?.id);
      this.measures = this.tempGsiObj?.solutionLogic?.[this.activeCuIndex]?.measures;
    });
    this.entityFacadeService.loadAttributesByEntId(this.measureEntity?.id);
  }

  fetchEntityData() {
    this.entityFacadeService.entityAttributes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.measureEntity = res;
      this.createNewEntity = this.measureEntity?.displayName;
      this.attributes_Arr = [];
      this.measureEntity?.nslAttributes.forEach((nslattribute: any) => {
        this.attributes_Arr.push({
          key: nslattribute?.displayName,
          uiElement: nslattribute?.attributeType?.uiElement,
        });
      });
    });
  }
  /*selecting entity from collections to add  */
  getEntityFromCollections() {
    this.nodeEntityFacadeService.entityAttributes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.createNewEntity = res?.name;
      this.measureEntity = res;
      // let attributes_length=res?.nslAttributes.length;
      this.attributes_Arr = new Array(0);
      res?.nslAttributes?.forEach((attr: any) => {
        this.attributes_Arr.push({ key: attr.name, uiElement: attr.attributeType?.type });
      });
      this.enableConditions = true;
    });
  }

  /* this function will create entity payload and will call save entity service */
  createEntity() {
    this.openUiElementType = false;
    this.searchUiElement = '';
    let createEntityObj: any = {
      isMultiValue: false,
      layerType: '',
      description: '',
      nslAttributes: [],
      name: this.createNewEntity,
    };
    this.attributes_Arr.forEach((attr: any) => {
      let dataType: any = {
        name: attr.uiElement.name,
        dataType: attr.uiElement.dataType,
        esDataType: attr?.uiElement?.esDataType,
        uiElement: attr.uiElement.uiElement,
        isMulti: attr.uiElement.isMulti,
        properties: attr.uiElement.properties,
      };
      let attributeObj = getAttributeObj(attr.key, dataType);
      createEntityObj.nslAttributes.push(attributeObj);
    });
    this.nodeEntityFacadeService.saveEntityForSF(createEntityObj);
  }

  /* after entity save to check weather saved or not */
  detectEntitySave() {
    this.nodeEntityFacadeService.sendEntityResponseToSf$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (res: any) => {
        /*istanbul ignore else */
        if (res?.result) {
          this.enableConditions = true;
          this.measureEntity = res?.result;
          this.isEntity = true;
        }
      },
      (err: any) => {
        this.enableConditions = false;
      }
    );
  }

  /*adding new attributes to measures entity */
  addAttributes() {
    this.attributes_Arr.push({ key: '', uiElement: this.defaultUiElement });
    this.openUiElementType = false;
    this.searchUiElement = '';
  }

  /*deleting newly created attributes */
  deleteAttribute(index: any) {
    this.attributes_Arr.splice(index, 1);
    this.openUiElementType = false;
    this.searchUiElement = '';
  }

  /*dropdown opening and closing to search ui element type */
  openUiElement(index: any) {
    this.openUiElementIndex = index;
    this.openUiElementType = !this.openUiElementType;
    this.searchUiElement = '';
  }

  /*fnding all ui elements based on search we entered  */
  findAllUiElements() {
    this.attributefacadeservice.getAllAttributeTypes(this.searchUiElement, 0, 50).subscribe((res: any) => {
      this.allUiElements = res?.data;
    });
  }

  /*after searching ui elements we are selecting uielement for particular attribute and saving in attributes payload */
  addUiElementToAttribute(uielement: any, index: any) {
    this.attributes_Arr[index].uiElement = uielement;
    this.openUiElementType = false;
    this.searchUiElement = '';
  }

  /*creating new conditions empty array to give new conditions and appending to existing conditions */
  addConditions() {
    let value_Map: any = [];
    this.saveFlag = true;
    this.attributes_Arr?.forEach((attribute: any) => {
      let obj = {};
      obj['attributeName'] = attribute.key;
      obj['attributeValue'] = '';
      obj['uiElement'] = attribute.uiElement.uiElement;
      value_Map.push(obj);
    });
    this.conditions.push({
      condition: this.contextualId,
      valueMap: value_Map,
    });
    this.operators.push({
      operator: '',
      value: '',
    });
  }

  /*deleting created condition function it will deleted from conditions array */
  deleteCondition(index: any) {
    this.conditions.splice(index, 1);
    this.operators.splice(index, 1);
  }

  /*selecting operator for each condition */
  setConditionOperator(index: any, operator: any) {
    this.operators[index].operator = operator;
  }

  /*selecting condition with operator and reference value and assigning it to condition variable which is part of conditions array */
  setCondition(index: any) {
    /*istanbul ignore else */
    if (index >= 0) {
      /*istanbul ignore else */
      // if (this.operators[index]?.operator == '=') {
      //   this.operators[index].operator = '==';
      // }
      this.conditions[index].condition =
        this.contextualId +
        String(this.operators[index]?.operator == '=' ? '==' : this.operators[index]?.operator) +
        String(this.operators[index]?.value);
    }
  }

  @ViewChild('attachmodal') attachmodal: TemplateRef<any>;

  /*opening file upload dialog box */
  attachDialog(i: any, index: any, templateRef: TemplateRef<any>, attribute: any) {
    this.currentConditionindex = i;
    this.currentAttributeindex = index;
    this.fileType = attribute.uiElement;
    this.dialog.open(templateRef, { panelClass: 'attachmodal-bg' });
  }

  /*after uploading file close dialog box */
  closeFileUpload() {
    this.dialog.closeAll();
  }

  /*after uploading file app-file-upload will emit uploaded file details. so saving file name which we are uploading */
  fileUploaded(event: any) {
    this.conditions[this.currentConditionindex].valueMap[this.currentAttributeindex].attributeValue = JSON.stringify(
      event
    );
  }

  deletedFile(event: any) {}

  /* to save cu with created entity */
  saveChangeUnit() {
    /* istanbul ignore next */
    this.currentCuData.tfId = this.currentCuData?.tfReferencedChangeUnit
      ? this.currentCuData?.tfReferencedChangeUnit
      : this.currentCuData?.tfId;
    /* istanbul ignore next */
    this.currentCuData.id = this.currentCuData.referencedChangeUnit
      ? this.currentCuData.referencedChangeUnit
      : this.currentCuData.id;
    this.currentCuData.tfReferencedChangeUnit = undefined;
    this.currentCuData.referencedChangeUnit = undefined;
    /*istanbul ignore next*/
    this.nodeChangeUnitFacadeService.updateChangeUnit(this.currentCuData, false);
    this.saveGSI();
  }

  /* to save gsi */
  saveGSI() {
    /* istanbul ignore next */
    if (this.measureEntity) {
      let rating: any = {};
      let selectedAttributeContextualId: any = {};
      this.conditions.forEach((condition: any, index: any) => {
        let attributesOfCondition: any = {};
        condition.valueMap.forEach((attrEach: any) => {
          let obj = {};
          attributesOfCondition[attrEach.attributeName] = attrEach.attributeValue;
          // attributesOfCondition.push(obj);
        });
        this.conditions[index].valueMap = attributesOfCondition;
      });
      rating['conditions'] = this.conditions;
      rating['measureEntity'] = this.measureEntity;
      selectedAttributeContextualId[String(this.createNewEntity)] = rating;
      this.measures = {
        ...this.measures,
      };
      this.measures[String(this.contextualId)] = selectedAttributeContextualId;
      // this.measures.push(selectedAttributeContextualId);

      this.tempGsiObj.solutionLogic[this.activeCuIndex].measures = this.measures;
    }
    this.gsifacade.saveGsi(this.tempGsiObj);
    this.onCloseDialog();
    this.alertService.showToaster('Measures added succesdfully', '', 'success');
  }

  /* to close measures*/
  onCloseDialog() {
    if (this.isnode == 'node') {
      this.gsifacade.changeNodeComponent('Graph');
    } else {
      this.gsifacade.changeNSLComponent('nslGsi');
    }

    this.dialog.closeAll();
  }

  /*delete exisiting measures */
  deleteEnity() {
    this.createNewEntity = '';
    this.attributes_Arr = [];
    this.conditions = [];
    this.operators = [];
    this.enableConditions = false;
    this.measureEntity = null;
    delete this.measures[this.contextualId];
    this.isEntity = false;
  }

  ngOnDestroy() {
    this.nodeEntityFacadeService.sendEntityResponseToSf.next(null);
  }
}
