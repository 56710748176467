import { DOCUMENT } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ElementRef, Renderer2, Inject } from '@angular/core';
import { TransactionFacadeService, templateExpandMap, getCurrentEventDataArray, getEventData, displayDateDefaultValue } from '@common-services';
@Component({
  selector: 'app-dynamic-ui-card-templates',
  templateUrl: './dynamic-ui-card-templates.component.html',
  styleUrls: ['./dynamic-ui-card-templates.component.scss'],
})
export class DynamicUiCardTemplatesComponent implements OnInit {
  @Input() title: any;
  contentUrl: any = {};
  @Input() expandIndex: number = -2;
  @Input() currentIndex: number;
  @Input() set _dynamicTemplate(value: any) {
    this.dynamicTemplate = value;
    if (this.isDynamicTemplate && Object.keys(this.attributes).length > 0) {
      this.bindHTML();
    }
  }
  dynamicTemplate: any;

  @Input() showExpand: boolean = false;
  constructor(
    private transactionFacadeService: TransactionFacadeService,
    private el: ElementRef,
    private render: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}
  templateType: any;
  expandType: number;
  isDynamicTemplate: boolean = false;

  @Output() expandEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitOnSelectDelete: EventEmitter<any> = new EventEmitter<any>();

  // @Input() expandIndex: number = -2;
  // @Input() currentIndex: number;
  set childTemplate(value: number) {
    this.templateType = value;
    this.expandType = templateExpandMap[this.templateType];
  }

  expandTemplate: any;
  @Input() entity: any;
  // @Input() entity: any;
  // @Input() attributePositions: any;
  @Input() settings: any;
  @Input() eventCUList: any;
  attributes: any = {};
  attributes_expand: any = {};
  hasExpand: boolean = false;
  currentEventDataArray: any = [[], [], [], [], [], [], []];
  @Output() selectCardEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() dCard: any;
  @Input() listValues: any;
  ngOnInit(): void {
    if (this.settings?.customizeCardconfigData?.templateType) {
      this.isDynamicTemplate = this.settings?.customizeCardconfigData?.templateType.includes('dynamic');
    }
    this.configureCard();
    if (!this.isDynamicTemplate) {
      this.childTemplate = this.settings?.customizeCardconfigData?.templateType;
      if (this.settings?.customizeCardconfigData?.expandTemplateType) {
        this.expandType = this.settings?.customizeCardconfigData?.expandTemplateType;
      }
    }
    if (
      this.settings?.customizeCardconfigData?.expandTemplateTypeData &&
      Object.keys(this.settings?.customizeCardconfigData?.expandTemplateTypeData).length > 0
    ) {
      this.hasExpand = true;
      if (this.settings?.customizeCardconfigData?.expandTemplateType) {
        this.expandType = this.settings?.customizeCardconfigData?.expandTemplateType;
      }
      this.configureExpandData();
    }
    this.attachImages();
  }

  configureCard(): void {
    let attributePositions = this.settings?.customizeCardconfigData?.attr;
    this.entity?.forEach((ele: any) => {
      const index = attributePositions.findIndex((attr: any) => {
        return attr == ele.attributeName;
      });
      this.mapingAttributeValues(index, ele);
    });
  }

  configureExpandData(): void {
    /* istanbul ignore next */
    let attributePositions = this.swapKeyValues(this.settings?.customizeCardconfigData?.expandTemplateTypeData);
    this.entity?.forEach((ele: any) => {
      this.attributes_expand[attributePositions[ele.attributeName]] = ele;
    });
  }

  swapKeyValues(obj: any): any {
    let result = {};
    Object.keys(obj).forEach((key: any) => {
      result[obj[key]] = key;
    });
    return result;
  }

  mapingAttributeValues(index: any, ele: any) {
    this.attributes[index] = ele;
  }
  openExpand(event: any, type?: any): void {
    if (!this.hasExpand) {
      this.clickOnCard(event, type);
      return;
    }
    /*istanbul ignore else*/
    if (event.srcElement.tagName !== 'INPUT' && event.srcElement.className !== 'box') {
      this.showExpand = true;
      this.expandEmitter.emit(this.showExpand);
    }
  }

  closeExpand(event: any): void {
    this.showExpand = false;
    this.expandEmitter.emit(this.showExpand);
  }

  attachImages() {
    Object.keys(this.attributes).forEach((key, index) => {
      if (this.attributes[key].inputType == 'image') {
        const num = parseInt(key);
        this.contentUrl[num + 1] = this.attributes[key].attributeValue;
      }
    });
  }
  selectedCards(event: any, type?: string) {
    if (type == 'radiobutton') this.dCard.isChecked = !this.dCard.isChecked;
    event.stopPropagation();
    event.checked = this.dCard.isChecked;
    this.selectCardEmitter.emit(event);
  }
  clickOnCard(event: any, type?: string) {
    if (event.srcElement.tagName !== 'INPUT' && event.srcElement.className !== 'box') {
      this.dCard.isChecked = !this.dCard.isChecked;
      this.selectedCards(event, type);
    }
  }
  deleteCard(event: any) {
    this.deleteEventEmitter.emit(event);
  }

  // Check event data based on attribute id
  checkEventGSI(attr: any, index: number = 0) {
    if (attr && attr.attributeName && attr?.[attr.attributeName]) {
      /*istanbul ignore next*/
      this.currentEventDataArray[index] = getCurrentEventDataArray(this.eventCUList, attr[attr.attributeName]);
      if (this.currentEventDataArray[index].length > 0) {
        return true;
      }
    }
    return false;
  }

  // Save event GSI data
  saveEventGSI(currEvent: any, attrData: any, event?: any) {
    /*istanbul ignore next*/
    event?.stopPropagation();
    /*istanbul ignore next*/
    let data = getEventData(attrData);
    /* istanbul ignore next */
    data['gsiId'] = currEvent?.gsiId;
    data['isPopupEvent'] = currEvent?.isPopupEvent;
    this.transactionFacadeService.dataForCheckForEvents = data;
    this.transactionFacadeService.setGsiDraftData(currEvent);
  }
  /* istanbul ignore next */
  bindHTML() {
        this.el.nativeElement.querySelector('.dynamic-template-placeholder').innerHTML = ``;
        this.el.nativeElement.querySelector('.dynamic-template-placeholder').innerHTML = this.dynamicTemplate;
        /* istanbul ignore next */
        this.el.nativeElement.querySelectorAll('.dynamic-card-block').forEach((el: any, index: number) => {
          Object.values(this.attributes).forEach((rec: any, ind: number) => {
            /* istanbul ignore next */
            if (el.querySelectorAll('li')[ind]?.classList.value.includes('only-value')) {
              if (this.checkEventGSI(rec, ind)) {
                el.querySelectorAll('li')[ind].addEventListener(
                  'click',
                  () => {
                    /* istanbul ignore next */
                    this.saveEventGSI(this.currentEventDataArray?.[ind]?.[0], rec[rec.attributeName]);
                  },
                  false
                );
              }
              el.querySelectorAll('li')[ind].innerHTML = `<span class='value-placeholer'>${this.getValue(
                rec?.attributeValue,
                rec?.attributeType
              )}</span>`;
            }
            /* istanbul ignore next */
            if (el.querySelectorAll('li')[ind]?.classList.value.includes('attribute-value')) {
              if (rec?.attr_displayName) {
                el.querySelectorAll('li')[
                  ind
                ].innerHTML = `<span class='attr-placeholer'>${rec?.attr_displayName}</span>`;
              } else {
                el.querySelectorAll('li')[ind].innerHTML = `<span class='attr-placeholer'>${rec?.attributeName}</span>`;
              }
              el.querySelectorAll('li')[ind].innerHTML += `<span class='value-placeholer'>${this.getValue(
                rec?.attributeValue,
                rec?.attributeType
              )}</span>`;
              if (this.checkEventGSI(rec, ind)) {
                el.querySelectorAll('li')[ind].addEventListener(
                  'click',
                  () => {
                    /* istanbul ignore next */
                    this.saveEventGSI(this.currentEventDataArray?.[ind]?.[0], rec[rec.attributeName]);
                  },
                  false
                );
              }
            }
            /* istanbul ignore next */
            if (el.querySelectorAll('li')[ind]?.classList.value.includes('hyper-link')) {
              el.querySelectorAll('li')[
                ind
              ].innerHTML = `<a class='link-value' href=${Array.isArray(rec?.attributeValue) ?rec?.attributeValue[0] :rec?.attributeValue} target='_blank'>${rec?.attr_displayName}</a>`;
            }
            /* istanbul ignore next */
            if (el.querySelectorAll('li')[ind]?.classList.value.includes('card-logo')) {
              /* istanbul ignore next */
              if (rec?.attributeValue) {
                el.querySelectorAll('li')[ind].innerHTML = `<img src='${this.getImageVideoValue(
                  rec?.attributeValue
                )}' class='image-class' alt='' />`;
                if (this.checkEventGSI(rec, ind)) {
                  el.querySelectorAll('li')[ind].addEventListener(
                    'click',
                    () => {
                      /* istanbul ignore next */
                      this.saveEventGSI(this.currentEventDataArray?.[ind]?.[0], rec[rec.attributeName]);
                    },
                    false
                  );
                }
              } else {
                el.querySelectorAll('li')[ind].innerHTML = `<img src='' class='image-class' alt='' />`;
              }
            }
            if (el.querySelectorAll('li')[ind]?.classList.value.includes('card-video')) {
              let dimensions = el.querySelectorAll('li')[ind]?.classList?.value?.split(' ');
              let width = 500;
              let height = 300;
              let l = dimensions?.length;
              let dim = dimensions[l - 1].split('-');
              if (dim.length == 4) {
                width = dim[1];
                height = dim[3];
              }

              /* istanbul ignore next */
              if (rec?.attributeValue) {
                //considering only youtube is provided other than nslhub.com
                el.querySelectorAll('li')[ind].innerHTML =
                  this.getHostUrl(rec?.attributeValue) == 'www.youtube.com'
                    ? `<iframe width="${width}" height="${height}" src="${this.getImageVideoValue(
                        rec?.attributeValue
                      )}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                    : `
              <video width="${width}" height="${height}" controls>
              <source src="${this.getImageVideoValue(rec?.attributeValue)}" >
              Your browser does not support HTML video.
              </video>`;
              } else {
                el.querySelectorAll('li')[ind].innerHTML = `<img src='' class='image-class' alt='' />`;
              }
            }
          });
        });
        if (this.dynamicTemplate?.includes('card-full-width')) {
          document.querySelectorAll('.dynamic-expand-template').forEach((e) => {
            if (e.getElementsByClassName('card-full-width').length > 0) {
              e.classList.add('w-100');
            }
          });
        }
  }
  formatDateTime(dt: any): any {
    /* istanbul ignore else */
    if (dt) {
      let x = dt.split('T');
      x[0] = new Date(dt);
      let hrs = x[0].getHours();
      const mins = x[0].getMinutes();
      const meridien = hrs < 12 ? 'AM' : 'PM';
      /* istanbul ignore next */
      if (hrs === '00') {
        hrs = '12';
      } else if (hrs > 12) {
        hrs = hrs - 12;
      }
      let time = hrs + ':' + mins + meridien;
      let date = displayDateDefaultValue(x[0], 'date', 'MM/dd/yyyy', '12-hr', 'yyyy-MM');
      return date + ' ' + time;
    } else return 'none';
  }

  getValue(attrVal: any, type?: any) {
    let finalType = '';
    if (type) {
      finalType = type.toLowerCase();
    }
    if (Array.isArray(attrVal)) {
      let finalValue = '';
      if (finalType == 'date' || finalType == 'datetime') {
        attrVal.forEach((val: any, index: number) => {
          if (finalType == 'date') {
            finalValue += this.formatDateTime(val[0])?.split(' ')?.[0];
          } else if (finalType == 'datetime') {
            finalValue += this.formatDateTime(val[0]);
          }
        });
      }
      return finalType == 'date' || finalType == 'datetime' ? finalValue : attrVal.join(' ');
    } else {
      let finalValue = '';
      if (finalType == 'date') {
        finalValue = this.formatDateTime(attrVal)?.split(' ')?.[0];
      } else if (finalType == 'datetime') {
        finalValue = this.formatDateTime(attrVal);
      }
      return finalType == 'date' || finalType == 'datetime' ? finalValue : attrVal;
    }
  }
  getHostUrl(attrVal: any) {
    try{
      let url = new URL(attrVal);
      return url.host;
    } catch(er) {
      return attrVal
    }
   
  }

  getImageVideoValue(attrVal: any) {
    if (Array.isArray(attrVal)) {
      return attrVal[0];
    } else {
      let image :any;
      if(attrVal?.charAt(0)=='{'){
        image = JSON.parse(attrVal);
        if (image?.contentUrl) {
          return image.contentUrl;
        }
      } else if(attrVal?.charAt(0)=='/') return attrVal;
      else {
        let host = this.getHostUrl(attrVal);
        if (host == 'www.youtube.com') {
          let arr = attrVal.split('watch?v=');
          let videoId = arr[1];
          attrVal = 'https://' + host + '/embed/' + videoId;
        }
        return attrVal;
      }
    }
  }
  onSelectDelete(event: any) {
    this.emitOnSelectDelete.next(event);
  }
}
