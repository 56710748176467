<ng-template #alertDialog>
  <div mat-dialog-content>You can’t map GSI and add to cart functionality at a same time.</div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ labels?.Close }}</button>
  </div>
</ng-template>
<ng-template #actionButton let-data="data">
  <span class="btn-close">
    <i
      class="bi bi-x"
      *ngIf="childGsiMapping?.[data?.position]"
      (click)="removeGsiFromCard(data?.position, childGsiMapping)"
    ></i>
    <button
      *ngIf="!isGrammer"
      #gsiAction
      (drop)="dropActionBtn($event, childGsiMapping)"
      (dragover)="allowDrop($event)"
      class="btn template-btn action-button btnaction"
      pTooltip="Action Button"
      [id]="'action-' + data?.position"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      (dblclick)="dblClickButtonInCard($event)"
    >
      {{ childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position]?.name : 'Action Button'}}
    </button>
  </span>
</ng-template>
<div
  class="entityTable-configuration"
  [ngClass]="{ 'Advanced-feature-configuration': isAdvancedFeature }"
  [ngClass]="{ 'd-none': !(!canvasDesignPage && !isAdvancedFeature) }"
>
  <div class="nhCard">
    <div class="nhCard_header">
      <div class="nhCard_header_inner d-flex align-items-center justify-content-between">
        <div class="nhCard_header_left d-flex align-items-center">
          <button class="nh-unsetAll cursor-pointer nhCard_header_back">
            <em class="nh-icon nh-icon-chevron-left" (click)="backtoGsiPage()"></em>
          </button>
          <h4
            class="nh-fs-18 cursor-pointer"
            id="mylibrary_1"
            aria-current="page"
            [routerLink]="['/cdui/mylibrary-dashboard']"
          >
            <ng-container *ngIf="!isGrammer">{{ labels?.My_Library }}</ng-container>
            <ng-container *ngIf="isGrammer">CU Card Configuration</ng-container>
          </h4>
        </div>
        <div class="nhCard_header_middle d-flex align-items-center">
          <button [ngClass]="tabSelected == 'child' ? 'active' : ''" aria-current="page" (click)="changeTab('child')">
            {{ labels?.Child }}
          </button>
          <button (click)="changeTab('expand')" [ngClass]="tabSelected == 'expand' ? 'active' : ''" *ngIf="isExpand">
            {{ labels?.Expand }}
          </button>
          <button
            *ngIf="CART_BUTTON.isSelected['child'].isSelected || CART_BUTTON.isSelected['expand'].isSelected"
            (click)="changeTab('cart')"
            [ngClass]="tabSelected == 'cart' ? 'active' : ''"
          >
            {{ labels?.Cart }}
          </button>
          <button
            class="d-flex align-items-center btn-cusomise"
            *ngIf="tabSelected != 'cart' && !isKanbanBoard"
            (click)="openDialog(myDialog)"
          >
            {{ labels?.Customise_card }}
            <img [src]="'../../../../../assets/img/general-entity/createsolution.svg' | CDNUrlRewritePipe" alt="" />
          </button>
          <button
            class="d-flex align-items-center btn-cusomise"
            *ngIf="isKanbanBoard"
            (click)="showKanbanCardConfiguration = !showKanbanCardConfiguration"
          >
            {{ showKanbanCardConfiguration ? 'Customise Card' : 'Kanban Board' }}
            <img [src]="'../../../../../assets/img/general-entity/createsolution.svg' | CDNUrlRewritePipe" alt="" />
          </button>
        </div>
        <div class="nhCard_header_right d-flex align-items-center">
          <ul class="nhCard_header_right_list" *ngIf="!isGrammer && (isCardConfig || isAdvancedFeature)">
            <li class="eth-close" id="cancel_1" (click)="gotoMylibrary()"><i class="bi bi-x-lg"></i></li>
            <li class="eth-check" *ngIf="!isAdvancedFeature" id="next_1" (click)="gotoAdvancedFeature()">
              <i class="bi bi-check2"></i>
            </li>
            <li>
              <button class="btn publish" *ngIf="!isAdvancedFeature && !isExpand" (click)="withoutAdvancedFeatures()">
                Publish
              </button>
            </li>
          </ul>
          <ul class="nhCard_header_right_list" *ngIf="isGrammer">
            <li class="eth-close" id="cancel_1" (click)="cardConfigEvent.emit(false)"><i class="bi bi-x-lg"></i></li>
            <li class="eth-check" id="done_2" (click)="gotoEntityConfigpage()"><i class="bi bi-check2"></i></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nhCard_maincontent">
      <div class="nhrow">
        <div class="nhrow_col nhrow_left">
          <div class="et-config-content-left" *ngIf="isCardConfig">
            <mat-tab-group mat-align-tabs="start" class="form-tabs form-tabs-opt1">
              <mat-tab label="{{ labels?.Entity }}">
                <div class="attributes-list">
                  <ul>
                    <ng-container *ngIf="!isGrammer">
                      <ul *ngIf="!selectedDynamicTemplate">
                        <ng-container *ngFor="let attr of draggableAttributes">
                          <li
                          *ngIf="
                            ((!attr.selectedInChild &&
                            tabSelected == 'child') ||
                            (!attr.selectedInExpand && tabSelected == 'expand') ||
                            (!attr.selectedInKanban && isKanbanBoard && !showKanbanCardConfiguration)) &&
                              attr?.attributeType?.uiElement?.uiElement != 'hyperlink'
                            "
                            draggable="true"
                            (dragstart)="drag($event, attr, true)"
                            [id]="attr.name"
                          >
                            <i class="bi bi-grip-vertical"></i>
                            <p>{{ attr.name }}</p>
                          </li>
                        </ng-container>
                      </ul>
                      <ul *ngIf="selectedDynamicTemplate">
                        <ng-container *ngFor="let attr of nslAttributes">
                          <li
                            *ngIf="
                             tabSelected == 'child'
                            "
                            draggable="true"
                            (dragstart)="drag($event, attr, true)"
                            [id]="attr.name"
                          >
                            <i class="bi bi-grip-vertical"></i>
                            <p>{{ attr.name }}</p>
                          </li>
                        </ng-container>
                        <ng-container *ngFor="let attr of nslAttributesExpand">
                          <li
                            *ngIf="tabSelected == 'expand'
                            "
                            draggable="true"
                            (dragstart)="drag($event, attr, true)"
                            [id]="attr.name"
                          >
                            <i class="bi bi-grip-vertical"></i>
                            <p>{{ attr.name }}</p>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-container>
                    <ng-container *ngIf="isGrammer">

                        <ul *ngIf="!selectedDynamicTemplate">
                          <ng-container *ngFor="let attr of draggableAttributes">
                            <li
                            *ngIf="
                            (!attr.selectedInChild && tabSelected == 'child') ||
                            (!attr.selectedInExpand && tabSelected == 'expand')
                          "
                              draggable="true"
                              (dragstart)="drag($event, attr, true)"
                              [id]="attr.name"
                            >
                              <i class="bi bi-grip-vertical"></i>
                              <p>{{ attr.name }}</p>
                            </li>
                          </ng-container>
                        </ul>
                        <ul *ngIf="selectedDynamicTemplate">
                          <ng-container *ngIf="
                          tabSelected == 'child'
                         ">
                            <li

                               *ngFor="let attr of nslAttributes"
                              draggable="true"
                              (dragstart)="drag($event, attr, true)"
                              [id]="attr.name"
                            >
                              <i class="bi bi-grip-vertical"></i>
                              <p>{{ attr.name }}</p>
                            </li>
                          </ng-container>
                          <ng-container  *ngIf="tabSelected == 'expand' ">
                            <li

                               *ngFor="let attr of nslAttributesExpand"
                              draggable="true"
                              (dragstart)="drag($event, attr, true)"
                              [id]="attr.name"
                            >
                              <i class="bi bi-grip-vertical"></i>
                              <p>{{ attr.name }}</p>
                            </li>
                          </ng-container>
                        </ul>
                    </ng-container>
                  </ul>
                  <!-- <ul *ngIf="tabSelected == 'expand'">
                    <ng-container *ngFor="let attr of nslAttributesExpand">
                      <li draggable="true" (dragstart)="drag($event, attr, true)" [id]="attr.name">
                        <i class="bi bi-grip-vertical"></i>
                        <p>{{ attr?.name }}</p>
                      </li>
                    </ng-container>
                  </ul> -->
                  <ul *ngIf="tabSelected == 'cart'">
                    <ng-container *ngFor="let attr of cartAttributes; let i = index">
                      <li
                        *ngIf="!attr?.isSelected"
                        draggable="true"
                        (dragstart)="drag($event, attr, true)"
                        [id]="'attr-' + i"
                      >
                        <i class="bi bi-grip-vertical"></i>
                        <p>{{ attr?.name }}</p>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </mat-tab>
              <mat-tab label="{{ labels?.GSI }}" *ngIf="!isGrammer && tabSelected !== 'cart'">
                <mat-accordion *ngIf="tabSelected !== 'expand'">
                  <ng-container *ngFor="let gsi of childGsiList; let i = index">
                    <mat-expansion-panel
                      *ngIf="!gsi.isSelected && gsi.entityType == 'GSI'"
                      [id]="gsi.name + '-' + i"
                    >
                      <mat-expansion-panel-header
                      draggable="true"
                      (dragstart)="dragButton($event, gsi)"
                      >
                        <mat-panel-title >
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex align-items-center justify-content-between">
                        <mat-slide-toggle [(ngModel)]="gsi.enablePopup"  class="er-filter-toggle er-filter-toggle-a label-color" >
                          <span>
                            {{ labels?.Enable_Popup || 'Enable Popup' }}
                          </span>
                        </mat-slide-toggle>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
                <mat-accordion *ngIf="tabSelected == 'expand'">
                  <ng-container *ngFor="let gsi of expandGsiList; let i = index">
                    <mat-expansion-panel
                      *ngIf="!gsi.isSelected && gsi.entityType == 'GSI'"
                      draggable="true"
                      (dragstart)="drag($event, gsi, false)"
                      [id]="gsi.name + '-' + i"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="d-flex align-items-center justify-content-between">
                        <mat-slide-toggle [(ngModel)]="gsi.enablePopup" class="er-filter-toggle er-filter-toggle-a label-color">
                          <span>
                            {{ labels?.Enable_Popup || 'Enable Popup' }}
                          </span>
                        </mat-slide-toggle>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
                <ng-container *ngFor="let attr of draggableAttributes">
                  <li
                    *ngIf="
                      ((!attr.selectedInChild && tabSelected == 'child') ||
                        (!attr.selectedInExpand && tabSelected == 'expand')) &&
                      attr?.attributeType?.uiElement?.uiElement == 'hyperlink'
                    "
                    draggable="true"
                    (dragstart)="drag($event, attr, true)"
                    [id]="attr.name"
                  >
                    <i class="bi bi-grip-vertical"></i>
                    <p>{{ attr.name }}</p>
                  </li>
                </ng-container>
              </mat-tab>
              <mat-tab label="{{ labels?.PAGE || 'PAGE' }}" *ngIf="!isGrammer && tabSelected !== 'cart'">
                <mat-accordion *ngIf="tabSelected != 'expand'">
                  <ng-container *ngFor="let gsi of childGsiList; let i = index">
                    <mat-expansion-panel
                      *ngIf="!gsi.isSelected && gsi.entityType === 'PAGE'"
                      draggable="true"
                      (dragstart)="dragButton($event, gsi)"
                      [id]="gsi.name + '-' + i"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
                <mat-accordion *ngIf="tabSelected == 'expand'">
                  <ng-container *ngFor="let gsi of expandGsiList; let i = index">
                    <mat-expansion-panel
                      *ngIf="!gsi.isSelected && gsi.entityType === 'PAGE'"
                      draggable="true"
                      (dragstart)="drag($event, gsi, false)"
                      [id]="gsi.name + '-' + i"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <i class="bi bi-grip-vertical"></i>
                          <p>{{ gsi.name }}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </mat-tab>
              <mat-tab label="{{ labels?.Add_To_Cart }}" *ngIf="!isGrammer && tabSelected !== 'cart'">
                <div class="entity-tabs-info">
                  <div class="cart-enable">
                    <mat-accordion *ngIf="tabSelected !== 'expand'">
                      <ng-container *ngIf="!CART_BUTTON.isSelected['child'].isSelected">
                        <mat-expansion-panel
                          *ngIf="!showButtonNameEdit"
                          draggable="true"
                          (dragstart)="dragCartType($event, true)"
                          id="cartButtonbeforeDrag"
                        >
                          <mat-expansion-panel-header>
                            <mat-panel-title class="text-truncate">
                              <i class="bi bi-grip-vertical"></i>
                              <p
                                class="text-truncate nh-wp-75"
                                pTooltip="{{ CART_BUTTON?.displayName }}"
                                tooltipPosition="top"
                              >
                                {{ CART_BUTTON?.displayName }}
                              </p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <mat-form-field class="custom-input-mat">
                            <label class="main-label">Change Title</label>
                            <input matInput type="text" [(ngModel)]="CART_BUTTON.displayName" name="cartButtonName" />
                          </mat-form-field>
                        </mat-expansion-panel>
                      </ng-container>
                    </mat-accordion>
                    <mat-accordion *ngIf="tabSelected === 'expand'">
                      <ng-container *ngIf="!CART_BUTTON.isSelected['expand'].isSelected">
                        <mat-expansion-panel *ngIf="!showButtonNameEdit" draggable="true" id="cartButtonbeforeDrag">
                          <mat-expansion-panel-header>
                            <mat-panel-title class="text-truncate">
                              <i class="bi bi-grip-vertical"></i>
                              <p
                                class="text-truncate nh-wp-75"
                                pTooltip="{{ CART_BUTTON?.displayName }}"
                                tooltipPosition="top"
                              >
                                {{ CART_BUTTON?.displayName }}
                              </p>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <mat-form-field class="custom-input-mat">
                            <label class="main-label">Change Title</label>
                            <input matInput type="text" [(ngModel)]="CART_BUTTON.displayName" name="cartButtonName" />
                          </mat-form-field>
                        </mat-expansion-panel>
                      </ng-container>
                    </mat-accordion>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <div class="nhrow_col nhrow_middle" [ngClass]="{ kanbanboard: isKanbanBoard && !showKanbanCardConfiguration }">
          <div class="entity-config-info">
            <div class="entity-expand-wrap ng-star-inserted">
              <div class="entity-expand ng-star-inserted">
                <div class="entitycards-scroll ng-star-inserted">
                  <app-card-drop
                  *ngIf="(isKanbanBoard && showKanbanCardConfiguration) || (!isKanbanBoard)"
                    [boxShadowData]="boxShadowData"
                    [cardGsiMap]="cardGsiMap"
                    [childGsiList]="childGsiList"
                    [isEdit]="isEdit"
                    [_childConfig]="childConfig"
                    [_expandConfig]="expandConfig"
                    [_cartViewConfig]="cartViewConfig"
                    [(expandCardStyle)]="expandCardStyle"
                    [(expandStyleAttribute)]="expandStyleAttribute"
                    [(styleCard)]="styleCard"
                    [(expandStyleGsi)]="expandStyleGsi"
                    [(styleGsi)]="styleGsi"
                    [(styleAttribute)]="styleAttribute"
                    [_tabSelected]="tabSelected"
                    [_dragAttrType]="dragAttrType"
                    [_templateType]="templateType"
                    [_expandTemplateType]="expandTemplateType"
                    [_expandComponentName]="expandComponentName"
                    [_childComponentName]="childComponentName"
                    [(CART_BUTTON)]="CART_BUTTON"
                    [expandGsiList]="expandGsiList"
                    [isGrammer]="isGrammer"
                    [isDesignTab]="isDesignTab"
                    [isDesign]="isDesign"
                    [(childGsiMapping)]="childGsiMapping"
                    [_style]="style"
                    [_colorConditionSetter]="colorConditionSetter"
                    [selectedDirectiveId]="selectedDirectiveId"
                    [selectGsiListExpand]="selectGsiListExpand"
                    [(changeObj)]="changeObj"
                    [isSelectTemplate]="isSelectTemplate"
                    [_draggableAttributes]="draggableAttributes"
                    [(draggedAttribute)]="draggedAttribute"
                    [draggedGSI]="draggedGSI"
                    [(conditionColor)]="conditionColor"
                    [(conditionAttribute)]="conditionAttribute"
                    [(conditionAttributeExpand)]="conditionAttributeExpand"
                    [(dropEntityExpandData)]="dropEntityExpandData"
                    [(isCardConfig)]="isCardConfig"
                    (cardConfigEvent)="cardConfigEvent.emit($event)"
                    [(attributeDirectiveId)]="attributeDirectiveId"
                    [isAdvancedFeature]="isAdvancedFeature"
                    [renderDynamiTemplate]="renderDynamiTemplate"
                    [attri]="attributes"
                    [cartTemplateType]="cartTemplateType"
                    [cartAttributesPositionMap]="cartAttributesPositionMap"
                    (enableDroppedAttributeEvent)="enableDroppedAttributeOnCartTemplate($event)"
                    (cartAttributeStylesEmit)="receiveCartAttributeStyles($event)"
                    [_cartAttributes]="cartAttributes"
                    [(cartAttrStyleArray)]="cartAttrStyleArray"
                    [(cartAttrStyleArr)]="cartAttrStyleArr"
                    [isDynamicExpandCardSelected]="isDynamicExpandCardSelected"
                    [_entityCardFlip]="entityCardFlip"
                  >
                  </app-card-drop>
                  <ng-container *ngIf="isKanbanBoard && !showKanbanCardConfiguration">
                    <app-kanban-configuration
                      [_kanbanStatus]="kanbanStatus"
                      [(kanbanGsiConfiguration)]="kanbanGsiConfiguration"
                      [showKanbanCardConfiguration]="!showKanbanCardConfiguration"
                      (kanbanStatusEmitter)="getKanbanStatus($event)"

                      [boxShadowData]="boxShadowData"
                      [cardGsiMap]="cardGsiMap"
                      [childGsiList]="childGsiList"
                      [isEdit]="isEdit"
                      [_childConfig]="childConfig"
                      [_expandConfig]="expandConfig"
                      [_cartViewConfig]="cartViewConfig"
                      [(expandCardStyle)]="expandCardStyle"
                      [(expandStyleAttribute)]="expandStyleAttribute"
                      [(styleCard)]="styleCard"
                      [(expandStyleGsi)]="expandStyleGsi"
                      [(styleGsi)]="styleGsi"
                      [(styleAttribute)]="styleAttribute"
                      [_tabSelected]="tabSelected"
                      [_dragAttrType]="dragAttrType"
                      [_templateType]="templateType"
                      [_expandTemplateType]="expandTemplateType"
                      [_expandComponentName]="expandComponentName"
                      [_childComponentName]="childComponentName"
                      [(CART_BUTTON)]="CART_BUTTON"
                      [expandGsiList]="expandGsiList"
                      [isGrammer]="isGrammer"
                      [isDesignTab]="isDesignTab"
                      [isDesign]="isDesign"
                      [(childGsiMapping)]="childGsiMapping"
                      [_style]="style"
                      [_colorConditionSetter]="colorConditionSetter"
                      [selectedDirectiveId]="selectedDirectiveId"
                      [selectGsiListExpand]="selectGsiListExpand"
                      [(changeObj)]="changeObj"
                      [isSelectTemplate]="isSelectTemplate"
                      [_draggableAttributes]="draggableAttributes"
                      [(draggedAttribute)]="draggedAttribute"
                      [draggedGSI]="draggedGSI"
                      [(conditionColor)]="conditionColor"
                      [(conditionAttribute)]="conditionAttribute"
                      [(conditionAttributeExpand)]="conditionAttributeExpand"
                      [(dropEntityExpandData)]="dropEntityExpandData"
                      [(isCardConfig)]="isCardConfig"
                      (cardConfigEvent)="cardConfigEvent.emit($event)"
                      [(attributeDirectiveId)]="attributeDirectiveId"
                      [isAdvancedFeature]="isAdvancedFeature"
                      [renderDynamiTemplate]="renderDynamiTemplate"
                      [attri]="attributes"
                      [cartTemplateType]="cartTemplateType"
                      [cartAttributesPositionMap]="cartAttributesPositionMap"
                      (enableDroppedAttributeEvent)="enableDroppedAttributeOnCartTemplate($event)"
                      (cartAttributeStylesEmit)="receiveCartAttributeStyles($event)"
                      [_cartAttributes]="cartAttributes"
                      [(cartAttrStyleArray)]="cartAttrStyleArray"
                      [(cartAttrStyleArr)]="cartAttrStyleArr"
                    ></app-kanban-configuration>
                  </ng-container>
                  <ng-container *ngIf="renderDynamiTemplate">
                    <div
                    class="dynamic-template-placeholder"
                    [ngStyle]="{ display: tabSelected == 'child' ? '' : 'none' }"
                    #dropEntityData
                  ></div>
                  </ng-container>
                  <!-- <ng-container *ngIf="renderDynamiTemplate">
                    <div
                    class="dynamic-template-placeholder"
                    [ngStyle]="{ display: tabSelected == 'expand' ? '' : 'none' }"
                    #dropDynamicExpandEntityData
                  ></div>
                  </ng-container> -->
                  <lib-dynmaic-expand-card
                  [ngClass]="{ 'd-none': tabSelected !== 'expand' }"
                  [selectGsiListExpand]="selectGsiListExpand"
                  [colorConditionSetter]="colorConditionSetter"
                  [isDesign]="isDesignTab"
                  (emitConditionColor)="conditionColorEmit($event, 'expand')"
                  [templateType]="templateType"
                  [_expandType]="expandTemplateType"
                  [isGrammer]="isGrammer"
                  [_isDynamicExpandCardSelected]="isDynamicExpandCardSelected"
                  *ngIf="!isAdvancedFeature"
                  [ngStyle]="tabSelected == 'expand' ? { width: '33.3%' } : { display: 'none' }"
                  (droppedAttr)="droppedAttr($event)"
                  (droppedAttrExpandEmit)="recieveDropEntityExpandData($event)"
                  [dragAttrType]="dragAttrType"
                  [nslAttributes]="nslAttributesExpand"
                  (droppedActionButton)="droppedActionButton($event)"
                  (onDeletion)="setExpandAttributeDataAfterDeletion($event)"
                  [expandGsiList]="expandGsiList"
                  (expandGsiListemit)="expandGsiListemit($event)"
                  [CART_BUTTON]="CART_BUTTON"
                  (CART_BUTTON_EMIT)="cartButtonEmit($event)"
                  [boxShadowData]="boxShadowData"
                  [(hoverColorsExpand)]="hoverColorsExpand"
                  [style]="style"
                  [tabSelected]="tabSelected">

                  </lib-dynmaic-expand-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nhrow_col nhrow_right" *ngIf="!isGrammer">
          <div class="et-config-content-right">
            <mat-tab-group (selectedTabChange)="tabChanged($event)" class="form-tabs form-tabs-opt1">
              <mat-tab label="{{ labels?.Configure }}">
                <div class="editfields-form nh-mb-16" *ngIf="isSlectedRole">
                  <label>{{ labels?.Roles }}</label>
                  <div class="editfields-info">
                    <h4 pTooltip="{{ selectedRole }}" tooltipPosition="top" tooltipStyleClass="transcustomtolltipbook">
                      {{ selectedRole }}
                    </h4>
                    <button class="btn btnedit">
                      <em class="nh-icon nh-icon-trash01" (click)="backtoRolePage()"></em>
                      <!-- <img
                        [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                        alt=""
                        (click)="backtoRolePage()"
                      /> -->
                    </button>
                  </div>
                </div>
                <div class="editfields-form nh-mb-16" *ngIf="isSlectedEntity">
                  <label>{{ labels?.ENTITY }}</label>
                  <div class="editfields-info">
                    <h4
                      pTooltip="{{ selectedEntity }}"
                      tooltipPosition="top"
                      tooltipStyleClass="transcustomtolltipbook"
                    >
                      {{ selectedEntity }}
                    </h4>
                    <button class="btn btnedit">
                      <em class="nh-icon nh-icon-trash01" (click)="backtoEntityPage()"></em>
                      <!-- <img
                        [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                        alt=""
                        (click)="backtoEntityPage()"
                      /> -->
                    </button>
                  </div>
                </div>
                <div class="editfields-form nh-mb-16" *ngIf="selectedGsi.length > 0">
                  <label>{{ labels?.Solution }}</label>
                  <div class="editfields-info">
                    <h4
                      pTooltip="{{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}  {{
                        selectedGsi[2] ? ',' + selectedGsi[2] : ''
                      }} "
                      tooltipPosition="top"
                      tooltipStyleClass="transcustomtolltipbook"
                    >
                      {{ selectedGsi[0] }} {{ selectedGsi[1] ? ',' + selectedGsi[1] : '' }}
                      {{ selectedGsi[2] ? ',' + selectedGsi[2] : '' }}
                    </h4>
                    <button class="btn btnedit">
                      <em class="nh-icon nh-icon-trash01" *ngIf="!isEdit" (click)="backtoGsiPage()"></em>
                      <em class="nh-icon nh-icon-edit02" *ngIf="isEdit" (click)="backtoGsiPage()"></em>
                      <!-- <img
                        [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                        alt=""
                        (click)="backtoGsiPage()"
                      /> -->
                    </button>
                  </div>
                </div>
                <div class="dcd-btn-container">
                  <button class="btn" (click)="callConnectors()">Add Connectors</button>
                </div>
              </mat-tab>
              <mat-tab label="{{ labels?.Design }}" *ngIf="!isAdvancedFeature">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ labels?.Color || 'Color' }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="color-picker1">
                      <div class="appearance-sec" *ngIf="tabSelected === 'child' || !applySameToExpand">
                        <div class="color-customisation">
                          <ul>
                            <li (click)="toggleColorPicker()">
                              <p>{{ labels?.Fill_Color || 'Fill Color' }}</p>
                              <p class="box-color">
                                <span class="color-fill" [ngStyle]="{ 'background-color': selectedColor }"></span>
                                <i class="bi bi-eyedropper"></i>
                              </p>
                            </li>
                            <span
                              *ngIf="colorPickerToggler"
                              [cpPositionRelativeToArrow]="true"
                              [cpDialogDisplay]="'inline'"
                              (colorPickerChange)="changeColor($event)"
                              [(colorPicker)]="colorModel"
                              [cpOKButton]="true"
                              (colorPickerSelect)="closeColorPicker($event)"
                              [cpToggle]="true"
                              [cpOKButtonText]="'Done'"
                            >
                            </span>
                            <li (click)="toggleHoverBackgroundColor()">
                              <p>{{ labels?.Hover_Color || 'Hover Color' }}</p>
                              <p class="box-color">
                                <span class="color-fill" [ngStyle]="{ 'background-color': hoverColor }"></span>
                                <i class="bi bi-eyedropper"></i>
                              </p>
                            </li>
                            <span
                              *ngIf="hoverBackgroundPickerToggler"
                              [cpPositionRelativeToArrow]="true"
                              [cpDialogDisplay]="'inline'"
                              (colorPickerChange)="hoverBackgroundColor($event)"
                              [(colorPicker)]="colorModel"
                              [cpOKButton]="true"
                              (colorPickerSelect)="closehoverBackgroundColorPicker($event)"
                              [cpToggle]="true"
                              [cpOKButtonText]="'Done'"
                            >
                            </span>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ labels?.Condition }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="condition-wrapper">
                      <div
                        class="add-condition d-flex justify-content-center align-items-center cursor-pointer"
                        (click)="addCondition()"
                        *ngIf="tabSelected === 'child' || !applySameToExpand"
                      >
                        <span><i class="bi bi-plus-lg"></i></span>
                        <span class="text">{{ labels?.Add_Condition }}</span>
                      </div>
                      <div class="condition-box d-flex">
                        <span *ngIf="conditionArray.length > 0">{{ labels?.Child || 'Child' }}:</span>
                        <ng-container *ngFor="let condition of conditionArray; let index = index">
                          <div class="d-flex nh-fs-14">
                            <div class="d-flex nh-column-gap-5 justify-content-between condition-attr">
                              <p matTooltip="{{ condition.attribute }}" class="text-truncate">
                                {{ condition.attribute }}
                              </p>
                              <p>{{ condition.condition }}</p>
                              <p
                                matTooltip="{{ condition.value }}"
                                class="box text-truncate"
                                [ngStyle]="{ background: condition.color }"
                              >
                                {{ condition.value }}
                              </p>
                            </div>
                            <div>
                              <i
                                class="bi bi-trash3 nh-fs-14 ml-auto cursor-pointer"
                                (click)="deleteSelectedCondition(index, 'child')"
                              ></i>
                            </div>
                          </div>
                        </ng-container>
                        <span *ngIf="conditionArrayExpand.length > 0">{{ labels?.Expand || 'Expand' }}:</span>
                        <ng-container *ngFor="let condition of conditionArrayExpand; let index = index">
                          <div class="d-flex nh-fs-14 nh-column-gap-10">
                            <span
                              ><i class="bi bi-trash3" (click)="deleteSelectedCondition(index, 'expand')"></i>
                              {{ condition.attribute }}{{ condition.condition }}{{ condition.value }}</span
                            >
                            <span class="box" [ngStyle]="{ background: condition.color }">
                              <p>{{ condition.color }}</p></span
                            >
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="condition-tab" *ngIf="addConditionVariable">
                      <div class="operator-list">
                        <ul (click)="addConditionOperator()">
                          <li>
                            <button
                              class="btn-acc btn-icon"
                              [ngClass]="{ active: selectedCondition === '==' }"
                              (click)="setSelectedCondition('==')"
                            >
                              <span>==</span>
                            </button>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-icon"
                              [ngClass]="{ active: selectedCondition === '!=' }"
                              (click)="setSelectedCondition('!=')"
                            >
                              <span>!=</span>
                            </button>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-icon"
                              (click)="setSelectedCondition('>')"
                              [ngClass]="{ active: selectedCondition === '>' }"
                            >
                              <span>></span>
                            </button>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-icon"
                              (click)="setSelectedCondition('\<')"
                              [ngClass]="{ active: selectedCondition === '\<' }"
                            >
                              <span><</span>
                            </button>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-icon"
                              (click)="setSelectedCondition('>=')"
                              [ngClass]="{ active: selectedCondition === '>=' }"
                            >
                              <span>>=</span>
                            </button>
                          </li>
                          <li>
                            <button
                              class="btn-acc btn-icon"
                              (click)="setSelectedCondition('\<=')"
                              [ngClass]="{ active: selectedCondition === '\<=' }"
                            >
                              <span><=</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div class="input-section">
                        <p class="title text-color">{{ labels?.Value || 'Value' }}</p>
                        <input
                          type="text"
                          placeholder="Add Value"
                          class="form-control"
                          [(ngModel)]="selectedConditonValue"
                        />
                      </div>
                      <div class="color-customisation">
                        <ul>
                          <li (click)="ChangeConditionColorToggle()">
                            <p>{{ labels?.Fill_Color || 'Fill Color' }}</p>
                            <p class="box-color">
                              <span class="color-fill" [ngStyle]="{ 'background-color': conditionColor }"></span
                              ><i class="bi bi-eyedropper"></i>
                            </p>
                          </li>
                          <span
                            *ngIf="conditioncColorToggler"
                            [cpPositionRelativeToArrow]="true"
                            [cpDialogDisplay]="'inline'"
                            (colorPickerChange)="changeColorCondition($event)"
                            [(colorPicker)]="conditionColor"
                            [cpOKButton]="true"
                            (colorPickerSelect)="ChangeConditionColorToggle()"
                            [cpToggle]="true"
                            [cpOKButtonText]="'Done'"
                          >
                          </span>
                        </ul>
                      </div>
                      <button
                        class="btn-acc btn-dark create-btn"
                        (click)="createCondition()"
                        *ngIf="addConditionVariable"
                      >
                        {{ labels?.Create || 'Create' }}
                      </button>
                      <section class="example-section check-box">
                        <mat-checkbox
                          class="example-margin"
                          *ngIf="expandTemplateType"
                          [(ngModel)]="applySameToExpand"
                          >{{ labels?.Also_apply_at_expand_view || 'Also apply at expand view' }}</mat-checkbox
                        >
                      </section>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="tabSelected === 'child' || !applySameToExpand">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ labels?.Text || 'Text' }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="test">
                      <p class="title text-color">{{ labels?.Font_Family || 'Font Family' }}</p>
                      <div class="entity-custom-select">
                        <mat-form-field appearance="fill" floatLabel="never">
                          <mat-select
                            (ngModelChange)="changeFont($event)"
                            [(ngModel)]="_fontIndex"
                            placeholder="Inter"
                            panelClass="entity-custom-select-options"
                          >
                            <mat-option *ngFor="let font of fonts; let i = index" [value]="i">{{
                              font.name
                            }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <p class="title text-color">{{ labels?.Font_Style || 'Font Style' }}</p>
                      <div class="entity-custom-select"></div>
                      <div class="font-style">
                        <ul>
                          <li
                            [ngStyle]="{
                              border: isBold ? '1px solid black' : '1px solid rgba(217, 217, 217, 0.5)'
                            }"
                            (click)="changeBold()"
                          >
                            <span>{{ labels?.B || 'B' }}</span>
                          </li>
                          <li
                            [ngStyle]="{
                              border: isItalic ? '1px solid black' : '1px solid rgba(217, 217, 217, 0.5)'
                            }"
                            (click)="changeItalic()"
                          >
                            <span>{{ labels?.I || 'I' }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="alignment">
                        <p class="title text-color">{{ labels?.Alignment || 'Alignment' }}</p>
                        <ul>
                          <li
                            *ngFor="let align of alignmentOptions"
                            (click)="checkAlign(align)"
                            [ngClass]="{ active: alignment == align }"
                          >
                            <em [class]="align.class"></em>
                          </li>
                        </ul>
                      </div>
                      <div class="font-section">
                        <p class="title text-color">{{ labels?.Font_Size || 'Font Size' }}</p>
                        <div class="font-input">
                          <button class="font-btn">
                            <i class="bi bi-dash" (click)="decreaseFontSize()"></i>
                          </button>
                          <span>{{ fontSize }}</span>
                          <button class="font-btn">
                            <i class="bi bi-plus" (click)="increaseFontSize()"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ labels?.Border || 'Border' }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="appearance-sec">
                      <div>
                        <div class="color-customisation">
                          <ul>
                            <li class="nh-mb-12 nh-mt-8">
                              <mat-slide-toggle
                                class="er-filter-toggle er-filter-toggle-a"
                                id="border-radius-toggle"
                                (click)="borderSizeToggleChange()"
                                [checked]="borderRadiusToggle"
                              >
                                <span>
                                  <p class="nh-fs-14">{{ labels?.Border || 'Border' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div *ngIf="borderRadiusToggle === true">
                        <div class="color-customisation">
                          <ul>
                            <li>
                              <p>{{ labels?.Border || 'Border' }} {{ labels?.Color || 'Color' }}</p>
                              <p class="box-color">
                                <span class="color-fill" [ngStyle]="{ 'background-color': borderColorModel }"></span>
                                <i class="bi bi-eyedropper cursor-pointer" (click)="toggleBorderColorPicker()"></i>
                              </p>
                            </li>
                            <span
                              class="color-fill"
                              *ngIf="borderColorPickerToggler"
                              [cpPositionRelativeToArrow]="true"
                              [cpDialogDisplay]="'inline'"
                              (colorPickerChange)="changeBorderColor($event)"
                              [(colorPicker)]="borderColorModel"
                              [cpOKButton]="true"
                              (colorPickerSelect)="closeBorderColorPicker($event)"
                              [cpToggle]="true"
                              [cpOKButtonText]="'Done'"
                            ></span>
                          </ul>
                        </div>
                        <div class="pagination-sec d-flex nh-column-gap-16 nh-mb-16 nh-mt-16">
                          <div class="font-section w-100">
                            <p class="title text-color">
                              {{ labels?.Border || 'Border' }} {{ labels?.Size || 'Size' }}
                            </p>
                            <div class="font-input">
                              <button class="font-btn">
                                <i class="bi bi-dash" (click)="decreaseBorderProperty('borderSize')"></i>
                              </button>
                              <span>{{ borderSize }}</span>
                              <button class="font-btn">
                                <i class="bi bi-plus" (click)="increaseBorderProperty('borderSize')"></i>
                              </button>
                            </div>
                          </div>
                          <div class="font-section w-100">
                            <p class="title text-color">
                              {{ labels?.Border || 'Border' }} {{ labels?.Radius || 'Radius' }}
                            </p>
                            <div class="font-input">
                              <button class="font-btn">
                                <i class="bi bi-dash" (click)="decreaseBorderProperty('borderRadius')"></i>
                              </button>
                              <span>{{ borderRadius }}</span>
                              <button class="font-btn">
                                <i class="bi bi-plus" (click)="increaseBorderProperty('borderRadius')"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ul>
                          <li class="nh-mb-12">
                            <mat-slide-toggle
                              class="er-filter-toggle er-filter-toggle-a"
                              [(ngModel)]="boxShadowToggle"
                              (change)="boxShadowToggle && openTemplatesDialog()"
                            >
                              <span>
                                <p class="nh-fs-14">
                                  {{ labels?.Apply_Drop_Shadow || 'Apply Drop Shadow' }}
                                </p>
                              </span>
                            </mat-slide-toggle>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ labels?.Advanced_Settings || 'Advanced Settings' }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="appearance-sec">
                      <div>
                        <div class="color-customisation">
                          <ul>
                            <li class="nh-mb-12 nh-mt-8">
                              <mat-slide-toggle
                                class="er-filter-toggle er-filter-toggle-a"
                                id="enable analytics"
                                [(ngModel)]="enableAnalytics"
                              >
                                <span>
                                  <p class="nh-fs-14">{{ labels?.Enable_Analytics || 'Enable Analytics' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                            <li class="nh-mb-12 nh-mt-8" *ngIf="entityFlipSet?.has(templateType?.toString())">
                              <mat-slide-toggle
                                class="er-filter-toggle er-filter-toggle-a"
                                id="entityCardFlip"
                                [(ngModel)]="entityCardFlip"
                              >
                                <span>
                                  <p class="nh-fs-14">{{ labels?.entityCardFlip || ' entity Card Flip' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel *ngIf="tabSelected === 'expand'">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ labels?.Expand_Popup || 'Expand Popup' }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="appearance-sec">
                      <div>
                        <div class="color-customisation">
                          <ul>
                            <li class="nh-mb-12 nh-mt-8">
                              <mat-slide-toggle
                                class="er-filter-toggle er-filter-toggle-a"
                                id="expand popup"
                                [(ngModel)]="isExpandPopup"
                              >
                                <span>
                                  <p class="nh-fs-14">{{ labels?.Popup_for_expand_card || 'Popup for expand card' }}</p>
                                </span>
                              </mat-slide-toggle>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>
              <mat-tab label="Constraint" *ngIf="isKanbanBoard">
                <div class="kanban-block">
                  <mat-form-field
                    class="mb-4 label-list"
                  >
                    <mat-label>Select Attribute</mat-label>
                    <mat-select
                      id="attributeSelectDropdown"
                      [(ngModel)]="selectedAttribute"
                    >
                      <mat-option
                        *ngFor="let attribute of attributeTypes; let i = index"
                        id="attributeSelected_{{ attribute.name }}"
                        (click)="selectAttributeForValidator(attribute)"
                        [value]="attribute.name"
                      >
                        {{ attribute.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    class="mb-4 label-list"
                  >
                    <mat-label>{{ labels?.Select_Validators }}</mat-label>
                    <mat-select
                      id="validatorsSelectDropdown"
                      [(ngModel)]="selectedConstraint"
                    >
                      <mat-option
                        *ngFor="let validator of validatorsList"
                        id="validatorsSelected_{{ validator.name }}"
                        (click)="validatorsChange(validator)"
                        [value]="validator.name"
                      >
                        {{ validator.displayName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="basedrop-panel">
                  <form [formGroup]="constraintsForm" (ngSubmit)="addConstraint()">
                    <div class="" *ngFor="let property of selectedConstraintProperties">
                      <mat-form-field
                        appearance="legacy"
                      >
                        <mat-label
                          >{{ property?.name }} <span class="bi bi-info-circle pe" matTooltip="{{ property?.info }}"></span
                        ></mat-label>
                        <input
                          #inputDuration
                          [formControlName]="property?.name"
                          [type]="dataMap[property?.dataType]"
                          matInput
                          [required]="property?.isMandatory"
                        />
                      </mat-form-field>
                    </div>
                    <input type="submit" value="Add Constraint" class="btn btn-info save btnsave" />
                  </form>
                  <!-- <button (click)="deleteAllConstraints()" class="cta-btn">Reset Constraints</button> -->
                </div>
                <div class="constraints-display">
                  <div *ngFor="let constraint of constraintsArray; let i = index">
                    <table class="table-custom">
                      <tr>
                        <td colspan="2" align="right">
                          <span>
                          <img
                          [src]="'../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe"
                          alt=""
                          width="20"
                          height="20"
                          (click)="editConstraint(constraint, i)"
                        />
                      </span>

                          <span>
                          <img
                          [src]="'../../../../../assets/img/general-entity/delete-icon.svg' | CDNUrlRewritePipe"
                          alt=""
                          height="20"
                          width="20"
                          (click)="deleteConstraint(i)"
                        />
                      </span></td>
                      </tr>
                      <tr>
                        <th>
                          <span>attribute name</span>
                        </th>
                        <th>
                          <span>{{constraint.attributeName}}</span>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <span>constraint name</span>
                        </th>
                        <th>
                          <span>{{constraint.name}}</span>
                        </th>
                      </tr>
                      <tr *ngFor="let property of constraint.properties | keyvalue">
                        <td><span >{{property.key}}:</span></td>
                        <td><span >{{property.value}}</span></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="entityTable-configuration" *ngIf="isAdvancedFeature">
  <div class="nhCard">
    <div class="nhCard_header">
      <div class="nhCard_header_inner d-flex align-items-center justify-content-between">
        <div class="nhCard_header_left d-flex align-items-center">
          <button class="nh-unsetAll cursor-pointer nhCard_header_back">
            <em class="nh-icon nh-icon-chevron-left" (click)="isAdvancedFeature = false; isCardConfig = true"></em>
          </button>
          <h4 class="nh-fs-18">{{ labels?.Advanced_Feature || 'Advanced Feature' }}</h4>
        </div>
        <div
          class="nhCard_header_right d-flex align-items-center"
          *ngIf="!isGrammer && (isCardConfig || isAdvancedFeature)"
        >
          <ul class="nhCard_header_right_list">
            <li class="eth-close" id="cancel_1" (click)="gotoMylibrary()"><i class="bi bi-x-lg"></i></li>
            <!-- <li
              class="eth-check"
              *ngIf="!isAdvancedFeature"
              id="next_2"
              (click)="goToFilterDesignPage()"
            >
              <i class="bi bi-check2"></i>
            </li>
            <li>
              <button
                class="btn publish"
                *ngIf="!isAdvancedFeature"
                (click)="withoutAdvancedFeatures()"
              >
                {{ labels?.Publish || 'Publish' }}
              </button>
            </li> -->
            <li>
              <button class="btn publish" *ngIf="isAdvancedFeature" (click)="goToFilterDesignPage()">
                {{ labels?.Publish || 'Publish' }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nhCard_maincontent">
      <div class="nhrow">
        <div class="nhrow_col nhrow_middle" [ngClass]="{ nhrow_middle_isAdvancedFeature: isAdvancedFeature }">
          <app-drop-advanced-features
            *ngIf="isAdvancedFeature"
            [editFilterDesign]="filterDesign"
            [entityData]="entityData"
            (apiVersionDataEmit)="getApiVersion($event)"
            [_selectedEntityData]="selectedEntityData"
            [selectedRoleId]="selectedRoleId"
            [combinationArray]="combinationArray"
            [dropAdvancedFeature]="dropAdvancedFeature"
            [_childGsiList]="childGsiList"
            [urlBindingGsi]="urlBindingGsi"
            [urlBindingAttribute]="urlBindingAttribute"
            [_dropMappedAttributes]="dropMappedDragabbleAttributes"
            (combinationArrayEmit)="combinationArrayEmit($event)"
            (hiddenRuleSetEmit)="hiddenRuleSetEmit($event)"
            (selectedEntityDataEmit)="selectedEntityDataEmit($event)"
            (urlBindingEmit)="urlBindingEmit($event)"
            (advancedFeaturesEmit)="advancedFeaturesEmit($event)"
            (chatBotDataEmit)="chatBotDataEmit($event)"
            [isEntityCard]="true"
          >
          </app-drop-advanced-features>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #myDialog>
  <div mat-dialog-title class="modal-header">
    <div class="header-actions d-flex justify-content-between align-items-center nh-mb-6">
      <h5>{{ labels?.Choose_Card }}</h5>
      <div class="modal-actions">
        <mat-dialog-actions class="entity-config-btns">
          <button
            class="btn btn-next"
            [disabled]="!(temporaryTemplate || expandType)"
            (click)="dialog?.closeAll(); selectedTemplate()"
          >
            {{ labels?.Next }}
          </button>
          <button class="btn btn-cancel" (click)="dialog.closeAll()">{{ labels?.Cancel }}</button>
        </mat-dialog-actions>
        <mat-icon (click)="dialog.closeAll()" style="cursor: pointer;">close</mat-icon>
      </div>
    </div>
    <!-- <div class="card-library d-flex nh-column-gap-16 nh-mb-8 nh-mt-16">
      <button class="card-icon nh-fs-14 nh-fw-500 position-relative nh-radius-8 cursor-pointer nh-lh-17">
        Card Library
      </button>
      <button class="card-icon nh-fs-14 nh-fw-500 position-relative nh-radius-8 cursor-pointer nh-lh-17">Upload</button>
    </div> -->
    <div class="card-library d-flex nh-column-gap-16 nh-mt-16">
      <button
        class="nh-fs-14 nh-fw-500 position-relative nh-radius-8 cursor-pointer nh-lh-17"
        *ngIf="tabSelected != 'expand'"
        (click)="isDynamicTeplates = false; renderDynamicTemplates()"
        [ngClass]="{ active: isDynamicTeplates == false }"
      >
        Static Templates
      </button>
      <button
        class="nh-fs-14 nh-fw-500 position-relative nh-radius-8 cursor-pointer nh-lh-17"
        *ngIf="tabSelected != 'expand'"
        (click)="isDynamicTeplates = true; renderDynamicTemplates()"
        [ngClass]="{ active: isDynamicTeplates == true }"
      >
        Dynamic Templates
      </button>
    </div>
  </div>
  <div mat-dialog-content class="modal-body">
    <div class="modal-entitytabs">
      <div class="entity-tabs">
        <mat-tab-group mat-align-tabs="start" *ngIf="!isGrammer && tabSelected == 'child' && !isDynamicTeplates">
          <mat-tab
            *ngFor="let template of templates"
            [label]="template.name === 'tenant' ? tenantName : template.name"
            aria-label="template_{{ template.name }}"
          >
            <div class="row features 1" id="template_{{ template.name }}" *ngIf="!template.isMulti">
              <div
                class="template-box modal-templateType5"
                id="templateType_{{ templateType.templateNumber }}"
                *ngFor="let templateType of template.template"
                [ngClass]="{
                  'col-4': template.noOfItemsinAcolumn === 3,
                  'col-6': template.noOfItemsinAcolumn === 2,
                  'd-none': !templateType.contentURL
                }"
              >
                <div
                  *ngIf="templateType.contentURL"
                  [ngClass]="
                    temporaryTemplate === templateType.templateNumber ? 'template-box-selected' : 'template-box'
                  "
                  (click)="templateSelection(templateType)"
                >
                  <img class="temp-img" [src]="templateType.contentURL | CDNUrlRewritePipe" alt="general-entity" />
                </div>
              </div>
            </div>
            <mat-tab-group class="multi-attributes" *ngIf="template.isMulti">
              <mat-tab
                *ngFor="let multiTemplate of template.template"
                [label]="multiTemplate.name === 'tenant' ? tenantName : multiTemplate.name"
                aria-label="template_{{ multiTemplate.name }}"
              >
                <div class="row features 2" id="template_{{ multiTemplate.name }}" *ngIf="!multiTemplate.isMulti">
                  <div
                    *ngFor="let templateType of multiTemplate.template"
                    id="templateType_{{ templateType.templateNumber }}"
                    class="template-box modal-templateType5"
                    [ngClass]="{
                      'col-4': multiTemplate.noOfItemsinAcolumn === 3,
                      'col-6': multiTemplate.noOfItemsinAcolumn === 2,
                      'd-none': !templateType.contentURL
                    }"
                  >
                    <div
                      *ngIf="templateType.contentURL"
                      [ngClass]="
                        temporaryTemplate === templateType.templateNumber ? 'template-box-selected' : 'template-box'
                      "
                      (click)="templateSelection(templateType)"
                    >
                      <img class="temp-img" [src]="templateType.contentURL | CDNUrlRewritePipe" alt="general-entity" />
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
        </mat-tab-group>
        <mat-tab-group mat-align-tabs="start" *ngIf="!isGrammer && tabSelected == 'child' && isDynamicTeplates">
          <mat-tab label="Dynamic Templates">
            <div class="row features 3" *ngIf="dynamicTemplates.length > 0">
              <ng-container *ngFor="let template of dynamicTemplates">
                <div class="template-box col-4 modal-templateType5" id="template_{{ template.id }}">
                  <div
                    [ngClass]="temporaryTemplate == template.id ? 'template-box-selected' : 'template-box'"
                    (click)="templateSelection(template, true)"
                    id="template_{{ template.id }}"
                  >
                    <img class="temp-img" [src]="template.templateImage" alt="general-entity" />
                    <h3 class="attr_countt">{{ template.name }}</h3>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row features 4" *ngIf="dynamicTemplates.length === 0">
              <div class="col">No Templates Found</div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <mat-tab-group mat-align-tabs="start" *ngIf="isGrammer && tabSelected == 'child' && !isDynamicTeplates">
          <mat-tab
            *ngFor="let template of templates"
            [label]="template.name === 'tenant' ? tenantName : template.name"
            [disabled]="!template.isGrammer"
            [ngClass]="{ 'd-none': template.isGrammer }"
            aria-label="template_{{ template.name }}"
          >
            <div
              class="row features 5"
              id="template_{{ template.name }}"
              *ngIf="!template.isMulti && template.isGrammer"
            >
              <div
                class="template-box modal-templateType5"
                id="templateType_{{ templateType.templateNumber }}"
                *ngFor="let templateType of template.template"
                [ngClass]="{
                  'col-4': template.noOfItemsinAcolumn === 3,
                  'col-6': template.noOfItemsinAcolumn === 2,
                  'd-none': !templateType.contentURLGrammer
                }"
              >
                <div
                  *ngIf="templateType.contentURLGrammer"
                  id="templateType_{{ templateType.templateNumber }}"
                  [ngClass]="
                    temporaryTemplate === templateType.templateNumber ? 'template-box-selected' : 'template-box'
                  "
                  (click)="templateSelection(templateType)"
                >
                  <img
                    class="temp-img"
                    [src]="templateType.contentURLGrammer | CDNUrlRewritePipe"
                    alt="general-entity"
                  />
                </div>
              </div>
            </div>
            <mat-tab-group class="multi-attributes" *ngIf="template.isMulti">
              <mat-tab
                *ngFor="let multiTemplate of template.template"
                [label]="multiTemplate.name === 'tenant' ? tenantName : multiTemplate.name"
                [ngClass]="{ 'd-none': multiTemplate.isGrammer }"
                aria-label="multiTemplate_{{ multiTemplate.name }}"
              >
                <div
                  class="row features 6"
                  id="template_{{ multiTemplate.name }}"
                  *ngIf="!multiTemplate.isMulti && multiTemplate.isGrammer"
                >
                  <div
                    class="template-box modal-templateType5"
                    id="templateType_{{ templateType.templateNumber }}"
                    *ngFor="let templateType of multiTemplate.template"
                    [ngClass]="{
                      'col-4': multiTemplate.noOfItemsinAcolumn === 3,
                      'col-6': multiTemplate.noOfItemsinAcolumn === 2,
                      'd-none': !templateType.contentURLGrammer
                    }"
                  >
                    <div
                      *ngIf="templateType.contentURLGrammer"
                      [ngClass]="
                        temporaryTemplate === templateType.templateNumber ? 'template-box-selected' : 'template-box'
                      "
                      (click)="templateSelection(templateType)"
                    >
                      <img
                        class="temp-img"
                        [src]="templateType.contentURLGrammer | CDNUrlRewritePipe"
                        alt="general-entity"
                      />
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </mat-tab>
        </mat-tab-group>
        <mat-tab-group mat-align-tabs="start" *ngIf="isGrammer && tabSelected == 'child' && isDynamicTeplates">
          <mat-tab label="Dynamic Templates">
            <div class="row features 7" *ngIf="dynamicTemplates.length > 0">
              <ng-container *ngFor="let template of dynamicTemplates">
                <div class="template-box col-4 modal-templateType5">
                  <div
                    id="template_{{ template.id }}"
                    [ngClass]="temporaryTemplate === template.id ? 'template-box-selected' : 'template-box'"
                    (click)="templateSelection(template, true)"
                  >
                    <img class="temp-img" [src]="template.templateImage" alt="general-entity" />
                    <h3 class="attr_countt">{{ template.name }}</h3>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row features" *ngIf="dynamicTemplates.length === 0">
              <div class="col">No Templates Found</div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <mat-tab-group
          mat-align-tabs="start"
          (selectedTabChange)="customizeTabChange($event)"
          *ngIf="tabSelected == 'expand'"
        >
          <mat-tab
            *ngFor="let template of expandTemplates"
            [label]="template.name === 'tenant' ? tenantName : template.name"
            [ngClass]="{ 'd-none': template.isGrammer }"
            aria-label="template_{{ template.name }}"
          >
            <div class="row features 7" id="template_{{ template.name }}">
              <div
                class="template-box modal-templateType5"
                id="templateType_{{ templateType.templateNumber }}"
                *ngFor="let templateType of template.template"
                [ngClass]="{
                  'col-4': template.noOfItemsinAcolumn === 3,
                  'col-6': template.noOfItemsinAcolumn === 2,
                  'col-12': template.noOfItemsinAcolumn === 1
                }"
              >
                <div
                  [ngClass]="
                    temporaryTemplate === templateType.templateNumber ? 'template-box-selected' : 'template-box'
                  "
                  (click)="templateSelection(templateType)"
                >
                  <img
                    class="temp-img"
                    [src]="templateType.contentURL | CDNUrlRewritePipe"
                    alt="general-entity"
                  />
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Dynamic Templates">
            <ng-template mat-tab-label>
              <span id="Dynamic Templates">Dynamic Templates</span>
            </ng-template>
            <div class="features row" *ngIf="dynamicTemplates.length > 0">
              <ng-container *ngFor="let template of dynamicTemplates">
                <div class="template-box col-4 modal-templateType5">
                  <div
                    [ngClass]="temporaryTemplate === template.id ? 'template-box-selected' : 'template-box'"
                    (click)="templateSelection(template, true)"
                    [id]="'Template-' + template.id"
                  >
                    <img class="temp-img" [src]="template.templateImage" alt="general-entity" />
                    <h3 class="attr_countt">{{ template.name }}</h3>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row" *ngIf="dynamicTemplates.length === 0">
              <div class="col">No Templates Found</div>
            </div>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #expandDialog>
  <div class="dialog-content">
    <h1 mat-dialog-title class="dialog-title">{{ labels?.Do_you_want_to_add_expand_card }}</h1>
    <div class="action-buttons">
      <div class="btn btn-dialog btn-yes" id="clickyes1" (click)="showExpand()" matDialogClose>{{ labels?.Yes }}</div>
      <div class="btn btn-dialog btn-no" id="clickno1" (click)="closeDialog()">{{ labels?.No }}</div>
    </div>
  </div>
</ng-template>
<ng-template #cardNameDialog>
  <div class="title">Enter Card Name</div>
  <div class="content">
    <p class="name">Enter Name</p>
    <input placeholder="Write Filter Name" type="text" name="name" [(ngModel)]="cardConfigName" />
  </div>
  <div class="btns">
    <button (click)="closeDialog()">{{ labels?.Cancel }}</button>
    <button (click)="closeCardNameDialog()">{{ labels?.Save }}</button>
  </div>
</ng-template>
<router-outlet></router-outlet>
<app-loader></app-loader>
<app-toaster></app-toaster>
