import { Injectable } from '@angular/core';
import { EventsEndpointService } from './events-endpoint.service';
import { Subject, BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Validators, ValidationErrors, FormControl } from '@angular/forms';
import { FolderData } from '../../lib/modal/Folder';
import { FieldConfig } from '../modal/field.interface';
@Injectable({
  providedIn: 'root',
})
export class EventsFacadeService {
  currentEntInd: any;
  currentCuId: number;
  mainEntityIndex: number = 0;
  tablerow = 0;
  tempArray: any = [];
  scormStatus = new BehaviorSubject('0');
  scormStatus$ = this.scormStatus.asObservable();
  public scormScore = new BehaviorSubject('');
  scormScore$ = this.scormScore.asObservable();
  dependentDropdownResponse = new Subject();
  dependentDropdownResponse$ = this.dependentDropdownResponse.asObservable();
  // ELastic Search - FE757
  public typeAHeadResponse = new Subject();
  typeAHeadResponse$ = this.typeAHeadResponse.asObservable();
  private foldersData: Subject<FolderData> = new Subject<FolderData>();
  foldersData$: Observable<FolderData> = this.foldersData.asObservable();

  private usersData: Subject<any> = new Subject<any>();
  usersData$: Observable<any> = this.usersData.asObservable();

  private rolesData: Subject<any> = new Subject<any>();
  rolesData$: Observable<any> = this.rolesData.asObservable();

  submitEventSubject = new Subject<any>();
  submitEventSubject$ = this.submitEventSubject.asObservable();

  hideSubmitButton = new Subject<any>();
  hideSubmitButton$ = this.hideSubmitButton.asObservable();

  typeAHeadEvent = new Subject();
  typeAHeadEvent$ = this.typeAHeadEvent.asObservable();

  cuEvents = new Subject();
  ngUnsubscribe = new Subject();
  eventTrigger = new Subject();
  eventTrigger$ = this.eventTrigger.asObservable();

  pdfTextCord = new Subject();
  pdfTextCord$ = this.pdfTextCord.asObservable();

  updateLabelColor = new Subject();
  updateLabelColor$ = this.updateLabelColor.asObservable();

  storeLayoutConfigData = new BehaviorSubject('');
  storeLayoutConfigData$ = this.storeLayoutConfigData.asObservable();

  eventApiFailedSubject = new BehaviorSubject(false);
  eventApiFailed$ = this.eventApiFailedSubject.asObservable();

  eventTriggerCondition = new Subject();
  cuEventsResponse$ = this.cuEvents.asObservable();
  triggeredEvents: any[] = [];
  selectedCuData: any;
  gsiName: any;
  delegationId: any;
  formData: any;
  entityList: any;
  accordianIndex: any = -1;
  targetEnt: any = [];
  tableScrollEvent = new Subject();
  dateMap = new Map();
  tableScrollEvent$ = this.tableScrollEvent.asObservable();
  tableScrollPosition: any = [];
  disableContextualSearchSubejct: Subject<boolean> = new Subject<boolean>();
  disbaleContextualSearch$ = this.disableContextualSearchSubejct.asObservable();

  disableEntitRef: Subject<boolean> = new Subject<boolean>();
  disableEntityRef$ = this.disableEntitRef.asObservable();

  eventResponseData = new Subject();
  eventResponseData$ = this.eventResponseData.asObservable();

  isPhysicalLayerVideo = new Subject();
  isPhysicalLayerVideo$ = this.isPhysicalLayerVideo.asObservable();
  attributeCount: any;
  attrData: any;
  previousAttrData: any;
  typeAheadEventType: string;
  typeAheadOptionsData: Subject<any[]> = new Subject<any[]>();
  typeAheadOptionsData$ = this.typeAheadOptionsData.asObservable();
  typeAheadPageData: { pageNumber: number; pageSize: number } = { pageNumber: 0, pageSize: 100 };

  triggerSlotExecution = new Subject<Boolean>();
  triggerSlotExecution$ = this.triggerSlotExecution.asObservable();

  constructor(private eventEndpointService: EventsEndpointService, private loader: LoaderService) { }

  /**
   * Sets delegation id for the update delegation screen
   * @param value
   */
  setDelegationId(value: any) {
    this.delegationId = value;
  }

  /**
   * Gets delegation id of the delegation that needs to be updated.
   * @returns
   */
  getDelegationId() {
    return this.delegationId;
  }

  changeUnitEvents(data: any, cuId: any, entityDetails: any, layers: any, originalData?: any) {
    this.loader.show();
    this.tableScrollEvent.next();
    /*istanbul ignore next*/
    originalData = originalData ? originalData : layers;
    originalData.forEach((data: any) => {
      this.entityAttributesOrder(data.entityList);
    });
    this.eventEndpointService
      .setChangeUnitEvents(data, cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          let index: any;
          /*istanbul ignore next*/
          if (entityDetails?.isInfo) {
            index = originalData?.findIndex((i: any) => i.type == 'information');
          } else {
            index = originalData?.findIndex((i: any) => i.type == 'physical');
          }
          /*istanbul ignore next*/
          if (res?.result?.transId && res?.result?.transId !== data?.transId) {
          } else if (index !== -1 && (entityDetails?.isTableConfig || entityDetails?.isMulti)) {
            this.findTargetEnt(res.result.currentCU.layers[index], entityDetails.entityName, index);
            let multiRecords = false;
            /* istanbul ignore next */
            if (this.targetEnt.length > 1) {
              multiRecords = true;
            }
            if (entityDetails?.isTableConfig) {
              let attributeIndex: any = parseInt(entityDetails?.attrName?.split('$')[1]);
              let indexNumber = Math.floor(attributeIndex / this.targetEnt[0]?.nslAttributes.length);
              this.tablerow = 0;
              let i = 0;
              for (let i = 0; i < originalData[index]?.entityList?.length; i++) {
                let entity = originalData[index]?.entityList[i];
                if (entity.name == entityDetails.entityName && this.tablerow == indexNumber) {
                  originalData[index].entityList[i] = this.targetEnt;
                  originalData[index].entityList = originalData[index].entityList.flat();
                  break;
                } else if (entity.name == entityDetails.entityName) {
                  this.tablerow = this.tablerow + 1;
                } else {
                  entity.nslAttributes?.forEach((attribute: any, ind: number) => {
                    if (attribute.type == 'entity') {
                      this.replaceTargetEntityTable(attribute, entityDetails.entityName, ind, entity, indexNumber);
                    }
                  });
                }
              }
            } else {
              originalData[index].entityList.forEach((entity: any, i: number) => {
                if (entity.name == entityDetails.entityName) {
                  originalData[index].entityList[i] = this.targetEnt;
                  originalData[index].entityList = originalData[index].entityList.flat();
                  return;
                } else {
                  entity.nslAttributes?.forEach((attribute: any, ind: number) => {
                    if (attribute.type == 'entity') {
                      this.replaceTargetEntity(attribute, entityDetails.entityName, ind, entity);
                    }
                  });
                }
              });
            }
            this.targetEnt = [];
            this.findTargetEnt(originalData[index], entityDetails.entityName, index);
            /* istanbul ignore next */
            if (!multiRecords) {
              res.result.currentCU.layers[index].entityList.forEach((entity: any, i: number) => {
                if (entity.name == entityDetails.entityName.split('$')[0]) {
                  res.result.currentCU.layers[index].entityList[i] = this.targetEnt;
                  res.result.currentCU.layers[index].entityList = res.result.currentCU.layers[index].entityList.flat();
                  return;
                } else {
                  entity.nslAttributes?.forEach((attribute: any, ind: number) => {
                    if (attribute.type == 'entity') {
                      this.replaceTargetEntity(attribute, entityDetails.entityName.split('$')[0], ind, entity);
                    }
                  });
                }
              });
            } else {
              res.result.currentCU.layers[index].entityList = originalData[index].entityList;
            }
            this.targetEnt = [];
            multiRecords = false;
          }
          /* istanbul ignore next */
          if (entityDetails?.eventType === 'ON_SELECT') {
            this.eventResponseData.next({ res: res, entityDetails: entityDetails });
          } else {
            this.cuEvents.next({ res: res, entityDetails: entityDetails });
          }
        },
        (error) => {
          this.eventApiFailedSubject.next(true);
        }
      );
  }

  entityAttributesOrder(changedFormValues: any) {
    changedFormValues.sort((x: any, y: any) => {
      return x.attr_order_num - y.attr_order_num;
    });
    /* istanbul ignore next */
    changedFormValues?.forEach((ent: any) => {
      ent?.nslAttributes?.sort((x: any, y: any) => {
        return x.attr_order_num - y.attr_order_num;
      });
      /* istanbul ignore next */
      ent?.nslAttributes?.forEach((attr: any) => {
        if (attr.type == 'entity') {
          this.nestedEntityAttributesOrder(attr);
        }
      });
    });
  }

  /**set the original order  for nested*/
  nestedEntityAttributesOrder(ent: any) {
    ent?.nslAttributes?.sort((x: any, y: any) => {
      return x.attr_order_num - y.attr_order_num;
    });
    /* istanbul ignore next */
    ent?.nslAttributes?.forEach((attribute: any) => {
      if (attribute.type == 'entity') {
        this.nestedEntityAttributesOrder(attribute);
      }
    });
  }

  findTargetEnt(res: any, entityName: any, index: number) {
    res.entityList.forEach((ent: any) => {
      if (ent.name.split('$')[0] == entityName.split('$')[0]) {
        this.targetEnt.push(ent);
      }
      /* istanbul ignore next */
      if (this.targetEnt.length == 0) {
        ent.nslAttributes?.forEach((attribute: any) => {
          if (attribute.type == 'entity') {
            this.findNestedTargetEnt(attribute, entityName, index);
          }
        });
      }
    });
  }

  findNestedTargetEnt(res: any, entityName: any, index: number) {
    /* istanbul ignore else */
    if (res.name.split('$')[0] == entityName.split('$')[0]) {
      this.targetEnt.push(res);
    }
    /* istanbul ignore next */
    if (this.targetEnt.length == 0) {
      res.nslAttributes?.forEach((attribute: any) => {
        if (attribute.type == 'entity') {
          this.findNestedTargetEnt(attribute, entityName, index);
        }
      });
    }
  }

  replaceTargetEntity(res: any, entityName: any, index: number, entity: any) {
    /* istanbul ignore else */
    if (res.name == entityName) {
      entity.nslAttributes[index] = this.targetEnt;
      entity.nslAttributes = entity.nslAttributes.flat();
      return;
    } else {
      res.nslAttributes?.forEach((attribute: any, inde: any) => {
        if (attribute.type == 'entity') {
          this.replaceTargetEntity(attribute, entityName, inde, res);
        }
      });
    }
  }

  replaceTargetEntityTable(res: any, entityName: any, index: number, entity: any, indexNumber: number) {
    /* istanbul ignore else */
    if (res.name == entityName && this.tablerow == indexNumber) {
      entity.nslAttributes[index] = this.targetEnt;
      entity.nslAttributes = entity.nslAttributes.flat();
      this.tablerow = this.tablerow + 1;
      return;
    } else if (res.name == entityName) {
      this.tablerow = this.tablerow + 1;
    } else {
      res.nslAttributes?.forEach((attribute: any, inde: any) => {
        if (attribute.type == 'entity') {
          this.replaceTargetEntity(attribute, entityName, inde, res);
        }
      });
    }
  }

  getDependentDropdownDetails(
    payload: any,
    eventType: string,
    changeDriver: any,
    entityDetails: any,
    pageNo?: any,
    pageSize?: any,
    changeDriverValue?: any
  ) {
    this.loader.show();
    this.tableScrollEvent.next();
    this.eventEndpointService
      .getDependentDropdownDetails(payload, eventType, changeDriver, pageNo, pageSize, changeDriverValue)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dependentDropdownResponse.next({ res: res, entityDetails: entityDetails });
      });
  }

  /** Get the target entity from nested entities*/
  getNestedEntityData(ent: any, entityDetails: any, data: any) {
    /* istanbul ignore next */
    ent.nslAttributes?.every((val: any, ind: any) => {
      if (val.type == 'entity') {
        /* istanbul ignore next */
        if (val.name.split('$')[0] == entityDetails.entityName.split('$')[0]) {
          ent.nslAttributes.forEach((attr: any, nestedAttrIndex: number) => {
            if (attr.type == 'entity') {
              attr.nslAttributes.forEach((attribute: any) => {
                if (attribute.name + '$' + attribute.attr_order_num === entityDetails.attrName) {
                  this.tempArray.push(nestedAttrIndex);
                  this.currentEntInd = nestedAttrIndex;
                  // this.attributeCount = data.nslAttributes[nestedAttrIndex].nslAttributes.length
                }
              });
            }
          });
          return false;
        } else {
          this.getNestedEntityData(val, entityDetails, data);
        }
      }
      return true;
    });
  }

  updateScormData(data: any) {
    this.scormStatus.next(data);
  }
  updateScormScore(data: any) {
    this.scormScore.next(data);
  }
  /* conditional potentiality start  */
  setEvent(data: any) {
    this.eventTrigger.next(data);
  }

  isPhysicalLayerVideos(isPhysicalLayerVideo: boolean) {
    this.isPhysicalLayerVideo.next(isPhysicalLayerVideo);
  }

  getEvent() {
    return this.eventTrigger.asObservable();
  }
  /* conditional potentiality ends  */

  setTriggerEvent(data: any) {
    this.eventTriggerCondition.next(data);
  }

  getTriggerEvent() {
    return this.eventTriggerCondition.asObservable();
  }
  getDisablePropertiesList(propertyName: any) {
    let propertiesArr: any = [];
    switch (propertyName) {
      case 'notAfterDate':
        propertiesArr = ['notAfterDays'];
        return propertiesArr;

      case 'notAfterDays':
        propertiesArr = ['notAfterDate'];
        return propertiesArr;

      case 'notBeforeDate':
        propertiesArr = ['notBeforeDays'];
        return propertiesArr;

      case 'notBeforeDays':
        propertiesArr = ['notBeforeDate'];
        return propertiesArr;
    }
  }
  getDisableValidatorsList(constraintName: any) {
    let constraintsArr: any = [];
    switch (constraintName) {
      case 'positive':
        constraintsArr = ['negative', 'positiveorzero', 'zero', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'notzero':
        constraintsArr = ['positive', 'positiveorzero', 'zero', 'min', 'max', 'negativeorzero'];

        return constraintsArr;

      case 'zero':
        constraintsArr = ['positive', 'positiveorzero', 'negative', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'min':
      case 'max':
        constraintsArr = ['negativeorzero', 'positive', 'positiveorzero', 'zero', 'notzero'];
        return constraintsArr;
      case 'negativeorzero':
      case 'negative':
        constraintsArr = ['positive', 'positiveorzero', 'zero', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'asserttrue':
      case 'required':
      case 'assertfalse':
        constraintsArr = [];
        return constraintsArr;

      case 'notempty':
        constraintsArr = ['empty'];
        return constraintsArr;

      case 'empty':
        constraintsArr = ['length'];
        return constraintsArr;

      case 'length':
        constraintsArr = ['notempty'];
        return constraintsArr;

      case 'positiveorzero':
        constraintsArr = ['negative', 'positive', 'zero', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'pastorpresent':
        constraintsArr = ['present', 'pastorfuture', 'presentorfuture', 'past'];
        return constraintsArr;
      case 'present':
        constraintsArr = ['past', 'pastorfuture', 'presentorfuture', 'pastorpresent'];
        return constraintsArr;
      case 'past':
      case 'future':
        constraintsArr = ['present', 'pastorfuture', 'presentorfuture', 'pastorpresent'];
        return constraintsArr;
      case 'presentorfuture':
        constraintsArr = ['present', 'pastorfuture', 'past', 'pastorpresent'];
        return constraintsArr;
      case 'pastorfuture':
        constraintsArr = ['present', 'past', 'future', 'presentorfuture', 'pastorpresent'];
        return constraintsArr;
    }
  }

  clearOnLoadEvents() {
    this.triggeredEvents = [];
  }

  getValidations(constraints: any, uielement: any = null) {
    let validationsArray: any = [];
    /*istanbul ignore next*/
    constraints?.forEach((obj: any) => {
      if (this.addValidations(obj, uielement)) {
        validationsArray.push(this.addValidations(obj, uielement));
      }
    });
    return validationsArray;
  }

  addValidations(obj: any, uielement: any) {
    let regx: any;
    let validObject: any;
    switch (obj.name) {
      case 'positive':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'positive',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            type: obj.properties.type,
            value : 0,
          };
        } else {
          validObject = {
            name: 'positive',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
            value : 0,
          };
        }
        break;
      case 'notzero':
        regx = '^((?!0+$)[0-9.]*$)|-[1-9]+[0-9.]*$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'notzero',
            validator: Validators.pattern(regx),
            message: obj.properties.errorMessage,
            requiredPattern: regx,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'notzero',
            message: obj.properties.errorMessage,
            requiredPattern: regx,
            type: obj.properties.type,
          };
        }
        break;
      case 'zero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'zero',
            validator: Validators.pattern('^[0-0]+[0-0]*$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-0]+[0-0]*$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'zero',
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-0]+[0-0]*$',
            type: obj.properties.type,
          };
        }
        break;

      case 'smallerThanOrEqual':
      case 'smallerThan':
      case 'max':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj?.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
            value: obj.properties.value,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
            value: obj.properties.value,
          };
        }
        break;
      case 'valuesSelect':
        regx = '^.{' + obj?.properties?.minimumSelect + ',' + obj?.properties?.maximumSelect + '}$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'valuesSelect',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            min: obj.properties.minimumSelect,
            max: obj.properties.maximumSelect,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'valuesSelect',
            message: obj.properties.errorMessage,
            min: obj.properties.minimumSelect,
            max: obj.properties.maximumSelect,
            type: obj.properties.type,
          };
        }
        break;
      case 'greaterThanOrEqual':
      case 'greaterThan':
      case 'min':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj?.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
            value: obj.properties.value,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
            value: obj.properties.value,
          };
        }
        break;
      case 'negative':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'negative',
            validator: Validators.pattern('^(-0.0*[1-9]|-[1-9]+[0-9.]*|-[0-9]*.[0-9]+)$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(-0.0*[1-9]|-[1-9]+[0-9.]*|-[0-9]*.[0-9]+)$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'negative',
            message: obj.properties.errorMessage,
            requiredPattern: '^(-0.0*[1-9]|-[1-9]+[0-9.]*|-[0-9]*.[0-9]+)$',
            type: obj.properties.type,
          };
        }
        break;

      case 'asserttrue':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'asserttrue',
            validator: Validators.pattern('^(true|1)$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(true|1)$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'asserttrue',
            message: obj.properties.errorMessage,
            requiredPattern: '^(true|1)$',
            type: obj.properties.type,
          };
        }
        break;

      case 'assertfalse':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'assertfalse',
            validator: Validators.pattern('^(false|0)$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(false|0)$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'assertfalse',
            message: obj.properties.errorMessage,
            requiredPattern: '^(false|0)$',
            type: obj.properties.type,
          };
        }
        break;

      case 'notempty':
        /*istanbul ignore next*/
        if (uielement == 'textarea' && (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN')) {
          validObject = {
            name: 'notempty',
            validator: this.checkNotemptyValidation,
            message: obj.properties.errorMessage,
            requiredPattern: '',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'notempty',
            message: obj.properties.errorMessage,
            requiredPattern: '',
            type: obj.properties.type,
          };
        }
        break;
      case 'afterValidation':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        }
        break;
      case 'onorafterValidation':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        }
        break;
      case 'beforeValidation':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        }
        break;
      case 'onorbeforeValidation':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        }
        break;
      case 'empty':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'empty',
            validator: Validators.pattern('^/^s*$/g$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^/^s*$/g$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'empty',
            message: obj.properties.errorMessage,
            requiredPattern: '^/^s*$/g$',
            type: obj.properties.type,
          };
        }
        break;

      case 'length':
        let lenpattern = '^.{' + obj.properties.value + '}$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'length',
            validator: Validators.pattern(lenpattern), //Validators.maxLength(Number(obj.properties.value)), //Validators.pattern(lenpattern),
            message: obj.properties.errorMessage,
            requiredPattern: lenpattern,
            value: obj.properties.value,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'length',
            message: obj.properties.errorMessage,
            requiredPattern: lenpattern,
            type: obj.properties.type,
          };
        }
        break;
      case 'equalTo':
        regx = '^' + obj.properties.value + '$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'equalTo',
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            type: obj.properties.type,
            validator: obj.properties.dependentExpression ? Validators.required : Validators.pattern(regx),
            dependentExpression: obj.properties.dependentExpression,
          };
        } else {
          validObject = {
            name: 'equalTo',
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            type: obj.properties.type,
            ...(!obj.properties.dependentExpression && { requiredPattern: regx }),
            dependentExpression: obj.properties.dependentExpression,
          };
        }
        break;
      case 'contains':
        regx = '^.*' + obj?.properties?.contains + '.*$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'contains',
            validator: Validators.pattern(regx),
            message: obj.properties.errorMessage,
            value: obj.properties.contains,
            requiredPattern: regx,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'contains',
            message: obj.properties.errorMessage,
            value: obj.properties.contains,
            requiredPattern: regx,
            type: obj.properties.type,
          };
        }
        break;
      case 'notequalTo':
        regx = '^(?!' + obj.properties.value + '$).*$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'notequalTo',
            validator: obj.properties.dependentExpression ? Validators.required : Validators.pattern(regx),
            message: obj.properties.errorMessage,
            requiredPattern: regx,
            type: obj.properties.type,
            value: obj.properties.value,
            dependentExpression: obj.properties.dependentExpression,
          };
        } else {
          validObject = {
            name: 'notequalTo',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
            value: obj.properties.value,
            ...(!obj.properties.dependentExpression && { requiredPattern: regx }),
            dependentExpression: obj.properties.dependentExpression,
          };
        }
        break;
      case 'startWith':
        let startspattern = '^' + obj.properties.startWith + '.{0,}$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'startWith',
            validator: Validators.pattern(startspattern),
            message: obj.properties.errorMessage,
            requiredPattern: startspattern,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'startWith',
            message: obj.properties.errorMessage,
            requiredPattern: startspattern,
            type: obj.properties.type,
          };
        }
        break;

      case 'endsWith':
        let endspattern = '^.{0,}' + obj.properties.endsWith + '$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'endsWith',
            validator: Validators.pattern(endspattern),
            message: obj.properties.errorMessage,
            requiredPattern: endspattern,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'endsWith',
            message: obj.properties.errorMessage,
            requiredPattern: endspattern,
            type: obj.properties.type,
          };
        }
        break;

      case 'pattern':
        /*istanbul ignore next*/
        if (uielement == 'textarea' || uielement == 'text' || uielement == 'checkbox' || uielement == 'encrypttext' || uielement == 'time' || uielement == 'BarCodeScanner' || uielement == 'mobilenumber') {
          if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
            validObject = {
              name: 'pattern',
              validator: Validators.pattern('^' + obj.properties.regExp + '$'),
              message: obj.properties.errorMessage,
              requiredPattern: '^' + obj.properties.regExp + '$',
              type: obj.properties.type,
            };
          } else {
            validObject = {
              name: 'pattern',
              message: obj.properties.errorMessage,
              requiredPattern: '^' + obj.properties.regExp,
              type: obj.properties.type,
            };
          }
        }
        break;
      case 'required':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'required',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'required',
            requiredPattern: '^(?!^ +$)^.+$',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        }
        break;

      case 'negativeorzero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'negativeorzero',
            validator: Validators.pattern('^(-(d)*)|0$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(-(d)*)|0$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'negativeorzero',
            message: obj.properties.errorMessage,
            requiredPattern: '^(-(d)*)|0$',
            type: obj.properties.type,
          };
        }
        break;

      case 'positiveorzero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'positiveorzero',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            type: obj.properties.type,
            value : 0,
          };
        } else {
          validObject = {
            name: 'positiveorzero',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
            value : 0,
          };
        }
        break;

      case 'pastorpresent':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'pastorpresent',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'pastorpresent',
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'present':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'present',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'present',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        }
        break;
      case 'past':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'past',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'past',
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'future':
        // pattern('^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)Z$')
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'future',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'future',
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'presentorfuture':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'presentorfuture',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'presentorfuture',
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'pastorfuture':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'pastorfuture',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'pastorfuture',
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'filenamelengthtype':
        validObject = {
          name: 'filenamelengthtype',
          message: obj.properties.errorMessage,
          maxLength: obj.properties.maxLength,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'maxfilesizetype':
        validObject = {
          name: 'maxfilesizetype',
          message: obj.properties.errorMessage,
          value: obj.properties.value,
          unit: obj.properties.unit,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'minfilesizetype':
        validObject = {
          name: 'minfilesizetype',
          message: obj.properties.errorMessage,
          value: obj.properties.value,
          unit: obj.properties.unit,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'assertextensiontype':
        validObject = {
          name: 'assertextensiontype',
          message: obj.properties.errorMessage,
          extension: obj.properties.extension,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'assertnotextensiontype':
        validObject = {
          name: 'assertnotextensiontype',
          message: obj.properties.errorMessage,
          extension: obj.properties.extension,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'filenamepatterntype':
        /*istanbul ignore next*/
        validObject = {
          name: 'filenamepatterntype',
          message: obj.properties.errorMessage,
          requiredPattern: obj.properties.regExp,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
    }
    return validObject;
  }

  checkNotemptyValidation(control: FormControl) {
    /*istanbul ignore next*/
    let text = control?.value;
    /*istanbul ignore next*/
    if (!text?.replace(/\s/g, '').length) {
      return true;
    }
    return false;
  }

  getExactErrorMessage(field: FieldConfig, validation: any, group: any) {
    let isError;
    /*istanbul ignore next*/
    const controlErrors: ValidationErrors = group?.controls[field.attribute?.name]?.errors;
    let keyName;
    /*istanbul ignore next*/
    if (controlErrors != null) {
      Object.keys(controlErrors)?.forEach((keyError) => {
        keyName = keyError;
      });
      if (keyName == 'pattern') {
        isError = controlErrors.pattern?.requiredPattern === validation.requiredPattern ? true : false;
      } else if (keyName == 'incorrect') {
        isError = true;
      } else {
        isError = validation?.name === keyName ? true : false;
      }
    }

    return isError;
  }

  // Elastic Search - FE757
  // Fetch contextual search data for attribute at transaction side
  // (i.e. values for typeahead attribute)
  postContextualSearchData(data: any) {
    this.loader.show();
    this.eventEndpointService
      .fetchSearchData(data, this.typeAheadPageData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.typeAHeadResponse.next(res);
      });
  }
  // passing event to transaction
  addTypeAheadEvent(eventData: any) {
    this.typeAHeadEvent.next(eventData);
  }

  updateTypeAheadPageNumber(pageNumber: number) {
    this.typeAheadPageData.pageNumber = pageNumber;
  }

  increaseTypeAheadPageNumber() {
    this.typeAheadPageData.pageNumber += 1;
  }
  //toggle disable Contextual Search Configuration
  disableContextualSearchConfiguration(val: boolean) {
    this.disableContextualSearchSubejct.next(val);
  }
  // toggle disable Entity Reference
  disableEntityRefRadio(val: boolean) {
    this.disableEntitRef.next(val);
  }
  getFolders(folderNames: string) {
    this.eventEndpointService.getFolders(folderNames).subscribe((res: FolderData) => {
      this.foldersData.next(res);
    });
  }
  getUsers(searchString?: string, pageNumber?: number): void {
    this.eventEndpointService.getUsers(searchString ? searchString : '', pageNumber).subscribe((res: any) => {
      this.usersData.next(res?.usersList ? res.usersList : res);
    });
  }

  getRoles(searchString?: string): void {
    this.eventEndpointService.getRoles(searchString ? searchString : '').subscribe((res: any) => {
      this.rolesData.next(res);
    });
  }
  /*istanbul ignore next*/
  getPdfTextCord(data: any) {
    // const res1 = {
    //   coordinates: { height: 24, width: 209, x: 802, y: 201 },
    //   page_no: '1',
    //   ref_dimensions: { height: 2275, width: 1758 },
    // };
    // this.pdfTextCord.next(res1);

    this.eventEndpointService
      .getpdfTextCord(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.pdfTextCord.next(res);
      });
  }
  /* istanbul ignore next */
  storeLayoutConfig(data: any) {
    this.storeLayoutConfigData.next(data);
  }
  /*istanbul ignore next*/
  updateSelctedAttrLabel(data: any) {
    this.updateLabelColor.next(data);
  }
  onSubmitEvent(data: any) {
    this.submitEventSubject.next(data);
  }

  getEventExpressionDetails(payload: any, eventType: string, changeDriver: any, id: any, entityDetails?: any) {
    this.loader.show();
    this.tableScrollEvent.next();
    this.eventEndpointService
      .getEventExpressionDetails(payload, eventType, changeDriver, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dependentDropdownResponse.next({ res: res, entityDetails: entityDetails, expressionEvent: true });
      });
  }

  convertDateFormat(inputFormat: string): string {
    let outputFormat = inputFormat;
    let dateFormatsForShowForDropdown = [
      { value: 'dd/MM/yyyy', displayValue: 'dd/mm/yy'},
      { value: 'MM/dd/yyyy', displayValue: 'mm/dd/yy'},
      { value: 'yyyy/MM/dd', displayValue: 'yy/mm/dd'},
    ];
    /*istanbul ignore next*/
    let ind = dateFormatsForShowForDropdown.findIndex((ele: any) => ele.value === inputFormat);
    /*istanbul ignore next*/
    if(ind >= 0) {
      outputFormat = dateFormatsForShowForDropdown[ind].displayValue;
    }
    return outputFormat;
  }

  downloadMultipleFiles(files: any) {
    return this.eventEndpointService.downloadMultipleFiles(files);
  }

  /*istanbul ignore next*/
  setFeedBack(data: any) {
    this.eventEndpointService
      .setFeedBack(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        // show toaster
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
