<ng-container *ngFor="let savedSearchQuery of savedSearchQueries; let i = index">
  <h4 class="nh-mr-24 nh-ml-24">
    {{ selectedFilterName?.[i] ? selectedFilterName?.[i] : (primaryEntity?.displayName ?  primaryEntity?.displayName : primaryEntity?.name)}}
  </h4>
  <app-entity-grid-card
    class="col-auto"
    [cardId]="cardId"
    [isShowCardDetails]="false"
    [sharableFilterSearchQuery]="savedSearchQuery"
  >
  </app-entity-grid-card>
</ng-container>
