import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-node-special-feature-query-condition',
  templateUrl: './node-special-feature-query-condition.component.html',
  styleUrls: ['./node-special-feature-query-condition.component.scss'],
})
export class NodeSpecialFeatureQueryConditionComponent implements OnInit {
  allAttributes: any = [];
  operators: any = [];
  @Input() tempGsiObj: any;
  @Input() cuIndex: any;
  @Output() sendQueryCondition = new EventEmitter();
  @Input() OtherInfo: any;
  @Input() changeUnit: any;
  private ngUnsubscribe = new Subject();
  currentlyDisabled: any = [];
  inputTarget: any = [];
  selectTarget: any = [];
  selectSource: any = [];
  selectOperator: any = [];
  allAttributesContextualId: any = [];
  queryObject = {
    attributeName: '', //source contextualName
    queryOperator: '', //query operator
    attributeValue: '', //target ContextualName or constant value
    nslQuery: '', //not needed
    queryCondition: '', //na
    predicate: '', //and or
  };
  triggerCu: any;
  sourceExpand: boolean = false;
  targetExpand: boolean = false;
  queryTargetContextualId: any = [];
  querySourceContextualId: any = [];
  source: boolean = false;
  queryCondition: any = [];
  currentIndex: any;
  andOr: any = [];
  selectPredicate: any = [];
  labels: any;
  constructor(private nodeSpecialFeature: NodeEntityFacadeService, private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.queryCondition.push({
      attributeName: '',
      queryOperator: '',
      attributeValue: '',
      nslQuery: '',
      queryCondition: '',
      predicate: '',
    });
    this.getSpecialFeatureProperties();
     this.getOperators();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getSpecialFeatureProperties() {
    let res = this.OtherInfo;
    if (res) {
      /*istanbul ignore next*/
      let uniqueKey = res?.layer + res?.entityName + res?.attribute?.name;
      if (this.tempGsiObj?.solutionLogic?.[this.cuIndex]?.specialFeatureProperties) {
        const featureProps = this.tempGsiObj?.solutionLogic[this.cuIndex]?.specialFeatureProperties[uniqueKey];
        /*istanbul ignore next*/
        if (featureProps?.props?.queryCondition) {
          let queryDetails = JSON.parse(featureProps.props.queryCondition);
          this.currentIndex = 0;
          for (let data of queryDetails.transEntityRecords[0].txnNslAttribute[0].txnGeneralEntity.transEntityRecords) {
            if (this.currentIndex != 0) {
              this.queryCondition.push({
                attributeName: '',
                queryOperator: '',
                attributeValue: '',
                nslQuery: '',
                queryCondition: '',
                predicate: '',
              });
            }
            this.querySourceContextualId[this.currentIndex] = data?.txnNslAttribute[0]?.values[0];
            this.selectSource[this.currentIndex] = data?.txnNslAttribute[0]?.values[0]?.split('.')[5];
            this.selectOperator[this.currentIndex] = data?.txnNslAttribute[1]?.values[0];
            this.selectPredicate[this.currentIndex] = data?.txnNslAttribute[5]?.values[0];
            if (this.selectPredicate[this.currentIndex] == 'AND') {
              this.andOr[this.currentIndex + 1] = false;
            } else {
              this.andOr[this.currentIndex + 1] = true;
            }
            if (data?.txnNslAttribute[2]?.values[0]?.includes('.')) {
              this.queryTargetContextualId[this.currentIndex] = data.txnNslAttribute[2].values[0];
              this.selectTarget[this.currentIndex] = data?.txnNslAttribute[2]?.values[0]?.split('.')[5];
              this.inputTarget[this.currentIndex] = '';
            } else {
              this.inputTarget[this.currentIndex] = data?.txnNslAttribute[2]?.values[0];
              this.queryTargetContextualId[this.currentIndex] = '';
              this.selectTarget[this.currentIndex] = '';
            }
            this.emitDatatoSpecialfeature();
            this.currentIndex++;
          }
        }
      }
    }
  }

  getOperators() {
    this.nodeSpecialFeature.getEqlOperators();
    this.nodeSpecialFeature.eqlOperator$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res.isOperatorsAvailable) {
        this.operators = [];
        res.operators.forEach((operator: any) => {
          this.operators.push({ value: operator });
        });
      }
    });
  }

  selectTriggerCu(triggerCu: any) {
    this.triggerCu = triggerCu;
  }

  expandSource(index: any) {
    this.sourceExpand = !this.sourceExpand;
    this.targetExpand = false;
    this.source = true;
    this.currentIndex = index;
  }

  expandTarget(index: any) {
    this.targetExpand = !this.targetExpand;
    this.sourceExpand = false;
    this.source = false;
    this.currentIndex = index;
  }

  //for displaying selected attribute in UI
  selectAttribute(attribute: any) {
    if (this.source) {
      this.selectSource[this.currentIndex] = attribute;
    } else {
      this.selectTarget[this.currentIndex] = attribute;
    }
    this.sourceExpand = false;
    this.targetExpand = false;
  }
  // setting contextual Id
  setQueryContextualId(soltIndex: number, entity: any, attribute: any, name: any, triggerCuIndex: any, triggerCu: any) {
    this.selectAttribute(attribute.name);
    this.setQueryContextualName(soltIndex, entity, attribute, name, triggerCuIndex, triggerCu);
  }

  // setting contexual Name
  setQueryContextualName(
    soltIndex: number,
    entity: any,
    attribute: any,
    name: any,
    triggerCuIndex: any,
    triggerCu: any
  ) {
    let dot = '.';
    /*istanbul ignore next*/
    let gsiName = this.tempGsiObj?.gsiName;
    let cuName = triggerCu.name + ':' + triggerCuIndex;
    let layer = 'physical.slot:' + (soltIndex + 1);
    /*istanbul ignore next*/
    let entityName = entity?.name;
    let contextualName = gsiName + dot + cuName + dot + layer + dot + entityName;
    if (attribute != null) {
      let attributeName = attribute.name;
      contextualName = contextualName + dot + attributeName;
    }

    if (this.source) {
      this.querySourceContextualId[this.currentIndex] = contextualName;
    } else {
      this.queryTargetContextualId[this.currentIndex] = contextualName;
    }
    this.sourceExpand = false;
    this.targetExpand = false;
    this.inputTarget[this.currentIndex] = '';
    this.emitDatatoSpecialfeature();
  }

  //emitting data to special features component
  emitDatatoSpecialfeature() {
    if (
      this.querySourceContextualId[this.currentIndex] ||
      this.queryTargetContextualId[this.currentIndex] ||
      this.selectOperator[this.currentIndex] ||
      this.selectPredicate[this.currentIndex] ||
      this.inputTarget[this.currentIndex]
    ) {
      this.queryCondition[this.currentIndex].attributeName = this.querySourceContextualId[this.currentIndex]
        ? this.querySourceContextualId[this.currentIndex]
        : '';
      this.queryCondition[this.currentIndex].queryOperator = this.selectOperator[this.currentIndex]
        ? /*istanbul ignore next*/
          this.selectOperator[this.currentIndex]
        : '';
      this.queryCondition[this.currentIndex].predicate = this.selectPredicate[this.currentIndex]
        ? /*istanbul ignore next*/
          this.selectPredicate[this.currentIndex]
        : '';
      if (this.queryTargetContextualId[this.currentIndex]) {
        this.queryCondition[this.currentIndex].attributeValue = this.queryTargetContextualId[this.currentIndex]
          ? this.queryTargetContextualId[this.currentIndex]
          : '';
      } else {
        this.queryCondition[this.currentIndex].attributeValue = this.inputTarget[this.currentIndex]
          ? this.inputTarget[this.currentIndex]
          : '';
      }
    }
    this.sendQueryCondition.emit({ query: this.queryCondition });
  }

  //adding new query
  addNewQuery() {
    this.queryCondition.push({
      attributeName: '',
      queryOperator: '',
      attributeValue: '',
      nslQuery: '',
      queryCondition: '',
      predicate: '',
    });
    this.selectPredicate[this.queryCondition.length - 2] = 'AND';
    this.andOr[this.queryCondition.length - 1] = false;
    this.emitDatatoSpecialfeature();
  }

  //deleting query
  deleteQuery(index: any) {
    this.queryCondition.splice(index, 1);
    this.selectPredicate.splice(index - 1, 1);
    this.selectSource.splice(index, 1);
    this.selectTarget.splice(index, 1);
    this.selectOperator.splice(index, 1);
    this.inputTarget.splice(index, 1);
    this.sendQueryCondition.emit({ query: this.queryCondition });
  }

  andOrToggle(currentToggle: any, index: any) {
    if (currentToggle == 'AND') {
      this.andOr[index] = false;
    } else {
      this.andOr[index] = true;
    }
    this.currentIndex = index - 1;
    this.selectPredicate[index - 1] = currentToggle;
    this.emitDatatoSpecialfeature();
  }

  clearSelection() {
    if (this.targetExpand) {
      this.queryTargetContextualId[this.currentIndex] = null;
      this.selectTarget[this.currentIndex] = null;
      this.emitDatatoSpecialfeature();
    }
    this.sourceExpand = false;
    this.targetExpand = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
