<mat-radio-group class="add-radio-group" aria-label="Select an option">
  <ul *ngFor="let attr of entity?.nslAttributes; let i = index">
    <li *ngIf="!attr?.nslAttributes">
      <mat-radio-button class="cstm-radio-btn" [value]="attr.name" (click)="triggerAttribute(entity, attr)"
        >{{ attr.name }}
      </mat-radio-button>
    </li>
    <mat-accordion *ngIf="attr?.nslAttributes">
      <mat-expansion-panel id="txnNestedEntityExpan_{{ attr.name }}" class="mat-elevation-z0 cstm-radio">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p [matTooltipPosition]="'above'" matTooltipClass="tool-tip-custom" matTooltip="{{ attr?.name }}">
              {{ attr.name }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="attribute-list attribute-list-li">
          <app-entity-radio-dcd
            [entityIndex]="i"
            [attrName]="entity?.name + '.' + attr?.name"
            [entity]="attr"
            (attributeEmit)="attributeToEmit($event)"
          >
          </app-entity-radio-dcd>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </ul>
</mat-radio-group>
