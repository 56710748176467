import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LibraryFacadeService } from '../library/library-facade.service';

@Component({
  selector: 'app-entity-dcd',
  templateUrl: './entity-dcd.component.html',
  styleUrls: ['./entity-dcd.component.scss'],
})
export class EntityDcdComponent implements OnInit {
  @Input() attr: any;
  @Input() attrName: any;
  @Input() attrId: any;
  @Input() entityIndex: any;
  @Input() entity: any;
  @Input() cuName: any;
  @Input() layerName: any;
  @Output() attributeEmit = new EventEmitter();
  @Output() entityEmit = new EventEmitter();
  expanded: boolean = false;
  nestedAttrName: string;
  nestedAttrId: string;
  attributesList: any = new Array();
  currentCUType: string;
  private ngUnsubscribe = new Subject();

  constructor(private libraryFacadeService: LibraryFacadeService) {
    this.detectChangeOfCUTypeStream();
    this.detectAttributesList();
  }

  ngOnInit(): void {
    this.nestedAttrName = this.attrName;
    this.nestedAttrId = this.attrId;
  }

  // Get current CU type from library facade service
  detectChangeOfCUTypeStream() {
    this.libraryFacadeService.currentCUType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cuType: any) => {
      if (cuType) {
        this.currentCUType = cuType;
      }
    });
  }

  // Get attributes' dcd list for membership criteria for reserved CU
  detectAttributesList() {
    this.libraryFacadeService.attributesList$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.attributesList = res;
      }
    });
  }

  addAttributetoDcd(attr: any) {
    this.attributesList = [];
    const tempAttr = { ...attr };
    /*istanbul ignore next*/
    tempAttr.name = this.nestedAttrName?.replace(this.entity?.name + '.', '');
    tempAttr.id = this.nestedAttrId?.replace(this.entity?.tfId + '.', '');
    this.attributeEmit.emit({
      entityIndex: this.entityIndex,
      entity: this.entity,
      attribute: tempAttr,
    });
  }

  addEntitytoDcd(entity: any) {
    this.attributesList = [];
    const tempEntity = { ...entity };
    tempEntity.name = this.nestedAttrName;
    tempEntity.id = this.nestedAttrId;
    this.entityEmit.emit({ entityIndex: this.entityIndex, entity: tempEntity });
  }

  nestedAttributeEmit(attribute: any) {
    this.attributeEmit.emit(attribute);
  }

  nestedEntityEmit(entity: any) {
    this.entityEmit.emit(entity);
  }
}
