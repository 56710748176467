<div class="right-toggle-sidenav right-toggle-sidenav-ar">
  <div class="register-customer">
    <div class="close-area">
      <button class="material-icons" (click)="closeEntity()">
        {{ labels?.close }}
      </button>
      <label class="status"
        >Status: {{ configurationData?.data?.entityData?.status }} ( Ver
        {{ configurationData?.data?.entityData?.version }} )</label
      >
    </div>
    <div class="heading-area">
      <h2>{{ labels?.Independent_Entity }}</h2>
      <button class="save" (click)="saveconf()">{{labels?.Save}}</button>
    </div>

    <div class="accordain-list">
      <mat-accordion>
        <!-- For Entity Details -->
        <mat-expansion-panel [expanded]="step === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Entity_Details }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field>
            <mat-label>{{ labels?.Entity_name }}</mat-label>
            <input
              matInput
              placeholder="Entity name mentioned earlier"
              [(ngModel)]="configurationData.data.entityData.name"
              disabled
            />
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ labels?.Display_name }}</mat-label>
            <input matInput placeholder="Entity name" [(ngModel)]="configurationData.data.entityData.displayName" />
          </mat-form-field>
        </mat-expansion-panel>

        <!-- For Entity UI control -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.UI_Controls }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- For selecting entity UI control -->
          <div class="w-75">
            <mat-label>{{ labels?.Select_Ui_Control }}</mat-label>
            <mat-form-field appearance="outline">
              <mat-select
                id="entityConfiguration"
                [(ngModel)]="entityConfig.type"
                (selectionChange)="entityConfigChange()"
              >
                <mat-option value="" id="none">{{ labels?.None }}</mat-option>
                <mat-option value="table" id="entityConfigurationTable">{{ labels?.Table }}</mat-option>
                <mat-option value="card" id="entityConfigurationCard">{{ labels?.Card }}</mat-option>
                <mat-option value="cardWithTemplate" id="entityConfigurationCardWithTemplate">
                  {{ labels?.Card_With_Templates }}
                </mat-option>
                <mat-option value="progressBar" id="entityConfigurationProgressBar">
                  {{ labels?.Progress_Bar ? labels.Progress_Bar : 'Progress Bar' }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- For Conditional Potentiality -->
          <div>
            <mat-label class="label-txt">{{ labels?.Enable_Conditional_Potentiality_at_Gsi_and_Book_level }}</mat-label>
            <mat-radio-group fxLayout="column" [(ngModel)]="isConditionalPotentiality">
              <mat-radio-button id="conditionalPotentialityYes" [value]="true">{{ labels?.Yes }}</mat-radio-button>
              <mat-radio-button id="conditionalPotentialityNo" [value]="false">{{ labels?.No }} </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Entity Configuration for Card and Card with template -->
          <div class="ui-controls-content" *ngIf="entityConfig.type != 'table' && entityConfig.type != ''">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ labels?.Display }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- Entity Configuration for Card and Card with template -->
                <div *ngIf="entityConfig.type == 'cardWithTemplate' || entityConfig.type == 'card'">
                  <mat-radio-group fxLayout="column" [(ngModel)]="entityConfig.cardSelection" name="isConfigureAsCard">
                    <mat-radio-button id="configureSingle" class="px-2" value="single">{{
                      labels?.Single_Select
                    }}</mat-radio-button>
                    <mat-radio-button id="configureMulti" class="px-2" value="multiple">{{
                      labels?.Multi_Select
                    }}</mat-radio-button>
                  </mat-radio-group>
                  <div *ngIf="entityConfig.cardSelection == 'multiple'">
                    <form class="example-form d-flex" [formGroup]="cardformGroup" ngNativeValidate>
                      <div class="w-50">
                        <mat-form-field floatLabel="never">
                          <label class="mb-2">{{ labels?.Minimum_Selection }}</label>
                          <input
                            type="number"
                            class=""
                            [min]="1"
                            [max]="cardformGroup.get('maximumselection').value"
                            [readonly]="cardformGroup.get('minimumselectionAll').value"
                            matInput
                            formControlName="minimumselection"
                          />
                        </mat-form-field>
                        <mat-checkbox formControlName="minimumselectionAll">
                          {{ labels?.All }}
                        </mat-checkbox>
                      </div>

                      <div class="w-50">
                        <mat-form-field floatLabel="never">
                          <label class="mb-2">{{ labels?.Maximum_Selection }}</label>
                          <input
                            type="number"
                            class=""
                            [min]="cardformGroup.get('minimumselection').value"
                            [readonly]="cardformGroup.get('minimumselectionAll').value"
                            matInput
                            formControlName="maximumselection"
                          />
                        </mat-form-field>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- Entity Configuration only for Card with template -->
                <div class="mt-3" *ngIf="entityConfig.type == 'cardWithTemplate'">
                  <!-- <app-card-view-control
                    [editObject]="false"
                    [isGrammer]="true"
                    (cardViewEvent)="saveCardViewDetails($event)"
                    (cancelEvent)="closeEntity()"
                    [cardEntityData]="configurationData.data.entityData"
                  ></app-card-view-control> -->
                </div>
                <!-- Entity Configuration for Progress Bar -->
                <div *ngIf="entityConfig.type == 'progressBar'" class="progressbar-config-panel">
                  <div class="title-bar">
                    <h2>{{ labels?.Progress_Bar ? labels.Progress_Bar : 'Progress Bar' }}</h2>
                    <div class="progressbar-actions">
                      <button class="btn done" (click)="saveconf()">{{ labels?.Done }}</button>
                      <!-- <i class="bi bi-x-circle"></i> -->
                    </div>
                  </div>
                  <div class="mb-3">
                    <h3 class="pb-title">{{ labels?.Orientation ? labels.Orientation : 'Orientation' }}</h3>
                    <mat-radio-group
                      fxLayout="column"
                      [(ngModel)]="entityConfig.orientation"
                      name="isConfigureAsProgressBar"
                    >
                      <mat-radio-button id="configureHorizontal" class="" value="horizontal">{{
                        labels?.Horizontal ? labels.Horizontal : 'Horizontal'
                      }}</mat-radio-button>
                      <mat-radio-button id="configureVertical" class="px-2" value="vertical">{{
                        labels?.Vertical ? labels.Vertical : 'Vertical'
                      }}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div class="mb-3">
                    <h3 class="pb-title">{{ labels?.Color ? labels.Color : 'Color' }}</h3>
                    <div class="color-customisation">
                      <ul>
                        <li>
                          <label class="pb-label">{{ labels?.Active ? labels.Active : 'Active' }}</label>
                          <div class="color-items">
                            <input
                              type="text"
                              class="inputwidth"
                              [ngStyle]="{ background: entityConfig.colouring.active, color: 'transparent' }"
                              [(ngModel)]="entityConfig.colouring.active"
                            />
                            <i
                              class="bi bi-eyedropper"
                              (click)="TableColorPickr = !TableColorPickr"
                              [(colorPicker)]="entityConfig.colouring.active"
                            ></i>
                          </div>
                        </li>
                        <li>
                          <label class="pb-label">{{ labels?.Inactive ? labels.Inactive : 'InActive' }}</label>
                          <div class="color-items">
                            <input
                              type="text"
                              class="inputwidth"
                              [ngStyle]="{ background: entityConfig.colouring.Inactive, color: 'transparent' }"
                              [(ngModel)]="entityConfig.colouring.Inactive"
                            />
                            <i
                              class="bi bi-eyedropper"
                              (click)="TableColorPickr = !TableColorPickr"
                              [(colorPicker)]="entityConfig.colouring.Inactive"
                            ></i>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="mb-3">
                    <h3 class="pb-title">{{ labels?.Font ? labels.Font : 'Font' }}</h3>
                    <div class="color-customisation">
                      <ul>
                        <li>
                          <label class="pb-label">{{ labels?.color }}</label>
                          <div class="color-items">
                            <input
                              type="text"
                              class="inputwidth"
                              [ngStyle]="{ background: entityConfig.colouring.color, color: 'transparent' }"
                              [(ngModel)]="entityConfig.colouring.color"
                            />
                            <i
                              class="bi bi-eyedropper"
                              (click)="TableColorPickr = !TableColorPickr"
                              [(colorPicker)]="entityConfig.colouring.color"
                            ></i>
                          </div>
                        </li>
                        <li>
                          <label class="pb-label">{{ labels?.Size ? labels.Size : 'Size' }}</label>
                          <mat-select [(ngModel)]="entityConfig.colouring.size" id="dropdown_size" class="size-input">
                            <div class="select-dropdown-list">
                              <mat-option
                                *ngFor="let option of options"
                                id="dropdownselect_{{ option }}"
                                [value]="option"
                              >
                                {{ option }}
                              </mat-option>
                            </div>
                          </mat-select>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-expansion-panel>

        <!-- To set global entity -->
        <mat-expansion-panel (opened)="fetEntityByEntityId()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Global_GE }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="globalEntity">
            <mat-checkbox
              [(ngModel)]="isGlobalEntity"
              [disabled]="isGlobalEntity"
              [ngClass]="{ 'p-event-none': isGlobalEntity }"
              (click)="openGlobalEntityConfirmation(globalEntityDialog)"
            >
              {{ labels?.Set_as_Global_Entity }}
            </mat-checkbox>
            <mat-checkbox
              [(ngModel)]="setAsMasterData"
              [disabled]="setAsMasterData"
              [ngClass]="{ 'p-event-none': setAsMasterData }"
            >
              {{ labels?.Master_Data }}
            </mat-checkbox>
            <mat-checkbox 
              class="is-auditable-checkbox" 
              [(ngModel)]="isAuditable"
            >
              {{ labels?.Is_Auditable }}
            </mat-checkbox>
            <!-- <mat-checkbox [(ngModel)]="setAsMasterData">
              Master Data
            </mat-checkbox> -->
          </div>
        </mat-expansion-panel>
        <app-entity-primary-key
          [configurationData]="configurationData"
          (updatedUniqueCons)="getUniqueConstraints($event)"
        >
        </app-entity-primary-key>

        <!-- For Advanced settings -->
        <mat-expansion-panel *ngIf="DesignRights?.length > 0 || TxnRights?.length > 0" class="permission-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ labels?.Advanced_settings }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- sub accordian-->
          <mat-accordion>
            <mat-expansion-panel class="permission-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ labels?.Permissions }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="assign-rights">
                <ul>
                  <li class="roles-li" *ngFor="let role of roles; let i = index">
                    <div class="actions-item">
                      <button
                      >
                        {{ role.name }}
                      </button>
                      <div id="cuRoleshovered" class="actions-item-hover">
                        <div class="hover-icons">
                          <button (click)="showRights(i)">
                            <figure>
                              <img [src]="'../../../../../../assets/img/edit-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Edit }}</span>
                          </button>
                          <!-- <button mat-menu-item>
                            <figure>
                              <img [src]="'../../../../../../assets/img/info-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Information }}</span>
                          </button> -->
                          <button mat-menu-item (click)="removeAgent(i)">
                            <figure>
                              <img [src]="'../../../../../../assets/img/delete-icon.svg' | CDNUrlRewritePipe" />
                            </figure>
                            <span>{{ labels?.Delete }} </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="selectedindex == i" class="read-right-actions">
                      <div>
                        <label>{{ labels?.Solution_Design }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                DesignRights &&
                                DesignRights.length > 0 &&
                                DesignRights[getDesignRightsIndex(role)] &&
                                DesignRights[getDesignRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                DesignRights &&
                                DesignRights.length > 0 &&
                                DesignRights[getDesignRightsIndex(role)] &&
                                DesignRights[getDesignRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(DesignRights[getDesignRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <label>{{ labels?.Transaction }}</label>
                        <ul class="sub-assign customCheck">
                          <li>
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].informationRight
                              "
                              >{{ labels?.Read }}</mat-checkbox
                            >
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].decisionRight
                              "
                              (change)="autoCheckRights(TxnRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Write }}
                            </mat-checkbox>
                            <mat-checkbox
                              [(ngModel)]="
                                TxnRights &&
                                TxnRights.length > 0 &&
                                TxnRights[getTxnRightsIndex(role)] &&
                                TxnRights[getTxnRightsIndex(role)].executionRight
                              "
                              (change)="autoCheckRights(TxnRights[getTxnRightsIndex(role)])"
                              >{{ labels?.Execute }}
                            </mat-checkbox>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-expansion-panel>

        <ng-template #dialog>
          <div class="primary-object">
            <div class="heading">
              <h2>{{labels?.Select_Attribute}}</h2>
            </div>
            <div class="hl"></div>
            <span class="cancel-icon" (click)="closeDialog()"> <i class="bi bi-x-lg"></i></span>
            <!-- <div class="list-display">
              <div class="boxes">
                <div *ngFor="let attr of attributesList; let i = index">
                  <div *ngIf="attr.visible" class="box" >
                    <span>{{ attr.name }}</span>
                    <mat-icon
                    (click)="selectPrimaryAttribute({checked: true}, i)"
                    >add</mat-icon>
                  </div>

                </div>
              </div>

              <div class="boxes">
                <div class="box" *ngFor="let key of uniqueKey; let i = index">
                  <span>{{ key.name }}</span>
                  <mat-icon
                  (click)="selectPrimaryAttribute({checked: false}, i)"
                  >delete</mat-icon>
                </div>
              </div>
            </div> -->

            <div mat-dialog-content class="dialog-align">
              <div>
                <div *ngFor="let attr of attributesList; let i = index" [hidden]="!attr.visible" class="boxes-display">
                  <div class="box-display">
                    <span>{{ attr.name }}</span>
                    <mat-icon (click)="selectPrimaryAttribute({ checked: true }, i)">add</mat-icon>
                  </div>
                </div>
              </div>

              <div>
                <div *ngFor="let key of attributesList; let i = index" [hidden]="key.visible" class="boxes-display">
                  <div class="box-display">
                    <span>{{ key.name }}</span>
                    <mat-icon (click)="selectPrimaryAttribute({ checked: false }, i)">delete</mat-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-btn" (click)="saveUniqueKey()"><button mat-raised-button>Add</button></div>
          </div>

          <!-- <ul>
            <li *ngFor="let attr of configurationData?.data?.entityData?.nslAttributes">{{ attr.name }}</li>
            <br />
          </ul> -->
        </ng-template>
      </mat-accordion>
    </div>
  </div>
</div>

<!-- Dialog for global entity checkbox confirmation -->
<ng-template #globalEntityDialog>
  <div class="irdr-popup">
    <mat-dialog-content class="border-bottom border-top py-3 custm-cont">
      <div class="cont-wrapp">
        <div class="cont-msg">
          <p>{{ labels?.Setting_as_Global_Entity_once_cannot_be_reverted_back }}</p>
          <p>{{ labels?.Do_you_like_to_set_this_entity_as_Global }}</p>
        </div>
        <div class="cont-bts">
          <button mat-stroked-button type="button" (click)="closeGlobalEntityDialog(true)">{{ labels?.Yes }}</button>
          <button mat-stroked-button type="button" (click)="closeGlobalEntityDialog(false)">{{ labels?.No }}</button>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</ng-template>
