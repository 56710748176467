import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dldEntity',
  /* istanbul ignore next */
  pure: true,
})
export class DLDEntityPipe implements PipeTransform {
  transform(data: any, layerToShow: string): any {
    /* istanbul ignore next */
    if (data) {
      return this.extractEntitiesFromCU(data, layerToShow);
    }
  }

  private extractEntitiesFromCU(cuData: any, layerToShow: string) {
    const changeUnit = cuData.DATA;
    /* istanbul ignore next */
    if (changeUnit) {
      /* istanbul ignore next */
      if (changeUnit?.layers?.length > 0) {
        for (let value of changeUnit.layers) {
          /* istanbul ignore next */
          if (value.type === layerToShow) {
            return value.participatingItems.map((entity: any) => entity?.name);
          }
        }
      }
    }
  }
}
