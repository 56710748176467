import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GsiFacadeService, AlertService, EntityBoardEndpointService, GsiButton, RelatedRecordPayload } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { appendValue, decodeString, encodeString, IAttributeType, IRecordDetail, IRelatedAddToCart, IRelatedRecords, IRelatedRecordsAndView, IRelatedRecordsValues, IRelatedTxnGeneralEntity, ITxnNslAttribute, RelatedRecords, Record } from '../../constants/customize-filter-templates.constants';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.scss'],
})
export class PageViewComponent implements OnInit {
  cardDetails: any;
  conditionArrayExpand: any[];
  expandBackground: string;
  expandColors: string[];
  expandBoxShadow: any;
  btnColors: string[];
  cartView: any;
  ngSubscribe = new Subject();
  @Input() viewName: string;
  @Input() entityName: string;
  @Input() recordId: number;
  expandNameValueMap: Map<string, string> = new Map();
  expandTemplateTypeData: { [key: string]: string };
  gsiExpandData: any;
  record: IRecordDetail;
  allowComments: boolean;
  generalEntityId: number;
  imagePlaceHolderPosition: number;
  @Input() isRouted: boolean = true;
  @Output() emitClosePage = new EventEmitter();

  payLoad: RelatedRecordPayload;
  relatedRecords: RelatedRecords;
  constructor(
    private gsiFacadeService: GsiFacadeService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    protected entityBoardService: EntityBoardEndpointService,
    private clipboard: Clipboard,
    private el: ElementRef,
    private rendrer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.isRouted) {
      this.getRouteParameters();
    }
    this.getPageRecords();
  }
  ngOnChanges() {
    if (this.isRouted) {
      this.getRouteParameters();
    }
    this.getPageRecords();
  }

  private getPageRecords = () => {
    this.entityBoardService
      .getEntityRelatedAndExpandView(this.entityName, this.viewName, this.recordId, this.payLoad)
      .subscribe((res: { result: IRelatedRecordsAndView; status: number; message?: string }) => {
        /* istanbul ignore else */
        if (res?.status === 200 && res?.result) {
          console.log(res);
          this.cardDetails = res?.result?.viewDesign?.expandedView?.style;
          this.cartView = res?.result?.viewDesign?.cartView;
          const { attributeTypes, expandTemplateTypeData, gsiExpandData, allowComments } = this.cardDetails;
          this.expandTemplateTypeData = expandTemplateTypeData;
          this.gsiExpandData = gsiExpandData;
          this.record = res?.result?.recordDetail;
          this.generalEntityId = this.record.generalEntityId;
          this.allowComments = allowComments;
          this.prepareCardsData(res?.result?.recordDetail?.txnNslAttribute, attributeTypes);
          this.relatedRecords = this.prepareRelatedRecordsData(res?.result?.relatedRecords, attributeTypes);
          if(this.cardDetails?.templateType?.includes('dynamic')) {
            this.bindHTML(this.cardDetails?.templateType);
          }
        } else {
          this.alertService.showToaster(res?.message, '', 'error');
        }
      });
  };

  private prepareRelatedRecordsData = (
    relatedRecords: IRelatedRecords,
    attributeTypes: IAttributeType[]
  ): RelatedRecords => {
    const related: RelatedRecords = {};
    Object.keys(relatedRecords).forEach((value: string) => {
      let obj: Record[] = [];
      Object.keys(relatedRecords[value]).forEach((key: string) => {
        let flag = false;
        if (relatedRecords?.[value]?.[key]?.find((recordId: string) => recordId == this.recordId.toString())) {
          flag = true;
        } else {
          flag = false;
        }
        const type: IAttributeType = attributeTypes.find((val: IAttributeType) => val?.name == value);
        obj.push({
          value: appendValue({ values: [key] }, type?.uiElement , '12-hr'),
          recordIds: relatedRecords[value][key],
          selected: flag,
          disabled: true,
          selectableRecordIds: [],
        });
      });
      related[value] = obj;
    });
    this.updateDisabledForRelatedRecords(related);
    return related;
  };

  private setSelectedRecords = (
    relatedRecords: RelatedRecords,
    attributeValue: string,
    getSelectedRecords: Record[]
  ) => {
    Object.keys(relatedRecords).forEach((otherAttributeValue: string) => {
      if (attributeValue != otherAttributeValue) {
        getSelectedRecords.push(relatedRecords[otherAttributeValue].find((record: Record) => record.selected));
      }
    });
  };

  private updateRecordIdMap = (getSelectedRecords: Record[], recordIdMap: Map<String, number>) => {
    getSelectedRecords.forEach((record: Record) => {
      record.recordIds.forEach((recordId: string) => {
        if (recordIdMap.has(recordId)) {
          recordIdMap.set(recordId, recordIdMap.get(recordId) + 1);
        } else if (!recordIdMap.has(recordId)) {
          recordIdMap.set(recordId, 1);
        }
      });
    });
  };

  private setDisabledForCurrentRecord = (
    relatedRecords: RelatedRecords,
    attributeValue: string,
    getSelectedRecords: Record[],
    recordIdMap: Map<String, number>
  ) => {
    relatedRecords[attributeValue].forEach((record: Record) => {
      record.selectableRecordIds = [];
      record.recordIds.forEach((recordId: string) => {
        if (recordIdMap.has(recordId) && recordIdMap.get(recordId) === getSelectedRecords.length) {
          record.selectableRecordIds.push(recordId);
          record.disabled = false;
        }
      });
    });
  };

  private updateDisabledForRelatedRecords = (relatedRecords: RelatedRecords) => {
    Object.keys(relatedRecords).forEach((attributeValue: string) => {
      const getSelectedRecords: Record[] = [];
      this.setSelectedRecords(relatedRecords, attributeValue, getSelectedRecords);
      const recordIdMap: Map<String, number> = new Map();
      this.updateRecordIdMap(getSelectedRecords, recordIdMap);
      this.setDisabledForCurrentRecord(relatedRecords, attributeValue, getSelectedRecords, recordIdMap);
    });
  };

  /* istanbul ignore next */
  public goToRelatedRecord = (recordId: string[]) => {
    const updatedRecordId = recordId.find((value: string) => value.toString() !== this.recordId.toString())
    if (updatedRecordId) {
      this.recordId = parseInt(updatedRecordId);
      if (this.isRouted) {
        this.router.navigate([`/view/${encodeString(this.entityName)}/${encodeString(this.recordId.toString())}`], {
          queryParams: { viewname: encodeString(this.viewName) },
        });
      }
      this.getPageRecords();
    }
  };

  private prepareCardsData = (txnNslAttribute: ITxnNslAttribute[], attributeTypes: IAttributeType[]) => {
    this.expandNameValueMap.clear();
    txnNslAttribute.forEach((attr: ITxnNslAttribute) => {
      const type: IAttributeType = attributeTypes.find((value: IAttributeType) => value?.name == attr?.name);
      this.expandNameValueMap.set(attr.name, appendValue(attr, type?.uiElement,'12-hr'));
      if (type?.uiElement === 'image') {
        this.imagePlaceHolderPosition = this.setImagePlaceHolderPosition(attr);
      }
    });
  };

  private setImagePlaceHolderPosition(attr: ITxnNslAttribute): number {
    const keyValue: [string, string] = Object.entries(this.expandTemplateTypeData).find(
      (value: [string, string]) => attr?.name == value[1]
    );
    return keyValue?.length == 2 ? parseInt(keyValue?.[0]) : this.imagePlaceHolderPosition;
  }

  private getRouteParameters = () => {
    this.entityName = decodeString(this.route?.snapshot?.params?.entityName);
    this.recordId = parseInt(decodeString(this.route?.snapshot?.params?.recordId));
    this.viewName = decodeString(this.route?.snapshot?.queryParams?.['viewname']);
  };

  executeCard(buttonData: GsiButton, event?: Event) {
    if (event) event?.preventDefault();
    if (buttonData.entityType == 'GSI') {
      /** this if block detects if it has url binding  */
      this.executeGsi(buttonData);
    } else {
      // cart execution
      this.addToCart(this.record, this.cartView);
    }
  }

  private updateTransEntityRecord = (record: IRecordDetail): IRelatedTxnGeneralEntity => {
    const payload: IRelatedTxnGeneralEntity = {
      displayName: this.entityName,
      generalEntityID: record.generalEntityId,
      id: this.recordId,
      name: this.entityName,
      transEntityRecords: [{ txnNslAttribute: record?.txnNslAttribute }],
    };
    return payload;
  };

  addToCart(transactionEntityRecord: IRecordDetail, cartView: any) {
    let requestData: IRelatedAddToCart = {
      txnGeneralEntity: this.updateTransEntityRecord(transactionEntityRecord),
      cartView,
    };
    this.gsiFacadeService.addToCart(requestData).subscribe((response) => {
      if (response.status === 200) {
        this.alertService.showToaster(response.message, 'success', 'success');
      }
    });
  }

  /*istanbul ignore next*/
  executeGsi(gsi: any) {
    this.gsiFacadeService
      .getGsiIdFromMasterId(gsi.masterId)
      ?.pipe(takeUntil(this.ngSubscribe))
      .subscribe((res: any) => {
        const txnData = btoa(
          JSON.stringify({
            gsiId: res?.result?.id ? res?.result?.id : gsi.id,
            gsiMasterId: gsi?.masterId,
            recordId: this.recordId,
            entityName: this.entityName,
          })
        );
        /* istanbul ignore next */
        localStorage.setItem('gsi-masterId', res?.result?.masterId);
        this.router.navigate(['transaction/view/' + txnData]);
      });
  }

  copyLinkToClipboard() {
    var url = window.location.origin;
    url =
      url +
      `/view/${encodeURIComponent(encodeString(this.entityName))}/${encodeURIComponent(
        encodeString(this.record.id.toString())
      )}?viewname=${encodeURIComponent(encodeString(this.viewName))}`;
    this.clipboard.copy(url);
    this.alertService.showToaster('Copy to clipboard', '', 'success');
  }

  private bindHTML = (templateId: string) => {
    const tempId = templateId.split('-')[1];
    if (tempId) {
      this.entityBoardService.getTemplate(tempId).subscribe((res) => {
        const template = res.result.template;
        const styleStartIndex = template.indexOf('<style>');
        const styleEndIndex = template.indexOf('</style>');
        const style = template.substring(styleStartIndex + 7, styleEndIndex);
        const content = template.substring(0, styleStartIndex);
        const placeholder = this.el.nativeElement.querySelector('.dynamic-template-placeholder');
        placeholder.innerHTML = `<style>${style}</style> ${content}`;
        this.el.nativeElement
        .querySelectorAll('.dynamic-card-block')
        .forEach((el: HTMLElement) => {
          el.querySelectorAll('li').forEach((attr: HTMLElement, ind: number) => {
            this.handleAttribute(attr, this.expandTemplateTypeData[ind], this.relatedRecords, this.expandNameValueMap);
          });
          this.handleGsiButtons(el, this.gsiExpandData, this.cardDetails, this.addToCart, this.executeGsi);
        });
      });
    }
  }

  private handleAttribute = (attr: HTMLElement, templateData: string, relatedRecords: RelatedRecords, expandNameValueMap: Map<string, string>) => {
    if (attr.classList.value.includes('only-value') || attr.classList.value.includes('attribute-value')) {
      const isOnlyValue = attr.classList.value.includes('only-value');
      const str = this.buildAttributeContent(templateData, relatedRecords, expandNameValueMap);
      if (isOnlyValue) {
        attr.innerHTML = str;
      } else if(!isOnlyValue && templateData) {
        attr.innerHTML = `<span class='attr-placeholder'>${templateData}</span>${str}`;
      } else {
        attr.innerHTML = '';
      }
      if (relatedRecords[templateData]) {
        attr.querySelectorAll('.value-placeholder').forEach((ele: HTMLElement, i: number) => {
          ele.addEventListener('click', () => {
            this.goToRelatedRecord(relatedRecords[templateData][i].selectableRecordIds);
          });
        });
      }
    } else if (attr.classList.value.includes('card-logo')) {
      this.handleCardLogo(attr, templateData, relatedRecords, expandNameValueMap);
    }
  }

  private buildAttributeContent = (templateData: string, relatedRecords: RelatedRecords, expandNameValueMap: Map<string, string>): string => {
    let str = '';
    if (relatedRecords[templateData]) {
      relatedRecords[templateData].forEach((record: Record) => {
        const activeClass = record.selected ? 'active' : '';
        const disabledClass = record.disabled ? 'record-disabled' : '';
        str += `<span class='value-placeholder cursor-pointer ${activeClass} ${disabledClass}'>${record.value}</span>`;
      });
    } else if (expandNameValueMap.has(templateData)) {
      str += `<span class='value-placeholder'>${expandNameValueMap.get(templateData)}</span>`;
    }
    return str;
  }

  private handleCardLogo = (attr: HTMLElement, templateData: string, relatedRecords: RelatedRecords, expandNameValueMap: Map<string, string>) => {
    let str = '';
    if (relatedRecords[templateData]) {
      relatedRecords[templateData].forEach((record: Record) => {
        const activeClass = record.selected ? 'active' : '';
        const disabledClass = record.disabled ? 'record-disabled' : '';
        str += `<img src="${record.value}" class="image-class cursor-pointer ${activeClass} ${disabledClass}" alt='' />`;
      });
    } else if (expandNameValueMap.has(templateData)) {
      str = `<img src="${expandNameValueMap.get(templateData)}" class="image-class" alt='' />`;
    }
    attr.innerHTML = str;
    if (relatedRecords[templateData]) {
      attr.querySelectorAll('.image-class').forEach((ele: HTMLElement, i: number) => {
        ele.addEventListener('click', () => {
          this.goToRelatedRecord(relatedRecords[templateData][i].selectableRecordIds);
        });
      });
    }

  }

  private handleGsiButtons = (el: HTMLElement, gsiExpandData: any, cardDetails: any, addToCart: Function, executeGsi: Function) => {
    if (el.querySelectorAll('.gsi-button')?.length > 0) {
      el.querySelectorAll('.gsi-button').forEach((btn: HTMLElement, i: number) => {
        const gsiData = gsiExpandData[btn.id];
        if (gsiData) {
          btn.innerHTML = gsiData.displayName;
        } else {
          btn.innerHTML = '';
          this.rendrer.removeChild(btn.parentElement, btn);
        }
        btn.addEventListener('click', (event: Event) => {
          event.stopPropagation();
          if (gsiData?.id != -1) {
            executeGsi(gsiData);
          } else if (cardDetails?.gsiList[i]?.id == -1) {
            addToCart(this.record, this.cartView);
          }
        }, false);
      });
    }
  }
}
