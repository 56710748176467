import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { HyperlinkComponent } from './hyperlink.component';

@NgModule({
  declarations: [HyperlinkComponent],
  imports: [ControlsMaterialModule],
  exports: [HyperlinkComponent],
})
export class HyperLinkModule {
  static entry = {
    hyperlink: HyperlinkComponent,
  };
}
