import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-zoom-inout',
  templateUrl: './image-zoom-inout.component.html',
  styleUrls: ['./image-zoom-inout.component.scss'],
})
export class ImageZoomInoutComponent implements OnInit {
  zoomImagePercentage: number;
  zoomMaxWidth: number;
  src: any;
  buttonClickCounts: any = 0;
  maxClicks: any = 4;
  rotate: number = 0;
  field: any;
  canDownload: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      file: any;
      field: any;
    },
    public dialogService: MatDialog,
    public dialogRef: MatDialogRef<ImageZoomInoutComponent>,

  ) {
    this.src = data.file;
    this.field = data.field;
    this.isDownloadFile();
    this.zoomMaxWidth = 90;
  }

  ngOnInit(): void {}

  zoomInOut(type: any) {
    this.findImageWidth();
    if (type == 'in') {
      this.zoomImagePercentage += 10;
      this.handleClick('btn1');
    } else if (type == 'out' && this.zoomImagePercentage >= 10) {
      this.zoomImagePercentage -= 10;
      this.handleClick('btn2');
    }
    this.zoomMaxWidth = this.zoomImagePercentage;
  }

  closeImageZoomScreen() {
    this.zoomImagePercentage = null;
    this.rotate = 0;
    this.zoomMaxWidth = 90;
    this.dialogRef.close();
  }

  findImageWidth() {
    let element = document.getElementById('imgZoom').clientWidth;
    this.zoomImagePercentage = (element / window.innerWidth) * 100;
  }

  handleClick(buttonId: string) {
    if (buttonId == 'btn1') this.buttonClickCounts++;
    else if (buttonId == 'btn2') this.buttonClickCounts--;
  }

  onRightClick(event: any) {
    event.preventDefault();
  }

  onRotate(direction: any) {
    if (direction == 'left') {
      this.rotate -= 90;
    } else if (direction == 'right') {
      this.rotate += 90;
    }
  }

  isDownloadFile() {
    this.canDownload = this.field?.canDownload;
  }
  downloadFile() {
    fetch(this.src?.contentUrl)
      .then((res) => res.blob())
      .then((blob) => {
        import('file-saver').then((fileSaver) => {
          (fileSaver as any).default.saveAs(blob, this.src?.displayName);
        });
      });
  }
}
