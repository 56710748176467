import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ChangeUnitEndpointService } from './change-unit-endpoint.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { ChangeUnitList } from '../modal/change-unit-list';
import { EntityStoreService } from '../stores/entity-store';
import { ChangeUnitStoreService } from '../stores/change-unit-store';
import { LoaderService } from '../loader/loader.service';
import { GsiEndpointService } from '../gsi/gsi-endpoint.service';
import { changeUnitDeMapper } from '../mappers/change-unit/cu-demapper';
import { changeUnitListMapper } from '../mappers/list/change-unit';
import { changeUnitMapper } from '../mappers/change-unit/cu-mapper';
import { ChangeUnit } from '../modal/change-unit';
import { entityListMapper } from '../mappers/list/entity';
import { RearrangeData } from '../modal/rearrange-data';
import { AlertService } from '../toaster/alert.service';
import { gsiMapper } from '../mappers/gsi/gsi-mapper';
import { compositeExtractionMapper } from '../mappers/composite-extration/compositeExtraction-mapper';

@Injectable({
  providedIn: 'root',
})
export class ChangeUnitFacadeService extends CduiBaseService
  implements OnDestroy {
  ngUnsubscribe = new Subject();
  changeUnitListIntital: ChangeUnitList[];
  public changeUnitSubject = new Subject();
  changeUnitList$ = this.changeUnitSubject.asObservable();

  public entityAttributes = new Subject();
  entityAttributes$ = this.entityAttributes.asObservable();

  public cuDetails = new Subject();
  cuDetails$ = this.cuDetails.asObservable();

  public seperateCu = new Subject();
  seperateCu$ = this.seperateCu.asObservable();

  public cuDetailsForEQL = new BehaviorSubject('');
  cuDetailsForEQL$ = this.cuDetailsForEQL.asObservable();

  public libCuDetails = new Subject();
  libCuDetails$ = this.libCuDetails.asObservable();

  public saveCuSubject = new BehaviorSubject('');
  lastSavedCu$ = this.saveCuSubject.asObservable();

  public draftCuSubject = new BehaviorSubject('');
  draftedCU$ = this.draftCuSubject.asObservable();

  public openDraftSubject = new Subject();
  openDraftCU$ = this.openDraftSubject.asObservable();

  public updateDraftSubject = new Subject();
  updateDraft$ = this.updateDraftSubject.asObservable();

  public cuCount = new Subject();
  cuCount$ = this.cuCount.asObservable();

  public reservedCuList = new Subject();
  reservedCuList$ = this.reservedCuList.asObservable();

  public reservedEntityList = new Subject();
  reservedEntityList$ = this.reservedEntityList.asObservable();

  public changeUnitListByGSI = new Subject();
  changeUnitListByGSI$ = this.changeUnitListByGSI.asObservable();

  public changeUnitListByTracableGSI = new Subject();
  changeUnitListByTracableGSI$ = this.changeUnitListByTracableGSI.asObservable();

  public reservedEntityData = new Subject();
  reservedEntityData$ = this.reservedEntityData.asObservable();

  // Elastic Search - FE757
  public gsiData: any;

  public relatedChangeUnitListByGSI = new Subject();
  relatedChangeUnitListByGSI$ = this.relatedChangeUnitListByGSI.asObservable();

  public gsiEditResponse = new Subject();
  gsiEditedResponse$ = this.gsiEditResponse.asObservable();

  public changeUnitUIControl = new Subject();
  changeUnitUIControl$ = this.changeUnitUIControl.asObservable();

  public updateCuEvents = new Subject();
  updateCuEventsResponse$ = this.updateCuEvents.asObservable();

  public addAlternateCu = new Subject();
  addAlternateCu$ = this.addAlternateCu.asObservable();

  public changeUnitOptions = new Subject();
  changeUnitOptions$ = this.changeUnitOptions.asObservable();
  public currentLayerIndex = new BehaviorSubject(null);
  currentLayerIndex$ = this.currentLayerIndex.asObservable();

  public focusedInputParentCU = new Subject();
  focusedInputParentCU$ = this.focusedInputParentCU.asObservable();
  public solutionDetails = new Subject();
  solutionDetails$ = this.solutionDetails.asObservable();
  public saveCuDetails = new Subject();
  saveCuDetails$ = this.saveCuDetails.asObservable();
  public solutionDetailsFail = new Subject();
  solutionDetailsFail$ = this.solutionDetailsFail.asObservable();

  resumedChangeUnit = new Subject();
  resumedChangeUnit$ = this.resumedChangeUnit.asObservable();

  public reservedActions = new Subject();
  reservedActions$ = this.reservedActions.asObservable();
  public generalEntityTuplesData = new Subject();
  generalEntityTuplesData$ = this.generalEntityTuplesData.asObservable();

  public constantChangeDriver = new Subject();
  constantChangeDriver$ = this.constantChangeDriver.asObservable();

  public saveConstantChangeDriverObs = new Subject();
  savedConstantChangeDriver$ = this.saveConstantChangeDriverObs.asObservable();

  public addChangeUnitToHoverComponent = new Subject();
  addChangeUnitToHoverComponentFlag$ = this.addChangeUnitToHoverComponent.asObservable();

  public updateConstantChangeDriverData = new Subject();
  updateConstantChangeDriverData$ = this.updateConstantChangeDriverData.asObservable();

  //getNextCU while switching between two CUs
  public cuSaveFunctionInCuComponent = new Subject();
  cuSaveFunctionInCuComponent$ = this.cuSaveFunctionInCuComponent.asObservable();

  public clickOpenCuInComponent = new Subject();
  clickOpenCuInComponent$ = this.clickOpenCuInComponent.asObservable();

  /** Stream for checking for Published CU
   * changes at Trigger CES level */
  public publshedCUSave = new Subject();
  publishedCUSave$ = this.publshedCUSave.asObservable();

  public layersAgentType = new BehaviorSubject('');
  layersAgentType$ = this.layersAgentType.asObservable();

  public subtransactionalCUCondition = new BehaviorSubject('');
  subtransactionalCUCondition$ = this.subtransactionalCUCondition.asObservable();

  public getPreviewRearrangeGsi = new Subject();
  getPreviewRearrangeGsi$ = this.getPreviewRearrangeGsi.asObservable();

  public getRearrangeGsi = new Subject();
  getRearrangeGsi$ = this.getRearrangeGsi.asObservable();

  public currentEntityDetails = new Subject();
  currentEntityDetails$ = this.currentEntityDetails.asObservable();

  public updatedCU = new Subject();
  updatedCU$ = this.updatedCU.asObservable();

  public triggerCuNested = new Subject();
  triggerCuNested$ = this.triggerCuNested.asObservable();

  public GsiDesign = new BehaviorSubject('');
  GsiDesign$ = this.GsiDesign.asObservable();

  validatedCUName = new Subject();
  validatedCUName$ = this.validatedCUName.asObservable();
  private cuValidate = new Subject();
  cuValidate$ = this.cuValidate.asObservable();
  private nslFormulaInfo = new Subject();
  nslFormulaInfo$ = this.nslFormulaInfo.asObservable();

  public metaDataDetails = new BehaviorSubject('');
  metaDataDetails$ = this.metaDataDetails.asObservable();

  errorMessageDetails = new Subject();
  errorMessageDetails$ = this.errorMessageDetails.asObservable();
  // to get cuList of a embeded gsi
  embededGsiDetails = new Subject();
  embededGsiDetails$ = this.embededGsiDetails.asObservable();

  public removeAgentFromAgentsList = new BehaviorSubject(null);
  removeAgentFromAgentsList$ = this.removeAgentFromAgentsList.asObservable();

  public removeEntityFromEntityList = new Subject();
  removeEntityFromEntityList$ = this.removeEntityFromEntityList.asObservable();

  public triggerCuData = new Subject();
  triggerCuData$ = this.triggerCuData.asObservable();
  nextCu: any;
  openNextCu: boolean;

  cuPanelClose: boolean = false;
  constructor(
    private changeUnitEndPoint: ChangeUnitEndpointService,
    private entityStoreService: EntityStoreService,
    private changeUnitStore: ChangeUnitStoreService,
    private alertService: AlertService,
    private loader: LoaderService,
    private gsiEndpointService: GsiEndpointService,
    private router: Router,
    private http: HttpClient,
    private injector: Injector
  ) {
    super(injector);
    this.changeUnitStore.stateChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: any) => {
        if (state) {
          this.changeUnitSubject.next(state.changeUnitList);
        }
      });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeAgentType(type: string) {
    this.layersAgentType.next(type);
  }

  getReservedEntities() {
    this.loader.show();
    this.changeUnitEndPoint
      .getReservedEntities()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.reservedActions.next(res);
      });
  }

  updateLastedFocusedInputParentCU(changeUnit: any) {
    this.focusedInputParentCU.next(changeUnit);
  }
  setDraftChaneUnit(changeUnit: any) {
    this.draftCuSubject.next(changeUnit);
  }
  validateCuList(cuData: any, cu: any) {
    this.http
      .post(this.api?.cuValidate, cuData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.cuValidate.next({ res: res, cu: cu });
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  validateCu(data: any) {
    this.cuValidate.next(data);
  }

  getAllChangeUnits(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean
  ) {
    this.loader.show();
    this.changeUnitEndPoint
      .getAllCU(pagenum + 1, pageSize, searchCriteria, isPublished)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuList = changeUnitListMapper(res);
        this.changeUnitStore.setStateData(cuList);
      });
  }
  getChangeUnitoptions(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean
  ) {
    this.loader.show();
    this.changeUnitEndPoint
      .getAllCU(pagenum + 1, pageSize, searchCriteria, isPublished)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuList = changeUnitListMapper(res);
        this.changeUnitOptions.next(cuList);
      });
  }

  getSaveCuDetails(data: any, cures?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res[0]) res[0] = compositeExtractionMapper(res[0]);
          this.saveCuDetails.next({ res, cures });
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
          this.saveCuDetails.next({ error: true, cures });
        }
      );
  }

  saveTriggerCu(
    cuList: any,
    mainNestedCuName?: any,
    isSeperateCU?: any,
    secondLevel?: any,
    isFinalNested?: any
  ) {
    this.http
      .post(`${this.api?.saveTriggerCu}`, cuList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          let data: any = [];
          /*istanbul ignore else*/
          if (res && res?.result) {
            res.mainNestedCuName = mainNestedCuName;
            this.triggerCuData.next(res);
          }
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  getSolutionDetails(data: any, cures?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.solutionDetails.next({ res, cures });
        },
        /*istanbul ignore next*/
        (err: any) => {
          if (cures == 'finish') {
            const obj = {
              flatApiFail: true,
              finish: 'finish',
            };
            this.solutionDetailsFail.next(obj);
            this.alertService.showToaster(err.message, '', 'error');
          } else {
            const obj = {
              flatApiFail: true,
            };
            this.solutionDetailsFail.next(obj);
            this.alertService.showToaster(err.message, '', 'error');
          }
        }
      );
  }

  getChangeUnitCount(pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitEndPoint
      .getCuCount(0, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.cuCount.next(res);
      });
  }

  saveCU(cu: any, isModified?: boolean) {
    this.loader.show();
    let cuInfo = changeUnitDeMapper(cu);

    this.changeUnitEndPoint
      .saveCU(cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          localStorage.removeItem('skipWarnings');
          if (res && res.status && res.status == 200) {
            const cuRes = changeUnitMapper(res.result);
            /* istanbul ignore next */
            this.saveCuSubject.next({
              ...cuRes,
              oldVersionId: cu?.oldVersionId,
              isEdit: cu?.isEdit,
              isModified: isModified,
              slotItemProperties: cu?.slotItemProperties,
              source: cu?.source,
              tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
              tCUConditionalPotentialityNames:
                cu?.tCUConditionalPotentialityNames,
              constraint: cu?.constraint,
              constraintName: cu?.constraintName,
              estimatedTime: cu?.estimatedTime,
              isReservedTemplate: cu?.isReservedTemplate,
              isTemplate: cu?.isTemplate,
              index: cu?.index,
              eventCUList: cu?.eventCUList,
              txnDataSaveMode: cu?.txnDataSaveMode,
              transactionEndpoint: cu?.transactionEndpoint,
              singlNestedSave: cu?.singlNestedSave,
              disableSTEs:cu?.disableSTEs,
              triggerCUDialogList: cu?.triggerCUDialogList,
            });
            this.alertService.showToaster(res.message, '', 'success');
          } else {
            /* istanbul ignore else*/
            if (res && res.message) {
              this.alertService.showToaster(`${res.message}`, '', 'error');
            }
          }
        },
        (error) => {
          const { err } = error;
          localStorage.removeItem('skipWarnings');
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }

  resetlastsavedCU() {
    this.saveCuSubject.next('');
  }

  updateDraftFromEntity() {
    this.updateDraftSubject.next(true);
  }

  updateDraftCU(changeUnit: ChangeUnit) {
    const data = changeUnitMapper(changeUnit);
    if(changeUnit?.seperateCuData)
    {
      this.seperateCu.next(true);
    }
    this.cuDetails.next(data);
  }

  openDraftedCU() {
    this.openDraftSubject.next(true);
  }

  getCuDetailsById(id: any) {
    /* istanbul ignore else */
    if (id) {
      this.loader.show();
      this.changeUnitEndPoint
        .getCuDetailsById(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          const data = changeUnitMapper(res?.result);
          this.cuDetails.next(data);
          this.cuDetailsForEQL.next(data);
        });
    }
  }

  getLibCuDetailsById(id: any) {
    if (id) {
      this.loader.show();
      this.changeUnitEndPoint
        .getCuDetailsById(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          const data = changeUnitMapper(res?.result);
          this.libCuDetails.next(data);
        });
    }
  }

  getReservedCuDetailsById(
    id: any,
    isReservedForDCD?: any,
    isReservedTemplate?: boolean,
    isTemplate?: boolean,
  ) {
    /* istanbul ignore else */
    if (id) {
      this.loader.show();
      this.changeUnitEndPoint
        .getReservedCuDetailsById(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          const data = changeUnitMapper(res?.result);

          if (isReservedForDCD) {
            this.libCuDetails.next(data);
          } else {
            /* istanbul ignore else */
            if (isReservedTemplate) {
              data.isReservedTemplate = isReservedTemplate;
            }
            this.cuDetails.next(data);
          }
        });
    }
  }
  //calls an api to get the impacted BETs Data
  getRelatedBetsData(id: any, type: any) {
    return this.changeUnitEndPoint.getRelatedBetsData(id, type);
  }

  clearLibraryCuDetails() {
    this.libCuDetails.next(null);
  }

  getGsiById(gsiId: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .getGsiById(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.status === 400) {
          this.alertService.showToaster(res.message, '', 'error');
        }
        if (res && res['result'] != 'null') {
          this.changeUnitListByGSI.next(res['result']);
        }
      });
  }

  getTracableGsiById(gsiId: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .getTracableGsiById(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.status === 400) {
          this.alertService.showToaster(res.message, '', 'error');
        }
        if (res && res['result'] != 'null') {
          this.changeUnitListByTracableGSI.next(res['result']);
        }
      });
  }

  getCusByGsiId(gsi: any, isDld?: boolean, triggerCuId?: any, bookid?: any, isDebug?: any) {
    const url = this.router.url;
    this.loader.show();
    if (isDld) {
      this.loader.hide();
      gsi.dldrecommendation = true;
      this.changeUnitListByGSI.next(gsi);
    } else {
      this.changeUnitEndPoint
        .getCusByGsiId(gsi, triggerCuId, bookid, isDebug)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            /* istanbul ignore else */
            if (res.status === 400) {
              this.alertService.showToaster(res.message, '', 'error');
              /* istanbul ignore next */
              if (url === '/transaction/view') {
                this.router.navigate(['/mylibrary-dashboard']);
              }
            }
            // if (res.status === 403) {
            //   // Unauthorized access
            //   this.alertService.showToaster(res.message, '', 'warning');
            // }
            /* istanbul ignore else */
            if (res && res['result'] != 'null') {
              this.metaDataDetails.next(res);
              // Elastic Search - FE757
              /* istanbul ignore next */
              if (res?.result?.designUrl) {
                this.gsiData = res['result'];
                this.getDesignByUrl(res?.result?.designUrl);
              }
              if (url.includes('/transaction/view')) {
                sessionStorage.setItem('attrList', JSON.stringify([]));
              }
            }
          },
          (error: any) => {
            this.alertService.showToaster(
              `${error.message ? error.message : 'User does not have rights to execute the solution'}`,
              '',
              'error'
            );
            this.router.navigate(['/cdui/mylibrary-dashboard']);
          }
        );
    }
  }
  getDesignByUrl(url: string) {
    this.changeUnitEndPoint
      .getDesignByUrl(url)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res?.result) {
          /* istanbul ignore next */
          let gsiId = this.gsiData.masterId;
          let gsilayout = res?.result?.gsiLayout[gsiId];
          /* istanbul ignore next */
          if (gsilayout == undefined) {
            res.result.gsiLayout =
              res.result.gsiLayout?.default || res.result.gsiLayout[0];
          } else {
            res.result.gsiLayout = gsilayout;
          }
          if (this.gsiData.design) {
            this.gsiData.design = res.result;
          } else {
            this.gsiData['design'] = res.result;
          }
          this.changeUnitListByGSI.next(this.gsiData);
          this.GsiDesign.next(res.result);
        }
      });
  }

  getCuDetailsByGSIId(gsi: number, cuId: number) {
    if (gsi && cuId) {
      this.loader.show();
      this.changeUnitEndPoint
        .getCuDataByGsiId(gsi, cuId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          this.resumedChangeUnit.next(res);
        });
    }
  }
  getchangeunitsfromCompositeAPI(data: any) {
    data.compositeSave = true;
    this.changeUnitListByGSI.next(data);
  }

  getrelatedCulistByGsiId(id: any) {
    this.loader.show();
    this.gsiEndpointService
      .getGSIDetailsById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res && res['result'] != 'null') {
          /* istanbul ignore next */
          this.relatedChangeUnitListByGSI.next(gsiMapper(res?.result));
        }
      });
  }

  // getrelatedCulistByGsiId(gsi: any, triggerCuId?: any) {
  //   this.loader.show();
  //   this.changeUnitEndPoint
  //     .getCusByGsiId(gsi, triggerCuId)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.loader.hide();
  //       if (res && res['result'] != 'null') {
  //         this.relatedChangeUnitListByGSI.next(res.result.solutionLogic);
  //       }
  //     });
  // }

  updateChangeUnit(
    cu: any,
    changeunitData: any,
    isModified: boolean,
    isVersionEdit: boolean
  ) {
    this.loader.show();
    const cuInfo = changeUnitDeMapper(changeunitData);
    this.changeUnitEndPoint
      .saveCU(cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res && res.status && res.status == 200) {
          const cuRes = changeUnitMapper(res.result);
          /* istanbul ignore next*/
          if (isVersionEdit && changeunitData?.slotItemProperties) {
            Object.keys(changeunitData.slotItemProperties)?.forEach(
              (slot: any) => {
                /* istanbul ignore next*/
                if (
                  changeunitData?.slotItemProperties[slot]?.propertyData
                    ?.CONSTANT?.id
                ) {
                  delete changeunitData.slotItemProperties[slot].propertyData
                    .CONSTANT.id;
                }
              }
            );
          }
          /* istanbul ignore next*/
          this.saveCuSubject.next({
            ...cuRes,
            oldVersionId: changeunitData?.oldVersionId,
            isModified: isModified,
            slotItemProperties: changeunitData.slotItemProperties,
            tCUConditionalPotentiality:
              changeunitData.tCUConditionalPotentiality,
            tCUConditionalPotentialityNames:
              changeunitData.tCUConditionalPotentialityNames,
            constraint: changeunitData?.constraint,
            constraintName: changeunitData?.constraintName,
            estimatedTime: changeunitData?.estimatedTime,
            isReservedTemplate: changeunitData?.isReservedTemplate,
            index: changeunitData?.index,
            eventCUList: changeunitData?.eventCUList,
            txnDataSaveMode: changeunitData?.txnDataSaveMode,
            transactionEndpoint: changeunitData?.transactionEndpoint,
            // referencedChangeUnit: changeunitData?.referencedChangeUnit,
            // tfReferencedChangeUnit: changeunitData?.tfReferencedChangeUnit,
            isEdit:changeunitData?.isEdit,
            singlNestedSave: changeunitData?.singlNestedSave,
            disableSTEs:changeunitData?.disableSTEs,
            triggerCUDialogList: changeunitData?.triggerCUDialogList,
          });
        }
        this.alertService.showToaster(res.message, '', 'success');
        localStorage.removeItem('skipWarnings');
      });
  }

  /*

  updateChangeUnit(cu: any, changeunitData: any, constantChangeDriverFlag: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .saveCU(changeunitData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res && res.status && res.status == 200) {
          res.result.constantChangeDriverFlag = constantChangeDriverFlag;
          this.saveCuSubject.next(res.result);
        }
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  */

  getReservedCuList(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitEndPoint
      .getReservedCuList(pagenum + 1, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuRes = changeUnitListMapper(res);
        this.reservedCuList.next(cuRes);
        this.changeUnitStore.setStateData(cuRes);
      });
  }
  getReservedEntitiesList(
    pagenum: number,
    pageSize: number,
    searchCriteria: string
  ) {
    this.loader.show();
    this.changeUnitEndPoint
      .getReservedEntityList(pagenum + 1, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const entList = entityListMapper(res);
        this.entityStoreService.setStateData(entList);
        this.reservedEntityList.next(entList);
      });
  }
  updateChangeUnitRights(cu: any, body: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .updateChangeUnitRights(cu, body)
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) this.alertService.showToaster(res.message, '', 'success');
      });
  }
  uiELelmentSave(data: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .uiElementSave(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.generalEntityTuplesData.next(res);
        this.loader.hide();
      });
  }
  saveStaticLabels(staticLabels: any, lang: any) {
    this.changeUnitEndPoint
      .staticLabelsSave(staticLabels, lang)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {});
  }

  getStaticLabels(lang: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .getStaticLabels(lang)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
      });
  }

  loadUIControls(cuId: any) {
    this.loader.hide();
    this.changeUnitEndPoint
      .loadUIControls(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.changeUnitUIControl.next(res);
      });
  }

  updateAttributeEvents(data: any) {
    this.updateCuEvents.next(data);
  }

  updateCurrentLayerIndex(index: number) {
    this.currentLayerIndex.next(index);
  }

  addAlternateCuToGsi(flag: boolean) {
    this.addAlternateCu.next(flag);
  }

  addConstantChangeDriverToSlot(changeDriver: any) {
    this.constantChangeDriver.next(changeDriver);
  }

  currentEntityToUpdate(entity: any) {
    this.currentEntityDetails.next(entity);
  }

  saveConstantChangeDriver(ccdData: any, triggerCUId: any) {
    this.changeUnitEndPoint
      .saveConstantChangeDriver(ccdData, triggerCUId)
      .subscribe((constantChangeDriver: any) => {
        constantChangeDriver.triggerCUId = triggerCUId;
        this.saveConstantChangeDriverObs.next(constantChangeDriver);
      });
  }

  updateConstantChangeDriver(data: any) {
    this.updateConstantChangeDriverData.next(data);
  }

  changeUnitAddtoHoverComponent(flag: any, type: any) {
    this.addChangeUnitToHoverComponent.next({ flag: flag, type: type });
  }

  // Doing a Service Call based on CU Status
  publishedCUSaveHit(status: any) {
    this.publshedCUSave.next(status);
  }

  //storing subtransactional cu data
  subtransactionalCU(data: any) {
    this.subtransactionalCUCondition.next(data);
  }

  validateEQLWhiteboardQuery(data: any) {
    return this.changeUnitEndPoint.validateEQLWhiteboardQuery(data);
  }

  validateEQLBuilderQuery(data: any) {
    return this.changeUnitEndPoint.validateEQLBuilderQuery(data);
  }

  previewRearrageCU(rearrangeData: RearrangeData) {
    this.changeUnitEndPoint
      .previewRearrageCU(rearrangeData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getPreviewRearrangeGsi.next(res);
      });
  }

  rearrangeCU(rearrangeData: RearrangeData, cu: any) {
    this.changeUnitEndPoint
      .rearrangeCU(rearrangeData, cu)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getRearrangeGsi.next(gsiMapper(res.result));
      });
  }

  fetchCu(id: any) {
    if (id) {
      this.changeUnitEndPoint
        .getCuDetailsById(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) => {
            /* istanbul ignore next */
            if (res.status === 200) {
              this.updatedCU.next(changeUnitMapper(res.result));
            }
          },
          (err: any) => {
            this.alertService.showToaster(err, '', 'error');
          }
        );
    }
  }
  fetchTriggerCu(id: any) {
    if (id) {
      const url = this.router.url;
      this.changeUnitEndPoint
        .getTriggerCU(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res.status === 400) {
            this.alertService.showToaster(res.message, '', 'error');
            /* istanbul ignore next */
            if (url === '/transaction/view') {
              this.router.navigate(['/mylibrary-dashboard']);
            }
          }
          // This method is used to store the trigger cu data
          if (res) {
            this.triggerCuNested.next(res);
          }
          /* istanbul ignore next */
          if (res && res['result'] != 'null') {
            // Elastic Search - FE757
            /* istanbul ignore next */
            if (res?.result?.designUrl) {
              this.gsiData = res['result'];
              this.getDesignByUrl(res?.result?.designUrl);
            }
          }
        });
    }
  }

  validateCUName(tenantName: any, CUName: string) {
    this.loader.show();
    this.changeUnitEndPoint
      .validateCUName(tenantName, CUName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res) {
            this.alertService.showToaster(
              'Change Unit already exists',
              '',
              'error'
            );
          }
        },
        (error: any) => {
          this.validatedCUName.next(true);
        }
      );
  }
  getReservedEntityById(id: any) {
    return this.changeUnitEndPoint.getEntityById(id);
  }
  nslFormula(data: any) {
    this.nslFormulaInfo.next(data);
  }
  fetchEmbGsiDetails(id: any) {
    //this.loader.show();
    this.changeUnitEndPoint.fetchEmbGsiDetails(id).subscribe((res: any) => {
      //this.loader?.hide();
      /* istanbul ignore next */
      if (res) {
        let result = res?.result;
        this.embededGsiDetails?.next(result);
      }
    });
  }
  deleteCu(triggerCUId: any, gsiId: any, isEmbedded?: any) {
    return this.http.delete(this.api?.deleteCu + `/${gsiId}/solution/${triggerCUId}`);
  }

  callCuSaveFunctionInCuComponent(nextCU: any) {
    this.openNextCu = true;
    this.nextCu = nextCU;
    this.cuSaveFunctionInCuComponent.next(true);
  }

  callOpenNextCuInComponent() {
    this.clickOpenCuInComponent.next(true);
  }
  resetNextCuDetails() {
    this.openNextCu = false;
    this.nextCu = undefined;
  }

  updateAgentFromAgentLists(currentAgentIndex: any) {
    this.removeAgentFromAgentsList.next(currentAgentIndex);
  }

  updateEntityFromEntityList(layerIndex: number, layer: any, layerType: any) {
    this.removeEntityFromEntityList.next({ layerIndex: layerIndex, layer: layer, layerType: layerType });
  }
    errorData(errData: any, type: any) {
    this.errorMessageDetails.next({ errData: errData, type: type });
  }
}
