import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { DropDownComponent } from './dropdown.component';
import { TooltipDirective } from './tool-tip.directive';
// import { TransactionMaterialModule } from 'projects/transaction-library/src/lib/transaction-material/transaction-material.module';

@NgModule({
  declarations: [DropDownComponent, TooltipDirective],
  imports: [ ControlsMaterialModule],

  exports: [DropDownComponent],
})
export class DropDownModule {
  static entry = {
    dropdown: DropDownComponent,
  };
}
