import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFeedback, LivechatEndpointService, Registration } from './livechat-endpoint.service';
import { CduiBaseService, ChatBotStore, ChatBotStoreActions } from '@common-services';

@Injectable({
  providedIn: 'root',
})
export class LivechatFacadeService extends CduiBaseService {
  chatMessage: any[] = [];
  updatedChatData = new Subject();
  updatedChatData$ = this.updatedChatData.asObservable();
  ngUnsubscribe = new Subject();
  selectedRoleData = new Subject();
  selectedRoleData$ = this.selectedRoleData.asObservable();
  isShown: boolean;
  suggestions = new Subject();
  suggestionsObservable = this.suggestions.asObservable();

  constructor(
    private livechatEndpointService: LivechatEndpointService,
    private chatBotStore: ChatBotStore,
    injector: Injector
  ) {
    super(injector);
    this.detectChatBotStateChange();
  }

  resumeChatbotState() {
    let data = this.chatBotStore.getStateData();

    setTimeout(() => {
      this.updatedChatData.next({ ...data, playlastMessage: false });
    });
  }

  detectChatBotStateChange() {
    this.chatBotStore.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedStoreData: any) => {
      this.updatedChatData.next(updatedStoreData);
    });
  }

  resetStore() {
    this.chatBotStore.setStateData({}, ChatBotStoreActions.ResetStore);
  }

  setChatBotState(body: any, action: any) {
    this.chatBotStore.setStateData(body, action);
  }

  connect(token: string, roomId: any, userInfo: any, jwtToken: string, flag?: string, dceoQueryType?: string) {
    /*istanbul ignore next*/
    let register: Registration = {
      userName: userInfo?.name,
      email: userInfo?.email,
      token: token,
      jwtToken: jwtToken,
      department:
        flag === 'TenantSpecific'
          ? localStorage.getItem('TenantName') == 'b2c'
            ? 'nslhub'
            : localStorage.getItem('TenantName')
          : 'nslhub',
      dceoCategory: dceoQueryType ? dceoQueryType : undefined,
    };
    this.livechatEndpointService.connectSockJS(token, roomId, userInfo, register);
  }

  send(token: string, roomId: any, message: string, selectedLanguage: string) {
    let body = {
      quest: message,
      read: true,
    };
    this.setChatBotState(body, ChatBotStoreActions.UpdateQuestion);
    this.livechatEndpointService.sendMessage(token, roomId, message, selectedLanguage);
  }

  disconnect(token: string, roomId: any) {
    this.livechatEndpointService.disconnect(token, roomId);
  }

  transcript(token: string, roomId: any, userInfo: any) {
    this.livechatEndpointService.transcript(token, roomId, userInfo);
    this.resetStore();
  }

  /*istanbul ignore next*/
  feedBack(data: IFeedback) {
    this.livechatEndpointService
      .sendFeedback(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res) {
          }
        },
        (error: any) => {
          if (error) {
          }
        }
      );
  }

  changeIsShown(flag: boolean) {
    this.isShown = flag;
    this.livechatEndpointService.changeIsShown(this.isShown);
  }

  setDepartment(data: any, roomId: string) {
    this.livechatEndpointService.disconnect(data?.token, roomId);
  }

  addWelcomeMessage(message: string, roomId: string) {
    this.livechatEndpointService.addWelcomeMessage(message, roomId);
  }
  
  getSelectedRoleData(data: any) {
    this.selectedRoleData.next(data);
  }
  fileUpload(formdata: FormData, roomId: string) {
    /*istanbul ignore else*/
    if (formdata) {
      return this.livechatEndpointService.fileUpload(formdata, roomId);
    }
  }

  fileDownload(data: any) {
    return this.livechatEndpointService.fileDownload(data);
  }

  getTranscriptData(roomId: string, tokenId: string) {
    return this.livechatEndpointService.getTranscriptData(roomId, tokenId);
  }

  getChatHistory(roomId: string) {
    return this.livechatEndpointService.getChatHistory(roomId);
  }

  addLanguageForBot(roomId: string, language: string) {
    this.livechatEndpointService.addLanguageForBot(roomId, language).subscribe((res) => {
      console.log(res);
    });
  }
  getLanguages(tenantSpecific: boolean) {
    return this.livechatEndpointService.getLanguages(tenantSpecific);
  }

  getAutoSuggestions(text: string, language: string) {
    this.livechatEndpointService
      .getAutoSuggestions(text,language)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log(res);
        this.suggestions.next(res);
      });
  }
}

