<ng-template #actionButton let-data="data">
  <span class="btn-close" *ngIf="data?.cardDisplay == 'design'">
    <i class="bi bi-x" *ngIf="childGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
    <button
    *ngIf="!isGrammer"
    #gsiAction
    attributeDesignConfig
    [isEdit]="isEdit"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [_style]="style"
    [tabSelected]="tabSelected"
    [isDesign]="isDesign"
    [isButton]="true"
    [_selectedDirectiveId]="selectedDirectiveId"
    (drop)="dropActionBtn($event)"
    (dragover)="allowDrop($event)"
    class="btn template-btn action-button btnaction"
    pTooltip="Action Button"
    [id]="'action-' + data?.position"
    tooltipPosition="top"
    tooltipStyleClass="transcustomtolltipbook"
    [ngClass]="data?.class"
    [cardDisplay]="cardDisplay"
    [styleGsi]="styleGsi?.[data?.position]"
  >
    {{childGsiMapping?.[data?.position] ? childGsiMapping?.[data?.position].name : 'Action Button'}}
  </button>
  </span>
  <ng-container *ngIf="data?.cardDisplay == 'view'">
    <ng-container *ngIf="data?.isButtonImg">
      <img
        (click)="gotoTxnPage((cardDetails?.gsiList)[data?.position], card)"
        tooltipPosition="top"
        *ngIf="(cardDetails?.gsiList)[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
        class="passcolor"
        [src]="
          '../../../../../assets/img/general-entity/templates/nineteenattributes/Red-arrow.png' | CDNUrlRewritePipe
        "
        alt="general-entity"
      />
    </ng-container>
    <ng-container *ngIf="!data?.isButtonImg"
      ><button
        [ngStyle]="{ background: btnHoveredBoolean[data?.position] ? btnHoverColors[data?.position] :btnColors?.[data?.position] }"
        (mouseover)="btnHoveredBoolean[data?.position] = true"
        (mouseout)="btnHoveredBoolean[data?.position] = false"
        [ngClass]="data?.class"
        (click)="gotoTxnPage(gsiMapping?.[data?.position], card, $event)"
        tooltipPosition="top"
        *ngIf="gsiMapping?.[data?.position]?.name"
        tooltipStyleClass="transcustomtolltipbook"
      >
        {{ gsiMapping?.[data?.position]?.displayName }}
      </button>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="data?.cardDisplay == 'design'">
    <li
      *ngIf="!data.isImage"
      [id]="attributeItem?.innerHTML"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      [_style]="style"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      [tabSelected]="tabSelected"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="styleAttribute[data?.position]"
    >
      <div [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }" class="attribute-unlink">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg'"
          alt=""
      /></span> -->
    </li>
    <li
      *ngIf="data?.isImage"
      id="attr-{{ data?.position }}"
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      [ngClass]="data?.class"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{childPositionMap?.[data?.position] ?childPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf="data.cardDisplay == 'view'">
    <ng-container *ngIf="!data?.isImage">
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        *ngIf="!data?.isSingleValue && !data?.isSingleDisplay"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        <strong class="passcolor">{{ card?.recordData?.[data.position]?.displayName }}</strong>
        <span>{{ card?.recordData?.[data.position]?.value }}</span>
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue"
        (click)="emitAddOnClick($event, data?.position, card)"
      >
        {{ card?.recordData?.[data.position]?.value }}
      </li>
      <span
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[attributeColors?.[data?.position]]"
        [conditionArray]="conditionArray"
        [attrHoveredColors]="[attrHoveredColors?.[data?.position]]"
        [_otherStyle]="[_otherStyle?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleDisplay"
        (click)="emitAddOnClick($event, data?.position, card)"
        >{{ card?.recordData?.[data.position]?.displayName }}</span
      >
    </ng-container>

    <ng-container *ngIf="data.isImage">
      <img
        [ngClass]="data.class"
        *ngIf="(card?.recordData)[data.position]?.contentUrl"
        [src]="(card?.recordData)[data.position]?.contentUrl | CDNUrlRewritePipe"
        alt="general-entity"
      />
    </ng-container>
  </ng-container>
</ng-template>

<div *ngIf="templateType == '181'">
  <div
    class="swiper-single-card entity-template-t181"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    CardDesignConfig
    [isEdit]="isEdit"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <section class="row expand-head">
      <div class="col-6">
        <div class="row primary-list">
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 1, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 3, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 4, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 5, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 6, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 7, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 8, class: 'template-title', cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <ul class="note">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 9, class: 'template-title', cardDisplay: cardDisplay }
            }"
          ></ng-template>
        </ul>
      </div>
      <div class="col-6">
        <div class="secondary-list">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 10, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 11, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 12, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 13, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 14, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 15, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
        </div>
      </div>
    </section>
    <div class="tertiary-list">
      <div class="row">
        <div class="col-3">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 16, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
        </div>
        <div class="col-3">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 17, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
        </div>
        <div class="col-3">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: { position: 18, class: 'template-title', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </ul>
        </div>
      </div>
    </div>
    <ul class="d-flex flex-wrap btn-list">
      <li>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'btn-primary' } }"
        ></ng-template>
      </li>
      <li>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, class: 'btn-secondary' } }"
        ></ng-template>
      </li>
      <li>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, class: 'btn-secondary' } }"
        ></ng-template>
      </li>
      <li>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 3, cardDisplay: cardDisplay, class: 'btn-secondary' } }"
        ></ng-template>
      </li>
      <li>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 4, cardDisplay: cardDisplay, class: 'btn-secondary' } }"
        ></ng-template>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="templateType == '182'">
  <div
    class="entity-template-t182"
    [ngClass]="{ 'design-t182': cardDisplay == 'design' }"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    CardDesignConfig
    [isEdit]="isEdit"
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <section class="expand-head">
      <ul class="primary-list">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="secondary-list">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 1, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="tertiary-list">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 2, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 3, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 4, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 5, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 6, class: 'template-title', cardDisplay: cardDisplay }
          }"
        ></ng-template>
      </ul>
      <ul class="btn-list">
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 0, cardDisplay: cardDisplay, class: 'btn btn-primary' }
            }"
          ></ng-template>
        </li>
        <li>
          <ng-template
            [ngTemplateOutlet]="actionButton"
            [ngTemplateOutletContext]="{
              data: { position: 1, cardDisplay: cardDisplay, class: 'btn btn-secondary' }
            }"
          ></ng-template>
        </li>
      </ul>
    </section>
  </div>
</div>
<div *ngIf="templateType == '317'">
  <div
    class="patient-details-card dynamic-card-block"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <ul class="flex-container">
      <li class="image-section card-logo">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 0, isImage: true, cardDisplay: cardDisplay, isSingleValue: false }
          }"
        ></ng-template>
      </li>
      <div class="content-section">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,

                class: 'member heading-text only-value text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
        <div class="section">
          <div class="division-section">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,

                    class: 'member only-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,

                    class: 'member id-number only-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="division-section">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,

                    class: 'member only-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,

                    class: 'member id-number only-value text-truncate',
                    cardDisplay: cardDisplay,
                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
      </div>
      <div class="member-card attribute-value">
        <ul>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 6,

                class: 'text-truncate passcolor',
                cardDisplay: cardDisplay,
                isSingleDisplay: true
              }
            }"
          ></ng-template>

          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 6,

                class: 'text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </ul>

    <div class="cont">
      <ul class="down-section">
        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 7,

              class: 'text-truncate passcolor',
              cardDisplay: cardDisplay,
              isSingleDisplay: true
            }
          }"
        ></ng-template>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: { position: 7, class: 'text-truncate', cardDisplay: cardDisplay, isSingleValue: true }
          }"
        ></ng-template>
      </ul>
      <ul class="section-2">
        <li class="checkbox text-truncate card-logo">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 8, isImage: true, cardDisplay: cardDisplay } }"
          ></ng-template>
        </li>

        <ng-template
          [ngTemplateOutlet]="attributeTemplate"
          [ngTemplateOutletContext]="{
            data: {
              position: 9,

              class: 'inputbox only-value text-truncate',
              cardDisplay: cardDisplay,
              isSingleValue: true
            }
          }"
        ></ng-template>
      </ul>
      <div class="section-3">
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, class: 'white-cta' } }"
        ></ng-template>
        <ng-template
          [ngTemplateOutlet]="actionButton"
          [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, class: 'green-cta' } }"
        ></ng-template>
      </div>
    </div>
  </div>
</div>
<div *ngIf="templateType == '315'">
  <div
    class="doctor-dashboard-details"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="dd-1">
      <ul>
        <li class="image">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 0, isImage: true, cardDisplay: cardDisplay, isSingleValue: false }
            }"
          ></ng-template>
        </li>
      </ul>
      <div class="details">
        <ul class="info">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 1,

                class: 'text text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 2,

                class: 'name text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 3,

                class: 'address text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 4,

                class: 'education text-truncate',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
        </ul>
        <ul class="count">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 5,

                class: 'member text-truncate passcolor',
                cardDisplay: cardDisplay,
                isSingleValue: true
              }
            }"
          ></ng-template>
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: {
                position: 5,

                class: 'class text-truncate passcolor',
                cardDisplay: cardDisplay,
                isSingleDisplay: true
              }
            }"
          ></ng-template>
        </ul>
      </div>
    </div>
    <!-- <ul class="count-s">
      <span class="class text-truncate passcolor">{{ card?.recordData?.[5]?.displayName }}</span>
      <li class="member text-truncate">{{ (card?.recordData)[5]?.value }}</li>
    </ul> -->
    <div class="p-cards">
      <ul class="p-card bg-1">
        <li class="image">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{
              data: { position: 6, isImage: true, cardDisplay: cardDisplay, isSingleValue: false }
            }"
          ></ng-template>
        </li>
        <div class="gsi-details">
          <div>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{ data: { position: 0, cardDisplay: cardDisplay, isButtonImg: true } }"
            ></ng-template>
          </div>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 7,

                  class: 'text-truncate passcolor',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 7,

                  class: 'lineclamp',
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </ul>
      <ul class="p-card bg-2">
        <li class="image">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 8, isImage: true, cardDisplay: cardDisplay } }"
          ></ng-template>
        </li>
        <div class="gsi-details">
          <div>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{ data: { position: 1, cardDisplay: cardDisplay, isButtonImg: true } }"
            ></ng-template>
          </div>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 9,

                  class: 'text-truncate passcolor',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 9,

                  class: 'lineclamp',
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </ul>
      <ul class="p-card bg-3">
        <li class="image">
          <ng-template
            [ngTemplateOutlet]="attributeTemplate"
            [ngTemplateOutletContext]="{ data: { position: 10, isImage: true, cardDisplay: cardDisplay } }"
          ></ng-template>
        </li>
        <div class="gsi-details">
          <div>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{ data: { position: 2, cardDisplay: cardDisplay, isButtonImg: true } }"
            ></ng-template>
          </div>
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 11,

                  class: 'text-truncate passcolor',
                  cardDisplay: cardDisplay,
                  isSingleValue: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 11,

                  class: 'lineclamp',
                  cardDisplay: cardDisplay,
                  isSingleDisplay: true
                }
              }"
            ></ng-template>
          </ul>
        </div>
      </ul>
    </div>
  </div>
</div>
<div class="entity-template-child-view entity-template-type swiper-single-card" *ngIf="templateType == '242'">
  <section
    class="row align-items-center"
    [ngStyle]="{ 'background-color': cardColor }"
    CardDesignConfig
    [isEdit]="isEdit"
    [ngStyle]="{ 'background-color': cardColor, 'box-shadow': cardShadow }"
    [id]="'Template-' + templateType"
    (emitHoverColor)="recieveHoverColor($event)"
    [_changeShadowStyle]="boxShadowData"
    [style]="style"
    [_selectedDirectiveId]="selectedDirectiveId"
    (emitSelectedElement)="recieveEmittedDirectiveId($event)"
    [cardBorderSize]="cardBorderSize"
    [cardBorderRadius]="cardBorderRadius"
    [cardBorderColor]="cardBorderColor"
    [cardShadow]="cardShadow"
    [cardColor]="cardColor"
    [card]="card"
    [cardDisplay]="cardDisplay"
    [(cardStyle)]="cardStyle"
  >
    <div class="col">
      <div class="row">
        <ul class="col-xl-2">
          <li class="list-attribute" id="">
            <strong class="title passcolor">{{ card?.recordData?.[0].attributeName }} </strong>
            <span
              ><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 0, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template
            ></span>
          </li>
        </ul>
        <ul class="col-xl-2">
          <li class="list-attribute" id="">
            <strong class="title passcolor">{{ card?.recordData?.[1].attributeName }} </strong>
            <span>{{ card?.recordData?.[1].value }}</span>
          </li>
        </ul>
        <ul class="col-xl-2">
          <li class="list-attribute" id="">
            <strong class="title passcolor">{{ card?.recordData?.[2].attributeName }} </strong>
            <span
              ><ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 2, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template
            ></span>
          </li>
        </ul>
        <ul class="col-xl-2">
          <li class="list-attribute" id="">
            <strong class="title passcolor">{{ card?.recordData?.[3].attributeName }} </strong>
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 3, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </span>
          </li>
        </ul>
        <ul class="col-xl-2">
          <li class="list-attribute" id="">
            <strong class="title passcolor">{{ card?.recordData?.[4].attributeName }} </strong>
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 4, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </span>
          </li>
        </ul>
        <ul class="col-xl-2">
          <li class="list-attribute" id="">
            <strong class="title passcolor">{{ card?.recordData?.[5].attributeName }} </strong>
            <span>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: { position: 5, isSingleValueWOC: true, cardDisplay: cardDisplay }
                }"
              ></ng-template>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <ul class="d-flex flex-wrap btn-list">
        <li>
          <button>
            <ng-template
              [ngTemplateOutlet]="actionButton"
              [ngTemplateOutletContext]="{
                data: { position: 0, class: 'btn btn-cta text-truncate', cardDisplay: cardDisplay }
              }"
            ></ng-template>
          </button>
        </li>
        <button class="expand-btn">
          <img src="../../../../../assets/img/general-entity/view-more.svg" alt="" />
        </button>
      </ul>
    </div>
  </section>
</div>
