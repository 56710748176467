import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { DateTimePickerModule } from 'ngx-datetime-picker';
import { DatetimeTypeWidgetsComponent } from './datetime-type-widgets.component';
import { MaterialModule } from '../materail/material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [DatetimeTypeWidgetsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TooltipModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    CalendarModule,
    DateTimePickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [DatetimeTypeWidgetsComponent],
  providers: [DatePipe],
})
export class DatetimeTypeWidgetsModule {}
