import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  HostListener,
  Renderer2,
  Injector,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { TranslatorService, AlertService, EventsFacadeService, TransactionFacadeService, displayValue, getCurrentEventDataArray, getEventData, paginationDetails, EventsEndpointService } from '@common-services';
import { SortEvent, ConfirmationService } from 'primeng/api';
import * as XLSX from 'xlsx';
import { Table } from 'primeng/table/table';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
// import { DialogComponent } from 'ui-controls';
import { DynamicUiTableComponent } from '../dynamic-ui-table/dynamic-ui-table.component';

@Component({
  selector: 'app-dynamic-ui-table-labels',
  templateUrl: './dynamic-ui-table-labels.component.html',
  styleUrls: ['./dynamic-ui-table-labels.component.scss'],
  providers: [DatePipe, ConfirmationService],
})
export class DynamicUiTableLabelsComponent extends DynamicUiTableComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  constructor(
    dialog: MatDialog,
    translator: TranslatorService,
    alertService: AlertService,
    confirmationService: ConfirmationService,
    eventService: EventsFacadeService,
    transactionFacade: TransactionFacadeService,
    renderer: Renderer2,
    public eventsEndPoint: EventsEndpointService,
      ) {
    super(dialog, translator, alertService, confirmationService, eventService, transactionFacade, renderer,eventsEndPoint );
    this.detectTableScrollEvent();
  }

  ngOnChanges(simple: SimpleChanges) {
    super.ngOnChanges(simple);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
