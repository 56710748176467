<ng-container>
  <div class="main efunds-bg">
    <div class="login">
      <figure class="logo-top">
        <img [src]="'../../../../assets/img/login/NG-logo.png' " alt="" />
      </figure>
      <figure class="banner-img">
        <img [src]="'../../../../assets/images/login-screen/Login Graphic.png' " alt="" />
      </figure>

    </div>
  </div>

  <!-- Header for login page -->

  <div class="login-container login-main-wrapper" *ngIf="!isCustomLoginTenant">
    <div class="login-box login-wrapper">
      <div class="middle-content">
        <div class="login-left-content">
          <!-- <ng-lottie [options]="lottieOptions" class="login-globe"></ng-lottie> -->
        </div>
        <div class="login-right-content">
          <div>
            <h4 class="card-title">
              {{ labels?.Sign_in }}
            </h4>
            <div class="otpsent">
              <h5>OTP has been sent to your email address</h5>
            </div>

            <form *ngIf="!otpLogin" (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
              <div class="form-container">
                <div *ngIf="!isSingletonLogin">
                  <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
                    {{ username_or_password_incorrect }}
                  </div>
                  <div class="form-group mb-0" id="drodownSelect">
                    <div>
                      <div class="form-field">
                        <div class="icon-filed">
                          <img [src]="'../../../../assets/img/login/icon-email.svg' " />
                        </div>
                        <input
                          type="text"
                          id="userName"
                          class="form-control loginUername"
                          formControlName="username"
                          autocomplete="username"
                          placeholder="Username, Email, Mobile Number or Customer Id"
                        />
                        <span hidden translate>{{ labels?.Username }}</span>
                        <small
                          [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                          class="text-danger"
                          name="otpNumber"
                          (keydown)="checkForNumbers($event)"
                          class="text-danger"
                          translate
                        >
                          {{ labels?.Usernameisrequired }}
                        </small>
                      </div>
                      <!-- <label class="d-block">
                        <input
                          type="text"
                          id="userName"
                          class="form-control loginUername"
                          formControlName="username"
                          autocomplete="username"
                          [placeholder]="labels?.Username"
                        />
                        <span hidden translate>{{ labels?.Username }}</span>
                        <small
                          [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
                          class="text-danger"
                          name="otpNumber"
                          (keydown)="checkForNumbers($event)"
                          class="text-danger"
                          translate
                        >
                          {{ labels?.Usernameisrequired }}
                        </small>
                      </label> -->
                      <div class="form-field">
                        <div class="icon-filed">
                          <img [src]="'../../../../assets/img/login/icon-password.svg' " />
                        </div>
                        <input
                          class="login-input-pwd"
                          [type]="showPassword ? 'text' : 'password'"
                          id="passWord"
                          oncopy="return false"
                          oncut="return false"
                          class="form-control mb-0 loginPassword"
                          formControlName="password"
                          autocomplete="current-password"
                          [placeholder]="labels?.Password"
                          required
                          (paste)="preventPasting($event)"
                        />
                        <div class="toggle-eye-icon">
                          <mat-icon (click)="toggleShowPassword()">
                            {{ showPassword ? 'visibility' : 'visibility_off' }}
                          </mat-icon>
                        </div>
                        <span hidden translate>{{ labels?.Password }}</span>
                        <small
                          [hidden]="loginForm.controls.password.valid || loginForm.controls.password.untouched"
                          class="text-danger"
                          translate
                        >
                          {{ labels?.Password_is_required }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <!-- Div for Logging via OTP feature Starts-->
                  <div *ngIf="signupProperties2fa?.sessionType != '2fa'" class="login-mobile">
                    <p class="loginOTP" id="loginOTP" (click)="otpLoginClick()">Login via Mobile</p>
                  </div>
                  <!-- Div for Logging via OTP feature Ends-->
                  <button class="btn-custom" type="submit">
                    <em class="fas fa-cog fa-spin" [hidden]="!isLoading"></em>
                    <span id="submitBtn" class="loginSubmitBtn" translate>{{ labels?.Submit }}</span>
                  </button>
                  <div class="d-flex justify-content-between">
                    <p class="forgotPassword" id="forgotPasswords">
                      <span (click)="forgotClick(true)">Forgot Password ?</span>
                    </p>
                    <p
                      *ngIf="tenantName === 'selfonboard' || allowSelfSignup"
                      class="forgotPassword"
                      id="selfonboardSignUp"
                    >
                      <span (click)="selfonboardSignUp()">Sign Up</span>
                    </p>
                  </div>
                  <div *ngIf="allowSelfSignup" class="" id="forgotPasswords" (click)="forgotCustomerid()">
                    <p class="forgotPassword" id="forgotPasswords">
                      <span (click)="forgotCustomerid()">Forgot Customer Id?</span>
                    </p>
                  </div>
                </div>
                <div *ngIf="identityProvidersList?.length > 0" class="social-logins">
                  <div class="top-level">
                    <div class="login-via">
                      Or Login via
                    </div>
                    <div class="signupwith" *ngFor="let provider of identityProvidersList">
                      <a href="javascript:;" *ngIf="provider.isImageExist">
                        <img
                          src="../../../../assets/img/login/icon-{{ provider?.alias }}.svg"
                          (click)="socialLogin(provider?.alias)"
                          alt=""
                        />
                      </a>
                      <a href="javascript:;" *ngIf="!provider.isImageExist" class="provider-btn">
                        <p (click)="socialLogin(provider?.alias)">{{ provider?.displayName }}</p>
                      </a>
                    </div>
                  </div>
                  <!-- <div class="bottom-level">
                    <div class="signupwith" *ngFor="let provider of identityProvidersList">
                      <a href="javascript:;" *ngIf="!provider.isImageExist" class="provider-btn">
                        <p (click)="socialLogin(provider?.alias)">{{ provider?.displayName }}</p>
                      </a>
                    </div>
                  </div> -->

                  <!-- <div class="signupwith">
                    <a href="javascript:;"
                      ><img
                        [src]="'../../../../assets/img/login/icon-google.svg' "
                        (click)="socialLogin('google')"
                        alt=""
                    /></a>
                  </div>
                  <div class="signupwith">
                    <a href="javascript:;"
                      ><img
                        [src]="'../../../../assets/img/login/icon-in.svg' "
                        (click)="socialLogin('linkedin')"
                        alt=""
                    /></a>
                  </div>
                  <div class="signupwith">
                    <a href="javascript:;"
                      ><img
                        [src]="'../../../../assets/img/login/icon-github.svg' "
                        (click)="socialLogin('github')"
                        alt=""
                    /></a>
                  </div>
                  <div class="signupwith" *ngIf="loginApiKey === 'carnivals'">
                    <a href="javascript:;"
                      ><img
                        [src]="'../../../../assets/img/login/icon-apple.svg' "
                        (click)="socialLogin('apple')"
                    /></a>
                  </div> -->
                </div>
              </div>
            </form>

            <form *ngIf="otpLogin" [formGroup]="loginForm" novalidate>
              <div class="form-container otplogin viamobile">
                <div class="form-field form-login">
                  <div class="select-country">
                    <mat-form-field>
                      <div class="icon-filed">
                        <img [src]="'../../../../assets/img/login/icon-phone.svg' " />
                      </div>
                      <mat-label class="country-label">Select Country</mat-label>
                      <mat-select [(ngModel)]="selectedCountryCode" [ngModelOptions]="{ standalone: true }">
                        <mat-option *ngFor="let country of countryCodes" [value]="country['countryCode']"
                          >{{ country['countryName'] }} : {{ country['countryCode'] }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <mat-form-field appearance="standard" class="reg-mobile-number">
                    <mat-label>{{ labels?.Registered_Mobile_Number }}</mat-label>
                    <input
                      matInput
                      type="tel"
                      id="mobileNumberRegistered"
                      name="mobileNumber"
                      [(ngModel)]="mobileNumber"
                      class="form-control reg-mobile-number-input"
                      (keydown)="mobileNumberEntered(mobileNumber)"
                      (keypress)="acceptMobileNumber($event)"
                      [ngModelOptions]="{ standalone: true }"
                      required
                    />
                  </mat-form-field>
                </div>

                <div class="otpvalid" *ngIf="mobileNumberFieldEntered">
                  <div class="form-field">
                    <div class="icon-filed">
                      <img [src]="'../../../../assets/img/login/icon-otp.svg' " />
                    </div>
                    <label class="d-block">
                      <input
                        type="text"
                        maxlength="6"
                        size="6"
                        class="form-control"
                        [(ngModel)]="otpCapture"
                        id="otpReceived"
                        placeholder="Enter OTP"
                        [ngModelOptions]="{ standalone: true }"
                        (keypress)="numberOnly($event)"
                        required
                      />
                    </label>
                    <div class="otpvalid-info" *ngIf="otpValidval">
                      <span class="otpText">OTP valid for</span>
                      <span>{{ display }}</span>
                    </div>
                    <!-- <div class="otpdidnt" *ngIf="resendOTP">
                      <span class="otpText">Didn't receive OTP?</span>
                      <span (click)="resendOTPtoMobile()">Resend OTP</span>
                    </div> -->
                  </div>
                </div>

                <!-- <div class="form-group mb-0"> -->
                <!-- <form>
                    <div class="mobilefieldauto mb-5">
                      <input
                          type="tel"
                          id="mobileNumberRegistered"
                          name="mobileNumber"
                          [(ngModel)]="mobileNumber"
                          class="form-control loginUername"
                          placeholder="Registered Mobile Number"
                          (keydown)="mobileNumberEntered(mobileNumber)"
                          (keypress)="acceptMobileNumber($event)"
                          [ngModelOptions]="{ standalone: true }"
                          required
                        />
                    </div>
                  </form> -->
                <!-- <div class="otpvalid" *ngIf="mobileNumberFieldEntered">
                    <label class="d-block">
                      <input
                          type="text"
                          maxlength="6"
                          size="6"
                          class="form-control loginUername"
                          [(ngModel)]="otpCapture"
                          id="otpReceived"
                          placeholder="Enter OTP"
                          [ngModelOptions]="{ standalone: true }"
                          (keypress)="numberOnly($event)"
                          required
                        />
                    </label>
                    <div class="otpvalid-info" *ngIf="otpValidval">
                      <span class="otpText">OTP valid for</span>
                      <span>{{ display }}</span>
                    </div>
                    <div class="otpdidnt" *ngIf="resendOTP">
                      <span class="otpText">Didn't receive OTP?</span>
                      <span (click)="resendOTPtoMobile()">Resend OTP</span>
                    </div>
                  </div> -->
                <!-- </div> -->
                <div class="login-otp-cls">
                  <span *ngIf="!mobileNumberFieldEntered">
                    <a
                      *ngIf="!otpLogin"
                      class="loginOTP loginotpclr"
                      (click)="otpLoginClick()"
                      [ngClass]="{ disabled: !mobileNumber }"
                      >Login via Mobile</a
                    >
                  </span>
                  <!-- <a *ngIf="otpLogin" class="loginOTP loginotpclr mt-96" (click)="generateOTP()">Login via OTP</a> -->

                  <!-- <a *ngIf="otpLogin" class="loginOTP loginotpclr" (click)="cancelForgotPassword()">Login via Email</a> -->
                  <a id="sentOTPbtn" [ngClass]="display && display != '00:00' ? 'disable-content' : ''" class="btn-custom" (click)="generateOTP()">
                    <!-- {{ labels?.CANCEL }} -->
                    Send OTP
                  </a>
                </div>
                <div class="footer-cls">
                  <button
                    class="btn-custom"
                    type="submit"
                    [disabled]="
                      isLoading ||
                      (otpLogin && !mobileNumberFieldEntered) ||
                      !mobileNumber ||
                      (mobileNumberFieldEntered && !otpCapture)
                    "
                    (click)="validateOTP()"
                    id="submitBtn"
                  >
                    <em class="fas fa-cog fa-spin" [hidden]="!isLoading"></em>
                    <span *ngIf="otpLogin">{{ labels?.Submit }}</span>
                  </button>
                </div>
                <div>
                  <!-- <p class="forgotPassword" id="forgotPassword">
                    <span (click)="forgotClick()">Forgot Password ?</span>
                  </p> -->
                  <a *ngIf="otpLogin" class="loginOTP loginotpclr" (click)="cancelForgotPassword()">Login via Email</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="!tenantExists && !tenantName && !clientId">
  <!-- <app-tenant-not-found [tenantName]="tenantName"></app-tenant-not-found> -->
</div>
<ng-container *ngIf="twofactorverification">
  <div class="main" *ngIf="isCustomLoginTenant">
    <div class="login">
      <figure class="banner-img">
        <img [src]="'../../../../assets/images/login-screen/Login Graphic.png' " alt="" />
      </figure>
      <form>
        <div class="login-details">
          <h1>Forgot Password</h1>
          <input
            type="email"
            id="registeredEmailId"
            [(ngModel)]="email"
            name="email"
            required
            placeholder="Enter Registered Email /Mobile Number"
            class="forgot-password-input"
          />
          <div class="forgot-btns">
            <button id="forgotSubmit" (click)="clickSubmit()" class="btn-submit" [disabled]="otp===undefined || otp===''">
              <p>{{ labels?.Submit }}</p>
            </button>
            <button id="forgotPswdCancel" (click)="cancelClick()" class="btn-cancel">
              <p>{{ labels?.Cancel }}</p>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="login-container login-main-wrapper" *ngIf="!isCustomLoginTenant">
    <div class="login-box login-wrapper">
      <div class="middle-content">
        <div class="login-left-content">
          <!-- <ng-lottie [options]="lottieOptions" class="login-globe"></ng-lottie> -->
        </div>
        <div class="login-right-content">
          <div class="w-100">
            <div>
              <h4 class="card-title">2 Factor Authentication</h4>
            </div>
            <form>
              <div class="form-field">
                <div class="icon-filed">
                  <!-- <img [src]="'../../../../assets/img/login/icon-email.svg' " /> -->
                  <mat-icon> mobile_screen_share</mat-icon>
                </div>
                <input
                  type="email"
                  class="form-control"
                  id="registeredEmailId"
                  email
                  [(ngModel)]="otp"
                  name="email"
                  required
                  placeholder="Enter OTP"
                />
              </div>
              <div class="footer-cls">
                <button class="btn-custom" id="forgotSubmit" (click)="clickSubmit()">{{ labels?.Submit }}</button>
                <button
                  class="btn-custom btn-cancel"
                  id="forgotPswdCancel"
                  (click)="resendOtp()"
                  >
                  <!-- [disabled]="timeLeft > 0"
                  [ngClass]="{ 'my-class': timeLeft > 0 }" -->
                  Resend
                </button>
              </div>
              <div class="footer-resend-text">
                <!-- <p *ngIf="timeLeft != 0">
                  Re-send OTP in <span>{{ timeLeft }}</span> seconds
                </p> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
