import { compositeExtractionDeMapper } from '../composite-extration/compositeExtraction-demapper';
import { entityDeMapper } from '../entity/entity-demapper';

let transform = require('node-json-transform').transform;

export function solutionLogicDeMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'id',
      id: 'id',
      name: 'name',
      agents: 'agents',
      cuType: 'cuType',
      actionLabel: 'actionLabel',
      layers: 'layers',
      displayName: 'displayName',
      description: 'description',
      ontology: 'ontology',
      index: 'index',
      dsdReferencedChangeUnit: 'referencedChangeUnit',
      referencedChangeUnit: 'tfReferencedChangeUnit',
      masterId: 'masterId',
      nextTriggerSet: 'nextTriggerSet',
      reservedCUType: 'reservedCUType',
      isFaceted: 'isFaceted',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      keywords: 'keywords',
      attachments: 'attachments',
      dcd: 'dcd',
      mindCUList: 'mindCUList',
      exceptionCUList: 'exceptionCUList',
      cuSystemProperties: 'cuSystemProperties',
      source: 'source',
      eventCUList: 'eventCUList',
      constraint: 'constraint',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      constraintName: 'constraintName',
      slotItemProperties: 'slotItemProperties',
      specialFeatureProperties: 'specialFeatureProperties',
      condition: 'condition',
      conditionName: 'conditionName',
      ownerId: 'ownerId',
      uiProperties: 'uiProperties',
      estimatedTime: 'estimatedTime',
      pathwaysCountFromCurrentCU: 'pathwaysCountFromCurrentCU',
      // querySentence: 'sentenceTags',
      tCUsentenceName: 'sentenceTags',
      mileStoneId: 'mileStoneId',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      version: 'version',
      isParallel: 'isParallel',
      attributeMapping: 'attributeMapping',
      nestedOutgoingDCDs: 'nestedOutgoingDCDs',
      disableSTEs: 'disableSTEs',
      triggerCULayers: 'triggerCULayers',
      measures: 'measures',
      rcuRecommendations: 'rcuRecommendations',
      conflicts: 'conflicts',
      hasConflict: 'hasConflict',
      txnDataSaveMode: 'txnDataSaveMode',
      transactionEndpoint: 'transactionEndpoint',
      triggerCUDialogList: 'triggerCUDialogList',
      exceptions: 'exceptions',
      isNameUpdated: 'isNameUpdated',
      isTemplate: 'isTemplate',
    },
    operate: [
      // {
      //   run: (val: any) => 'BASIC',
      //   on: 'cuType',
      // },
      {
        run: function (val: any) {
          if (val === /*ChangeUnitTypes.GSI*/ 'GSI') {
            return val;
          } else {
            return /*ChangeUnitTypes.Basic*/ 'Basic';
          }
        },
        on: 'cuType',
      },
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((y: any) => {
              /* istanbul ignore next */
              return {
                nextCUName: y.nextCUName,
                index: y.index,
                dsdNextCUId: y?.nextCUId,
                isParallel: y?.isParallel,
              };
            });
          } else {
            return [];
          }
        },
        on: 'nextTriggerSet',
      },
      // {
      //   run: function (val: any) {
      //     if (data?.dsdReferencedChangeUnit === null || data?.dsdReferencedChangeUnit === undefined) {
      //       return data?.referencedChangeUnit;
      //     }
      //   },
      //   on: 'dsdReferencedChangeUnit',
      // },
      {
        run: function (val: any) {
          let layers = transform(val, nestedMap);
          // check if layers are empty
          /* istanbul ignore next */
          return layers?.filter(
            (layer: any) => layer.participatingItems?.length > 0
          );
        },
        on: 'layers',
      },
      {
        run: (val: any) => {
          /*istanbul ignore next*/

          if (val !== undefined && typeof val === 'string') {
            return Number(val);
          } else {
            return val;
          }
        },
        on: 'id',
      },
      {
        run: function (val: any) {
          let triggerCULayers = transform(val, nestedMap);
          // check if layers are empty
          /* istanbul ignore next */
          return triggerCULayers?.filter(
            (layer: any) => layer.participatingItems?.length > 0
          );
        },
        on: 'triggerCULayers',
      },
      {
        run: function (val: any) {
          if (val && val.length > 0) {
            return val
              .filter((cu: any) => {
                /* istanbul ignore next */
                return (cu.isGsi && cu.dsdGsiId) || !cu.isGsi ? true : false;
              })
              .map((ele: any) => {
                let genericObj = {
                  eventExpressions: ele?.eventExpressions,
                  isPopupEvent: ele?.isPopupEvent,
                  changeDriverId: ele.changeDriverId,
                  dsdChangeDriverId: ele.dsdChangeDriverId,
                  eventType: ele.eventType,
                  isGsi: ele.isGsi,
                  ownerId: ele.ownerId,
                  slotContextualId: ele?.slotContextualId ? ele?.slotContextualId : '',
                  slotContextualName: ele?.slotContextualName ? ele?.slotContextualName : '',
                  sourceChangeDrivers: ele?.sourceChangeDrivers,
                  targetChangeDrivers: ele?.targetChangeDrivers,
                };
                if (ele.isGsi) {
                  return {
                    ...genericObj,
                    attributeMappings: ele?.attributeMappings,
                    dsdGsiId: ele.dsdGsiId,
                    gsiId: ele.gsiId,
                    gsiName: ele.gsiName,
                    id: ele.id,
                  };
                } else {
                  return {
                    ...genericObj,
                    referenceEntityInfo: ele?.referenceEntityInfo,
                  };
                }
              });
          }
        },
        on: 'eventCUList',
      },
      {
        run: (val: any) => {
          return val?.map((x: any) => {
            return {
              ...x,
              formula: x.formulaName ? x.formulaName : x.formula,
              sourceContextualId: x.sourceContextualName,
              targetContextualId: x.targetContextualName,
              sourceArray: setSourceArray(x.sourceArray),
            };
          });
        },
        on: 'dcd',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val ? val : undefined;
        },
        on: 'constraint',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraintName',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val ? val : undefined;
        },
        on: 'estimatedTime',
      },
      {
        run: (val: any) => {
          if (val !== undefined && Object.keys(val).length) {
            return val;
          } else {
            return {};
          }
        },
        on: 'uiProperties',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined) {
            /* istanbul ignore next */
            return JSON.stringify(compositeExtractionDeMapper(val));
          } else {
            return val;
          }
        },
        on: 'querySentence',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined) {
            // val = removeDataType(val);
            /* istanbul ignore next */
            return JSON.stringify(val);
          } else {
            return val;
          }
        },
        on: 'tCUsentenceName',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined && !data?.reservedCUType?.includes('EQL')) {
            Object.keys(val)?.forEach((key: any) => {
              if (
                !val?.[key]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute
                  ?.length
              )
                delete val[key];
              else {
                val[key].propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute = val[
                  key
                ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute?.filter(
                  (attr: any) => {
                    return filterNested(attr);
                  }
                );
                val[key].propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute = val[
                  key
                ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute?.filter(
                  (attr: any) => {
                    return attr?.values || attr?.txnGeneralEntity;
                  }
                );
              }
              if (
                !val?.[key]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute
                  ?.length
              )
                delete val[key];
            });
            return val;
          } else {
            return val;
          }
        },
        on: 'slotItemProperties',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined) {
            /* istanbul ignore next */
            if (val?.length > 0) {
              /* istanbul ignore next */
              val?.forEach((agent: any) => {
                if (agent?.condition && agent?.condition?.fromDateTime === '') {
                  delete agent.condition;
                }
              });
            }
            return val;
          } else {
            return val;
          }
        },
        on: 'designTimeRights',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined) {
            /* istanbul ignore next */
            if (val?.length > 0) {
              /* istanbul ignore next */
              val?.forEach((agent: any) => {
                if (agent?.condition && agent?.condition?.fromDateTime === '') {
                  delete agent.condition;
                }
              });
            }
            return val;
          } else {
            return val;
          }
        },
        on: 'txnTimeRights',
      },
    ],
  };

  function setSourceArray(sourceArray: any) {
    return sourceArray?.map((x: any) => {
      return {
        ...x,
        id: x.name,
      };
    });
  }

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          return val?.map((x: any) => {
            return {
              id: x.participatingItemId,
              isMultiValue: x.isMultiValue,
              isPlaceholder: x.isPlaceholder,
              conditionalPotentiality: x?.conditionalPotentiality,
              conditionalPotentialityName: x?.conditionalPotentialityName,
              changeDriverMetaData: x.changeDriverMetaData, /// optional changes drivers data ///
              item: {
                DATA: entityDeMapper(x),
                TYPE: 'GeneralEntity',
              },
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };

  const result = transform(data, map);

  return result;
}
function filterNested(attr: any) {
  if (attr?.txnGeneralEntity) {
    attr?.txnGeneralEntity?.transEntityRecords?.forEach((record: any, i: number) => {
      record.txnNslAttribute = record.txnNslAttribute?.filter((nestedAttr: any) => {
        return filterNested(nestedAttr);
      });
      if (!record?.txnNslAttribute?.length) {
        delete record?.txnNslAttribute;
      }
      if (!record?.txnNslAttribute) {
        delete attr?.txnGeneralEntity?.transEntityRecords[i];
      }
      if (attr?.txnGeneralEntity?.name == 'Taxes Summary') console.log(attr?.txnGeneralEntity, 'nameeeeeee');
    });

    if (!attr?.txnGeneralEntity?.transEntityRecords || !attr?.txnGeneralEntity?.transEntityRecords?.[0]) {
      delete attr?.txnGeneralEntity;
    }
    return true;
  } else if (attr?.values?.length) {
    return true;
  }
  return false;
}
