import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
export class ImageMapCoordinate {
    x = 0;
    y = 0;
    width = 100;
    height = 100;
    name?: string;
    page_no?: number;
    flag?: boolean;
    constructor(init?: Partial<ImageMapCoordinate>) {
      Object.assign(this, init);
    }
  }
  
@Component({
  selector: 'app-node-ocr-left',
  templateUrl: './node-ocr-left.component.html',
  styleUrls: ['./node-ocr-left.component.scss'],
})
export class NodeOcrLeftComponent implements OnInit, OnDestroy {
  @Input() ocrData: any;
  coordinates: any;
  originalDocumentSize: any;
  image: any;
  filename: any;
  fileType: any;

  @Output() emitCoordinates: EventEmitter<any> = new EventEmitter();
  constructor() {}
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.coordinates = this.ocrData?.mappings;
    this.originalDocumentSize = this.ocrData?.ref_file_dimenstions;
    this.image = this.ocrData?.ref_doc;
    this.filename = this.ocrData?.file_name;
    this.fileType = this.ocrData?.file_type;
  }
  selectedField(coordinate: ImageMapCoordinate) {
    this.emitCoordinates.emit(coordinate);
  }
}
