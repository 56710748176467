<ng-template #actionButton let-data="data">
  <ng-container *ngIf="cardDisplay == 'design'">
    <span class="btn-close">
      <i class="bi bi-x" *ngIf="expandGsiMapping?.[data?.position]" (click)="removeGsiFromCard(data?.position)"></i>
      <button
        *ngIf="!isGrammer"
        #gsiAction
        attributeDesignConfig
        [isEdit]="isEdit"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [_style]="style"
        [tabSelected]="tabSelected"
        [isDesign]="isDesign"
        [isButton]="true"
        [_selectedDirectiveId]="selectedDirectiveId"
        (drop)="dropActionBtn($event)"
        (dragover)="allowDrop($event)"
        class="btn template-btn action-button btnaction text-truncate"
        [id]="'action-' + data?.position"
        [ngClass]="data?.class"
        [cardDisplay]="cardDisplay"
        [styleGsi]="expandStyleGsi?.[data?.position]"
      >
        {{expandGsiMapping?.[data?.position] ? expandGsiMapping?.[data?.position].name : 'Action Button'}}
      </button>
    </span>
  </ng-container>
  <ng-container *ngIf="cardDisplay == 'view'">
    <button
      [ngClass]="data?.class"
      (click)="gotoTxnPage((cardDetails?.gsiList)[data?.position], card)"
      [ngStyle]="{ background: btnHoveredBoolean[data?.position] ? btnHoverColors[data?.position] :btnColors?.[data?.position] }"
      (mouseover)="btnHoveredBoolean[data?.position] = true"
      (mouseout)="btnHoveredBoolean[data?.position] = false"
      pTooltip=" {{ (cardDetails?.gsiList)[data?.position]?.name }}"
      tooltipPosition="top"
      tooltipStyleClass="transcustomtolltipbook"
      *ngIf="(cardDetails?.gsiList)[data?.position]?.name"
    >
      {{ (cardDetails?.gsiList)[data?.position]?.name }}
    </button>
  </ng-container>
</ng-template>
<ng-template #attributeTemplate let-data="data">
  <ng-container *ngIf="cardDisplay == 'design'">
    <li
      *ngIf="!data?.isImage"
      [id]="attributeItem?.innerHTML?.trim()"
      class="{{ data?.class }}"
      (drop)="dropAttribute($event, 'any')"
      attributeDesignConfig
      [cardDisplay]="cardDisplay"
      [isEdit]="isEdit"
      [_style]="style"
      (emitSelectedElement)="recieveEmittedDirectiveId($event)"
      [isDesign]="isDesign"
      [_isCondition]="conditionColors"
      [_selectedDirectiveId]="selectedDirectiveId"
      (emitSelectedConditionElement)="recieveConditionColor($event)"
      (dragover)="allowDrop($event)"
      [(styleAttribute)]="expandStyleAttribute[data?.position]"
      [tabSelected]="tabSelected"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{expandPositionMap?.[data?.position] ?expandPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{expandPositionMap?.[data?.position] ?expandPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          width="24"
          height="24"
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
          alt=""
      /></span> -->
    </li>
    <li
      (drop)="dropAttribute($event, 'image')"
      (dragover)="allowDrop($event)"
      *ngIf="data?.isImage"
      [ngClass]="data.class"
      id="attr-{{ data?.position }}"
    >
      <div class="attribute-unlink" [ngClass]="{ 'nh-pr-12': attributeItem?.innerHTML?.trim() !== 'Attribute' }">
        <div #attributeItem id="attr-{{ data?.position }}">
          {{expandPositionMap?.[data?.position] ?expandPositionMap?.[data?.position]?.name : "Attribute" }}
        </div>
        <em
          class="nh-icon nh-icon-trash01 cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ></em>
      </div>
      <!-- <span
        class="main-attr"
        #attributeItem
        id="attr-{{ data?.position }}"
        >{{expandPositionMap?.[data?.position] ?expandPositionMap?.[data?.position]?.name : "Attribute" }}</span
      >
      <span class="delete-attr" *ngIf="attributeItem?.innerHTML?.trim() !== 'Attribute'"
        ><img
          class="cursor-pointer"
          (click)="onDeletingAttr(attributeItem)"
          [src]="'../../../../../assets/img/addmeasures/delete.svg' | CDNUrlRewritePipe"
          alt=""
      /></span> -->
    </li>
  </ng-container>
  <ng-container *ngIf="cardDisplay == 'view'">
    <ng-container *ngIf="data?.isImage">
      <img
        [ngClass]="data?.class"
        *ngIf="card.recordData[data?.position]?.contentUrl"
        [src]="card.recordData[data?.position]?.contentUrl | CDNUrlRewritePipe"
        alt=" "
      />
    </ng-container>
    <ng-container *ngIf="!data?.isImage">
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[expandColors?.[data?.position]]"
        [conditionArray]="conditionArrayExpand"
        [attrHoveredColors]="[attrhoveredColors?.[data?.position]]"
        [_otherStyle]="[otherStyles?.[data?.position]]"
        [ngClass]="data?.class"
        *ngIf="data?.isSingleValue"
      >
        {{ (card?.recordData)[data?.position]?.value }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[expandColors?.[data?.position]]"
        [conditionArray]="conditionArrayExpand"
        [attrHoveredColors]="[attrhoveredColors?.[data?.position]]"
        [_otherStyle]="[otherStyles?.[data?.position]]"
        *ngIf="data?.isSingleValueWOC"
      >
        {{ (card?.recordData)[data?.position]?.value }}
      </li>
      <li
        CardDesignConfig
        [isAttributeLevel]="true"
        [isEntityLevel]="false"
        [singleRecord]="card?.recordData?.[data.position]"
        [attributeColors]="[expandColors?.[data?.position]]"
        [conditionArray]="conditionArrayExpand"
        [attrHoveredColors]="[attrhoveredColors?.[data?.position]]"
        [_otherStyle]="[otherStyles?.[data?.position]]"
        *ngIf="data?.isSingleDisplay"
      >
        {{expandDetails?.[data?.position]}}
      </li>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #closeExpand>
  <div class="top-header cursor-pointer" [ngClass]="{ 'd-none': expandType == 20 }">
    <mat-icon (click)="closeCard()">close</mat-icon>
  </div>
</ng-template>
<!-- <div class="multi-attribute-cards-expand">
  <div class="mat-icon-multi-attributes" [ngClass]="{ 'd-none': expandType == 20 }">
    <mat-icon (click)="closeCard()">close</mat-icon>
  </div>
</div> -->
<div class="insurance-expandcards-preview">
  <div class="entitytemplates row">
    <div class="col-12" *ngIf="expandType == '74'">
      <div
        class="insurance-expand-card-1 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-label',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-subtitle-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '75'">
      <div
        class="insurance-expand-card-2 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '75'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-subtitle-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-secondary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-secondary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 4,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '76'">
      <div
        class="insurance-expand-card-3 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '76'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'card-title',

                  isSingleValue: true
                }
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'card-subtitle',

                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
          <div class="expandcard row">
            <div class="card-img col-8">
              <ul>
                <li class="card-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          class: 'template-img',

                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>
              </ul>
            </div>
            <div class="card-data col-4">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 3,
                      class: 'card-subtitle-bold',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 4,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 5,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 6,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 7,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
              <div class="btn-cta">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      class: 'btn btn-preview btn-tooltip'
                    }
                  }"
                ></ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '77'">
      <div
        class="insurance-expand-card-4 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '77'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-title-black',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-subtitle-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle-medium',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="ul-steps">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>

            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '78'">
      <div
        class="insurance-expand-card-5 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '78'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-data col-6">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-subtitle-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
          <div class="card-img col-6">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 3,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '79'">
      <div
        class="insurance-expand-card-6 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '79'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-6">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-label',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '80'">
      <div
        class="insurance-expand-card-7 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '80'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="card-logo">
          <ul>
            <li class="card-icon">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      class: 'template-img',

                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'card-label',

                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>

        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 2,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="lisrgroup">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'listgroup-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'listgroup-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'listgroup-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'listgroup-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-data-box col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'box-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'box-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'box-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'box-title-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn btn-preview btn-tooltip',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '81'">
      <div
        class="insurance-expand-card-8 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '81'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-steps2',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps3',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-steps4',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="row-ul">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-steps5',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li>
                <div class="btn-cta">
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'btn cta-btn-primary btn-preview btn-tooltip'
                      }
                    }"
                  ></ng-template>
                  <ng-template
                    [ngTemplateOutlet]="actionButton"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'btn cta-btn-primary btn-preview btn-tooltip'
                      }
                    }"
                  ></ng-template>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '82'">
      <div
        class="insurance-expand-card-9 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '82'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="ul-labels">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 11,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-9">
            <ul class="ul-labels">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: 'card-title-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '83'">
      <div
        class="insurance-expand-card-10 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '83'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-5">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 2,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-label',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-data col-7">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'card-subtitle clrblack',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '84'">
      <div
        class="insurance-expand-card-11 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '84'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-label',
                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-subtitle-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle-medium',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-subtitle-medium',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-subtitle-large1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '85'">
      <div
        class="insurance-expand-card-12 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '85'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <!-- <li class="linebar"></li> -->

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-subtitle-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-title-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <button
                class="btn cta-btn-secondary btn-preview btn-tooltip"
                (click)="gotoTxnPage((cardDetails?.gsiList)[1], card)"
                [ngStyle]="{ background: btnColors[1] }"
                pTooltip=" {{ (cardDetails?.gsiList)[1]?.name }}"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
                *ngIf="(cardDetails?.gsiList)[1]?.name"
              >
                {{ (cardDetails?.gsiList)[1]?.name }}
              </button>
              <button
                class="btn cta-btn-secondary btn-preview btn-tooltip"
                (click)="gotoTxnPage((cardDetails?.gsiList)[2], card)"
                [ngStyle]="{ background: btnColors[2] }"
                pTooltip=" {{ (cardDetails?.gsiList)[2]?.name }}"
                tooltipPosition="top"
                tooltipStyleClass="transcustomtolltipbook"
                *ngIf="(cardDetails?.gsiList)[2]?.name"
              >
                {{ (cardDetails?.gsiList)[2]?.name }}
              </button>
            </div>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 5,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '86'">
      <div
        class="insurance-expand-card-13 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '86'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard">
          <ul>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 0,
                  class: 'card-title',

                  isSingleValue: true
                }
              }"
            ></ng-template>

            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'card-subtitle',

                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
          <div class="expandcard row">
            <div class="card-img col-8">
              <ul>
                <li class="card-logo">
                  <figure>
                    <ng-template
                      [ngTemplateOutlet]="attributeTemplate"
                      [ngTemplateOutletContext]="{
                        data: {
                          position: 2,
                          class: 'template-img',

                          isImage: true
                        }
                      }"
                    ></ng-template>
                  </figure>
                </li>

                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 3,
                      class: 'card-label',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
            <div class="card-data col-4">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 4,
                      class: 'card-subtitle-bold',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 5,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 6,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 7,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 8,
                      class: 'card-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
              <div class="btn-cta">
                <ng-template
                  [ngTemplateOutlet]="actionButton"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      class: 'btn btn-preview btn-tooltip'
                    }
                  }"
                ></ng-template>
              </div>
            </div>
          </div>
          <ul>
            <!-- <li class="linebar"></li> -->
          </ul>
          <div class="expandcard row align-items-end">
            <div class="card-img col-6">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 9,
                      class: 'card-subtitle-bold',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <li class="linebar linebaroverlay"></li>

                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 10,
                      class: 'card-title-large',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
            <div class="card-data col-12">
              <ul class="col-6 data">
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 11,
                      class: 'card-title-large',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 12,
                      class: 'card-title-large',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '87'">
      <div
        class="insurance-expand-card-14 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '87'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-data col-6">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <!-- <li class="linebar"></li> -->
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-subtitle-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
          <div class="card-img col-6">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 3,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-title-medium',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="expandcard">
          <div class="card-steps row">
            <div class="col-4">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 5,
                      class: 'card-title-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
            <div class="col-4 linebaroverlay">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 6,
                      class: 'card-title-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 7,
                      class: 'card-title-steps',

                      isSingleValue: true
                    }
                  }"
                ></ng-template>
              </ul>
            </div>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-title-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '88'">
      <div
        class="insurance-expand-card-15 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '88'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-6">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-label',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 1,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-label-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-data col-6">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>

        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '89'">
      <div
        class="insurance-expand-card-16 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '89'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="card-logo">
          <ul>
            <li class="card-icon">
              <figure>
                <ng-template
                  [ngTemplateOutlet]="attributeTemplate"
                  [ngTemplateOutletContext]="{
                    data: {
                      position: 0,
                      class: 'template-img',

                      isImage: true
                    }
                  }"
                ></ng-template>
              </figure>
            </li>
            <ng-template
              [ngTemplateOutlet]="attributeTemplate"
              [ngTemplateOutletContext]="{
                data: {
                  position: 1,
                  class: 'card-label',

                  isSingleValue: true
                }
              }"
            ></ng-template>
          </ul>
        </div>

        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 2,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="lisrgroup">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'listgroup-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'listgroup-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'listgroup-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'listgroup-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-data-box col-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'box-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'box-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'box-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'box-title-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn btn-preview btn-tooltip',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
        <div class="expandcard row expandcard-space">
          <div class="card-data col-md-8 offset-md-4">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: 'card-title-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    class: 'card-title-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="lisrgroup">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 21,
                    class: 'listgroup-title-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 22,
                    class: 'listgroup-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 23,
                    class: 'listgroup-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '90'">
      <div
        class="insurance-expand-card-17 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '90'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-title-black',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-subtitle-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle-medium',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="ul-steps">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="ul-space">
              <!-- <li class="linebar"></li> -->
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 21,
                    class: 'card-subtitle-large1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 22,
                    class: 'card-subtitle-large2',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 23,
                    class: 'card-subtitle-large3',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '91'">
      <div
        class="insurance-expand-card-18 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '91'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 0,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-steps2',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps3',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-steps4',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="row-ul">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-steps5',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="row-ul-step">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-step75',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-step25',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="row-ul-step">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-step75',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'card-step25',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="row-ul-step">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-step75',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-step25',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-subtitle-para',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-textarea',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '92'">
      <div
        class="insurance-expand-card-19 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '92'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul class="ul-labels">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-subtitle-large',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-img col-4">
            <ul>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 11,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-9">
            <ul class="ul-labels">
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-12">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: 'card-title-small',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
        </div>
        <div class="expandcard row">
          <div class="card-data col-8">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 20,
                    class: 'card-textarea',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="expandType == '93'">
      <div
        class="insurance-expand-card-20 insurance-expand"
        CardDesignConfig
        [isEdit]="isEdit"
        [id]="'Template-' + expandType"
        [_changeShadowStyle]="boxShadowData"
        [style]="style"
        [_selectedDirectiveId]="selectedDirectiveId"
        (emitSelectedElement)="recieveEmittedDirectiveId($event)"
        [(cardStyle)]="expandCardStyle"
        [expandDetails]="expandDetails"
        [expandedCardDetail]="expandedCardDetail"
        [cardHoveredColor]="cardHoveredColor"
        [ngStyle]="{ 'background-color': expandBackground }"
        [cardColor]="expandBackground"
        [conditionArray]="conditionArrayExpand"
        [card]="card"
        *ngIf="expandType == '93'"
      >
        <ng-template [ngTemplateOutlet]="closeExpand"></ng-template>
        <div class="expandcard row">
          <div class="card-img col-5">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'card-title',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="card-logo">
                <figure>
                  <ng-template
                    [ngTemplateOutlet]="attributeTemplate"
                    [ngTemplateOutletContext]="{
                      data: {
                        position: 2,
                        class: 'template-img',

                        isImage: true
                      }
                    }"
                  ></ng-template>
                </figure>
              </li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 3,
                    class: 'card-label',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
          </div>
          <div class="card-data col-7">
            <ul>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 4,
                    class: 'card-subtitle',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 5,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 6,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 7,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 8,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 9,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 10,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 11,
                    class: 'card-subtitle clrblack',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <li class="linebar"></li>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 12,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 13,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 14,
                    class: 'card-steps',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 15,
                    class: 'card-steps1',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 16,
                    class: 'card-subtite-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 17,
                    class: 'card-textarea',

                    isSingleValue: true
                  }
                }"
              ></ng-template>

              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 18,
                    class: 'card-subtite-bold',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="attributeTemplate"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 19,
                    class: 'card-textarea',

                    isSingleValue: true
                  }
                }"
              ></ng-template>
            </ul>
            <div class="btn-cta">
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 0,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 1,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
              <ng-template
                [ngTemplateOutlet]="actionButton"
                [ngTemplateOutletContext]="{
                  data: {
                    position: 2,
                    class: 'btn cta-btn-primary btn-preview btn-tooltip'
                  }
                }"
              ></ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
