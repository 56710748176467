import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';

@Injectable({
  providedIn: 'root',
})
export class CrossDomainMessagingService extends ObservableStore<any> {

  constructor() {
    super({trackStateHistory: true});
  }

  setData(data: any, action: string) {
    this.setState({...data}, action);
  }
  resetState(state: any, dispatchState?: boolean){
    this.resetState(state, dispatchState);
  }
  getData() {
    return this.getState();
  }
}
