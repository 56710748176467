<div class="con-main-wrapper connectors-wrapper">
  <!--Header area-->
  <div class="con-header">
    <div class="con-header-left">
      <h2>
        Widget Configuration
      </h2>
    </div>
    <div class="con-header-right">
      <button class="btn btn-done" (click)="saveWidget()">Save</button>
      <button class="btn btn-done" (click)="closeWidgetSetion()">Close</button>
    </div>
  </div>
  <!--Content area-->
  <div class="con-content">
    <div class="con-left-content">
      <h3 class="con-left-header">Widget Type</h3>
      <ul class="widget-type-icons">
        <li
          *ngFor="let widget of widgetTypes; let i = index"
          [matTooltip]="
            widget.minAttributes == widget.maxAttributes
              ? 'required ' + widget.minAttributes + ' attributes'
              : 'required atleast ' + widget.minAttributes + 'and atmost ' + widget.maxAttributes + ' attributes'
          "
        >
          <div (click)="selectWidget(i)" class="db-widget-icon" [ngClass]="{ active: i == selectedWidgetIndex }">
            <img class="db-logo" [src]="widget.image" />
            <span>{{ widget.name }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="con-middle-content" *ngIf="selectedWidgetIndex != -1">
      <div class="form-details" *ngFor="let axisistype of selectedWidget.axises">
        <div class="label">{{ axisistype.name }}<span class="start" *ngIf="!axisistype.isOptional">*</span></div>

        <div class="form-group">
          <select
            class="custom-select"
            aria-label="cu-selection"
            [(ngModel)]="configuration[axisistype.name]"
            (change)="validateForm()"
          >
            <option value="-1" selected>Select Attribute</option>
            <ng-container *ngFor="let attribute of attributeList; let attrIndex = index">
              <option *ngIf="attribute.attributeType.type == axisistype.type" [value]="attrIndex">
                {{ attribute.name }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="submit-container">
        <button class="btn btn-done" (click)="showCanvasJs()" [disabled]="submitDisabled">done</button>
      </div>
    </div>
    <div class="con-right-content" *ngIf="showCanvas">
      <app-canvasjs-display
        [chartType]="selectedWidget.chartType"
        [entityName]="entity.name"
        [data]="selectedWidget.data"
        [_canavasChanged]="canvasChanged"
        [categories]="selectedWidget.categories"
      ></app-canvasjs-display>
      <div class="danger">* data displayed here is to show how graph is displayed</div>
    </div>
    <!-- Displaying Connectors Ends -->
  </div>
</div>
