import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoStreamingComponent } from './video-streaming.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [VideoStreamingComponent],
  imports: [ControlsMaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [VideoStreamingComponent],
})
export class VideoStreamingModule {
  static entry = {
    video: VideoStreamingComponent,
  };
}
