import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsJsonString } from '../../generic-folder/generic-functions';
import { LibraryFacadeService } from '../library/library-facade.service';
import { TranslatorService } from '../translator/translator.service';

@Component({
  selector: 'app-add-language-template',
  templateUrl: './add-language-template.component.html',
  styleUrls: ['./add-language-template.component.scss'],
})
export class AddLanguageTemplateComponent implements OnInit {
  @Input() transEntityRecords: any = [];
  @Input() entity: any;
  ngUnsubscribe = new Subject();
  @Output() emitTemplateCCD = new EventEmitter();
  languages: any = [];
  labels: any;
  txnNslAttributeObj: any = {
    txnNslAttribute: [
      {
        name: 'Language Code',
        nslAttributeID: null,
        dsdId: null,
        values: [''],
        properties: ['CONSTANT'],
      },
      {
        name: 'Language Template',
        nslAttributeID: null,
        dsdID: null,
        values: [],
        properties: ['CONSTANT'],
      },
    ],
  };
  constructor(public translator: TranslatorService, private libraryfacadeservice: LibraryFacadeService) {
    this.getLanguages();
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.translator.getLanguages();
    this.entity?.nslAttributes?.forEach((attr: any) => {
      this.txnNslAttributeObj?.txnNslAttribute?.forEach((txnAttr: any) => {
        /* istanbul ignore else */
        if (txnAttr?.name == attr?.name) {
          txnAttr.nslAttributeID = attr?.id;
          txnAttr.dsdId = attr?.id;
        }
      });
    });
    /* istanbul ignore else */
    if (this.transEntityRecords?.length == 0) {
      let txnNslAttribute = JSON.parse(JSON.stringify(this.txnNslAttributeObj));
      this.transEntityRecords.push(txnNslAttribute);
    } else {
      this.transEntityRecords?.forEach((record: any, i: number) => {
        record?.txnNslAttribute[1]?.values?.forEach((val: any, j: number) => {
          if (IsJsonString(val)) {
            this.transEntityRecords[i].txnNslAttribute[1].values[j] = JSON.parse(val);
          }
        });
      });
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  //To add uploaded templates in transEntityRecords
  getUploadedTemplate(templates: any, index: number) {
    /* istanbul ignore else */
    if (Array.isArray(templates)) templates = templates[0];
    /* istanbul ignore else */
    if (!IsJsonString(templates)) templates = JSON.stringify(templates);
    this.transEntityRecords[index].txnNslAttribute[1].values.push(templates);
  }

  //To add a new template
  addRecord() {
    let txnNslAttribute = JSON.parse(JSON.stringify(this.txnNslAttributeObj));
    this.transEntityRecords.push(txnNslAttribute);
  }

  //To get supported Language list for tenant
  getLanguages() {
    this.translator.languages$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.languages = res;
      }
    });
  }
  //To save Template value as CCD and emit the data
  saveTemplateCCD() {
    this.transEntityRecords?.forEach((record: any, i: number) => {
      record?.txnNslAttribute[1]?.values?.forEach((val: any, j: number) => {
        if (!IsJsonString(val)) {
          this.transEntityRecords[i].txnNslAttribute[1].values[j] = JSON.stringify(val);
        }
      });
    });
    let a: any = null;
    let res = {
      dsdID: this.entity?.id,
      generalEntityID: this.entity?.id,
      name: this.entity?.name,
      entityClassification: '',
      entityType: a,
      isMultiValue: true,
      // properties: ['CONSTANT'],
      transEntityRecords: this.transEntityRecords,
    };
    this.libraryfacadeservice.templateCCD.next(res);
    this.emitTemplateCCD.emit(res);
  }

  //To delete template from record
  deleteTemplate(i: number) {
    this.transEntityRecords.splice(i, 1);
  }
}
