import { NgModule } from '@angular/core';
import { ControlsMaterialModule } from '../material.module';
import { RedacMappingComponent } from '../redac-mapping/redac-mapping.component';
import { RedacComponent } from './redac.component';
import { CommonServicesModule } from '@common-services';

@NgModule({
  declarations: [RedacComponent, RedacMappingComponent],
  imports: [
    ControlsMaterialModule,
    CommonServicesModule
  ],
  exports: [RedacComponent, RedacMappingComponent],
})
export class RedacModule {
  static entry = {
    redact: RedacComponent,
  };
}
