<div mat-dialog-title>
  <div class="mat-dialog-title-left d-flex nh-column-gap-15">
    <button class="nh-unsetAll cursor-pointer" (click)="downloadFile()" *ngIf="!isHtmlType() && canDownload">
      <em class="nh-icon nh-icon-download01 nh-fw-700 nh-fs-20"></em>
    </button>
    <div class="rotateAction nh-display-flex nh-column-gap-10" *ngIf="isImageType() && !src.fromDesignSystem">
      <button class="nh-unsetAll cursor-pointer" (click)="rotateLeft()">
        <em class="nh-icon nh-icon-rotate_left nh-fw-700"></em>
      </button>
      <button class="nh-unsetAll cursor-pointer" (click)="rotateRight()">
        <em class="nh-icon nh-icon-rotate_right nh-fw-700"></em>
      </button>
    </div>
  </div>
  <button class="nh-unsetAll cursor-pointer close-btn-modal" (click)="closePopup()">
    <em class="nh-icon nh-icon-x-lg nh-fw-700"></em>
  </button>
</div>
<mat-dialog-content>
  <div class="popup-div">
    <iframe *ngIf="isHtmlType()" [src]="src.contentUrl | safeResourceUrl"></iframe>
    <iframe [src]="src.contentUrl | useHttpImgSrc | async" *ngIf="isFrameSupported()" title=""></iframe>
    <div class="pdf-viewer" *ngIf="isPdfType()">
      <!-- add candownload, print and download -->
      <ngx-extended-pdf-viewer
        (contextmenu)="onRightClick($event)"
        [src]="src.contentUrl"
        [showDownloadButton]="canDownload"
        [showFindButton]="true"
        [showOpenFileButton]="false"
        [showPrintButton]="canDownload"
        [showTextEditor]="false"
        [showDrawEditor]="false"
        [showStampEditor]="false"
        [showBorders]="false"
        [theme]="'dark'"
        [textLayer]="true"
        [spread]="'odd'"
        [sidebarVisible]="true"
      ></ngx-extended-pdf-viewer>
    </div>
    <div *ngIf="isSheet" class="table-preview">
      <table>
        <tbody>
          <tr *ngFor="let row of sheetData">
            <td *ngFor="let val of row">
              {{ val }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="fileUploadAudioPreview" *ngIf="isAudioType()">
      <audio controls>
        <source [src]="src.contentUrl | safeResourceUrl" />
      </audio>
    </div>
    <div class="fileUploadImgPreview" *ngIf="isImageType()">
      <!-- <p-image
        [src]="src.contentUrl"
        id="map"
        alt="Image"
        class="file-upload-img fileuploadimg rotateImage mx-auto"
        [preview]="true"
        (contextmenu)="onRightClick($event)"
      ></p-image> -->
      <img
        (contextmenu)="onRightClick($event)"
        class="file-upload-img rotateImage mx-auto"
        [src]="src.contentUrl | useHttpImgSrc | async"
        *ngIf="isImageType()"
        alt=""
      />
    </div>
    <div class="fileUploadVideoPreview" *ngIf="isVideoType()">
      <video controls>
        <source [src]="src.contentUrl | safeResourceUrl" />
        <track label="English" kind="captions" srclang="en" src="" default />
        Your browser does not support HTML video.
      </video>
    </div>

    <div *ngIf="isOfficeType" class="office-preview">
      <iframe
        *ngIf="isOfficeType"
        [src]="officeUrl | useHttpImgSrc | async"
        width="100%"
        height="250px"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</mat-dialog-content>
