<div class="compare-bet">
  <div class="compare-popup-top">
    <div class="pop-head">
      <h2>DIFFER {{ property }}</h2>
    </div>
  </div>
  <span class="cancel">
    <mat-icon (click)="closePopup()">close</mat-icon>
  </span>
  <div class="valuesscroll">
    <div *ngIf="property == 'ATTRIBUTE CONSTRAINTS'" class="float-left">
      <div class="validator">
        <div>
          <h3>{{ labels?.upperBet }}</h3>
          <div *ngFor="let section of upperBetProperties; let sectionIndex = index">
            <ul class="UpperBetProperties">
              <li>
                <ul class="bet-properties">
                  <strong>{{ section?.displayName }}</strong>
                </ul>
                <span *ngFor="let property of section?.properties">
                  <ul class="bet-properties">
                    <strong>{{ property?.message }}</strong>
                  </ul>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="float-right">
          <h3>{{ labels?.lowerBet }}</h3>
          <div
            class="lower-bet-validators"
            *ngFor="let section of lowerBetProperties?.constraints; let sectionIndex = index"
          >
            <ul class="lower-bet-properties">
              <li class="bet-properties">
                <ul class="bet-properties">
                  {{
                    section?.displayName
                  }}
                </ul>
                <span *ngFor="let property of section?.properties">
                  <ul class="bet-properties" style="color: blue;">
                    <strong>{{ property?.message }}</strong>
                  </ul>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
    </div>

    <div class="defaultValue" *ngIf="property == 'DEFAULT VALUE'">
      <div class="float-left">
        <h3>{{ labels?.upperBet }}</h3>
        <div class="lower-bet-validators" *ngFor="let value of upperBetProperties">
          <span class="bet-properties"
            ><strong>{{ value }}</strong></span
          >
        </div>
      </div>
      <div class="float-right">
        <h3>{{ labels?.lowerBet }}</h3>
        <div class="lower-bet-validators" *ngFor="let value of lowerBetProperties?.defaultValue">
          <span class="bet-properties" style="color: blue;"
            ><strong>{{ value }}</strong></span
          >
        </div>
      </div>
    </div>
    <div class="defaultValue" *ngIf="property == 'MEMBERSHIP CRITERIA'">
      <div class="float-left">
        <h3>{{ labels?.upperBet }}</h3>
        <div class="lower-bet-validators" *ngFor="let value of upperBetProperties">
          <span class="bet-properties"
            ><strong>{{ value }}</strong></span
          >
        </div>
      </div>
      <div class="float-right">
        <h3>{{ labels?.lowerBet }}</h3>
        <div class="lower-bet-validators">
          <span class="bet-properties" style="color: blue;"
            ><strong>{{ lowerBetProperties?.memberShipCriteria }}</strong></span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="merge-btn">
    <button (click)="mergeLowertoUpper(property)" [disabled]="merged" class="btn">MERGE {{ property }}</button>
  </div>
</div>
