import { Component, OnInit, Input, ViewEncapsulation, forwardRef, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { EventsFacadeService, FieldConfig, TransactionFacadeService, TranslatorService, MMIMapService } from '@common-services';
import { mappls, mappls_plugin } from 'mappls-web-maps';

@Component({
  selector: 'app-mmi-maps',
  templateUrl: './mmi-maps.component.html',
  styleUrls: ['./mmi-maps.component.scss'],
})
export class MMIMapsComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  field: FieldConfig;
  group: FormGroup;

  currentlat: any;
  currentlng: any;

  clickedLat: any;
  clickedLng: any;

  origin: any;
  destination: any;

  startPoint: any;
  endPoint: any;

  previousInfoWindow: any;

  @Input() config: any;
  @Input() _mapData: any;
  dateChanged: Subject<any> = new Subject();
  isDisabledButton: Subject<any> = new Subject();
  latitude: number;
  longitude: number;
  radiusLong: any;
  radius = 5000;
  zoom: number;
  markers: any;
  filteredMarkers: any;
  addressByCoord: any;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  mapData = {
    lat: 51.673858,
    lng: 7.815982,
    zoomControl: false,
    disableDefaultUI: false,
    zoom: 12,
    markers: [] as any,
  };
  currentCu: any;
  selectedMarker: { lat: any; lng: any };
  searchControl: FormControl;
  showMarkers: boolean;
  currentLocation: boolean;
  currentLocationData: any;
  showNavigation: boolean;
  displayLocationstatus: boolean = false;
  labels: any;
  calllocationData: any;
  locationInterval: NodeJS.Timeout | number | null = null;
  toggleInterval: NodeJS.Timeout | number | null = null;
  unsubscribe$: Subject<any> = new Subject<any>();
  callLocation: boolean = false;
  destinationMarker: any;

  mapObject: any;
  markerObject: any;
  mapplsClassObject = new mappls();
  mapplsPluginObject = new mappls_plugin();
  mapProps = {
    center: [28.633, 77.2194],
    traffic: false,
    zoom: 3,
    geolocation: false,
    clickableIcons: true,
    search: true,
  };

  token: string;
  locations: any = [];
  eloc: any;
  place: any;

  constructor(
    private ngZone: NgZone,
    private eventsService: EventsFacadeService,
    private http: HttpClient,
    private translator: TranslatorService,
    private transactionFacadeService: TransactionFacadeService,
    private mmiMapService: MMIMapService
  ) {
    this.detectLanguageChange();
  }

  ngOnChanges() {
    this.mapData = this.config;
  }

  ngOnInit() {
    if (this.field.value) {
      this.mapProps.center[0] = JSON.parse(this.field.value).latitude;
      this.mapProps.center[1] = JSON.parse(this.field.value).longitude;
    }
    this.mmiMapService
      .getToken()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.token = res['access_token'];
        this.loadMMImap();
      });
  }

  loadMMImap() {
    if (this.markers) {
      this.markers.remove();
    }
    this.selectedMarker = {
      lat: this.mapProps.center[0],
      lng: this.mapProps.center[1],
    };
    if (this.field?.configuration && this.field?.configuration?.mmiDestination) {
      this.destinationMarker = {
        lat: JSON.parse(this.field.configuration?.mmiDestination).latitude,
        lng: JSON.parse(this.field.configuration?.mmiDestination).longitude,
      };
    }
    this.mapplsClassObject.initialize(this.token, () => {
      this.mapObject = this.mapplsClassObject.Map({ id: 'map', properties: this.mapProps });
      this.markerObject = this.mapplsClassObject.Marker({
        map: this.mapObject,
        icon: 'https://maps.mapmyindia.com/images/to.png',
        position: this.selectedMarker,
        fitbounds: true,
        popupHtml: this.place,
        draggable: true,
      });
      //load map layers/components after map load, inside this callback (Recommended)
      this.mapObject.on('load', (data: any) => {
        this.getAddress(this.selectedMarker);
        if (this.destinationMarker?.lat) this.getDirection(this.selectedMarker, this.destinationMarker);
      });
      this.markerObject.addListener('dragend', (data: any) => {
        this.getAddress(data.target.getLngLat());
        this.onChange('');
      });
    });
  }

  searchLocations(event: any) {
    if (event.length > 2) {
      this.mmiMapService.autoSuggest(this.token, event).then((data: any) => {
        if (data?.suggestedLocations) {
          this.locations = data.suggestedLocations;
        }
      });
    }
  }

  locationSelected(event: any) {
    let dt = event.option.value;
    if (!dt) return false;
    this.eloc = dt.eLoc;
    this.place = dt.placeName + ', ' + dt.placeAddress;
    this.selectedMarker = {
      lat: dt.latitude,
      lng: dt.longitude,
    };
    if (this.markers) {
      this.markers.remove();
    }
    this.markerObject = this.mapplsClassObject.Marker({
      map: this.mapObject,
      icon: 'https://maps.mapmyindia.com/images/to.png',
      position: this.selectedMarker,
      fitbounds: true,
      popupHtml: this.place,
      draggable: true,
    });

    this.markerObject.addListener('dragend', (data: any) => {
      this.getAddress(data.target.getLngLat());
    });
    this.onChange(event);
  }

  getAddress(position: any) {
    this.mmiMapService.reverseGeocode(this.token, position).then((data: any) => {
      if (data.results) {
        this.locations = [];
        let obj = {
          placeName: data.results[0].locality,
          longitude: data.results[0].lng,
          latitude: data.results[0].lat,
          placeAddress: data.results[0].formatted_address,
        };
        this.locations.push(obj);
        this.searchControl = this.locations[0];
        this.locations = [];
      }
    });
  }

  getDirection(source: any, destination: any) {
    this.mmiMapService.geoRoute(source, destination).then((data: any) => {
      if (data) {
        let points = this.mmiMapService.decode(data.routes[0].geometry);

        this.mapplsClassObject.Polyline({
          map: this.mapObject,
          paths: points,
          strokeColor: '#333',
          strokeOpacity: 1.0,
          strokeWeight: 5,
          fitbounds: true,
        });
      }
    });
  }

  getOptionText(option: any) {
    return option?.placeAddress;
  }

  /**
   * Determines whether input value is changed
   * @param event Contains the changed value
   */
  onChange(event: any) {
    this.group.get(this.field.attribute.name).setValue({
      latitude: this.selectedMarker.lat ? this.selectedMarker.lat : '',
      longitude: this.selectedMarker.lng ? this.selectedMarker.lng : '',
    });
    this.dateChanged.next(true);
    const data = {
      attributeId: this.field?.attribute?.id,
      isTableConfig: this.field?.attribute?.isTableConfig,
      attrName: this.field?.attribute?.name,
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    this.eventsService.setEvent(data);
    /* conditional potentiality check  */
    /* istanbul ignore else */
    if (this.field.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent('');
    }
  }

  onFocousOut() {
    //this.iconSvg?.nativeElement?.classList?.remove('focus-color');
  }
  /**
   * Determines whether input field is focused
   * @param event Contains the input value
   */
  onFocus(event: any) {
    //this.iconSvg?.nativeElement?.classList?.add('focus-color');
    const data = {
      attributeId: this.field?.attribute?.id,
      isTableConfig: this.field?.attribute?.isTableConfig,
      attrName: this.field?.attribute?.name,
      eventType: 'ON_FOCUS',
      entityName: this.field?.entityName,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    this.eventsService.setEvent(data);
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  ngOnDestroy() {
    clearInterval(this.locationInterval);
    clearInterval(this.toggleInterval);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
