<!-- <p>entity-mapper works!</p> -->
<div class="con-main-wrapper mapper-wrap">
  <!--Header area-->
  <div class="con-header">
    <div class="con-header-left">
      <h2>
        {{labels?.PHYSICAL_LAYER}}
      </h2>
      <h2>
        {{labels?.INFORMATION_LAYER}}
      </h2>
    </div>
    <div class="con-header-left con-header-right">
      <h2>
        {{labels?.MAPPED_DETAILS}}
      </h2>
      <button (click)="closeEntityMapper()" class="btn-close"><i class="bi bi-x-square"></i></button>
    </div>
  </div>
  <!--Content area-->
  <div class="con-content">
    <div class="con-left-content">
      <div class="left-sub-wrap">
        <div class="enity-class-name" *ngIf="physicalLayer">
          <mat-accordion>
            <mat-expansion-panel
              class="mat-elevation-z0"
              *ngFor="let entity of physicalLayer.participatingItems; let entityIndex = index"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{ entity.name }} <span class="cstm-check"></span> </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- <mat-expansion-panel
                class="mat-elevation-z0 sub-panel-exp"
                *ngFor="let attribute of entity.nslAttributes"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ attribute.name }}
                    <span class="cstm-check"
                      ><mat-checkbox
                        [(ngModel)]="attribute.isSelected"
                        (change)="physicalLayerEntityIndex(entityIndex)"
                      ></mat-checkbox
                    ></span>
                  </mat-panel-title>
                </mat-expansion-panel-header> -->
              <div class="sub-panel-exp">
                <div class="panel-content-wrapper">
                  <ul>
                    <li *ngFor="let attribute of entity.nslAttributes">
                      {{ attribute.name }}
                      <span class="cstm-check"
                        ><mat-checkbox
                          [(ngModel)]="attribute.isSelected"
                          (change)="physicalLayerEntityIndex(entityIndex)"
                        ></mat-checkbox
                      ></span>
                    </li>
                    <!-- <li>
                        Attribute’s Member as Constant Value <span class="cstm-check"><mat-checkbox></mat-checkbox></span>
                      </li>
                      <li>
                        Attribute’s Member as Constant Value <span class="cstm-check"><mat-checkbox></mat-checkbox></span>
                      </li> -->
                  </ul>
                </div>
              </div>
              <!-- </mat-expansion-panel> -->
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="enity-class-name left-check" *ngIf="infomationLayer">
          <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0" *ngFor="let entity of infomationLayer.participatingItems">
              <mat-expansion-panel-header>
                <mat-panel-title> <span class="cstm-check"></span> {{ entity.name }} </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- <mat-expansion-panel
                class="mat-elevation-z0 "
                *ngFor="let attribute of entity.nslAttributes"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="cstm-check"><mat-checkbox [(ngModel)]="attribute.isSelected"></mat-checkbox></span>
                    {{ attribute.name }}
                  </mat-panel-title>
                </mat-expansion-panel-header> -->

              <div class="sub-panel-exp">
                <div class="panel-content-wrapper">
                  <ul>
                    <li *ngFor="let attribute of entity.nslAttributes">
                      <span class="cstm-check"><mat-checkbox [(ngModel)]="attribute.isSelected"></mat-checkbox></span>
                      {{ attribute.name }}
                    </li>
                    <!-- <li>
                        <span class="cstm-check"><mat-checkbox></mat-checkbox></span> Attribute’s Member as Constant Value
                      </li>
                      <li>
                        <span class="cstm-check"><mat-checkbox></mat-checkbox></span> Attribute’s Member as Constant Value
                      </li> -->
                  </ul>
                </div>
              </div>
              <!-- </mat-expansion-panel> -->
            </mat-expansion-panel>
            <!-- <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Entity 2 Class Name
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-expansion-panel class="mat-elevation-z0 sub-panel-exp">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Attribute 1 Class Name
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-content-wrapper">
                  <ul>
                    <li>Attribute’s Member as Constant Value</li>
                    <li>Attribute’s Member as Constant Value</li>
                    <li>Attribute’s Member as Constant Value</li>
                  </ul>
                </div>
              </mat-expansion-panel>
            </mat-expansion-panel> -->
          </mat-accordion>
        </div>
      </div>
      <div class="btn-wrap">
        <button mat-stroked-button class="primary-btn" (click)="mapAttributes()">{{labels?.Map||'Map'}} {{labels?.Attributes||'Attributes'}}</button>
        <button mat-stroked-button class="primary-btn" (click)="updateCU()">{{labels?.Done||'Done'}}</button>
      </div>
    </div>

    <!-- Displaying Connectors begins -->
    <div class="con-right-content con-left-content">
      <div *ngFor="let source of attributeMappedObj | keyvalue">
        <div class="mapped-details" *ngFor="let attributeMap of attributeMappedObj[source.key]; let mapIndex = index">
          <div class="mapped-etty">Physical Layer- {{ source.key | dcdLabel: false }}</div>
          <div class="mapped-etty">Information Layer- {{ attributeMap | dcdLabel: false }}</div>
          <span class="delete-icon" (click)="deleteAttributeMap(source.key, mapIndex)">
            <mat-icon>delete_outline</mat-icon>
          </span>
        </div>
      </div>
    </div>

    <!-- Displaying Connectors Ends -->
  </div>
</div>
