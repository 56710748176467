<ng-container *ngFor="let formlevel of control.value?.controls; let i = index; let last = last">
  <div
    [formGroup]="formlevel"
    class="icon-cursor player-opacity"
    [ngClass]="{
      'attribte-opacity': formlevel.value.isDisabled
        ? formlevel.value.isDisabled
        : formlevel.value?.isNegative
        ? formlevel.value?.isNegative
        : formlevel.value.isInfo && formlevel.value.value != ''
    }"
  >
    <div>
      <ng-container
        dynamicField
        [formData]="formlevel"
        [group]="formlevel"
        (addmoreAttributes)="addMultipleControl(control.key, control.value.controls, control, formlevel, i, $event)"
        (removeAttributes)="removeMultipleControl(control.key, control.value.controls, control, formlevel, i)"
        (dateChanged)="dateChnagedEvent($event)"
        (isDisabledButton)="isDisabledButton($event)"
        (wayPointsLocationData)="getWaypointsData($event)"
        (clickableOnSelect)="getClickableOnSelectData($event)"
      >
      </ng-container>
    </div>
  </div>
</ng-container>
