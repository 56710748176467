import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { GsiButton, DraggableAttribute, AlertService, EntityBoardEndpointService } from '@common-services';


@Component({
  template: '',
})
export class InsuranceEcommerceExpandBase implements OnInit {
  @Input() btnHoverColors: any = [];
  @Input() set mappedExpandGsi(value: any) {
    if (value) {
      value.forEach((attr: any, index: number) => {
        let tempGsi = this.expandGsiList.findIndex((gsi: any) => gsi.name == attr);
        if (tempGsi != -1) {
          this.makeGsiButtonFromId(index, tempGsi);
        }
      });
    }
  }
  @Input() expandCardStyle: any;
  @Input() cardDisplay: any;
  @Input() expandStyleAttribute: any;
  @Input() expandStyleGsi: any;

  dropActionBts: any = {};
  @Input() style: any;
  // @Input() expandType: number | string = 0;
  @Input() previewType: 'wireFrame' | 'Preview';
  @Input() selectGsiListExpand: any;
  droppedExpandColors: any;
  @Input() selectedDirectiveId: any;
  @Input() boxShadowData: string = '';
  @Input() isDesign: boolean = false;
  cardColorExpand: any;
  btnColorsExpand: any = [];
  droppedStylesExpand: any = [];
  expandGsiMapping: { [key: number]: GsiButton } = {};
  @Input() expandGsiList: GsiButton[] = [];
  @Input() CART_BUTTON: GsiButton;
  @Output() expandGsiListemit = new EventEmitter();
  @Output() dialogEmit = new EventEmitter();
  @Output() CART_BUTTON_EMIT = new EventEmitter();
  hoverColorsFinalExpand: any = [];
  expandHoverBackground: any;
  @Input() set colorConditionSetter(value: any) {
    this.conditionColors = value;
  }
  conditionColors: any;
  @Input() set changeObj(data: any) {
    // this.sendExpandData();
  }

  dropExpandAttributesForDeletion: any = [];

  @Input() isGrammer: any = false;
  @Input() dragAttrType: any;
  @Input() isEdit: boolean = false;

  @Input() templateType: any;
  @Output() droppedAttr = new EventEmitter();
  @Output() onDeletion = new EventEmitter();
  @Output() droppedActionButton = new EventEmitter();
  @Input() tabSelected: any;
  droppedAttrExpand: any = {};
  @Output() droppedAttrExpandEmit = new EventEmitter();
  @Output() emitConditionColor = new EventEmitter();
  @Input() hoverColorsExpand: any;
  @ViewChild('dropEntityData') dropEntityData: ElementRef;
  @Input() expandPositionMap: { [key: number]: DraggableAttribute } = {};
  contentUrl: string;
  constructor(private alertService: AlertService, private entityBoardService: EntityBoardEndpointService) {}
  btnHoveredBoolean: boolean[] = [];
  @Input() set _expandType(value: any) {
    this.expandType = String(value);
  }
  ngOnChanges(): void {
    // this.sendExpandData();
  }
  /*istanbul ignore next*/
  onDeletingAttr(element: any) {
    this.onDeletion.emit(element);
  }

  dropAttribute(ev: any, attrType?: any) {
    if (
      this.dragAttrType != 'button' &&
      ((this.dragAttrType && attrType == 'image' && this.dragAttrType == 'image') ||
        (this.dragAttrType && attrType == 'any' && this.dragAttrType !== 'image'))
    ) {
      this.dropAttributeInTemplates(ev);
    } else if (
      this.dragAttrType != 'button' &&
      (attrType == this.dragAttrType || (attrType == 'label' && this.dragAttrType == 'metric'))
    ) {
      this.dropAttributeInTemplates(ev);
    } else {
      this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
    }
    // this.dragAttrType = undefined;
  }

  dropAttributeInTemplates(ev: any) {
    // this.dropAttributeInTemplatesExpand(ev);
    this.droppedAttr.emit(ev);
  }

  allowDrop(ev: any) {
    ev.preventDefault();
  }

  dropActionBtn(event: any) {
    /* istanbul ignore next */
    if (this.dragAttrType === 'button' || this.dragAttrType === 'cart') {
      if (this.checkGSIMappingCondition()) {
        this.alertGSIMappedToCart();
        return;
      }
      let buttonId = parseInt(event?.target?.id?.split('-')[1]);
      if (!this.expandGsiMapping?.[buttonId]) {
        event.preventDefault();
        if (this.dragAttrType == 'button') {
          const data = event?.dataTransfer?.getData('text');
          const gsiIndex = parseInt(data?.split('-')[1]);
          this.makeGsiButtonFromId(buttonId, gsiIndex);
        } else {
          this.makeCartButtonFrom(buttonId);
        }
      } else {
        if (this.expandGsiMapping[buttonId].entityType == 'GSI' && this.dragAttrType == 'cart')
          this.alertGSIMappedToCart();
        this.alertService.showToaster('Dragged attribute type is not matched to the dropped attribute.', '', 'Error');
      }
    }
  }

  /*istanbul ignore next*/
  checkGSIMappingCondition(): boolean {
    let keysLength = Object.keys(this.expandGsiMapping).length;
    if (keysLength > 0 && this.dragAttrType == 'cart') {
      return true;
    } else if (
      this.dragAttrType == 'button' &&
      (this.CART_BUTTON.isSelected['child'].isSelected || this.CART_BUTTON.isSelected['expand'].isSelected)
    ) {
      return true;
    }
    return false;
  }

  /*istanbul ignore next*/
  alertGSIMappedToCart() {
    this.dialogEmit.emit();
  }

  /*istanbul ignore next*/
  makeCartButtonFrom(buttonId: number) {
    this.CART_BUTTON.isSelected['expand'].isSelected = true;
    this.CART_BUTTON.isSelected['expand'].position = buttonId;
    this.expandGsiMapping[buttonId] = this.CART_BUTTON;
    this.dropActionBts['action-' + buttonId] = this.CART_BUTTON;
    this.CART_BUTTON_EMIT.emit(this.CART_BUTTON);
  }

  /*istanbul ignore next*/
  makeGsiButtonFromId(buttonId: number, gsiId: number) {
    this.expandGsiMapping[buttonId] = this.expandGsiList[gsiId];
    this.expandGsiList[gsiId].isSelected = true;
    this.expandGsiList[gsiId].position = buttonId;
    this.dropActionBts['action-' + buttonId] = this.expandGsiList[gsiId];
    this.expandGsiListemit.emit(this.expandGsiList);
  }

  /*istanbul ignore next*/
  removeGsiFromCard(index: number) {
    if (this.expandGsiMapping[index].entityType === 'GSI') {
      this.expandGsiMapping[index].isSelected = false;
      delete this.expandGsiMapping[index];
      delete this.dropActionBts['action-' + index];
    } else {
      this.expandGsiMapping[index].isSelected['expand'].isSelected = false;
      delete this.expandGsiMapping[index];
      delete this.dropActionBts['action-' + index];
    }
  }

  recieveEmittedDirectiveId(event: any) {
    this.selectedDirectiveId = event;
  }
  recieveConditionColor(event: any) {
    this.emitConditionColor.emit(event);
  }
  recieveExpandHoverColor(event: any) {
    this.expandHoverBackground = event;
  }
  ngOnDestroy() {
    // this.sendExpandData();
    if (Object.keys(this.droppedAttrExpand).length > 0 || Object.keys(this.dropActionBts).length > 0) {
      if (this.isGrammer && this.droppedAttrExpand) {
        this.droppedAttrExpandEmit.emit({ droppedAttrExpand: this.droppedAttrExpand, event: true });
      }
      this.entityBoardService.sendDataToEntityCard({
        attrData: this.droppedAttrExpand,
        gsiData: this.dropActionBts,
        dropExpandColors: this.droppedExpandColors,
        expandBackground: this.cardColorExpand,
        expandBtnColors: this.btnColorsExpand,
        droppedStylesExpand: this.droppedStylesExpand,
        expandHoverBackground: this.expandHoverBackground,
      });
    }
  }
  noOfValue(len: number) {
    return Array.from({ length: len }, (v, i) => i);
  }
  @Input() txnDataIndex: any;
  @Output() emitExecuteCard = new EventEmitter();

  @Input() conditionArrayExpand: any;
  @Input() expandColors: any;
  @Input() expandBackground: any;
  @Input() btnColors: any;
  @Input() otherStyles: any;
  @Input() expandBoxShadow: any;
  @Input() cardHoveredColor: any;
  @Input() attrhoveredColors: any;

  @Input() expandType: any;
  @Input() expandCardDetails: any;
  @Input() gsiExpanddata: any;

  @Output() closeCardEmitter: EventEmitter<boolean> = new EventEmitter();
  expandDetails: any;
  expandedCardDetail: any[] = [];
  cardsData: any;
  card: any;
  cardDetails: any;
  @Input() set _recordId(data: string) {
    // this.expandCardDetails = [];
    this.expandedCardDetail = this.expandCardDetails?.filter((card: any) => card.recordId == data);
    /* istanbul ignore else*/
  }

  expandMapSet: Set<string>;

  @Input() set expandMap(data: any) {
    if(data){
      this.expandMapSet = new Set(Object.keys(data));
      this.expandDetails = data;
    }
  }

  ngOnInit(): void {
    /*istanbul ignore next*/
    this.mapExpandTemplates();
    if (this.cardDisplay == 'view') {
      this.btnColors?.forEach((color: any, index: number) => {
        this.btnHoveredBoolean[index] = false;
      });
    }
  }

  closeCard(): void {
    this.closeCardEmitter.emit(true);
  }

  gotoTxnPage(gsi: any, card?: any) {
    this.emitExecuteCard.emit({ gsi: gsi, card: card, txnDataIndex: this.txnDataIndex });
  }
  mapExpandTemplates() {
    this.card = {
      entityName: this.expandCardDetails?.[0]?.entityName,
      recordId: this.expandedCardDetail?.[0]?.recordId,
      recordData: [],
    };
    Object.keys(this.expandDetails || {}).forEach((key: any) => {
      let val: any = null;
      if (this.isJsonString(this.expandedCardDetail[0]?.[this.expandDetails[key]])) {
        val = JSON.parse(this.expandedCardDetail[0]?.[this.expandDetails[key]]).contentUrl;
      }
      this.card.recordData[key - 1] = {
        value: this.expandedCardDetail[0]?.[this.expandDetails[key]],
        contentUrl: val,
        attributeName: this.expandDetails[key],
      };
      this.conditionArrayExpand?.forEach((condition: any) => {
        /*istanbul ignore next*/
        if (condition.attribute == this.expandDetails[key]) {
          condition.index = key - 1;
        }
      });
    });
    this.cardDetails = { templateType: this.expandType, gsiList: [] };
    Object.keys(this.gsiExpanddata || {}).forEach((key: any) => {
      if (key == 'action-0') {
        this.cardDetails.gsiList[0] = this.gsiExpanddata[key];
      } else if (key == 'action-1') {
        this.cardDetails.gsiList[1] = this.gsiExpanddata[key];
      } else if (key == 'action-2') {
        this.cardDetails.gsiList[2] = this.gsiExpanddata[key];
      }
    });
  }
  isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

