<link
  *ngIf="fontUrl"
  [href]="fontUrl | safeResourceUrl"
  rel="stylesheet"
  type="text/css"
/>
<!-- For display value of bgStyleContent for cypress testcases. It will always be hidden -->
<div style="display: none;">
  <label *ngIf="bgStyleContent.backgroundImage">
    <span
      id="backgroundImage_{{
        getFileNameFromImgUrl(bgStyleContent.backgroundImage)
      }}"
    >
      {{ getFileNameFromImgUrl(bgStyleContent.backgroundImage) }}
    </span>
  </label>
  <label *ngIf="bgStyleContent.opacity">
    <span id="opacity_{{ bgStyleContent.opacity * 100 }}">
      {{ bgStyleContent.opacity * 100 }}
    </span>
  </label>
</div>
<!-- {{bgStyleContent | json}} -->
<div [ngClass]="{ 'd-none': isLmstemplate }">
  <div class="bg-img position-fixed" [ngStyle]="bgStyleContent"></div>
</div>

<div
  class="cuLayouts layoutCuHorizontal {{ cuLayoutTemplate.template }} {{
    tenantName
  }}"
  id="layoutCuHorizontal_{{ cuLayoutTemplate.template }}"
  *ngIf="
    cuLayoutTemplate.template !== 't-1' &&
    templateType !== 't-13' &&
    !isCarnival &&
    !isOnboarding &&
    cuLayoutTemplate.template !== 't-13'
  "
  [ngClass]="{
    cuButtonFixed: buttonAction?.positionFixed,
    bottomAds: adsBottom,
    rightAds: ads,
    cuLayouts_extraLarge: cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE',
    cuLayouts_medium: cuLayoutTemplate?.uiControlSize === 'MEDIUM',
    cuLayouts_small: cuLayoutTemplate?.uiControlSize === 'SMALL'
  }"
>
  <div
    class="cuLayouts_colorbg nh-z-n1 position-fixed nh-start-0 nh-top-0 nh-hp-100 nh-wp-100"
    id="background_{{
      getFileNameFromImgUrl(
        bgStyleContent.backgroundImage
          ? bgStyleContent.backgroundImage
          : cuLayoutTemplate?.background?.color
      )
    }}"
    [ngStyle]="{
      'background-image': bgStyleContent.backgroundImage
        ? bgStyleContent.backgroundImage
        : 'none',
      'background-color': cuLayoutTemplate?.background?.color
        ? cuLayoutTemplate.background.color
        : 'transparent',
      opacity: bgStyleContent.backgroundImage
        ? (bgStyleContent.opacity * 100) / 100
        : 1
    }"
  ></div>
  <div
    class="cuLayouts_imagebg nh-z-n1 position-fixed nh-start-0 nh-top-0 nh-hp-100 nh-wp-100"
    id="background_{{
      getFileNameFromImgUrl(cuLayoutTemplate?.background?.url)
    }}"
    *ngIf="templateType !== 't-13' && !isCarnival && !isOnboarding"
  >
    <img
      *ngIf="cuLayoutTemplate?.background?.url"
      [src]="cuLayoutTemplate?.background?.url"
      alt="image"
      class="nh-hp-100 nh-wp-100"
    />
  </div>
  <div class="layoutCuHorizontal_content">
    <!--Ads top start-->
    <app-advertisement-panel> </app-advertisement-panel>
    <!--Ads top end-->
    <!--Ads corner start-->
    <div
      class="main-right-ads nh-radius-8"
      *ngIf="ads != undefined"
      id="right-ads"
      [ngClass]="{
        'main-right-ads-single': ads.adType == 'Standard Image Ads',
        'main-right-add-multiple': ads.adType == 'Responsive Display Ads'
      }"
    >
      <div
        *ngIf="ads.adType == 'Responsive Display Ads'"
        class="text-center nh-bg-50 main-right-ads_inner nh-radius-8"
        id="right-ads-images"
      >
        <figure
          class="ads-logo"
          *ngIf="adsBottom?.s3Location"
          (click)="adClicked(ads)"
          id="right-ads-logo"
        >
          <img
            [title]="ads.destinationUrl"
            class="cursor-pointer img-fluid"
            [src]="ads?.s3Location"
            id="right-ads-logo-img"
            alt="ads.destinationUrl"
          />
        </figure>
        <div class="ads-list nh-display-flex">
          <figure
            class="ads-list-item nh-display-block nh-radius-8"
            *ngFor="let adData of ads?.containers"
            id="right-ads-figure"
          >
            <div class="ads-list-image">
              <img
                id="right-ads-imgs"
                [title]="adData.destinationUrl"
                [src]="adData?.image"
                (click)="adClicked(adData)"
                class="cursor-pointer img-fluid nh-radius-8 mx-auto nh-display-block"
                alt="adData.destinationUrl"
              />
            </div>
            <div class="ads-title" id="right-ads-description">
              {{ adData?.description }}
            </div>
          </figure>
        </div>
        <a
          href="javascript:;"
          id="right-ads-action"
          class="nh-unsetAll nh-text-center nh-btn nh-btn-buynow nh-radius-16 nh-display-block"
          (click)="adClicked(ads)"
          >{{ ads?.action }}</a
        >
      </div>
      <div
        *ngIf="ads.adType == 'Standard Image Ads'"
        class="main-right-ads_innersingle"
        id="right-ads-single-img"
      >
        <figure id="right-ads-single-figure">
          <img
            [title]="ads.destinationUrl"
            class="nh-radius-8 cursor-pointer img-fluid"
            [src]="ads?.s3Location"
            (click)="adClicked(ads)"
            alt="ads.destinationUrl"
          />
        </figure>
      </div>
    </div>
    <!--Ads corner end-->
    <div class="layoutCuHorizontal_container mx-auto">
      <div class="nhCard nh-position-relative nh-z-5">
        <div
          class="nhCard_header nh-position-relative"
          [ngClass]="{
            'ocr-visible': transactionCU?.currentCU?.uiProperties,
            'nh-mb-20':
              (!(cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE') ||
                isIpadLandscape) &&
              !(cuLayoutTemplate?.uiControlSize === 'MEDIUM') &&
              !(cuLayoutTemplate?.uiControlSize === 'SMALL'),
            'nh-mb-22':
              cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE' &&
              !isIpadLandscape,
            'nh-mb-18':
              cuLayoutTemplate?.uiControlSize === 'MEDIUM' && !isIpadLandscape,
            'nh-mb-16':
              (cuLayoutTemplate?.uiControlSize === 'MEDIUM' &&
                isIpadLandscape) ||
              cuLayoutTemplate?.uiControlSize === 'SMALL'
          }"
        >
          <div
            class="nhCard_header_inner nh-position-relative nh-display-flex justify-content-between align-items-center nh-mb-16"
            [ngClass]="{
              'ocr-visible':
                transactionCU?.currentCU?.uiProperties?.ar_id ||
                transactionCU?.currentCU?.uiProperties,
              nhCard_header_inner_pushback:
                isPushbackCommentEnabled && commentsOnCU?.length > 0,
              'nh-mb-16':
                (!(cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE') ||
                  isIpadLandscape) &&
                !(cuLayoutTemplate?.uiControlSize === 'MEDIUM') &&
                !(cuLayoutTemplate?.uiControlSize === 'SMALL'),
              'nh-mb-18':
                cuLayoutTemplate?.uiControlSize === 'EXTRA LARGE' &&
                !isIpadLandscape,
              'nh-mb-14': cuLayoutTemplate?.uiControlSize === 'MEDIUM',
              'nh-mb-12': cuLayoutTemplate?.uiControlSize === 'SMALL'
            }"
          >
            <div
              class="d-flex align-items-center nhCard_header_left nh-overflow-hidden"
            >
              <ng-container
                [ngTemplateOutlet]="backBtnTemplate"
                [ngTemplateOutletContext]="{ btnPos: 'top' }"
              ></ng-container>
              <span
                class="nh-fw-700 text-truncate nh-fontFamily nh-gsiColor"
                [ngClass]="{
                  'nh-fs-12': isIpadLandscape,
                  'nh-gsi-fontsize': !isIpadLandscape
                }"
                *ngIf="!isHideGsi"
                >{{ gsiName }}</span
              >
            </div>
            <div
              class="ocrbox"
              *ngIf="
                transactionCU?.currentCU?.uiProperties?.ar_id ||
                transactionCU?.currentCU?.uiProperties ||
                (isPushbackCommentEnabled && commentsOnCU?.length > 0)
              "
            >
              <div class="ocr" *ngIf="transactionCU?.currentCU?.uiProperties">
                <div class="ocr-image" *ngIf="ocrSmallModal">
                  <div
                    *ngIf="ocrModalShow"
                    id="right-panel-wrap"
                    class="right-panel-wrap-section"
                  >
                    <app-ocr-modal
                      [transactionCU]="transactionCU"
                      [transactionViewOCRFlag]="transactionViewOCR"
                      [gsi]="gsi?.changeUnits"
                      [changeUnit]="selectedCu"
                      [isReadOnly]="isReadOnly"
                      (emitter)="ocrSmallModal = $event"
                    ></app-ocr-modal>
                  </div>
                </div>
                <a
                  class="ocr-link"
                  href="javascript:;"
                  (click)="openDialog(myDialog); openOCRModal()"
                >
                  <img
                    [src]="
                      '../../../../../assets/images/ocr-zoom.svg'
                        | CDNUrlRewritePipe
                    "
                    alt=""
                  />
                  - OCR
                </a>
              </div>
              <div
                class="Ar-camera"
                *ngIf="transactionCU?.currentCU?.uiProperties?.ar_id"
              >
                <span class="material-icons" (click)="openArCamera(selectedCu)"
                  >photo_camera</span
                >
                <span class="Ar-text"> -AR</span>
              </div>
              <div
                class="pushbackhistory"
                *ngIf="isPushbackCommentEnabled && commentsOnCU?.length > 0"
              >
                <button
                  class="nh-btn nh-btn-pushbackhistory nh-bg-50 nh-radius-6 nh-display-inline-flex nh-align-center nh-justify-center nh-position-relative"
                  (click)="openCommentPanel()"
                >
                  <span
                    class="nh-btn-pushbackhistory-count nh-position-absolute nh-display-inline-block nh-rad-50"
                    >{{ commentsOnCU.length }}</span
                  >
                  <em class="nh-icon nh-icon-history-chat"></em>
                </button>
              </div>
            </div>
          </div>
          <h2
            class="nh-fw-700 nh-cuColor nh-fontFamily"
            [ngClass]="{
              'nh-fs-24': isIpadLandscape,
              'nh-cu-fontsize': !isIpadLandscape
            }"
            *ngIf="!isHideCU"
          >
            {{
              selectedCu?.displayName
                ? selectedCu?.displayName
                : selectedCu?.name
            }}
          </h2>
        </div>
        <div class="nhCard_body">
          <div class="row nhCard_row">
            <div
              *ngIf="!layoutConfigurations?.hidePhyLayer"
              class="nhCard_row_col nhCard_row_left {{
                layoutConfigurations?.classLeft
              }}"
              [ngClass]="{
                'nhCard_row_col-empty': infoDisplayNone
              }"
            >
              <div class="nhCard_row_left_inner">
                <app-physical-layer
                  [isTransactionActive]="isTransactionActive"
                  [layoutConfigurations]="layoutConfigurations"
                  class="app-physical-layer"
                  [tempInfoEntList]="tempInfoEntList"
                  [estimatedTime]="selectedCu && selectedCu.estimatedTime"
                  [entitiesList]="physicalEntities"
                  [layoutSetting]="templateType"
                  [isReadOnly]="isReadOnly"
                  (emitFormData)="getFormData($event)"
                  (formValueChanges)="dynamicformValueChange($event)"
                  [gsi]="gsi"
                  [currentCu]="selectedCu"
                  (entityDataEmitForTable)="entityDataForTable($event)"
                  [cuLayoutTemplate]="cuLayoutTemplate"
                  (addMultipleEntities)="addMultipleEntities($event)"
                  [selectedCard]="selectedCard"
                >
                </app-physical-layer>
              </div>
            </div>
            <div
              *ngIf="!layoutConfigurations?.hideInfoLayer"
              class="nhCard_row_col nhCard_row_right {{
                layoutConfigurations?.classRight
              }}"
              [ngClass]="{
                'nhCard_row_col-empty': infoDisplayNone
              }"
            >
              <div
                class="nhCard_row_right_inner"
                *ngIf="
                  numberOfLayers &&
                  (numberOfLayers[0]?.type == 'information' ||
                    numberOfLayers[1]?.type == 'information' ||
                    numberOfLayers[2]?.type == 'information')
                "
                [ngStyle]="{ display: isFacetSearchCu ? 'none' : '' }"
              >
                <app-info-layer
                  [isTransactionActive]="isTransactionActive"
                  (emitFormData)="getFormData($event)"
                  [layoutConfigurations]="layoutConfigurations"
                  class="app-info-layer"
                  [entitiesList]="informationEntities"
                  [isReadOnly]="isReadOnly"
                  (infoLayerformValueChanges)="dynamicformValueChange($event)"
                  [gsi]="gsi"
                  [currentCu]="selectedCu"
                  (entityDataEmitForTable)="infoEntityDataForTable($event)"
                  [cuLayoutTemplate]="cuLayoutTemplate"
                  (addMultipleEntities)="addMultipleEntities($event)"
                >
                </app-info-layer>
              </div>
            </div>
          </div>
          <div
            class="row nhCard_row nh-mt-min-xl-64 nh-mt-max-lg-40"
            *ngIf="
              selectedCu?.disableSTEs &&
              tenantName != 'efundzz' &&
              tenantName != 'ezit' &&
              tenantName != 'callhealth'
            "
          >
            <div class="nhCard_row_col nhCard_row_col-full col-12">
              <button
                #tooltip="matTooltip"
                [disabled]="isReadOnly"
                id="Record_Additional_information"
                matTooltip="{{ labels?.Record_Additional_Information }}"
                [matTooltipPosition]="'right'"
                matTooltipClass="additionalInfo-tooltip"
                class="additionalInfo border-none nh-bg-transparent p-0 cursor-pointer"
                (click)="!isReadOnly && openAdditionDialog()"
              >
                <em class="nh-icon nh-icon-plus-circle"></em>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="nhCard_footer"
        [ngClass]="
          buttonAction?.positionFixed
            ? 'nh-position-fixed'
            : 'nh-position-static'
        "
        [attr.culayoutActionPosition]="
          buttonAction?.positionFixed ? 'fixed' : 'static'
        "
      >
        <div
          class="cuButton_inner"
          [ngClass]="{
            'justify-content-end': buttonAction?.buttonAlignment === 'right',
            'justify-content-center': buttonAction?.buttonAlignment === 'center'
          }"
        >
          <div class="cuButtons" *ngIf="!saveButtonStyle?.Save?.disableButton">
            <button
              (click)="saveAsDraft()"
              [disabled]="isReadOnly"
              class="cuButtons-btn cuButtons-btn-save"
              [ngClass]="{
                'cuButtons-btn-rounded':
                  saveButtonStyle?.Save?.style == 'rounded',
                'cuButtons-btn-corner':
                  saveButtonStyle?.Save?.style == 'rounded corner',
                'cuButtons-btn-solid': saveButtonStyle?.Save?.style == 'solid',
                'cuButtons-btn-save-icon': !saveButtonStyle?.Save?.svgUrl,
                'cuButtons-btn-save-noicon':
                  saveButtonStyle?.Save?.format == 'icon',
                'enable-shadow': buttonAction.buttonDropShadow
              }"
            >
              <span
                *ngIf="
                  saveButtonStyle?.Save?.format == 'button' &&
                  saveButtonStyle?.Save?.style
                "
              >
                {{ labels?.[saveButtonStyle?.Save?.actionName] ? labels?.[saveButtonStyle?.Save?.actionName]: saveButtonStyle?.Save?.actionName }}
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  !saveButtonStyle?.Save?.svgUrl &&
                  saveButtonStyle?.Save?.format == 'icon'
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/save.svg"
                  alt="Save"
                />
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  showdefaultSaveButtons ||
                  (saveButtonStyle?.Save?.format == 'button' &&
                    !saveButtonStyle?.Save?.style) ||
                  (saveButtonStyle?.Save?.format == 'icon' &&
                    !saveButtonStyle?.Save?.svgUrl)
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/save.svg"
                  alt="Save"
                />
              </span>
            </button>
            <span
              *ngIf="
                saveButtonStyle?.Save?.format !== 'button' ||
                showdefaultSaveButtons ||
                (saveButtonStyle?.Save?.format == 'button' &&
                  !saveButtonStyle?.Save?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[saveToolTip] ? labels?.[saveToolTip]: saveToolTip }}
            </span>
          </div>
          <div
            class="cuButtons"
            *ngIf="
              !details?.showNext &&
              !cancelButtonStyle?.Cancel?.disableButton &&
              !isMobile
            "
          >
            <button
              (click)="goBack()"
              [disabled]="isReadOnly"
              class="cuButtons-btn cuButtons-btn-cancel"
              [ngClass]="{
                'cuButtons-btn-rounded':
                  cancelButtonStyle?.Cancel?.style == 'rounded',
                'cuButtons-btn-corner':
                  cancelButtonStyle?.Cancel?.style == 'rounded corner',
                'cuButtons-btn-solid':
                  cancelButtonStyle?.Cancel?.style == 'solid',
                'cuButtons-btn-cancel-icon': !cancelButtonStyle?.Cancel?.svgUrl,
                'cuButtons-btn-cancel-noicon':
                  cancelButtonStyle?.Cancel?.format == 'icon',
                'enable-shadow': buttonAction.buttonDropShadow
              }"
            >
              <span
                *ngIf="
                  cancelButtonStyle?.Cancel?.format == 'button' &&
                  cancelButtonStyle?.Cancel?.style
                "
              >
                {{ labels?.[cancelButtonStyle?.Cancel?.actionName] ? labels?.[cancelButtonStyle?.Cancel?.actionName]: cancelButtonStyle?.Cancel?.actionName }}
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  !cancelButtonStyle?.Cancel?.svgUrl &&
                  cancelButtonStyle?.Cancel?.format == 'icon'
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/cancel.svg"
                  alt=" "
                />
              </span>
              <span
                class="cuButtons-imgs"
                *ngIf="
                  showdefaultCancelButtons ||
                  (cancelButtonStyle?.Cancel?.format == 'button' &&
                    !cancelButtonStyle?.Cancel?.style) ||
                  (cancelButtonStyle?.Cancel?.format == 'icon' &&
                    !cancelButtonStyle?.Cancel?.svgUrl)
                "
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/cancel.svg"
                  alt=" "
                />
              </span>
            </button>
            <span
              *ngIf="
                cancelButtonStyle?.Cancel?.format !== 'button' ||
                showdefaultCancelButtons ||
                (cancelButtonStyle?.Cancel?.format == 'button' &&
                  !cancelButtonStyle?.Cancel?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[cancelToolTip] ? labels?.[cancelToolTip]: cancelToolTip }}
            </span>
          </div>
          <!-- For Back Button -->
          <ng-container
            [ngTemplateOutlet]="backBtnTemplate"
            [ngTemplateOutletContext]="{ btnPos: 'bottom' }"
          ></ng-container>
          <!-- For Submit Button -->
          <div class="cuButtons" *ngIf="!hideSubmitButton">
            <button
              (click)="onSubmit($event)"
              id="submitTrans"
              class="cuButtons-btn cuButtons-btn-submit"
              [ngClass]="{
                'cuButtons-btn-rounded':
                  submitButtonStyle?.Submit?.style == 'rounded',
                'cuButtons-btn-corner':
                  submitButtonStyle?.Submit?.style == 'rounded corner',
                'cuButtons-btn-solid':
                  submitButtonStyle?.Submit?.style == 'solid',
                'cuButtons-btn-submit-icon': !submitButtonStyle?.Submit?.svgUrl,
                'cuButtons-btn-submit-noicon':
                  submitButtonStyle?.Submit?.format == 'icon',
                'enable-shadow': buttonAction.buttonDropShadow
              }"
            >
              <span *ngIf="details?.showNext">{{ labels?.Next }}</span>
              <span
                *ngIf="
                  submitButtonStyle?.Submit?.format == 'button' &&
                  submitButtonStyle?.Submit?.style
                "
              >
                {{ labels?.[submitButtonStyle?.Submit?.actionName] ? labels?.[submitButtonStyle?.Submit?.actionName]: submitButtonStyle?.Submit?.actionName }}
              </span>
              <span
                *ngIf="
                  !details?.showNext &&
                  !submitButtonStyle?.Submit?.svgUrl &&
                  submitButtonStyle?.Submit?.format == 'icon'
                "
                class="cuButtons-imgs"
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/right-arrow-submit.svg"
                  alt=" "
              /></span>
              <span
                *ngIf="
                  showdefaultSubmitButtons ||
                  (submitButtonStyle?.Submit?.format == 'button' &&
                    !submitButtonStyle?.Submit?.style) ||
                  (submitButtonStyle?.Submit?.format == 'icon' &&
                    !submitButtonStyle?.Submit?.svgUrl)
                "
                class="cuButtons-imgs"
              >
                <img
                  class="e-commerce-imgsize"
                  src="../../../../../assets/img/right-arrow-submit.svg"
                  alt=" "
              /></span>
            </button>
            <span
              *ngIf="
                submitButtonStyle?.Submit?.format !== 'button' ||
                showdefaultSubmitButtons ||
                (submitButtonStyle?.Submit?.format == 'button' &&
                  !submitButtonStyle?.Submit?.style)
              "
              class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
            >
              {{ labels?.[submitToolTip] ? labels?.[submitToolTip]: submitToolTip }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Ads bottom-->
  <div
    class="add-bottom-block nh-position-fixed"
    id="ads-bottom-block"
    *ngIf="adsBottom != undefined"
  >
    <div class="card-section-ads" id="ads-bottom-block-div">
      <div class="card-section-ads-left nh-display-flex nh-align-center">
        <span class="ad-label">Ad</span>
        <figure
          class="card-img-ads cursor-pointer"
          *ngIf="adsBottom?.s3Location"
          (click)="adClicked(adsBottom)"
          id="ads-bottom-logo"
        >
          <img
            [title]="adsBottom?.destinationUrl"
            [src]="adsBottom?.s3Location"
          />
        </figure>
      </div>
      <div class="card-section-ads-right nh-display-flex nh-align-center">
        <p class="card-text-ads text-truncate" *ngIf="adsBottom?.description">
          {{ adsBottom?.description }}
        </p>
        <p
          class="card-link-ads"
          id="ads-bottom-action"
          (click)="adClicked(adsBottom)"
        >
          <a href="javascript:;">{{ adsBottom?.action }}</a>
        </p>
      </div>
    </div>
  </div>
  <!--Ads bottom end-->
</div>

<ng-template #myDialog>
  <div mat-dialog-content>
    <div class="row dragNdrop">
      <h6>OCR Extraction</h6>
      <div class="col text-center">
        <label for="dragNdrop">
          <span class="raised">Browse</span>
        </label>
      </div>
      <div class="col gray-txt">
        <input
          type="file"
          (change)="fileChange($event)"
          id="dragNdrop"
          class="dragNdropInput"
        />
        <label for="dragNdrop">Or Drop file here</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #thankumodal>
  <div mat-dialog-content class="thankumodal">
    <div class="welcome-pop text-center">
      <figure>
        <img
          [src]="
            '../../../assets/images/carnival/carnival/roadmap/thanku-redirect.svg'
              | CDNUrlRewritePipe
          "
          alt="Thank you for being a part of NH Carnival!"
        />
      </figure>
      <h2>Thank you for being a part of NH Carnival!</h2>
      <h5>Redirecting to nslhub in 00:{{ timerToRedirect }} sec.</h5>
      <p>We have sent you an e-mail with your nslhub login details.</p>
      <p>You can always login later using that.</p>
    </div>
  </div>
</ng-template>

<ng-template #ArDialog>
  <div mat-dialog-content class="thankumodal">
    <button mat-button mat-dialog-close class="close-btn-ar-box">
      <mat-icon>close</mat-icon>
    </button>
    <div class="welcome-pop text-center">
      <h3>Change Unit is Containing Augmented Reality Entities</h3>
      <button
        *ngIf="physicalBtn"
        mat-button
        class="ar-btns"
        (click)="openArCamera('PE')"
      >
        Physical Entity View
      </button>
      <button
        *ngIf="arBtnEntity"
        mat-button
        class="ar-btns"
        (click)="openArCamera('AE')"
      >
        Augmented Entity View
      </button>
      <button
        *ngIf="hybridBtn"
        mat-button
        class="ar-btns"
        (click)="openArCamera('HE')"
      >
        Hybrid Entity View
      </button>
      <p *ngIf="!physicalBtn && !arBtnEntity && !hybridBtn">No Data Found</p>
    </div>
  </div>
</ng-template>

<app-pushback-transaction-view
  class="d-none"
  [comment]="comment"
  [callTriggerPushback]="callTriggerPushback"
  [selectedCuIndexForPushback]="selectedCuIndexForPushback"
  (isPushbackEnabled)="isPushbackEnabled = $event"
  (isPushbackCommentEnabled)="isPushbackCommentEnabled = $event"
  (sendCUsListForPushback)="CUsListForPushback = $event"
  (sendSelectedCuIndexForPushback)="selectedCuIndexForPushback = $event"
  (sendCommentsOnCU)="
    commentsOnCU = $event;
    commentsOnCU?.length > 0 ? openCommentPanel() : closeCommentPanel()
  "
  (clearComment)="comment = $event"
></app-pushback-transaction-view>

<ng-template #backBtnTemplate let-btnPos="btnPos">
  <button
    *ngIf="!isPushbackEnabled && btnPos === 'top'"
    role="button"
    class="nh-unsetAll cursor-pointer nh-gsiColor"
    (click)="goBack()"
  >
    <em
      class="nh-icon nh-icon-chevron-left"
      [ngClass]="{
        'nh-fs-12': isIpadLandscape,
        'nh-gsi-fontsize': !isIpadLandscape
      }"
    ></em>
  </button>

  <!-- For Pushback Back Button on top -->
  <div class="changeUnitDropwn" *ngIf="isPushbackEnabled && btnPos === 'top'">
    <button
      role="button"
      class="nh-unsetAll cursor-pointer nh-gsiColor"
      (click)="backBtnClick($event, op1, culist1)"
    >
      <em
        class="nh-icon nh-icon-chevron-left"
        [ngClass]="{
          'nh-fs-12': isIpadLandscape,
          'nh-gsi-fontsize': !isIpadLandscape
        }"
      ></em>
    </button>

    <!-- List of Pushback CUs -->
    <p-overlayPanel
      #culist1
      id="culist1"
      styleClass="cuPushback-changeUnitDropwn"
      [dismissable]="false"
      (onHide)="selectedCuIndexForPushback = -1"
      appendTo="body"
    >
      <ng-template pTemplate>
        <div class="cuPushback-changeUnitDropwn-inner">
          <button
            *ngFor="let cu of CUsListForPushback; let cuIndex = index"
            (click)="selectPushbackCU($event, op1, culist1, cuIndex)"
            [ngClass]="{
              'active-changeUnitDropwn-item':
                selectedCuIndexForPushback === cuIndex
            }"
            type="button"
            [disabled]="isCommentPanelVisible"
          >
            {{
              cu?.triggerCUDisplayName
                ? cu.triggerCUDisplayName
                : cu.triggerCUName
            }}
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>

    <!-- Pushback Comment Panel -->
    <p-overlayPanel
      #op1
      id="topBackBtn"
      styleClass="cuPushback-popover"
      (onShow)="isCommentPanelVisible = true"
      (onHide)="isCommentPanelVisible = false; onCommentPanelHide(culist1)"
      appendTo="body"
    >
      <ng-template pTemplate>
        <div class="cuPushback-popover-inner">
          <textarea
            [(ngModel)]="comment"
            class="cuPushback-popover-textarea write-comments"
            placeholder="Provide comments here"
          ></textarea>
        </div>
        <div class="cuPushback-popover-footer text-right">
          <button
            type="button"
            class="nh-unsetAll cursor-pointer"
            (click)="triggerPushbackFunc($event, op1)"
          >
            <em class="nh-icon nh-icon-arrow-right-short"></em>
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>

  <!-- For Pushback Back Button on bottom -->
  <div class="cuButtons" *ngIf="isPushbackEnabled && btnPos === 'bottom'">
    <button
      (click)="backBtnClick($event, op2, culist2)"
      id="backTrans"
      class="cuButtons-btn cuButtons-btn-back cuButtons-btn-back-icon"
      [ngClass]="{
        'cuButtons-btn-rounded': backButtonStyle?.Back?.style == 'rounded',
        'cuButtons-btn-corner':
          backButtonStyle?.Back?.style == 'rounded corner',
        'cuButtons-btn-solid': backButtonStyle?.Back?.style == 'solid',
        'cuButtons-btn-back-icon': !backButtonStyle?.Back?.svgUrl,
        'cuButtons-btn-back-noicon': backButtonStyle?.Back?.format == 'icon',
        'enable-shadow': buttonAction.buttonDropShadow
      }"
    >
      <span *ngIf="details?.showNext">Back</span>
      <span
        *ngIf="
          backButtonStyle?.Back?.format == 'button' &&
          backButtonStyle?.Back?.style
        "
      >
        {{ labels?.[backButtonStyle?.Back?.actionName] ? labels?.[backButtonStyle?.Back?.actionName]: backButtonStyle?.Back?.actionName }}
      </span>
      <span
        *ngIf="
          !details?.showNext &&
          !backButtonStyle?.Back?.svgUrl &&
          backButtonStyle?.Back?.format == 'icon'
        "
        class="cuButtons-imgs"
      >
        <img
          class="e-commerce-imgsize"
          src="../../../../../assets/img/left-arrow-submit.svg"
          alt=" "
      /></span>
      <span
        *ngIf="
          showdefaultBackButtons ||
          (backButtonStyle?.Back?.format == 'button' &&
            !backButtonStyle?.Back?.style) ||
          (backButtonStyle?.Back?.format == 'icon' &&
            !backButtonStyle?.Back?.svgUrl)
        "
        class="cuButtons-imgs"
      >
        <img
          class="e-commerce-imgsize"
          src="../../../../../assets/img/left-arrow-submit.svg"
          alt=" "
      /></span>
    </button>

    <span
      *ngIf="
        (backButtonStyle?.Back?.format !== 'button' ||
          showdefaultBackButtons ||
          (backButtonStyle?.Back?.format == 'button' &&
            !backButtonStyle?.Back?.style)) &&
        isPushbackEnabled
      "
      class="cuButtons-btn-text nh-display-inline-block text-capitalize text-center"
    >
      Back
    </span>

    <!-- List of Pushback CUs -->
    <p-overlayPanel
      #culist2
      id="culist2"
      styleClass="cuPushback-changeUnitDropwn"
      (onHide)="selectedCuIndexForPushback = -1"
      appendTo="body"
    >
      <ng-template pTemplate>
        <div class="cuPushback-changeUnitDropwn-inner">
          <button
            *ngFor="let cu of CUsListForPushback; let cuIndex = index"
            (click)="selectPushbackCU($event, op2, culist2, cuIndex)"
            [ngClass]="{
              'active-changeUnitDropwn-item':
                selectedCuIndexForPushback === cuIndex
            }"
            type="button"
            [disabled]="isCommentPanelVisible"
          >
            {{
              cu?.triggerCUDisplayName
                ? cu.triggerCUDisplayName
                : cu.triggerCUName
            }}
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>

    <!-- Pushback Comment Panel -->
    <p-overlayPanel
      #op2
      id="bottomBackBtn"
      styleClass="cuPushback-popover"
      (onShow)="isCommentPanelVisible = true"
      (onHide)="isCommentPanelVisible = false; onCommentPanelHide(culist2)"
      appendTo="body"
    >
      <ng-template pTemplate>
        <div class="cuPushback-popover-inner">
          <textarea
            [(ngModel)]="comment"
            class="cuPushback-popover-textarea write-comments"
            placeholder="Provide comments here"
          ></textarea>
        </div>
        <div class="cuPushback-popover-footer text-right">
          <button
            type="button"
            class="nh-unsetAll cursor-pointer"
            (click)="triggerPushbackFunc($event, op2)"
          >
            <em class="nh-icon nh-icon-arrow-right-short"></em>
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>
</ng-template>

<!-- Pushback Comment History -->
<div
  class="cuPushback-chathistory-overlay nh-position-fixed nh-start-0 nh-top-0 nh-wp-100 nh-hp-100"
  (click)="closeCommentPanel()"
  [ngClass]="{ 'cuPushback-chathistory-overlay-in': chatHistoryPanel }"
></div>
<div
  class="cuPushback-chathistory nh-position-fixed nh-end-0 nh-top-0 nh-hp-100 nh-bg-50"
  [ngClass]="{ 'cuPushback-chathistory-in': chatHistoryPanel }"
>
  <div class="cuPushback-chathistory-header nh-position-relative">
    <h4>{{ labels?.Comment }} ({{ commentsOnCU.length }})</h4>
    <button
      type="button"
      class="nh-unsetAll cursor-pointer nh-position-absolute"
      (click)="closeCommentPanel()"
    >
      <em class="nh-icon nh-icon-x-lg"></em>
    </button>
  </div>
  <div class="cuPushback-chathistory-content">
    <ng-container
      *ngFor="let section of commentsOnCU; let sectionIndex = index"
    >
      <div
        class="cuPushback-chathistory-item nh-display-flex"
        *ngFor="let cmt of section.comments"
      >
        <div class="cuPushback-chathistory-item-image">
          <em class="nh-icon nh-icon-agent-fill"></em>
        </div>
        <div class="cuPushback-chathistory-item-cnt">
          <div class="itemheader nh-display-flex justify-content-between">
            <h4 *ngIf="cmt.userId === userInfo.id" class="username">
              {{ labels?.You }}
            </h4>
            <h4 *ngIf="cmt.userId !== userInfo.id" class="username">
              {{ cmt.userName }}
            </h4>
            <span class="time">{{ cmt.displayDate }}</span>
          </div>
          <div class="cutargetdiv nh-display-flex justify-content-between">
            <div class="cutargetdiv_01">
              <strong>{{ labels?.SourceCU }}:</strong>
              <span>{{ section?.sourceTriggerCuName }}</span>
            </div>
            <div class="cutargetdiv_02">
              <strong>{{ labels?.TargetCU }}:</strong>
              <span>{{ section?.targetTriggerCuName }}</span>
            </div>
          </div>
          <div class="desc">
            {{ cmt.message }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
