import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AttributeStoreService,dependentAttributeValue,EntityStoreService, EventsFacadeService, FieldConfig, getAttributeValue, getUiControlCustomization, setAttributeValue, setErrorDependentAttribute, TransactionFacadeService, TranslatorService } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-dateonly',
  templateUrl: './dateonly.component.html',
  styleUrls: ['./dateonly.component.scss'],
})
export class DateOnlyComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  minDate: Date;
  maxDate: Date;
  tempValue: any = null;
  formattedValue: any;
  requiredFormat: string = 'MM/dd/yyyy';
  requiredFormatPrime: string = 'mm/dd/yy';
  labels: any;
  private ngUnsubscribe = new Subject();
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  invalidDate: boolean = false;
  appliedClass: string = 'ui-custom-calendar ui-custom-date';
  foundObject: any;
  // Variables related to NSL_SlotBooking
  availableDatesArray:any[]=[];
  isDatepickerClosed:boolean = false;
  selectedMonth:any;
  bodyAppend: string = 'body';
  validationList: any[] = [
    'onorafterValidation',
    'afterValidation',
    'onorbeforeValidation',
    'beforeValidation',
    'equalTo',
    'notequalTo',
    'pastorpresent',
    'present',
    'past',
    'future',
    'presentorfuture',
    'pastorfuture',
  ];
  /*istanbul ignore next*/
  specificDatesArrays = (date: Date | null): boolean =>{
    if(this.availableDatesArray?.length == 0) return true;
    return this.availableDatesArray?.some((enabledDate:any) =>
      date &&
      date.getDate() === new Date(enabledDate).getDate()
    );
  }
  disableSpecificDate: Date[];
  constructor(
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    private dateAdapter: DateAdapter<any>,
    private datePipe: DatePipe,
    private entityStoreService:EntityStoreService,
    private attributeStoreService:AttributeStoreService,
    public transactionFacadeService: TransactionFacadeService
  ) {
    this.group = new FormGroup({
      dateSelectData: new FormControl(''),
    });
    this.getAvailableDates();
  }
  ngOnInit() {
    console.log(this.field);

    if (this.field?.type === 'currentdateonly') {
      this.field.value = new Date();
      this.group.controls[this.field.attribute.name].setValue(this.field.value);
      this.formattedValue = moment(this.field?.value).format(
        this.field?.attributeType?.properties?.format?.toUpperCase()
      );
    }

    /*istanbul ignore next*/
    if (this.field?.value) {
      var offset = new Date(this.field?.value).getTimezoneOffset(); //return in minutes
      // Calculate the offset in milliseconds
      const offsetMilliseconds = offset * 60 * 1000;
      // Adjust the date by the offset
      this.field.value = new Date((new Date(this.field?.value)).getTime() + offsetMilliseconds);
      // this.field.value = new Date(this.field.value);
      const date =
        this.field.value.getFullYear() + '-' + (this.field.value.getMonth() + 1) + '-' + this.field.value.getDate();
      /*istanbul ignore next*/
      this.group.controls[this.field?.attribute?.name].setValue(date);
      // /*istanbul ignore next*/
      // dateDetails = dateDetails.getFullYear() + '-' + (dateDetails.getMonth() + 1) + '-' + dateDetails.getDate();
      // /*istanbul ignore next*/
      // this.date = dateDetails;
    }
     /*istanbul ignore next*/
     if (this.field?.configuration?.dateTimeFormat?.format) {
      /*istanbul ignore next*/
      this.requiredFormat = this.field?.configuration?.dateTimeFormat?.format;
    } else if (this.field?.attributeType?.properties?.format) {
      /*istanbul ignore next*/
      this.requiredFormat = this.field?.attributeType?.properties?.format;
    }
    /* istanbul ignore next */
    this.requiredFormatPrime = this.eventsService.convertDateFormat(this.requiredFormat);
    this.tempValue = this.field.value;
    let dateOption = getUiControlCustomization('DatePicker');
    /* istanbul ignore else */
    if (dateOption) {
      this.foundObject = { event: dateOption };
      let op = dateOption.slice(-1);
      if (dateOption == 'Option 7') {
        op = 1;
      }
      this.appliedClass = `form-custom-date form-custom-date-opt${op}`;
    }
    let mccPopup = sessionStorage.getItem('template');
    this.bodyAppend = mccPopup?.includes('cupopup')
      ? (this.field.attribute['isTableConfig'] ? 'body' : '')
      : !this.foundObject?.event || this.foundObject?.event == ''
      ? 'body'
      : '';
  }

  setOptionalValidators() {
    if (this.field?.optionalValidation) {
      if (!this.field?.validations) {
        this.field.validations = this.field?.optionalValidation;
        const validList: any = [];
        this.field?.validations?.forEach((valid: any) => {
          validList.push(valid.validator);
        });
        this.group?.controls[this.field?.attribute?.name]?.setValidators(Validators.compose(validList));
        this.group?.controls[this.field?.attribute?.name]?.updateValueAndValidity();
      }
    }
  }

  getAfterDate(object: any, field: any) {
    let dE = object?.dependentExpression;
    let formData = this.eventsService?.formData;
    /* istanbul ignore else */
    if (dE) {
      setAttributeValue();
      dependentAttributeValue(dE, formData, false, field);
      return new Date(getAttributeValue());
    }
  }

  setMinAndMaxDate(type: any, obj: any) {
    /* istanbul ignore next */
    let min, max, date;
    max = min = this.getAfterDate(obj, this.field) || new Date(obj?.value);
    switch (type) {
      case 'onorafterValidation':
        /* istanbul ignore else */
        if (min) {
          this.minDate = min;
          this.minDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'afterValidation':
        /* istanbul ignore else */
        if (min != undefined) {
          date = new Date(min);
          date?.setDate(date?.getDate() + 1);
          this.minDate = date;
          this.minDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'onorbeforeValidation':
        /* istanbul ignore else */
        if (max) {
          this.maxDate = max;
          this.maxDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'beforeValidation':
        /* istanbul ignore else */
        if (max != undefined) {
          date = new Date(max);
          date?.setDate(date?.getDate() - 1);
          this.maxDate = date;
          this.maxDate?.setHours(0, 0, 0, 0);
        }
        break;
      case 'equalTo':
        /* istanbul ignore else */
        if (max != undefined) {
          this.maxDate = max;
        }
        /* istanbul ignore else */
        if (min != undefined) {
          this.minDate = min;
        }
        this.minDate?.setHours(0, 0, 0, 0);
        this.maxDate?.setHours(0, 0, 0, 0);
        break;
      case 'pastorpresent':
        this.maxDate = new Date();
        /* istanbul ignore next */
        if (obj?.notBeforeDays) {
          this.minDate = new Date(new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000);
        } else {
          /* istanbul ignore next */
          this.minDate = new Date(obj?.notBeforeDate);
        }
        break;

      case 'present':
        this.maxDate = this.minDate = new Date();
        break;

      case 'past':
        let maxdateObj = new Date();
        maxdateObj.setDate(maxdateObj.getDate() - 1);
        this.maxDate = maxdateObj;
        /* istanbul ignore next */
        if (obj?.notBeforeDays) {
          this.minDate = new Date(new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000);
        } else {
          /* istanbul ignore next */
          this.minDate = new Date(obj?.notBeforeDate);
        }
        break;

      case 'future':
        let dateObj = new Date();
        dateObj.setHours(0, 0, 0, 0);
        dateObj.setDate(dateObj.getDate() + 1);
        this.minDate = dateObj;
        /* istanbul ignore next */
        if (obj?.notAfterDays) {
          this.maxDate = new Date(new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000);
        } else {
          /* istanbul ignore next */
          this.maxDate = new Date(obj?.notAfterDate);
        }
        break;

      case 'presentorfuture':
        this.minDate = new Date();
        /* istanbul ignore next */
        if (obj?.notAfterDays) {
          this.maxDate = new Date(new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000);
        } else {
          /* istanbul ignore next */
          this.maxDate = new Date(obj?.notAfterDate);
        }
        break;

      case 'pastorfuture':
        // this.myFilter = (d: Date): boolean => {
        //   const day = this.dateToEpoch(d);
        //   const today = this.dateToEpoch(new Date());
        //   return day !== today;
        // };
        /* istanbul ignore next */
        if (obj?.notBeforeDays) {
          this.minDate = new Date(new Date().getTime() - obj.notBeforeDays * 24 * 60 * 60 * 1000);
        } else {
          /* istanbul ignore next */
          this.minDate = new Date(obj?.notBeforeDate);
        }
        /* istanbul ignore next */
        if (obj?.notAfterDays) {
          this.maxDate = new Date(new Date().getTime() + obj.notAfterDays * 24 * 60 * 60 * 1000);
        } else {
          /* istanbul ignore next */
          this.maxDate = new Date(obj?.notAfterDate);
        }
        break;
    }
  }

  validateDependentExpressionDate(validation: any) {
    let min: Date,
      max: Date,
      flag = false;
    max = min = this.getAfterDate(validation, this.field) || new Date(validation?.value);
    if (max) {
      min.setHours(0, 0, 0, 0);
      max.setHours(0, 0, 0, 0);
    }
    if((this.group?.controls[this.field?.attribute.name]?.value!=null &&this.group?.controls[this.field?.attribute.name]?.value!=undefined &&this.group?.controls[this.field?.attribute.name]?.value!='')){
    switch (validation.name) {
      case 'onorafterValidation':
      case 'afterValidation':
        /* istanbul ignore else */
        if (min != undefined) {
          if (this.group?.controls[this.field?.attribute?.name]?.value?.getTime() < this.minDate?.getTime()) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
        }
        break;
      case 'onorbeforeValidation':
      case 'beforeValidation':
        /* istanbul ignore else */
        if (max != undefined) {
          if (this.group?.controls[this.field?.attribute?.name]?.value?.getTime() > this.maxDate?.getTime()) {
            setErrorDependentAttribute(validation, this.field, this.group);
            flag = true;
          }
        }
        break;
      case 'equalTo':
        /* istanbul ignore next */
        if (this.group?.controls[this.field?.attribute?.name]?.value?.getTime() != max?.getTime()) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;
      case 'notequalTo':
        this.disableSpecificDate = [new Date(min)];
        if (
          this.group?.controls[this.field?.attribute?.name]?.value?.getTime() == this.disableSpecificDate[0]?.getTime()
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;
      case 'pastorpresent':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'present':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'past':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'future':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'presentorfuture':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;

      case 'pastorfuture':
        if (
          this.group?.controls[this.field?.attribute?.name]?.value < this.minDate ||
          this.group?.controls[this.field?.attribute?.name]?.value > this.maxDate
        ) {
          setErrorDependentAttribute(validation, this.field, this.group);
          flag = true;
        }
        break;
    }
  }
    this.group?.status === 'INVALID'
      ? this.transactionFacadeService.disableSubmitButtonFlag.next(true)
      : this.transactionFacadeService.disableSubmitButtonFlag.next(false);
    return flag;
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return this.validateDependentExpressionDate(validation);
    }
    /* istanbul ignore next */
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.validationList?.includes(validation?.name)) {
      return this.validateDependentExpressionDate(validation);
    }
    /* istanbul ignore next */
    if (
      this.group?.controls[field?.attribute.name]?.value
    ) {
      return (this.group?.controls[field.attribute.name].value).toString().match(validation.requiredPattern)
        ? false
        : true;
    }
  }


  clicked(event: any) {
    if (!this.field.isHidden) {
      //console.log(event);
      /*istanbul ignore next*/
      if (event) {
        /*istanbul ignore next*/
        const date = event.getFullYear() + '-' + (event.getMonth() + 1) + '-' + event.getDate();
        /*istanbul ignore next*/
        this.group.controls[this.field?.attribute?.name].setValue(date);
        /*istanbul ignore next*/
        this.field.value = new Date(event);
        this.group.controls[this.field.attribute.name].setValue(this.field.value);
        /*istanbul ignore next*/
        this.tempValue = this.field.value;
        const data = {
          attributeId: this.field?.attribute['id'],
          isTableConfig: this.field?.attribute['isTableConfig'],
          attrName: this.field?.attribute['name'],
          eventType: 'ON_CHANGE',
          entityName: this.field?.entityName,
          entityId: this.field?.entityId,
          slotNumber: this.field?.slotNumber,
          isInfo: this.field?.isInfo,
          isMulti: this.field?.isMultiEntity,
          txnRecordId: this.field?.txnRecordId,
          ent_index: this.field?.ent_index,
        };
        /* conditional potentiality check  */
        /* istanbul ignore next */
        if (this.field?.triggerConditionalPotentiality) {
          this.eventsService.setTriggerEvent(data);
        } else {
          this.eventsService.setEvent(data);
        }
      }
    }
  }

  onBlur(event?: any) {
    this.invalidDate = this.field.value && this.field.value?.length != 10 &&
      !moment(this.field.value, this.requiredFormat?.toUpperCase(), true).isValid();
    let currentDate: any = new Date(event.currentTarget.value);
    if (JSON.stringify(this.field.value) != JSON.stringify(this.tempValue)) {
      this.clicked(this.field.value);
    }
    if(!isNaN(Date.parse(currentDate))){
      this.group.controls[this.field.attribute.name].setValue(currentDate);
    }
  }
  keyPress(event: any) {
    var currDate = event.target.value;
    if (event.key) {
      var numRegex = /[0-9]|[\/]/;
      if (!numRegex.test(event.key)) {
        event.preventDefault();
        return;
      }
      if ((event.key == '/' && currDate.length != 2 && currDate.length != 5) || currDate.length > 9) {
        event.preventDefault();
        return;
      }
    }
  }
  inputChange(event: any) {
    var currDate = event.currentTarget.value;
    if (event.data && event.data != '/') {
      if (currDate.length == 2) {
        event.currentTarget.value = currDate + '/';
      } else if (currDate.length == 5) {
        event.currentTarget.value = currDate + '/';
      }
    }
    this.invalidDate = currDate?.length === 10 && !moment(currDate, this.requiredFormat.toUpperCase(), true).isValid();
  }
  dateClicked(event: any) {
    if (this.field?.isOptional) {
      this.setOptionalValidators();
    }

    this.field?.validations?.forEach((obj) => {
      /* istanbul ignore next */
      if (obj.name != 'required') {
        this.setMinAndMaxDate(obj.name, obj);
      }
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.languageChange();
    });
  }
  languageChange() {
    this.dateAdapter.setLocale(localStorage.locale);
  }

  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.field.value = normalizedMonth;
    /* istanbul ignore next */
    if (this.field?.entityName?.includes('NSL_SlotBooking')) {
      if(this.isDatepickerClosed){
        normalizedMonth = this.datePipe.transform(normalizedMonth?.value, 'yyyy-MM-dd');
        this.isDatepickerClosed = false;
        this.attributeStoreService.setStateData('isDateSelected');
      }else{
        normalizedMonth = this.datePipe.transform(normalizedMonth, 'yyyy-MM');
        this.attributeStoreService.setStateData('isMonthSelected');
      }
      this.group?.controls[this.field?.attribute?.name]?.setValue(normalizedMonth);
      this.eventsService.setEvent({
        attributeId: this.field?.attribute['id'],
        isTableConfig: this.field?.attribute['isTableConfig'],
        attrName: this.field?.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field?.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
        type: this.field?.type,
        dateChanged:normalizedMonth,
      });
    }
  }

  calendarClosed(normalizedMonth: any, datepicker: MatDatepicker<any>){
    this.isDatepickerClosed = true;
    this.chosenMonthHandler(normalizedMonth,datepicker);
  }

  getAvailableDates(){
    this.availableDatesArray = this.entityStoreService?.getStateData()?.entityList;
    if(this.availableDatesArray?.length > 0){
      let values =  this.availableDatesArray[0]?.split('/');
      this.selectedMonth = new Date(values[2],values[0]-1);
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
