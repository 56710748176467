import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { dependentAttributeValue, EventsFacadeService, FieldConfig, getAttributeValue, getUiControlCustomization, setAttributeValue, TransactionFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-radio-group',
  styleUrls: ['./radio-group.component.scss'],
  template: `
    <span [innerHTML]="field?.sentence"></span>
    <div class="radio-group-wrap" [formGroup]="group" [ngClass]="{
              inValidClass:
                !field?.isInfo &&
                (group?.controls)[field?.attribute?.name]?.invalid &&
                (group?.controls)[field?.attribute?.name]?.touched
            }">
      <div class="d-flex justify-content-between nh-column-gap-10">
        <div class="d-flex align-items-center nh-mb-8 nh-mw-0 main-label-wrapper">
          <label
            class="main-label nh-mb-0"
            pTooltip="{{ field?.label }}"
            tooltipPosition="top"
            tooltipStyleClass="transcustomtolltipbook"
            [style.color]="field.color"
            [style.font-size.px]="field.fontSize"
            *ngIf="!field?.hideLabels && !field?.configuration?.hideLabel && !field?.hideLabelMultiAttr"
            ><span class="main-label-inner"
              ><span class="main-label-text text-truncate">{{ field.label }} <span class="main-label-colon">:</span></span>
              <span *ngIf="field?.isRequired && !field?.isInfo" class="mat-form-field-required-marker">*</span></span
            ></label
          >
          <p
            class="nh-ml-6 d-flex main-label-helperIcon"
            *ngIf="field?.configuration?.showHelperTextIcon"
            [ngStyle]="{
              color: field?.configuration?.helperTextColor,
              'font-size': field?.configuration?.helperTextFont
            }"
          >
            <mat-icon
              class="material-icons-outlined helperText-infoIcon"
              [ngStyle]="{ color: field?.configuration?.helperTextIconColor }"
              matTooltip="{{ field?.configuration?.helpertext }}"
              matTooltipPosition="above"
              *ngIf="!field?.hideLabels"
              >info_outline</mat-icon
            >
          </p>
        </div>
        <div
          class="d-flex align-items-center nh-column-gap-5 pb-2"
          *ngIf="
            field?.nslAttributeProperties?.triggerSubTransaction == 'true' ||
            (field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig'])
          "
        >
          <mat-icon
            matTooltip="Sub-Transactional CU"
            matSuffix
            class="d-flex align-items-center justify-content-center"
            *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
            ><span class="sub-cu" (click)="openSubPopUp()"
              ><img class="d-block" src="../assets/img/transaction/trigger-att.svg"
            /></span>
          </mat-icon>
          <mat-icon
            *ngIf="field.isMulti && !field.configuration?.hideMultiValueIcon && !(field?.attribute)['isTableConfig']"
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            id="radioClick_{{ field.attribute.name }}_{{ instanceNumber }}"
            (click)="addAttribute.next()"
            >add</mat-icon
          >
          <mat-icon
            *ngIf="
              field.isMulti &&
              (field?.isOptional || field?.attrInstanceId != 0) &&
              !field.configuration?.hideMultiValueIcon &&
              !(field?.attribute)['isTableConfig']
            "
            [ngClass]="{ 'mat-icon-disable': field.readonly }"
            matSuffix
            id="radioClick_{{ field.attribute.name }}"
            (click)="removeAttribute.next()"
            >remove</mat-icon
          >
        </div>
      </div>
      <p
        class="top-text"
        *ngIf="field?.configuration?.helperTextPosition == 'top' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <mat-radio-group
        *ngIf="!((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])"
        [formControlName]="field.attribute?.name"
        id="{{ field?.attribute?.name }}_{{ instanceNumber }}"
        [required]="field?.isRequired && !field?.isInfo"
        class="{{ appliedClass }}"
        (change)="onSelectionChange($event)"
        [ngClass]="
          field?.configuration?.showHorizontalOptions
            ? 'horizontal-cls'
            : field?.horizontalOptions
            ? 'horizontal-listrundown-cls'
            : 'vertical-cls'
        "
      >
        <mat-radio-button
          class="radio-button-cls"
          [disabled]="field.readonly"
          *ngFor="let option of field.options"
          [matTooltip]="field?.configuration?.hideTooltip ? '' : option.displayValue"
          [matTooltipPosition]="'above'"
          id="radioClick_{{ field.entityName }}_{{ field.attribute.name }}_{{ option.displayValue }}_{{
            field.attrInstanceId
          }}_{{ instanceNumber }}"
          [value]="option.actualValue"
          tooltipStyleClass="transcustomtolltipbook"
        >
          {{ field?.hideOptionLabels ? '' : option.displayValue }}
        </mat-radio-button>
      </mat-radio-group>
      <!--Radio groups in table-->
      <div class="radio-check-table" *ngIf="((this.field?.attribute)['isTableConfig'] && (this.field?.attribute)['tableUiStyle'])">
        <p-overlayPanel #op styleClass="ui-control-dropdown ui-control-dropdown-rc">
          <mat-radio-group
            [formControlName]="field.attribute?.name"
            id="{{ field?.attribute?.name }}_{{ instanceNumber }}"
            [required]="field?.isRequired && !field?.isInfo"
            class="{{ appliedClass }}"
            (change)="onSelectionChange($event)"
            aria-label="Select an option"
            class="radio-group-wrap"
          >
            <mat-radio-button
              class="radio-button-cls"
              [disabled]="field.readonly"
              *ngFor="let option of field.options"
              [matTooltip]="field?.configuration?.hideTooltip ? '' : option.displayValue"
              [matTooltipPosition]="'above'"
              id="radioClick_{{ field.entityName }}_{{ field.attribute.name }}_{{ option.displayValue }}_{{
                field.attrInstanceId
              }}_{{ instanceNumber }}"
              [value]="option.actualValue"
              tooltipStyleClass="transcustomtolltipbook"
            >
              {{ field?.hideOptionLabels ? '' : option.displayValue }}
            </mat-radio-button>
          </mat-radio-group>
        </p-overlayPanel>
        <span (click)="op.toggle($event)" class="select-label" *ngIf="!group?.value?.value">Select</span>
        <div class="selected-list" *ngIf="group?.value?.value" (click)="op.toggle($event)">
          <ul>
            <li>{{ group?.value?.value }}</li>
          </ul>
          <figure class="w-auto" (click)="op.toggle($event)">
            <img src="../../../assets/images/ui-controls/group-edit-icon.svg" />
          </figure>
        </div>
      </div>
      <p
        class="bottom-text"
        *ngIf="field?.configuration?.helperTextPosition == 'bottom' && !field?.configuration?.showHelperTextIcon"
        [ngStyle]="{ color: field?.configuration?.helperTextColor, 'font-size': field?.configuration?.helperTextFont }"
      >
        {{ field?.configuration?.helpertext }}
      </p>
      <ng-container *ngFor="let validation of field.validations" ngProjectAs="mat-error">
        <mat-error
          *ngIf="
            validation.type &&
            (validation.type === 'ERROR' || validation.type === 'BLOCK_WARN') &&
            getErrorMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
        <mat-error
          [ngClass]="validation.type == 'INFO' ? 'infocolor' : 'warncolor'"
          *ngIf="
            validation.type &&
            validation.type !== 'ERROR' &&
            validation.type !== 'BLOCK_WARN' &&
            getInfoWarnMessage(field, validation)
          "
          >{{ validation.message }}</mat-error
        >
      </ng-container>
    </div>
  `,
})
export class RadioGroupComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  options: any = [
    { name: 'test', value: 'test' },
    { name: 'test2', value: 'test2' },
  ];
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  static count = 0;
  instanceNumber: number = -1;
  appliedClass: string = 'radio-group-cls';

  constructor(
    public dialog: MatDialog,
    private eventsService: EventsFacadeService,
    private transactionFacadeService: TransactionFacadeService
  ) {
    RadioGroupComponent.count++;
    this.instanceNumber = RadioGroupComponent.count;
  }

  ngOnInit(): void {
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
    let radioOptions = getUiControlCustomization('Radio');
    if (radioOptions) {
      let i = radioOptions.slice(-1);
      this.appliedClass = `form-radiobtn-opt form-radiobtn-opt${i}`;
    }
  }

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
  onSelectionChange(event: any) {
    this.validateDependentExpression();
    const data = {
      attributeId: this.field?.attribute['id'],
      isTableConfig: this.field?.attribute['isTableConfig'],
      attrName: this.field?.attribute['name'],
      eventType: 'ON_CHANGE',
      entityName: this.field?.entityName,
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
      ent_index: this.field?.ent_index,
    };
    /* conditional potentiality check  */
    /* istanbul ignore else */
    /* istanbul ignore else */
    if (this.field.triggerConditionalPotentiality) {
      this.eventsService.setTriggerEvent(data);
    } else {
      this.eventsService.setEvent(data);
    }
    /* istanbul ignore next */
    if (this.field?.configuration?.submitCU) {
      if (this.field?.isOnSelectSubmit) {
        this.chipDataChanged.next();
      } else {
        this.eventsService.onSubmitEvent(data);
      }
    }
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      if (validation?.name == 'equalTo' || validation?.name == 'notequalTo') {
        return this.validateDependentExpression();
      }
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  getDependentAttributesValue(validator: any, formData: any, valueSelect?: any, field?: any) {
    setAttributeValue();
    /* istanbul ignore next*/
    dependentAttributeValue(validator?.dependentExpression, formData, valueSelect, field);
    let val = getAttributeValue();
    return val;
  }
  setErrorsDependentAttribute(validator: any) {
    /* istanbul ignore next*/
    if (validator?.type !== 'INFO') {
      /* istanbul ignore next*/
      this.group?.controls[this.field?.attribute?.name]?.setErrors({ [validator?.name || 'incorrect']: true });
      /* istanbul ignore next*/
      this.group?.setErrors({ invalid: true });
    }
  }
  validateDependentExpression() {
    /* istanbul ignore next*/
    let formData = this.eventsService?.formData;
    let flag = false,
      val = undefined;
    this.field?.validations?.forEach((validator: any) => {
      /* istanbul ignore next*/
      switch (validator?.name) {
        case 'equalTo':
          val = this.getDependentAttributesValue(validator, formData, false, this.field);
          if (
            this.field?.type == 'radiobutton' &&
            this.group?.controls[this.field?.attribute?.name]?.value != (val ? val : validator?.value)
          ) {
            this.setErrorsDependentAttribute(validator);
            return (flag = true);
          }
          break;

        case 'notequalTo':
          val = this.getDependentAttributesValue(validator, formData, false, this.field);
          if (
            this.field?.type == 'radiobutton' &&
            this.group?.controls[this.field?.attribute?.name]?.value == (val ? val : validator?.value)
          ) {
            this.setErrorsDependentAttribute(validator);
            return (flag = true);
          }
          break;
      }
    });
    this.group?.status === 'INVALID'
      ? this.transactionFacadeService.disableSubmitButtonFlag.next(true)
      : this.transactionFacadeService.disableSubmitButtonFlag.next(false);
    return flag;
  }
}

