<div
  class="onboard-landing efunds-bg ezit-bg telcel"
  [ngClass]="{ 'efunds-bg': isEfundzz, 'ezit-bg': isEzit, 'medaxiom-bg': isMedaxiom, telcel: istelcel }"
>
  <div class="container">
    <div class="row">
      <div class="col-md-12 justify-content-center">
        <div class="onboard-signup">
          <div class="row onboard-signup-inner">
            <div class="col-md-5" *ngIf="!isMedaxiom">
              <ng-lottie
                *ngIf="!isEfundzz && !isEzit && !istelcel"
                [options]="lottieOptions"
                class="login-globe"
              ></ng-lottie>

              <div *ngIf="isEfundzz" class="signup-img">
                <img src="../../../assets/img/login/efundz-new.png" alt="" />
              </div>
              <div *ngIf="isEzit" class="signup-img">
                <img src="../../../assets/img/login/ezit-logo.png" alt="" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="signup-form">
                <h3>{{ labels?.Sign_up_to_get_started || 'Sign up to get started' }}!</h3>

                <mat-tab-group class="signup-tab-list">
                  <mat-tab
                    *ngIf="
                      signupOptions == 'SignupViaEmail' || signupOptions == 'SignupViaEmailAndMobile' || onboarding
                    "
                    label="{{ labels?.Signup_using_Email || 'Signup using Email' }}"
                  >
                    <form #signupForm="ngForm" name="form" class="signEmailForm">
                      <div *ngIf="showPublicRoles" class="form-group">
                        <mat-form-field appearance="fill">
                          <mat-label>Select Role</mat-label>
                          <mat-select
                            matNativeControl
                            [(ngModel)]="selectedRole"
                            name="selectedRole"
                            id="selectedRole"
                            [required]="isRoleMandatory && showPublicRoles"
                          >
                            <mat-option *ngFor="let role of rolesList" [value]="role">
                              {{ role?.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group" *ngIf="customLabel && customIdGeneration != 'Auto'">
                        <mat-form-field>
                          <input
                            matInput
                            id="customLabel"
                            name="customLabel"
                            type="text"
                            placeholder="{{ customLabel }}"
                            [(ngModel)]="signupPayload.customID"
                            [required]="customLabel"
                            [readonly]="referralCode"
                          />
                        </mat-form-field>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="firstName"
                              type="text"
                              placeholder="{{ labels?.First_Name || 'First Name' }}"
                              [(ngModel)]="signupPayload.firstName"
                              name="firstName"
                              required
                              (keypress)="validateName($event)"
                            />
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="lastName"
                              type="text"
                              placeholder="Last Name"
                              [(ngModel)]="signupPayload.lastName"
                              name="lastName"
                              (keypress)="validateName($event)"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-12 pl-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="userName"
                              type="text"
                              placeholder="Email"
                              [(ngModel)]="signupPayload.email"
                              name="email"
                              (keyup)="ValidateEmail()"
                              required
                            />
                          </mat-form-field>
                          <div class="err-text-input">
                            <small [ngClass]="{ 'myclass-2': emailValidator == false }" *ngIf="!emailValidator">
                              {{ labels?.Enter_a_Valid_Email || 'Enter a Valid Email' }}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="form-row cstm-form-row cstm-form-row m-0">
                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field>
                            <mat-label>{{ labels?.Select_Country || 'Select Country' }}</mat-label>
                            <mat-select  [(ngModel)]="_countryName"  (selectionChange)="onCountrySelect()" name="SelectedCountryCode"  [disabled]="defaultCountryCodeEditable">
                              <mat-option *ngFor="let country of countryCodes" [value]="country[1].countryName"
                                >{{ country[1].countryName }} : {{ country[1].countryCode }}</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                          <div></div>
                        </div>

                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="mobile"
                              type="text"
                              placeholder="Contact Number"
                              [(ngModel)]="signupPayload.mobileNumber"
                            
                              (keypress)="ValidateMobileNumberEmail($event)"
                              (keydown.space)="$event.preventDefault()"
                              maxlength="13"
                              name="mobileNumber"
                              [required]="faEnabled || mobileNumberMandatoryWhileEmailSignUp"
                            />
                          </mat-form-field>
                          <div class="err-text-input">
                            <small
                              [ngClass]="{ 'myclass-2': mobileNumberValidatorEmail == false }"
                              *ngIf="!mobileNumberValidatorEmail && mobileNumberData"
                            >
                            {{mobileNumberValidatorEmail}}
                            Mobile Number Must be {{ mobileNumberData[1].mobileNumberLength }} digits
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="add-user-custom">
                        <li class="row custom-add-details">
                          <mat-form-field
                            appearance="outline"
                            class="col-md-6"
                            *ngFor="let attribute of attributeList1; let i = index"
                          >
                            <mat-label>{{ attribute }}</mat-label>
                            <input
                              id="CustomAttributes{{ i }}"
                              name="customAttributes{{ i }}"
                              [(ngModel)]="attributeArray[i]"
                              matInput
                              placeholder="Please Enter"
                              [ngModelOptions]="{ standalone: true }"
                              *ngIf="attributeList1.length"
                            />
                          </mat-form-field>
                        </li>
                      </div>
                      <div class="form-row cstm-form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="password"
                              type="password"
                              placeholder="Password"
                              [(ngModel)]="signupPayload.password"
                              required
                              name="password"
                              (ngModelChange)="ValidateConfirmPassword()"
                            />
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0 passwordpolicy">
                          <mat-form-field>
                            <input
                              matInput
                              id="confirmPassword"
                              type="password"
                              placeholder="Confirm Password"
                              [(ngModel)]="confirmPassword"
                              (ngModelChange)="ValidateConfirmPassword()"
                              required
                              name="confirmPassword"
                            />
                            <div class="cstm-tooltip" href="">
                              <div class="pasword-policy">
                                <span class="info-icon">
                                  <mat-icon matSuffix>info</mat-icon>
                                </span>
                                <span class="policyinfo" *ngIf="password_info">
                                  Password Policy(minimum requirements):<br />
                                  Digits: {{ password_info?.digits }}<br />
                                  forceExpiredPasswordChange: {{ password_info?.forceExpiredPasswordChange }}days<br />
                                  Length: {{ password_info?.length }}<br />
                                  notUsername: {{ password_info?.notUsername }}<br />
                                  specialChars: {{ password_info?.specialChars }}<br />
                                  upperCase: {{ password_info?.upperCase }}<br />
                                </span>
                                <span class="policyinfo" *ngIf="!password_info">
                                  Password Policy(No minimum requirements):<br />
                                </span>
                              </div>
                            </div>
                          </mat-form-field>
                          <div class="err-text-input">
                            <small
                              [ngClass]="{ 'myclass-2': passwordCheck == false }"
                              *ngIf="!passwordCheck && signupPayload?.password && confirmPassword"
                            >
                            {{ labels?.Password_not_matched || 'Password not matched' }}
                            </small>
                            <small
                              [ngClass]="{ 'myclass-1': passwordCheck == true }"
                              *ngIf="passwordCheck && confirmPassword.length"
                            >
                            {{ labels?.Password_matched || 'Password_matched' }}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div>
                        <input type="checkbox" name="checkbox" [(ngModel)]="termsAgreed" />
                        <span (click)="openDialog(termsAndConditions)"
                          >{{ labels?.I_agree_to_all_the || 'I agree to all the' }} <a class="terms">{{ labels?.terms_and_conditions || 'terms and conditions' }}</a>
                        </span>
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-signup"
                          id="signup"
                          (click)="signup()"
                          [disabled]="
                          !signupForm.valid ||
                          !emailValidator ||
                          !passwordCheck ||
                          !mobileNumberValidatorEmail ||
                          !termsAgreed
                        "
                      >
                      {{ labels?.Signup || 'Signup' }}
                        </button>
                      </div>
                    </form>
                  </mat-tab>
                  <mat-tab
                    *ngIf="
                      !onboarding &&
                      (signupOptions == 'SignupViaMobile' || signupOptions == 'SignupViaEmailAndMobile') &&
                      !faEnabled
                    "
                    label="{{ labels?.Signup_using_Mobile || 'Signup using Mobile' }}"
                  >
                    <div class="form" action="">
                      <div *ngIf="showPublicRoles" class="form-group">
                        <mat-form-field appearance="fill">
                          <mat-label>{{ labels?.Select_Role || 'Select_Role' }}</mat-label>
                          <mat-select [(ngModel)]="selectedRole" [ngModelOptions]="{ standalone: true }">
                            <mat-option *ngFor="let role of rolesList" [value]="role">
                              {{ role?.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-row cstm-form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field>
                            <mat-label>Select Country</mat-label>
                            <mat-select
                            [(ngModel)]="_countryName"
                              name="SelectedCountryCode"
                              required
                              (selectionChange)="ValidateMobileNumberMobile($event)"
                              [disabled]="defaultCountryCodeEditable"
                            >
                            <mat-option *ngFor="let country of countryCodes" [value]="country[1].countryName"
                            >{{ country[1].countryName }} : {{ country[1].countryCode }}</mat-option
                          >
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="mobileNumber"
                              type="text"
                              placeholder="Mobile Number"
                              [(ngModel)]="signupMobile"
                              [ngModelOptions]="{ standalone: true }"
                              (keypress)="acceptInteger($event)"
                              (change)="activateOtp = false"
                              (keyup)="ValidateMobileNumberMobile()"
                              required
                            />
                          </mat-form-field>
                          <div class="err-text-input" *ngIf="signupMobile > 0">
                            <small
                              [ngClass]="{ 'myclass-2': mobileNumberValidatorMobile == false }"
                              *ngIf="!mobileNumberValidatorMobile"
                            >
                            {{ labels?.Mobile_Number_Must_be_10_digits || 'Mobile Number Must be 10 digits' }}
                            </small>
                          </div>
                      </div>
                      <div>
                        <input type="checkbox" name="checkbox" [(ngModel)]="termsAgreed" />
                        <span (click)="openDialog(termsAndConditions)"
                          >{{ labels?.I_agree_to_all_the || 'I agree to all the' }} <a class="terms">{{ labels?.terms_and_conditions || 'terms and conditions' }}</a>
                        </span>
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-signup"
                          id="sendOtpButton"
                          (click)="sendOtp()"
                          [disabled]="
                            (timeLeft != 0 && activateOtp) ||
                            !mobileNumberValidatorMobile ||
                            !signupMobile ||
                            !termsAgreed
                          "
                        >
                          {{ activateOtp ? 'Resend OTP' : 'Send OTP' }}
                        </button>
                        <p *ngIf="timeLeft != 0 && activateOtp">{{ labels?.Resend_OTP_in || 'Resend OTP in' }} {{ timeLeft }}
                          {{ labels?.seconds || 'seconds' }}</p>
                      </div>
                      <div class="form-group" *ngIf="activateOtp">
                        <mat-form-field>
                          <input
                            matInput
                            id="otp"
                            type="text"
                            placeholder="OTP"
                            [(ngModel)]="signupOtp"
                            (keypress)="acceptInteger($event)"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </mat-form-field>
                      </div>
                      <div class="form-group" *ngIf="activateOtp">
                        <button class="btn btn-signup" id="verifyOtp" [disabled]="!termsAgreed" (click)="verifyOtp()">
                          {{ labels?.Verify_and_Signup || 'Verify and Signup' }}
                        </button>
                      </div>
                    </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <div class="signup-others">
                  <!-- <a (click)="forgotPassword()" href="" class="forgot-pwd">Forgot password?</a> -->
                  <!-- <p>
                    or signup via
                    <span>
                      <img [src]="'../../../assets/img/onboard/google-icon.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                    <span>
                      <img [src]="'../../../assets/img/onboard/twitter-cion.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                    <span>
                      <img [src]="'../../../assets/img/onboard/fb-icon.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                  </p> -->
                  <h4>{{ labels?.Already_have_an_account || 'Already have an account' }}?<a (click)="signin()">{{
                    labels?.Login || 'Login'
                  }}</a></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #termsAndConditions let-data>
  <iframe [src]="data | safeResourceUrl" class="iFrame"></iframe>
  <div class="terms-dialog">
    <button class="agree" (click)="closeDialog('Agreed')">Agree</button>
    <button class="cancl" (click)="closeDialog('cancel')">Cancel</button>
  </div>
</ng-template>
