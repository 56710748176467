import {
  Component,
  OnInit,
  ViewChild,
  Input,
  forwardRef,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS } from '@angular/forms';
import { TranslatorService, UploadFacadeService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DigitalSignatureComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DigitalSignatureComponent),
      multi: true,
    },
  ],
})
export class DigitalSignatureComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild('signaturePadRef') signaturePadRef: ElementRef;
  signaturePad: SignaturePad;
  @Input() _signature: any;
  labels: any;
  private ngUnsubscribe = new Subject();
  @Input() field: any;
  contentUrl: any;
  isInTable: boolean;

  /**
   * Gets signature pad options
   */
  get signaturePadOptions() {
    return this._signature;
  }

  /**
   * Sets signature pad options
   */
  set signaturePadOptions(value) {
    /*istanbul ignore else*/
    if (value && this.IsJsonString(value)) {
      let jsonObj: any = JSON.parse(value);
      /*istanbul ignore else*/
      if (jsonObj && jsonObj.contentUrl) {
        this._signature = jsonObj;
      }
    } else if (value && value?.contentUrl) {
      this._signature = value;
    }
  }

  constructor(private translator: TranslatorService, private fileuploadService: UploadFacadeService) {
    this.detectLanguageChange();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  ngOnInit(): void {}

  /**
   * After view init, initilizes the signature pad and
   * if any signature is coming through as input it will set it to signature pad
   */
  /*istanbul ignore next*/
  ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signaturePadRef.nativeElement);
    this.signaturePad.onBegin = this.drawStart.bind(this);
    this.signaturePad.onEnd = this.drawComplete.bind(this);
    /*istanbul ignore else*/
    if (this._signature) {
      this.signaturePad.off();
      this.getBase64ImageFromUrl(this._signature?.contentUrl)
        .then((result: any) => {
          this.signaturePad.fromDataURL(result);
          this.contentUrl = this._signature.contentUrl;
          this.propagateChange(this._signature);
        })
        .catch((err) => console.error(err));
    }
    if (this.field?.isNegative) {
      this.signaturePad.off();
    }
  }

  /**
   * This function is triggered when the signature is ended
   */
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    const payload = {
      content: this.signaturePad.toDataURL(),
      fileName: 'signature.png',
      folders: ['digitalSignature'],
      mimeType: 'image/png',
    };
    this.fileuploadService
      .uploadBase64File(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        /*istanbul ignore else*/
        if (data) {
          this.contentUrl = data.contentUrl;
          this.propagateChange(data);
        }
      });
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
  }

  /**
   * Clears signature
   */
  clearSignature(): void {
    this.signaturePad.clear();
    this.propagateChange(null);
    this.signaturePad = new SignaturePad(this.signaturePadRef?.nativeElement);
    this.signaturePad.onBegin = this.drawStart.bind(this);
    this.signaturePad.onEnd = this.drawComplete.bind(this);
    // const payload = {
    //   content: this.signaturePad.toDataURL(),
    // };
    // this.fileuploadService
    //   .updateBase64File(this.contentUrl, payload)
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((uploadedFile: any) => {
    //     /*istanbul ignore next*/
    //     if (uploadedFile) {
    //       this.propagateChange(uploadedFile);
    //     }
    //   });
  }

  writeValue(value: any) {
    /*istanbul ignore else*/
    if (value) {
      this.signaturePadOptions = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate() {
    this.isInTable = this.field?.attribute?.isTableConfig;
    /*istanbul ignore next*/
    const inNotValid = !this.field?.isRequired || (this.signaturePad && !this.signaturePad.isEmpty()) ? false : true;
    /*istanbul ignore next*/
    return !inNotValid ? null : { valid: false };
  }

  /**
   * Gets base64 image from url
   * @param imageUrl
   * @returns
   */
  async getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * Determines whether json string or not
   * @param str
   * @returns
   */
  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
