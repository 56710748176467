<div class="publish-nsllibrary metadata-wrap">
  <div class="metadata-btns" *ngIf="lastChild">
    <button class="plus-btn" (click)="onClickActionIcons()" id="addsubcategory">
      <i class="bi bi-plus"></i> {{ labels?.Add_Sub_Category }}
    </button>
  </div>
  <div class="sub-heading ">
    <div class="sub-heading-inner" *ngFor="let item of ontologyData; let i = index">
      <!-- <figure>
        <img class="w-100" [src]="'../../../../../assets/img/icon-metadata-sub.svg' | CDNUrlRewritePipe" alt="" /> -->
      <!-- </figure> -->
      <div class="form-group w-50">
        <label>{{labels?.Choose_Category||'Choose Category'}}</label>
        <mat-form-field appearance="outline">
          <mat-select
            name="item.name"
            [(ngModel)]="item.id"
            panelClass="select-panel choose-catg"
            id="choosecategory{{ i }}"
            [placeholder]="i == 0 ? labels?.Choose_Category : labels?.Choose_SubCategory"
            (selectionChange)="onChangeOntology(item.id, i)"
            disableOptionCentering
          >
            <mat-form-field class="search-select" floatLabel="never">
              <input
                matInput
                type="text"
                id="parentontologyInput"
                class="search-input-cls"
                [(ngModel)]="item.searchText"
                [ngModelOptions]="{ standalone: true }"
                [placeholder]="labels?.Search"
              />
              <button
                mat-button
                *ngIf="item.searchText"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="onClearClick(item)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-option [value]="">{{ labels?.None }}</mat-option>
            <mat-option
              *ngFor="let ontology of item.children | filter: item.searchText"
              [value]="ontology"
              id="{{ 'ontology_' + ontology.name }}"
              >{{ ontology.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="enableMetaData">
      <div class="sub-heading-inner textarea-none">
        <!-- <figure>
          <img class="w-100" [src]="'../../../../../assets/img/icon-metadata-sub.svg' | CDNUrlRewritePipe" alt="" /> -->
        <!-- </figure> -->
        <div class="form-group w-100">
          <label>{{labels?.Description||'Description'}}</label>
          <textarea
            [placeholder]="labels?.Description"
            class="sub-textarea"
            [(ngModel)]="metaInfo.description"
            (change)="onChangeMetaData()"
          ></textarea>
        </div>
      </div>
      <div class="sub-heading-inner">
        <!-- <figure>
          <img class="w-100" [src]="'../../../../../assets/img/icon-metadata-sub.svg' | CDNUrlRewritePipe" alt="" />
        </figure> -->
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{labels?.Author||'Author'}}</label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  [placeholder]="labels?.Author"
                  matInput
                  [ngModel]="metaInfo.author && metaInfo?.author?.name"
                  (ngModelChange)="metaInfo.author && (metaInfo.author.name = $event)"
                  name="author"
                  (change)="onChangeMetaData()"
                  [matTooltip]="metaInfo.author?.name"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{labels?.Keywords||'Keywords'}}</label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  [placeholder]="labels?.Keywords"
                  matInput
                  [(ngModel)]="metaInfo.keywords"
                  name="keywords"
                  id="keywords"
                  (change)="onChangeMetaData()"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="sub-heading-inner">
        <figure>
          <img class="w-100" [src]="'../../../../../assets/img/icon-metadata-sub.svg' | CDNUrlRewritePipe" alt="" />
        </figure>
        <mat-form-field appearance="standard">
          <input
            type="text"
            [placeholder]="labels?.Keywords"
            matInput
            [(ngModel)]="metaInfo.keywords"
            name="keywords"
            id="keywords"
            (change)="onChangeMetaData()"
          />
        </mat-form-field>
      </div> -->
    </ng-container>
  </div>
  <div class="row">
    <!-- <div class="col-auto">
      <button class="plus-btn" (click)="onClickActionIcons()" id="addsubcategory">
        <figure>
          <img [src]="'../../../../../assets/img/icon-violet-plus.svg' | CDNUrlRewritePipe" alt="" />
        </figure>
        {{ labels?.Add_Sub_Category }}
      </button>
    </div> -->
    <div class="col-auto" *ngIf="!enableMetaData">
      <button class="plus-btn" (click)="onClickActionIcons('metadata')" id="addmetadata">
        <figure>
          <img [src]="'../../../../../assets/img/icon-violet-plus.svg' | CDNUrlRewritePipe" alt="" />
        </figure>
        {{ labels?.Add_MetaData }}
      </button>
    </div>
  </div>
</div>
