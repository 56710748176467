import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TranslatorService, EventsFacadeService, FieldConfig } from '@common-services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '../subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'app-year',
  templateUrl: './year.component.html',
  styleUrls: ['./year.component.scss'],
  styles: [
    `
      .datedisplay {
        width: 100%;
        margin-top: 15px;
      }
    `,
  ],
})
export class YearComponent implements OnInit, OnDestroy {
  field: FieldConfig;
  group: FormGroup;
  minDate: Date;
  maxDate: Date;
  myFilter: any;
  dateTime: Date;
  dateVal: any;
  addAttribute: Subject<any> = new Subject();
  removeAttribute: Subject<any> = new Subject();
  dateChanged: Subject<any> = new Subject();
  chipDataChanged: Subject<any> = new Subject();
  requiredFormat: string = 'yyyy';
  private ngUnsubscribe = new Subject();
  formattedValue: any;

  constructor(
    private eventsService: EventsFacadeService,
    private translator: TranslatorService,
    public dialog: MatDialog,
    private dateAdapter: DateAdapter<any>
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    /* istanbul ignore next */
    this.field?.validations?.forEach((obj) => {
      /* istanbul ignore next */
      if (obj?.name != 'required') {
        this.setMinAndMaxDate(obj.name, obj);
      }
    });
    this.formattedValue = moment(this.field?.value).format('YYYY'.toUpperCase());
    /* istanbul ignore next */
    if (this.field?.value && (typeof this.field?.value === 'string' || typeof this.field?.value === 'number')) {
      this.field.value = new Date(String(this.field.value));
      /* istanbul ignore next */
      this.group?.controls?.[this.field?.attribute?.name]?.setValue(this.field?.value);
    }
    this.languageChange();
    if (this.field?.configuration?.submitCU) {
      this.eventsService.hideSubmitButton.next({
        currentCuId: this.eventsService.currentCuId,
        hideSubmitButton: !!this.field?.configuration?.submitCU,
      });
    }
  }
  openDatePicker() {
    if (!document.querySelector('.mat-datepicker-content')?.classList.contains('custom-year-date')) {
      document.querySelector('.mat-datepicker-content')?.classList.add('custom-year-date');
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.languageChange();
    });
  }

  languageChange() {
    this.dateAdapter.setLocale(localStorage.locale);
  }

  setMinAndMaxDate(type: any, obj: any) {
    let currentYear: number = new Date().getFullYear();
    this.minDate = null;
    this.maxDate = null;
    switch (type) {
      case 'pastorpresent':
        this.maxDate = new Date(currentYear, 11, 31);
        /* istanbul ignore next */
        if (obj?.notBeforeYear) {
          this.minDate = new Date(Number(obj.notBeforeYear), 0, 1);
        }
        break;

      case 'present':
        this.maxDate = new Date(currentYear, 11, 31);
        this.minDate = new Date(currentYear, 0, 1);
        break;

      case 'past':
        this.maxDate = new Date(currentYear - 1, 11, 31);
        /* istanbul ignore next */
        if (obj?.notBeforeYear) {
          this.minDate = new Date(Number(obj.notBeforeYear), 0, 1);
        }
        break;

      case 'future':
        this.minDate = new Date(currentYear + 1, 0, 1);
        /* istanbul ignore next */
        if (obj?.notAfterYear) {
          this.maxDate = new Date(Number(obj.notAfterYear), 11, 31);
        }
        break;

      case 'presentorfuture':
        this.minDate = new Date(currentYear, 0, 1);
        /* istanbul ignore next */
        if (obj?.notAfterYear) {
          this.maxDate = new Date(Number(obj.notAfterYear), 11, 31);
        }
        break;
    }
  }

  getErrorMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    return this.group.get(String(this.field.attribute.name)).status == 'VALID'
      ? false
      : this.eventsService.getExactErrorMessage(field, validation, this.group);
  }

  getInfoWarnMessage(field: FieldConfig, validation: any) {
    /* istanbul ignore next */
    if (this.group?.controls[field?.attribute.name]?.value) {
      return (this.group?.controls[field.attribute.name].value).match(validation.requiredPattern) ? false : true;
    }
  }

  /**
   * Determines whether selection change on
   * @param event holds the selected date value
   */
  onDateChange(event: any) {
    if (!this.field.isHidden) {
      /* istanbul ignore next */
      this.field.value = event?.value;
      /* istanbul ignore next */
      this.group.controls[this.field?.attribute?.name].setValue(this.field.value);
      this.dateChanged.next();
      const data = {
        attributeId: this.field.attribute['id'],
        isTableConfig: this.field.attribute['isTableConfig'],
        attrName: this.field.attribute['name'],
        eventType: 'ON_CHANGE',
        entityName: this.field.entityName,
        entityId: this.field?.entityId,
        slotNumber: this.field?.slotNumber,
        isInfo: this.field?.isInfo,
        isMulti: this.field?.isMultiEntity,
        txnRecordId: this.field?.txnRecordId,
        ent_index: this.field?.ent_index,
      };
      /* conditional potentiality check  */
      /* istanbul ignore next */
      if (this.field.triggerConditionalPotentiality) {
        this.eventsService.setTriggerEvent(data);
      } else {
        this.eventsService.setEvent(data);
      }
      /* istanbul ignore next */
      if (this.field?.configuration?.submitCU) {
        if (this.field?.isOnSelectSubmit) {
          this.chipDataChanged.next();
        } else {
          this.eventsService.onSubmitEvent(data);
        }
      }
    }
  }

  /* istanbul ignore next */
  chosenYearForYear(normalizedYear: any, yearPicker: MatDatepicker<any>) {
    this.field.value = normalizedYear;
    /* istanbul ignore next */
    this.group?.controls[this.field?.attribute?.name]?.setValue(normalizedYear);
    /* istanbul ignore next */
    yearPicker?.close();
    /* istanbul ignore next */
    const data = {
      attributeId: this.field.attribute['id'],
      isTableConfig: this.field.attribute['isTableConfig'],
      attrName: this.field.attribute['name'],
      eventType: 'ON_CHANGE',
      entityId: this.field?.entityId,
      slotNumber: this.field?.slotNumber,
      isInfo: this.field?.isInfo,
      isMulti: this.field?.isMultiEntity,
      txnRecordId: this.field?.txnRecordId,
    };
    if (this.field?.configuration?.submitCU) {
      if (this.field?.isOnSelectSubmit) {
        this.chipDataChanged.next();
      } else {
        this.eventsService.onSubmitEvent(data);
      }
    }

    if(yearPicker.startView == 'year'){
      yearPicker.startView = 'multi-year'
    }
  }

  /* istanbul ignore next */
  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
