import { UiControlsComponent } from './ui-controls.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AudioModule } from './controls/audio/audio.module';
import { AutoIdentifierModule } from './controls/auto-identifier/auto-identifier.module';
import { BooleanModule } from './controls/boolean/boolean.module';
import { CaptureImageModule } from './controls/capture-image/capture-image.module';
import { CheckboxModule } from './controls/checkbox/checkbox.module';
import { ChipMultipleModule } from './controls/chip-multiple/chip-multiple.module';
import { ChipSingleModule } from './controls/chip-single/chip-single.module';
import { ClickableModule } from './controls/clickable/clickable.module';
import { DateRangeModule } from './controls/date-range/daterange.module';
import { DateModule } from './controls/date/date.module';
import { DateOnlyModule } from './controls/dateonly/dateonly.module';
import { DateTimeModule } from './controls/datetime/datetime.module';
import { DecimalModule } from './controls/decimal/decimal.module';
import { DropDownModule } from './controls/dropdown/dropdown.module';
import { DurationModule } from './controls/duration/duration.module';
import { EditorModule } from './controls/editor/editor.module';
import { HtmlModule } from './controls/html/html.module';
import { HyperLinkModule } from './controls/hyperlink/hyperlink.module';
import { InputModule } from './controls/input/input.module';
import { IntegerModule } from './controls/integer/integer.module';
import { LabelWithImageModule } from './controls/label-with-image/label-with-image.module';
import { LabelModule } from './controls/label/label.module';
import { ListModule } from './controls/list/list.module';
import { MultiselectedDropDownModule } from './controls/multiselectdropdown/multiselecteddropdown.module';
import { PreviewdocumentModule } from './controls/previewdocument/previewdocument.module';
import { ProgressbarModule } from './controls/progressbar/progressbar.module';
import { QrScannerControlModule } from './controls/qr-scanner-control/qr-scanner-control.module';
import { RadioGroupModule } from './controls/radio-group/radio-group.module';
import { RatingModule } from './controls/rating/rating.module';
import { RecordAudioModule } from './controls/record-audio/record-audio.module';
import { RecordVideoModule } from './controls/record-video/record-video.module';
import { RedacModule } from './controls/redac/redac.module';
import { RedirectionModule } from './controls/redirection/redirection.module';
import { SignatureModule } from './controls/signature/signature.module';
import { SliderControlModule } from './controls/slider/slider.module';
import { TextareaModule } from './controls/textarea/textarea.module';
import { TimeOnlyModule } from './controls/timeonly/timeonly.module';
import { TimeModule } from './controls/time/time.module';
import { TimerModule } from './controls/timer/timer.module';
import { TypeaheadModule } from './controls/typeahead/typeahead.module';
import { VideoModule } from './controls/video/video.module';
import { WebexWidgetModule } from './controls/webexWidget/webex-widget.module';
import { YearModule } from './controls/year/year.module';
import { VideoStreamingModule } from './controls/video-streaming/video-streaming.module';
import { DicomIntegrationModule } from './controls/dicom-integration/dicom-integration.module';
import { NslDicomOperationModule } from './controls/nsl-dicom-operation/nsl-dicom-operation.module';
import { CommonServicesModule } from '@common-services';
import { TableComponent } from './controls/table/table.component';
import { CommonModule } from '@angular/common';
import { ControlsMaterialModule } from './controls/material.module';
import { DialogComponent } from './controls/image/dialog/dialog.component';
import { SchedulerComponent } from './controls/scheduler/scheduler.component';
import { FileComponent } from './controls/file/file.component';
import { ChartsComponent } from './controls/charts/charts.component';
import { FileWithIRDRComponent } from './controls/filewith-irdr/filewith-irdr.component';
import { FolderuiComponent } from './controls/folderui/folderui.component';
import { GoogleMapsComponent } from './controls/google-maps/google-maps.component';
import { ImageComponent } from './controls/image/image.component';
import { LocationComponent } from './controls/location/location.component';
import { MMIMapsComponent } from './controls/mmi-maps/mmi-maps.component';
import { ScormPlayerComponent } from './controls/scorm-player/scorm-player';
import { SubtransactionalCuDialogComponent } from './controls/subtransactional-cu-dialog/subtransactional-cu-dialog.component';
import { CurrentTimeModule } from './controls/currentTime/currentTime.module';
import { FileUploadPreviewComponent } from './controls/file-upload-preview/file-upload-preview.component';
import { FileUploadComponent } from './controls/file-upload/file-upload.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {ImageModule} from 'primeng/image';
import { ImageZoomInoutComponent } from './controls/image-zoom-inout/image-zoom-inout.component';
import { TreeModule } from 'primeng/tree';
import { SidebarModule } from 'primeng/sidebar';
import { FolderUploadComponent } from './controls/folder-upload/folder-upload.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DropdownModule } from 'primeng/dropdown';
// import { DragNDropDirective } from './controls/file-upload/drag-drop.directive';



@NgModule({
  declarations: [
    UiControlsComponent,
    TableComponent,
    DialogComponent,
    SchedulerComponent,
    FileComponent,
    ChartsComponent,
    FileWithIRDRComponent,
    FolderuiComponent,
    ScormPlayerComponent,
    ImageComponent,
    LocationComponent,
    GoogleMapsComponent,
    MMIMapsComponent,
    SubtransactionalCuDialogComponent,
    FileUploadPreviewComponent,
    FileUploadComponent,
    ImageZoomInoutComponent,
    FolderUploadComponent,
  ],
  imports: [
    CommonServicesModule,
    CommonModule,
    ControlsMaterialModule,
    InputModule,
    TypeaheadModule,
    DecimalModule,
    DateModule,
    YearModule,
    DateTimeModule,
    TimeModule,
    BooleanModule,
    DropDownModule,
    RadioGroupModule,
    CheckboxModule,
    TextareaModule,
    MultiselectedDropDownModule,
    PreviewdocumentModule,
    ProgressbarModule,
    SignatureModule,
    VideoModule,
    AudioModule,
    ListModule,
    LabelModule,
    EditorModule,
    DateRangeModule,
    HyperLinkModule,
    HtmlModule,
    WebexWidgetModule,
    DecimalModule,
    AutoIdentifierModule,
    InputModule,
    LabelWithImageModule,
    DurationModule,
    RedirectionModule,
    ClickableModule,
    QrScannerControlModule,
    ChipMultipleModule,
    ChipSingleModule,
    RecordVideoModule,
    RecordAudioModule,
    CaptureImageModule,
    RedacModule,
    RatingModule,
    SliderControlModule,
    IntegerModule,
    DateOnlyModule,
    TimerModule,
    TimeOnlyModule,
    VideoStreamingModule,
    DicomIntegrationModule,
    NslDicomOperationModule,
    CurrentTimeModule,
    NgxExtendedPdfViewerModule,
    ImageModule,
    TreeModule,
    SidebarModule,
    MatProgressSpinnerModule,
  DropdownModule 
    // DragNDropDirective
  ],
  exports: [
    UiControlsComponent,
    InputModule,
    TypeaheadModule,
    DecimalModule,
    DateModule,
    YearModule,
    DateTimeModule,
    TimeModule,
    BooleanModule,
    DropDownModule,
    RadioGroupModule,
    CheckboxModule,
    TextareaModule,
    MultiselectedDropDownModule,
    PreviewdocumentModule,
    ProgressbarModule,
    SignatureModule,
    VideoModule,
    AudioModule,
    ListModule,
    LabelModule,
    EditorModule,
    DateRangeModule,
    HyperLinkModule,
    HtmlModule,
    WebexWidgetModule,
    DecimalModule,
    AutoIdentifierModule,
    InputModule,
    LabelWithImageModule,
    DurationModule,
    RedirectionModule,
    ClickableModule,
    QrScannerControlModule,
    ChipMultipleModule,
    ChipSingleModule,
    RecordVideoModule,
    RecordAudioModule,
    CaptureImageModule,
    RedacModule,
    RatingModule,
    SliderControlModule,
    IntegerModule,
    DateOnlyModule,
    TimerModule,
    TimeOnlyModule,
    VideoStreamingModule,
    DicomIntegrationModule,
    NslDicomOperationModule,
    TableComponent,
    DialogComponent,
    SchedulerComponent,
    FileComponent,
    ChartsComponent,
    FileWithIRDRComponent,
    FolderuiComponent,
    ScormPlayerComponent,
    ImageComponent,
    LocationComponent,
    GoogleMapsComponent,
    MMIMapsComponent,
    SubtransactionalCuDialogComponent,
    CurrentTimeModule,
    FileUploadPreviewComponent,
    FileUploadComponent,
    NgxExtendedPdfViewerModule,
    ImageModule,
    FolderUploadComponent,
    // DragNDropDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UiControlsModule { }
