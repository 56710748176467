<section class="tab-header">
  <div class="row no-gutters align-items-center">
    <div class="col node-search-wrap">
      <!-- <div class="node-select">
        <mat-form-field appearance="standard">
          <select matNativeControl [(ngModel)]="filterType" (change)="loadData($event)">
            <option value="all">All</option>
            <option value="published">Published</option>
            <option value="drafts">Drafts</option>
          </select>
        </mat-form-field>
      </div> -->
      <div class="node-search-right">
        <!-- <div class="node-create">
          <button class="create-new-btn" id="createNew_Book"></button>
        </div> -->
        <div class="d-flex">
          <div class="node-search-block right-item">
            <!-- <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
              <mat-form-field appearance="outline">
                <input matInput placeholder="Search" [(ngModel)]="searchText" (keyup)="getAllBooks()" />
              </mat-form-field>
            </div> -->
            <button
              (click)="displayNodesearch = !displayNodesearch; searchText = ''; getAllBooks()"
              class="search-btn-icon search-btn-large"
              id="search_Book"
            ></button>
          </div>
        </div>
      </div>

      <div class="node-search-field" [ngClass]="{ showNodesearch: displayNodesearch }">
        <mat-form-field appearance="legacy">
          <button mat-icon-button matSuffix (click)="displayNodesearch = !displayNodesearch; searchText = ''">
            <mat-icon matSuffix>close</mat-icon>
          </button>
          <input matInput [(ngModel)]="searchText" placeholder="Search" (keyup)="getAllBooks()" />
        </mat-form-field>
      </div>
    </div>
  </div>
</section>
<section class="tab-body">
  <ul class="common-list">
    <li *ngFor="let book of bookList?.content; let bookId = index">
      <!-- <div class="d-flex item align-items-center">
        <div class="details">
          <p class="title" matTooltip="{{ book.name }}">
            {{ book.name }}
          </p>
        </div>
        <a class="add-icon" id="addBook_{{ book?.name?.replaceAll(' ', '_') }}" (click)="bookAddFromLibrary(book)"
          ><span></span
        ></a>
        <a class="copy-icon" id="copyBook_{{ book?.name?.replaceAll(' ', '_') }}"><span></span></a>
         <a class="more-icon" id="moreBookDetails_{{ book?.name?.replaceAll(' ', '_') }}" (click)="fetchBookGsis(book,bookId)"><span></span></a>
      </div> -->
      <mat-accordion>
        <mat-expansion-panel hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <div class="d-flex item align-items-center" [ngClass]="{ active: bookId == activeBookIndex }">
              <div class="details">
                <p class="title" pTooltip="{{ book?.name }}" tooltipPosition="top">
                  {{ book?.name }}
                </p>
              </div>
              <a
                class="add-icon"
                id="addBook_{{ book?.name?.replaceAll(' ', '_') }}"
                (click)="bookAddFromLibrary(book)"
                ><span></span
              ></a>
              <a class="copy-icon" id="copySolution_{{ book?.name?.replaceAll(' ', '_') }}"><span></span></a>
              <a
                class="more-icon"
                id="moreSolutionDetails_{{ book?.name?.replaceAll(' ', '_') }}"
                (click)="fetchBookGsis(book, bookId)"
                ><span></span
              ></a>
            </div>
          </mat-expansion-panel-header>
          <div class="item-body" *ngIf="book?.gsiList">
            <mat-expansion-panel
              hideToggle
              class="mat-elevation-z0"
              *ngFor="let gsi of book?.gsiList; let gsiInd = index"
            >
              <mat-expansion-panel-header>
                <div
                  class="d-flex item level-two align-items-center"
                  [ngClass]="{ active: gsiInd == book?.activeGsiIndex }"
                >
                  <div class="details">
                    <p class="sub">{{ gsi.status }} [{{ gsi.version }}]</p>
                    <p class="title" pTooltip="{{ gsi.name }}" tooltipPosition="top">
                      {{ gsi.name }}
                    </p>
                  </div>
                  <a
                    class="add-icon"
                    id="addSolution_{{ gsi?.name?.replaceAll(' ', '_') }}"
                    (click)="importGsiData(gsi, clonedTemplate); $event.stopPropagation()"
                    ><span></span
                  ></a>

                  <a
                    class="more-icon"
                    id="moreSolutionDetails_{{ gsi?.name?.replaceAll(' ', '_') }}"
                    (click)="expandGsi(gsi, gsiInd, book)"
                    ><span></span
                  ></a>
                </div>
              </mat-expansion-panel-header>
              <div *ngIf="book?.gsiData">
                <mat-expansion-panel
                  hideToggle
                  class="mat-elevation-z0"
                  *ngFor="let cu of book?.gsiData?.solutionLogic"
                >
                  <mat-expansion-panel-header>
                    <div class="d-flex item level-three align-items-center">
                      <div class="details">
                        <!-- <p class="sub"></p> -->
                        <p class="title">{{ cu.displayName }}</p>
                      </div>
                      <a class="more-icon" id="moreDisplayCuDetails_{{ cu?.displayName?.replaceAll(' ', '_') }}"
                        ><span></span
                      ></a>
                    </div>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let cus of cu?.solutionLogic">
                    <mat-expansion-panel-header>
                      <div class="d-flex item level-four align-items-center">
                        <div class="details">
                          <!-- <p class="sub"></p> -->
                          <p class="title">{{ cus.displayName }}</p>
                        </div>
                        <a class="more-icon" id="moreCuDetails_{{ cus?.displayName?.replaceAll(' ', '_') }}"
                          ><span></span
                        ></a>
                      </div>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of cus.layers">
                      <mat-expansion-panel-header>
                        <div class="d-flex item level-four align-items-center">
                          <div class="details">
                            <!-- <p class="sub"></p> -->
                            <p class="title">{{ layer.type }}</p>
                          </div>
                          <a class="more-icon" id="moreLayersDetails_{{ layer?.type }}"><span></span></a>
                        </div>
                      </mat-expansion-panel-header>
                      <mat-expansion-panel
                        hideToggle
                        class="mat-elevation-z0"
                        *ngFor="let ent of layer.participatingItems"
                      >
                        <mat-expansion-panel-header>
                          <div class="d-flex item level-five align-items-center">
                            <div class="details">
                              <!-- <p class="sub"></p> -->
                              <p class="title">{{ ent.item.DATA.name }}</p>
                            </div>
                            <a
                              class="more-icon"
                              id="moreEntityDetails_{{ ent?.item?.DATA?.name?.replaceAll(' ', '_') }}"
                              ><span></span
                            ></a>
                          </div>
                        </mat-expansion-panel-header>
                        <div class="mat-elevation-z0" *ngFor="let attr of ent.item?.DATA?.nslAttributes">
                          <div class="d-flex item level-five align-items-center">
                            <div class="d-flex" *ngIf="!attr.generalEntity">
                              {{ attr.name }}<a class="create-new-btn"></a>
                            </div>
                            <div class="d-flex" *ngIf="attr.generalEntity">
                              <app-node-expand-entity [entity]="attr.generalEntity"></app-node-expand-entity>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-expansion-panel>
                  </mat-expansion-panel>
                  <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngFor="let layer of cu.layers">
                    <mat-expansion-panel-header>
                      <div class="d-flex item level-four align-items-center">
                        <div class="details">
                          <!-- <p class="sub"></p> -->
                          <p class="title">{{ layer.type }}</p>
                        </div>
                        <a class="more-icon"><span></span></a>
                      </div>
                    </mat-expansion-panel-header>
                    <mat-expansion-panel
                      hideToggle
                      class="mat-elevation-z0"
                      *ngFor="let ent of layer.participatingItems"
                    >
                      <mat-expansion-panel-header>
                        <div class="d-flex item level-five align-items-center">
                          <div class="details">
                            <!-- <p class="sub"></p> -->
                            <p class="title">{{ ent.item.DATA.name }}</p>
                          </div>
                          <a class="more-icon"><span></span></a>
                        </div>
                      </mat-expansion-panel-header>
                      <div class="mat-elevation-z0" *ngFor="let attr of ent.item?.DATA?.nslAttributes">
                        <div class="d-flex item level-five align-items-center">
                          <div class="details">
                            <!-- <p class="sub"></p> -->
                            <p class="title">{{ attr.name }}</p>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-expansion-panel>
                </mat-expansion-panel>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</section>
<div class="tab-footer">
  <app-paginator
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [totalRecords]="bookList.total"
    (onPageChange)="onPageChange($event)"
  >
  </app-paginator>
</div>

<ng-template #clonedTemplate>
  <mat-dialog-content class="alert-dialog py-3">
    <div class="users-table" *ngIf="!alertModal">
      <h5>Would you like to discard existing Solution? Proceed</h5>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-end">
    <span *ngIf="!alertModal">
      <button mat-button (click)="cloningGsi()" color="primary">Yes</button>
      <button mat-button (click)="this.dialog.closeAll()" color="accent">No</button>
    </span>
  </mat-dialog-actions>
</ng-template>