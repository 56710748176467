import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { BPMNViewModule } from '../bpmn-view/bpmn-view.module';
import { MaterialModule } from '../materail/material.module';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';

import { ExtractionComponentDialogModule } from './extraction-component-dialog.module';
import { ExtractionComponent } from './extraction.component';
@NgModule({
  declarations: [ExtractionComponent],
  imports: [
    BPMNViewModule,
    ApplicationPipesModule,
    MaterialModule,
    ExtractionComponentDialogModule,
    MatStepperModule,
    CommonModule,
  ],
  exports: [ExtractionComponent],
})
export class ExtractionModule {}
