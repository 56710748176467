import { NgModule } from '@angular/core';

import { currentTimeComponent } from './currentTime.component';
import { ControlsMaterialModule } from '../material.module';

@NgModule({
  declarations: [currentTimeComponent],
  imports: [ControlsMaterialModule],
  exports: [currentTimeComponent],
})
export class CurrentTimeModule {
  static entry = {
    currenttime: currentTimeComponent,
    currenttimeonly: currentTimeComponent,
  };
}
