import { Component, Input, OnInit } from '@angular/core';
import { TranslatorService } from '@common-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
@Component({
  selector: 'app-clone-modal',
  templateUrl: './clone-modal.component.html',
  styleUrls: ['./clone-modal.component.scss'],
})
export class CloneModalComponent implements OnInit {
  showModal: boolean = false;
  @Input() data: any;
  @Input() isPublished: any;
  @Input() title: any;
  @Input() type: any;
  clonedName: any;
  labels: any;
  private ngUnsubscribe = new Subject();
  constructor(
    private entityFacadeService: NodeEntityFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    this.show();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  show() {
    this.showModal = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }
  saveClonedCu() {
    const data = { name: this.clonedName };
    if (this.type == 'entity') {
      this.entityFacadeService.getCloneDetails(this.data?.dsdId, this.isPublished, this.type, data);
    } else {
      this.changeunitFacade.getCloneDetails(this.data?.dsdId, this.isPublished, this.type, data);
    }
    this.showModal = false;
  }
}
