<!-- <node-alternativecu
  [gsiData]="gsiList[0]"
  [_gsiSolutionLogic]="changeUnitListAny"
  [selectedLabel]="selectedLabel"
  (addedCu)="addedCu($event)"
>
</node-alternativecu>

<node-changeunit-form
  [gsiData]="gsiDetails"
  [cuIndex]="_cuIndex"
  [_solutionLogic]="solutionData"
  (onFormView)="handleFormView($event)"
  (checkForNextCUEmit)="stepConfirmChange($event)"
>
</node-changeunit-form> -->

<div class="review-approval-screen">
  <header>
    <!-- <p><span>E-Commerce Solution</span> by Ravi Kumar</p> -->
    <div>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font"
          >close</mat-icon
        >
      </button>
    </div>
  </header>
  <div class="solution-review-details">
    <!-- <div>
      <p class="title-book">
        Book
      </p>
      <p class="title-book-name">
        E-Commerce
      </p>
    </div> -->
    <div>
      <p class="title-wish">
        Wish
      </p>
      <p class="title-wish-name">
        {{ this.displayName }}
      </p>
    </div>
    <!-- <div>
      <p class="title-cu">
        CU
      </p>
      <p class="title-cu-name">
        Customer registers oneself with name, mobile number, email ID and date of birth. Customer selects product with
        its type, color, size, quantity. Customer adds products to cart with product details such as type, color, size
        and quantity.
      </p>
    </div> -->
  </div>
  <div class="review-comment-section">
    <label for="input-comments">Comments</label>
    <input
      type="text"
      name=""
      id="input-comments"
      (change)="sumbitClick = true"
      [(ngModel)]="textReviewNotes"
      placeholder="Write your Comment"
    />
    <div class="d-flex nh-column-gap-16">
      <input
        *ngIf="sumbitClick"
        type="submit"
        value="Submit"
        id="submit-comment-button"
        (click)="sumbitClick = !sumbitClick"
      />
      <input type="submit" value="Review Status" id="review-status" (click)="showReviewStatus()" />
    </div>
  </div>
  <div class="approval-confirmation">
    <button mat-dialog-close class="approve-button" id="arc-approve" (click)="onApproveReject('approve')">
      {{ labels?.Approve }}
    </button>
    <button mat-dialog-close class="reject-button" id="arc-rejected" (click)="onApproveReject('reject')">
      {{ labels?.Reject }}
    </button>
  </div>
</div>
