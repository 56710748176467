<div mat-dialog-title class="nh-display-flex">
  <button class="nh-unsetAll cursor-pointer close-btn-modal ml-auto" (click)="onNoClick()">
    <em class="nh-icon nh-icon-x-lg nh-fw-700 nh-fs-20"></em>
    <img [src]="'../../../../../../../assets/img/closecircle.svg' | CDNUrlRewritePipe" alt="" style="display: none;" />
  </button>
</div>
<mat-dialog-content (contextmenu)="onRightClick($event)">
  <figure *ngFor="let img of data; let i = index">
    <img
      class="img-fluid mx-auto nh-display-block"
      draggable="false"
      *ngIf="i == currentIndex"
      [src]="img"
      alt=""
    />
  </figure>
</mat-dialog-content>
<mat-dialog-actions
  align="center"
  class="nh-imageModal-footer nh-column-gap-20"
  *ngIf="data?.length > 1"
  (contextmenu)="onRightClick($event)"
>
  <button class="nh-unsetAll cursor-pointer" (click)="gotoPrevious()">
    <img
      draggable="false"
      [src]="'../../../../../../../assets/images/navigate_before.svg' | CDNUrlRewritePipe"
      alt=""
    />
  </button>
  <button class="nh-unsetAll cursor-pointer" (click)="gotoNext()">
    <img draggable="false" [src]="'../../../../../../../assets/images/navigate_next.svg' | CDNUrlRewritePipe" alt="" />
  </button>
</mat-dialog-actions>
