import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'progressBarClass' })
export class ProgressBarClass implements PipeTransform {
  /**
   * Pipe returns the class in order to identify active, touched, untouched progressbar classes
   *
   * @param items list of elements to search in
   * @param displayName search string
   * @param currentIndex search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], displayName: string, currentIndex: number): string {
    if (!!displayName && items) {
      const activeIndex = items.findIndex((item) => item.DATA?.displayName === displayName);
      const _class = ['nav-link-progressbarlink'];
      _class.push(
        currentIndex < activeIndex
          ? 'progressbar-touched'
          : currentIndex > activeIndex
          ? 'progressbar-untouched'
          : 'progressbar-active'
      );
      if (currentIndex === 0) {
        _class.push('progressbar-first');
      } else if (items.length === currentIndex + 1) {
        _class.push('progressbar-last');
      }
      return _class.join(' ');
    }
    return '';
  }
}
