import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-change-detection',
  templateUrl: './change-detection.component.html',
  styleUrls: ['./change-detection.component.scss'],
})
export class ChangeDetectionComponent implements OnInit, OnChanges {
  @Input() text: any;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    var doc = new DOMParser().parseFromString(this.text, 'text/html');
    this.text = this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
  }
}
