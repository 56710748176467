import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private http: HttpClient) {
    /* istanbul ignore next */
    this.getProductsSmall().subscribe((data) => {});
  }

  getProductsSmall() {
    return this.http.get('../../../../../assets/json/layouts.json');
  }

  getAppViewProductsSmall() {
    return this.http.get('../../../../../assets/json/app-view-layouts.json');
  }
}
