<ng-container>
  <div class="tbl_conf tbl_conf_template1" *ngIf="
      (cofiguration?.allowColumnSearch && cofiguration?.search?.searchPlacement == 'top') ||
      (cofiguration?.allowColumnsort && cofiguration?.sort?.sortPlacement == 'top') ||
      isFacetSearchCu
    ">
    <div class="innerContent" [ngClass]="{ 'without_search only-swiper without_sort': false }">
      <div *ngIf="
          isFacetSearchCu &&
          tableConfiguration?.advancedFeatures?.allowSearchFields &&
          tableConfiguration?.advancedFeatures?.searchFields == 'top'
        " class="d-flex justify-content-end nh-mb-10">
        <span class="clear cursor-pointer" (click)="clearAllFeatures()">CLEAR ALL</span>
      </div>
      <div class="filter-header">
        <div class="filter-header-left filter-header-top">
          <!-- *ngIf="entitySearchFields?.length > 0" -->
          <!-- <ng-container *ngIf="false" [ngTemplateOutlet]="searchFieldsTemplate"></ng-container>
          <ng-container [ngTemplateOutlet]="searchFieldsTemplateWrap"></ng-container> -->
          <ng-container *ngIf="
              isFacetSearchCu &&
              tableConfiguration?.advancedFeatures?.allowSearchFields &&
              tableConfiguration?.advancedFeatures?.searchFields == 'top'
            ">
            <ng-container *ngIf="!this.cofiguration?.facet?.configs?.[1]?.checked"
              [ngTemplateOutlet]="searchFieldsTemplate"></ng-container>
            <ng-container *ngIf="this.cofiguration?.facet?.configs?.[1]?.checked"
              [ngTemplateOutlet]="searchFieldsTemplateWrap"></ng-container>
          </ng-container>
          <ng-template #searchFieldsTemplate let-data="data">
            <ng-container *ngFor="let attribute of facetedData?.nslAttributes; let ind = index">
              <ng-container [ngTemplateOutlet]="stringType"
                [ngTemplateOutletContext]="{ data: { attr: attribute, ind: ind } }"></ng-container>
            </ng-container>
          </ng-template>
          <ng-template #searchFieldsTemplateWrap let-data="data">
            <div class="templates-swiper-side-menu filter-swiper">
              <swiper [config]="sideMenu_swiper_config">
                <ng-template swiperSlide *ngFor="let attribute of facetedData?.nslAttributes; let ind = index">
                  <ng-container [ngTemplateOutlet]="stringType"
                    [ngTemplateOutletContext]="{ data: { attr: attribute, index: ind } }"></ng-container>
                </ng-template>
              </swiper>
              <!-- <div class="swiper-pager"></div> -->
              <button class="swiper-btn swiper-prev"><em class="nh-icon nh-icon-chevron-left"></em></button>
              <button class="swiper-btn swiper-next"><em class="nh-icon nh-icon-chevron-right"></em></button>
            </div>
          </ng-template>
          <ng-template #stringType let-data="data">
            <div class="tbl-accordion cursor-pointer"
              *ngIf="!cofiguration?.facet?.facetFields?.includes(data.attr.displayName ? data.attr.displayName : data.attr.name)">
              <span class="filter-name text-truncate" [matMenuTriggerFor]="attributeMenu" [ngClass]="{ active: false }"
                [ngClass]="{'nh-pr-60' : this.cofiguration?.facet?.configs?.[1]?.checked && data.attr.values.length > 0  ? data.attr.values.length : ''}">
                {{ data.attr.displayName }}
                <span
                  *ngIf="this.cofiguration?.facet?.configs?.[1]?.checked && data.attr.values.length > 0  ? data.attr.values.length : ''"
                  class="count">{{ this.cofiguration?.facet?.configs?.[1]?.checked && data.attr.values.length > 0 ?
                  data.attr.values.length : '' }}</span>
              </span>
              <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
                <div class="search-filter">
                  <input matInput type="text" placeholder="Search" />
                  <em class="nh-icon nh-icon-search-line"></em>
                  <em class="nh-icon nh-icon-x-lg d-none"></em>
                </div>
                <div class="checkboxes">
                  <div *ngFor="let option of data.attr.options">
                    <mat-checkbox class="menu-checkbox-option" [value]="option.actualValue"
                      [checked]="data.attr.values.includes(option.actualValue)"
                      (change)="onFacetFilterChange($event, data.index)" (click)="$event.stopPropagation()">
                      <div class="attr-name">{{ option?.displayValue }}</div>
                    </mat-checkbox>
                  </div>
                </div>
              </mat-menu>
            </div>
          </ng-template>

          <!-- <div class="tbl-accordion cursor-pointer" (click)="savedFilter.toggle($event)">
            <p-overlayPanel styleClass="filterOverlay" #savedFilter>
              <div class="searchbar">
                <input type="text" placeholder="Search" />
                <em class="nh-icon nh-icon-search-line"></em>
                <em class="nh-icon nh-icon-x-lg d-none"></em>
              </div>
              <div class="saved-filter-list checkboxes">
                <button class="clear-btn cursor-pointer">
                  clear
                </button>
                <span class="saved-filter-list-item">
                  <p-option value="New York" inputId="ny" [ngClass]="{ 'active-filter': 'false' }">filter</p-option>
                  <mat-icon class="cursor-pointer">close</mat-icon>
                </span>
              </div>
            </p-overlayPanel>
            <span class="filter-name text-truncate saved" [ngClass]="{ active: false }">Saved Filters</span>
          </div> -->
          <!-- <button class="selected-filters save-all cursor-pointer">
            <em class="nh-icon nh-icon-save1"></em>
          </button> -->
          <!-- <div class="tbl-accordion cursor-pointer" (click)="more.toggle($event)">
            <p-overlayPanel styleClass="filterOverlay" #more>
              <div class="searchbar">
                <input type="text" placeholder="Search" />
                <em class="nh-icon nh-icon-search-line"></em>
                <em class="nh-icon nh-icon-x-lg d-none"></em>
              </div>
              <ul class="checkboxes">
                <ng-container>
                  <li class="checckbox">
                    <mat-checkbox disableRipple class="menu-checkbox-option">
                      <div class="attr-name">Display Name</div>
                    </mat-checkbox>
                  </li>
                </ng-container>
              </ul>
            </p-overlayPanel>
            <span class="filter-name text-truncate saved-menu" [ngClass]="{ active: false }">More</span>

            <mat-menu #attributeMenu="matMenu" class="menu-Overlay">
              <ng-container>
                <div class="searchbar">
                  <input type="text" placeholder="Search" />
                  <em class="nh-icon nh-icon-search-line"></em>
                  <em class="nh-icon nh-icon-x-lg d-none"></em>
                </div>
                <div class="checkboxes">
                  <mat-checkbox disableRipple class="menu-checkbox-option">
                    <div class="attr-name">Display name</div>
                  </mat-checkbox>
                </div>
              </ng-container>
            </mat-menu>
          </div> -->
        </div>
        <div class="filter-header-right filter-header-top" *ngIf="
            (cofiguration?.allowColumnSearch && cofiguration?.search?.searchPlacement == 'top') ||
            (cofiguration?.allowColumnsort && cofiguration?.sort?.sortPlacement == 'top')
          ">
          <div class="tbl-accordion cursor-pointer" (click)="sort.toggle($event)"
            *ngIf="cofiguration?.allowColumnsort && cofiguration?.sort?.sortPlacement == 'top'">
            <p-overlayPanel styleClass="filterOverlay" #sort>
              <div class="attr-list1">
                <mat-radio-group class="order" aria-label="Select sort option" [(ngModel)]="sortOption"
                  (ngModelChange)="applyFilterAndSort()">
                  <mat-radio-button [value]="1">Ascending</mat-radio-button>
                  <mat-radio-button [value]="-1">Descending</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group class="order-option" aria-label="Select sort option" [(ngModel)]="sortOptionAttr"
                  (ngModelChange)="applyFilterAndSort()">
                  <mat-radio-button [value]="attr?.field" [ngClass]="{
                      'd-none': cofiguration?.sort?.sortFields?.includes(
                        attr?.displayValue ? attr?.displayValue : attr?.header
                      )
                    }" *ngFor="let attr of cols; let i = index">{{ attr?.displayValue ? attr?.displayValue :
                    attr?.header }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </p-overlayPanel>
            <span class="filter-name text-truncate sort" [ngClass]="{ active: false }">Sort By</span>
          </div>
          <div class="searchbar"
            *ngIf="cofiguration.allowColumnSearch && cofiguration?.search?.searchPlacement == 'top'">
            <input type="text" placeholder="name" />
            <em class="nh-icon nh-icon-search-line"></em>
            <!-- *ngIf="attributeValue?.length == 0" -->
            <em class="nh-icon nh-icon-x-lg" *ngIf="false"></em>
            <!-- *ngIf="attributeValue?.length != 0" -->
          </div>
        </div>
      </div>
      <div class="selected-filters" *ngIf="isFacetSearchCu && this.cofiguration?.facet?.configs?.[0]?.checked">
        <ng-container *ngFor="let attr of this.facetedData?.nslAttributes; let ind = index">
          <span *ngFor="let val of attr.values" class="filter-name text-truncate cancel"
            [ngClass]="{ active: false }">{{ val }}
            <button (click)="onFacetFilterChange($event, ind, val)">
              <em class="nh-icon nh-icon-x-lg"></em></button></span>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<div class="tbl-com ui-tbl-section" [ngClass]="{
    'tbl-comp-section': isFullScreen,
    'split-table': splitScreenclicked,
    'custom-table-medium': cofiguration.tableComponentScale == 'medium',
    'custom-table-small': cofiguration.tableComponentScale == 'small',
    'defi_solutions': tenantName?.includes('defisolutions')
  }">
  <p-table #vsTable class="ptable-wrap transaction-table" [ngClass]="{
      'table-outline': cofiguration?.viewAsGridTable || tableConfiguration?.gridLines?.outLine?.selected == true,
      'custom-p-table-grid': cofiguration?.gridTable,
      'custom-p-table': !cofiguration?.gridTable,
      'filtersonhover': tableConfiguration?.advancedFeatures?.filtersConfig?.hover,
      'filtersonnextrow': tableConfiguration?.advancedFeatures?.filtersConfig?.nextRow,
      'single-page' : singlePageTable,
      'pagination-right': pagination?.placementOfPagination == 'right',
      'pagination-left': pagination?.placementOfPagination == 'left',
      'header-wrap': true,
      'body-wrap': tableConfiguration?.advancedFeatures?.attributeWrap
    }" [tableStyle]="{
      'min-width': !singlePageTable ? tableWidth : '',
      'border-color': tableConfiguration?.gridLines?.outLine?.selected
        ? tableConfiguration?.gridLines?.outLine?.borderColor
        : 'none',
      'border-width': tableConfiguration?.gridLines?.outLine?.selectedOutLine ? '5px' : '1px',
      'border-radius': tableConfiguration?.gridLines?.outLine?.selected
        ? tableConfiguration?.gridLines?.outLine?.borderRadius + 'px'
        : '0px',
      'border-collapse': tableConfiguration?.gridLines?.outLine?.selected ? 'separate' : 'collapse'
    }" [columns]="selectedColumns" [value]="listValues"
    [paginator]="cofiguration?.pagination && (!customPagination || isInfo) && listValues.length > 10"
    [rows]="pagination?.rows" [showCurrentPageReport]="true" currentPageReportTemplate="{first} {{ labels?.to || 'to' }} {last} {{ labels?.of || 'of' }} {totalRecords} {{
      labels?.entries || 'entries'
    }}" [rowsPerPageOptions]="[10, 15, 25, 50]" paginatorDropdownAppendTo="body" (sortFunction)="customSort($event)"
    [customSort]="true" (onPage)="paginate($event)" [(selection)]="selectedTransactionArray"
    (onHeaderCheckboxToggle)="selectRows($event)" [styleClass]="
      tableConfiguration?.gridLines?.selectedStyle?.class
        ? tableConfiguration?.gridLines?.selectedStyle?.class
        : 'p-datatable-sm'
    " [(first)]="rowNumberChanged.first">
    <!-- file upload and download -->
    <ng-template pTemplate="caption">
      <div class="table_gsis">
        <h5 *ngIf="cofiguration?.showTitle" class="heading-tuc nh-pb-6">
          {{ tableConfiguration?.setting?.entityTitle ? tableConfiguration?.setting?.entityTitle : title }}
        </h5>
        <div class="gsibuttons nh-pb-6" [ngClass]="{ 'w-100': !cofiguration?.showTitle }">
          <div class="splitwrap-info gsis" style="display: inline-flex;" *ngIf="
              combineEventsData?.length > 0 && tableConfiguration?.gsiConfigurations?.gsiPosition == 'onTop' && !isInfo
            ">
            <ng-container *ngFor="let currEvent of combineEventsData; let i = index">
              <button class="cursor-pointer"
                [ngClass]="{ 'gsi-image': gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon' }"
                *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                matTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}">
                <img class="icon" [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc"
                  (click)="saveEventGSI(currEvent, selectedColumns, 'onTop')" />
              </button>
              <button type="button" *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon' "
                [ngStyle]="{
                  'background-color': gsiConfigurations?.[i]?.gsiConfiguration?.background,
                  color: gsiConfigurations?.[i]?.gsiConfiguration?.font,
                  'border-radius': gsiConfigurations?.[i]?.gsiConfiguration?.borderRadius
                    ? gsiConfigurations?.[i]?.gsiConfiguration?.borderRadius + 'px'
                    : '0px',
                  'border-color': gsiConfigurations?.[i]?.gsiConfiguration?.border
                    ? gsiConfigurations?.[i]?.gsiConfiguration?.border
                    : 'none',
                  'font-style': gsiConfigurations?.[i]?.gsiConfiguration?.italic ? 'italic' : 'normal',
                  'text-decoration-line': gsiConfigurations?.[i]?.gsiConfiguration?.underLine ? 'underLine' : 'none',
                  'font-size': gsiConfigurations?.[i]?.gsiConfiguration?.fontSize + 'px',
                  'hover:background-color': gsiConfigurations?.[i]?.gsiConfiguration?.colorHover,
                  'hover:color': gsiConfigurations?.[i]?.gsiConfiguration?.fontHover,
                  'font-family': gsiConfigurations?.[i]?.gsiConfiguration?.fontFamily,
                  'text-align': gsiConfigurations?.[i]?.gsiConfiguration?.alignment
                }" mat-button (click)="saveEventGSI(currEvent, selectedColumns, 'onTop')">
                <img *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'" class="icon"
                  [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc" />
                {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
              </button>
            </ng-container>
          </div>
          <div *ngIf="cofiguration?.toggleColumn">
            <p-multiSelect class="column-toggle" (onPanelShow)="handleOnPanelShow()" (onPanelHide)="handleOnPanelHide()"
              [options]="togglecols" [(ngModel)]="selectedColumns" optionLabel="label"
              selectedItemsLabel="{0} columns selected" [style]="{ minWidth: '150px' }" placeholder="Choose Columns"
              appendTo="body" disableRipple></p-multiSelect>
          </div>
          <div class="export upload cursor-pointer nh-ml-14" *ngIf="cofiguration?.allowUpload">
            <input class="cursor-pointer" id="input-file-id_{{ entityName }}_{{ slot }}" type="file"
              (change)="onFileChange($event)" />
            <label for="input-file-id_{{ entityName }}_{{ slot }}">
              <button>
                <figure>
                  <img src="../../../../../assets/images/import-new.svg" alt="" />
                </figure>
                Import
              </button>
            </label>
          </div>
          <div class="export nh-ml-14" *ngIf="cofiguration?.allowDownload">
            <button class="nh-mr-0" (click)="export.toggle($event)">
              <figure>
                <img src="../../../../../assets/images/export-new.svg" alt="" *ngIf="!tenantName?.includes('defisolutions')"/>
                <em class="nh-icon nh-icon-download01" *ngIf="tenantName?.includes('defisolutions')"></em>
              </figure>
              Export
            </button>
            <p-overlayPanel styleClass="exportOverlay" baseZIndex="9999" #export>
              <ul>
                <li *ngIf="exportOptions?.[0].isChecked" (click)="exportPdf()">PDF</li>
                <li *ngIf="exportOptions?.[0].isChecked" (click)="exportLandScapePdf()">PDF Landscape</li>
                <li *ngIf="exportOptions?.[1].isChecked" (click)="exportCSVFile(ptcsv)">CSV</li>
                <li *ngIf="exportOptions?.[2].isChecked" (click)="exportExcel()">Excel</li>
              </ul>
            </p-overlayPanel>
          </div>
          <div class="export nh-ml-14"
            *ngIf="cofiguration?.inputTemplate || tableConfiguration?.advanceTableFeatures?.tableTemplate">
            <button class="nh-mr-0" type="button">
              <span class="cstm-lble">
                <a (click)="downloadTemplate()">{{ labels?.DownLoad_Template }}</a>
              </span>
            </button>
          </div>
          <div *ngIf="
              (filterData?.reservedCUType == 'NSL_Template_SearchResult' ||
                filterData?.reservedCUType == 'NSL_Template_PaginatedEQLSearchResult') &&
              cofiguration?.updateTableData
            ">
            <button id="update_Table_for_reserveCu" (click)="updateTable()">
              {{ labels?.Update || 'Update' }}
            </button>
          </div>
          <div class="nh-ml-14 export" *ngIf="!isFullScreen && cofiguration?.allowExpand">
            <button (click)="zoomTbl()">
              <i class="bi bi-arrows-angle-expand"></i>
            </button>
          </div>
          <div class="nh-ml-14 export" *ngIf="isFullScreen && cofiguration?.allowExpand">
            <button (click)="minimizeTbl()">
              <i class="bi bi-arrows-angle-contract"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="tbl-hder" [ngStyle]="{
          'background-color': tableConfiguration?.description?.header?.style?.backgroundColor,
          color: tableConfiguration?.description?.header?.style?.fontColor,
          'font-weight': tableConfiguration?.description?.header?.text?.bold ? 'bold' : 'normal',
          'font-style': tableConfiguration?.description?.header?.text?.italic ? 'italic' : 'normal',
          'text-decoration-line': tableConfiguration?.description?.header?.text?.underLine ? 'underline' : 'none',
          'font-size': tableConfiguration?.description?.header?.text?.fontSize + 'px',
          'font-family': tableConfiguration?.description?.header?.text?.fontFamily,
          'text-align': tableConfiguration?.description?.header?.text?.alignment
        }" *ngIf="tableDescription?.headerDescription">
        {{ tableDescription?.headerDescription }}
      </div>
    </ng-template>
    <!-- SrNo, attributes and filters,sorting -->
    <ng-template pTemplate="header" let-columns>
      <tr class="row-outline" [ngStyle]="{
          'border-radius': tableConfiguration?.gridLines?.rows?.selected
            ? tableConfiguration?.gridLines?.rows?.borderRadius + 'px'
            : '0px'
        }">
        <th *ngIf="(cofiguration?.deleteRecord && listValues?.length > 1) || cofiguration?.loadLabels"
          class="delete-row row-delete"></th>
        <!-- <th *ngIf="cofiguration?.loadLabels"></th> -->
        <th [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            'freeze-element': cofiguration?.freezeSelection || cofiguration?.freezeSeNum
          }" [ngStyle]="{
            'background-color': tableConfiguration?.headerBackground,
            'hover:color': tableConfiguration?.header?.fontHover,
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            width: !singlePageTable ? extraWidthPercentSelectButton : ''
          }" class="checkbox-table text-center" *ngIf="!cofiguration?.hideSelectButton && !hideSelectAllButton">
          <p-tableHeaderCheckbox id="{{ entityName }}_{{ slot }}_Select All" (click)="selectedTransactions($event, -1)"
            [disabled]="isInfo"></p-tableHeaderCheckbox>
        </th>
        <th [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            'freeze-element': cofiguration?.freezeSelection || cofiguration?.freezeSeNum
          }" [ngStyle]="{
            'background-color': tableConfiguration?.headerBackground,
            'hover:color': tableConfiguration?.header?.fontHover,
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            'font-size' : tableConfiguration?.header?.text?.fontSize + 'px',
            width: !singlePageTable ? extraWidthPercentSelectButton : ''
          }" *ngIf="
            !cofiguration?.hideSelectButton &&
            hideSelectAllButton &&
            (tableSelection !== 'single' || !cofiguration?.allowSelection)
          "></th>
        <th [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            'freeze-element': cofiguration?.freezeSeNum,
            'freeze-element-checkbox':
              cofiguration?.freezeSeNum && !cofiguration?.hideSelectButton && !hideSelectAllButton,
            'freeze-element-radiobox':
              tableSelection === 'single' &&
              (cofiguration?.freezeSelection || cofiguration?.freezeSeNum) &&
              !cofiguration?.allowSelection
          }" [ngStyle]="{
            'background-color': tableConfiguration?.headerBackground,
            'font-weight': tableConfiguration?.header?.text?.bold ? 'bold' : 'normal',
            'font-style': tableConfiguration?.header?.text?.italic ? 'italic' : 'normal',
            'text-decoration-line': tableConfiguration?.header?.text?.underLine ? 'underline' : 'none',
            'font-size': tableConfiguration?.header?.text?.fontSize + 'px',
            'hover:color': tableConfiguration?.header?.fontHover,
            'font-family': tableConfiguration?.header?.text?.fontFamily,
            'text-align': cofiguration?.header?.text?.alignment?.justify,
            color: tableConfiguration?.header?.fontColor,
            'border-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            width: !singlePageTable ? extraWidthPercentSNo : ''
          }" *ngIf="!cofiguration?.hideSerialNumber" class="serial-number serial input text-center">
          {{ labels.SNo || 'S.No' }}
        </th>
        <!-- nested row -->
        <th *ngIf="tableConfiguration?.nestedRow" [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable
          }" [ngStyle]="{
            'background-color': tableConfiguration?.headerBackground,
            'hover:color': tableConfiguration?.header?.fontHover,
            'border-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            'font-size' : tableConfiguration?.header?.text?.fontSize + 'px',
            width: !singlePageTable ? extraWidthPercentSelectButton : ''
          }"></th>

        <!-- <th
          [ngClass]="{ 'freeze-element': freezeColumnName == 'selectionColumn' }"
          *ngIf="!cofiguration?.hideSelectButton && hideSelectAllButton"
        ></th>
        <th [ngClass]="{ 'freeze-element': freezeColumnName == 'SrNo' }" *ngIf="!cofiguration?.hideSerialNumber"></th>
        -->
        <th class="content-name" [ngStyle]="{
            'background-color': tableConfiguration?.headerBackground,
            'font-weight': tableConfiguration?.header?.text?.bold ? 'bold' : 'normal',
            'font-style': tableConfiguration?.header?.text?.italic ? 'italic' : 'normal',
            'text-decoration-line': tableConfiguration?.header?.text?.underLine ? 'underline' : 'none',
            'font-size': tableConfiguration?.header?.text?.fontSize + 'px',
            'hover:color': tableConfiguration?.header?.fontHover,
            'font-family': tableConfiguration?.header?.text?.fontFamily,
            'text-align': tableConfiguration?.header?.text?.alignment?.justify,
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            color: tableConfiguration?.header?.fontColor,
            width: !singlePageTable ? (tableConfiguration?.widthArray)[index] : ''
          }" *ngFor="let col of columns; let index = index" id="col.header" [ngClass]="{
            hide: col?.form?.value?.isHidden === true,
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            hideAttribute:
              col?.containsOnSelectEvent &&
              tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
              tableConfiguration?.gsiConfigurations?.hideAttrName
          }" pTooltip="{{ col?.displayValue ? col?.displayValue : col?.header }}{{
            !col?.isOptional && !col?.isInfo ? ' *' : ''
          }}" tooltipPosition="top">
          <span *ngIf="
              !(
                col?.containsOnSelectEvent &&
                tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
                tableConfiguration?.gsiConfigurations?.hideAttrName
              ) && !col?.attrConfiguration1?.hideLabel
            " class="entity-table-header-span align-middle"
            [ngClass]="{ wc_56: numberOfButtons == 3, wc_36: numberOfButtons == 2, wc_16: numberOfButtons == 1 }">{{
            col?.displayValue ? col?.displayValue : col?.header }}
            <em *ngIf="!col?.isOptional && !col?.isInfo" class="mat-form-field-required-marker table-marker">*</em>
            <p class="ml-2" *ngIf="col?.attrConfiguration1?.showHelperTextIcon" [ngStyle]="{
                color: col?.attrConfiguration1?.helperTextColor,
                'font-size': col?.attrConfiguration1?.helperTextFont
              }">
              <mat-icon class="material-icons-outlined" matTooltip="{{ col?.attrConfiguration1?.helpertext }}"
                matTooltipPosition="above">help_outline</mat-icon>
            </p>
          </span>
          <span *ngIf="
              !(
                col.containsOnSelectEvent &&
                tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
                tableConfiguration?.gsiConfigurations?.hideAttrName
              )
            " #buttonContainer class="table-filter-icons">
            <button *ngIf="
                cofiguration?.allowColumnsort &&
                cofiguration?.sort?.sortPlacement !== 'top' &&
                !cofiguration?.sort?.sortFields?.includes(col?.displayValue ? col?.displayValue : col?.header)"
              [pSortableColumn]="col.field">
              <em class="nh-icon nh-icon-Sorting"></em>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="
                cofiguration?.allowColumnFilter &&
                !cofiguration?.filter?.filterFields?.includes(col?.displayValue ? col?.displayValue : col?.header)
              ">
              <em class="nh-icon nh-icon-filter-small"></em>
            </button>
            <button (click)="searchClicked($event, index)" *ngIf="
                (cofiguration?.allowColumnSearch == true ||
                  (!isallowColumnSearch && cofiguration?.allowColumnFilter)) &&
                cofiguration?.search?.searchPlacement !== 'top' &&
                !cofiguration?.search?.searchFields?.includes(col?.displayValue ? col?.displayValue : col?.header)
              ">
              <em class="nh-icon nh-icon-search"></em>
            </button>
            <mat-menu #menu="matMenu" class="menu-Overlay">
              <div class="search-filter">
                <input type="text" placeholder="{{ labels?.Search_by }}" [(ngModel)]="col.searchText"
                  (click)="innerSearch($event)" #search />
                <em class="nh-icon nh-icon-search-line d-none"></em>
                <em class="nh-icon nh-icon-x-lg"
                  (click)="filterFunction(col, col?.searchText, 'Clear', col?.searchText)"></em>
              </div>
              <ul class="filter-list checkboxes">
                <li (click)="filterFunction(col, col?.searchText, filter, null)" *ngFor="let filter of filters">
                  {{ filter }}
                </li>
              </ul>
            </mat-menu>
            <!-- <div class="tablesearch">
              <span class="searchbar">
                <input
                  class="input"
                  placeholder="{{ labels?.Search_by }}"
                  type="text"
                  [(ngModel)]="searchValue"
                  (ngModelChange)="searchQuery.next([$event, col])"
                />
                <em class="nh-icon nh-icon-search-line d-none"></em>
                <em
                  class="nh-icon nh-icon-x-lg"
                  (click)="closeInput()"
                  *ngIf="isSearchClicked && index == selectedIndex && !col.searchText"
                ></em>
              </span>
            </div> -->
          </span>
          <div class="search-inputs" *ngIf="isSearchClicked && index == selectedIndex && !col?.searchText">
            <input class="input" placeholder="Search by" type="text" [(ngModel)]="searchValue"
              (ngModelChange)="searchQuery.next([$event, col])" />
            <img src="../../../../../assets/img/general-entity/close-icon.svg" alt="" (click)="closeInput()" />
          </div>
        </th>
      </tr>
    </ng-template>
    <!-- table body -->
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
      <!-- 'background-color':
            rowIndex % 2 == 0
              ? oddRowHovered && rowHovered == rowIndex
                ? tableConfiguration?.oddRow?.colorHover
                : tableConfiguration?.oddRowColor
              : evenRowHovered && rowHovered == rowIndex
              ? tableConfiguration?.evenRow?.colorHover
              : tableConfiguration?.evenRowColor, -->
      <tr [ngStyle]="{
          'font-weight':
            rowIndex % 2 == 0
              ? tableConfiguration?.oddRow?.text?.bold
                ? 'bold'
                : 'normal'
              : tableConfiguration?.evenRow?.text?.bold
              ? 'bold'
              : 'normal',
          'font-style':
            rowIndex % 2 == 0
              ? tableConfiguration?.oddRow?.text?.italic
                ? 'italic'
                : 'normal'
              : tableConfiguration?.evenRow?.text?.italic
              ? 'italic'
              : 'normal',
          'text-decoration-line':
            rowIndex % 2 == 0
              ? tableConfiguration?.oddRow?.text?.underLine
                ? 'underLine'
                : 'none'
              : tableConfiguration?.evenRow?.text?.underLine
              ? 'underLine'
              : 'none',
          'hover:background-color':
            rowIndex % 2 == 0 ? tableConfiguration?.oddRow?.colorHover : tableConfiguration?.evenRow?.colorHover,
          'hover:color':
            rowIndex % 2 == 0 ? tableConfiguration?.oddRow?.fontHover : tableConfiguration?.evenRow?.fontHover,
          'font-family':
            rowIndex % 2 == 0
              ? tableConfiguration?.oddRow?.text?.fontFamily
              : tableConfiguration?.evenRow?.text?.fontFamily,
          'text-align':
            rowIndex % 2 == 0
              ? tableConfiguration?.oddRow?.text?.alignment?.justify
              : tableConfiguration?.evenRow?.text?.alignment?.justify,
          'border-radius': tableConfiguration?.gridLines?.rows?.selected
            ? tableConfiguration?.gridLines?.rows?.borderRadius + 'px'
            : '0px'
        }" (click)="clickOnRow($event, rowIndex)" [ngClass]="{
          'last-row': rowIndex === listValues.length - 1 || (rowIndex + 1) % pagination?.rows === 0,
          'row-highlight': selectedTransactionArray?.length > 0 && selectedTransactionArray?.includes(rowData),
          'row-highlight-first':
            selectedTransactionArray?.length > 0 &&
            selectedTransactionArray?.includes(rowData) &&
            ((cofiguration?.deleteRecord && listValues.length > 1) || cofiguration?.loadLabels)
        }" class="normal-table editable edittable-normal row-outline">
        <!-- tablerow deletion -->
        <td class="position-absolute d-flex delete-row p-0"
          *ngIf="(cofiguration?.deleteRecord && listValues.length > 1) || cofiguration?.loadLabels">
          <div class="row-delete" *ngIf="cofiguration?.deleteRecord && listValues.length > 1"
            (click)="deleteEntityControl(rowIndex, rowData)">
            <em class="nh-icon nh-icon-trash01"></em>
          </div>
          <div *ngIf="cofiguration?.loadLabels" class="row-delete" (click)="editRow(rowIndex, false)">
            <em class="nh-icon nh-icon-edit02"></em>
          </div>
        </td>
        <!-- <td *ngIf="cofiguration?.loadLabels">
          <span  (click)="editRow(rowIndex,false)">
          <mat-icon>edit</mat-icon>

          </span>
        </td> -->
        <!-- tablerow selection -->
        <td *ngIf="!cofiguration?.hideSelectButton && (tableSelection !== 'single' || !cofiguration?.allowSelection)"
          class="text-center radiobox" [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            'freeze-element': cofiguration?.freezeSelection || cofiguration?.freezeSeNum
          }" [ngStyle]="{
            'background-color': rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.colorHover
                  : tableConfiguration?.oddRowColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.colorHover
                : tableConfiguration?.evenRowColor,
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            color:
              rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.fontHover
                  : tableConfiguration?.oddRow?.fontColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.fontHover
                : tableConfiguration?.evenRow?.fontColor,
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            width: !singlePageTable ? extraWidthPercentSelectButton : ''
          }" (mouseover)="setHoverColors(rowIndex)" (mouseout)="setOutHoverColors(rowIndex)">
          <p-tableRadioButton *ngIf="tableSelection === 'single' && !cofiguration?.allowSelection" [disabled]="isInfo"
            (click)="selectedTransactions($event, rowIndex)" [value]="rowData"></p-tableRadioButton>
          <p-tableCheckbox *ngIf="tableSelection !== 'single'" class="checkbox" [disabled]="isInfo"
            (click)="selectedTransactions($event, rowIndex)" [value]="rowData"></p-tableCheckbox>
        </td>
        <!-- serial number -->
        <td [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            'freeze-element': cofiguration?.freezeSeNum,
            'freeze-element-checkbox':
              cofiguration?.freezeSeNum && !cofiguration?.hideSelectButton && !hideSelectAllButton,
            'freeze-element-radiobox':
              tableSelection === 'single' &&
              (cofiguration?.freezeSelection || cofiguration?.freezeSeNum) &&
              !cofiguration?.allowSelection
          }" [ngStyle]="{
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            color:
              rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.fontHover
                  : tableConfiguration?.oddRow?.fontColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.fontHover
                : tableConfiguration?.evenRow?.fontColor,
            'background-color': rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.colorHover
                  : tableConfiguration?.oddRowColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.colorHover
                : tableConfiguration?.evenRowColor,
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            'font-size':
              rowIndex % 2 == 0
                ? tableConfiguration?.oddRow?.text?.fontSize + 'px'
                : tableConfiguration?.evenRow?.text?.fontSize + 'px',
            width: !singlePageTable ? extraWidthPercentSNo : ''
          }" (mouseover)="setHoverColors(rowIndex)" (mouseout)="setOutHoverColors(rowIndex)"
          *ngIf="!cofiguration?.hideSerialNumber" class="text-center sno">
          {{
          customPagination
          ? (paginationDetails.pageNumber ? paginationDetails.pageNumber - 1 : 1 - 1) * paginationDetails.pageSize +
          rowIndex +
          1
          : rowIndex + 1
          }}
        </td>
        <!-- nested row -->

        <td class="table-row" *ngIf="tableConfiguration?.nestedRow" [ngClass]="{
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable
          }" [ngStyle]="{
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            color:
              rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.fontHover
                  : tableConfiguration?.oddRow?.fontColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.fontHover
                : tableConfiguration?.evenRow?.fontColor,
              'background-color': rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.colorHover
                  : tableConfiguration?.oddRowColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.colorHover
                : tableConfiguration?.evenRowColor,
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            'font-size':
              rowIndex % 2 == 0
                ? tableConfiguration?.oddRow?.text?.fontSize + 'px'
                : tableConfiguration?.evenRow?.text?.fontSize + 'px',
            width: !singlePageTable ? extraWidthPercentSelectButton : ''
          }">
          <button *ngIf="tableConfiguration?.nestedRow" type="button" pButton pRipple
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="selectedNestedRow === rowIndex ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="
              this.selectedNestedRow === rowIndex ? (this.selectedNestedRow = -1) : (this.selectedNestedRow = rowIndex)
            "></button>
        </td>

        <!-- attributes data -->
        <td *ngFor="let col of columns; let index = index" [ngClass]="{
            hide: col?.form?.value?.isHidden === true,
            'column-outline': tableConfiguration?.gridLines?.column?.selected == true || cofiguration?.viewAsGridTable,
            'combination-after':
              tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
              rowData?.[col?.header]?.containsOnSelectEvent &&
              !isInfo &&
              tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop',
            hideAttribute:
            rowData?.[col?.header]?.containsOnSelectEvent &&
              !isInfo &&
              tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
              tableConfiguration?.gsiConfigurations?.hideAttrName,
              'inlinegsi': rowData?.[col?.header]?.containsOnSelectEvent &&
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop' &&
                !isInfo,
                'uiCon-alignCenter': rowIndex % 2 != 0
                ? tableConfiguration?.oddRow?.text?.alignment?.justify == 'center'
                : tableConfiguration?.evenRow?.text?.alignment?.justify == 'center',
                'uiCon-alignRight':rowIndex % 2 != 0
                ? tableConfiguration?.oddRow?.text?.alignment?.justify == 'right'
                : tableConfiguration?.evenRow?.text?.alignment?.justify == 'right'
          }" [ngStyle]="{
            'background-color': (rowConditionCheckedArray?.[rowIndex]?.[index]?.attrId == col.attr_id && rowConditionCheckedArray?.[rowIndex]?.[index]?.checked) ? rowConditionCheckedArray?.[rowIndex]?.[index]?.fillColor :
              rowIndex % 2 == 0
                ? oddRowHovered == rowIndex ||
                  (index == columnHovered && tableConfiguration?.advanceTableFeatures?.columnHighlights)
                  ? tableConfiguration?.oddRow?.colorHover
                  : tableConfiguration?.oddRowColor
                : evenRowHovered == rowIndex ||
                  (index == columnHovered && tableConfiguration?.advanceTableFeatures?.columnHighlights)
                ? tableConfiguration?.evenRow?.colorHover
                : tableConfiguration?.evenRowColor,
            color:
              rowIndex % 2 == 0
                ? oddRowHovered == rowIndex
                  ? tableConfiguration?.oddRow?.fontHover
                  : tableConfiguration?.oddRow?.fontColor
                : evenRowHovered == rowIndex
                ? tableConfiguration?.evenRow?.fontHover
                : tableConfiguration?.evenRow?.fontColor,
            'border-right-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-left-color': cofiguration?.viewAsGridTable
              ? '#cccccc'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderColor
              : 'none',
            'border-top-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-bottom-color': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderColor
              : 'none',
            'border-top-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-bottom-width': tableConfiguration?.gridLines?.rows?.selected
              ? tableConfiguration?.gridLines?.rows?.borderThickness + 'px'
              : '1px',
            'border-left-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-right-width': cofiguration?.viewAsGridTable
              ? '1px'
              : tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderThickness + 'px'
              : '0px',
            'border-radius': tableConfiguration?.gridLines?.column?.selected
              ? tableConfiguration?.gridLines?.column?.borderRadius + 'px'
              : '0px',
            'text-align':
              rowIndex % 2 == 0
                ? tableConfiguration?.oddRow?.text?.alignment?.justify
                : tableConfiguration?.evenRow?.text?.alignment?.justify,
            'font-size':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.fontSize + 'px'
                  : tableConfiguration?.evenRow?.text?.fontSize + 'px',
            width: !singlePageTable ? (tableConfiguration?.widthArray)[index] : ''
          }" class="table-w-fixed table-left-border" (mouseover)="setHoverColors(rowIndex, index)"
          (mouseout)="setOutHoverColors(rowIndex, index)">
          <div class="applabel-0 splitwrap" [ngClass]="{
              'split-view': splitScreenclicked,
              combinebtns:
                tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
                rowData?.[col?.header]?.containsOnSelectEvent &&
                !isInfo &&
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop',
              inlinebtns:
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'combineInline' &&
                !isInfo &&
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop'
            }">
            <div [ngClass]="{
                'attribte-opacity': rowData[col.header]?.isDisabled
                  ? rowData[col.header]?.isDisabled
                  : rowData[col.header]?.isNegative
                  ? rowData[col.header]?.isNegative
                  : rowData[col.header]?.isInfo && rowData[col.header]?.value != ''
              }" class="splitwrap-info"
              [ngClass]="{ 'flex-92': tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' }" *ngIf="
                !(
                  rowData?.[col?.header]?.containsOnSelectEvent &&
                  tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
                  tableConfiguration?.gsiConfigurations?.hideAttrName
                )
              ">
              <ng-container *ngIf="
                  rowData[col.header]?.inputType !== 'label' ||
                  (rowData[col.header]?.inputType === 'label' && rowData[col.header]?.value)
                " dynamicField [formData]="rowData[col.header]" [group]="rowData[col.header + 'form']"
                [isTableConfig]="true" [tableUiStyle]="cofiguration?.tableDesign" [hideLabels]="hideLabels"
                (dateChanged)="dateChnagedEvent($event)" (clickableOnSelect)="getClickableOnSelectData($event)"
                (chipDataChanged)="selectedTransactions($event, rowIndex, true)" [isOnSelectSubmit]="
                  (tableSelection === 'single' && tableSettings?.submitOnSelection) ||
                  (cofiguration?.hideSelectButton && tableSettings?.submitOnSelection)
                ">
              </ng-container>
              <div *ngIf="rowData[col.header].inputType === 'image' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="1"
                  (click)="openpopup(rowData, rowData[col.header])">{{labels?.Popup}}</mat-radio-button>
                <mat-radio-button value="2"
                  (click)="newtab(rowData, rowData[col.header], col.header)">newtab</mat-radio-button>
                <mat-radio-button value="3"
                  (click)="splitScreen(rowData, rowData[col.header], col.header)">{{labels?.Splits_Screen}}</mat-radio-button>
              </div>
              <div *ngIf="rowData[col.header].inputType === 'video' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="2"
                  (click)="newtab(rowData, rowData[col.header], col.header)">{{labels?.Newtab}}</mat-radio-button>

                <mat-radio-button value="3"
                  (click)="splitScreen(rowData, rowData[col.header], col.header)">{{labels?.Splits_Screen}}</mat-radio-button>
              </div>
              <div *ngIf="rowData[col.header].inputType === 'file' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="2"
                  (click)="newtab(rowData, rowData[col.header], col.header)">{{labels?.Newtab}}</mat-radio-button>

                <mat-radio-button value="3"
                  (click)="splitScreen(rowData, rowData[col.header], col.header)">{{labels?.Splits_Screen}}</mat-radio-button>
              </div>
            </div>

            <!-- Detect event gsi -->
            <div class="splitwrap-info" *ngIf="
                rowData?.[col?.header]?.containsOnSelectEvent &&
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop' &&
                !isInfo
              ">
              <ng-container *ngIf="
                  tableConfiguration?.gsiConfigurations?.gsiPosition !== 'combineInline' &&
                  tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop'
                ">
                <div class="gsibuttons inline-gsi-buttons">
                  <div class="inline-gsi-button" *ngFor="let currEvent of combineEventsData; let i = index"
                    [ngClass]="{'btnwithimg': currEvent?.changeDriverId == col?.attr_id && gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon' && !parse(rowData[col.header]?.attr_configuration)?.clickableConfig?.enableClickableCustomization }">
                    <img class="icon gsi-image cursor-pointer"
                      [ngClass]="{'nh-mr-16': currEvent?.changeDriverId == col?.attr_id && gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon'}"
                      *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                      [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc"
                      matTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}"
                      (click)="saveEventGSI(currEvent, rowData[col.header])" />
                    <button type="button"
                      *ngIf="currEvent?.changeDriverId == col?.attr_id && gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon' && !parse(rowData[col.header]?.attr_configuration)?.clickableConfig?.enableClickableCustomization"
                      mat-button [ngStyle]="{
                      'background-color': gsiConfigurations?.[i]?.gsiConfiguration?.background,
                      color: gsiConfigurations?.[i]?.gsiConfiguration?.font,
                      'border-radius': gsiConfigurations?.[i]?.gsiConfiguration?.borderRadius
                        ? gsiConfigurations?.[i]?.gsiConfiguration?.borderRadius + 'px'
                        : '0px',
                      'border-color': gsiConfigurations?.[i]?.gsiConfiguration?.border
                        ? gsiConfigurations?.[i]?.gsiConfiguration?.border
                        : 'none',
                      'font-style': gsiConfigurations?.[i]?.gsiConfiguration?.italic ? 'italic' : 'normal',
                      'text-decoration-line': gsiConfigurations?.[i]?.gsiConfiguration?.underLine ? 'underLine' : 'none',
                      'font-size': gsiConfigurations?.[i]?.gsiConfiguration?.fontSize + 'px',
                      'hover:background-color': gsiConfigurations?.[i]?.gsiConfiguration?.colorHover,
                      'hover:color': gsiConfigurations?.[i]?.gsiConfiguration?.fontHover,
                      'font-family': gsiConfigurations?.[i]?.gsiConfiguration?.fontFamily,
                      'text-align': gsiConfigurations?.[i]?.gsiConfiguration?.alignment
                    }" (click)="saveEventGSI(currEvent, rowData[col.header])">
                      <img *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'"
                        class="icon gsi-image" [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc" />
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>

              </ng-container>
              <ng-container *ngIf="tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline'">
                <div class="export more-btns nh-ml-14">
                  <button class="cursor-pointer" (click)="morebtn.toggle($event)"></button>
                  <p-overlayPanel styleClass="exportOverlay" baseZIndex="9999" #morebtn>
                    <ul>
                      <li class="text-truncate"
                        pTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}"
                        tooltipPosition="top" *ngFor="let currEvent of currentEventData; let i = index"
                        (click)="saveEventGSI(currEvent, rowData[col.header])">
                        <ng-container
                          *ngIf="!parse(rowData[col.header]?.attr_configuration)?.clickableConfig?.enableClickableCustomization">
                          <img class="icon" *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                            [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc"
                            matTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}" />
                          <img *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'"
                            class="icon" [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc" />
                          <span *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon'">{{
                            currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName
                            }}</span>
                        </ng-container>

                      </li>
                    </ul>
                  </p-overlayPanel>
                </div>
              </ng-container>
            </div>
          </div>
        </td>
        <!-- <div class="row-delete" #rowDelete (click)="deleteEntityControl(rowIndex, rowData)">
          <em class="nh-icon nh-icon-trash01"></em>
        </div> -->
      </tr>
      <!-- nested row -->
      <tr *ngIf="selectedNestedRow === rowIndex && nestedCols?.length > 0">
        <td *ngIf="(cofiguration?.deleteRecord && listValues.length > 1) || cofiguration?.loadLabels"></td>
        <td *ngIf="!cofiguration?.hideSelectButton && (tableSelection !== 'single' || !cofiguration?.allowSelection)">
        </td>
        <td class="sno" *ngIf="!cofiguration?.hideSerialNumber"></td>
        <td *ngIf="tableConfiguration?.nestedRow" [ngStyle]="{
            width: !singlePageTable ? extraWidthPercentSelectButton : ''
          }"></td>
        <td *ngFor="let col of nestedCols; let index = index" class="table-w-fixed table-left-border"
          (mouseover)="setHoverColors(rowIndex, index)" (mouseout)="setOutHoverColors(rowIndex, index)"
          [style.width]="!singlePageTable ? (tableConfiguration?.widthArray)[index] : ''"
          [ngStyle]="{'font-size':
          rowIndex % 2 == 0
            ? tableConfiguration?.oddRow?.text?.fontSize + 'px'
            : tableConfiguration?.evenRow?.text?.fontSize + 'px'}"
          >
          <div class="applabel-0 splitwrap" [ngClass]="{
              'split-view': splitScreenclicked,
              combinebtns:
                tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
                rowData?.[col?.header]?.containsOnSelectEvent &&
                !isInfo &&
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop',
              inlinebtns: tableConfiguration?.gsiConfigurations?.gsiPosition === 'inline' && !isInfo
            }">
            <div [ngClass]="{
                'attribte-opacity': rowData[col.header]?.isDisabled
                  ? rowData[col.header]?.isDisabled
                  : rowData[col.header]?.isNegative
                  ? rowData[col.header]?.isNegative
                  : rowData[col.header]?.isInfo && rowData[col.header]?.value != ''
              }" class="splitwrap-info"
              [ngClass]="{ 'flex-92': tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' }" *ngIf="
                !(
                  rowData?.[col?.header]?.containsOnSelectEvent &&
                  tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline' &&
                  tableConfiguration?.gsiConfigurations?.hideAttrName
                )
              ">
              <ng-container *ngIf="
                  rowData[col.header]?.inputType !== 'label' ||
                  (rowData[col.header]?.inputType === 'label' && rowData[col.header]?.value)
                " dynamicField [formData]="rowData[col.header]" [group]="rowData[col.header + 'form']"
                [isTableConfig]="true" [tableUiStyle]="cofiguration?.tableDesign" [hideLabels]="hideLabels"
                (dateChanged)="dateChnagedEvent($event)" (clickableOnSelect)="getClickableOnSelectData($event)"
                (chipDataChanged)="selectedTransactions($event, rowIndex, true)" [isOnSelectSubmit]="
                  (tableSelection === 'single' && tableSettings?.submitOnSelection) ||
                  (cofiguration?.hideSelectButton && tableSettings?.submitOnSelection)
                ">
              </ng-container>
              <div *ngIf="rowData[col.header].inputType === 'image' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="1"
                  (click)="openpopup(rowData, rowData[col.header])">{{labels?.Popup}}</mat-radio-button>
                <mat-radio-button value="2"
                  (click)="newtab(rowData, rowData[col.header], col.header)">newtab</mat-radio-button>
                <mat-radio-button value="3"
                  (click)="splitScreen(rowData, rowData[col.header], col.header)">{{labels?.Splits_Screen}}</mat-radio-button>
              </div>
              <div *ngIf="rowData[col.header].inputType === 'video' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="2"
                  (click)="newtab(rowData, rowData[col.header], col.header)">{{labels?.Newtab}}</mat-radio-button>

                <mat-radio-button value="3"
                  (click)="splitScreen(rowData, rowData[col.header], col.header)">{{labels?.Splits_Screen}}</mat-radio-button>
              </div>
              <div *ngIf="rowData[col.header].inputType === 'file' && viewOptionsInTable" class="split-radio">
                <mat-radio-button value="2"
                  (click)="newtab(rowData, rowData[col.header], col.header)">{{labels?.Newtab}}</mat-radio-button>

                <mat-radio-button value="3"
                  (click)="splitScreen(rowData, rowData[col.header], col.header)">{{labels?.Splits_Screen}}</mat-radio-button>
              </div>
            </div>

            <!-- Detect event gsi -->
            <div class="splitwrap-info" *ngIf="
                rowData?.[col?.header]?.containsOnSelectEvent &&
                tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop' &&
                !isInfo
              ">
              <ng-container *ngIf="
                  tableConfiguration?.gsiConfigurations?.gsiPosition !== 'combineInline' &&
                  tableConfiguration?.gsiConfigurations?.gsiPosition !== 'onTop'
                ">
                <div class="gsibuttons">
                  <div class="inline-gsi-buttons" *ngFor="let currEvent of combineEventsData; let i = index">
                    <img class="icon gsi-image cursor-pointer"
                      *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                      [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc"
                      matTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}"
                      (click)="saveEventGSI(currEvent, rowData[col.header])" />
                    <button type="button"
                      *ngIf="currEvent?.changeDriverId == col?.attr_id && gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon' && !parse(rowData[col.header]?.attr_configuration)?.clickableConfig?.enableClickableCustomization"
                      mat-button
                      pTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}"
                      tooltipPosition="top" [ngStyle]="{
                      'background-color': gsiConfigurations?.[i]?.gsiConfiguration?.background,
                      color: gsiConfigurations?.[i]?.gsiConfiguration?.font,
                      'border-radius': gsiConfigurations?.[i]?.gsiConfiguration?.borderRadius
                        ? gsiConfigurations?.[i]?.gsiConfiguration?.borderRadius + 'px'
                        : '0px',
                      'border-color': gsiConfigurations?.[i]?.gsiConfiguration?.border
                        ? gsiConfigurations?.[i]?.gsiConfiguration?.border
                        : 'none',
                      'font-style': gsiConfigurations?.[i]?.gsiConfiguration?.italic ? 'italic' : 'normal',
                      'text-decoration-line': gsiConfigurations?.[i]?.gsiConfiguration?.underLine ? 'underLine' : 'none',
                      'font-size': gsiConfigurations?.[i]?.gsiConfiguration?.fontSize + 'px',
                      'hover:background-color': gsiConfigurations?.[i]?.gsiConfiguration?.colorHover,
                      'hover:color': gsiConfigurations?.[i]?.gsiConfiguration?.fontHover,
                      'font-family': gsiConfigurations?.[i]?.gsiConfiguration?.fontFamily,
                      'text-align': gsiConfigurations?.[i]?.gsiConfiguration?.alignment
                    }" (click)="saveEventGSI(currEvent, rowData[col.header])">
                      <img *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'"
                        class="icon" [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc" />
                      {{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}
                    </button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="tableConfiguration?.gsiConfigurations?.gsiPosition === 'combineInline'">
                <div class="export more-btns nh-ml-14">
                  <button class="cursor-pointer" (click)="morebtn.toggle($event)"></button>
                  <p-overlayPanel styleClass="exportOverlay" baseZIndex="9999" #morebtn>
                    <ul>
                      <li class="text-truncate"
                        pTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}"
                        tooltipPosition="top" *ngFor="let currEvent of currentEventData; let i = index"
                        (click)="saveEventGSI(currEvent, rowData[col.header])">
                        <ng-container
                          *ngIf="!parse(rowData[col.header]?.attr_configuration)?.clickableConfig?.enableClickableCustomization">
                          <img class="icon" *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'onlyIcon'"
                            [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc"
                            matTooltip="{{ currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName }}" />
                          <img *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay === 'gsiNameWithIcon'"
                            class="icon" [src]="gsiConfigurations?.[i]?.gsiConfiguration?.imageSrc" />
                          <span *ngIf="gsiConfigurations?.[i]?.gsiConfiguration?.gsiDisplay !== 'onlyIcon'">{{
                            currEvent?.gsiDisplayName ? currEvent?.gsiDisplayName : currEvent?.gsiName
                            }}</span>
                        </ng-container>

                      </li>
                    </ul>
                  </p-overlayPanel>
                </div>
              </ng-container>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-table [hidden]="true" #ptcsv [columns]="csvColumns" [value]="csvData">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" id="col.header" [ngClass]="{ hide: col?.form?.value?.isHidden === true }">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngClass]="{ hide: col?.form?.value?.isHidden === true }">
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- table footer -->
  <div [ngStyle]="{ 'background-color': tableConfiguration?.description?.footer?.style?.backgroundColor }"
    class="tbl-hder" *ngIf="tableDescription?.footerDescription">
    <div [ngStyle]="{
        color: tableConfiguration?.description?.footer?.style?.fontColor,
        'font-weight': tableConfiguration?.description?.footer?.text?.bold ? 'bold' : 'normal',
        'font-style': tableConfiguration?.description?.footer?.text?.italic ? 'italic' : 'normal',
        'text-decoration-line': tableConfiguration?.description?.footer?.text?.underLine ? 'underline' : 'none',
        'font-size': tableConfiguration?.description?.footer?.text?.fontSize + 'px',
        'font-family': tableConfiguration?.description?.footer?.text?.fontFamily,
        'text-align': tableConfiguration?.description?.footer?.text?.alignment
      }">
      {{ tableDescription?.footerDescription }}
    </div>
  </div>
  <div class="content-footer" *ngIf="customPagination && showPaginator && !isInfo">
    <div class="row align-items-center">
      <div class="col-auto" *ngIf="!cofiguration?.hidePagination; else pagination">
        <p-paginator (onPageChange)="onPageChange($event)" [first]="0" [rows]="paginationDetails.pageSize"
          [totalRecords]="paginationDetails.totalNumberOfRecords"></p-paginator>
      </div>
      <ng-template #pagination>
        <pagination-template class="reservedcu-paginator" #p="paginationApi" (pageChange)="onPageChange($event)">
          <ul>
            <li class="arrows leftarrow" (click)="p.previous()" [ngClass]="{ disablepaginator: p.isFirstPage() }">
              Previous
            </li>
            <li class="paginator-number">{{ p.getCurrent() }}</li>
            <li class="arrows rightarrow" [ngClass]="{ disablepaginator: p.isLastPage() }" (click)="p.next()">Next</li>
          </ul>
        </pagination-template>
      </ng-template>
    </div>
  </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<ng-template #popuptbl>
  <div class="splitscreen-modal">
    <div class="splitscreen-modal-table splitscreen-modal-gap events-none" disabled="disabled">
      <p-table #vsTable class="ptable-wrap opecity-0"
        [ngClass]="cofiguration?.gridTable ? 'custom-p-table-grid' : 'custom-p-table'" [columns]="selectedColumns"
        [value]="listValues"
        [paginator]="cofiguration?.pagination && (!customPagination || isInfo) && listValues.length > 10"
        [rows]="pagination?.rows" [showCurrentPageReport]="true" currentPageReportTemplate="{{ labels?.showing || 'showing' }} {first} {{ labels?.to || 'to' }} {last} {{
          labels?.of || 'of'
        }} {totalRecords} {{ labels?.entries || 'entries' }}" [rowsPerPageOptions]="[10, 15, 25, 50]"
        (sortFunction)="customSort($event)" [customSort]="true" (onPage)="paginate($event)"
        [(selection)]="selectedTransactionArray" (onHeaderCheckboxToggle)="selectRows($event)"
        styleClass="p-datatable-sm">
        <ng-template pTemplate="caption">
          <div class="table_gsis">
            <h5 *ngIf="cofiguration?.showTitle" class="heading-tuc nh-pb-6">
              {{ tableConfiguration?.setting?.entityTitle ? tableConfiguration.setting?.entityTitle : title }}
            </h5>
            <div class="gsibuttons nh-pb-6" [ngClass]="{ 'w-100': !cofiguration?.showTitle }">
              <div>
                <p-multiSelect class="column-toggle" *ngIf="cofiguration?.toggleColumn" [options]="togglecols"
                  [(ngModel)]="selectedColumns" optionLabel="label" selectedItemsLabel="{0} columns selected"
                  [style]="{ minWidth: '150px' }" placeholder="Choose Columns" appendTo="body"></p-multiSelect>
              </div>
              <div class="export upload nh-ml-14" *ngIf="cofiguration?.allowUpload">
                <input class="cursor-pointer" id="input-file-id_{{ entityName }}_{{ slot }}" type="file"
                  (change)="onFileChange($event)" />
                <label for="input-file-id_{{ entityName }}_{{ slot }}">
                  <button>
                    <figure>
                      <img src="../../../../../assets/images/import-new.svg" alt="" />
                    </figure>
                    Import
                  </button>
                </label>
              </div>
              <div class="export nh-ml-14" *ngIf="cofiguration?.allowDownload">
                <button (click)="export.toggle($event)">
                  <figure>
                    <img src="../../../../../assets/images/export-new.svg" alt="" />
                  </figure>
                  Export
                </button>
                <p-overlayPanel styleClass="exportOverlay" baseZIndex="9999" #export>
                  <ul>
                    <li *ngIf="exportOptions[0].isChecked" (click)="exportPdf()">PDF</li>
                    <li *ngIf="exportOptions[0].isChecked" (click)="exportLandScapePdf()">PDF Landscape</li>
                    <li *ngIf="exportOptions[1].isChecked" (click)="exportCSVFile(ptcsv)">CSV</li>
                    <li *ngIf="exportOptions[2].isChecked" (click)="exportExcel()">Excel</li>
                  </ul>
                </p-overlayPanel>
              </div>
              <div class="file-btn nh-ml-14"
                *ngIf="cofiguration?.inputTemplate || tableConfiguration?.advanceTableFeatures?.tableTemplate">
                <button type="button">
                  <span class="cstm-lble">
                    <a (click)="downloadTemplate()">{{ labels?.Download_Template }}</a>
                  </span>
                </button>
              </div>
              <div *ngIf="
              (filterData?.reservedCUType == 'NSL_Template_SearchResult' ||
                filterData?.reservedCUType == 'NSL_Template_PaginatedEQLSearchResult') &&
              cofiguration?.updateTableData
            ">
                <button id="update_Table_for_reserveCu" (click)="updateTable()">
                  {{ labels?.Update || 'Update' }}
                </button>
              </div>
              <div class="nh-ml-14 export" *ngIf="!isFullScreen && cofiguration?.allowExpand">
                <button (click)="zoomTbl()">
                  <i class="bi bi-arrows-angle-expand"></i>
                </button>
              </div>
              <div class="nh-ml-14 export" *ngIf="isFullScreen && cofiguration?.allowExpand">
                <button (click)="minimizeTbl()">
                  <i class="bi bi-arrows-angle-contract"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="tbl-hder" *ngIf="tableDescription?.headerDescription" [ngStyle]="{
              'background-color': tableConfiguration?.description?.header?.style?.backgroundColor,
              color: tableConfiguration?.description?.header?.style?.fontColor,
              'font-weight': tableConfiguration?.description?.header?.text?.bold ? 'bold' : 'normal',
              'font-style': tableConfiguration?.description?.header?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': tableConfiguration?.description?.header?.text?.underLine ? 'underline' : 'none',
              'font-size': tableConfiguration?.description?.header?.text?.fontSize + 'px',
              'font-family': tableConfiguration?.description?.header?.text?.fontFamily,
              'text-align': tableConfiguration?.description?.header?.text?.alignment
            }">
            {{ tableDescription?.headerDescription }}
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr class="row-outline">
            <th [ngStyle]="{
                'background-color': tableConfiguration?.headerBackground,
                'font-weight': tableConfiguration?.header?.text?.bold ? 'bold' : 'normal',
                'font-style': tableConfiguration?.header?.text?.italic ? 'italic' : 'normal',
                'text-decoration-line': tableConfiguration?.header?.text?.underLine ? 'underline' : 'none',
                'font-size': tableConfiguration?.header?.text?.fontSize + 'px',
                'hover:color': tableConfiguration?.header?.fontHover,
                'font-family': tableConfiguration?.header?.text?.fontFamily,
                'text-align': cofiguration?.header?.text?.alignment?.justify
              }" [ngClass]="{ 'freeze-element': cofiguration?.freezeSeNum }" *ngIf="
                !cofiguration?.hideSelectButton &&
                !hideSelectAllButton &&
                tableSelection === 'single' &&
                !cofiguration?.allowSelection
              ">
              <p-tableHeaderCheckbox id="{{ entityName }}_{{ slot }}_Select All"
                (click)="selectedTransactions($event, -1)" [disabled]="isInfo"></p-tableHeaderCheckbox>
            </th>
            <th [ngStyle]="{
                'background-color': tableConfiguration?.headerBackground,
                'font-weight': tableConfiguration?.header?.text?.bold ? 'bold' : 'normal',
                'font-style': tableConfiguration?.header?.text?.italic ? 'italic' : 'normal',
                'text-decoration-line': tableConfiguration?.header?.text?.underLine ? 'underline' : 'none',
                'font-size': tableConfiguration?.header?.text?.fontSize + 'px',
                'hover:color': tableConfiguration?.header?.fontHover,
                'font-family': tableConfiguration?.header?.text?.fontFamily,
                'text-align': cofiguration?.header?.text?.alignment?.justify
              }" *ngIf="
                !cofiguration?.hideSelectButton &&
                hideSelectAllButton &&
                tableSelection === 'single' &&
                !cofiguration?.allowSelection
              "></th>
            <th [ngStyle]="{
                'background-color': tableConfiguration?.headerBackground,
                'font-weight': tableConfiguration?.header?.text?.bold ? 'bold' : 'normal',
                'font-style': tableConfiguration?.header?.text?.italic ? 'italic' : 'normal',
                'text-decoration-line': tableConfiguration?.header?.text?.underLine ? 'underline' : 'none',
                'font-size': tableConfiguration?.header?.text?.fontSize + 'px',
                'hover:color': tableConfiguration?.header?.fontHover,
                'font-family': tableConfiguration?.header?.text?.fontFamily,
                'text-align': cofiguration?.header?.text?.alignment?.justify,
                width: !singlePageTable ? extraWidthPercentSNo : ''
              }" class="text-center" *ngIf="!cofiguration?.hideSerialNumber">
              S.No
            </th>
            <!-- <th
            [ngClass]="{ 'freeze-element': freezeColumnName == 'selectionColumn' }"
            *ngIf="!cofiguration?.hideSelectButton && hideSelectAllButton"
          ></th>
          <th [ngClass]="{ 'freeze-element': freezeColumnName == 'SrNo' }" *ngIf="!cofiguration?.hideSerialNumber"></th> -->
            <th [ngStyle]="{
                'background-color': tableConfiguration?.headerBackground,
                'font-weight': tableConfiguration?.header?.text?.bold ? 'bold' : 'normal',
                'font-style': tableConfiguration?.header?.text?.italic ? 'italic' : 'normal',
                'text-decoration-line': tableConfiguration?.header?.text?.underLine ? 'underline' : 'none',
                'font-size': tableConfiguration?.header?.text?.fontSize + 'px',
                'hover:color': tableConfiguration?.header?.fontHover,
                'font-family': tableConfiguration?.header?.text?.fontFamily,
                'text-align': cofiguration?.header?.text?.alignment?.justify
              }" *ngFor="let col of columns; let index = index" id="col.header" [ngClass]="{
                hide: col?.form?.value?.isHidden === true
              }">
              <div class="filter-wrap">
                {{ col?.displayValue ? col?.displayValue : col?.header }}
                <div class="box-bg" *ngIf="
                    cofiguration?.allowColumnsort &&
                    cofiguration?.sort?.sortPlacement !== 'top' &&
                    !cofiguration?.sort?.sortFields?.includes(col?.displayValue ? col?.displayValue : col?.header)
                  " [pSortableColumn]="col.field">
                  <img src="../../../../../assets/img/general-entity/table-sort.svg" alt="" />
                </div>

                <button type="button" class="box-bg" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="
                    cofiguration?.allowColumnFilter &&
                    !cofiguration?.filter?.filterFields?.includes(col?.displayValue ? col?.displayValue : col?.header)
                  ">
                  <em class="nh-icon nh-icon-filter-small"></em>
                </button>
                <mat-menu #menu="matMenu" class="ui-table-matmenu">
                  <div class="box-manu">
                    <div class="input-del">
                      <span><input class="input" placeholder="{{ labels?.Search_by }}" type="text"
                          [(ngModel)]="col.searchText" (click)="innerSearch($event)" #search /></span>
                      <div class="delete-icon" (click)="filterFunction(col, col?.searchText, 'Clear', col?.searchText)">
                        <em class="nh-icon nh-icon-trash01 cursor-pointer"></em>
                      </div>
                    </div>

                    <ul class="filter-list checkboxes">
                      <li (click)="filterFunction(col, col?.searchText, filter, null)" *ngFor="let filter of filters">
                        {{ filter }}
                      </li>
                    </ul>
                  </div>
                </mat-menu>

                <div class="box-bg" *ngIf="
                    (cofiguration?.allowColumnSearch == true ||
                      (!isallowColumnSearch && cofiguration?.allowColumnFilter)) &&
                    cofiguration?.search?.searchPlacement !== 'top' &&
                    !cofiguration?.search?.searchFields?.includes(col?.displayValue ? col?.displayValue : col?.header)
                  ">
                  <img src="../../../../../assets/img/general-entity/table-serach.svg"
                    (click)="searchClicked($event, index)" alt="" />
                </div>
                <div class="search-inputs" *ngIf="
                    (cofiguration?.allowColumnSearch == true ||
                      (!isallowColumnSearch && cofiguration?.allowColumnFilter)) &&
                    !cofiguration?.searchFields?.includes(col?.displayValue ? col?.displayValue : col.header)
                  ">
                  <div class="box-manu" *ngIf="isSearchClicked && index == selectedIndex && !col?.searchText">
                    <input class="input" placeholder="{{ labels?.Search_by }}" type="text" [(ngModel)]="searchValue"
                      (ngModelChange)="searchQuery.next([$event, col])" />
                  </div>
                  <img src="../../../../../assets/img/general-entity/close-icon.svg" (click)="closeInput()"
                    *ngIf="isSearchClicked && index == selectedIndex" alt="" />
                </div>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
          <tr class="row-outline" [ngStyle]="{
              color:
                rowIndex % 2 == 0
                  ? oddRowHovered == rowIndex
                    ? tableConfiguration?.oddRow?.fontHover
                    : tableConfiguration?.oddRow?.fontColor
                  : evenRowHovered == rowIndex
                  ? tableConfiguration?.evenRow?.fontHover
                  : tableConfiguration?.evenRow?.fontColor,
              'font-weight':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.bold
                    ? 'bold'
                    : 'normal'
                  : tableConfiguration?.evenRow?.text?.bold
                  ? 'bold'
                  : 'normal',
              'font-style':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.italic
                    ? 'italic'
                    : 'normal'
                  : tableConfiguration?.evenRow?.text?.italic
                  ? 'italic'
                  : 'normal',
              'text-decoration-line':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.underLine
                    ? 'underLine'
                    : 'none'
                  : tableConfiguration?.evenRow?.text?.underLine
                  ? 'underLine'
                  : 'none',
              'font-size':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.fontSize + 'px'
                  : tableConfiguration?.evenRow?.text?.fontSize + 'px',
              'hover:background-color':
                rowIndex % 2 == 0 ? tableConfiguration?.oddRow?.colorHover : tableConfiguration?.evenRow?.colorHover,
              'hover:color':
                rowIndex % 2 == 0 ? tableConfiguration?.oddRow?.fontHover : tableConfiguration?.evenRow?.fontHover,
              'font-family':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.fontFamily
                  : tableConfiguration?.evenRow?.text?.fontFamily,
              'text-align':
                rowIndex % 2 == 0
                  ? tableConfiguration?.oddRow?.text?.alignment?.justify
                  : tableConfiguration?.evenRow?.text?.alignment?.justify
            }" (click)="clickOnRow($event, rowIndex)">
            <td
              *ngIf="!cofiguration?.hideSelectButton && (tableSelection !== 'single' || !cofiguration?.allowSelection)"
              class="w-50 text-center radiobox">
              <p-tableRadioButton *ngIf="tableSelection === 'single' && !cofiguration?.allowSelection"
                [disabled]="isInfo" (click)="selectedTransactions($event, rowIndex)"
                [value]="rowData"></p-tableRadioButton>
              <p-tableCheckbox *ngIf="tableSelection !== 'single'" class="checkbox" [disabled]="isInfo"
                (click)="selectedTransactions($event, rowIndex)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td *ngIf="cofiguration?.deleteRecord && listValues.length > 1" class="w-50 text-center nh-pl-8 nh-pr-8">
              <div class="span-minus" (click)="deleteEntityControl(rowIndex, rowData)">
                <mat-icon>delete</mat-icon>
              </div>
            </td>

            <td *ngIf="!cofiguration?.hideSerialNumber" class="w-70 text-center sno">
              {{
              customPagination
              ? (paginationDetails.pageNumber ? paginationDetails.pageNumber - 1 : 1 - 1) *
              paginationDetails.pageSize +
              rowIndex +
              1
              : rowIndex + 1
              }}
            </td>
            <!-- <td *ngFor="let col of columns" [ngClass]="{ hide: col?.form?.value?.isHidden === true }">
              <div class="applabel-0 splitwrap" [ngClass]="splitScreenclicked ? 'split-view' : ''">
                <div
                  [ngClass]="{
                    'attribte-opacity': rowData[col.header]?.isDisabled
                      ? rowData[col.header]?.isDisabled
                      : rowData[col.header]?.isNegative
                      ? rowData[col.header]?.isNegative
                      : rowData[col.header]?.isInfo && rowData[col.header]?.value != ''
                  }"
                  class="splitwrap-info"
                >
                  <ng-container
                    dynamicField
                    [formData]="rowData[col.header]"
                    [group]="rowData[col.header + 'form']"
                    [isTableConfig]="true"
                    [hideLabels]="hideLabels"
                    (dateChanged)="dateChnagedEvent($event)"
                    (chipDataChanged)="selectedTransactions($event, rowIndex, true)"
                    [isOnSelectSubmit]="
                      (tableSelection === 'single' && tableSettings?.submitOnSelection) ||
                      (cofiguration?.hideSelectButton && tableSettings?.submitOnSelection)
                    "
                  >
                  </ng-container>
                  <div
                    *ngIf="
                      rowData[col.header].inputType === 'image' ||
                      rowData[col.header].inputType === 'video' ||
                      rowData[col.header].inputType === 'file'
                    "
                    class="split-radio"
                  >
                    <mat-radio-group disabled>
                      <mat-radio-button value="1" (click)="openpopup(rowData, rowData[col.header].value)"
                        >popup</mat-radio-button
                      >
                      <mat-radio-button value="2" (click)="newtab(rowData, rowData[col.header].value)"
                        >newtab</mat-radio-button
                      >
                      <mat-radio-button
                        value="3"
                        (click)="splitScreen(rowData[col.header].inputType, rowData[col.header].value)"
                        >splits Screen</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </td> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="summary" *ngIf="tableDescription?.footerDescription">
          <div [ngStyle]="{
              'background-color': tableConfiguration?.description?.footer?.style?.backgroundColor,
              color: tableConfiguration?.description?.footer?.style?.fontColor,
              'font-weight': tableConfiguration?.description?.footer?.text?.bold ? 'bold' : 'normal',
              'font-style': tableConfiguration?.description?.footer?.text?.italic ? 'italic' : 'normal',
              'text-decoration-line': tableConfiguration?.description?.footer?.text?.underLine ? 'underline' : 'none',
              'font-size': tableConfiguration?.description?.footer?.text?.fontSize + 'px',
              'font-family': tableConfiguration?.description?.footer?.text?.fontFamily,
              'text-align': tableConfiguration?.description?.footer?.text?.alignment
            }">
            <span>{{ tableDescription?.footerDescription }}</span>
          </div>
        </ng-template>
      </p-table>
    </div>

    <div class="splitscreen-modal-img splitscreen-modal-gap">
      <div *ngIf="rowsdataInputType === 'image'" class="h-100">
        <img [src]="splitViewData[0]" alt="image3434" />
      </div>
      <div *ngIf="rowsdataInputType === 'video'" class="h-100">
        <video controls autoplay>
          <source [src]="splitViewData[0]" type="video/mp4" />
        </video>
      </div>
      <div *ngIf="rowsdataInputType === 'file'" class="h-100">
        <iframe [src]="splitViewData[0] | safeResourceUrl"></iframe>
      </div>
    </div>
  </div>
</ng-template>

<p-dialog [(visible)]="displayBasic2">
  <ng-template pTemplate *ngTemplateOutlet="popuptbl"> </ng-template>
</p-dialog>