import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CduiBaseService } from '../cdui-base/cdui-base.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewSolutionEndpointService extends CduiBaseService {
  advSearchFilterStatusSubject = new Subject();
  advSearchFilterStatus$ = this.advSearchFilterStatusSubject.asObservable();

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  setAdvSearchFilterStatus(data: any) {
    this.advSearchFilterStatusSubject.next(data);
  }

  /**
   * Gets calendar events
   * @param payload
   * @returns
   */
  getCalendarEvents(payload: any, isForDropdown: boolean, queryParamsForFilter?: any) {
    /* istanbul ignore next */
    let url = `${this.api?.fetchCalendarEvents}`;
    if (queryParamsForFilter && queryParamsForFilter?.eventType && queryParamsForFilter?.title) {
      url = url + `?title="${queryParamsForFilter.title}eventType=${queryParamsForFilter.eventType}`;
    } else if (queryParamsForFilter && queryParamsForFilter?.eventType) {
      url = url + `?eventType=${queryParamsForFilter.eventType}`;
    } else if (queryParamsForFilter && queryParamsForFilter?.title) {
      url = url + `?title=${queryParamsForFilter.title}`;
    }

    return this.http.post(url, payload);
  }

  //Scheduler API
  scheduleCalendarEvent(payload: any) {
    const url = `${this.api?.calendarScheduler}`;
    return this.http.post(url, payload);
  }

  updateCalendarEvent(payload: any) {
    const url = `${this.api?.calendarScheduler}`;
    return this.http.put(url, payload);
  }

  deleteCalendarEvent(payload: any) {
    const url = `${this.api?.calendarScheduler}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    return this.http.delete(url, options);
  }

  getCalendarAvailability(payload: any) {
    const url = `${this.api?.calendarSlots}?configEntityName=${payload?.outputEntityName}_Configuration&entityNameMapping=${payload?.outputEntityName}`;
    return this.http.post(url, payload);
  }

  getTypeAheadSearcheResults(payload: any) {
    return this.http.post<any>(`${this.api?.typeAheadSearch}`, payload);
    // return this.http.get<any>(
    //   `${this.api?.typeAheadSearch}${query}&status=DRAFT&status=READY&status=REJECTED&status=PUBLISHED&status=APPROVED`
    // );
  }

  getTypeAheadSearcheResultsNSLLibrary(query: string) {
    return this.http.get<any>(`${this.api?.typeAheadSearchNSLLibrary}${query}&status=PUBLISHED`);
  }

  getSearchResults(payload: any) {
    return this.http.post<any>(`${this.api?.getSearchResults}`, payload);
  }

  getAdvTypeAheadSearcheResultsNSLLibrary(payload: any) {
    return this.http.post<any>(`${this.api?.advTypeAheadSearchNSLLibrary}`, payload);
  }

  viewAllSearchResults(payload: string) {
    return this.http.post<any>(`${this.api?.viewAllSearchResults}`, payload);
    // return this.http.get<any>(
    //   `${this.api?.viewAllSearchResults}${query}&recordType=${record}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=DRAFT&status=READY&status=REJECTED&status=PUBLISHED&status=APPROVED`
    // );
  }

  getCardIds(data: any) {
    return this.http.post<any>(`${this.api?.getCardIds}`, data);
  }

  // saveCU(cu: any) {
  //   return this.http.post(environment.tfCoreUrl + '/CU', cu);
  // }

  // getAllCus(pagenum: number, searchCriteria: string) {
  //   return this.http.get(
  //     environment.tfCoreUrl + '/CU/matching?pageNo=' + pagenum + '&pageSize=10&searchCriteria=' + searchCriteria
  //   );
  // }

  // saveGsi(body: any) {
  //   return this.http.post(environment.tfCoreUrl + '/GSI', body);
  // }
}
