import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { updateBetSelectedItems } from '@common-services';
import {
  AlertService, AttributeEndpointService,
  ChangeUnitEventComponent,
  Entity, EntityFacadeService,
  EventsFacadeService, GsiFacadeService,
  NodeGsiFacadeService,
  TranslatorService,
  checkDataTypeInCondition, checkInputField, defaultEditorConfig, displayDateDefaultValue,
  entityMapper,
  upperVsLowerBetData, validateDefaultValue
} from '@common-services';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NodeChangeUnitFacadeService } from '../../node-services/node-change-unit-facade.service';
import { NodeEntityFacadeService } from '../../node-services/node-entity-facade.service';
import { NodeCompareComponent } from '../node-compare/node-compare.component';
@Component({
  selector: 'app-node-attribute-edit',
  templateUrl: './node-attribute-edit.component.html',
  styleUrls: ['./node-attribute-edit.component.scss'],
})
export class NodeAttributeEditComponent implements OnInit, OnDestroy {
  @Input() tempGsiObj: any;
  @Input() configurationData: any;
  @Output() closeattribute: EventEmitter<any> = new EventEmitter<any>();
  close: boolean = false;
  ngUnsubscribe = new Subject();
  step = 0;
  searchText: string = '';
  attributeTypes: any;
  attributeValidations: any;
  dataType: any;
  UiValidatorDropdown: any = [];
  selectedValidatorsList: any = [];
  iseditAttr: any = true;
  chipImageUrl: any = [];
  fileDetails: any;
  addButtonClicked: boolean;
  disableAddButton: boolean;
  imageUrlwithChip: string;
  selectedUiValidator: any;
  constraints: any[];
  attrDetails = this.formBuilder.group({
    defaultOption: [''],
    isMultiValue: [''],
    multidownload: false,
    unitType: [''],
    defaultValue: [''],
    constantChangeDriver: [''],
    constantChangeDriverValue: [''],
    attributeUiElement: [''],
  });
  showConditions: boolean = false;
  constantChangeDriverObj: any = {};
  labels: any;
  fileUrl: any;
  sourceValues: any = [];
  addEntityBtn: boolean = false;
  showPopup: boolean;
  strikeThrough:boolean = false;
  viewLink: boolean;
  selectorSeparated: string;
  showHelperTextIcon: boolean = false;
  format_Text: string;
  selectedDateFormat: string;
  countResetParameter: string;
  incremental_Id: any;
  format: string;
  selectedindex: any;
  separator: string;
  attrConfiguration: any = {};
  multiValue: any = false;
  entResponse: any;
  memberShipCriteriaOperators: any = ['<', '>', '=', '==', '!=', '<=', '>='];
  memberShipCriteriaOperatorsPrimaryKeySearch: string[] = ['<', '>', '=', '<=', '>=', 'begins_with', 'contains'];
  currentOperator: string = '';
  potentialityFlag: any;
  potentialityAttr: string;
  physicalLayer: any[];
  informationLayer: any[];
  triggerCESLayer: any[];
  selectedChangeUnit: any;
  changeUnit: any;
  memberAttrAdd: string;
  TxnRights: any = [];
  DesignRights: any = [];
  roles: any = [];
  currenttagToken: any;
  unitType: boolean = false;
  labelForPhyscialLayer = { name: 'physical Layer', label: 'physical Layer' };
  labelForInformationLayer = { name: 'information Layer', label: 'information Layer' };
  labelForTriggerCESLayer = { name: 'triggerCES Layer', label: 'triggerCES Layer' };
  attributeEvents = [
    { name: 'SelectEvent', value: '', label: 'SelectEvent' },
    { name: 'ON_FOCUS', value: 'ON_FOCUS', label: 'OnFocus' },
    { name: 'ON_CHANGE', value: 'ON_CHANGE', label: 'OnChange' },
    { name: 'ON_LOAD', value: 'ON_LOAD', label: 'OnLoad' },
    { name: 'ON_SELECT', value: '', label: 'OnSelect' },
  ];
  showPublishedGsi: boolean = false;
  publishedGsi: any;
  eventGsi: any;
  eventType: string = '';
  eventCuList: any[] = [];
  selectedEventIndex: number = -1;
  showReferenceEntity: boolean = true;
  entitySearchText: string = '';
  selectedEntites: any[];
  searchEntityText: Subject<boolean> = new Subject();
  detectGsiSearchText: Subject<boolean> = new Subject();
  gsiSearchText: string = '';
  EventRef: 'GSI' | 'EntityReference' | '';
  eventsPhysicalLayer: any[] = [];
  constantChangeDrivers: any = {};
  memberShipFlagsArr: boolean = false;
  memberShipAttr: string = '';
  memberShipAttrWithId: string = '';
  currentOp: string;
  isSpecialFeature: boolean;
  specialFeatureIndex: number = -1;
  slotIndex: number = 0;
  CuIndex: number;
  specialFeatureProperties: any;
  sourceFormulaAttr: string = '';
  targetFormulaAttr: string = '';
  operatorFormula: string = '';
  selectSource: boolean = false;
  selectTarget: boolean = false;
  selectedOperator: string = '';
  showOperatorBox: boolean = false;
  conditionOperators: string[] = ['<', '>', '=', '!='];
  editFileUrl: any;
  entityAttributeList: any;
  entityMap: Map<string, any> = new Map<string, any>();
  fromNestedAttribute: boolean = false;
  selectedChangedriverOptAttr: any = [];
  SelectedalternativeAttr: any;
  altAttr: any = [];
  optChangeDrivers: any = ['OPTIONAL', 'NEGATIVE', 'ALTERNATIVE', 'HIDDEN', 'NONE'];
  mobileNumberFormat: any = [
    { displayValue: 'INDIA', actualValue: '+91' },
    { displayValue: 'USA', actualValue: '+1' },
    { displayValue: 'MEXICO', actualValue: '+52' },
  ];
  dateTabConfig = {
    maxNumberOfTabs: 3,
    style: 3,
    hideBlanks: false,
    showPredates: false,
    maxNumberOfPreDates: 0,
  };
  selectedMobileNumberFormat: any = { displayValue: 'INDIA', actualValue: '+91' };
  attributeType: any;
  currentUielement: any;
  currentCU: any;
  allDefaultValues: any = [];
  dateTypeDefaultValue: any; // For date, datetime, dateRange
  durationDefaultValue: any = {
    days: null,
    hours: null,
    minutes: null,
  }; // For duration
  defaultValue: any = ''; // Common default value
  multiDefaultValue: boolean = false;
  isMultiFileDownload: boolean = false;
  dateTypeUiControls: any = ['date', 'datetime', 'dateRange', 'time', 'yearMonth', 'year']; // For date type UI controls
  targetValue = 100;
  dateFormatType: string = 'MM/dd/yyyy';
  timeFormatType: string = '24-hr';
  yearMonthFormatType: string = 'yyyy-MM';
  EditorConfig: AngularEditorConfig = JSON.parse(JSON.stringify(defaultEditorConfig));
  booleanDefaultValue = false; // For Boolean
  uiControlList = [
    'boolean',
    'date',
    'datetime',
    'dateRange',
    'duration',
    'editor',
    'time',
    'yearMonth',
    'year',
    'basicSlider',
    'inputSlider',
    'rangeSlider',
    'stepSlider',
    'verticalSlider',
    'MMILocation',
  ]; // For seperate UI controls list apart from normat text value
  excludeUiControlList = [
    'file',
    'image',
    'audio',
    'video',
    'password',
    'encrypttext',
    'currentdate',
    'currentdatetime',
    'qrScanner',
    'qrDecoder',
    'scheduler',
    'signature',
    'scormProgress',
    'webex',
    'location',
    'videoRecording',
    'audioRecording',
    'capturingImage',
    'list',
  ]; // Exclude UI controls list
  sliderStepValue: number;
  lowerBetProperties: any = { defaultValue: [], constraints: [] };
  UpperBetProperties: any = { defaultValue: [], constraints: [] };
  betLevel: boolean = true;
  betLevelDefault: boolean = true;
  levelList: any[] = ['Basic', 'Entity'];
  compareDef: boolean = false;
  compareVal: boolean = false;
  propertyType: string;
  dialogRef: any;
  translationType: string = 'NONE';
  translationTypeArray: any = ['TRANSLATE', 'TRANSLITERATE', 'NONE'];
  helperTextString: string = '';
  helperTextPostion: string = '';
  helperTextColor: string = '';
  helperTextFont: string = '';
  timeLimit: any = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  singleSelection: any = [
    'radiobutton',
    'dropdown',
    'date',
    'datetime',
    'chipsingleselection',
    'clickable',
    'daterange',
    'time',
    'year',
  ];
  toggleCUSubmit: boolean = false;
  hideSearchLocation: boolean = false;
  isReadOnly: boolean = false;
  showDataAsTab: boolean = false;
  hideSearchBar: boolean = false;
  copyLink: boolean = false;
  attributeMapping: boolean = false;
  attrPath: string = '';
  displayText: string = '';
  showHorizontalOptions: boolean = false;
  hideDeletion: boolean = false;
  hideOpenInNewTab: boolean = false;
  hidePreview: boolean = false;
  hideTooltip: boolean = false;
  mmiDestination: string = '';
  mmiDestinationLon: string = '';
  mmiDestinationLat: string = '';
  mmiDefaultLon: string = '';
  mmiDefaultLat: string = '';
  eachImageUrl: any = [];
  imgUrlWithLabel: string;
  currentEntity: any;
  currentAttr: any;
  percentageType: string = 'percentage';
  designStyleList: any = [
    { displayValue: '3 Days ', actualValue: 3 },
    { displayValue: '5 Days ', actualValue: 5 },
    { displayValue: '7 Days ', actualValue: 7 },
    { displayValue: '14 Days ', actualValue: 14 },
    { displayValue: '30 Days ', actualValue: 30 },
  ];
  currencyOptions: any[] = [];

  constructor(
    private entityFacadeService: EntityFacadeService,
    private attributeEndpointService: AttributeEndpointService,
    private entityFacade: EntityFacadeService,
    private changeunitFacade: NodeChangeUnitFacadeService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService,
    private nodeEntityFacadeService: NodeEntityFacadeService,
    private gsiFacade: NodeGsiFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private alertService: AlertService,
    private eventsFacadeService: EventsFacadeService,
    public dialog: MatDialog
  ) {
    this.detectLanguageChange();
    this.detectEntResponse();
    this.getDCDStream();
    this.loadEntities();
    this.loadEntitiesOnSearch();
    this.loadGSI(0);
    this.loadSearchedGsi();
    this.getTagToken();
    this.DetectGetCuInAttrEdit();
    this.getValidations();
    this.detectEntityData();
    this.getAttributeComparisonData();
    this.detectCcdValue();
  }

  DetectGetCuInAttrEdit() {
    this.changeunitFacade.getCuAttrEdit$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.currentCU = res;
      }
    });
  }

  ngOnInit(): void {
    /*istanbul ignore next*/
    this.getUiControlDataType();
    if (this.configurationData.data.attr?.attributeType?.type == 'list') {
      this.multiDefaultValue = true;
    }
    /*istanbul ignore next*/
    if (this.configurationData) {
      const cuId = this.configurationData?.data?.currentcu?.data?.referencedChangeUnit;
      if (cuId) {
        this.changeunitFacade.getCuInAttrEdit(cuId);
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType) {
        this.getAttributeTypes(this.configurationData.data.attr.attributeType.uiElement.dataType);
      }
      if (this.configurationData?.isNested) {
        this.fromNestedAttribute = true;
      }
      /*istanbul ignore next*/
      if (this.configurationData.data?.attr?.nslAttributeMetaData) {
        /*istanbul ignore next*/
        this.selectedChangedriverOptAttr = this.configurationData?.data?.attr?.nslAttributeMetaData?.changeDriverTypes;
        /*istanbul ignore next*/
        this.SelectedalternativeAttr = this.configurationData?.data?.attr?.nslAttributeMetaData?.alternativeAttribute;
        /*istanbul ignore next*/
        if (this.configurationData?.data?.attr?.nslAttributeMetaData?.translationType == 'TRANSLATE') {
          this.translationType = 'TRANSLATE';
        } else if (this.configurationData?.data?.attr?.nslAttributeMetaData?.translationType == 'TRANSLITERATE') {
          this.translationType = 'TRANSLITERATE';
        } else {
          this.translationType = 'NONE';
        }
      }
      this.fetchAttributesOfEntity();
      this.configurationData.data.entity?.nslAttributes.forEach((attr: any) => {
        if (attr?.id != this.configurationData?.data?.attr?.id) {
          this.altAttr.push(attr);
        }
      });
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.properties) {
        this.configurationData.data.attr.attributeType.uiElement.properties = [];
      }
      this.dataType = this.configurationData?.data?.attr?.attributeType?.uiElement?.dataType;
      /* istanbul ignore next */
      if (this.configurationData?.data?.attr?.conditionalPotentiality?.length > 0) {
        this.potentialityFlag = 'true';
      } else {
        this.potentialityFlag = 'false';
      }
      this.entityFacade.getUIValidations();

      let foundCu;
      if (this.configurationData?.data?.isNestedCuAttr) {
        foundCu = this.configurationData?.data?.currentcu?.data?.nestedCU;
      } else {
        const currentCUReferencedChangeUnit = this.configurationData?.data?.currentcu?.data?.dsdRefId
          ? this.configurationData.data.currentcu.data.dsdRefId
          : this.configurationData?.data?.currentcu?.referencedChangeUnit;

        /*istanbul ignore next*/
        if (currentCUReferencedChangeUnit) {
          /*istanbul ignore next*/
          foundCu = this.tempGsiObj.solutionLogic?.find(
            (x: any) => x.referencedChangeUnit == currentCUReferencedChangeUnit
          );
        }
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'labelwithimage') {
        let attrConfig = JSON.parse(this.configurationData?.data?.attr?.configuration);
        let defaultValue = attrConfig?.imgUrlWithLabel;
        /* istanbul ignore next */
        this.editFileUrl = this.IsJsonString(defaultValue) ? JSON.parse(defaultValue) : null;
      }
      if (
        this.configurationData?.data?.attr?.configuration &&
        typeof this.configurationData?.data?.attr?.configuration == 'string'
      ) {
        let attrConfig = JSON.parse(this.configurationData?.data?.attr?.configuration);
        this.helperTextString = attrConfig?.helpertext;
        this.helperTextColor = attrConfig?.helperTextColor;
        this.helperTextFont = attrConfig?.helperTextFont;
        this.helperTextPostion = attrConfig?.helperTextPosition;
        this.showHelperTextIcon = attrConfig?.showHelperTextIcon;


        this.attrDetails.patchValue({
          helpertext: attrConfig?.helpertext,
          helperTextPosition: attrConfig?.helperTextPosition,
          helperTextFont: attrConfig?.helperTextFont,
          helperTextColor: attrConfig?.helperTextColor,
          showHelperTextIcon: attrConfig?.showHelperTextIcon,
        });
        /*istanbul ignore next*/
        this.attrDetails.patchValue({
          imgUrlWithLabel: this.attrConfiguration?.imgUrlWithLabel,
        });
        this.isReadOnly = attrConfig?.isReadOnly ? true : false;
        this.showDataAsTab = attrConfig?.showDataAsTab ? true : false;
        this.dateTabConfig = attrConfig?.dateTabConfig ? attrConfig?.dateTabConfig : this.dateTabConfig;
        this.hideSearchBar = attrConfig?.hideSearchBar ? true : false;
        this.copyLink = attrConfig?.copyLink ? true : false;
        this.attributeMapping = attrConfig?.attributeMapping ? true : false;
        this.attrPath = attrConfig?.attrPath;
        this.displayText = attrConfig?.displayText;
        this.hideDeletion = attrConfig?.hideDeletion;
        this.hidePreview = attrConfig?.hidePreview;
        this.hideOpenInNewTab = attrConfig?.hideOpenInNewTab;
        this.showHorizontalOptions = attrConfig?.showHorizontalOptions;
        this.hideTooltip = attrConfig?.hideTooltip;
        this.showPopup = attrConfig?.showPopup;
        this.strikeThrough = attrConfig?.strikeThrough;
        if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation') {
          this.mmiDestination = this.attrConfiguration?.mmiDestination ? this.attrConfiguration.mmiDestination : '';
          if (this.mmiDestination && this.mmiDestination !== '') {
            this.mmiDestinationLat = JSON.parse(this.mmiDestination)?.latitude;
            this.mmiDestinationLon = JSON.parse(this.mmiDestination)?.longitude;
          }
        }
        this.selectedMobileNumberFormat = attrConfig?.mobileFormat
          ? attrConfig?.mobileFormat
          : { displayValue: 'INDIA', actualValue: '+91' };
        if (
          this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'videoRecording' ||
          this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'currency'
        ) {
          this.timeLimit = attrConfig;
        }
      }
      /* istanbul ignore next */
      if (this.configurationData?.data?.attr && this.configurationData?.data?.attr?.configuration) {
        let res: any;
        res = JSON.parse(this.configurationData?.data?.attr?.configuration);
        if (res?.submitCU == true) {
          this.toggleCUSubmit = true;
        }
        this.isMultiFileDownload = res?.multipleFileDownload;
      }

      if (foundCu) {
        this.changeUnit = foundCu;
        this.slotIndex = this.changeUnit.layers?.findIndex((layer: any) => {
          return layer.type == this.configurationData.data.entity.layerType;
        });

        /*istanbul ignore next*/
        this.constantChangeDriverObj = {
          slotId: this.configurationData.data?.entity?.id,
          slotIndex: this.configurationData.data.entityIndex,
          entity: this.configurationData.data.entity,
          attributeId: this.configurationData.data?.attr?.id,
          layerType: this.configurationData.data.entity?.layerType,
        };

        let layerShortCut = this.getLayerId(this.constantChangeDriverObj);
        // this.constantChangeDrivers = [];
        // DATA[i].constantChangeDriverEntity = false;
        /* istanbul ignore next */
        if (this.changeUnit?.slotItemProperties && this.changeUnit?.slotItemProperties?.[layerShortCut]) {
          this.constantChangeDrivers = this.changeUnit.slotItemProperties;
          /* istanbul ignore next */
          let changeDriverCCDValue = this.changeUnit.slotItemProperties?.[
            layerShortCut
          ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.[0]?.txnNslAttribute?.find(
            (x: any) => x?.dsdId === this.configurationData?.data?.attr?.dsdId
          );
          /*istanbul ignore next*/
          if (changeDriverCCDValue?.values) {
            this.attrDetails.patchValue({
              constantChangeDriverValue: changeDriverCCDValue?.values ? changeDriverCCDValue.values[0] : false,
              constantChangeDriver: true,
            });
          }
        }

        this.eventCuList = foundCu?.eventCUList ? foundCu.eventCUList : [];
        /*istanbul ignore next*/
        if (this?.eventCuList?.length > 0) {
          this.eventCuList?.forEach((event: any) => {
            /*istanbul ignore next*/
            if (event.isGsi == true) {
              event.eventRef = 'GSI';
            } else {
              this.entityMap.set(event?.referenceEntityInfo?.referenceEntityId.toString(), {});
              event.eventRef = 'EntityReference';
              if (event?.referenceEntityInfo?.membershipCriterias?.length > 0) {
                this.showConditions = true;
                event?.referenceEntityInfo?.membershipCriterias?.forEach((res: any) => {
                  res.displayCondition = `${res.referenceAttributeContextualName}${res.membershipContextualName}`;
                });
              }
            }
          });
        }
        console.log(this.UiValidatorDropdown, 'ui');
        // this.callAttributeComparisonData('DEFAULT_VALUE');
        // this.callAttributeComparisonData('ATTRIBUTE_CONSTRAINTS');
      }
      this.loadEventCuListEntities();
      /*istanbul ignore next*/
      let physicalLayerIndex: number;
      if (this.configurationData?.data?.isNestedCuAttr) {
        let currentCu = this.configurationData?.data?.currentcu?.data?.nestedCU;
        physicalLayerIndex = currentCu.layers?.findIndex((layer: any) => {
          return layer.type == 'physical';
        });
        if (physicalLayerIndex != -1) {
          this.eventsPhysicalLayer = currentCu?.layers[physicalLayerIndex].participatingItems;
        }
      } else {
        physicalLayerIndex = this.configurationData.data.currentcu?.data?.cuLayers.findIndex((layer: any) => {
          return layer.type == 'physical';
        });
        /*istanbul ignore next*/
        if (physicalLayerIndex != -1) {
          this.eventsPhysicalLayer = this.configurationData.data.currentcu?.data?.cuLayers[
            physicalLayerIndex
          ].participatingItems;
        }
      }
      this.detectSpecialFeatures();
      this.getLayersData();
    }
    this.getLayersData();
    //irdr attributes
    /*istanbul ignore next*/
    this.DesignRights = this.configurationData?.data?.attr?.designTimeRights;
    /*istanbul ignore next*/
    this.TxnRights = this.configurationData?.data?.attr?.txnTimeRights;
    /*istanbul ignore next*/
    this.configurationData?.data?.attr?.designTimeRights?.forEach((role: any) => {
      /*istanbul ignore next*/
      this.roles.push({
        name: role.name ? role.name : role.rightHolderName,
        id: role.rightHolderId,
        rightHolderId: role.rightHolderId,
      });
    });
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.memberShipName) {
      this.memberShipFlagsArr = true;
    }
    this.getDesignRightsIndex(this.DesignRights);
    this.getTxnRightsIndex(this.TxnRights);
    this.detectAttributeConfig();
    if (this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues?.length > 0) {
      this.sourceValues = this.configurationData?.data?.attr?.attributeType?.extendedProperties?.sourceValues;
    }
    this.editAttributes();
  }

  loadEventCuListEntities(): void {
    this.nodeEntityFacadeService.entitydetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((entity: Entity) => {
      this.entityMap.set(entity.id.toString(), entity);
    });
    for (let key of this.entityMap.keys()) {
      this.nodeEntityFacadeService.getEntityDeatilsbyId(key);
    }
  }

  fetchAttributesOfEntity() {
    this.nodeEntityFacadeService
      .getEntityDeatils(this.configurationData.data.entity.id, false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        let entity = entityMapper(res.result);
        this.entityAttributeList = entity.nslAttributes;
      });
  }
  setStep(index: number) {
    this.step = index;
  }

  onChangeOption(event: any, index: any) {
    /*istanbul ignore next*/
    if (this.selectedChangedriverOptAttr !== 'none') {
      this.configurationData['nslAttributeMetaData'] = { changeDriverTypes: this.selectedChangedriverOptAttr };
    }
  }
  /* optional change drivers attribute level */
  /* optional change drivers attribute level */
  onChangeOptionAttr(event: any, index: any) {
    /* istanbul ignore if */
    const selectedItems = updateBetSelectedItems(event, this.selectedChangedriverOptAttr);
    this.selectedChangedriverOptAttr = selectedItems;
    this.configurationData.data.attr['nslAttributeMetaData'] = { changeDriverTypes: selectedItems };
    if (selectedItems?.includes('ALTERNATIVE')) {
      this.configurationData.data.attr.nslAttributeMetaData = {
        changeDriverTypes: selectedItems,
        alternativeAttribute: this.SelectedalternativeAttr,
      };
    }
  }

  ChangeTranslationType(value: string) {
    this.translationType = value;
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr?.nslAttributeMetaData) {
      this.configurationData.data.attr.nslAttributeMetaData['translationType'] = value;
    } else {
      this.configurationData.data.attr.nslAttributeMetaData = { translationType: value };
    }
  }
  getDCDStream() {
    this.changeunitFacade.dcd$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res && res.formulaId && res.cuType === 'membership') {
        this.memberShipAttr = res.formulaId;
        this.memberShipAttrWithId = res.formulaIdWithId;
        // this.updateAttrMemberShip(this.configurationData.data.attr);
      }
      if (res && res.formulaId && res.cuType === 'conditionalPotentiality') {
        this.potentialityAttr = res.formulaId;
        this.attributeType = res.attributeType;
        /*istanbul ignore next*/
        this.currentUielement = res?.attributeuiElement;
      }
    });
  }

  memberShipFlagsChange() {
    this.memberShipAttr = '';
    this.memberShipAttrWithId = '';
    this.currentOp = '';
    this.configurationData.data.attr.memberShipName = '';
    this.configurationData.data.attr.memberShip = '';
  }
  /*removing the conditional potentiality */
  //functionality moved to other component
  // removeConditionalCriteria(nslEntAttr: any, i: any) {
  //   nslEntAttr.conditionalPotentialityName.splice(i, 1);
  //   nslEntAttr.conditionalPotentiality.splice(i, 1);
  //   this.potentialityFlag = 'false';
  //   this.currentOperator = '';
  // }
  getDesignRightsIndex(role: any) {
    /*istanbul ignore next*/
    if (this.DesignRights && this.DesignRights?.length > 0 && role) {
      return this.DesignRights?.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.rightHolderId === role?.id
      );
    }
    /*istanbul ignore next*/
    return -1;
  }
  getTxnRightsIndex(role: any) {
    /*istanbul ignore next*/
    if (this.TxnRights && this.TxnRights?.length > 0 && role) {
      return this.TxnRights?.findIndex(
        (ele: any) => ele.rightHolderId === role.rightHolderId || ele.rightHolderId === role?.id
      );
    }
    /*istanbul ignore next*/
    return -1;
  }
  autoCheckRights(rights: any) {
    /*istanbul ignore next*/
    if (rights && (rights.decisionRight || rights.executionRight)) {
      rights.informationRight = true;
    }
  }
  menuLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuLeave(trigger);
    }, 500);
  }

  buttonEnter(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonEnter(trigger);
    });
  }

  buttonLeave(trigger: any) {
    setTimeout(() => {
      this.gsiFacadeService.menuButtonLeave(trigger);
    }, 520);
  }
  menuenter() {
    this.gsiFacadeService.menuenter();
  }
  showRights(i: any) {
    this.selectedindex = i;
  }

  removeAgent(i: any) {
    this.roles.splice(i, 1);
    this.configurationData?.data?.attr?.designTimeRights.splice(i, 1);
    this.configurationData?.data?.attr?.txnTimeRights.splice(i, 1);
    console.log(this.configurationData);
  }

  getAttributeTypes(dataType?: any) {
    this.attributeEndpointService
      .getAllAttributeTypes(this.searchText, 1, 100, dataType, false)
      .subscribe((res: any) => {
        this.attributeTypes = res.data;
      });
  }

  operator(op: any, attr: any) {
    this.currentOperator = op;
    attr.membershipInput = '';
    attr.membershipWithIdInput = '';
    attr.memberShipName = (this.currentOperator + this.memberShipAttr + attr.membershipInput).trim();
    attr.memberShip = (this.currentOperator + this.memberShipAttrWithId + attr.membershipInput).trim();
  }
  updateAttrMemberShip(attr: any) {
    /*istanbul ignore else*/
    if (attr && !attr.membershipInput) {
      attr.membershipInput = '';
      attr.membershipWithIdInput = '';
    }
    attr.memberShipName = (this.currentOp + this.memberShipAttr + attr.membershipInput).trim();
    attr.memberShip = (this.currentOp + this.memberShipAttrWithId + attr.membershipInput).trim();

    /*istanbul ignore else*/
    if (this.currentOp) {
      this.memberShipAttr = '';
      this.currentOp = '';
      this.configurationData.data.attr.membershipInput = '';
    }
  }
  addMembership(attr: any, index?: any) {
    /*istanbul ignore next*/
    if (!this.memberShipFlagsArr) {
      /*istanbul ignore next*/
      if (attr && attr.membershipInput) {
        attr.membershipInput = '';
        attr.memberShipName = '';
        attr.memberShip = '';
        this.currentOp = '';
      }
    }
  }
  chipImageUpload(event: any) {
    this.chipImageUrl.push(event?.contentUrl);
    this.fileDetails = event;
    this.imageUrlwithChip = JSON.stringify(this.chipImageUrl);
    this.addButtonClicked = false;
    if (this.chipImageUrl.length == this.sourceValues?.length) {
      this.disableAddButton = true;
    }
  }
  uploadMultipleFiles(event: any) {
    this.addButtonClicked = true;
  }
  deletedFileResponse(event: any) {
    this.fileUrl = event;
    this.chipImageUrl.length = this.chipImageUrl.length - 1;
  }
  /* adding condition at arrtibute level in cu */
  //functionality moved to other component
  // addConditionalpotentiality(attr: any, index?: any) {
  //   /*istanbul ignore next*/
  //   if (this.potentialityFlag === 'false') {
  //     /*istanbul ignore next*/
  //     if (attr && attr.PotentialityInput) {
  //       attr.PotentialityInput = '';
  //       this.currentOperator = '';
  //     }
  //   }
  // }
  //TO check target input
  checkTargetInput(event: any) {
    const query = { sourceAttributeuiElement: this.currentUielement };
    return checkInputField(event, query);
  }
  /* updating the conditions at attribute level in cu */
  updateConditionalPotentiality(attr: any) {
    /*istanbul ignore next*/
    const obj = {
      sourceAttributeType: this.attributeType,
      targetAttributeInput: attr.PotentialityInput,
    };
    attr.PotentialityInput = checkDataTypeInCondition(obj).targetAttributeInput;
    // if (attr.PotentialityInput !== '' && isNaN(attr.PotentialityInput)) {
    //   attr.PotentialityInput = `'${attr.PotentialityInput}'`;
    // }
    /*istanbul ignore next*/
    if (attr.conditionalPotentiality && attr.conditionalPotentiality?.length > 0) {
      attr.conditionalPotentiality.push(
        (this.potentialityAttr + ' ' + this.currentOperator + ' ' + attr.PotentialityInput).trim()
      );
    } else {
      attr.conditionalPotentiality = [
        (this.potentialityAttr + ' ' + this.currentOperator + ' ' + attr.PotentialityInput).trim(),
      ];
    }
    /*istanbul ignore next*/
    if (attr.conditionalPotentialityName && attr.conditionalPotentialityName?.length > 0) {
      attr.conditionalPotentialityName.push(
        (this.potentialityAttr + ' ' + this.currentOperator + ' ' + attr.PotentialityInput).trim()
      );
    } else {
      attr.conditionalPotentialityName = [
        (this.potentialityAttr + ' ' + this.currentOperator + ' ' + attr.PotentialityInput).trim(),
      ];
    }
    if (attr?.generalEntity) {
      attr.generalEntity.conditionalPotentiality = attr.conditionalPotentiality;
      attr.generalEntity.conditionalPotentialityName = attr.conditionalPotentialityName;
    }
    this.currentOperator = '';
    attr.PotentialityInput = '';
    this.potentialityAttr = '';
  }
  closeAttribute() {
    this.close = true;
    this.closeattribute.emit(this.close);
    this.changeunitFacade.closeActionpanel();
  }
  getLayersData() {
    /*istanbul ignore next*/
    if (this.configurationData) {
      this.selectedChangeUnit = this.configurationData;
      this.physicalLayer = [];
      this.informationLayer = [];
      this.triggerCESLayer = [];
      /*istanbul ignore next*/
      if (this.configurationData?.data?.currentcu?.data?.cuLayers?.length > 0) {
        this.configurationData?.data?.currentcu?.data?.cuLayers?.forEach((layer: any) => {
          /*istanbul ignore next*/
          if (layer?.participatingItems?.length > 0) {
            if (layer?.type == 'information') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                /*istanbul ignore next*/
                if (participatingItem && participatingItem?.item?.DATA?.name) {
                  entityIndex = this.informationLayer?.findIndex(
                    (ele: any) => ele.item.DATA.name === participatingItem.item.DATA.name
                  );
                }
                if (entityIndex === -1) {
                  this.informationLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'physical') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                let entityIndex;
                if (participatingItem && participatingItem.name) {
                  entityIndex = this.physicalLayer?.findIndex((ele: any) => ele.name === participatingItem.name);
                }
                if (entityIndex === -1) {
                  this.physicalLayer.push(participatingItem);
                }
              });
            } else if (layer?.type == 'triggerCES') {
              layer?.participatingItems?.forEach((participatingItem: any) => {
                this.triggerCESLayer.push(participatingItem);
              });
            }
          }
        });
      }
    }
  }

  selectAttrType(attrType: any, attr: any) {
    this.getAttributeTypes();
    attr['uiElement'] = {};
    /*istanbul ignore next*/
    let uiElement = this.attributeTypes?.find((x: any) => x.uiElement.toLowerCase() === attrType.value.toLowerCase());
    /*istanbul ignore next*/
    if (uiElement) {
      attr.attributeType.uiElement = uiElement;
      attr.attributeType.type = uiElement.dataType;
      this.dataType = uiElement.dataType;
    }
    if (
      uiElement.uiElement == 'checkbox' ||
      uiElement.uiElement == 'dropdown' ||
      uiElement.uiElement == 'radioButton' ||
      uiElement.uiElement == 'multiselectdropdown'
    ) {
      this.multiValue = true;
    } else {
      this.multiValue = false;
    }
    if (uiElement.uiElement == 'currency' || uiElement.uiElement == 'metric') {
      this.unitType = true;
    }
    this.getvalidationsList();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  fileUploadUrl(event: any) {
    this.fileUrl = event;
  }

  // adding updated attribute data to entity
  addDataToEntity() {
    /*istanbul ignore next*/
    if (this.attrDetails.valid) {
      this.attrDetails.value.size = 65535;
      this.configurationData.data.attr.attributeType.properties = {};
      this.configurationData.data.attr.attributeType.extendedProperties = {};
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'currency') {
        this.configurationData.data.attr.attributeType.properties.currencyType = this.attrDetails.value.unitType;
      }
      if (this.configurationData.data.attr.attributeType.uiElement.uiElement === 'metric') {
        this.configurationData.data.attr.attributeType.properties.unit = this.attrDetails.value.unitType;
      }
      //changing the time format
      if (this.attrDetails?.value?.attributeType === 'scheduler') {
        this.configurationData.data.attr.attributeType.properties = {};
      }
      if (this.attrDetails.value.attributeUiElement === 'location') {
        let attrConfig = JSON.parse(JSON.stringify(this.configurationData?.data?.attr?.configuration));
        this.attrConfiguration = { ...attrConfig, locationType: this.attrDetails?.value?.locationType };

        if (this.hideSearchLocation) {
          this.attrConfiguration = { ...this.attrConfiguration, hideSearchLocation: this.hideSearchLocation };
        } else if (!this.hideSearchLocation && this.attrConfiguration?.hideSearchLocation) {
          this.attrConfiguration.hideSearchLocation = false;
        }

        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      this.attrConfiguration = {
        ...this.attrConfiguration,
        isReadOnly: this.isReadOnly,
        submitCU: this.toggleCUSubmit,
      };
      this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      if (this.attrDetails?.value?.attributeUiElement == 'file') {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          hidePreview: this.hidePreview,
          hideDeletion: this.hideDeletion,
          hideOpenInNewTab: this.hideOpenInNewTab,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'MMILocation') {
        this.attrConfiguration = { ...this.attrConfiguration, mmiDestination: this.mmiDestination };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails?.value?.attributeUiElement === 'textarea' ||
          this.attrDetails?.value?.attributeUiElement === 'label' ||
          this.attrDetails?.value?.attributeUiElement === 'chipsingleselection' ||
          this.attrDetails?.value?.attributeUiElement === 'chipmultipleselection'
      ) {
        this.attrConfiguration = { ...this.attrConfiguration, hideTooltip: this.hideTooltip };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'html') {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          showPopup: this.showPopup,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'label') {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          strikeThrough: this.strikeThrough,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (
        this.attrDetails?.value?.attributeUiElement === 'dropdown' ||
        this.attrDetails?.value?.attributeUiElement === 'multiselectdropdown'
      ) {
        this.attrConfiguration = { ...this.attrConfiguration, hideSearchBar: this.hideSearchBar };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails?.value?.attributeUiElement === 'date' && this.showDataAsTab) {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          showDataAsTab: this.showDataAsTab,
          dateTabConfig: this.dateTabConfig,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails?.value?.attributeUiElement === 'hyperlink') {
        this.attrConfiguration = {
          ...this.attrConfiguration,
          copyLink: this.copyLink,
          displayText: this.displayText,
          attributeMapping: this.attributeMapping,
          attrPath: this.attrPath,
        };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails.value.attributeUiElement == 'mobilenumber') {
        this.attrConfiguration = { ...this.attrConfiguration, mobileFormat: this.selectedMobileNumberFormat };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails.value.attributeUiElement == 'radioButton') {
        this.attrConfiguration = { ...this.attrConfiguration, showHorizontalOptions: this.showHorizontalOptions };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrConfiguration) {
        this.attrConfiguration = { ...this.attrConfiguration, showHelperTextIcon: this.showHelperTextIcon };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrConfiguration) {
        this.attrConfiguration = { ...this.attrConfiguration, strikeThrough: this.strikeThrough };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      if (this.attrDetails?.value?.attributeUiElement == 'percentage') {
        this.attrConfiguration = { ...this.attrConfiguration, percentageType: this.percentageType };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.attrDetails?.value?.attributeUiElement == 'labelwithimage') {
        this.attrConfiguration = { ...this.attrConfiguration, imgUrlWithLabel: this.imgUrlWithLabel };
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.attrConfiguration);
      }
      if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'html') {
        if (this.showPopup) {
          this.configurationData.data.attr.attributeType.properties.showPopup = true;
        } else {
          this.configurationData.data.attr.attributeType.properties.viewLink = true;
        }
      }
      /* istanbul ignore next */
      if (
        this.attrDetails.value.attributeUiElement == 'audioRecording' ||
        this.attrDetails.value.attributeUiElement == 'videoRecording'
      ) {
        this.configurationData.data.attr['configuration'] = JSON.stringify(this.timeLimit);
      }
      this.appendUiAttributes();
      this.configurationData.data.attr.constraints = this.constraints;
    }
    /* istanbul ignore next */
    this.timeLimit = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    this.sourceValues = [];
    this.iseditAttr = false;
    this.fileUrl = undefined;
    this.selectedUiValidator = null;
    this.translationType = 'NONE';
  }
  /* istanbul ignore next */
  updateDataInNestedEntity(entity: any, parentEntity: any) {
    entity.nslAttributes.forEach((attr: any) => {
      if (attr?.generalEntity) {
        if (attr.generalEntity.name == parentEntity.name) {
          attr.generalEntity = parentEntity;
        } else {
          this.updateDataInNestedEntity(attr.generalEntity, parentEntity);
        }
      }
    });
  }
  saveEntitywithAttr() {
    if (
      this.configurationData?.data?.attr?.configuration &&
      typeof this.configurationData.data.attr.configuration !== 'string'
    ) {
      this.configurationData.data.attr.configuration = JSON.stringify(this.configurationData.data.attr.configuration);
    }
    /*istanbul ignore next*/
    this.constantChangeDriverObj = {
      slotId: this.configurationData?.data?.entity?.id,
      slotIndex: this.configurationData?.data.entityIndex,
      entity: this.configurationData?.data.entity,
      attributeId: this.configurationData?.data?.attr?.id,
      layerType: this.configurationData?.data.entity?.layerType
        ? this.configurationData.data.entity.layerType
        : this.configurationData?.data?.layerType,
    };
    /*istanbul ignore next*/
    this.constantChangeDriverObj.selected = this.attrDetails?.value?.constantChangeDriver;
    /*istanbul ignore next*/
    let trimmedCCDValue =
      typeof this.attrDetails?.value?.constantChangeDriverValue === 'string'
        ? this.attrDetails?.value?.constantChangeDriverValue.trim()
        : this.attrDetails?.value?.constantChangeDriverValue;
    /*istanbul ignore next*/
    // if (trimmedCCDValue !== '' && this.constantChangeDriverObj.selected) {
    this.constantChangeDriverObj.values =
      this.constantChangeDriverObj.selected == true ? [this.attrDetails?.value?.constantChangeDriverValue] : [''];
    if (this.constantChangeDriverObj.selected == true) {
      this.setConstantChangeDriver(this.constantChangeDriverObj);
    }

    // }
    this.removeEmptyCondition();
    this.configurationData.data.attr.constraints = this.constraints;
    if (!this.multiDefaultValue) {
      this.configurationData.data.attr.defaultValue = this.allDefaultValues[0];
    } else {
      this.configurationData.data.attr.defaultValue = JSON.stringify(this.allDefaultValues);
    }
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'file') {
      let attrConfig = JSON.parse(this.configurationData?.data?.attr?.configuration);
      attrConfig = {
        ...attrConfig,
        multipleFileDownload: this.isMultiFileDownload,
      };
      this.configurationData.data.attr['configuration'] = JSON.stringify(attrConfig);
    }
    if (
      this.helperTextPostion != '' ||
      this.helperTextString != '' ||
      this.helperTextFont != '' ||
      this.helperTextColor != ''
    ) {
      let attrConfig = JSON.parse(this.configurationData?.data?.attr?.configuration);
      attrConfig = {
        ...attrConfig,
        helpertext: this.helperTextString,
        helperTextPosition: this.helperTextPostion,
        helperTextFont: this.helperTextFont,
        helperTextColor: this.helperTextColor,
      };
      this.configurationData.data.attr['configuration'] = JSON.stringify(attrConfig);
    }
    
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement === 'html') {
      if (this.showPopup) {
        this.configurationData.data.attr.attributeType.properties.showPopup = true;
      } else {
        this.configurationData.data.attr.attributeType.properties.viewLink = true;
      }
    }
    if (this.imgUrlWithLabel != '') {
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imgUrlWithLabel: this.imgUrlWithLabel,
      };
    }
    if (this.imageUrlwithChip != '') {
      this.attrConfiguration = {
        ...this.attrConfiguration,
        imageUrlwithChip: this.imageUrlwithChip,
      };
    }
    this.attrConfiguration = {
      ...this.attrConfiguration,
      ...JSON.parse(this.configurationData.data.attr['configuration']),
    };
    this.addDataToEntity();
    this.removeEmptyCondition();
    if (this.fromNestedAttribute) {
      this.changeUnitSaveForNested(this.configurationData.data.saveData.entity);
    } else {
      /*istanbul ignore next*/
      const findAttrIndex = this.configurationData.data.entity.nslAttributes?.findIndex(
        (attr: any) => attr?.name == this.configurationData.data.attr?.name
      );
      this.configurationData.data.entity.nslAttributes[findAttrIndex] = this.configurationData.data.attr;
      /*istanbul ignore next*/
      this.configurationData.data.entity.nslAttributes?.forEach((attr: any, j: number) => {
        if (attr?.memberShip && attr?.memberShipName) {
          this.entityAttributeList[j].memberShip = attr?.memberShip;
          this.entityAttributeList[j].memberShipName = attr?.memberShipName;
        }
      });
      let entity: any = this.configurationData.data.entity;
      /*istanbul ignore next*/
      entity.nslAttributes = this.constantChangeDriverObj?.entity?.nslAttributes
        ? this.constantChangeDriverObj.entity.nslAttributes
        : this.entityAttributeList;
      let attrIndex: number = this.configurationData.data.entity.nslAttributes.findIndex((attr: any) => {
        return attr.name == this.configurationData.data.attr.name;
      });
      this.configurationData.data.entity.nslAttributes[attrIndex] = this.configurationData.data.attr;
      /* istanbul ignore next */
      this.changeUnitSave(this.configurationData.data.entity);
    }
    this.closeAttribute();
  }
  triggerOnchange(event: any, type: string, tabs: any) {
    this.dateTabConfig[tabs] = type == 'plus' ? this.dateTabConfig[tabs] + 1 : this.dateTabConfig[tabs] - 1;
  }
  changeUnitSaveForNested(entity: any) {
    /*istanbul ignore next*/
    if (entity) {
      /*istanbul ignore next*/
      if (this.configurationData?.data?.currentcu) {
        const configCuID = this.configurationData.data.currentcu?.data?.referencedChangeUnit
          ? this.configurationData.data.currentcu?.data?.referencedChangeUnit
          : this.configurationData.data.currentcu?.data?.id;

        let foundCu = { ...this.tempGsiObj.solutionLogic?.find((x: any) => x.referencedChangeUnit == configCuID) };
        foundCu.layers?.forEach((layer: any) => {
          layer.participatingItems.forEach((item: any) => {
            if (item.name == entity.name) {
              item.nslAttributes = entity.nslAttributes;
            }
          });
        });
        foundCu.tfId = foundCu.tfReferencedChangeUnit;
        foundCu.id = foundCu.referencedChangeUnit;
        this.changeunitFacade.finalCuSave(foundCu);
      }
    }
  }
  removeEmptyCondition() {
    /*istanbul ignore next*/
    this.eventCuList?.forEach((event: any) => {
      /*istanbul ignore next*/
      if (event?.referenceEntityInfo) {
        /*istanbul ignore next*/
        let l: number = event?.referenceEntityInfo?.membershipCriterias?.length - 1;
        /*istanbul ignore next*/
        let sol = event?.referenceEntityInfo?.membershipCriterias?.[l];
        /*istanbul ignore next*/
        if (
          !(
            sol?.membershipContextualId ||
            sol?.membershipContextualName ||
            sol?.referenceAttributeContextualName ||
            sol?.referenceAttributeId
          )
        ) {
          /*istanbul ignore next*/
          event?.referenceEntityInfo?.membershipCriterias?.splice(l, 1);
        }
      }
    });
  }

  changeUnitSave(res: any) {
    /*istanbul ignore next*/
    if (res) {
      let foundCu: any;
      /*istanbul ignore next*/
      if (this.configurationData?.data?.currentcu) {
        const configCuID = this.configurationData.data.currentcu?.data?.referencedChangeUnit
          ? this.configurationData.data.currentcu?.data?.referencedChangeUnit
          : this.configurationData.data.currentcu?.data?.id;
        if (this.configurationData?.data?.cu?.isSeperateCU) {
          foundCu = this.configurationData?.data?.cu;
          foundCu.description = foundCu.description?.replace(
            this.currenttagToken.token,
            this.configurationData?.data?.attr?.displayName
          );
          if (foundCu?.sentenceTags?.physical[this.currenttagToken?.index]?.token !== '') {
            foundCu.sentenceTags.physical[
              this.currenttagToken.index
            ].token = this.configurationData?.data?.attr?.displayName;
          }
        } else if (this.configurationData?.data?.isNested) {
          foundCu = { ...this.configurationData?.data?.cu };
          if (foundCu?.referencedChangeUnit) foundCu.id = foundCu.referencedChangeUnit;
        } else if (this.configurationData?.data?.isNestedCuAttr) {
          foundCu = this.configurationData?.data?.currentcu?.data?.nestedCU;
        } else {
          foundCu = { ...this.tempGsiObj.solutionLogic?.find((x: any) => x.referencedChangeUnit == configCuID) };
          this.currentCU.sentenceTags = foundCu?.sentenceTags;
          foundCu = { ...foundCu, ...this.currentCU };
        }

        /*istanbul ignore next*/
        if (foundCu) {
          foundCu.layers?.forEach((layer: any) => {
            layer.participatingItems?.forEach((entity: any, index: any) => {
              if (entity?.name == res?.name && layer?.type == this.configurationData?.data?.layerType) {
                entity.nslAttributes.forEach((attribute: any, attrIndex: any) => {
                  if (attribute?.name === this.configurationData.data.attr?.name) {
                    attribute.attributeType = this.configurationData.data.attr.attributeType;
                    attribute.conditionalPotentiality = this.configurationData.data.attr.conditionalPotentiality;
                    attribute.conditionalPotentialityName = this.configurationData.data.attr.conditionalPotentialityName;
                    attribute.nslAttributeMetaData = this.configurationData.data.attr?.nslAttributeMetaData;
                    attribute.memberShip = this.configurationData.data.attr.memberShip;
                    attribute.memberShipName = this.configurationData.data.attr.memberShipName;
                    attribute.configuration = this.configurationData.data.attr.configuration;
                    if (this.configurationData?.data?.attr) {
                      attribute.designTimeRights = this.configurationData?.data?.attr?.designTimeRights;
                      attribute.txnTimeRights = this.configurationData?.data?.attr?.txnTimeRights;
                      attribute.constraints = this.configurationData.data?.attr?.constraints;
                    }
                    if (this.configurationData.data.attr?.generalEntity) {
                      attribute.generalEntity.conditionalPotentiality = this.configurationData.data.attr?.generalEntity.conditionalPotentiality;
                      attribute.generalEntity.conditionalPotentialityName = this.configurationData.data.attr?.generalEntity.conditionalPotentialityName;
                    }
                  }
                });
              }
            });
          });
          foundCu?.layers?.forEach((entity: any) => {
            entity.participatingItems?.forEach((ent: any) => {
              ent.nslAttributes?.forEach((attr: any) => {
                if (attr.dsdId == this.configurationData?.data?.attr?.dsdId) {
                  if (attr.displayName != this.configurationData?.data?.attr?.displayName) {
                    foundCu.description = foundCu.description?.replace(
                      this.currenttagToken.token,
                      this.configurationData?.data?.attr?.displayName
                    );
                    if (foundCu?.sentenceTags?.physical[this.currenttagToken?.index]?.token !== '') {
                      foundCu.sentenceTags.physical[
                        this.currenttagToken.index
                      ].token = this.configurationData?.data?.attr?.displayName;
                    }
                  } else if (attr?.name != this.configurationData?.data?.attr?.name) {
                    foundCu.description = foundCu.description?.replace(
                      this.currenttagToken.token,
                      this.configurationData?.data?.attr?.name
                    );
                    if (foundCu?.sentenceTags?.physical[this.currenttagToken?.index]?.token !== '') {
                      foundCu.sentenceTags.physical[
                        this.currenttagToken.index
                      ].token = this.configurationData?.data?.attr?.name;
                    }
                  }
                }
              });
            });
          });
          /*istanbul ignore next*/
          if (!this.configurationData?.data?.cu?.isSeperateCU) {
            foundCu.layers?.forEach((layer: any) => {
              for (let i = 0; i < layer?.participatingItems?.length; i++) {
                if (
                  layer?.participatingItems[i]?.name == res?.name &&
                  layer?.participatingItems[i]?.participatingItemId == res?.participatingItemId
                )  {
                  if (layer?.participatingItems[i]?.changeDriverMetaData) {
                    res['changeDriverMetaData'] = layer.participatingItems[i].changeDriverMetaData;
                  }

                  let attribute = res.nslAttributes.find((attr: any, j: number) => {
                    if (attr?.name === this.configurationData.data.attr?.name) {
                      if (this.multiDefaultValue) {
                        layer.participatingItems[i].nslAttributes[j].defaultValue = JSON.stringify(
                          this.allDefaultValues
                        );
                      } else layer.participatingItems[i].nslAttributes[j].defaultValue = this.allDefaultValues[0];
                    }
                    return attr?.name === this.configurationData.data.attr?.name;
                  });
                  /*istanbul ignore next*/
                  let currentAttr = layer?.participatingItems[i]?.nslAttributes?.find((x: any) => {
                    return x?.name === this.configurationData.data.attr?.name;
                  });
                  currentAttr = attribute;
                  /*istanbul ignore next*/
                  if (currentAttr) {
                    currentAttr['nslAttributeMetaData'] = this.configurationData?.data?.attr?.nslAttributeMetaData;
                    currentAttr['defaultValue'] = this.configurationData?.data?.attr?.defaultValue;
                    console.log(currentAttr['defaultValue'], 'def');
                  }
                }
              }
            });
          }
          /*istanbul ignore next*/
          if (this.configurationData.data.cu?.actionLabel) {
            foundCu.actionLabel = this.configurationData.data.cu.actionLabel;
          }
          if (this.configurationData?.data?.cu?.isSeperateCU) {
            this.changeunitFacade.updateChangeUnit(foundCu, false, true);
          } else if (this.configurationData?.data?.isNestedCuAttr) {
            this.changeunitFacade.saveDraftCu(foundCu);
          } else {
            // foundCu.tfId = foundCu.tfReferencedChangeUnit;
            // foundCu.id = foundCu.referencedChangeUnit;
            if (!foundCu?.isReserved) {
              foundCu.slotItemProperties = this.constantChangeDrivers;
              if (!this.constantChangeDriverObj.selected) {
                let slotItemFlag = true;
                let layer = this.getLayerId(this.constantChangeDriverObj);
                let attr =
                  foundCu?.slotItemProperties?.[layer]?.propertyData?.CONSTANT?.changeDriverData?.DATA
                    ?.transEntityRecords?.[0]?.txnNslAttribute;
                attr?.forEach((res: any, ind: any) => {
                  if (res?.dsdId == this.constantChangeDriverObj?.attributeId) {
                    delete res.values;
                    delete res.properties;
                  }
                  if (res?.properties != null) {
                    slotItemFlag = false;
                  }
                });
                if (slotItemFlag) {
                  foundCu.slotItemProperties = {};
                }
                //foundCu.slotItemProperties = {};
              }
            } else if (foundCu?.isReserved) {
              foundCu.slotItemProperties = this.constantChangeDrivers;
              if (!this.constantChangeDriverObj.selected) {
                foundCu.slotItemProperties = {};
              }
            }

            this.changeunitFacade.finalCuSave(foundCu);
          }
          // this.saveGsi();
        }
      }
    }
  }
  // update the entity data in changeunit and gsi object
  detectEntResponse() {
    this.nodeEntityFacadeService.updateEntityResponse$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.entResponse = res;
      this.onChangeOptionAttr(event, 1);
      this.changeUnitSave(res);
    });
  }
  saveGsi() {
    this.gsiFacade.saveGsi(this.tempGsiObj);
  }
  loadGSI(index: number) {
    this.selectedEventIndex = index;
    this.gsiFacade.getGsiSuggestions(1, 5, '', true, true);
    this.gsiFacade.publishedGsi$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((gsiData: any) => {
      this.publishedGsi = gsiData;
      this.showPublishedGsi = true;
    });
  }
  loadSearchedGsi() {
    this.detectGsiSearchText.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: boolean) => {
      /*istanbul ignore next*/
      if (res) {
        this.gsiFacade.getGsiSuggestions(1, 5, this.gsiSearchText, true, true);
      }
    });
  }

  selectGsiForEvent(index: number, selectedEventIndex?: number) {
    this.eventGsi = this.publishedGsi[index];
    this.showPublishedGsi = false;
    this.eventCuList[selectedEventIndex].gsiName = this.publishedGsi[index].name;
    this.EventRef = '';
    /*istanbul ignore next*/
    this.eventCuList[selectedEventIndex].gsiId = this.eventGsi?.id;
    /*istanbul ignore next*/
    this.eventCuList[selectedEventIndex].dsdGsiId = this.eventGsi?.dsdId;
    /*istanbul ignore next*/
    let cuIndex = this.tempGsiObj.solutionLogic?.findIndex((cu: any) => {
      return cu.id == this.configurationData.data.currentcu.data.id;
    });
    /*istanbul ignore next*/
    if (cuIndex != -1) {
      this.tempGsiObj.solutionLogic[cuIndex].eventCUList = this.eventCuList;
    }
  }
  addMoreEvents() {
    // this.EventRef = 'GSI';
    // const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
    // /* istanbul ignore next */
    // this.eventCuList.push({
    //   changeDriverId: this.configurationData?.data?.attr?.tfId,
    //   eventType: 'SelectEvent',
    //   gsiId: '',
    //   eventRef: 'GSI',
    //   isGsi: true,
    //   ownerId: activeUser?.id,
    //   dsdGsiId: '',
    //   dsdChangeDriverId: this.configurationData?.data?.dsdId,
    // });

    console.log(this.configurationData);
    this.currentEntity = this.configurationData?.data?.entity;
    let currentAttr = this.configurationData?.data?.attr;
    currentAttr.entity = this.currentEntity;
    currentAttr.cuId =
      this.configurationData?.data?.currentcu && this.configurationData?.data?.currentcu?.referencedChangeUnit
        ? this.configurationData?.data?.currentcu?.referencedChangeUnit
        : this.configurationData?.data?.currentcu?.id;
    // attrindex missing
    let restrictOnFocus = ['typeahead', 'multiselectdropdown', 'dropdown'];

    currentAttr['events'] = JSON.parse(JSON.stringify(this.attributeEvents));
    /* istanbul ignore next */
    restrictOnFocus.forEach((uiControl) => {
      if (currentAttr.attributeType?.uiElement?.uiElement == uiControl) {
        currentAttr.events = currentAttr?.events.filter((ele: any) => ele.name !== 'ON_FOCUS');
      }
    });

    /* istanbul ignore next */
    if (this.configurationData?.data?.attr?.attributeType?.uiElement?.uiElement == 'clickable') {
      currentAttr.events = currentAttr?.attr?.events.map((event: any) => {
        if (event.name == 'ON_CHANGE') event.name = 'ON_CLICK';
        return event;
      });
    }

    currentAttr.events = currentAttr?.events?.map((event: any) => {
      event.value = event.name;
      return event;
    });


    let cuIndex = this.tempGsiObj?.solutionLogic?.findIndex((cu: any) => {
      return cu.id == this.configurationData?.data?.currentcu?.data?.id;
    });

    /* istanbul ignore next */
    let eventDialogRef = this.dialog.open(ChangeUnitEventComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: {
        currentAttr: this.configurationData?.data?.attr,
        changeUnit: this.configurationData?.data?.currentcu,
        tempGsiObj: this.tempGsiObj,
        sourceContextualId: this.getSlotID(),
        entityIndex: this.configurationData?.data?.entityIndex,
        eventCUList: this.tempGsiObj?.solutionLogic?.[cuIndex]?.eventCUList,
      },
    });
    /* istanbul ignore next */
    eventDialogRef?.afterClosed().subscribe((result: any) => {
      if (result?.eventCUList) {
        this.configurationData.data.currentcu.eventCUList = result.eventCUList;
        this.tempGsiObj.solutionLogic[cuIndex].eventCUList = result.eventCUList;
      }
      this.dialog.closeAll();
    });
  }

  getSlotID() {
    const layerID = this.configurationData?.data?.layerType == 'physical' ? 'PL' : this.configurationData?.data?.layerType == 'information' ? 'IL' : 'TL';
    let layerIndex = this.configurationData?.data?.currentcu?.layers?.findIndex((layer: any) => {
      return layer.type == this.configurationData?.data?.layerType;
    });
    let slot = this.configurationData?.data?.currentcu?.layers?.[layerIndex]?.participatingItems?.findIndex((x: any) => {
      return x?.participatingItemId == this.configurationData?.data?.entity?.participatingItemId;
    });
     /* istanbul ignore else */
    if (slot != -1) return layerID + '.' + 'SL' + this.getIndexPadding(slot + 1);
    else return '';
  }

  switchRefernce(index: number) {
    const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
    if (this.eventCuList[index].eventRef == 'EntityReference') {
      this.EventRef = 'EntityReference';
      /*istanbul ignore next*/
      this.eventCuList[index] = {
        changeDriverId: this.configurationData?.data?.attr?.tfId,
        dsdChangeDriverId: this.configurationData?.data?.dsdId,
        eventType: this.eventCuList[index]?.eventType,
        isGsi: false,
        eventRef: 'EntityReference',
        referenceEntityInfo: {
          attributeReferences: [],
          dsdReferenceEntityId: '',
          membershipCriterias: [],
          referenceEntityId: 0,
        },
      };
    } else {
      this.EventRef = 'GSI';
      /* istanbul ignore next */
      this.eventCuList[index] = {
        changeDriverId: this.configurationData?.data?.attr?.tfId,
        eventType: this.eventCuList[index]?.eventType,
        gsiId: '',
        isGsi: true,
        ownerId: activeUser?.id,
        dsdGsiId: '',
        eventRef: 'GSI',
        dsdChangeDriverId: this.configurationData?.data?.dsdId,
      };
    }
  }

  loadEntities() {
    this.nodeEntityFacadeService.getEntitiesForEvents(1, 10, '');
    this.nodeEntityFacadeService.eventEntityList$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((eventEntityList: any) => {
        /*istanbul ignore next*/
        this.selectedEntites = eventEntityList?.result?.data;
      });
  }

  loadEntitiesOnSearch() {
    this.searchEntityText.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.nodeEntityFacadeService.getEntitiesForEvents(1, 10, this.entitySearchText);
    });
  }

  onEntitySelection(index: number, entity: any) {
    this.eventCuList[index].referenceEntityInfo.dsdReferenceEntityId = entity.dsdId;
    this.eventCuList[index].referenceEntityInfo.referenceEntityId = entity.id;
    let completeEntity: any = {};
    /*istanbul ignore next*/
    this.nodeEntityFacadeService.getEntityDeatils(entity.dsdId, false).subscribe((ent: any) => {
      completeEntity = ent.result;
      this.eventCuList[index].referenceEntityInfo.completeEntity = completeEntity;
    });
    /* istanbul ignore next */
    let cuIndex = this.tempGsiObj.solutionLogic?.findIndex((cu: any) => {
      return cu.id == this.configurationData.data.currentcu.data.id;
    });
    /*istanbul ignore next*/
    if (cuIndex != -1) {
      this.tempGsiObj.solutionLogic[cuIndex].eventCUList = this.eventCuList;
    }
  }

  AddConditions(index: number) {
    this.showConditions = true;
    this.selectSource = true;
    /*istanbul ignore next*/
    this.eventCuList[index].referenceEntityInfo = {
      ...this.eventCuList[index]?.referenceEntityInfo,
      membershipCriterias: [
        {
          currentConditionLayer: 'Physical Layer',
          displayCondition: '',
          membershipContextualId: '',
          membershipContextualName: '',
          operator: '',
          referenceAttributeContextualName: '',
          referenceAttributeId: '',
          sourceAttr: {},
          sourceentity: {},
          targetAttr: {},
        },
      ],
    };
  }

  showOperators() {
    this.showOperatorBox = !this.showOperatorBox;
  }

  selectOperator(operator: string) {
    this.selectedOperator = operator;
    this.showOperatorBox = false;
  }

  SelectSourceAttribute(attribute: any, index: number, entity: any) {
    /*istanbul ignore next*/
    let conditionIndex = this.eventCuList[index]?.referenceEntityInfo.membershipCriterias.length - 1;
    this.eventCuList[index].referenceEntityInfo.membershipCriterias[conditionIndex].referenceAttributeContextualName =
      entity.name + '.' + attribute.name;
    this.eventCuList[index].referenceEntityInfo.membershipCriterias[conditionIndex].referenceAttributeId =
      entity.name + '.' + attribute.name;
    this.sourceFormulaAttr = attribute.name;
    this.selectSource = false;
    this.selectTarget = true;
  }

  SelectTargetAttribute(attribute: any, index: number, entity: any, entityIndex?: number) {
    /*istanbul ignore next*/
    let conditionIndex = this.eventCuList[index]?.referenceEntityInfo.membershipCriterias.length - 1;
    let str = this.selectedOperator + 'PL.SL00' + entityIndex + '.' + entity.name + '.' + attribute.name;
    this.eventCuList[index].referenceEntityInfo.membershipCriterias[conditionIndex].membershipContextualId = str;
    this.eventCuList[index].referenceEntityInfo.membershipCriterias[conditionIndex].membershipContextualName = str;
    this.targetFormulaAttr = attribute.name;
  }

  AddCondition(index: number) {
    /*istanbul ignore next*/
    let conditionIndex = this.eventCuList[index]?.referenceEntityInfo.membershipCriterias.length - 1;
    this.eventCuList[index].referenceEntityInfo.membershipCriterias[conditionIndex].displayCondition =
      this.sourceFormulaAttr + this.selectedOperator + this.targetFormulaAttr;
    this.selectedOperator = '';
    this.targetFormulaAttr = '';
    this.sourceFormulaAttr = '';
    this.selectSource = true;
    this.selectTarget = false;
    /*istanbul ignore next*/
    this.eventCuList[index]?.referenceEntityInfo.membershipCriterias.push({
      currentConditionLayer: 'Physical Layer',
      displayCondition: '',
      membershipContextualId: '',
      membershipContextualName: '',
      operator: '',
      referenceAttributeContextualName: '',
      referenceAttributeId: '',
    });
  }

  deleteCondition(index: number, conditionIndex: number) {
    /*istanbul ignore next*/
    this.eventCuList[index]?.referenceEntityInfo?.membershipCriterias?.splice(conditionIndex, 1);
  }

  AddTargetAttribute(index: number) {
    /*istanbul ignore next*/
    this.eventCuList[index]?.referenceEntityInfo.attributeReferences.push({
      currentLayer: '',
      entityDetails: {},
      isEnableTargetAttribute: false,
      refActualAttributeId: '',
      refActualAttributeName: null,
      refDisplayAttributeId: '',
      refDisplayAttributeName: null,
      referenceValueType: '',
      targetAttributeId: '',
      targetAttributeName: null,
    });
  }

  changeEventRef(index: number) {
    this.switchRefernce(index);
    /*istanbul ignore next*/
    if (this.EventRef == 'GSI') {
      this.loadGSI(index);
    } else {
      this.loadEntities();
    }
  }

  SelectAttribute(attrReferenceIndex: number, attribute: any, index: number, entity: any) {
    /* istanbul ignore next */
    if (
      this.eventCuList &&
      this.eventCuList[index]?.referenceEntityInfo?.attributeReferences &&
      this.eventCuList[index]?.referenceEntityInfo?.attributeReferences[attrReferenceIndex]
    ) {
      this.eventCuList[index].referenceEntityInfo.attributeReferences[attrReferenceIndex].layer = 'physical';
      /* istanbul ignore next */
      this.eventCuList[index].referenceEntityInfo.attributeReferences[attrReferenceIndex] = {
        ...this.eventCuList[index]?.referenceEntityInfo?.attributeReferences[attrReferenceIndex],
        currentLayer: 'Physical Layer',
      };
      this.eventCuList[index].referenceEntityInfo.attributeReferences[
        attrReferenceIndex
      ].isEnableTargetAttribute = false;
      /* istanbul ignore next */
      this.eventCuList[index].referenceEntityInfo.attributeReferences[attrReferenceIndex].entityDetails = {
        attribute: {
          name: attribute?.name,
        },
        entity: { name: entity?.name },
        contextualAttrName: 'PL.SL001.' + entity?.name + '.' + attribute?.name,
        contextualAttrId: 'PL.SL001.EN' + entity?.tfId + '.' + attribute?.id,
      };
      /* istanbul ignore next */
      this.eventCuList[index].referenceEntityInfo.attributeReferences[attrReferenceIndex] = {
        /*istanbul ignore next*/
        ...this.eventCuList[index]?.referenceEntityInfo.attributeReferences[attrReferenceIndex],
        targetAttributeId: 'PL.SL001.' + entity.name + '.' + attribute.name,
      };
    }
  }

  onDisplayAttributeSelection(eventIndex: number, attribute: any, attrReferenceIndex: number) {
    /* istanbul ignore next */
    if (
      this.eventCuList &&
      this.eventCuList[eventIndex]?.referenceEntityInfo?.attributeReferences &&
      this.eventCuList[eventIndex]?.referenceEntityInfo?.attributeReferences[attrReferenceIndex]
    ) {
      /* istanbul ignore next */
      this.eventCuList[eventIndex].referenceEntityInfo.attributeReferences[attrReferenceIndex].refDisplayAttributeId =
        this.eventCuList[eventIndex]?.referenceEntityInfo?.completeEntity?.name + '.' + attribute?.name;

      this.eventCuList[eventIndex].referenceEntityInfo.attributeReferences[attrReferenceIndex].displayAttrId = {
        ...attribute,
      };
    }
  }

  onActualAttributeSelection(eventIndex: number, attribute: any, attrReferenceIndex: number) {
    /* istanbul ignore next */
    if (
      this.eventCuList &&
      this.eventCuList[eventIndex]?.referenceEntityInfo?.attributeReferences &&
      this.eventCuList[eventIndex]?.referenceEntityInfo?.attributeReferences[attrReferenceIndex]
    ) {
      /* istanbul ignore next */
      this.eventCuList[eventIndex].referenceEntityInfo.attributeReferences[attrReferenceIndex].refActualAttributeId =
        this.eventCuList[eventIndex]?.referenceEntityInfo?.completeEntity?.name + '.' + attribute?.name;
      this.eventCuList[eventIndex].referenceEntityInfo.attributeReferences[attrReferenceIndex].actualAttId = {
        ...attribute,
      };
    }
  }

  deleteEvent(index: number) {
    this.eventCuList.splice(index, 1);
  }

  detectSpecialFeatures() {
    this.sendSpecialFeaturePayLoad();
    this.nodeEntityFacadeService.specialFeature$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res.isSpecialFeatureAvailable) {
        this.specialFeatureIndex = res.result?.findIndex((spf: any) => {
          return spf.name == this.configurationData?.data?.attr?.attributeType?.type;
        });
        if (this.specialFeatureIndex >= 0) {
          this.isSpecialFeature = true;
        }
      } else {
        this.nodeEntityFacadeService.fetchSpecialFeatures();
      }
    });
  }
  sendSpecialFeaturePayLoad() {
    /*istanbul ignore next*/
    const currentCUReferencedChangeUnit = this.configurationData?.data?.currentcu?.data?.dsdRefId
      ? this.configurationData.data.currentcu.data.dsdRefId
      : this.configurationData?.data?.currentcu?.referencedChangeUnit;
    /*istanbul ignore next*/
    let CuIndex = this.tempGsiObj?.solutionLogic?.findIndex(
      (x: any) => x.referencedChangeUnit == currentCUReferencedChangeUnit
    );
    this.CuIndex = CuIndex;
    /*istanbul ignore next*/
    this.specialFeatureProperties = this.tempGsiObj?.solutionLogic[this.CuIndex]?.specialFeatureProperties;
  }

  getTagToken() {
    this.changeunitFacade.receiveTag$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.currenttagToken = res;
    });
  }

  findSpecialFeaturePayload(event: any) {
    this.tempGsiObj.solutionLogic[this.CuIndex].specialFeatureProperties = {
      ...this.tempGsiObj.solutionLogic[this.CuIndex].specialFeatureProperties,
      ...event,
    };
  }
  getIndexPadding(index: number) {
    let input = index + '';
    while (input.length < 3) {
      input = '0' + input;
    }
    return input;
  }
  getLayerId(res: any) {
    let indexPadding = this.getIndexPadding(res.slotIndex + 1);
    let layerShortCut = 'PL_SL' + indexPadding;
    /*istanbul ignore next*/
    if (res?.layerType == 'information') {
      layerShortCut = 'IL_SL' + indexPadding;
    } else if (res?.layerType == 'triggerCES') {
      /*istanbul ignore next*/
      layerShortCut = 'triggerCES_SL' + indexPadding;
    } else if (res?.layerType === 'reservedActions') {
      /*istanbul ignore next*/
      layerShortCut = 'reservedActions_SL' + indexPadding;
    }
    return layerShortCut;
  }
  setConstantChangeDriver(res: any) {
    let layerShortCut = this.getLayerId(res);
    if (this.constantChangeDrivers && this.constantChangeDrivers[layerShortCut]) {
      /*istanbul ignore next*/
      if (res.entity.constantChangeDriverEntity) {
        this.constantChangeDrivers[layerShortCut].propertyData.CONSTANT.changeDriverData.DATA.properties = ['CONSTANT'];
      }
      let foundAttrIndex = -1;
      /*istanbul ignore next*/
      foundAttrIndex = this.constantChangeDrivers[
        layerShortCut
      ]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords[0]?.txnNslAttribute?.findIndex(
        (x: any) => x.dsdId === res.attributeId
      );
      /*istanbul ignore next*/
      if (foundAttrIndex !== -1) {
        /*istanbul ignore next*/
        if (res.selected && res.values?.length > 0) {
          /*istanbul ignore next*/
          this.constantChangeDrivers[
            layerShortCut
          ].propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute[
            foundAttrIndex
          ].values = res.values ? res.values : [];
          this.constantChangeDrivers[
            layerShortCut
          ].propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute[
            foundAttrIndex
          ].properties = ['CONSTANT'];
        } else {
          /*istanbul ignore next*/
          delete this.constantChangeDrivers[layerShortCut].propertyData.CONSTANT.changeDriverData.DATA
            .transEntityRecords[0].txnNslAttribute[foundAttrIndex].properties;
          /*istanbul ignore next*/
          delete this.constantChangeDrivers[layerShortCut].propertyData.CONSTANT.changeDriverData.DATA
            .transEntityRecords[0].txnNslAttribute[foundAttrIndex].values;
        }
      }
    } else {
      /*istanbul ignore next*/
      let constantPropertyData: any = {
        propertyData: {
          CONSTANT: {
            cuLayer: res?.layerType,
            slotItemIndex: res.slotIndex,
            changeDriverData: {
              TYPE: 'TxnGeneralEntity',
              DATA: {
                name: res.entity.name,
                dsdId: res.entity?.id,
                transEntityRecords: [
                  {
                    txnNslAttribute: [],
                  },
                ],
              },
            },
          },
        },
      };
      /*istanbul ignore next*/
      res.entity.nslAttributes?.forEach((attribute: any) => {
        const attr: any = {
          name: attribute.name,
          dsdId: attribute?.id,
        };
        /*istanbul ignore next*/
        if (res.attributeId === attribute?.id && res.values?.length > 0) {
          attr.values = res.values;
          /*istanbul ignore next*/
          if (res.selected) {
            attr.properties = ['CONSTANT'];
          }
        }
        constantPropertyData.propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute.push(
          attr
        );
      });
      /*istanbul ignore next*/
      if (res.entity.constantChangeDriverEntity) {
        constantPropertyData.propertyData.CONSTANT.changeDriverData.DATA.properties = ['CONSTANT'];
      }
      this.constantChangeDrivers[layerShortCut] = constantPropertyData;
      // this.constantChangeDrivers[0].push(slotItem);
      // this.changeUnitFacade.updateConstantChangeDriver(
      //   constantPropertyData.propertyData.CONSTANT.changeDriverData.DATA.transEntityRecords[0].txnNslAttribute
      // );
    }
  }

  detectAttributeConfig() {
    /* istanbul ignore else */
    if (
      this.configurationData?.data?.attr['configuration'] &&
      typeof this.configurationData?.data?.attr['configuration'] == 'string'
    ) {
      this.configurationData.data.attr['configuration'] = JSON.parse(
        this.configurationData?.data?.attr['configuration']
      );
    } else {
      this.configurationData.data.attr['configuration'] = {
        hideLabel: false,
        autoRedirect: false,
      };
    }
    /* istanbul ignore else */
    if (!this.configurationData?.data?.attr['configuration']?.autoRedirect) {
      this.configurationData.data.attr['configuration']['autoRedirect'] = false;
    }

  }
  addDefaultValuetoAttibute() {
    /* istanbul ignore next */
    if (this.sourceValues?.length >= 0) {
      this.addEntityBtn = true;
    }
    /*istanbul ignore next*/
    if (this.attrDetails?.value?.defaultOption?.length > 0 || this.attrDetails?.value?.defaultOption?.trim() !== '') {
      if (this.attrDetails?.value?.attributeUiElement == 'currency') {
        this.sourceValues = [];
        this.currencyOptions = this.attrDetails?.value?.defaultOption;
        /*istanbul ignore next*/
        this.attrDetails?.value?.defaultOption.forEach((option: any) => {
          this.sourceValues?.push({
            TYPE: 'OptionValue',
            DATA: {
              actualValue: option?.name,
              displayValue: option?.symbol_native,
            },
          });
        });
      } else {
        let index = this.sourceValues.findIndex(
          (ele: any) => ele?.DATA?.actualValue.toLowerCase() === this.attrDetails?.value?.defaultOption.toLowerCase()
        );
        if(index == -1){
          this.sourceValues?.push({
            TYPE: 'OptionValue',
            DATA: {
              actualValue: this.attrDetails.value.defaultOption,
              displayValue: this.attrDetails.value.defaultOption,
            },
          });
        }
        this.attrDetails.patchValue({
          defaultOption: '',
        });
      }
    }
  }
  editAttributes() {
    // For default value
    /*istanbul ignore next*/
    if (this.configurationData?.data?.attr) {
      this.attrDetails.patchValue({
        defaultValue: '',
        attributeUiElement: this.configurationData.data.attr?.attributeType?.uiElement?.uiElement,
        attributeType: this.configurationData.data.attr?.attributeType?.uiElement?.dataType,
      });

      this.allDefaultValues = [];
      if (this.configurationData?.data?.attr?.defaultValue) {
        let attrDefaultValue = this.configurationData.data.attr.defaultValue;
        if (
          (this.configurationData.data.attr?.attributeType?.uiElement?.dataType === 'list' ||
            attrDefaultValue[0] == '[') &&
          this.configurationData.data.attr?.attributeType?.uiElement?.uiElement !== 'dateRange'
        ) {
          this.allDefaultValues = this.IsJsonString(attrDefaultValue) ? JSON.parse(attrDefaultValue) : [];
          this.multiDefaultValue = true;
        } else {
          this.allDefaultValues = [attrDefaultValue];
        }
      }
      /* istanbul ignore next */
      if (
        this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'boolean' &&
        this.allDefaultValues[0] === 'true'
      ) {
        this.booleanDefaultValue = true;
      }
        /* istanbul ignore next */
        if (
          this.configurationData.data.attr?.attributeType?.uiElement?.uiElement === 'location' &&
          this.configurationData?.data?.attr?.configuration.hideSearchLocation
        ) {
          this.hideSearchLocation = this.configurationData?.data?.attr?.configuration.hideSearchLocation;
        }
        if (this.configurationData?.data?.attr?.attributeType?.properties?.showPopup == 'true') {
          this.showPopup = true;
        } else {
          this.viewLink = true;
        }
    }

    /*istanbul ignore next*/
    this.constantChangeDriverObj = {
      slotId: this.configurationData.data?.entity?.id,
      slotIndex: this.configurationData.data.entityIndex,
      entity: this.configurationData.data.entity,
      attributeId: this.configurationData.data?.attr?.id,
      layerType: this.configurationData.data.entity?.layerType,
    };
  }
  removeScourceValue(i: any) {
    if (this.sourceValues.length <= 1) {
      this.addEntityBtn = false;
    }
    /*istanbul ignore next*/
    if (this.allDefaultValues?.length > 0) {
      /*istanbul ignore next*/
      let value = this.sourceValues?.[i]?.DATA?.actualValue;
      /*istanbul ignore next*/
      let index = this.allDefaultValues?.findIndex((ele: any) => ele === value);
      /*istanbul ignore next*/
      if (index >= 0) {
        this.allDefaultValues.splice(index, 1);
      }
    }
    this.sourceValues.splice(i, 1);
  }
  removeDefaultValue(i: any) {
    if (this.attrDetails?.value?.attributeUiElement == 'MMILocation') {
      this.mmiDefaultLat = '';
      this.mmiDefaultLon = '';
    }
    this.allDefaultValues.splice(i, 1);
  }
  addDefaultValue() {
    this.dateTypeDefaultValue = null;
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'duration') {
      /* istanbul ignore next */
      if (
        this.durationDefaultValue?.days !== null ||
        this.durationDefaultValue?.hours !== null ||
        this.durationDefaultValue?.minutes !== null
      ) {
        let durationFormat = '';

        /* istanbul ignore next */
        let days: any = this.durationDefaultValue?.days;
        /* istanbul ignore next */
        let hours: any = this.durationDefaultValue?.hours;
        /* istanbul ignore next */
        let minutes: any = this.durationDefaultValue?.minutes;
        if (!days && !hours) {
          if (minutes) durationFormat = `PT${minutes}M`;
          else durationFormat = `P`;
        } else if (!days) {
          if (minutes) durationFormat = `PT${hours}H${minutes}M`;
          else durationFormat = `PT${hours}H`;
        } else if (!hours) {
          if (minutes) durationFormat = `P${days}DT${minutes}M`;
          else durationFormat = `P${days}D`;
        } else {
          if (minutes) durationFormat = `P${days}DT${hours}H${minutes}M`;
          else durationFormat = `P${days}DT${hours}H`;
        }

        this.defaultValue = durationFormat;

        this.durationDefaultValue.days = null;
        this.durationDefaultValue.hours = null;
        this.durationDefaultValue.minutes = null;
      } else {
        this.alertService.showToaster('Please enter duration!', '', 'error');
        return;
      }
    }
    if (this.attrDetails?.value?.attributeUiElement === 'MMILocation') {
      this.defaultValue = JSON.stringify({ latitude: this.mmiDefaultLat, longitude: this.mmiDefaultLon });
      this.mmiDefaultLat = '';
      this.mmiDefaultLon = '';
    }
    /* istanbul ignore next */
    if (this.defaultValue === '') {
      return;
    }
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'rating' && Number(this.defaultValue) > 5) {
      this.alertService.showToaster('Provide default value in range of 0 to 5', '', 'info');
      return;
    }

    /* istanbul ignore next */
    this.attrDetails?.patchValue({ defaultValue: this.defaultValue });
    /* istanbul ignore next */
    if (this.validateDefaultValue()) {
      /* istanbul ignore next */
      if (this.multiDefaultValue || this.allDefaultValues?.length === 0) {
        /* istanbul ignore next */
        if (
          this.attrDetails?.value?.defaultValue &&
          this.dateTypeUiControls?.includes(this.attrDetails?.value?.attributeUiElement)
        ) {
          /* istanbul ignore next */
          this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
          /* istanbul ignore next */
          this.attrDetails?.patchValue({ defaultValue: '' });
          this.defaultValue = '';
        } else {
          /* istanbul ignore next */
          if (this.attrDetails?.value?.defaultValue?.trim() !== '') {
            /* istanbul ignore next */
            this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
            /* istanbul ignore next */
            this.attrDetails?.patchValue({ defaultValue: '' });
            this.defaultValue = '';
          } else {
            this.alertService.showToaster(
              'Default value is already exist and multiple values are not allowed',
              '',
              'error'
            );
          }
        }
      } else if (!this.multiDefaultValue && this.allDefaultValues.length > 0) {
        this.alertService.showToaster(
          'Default value is already present and multiple values are not allowed',
          '',
          'error'
        );
      } else {
        this.alertService.showToaster('Please enter valid default value!', '', 'error');
      }
    }
  }
  validateDefaultValue() {
    /* istanbul ignore next */
    let uiControl = this.attrDetails?.value?.attributeUiElement;
    /* istanbul ignore next */
    let dataType = this.attrDetails?.value?.attributeType;
    /* istanbul ignore next */
    let value = this.attrDetails?.value?.defaultValue;

    let res = validateDefaultValue(
      this.dateTypeUiControls,
      uiControl,
      dataType,
      value,
      this.sourceValues,
      this.allDefaultValues
    );
    if (res.message.length > 0) {
      res.message.forEach((msg: any) => {
        this.alertService.showToaster(msg.msg, '', msg.type);
      });
    }
    return res.isValid;
  }
  sliderDefaultValue() {
    console.log(this.defaultValue);
    /* istanbul ignore next */
    if (this.defaultValue > this.targetValue) {
      this.alertService.showToaster(`Please provide Default value in range of 0 to ${this.targetValue}`, '', 'info');
    } else {
      this.attrDetails?.patchValue({ defaultValue: this.defaultValue });
      this.allDefaultValues?.push(JSON.parse(JSON.stringify(this.attrDetails?.value?.defaultValue)));
      this.attrDetails?.patchValue({ defaultValue: '' });
      this.defaultValue = '';
    }
  }
  displayDateDefaultValue(value: any, attributeUiElement: any) {
    return displayDateDefaultValue(
      value,
      attributeUiElement,
      this.dateFormatType,
      this.timeFormatType,
      this.yearMonthFormatType
    );
  }
  formatDate() {
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'time' && !isNaN(Date.parse(this.dateTypeDefaultValue))) {
      let updateTime = this.dateTypeDefaultValue?.setSeconds(0);
      this.dateTypeDefaultValue = new Date(updateTime);
    }
    this.defaultValue = this.dateTypeDefaultValue;
    /* istanbul ignore next */
    if (this.attrDetails?.value?.attributeUiElement === 'dateRange') {
      this.defaultValue = JSON.stringify(this.defaultValue);
    }
  }
  // For yearMonth UI control
  /* istanbul ignore next */
  chosenYearHandler(normalizedYear: any) {
    this.dateTypeDefaultValue = new Date(normalizedYear);
  }
  // For yearMonth UI control
  /* istanbul ignore next */
  chosenMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    this.dateTypeDefaultValue = new Date(normalizedMonth);
    /* istanbul ignore next */
    datepicker?.close();
    this.formatDate();
  }

  /* istanbul ignore next */
  chosenYearForYear(normalizedYear: any, yearPicker: MatDatepicker<any>) {
    this.defaultValue = new Date(normalizedYear).getFullYear();
    /* istanbul ignore next */
    yearPicker?.close();
  }
  checkDuration() {
    if (this.durationDefaultValue.hours < 0 || this.durationDefaultValue.hours > 23) {
      this.durationDefaultValue.hours = null;
    }
    if (this.durationDefaultValue.minutes < 0 || this.durationDefaultValue.minutes > 59) {
      this.durationDefaultValue.minutes = null;
    }
  }
  booleanValue() {
    this.allDefaultValues = [];
    if (this.booleanDefaultValue) {
      this.allDefaultValues.push('true');
    }
  }
  onlyNumberKey(event: any) {
    /* istanbul ignore next */
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode == 45 || (event.charCode > 46 && event.charCode <= 57);
  }
  validatorsChange(event: any, index: any) {
    event.properties.map((val: any) => {
      val.message = '';
    });
    this.selectedUiValidator = event.name;
    this.selectedValidatorsList.push(event);
    this.UiValidatorDropdown.constraints.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });

    this.UiValidatorDropdown.constraints.splice(index, 1);
    this.constraints = [];
    const constraintsArr = this.eventsFacadeService.getDisableValidatorsList(event.name);
    /* istanbul ignore next */
    if (constraintsArr && constraintsArr?.length) {
      /*istanbul ignore next*/
      constraintsArr?.forEach((element: any, i: any) => {
        this.UiValidatorDropdown.constraints?.forEach((value: any, j: any) => {
          if (element === value.name) {
            value.disabled = true;
          }
        });
      });
    }
  }
  removeValidator(row: any, index: any) {
    this.selectedValidatorsList.splice(index, 1);
    this.UiValidatorDropdown.constraints.map((val: any) => {
      val.disabled = false;
      if (val.name == 'optional' || val.name == 'empty') {
        val.disabled = true;
      }
    });
    this.UiValidatorDropdown.constraints.push(row);
    /*istanbul ignore next*/
    this.UiValidatorDropdown.constraints = this.UiValidatorDropdown.constraints.filter(
      (v: any, i: any, a: any) => a?.findIndex((t: any) => t.name === v.name) === i
    );
  }
  validationMultiValue(attrType: any) {
    this.attributeEndpointService.getAllAttributeTypes(this.searchText, 1, 100).subscribe((result: any) => {
      /*istanbul ignore next*/
      if (result?.data) {
        let res: any;
        this.addEntityBtn = true;
        result?.data.forEach((type: any, i: any) => {
          if (attrType?.value == type?.uiElement) {
            res = type;
          }
        });
        if (res && res?.isMulti === true) {
          /* istanbul ignore next */
          if (res.isMulti === true && res?.uiElement != 'typeahead') {
            this.addEntityBtn = false;
          } else {
            this.addEntityBtn = true;
          }
        }
      }
    });
  }
  typeChange(selectType: any, ind: number) {
    this.selectedValidatorsList[ind].selectType = selectType;
  }
  getvalidationsList() {
    /*istanbul ignore next*/
    if (!this.dataType) {
      /*istanbul ignore next*/
      this.dataType = this.configurationData?.data?.attr?.attributeType?.type;
    }
    if (this.dataType) {
      const constraintsData =
        this.attributeValidations?.length > 0 ? JSON.parse(JSON.stringify(this.attributeValidations)) : [];
      this.UiValidatorDropdown = constraintsData?.find((value: any) => {
        return value.name === this.dataType;
      });

      this.UiValidatorDropdown?.constraints.map((val: any) => {
        /*istanbul ignore next*/
        if (val.name == 'optional' || val.name == 'empty') {
          val.disabled = true;
        }
      });
      /*istanbul ignore next*/
      if (this.attributeValidations && this.attributeValidations.length) {
        this.selectedValidatorsList = [];
        /*istanbul ignore next*/
        if (
          this.configurationData?.data?.attr &&
          this.configurationData.data.attr['constraints'] &&
          this.configurationData.data.attr['constraints'].length
        ) {
          this.configurationData.data.attr['constraints']?.forEach((value: any) => {
            const option = this.UiValidatorDropdown?.constraints?.find((val: any) => {
              return val.name == value.name;
            });
            const obj = {
              name: value.name,
              displayName: option?.displayName ? option.displayName : value.name,
            };
            obj['properties'] = [];
            /*istanbul ignore next*/
            if (value.properties && Object.keys(value.properties).length) {
              let editConstraintsArrKeys = Object.keys(value.properties);
              let editConstraintsArrValues = Object.values(value.properties);

              editConstraintsArrKeys?.forEach((val, i) => {
                obj['properties'].push({
                  name: val,
                  message: editConstraintsArrValues[i],
                });
              });
            }
            /*istanbul ignore next*/
            if (this.iseditAttr) {
              this.selectedValidatorsList.push(obj);
              this.selectedValidatorsList.filter((el: any) => {
                this.UiValidatorDropdown.constraints = this.UiValidatorDropdown?.constraints?.filter((el1: any) => {
                  return el1.name !== el.name;
                });
              });
            }
          });
        }
        if (this.selectedValidatorsList) {
          this.selectedValidatorsList.forEach((selected: any) => {
            selected.properties.forEach((value: any) => {
              if (value.name === 'type') {
                selected.selectType = value.message;
              }
            });
          });
        }
      }
    }
  }
  getValidations() {
    this.entityFacadeService.attributeValidation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.attributeValidations = res;
        this.getvalidationsList();
      }
    });
  }
  detectEntityData() {
    this.nodeEntityFacadeService.editAttributeEntity$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.configurationData.data.entity = response;
    });
  }
  getUiControlDataType() {
    let val = this.configurationData;
    /* istanbul ignore next */
    let attr = val?.data?.attr;
    /* istanbul ignore next */
    let entity = val?.data?.entity;
    /* istanbul ignore next */
    if (entity?.status === 'DRAFT' && entity?.version === '1.0' && !attr?.id) {
      this.getAttributeTypes();
    } else {
      this.checkAttributeUiControl(attr);
    }
  }
  checkAttributeUiControl(attr: any) {
    /* istanbul ignore next */
    if (attr?.attributeType?.uiElement?.dataType) {
      /* istanbul ignore next */
      this.getAttributeTypes(attr?.attributeType?.uiElement?.dataType);
    }
  }
  IsJsonString(str: any) {
    /* istanbul ignore next */
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  appendUiAttributes() {
    this.constraints = [];
    /*istanbul ignore next*/
    this.selectedValidatorsList?.forEach((element: any, i: any) => {
      /*istanbul ignore next*/
      if (element) {
        this.constraints.push({
          name: element.name,
          properties: {},
          type: element.type,
        });
        /*istanbul ignore next*/
        if (element.properties && element.properties?.length) {
          element.properties?.forEach((value: any) => {
            this.constraints[i].properties[value.name] = value.message;
          });
        }
        if (element?.selectType) {
          this.constraints[i].properties['type'] = element?.selectType;
        }
      }
    });
  }
  CompareProperties(upper: string, prop: string) {
    if (prop == 'ATTRIBUTE_CONSTRAINTS') this.betLevel = upper == 'Basic' ? true : false;
    else if (prop == 'DEFAULT_VALUE') this.betLevelDefault = upper == 'Basic' ? true : false;
    if (!this.betLevel || !this.betLevelDefault) {
      this.callAttributeComparisonData(prop);
    }
  }
  callAttributeComparisonData(property: string) {
    this.nodeEntityFacadeService.getAttributeComparisonData(this.prepareComparisondata(property));
  }
  strikeThroughConfig(event: any): void {
    this.attrConfiguration.strikeThrough = event.checked;
  }
  prepareComparisondata(prop: string) {
    this.propertyType = prop;
    let data = this.configurationData.data;
    // let layer: any=this.triggerCuData.layers.find((lay: any) => lay?.type == data?.layerType);
    let entity = data.entity;
    let attr = data.attr;
    let str = JSON.stringify(data.entityIndex + 1);
    let contextualId = data?.layerType + '.slot:' + str + '.' + entity?.name + '.' + attr?.name;
    let payload: any = {
      property: [prop],
      attributeName: attr?.name,
      attributeType: attr.attributeType?.uiElement?.uiElement,
      referenceChangeUnitId: null,
      triggerCUID: this.changeUnit.id,
      entityID: entity.id,
      entityPath: contextualId,
      gsiId: this.tempGsiObj.id,
      properties: {
        constraints: attr['constraints'],
        defaultValue: this.multiDefaultValue ? JSON.stringify(this.allDefaultValues) : this.allDefaultValues[0],
        memberShipCriteria: attr?.memberShipCriteria,
      },
    };
    return payload;
  }



  getAttributeComparisonData() {
    this.nodeEntityFacadeService.AttributeComparisonData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        let isDefVal: boolean = false;
        if (this.propertyType == 'DEFAULT_VALUE') {
          isDefVal = true;
          this.compareDef = true;
        } else this.compareVal = true;
        if (!this?.lowerBetProperties) this.lowerBetProperties = {};
        let properties = res.result[0]?.properties;
        if (isDefVal) {
          if (this.multiDefaultValue) {
            this.UpperBetProperties.defaultValue = JSON.parse(properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.defaultValue);
            let defVal = properties?.ENTITY_LEVEL_PROPERTIES?.defaultValue;
            if (defVal) this.lowerBetProperties.defaultValue = JSON.parse(defVal);
          } else {
            this.UpperBetProperties.defaultValue[0] = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.defaultValue;
            let defVal = properties?.ENTITY_LEVEL_PROPERTIES?.defaultValue;
            if (defVal) this.lowerBetProperties.defaultValue[0] = defVal;
          }
          if (this.lowerBetProperties.defaultValue == undefined || this.lowerBetProperties.defaultValue?.length == 0)
            this.lowerBetProperties.defaultValue = this.UpperBetProperties?.defaultValue;
        } else {
          this.UpperBetProperties.constraints = properties?.CHANGE_UNIT_LEVEL_PROPERTIES?.constraints;
          let temp = properties?.ENTITY_LEVEL_PROPERTIES?.constraints;
          this.lowerBetProperties = upperVsLowerBetData(
            temp,
            this.UpperBetProperties,
            this.UiValidatorDropdown,
            this.lowerBetProperties,
            this.iseditAttr
          );
        }
      }
    });
  }
  compareValuePopup(prop: string) {
    this.propertyType = prop;
    this.dialogRef = this.dialog.open(NodeCompareComponent, {
      width: '600px',
      height: '700px',
      data: {
        property: this.propertyType,
        upperBetProperties: this.propertyType == 'DEFAULT VALUE' ? this.allDefaultValues : this.selectedValidatorsList,
        lowerBetProperties: this.lowerBetProperties,
        status: 'DRAFT',
      },
    });
    this.dialogRef?.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.propertyType == 'DEFAULT VALUE') this.allDefaultValues = result?.upperBetProperties;
        else this.selectedValidatorsList = result?.upperBetProperties;
      }
    });
  }

  detectCcdValue() {
    this.changeunitFacade.ccd$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.attrDetails.value.constantChangeDriver = res.isCcd;
        this.attrDetails.value.constantChangeDriverValue = res.ccdValue;
      }
    });
  }

  selectedPosition(type: string) {
    this.helperTextPostion = type;
  }

  selectedColor(type: string) {
    this.helperTextColor = type;
  }

  selectedFont(type: string) {
    this.helperTextFont = type;
  }
  /* istanbul ignore next */
  setLimit(val: any, action: any) {
    /* istanbul ignore next */
    if ((action == 'minutes' || action == 'seconds') && val?.target?.value > 59) {
      this.timeLimit[action] = 59;
    } else if ((action == 'minutes' || action == 'seconds') && val?.target?.value < 0) {
      this.timeLimit[action] = 0;
    }
  }
  htmlToggle(option: boolean) {
    if (option) {
      this.attrConfiguration.showPopup = true;
    }
  }
  changeSubmitToggle(event: any, template: any) {
    /* istanbul ignore next */
    if (event?.checked === true) {
      this.openWarnningDialog(template);
    } else {
      this.toggleCUSubmit = false;
      let attrConfig = JSON.parse(this.configurationData?.data?.attr?.configuration);
      attrConfig = {
        ...attrConfig,
        submitCU: false,
      };
      this.configurationData.data.attr['configuration'] = JSON.stringify(attrConfig);
    }
  }

  openWarnningDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      panelClass: 'onboardingtype-dialog',
      width: '40%',
      height: '15%',
      disableClose: true,
    });
  }

  onSubmitSelected() {
    let currentAttrId = this.configurationData?.data?.attr?.id;
    let attrConfig = this.configurationData?.data?.attr?.configuration;
    attrConfig = {
      ...attrConfig,
      submitCU: true,
    };
    this.configurationData.data.attr['configuration'] = JSON.stringify(attrConfig);
    for (let layer of this.currentCU?.layers) {
      for (let entity of layer?.participatingItems) {
        for (let attribute of entity?.nslAttributes) {
          if (attribute?.configuration && attribute?.id != currentAttrId) {
            let config = JSON.parse(attribute?.configuration);
            if (config?.submitCU) {
              config.submitCU = false;
            }
            attribute.configuration = JSON.stringify(config);
          }
        }
      }
    }
    this.dialog.closeAll();
  }

  onSubmitCancel() {
    this.toggleCUSubmit = false;
    this.dialog.closeAll();
  }

  addMMIDestination() {
    this.mmiDestination = JSON.stringify({ latitude: this.mmiDestinationLat, longitude: this.mmiDestinationLon });
    this.mmiDestinationLat = '';
    this.mmiDestinationLon = '';
  }
  removeMMIDestination() {
    this.mmiDestinationLat = '';
    this.mmiDestinationLon = '';
    this.mmiDestination = '';
  }
  editMMIDestination() {
    this.mmiDestinationLat = JSON.parse(this.mmiDestination)?.latitude;
    this.mmiDestinationLon = JSON.parse(this.mmiDestination)?.longitude;
    this.mmiDestination = '';
  }

  /**
   * @author Rohith
   * @Description This method is used to upload image for label with image ui control
   * @Date 10/11/2023
   */
   fileUploadUrl2(event: any) {
    this.eachImageUrl.push(event);
    this.imgUrlWithLabel = JSON.stringify(this.eachImageUrl);
  }

  /**
   * @author Rohith
   * @Description This method is used to get the deleted image data
   * @Date 10/11/2023
   */
  deletedFileImgResponse(event: any) {
    this.fileUrl = event;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
